import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { scaleQuantize } from "d3-scale";
import { max } from "d3-array";
import Header from "./Header";
import {
  COLOR_RANGE,
  BLUE_COLOR_RANGE,
  COLORS,
} from "../../../../../constants";
import LayersIcon from "../../../../../images/layers.svg";
import InfoPopover from "../../../../Helpers/InfoPopover";
import Map from "./Map";
import Legend from "./Legend";
import Tooltip from "./Tooltip";
import { MarketReportMap } from "../../../../Maps/MarketReportMap"

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
  },
  mapWrapper: {
    overflowX: "auto",
  },
  map: {
    border: "1px solid #707070",
    padding: "0 0 15px",
    width: 641,
    height: 469,
    position: "relative",
  },
  selectWrapper: {
    width: 159,
    height: 40,
    margin: "0 464.5px 365.3px 3.1px",
    padding: "11px 13px 11px 14px",
    borderRadius: "5px",
    border: "none",
    boxShadow: "0 1px 5px 0 rgba(0, 0, 0, 0.5)",
    backgroundColor: COLORS.white,
    textTransform: "none",
    color: "#777777",
    fontSize: 14,
    fontWeight: "bold",
    zIndex: 5,
    position: "absolute",
    top: 4,
    left: 0,
    "&:hover": {
      backgroundColor: COLORS.lightGray,
    },
  },
  select: {
    padding: "11px 13px 11px 10px",
    border: "none",
    color: "#777777",
    fontWeight: "bold",
    position: "absolute",
    top: 0,
    "&:focus": {
      outline: "none",
    },
    "&:hover": {
      backgroundColor: COLORS.lightGray,
    },
  },
  legend: {
    padding: 17,
    display: "flex",
    flexDirection: "column",
    "& .title": {
      fontSize: 16,
      fontWeight: "bold",
      lineHeight: "21px",
      color: COLORS.text,
      display: "flex",
      flexDirection: "row",
      alignItems: "center",
      marginBottom: 20,
    },
  },
}));

/**
 * Renders the map container
 * @param {object} counties object with county data
 * @param {object} activeFarm object with farm data
 * @param {object} crop object with id and active property
 */

const MapContainer = ({ counties, activeFarm, crop, 
  harvestDataForCounty, 
  harvestDataForFarm,
  selectedState,
  activeYear,
  selectedCrop,
  precipitationDataForCounty,
  precipDataForFarm,
  gddDataForCounty
 }) => {
  //console.log("harvestDataForFarm", harvestDataForFarm)
  
  const classes = useStyles();

  const [activeMarker, setActiveMarker] = useState(null);
  const [topic, setTopic] = useState("Yield/Acre");

  const colors = topic === "Yield/Acre" ? COLOR_RANGE : BLUE_COLOR_RANGE;

  let rangeValues = [0]
  if(topic === "Yield/Acre"){
    rangeValues = selectedCrop !== undefined ? 
    harvestDataForCounty.filter(x => x.CropName === selectedCrop.id).length > 0 ? 
    harvestDataForCounty.filter(x => x.CropName === selectedCrop.id).map(x => x.AverageHarvestYield) : [0] : [0]
  }
  else{
    rangeValues = precipitationDataForCounty.map(x => x.Pcpn)
  }
  //console.log("rangeValues", rangeValues)
  // Calculate the color scale
  const colorScale = scaleQuantize()
    .range(colors.map((d) => d.backgroundColor))
    .domain([0, Math.max(...rangeValues)]);
  //console.log("colorScale", colorScale)

  const options = ["Yield/Acre", "Precipitation", "GDD"];

  return (
    <div className={classes.container}>
      <Header topic={topic} crop={crop} activeYear={activeYear} />
      <div className={classes.wrapper}>
        <div className={classes.mapWrapper}>
          <div className={classes.map}>
            <div className={classes.selectWrapper}>
              <img
                src={LayersIcon}
                alt="changing layers on the map - first icon"
              />
              <select
                className={classes.select}
                value={topic}
                onChange={(e) => setTopic(e.target.value)}
              >
                {options.map((option, i) => (
                  <option key={`menu-item-for-${i}}`} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
            {/* <Map
              counties={counties}
              colorScale={colorScale}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
              activeFarm={activeFarm}
              topic={topic}
            /> */}
            <MarketReportMap 
              counties={counties}
              colorScale={colorScale}
              activeMarker={activeMarker}
              setActiveMarker={setActiveMarker}
              activeFarm={activeFarm}
              topic={topic}
              harvestDataForCounty={harvestDataForCounty}
              selectedState={selectedState}
              selectedCrop={selectedCrop}
              precipitationDataForCounty={precipitationDataForCounty}
              gddDataForCounty={gddDataForCounty}
            />
          </div>
        </div>
        <div className={classes.legend}>
          <div className="title">
            {topic}
            <span>
              <InfoPopover text="legend" />
            </span>
          </div>
          <Legend
            legendType={topic}
            colorScale={colorScale}
            activeFarm={activeFarm}
            harvestDataForFarm={harvestDataForFarm}
            crop={selectedCrop}
            harvestDataForCounty={harvestDataForCounty}
            precipDataForFarm={precipDataForFarm}
            gddDataForCounty={gddDataForCounty}
          />
        </div>
      </div>
      {activeMarker && <Tooltip activeMarker={activeMarker} />}
    </div>
  );
};

export default MapContainer;

MapContainer.propTypes = {
  counties: PropTypes.object.isRequired,
  activeFarm: PropTypes.object.isRequired,
  crop: PropTypes.object.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { ascending } from "d3-array";
import {
  COLORS,
  NO_DECIMAL_PERCENT_FORMAT,
  PREFIX_FORMAT,
  MARKET_TABS,
} from "../../../../../../constants";
import positiveIcon from "../../../../../../images/plus_icon.svg";
import negativeIcon from "../../../../../../images/minus_icon.svg";
import GeographyMarker from "../../GeographyMarker";

const useStyles = makeStyles(() => ({
  row: {
    width: "100%",
    color: COLORS.text,
    fontSize: 14,
    "&.last": {
      borderBottom: `1px solid ${COLORS.lightGray}`,
    },
  },
  barContainer: {
    display: "inline-block",
    position: "relative",
    width: "95%",
    minWidth: 220,
    height: "28px",
    background: COLORS.veryLightGrey,
    margin: 8,
  },
  differenceContainer: {
    fontSize: 12,
    width: "10%",
    minWidth: 60,
    fontWeight: "bold",
    color: COLORS.green,
    "&.negative": {
      color: COLORS.red,
    },
    "& :first-child": {
      marginRight: "3px",
    },
  },
  percentage: {
    float: "left",
  },
  divider: {
    position: "relative",
    height: "100%",
    width: 1,
    borderRight: `1px solid ${COLORS.lightGray}`,
  },
  label: {
    fontSize: 12,
    width: "10%",
    minWidth: 60,
    fontWeight: "bold",
    paddingLeft: 10,
    color: COLORS.gray,
    "&.farm": {
      color: COLORS.green,
    },
  },
  title: {
    width: "15%",
    minWidth: 80,
    paddingLeft: "1rem",
  },
  expand: {
    marginLeft: 5,
    "& > svg": {
      cursor: "pointer",
      color: COLORS.gray,
      "&:hover, &.active": {
        color: COLORS.green,
      },
    },
  },
}));

/**
 * Renders the market report overview table expandable variety row
 * @param {object} row object with items, values, and varieties properties.
 * Not all objects have all of these properties.
 * @param {string} farmProperty default col header
 * @param {string} geographyProperty default col header
 * @param {string} dateProperty default col header
 * @param {string} category the name of the active category
 * @param {boolean} lastVariety designates if it's the last index for style use
 */

const VarietyTableRow = ({
  row,
  farmProperty = "Your Farm",
  geographyProperty = "State",
  dateProperty = "avgPlantingDate",
  category,
  lastVariety,
}) => {
  //console.log("last", lastVariety);
  const classes = useStyles();
  const farm = row.values.find((d) => d.name === farmProperty);
  const geography = row.values.find((d) => d.name === geographyProperty);
  let deno = farm.value !== 0 ? farm.value : geography.value
  const difference = (farm.value - geography.value) / deno;

  return (
    <tr className={`${classes.row} ${lastVariety ? "last" : ""}`}>
      <td className={classes.title}>{row.item}</td>
      <td className={classes.barContainer}>
        {row.values
          .sort((a, b) => ascending(a.value, b.value))
          .map((d, i) => (
            <GeographyMarker
              key={`geography-marker-for-${d.name}`}
              isFarm={d.name === farmProperty}
              value={PREFIX_FORMAT(d.value)}
              position={Math.floor(Math.random() * 100)}
              isLarger={i === row.values.length - 1}
            />
          ))}
      </td>
      <td
        className={`${classes.differenceContainer} ${
          difference < 0 ? "negative" : ""
        }`}
      >
        <img
          style={{ float: "left" }}
          src={difference < 0 ? negativeIcon : positiveIcon}
          alt="icon representing the difference between geography and farm."
        />
        <div className={classes.percentage}>
          {NO_DECIMAL_PERCENT_FORMAT(Math.abs(difference))}
        </div>
      </td>
      {category !== MARKET_TABS.Soil ? (
        <>
          <td className={classes.divider} />
          <td className={classes.label}>{geography[dateProperty]}</td>
          <td className={`${classes.label} farm`}>{farm[dateProperty]}</td>
        </>
      ) : null}
    </tr>
  );
};

export default VarietyTableRow;

VarietyTableRow.propTypes = {
  row: PropTypes.object.isRequired,
  farmProperty: PropTypes.string,
  geographyProperty: PropTypes.string,
  dateProperty: PropTypes.string,
  category: PropTypes.string.isRequired,
  lastVariety: PropTypes.bool.isRequired,
};

import React, { useEffect, useState, useRef } from "react";
import * as L from "leaflet";
import * as turf from "@turf/turf";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "leaflet-path-transform";
import { OpenStreetMapProvider } from "leaflet-geosearch";
import { exists } from "../../utils/helpers";
import { fieldToolTip } from './MapFunctions/helpers'
import { Tiles } from '../../constants/Tiles'

export function DigsImage({ field }) {

  const [map, setMap] = useState(null);
  const fieldLayer = useRef(L.featureGroup(null));

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (exists(field) && map !== null) {
      drawField(field);
    }
  }, [field, map]);

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map("digs-image-map", {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    setMap(map);
    map.addLayer(fieldLayer.current);
  }

  const drawField = (field) => {
    try {
      const features = JSON.parse(field.boundary);
      const geo = {
        type: "FeatureCollection",
        features: [features],
      };
      // remove any fields currently on layer
      fieldLayer.current.clearLayers();

      // add field to map
      let newCollection = L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          fieldLayer.current.addLayer(layer).setStyle({
            color: "#8e24aa",
            fillColor: "#f3e5f5",
            opacity: 1,
            fillOpacity: 0,
          });

          // Hoverable info for field
          // const toolTip = fieldToolTip(field)
          // layer.bindTooltip(toolTip, {
          //   className: 'leaftletTooltipClass',
          //   permanent: true,
          // })
        },
      }).addTo(map);

      // fit map to field bounds with some padding
      map.fitBounds(newCollection.getBounds(), {padding: [30,30]});
    } catch (err) {
      console.log(err)
    }
  }

  return(
    <div id='digs-image-map' style={{ height: 550, width: 550 }}></div>
  )
}

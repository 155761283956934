// colors for dynamically set css
export const green = '#67b444';
export const darkGreen = '#548235';
export const grey = '#999999';
export const grey2 = '#bfbfbf'; // pretty close to disabled grey
export const lightGrey = '#f3f3f3';
export const offWhite = '#f2f2f2';
export const darkGrey = '#555555';
export const red = '#d32f2f';
export const darkBlue = '#203864';

// text colors
export const textPrimary = '#424242';
export const textSecondary = '#767676';
export const darkText = '#767676';
export const blackText = '#424242';

// DIGS colors
export const lightBlue = '#007cba';
export const greyBlue = '#006ba1';


export const pewter = '#3B3838'
import React from 'react';
import {
  Box, Typography, Select, MenuItem, Link, Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from '../../../styles/select';
import { useWindowDimensions } from '../../../utils/dimensions';
import { blackText } from '../../../styles/colors';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { GddChart } from './GddChart';
import { IntegrationLinks, GetUav } from '../../Shared/ServiceLinks';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * Display Polaris map and data
 * @param {Object} field  Field to display data for
 * @param {Bool} loading Loading status
 * @param {String} errorMessage Message to display to user if do not have data
 * @returns {JSX} Chart
 */
export function GrowingDays({
  field,
  loading,
  gdds,
  years,
  months,
  startYear,
  setStartYear,
  endYear,
  setEndYear,
  month,
  setMonth,
  gdd,
  setGdd,
  data,
  errorMessage,
  precisionField,
  onConnectClick,
}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  return (
    <Box color={blackText} fontWeight={500}>

      <Box m={2}>
        <Box fontSize={18}>
          Seasonal Growing Degree Days
        </Box>

        <Typography style={{ fontSize: '1rem', fontWeight: 500, color: blackText }}>
          Growing degrees days are the cumulative sum of degrees above a specified cutoff value. Default is 50°F, possible values include 32°F and 60°F. The more growing degree days, the hotter the growing season. This graph shows accumulation to date compared to historical norms and is updated daily. Source data obtained from PRISM Climate Group, Oregon State University,
          {' '}
          <Link style={{ textDecoration: 'none' }} href="http://prism.oregonstate.edu" target="_blank" rel="Noreferrer">http://prism.oregonstate.edu</Link>
          , tabulation created now.
        </Typography>
        <Divider />
      </Box>

      <Box display="flex" flexWrap="wrap">

        <Box mx={2}>
          <Box>
            End Year
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={endYear}
            onChange={(e) => setEndYear(e.target.value)}
          >
            {
              years.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x}
                >
                  {x}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box mx={1}>
          <Box>
            Start Year
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={startYear}
            onChange={(e) => setStartYear(e.target.value)}
          >
            {
              years.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x}
                >
                  {x}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box mx={1}>
          <Box>
            Start Month
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            {
              months.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x}
                >
                  {x}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box mx={1}>
          <Box>
            GDD Cutoff
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={gdd}
            onChange={(e) => setGdd(e.target.value)}
          >
            {
              gdds.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x}
                >
                  {x}
                  {' '}
                  Fahrenheit
                </MenuItem>
              ))
            }
          </Select>
        </Box>
      </Box>

      { errorMessage === '' ? (
        <GddChart
          data={data}
          endYear={endYear}
          startYear={startYear}
        />
      ) : (
        <Box m={1} display="flex">
          <Box className={classes.errorMessageBox}>
            {errorMessage}
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="center">
        <Box p={0.5}>
          { !precisionField ? (
            <IntegrationLinks onConnect={onConnectClick} />
          ) : (
            <GetUav direction="row" />
          )}
        </Box>
      </Box>

      {loading && <SpinningLoader />}
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TableRow,
  TableCell,
  TableFooter,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core";
import { COLORS, DOLLAR_FORMAT } from "../../../../../../constants";

const useStyles = makeStyles(() => ({
  cell: {
    color: COLORS.text,
    fontSize: 14,
    fontWeight: "bold",
    border: "none",
    borderTop: `2px solid ${COLORS.green}`,
    paddingTop: 22,
  },
  button: {
    color: COLORS.white,
    backgroundColor: COLORS.green,
    display: "flex",
    alignItems: "left",
    fontSize: 14,
    fontWeight: "bold",
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "uppercase",
    width: "100%",
    lineHeight: 1.36,
  },
}));

/**
 * Renders the summary row of the farm overview table
 * @param {object} values  array of row data
 * @param {array} headerCells  array of objects with functions/information
 * needed to access data in row object
 * @param {array} selectedFields array of selected field data objects
 * @param {object} activeFarm object with farm data
 * @param {string} activeYear the year
 * @param {object} fieldHeaderCells keyed object for the categories for
 * the field report. Being passed down into pdf report
 * @param {array} data  array of objects with data to be passed down into pdf report
 */

const Summary = ({
  headerCells,
  values,
  showVarietyAcreage
}) => {
  const classes = useStyles();
  // console.log("headerCells", headerCells)
  return (
    <TableFooter>
      <TableRow>
        {headerCells.map(
          ({ id }, i) => {
            // console.log("summary id", id)
            // console.log("values.filter(x => x.varietyName === id)", values.filter(x => x.varietyName === id))
            let matchingValue = values.filter(x => x.varietyName === id)
            if(matchingValue.length > 0){
              if(showVarietyAcreage){
                matchingValue = matchingValue.map(x => x.varietyAcres).reduce((a,b) => a+b, 0).toFixed(2) + " Acres"
              }
              else{
                matchingValue = DOLLAR_FORMAT(matchingValue[0].profit) + "/Acre"
              }
            }
            else{
              matchingValue = 0;
            }
            return (
              <TableCell
                key={`table--summary-for-${id}`}
                className={classes.cell}
              >
                {
                  !i ? "Total" : matchingValue
                }
              </TableCell>
            );
          }
        )}
      </TableRow>
    </TableFooter>
  );
};

export default Summary;

Summary.propTypes = {
  values: PropTypes.array.isRequired,
  headerCells: PropTypes.array.isRequired,
};

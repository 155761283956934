import React, { useEffect, useState, useContext } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { range, mean, sum, extent } from "d3-array";
import { format } from "d3-format";
import {
  COLORS,
  FIELD_OPERATION_TYPE,
  ORG_ID,
  MONTHS,
  Nutrients,
  DOLLAR_FORMAT,
  COLOR_RANGE
} from "../../../constants";
import TitleContainer from "./Title";
import {
  fetchFarmOperations,
  fetchFieldsForOrgFarm,
  getDieselPrices,
  fetchProfitMapSummaries,
  fetchBoundaries,
  getStateAndCounty
} from "../../../utils/dataFetchers";
import { exists } from '../../../utils/helpers';
import {
  getNutrientDataForFarm,
  fetchStateAndCountyForFields,
  getFarmReportSummary,
  getChemicalData,
  getMultiYearHarvestData,
  getMultiYearNutrientData,
  getNutrientDataForFields,
  getVarietyBreakdown, 
  getMultiYearVarietyData,
  getPrecipitation, getGDD
} from "../../../utils/farmReportDataFetchers";
import Overview from "./Overview/FarmReportOverview";
import Analytics from "./Analytics/Analytics";
import Spinner from "../../Helpers/Spinner";
import FieldReport from "../FieldReport/FieldReport";
import {
  processData,
  getPrecipMapData,
  getDataForAnalytics,
  formatChemicalData,
  getGddMapData,
} from "../../Helpers/FarmOperation/helper";
import { useDimensions } from "../../../utils";
import { produce } from "immer";
import { FieldContext } from "../../Context/FieldContext";
import * as L from 'leaflet';
import * as wkt from 'terraformer-wkt-parser';
import * as turf from '@turf/turf';

const useStyles = makeStyles(() => ({
  container: ({ mobileView }) => ({
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    padding: 25,
    marginLeft: mobileView ? 0 : 250,
    maxWidth: 1000,
  }),
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  title: {
    color: COLORS.green,
    fontSize: 32,
    fontWeight: "normal",
    lineHeight: 1.34,
  },
}));

/**
 * Renders the farm report
 * @param {object} activeFarm object with farm data
 * @param {string} activeYear the year
 * @param {array} views array of objects with id, label and active properties
 * @param {function} setViews set state function to switch between overview and analytics
 * @param {boolean} mobileView for mobile views
 * @param {Int} ORG_ID id for currently selected organization
 * @param {Object} selectedOrg object containing info about current organization
 * @param {function} setIsFieldReport  set state function needed to hide overview/analytics mobile nav bar on field report
 * @param {boolean} areAllActive needed to pass down the state of the select all checkbox
 * @param {function} setAreAllActive  needed to pass down the ability to change the state of the select all checkbox
 * @param {String} message message to display while data is loading
 * @param {Function} setMessage function to update loading display message
 * @param {Boolean} access boolean to determine if user has a subscription for ProfitLayers
 * @param {Function} setAccess function to update access bool value
 * @param {Boolean} processing bool to indicate that data is loading, passed down to Overview component
 * @param {Function} setProcessing function to update processing bool, passed down to Overview component
 * @param {Function} checkAccess function to check if user has access to tool
 * @param {Boolean} noData bool to indicate that there is no data for the farm, passed down to the Overview component
 * @param {Function} setNoData function to update the noData bool value
 * @param {Boolean} fromProfitLayers bool to indicate if user navigated from profitlayers tool (not currently in use)
 * @param {Array} allFieldsInfo array containing all fields for the currently selected farm
 * @param {String} reportType string indicating report type
 * @param {Object} user user object from the userContext
 */

const FarmReport = ({
  activeFarm,
  activeYear,
  views,
  setViews,
  mobileView,
  setIsFieldReport,
  ORG_ID,
  selectedOrg,
  areAllActive,
  setAreAllActive,
  message,
  setMessage,
  access,
  setAccess,
  processing,
  setProcessing,
  checkAccess,
  noData,
  setNoData,
  fromProfitLayers,
  allFieldsInfo,
  reportType,
  user
}) => {
  const classes = useStyles({ mobileView });

  const { width, height } = useDimensions();

  const [tableControls, setTableControls] = useState({
    showHeatmap: true,
    showAdvancedControls: false,
  });
  const [fields, setFields] = useState([]);
  const [data, setData] = useState({});
  const [selectedCropData, setSelectedCropData] = useState([]);
  const [fieldData, setFieldData] = useContext(FieldContext);

  // Get active view
  const activeView = views?.find((view) => view.active);

  // to store formatted data of operations
  const [seedMap, setSeedMap] = useState(null);
  const [harMap, setHarMap] = useState(null);
  const [appMap, setAppMap] = useState(null);
  const [fpMap, setfpMap] = useState(null);
  const [otherCostMap, setOtherCostMap] = useState(null);
  const [otherRevMap, setOtherRevMap] = useState(null);
  const [cropNames, setCropNames] = useState(null);
  const [chemicalMap, setChemicalMap] = useState(null);

  const [precipitationData, setPrecipitationData] = useState(null);
  const [gddData, setGddData] = useState(null);
  const [nutrientInfo, setNutrientInfo] = useState(null);
  const [dieselPricesList, setDieselPrices] = useState([]);

  const [multiYearHarvestData, setMultiYearHarData] = useState(null)
  const [multiYearNutrientData, setMultiYearNutrientData] = useState(null)
  const [precipMultiYears, setPrecipMultiYears] = useState(null)
  const [gddForMultiYears, setGddMultiYears] = useState(null);
  const [multiYears, setMultiYears] = useState(null)
  const [multiYearCrops, setMultiYearCrops] = useState(null)
  const [multiYearVarietyData, setMultiYearVarietyData] = useState([])
  const [multiYearVarietyProfits, setMultiYearVarietyProfits] = useState({
    profitByVariety: {},
    missingFields: [],
    profitByVarietyWithField: []
  })
  const [loading, setLoading] = useState(false)
  //const [allFieldsInfo, setAllFields] = useState(null)

  const [profitSummaries, setProfitSummaries] = useState([])

  const [checked, setChecked] = useState(false);
  const [nutrientRate, setNutrientRate] = useState(false)

  const [nutrientMap, setNutrientMap] = useState(null)

  const [selectedColumns, setSelectedColumns] = useState([]);

  const [originalFields, setOriginalFields] = useState([])

  const [fieldBoundaries, setFieldBoundaries] = useState([]);

  //Props used by profitbality matrix
  const [state, setState] = useState({
    data: [],
    metadata: {},
  });
  const [isLoaded, setIsLoaded] = useState(false);
  const [reload, setReload] = useState(true);
  const [initialLoad, setInitialLoad] = useState(true)
  const [fieldIds, setFieldsIds] = useState([])

  // Farm Breakdown
  const [metrics, setMetrics] = useState({
    profit: 0,
    acres: 0,
  });

  const [fbstate, setFBState] = useState({
    cropTypes: {
      label: "Crop type",
      values: [],
    },
    cropVarieties: {
      label: "Crop variety",
      values: [],
      data: [],
    },
    chemicalInputs: {
      label: "Chemical input",
      values: [],
      data: [],
    },
  });

  const [cropInitialLoad, setCropInitialLoad] = useState(true);
  const [varietyInitialLoad, setVarietyInitialLoad] = useState(true);
  const [chemInitialLoad, setChemInitialLoad] = useState(true);
  const [show, setShow] = useState(false)

  // Variety Breakdown
  const [profitByVariety, setProfitByVariety] = useState({
    profitByVariety: {},
    missingFields: [],
    profitByVarietyWithField: []
  })
  const [tableControlsVariety, setTableControlsVariety] = useState({
    showHeatmap: true,
    showAdvancedControls: false,
  });

  //show map vs table
  const [showMap, setShowMap] = useState(false)

  const [categories, setCategories] = useState([
    { name: "Harvest", active: true },
    { name: "Seeding", active: false },
    { name: "Chemicals", active: false },
    { name: "Field Passes", active: false },
    { name: "Other Costs", active: false },
    { name: "Other Revenue", active: false },
  ]);

  const [fieldCategoryData] = useState(
    categories.map((category) => ({
      category: category.name,
      values: [
        {
          variety: "SOYBEANS Dairyland 1526",
          acres: 2.49,
          seedsPerAcre: 150.1,
          seedsPerAcreUnit: "/Acre",
          seedsPrice: 0.3146,
          seedsPriceUnit: " K/Seeds",
          seedCost: 0.4546,
          seedCostUnit: "/Acre",
        },
        {
          variety: "SOYBEANS Dairyland 1526",
          acres: 3.49,
          seedsPerAcre: 170.1,
          seedsPerAcreUnit: "/Acre",
          seedsPrice: 0.2146,
          seedsPriceUnit: " K/Seeds",
          seedCost: 0.5546,
          seedCostUnit: "/Acre",
        },
      ],
    }))
  );

  useEffect(() => {
    (async () => {
      setData({});
      setInitialLoad(true)
    })();
  }, [activeYear, activeFarm.id]);

  useEffect(() => {
    loadFarmData()  
  }, [activeYear, activeFarm.id]);  

  /**
   * Get all fields boundaries for the currently selected farm
   * triggered by selecting a farm from the side menu
   */
  useEffect(() => {
    (async () => {
      let boundaries = await fetchBoundaries(ORG_ID, activeFarm.id)
      const cleanedFields = [];

      if (exists(boundaries)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of boundaries) {
          const cleanedField = await cleanField(field, ORG_ID, selectedOrg.name, activeFarm.id, activeFarm.name,allFieldsInfo);
          cleanedFields.push(cleanedField);
        }
        cleanedFields.sort((a, b) => a.name.localeCompare(b.name));
      }

      // console.log("cleanedFields", cleanedFields)     
      setFieldBoundaries(cleanedFields);
    })();
  }, [activeFarm.id])

  useEffect(() => {
    (async () => {
      setIsLoaded(false);
      if (activeFarm.id !== undefined && reload) {
        setMessage("Fetching Profit Layers Summaries...");
        const farms = await fetchProfitMapSummaries(ORG_ID, activeFarm.id);
        //console.log("farms", farms)
        setState(
          produce((draft) => {
            draft.data = farms?.data || [];
            draft.metadata = farms?.metadata || {};
          })
        );

        let farmReportInfo = await getFarmReportSummary(
          ORG_ID,
          activeFarm.id,
          activeYear
        );
        farmReportInfo = JSON.parse(farmReportInfo);

        let fieldsClone = [...fields];
        for(let f of fieldsClone){
          let farmReport = farmReportInfo.filter((d) => d.FieldID == f.id);

          if (farmReport.length > 0) {
            f.percentProfitable = farmReport[0].Profitable_Percent;
            f.unprofitablePercent = farmReport[0].UnProfitable_Percent;
          }
        }
       
        setFields(fieldsClone);
        setOriginalFields(fieldsClone);
        setReload(false);
      }
      setIsLoaded(true);
    })();
  }, [ORG_ID, activeFarm, reload]);

  // Did the user select a field, i.e. should we show the field detail page
  const selectedField = fields.find((field) => field.selected);
  useEffect(() => {
    setIsFieldReport(!!selectedField);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedField]);

  /**
   * Function to load in and format all data needed for the Farm Report
   * Called on initial load of the FarmReport component and by the FieldReport component when a price is saved through the price converter
   * @param {Boolean} refreshPrices bool to determine how operation prices are queried, if true uses ProductPrices table values over ProfitMapEntries
   */
  const loadFarmData = async (refreshPrices = false) => { //likely will need to pass this to fieldReport to reload operations on price converter save.
    if (access) {
      const types = Object.values(FIELD_OPERATION_TYPE); //.slice(0, 1); // remove slice when done testing
      setMessage("Fetching operations data for farm: " + activeFarm.name);
      const results = await Promise.all(
        types.map(
          async (type) =>
            await fetchFarmOperations(ORG_ID, activeYear, activeFarm.id, type, refreshPrices)
        )
      );
      // console.log("results", results);

      //Step 1: Use helper functions to sort each operation data for each field and store using map
      //Step 2: Convert seeding, harvest data similar to one used in profitlayers
      //Step 3: Combine all each field data for all ops
      //Step 4: Perform calculations to get revenue, expenses and profit

      //API call to get diesel prices --> can be removed after moving code to ProfitLayers folder and pass dieselPrices from ProfitLayers.js
      setMessage("Fetching diesel prices for farm: " + activeFarm.name);
      const dieselPrices = await getDieselPrices(activeYear);
      setDieselPrices(dieselPrices);       

      //API call to get profitmap summaries of selected field
      let profitMapSummaries = await fetchProfitMapSummaries(ORG_ID, activeFarm.id);
      // console.log(profitMapSummaries)
      setProfitSummaries(profitMapSummaries);

      setState(
        produce((draft) => {
          draft.data = profitMapSummaries?.data || [];
          draft.metadata = profitMapSummaries?.metadata || {};
        })
      );
      setReload(false);
      //API call to get state and county values, associtated to fields in the selected farm
      let stateAndCounty = await fetchStateAndCountyForFields(
        ORG_ID,
        activeFarm.id
      );
      stateAndCounty = JSON.parse(stateAndCounty);

      setMessage("Formatting data to display...");
      let [seed, har, app, generic, tillage] = await processData(
        results,
        activeYear,
        dieselPrices,
        allFieldsInfo,
        setMessage
      );

      let updatedResults = [];


      let farmReportInfo = await getFarmReportSummary(
        ORG_ID,
        activeFarm.id,
        activeYear
      );
      farmReportInfo = JSON.parse(farmReportInfo);

      //API call to get variety breakdown
      setMessage("Fetching profit by variety information...");
      let varietyProfits = await getVarietyBreakdown(ORG_ID, [activeYear], allFieldsInfo.map(x => x.FieldID))
      setProfitByVariety(varietyProfits)

      //Function that returns array of object, crops that will be used in overview table and analytics tab
      const [
        updatedData,
        seedingOps,
        harvestOps,
        appOps,
        fieldPassOps,
        otherCostOps,
        otherRevOps,
        crops,
      ] = await getDataForAnalytics(
        allFieldsInfo,
        seed,
        har,
        app,
        generic,
        tillage,
        profitMapSummaries,
        activeYear,
        MONTHS,
        dieselPrices,
        farmReportInfo
      );

      //console.log("fieldOpInfo", fieldOpInfo);
      updatedResults.push({ data: updatedData });
      setSeedMap(seedingOps);
      setHarMap(harvestOps);
      setAppMap(appOps);
      setfpMap(fieldPassOps);
      setOtherCostMap(otherCostOps);
      setOtherRevMap(otherRevOps);

      let cropsForDD = [...new Set(crops)].sort();
      let selectedCrop = "";
      let crpData = [];
      for (let c of cropsForDD) {
        crpData.push({
          id: c,
          active: false,
        });
      }
      if (crpData.length > 0) {
        selectedCrop = crpData[0].id;
        crpData[0].active = true;
      }

      setCropNames(crpData);

      // Parse data and get unique fields
      let data = {};
      let fields = [];
      updatedResults.forEach((resultSet, i) => {
        data[types[i]] = resultSet;
        //console.log('resultSet', resultSet)

        if (resultSet.data !== undefined) {
          resultSet.data.forEach((row, j) => {
            // Get all unique fields
            if (
              fields.findIndex((field) => field.id === row.fieldID) === -1
            ) {
              fields.push({
                id: row.fieldID,
                name: row.fieldName,
                orgId: row.organizationID,
                state: row.state,
                county: row.county,
                acres: row.acres, //Math.random() * 100, // calculate acres for this field
                percentProfitable: row.percentProfitable, // calculate percent profitability for this field
                unprofitablePercent: row.unprofitablePercent,
                active: row.active, //false, // show we include this field in the detailed report
                selected: row.selected, //false, // show we be showing the field view for this field
                expenses: row.expenses, //Math.random() * 1000, // calculate total expenses for the field
                revenue: row.revenue, //Math.random() * 1000, // calculate total revenue for the field
                profit: row.profit, //Math.random() * 1000, // calculate total profit for the field
                primaryCrop: row.primaryCrop, //j % 2 ? "Corn" : "Soybeans",
                cropYield: row.cropYield,
                harvest: row.harvest,
                seeding: row.seeding,
                chemicals: row.chemicals,
                fieldPasses: row.fieldPasses,
                otherCosts: row.otherCosts,
                otherRevenues: row.otherRevenues,
                varieties: row.varieties.map((g) => {
                  return {
                    name: g.name, // get the unique variety names
                    y: g.varietyYield, // calculate x-variable for the x-axis of the analytics charts
                    x: 0, // calculate y-variable for the x-axis of the analytics charts
                  };
                }),
                crops: row.crops,
                noData: row.noData,
                varietyNames: row.varietyBreakdown,
                year: activeYear, 
              });
            }
          });
        }
      });

      // console.log("fields", fields);

      // console.log("data", data);
      setOriginalFields(fields)
      setFields(fields); //used for farm table and analytics graph
      setData(data); //used for not known yet

      getMultiYearHarData();
      getMultiYearNutrientInfo();
      //getNutrientInfo();
      getChemicalInfo();
      getNutrientRates();
      //getMultiYearVarietyProfit();
    }
  } 

  /**
   * Gets precipitation data from backend for the farm's fields
   * @param {Array} boundaries array of boundary objects (results of the useEffect tracking activeFarm.id)
   * @param {Array} years array of integer year values
   * @param {Array} months array of integer month values
   */
  const getPrecipitationForFields = async(boundaries, years, months) => {
    try{
      let map = new Map()

      setLoading(true)
      for(let f of boundaries){
        let startMonth, endMonth;

        for(let i=0; i<months.length; i++){
          if(months[i].active == true){
            startMonth = i+1;
            break;
          }
        }

        for(let i=months.length-1; i>=0; i--){
          if(months[i].active == true){
            endMonth = i;
            break;
          }
        }

        let res = await getPrecipitation(f.longitude, f.latitude, years, startMonth, endMonth)
        res = JSON.parse(res)

        map.set(f.id, res)
      }
      setPrecipitationData(map)
      setLoading(false)
      //console.log("map", map)
    }
    catch(err){
      console.log(err)
    }
  }

  /**
   * Gets growing degree day data from backend for the farm's fields
   * @param {Array} boundaries array of boundary objects (results of the useEffect tracking activeFarm.id)
   * @param {Array} years array of integer year values
   * @param {Array} months array of integer month values
   */
  const getGDDForFields = async(boundaries, years, months) => {
    try{
      let map = new Map()

      setLoading(true);
      for(let f of boundaries){
        let startMonth, endMonth;

        for(let i=0; i<months.length; i++){
          if(months[i].active == true){
            startMonth = i+1;
            break;
          }
        }

        for(let i=months.length-1; i>=0; i--){
          if(months[i].active == true){
            endMonth = i;
            break;
          }
        }

        let res = await getGDD(f.longitude, f.latitude, years, startMonth, endMonth)
        res = JSON.parse(res)

        map.set(f.id, res)
      }
      setGddData(map)
      setLoading(false)
      //console.log("map", map)
    }
    catch(err){
      console.log(err)
    }
  }

  const getAllFields = async (orgId, farmId) => {
    try {
      let res = await fetchFieldsForOrgFarm(orgId, farmId);
      return res;
    } catch (err) { }
  };

  /**
   * Takes field data from database and adds additional needed information.
   * @param {Object} data Field info returned from controller
   * @param {String} orgId Organization ID
   * @param {String} orgName Organization Name
   * @param {String} farmId Farm ID
   * @param {String} farmName Name of field's farm
   * @param {Array} farmsFields Fields associated with farm
   * @param {Bool} getLocation If state and county data is needed for field
   * @return{Object} Field with all data needed in other components
   */
  const cleanField = async (data, orgId, orgName, farmId, farmName, farmsFields, getLocation) => {
    if (data === undefined) {
      return {
        id: '',
        name: '',
        orgId: '',
        org: '',
        farmId: '',
        farm: '',
        state: '',
        county: '',
        acres: 0,
        latitude: 0,
        longitude: 0,
        coordinates: [{ type: '', coordinates: [] }],
        points: [],
        source: '',
        boundaryId: '',
      };
    }

    try {
      const geojson = L.GeoJSON.geometryToLayer(wkt.parse(data.Shape)).toGeoJSON();

      const featureCollection = {
        type: 'FeatureCollection',
        features: [geojson],
      };

      const coordinates = geojson?.geometry;
      const points = geojson?.geometry?.coordinates;

      const centroid = turf.centroid(featureCollection);
      const centerPoints = centroid.geometry.coordinates;

      const [state, county] = await getStateAndCounty(centerPoints[1], centerPoints[0]);

      // if acres are null (all climate fields) calculate from boundary coordinates
      const acres = data.Area !== null ? data.Area : turf.convertArea(turf.area(featureCollection), 'meters', 'acres');

      let org = selectedOrg.name;

      let name = '';

      const fieldsToMatch = farmsFields;

      if (data.FieldName !== undefined) {
        name = data.FieldName;
      } else {
        const matchingField = fieldsToMatch.filter((x) => x.FieldID === data.FieldID);
        if (matchingField.length > 0) {
          name = matchingField[0].FieldName;
        }
      }

      const field = {
        acres,
        centroid,
        county,
        coordinates,
        farmId,
        geojson,
        name,
        orgId,
        org: orgName,
        points,
        state,
        id: data.FieldID,
        farm: farmName,
        latitude: centerPoints[1],
        longitude: centerPoints[0],
        boundary: JSON.stringify(geojson),
        source: data.Source,
        shape: data.Shape,
        boundaryId: data.BoundaryID,
      };
      return field;
    } catch (err) {
      console.error(`problem cleaning field ${err}`, data);
      return {
        id: '',
        name: '',
        orgId: '',
        org: '',
        farmId: '',
        farm: '',
        state: '',
        county: '',
        acres: 0,
        latitude: 0,
        longitude: 0,
        coordinates: [{ type: '', coordinates: [] }],
        points: [],
        source: '',
        boundaryId: '',
      };
    }
  };

  /**
   * Gets all harvest data for the currently selected farm
   */
  const getMultiYearHarData = async () => {
    try {
      let res = await getMultiYearHarvestData(ORG_ID, activeFarm.id);
      let varietyRes = await getMultiYearVarietyData(ORG_ID, activeFarm.id)
      res = JSON.parse(res);
      //console.log("res", res);
      let years = [...new Set(res.map(x => x.CropSeason))]
      setMultiYears(years.sort((a, b) => a - b))
      getMultiYearVarietyProfit(years)
      getNutrientInfo(years)

      let cropsForDD = [...new Set(res.map(x => x.CropName))].sort();
      let selectedCrop = "";
      let crpData = [];
      for (let c of cropsForDD) {
        crpData.push({
          id: c,
          active: false,
        });
      }
      if (crpData.length > 0) {
        selectedCrop = crpData[0].id;
        crpData[0].active = true;
      }

      setMultiYearCrops(crpData);
      
      setMultiYearHarData(res);
      setMultiYearVarietyData(JSON.parse(varietyRes))
    }
    catch (err) {
      console.log("error in funtion getMultiYearHarData()", err)
    }
  }

  /**
   * gets all nutrient data for the currently selected farm
   */
  const getMultiYearNutrientInfo = async () => {
    try {
      let res = await getMultiYearNutrientData(ORG_ID, activeFarm.id);
      res = JSON.parse(res);
      setMultiYearNutrientData(res);
    }
    catch (err) {
      console.log("error in funtion getMultiYearNutrientInfo()", err)
    }
  }

  /**
   * Gets nutrient data for the defined years
   * @param {Array} yearsList array of integer year values ie [2020,2021]
   */
  const getNutrientInfo = async (yearsList) => {
    try {
      let nutrientData = new Map();
      
      let res = await getNutrientDataForFarm(
        ORG_ID,
        activeFarm.id,
        yearsList,
        Nutrients
      );
      res = JSON.parse(res)
      /* Get nutrient data for farm Start*/
      for (let n of Nutrients) {
        let parsedRes = res.filter(x => x.NutrientAlias.toUpperCase() === n.toUpperCase())
        nutrientData.set(n, parsedRes);
      }
      setNutrientInfo(nutrientData);
      /* Get nutrient data end */
    }
    catch (err) {
      console.log("getNutrientInfo error", err)
    }
  }

  /**
   * Send request to backend to get variety profit breakdown for multiple years
   * @param {Array} yearsList array of integer year values
   */
  const getMultiYearVarietyProfit = async (yearsList) => {
    try {      
      let res = await getVarietyBreakdown(
        ORG_ID,
        yearsList,
        allFieldsInfo.map(x => x.FieldID)
      );
      //console.log("setMultiYearVarietyProfits", res)
      setMultiYearVarietyProfits(res);
    }
    catch (err) {
      console.log("getNutrientInfo error", err)
    }
  }

  const getChemicalInfo = async () => {
    try {

      /* Get chemical data for farm Start*/
      let chemicalRes = await getChemicalData(
        ORG_ID,
        activeFarm.id,
        activeYear
      );
      let chemMap = formatChemicalData(JSON.parse(chemicalRes));
      setChemicalMap(chemMap);
      /* Get chemical data end */
    }
    catch (err) {
      console.log("getChemicalInfo error", err)
    }
  }

  const getNutrientRates = async () => {
    try {

      /* Get chemical data for farm Start*/
      let nutrientRes = await getNutrientDataForFields(
        ORG_ID,
        activeFarm.id,
        activeYear
      );
      let nutriMap = formatChemicalData(JSON.parse(nutrientRes));
      setNutrientMap(nutriMap);
      /* Get chemical data end */
    }
    catch (err) {
      console.log("getNutrientRates error", err)
    }
  }

  /**
   * Function for calulating the correct acreage weighted cost/profit value for seeding and harvest operations
   * @param {Array} values array of operation objects
   * @param {Function} accessor function to get defined accessor value from object (see the fieldHeaderCells object defined below)
   * @returns Number value
   */
  const acreageWeightedCalc = (values, accessor) => {
    let totalAcres = values.map((x) => x.acres).reduce((a, b) => (a + b), 0);
    let quantity = 0;
    let price = 0;
    let costPerAcre = 0;
    let type;

    values.forEach((value) =>{
      type = Object.keys(value).find(key => value[key] === accessor(value));
      const percent = value.acres / totalAcres;
      quantity += value.seedsPerAcre * percent;
      const opPrice = value.seedsPrice * percent;
      const opCostPerAcre = value.seedCost * percent;
      if (!(Number.isNaN(opPrice))) {
        price += opPrice;
      }
      if (!Number.isNaN(opCostPerAcre)) {
        costPerAcre += opCostPerAcre;
      }
    })

    if(type === 'seedsPrice'){
      return price
    }
    else{
      return costPerAcre
    }

  }

  /**
   * Function for calculating the correct cost/profit value for other Cost and Revenue operations
   * @param {Array} values array of operation objects
   * @param {Function} accessor function to get defined accessor value from object (see the fieldHeaderCells object defined below)
   * @returns 
   */
  const otherOperationsCostCalc = (values, accessor) => {
    let totalCost = values.map(x => x.seedsPrice).reduce((a,b) => a+b, 0);
    let price = 0;
    if(values.length > 0){
      price = totalCost / values[0].fieldAcres
    }
    return price
  }

  // Get the header cells for field report --
  // putting them here as they need to go down to both the field report and
  // into the farm report in order to generate the pdf report
  const fieldHeaderCells = {
    Harvest: [
      {
        heading: "Variety",
        id: "variety",
        accessor: (d) => d.variety,
        format: null,
        aggregateFunction: null,
        unit: null,
      },
      {
        heading: "Acres",
        id: "acres",
        accessor: (d) => d.acres,
        format: format(",.2f"),
        aggregateFunction: sum,
        unit: null,
      },
      {
        heading: "Yield",
        id: "yield-per-acre",
        accessor: (d) => d.seedsPerAcre,
        format: format(",.2f"),
        aggregateFunction: mean,
        unit: (d) => " " + d.unit + "/Acre",
      },
      {
        heading: "Cost",
        id: "bushel-price",
        accessor: (d) => d.seedsPrice,
        format: format("$,.2f"),
        aggregateFunction: acreageWeightedCalc,
        unit: (d) => " /" + d.unit,
      },
      {
        heading: "Revenue",
        id: "revenue-per-acre",
        accessor: (d) => d.seedCost,
        format: format("$,.2f"),
        aggregateFunction: acreageWeightedCalc,
        unit: " /Acre",
      },
    ],
    Seeding: [
      {
        heading: "Variety",
        id: "variety",
        accessor: (d) => d.variety,
        format: null,
        aggregateFunction: null,
        unit: null,
      },
      {
        heading: "Acres",
        id: "acres",
        accessor: (d) => d.acres,
        format: format(",.2f"),
        aggregateFunction: sum,
        unit: null,
      },
      {
        heading: "Seeds",
        id: "seeds-per-acre",
        accessor: (d) => d.seedsPerAcre,
        format: format(",.2f"),
        aggregateFunction: mean,
        unit: (d) => " " + d.unit + "/Acre",
      },
      {
        heading: "Seeds Price",
        id: "seeds-price",
        accessor: (d) => d.seedsPrice,
        format: format("$,.4f"),
        aggregateFunction: acreageWeightedCalc,
        unit: (d) => " /" + d.unit,
      },
      {
        heading: "Seed Cost",
        id: "seed-cost",
        accessor: (d) => d.seedCost,
        format: format("$,.2f"),
        aggregateFunction: acreageWeightedCalc,
        unit: " /Acre",
      },
    ],
    Chemicals: !checked
      ? [
        {
          heading: "Name",
          id: "name",
          accessor: (d) => d.variety,
          format: null,
          aggregateFunction: null,
          unit: null,
        },
        {
          heading: "Acres",
          id: "acres",
          accessor: (d) => d.acres,
          format: format(",.2f"),
          aggregateFunction: sum,
          unit: null,
        },
        {
          heading: "Quantity",
          id: "quantity-per-acre",
          accessor: (d) => d.seedsPerAcre,
          format: format(",.2f"),
          aggregateFunction: mean,
          unit: (d) => " " + d.unit + "/Acre",
        },
        {
          heading: "Cost/Unit",
          id: "chemical-price",
          accessor: (d) => d.seedsPrice,
          format: format("$,.2f"),
          aggregateFunction: mean,
          unit: (d) => " /" + d.unit,
        },
        {
          heading: "Cost/Acre",
          id: "chemical-cost-per-acre",
          accessor: (d) => d.seedCost,
          format: format("$,.2f"),
          aggregateFunction: mean,
          unit: " /Acre",
        },
      ] :
      !nutrientRate ? [
        {
          heading: "Operation Name",
          id: "operationName",
          accessor: (d) => d.operationName,
          format: null,
          aggregateFunction: null,
          unit: null,
        },
        {
          heading: "Chemical Name",
          id: "chemName",
          accessor: (d) => d.Chemical,
          format: null,
          aggregateFunction: null,
          unit: null,
        },
        {
          heading: "Product Name",
          id: "prodName",
          accessor: (d) => d.OriginalProduct,
          format: null,
          aggregateFunction: null,
          unit: null,
        },
        {
          heading: "Chemical Rate",
          id: "chemRate",
          accessor: (d) => d.ChemicalRate,
          format: format(",.5f"),
          aggregateFunction: null,
          unit: " lbs/Acre",
        },
      ] : [
        {
          heading: "Operation Name",
          id: "operationName",
          accessor: (d) => d.operationName,
          format: null,
          aggregateFunction: null,
          unit: null,
        },
        {
          heading: "Nutrient Name",
          id: "nutrientName",
          accessor: (d) => d.NutrientAlias,
          format: null,
          aggregateFunction: null,
          unit: null,
        },
        {
          heading: "Product Name",
          id: "prodName",
          accessor: (d) => d.OriginalProduct,
          format: null,
          aggregateFunction: null,
          unit: null,
        },
        {
          heading: "Chemical Rate",
          id: "chemRate",
          accessor: (d) => d.NutrientRate,
          format: format(",.5f"),
          aggregateFunction: null,
          unit: " lbs/Acre",
        },
      ],
    "Field Passes": [
      {
        heading: "Description",
        id: "description",
        accessor: (d) => d.variety,
        format: null,
        aggregateFunction: null,
        unit: null,
      },
      {
        heading: "Acres",
        id: "acres",
        accessor: (d) => d.acres,
        format: format(",.2f"),
        aggregateFunction: sum,
        unit: null,
      },
      {
        heading: "Cost",
        id: "cost-per-acre",
        accessor: (d) => d.seedsPerAcre,
        format: format("$,.2f"),
        aggregateFunction: otherOperationsCostCalc,
        unit: " /Acre Applied",
      },
      {
        heading: "Total Cost",
        id: "total-cost",
        accessor: (d) => d.seedsPrice,
        format: format("$,.2f"),
        aggregateFunction: sum,
        unit: null,
      },
    ],
    "Other Costs": [
      {
        heading: "Description",
        id: "description",
        accessor: (d) => d.variety,
        format: null,
        aggregateFunction: null,
        unit: null,
      },
      {
        heading: "Acres",
        id: "acres",
        accessor: (d) => d.acres,
        format: format(",.2f"),
        aggregateFunction: sum,
        unit: null,
      },
      {
        heading: "Cost",
        id: "cost-per-acre",
        accessor: (d) => d.seedsPerAcre,
        format: format("$,.2f"),
        aggregateFunction: otherOperationsCostCalc,
        unit: " /Acre Applied",
      },
      {
        heading: "Total Cost",
        id: "total-cost",
        accessor: (d) => d.seedsPrice,
        format: format("$,.2f"),
        aggregateFunction: sum,
        unit: null,
      },
    ],
    "Other Revenue": [
      {
        heading: "Description",
        id: "variety",
        accessor: (d) => d.variety,
        format: null,
        aggregateFunction: null,
        unit: null,
      },
      {
        heading: "Acres",
        id: "acres",
        accessor: (d) => d.acres,
        format: format(",.2f"),
        aggregateFunction: sum,
        unit: null,
      },
      {
        heading: "Cost",
        id: "cost-per-acre",
        accessor: (d) => d.seedsPerAcre,
        format: format("$,.2f"),
        aggregateFunction: otherOperationsCostCalc,
        unit: "/Acre Applied",
      },
      {
        heading: "Total Cost",
        id: "total-cost",
        accessor: (d) => d.seedsPrice,
        format: format("$,.2f"),
        aggregateFunction: sum,
        unit: null,
      },
    ],
  };

  return (
    <div style={{ height: height - 195, overflow: "auto" }}>
      {selectedField === undefined ? (
        <>
          {Object.keys(data).length ? (
            <div className={classes.container}>
              <TitleContainer
                activeFarm={activeFarm}
                views={views}
                setViews={setViews}
                activeYear={activeYear}
                mobileView={mobileView}
                ORG_ID={ORG_ID}
              />
              {activeView.id === "overview" ? (
                <Overview
                  activeFarm={activeFarm}
                  fields={fields}
                  setFields={setFields}
                  activeYear={activeYear}
                  tableControls={tableControls}
                  setTableControls={setTableControls}
                  mobileView={mobileView}
                  ORG_ID={ORG_ID}
                  dieselPrices={dieselPricesList}
                  seedMap={seedMap}
                  harMap={harMap}
                  appMap={appMap}
                  fpMap={fpMap}
                  otherCostMap={otherCostMap}
                  otherRevMap={otherRevMap}
                  fieldHeaderCells={fieldHeaderCells}
                  data={fieldCategoryData}
                  selectedOrg={selectedOrg}
                  areAllActive={areAllActive}
                  setAreAllActive={setAreAllActive}
                  message={message}
                  setMessage={setMessage}
                  chemicalMap={chemicalMap}
                  access={access}
                  setAccess={setAccess}
                  processing={processing}
                  setProcessing={setProcessing}
                  checkAccess={checkAccess}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                  state={state}
                  setState={setState}
                  isLoaded={isLoaded}
                  setIsLoaded={setIsLoaded}
                  reload={reload}
                  setReload={setReload}
                  noData={noData}
                  setNoData={setNoData}
                  originalFields={originalFields}
                  initialLoad={initialLoad}
                  setInitialLoad={setInitialLoad}
                  fieldIds={fieldIds}
                  setFieldsIds={setFieldsIds}
                  metrics={metrics}
                  setMetrics={setMetrics}
                  fbstate={fbstate}
                  setFBState={setFBState}
                  cropInitialLoad={cropInitialLoad}
                  setCropInitialLoad={setCropInitialLoad}
                  varietyInitialLoad={varietyInitialLoad}
                  setVarietyInitialLoad={setVarietyInitialLoad}
                  chemInitialLoad={chemInitialLoad}
                  setChemInitialLoad={setChemInitialLoad}
                  show={show}
                  setShow={setShow}
                  profitByVariety={profitByVariety}
                  tableControlsVariety={tableControlsVariety}
                  setTableControlsVariety={setTableControlsVariety}
                  fieldBoundaries={fieldBoundaries}
                  showMap={showMap}
                  setShowMap={setShowMap}
                />
              ) : (
                  <Analytics
                    data={data}
                    activeFarm={activeFarm}
                    fields={fields}
                    setFields={setFields}
                    cropNames={cropNames}
                    selectedCropData={selectedCropData}
                    setSelectedCropData={setSelectedCropData}
                    precipitationData={precipitationData}
                    activeYear={activeYear}
                    nutrientInfo={nutrientInfo}
                    gddData={gddData}
                    multiYearHarvestData={multiYearHarvestData}
                    multiYearNutrientData={multiYearNutrientData}
                    multiYears={multiYears}
                    multiYearCrops={multiYearCrops}
                    allFieldsInfo={allFieldsInfo}
                    profitSummaries={profitSummaries}
                    multiYearVarietyData={multiYearVarietyData}
                    multiYearVarietyProfits={multiYearVarietyProfits}
                    getPrecipitationForFields={getPrecipitationForFields}
                    fieldBoundaries={fieldBoundaries}
                    getGDDForFields={getGDDForFields}
                    loading={loading}
                  />
                )}
            </div>
          ) : (
              <Spinner message={message} />
            )}
        </>
      ) : (
          <FieldReport
            selectedField={selectedField}
            activeYear={activeYear}
            activeFarm={activeFarm}
            fields={fields}
            setFields={setFields}
            seedMap={seedMap}
            harMap={harMap}
            appMap={appMap}
            fpMap={fpMap}
            otherCostMap={otherCostMap}
            otherRevMap={otherRevMap}
            mobileView={mobileView}
            ORG_ID={ORG_ID}
            fieldHeaderCells={fieldHeaderCells}
            data={fieldCategoryData}
            categories={categories}
            setCategories={setCategories}
            chemicalMap={chemicalMap}
            checked={checked}
            setChecked={setChecked}
            nutrientRate={nutrientRate}
            setNutrientRate={setNutrientRate}
            nutrientMap={nutrientMap}
            reload={reload}
            setReload={setReload}
            reportType={reportType}
            user={user}
            loadFarmData={loadFarmData}
          />
        )}
    </div>
  );
};

export default FarmReport;

FarmReport.propTypes = {
  activeFarm: PropTypes.object.isRequired,
  activeYear: PropTypes.string.isRequired,
  views: PropTypes.array.isRequired,
  setViews: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  setIsFieldReport: PropTypes.func.isRequired,
  areAllActive: PropTypes.bool.isRequired,
  setAreAllActive: PropTypes.func.isRequired,
};

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { OperationDisplay } from './OperationDisplay';

/**
 * Displays fields operations
 * @param {Object} clusSeen reference for CLU information
 * @param {Object} commoditiesSeen key:value mappings of commodities seen and their associated commodity types
 * @param {Object} commodityTypesSeen key:value mappings of commodity types seen and their associated intended uses
 * @param {Number} commodityYear Planting year
 * @param {Dates} dates dates to be used for operation
 * @param {Function} editOperationWithClu Helps setup the modal to edit the CLU boundary
 * @param {Number} farmIndex Farms index in ordered mappings
 * @param {Number} fieldIndex Index in farm array
 * @param {Array} fieldInfo Field data
 * @param {String} gartPath Denotes whether this interface instance is for creating GART files
 * @param {String} impersonating email address for user being impersonated by agent
 * @param {Array} mismatchOptions Options for displaying mismatches between CLUs and operation
 * @param {Function} openFileDownload Opens modal for downloading personal files
 * @param {Function} openFileUpload Opens modal for uploading personal files
 * @param {Number} reinsuranceYear Year for submission of acreage report
 * @param {Function} setDates function to change dates
 * @param {Object} statesSeen key:value mappings of states seen and their associated commodities
 * @param {Function} updateIntersection Updates data for a CLU, that intersects with operations
 * @param {Function} updateIntersections Updates which CLUs intersect with operations
 * @param {Function} updateOperation Updates operation
 * @returns {JSX} Field display
 */
export function FieldDisplay({
  clusSeen,
  commoditiesSeen,
  commodityTypesSeen,
  commodityYear,
  dates,
  editOperationWithClu,
  farmIndex,
  fieldIndex,
  fieldInfo,
  gartPath,
  impersonating,
  mismatchOptions,
  openFileDownload,
  openFileUpload,
  reinsuranceYear,
  setDates,
  statesSeen,
  updateIntersection,
  updateIntersections,
  updateOperation,
}) {
  return (
    <Box>
      {fieldInfo[1].map((operation, opIndex) => (
        <Box key={operation[0]+opIndex} display="flex" justifyContent="center">
          <OperationDisplay
            clusSeen={clusSeen}
            commoditiesSeen={commoditiesSeen}
            commodityTypesSeen={commodityTypesSeen}
            commodityYear={commodityYear}
            dates={dates}
            editOperationWithClu={editOperationWithClu}
            farmIndex={farmIndex}
            fieldIndex={fieldIndex}
            gartPath={gartPath}
            impersonating={impersonating}
            mismatchOptions={mismatchOptions}
            openFileDownload={openFileDownload}
            openFileUpload={openFileUpload}
            operationInfo={operation[1]}
            opIndex={opIndex}
            reinsuranceYear={reinsuranceYear}
            setDates={setDates}
            statesSeen={statesSeen}
            updateIntersection={updateIntersection}
            updateIntersections={updateIntersections}
            updateOperation={updateOperation}
          />
        </Box>
      ))}
    </Box>
  );
}

FieldDisplay.propTypes = {
  clusSeen: PropTypes.shape().isRequired,
  commoditiesSeen: PropTypes.shape().isRequired,
  commodityTypesSeen: PropTypes.shape().isRequired,
  commodityYear: PropTypes.number.isRequired,
  dates: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.string,
    )
  ).isRequired,
  editOperationWithClu: PropTypes.func.isRequired,
  farmIndex: PropTypes.number.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  fieldInfo: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired,
    ])
  ).isRequired,
  gartPath: PropTypes.bool.isRequired,
  impersonating: PropTypes.string.isRequired,
  mismatchOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  openFileDownload: PropTypes.func.isRequired,
  openFileUpload: PropTypes.func.isRequired,
  reinsuranceYear: PropTypes.number.isRequired,
  setDates: PropTypes.func.isRequired,
  statesSeen: PropTypes.shape({
    stateAbbr: PropTypes.shape(),
  }).isRequired,
  updateIntersection: PropTypes.func.isRequired,
  updateIntersections: PropTypes.func.isRequired,
  updateOperation: PropTypes.func.isRequired,
};

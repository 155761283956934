export const roundIcon = {
  width: "25px",
  height: "25px",
  fontSize: "14px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: 2,
  borderRadius: "50%",
  marginRight: "10px",
};

export const mobileRoundIcon = {
  width: "20px",
  height: "20px",
  fontSize: "12px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  border: 2,
  borderRadius: "50%",
  marginRight: "6px",
};

import React from "react";
import PropTypes from "prop-types";
import {
  TableRow,
  TableCell,
  TableFooter,
  makeStyles,
} from "@material-ui/core";

import { COLORS } from "../../../../constants";

const useStyles = makeStyles(() => ({
  cell: {
    color: COLORS.text,
    fontSize: 14,
    fontWeight: "bold",
    border: "none",
    borderTop: `2px solid ${COLORS.green}`,
  },
}));

/**
 * Renders the summary row for the field report table
 * @param {array} headerCells  array of objects with metadata functions/information
 * to access row data for fields
 * @param {array} values  array of data objects
 */
const Summary = ({ values, headerCells }) => {
  const classes = useStyles();
  return (
    <TableFooter>
      <TableRow>
        {headerCells.map(({ id, format, accessor, aggregateFunction }, i) => {
          const aggregateValue =
            aggregateFunction && aggregateFunction(values, accessor);
          return (
            <TableCell
              key={`table--summary-for-${id}`}
              className={classes.cell}
            >
              {!i
                ? "Summary:"
                : format
                ? format(aggregateValue)
                : aggregateValue}
            </TableCell>
          );
        })}
      </TableRow>
    </TableFooter>
  );
};

export default Summary;

Summary.propTypes = {
  values: PropTypes.array.isRequired,
  headerCells: PropTypes.array.isRequired,
};

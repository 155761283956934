import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Divider,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as d3 from 'd3';
import d3tip from 'd3-tip';
import * as _ from 'underscore';
// Settings
import { GeneralSettings } from '../Settings/GeneralSettings';
import { QuoteInputs } from '../Settings/QuoteInputs';
import { OptionsBox } from '../Settings/OptionsBox';
import { AdvancedOptions } from '../Settings/AdvancedOptions';
import { AcreSelection } from '../Settings/AcreSelection';
import { RefineEstimates } from '../Settings/RefineEstimates';
// General use and helpers
import { createTable } from '../createInsuranceTable';
import { exists } from '../../../utils/helpers';

// Styles
import { NumberFormatCustom } from '../../../utils/NumberFormatCustom';
import {
  blackText, darkText, grey, green,
} from '../../../styles/colors';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  input: {
    width: '120px',
    margin: '0px',
  },
  boxLabel: theme.boxLabel,
  inputName: {
    height: 25,
    whiteSpace: 'nowrap',
  },
  infoIcon: {
    ...theme.infoToolTip,
    marginLeft: '2px',
    marginBottom: '10px',
  },
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * Display Insurance What If Tool. General Settings, Quote Inputs, Insurance
 * Type, and Advanced Options will be available for user interaction in this
 * component through import of aboved named components. The option values are
 * stored in DataLayers (this component's intended parent) and based here as
 * parameters. This component handles additional user selection options from
 * internal function whatIfInputs and selectDataFormat. Based originally on
 * default values that can then be updated by user, display resposne from
 * getRMARates, which is called and stores response in DataLayers. With passed
 * parameters, user can view chart (powered by d3) and tables of their
 * projections.
 * @param {Bool} mobileView If view is under mobile breakpoint
 * @param {Function} handleChange Takes value and string of type to update
 * @param {Function} updateAdvancedOptions Takes key and value to update advancedOptions object
 * @param {Object} advancedOptions Stores advanved options
 * @param {Function} updateInputs Takes key and value to update whatIfInputs object
 * @param {Function} updateMultipleInputs Change multiple what if inputs with on call
 * @param {Object} inputs Stores what if options.
 * @param {Number} acres Defaults to selected fields acres, user can update
 * @param {Number} commodityYear User selected year for data.
 * @param {Array} crops Available crops for dropdown
 * @param {String} crop Selected crop
 * @param {Array} practices Available practices for dropdown
 * @param {Object} practice Selected practice (e.g. { practiceCode: '4', practiceName: 'Non-Irrigated' })
 * @param {Array} types Available types of crop for dropdown
 * @param {Object} type Selected type (e.g. {typeCode: '26', typeName: 'Grain'})
 * @param {String} insuranceType Selected insurance type
 * @param {Function} setInsuranceType Updates insurance type on user selections
 * @param {String} insuranceUnit Selected insurance unit
 * @param {Function} setInsuranceUnit Updates insurance unit on user selections
 * @param {Number} approvedYield Defaults to 118, updated by getYield in getValues
 * @param {Number} projectedPrice Defaults 3.96, updated by getGetPriceAndVolatility
 * @param {Number} acres Defaults to selected fields acres
 * @param {Bool} allAcres Use all acres or per acre values
 * @param {Function} setAllAcres Updated from radio control
 * @param {Array} liability Projected liabiliies returned from getRMARates
 * @param {Array} liabilityPerAcre Projected liabilities per acre returned from getRMARates
 * @param {Object} subsidizedPremium Projected premiums  returned from getRMARates
 * @param {Object} subsidizedPremiumAllAcres Projected premiums returned from getRMARates
 * @param {Array} yields Yields used for initial values Refine Estimates
 * @param {Function} setYields Used in RefineEstimates on user input
 * @param {Function} updateYields Updates actual and rate yield from response from RefineEstimates
 * @param {Function} getInputAndSendRequest Recalculate insurance tool values and calls RMA rates based on user input (initially default values)
 * @param {Array} exclusionYears Used in RefineEstimates
 * @param {Bool} error True if getRMARates get's an invalid response
 * @param {String} locationErrorMessage Message to display to user in un-supported location
 * @param {String} source to know page source (DataLayers/ProfitLayers)
 * @param {Function} enqueueSnackbar
 * @param {Bool} disableUserInputsOnLoad
 * @param {Array} errorMessage
 * @param {Number} previousDefaultRevenue value used to notice if there was a new call to getrmarates with differing liability values
 * @param {Function} setPreviousDefaultRevenue Used to set previousDefaultRevenue
 */
export function WhatIfAnalysis({
  mobileView,
  handleChange,
  updateAdvancedOptions,
  advancedOptions,
  updateInputs,
  updateMultipleInputs,
  inputs,
  acres,
  commodityYear,
  crops,
  crop,
  practices,
  practice,
  types,
  type,
  insuranceType,
  setInsuranceType,
  insuranceUnit,
  setInsuranceUnit,
  approvedYield,
  projectedPrice,
  allAcres,
  setAllAcres,
  liability,
  liabilityPerAcre,
  subsidizedPremium,
  subsidizedPremiumAllAcres,
  guarantee,
  yields,
  setYields,
  updateYields,
  getInputAndSendRequest,
  exclusionYears,
  getCalculateYieldFromHistoryData,
  error,
  source,
  harvestRevenue,
  totalCosts,
  yieldFromOps,
  setYieldFromOps,
  setHistoryYields,
  historyYields,
  totalProfit,
  otherRevenue,
  setIndemnityPerAcre,
  coverageLevel,
  setPremiumPerAcre,
  locationErrorMessage,
  enqueueSnackbar,
  disableUserInputsOnLoad,
  errorMessage,
  previousDefaultRevenue,
  setPreviousDefaultRevenue,
}) {
  const classes = useStyles();
  // console.log('yieldFromOps', yieldFromOps)
  const [graphs, setGraphs] = useState(true);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [refiningEstimates, setRefiningEstimates] = useState(false);
  const [showMessage, setShowMessage] = useState(false);
  const [tableInput, setTableInput] = useState('YP_Basic');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [disableUserInputs, setDisableUserInputs] = useState(false);

  // Figures out if there is an errorMessage and whether it applies to this tool
  useEffect(() => {
    // Should always have the tools it's meant for, the error message, and what to disable
    if (errorMessage.length > 2 && (errorMessage[0] === 'WhatIf' || errorMessage[0] === 'All')) {
      setShowErrorMessage(true);
      setDisableUserInputs(errorMessage[2]);
    } else {
      setShowErrorMessage(false);
      setDisableUserInputs(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (exists([
      insuranceType,
      insuranceUnit,
      liability,
      liabilityPerAcre,
      subsidizedPremium,
      subsidizedPremiumAllAcres,
      graphs,
    ])) {
      try {
        drawGraph();
        if (source.toUpperCase() === 'PROFITLAYERS') {
          drawNetInsuredGraph();
        } else {
          drawuprightbarchart();
        }
      } catch (err) {
        // console.log(err);
      }
    }
  }, [
    allAcres,
    insuranceType,
    insuranceUnit,
    liability,
    liabilityPerAcre,
    subsidizedPremium,
    subsidizedPremiumAllAcres,
    graphs,
  ]);

  useEffect(() => {
    determineTableInput(insuranceUnit, insuranceType);
  }, [insuranceUnit, insuranceType]);

  const toggleOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  const determineTableInput = (unit, insurance) => {
    const insuranceAbbreviaton = insurance === 'Yield Protection' ? 'YP' : insurance === 'Revenue Protection' ? 'RP' : 'RPHPE';
    const key = `${insuranceAbbreviaton}_${unit}`;
    setTableInput(key);
  };

  const tableDisplay = () => (
    allAcres ? (
      exists(subsidizedPremiumAllAcres[tableInput]) && (
        createTable(
          subsidizedPremiumAllAcres[tableInput],
          liability,
          tableInput.slice(0, 3) === 'YP_' ? guarantee : false,
          allAcres,
        )
      )
    ) : (
      exists(subsidizedPremium[tableInput]) && (
        createTable(
          subsidizedPremium[tableInput],
          liabilityPerAcre,
          tableInput.slice(0, 3) === 'YP_' ? guarantee : false,
          allAcres,
        )
      )
    )
  );

  const rmaErrorMessage = () => (
    <Box my={1} display="flex" justifyContent="center" width="100%">
      <Box
        p={1}
        borderRadius="borderRadius"
        border={1}
        borderColor={green}
      >
        We encounted an issue getting premium for selected inputs. Please check back later as we are in the process of updating our database!
      </Box>
    </Box>
  );

  const missingInputsMessage = () => (
    <Box my={1} display="flex" justifyContent="center" width="100%">
      <Box
        p={1}
        borderRadius="borderRadius"
        border={1}
        borderColor={grey}
      >
        We currently can't preform calculations for selected crop. Please check back later as we are in the process of adding more!
      </Box>
    </Box>
  );

  const whatIfInputs = () => (
    <Box
      mx={1}
      color={blackText}
      fontWeight={500}
    >
      <Box color={grey} className={classes.boxLabel}>
        What If Inputs
      </Box>
      <Box
        p={1}
        border={1}
        borderRadius="borderRadius"
        borderColor={grey}
        display="flex"
        alignItems="center"
        minHeight={97.5}
        paddingTop="13px"
      >
        <Box mr={2}>
          <Box className={classes.inputName}>
            Actual Yield (Bu./Acre)
          </Box>
          <TextField
            className={classes.input}
            variant="outlined"
            value={inputs.actualYield}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onChange={(e) => updateInputs('actualYield', Number(e.target.value.replaceAll(',', '')))}
            onBlur={(e) => yieldChange(Number(e.target.value.replaceAll(',', '')))}
            inputProps={{
              style: {
                padding: 12,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            disabled={!!disableUserInputs || disableUserInputsOnLoad}
          />
        </Box>

        { insuranceType !== 'Yield Protection' && (
          <Box mr={2}>
            <Box className={classes.inputName}>
              Harvest Price ($/Bu.)
              <CustomToolTip
                placement="right"
                title="The harvest price for the Revenue Protection policy is limited at 2 times the projected price."
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </CustomToolTip>
            </Box>
            <TextField
              className={classes.input}
              variant="outlined"
              value={inputs.actualPrice}
              // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
              onChange={(e) => updateInputs('actualPrice', Number(e.target.value.replaceAll(',', '')))}
              onBlur={(e) => priceChange(Number(e.target.value.replaceAll(',', '')))}
              inputProps={{
                style: {
                  padding: 12,
                  fontWeight: 500,
                  fontSize: 14,
                },
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                startAdornment: '$',
              }}
              disabled={!!disableUserInputs || disableUserInputsOnLoad}
            />
          </Box>
        )}

        <Box>
          <Box className={classes.inputName}>
            {allAcres ? 'Actual Revenue' : 'Actual Revenue ($/Acre)'}
          </Box>
          <TextField
            className={classes.input}
            variant="outlined"
            value={inputs.actualRevenue}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onChange={(e) => updateInputs('actualRevenue', Number(e.target.value.replaceAll(',', '')))}
            onBlur={(e) => revenueChange('#graph', true, Number(e.target.value.replaceAll(',', '')))}
            inputProps={{
              style: {
                padding: 12,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: '$',
            }}
            disabled={!!disableUserInputs || disableUserInputsOnLoad}
          />
        </Box>
      </Box>
    </Box>
  );

  const selectDataFormat = () => (
    <Box
      mx={1}
      display="flex"
      flexDirection="column"
      color={blackText}
      fontWeight={500}
    >
      <Box
        color={grey}
        className={classes.boxLabel}
      >
        Data Format
      </Box>
      <Box
        p={1}
        display="flex"
        border={1}
        borderRadius="borderRadius"
        borderColor={grey}
      >
        <Box
          display="flex"
          alignItems="center"
        >
          <Box>
            Tables
          </Box>

          <Switch
            color="primary"
            checked={graphs}
            onChange={() => setGraphs(!graphs)}
            name="data-format"
            inputProps={{ 'aria-label': 'data-format' }}
            disabled={!!disableUserInputs || disableUserInputsOnLoad}
          />

          <Box>
            Graph
          </Box>
        </Box>
        <AcreSelection
          allAcres={allAcres}
          setAllAcres={setAllAcres}
          disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
          whatIfInputs={inputs}
          acres={acres}
          updateWhatIfInputs={updateInputs}
        />
      </Box>

    </Box>
  );

  const yieldChange = (yieldVal) => {
    // console.log("Actual yield after change:", yieldVal);
    // Make sure to also update actualYield when it's value is changed
    if (setYieldFromOps !== undefined) {
      setYieldFromOps(yieldVal);
      // updateInputs('actualYield', yieldVal)
    }

    const insurance_option = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe';
    const actualyield = yieldVal;
    let harvestprice = inputs.actualPrice;
    const peracre = !allAcres;
    var actualrevenue = inputs.actualRevenue;

    if (insurance_option === 'yp' || insurance_option === 'aph') {
      harvestprice = projectedPrice;
    }

    if ((harvestprice === undefined || harvestprice === null || harvestprice === '') && (actualrevenue !== undefined || actualrevenue !== null || actualrevenue !== '')) {
      harvestprice = peracre ? actualrevenue / actualyield : (actualrevenue * projectedPrice) / actualyield;
      // revenueChange($('#' + insurance_option + '_revenue').next(), false);
      updateInputs('actualPrice', harvestprice);
    } else if ((harvestprice !== undefined || harvestprice !== null || harvestprice !== '') && (actualrevenue === undefined || actualrevenue === null || actualrevenue === '')) {
      var actualrevenue = peracre ? actualyield * harvestprice : actualyield * harvestprice * acres;
      // console.log("1");
      revenueChange('#graph', false, actualrevenue);
    } else if (((harvestprice !== undefined || harvestprice != null || harvestprice !== '') && (actualrevenue !== undefined || actualrevenue !== null || actualrevenue !== ''))) {
      var actualrevenue = peracre ? actualyield * harvestprice : actualyield * harvestprice * acres;
      // console.log("2");
      revenueChange('#graph', false, actualrevenue);
    }
  };

  // Function called when price gets updated
  const priceChange = (priceVal) => {
    const insurance_option = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe';
    let harvestprice = priceVal;
    const actualyield = inputs.actualYield;
    const peracre = !allAcres;

    if (insurance_option.toLowerCase() == 'rp') {
      if ((projectedPrice * 2) < harvestprice) {
        harvestprice = projectedPrice * 2;
        updateInputs('actualPrice', harvestprice);
      }
    }

    if (actualyield != undefined && actualyield != null && actualyield != '') {
      const actualrevenue = peracre ? actualyield * harvestprice : actualyield * harvestprice * acres;
      // console.log("3");
      revenueChange('#graph', false, actualrevenue);
    }
  };

  const removeElements = (elms) => elms.forEach((el) => el.remove());

  /* Functions to display net payment graph start */
  const drawGraph = () => {
    if (document.getElementById('graph') !== null) {
      document.getElementById('graph').innerHTML = '';
      removeElements(document.querySelectorAll('.d3-tip.n'));
    }

    const type = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe';
    const data = []; const
      prem = subsidizedPremium[`${type.toUpperCase()}_${insuranceUnit}`];
    const defaultrevenue = _.find(liability, (value) => value.name == '50%').value;
    const defaultacre_option = !allAcres;
    const title = defaultacre_option ? 'Net Payment Per Acre' : 'Net Payment';

    for (let i = 0; i < liability.length; i++) {
      data.push({ name: liability[i].name, value: 0 });
    }

    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));

    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: 0,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);

    const x = d3.scaleBand()
      .domain(data.map((d) => d.name))
      .range([margin.left, width])
      .padding(0.1)
      .round(true);
    // console.log('x', x)
    // console.log('prem', prem)
    const y = d3.scaleLinear()
      .domain([-d3.max(prem, (d) => d.value), d3.max(liability, (d) => d.value)])
      .range([height, margin.top]);

    // console.log('y', y)

    const chart = d3.select('#graph')
      .attr('width', margin.left + margin.right + width)
      .attr('height', (height + margin.top + margin.bottom));

    const tooltip_format = d3.format('$,.2f');

    const tip = d3tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0]);

    tip.html((d) => `<strong>${title}: </strong><span>${tooltip_format(d.value)}</span>`);

    chart.call(tip);

    const xAxis = d3.axisBottom()
      .tickSize(0)
      .scale(x);

    const yAxis = d3.axisLeft()
      .ticks(5)
      .tickSize(-width + margin.left, 0, 0)
      .tickSizeOuter(0)
      .tickFormat(d3.format('$,.0f'))
      .scale(y);

    // console.log('data', data)

    const bar = chart.selectAll('g')
      .data(data)
      .enter().append('g')
      .attr('class', 'bar')
      .attr('transform', (d) => `translate(${x(d.name)},0)`)
      .on('mouseover', function (d) { tip.show(d, this.querySelector('.netpayment')); })
      .on('mouseout', tip.hide);

    bar.append('rect')
      .attr('class', 'netpayment')
      .attr('y', (d) => (d.value <= 0 ? y(0) : y(d.value)))
      .attr('width', x.bandwidth())
      .attr('height', (d) => Math.abs(y(d.value) - y(0)))
      .style('fill', (d) => (d.value <= 0 ? '#8B64B8' : '#5590B3'));

    // Invisible bars for the mouse over event
    bar.append('rect')
      .attr('class', 'mouseoverbar')
      .attr('y', margin.top)
      .attr('width', x.bandwidth())
      .attr('height', y(0) - margin.top)
      .style('fill', 'white')
      .style('opacity', 0);

    chart.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${y(0)})`)
      .call(xAxis)
      .selectAll('text')
      .attr('transform', `translate(${[0, y(y.domain()[0]) - y(0)]})`)
      .style('font-size', `${labelfontsize}px`);

    chart.insert('g', ':first-child')
      .attr('class', 'y axis grid')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(yAxis)
      .selectAll('text')
      .style('font-size', `${labelfontsize}px`);

    // Labels

    chart.append('text')
      .attr('class', 'ylabel')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${[(margin.left + width) / 2, height + margin.top]})`)
      .style('font-size', `${leftaxisfontsize}px`)
      .text('Coverage Level')
      .style('fill', '#5590B3');

    chart.append('text')
      .attr('class', 'title')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'hanging')
      .attr('y', 0)
      .attr('transform', `translate(${(width + (margin.left) + margin.right) / 2},0)`)
      .text(title)
      .style('font-size', `${titlefontsize}px`)
      .style('fill', '#5590B3');

    setShowMessage(true);
    // console.log('chart', chart)

    // start from here , redrawIndemnityGraph()
    setDefaultRevenue(defaultrevenue);
  };

  // Decides whether revenueChange should be called with some default revenue values, extracted from the liability results from rmarates
  // or use the current revenue value (might have been changed by the user so we do not just want to reset it)
  const setDefaultRevenue = (defaultrevenue) => {
    // If the defaultrevenue has changed, then it must mean we have new results from rmaRates, so use this now
    if (defaultrevenue !== previousDefaultRevenue) {
      const peracre = !allAcres;
      const newrevenue = peracre ? (defaultrevenue / acres) : defaultrevenue;
      setPreviousDefaultRevenue(defaultrevenue);
      // console.log("4");
      revenueChange('#graph', false, newrevenue);
    } else {
      // console.log("5");
      // const peracre = !allAcres;
      // // peracre signifies user sees "Per Acre" option selected
      // // inputs.perAcre signifies that the revenue value we have stored is the perAcre value
      // const newrevenue =
      //   (peracre && inputs.perAcre) ? inputs.actualRevenue :
      //   (peracre && !inputs.perAcre) ? (inputs.actualRevenue / acres) :
      //   (!peracre && inputs.perAcre) ? (inputs.actualRevenue * acres) :
      //   (!peracre && !inputs.perAcre) ? inputs.actualRevenue :
      //   -1; // It should never reach this last option..
      // const isPerAcre = peracre === inputs.perAcre ? inputs.perAcre : !inputs.perAcre;
      // console.log(newrevenue);
      // console.log(isPerAcre);
      // revenueChange('#graph', false, newrevenue, isPerAcre);
      revenueChange('#graph', false, inputs.actualRevenue);
    }
  };

  // Function called when revenue gets updated
  const revenueChange = (elem, dragged, revenue = null) => {
    // console.log('revenuechange')
    // values from input placeholders
    const insurance_option = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe';
    const actualrevenue = Number(revenue);
    let actualyield = yieldFromOps !== undefined ? Number(yieldFromOps) : Number(advancedOptions.actualYield);

    let harvestprice = Number(inputs.actualPrice);
    const peracre = !allAcres;
    const aph_yield = approvedYield; // need a place holder to enter it (advanced options in whatif)
    const projected_price = projectedPrice; // Projected price in quote inputs
    // let acres = acres // should be field.acres
    const share_percent = advancedOptions.sharePercentage; // should be share percentage from advanced options

    // console.log("actual price:", harvestprice)
    const calculated_revenue = actualyield * acres * harvestprice;
    if (calculated_revenue != actualrevenue && (actualrevenue != undefined || actualrevenue != null || actualrevenue != '')) {
      if (insurance_option == 'yp' || insurance_option == 'aph') {
        actualyield = peracre ? (actualrevenue / projected_price) : (actualrevenue / projected_price) / acres;
      }
      // Use the harvest price to recalculate yield if neccessary

      else if (harvestprice != undefined && harvestprice != null && harvestprice != '' && harvestprice != 0) {
        if (actualyield != undefined && actualyield != null && actualyield != '') {
          // Check if the yield and price produce the revenue
          actualyield = peracre ? (actualrevenue / harvestprice) : (actualrevenue / harvestprice) / acres;
        } else {
          // actualyield = peracre ? (actualrevenue / harvestprice) : (actualrevenue * acres) / harvestprice;
          actualyield = peracre ? (actualrevenue / harvestprice) : (actualrevenue / acres) / harvestprice;
        }
      }
      // If the harvest price is not entered see if we can use actual yield
      else if (actualyield != undefined && actualyield != null && actualyield != '') {
        // harvestprice = peracre ? (actualrevenue / actualyield) : (actualrevenue * acres) / actualyield;
        harvestprice = peracre ? (actualrevenue / actualyield) : (actualrevenue / acres) / actualyield;
      }
      // If we are here, neither price nor yield has been filled in. Use projected price to calculate yield.
      else {
        harvestprice = projected_price;
        actualyield = peracre ? (actualrevenue / projected_price) : (actualrevenue / projected_price) / acres;
      }

      if (insurance_option == 'rp') {
        if ((projected_price * 2) <= harvestprice) {
          harvestprice = (projected_price * 2);
          actualyield = peracre ? (actualrevenue / harvestprice) : (actualrevenue / harvestprice) / acres;
        }
      }
      if (insurance_option === 'yp') {
        harvestprice = actualrevenue / actualyield;
      }

      // console.log("About to update all inputs:")
      // console.log(actualyield, harvestprice, actualrevenue)
      const updateVals = [
        { key: 'actualYield', value: actualyield },
        { key: 'actualPrice', value: harvestprice },
        { key: 'actualRevenue', value: actualrevenue },
        // { key: 'perAcre', value: isPerAcre}
      ];

      updateMultipleInputs(updateVals);
    }
    // --------- End value Calculations -----------//

    // Calculate indemnity and display in graph
    const unit_option = insuranceUnit;
    const selectedpremium = insurance_option == 'aph' ? `YP_${unit_option}` : `${insurance_option.toUpperCase()}_${unit_option}`;

    const liabilityV = peracre ? _.map(getLiability(insurance_option), (value) => ({ name: value.name, value: value.value / acres })) : getLiability(insurance_option);
    // console.log('liabilityV', liabilityV)

    const indemnity = _.map(liabilityV, (value) => ({ name: value.name, indemnity: Math.max(0, value.value - actualrevenue) }));

    const indemnity_premium = peracre ? subsidizedPremium[selectedpremium] : subsidizedPremiumAllAcres[selectedpremium];
    // console.log('indemnity_premium', indemnity_premium, selectedpremium, subsidizedPremium)
    const indemnity_title = peracre ? 'Net Payment Per Acre' : 'Net Payment';

    // console.log(indemnity, coverageLevel)
    const indemnityVal = indemnity.filter((x) => x.name === coverageLevel)[0].indemnity;
    const premiumVal = indemnity_premium.filter((x) => x.name === coverageLevel)[0].value;
    // console.log('indemnityVal', indemnityVal)
    setIndemnityPerAcre(indemnityVal);
    setPremiumPerAcre(premiumVal);

    const data = _.map(indemnity_premium,
      (value, index) => ({ name: value.name, value: indemnity[index].indemnity - value.value }));

    if (source.toUpperCase() !== 'PROFITLAYERS') {
      if (!dragged) {
        maintainActualRevenueLine('#liabilityGraph', actualrevenue, liabilityV, indemnity, false);
      } else {
        maintainActualRevenueLine('#liabilityGraph', actualrevenue, liabilityV, indemnity, true);
      }

      if (insurance_option == 'rp') {
        redrawLiabilityGraph('#liabilityGraph', liabilityV);
      }
    } else {
      const profitTitle = peracre ? 'Net Insured Profit Per Acre' : 'Net Insured Profit';
      // Formula Net Profit = (Indemnity per acre - Premium per acre) + (What if Harvest Price x What if Yield) – (Harvest Revenue Per Acre)  - Total Cost Per Acre
      // console.log('indemnity_premium', indemnity_premium)
      // console.log('indemnity', indemnity)
      const dataForNetProfit = _.map(indemnity_premium,
        (value, index) => ({
          name: value.name,
          value: indemnity[index].indemnity - value.value + (harvestprice * actualyield) + Number(otherRevenue)
                                             - Math.abs(Number(totalCosts)),
        }));
      reDrawNetProfitGraph('#liabilityGraph', liabilityV, indemnity_premium, profitTitle, dataForNetProfit);
    }

    redrawIndemnityGraph('#graph', liabilityV, indemnity_premium, indemnity_title, data);
  };

  const getLiability = (insurance_option) => {
    const harvestprice = inputs.actualPrice;

    if (insurance_option == 'yp' || insurance_option == 'aph') {
      var liabile = liability;
    } else if (insurance_option == 'rphpe') {
      var liabile = liability;
    } else if (insurance_option == 'rp') {
      // RP liability is dynamic. Redraw liability graph.
      const aph_yield = advancedOptions.rateYield; // need a place holder to enter it (advanced options in whatif)
      const projected_price = projectedPrice; // Projected price in quote inputs
      // let acres = acres // should be field.acres
      const share_percent = advancedOptions.sharePercentage; // should be share percentage from advanced options
      // console.log(aph_yield, projected_price, harvestprice, acres, share_percent)
      var liabile = _.map(liability, (value, index) => ({ name: value.name, value: Math.round(Math.round(Math.round((50 + (index * 5)) / 100 * aph_yield * 10) / 10 * Math.max(projected_price, harvestprice) * acres * 100) / 100 * share_percent) }));
      // console.log('liable', liabile)
    }

    return liabile;
  };

  const redrawIndemnityGraph = (container, liability, premium, title, data) => {
    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));

    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: 0,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);
    const xaxis_strokewidth = 1;

    const y = d3.scaleLinear()
      .domain([-d3.max(premium, (d) => d.value), d3.max(liability, (d) => d.value)])
      .range([height, margin.top]);

    const yAxis = d3.axisLeft()
      .ticks(5)
      .tickSize(-width + margin.left, 0, 0)
      .tickSizeOuter(0)
      .tickFormat(d3.format('$,.0f'))
      .scale(y);

    const chart = d3.select('#graph')
      .attr('width', margin.left + margin.right + width)
      .attr('height', (height + margin.top + margin.bottom));

    chart.selectAll('.bar')
      .data(data);

    chart.select('.y')
      .transition()
      .duration(300)
      .call(yAxis)
      .selectAll('text')
      .style('font-size', `${labelfontsize}px`);

    chart.select('.x')
      .attr('transform', `translate(0,${y(0)})`)
      .style('stroke-width', xaxis_strokewidth)
      .selectAll('text')
      .attr('transform', `translate(${[0, y(y.domain()[0]) - y(0)]})`);

    chart.selectAll('.netpayment')
      .data(data)
      .attr('y', (d) => (d.value <= 0 ? y(0) + xaxis_strokewidth : y(d.value)))
      .attr('height', (d) => Math.abs(y(d.value) - y(0)))
      .style('fill', (d) => (d.value <= 0 ? '#8B64B8' : '#5590B3'));
  };

  /* Functions to display net payment graph end */

  /* Functions to display liability graph start */
  const drawuprightbarchart = () => {
    if (document.getElementById('liabilityGraph') !== null) {
      document.getElementById('liabilityGraph').innerHTML = '';
      removeElements(document.querySelectorAll('.d3-tip.n'));
    }

    const defaultacre_option = !allAcres; let
      data;
    if (defaultacre_option) { data = liabilityPerAcre; } else { data = liability; }
    const title = defaultacre_option ? 'Liability - Your Coverage Per Acre' : 'Liability - Your Coverage';
    const insurance_option = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe'; const
      useWidth = 100;
    const barclass = 'liability';
    const container = '#liabilityGraph';
    const defaultrevenue = _.find(liability, (value) => value.name == '50%').value;

    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));

    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: container.split('_')[1] == 'chart' ? 0 : containerwidth * 0.05,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);

    const x = d3.scaleBand()
      .domain(data.map((d) => d.name))
      .range([margin.left, width])
      .padding(0.1)
      .round(true);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([height, margin.top]);

    const chart = d3.select(container)
      .attr('width', margin.left + margin.right + width)
      .attr('height', (height + margin.top + margin.bottom));

    const tooltip_format = d3.format('$,.2f');

    // Set the show value on hover tip
    const tip = d3tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0]);
    const acre_appendage = ` ${title.split(' ').slice(1).join(' ')}`;
    if (barclass == 'liability') {
      tip.html((d) => `<strong>Your Coverage${acre_appendage}: </strong><span>${tooltip_format(d.value)}</span><br/>`
          + `<strong>Indemnity${acre_appendage}: </strong><span>${d.indemnity == undefined ? tooltip_format(0) : tooltip_format(d.indemnity)}</span>`);
    } else {
      tip.html((d) => `<strong>${title}: </strong><span>${tooltip_format(d.value)}</span>`);
    }

    chart.call(tip);

    const xAxis = d3.axisBottom()
      .tickSize(0)
      .scale(x);

    const yAxis = d3.axisLeft()
      .ticks(5)
      .tickSize(-width + margin.left, 0, 0)
      .tickSizeOuter(0)
      .tickFormat(d3.format('$,.0f'))
      .scale(y);

    const bar = chart.selectAll('g')
      .data(data)
      .enter().append('g')
      .attr('class', 'bar')
      .attr('transform', (d) => `translate(${x(d.name)},0)`)
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide);

    bar.append('rect')
      .attr('class', barclass)
      .attr('y', (d) => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - y(d.value));

    chart.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .selectAll('text')
      .attr('transform', `translate(${[0, 3]})`)
      .style('font-size', `${labelfontsize}px`);

    chart.insert('g', ':first-child')
      .attr('class', 'y axis grid')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(yAxis)
      .selectAll('text')
      .style('font-size', `${labelfontsize}px`);

    const title_container = chart.append('g')
      .attr('class', 'title_container')
      .attr('transform', `translate(${((width + 10) + (margin.left) + margin.right) / 2},0)`);

    // title_container.append('text')
    //   .attr('class', 'title')
    //   .attr('text-anchor', 'middle')
    //   .attr('alignment-baseline', 'hanging')
    //   .text(title)
    //   .style('font-size', titlefontsize + 'px')
    //   .style('fill', '#5590B3');

    chart.append('text')
      .attr('class', 'title')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'hanging')
      .attr('y', 0)
      .attr('transform', `translate(${(width + (margin.left) + margin.right) / 2},0)`)
      .text(title)
      .style('font-size', `${titlefontsize}px`)
      .style('fill', '#5590B3');

    const tooltip_radius = 10;

    if (barclass == 'liability') {
      const texttip = d3tip()
        .attr('class', 'd3-tip')
        .offset([0, 0]);

      texttip.html('<strong>Your Coverage, formally know as liability, is the value of your crop that is insured.');
      chart.call(texttip);

      const tooltip_icon = title_container.append('g')
        .attr('class', 'tooltip-icon')
        .attr('transform', `translate(${[width / 2 - (tooltip_radius), tooltip_radius]})`);

      tooltip_icon.append('circle')
        .attr('class', 'tooltip-circle')
        .attr('r', tooltip_radius)
        .style('fill', '#32ACC7')
        .style('stroke-width', 1)
        .style('stroke', 'white');

      tooltip_icon.append('text')
        .attr('class', 'question-mark')
        .attr('text-anchor', 'middle')
        .attr('alignment-baseline', 'central')
        .text('?')
        .attr('text-anchor', 'middle')
        .attr('dominant-baseline', 'hanging')
        .attr('y', -5)
        .style('fill', 'white')
        .style('font-size', tooltip_radius * 1.5);

      chart.select('.title_container')
        .on('mouseover', function (d) { texttip.show(d, this.querySelector('.tooltip-circle')); })
        .on('mouseout', texttip.hide);
    }

    // Labels

    chart.append('text')
      .attr('class', 'xlabel')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${[(margin.left + width) / 2, height + margin.top]})`)
      .style('font-size', `${leftaxisfontsize}px`)
      .style('fill', '#5590B3')
      .text('Coverage Level');

    setDefaultRevenue(defaultrevenue);
  };

  const maintainActualRevenueLine = (container, actualrevenue, liability, indemnity, dragged) => {
    const maxliab = _.max(liability, (value) => value.value).value;
    actualrevenue = actualrevenue >= maxliab ? maxliab : actualrevenue;

    if (!dragged) {
      drawActualRevenueLine(container, actualrevenue, liability, indemnity);
    } else {
      updateActualRevenueLine(container, actualrevenue, liability, indemnity, dragged);
    }
  };

  const drawActualRevenueLine = (container, actualrevenue, liability, indemnity) => {
    if (document.getElementsByClassName('revenue_container') !== null) {
      removeElements(document.querySelectorAll('.revenue_container'));
    }

    // Force the acre option to total acre.
    const insurance_option = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe';
    const peracre = !allAcres;

    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));

    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: containerwidth * 0.05,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);

    const x = d3.scaleBand()
      .domain(liability.map((d) => d.name))
      .range([margin.left, width])
      .padding(0.1)
      .round(true);

    const y = d3.scaleLinear()
      .domain([0, d3.max(liability, (d) => d.value)])
      .range([height, margin.top]);

    const data = _.map(liability, (value, index) => ({ name: value.name, value: value.value, indemnity: indemnity[index].indemnity }));

    const chart = d3.select(container);

    // Draw the actual revenue line

    const absolutezero = height;
    const absolutemax = y.range()[1];

    // Attach a dragging function to the line.
    const drag = d3.drag()
      .on('start', function (d) {
        const moveme = d3.select(this.parentNode).selectAll('.moveme');
        if (moveme.size() > 0) {
          moveme.remove();
        }
      })
      .on('drag', function (d) {
        const dy = d3.event.y;
        const container2move = d3.select(this.parentNode.parentNode);
        const transformstring = container2move.attr('transform');
        const transform = transformstring.substring(transformstring.indexOf('(') + 1, transformstring.indexOf(')')).split(',');
        const oldY = parseFloat(transform[1]);
        let yNew = oldY + dy;
        if (yNew >= absolutezero) {
          yNew = absolutezero;
        } else if (yNew <= absolutemax) {
          yNew = absolutemax;
        }

        container2move
          .attr('transform', `translate(${margin.left},${yNew})`);

        const actualrevenue = Math.round(y.invert(yNew));
        // setActualRevenue(actualrevenue)
        container2move.data([actualrevenue]);
        // console.log('revenuechange')
        revenueChange('#graph', true, actualrevenue);
      }).on('end', () => {
      });

    const revenue = chart
      .append('g')
      .data([actualrevenue])
      .attr('class', 'revenue_container')
      .attr('transform', `translate(${margin.left},${y(actualrevenue)})`);

    revenue.append('line')

      .attr('class', 'actualrevenue_line')
      .attr('x1', 0)
      .attr('x2', width - margin.left)
      .attr('y1', 0)
      .attr('y2', 0)
      .style('stroke-width', 2)
      .style('stroke', 'black');

    const circleradius = 10;

    const circle = revenue.append('g')
      .attr('class', 'circle')
      .attr('transform', `translate(${width - margin.left + circleradius},0)`)
      .style('fill', 'white');

    circle.append('text')
      .text('$')
      .attr('dominant-baseline', 'central')
      .attr('text-anchor', 'middle')
      .attr('y', -circleradius / 8)
      .style('fill', 'black');

    circle.append('text')
      .text('Move Me!')
      .attr('text-anchor', 'start')
      .attr('y', -circleradius * 1.2)
      .attr('x', -circleradius)
      .attr('class', 'moveme')
      .style('fill', 'black')
      .style('font-size', '12px');

    circle.append('circle')
      .attr('class', 'grabcircle')
      .attr('r', circleradius)
      .style('fill', 'white')
      .style('fill-opacity', 0)
      .style('stroke-width', 1)
      .style('stroke', 'black')
      .call(drag);

    // Labels on top and bottom of line.

    const indemnity_label = revenue.append('g')
      .attr('class', 'indemnity_label')
      .attr('transform', `translate(${[(width - margin.left) / 2, -4]})`);

    indemnity_label.append('text')
      .attr('class', 'text-shadow')
      .text('Indemnity')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${[2, 2]})`)
      .style('font-size', `${labelfontsize * 1.5}px`)
      .style('fill', 'black');

    indemnity_label.append('text')
      .text('Indemnity')
      .attr('class', 'normal-text')
      .attr('text-anchor', 'middle')
      .style('font-size', `${labelfontsize * 1.5}px`)
      .style('fill', 'white');

    const actualRevenue_label = revenue.append('g')
      .attr('class', 'actualRevenue_label')
      .attr('transform', `translate(${[(width - margin.left) / 2, 4]})`);

    actualRevenue_label.append('text')
      .attr('class', 'text-shadow')
      .text('Actual Revenue')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .attr('transform', `translate(${[2, 20]})`)
      .style('font-size', `${labelfontsize * 1.5}px`)
      .style('fill', 'black');

    actualRevenue_label.append('text')
      .attr('class', 'text-shadow')
      .text('Actual Revenue')
      .style('text-shadow', '-1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000')
      .attr('alignment-baseline', 'hanging')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${[2, 20]})`)
      .style('font-size', `${labelfontsize * 1.5}px`)
      .style('fill', 'white');

    actualRevenue_label.append();

    // ----- Highlight any rectangle values above. ----- //

    // liability = _.map(liability, (value, index) => ({ name: value.name, value: value.value - indemnity[index].indemnity }));

    // Keep current rectangles, just draw the new ones on top of them.
    // console.log('data', data)
    chart.selectAll('.bar')
      .data(data)
      .append('rect')
      .data(data)
      .attr('class', 'indemnity')
      .attr('y', (d, index) => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', (d, index) => height - y(d.indemnity))
      .style('fill', '#5590B3');
  };

  const redrawLiabilityGraph = (container, data) => {
    // Make the widths relative to the screen size.

    // if (document.getElementById('liabilityGraph') !== null) {
    //   document.getElementById('liabilityGraph').innerHTML = '';
    // }
    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));
    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: containerwidth * 0.05,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);

    const x = d3.scaleBand()
      .domain(data.map((d) => d.name))
      .range([margin.left, width])
      .padding(0.1)
      .round(true);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([height, margin.top]);

    const yAxis = d3.axisLeft()
      .ticks(5)
      .tickSize(-width + margin.left, 0, 0)
      .tickSizeOuter(0)
      .tickFormat(d3.format('$,.0f'))
      .scale(y);

    const chart = d3.select('#liabilityGraph');

    chart.selectAll('.liability')
      .data(data)
      .attr('y', (d) => y(d.value))
      .attr('height', (d) => height - y(d.value));

    chart.selectAll('.y')
      .transition()
      .duration(300)
      .call(yAxis)
      .selectAll('text')
      .style('font-size', `${labelfontsize}px`);
  };

  const updateActualRevenueLine = (container, actualrevenue, liability, indemnity, dragged) => {
    // if (document.getElementsByClassName('revenue_container') !== null) {
    //   removeElements(document.querySelectorAll('.revenue_container'));
    // }
    const insurance_option = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe';
    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));

    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: containerwidth * 0.05,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);

    const data = _.map(liability, (value, index) => ({ name: value.name, value: value.value, indemnity: indemnity[index].indemnity }));

    const x = d3.scaleBand()
      .domain(liability.map((d) => d.name))
      .range([margin.left, width])
      .padding(0.1)
      .round(true);

    const y = d3.scaleLinear()
      .domain([0, d3.max(liability, (d) => d.value)])
      .range([height, margin.top]);

    const chart = d3.select(container);

    const bar = chart.selectAll('.bar');

    // Draw the actual revenue line
    const absolutezero = height;
    const absolutemax = y.range()[1];

    const drag = d3.drag()
      .on('start', function (d) {
        const moveme = d3.select(this.parentNode).selectAll('.moveme');
        if (moveme.size() > 0) {
          moveme.remove();
        }
      })
      .on('drag', function (d) {
        const dy = d3.event.y;
        const container2move = d3.select(this.parentNode.parentNode);
        const transformstring = container2move.attr('transform');
        const transform = transformstring.substring(transformstring.indexOf('(') + 1, transformstring.indexOf(')')).split(',');
        const oldY = parseFloat(transform[1]);
        let yNew = oldY + dy;
        if (yNew >= absolutezero) {
          yNew = absolutezero;
        } else if (yNew <= absolutemax) {
          yNew = absolutemax;
        }

        container2move
          .attr('transform', `translate(${margin.left},${yNew})`);

        const actualrevenue = Math.round(y.invert(yNew));
        // setActualRevenue(actualrevenue)
        // console.log('actualRevenue', actualrevenue)
        revenueChange('#liabilityGraph', true, actualrevenue);
      }).on('end', () => {
      });

    bar.data(data);

    // Draw the actual revenue line

    if (dragged) {
      chart.selectAll('.revenue_container')
        .data([actualrevenue])
        .attr('transform', `translate(${[margin.left, y(actualrevenue)]})`);
      const circle = chart.select('.circle');
      circle.select('circle')
        .call(drag);
    }

    // Keep current rectangles, just draw the new ones on top of them.
    chart.selectAll('.indemnity')
      .data(data)
      .attr('y', (d) => y(d.value))
      .attr('height', (d, index) => height - y(d.indemnity));
  };
  /* Functions to display laibaility grpah end */

  /* Functions to draw net insured profit graph */
  const drawNetInsuredGraph = () => {
    if (document.getElementById('netProfitGraph') !== null) {
      document.getElementById('netProfitGraph').innerHTML = '';
      removeElements(document.querySelectorAll('.d3-tip.n'));
    }

    const type = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe';
    const data = []; const
      prem = subsidizedPremium[`${type.toUpperCase()}_${insuranceUnit}`];
    const defaultrevenue = _.find(liability, (value) => value.name == '50%').value;
    const defaultacre_option = !allAcres;
    const title = defaultacre_option ? 'Net Insured Profit Per Acre' : 'Net Insured Profit';

    for (let i = 0; i < liability.length; i++) {
      data.push({ name: liability[i].name, value: 0 });
    }

    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));

    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: 0,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);

    const x = d3.scaleBand()
      .domain(data.map((d) => d.name))
      .range([margin.left, width])
      .padding(0.1)
      .round(true);
    // console.log('x', x)
    // console.log('prem', prem)
    const y = d3.scaleLinear()
      .domain([-d3.max(prem, (d) => d.value), d3.max(liability, (d) => d.value)])
      .range([height, margin.top]);

    // console.log('y', y)

    const chart = d3.select('#netProfitGraph')
      .attr('width', margin.left + margin.right + width)
      .attr('height', (height + margin.top + margin.bottom));

    const tooltip_format = d3.format('$,.2f');

    const tip = d3tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0]);

    tip.html((d) => `<strong>${title}: </strong><span>${tooltip_format(d.value)}</span>`);

    chart.call(tip);

    const xAxis = d3.axisBottom()
      .tickSize(0)
      .scale(x);

    const yAxis = d3.axisLeft()
      .ticks(5)
      .tickSize(-width + margin.left, 0, 0)
      .tickSizeOuter(0)
      .tickFormat(d3.format('$,.0f'))
      .scale(y);

    // console.log('data', data)

    const bar = chart.selectAll('g')
      .data(data)
      .enter().append('g')
      .attr('class', 'bar')
      .attr('transform', (d) => `translate(${x(d.name)},0)`)
      .on('mouseover', function (d) { tip.show(d, this.querySelector('.netProfit')); })
      .on('mouseout', tip.hide);

    bar.append('rect')
      .attr('class', 'netProfit')
      .attr('y', (d) => (d.value <= 0 ? y(0) : y(d.value)))
      .attr('width', x.bandwidth())
      .attr('height', (d) => Math.abs(y(d.value) - y(0)))
      .style('fill', (d) => (d.value <= 0 ? '#8B64B8' : '#5590B3'));

    // Invisible bars for the mouse over event
    bar.append('rect')
      .attr('class', 'mouseoverbar')
      .attr('y', margin.top)
      .attr('width', x.bandwidth())
      .attr('height', y(0) - margin.top)
      .style('fill', 'white')
      .style('opacity', 0);

    chart.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${y(0)})`)
      .call(xAxis)
      .selectAll('text')
      .attr('transform', `translate(${[0, y(y.domain()[0]) - y(0)]})`)
      .style('font-size', `${labelfontsize}px`);

    chart.insert('g', ':first-child')
      .attr('class', 'y axis grid')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(yAxis)
      .selectAll('text')
      .style('font-size', `${labelfontsize}px`);

    // Labels

    chart.append('text')
      .attr('class', 'ylabel')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${[(margin.left + width) / 2, height + margin.top]})`)
      .style('font-size', `${leftaxisfontsize}px`)
      .text('Coverage Level')
      .style('fill', '#5590B3');

    chart.append('text')
      .attr('class', 'title')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'hanging')
      .attr('y', 0)
      .attr('transform', `translate(${(width + (margin.left) + margin.right) / 2},0)`)
      .text(title)
      .style('font-size', `${titlefontsize}px`)
      .style('fill', '#5590B3');

    setDefaultRevenue(defaultrevenue);
  };

  const reDrawNetProfitGraph = (container, liability, premium, title, data) => {
    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));

    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: 0,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);
    const xaxis_strokewidth = 1;

    const y = d3.scaleLinear()
      .domain([-d3.max(premium, (d) => d.value), d3.max(liability, (d) => d.value)])
      .range([height, margin.top]);

    const yAxis = d3.axisLeft()
      .ticks(5)
      .tickSize(-width + margin.left, 0, 0)
      .tickSizeOuter(0)
      .tickFormat(d3.format('$,.0f'))
      .scale(y);

    const chart = d3.select('#netProfitGraph')
      .attr('width', margin.left + margin.right + width)
      .attr('height', (height + margin.top + margin.bottom));

    chart.selectAll('.bar')
      .data(data);

    chart.select('.y')
      .transition()
      .duration(300)
      .call(yAxis)
      .selectAll('text')
      .style('font-size', `${labelfontsize}px`);

    chart.select('.x')
      .attr('transform', `translate(0,${y(0)})`)
      .style('stroke-width', xaxis_strokewidth)
      .selectAll('text')
      .attr('transform', `translate(${[0, y(y.domain()[0]) - y(0)]})`);

    chart.selectAll('.netProfit')
      .data(data)
      .attr('y', (d) => (d.value <= 0 ? y(0) + xaxis_strokewidth : y(d.value)))
      .attr('height', (d) => Math.abs(y(d.value) - y(0)))
      .style('fill', (d) => (d.value <= 0 ? '#8B64B8' : '#5590B3'));
  };

  const header = () => (
    <Box mt={1} mx={1}>
      <Box fontSize={18}>
        Crop Insurance What-If Analysis
      </Box>

      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        Enter hypothetical end of season results to see the resulting insurance payout.
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        This information is a premium estimate only, and is only for
        educational purposes. You must refer to an authorized crop
        insurance agent for official quote(s) and specific information
        regarding insurance coverage, actuarial information, conditions
        and exclusions.
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        Note: You must click &quot;RECALCULATE PREMIUM&quot; after changing any of the Quote Inputs for the changes to apply.
      </Typography>
      <Divider />
    </Box>
  );

  return (
    <Box color={blackText} fontWeight={500}>
      { header() }

      { locationErrorMessage === '' ? (
        <Box>
          <Box
            display="flex"
            flexWrap="wrap"
          >
            <Box display="flex" flexWrap="wrap" width="100%">
              {/* Selections */}
              <GeneralSettings
                handleChange={handleChange}
                commodityYear={commodityYear}
                crops={crops}
                crop={crop}
                disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
              />

              <QuoteInputs
                handleChange={handleChange}
                types={types}
                type={type}
                practices={practices}
                practice={practice}
                acres={acres}
                projectedPrice={projectedPrice}
                approvedYield={approvedYield}
                enqueueSnackbar={enqueueSnackbar}
                disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
              />
            </Box>

            <Box display="flex" flexWrap="wrap" width="100%">
              {whatIfInputs()}

              <OptionsBox
                insuranceType={insuranceType}
                unit={insuranceUnit}
                setUnit={setInsuranceUnit}
                setInsuranceType={setInsuranceType}
                disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
              />
            </Box>

            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              width="100%"
            >

              {selectDataFormat()}

              {showAdvancedOptions && (
                <AdvancedOptions
                  open={showAdvancedOptions}
                  setOpen={setShowAdvancedOptions}
                  options={advancedOptions}
                  updateOptions={updateAdvancedOptions}
                  mobileView={mobileView}
                  getInputAndSendRequest={getInputAndSendRequest}
                />
              )}

              {refiningEstimates && (
                <RefineEstimates
                  yields={yields}
                  setYields={setYields}
                  updateYields={updateYields}
                  open={refiningEstimates}
                  setOpen={setRefiningEstimates}
                  mobileView={mobileView}
                  commodityYear={commodityYear}
                  exclusionYears={exclusionYears}
                  getCalculateYieldFromHistoryData={getCalculateYieldFromHistoryData}
                  getInputAndSendRequest={getInputAndSendRequest}
                  setHistoryYields={setHistoryYields}
                  historyYields={historyYields}
                />
              )}

              <Box
                my={2}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => getInputAndSendRequest()}
                  style={{ margin: 8, height: 40, whiteSpace: 'nowrap' }}
                  disabled={disableUserInputsOnLoad || showErrorMessage}
                  disableElevation
                >
                  Recalculate Premium
                </Button>

                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setRefiningEstimates(!refiningEstimates)}
                  style={{ margin: 8, height: 40, whiteSpace: 'nowrap' }}
                  disabled={disableUserInputsOnLoad || showErrorMessage}
                  disableElevation
                >
                  Refine Estimates
                </Button>

                <Button
                  variant="outlined"
                  color="primary"
                  style={{ margin: 8, height: 40, whiteSpace: 'nowrap' }}
                  onClick={() => toggleOptions()}
                  disabled={disableUserInputsOnLoad || showErrorMessage}
                  disableElevation
                >
                  Advanced Options
                </Button>
              </Box>

            </Box>
          </Box>

          { error && rmaErrorMessage() }

          { (practice === null || type === null) && missingInputsMessage() }

          { !showErrorMessage ? (
            <>
              <Box
                display="flex"
                flexWrap="wrap"
                mt={2}
              >
                {graphs ? (
                  <Box
                    display="flex"
                    flexWrap="wrap"
                    alignItems="center"
                    justifyContent="center"
                    id="what-if-charts-graphs"
                  >
                    {source.toUpperCase() === 'PROFITLAYERS' && <svg style={{ margin: '16px' }} id="netProfitGraph" />}
                    <svg style={{ margin: '16px' }} id="graph" className="indemnity_graph" />
                    {source.toUpperCase() === 'DATALAYERS' && <svg style={{ margin: '16px' }} id="liabilityGraph" />}
                  </Box>
                ) : (
                  tableDisplay()
                )}
              </Box>

              {showMessage && (
                <Box
                  m={1}
                  color="#e60000"
                  display="flex"
                  justifyContent="center"
                  style={{ minWidth: '450px', maxWidth: '1000px' }}
                >
                  ** This information is a premium estimate only, and is only for educational purposes. You must refer to an authorized crop insurance agent for official quote(s) and specific information regarding insurance coverage, actuarial information, conditions and exclusions.
                </Box>
              )}
            </>
          ) : (
            <Box m={1} mt={3} display="flex">
              <Box className={classes.errorMessageBox}>
                {errorMessage[1]}
              </Box>
            </Box>
          )}
        </Box>
      ) : (
        <Box m={1} display="flex">
          <Box className={classes.errorMessageBox}>
            {locationErrorMessage}
          </Box>
        </Box>
      )}

    </Box>
  );
}

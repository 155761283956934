import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { FieldImage } from '../../Maps/FieldImage';
import { textPrimary, darkGrey } from '../../../styles/colors';
import { numFormat } from '../../../utils/helpers';

/**
 * Hidden component that can be used to take an image of field
 * @param {Object} field Field with data such as acres and coordinates
 * @returns {JSX} Map with field image and meta data
 */
export function SelectedFieldImage({ field }) {
  return (
    <Box>
      <Box
        id="field-image"
        display="flex"
        flexDirection="column"
        borderRadius="borderRadius"
        border={1}
        borderColor={darkGrey}
        bgcolor="#ffffff"
        fontWeight={500}
        color={textPrimary}
        fontSize={16}
      >
        <Box px={1}>
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            { field?.name && (
              <Box mr={2}>
                { field.name}
              </Box>
            )}

            <Box>
              {`Field Acres: ${numFormat(field?.acres)}`}
            </Box>

            { field?.zoneAcres && (
              <Box ml={1}>
                {`Zone Acres: ${numFormat(field?.zoneAcres)}`}
              </Box>
            )}
          </Box>

          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box>
              {`Latitude: ${numFormat(field?.latitude, 2, 5)}`}
            </Box>
            <Box ml={1}>
              {`Longitude: ${numFormat(field?.longitude, 2, 5)}`}
            </Box>
          </Box>
        </Box>

        <FieldImage
          field={field}
        />
      </Box>

    </Box>
  );
}

SelectedFieldImage.propTypes = {
  field: PropTypes.object.isRequired,
};

import React, { useEffect, useState, useContext, Fragment } from 'react'
import {
  AppBar,
  Box,
  Button,
  Typography,
  Divider,
  Checkbox,
  Select,
  Modal,
  TextField,
  Card,
  CardActionArea, 
  CardContent, 
  Grid,
  Tooltip,
  MenuItem,
  Drawer
 } from '@material-ui/core';
 import Autocomplete from '@material-ui/lab/Autocomplete';
 import { makeStyles } from '@material-ui/core/styles';
 import { MenuProps } from "../../styles/select";
 import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
 import HighlightOffIcon from '@material-ui/icons/HighlightOff';
 import ArrowBackIcon from "@material-ui/icons/ArrowBack";
 import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
 import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
 import { scrollTo, dollarFormat, exists, createYearArray, formatDate } from '../../utils/helpers'
 import { WeatherChart } from './WeatherChart'
 import { UserContext } from "../Context/UserContext";
 import { useWindowDimensions } from '../../utils/dimensions'
 import { useSnackbar } from "notistack";
 import { lightGrey, green, blackText, grey } from '../../styles/colors'
 import * as turf from "@turf/turf";
 import * as df from '../../utils/dataFetchers'
 import * as d3 from 'd3-geo'
import * as wkt from 'terraformer-wkt-parser';
import {SoilData} from '../DataLayers/SSURGO/SoilData'
import {Polaris} from '../DataLayers/Polaris/Polaris'
import {CropLand} from '../DataLayers/CropLand/CropLand'
import { convertExtentToBounds } from '../Maps/MapFunctions/helpers'
import { numFormat } from '../../utils/helpers';
import { PreviousBoundariesInfo } from './PreviousBoundaries/PreviousBoundariesInfo';
import { Comps } from './Comparables/Comps'
import { comptest } from './compsTest'

const zonesColors = [
  "#c34016",
  "#f3750e",
  "#f4ae62",
  "#fff200",
  "#ccee5f",
  "#69dc27",
  "#97e8d1",
  "#13a28c",
  "#3053c9",
  "#5712da",
  "#e74daa",
  "#742a4d",
];

const weatherTypes = [
  {name: 'Precipitation', value: 'precip'},
  {name: 'Temperature', value: 'temp'}
]

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '80%',
    backgroundColor: '#f3f3f3'
  },
  drawerConentContainer: {
    width: '100%',
    height: '100%'
  },
  detailsContainer: {
    margin: '10px',
    display: "flex",
    //flexDirection: 'column',
    backgroundColor: 'white',
    borderRadius: '12px',
    marginBottom: '20px',
    border: 'black',
    borderStyle: 'solid',
    borderWidth: '1px'
  },
  detailsColumn:{
    display:'flex',
    flexDirection: 'column',
    margin: "10px",
  },
  detailsRow:{
    display: 'flex'
  },
  disabledInput: theme.disabledInput,
  input: theme.plInput,

  infoContainer:{
    height: "100%",
    margin: '10px'
  },
  infoNav:{
    display:'flex',
    marginLeft: '20px'
  },
  infoTab:{
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: '5px',
    backgroundColor: '#cacaca',
    cursor: 'pointer',
    fontWeight: 500,
    fontSize: 18,
    color: blackText,
    minWidth: '120px',
    textAlign: 'center',
    boxShadow: '0px 0px 2px;'
  },
  activeInfoTab:{
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    backgroundColor:'white',
    padding: '5px',
    fontWeight: 500,
    fontSize: 18,
    color: blackText,
    border: 'black',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderBottom: 'white',
    boxShadow: '0px 5px white',
    minWidth: '120px',
    textAlign: 'center'
  },
  info:{
    width:'100%',
    height: '100%',
    backgroundColor: 'white',
    padding: '5px',
    paddingTop: '10px',
    paddingBottom: '10px',
    borderRadius: '12px',
    border: 'black',
    borderStyle: 'solid',
    borderWidth: '1px'
  },
  soilContainer:{

  }
}))


 export function ParcelDetails({
   selectedParcel, 
   setSelectedParcel, 
   getComparables,
   open, 
   setOpen,
   documents,
   setDocuments,
   reports,
   setReports,
   refreshParcels
  }){

  const classes = useStyles()
  const [user, setUser] = useContext(UserContext);
  const mobileBreakPoint = 860

  const [displayDetails, setDisplayDetails] = useState(false)
  const [selectedParcelDate, setSelectedParcelDate] = useState("")

  const [editName, setEditName] = useState(false)
  const [selectedParcelName, setSelectedParcelName] = useState("")
  const [editDocument, setEditDocument] = useState(false)
  const [selectedParcelDoc, setSelectedParcelDoc] = useState({})
  const [documentInput, setDocumentInput] = useState("")
  const [editReport, setEditReport] = useState(false)
  const [selectedParcelReport, setSelectedParcelReport] = useState({})
  const [reportInput, setReportInput] = useState("")
  const [editAppraisal, setEditAppraisal] = useState(false)

  const [activeDetails, setActiveDetails] = useState(true)

  const [activeTab, setActiveTab] = useState("SSURGO")

  const [formattedField, setFormattedField] = useState({})

  const [compsLoaded, setCompsLoaded] = useState(true)
  const [comparables, setComparables] = useState([])
  const [countyAverages, setCountyAverages] = useState({})

  //SSURGO INFO
  const [soilGeo, setSoilGeo] = useState({})
  const [soilAverage, setSoilAverage] = useState(null)
  const [soilLegend, setSoilLegend] = useState([])
  const [ssurgoError, setSSurgoError] = useState('');
  const [ssurgoLoading, setSsurgoLoading] = useState(true);

  // Polaris Info
  const [polarisImage, setPolarisImage] = useState('')
  const [polarisLegend, setPolarisLegend] = useState([])
  const [polarisExtent, setPolarisExtent] = useState({})
  const [polarisAverage, setPolarisAverage] = useState(0)
  const [polarisError, setPolarisError] = useState('');
  const [polarisLoading, setPolarisLoading] = useState(true);

  //CDL info
  const [cdlImage, setCdlImage] = useState('')
  const [cdlLegend, setCdlLegend] = useState([])
  const [cdlExtent, setCdlExtent] = useState({})
  const cdlYears = createYearArray(20, false, 1);
  const [selectedCdlYear, setSelectedCdlYear] = useState(cdlYears[1]);
  const [cdlLoading, setCdlLoading] = useState(false)
  const [cdlError, setCdlError] = useState('')

  const [selectedWeatherType, setSelectedWeatherType] = useState(weatherTypes[0])

  // Previous Boundaries Info
  const [previousBoundaries, setPreviousBoundaries] = useState([])

  useEffect(() => {
    if(Object.keys(selectedParcel).length > 0){
      console.log(selectedParcel)
      setEditAppraisal(!exists(selectedParcel.AppraisalDate))
      getCountyAverages(selectedParcel.StateFips, selectedParcel.CountyFips)
      createFieldObj()
    }
  }, [selectedParcel])

  useEffect(() => {
    if(Object.keys(formattedField).length > 0){
      getComparableInfo()
      getSSURGO()
      getPolaris()
      getCDL()
      getBoundaries()
    }
  }, [formattedField])

  useEffect(() => {
    if(open === false){
      //reset tab back to ssurgo on close
      setActiveTab('SSURGO')
    }
  }, open)

  const createFieldObj = () => {
    let geom = wkt.parse(selectedParcel.WKT)
    let geojson = {type:"Feature", geometry: geom}
    let field = {}
    field['boundary'] = JSON.stringify(geojson)
    field['acres'] = selectedParcel.Acres
    setFormattedField(field)
  }

  const getBoundaries = async () => {
    let result = await df.getPreviousBoundaries(selectedParcel.FieldID, selectedParcel.OrganizationID, user.token)
    let boundaries = JSON.parse(result)
    let sorted = boundaries.sort((a,b) => new Date(b.created_at)-new Date(a.created_at))
    let formatted = sorted.map(x => ({...x, date:formatDate(x.created_at), display:`${formatDate(x.created_at)} (${numFormat(x.Area)})`}))
    setPreviousBoundaries(formatted)
  }

  const getComparableInfo = async () => {
    setCompsLoaded(false)
    let comps = await getComparables(5, "Sold", null, new Date(), null, formattedField.boundary, true)
    let compParcels = comps.parcels
    let items = []
    for(const record in compParcels){
      for(const parcel of compParcels[record].parcels){
        let item = {
          "Shape": parcel.Shape,
          "ParcelID": parcel.Parcel_ID
        }
        items.push(item)
      }
    }
    let req = {"compInfo": items}
    let info = await df.getComparablesInformation(req, user.token)
    let compsInfo = JSON.parse(info)
    console.log(compsInfo);

    let results = {}
    for(const item of compsInfo){
      if (results[item.ParcelID] === undefined){
        let curr = compsInfo.filter(x => x.ParcelID === item.ParcelID)
        // get values for each ParcelID needed here (NCCPI, CropCode, etc..)
        let ssurgoResults = curr.filter(x => x.NCCPI !== undefined)
        let precipResults = curr.filter(x => x.p !== undefined)
        let avgNccpi = ssurgoResults.map(x => x.NCCPI).reduce((a,b) => a+b, 0) / ssurgoResults.length
        let avgPrecip = precipResults.map(x => x.p).reduce((a,b) => a+b, 0) / 12
        results[item.ParcelID] = {
          NCCPI: avgNccpi,
          Precipitation: avgPrecip
        }
      }
    }

    for(const record in compParcels){
      let polygons = []
      for(let parcel of compParcels[record].parcels){
        if(results[parcel.Parcel_ID] !== undefined){
          parcel['NCCPI'] = results[parcel.Parcel_ID].NCCPI
          parcel['Precipitation'] = results[parcel.Parcel_ID].Precipitation
        }
        let feature = {
          type:"Feature",
          geometry: parcel.GeoJSON,
          properties: {}
        }
        polygons.push(feature)
      }
      console.log(polygons)
      if(polygons.length > 1){
        let newPoly = polygons[0]
        for(let i=1; i < polygons.length; i++){
          newPoly = turf.union(newPoly, polygons[i])
        }
        compParcels[record]['GeoJSON'] = newPoly
      }
      else{
        compParcels[record]['GeoJSON'] = polygons[0]
      }
    }

    console.log("COMPS", compParcels)
    setComparables(Object.values(compParcels))
    setCompsLoaded(true)

  }

  const getCountyAverages = async (stateFips, countyFips) => {
    let results = await df.getCountyAverages(stateFips, countyFips)
    console.log(results)
    if(results !== undefined){
      setCountyAverages(results)
    }
  }

  const getSSURGO = async () => {
    setSsurgoLoading(true)
    setSSurgoError('')
    let ssurgoReq = {
      AOI: formattedField.boundary,
      Projection: null,
      Resolution: null,
      Soil_Parameter: null,
      Product: null
    }

    let ssurgoResponse = await df.ssurgoForZones(ssurgoReq)
    console.log(ssurgoResponse)
    if(ssurgoResponse === undefined){
      setSsurgoLoading(false)
      setSSurgoError('We were unable to load SSURGO soil data for this field. Please try another field.')
    }
    else{
      let ssurgoGeo = ssurgoResponse.attributes.GeoJSON
      let data = []
      for(let i =0; i< ssurgoGeo.features.length; i++){
        ssurgoGeo.features[i].properties.color = zonesColors[i]
        data.push(ssurgoGeo.features[i].properties)
      }
  
      setSoilAverage(ssurgoResponse.attributes.Weighted_Average)
      setSoilGeo(ssurgoGeo)
      setSoilLegend(data)
      setSsurgoLoading(false)
    }
  }

  const getPolaris = async (variable='ph', depth='0-5', stat='mean') =>{
    setPolarisLoading(true)
    setPolarisError('')
    try {
      let polarisReq = {
        Soil_Parameter: variable,
        Depth_Range: depth,
        Statistic: stat,
        Legend_Ranges: null,
        aoi: formattedField.boundary,
        Resolution: "0.0001"
      }

      let polarisProcessing = await df.processPolaris(polarisReq)
      let polarisResponse = JSON.parse(polarisProcessing)
      if(polarisResponse.Error){
        console.log("Error getting polaris", polarisResponse.Error)
        setPolarisError('We were unable to get Polaris Data for your field. Please check back later or try another field')
      }
      else{
        let polarisImage = polarisResponse.Features[0].attributes.pngb64
        let polarisExtent = polarisResponse.Features[0].attributes.Extent
        let convertedExtent = polarisExtent.split(", ").map(x => parseFloat(x))
        let bounds = convertExtentToBounds(convertedExtent)
        const sum = polarisResponse.Features[0].attributes.Legend.map(x => x.Mean).reduce((a,b) => a + b, 0)
        let legend = polarisResponse.Features[0].attributes.Legend
        setPolarisImage(polarisImage)
        setPolarisExtent(bounds)
        setPolarisLegend(polarisResponse.Features[0].attributes.Legend)
        let average = sum / polarisResponse.Features[0].attributes.Legend.length
        if (selectedParcel.Polaris?.ph_mean_30_60_Mean){
          average = selectedParcel.Polaris.ph_mean_30_60_Mean[0].Value
        }
        setPolarisAverage(average)
        setPolarisLoading(false)
      }
    } catch (err) {
      console.log(`Problem getting Polaris: ${err}`)
      setPolarisLoading(false)
      setPolarisError('We were unable to get Polaris Data for your field. Please check back later or try another field')
    }
  }

  const getCDL = async (year=selectedCdlYear) => {
    setCdlLoading(true)
    setCdlError('')
    try {
      let CDLReq = {
        aoi: formattedField.boundary,
        Projection: "EPSG:4326",
        Resolution: "0.0001",
        years: null,
        product: null,
      }

      let years = [year.toString()]
      CDLReq.years = JSON.stringify(years)

      let CDLProcessing = await df.processCDL(CDLReq)
      let CDLResponse = JSON.parse(CDLProcessing)
      if(CDLResponse.error || CDLResponse.message || CDLResponse.status === "FAILURE"){
        setCdlImage("")
        setCdlExtent([])
        setCdlLegend([])
        setCdlLoading(false)
        return false
      }

      let info = CDLResponse[year].attributes
      let CDLImage = info.pngb64

      let CDLExtent = convertExtentToBounds(info.Extent.split(", ").map((x) => parseFloat(x)))

      let legend = CDLResponse[`${year}`].attributes.Legend
      //console.log("CDL LEGEND", legend)

      let sortedLegend = legend.sort((a,b) => b.Acres-a.Acres )

      setCdlImage(CDLImage)
      setCdlExtent(CDLExtent)
      setCdlLegend(sortedLegend)
      setCdlLoading(false)
      return true
    } catch (e) {
      console.log(`problem getting cdl: ${e}`)
      setCdlLoading(false)
      return false
    }
  }


  const updateParcel = async (type, value) => {
    let parcelCopy = {...selectedParcel}
    let exists = []
    let updateTo = null;
    let req = {
      FieldName: selectedParcel.FieldName, 
      FieldID: selectedParcel.FieldID,
      OrganizationID: selectedParcel.OrganizationID,
      BoundaryID: selectedParcel.BoundaryID
    }

    switch(type.toLowerCase()){
      case "appraisal":
        //call to update appraisal date
        parcelCopy.AppraisalDate = selectedParcelDate
        df.updateAppraisalDate(user.token, selectedParcel.BoundaryID, selectedParcelDate)
        setSelectedParcel(parcelCopy)
        setEditAppraisal(false)
        break;
      case "name":
        
        req['FieldName'] = selectedParcelName
        req['ReportName'] = selectedParcel.ReportName
        req['DocumentName'] = selectedParcel.DocumentID 
        let status = await df.updateParcelInfo(req, user.token)
        parcelCopy.FieldName = selectedParcelName
        //call to update in db
        setSelectedParcel(parcelCopy)
        setEditName(false)
        break

      case "document":
        let docName = "";
        if(selectedParcelDoc !== null){
          docName = selectedParcelDoc.name ? selectedParcelDoc.name : documentInput
        }
        else{
          docName = documentInput
        }

        exists = documents.filter(x => x.name === docName)
        if(exists.length === 0){
          //create new document
          let newDoc = await createNewDocument(docName)
          exists[0] = newDoc
        }
        
        //update parcels associated document
        updateTo = exists[0]

        //get all needed info for update endpoint
        // let parcelReport = reports.filter(x => x.name === selectedParcel.ReportName)

        //dont include id of report or endpoint may think we are updating report
        req['DocumentID'] = updateTo.id
        req['DocumentName'] = updateTo.name
        req['ReportName'] = selectedParcel.ReportName //need report name for fieldinfo table update

        let docStatus = await df.updateParcelInfo(req, user.token)
        console.log(docStatus)

        let docCopy = [...documents]
        docCopy.push(updateTo)
        setDocuments(docCopy)

        parcelCopy.DocumentID = docName
        setSelectedParcel(parcelCopy)
        setSelectedParcelDoc({})
        setDocumentInput("")
        setEditDocument(false)

        break;
      case "report":
        let reportName = ""
        if(selectedParcelReport !== null){
          reportName = selectedParcelReport.name ? selectedParcelReport.name : reportInput
        }
        else{
          reportName = reportInput
        }

        exists = reports.filter(x => x.name === reportName)
        if(exists.length === 0){
          //create new report
          let newReport = await createNewReport(reportName)
          exists[0] = newReport
        }

        //update parcels associated report
        updateTo = exists[0]

        req['ReportName'] = updateTo.name
        req['ReportID'] = updateTo.id
        req['DocumentName'] = selectedParcel.DocumentID

        let reportStatus = await df.updateParcelInfo(req, user.token)

        let repCopy = [...reports]
        repCopy.push(updateTo)
        setReports(repCopy)
        
        parcelCopy.ReportName = reportName
        setSelectedParcel(parcelCopy)
        setSelectedParcelReport({})
        setReportInput("")
        setEditReport(false)
        break
      default:
        break;
    }
    refreshParcels()
  }

  const createNewDocument = async (name) => {
    let newID = await df.getNewGUID("Farms")
    let orgID = selectedParcel.OrganizationID
    console.log(newID, name, orgID)
    await df.createNewFarm(newID, name, orgID.toString(), 'Ag-Analytics', user.token)
    let newDoc = {id: newID, name: name}
    //update document list?

    return newDoc
  }

  const createNewReport = async (name) => {
    let newID = await df.getNewGUID("Clients")
    let orgID = selectedParcel.OrganizationID
    await df.createClient(newID, name, orgID, 'Ag-Analytics', user.token)
    let newReport = {id: newID, name:name}
    //update report list?

    return newReport
  }

  const handleAutoCompleteChange = (val, type) => {
    if(type === "document"){
      if(val !== null){
        setDocumentInput(val.name)
        setSelectedParcelDoc(val)
      }
      else{
        setSelectedParcelDoc(val)
        setDocumentInput("")
      }
    }
    else{
      if(val !== null){
        setReportInput(val.name)
        setSelectedParcelReport(val)
      }
      else{
        setReportInput("")
        setSelectedParcelReport(val)
      }
    }
  }

  const formatDate = (dateString) => {
    if(exists(dateString)){
      let noT = dateString.split("T")[0]
      let brkn = noT.split("-")
      let correct = [brkn[1], brkn[2], brkn[0]].join("/")
      return correct
    }
    else{
      return
    }
    
  }

  const handleWeatherTypeChange = (val) => {
    let selected = weatherTypes.filter(x => x.value === val.value)[0]
    setSelectedWeatherType(selected)
  }

  const getPath = (field) => {
    //console.log(field)
    try {
      const shape = wkt.parse(field.WKT);
      const width = 120
      const projection = d3.geoMercator()
      projection.fitExtent([[10, 10], [width, width]], shape);
      const geoPath = d3.geoPath(projection);
      return geoPath(shape)

    } catch (err) {
      console.log(err)
      return ''
    }
  }

  return(
    <Drawer 
      open={open}
      onClose={() => setOpen(false)}
      anchor={'right'}
      variant={'temporary'}
      PaperProps={{className: classes.drawer}}
    >
      <Box className={classes.drawerConentContainer}>
        <Box style={{padding:'10px'}}>
          <Button 
            variant="contained"
            color="primary"
            style={{ height: "45px", marginLeft: '1rem'}}
            onClick={() => setOpen(false)}
          >
            <ArrowBackIcon/> Return To Summaries
          </Button>
        </Box>

        <Box> {/** Parcel info (name, report, doc) */}
          <Box className={classes.detailsContainer}>{/** details container */}
            <Box className={classes.detailsColumn}>
              <Typography variant="body2" color="textSecondary" component="p">
                <svg id={"shapesvg"} width='140' height='140'>
                  <path fill="none" stroke="#F05922" strokeWidth="3" d={getPath(selectedParcel)} />
                </svg>
              </Typography>
              <Typography variant="h6" color="textSecondary" >
                Acres: {Math.round(selectedParcel.Acres * 100) / 100}
              </Typography>
            </Box>
            <Box className={classes.detailsColumn} style={{justifyContent: 'space-evenly'}}>
              <Box className={classes.detailsRow}>
                <Box>
                  <Typography variant="h5">
                    {`${selectedParcel.County}, ${selectedParcel.State}`}
                  </Typography>
                </Box>
              </Box>
              <Box className={classes.detailsRow}>
                <Box>
                  <Typography variant="h5">
                    {`${selectedParcel.Latitude?.toFixed(4)}, ${selectedParcel.Longitude?.toFixed(4)}`}
                  </Typography>
                </Box>
              </Box>
            </Box>
            <Box className={classes.detailsColumn}>
              <Box className={classes.detailsRow}> 
                <Box>
                  <Box>
                    Parcel Name
                  </Box>
                  {editName ?
                    <Box display="flex">
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        type="text"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={selectedParcelName}
                        onChange={(event) => setSelectedParcelName(event.target.value)}
                      />
                      <Tooltip title="Save Changes">
                        <Button onClick={() => updateParcel("name")}>
                          <SaveOutlinedIcon/>
                        </Button>
                      </Tooltip>
                      <Tooltip title="Close">
                        <Button onClick={() => setEditName(false)}>
                          <HighlightOffIcon/>
                        </Button>
                      </Tooltip>
                    </Box>
                  :
                    <Box display="flex">
                      <Box className={classes.disabledInput}>
                        {selectedParcel.FieldName}
                      </Box>
                      <Tooltip title="Edit Parcel Name">
                        <Button onClick={() => setEditName(true)}>
                          <EditOutlinedIcon/>
                        </Button>
                      </Tooltip>

                    </Box>
                  }
                </Box>

                <Box>
                  <Box>
                    Appraisal Date
                  </Box>
                  {editAppraisal ?
                    <Box
                    display='flex'
                    flexWrap='wrap'
                    alignItems='center'
                    >
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={selectedParcelDate}
                        onChange={(event) => setSelectedParcelDate(event.target.value)}
                      />
                      <Tooltip title="Save Changes">
                        <Button onClick={() => updateParcel("appraisal")}>
                          <SaveOutlinedIcon/>
                        </Button>
                      </Tooltip>

                      {exists(selectedParcel.AppraisalDate) && 
                        <Tooltip title="Close">
                          <Button onClick={() => setEditAppraisal(false)}>
                            <HighlightOffIcon/>
                          </Button>
                        </Tooltip>
                      }
                      
                    </Box>
                  :
                    <Box display="flex">
                      <Box className={classes.disabledInput}>
                        {formatDate(selectedParcel.AppraisalDate)}
                      </Box>
                      <Tooltip title="Edit Appraisal Date">
                        <Button onClick={() => setEditAppraisal(true)}>
                          <EditOutlinedIcon/>
                        </Button>
                      </Tooltip>
                    </Box>
                    
                  }
                  
                </Box>
              
              </Box>

              <Box className={classes.detailsRow}>
                <Box>
                  <Box>
                    Document
                  </Box>
                  {editDocument ?
                    <Box display="flex">

                      <Autocomplete
                        className={classes.input}
                        freeSolo
                        options={documents}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        onChange={(e, val) => handleAutoCompleteChange(val, "document")}
                        value={selectedParcelDoc}
                        inputValue={documentInput}
                        onInputChange={(event, newInputValue) => {
                          setDocumentInput(newInputValue);
                        }}
                      />

                      <Tooltip title="Save Changes">
                        <Button onClick={() => updateParcel("document")}>
                          <SaveOutlinedIcon/>
                        </Button>
                      </Tooltip>
                      <Tooltip title="Close">
                        <Button onClick={() => setEditDocument(false)}>
                          <HighlightOffIcon/>
                        </Button>
                      </Tooltip>
                    </Box>
                  :
                    <Box display="flex">
                      <Box className={classes.disabledInput}>
                        {selectedParcel.DocumentID}
                      </Box>
                      <Tooltip title="Edit Document">
                        <Button onClick={() => setEditDocument(true)}>
                          <EditOutlinedIcon/>
                        </Button>
                      </Tooltip>

                    </Box>
                  }
                </Box>
                
                <Box>
                  <Box>
                    Report
                  </Box>
                  {editReport ?
                    <Box display="flex">

                      <Autocomplete
                        className={classes.input}
                        freeSolo
                        options={reports}
                        getOptionLabel={(option) => option.name}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        onChange={(e, val) => handleAutoCompleteChange(val, "report")}
                        value={selectedParcelReport}
                        inputValue={reportInput}
                        onInputChange={(event, newInputValue) => {
                          setReportInput(newInputValue);
                        }}
                      />

                      <Tooltip title="Save Changes">
                        <Button onClick={() => updateParcel("report")}>
                          <SaveOutlinedIcon/>
                        </Button>
                      </Tooltip>
                      <Tooltip title="Close">
                        <Button onClick={() => setEditReport(false)}>
                          <HighlightOffIcon/>
                        </Button>
                      </Tooltip>
                    </Box>
                  :
                    <Box display="flex">
                      <Box className={classes.disabledInput}>
                        {selectedParcel.ReportName}
                      </Box>
                      <Tooltip title="Edit Report">
                        <Button onClick={() => setEditReport(true)}>
                          <EditOutlinedIcon/>
                        </Button>
                      </Tooltip>

                    </Box>
                  }
                </Box>
              
              </Box>
            </Box>
            
            
          </Box>
        </Box>

        <Box> {/** Details (weather, ssurgo, etc.) */}
          <Box className={classes.infoContainer}>
            <Box className={classes.infoNav}>
              <Box 
                className={activeTab ==="SSURGO" ? classes.activeInfoTab : classes.infoTab}
                onClick={() => setActiveTab("SSURGO")}
              >
                SSURGO
              </Box>
              <Box 
                className={activeTab ==="Polaris" ? classes.activeInfoTab : classes.infoTab}
                onClick={() => setActiveTab("Polaris")}
              >
                Polaris
              </Box>
              <Box 
                className={activeTab ==="CDL" ? classes.activeInfoTab : classes.infoTab}
                onClick={() => setActiveTab("CDL")}
              >
                Crop History
              </Box>
              <Box 
                className={activeTab ==="Weather" ? classes.activeInfoTab : classes.infoTab}
                onClick={() => setActiveTab("Weather")}
              >
                Weather
              </Box>
              <Box 
                className={activeTab ==="Boundary" ? classes.activeInfoTab : classes.infoTab}
                onClick={() => setActiveTab("Boundary")}
              >
                Boundaries
              </Box>
              <Box 
                className={activeTab ==="Comps" ? classes.activeInfoTab : classes.infoTab}
                onClick={() => setActiveTab("Comps")}
              >
                Comparables
              </Box>
            </Box>
            <Box className={classes.info}>
              {activeTab === "SSURGO" &&
                <Box className={classes.soilContainer}>
                  <SoilData
                    field={formattedField}
                    loading={ssurgoLoading}
                    //loading={true}
                    geo={soilGeo}
                    legend={soilLegend}
                    average={soilAverage}
                    mobileBreakPoint={mobileBreakPoint}
                    errorMessage={ssurgoError}
                    slideDirection={'up'}
                    loaderPosition={'50%'}
                    underwriting={true}
                    countyAverage={selectedParcel?.SSURGO?.NCCPI.filter(x=>x.Variable_Name==='County_Avg_NCCPI')[0]?.Value}
                  />
                </Box>
              }
              {activeTab === "Polaris" && 
                <Box>
                  <Polaris
                    field={formattedField}
                    loading={polarisLoading}
                    getPolaris={getPolaris}
                    image={polarisImage}
                    setImage={setPolarisImage}
                    legend={polarisLegend}
                    setLegend={setPolarisLegend}
                    extent={polarisExtent}
                    setExtent={setPolarisExtent}
                    average={polarisAverage}
                    setAverage={setPolarisAverage}
                    mobileBreakPoint={mobileBreakPoint}
                    errorMessage={polarisError}
                    underwriting={true}
                    slideDirection={'up'}
                    loaderPosition={'50%'}
                    countyAverage = {selectedParcel?.Polaris?.ph_mean_30_60_CountyAvg[0].Value}
                  />
                </Box>
              }
              {activeTab === "Weather" &&
                <Box>
                  <Select
                    className={classes.selections}
                    variant="outlined"
                    MenuProps={MenuProps}
                    value={selectedWeatherType.value}
                    onChange={(e) => handleWeatherTypeChange(e.target)}
                  >
                    {
                      weatherTypes.map((x, i) => (
                        <MenuItem
                          key={i}
                          value={x.value}
                          name={x.name}
                        >
                          {x.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  <WeatherChart
                    data={selectedParcel.Weather}
                    type={selectedWeatherType.name}
                  />
                </Box>
                
              }
              {activeTab === "CDL" &&
                <Box>
                  <CropLand
                      field={formattedField}
                      loading={cdlLoading}
                      getCDL={getCDL}
                      years={cdlYears}
                      selectedYear={selectedCdlYear}
                      setSelectedYear={setSelectedCdlYear}
                      image={cdlImage}
                      setImage={setCdlImage}
                      legend={cdlLegend}
                      setLegend={setCdlLegend}
                      extent={cdlExtent}
                      setExtent={setCdlExtent}
                      mobileBreakPoint={mobileBreakPoint}
                      errorMessage={cdlError}
                      tillable={false}
                      underwriting={true}
                      slideDirection={'up'}
                      loaderPosition={'50%'}
                    />
                </Box>
              }
              {activeTab === "Boundary" &&
                <Box>
                  <PreviousBoundariesInfo
                    parcelBoundaries={previousBoundaries}
                  />
                </Box>                
              }
              {activeTab === "Comps" &&
                <Box>
                  <Comps
                    comparables={comparables}
                    countyAverages={countyAverages}
                    loaded={compsLoaded}
                    selectedParcel={selectedParcel}
                  />
                </Box>
              }
                
            </Box>
          </Box>
        </Box>

      </Box>
    </Drawer>
    

  )




 }
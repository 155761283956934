
// Leaflet and Turf
import * as L from "leaflet";
import * as turf from "@turf/turf";

import home from '../../../images/houseIcon.png'
import dollar from '../../../images/dollarIcon.png'

export const drawParcels = (parcels, map, valueRadius, inputProperty, foundParcels, parcelLayer, setActiveParcel) =>{
  //add radius circle to map and zoom to extent
  let circle = turf.circle(parcels.center, parcels.radius, {units: "miles"})
  valueRadius.current.clearLayers()
  valueRadius.current.addData(circle)
  valueRadius.current.eachLayer(layer => {
    layer.setStyle({
      fillOpacity: 0.05
    })
  })
  if(map.hasLayer(valueRadius.current)){
    map.removeLayer(valueRadius.current)
  }
  valueRadius.current.addTo(map)
  // let radiusBounds = valueRadius.current.getBounds()
  // map.fitBounds(radiusBounds)
  
  //add selected property icon
  if(inputProperty.current !== null){
    inputProperty.current.remove()
  }
  let homeIcon = L.icon({
    iconUrl: home,
    iconSize: [30,30]
  })
  let fieldGeo = L.geoJSON(parcels.field)
  inputProperty.current = L.marker(fieldGeo.getBounds().getCenter())
  inputProperty.current.setIcon(homeIcon)
  inputProperty.current.on('click', function(e){
    //on click zoom to property
    map.fitBounds(fieldGeo.getBounds(), {padding: [30,30]})
  })

  inputProperty.current.addTo(map)
  if(parcels.parcels !== null){
    //add other properties to map
    for(const item of foundParcels.current){
      item.removeFrom(map)
    }
    foundParcels.current = [];
    let parcelIcon = L.icon({
      iconUrl: dollar,
      iconSize: [30,30]
    })
    let boundaries = {type: "FeatureCollection", features: []}
    for(let i in parcels.parcels){
      let sold = parcels.parcels[i]
      for(const parcel of sold.parcels){
        let parcelGeo = L.geoJSON(parcel.GeoJSON)
        let geojson = parcelGeo.toGeoJSON()
        geojson.features.map(x => boundaries.features.push(x))
        let center = parcelGeo.getBounds().getCenter();
        let parcelMarker = L.marker(center)
        parcelMarker.setIcon(parcelIcon)

        //zoom to parcel and draw it on map
        parcelMarker.on('click', function(e){
          map.flyTo(e.target._latlng, 16, {duration: 0.1})
          //get index of parcel in foundParcels
          console.log(e)
          let id = e.target._leaflet_id
          let indx = foundParcels.current.findIndex(x => x._leaflet_id === id)
          setActiveParcel({index: indx, parcelMarkers: foundParcels})
        })

        foundParcels.current.push(parcelMarker)
        parcelMarker.addTo(map)
      }
    }
    parcelLayer.current.clearLayers()
    if(map.hasLayer(parcelLayer.current)){
      map.removeLayer(parcelLayer.current)
    }
    parcelLayer.current.addData(boundaries)
    parcelLayer.current.addTo(map)
    setActiveParcel({index: -1, parcelMarkers: foundParcels})
    valueRadius.current.bringToBack()
  }
  else{
    for(const item of foundParcels.current){
      item.remove()
    }
    foundParcels.current = []
  }
}
import React, { useEffect, useState, useContext, useRef } from "react";
import {
  makeStyles,
  createMuiTheme,
  MuiThemeProvider,
} from "@material-ui/core/styles";
import {
  Box,
  Button,
  Divider,
  Grid,
  TextField,
  Typography,
  Menu,
  MenuItem,
  Slider,
  Tooltip,
  Select,
  Switch,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from '@material-ui/icons/Cancel';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import PublishIcon from "@material-ui/icons/Publish";
import UndoIcon from "@material-ui/icons/Undo";
import ClearIcon from "@material-ui/icons/Clear";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SaveIcon from "@material-ui/icons/Save";
import FolderOpenIcon from "@material-ui/icons/FolderOpen";
import { max } from "date-fns/esm";
import { min } from "date-fns";
import { FieldContext } from "../Context/FieldContext";
import { coordAll, polygon, getType } from "@turf/turf";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import { create } from "lodash";
import { getBoundary } from "../../utils/dataFetchers";
import { exists, numFormat } from "../../utils/helpers";
import { Endpoints } from "../../constants/Endpoints";
import { AgToolTip } from '../../utils/customComponents'
import { useWindowDimensions } from "../../utils/dimensions";
import { createField } from './MapFunctions/fieldInteractions';
import { drawFieldsInBounds } from './MapFunctions/helpers';
import { drawMap } from './MapFunctions/drawMap';
import * as L from "leaflet";
import * as turf from "@turf/turf";
import * as df from "../../utils/dataFetchers";
import * as wkt from "terraformer-wkt-parser";
import {
  rotateIcon,
  horizontalIcon,
  verticalIcon,
  acresIcon
} from './Styles/icons';
import { fieldToolTip } from './MapFunctions/helpers'
import { Tiles } from '../../constants/Tiles'



const useStyles = makeStyles((theme) => ({
  mapControls: {
    color: theme.palette.greys.dark,
    backgroundColor: '#ffffff',
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    width: "100%",
    padding: "15px",
  },
  dropdown: {
    fontSize: '14px',
    marginLeft: '5px',
  },
  select: {
    ...theme.hover,
    display: 'flex',
    width: '100px',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.black,
  },
  icon: theme.icon,
  greenIcon: theme.greenIcon,
}));

const colorHash = {
  normal: "#f4f8f9",
  normalGray: "#808080",
  highlighted: "#33a7ff",
};

const stateStyle = {
  fillOpacity: 0.3,
  fillColor: colorHash.normal,
  weight: 1,
  dashArray: "3",
  color: colorHash.normal,
  lineJoin: "miter",
  lineCap: "square",
  opacity: 0.8,
};

const countyStyle = {
  fillOpacity: 0.6,
  color: colorHash.normalGray,
  weight: 2,
};

const plssStyle = {
  fillColor: colorHash.normal,
  color: colorHash.normalGray,
  weight: 2,
  opacity: 0.6,
};

const sectionStyle = {
  color: colorHash.normalGray,
  fillColor: colorHash.normal,
  weight: 2,
  opacity: 0.6,
};

const cluStyle = {
  opacity: 0.6,
  fillColor: colorHash.normal,
  weight: 1,
  color: colorHash.normal,
};

const selectedStyle = {
  fillColor: "#b3e5fc",
  color: "#1565c0",
  fillOpacity: 0.25,
};

// Zone drawing and grid roation variables
let zoneNum = 1;
let EWoffset = 0;
let NSoffset = 0;
let rotateVal = 0;
let gridSize = 0;
let slider1;
let slider2;
let slider3;
let slider4;
let selected = {};
let fillColors = [  "#ccee5f",  "#c34016",  "#742a4d",  "#f3750e",  "#e74daa",
"#f4ae62",  "#5712da",  "#fff200",  "#3053c9",  "#69dc27",  "#13a28c",  "#97e8d1",
]
let zonesColors = [
  "#c34016",
  "#f3750e",
  "#f4ae62",
  "#fff200",
  "#ccee5f",
  "#69dc27",
  "#97e8d1",
  "#13a28c",
  "#3053c9",
  "#5712da",
  "#e74daa",
  "#742a4d",
];
let usedColors = []; //so we dont accidentally repeat colors

export function DigsMap({
  selectField,
  handleZone,
  canEdit,
  geoJson,
  includeClus,
  includeSSURGO,
  containerHeight,
  fields,
  coordinatesToRemove,
  setCoordinatesToRemove,
  selectedField,
  removeFromOrder,
  goToAddServices,
  getFieldImage,
}) {
  /**
   * @param {Function} selectField Select field on click
   * @param {Function} handleZone Pass field data and type (ex. grid)
   * @param {Function} goToAddServices Advance to add services tab
   * @param {Bool} canEdit Show controls for interaction with map
   * @param {Object} geoJson For drawing variety or editing selected CLU
   * @param {Bool} includeClus Determine if CLUs should be added to map
   * @param {Bool} includeSSURGO Determine if SSURGO zone option is available
   * @param {Number} containerHeight Height of containing element in pixels
   * Handle all user interactions with drawing and editing polygons.
   * Function handleZone to allow parent function to interact with drawn layers.
   * It takes two paramters. The first can vary in structure based on the second,
   * which is the action the function will perform.
   */

  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [fieldData, setFieldData] = useContext(FieldContext);

  const [zoomLevel, setZoomLevel] = useState(6)

  // Fields already drawn on map
  const [addedFields, setAddedFields] = useState([]);

  // Block field selection on click if drawing polygon
  const [drawing, setDrawing] = useState(false)

  const [map, setMap] = useState(null);

  const [zone, setZone] = useState({});
  const [editedZones, setEditedZones] = useState([]);
  const [drawnZones, setDrawnZones] = useState([]);
  const [drawnLayer, setDrawnLayer] = useState(null);

  const [gridSlider, setGridSlider] = useState(true);

  // is it open or not
  const [zoneToolsDropdown, setZoneToolsDropdown] = useState(null);
  const [tooltip, setToolTip] = useState("Editing Zone: " + zoneNum);

  // selection menu for mobile
  const [selectOpen, setSelectOpen]  = useState(null);

  // grid, polygon, etc...
  const [activeTool, setActiveTool] = useState("");
  const [cancelButton, setCancelButton] = useState(false);
  const [saveBtn, setSaveBtn] = useState(true);

  const [fieldGeo, setFieldGeo] = useState(null);

  // Feature and type of clu or polygon to create new field from
  const [newField, setNewField] = useState(null);

  const [fitNewBounds, setFitNewBounds] = useState(false);

  // Slicing (segment/split field)
  const [slicing, setSlicing] = useState(false);
  const slicingMode = useRef(false);
  const currentSlice = useRef(null);
  const slicedZones = useRef([]);
  const slicedMap = useRef(L.geoJson(null));

  const [editing, setEditing] = useState(false);
  const [editedBoundary, setEditedBoundary] = useState(null);

  let maxZoneNum = 1;
  let fillColor = "#E7745F";

  const [enableClus, setEnableClus] = useState(true)
  const showClus = useRef(true)

  // Layers
  const stateLayer = useRef(L.geoJSON(null, {
    style: stateStyle,
    onEachFeature: highlightMousover,
  }));

  const countyLayer = useRef(L.geoJSON(null, {
    style: countyStyle,
    onEachFeature: highlightMousover,
  }));

  const plssLayer = useRef(L.geoJSON(null, {
    style: plssStyle,
    onEachFeature: highlightMousover,
  }))

  const sectionLayer = useRef(L.geoJSON(null, {
    style: sectionStyle,
    onEachFeature: highlightMousover,
  }))

  const cluLayer = useRef(L.geoJSON(null, {
    style: cluStyle,
    onEachFeature: cluEvents,
    pmIgnore: false,
  }))

  const fieldLayer = useRef(L.featureGroup(null));
  const gridlayer = useRef(L.geoJSON(null));
  const createdZones = useRef(L.geoJSON(null));
  const ssurgoLayers = useRef(L.geoJSON(null));
  const inBounds = useRef(L.layerGroup()); // currently unused in this component

  useEffect(() => {
    drawMap(
      stateLayer,
      countyLayer,
      plssLayer,
      sectionLayer,
      cluLayer,
      fieldLayer,
      inBounds,
      setMap,
      null,
      setZoomLevel,
      handleNewField,
      showClus,
      false,
      drawFieldsInBounds,
      'map-digs',
      false
    );
  }, []);

  useEffect(() => {
    if (newField !== null) {
      // Remove poly layer
      setDrawing(false)
      createNewField(newField.feature, newField.type, fieldData.selectedField, newField.layer)
      setNewField(null)
    }

  }, [newField])

  useEffect(() => {
    if (editing && selectedField !== null) {
      // need to do this here to has access to fresh state
      try {
        drawField(selectedField, true)
      } catch (err) {
        console.log(err)
      }
    }
  }, [editing])

  useEffect(() => {
    if (editedBoundary && map !== null) {

      setFieldData({
        ...fieldData,
        selectedField: { id: '', name: '', farmId: '', farm: '', orgId: '', org: '', boundaryId: '' },
      })

      let updatedField = JSON.parse(JSON.stringify(selectedField))
      updatedField.points = editedBoundary.geometry.coordinates
      updatedField.geometry = editedBoundary.geometry
      updatedField.features = editedBoundary
      updatedField.boundary = JSON.stringify(editedBoundary)
      updatedField.acres = turf.convertArea(turf.area(editedBoundary), 'meters', 'acres');
      const centroid = turf.centroid(editedBoundary);
      updatedField.latitude = centroid.geometry.coordinates[1]
      updatedField.longitude = centroid.geometry.coordinates[0]

      selectEdited(updatedField, selectedField)
    }
  }, [editedBoundary])

  const selectEdited = async (editedField, originalField) => {
    const edited = await selectField(editedField)
  }

  useEffect(() => {
    if (selectedField !== null && map !== null) {
      try {
        const features = JSON.parse(selectedField.boundary);
        const geo = {
          type: "FeatureCollection",
          features: [features],
        };
        const newCollection = L.geoJson(geo)
        map.fitBounds(newCollection.getBounds(), {padding: [30,30]});
      } catch (err) {
        console.log(err)
      }
    }
  }, [selectedField, map])


  const createNewField = async (feature, type, data, layer) => {
    const field = await createField(feature, type, data);
    const selected = await selectField(field)

    // after field has been drawn and image taken, remove polylayer
    if (type === 'polygon') {
      map.removeLayer(layer)
    }
  }

  useEffect(() => {
    // determine if save and cancel buttons should be displayed
    if (activeTool !== "") {
      setCancelButton(true);
    }
    if (activeTool !== "") {
      setSaveBtn(false);
    } else {
      setSaveBtn(true);
    }
  }, [activeTool]);

  useEffect(() => {
    if (!gridSlider) {
      addGridControls();
    }
  }, [gridSlider]);

  useEffect(() => {
    let flag = false;
    try {
      selected.forEach(function (item) {
        if (item.selected === true && item.zone == zoneNum) {
          flag = true;
        }
      });
    } catch (err) {}
    if (zoneNum > maxZoneNum) {
      maxZoneNum = zoneNum;
    }
  }, [zoneNum]);

  useEffect(() => {
    if (map !== null && !editing) {
      clearLayers()
      clearDrawn()
      for (const field of fieldData.fields) {
        if (field.id !== fieldData.selectedField.id) {
          //console.log('drawing field')
          drawField(field)
        }
      }

      const boundaries = fieldData.fields.map(x => x.boundary)
      if (exists(fieldData.selectedField.boundary)) {
        boundaries.push(fieldData.selectedField.boundary)
      }
      const fieldsToDraw = fields.filter(x => !boundaries.includes(x.boundary))

      for (const f of fieldsToDraw) {
        if (f.points !== coordinatesToRemove) {
          //console.log('drawing field')
          drawField(f)
        }
      }

      if (noneSelected()) {
        // After all fields are drawn, fit to bounds if no field is selected
        setFitNewBounds(true)
      } else if (
        exists(fieldData.selectedField) &&
        fieldData.selectedField.points !== coordinatesToRemove
      ){
        const snap = !exists(selectedField)
        drawField(fieldData.selectedField, snap)
      } else {
        //console.log('success')
      }
    }

  }, [fieldData.fields, fields, map, coordinatesToRemove]);

  const noneSelected = () => {
    // console.log(fieldData.selectedField.id)
    // console.log(selectedField)
    if (!exists(fieldData.selectedField.id) && !(exists(selectedField))) {
      return true
    }

    if (selectedField === null) {
      return true
    } else if (selectedField.id === '') {
      return true
    } else {
      return false
    }
  }

  useEffect(() => {
    // perfrom potential breaking checks and that individual field not selected
    if (
      fitNewBounds &&
      fieldLayer.current !== null &&
      fieldLayer.current.getBounds() !== null &&
      map !== null
    ) {
      // get field layer bounds
      const bounds = fieldLayer.current.getBounds();
      // check to make has properties to fit
      if (bounds.hasOwnProperty('_southWest')) {
        map.fitBounds(bounds, {padding: [10,10]});
      }
    }
    setFitNewBounds(false)
  }, [fieldLayer.current, fitNewBounds]);

  // useEffect (() => {
  //   if (map !== null && exists(fieldData.selectedField.boundary)) {
  //     //console.log('drawing field')
  //     drawField(fieldData.selectedField, true)
  //   }
  // }, [fieldData.selectedField])

  useEffect (() => {
    if (map !== null && exists(selectedField?.boundary)) {
      //console.log('drawing field')
      drawField(selectedField, true)
    }
  }, [selectedField])


  useEffect(() => {
    // do this in useEffect to ensure proper state in passed down function
    exists(zone) && handleZone(zone, "add", fieldData.selectedField.id);
  }, [zone]);

  useEffect(() => {
    if (drawnLayer !== null) {
      setDrawnZones([...drawnZones, drawnLayer]);
    }
  }, [drawnLayer]);

  useEffect(() => {
    // when zones are edited, update drawn zones and get updated features
    // for handleZone to update at top level.
    if (editedZones.length > 0) {
      const editDrawnZones = [...drawnZones];
      const editedFeatures = [];
      for (const edited of editDrawnZones) {
        let index = drawnZones.findIndex(
          (x) => x._leaflet_id === edited._leaflet_id
        );
        const feature = getFeature(edited);
        editedFeatures.push(feature);
      }
      setDrawnZones(editDrawnZones);
      handleZone(editedFeatures, "edit");
    }
  }, [editedZones]);

  const handleNewField = (field) => {
    setNewField(field)

    setActiveTool('')
    setCancelButton(false);
  }

  const handleCluSwitch = (checked) => {
    /**
     * Update state (needed for switch control and re-renders) and ref (needed
     * for map draw controls) on switch change. After checking that map is
     * rendered, if switch is false, remove layer. If setting to true, set map
     * zoom to level CLUs are drawn at. A map event is needed to fire draw
     * function which is initiated inside of drawMap function.
     * @type {Object} event Event from switch
     */

    showClus.current = checked
    setEnableClus(checked)

    if (map !== null) {
      if (checked === false) {
        map.removeLayer(cluLayer.current)
      } else {
        // if map is zoomed to far out for clu display, zoom to that point
        if (zoomLevel < 14) {
          map.setZoom(14)
        } else {
          // need some kind of map even to start clu draw
          map.setZoom(zoomLevel)
        }
      }
    }
  }

  const getFeature = (layer) => {
    const coordinates = layer._latlngs[0].map((x) => [x.lng, x.lat]);
    coordinates.push(coordinates[0]); // ensure closed polygon
    const acres = turf.area(turf.polygon([coordinates])) / 4047;

    const feature = {
      type: "Feature",
      geometry: {
        type: "Polygon",
        coordinates: [coordinates],
      },
      properties: {
        CALCACRES: acres,
      },
    };

    return feature;
  };

  // Grid Slider
  const labels = ["0.25AC", "0.5AC", "1AC", "2AC", "4AC", "5AC", "10AC"];

  const marks = [
    {
      value: 1,
      label: "",
    },
    {
      value: 2,
      label: "",
    },
    {
      value: 3,
      label: "",
    },
    {
      value: 4,
      label: "",
    },
    {
      value: 5,
      label: "",
    },
    {
      value: 6,
      label: "",
    },
    {
      value: 7,
      label: "",
    },
  ];

  /**
   * This function will take an interger value from the grid size slider and convert the integer value to
   * the size in Km to give to the addGrid function to render a grid
   * @param {int} newvalue
   */
  const gridSliderValue = (newvalue) => {
    let gridVal = 0;
    switch (newvalue) {
      case 1:
        gridVal = 0.032;
        break;
      case 2:
        gridVal = 0.045;
        break;
      case 3:
        gridVal = 0.0638;
        break;
      case 4:
        gridVal = 0.09;
        break;
      case 5:
        gridVal = 0.128;
        break;
      case 6:
        gridVal = 0.1421;
        break;
      case 7:
        gridVal = 0.201;
        break;
      default:
        gridVal = 0.0638;
        break;
    }
    addGrid(gridVal, true);
  };

  // CLU selection
  function cluEvents(feature, layer) {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: selectClu,
    });
  }

  async function selectClu(e) {
    // creates a new field from clu

    // set dynamically
    const drawing = false
    if (!drawing) {
      var layer = e.target;
      setNewField({ feature:layer.feature, type: 'clu' })
    }
  }

  // Select Field Events
  function selectedFieldEvents(feature, layer) {
    // layer.on({
    //   "pm:cut": handleFieldCut,
    // });
  }

  // remove field
  function handleFieldCut(e) {
    // var layer = e.layer;
    // layer.resetStyle();
    // changeSelectedField(layer);
    // selectedFieldLayer.bringToFront();
    // //var geo = layer.toGeoJSON()
    // layer.remove();
  }

  // function changeSelectedField(layer) {
  //   var layerCopy = L.geoJSON(layer.toGeoJSON(), {
  //     onEachFeature: selectedFieldEvents,
  //     pmIgnore: false,
  //   }).setStyle(selectedStyle);
  //
  //   selectedFieldLayer.current.clearLayers();
  //   selectedFieldLayer.current.addLayer(layerCopy);
  // }

  // Highlights
  function highlightMousover(feature, layer) {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
    });
  }

  function highlightFeature(e) {
    var layer = e.target;

    if (
      JSON.stringify(layer.feature) !== JSON.stringify(fieldData.selectedField.feature)
    ) {
      layer.setStyle({
        fillColor: colorHash.highlighted,
        color: colorHash.highlighted,
      });
    }
  }

  function resetHighlight(e) {
    var layer = e.target;
    if (
      JSON.stringify(layer.feature) !== JSON.stringify(fieldData.selectedField.feature)
    ) {
      if (layer.feature.properties.COUNTYNS) {
        layer.setStyle({
          fillColor: colorHash.normalGray,
          color: colorHash.normalGray,
        });
      } else if (layer.feature.properties.STUSPS) {
        layer.setStyle({
          fillColor: colorHash.normal,
          color: colorHash.normal,
        });
      } else if (layer.feature.properties.TWNRNG) {
        layer.setStyle(plssStyle);
      } else if (layer.feature.properties.PLSSID) {
        layer.setStyle(sectionStyle);
      } else {
        layer.setStyle(cluStyle);
      }
    }
  }

  // async function drawMap() {
  //   /**
  //    * Draw map with controls, searchbar, and all necessary layers (State, County, ClU, etc...)
  //   */
  //   const mapboxTiles = L.tileLayer(
  //     Tiles.ESRIBASEMAP
  //   );
  //
  //   const provider = new OpenStreetMapProvider();
  //   const searchControl = new GeoSearchControl({
  //     provider: provider,
  //     style: width > 880 ? "bar" : "button",
  //     position: "topright",
  //     autoComplete: true,
  //     showMarker: true, // optional: true|false  - default true
  //     showPopup: false, // optional: true|false  - default false
  //     marker: {
  //       icon: new L.Icon.Default(),
  //       draggable: false,
  //     },
  //     popupFormat: ({ query, result }) => result.label, // optional: function    - default returns result label
  //     maxMarkers: 1, // optional: number      - default 1
  //     retainZoomLevel: false, // optional: true|false  - default false
  //     animateZoom: true, // optional: true|false  - default true
  //     autoClose: true, // optional: true|false  - default false
  //     searchLabel: "Enter address", // optional: string      - default 'Enter address'
  //     keepResult: true, // optional: true|false  - default false
  //   });
  //
  //   // Add map to div by Id, set any parameters and initial view, add mapbox layer
  //   const map = L.map("map-digs", {
  //     dragging: !L.Browser.mobile,
  //     minZoom: 4,
  //     editable: true,
  //     editOptions: {
  //       lineGuideOptions: {
  //         opacity: 0,
  //       },
  //     },
  //   })
  //     .setView([41.016, -92.4083], 5)
  //     .addLayer(mapboxTiles);
  //
  //   // draw controls
  //   map.pm.addControls({
  //     drawMarker: false,
  //     drawCircle: false,
  //     drawCircleMarker: false,
  //     drawRectangle: true,
  //     dragMode: true,
  //     drawPolygon: true,
  //     cutPolygon: true,
  //     editPolygon: true,
  //     drawPolyline: true,
  //     deleteLayer: true,
  //   });
  //
  //   map.addControl(searchControl);
  //
  //   function getGeom() {
  //     var bounds = map.getBounds();
  //     var max = bounds.getNorthEast();
  //     var min = bounds.getSouthWest();
  //
  //     var geomParam = {
  //       xmin: min.lng,
  //       ymin: min.lat,
  //       xmax: max.lng,
  //       ymax: max.lat,
  //       spatialReference: { wkid: 4326 },
  //     };
  //     return geomParam;
  //   }
  //
  //   async function drawStateLayer() {
  //     try {
  //       let geom = getGeom();
  //       let type = "envelope";
  //       let level = "State";
  //       let qString =
  //         "?Geometry=" +
  //         JSON.stringify(geom) +
  //         "&Geometry_type=" +
  //         type +
  //         "&Level=" +
  //         level;
  //       fetch(Endpoints.BASEURL + "/api/Boundary/GetBoundary" + qString, {
  //         method: "GET",
  //         headers: {
  //           Accept: "application/json",
  //         },
  //       })
  //         .then((response) => {
  //           if (response.ok) {
  //             return response.json();
  //           }
  //         })
  //         .then((info) => {
  //           stateLayer.current.clearLayers();
  //           info = JSON.parse(info)
  //           let results = info.results;
  //           if (info !== undefined && info.results !== undefined) {
  //             for(const feature of results.features){
  //               if(feature.geometry.type === "GeometryCollection"){
  //                 let badNdxs = []
  //                 for(let i=0;i<feature.geometry.geometries.length;i++){
  //                   if(feature.geometry.geometries[i].type !== "Polygon"){
  //                     badNdxs.push(i)
  //                   }
  //                 }
  //                 //loop backwards over gemoetries and remove
  //                 for(let j=badNdxs.length-1; j>=0; j--){
  //                   delete feature.geometry.geometries[badNdxs[j]]
  //                 }
  //               }
  //             }
  //             let currentZoom = map.getZoom()
  //             stateLayer.current.addData(info.results);
  //             if(currentZoom <= 6){
  //               map.addLayer(stateLayer.current);
  //             }
  //           }
  //         }).catch(error => console.log(error));
  //     } catch (err) {
  //       console.log(`Problem drawing state layer`)
  //     }
  //   }
  //
  //   function drawCountyLayer() {
  //     try {
  //       let geom = getGeom();
  //       let type = "envelope";
  //       let level = "County";
  //       let qString =
  //         "?Geometry=" +
  //         JSON.stringify(geom) +
  //         "&Geometry_type=" +
  //         type +
  //         "&Level=" +
  //         level;
  //       fetch(Endpoints.BASEURL + "/api/Boundary/GetBoundary" + qString, {
  //         method: "GET",
  //         headers: {
  //           Accept: "application/json",
  //         },
  //       })
  //         .then((response) => {
  //           if (response.ok) {
  //             return response.json();
  //           }
  //         })
  //         .then((info) => {
  //           countyLayer.current.clearLayers();
  //           if (info !== undefined && info.results !== undefined) {
  //             countyLayer.current.addData(info.results);
  //             let currentZoom = map.getZoom()
  //             if(currentZoom > 6 && currentZoom <= 10.4){
  //               map.addLayer(countyLayer.current);
  //             }
  //           }
  //         }).catch(error => console.log(error));
  //     } catch (err) {
  //       console.log(`Failed to draw county layer, error: ${err}`)
  //     }
  //   }
  //
  //
  //   function drawPlssLayer() {
  //     try {
  //       let geom = getGeom();
  //       let type = "envelope";
  //       let level = "PLSS";
  //       let qString =
  //         "?Geometry=" +
  //         JSON.stringify(geom) +
  //         "&Geometry_type=" +
  //         type +
  //         "&Level=" +
  //         level;
  //       fetch(Endpoints.BASEURL + "/api/Boundary/GetBoundary" + qString, {
  //         method: "GET",
  //         headers: {
  //           Accept: "application/json",
  //         },
  //       })
  //         .then((response) => {
  //           if (response.ok) {
  //             return response.json();
  //           }
  //         })
  //         .then((info) => {
  //           if (info !== undefined && info.results !== undefined) {
  //             plssLayer.current.clearLayers();
  //             plssLayer.current.addData(info.results);
  //             let zoom = map.getZoom()
  //             if(zoom >= 10.5 && zoom <= 12){
  //               map.addLayer(plssLayer.current);
  //             }
  //           }
  //         }).catch(error => console.log(error));
  //     } catch (err) {
  //       console.log(`Problem draing plss layer`)
  //     }
  //   }
  //
  //   function drawSectionLayer() {
  //     try {
  //       let geom = getGeom();
  //       let type = "envelope";
  //       let level = "1SQMI_GRID";
  //       let qString =
  //         "?Geometry=" +
  //         JSON.stringify(geom) +
  //         "&Geometry_type=" +
  //         type +
  //         "&Level=" +
  //         level;
  //       fetch(Endpoints.BASEURL + "/api/Boundary/GetBoundary" + qString, {
  //         method: "GET",
  //         headers: {
  //           Accept: "application/json",
  //         },
  //       })
  //         .then((response) => {
  //           if (response.ok) {
  //             return response.json();
  //           }
  //         })
  //         .then((info) => {
  //           if (info !== undefined && info.results !== undefined) {
  //             sectionLayer.current.clearLayers();
  //             sectionLayer.current.addData(info.results);
  //             let currentZoom = map.getZoom()
  //             if(currentZoom > 12 && currentZoom <= 13.7){
  //               map.addLayer(sectionLayer.current);
  //             }
  //           }
  //         }).catch(error => console.log(error));
  //     } catch (err) {
  //       console.log(`Problem drawing section layer`)
  //     }
  //   }
  //
  //   function drawCluLayer() {
  //     if (showClus.current) {
  //       try {
  //         let geom = getGeom();
  //         let type = "envelope";
  //         let level = "CLU";
  //         let qString =
  //           "?Geometry=" +
  //           JSON.stringify(geom) +
  //           "&Geometry_type=" +
  //           type +
  //           "&Level=" +
  //           level;
  //         fetch(Endpoints.BASEURL + "/api/Boundary/GetBoundary" + qString, {
  //           method: "GET",
  //           headers: {
  //             Accept: "application/json",
  //           },
  //         })
  //           .then((response) => {
  //             if (response.ok) {
  //               return response.json();
  //             }
  //           })
  //           .then((info) => {
  //             if (info !== undefined && info.results !== undefined) {
  //               cluLayer.current.clearLayers();
  //               cluLayer.current.addData(info.results);
  //               if (showClus.current) {
  //                 map.addLayer(cluLayer.current);
  //                 cluLayer.current.bringToBack();
  //                 fieldLayer.current.bringToFront();
  //               }
  //             }
  //           }).catch(error => console.log(error));
  //       } catch (err) {
  //         console.log(err)
  //       }
  //     }
  //   }
  //
  //   // Determine map layers based on zoom level
  //   function mapZoomEvent() {
  //     if (map === "") {
  //       return null;
  //     }
  //     const currentZoom = map.getZoom();
  //     setZoomLevel(currentZoom)
  //     if (currentZoom <= 6) {
  //       map.removeLayer(countyLayer.current);
  //       map.removeLayer(plssLayer.current);
  //       map.removeLayer(sectionLayer.current);
  //       map.removeLayer(cluLayer.current);
  //       map.addLayer(stateLayer.current);
  //       drawStateLayer();
  //     } else if (currentZoom > 6 && currentZoom <= 10.4) {
  //       map.removeLayer(stateLayer.current);
  //       map.removeLayer(plssLayer.current);
  //       map.removeLayer(sectionLayer.current);
  //       map.removeLayer(cluLayer.current);
  //       drawCountyLayer();
  //     } else if (currentZoom >= 10.5 && currentZoom <= 12) {
  //       map.removeLayer(countyLayer.current);
  //       map.removeLayer(sectionLayer.current);
  //       map.removeLayer(stateLayer.current);
  //       map.removeLayer(cluLayer.current);
  //       drawPlssLayer();
  //     } else if (currentZoom > 12 && currentZoom <= 13.7) {
  //       map.removeLayer(plssLayer.current);
  //       map.removeLayer(countyLayer.current);
  //       map.removeLayer(stateLayer.current);
  //       map.removeLayer(cluLayer.current);
  //       drawSectionLayer();
  //     } else if (currentZoom > 13.7 ) {
  //       map.removeLayer(sectionLayer.current);
  //       map.removeLayer(plssLayer.current);
  //       map.removeLayer(countyLayer.current);
  //       map.removeLayer(stateLayer.current);
  //       drawCluLayer();
  //     }
  //
  //     // if (currentZoom < 15) {
  //     //   map.eachLayer(function(l) {
  //     //     if (l.getTooltip) {
  //     //       var toolTip = l.getTooltip();
  //     //       if (toolTip) {
  //     //         map.closeTooltip(toolTip);
  //     //       }
  //     //     }
  //     //   });
  //     // } else if (currentZoom >= 15) {
  //     //   map.eachLayer(function(l) {
  //     //     if (l.getTooltip) {
  //     //       var toolTip = l.getTooltip();
  //     //       if (toolTip) {
  //     //         map.addLayer(toolTip);
  //     //       }
  //     //     }
  //     //   });
  //     // }
  //   }
  //
  //   function mapMovedEvent() {
  //     var currentZoom = map.getZoom();
  //
  //     if (currentZoom > 6 && currentZoom <= 10.4) {
  //       drawCountyLayer();
  //     } else if (currentZoom >= 10.5 && currentZoom <= 12) {
  //       drawPlssLayer();
  //     } else if (currentZoom > 12 && currentZoom <= 13.7) {
  //       drawSectionLayer();
  //     } else if (currentZoom > 13.7) {
  //       drawCluLayer();
  //     }
  //   }
  //
  //   function setClickZoomEvent(layer, layerName) {
  //     layer.on("click", function (e) {
  //       switch (layerName) {
  //         case "sectionLayer":
  //           map.flyTo([e.latlng.lat, e.latlng.lng], 15, { duration: 0.7 });
  //           break;
  //         case "plssLayer":
  //           map.flyTo([e.latlng.lat, e.latlng.lng], 13, { duration: 0.7 });
  //           break;
  //         case "countyLayer":
  //           map.flyTo([e.latlng.lat, e.latlng.lng], 11, { duration: 0.7 });
  //           break;
  //         case "stateLayer":
  //           map.flyTo([e.latlng.lat, e.latlng.lng], 9, { duration: 0.7 });
  //           break;
  //         default:
  //         // some events we dont move on
  //       }
  //     });
  //   }
  //
  //   setClickZoomEvent(stateLayer.current, "stateLayer");
  //   setClickZoomEvent(countyLayer.current, "countyLayer");
  //   setClickZoomEvent(plssLayer.current, "plssLayer");
  //   setClickZoomEvent(sectionLayer.current, "sectionLayer");
  //   setClickZoomEvent(cluLayer.current, "cluLayer");
  //
  //   // draw and add layers
  //   drawStateLayer();
  //
  //   //map.addLayer(selectedFieldLayer.current);
  //   map.addLayer(fieldLayer.current);
  //   mapZoomEvent();
  //   map.on("zoomend", function (e) {
  //     mapZoomEvent();
  //   });
  //   map.on("moveend", function (e) {
  //     mapMovedEvent();
  //   });
  //
  //   map.on("pm:create", (e) => {
  //     try {
  //       const polyLayer = e.layer
  //       const feature = getFeature(polyLayer);
  //       setNewField({ feature: feature, type: 'polygon', layer: e.layer })
  //
  //       // remove drawn layer from map. if will be redrawn when create field
  //       //map.removeLayer(polyLayer)
  //       setActiveTool('')
  //       setCancelButton(false);
  //     } catch (e) {
  //       //console.log("check for circles");
  //       console.log(e);
  //     }
  //   });
  //
  //   setMap(map);
  // }

  /**
   * Draw variety on map based on geoJson features
   * @param  {[type]} geo GeoJSON for draw
   */
  function drawVarietyZone(geo) {
    for (var f of geo.features) {
      const geometry = f.geometry;
      const boundary =
        geometry.type === "Polygon"
          ? turf.polygon(f.geometry.coordinates)
          : turf.multiPolygon(f.geometry.coordinates);

      let newCollection = L.geoJson(boundary, {
        style: (feature) => {
          return {
            color: "#33a7ff",
            fillColor: "#80dff6",
            fillOpacity: ".35",
          };
        },
      }).addTo(map);
      //map.fitBounds(newCollection.getBounds());
    }
  }


  /* Draws field layer on map from geoJson. Attachs click function to field.
   */
  async function drawField(field, snapTo) {
    try {
      const features = JSON.parse(field.boundary);
      const geo = {
        type: "FeatureCollection",
        features: [features],
      };

      let newCollection = L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          fieldLayer.current.addLayer(layer).setStyle({
            color: "#8e24aa",
            fillColor: "#f3e5f5",
            opacity: 1,
            fillOpacity: 0,
          });

          const idsInOrder = fields.map(x => x.id)
          const inOrder = idsInOrder.includes(field.id)

          if (inOrder) {
            try {
              L.ClickableTooltip = L.Tooltip.extend({
                  onAdd: function (map) {
                      L.Tooltip.prototype.onAdd.call(this, map);

                      var el = this.getElement(),
                          self = this;
                      el.id = field.id
                      el.addEventListener('click', function() {
                          self.fire("click");
                      });
                      el.style.pointerEvents = 'auto';
                  }
              });

              var tooltip = new L.ClickableTooltip({
                  direction: 'top',
                  permanent: true,
                  noWrap: true,
                  opacity: 0.8
              });
              tooltip.setContent(
                `<div style="height: 20; width: 34;">
                  <div style="text-align: center; font-size: 12px; font-wieght: 500;">${field.name}</div>
                  <div style="margin: 2px 6px; font-size: 10px;">Acres: ${numFormat(field.acres)}</div>
                  <div style="margin: 2px 6px; font-size: 10px;">Latitude: ${numFormat(field.latitude, 2, 5)}</div>
                  <div style="margin: 2px 6px; font-size: 10px;">Longitude: ${numFormat(field.longitude, 2, 5)}</div>
                  <p style="margin: 2px 6px; text-align: center; font-size: 10px; color: ${inOrder ? 'red' : 'blue'};">
                    ${inOrder ? 'Remove from order' :'Add to order'}
                  </p>
                </div>`
              );
              tooltip.setLatLng(new L.LatLng(field.latitude, field.longitude));

              tooltip.on('click', function() {
                removeFromOrder(field)
              });
            } catch (err) {
              console.log(err)
            }
          }

          layer.bindTooltip(tooltip, {className: 'leaftletTooltipClass'})

          fieldLayer.current.on("mouseover", function (e) {
            e.layer.setStyle({ color: "#6a1b9a", fillOpacity: .05 });
          });

          fieldLayer.current.on("mouseout", function (e) {
            e.layer.setStyle({ color: "#8e24aa", fillOpacity: 0 });
          });

          fieldLayer.current.on("click", function (e) {
            e.layer.setStyle({ color: "#561280", opacity: 0.99, fillOpacity: 0 });
          });

          map.on("pm:drawstart", ({ workingLayer }) => {
            setDrawing(true);
            showClus.current = false
            map.removeLayer(cluLayer.current);
            workingLayer.on("pm:vertexadded", (e) => {
              handleSliceVertex(e);
            });
          });

          layer.on('pm:update', ({ layer, shape }) => {
            setEditedBoundary(layer.toGeoJSON())
          })

          layer.on({
            click: (e) => {
              if (!drawing) {
                selectField(field);
              }
            },
          });
        },
      }).addTo(map).on('click', function(e) {
        goToAddServices()
      });

      if (snapTo) {
        map.fitBounds(newCollection.getBounds(), {padding: [30,30]});
      }

    } catch (err) {
      //console.log(err)
    }
  }

  const addPolygon = () => {
    //setCancelButton(true);
    setActiveTool("polygon");
    //handleCluSwitch(false)
    showClus.current = false
    setEnableClus(false)
    // start polygon draw
    let draw = document.getElementsByClassName(
      "control-icon leaflet-pm-icon-polygon"
    );
    draw[0].click();
  };

  const addSquare = () => {
    //setCancelButton(true);
    setActiveTool("polygon");
    //handleCluSwitch(false)
    showClus.current = false
    setEnableClus(false)
    // start polygon draw
    let draw = document.getElementsByClassName(
      "control-icon leaflet-pm-icon-rectangle"
    );
    draw[0].click();
  };

  const editBoundary = () => {
    // switch CLUs off
    //handleCluSwitch(false)
    showClus.current = false
    setEnableClus(false)
    setEditing(true);

    // Clear layers so can only edit fieldToClaim
    clearDrawn()
    clearLayers()
    let control = document.getElementsByClassName(
      "control-icon leaflet-pm-icon-edit"
    )[0];
    fieldLayer.current.pm.enable()
    control.click();
  }

  const finishEdit = () => {
    setEditing(false);
    setCancelButton(false)
    // clearDrawn()
    // clearLayers()
    let control = document.getElementsByClassName(
      "leaflet-pm-action  action-finishMode"
    );
    control[0].click();
  }

  // Dropdown on controls
  const handleClick = (event) => {
    setZoneToolsDropdown(event.currentTarget);
  };

  const handleClose = () => {
    setZoneToolsDropdown(null);
  };

  /**
   * Basic function to take an integer value in acres and convert it to Km
   * @param {int} value
   */
  const acToGrid = (value) => {
    const val = Math.sqrt(turf.convertArea(value, "acres", "kilometers"));
    addGrid(val, true);
  };


  /**
   *
   * @param {int} newvalue amount to move by
   * @param {int} gridSize length of cell-side in km for grid re-render
   * @param {string} dir either "x" or "y" to decide which direction to move the grid
   */
  const moveGrid = (newvalue, gridSize, dir) => {
    switch (dir) {
      case "x":
        EWoffset = newvalue;
        addGrid(gridSize);
        break;
      case "y":
        NSoffset = newvalue;
        addGrid(gridSize);
        break;
    }
  };

  /**
   * remove controls that are anchored to the map view for grid movements
   */
  const removeGridControls = () => {
    slider1.remove();
    slider2.remove();
    slider3.remove();
    slider4.remove();
  };

  /**
   * add controls for the grid to the map view
   */
  const addGridControls = () => {
    //grid size
    slider1 = L.control.slider(
      function (value) {
        acToGrid(value);
      },
      {
        id: slider1,
        orientation: "horizontal",
        position: "topleft",
        title: "Grid Size",
        min: 0.5,
        max: 10,
        step: 0.5,
        value: 2,
        collapsed: true,
        increment: true,
        size: "200px",
        logo: acresIcon,
      }
    );

    //grid rotation
    slider2 = L.control.slider(
      function (value) {
        handleRotateSlider(parseInt(value));
      },
      {
        id: slider2,
        orientation: "horizontal",
        position: "topleft",
        title: "Rotate Grid",
        min: -90,
        max: 90,
        step: 0.5,
        value: 0,
        collapsed: true,
        increment: true,
        size: "400px",
        logo: rotateIcon,
      }
    );

    //move E/W
    slider3 = L.control.slider(
      function (value) {
        moveGrid(parseFloat(value), gridSize, "x");
      },
      {
        id: slider3,
        orientation: "horizontal",
        position: "topleft",
        title: "Move East/West",
        min: -0.005,
        max: 0.005,
        step: 0.00006,
        value: 0,
        collapsed: true,
        size: "400px",
        increment: true,
        logo: horizontalIcon,
        showValue: false,
      }
    );

    //move N/S
    slider4 = L.control.slider(
      function (value) {
        moveGrid(parseFloat(value), gridSize, "y");
      },
      {
        id: slider4,
        orientation: "horizontal",
        position: "topleft",
        title: "Move North/South",
        min: -0.005,
        max: 0.005,
        step: 0.00006,
        value: 0,
        collapsed: true,
        increment: true,
        size: "450px",
        logo: verticalIcon,
        showValue: false,
      }
    );

    //after we define the controls we can add them to the map
    slider1.addTo(map);
    slider2.addTo(map);
    slider3.addTo(map);
    slider4.addTo(map);
  };


  /**
   * generates a random color #aabbcc and sends it back
   */
  const getRandomColor = () => {
    var letters = "0123456789ABCDEF";
    var color = "#";
    for (var i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  /**
   * after zones are created from a drawing tool and sent to the zone handler with the unique
   * colors in the properties, we can take an array of colors and the name of a tool
   * then we iterate through the zones on the corresponding layer and apply styling
   * @param {string array} zoneColors
   * @param {string) tool either grid or slice
   * @param {optional array of features} existing  FOR SLICE ONLY - Deprecated
   */
  const colorZones = (zoneColors, tool, existing = null) => {
    let x = 0;
    switch (tool) {
      case "grid":
        createdZones.current.eachLayer(function (layer) {
          let zoneTheme = {
            color: zoneColors[x],
            fillColor: zoneColors[x],
            fillOpacity: "0.3",
            strokeWidth: "5",
          };
          x++;
          layer.setStyle(zoneTheme);
        });
        break;
      case "slice":
        let newZonesNum = zoneColors.length;
        slicedMap.current.eachLayer(function (layer) {
          if (x <= newZonesNum) {
            var zoneTheme = {
              color: zoneColors[x],
              fillColor: zoneColors[x],
              fillOpacity: "0.3",
              strokeWidth: "5",
            };
          }
          x++;
          layer.setStyle(zoneTheme);
        });
        break;
    }
  };


  // Currently will always just call addGrid. Future idea is to set a state variable if
  // field hasnt been selected so this will start a square polygon draw, then on
  // completetion open grid tool
  const handleGridStart = () => {
    // Call addGrid if field has been selected, if not start square polygon draw
    // then use created field for grid
    if (fieldData.selectedField.id !== '') {
      addGrid(0.0638, true)
    } else {
      addPolygon()
    }
  }

  /**
   * This is the main function for the grid tool. Anytime the grid moves, or changes it gets re-rendered here
   * This will handle styling so that clicking next zone will start highlighting in a new color, it will also create
   * a bounding box for a field and apply changes to it to reflect the movements made with the controls
   * i.e. rotate, shift x or y and change size (e)
   * @param {int} e this is the size of the grid, it has a default value in case the grid accidentally
   * gets re rendered with no size set
   * @param {bool} firstRender this exists because if its the first time drawing there grid there are some
   * things have to happen a little differently
   */
  const addGrid = (e = 0.0638, firstRender = false) => {
    setActiveTool("grid");
    setCancelButton(true);
    //lets begin by clearing any old grids, and revealing controls
    gridSize = e;
    gridlayer.current.clearLayers();
    setGridSlider(false);
    const geo = fieldGeo;

    var bbox = turf.bbox(fieldGeo);
    bbox[0] += -0.002 + EWoffset;
    bbox[1] += -0.002 + NSoffset;
    bbox[2] += 0.002 + EWoffset;
    bbox[3] += 0.002 + NSoffset;

    var cellSide = e;
    var squareGrid = turf.squareGrid(bbox, cellSide);

    //Now we will get intro tracking grid tiles
    let selectedTilesData = [];

    if (firstRender === true) {
      //if its the first time drawing the grid, lets create the dictionary
      selectedTilesData = squareGrid.features.map((feature, index) => {
        let data = { feature: feature, selected: false, key: index, zone: -1 };
        return data;
      });
    } else {
      //we have re-drawn/re-rendered the map, lets update coordinates of the dictionary instead
      selectedTilesData = selected;
      for (var x = 0; x < selectedTilesData.length; x++) {
        selected[x].feature = squareGrid.features[x];
      }
    }

    if (rotateVal != 0) {
      const pivot = turf.center(squareGrid); //This should be the center of the entire square grid
      turf.featureEach(squareGrid, function (currentFeature, featureIndex) {
        //for-each feature/square
        const options = { pivot: pivot, mutate: true };
        let tempFeature = currentFeature; //grab the current square
        squareGrid.features[featureIndex] = turf.transformRotate(
          tempFeature,
          rotateVal,
          options
        );
        let rotatedCopy = turf.transformRotate(tempFeature, rotateVal, options);

        try {
          selected[featureIndex].feature = rotatedCopy;
        } catch (err) {}
      });
    }
    gridlayer.current.addData(squareGrid);

    //Here we will get into styling the tiles based on if they are selected or not etc...
    gridlayer.current.eachLayer(function (layer) {
      let ndx = gridlayer.current.getLayers().indexOf(layer);
      var selectedBool = selectedTilesData[ndx].selected;

      let layerZnum = selectedTilesData[ndx].zone;
      fillColor = fillColors[zoneNum];
      layer.setStyle({
        weight: 0.75,
        color: "white",
        opacity: 0.75,
        fillOpacity: "0.2",
      });

      if (selectedBool) {
        layer.setStyle({
          fillColor: fillColors[layerZnum],
          fillOpacity: "0.3",
        });
        //console.log(fillColor);
      }

      layer.on("mouseover", function () {
        if (layerZnum > 0) {
          this.setStyle({
            fillColor: fillColors[layerZnum],
          });
        } else {
          this.setStyle({ fillColor: fillColor, fillOpacity: "0.3" });
        }
      });

      layer.on("mouseout", function () {
        let ndx = gridlayer.current.getLayers().indexOf(this);
        var selectedBool = selectedTilesData[ndx].selected;
        if (selectedBool === false) {
          this.setStyle({
            fillColor: "white",
            fillOpacity: "0.2",
          });
        }
      });

      layer.on("click", function (e) {
        let ndx = gridlayer.current.getLayers().indexOf(this);
        var selectedBool = selectedTilesData[ndx].selected;

        if (selectedBool === false) {
          selectedTilesData[ndx].selected = true;
          selectedTilesData[ndx].zone = zoneNum;
          selected = selectedTilesData;
          e.target.setStyle({
            fillColor: fillColor,
            fillOpacity: "0.3",
          });
        } else {
          selectedTilesData[ndx].selected = false;
        }
      });
    });
    //once its all said and done, lets copy that dictionary to a state variable so we dont lose it
    selected = selectedTilesData;
    gridlayer.current.addTo(map);
  };

  /**
   * This function will take a feature and a list of other features and return the number of tiles
   * the new one shares sides with as well as a list of who it shares sides with.
   * @param {feature} newTile
   * @param {array of features} allTiles
   */
    const sharedEdges = (newTile, allTiles) => {
      const returnArry = []; //0 = shared edges, 1 neighbors array
      const edgeFriends = [];
      newTile = newTile.coordinates[0];
      var matches = 0;

      for (var j = 0; j < allTiles.length; j++) {
        //list of all tiles
        const currentTile = allTiles[j].coordinates[0]; //get a specific tile
        matches = 0;
        for (var x = 0; x < 4; x++) {
          //current tiles coords
          for (var y = 0; y < 4; y++) {
            //new tiles coords
            if (
              currentTile[x][0] == newTile[y][0] &&
              currentTile[x][1] == newTile[y][1]
            ) {
              matches++;
              if (matches === 2) {
                edgeFriends.push(allTiles[j]);
              }
            }
          }
        }
      }
      returnArry.push(parseInt(edgeFriends.length));
      returnArry.push(edgeFriends);
      return returnArry;
    };


  /**
   * Here is the grand finale for the grid tool. After zones have been created with the grid and the finish button
   * gets clicked, this function will combine the tiles into larger features and pass them on to the zone handler
   * with the information needed for colors and deleting functionality and then it will remove the grid tools from the screen
   * putting the user into a finished editing view
   *
   */
  const createGridZones = () => {
    const zonesToMerge = maxZoneNum; //number of zones we're creating
    const zones = [];
    const allTiles = selected;
    const selectedTiles = [];
    const maskedZones = [];

    //-----------------check if we have selected any tiles-----------------------
    let numTilesSelected = 0;
    for (var x = 0; x < allTiles.length; x++) {
      if (allTiles[x].selected === true) numTilesSelected++;
    }

    //after check that we have selected a tile - push empty arrays
    //that structure will look like: [[], [], []...]
    if (numTilesSelected > 0) {
      for (var x = 0; x < zonesToMerge; x++) {
        selectedTiles.push([]);
      }
      //----------------------------------------------------------------------------

      //now that we set up nested arrays lets make that previous structure look like this:
      //  [ [zone1 feature, zone1 feature...], [zone2 feature, zone2 feature...] ...]
      for (var x = 0; x < allTiles.length; x++) {
        const currentTile = allTiles[x];
        if (currentTile.selected === true) {
          const zoneNdx = currentTile.zone - 1;
          selectedTiles[zoneNdx].push(currentTile.feature);
        }
      }

      //----------------------------------------------------------------------------
      //here is where we are combining the polygons to make zones
      // [ [big zone1 polygon], [big zone2 polygon] ... ]
      for (var x = 0; x < zonesToMerge; x++) {
        var featureList = selectedTiles[x];
        const tempZone = turf.union(...featureList);
        zones.push(tempZone);
      }

      //----------------------------------------------------------------------------
      //At this point we have an array with sub-arrays per zone containing big polygons
      //next is to mask them so that they exist only within the field boundaries
      let fieldMask = {};
      const polysForField = [];

      //if the field has multiple boundaries, we need to find those intersections
      if (fieldGeo.features[0].geometry.coordinates.length > 1) {
        for (
          var t = 0;
          t < fieldGeo.features[0].geometry.coordinates.length;
          t++
        ) {
          const tempPoly = turf.polygon(
            fieldGeo.features[0].geometry.coordinates[t]
          );
          polysForField.push(tempPoly);
        }
      } else {
        //or if the field has one boundary we can create a 'mask'
        fieldMask = fieldGeo.features[0].geometry;
      }

      const intersectingPolys = [];
      let intersectZoneNdx = -1;
      for (var x = 0; x < zones.length; x++) {
        //loop over the
        var tempZone = zones[x];

        if (fieldGeo.features[0].geometry.coordinates.length > 1) {
          //only if multipolygon
          for (var i = 0; i < polysForField.length; i++) {
            if (turf.intersect(zones[x], polysForField[i]) !== null) {
              //grid intersects with field
              intersectingPolys.push(
                turf.intersect(tempZone, polysForField[i])
              );
              intersectZoneNdx = x;
            }
          }
        } else {
          zones[x] = turf.intersect(tempZone, fieldMask);
          maskedZones.push(turf.intersect(tempZone, fieldMask)); // instead of overwriting the array lets try this?
        }
      }

      if (fieldGeo.features[0].geometry.coordinates.length > 1) {
        //only if multipolygon
        for (var r = 0; r < intersectingPolys.length; r++) {
          const tempZone = intersectingPolys[r];
          maskedZones.push(tempZone);
        }
      }

      //here we are going to make one more list of the created zones structured how the zone handler wants them
      const forZoneHandler = [];
      const zoneColors = [];
      for (var x = 0; x < maskedZones.length; x++) {
        let tempColor =
          zonesColors[Math.floor(Math.random() * zonesColors.length)];
        if (zonesColors.length != usedColors.length) {
          //as long as we havent used all of the colors, get a new one
          while (usedColors.includes(tempColor)) {
            tempColor = zonesColors[Math.floor(Math.random() * zonesColors.length)];
          }
        }
        zoneColors.push(tempColor);
        usedColors.push(tempColor);
        const tempFeature = {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: maskedZones[x].geometry.coordinates,
          },
          properties: {
            featureRef: gridlayer,
            mapRef: map,
            CALCACRES: turf.convertArea(
              turf.area(maskedZones[x]),
              "meters",
              "acres"
            ),
            zone: x + 1,
            color: tempColor,
          },
        };
        forZoneHandler.push(tempFeature);
      }

      //send our data to the handler
      forZoneHandler.map((zone) => handleZone(zone, "add", fieldData.selectedField.id, zone.properties.zone, zone.properties.color));

      //cleanup the map and reset states
      clearGrid();
      gridlayer.current.clearLayers();
      forZoneHandler.map((zone) => createdZones.current.addData(zone));
      createdZones.current.addTo(map);
      colorZones(zoneColors, "grid");
      setCancelButton(false);
    } else {
      clearGrid();
    }
  };

  const undo = () => {
    if (slicingMode.current) {
      // also needs to go through and remove from currentSlice
      let sliceGeo = currentSlice.current.toGeoJSON();
      if (sliceGeo.geometry.coordinates.length > 1) {
        let newCoords = sliceGeo.geometry.coordinates;
        newCoords.splice(newCoords - 1, 1);
        currentSlice.current.setLatLngs(newCoords);
        currentSlice.current.redraw();
      } else {
        currentSlice.current = null;
      }

      let lineControl = document.getElementsByClassName(
        "control-icon leaflet-pm-icon-polyline"
      )[0];
      let control = lineControl.parentNode;
      let lineEditControls = control.nextSibling;
      var removeVertex = null;
      for (let i = 0; i < lineEditControls.childNodes.length; i++) {
        if (
          lineEditControls.childNodes[i].className ===
          "leaflet-pm-action  action-removeLastVertex"
        ) {
          removeVertex = lineEditControls.childNodes[i];
          break;
        }
      }
      removeVertex.click();
    } else if (drawnZones.length > 0) {
      // make a copy so we can mutate with pop to remove and use layer
      const zoneCopy = [...drawnZones];
      const recentZone = zoneCopy.pop();
      map.removeLayer(recentZone);
      setDrawnZones(zoneCopy);
      handleZone(zone, "undo");
    }
  };

  /**
   * when the clear button gets clicked, it will call a few different functions to clean stuff up from various
   * zone creating tools to maintain modularity. This function will clean everything up from the grid
   */
  const clearGrid = () => {
    try {
      removeGridControls();
    } catch (err) {}
    rotateVal = 0;
    setCancelButton(false);
    EWoffset = 0;
    NSoffset = 0;
    zoneNum = 1;
    gridSize = 0.0638;
    setGridSlider(true);
    setToolTip("Editing Zone: " + zoneNum);
    gridlayer.current.clearLayers();
    createdZones.current.clearLayers();
  };

  const clearSlice = () => {
    setSlicing(false);
    setCancelButton(false);
    slicingMode.current = false;
    map.pm.Draw.disable();
    slicedMap.current.clearLayers();
    slicedZones.current = [];
  };

  const clearSSURGOzones = () => {
    ssurgoLayers.current.clearLayers();
  };

  const clear = () => {
    clearDrawn()
    clearLayers()
    usedColors = [];
    clearSlice();
    clearGrid();
    clearSSURGOzones();
    handleZone([], "clear");
    drawnZones.map((zone) => map.removeLayer(zone));
    setFieldData({
      fields: [],
      selectedField: {id: '', name: '', farmId: '', farm: '', orgId: '', org: ''}
    })
  };

  const clearDrawn = () => {
    map.eachLayer(function (layer) {
      try {
        //we can use this test to identify if the layer contains a drawn feature or not
        //and if so - we can remove it
        var currLayerCoords = layer.feature.geometry.coordinates[0];
        if (isUserLayer(layer)) {
          map.removeLayer(layer);
        }
      } catch(err) {
        // console.log(err)
      }
    });
  }

  const isUserLayer = (layer) =>{
    let state = stateLayer.current.hasLayer(layer)
    let county = countyLayer.current.hasLayer(layer)
    let plss = plssLayer.current.hasLayer(layer)
    let section = sectionLayer.current.hasLayer(layer)
    let clu = cluLayer.current.hasLayer(layer)
    let notGov = !state && !county && !plss & !section & !clu
    return notGov
  }

  const clearLayers = () => {
    // clear current layers
    map.removeLayer(cluLayer.current)
    map.removeLayer(sectionLayer.current)
    map.removeLayer(plssLayer.current)
    map.removeLayer(fieldLayer.current)
    fieldLayer.current.clearLayers()
  }

  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  function valuetext(value) {
    return `${value}ac`;
  }

  /**
   * used for decrimenting the current zone number being edited
   */
  const prevZone = () => {
    if (zoneNum > 1) {
      zoneNum--;
    }
    setToolTip("Editing Zone: " + zoneNum);
    addGrid(gridSize, false);
  };

  /**
   * used for incrementing the current zone number being edited
   */
  const addNewZone = () => {
    zoneNum++;
    setToolTip("Editing Zone: " + zoneNum);
    addGrid(gridSize, false);
  };

  /**
   * when the cancel button is clicked, there are something things that need to happen depending on what tool was
   * just canceled...also note that canceling is a different cleanup process than clicking the clear button
   * biggest difference being not wiping the created zones off of the map
   */
  const cancelClickHandler = () => {
    const tool = activeTool;
    setActiveTool("");
    switch (tool) {
      case "grid":
        zoneNum = 1;
        removeGridControls();
        setCancelButton(false);
        rotateVal = 0;
        EWoffset = 0;
        NSoffset = 0;
        gridSize = 0.0638;
        setGridSlider(true);
        setToolTip("Editing Zone: " + zoneNum);
        gridlayer.current.clearLayers();
        break;
      case "polygon":
        map.pm.Draw.disable();
        setCancelButton(false);
        break;
      case "slice":
        setSlicing(false);
        setCancelButton(false);
        slicingMode.current = false;
        map.pm.Draw.disable();
        break;
    }
  };

  const handleSliceVertex = (e) => {
    if (!slicingMode.current) {
      return;
    }
    if (currentSlice.current === null) {
      currentSlice.current = L.polyline([e.latlng]);
    } else {
      if(!currentSlice.current.getLatLngs().includes(e.latlng)){
        currentSlice.current.addLatLng(e.latlng);
      }
      let sliceGeo = currentSlice.current.toGeoJSON();
      // use field boundary if zones don't exist, other wise use zones
      let intersection = {
        features: [],
      };
      let features = [];
      let existing = [];
      if (slicedZones.current.length === 0) {
        features.push(JSON.parse(fieldData.selectedField.boundary));
        intersection = turf.lineIntersect(features[0], sliceGeo);
      } else {
        handleZone(null, "clear");
        //need to do a intersect check on each existing zone
        for (const sz of slicedZones.current) {
          let zoneIntersection = turf.lineIntersect(sz, sliceGeo);
          //if intersects, add sz to features for cut
          if (zoneIntersection.features.length > 1) {
            intersection = zoneIntersection;
            features.push(sz);
          } else {
            //need to make sure that existing zones not in a slice are saved
            existing.push(sz);
          }
        }
      }

      slicedZones.current = [];
      if (intersection.features.length > 1) {
        //line has connected to other edge
        //split polygon based on line

        // loop over features, checking for a multipoly. if multi, split into polys and remake list
        let multiPolyNdxs = [];
        let newPolys = [];
        for (const feat of features) {
          if (feat.geometry.type == "MultiPolygon") {
            // loop over coordinates and create new Polygon feature
            for (const coordinates of feat.geometry.coordinates) {
              let newP = {
                geometry: {
                  coordinates: [],
                  type: "Polygon",
                },
                properties: {},
                type: "Feature",
              };

              newP.geometry.coordinates = coordinates;
              newPolys.push(newP);
              multiPolyNdxs.push(features.indexOf(feat));
            }
          }
        }

        // remove multi polygons from list and add in new created polygons
        multiPolyNdxs.map((x) => features.splice(x, 1));
        features = [...features, ...newPolys];
        const zoneColors = [];
        let finalZones = null;
        for (const toSplit of features) {
          let split = polygonCut(toSplit.geometry, sliceGeo.geometry);
          if (finalZones === null) {
            finalZones = split;
          } else {
            split.features.map((x) => finalZones.features.push(x));
          }

          let splitFeats = split.features;
          for (const feature of splitFeats) {
            let featurePoly = turf.polygon(feature.geometry.coordinates);
            let area = turf.area(featurePoly) / 4046.86;
            feature.properties["CALCACRES"] = area;
            slicedZones.current.push(feature);
          }
        }

        slicedZones.current = [...slicedZones.current, ...existing];
        for (const feature of slicedZones.current) {

          let tempColor =
            zonesColors[Math.floor(Math.random() * zonesColors.length)];
          if (usedColors.includes(tempColor)) {
            tempColor = getRandomColor();
          }
          zoneColors.push(tempColor);
          usedColors.push(tempColor);

          feature.properties.color = tempColor;
          feature.properties.featureRef  = slicedMap
          feature.properties.mapRef = map
        }
        existing.map((x) => finalZones.features.push(x));
        console.log(slicedZones)
        slicedZones.current.map((x) => handleZone(x, "add", fieldData.selectedField.id, x.properties.id, x.properties.color));

        //need to create zones with geojson and keep track of zones
        if (map.hasLayer(slicedMap.current)) {
          map.removeLayer(slicedMap.current);
        }
        slicedMap.current.clearLayers();
        slicedMap.current.addData(finalZones);

        slicedMap.current.addTo(map);
        colorZones(zoneColors, "slice", slicedZones.current);

        // finish drawing line to restart
        let lineControl = document.getElementsByClassName(
          "control-icon leaflet-pm-icon-polyline"
        )[0];
        let control = lineControl.parentNode;
        let lineEditControls = control.nextSibling;
        var finishLine = null;
        for (let i = 0; i < lineEditControls.childNodes.length; i++) {
          if (
            lineEditControls.childNodes[i].className ===
            "leaflet-pm-action  action-finish"
          ) {
            finishLine = lineEditControls.childNodes[i];
            break;
          }
        }
        finishLine.click();
        //clear currentSlice
        currentSlice.current = null;

        //click start again for continued slicing
        let start = document.getElementsByClassName(
          "control-icon leaflet-pm-icon-polyline"
        )[0];
        start.click();
      }
    }
  };

  function polygonCut(polygon, line, idPrefix) {
    // this is a function to cut a pulygon into seperate polygons
    // based on a line.
    // taken from - https://gis.stackexchange.com/questions/344068/splitting-a-polygon-by-multiple-linestrings-leaflet-and-turf-js
    const THICK_LINE_UNITS = "kilometers";
    const THICK_LINE_WIDTH = 0.001;
    var i, j, id, intersectPoints, lineCoords, forCut, forSelect;
    var thickLineString, thickLinePolygon, clipped, polyg, intersect;
    var polyCoords = [];
    var cutPolyGeoms = [];
    var cutFeatures = [];
    var offsetLine = [];
    var retVal = null;

    console.log("LINE TO SPLIT WITH", line)

    if (
      (polygon.type != "Polygon" && polygon.type != "MultiPolygon") ||
      line.type != "LineString"
    ) {
      return retVal;
    }

    if (typeof idPrefix === "undefined") {
      idPrefix = "";
    }

    intersectPoints = turf.lineIntersect(polygon, line);
    if (intersectPoints.features.length == 0) {
      return retVal;
    }

    var lineCoords = turf.getCoords(line);
    if (
      turf.booleanWithin(turf.point(lineCoords[0]), polygon) ||
      turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)
    ) {
      return retVal;
    }

    offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS,
    });
    offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS,
    });

    for (i = 0; i <= 1; i++) {
      forCut = i;
      forSelect = (i + 1) % 2;
      polyCoords = [];
      for (j = 0; j < line.coordinates.length; j++) {
        polyCoords.push(line.coordinates[j]);
      }
      for (
        j = offsetLine[forCut].geometry.coordinates.length - 1;
        j >= 0;
        j--
      ) {
        polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
      }
      polyCoords.push(line.coordinates[0]);

      thickLineString = turf.lineString(polyCoords);
      thickLinePolygon = turf.lineToPolygon(thickLineString);
      clipped = turf.difference(polygon, thickLinePolygon);

      cutPolyGeoms = [];
      for (j = 0; j < clipped.geometry.coordinates.length; j++) {
        polyg = turf.polygon(clipped.geometry.coordinates[j]);
        intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
        if (intersect.features.length > 0) {
          cutPolyGeoms.push(polyg.geometry.coordinates);
        }
      }

      cutPolyGeoms.forEach(function (geometry, index) {
        id = idPrefix + (i + 1) + "." + (index + 1);
        cutFeatures.push(turf.polygon(geometry, { id: id }));
      });
    }

    if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);

    return retVal;
  }

  /**
   * The text input field for grid size will send the value here to convert an input value in AC to
   * a value to be used for the grid cell side in Km, and then the grid gets re rendered with the new size
   * @param {float} event
   */
  const handleTextInputSize = (event) => {
    if (event.target.value > 0.25 && event.target.value < 50) {
      const val = Math.sqrt(
        turf.convertArea(event.target.value, "acres", "kilometers")
      );
      gridSize = val;
      addGrid(val, true);
    }
  };

  const sliceMap = () => {
    setCancelButton(false);
    setActiveTool("slice");
    setSlicing(true);
    map.removeLayer(cluLayer.current)
    showClus.current = false
    slicingMode.current = true;
    let lineControl = document.getElementsByClassName(
      "control-icon leaflet-pm-icon-polyline"
    )[0];
    lineControl.click();
  };

  /**
   * Function to handle the slider for grid rotation and then re-render grid
   * @param {float from slider} newvalue
   */
  const handleRotateSlider = (newvalue) => {
    rotateVal = newvalue;
    addGrid(gridSize, false);
  };

  const completeSlice = () => {
    setSlicing(false);
    setCancelButton(false);
    slicingMode.current = false;
    let lineControl = document.getElementsByClassName(
      "control-icon leaflet-pm-icon-polyline"
    )[0];
    let control = lineControl.parentNode;
    let lineEditControls = control.nextSibling;
    var finishLine = null;
    for (let i = 0; i < lineEditControls.childNodes.length; i++) {
      if (
        lineEditControls.childNodes[i].className ===
        "leaflet-pm-action  action-cancel"
      ) {
        finishLine = lineEditControls.childNodes[i];
        break;
      }
    }
    finishLine.click();
    showClus.current = true
  };


  //To get and add SSURGO zones
  const addSSURGOzones = async () => {
    let request = {
      AOI: fieldData.selectedField.boundary,
      Product: "GeoJSON",
      Soil_Parameter: "nccpi2all",
      Projection: "EPSG:4326",
      Resolution: "0.0001",
    };

    let res = await df.ssurgoForZones(request);
    if (res !== undefined) {

      let colors = [
        "#f707a7",
        "#f55ffa",
        "#db07f7",
        "#07f7df",
        "#07cbf7",
        "#0793f7",
        "#8bf707",
        "#40e609",
        "#4bbf24",
        "#2a991e",
      ];

      //drawSSURGOZones(res.attributes.GeoJSON)
      for (var i = 0; i < res.attributes.GeoJSON.features.length; i++) {
        res.attributes.GeoJSON.features[i].properties.CALCACRES =
          turf.area(res.attributes.GeoJSON.features[i]) * 0.000247105;
          res.attributes.GeoJSON.features[i].properties.zone = i + 1;
          res.attributes.GeoJSON.features[i].properties.color = zonesColors[i];
        setZone(res.attributes.GeoJSON.features[i]);

        ssurgoLayers.current.addData(res.attributes.GeoJSON.features[i]);
      }
      let j = 0;
        ssurgoLayers.current.eachLayer(function (layer) {
        let color = zonesColors[j++];

        layer.setStyle({
          fillOpacity: 0.75,
          fillColor: color,
        });
      });
      ssurgoLayers.current.addTo(map);
    }
  };



  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleOpenSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorEl(null);
  };

  const simpleMenu = () => (
    <Box pl={1}>
      <Button
        variant='outlined'
        color='primary'
        aria-controls="simple-menu"
        aria-haspopup="true"
        onClick={handleOpenSelect}
        style={{margin: 3}}
      >
        Map Tools
      </Button>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseSelect}
      >
        { !editing ? (
          <div>
            <MenuItem onClick={handleCloseSelect}>
              <Box className={classes.greenIcon} onClick={() => addPolygon()}>
                <AddIcon />
                {' Draw'}
              </Box>
            </MenuItem>
            <MenuItem onClick={handleCloseSelect}>
              <Box className={classes.greenIcon} onClick={() => addSquare()}>
                <AddBoxOutlinedIcon />
                {' Square'}
              </Box>
            </MenuItem>
            {
              (selectedField !== null && selectedField.id !== '') && (
                <MenuItem>
                  <Box className={classes.greenIcon} onClick={() => editBoundary()}>
                    <EditLocationIcon />
                    {' Edit Boundary'}
                  </Box>
                </MenuItem>
            )}
            {
              !editing &&
              <MenuItem>
                <Box className={classes.icon} onClick={() => clear()}>
                  <HighlightOffIcon />
                  {' Clear'}
                </Box>
              </MenuItem>
            }
          </div>
        ) : (
          <MenuItem>
            <Button
              variant="outlined"
              color="primary"
              style={{ height: '24px', margin: "0 5px" }}
              onClick={() => finishEdit()}
            >
              Finish Edit
            </Button>
          </MenuItem>
        )

        }




      </Menu>
    </Box>
  )


  return (
    <Box
      style={{
        height: containerHeight !== undefined ? containerHeight : '100%',
        width: "auto",
        backgroundColor: '#ffffff'
      }}
    >
      {
        (width <= 500 && canEdit) && (
          <Box display='flex'>
            { !cancelButton && simpleMenu() }

            {slicing && (
              <Box className={classes.icon}>
                <Button onClick={() => completeSlice()}>Finish Edit</Button>
              </Box>
            )}


            {!gridSlider && (
              <Box display='flex' style={{height: '40px',padding: '2px 0px'}}>
                <Box
                  mx={1}
                  display='flex'
                  alignItems='center'
                >
                  <TextField
                    id="standard-basic"
                    variant="outlined"
                    onChange={(event) => handleTextInputSize(event)}
                    inputProps={{
                      style: {
                        padding: 7,
                      },
                    }}
                    style={{
                      height: "30px",
                      width: '40px',
                    }}
                  />
                  <Box fontWeight={500} mr={1}>
                     &nbsp;Acres
                  </Box>
                </Box>

                <AgToolTip
                  title={tooltip}
                  open={true}
                  style={{ fontSize: "20px" }}
                >
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{ height: "30px", marginRight: "5px" }}
                    onClick={() => prevZone()}
                  >
                    Previous
                  </Button>
                </AgToolTip>

                <AgToolTip>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => addNewZone()}
                    style={{ height: "30px", marginRight: "5px" }}
                  >
                    Next
                  </Button>
                </AgToolTip>

                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => createGridZones()}
                  style={{ height: "30px" }}
                >
                  Finish
                </Button>

              </Box>
            )}

            {!gridSlider && <Box className={classes.gridControls}></Box>}

            { (cancelButton && !editing) &&
              <Box
                className={classes.greenIcon}
                onClick={() => cancelClickHandler()}
              >
                <CancelIcon />
                { gridSlider ? ' Cancel' : '' }
              </Box>
            }

          </Box>
        )
      }

      {(canEdit && width > 500) && (
        <Box className={classes.mapControls} >
          <Box display="flex " style={{ width: "100%" }} >

            {(!cancelButton && !editing) && (
              <AgToolTip
                title="Draw polygons by clicking to place vertices. Click the first vertex to complete the polygon"
                placement="top-start"
              >
                <Box className={classes.greenIcon} onClick={() => addPolygon()}>
                  <AddIcon /> Draw
                </Box>
              </AgToolTip>
            )}

            {(!cancelButton && !editing) && (
              <AgToolTip
                title="Draw square polygon. After completion you will be able to split or grid the boundary."
                placement="top-start"
              >
                <Box className={classes.greenIcon} onClick={() => addSquare()}>
                  <AddBoxOutlinedIcon /> Square
                </Box>
              </AgToolTip>
            )}

            {(!cancelButton && !editing && selectedField !== null && selectedField.id !== '') && (
              <Box className={classes.greenIcon} onClick={() => editBoundary()}>
                <EditLocationIcon />
                {' Edit Boundary'}
              </Box>
            )}

            {editing &&
              <Button
                variant="outlined"
                color="primary"
                style={{ height: '24px', margin: "0 5px" }}
                onClick={() => finishEdit()}
              >
                Finish Edit
              </Button>
            }


            {/* {(!cancelButton && fieldData.selectedField.id !== '') && (
              <AgToolTip
                title="Draw lines to dissect the field by clicking to place endpoints"
                placement="top-start"
                className={classes.tootlTipLight}
              >
                <Box className={classes.greenIcon} onClick={() => sliceMap()}>
                  <AddIcon /> Split Field
                </Box>
              </AgToolTip>
            )} */}

            {/* {(!cancelButton && fieldData.selectedField.id !== '') && (
              <AgToolTip
                title="Click tiles to add to a zone. Use sliders to control grid postion and size. To create additional zones: click 'Next Zone'. Click 'Finish' to finalize zones"
                placement="top-start"
              >
                <Box
                  className={classes.greenIcon}
                  onClick={() => addGrid(0.0638, true)}
                >
                  <GridOnOutlinedIcon /> Gridded
                </Box>
              </AgToolTip>
            )} */}

            {!cancelButton && includeSSURGO && (
              <AgToolTip
                title="Generate zones based on SSURGO soil data"
                placement="top-start"
              >
                <Box
                  className={classes.greenIcon}
                  onClick={() => addSSURGOzones()}
                >
                  <Box
                    className={classes.greenIcon}
                    onClick={() => addSSURGOzones()}
                  >
                    <AddIcon /> SSURGO Zones
                  </Box>
                </Box>
              </AgToolTip>
              )
            }


            {(cancelButton && !editing) && (
              <Box
                className={classes.greenIcon}
                onClick={() => cancelClickHandler()}
              >
                <CancelIcon />
                {' Cancel'}
              </Box>
            )}
            {/* <Box className={classes.icon} onClick={() => undo()}>
              <UndoIcon />
              Undo
            </Box> */}
            {
              !editing && (
                <Box className={classes.greenIcon} onClick={() => clear()}>
                  <HighlightOffIcon />
                  Clear
                </Box>
            )}


            {slicing && (
              <Box className={classes.greenIcon}>
                <Button onClick={() => completeSlice()}>Finish Edit</Button>
              </Box>
            )}
            {!gridSlider && (
              <Box
                mx={1}
                display='flex'
                alignItems='center'
              >
                <TextField
                  id="standard-basic"
                  variant="outlined"
                  onChange={(event) => handleTextInputSize(event)}
                  inputProps={{
                    style: {
                      padding: 7,
                    },
                  }}
                  style={{
                    height: "30px",
                    width: '40px',
                  }}
                />
                <Box fontWeight={500} mr={1}>
                   &nbsp;Acres/ Zone
                </Box>
              </Box>
            )}

            {!gridSlider && (
              <Button
                variant="outlined"
                color="primary"
                style={{ height: "30px", marginRight: "5px" }}
                onClick={() => prevZone()}
              >
                Prev Zone
              </Button>
            )}

            {!gridSlider && (
              <AgToolTip
                title={tooltip}
                open={true}
                style={{ fontSize: "20px" }}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => addNewZone()}
                  style={{ height: "30px", marginRight: "5px" }}
                >
                  Next Zone
                </Button>
              </AgToolTip>
            )}

            {!gridSlider && (
              <Button
                variant="outlined"
                color="primary"
                onClick={() => createGridZones()}
                style={{ height: "30px" }}
              >
                Finish
              </Button>
            )}
          </Box>

          <Box
            display='flex'
            alignItems='center'
            style={{width: '265px', height: '24px'}}
            fontWeight={500}
            fontSize={14}
          >
            <Switch
              color='primary'
              checked={enableClus}
              disabled={editing}
              onChange={(e) => handleCluSwitch(e.target.checked)}
              inputProps={{ 'aria-label': 'boundary toggle' }}
            />
              { width > 660 ? 'Show Boundary Layer' : 'Boundaries' }
          </Box>

        </Box>
      )}
      {!gridSlider && <Box className={classes.gridControls}></Box>}
      <div
        id="map-digs"
        style={{
           height: containerHeight !== undefined ? containerHeight - 55 : '100%',
           width: "auto"
         }}
      >

      </div>
    </Box>
  )
}

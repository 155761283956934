import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core'

export function KeyPoints({ }) {
  return (
    <Box textAlign='justify'>
      <Box>
        1.	Values for “orderId”, “subOrderId” and “eMail” need to be provided, as the VendorResponse endpoint will process the response based on “orderId” and “subOrderId” provided. “eMail” will be used to send an email back after response has been posted successfully. <br />
        2.	The above JSON format provided, gives basic structure of the response to be posted and empty values to be passed if there are no values available to any of the keys. It is better to send empty string value or empty array value if there are no values associated with it rather than removing that key from the response. <br />
        3.	While posting response, it would be good to double check with the spellings of keys.<br />
        4.	“reports” key is of type list and should have comma separated report names.<br />
        &nbsp;&nbsp; e.g. "reports": ["Stand Count", "Nitrogen Status"] instead of "reports": ["Stand Count, Nitrogen Status"]. <br />
        5.	The data type of “acres”, “subtotal”, “totalAcres”, “totalCost” and costs within “costPerAcre” should be of type float/number instead of string.<br />
        6.	It is better to have “data:image/png;base64,” string included in base64 string, as image could be of type png or jpeg. <br />
        7.	Explanation of “summary, <br />
        &nbsp;&nbsp;    a.	Each object with in “summary” has a “reportName” which is a string, and “reportSummary” which is a list of objects. “reportSummary” has “title” and “description”.<br />
        &nbsp;&nbsp;    b.	“summary”: [{'{'} <br />
        &nbsp;&nbsp;&nbsp;&nbsp;    “reportName”: “”, <br />
        &nbsp;&nbsp;&nbsp;&nbsp;    “reportSummary”: [{'{'} <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;       “title”: “”, <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      “description”: “” <br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      {'}'}, {'{'}<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      “title”: “”,<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      “description”: “”<br />
        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;      {'}'}]<br />
        &nbsp;&nbsp;&nbsp;&nbsp;    {'}'}]<br />
      </Box>
      <br />
      <Box>
        Map extents Requirement:<br/>
        A map extent is the portion of area of a region in a map. Along with base64 string, we would require map extent values to display PNG on a map layer. Map extents can be generated in 2 ways:<br/>
        1.	Using GeoJSON: Consider GeoJSON {'{'}"type":"Feature","properties":{'{'}{'}'},"geometry":{'{'}"type":"Polygon","coordinates":[[[-118.583361,45.970274],[-118.591778,45.970513],[-118.594547,45.970593],[-118.600468,45.970663],[-118.600407,45.967035],[-118.597781,45.96699],[-118.597582,45.966987],[-118.580424,45.966695],[-118.580327,45.966749],[-118.58029,45.966832],[-118.580292,45.966896],[-118.580386,45.966995],[-118.580533,45.967085],[-118.580617,45.967275],[-118.5807,45.967878],[-118.580851,45.968123],[-118.581366,45.968692],[-118.582469,45.969682],[-118.583361,45.970274]]]{'}'}{'}'}.<br/>
        &nbsp;&nbsp; To get map extents from this GeoJSON, it is required to loop through coordinate values to find X min, Y min, X max and Y max values. The map extent values for above GeoJSON would be “-118.600468, 45.966695, -118.58029, 45.970663”.<br/>
        &nbsp;&nbsp; Note: If the field boundary has changed during fly, use the updated boundary GeoJSON to generate map extents.<br/>
        2.	Using TIFF file: The link below would provide more information on how to generate map extents from TIFF files.<br/>
        &nbsp;&nbsp;<a href="https://gis.stackexchange.com/questions/283375/python-how-to-set-the-extent-of-a-raster-file-based-on-another-raster-file-no">https://gis.stackexchange.com/questions/283375/python-how-to-set-the-extent-of-a-raster-file-based-on-another-raster-file-no</a><br/>

      </Box>

    </Box>
  )
}

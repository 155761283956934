import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, makeStyles, Box } from "@material-ui/core";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ComparisonButtonGroup from "../../Helpers/ComparisonButtonGroup";
import { COLORS } from "../../../constants";
import FieldReportTable from "./FieldReportTable/FieldReportTable";
import { PriceTool } from '../../ProfitLayers/PriceTool/PriceTool'
import TitleContainer from "./TitleContainer/Title";
import { getProfitMapResponse, fetchField, getProfitMap, logProfitMapresponse, logProfitMapSummary } from "../../../utils/dataFetchers";
import {
  formatForProfitMapGen,
  convertPlantings,
} from "../../Helpers/FarmOperation/helper"
import { formatForProfitMap } from "../../ProfitLayers/utils/conversions"
import * as calc from  "../../ProfitLayers/utils/calculations"
import * as L from "leaflet";
import * as wkt from "terraformer-wkt-parser";
import * as turf from "@turf/turf";
import { CircularProgress } from "@material-ui/core";
import { SpinningLoader } from "../../Shared/SpinningLoader";

const useStyles = makeStyles(() => ({
  wrapper: ({ mobileView }) => ({
    display: "flex",
    flexDirection: "column",
    padding: "25px 25px 0px 25px",
    marginLeft: mobileView ? 0 : 250,
    maxWidth: 1000,
  }),
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    paddingTop: "21.5px",
    color: COLORS.text,
    fontSize: "14px",
    overflowX: "auto",
  },
  buttonWrapper: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  backButton: {
    color: COLORS.green,
    backgroundColor: COLORS.white,
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: "bold",
    border: `1px solid ${COLORS.green}`,
    borderRadius: 5,
    marginTop: 20,
    boxShadow: "none",
  },
  disclaimerWrapper: {
    display: "flex",
    maxWidth: 856,
    marginTop: 65,
    padding: `15px 10px 14px 16px`,
    borderRadius: 5,
    border: `1px solid ${COLORS.lightGray}`,
    fontSize: 16,
    "& a": {
      color: COLORS.green,
    },
  },
  circle: {
    marginRight: 14,
    backgroundColor: COLORS.gray,
    width: 18,
    height: 18,
    borderRadius: "50%",
  },
  priceToolContainer:{
    width:'100%',
    paddingLeft: '100px',
    display:'flex'
  }
}));

/**
 * Renders the field report
 * @param {Object} selectedField field data object
 * @param {Object} activeFarm object with farm data
 * @param {string} activeYear the year
 * @param {Array} fields array of field objects
 * @param {Function} setFields set state function to set active field
 * @param {Map} seedMap Map of fieldIDs to seeding operation object
 * @param {Map} harMap Map of fieldIDs to harvest operation object
 * @param {Map} appMap Map of fieldIDs to application operation object
 * @param {Map} fpMap Map of fieldIDs to field pass operation object
 * @param {Map} otherCostMap Map of fieldIDs to other cost operation object
 * @param {Map} otherRevMap Map of fieldIDs to other revenue operation object
 * @param {boolean} mobileView for mobile views
 * @param {Object} fieldHeaderCells keyed object with metadata for
 * @param {Array} data  array of objects with data for each category
 * @param {Array} categories array of category objects with name and active properties
 * @param {Function} setCategories set state function to set active category
 * @param {Int} ORG_ID organization id for the currently selected organization
 * @param {Map} chemicalMap Map of fieldIDs to chemical object
 * @param {Boolean} checked bool passed down to ComparisonButtonGroup and determines display of application operations
 * @param {Function} setChecked function to update checked bool
 * @param {Boolean} nutrientRate bool passed down to ComparisonButtonGroup and determines display of application operations
 * @param {Function} setNutrientRate function to update nutrientRate bool
 * @param {Map} nutrientMap Map of fieldIds to nutrient objects
 * @param {Boolean} reload boolean, currently un-used
 * @param {Function} setReload function to update reload bool
 * @param {String} reportType string describing report type (eg 'Farm')
 * @param {Object} user object containing user data (see user context usages for an example)
 * @param {Function} loadFarmData function to reload farm report data (needed for the price converter tool)
 */

const FieldReport = ({
  selectedField,
  activeFarm,
  activeYear,
  fields,
  setFields,
  seedMap,
  harMap,
  appMap,
  fpMap,
  otherCostMap,
  otherRevMap,
  mobileView,
  fieldHeaderCells,
  data,
  categories,
  setCategories,
  ORG_ID,
  chemicalMap,
  checked,
  setChecked,
  nutrientRate,
  setNutrientRate,
  nutrientMap,
  reload,
  setReload,
  reportType,
  user,
  loadFarmData
}) => {
  const classes = useStyles({ mobileView });

  const [updatedData, setUpdatedData] = useState(
    categories.map((category) => {
      return {
        category: category.name,
        values: [
          {
            variety: "",
            acres: 0,
            seedsPerAcre: 0,
            seedsPrice: 0,
            seedCost: 0,
          },
        ],
      };
    })
  );

  const [profitMapResponse, setProfitMapResponse] = useState({});

  const [mapImage, setMapImage] = useState("");
  const [mapExtent, setMapExtent] = useState([]);
  const [legend, setLegend] = useState([]);

  const [boundary, setBoundary] = useState(null);

  const [loading, setLoading] = useState(false);
  const [priceToolOpen, setPriceToolOpen] = useState(false)
  const [type, setType] = useState('Harvest')

  const [priceSaving, setPriceSaving] = useState(false)

  useEffect(() => {
    generateUpdatedData();
  },[seedMap,
    harMap,
    appMap,
    fpMap,
    otherCostMap,
    otherRevMap])

  useEffect(() => {
    let selected = categories.filter(x => x.active)[0]
    setType(selected.name)
  },[categories])

  useEffect(() => {
    getFieldBoundary();
  }, [selectedField]);

  useEffect(() => {
    if (boundary !== null) {
      getProfitMapResponseForField();
    }
  }, [boundary]);

  /**
   * Get field boundary from backend to display in map
   */
  const getFieldBoundary = async () => {
    let res = await fetchField(ORG_ID, activeFarm.id, selectedField.id);
    let data = res[0];

    const geojson = L.GeoJSON.geometryToLayer(
      wkt.parse(data.Shape)
    ).toGeoJSON();
    setBoundary(JSON.stringify(geojson));
  };

  /**
   * Formats Map objects into correct format to be used in the different field report displays
   */
  const generateUpdatedData = () => {
    let fieldData = [];
    for (let c of categories) {
      let obj = {};
      let values = [];

      if (c.name == "Harvest") {
        obj.category = "Harvest";
        let opData = harMap.get(selectedField.id);
        for (let op of opData) {
          if (!op.hidden) {
            for (let va of op.varieties) {
              let innerObj = {
                acres: va.varietyArea,
                seedsPrice: va.price,
                seedsPerAcre: va.varietyYieldRate,
                seedCost: va.price * va.varietyYieldRate,
                variety: va.productName + " - " + va.variety,
                unit: va.totalUnit,
                fieldAcres: selectedField.acres,
              };
              values.push(innerObj);
            }
          }
        }
        obj.values = values;
      }

      if (c.name == "Seeding") {
        obj.category = "Seeding";
        let opData = seedMap.get(selectedField.id);
        for (let op of opData) {
          if (!op.hidden) {
            for (let va of op.varieties) {
              let innerObj = {
                acres: va.varietyArea,
                seedsPrice: va.price,
                seedsPerAcre: va.varietyRate,
                seedCost: va.price * va.varietyRate,
                variety: va.productName + " - " + va.variety,
                unit: va.totalUnit,
                fieldAcres: selectedField.acres,
              };
              values.push(innerObj);
            }
          }
        }
        obj.values = values;
      }

      if (c.name == "Chemicals") {
        obj.category = "Chemicals";

        if (!checked) {
          let opData = appMap.get(selectedField.id);
          for (let op of opData) {
            if (!op.isDeleted) {
              let innerObj = {
                acres: op.summaryArea,
                seedsPrice: op.operationCost / op.summaryApplicationRate,
                seedsPerAcre: op.summaryApplicationRate,
                seedCost: op.operationCost,
                variety: op.productName,
                unit: op.summaryTotalAppliedUnit,
                fieldAcres: selectedField.acres,
              };
              values.push(innerObj);
            }
          }
          obj.values = values;
        } else {

          if(!nutrientRate){
            let opData =
            chemicalMap.get(selectedField.id) === undefined
              ? []
              : chemicalMap.get(selectedField.id);
              
          let chemData =
          appMap.get(selectedField.id) === undefined
            ? []
            : appMap.get(selectedField.id);
          //console.log("chemData", chemData)

          for (let op of opData) {
            let operation = chemData.filter(x => x.operationID === op.ApplicationOperationID)
            //console.log("operation", operation)
            let innerObj = {
              operationName: operation.length > 0 ? operation[0].productName : "",
              Chemical: op.Chemical,
              OriginalProduct: op.OriginalProduct,
              ChemicalRate: op.ChemicalRate,
            };
            values.push(innerObj);
          }
          obj.values = values;
          }
          else{
            let opData =
            nutrientMap.get(selectedField.id) === undefined
              ? []
              : nutrientMap.get(selectedField.id);
              
          let chemData =
          appMap.get(selectedField.id) === undefined
            ? []
            : appMap.get(selectedField.id);
          //console.log("chemData", chemData)

          for (let op of opData) {
            let operation = chemData.filter(x => x.operationID === op.ApplicationOperationID)
            //console.log("operation", operation)
            let innerObj = {
              operationName: operation.length > 0 ? operation[0].productName : "",
              NutrientAlias: op.NutrientAlias,
              OriginalProduct: op.OriginalProduct,
              NutrientRate: op.NutrientRate,
            };
            values.push(innerObj);
          }
          obj.values = values;
          }
        }
      }

      if (c.name == "Field Passes") {
        obj.category = "Field Passes";
        let opData = fpMap.get(selectedField.id);

        for (let op of opData) {
          if (!op.isDeleted) {
            let innerObj = {
              acres: op.area,
              seedsPrice: +op.price * +op.area,
              seedsPerAcre: +op.price,
              seedCost: +op.price * +op.area,
              variety: op.name,
              fieldAcres: selectedField.acres,
            };
            values.push(innerObj);
          }
        }
        obj.values = values;
      }

      if (c.name == "Other Costs") {
        obj.category = "Other Costs";
        let opData = otherCostMap.get(selectedField.id);

        for (let op of opData) {
          if (!op.isDeleted) {
            let innerObj = {
              acres: op.total,
              seedsPrice: +op.price * +op.total,
              seedsPerAcre: +op.price,
              seedCost: +op.price * +op.total,
              variety: op.name,
              fieldAcres: selectedField.acres,
            };
            values.push(innerObj);
          }
        }
        obj.values = values;
      }

      if (c.name == "Other Revenue") {
        obj.category = "Other Revenue";
        let opData = otherRevMap.get(selectedField.id);

        for (let op of opData) {
          if (!op.isDeleted) {
            let innerObj = {
              acres: op.total,
              seedsPrice: +op.price * +op.total,
              seedsPerAcre: +op.price,
              seedCost: +op.price,
              variety: op.name,
              fieldAcres: selectedField.acres,
            };
            values.push(innerObj);
          }
        }
        obj.values = values;
      }

      if (c.name == "Chemical Rate") {
        obj.category = "Chemical Rate";
        let opData =
          chemicalMap.get(selectedField.id) === undefined
            ? []
            : chemicalMap.get(selectedField.id);
        let chemData =
        appMap.get(selectedField.id) === undefined
          ? []
          : appMap.get(selectedField.id);
        console.log("chemData", chemData)
        
        for (let op of opData) {
          let operation = chemData.filter(x => x.operationID === op.ApplicationOperationID)
          console.log("operation", operation)
          let innerObj = {
            operationName: operation.length > 0 ? operation[0].productName : "",
            Chemical: op.Chemical,
            OriginalProduct: op.OriginalProduct,
            ChemicalRate: op.ChemicalRate,
          };
          values.push(innerObj);
        }
        obj.values = values;
      }

      fieldData.push(obj);
    }
    console.log("fieldData", fieldData);
    setUpdatedData(fieldData);
  };

  /**
   * Gets the most recent profitmap that was saved for display on the map
   */
  const getProfitMapResponseForField = async () => {
    try {
      let res = await getProfitMapResponse(
        ORG_ID,
        selectedField.id,
        activeYear
      );
      if (res.length > 0) {
        let profitMapResponse = JSON.parse(res[0].profitMap_Response);
        if (profitMapResponse.length > 0) {
          setProfitMapResponse();

          let mapImages = `data:image/jpeg;base64,${profitMapResponse[0].pngb64}`;
          setMapImage(mapImages);

          if (profitMapResponse[0].extent !== undefined) {
            const bounds = profitMapResponse[0].extent.split(",");
            const imageBounds = [
              [parseFloat(bounds[3]), parseFloat(bounds[0])],
              [parseFloat(bounds[1]), parseFloat(bounds[2])],
            ];
            setMapExtent(imageBounds);
          }
          setLegend(profitMapResponse[0].legend);
        }
      }
    } catch (err) {
      console.log("getProfitMapResponseForField failed", err);
    }
  };

  /**
   * Generate profit map for field. Used in the Title if there is no profitable/unprofitable percent for the field
   */
  const generateProfitLayer = async () => {
    try{
      let fieldID = selectedField.id;
      let seed = seedMap.get(fieldID) === undefined ? [] : seedMap.get(fieldID);
      let har = harMap.get(fieldID) === undefined ? [] : harMap.get(fieldID);
      let app = appMap.get(fieldID) === undefined ? [] : appMap.get(fieldID);
      let fp = fpMap.get(fieldID) === undefined ? [] : fpMap.get(fieldID);
      let othCost =
        otherCostMap.get(fieldID) === undefined
          ? []
          : otherCostMap.get(fieldID);
      let othRev =
        otherRevMap.get(fieldID) === undefined ? [] : otherRevMap.get(fieldID);

      const harvests = convertPlantings(har).map((x) => formatForProfitMap(x));
      const seedings = convertPlantings(seed).map((x) => formatForProfitMap(x));
      const applications = app.map((x) => formatForProfitMap(x));

      let fieldOps = [
        ...seedings,
        ...harvests,
        ...applications,
        ...othCost,
        ...othRev,
        ...fp
      ];

      if(fieldOps.length > 0){
        const operationsCopy = formatForProfitMapGen(fieldOps);

        let orgId = fieldOps[0].organizationID;

        let request = {
          FieldOperationList: operationsCopy,
          Constant: 0,
          Year: activeYear,
          IsResizeMapRequest: false,
          ProfitMapLegendRange: null,
          LegendColors: null,
          ProfitByZones: false,
          Zones: null,
          boundarygeojson: null,
          IsHighResolutionMapRequest: false,
          MultiYears: [activeYear],
        };
        setLoading(true);

        let res = await getProfitMap(orgId, fieldID, request);

        if(res.profitMap !== undefined){
          if (res.profitMap.code === 500) {
            
          } else {
            res.profitMap[0].legend = calc.formatProfitLegend(
              res.profitMap[0].legend,
              request
            );
            res.profitMap = calc.updateProfitMapValues(
              fields.filter((f) => f.id === fieldID)[0].profit,
              res.profitMap,
              true,
              fields.filter((f) => f.id === fieldID)[0].acres
            );
  
            let profitLog = {
              ProfitMap_Response: null, //json string of response from get profitmap
              isLegendSaved: null, //bool -> false if range # changed, otherwise true
              zoneBoundary: null, // boundary of zone if map was for zone
              zoneCost: null, // cost if map was for zone
            };
            profitLog.ProfitMap_Response = JSON.stringify(res.profitMap);
            profitLog.isLegendSaved = 0;
  
            const logged = await logProfitMapresponse(
              orgId,
              fieldID,
              activeYear,
              profitLog
            );
  
            let summaryObj = {
              NetSeededAcre: fields.filter((f) => f.id === fieldID)[0].acres,
              Profit: res.profitMap[0].mean,
            };
            const summary = await logProfitMapSummary(
              orgId,
              fieldID,
              activeYear,
              summaryObj
            );

            if (boundary !== null) {
              getProfitMapResponseForField();
            }
            setReload(true)
            setLoading(false)
          }
        }
      }
    }
    catch (err){
      console.log("error generating profit layers", err)
    }
  }

  /**
   * Ignores default event action and sets state variable to open price converter
   * @param {Object} e event object from <a> tag
   */
  const openPriceTool = (e) => {
    e.preventDefault()
    setPriceToolOpen(true)
  }

  /**
   * Function called by PriceTool after a new price is saved.
   * Calls back to get farm report data using Price Tool prices so that 
   * operation prices will be updated
   */
  const handlePriceSave = async () => {
    setPriceSaving(true)
    await loadFarmData(true, selectedField.id)
    // generateUpdatedData();
    setPriceSaving(false)
  }

  const activeCategory = categories.find((category) => category.active);

  return (
    <Box display="flex">
      <div className={classes.wrapper}>
        {
          loading && <CircularProgress size={60}
          style={{
            position: "absolute",
            top: "30%",
            right: 0,
            left: 0,
            margin: "auto",
            zIndex: 1005,
            color: COLORS.green,
          }}/>
        }
        
        <TitleContainer
          activeFarm={activeFarm}
          selectedField={selectedField}
          activeYear={activeYear}
          setFields={setFields}
          mobileView={mobileView}
          mapImage={mapImage}
          mapExtents={mapExtent}
          boundary={boundary}
          legend={legend}
          generateProfitLayer={generateProfitLayer}
        />
        <div className={classes.descriptionContainer}>
          <ComparisonButtonGroup
            categories={categories}
            setCategories={setCategories}
            valueAccessor={(d) => d.name}
            mobileView={mobileView}
            checked={checked}
            setChecked={setChecked}
            nutrientRate={nutrientRate}
            setNutrientRate={setNutrientRate}
            reportType={reportType}
          />
          <FieldReportTable
            headerCells={fieldHeaderCells}
            data={updatedData.find((d) => d.category === activeCategory.name)}
          />
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.backButton}
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={() =>
                setFields(fields.map((field) => ({ ...field, selected: false })))
              }
            >
              Back to Farm Report
            </Button>
          </div>
          <div className={classes.disclaimerWrapper}>
            <div className={classes.circle} />
            <div>
              Notice a problem with these prices? Use our{" "}
              <a href="#" onClick={(e) => openPriceTool(e)}>Price Tool</a> to update them across all
              your fields.
            </div>
          </div>
        </div>
        
      </div>
      {priceToolOpen &&
        <Box className={classes.priceToolContainer}>
          <PriceTool
            setOpen={setPriceToolOpen}
            field={selectedField}
            user={user}
            selectedYears={[activeYear]}
            isProfitLayers={true}
            type={type}
            setSaving={setPriceSaving}//bool for displaying loading spinner
            refreshHarvestOperations={handlePriceSave}
            refreshApplicationOperations={handlePriceSave}
            refreshSeedingOperations={handlePriceSave}
            refreshGenericOperations={handlePriceSave}
            isFieldReport
          />
        </Box>
      }
      {priceSaving &&
        <SpinningLoader/>
      }
    </Box>
  );
};

export default FieldReport;

FieldReport.propTypes = {
  selectedField: PropTypes.object.isRequired,
  activeFarm: PropTypes.object.isRequired,
  activeYear: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  setFields: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  fieldHeaderCells: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  setCategories: PropTypes.func.isRequired,
};

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider, Typography } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { numFormat, dollarFormat } from '../../../../utils/helpers';
import { green, blackText } from '../../../../styles/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  table: {
    marginTop: 4,
    fontWeight: 500,
    color: theme.palette.text.black,
    minWidth: 300,
  },
  tableRow: {
    display: "flex",
    flexGrow: 1,
    padding: "8px",
    fontWeight: 500,
    color: theme.palette.text.black,
  },
}));

/**
 * Summary table for elevation map and associated types
 * @param {Array} legend         [description]
 * @param {Boolean} perAcre        [description]
 * @param {Number} netSeededAcres [description]
 * @param {String} type
 */
export function MobileElevationSummary({
  legend,
  perAcre,
  netSeededAcres,
  type
}) {
  const classes = useStyles();

  const legendRow = (x, i) => {
    const measure = type === 'elevationMap' ? ' ft' : ''
    return (
      <Box
        key={i}
        display='flex'
        alignItems='center'
        style={{padding: 4}}
      >
        <FiberManualRecordIcon
          style={{color: x.color, marginRight: 6}}
        />
        <Box>
          {numFormat(x.min)}
          {measure}
          {' - '}
          {numFormat(x.max)}
          {measure}
        </Box>
      </Box>
    )
  }

  const title = () => {
    console.log(type)
    if (type === 'Slope') {
      return 'Slope Summary (ft)';
    }
    else if(type === 'Relative_Elevation'){
      return 'Relative Elevation Map Summary (z-score)';
    }
    else if (type === 'TPI') {
      return 'Topographic Position Index'
    }
    else if (type === 'TRI') {
      return 'Terrain Ruggedness Index'
    }
    else {
      return 'Elevation Map Summary (ft)';
    }
  }

  return (
    <Box className={classes.root}>
      <Box
        className={classes.table}
        border={1}
        borderColor={green}
        borderRadius='borderRadius'
      >
        <Box style={{backgroundColor: green, padding: 4}}>
          <Typography
            align='center'
            style={{fontWeight: 500, fontSize: 16, color: '#ffffff'}}
          >
            { title() }
          </Typography>
        </Box>

        {
          legend !== undefined && (
            legend.map((x, i) => legendRow(x, i))
          )
        }

      </Box>
    </Box>

  )
}

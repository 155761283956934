import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, withStyles, useTheme } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import TabPanel from "@material-ui/lab/TabPanel";
import TabContext from "@material-ui/lab/TabContext";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import produce from "immer";
import { useMediaQuery } from "@material-ui/core";
import ColumnSelects from "./ReportColumnSelects";
import { ADVANCED_CONTROLS, COLORS } from "../../../../constants";
import FilterResults from "./FilterResults/FilterResults";
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';

const ThemedTabs = withStyles((theme) => ({
  root: {
    padding: `0 ${theme.spacing(3)}px`,
  },
  indicator: {
    backgroundColor: COLORS.green,
  },
}))((props) => <Tabs {...props} />);

const ThemedTab = withStyles(() => ({
  root: {
    fontSize: 20,
    fontWeight: "bold",
    color: COLORS.gray,
    textTransform: "none",
    "&$selected": {
      color: COLORS.green,
    },
  },
  selected: {
    color: COLORS.green,
  },
}))((props) => <Tab {...props} />);

const ThemedButton = withStyles(() => ({
  root: {
    color: COLORS.white,
    backgroundColor: COLORS.green,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "bold",
    border: `1px solid ${COLORS.green}`,
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "none",
    "&:hover": {
      backgroundColor: COLORS.green,
    },
  },
}))((props) => <Button {...props} variant="contained" disableElevation />);

const ThemedButtonOutline = withStyles(() => ({
  root: {
    color: COLORS.green,
    backgroundColor: COLORS.white,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "bold",
    border: `1px solid ${COLORS.green}`,
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "none",
  },
}))((props) => <Button {...props} variant="outlined" />);

const styles = (theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const useStyles = makeStyles((theme) => ({
  dialog: (smallScreen) => ({
    margin: smallScreen ? "2rem 0" : 0,
  }),
  content: (smallScreen) => ({
    padding: 0,
    minWidth: smallScreen ? 0 : 700,
    width: "100%",
  }),
  tabs: {
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  tab: {},
  actions: (smallScreen) => ({
    backgroundColor: COLORS.veryLightGrey,
    padding: `${theme.spacing(2)}px ${theme.spacing(3)}px`,
    borderTop: `1px solid ${COLORS.lightGray}`,
    justifyContent: smallScreen ? "flex-start" : "flex-end",
  }),
  buttons: {},
  button: {},
  buttonOutline: {
    color: COLORS.green,
    backgroundColor: COLORS.white,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "bold",
    border: `1px solid ${COLORS.green}`,
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "none",
  },
}));

/**
 * Container that shows dialog box with the two tabs of 'select columns' and 'filter results'
 * @param {boolean} open  based on whether to show advanced controls
 * @param {function} setTableControls  set state function
 * @param {array} options array of objects with option information
 * @param {function} setOptions set state function
 * @param {array} selectedColumns array of objects with info for each select:
 * {id: string, active: bool, disabled: bool}
 * @param {func} setSelectedColumns set state function
 * @param {boolean} variety boolean to indicate if Report dialog is for Farm Report overview or Farm Report Variety overview
 */

const ReportDialog = ({
  open,
  setTableControls,
  options,
  setOptions,
  selectedColumns,
  setSelectedColumns,
  variety
}) => {
  const theme = useTheme();
  const smallScreen = useMediaQuery(theme.breakpoints.down("xs"));
  const classes = useStyles(smallScreen);

  let panels=[
    { id: ADVANCED_CONTROLS.Select, active: true },
    { id: ADVANCED_CONTROLS.Filter, active: false },
  ];
  if(variety){
    panels = [
      { id: ADVANCED_CONTROLS.Filter, active: true },
    ]
  }

  const [panelValues, setPanelValues] = useState(panels);

  const handleTabChange = (event, newValue) => {
    setPanelValues(
      panelValues.map((panelValue, i) => ({
        ...panelValue,
        active: i === newValue,
      }))
    );
  };

  const handleNext = () => {
    setPanelValues(
      panelValues.map((panelValue) => ({
        ...panelValue,
        active: panelValue.id === ADVANCED_CONTROLS.Filter,
      }))
    );
  };

  const handleClose = () => {
    setTableControls(
      produce((draft) => {
        draft.showAdvancedControls = false;
      })
    );
  };

  const handleClear = () => {
    setOptions(
      options.map((option) => ({
        ...option,
        filteredRange: option.range,
      }))
    );
  };

  const handleCloseClick = () => {
    handleClear();
    handleClose();
  }

  const activePanelValue = panelValues.find((d) => d.active);

  return (
    <>
      <Dialog
        className={classes.dialog}
        maxWidth="md"
        open={open}
        fullScreen={smallScreen}
        onBackdropClick={handleClose}
      >
        <DialogTitle id="customized-dialog-title" onClose={handleCloseClick}>
          Advanced Controls
        </DialogTitle>
        <DialogContent className={classes.content}>
          <TabContext value={activePanelValue.id}>
            <ThemedTabs
              className={classes.tabs}
              value={panelValues.map((d) => d.id).indexOf(activePanelValue.id)}
              indicatorColor="primary"
              textColor="primary"
              onChange={handleTabChange}
            >
              {panelValues.map((panelValue) => (
                <ThemedTab key={`tab-${panelValue.id}`} label={panelValue.id} />
              ))}
            </ThemedTabs>
            {!variety && 
              <TabPanel value={ADVANCED_CONTROLS.Select}>
                <ColumnSelects
                  options={options}
                  selectedColumns={selectedColumns}
                  setSelectedColumns={setSelectedColumns}
                  isSmallScreen={smallScreen}
                />
              </TabPanel>
            }
            <TabPanel value={ADVANCED_CONTROLS.Filter} style={{padding: "unset"}}>
              <FilterResults
                options={options}
                setOptions={setOptions}
                selectedColumns={selectedColumns}
                isSmallScreen={smallScreen}
                variety={variety}
              />
            </TabPanel>
          </TabContext>
        </DialogContent>
        <DialogActions className={classes.actions}>
          {activePanelValue.id === ADVANCED_CONTROLS.Select ? (
            <>
              <ThemedButton onClick={handleNext}>
                Next <ArrowForwardIcon />
              </ThemedButton>
              <ThemedButtonOutline onClick={handleClose}>
                Close
              </ThemedButtonOutline>
            </>
          ) : (
            <>
              <ThemedButton onClick={handleClose}>Save</ThemedButton>
              <ThemedButtonOutline onClick={handleClear}>
                Clear
              </ThemedButtonOutline>
              <ThemedButtonOutline onClick={handleCloseClick}>
                Close
              </ThemedButtonOutline>
            </>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ReportDialog;

ReportDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  setTableControls: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  setSelectedColumns: PropTypes.func.isRequired,
};

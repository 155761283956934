import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// Map imports
import * as L from 'leaflet';

// Functions
import { drawBasicMap } from '../MapFunctions/drawMap';
import { createGeoFromBoundary } from '../MapFunctions/helpers';

import { zoneColors } from '../Styles/layerStyles';

/**
 * Takes a CLU and draws associated boundaries
 * @param {Object} clu Clu info, including subfield boundaries
 * @param {String} completeBoundary Full CLU boundary
 * @param {Boolean} isRecentMapDrawn Helps decide whether to redraw the boundary
 * @param {Function} setIsRecentMapDrawn Changes state of isRecentMapDrawn
 * @returns {JSX} map
 */
export function CluMap({ clu, completeBoundary, isRecentMapDrawn, setIsRecentMapDrawn }) {
  const generateID = () => { return Math.floor(Math.random() * Math.floor(10000)).toString(); }
  const mapId = useRef(generateID());
  const [map, setMap] = useState(null);
  const [drawn, setDrawn] = useState(false);
  const cluLayer = useRef(L.featureGroup(null));

  useEffect(() => {
    if (map === null) {
      try {
        drawBasicMap(setMap, cluLayer.current, mapId.current);
      } catch (error) {
        // Incase generate duplicate ID
        // NOTE: I think there is a try-catch in leaflet for this case that will just cause this catch to never occur
        mapId.current = generateID();
      }
    }
  }, [mapId, map]);

  useEffect(() => {
    if (!isRecentMapDrawn) { 
      // Also need to remove previous added map layers
      cluLayer.current.eachLayer((layer) => {
        map.removeLayer(layer);
      });
      cluLayer.current.clearLayers();
      setDrawn(false); 
      setIsRecentMapDrawn(true);
    }
  }, [isRecentMapDrawn])

  useEffect(() => {
    // console.log('isRecentMapDrawn :>> ', isRecentMapDrawn);
    // console.log('drawn :>> ', drawn);
    // console.log('(clu && map && completeBoundary && !drawn) :>> ', (clu && map && completeBoundary && !drawn));
    if (clu && map && completeBoundary && !drawn) {
      setDrawn(true);

      // Add full CLU boundary and fit map bounds
      if (completeBoundary !== 'X') {
        const boundary = createGeoFromBoundary(completeBoundary);
        const boundaryCollection = L.geoJson(boundary, {
          onEachFeature: (feature, layer) => {
            layer.setStyle({ fillOpacity: 0 });
            cluLayer.current.addLayer(layer);
          }
        });
        boundaryCollection.addTo(map);
      }

      // Add subfield boundaries
      clu.forEach((subField, i) => {
        const geo = createGeoFromBoundary(subField.boundary);
        const collection = L.geoJson(geo, {
          onEachFeature: (feature, layer) => {
            const color = zoneColors[i];
            const weight = 2 + i;
            layer.setStyle({
              color,
              weight,
              opacity: 1,
              fillOpacity: 0.35,
            });
            cluLayer.current.addLayer(layer);
            const toolTip = `<div style="height: 30; width: 30;"><div style="margin: 2px 6px;">Subfield: ${subField.subfieldNumber}</div></div>`;
            layer.bindTooltip(toolTip, { className: 'leaftletTooltipClass' });
          },
        });
        collection.addTo(map);
      });
      
      // Fit map to bounds of drawn shapes
      map.fitBounds(cluLayer.current.getBounds(), {
        padding: [30, 30],
      });
    }
  }, [clu, map, completeBoundary, drawn]);

  return (
    <Box
      id={`${mapId.current}`}
      height="100%"
      width="100%"
    />
  );
}

CluMap.propTypes = {
  clu: PropTypes.array.isRequired,
  completeBoundary: PropTypes.string.isRequired,
  isRecentMapDrawn: PropTypes.bool.isRequired,
  setIsRecentMapDrawn: PropTypes.func.isRequired,
};

/* eslint-disable react/jsx-closing-tag-location */
import React from 'react';
// Deliverables
const zoneCreation = {
  number: 1,
  deliverable: 'Zone Creation Consultation',
  description: 'Yield-based management zones created by combining your declared 2.5-acre or 5-acre sampling grids with your latest yield maps (provided by the customer) and bare-earth imagery',
};

const soilCollectionTwoPointFive = {
  number: 2,
  deliverable: 'Soil Collection (2.5-Poly)',
  description: 'Professional collection of soil samples from your field',
};

const soilCollectionFive = {
  number: 2,
  deliverable: 'Soil Collection (5-Poly)',
  description: 'Professional collection of soil samples from your field',
};

const labAnalysisPolyS1 = {
  number: 3,
  deliverable: 'Lab Analysis (S1)',
  description: 'Laboratory soil tests and fertility analysis based on best management practices from the region. Customers can select Basic or Advanced Fertility tests and analysis. Results are generated and returned as data tables',
};

const labAnalysisPolyS3 = {
  number: 3,
  deliverable: 'Lab Analysis (S3)',
  description: 'Laboratory soil tests and fertility analysis based on best management practices from the region. Customers can select Basic or Advanced Fertility tests and analysis. Results are generated and returned as data tables',
};

const labAnalysisGridS1 = {
  number: 3,
  deliverable: 'Lab Analysis (S1)',
  description: 'Laboratory soil tests and fertility analysis based on best management practices from the region. Customers can select Basic or Advanced Fertility tests and analysis. Results are generated and returned as data tables',
};

const labAnalysisGridS3 = {
  number: 3,
  deliverable: 'Lab Analysis (S3)',
  description: 'Laboratory soil tests and fertility analysis based on best management practices from the region. Customers can select Basic or Advanced Fertility tests and analysis. Results are generated and returned as data tables',
};

const mapBook = {
  number: 4,
  deliverable: 'Map Book',
  description: ' A collection of maps representing the results returned by the Lab Fertility Analysis',
};

const fertilityApplicationScript = {
  number: 5,
  deliverable: 'Fertility Application Script',
  description: ' Recommendations for next season’s nutrient management plan, as defined by results from the Lab Fertility Analysis and the Soil Analytics Tool, as well as your predicted upcoming crop',
};

const soilAnalysisTool = {
  number: 6,
  deliverable: 'Soil Analysis Tool',
  description: 'Visual analysis of limiting nutrients and overall nutrient performance, which combines your yield maps (provided by the customer) and Lab Fertility Analysis results',
};

export const twoPointFiveAcrePolygonPackages = [
  {
    name: '2.5 Acre Polygon Basic Package (S1 Test) "2.5PolyBas"',
    displayName: <span>
      2.5 Acre Polygon
      <br />
      Basic Package (S1 Test)
    </span>,
    deliverables: [
      zoneCreation,
      soilCollectionTwoPointFive,
      labAnalysisGridS1,
      mapBook,
      fertilityApplicationScript,
      soilAnalysisTool,
    ],
    msrp: 7,
  },
  {
    name: '2.5 Acre Polygon Advanced Package (S3 Test) "2.5PolyAdv"',
    displayName: <span>
      2.5 Acre Polygon
      <br />
      Advanced Package (S3 Test)
    </span>,
    deliverables: [
      zoneCreation,
      soilCollectionTwoPointFive,
      labAnalysisGridS3,
      mapBook,
      fertilityApplicationScript,
      soilAnalysisTool,
    ],
    msrp: 10,
  },
];

export const twoPointFiveAcreGridPackages = [
  {
    name: '2.5 Acre Grid Basic Package (S1 Test) "2.5GridBas"',
    displayName: <span>
      2.5 Acre Grid
      <br />
      Basic Package (S1 Test)
    </span>,
    deliverables: [
      soilCollectionTwoPointFive,
      labAnalysisPolyS1,
      mapBook,
      fertilityApplicationScript,
    ],
    msrp: 8,
  },
  {
    name: '2.5 Acre Grid Advanced Package (S3 Test) "2.5GridAdv"',
    displayName: <span>
      2.5 Acre Grid
      <br />
      Advanced Package (S3 Test)
    </span>,
    deliverables: [
      soilCollectionTwoPointFive,
      labAnalysisPolyS3,
      mapBook,
      fertilityApplicationScript,
    ],
    msrp: 11,
  },
];

export const fiveAcrePolygonPackages = [
  {
    name: '5 Acre Polygon Basic Package (S1 Test) "5.0PolyBas"',
    displayName: <span>
      5 Acre Polygon
      <br />
      Basic Package (S1 Test)
    </span>,
    deliverables: [
      zoneCreation,
      soilCollectionFive,
      labAnalysisPolyS1,
      mapBook,
      fertilityApplicationScript,
      soilAnalysisTool,
    ],
    msrp: 5,
  },
  {
    name: '5 Acre Polygon Advanced Package (S3 Test) "5.0PolyAdv"',
    displayName: <span>
      5 Acre Polygon
      <br />
      Advanced Package (S3 Test)
    </span>,
    deliverables: [
      zoneCreation,
      soilCollectionFive,
      labAnalysisPolyS3,
      mapBook,
      fertilityApplicationScript,
      soilAnalysisTool,
    ],
    msrp: 7,
  },
];

export const fiveAcreGridPackages = [
  {
    name: '5 Acre Grid Basic Package (S1 Test) "5.0GridBas"',
    displayName: <span>
      5 Acre Grid
      <br />
      Basic Package (S1 Test)
    </span>,
    deliverables: [
      soilCollectionFive,
      labAnalysisGridS1,
      mapBook,
      fertilityApplicationScript,
    ],
    msrp: 6,
  },
  {
    name: '5 Acre Grid Advanced Package (S3 Test) "5.0GridAdv"',
    displayName: <span>
      5 Acre Grid
      <br />
      Advanced Package (S3 Test)
    </span>,
    deliverables: [
      soilCollectionFive,
      labAnalysisGridS3,
      mapBook,
      fertilityApplicationScript,
    ],
    msrp: 7.5,
  },
];

export const twoPointFiveAcrePackages = [
  ...twoPointFiveAcrePolygonPackages,
  ...twoPointFiveAcreGridPackages,
];

export const fiveAcrePackages = [
  ...fiveAcrePolygonPackages,
  ...fiveAcreGridPackages,
];

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  TextField,
  Button,
  LinearProgress,
  CircularProgress,
  Divider,
  Checkbox,
  Fade,
  Chip,
  Select,
  Input,
  MenuItem,
  ListItemText,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import GetAppIcon from '@material-ui/icons/GetApp';
import domtoimage from 'dom-to-image';
import { PDFDownloadLink } from '@react-pdf/renderer';
import {
  blackText, darkText, green, grey,
} from '../../../styles/colors';
import { MenuProps } from '../../../styles/select';
import { CustomToolTip } from '../../../utils/customComponents';
import { exists, sleep } from '../../../utils/helpers';
import { requestNaipImagery } from '../../Maps/MapFunctions/naip_functions';

// Maps
import { AerialImageryMap } from '../AerialImagery/AerialImageryMap';
import { PolarisMap } from '../Polaris/PolarisMap';
import { CropLandMap } from '../CropLand/CropLandMap';
import { SoilDataMap } from '../SSURGO/SoilDataMap';
import { NdviMap } from '../CropHealth/ndviMap';
import { TopographyMap } from '../Topography/TopographyMap';
import { SARMap } from '../SAR/SARMap';

// Charts
import { GddChart } from '../GrowingDays/GddChart';
import { PrecipChart } from '../Precipitation/PrecipChart';

// React PDF
import { DataLayersPdf } from './DataLayersPdf';

const useStyles = makeStyles((theme) => ({
  getPdf: {
    width: 200,
    fontWeight: 600,
    fontSize: 20,
  },
  downloadLink: {
    color: green,
    '&:hover': {
      color: theme.palette.primary.dark,
      cursor: 'pointer',
    },
  },
  infoToolTip: {
    ...theme.infoToolTip,
    margin: '0 4px 8px 4px',
  },
  chips: theme.chips,
  chip: theme.chip,
}));

/**
 * Interface for creating PDF.
 * @param {*} field
 * @param {*} status
 * @param {*} polarisAverage
 * @param {*} polarisLegend
 * @param {*} polarisImage
 * @param {*} polarisExtent
 * @param {*} cropLandImage
 * @param {*} cropLandLegend
 * @param {*} cropLandExtent
 * @param {*} soilGeo
 * @param {*} soilLegend
 * @param {*} soilAverage
 * @param {*} ndviImage
 * @param {*} ndviExtent
 * @param {*} ndviLegend
 * @param {*} ndviAttributes
 * @param {*} gddData
 * @param {*} gddStart
 * @param {*} gddEnd
 * @param {*} precipData
 * @param {*} precipStart
 * @param {*} precipEnd
 * @param {*} elevationImage
 * @param {*} elevationLegend
 * @param {*} elevationExtent
 * @param {*} elevationIndexSelection
 * @param {*} selectedElevation
 * @param {*} getElevationIndex
 * @param {*} naipData
 * @param {*} selectedNaipYear
 * @param {*} setSelectedNaipYear
 * @param {*} naipYears
 * @param {*} getNaip
 * @param {*} sarImage
 * @param {*} sarExtent
 * @param {*} authenticated
 * @returns {JSX} Interface for creating PDF
 */
export function CreatePdf({
  field,
  status,
  polarisAverage,
  polarisLegend,
  polarisImage,
  polarisExtent,
  cropLandImage,
  cropLandLegend,
  cropLandExtent,
  soilGeo,
  soilLegend,
  soilAverage,
  ndviImage,
  ndviExtent,
  ndviLegend,
  ndviAttributes,
  gddData,
  gddStart,
  gddEnd,
  precipData,
  precipStart,
  precipEnd,
  elevationImage,
  elevationLegend,
  elevationExtent,
  elevationIndexSelection,
  selectedElevation,
  getElevationIndex,
  naipData,
  selectedNaipYear,
  setSelectedNaipYear,
  naipYears,
  getNaip,
  sarImage,
  sarExtent,
  authenticated,
}) {
  const classes = useStyles();

  const [reportName, setReportName] = useState('Data Layers');
  const [notes, setNotes] = useState('');

  const [polarisMap, setPolarisMap] = useState('');
  const [cropLandMap, setCropLandMap] = useState('');
  const [soilDataMap, setSoilDataMap] = useState('');
  const [ndviMap, setNdviMap] = useState('');
  const [topographyMap, setTopographyMap] = useState('');
  const [precipChart, setPrecipChart] = useState('');
  const [gddChart, setGddChart] = useState('');
  const [naipMap, setNaipMap] = useState('');
  const [sarMap, setSarMap] = useState('');
  const [premiumGraph, setPremiumGraph] = useState('');
  const [whatIfAnalysis, setWhatIfAnalysis] = useState('');
  const [insuranceAI, setInsuranceAI] = useState('');

  const [loading, setLoading] = useState(false);
  const [pdfCanDownload, setPdfCanDownload] = useState(false);
  const [pdfReady, setPdfReady] = useState(false);

  const [includePolaris, setIncludePolaris] = useState(true);
  const [includeCropLand, setIncludeCropLand] = useState(true);
  const [includeSoilData, setIncludeSoilData] = useState(true);
  const [includeNdvi, setIncludeNdvi] = useState(true);
  const [includePrecip, setIncludePrecip] = useState(true);
  const [includeGdd, setIncludeGdd] = useState(true);
  const [includeSar, setIncludeSar] = useState(true);
  // This response can take much more time than others, so don't include by default
  const [includeInsurance, setIncludeInsurance] = useState(false);
  // only available for logged in users
  const [includeTopography, setIncludeTopography] = useState(true);
  const [includeNaip, setIncludeNaip] = useState(true);

  // Multiple options
  const [selectedTopography, setSelectedTopography] = useState([elevationIndexSelection[0].name]);
  const [selectedNaipYears, setSelectedNaipYears] = useState([selectedNaipYear]);

  const finishedLoading = () => {
    const includedByDefaultStatus = { ...status };
    // remove status not included by default
    delete includedByDefaultStatus.insuranceTool;

    if (!authenticated) {
      delete includedByDefaultStatus.elevationIndexStatus;
      delete includedByDefaultStatus.Naip;
    }

    // Check if all loading statuses are false
    const finished = Object.keys(includedByDefaultStatus).every((k) => includedByDefaultStatus[k] === false);
    return finished;
  };

  useEffect(() => {
    if (field.name !== undefined && field.name !== '') {
      const date = new Date();
      setReportName(
        `${
          field.name
        }- ${date.getMonth()}/${date.getDay()}/${date.getFullYear()}`,
      );
    }
  }, [field]);

  useEffect(() => {
    exists(polarisImage) ? setIncludePolaris(true) : setIncludePolaris(false);
  }, [polarisImage]);

  useEffect(() => {
    exists(cropLandImage) ? setIncludeCropLand(true) : setIncludeCropLand(false);
  }, [cropLandImage]);

  useEffect(() => {
    exists(soilGeo) ? setIncludeSoilData(true) : setIncludeSoilData(false);
  }, [soilGeo]);

  useEffect(() => {
    exists(ndviImage) ? setIncludeNdvi(true) : setIncludeNdvi(false);
  }, [ndviImage]);

  useEffect(() => {
    if (gddData?.length > 0 && gddData[0]?.CumGDD) {
      setIncludeGdd(true);
    } else {
      setIncludeGdd(false);
    }
  }, [gddData]);

  useEffect(() => {
    if (precipData?.length > 0 && precipData[0]?.CumPrecipitation) {
      setIncludePrecip(true);
    } else {
      setIncludePrecip(false);
    }
  }, [precipData]);

  useEffect(() => {
    exists(sarImage) ? setIncludeSar(true) : setIncludeSar(false);
  }, [sarImage]);

  useEffect(() => {
    if (exists(elevationImage) && authenticated) {
      setIncludeTopography(true);
    } else {
      setIncludeTopography(false);
    }
  }, [elevationImage, authenticated]);

  useEffect(() => {
    if (exists(naipData) && authenticated) {
      setIncludeNaip(true);
    } else {
      setIncludeNaip(false);
    }
  }, [naipData, authenticated]);

  // useEffect(() => {
  //   setIncludeInsurance(authenticated);
  // }, [authenticated]);

  /* Change handlers */
  const handleChange = (event) => {
    setNotes(event.target.value);
  };

  const handleTopographyChange = (event) => {
    setSelectedTopography(event.target.value);
  };

  const handleNaipYearChange = (event) => {
    setSelectedNaipYears(event.target.value);
  };

  const resetPdfButton = async () => {
    await sleep(1000);
    setPdfReady(false);
    setPdfCanDownload(false);
    setLoading(false);
  };

  const downloadReady = () => {
    try {
      const element = document.getElementById('download-pdf');
      if (element) {
        setTimeout(() => { element.click(); }, 0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const pdfButton = () => (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        id="download-pdf"
        onClick={() => resetPdfButton()}
        style={{ height: 40 }}
      >
        <GetAppIcon />
        {' Download PDF'}
      </Button>
      {setTimeout(() => { downloadReady(); }, 0)}
    </>
  );

  const downloadPdf = () => (
    <PDFDownloadLink
      onLoadError={console.error}
      document={(
        <DataLayersPdf
          field={field}
          notes={notes}
          reportName={reportName}
          polarisMap={polarisMap}
          polarisAverage={polarisAverage}
          polarisLegend={polarisLegend}
          cropLandMap={cropLandMap}
          cropLandLegend={cropLandLegend}
          soilDataMap={soilDataMap}
          soilLegend={soilLegend}
          soilAverage={soilAverage}
          ndviMap={ndviMap}
          ndviLegend={ndviLegend}
          ndviAttributes={ndviAttributes}
          topographyMap={topographyMap}
          elevationLegend={elevationLegend}
          selectedElevation={selectedElevation}
          gddChart={gddChart}
          precipChart={precipChart}
          sarMap={sarMap}
          naipMap={naipMap}
          naipYear={selectedNaipYear}
          premiumGraph={premiumGraph}
          whatIfAnalysis={whatIfAnalysis}
          insuranceAI={insuranceAI}
        />
      )}
      fileName={`${reportName}.pdf`}
      style={{ textDecoration: 'none' }}
    >
      {({
        blob, url, loading, error,
      }) => (
        loading
          ? (
            <Box display="flex" alignItems="center" height={40}>
              <LinearProgress style={{ height: 6, width: 80 }} />
            </Box>
          )
          : pdfButton()
      )}
    </PDFDownloadLink>
  );

  const clickDownload = () => {
    try {
      document.getElementById('download-pdf').click();
    } catch (e) {
      console.log('missed click');
    }
  };

  const getPdfData = async () => {
    setLoading(true);

    // check that component isnt loading and image for map exists
    if (includeNdvi && !status.NDVI) {
      const ndvi = await getImage('ndvi-pdf');
      setNdviMap(ndvi);
    }

    if (includeTopography && !status.elevationIndexStatus) {
      // const topography = await getImage('topography-pdf');
      // setTopographyMap(topography);
      const images = await getTopographyImages();
      setTopographyMap(images);
    }

    if (includeSoilData && !status.SSURGO) {
      const ssurgo = await getImage('ssurgo-pdf');
      setSoilDataMap(ssurgo);
    }

    if (includeNaip && !status.Naip) {
      // const naip = await getImage('naip-pdf');
      // setNaipMap(naip);
      const images = await getNaipImages();
      setNaipMap(images);
    }

    if (includeCropLand && !status.CDL) {
      const cropLand = await getImage('cropland-pdf');
      setCropLandMap(cropLand);
    }

    if (includeGdd && !status.GDD) {
      const gdd = await getImage('gdd-pdf');
      setGddChart(gdd);
    }

    if (includePrecip && !status.Precip) {
      const precip = await getImage('precip-pdf');
      setPrecipChart(precip);
    }

    if (includePolaris && !status.Polaris) {
      const polaris = await getImage('polaris-pdf');
      setPolarisMap(polaris);
    }

    if (includeSar && !status.SAR) {
      const sar = await getImage('sar-pdf');
      setSarMap(sar);
    }

    if (includeInsurance && !status.insuranceTool) {
      try {
        const premiumGraph = await getImage('premGraph');
        const whatIfAnalysis = await getImage('what-if-charts-graphs');
        const insuranceAI = await getImage('insurance-ai-tables');
        setPremiumGraph(premiumGraph);
        setWhatIfAnalysis(whatIfAnalysis);
        setInsuranceAI(insuranceAI);
      } catch (err) {
        console.log(err);
      }
    }

    setPdfReady(true);
    setLoading(false);
  };

  const getTopographyImages = async () => {
    const images = [];
    const selected = elevationIndexSelection.filter((x) => selectedTopography.includes(x.name));
    for (const elevationIndex of selected) {
      const legend = await getElevationIndex(elevationIndex.value);
      const image = await getImage('topography-pdf');
      const unit = elevationIndex.unit === 'ft' ? 'Feet' : elevationIndex.unit;
      images.push({
        image,
        legend,
        unit,
        name: elevationIndex.name,
      });
    }
    return images;
  };

  const getNaipImages = async () => {
    const images = [];

    for (const year of selectedNaipYears.filter((x) => x !== '')) {
      setSelectedNaipYear(year);
      const data = await getNaip(year, field.boundary);
      await sleep(500);
      const image = await getImage('naip-pdf');
      images.push({ year, image });
    }

    return images;
  };

  const getImage = async (id) => {
    try {
      const node = document.getElementById(id);
      const dataUrl = await domtoimage.toPng(node);
      if (dataUrl !== null) {
        return dataUrl;
      }

      return '';
    } catch (e) {
      console.log(e);
      // console.log(`Problem getting ${id}: ${e}`)
      return '';
    }
  };

  return (
    <Box color={blackText} fontWeight={500}>
      <Box mt={1} m={2}>
        <Box fontSize={18}>
          Generate Data Layers PDF
        </Box>
        <Divider />
      </Box>
      <Box
        p={2}
        display="flex"
        flexWrap="wrap"
        color={blackText}
        fontWeight={500}
      >
        <Box>
          <Box>
            {
              pdfCanDownload && (
                <Fade in={pdfCanDownload}>
                  <Box
                    mt={1}
                    className={classes.downloadLink}
                    onClick={() => clickDownload()}
                  >
                    Your PDF is ready for download
                  </Box>
                </Fade>
              )
            }

            <Box
              my={1}
              display="flex"
              style={{ height: 50 }}
            >
              <Box
                fontSize={16}
                color={darkText}
                style={{ margin: '8px 6px 0 0' }}
              >
                Report Name:
              </Box>

              <Box>
                <TextField
                  variant="outlined"
                  value={reportName}
                  onChange={(event) => setReportName(event.target.value)}
                  style={{ width: 200, height: 28 }}
                  InputLabelProps={{
                    shrink: false,
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </Box>
            </Box>
          </Box>

          <Box>
            <TextField
              id="pdf-notes"
              label="Add Notes"
              multiline
              variant="outlined"
              rows={12}
              value={notes}
              onChange={handleChange}
              style={{ width: 400 }}
            />
          </Box>

          { finishedLoading() ? (
            <Box
              p={1}
            >
              {
                loading
                  ? (
                    <Box display="flex" alignItems="center" height={40}>
                      <LinearProgress style={{ height: 6, width: 80 }} />
                    </Box>
                  )
                  : !pdfReady
                    ? (
                      <Button
                        variant="outlined"
                        color="primary"
                        size="large"
                        onClick={() => getPdfData()}
                        style={{ height: '40px' }}
                      >
                        <GetAppIcon />
                        {' PDF Report'}
                      </Button>
                    )
                    : downloadPdf()
              }
            </Box>
          ) : (
            <Box p={1}>
              <Box display="flex" alignItems="center" height={40}>
                <LinearProgress style={{ height: 6, width: 80 }} />
              </Box>
            </Box>

          )}

          <Box
            style={{
              width: '0px',
              height: '0px',
              overflow: 'hidden',
            }}
          >
            <Box
              id="naip-pdf"
              display="flex"
              borderRadius={4}
              height={540}
              width={540}
              style={{
                overflow: 'auto',
              }}
            >
              <AerialImageryMap
                boundary={field.boundary}
                naipData={naipData}
                selectedYear={selectedNaipYear}
                showNaip
                height={500}
                width={500}
              />
            </Box>

            <Box
              id="polaris-pdf"
              display="flex"
              borderRadius={4}
              height={540}
              width={540}
              style={{
                overflow: 'auto',
              }}
            >
              <PolarisMap
                showImagery
                boundary={field.boundary}
                mapImage={polarisImage}
                mapExtents={polarisExtent}
              />
            </Box>

            <Box
              id="cropland-pdf"
              display="flex"
              borderRadius={4}
              height={540}
              width={540}
              style={{
                overflow: 'auto',
              }}
            >
              <CropLandMap
                showImagery
                boundary={field.boundary}
                mapImage={cropLandImage}
                mapExtents={cropLandExtent}
                height={500}
                width={500}
              />
            </Box>

            <Box
              id="ssurgo-pdf"
              display="flex"
              borderRadius={4}
              height={540}
              width={540}
              style={{
                overflow: 'auto',
              }}
            >
              <SoilDataMap
                showImagery
                boundary={field.boundary}
                mapGeo={soilGeo}
                mapExtents=""
              />
            </Box>

            <Box
              id="ndvi-pdf"
              display="flex"
              borderRadius={4}
              height={540}
              width={540}
              style={{
                overflow: 'auto',
              }}
            >
              <NdviMap
                showImagery
                boundary={field.boundary}
                mapImage={ndviImage}
                mapExtents={ndviExtent}
                height={500}
                width={500}
              />
            </Box>

            <Box
              id="topography-pdf"
              display="flex"
              borderRadius={4}
              height={540}
              width={540}
              style={{
                overflow: 'auto',
              }}
            >
              <TopographyMap
                showImagery
                boundary={field.boundary}
                mapImage={elevationImage}
                mapExtents={elevationExtent}
                height={500}
                width={500}
              />
            </Box>

            <Box
              id="sar-pdf"
              style={{
                height: 500,
                width: 500,
                overflow: 'auto',
                borderRadius: 2,
              }}
            >
              <SARMap
                boundary={field.boundary}
                mapImage={sarImage}
                mapExtents={sarExtent}
                showSar
                height={500}
                width={500}
              />
            </Box>

            <Box
              id="gdd-pdf"
              style={{
                height: 500,
                width: 500,
                overflow: 'auto',
              }}
            >
              <GddChart
                data={gddData}
                startYear={gddStart}
                endYear={gddEnd}
              />
            </Box>

            <Box
              id="precip-pdf"
              style={{
                height: 500,
                width: 500,
                overflow: 'auto',
              }}
            >
              <PrecipChart
                data={precipData}
                startYear={gddStart}
                endYear={gddEnd}
              />
            </Box>
          </Box>
        </Box>

        <Box
          fontSize={16}
          mb={1}
          ml={1}
        >
          <Box>
            Includes:
          </Box>

          <Box
            mb={1}
            border={1}
            borderRadius="borderRadius"
            borderColor={grey}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 42, width: 42 }}
              >
                { status.Polaris ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <Checkbox
                    color="primary"
                    checked={includePolaris}
                    disabled={!exists(polarisImage) || loading}
                    onChange={() => setIncludePolaris(!includePolaris)}
                    inputProps={{ 'aria-label': 'Polaris checkbox' }}
                  />
                )}
              </Box>

              <Box display="flex" alignItems="center">
                <Box>
                  Polaris, a recently developed soil data set that provides additional soil attributes
                </Box>
                <CustomToolTip
                  title="Check to include in PDF. Note you will be unable to select until data is loaded"
                  placement="right"
                >
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </CustomToolTip>
              </Box>
            </Box>
          </Box>

          <Box
            mb={1}
            border={1}
            borderRadius="borderRadius"
            borderColor={grey}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 42, width: 42 }}
              >
                { status.SSURGO ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <Checkbox
                    color="primary"
                    checked={includeSoilData}
                    disabled={!exists(soilGeo) || loading}
                    onChange={() => setIncludeSoilData(!includeSoilData)}
                    inputProps={{ 'aria-label': 'Soil Data checkbox' }}
                  />
                )}
              </Box>

              <Box display="flex" alignItems="center">
                <Box>
                  SSURGO Soil type data from USDA-NRCS
                </Box>
                <CustomToolTip
                  title="Check to include in PDF. Note you will be unable to select until data is loaded"
                  placement="right"
                >
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </CustomToolTip>
              </Box>
            </Box>
          </Box>

          { authenticated && (
            <Box
              mb={1}
              border={1}
              borderRadius="borderRadius"
              borderColor={grey}
            >
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 42, width: 42 }}
                >
                  { status.elevationIndexStatus ? (
                    <CircularProgress color="primary" size={16} />
                  ) : (
                    <Checkbox
                      color="primary"
                      checked={includeTopography}
                      disabled={!exists(elevationImage) || !authenticated || loading}
                      onChange={() => setIncludeTopography(!includeTopography)}
                      inputProps={{ 'aria-label': 'NDVI checkbox' }}
                    />
                  )}
                </Box>

                <Box display="flex" alignItems="center">
                  <Box>
                    Field topography and slope data
                  </Box>
                  <CustomToolTip
                    title={`Check to include in PDF. Note ${authenticated ? 'you will be unable to select until data is loaded' : 'you must be logged in for view this data.'} `}
                    placement="right"
                  >
                    <InfoOutlinedIcon className={classes.infoToolTip} />
                  </CustomToolTip>
                </Box>
              </Box>

              { elevationIndexSelection.length > 0 && (
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="topography-mutiple-chip"
                  multiple
                  value={selectedTopography}
                  onChange={handleTopographyChange}
                  input={<Input id="select-multiple-topography" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value, i) => (
                        <Chip key={i} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                  disabled={!includeTopography}
                  style={{ margin: '0px 0px 4px 4px' }}
                >
                  {elevationIndexSelection.map((x, i) => (
                    <MenuItem key={i} value={x.name}>
                      <Checkbox color="primary" checked={selectedTopography.includes(x.name)} />
                      <ListItemText primary={x.name} />
                    </MenuItem>
                  ))}
                </Select>
              )}

            </Box>
          )}

          { authenticated && (
            <Box
              mb={1}
              border={1}
              borderRadius="borderRadius"
              borderColor={grey}
            >
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 42, width: 42 }}
                >
                  { status.Naip ? (
                    <CircularProgress color="primary" size={16} />
                  ) : (
                    <Checkbox
                      color="primary"
                      checked={includeNaip}
                      disabled={!exists(naipData) || !authenticated || loading}
                      onChange={() => setIncludeNaip(!includeNaip)}
                      inputProps={{ 'aria-label': 'NAIP checkbox' }}
                    />
                  )}
                </Box>

                <Box display="flex" alignItems="center">
                  <Box>
                    Aerial Imagery
                  </Box>
                  <CustomToolTip
                    title={`Check to include in PDF. Note ${authenticated ? 'you will be unable to select until data is loaded' : 'you must be logged in for view this data.'} `}
                    placement="right"
                  >
                    <InfoOutlinedIcon className={classes.infoToolTip} />
                  </CustomToolTip>
                </Box>
              </Box>

              { naipYears.length > 0 && (
                <Select
                  labelId="demo-mutiple-chip-label"
                  id="topography-mutiple-chip"
                  multiple
                  value={selectedNaipYears.filter((x) => x !== '')}
                  onChange={handleNaipYearChange}
                  input={<Input id="select-multiple-topography" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value, i) => (
                        <Chip key={i} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                  disabled={!includeNaip}
                  style={{ margin: '0px 0px 4px 4px' }}
                >
                  {naipYears.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      <Checkbox color="primary" checked={selectedNaipYears.includes(x)} />
                      <ListItemText primary={x} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            </Box>
          )}

          <Box
            mb={1}
            border={1}
            borderRadius="borderRadius"
            borderColor={grey}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 42, width: 42 }}
              >
                { status.NDVI ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <Checkbox
                    color="primary"
                    checked={includeNdvi}
                    disabled={!exists(ndviImage) || loading}
                    onChange={() => setIncludeNdvi(!includeNdvi)}
                    inputProps={{ 'aria-label': 'NDVI checkbox' }}
                  />
                )}
              </Box>

              <Box display="flex" alignItems="center">
                <Box>
                  Satellite crop health data
                </Box>
                <CustomToolTip
                  title="Check to include in PDF. Note you will be unable to select until data is loaded"
                  placement="right"
                >
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </CustomToolTip>
              </Box>
            </Box>
          </Box>

          <Box
            mb={1}
            border={1}
            borderRadius="borderRadius"
            borderColor={grey}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 42, width: 42 }}
              >
                { status.CDL ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <Checkbox
                    color="primary"
                    checked={includeCropLand}
                    disabled={!exists(cropLandImage) || loading}
                    onChange={() => setIncludeCropLand(!includeCropLand)}
                    inputProps={{ 'aria-label': 'Crop History checkbox' }}
                  />
                )}
              </Box>

              <Box display="flex" alignItems="center">
                <Box>
                  Crop History, published by USDA, provide estimates of historical crops cover
                </Box>
                <CustomToolTip
                  title="Check to include in PDF. Note you will be unable to select until data is loaded"
                  placement="right"
                >
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </CustomToolTip>
              </Box>
            </Box>
          </Box>

          <Box
            mb={1}
            border={1}
            borderRadius="borderRadius"
            borderColor={grey}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 42, width: 42 }}
              >
                { status.GDD ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <Checkbox
                    color="primary"
                    checked={includeGdd}
                    disabled={!exists(gddData) || loading}
                    onChange={() => setIncludeGdd(!includeGdd)}
                    inputProps={{ 'aria-label': 'Growing Degree Days checkbox' }}
                  />
                )}
              </Box>

              <Box display="flex" alignItems="center">
                <Box>
                  Seasonal growing degree days
                </Box>
                <CustomToolTip
                  title="Check to include in PDF. Note you will be unable to select until data is loaded"
                  placement="right"
                >
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </CustomToolTip>
              </Box>
            </Box>
          </Box>

          <Box
            mb={1}
            border={1}
            borderRadius="borderRadius"
            borderColor={grey}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 42, width: 42 }}
              >
                { status.Precip ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <Checkbox
                    color="primary"
                    checked={includePrecip}
                    disabled={!exists(precipData) || loading}
                    onChange={() => setIncludePrecip(!includePrecip)}
                    inputProps={{ 'aria-label': 'Precipitation checkbox' }}
                  />
                )}
              </Box>

              <Box display="flex" alignItems="center">
                <Box>
                  Accumulated precipitation to date compared to historical norms
                </Box>
                <CustomToolTip
                  title="Check to include in PDF. Note you will be unable to select until data is loaded"
                  placement="right"
                >
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </CustomToolTip>
              </Box>
            </Box>
          </Box>

          <Box
            mb={1}
            border={1}
            borderRadius="borderRadius"
            borderColor={grey}
          >
            <Box display="flex" alignItems="center">
              <Box
                display="flex"
                justifyContent="center"
                alignItems="center"
                style={{ height: 42, width: 42 }}
              >
                { status.SAR ? (
                  <CircularProgress color="primary" size={16} />
                ) : (
                  <Checkbox
                    color="primary"
                    checked={includeSar}
                    disabled={!exists(sarImage) || loading}
                    onChange={() => setIncludeSar(!includeSar)}
                    inputProps={{ 'aria-label': 'Precipitation checkbox' }}
                  />
                )}
              </Box>

              <Box display="flex" alignItems="center">
                <Box>
                  Satellite Radar Imagery
                </Box>
                <CustomToolTip
                  title="Check to include in PDF. Note you will be unable to select until data is loaded"
                  placement="right"
                >
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </CustomToolTip>
              </Box>
            </Box>
          </Box>

          { authenticated && (
            <Box
              mb={1}
              border={1}
              borderRadius="borderRadius"
              borderColor={grey}
            >
              <Box display="flex" alignItems="center">
                <Box
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  style={{ height: 42, width: 42 }}
                >
                  { status.insuranceTool ? (
                    <CircularProgress color="primary" size={16} />
                  ) : (
                    <Checkbox
                      color="primary"
                      checked={includeInsurance}
                      disabled={loading}
                      onChange={() => setIncludeInsurance(!includeInsurance)}
                      inputProps={{ 'aria-label': 'Insurance Data checkbox' }}
                    />
                  )}
                </Box>

                <Box display="flex" alignItems="center">
                  <Box>
                    Insurance Tool Data
                  </Box>
                  <CustomToolTip
                    title={`Check to include in PDF. Note ${authenticated ? 'you will be unable to select until data is loaded' : 'you must be logged in for view this data.'} `}
                    placement="right"
                  >
                    <InfoOutlinedIcon className={classes.infoToolTip} />
                  </CustomToolTip>
                </Box>
              </Box>
            </Box>
          )}

        </Box>
      </Box>
    </Box>
  );
}

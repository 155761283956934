import React, {useEffect} from "react";
import { Box, Slide } from "@material-ui/core";
import { VendorsMap } from '../Maps/VendorsMap'
import { SelectFieldV2 } from '../FieldSelection/SelectFieldV2';

 // * @param {Number} nextStep Optionally move to next step after field select
export function SelectField({
  bodyHeight,
  mobileBreakPoint,
  isAuthenticated,
  selectField,
  selectedField,
  initialBounds,
  nextStep
}) {

  return (
    <Box
      display='flex'
      height={'100%'}
      style={{flexDirection: window.innerWidth < mobileBreakPoint ? 'column' : 'row'}}
    >
      { isAuthenticated && (
        <Slide
          direction='right'
          mountOnEnter
          unmountOnExit
          in={true}
        >
          <div>
            <SelectFieldV2
              containerHeight={bodyHeight}
              select={selectField}
              mobile={window.innerWidth < mobileBreakPoint}
            />
          </div>
        </Slide>
			)}

      <VendorsMap
        height={bodyHeight}
        selectField={selectField}
        selectedField={selectedField}
        initialBounds={initialBounds}
        nextStep={nextStep}
      />
    </Box>
  )
}

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import { produce } from "immer";
import {
  COLORS,
  DOLLAR_FORMAT,
  PERCENT_FORMAT,
  SINGLE_DECIMAL_FORMAT,
} from "../../../../constants";
import Pie from "./Pie";
import DownloadGreenIcon from "../../../../images/download_green.svg";
import MapIcon from "../../../../images/map.svg";
import GreenCheckbox from "../../../Helpers/GreenCheckbox";
import { FieldReportMap } from "../../../Maps/FieldReportMap";
import { ProfitMapResponse } from "../../../Helpers/Modals/ProfitMapResponse";

const useStyles = makeStyles((theme) => ({
  container: ({ mobileView }) => ({
    display: "flex",
    justifyContent: "space-between",
    color: COLORS.green,
    fontWeight: "normal",
    flexDirection: mobileView ? "column" : "row",
  }),
  titleContainer: {
    flex: `0 0 calc(100% - ${224 + theme.spacing(3)}px)`,
    display: "flex",
    flexDirection: "column",
    minWidth: 300,
    maxWidth: "60%"
  },
  title: ({ mobileView }) => ({
    lineHeight: 1.34,
    fontSize: mobileView ? 24 : 32,
  }),
  subTitleContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  subTitle: {
    fontSize: 24,
    lineHeight: "32px",
  },
  checkboxContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  include: {
    fontSize: 14,
    lineHeight: "19px",
    fontWeight: "bold",
  },
  detailsContainer: {
    width: "90%",
    borderRadius: "5px",
    backgroundColor: "#eefce8",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "space-between",
  },
  pieContainer: {
    flex: `0 1 ${250 - theme.spacing(6)}px`,
    display: "flex",
    alignItems: "center",
    paddingTop: "10px",
    borderTop: `2px solid ${COLORS.white}`,
    "& b": {
      fontSize: 22,
    },
  },
  pieText: {
    marginLeft: 0 //theme.spacing(1.5),
  },
  imageContainer: {
    flex: "0 0 224px",
    display: "flex",
    flexDirection: "column",
  },
  image: {
    width: "100%",
    height: 145, // 'auto'
  },
  profit: {
    color: COLORS.text,
    padding: theme.spacing(3),
  },
  imageButtons: ({ mobileView }) => ({
    display: "flex",
    justifyContent: mobileView ? "left" : "space-between",
  }),
  button: {
    fontSize: 12,
    fontWeight: "bold",
    color: COLORS.green,
  },
}));

/**
 * Renders the header container on field report with profit percentage, donut chart and profit layers map
 * @param {Object} activeFarm object with farm data
 * @param {Object} selectedField field data object
 * @param {String} activeYear the year
 * @param {Function} setFields set state function for setting selected field
 * @param {Boolean} mobileView  for mobile view
 * @param {String} mapImage pngb64 string of the fields profit map
 * @param {Array} mapExtents Array of coordinates for the image's extent
 * @param {String} boundary JSON Stringified geojson object of the fields boundary
 * @param {Array} legend Array containing legend info (this should match legends used for all other maps in the ProfitLayers app)
 * @param {Function} generateProfitLayer function to generate a profitmap for the field
 */
const TitleContainer = ({
  activeFarm,
  selectedField,
  activeYear,
  setFields,
  mobileView,
  mapImage,
  mapExtents,
  boundary,
  legend,
  generateProfitLayer
}) => {
  const classes = useStyles({ mobileView });
  const print = useRef(null);

  const [open, setOpen] = useState(false);

  const handlePrintMap = (e) => {
    e.preventDefault()
    let printButton = document.getElementById('leafletEasyPrint')
    let printHolder = document.getElementsByClassName('easyPrintHolder')[0]
    let options = printHolder.getElementsByTagName('li')
    printButton.dispatchEvent(new MouseEvent('mouseover', { 'view': window, 'bubbles': true, 'cancelable': true }));
    options[1].click()
  }

  return (
    <div className={classes.container}>
      <div className={classes.titleContainer}>
        <div className={classes.title}>
          {`${activeFarm.name} ${activeYear} Report`}
        </div>
        <div className={classes.subTitleContainer}>
          <div className={classes.subTitle}>{selectedField.name}</div>
          <div className={classes.checkboxContainer}>
            <GreenCheckbox
              checked={selectedField.active}
              onChange={() =>
                setFields(
                  produce((draft) => {
                    const idx = draft.findIndex(
                      (d) => d.name === selectedField.name
                    );
                    if (idx !== -1) draft[idx].active = !draft[idx].active;
                  })
                )
              }
            />
            <div className={classes.include}>Include in Farm Report?</div>
          </div>
        </div>
        <div className={classes.detailsContainer}>
          <div className={classes.profit}>
            <b>{`${SINGLE_DECIMAL_FORMAT(selectedField.acres)} acres `}</b>
            <span>
              resulted in profits of <b>{DOLLAR_FORMAT(selectedField.profit)}/Acre</b>
            </span>

            { mapImage !== "" && selectedField.unprofitablePercent !== null ? 
            <div className={classes.pieContainer}>
              <div>
                <Pie value={selectedField.unprofitablePercent} />
              </div>
              <div className={classes.pieText}>
                <b>{`${   selectedField.unprofitablePercent + "%"}`}</b> of this
                field is unprofitable.
              </div>
            </div> : 
            <div className={classes.pieContainer}>
              <div className={classes.pieText}>
                Generate a ProfitLayers map to view a percentage of field unprofitable.
                <Button variant="contained" onClick={generateProfitLayer} color="primary">Generate Profit Layers</Button>
              </div>
              <div>
                
              </div>              
            </div>
          }
          </div>          
          
        </div>
      </div>
      <div className={classes.imageContainer}>
        {/* <img
          className={classes.image}
          alt="a representation of the selected field's profits"
        /> */}
        <FieldReportMap
          mapImage={mapImage}
          mapExtents={mapExtents}
          boundary={boundary}
          print={print}
          width={400}
          id="title"
        />
        <div className={classes.imageButtons}>
          {/** Hidding button for now. Print map from here does not work */}
          {/* <Button
            className={classes.button}
            startIcon={<img src={DownloadGreenIcon} alt="Print map" />}
            onClick={(e) => handlePrintMap(e)}
          >
            Print Map
          </Button> */}
          <Button
            className={classes.button}
            startIcon={<img src={MapIcon} alt="Expand map" />}
            onClick={(e) => setOpen(true)}
          >
            Expand Map
          </Button>
        </div>
      </div>

      <div>
        {open && (
          <ProfitMapResponse
            open={open}
            setOpen={setOpen}
            mapImage={mapImage}
            mapExtents={mapExtents}
            boundary={boundary}
            print={print}
            legend={legend}
            selectedField={selectedField}
          />
        )}
      </div>
    </div>
  );
};

export default TitleContainer;

TitleContainer.propTypes = {
  activeFarm: PropTypes.object.isRequired,
  selectedField: PropTypes.object.isRequired,
  activeYear: PropTypes.string.isRequired,
  setFields: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
};

/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
/* eslint-disable valid-jsdoc */
import React, { useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Slide, Modal, Button, Fade,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import * as turf from '@turf/turf';
import { useSnackbar } from 'notistack';
import { active } from 'd3';
import { FieldProvider, FieldContext } from '../Context/FieldContext';
import { UserContext } from '../Context/UserContext';
import { SelectFieldV2 } from './SelectFieldV2';
import { FieldClaim } from './FieldClaim';
import { Map } from '../Maps/Map';
import { IntegrationLinks } from '../Partners/Integrate';
import { IntegrateModal } from '../Shared/IntegrateModal';
import { Connect } from '../Shared/Connect';
import { BeginClaim } from './BeginClaim';
import { useWindowDimensions } from '../../utils/dimensions';
import { UploadFile } from '../UploadShapeFile/UploadFile';
import { SaveBoundary } from './SaveBoundary';
import * as df from '../../utils/dataFetchers';
import { Endpoints } from '../../constants/Endpoints';
import { checkIsMobile } from '../../utils/helpers';
// import poweredBy from '../../images/Ag-Analytics_Full_Black_Powered.svg'
import poweredBy from '../../images/Ag-Analytics_Full_Black_Powered.svg'

const WKT = require('terraformer-wkt-parser');

function getModalStyle() {
  const top = 10;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    height: 'auto',
    width: '400px',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: theme.modalPaper,
  fieldSelection: {
    minHeight: '550px',
    overflowY: 'auto',
    display: 'flex',
    backgroundColor: theme.palette.greys.light,
  },
  mobileView: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: theme.palette.greys.light,
  },
  mobileSelect: {
    paddingBottom: '1rem',
    marginBottom: '1rem',
    maxHeight: '545px',
  },
  fullLogo: {
    width: '250px',
    height: 'auto',
    marginRight: '20px',
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginRight: '8px',
  },
}));

export function FieldSelection({
  mobileView,
  hideFieldSelection,
  showMap,
  changeSection,
  continueText,
  digitizing,
  claimPrompt,
  changePage,
  setDataLayersStep,
  underwriting,
  loanID,
  parcels,
  activeParcel,
  setActiveParcel,
  setReports,
  setDocuments,
  setIsLoading,
  fieldManager = false,
  isDataLayers = false,
}) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  const user = useContext(UserContext)[0];
  const [userHasNotIntegratedButHasField, setUserHasNotIntegratedButHasField] = useState(false);
  const [orgHasAFarm, setOrgHasAFarm] = useState(false);
  const [fieldData, setFieldData] = useContext(FieldContext);
  const [selectedOrg, setSelectedOrg] = useState({})

  // Open modal to ask to prompt user to continue to field claim or edit
  const [startClaim, setStartClaim] = useState(false);
  // Open modal to claim field
  const [claiming, setClaiming] = useState(false);
  const [loginPrompt, setLoginPrompt] = useState(false);
  const [openIntegration, setOpenIntegration] = useState(false);

  const [uploadedField, setUploadedField] = useState(null);

  // User wants to edit or add more parcels to field before claiming
  const [editing, setEditing] = useState(false);

  // Open modal to save boundary
  const [saveBoundary, setSaveBoundary] = useState(false);

  // Check to see if user has an account and has not integrated but has created a field in Default org, we then want to fill up the extra space the map is in
  useEffect(() => {
    if (user.organizations && 
      user.organizations.length === 1 && user.organizations[0].Name === 'Default' &&
      orgHasAFarm
    ) {
      // Could have different heights for when there is just a farm vs. a farm & field but that shouldn't happen
      setUserHasNotIntegratedButHasField(true);
    }
  }, [user, orgHasAFarm])

  // handles if field was set to claim
  // also handles if field was set to claim but then user was re-directed to log in
  useEffect(() => {
    if (
      fieldData.fieldToClaim
      && fieldData.fieldToClaim.claiming
      && user.isAuthenticated
    ) {
      setClaiming(true);
      // Remove claiming flag so refreshes dont continously bring up modal
      setFieldData({
        ...fieldData,
        fieldToClaim: { ...fieldData.fieldToClaim, claiming: false },
      });
    }
  }, [fieldData.fieldToClaim, user]);

  // useEffect(()=>{
  //   console.log("LoanID in FieldSelection", loanID)
  // }, [loanID])

  // For map to move parent to next section on user clicking a field from the map
  const handleFieldSelect = (field) => {
    setFieldData({
      ...fieldData,
      fieldToClaim: {
        feature: null,
        geometry: {},
        coordinates: [],
        points: [],
        boundary: {},
        acres: 0,
      },
      selectedField: field,
    });
    if (underwriting === undefined) {
      if (continueText === "Create ProfitLayers"){
        ReactGA.event({
          category: "Navigation",
          action: "Navigate to Setup Profitlayers",
          label: "Selected field on map"
        })
      }
      
      changeSection(1);
    }
  };

  const beginClaim = () => {
    if(isDataLayers || fieldManager){
      changeSection(1)
    }
    else{
      if (!user?.isAuthenticated){
        goToDataLayers()
      }
      else{
        setStartClaim(true);
      }
    }
  };

  const saveBoundaryModal = () => {
    setSaveBoundary(true);
  };

  const saveEditedBoundary = async () => {
    setSaveBoundary(false);
    const fieldCopy = fieldData.fieldToClaim;
    const wktShape = WKT.convert(fieldData.fieldToClaim.geometry);

    const request = {
      OrganizationID: fieldCopy.orgId,
      FieldID: fieldCopy.id,
      Area: turf.convertArea(
        turf.area(fieldData.fieldToClaim.features),
        'meters',
        'acres',
      ),
      Shape: wktShape,
      BoundaryID: fieldCopy.boundaryId,
    };
    // console.log("request", request)
    // console.log(fieldData.fieldToClaim)
    // console.log(fieldData.selectedField)
    try {
      let res;
      if (underwriting === undefined) {
        res = await df.updateBoundary(request);
      } else {
        request.Latitude = fieldData.fieldToClaim.latitude;
        request.Longitude = fieldData.fieldToClaim.longitude;
        request.OrganizationName = fieldCopy.org;
        res = await df.updateParcelBoundary(request, user.token);
      }
      setFieldData({
        ...fieldData,
        selectedField: {
          ...fieldData.selectedField,
          acres: fieldData.fieldToClaim.acres,
          centroid: fieldData.fieldToClaim.features,
          coordinates: fieldData.fieldToClaim.geometry,
          geojson: fieldData.fieldToClaim.features,
          points: fieldData.fieldToClaim.points[0],
          boundary: fieldData.fieldToClaim.boundary,
          boundaryId: underwriting ? res : fieldData.selectedField.boundaryId,
        },
        fieldToClaim: {
          feature: null,
          coordinates: [],
          points: [],
          acres: 0,
        },
      });
      // console.log("res", res)
      // make call to get fields again?
    } catch (err) {
      console.error(err);
    }
  };

  const handleStartFieldClaim = () => {
    // check if user is logged in, if not open log in modal, if so claim field
    if (user.isAuthenticated) {
      setClaiming(true);
    } else {
      setLoginPrompt(true);
      setFieldData({
        ...fieldData,
        fieldToClaim: { ...fieldData.fieldToClaim, claiming: true },
      });
    }
  };

  const goToDataLayers = () => {
    // set step to View Data Layers
    setDataLayersStep(1);
    // set section to Data Layers
    changePage(1);
  };

  const onConnectClick = () => {
    if (checkIsMobile()) {
      // Open integration page on mobile
      window.open(`${Endpoints.HOME}/app/integrate`, '_blank');
    } else {
      // Open modal
      setOpenIntegration(true);
    }
  };

  /**
   * Properly handle a press to the continue button from Field Selection screen. Behavior is different for Data Layers.
   * Decides whether user should be asked to sign in, select a field, or not.
   */
  const handleContinueClick = () => {
    // If from profitLayers, ask them to sign in first (we don't want them selecting a field first)
    if (continueText === 'Create ProfitLayers' && !user.isAuthenticated) {
      setLoginPrompt(true);
    }
    if(continueText === 'Create ProfitLayers'){
      ReactGA.event({
        action: "Navigate",
        category: "Navigate to Setup Profitlayers",
        label: "Field Selection Create ProfitLayers Button"
      })
    }

    // There at least needs to be a field selected
    if (fieldManager && fieldData.selectedField.farmId !== '') {
      changeSection(1);
    } else {
      if (
        fieldData.selectedField.id === ''
        && fieldData.fieldToClaim?.feature === null
        && underwriting === undefined
      ) {
        enqueueSnackbar('Please first claim or select a field on the map.');
        return;
      }

      // If a field was chosen, continue if user is authenticated or they are trying to view Data Layers
      if (user.isAuthenticated || continueText === 'View Data Layers') {
        // Only continue if field has been claimed already or they are trying to view Data Layers
        if (
          fieldData.fieldToClaim?.feature === null
          || continueText === 'View Data Layers'
        ) {
          changeSection(1);
        } else {
          // User has not claimed field but is trying to go to ProfitLayers, AcreageReporting, etc. (not allowed)
          setClaiming(true);
        }
      } else {
        setLoginPrompt(true);
      }
    }
  };

  const fieldSelectFooter = () => (
    <Box
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ height: '60px', width: '100%' }}
    >
      <Box display="flex">
        {/* {width > 760 &&
          <Box>
            <img
              src={poweredBy}
              className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
              alt={"Powered By Ag-Analytics"}
            />
          </Box>
        } */}

        <UploadFile
          beginClaim={setStartClaim}
          handleBeginFieldClaim={handleStartFieldClaim}
          startClaim={handleStartFieldClaim}
          setEditing={setEditing}
          setUploadedField={setUploadedField}
          underwriting={underwriting}
          organization={selectedOrg}
        />

        {fieldData.fieldToClaim && fieldData.fieldToClaim.feature !== null && (
          <Button
            variant="contained"
            color="primary"
            style={{ height: '45px', marginLeft: '1rem', width: 120 }}
            onClick={() => handleStartFieldClaim()}
            disableElevation
          >
            Claim Field
          </Button>
        )}

        
      </Box>
      
      
      
      <Box display="flex" style={{alignItems: 'center'}}>
        {width > 760 &&
          <Box>
            <img
              src={poweredBy}
              className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
              alt={"Powered By Ag-Analytics"}
            />
          </Box>
        }
        <Button
          variant="contained"
          color="primary"
          style={{ height: '45px', marginLeft: '1rem' }}
          onClick={() => handleContinueClick()}
          disableElevation
        >
          {`${continueText} `}
          <ArrowForwardIcon />
        </Button>
      </Box>
      
    </Box>
  );

  return (
    <Box style={{ width: '100%', }}>
      {mobileView ? (
        !claiming ? (
          // 135 is for PL app bar (75), navbar (56) and random space at bottom (4)
          <Box syle={{ height: height - 135 }}>
           {/* 195 comes from 135 above and footer which is 60 */}
            <Box
              style={{
                height: height - 195,
                overflowY: 'auto',
              }}
            >
              <Box>
                <Slide
                  direction="right"
                  in={!hideFieldSelection}
                  timeout={300}
                  mountOnEnter
                  unmountOnExit
                >
                  <Box>
                    {user.isAuthenticated && user.organizations?.length > 0 ? (
                      <SelectFieldV2
                        containerHeight={500}
                        mobile
                        openConnect={onConnectClick}
                        setIsLoading={setIsLoading}
                        underwriting={underwriting}
                        setReports={setReports}
                        setDocuments={setDocuments}
                        setParentOrg={setSelectedOrg}
                      />
                    ) : (
                      <IntegrationLinks
                        mobileView
                        signedIn={user.isAuthenticated}
                        onConnect={onConnectClick}
                      />
                    )}
                  </Box>
                </Slide>

                <Fade in={showMap} unmountOnExit mountOnEnter>
                  <Box
                    style={{
                      height: '540px',
                      width: '100%',
                    }}
                  >
                    {/* 195 comes from 135 above and footer which is 60 */}
                    <Map
                      containerHeight={height > 745 ? height - 195 : 550}
                      handleFieldSelect={handleFieldSelect}
                      beginClaim={beginClaim}
                      uploadedField={uploadedField}
                      editingClaim={editing}
                      saveBoundaryModal={saveBoundaryModal}
                      digitizing={digitizing}
                      parcels={parcels}
                      activeParcel={activeParcel}
                      setActiveParcel={setActiveParcel}
                      underwriting={underwriting}
                    />
                  </Box>
                </Fade>
              </Box>
            </Box>
            {fieldSelectFooter()}
          </Box>
        ) : (
          <Box style={{ height: '100%' }}>
            <FieldClaim
              setSection={changeSection}
              setOpen={setClaiming}
              loanID={loanID}
              underwriting={underwriting}
            />
          </Box>
        )
      ) : (
        <>
          <Box className={classes.fieldSelection}>
            {/* Field selection or integration links */}
            <Box
              style={{
                height: '100%',
                minHeight: '550px',
                width: '300px',
              }}
            >
              {user.isAuthenticated
              && user.organizations !== undefined
              && user.organizations.length > 0 ? (
                <SelectFieldV2
                  containerHeight={height - 240}
                  openConnect={onConnectClick}
                  setIsLoading={setIsLoading}
                  underwriting={underwriting}
                  setReports={setReports}
                  setDocuments={setDocuments}
                  setOrgHasAFarm={setOrgHasAFarm}
                  setParentOrg={setSelectedOrg}
                />
              ) : (
                <IntegrationLinks
                  mobileView={false}
                  signedIn={user.isAuthenticated}
                  onConnect={onConnectClick}
                />
              )}
            </Box>

            {/* Map with all functions needed to select and create field */}
            <Fade in={showMap} unmountOnExit mountOnEnter>
              <Box
                style={{
                  height: '100%',
                  minHeight: '550px',
                  width: '100%',
                }}
              >
                {/* 195 comes from 135 above and footer which is 60 */}
                <Map
                  containerHeight={
                    (userHasNotIntegratedButHasField) ? 917
                    : height > 745 ? height - 195 : 550
                  }
                  handleFieldSelect={handleFieldSelect}
                  beginClaim={beginClaim}
                  uploadedField={uploadedField}
                  editingClaim={editing}
                  saveBoundaryModal={saveBoundaryModal}
                  digitizing={digitizing}
                  parcels={parcels}
                  activeParcel={activeParcel}
                  setActiveParcel={setActiveParcel}
                  underwriting={underwriting}
                  userHasNotIntegratedButHasField={userHasNotIntegratedButHasField}
                />
              </Box>
            </Fade>
          </Box>

          {fieldSelectFooter()}

          {/* Field Claim in modal */}
          <Modal
            open={claiming}
            onClose={() => setClaiming(false)}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Box
              style={modalStyle}
              className={classes.paper}
              borderRadius="borderRadius"
              boxShadow={2}
            >
              <FieldClaim
                setSection={changeSection}
                setOpen={setClaiming}
                loanID={loanID}
                underwriting={underwriting}
              />
            </Box>
          </Modal>
        </>
      )}

      {/* Modals */}
      <BeginClaim
        open={startClaim}
        setOpen={setStartClaim}
        startClaim={handleStartFieldClaim}
        setEditing={setEditing}
        continueText={continueText}
        continueWithoutClaim={changeSection}
        goToDataLayers={goToDataLayers}
      />

      <Connect
        open={loginPrompt}
        setOpen={setLoginPrompt}
        message="Sign in to continue using the tool"
      />

      <IntegrateModal open={openIntegration} setOpen={setOpenIntegration} />

      <SaveBoundary
        open={saveBoundary}
        setOpen={setSaveBoundary}
        saveEditedBoundary={saveEditedBoundary}
      />
    </Box>
  );
}

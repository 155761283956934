import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';

/**
 * When using this, make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
 */ 
export function NumberFormatSeeds(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={5}
      thousandSeparator
    />
  );
}

/**
 * When using this, make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
 */ 
 export function NumberFormatFour(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={4}
      thousandSeparator
    />
  );
}

/**
 * When using this, make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
 */ 
export function NumberFormatThree(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={3}
      thousandSeparator
    />
  );
}

/**
 * When using this, make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
 */ 
 export function NumberFormatCustom(props) {
  const { inputRef, onChange, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={2}
      thousandSeparator
    />
  );
}

/**
 * When using this, make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
 */ 
 export function NumberFormatRMAArea(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      thousandSeparator
      decimalScale={2}
      allowNegative={false}
    />
  );
}

/**
 * When using this, make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
 */ 
export function NumberFormatOne(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={1}
      thousandSeparator
    />
  );
}

/**
 * When using this, make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
 */ 
export function NumberFormatZero(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={0}
      thousandSeparator
    />
  );
}

/**
 * When using this, make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
 */ 
 export function NumberFormatNumber(props) {
  const { inputRef, onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            value: values.value,
          },
        });
      }}
      decimalScale={0}
      allowNegative={false}
    />
  );
}


NumberFormatSeeds.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberFormatFour.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberFormatThree.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberFormatCustom.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberFormatRMAArea.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberFormatOne.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};

NumberFormatZero.propTypes = {
  inputRef: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
};
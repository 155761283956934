import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { ascending, max, min, extent, sum } from "d3-array";
import ProfitabilityMatrixContainer from "./ProfitabilityMatrixContainer/ProfitabilityMatrixContainer";
import { COLORS, COLOR_RANGE } from "../../../../constants";
import FarmBreakdown from "./FarmBreakdown/FarmBreakdown";
import FarmOverviewTableContainer from "./FarmOverviewTableContainer/FarmOverviewTableContainer";
import ReportDialog from "../ReportDialog/ReportDialog";
import { FarmReport } from "../../../FarmReportHelper/FarmReport";
import ProfitByVarietyMatrix from "./ProfitByVarietyMatrixContainer/VarietyMatrixContainer"
import { kebabCase } from "lodash";
import { scaleQuantize } from "d3-scale";
import { subMilliseconds } from "date-fns/fp";

const useStyles = makeStyles(() => ({
  wrapper: ({ mobileView }) => ({
    width: "100%",
    display: mobileView ? "block" : "flex",
    flexWrap: "wrap",
    borderBottom: `2px solid ${COLORS.lightGray}`,
    marginTop: 21,
    paddingBottom: 10
  }),
}));

/**
 * Renders the farm overview
 * @param {object} activeFarm object with farm data
 * @param {string} activeYear the year
 * @param {array} fields  array of objects with field data
 * @param {function} setFields set state function
 * @param {object} tableControls handles controls (buttons) in header for displaying table
 * @param {function} setTableControls  set state function
 * @param {boolean} mobileView for mobile view
 * @param {Int} ORG_ID id for currently selected organization
 * @param {array} dieselPrices list of object containing info about diesel prices
 * @param {Map} seedMap Map of fieldIDs to seeding operation object
 * @param {Map} harMap Map of fieldIDs to harvest operation object
 * @param {Map} appMap Map of fieldIDs to application operation object
 * @param {Map} fpMap Map of fieldIDs to field pass operation object
 * @param {Map} otherCostMap Map of fieldIDs to other cost operation object
 * @param {Map} otherRevMap Map of fieldIDs to other revenue operation object
 * @param {object} fieldHeaderCells keyed object for the categories for
 * the field report. Being passed down into pdf report.
 * @param {array} data  array of objects with data to be passed down into pdf report
 * @param {Object} selectedOrg object containing info about current organization
 * @param {boolean} areAllActive needed to pass down the state of the select all checkbox
 * @param {function} setAreAllActive  needed to pass down the ability to change
 * the state of the select all checkbox
 * @param {String} message message to display while data is loading
 * @param {Function} setMessage function to update loading display message
 * @param {Boolean} access boolean to determine if user has a subscription for ProfitLayers
 * @param {Function} setAccess function to update access bool value
 * @param {Boolean} processing bool to indicate that data is loading, passed down to Overview component
 * @param {Function} setProcessing function to update processing bool, passed down to Overview component
 * @param {Function} checkAccess function to check if user has access to tool
 * @param {Array} selectedColumns array of objects containing info about currently displayed columns
 * @param {Function} setSelectedColumns function to update selectedColumns array
 * @param {Boolean} reload bool used in top level FarmReport component, if true reloads profit summary values
 * @param {Function} setReload passed to FarmReportHelper FarmReport component, sets the reload state variable
 * @param {Object} state object containing data and metadata for profit map summaries, used by matrix tables
 * @param {Function} setState function to update state value, not used outside of top level FarmReport
 * @param {Boolean} isLoaded bool to indicate if profit summaries have loaded in
 * @param {Function} setIsLoaded function to update isLoaded value
 * @param {Boolean} noData bool to indicate that there is no data for the farm, passed down to the Overview component
 * @param {Function} setNoData function to update the noData bool value
 * @param {Array} originalFields array of objects containing field data
 * @param {Boolean} initialLoad bool to indicate if currently doing initial load
 * @param {Function} setInitialLoad function to update initialLoad value
 * @param {Array} fieldIds array of field ids
 * @param {Function} setFieldsIds function to update fieldIds array
 * @param {Object} metrics object containing total values for farm breakdown, profit & acres
 * @param {Function} setMetrics function to update metics value
 * @param {Object} fbstate object containing info about farm breakdown items, cropTypes, cropVarieties, & chemicalInputs
 * @param {Function} setFBState function to update fbstate value
 * @param {Boolean} cropInitialLoad boolean currently un-used
 * @param {Function} setCropInitialLoad function to update cropInitialLoad value
 * @param {Boolean} varietyInitialLoad boolean currently un-used
 * @param {Function} setVarietyInitialLoad function to update varietyInitialLoad
 * @param {Boolean} chemInitialLoad boolean currently un-used
 * @param {Function} setChemInitialLoad function to update chemInitialLoad
 * @param {Boolean} show boolean currently un-used
 * @param {Function} setShow function to update show
 * @param {Object} profitByVariety object containing information about profit for varieties (see profitByVariety state variable in FarmReport component)
 * @param {Object} tableControlsVariety object containing avaialable controls for variety overview table
 * @param {Function} setTableControlsVariety function to update tableControlsVariety (set heatmap to true)
 * @param {Array} fieldBoundaries array of field boundary objects
 * @param {Boolean} showMap boolean to indicate if farm report overview should show the map view
 * @param {Function} setShowMap function to update showMap view boolean
 */

const Overview = ({
  activeFarm,
  fields,
  setFields,
  activeYear,
  tableControls,
  setTableControls,
  mobileView,
  ORG_ID,
  dieselPrices,
  seedMap,
  harMap,
  appMap,
  fpMap,
  otherCostMap,
  otherRevMap,
  fieldHeaderCells,
  data,
  selectedOrg,
  areAllActive,
  setAreAllActive,
  message,
  setMessage,
  access,
  setAccess,
  processing,
  setProcessing,
  checkAccess,
  selectedColumns,
  setSelectedColumns,
  reload,
  setReload,
  state,
  setState,
  isLoaded,
  setIsLoaded,
  noData,
  setNoData,
  originalFields,
  initialLoad,
  setInitialLoad,
  fieldIds,
  setFieldsIds,
  metrics,
  setMetrics,
  fbstate,
  setFBState,
  cropInitialLoad,
  setCropInitialLoad,
  varietyInitialLoad,
  setVarietyInitialLoad,
  chemInitialLoad,
  setChemInitialLoad,
  show,
  setShow,
  profitByVariety,
  tableControlsVariety,
  setTableControlsVariety,
  fieldBoundaries,
  showMap,
  setShowMap,
}) => {
  const classes = useStyles({ mobileView });

  const [options, setOptions] = useState([
    {
      label: "Total Expenses",
      helper: "",
      accessor: (d) => d.expenses,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Total Revenue",
      helper: "",
      accessor: (d) => d.revenue,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Profits",
      helper: "",
      accessor: (d) => d.profit,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Primary Crop",
      helper: "",
      accessor: (d) => d.primaryCrop,
      range: [],
      filteredRange: [],
      values: [],
      numeric: false,
    },
    {
      label: "Profitable (%)",
      helper: "",
      accessor: (d) => d.percentProfitable,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Unprofitable (%)",
      helper: "",
      accessor: (d) => d.unprofitablePercent,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Harvest Revenue",
      helper: "",
      accessor: (d) => d.harvest,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Seeding Expenses",
      helper: "",
      accessor: (d) => d.seeding,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Chemicals Expenses",
      helper: "",
      accessor: (d) => d.chemicals,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Field Passes",
      helper: "",
      accessor: (d) => d.fieldPasses,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Other Costs",
      helper: "",
      accessor: (d) => d.otherCosts,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
    {
      label: "Other Revenues",
      helper: "",
      accessor: (d) => d.otherRevenues,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
    },
  ]);

  // Profit
  const [varietyProfitData, setVarietyProfitData] = useState([]);
  const [showDetails, setShowDetails] = useState(false);
  const [showVarietyAcreage, setShowVarietyAcreage] = useState(false)
  const [varieties, setVarieties] = useState([])
  const [varietyAcresData, setVarietyAcresData] = useState([]);
  const [minMaxProfit, setMinMaxProfit] = useState([]);
  const [varietySummary, setVarietySummary] = useState([])

  const [varietyOptions, setVarietyOptions] = useState([
    {
      label: "Crop Name",
      id: "crop",
      accessor: (d) => d.cropName,
      range: [],
      filteredRange: [],
      values: [],
      numeric: false,
      aggregateFunction: null,
    },
    {
      label: "Variety Name",
      id: "variety",
      accessor: (d) => d.varietyName,
      range: [],
      filteredRange: [],
      values: [],
      numeric: false,
      aggregateFunction: null,
    },
    {
      label: "Variety Acreage",
      id: "acres",
      accessor: (d) => d.varietyAcres,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
      aggregateFunction: sum,
    },
    {
      label: "Average Profit",
      id: "average-profit",
      accessor: (d) => d.profit,
      range: [],
      filteredRange: [],
      values: [],
      numeric: true,
      aggregateFunction: sum,
    },
  ])

  // if there are no previously selected columns,
  // create object with labels and an active state that starts out as
  // true for the first four options and false for the rest.
  // on subsequent  uses, use previous selected columns
  let columnSelects;
  if (!selectedColumns.length) {
    columnSelects = Array.from({ length: 4 }, () =>
      options.map((option) => ({ id: option.label }))
    );
    columnSelects.map((item) =>
      item.push({ id: "None selected", active: false, disabled: false })
    );
  }

  const initialSelects = selectedColumns.length
    ? selectedColumns
    : columnSelects.map((item, i) =>
      item.map((el, j) => {
        if (i === j) return { ...el, active: true, disabled: false };
        if (el.id === "None selected") return el;
        if (j < 4) return { ...el, active: false, disabled: true };
        return { ...el, active: false, disabled: false };
      })
  );

  /**
   * On Farm change, update the option values
   */
  useEffect(() => {
    setOptions(
      options.map((option) => {
        const { numeric, accessor } = option;
        let values = fields.map(accessor);
        if (option.label === "Primary Crop") {
          //values = values.filter(v => v !== "")
        }
        const range = numeric
          ? [Math.min(0, min(values)), max(values)]
          : Array.from(new Set(values)).sort(ascending);
        return {
          ...option,
          range,
          filteredRange: [...range],
          values,
        };
      })
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeFarm]);

  /**
   * Set displayed columns with the selected columns
   * initially set based on selectedColumns
   */
  useEffect(() => {
    setSelectedColumns(initialSelects);
  }, [initialSelects]);


  /**
   * Update variety profit and acre values when the list of fields passed in is changed
   */
  useEffect(() => {
    let varietyData = [], varData1 = []
    // console.log("Object.keys(values.profitByVariety)", Object.keys(values.profitByVariety))
    let varieties = fields.map(x => x.varietyNames).flat(2)
    //console.log("varieties", varieties)

    let varietyNames = profitByVariety.profitByVarietyWithField.length > 0 ? profitByVariety.profitByVarietyWithField.map(x => x.varietyName) : []
    varietyNames = [...new Set(varietyNames)]
    setVarieties(varietyNames)

    for (let v of varietyNames) {

      //CropInfo is being used to get acres based on variety selected
      let cropInfo = varieties.filter(x => x.name === v);
      let totalVarietyAcres = cropInfo.length > 0 ? cropInfo.reduce(
        (accumulator, currentValue) => accumulator + currentValue.varietyAcres, 0,
      ) : 0;

      //varietyProfitInfo is used to get profit info of variety
      let varietyProfitInfo = profitByVariety.profitByVarietyWithField.filter(x => x.varietyName == v)

      //Getting the Field IDs for which profitmap is generated that can be used calulate wieghted profit
      let fieldIds = varietyProfitInfo.length > 0 ? varietyProfitInfo.map(x => x.fieldID) : []
      fieldIds = [...new Set(fieldIds)]

      if (cropInfo.length > 0) {
        let totalProfit = 0, totalAcres = 0
        for (let id of fieldIds) {
          let acre = cropInfo.filter(x => x.fieldID == id)
          let profit = varietyProfitInfo.filter(x => x.fieldID == id)

          if (acre.length > 0 && profit.length > 0) {
            totalAcres += acre[0].varietyAcres;
            totalProfit += profit[0].profitVariety * acre[0].varietyAcres;
          }
        }

        varietyData.push({
          cropName: cropInfo[0].cropName,
          varietyName: v,
          profit: totalProfit / totalAcres,
          varietyAcres: totalAcres
        })
      }
    }

    let fieldsClone = [...fields];
    let fieldVariety = [];
    let minMax = [];

    for(let i=0; i < fieldsClone.length; i++){      
      let profits = profitByVariety.profitByVarietyWithField.filter(x => x.fieldID === fieldsClone[i].id)
      for(let profit of profits){
        let varietiesList = fields.filter(x => x.id === profit.fieldID)[0].varietyNames
        let info = varietiesList.filter(x => x.name === profit.varietyName)
        
        if(info.length > 0){
          minMax.push(profit.profitVariety.toFixed(2))
          fieldVariety.push({
            varietyName: info[0].cropName + " - " + profit.varietyName,
            varietyAcres: info[0].varietyAcres,
            cropName: info[0].cropName,
            profit: profit.profitVariety.toFixed(2),
            fieldId: fieldsClone[i].id,
            fieldName: fieldsClone[i].name,
            variety: profit.varietyName,
          })  
        }        
      }   
      fieldsClone[i].varietyFieldInfo = fieldVariety     
    }

    setVarietyProfitData(varietyData) // used in ProfitByVarietyMatrixTable
    setVarietyAcresData(fieldVariety) // used in VarietyAcresMatrixTable
    setMinMaxProfit([Math.min(...minMax), Math.max(...minMax)])
  }, [fields, profitByVariety])

  useEffect(() => {
    setVarietyOptions(
      varietyOptions.map((option) => {
        const { numeric, accessor } = option;
        //console.log("option", option)
        let values = varietyProfitData.map(accessor);
        //console.log("values", values)
        if (option.label === "Crop Name" || option.label === "Variety Name") {
          //values = values.filter(v => v !== "")
        }
        const range = numeric
          ? [Math.min(0, min(values)), max(values)]
          : Array.from(new Set(values)).sort(ascending);
        return {
          ...option,
          range,
          filteredRange: [...range],
          values,
        };
      })
    );

  }, [varietyProfitData]);

  return (
    <div>
      <div className={classes.wrapper}>
        <FarmReport //CAUTION, this is NOT the FarmReport component in the FarmReport folder, component from FarmReportHelpers
          dieselPrices={dieselPrices}
          year={activeYear}
          seedMap={seedMap}
          harMap={harMap}
          appMap={appMap}
          fpMap={fpMap}
          otherCostMap={otherCostMap}
          otherRevMap={otherRevMap}
          orgId={ORG_ID}
          farmId={activeFarm.id}
          fields={fields}
          setReload={setReload}
          access={access}
          setAccess={setAccess}
          processing={processing}
          setProcessing={setProcessing}
          checkAccess={checkAccess}
          initialLoad={initialLoad}
          setInitialLoad={setInitialLoad}
          fieldIds={fieldIds}
          setFieldsIds={setFieldsIds}
        />
      </div>
      <div className={classes.wrapper}>
        <ProfitByVarietyMatrix
          ORG_ID={ORG_ID}
          activeFarm={activeFarm}
          message={message}
          setMessage={setMessage}
          state={varietyProfitData}
          setState={setVarietyProfitData}
          isLoaded={isLoaded}
          setIsLoaded={setIsLoaded}
          fields={fields}
          tableControlsVariety={tableControlsVariety}
          setTableControlsVariety={setTableControlsVariety}
          varietyOptions={varietyOptions}
          setVarietyOptions={setVarietyOptions}
          showVarietyAcreage={showVarietyAcreage}
          setShowVarietyAcreage={setShowVarietyAcreage}
          varieties={varieties}
          setVarieties={setVarieties}
          varietyAcresData={varietyAcresData}
          setVarietyAcresData={setVarietyAcresData}
          minMaxProfit={minMaxProfit}
          showDetails={showDetails}
          setShowDetails={setShowDetails}
        />
      </div>
      <div className={classes.wrapper}>
        {/* <FarmBreakdown
          activeFarm={activeFarm}
          activeYear={activeYear}
          ORG_ID={ORG_ID}
          message={message}
          setMessage={setMessage}
          metrics={metrics}
          setMetrics={setMetrics}
          state={fbstate}
          setState={setFBState}
          cropInitialLoad={cropInitialLoad}
          setCropInitialLoad={setCropInitialLoad}
          varietyInitialLoad={varietyInitialLoad}
          setVarietyInitialLoad={setVarietyInitialLoad}
          chemInitialLoad={chemInitialLoad}
          setChemInitialLoad={setChemInitialLoad}
          show={show}
          setShow={setShow}
        /> */}
        <ProfitabilityMatrixContainer
          ORG_ID={ORG_ID}
          activeFarm={activeFarm}
          reload={reload}
          setReload={setReload}
          message={message}
          setMessage={setMessage}
          state={state}
          setState={setState}
          isLoaded={isLoaded}
          setIsLoaded={setIsLoaded}
        />
      </div>
      <FarmOverviewTableContainer
        activeFarm={activeFarm}
        activeYear={activeYear}
        fields={fields}
        setFields={setFields}
        options={options}
        setOptions={setOptions}
        tableControls={tableControls}
        setTableControls={setTableControls}
        selectedColumns={selectedColumns}
        mobileView={mobileView}
        fieldHeaderCells={fieldHeaderCells}
        data={data}
        selectedOrg={selectedOrg}
        seedMap={seedMap}
        harMap={harMap}
        appMap={appMap}
        fpMap={fpMap}
        otherCostMap={otherCostMap}
        otherRevMap={otherRevMap}
        areAllActive={areAllActive}
        setAreAllActive={setAreAllActive}
        noData={noData}
        setNoData={setNoData}
        originalFields={originalFields}
        showMap={showMap}
        setShowMap={setShowMap}
        fieldBoundaries={fieldBoundaries}
      />
      <ReportDialog
        open={tableControls.showAdvancedControls}
        setTableControls={setTableControls}
        fields={fields}
        setFields={setFields}
        options={options}
        setOptions={setOptions}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
      />
      {/** Rendering Report Dialog without the 'selectedColumns' prop will render it for varieti */}
      <ReportDialog
        open={tableControlsVariety.showAdvancedControls}
        setTableControls={setTableControlsVariety}
        fields={varietyProfitData}
        setFields={setVarietyProfitData}
        options={varietyOptions}
        setOptions={setVarietyOptions}
        selectedColumns={selectedColumns}
        setSelectedColumns={setSelectedColumns}
        variety={true}
      />
    </div>
  );
};

export default Overview;

Overview.propTypes = {
  activeFarm: PropTypes.object.isRequired,
  fields: PropTypes.array.isRequired,
  setFields: PropTypes.func.isRequired,
  activeYear: PropTypes.string.isRequired,
  tableControls: PropTypes.object.isRequired,
  setTableControls: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
  fieldHeaderCells: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  areAllActive: PropTypes.bool.isRequired,
  setAreAllActive: PropTypes.func.isRequired,
};

import React, { useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider } from "@material-ui/core";
import { numFormat, dollarFormat } from '../../../../utils/helpers';
import { green } from '../../../../styles/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    overflow: 'auto',
  },
  table: {
    marginTop: 4,
    fontWeight: 500,
    minWidth: 370,
    color: theme.palette.text.black,
  },
  labels: {
    display: "flex",
    padding: "4px 8px",
    alignItems: "center",
    fontWeight: 500,
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  tableRow: {
    display: "flex",
    flexGrow: 1,
    padding: "8px",
    fontWeight: 500,
    color: theme.palette.text.black,
  },
}));

export function MobileProfitSummary({
  legend,
  profitMapResponse,
  perAcre,
  netSeededAcres,
}) {
  const classes = useStyles();

  const createSummaryRow = (sum, i) => {
    //const varietyName = sum.variety.toUpperCase() === "NOVARIETY" ? "TOTAL PROFIT" : sum.variety
    let acres = 0
    let varietyName = ''
    let varietyColor = profitMapResponse.filter(
      x => x.variety.toUpperCase() === "VARIETY")[0]

    if (sum.variety.toUpperCase() === "NOVARIETY") {
      varietyName = "TOTAL PROFIT"
      acres = netSeededAcres;
    } else {
      varietyName = sum.variety
      for ( let i=0; i<varietyColor.legend.length;i++ ){
        if(varietyColor.legend[i].variety.replace('Variety', 'Zone').replace('.0', '') === sum.variety.replace('Variety', 'Zone').replace('.0', '')){
          acres = varietyColor.legend[i].areaPercent * netSeededAcres / 100
          break;
        }
      }
    }

    return(
      <Box key={i}>
        { varietyName === 'TOTAL PROFIT' && <Divider/> }

        <Box className={classes.tableRow}>
          <Box
            px={1}
            style={{ width: "33%" }}
          >
            { varietyName }
          </Box>

          <Box
            px={1}
            style={{ width: "37%", textAlign: 'center' }}
          >
            {perAcre? dollarFormat(sum.mean) : dollarFormat(sum.mean * acres)}
            {perAcre ? '/ac' : ''}
          </Box>
          <Box
            px={1}
            style={{ width: "30%", textAlign: 'center' }}
          >
            {numFormat(acres)}
            {' acres'}
          </Box>
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box
        className={classes.table}
        border={1}
        borderColor={green}
        borderRadius='borderRadius'
      >
        <Box className={classes.labels}>
          <Box px={1} style={{ width: "30%", textAlign: 'center' }}>
          </Box>
          <Box
            px={1}
            style={{ width: "40%", textAlign: 'center', whiteSpace: 'nowrap'}}>
            {perAcre ? ' Average Profit ($/Acre)' : 'Total Profit ($)'}
          </Box>
          <Box px={1} style={{ width: "30%" , textAlign: 'center' }}>
            Acres
          </Box>
        </Box>

        {
          profitMapResponse.length > 0 &&  (
            profitMapResponse.map((
              sum, i) => sum.variety.toUpperCase() !== "VARIETY" &&
              sum.variety.toUpperCase() !== "NOVARIETY" &&
              createSummaryRow(sum, i))
          )
        }

        {
          profitMapResponse.length > 0 && (
            profitMapResponse.map((
              sum, i) => sum.variety.toUpperCase() === "NOVARIETY" &&
              createSummaryRow(sum, i))
          )
        }

      </Box>
    </Box>
  )
}

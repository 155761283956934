export const canPlaceOrder = (cart, formData, termsAccepted) => {
  if (
    cart.length > 0
    && formData.email !== ''
    && formData.validEmail
    && formData.phone !== ''
    && formData.validPhone
    && formData.address !== ''
    && formData.zipCode !== ''
    && termsAccepted
  ) {
    return true;
  }
  return false;
};

export const incompleteOrderMessage = (
  cart,
  formData,
  termsAccepted,
) => {

  let message = 'To complete your order, please'
  let incompletes = []
  let alert = false

  if (cart.length === 0) {
    incompletes.push('select at least one package')
  }

  if (!termsAccepted) {
    incompletes.push('read and accept the terms of use')
    // alert turns ToC box border red
    alert = true
  }

  if (formData.email === '' || !formData.validEmail) {
    incompletes.push('provide a valid email address')
  }

  if (formData.phone === '' || !formData.validPhone) {
    incompletes.push('provide a valid phone number')
  }

  if (formData.address === '' || formData.zipCode === '') {
    incompletes.push('provide a complete address')
  }

  if (incompletes.length === 1) {
    message += ` ${incompletes[0]}.`
  } else {
    // multiple incompletes
    incompletes.forEach((item, i) => {
      if (i === incompletes.length - 1) {
        // last one
        message += ` and ${item}.`
      } else {
        message += ` ${item},`
      }
    });
  }
  return { message, alert }
}

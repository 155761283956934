import React, { useEffect, useState } from 'react';
import {
  Typography,
  Box,
  Slider,
  CircularProgress,
  Tooltip,
  Switch,
  FormControlLabel,
  Button,
  Link,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from '@material-ui/core';
import UpdateIcon from '@material-ui/icons/Update';
import RefreshIcon from '@material-ui/icons/Refresh';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { useWindowDimensions } from '../../../../utils/dimensions';
import { numFormat, dollarFormat, capitalizeFirstLetter } from '../../../../utils/helpers';
import * as con from '../../utils/conversions';
import { MapSlider, ElevationSlider } from './Sliders/MapSlider';
import { green } from '../../../../styles/colors';
import { tooltips } from '../../../DataLayers/Topography/info';

const useStyles = makeStyles((theme) => ({
  slider: {
    fontWeight: 500,
    color: theme.palette.greys.darkGrey,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '10px',
  },
  footer: {
    width: '95%',
    height: '33px',
    backgroundColor: theme.palette.greys.light,
    color: theme.palette.text.grey,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    marginTop: '15px',
  },
  settings: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  refresh: theme.greenIcon,
  labels: {
    display: 'flex',
    padding: '4px 8px',
    alignItems: 'center',
    fontWeight: 500,
    color: 'white',
    backgroundColor: theme.palette.primary.main,
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
    flexGrow: 1,
    padding: '8px',
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoIcon: {
    fontSize: 20,
    color: theme.palette.primary.main,
  },
  update: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export function RangeSlider(props) {
  const classes = useStyles();
  const [legend, setLegend] = useState([]);
  const [ranges, setRanges] = useState([]);
  const [displayButton, setDisplayButton] = useState(false);
  const [average, setAverage] = useState(0);
  const { height, width } = useWindowDimensions();
  // Toggle for slider vs table
  const [varietySummary, setVarietySummary] = useState(false);
  const [open, setOpen] = useState(false);
  const [perAcreExt, setPerAcreExt] = useState('/Acre')
  const [price, setPrice] = useState(null)
  //console.log('rangeslider props', props)

  useEffect(() => {
    let text = props.perAcre ? '/Acre' : ''
    setPerAcreExt(text)
  }, [props.perAcre])

  useEffect(() => {
    console.log(props.selectedOp)
    let priceCalc = 0;

    if (props?.selectedOp?.price !== undefined) {
      priceCalc = props?.selectedOp?.price
      //console.log('priceCalc', priceCalc, props?.selectedOp.price)
    }
    else {
      priceCalc = Math.abs(props?.selectedOp?.operationCost / props?.selectedOp?.rate)
    }
    let price = Math.round(priceCalc * 10000) / 10000
    console.log("PRICE IN RANGE SLIDER", price)
    setPrice(price)
  }, [props.selectedOp, props.isQuantityMap])

  useEffect(() => {
    //console.log('props', props)
    if (props.legend !== undefined && props.legend.length > 0) {
      setLegend(props.legend);
      const cloneLegend = [...props.legend];
      const marks = [];
      let num = null;
      const colors = [];
      if (
        props.isElevation !== undefined
        && props.isElevation === true
        && cloneLegend[0].areaPercent === undefined
      ) {
        if (props.type !== 'Variety' || props.legend[0].variety === null) {
          cloneLegend.reverse();
        }
      }
      if ((props.type === 'Variety' || props.type === 'zoneAnalysisMap') && props.legend.length > 0 && props.legend[0].variety !== null) {
        marks.push(0);
        for (let i = 0; i < cloneLegend.length; i++) {
          colors.push(cloneLegend[i].color);
          num = Number(cloneLegend[i].area.slice(0, -2)) + marks[i];
          marks.push(Math.round(num * 100) / 100);
        }
      } else {
        for (let i = cloneLegend.length - 1; i >= 0; i--) {
          colors.push(cloneLegend[i].color);
          num = cloneLegend[i].min;
          marks.push(Math.round(num * 100) / 100);
          if (i === 0) {
            num = cloneLegend[i].max;
            marks.push(Math.round(num * 100) / 100);
          }
        }
      }

      if (props.perAcre) {
        setRanges(marks);
      } else {
        if ((props.type === 'Variety' || props.type === 'zoneAnalysisMap') && props.legend.length > 0 && props.legend[0].variety !== null) {
          setRanges(marks);
        }
        else {
          const perField = marks.map((x) => (x * props.netSeededAcres));
          //console.log(marks, perField)
          setRanges(perField);
        }
      }
    } else {
      setRanges([]);
    }
  }, [props.legend, props.isElevation]);

  useEffect(() => {
    if (props.isOperation === true) {
      var box = document.getElementById('range-slider');
      if (box !== null) {
        box.style.pointerEvents = 'none';
      }
    } else {
      var box = document.getElementById('range-slider');
      if (box !== null) {
        box.style.pointerEvents = 'auto';
      }
    }
  }, [props.isOperation, varietySummary]);

  useEffect(() => {
    if (ranges.length > 0 && !(varietySummary && (props.type === 'Profit' || props.type === 'zoneAnalysisMap'))) {
      document.getElementById('slider').style.display = 'block';
      createColorBlocks();
      getAverage(ranges);
    } else if (varietySummary && (props.type === 'Profit' || props.type === 'zoneAnalysisMap')) {
      // nothing
    }
    else {
      document.getElementById('slider').style.display = 'none';
    }
    //console.log('isVarietyMap', props.isVarietyMap)
    if (props.isVarietyMap !== undefined && props.isVarietyMap === true) {
      document.getElementById('slider').style.display = 'none';
    }
  }, [ranges, varietySummary, width, props.isVarietyMap]);

  useEffect(() => {
    //console.log(props)
    function handleResize() {
      if (ranges.length > 0) {
        const slider = document.getElementById('slider');
        if (slider) {
          // check that exists
          slider.style.display = 'block';
          createColorBlocks();
          getAverage(ranges);
        }
      }
    }
    window.addEventListener('resize', handleResize);
  }, []);

  const createColorBlocks = () => {
    const sliderRoot = document.getElementById('slider');
    const sliderRail = document.getElementsByClassName('MuiSlider-rail')[0];
    const markers = document.getElementsByClassName('MuiSlider-thumb');

    const end = props.legend.length;
    let prevLeft = 0;

    // clear all the existing color blocks before creating new
    const blox = document.getElementsByClassName('MuiSlider-track');
    while (blox.length > 0) {
      blox[0].parentNode.removeChild(blox[0]);
    }

    for (let i = 0; i < ranges.length - 1; i++) {
      if (props.legend[i].areaPercent !== 100) {
        var colorBlock = document.createElement('span');
        colorBlock.setAttribute('class', 'MuiSlider-track');
        const dist = getDistanceBetweenMarkers(markers[i], markers[i + 1]);
        // dist = dist === 0 ? 250 : dist;
        colorBlock.style.left = `${prevLeft}px`;
        colorBlock.style.width = `${dist}px`;
        colorBlock.style.height = '10px';

        if ((props.type === 'zoneAnalysisMap' || props.type === 'Variety') && props.legend.length > 0 && props.legend[0].variety !== null) {
          colorBlock.style.backgroundColor = props.legend[i].color;
        } else {
          colorBlock.style.backgroundColor = props.legend[end - (i + 1)].color;
        }
        // colorBlock.style.backgroundColor = (props.type === 'Variety' || props.type === 'zoneAnalysisMap' && props.legend.length > 0 && props.legend[0].variety !== null) ? props.legend[i].color : props.legend[end - (i + 1)].color;
        prevLeft += dist;
        sliderRoot.insertBefore(colorBlock, sliderRail);
      } else {
        var colorBlock = document.createElement('span');
        colorBlock.setAttribute('class', 'MuiSlider-track');
        colorBlock.style.width = '100%';
        colorBlock.style.height = '10px';
        colorBlock.style.backgroundColor = props.legend[0].color;
        sliderRoot.insertBefore(colorBlock, sliderRail);
        break;
      }
    }
  };

  const getDistanceBetweenMarkers = (marker1, marker2) => {
    /*
     Takes two DOM elements and calculates the distance between them in pixels
     based off: https://stackoverflow.com/a/19671293/8061384
     */

    const rect1 = marker1.getBoundingClientRect();
    const rect2 = marker2.getBoundingClientRect();

    // get center points
    const rect1x = rect1.left + rect1.width / 2;
    const rect1y = rect1.top + rect1.height / 2;
    const rect2x = rect2.left + rect2.width / 2;
    const rect2y = rect2.top + rect2.height / 2;

    // calculate distance
    const distanceSquared = Math.pow(rect1x - rect2x, 2) + Math.pow(rect1y - rect2y, 2);
    const distance = Math.sqrt(distanceSquared);
    // console.log("distance", distance);
    return distance;
  };


  const handleRangeChange = (event, value) => {
    setRanges(value);
    // reconstruct legend with new values, send back to profit map to get new map
    let newLegend = JSON.parse(JSON.stringify(legend));
    let j = 0;
    for (let i = newLegend.length - 1; i >= 0; i--) {
      newLegend[i].min = value[j];
      newLegend[i].max = value[j + 1];
      j += 1
    }
    setLegend(newLegend);
  };

  const getAverage = (value) => {
    const total = value.reduce((a, b) => a + b, 0);
    const rangeAverage = (total / value.length).toFixed(2);
    setAverage(rangeAverage);
  };

  const updateMap = () => {
    // format info for request to getProfitMap
    setDisplayButton(false);
    const binRanges = {};
    const binvals = [];
    const colvals = [];
    for (let i = 0; i < legend.length; i++) {
      colvals.push(legend[i].color);
      if (i === 0) {
        binvals.push(legend[i].max)
      }
      binvals.push(legend[i].min);
    }
    binRanges.binRange = binvals.reverse().join(', ');
    binRanges.colors = JSON.stringify(colvals.reverse());
    binRanges.range = legend.length;
    props.applyUpdate(binRanges, legend);
  };

  const displayValue = () => {
    let value = '';
    switch (props.type) {
      case 'Profit':
        value = 'Profit Map ($' + perAcreExt + ')';
        break;
      case 'Elevation':
        if (props.selectedElevationType === 'Slope') {
          value = 'Slope Summary';
        } else if (props.selectedElevationType === 'Relative_Elevation') {
          value = 'Relative Elevation Map Summary (z-score)';
        } else if (props.selectedElevationType === 'TPI') {
          value = 'Topographic Position Index';
        } else if (props.selectedElevationType === 'TRI') {
          value = 'Terrain Ruggedness Index';
        } else {
          value = 'Elevation Map Summary (ft)';
        }
        break;
      case 'Operation':
        value = 'Operation Map Summary';
        break;
      case 'Variety':
        if (
          props.selectedVariety !== null
          && props.selectedVariety.name === 'Variety Map'
        ) {
          value = 'Planted Acres by Variety';
        } else {
          value = `Profit Analysis by Variety: ${props.selectedVariety.name} ($${perAcreExt})`;
        }
        break;
      case 'zoneAnalysisMap':
        value = 'Profit Breakdown by Zone';
        break;
      default:
        console.log(`unexpected type: ${props.type}`);
    }
    return value;
  };

  const varietyBreakdownTable = () => (
    <Box
      display="flex"
      flexDirection="column"
      border={1}
      borderColor={green}
      borderRadius="borderRadius"
      style={{
        minWidth: 400, width: 750, overflowY: 'auto', pointerEvents: 'auto',
      }}
    >
      <Box
        py={1}
        display="flex"
        flexGrow={1}
        fontWeight={500}
        className={classes.labels}
      >
        <Box style={{ width: '30%', textAlign: 'center' }}>
          {props.type === 'zoneAnalysisMap' ? 'Zone' : 'Variety'}
        </Box>
        <Box style={{ width: '40%', textAlign: 'center', whiteSpace: 'nowrap' }}>
          {props.perAcre ? 'Average Profit ($/Acre)' : 'Profit ($)'}
        </Box>
        <Box style={{ width: '30%', textAlign: 'center' }}>
          Acres
        </Box>
      </Box>
      {
        props.type === 'Profit'
          ? props.profitMapResponse.length > 0
          && props.profitMapResponse.map((sum, i) => sum.variety.toUpperCase() !== 'VARIETY'
            && sum.variety.toUpperCase() !== 'NOVARIETY'
            && createRow(sum, i, false))
          : props.profitMapResponse.length > 0
          && props.profitMapResponse.map((sum, i) => sum.variety.toUpperCase() !== 'VARIETY'
            && sum.variety.toUpperCase() !== 'NOVARIETY'
            && createRow(sum, i, false))
      }

      {
        props.type === 'Profit'
        && props.profitMapResponse.length > 0
        && props.profitMapResponse.map((sum, i) => sum.variety.toUpperCase() === 'NOVARIETY'
          && createRow(sum, i, true))
      }
    </Box>
  );

  const createRow = (sum, i, addDivider) => {
    const varietyColor = props.profitMapResponse.filter((x) => x.variety.toUpperCase() === 'VARIETY')[0];
    let color = '';
    let acres = 0;
    if (sum.variety.toUpperCase() === 'NOVARIETY') {
      color = '';
      acres = props.netSeededAcres;
    } else {
      for (var i = 0; i < varietyColor.legend.length; i++) {
        if (varietyColor.legend[i].variety.replace('Variety', 'Zone').replace('.0', '') === sum.variety.replace('Variety', 'Zone').replace('.0', '')) {
          color = varietyColor.legend[i].color;
          acres = varietyColor.legend[i].areaPercent * props.netSeededAcres / 100;
          break;
        }
      }
    }
    //const zoneColor = varietyColor.legend.filter(x => x.variety === )
    return (
      <Box
        key={i}
        display="flex"
        flexDirection="column"
        flexGrow={1}
      >
        {addDivider && <Divider style={{ width: '100%' }} />}
        <Box className={classes.tableRow}>
          <Box style={{ width: '30%', textAlign: 'center' }}>
            {color !== '' && (
              <FiberManualRecordIcon
                style={{ color: color }}
              />
            )}
            {
              sum.variety.toUpperCase() === 'NOVARIETY' ? 'TOTAL PROFIT'
                : props.type === 'Profit' ? sum.variety
                  : Number(sum.variety) === 2 ? 'Application Applied'
                    : Number(sum.variety) === 1 ? 'Application Not Applied'
                      : sum.variety.replace('Variety', 'Zone').replace('.0', '')
            }
          </Box>
          <Box style={{ width: '40%', textAlign: 'center' }}>
            {props.perAcre ? dollarFormat(sum.mean) : dollarFormat(sum.mean * props.netSeededAcres)}
            {props.perAcre ? '/ac' : ''}
          </Box>
          <Box style={{ width: '30%', whiteSpace: 'nowrap', textAlign: 'center' }}>
            {numFormat(acres)}
            {' acres'}
          </Box>
        </Box>

      </Box>
    );
  };

  const showAverage = () => {
    let value = (props.type === 'Profit' || props.type === 'Variety') ? '$' : '';
    let mean = 0;
    let unit = '';
    let avgTitle = ''
    //console.log('props', props.type, props.isQuantityMap)
    if (props.isQuantityMap) {
      unit = props.selectedOp.fileType.toUpperCase() === 'HARVEST' ? 'revenue' : 'cost';
    } else if (props.unit !== '' && props.unit !== null && props.unit !== undefined) {
      if (props.unit === 'K seeds') {
        unit = 'Seeds';
      } else {
        unit = con.unitConversion(props.unit, true);
        unit = unit.charAt(0).toUpperCase() + unit.slice(1);
      }
    }

    if (props.isQuantityMap) {
      if (props?.selectedOp?.fileType === 'Harvest') {
        avgTitle = 'Revenue'
      }
      else if (props?.selectedOp?.fileType?.toUpperCase() === 'APPLICATION' && props.nMap) {
        avgTitle = 'N Cost'
      }
      else {
        avgTitle = 'Cost'
      }
    }
    else {
      if (props?.selectedOp?.fileType === 'Harvest') {
        avgTitle = 'Yield'
      }
      else if (props?.selectedOp?.fileType?.toUpperCase() === 'APPLICATION' && props.nMap) {
        avgTitle = 'N Rate'
      }
      else {
        avgTitle = 'Rate'
      }
    }

    if (props.profitMapResponse && props.profitMapResponse.length > 0) {
      if (props.type === 'Variety' && props.selectedVariety !== undefined) {
        const pm = props.profitMapResponse.filter((x) => x.variety === props.selectedVariety.name)[0];
        //console.log('pm', pm);
        mean += props.perAcre ? pm?.mean : (pm?.mean * props.netSeededAcres);
      } else if (props.profitMapResponse.filter((x) => x.variety === 'NoVariety').length > 0) {
        // console.log(props.profitMapResponse)
        const pm = props.profitMapResponse.filter(
          (x) => x.variety === 'NoVariety',
        )[0];
        // console.log(pm.mean)
        mean += props.perAcre ? pm?.mean : (pm?.mean * props.netSeededAcres);
        //console.log(mean)
      }
    }

    // if (unit === 'K seeds') {
    //   console.log()
    //   mean =  mean / 1000
    // }
    value += numFormat(mean);

    return (
      <Box display="flex" justifyContent="center">
        <Box px={1}>
          {props.type === 'Profit'
            ? (props.perAcre ? 'Average Profit (per Acre): ' : 'Total Profit: ')
            : props.type === 'Elevation'
              ? 'Average Elevation Change ' + (props.perAcre ? 'per Acre: ' : ': ')
              : props.type === 'Operation'
                ? (props.perAcre ? `Average ${avgTitle}: ` : `${avgTitle}: `)
                : (props.perAcre ? 'Average' : 'Total: ')}

          {props.zoneMeanProfit !== undefined ? `$${props.zoneMeanProfit}${perAcreExt}`
            : (props.isQuantityMap && props.type === 'Operation' ? '$' : '') + value}
          {' '}
          {
            props.type === 'Elevation' && (props.selectedElevationType === 'elevationMap' || props.selectedElevationType === 'Slope')
              ? 'ft'
              : props.type === 'Elevation' && (props.selectedElevationType === 'TPI' || props.selectedElevationType === 'TRI')
                ? 'Index Value'
                : props.type === 'Elevation' && (props.selectedElevationType === 'Relative_Elevation')
                  ? 'z-score'
                  : props.type === 'Operation'
                    ? (props.isQuantityMap) ? perAcreExt : `${unit}${perAcreExt}`
                    : props.type === 'Profit' || props.type === 'Variety' ? perAcreExt : ''
          }
        </Box>
        {/* Check is selected tab is "Operation" and if NPK has values to display N (lbs/ac) (N-rate * appliedRate) */}
        {
          props.type === 'Operation'
          && props.NPK !== undefined
          && props.NPK.N !== undefined
          && (
          <Box px={1}>
            { `N Rate in (lbs.${perAcreExt}): ${(Number(props.NPK.N) * Number(props.appliedRate)).toFixed(2)} lbs.${perAcreExt}` }
          </Box>
          )
        }
      </Box>

    );
  };

  const handleSummaryTableChange = () => {
    setVarietySummary(!varietySummary);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleUpdateData = async () => {
    await props.syncData('Operations');
    setOpen(false);
  };

  const displayVarietyMean = (variety) => {
    // Display mean $/acre for variety. Find selected variety in profit map
    // response to get value.
    if (props.profitMapResponse !== undefined) {
      const profitVariety = props.profitMapResponse.filter((y) => y.variety === variety);
      if (profitVariety.length > 0 && profitVariety[0] !== undefined) {
        return (
          <Box>
            {props.perAcre ? dollarFormat(profitVariety[0].mean) : dollarFormat(profitVariety[0].mean * props.netSeededAcres)}
            {perAcreExt}
          </Box>
        );
      }
      return (
        <div />
      );
    }
  };

  const showMissingFileNames = (file, i) => (
    <Typography key={i}>{file}</Typography>
  );

  const valueText = (value) => (
    props.type === 'Operation'
      ? (props.isQuantityMap ? dollarFormat(value) : `${numFormat(value)}`)
      : props.type === 'Elevation' && (props.selectedElevationType === 'elevationMap' || props.selectedElevationType === 'Slope')
        ? `${numFormat(value)}`
        : props.type === 'Elevation' && (props.selectedElevationType === 'TPI' || props.selectedElevationType === 'TRI')
          ? `${numFormat(value)}`
          : props.type === 'Elevation' && (props.selectedElevationType === 'Relative_Elevation')
            ? `${numFormat(value)}`
            : ((props.type === 'Variety' || props.type === 'zoneAnalysisMap') && props.legend.length > 0 && props.legend[0].variety !== null)
              ? `${numFormat(value)}%`
              : `$${numFormat(value, 0, 0)}`);

  return (
    <Box
      className={classes.slider}
      style={{
        height:
          props.manualEntry === true
            ? '175px'
            : props.editModal === true
              ? 'unset'
              : 'auto',
        width: '100%',
      }}
    >

      <Box
        px={1}
        display="flex"
        justifyContent="center"
      >
        {
          !props.editModal
          && (
            <Box
              display="flex"
              fontWeight={500}
              fontSize={16}
            >
              {displayValue()}

              { props?.type === 'Variety'
                && props?.selectedVariety !== null
                && props?.selectedVariety.name === 'Variety Map'

                && (
                  <Tooltip
                    title="View total acreage and profits for each variety on the field"
                    placement="right"
                  >
                    <InfoOutlinedIcon
                      style={{ fontSize: 16, marginLeft: 4 }}
                    />
                  </Tooltip>
                )}

              { props?.type === 'Elevation' && (
                <Tooltip
                  title={tooltips[props?.selectedElevationType]}
                  placement="right"
                >
                  <InfoOutlinedIcon
                    style={{ fontSize: 16, marginLeft: 4 }}
                  />
                </Tooltip>
              )}

              {
                (props.type === 'Profit' && !props.mapLoading && displayButton)
                && (
                  <Box
                    fontSize={14}
                    className={classes.update}
                    onClick={() => updateMap()}
                  >
                    <UpdateIcon />
                    {' Apply Changes'}
                  </Box>
                )
              }

            </Box>
          )
        }
      </Box>

      {(!props.mobile)
        && (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            style={{ width: '100%' }}
            flexWrap="wrap"
          >
            <Box px={2}>
              {
                (props.type === 'zoneAnalysisMap' && !props.mapLoading && props.profitMapResponse.length > 0)
                  ? (
                    <Box fontWeight={500}>
                      <Switch
                        checked={varietySummary}
                        onChange={(e) => handleSummaryTableChange()}
                        name="nitro"
                        color="primary"
                      />
                      {'Zone Table'}
                    </Box>
                  )
                  : <div>&nbsp;</div>
              }
            </Box>

            <Box px={2}>
              {
                ((props.type === 'Operation' && props.selectedOp !== ''))
                  ? (
                    <Box fontWeight={500}>
                      {
                        props?.selectedOp?.fileType?.toUpperCase() === 'HARVEST'
                          ? `Yield:
                ${capitalizeFirstLetter(con.unitConversion(props.unit, true))}
                ${perAcreExt}`
                          : `Applied Rate:
                ${props.unit !== 'K seeds'
                            ? capitalizeFirstLetter(con.unitConversion(props.unit, true))
                            : 'Seeds'}${perAcreExt}`
                      }
                      <Switch
                        checked={props.isQuantityMap}
                        onChange={(e) => props.setIsQuantityMap(!props.isQuantityMap)}
                        name="nitro"
                        color="primary"
                      />
                      {props?.selectedOp?.fileType?.toUpperCase() === 'HARVEST' ? 'Revenue ($' + perAcreExt + ')' : 'Cost ($' + perAcreExt + ')'}
                      {props.isQuantityMap
                        ? ` (Price being used: $${price}/${props.unit})`
                        : ''}
                    </Box>
                  )
                  : <div>&nbsp;</div>
              }
            </Box>

            <Box px={2}>
              {
                props.type === 'Profit'
                  && ((props.profitMapResponse
                    && props.profitMapResponse.length > 0
                    && props.profitMapResponse[0] !== undefined
                    && props.profitMapResponse[0].isMissingOperationFile === true)
                    || props.errorMessage !== null)
                  ? (
                    <div>
                      <Box className={classes.hover} onClick={handleClickOpen}>
                        Missing Operations
                    <InfoOutlinedIcon className={classes.infoIcon} />
                      </Box>
                      <Dialog
                        open={open}
                        onClose={handleClose}
                        aria-labelledby="alert-dialog-title"
                        aria-describedby="alert-dialog-description"
                      >
                        {
                          props.profitMapResponse[0] !== undefined
                            && props.profitMapResponse[0].missingOperationFile !== null ? (
                              <DialogTitle id="alert-dialog-title">
                                The below operations are missing or have faulty shapefiles:
                              </DialogTitle>
                            ) : (
                              <DialogTitle id="alert-dialog-title">
                                {props.errorMessage}
                              </DialogTitle>
                            )
                        }

                        <DialogContent>
                          {props.profitMapResponse[0] !== undefined && props.profitMapResponse[0].missingOperationFile !== null && (
                            <DialogContentText id="alert-dialog-description" style={{ color: 'black' }}>
                              {props.profitMapResponse[0] !== undefined && props.profitMapResponse[0].missingOperationFile.map((x, i) => showMissingFileNames(x, i))}
                              <br />
                        We assumed a constant rate on field for that file for the prices provided. Please click on the
                              {' '}
                              <b>UPDATE FIELD DATA</b>
                              {' '}
                        to query precision ag system and
                        update your records for this field.
                            </DialogContentText>
                          )}
                        </DialogContent>
                        <DialogActions>
                          {props.profitMapResponse[0] !== undefined && props.profitMapResponse[0].missingOperationFile !== null && (
                            <Button onClick={() => handleUpdateData()} color="primary">
                              Update Field Data
                            </Button>
                          )}
                          <Button onClick={handleClose} color="primary">
                            Close
                      </Button>
                        </DialogActions>
                      </Dialog>
                    </div>
                  )
                  : <div>&nbsp;</div>
              }
            </Box>

          </Box>
        )}

      {varietySummary && (props.type === 'Profit' || props.type === 'zoneAnalysisMap') ? varietyBreakdownTable()
        : (
          <Box
            id="range-slider"
            mt={4}
            px={2}
            style={{
              width: props.editModal === true ? width - 600 : width - 350,
              minWidth: 680,
            }}
          >
            {
              (props.type === 'Elevation' && props.selectedElevationType === 'Relative_Elevation')
                ? (
                  <ElevationSlider
                    id="slider"
                    valueLabelFormat={(x) => valueText(x)}
                    max={ranges[ranges.length - 1]}
                    min={ranges[0]}
                    value={ranges}
                    step={0.01}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    onChange={handleRangeChange}
                    onChangeCommitted={() => setDisplayButton(true)}
                  />
                )
                : (
                  <MapSlider
                    id="slider"
                    valueLabelFormat={(x) => valueText(x)}
                    max={ranges[ranges.length - 1]}
                    min={ranges[0]}
                    value={ranges}
                    step={0.01}
                    valueLabelDisplay="on"
                    aria-labelledby="range-slider"
                    onChange={handleRangeChange}
                    onChangeCommitted={() => setDisplayButton(true)}
                  />
                )
            }
          </Box>
        )}

      {props.type === 'Operation' && props.NPK !== undefined && props.NPK.N !== undefined
        ? (
          <FormControlLabel
            control={(
              <Switch
                checked={props.nMap}
                onChange={(e) => props.setNMap(!props.nMap)}
                name="nitro"
                color="primary"
              />
            )}
            label="N Map"
          />
        )
        : ''}

      <Box fontSize={14} mt={1}>
        {
          varietySummary && props.type === 'zoneAnalysisMap' ? ''
            : ((props.type === 'Variety' || props.type === 'zoneAnalysisMap') && props.legend.length > 0 && props.legend[0].variety !== null)
              ? (
                <Box display="flex" flexWrap="wrap">
                  {
                    props.legend.map((x, i) => (
                      <Box
                        key={i}
                        m={1}
                        p={1}
                        border={1}
                        borderRadius="borderRadius"
                        display="flex"
                        alignItems="center"
                      >
                        <FiberManualRecordIcon style={{ color: x.color, marginRight: '5px' }} />
                        {
                          props.type === 'zoneAnalysisMap' && Number(x.variety) === 2 ? (
                            <Box>
                              {'Application Applied ($'}
                              {props.perAcre ? props.profitMapResponse.filter((y) => Number(y.variety) === Number(x.variety))[0].mean.toFixed(2) : props.profitMapResponse.filter((y) => Number(y.variety) === Number(x.variety))[0].mean.toFixed(2) * props.netSeededAcres}
                              {props.perAcre ? '/ac' : ''}
                            </Box>
                          ) : props.type === 'zoneAnalysisMap' && Number(x.variety) === 1 ? (
                            <Box>
                              {'Application Not Applied ($'}
                              {props.perAcre ? props.profitMapResponse.filter((y) => Number(y.variety) === Number(x.variety))[0].mean.toFixed(2) : props.profitMapResponse.filter((y) => Number(y.variety) === Number(x.variety))[0].mean.toFixed(2) * props.netSeededAcres}
                              {props.perAcre ? '/ac' : ''}
                            </Box>
                          ) : (
                                <Box>
                                  <Box>
                                    {x.variety.replace('Variety', 'Zone').replace('.0', '')}
                                  </Box>
                                  <Box>
                                    {props.isVarietyMap !== undefined && props.isVarietyMap === true ? "" : displayVarietyMean(x.variety)}
                                  </Box>

                                </Box>
                              )
                        }
                      </Box>
                    ))
                  }
                </Box>
              )
              : !(ranges.length === 0 && props.mapLoading) && (
                <Box>
                  { varietySummary === true && props.type === 'Profit' || props.type === 'zoneAnalysisMap' ? '' : showAverage()}
                </Box>
              )
        }
      </Box>
      {varietySummary === true && props.type === 'Profit' || props.type === 'zoneAnalysisMap' ? '' : (!props.editModal && !(props.type === 'Variety' && props.legend.length > 0 && props.legend[0].variety !== null)) && props.type === 'Profit' && (
        <Box className={classes.footer}>
          <Box>Drag numbers to change ranges or</Box>
          <Box
            className={classes.settings}
            onClick={() => props.openSettings(true)}
          >
            &nbsp;edit profit ranges
          </Box>
        </Box>
      )}

    </Box>
  );
}
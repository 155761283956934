/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Chip,
  Modal,
  Button,
  TextField,
  Typography,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useSnackbar } from 'notistack';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { AcreageReportingPDF } from './AcreageReportingPDF';
import { green, textPrimary, textSecondary } from '../../../styles/colors';
import { PdfCluMap } from '../../Maps/AcreageReporting/PDFMap';
import { sleep, getImage } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
    maxHeight: window.innerHeight - 20,
    maxWidth: window.innerWidth - 20,
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 5,
    right: 5,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  label: {
    fontSize: '.9rem',
    fontWeight: 500,
  },
  getPdf: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  select: {
    margin: '0 10px',
    backgroundColor: '#ffffff',
    width: 400,
  },
}));

/**
 * User can re-name report, enter notes, then start process to create and download PDF of CLU data.
 * Hidden map component is used for gathering images. Multi select autocomplete are available for
 * farm, tract, and clu selection, with a max of 20 CLUs selected a time due to PDF render
 * limitations.
 * @param {Object} clusSeen Data relating to clus user has viewed
 * @param {Object} CLUSummary Summary of CLU
 * @param {Object} commoditiesSeen Data relating to clus user has viewed
 * @param {Object} commodityTypesSeen Data relating to commodityTypes user has viewed
 * @param {Bool} open Open modal
 * @param {Array} pdfGeneratedFor CLU identifiers for which PDF has been generated this session
 * @param {String} printedName User's name, used with digital signature
 * @param {String} reportName Name of report for pdf title
 * @param {Function} setOpen Control modal open state
 * @param {Function} setPdfGeneratedFor Updates CLU identifiers for which PDF has been generated
 * @param {Function} setReportName Update report name
 * @param {String} signature Base64 PNG of digital signature
 * @param {Date} signatureTimeStamp Date object of time of digital signature
 * @param {Object} statesSeen Data relating to states user has viewed
 * @returns {JSX} PDF creation modal
 */
export const CreateCARTPdf = ({
  clusSeen,
  CLUSummary,
  commoditiesSeen,
  commodityTypesSeen,
  open,
  pdfGeneratedFor,
  printedName,
  reportName,
  setOpen,
  setPdfGeneratedFor,
  setReportName,
  signature,
  signatureTimeStamp,
  statesSeen,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [notes, setNotes] = useState('');

  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const [cluData, setCluData] = useState([]);

  // Populates farm dropdown
  const [farms, setFarms] = useState([]);
  // Farms selected from dropdown
  const [selectedFarms, setSelectedFarms] = useState([]);

  // Stores all tracts available tracts
  const [tracts, setTracts] = useState([]);
  // Tracts for selected farms, these will populate dropdown
  const [selectedFarmsTracts, setSelectedFarmsTracts] = useState([]);
  // Tracts selected from dropdown
  const [selectedTracts, setSelectedTracts] = useState([]);

  // Stores all CLUs
  const [clus, setClus] = useState([]);
  // CLUs for selected tracts, these will populate dropdown
  const [selectedTractsClus, setSelectedTractsClus] = useState([]);
  // CLUs selected from dropdown
  const [selectedClus, setSelectedClus] = useState([]);

  // Control multiselects, needed to auto-close when all options selected
  const [farmSelectionOpen, setFarmSelectionOpen] = useState(false);
  const [tractSelectionOpen, setTractSelectionOpen] = useState(false);
  const [cluSelectionOpen, setCluSelectionOpen] = useState(false);

  // Boundaries for map
  const [clu, setClu] = useState(null);
  const [completeBoundary, setCompleteBoundary] = useState(null);

  useEffect(() => {
    const farmNumbers = new Set();
    const tractNumbers = new Set();
    const cluNumbers = [];

    // Build selection options from all clus seen
    Object.values(clusSeen).forEach((cluInfo) => {
      const farm = cluInfo?.farm_number;
      const tract = cluInfo?.tract_number;
      const cluNumber = cluInfo?.clu_number;
      farmNumbers.add(farm);
      tractNumbers.add({
        tractNumber: tract, tractName: `Tract: ${tract}`, farmNumber: farm, farmName: `Farm ${farm}`,
      });
      cluNumbers.push({
        cluNumber, tractNumber: tract, tractName: `Tract: ${tract}`, farmNumber: farm, farmName: `Farm ${farm}`, cluIdentifier: cluInfo.clu_identifier,
      });
    });

    // Convert sets to arrays and sort
    const farmsList = Array.from(farmNumbers).sort();
    const tractList = Array.from(tractNumbers).sort();

    setFarms(farmsList);
    setTracts(tractList);
    setClus(cluNumbers);

    // Set selection to first farm by default
    handleFarmChange([farmsList[0]], tractList, cluNumbers);
  }, [clusSeen]);

  // This is the part of the generation that takes time: getting all the images
  const getCluImages = async () => {
    try {
      setPdfLoading(true);

      // Store clu info with images added
      const clusWithImages = [];

      // Get CLU indentifiers from those selected in dropdown
      const selectedIdentifiers = selectedClus.map((x) => x.cluIdentifier);
      const summariesForSelectedClus = CLUSummary.filter(
        (x) => selectedIdentifiers.includes(x[0].clu_identifier),
      );

      for (const cluInfo of summariesForSelectedClus) {
        const cluSortedInfo = [...cluInfo];
        // Sort so larger subfields are drawn first
        cluSortedInfo.sort((a, b) => b.acres - a.acres);
        const cluBoundary = clusSeen[cluInfo[0].clu_identifier].cluShape;

        // Boundary data map needs
        setClu(cluSortedInfo);
        setCompleteBoundary(cluBoundary);

        // We need this here for map to have time to load new boundary
        await sleep(1000);
        const mapImage = await getImage('pdf-map-image');

        cluSortedInfo[0].image = mapImage;
        clusWithImages.push(cluSortedInfo);
      }
      setCluData(clusWithImages);
      setPdfLoading(false);
      const updatedGenerated = [...pdfGeneratedFor, ...selectedIdentifiers];
      setPdfGeneratedFor(updatedGenerated);

      getNextClu(updatedGenerated);
    } catch (err) {
      console.error(err);
    }
  };

  const getNextClu = (generated) => {
    try {
      const notGeneratedFor = selectedTractsClus.filter((x) => !generated.includes(x.cluIdentifier));
      if (notGeneratedFor.length) {
        // Tract had not yet generated CLUs, so add one
        setSelectedClus([notGeneratedFor[0]]);
      } else {
        // Selected tracts have had all their CLUs generated
        const notGeneratedForAll = clus.filter((x) => !generated.includes(x.cluIdentifier));

        // Add new clus farm and tract, then update selected clu
        handleFarmChange([...selectedFarms, notGeneratedForAll[0].farmNumber], tracts, clus);

        const {
          farmName, farmNumber, tractName, tractNumber,
        } = notGeneratedForAll[0];
        const newTract = {
          farmName, farmNumber, tractName, tractNumber,
        };
        handleTractChange([...selectedTracts, newTract], clus);
        handleCluChange([notGeneratedForAll[0]]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const maxSelected = 20;
  // Select all avaialble farms, up to 20
  const selectAllFarms = () => {
    if (farms.length < maxSelected) {
      handleFarmChange(farms, tracts, clus);
    } else {
      handleFarmChange(farms.slice(0, maxSelected), tracts, clus);
      enqueueSnackbar(`Your top ${maxSelected} farms have been selected. There is a maximum of ${maxSelected} CLUs per PDF.`);
    }
  };

  // Select all avaialble tracts, up to 20
  const selectAllTracts = () => {
    if (selectedFarmsTracts.length <= maxSelected) {
      handleTractChange(selectedFarmsTracts, clus);
    } else {
      handleTractChange(selectedFarmsTracts.slice(0, maxSelected), clus);
      enqueueSnackbar(`Your top ${maxSelected} tracts have been selected. There is a maximum of ${maxSelected} CLUs per PDF.`);
    }
  };

  // Select all avaialble clus, up to 20
  const selectAllClus = () => {
    if (selectedTractsClus.length <= maxSelected) {
      setSelectedClus(selectedTractsClus);
    } else {
      setSelectedClus(selectedTractsClus.slice(0, maxSelected));
      enqueueSnackbar(`Your top ${maxSelected} CLUs have been selected. There is a maximum of ${maxSelected} CLUs per PDF.`);
    }
  };

  const handleFarmChange = (farmsSelected, allTracts, allClus) => {
    setSelectedFarms(farmsSelected);

    // Get tracts for all selected farms
    const newTracts = allTracts.filter((x) => farmsSelected.includes(x.farmNumber));
    // Important to sort for grouping
    newTracts.sort((a, b) =>
      a.farmNumber - b.farmNumber || a.tractNumber - b.tractNumber,
      // a.farmNumber - b.farmNumber
    );

    if (newTracts.length) {
      // Only keep the tracts that were previously selected that are still in newTracts
      let tractsSelected = selectedTracts.filter((tract) => newTracts.includes(tract));
      if (!tractsSelected.length) {
        tractsSelected = [newTracts[0]];
      }

      setSelectedFarmsTracts(newTracts);
      handleTractChange(tractsSelected, allClus);
    } else {
      setSelectedFarmsTracts(newTracts);
      setSelectedTracts([]);
      setSelectedTractsClus([]);
      setSelectedClus([]);
    }
  };

  const handleTractChange = (tractsSelected, allClus) => {
    setSelectedTracts(tractsSelected);

    // Get all farm and associated tract numbers, then filter all CLUs
    const farmsAndTracts = {};
    tractsSelected.forEach((tractInfo) => {
      if (farmsAndTracts.hasOwnProperty(tractInfo.farmNumber)) {
        farmsAndTracts[tractInfo.farmNumber].push(tractInfo.tractNumber);
      } else {
        farmsAndTracts[tractInfo.farmNumber] = [tractInfo.tractNumber];
      }
    });
    const newClus = allClus.filter((x) =>
      farmsAndTracts.hasOwnProperty(x.farmNumber) && farmsAndTracts[x.farmNumber].includes(x.tractNumber),);

    // Sort and set filtered CLUs
    newClus.sort((a, b) =>
      a.tractNumber - b.tractNumber || a.farmNumber - b.farmNumber || a.cluNumber - b.cluNumber,
      // a.tractNumber - b.tractNumber
    );

    if (newClus?.length) {
      // Only keep the clus that were previously selected that are still in newClus
      let clusSelected = selectedClus.filter((clu) => newClus.includes(clu));
      if (!clusSelected.length) {
        clusSelected = [newClus[0]];
      }

      setSelectedTractsClus(newClus);
      handleCluChange(clusSelected);
    } else {
      setSelectedTractsClus([]);
      setSelectedClus([]);
    }
  };

  const handleCluChange = (clusSelected) => {
    if (clusSelected.length <= maxSelected) {
      setSelectedClus(clusSelected);
    } else {
      enqueueSnackbar(`There is a maximum of ${maxSelected} CLUs per PDF.`);
    }
  };

  const handleDownloadClick = () => {
    // Clear data so user can begin generating fresh pdf
    setCluData([]);
    setPdfDownloaded(true);

    // Close modal on download or keep open?
    // setOpen(false)
  };

  const downloadReady = () => {
    try {
      const element = document.getElementById('pdf-download');
      if (element) {
        setTimeout(() => { element.click(); }, 0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const cluSelection = () => (
    <Box display="flex" flexDirection="column">
      <Box p={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            Select Farms
          </Box>
          <IconButton
            color="primary"
            onClick={selectAllFarms}
          >
            <DoneAllIcon fontSize="small" />
            <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Select All</span>
          </IconButton>
        </Box>

        <Autocomplete
          className={classes.select}
          multiple
          disableClearable
          disableCloseOnSelect
          filterSelectedOptions
          noOptionsText="All Farms Selected"
          open={farmSelectionOpen}
          onOpen={() => setFarmSelectionOpen(true)}
          onClose={() => setFarmSelectionOpen(false)}
          disabled={pdfLoading}
          options={farms}
          value={selectedFarms}
          getOptionLabel={(option) => `Farm: ${option}`}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
            />
          )}
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option}
              {...getTagProps({ index })}
            />
          ))}
          onChange={(event, value) => handleFarmChange(value, tracts, clus)}
        />
      </Box>

      <Box p={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            Select Tracts
          </Box>
          <IconButton
            color="primary"
            onClick={selectAllTracts}
          >
            <DoneAllIcon fontSize="small" />
            <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Select All</span>
          </IconButton>
        </Box>
        <Autocomplete
          className={classes.select}
          multiple
          disableClearable
          disableCloseOnSelect
          filterSelectedOptions
          noOptionsText="All Tracts Selected"
          open={tractSelectionOpen}
          onOpen={() => setTractSelectionOpen(true)}
          onClose={() => setTractSelectionOpen(false)}
          disabled={pdfLoading}
          options={selectedFarmsTracts}
          groupBy={(option) => option.farmName}
          value={selectedTracts}
          getOptionLabel={(option) => `${option.farmName} - ${option.tractName}`}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
            />
          )}
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={`${option.farmNumber} - ${option.tractNumber}`}
              {...getTagProps({ index })}
            />
          ))}
          onChange={(event, value) => handleTractChange(value, clus)}
        />
      </Box>

      <Box p={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            Select CLUs
          </Box>
          <IconButton
            color="primary"
            onClick={selectAllClus}
          >
            <DoneAllIcon fontSize="small" />
            <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Select All</span>
          </IconButton>
        </Box>
        <Autocomplete
          className={classes.select}
          multiple
          disableClearable
          disableCloseOnSelect
          filterSelectedOptions
          noOptionsText="All CLUs Selected"
          open={cluSelectionOpen}
          onOpen={() => setCluSelectionOpen(true)}
          onClose={() => setCluSelectionOpen(false)}
          options={selectedTractsClus}
          disabled={pdfLoading}
          groupBy={(option) => option.tractName}
          value={selectedClus}
          getOptionLabel={(option) => `${option.farmName} - ${option.tractName} - CLU: ${option.cluNumber}`}
          renderOption={(option) => (
            <span
              style={{
                color: pdfGeneratedFor.includes(option.cluIdentifier) ? textSecondary : textPrimary,
                fontStyle: pdfGeneratedFor.includes(option.cluIdentifier) ? 'italic' : 'normal',
              }}
            >
              {`${option.farmName} - ${option.tractName} - CLU: ${option.cluNumber}`}
            </span>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
            />
          )}
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={`${option.farmNumber} - ${option.tractNumber} - ${option.cluNumber}`}
              {...getTagProps({ index })}
            />
          ))}
          onChange={(event, value) => handleCluChange(value)}
        />
        <Box p={0.5} fontWeight={400} fontSize="0.9rem" maxWidth={400}>
          *CLUs included in PDFs generated this session will appear&nbsp;
          <span style={{ color: textSecondary, fontWeight: 500 }}><em>grey italic&nbsp;</em></span>
          in the selection menu.
        </Box>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        className={classes.paper}
        boxShadow={2}
      >
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpen(false)}
        />

        <Box p={1} borderBottom={1}>
          <Typography align="center" variant="h6">
            Generate PDF
          </Typography>
        </Box>

        <Box display="flex" flexGrow={1}>
          { cluSelection() }

          <Box p={1} display="flex" alignItems="center" flexDirection="column">
            <Box p={1}>
              <Box className={classes.label}>Report Name</Box>
              <TextField
                variant="outlined"
                value={reportName}
                onChange={(event) => setReportName(event.target.value)}
                disabled={pdfLoading}
                style={{ width: 340, height: 28 }}
                InputLabelProps={{
                  shrink: false,
                }}
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
              />
            </Box>
            <Box p={1}>
              <Box className={classes.label}>Notes</Box>
              <TextField
                id="pdf-notes"
                multiline
                variant="outlined"
                rows={8}
                rowsMax={12}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                disabled={pdfLoading}
                style={{ width: 340 }}
              />
            </Box>

            {cluData.length === 0 && !pdfLoading && selectedClus.length > 0
              && (
              <Box mb={1} display="flex" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={getCluImages}
                  disableElevation
                >
                  Generate
                  {pdfDownloaded ? ' Another ' : ' '}
                  PDF
                </Button>
              </Box>
              )}

            { pdfLoading && (
              <Box display="flex" alignItems="center" color={green}>
                <span>Generating Images</span>
                <LinearProgress style={{ height: 6, width: 80, marginLeft: 8 }} />
              </Box>
            )}

            { cluData.length > 0 && (
              <PDFDownloadLink
                onLoadError={console.error}
                document={(
                  <AcreageReportingPDF
                    CLUSummary={cluData}
                    clusSeen={clusSeen}
                    commoditiesSeen={commoditiesSeen}
                    commodityTypesSeen={commodityTypesSeen}
                    enqueueSnackbar={enqueueSnackbar}
                    notes={notes}
                    printedName={printedName}
                    reportName={reportName}
                    signature={signature}
                    signatureTimeStamp={signatureTimeStamp}
                    statesSeen={statesSeen}
                  />
                )}
                fileName={`${reportName}.pdf`}
                style={{ textDecoration: 'none', color: green }}
              >
                {({ loading }) => (
                  loading
                    ? <Box>Loading PDF...</Box>
                    : (
                      <>
                        <Button
                          id="pdf-download"
                          color="primary"
                          variant="outlined"
                          size="large"
                          onClick={handleDownloadClick}
                        >
                          Download PDF
                        </Button>
                        {downloadReady()}
                      </>
                    )
                )}
              </PDFDownloadLink>
            )}
          </Box>
        </Box>

        { (clu && completeBoundary) && (
          <Box
            style={{
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                right: '-5000px',
                top: '5000px',
              }}
            >
              <PdfCluMap
                clu={clu}
                completeBoundary={completeBoundary}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

CreateCARTPdf.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  reportName: PropTypes.string.isRequired,
  setReportName: PropTypes.func.isRequired,
  CLUSummary: PropTypes.arrayOf(PropTypes.array.isRequired).isRequired,
  clusSeen: PropTypes.shape().isRequired,
  commoditiesSeen: PropTypes.shape().isRequired,
  commodityTypesSeen: PropTypes.shape().isRequired,
  statesSeen: PropTypes.shape({
    stateAbbr: PropTypes.shape(),
  }).isRequired,
  pdfGeneratedFor: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPdfGeneratedFor: PropTypes.func.isRequired,
  signature: PropTypes.string,
  printedName: PropTypes.string.isRequired,
  signatureTimeStamp: PropTypes.instanceOf(Date),
};

CreateCARTPdf.defaultProps = {
  signature: null,
  signatureTimeStamp: null,
};

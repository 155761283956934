import React, { useState, Fragment, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Link,
  Checkbox,
  Divider,
  Button,
  Modal, MenuItem, Menu, FormControl, InputLabel, Select
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blackText } from '../../../../styles/colors'
import { FieldContext } from '../../../Context/FieldContext'
import * as turf from '@turf/turf';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import SaveIcon from '@material-ui/icons/Save';
import { MenuProps } from "../../../../styles/select";


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdown: {
    ...theme.hover,
    fontSize: '14px',
    marginLeft: '5px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  selections: {
    height: 40,
    minWidth: 120,
  },
}));

function getModalStyle() {
  const top = 20;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    width: 300,
    height: 'auto',
    padding: 4,
  };
}

export function Properties({ showModal, uploadedFileGeoJSON, operationType, setShowPropertyModal, combination, setCombination, handleCombinationFromShp }) {
  console.log("uploadedFileGeoJSON", uploadedFileGeoJSON)
  const classes = useStyles();
  const modalStyle = getModalStyle();
  const [uploadedFileProps, setFileProps] = useState([])
  const [selectFilePropOpen, setSelectFilePropOpen] = useState(false);
  const [selectedFileProp, setSelectedFileProp] = useState('')
  const [operationProperties, setOperationProperties] = useState([])
  const [selectOperationPropOpen, setSelectOperationPropOpen] = useState(false);
  const [selectedOperationProp, setSelectedOperationProp] = useState('')



  const seedingProperties = [{ name: "Applied Rate", value: "rate" }, { name: "Variety", value: "variety" }, { name: "Crop", value: "productName" }, { name: 'Date', value: 'date' },
  { name: 'Price', value: 'price' }, { name: 'Unit', value: 'unit' }]
  const harvestProperties = [{ name: "Yield", value: "rate" }, { name: "Variety", value: "variety" }, { name: "Crop", value: "productName" }, { name: 'Date', value: 'date' },
  { name: 'Price', value: 'price' }, { name: 'Unit', value: 'unit' }]

  const applicationProperties = [{ name: "Name", value: "name" }, { name: "Applied Rate", value: "rate" }, { name: "Unit", value: "unit" }]

  useEffect(() => {
    if (uploadedFileGeoJSON.varieties.length > 0) {
      let keys = Object.keys(uploadedFileGeoJSON.varieties[0])
      let keysList = [];

      for (var i = 0; i < keys.length; i++) {
        var obj = {
          name: keys[i],
          value: keys[i]
        }
        keysList.push(obj)
      }
      setFileProps(keysList)
      setSelectedFileProp(keysList[0])
    }
  }, [uploadedFileGeoJSON])

  useEffect(() => {

    if (operationType.toUpperCase() === "SEEDING") {
      setOperationProperties(seedingProperties)
      setSelectedOperationProp(seedingProperties[0])
    }
    else if (operationType.toUpperCase() === "HARVEST") {
      setOperationProperties(harvestProperties)
      setSelectedOperationProp(harvestProperties[0])
    }
    else if (operationType.toUpperCase() === "APPLICATION") {
      setOperationProperties(applicationProperties)
      setSelectedOperationProp(applicationProperties[0])
    }
  }, [operationType])

  const handleFilePropSelect = (value, name) => {
    let exisiting = combination.filter(x=> x.operationProp === name)
    if(exisiting.length > 0){
      exisiting[0].operationProp = name
      exisiting[0].fileProp = value

      let updated = [...combination, ...exisiting]
      let uniqueCombo = [...new Set(updated)]
      console.log("uniqueCombo", uniqueCombo)

      setCombination(uniqueCombo)
    }
    else{
      setCombination([...combination, { fileProp: value, operationProp: name }])
    }
  }

  const handleOperationPropSelect = (value) => {
    for (var i = 0; i < operationProperties.length; i++) {
      if (value === operationProperties[i].value) {
        setSelectedOperationProp(operationProperties[i])
      }
    }
  }


  const handleCombination = (e) => {
    setCombination([...combination, { fileProp: selectedFileProp, operationProp: selectedOperationProp }])
  }

  const displayCombination = (x, height) => (
    <Box style={{ top: height }}>
      <Typography>
        <span style={{fontWeight: 'bold'}}>{x.fileProp.name}</span>
        matched with
        <span style={{fontWeight: 'bold'}}>{x.operationProp.name}</span>
      </Typography>
    </Box>
  )

  const handleSavedCombination = () => {
    setShowPropertyModal(false)
    handleCombinationFromShp(uploadedFileGeoJSON)
  }

  const clearCombination = (e) => {
    setCombination([])
  }

  const handleClose = () => {
    setShowPropertyModal(false)
  }

  return (
    <Box>
      <Modal
        open={showModal}
        aria-labelledby="field-claim-prompt"
        aria-describedby="proceed-field-claim-prompt"
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}
      >
        <Box
          className={classes.root}
          style={modalStyle}
          borderRadius="borderRadius"
          boxShadow={2}
          color={blackText}
          fontWeight={500}
          p={1}
        >
          <Box display="flex" justifyContent='flex-end' style={{ width: '100%' }}>
            <HighlightOffIcon
              className={classes.close}
              onClick={() => handleClose()}
            />
          </Box>
          <Box p={1}>
            Match shape file properties with operation properties
          </Box>
          <Box color={blackText}>
            {operationProperties.map((x, i) => (
              <Box
                display="flex"
                alignItems='center'
                style={{width: '100%'}}
              >
                <Box style={{width: '35%', marginBottom: 12}}>
                  {x.name}
                </Box>

                <Box style={{width: '65%'}}>
                  <Select
                    className={classes.selections}
                    variant='outlined'
                    MenuProps={MenuProps}
                  >
                    {
                      uploadedFileProps.map((y, i) => (
                        <MenuItem
                          key={i}
                          value={y.value}
                          onClick={(e) => { handleFilePropSelect(y.value, x.value)}}
                        >
                          {y.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </Box>
              </Box>
            ))}
          </Box>


          {/* <Box display="flex" style={{ width: '100%' }}>
            <Box style={{ width: '40%', margin: '10px' }}>
              <Box>
                Shapefile Properties
              </Box>
              <Select
                className={classes.selections}
                variant='outlined'
                MenuProps={MenuProps}
                value={selectedFileProp.value}
                onChange={(e) => { handleFilePropSelect(e.target.value) }}
              >
                {
                  uploadedFileProps.map((x, i) => (
                    <MenuItem
                      key={i}
                      value={x.value}
                    >
                      {x.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>

            <Box style={{ width: '40%', margin: '10px' }}>
              <Box>
                Operation Properties
              </Box>
              <Select
                className={classes.selections}
                variant='outlined'
                MenuProps={MenuProps}
                value={selectedOperationProp.value}
                onChange={(e) => { handleOperationPropSelect(e.target.value) }}
              >
                {
                  operationProperties.map((x, i) => (
                    <MenuItem
                      key={i}
                      value={x.value}
                    >
                      {x.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>

            <Box style={{ width: '20%', margin: '10px' }}>
              <Box>
                <Button
                  color='primary'
                  onClick={(e) => handleCombination(e)}
                >
                  <SaveIcon />
                </Button>
              </Box>
            </Box>
          </Box> */}

          {/* <Box display="flex" style={{ width: '100%' }}>
            <Box style={{ margin: '10px' }}>
              {
                combination.length > 0 && combination.map((x, i) => (
                  displayCombination(x, 75 + (i * 10))
                ))
              }
            </Box>
          </Box> */}

          {/* <Box my={1}
            style={{
              position: 'absolute',
              bottom: '0px',
              left: '10px'
            }}
          >
            <Button
              variant="contained"
              component="label"
              color='primary'
              onClick={(e) => clearCombination(e)}
            >
              Clear Combination
            </Button>
          </Box> */}

          <Box my={1}
            style={{
              position: 'relative',
            }}
          >
            <Button
              variant="contained"
              component="label"
              color='primary'
              onClick={(e) => handleSavedCombination(e)}
              disableElevation
            >
              Process Operation
            </Button>
          </Box>
        </Box>
      </Modal>
    </Box>
  )
}

import React, { useEffect, useState, useRef } from 'react';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { drawFieldLayer, drawImage } from './mapFunctions';
import { exists } from '../../../../utils/helpers';
import {Tiles} from '../../../../constants/Tiles'


export function InsuranceMap({ boundary, mapImage, mapExtents }) {
  const [map, setMap] = useState(null);
  const imageOverlay = useRef(null);
  const fieldLayer = L.featureGroup(null);

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      drawFieldLayer(geoJson, fieldLayer, map);
    }
  }, [boundary, map]);

  useEffect(() => {
    if (exists([map, boundary, mapImage])) {
      //console.log(mapImage)
      drawImage(imageOverlay, mapImage, boundary, mapExtents, map);
    }
  }, [mapImage, boundary, map]);

  useEffect(() => {
    if (map !== null) {
      try {
        map.fitBounds(mapExtents)
      } catch (e) {
        console.log(`problem fetting map bounds: ${e}`)
      }
    }
  }, [mapExtents])

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('insurance-profit-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current']
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }

  return (
    <div id="insurance-profit-map" style={{ height: 550, width: 550 }} />
  );
}

import React, { useState, Fragment, useEffect, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Link,
  Checkbox,
  Divider,
  Button,
  Modal,
  Tooltip,
} from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import PublishIcon from "@material-ui/icons/Publish";
import { blackText } from "../../styles/colors";
import { FieldContext } from "../Context/FieldContext";
import { UserContext } from "../Context/UserContext";
import { SpinningLoader } from '../Shared/SpinningLoader'
import * as turf from "@turf/turf";
import { getStateAndCounty, extractPDF, createNewFarm, createNewBoundary,
   createNewField, addFarmsForFields, getNewGUID } from "../../utils/dataFetchers";
import { useSnackbar } from "notistack";
const WKT = require('terraformer-wkt-parser');



const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

function getModalStyle() {
  const top = 20;
  return {
    top: `${top}%`,
    margin: "auto",
    zIndex: 1002,
    //width: 240,
    // height: 120,
    padding: 4,
  };
}

export function UploadFile({
  beginClaim,
  handleBeginFieldClaim,
  setUploadedField,
  setEditing,
  underwriting,
  organization
}) {
  const classes = useStyles();
  const modalStyle = getModalStyle();
  const { enqueueSnackbar } = useSnackbar();

  const [fieldData, setFieldData] = useContext(FieldContext);
  const [user, setUser] = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [file, setFile] = useState(null);
  const [shpFile, setShpFile] = useState(null);
  const [dbfFile, setDbfFile] = useState(null);
  const [geojson, setGeoJson] = useState(null);
  const [fieldName, setFieldName] = useState("");
  const [uint8array, setuint8array] = useState(null);
  const [btnDisabled, setBtnDisabled] = useState(true);
  const [loadingBoundaries, setLoadingBoundaries] = useState(false);
  const [message, setMessage] = useState('');

  const currentFarms = useRef([]);
  const currentFields = useRef([]);
  const newFields = useRef([]);


  async function loadShpZip(file1, shpFile1, dbfFile1) {
    let multiGeojsons = [];
    var shapefile = require("shapefile");
    if (file1 !== null) {
      await shapefile.open(shpFile1, dbfFile1, null)
        .then((source) =>source.read()
          .then(function log(result) {
            // console.log(result)
            if (result.done) return;
            if (
              result.value.geometry.type !== "Point" &&
              multiGeojsons.length <= 20
            ) {
              console.log(result)
              multiGeojsons.push(result.value);
              //console.log("multiGeojsons", multiGeojsons)
              return source.read().then(log);
            } else if (result.value.geometry.type === "Point") {
              enqueueSnackbar(
                "Cannot process point shape file, try with a different file"
              );
              return;
            }
          })
        )
        .catch((error) => {
          console.log(error)
          console.error(error.stack)
      });
      try{
        console.log(multiGeojsons)
        // key for field name and farm name
        let fieldKey = Object.keys(multiGeojsons[0].properties).filter(x => x.toUpperCase()==('FIELD') || x.toUpperCase()==('FIELD_NAME'))[0]
        let farmKey = Object.keys(multiGeojsons[0].properties).filter(x => x.toUpperCase()==('FARM') || x.toUpperCase()==('FARM_NAME'))[0]
        let farmName = multiGeojsons[0].properties[farmKey]
        // group the list of geojson into lists based off field name
        let fields = {}
        for(var i=0; i<multiGeojsons.length; i++){
          let fieldName = multiGeojsons[i].properties[fieldKey]
          if(fields[fieldName]){
            fields[fieldName].push(multiGeojsons[i])
          }
          else{
            fields[fieldName] = [multiGeojsons[i]]
          }
        }
        console.log(farmName, fields)
        // check current org if farm already exists by farmname create if not
        let orgId = organization.id;
        let foundFarm = currentFarms.current.filter(x => x.name === farmName)
        let farmID;
        let newFarm = false;
        if(foundFarm.length > 0){
          farmID = foundFarm[0].id
        }
        else{
          // farm doesnt exist, create it
          newFarm = true
          farmID = await getNewGUID("Farms")
          await createNewFarm(farmID, farmName, orgId, 'Ag-Analytics')
          let createdFarm = {name: farmName, id: farmID}
          currentFarms.current.push(createdFarm)
        }
        
        // loop over fields and create new entries if dont exist
        for(let field in fields){
          let createNew = true
          if(!newFarm){
            let fieldsInFarm = currentFields.current.filter(f => f.farmId === farmID && f.name === field)
            if(fieldsInFarm.length > 0){
              createNew = false
            }
          }
          if(createNew){
            const boundaryId = await getNewGUID('Boundaries');
            const fieldId = await getNewGUID('Fields');
            // create correct geometry
            let geometry = {}
            if(fields[field].length > 1){
              geometry['type'] = 'MultiPolygon'
              geometry['coordinates'] = fields[field].map(x => x.geometry.coordinates)
            }
            else{
              geometry = fields[field][0].geometry
            }
            const wktShape = WKT.convert(geometry);
            let centroid = turf.center(geometry);
            const centerPoints = centroid.geometry.coordinates;

            let area = turf.convertArea(turf.area(geometry), 'meters', 'acres');

            const location = await getStateAndCounty(centerPoints[1], centerPoints[0], true)

            const boundaryReq = {
              Area: area,
              AreaUnit: 'ac',
              BoundaryID: boundaryId,
              BoundaryName: '',
              FieldID: fieldId,
              IsActive: 1,
              OrganizationID: orgId,
              Source: 'Ag-Analytics',
              isDeleted: 0,
              Shape: wktShape,
            };

            await createNewBoundary(boundaryReq);

            const fieldReq = {
              BoundaryID: boundaryId,
              ClientID: 'noClient',
              FarmID: farmID,
              FieldName: field,
              FieldID: fieldId,
              OrganizationID: orgId,
              Source: 'Ag-Analytics',
              acres: area,
              boundary: wktShape,
              clu_id: boundaryId,
              isDeleted: 0,
              date_modified: new Date(),
              state: location ? parseInt(location.STATEFP) : null,
              county: location ? parseInt(location.COUNTYFP) : null
            };

            await createNewField(fieldReq);
            await addFarmsForFields(fieldId, farmID, orgId, 'Ag-Analytics')
            let newField = {farmId: farmID, farmName, name: field, id: fieldId}
            currentFields.current.push(newField)
            newFields.current.push(newField)
          }
        }
      }
      catch(e){
        console.log(e)
      }
    }
    //feature: null, coordinates: [], points: [], acres: 0
  }

  const handleChange = async (e) => {
    console.log("e.target.files[0]", e.target.files);
    console.log(fieldData)
    let fileobj = e.target.files[0];
    if (e.target.files[0].name.includes(".zip")) {
      setFile(e.target.files[0]);
      setFieldName(e.target.files[0].name);

      setBtnDisabled(false);
      let reader = new FileReader();
      reader.readAsArrayBuffer(e.target.files[0]);
      reader.onload = async (e) => {
        let JSZip = require("jszip");
        let zip = new JSZip(reader.result);
        try {
          var shpString = zip.file(/.shp$/i)[0].name;
          var dbfString = zip.file(/.dbf$/i)[0].name;

          currentFarms.current = [...fieldData.farms];
          currentFields.current = [...fieldData.fields];
          let currentFarmID = currentFields.current.length > 0 ? currentFields.current[0].farmID : null
          // lists of available shape and dbf files both should be same length         
          var shapeFiles = zip.file(/.shp$/i);
          var dbfFiles = zip.file(/.dbf$/i);
          setLoadingBoundaries(true)
          for(var i=0; i<shapeFiles.length; i++){
            var shapeFile = shapeFiles[i].asArrayBuffer();
            var dbfFile = dbfFiles[i].asArrayBuffer();
            console.log("Loading shape file from list")
            await loadShpZip(fileobj, shapeFile, dbfFile);
          }
          setFieldData({...fieldData, reload: true})
          setLoadingBoundaries(false)
          setMessage(`Your boundaries have been added to your account under the ${organization.name} organization, exit this modal to view them or continue uploading files.`)
          // setShowModal(false); //uncomment to auto close modal after upload
        } catch (err) {
          console.log(err)
          enqueueSnackbar("Couldn't find .shp or .dbf file!");
          setShowModal(false);
        }
      };
    } else {
      enqueueSnackbar("File format is wrong, upload a zip file!");
      setShowModal(false);
    }
    //loadShpZip()
  };

  const handlePDF = async (e) => {
    for (const file of e.target.files) {
      const fileName = file.name;
      if (fileName.includes(".pdf")) {
        setBtnDisabled(false);
        try {
          let formData = new FormData();
          formData.append("fileUpload", file);
          //console.log(formData)
          const response = await extractPDF(user.token, fileName, formData);

          // Make sure call succeeded
          if (response === undefined) {
            enqueueSnackbar(
              "An error occurred while uploading your file (" + fileName + ")"
            );
            console.log(
              "An error occurred while uploading the file (" +
                fileName +
                ") in UploadAgFile"
            );
          } else {
            // Notify user
            console.log(response);
            let result = await response.json();
            console.log(result);
            if (result.length > 1) {
              let pdfGeo = turf.flip(result[1][0]);
              //need to trigger field claiming process now with this geojson
              let acres = turf.convertArea(
                turf.area(pdfGeo),
                "meters",
                "acres"
              );
              pdfGeo.properties.CALCACRES = acres;
              console.log(acres);
              const centroid = turf.centroid(pdfGeo);
              const centerPoints = centroid.geometry.coordinates;
              const [state, county] = await getStateAndCounty(
                centerPoints[1],
                centerPoints[0]
              );

              let newField = {
                boundary: JSON.stringify(pdfGeo),
                state: state,
                county: county,
                latitude: centerPoints[1],
                longitude: centerPoints[0],
                id: "",
                org: "",
                farm: "",
                geometry: pdfGeo.geometry,
                name: "",
                acres: acres,
                source: "new",
                feature: pdfGeo,
                coordinates: pdfGeo.geometry,
                points: pdfGeo.geometry.coordinates,
              };
              console.log(newField);
              setFieldData({ ...fieldData, fieldToClaim: newField });
              setShowModal(false);
              setUploadedField({ feature: pdfGeo });
              beginClaim(false);
              setEditing(false);
            }
            enqueueSnackbar(
              "Your file (" + fileName + ") was successfully uploaded!"
            );
            // console.log('await response.text() :>> ', await response.text());
          }
        } catch (err) {
          enqueueSnackbar("Error");
          console.log(
            "An error occurred while uploading the file (" +
              fileName +
              "): " +
              err
          );
        }
      } else {
        enqueueSnackbar("File format is wrong, upload a zip file!");
        setShowModal(false);
      }
    }

    //loadShpZip()
  };

  const handleClose = () => {
    setShowModal(false);
    setShpFile(null);
    setDbfFile(null);
    setFieldName("");
    setBtnDisabled(true);
  };

  return (
    <Box>
      <Button
        variant="contained"
        color="primary"
        onClick={() => setShowModal(true)}
        style={{ height: "45px" }}
        disableElevation
      >
        {window.innerWidth > 480 ? "Upload " : <PublishIcon />}
        {"File"}
      </Button>

      <Modal
        open={showModal}
        onClose={() => handleClose()}
        aria-labelledby="field-claim-prompt"
        aria-describedby="procede-field-claim-prompt"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          className={classes.root}
          style={modalStyle}
          borderRadius="borderRadius"
          boxShadow={2}
        >
          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: "100%" }}
          >
            <HighlightOffIcon
              className={classes.close}
              onClick={() => handleClose()}
            />
          </Box>

          <Box
            display="flex"
            style={{ flexDirection: "column", alignItems: "center" }}
          >
            <Box px={1} fontWeight={500} fontSize={16} fontColor={blackText}>
              Upload a zipped shapefile to import your field’s boundary
            </Box>
            <Box my={1}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                disableElevation
              >
                Upload Shape File
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e)}
                  multiple
                />
              </Button>
            </Box>

            {/* <Box my={1}>
              <Button
                variant="contained"
                component="label"
                color='primary'
                onClick={() => handleClick()}
                disabled={btnDisabled}
              >
                Convert shape file to geojson
              </Button>
            </Box> */}

            <Typography>File Name: {fieldName}</Typography>
          </Box>

          {underwriting && (
            <Box
              display="flex"
              style={{ flexDirection: "column", alignItems: "center" }}
            >
              <Box px={1} fontWeight={500} fontSize={16} fontColor={blackText}>
                Upload a PDF that contains a field boundary and centroid to use.
                This feature is currently in BETA
              </Box>
              <Box my={1}>
                <Button
                  variant="contained"
                  component="label"
                  color="primary"
                  disableElevation
                >
                  Upload PDF File (BETA)
                  <input
                    type="file"
                    style={{ display: "none" }}
                    onChange={(e) => handlePDF(e)}
                    multiple
                  />
                </Button>
              </Box>

              {/* <Box my={1}>
              <Button
                variant="contained"
                component="label"
                color='primary'
                onClick={() => handleClick()}
                disabled={btnDisabled}
              >
                Convert shape file to geojson
              </Button>
            </Box> */}

              <Typography>{fieldName}</Typography>
            </Box>
          )}

          <Box style={{width: 260, textAlign: 'center', fontWeight: 500, marginTop: 10, marginBottom: 10}}>
            {message}
          </Box>
        </Box>
      </Modal>
      
      {loadingBoundaries &&
        <SpinningLoader/>
      }

    </Box>
  );
}

import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Radio,
  TextField,
  Select,
  MenuItem,
  Divider,
  Modal,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blackText, grey } from '../../../styles/colors';
import { MenuProps } from '../../../styles/select';
import { NumberFormatCustom } from '../../../utils/NumberFormatCustom';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  paper: theme.modalPaper,
  close: {
    ...theme.close,
    top: '10px',
    right: '10px',
  },
  infoIcon: theme.infoToolTip,
  input: {
    width: '150px',
    margin: '5px',
  },
  inputAI: {
    width: '260px',
    margin: '5px',
  },
  inputName: {
    height: 25
  },
  selections: {
    height: 40,
    minWidth: 120,
  },
  inputBox: {
    height: 70
  }
}));


export function AdvancedOptions({
  options,
  updateOptions,
  open,
  setOpen,
  mobileView,
  getInputAndSendRequest,
  forInsuranceAI,
  handleChange,
  types,
  type,
  practices,
  practice,
  acres,
  projectedPrice,
  approvedYield
}) {
  const classes = useStyles();

  const handleRadioChange = (event) => {
    updateOptions('trendAdjustment', event.target.value === 'yes')
  };

  const handleFeeChange = (event) => {
    updateOptions('includeAdminFee', event.target.value === 'yes')
  };

  const save = () => {
    getInputAndSendRequest()
    setOpen(false)
  }

  const cancel = () => {
    // reset values to default and close
    updateOptions('reset')
    setOpen(false)
  }

  // Body for when this component is called from WhatIf or Premium Calculator tab
  const body = () => (
    <Box
      p={1}
      fontWeight={500}
      color={blackText}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      style={{width: 400}}
    >
      <Box p={1} fontSize={18}>
        Advanced Options
      </Box>
      <Divider />

      <Box p={1}>
        <Box mt={1}>
          <Box>
            Use Trend Adjustment (TA) or Yield Exclusion (YE)
          </Box>

          <Box
            display="flex"
            alignItems="center"
          >
            <Radio
              color="primary"
              checked={options.trendAdjustment}
              onChange={handleRadioChange}
              value="yes"
              name="Trend-Adjustment"
              inputProps={{ 'aria-label': 'Trend-Adjustment-Yes' }}
            />
            <Box mx={1}>
              Yes
            </Box>

            <Radio
              color="primary"
              checked={!options.trendAdjustment}
              onChange={handleRadioChange}
              value="no"
              name="Trend-Adjustment"
              inputProps={{ 'aria-label': 'Trend-Adjustment-No' }}
            />
            <Box>
              No
            </Box>
          </Box>
        </Box>

        <Box mt={1}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Box>
              Rate Yield
            </Box>

            <CustomToolTip
              title="The producer's average APH yield, before applying Trend Adjustment or Yield Exclusion."
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.input}
            variant="outlined"
            value={options.rateYield}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onKeyUp={(e) => updateOptions('rateYield', Number(e.target.value.replaceAll(',', '')), true)}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: 'Bu./Acre',
            }}
          />
        </Box>

        <Box mt={1}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Box>
              Prevented Planting
            </Box>
            <CustomToolTip
              title="Elected coverage in the event of failure to plant an insured crop with the proper equipment by the final planting date or during the late planting period, if applicable, as defined by the RMA. Most farms select 'Standard' levels. However, producers can raise prevented planting coverage an additional 5% for most crops."
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <Select
            className={classes.selections}
            variant="outlined"
            value={options.preventedPlanting}
            onChange={(e) => updateOptions('preventedPlanting', e.target.value)}
            style={{ margin: '5px', width: '150px'}}
          >
            <MenuItem
              value="Standard"
            >
              Standard
            </MenuItem>
            <MenuItem
              value="Plus 5%"
            >
              Plus 5%
            </MenuItem>
          </Select>
        </Box>

        <Box mt={1}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Box>
              Share Percentage
            </Box>

            <CustomToolTip
              title={
                `Crop share percentage (between 0 and 1). Your insurable
                interest in the insured crop as an owner, operator, or tenant.`
              }
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.input}
            variant="outlined"
            value={options.sharePercentage}
            onChange={(e) => updateOptions('sharePercentage', e.target.value)}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
        </Box>

        <Box mt={1}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Box>
              Trend Adjustment Factor
            </Box>

            <CustomToolTip
              title={
                `County Trend Adjustment factor, as published by RMA.
                Trend-Adjusted Actual Production History (APH), if elected,
                adjusts yields in APH databases to reflect increases in yields
                through time in the county. Trend adjustments are made on each
                eligible yield within a qualifying APH database based on the
                county’s historical yield trend, which is provided in the county
                actuarial documents. The approved APH yield is calculated using
                trend-adjusted yields, as well as any other applicable yields,
                within the APH database.`
              }
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.input}
            variant="outlined"
            value={options.adjustmentFactor}
            onChange={(e) => updateOptions('adjustmentFactor', e.target.value)}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
        </Box>

        <Box mt={1}>
          <Box
            display="flex"
            alignItems="center"
          >
            <Box>
              Volatility Factor (in range [0.10, 0.40])
            </Box>

            <CustomToolTip
              title={
                `For revenue protection coverage, implied volatility as
                published by RMA. Generally this is the implied volatility of
                the option contract corresponding to the futures contract
                specified in the applicable crop’s projected price or harvest
                price definition.`
              }
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.input}
            variant="outlined"
            value={options.volatilityFactor}
            onChange={(e) => updateOptions('volatilityFactor', e.target.value)}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
        </Box>

        <Box mt={1}>
          <Box>
            Include Administrative Fee
          </Box>

          <Box
            display="flex"
            alignItems="center"
          >
            <Radio
              color="primary"
              checked={options.includeAdminFee}
              onChange={handleFeeChange}
              value="yes"
              name="Admin-Fee"
              inputProps={{ 'aria-label': 'Admin-Fee-Yes' }}
            />
            <Box mx={1}>
              Yes
            </Box>

            <Radio
              color="primary"
              checked={!options.includeAdminFee}
              onChange={handleFeeChange}
              value="no"
              name="Admin-Fee"
              inputProps={{ 'aria-label': 'Admin-Fee-No' }}
            />
            <Box>
              No
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box
          px={1}
          pt={1}
          display='flex'
          justifyContent='flex-end'
          style={{width: '100%'}}
        >
          <Button
            color='primary'
            variant='outlined'
            onClick={() => cancel()}
            style={{margin: '0 8px'}}
          >
            Reset
          </Button>

          <Button
            color='primary'
            variant='contained'
            onClick={() => save()}
            disableElevation
          >
            Recalculate Premium
          </Button>
        </Box>

      </Box>
    </Box>
  )

  // Body for when this component is called from InsuranceAI tab
  const bodyAI = () => (
    <Box
      p={1}
      fontWeight={500}
      color={blackText}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      style={{width: 400}}
    >
      <Box p={1} fontSize={18}>
        Quote Inputs
      </Box>
      <Divider />

      <Box p={1}>
        <Box mt={1} className={classes.inputBox}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputName}
          >
            <Box>
              Type
            </Box>

            <CustomToolTip
              title="Commodity covered."
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={type !== null ? type : ''}
            onChange={(e) => handleChange(e.target.value, 'type')}
            style={{ margin: '5px', width: '260px'}}
          >
            {
              types.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x}
                >
                  {x.typeName}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box mt={1} className={classes.inputBox}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputName}
          >
            <Box>
              Practice
            </Box>

            <CustomToolTip
              title="Practices covered under crop insurance. For example, Irrigation status or Organic production."
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={practice !== null ? practice : ''}
            onChange={(e) => handleChange(e.target.value, 'practice')}
            style={{ margin: '5px', width: '260px'}}
          >
            {
              practices.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x}
                >
                  {x.practiceName}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box mt={1} className={classes.inputBox}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputName}
          >
            <Box>
              Acres
            </Box>
          </Box>

          <TextField
            className={classes.inputAI}
            variant="outlined"
            value={acres}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onChange={(e) => handleChange(Number(e.target.value.replaceAll(',', '')), 'acres')}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
          />
        </Box>

        <Box mt={1} className={classes.inputBox}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputName}
          >
            <Box>
              Approved Yield
            </Box>

            <CustomToolTip
              title="Historic Yield level covered under crop insurance. These are prepopulated based on previous years for your county. If you are using Trend Adjustment (TA) or Yield Exclusion (YE), this should include the yield adjustment according to those adjustment practices. If not using these adjustments, this is your average yield over past years."
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.inputAI}
            variant="outlined"
            value={approvedYield}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onKeyUp={(e) => handleChange(Number(e.target.value.replaceAll(',', '')), 'approvedYield')}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: 'Bu./Acre',
            }}
          />
        </Box>

        <Box mt={1} className={classes.inputBox}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputName}
          >
            <Box>
              Rate Yield
            </Box>

            <CustomToolTip
              title="The producer's average APH yield, before applying Trend Adjustment or Yield Exclusion."
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.inputAI}
            variant="outlined"
            value={options.rateYield}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onKeyUp={(e) => updateOptions('rateYield', Number(e.target.value.replaceAll(',', '')), true)}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: 'Bu./Acre',
            }}
          />
        </Box>

        <Box mt={1} className={classes.inputBox}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputName}
          >
            <Box>
              Projected Price
            </Box>

            <CustomToolTip
              title="The Projected Price is the predicted price for the selected crop at the harvesting period of the crop. This field is populated for you, but you may enter your own value if desired."
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.inputAI}
            variant="outlined"
            value={projectedPrice}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onChange={(e) => handleChange(Number(e.target.value.replaceAll(',', '')), 'projectedPrice')}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: '$',
            }}
          />
        </Box>

        <Box mt={1} className={classes.inputBox}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputName}
          >
            <Box>
              Trend Adjustment Factor
            </Box>

            <CustomToolTip
              title={
                `County Trend Adjustment factor, as published by RMA.
                Trend-Adjusted Actual Production History (APH), if elected,
                adjusts yields in APH databases to reflect increases in yields
                through time in the county. Trend adjustments are made on each
                eligible yield within a qualifying APH database based on the
                county’s historical yield trend, which is provided in the county
                actuarial documents. The approved APH yield is calculated using
                trend-adjusted yields, as well as any other applicable yields,
                within the APH database.`
              }
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.inputAI}
            variant="outlined"
            value={options.adjustmentFactor}
            onChange={(e) => updateOptions('adjustmentFactor', e.target.value)}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
        </Box>

        <Box mt={1} className={classes.inputBox}>
          <Box
            display="flex"
            alignItems="center"
            className={classes.inputName}
          >
            <Box>
              Volatility Factor
            </Box>

            <CustomToolTip
              title={
                `For revenue protection coverage, implied volatility as
                published by RMA. Generally this is the implied volatility of
                the option contract corresponding to the futures contract
                specified in the applicable crop’s projected price or harvest
                price definition. Should be in range [0.10, 0.40].`
              }
              placement="right"
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>

          <TextField
            className={classes.inputAI}
            variant="outlined"
            value={options.volatilityFactor}
            onChange={(e) => updateOptions('volatilityFactor', e.target.value)}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
        </Box>

        <Box mt={1} className={classes.inputBox}>
          <Box className={classes.inputName}>
            Use Trend Adjustment (TA) or Yield Exclusion (YE)
          </Box>

          <Box
            display="flex"
            alignItems="center"
          >
            <Radio
              color="primary"
              checked={options.trendAdjustment}
              onChange={handleRadioChange}
              value="yes"
              name="Trend-Adjustment"
              inputProps={{ 'aria-label': 'Trend-Adjustment-Yes' }}
            />
            <Box mx={1}>
              Yes
            </Box>

            <Radio
              color="primary"
              checked={!options.trendAdjustment}
              onChange={handleRadioChange}
              value="no"
              name="Trend-Adjustment"
              inputProps={{ 'aria-label': 'Trend-Adjustment-No' }}
            />
            <Box>
              No
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box
          px={1}
          pt={1}
          display='flex'
          justifyContent='flex-end'
          style={{width: '100%'}}
        >
          <Button
            color='primary'
            variant='outlined'
            onClick={() => cancel()}
            style={{margin: '0 8px'}}
          >
            Reset
          </Button>

          <Button
            color='primary'
            variant='contained'
            onClick={() => save()}
            disableElevation
          >
            Recalculate Premium
          </Button>
        </Box>

      </Box>
    </Box>
  )

  return (
    mobileView ? (
      <Box
        mx={1}
        mt={2}
        borderRadius="borderRadius"
        border={1}
        borderColor={grey}
        style={{ width: '400px' }}
      >
        { forInsuranceAI === undefined ? body() : bodyAI() }
      </Box>
    ) : (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'auto'
        }}
      >
        <Box
          className={classes.paper}
          borderRadius="borderRadius"
          boxShadow={2}
          style={{
            top: '5%',
            margin: 'auto',
            zIndex: 1002,
            height: 'auto',
            width: '400px',
          }}
        >
          <HighlightOffIcon
            className={classes.close}
            onClick={() => setOpen(false)}
          />
          { forInsuranceAI === undefined ? body() : bodyAI() }
        </Box>
      </Modal>
    )
  )
}

import React from 'react';
import PropTypes from 'prop-types';

import { Box, Button, CircularProgress, Fade } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import { makeStyles } from '@material-ui/core/styles';

import { grey, blackText } from '../../../styles/colors';
import poweredBy from '../../../images/Ag-Analytics_Full_Black_Powered.svg'
import { useWindowDimensions } from '../../../utils/dimensions';
import { NavigateBeforeSharp } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  footer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: 60,
    width: '100%',
    padding: 16,
  },
  getPdf: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  saveStatus: {
    marginLeft: '8px',
    alignSelf: 'center',
  },
  fullLogo: {
    width: '250px',
    height: 'auto',
    marginRight: '20px',
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginRight: '8px',
  },
  smallLogo:{
    width: '90px',
    height: 'auto',
    marginRight: '2px'
  },
}));

/**
 * Rendered in AcreageReporing/AcreageReporting.js
 * Footer used for navigation
 * @param {Function} changeStep Performs checks necessary then changes step
 * @param {Function} checkYearForSave Saves current report data
 * @param {String} gartPath 'GART' or 'CART'
 * @param {Function} handleNewPlanting Open CreateNewOperation and set if prevented planting or not
 * @param {String} lastSaved Date and time of last save
 * @param {Bool} loading Data is loading so show Spinner
 * @param {Object} org Name and ID of chosen org
 * @param {Bool} savingReport Report is being saved
 * @param {Function} setPdfOpen Open PDF generation modal
 * @param {Function} setShowFieldSelection Show or hide Field Selection
 * @param {Bool} showFieldSelection  Determine if Field Selection should be shown
 * @param {Bool} showFooterInfo If initial load is complete and no error
 * @param {Number} step Current step user is on
 * @returns {JSX} Footer
 */
export const Footer = ({
  changeStep,
  checkYearForSave,
  gartPath,
  handleNewPlanting,
  lastSaved,
  loading,
  org,
  savingReport,
  setPdfOpen,
  setShowFieldSelection,
  showFieldSelection,
  showFooterInfo,
  step,
}) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const setupPage = 0;
  const reviewPage = 1;
  const finalizePage = 2;
  const viewPage = 9;

  // Breakpoints for changing style
  const phoneBreakpoint = 466;
  const tabletBreakpoint = 860;

  return (
    <Box
      className={classes.footer}
      // Separation as footer can blend in on setup page (borderBottom so icons don't move down)
      borderTop={(step === setupPage && showFooterInfo) ? '0.5px solid rgba(0, 0, 0, 0.2)' : ''}
      borderBottom={(step === setupPage && showFooterInfo) ? '0.5px solid white' : ''}
    >
      
      <Box
        display="flex"
        fontSize={14}
        fontWeight={600}
        color={blackText}
      >
        {/* Do not show any of these on viewPage - user can view all reports, regardless of org/farm/field */}
        { (step !== viewPage) && (
          (step > setupPage) ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => changeStep(step - 1)}
              style={{ width: width > phoneBreakpoint ? 94 : 22 }}
            >
              <ArrowBackIcon />
              {width > phoneBreakpoint ? 'Back' : ''}
            </Button>
          ) : (!showFieldSelection && showFooterInfo) ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowFieldSelection(true)}
            >
              <ArrowBackIcon />
              {width > phoneBreakpoint ? 'Field Selection' : ''}
            </Button>
          ) : (showFieldSelection && showFooterInfo) ? (
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setShowFieldSelection(false)}
            >
              {'Hide'}
              {width > tabletBreakpoint && ' Field'}
              {width > phoneBreakpoint && ' Selection'}
            </Button>
          ) : <Box 
                width={width > 1020 ? '250px' : width > 760 ? '160px' : 'auto'}
              >

              </Box>
        )}

        { (step !== viewPage && org.id !== -1 && width > tabletBreakpoint) && (
          <Box mx={2}>
            <Box fontSize={12} color={grey}>
              ORGANIZATION
            </Box>
            {org.name}
          </Box>
        )}
      </Box>

      {/* Create New Operation and Save buttons */}
      { step === setupPage && (
        <Box display="flex" height={36.5}>
          {width > phoneBreakpoint &&
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleNewPlanting()}
              disableElevation
              style={{ margin: '0 4px' }}
              disabled={loading}
            >
              <AddCircleOutlineOutlinedIcon style={{ marginRight: '8px' }} />
              {width > tabletBreakpoint ? 'Create Planting' : 'Create'}
            </Button>
          }

          {width > tabletBreakpoint &&
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleNewPlanting(true)}
              disableElevation
              style={{ margin: '0 4px' }}
              disabled={loading}
            >
              <AddCircleOutlineOutlinedIcon style={{ marginRight: '8px' }} />
              Create Prevented Planting
            </Button>
          }

          {showFooterInfo && (
            <Box display="flex" ml={width > phoneBreakpoint && "16px"}>
              <Button
                color="primary"
                variant="contained"
                onClick={() => checkYearForSave()}
                disableElevation
                disabled={loading}
              >
                Save
              </Button>
              <Box className={classes.saveStatus}>
                {savingReport ? (
                  <CircularProgress />
                ) : lastSaved === -1 ? (
                  'Error on Save. Please try again'
                ) : lastSaved === -2 ? (
                  'Data is Not Saved'
                ) : lastSaved instanceof Date ? (
                  <Box fontSize={width > phoneBreakpoint ? "12px" : "10px"}>
                    <Box>
                      Last Saved:
                    </Box>
                    <Box>
                      {lastSaved.toDateString()}
                    </Box>
                    <Box>
                      {lastSaved.toTimeString()}
                    </Box>
                  </Box>
                ) : (
                  'An error occured. Please try again later.'
                )}
              </Box>
            </Box>
          )}
        </Box>
      )}

      {/* Create PDF button */}
      {step === reviewPage && (
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setPdfOpen(true)}
        >
          Create PDF
        </Button>
      )}
      
      <Box display="flex" alignItems="center">
        {width > 760 &&
          <Box>
            <img
              src={poweredBy}
              className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
              alt={"Powered By Ag-Analytics"}
            />
          </Box>
        }
        {/* Next Page button - Do not show a button if not on 'Setup' or 'Review' page */}
        {
          (step < finalizePage && showFooterInfo) ? (
            <Button
            variant="contained"
            color="primary"
            style={{minWidth: width > phoneBreakpoint ? 120 : 60 }}
            disableElevation
            onClick={() => changeStep(step + 1)}
            >
              {width > phoneBreakpoint ? (
                step === setupPage ? 
                  gartPath ? 'Review Plantings ' : 'Review CLUs '
                : 'Finalize Report '
              ) : ''}
              <ArrowForwardIcon />
            </Button>
          ) : (
            <Box>{' '}</Box>
          )
        }
      </Box>
      
    </Box>
  );
};

Footer.propTypes = {
  changeStep: PropTypes.func.isRequired,
  checkYearForSave: PropTypes.func.isRequired,
  gartPath: PropTypes.bool.isRequired,
  handleNewPlanting: PropTypes.func.isRequired,
  lastSaved: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(Date),
  ]).isRequired,
  loading: PropTypes.bool.isRequired,
  org: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  savingReport: PropTypes.bool.isRequired,
  setPdfOpen: PropTypes.func.isRequired,
  setShowFieldSelection: PropTypes.func.isRequired,
  showFieldSelection: PropTypes.bool.isRequired,
  showFooterInfo: PropTypes.bool.isRequired,
  step: PropTypes.number.isRequired,
};

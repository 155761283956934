import React, { useState, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Endpoints } from '../../constants/Endpoints';
import {UserContext } from '../Context/UserContext';
import {Integrate} from '../Partners/Integrate';
import {
    Box,
    Button,
    Modal,
    Tooltip,
    CircularProgress, TextField,
    Divider
  } from "@material-ui/core";
  import { UpdateSubscription } from './UpdateSubscription'
import { useStaticState } from "@material-ui/pickers";
import {blackText} from "../../styles/colors";
import * as df from "../../utils/dataFetchers"



function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: "auto",
    zIndex: 1200,
    // height: '100%',
    // width: '100%',
  };
}

const useStyles = makeStyles((theme) => ({
    head: theme.tableHeader,
    headText: theme.tableHeaderText,
    labels: {
      display: "flex",
      padding: "6px 16px",
      alignItems: "center",
      fontWeight: 600,
      color: theme.palette.text.black,
    },
    tableRow: {
      display: "flex",
      flexGrow: 1,
      padding: "8px",
      fontWeight: 500,
      color: theme.palette.text.black,
    },
    icon: theme.plIcon,
    add: {
      ...theme.addOperation,
      width: "200px",
    },
    hidden: theme.hiddenOperation,    
    addBackground: {
        display: 'inline-block',
        padding: '.35rem .58rem',
        fontSize: '.9rem',
        whiteSpace: 'nowrap'
    },
    paper: {
      position: "absolute",
      width: "80vw",
      height: "85vh",
      backgroundColor: theme.palette.greys.light,
      overflow: "auto",
    },
    typography: {
      button: {
        textTransform: "none",
      },
    },
    select: {
      padding: "10px",
      borderRadius: "5px",
      "&:hover": {
        cursor: "pointer",
      },
    },
    sync: {
      ...theme.greenHover,
      fontSize: 18,
      marginLeft: "5px",
    },
    partner: {
      height: "60px",
      width: "auto",
      margin: 6,
    },
    partner2: {
      height: "80px",
      width: "auto",
      margin: 18,
    },
    connect: theme.connect,
    mobileConnect: {
      ...theme.connect,
      fontSize: 16,
      padding: 6,
      margin: 6,
    },
    logIn: {
      position: "absolute",
      width: "90vw",
      height: "90vh",
      backgroundColor: theme.palette.lightGrey,
    },
    arrows: theme.navigationArrows,
  }));
  

export function ManageSubscription({}) {

  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [authenticated, setAuthenticated] = useState(false);
  const user = useContext(UserContext)[0];
  const [subscriptionList, setSubscriptionList] = useState([])
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);
  const [open, setOpen]=useState(false)
  const [featuresList, setFeaturesList] = useState(null)
  const [customerInformation, setCustomerInfotmation] = useState(null)
  const [isLoading, setIsLoading] = useState(false)
  const [termsChecked, setTermsChecked] = useState(false)
  const [newUser, setNewUser] = useState(false);

  const [paymentMethod, setPaymentMethod] = useState(null)

  
  useEffect(() => {
    checkCustomerExists()
    //getListOfSubscriptions()
    const url = document.URL.split('?')
    if(url.length > 1){
      const extension = url[1]
      if(extension.includes("SignUp")){
        setNewUser(true)
      }
    }
  }, [])

  useEffect(() => {
    if (user.isAuthenticated !== undefined) {
      if (user.isAuthenticated) {
        setLoginPromptOpen(false);
      }
      else if((user.isAuthenticated && newUser)){
        setAuthenticated(true)
        //needs to create default org and farm here.
        handleNewUser()
      }
      else {
        setLoginPromptOpen(true);
      }
    }
  }, [user, newUser]);

  /**
   * Sends requests to backend to create Default org/client/farm
   * for a new user
   * this should only be triggered if a user where to Signup from this page
   */
  const handleNewUser = async () => {
    let newID = await df.getNewOrgID("Organizations")
    await df.createNewOrganization(newID, 'Default', 'Ag-Analytics')

    // create default client and farm for org
    await createDefaultClient(newID)
    await createDefaultFarm(newID)
    setLoginPromptOpen(false)
    checkCustomerExists()
  }

  /**
   * Send requests to create a new Client for the user
   * creates the Default client
   * @param {Int} orgId 
   */
  async function createDefaultClient(orgId){
    let newId = await df.getNewGUID("Clients")
    await df.createClient(newId, 'Default-Client', orgId, 'Ag-Analytics')
  }

  /**
   * Send requests to create a new Farm for the user
   * creates the Default farm
   * @param {Int} orgId 
   */
  async function createDefaultFarm(orgId) {
    let newId = await df.getNewGUID("Farms")
    await df.createNewFarm(newId, 'Default-Farm', orgId, 'Ag-Analytics')
  }

  /**
   * Calls the backend to check for an existing Customer record in
   * the database for the currently authenticated user
   */
  const checkCustomerExists = async () => {
    setIsLoading(true)
      try{
        let response = await fetch(Endpoints.BASEURL + Endpoints.CUSTOMER_EXISTS, {
          method: 'GET',
          credentials: 'include',
          headers: {
            Accept: "application/json",
          },
        }) 
        let jsonResponse = await response.json();
        if(jsonResponse === true){
          getCurrentCustomerInfo()
        }
        else{
          saveCustomerInfo()
          //getPlanInfo()
        }
      }
      catch(ex){
          console.log("checkCustomerExists", ex)
          setIsLoading(false)
      }
  }

  /**
   * Sends request to the backend to create a Customer for the current user
   * called by the checkCustomerExists() function if there is no Customer
   * entry in the database for the current user
   */
  const saveCustomerInfo = async () => {
    let request = {
      IsAzureRegistration: true
    }

    let response = await fetch(Endpoints.BASEURL + Endpoints.API_CUSTOMER, {
      method: 'POST',
      headers: {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
      }, credentials: 'include',
      body: JSON.stringify(request)
    })
    let jsonResponse = await response.json();
    getCurrentCustomerInfo()
  }

  /**
   * Gets the Customer information for the current user
   * triggers the getListOfSubscriptions function
   */
  const getCurrentCustomerInfo = async () => {
    try{
      let response = await fetch(Endpoints.BASEURL + Endpoints.CURRENT, {
        method: 'GET',
        credentials: 'include',
        headers: {
          Accept: "application/json",
        },
      }) 
      let jsonResponse = await response.json();
      //console.log("jsonResponse", jsonResponse)
      setCustomerInfotmation(jsonResponse)        
      getListOfSubscriptions()
    }
    catch(ex){
        console.log("getCurrentCustomerInfo", ex)
    }    
  }

  /**
   * Main function for getting data used in the ManageSubscription and UpdateSubscription components
   * Gets the current users subscriptions from the backend and then triggers the request to get all of
   * the subscriptions features, formatting the data into an array of subscription objects
   */
  const getListOfSubscriptions = async () => {
    try{
      let res = await fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION_BY_CUSTOMER, {
        method: 'GET',
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("jwtAccessToken") 
          }, credentials: 'include'
        })
      let jsonResponse = await res.json();
      // get features for subscriptions
      let features = await getSubscriptionFeatures(jsonResponse)
      let subscriptions = jsonResponse
      // get features associated with each subscription and add to subscription object
      for(var i=0; i<subscriptions.length; i++){
        let feats = features.filter(f => f.subscription.subscriptionIdentifier === subscriptions[i].subscriptionIdentifier)
        if(feats.length > 0){
          subscriptions[i]['features'] = feats
        }
      }
      setIsLoading(false)
      setSubscriptionList(subscriptions)
    }
    catch (err){
      console.log("get list of subscriptions fail", err)
    }
  }

  /**
   * Sends a request to the backend to get all of the subscription features
   * associated with subscriptions in the provided Array
   * @param {Array} subscriptions array of subscription objects returned from the fetch made in teh getListOfSubscriptions() function
   * @returns an array of subscription features or an empty array
   */
  const getSubscriptionFeatures = async (subscriptions) => {
    //construct id list for request
    let ids = []
    subscriptions.forEach(s => {
      if(s.subscriptionIdentifier !== null && s.subscriptionIdentifier !== undefined){
        ids.push(s.subscriptionIdentifier)
      }
    })

    let url = `${Endpoints.BASEURL}${Endpoints.API_GET_MULTIPLE_SUBSCRIPTION_FEATURES}`

    try{
      let res = await fetch(url, {
        method: "POST",
        credentials: 'include',
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json"
        },
        body: JSON.stringify({ids: ids})
      })
      let features = await res.json()
      
      if(features.data.length > 0){
        return features.data
      }
      else{
        return []
      }
    }
    catch(err){
      console.log(err)
    }

  }

  /**
   * *** DEPRICATED ***
   * THE FUNCTIONALITY FOR THIS FUNCTION HAS BEEN REPLACED BY THE getSubscriptionFeatures() FUNCTION
   * 
   * Function used for getting details about the selected subscription
   * @param {*} e 
   * @param {*} sub 
   */
  const getFeaturesList = async (e, sub) => {
    //console.log("sub", sub)
    try{
      let res = await fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION_FEATURE_BY_SUBSCRIPTION + "/" + sub.subscriptionIdentifier, {
        method: 'GET',
          headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("jwtAccessToken") 
          }, credentials: 'include'
        })
      let jsonResponse = await res.json();
      //console.log("getFeaturesList", jsonResponse)
      for(var i=0;i<jsonResponse.data.length;i++){
          if(sub.subscriptionIdentifier === jsonResponse.data[i].subscription.subscriptionIdentifier){
            setFeaturesList([jsonResponse.data[i]])
          }
      }
      setOpen(true)
    }
    catch(err){
      console.log("getFeaturesList error", err)
    }
  }

  /**
   * Sends the request to the backend to get the payment method associated with a subscription from braintree
   * @param {Object} sub subscription object from the subscriptionList state variable
   */
  const getPaymentDetails = async (sub) => {
    let payment = await df.getSubscriptionPaymentMethod(sub.subscriptionIdentifier)
    setPaymentMethod(payment)
  }

  /**
   * Triggers request to backend to get the payment method associated with a subscription and sets the 
   * FeaturesList state variable for the currently selected subscription
   * Function triggered by user clicking the 'View' link for a subscription
   * @param {Object} sub subscription object from the subscriptionList state variable
   */
  const getSubscriptionDetails = (sub) => {
    setFeaturesList(sub?.features)
    setOpen(true)
    getPaymentDetails(sub)
  }

  /**
   * Sends request to unsubscribe user from the currently selected subscription
   * this is passed down to the UpdateSubscription component for use by it's
   * Unsubscribe button
   */
  const handleUnsubscription = async () => {
    //setIsLoading(true)
    let request={
      ApiKey: customerInformation.apiKey,
      Balance: featuresList[0].subscription.balance,
      BillingDayOfMonth: featuresList[0].subscription.billingDayOfMonth,
      BillingPeriodEndDate: featuresList[0].subscription.billingPeriodEndDate,
      BillingPeriodStartDate: featuresList[0].subscription.billingPeriodStartDate,
      BraintreeCreatedOn: featuresList[0].subscription.braintreeCreatedOn,
      Features: [],
      FirstBillingDate: featuresList[0].subscription.firstBillingDate,
      NeverExpires: featuresList[0].subscription.neverExpires,
      NumberOfBillingCycles: featuresList[0].subscription.numberOfBillingCycles,
      PaymentMethodNonce: null,
      PlanId: featuresList[0].subscription.planId,
      Price: featuresList[0].subscription.price,
      SubscriptionIdentifier: featuresList[0].subscription.subscriptionIdentifier,
      SubscriptionStatusId: featuresList[0].subscription.subscriptionStatusId,
      TrialDuration: featuresList[0].subscription.trialDuration,
      TrialDurationUnit: featuresList[0].subscription.trialDurationUnit,
      TrialPeriod: featuresList[0].subscription.trialPeriod,
    }

    try{
      let response = await fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION, {
        method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Content-Type': 'application/json',
            }, credentials: 'include',
            body: JSON.stringify(request)
      })
      let jsonResponse = await response.json();
      if(jsonResponse.isSuccessful){
        getListOfSubscriptions()
      }
    }
    catch(err){
      console.log("handleUnsubscription", err)
    }

    setOpen(false)
  }

  /**
   * Determines which status badge to display based on the subscriptions status
   * @param {String} status the subscriptionStatus.subscriptionStatusName value from an item in the subscriptionList
   * @returns JSX
   */
  const fnGetSubscriptionStatus = (status) => {
    switch (status.toUpperCase()) {
      case 'ACTIVE':
        return ([
          <span style={{color: 'white', backgroundColor:'#5cb85c'}} className={classes.addBackground} >{status}</span>
        ]);
      case 'CANCELED':
        return ([
          <span style={{color: 'white', backgroundColor:'red'}} className={classes.addBackground}>{status}</span>
        ]);
        case 'EXPIRED':
        return ([
          <span style={{color: 'white', backgroundColor:'red'}} className={classes.addBackground}>{status}</span>
        ]);
        case 'PAST DUE':
        return ([
          <span style={{color: 'white', backgroundColor:'orange'}} className={classes.addBackground}>{status}</span>
        ]);
        case 'PENDING':
        return ([
          <span style={{color: 'white', backgroundColor:'blue'}} className={classes.addBackground}>{status}</span>
        ]); 
    }
  }

  /**
   * Send user to signin route
   */
  const handleLogin = () => window.location.assign(Endpoints.BASEURL + Endpoints.SIGNINSUBSCRIPTION);

  /**
   * Send user to signup route
   */
  const handleSignUp = () => window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUPSUBSCRIPTION);

  /**
   * Creates the login/integration modal to be displayed if a user is not currently authenticated
   * @returns JSX
   */
  const showIntegrate = () => {

    return (
      <Modal
        open={loginPromptOpen}
        //onClose={() => setLoginPromptOpen(false)}
        aria-labelledby="log-in-sign-up"
        aria-describedby="log-in-sign-up-prompt"
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <Box
          style={modalStyle}
          className={classes.paper}
          borderRadius="borderRadius"
          boxShadow={2}
        >
          <Box
            p={2}
            display="flex"
            style={{ width: "100%" }}
          >
            <Box>&nbsp;</Box>

            <Box fontWeight={500} fontSize={20} color={blackText}>
              Sign in and Connect Precision Ag.
            </Box>

            {/* <CancelOutlinedIcon
              className={classes.edit}
              onClick={() => setLoginPromptOpen(false)}
            /> */}
          </Box>

          <Divider />
          
            <Box
              display="flex"
              justifyContent="center"
              style={{ width: "100%" }}
              flexWrap="wrap"
            >
              <Button
                color="primary"
                variant="contained"
                onClick={() => handleLogin()}
                style={{ margin: "8px 16px", fontSize: 20, width: "220px" }}
              >
                SIGN IN
              </Button>

              <Button
                color="primary"
                variant="contained"
                onClick={() => handleSignUp()}
                style={{ margin: "8px 16px", fontSize: 20 }}
              >
                CREATE AN ACCOUNT
              </Button>
            </Box>

          <Integrate setChecked={setTermsChecked}/>

        </Box>
      </Modal>
    );
  };

  /**
   * Function to create a JSX row object displayed in the ManageSubscription table
   * @param {Object} sub subscription object from the subscriptionList state variable
   * @param {Int} i index value for the subscription object in subscriptionList 
   * @returns JSX
   */
  const createRow = (sub, i) => {
    // determine organization name to display (displays N/A if missing)
    let orgName = 'N/A'
    if(sub.features){
      orgName = sub.features[0].customerOrganization.organizationName
    }
    // determine if we should display option to view/edit features based on subscription status
    let allowUpdates = true;
    if(['CANCELED', 'PENDING', 'EXPIRED'].includes(sub.subscriptionStatus.subscriptionStatusName.toUpperCase())){
      allowUpdates = false;
    }
    return(
      <Box display="flex" key={i} className={classes.tableRow} style={{ backgroundColor: i % 2 == 0 ? "#f3f3f3" : "#ffffff" }} >
        <Box px={1} style={{ width: "20%" }}>{sub.orderNumber}</Box>
        <Box px={1} style={{ width: "20%" }}>{orgName}</Box>
        <Box px={1} style={{ width: "20%" }}>
          {allowUpdates &&
            <a onClick={() => getSubscriptionDetails(sub)} style={{ cursor: 'pointer', textDecoration : 'underline'}}>View</a>
          }
        </Box>
        <Box px={1} style={{ width: "20%" }}>{sub.billingPeriodStartDate !== null ? sub.billingPeriodStartDate.substring(0, 10) : ""}</Box>
        <Box px={1} style={{ width: "20%" }}>{sub.billingPeriodEndDate != null ? sub.billingPeriodEndDate.substring(0, 10) : ""}</Box>
        <Box px={1} style={{ width: "20%" }}>{fnGetSubscriptionStatus(sub.subscriptionStatus.subscriptionStatusName)}</Box>
      </Box>
    )
  }
  
  return(
    <Box p={1} px={2} style={{ width: "100%" }}>
      {isLoading && (<CircularProgress
                style={{
                  position: 'absolute',
                  right: '50%',
                  top: '50%',
                  zIndex: 1005,
                  color: '#1e88e5',
                }}
      />)}
      
      <Box style={{fontSize: '2em', textAlign: 'justify'}}>
        Find the list of your subscriptions below. Manage your subscriptions by clicking on order number
      </Box>
      <br/>
      <Box display="flex" className={classes.labels}>
        <Box px={1} style={{ width: "20%" }}>
          Order Number
        </Box>
        <Box px={1} style={{ width: "20%" }}>
          Organization
        </Box>
        <Box px={1} style={{ width: "20%" }}>
          Features
        </Box>
        <Box px={1} style={{ width: "20%" }}>
          Billing Start Date
        </Box>
        <Box px={1} style={{ width: "20%" }}>
          Billing End Date
        </Box>
        <Box px={1} style={{ width: "20%" }}>
          Status
        </Box>
      </Box>
      {showIntegrate()}
      {subscriptionList.map((op, i) => createRow(op, i))}
      <Modal
        open={open}
        aria-labelledby="update-subscription"
        aria-describedby="update-subscription"
      >
        <div>
          <UpdateSubscription
            setOpen={setOpen}
            featuresList={featuresList}
            customerInformation={customerInformation}
            handleUnsubscription={handleUnsubscription}
            paymentMethod={paymentMethod}
            clearPaymentMethod={setPaymentMethod}
            getPaymentDetails={getPaymentDetails}
          />
        </div>
      </Modal>
    </Box>
  )
}
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Page, Text, View, Document, StyleSheet, Font, Image, Link, Svg, Circle,
} from '@react-pdf/renderer';

// Images
import logo from '../../../images/AgAnalyticsLogo_Black_Inline_Lower_Powered1.png';

// Function and data display
import { formatDateObject } from '../../../utils/helpers';
import { allCLUInfo, getValueForPdfDisplay } from '../Functionality/reviewCLUsDisplay';
import * as preset from '../presetData';

// Text style
import { textPrimary, grey } from '../../../styles/colors';
import RobotoRegular from '../../../assets/fonts/Roboto-Regular.ttf';
import RobotoBold from '../../../assets/fonts/Roboto-Bold.ttf';
import RobotoBlack from '../../../assets/fonts/Roboto-Black.ttf';
import { zoneColors } from '../../Maps/Styles/layerStyles';


Font.register({
  family: 'Roboto',
  fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBlack, fontWeight: 900 },
  ],
  format: 'truetype',
});

const styles = StyleSheet.create({
  body: {
    paddingBottom: 65,
    fontFamily: 'Roboto',
    color: textPrimary,
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    backgroundColor: '#f3f3f3',
    padding: 8,
  },
  plLogo: {
    height: 'auto',
    width: '150px',
  },
  cluSection: {
    borderBottom: `2px solid ${grey}`,
    margin: 4,
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footerLogo: {
    position: 'absolute',
    bottom: 30,
    left: 20,
    width: '91px',
    height: 'auto',
  },
  footerLink: {
    position: 'absolute',
    bottom: 35,
    left: 130,
    fontSize: 8,
  },
  cluHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    padding: 6,
    backgroundColor: 'rgba(25, 104, 49, 0.14)',
  },
  cluHeaderRow: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    width: '100%',
  },
  cluHeaderText: {
    fontSize: 12,
    fontWeight: 500,
  },
  subFieldHeader: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 2,
    borderBottom: `1px solid ${grey}`,
  },
  displayInfo: {
    display: 'flex',
    flexDirection: 'column',
    margin: 4,
  },
  textInfo: {
    display: 'flex',
    padding: 6,
    borderRadius: 4,
    border: `1px solid ${grey}`,
  },
  explanationView: {
    display: 'flex',
    flexDirection: 'row',
    padding: 6,
    maxWidth: 380,
    borderRadius: 4,
    border: `1px solid ${grey}`,
  },
  label: {
    fontSize: 10,
    color: textPrimary,
  },
  text: {
    fontSize: 10,
    color: textPrimary,
  },
  consentText: {
    fontSize: 12,
    color: textPrimary,
    marginBottom: 6,
  },
});

/**
 * Creates and formats a PDF for displaying selected fields and all important info for user
 * @param {Array} CLUSummary Summary of CLUs for 'CART'
 * @param {Object} clusSeen Data relating to clus user has viewed
 * @param {Object} commoditiesSeen Data relating to clus user has viewed
 * @param {Object} commodityTypesSeen Data relating to commodityTypes user has viewed
 * @param {Function} enqueueSnackbar To give a message to user
 * @param {String} notes Notes by user to included in PDF
 * @param {Array} operationSummary Summary of Operations for 'GART'
 * @param {String} printedName User name in plaint text for use along with digital signature
 * @param {String} reportName Name report will be associated with
 * @param {String} signature Base64 PNG of digital signature to include in report, if present
 * @param {Date} signatureTimeStamp Date signature was made
 * @param {Object} statesSeen Data relating to states user has viewed
 * @returns Formatted PDF
 */
export const AcreageReportingPDF = ({
  CLUSummary,
  clusSeen,
  commoditiesSeen,
  commodityTypesSeen,
  enqueueSnackbar,
  notes,
  operationSummary,
  printedName,
  reportName,
  signature,
  signatureTimeStamp,
  statesSeen,
}) => {

  // Var of mappings to have the same code for all cvt types
  const seenCVTElements = {
    crop: statesSeen,
    cropType: commoditiesSeen,
    intendedUse: commodityTypesSeen,
  };

  const displayInfo = (entry, gartPath) => {
    try {
      // Get display values from codes
      const displayValues = {};
      allCLUInfo.forEach((info, i) => {
        // Skip CLU item if 'GART' zoneType
        if (gartPath && info.source === 'cluProducerReviewRequestIndicator') { return; }

        const text = getValueForPdfDisplay(
          info,
          entry,
          seenCVTElements,
          clusSeen,
          handleChangeReason,
          enqueueSnackbar,
        );
        displayValues[info.source] = text;
      });

      return (
        <View>
          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { displayValues?.finalPlantedDate && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Planting Date
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.finalPlantedDate}
                  </Text>
                </View>
              </View>
            )}

            { displayValues?.finalReportedAcreage && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Acres
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {Math.round((displayValues.finalReportedAcreage + Number.EPSILON) * 100) / 100}
                  </Text>
                </View>
              </View>
            )}

            { displayValues?.crop && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Crop
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.crop}
                  </Text>
                </View>
              </View>
            )}

            { displayValues?.cropType && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Crop Type
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.cropType}
                  </Text>
                </View>
              </View>
            )}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { displayValues?.croppingPracticeCode && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Crop Practice
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.croppingPracticeCode}
                  </Text>
                </View>
              </View>
            )}

            { displayValues?.productPlantingCode && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Planting Code
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.productPlantingCode}
                  </Text>
                </View>
              </View>
            )}

            { displayValues?.intendedUse && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Intended Use
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.intendedUse}
                  </Text>
                </View>
              </View>
            )}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { displayValues?.organicPracticeTypeCode && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Irrigation Status
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.irrigationPracticeCode}
                  </Text>
                </View>
              </View>
            )}
            { displayValues?.organicPracticeTypeCode && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Organic Status
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.organicPracticeTypeCode}
                  </Text>
                </View>
              </View>
            )}

            { displayValues?.sharePercentage && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Share %
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.sharePercentage}
                  </Text>
                </View>
              </View>
            )}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { (displayValues?.skipRowPatternCode && displayValues.skipRowPatternCode !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Skip Row Pattern Code
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.skipRowPatternCode}
                  </Text>
                </View>
              </View>
            )}

            { (displayValues?.skipRowConversionFactor && displayValues.skipRowConversionFactor !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Skip Row Conversion Factor
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.skipRowConversionFactor}
                  </Text>
                </View>
              </View>
            )}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { (displayValues?.skipRowCount && displayValues.skipRowCount !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Skip Row Count
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.skipRowCount}
                  </Text>
                </View>
              </View>
            )}

            { (displayValues?.skipRowWidth && displayValues.skipRowWidth !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Skip Row Width
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.skipRowWidth}
                  </Text>
                </View>
              </View>
            )}

            { (displayValues?.cropRowCount && displayValues.cropRowCount !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Crop Row Count
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.cropRowCount}
                  </Text>
                </View>
              </View>
            )}

            { (displayValues?.cropRowWidth && displayValues.cropRowWidth !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Crop Row Width
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.cropRowWidth}
                  </Text>
                </View>
              </View>
            )}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { displayValues?.cluProducerReviewRequestIndicator && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  FSA Review Requested
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.cluProducerReviewRequestIndicator}
                  </Text>
                </View>
              </View>
            )}

            { displayValues?.micsCode && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  MICS Code
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.micsCode}
                  </Text>
                </View>
              </View>
            )}

            { displayValues?.micsName && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  MICS Name
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.micsName}
                  </Text>
                </View>
              </View>
            )}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { (displayValues?.reportedAcreageModifiedReasonCode && displayValues?.reportedAcreageModifiedReasonCode !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Acreage Modified Reason
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.reportedAcreageModifiedReasonCode}
                  </Text>
                </View>
              </View>
            )}

            { (displayValues?.reportedAcreageModifiedOtherReasonText && displayValues?.reportedAcreageModifiedOtherReasonText !== 'N/A') && (
              <View style={{ maxWidth: 200 }}>
                <View style={styles.displayInfo}>
                  <Text style={styles.label}>
                    Acreage Modified Explanation
                  </Text>
                  <View style={styles.explanationView}>
                    <Text style={styles.text}>
                      {displayValues.reportedAcreageModifiedOtherReasonText}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { (displayValues?.geospatialShapeModifiedReasonCode && displayValues?.geospatialShapeModifiedReasonCode !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Geospatial Shape Modified Reason
                </Text>
                <View style={styles.textInfo}>
                  <Text style={styles.text}>
                    {displayValues.geospatialShapeModifiedReasonCode}
                  </Text>
                </View>
              </View>
            )}

            { (displayValues?.geospatialShapeModifiedOtherReasonText && displayValues?.geospatialShapeModifiedOtherReasonText !== 'N/A') && (
              <View style={{ width: 200 }}>
                <View style={styles.displayInfo}>
                  <Text style={styles.label}>
                    Geospatial Shape Explanation
                  </Text>
                  <View style={styles.explanationView}>
                    <Text style={styles.text}>
                      {displayValues.geospatialShapeModifiedOtherReasonText}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>

          <View style={{ display: 'flex', flexDirection: 'row' }}>
            { (displayValues?.plantedDateModifiedReasonCode && displayValues?.plantedDateModifiedReasonCode !== 'N/A') && (
              <View style={styles.displayInfo}>
                <Text style={styles.label}>
                  Planting Date Modified Reason
                </Text>
                <View style={styles.explanationView}>
                  <Text style={styles.text}>
                    {displayValues.plantedDateModifiedReasonCode}
                  </Text>
                </View>
              </View>
            )}

            { (displayValues?.plantedDateModifiedOtherReasonText && displayValues?.plantedDateModifiedOtherReasonText !== 'N/A') && (
              <View style={{ width: 200 }}>
                <View style={styles.displayInfo}>
                  <Text style={styles.label}>
                    Planting Date Modified Explanation
                  </Text>
                  <View style={styles.explanationView}>
                    <Text style={styles.text}>
                      {displayValues.plantedDateModifiedOtherReasonText}
                    </Text>
                  </View>
                </View>
              </View>
            )}
          </View>
        </View>
      );
    } catch (err) {
      console.error(err);
      return (
        <View>
          <Text>Problem Getting Data</Text>
        </View>
      );
    }
  };

  // Display header, color icon for zone on map, and substructure info such as crop, acres, etc
  const displaySubstructure = (entry, entryIndex, gartPath = false) => (
    <View key={entryIndex} wrap={false}>
      <View style={styles.subFieldHeader}>
        <Svg width="14px" height="14px">
          <Circle
            cx="5"
            cy="5"
            r="4"
            fill={zoneColors[entryIndex]}
            stroke={zoneColors[entryIndex]}
          />
        </Svg>

        {gartPath ?
          <Text>{`Planting ${entryIndex+1}`}</Text>
        :  
          <Text>{`Subfield: ${entry.subfieldNumber}`}</Text>
        }
      </View>
      {displayInfo(entry, gartPath)}
    </View>
  );

  // Displays header for CLU, image, and calls displaySubstructure to display subfields
  const cluDisplay = (cluInfo, i) => {
    // All CLUs copies have the same info so just use the first copy each time
    const cluRef = clusSeen[cluInfo[0].clu_identifier];

    return (
      <View key={i} style={styles.cluSection} wrap={false}>
        <View style={styles.cluHeader}>
          <View>
            <Text style={styles.cluHeaderText}>
              {`Farm # ${cluRef.farm_number} | Tract # ${cluRef.tract_number} | CLU # ${cluRef.clu_number}`}
            </Text>
            <Text style={styles.cluHeaderText}>
              {cluInfo[0].clu_identifier}
            </Text>
          </View>
          <View>
            <Text style={styles.cluHeaderText}>
              {`Admin State: ${cluRef.admin_state}`}
            </Text>
            <Text style={styles.cluHeaderText}>
              {`Admin County: ${cluRef.admin_county}`}
            </Text>
          </View>
        </View>

        <View style={{
          display: 'flex', flexDirection: 'row', width: '100%',
        }}
        >
          <View style={{
            display: 'flex', width: '33%', padding: '4px', marginTop: '12px',
          }}
          >
            <Image
              src={cluInfo[0].image}
              style={{ height: 'auto', width: '100%' }}
            />
          </View>

          <View style={{ display: 'flex', width: '67%' }}>
            {cluInfo.map((cluEntry, entryIndex) => displaySubstructure(cluEntry, entryIndex))}
          </View>
        </View>
      </View>
    );
  };

  // Displays header for Field, image, and calls displaySubstructure to display operations
  const fieldDisplay = (opInfo, i) => {
    // All operations have the same general info so just use the first copy each time
    const opRef = opInfo[0];

    return (
      <View key={i} style={styles.cluSection} wrap={false}>
        <View style={styles.cluHeader}>
          <View>
            <Text style={styles.cluHeaderText}>
              {`Farm: ${opRef.farmName} | Field: ${opRef.fieldName}`}
            </Text>
          </View>
          <View>
            <Text style={styles.cluHeaderText}>
              {`State Code: ${opRef.stateCode}`}
            </Text>
            <Text style={styles.cluHeaderText}>
              {`County Code: ${opRef.countyCode}`}
            </Text>
          </View>
        </View>

        <View style={{
          display: 'flex', flexDirection: 'row', width: '100%',
        }}
        >
          <View style={{
            display: 'flex', width: '33%', padding: '4px', marginTop: '12px',
          }}
          >
            <Image
              src={opInfo[0].image}
              style={{ height: 'auto', width: '100%' }}
            />
          </View>

          <View style={{ display: 'flex', width: '67%' }}>
            {opInfo.map((opEntry, entryIndex) => displaySubstructure(opEntry, entryIndex, true))}
          </View>
        </View>
      </View>
    );
  };

  const digitalSignature = () => (
    <View
      wrap={false}
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexGrow: 1,
        padding: 16,
        marginTop: 8,
      }}
    >
      <View style={{ flexDirection: 'column', flexGrow: 1, justifyContent: 'space-between' }}>
        <View>
          <Text style={{
            color: textPrimary, fontSize: 12, fontWeight: 500, marginBottom: 6,
          }}
          >
            By digitally signing, you consent to all of the below:
          </Text>
          <Text style={styles.consentText}>
            You are accessing a U.S. Government information system, which includes (1) this computer
            network, (2) all computers connected to this network, and (3) all devices and storage
            media attached to this network or to a computer on this network. This information system
            is provided for U.S. Government-authorized use only.
          </Text>
          <Text style={styles.consentText}>
            Unauthorized or improper use of this system may result in disciplinary action,
            as well as civil and criminal penalties.
          </Text>
          <Text style={styles.consentText}>
            By using this information system, you understand and consent to the following:
          </Text>
          <Text style={styles.consentText}>
            You have no reasonable expectation of privacy regarding any communications or data
            transiting or stored on this information system. At any time, the government may for
            any lawful government purpose monitor, intercept, and search and seize any
            communication or data transiting or stored on this information system.
          </Text>
          <Text style={styles.consentText}>
            Any communication or data transiting or stored on this information system may be
            disclosed or used for any lawful government purpose.
          </Text>
          <Text style={styles.consentText}>
            Your consent is final and irrevocable. You may not rely on any statements or
            informal policies purporting to provide you with and expectation of privacy
            regarding communication on this system, whether oral or written, by your
            supervisor or any other official, except USDA’s Chief Information Officer.
          </Text>
        </View>

        <View>
          <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <View style={{ margin: 4 }}>
              <Text style={styles.label}>
                Signed Date
              </Text>
              <View style={{ border: `1px solid ${grey}`, padding: 8 }}>
                <Text style={{ color: textPrimary, fontSize: 12 }}>
                  {formatDateObject(signatureTimeStamp)}
                </Text>
              </View>
            </View>
          </View>

          <View style={{ flexDirection: 'row', justifyContent: 'flex-end' }}>
            <View style={{ margin: 4 }}>
              <Text style={styles.label}>
                Printed Name
              </Text>
              <View style={{ border: `1px solid ${grey}`, padding: 8 }}>
                <Text>
                  {printedName}
                </Text>
              </View>
            </View>

            <View style={{ margin: 4 }}>
              <Text style={styles.label}>
                Digital Signature
              </Text>
              <View style={{ border: `1px solid ${grey}` }}>
                <Image src={signature} style={{ height: 36, width: 'auto' }} fixed />
              </View>
            </View>
          </View>
        </View>
      </View>
    </View>
  );

  // Common code for getting the display value for an element's change reason code
  const handleChangeReason = (info, entry) => {
    const code = entry[info.source];
    const match = preset[info.link].filter((elem) => elem.code === code);

    if (match.length) {
      return match[0].display;
    }
    return '';
  };

  return (
    <Document>
      <Page wrap size="A4" style={styles.body}>
        <View style={styles.header} fixed>
          <Text
            style={{
              fontSize: 12,
              fontWeight: 800,
              color: textPrimary,
              paddingLeft: 40,
            }}
          >
            { reportName }
          </Text>
        </View>

        <View>
          {clusSeen ? 
            CLUSummary.map((cluInfo, i) => (cluDisplay(cluInfo, i)))
          :
            operationSummary.map((opInfo, i) => (fieldDisplay(opInfo, i)))
          }
        </View>

        { notes !== '' && (
          <View style={{ maxWidth: 400, padding: 8 }} wrap={false}>
            <View style={styles.displayInfo}>
              <Text style={styles.label}>
                {'Notes: '}
              </Text>
              <View style={styles.explanationView}>
                <Text style={styles.text}>
                  {notes}
                </Text>
              </View>
            </View>
          </View>
        )}

        { (signature && printedName !== '') && (
          digitalSignature()
        )}

        <Image src={logo} data-img={logo} style={styles.footerLogo} fixed />

        <Link style={styles.footerLink} href="https://profit.ag" fixed>
          profit.ag
        </Link>

        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) => (
            `${pageNumber} / ${totalPages}`
          )}
          fixed
        />
      </Page>
    </Document>
  );
};

AcreageReportingPDF.propTypes = {
  CLUSummary: PropTypes.array,
  clusSeen: PropTypes.shape(),
  commoditiesSeen: PropTypes.shape().isRequired,
  commodityTypesSeen: PropTypes.shape().isRequired,
  operationSummary: PropTypes.array,
  printedName: PropTypes.string.isRequired,
  notes: PropTypes.string.isRequired,
  reportName: PropTypes.string.isRequired,
  signature: PropTypes.string,
  signatureTimeStamp: PropTypes.instanceOf(Date),
  statesSeen: PropTypes.shape({
    stateAbbr: PropTypes.shape(),
  }).isRequired,
};

AcreageReportingPDF.defaultProps = {
  signature: undefined,
  signatureTimeStamp: null,
};

import React, { useEffect, useState, useRef } from 'react';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { drawFieldLayer, drawImage } from '../../Maps/MapFunctions/helpers';
import { drawParcels } from '../../Maps/MapFunctions/parcelDrawing';
import { exists } from '../../../utils/helpers';
import { Tiles } from '../../../constants/Tiles'
import home from '../../../images/houseIcon.png'
import dollar from '../../../images/dollarIcon.png'

export function LandValueMap({
  boundary, parcels, activeParcel, setActiveParcel, height, width, location,
}) {
  const [map, setMap] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const imageOverlay = useRef(null);
  const fieldLayer = L.featureGroup(null);
  const foundParcels = useRef([])
  const parcelLayer = useRef(L.geoJSON())
  const valueRadius = useRef(L.geoJSON())
  const inputProperty = useRef(null)

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (map && location === '') {
      map.setMaxZoom(17);
    }
  }, [location, map]);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      drawFieldLayer(geoJson, fieldLayer, map);
    }
  }, [boundary, map]);

  useEffect(() => {
    if(parcels !== undefined && map !== null && parcels !== null){
      drawParcels(parcels, map, valueRadius, inputProperty, foundParcels, parcelLayer, setActiveParcel)
    }
  },[map, parcels])

  const findParcelGeoJson = (parcels, latlng) => {
    let parcelGeo = null;
    let parcelID = null
    for(let i in parcels.parcels){
      let item = parcels.parcels[i]
      for(const parcel of item.parcels){
        let bounds = L.geoJSON(parcel.GeoJSON).getBounds()
        if(bounds.contains(latlng)){
          parcelGeo = parcel.GeoJSON
          parcelID = i
          break;
        }
      }
      if(parcelGeo !== null){
        break;
      }
    }

    //updated field context with the selected index so that the table component can expand it
    if(parcelID !== null){
      let parcelNdx = Object.keys(parcels.parcels).indexOf(parcelID)
      if(parcelNdx !== -1){
        setActiveParcel({index:parcelNdx, parcelMarkers:foundParcels})
      }
    }

    return parcelGeo
  }

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('landvalue-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current']
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }

  return (
    <div
      id="landvalue-map"
      style={{
        height: height,
        minHeight: 500,
        width: width,
        borderRadius: 2,
      }}
    />
  );
}

import React, { useState, useEffect } from "react";
import { Box, Typography } from "@material-ui/core";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import RemoveIcon from "@material-ui/icons/Remove";
import { useSnackbar } from "notistack";

import * as _ from "underscore";
import {
  exists,
  numFormat,
  dollarFormat,
  formatImageBounds,
  consoleImportant,
} from "../../utils/helpers";
import { lightGrey } from "../../styles/colors";
import { SpinningLoader } from "../Shared/SpinningLoader";

// Functions that get, clean, and return values we need
import {
  getInsuranceCropsForCounty,
  getInsuranceTypes,
  getCropPractices,
  getInsurancePriceAndVolatility,
  getInsuranceLocationData,
  getYield,
  getFactor,
  getExclusionYears,
  formatCode,
  createYieldBodyForHistory,
} from "./getValues";
import {
  noDataInResponse,
  getYieldInsurance,
  getRAMARates,
  getYieldsForInsurance,
  calculateYieldFromHistory,
} from "../../utils/dataFetchers";

// Insurance Components
import { WhatIfAnalysis } from "./Tools/WhatIfAnalysis";
import { PremiumCalculator } from "./Tools/PremiumCalculator";
import { InsuranceAI } from "./Tools/InsuranceAI";

// Profit Map
import { InsuranceMap } from "../ProfitLayers/Reports/Maps/Insurance";
import { MobileProfitSummary } from "../ProfitLayers/Reports/Summaries/MobileProfitSummary";

/**
 * Controlling function for Insurance Tools. Manages state and controls display
 * for What If Analysis, Premium Calculator, and Insurance AI.
 * @param {Bool} mobileView Determines if display should be tailored towards mobile
 * @param {String} displayTool Tool to display, determined by parent component
 * @param {Object} field Data for selected field
 * @param {Array} cropYield to get crop yield for selected year and crop name
 * @param {String} source to know page source (DataLayers/ProfitLayers)
 * @param {Number} totalCosts total cost (from ProfitLayers)
 * @param {Number} harvestRevenue revenue from harvest (from ProfitLayers)
 * @param {Object} profitMapResponse response needed to create profitmap (from ProfitLayers)
 * @param {Number} totalProfit total profit (from ProfitLayers)
 * @param {Number} otherRevenue other revenue (from ProfitLayers)
 * @param {Number} netSeededAcres actual area of the field that has been seeded (from ProfitLayers)
 * @param {Function} setInsuranceToolLoading Let parent component know if loading
 */
export function InsuranceTools({
  mobileView,
  displayTool,
  field,
  cropYield = [],
  source = "DATALAYERS",
  totalCosts,
  harvestRevenue,
  profitMapResponse,
  totalProfit,
  otherRevenue,
  netSeededAcres,
  setInsuranceToolLoading,
}) {
  // ------ General Variables ------

  const { enqueueSnackbar } = useSnackbar();

  // State and County codes for selected field
  const [userLocationCodes, setUserLocationCodes] = useState(null);
  const [acres, setAcres] = useState(0);

  // ------ Insurance Tools Variables ------

  // Loading and error trackers
  const [yieldInsuranceInitialLoad, setYieldInsuranceInitialLoad] = useState(
    true
  );
  const [insuranceLoading, setInsuranceLoading] = useState(true);
  const [loading, setLoading] = useState(true);
  const [disableUserInputsOnLoad, setDisableUserInputsOnLoad] = useState(true);
  const [error, setError] = useState(false);
  const [locationErrorMessage, setLocationErrorMessage] = useState("");
  const [errorMessage, setErrorMessage] = useState([]);

  // Insurance tools selection options
  //const [commodityYear, setCommodityYear] = useState(new Date().getFullYear()); // For future use
  const [commodityYear, setCommodityYear] = useState(2018);
  const defaultCrop = { CropCode: "41", Name: "Corn" };
  const [crops, setCrops] = useState([defaultCrop]);
  const [crop, setCrop] = useState(defaultCrop);
  // This is to make sure we wait for crops to be updated before updating the other insurance values
  const [gotCrops, setGotCrops] = useState(false);

  // Type and practice vars
  const [types, setTypes] = useState([
    { typeCode: "16", typeName: "Grain" },
    { typeCode: "26", typeName: "Sillage" },
  ]);
  const [type, setType] = useState(types[0]);

  const [practices, setPractices] = useState([
    { practiceCode: "2", practiceName: "Irrigated" },
    { practiceCode: "3", practiceName: "Non-Irrigated" },
    { practiceCode: "702", practiceName: "Organic(Certified) Irr." },
    { practiceCode: "712", practiceName: "Organic(Transitional) Irr." },
    { practiceCode: "713", practiceName: "Organic(Certified) Non-Irr." },
    { practiceCode: "714", practiceName: "Organic(Transitional) Non-Irr." },
  ]);
  const [practice, setPractice] = useState(practices[1]);

  // Other general vars, displayed to user, some for refine estimates, and the rest for advanced options
  const [projectedPrice, setProjectedPrice] = useState(3.96);
  const [approvedYield, setApprovedYield] = useState(0);
  const [yields, setYields] = useState([]);
  const [historyYields, setHistoryYields] = useState([]);
  const [overwriteYields, setOverwriteYields] = useState(undefined);
  const [insuranceType, setInsuranceType] = useState("Revenue Protection");
  const [insuranceUnit, setInsuranceUnit] = useState("Enterprise");

  const [advancedOptions, setAdvancedOptions] = useState({
    trendAdjustment: true,
    rateYield: 0,
    preventedPlanting: "Standard",
    sharePercentage: "1.00",
    adjustmentFactor: "0.66",
    volatilityFactor: "0.15",
    includeAdminFee: false,
  });

  // Track if rmaRates, yieldInsurance, and updateYield completed their execution
  const [gotInitialYields, setGotInitialYields] = useState(false);
  const [rmaRatesRes, setRMARatesRes] = useState(false);
  const [yieldInsRes, setYieldInsRes] = useState([]);
  // This is to make sure we wait for updateInsuranceValues to finish execution before calling getInputAndSendRequest
  const [callGISR, setCallGISR] = useState(false);

  // Inputs set in What if Tool
  const [whatIfInputs, setWhatIfInputs] = useState({
    actualYield: 0,
    actualPrice: 0,
    actualRevenue: 0,
    perAcre: true,
  });
  const [inputUpdated, setInputUpdated] = useState("");

  // What If and Premium Calculations
  const [countyDataAvailable, setCountyDataAvailable] = useState("");
  const [countyLevelGuarantee, setCountyLevelGuarantee] = useState([]);
  const [countyLevelPrem, setCountyLevelPrem] = useState([]);
  const [guarantee, setGuarantee] = useState([]);
  const [liabilityPerAcre, setLiabilityPerAcre] = useState([]);
  const [liability, setLiability] = useState([]);
  const [plans, setPlans] = useState([]);
  const [premium, setPremium] = useState([]);
  const [premiumAllAcres, setPremiumAllAcres] = useState([]);
  const [subsidy, setSubsidy] = useState([]);
  const [subsidyAmount, setSubsidyAmount] = useState([]);
  const [subsidyAmountAllAcres, setSubsidyAmountAllAcres] = useState([]);
  const [totalPremium, setTotalPremium] = useState([]);
  const [totalPremiumAllAcres, setTotalPremiumAllAcres] = useState([]);
  const [subsidizedPremium, setSubsidizedPremium] = useState({});
  const [subsidizedPremiumAllAcres, setSubsidizedPremiumAllAcres] = useState(
    {}
  );
  const [exclusionYears, setExclusionYears] = useState([]);
  const [allAcres, setAllAcres] = useState(false);
  const [previousDefaultRevenue, setPreviousDefaultRevenue] = useState(0);

  // Various options for InsuranceAI
  const [premiumExists, setPremiumExists] = useState(false);
  const coverageLevels = [
    "50%",
    "55%",
    "60%",
    "65%",
    "70%",
    "75%",
    "80%",
    "85%",
  ];
  const [coverageLevel, setCoverageLevel] = useState(coverageLevels[7]);

  const [usedYieldStorage, setUsedYieldStorage] = useState({}); // To keep track of previously set yields for each crop by year
  const [gotCornYields, setGotCornYields] = useState({});
  const [gotSoybeansYields, setGotSoybeansYields] = useState({});

  // Will contain two arrays to create table statistic table from
  const [statisticTableInputs, setStatisticTableInputs] = useState([]);
  // Set this with an array to create yield table from
  const [soilAndYieldTableInputs, setSoilAndYieldTableInputs] = useState([]);
  const [showTables, setShowTables] = useState(false);

  // Vars to contain data for InsuranceAI graphs
  const [d_Yprc, setdYprc] = useState([]);
  const [d_revprc, setdrevprc] = useState([]);
  const [d_yieldprc, setdyieldprc] = useState([]);
  const [d_priceprc, setdpriceprc] = useState([]);
  const [d_indemprc, setdindemprc] = useState([]);
  const [guaranteeInsurance, setGuaranteeInsurance] = useState(0);
  const insurancePlanCode = {
    "Yield Protection": "01",
    "Revenue Protection": "02",
    "Revenue Protection with Harvest Price Exclusion": "03",
  };

  // For profit map legend calculation, maybe useful other places?
  const [indemnityPerAcre, setIndemnityPerAcre] = useState(null);
  const [premiumPerAcre, setPremiumPerAcre] = useState(null);

  // Profit Map Data
  const [profitMapImage, setProfitMapImage] = useState(null);
  const [profitMapExtent, setProfitMapExtent] = useState(null);
  const [profitLegend, setProfitLegend] = useState(null);
  const [yieldFromOps, setYieldFromOps] = useState(null);

  // ------ UseEffects to update upon changes ------

  // Load data here for insurance tools that rely on field info or must be done early on
  useEffect(() => {
    // This should be the first thing that runs anytime InsuranceTools loads.
    // We want to do this before updateLocationCodes as that will cause the rest of the insurance values to update
    resetInsuranceValues();

    // Check field is in U.S., if its not tool does not work
    if (exists(field?.state)) {
      // Need to do this as useEffect cannot wait on an async function
      async function getLocationAndCrops() {
        const locationCodes = await updateLocationCodes(
          field.state,
          field.county
        );
        updateInsuranceCrops(
          locationCodes.state,
          locationCodes.county,
          commodityYear
        );
      }
      getLocationAndCrops();
      if (netSeededAcres !== undefined) {
        setAcres(netSeededAcres);
      } else {
        setAcres(field.acres);
      }

      // If possible, get historical yield values
      if (field.orgId !== undefined && field.id !== undefined) {
        // This means we can possibly get history yields so we will try
        getYieldsForInsuranceDis();
      } else {
        // History Yields could not be gotten for this field, so we will not have inputedYieldValues
        setOverwriteYields(true);
      }
    } else {
      // console.log('set location error')
      setLocationErrorMessage(
        "Insurance Tools are only available for fields in the U.S."
      );
      setLoading(false);
      setInsuranceLoading(false);
    }
  }, [field, netSeededAcres]);

  // Get all insurance data that changes when location or crop updates
  useEffect(() => {
    if (
      exists([
        userLocationCodes?.state,
        userLocationCodes?.county,
        crop?.CropCode,
      ]) &&
      gotCrops &&
      acres > 0 &&
      overwriteYields !== undefined
    ) {
      setGotCrops(false);

      updateInsuranceValues(
        userLocationCodes.state,
        crop.CropCode,
        commodityYear,
        userLocationCodes.county
      );
    }
  }, [userLocationCodes, crop, gotCrops, overwriteYields]);

  // On initial render only, only call YieldInsuranceAPI (and then getRMARates)
  // when the necessary values have all loaded in
  useEffect(() => {
    if (
      callGISR &&
      gotInitialYields &&
      exists([field.boundary]) &&
      yieldInsuranceInitialLoad
    ) {
      // Avoid below useEffect function being called as this will do the job this time
      setCallGISR(false);
      setYieldInsuranceInitialLoad(false);
      setLoading(true);
      getInputAndSendRequest();
    }
  }, [callGISR, gotInitialYields, field.boundary, yieldInsuranceInitialLoad]);

  // This gets called when crop/year has been changed after initial render
  // This helps avoid getYieldInsurance and getRMARates being called with values before their state has correctly updated
  useEffect(() => {
    // This is similar to the useEffect above. We keep them separate as this looks cleaner.
    // We don't need to check everything again this time as we know all those values already exist
    if (!yieldInsuranceInitialLoad && callGISR) {
      // Avoid this function being called again unless needed
      setCallGISR(false);
      setLoading(true);
      getInputAndSendRequest();
    }
  }, [yieldInsuranceInitialLoad, callGISR]);

  // When all these values are ready (after call to getInputAndSendRequest), we shall update the values for the
  // InsuranceAI tables and graph.
  // NOTE: This could be handled directly in getInputAndSendRequest and remove the need for all these tracking vars
  useEffect(() => {
    if (rmaRatesRes && yieldInsRes?.length && premiumExists) {
      handleAPIResponse();
    }
  }, [rmaRatesRes, yieldInsRes, premiumExists]);

  useEffect(() => {
    if (source === "DATALAYERS") {
      setProfitMapImage(null);
      setProfitMapExtent(null);
    } else if (
      exists(profitMapResponse) &&
      exists(indemnityPerAcre) &&
      exists(statisticTableInputs) &&
      exists(harvestRevenue)
    ) {
      setProfitMapImage(
        `data:image/jpeg;base64,${profitMapResponse[0].pngb64}`
      );
      setProfitMapExtent(formatImageBounds(profitMapResponse[0].extent));
      createInsuranceLegend(profitMapResponse[0].legend);
    }
  }, [
    profitMapResponse,
    indemnityPerAcre,
    premiumPerAcre,
    harvestRevenue,
    whatIfInputs,
    source,
  ]);

  // Sets the current year's yield from actual yield information
  useEffect(() => {
    if (cropYield !== undefined) {
      for (let i = 0; i < cropYield.length; i++) {
        if (
          crop.CropCode === cropYield[i].cropCode &&
          commodityYear === cropYield[i].year
        ) {
          const updateVals = [
            { key: "actualYield", value: cropYield[i].avgYield },
          ];
          updateMultipleInputs(updateVals);
          setYieldFromOps(cropYield[i].avgYield);
        }
      }
    }
  }, [cropYield]);

  // If either loading parameter is true, this will alert parent component for createPDF
  useEffect(() => {
    // (These were originally two because a completed getRMARates is what is needed for first two tools and handleAPIResponse is needed
    // for InsuranceAI only, captured with loading and insuranceLoading respectively.
    // But, now loading is also used for other parts involving getting data)
    if (setInsuranceToolLoading) {
      setInsuranceToolLoading(loading || insuranceLoading);
    }
    setDisableUserInputsOnLoad(loading || insuranceLoading);
  }, [loading, insuranceLoading, setInsuranceToolLoading]);

  // ------ Functions to run upon initial load or similar ------

  const getYieldsForInsuranceDis = async () => {
    let yieldResponses = [];
    // Get them all going so we don't have to wait as much
    for (var i = commodityYear - 14; i < commodityYear; i++) {
      const response = getYieldsForInsurance(field.orgId, field.id, i);
      // console.log(response);
      yieldResponses.push(response);
    }

    let yieldsForIns = [];
    for (var i = 0; i < yieldResponses.length; i++) {
      let response = await yieldResponses[i];

      if (response) {
        const parsedResponse = JSON.parse(response);
        // console.log(response);
        if (parsedResponse.length !== 0) {
          yieldsForIns.push(parsedResponse[0]);
        }
      }
    }
    // console.log('yieldsForIns', yieldsForIns);
    setHistoryYields(yieldsForIns);

    if (yieldsForIns.length > 0) {
      setOverwriteYields(false);
    } else {
      setOverwriteYields(true);
    }
  };

  /**
   * Resets values for insurance tools when field is changed.
   * We don't reset all values. We only reset those that change and are needed as default values for functionality.
   */
  const resetInsuranceValues = () => {
    // General values
    setUserLocationCodes(null);
    setAcres(0);

    // Loading and error trackers
    setInsuranceLoading(true);
    setLoading(true);
    setDisableUserInputsOnLoad(true);
    setError(false);
    setLocationErrorMessage("");
    setErrorMessage([]);

    // General inputs
    //setCommodityYear(new Date().getFullYear()); // for future use
    setCommodityYear(2018); // For testing
    setCrops([defaultCrop]);
    setCrop(defaultCrop);
    setGotCrops(false);
    // not resetting types
    setType(types[0]);
    // not resetting practices
    setPractice(practices[1]);
    setProjectedPrice(3.96);
    setApprovedYield(0);
    setYields([]);
    setHistoryYields([]);
    setOverwriteYields(undefined);
    setInsuranceType("Revenue Protection");
    setInsuranceUnit("Enterprise");

    // Reset advanced options
    resetAdvancedOptions();

    // Response trackers
    setGotInitialYields(false);
    setRMARatesRes(false);
    setYieldInsRes([]);
    setCallGISR(false);

    // What If inputs
    setWhatIfInputs({
      actualYield: 0,
      actualPrice: 0,
      actualRevenue: 0,
      perAcre: true,
    });
    setInputUpdated("");

    // What If and Premium Calculator inputs
    setCountyDataAvailable("");
    setCountyLevelGuarantee([]);
    setCountyLevelPrem([]);
    setGuarantee([]);
    setLiabilityPerAcre([]);
    setLiability([]);
    setPlans([]);
    setPremium([]);
    setPremiumAllAcres([]);
    setSubsidy([]);
    setSubsidyAmount([]);
    setSubsidyAmountAllAcres([]);
    setTotalPremium([]);
    setTotalPremiumAllAcres([]);
    setSubsidizedPremium({});
    setSubsidizedPremiumAllAcres({});
    setExclusionYears([]);
    setAllAcres(false);
    setPreviousDefaultRevenue(0);

    // InsuranceAI vars
    setPremiumExists(false);
    // not resetting coverageLevels
    setCoverageLevel(coverageLevels[7]);

    setUsedYieldStorage({});
    setGotCornYields({});
    setGotSoybeansYields({});

    // not resetting tableInputs, will be overwritten when needed
    setShowTables(false);
    // not resetting graph inputs, same reason as tableInputs
    // except this one as it is needed to create the graph. Won't appear but will run unnecessary code
    setdYprc([]);
    // not resetting insurancePlanCode

    // Profit map legend and data values
    setIndemnityPerAcre(null);
    setPremiumPerAcre(null);
    setProfitMapImage(null);
    setProfitMapExtent(null);
    setProfitLegend(null);
    setYieldFromOps(null);

    // Lastly, set this to true so that we get the new insurance and RMArates values (through useEffect) for Insurance tools
    setYieldInsuranceInitialLoad(true);
  };

  // ------ Get Insurance Tools Data ------

  /**
   * Set location codes to state and county code
   * @param  {String}  state  Field's state
   * @param  {String}  county Field's county
   * @returns {Object} {state: 'stateCode', county: 'countyCode'}
   */
  const updateLocationCodes = async (state, county) => {
    const locationCodes = await getInsuranceLocationData(state, county);
    setUserLocationCodes(locationCodes);
    return locationCodes;
  };

  /**
   * Tries to update type, practice, price, volatility, yields (if allowed), TA factor, and exclusion years
   * @param {String} state field's state code
   * @param {String} cropCode currently selected crop code
   * @param {Number} year currently selected year
   * @param {String} county field's county code
   * @returns {void}
   */
  const updateInsuranceValues = async (state, cropCode, year, county) => {
    setLoading(true);
    const newType = await updateInsuranceTypes(state, cropCode, year, county);
    const newPractice = await updateInsurancePractice(
      state,
      cropCode,
      year,
      county
    );
    // This function will fire every time crop is updated, so we should be able
    // to safely get yields, factors, and price and voloatility here.
    if (exists([newType, newPractice])) {
      await updateInsuranceValuesOnTypeOrPracticeChange(
        state,
        cropCode,
        year,
        county,
        newType.typeCode,
        newPractice.practiceCode
      );
    }
    setLoading(false);
    setCallGISR(true);
  };

  /**
   * Updates type and types
   * @param {String} stateCode field's state code
   * @param {String} cropCode currently selected crop code
   * @param {Number} year currently selected year
   * @param {String} countyCode field's county code
   * @returns {Object} { typeCode: code, typeName: name }
   */
  const updateInsuranceTypes = async (
    stateCode,
    cropCode,
    year,
    countyCode
  ) => {
    const newTypes = await getInsuranceTypes(
      stateCode,
      cropCode,
      year,
      countyCode
    );
    setTypes(newTypes.types);
    setType(newTypes.type);
    return newTypes.type;
  };

  /**
   * Updates practice and practices
   * @param {String} stateCode field's state code
   * @param {String} cropCode currently selected crop code
   * @param {Number} year currently selected year
   * @param {String} countyCode field's county code
   * @returns {Object} { practiceCode: code, practiceName: name }
   */
  const updateInsurancePractice = async (
    stateCode,
    cropCode,
    year,
    countyCode
  ) => {
    const newPractices = await getCropPractices(
      stateCode,
      cropCode,
      year,
      countyCode
    );
    // console.log(newPractices);
    setPractices(newPractices.practices);
    setPractice(newPractices.practice);
    return newPractices.practice;
  };

  /**
   * Updates price and volatility
   * @param {String} stateCode field's state code
   * @param {String} cropCode currently selected crop code
   * @param {Number} year currently selected year
   * @param {String} countyCode field's county code
   * @param {String} typeCode field's county code
   * @param {String} practiceCode field's county code
   * @returns {Object} { projectedPrice: price, volatilityFactor: factor }
   */
  const updateInsurancePriceAndVolatility = async (
    stateCode,
    cropCode,
    year,
    countyCode,
    typeCode,
    practiceCode
  ) => {
    const data = await getInsurancePriceAndVolatility(
      stateCode,
      cropCode,
      year,
      countyCode,
      typeCode,
      practiceCode
    );
    let returnVals = {};
    if (data !== null) {
      setProjectedPrice(numFormat(data.price));
      updateAdvancedOptions("volatilityFactor", numFormat(data.volatility));
      returnVals.projectedPrice = data.price;
      returnVals.volatilityFactor = data.volatility;
    } else {
      setProjectedPrice(0);
      // set volatlity factor to default value
      updateAdvancedOptions("volatilityFactor", 0.15);
      returnVals.projectedPrice = 0;
      returnVals.volatilityFactor = 0.15;
    }
    return returnVals;
  };

  /**
   * Updates price, volatility, TA factor, exclusion years, and yield values
   * @param {String} state field's state code
   * @param {String} cropCode currently selected crop code
   * @param {Number} year currently selected year
   * @param {String} county field's county code
   * @param {String} typeCode field's county code
   * @param {String} practiceCode field's county code
   * @returns {void}
   */
  const updateInsuranceValuesOnTypeOrPracticeChange = async (
    state,
    cropCode,
    year,
    county,
    typeCode,
    practiceCode
  ) => {
    setLoading(true);
    // Most of these do not need await as the next piece of code does not need data from these.
    // But none of them having await is a little dangerous as all these functions could say they're done without anything having loaded in..
    let trackPromises = [];
    trackPromises.push(
      updateInsurancePriceAndVolatility(
        state,
        cropCode,
        year,
        county,
        typeCode,
        practiceCode
      )
    );

    // Yield and factor state fips, county fips, commodity code, type code as one string.
    const requestCode = `${state}${county}${formatCode(
      4,
      cropCode
    )}${formatCode(3, typeCode)}${formatCode(3, practiceCode)}`;
    trackPromises.push(updateFactor(requestCode, year));
    trackPromises.push(updateExclusionYears(requestCode, year));

    let trackResponses = [];
    // So, we want to wait for all of these before moving on as the values need to update correctly before moving on
    for (const elem of trackPromises) {
      trackResponses.push(await elem);
    }

    // Now store each of these responses for possible use in updateYield
    let updatedVals = {};
    updatedVals.typeCode = typeCode;
    updatedVals.practiceCode = practiceCode;
    updatedVals.projectedPrice = trackResponses[0].projectedPrice;
    updatedVals.volatilityFactor = trackResponses[0].volatilityFactor;
    updatedVals.adjustmentFactor = trackResponses[1].adjustmentFactor;
    // console.log("test", updatedVals);

    // We want to do this last as, if we need to call calculateYieldFromHistory, we'll want to make sure we
    // are using the correct values, ie. React might not update them before the call happens
    await updateYield(cropCode, requestCode, year, updatedVals);
    setLoading(false);
  };

  /**
   * Updates yield values
   * If any crop gets called for the first time and overwriteYields is true, we call getYield and store those responses
   * in an object. However, when overwriteYields is false, we only get new yields from calculateYieldFromHistory.
   * Then we always reuse previously set approved and rate yield values upon new call to updateYield.
   * source can just be ignored source, according to Swathi.
   * @param {String} cropCode currently selected crop code
   * @param {String} requestCode yield and factor state fips, county fips, commodity code, type code as one string
   * @param {Number} year currently selected year
   * @param {String} updatedVals type, practice, price, and factors for calculateYieldFromHistory
   * @returns {Object} {apprYield: apprVal, rateYield: rateVal}
   */
  const updateYield = async (
    cropCode,
    requestCode,
    year,
    updatedVals = undefined
  ) => {
    // Check if there are already stored yield values for these
    if (usedYieldStorage[year]?.[cropCode]) {
      const newYield = await getYield(requestCode, year);

      // Update the yields
      if (overwriteYields) {
        setYields(newYield.yields);
      }
      // If overwriteYields is false, then set inputedYieldValues from historyYields
      else {
        let yieldsForReq = newYield.yields;

        // NOTE: Can make this a little faster later by storing history response (from getYieldsForInsuranceDis) in object with year key
        // If the user had changed some of the inputedYieldValue, they will have to do so again but it shouldn't be necessary really
        // as the rateYield and approvedYield we will use will have been stored using those modified inputedYieldValues
        for (var i = 0; i < historyYields.length; i++) {
          for (var j = 0; j < yieldsForReq.length; j++) {
            if (Number(historyYields[i].Year) == Number(yieldsForReq[j].year)) {
              yieldsForReq[j].inputedYieldValue = historyYields[i].Yield;
              break;
            }
          }
        }
        // Make sure yields is properly updated
        setYields(yieldsForReq);
      }

      updateAdvancedOptions(
        "rateYield",
        usedYieldStorage[year][cropCode].rateYield
      );
      setApprovedYield(usedYieldStorage[year][cropCode].apprYield);
      return;
    }

    let finalYield;

    // If this is a new selection of year and crop, then check if overwriteYields has been set
    // (if not, then field just changed and this will be called again soon)
    // If everything is properly writtenn, this should never get called..
    if (overwriteYields === undefined) {
      console.log(
        "updateYield call with overwriteYields being undefined. This shouldn't happen.."
      );
      return;
    }
    // overwriteYields being false indicates we should get yields using the historyYields data
    else if (!overwriteYields) {
      // NOTE: This was missing before, so yieldsForReq would always get an empty array.
      // If we do need this, then we need to call this each time any of (crop, type, pratice) update as well.
      const newYield = await getYield(requestCode, year);
      let yieldsForReq = newYield.yields;

      // NOTE: Can make this a little faster later by storing history response (from getYieldsForInsuranceDis) in object with year key
      for (var i = 0; i < historyYields.length; i++) {
        for (var j = 0; j < yieldsForReq.length; j++) {
          if (Number(historyYields[i].Year) == Number(yieldsForReq[j].year)) {
            yieldsForReq[j].inputedYieldValue = historyYields[i].Yield;
            break;
          }
        }
      }
      // Make sure yields is properly updated
      setYields(yieldsForReq);

      const bodyData = getCalculateYieldFromHistoryData(updatedVals);
      const histYields = createYieldBodyForHistory(yieldsForReq, commodityYear);
      const calculateBody = {
        ...bodyData,
        HistYield: histYields,
      };

      const refinedEstimates = await calculateYieldFromHistory(calculateBody);
      const parsed = JSON.parse(refinedEstimates);
      updateRefinedYields(parsed.TAAPHYield, parsed.AverageYield);
      finalYield = {
        apprYield: parsed.TAAPHYield,
        rateYield: parsed.AverageYield,
      };
    }
    // if overwriteYields is true then no history yields were found so we can just get them through getYield instead
    else {
      const newYield = await getYield(requestCode, year);
      setYields(newYield.yields);

      if (exists(newYield?.selectedYield?.tYield)) {
        updateAdvancedOptions("rateYield", newYield.selectedYield.tYield);
        setApprovedYield(newYield.selectedYield.tYield);
        finalYield = {
          apprYield: newYield.selectedYield.tYield,
          rateYield: newYield.selectedYield.tYield,
        };
      } else {
        updateAdvancedOptions("rateYield", 0);
        setApprovedYield(0);
        finalYield = { apprYield: 0, rateYield: 0 };
      }
    }

    // Update yieldStorage with the new refined yields
    setUsedYieldStorage((prevState) => ({
      ...prevState,
      [year]: {
        ...prevState[year],
        [cropCode]: finalYield,
      },
    }));
    setGotInitialYields(true);
    return finalYield;
  };

  /**
   * Updates Trend Adjustment factor
   * @param {String} requestCode yield and factor state fips, county fips, commodity code, type code as one string
   * @param {Number} year currently selected year
   * @returns {Object} {adjustmentFactor: factor}
   */
  const updateFactor = async (requestCode, year) => {
    const newFactor = await getFactor(requestCode, year);

    if (newFactor !== null) {
      updateAdvancedOptions("adjustmentFactor", newFactor);
      return { adjustmentFactor: newFactor };
    } else {
      updateAdvancedOptions("adjustmentFactor", 0);
      return { adjustmentFactor: 0 };
    }
  };

  /**
   * Updates exclusion years
   * @param {String} requestCode yield and factor state fips, county fips, commodity code, type code as one string
   * @param {Number} year currently selected year
   * @returns {void}
   */
  const updateExclusionYears = async (requestCode, year) => {
    const newExclusionYears = await getExclusionYears(requestCode, year);
    setExclusionYears(newExclusionYears);
  };

  /**
   * Updates crops available for this field's state and county duiring the selected year
   * @param {String} stateCode field's state code
   * @param {String} countyCode field's county code
   * @param {Number} year currently selected year
   * @returns {void}
   */
  const updateInsuranceCrops = async (stateCode, countyCode, year) => {
    setLoading(true);
    // getInsuranceCropsForCounty will return object with key crops being array of crops and
    // key crop being the first entry in crops. crop is an object.
    const updatedCrops = await getInsuranceCropsForCounty(
      stateCode,
      countyCode,
      year
    );

    // Error handling
    if (updatedCrops === noDataInResponse) {
      // Show error message and also disable everything but year selection
      const errorMsg =
        "There are no crops available for this state and county during the selected year. Please select another year.";
      setErrorMessage(["All", errorMsg, "ButYear"]);
      // Remove loading spinner
      setLoading(false);
      setInsuranceLoading(false);
      // Shouldn't be needed, but just in case
      setGotCrops(false);

      // Finally, remove most input choices
      setCrop({ CropCode: "None", Name: "No Crops Available" });
      const noType = { typeCode: "None", typeName: "No Types Available" };
      setTypes([noType]);
      setType(noType);
      const noPractice = {
        practiceCode: "None",
        practiceName: "No Practices Available",
      };
      setPractices([noPractice]);
      setPractice(noPractice);
      setProjectedPrice(0);
      setApprovedYield(0);
      setWhatIfInputs({
        actualYield: 0,
        actualPrice: 0,
        actualRevenue: 0,
        perAcre: true,
      });
    }
    // Set the crops and crop status
    else {
      setCrops(updatedCrops.crops);
      setCrop(updatedCrops.crop);
      setGotCrops(true);
    }
  };

  /**
   * Updates states and when applicable calls other updating functions when user updates values in GeneralSettings or QuoteInput
   * components. This way we can pass down one function through components that need to update state stored in Insurance Tools to
   * handle user interactions instead of many.
   * @param {Number|Object} value    New user inputed value
   * @param {String} toUpdate What to update new value with
   */
  const handleInsuranceInputChange = (value, toUpdate) => {
    switch (toUpdate) {
      case "year":
        // Updates commodity year change in InsuranceTool/GeneralSettings.
        // Then, call updateInsuranceCrops to updated available crops based on selected year.
        setCommodityYear(value);
        updateInsuranceCrops(
          userLocationCodes.state,
          userLocationCodes.county,
          value
        );
        break;
      case "crop":
        setCrop(value);
        setGotCrops(true);
        break;
      case "type":
        setType(value);
        updateInsuranceValuesOnTypeOrPracticeChange(
          userLocationCodes.state,
          crop.CropCode,
          commodityYear,
          userLocationCodes.county,
          value.typeCode,
          practice.practiceCode
        );
        break;
      case "practice":
        setPractice(value);
        updateInsuranceValuesOnTypeOrPracticeChange(
          userLocationCodes.state,
          crop.CropCode,
          commodityYear,
          userLocationCodes.county,
          type.typeCode,
          value.practiceCode
        );
        break;
      case "acres":
        // console.log('value :>> ', value);
        setAcres(value);
        break;
      case "projectedPrice":
        setProjectedPrice(value);
        break;
      case "approvedYield":
        setApprovedYield(value);
        let newYield = {
          apprYield: value,
          rateYield: advancedOptions.rateYield,
        };
        // if trend adjustment is no, updated rate yield to match approved yield
        if (!advancedOptions.trendAdjustment) {
          updateAdvancedOptions("rateYield", value);
          newYield = { apprYield: value, rateYield: value };
        }

        // Anytime these yields are changed by the user, we want to update yieldStorage
        setUsedYieldStorage((prevState) => ({
          ...prevState,
          [commodityYear]: {
            ...prevState[commodityYear],
            [crop.CropCode]: newYield,
          },
        }));
        break;
      default:
        console.log("unexpectd type: ", type);
    }
  };

  /**
   * Controls updating advancedOptions.js component's states
   * @param {String} key key to be updated
   * @param {Boolean|Number|String} val updated value for key
   * @param {Boolean} userChanged indicates whether the user initiated this call for rateYield only
   * @returns {void}
   */
  const updateAdvancedOptions = (key, val, userChanged = undefined) => {
    if (key === "reset") {
      resetAdvancedOptions();
    } else if (key === "volatilityFactor" && (+val < 0.1 || +val > 0.4)) {
      enqueueSnackbar(
        "Please enter a number between .10 and .40 for volatility Factor"
      );
    } else if (key === "sharePercentage" && (+val < 0 || +val > 1)) {
      enqueueSnackbar(
        "Please enter a number between 0 and 1 for share percentage"
      );
    } else if (key === "rateYield") {
      // Update rateYield differently as it needs to update usedYieldStorage
      // But only if it was changed by the user. As it's already taken care of in other circumstances
      if (userChanged) {
        const newYield = { apprYield: approvedYield, rateYield: val };
        // Anytime these yields are changed by the user, we want to update yieldStorage
        setUsedYieldStorage((prevState) => ({
          ...prevState,
          [commodityYear]: {
            ...prevState[commodityYear],
            [crop.CropCode]: newYield,
          },
        }));
      }
      setAdvancedOptions((prevState) => ({ ...prevState, [key]: val }));
    } else {
      // update key with new value
      setAdvancedOptions((prevState) => ({ ...prevState, [`${key}`]: val }));
    }
  };

  /**
   * Resets advanced options values
   */
  const resetAdvancedOptions = () => {
    setAdvancedOptions({
      trendAdjustment: true,
      rateYield: 0,
      preventedPlanting: "Standard",
      sharePercentage: "1.00",
      adjustmentFactor: "0.66",
      volatilityFactor: "0.15",
      includeAdminFee: false,
    });
  };

  /**
   * Updates a particular WhatIf input
   * @param {String} key key to be updated
   * @param {Number} val updated value for key
   */
  const updateWhatIfInputs = (key, val) => {
    // console.log("updating what if inputs", key)
    setWhatIfInputs((prevState) => ({ ...prevState, [`${key}`]: val }));
  };

  /**
   * Updates various WhatIf inputs
   * @param {Object} values stores key and value pairs of inputs to be updated
   *    (eg. [{ key: 'actualYield', value: actualyield }, { key: 'actualPrice', value: harvestprice } ])
   * @returns {void}
   */
  const updateMultipleInputs = (values) => {
    const inputsCopy = { ...whatIfInputs };

    for (let i = 0; i < values.length; i++) {
      inputsCopy[values[i].key] = values[i].value;
    }

    setWhatIfInputs(inputsCopy);
    setInputUpdated("");
  };

  // Creates the required data for calling calculateYieldFromHistory endpoint
  const getCalculateYieldFromHistoryData = (updatedVals) => {
    if (updatedVals !== undefined) {
      return {
        FIPS: Number(userLocationCodes.state + userLocationCodes.county),
        CropCode: +crop.CropCode,
        Type: +updatedVals.typeCode,
        Practice: +updatedVals.practiceCode,
        Year: +commodityYear,
        TAFactor: +updatedVals.adjustmentFactor,
        Price: +updatedVals.projectedPrice,
        Volatility: +updatedVals.volatilityFactor,
      };
    } else if (
      exists([userLocationCodes, practice?.practiceCode, type?.typeCode])
    ) {
      return {
        FIPS: Number(userLocationCodes.state + userLocationCodes.county),
        CropCode: +crop.CropCode,
        Type: +type.typeCode,
        Practice: +practice.practiceCode,
        Year: +commodityYear,
        TAFactor: +advancedOptions.adjustmentFactor,
        Price: +projectedPrice,
        Volatility: +advancedOptions.volatilityFactor,
      };
    }
    return null;
  };

  // Updates approved and rate yields with refined yield projections from RefineEstimates or calculateYieldFromHistory responses
  const updateRefinedYields = (taaphYield, averageYield) => {
    updateAdvancedOptions("rateYield", averageYield);
    setApprovedYield(taaphYield);

    let newYield = { apprYield: taaphYield, rateYield: averageYield };
    // Anytime these yields are changed by the user, we want to update yieldStorage
    setUsedYieldStorage((prevState) => ({
      ...prevState,
      [commodityYear]: {
        ...prevState[commodityYear],
        [crop.CropCode]: newYield,
      },
    }));
  };

  /**
   * Gets the required input for YieldInsurance call and makes it, then calls getRMARates
   * @param {Number} appYield approvedYield to use for when called from handleAPIReponse
   * @param {Number} rtYield rateYield to use for when called from handleAPIReponse
   * @returns {void}
   */
  const getInputAndSendRequest = async (appYield = null, rtYield = null) => {
    try {
      // Reset loading and error trackers.
      setErrorMessage([]);
      setLoading(true);
      setInsuranceLoading(true);
      let apprYield = appYield === null ? approvedYield : appYield;
      let rateYield = rtYield === null ? advancedOptions.rateYield : rtYield;

      // Do not even bother doing this if the crop is not either corn or soybenas
      if (crop.CropCode === "41" || crop.CropCode === "81") {
        const covLvl = parseInt(coverageLevel) / 100;
        const acresYield = parseInt(acres);
        const yieldDatabase = createYieldDataBase(yields);
        const insurancePlan = insurancePlanCode[insuranceType];

        // console.log("In getInputAndSendRequest")
        // console.log("apprYield:", apprYield, "rateYield", rateYield)
        // NOTE: The old code would get price and volatility from the last/previous year if they were both 0.
        // But that should never occur for us so this was ommitted for now..
        const request = `{"County Code":"${
          userLocationCodes.county
        }","Crop Code":${
          crop.CropCode
        },"MP Base Price":${projectedPrice},"MP RP Volatility":${
          advancedOptions.volatilityFactor
        }, "MP Unit Option Code":"OU", "Practice Code":${
          practice.practiceCode
        },"USER_ID":"1","MPCI Coverage Level":${covLvl}, "MPCI Insurance Plan":"${insurancePlan}", "State Number":${userLocationCodes.state.toString()}, "Type Code":${
          type.typeCode
        }, "unitdata":[{ "Field Shapes":[{"features":[ ${
          field.boundary
        }]}],"Adjusted Yield": 0, "Approved Yield": ${parseInt(
          apprYield
        )}, "Average Yield": 0, "Basic Unit Number": "0001", "FIELDIDs": ["123"],"Shape Acres":[${acresYield}], "Optional Unit Number": "0001", "Rate Yield":${parseInt(
          rateYield
        )}, "Sub County Code": "", "Unit Key": "1", "Yield Database": ${JSON.stringify(
          yieldDatabase
        )}, "Yield Option Codes": "[TA,YA]"}]}`;

        // Try for getYieldInsurance
        try {
          const yieldAPIRepsonse = await getYieldInsurance(request);
          setYieldInsRes(yieldAPIRepsonse);
          // console.log('yieldAPIRepsonse :>> ', yieldAPIRepsonse);
          // For profit map legend calculation
          // setIndemnityPerAcre(yieldAPIRepsonse[0].unitdata[0].UnitExpectedIndemnity)

          // Check for an issue/exception
          if (yieldAPIRepsonse?.exception) {
            consoleImportant("Exception while in getYieldInsurance:");
            console.log("error: " + yieldAPIRepsonse.exception);
            const errorMsg =
              "There was an issue getting insurance information. Please check back again later.";
            setErrorMessage(["InsuranceAI", errorMsg, "ButYearAndCrop"]);
            setYieldInsRes([]);
            setInsuranceLoading(false);
          } else if (yieldAPIRepsonse === noDataInResponse) {
            console.log(
              "No data for request was returned by getYieldInsurance."
            );
            const errorMsg =
              "No insurance data is available for your current selection. Please try another crop or year.";
            setErrorMessage(["InsuranceAI", errorMsg, "ButYearAndCrop"]);
            setYieldInsRes([]);
            setInsuranceLoading(false);
          } else if (yieldAPIRepsonse === "Bad request") {
            consoleImportant("A bad request was made to getYieldInsurance.");
            const errorMsg =
              "There was an issue while trying to get insurance data. Please check back again later.";
            setErrorMessage(["InsuranceAI", errorMsg, true]);
            setYieldInsRes([]);
            setInsuranceLoading(false);
          }

          // loading will be set to false by getRMARates
        } catch (error) {
          console.log(
            "There was an error while attempting to getYieldInsurance in getInputAndSendRequest:",
            error
          );
          setInsuranceLoading(false);
          setLoading(false);
        }
      }
      // Show error message to InsuranceAI
      else {
        // getYieldInsurance would return "No Content" if the above if statement ran
        const errorMsg =
          "This crop is not available for Insurance AI. Please select another crop.";
        setErrorMessage(["InsuranceAI", errorMsg, "ButYearAndCrop"]);
        setYieldInsRes([]);
        // If it's not either corn or soybeans, make sure to setInsuranceLoading as false or spinning loader will stay
        setInsuranceLoading(false);
      }

      // Try for getRMARates
      // These are separated as getRMARates doesn't actually need anything from getYieldInsurance
      // And we still want this to run even if getYieldInsurance fails
      try {
        await getRMARates(apprYield, rateYield);
      } catch (error) {
        console.log(
          "There was an error while attempting to getRMARates in getInputAndSendRequest:",
          error
        );
        setInsuranceLoading(false);
        setLoading(false);
      }
    } catch (error) {
      consoleImportant("An exception occurred while in getInputAndSendRequest");
      console.log("error: " + error);
      const errorMsg =
        "There was an issue getting Insurance Tools information. Please try changing the commodity year or reloading the page.";
      setErrorMessage(["All", errorMsg, "ButYear"]);
      setYieldInsRes([]);
      setInsuranceLoading(false);
      setLoading(false);
    }
  };

  /**
   * Takes the response from the call to YieldInsurance and sets the appropriate table and graph values for InsuranceAI
   * @returns {void}
   */
  const handleAPIResponse = async () => {
    try {
      if (yieldInsRes.length > 0) {
        const jsonDatap = yieldInsRes;

        const insYield = jsonDatap[0].unitdata[0].UnitExpectedYield;
        const newYield = { apprYield: insYield, rateYield: insYield };
        let cornSet,
          soybeansSet = false;
        // If the default yield value for this year and crop hasn't been gotten yet, set it from the YieldInsuranceAPI response
        // This will only happen the first time a crop is called.
        // We will then only use whatever value the user changes it to or it is changed to by refine estimates
        // We need to make sure it's one or the other for the CropCodes as other crops can reach this function too.
        if (
          (crop.CropCode == "41" && !gotCornYields[commodityYear]) ||
          (crop.CropCode == "81" && !gotSoybeansYields[commodityYear])
        ) {
          // If we have historyYields, do not overwrite them.
          // (Also no need to call getInputAndSendRequest again as we used the right yield values)
          if (!overwriteYields) {
            if (crop.CropCode == "41") {
              setGotCornYields({ ...gotCornYields, [commodityYear]: true });
              cornSet = true;
            } else if (crop.CropCode == "81") {
              setGotSoybeansYields({
                ...gotSoybeansYields,
                [commodityYear]: true,
              });
              soybeansSet = true;
            }
          } else {
            setUsedYieldStorage((prevState) => ({
              ...prevState,
              [commodityYear]: {
                ...prevState[commodityYear],
                [crop.CropCode]: newYield,
              },
            }));
            // console.log(insYield);
            updateAdvancedOptions("rateYield", insYield);
            setApprovedYield(insYield);
            // In this case, crop is marked as set at the bottom
          }
        }

        // Set soil statistics and yield risk table values
        const county_soil_avg = jsonDatap[0]["County Soil Avg"];
        const unit_soil_avg = jsonDatap[0].unitdata[0].UnitSoilAvg;
        const unit_expected_yield = jsonDatap[0].unitdata[0].UnitExpectedYield;
        const unit_std = jsonDatap[0].unitdata[0].UnitStd;

        setSoilAndYieldTableInputs([
          {
            rowName: "County Average - Soil Productivity Rating (NCCPI)",
            value: Math.round(county_soil_avg * 100) / 100,
          },
          {
            rowName: "Field Average - Soil Productivity Rating (NCCPI)",
            value: Math.round(unit_soil_avg * 100) / 100,
          },
          {
            rowName: "Expected Yield For This Field (bu/acre)",
            value: Math.round(unit_expected_yield * 100) / 100,
          },
          {
            rowName: "Expected Yield For This Field (Standard Deviation)",
            value: Math.round(unit_std * 100) / 100,
          },
        ]);

        // Set insurance statistic table values
        const unit_guarantee_per_acre =
          jsonDatap[0].unitdata[0].UnitGuaranteePerAcre;
        setGuaranteeInsurance(unit_guarantee_per_acre);
        const unit_expected_indemnity =
          jsonDatap[0].unitdata[0].UnitExpectedIndemnity;
        let freqpay = jsonDatap[0].unitdata[0].freqPay;
        freqpay = Math.round(freqpay * 100 * 100) / 100;

        const premiumVal = calculatePremium();
        const total_guarantee = unit_guarantee_per_acre * parseInt(acres);
        const total_indemnity = unit_expected_indemnity * parseInt(acres);
        const total_premium =
          Math.round(premiumVal * parseInt(acres) * 100) / 100;

        const yieldperacre = (parseInt(coverageLevel) / 100) * approvedYield;
        const yieldTotal =
          (parseInt(coverageLevel) / 100) * approvedYield * acres;

        const guaranteeDis = {
          rowname: "",
          tooltip: "",
          acre: "",
          total: "",
        };
        const insurancePlan = insurancePlanCode[insuranceType];
        if (insurancePlan === "02" || insurancePlan === "03") {
          guaranteeDis.rowName = "Guarantee";
          guaranteeDis.tooltip =
            "The liability of the insurance policy is the revenue that is guaranteed you will make at harvest time.";
          guaranteeDis.acre = Math.round(unit_guarantee_per_acre * 100) / 100;
          guaranteeDis.total = Math.round(total_guarantee * 100) / 100;
        } else if (insurancePlan === "01") {
          guaranteeDis.rowName = "Guarantee Yield (bu/acre)";
          guaranteeDis.tooltip = "";
          guaranteeDis.acre = Math.round(yieldperacre * 100) / 100;
          guaranteeDis.total = Math.round(yieldTotal * 100) / 100;
        }
        const expectedInsuredProfit = {};
        const expectedUninsuredProfit = {};

        if (source.toUpperCase() === "PROFITLAYERS") {
          // console.log(projectedPrice, jsonDatap[0].unitdata[0].UnitExpectedYield, jsonDatap[0].unitdata[0].UnitExpectedIndemnity, premiumVal, totalCosts)
          expectedInsuredProfit.rowName = "Expected Insured Profit";
          // Formula for expected insured profit = Base Price x  Expected Unit Yield +  Expected Indemnity per acre - Premium per acre - Total Cost Per Acre
          const insuredProfit =
            Number(projectedPrice) *
              Number(jsonDatap[0].unitdata[0].UnitExpectedYield) +
            Number(jsonDatap[0].unitdata[0].UnitExpectedIndemnity) -
            premiumVal -
            Math.abs(Number(totalCosts));
          expectedInsuredProfit.value = Math.round(insuredProfit * 100) / 100;

          expectedUninsuredProfit.rowName = "Expected Uninsured Profit";
          // Formula for expected uninsured profit = Base Price x Expected Unit Yield  - Total Cost Per Acre
          const uninsuredProfit =
            Number(projectedPrice) *
              Number(jsonDatap[0].unitdata[0].UnitExpectedYield) -
            Math.abs(Number(totalCosts));
          expectedUninsuredProfit.value =
            Math.round(uninsuredProfit * 100) / 100;
        }

        // For profit map legend calculations
        //setPremiumPerAcre(premiumVal) //moved to revenueChange() in whatifanalysis

        setStatisticTableInputs([
          [
            {
              rowName: "Premium",
              acre: premiumVal,
              total: total_premium,
              tooltip: "Premium is the amount you pay for insurance.",
            },
            guaranteeDis,
            {
              rowName: "Average Insurance Payment*",
              acre: Math.round(unit_expected_indemnity * 100) / 100,
              total: Math.round(total_indemnity * 100) / 100,
              tooltip:
                "On average, this is the insurance amount that will be paid.",
            },
          ],
          [
            {
              rowName: "Payment Probability",
              value: `${numFormat(freqpay, 2, 2)}%`,
            },
            expectedInsuredProfit,
            expectedUninsuredProfit,
          ],
        ]);

        // Update graph values
        setdyieldprc(jsonDatap[0].unitdata[0].yieldprc);
        setdindemprc(jsonDatap[0].unitdata[0].indemprc);
        setdrevprc(jsonDatap[0].unitdata[0].revprc);
        setdpriceprc(jsonDatap[0].unitdata[0].priceprc);
        setdYprc(jsonDatap[0].Yprc);

        // When all the insurance values have been properly updated, reset the dependency list for rerunning this function
        // These should ONLY be reset in here
        setRMARatesRes(false);
        setYieldInsRes([]);
        setPremiumExists(false);
        setShowTables(true);
        setInsuranceLoading(false);

        // The assumption here is that since we didn't have these yield values initially, we want to rerun the call to YieldInsurance with
        // these now. To get a consistent response.
        // And make sure to mark that it's been set
        if (
          crop.CropCode === "41" &&
          !gotCornYields[commodityYear] &&
          !cornSet
        ) {
          setGotCornYields({ ...gotCornYields, [commodityYear]: true });
          getInputAndSendRequest(insYield, insYield);
        } else if (
          crop.CropCode === "81" &&
          !gotSoybeansYields[commodityYear] &&
          !soybeansSet
        ) {
          setGotSoybeansYields({ ...gotSoybeansYields, [commodityYear]: true });
          getInputAndSendRequest(insYield, insYield);
        }
      }
    } catch (error) {
      // TODO: handle the error?
      console.log("There was an error while in handleAPIResponse:", error);
      setInsuranceLoading(false);
    }
  };

  /**
   * Creates a yield object containing using some values from refine estimates section
   * @param {Object} yieldObj county trend yield for a specific year and user-inputted/historical yield value
   * @returns {Object} yield object for calls to getYieldInsurance
   */
  const createYieldObject = (yieldObj) => {
    // If user has not entered a value it will be an empty, so set to 0
    const annualYield = parseInt(yieldObj.inputedYieldValue);

    const newYieldObj = {
      "Annual Production": 0,
      "Annual Yield": annualYield,
      "Historical Adjusted Yield": 0,
      "T-Yield": 0,
      "Yield Acreage": 0,
      "Yield Type Code": yieldObj.yieldType,
      "Yield Year": +yieldObj.year,
    };

    return newYieldObj;
  };

  /**
   * Create a record of the current state of the yield information in Refine Estimates.
   * Returns a default Object if nothing was inputted.
   * @param {Array} tYields list of county trend yields by year and user-inputted/historical yield values
   * @returns {Array} list of yield object for calls to getYieldInsurance
   */
  const createYieldDataBase = (tYields) => {
    // Filter for years starting at commodityYear
    // Slice down to 12 entries then map to createYieldObject
    const yieldsForDatabase = tYields
      .filter((x) => +x.year <= commodityYear && x.inputedYieldValue != "")
      .slice(-12)
      .map((x) => createYieldObject(x));

    if (!yieldsForDatabase.length) {
      return [
        {
          "Annual Production": 0,
          "Annual Yield": 0,
          "Historical Adjusted Yield": 0,
          "T-Yield": 0,
          "Yield Acreage": 0,
          "Yield Type Code": "Z",
          "Yield Year": 0,
        },
      ];
    }
    return yieldsForDatabase;
  };

  /**
   * Creates a request object, uses it in call to getRAMARates, and uses the response to set the state for various variables
   * for use in all the tools.
   * @param {Number} apprYieldFromIns approved yield from getInputAndSendRequest
   * @param {Number} rateYieldFromIns rate yield from getInputAndSendRequest
   * @returns {Promise} not to be used but to make sure we can await the function
   */
  const getRMARates = async (
    apprYieldFromIns = null,
    rateYieldFromIns = null
  ) => {
    // // Some crops will return null practice and type codes due to not yet being in database
    // if (exists([practice?.practiceCode, type?.typeCode])) {
    setError(false);
    try {
      // Build param object from states
      const whatifObj = {
        FIPS: Number(userLocationCodes.state + userLocationCodes.county),
        CropCode: +crop.CropCode,
        Type: +type.typeCode,
        Practice: +practice.practiceCode,
        PreventedPlanting:
          advancedOptions.preventedPlanting === "Standard" ? 0 : 1,
        UseTAYield: advancedOptions.trendAdjustment ? 1 : 0,
        UsePerAcre: !allAcres ? 1 : 0,
        SharePercentage: +advancedOptions.sharePercentage,
        TrendAdjustedYield:
          apprYieldFromIns === null ? +approvedYield : +apprYieldFromIns,
        Acres: parseFloat(Number(acres).toFixed(2)),
        Year: +commodityYear,
        APHYield:
          rateYieldFromIns === null
            ? +advancedOptions.rateYield
            : +rateYieldFromIns,
        Price: +projectedPrice,
        Volatility: +advancedOptions.volatilityFactor,
        IncludeAdminFee: advancedOptions.includeAdminFee ? 1 : 0,
      };

      const RMARatesResult = await getRAMARates(whatifObj);
      const RMARates = JSON.parse(RMARatesResult);
      setRMARatesRes(true);

      setCountyDataAvailable(RMARates.CountyDataAvailable);
      setCountyLevelGuarantee(RMARates.CountyLevelGuarantee);
      setCountyLevelPrem(RMARates.CountyLevelPrem);
      setGuarantee(RMARates.Guarantee);
      setPlans(RMARates.Plans);
      setPremium(RMARates.Premium);
      setPremiumExists(true);
      setSubsidy(RMARates.Subsidy);
      setSubsidyAmount(RMARates.SubsidyAmount);
      setSubsidyAmountAllAcres(RMARates.SubsidyAmountAllAcres);
      setTotalPremium(RMARates.TotalPremium);
      setTotalPremiumAllAcres(RMARates.TotalPremiumAllAcres);

      const liable = [];
      const liabilityPerAc = [];

      for (let i = 0; i < RMARates.Liability.length; i++) {
        const liableObj = {
          name: `${50 + i * 5}%`,
          value: RMARates.Liability[i][0],
        };
        liable.push(liableObj);

        const liablePAObj = {
          name: `${50 + i * 5}%`,
          value: Math.round(RMARates.Liability[i][0] * 100.0) / (100 * acres),
        };
        liabilityPerAc.push(liablePAObj);
      }
      // console.log('liable', liable)
      setLiability(liable);
      setLiabilityPerAcre(liabilityPerAc);

      const indexvalues = {
        0: "RP_Optional",
        1: "RP_Basic",
        2: "RP_Enterprise",
        3: "RPHPE_Optional",
        4: "RPHPE_Basic",
        5: "RPHPE_Enterprise",
        6: "YP_Optional",
        7: "YP_Basic",
        8: "YP_Enterprise",
      };

      const subsidized_premium = _.each(
        _.groupBy(
          _.filter(
            _.flatten(
              _.map(RMARates.Premium, (coverlevel) =>
                _.map(coverlevel, (value, index) => ({
                  name: indexvalues[index.toString()],
                  value,
                }))
              )
            ),
            (value) => value.value > 0
          ),
          "name"
        ),
        (coverlevel) => {
          _.each(coverlevel, (value, index) => {
            value.name = `${50 + index * 5}%`;
          });
        }
      );

      const subsidized_premium_allacres = _.each(
        _.groupBy(
          _.filter(
            _.flatten(
              _.map(RMARates.PremiumAllAcres, (coverlevel) =>
                _.map(coverlevel, (value, index) => ({
                  name: indexvalues[index.toString()],
                  value,
                }))
              )
            ),
            (value) => value.value > 0
          ),
          "name"
        ),
        (coverlevel) => {
          _.each(coverlevel, (value, index) => {
            value.name = `${50 + index * 5}%`;
          });
        }
      );

      setSubsidizedPremium(subsidized_premium);
      setSubsidizedPremiumAllAcres(subsidized_premium_allacres);

      setLoading(false);
      return RMARatesResult;
    } catch (err) {
      setError(true);
      setLoading(false);
      setInsuranceLoading(false);
      console.log(err);
    }
    // } else {
    //   enqueueSnackbar('Premium calculation not yet available for selected crop. Sorry for the inconvenience, please check back later.');
    //   setLoading(false);
    //   setInsuranceLoading(false);
    // }
  };

  /**
   * Chooses the appropriate premium for the InsuranceAI table based on the insurance plan.
   * @returns {Number} chosen premium
   */
  const calculatePremium = () => {
    const cvgLvlIndex = coverageLevels.findIndex((x) => x === coverageLevel);
    const MPCIInsurancePlan = insurancePlanCode[insuranceType];

    let col = 1;
    if (MPCIInsurancePlan == "03") {
      col = 3;
    }
    if (MPCIInsurancePlan == "02") {
      col = 0;
    }
    if (MPCIInsurancePlan == "01") {
      col = 6;
    }

    const premiumVal = parseFloat(premium[cvgLvlIndex][col]);
    return premiumVal;
  };

  /**
   * Add min and max values to the legend information for Profit Map
   * @param {Array} legend legend information for Profit Map
   */
  const createInsuranceLegend = (legend) => {
    //console.log(whatIfInputs, indemnityPerAcre, premiumPerAcre, otherRevenue, totalCosts, totalProfit)
    let price =
      insuranceType === "Yield Protection"
        ? whatIfInputs.actualRevenue / whatIfInputs.actualYield
        : whatIfInputs.actualPrice;
    //console.log('whatIfInputs.actualPrice', price)
    const constantValue =
      indemnityPerAcre -
      premiumPerAcre +
      price * whatIfInputs.actualYield +
      Number(otherRevenue) -
      Math.abs(Number(totalCosts)) -
      totalProfit;
    //console.log('constantValue', constantValue)
    const insuranceLegend = [];

    // For each entry in profit map legend, add above calculated constant value to min and max
    for (const entry of legend) {
      const insuranceEntry = {
        ...entry,
        min: entry.min + constantValue,
        max: entry.max + constantValue,
      };
      insuranceLegend.push(insuranceEntry);
    }

    setProfitLegend(insuranceLegend);
  };

  /**
   * Handles displaying Profit Map
   */
  const displayProfitMap = () => (
    <Box
      display="flex"
      flexDirection="column"
      mx={1}
      p={1}
      borderRadius="borderRadius"
      style={{
        marginTop: 100,
        backgroundColor: lightGrey,
      }}
    >
      <Typography color="textPrimary" variant="h6" align="center">
        {`Profit Layers with Insurance Estimates - ${coverageLevel} Coverage`}
      </Typography>

      <InsuranceMap
        boundary={field.boundary}
        mapImage={profitMapImage}
        mapExtents={profitMapExtent}
      />

      {profitLegend !== null && displayLegend()}
    </Box>
  );

  /**
   * Handles displaying Profit Map legend
   */
  const displayLegend = () => (
    <Box p={1} display="flex" flexWrap="wrap">
      {profitLegend.map((x, i) => (
        <Box
          key={i}
          display="flex"
          alignItems="center"
          fontWeight={500}
          fontSize={14}
          borderRadius={5}
          style={{
            backgroundColor: "#ffffff",
            margin: "3px",
            padding: "7px",
          }}
        >
          <FiberManualRecordIcon style={{ color: x.color }} />
          {dollarFormat(x.max)}
          <RemoveIcon style={{ margin: "0 4px" }} />
          {dollarFormat(x.min)}
        </Box>
      ))}
    </Box>
  );

  // Returns specific tool based on displayTool value or all of them for create pdf functionality
  return (
    <Box>
      {displayTool === "What-If Analysis" ? (
        <Box display="flex">
          <WhatIfAnalysis
            mobileView={mobileView}
            acres={acres}
            handleChange={handleInsuranceInputChange}
            updateAdvancedOptions={updateAdvancedOptions}
            advancedOptions={advancedOptions}
            updateInputs={updateWhatIfInputs}
            updateMultipleInputs={updateMultipleInputs}
            inputs={whatIfInputs}
            commodityYear={commodityYear}
            crops={crops}
            crop={crop}
            types={types}
            type={type}
            practices={practices}
            practice={practice}
            approvedYield={approvedYield}
            projectedPrice={projectedPrice}
            insuranceType={insuranceType}
            setInsuranceType={setInsuranceType}
            insuranceUnit={insuranceUnit}
            setInsuranceUnit={setInsuranceUnit}
            allAcres={allAcres}
            setAllAcres={setAllAcres}
            liability={liability}
            liabilityPerAcre={liabilityPerAcre}
            subsidizedPremium={subsidizedPremium}
            subsidizedPremiumAllAcres={subsidizedPremiumAllAcres}
            guarantee={guarantee}
            yields={yields}
            setYields={setYields}
            updateYields={updateRefinedYields}
            getInputAndSendRequest={getInputAndSendRequest}
            exclusionYears={exclusionYears}
            getCalculateYieldFromHistoryData={getCalculateYieldFromHistoryData}
            error={error}
            source={source}
            totalCosts={totalCosts}
            harvestRevenue={harvestRevenue}
            yieldFromOps={yieldFromOps}
            setYieldFromOps={setYieldFromOps}
            setHistoryYields={setHistoryYields}
            historyYields={historyYields}
            totalProfit={totalProfit}
            otherRevenue={otherRevenue}
            setIndemnityPerAcre={setIndemnityPerAcre}
            coverageLevel={coverageLevel}
            setPremiumPerAcre={setPremiumPerAcre}
            locationErrorMessage={locationErrorMessage}
            enqueueSnackbar={enqueueSnackbar}
            disableUserInputsOnLoad={disableUserInputsOnLoad}
            errorMessage={errorMessage}
            previousDefaultRevenue={previousDefaultRevenue}
            setPreviousDefaultRevenue={setPreviousDefaultRevenue}
          />
          <Box>
            {exists([profitMapImage, profitMapExtent]) && displayProfitMap()}
          </Box>
        </Box>
      ) : displayTool === "Premium Calculator" ? (
        <PremiumCalculator
          mobileView={mobileView}
          handleChange={handleInsuranceInputChange}
          updateAdvancedOptions={updateAdvancedOptions}
          advancedOptions={advancedOptions}
          commodityYear={commodityYear}
          crops={crops}
          crop={crop}
          types={types}
          type={type}
          practices={practices}
          practice={practice}
          insuranceType={insuranceType}
          setInsuranceType={setInsuranceType}
          acres={acres}
          projectedPrice={projectedPrice}
          approvedYield={approvedYield}
          insuranceUnit={insuranceUnit}
          setInsuranceUnit={setInsuranceUnit}
          liability={liability}
          liabilityPerAcre={liabilityPerAcre}
          premium={premium}
          totalPremium={totalPremium}
          totalPremiumAllAcres={totalPremiumAllAcres}
          guarantee={guarantee}
          allAcres={allAcres}
          setAllAcres={setAllAcres}
          subsidizedPremium={subsidizedPremium}
          subsidizedPremiumAllAcres={subsidizedPremiumAllAcres}
          yields={yields}
          setYields={setYields}
          updateYields={updateRefinedYields}
          commodityYear={commodityYear}
          exclusionYears={exclusionYears}
          getCalculateYieldFromHistoryData={getCalculateYieldFromHistoryData}
          getInputAndSendRequest={getInputAndSendRequest}
          source={source}
          error={error}
          locationErrorMessage={locationErrorMessage}
          enqueueSnackbar={enqueueSnackbar}
          disableUserInputsOnLoad={disableUserInputsOnLoad}
          errorMessage={errorMessage}
          whatIfInputs={whatIfInputs}
          updateWhatIfInputs={updateWhatIfInputs}
        />
      ) : displayTool === "Insurance AI" ? (
        <InsuranceAI
          acres={acres}
          advancedOptions={advancedOptions}
          approvedYield={approvedYield}
          commodityYear={commodityYear}
          coverageLevel={coverageLevel}
          coverageLevels={coverageLevels}
          crop={crop}
          crops={crops}
          d_Yprc={d_Yprc}
          d_revprc={d_revprc}
          d_yieldprc={d_yieldprc}
          d_priceprc={d_priceprc}
          d_indemprc={d_indemprc}
          disableUserInputsOnLoad={disableUserInputsOnLoad}
          error={error}
          errorMessage={errorMessage}
          exclusionYears={exclusionYears}
          getCalculateYieldFromHistoryData={getCalculateYieldFromHistoryData}
          getInputAndSendRequest={getInputAndSendRequest}
          guaranteeInsurance={guaranteeInsurance}
          handleChange={handleInsuranceInputChange}
          insuranceType={insuranceType}
          locationErrorMessage={locationErrorMessage}
          mobileView={mobileView}
          practice={practice}
          practices={practices}
          projectedPrice={projectedPrice}
          setCoverageLevel={setCoverageLevel}
          setInsuranceType={setInsuranceType}
          setInsuranceLoading={setInsuranceLoading}
          setYields={setYields}
          showTables={showTables}
          soilAndYieldTableInputs={soilAndYieldTableInputs}
          source={source}
          statisticTableInputs={statisticTableInputs}
          type={type}
          types={types}
          updateAdvancedOptions={updateAdvancedOptions}
          updateYields={updateRefinedYields}
          yields={yields}
        />
      ) : displayTool === "All" ? (
        <Box
          style={{
            width: "0px",
            height: "0px",
            overflow: "hidden",
          }}
        >
          <Box
            style={{
              height: "100%",
              width: "100%",
              minWidth: "1000px",
              minHeight: "1000px",
              overflow: "auto",
            }}
          >
            <WhatIfAnalysis
              mobileView={mobileView}
              acres={acres}
              handleChange={handleInsuranceInputChange}
              updateAdvancedOptions={updateAdvancedOptions}
              advancedOptions={advancedOptions}
              updateInputs={updateWhatIfInputs}
              updateMultipleInputs={updateMultipleInputs}
              inputs={whatIfInputs}
              commodityYear={commodityYear}
              crops={crops}
              crop={crop}
              types={types}
              type={type}
              practices={practices}
              practice={practice}
              approvedYield={approvedYield}
              projectedPrice={projectedPrice}
              insuranceType={insuranceType}
              setInsuranceType={setInsuranceType}
              insuranceUnit={insuranceUnit}
              setInsuranceUnit={setInsuranceUnit}
              allAcres={allAcres}
              setAllAcres={setAllAcres}
              liability={liability}
              liabilityPerAcre={liabilityPerAcre}
              subsidizedPremium={subsidizedPremium}
              subsidizedPremiumAllAcres={subsidizedPremiumAllAcres}
              guarantee={guarantee}
              yields={yields}
              setYields={setYields}
              updateYields={updateRefinedYields}
              getInputAndSendRequest={getInputAndSendRequest}
              exclusionYears={exclusionYears}
              getCalculateYieldFromHistoryData={
                getCalculateYieldFromHistoryData
              }
              error={error}
              source={source}
              totalCosts={totalCosts}
              harvestRevenue={harvestRevenue}
              yieldFromOps={yieldFromOps}
              setYieldFromOps={setYieldFromOps}
              setHistoryYields={setHistoryYields}
              historyYields={historyYields}
              setIndemnityPerAcre={setIndemnityPerAcre}
              coverageLevel={coverageLevel}
              locationErrorMessage={locationErrorMessage}
              enqueueSnackbar={enqueueSnackbar}
              disableUserInputsOnLoad={disableUserInputsOnLoad}
              errorMessage={errorMessage}
              previousDefaultRevenue={previousDefaultRevenue}
              setPreviousDefaultRevenue={setPreviousDefaultRevenue}
            />
            <PremiumCalculator
              mobileView={mobileView}
              handleChange={handleInsuranceInputChange}
              updateAdvancedOptions={updateAdvancedOptions}
              advancedOptions={advancedOptions}
              commodityYear={commodityYear}
              crops={crops}
              crop={crop}
              types={types}
              type={type}
              practice={practice}
              practices={practices}
              insuranceType={insuranceType}
              setInsuranceType={setInsuranceType}
              acres={acres}
              projectedPrice={projectedPrice}
              approvedYield={approvedYield}
              insuranceUnit={insuranceUnit}
              setInsuranceUnit={setInsuranceUnit}
              liability={liability}
              liabilityPerAcre={liabilityPerAcre}
              premium={premium}
              totalPremium={totalPremium}
              totalPremiumAllAcres={totalPremiumAllAcres}
              guarantee={guarantee}
              allAcres={allAcres}
              setAllAcres={setAllAcres}
              subsidizedPremium={subsidizedPremium}
              subsidizedPremiumAllAcres={subsidizedPremiumAllAcres}
              yields={yields}
              setYields={setYields}
              updateYields={updateRefinedYields}
              commodityYear={commodityYear}
              exclusionYears={exclusionYears}
              getCalculateYieldFromHistoryData={
                getCalculateYieldFromHistoryData
              }
              getInputAndSendRequest={getInputAndSendRequest}
              source={source}
              error={error}
              locationErrorMessage={locationErrorMessage}
              enqueueSnackbar={enqueueSnackbar}
              disableUserInputsOnLoad={disableUserInputsOnLoad}
              errorMessage={errorMessage}
              whatIfInputs={whatIfInputs}
              updateWhatIfInputs={updateWhatIfInputs}
            />
            <InsuranceAI
              acres={acres}
              advancedOptions={advancedOptions}
              approvedYield={approvedYield}
              commodityYear={commodityYear}
              coverageLevel={coverageLevel}
              coverageLevels={coverageLevels}
              crop={crop}
              crops={crops}
              d_Yprc={d_Yprc}
              d_revprc={d_revprc}
              d_yieldprc={d_yieldprc}
              d_priceprc={d_priceprc}
              d_indemprc={d_indemprc}
              disableUserInputsOnLoad={disableUserInputsOnLoad}
              error={error}
              errorMessage={errorMessage}
              exclusionYears={exclusionYears}
              getCalculateYieldFromHistoryData={
                getCalculateYieldFromHistoryData
              }
              getInputAndSendRequest={getInputAndSendRequest}
              guaranteeInsurance={guaranteeInsurance}
              handleChange={handleInsuranceInputChange}
              insuranceType={insuranceType}
              locationErrorMessage={locationErrorMessage}
              mobileView={mobileView}
              practice={practice}
              practices={practices}
              projectedPrice={projectedPrice}
              setCoverageLevel={setCoverageLevel}
              setInsuranceType={setInsuranceType}
              setInsuranceLoading={setInsuranceLoading}
              setYields={setYields}
              showTables={showTables}
              soilAndYieldTableInputs={soilAndYieldTableInputs}
              source={source}
              statisticTableInputs={statisticTableInputs}
              type={type}
              types={types}
              updateAdvancedOptions={updateAdvancedOptions}
              updateYields={updateRefinedYields}
              yields={yields}
            />
          </Box>
        </Box>
      ) : (
        ""
      )}

      {/* Decides whether to show loading spinner for all the tools. Done here so it doesn't appear while creating PDF */}
      {(loading || insuranceLoading) &&
        displayTool !== "All" &&
        displayTool !== "" && <SpinningLoader />}
    </Box>
  );
}

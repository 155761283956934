import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../../constants";
import ComparisonButtonGroup from "../../../../Helpers/ComparisonButtonGroup";

const useStyles = makeStyles(() => ({
  descriptionContainer: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    alignItems: "center",
    width: "100%",
    borderColor: COLORS.lightGray,
    borderStyle: "solid",
    borderWidth: "0 0 1px 0",
    paddingTop: "21.5px",
    color: COLORS.text,
    fontSize: "14px",
  },
  text: {
    paddingBottom: "35.7px",
  },
}));

/**
 * Renders the market report description
 * @param {array} categories array of object with name and active properties for tabs
 * @param {function} setCategories set state function to set active tab
 * @param {function} valueAccessor function to access name of category
 * @param {boolean} mobileView for mobile views
 */

const Description = ({
  categories,
  setCategories,
  valueAccessor = (d) => d.name,
  mobileView,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.descriptionContainer}>
      <div className={classes.text}>
        ProfitLayers Market Reports compares your production results to
        aggregated, anonymized results from your state. Understand key trends
        within your state (popular hybrids, common soil types, etc.), and use
        our analytics tools to compare your performance to the state average.
      </div>
      <ComparisonButtonGroup
        categories={categories}
        setCategories={setCategories}
        valueAccessor={valueAccessor}
        mobileView={mobileView}
      />
    </div>
  );
};

export default Description;

Description.propTypes = {
  categories: PropTypes.array.isRequired,
  setCategories: PropTypes.func.isRequired,
  valueAccessor: PropTypes.func.isRequired,
  mobileView: PropTypes.bool.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import HistogramContainer from "./HistogramContainer/HistogramContainer";
import CustomMultiSelect from "./CustomMultiSelect";
import InfoPopover from "../../../../Helpers/InfoPopover";

const useStyles = makeStyles((theme) => ({
  wrapper: {
    margin: "15px"
  },
  charts: {
    marginBottom: theme.spacing(2),
  },
  chart: {},
  multiSelect: {
    marginBottom: theme.spacing(2),
  },
  infoPopover: {
    display: "flex",
  },
  label: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

/**
 * Container that shows selected columns and provides filtering capability
 * @param {array} options array of objects with option information
 * @param {function} setOptions set state function
 * @param {array} selectedColumns array of objects with info for each select:
 * {id: string, active: bool, disabled: bool}
 * @param {boolean} isSmallScreen  boolean for mobile views
 */

const FilterResults = ({
  options,
  setOptions,
  selectedColumns,
  isSmallScreen,
  variety
}) => {
  const classes = useStyles({ isSmallScreen });

  // const numericOptions = options.filter((d) => d.numeric);
  // const nonNumericOptions = options.filter((d) => !d.numeric);

  // make array names of columns to show...
  const columnsToShow = variety ? [
    "Crop Name", "Variety Name", "Variety Acreage", "Average Profit"
  ]
  :
  [
    ...new Set(
      selectedColumns
        .map((selectGroup) =>
          selectGroup
            .map((el) =>
              el.id === "None selected"
                ? null
                : el.active === true
                ? el.id
                : null
            )
            .filter((el) => el)
        )
        .flat()
    ),
  ];

  
  console.log("columnsToShow", columnsToShow)

  // filter numeric options for those that are in selectedColumns
  const filteredNumericOptions = options.filter(
    (d) => d.numeric && columnsToShow.includes(d.label)
  );
  const filteredNonNumericOptions = options.filter(
    (d) => !d.numeric && columnsToShow.includes(d.label)
  );
  
  console.log("fileter results options", options)

  return (
    <div className={classes.wrapper}>
      <div className={classes.charts}>
        {filteredNumericOptions.map((option, i) => (
          <div className={classes.chart} key={`histogram-${i}`}>
            <HistogramContainer
              data={option.values}
              option={option}
              options={options}
              setOptions={setOptions}
              isCompact
              isSmallScreen={isSmallScreen}
            />
          </div>
        ))}
      </div>
      <div className={classes.multiSelect}>
        {filteredNonNumericOptions.map((option, i) => (
          <CustomMultiSelect
            key={`custom-multi-select-for-${i}`}
            label={
              <div className={classes.label}>
                {option.label}
                <span className={classes.infoPopover}>
                  <InfoPopover text={option.helper} />
                </span>
              </div>
            }
            option={option}
            options={options}
            setOptions={setOptions}
          />
        ))}
      </div>
    </div>
  );
};

export default FilterResults;

FilterResults.propTypes = {
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

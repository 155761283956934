import React, { useEffect, useState, useContext, Fragment } from 'react'
import { format } from "d3-format";
import {
  AppBar,
  Box,
  Button,
  Typography,
  Slide,
  Divider,
  Checkbox,
  Select,
  Modal,
  TextField,
  Card,
  CardActionArea,
  CardContent,
  Grid,
  Tooltip,
  MenuItem,
  Drawer,
  Accordion,
  AccordionActions,
  AccordionSummary,
  AccordionDetails
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from "../../../styles/select";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import { scrollTo, dollarFormat, exists, createYearArray, formatDate, numFormat } from '../../../utils/helpers'
import { UserContext } from "../../Context/UserContext";
import { lightGrey, green, blackText, grey } from '../../../styles/colors'
import * as d3 from 'd3-geo'
import * as wkt from 'terraformer-wkt-parser';
import { SpinningLoader } from '../../Shared/SpinningLoader';

const NO_DECIMAL_PERCENT_FORMAT = format(",.0%");

const useStyles = makeStyles(theme => ({
  drawer: {
    width: '80%',
    backgroundColor: '#f3f3f3'
  },
  drawerConentContainer: {
    width: '100%',
    height: '100%'
  },
  fieldItem: {
    background: 'white',
    minHeight: '200px',
    minWidth: '325px',
    borderRadius: '20px',
    padding: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.21) 3px 3px 3px 3px',
    marginLeft: '7px',
    marginRight:'3px',
    marginTop: '10px',
    position: "sticky"
  },
  detailsContainer:{
    minHeight: '120px',
    minWidth: '325px',
    marginLeft: '7px',
    marginRight:'3px',
    marginTop: '-30px',
    background: "#c4c4c4",
    borderBottomRightRadius: "20px",
    borderBottomLeftRadius: "20px",
    paddingBottom: '10px',
    paddingTop: '25px'
  },
  detailColumn: {
    marginRight: '25px',
    alignSelf: 'center'
  },
  detailsExpander:{
    display: "flex",
    cursor: "pointer"
  },
  compItem: {
    display:"flex",
    backgroundColor:"white",
    marginLeft: '10px',
    marginRight:'10px',
    marginTop:'10px',
    borderRadius: '5px',
    boxShadow: 'inset 0 0 5px 2px'
  },
  compDetails: {
    display:"flex",
    alignItems: "center"
  },
  compComparisonsContainer: {
    display:"flex"
  },
  compComparisonColumn:{
    display: "flex",
    flexDirection: "column",
    marginLeft: "30px",
    justifyContent: "center"
  },
  barContainer: {
    display: "inline-block",
    position: "relative",
    width: "95%",
    minWidth: '250px',
    height: "28px",
    background: "#f3f3f3",
    margin: 8,
  },
  legendContainer:{
    display: "flex",
    justifyContent: "space-between",
    position: "relative",
    height: "20px",
    paddingLeft: 8,
    marginTop: '10px'
  },
  legendMarkerContainer:{
    marginRight:'33%'
  },
  marker: {
    position: "absolute",
    color: "#999999",
    width: 3,
    height: "100%",
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1.1,
    backgroundColor: "#999999",
    display: "flex",
    alignItems: "center",
    "&.parcel": {
      color: "#67b346",
      backgroundColor: "#67b346",
    },
    "&.county":{
      color: "#e3b50e",
      backgroundColor: "#e3b50e",
    }
  },
  circle: {
    position: "absolute",
    width: 7,
    height: 7,
    borderRadius: "50%",
    top: `calc(50% - 3.5px)`,
    left: -3.5,
    backgroundColor: "inherit",
  },
  markerInner: {
    position: "relative",
    height: "100%",
    width: 0,
    marginLeft: 1.5,
    backgroundColor: "inherit",
  },
  value: {
    position: "absolute",
    whiteSpace: 'nowrap',
    "&.last": {
      paddingLeft: 9,
    },
    "&.first": {
      right: 0,
      paddingRight: 9,
      textAlign: "right",
    },
  },
  differenceContainer: {
    fontSize: 12,
    width: "10%",
    minWidth: 60,
    fontWeight: "bold",
    color: "#67b346",
    "&.negative": {
      color: "#cc0000",
    },
  },
}))




export function Comps({
  comparables,
  countyAverages,
  selectedParcel,
  loaded
}) {
  const classes = useStyles()
  const [user, setUser] = useContext(UserContext);
  const mobileBreakPoint = 860

  const [expanded, setExpanded] = useState(-1);

  // useEffect(() => {
  //   console.log(comparables)
  //   console.log(countyAverages)
  //   console.log(selectedParcel)
  // }, [comparables, countyAverages, selectedParcel])


  const handleExpandClick = (i) => {
    if(expanded === i){
      setExpanded(-1)
    }
    else{
      setExpanded(i)
    }
  }

  const getPath = (shape, width) => {
    try {
      const projection = d3.geoMercator()
      projection.fitExtent([[10, 10], [width, width]], shape);
      const geoPath = d3.geoPath(projection);
      return geoPath(shape)

    } catch (err) {
      console.log(err)
      return ''
    }
  }

  const getNearestIndex = (arr, num) =>{
    let curr = arr[0], diff = Math.abs(num - curr);
    let index = 0;
    for (let val = 0; val < arr.length; val++) {
        let newdiff = Math.abs(num - arr[val]);
        if (newdiff < diff) {
          diff = newdiff;
          curr = arr[val];
          index = val;
        };
    };
    return index;
  }

  const relativeMarkerPositioning = (selectedValue, compValue, countyValue) => {
    //create list out of values and sort in ascending order
    let values = [{val:selectedValue, name:"selected"}, {val:compValue, name:"comp"}, {val:countyValue, name:"county"}]
    values = values.sort((a,b) => a.val-b.val, 0)
    //get the range of the values
    let range = values[values.length-1].val - values[0].val
    //calulate 5% of range and add/subtract to create high/low end barriers
    let perc5 = range * 0.05
    let lowerEnd = values[0].val - perc5
    let upperEnd = values[values.length-1].val + perc5
    //update range
    range = upperEnd - lowerEnd
    //get 1% value of range
    let perc1 = range * 0.01
    //construct list of ~100 entries of possible values
    let valuePositions = []
    for(let i=lowerEnd; i < upperEnd; i+=perc1){
      valuePositions.push(i)
    }
    //get the indecies of the nearest values to the passed in values
    values.map(x => x['index']=getNearestIndex(valuePositions, x.val))
    //construct result
    let result = {}
    values.map(x => result[x.name] = x.index)
    result['lowest'] = values[0].name
    return result
  }


  const drawCompCard = (field, i) => {
    return (
      <Box  key={i}>
        <Box className={classes.fieldItem}>
          <Box display="flex">
            <Box className={classes.detailColumn}>
              <Typography variant="h6">
                <span style={{color:"gray"}}>Sale Date: </span>{formatDate(field.Sale_Date)}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p" style={{display:"flex", justifyContent:"center"}}>
                <svg id={"shapesvg" + i} width='140' height='140'>
                  <path fill="none" stroke="#F05922" strokeWidth="3" d={getPath(field.GeoJSON, 130)} />
                </svg>
              </Typography>
            </Box>

            <Box className={classes.detailColumn}>
              <Typography variant="h6">
                <span style={{color:"gray"}}>Acres: </span>{numFormat(field.Total_Acres)}
              </Typography>
              <Typography variant="h6">
                <span style={{color:"gray"}}>Sale Status: </span>{field.Status}
              </Typography>
              <Typography variant="h6">
                <span style={{color:"gray"}}>Sale Price: </span>{dollarFormat(field.Sale_Price)}
              </Typography>
              <Typography variant="h6">
                <span style={{color:"gray"}}>Price / Acre: </span>{dollarFormat(field.Price_Acre)}
              </Typography>
            </Box>

            <Box className={classes.detailColumn}>
              <Typography variant="h6">
                Precent Tillable: {field.Percent_Tillable}%
              </Typography>
              <Typography variant="h6">
                Listing Agent: {field.Listing_Agent}
              </Typography>
              <Typography variant="h6">
                Sale Type: {field.Sale_Condition}
              </Typography>
              <Typography variant="h6">
                Sale Tax: {dollarFormat(field.Taxes_total)}
              </Typography>
            </Box>
          
          </Box>
          
          <Box className={classes.detailsExpander} onClick={() => handleExpandClick(i)}>
            {expanded === i ?
              <ExpandLessIcon/> 
              :
              <ExpandMoreIcon/>
            }
            <Typography>
              View Details For {field.parcels.length} Parcels  
            </Typography> 
          </Box>
        </Box>
        {/** Comparison for individual parcels will go below */}
        {expanded === i &&
          <Box className={classes.detailsContainer}>
            {field.parcels.map((parcel, j) => drawParcelComparison(parcel, j))}
          </Box> 
        }
      </Box>
    )
  }

  const drawParcelComparison = (parcel, j) => {
    let selectedNCCPI = selectedParcel?.SSURGO?.NCCPI.filter(x=>x.Variable_Name==='NCCPI')[0]?.Value;
    let selectedPH = selectedParcel?.Polaris?.ph_mean_30_60_Mean[0]?.Value;
    let selectedLarger = selectedNCCPI > parcel.NCCPI
    let largerThenCounty = selectedNCCPI > countyAverages.nccpi
    let nccpiDifference = (selectedNCCPI - parcel.NCCPI) / selectedNCCPI
    let phDifference = (selectedPH - countyAverages.ph) / selectedPH   // using county average for now until parcel ph is available
    // let positions = relativeMarkerPositioning(selectedNCCPI, parcel.NCCPI, countyAverages.nccpi)
    let selectedPrecip = (selectedParcel?.Weather?.Precipitation?.map(x => x.Value).reduce((a,b) => a+b,0) / 12)
    let precipDifference = (selectedPrecip - parcel.Precipitation) / selectedPrecip
    
    let NccpiPositions = relativeMarkerPositioning(selectedNCCPI, parcel.NCCPI, countyAverages.nccpi)
    let precipPositions = relativeMarkerPositioning(selectedPrecip, parcel.Precipitation, countyAverages.precipitation)

    return (
      <Box className={classes.compItem} key={j}>{/** Item container */}
        <Box className={classes.compDetails}> {/** boundary and other info */}
          <Box>
            <Typography variant="body2" color="textSecondary" component="p" style={{display:"flex", justifyContent:"center"}}>
              <svg id={"shapesvg" + j} width='110' height='110'>
                <path fill="none" stroke="#F05922" strokeWidth="3" d={getPath(parcel.GeoJSON, 100)} />
              </svg>
            </Typography>
          </Box>
          <Box>
            <Typography variant="h7" component="h6">
              <span style={{color:"gray"}}>Parcel: </span>#{parcel.Parcel}
            </Typography>
            <Typography variant="h7" component="h6">
              <span style={{color:"gray"}}>Acres: </span>{numFormat(parcel.Acres)}
            </Typography>
            <Typography variant="h7" component="h6">
              <span style={{color:"gray"}}>Township: </span>{`${parcel.twnshp} ${parcel.range}`}
            </Typography>
            <Typography variant="h7" component="h6">
              <span style={{color:"gray"}}>Coordinates: </span>{`${numFormat(parcel.lat_center,2,4)}, ${numFormat(parcel.lng_center,2,4)}`}
            </Typography>
          </Box>
          
        </Box>

        <Box className={classes.compComparisonsContainer}> {/** Comparison stuff */}
          <Box className={classes.compComparisonColumn}> {/** First set of comparisons */}
            <Box className={classes.legendContainer}> {/** Legend info */}
              {drawLegendMarker("Selected Value", "parcel")}
              {drawLegendMarker("Parcel Value", "comp")}
              {drawLegendMarker("County Value", "county")}
            </Box>
            <Box display="flex" style={{alignItems:"center"}}>
              <Box style={{minWidth:"75px", textAlign:"center"}}>NCCPI</Box>
              <Box className={classes.barContainer}>
                {drawBarMarker(numFormat(parcel.NCCPI), NccpiPositions['comp'], "comp", NccpiPositions['lowest'] === 'comp')}
                {drawBarMarker(numFormat(selectedNCCPI), NccpiPositions['selected'], "parcel", NccpiPositions['lowest'] === 'selected')}
                {drawBarMarker(numFormat(countyAverages.nccpi), NccpiPositions['county'], "county", NccpiPositions['lowest'] === 'county')}
              </Box>
              <Box className={`${classes.differenceContainer} ${nccpiDifference < 0 ? "negative" : ""}`}>
                {nccpiDifference < 0 ?
                  <Box>
                    <RemoveCircleIcon/> {NO_DECIMAL_PERCENT_FORMAT(nccpiDifference)}
                  </Box>
                  :
                  <Box>
                    <AddCircleIcon/> {NO_DECIMAL_PERCENT_FORMAT(nccpiDifference)}
                  </Box>
                }
              </Box>
            </Box>
          </Box>
          <Box className={classes.compComparisonColumn}> {/** Second set of comparisons */}
            {/** using NCCPI AGAIN as a placeholder */}
            <Box display="flex" style={{alignItems:"center"}}> 
              <Box style={{minWidth:"75px", textAlign:"center"}}>Soil PH</Box>
              <Box className={classes.barContainer}>
                {/* {drawBarMarker(numFormat(parcel.NCCPI), numFormat(Number(parcel.NCCPI)*100), "comp", !selectedLarger)} */}
                {drawBarMarker(numFormat(selectedPH), numFormat(Number(selectedPH)*10), "parcel", selectedPH > countyAverages.ph)}
                {drawBarMarker(numFormat(countyAverages.ph), numFormat(countyAverages.ph*10), "county", countyAverages.ph > selectedPH)}
              </Box>
              <Box className={`${classes.differenceContainer} ${phDifference < 0 ? "negative" : ""}`}>
                {phDifference < 0 ?
                  <Box>
                    <RemoveCircleIcon/> {NO_DECIMAL_PERCENT_FORMAT(phDifference)}
                  </Box>
                  :
                  <Box>
                    <AddCircleIcon/> {NO_DECIMAL_PERCENT_FORMAT(phDifference)}
                  </Box>
                }
              </Box>
            </Box>
            <Box display="flex" style={{alignItems:"center"}}> 
              <Box style={{minWidth:"75px", textAlign:"center"}}>Precipitation</Box>
              <Box className={classes.barContainer}>
                {drawBarMarker(numFormat(parcel.Precipitation), precipPositions['comp'], "comp", precipPositions['lowest'] === 'comp')}
                {drawBarMarker(numFormat(selectedPrecip), precipPositions['selected'], "parcel", precipPositions['lowest'] === 'selected')}
                {drawBarMarker(numFormat(countyAverages.precipitation), precipPositions['county'], "county", precipPositions['lowest'] === 'county')}
              </Box>
              <Box className={`${classes.differenceContainer} ${precipDifference < 0 ? "negative" : ""}`}>
                {precipDifference < 0 ?
                  <Box>
                    <RemoveCircleIcon/> {NO_DECIMAL_PERCENT_FORMAT(precipDifference)}
                  </Box>
                  :
                  <Box>
                    <AddCircleIcon/> {NO_DECIMAL_PERCENT_FORMAT(precipDifference)}
                  </Box>
                }
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>
    )
  }

  const drawBarMarker = (value, position, type, isLarger) => {
    return(
      <div
        className={`${classes.marker} ${type === "parcel" ? "parcel" : type==="county" ? "county" : ""}`}
        style={{ left: `${position}%` }}
      >
        <div className={classes.markerInner}>
          <span className={classes.circle} />
        </div>
        <span className={`${classes.value} ${isLarger ? "last" : "first"}`}>
          {value}
        </span>
      </div>
    )
  }

  const drawLegendMarker = (value, type, position) => {
    return(
      <Box className={classes.legendMarkerContainer}>
        <div
          className={`${classes.marker} ${type === "parcel" ? "parcel" : type==="county" ? "county" : ""}`}
        >
          <div className={classes.markerInner}>
            <span className={classes.circle} />
          </div>
          <span className={`${classes.value} ${"last"}`}>
            {value}
          </span>
        </div>
      </Box>
      
    )
  }

  return (
    <Box>
      <Box>
        {comparables.map((x, i) => drawCompCard(x, i))}
      </Box>

      {!loaded &&
        <SpinningLoader />
      }
    </Box>
  )



}






import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Modal, Button, Divider} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blackText } from '../../styles/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    '&:hover': {
        cursor: 'pointer',
    },
  },
}));

function getModalStyle() {
  const top = 20;
  return {
    top: `${top}%`,
    margin:'auto',
    zIndex: 1002,
    width: 300,
    padding: 4,
  };
}

/**
 * [BeginClaim description]
 * @param {Bool} open Modal is open
 * @param {Function} setOpen Set modal is open
 * @param {Function} startClaim Continue to claim field
 * @param {Function} setEditing Edit/add to field
 * @param {String} continueText Used to determine parent
 * @param {Function} continueWithoutClaim In datalayers, option to move on without claim
 * @param {Function} goToDataLayers Go directly to view data layers
 */
export function BeginClaim({open, setOpen, startClaim, setEditing, continueText, continueWithoutClaim, goToDataLayers}) {
  const classes = useStyles();
  const modalStyle = getModalStyle();

  const continueToClaim = () => {
    startClaim();
    setOpen(false)
    setEditing(false)
  }

  const handleEdit = () => {
    setEditing(true)
    setOpen(false)
  }

  const handleClose = () => {
    setEditing(false)
    setOpen(false)
  }

  const handleContinueWithoutClaim = () => {
    setOpen(false);
    continueWithoutClaim(1)
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="field-claim-prompt"
      aria-describedby="procede-field-claim-prompt"
      style={{
        display:'flex',
        alignItems:'center',
        justifyContent:'center'
      }}
    >
      <Box
        className={classes.root}
        style={modalStyle}
        height={continueText === 'Create ProfitLayers' || continueText === 'View Data Layers' ? 370 : 280}
        borderRadius="borderRadius"
        boxShadow={2}
        fontWeight={500}
        fontSize={16}
        color={blackText}
      >

        <Box
          display="flex"
          justifyContent='space-between'
          style={{width: '100%', padding: 4}}
        >
          <Box>
            &nbsp;
          </Box>
          <Box fontSize={18}>
            Claim Field
          </Box>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => handleClose()}
          />
        </Box>

        <Divider style={{width: '100%', height: 1}}/>

        <Box p={1}>
          Would you like to edit or add more parcels to this field?
        </Box>

        <Button
          color='primary'
          variant='outlined'
          onClick={() => handleEdit()}
          style={{width: 200}}
        >
          Edit
        </Button>

        <Box mt={1} p={1}>
          If your field is complete, please continue to complete claim.
        </Box>

        <Button
          color='primary'
          variant='outlined'
          onClick={continueToClaim}
          style={{width: 200}}
        >
          Continue
        </Button>

        {
          continueText === 'View Data Layers' && (
            <React.Fragment>
              <Box mt={1} p={1}>
                View Data Layers for field without claiming.
              </Box>

              <Button
                color='primary'
                variant='outlined'
                onClick={() => handleContinueWithoutClaim()}
                style={{width: 200}}
              >
                View Data Layers
              </Button>
            </React.Fragment>
        )}

        { continueText === 'Create ProfitLayers' && (
          <>
            <Box mt={1} p={1} fontSize={18}>
              View Data Layers (Free)
            </Box>

            <Button
              id="go_to_data_layers"
              color='primary'
              variant='outlined'
              onClick={() => goToDataLayers()}
              style={{width: 200}}
            >
              Go To Data Layers
            </Button>
          </>
        )}
      </Box>
    </Modal>
  )
}

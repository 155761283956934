// #region - imports
import React, { useState, useEffect } from 'react';

import { makeStyles } from '@material-ui/core/styles';
import { green } from '../../../../styles/colors';
import { zoneColors } from '../../../Maps/Styles/layerStyles';

import {
  Box,
  CircularProgress,
  LinearProgress,
  Button,
  Divider,
  TextField,
  Popover,
  Chip,
  Typography,
  Checkbox,
  Menu,
  MenuItem,
} from '@material-ui/core';
import { ArrowDropDownCircle } from '@material-ui/icons';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import UpdateIcon from '@material-ui/icons/Update';

import rewind from '@mapbox/geojson-rewind';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

import * as con from '../../utils/conversions';
import { CustomToolTip } from '../../../../utils/customComponents';
import {
  getGeoJson,
  requestYieldForecast,
} from '../../../../utils/dataFetchers';
import { useWindowDimensions } from '../../../../utils/dimensions';
import { exists, numFormat } from '../../../../utils/helpers';
import { NumberFormatRMAArea } from '../../../../utils/NumberFormatCustom';

import { Zone } from './Zone';
import { ZoneMap } from '../../../Maps/ZoneMap';
import { highlightFeature } from '../../../Maps/MapFunctions/helpers';
import { SpinningLoader } from '../../../Shared/SpinningLoader';
import { ConfirmationPopup } from '../Shared/ConfirmationPopup';
import { updateEditedZones, addZoneColor } from '../Shared/zoneFunctions';
import { RangeSlider } from '../../Reports/Ranges/RangeSlider';
import { Endpoints } from '../../../../constants/Endpoints';
// #endregion

const marginStyle = {
  marginTop: '5px',
  marginBottom: '5px',
};

const useStyles = makeStyles((theme) => ({
  paper: theme.updateOperationModal,
  head: theme.updateOperationHeader,
  body: theme.updateOperationBody,
  footer: theme.updateOperationFooter,
  mapControls: theme.mapControls,
  details: theme.plDetails,
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  errorMessageBox: theme.errorMessageBox,
  selections: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: 16,
    width: '200px',
  },
  dropdown: {
    ...theme.hover,
    fontSize: '14px',
    marginLeft: '5px',
  },
}));


/**
 * Create new and update harvest and seeding operations. User will have the
 * ability to draw zones on map and create operations for each zone. Harvest
 * operations will recieve displayedSeedings in seeding paramater to be used
 * for optional yield forecasting.
 * @param {String} type Operation type, either Harvest or Seeding.
 * @param {String} action Add or Edit.
 * @param {Object} operationToEdit Operation to load data from and edit.
 * @param {Object} field Field data.
 * @param {Bool} perAcre Data is per acre or per field.
 * @param {Function} setOpen Determines if display modal
 * @param {Function} save Save new or edited operation.
 * @param {Array} operations All operations of type for selected year
 * @param {Function} setOperations Updates operations
 * @param {*} saveOperation
 * @param {Array} seedings Seeding operations used for yield forecast
 * @param {Number} year Selected year of operations.
 * @param {Array} years list of selected years
 * @param {Bool} forecast Determines if opens yield forecast on load.
 * @param {*} isOperationMap
 * @param {*} setOperationMap
 * @param {*} setOperationMapDis
 * @param {*} setProfitMapResponse
 * @param {*} setOperationUnit
 * @param {*} operationMapDis
 * @param {*} operationUnit
 * @param {*} profitMapResponse
 * @param {*} refreshOperations
 * @param {*} getOperationMap
 * @param {Boolean} isQuantityMap sets state for quantity map vs cost map
 * @param {Function} setIsQuantityMap sets state of isQUantityMap
 * @param {*} selectedOp
 * @param {*} isVarietyMap
 * @param {*} setSelectedOp
 * @returns {JSX} Add/edit seeding/harvest operation modal
 */
export function UpdatePlantings({
  type,
  action,
  operationToEdit,
  field,
  perAcre,
  setOpen,
  save,
  operations,
  setOperations,
  saveOperation,
  seedings,
  year,
  years,
  forecast,
  isOperationMap,
  setOperationMap,
  setOperationMapDis,
  setProfitMapResponse,
  setOperationUnit,
  operationMapDis,
  operationUnit,
  profitMapResponse,
  refreshOperations,
  getOperationMap,
  isQuantityMap,
  setIsQuantityMap,
  selectedOp,
  isVarietyMap,
  setSelectedOp,
}) {
  // #region - variables
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [source, setSource] = useState('');
  const [manualEntry, setManualEntry] = useState(true);
  const [zoneGeo, setZoneGeo] = useState({});

  // varieties from edit and newly created zones
  const [varieties, setVarieties] = useState([]);
  const [editedVarieties, setEditedVarieties] = useState([]);

  const [selectVarietyOpen, setSelectVarietyOpen] = useState(false);
  const [selectedVariety, setSelectedVariety] = useState('');

  // Date value used when not forecasting yield
  const [date, setDate] = useState(con.formatDate(new Date()));
  const [crop, setCrop] = useState('CORN');
  const [acres, setAcres] = useState(numFormat(field.acres));

  // zones with details visible
  const [displayDetails, setDisplayDetails] = useState(true);
  // array of index of zones to display
  const [displayZones, setDisplayZones] = useState([0]);

  // use appropriate units for operation
  const units = type === 'Seeding' ? con.seedingUnits : con.harvestUnits;
  const [unit, setUnit] = useState(units[0]);
  const [inputValue, setInputValue] = useState('');

  const [cropInputValue, setCropInputValue] = useState('CORN');
  const [allcrops, setCrops] = useState([]);

  // for yield forecast
  const [forecastYield, setForecastYield] = useState(forecast);
  const [plantingDate, setPlantingDate] = useState(`${new Date().getFullYear()}-05-01`);
  const [forecastDate, setForecastDate] = useState('');
  const [seedsPerAcre, setSeedsPerAcre] = useState(30000);
  const [selectedSeeding, setSelectedSeeding] = useState('');

  // for downloads
  const [downloadsPopoverOpen, setPopoverOpen] = useState(false);
  const [downloadsPopoverAnchor, setPopoverAnchor] = useState(null);
  const [downloads, setDownloads] = useState(undefined);
  const [downloadsLoading, setDlLoading] = useState(false);

  // confirmation dialog
  const [openConfirm, setOpenConfirm] = useState(false);

  // zones passed up from map
  const [zone, setZone] = useState({});
  const [zones, setZones] = useState([]);

  const [clickedFeature, setClickedFeature] = useState();
  const [allClickedFeatures, setAllClickedFeatures] = useState([]);
  const [deletedSliceZones, setDeletedSliceZones] = useState([]);

  const [combination, setCombination] = useState([]);

  const manuals = ['USERENTERED', 'AGANALYTICS', 'TEMPLATE', 'AG-ANALYTICS', undefined];

  const [usedZoneColors, setUsedZoneColors] = useState([]);
  const [zoneCount, setZoneCount] = useState(0);
  let numOfZones = zoneCount;

  const [opsDropDown, setOpsDropDown] = useState(operationToEdit.varieties);

  const [editLayers, setEditLayers] = useState([]);
  // #endregion

  // #region - useEffects
  // Set default planting date and forecast date
  useEffect(() => {
    setPlantingDate(`${year}-05-01`);
    // Do not use current date as that is too arbitrary
    // November - December seems a common date according to: https://swat.tamu.edu/media/90113/crops-typicalplanting-harvestingdates-by-states.pdf
    const hDate = `${year}-10-01`;
    setForecastDate(hDate);
  }, []);

  // on initial component load - send request to get available downloads
  useEffect(() => {
    if (action === 'Edit') {
      let url = `${Endpoints.BASEURL}/api/ProfitTool/DeereOperation/blob`;
      url += `?orgID=${operationToEdit.orgId}`;
      url += `&fieldID=${operationToEdit.fieldId}`;
      url += `&operationID=${operationToEdit.operationID}`;
      url += `&operationType=${type}`;
      url += `&source=${operationToEdit.source}`;

      setDlLoading(true);
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          // console.log("Failed to get file manifest");
          // console.log(res);
          setDlLoading(false);
        })
        .then((res) => {
          setDownloads(JSON.parse(res));
          setDlLoading(false);
        })
        .catch((err) => {
          // console.log("Download file manifest failed", err);
          setDlLoading(false);
        });
    }
  }, []);

  useEffect(() => {
    if (type === 'Seeding') {
      setForecastYield(false);
    }
  }, [type]);

  useEffect(() => {
    if (action === 'Edit') {
      setLoading(true);
      setForecastYield(false);
      if (selectedVariety === '' && operationToEdit.varieties !== undefined) {
        const varietyMap = {
          operationID: operationToEdit.varieties[0].operationID,
          productName: operationToEdit.varieties[0].productName,
          date: operationToEdit.varieties[0].date,
          variety: null,
          fileType: operationToEdit.varieties[0].fileType,
          attribute: operationToEdit.varieties[0].attribute,
          cellsize: operationToEdit.varieties[0].cellsize,
          operationCost: operationToEdit.varieties[0].operationCost,
          price: operationToEdit.varieties[0].price,
          unit: operationToEdit.varieties[0].unit,
          name: 'Variety Map',
          seedingOperationID: operationToEdit.varieties[0].operationID,
        };
        const wholeOperationMap = {
          operationID: operationToEdit.varieties[0].operationID,
          productName: operationToEdit.varieties[0].productName,
          date: operationToEdit.varieties[0].date,
          variety: null,
          fileType: operationToEdit.varieties[0].fileType,
          attribute: operationToEdit.varieties[0].attribute,
          cellsize: operationToEdit.varieties[0].cellsize,
          operationCost: operationToEdit.varieties[0].operationCost,
          price: operationToEdit.varieties[0].price,
          unit: operationToEdit.varieties[0].unit,
          name: `${operationToEdit.varieties[0].productName} (${operationToEdit.varieties[0].date})`,
          seedingOperationID: operationToEdit.varieties[0].operationID,
        };
        setSelectedVariety(wholeOperationMap);
        // console.log([wholeOperationMap, ...operationToEdit.varieties])
        setOpsDropDown([wholeOperationMap, varietyMap, ...operationToEdit.varieties]);
      }
      handleOperationToEdit(operationToEdit);
    } else {
      handleNewOperation();
    }
  }, [operationToEdit, field, action]);

  useEffect(() => {
    exists(zones) && zones.map((zone) => handleZone(zone.zone, zone.type));
  }, [zones]);

  useEffect(() => {
    numOfZones = zoneCount;
  }, [zoneCount]);

  useEffect(() => {
    const colors = varieties.map(
      (x) => x?.zone?.properties?.color,
    ).filter((x) => x !== undefined);
    setUsedZoneColors(colors);
  }, [varieties]);
  
  useEffect(() => {
    let varietiesAcres = 0;
    varieties.forEach((x) => { varietiesAcres += x.area; });
    setAcres(numFormat(varietiesAcres));
  }, [varieties]);

  useEffect(() => {
    if (editedVarieties.length > 0) {
      updatedEditedVarietiesZones(editedVarieties);
    }
  }, [editedVarieties]);

  useEffect(() => {
    if (clickedFeature != undefined) {
      let ndx = -1;
      let i = 0;
      const clickedCoords = clickedFeature.geometry.coordinates[0];
      for (const vari of varieties) {
        const zoneRef = vari.zone.geometry.coordinates[0];
        if (JSON.stringify(clickedCoords) === JSON.stringify(zoneRef)) {
          ndx = i;
        }
        i++;
      }
      if (ndx !== -1) {
        setDisplayZones([ndx]);
      }
    }
  }, [clickedFeature]);
  // #endregion

  // #region - handle operation info
  const handleNewOperation = (feature = null) => {
    numOfZones++;
    setZoneCount(numOfZones);
    // console.log(feature)
    const variety = {
      date,
      unit,
      area: 0,
      cellsize: '0.0001',
      fileType: type,
      isIrrigated: false,
      isOrganic: false,
      operationCost: 0,
      operationID: '',
      price: 0,
      productName: '',
      quantity: 0,
      source: 'USERENTERED',
      total: 0,
      variety: '',
    };

    let updatedVarieties = [];

    const geoVarieties = varieties;
    /* Check if manual zones exist that were created previously. If yes, then update geojson of those
    varities or else create new variety with associated zone drawn */
    if (
      Object.keys(zoneGeo).length > 0
      && numOfZones <= zoneGeo.features.length
      && geoVarieties[numOfZones - 1] !== undefined
      && action !== 'Edit'
    ) {
      geoVarieties[numOfZones - 1].zone = feature;
      geoVarieties[numOfZones - 1].area = feature.properties.CALCACRES;
      if (feature.properties.color !== undefined) {
        geoVarieties[numOfZones - 1].color = feature.properties.color;
      }
      updatedVarieties = [...geoVarieties];
      setVarieties([...geoVarieties]);
    } else {
      if (feature !== null) {
        variety.zone = feature;
        variety.area = feature.properties.CALCACRES;

        if (feature.properties.Soil_Type !== undefined) {
          variety.soilType = feature.properties.Soil_Type;
        }
        if (feature.properties.color !== undefined) {
          variety.color = feature.properties.color;
        } else {
          const usedColors = varieties.map((x) => x.color);
          variety.color = addZoneColor(zoneColors, usedColors, variety);
        }
      } else if (field.boundary !== '') {
        const feature = JSON.parse(field.boundary);
        feature.properties.CALCACRES = field.acres;
        variety.zone = feature;
        variety.area = field.acres;
      } else {
        // this probably shouldnt happen
        variety.zone = {};
      }

      variety.productName = crop;

      if (varieties.length > 0) {
        // set new values to that of first variety
        variety.price = varieties[0].price;
        variety.rate = varieties[0].rate;
        variety.total = varieties[0].total;
        variety.variety = varieties[0].variety;
      } else if (action === 'Edit' && operationToEdit?.varieties.length > 0) {
        // Editing, but due to split variety has been cleared
        const varietyToCopy = operationToEdit.varieties[0];

        variety.price = varietyToCopy.price;
        variety.rate = varietyToCopy.rate;
        variety.total = varietyToCopy.rate * varietyToCopy.price;
        variety.variety = varietyToCopy.variety;
      } else {
        // set price, rate, and variety to defaults
        const price = type === 'Seeding' ? 0.003 : 3.50;
        const rate = type === 'Seeding' ? 30000 : 185;
        variety.price = price;
        variety.rate = rate;
        variety.total = price * rate;
        variety.variety = 'DKC64-35';
      }

      variety.quantity = variety.rate * variety.area;
      updatedVarieties = [...varieties, variety];
      setVarieties((varieties) => [...varieties, variety]);
    }

    // set display to newly created zones index
    setDisplayZones([updatedVarieties.length - 1]);
  };

  const handleOperationToEdit = async (op) => {
    /** Set appropriate states for edit operation. If manual operation, get
     *  geoJson.
     */
    setCrop(op.name);
    setSource(op.source);
    let varieties = [];
    if (op.varieties !== undefined) {
      setUnit(con.unitConversion(op.varieties[0].unit, true));
      // Clone varieties
      const clonedVarieties = cloneDeep(op.varieties);
      varieties = clonedVarieties.map((x) => ({ ...x, total: (x.price * x.rate) }));
      // varieties = op.varieties.map((x) => JSON.parse(JSON.stringify(x)));
    }

    if (op.date !== undefined) {
      setDate(con.formatDate(op.date));
    }
    const neededColors = []; // empty array for colors for field
    if (op.source === undefined || manuals.includes(op.source.toUpperCase())) {
      setManualEntry(true);

      // get geoJson to overlay on map. each variety will have also their own
      const geo = await getGeoJson(op.orgId, op.fieldId, op.operationID, type);

      // need to construct varieties out of geojson
      // cycle over varieties -> find geo zones with matching varieties,
      // loop over matching zones and create new varities
      const newVarieties = [];

      for (const variety of varieties) {
        try {
          const matching = geo.features.filter((x) => x.properties.variety === variety.variety);

          for (const match of matching) {
            // make copy of variety
            const vCopy = JSON.parse(JSON.stringify(variety));

            const matchedPrice = variety.price;
            const matchedUnit = match.properties.unit;
            const varUnit = variety.unit;

            const rate = operationToEdit.varieties[0].unit.toUpperCase() === 'K SEEDS' ? match.properties.rate / 1000 : match.properties.rate;

            // update with info from geojson properties
            vCopy.area = match.properties.area;
            vCopy.rate = rate;
            vCopy.total = rate * matchedPrice;
            vCopy.quantity = match.properties.quantity;
            vCopy.price = matchedPrice;
            vCopy.color = match.properties.color;
            vCopy.zone = match;

            match.color = vCopy.color;
            setUsedZoneColors((prev) => [...prev, vCopy.color]);
            console.log(vCopy.color);
            newVarieties.push(vCopy);
          }
        } catch (err) {
          console.log(err);
        }
      }

      varieties = newVarieties;
      setZoneGeo(geo);
      if (geo?.features[0]?.properties?.area) {
        setAcres(numFormat(geo.features[0].properties.area));
      } else {
        setAcres(numFormat(field.acres));
      }
    } else {
      setManualEntry(false);
      setAcres(numFormat(field.acres));
    }
    // console.log("setting varieties", varieties)
    setVarieties(varieties);
    setLoading(false);
  };

  const displaySource = (agSource) => {
    if (agSource === 'Deere') {
      return 'John Deere Operations Center';
    } if (agSource === 'Climate') {
      return 'Climate FieldView';
    }
    return agSource;
  };
  // #endregion

  // #region - zone updates
  const handleVarietyMapClick = (event, imageOverlay) => {
    // console.log(event.latlng)
    // console.log(imageOverlay)
  };

  const updateZonesFromEditedBoundary = (geoJson, newAcres) => {
    if (varieties.length === 1) {
      const updatedVariety = {
        ...varieties[0],
        area: newAcres,
        zone: geoJson,
      };
      setVarieties([updatedVariety]);
    }
  };

  const handleZone = (data, zoneAction) => {
    /* Takes data, usually features, from map. Uses action string to detemine
     *  how to handle.
     */
    // Adding and editing need different checks to determinte if need to
    // remove first zone when it is complete field
    let acresToCheck = -1;
    if (action === 'Add' && varieties.length > 0) {
      acresToCheck = varieties[0].area;
    } else {
      // New operation
      if (data !== undefined && data !== null && data.properties !== undefined) {
        acresToCheck = data.properties.CALCACRES;
      } else {
        // Field has no zones
        acresToCheck = field.acres;
      }
    }

    switch (zoneAction.toLowerCase()) {
      case 'add':
        if (varieties.length === 1 && acresToCheck === field.acres) {
          // update initial zone to current drawn acres
          const newZone = [...varieties][0];
          newZone.area = data.properties.CALCACRES;
          newZone.zone = data;
          newZone.quantity = newZone.area * newZone.rate;

          if (data.properties.color !== undefined) {
            newZone.color = data.properties.color;
          } else {
            newZone.color = zoneColors[0];
          }
          if (data.properties.mapRef !== undefined) {
            newZone.mapRef = data.properties.mapRef;
          }
          if (data.properties.featureRef !== undefined) {
            newZone.featureRef = data.properties.featureRef;
          }
          if (data.properties.Soil_Type !== undefined) {
            newZone.soilType = data.properties.Soil_Type;
          }

          setVarieties([newZone]);
        } else {
          // create new zone from feature
          handleNewOperation(data);
        }
        break;
      case 'edit':
        // for editing polygons
        // copy variety list
        const varietyCopy = [...varieties];
        // get variety index
        const ndx = varietyCopy.findIndex((x) => x.zone.properties.id === data.properties.id);

        // //update variety
        varietyCopy[ndx].area = data.properties.CALCACRES;
        varietyCopy[ndx].zone = data;
        varietyCopy[ndx].quantity = varietyCopy[ndx].rate * varietyCopy[ndx].area;
        if (data.properties.color !== undefined) {
          varietyCopy[ndx].color = data.properties.color;
        }
        if (data.properties.mapRef !== undefined) {
          varietyCopy[ndx].mapRef = data.properties.mapRef;
        }
        if (data.properties.featureRef !== undefined) {
          varietyCopy[ndx].featureRef = data.properties.featureRef;
        }

        // reset varieteies with new info
        setVarieties(varietyCopy);

        break;
      case 'undo':
        removeZone(varieties.length - 1);
        break;
      case 'clear':
        setVarieties([]);
        setDisplayZones([]);
        break;
      case 'reset':
        // in this case takes a feature collection containing all zones
        // and updates all with data
        const resetCopy = [...varieties];
        for (const feature of data.features) {
          const ndx = resetCopy.findIndex((x) => x.zone.properties.id === feature.properties.id);

          // //update variety
          resetCopy[ndx].area = feature.properties.CALCACRES;
          resetCopy[ndx].zone = feature;
          resetCopy[ndx].quantity = resetCopy[ndx].rate * resetCopy[ndx].area;
          if (feature.properties.color !== undefined) {
            resetCopy[ndx].color = feature.properties.color;
          }
          if (feature.properties.mapRef !== undefined) {
            resetCopy[ndx].mapRef = feature.properties.mapRef;
          }
          if (feature.properties.featureRef !== undefined) {
            resetCopy[ndx].featureRef = feature.properties.featureRef;
          }
        }

        setVarieties(resetCopy);
        break;
      default:
        // console.log(`unexpected action: ${action}`);
    }
  };

  const removeZone = (i) => {
    const removedZones = [...deletedSliceZones];
    try {
      // This first try is in case something goes wrong identifying the zone to be removed
      // this way it wont crash, but instead the zone just wont be removed from the map

      const zoneToRem = varieties[i];
      removedZones.push(zoneToRem);
      setDeletedSliceZones(removedZones);

      const zoneToRemCoords = zoneToRem.zone.geometry.coordinates[0];
      const map = zoneToRem.zone.properties.mapRef;

      map.eachLayer((layer) => {
      // here we're going to identify the zone we want to remove
      // then do a map.removeLayer(layer);
        try {
          const currLayerCoords = layer.feature.geometry.coordinates[0];
          if (JSON.stringify(currLayerCoords) == JSON.stringify(zoneToRemCoords)) {
            console.log(currLayerCoords, zoneToRemCoords);
            console.log(layer);
            map.removeLayer(layer);
          }
        } catch (err) {
        }
      });
    } catch (err) {}

    const varietiesCopy = [...varieties];
    varietiesCopy.splice(i, 1);
    setVarieties(varietiesCopy);
  };

  const handleZoneChange = (i) => {
    if (displayZones.includes(i)) {
      setDisplayZones(displayZones.filter((x) => x !== i));
    } else {
      setDisplayZones([...displayZones, i]);
    }
    // console.log("setting hilighted", varieties[i])
    // setHilighted(varieties[i].zone)
    highlightFeature(i, varieties);
  };

  const updateZoneCrop = (val) => {
    const varietyClone = cloneDeep(varieties);
    const updatedVarieties = varietyClone.map((x) => ({ ...x, productName: val }));
    setVarieties(updatedVarieties);
  };
  // #endregion

  // #region - handle input updates
  const updatedEditedVarietiesZones = (editedVarieties) => {
    const updated = updateEditedZones(editedVarieties, varieties);
    setVarieties(updated);
    setEditedVarieties([]);
  };

  const updateGeoJson = (geoJson, newAcres) => {
    if ('color' in geoJson) {
      // This means we are editing an existing zone. The way this function is
      // use in ZoneMap we won't have access to varieties state, so we need to
      // set editedVariety state and let a useEffect call function to update
      // edited variety in varieties array.
      setEditedVarieties((prev) => [...prev, { zone: geoJson, acres: newAcres }]);
    } else {
      updateZonesFromEditedBoundary(geoJson, newAcres);
    }
  };

  const handleUpdateOnChange = (index, val, type) => {
    const varietiesCopy = [...varieties];

    // remove from varietiesCopy array and return variety to edit
    const edit = varietiesCopy.splice(index, 1)[0];

    // standarize all NaNs for checking before updating calculations
    const value = !Number.isNaN(val) ? val : '';

    edit[type] = value;

    // add updated variety back at correct index
    varietiesCopy.splice(index, 0, edit);
    setVarieties(varietiesCopy);
  };

  const handleVarietyUpdate = (index, val, type) => {
    /* Takes index of variety to be updates, value to be updates, and key (type).
     *  For price, total, and quantity use the given value to calculate other
     *  appropriate value to update.
     */
    const varietiesCopy = [...varieties];

    // remove from varietiesCopy array and return variety to edit
    const edit = varietiesCopy.splice(index, 1)[0];

    // standarize all NaNs for checking before updating calculations
    const value = !Number.isNaN(val) ? val : '';

    let updatePrice = 0;
    switch (type) {
      case 'price':
        if (edit.rate !== 0 && value !== '') {
          edit.total = edit.rate * value;
        }
        edit.price = value;
        break;
      case 'total':
        edit.total = value;
        if (edit.rate !== 0 && edit.rate !== '' && value !== '') {
          const newPrice = value / edit.rate;
          // with very small numbers, this could get set to small decimal value
          // than rounding textfield can display.
          edit.price = newPrice >= 0.00001 ? newPrice : 0.00001;
        }
        break;
      case 'rate':
        if (value !== 0) {
          updatePrice = perAcre
            ? value * edit.price
            : value * edit.price * edit.area;
          edit.total = updatePrice;
          edit.quantity = value * edit.area;
          edit[type] = value;
        }
        break;
      default:
        // update value at key
        edit[type] = value;
        break;
    }

    // add updated variety back at correct index
    varietiesCopy.splice(index, 0, edit);

    setVarieties(varietiesCopy);
  };

  const handleUnitUpdate = (newUnit) => {
    // console.log(varieties)
    setUnit(newUnit);
    // update for varieties
    const varietyCopy = [...varieties];
    const updated = varietyCopy.map((x) => ({ ...x, unit: newUnit }));
    setVarieties(updated);
  };

  const handleCropInput = async (val) => {
    /** This is the function from the old PL tool to retrieve crop names
     * based on user input. The api performs the autocomplete functionaity on keypress. * */
    if (val && val !== '' && !allcrops.includes(val)) {
      let url = Endpoints.BASEURL;
      url
        += type === 'Harvest' ? Endpoints.HARVESTCROPNAME : Endpoints.SEEDCROPNAME;
      url += `/${val}`;
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error('Failed to get crop names');
        })
        .then((crops) => setCrops(crops))
        .catch((err) => enqueueSnackbar(err));
    }

    // Some names that don't get returned by call to SeedCropName still work. So we need a way to
    // tell whether they'll work or not for sure before doing what's commented below.
    // // If there are no valid matches for user input
    // if (!crops.length) {
    //   enqueueSnackbar('This crop is not available. Please enter another one.');
    //   return;
    // }

    setCropInputValue(val);
    setCrop(val);
    updateZoneCrop(val);
  };

  const handleVarietySelect = (index) => {
    const variety = opsDropDown[index];
    // console.log("var", variety)
    setSelectedVariety(variety);

    // use call back to Plantings to get operation map
    getOperationMap(variety, index === 1, index);
  };

  const varietySelect = () => (
    <Menu
      anchorEl={selectVarietyOpen}
      open={Boolean(selectVarietyOpen)}
      onClose={() => setSelectVarietyOpen(null)}
      style={{ margin: '40px 0 0 0px' }}
    >
      {opsDropDown.map((value, i) => (
        <MenuItem
          key={i}
          onClick={() => { setSelectVarietyOpen(null); handleVarietySelect(i); }} // get operation map for this variety
          style={{
            fontWeight: 500,
          }}
        >
          {value.name}
        </MenuItem>
      )) }

    </Menu>
  );

  const handleCombinationFromShp = (geoJson) => {
    console.log('geoJson', geoJson);
    console.log('combination', combination);

    for (let i = 0; i < geoJson.varieties.length; i++) {
      for (let j = 0; j < combination.length; j++) {
        geoJson.varieties[i][combination[j].operationProp] = geoJson.varieties[i][combination[j].fileProp];
      }
    }
    console.log('geoJson.varieties', geoJson.varieties);
    setVarieties(geoJson.varieties);
    // setZones(geoJson.zones)
  };
  // #endregion

  // #region - Yield forecast
  // Verify and prepare the required data to call the yield forecast code. Then call it.
  const asyncYieldForecast = async () => {
    // Do not proceed if user has a crop that we do not predict for yet
    if (!crop.toLowerCase().includes('corn')) {
      enqueueSnackbar(crop + ' is not yet supported. Please choose another seeding operation or enter the values manually.');
      return;
    }
    // Do not proceed if user has entered 0 for seeding density
    else if (!seedsPerAcre) {
      enqueueSnackbar('Please enter a valid seeding density (seeds per acre should be greater than 0)');
      return;
    }
    // Do not proceed if user has entered nothing for forecasted harvest date
    else if (!forecastDate) {
      enqueueSnackbar('Please enter a valid forecasted harvest date');
      return;
    }

    enqueueSnackbar('Beginning yield forecast! You will be alerted once it is complete.');
    const splitDate = forecastDate.split('-');
    const harvestDate = `${splitDate[0]}-${splitDate[1]}-${splitDate[2]}`;

    const splitPDate = plantingDate.split('-');
    const pDate = `${splitPDate[0]}-${splitPDate[1]}-${splitPDate[2]}`;

    const shape = JSON.parse(field.boundary);
    const correctShape = rewind(shape);
    correctShape.properties.CALCACRES = varieties[0].area; // when using multiple zones,

    const request = {
      ModelType: 'NN',
      ModelVersion: 'v4.0.1',
      SHAPE: JSON.stringify(correctShape),
      CropSeason: parseInt(harvestDate.split('-')[0]),
      CropName: crop,
      PlantingDay: pDate,
      SeedingDensity: seedsPerAcre,
      CornAfterCorn: 0,
      HarvestDay: harvestDate,
    };
    // make call to forecast without waiting
    asyncForecast(request);

    // close modal
    setOpen(false);
  };

  // Call yield forecast endpoint, notify user of success or failure, then refresh operations if successful.
  const asyncForecast = async (request) => {
    const yieldRes = await requestYieldForecast(
      request,
      field.id,
      field.orgId,
      selectedSeeding,
    );

    if (yieldRes !== undefined) {
      // // Deprecated: Old code for handling yield forecast success
      // await handleYieldSuccess(yieldRes);

      enqueueSnackbar('Yield Forecast Complete, Check Your Harvest Operations');
      refreshOperations(field.orgId, field.id, year, years, false, true);
    } else {
      enqueueSnackbar('Yield Forecast Failed');
    }
  };

  // These next two are currently unused..
  const lowerCase = (str) => str[0].toLowerCase() + str.slice(1);

  // Deprecated: Old code for handling yield forecast success
  // Kept in case is needed later on - seems idea here was to keep modal open while loading then switch it to normal
  const handleYieldSuccess = async (yieldRes) => {
    // set info for map display
    setManualEntry(false);
    setOperationMap(true);
    setOperationUnit(yieldRes.ManualOperation.Operation_Record.YieldUnit);

    for (let i = 0; i < yieldRes.YieldRaster.length; i++) {
      const legendRes = yieldRes.YieldRaster[i].Legend.map(
        (obj) => Object.assign({}, ...Object.entries(obj).map(
          ([k, v]) => ({ [lowerCase(k)]: v }),
        )),
      );
      yieldRes.YieldRaster[i].Legend = legendRes;
    }

    const res = yieldRes.YieldRaster.map(
      (obj) => Object.assign({}, ...Object.entries(obj).map(
        ([k, v]) => ({ [lowerCase(k)]: v }),
      )),
    );
    // console.log("res",res)
    setOperationMapDis(res);
    setProfitMapResponse(res);

    // set info for variety display
    const convertedvarieties = con.convertOperationVarietiesForEdit(
      yieldRes.ManualOperation,
    );

    convertedvarieties.map((x) => (x.price = 3.68));
    // console.log(convertedvarieties)

    await refreshOperations(field.orgId, field.id, year, years, false, true);
    // setOperations([...operations, topLevel]);
    setVarieties(convertedvarieties);
    setForecastYield(false);
    setSelectedOp('');
  };

  // If user updates which seeding operation is selected, update the relevant inputs accordingly.
  const handleSeedingForecast = (op) => {
    // total area of all varieties
    if (op === null) {
      setSelectedSeeding('');
      setPlantingDate(`${year}-05-01`);
      setSeedsPerAcre(30000);
      setCrop('CORN');
    } else {
      const totalArea = op.varieties
        .map((x) => x.area)
        .reduce((a, b) => a + b, 0);

      let seedingDensity = 0;
      let kseeds = false;
      for (const variety of op.varieties) {
        seedingDensity
          += (variety.averageMaterialResult * variety.area) / totalArea;

        if (variety.unit === 'K seeds') {
          kseeds = true;
        }
      }

      seedingDensity = kseeds ? seedingDensity * 1000 : seedingDensity;

      setSelectedSeeding(op.operationID);
      setPlantingDate(con.formatDate(op.date));
      setSeedsPerAcre(parseFloat(seedingDensity.toFixed(2)));
      setCrop(op.name);
    }
  };

  /**
   * Special yield forecasting instructions and inputs
   * @returns {JSX} JSX
   */
  const yieldForecast = () => (
    <Box mt="12px">
      {/* Instructions */}
      <Divider style={marginStyle} />
      <Typography variant="subtitle2" style={marginStyle}>
        Yield Forecast is currently in BETA and only works with CORN seeding operations. Any other type of operation will fail.
      </Typography>
      <Typography variant="subtitle2" style={marginStyle}>
        You can either select an existing seeding operation or enter the planting date and seeding density for your crop.
        You can then select an expected harvest date and click on the 'Forecast Yield' button. 
      </Typography>
      <Divider style={marginStyle} />

      {/* Seeding operation selection */}
      <Box mt="20px">
        Seeding Operation
        <Autocomplete
          className={classes.input}
          options={seedings}
          getOptionLabel={(option) => (`${option.name} ${option.date}`)}
          onChange={(e, newValue) => handleSeedingForecast(newValue)}
          renderInput={(params) => <TextField {...params} variant="outlined" />}
          style={{ width: 300 }}
        />
      </Box>

      {/* Planting date and seeding density boxes */}
      <Box display="flex" mb={3}>
        <Box>
          Planting Date
          <TextField
            className={classes.input}
            variant="outlined"
            type="date"
            InputLabelProps={{
              shrink: true,
            }}
            value={plantingDate}
            onChange={(event) => setPlantingDate(event.target.value)}
          />
        </Box>
        <Box>
          Seeds Per Acre
          <TextField
            className={classes.input}
            variant="outlined"
            value={seedsPerAcre}
            onBlur={(e) => {
              const newNum = e.target.value;
              setSeedsPerAcre(Number(newNum.replaceAll(',', '')));
            }}
            InputProps={{
              inputComponent: NumberFormatRMAArea,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
  // #endregion

  // #region - handle footer interactions
  const handleDownloadClick = (fileType, fileName) => {
    /** When a filename is clicked on for download, send the request and try to dl it * */
    let url = `${Endpoints.BASEURL}/api/ProfitTool/DeereOperation/blob/download`;
    url += `?orgID=${operationToEdit.orgId}`;
    url += `&fieldID=${operationToEdit.fieldId}`;
    url += `&operationID=${operationToEdit.operationID}`;
    url += `&operationType=${type}`;
    url += `&fileType=${fileType}`;
    url += `&fileName=${fileName}`;
    url += `&source=${operationToEdit.source}`;
    // console.log("url", url);

    // init the download
    document.location.href = url;
  };

  const handleCancel = () => {
    setForecastYield(false);
    setOperationMap(false);
    setOperationMapDis([]);
    setOpen(false);
  };

  /**
   * For new operation,
   * For new yield forecast, just call asyncYieldForecast
   * For edited operation, await return value some saveOperation to get logID (logID ???)
   */
  const handleSave = async () => {
    // if creating manualEntry
    // manual api then saveOperation
    // else
    // saveOperation then manual api

    if (forecastYield) {
      asyncYieldForecast();
    } else {
      if (varieties[0].source === undefined || manuals.includes(varieties[0].source.toUpperCase())) {
        for (let i = 0; i < varieties.length; i++) {
          delete varieties[i].mapRef;
          delete varieties[i].featureRef;
          if (varieties[i].zone !== undefined) {
            delete varieties[i].zone.properties.mapRef;
            delete varieties[i].zone.properties.featureRef;
          }
        }
      }

      const operation = {
        date,
        varieties: [],
        hidden: false,
        name: crop,
        operationID: '',
        unit,
        zones: varieties.map((x) => x.zone),
      };

      const updatedVarieties = [...varieties];

      // Unit conversion for K SEEDS
      if (unit.toUpperCase() === 'K SEEDS') {
        operation.unit = 'seeds';
      }
      for (const variety of updatedVarieties) {
        if (variety.unit.toUpperCase() === 'K SEEDS') {
          variety.rate *= 1000;
          variety.price = Number(variety.price) / 1000;
          variety.unit = 'seeds';
          variety.quantity *= 1000;
        }
        variety.date = date;
      }

      if (action === 'Edit') {
        if (perAcre) {
          for (const variety of updatedVarieties) {
            const updatedQuantity = variety.rate * variety.area;
            variety.quantity = updatedQuantity;
          }
        }

        // await saveOperation to get logID (logID ???)
        const opVarieties = await saveOperation(updatedVarieties);

        // add zones back to returned varieties.
        // this will ONLY work if saveOperation return in same order
        const varietiesWithZones = opVarieties.map((x, i) => ({
          ...x,
          zone: varieties[i].zone,
          rate: varieties[i].rate,
        }));
        operation.varieties = varietiesWithZones;
        // operation.date = operationToEdit.date;
        operation.operationID = operationToEdit.operationID;

        if (varieties[0].source !== 'USERENTERED') {
          refreshOperations(field.orgId, field.id, year, years, false, true);
        }
      } else {
        // new operations need be updated with crop name
        const namedVarieties = updatedVarieties.map((x) => ({
          ...x,
          productName: crop,
        }));
        operation.varieties = namedVarieties;
      }

      const nonModifiedOpn = operations.filter(
        (ops) => ops.operationID !== operation.operationID,
      );

      // this will call manual operation endpoint
      setOperationMap(false);
      setOperationMapDis([]);
      setOpen(false);

      if (
        operation.operationID === ''
          || varieties[0].source === undefined
          || manuals.includes(varieties[0].source.toUpperCase())
          || action.toUpperCase() !== 'EDIT'
      ) {
        save(operation, false);
      }

      setOpen(false);
    }
  };
  // #endregion

  return (
    <Box
      className={classes.paper}
      borderRadius="borderRadius"
      style={{
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
        padding: 1,
        height: height - 20,
        width: width - 20,
      }}
    >
      {/* Head */}
      <Box className={classes.head}>
        <Box p={2}>
          {action}
          {' '}
          {type}
          {' Operation'}
        </Box>

        <Box display="flex" style={{ marginTop: 12 }}>
          {!manualEntry && (
            source === "YIELD" ? (
              <CustomToolTip
                title="This operation is a prediction based on input data"
                placement="left"
              >
                <Chip
                  style={{ margin: '0 10px' }}
                  label={(
                    <Box>
                      <UpdateIcon style={{ marginRight: 4 }}/>
                      FORECAST
                    </Box>
                  )}
                />
              </CustomToolTip>
            ) : (
              <CustomToolTip
                title="This operation is imported from your Precision Ag Data"
                placement="left"
              >
                <Chip
                  style={{ margin: '0 10px' }}
                  label={(
                    <Box>
                      <SyncAltIcon />
                      AG DATA
                    </Box>
                  )}
                />
              </CustomToolTip>
            )
          )}
          <HighlightOffIcon
            className={classes.icon}
            style={{ margin: "3px 10px 0px" }}
            onClick={() => setOpenConfirm(true)}
          />
        </Box>
      </Box>

      {/* Body */}
      <Box className={classes.body}>
        {/* Left-side of modal: operation details */}
        <Box className={classes.details}>
          <Box>
            {/* Do not show collapse message when forecasting yield */}
            {!forecastYield && (
              <Box display="flex" mb={1}>
                {displayDetails ? (
                  <ExpandLessIcon
                    className={classes.icon}
                    onClick={() => setDisplayDetails(false)}
                  />
                ) : (
                  <ExpandMoreIcon
                    className={classes.icon}
                    onClick={() => setDisplayDetails(true)}
                  />
                )}
                {type}
                {' '}
                Details
              </Box>
            )}

            {/* Always show button to switch between harvest op options and forecast yield options */}
            {/* For harvest operation, show harvest date, crop, unit, and acres boxes */}
            {/* For yield forecast, show info explaining what it is, seeding operation select, planting date, seeding density, harvest date, and crop boxes. Also show 'forecast yield' button */}
            {displayDetails && (
              <Box mb={1}>
                {/* Toggle to switch between harvest op and forecast yield options */}
                {(type === 'Harvest' && action !== 'Edit') && (
                  <Box display="flex" alignItems="center">
                    <Checkbox
                      checked={forecastYield}
                      onChange={() => setForecastYield(!forecastYield)}
                      color="primary"
                    />
                    Forecast Yield (BETA)
                    <CustomToolTip
                      title="Select a seeding operation and an expected harvest date to forecast yield. Currently only available for corn."
                      placement="right"
                    >
                      <InfoOutlinedIcon
                        style={{ margin: '0 0 8px 8px', fontSize: '14px' }}
                      />
                    </CustomToolTip>
                  </Box>
                )}

                {/* Special yield forecasting instructions and inputs */}
                {forecastYield && yieldForecast()}

                {/* Date and Crop input boxes */}
                <Box display="flex">
                  {forecastYield ? (
                    <Box width="214px">
                      Expected Harvest Date
                      <TextField
                        className={classes.input}
                        variant="outlined"
                        type="date"
                        InputLabelProps={{
                          shrink: true,
                        }}
                        value={forecastDate}
                        onChange={(event) => setForecastDate(event.target.value)}
                      />
                    </Box>
                  ) : (
                    <Box>
                      <Box display="flex" alignItems="center">
                        <Box>
                          {type}
                          {' Date'}
                        </Box>
                        {
                          (!manualEntry) && (
                            <CustomToolTip
                              placement="bottom-end"
                              title={`If you need to recalibrate your operation, please edit the operation date and primary information in ${displaySource(source)}`}
                            >
                              <InfoOutlinedIcon
                                style={{ margin: '0 0 8px 8px', fontSize: '14px' }}
                              />
                            </CustomToolTip>
                          )
                        }

                      </Box>
                      <Box>
                        {
                          source === undefined
                          || manuals.includes(source.toUpperCase())
                          || action === 'Add' ? (
                            <TextField
                              className={classes.input}
                              variant="outlined"
                              type="date"
                              InputLabelProps={{
                                shrink: true,
                              }}
                              value={date}
                              onChange={(event) => setDate(event.target.value)}
                            />
                            ) : (
                              <Box className={classes.disabledInput}>
                                {date}
                              </Box>
                            )
                        }
                      </Box>

                    </Box>
                  )}

                  {
                    ((source === undefined
                    || manuals.includes(source.toUpperCase())
                    || action === 'Add') & !forecastYield) ? (
                      <Box>
                        Crop
                        <Autocomplete
                          className={classes.input}
                          freeSolo
                          value={crop}
                          // onChange={(event, newValue) => setCrop(newValue)}
                          inputValue={cropInputValue}
                          onInputChange={(event, newInputValue) => handleCropInput(newInputValue)}
                          options={allcrops}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </Box>
                    ) : (
                      <Box style={{ width: '200px' }}>
                        Crop
                        <Box className={classes.disabledInput}>{crop}</Box>
                      </Box>
                    )
                  }
                </Box>

                {/* Unit and acres boxes or 'Forecast Yield' button */}
                {!forecastYield ? (
                  <Box display="flex">
                    {manualEntry ? (
                      <Box>
                        Unit
                        <Autocomplete
                          className={classes.input}
                          value={unit}
                          onChange={(event, newValue) => {
                            handleUnitUpdate(newValue);
                          }}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          options={units}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      </Box>
                    ) : (
                      <Box>
                        Unit
                        <Box className={classes.disabledInput}>{unit}</Box>
                      </Box>
                    )}

                    <Box style={{ width: '200px' }}>
                      Acres
                      <Box className={classes.disabledInput}>{acres}</Box>
                    </Box>
                  </Box>
                ) : (
                  <Box display="flex" justifyContent="center" m={2} mt={3}>
                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={() => asyncYieldForecast()}
                    >
                      Forecast Yield
                    </Button>
                  </Box>
                )}
              </Box>
            )}
            <Divider />
          </Box>

          {/* Shows info about the operation split by zones */}
          {!forecastYield && varieties.map((variety, i) => (
            <Zone
              allClickedFeatures={allClickedFeatures}
              clickedFeature={clickedFeature}
              key={i}
              zone={variety}
              index={i}
              type={type}
              displayZones={displayZones}
              handleZoneChange={handleZoneChange}
              handleChange={handleUpdateOnChange}
              handleUpdate={handleVarietyUpdate}
              remove={removeZone}
              manualEntry={manualEntry}
              unit={unit}
              action={action}
            />
          ))}
          {/* In case there are no varieties/zones, show a message to user that they cannot save */}
          {!varieties.length && (
            <Box m={1} display="flex">
              {loading
                ? (
                  <Box display="flex" alignItems="center">
                    {'Loading zones '}
                    <LinearProgress style={{ width: 40, marginLeft: 8 }} />
                  </Box>
                )
                : (
                  <Box className={classes.errorMessageBox}>
                    To save, you must have at least one zone for each operation.
                  </Box>
                )}
            </Box>
          )}
        </Box>

        {/* Right-side of modal: map and maybe map interaction buttons and range slider */}
        <Box
          style={{
            height: forecastYield
              ? height - 190
              : manualEntry
                ? height - 250
                : isOperationMap
                  ? height - 330
                  : height - 190,
            width: width - 480,
          }}
        >
          {isOperationMap === true && operationMapDis.length > 0 && operationToEdit.varieties && operationToEdit.varieties.length > 0
            && (
            <Box
              display="flex"
              style={{
                position: 'absolute',
                top: '10%',
                right: '80px',
                zIndex: 1500,
                backgroundColor: '#ffffff',
              }}
            >
              {varietySelect()}
              <Box
                className={classes.selections}
                onClick={(e) => setSelectVarietyOpen(e.currentTarget)}
              >
                {selectedVariety.name}
                <ArrowDropDownCircle
                  className={classes.dropdown}
                />
              </Box>
            </Box>
            )}
          <ZoneMap
            setClickedFeature={setClickedFeature}
            deletedSliceZones={deletedSliceZones}
            canEdit={manualEntry && !forecastYield}
            geoJson={zoneGeo}
            updateGeoJson={updateGeoJson}
            isOperationMap={isOperationMap}
            operationMap={operationMapDis}
            origin="Planting"
            setZones={setZones}
            operationType={type}
            combination={combination}
            setCombination={setCombination}
            handleCombinationFromShp={handleCombinationFromShp}
            action={action}
            containerHeight={isOperationMap ? height - 300 : height - 135}
            isVarietyMap={isVarietyMap}
            handleVarietyMapClick={handleVarietyMapClick}
            updateZones={setEditLayers}
            usedZoneColors={usedZoneColors}
            maxZoom={field.state === '' ? 17 : 18}
          />

          {isOperationMap === true && ((!isVarietyMap && operationMapDis.length > 0) || (isVarietyMap && operationMapDis.length > 1)) && (
            <Box style={{ marginTop: 'unset' }}>
              <RangeSlider
                legend={isVarietyMap === true ? operationMapDis[1].legend : operationMapDis[0].legend}
                isOperation
                type={isVarietyMap === true ? 'Variety' : 'Operation'}
                editModal
                manualEntry={manualEntry}
                unit={operationUnit}
                profitMapResponse={profitMapResponse}
                perAcre={perAcre}
                isQuantityMap={isQuantityMap}
                setIsQuantityMap={setIsQuantityMap}
                selectedOp={selectedOp}
                isVarietyMap={isVarietyMap}
              />
            </Box>
          )}
        </Box>
      </Box>

      {/* Footer */}
      <Box className={classes.footer}>
        <Box mx={2} color={green} fontWeight={600}>
          <Button
            color="primary"
            onClick={(e) => {
              setPopoverAnchor(e.currentTarget);
              setPopoverOpen(!downloadsPopoverOpen);
            }}
            disabled={downloads === undefined}
          >
            Downloads
            {downloadsLoading && (
              <CircularProgress size={16} style={{ marginLeft: 2 }} />
            )}
            {downloads && (
              <>
                <ExpandLessIcon />
                <Popover
                  id="downloads-popover"
                  open={downloadsPopoverOpen}
                  anchorEl={downloadsPopoverAnchor}
                  onClose={() => {
                    setPopoverAnchor(null);
                    setPopoverOpen(false);
                  }}
                  anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
                >
                  <Box style={{ padding: '1em' }}>
                    {downloads.shapefiles && downloads.shapefiles.length > 0 && (
                      <>
                        <Box className={classes.edit} fontSize={18}>
                          Shapefiles
                          {downloads.shapefiles.map((s, i) => (
                            <Box
                              key={i}
                              onClick={() => handleDownloadClick('shapefile', s)}
                              style={{ marginLeft: '2em', cursor: 'pointer' }}
                            >
                              {s}
                            </Box>
                          ))}
                        </Box>
                      </>
                    )}

                    {downloads.rasters && downloads.rasters.length > 0 && (
                      <>
                        <Divider />
                        <Box
                          className={classes.edit}
                          fontSize={18}
                          style={{ paddingTop: '0.5em' }}
                        >
                          Rasters
                          {downloads.rasters.map((r, i) => (
                            <Box
                              key={i}
                              onClick={() => handleDownloadClick('raster', r)}
                              style={{ marginLeft: '2em', cursor: 'pointer' }}
                            >
                              {r}
                            </Box>
                          ))}
                        </Box>
                      </>
                    )}

                    {downloads.adapt && downloads.adapt.length > 0 && (
                      <>
                        <Divider />
                        <Box
                          className={classes.edit}
                          fontSize={18}
                          style={{ paddingTop: '0.5em' }}
                        >
                          Adapt Files
                          {downloads.adapt.map((a, i) => (
                            <Box
                              key={i}
                              onClick={() => handleDownloadClick('adapt', a)}
                              style={{ marginLeft: '2em', cursor: 'pointer' }}
                            >
                              {a}
                            </Box>
                          ))}
                        </Box>
                      </>
                    )}
                  </Box>
                </Popover>
              </>
            )}
          </Button>
        </Box>

        <Button
          variant="outlined"
          color="primary"
          style={{ margin: '0 25px', backgroundColor: '#ffffff' }}
          onClick={() => handleCancel()}
        >
          Cancel
        </Button>

        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
          disableElevation
          disabled={!varieties.length}
        >
          Save
        </Button>
        { openConfirm && (
        <ConfirmationPopup
          setOpen={setOpenConfirm}
          save={handleSave}
          cancel={handleCancel}
          disableSave={!varieties.length}
        />
        )}
      </Box>

      {loading && <SpinningLoader />}
    </Box>
  );
}

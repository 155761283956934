import React, {
  useState, useEffect, useRef,
} from 'react';
import {
  Box,
  Button,
  Divider,
  TextField,
  Chip,
  Popover,
  LinearProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

import { makeStyles } from '@material-ui/core/styles';
import { darkGrey, green } from '../../../../styles/colors';
import { zoneColors } from '../../../Maps/Styles/layerStyles'

import {
  units,
  unitConversion,
  formatDate,
  applicationConversions,
} from '../../utils/conversions';
import { useWindowDimensions } from '../../../../utils/dimensions';
import { getGeoJson } from '../../../../utils/dataFetchers';
import {
  numFormat,
  exists,
  capitalizeFirstLetter,
  consoleImportant,
  noZeroValues,
  dollarFormat
} from '../../../../utils/helpers';
import { NumberFormatThree } from '../../../../utils/NumberFormatCustom';
import { CustomToolTip } from '../../../../utils/customComponents';

import { Zone } from './Zone';
import { ZoneMap } from '../../../Maps/ZoneMap';
import { Endpoints } from '../../../../constants/Endpoints';
import { SpinningLoader } from '../../../Shared/SpinningLoader';
import { ConfirmationPopup } from '../Shared/ConfirmationPopup'
import { RangeSlider } from '../../Reports/Ranges/RangeSlider';
import { cloneDeep } from "lodash";
import { useSnackbar } from 'notistack';


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    padding: 1,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: theme.updateOperationModal,
  head: theme.updateOperationHeader,
  body: theme.updateOperationBody,
  footer: theme.updateOperationFooter,
  mapControls: theme.mapControls,
  details: { ...theme.plDetails, width: '490px', paddingRight: '7px'},
  select: { ...theme.select, width: '200px' },
  input: theme.plInput,
  mixInput: { ...theme.plInput, width: '175px' },
  disabledInput: theme.disabledInput,
  errorMessageBox: theme.errorMessageBox,
  border: {
    width: '100px',
    height: '3px',
    backgroundColor: theme.palette.primary.main,
  },
  addMix: {
    width: '30%',
    minWidth: '100px',
    display: 'flex',
    justifyContent: 'center',
    textAlign: 'center',
    alignItems: 'center',
    color: theme.palette.primary.main,
    margin: '0 10px',
    padding: '10px',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editZones: {
    width: '70%',
    minWidth: '100px',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.primary.main,
    margin: 'auto',
    padding: '10px',
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mixDisabledInput: { ...theme.disabledInput, width: '150px' },
  delete: {
    color: green,
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: 12,
    display: 'flex',
    alignItems: 'center',
  },
  icon: theme.icon,
  infoToolTip: theme.infoToolTip,
}));

/**
 * Used to format output to user
 * Makes sure we only show 2-3 decimal places and remove lingering zeroes
 * @param {Number|String} val value to be formatted
 * @param {Number} decimals number of decimals to allow
 */
export function formatNumText(val, decimals = 3) {
  // convert for form used in text box. up to three decimals by default
  if (val === '') {
    return val;
  }

  try {
    // Convert the number to have a fixed number of decimals and remove any lingering zeroes.
    return Number(val).toFixed(decimals).replace(/\.?0+$/, '');
  } catch (err) {
    console.log(`error converting ${val}`);
    return '';
  }
};

/**
 * Create new and update application operations. User will have the
 * ability to draw zones on map and create operations for each zone. Year is
 * the user selected year and will be used to auto populate date selector.
 * Value display will be deteremined by perAcre, which when false will mean
 * some values are disaplyed per field rather than acre.
 * @param {String} action Add or Edit.
 * @param {Object} toEdit Operation to load data from and edit.
 * @param {Object} field Field data.
 * @param {Bool} perAcre Data is per acre or per field.
 * @param {Number} seededAcres Acres of field that have been seeded.
 * @param {Number} year Selected year of operations.
 * @param {Function} setOpen Determines if display modal
 * @param {Function} save Save new operation.
 * @param {Function} saveUpdate Save edited operation. Calls handleUpdate in Applications
 * @param {} isOperationMap
 * @param {}
 * @param {}
 * @param {}
 * @param {}
 * @param {}
 * @param {}
 */
export function UpdateApplications({
  action,
  toEdit,
  field,
  perAcre,
  seededAcres,
  year,
  setOpen,
  save,
  saveUpdate,
  isOperationMap,
  operationMapDis,
  operationUnit,
  profitMapResponse,
  isQuantityMap,
  setIsQuantityMap,
  selectedOp
}) {

  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [modalStyle] = React.useState(getModalStyle);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  const [manualEntry, setManualEntry] = useState(true);
  const [zoneGeo, setZoneGeo] = useState({});

  // Application Summary Data
  const [date, setDate] = useState(formatDate(new Date()));
  const [productName, setProductName] = useState('Tank Mix');
  const [allProducts, setAllProducts] = useState([]);
  const [acres, setAcres] = useState(0);
  const [quantityPerAcre, setQuantityPerAcre] = useState(14.0058594);
  const [quantity, setQuantity] = useState(0);
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [costPerAcre, setCostPerAcre] = useState(0);

  // zones with details visible
  const [displayDetails, setDisplayDetails] = useState(true);
  const [displayZones, setDisplayZones] = useState([0]);

  const [zones, setZones] = useState([]);
  const [doneInit, setDoneInit] = useState(false);

  const [unit, setUnit] = useState(units[0]);
  const [oldUnit, setOldUnit] = useState(units[0]);
  // Disabling some inputs when no input is selected helps avoid conversion issues with null input
  // This also helps us store the state before it was set to null for easy conversion
  const [noUnitSelected, setNoUnitSelected] = useState(false);
  const [inputValue, setInputValue] = useState('');
  const [carrierInputValue, setCarrierInputValue] = useState('');

  const [createTankMix, setCreateTankMix] = useState(true);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [componentQuantityEditable, setComponentQuantityEditable] = useState(true)

  // zones passed up from map
  const [mapZones, setMapZones] = useState([]);

  // Zone colors from loaded geoJson to pass to map
  const [usedZoneColors, setUsedZoneColors] = useState([])

  const [deletedSliceZones, setDeletedSliceZones] = useState([]);

  const [editLayers, setEditLayers] = useState([]);

  const [tank, setTank] = useState([
    {
      costPerAcre: '',
      inputUnit: '',
      isCarrier: false,
      percent: 0,
      price: '',
      productName: "",
      quantity: 0,
      quantityPerAcre: '0',
      rate: 0,
      total: 0,
      unit: units[1],
    },
  ]);

  // Default tank state - be careful changing these
  useEffect(()=>{
    setTank([
      {
        costPerAcre: '0.1',
        inputUnit: 'fluid ounce',
        isCarrier: false,
        percent: 0.0535484792,
        price: '0.13',
        productName: "FS MaxSupreme",
        quantity: 0,
        quantityPerAcre: '0.75',
        rate: 0,
        total: 0,
        unit: units[1],
      },
    ])
  },[])

  useEffect(() => {
    const colors = zones.map(
      x => x.properties.color).filter(x => x !== undefined)
    setUsedZoneColors(colors)
  }, [zones])

  // carrier data
  const [addCarrier, setAddCarrier] = useState(false);
  const [carrier, setCarrier] = useState('');
  const [carrierQuantity, setCarrierQuantity] = useState('0');
  const [carrierQuantityPerAcre, setCarrierQuantityPerAcre] = useState('0');
  const [carrierUnit, setCarrierUnit] = useState(units[0]);
  const [carrierPrice, setCarrierPrice] = useState('');
  const [carrierCostPerAcre, setCarrierCostPerAcre] = useState('');
  const [carrierPercent, setCarrierPercent] = useState(0);
  const [carrierTotal, setCarrierTotal] = useState('0');
  const [carrierRate, setCarrierRate] = useState('0');
  const [carrierInfo, setCarrierInfo] = useState({
    applicationOperationID: undefined,
    applicationLoggedID: undefined,
    area: undefined,
  })

  // for downloads
  const [downloadsPopoverOpen, setPopoverOpen] = useState(false);
  const [downloadsPopoverAnchor, setPopoverAnchor] = useState(null);
  const [downloads, setDownloads] = useState(undefined);
  const [downloadsLoading, setDlLoading] = useState(false);

  const updatingZones = useRef([]);

  const [combination, setCombination] = useState([])

  useEffect(() => {
    exists(mapZones) && mapZones.map(x => handleZone(x.zone, x.type))
  }, [mapZones])

  useEffect(() => {
    // Disable ability to edit tank mix quantity per acre if any zone rates are 0
    setComponentQuantityEditable(noZeroValues(zones.map(x => x.quantity)))
  }, [zones])

  // Update the applied rate displayed in the zones when average applied rate updates
  // Only done if update is not from zone updates
  const updateZoneRates = (prevQuantity, newQuantity, unitDetails = undefined) => {
    let newRatio;
    // console.trace()
    // console.log('prevQuantity, newQuantity :>> ', prevQuantity, newQuantity);
    // console.log('unitDetails :>> ', unitDetails);
    // console.log('applicationConversions :>> ', applicationConversions);
    // If its a unit change, just convert the values
    if (!!unitDetails?.oldUnit) {
      newRatio = applicationConversions[unitDetails.oldUnit][unitDetails.newUnit];
    }
    else {
      newRatio = newQuantity / prevQuantity;
    }

    // Convert all zone values
    const zonesCopy = [...zones];
    for (const zone of zonesCopy) {
      zone.quantity = zone.quantity * newRatio;
    }
    setZones(zonesCopy);
  }

  useEffect(() => {
    console.log('toEdit :>> ', toEdit);
    if (action === 'Edit' && toEdit !== undefined) {
      setLoading(true);
      setProductName(toEdit.productName);
      setAcres(toEdit.area);
      const convertedUnit = unitConversion(toEdit.totalMaterialUnit, true);
      setUnit(convertedUnit);
      setOldUnit(convertedUnit);
      setPrice(toEdit.price);
      setTotal(toEdit.operationCost);
      setCostPerAcre(toEdit.operationCost);
      setQuantity(toEdit.quantity);
      const conversionFactor = applicationConversions[unitConversion(toEdit.totalMaterialUnit, true)][convertedUnit];
      setQuantityPerAcre(conversionFactor * toEdit.averageMaterialResult);
      setDate(formatDate(toEdit.date));

      const manual = toEdit.source === 'Ag-Analytics' ||  toEdit.source === 'USERENTERED'
                  || toEdit.source === undefined ? true : false;
      setManualEntry(manual);

      // get component geojson
      let awaitGeoJson;
      if(manual){
        awaitGeoJson = getComponentGeojson(convertedUnit);
      }
      else{
        let boundary = JSON.parse(field.boundary)
        boundary.properties.CALCACRES = toEdit.area
        let zone = {...boundary, quantity: toEdit.averageMaterialResult}
        setZones([zone])
      }

      // Add some necessary variables to the tankMix
      const tankMix = toEdit.applicationComponents
        .filter((x) => !x.isCarrier)
        .map((x) => ({
          ...x,
          costPerAcre: x.price * (x.quantity / x.area),
          percent: (x.quantity / x.area) / x.averageMaterialResult,
          quantityPerAcre: x.quantity / x.area,
        }));

      setTank(tankMix);

      // Extract carrier information, if it exists
      const mixCarrier = toEdit.applicationComponents
        .filter((x) => x.isCarrier)
        .map((x) => ({ ...x }));
      if (mixCarrier.length > 0) {
        setCarrier(mixCarrier[0].name);
        setCarrierQuantity(mixCarrier[0].quantity);
        let carrierQuantityPerAcre = mixCarrier[0].quantity / mixCarrier[0].area;
        setCarrierQuantityPerAcre(carrierQuantityPerAcre) // per acre
        setCarrierUnit(unitConversion(mixCarrier[0].totalMaterialResultUnit, true));
        setCarrierPrice(mixCarrier[0].price);
        setCarrierCostPerAcre(carrierQuantityPerAcre * mixCarrier[0].price);
        mixCarrier[0].percent = carrierQuantityPerAcre / mixCarrier[0].averageMaterialResult
        setCarrierPercent(mixCarrier[0].percent);
        setAddCarrier(true);

        // Set this info for saving - We would want all carrier data to be handled directly from the carrier var itself.
        // and so we would not need to do this. But this is how it hsa been setup so passing these here for now
        setCarrierInfo({
          applicationOperationID: mixCarrier[0].applicationOperationID,
          applicationLoggedID: mixCarrier[0].applicationLoggedID,
          area: mixCarrier[0].areaResult,
        })
      }

      // Wait for geojson so we show the appropriate message and loading spinner
      const waitForMe = async () => {
        await awaitGeoJson;
        setLoading(false);
      }
      waitForMe();
    }
    // This happens if it's not add operation, basically
    else {
      setManualEntry(true);

      // Show carrier with some default values
      resetCarrier();
    }

  }, [action, toEdit]);

  useEffect(() => {
    if (action !== 'Edit') {
      const date = formatDate(new Date());
      const selectedDate = year + date.slice(4);
      setDate(selectedDate);
    }
  }, [year, action]);

  useEffect(() => {
    if (field.boundary !== '') {
      if (action === 'Add') {
        console.log(field)
        const feature = JSON.parse(field.boundary);
        feature.properties.CALCACRES = field.acres;
        // this is current default value when adding default product and carrier
        feature.quantity = 14.0058594;
        setZones([feature]);
        setAcres(field.acres);
      }
      // else {
      //   setZones([]);
      // }
    }
  }, [field, action]);

  useEffect(() => {
    if (zones.length > 0) {
      const totalAcre = zones
        .map((x) => x.properties.CALCACRES)
        .reduce((prev, next) => prev + next, 0);
      setAcres(totalAcre);
    }
    else{
      setAcres(0);
    }
  }, [zones]);

  // useEffect(() => {
  //   if(action === 'Edit' && editLayers.length > 0 && tank.length >0){
  //     //check that varieties dont already have refs
  //     //console.log(editLayers)
  //     //console.log(varieties)
  //     let missing = tank.filter(x => x.zone.properties['mapRef'] === undefined && x.zone.properties['featureRef'] === undefined)
  //     console.log(missing)
  //     if(missing.length > 0){
  //       updateVarietyZones(editLayers)
  //     }
  //   }
  // }, [editLayers, tank, action])

  // on initial component load - send request to get available downloads
  useEffect(() => {
    if(action === "Edit"){
      let url = `${Endpoints.BASEURL}/api/ProfitTool/DeereOperation/blob`;
      url += `?orgID=${field.orgId}`;
      url += `&fieldID=${field.id}`;
      url += `&operationID=${toEdit.operationID}`;
      url += `&operationType=application`;
      url += `&source=${toEdit.source}`;

      setDlLoading(true);
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return res.json()
        }
        setDlLoading(false);
      })
      .then((res) => {
        setDownloads(JSON.parse(res));
        // console.log("downloads", JSON.stringify(res));
        setDlLoading(false);
      })
      .catch((err) => {
        console.log("Download file manifest failed", err);
        setDlLoading(false);
      })
    }
  }, []);

  // Round the given number to the given precision - numFormat does not round
  function roundNumber(num, dec) {
    if (!dec) {
      consoleImportant('You need to pass a dec value.');
    }
    return Math.round(num * Math.pow(10, dec)) / Math.pow(10, dec);
  }

  /**
   * Gets the geoJson information for this field and operation, uses that to extract the operations' zones
   * and sets the correct applied rates for each.
   */
  const getComponentGeojson = async (convertedUnit) => {
    // get geojson overlay for components
    const geo = await getGeoJson(field.orgId, field.id, toEdit.operationID, 'application');
    setZoneGeo(geo);

    // Set zones applied rate values
    if (geo.features.length > 0) {
      let zonesWithQuantity = [];
      let catchChanges = geo.features[0].properties.Unit.split('1ac-1').length !== 2;

      for (const feature of geo.features) {
        // console.log('feature :>> ', feature);
        // add quantity key with converted applied rate value
        if (!catchChanges) {
          const extractUnit = feature.properties.Unit.split('1ac-1')[0];
          // Round up number as it should only store up to 5 decimal places
          const storedRate = roundNumber(Number(feature.properties.AppliedRat), 5);
          const quan = applicationConversions[unitConversion(extractUnit, true)][convertedUnit] * storedRate;
          // console.log('extractUnit, convertedUnit, storedRate, quan :>> ', extractUnit, convertedUnit, storedRate, quan);
          zonesWithQuantity.push( {...feature, quantity: quan} );
        }
        // add quantity key directly with applied rate value - this is not the best, want above
        else {
          zonesWithQuantity.push( {...feature, quantity: feature.properties.AppliedRat} );
        }
      }

      // Show message to developer. if (catchChanges) code here should be updated to new saving method.
      // Beware, as this might break older zones and how they work though.
      if (catchChanges) {
        consoleImportant('Make sure feature.properties["Unit"] in ProfitLayers.js still stores unit with 1ac-1 or update code.');
      }
      setZones(zonesWithQuantity)
    }
  }

  const cancel = () => {
    setOpen(false);
  };

  /**
   * Given a new unit set by user, calculate the new average aplied rate (quantity)
   * @param {String} val new unit
   */
  const handleUnitChange = (val) => {
    // console.log('unit :>> ', unit);
    // console.log('val :>> ', val);
    // Handle removing the unit
    if (val === null || val === undefined) {
      setNoUnitSelected(true);
      // Store old unit for when we eventually pick a unit
      if (unit !== undefined && unit !== null) {
        setOldUnit(unit);
      }
      setUnit(val);
      return;
    }
    // Reset this as unit was picked
    setNoUnitSelected(false);

    const convertedCarrier = applicationConversions[carrierUnit][val] * carrierQuantityPerAcre;
    updateQuantity(convertedCarrier, tank, val, oldUnit);
    const percentFactor = applicationConversions[oldUnit][val];
    // Update application percents on unit conversion.
    // console.log("\nupdating percent from handleUnitChange")
    updateApplicationPercents(tank, null, null, percentFactor)
    setUnit(val);
    setOldUnit(val);
  }

  /**
   * Adds a new tank mix component to tank mix and changes productName if appropriate.
   */
  const addNewMix = () => {

    if (tank.length === 1 && productName ==='') {
      setProductName('Tank Mix')
    }

    // add new empty value mix component to map to TankMix
    setTank([
      ...tank,
      {
        costPerAcre: '',
        inputUnit: '',
        isCarrier: false,
        price: '',
        productName: '',
        quantity: 0,
        quantityPerAcre: 0,
        rate: 0,
        total: 0,
        unit,
      },
    ]);

  };

  /**
   * Remove tank mix component found at the given index and update quantities accordingly.
   * @param {Number} index index of component
   */
  const removeMix = (index) => {
    const mixCopy = [...tank];
    mixCopy.splice(index, 1);
    setTank(mixCopy);

    const convertedCarrier = applicationConversions[carrierUnit][unit] * carrierQuantityPerAcre;
    const applicationQuantityPerAcre = updateQuantity(convertedCarrier, mixCopy, unit);
    // update component percentages on quantity change
    // console.log("\nupdating percent from removeMix()")
    updateApplicationPercents(mixCopy, carrierQuantityPerAcre, applicationQuantityPerAcre);
  };

  /**
   * Checks whether the passed array contains non-empty values that are all non-zero.
   * Returns true if any are incomplete.
   *
   * @param {Array} check list of values to be checked
   */
  const isIncomplete = (check) => {
    if (Array.isArray(check)) {
      const exist = check.map(item => item !== null && item !== '' && item !== '0' && item !== 0)
      return !exist.every(x => x)
    }
    else {
      consoleImportant(`You shouldn't be calling this function (isIncomplete) without an array. It will always yield false.`);
      return true;
    }
  }

  /**
   * Returns True if all inputs are valid, false if not
   * will trigger a notification for what is missing
   */
  const checkForValidInputs = () => {
    // Make sure that there is at least 1 tank mix or component
    let componentsValid = true;
    if (!tank.length && !addCarrier) {
      componentsValid = false;
      enqueueSnackbar("Please make sure there is at least a tank component or a carrier")
    }

    //check tank mix components
    let tankValid = true
    tank.forEach((mix, i) => {
      if( isIncomplete([mix.quantityPerAcre, mix.productName, mix.price]) ){
        tankValid = false;
        enqueueSnackbar("Please complete all required information for component "+(i+1).toString())
      }
    })

    //check for carrier
    let carrierValid = true;
    if(addCarrier){
      //console.log('carrier', carrier)
      /* added this carrier.toUpperCase() == 'WATER' ? 0.001 : carrierPrice because we don't want to check price if carrier is water, sending a default value to get back true for price */
      if( isIncomplete([carrier, carrierQuantityPerAcre, 0.001]) ){
        carrierValid = false; 
        enqueueSnackbar("Please complete all required information for the carrier")
      }
    }

    //check zones
    let zoneValid = true
    zones.forEach((zone, i)=>{
      if( isIncomplete([zone.quantity]) ){
        zoneValid = false;
        enqueueSnackbar("Please complete all required information for zone "+(i+1).toString())
      }
    })

    let valid = (componentsValid && tankValid && carrierValid && zoneValid)
    return valid;
  }

  /**
   * Extracts the necessary information from the operation details, components, and carrier and makes two calls for saving.
   * One two createManualOperation and the other to handleIndividualOperation, both in ProfitLayers.js
   */
  const handleSave = () => {
    //check that info is filled out correctly before proceeding
    let valid = checkForValidInputs()
    if(!valid){
      return;
    }

    // format operation for saving
    // convert price from string to number and add quantity key
    const tankMix = tank.map((x) => ({
      ...x,
      price: +x.price,
      quantity: +x.quantityPerAcre,
      rate: +x.quantityPerAcre,
      costPerAcre: +x.price * x.quantityPerAcre,
    }));

    // only can add carrier to new operations
    // if (action.toUpperCase() === 'ADD' && carrier !== '') {
    if (carrier !== '') {
      const mixCarrier = {
        costPerAcre: carrierCostPerAcre,
        productName: carrier,
        quantity: +carrierQuantityPerAcre, // for now only doing perAcre TODO update
        quantityPerAcre: +carrierQuantityPerAcre,
        unit: carrierUnit,
        totalMaterialResultUnit: unitConversion(carrierUnit),
        price: +carrierPrice,
        total: carrierPrice * carrierQuantity,
        isCarrier: true,
        rate: +carrierQuantityPerAcre,
        applicationOperationID: carrierInfo.applicationOperationID,
        applicationLoggedID: carrierInfo.applicationLoggedID,
        area: carrierInfo.area,
      };

      tankMix.push(mixCarrier);
    }

    const carrierComp = tankMix.filter(
      (x) => x.isCarrier !== undefined
        && x.isCarrier === true
        && x.productName !== '',
    );

    // const opId = get from edited or ''
    const operation = {
      tankMix,
      date,
      productName,
      unit,
      zones,
      area: acres,
      cellsize: '0.0001',
      hidden: false,
      isTankMix: carrierComp.length > 0,
      operationCost: costPerAcre,
      price: costPerAcre / acres,
      rate: +quantityPerAcre,
      operationID: tankMix[0].applicationOperationID
    };
    // console.log('operation',operation)
    setOpen(false);

    if (
      operation.tankMix[0].applicationOperationID === "" ||
      tankMix[0].source === "USERENTERED" ||
      action.toUpperCase() !== "EDIT"
    ) {
      // console.log(zones)
      save(operation, zones, false);
    }
    if(action.toUpperCase() === "EDIT") {
      saveUpdate(operation)
    }
  };

  const addNewZone = (data) => {
    const newZone = { ...data, quantity: 0 };
    if (data.properties.Soil_Type !== undefined) {
      newZone.soilType = data.properties.Soil_Type
    }

    if (data?.properties?.color === undefined) {
      newZone.properties.color = zoneColors[zones.length]
    }

    const fullList = [...updatingZones.current, newZone];
    setZones((prevState) => [...prevState, newZone]);
    return fullList;
  };

  const handleZone = (data, action) => {
    // console.log(data, action)
    switch (action) {
      case 'add':
        // when zone is added, remove zone that is entire field
        let newZones = null;
        if (
          zones.length === 1
          && zones[0].properties.CALCACRES === field.acres
          && updatingZones.current.length === 0
        ) {
          newZones = [{ ...data, quantity: 0 }];
          console.log('first zone', data)
          console.log('color', data?.properties?.color)
          // Add soil type from SSURGO
          if (data.properties.Soil_Type !== undefined) {
            newZones[0].soilType = data.properties.Soil_Type
          }
          if (data?.properties?.color === undefined) {
            newZones[0].properties.color = zoneColors[0]
          }

          setZones(newZones);
          updatingZones.current = newZones;
        } else {
          newZones = addNewZone(data);
        }
        const filteredZones = zones.filter(
          (x) => x.properties.CALCACRES !== field.acres,
        );

        const recent = newZones.length - 1;
        setDisplayZones([recent]);
        break;
      case 'edit':
        //setZones(data);
        // console.log("Editing", data)
        let editCopy = [...zones]
        // console.log(editCopy)
        let ndx = editCopy.findIndex(x => x.properties.id === data.properties.id)
        // console.log(ndx)
        editCopy[ndx] = {...data, quantity: editCopy[ndx].quantity}
        setZones(editCopy)
        break;
      case 'undo':
        removeZone(zones.length - 1);
        break;
      case 'clear':
        setZones([]);
        setDisplayZones([]);
        break;
      case "reset":
        let resetCopy = [...zones]
        resetCopy.map(x => console.log(x.properties.id))

        for(const feature of data.features){
          let ndx = resetCopy.findIndex(x => x.properties.id === feature.properties.id)

          resetCopy[ndx] = { ...feature, quantity: resetCopy[ndx].quantity}
        }

        setZones(resetCopy)
        break
      default:
        console.log(`unexpected action: ${action}`);
    }
  };

  /**
   * Update quantity of component on applied rate change based on stored
   * percent of tank component made up based on user entered data.
   * @param {Number} appliedRate new rate total rate to base component's rate from
   */
  const updateComponentsQuantity = (appliedRate) => {
    const tankMix = tank.map((x) => {
      // Convert the main unit back to component's unit
      // const convertRatio = applicationConversions[unit][x.unit];
      // const newQuantity = x.percent * (appliedRate * convertRatio);
      const newQuantity = (appliedRate * x.percent);
      
      return {
        ...x,
        quantityPerAcre: newQuantity,
        costPerAcre: x.price * newQuantity
      }
    });
    console.log("UPDATE QUANTITY", appliedRate, tankMix)
    setCarrierQuantityPerAcre(appliedRate * carrierPercent);
    updateApplicationCostPerAcre(carrierCostPerAcre, tankMix)
    setTank(tankMix);
  };

  /**
   * Given a new set of zones, update the new quantity per acre and components' quantities.
   * @param {Array} zonesCopy zones to be used as new zones
   */
  const zonesChanged = (zonesCopy) => {
    // console.log('zonesCopy :>> ', zonesCopy);
    // Calculate the new field applied rate
    let applied = zonesCopy
      .map((x) => Number(x.quantity * x.properties.CALCACRES))
      .reduce((a, b) => a + b, 0);
    const totalAcres = zonesCopy
      .map((x) => Number(x.properties.CALCACRES))
      .reduce((a, b) => a + b, 0);
    applied = applied / totalAcres;

    // console.log('applied :>> ', applied);
    // console.log('zones :>> ', zones);
    setQuantityPerAcre(applied);
    updateComponentsQuantity(applied);
  }

  /**
   * Given an index, remove the zone found at that index and update all necessary variables.
   * @param {Number} i index of zone to be removed
   */
  const removeZone = (i) => {
    const removedZones = [...deletedSliceZones];
    try{
      const zoneToRem = zones[i];
      setDeletedSliceZones(removedZones);
      const zoneToRemCoords = zoneToRem.geometry.coordinates[0];

      let map = zoneToRem.properties.mapRef;

      // Here we're going to identify the zone we want to remove from the mao
      map.eachLayer(function (layer) {
        try{
          var currLayerCoords = layer.feature.geometry.coordinates[0];
          console.log(currLayerCoords)
          if(JSON.stringify(currLayerCoords) == JSON.stringify(zoneToRemCoords)){
            map.removeLayer(layer);
          }
        }catch(err){}
      });
    }catch(err){}

    // Save the new updated zones
    const zonesCopy = [...zones];
    zonesCopy.splice(i, 1);
    console.log('zonesCopy :>> ', zonesCopy);
    zonesChanged(zonesCopy);
    setZones(zonesCopy);
  };

  const handleZoneChange = (i) => {
    if (displayZones.includes(i)) {
      setDisplayZones(displayZones.filter((x) => x !== i));
    } else {
      setDisplayZones([...displayZones, i]);
    }
  };

  /**
   * Update zone applied rate to value inputted by user:
   * Makes a copy of zones, removes zone to be updated, updates it and splices it back in at same index.
   * Then update quantity per acre, and components' quantities.
   * @param {Number} index index of zone to be updated
   * @param {String} val new applied rate for updateZone
   */
  const updateZone = (index, val) => {
    const zonesCopy = [...zones];
    const updatedZone = zonesCopy.splice(index, 1)[0];
    updatedZone.quantity = val;
    zonesCopy.splice(index, 0, updatedZone);

    zonesChanged(zonesCopy);
    setZones(zonesCopy);
  };

  const handleMixUpdate = (index, val, type) => {
    // make a copy of mix, remove and return component to be updated, update it, and splice it
    // back in at same index.
    const mixCopy = tank.map((x) => JSON.parse(JSON.stringify(x)));

    const edit = mixCopy.splice(index, 1)[0];
    const value = !Number.isNaN(val) ? val : '';

    let applicationQuantityPerAcre = 0;

    switch (type) {
      case 'price':
        edit.price = val;
        if (val !== '' && edit.quantityPerAcre !== '') {
          edit.costPerAcre = Number(val) * Number(edit.quantityPerAcre);
          const startingCostPerAcre = carrierCostPerAcre !== ''
            ? Number(carrierCostPerAcre) + edit.costPerAcre
            : edit.costPerAcre;
          updateApplicationCostPerAcre(startingCostPerAcre, mixCopy);
        } else if (val === '') {
          edit.costPerAcre = 0;
          const startingCostPerAcre = carrierCostPerAcre !== '' ? Number(carrierCostPerAcre) : 0;
          updateApplicationCostPerAcre(startingCostPerAcre, mixCopy);
        }
        break;
      case 'quantityPerAcre':
        edit.quantityPerAcre = val;
        if (val !== '') {
          const convertedQuantity = applicationConversions[unitConversion(edit.unit, true)][unit] * val
          const convertedCarrier = carrierQuantityPerAcre !== '' ? applicationConversions[carrierUnit][unit] * carrierQuantityPerAcre : 0

          const startQuantity = convertedQuantity + convertedCarrier

          // Updates application quantity in state and returns quantityPerAcre
          applicationQuantityPerAcre = updateQuantity(startQuantity, mixCopy, unit);

          if (edit.price !== '') {
            edit.costPerAcre = Number(edit.price) * Number(val);
            updateApplicationCostPerAcre(edit.costPerAcre + carrierCostPerAcre, mixCopy);
          }
        }
        break;
      case 'unit':
        // Ignore if user tries to clear unit value
        if (val === undefined || val === null) {
          break;
        }
        edit.unit = val
        if (edit.quantityPerAcre !== '') {
          const convertedQuantity = applicationConversions[edit.unit][unit] * edit.quantityPerAcre
          const convertedCarrier = carrierQuantityPerAcre !== '' ? applicationConversions[carrierUnit][unit] * carrierQuantityPerAcre : 0
          console.log(convertedCarrier)
          const startQuantity = convertedQuantity + convertedCarrier

          // Updates application quantity in state and returns quantityPerAcre
          applicationQuantityPerAcre = updateQuantity(startQuantity, mixCopy, unit);
        }
        break;
      case 'productName':
        edit.productName = val
        handleProductInput(index, val)
        break;
      default:
        edit[type] = value;
    }

    mixCopy.splice(index, 0, edit);

    // Update component percentages on quantity change
    // We don't want to call setTank(mixCopy); in this case as it is done in updateApplicationPercents
    if (type === 'quantityPerAcre' || type === 'unit') {
      // Ignore if user tries to clear unit value
      if (val === undefined || val === null) {
        return;
      }
      // console.log("\nupdating percent from MixUpdate")
      updateApplicationPercents(
        mixCopy,
        carrierQuantityPerAcre,
        applicationQuantityPerAcre,
      );
    } else {
      setTank(mixCopy);
    }
  };

  const handleCarrierUpdate = (val, type) => {
    // const val = !Number.isNaN(value) ? value : "";
    const convertedCarrier = carrierQuantityPerAcre !== '' ? applicationConversions[carrierUnit][unit] * carrierQuantityPerAcre : 0
    let carrierQuantityPerAcreValue;
    let applicationQuantityPerAcre;
    switch (type) {
      case 'price':
        if (val !== '' && carrierQuantityPerAcre !== '') {
          const newVal = Number(val) * Number(carrierQuantityPerAcre);
          setCarrierCostPerAcre(newVal);
          updateApplicationCostPerAcre(newVal, tank);
        } else if (val === '') {
          setCarrierCostPerAcre(0);
          updateApplicationCostPerAcre(0, tank);
        }
        setCarrierPrice(val);
        break;
      case 'quantityPerAcre':
        const convertedVal = applicationConversions[carrierUnit][unit] * val
        // Save new carrier rate for updateApplicationPercents
        carrierQuantityPerAcreValue = convertedVal;

        // Update operation and component quantity
        applicationQuantityPerAcre = updateQuantity(convertedVal, tank, unit);
        setCarrierQuantityPerAcre(val);

        // Update component cost
        if (val !== '' && carrierPrice !== '') {
          setCarrierCostPerAcre(Number(val) * Number(carrierPrice));
        }
        else {
          setCarrierCostPerAcre(0);
        }
        break;
      case 'unit':
        // Ignore if user tries to clear unit value
        if (val === undefined || val === null) {
          break;
        }
        // Save carrier rate for updateApplicationPercents - does NOT change!!!
        carrierQuantityPerAcreValue = carrierQuantityPerAcre;

        // Update operation quantity
        setCarrierUnit(val);
        if (carrierQuantityPerAcre !== '') {
          const convertedVal = applicationConversions[val][unit] * carrierQuantityPerAcre
          applicationQuantityPerAcre = updateQuantity(convertedVal, tank, unit);
        }
        break;
      default:
        console.log(`unexpected type: ${type}`);
    }

    // update component percentages on quantity change
    if (type === 'quantityPerAcre' || type === 'unit') {
      // Ignore if user tries to clear unit value
      if (val === undefined || val === null) {
        return;
      }
      // console.log("\nupdate percent from handleCarrierUpdate")
      updateApplicationPercents(
        tank,
        carrierQuantityPerAcreValue !== '' ? carrierQuantityPerAcreValue : 0,
        applicationQuantityPerAcre,
      );
    }
  };

  /**
   * Calculate the cost per acre using the cost of each component plus the one that was just updated
   * @param {String} newPrice price for excluded component
   * @param {Array} mix list of components (and carrier) to calculate rest of the price
   */
  const updateApplicationCostPerAcre = (newPrice, mix) => {
    let componentCostPerAcre = Number(newPrice);
    for (const x of mix) {
      componentCostPerAcre += !Number.isNaN(x.costPerAcre)
        ? Number(x.costPerAcre)
        : 0;
    }
    setCostPerAcre(componentCostPerAcre);
  };

  /**
   * Updates quantity and quantity per acre of application when called (on component update, unit change, etc.)
   * and converts the results to the passed unit.
   * Also updates all the zones to match the new field applied rate based on percentages.
   * Returns updated value of quantity per acre
   * NOTE: updateApplicationPercents should be called each time this function is called. So, we should just add that call here
   *       That would also simplify the code
   * @param {Number} newQuantity quantity of components not in tank (always includes carrier)
   * @param {Array} mix tank mix
   * @param {String} unit unit of application operation to convert components to
   * @param {Undefined|String} unitChange value of old unit if this is coming from handleUnitChange
   */
  const updateQuantity = (newQuantity, mix, unit, unitChange = undefined) => {
    let averageAppliedRate = Number(newQuantity);
    for (const x of mix) {
      averageAppliedRate += !Number.isNaN(x.quantityPerAcre)
        ? applicationConversions[unitConversion(x.unit, true)][unit] * Number(x.quantityPerAcre)
        : 0;
    }
    updateZoneRates(quantityPerAcre, averageAppliedRate, {oldUnit: unitChange, newUnit: unit});
    setQuantityPerAcre(averageAppliedRate);
    setQuantity(averageAppliedRate * acres);
    return averageAppliedRate;
  };

  const handleCombinationFromShp = (geoJson) => {
    for(var i=0;i<geoJson.varieties.length;i++){
      for(var j=0;j<combination.length;j++){
        geoJson.varieties[i][combination[j].operationProp] = geoJson.varieties[i][combination[j].fileProp]
      }
    }

    setProductName(geoJson.varieties[0].name)
    updateZoneRates(quantityPerAcre, geoJson.varieties[0].rate);
    setQuantityPerAcre(geoJson.varieties[0].rate)
  }

  /**
   * Tracks percent of application each component makes up. This is called whenever field applied rate is updated.
   * @param {Array} tankMix tank mix
   * @param {Number} carrierPerAcre carrier rate value
   * @param {Number} applicationQuantityPerAcre field applied rate
   * @param {undefined|String} unitFactor factor to be used to convert percentages if coming from handleUnitChange
   */
  const updateApplicationPercents = (
    tankMix,
    carrierPerAcre,
    applicationQuantityPerAcre,
    unitFactor = undefined,
  ) => {
    console.log("Changing percents");
    console.log('carrierPerAcre :>> ', carrierPerAcre);
    console.log("application quantity", applicationQuantityPerAcre);
    // console.log("application unit", unit);
    // console.log('unitFactor :>> ', unitFactor);

    const tankCopy = [...tankMix];
    // console.log([...tankCopy])

    if (applicationQuantityPerAcre !== 0) {
      for (const component of tankCopy) {
        // Just update percents using new unit value conversion factor instead if unit changed 
        if (unitFactor !== undefined) {
          component.percent = component.percent / unitFactor;
        }
        else {
          component.percent = component.quantityPerAcre / applicationQuantityPerAcre;
        }
      }
      setTank(tankCopy);

      if (unitFactor !== undefined) {
        // console.log("Carrier percent:", carrierPercent / unitFactor );
        setCarrierPercent( carrierPercent / unitFactor );
      }
      else {
        setCarrierPercent( carrierPerAcre / applicationQuantityPerAcre );
      }
    }
  };

  const updateApplication = (keyValue, name) => {
    const modifiedOp = tank.filter((op) => op.productName === name)[0];
  };

  const handleDownloadClick = (fileType, fileName) => {
    /** When a filename is clicked on for download, send the request and try to dl it **/
    let url = `${Endpoints.BASEURL}/api/ProfitTool/DeereOperation/blob/download`;
    url += `?orgID=${field.orgId}`;
    url += `&fieldID=${field.id}`;
    url += `&operationID=${toEdit.operationID}`;
    url += `&operationType=application`;
    url += `&fileType=${fileType}`;
    url += `&fileName=${fileName}`;
    url += `&source=${toEdit.source}`;

    // init the download
    document.location.href = url;
  }

  const [editedZones, setEditedZones] = useState([])

  useEffect(() => {
    if (editedZones.length > 0) {
      updatedEditedApplicationZones(editedZones)
    }
  }, [editedZones])

  const updatedEditedApplicationZones = (editedZones) => {
    const updated = updateEditedZones(editedZones, zones)
    setZones(updated)
    setEditedZones([])
  }

  const updateEditedZones = (editedZones, operations) => {
    // make a clone to to update
    const operationsClone = cloneDeep(operations)
    for (const operation of operationsClone) {
      let matchingEditedZone = editedZones.filter(x => x.zone.properties.id === operation.properties.id)
      if (matchingEditedZone.length > 0) {
        operation.geometry = matchingEditedZone[0].zone.geometry
        operation.properties.CALCACRES = matchingEditedZone[0].acres
      }
    }
    return operationsClone
  }

  const updateGeoJson = (geoJson, newAcres) => {
    if ('color' in geoJson) {
      // This means we are editing an existing zone. The way this function is
      // use in ZoneMap we won't have access to varieties state, so we need to
      // set editedVariety state and let a useEffect call function to update
      // edited variety in varieties array.
      setEditedZones((prev) => [...prev, {zone: geoJson, acres: newAcres}])

    } else {
      updateZonesFromEditedBoundary(geoJson, newAcres)
    }
  };

  const updateZonesFromEditedBoundary = (geoJson, newAcres) => {
    //console.log('zones', zones, action)
    if (zones.length === 1) {
      const updatedZones = {
        ...geoJson,
        properties: {CALCACRES: newAcres},
        quantity: zones[0].quantity
      }
      setZones([updatedZones])
    } else {
      console.log(zones)
    }
  }

  const handleProductInput = async (idx, val) => {
    /** This is the function from the old PL tool to retrieve product names
     * based on user input. The api performs the autocomplete functionaity on keypress. **/
    
    if ( val === undefined || val === null || val.replace(/\s/g, '').length == 0) {
      val = '';
    }

    if ( val !== "" && !(allProducts.includes(val)) ) {
      let url = Endpoints.BASEURL;
      url += Endpoints.APPMATERIALNAME;
      url += '/' + val;
      await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          'Accept': 'application/json'
        }
      })
      .then((res) => {
        if (res.status === 200) {
          return res.json();
        } else {
          throw new Error("Failed to get product names")
        }
      })
      .then((prods) => setAllProducts(prods))
      .catch((err) => enqueueSnackbar(err));
    }
  }

  /**
   * Removes carrier influence on quantity and resets some carrier values upon removal.
   */
  const deleteCarrier = () => {
    // Passing 0 is equivalent to saying carrier has 0 as it's rate
    const applicationQuantityPerAcre = updateQuantity(0, tank, unit);
    // update component percentages on quantity change
    // console.log("\nupdate percent from deleteCarrier()")
    updateApplicationPercents(tank, 0, applicationQuantityPerAcre);

    setAddCarrier(false);
    // This is needed so carrier values are not saved
    setCarrier('');
    // Don't know what these are for
    setCarrierTotal('0');
    setCarrierRate('0');
    setCarrierQuantityPerAcre('0')
  }

  /**
   * Sets some default carrier values for a new carrier.
   * Do a full reset if it's not an add application. This is needed so that upon carrier delete, setQuantityPerAcre does
   * not keep decreasing.
   */
  const resetCarrier = (full = false) => {
    if (full) {
      setCarrier('');
      setCarrierQuantity('');
      setCarrierQuantityPerAcre('');
      setCarrierPercent(0);
    }
    else {
      setCarrier('Water');
      setCarrierQuantity('14');
      setCarrierQuantityPerAcre('14');
      setCarrierPercent(0.999571612);
    }
    setCarrierUnit(units[0]);
    setCarrierPrice('');
    setCarrierCostPerAcre('');
    setAddCarrier(true);
  }

  const header = () => (
    <Box className={classes.head}>
      <Box p={2}>
        {action}
        {' '}
        Application
      </Box>
      <Box display="flex" style={{ marginTop: 10 }}>
        <Box
          className={classes.select}
          color={createTankMix ? green : darkGrey}
          onClick={() => setCreateTankMix(true)}
        >
          Components
          {createTankMix && (
            <Divider
              className={classes.border}
              style={{ marginTop: '10px' }}
            />
          )}
        </Box>
        <Box
          className={classes.select}
          color={createTankMix ? darkGrey : green}
          onClick={() => setCreateTankMix(false)}
        >
          <Box>Zones</Box>
          <Box fontSize={12}>
            { zones.length }
            {' Zone(s) / '}
            {acres.toFixed(2)}
            {' '}
            Acres
          </Box>
          {!createTankMix && <Divider className={classes.border} />}
        </Box>
        {!manualEntry && (
          <CustomToolTip
            title="This operation is imported from your Precision Ag Data"
            placement="left"
          >
            <Chip
              style={{ margin: '0 5px' }}
              label={(
                <Box>
                  <SyncAltIcon />
                  AG DATA
                </Box>
              )}
            />
          </CustomToolTip>
        )}
        <HighlightOffIcon
          className={classes.icon}
          onClick={() => setOpenConfirm(true)}
        />
      </Box>
    </Box>
  )

  return (
    <Box
      // style={modalStyle}
      className={classes.paper}
      borderRadius="borderRadius"
      style={{
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
        padding: 1,
        height: height - 20,
        width: width - 20,
      }}
    >
      { header() }

      { /* Body */}
      <Box className={classes.body}>
        <Box className={classes.details} borderRight={1}>
          <Box fontSize={20} fontWeight={500}>
            Application Summary
          </Box>
          <Box>
            <Box display="flex">
              {displayDetails ? (
                <ExpandLessIcon
                  className={classes.icon}
                  onClick={() => setDisplayDetails(false)}
                />
              ) : (
                <ExpandMoreIcon
                  className={classes.icon}
                  onClick={() => setDisplayDetails(true)}
                />
              )}
              Application Details
            </Box>

            {displayDetails && (
              <>
              <Box>
                <Box display="flex" mt={1}>
                  <Box style={{ width: '210px' }}>
                    Date
                    <TextField
                      className={classes.input}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={date}
                      onChange={(event) => setDate(event.target.value)}
                    />
                  </Box>
                  <Box style={{ width: '200px' }}>
                    Product
                    <Box className={classes.disabledInput}>{productName}</Box>
                  </Box>
                </Box>
              </Box>


                <Box>
                  <Box display="flex">
                    <Box>
                      Unit
                      {manualEntry || action === 'Add' ? (
                        <Autocomplete
                          className={classes.input}
                          value={unit}
                          onChange={(event, newValue) => {
                            handleUnitChange(newValue);
                          }}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            setInputValue(newInputValue);
                          }}
                          options={units}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                        />
                      ) : (
                        <Box className={classes.disabledInput}>{unit}</Box>
                      )}
                    </Box>
                    <Box style={{ width: '250px' }}>
                      {`Field Applied Rate (${capitalizeFirstLetter(unit)}/Acre)`}
                      <Box className={classes.disabledInput}>
                        {noUnitSelected ? (
                          "No Unit Selected"
                        ) : (
                          numFormat(quantityPerAcre)
                        )}
                      </Box>
                    </Box>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box>
                    Acres
                    <Box className={classes.disabledInput}>
                      {numFormat(acres)}
                    </Box>
                  </Box>
                  <Box>
                    {`Quantity Applied (${capitalizeFirstLetter(unit)})`}
                    <Box className={classes.disabledInput}>
                      {noUnitSelected ? (
                        "No Unit Selected"
                      ) : (
                        numFormat(Number(quantityPerAcre) * Number(acres))
                      )}
                    </Box>
                  </Box>
                </Box>

                <Box display="flex">
                  <Box>
                    <Box display='flex' alignItems='center'>
                      <Box>
                        {`Price ($/${capitalizeFirstLetter(unit)})`}
                      </Box>
                      <CustomToolTip
                        title='See an incorrect price? Update it for your entire organization in the Price Converter by clicking the info icon.'
                        placement='right'
                      >
                        <a href={`${Endpoints.HOME}/app/priceconverter`} target='_blank'>
                          <InfoOutlinedIcon className={classes.infoToolTip}/>
                        </a>
                      </CustomToolTip>
                    </Box>
                    <Box className={classes.disabledInput}>
                      {!Number.isNaN(costPerAcre / quantityPerAcre)
                        ? formatNumText(costPerAcre / quantityPerAcre)
                        : 0}
                    </Box>
                  </Box>
                  <Box>
                    Cost ($/Acre)
                    <Box className={classes.disabledInput}>
                      {formatNumText(costPerAcre)}
                    </Box>
                  </Box>
                </Box>
              </>
            )}
            <Divider />
          </Box>

          {(manualEntry || action === 'Add') && (
            <Box mt={1}>
              {createTankMix ? (
                <Box
                  className={classes.editZones}
                  border={1}
                  borderRadius="borderRadius"
                  onClick={() => setCreateTankMix(false)}
                >
                  Edit Zones
                </Box>
              ) : (
                <Box
                  className={classes.editZones}
                  border={1}
                  borderRadius="borderRadius"
                  onClick={() => setCreateTankMix(true)}
                >
                  Edit Tank Mix
                </Box>
              )}
            </Box>
          )}

          {manualEntry && zones.map((zone, i) => (
            <Zone
              key={i}
              index={i}
              zone={zone}
              remove={removeZone}
              update={updateZone}
              unit={unit}
              hideValues={noUnitSelected}
            />
          ))}
          {/* In case there are no zones, show a message to user that they cannot save */}
          {!zones.length && (
            <Box m={1} display='flex'>
              {loading ?
                <Box display='flex' alignItems='center'>
                  {'Loading zones '}
                  <LinearProgress style={{width: 40, marginLeft: 8}}/>
                </Box>
                : <Box className={classes.errorMessageBox}>
                  To save, you must have at least one zone for each operation.
                </Box>
              }
            </Box>
          )}
        </Box>

        <Box
          style={{
            height: '100%',
            // 490 is width of left side and 30 is to take into 20 taken from this UpdateApplication box
            // and 10 for if scroll bar is needed if too many zones
            width: width - 490 - 30,
            overflow: 'auto',
          }}
        >
            <Box style={{position: 'relative', overflow:'hidden'}}>
              <Box
                p={1}
                style={{
                  position: createTankMix ? 'static' : 'absolute',
                  right: '-5000px',
                  top: '5000px',
                  overflow: 'auto'
                }}
              >

                <Box fontSize={20} fontWeight={600} color={darkGrey}>
                  Components
                </Box>
                {/* Show no unit selected message if necessary; do not show edit component message if unit selected message is up */}
                {noUnitSelected ? (
                  <Box display='flex'>
                    <Box className={classes.errorMessageBox} marginY="12px">
                      Please enter a unit for application.
                    </Box>
                  </Box>
                ) : !componentQuantityEditable && (
                  <Box display='flex'>
                    <Box className={classes.errorMessageBox} marginY="12px">
                      To edit components' Quantity/Acre values, you must first have non-zero Applied Rates for each zone.
                    </Box>
                  </Box>
                )}
                {tank.map((component, i) => (
                  <Box key={i} mb={1}>
                    <Box
                      mb={1}
                      display="flex"
                      fontWeight={500}
                      color={darkGrey}
                      flexWrap="wrap"
                    >
                      <Box>
                        <Box>Product</Box>
                        {manualEntry || action === 'Add' ? (
                          <Autocomplete
                            className={classes.mixInput}
                            style={{width: 300}}
                            freeSolo
                            value={component.productName === null ? '' : component.productName}
                            onChange={(event, newValue) =>  handleMixUpdate(i, newValue, 'productName')}
                            inputValue={component.productName === null ? '' : component.productName}
                            onInputChange={(event, newInputValue) => handleMixUpdate(i, newInputValue, 'productName')}
                            options={allProducts}
                            renderInput={(params) => (
                              <TextField {...params} variant='outlined' />
                            )}
                          />
                        ) : (
                          <Box className={classes.mixDisabledInput}>
                            {component.productName}
                          </Box>
                        )}
                      </Box>

                      <Box>
                        <Box>Quantity/Acre</Box>
                        {(manualEntry || action === 'Add') ? (
                          componentQuantityEditable ? (
                            <TextField
                              className={classes.mixInput}
                              variant="outlined"
                              value={formatNumText(component.quantityPerAcre)}
                              // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                              onBlur={(e) => handleMixUpdate(i, Number(e.target.value.replaceAll(',', '')), 'quantityPerAcre')}
                              InputProps={{
                                inputComponent: NumberFormatThree,
                              }}
                            />
                          ) : (
                            <Box className={classes.mixDisabledInput}>
                              {numFormat(component.quantityPerAcre)}
                            </Box>
                          )
                        ) : (
                          <Box className={classes.mixDisabledInput}>
                            {numFormat(component.quantity / component.area)}
                            {` ${component.unit}/Acre`}
                          </Box>
                        )}
                      </Box>

                      <Box>
                        Unit
                        {action === 'Add' ? (
                          <Autocomplete
                            className={classes.input}
                            value={component.unit}
                            onChange={(event, newValue) => {
                              handleMixUpdate(i, newValue, 'unit');
                            }}
                            inputValue={component.inputUnit}
                            onInputChange={(event, newInputValue) => {
                              handleMixUpdate(i, newInputValue, 'inputUnit');
                            }}
                            options={units}
                            renderInput={(params) => (
                              <TextField {...params} variant="outlined" />
                            )}
                          />
                        ) : (
                          <Box className={classes.mixDisabledInput}>
                            {component.unit}
                          </Box>
                        )}
                      </Box>

                      <Box>
                        <Box>
                          {`Price ($/${capitalizeFirstLetter(component.unit)})`}
                        </Box>
                        <TextField
                          className={classes.mixInput}
                          variant="outlined"
                          value={formatNumText(component.price)}
                          // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                          onBlur={(e) => handleMixUpdate(i, Number(e.target.value.replaceAll(',', '')), 'price')}
                          InputProps={{
                            inputComponent: NumberFormatThree,
                          }}
                        />
                      </Box>

                      <Box>
                        <Box>Cost ($/Acre)</Box>
                        <Box className={classes.mixDisabledInput}>
                          {dollarFormat(component.costPerAcre)}
                        </Box>
                      </Box>

                      {(manualEntry || action === 'Add') && (
                        <Box className={classes.delete}>
                          <DeleteOutlineIcon
                            fontSize="small"
                            onClick={() => removeMix(i)}
                          />
                        </Box>
                      )}
                    </Box>
                    <Divider />
                  </Box>
                ))}

                <Box my={2} display="flex">
                  {(manualEntry || action === 'Add') && (
                    <Box
                      className={classes.addMix}
                      border={1}
                      borderRadius="borderRadius"
                      onClick={() => addNewMix()}
                    >
                      Add tank mix component
                    </Box>
                  )}

                  {((manualEntry || action === 'Add') && !addCarrier) && (
                    <Box
                      className={classes.addMix}
                      border={1}
                      borderRadius="borderRadius"
                      onClick={() => resetCarrier(true)}
                    >
                      Add Carrier
                    </Box>
                  )}
                </Box>

                {addCarrier && (
                  <Box>
                    <Box
                      display="flex"
                      flexWrap="wrap"
                      fontWeight={500}
                      color={darkGrey}
                      style={{ width: '100%' }}
                    >
                      {manualEntry || action === 'Add' ? (
                        <>
                          <Box>
                            <Box>Carrier</Box>
                            <TextField
                              className={classes.mixInput}
                              variant="outlined"
                              value={carrier}
                              onChange={(e) => setCarrier(e.target.value)}
                            />
                          </Box>
                          <Box>
                            <Box>Quantity/Acre</Box>
                            { componentQuantityEditable ? (
                              <TextField
                                className={classes.mixInput}
                                variant="outlined"
                                value={formatNumText(carrierQuantityPerAcre)}
                                // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                                onBlur={(e) => handleCarrierUpdate(
                                  Number(e.target.value.replaceAll(',', '')),
                                  'quantityPerAcre',
                                )}
                                InputProps={{
                                  inputComponent: NumberFormatThree,
                                }}
                              />
                            ) : (
                              <Box className={classes.mixDisabledInput}>
                                {numFormat(carrierQuantityPerAcre)}
                              </Box>
                            )}
                          </Box>

                          <Box>
                            Unit
                            <Autocomplete
                              className={classes.input}
                              value={carrierUnit}
                              onChange={(event, newValue) => {
                                handleCarrierUpdate(newValue, 'unit');
                              }}
                              inputValue={carrierInputValue}
                              onInputChange={(event, newInputValue) => {
                                setCarrierInputValue(newInputValue);
                              }}
                              options={units}
                              renderInput={(params) => (
                                <TextField {...params} variant="outlined" />
                              )}
                            />
                          </Box>
                        </>
                      ) : (
                        <>
                          <Box>
                            Carrier
                            <Box className={classes.mixDisabledInput}>
                              {carrier}
                            </Box>
                          </Box>
                          <Box>
                            Quantity per acre
                            <Box className={classes.mixDisabledInput}>
                              {numFormat(carrierQuantityPerAcre)}
                              {` ${carrierUnit}/Acre`}
                            </Box>
                          </Box>
                          <Box>
                            Unit
                            <Box className={classes.mixDisabledInput}>
                              {carrierUnit}
                            </Box>
                          </Box>
                        </>
                      )}

                      <Box>
                        <Box>
                          {`Price ($/${capitalizeFirstLetter(carrierUnit)})`}
                        </Box>
                        <TextField
                          className={classes.mixInput}
                          variant="outlined"
                          value={formatNumText(carrierPrice)}
                          // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                          onBlur={(e) => handleCarrierUpdate(Number(e.target.value.replaceAll(',', '')), 'price')}
                          InputProps={{
                            inputComponent: NumberFormatThree,
                          }}
                        />
                      </Box>

                      <Box>
                        <Box>Cost ($/Acre)</Box>
                        <Box className={classes.mixDisabledInput}>
                          {dollarFormat(carrierCostPerAcre)}
                        </Box>
                      </Box>
                      {
                        (manualEntry || action === 'Add')

                          && (
                          <Box className={classes.delete}>
                            <DeleteOutlineIcon
                              fontSize="small"
                              onClick={() => deleteCarrier()}
                            />
                          </Box>
                          )
                        }
                    </Box>
                  </Box>
                )}
              </Box>
            </Box>

            <Box style={{position: 'relative', overflow:'hidden'}}>
              <Box
                style={{
                  position: !createTankMix ? 'static' : 'absolute',
                  right: '-5000px',
                  top: '5000px',
                  height: '100%',
                  width: '100%',
                }}
              >
                <ZoneMap
                  canEdit={manualEntry || action === 'Add'}
                  geoJson={zoneGeo}
                  updateGeoJson={updateGeoJson}
                  isOperationMap={isOperationMap}
                  operationMap={operationMapDis}
                  containerHeight={isOperationMap ? height - 300  : height - 135}
                  origin={"Application"}
                  setZones={setMapZones}
                  deletedSliceZones={deletedSliceZones}
                  operationType={"Application"}
                  combination={combination}
                  setCombination={setCombination}
                  handleCombinationFromShp={handleCombinationFromShp}
                  action={action}
                  updateZones={setEditLayers}
                  usedZoneColors={usedZoneColors}
                  maxZoom={field.state === "" ? 17 : 18}
                />
                {isOperationMap === true && operationMapDis.length > 0 && (
                  <Box>
                    <RangeSlider
                      legend={operationMapDis[0].legend}
                      isOperation
                      type="Operation"
                      editModal
                      manualEntry={manualEntry}
                      unit={operationUnit}
                      profitMapResponse={profitMapResponse}
                      perAcre={perAcre}
                      isQuantityMap={isQuantityMap}
                      setIsQuantityMap={setIsQuantityMap}
                      selectedOp={selectedOp}
                    />
                  </Box>
                )}
              </Box>
            </Box>

        </Box>
      </Box>

      {/* Footer */}
      <Box className={classes.footer}>


        {
          (!downloadsLoading) &&
          <Box mx={2} color={green} fontWeight={600}>
            <Button
              color="primary"
              onClick={(e) => {
                setPopoverAnchor(e.currentTarget);
                setPopoverOpen(!downloadsPopoverOpen);
              }}
              disabled={downloads===undefined}
            >
              Downloads
              {
                downloads && (
                  <React.Fragment>
                    <ExpandLessIcon />
                    <Popover
                      id='downloads-popover'
                      open={downloadsPopoverOpen}
                      anchorEl={downloadsPopoverAnchor}
                      onClose={() => {
                        setPopoverAnchor(null);
                        setPopoverOpen(false);
                      }}
                      anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center'
                      }}
                      transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center'
                      }}
                    >
                      <Box style={{padding: '1em'}}>
                        {
                          downloads.shapefiles && downloads.shapefiles.length > 0 && (
                            <React.Fragment>
                              <Box className={classes.edit} fontSize={18} >
                                Shapefiles
                                {
                                  downloads.shapefiles.map((s, i) => (
                                    <Box
                                      key={i}
                                      onClick={() => handleDownloadClick("shapefile", s)}
                                      style={{marginLeft: '2em'}}
                                    >
                                      {s}
                                    </Box>
                                  ))
                                }
                              </Box>
                            </React.Fragment>
                          )
                        }

                        {
                          downloads.rasters && downloads.rasters.length > 0 && (
                            <React.Fragment>
                              <Divider />
                              <Box
                                className={classes.edit}
                                fontSize={18}
                                style={{paddingTop: '0.5em'}}
                              >
                                Rasters
                                {
                                  downloads.rasters.map((r, i) => (
                                    <Box
                                      key={i}
                                      onClick={() => handleDownloadClick("raster", r)}
                                      style={{marginLeft: '2em'}}
                                    >
                                      {r}
                                    </Box>
                                  ))
                                }
                              </Box>
                            </React.Fragment>
                          )
                        }

                        {
                          downloads.adapt && downloads.adapt.length > 0 && (
                            <React.Fragment>
                              <Divider />
                              <Box
                                className={classes.edit}
                                fontSize={18}
                                style={{paddingTop: '0.5em'}}
                              >
                                Adapt Files
                                {
                                  downloads.adapt.map((a, i) => (
                                    <Box
                                      key={i}
                                      onClick={() => handleDownloadClick("adapt", a)}
                                      style={{marginLeft: '2em'}}
                                    >
                                      {a}
                                    </Box>
                                  ))
                                }
                              </Box>
                            </React.Fragment>
                          )
                        }
                      </Box>
                    </Popover>
                  </React.Fragment>
                )
              }
            </Button>
          </Box>
        }

        <Button
          variant="outlined"
          color="primary"
          style={{ margin: '0 25px', backgroundColor: '#ffffff' }}
          onClick={() => cancel()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
          disableElevation
          disabled={!zones.length}
        >
          Save
        </Button>

        { openConfirm && (
          <ConfirmationPopup
            setOpen={setOpenConfirm}
            save={handleSave}
            cancel={cancel}
            disableSave={!zones.length}
          />
        )}
      </Box>

      {loading && <SpinningLoader />}
    </Box>
  );
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Box, Typography, Checkbox, Divider
} from '@material-ui/core/';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { useWindowDimensions } from '../../../utils/dimensions';

const useStyles = makeStyles((theme) => ({
  title: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 18,
    backgroundColor: '#006ba1',
    color: 'white',
    cRadius: '8px 8px 0 0',
  },
  bundle: {
    width: '360px',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bundleContent: {
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
  },
  selectField: {
    display: "flex",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
         cursor: 'pointer',
         color: theme.palette.primary.dark,
      },
  }
}));

export const CreateBundle = ({
  report,
  addBundle,
  inOrder,
  fields,
  fieldName,
  setSection }) => {

  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [checked, setChecked] = useState(false);

  const [option, setOption] = useState(0);

  return (
    <Box
      m={2}
      className={classes.bundle}
      boxShadow={3}
      border={5}
      borderColor='#006ba1'
      borderRadius={16}
      fontWeight={500}
      fontSize={14}
    >
      <Box className={classes.title}>
        {/* Title */}
        <Typography align="center" style={{fontWeight: 500, fontSize: 18}}>
          {report.name}
        </Typography>
      </Box>


      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        style={{height: '100%'}}
      >
        <Box px={1}>
          <Box style={{marginTop: 4}}>
            {report.included.filter(x => x !== 'Remediation Response Analysis' && !report.optional.includes(x)).map((info, i) => (
              <Box key={i} display='flex' alignItems='center' style={{padding: '4px'}} >
                <FiberManualRecordIcon style={{marginRight: 4, fontSize: 12, color: '#006ba1'}}/>
                {info}
              </Box>
            ))}
          </Box>

          { report.optional.length > 0 &&
            <Box px={1} style={{marginTop: 4}}>
              <Box>Please choose one:</Box>
              {report.optional.map((info, i) => (
                <Box key={i} display='flex' alignItems='center'>
                  <Checkbox
                    checked={option === i}
                    onChange={() => setOption(i)}
                   />
                   <Box>
                     {info}
                   </Box>
                </Box>
              ))}
              <Divider style={{marginTop: 4}}/>
            </Box>
          }

          <Box display='flex' alignItems='center'>
            <Checkbox
              checked={checked}
              onChange={() => setChecked(!checked)}
            />
            <Box>
              {'Add Remediation Response Analysis for $'}
              {report.remediationPrice}
            </Box>

          </Box>
        </Box>

        <Box>
          <Box my={1} mx={4}>
            <Box>
              {'$'}
              {
                report.included.includes('Remediation Response Analysis') ?
                report.value + report.remediationPrice
                :
                report.value
              }
              {' Value'}
            </Box>
            <Box color="#e53935" borderBottom={1} style={{ width: '150px' }}>
              {'-$'}
              {report.discount}
              {' Bundle Discount'}
            </Box>
            <Box color="#67b346">
              {'$'}
              {report.price}
              {' Package Price (per acre)'}
            </Box>
          </Box>

          {
            fields.length === 0 ?
              <Box
                mb={1}
                className={classes.selectField}
                onClick={() => setSection(1)}
              >
                Select a field to begin adding services
              </Box>
            :
            !inOrder.includes(report.name) ?
              <Box display="flex" justifyContent="center" mb={1}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => addBundle(report, checked, option)}
                  style={{
                    backgroundColor: '#006ba1',
                  }}
                >
                  Add to Order
                </Button>
              </Box>
            :
              <Box
                mb={1}
                display="flex"
                justifyContent="center"
                fontSize={12}
                fontWeight={500}
                color={'#006ba1'}
              >
                In Order
              </Box>

          }
        </Box>

      </Box>

    </Box>
  );
};

import React, { useState, useEffect, useContext } from 'react';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Typography,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  Slide,
  Switch,
  FormControl,
  TextField
} from '@material-ui/core';
import { ReportMap } from './ReportMap';
import { DisplayPdfMap } from '../../Maps/DisplayPdfMap';
import { Scatter } from './Scatter';
import { CostsChart } from './CostsChart';
import { useWindowDimensions } from '../../../utils/dimensions';
import { numFormat, dollarFormat } from '../../../utils/helpers';
import '../../../styles/Reports.css';
import {
  lightGrey, grey, darkGrey, green,
} from '../../../styles/colors';
import { Endpoints } from '../../../constants/Endpoints';
import { PaymentApp } from './PaymentApp';
import { InsuranceTools } from '../../InsuranceTools/InsuranceTools';
import { RateReview } from '@material-ui/icons';
import { CustomToolTip } from '../../../utils/customComponents'
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { LandlordTenant } from './LandlordTenant/LandlordTenant'
import * as df from "../../../utils/dataFetchers"
import * as fr from "../../../utils/farmReportDataFetchers"

import * as frd from '../../FarmReportHelper/SeedingHelper'
import * as har from '../../FarmReportHelper/HarvestHelper'
import * as appl from '../../FarmReportHelper/ApplicationHelper'
import * as gen from '../../FarmReportHelper/GenericHelper'

import { FieldContext } from "../../Context/FieldContext";
// import { FarmReport } from '../../FarmReportHelper/FarmReport';

const useStyles = makeStyles((theme) => ({
  selections: {
    backgroundColor: theme.palette.greys.light,
    width: '200px',
    minWidth: '200px',
    maxWidth: '200px',
    height: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  select: {
    margin: '5px 10px',
    paddingBottom: '10px',
    borderRadius: '5px',
    fontWeight: 600,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  mapSelect: {
    margin: '2px 8px',
    borderRadius: 4,
    padding: 8,
    fontWeight: 500,
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  chartSelect: {
    margin: '5px 0px',
    borderRadius: 4,
    padding: 8,
    fontWeight: 600,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoToolTip: theme.infoToolTip,
}));

/**
 * Controls map and chart displays.
 * @param {Number} totalCosts Seeding, application, field pass and other costs
 * @param {Number} totalRevenue Harvest and other revenue for field
 * @param {Number} totalProfit Total costs + total revenue
 * @param {Bool} loading Used to show spinner when map is loading
 * @param {Array} displayed Contains operation type, all displayed, and total
 * @param {String} source Source for data. Ex. Deere, Climate
 * @param {Object} selectedReport Selected report from mobile navigation
 * @param {Bool} profitMapNeedsUpdating Determine if need to udate profit map on load
 * @param {Function} setProfitMapNeedsUpdating Set to false after update
 */
export function Reports({
  field,
  operations,
  summary,
  perAcre,
  netSeededAcres,
  year,
  profitMapResponse,
  profitMap,
  operationMap,
  getProfitMap,
  logBinRange,
  getBinRange,
  pushProfitMap,
  getElevationMap,
  elevationMap,
  totalCosts,
  totalRevenue,
  totalProfit,
  loading,
  profitMapLoading,
  operationMapLoading,
  elevationMapLoading,
  displayed,
  savedTemplate,
  getAllTemplates,
  profitMapAccess,
  images,
  zoneAnalysis,
  zoneAnalysisRes,
  setZoneAnalysisRes,
  source,
  getElevationIndex,
  highRes,
  NPK,
  getNutrientParser,
  authenticated,
  setProfitMapAccess,
  handlePayment,
  isDisabled,
  setScatterLoaded,
  processing,
  setProcessing,
  syncData,
  errorMessage,
  isZoneAnalysisRes,
  setZoneAnalysisLoader,
  selectedReport,
  mobileBreakPoint,
  harvestRevenue,
  otherRevenue,
  profitMapNeedsUpdating,
  setProfitMapNeedsUpdating,
  selectedTemplate,
  setSelectedTemplate,
  setMapLoadedOnViewProfitLayers,
  templateRes,
  setTemplateRes,
  legendBinColors,
  setLegendBinColors,
  legendBinRanges,
  setLegendBinRanges,
  orgs,
  orderID,
  subOrderID,
  combineOps,
  clipByBoundary,
  files,
  dieselPrices
}) {

  const classes = useStyles();
  const nans = ['∞', 'NaN', '-∞'];
  // const mobileBreakPoint = 1080;
  const { height, width } = useWindowDimensions();
  const [fieldData, setFieldData] = useContext(FieldContext);
  const [tabValue, setTabValue] = useState(0);
  const [disOperations, setDisOperations] = useState([]);
  const [mapType, setMapType] = useState('Profit Map');
  const [zoneOptions, setZoneOptions] = useState([]);

  // temp for pdf
  const [pdfMapImages, setPdfMapImages] = useState(['']);
  const [pdfMapExtent, setPdfMapExtent] = useState([]);

  // Payment is processing
  // const [processing, setProcessing] = useState(false);

  // Types displayed under Map tab
  const mapTypes = [
    'Profit Map',
    'Comparison View',
    'Elevation Map',
    'Operation Layers',
  ];

  // No comparison view in mobile and no nested display under Map tab
  const mobileMapType = [
    'Profit Map',
    'Elevation Map',
    'Operation Layers',
    'Variety Analysis',
    'Zone Analysis',
  ]

  const elevationIndex = [
    { name: 'Slope', value: 'Slope' },
    { name: 'Relative Elevation', value: 'Relative_Elevation' },
    { name: 'Elevation Map', value: 'elevationMap' },
    { name: 'Topographic Position Index (TPI)', value: 'TPI' },
    { name: 'Terrain Ruggedness Index (TRI)', value: 'TRI' },
  ];

  // Insurance Tools
  const insuranceTools = [
    'What-If Analysis',
    'Premium Calculator',
    'Insurance AI',
  ];

  const [insuranceTool, setInsuranceTool] = useState(insuranceTools[0]);
  const [avgYield, setAvgYield] = useState([])

  const [basePrice, setBasePrice] = useState(Endpoints.PROFITLAYERS_PRICE)
  const [discount, setDiscount] = useState(0)
  const [referralcode, setReferralcode] = useState('')

  useEffect(() => {
    if (selectedReport !== undefined) {
      if (selectedReport.type === 'Map') {
        setTabValue(0);
        // Set map type based on index of mobileMapTypes from selectedReport.map
        setMapType(mobileMapType[selectedReport.map]);
      } else if (selectedReport.type === 'Chart') {
        setTabValue(1);
      } else if (selectedReport.type === 'Scatter') {
        setTabValue(2);
      } else if (selectedReport.type === 'Insurance') {
        setTabValue(3)
        setInsuranceTool(selectedReport.tool)
      }
    }
  }, [selectedReport]);

  useEffect(() => {
    const wholeOperations = [];
    if (highRes) {
      for (var i = 0; i < operations.length; i++) {
        operations[i].cellsize = Endpoints.highResCellSize;
      }
    } else {
      for (var i = 0; i < operations.length; i++) {
        operations[i].cellsize = Endpoints.defaultCellSize;
      }
    }

    for (let i = 0; i < operations.length; i++) {
      if (
        operations[i].fileType.toUpperCase() === 'HARVEST'
        || operations[i].fileType.toUpperCase() === 'SEEDING'
      ) {
        const obj = {
          operationID: operations[i].operationID,
          productName: operations[i].productName,
          date: operations[i].date,
          variety: null,
          fileType: operations[i].fileType,
          attribute: operations[i].attribute,
          cellsize: operations[i].cellsize,
          operationCost: operations[i].operationCost,
          price: operations[i].price,
          unit: operations[i].unit,
        };
        wholeOperations.push(obj);
      }
    }

    //console.log(wholeOperations)
    let uniqueOpns = new Map();
    wholeOperations.forEach((op) => uniqueOpns.set(op.operationID, op));
    uniqueOpns = [...uniqueOpns.values()];
    //console.log(uniqueOpns)

    const opns = operations.filter((op) => op.isDeleted == false && op.fileType.toUpperCase() !== 'FIELD PASS' && op.fileType.toUpperCase() !== 'TILLAGE'
      && op.fileType.toUpperCase() !== 'OTHER COSTS' && op.fileType.toUpperCase() !== 'OTHER REVENUES' && op.fileType.toUpperCase() !== 'GENERIC');

    setDisOperations([...opns, ...uniqueOpns]);

    const appOperations = operations.filter((op) => op.fileType.toUpperCase() === 'APPLICATION');
    const zoneAnalysisOptions = [];

    for (let i = 0; i < appOperations.length; i++) {
      const obj = {
        name: `${appOperations[i].productName} - ${appOperations[i].date} (${appOperations[i].fileType})`,
        value: appOperations[i].operationID,
      };
      zoneAnalysisOptions.push(obj);
    }

    setZoneOptions(zoneAnalysisOptions);
  }, [operations]);

  useEffect(() => {
    let cornYieldSum = 0;
    let cornAcresSum = 0;
    let soyYieldSum = 0;
    let soyAcreSum = 0;
    let cornYear = 0, soyYear = 0

    //console.log('operations', operations)
    for (var i = 0; i < operations.length; i++) {
      if (operations[i].fileType.toUpperCase() === "HARVEST" && operations[i].productName.toUpperCase().includes('CORN')) {
        cornYear = new Date(operations[i].date).getFullYear()
        cornYieldSum += operations[i].rate * operations[i].area
        cornAcresSum += operations[i].area
      }
      else if (operations[i].fileType.toUpperCase() === "HARVEST" && operations[i].productName.toUpperCase().includes('SOYBEANS')) {
        soyYear = new Date(operations[i].date).getFullYear()
        soyYieldSum += operations[i].rate * operations[i].area
        soyAcreSum += operations[i].area
      }
    }

    let cropYield = []

    cropYield.push({
      crop: 'CORN',
      cropCode: '41',
      avgYield: cornYieldSum / cornAcresSum,
      year: cornYear
    })

    cropYield.push({
      crop: 'SOYBEANS',
      cropCode: '81',
      avgYield: soyYieldSum / soyAcreSum,
      year: soyYear
    })

    //console.log('cropYield', cropYield)
    setAvgYield(cropYield)
  }, [operations])

  useEffect(() => {
    console.log('mapType', mapType)
  }, [mapType])

  const getFarmData = async () => {    
    let allFieldIds = fieldData.fields.map(value => value.id);
    
    let seedMap = await frd.getSeedingForFarm(field.orgId, field.farmId, year[0])
    let harMap = await har.getHarvestForFarm(field.orgId, field.farmId, year[0])
    let appMap = await appl.getApplicationForFarm(field.orgId, field.farmId, year[0])
    let genMap = await gen.getGenericForFarm(field.orgId, field.farmId, year[0], dieselPrices)
    let tillMap = await gen.getTillageForFarm(field.orgId, field.farmId, year[0], dieselPrices)

    let fieldIds = await fr.getFieldWithProfitSummary(field.orgId, field.farmId, year)  
    console.log('fieldIds', fieldIds)  
    fieldIds = JSON.parse(fieldIds)
    let fieldsWithSummary = fieldIds.map(value => value.field_id);

    let fieldsWithNoSummary = allFieldIds.filter((o) => fieldsWithSummary.indexOf(o) === -1);

    console.log('fieldsWithNoSummary', fieldsWithNoSummary)

    for(let i=0; i<fieldsWithNoSummary.length; i++){
      let fieldID = fieldsWithNoSummary[i]

      let seeding = seedMap.get(fieldID) === undefined ? [] : seedMap.get(fieldID)
      let har = harMap.get(fieldID) === undefined ? [] : harMap.get(fieldID)
      let app = appMap.get(fieldID) === undefined ? [] : appMap.get(fieldID)
      let generic = genMap.get(fieldID) === undefined ? [] : genMap.get(fieldID)
      let tillage = tillMap.get(fieldID) === undefined ? [] : tillMap.get(fieldID)

      let fieldOps = [...seeding, ...har, ...app, ...generic, ...tillage]

      if(fieldOps.length > 0){
        let orgId = fieldOps[0].organizationID
      
        let request = {
          FieldOperationList: fieldOps,
          Constant: 0,
          Year: year[0],
          IsResizeMapRequest: false,
          ProfitMapLegendRange: null,
          LegendColors: null,
          ProfitByZones: false,
          Zones: null,
          boundarygeojson: null,
          IsHighResolutionMapRequest: highRes,
          MultiYears:year,
        }
  
        console.log(request)
        let res = await df.getProfitMap(orgId, fieldID, request)
  
        console.log('res', res)
        if (res.profitMap.code === 500) {
          continue;
        }
        else {
           
          //Log profit map obj
          let profitLog = {
            ProfitMap_Response: null, //json string of response from get profitmap
            isLegendSaved: null, //bool -> false if range # changed, otherwise true
            zoneBoundary: null, // boundary of zone if map was for zone
            zoneCost: null, // cost if map was for zone
          };
          profitLog.ProfitMap_Response = JSON.stringify(res.profitMap);
          profitLog.isLegendSaved = 0;
          if (year.length === 1) {
            const logged = await df.logProfitMapresponse(
              orgId,
              fieldID,
              year[0],
              profitLog
            );
  
            let summaryObj = {
              NetSeededAcre: netSeededAcres,
              Profit: res.profitMap[0].mean
            }
            const summary = await df.logProfitMapSummary(
              orgId, fieldID, year[0], summaryObj
            )            
          }
        }
      }      
    }
  }


  const handleReferralCode = async (value) => {
    setReferralcode(value)

    try {
      let res = await df.checkReferralCode(value)
      console.log(res)
      if (Object.keys(res).length > 0 && res.percentageDiscount !== undefined) {
        setDiscount(res.percentageDiscount)
      }
      else {
        setDiscount(0)
      }
    }
    catch (err) {
      console.log('error verifying disocunt code', err)
      setDiscount(0)
    }
  }

  const showMapSelection = () =>
    // Variety and Zone maps will be displayed when tabValue is 0, but are no
    // longer in Map section sub group
    tabValue === 0 && mapTypes.includes(mapType);

  return (
    <Box display="flex" style={{ height: '100%', width: '100%' }}>
      <Slide
        direction="right"
        in={width >= mobileBreakPoint}
        mountOnEnter
        unmountOnExit
      >
        <Box
          className={classes.selections}
          style={{ height: height - 195, overflow: 'auto' }}
        >
          <Box my={1}>
            <Box
              className={classes.select}
              onClick={() => {
                setTabValue(0);
                setMapType('Profit Map');
                ReactGA.event({
                  category: "Reports",
                  action: `Viewed Profit Map`
                })
              }}
              style={(tabValue === 0 && mapTypes.includes(mapType)) ? { paddingBottom: 0 } : {}}
            >
              <Box
                id="map_options"
                style={
                  { color: (tabValue === 0 && mapTypes.includes(mapType)) ? darkGrey : grey }}
              >
                Maps
              </Box>
            </Box>

            {
              showMapSelection() && mapTypes.map((x, i) => (
                <Box key={i}>
                  <Box
                    className={classes.mapSelect}
                    onClick={() => {
                      setTabValue(0);
                      setMapType(x);
                      ReactGA.event({
                        category: "Reports",
                        action: `Viewed ${x}`
                      })
                    }}
                    color={mapType === x ? green : darkGrey}
                    style={{
                      backgroundColor: mapType === x ? 'white' : lightGrey,
                    }}
                  >
                    {x}
                  </Box>
                  <Divider style={{ margin: '2px 0' }} />
                </Box>
              ))
            }

            <Box
              onClick={() => {
                setTabValue(0);
                setMapType('Variety Analysis');
                ReactGA.event({
                  category: "Reports",
                  action: `Viewed Variety Analysis`
                })
              }}
            >
              <Box
                id="variety_analysis"
                className={classes.chartSelect}
                style={{
                  color: (tabValue === 0 && mapType === 'Variety Analysis') ? green : grey,
                  backgroundColor: (tabValue === 0 && mapType === 'Variety Analysis') ? '#ffffff' : lightGrey,
                }}
              >
                Variety Analysis
              </Box>
            </Box>
            <Box
              onClick={() => {
                setTabValue(0);
                setMapType('Zone Analysis');
                ReactGA.event({
                  category: "Reports",
                  action: `Viewed Zone Analysis`
                })
              }}
            >
              <Box
                id="zone_analysis"
                className={classes.chartSelect}
                style={{
                  color: (tabValue === 0 && mapType === 'Zone Analysis') ? green : grey,
                  backgroundColor: (tabValue === 0 && mapType === 'Zone Analysis') ? '#ffffff' : lightGrey,
                }}
              >
                Zone Analysis
              </Box>
            </Box>
            <Box
              onClick={() => {
                setTabValue(1);
                setMapType('');
                ReactGA.event({
                  category: "Reports",
                  action: `Viewed Cost Chart`
                })
              }}
            >
              <Box
                id="costs_chart"
                className={classes.chartSelect}
                style={{
                  color: tabValue === 1 ? green : grey,
                  backgroundColor: tabValue === 1 ? 'white' : lightGrey,
                }}
              >
                Cost Chart
              </Box>
            </Box>
            <Box
              onClick={() => {
                setTabValue(2);
                setMapType('');
                ReactGA.event({
                  category: "Reports",
                  action: `Viewed Scatter Plot`
                })
              }}
            >
              <Box
                id="scatter_plot"
                className={classes.chartSelect}
                style={{
                  color: tabValue === 2 ? green : grey,
                  backgroundColor: tabValue === 2 ? 'white' : lightGrey,
                }}
              >
                Scatter Plot
              </Box>
            </Box>

            <Box
              id="insurance_tools"
              className={classes.chartSelect}
              onClick={() => setTabValue(3)}
            >
              <Box style={{ color: tabValue === 3 ? darkGrey : grey }}>
                Insurance Tools
              </Box>
            </Box>

            {
              tabValue === 3 && insuranceTools.map((x, i) => (
                <Box key={i}>
                  <Box
                    className={classes.mapSelect}
                    onClick={() => {
                      setInsuranceTool(x);
                    }}
                    color={insuranceTool === x ? green : darkGrey}
                    style={{
                      backgroundColor: insuranceTool === x ? 'white' : lightGrey,
                    }}
                  >
                    {x}
                  </Box>
                  <Divider style={{ margin: '2px 0' }} />
                </Box>
              ))
            }

            <Box
              onClick={() => {
                setTabValue(4);
                setMapType('');
                ReactGA.event({
                  category: "Reports",
                  action: `Viewed Landlord Tenant Split`
                })
              }}
            >
              <Box
                id="landlord_split"
                className={classes.chartSelect}
                style={{
                  color: tabValue === 4 ? green : grey,
                  backgroundColor: tabValue === 4 ? 'white' : lightGrey,
                }}
              >
                Landlord - Tenant Split Post Analysis
              </Box>
            </Box>

            {/* <Box
              onClick={() => {
                setTabValue(5);
                setMapType('');
              }}
            >
              <Box
                className={classes.chartSelect}
                style={{
                  color: tabValue === 5 ? green : grey,
                  backgroundColor: tabValue === 5 ? 'white' : lightGrey,
                }}
              >
                Farm Report
              </Box>
            </Box> */}

          </Box>

          <Box color={grey} fontWeight={600}>
            <Divider />
            <Box mt={2}>
              Total Revenue
            </Box>
            <Box color={darkGrey}>
              {!nans.includes(numFormat(totalRevenue)) ? dollarFormat(Math.abs(totalRevenue)) : '$0.00'}
              {perAcre && '/Acre'}
            </Box>
            <Box mt={2}>
              Total Expenses
            </Box>
            <Box color={darkGrey}>
              {!nans.includes(numFormat(totalCosts)) ? dollarFormat(Math.abs(totalCosts)) : '$0.00'}
              {perAcre && '/Acre'}
            </Box>
            <Box mt={2}>
              Total Profit
              <CustomToolTip
                title='Your ProfitLayers Average Profit in the Operation Summaries may differ slightly from the Average Profit in the individual Operation Tables if the operations have different acreages. This does not affect the accuracy of your $/Acre ProfitLayers maps.'
                placement='right'
              >
                <InfoOutlinedIcon className={classes.infoToolTip} />
              </CustomToolTip>
            </Box>
            <Box color={darkGrey}>
              {!nans.includes(numFormat(totalProfit)) ? dollarFormat(totalProfit) : '$0.00'}
              {perAcre && '/Acre'}
            </Box>
          </Box>
        </Box>
      </Slide>

      {
        profitMapAccess ? (
          <>

            <Box
              style={{
                position: 'relative',
                overflow: 'hidden',
              }}
            >
              <Box
                style={{
                  position: tabValue === 0 ? 'static' : 'absolute',
                  right: '-5000px',
                  top: '5000px',
                }}
              >
                <Box
                  style={{
                    height: height - 200,
                    width: width < mobileBreakPoint ? width : width - 200,
                    overflowY: 'auto',
                  }}
                >
                  <ReportMap
                    field={field}
                    perAcre={perAcre}
                    seededAcres={netSeededAcres}
                    totalProfit={totalProfit}
                    displayOperations={disOperations}
                    operations={operations}
                    summary={summary}
                    year={year}
                    profitMapResponse={profitMapResponse}
                    profitMap={profitMap}
                    operationMap={operationMap}
                    getProfitMap={getProfitMap}
                    logBinRange={logBinRange}
                    getBinRange={getBinRange}
                    pushProfitMap={pushProfitMap}
                    getElevationMap={getElevationMap}
                    elevationMap={elevationMap}
                    mapType={mapType}
                    savedTemplate={savedTemplate}
                    getAllTemplates={getAllTemplates}
                    setPdfMapImages={setPdfMapImages}
                    setPdfMapExtent={setPdfMapExtent}
                    zoneAnalysis={zoneAnalysis}
                    zoneAnalysisRes={zoneAnalysisRes}
                    zoneOptions={zoneOptions}
                    setZoneAnalysisRes={setZoneAnalysisRes}
                    source={source}
                    elevationIndex={elevationIndex}
                    getElevationIndex={getElevationIndex}
                    highRes={highRes}
                    NPK={NPK}
                    getNutrientParser={getNutrientParser}
                    syncData={syncData}
                    errorMessage={errorMessage}
                    isZoneAnalysisRes={isZoneAnalysisRes}
                    setZoneAnalysisLoader={setZoneAnalysisLoader}
                    mobileBreakPoint={mobileBreakPoint}
                    profitMapNeedsUpdating={profitMapNeedsUpdating}
                    setProfitMapNeedsUpdating={setProfitMapNeedsUpdating}
                    selectedTemplate={selectedTemplate}
                    setSelectedTemplate={setSelectedTemplate}
                    setMapLoadedOnViewProfitLayers={setMapLoadedOnViewProfitLayers}
                    templateRes={templateRes}
                    setTemplateRes={setTemplateRes}
                    legendBinColors={legendBinColors}
                    setLegendBinColors={setLegendBinColors}
                    legendBinRanges={legendBinRanges}
                    setLegendBinRanges={setLegendBinRanges}
                    clipByBoundary={clipByBoundary}
                  />

                  {(profitMapLoading && mapType === 'Profit Map')
                    && (
                      <CircularProgress
                        size={width > 768 ? 140 : 60}
                        style={{
                          position: 'absolute',
                          top: '30%',
                          right: 0,
                          left: 0,
                          margin: 'auto',
                          zIndex: 1005,
                          color: green,
                        }}
                      />
                    )}
                  {(operationMapLoading && mapType === 'Operation Layers')
                    && (
                      <CircularProgress
                        size={width > 768 ? 140 : 60}
                        style={{
                          position: 'absolute',
                          top: '30%',
                          right: 0,
                          left: 0,
                          margin: 'auto',
                          zIndex: 1005,
                          color: green,
                        }}
                      />
                    )}
                  {(elevationMapLoading && mapType === 'Elevation Map')
                    && (
                      <CircularProgress
                        size={width > 768 ? 140 : 60}
                        style={{
                          position: 'absolute',
                          top: '30%',
                          right: 0,
                          left: 0,
                          margin: 'auto',
                          zIndex: 1005,
                          color: green,
                        }}
                      />
                    )}
                </Box>
              </Box>

            </Box>

            <Box
              style={{
                width: tabValue === 1 ? '100%' : '0px',
                height: tabValue === 1 ? '100%' : '0px',
                overflow: 'hidden',
              }}
            >
              <Box
                id="costchart"
                borderRadius="borderRadius"
                border={tabValue === 1 ? 0 : 1}
                p={1}
                style={{
                  height: height - 170,
                  width: width > mobileBreakPoint ? width - 200 : width,
                  minWidth: '500px',
                  minHeight: '600px',
                  overflow: 'auto',
                }}
              >
                <CostsChart
                  displayed={displayed}
                  ops={operations}
                  perAcre={perAcre}
                  netSeededAcres={netSeededAcres}
                  containerHeight={tabValue === 1 ? '100%' : '600px'}
                  containerWidth={tabValue === 1 ? '100%' : '500px'}
                />
              </Box>
            </Box>

            <Box
              style={{
                width: tabValue === 2 ? '100%' : '0px',
                height: tabValue === 2 ? '100%' : '0px',
                overflow: 'hidden',
              }}
            >
              <Box
                id="scatterplot"
                style={{
                  height: height - 160,
                  width: width > mobileBreakPoint ? width - 200 : width,
                  minWidth: '500px',
                  minHeight: '600px',
                  overflow: 'auto',
                }}
              >
                <Scatter
                  displayed={displayed}
                  field={field}
                  year={year}
                  operations={disOperations}
                  showing={tabValue === 2}
                  setLoaded={setScatterLoaded}
                  containerHeight={tabValue === 2 ? '100%' : '600px'}
                  containerWidth={tabValue === 2 ? '100%' : '500px'}
                  orderID={orderID}
                  subOrderID={subOrderID}
                  files={files}
                />
              </Box>
            </Box>

            <Box style={{ height: height - 190, overflow: 'auto' }}>
              <InsuranceTools
                displayTool={tabValue !== 3 ? '' : insuranceTool}
                field={field}
                mobileView={width <= mobileBreakPoint}
                cropYield={avgYield}
                source='PROFITLAYERS'
                totalCosts={totalCosts}
                harvestRevenue={harvestRevenue}
                profitMapResponse={profitMapResponse}
                totalProfit={totalProfit}
                otherRevenue={otherRevenue}
                netSeededAcres={netSeededAcres}
              />
            </Box>

            <Box
              style={{
                width: tabValue === 4 ? '100%' : '0px',
                height: tabValue === 4 ? '100%' : '0px',
                overflow: 'hidden',
              }}
            >
              <Box
                id="landlordTenantScreen"
                style={{
                  height: height - 160,
                  width: width > mobileBreakPoint ? width - 200 : width,
                  minWidth: '500px',
                  minHeight: '600px',
                  overflow: 'auto',
                }}
              >
                <LandlordTenant
                  operations={operations}
                  year={year}
                  totalRevenue={totalRevenue}
                  totalCosts={totalCosts}
                  totalProfit={totalProfit}
                  netSeededAcres={netSeededAcres}
                  combineOps={combineOps}
                  perAcre={perAcre}
                />
              </Box>
            </Box>

            {/* <Box
              style={{
                width: tabValue === 5 ? '100%' : '0px',
                height: tabValue === 5 ? '100%' : '0px',
                overflow: 'hidden',
              }}
            >
              <Box
                id="farmreportscreen"
                style={{
                  height: height - 160,
                  width: width > mobileBreakPoint ? width - 200 : width,
                  minWidth: '500px',
                  minHeight: '600px',
                  overflow: 'auto',
                }}
              >
                <FarmReport
                  dieselPrices={dieselPrices}
                  year={year}
                  field={field}
                  highRes={highRes}
                  netSeededAcres={netSeededAcres}
                />
              </Box>
            </Box> */}
          </>
        ) : (
            <Box>
              {
                !processing
                  ? (
                    <Box
                      style={{
                        paddingTop: 16,
                        display: 'flex',
                        //justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        margin: 'auto',
                        height: '100%',
                        width: '100%',
                      }}
                    >

                      <Box
                        px={1}
                        style={{
                          fontSize: '2em',
                          textAlign: 'center',
                        }}
                      >
                        Please sign up for a yearly subscription to get unlimited Profit Layers for Organization:
                      {' '}
                        {field.org}
                        {' '}
                      (
                      {field.orgId}
                      )
                    </Box>
                      <Box textAlign='center' fontSize={'2em'}>
                        {'($' + Endpoints.PROFITLAYERS_PRICE + ' per year)'}
                      </Box>
                      <Box
                        mb={1}
                        style={{
                          fontSize: '1em',
                          textAlign: 'center',
                        }}
                      >
                        Note: The subscription comes with a <span style={{ fontWeight: 500 }}>30-day money back guarantee</span> and renews automatically at ${Endpoints.PROFITLAYERS_PRICE} per year.
                    </Box>
                      {/* <Button
                  variant="contained"
                  color="primary"
                  href={Endpoints.BASEURL + Endpoints.PAYMENT}
                  target='_blank'
                >
                  Sign Up Now
                </Button> */}
                      <Box style={{ width: '25%' }}>
                        <FormControl
                          variant="outlined"
                          style={{ position: 'relative', display: 'flex' }}
                        >
                          <TextField value={referralcode} onChange={(event) => handleReferralCode(event.target.value)} label="Discount Code" />
                        </FormControl>
                      </Box>

                      <PaymentApp
                        authenticated={authenticated}
                        setProfitMapAccess={setProfitMapAccess}
                        setProcessing={setProcessing}
                        field={field}
                        handlePayment={handlePayment}
                        isDisabled={isDisabled}
                        orgs={orgs}
                        referralcode={referralcode}
                      />
                    </Box>
                  )
                  : (
                    <Box p={3}>
                      <Box
                        p={3}
                        display="flex"
                        flexDirection="column"
                        justifyContent="center"
                        alignItems="center"
                        boxShadow={2}
                        borderRadius="borderRadius"
                        fontWeight={500}
                        fontSize={20}
                      >
                        <Box mb={2} px={1}>
                          Thank your for your purchase. We're processing your order.
                      </Box>
                        <CircularProgress color="primary" />
                      </Box>
                    </Box>
                  )
              }

            </Box>
          )
      }

    </Box>
  );
}

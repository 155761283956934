import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Slide, Divider } from '@material-ui/core';
import { Weather } from './Weather'
import { WeatherAlerts } from './WeatherAlerts'
import { RadarMap } from './RadarMap'

export function RealTimeWeather({
  field,
  setSelectedLayer,
  mobileBreakPoint,
  weatherLoading,
  weatherAlertsLoading,
  weatherError,
  weatherAlertsError,
  hourly,
  weekly,
  severeWeatherAlerts,
  getSevereWeatherAlerts,
  setSevereWeatherAlerts
}) {

  const [mapHeight, setMapHeight] = useState(window.innerHeight - 200)

  useEffect(() => {
    getMapHeight()
  }, [window.innerHeight])

  const getMapHeight = () => {
    let height = window.innerHeight - 400;
    if (height < 400) {
      setMapHeight(400)
    } else {
      setMapHeight(height)
    }
  }

  return (
    <Slide direction='right' in={true}>
      <Box>

        <RadarMap
          boundary={field.boundary}
          centroid={[field?.latitude, field?.longitude]}
          containerHeight={mapHeight}
        />

        <Box id='weather-alerts'>
          <WeatherAlerts
            loading={weatherAlertsLoading}
            field={field}
            getSevereWeatherAlerts={getSevereWeatherAlerts}
            severeWeatherAlerts={severeWeatherAlerts}
            setSevereWeatherAlerts={setSevereWeatherAlerts}
            mobileBreakPoint={mobileBreakPoint}
            errorMessage={weatherAlertsError}
            location={field?.state}
          />
        </Box>

        <Box
          m={1}
          id='current-weather'
          borderRadius='borderRadius'
          border={1}
        >
          <Weather
            loading={weatherLoading}
            setSelectedLayer={setSelectedLayer}
            hourly={hourly}
            weekly={weekly}
            errorMessage={weatherError}
          />
        </Box>

      </Box>
    </Slide>
  )
}

import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Box, Button, Typography, Paper, Link, Modal, Divider, AppBar, TextField, FormLabel, RadioGroup, Radio, FormControlLabel,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card,
  CardActionArea, CardContent, Grid, Select, MenuItem, Chip, Input, ListItemText,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  grey,
  lightGrey,
  darkGrey,
  green,
  blackText,
  darkText,
} from "../../../styles/colors";
import { MenuProps } from '../../../styles/select';
import { selectedStyle } from '../../Maps/Styles/layerStyles';
import { CustomToolTip } from '../../../utils/customComponents'
import * as wkt from 'terraformer-wkt-parser';
import * as L from 'leaflet';
import * as df from '../../../utils/dataFetchers'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  chips: theme.chips,
  chip: theme.chip,
  infoToolTip: theme.infoToolTip,
}));

export function VarietyByWeather({ field, crops, selectedCrop, setSelectedCrop, cropYears, yieldInfo, varietyInfo, precipData, gddData, setGddData, setPrecipData }) {

  /**
   * Update Field Information
   * @param {Object} field selected field data
   */
  const classes = useStyles();

  const [weather, setWeather] = useState(['Precipitation', 'GDD'])
  const [selectedWeather, setSelectedWeather] = useState('Precipitation')
  const [years, setYears] = useState([])
  const [selectedYears, setSelectedYears] = useState([])

  const [selectedVar, setSelectedVar] = useState('')
  const months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Sepetember', 'October', 'November', 'December']
  const [selectedMonths, setSelectedMonths] = useState(['July', 'August'])
  const [weatherData, setWeatherData] = useState([])
  const [yieldWeather, setYieldWeather] = useState([])

  const [varieties, setVarieties] = useState([]);
  const [selectedVarieties, setSelectedVarities] = useState([]);
  const [varietyColorList, setVarietyColorList] = useState([])

  const [display, setDisplay] = useState('yield')
  const [yieldWeatherData, setYieldWeatherData] = useState(null)
  const [seasonWeatherData, setSeasonWeatherData] = useState(null)

  useEffect(() => {
    let menuList = cropYears.filter(op => op.crop == selectedCrop)
    let yearsList = [], varList = []
    for (let menu of menuList) {
      yearsList.push(menu.year)
      varList.push(menu.variety)
    }
    yearsList = [...new Set(yearsList)]
    varList = [...new Set(varList)]
    setYears(yearsList.sort((a, b) => a - b))
    setSelectedYears(yearsList)
    setVarieties(varList)
    setSelectedVarities(varList)
  }, [cropYears, selectedCrop])

  useEffect(() => {
    drawYieldWeatherGraph()
    drawWeatherSeasonGraph()
  }, [varietyInfo, precipData, gddData, selectedWeather, selectedCrop, selectedVarieties, selectedYears])

  useEffect(() => {
    let vals = []
    for(let i=0; i<months.length;i++){
      for(let j=0; j<selectedMonths.length;j++){
        if(months[i] === selectedMonths[j]){
          vals.push(i)
        }
      }
    }
    let startMonth = Math.min(...vals) + 1
    let endMonth = Math.max(...vals) + 1
    getPrecipData(startMonth, endMonth)
    getGdd(startMonth, endMonth)
    //console.log(startMonth, endMonth)
  }, [selectedMonths])

  const handleMonthChange = (event) => {
    setSelectedMonths(event.target.value)
  }

  const handleYearChange = (event) => {
    setSelectedYears(event.target.value)
  }

  const handleVarietyChange = (event) => {
    setSelectedVarities(event.target.value)
  }

  const getPrecipData = async (startMonth, endMonth) => {
    let allData = []

    if(selectedYears.length > 0){
      for(let year of selectedYears){
        let res = await df.getPrecipitation(field.longitude, field.latitude, year, startMonth, endMonth)
        res = JSON.parse(res)
        allData.push(res[0])
      }
      setPrecipData(allData)
    }
  }

  const getGdd = async (startMonth, endMonth) => {
    let allData = []

    if(selectedYears.length > 0){
      for(let year of selectedYears){
        let res = await df.getGDD(field.longitude, field.latitude, year, startMonth, endMonth)
        res = JSON.parse(res)
        allData.push(res[0])
      }
      setGddData(allData)
    }
  }

  const drawYieldWeatherGraph = () => {
    var finalData = [], weatherInfo = [], finalVariety = [], varietyColor = [];
    var nameColor = [], cropYearsList = [];
    var colors = ["#434348", "#90ed7d", "#f7a35c", "#8085e9", "#f15c80", "#e4d354", "#2b908f", "#f45b5b", "#91e8e1", "#67b346"];
    var prod = 0, sumProduct = 0, acresSum = 0, yAxis = [];

    for (var i = 0; i < selectedVarieties.length; i++) {
      var obj = {
        name: selectedVarieties[i],
        color: colors[i]
      }
      nameColor.push(obj)
    }
    setVarietyColorList(nameColor)

    var selectedWeatherInfo = [];
    let precipitation = [], gdd =[]
    try{
      precipitation = precipData.filter(pre => pre?.Year !== undefined && selectedYears.includes(pre?.Year))
      gdd = gddData.filter(g => g?.Year !== undefined && selectedYears.includes(g?.Year))
    }
    catch(err){
      console.log('error filtering precip/gdd data', err)
      precipitation = []
      gdd = []
    }
   
   // console.log('precipitation', precipitation, selectedWeather)

    if (selectedWeather == 'Precipitation') {
      for (var i = 0; i < precipitation.length; i++) {
        if (precipitation[i] != undefined) {
          var obj = {
            Year: precipitation[i].Year,
            cumPrecip: precipitation[i].cumPrecip * 0.0394
          }
          selectedWeatherInfo.push(obj);
        }
      }
    }
    else if (selectedWeather == 'GDD') {
      for (var i = 0; i < gdd.length; i++) {
        if (gdd[i] != undefined) {
          var obj = {
            Year: gdd[i].Year,
            cumPrecip: gdd[i].cumGDD
          }
          selectedWeatherInfo.push(obj);
        }
      }
    }


    //console.log('harvestVariety', harvestVariety)
    var filteredCropYield = varietyInfo.filter((obj) => obj.CropName === selectedCrop);
    //console.log('filteredCropYield', filteredCropYield)

    for (var i = 0; i < filteredCropYield.length; i++) {
      cropYearsList[i] = filteredCropYield[i].CropSeason;
    }

    cropYearsList = [...new Set(cropYearsList)]
    //console.log(cropYearsList)

    for (var i = 0; i < nameColor.length; i++) {
      for (var j = 0; j < filteredCropYield.length; j++) {
        if (nameColor[i].name == filteredCropYield[j].Variety) {
          var obj = {
            Year: filteredCropYield[j].CropSeason,
            yield: filteredCropYield[j].VarietyYield,
            color: nameColor[i].color
          }
          varietyColor.push(obj)
        }
      }
    }

    //console.log('varietyColor', varietyColor)
    //console.log('selectedWeatherInfo', selectedWeatherInfo)

    for (var i = 0; i < selectedWeatherInfo.length; i++) {
      for (var j = 0; j < varietyColor.length; j++) {
        if (selectedWeatherInfo[i].Year == varietyColor[j].Year) {
          var obj = {
            x: Math.round(selectedWeatherInfo[i].cumPrecip * 100) / 100,
            y: Math.round(varietyColor[j].yield * 100) / 100,
            color: varietyColor[j].color
          }
          finalVariety.push(obj)
        }
      }
    }

    finalVariety = finalVariety.slice(0);
    finalVariety.sort(function (a, b) {
      var x = a.x;
      var y = b.x;
      return x < y ? -1 : x > y ? 1 : 0;
    })

    //console.log('finalVariety', finalVariety)


    //console.log('filteredCropYield', filteredCropYield)
    let selectedYearsList = years.filter(year =>selectedYears.includes(year));
    //console.log('selectedYears', selectedYearsList)

    for (var i = 0; i < selectedYearsList.length; i++) {
      prod = 0; sumProduct = 0; acresSum = 0;
      for (var j = 0; j < filteredCropYield.length; j++) {
        if (selectedYearsList[i] == filteredCropYield[j].CropSeason) {
          prod = filteredCropYield[j].AverageYield * filteredCropYield[j].Area;
          sumProduct += prod;
          acresSum += filteredCropYield[j].Area
        }
      }
      var obj = {
        year: selectedYearsList[i],
        yield: Math.round(sumProduct / acresSum * 100) / 100
      }
      yAxis.push(obj)
    }

    yAxis = yAxis.slice(0);
    yAxis.sort(function (a, b) {
      var x = a.year;
      var y = b.year;
      return x < y ? -1 : x > y ? 1 : 0;
    })
    //console.log('yAxis', yAxis)

    for (var j = 0; j < selectedYearsList.length; j++) {
      for (var i = 0; i < selectedWeatherInfo.length; i++) {
        if (selectedYearsList[j] == selectedWeatherInfo[i].Year) {
          var obj = {
            x: selectedWeatherInfo[i].Year,
            y: Math.round(selectedWeatherInfo[i].cumPrecip * 100) / 100
          }
          weatherInfo.push(obj)
        }
      }
    }

    weatherInfo = weatherInfo.slice(0);
    weatherInfo.sort(function (a, b) {
      var x = a.x;
      var y = b.x;
      return x < y ? -1 : x > y ? 1 : 0;
    })
    //console.log('weatherInfo', weatherInfo)

    for (var i = 0; i < weatherInfo.length; i++) {
      var obj = {
        x: weatherInfo[i].y,
        y: yAxis[i].yield
      }
      finalData.push(obj)
    }
    //console.log('finalData', finalData)

    finalData = finalData.slice(0);
    finalData.sort(function (a, b) {
      var x = a.x;
      var y = b.x;
      return x < y ? -1 : x > y ? 1 : 0;
    })
    var unit = '';
    if (weather == 'Precipitation') unit = '(inch)'

    var options = {

      title: {
          text: '<b>Yield vs. ' + weather + '</b>'
      },
      xAxis: {
          title: {
              text: '<b>' + weather +' '+unit+'</b>'
          }
      },
      yAxis: {
          title: {
              text: '<b>Yield (bu./acre)</b>'
          }
      },
      //legend: {
      //    enabled: true
      //},
      series: [{
          name: 'Yield (bu./acre)',
          type: 'line',
          data: [{}],
          states: {
              inactive: {
                  opacity: 1
              }
          }
      },
          {
          name: 'Variety Yield (bu./acre)',
          type: 'scatter',
          data: [{}],
          states: {
              inactive: {
                  opacity: 1
              }
          }
      }
      ],

      navigation: {
          menuItemStyle: {
              borderLeft: '10px solid #FFFFFF',
              fontSize: '15px',
              padding: "2px 10px"
          }
      },
      tooltip: {
          crosshairs: true,
          //formatter: function () {
          //    return 'Amount of <b>' + type + '</b> applied per acre is <b>' + Math.round(this.y * 100) / 100 + 'lbs</b> on <b>' + (new Date(this.x)).toLocaleDateString() + '</b>.'
          //}
      },
      plotOptions: {
          series: {
              marker: {
                  radius: 6
              }
          }
      },
      credits: {
          text: 'Ag-Analytics',
          href: 'https://analytics.ag'
      },
  }

    //console.log('finalData', finalData)
    setYieldWeather(options)

    options.series[0].data = finalData
    options.series[1].data = finalVariety
    setYieldWeatherData(options)
    //console.log('options', options)
  }

  function drawWeatherSeasonGraph() {
    var finalGddData = [];
    var finalYield = [];
    var cropYears1 = [];
    var weather2 = selectedWeather;

    var selectedWeather2 = [], precipitation = [], gdd = [];

    try{
      precipitation = precipData.filter(pre => selectedYears.includes(pre?.Year))
      gdd = gddData.filter(g => selectedYears.includes(g?.Year))
    }
    catch(err){
      console.log('error filtering precip/gdd data', err)
      precipitation = []
      gdd = []
    }

    if (weather2 == 'Precipitation') {
        for (var i = 0; i < precipitation.length; i++) {
            if (precipitation[i] != undefined) {
                var obj1 = {
                    Year: precipitation[i].Year,
                    cumPrecip: precipitation[i].cumPrecip * 0.0394
                }
                selectedWeather2.push(obj1);
            }
        }
    }
    else if (weather2 == 'GDD') {
        for (var i = 0; i < gdd.length; i++) {
            if (gdd[i] != undefined) {
                var obj1 = {
                    Year: gdd[i].Year,
                    cumPrecip: gdd[i].cumGDD
                }
                selectedWeather2.push(obj1);
            }
        }
    }

    for (var i = 0; i < selectedWeather2.length; i++) {
        var obj1 = {
            x: selectedWeather2[i].Year,
            y: selectedWeather2[i].cumPrecip
        }
        finalGddData.push(obj1)
    }

    finalGddData = finalGddData.slice(0);
    finalGddData.sort(function (a, b) {
        var x = a.x;
        var y = b.x;
        return x < y ? -1 : x > y ? 1 : 0;
    })

    var cropName1 = selectedCrop

    var filteredCropYield1 = varietyInfo.filter((obj) => obj.CropName === cropName1);
    //console.log('filteredCropYield1', filteredCropYield1)

    for (var i = 0; i < filteredCropYield1.length; i++) {
        cropYears1[i] = filteredCropYield1[i].CropSeason;
    }
    cropYears1 = [...new Set(cropYears1)]
    //console.log('cropYears1', cropYears1)

    var prod1 = 0, sumProduct1 = 0, acresSum1 = 0;
    let gddYears = years.filter(year => selectedYears.includes(year))

    for (var i = 0; i < gddYears.length; i++) {
        prod1 = 0; sumProduct1 = 0; acresSum1 = 0;
        for (var j = 0; j < filteredCropYield1.length; j++) {
            if (gddYears[i] == filteredCropYield1[j].CropSeason) {
                prod1 = filteredCropYield1[j].AverageYield * filteredCropYield1[j].Area;
                sumProduct1 += prod1;
                acresSum1 += filteredCropYield1[j].Area
            }
        }
        var obj = {
            x: gddYears[i],
            y: Math.round(sumProduct1 / acresSum1 * 100) / 100
        }
        finalYield.push(obj)
    }
    //console.log('finalYield', finalYield)

    finalYield = finalYield.slice(0);
    finalYield.sort(function (a, b) {
        var x = a.x;
        var y = b.x;
        return x < y ? -1 : x > y ? 1 : 0;
    })

    var unit2 = '';
    if (weather2 == 'Precipitation') unit2 = '(inch)'

    var options = {
        title: {
            text: '<b>Yield vs. Season</b>'
        },
        xAxis: {
            title: {
                text: '<b>Crop Season</b>'
            }
        },
        yAxis: [{
            title: {
                text: '<b>' + weather2 + ' ' + unit2 + '</b>'
            },
        }, {
            title: {
                text: '<b>Yield (bu./acre)</b>'
            },
            opposite: true
        }],
        legend: {
            enabled: true
        },
        series: [{
            name: weather2,
            type: 'line',
            data: [{}],
            zIndex: 4,
            states: {
                inactive: {
                    opacity: 1
                }
            }
        },
        {
            name: 'Yield (bu./acre)',
            type: 'column',
            yAxis: 1,
            data: [{}],
            states: {
                inactive: {
                    opacity: 1
                }
            }
        }
        ],

        navigation: {
            menuItemStyle: {
                borderLeft: '10px solid #FFFFFF',
                fontSize: '15px',
                padding: "2px 10px"
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            //formatter: function () {
            //    return 'Amount of <b>' + type + '</b> applied per acre is <b>' + Math.round(this.y * 100) / 100 + 'lbs</b> on <b>' + (new Date(this.x)).toLocaleDateString() + '</b>.'
            //}
        },
    }

    options.series[0].data = finalGddData
    options.series[1].data = finalYield
    setSeasonWeatherData(options)
    //console.log('finalGddData', finalGddData)
    //console.log('finalYield', finalYield)
}

  return (
    <Box my={1} style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center">
        <Button
          color='primary'
          variant={display === 'yield' ? 'contained' : 'outlined'}
          onClick={() => setDisplay('yield')}
          style={{margin: '0 8px'}}
          disableElevation
        >
          Yield X Weather
        </Button>
        <Button
          color='primary'
          variant={display === 'season' ? 'contained' : 'outlined'}
          onClick={() => setDisplay('season')}
          style={{margin: '0 8px'}}
          disableElevation
        >
          Weather X Season
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box
          display="flex"
          flexWrap="wrap"
          fontSize={18}
        >
          <Box m={1}>
            <Box>
              Crop
            </Box>
            <Select
              variant="outlined"
              MenuProps={MenuProps}
              style={{ width: 200, height: 40 }}
              value={selectedCrop}
              onChange={(e) => setSelectedCrop(e.target.value)}
            >
              {
                crops.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x}
                  >
                    {x}
                  </MenuItem>
                ))
              }
            </Select>
          </Box>

          { display === 'yield' && (
            <Box m={1}>
              <Box>
                Variety
              </Box>
              <Select
                id="variety-mutiple"
                multiple
                variant='outlined'
                value={selectedVarieties}
                onChange={handleVarietyChange}
                input={<Input id="select-multiple-varieties" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value, i) => (
                      <Chip key={i} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {varieties.map((variety, i) => (
                  <MenuItem key={i} value={variety}>
                    <Checkbox
                      color="primary"
                      checked={selectedVarieties.includes(variety)}
                    />
                    <ListItemText primary={variety} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          )}

          <Box
            display="flex"
            flexWrap="wrap"
            fontSize={18}
          >
            <Box m={1}>
              <Box display='flex' alginItems='center'>
                {'Weather'}
                <CustomToolTip
                  placement='top-right'
                  title='Weather Variables: Growing Degree Days (GDD) are the accumulated monthly degrees daily that accrue in excess of 50 F. Precipitation is cumulative monthly precipitation in millimeters (mm). Weather data are procured from 4km resolution PRISM data.'
                >
                  <InfoOutlinedIcon className={classes.infoToolTip}/>
                </CustomToolTip>

              </Box>
              <Select
                variant="outlined"
                MenuProps={MenuProps}
                style={{ width: 200, height: 40 }}
                value={selectedWeather}
                onChange={(e) => setSelectedWeather(e.target.value)}
              >
                {
                  weather.map((x, i) => (
                    <MenuItem
                      key={i}
                      value={x}
                    >
                      {x}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>

            <Box m={1}>
              <Box>
                Crop Year
              </Box>
              <Select
                id="years-mutiple"
                multiple
                value={selectedYears}
                onChange={handleYearChange}
                input={<Input id="select-multiple-years" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value, i) => (
                      <Chip key={i} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {years.map((year, i) => (
                  <MenuItem key={i} value={year}>
                    <Checkbox
                      color="primary"
                      checked={selectedYears.includes(year)}
                    />
                    <ListItemText primary={year} />
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box m={1}>
              <Box>
                Season Month
              </Box>
              <Select
                id="months-mutiple"
                multiple
                value={selectedMonths}
                onChange={handleMonthChange}
                input={<Input id="select-multiple-months" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value, i) => (
                      <Chip key={i} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {months.map((month, i) => (
                  <MenuItem key={i} value={month}>
                    <Checkbox
                      color="primary"
                      checked={selectedMonths.includes(month)}
                    />
                    <ListItemText primary={month} />
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

        </Box>

        <Box id='yieldWeather'>
          {
            (display === 'yield' && yieldWeatherData !== null) && (
              <Box>
                <HighchartsReact
                  highcharts={Highcharts}
                  options={yieldWeatherData}
                />
                <Box style={{display: 'inline-flex', width: 'auto'}}>
                  {varietyColorList.map((x, i) => (
                    <>
                      <p style={{marginLeft: '10px', height: "10px", width: '10px', transform: "rotate(45deg)", backgroundColor: x.color, display: 'inline-block'}}></p>
                      <p style={{marginLeft: '5px'}}>{x.name}</p>
                    </>
                  ))}
                </Box>
              </Box>
            )
          }

          {
            (display === 'season' && seasonWeatherData !== null) && (
              <HighchartsReact
                highcharts={Highcharts}
                options={seasonWeatherData}
              />
            )
          }
        </Box>
      </Box>

    </Box>
  )
}

import React from "react";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../constants";
import InfoPopover from "../InfoPopover";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
    color: COLORS.darkGray,
    display: "flex",
    alignItems: "center",
    marginTop: ".4rem",
    marginBottom: ".2rem",
  },
  wrapper: {
    display: "flex",
    flexDirection: "column",
    marginRight: "10px"
  },
  pillContainer: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    flexWrap: "wrap",
    maxWidth: (30 + 3) * 6,
    "&>*": {
      flex: "0 1 30px",
      marginRight: 3,
      marginBottom: 3,
    },
  },
  pill: {
    textAlign: "center",
    width: 30,
    height: 18,
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: "18px",
    backgroundColor: COLORS.lightGray,
    color: COLORS.white,
    cursor: "pointer",
    "&.active": {
      backgroundColor: COLORS.darkGray,
    },
  },
}));

const SeasonSelect = ({ seasons, setSeasons, message="Select Your Season" }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <div className={classes.label}>
        {message}
        <InfoPopover text={message} />
      </div>
      <div className={classes.pillContainer}>
        {seasons.map((season) => {
          return (
            <div
              key={`season-pill-for-${season.id}`}
              onClick={() =>
                setSeasons(
                  seasons.map((d) => {
                    if (d.id === season.id) {
                      return {
                        ...d,
                        active: !d.active,
                      };
                    }
                    return d;
                  })
                )
              }
              className={`${classes.pill} ${season.active ? "active" : ""}`}
            >
              {season.id}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default SeasonSelect;

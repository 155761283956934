import React from "react";
import ReactGA from "react-ga";
import PropTypes from "prop-types";

import { makeStyles } from "@material-ui/core/styles";
import { Box, Modal, Divider, Button } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { blackText } from "../../styles/colors";

import { Endpoints } from "../../constants/Endpoints";
import { Integrate } from "../Partners/Integrate";

const useStyles = makeStyles((theme) => ({
  root: {
    position: "absolute",
    backgroundColor: theme.palette.greys.light,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    position: "absolute",
    top: 8,
    right: 8,
    "&:hover": {
      cursor: "pointer",
    },
  },
}));

const getModalStyle = () => {
  const top = 5;
  return {
    top: `${top}%`,
    margin: "auto",
    zIndex: 1002,
    width: "80vw",
    height: "80vh",
    padding: 1,
  };
};

/**
 * Display sign in, sign up, integration, and subscription links when relevant.
 * Optionally takes endpoint key to use for redirect on sign in.
 * 
 * Rendered in:
 * components\AcreageReporting\AcreageReporting.js
 * components\FieldSelection\FieldSelection.js
 * components\Pricing\esri\Esri.js
 * components\ProfitLayers\ProfitLayers.js
 * 
 * @param {Bool} open Open modal
 * @param {Function} setOpen Open or close
 * @param {String} message Message to display to user
 * @param {Boolean} shouldForceLogIn whether user should be forced to log in (cannot close modal)
 * @param {String} signInFrom Optional endpoint key to redirect to after sign in
 * @param {String} signInParam Optional param to append to signInFrom url
 * @returns {JSX} Connection
 */
export function Connect({
  open,
  setOpen,
  message,
  shouldForceLogIn,
  signInFrom,
  signInParam,
}) {
  const classes = useStyles();
  const modalStyle = getModalStyle();

  const handleLogin = () => {
    ReactGA.event({
      category: "User",
      action: "Sign In",
    })

    try {
      let url = Endpoints.BASEURL;
      if (!signInFrom) {
        // Usual redirect
        url += Endpoints.SIGNIN;
      } else {
        // Custom redirect
        url += Endpoints[signInFrom];
        if (signInParam) {
          url += signInParam;
        }
      }
      window.location.assign(url);
    } catch (err) {
      console.error(err);
    }
  };

  const handleSignUp = () => {
    ReactGA.event({
      category: "User",
      action: "Sign Up"
    })

    if (signInFrom === 'SIGNINFROMACREAGE') {
      window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUPFROMACREAGE);
    } else if (signInFrom === 'SIGNINESRI') {
      window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUPESRI);
    } else if (signInFrom === 'SIGNINFROMAPP') {
      let url = Endpoints.BASEURL + Endpoints.SIGNUPFROMAPP;
      if (signInParam) {
        url += signInParam;
      }
      window.location.assign(url);
    } else {
      window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUP);
    }
  };

  return (
    <Modal
      open={open}
      onClose={() => shouldForceLogIn ? {} : setOpen(false)}
      aria-labelledby="log-in-sign-up"
      aria-describedby="log-in-sign-up-prompt"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        style={modalStyle}
        className={classes.root}
        borderRadius="borderRadius"
        boxShadow={2}
      >
        {/* Modal title section*/}
        {!shouldForceLogIn && (
          <HighlightOffIcon
            className={classes.close}
            onClick={() => setOpen(false)}
          />
        )}
        <Box
          p={2}
          display="flex"
          justifyContent="center"
          alignItems="center"
          style={{ width: "100%", backgroundColor: "#ffffff" }}
        >
          <Box fontWeight={500} fontSize={20} color={blackText}>
            {message}
          </Box>
        </Box>
        <Divider />

        {/* Modal sign in/up buttons and Integrate component */}
        <Box style={{ overflow: "auto", height: "100%" }}>
          <Box>
            <Box
              display="flex"
              justifyContent="center"
              flexGrow={1}
              style={{ width: "100%" }}
              flexWrap="wrap"
            >
              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleLogin()}
                style={{
                  margin: "8px 16px",
                  fontSize: 20,
                  width: "220px",
                  backgroundColor: "#ffffff",
                }}
              >
                SIGN IN
              </Button>

              <Button
                color="primary"
                variant="outlined"
                onClick={() => handleSignUp()}
                style={{
                  margin: "8px 16px",
                  fontSize: 20,
                  backgroundColor: "#ffffff",
                }}
              >
                CREATE AN ACCOUNT
              </Button>
            </Box>

            <Integrate />
          </Box>
        </Box>

        {/* {!shouldForceLogIn && (
          <>
            <Divider />
            <Box
              p={2}
              display='flex'
              alignItems='center'
              style={{width: '100%', height: 60, backgroundColor: '#ffffff'}}
            >
              <Button
                color='primary'
                variant='outlined'
                onClick={() => setOpen(false)}
              >
                Exit
              </Button>
            </Box>
          </>
        )} */}
      </Box>
    </Modal>
  );
}

Connect.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
  signInFrom: PropTypes.string,
};

Connect.defaultProps = {
  signInFrom: undefined,
};

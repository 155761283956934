import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Divider, Slide, Fade,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { DrainageMap } from './DrainageMap';
import { numFormat } from '../../../utils/helpers';
import { blackText, grey, lightGrey } from '../../../styles/colors';
import { useWindowDimensions } from '../../../utils/dimensions';

import { SpinningLoader } from '../../Shared/SpinningLoader';
import { GetWaterManagement } from '../../Shared/ServiceLinks';

import { ImageSwitch } from '../Shared/ImageSwitch';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  messageBox: theme.messageBox,
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * Display CropLand map and data
 * @param {Object} field  Field to display data for
 * @param {Object} loading Loading status
 * @param {Function} getCDL Get crop land data
 * @param {Array} years Years for drop down
 * @param {String} selectedYear year used for initial call and dropdown set
 * @param {Function} setSelectedYear change selected year from dropdown
 * @param {String} image  Image for map
 * @param {Array} legend Legend data for table
 * @param {Array} extent Extent of image for map
 * @param {Number} mobileBreakPoint Pixel width side navigation changes to dropdown
 * @param {String} errorMessage Message to display to user if do not have data
 * @returns {JSX} Drainage component
 */
export function Drainage({
  field,
  loading,
  image,
  legend,
  extent,
  mobileBreakPoint,
  errorMessage,
}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  const [showImagery, setShowImagery] = useState(true);

  const handleSwitch = (checked) => {
    setShowImagery(checked);
  };

  const createTable = () => (
    <Box
      m={1}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      style={{ minWidth: '400px', maxWidth: '1000px', overflowX: 'auto' }}
    >
      <Box p={1} display="flex" alignItems="center" fontSize={16}>
        <Box style={{ width: '30%' }}>
          Acres
        </Box>
        <Box style={{ width: '30%' }}>
          Tile Drained
        </Box>
        <Box style={{ width: '20%' }}>
          % of Total
        </Box>

      </Box>

      <Divider style={{ color: grey, height: 4 }} />
      {
        legend.map((x, i) => createRow(x, i))
      }
    </Box>
  );

  const createRow = (x, i) => (
    <Box
      key={i}
      p={1}
      display="flex"
      alignItems="center"
      style={{ backgroundColor: i % 2 == 0 ? '#ffffff' : lightGrey }}
    >
      <Box style={{ width: '30%' }}>
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon
            style={{ color: x.color, marginRight: 8 }}
          />
          {numFormat(x.Acres)}
        </Box>
      </Box>

      <Box style={{ width: '30%', marginLeft: '25px' }}>
        {x.TileDrained}
      </Box>

      <Box style={{ width: '20%' }}>
        {x.Area}
      </Box>

    </Box>
  );

  return (
    <Slide direction="right" in>
      <Box color={blackText} fontWeight={500}>
        <Box mt={1} mx={1}>
          <Box fontSize={18}>
            Tile Drainage
          </Box>

          <Box fontSize="1rem">
            Tile Drainage is an important determinant of productivity for many agricultural working lands.
          </Box>
          <Divider />
        </Box>

        { errorMessage === '' && (
          <Box display="flex" alignItems="center" flexWrap="wrap">
            <ImageSwitch
              checked={showImagery}
              handleSwitch={handleSwitch}
            />
          </Box>
        )}

        { errorMessage === '' ? (
          <Box mt={.5} display="flex" flexWrap="wrap">
            <Box
              mx={1}
              display="flex"
              flexGrow={1}
              minWidth={width > 840 ? 600 : width > mobileBreakPoint ? width - 240 : width - 30}
              borderRadius="borderRadius"
            >
              <DrainageMap
                boundary={field.boundary}
                mapImage={image}
                mapExtents={extent}
                showImagery={showImagery}
                height="100%"
                width="100%"
                location={field?.state}
              />
            </Box>

            <Box display="flex" minWidth={380} m="0 auto">
              <Box>
                { legend !== undefined && (
                  <Fade
                    in={legend?.length > 0 && !loading}
                    mountOnEnter
                    unmountOnExit
                  >
                    {createTable()}
                  </Fade>
                )}

                <Box p={1}>
                  <GetWaterManagement />
                </Box>

              </Box>
            </Box>
          </Box>
        ) : (
          <Box m={1} display="flex">
            <Box className={classes.errorMessageBox}>
              {errorMessage}
            </Box>
          </Box>
        )}

        {loading && <SpinningLoader />}
      </Box>
    </Slide>
  );
}

import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Fade } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';

// Map imports
import * as L from 'leaflet';
import * as wkt from 'terraformer-wkt-parser';

// Functions
import { drawBasicMap } from '../MapFunctions/drawMap';
import {
  createCollection,
} from '../MapFunctions/fieldInteractions';
import {
  getOperationBoundary,
} from '../../../utils/dataFetchers';

import { EditBoundary } from '../Toolbars/EditBoundary';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { opacityIcon } from '../Styles/icons';

/**
 * Displays editable operation level map with all CLU intersections
 * @param {Array} cluBoundaries Boundaries of intersecting CLUs
 * @param {Object} clusSeen reference for CLU information
 * @param {Object} expanded Stores which CLUs are expanded
 * @param {String} gartPath Denotes whether this interface instance is for creating GART files
 * @param {String} height Map height in pixels
 * @param {String} impersonating email address for user being impersonated by agent
 * @param {Object} indexes Indexes to be passed to updateIntersections
 * @param {Object} operation Operation data
 * @param {Function} setSelectedClu Selects clu for highlighting
 * @param {Function} updateIntersections Updates intersections on edit
 * @returns {JSX} Editable map
 */
export function UpdateOperationsMap({
  cluBoundaries,
  clusSeen,
  expanded,
  gartPath,
  height,
  impersonating,
  indexes,
  operation,
  setSelectedClu,
  updateIntersections,
}) {
  let mapId = Math.floor(Math.random() * Math.floor(10000)).toString();
  const { enqueueSnackbar } = useSnackbar();

  const [map, setMap] = useState(null);
  const [activeTool, setActiveTool] = useState('');
  const [editedBoundary, setEditedBoundary] = useState(null);
  const [showCompleteClu, setShowCompleteClu] = useState(true);
  const [showToolbar, setShowToolbar] = useState(true);

  // Determine if need to draw operation
  const [boundaryNeedsUpdate, setBoundaryNeedsUpdate] = useState(true);
  const [loading, setLoading] = useState(false);

  const cluLayer = useRef(L.featureGroup(null));
  const operationLayer = useRef(L.featureGroup(null));
  const operationMapLayer = useRef(L.imageOverlay(null));
  const opacitySlider = useRef(null);

  // Hide toolbar to not allow editing operation boundary when there's no CLU intersections
  useEffect(() => {
    if (cluBoundaries?.length) {
      let allNonCLUBoundaries = true;
      for (const clu of cluBoundaries) {
        if (clu.clu_identifier !== 'X') {
          allNonCLUBoundaries = false;
          break;
        }
      }

      if (allNonCLUBoundaries) {
        setShowToolbar(false);
      }
      else {
        setShowToolbar(true);
      }
    }
  }, [cluBoundaries]);

  // Draw map
  useEffect(() => {
    if (map === null) {
      try {
        drawBasicMap(setMap, cluLayer.current, mapId, null, setActiveTool);
      } catch (error) {
        // Incase generate duplicate ID
        mapId = Math.floor(Math.random() * Math.floor(10000)).toString();
      }
    } else {
      // Handle resetting toolbar if cut is invalid
      map.on('pm:globalcutmodetoggled', (e) => {
        if (!e.enabled) setActiveTool(''); 
      })
    }
  }, [mapId, map]);

  // If appropriate, automatically toggle when user expands/unexpands a CLU
  useEffect(() => {
    if (expanded.length) {
      setShowCompleteClu(false);
    }
    else {
      setShowCompleteClu(true);
    }
  }, [expanded])

  // Draw all CLU boundaries depending on toggle
  useEffect(() => {
    if (cluBoundaries?.length && setSelectedClu && map) {
      drawCluBoundaries(showCompleteClu);
    }
  }, [cluBoundaries, setSelectedClu, showCompleteClu, map]);

  // After user edits boundary, process it to decide how to handle
  useEffect(() => {
    if (editedBoundary) {
      map.removeLayer(editedBoundary);
      updateBoundary(editedBoundary.toGeoJSON());
    }
  }, [editedBoundary]);

  // Actually update the operation boundary when appropriate
  useEffect(() => {
    if (operation && map && boundaryNeedsUpdate) {
      // remove previous boundary
      clearOperations();
      drawOperationBoundary(operation);
      setBoundaryNeedsUpdate(false);
    }
  }, [operation, map]);

  // Draw operation raster if available
  useEffect(() => {
    if (operation.operationMap !== undefined && map !== undefined && map !== null) {
      if (operation.operationMap !== undefined && operation.operationMap !== 'Error' && operation.operationMap !== 'NA') {
        drawOperationMap(operation.operationMap);
      }
    }
  }, [operation.operationMap, map]);


  const drawOperationBoundary = async () => {
    try {
      let geoJson;

      // If this is from saved data or created from interface
      if (operation?.operationBoundary) {
        geoJson = operation.operationBoundary;
        if (typeof(geoJson) === "string") {
          geoJson = JSON.parse(geoJson);
        }
      }
      // If it's an operation being imported from SeedingOperations table, then get boundary from there 
      else {
        const operationBoundary = await getOperationBoundary(
          operation.orgID,
          operation.fieldID,
          operation.operationID,
          'Seeding',
          impersonating,
        );

        if (!operationBoundary[0]?.boundary) {
          console.error('no boundary found');
          return;
        }

        geoJson = L.GeoJSON.geometryToLayer(
          wkt.parse(operationBoundary[0].boundary),
        ).toGeoJSON();
      }

      drawBoundary(
        geoJson,
        '#1e88e5',
        5,
        operationLayer.current,
        null,
        setEditedBoundary,
        null,
        true,
      );

      // Bring to front so tooltips are visible
      cluLayer.current.bringToFront();
    } catch (err) {
      enqueueSnackbar('Unable to get boundary for this operation at this time');
      console.error(err);
    }
  };

  const clearOperations = () => {
    operationLayer.current.eachLayer((layer) => {
      map.removeLayer(layer);
    });
  };

  const clearClus = () => {
    cluLayer.current.eachLayer((layer) => {
      map.removeLayer(layer);
    });
  };

  const toggleCluIntersection = (event) => {
    setShowCompleteClu(event.target.checked);
    drawCluBoundaries(event.target.checked);
  };

  const drawCluBoundaries = (drawCompleteBoundaries) => {
    try {
      clearClus();

      if (drawCompleteBoundaries) {
        cluBoundaries.forEach((clu) => {
          // Skip "fake CLUs"
          if (clu.clu_identifier === 'X') return;

          drawBoundary(
            clusSeen[clu.clu_identifier].cluShape,
            '#8e24aa',
            2,
            cluLayer.current,
            { function: setSelectedClu, value: clu },
            null,
            createToolTip(
              clu,
              clu.finalReportedAcreage,
            ),
          );
        });
      } else {
        cluBoundaries.forEach((clu) => {
          const color = expanded.includes(clu.zoneID) ? '#ffeb3b' : '#8e24aa';
          const weight = expanded.includes(clu.zoneID) ? 3 : 2;
          drawBoundary(
            clu.boundary,
            color,
            weight,
            cluLayer.current,
            { function: setSelectedClu, value: clu },
            null,
            createToolTip(clu, clu.finalReportedAcreage),
            null,
            expanded.includes(clu.zoneID) ? 0.35 : 0,
          );
        });
      }
      // Bring CLU layer to the front so tooltips are visible
      cluLayer.current.bringToFront();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to get boundary at this time');
    }
  };

  const createToolTip = (clu, acres) => (
    `
      <div style="height: 30; width: 30;">
        <div style="margin: 2px 6px;">Farm #: ${clusSeen[clu.clu_identifier].farm_number}</div>
        <div style="margin: 2px 6px;">Tract #: ${clusSeen[clu.clu_identifier].tract_number}</div>
        <div style="margin: 2px 6px;">CLU #: ${clu.displayNumber}</div>
        <div style="margin: 2px 6px;">Planted Acres: ${acres.toFixed(2)}</div>
      </div>
    `
  );

  const drawBoundary = (
    boundary,
    color,
    weight,
    layer,
    handleClick,
    boundaryEdit,
    toolTip,
    fitBounds,
    fillOpacity,
  ) => {
    // handleClick in this case is an object containing the function and value
    // to pass. Implemented this way to avoid passing layer up and for future
    // flexibility.

    const collection = createCollection(
      boundary,
      color,
      weight,
      layer,
      false,
      handleClick,
      boundaryEdit,
      finishCut,
      toolTip,
      fillOpacity,
    );
    if (collection) {
      collection.addTo(map);

      if (fitBounds) {
        map.fitBounds(collection.getBounds(), {
          padding: [30, 30],
        });
      }
    }
  };

  // Ignores any changes made and resets boundary to state before changes
  const resetBoundary = () => {
    // Reset toolbar state
    finishEdit();
    setBoundaryNeedsUpdate(false);
    clearOperations();
    drawOperationBoundary(operation);
  }

  // Called after edit or cut actions on drawn boundary(ies)
  const updateBoundary = async (boundary) => {
    try {
      // There's already a loading indicator in AcreageReporting.js
      // setLoading(true);

      // Check boundary is valid - user might have deleted all points in boundary
      if (!boundary.geometry || !boundary.geometry.coordinates[0][0]) {
        enqueueSnackbar('Please input a valid boundary.');
        resetBoundary();
      } else {
        const updatedOp = { ...operation };

        // Set operation boundary to a json string for display and use elsewhere
        updatedOp.operationBoundary = JSON.stringify(boundary.geometry);
        setBoundaryNeedsUpdate(true);

        // orderedMappings will be updated in updateIntersections
        const success = await updateIntersections(indexes, updatedOp, true);

        // Only update to new boundary if needed
        if (success) {
          clearClus();
        } else {
          resetBoundary();
        }
      }
    } catch (err) {
      enqueueSnackbar('Unable to update boundary at this time');
      console.error(err);
      resetBoundary();
    } finally {
      // setLoading(false);
    }
  };

  const edit = () => {
    try {
      setActiveTool('edit');
      operationLayer.current.bringToFront();
      operationLayer.current.pm.enable();
    } catch (err) {
      console.error(err);
    }
  };

  const finishEdit = () => {
    try {
      setActiveTool('');
      operationLayer.current.pm.disable();
    } catch (err) {
      console.error(err);
    }
  };

  const cut = () => {
    try {
      setActiveTool('cut');
      operationLayer.current.bringToFront();
      // enable cutting mode
      map.pm.enableGlobalCutMode({
        allowSelfIntersection: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const finishCut = () => {
    setActiveTool('');
  };

  const drawOperationMap = (operationMapObj) => {
    const extentString = operationMapObj.extent;
    const image = `data:image/png;base64,${operationMapObj.pngb64}`;

    const brkn = extentString.split(', ').map((x) => parseFloat(x));
    const corner1 = L.latLng(brkn[1], brkn[0]);
    const corner2 = L.latLng(brkn[3], brkn[2]);
    const bounds = L.latLngBounds(corner1, corner2);

    operationMapLayer.current.setUrl(image);
    operationMapLayer.current.setBounds(bounds);

    if (!map.hasLayer(operationMapLayer.current)) {
      operationMapLayer.current.addTo(map);
      // add opacity control
      opacitySlider.current = L.control.slider(
        (value) => { updateOperationOpacity(parseInt(value)); },
        {
          id: opacitySlider.current,
          orientation: 'horizontal',
          position: 'topleft',
          title: 'Opacity',
          min: 0,
          max: 100,
          step: 1,
          value: 100,
          collapsed: true,
          increment: true,
          size: '200px',
          logo: opacityIcon,
        },
      );
      opacitySlider.current.addTo(map);
    }
  };

  const updateOperationOpacity = (value) => {
    operationMapLayer.current.setOpacity(value / 100);
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={height}
      width="100%"
      borderRadius={4}
    >
      {/* Toolbar */}
      {!gartPath ?
        // Only show toolbar if appropriate
        showToolbar &&
        <EditBoundary
          activeTool={activeTool}
          edit={edit}
          cut={cut}
          finishEdit={finishEdit}
          toggle={toggleCluIntersection}
          toggleValue={showCompleteClu}
          toggleText="Show full CLU boundaries"
        />
      :
        <EditBoundary
          activeTool={activeTool}
          edit={edit}
          cut={cut}
          finishEdit={finishEdit}
        />
      }

      {/* Map */}
      <Box
        id={`${mapId}`}
        display="flex"
        height="100%"
        width="100%"
      />

      {/* Legend */}
      <Box p={1} display="flex" fontSize="1rem" fontWeight={500}>
        <Box px={1} display="flex" alignItems="center">
          <FiberManualRecordIcon style={{ color: '#1e88e5' }} />
          Planted Acreage
        </Box>
        {!gartPath &&
          <>
            <Box px={1} display="flex" alignItems="center">
              <FiberManualRecordIcon style={{ color: '#8e24aa' }} />
              CLU
            </Box>
            <Fade in={expanded?.length > 0} unmountOnExit mountOnEnter>
              <Box px={1} display="flex" alignItems="center">
                <FiberManualRecordIcon style={{ color: '#ffeb3b' }} />
                Expanded
              </Box>
            </Fade>
          </>
        }
      </Box>

      { loading && <SpinningLoader /> }
    </Box>
  );
}
  
UpdateOperationsMap.propTypes = {
  cluBoundaries: PropTypes.array.isRequired,
  clusSeen: PropTypes.object.isRequired,
  operation: PropTypes.object.isRequired,
  updateIntersections: PropTypes.func.isRequired,
  impersonating: PropTypes.string.isRequired,
  indexes: PropTypes.object.isRequired,
  height: PropTypes.string.isRequired,
  setSelectedClu: PropTypes.func.isRequired,
  expanded: PropTypes.array.isRequired,
};

import React, { useState, useEffect } from "react";
import ReactExport from "react-data-export";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export function ExcelExportDetailed({
  selectedFields,
  seedMap,
  harMap,
  appMap,
  fpMap,
  otherCostMap,
  otherRevMap,
  activeYear,
  selectedOrg,
  activeFarm,
}) {
  console.log("ExcelExportDetailed");
  const [data, setData] = useState([
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
    {
      value: "",
    },
  ]);

  useEffect(() => {
    let formatData = [];

    for (let f of selectedFields) {
      let harvestData = harMap.get(f.id);
      //console.log("harvestData", harvestData)
      for (let h of harvestData) {
        let rowData = [];
        if (!h.hidden) {
          for (let va of h.varieties) {
            rowData.push(
              {
                value: selectedOrg.Name,
              },
              {
                value: activeFarm.name,
              },
              {
                value: f.name,
              },
              {
                value: +activeYear,
              },
              {
                value: va.productName,
              },
              {
                value: "Harvest",
              },
              {
                value: va.date,
              },
              {
                value: va.variety,
              },
              {
                value: +(+va.varietyArea).toFixed(5),
              },
              {
                value: +(+va.varietyYieldRate).toFixed(5),
              },
              {
                value: va.totalUnit + "/Acre",
              },
              {
                value: +(+va.price).toFixed(5),
              },
              {
                value: +(+va.price * +va.varietyYieldRate).toFixed(2),
              },
              {
                value: +(
                  +va.price *
                  +va.varietyYieldRate *
                  +va.varietyArea
                ).toFixed(2),
              }
            );
            formatData.push(rowData);
          }
        }
      }

      let seedingData = seedMap.get(f.id);
      //console.log("seedingData", seedingData)
      for (let h of seedingData) {
        let rowData = [];
        if (!h.hidden) {
          for (let va of h.varieties) {
            rowData.push(
              {
                value: selectedOrg.Name,
              },
              {
                value: activeFarm.name,
              },
              {
                value: f.name,
              },
              {
                value: +activeYear,
              },
              {
                value: va.productName,
              },
              {
                value: "Seeding",
              },
              {
                value: va.date,
              },
              {
                value: va.variety,
              },
              {
                value: +(+va.varietyArea).toFixed(5),
              },
              {
                value: +(+va.varietyRate).toFixed(5),
              },
              {
                value: va.totalUnit + "/Acre",
              },
              {
                value: +(+va.price).toFixed(5),
              },
              {
                value: +(+va.price * +va.varietyRate).toFixed(5),
              },
              {
                value: +(+va.price * +va.varietyRate * +va.varietyArea).toFixed(
                  5
                ),
              }
            );
            formatData.push(rowData);
          }
        }
      }

      let chemicals = appMap.get(f.id);
      //console.log("chemicals", chemicals)
      for (let op of chemicals) {
        let rowData = [];
        if (!op.isDeleted) {
          rowData.push(
            {
              value: selectedOrg.Name,
            },
            {
              value: activeFarm.name,
            },
            {
              value: f.name,
            },
            {
              value: +activeYear,
            },
            {
              value: f.primaryCrop,
            },
            {
              value: "Chemicals",
            },
            {
              value: op.date,
            },
            {
              value: op.productName,
            },
            {
              value: +(+op.summaryArea).toFixed(5),
            },
            {
              value: +(+op.summaryApplicationRate).toFixed(5),
            },
            {
              value: op.summaryTotalAppliedUnit + "/Acre",
            },
            {
              value: +(+op.operationCost / +op.summaryApplicationRate).toFixed(
                5
              ),
            },
            {
              value: +(+op.operationCost).toFixed(5),
            },
            {
              value: +(+op.operationCost * +op.summaryArea).toFixed(5),
            }
          );
          formatData.push(rowData);
        }
      }

      let fp = fpMap.get(f.id);
      for (let op of fp) {
        let rowData = [];
        if (!op.isDeleted) {
          rowData.push(
            {
              value: selectedOrg.Name,
            },
            {
              value: activeFarm.name,
            },
            {
              value: f.name,
            },
            {
              value: +activeYear,
            },
            {
              value: f.primaryCrop,
            },
            {
              value: "Field Pass",
            },
            {
              value: op.date,
            },
            {
              value: op.name,
            },
            {
              value: +(+op.area).toFixed(5),
            },
            {
              value: 1,
            },
            {
              value: "FieldPass/Acre",
            },
            {
              value: +(+op.price).toFixed(5),
            },
            {
              value: +(+op.price).toFixed(5),
            },
            {
              value: +(+op.price * +op.area).toFixed(5),
            }
          );
          formatData.push(rowData);
        }
      }

      let otherCosts = otherCostMap.get(f.id);
      //console.log("otherCosts", otherCosts)
      for (let op of otherCosts) {
        let rowData = [];
        if (!op.isDeleted) {
          rowData.push(
            {
              value: selectedOrg.Name,
            },
            {
              value: activeFarm.name,
            },
            {
              value: f.name,
            },
            {
              value: +activeYear,
            },
            {
              value: f.primaryCrop,
            },
            {
              value: "OtherCosts",
            },
            {
              value: op.date,
            },
            {
              value: op.name,
            },
            {
              value: +(+op.total).toFixed(5),
            },
            {
              value: 1,
            },
            {
              value: "OtherCosts/Acre",
            },
            {
              value: +(+op.price).toFixed(5),
            },
            {
              value: +(+op.price).toFixed(5),
            },
            {
              value: +(+op.price * +op.total).toFixed(5),
            }
          );
          formatData.push(rowData);
        }
      }

      let otherRev = otherRevMap.get(f.id);
      //console.log("otherRev", otherRev)
      for (let op of otherRev) {
        let rowData = [];
        if (!op.isDeleted) {
          rowData.push(
            {
              value: selectedOrg.Name,
            },
            {
              value: activeFarm.name,
            },
            {
              value: f.name,
            },
            {
              value: +activeYear,
            },
            {
              value: f.primaryCrop,
            },
            {
              value: "Other Revenue",
            },
            {
              value: op.date,
            },
            {
              value: op.name,
            },
            {
              value: +(+op.total).toFixed(5),
            },
            {
              value: 1,
            },
            {
              value: "OtherRevenue/Acre",
            },
            {
              value: +(+op.price).toFixed(5),
            },
            {
              value: +(+op.price).toFixed(5),
            },
            {
              value: +(+op.price * +op.total).toFixed(5),
            }
          );

          formatData.push(rowData);
        }
      }

      formatData.push([
        {
          value: selectedOrg.Name,
        },
        {
          value: activeFarm.name,
        },
        {
          value: f.name,
        },
        {
          value: +activeYear,
        },
        {
          value: f.primaryCrop,
        },
        {
          value: "Profit",
        },
        {
          value: "",
        },
        {
          value: "Profit",
        },
        {
          value: +f.acres,
        },
        {
          value: 1,
        },
        {
          value: "Profit/Acre",
        },
        {
          value: +(+f.profit).toFixed(5),
        },
        {
          value: +(+f.profit).toFixed(5),
        },
        {
          value: +(+f.profit * +f.acres).toFixed(5),
        },
      ]);
    }
    //console.log("formatData", formatData);
    setData(formatData);
  }, [selectedFields]);

  const multiDataSet = [
    {
      columns: [
        { title: "Organization", width: { wpx: 80 } }, //pixels width
        { title: "Farm Name", width: { wpx: 80 } }, //char wi
        { title: "Field Name", width: { wpx: 80 } }, //pixels width
        { title: "Year", width: { wpx: 80 } }, //char width
        { title: "Crop", width: { wpx: 80 } },
        { title: "OperationType", width: { wpx: 80 } },
        { title: "Date", width: { wpx: 80 } },
        { title: "Description/Variety/Name", width: { wpx: 80 } },
        { title: "Acres", width: { wpx: 80 } },
        { title: "Quantity Per Acre", width: { wpx: 80 } },
        { title: "Quantity Unit", width: { wpx: 80 } },
        { title: "Price Per Unit ($/unit)", width: { wpx: 80 } },
        { title: "Revenue/Cost/Profit Per Acre ($/Acre)", width: { wpx: 80 } },
        { title: "Revenue/Cost/Profit Total ($)", width: { wpx: 80 } },
      ],
      data: data,
    },
  ];

  //console.log("multiDataSet", multiDataSet)

  return (
    <div>
      <ExcelFile element={<a>Print Detailed Report (Excel)</a>}>
        <ExcelSheet dataSet={multiDataSet} name="Farm Detailed Summary " />
      </ExcelFile>
    </div>
  );
}

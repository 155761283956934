/* eslint-disable no-restricted-syntax */
/* eslint-disable require-jsdoc */
/* eslint-disable no-console */
// Leaflet and Turf
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import * as LE from 'esri-leaflet';
import * as wkt from 'terraformer-wkt-parser';
import { getFeature } from './helpers';

import { Endpoints } from '../../../constants/Endpoints';
import { Tiles } from '../../../constants/Tiles';

export async function drawMap(
  stateLayer,
  countyLayer,
  plssLayer,
  sectionLayer,
  cluLayer,
  fieldLayer,
  inBounds,
  setMap,
  controls,
  setZoomLevel,
  setNewField,
  showClus,
  digitzing,
  drawFieldsInBounds,
  mapId,
  showEsriNaip,
  valueRadius,
  hideSearchbar,
  underwriting
) {
  /**
   * Draw map with controls, searchbar, and all necessary layers (State, County, ClU, etc...)
  */

  const mapboxTiles = L.tileLayer(
    Tiles.HYBRID,
  );
  const topoTiles = L.tileLayer(
    Tiles.MAPBOXTOPO,
  );

  // Add map to div by Id, set any parameters and initial view, add mapbox layer
  const map = L.map(mapId, {
    dragging: !L.Browser.mobile,
    minZoom: 4,
    editable: true,
    editOptions: {
      lineGuideOptions: {
        opacity: 0,
      },
    },
  })
    .setView([41.016, -92.4083], 5)
    .addLayer(mapboxTiles);

  let markers; let
    polygons;

  // draw controls
  map.pm.addControls({
    drawMarker: false,
    drawCircle: false,
    drawCircleMarker: false,
    drawRectangle: true,
    dragMode: true,
    drawPolygon: true,
    cutPolygon: true,
    editPolygon: true,
    drawPolyline: true,
    deleteLayer: true,
  });

  // turn off snap for digitzing
  if (digitzing) {
    map.pm.setGlobalOptions({ snappable: false });
  }

  if (!hideSearchbar) {
    const provider = new OpenStreetMapProvider();
    const searchControl = new GeoSearchControl({
      provider, // required
      style: window.innerWidth > 880 ? 'bar' : 'button',
      showMarker: true, // optional: true|false  - default true
      showPopup: false, // optional: true|false  - default false
      marker: {
        // optional: L.Marker    - default L.Icon.Default
        icon: new L.Icon.Default(),
        draggable: false,
      },
      popupFormat: ({ query, result }) => result?.label, // optional: function - default returns result label
      maxMarkers: 1, // optional: number - default 1
      retainZoomLevel: false, // optional: true|false - default false
      animateZoom: true, // optional: true|false - default true
      autoClose: true, // optional: true|false - default false
      searchLabel: 'Enter address or coordinates', // optional: string - default 'Enter address'
      keepResult: true, // optional: true|false - default false
    });
    map.addControl(searchControl);
  }

  if (controls !== null) {
    controls.current = L.control.layers({}, null).addTo(map);
  }

  function getGeom(wkt = false) {
    const bounds = map.getBounds();
    const max = bounds.getNorthEast();
    const min = bounds.getSouthWest();

    const geomParam = {
      xmin: min.lng,
      ymin: min.lat,
      xmax: max.lng,
      ymax: max.lat,
      spatialReference: { wkid: 4326 },
    };
    if (wkt) {
      const bboxCoords = `POLYGON ((${geomParam.xmin} ${geomParam.ymin}, ${geomParam.xmax} ${geomParam.ymin}, ${
        geomParam.xmax} ${geomParam.ymax}, ${geomParam.xmin} ${geomParam.ymax},${geomParam.xmin} ${geomParam.ymin}))`;
      return bboxCoords;
    }
    return geomParam;
  }

  // async function drawStateLayer() {
  //   try {
  //     const geom = getGeom(true);
  //     const type = 'envelope';
  //     const level = 'State';
  //     const qString = `?Geometry=${geom
  //     }&Geometry_type=${
  //       type
  //     }&Level=${
  //       level}`;
  //     fetch(`${Endpoints.BASEURL}/api/Boundary/GetBoundary${qString}`, {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           return response.json();
  //         }
  //       })
  //       .then((info) => {
  //         stateLayer.current.clearLayers();
  //         const results = JSON.parse(info);
  //         if (info !== undefined && results !== undefined) {
  //           for (const feature of results.results.features) {
  //             if (feature.geometry.type === 'GeometryCollection') {
  //               const badNdxs = [];
  //               for (let i = 0; i < feature.geometry.geometries.length; i++) {
  //                 if (feature.geometry.geometries[i].type !== 'Polygon') {
  //                   badNdxs.push(i);
  //                 }
  //               }
  //               // loop backwards over gemoetries and remove
  //               for (let j = badNdxs.length - 1; j >= 0; j--) {
  //                 delete feature.geometry.geometries[badNdxs[j]];
  //               }
  //             }
  //           }
  //           stateLayer.current.addData(results.results);
  //           const currentZoom = map.getZoom();
  //           if (currentZoom <= 6) {
  //             map.addLayer(stateLayer.current);
  //           }
  //         }
  //       }).catch((error) => console.log(error));
  //   } catch (err) {
  //     console.log('Problem drawing state layer');
  //   }
  // }

  // function drawCountyLayer() {
  //   try {
  //     const geom = getGeom(true);
  //     const type = 'envelope';
  //     const level = 'County';
  //     const qString = `?Geometry=${geom
  //     }&Geometry_type=${
  //       type
  //     }&Level=${
  //       level}`;
  //     fetch(`${Endpoints.BASEURL}/api/Boundary/GetBoundary${qString}`, {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           return response.json();
  //         }
  //       })
  //       .then((info) => {
  //         countyLayer.current.clearLayers();
  //         if (info !== undefined) {
  //           let result = JSON.parse(info)
  //           //console.log(result)
  //           for(let item of result){
  //             let temp = {...item}
  //             let shape = item.shape
  //             let geo = wkt.parse(shape)
  //             let feature = {type:"Feature", geometry: {}, properties: {}}
  //             feature.geometry = geo
  //             delete temp.shape
  //             feature.properties = temp
  //             //console.log(feature)
  //             countyLayer.current.addData(feature);
  //           }
  //           let currentZoom = map.getZoom()
  //           if (currentZoom > 7) {
  //             countyLayer.current.eachLayer((layer) => {
  //               layer.bindTooltip(`${layer.feature.properties.NAME}, ${layer.feature.properties.ST_ABBR}`, 
  //                 {permanent: true, direction: "center", className:"underwritingTooltip"}).openTooltip()
  //             })
  //           }

  //           if (currentZoom > 6 && currentZoom <= 10.4) {
  //             map.addLayer(countyLayer.current);
  //           }
  //         }
  //       }).catch((error) => console.log(error));
        
  //   } catch (err) {
  //     console.log(`Failed to draw county layer, error: ${err}`);
  //   }
  // }

  // function drawPlssLayer() {
  //   try {
  //     const geom = getGeom(true);
  //     const type = 'envelope';
  //     const level = 'PLSS';
  //     const qString = `?Geometry=${geom
  //     }&Geometry_type=${
  //       type
  //     }&Level=${
  //       level}`;
  //     fetch(`${Endpoints.BASEURL}/api/Boundary/GetBoundary${qString}`, {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           return response.json();
  //         }
  //       })
  //       .then((info) => {
  //         if (info !== undefined) {
  //           plssLayer.current.clearLayers();
  //           const result = JSON.parse(info);
  //           for (const item of result) {
  //             const temp = { ...item };
  //             const { shape } = item;
  //             const geo = wkt.parse(shape);
  //             const feature = { type: 'Feature', geometry: {}, properties: {} };
  //             feature.geometry = geo;
  //             delete temp.shape;
  //             feature.properties = temp;
  //             plssLayer.current.addData(feature);
  //           }
  //           let zoom = map.getZoom()
            
  //           plssLayer.current.eachLayer((layer) => {
  //             layer.bindTooltip(`${layer.feature.properties.TOWNSHIP} ${layer.feature.properties.RANGE}`, {
  //               permanent:true,
  //               direction:'center',
  //               className: 'underwritingTooltip'
  //             }).openTooltip()
  //           })
            
            
  //           if (zoom >= 10.5 && zoom <= 12) {
  //             map.addLayer(plssLayer.current);
  //           }
  //         }
  //       }).catch((error) => console.log(error));
  //   } catch (err) {
  //     console.log('Problem draing plss layer');
  //   }
  // }

  // function drawSectionLayer() {
  //   try {
  //     const geom = getGeom(true);
  //     const type = 'envelope';
  //     const level = '1SQMI_GRID';
  //     const qString = `?Geometry=${geom
  //     }&Geometry_type=${
  //       type
  //     }&Level=${
  //       level}`;
  //     fetch(`${Endpoints.BASEURL}/api/Boundary/GetBoundary${qString}`, {
  //       method: 'GET',
  //       headers: {
  //         Accept: 'application/json',
  //       },
  //     })
  //       .then((response) => {
  //         if (response.ok) {
  //           return response.json();
  //         }
  //       })
  //       .then((info) => {
  //         if (info !== undefined) {
  //           sectionLayer.current.clearLayers();
  //           const result = JSON.parse(info);
  //           for (const item of result) {
  //             const temp = { ...item };
  //             const { shape } = item;
  //             const geo = wkt.parse(shape);
  //             const feature = { type: 'Feature', geometry: {}, properties: {} };
  //             feature.geometry = geo;
  //             delete temp.shape;
  //             feature.properties = temp;
  //             sectionLayer.current.addData(feature);
  //           }
  //           const currentZoom = map.getZoom();
  //           if (currentZoom > 12 && currentZoom <= 13.7) {
  //             map.addLayer(sectionLayer.current);
  //           }
  //         }
  //       }).catch((error) => console.log(error));
  //   } catch (err) {
  //     console.log('Problem drawing section layer');
  //   }
  // }

  // The controller for this can be found at: AgAnalytics\AgAnalytics\APIControllers\BoundaryController.cs
  
  function drawCluLayer() {
    if (showClus.current) {
      try {
        const geom = getGeom(true);
        const type = 'envelope';
        const level = 'CLU';
        const qString = `?Geometry=${geom
        }&Geometry_type=${
          type
        }&Level=${
          level}`;
        fetch(`${Endpoints.BASEURL}/api/Boundary/GetBoundary${qString}`, {
          method: 'GET',
          headers: {
            Accept: 'application/json',
          },
        })
          .then((response) => {
            if (response.ok) {
              return response.json();
            }
          })
          .then((info) => {
            if (info !== undefined) {
              cluLayer.current.clearLayers();
              const result = JSON.parse(info);
              for (const item of result) {
                const temp = { ...item };
                const { shape } = item;
                const geo = wkt.parse(shape);
                const feature = { type: 'Feature', geometry: {}, properties: {} };
                feature.geometry = geo;
                delete temp.shape;
                feature.properties = temp;
                cluLayer.current.addData(feature);
              }
              if (showClus) {
                map.addLayer(cluLayer.current);
                cluLayer.current.bringToBack();
                fieldLayer.current.bringToFront();
                // if in loanUnderwriting app make sure to keep value radius as lowest layer
                if (valueRadius) {
                  if (map.hasLayer(valueRadius.current)) {
                    valueRadius.current.bringToBack();
                  }
                }
              }
            }
          }).catch((error) => console.log(error));
      } catch (err) {
        console.log(err);
      }
    }
  }

  // Determine map layers based on zoom level
  function mapZoomEvent() {
    if (map === '') {
      return null;
    }
    const currentZoom = map.getZoom();
    setZoomLevel(currentZoom);
    // console.log(currentZoom);
    if (currentZoom > 13.7){
      drawCluLayer();
    }
    else{
      map.removeLayer(cluLayer.current);
    }

    // if (currentZoom <= 6) {
    //   map.removeLayer(countyLayer.current);
    //   map.removeLayer(plssLayer.current);
    //   map.removeLayer(sectionLayer.current);
    //   map.removeLayer(cluLayer.current);
    //   // drawStateLayer()
    //   map.addLayer(stateLayer.current);
    // } else if (currentZoom > 6 && currentZoom <= 10.4) {
    //   map.removeLayer(stateLayer.current);
    //   map.removeLayer(plssLayer.current);
    //   map.removeLayer(sectionLayer.current);
    //   map.removeLayer(cluLayer.current);
    //   drawCountyLayer();
    // } else if (currentZoom >= 10.5 && currentZoom <= 12) {
    //   map.removeLayer(countyLayer.current);
    //   map.removeLayer(sectionLayer.current);
    //   map.removeLayer(stateLayer.current);
    //   map.removeLayer(cluLayer.current);
    //   drawPlssLayer();
    // } else if (currentZoom > 12 && currentZoom <= 13.7) {
    //   map.removeLayer(plssLayer.current);
    //   map.removeLayer(countyLayer.current);
    //   map.removeLayer(stateLayer.current);
    //   map.removeLayer(cluLayer.current);
    //   drawSectionLayer();
    // } else if (currentZoom > 13.7) {
    //   map.removeLayer(sectionLayer.current);
    //   map.removeLayer(plssLayer.current);
    //   map.removeLayer(countyLayer.current);
    //   map.removeLayer(stateLayer.current);
    //   drawCluLayer();
    // }
  }

  function mapMovedEvent() {
    const currentZoom = map.getZoom();

    if (currentZoom > 13.7 /* && currentZoom < 16.7 && enableClus */) {
      drawCluLayer();
      drawFieldsInBounds(map.getBounds());
    } 
  }

  function setClickZoomEvent(layer, layerName) {
    layer.on('click', (e) => {
      switch (layerName) {
        case 'sectionLayer':
          map.flyTo([e.latlng.lat, e.latlng.lng], 15, { duration: 0.7 });
          break;
        case 'plssLayer':
          map.flyTo([e.latlng.lat, e.latlng.lng], 13, { duration: 0.7 });
          break;
        case 'countyLayer':
          map.flyTo([e.latlng.lat, e.latlng.lng], 11, { duration: 0.7 });
          break;
        case 'stateLayer':
          map.flyTo([e.latlng.lat, e.latlng.lng], 9, { duration: 0.7 });
          break;
        default:
        // some events we dont move on
      }
    });
  }

  // setClickZoomEvent(stateLayer.current, 'stateLayer');
  // setClickZoomEvent(countyLayer.current, 'countyLayer');
  // setClickZoomEvent(plssLayer.current, 'plssLayer');
  // setClickZoomEvent(sectionLayer.current, 'sectionLayer');
  setClickZoomEvent(cluLayer.current, 'cluLayer');

  // draw and add layers
  // drawStateLayer();

  // map.addLayer(selectedFieldLayer.current);
  map.addLayer(fieldLayer.current);
  map.addLayer(inBounds.current);
  mapZoomEvent();
  map.on('zoomend', (e) => {
    mapZoomEvent();
  });
  map.on('moveend', (e) => {
    mapMovedEvent();
  });

  map.on('pm:create', (e) => {
    try {
      const polyLayer = e.layer;
      const feature = getFeature(polyLayer);
      setNewField({ feature, type: 'polygon', layer: e.layer });
    } catch (err) {
      map.removeLayer(e.layer);
      // console.log("check for circles");
      // console.log(e);
    }
  });

  setMap(map);
}

/**
 * Draws basic map. Typically used for interaction were layers such as state,
 * county, etc are not needed. Uses cases would be image display or interactions
 * with currently selected field.
 * 
 * Currently used in: 
 * Maps\AcreageReporting\ViewClu.js
 * Maps\AcreageReporting\ViewOperation.js
 * Maps\AcreageReporting\UpdateOperation.js
 * components\Digs\Results\Map\ResultsMap.js
 * components\LoanUnderwriting\PreviousBoundaries\PreviousBoundariesMap.js
 * 
 * @param {Function} setMap Sets map in parent component
 * @param {Object} fieldLayer Layer field will be drawn on
 * @param {String} mapId ID of div to draw map to
 * @param {Function} onCreate Fire on completed polygon draw
 * @returns {Void} No return
 */
export async function drawBasicMap(setMap, fieldLayer, mapId, onCreate) {
  const mapboxTiles = L.tileLayer(
    Tiles.ESRIBASEMAP,
  );

  // Add map to div by Id, set any parameters and initial view, add mapbox layer
  const map = L.map(mapId, {
    dragging: !L.Browser.mobile,
    editable: true,
    editOptions: {
      lineGuideOptions: {
        opacity: 0,
      },
    },
  })
    .setView([41.016, -92.4083], 5)
    .addLayer(mapboxTiles);

  L.easyPrint({
    sizeModes: ['A4Portrait', 'A4Landscape', 'Current'],
  }).addTo(map);

  if (onCreate) {
    map.on('pm:create', (e) => {
      try {
        const polyLayer = e.layer;
        const feature = getFeature(polyLayer);
        onCreate({ feature, type: 'polygon', layer: e.layer });
      } catch (err) {
        map.removeLayer(e.layer);
      }
    });
  }

  setMap(map);
  map.addLayer(fieldLayer);
}

// export const drawStateLayer = async (stateLayer, bounds) => {
//   try {
//     const geom = getGeom(bounds, true);
//     const type = 'envelope';
//     const level = 'State';
//     const qString = `?Geometry=${geom
//     }&Geometry_type=${
//       type
//     }&Level=${
//       level}`;
//     fetch(`${Endpoints.BASEURL}/api/Boundary/GetBoundary${qString}`, {
//       method: 'GET',
//       headers: {
//         Accept: 'application/json',
//       },
//     })
//       .then((response) => {
//         if (response.ok) {
//           return response.json();
//         }
//       })
//       .then((info) => {
//         stateLayer.current.clearLayers();
//         const results = JSON.parse(info);
//         if (info !== undefined && results !== undefined) {
//           for (const feature of results.results.features) {
//             if (feature.geometry.type === 'GeometryCollection') {
//               const badNdxs = [];
//               for (let i = 0; i < feature.geometry.geometries.length; i++) {
//                 if (feature.geometry.geometries[i].type !== 'Polygon') {
//                   badNdxs.push(i);
//                 }
//               }
//               // loop backwards over gemoetries and remove
//               for (let j = badNdxs.length - 1; j >= 0; j--) {
//                 delete feature.geometry.geometries[badNdxs[j]];
//               }
//             }
//           }
//           stateLayer.current.addData(results.results);
//           // const currentZoom = map.getZoom();
//           // if (currentZoom <= 6) {
//           //   map.addLayer(stateLayer.current);
//           // }
//         }
//       }).catch((error) => console.log(error));
//   } catch (err) {
//     console.log('Problem drawing state layer');
//   }
// };

// export const drawCountyLayer = (countyLayer, mapBounds) => {
//   try {
//     const geom = getGeom(mapBounds, true);
//     const type = 'envelope';
//     const level = 'County';
//     const qString = `?Geometry=${geom
//     }&Geometry_type=${
//       type
//     }&Level=${
//       level}`;
//     fetch(`${Endpoints.BASEURL}/api/Boundary/GetBoundary${qString}`, {
//       method: 'GET',
//       headers: {
//         Accept: 'application/json',
//       },
//     })
//       .then((response) => {
//         if (response.ok) {
//           return response.json();
//         }
//       })
//       .then((info) => {
//         countyLayer.current.clearLayers();
//         if (info !== undefined) {
//           const result = JSON.parse(info);
//           // console.log(result)
//           for (const item of result) {
//             const temp = { ...item };
//             const { shape } = item;
//             const geo = wkt.parse(shape);
//             const feature = { type: 'Feature', geometry: {}, properties: {} };
//             feature.geometry = geo;
//             delete temp.shape;
//             feature.properties = temp;
//             // console.log(feature)
//             countyLayer.current.addData(feature);
//           }

//           countyLayer.current.eachLayer((layer) => {
//             layer.bindTooltip(`${layer.feature.properties.NAME}, ${layer.feature.properties.ST_ABBR}`,
//               { permanent: true, direction: 'center', className: 'underwritingTooltip' }).openTooltip();
//           });

//           // const currentZoom = map.getZoom();
//           // if (currentZoom > 6 && currentZoom <= 10.4) {
//           //   map.addLayer(countyLayer.current);
//           // }
//         }
//       }).catch((error) => console.log(error));
//   } catch (err) {
//     console.log(`Failed to draw county layer, error: ${err}`);
//   }
// };

function getGeom(bounds, isWkt) {
  const max = bounds.getNorthEast();
  const min = bounds.getSouthWest();

  const geomParam = {
    xmin: min.lng,
    ymin: min.lat,
    xmax: max.lng,
    ymax: max.lat,
    spatialReference: { wkid: 4326 },
  };
  if (isWkt) {
    const bboxCoords = `POLYGON ((${geomParam.xmin} ${geomParam.ymin}, ${geomParam.xmax} ${geomParam.ymin}, ${
      geomParam.xmax} ${geomParam.ymax}, ${geomParam.xmin} ${geomParam.ymax},${geomParam.xmin} ${geomParam.ymin}))`;
    return bboxCoords;
  }
  return geomParam;
}

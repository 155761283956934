/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-restricted-syntax */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  InputAdornment,
  TextField,
  CircularProgress,
  Checkbox,
  FormControlLabel,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import * as L from 'leaflet';
import * as wkt from 'terraformer-wkt-parser';
import * as turf from '@turf/turf';
import { useSnackbar } from 'notistack';
import { FieldContext, FieldProvider } from '../Context/FieldContext';

import { UserContext } from '../Context/UserContext';
import { exists } from '../../utils/helpers';
import {
  getProfitMapAccess,
  getStateAndCounty,
  fetchFarmsForOrg,
  fetchBoundaries,
  fetchOrgsFields,
  fetchField,
  fetchFarms,
  fetchFields,
  fetchFieldData,
  fetchTopOrg,
  fetchClients,
} from '../../utils/dataFetchers';
import {
  grey, blackText, darkText, lightGrey, green,
} from '../../styles/colors';
import { IntegrationLinks } from '../Partners/Integrate';
import { CustomAutocomplete, CustomToolTip } from '../../utils/customComponents';

import { Endpoints } from '../../constants/Endpoints';
// import {cleanField} from './cleanField'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.greys.light,
    padding: '15px',
    paddingBottom: '0px',
    height: '100%',
  },
  selectionBox: {
    paddingTop: '5px',
    overflowY: 'auto',
  },
  selections: {
    fontWeight: 500,
    fontSize: 14,
    margin: '0 15px 5px 0',
    borderRadius: '6px',
    padding: '5px',
    '&:hover': {
      cursor: 'pointer',
      color: '#000000',
      backgroundColor: '#f8f8ff',
    },
  },
  dropdown: {
    fontSize: '14px',
    marginLeft: '5px',
  },
  select: {
    ...theme.hover,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: 16,
    color: blackText,
    overflowX: 'hidden',
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  highlight: {
    '&:hover': {
      backgroundColor: '#ffffff',
      borderRadius: '6px',
    },
  },
  mobile: {
    backgroundColor: theme.palette.greys.light,
    display: 'flex',
    width: '100%',
  },
  active: {
    backgroundColor: '#ffffff',
    boxShadow: 'inset 0px 0px 5px #c1c1c1',
    borderRadius: 5,
    padding: 5,
  },
  notActive: {
    padding: 5,
  },
  nothing: {},
  customAutoComplete: {
    '& .MuiInputLabel-outlined': {
      color: blackText,
    },
  },
  buttonLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  infoToolTip: theme.infoToolTip,
}));

/**
* FieldContext will provide a list of fields for selected farm with all
* needed data in. If user has selected a field it will be provided from
* selectedField key. Using dropdown menu, FieldContext will be updated from
* here. It is expected that Map component will usually be used in
* conjunction and also have access to FieldContext for both recieving
* updated field data and setting selectedField through click. Field data
* values selectedField and fields should only be updated to contain fields
* with complete data (ids, geo data, etc). Adding fields with incomplete
* data will cause issues for map components and possibly service ordering.
 * @param {Number} containerHeight Height of containing div, useful when side by side with map
 * @param {Function} select Allows parent to select field without map interaction
 * @param {Function} openConnect To be passed to IntegrationLinks for opening connect modal in parent
 * @param {Bool} mobile Change display for small screens
 * @param {Bool} needsStateAndCounty Does parent component need state and country for field
 * @returns {JSX} Field Selection
 */
export function SelectFieldV2({
  containerHeight,
  select,
  openConnect,
  mobile = false,
  needsStateAndCounty = false,
  page = 'Layers',
  underwriting,
  setReports,
  setDocuments,
  loanID,
  setIsLoading,
  setOrgHasAFarm,
  setParentOrg,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const user = useContext(UserContext)[0];
  const [fieldData, setFieldData] = useContext(FieldContext);

  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState({ id: '', name: '' });

  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState({ id: '', name: '' });

  // Clients in dropdown
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState({ id: '', name: '' });
  const [useClient, setUseClient] = useState(false);

  // hold field meta data
  // can be incomplete where as fieldData.fields should contain boundary info
  const [fields, setFields] = useState([]);

  // selecting Organization, Farm, or Field
  const [selecting, setSelecting] = useState('');

  // Controls whether to initiate initial load from local storage data
  const [loaded, setLoaded] = useState(false);

  // fields loading
  const [loading, setLoading] = useState(false);

  // auto complete keys to force refresh
  const [farmKey, setFarmKey] = useState(Math.random());
  const [fieldKey, setFieldKey] = useState(Math.random());

  // Old: Used in case where field without data is selected. We need to block load
  // of fields from updating fieldData while awaiting selected field data
  const selectedFieldId = useRef({ id: '' });

  const [hasSubscription, setHasSubscription] = useState(null);
  const urlsToShowSubscribe = ['profitlayers', 'datalayers', ''];
  const showSubscribe = useRef(false);

  useEffect(() => {
    const url = window.location.href;
    const splitUrl = url.toLowerCase().split('app/');
    if (splitUrl?.length > 1) {
      const show = urlsToShowSubscribe.includes(splitUrl[1].replace('/', ''));
      showSubscribe.current = show;
    } else {
      showSubscribe.current = true;
    }
  }, []);

  useEffect(() => {
    // get organizations from UserContext
    if (exists(user.organizations)) {
      setOrganizations(
        user.organizations.map((org) => ({
          id: org.ID, name: org.Name,
        })),
      );
    }
  }, [user]);

  useEffect(() => {
    //console.log("fieldData", fieldData)
    if (fieldData?.hasLoaded && user?.organizations?.length && !loaded) {
      // console.log("USER DATA IN SELECTFIELDV2", user)
      onLoad(fieldData?.selectedField, user?.organizations);
    }
    if(fieldData?.reload){
      setLoaded(false)
      setFieldData({...fieldData, reload: false})
    }
  }, [fieldData, user]);

  useEffect(() => {
    // This handleSelectionChange was used by the old method of field selection and does not seem to be used anymore
    if (fieldData.selectedField && fieldData.selectedField.orgId === '' && loaded) {
      handleSelectionChange('Organization');
    }

    if (fieldData.selectedField.claimedFlag) {
      // update farm and org list

      // currently only being used for loanUnderwriting field claim so only update farm list
      updateFarmList(fieldData.selectedField.orgId, fieldData.selectedField.farmId);
    }

    // if (
    //   exists(fieldData?.selectedField?.orgId) &&
    //   fieldData?.selectedField?.orgId !== selectedOrg.id
    // ) {
    //   setSelectedOrg({
    //     id: fieldData.selectedField.orgId, name: fieldData.selectedField.org
    //   })
    //   console.log('updating org input value', fieldData.selectedField.org)
    // }
    //
    // if (
    //   fieldData?.selectedField?.farmId !== '' &&
    //   fieldData?.selectedField?.farmId !== selectedFarm.id
    // ) {
    //   setSelectedFarm({
    //     id: fieldData.selectedField.farmId, name: fieldData.selectedField.farm
    //   })
    // }
  }, [fieldData.selectedField]);

  useEffect(() => {
    const searchBar = document.getElementById('search-bar');
    if (selecting !== '' && searchBar !== null) {
      try {
        searchBar.focus();
      } catch (e) {
        console.error(e);
      }
    }
  }, [selecting]);

  // useEffect(() => {
  //   if(underwriting && farms.length > 0){
  //     setDocuments(farms)
  //   }
  // }, [farms, underwriting])

  // useEffect(() => {
  //   if(underwriting && clients.length > 0){
  //     setReports(clients)
  //   }
  // },[clients, underwriting])

  useEffect(() => {
    if(setParentOrg && selectedOrg.id !== ''){
      setParentOrg(selectedOrg)
    }
  },[selectedOrg])

  useEffect(() => {
    if (fieldData.fieldsList !== undefined) {
      const fieldsList = fieldData.fieldsList.map((field) => ({ id: field.ID, name: field.Name }));
      fieldsList.sort((a, b) => a.name.localeCompare(b.name));
      setFields(fieldsList);
    }
  }, [fieldData.fieldsList]);

  /** Load in necessary data based on local storage.
  *  If selected field exists, load meta data for orgs, farms, and fields,
  *  but not complete data for fields.
  *  If farm has been selected, fetch fields with complete data
  * @param {Object} field selected field from field data
  * @param {Array} userOrgs User's organizations from user context
  * @returns {void}
  */
  const onLoad = async (field, userOrgs) => {
    try {
      if (field.id !== '' && field.farmId !== '' && field.orgId !== '') {
        // field loaded from local storage
        const orgIds = userOrgs.map((x) => x.ID);
        // only load field from storage if its included in a user's orgs
        // safety check for uncleared local storage on crash or refresh that
        // causes logout without storage clear
        // console.log(field)
        if (orgIds.includes(field.orgId)) {
          setLoaded(true);
          setLoading(true);

          setSelectedOrg({ id: field.orgId, name: field.org });
          setSelectedClient({ id: field.clientId, name: field.client });
          setSelectedFarm({ id: field.farmId, name: field.farm });

          const clientsList = await getClients(field.orgId);
          setClients(clientsList);

          // Get farms, set selected farm after populating array
          const farms = await getFarms(field.orgId);
          // console.log("farms", farms)
          setFarms(farms);
          setFieldData({
            ...fieldData,
            farms: farms,
          });

          if (fieldData.fields.length === 0) {
            // Field is loaded from local storage, update field context to it
            setFieldData({
              ...fieldData,
              fields: [fieldData.selectedField],
              farms: farms,
            });
          }

          const fields = await getFields(field.orgId, field.farmId, field.farmName);
          if (fields !== undefined) {
            const fieldsWithFarms = fields.map((x) => ({ ...x, farmId: field.farmId, farm: field.farm }));
            fieldsWithFarms.sort((a, b) => a.name.localeCompare(b.name));

            // set fields with only basic data (no geo) along with farm id and name
            setFields(fieldsWithFarms);
          }

          // Check if org has ProfitLayers subscription. If not we will show purchase link
          if (showSubscribe.current) {
            const hasProfitLayerAccess = await getProfitMapAccess(field.orgId);
            setHasSubscription(hasProfitLayerAccess);
          }
        } else {
          // Loaded field was not part of user's org, so clear it
          setFieldData({
            ...fieldData,
            fields: [],
            selectedField: {
              id: '', name: '', farmId: '', farm: '', orgId: '', org: '', clientId: '', client: '',
            },
          });
          handleSelectionChange('Organization');
        }
        setLoading(false);
      } else if (field.farmId !== '' && field.orgId !== '') {
        // farm loaded from local storage
        setLoaded(true);
        setLoading(true);
        //console.log('org and farm');
        const { farmId } = field;
        const { farm } = field;

        setSelectedOrg({ id: field.orgId, name: field.org });
        setSelectedClient({ id: '', name: '' });
        setSelectedFarm({ id: farmId, name: farm });

        const clientsList = await getClients(field.orgId);
        setClients(clientsList);

        const farms = await getFarms(field.orgId);
        // console.log("farms", farms)
        setFarms(farms);
        setFieldData({
          ...fieldData,
          farms: farms,
        });


        const farmsFields = await getFields(field.orgId, farmId);
        setFields(farmsFields);
        handleSelectionChange('Field');
        setLoading(false);

        // Now get fields with boundaries
        const fieldsData = await getFieldData(field.orgId, field.org, farmId, farm, farmsFields);

        // If after load field has
        if (fieldData.selectedField.id === '') {
          // set fields with complete data
          setFieldData({
            ...fieldData,
            fieldToClaim: {
              feature: null, geometry: {}, coordinates: [], points: [], boundary: {}, acres: 0,
            },
            fields: fieldsData,
            selectedField: {
              farmId,
              farm,
              orgId: field.orgId,
              org: field.org,
              id: '',
              name: '',
              clientId: field.clientId,
              client: field.client,
            },
          });
        }

        if (showSubscribe.current) {
          // Check if org has ProfitLayers subscription. If not we will show purchase link
          const hasProfitLayerAccess = await getProfitMapAccess(field.orgId);
          setHasSubscription(hasProfitLayerAccess);
        }
      } else if (field.orgId !== '') {
        setLoaded(true);
        setSelectedOrg({ id: field.orgId, name: field.org });
        handleOrgChange(field.orgId, field.org);
      } else if (userOrgs.length === 1) {
        // user only has one org, so select it by default
        setLoaded(true);

        setSelectedOrg({ id: userOrgs[0].ID, name: userOrgs[0].Name });
        handleOrgChange(userOrgs[0].ID, userOrgs[0].Name);
      } else {
        // No stored field data
        const topOrg = await getTopOrg();
        if (topOrg.length > 0) {
          handleOrgChange(topOrg[0].ID, topOrg[0].Name);
        } else {
          handleSelectionChange('Organization');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const getTopOrg = async () => {
    try {
      const topOrg = await fetchTopOrg(user.token);
      return topOrg;
    } catch (err) {
      console.error(err);
    }
  };

  const getFarms = async (orgId, clientId = null) => {
    try {
      const farms = await fetchFarms(orgId, false, clientId, user.token);

      if (farms !== undefined) {
        // For controlling map size in a special condition
        if (setOrgHasAFarm) setOrgHasAFarm(true);
        return farms.map((farm) => ({ id: farm.FarmID, name: farm.FarmName }));
      }

      return [];
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const updateFarmList = async (orgId, farmId) => {
    try {
      const farms = await getFarms(orgId);
      // set selected farm based on passed in farmID?
      const selectedFarm = farms.filter((x) => x.id === farmId)[0];
      setFarms(farms);
      //console.log("farms", farms)
      setFieldData({
        ...fieldData,
        farms: farms,
      });

      setSelectedFarm(selectedFarm);
    } catch (err) {
      console.error(err);
    }
  };

  const getClients = async (orgId) => {
    try {
      const clientslist = await fetchClients(orgId, user.token);
      if (clientslist !== undefined) {
        return clientslist.map((client) => ({ id: client.ClientID, name: client.ClientName }));
      }

      return [];
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getOrgFields = async (orgId) => {
    try {
      if (setIsLoading !== undefined) {
        setIsLoading(true);
      }
      const fields = await fetchBoundaries(orgId, user.token);
      if (setIsLoading !== undefined) {
        setIsLoading(false);
      }
      return fields !== undefined ? fields : [];
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  const getFields = async (orgId, farmId) => {
    // get all fields for farms without boundaries
    try {
      const farmsFields = await fetchFields(orgId, farmId, user.token);
      if (exists(farmsFields)) {
        // const fields = farmsFields.map((field) => ({ id: field.FieldID, name: field.FieldName }));
        const fields = farmsFields.map((field) => ({ id: field.ID, name: field.Name }));
        fields.sort((a, b) => a.name.localeCompare(b.name));
        return fields;
      }
      enqueueSnackbar('No fields found for this farm.');
      return [];
    } catch (err) {
      console.error(err);
      enqueueSnackbar('No fields found for this farm.');
      return [];
    }
  };

  const getFieldData = async (orgId, orgName, farmId, farmName, farmsFields = []) => {
    // get fields for farm with boundaries
    try {
      if (setIsLoading !== undefined) {
        setIsLoading(true);
      }
      const data = await fetchBoundaries(orgId, farmId, true, user.token);
      const cleanedFields = [];

      if (exists(data)) {
        // eslint-disable-next-line no-restricted-syntax
        for (const field of data) {
          const cleanedField = await cleanField(field, orgId, orgName, farmId, farmName, farmsFields);
          cleanedFields.push(cleanedField);
        }
        cleanedFields.sort((a, b) => a.name.localeCompare(b.name));
      }

      if (setIsLoading !== undefined) {
        setIsLoading(false);
      }

      return cleanedFields;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  /**
   * Takes field data from database and adds additional needed information.
   * @param {Object} data Field info returned from controller
   * @param {String} orgId Organization ID
   * @param {String} orgName Organization Name
   * @param {String} farmId Farm ID
   * @param {String} farmName Name of field's farm
   * @param {Array} farmsFields Fields associated with farm
   * @param {Bool} getLocation If state and county data is needed for field
   * @return{Object} Field with all data needed in other components
   */
  const cleanField = async (data, orgId, orgName, farmId, farmName, farmsFields, getLocation) => {
    if (data === undefined) {
      return {
        id: '',
        name: '',
        orgId: '',
        org: '',
        farmId: '',
        farm: '',
        state: '',
        county: '',
        acres: 0,
        latitude: 0,
        longitude: 0,
        coordinates: [{ type: '', coordinates: [] }],
        points: [],
        source: '',
        boundaryId: '',
      };
    }

    try {
      // extract and clean coordinates from boundary
      // const coordinates = data.Shape[0] === 'P'
      //   ? { type: 'Polygon', coordinates: getPoly(data.Shape) }
      //   : { type: 'MultiPolygon', coordinates: getMultiPoly(data.Shape) };

      // // get points, previously for centroid. if multi, get from first poly
      // // now using turf to get centroid so shouldnt be used anywhere, but leaving for now
      // const points = data.Shape[0] === 'P'
      //   ? getPoly(data.Shape)
      //   : getPolyFromMulti(data.Shape);

      const geojson = L.GeoJSON.geometryToLayer(wkt.parse(data.Shape)).toGeoJSON();

      const featureCollection = {
        type: 'FeatureCollection',
        features: [geojson],
      };

      const coordinates = geojson?.geometry;
      const points = geojson?.geometry?.coordinates;

      const centroid = turf.centroid(featureCollection);
      const centerPoints = centroid.geometry.coordinates;

      const [state, county] = await getStateAndCounty(centerPoints[1], centerPoints[0]);

      // if acres are null (all climate fields) calculate from boundary coordinates
      const acres = data.Area !== null ? data.Area : turf.convertArea(turf.area(featureCollection), 'meters', 'acres');

      let org = '';

      if (selectedOrg.name !== '') {
        org = selectedOrg.name;
      } else {
        const match = organizations.filter((x) => x.id === orgId);
        if (match.length > 0) {
          org = match[0].name;
        }
      }

      let name = '';

      const fieldsToMatch = farmsFields !== undefined ? farmsFields : fields;

      if (data.FieldName !== undefined) {
        name = data.FieldName;
      } else {
        const matchingField = fieldsToMatch.filter((x) => x.id === data.FieldID);
        if (matchingField.length > 0) {
          name = matchingField[0].name;
        }
      }

      const field = {
        acres,
        centroid,
        county,
        coordinates,
        farmId,
        geojson,
        name,
        orgId,
        org: orgName,
        points,
        state,
        id: data.FieldID,
        farm: farmName,
        latitude: centerPoints[1],
        longitude: centerPoints[0],
        boundary: JSON.stringify(geojson),
        source: data.Source,
        shape: data.Shape,
        boundaryId: data.BoundaryID,
      };
      return field;
    } catch (err) {
      console.error(`problem cleaning field ${err}`, data);
      return {
        id: '',
        name: '',
        orgId: '',
        org: '',
        farmId: '',
        farm: '',
        state: '',
        county: '',
        acres: 0,
        latitude: 0,
        longitude: 0,
        coordinates: [{ type: '', coordinates: [] }],
        points: [],
        source: '',
        boundaryId: '',
      };
    }
  };

  // Change handlers
  const handleOrgChange = async (orgId, org) => {
    try {
      // update selected org if needed (wont be after initial load)
    // If nothing triggered this yet, do it now since
      setLoaded(true);
      // Set to null (not false) so we don't show button untill org has loaded
      setHasSubscription(null);
      // console.log("SELECTED ORG", orgId, org)
      if (selectedOrg.id !== orgId) {
        setSelectedOrg({ id: orgId, name: org });
        setSelectedFarm({ id: '', name: '' });
      }

      // Set field data with selected orgId and name. Get all farms and fields.
      setFarmKey(Math.random());
      setFieldKey(Math.random());

      /* we will update this again when fields data is loaded, but because this
       process can take some time, update orgId and name first */
      setFieldData(
        {
          ...fieldData,
          fields: [],
          selectedField: {
            orgId, org, id: '', name: '', farmId: '', farm: '', clientId: '', client: '',
          },
        },
      );

      setLoading(true);

      // to get clients for selected org
      const orgClients = await getClients(orgId);
      orgClients.sort((a, b) => a.name.localeCompare(b.name));
      setClients(orgClients);

      // farms and fields for selected organization
      const orgsFarms = await getFarms(orgId);
      setFieldData({
        ...fieldData,
        farms: orgsFarms,
      });

      orgsFarms.sort((a, b) => a.name.localeCompare(b.name));
      setFarms(orgsFarms);
      setLoading(false);

      if (orgsFarms !== undefined && orgsFarms.length) {
        // Handle farms
        if (orgsFarms.length > 1) {
          handleSelectionChange('Farm');
          // Get all field names and ids for Org
          const orgsFields = await fetchOrgsFields(orgId, user.token);
          let updatedFields = [];
          if (orgsFields !== undefined) {
            updatedFields = orgsFields.map((field) => ({ id: field.ID, name: field.Name }));
            updatedFields.sort((a, b) => a.name.localeCompare(b.name));

            setFields(updatedFields);
            updateOrgsFields(orgId, org, orgsFarms, updatedFields);
            handleFarmChange(orgsFarms[0].id, orgsFarms[0].name, orgId, org, orgsFarms);
          }
        } else if (orgsFarms.length === 1) {
        // console.log("farm change triggered by org change", org)
          handleFarmChange(orgsFarms[0].id, orgsFarms[0].name, orgId, org, orgsFarms);
        }
      } else {
        enqueueSnackbar(`No farms found for ${org}`);
        handleSelectionChange('Organization');
      }

      if (showSubscribe.current) {
      // Check if org has ProfitLayers subscription. If not we will show purchase link
        const hasProfitLayerAccess = await getProfitMapAccess(orgId);
        setHasSubscription(hasProfitLayerAccess);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const checkIfFieldInUS = async (field) => {
    try {
      const geojson = L.GeoJSON.geometryToLayer(wkt.parse(field.Shape)).toGeoJSON();
      const featureCollection = {
        type: 'FeatureCollection',
        features: [geojson],
      };
      const centroid = turf.centroid(featureCollection);
      const centerPoints = centroid.geometry.coordinates;

      const location = await getStateAndCounty(centerPoints[1], centerPoints[0]);
      if (exists(location)) {
        return true;
      }

      return false;
    } catch (err) {
      console.error(err);
      return false;
    }
  };

  // This function actually gets the fields' boundaries using getOrgFields and decides whether
  // to set org's fields in fieldData.fields and whether handleFarmChange needs to be called if only 1 farm exists
  const updateOrgsFields = async (orgId, org, orgsFarms, orgsFields) => {
    try {
      const orgsFieldsData = await getOrgFields(orgId, true);
      // If farm has not yet been selected, update field data
      if (selecting !== 'Field' && orgsFieldsData !== undefined) {
        const cleanFields = [];

        // if orgs field is out of country pass bool to cleanField() to have it avoid sending request to getLocation()
        // let getLocation = await checkIfFieldInUS(orgsFieldsData[0])
        // console.log("field in us", getLocation)
        for (const field of orgsFieldsData) {
          const farm = orgsFarms.filter((x) => x.id === field.FarmID)[0];
          if (farm !== undefined) {
            const cleaned = await cleanField(field, orgId, org, farm.id, farm.name, orgsFields);
            cleanFields.push(cleaned);
          }
        }

        // Check again that farm or field has not been selected
        if (selecting !== 'Field' && selectedFieldId.current === '') {
          cleanFields.sort((a, b) => a.name.localeCompare(b.name));

          if (cleanFields.length === 0) {
            enqueueSnackbar(`No Fields found for ${org}`);
          } else if (cleanFields.length === 1) {
            setFieldData(
              {
                ...fieldData,
                fields: cleanFields,
                selectedField: {
                  orgId,
                  org,
                  id: cleanFields[0].id,
                  name: cleanFields[0].name,
                  farmId: cleanFields[0].farmId,
                  farm: cleanFields[0].farm,
                  clientId: selectedClient.id,
                  client: selectedClient.name,
                },
                farms: orgsFarms
              },
            );
            handleFarmChange(cleanFields[0].farmId, cleanFields[0].farm, orgId, org, orgsFarms);
          } else {
            setFieldData(
              {
                ...fieldData,
                fields: cleanFields,
                selectedField: {
                  orgId,
                  org,
                  id: '',
                  name: '',
                  farmId: '',
                  farm: '',
                  clientId: selectedClient.id,
                  client: selectedClient.name,
                },
                farms: orgsFarms
              },
            );
          }
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // handlers for client
  const handleClientChange = async (clientId, client, orgId, org) => {
    try {
      // update selected org if needed (wont be after initial load)
    // If nothing triggered this yet, do it now since
      setSelectedClient({ id: clientId, name: client });
      setSelectedFarm({ id: '', name: '' });

      // Set field data with selected orgId and name. Get all farms and fields.
      /* we will update this again when fields data is loaded, but because this
       process can take some time, update orgId and name first */
      setFieldData(
        {
          ...fieldData,
          fields: [],
          selectedField: {
            orgId, org, id: '', name: '', farmId: '', farm: '', clientId, client,
          },
        },
      );

      // farms and fields for selected organization
      setLoading(true);
      const orgsFarms = await getFarms(orgId, clientId);
      //console.log("orgsFarms", orgsFarms)
      orgsFarms.sort((a, b) => a.name.localeCompare(b.name));
      setFarms(orgsFarms);
      setFieldData({
        ...fieldData,
        farms: orgsFarms,
      });

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleClientUse = async () => {
    try {
      setUseClient(!useClient);
      let clientId = null;
      if (!useClient && selectedClient.id !== '') {
        clientId = selectedClient.id;
      }
      setLoading(true);
      const orgsFarms = await getFarms(selectedOrg.id, clientId);
      //console.log("orgsFarms", orgsFarms)
      orgsFarms.sort((a, b) => a.name.localeCompare(b.name));
      setFarms(orgsFarms);
      setFieldData({
        ...fieldData,
        farms: orgsFarms,
      });

      setLoading(false);
    } catch (err) {
      console.error(err);
    }
  };

  const handleFarmChange = async (farmId, farm, orgId, orgName, farmList) => {
    try {
      // update selected farm if needed (could not be after initial load)
      if (selectedFarm.id !== farmId) {
        setFields([]);
        setSelectedFarm({ id: farmId, name: farm });
      }

      setLoading(true);
      setFieldKey(Math.random());

      selectedFieldId.current = '';
      setFieldData({
        ...fieldData,
        fields: [],
        selectedField: {
          farmId,
          farm,
          orgId,
          org: orgName,
          id: '',
          name: '',
          clientId: selectedClient.id,
          client: selectedClient.name,
          farms: farmList
        },
      });

      const farmsFields = await getFields(orgId, farmId);

      if (farmsFields !== undefined && farmsFields.length > 0) {
        farmsFields.sort((a, b) => a.name.localeCompare(b.name));
        setFields(farmsFields);
        handleSelectionChange('Field');

        if (farmsFields.length === 1) {
          const selectedField = await fetchField(orgId, farmId, farmsFields[0].id, user.token);
          if (selectedField?.length) {
            const cleanedField = await cleanField(selectedField[0], orgId, orgName, farmId, farm, farmsFields);
            if (cleanedField.id !== '') {
              setFieldData({
                ...fieldData,
                fieldToClaim: {
                  feature: null, geometry: {}, coordinates: [], points: [], boundary: {}, acres: 0,
                },
                fields: [cleanedField],
                selectedField: cleanedField,
                farms: farmList
              });
              setSelecting('');
              handleSelectionChange('');
              if (select !== undefined) {
                select(cleanedField);
              }
            } else {
              enqueueSnackbar('Problem selecting field');
            }
          }
        }
      } else {
        setFields([]);

        setFieldData({
          ...fieldData,
          fields: [],
          selectedField: {
            farmId,
            farm,
            orgId,
            org: orgName,
            id: '',
            name: '',
            clientId: selectedClient.id,
            client: selectedClient.name,
          },
          farms: farmList
        });
      }

      setLoading(false);

      // If farm has more than one field, get their data
      if (farmsFields.length > 1) {
        const fieldsWithData = await getFieldData(orgId, orgName, farmId, farm, farmsFields);
        updateFarmsFields(fieldsWithData, farmId, farm, orgId, orgName);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const updateFarmsFields = (fieldsWithData, farmId, farm, orgId, orgName) => {
    try {
      // if still selecting field after awaiting field data request
      const fieldsIds = fieldsWithData.map((x) => x.id);

      if (selectedFieldId.current !== '' && fieldsIds.includes(selectedFieldId.current)) {
        const selected = fieldsWithData.filter((x) => x.id === selectedFieldId.current)[0];
        setFieldData({
          ...fieldData,
          fieldToClaim: {
            feature: null, geometry: {}, coordinates: [], points: [], boundary: {}, acres: 0,
          },
          fields: fieldsWithData,
          selectedField: selected,
        });
      }

      if (selectedFieldId.current === '') {
        if (fieldsWithData.length === 0) {
          enqueueSnackbar(`No Fields found for ${farm}`);
        } else if (fieldsWithData.length === 1) {
          setFieldData({
            ...fieldData,
            fieldToClaim: {
              feature: null, geometry: {}, coordinates: [], points: [], boundary: {}, acres: 0,
            },
            fields: fieldsWithData,
            selectedField: fieldsWithData[0],
          });
          handleSelectionChange('');
          if (select !== undefined) {
            select(fieldsWithData[0]);
          }
        } else {
          setFieldData({
            ...fieldData,
            selectedField: {
              farmId,
              farm,
              orgId,
              org: orgName,
              id: '',
              name: '',
              clientId: selectedClient.id,
              client: selectedClient.name,
            },
            fields: fieldsWithData,
          });
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFieldChange = async (orgId, orgName, farmId, id, farmName, fieldName) => {
    try {
      selectedFieldId.current = id;
      const field = fieldData.fields.filter((x) => x.id === id)[0];

      if (field !== undefined) {
        if (field.name === '') {
          field.name = fieldName;
        }
        setFieldData({
          ...fieldData,
          fieldToClaim: {
            feature: null, geometry: {}, coordinates: [], points: [], boundary: {}, acres: 0,
          },
          fields: fieldData.fields,
          selectedField: field,
        });
        if (select !== undefined) {
          select(field);
          handleSelectionChange('');
        }
      } else {
        try {
          const selectedField = await fetchField(orgId, farmId, id, user.token);

          const cleanedField = await cleanField(selectedField[0], orgId, orgName, farmId, farmName, fields);
          if (cleanedField.id !== '') {
            setFieldData({
              ...fieldData,
              fieldToClaim: {
                feature: null, geometry: {}, coordinates: [], points: [], boundary: {}, acres: 0,
              },
              fields: [...fieldData.fields, cleanedField],
              selectedField: cleanedField,
            });
            if (select !== undefined) {
              select(cleanedField);
              handleSelectionChange('');
            }
          } else {
            enqueueSnackbar('Problem selecting field');
          }
        } catch (err) {
          console.error(err);
          enqueueSnackbar('Problem selecting field');
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // NOTE: This handleSelectionChange was mostly used by the old method of field selection and does not seem to be needed anymore
  // It seems selecting seems to still be helpful to see if user has changed selection (as in handleOrgChange)
  const handleSelectionChange = (type) => {
    setSelecting(type);
  };

  const floatingSelection = () => (
    <Box
      style={{
        position: 'absolute',
        top: 240,
        left: 60,
        zIndex: 1005,
        backgroundColor: 'transparent',
      }}
    >
      <Box p={1}>
        {organizations.length > 0 && (
          <CustomAutocomplete
            id="Organizations-Selection"
            className={classes.customAutoComplete}
            options={organizations}
            getOptionLabel={(option) => option.name}
            style={{
              width: 240, margin: '0 10px', backgroundColor: '#ffffff', borderRadius: '4px',
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Organization" />}
            value={selectedOrg}
            onChange={(event, value) => handleOrgChange(value.id, value.name)}
            disableClearable
          />
        )}
      </Box>

      <Box p={1}>
        {farms.length > 0 && (
          <CustomAutocomplete
            key={farmKey}
            id="Farms-Selection"
            className={classes.customAutoComplete}
            options={farms}
            getOptionLabel={(option) => option.name}
            style={{
              width: 240, margin: '0 10px', backgroundColor: '#ffffff', borderRadius: '4px',
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Farm" />}
            value={selectedFarm}
            onChange={(event, value) => handleFarmChange(value.id, value.name, selectedOrg.id, selectedOrg.name, fieldData.farms)}
            disableClearable
          />
        )}
      </Box>

      <Box p={1}>
        {(fields.length > 0 && selectedFarm?.id !== '') && (
          <CustomAutocomplete
            key={fieldKey}
            id="Field-Selection"
            className={classes.customAutoComplete}
            options={fields}
            getOptionLabel={(option) => option.name}
            style={{
              width: 240, margin: '0 10px', backgroundColor: '#ffffff', borderRadius: '4px',
            }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Field" />}
            value={fieldData.selectedField}
            onChange={(event, value) => handleFieldChange(fieldData.selectedField.orgId, fieldData.selectedField.org, fieldData.selectedField.farmId, value.id, fieldData.selectedField.farm)}
            disableClearable
          />
        )}
      </Box>
    </Box>
  );

  const fieldSelection = () => (
    <Box
      mt={1}
      display="flex"
      flexDirection="column"
      fontWeight={500}
      color={blackText}
    >
      { organizations.length > 0 && (
        <Box p={1}>

          <Box height={21}>
            {underwriting ? 'Loan ID'
              : selectedOrg?.id !== '' ? 'Organization' : 'Select Organization'}
          </Box>

          <Autocomplete
            id="Organizations-Selection"
            className={classes.customAutoComplete}
            options={organizations}
            getOptionLabel={(option) => option.name}
            style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Organization" />}
            value={selectedOrg}
            onChange={(event, value) => handleOrgChange(value.id, value.name)}
            disableClearable
            disabled={loading}
          />
        </Box>
      )}

      { clients.length > 0 && 
        <Box p={1} style={{paddingTop:0}}>
          <Box height={40} mb={1} style={{marginLeft:10}}>
            <FormControlLabel
              control={<Checkbox name="useClient" />}
              label={underwriting ? 'Filter by Report' : 'Filter by Client'}
              onChange={() => handleClientUse()}
              checked={useClient}
            />
          </Box>
          {useClient && 
            <Autocomplete
              id="Client-Selection"
              className={classes.customAutoComplete}
              options={clients}
              getOptionLabel={(option) => (option.name !== undefined ? option.name : '')}
              style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
              renderInput={(params) => <TextField {...params} variant="outlined" placeholder={underwriting ? 'Select Report' : 'Select Client'} />}
              value={selectedClient}
              onChange={(event, value) => handleClientChange(value.id, value.name, selectedOrg.id, selectedOrg.name)}
              disableClearable
              disabled={!useClient}
            />
          }
        </Box>
      }

      {farms.length > 0 && (
      <Box p={1}>
        <Box height={21}>
          {underwriting ? 'Document ID'
            : selectedFarm?.id !== '' ? 'Farm' : farms.length > 0 ? 'Select Farm' : ''}
        </Box>

        <Autocomplete
          key={farmKey}
          id="Farms-Selection"
          className={classes.customAutoComplete}
          options={farms}
          getOptionLabel={(option) => option.name}
          style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Farm" />}
          value={selectedFarm}
          onChange={(event, value) => handleFarmChange(value.id, value.name, selectedOrg.id, selectedOrg.name, fieldData.farms)}
          disableClearable
          disabled={loading}
        />
      </Box>
      )}

      { (fields.length > 0 && selectedFarm?.id !== '') && (
      <Box p={1}>
        <Box height={21}>
          {!underwriting

            ? fieldData?.selectedField?.id !== '' ? 'Field' : (fields.length > 0 && selectedFarm?.id !== '') ? 'Select Field' : ''
            : fieldData?.selectedField?.id !== '' ? 'Parcel' : (fields.length > 0 && selectedFarm?.id !== '') ? 'Select Parcel' : ''}
        </Box>

        <Autocomplete
          key={fieldKey}
          id="Field-Selection"
          className={classes.customAutoComplete}
          options={fields}
          getOptionLabel={(option) => option.name}
          style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
          renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Field" />}
          value={fieldData.selectedField}
          onChange={(event, value) => handleFieldChange(fieldData.selectedField.orgId, fieldData.selectedField.org, fieldData.selectedField.farmId, value.id, fieldData.selectedField.farm, value.name)}
          disableClearable
          disabled={loading}
        />
      </Box>
      )}
    </Box>
  );

  const mobileFieldSelection = () => (
    <Box
      display="flex"
      flexWrap="wrap"
      fontWeight={500}
      color={blackText}
    >

      { organizations.length > 0 && (
        <Box mt={0.5}>
          <Box height={21}>
            {selectedOrg?.id !== '' ? 'Organization' : 'Select Organization'}
          </Box>

          <CustomAutocomplete
            id="Organizations-Selection"
            className={classes.customAutoComplete}
            options={organizations}
            getOptionLabel={(option) => option.name}
            style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Organization" />}
            value={selectedOrg}
            onChange={(event, value) => handleOrgChange(value.id, value.name)}
            disableClearable
          />
        </Box>
      )}

      { clients.length > 0 && (
        <Box mt={0.5}>
          <Box height={40} mb={1}>
            <FormControlLabel
              control={<Checkbox name="useClient" />}
              label="Select this option to filter by client."
              onChange={() => handleClientUse()}
              checked={useClient}
            />
          </Box>

          <CustomAutocomplete
            id="Client-Selection"
            className={classes.customAutoComplete}
            options={clients}
            getOptionLabel={(option) => option.name}
            style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Client" />}
            value={selectedClient}
            onChange={(event, value) => handleClientChange(value.id, value.name, selectedOrg.id, selectedOrg.name)}
            disableClearable
            disabled={!useClient}
          />
        </Box>
      )}

      { farms.length > 0 && (
        <Box mt={0.5}>
          <Box height={21}>
            {selectedFarm?.id !== '' ? 'Farm' : farms.length > 0 ? 'Select Farm' : ''}
          </Box>

          <CustomAutocomplete
            key={farmKey}
            id="Farms-Selection"
            className={classes.customAutoComplete}
            options={farms}
            getOptionLabel={(option) => option.name}
            style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Farm" />}
            value={selectedFarm}
            onChange={(event, value) => handleFarmChange(value.id, value.name, selectedOrg.id, selectedOrg.name, fieldData.farms)}
            disableClearable
          />
        </Box>
      )}

      { (fields.length > 0 && selectedFarm?.id !== '') && (
        <Box mt={0.5}>
          <Box height={21}>
            {fieldData?.selectedField?.id !== '' ? 'Field' : (fields.length > 0 && selectedFarm?.id !== '') ? 'Select Field' : ''}
          </Box>

          <CustomAutocomplete
            key={fieldKey}
            id="Field-Selection"
            className={classes.customAutoComplete}
            options={fields}
            getOptionLabel={(option) => option.name}
            style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
            renderInput={(params) => <TextField {...params} variant="outlined" placeholder="Select Field" />}
            value={fieldData.selectedField}
            onChange={(event, value) => handleFieldChange(fieldData.selectedField.orgId, fieldData.selectedField.org, fieldData.selectedField.farmId, value.id, fieldData.selectedField.farm)}
            disableClearable
          />

        </Box>
      )}
    </Box>
  );

  const subscribe = () => (
    <Box my={1} textAlign="center">
      <Button
        variant="outlined"
        color="primary"
        target="_blank"
        href={`${Endpoints.HOME}/app/purchase`}
        style={{ backgroundColor: '#ffffff', marginTop: 8 }}
      >
        <span className={classes.buttonLink}>
          Subscribe to PROFIT LAYERS
          <sup>&reg;</sup>
        </span>
      </Button>
    </Box>
  );

  return (
    !mobile ? (
      <Box className={classes.root}>
        { fieldSelection()}

        {
          loading
          && (
          <Box p={2} display="flex" justifyContent="center" style={{ width: '100%' }}>
            <CircularProgress />
          </Box>
          )
        }

        {
          (organizations.length === 1 && organizations[0].name === 'Default')
          && (
          <IntegrationLinks
            mobileView={false}
            signedIn
            onConnect={openConnect}
          />
          )
        }

        { hasSubscription === false && subscribe()}
      </Box>
    )
      : (
        <Box p={1} display="flex" flexWrap="wrap">
          {mobileFieldSelection()}

          {
            loading
            && (
            <Box p={2}>
              <CircularProgress />
            </Box>
            )
          }

          { hasSubscription === false && subscribe()}
        </Box>
      )
  );
}

SelectFieldV2.propTypes = {
  containerHeight: PropTypes.number,
  select: PropTypes.func,
  openConnect: PropTypes.func,
  page: PropTypes.string,
  mobile: PropTypes.bool,
  needsStateAndCounty: PropTypes.string,
  underwriting: PropTypes.bool,
  setReports: PropTypes.func,
  setDocuments: PropTypes.func,
  loanID: PropTypes.string,
  setIsLoading: PropTypes.func,
};

SelectFieldV2.defaultProps = {
  containerHeight: undefined,
  select: undefined,
  openConnect: undefined,
  page: 'layers',
  mobile: false,
  needsStateAndCounty: undefined,
  underwriting: undefined,
  setReports: undefined,
  setDocuments: undefined,
  loanID: undefined,
  setIsLoading: undefined,
};
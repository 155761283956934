import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// Map imports
import * as L from 'leaflet';

// Functions
import { createGeoFromBoundary } from '../MapFunctions/helpers';

import { Tiles } from '../../../constants/Tiles';
import { zoneColors } from '../Styles/layerStyles';

/**
 * Takes a CLU and draws associated boundaries
 * @param {Object} clu Clu info, including subfield boundaries
 * @param {String} completeBoundary Full CLU boundary
 * @returns {JSX} map
 */
export function PdfCluMap({ clu, completeBoundary }) {
  const [map, setMap] = useState(null);
  const cluLayer = useRef(L.featureGroup(null));

  useEffect(() => {
    if (map === null) {
      drawMap();
    }
  }, []);

  useEffect(() => {
    if (clu && map && completeBoundary) {
      cluLayer.current.clearLayers();

      // Add full CLU boundary and fit map bounds
      if (completeBoundary !== 'X') {
        const boundary = createGeoFromBoundary(completeBoundary);
        const boundaryCollection = L.geoJson(boundary, {
          onEachFeature: (feature, layer) => {
            layer.setStyle({ fillOpacity: 0 });
            cluLayer.current.addLayer(layer);
          }
        });
        boundaryCollection.addTo(map);
      }

      // Add subfield boundaries
      clu.forEach((subField, i) => {
        const geo = createGeoFromBoundary(subField.boundary);
        const collection = L.geoJson(geo, {
          onEachFeature: (feature, layer) => {
            const color = zoneColors[i];
            const weight = 2 + i;
            layer.setStyle({
              color,
              weight,
              opacity: 1,
              fillOpacity: 0.35,
            });
            cluLayer.current.addLayer(layer);
            const toolTip = `<div style="height: 30; width: 30;"><div style="margin: 2px 6px;">Subfield: ${subField.subfieldNumber}</div></div>`;
            layer.bindTooltip(toolTip, { className: 'leaftletTooltipClass' });
          },
        });
        collection.addTo(map);
      });

      // Fit map to bounds of drawn shapes
      map.fitBounds(cluLayer.current.getBounds(), {
        padding: [30, 30],
      })
    }
  }, [clu, map, completeBoundary]);

  const drawMap = () => {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    // Add map to div by Id, set any parameters and initial view, add mapbox layer
    const cluMap = L.map('clu-map', { zoomControl: false })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    cluMap.addLayer(cluLayer.current);
    setMap(cluMap);
  };

  return (
    <div style={{ height: 360, width: 360, borderRadius: 4 }} id="pdf-map-image">
      <Box
        id="clu-map"
        height="100%"
        width="100%"
      />
    </div>

  );
}

PdfCluMap.propTypes = {
  clu: PropTypes.array,
  completeBoundary: PropTypes.string,
};

PdfCluMap.defaultProps = {
  clu: null,
  completeBoundary: null,
};


/**
 * Takes a Field and draws associated boundaries
 * @param {Object} fieldInfo Field info, including operation boundaries
 * @param {String} completeBoundary Full field boundary
 * @returns {JSX} map
 */
 export function PdfFieldMap({ fieldInfo, completeBoundary }) {
  const [map, setMap] = useState(null);
  const fieldLayer = useRef(L.featureGroup(null));

  useEffect(() => {
    if (map === null) {
      drawMap();
    }
  }, []);

  useEffect(() => {
    if (fieldInfo && map) { // && completeBoundary) {
      fieldLayer.current.clearLayers();

      // Add operation boundaries
      fieldInfo.forEach((operation, idx) => {
        const geo = createGeoFromBoundary(operation.boundary);
        const collection = L.geoJson(geo, {
          onEachFeature: (feature, layer) => {
            const color = zoneColors[idx];
            const weight = 2 + idx;
            layer.setStyle({
              color,
              weight,
              opacity: 1,
              fillOpacity: 0.35,
            });
            fieldLayer.current.addLayer(layer);
          },
        });
        collection.addTo(map);
      });

      // Fit map to bounds of drawn shapes
      map.fitBounds(fieldLayer.current.getBounds(), {
        padding: [30, 30],
      });
    }
  }, [fieldInfo, map, completeBoundary]);

  const drawMap = () => {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    // Add map to div by Id, set any parameters and initial view, add mapbox layer
    const fieldMap = L.map('field-map', { zoomControl: false })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    fieldMap.addLayer(fieldLayer.current);
    setMap(fieldMap);
  };

  return (
    <div style={{ height: 360, width: 360, borderRadius: 4 }} id="pdf-map-image">
      <Box
        id="field-map"
        height="100%"
        width="100%"
      />
    </div>

  );
}

PdfFieldMap.propTypes = {
  fieldInfo: PropTypes.array,
  completeBoundary: PropTypes.string,
};

PdfFieldMap.defaultProps = {
  fieldInfo: null,
  completeBoundary: null,
};

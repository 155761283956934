import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { exists } from '../../utils/helpers';
import { Tiles } from '../../constants/Tiles';

/**
 * Draws field on map for basic image grab
 * @param {object} field Field to draw
 * @returns {JSX} Map with field image
 */
export function FieldImage({ field }) {
  const [map, setMap] = useState(null);
  const fieldLayer = useRef(L.featureGroup(null));
  // Layer to draw zones from field geo to
  const geoZones = useRef(L.geoJSON(null));

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (map) {
      if (field?.boundary) {
        drawField(field);
      }
      if (field?.zones) {
        drawFieldZones(field.zones);
      }
    }
  }, [field, map]);

  const drawMap = async () => {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const map = L.map('field-image-map', {
      zoomControl: false,
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    setMap(map);
    map.addLayer(fieldLayer.current);
  };

  const drawField = (field) => {
    try {
      const features = JSON.parse(field.boundary);
      const geo = {
        type: 'FeatureCollection',
        features: [features],
      };
      // remove any fields currently on layer
      fieldLayer.current.clearLayers();

      // add field to map
      const newCollection = L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          fieldLayer.current.addLayer(layer).setStyle({
            color: '#8e24aa',
            fillColor: '#f3e5f5',
            opacity: 1,
            fillOpacity: 0.25,
          });
        },
      }).addTo(map);

      // fit map to field bounds with some padding
      map.fitBounds(newCollection.getBounds(), { padding: [30, 30] });
    } catch (err) {
      console.error(err);
    }
  };

  const drawFieldZones = (zones) => {
    try {
      const featureCollection = {
        type: 'FeatureCollection',
        features: zones,
      };

      geoZones.current = L.geoJSON(featureCollection);

      geoZones.current.eachLayer((layer) => {
        const { color } = layer.feature.properties;
        layer.setStyle({
          color: color !== '' ? color : '#3b93ff',
          fillColor: color !== '' ? color : '#dbfeff',
          fillOpacity: '.05',
        });
      });

      geoZones.current.addTo(map);
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <div id="field-image-map" style={{ height: 550, width: 550 }} />
  );
}

FieldImage.propTypes = {
  field: PropTypes.object.isRequired,
};

import React, { useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { makeStyles } from "@material-ui/core/styles";
import { Endpoints } from '../../constants/Endpoints';
import { updateSubscriptionPaymentMethod } from "../../utils/dataFetchers";
import { BrainTreeDropIn } from "../ProfitLayers/Reports/BrainTreeDropInReact";
import braintree from 'braintree-web-drop-in';
import { useSnackbar } from 'notistack';
import {
    Box,
    Button,
    Modal,
    Tooltip,
    CircularProgress, TextField
  } from "@material-ui/core";
import { feature } from "@turf/turf";

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {...theme.modalPaper, width: "50%", height: "auto"},
  head: { ...theme.modalHead, padding: "20px" },
  mapControls: theme.mapControls,
  details: {...theme.plDetails, height: '80%'},
  buttonBox: theme.buttonBox,
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  labels: {
    display: "flex",
    padding: "6px 16px",
    alignItems: "center",
    fontWeight: 600,
    color: theme.palette.text.black,
  },
  tableRow: {
    display: "flex",
    flexGrow: 1,
    padding: "8px",
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  braintreeContainer:{
    padding: '15px'
  }
}));

const renderSubmitButton = ({ onClick, isDisabled, text }) => {
  return (
    <Button variant="contained"
      color="primary"
      onClick={onClick}
      disabled={isDisabled}
      style={{ width: '100%', marginBottom: '10px' }}
      disableElevation
    >
      Update
    </Button>
  )
}

/**
 * JSX modal for displaying subscription details and holds functionality for updating the 
 * payment method associated with a subscription as well as canceling a subscription
 * @param {Function} setOpen function that updates the open state variable in ManageSubscription.js, determines if UpdateSubscription component is displayed
 * @param {Array} featuresList array of features associated with the currently selected subscription, information in here is used for display
 * @param {Object} customerInformation object containing data about the current customer
 * @param {Function} handleUnsubscription function to cancel the subscription
 * @param {Object} paymentMethod object containing information about the payment method associated with the currently selected subscription
 * @param {Function} clearPaymentMethod function to update the value of the paymentMethod state variable, used to reset when the modal closes
 * @param {Function} getPaymentDetails function to get the payment method for the current subscription, used after updating the payment method
 */
export function UpdateSubscription({
  setOpen, 
  featuresList, 
  customerInformation,
  handleUnsubscription,
  paymentMethod,
  clearPaymentMethod,
  getPaymentDetails
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [modalStyle] = React.useState(getModalStyle);

  const [isLoading, setIsLoading] = useState(false)

  const [updatingPaymentMethod, setUpdatingPaymentMethod] = useState(false)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
  const [showErrorMessgae, setShowErrorMessage] = useState(false)
  const [processing, setProcessing] = useState(false)
  const [paymentNonce, setPaymentNonce] = useState(null)

  useEffect(() => {
    if(updatingPaymentMethod){
      generatePaymentMethodNonce()
    }
  },[updatingPaymentMethod])

  const handleClick = async () => {
    setIsLoading(true)
    await handleUnsubscription()
    setIsLoading(false)
  }

  /**
   * method used to update the payment method associate with a subscription
   * uses the payload returned from braintree when the Update button is pressed to get the
   * nonce for the user's selected payment method
   * @param {Object} payload response from Braintree when the 'Update' button is pressed
   */
  const handlePaymentMethod = async (payload) => {
    //send payment nonce to backend to update current subscription with new payment method
    let sub = featuresList[0].subscription
    let nonce = payload.nonce
    let result = await updateSubscriptionPaymentMethod(sub.subscriptionIdentifier, nonce)
    if (result !== null){
      getPaymentDetails(sub)
      setUpdatingPaymentMethod(false)
      enqueueSnackbar("Payment method successfully updated", {autoHideDuration: 5000})
    }
  }

  /**
   * Functions passed to the Braintree dropin that are never actually used
   */
  const onError = () => {
    setShowErrorMessage(true)
  }
  const onCreate = () => {

  }

  /**
   * Create the user's payment nonce for the BrainTree drop in to use
   */
  const generatePaymentMethodNonce = async () => {
    let url = Endpoints.BASEURL + Endpoints.API_CUSTOMER_PAYMENT_NONCE
    // console.log("userToken", userToken)
    try {
      let response;
      response = await fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + sessionStorage.getItem("jwtAccessToken")
        },
        method: "GET",
        credentials: 'include'
      })

      let jsonResponse = await response.json();
      // console.log("jsonResponse.clientToken", jsonResponse)
      setIsLoading(false)
      setPaymentNonce(jsonResponse.clientToken)
    }
    catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  /**
   * Function to call correct clean up functions and close modal
   */
  const handleClose = () => {
    clearPaymentMethod(null)
    setOpen(false)
  }

  /**
   * Creates the row that is displayed in the modal
   * @param {Object} sub object from the featureList array
   * @param {Int} i index of 'sub' object in featureList array
   * @returns JSX
   */
  const createRow = (sub, i) => {
    let discountApplied = sub.subscription.dealerDiscount != null && sub.subscription.dealerDiscount > 0 ? sub.subscription.dealerDiscount : 0
    return(
      <Box display="flex" key={i} className={classes.tableRow} style={{ backgroundColor: i % 2 == 0 ? "#f3f3f3" : "#ffffff" }} >
        <Box px={1} style={{ width: "30%" }}>{sub.customerOrganization.organizationId}</Box>
        <Box px={1} style={{ width: "30%" }}>{sub.customerOrganization.organizationName}</Box>
        <Box px={1} style={{ width: "30%" }}>$ {(sub.quantity*sub.feature.price)-discountApplied}</Box>
        <Box px={1} style={{ width: "30%" }}>{sub.feature.featureName.toUpperCase()}</Box>
        {paymentMethod !== null &&
          <Box px={1} style={{ width: "30%" }}>{`**** **** **** ${paymentMethod.lastFour}`}</Box>
        }
      </Box>
    )
  }

  return(
    <Box
      style={modalStyle}
      className={classes.paper}
      borderRadius="borderRadius"
    >
      {isLoading && (<CircularProgress
                style={{
                  position: 'absolute',
                  right: '50%',
                  top: '50%',
                  zIndex: 1005,
                  color: '#1e88e5',
                }}
      />)}
      <Box className={classes.head}>
        Update Subscription:
      </Box>

      <Box display="flex" className={classes.labels}>
        <Box px={1} style={{ width: "30%" }}>
          Organization ID
        </Box>
        <Box px={1} style={{ width: "30%" }}>
          Organization Name
        </Box>
        <Box px={1} style={{ width: "30%" }}>
          Total Cost
        </Box>    
        <Box px={1} style={{ width: "30%" }}>
          Subscribed Feature
        </Box>
        {paymentMethod !== null && 
          <Box px={1} style={{ width: "30%" }}>
            Credit Card
          </Box>  
        }     
      </Box>
      {featuresList != null && featuresList.map((op, i) => createRow(op, i))}
      
      <Box>
        <Box p={2} className={classes.buttonBox}>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '25px' }}
            onClick={() => handleClose()}
          >
            Cancel
          </Button>
          <Button
            variant="outlined"
            color="primary"
            style={{ marginRight: '25px' }}
            onClick={() => setUpdatingPaymentMethod(true)}
          >
            Update Payment Method
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={()=>handleClick()}
          >
            Unsubscribe
          </Button>
        </Box>
      </Box>
    
      {updatingPaymentMethod && 
        <Box className={classes.braintreeContainer}>
          <BrainTreeDropIn
            braintree={braintree}
            paypal={{ flow: 'vault' }}
            authorizationToken={paymentNonce}
            renderSubmitButton={renderSubmitButton}
            setProcessing={setProcessing}
            handlePaymentMethod={handlePaymentMethod}
            onError={onError}
            onCreate={onCreate}
            isSubmitButtonDisabled={isSubmitButtonDisabled}
            setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
            source={"ManageSubscription"}
          />
        </Box>
      }
    
    </Box>
    
  )
}

UpdateSubscription.propTypes = {
  setOpen: PropTypes.func.isRequired, 
  featuresList: PropTypes.arrayOf(
    PropTypes.object
  ).isRequired, 
  customerInformation: PropTypes.object,
  handleUnsubscription: PropTypes.func.isRequired,
  paymentMethod: PropTypes.object.isRequired,
  clearPaymentMethod: PropTypes.func.isRequired,
  getPaymentDetails: PropTypes.func.isRequired
}

// -------------------- IMPORTS --------------------
// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  Box,
  Button,
  Divider,
  Modal,
  Switch,
  TextField,
  Tooltip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { darkText } from '../../../styles/colors';
import { useSnackbar } from 'notistack';

// Get data
import { getSharedCLUEmails } from '../../../utils/dataFetchers';

// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: 300,
    margin: 6,
  },
  body: {
    height: 'auto',
    width: '100%',
    padding: '0 20px',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.black,
    overflowY: 'auto',
  },
  buttonBox: {
    ...theme.buttonBox,
    alignItems: 'center',
    height: '60px',
    marginTop: '24px',
  },
  description: {
    fontSize: 14,
    color: theme.palette.text.grey,
  },
  head: {
    height: '50px',
    padding: '20px',
    alignItems: 'center',
    width: '100%',
    color: darkText,
    fontSize: 20,
    fontWeight: 500,
    display: 'flex',
  },
  paper: {
    ...theme.modalPaper,
    position: 'absolute',
    height: 'auto',
    width: '450px',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column'
  },
  setting: {
    marginTop: '24px',
  },
  settingAction: {
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
  },
}));


// -------------------- MAIN FUNCTION --------------------
/**
 * Rendered in AcreageReporting/AcreageReporting.js
 * Modal for editing interface settings. Options displayed depend on gartPath.
 * @param {String} gartPath 'GART' or 'CART'
 * @param {Boolean} includeNonCLUBoundaries Whether to include "orphaned boundaries"
 * @param {Boolean} initialLoadComplete Whether it has been attempted to load data
 * @param {Boolean} loading Data is loading, show Spinner
 * @param {Function} refreshData Calls initialLoad
 * @param {Function} resetPage Resets interface to most of the initial state when coming from Landing/Onboarding stages
 * @param {Function} resetWarning Open ResetWarning modal
 * @param {Function} setEditAccessesOpen Open EditCLUAccesses modal
 * @param {Function} setIncludeNonCLUBoundaries Expects true or false
 * @param {Function} setSettingsOpen Set if this modal is opened or closed
 * @param {Boolean} settingsOpen Determine if this modal is opened or closed
 * @param {Function} setUseOrgCLUData Expects true or false
 * @param {Function} setUseOwnCLUData Expects '' or 'EmailGoesHere'
 * @param {Boolean} showSettingsInfo If initial load is complete and no error
 * @param {Boolean} useOrgCLUData CLU data to use. Either true or false
 * @param {String} useOwnCLUData CLU data to use. Either '' or 'EmailGoesHere'
 * @returns {JSX} Modal
 */
export function Settings({
  gartPath,
  includeNonCLUBoundaries,
  initialLoadComplete,
  loading,
  refreshData,
  resetPage,
  resetWarning,
  setEditAccessesOpen,
  setIncludeNonCLUBoundaries,
  setSettingsOpen,
  settingsOpen,
  setUseOrgCLUData,
  setUseOwnCLUData,
  showSettingsInfo,
  useOrgCLUData,
  useOwnCLUData,
}) {
  // -------------------- VARIABLES --------------------
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [emailsToUse, setEmailsToUse] = useState(['']);


  // -------------------- USE EFFECTS --------------------
  useEffect(() => {
    if (!gartPath) initializeSharedCLUEmails();
  }, [])


  // -------------------- FUNCTIONALITY --------------------
  // Gets the user that have decided to share CLU data with the current user
  const initializeSharedCLUEmails = async () => {
    try {
      const emails = await getSharedCLUEmails();

      if (emails !== undefined && typeof emails !== 'string') {
        setEmailsToUse(emails.map(elem => elem.email_address));
      }
      else {
        enqueueSnackbar('An issue occured while getting the users that have shared their CLU data with you. You might be logged out, please refresh and try again.');
      }
    } catch(err) {
      console.error('An error occured while getting shared CLU email data: ', err);
    }
  }

  const save = () => {
    setSettingsOpen(false);
  };

  // Content/body of modal
  const modalBody = () => (
    <>
      {/* Refresh or Reset Data */}
      { showSettingsInfo && (
        <Box className={classes.setting}>
          Refresh or Reset Data
          <Box className={classes.description}>
            Hover over buttons to see what they do.
          </Box>

          <Box
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
            p={1}
          >
            <Tooltip title="Remove any unsaved changes">
              <div>
                <Button 
                  color="primary"
                  variant="contained"
                  onClick={() => {setSettingsOpen(false);refreshData()}}
                  disabled={loading}
                >
                  Refresh Data
                </Button>
              </div>
            </Tooltip>

            <Tooltip title="Delete saved data and start from original default values">
              <div>
                <Button
                  color="secondary"
                  variant="contained"
                  onClick={() => {setSettingsOpen(false);resetWarning(true)}}
                  disabled={loading}
                >
                  Reset Data
                </Button>
              </div>
            </Tooltip>
          </Box>
        </Box>
      )}

      { !gartPath && (
        <>
          {/* Edit CLU_Accesses */}
          <Box className={classes.setting}>
            Edit CLU Permissions
            <Box className={classes.description}>
              Add, view, and edit who has access to your uploaded CLU data.
            </Box>
            <Box className={classes.settingAction}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => {
                  setSettingsOpen(false);
                  setEditAccessesOpen(true);
                }}
              >
                View Permissions
              </Button>
            </Box>
          </Box>

          {/* These settings should be set before the user load's data. Changing these after data is loaded could cause many issues.
          So, now there's a way for user to get back to initial screen showing Overview Page. */}
          {initialLoadComplete ?
            <>
              {/* Reset Page */}
              <Box className={classes.setting}>
                Reset Page
                <Box className={classes.description}>
                  Access initial screen to change Agent and CLU settings.
                </Box>
                <Box className={classes.description}>
                  WARNING: Make sure to <b>save</b> as any changes not saved will be lost.
                </Box>
                <Box className={classes.settingAction}>
                  <Button
                    color="secondary"
                    variant="contained"
                    onClick={() => {
                      setSettingsOpen(false);
                      resetPage();
                    }}
                    disabled={loading}
                  >
                    Reset Page
                  </Button>
                </Box>
              </Box>
            </>
          :
            <>
              {/* Use own or other's CLU data */}
              <Box className={classes.setting}>
                <Box>
                  CLU Data: Own vs. Another 
                  <Box className={classes.description}>
                    Leave blank to use your own data or choose another user's email to use their CLU data.
                  </Box>
                  <Box className={classes.description}>
                    NOTE: Using another user's CLU data means you cannot use uploaded CLU data associated with your account.
                  </Box>
                  <Box className={classes.description}>
                    NOTE: You can only use another user's CLU data if they have given you access.
                  </Box>
                </Box>

                <Box className={classes.settingAction} alignItems="center">
                  <Autocomplete
                    className={classes.autocomplete}
                    options={emailsToUse}
                    value={useOwnCLUData}
                    getOptionLabel={(option) => option}
                    renderInput={(params) => (
                      <TextField
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...params}
                        variant="outlined"
                        placeholder="Select Email"
                      />
                    )}
                    onChange={(event, value) => { setUseOwnCLUData(value || ''); }}
                  />
                </Box>
              </Box>

              {/* Use overall or org-specific CLU data */}
              {useOwnCLUData === '' &&
                <Box className={classes.setting}>
                  <Box>
                    CLU Data: Account vs. Organization
                    <Box className={classes.description}>
                      Choose whether to use CLU Data associated with your overall account or with the selected organization.
                    </Box>
                    <Box className={classes.description}>
                      NOTE: To associate CLU data with a particular organization, click "Upload CLU" on the bottom-left of the screen.
                    </Box>
                  </Box>

                  <Box className={classes.settingAction} alignItems="center">
                    <Box>
                      Account
                    </Box>

                    <Switch
                      color="primary"
                      checked={useOrgCLUData}
                      onChange={() => setUseOrgCLUData(!useOrgCLUData) }
                      name="display-format"
                      inputProps={{ 'aria-label': 'display-format' }}
                      disabled={loading}
                    />

                    <Box>
                      Organization
                    </Box>
                  </Box>
                </Box>
              }

              {/* Choice to Include "Orphaned Boundaries" */}
              <Box className={classes.setting}>
                <Box>
                  Include "Orphaned Boundaries"
                  <Box className={classes.description}>
                    Choose whether to include your plantings' boundaries that do not intersect any of your uploaded CLUs. 
                  </Box>
                  <Box className={classes.description}>
                    NOTE: Plantings' boundaries not intersecting with any of your uploaded CLUs could indicate missing CLU data.
                  </Box>
                </Box>

                <Box className={classes.settingAction} alignItems="center">
                  <Box>
                    Exclude
                  </Box>

                  <Switch
                    color="primary"
                    checked={includeNonCLUBoundaries}
                    onChange={() => setIncludeNonCLUBoundaries(!includeNonCLUBoundaries) }
                    name="display-format"
                    inputProps={{ 'aria-label': 'display-format' }}
                    disabled={loading}
                  />

                  <Box>
                    Include
                  </Box>
                </Box>
              </Box>
            </>
          }
        </>
      )}
    </>
  )

  // Handles layout of settings modal
  const settingsModal = () => (
    <Modal
      open={settingsOpen}
      onClose={() => setSettingsOpen(false)}
      style={{display:'flex', alignItems:'center', justifyContent:'center'}}
    >
      <Box
        className={classes.paper}
        borderRadius="borderRadius"
      >
        {/* Settings Title */}
        <Box className={classes.head}>
          Settings
        </Box>

        {/* Content of modal */}
        <Box className={classes.body}>
          <Divider />
          {modalBody()}
        </Box>

        {/* Bottom footer */}
        <Box className={classes.buttonBox}>
          <Box m={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setSettingsOpen(false)}
              style={{backgroundColor: '#ffffff'}}
            >
              Close
            </Button>
          </Box>
          {/* <Box m={1}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => save()}
            >
              Save
            </Button>
          </Box> */}
        </Box>
      </Box>
    </Modal>
  )


  // -------------------- RETURN --------------------
  return ( settingsModal() );
}

Settings.propTypes = {
  gartPath: PropTypes.bool.isRequired,
  includeNonCLUBoundaries: PropTypes.bool.isRequired,
  initialLoadComplete: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  refreshData: PropTypes.func.isRequired,
  resetWarning: PropTypes.func.isRequired,
  setEditAccessesOpen: PropTypes.func.isRequired,
  setIncludeNonCLUBoundaries: PropTypes.func.isRequired,
  setSettingsOpen: PropTypes.func.isRequired,
  settingsOpen: PropTypes.bool.isRequired,
  setUseOrgCLUData: PropTypes.func.isRequired,
  setUseOwnCLUData: PropTypes.func.isRequired,
  showSettingsInfo: PropTypes.bool.isRequired,
  useOrgCLUData: PropTypes.bool.isRequired,
  useOwnCLUData: PropTypes.string.isRequired,
};

import * as fr from '../../utils/farmReportDataFetchers'
import {exists } from '../../utils/helpers'
import { getIndividualSeedPrice } from '../../utils/dataFetchers'
import {formatDate} from './helper'
import * as calc from "../ProfitLayers/utils/calculations";

export async function getGenericForFarm(orgId, farmId, year) {
  try{
    let genericOps = await fr.getFarmOperations(orgId, farmId, year, 'Generic')
    //console.log(genericOps)

    let formattedGen = genericOps.data.map((x) => ({
      ...x,
      fileType: "Generic",
      id: x.operationID,
      cellsize: "0.0001",
      date: formatDate(x.startDate),
    }));

    let combinedGenerics = combineGenericOperations(formattedGen)
    let map = new Map()
    for (let op of combinedGenerics){
      if (!map.get(op.fieldID)) map.set(op.fieldID, [])
      let data = map.get(op.fieldID)
      //console.log(data)
      data.push(op)
      map.set(op.fieldID, data)
    }

    console.log('generic map', map)
    return map;
  }
  catch(err){
    console.log('get generic ops failed', err)
  }
}

const combineGenericOperations = (formattedGen) => {
  let combinedGenerics = []
  //combine generics with same operation ID for new zone setup
  for (const generic of formattedGen) {
    let opId = generic.operationID
    let exists = combinedGenerics.filter(x => x.operationID === opId)

    if (exists.length === 0) {
      let matching = formattedGen.filter(x => x.operationID === opId)
      var toplevelGen = {
        ...generic,
        zones: []
      }

      for (const match of matching) {
        toplevelGen.zones.push(match)
      }
      //calculate top level total and rates
      let opTotal = matching.map(x => x.total).reduce((prev, next) => prev + next, 0)
      let areaTotal = matching.map(x => x.area).reduce((prev, next) => prev + next, 0)
      let opRate = (opTotal / areaTotal).toFixed(2)

      toplevelGen.area = areaTotal
      toplevelGen.total = opTotal
      toplevelGen.rate = opRate
      toplevelGen.price = matching[0].price
      combinedGenerics.push(toplevelGen)
    }
  }
  //console.log('combinedGenerics', combinedGenerics)
  return combinedGenerics
}

export async function getTillageForFarm(orgId, farmId, year, dieselPrices){
  try{
    let tillageOperations = await fr.getFarmOperations(orgId, farmId, year, 'Tillage')

    let tillage = [];
    if (tillageOperations.data && tillageOperations.data.length > 0) {
      tillage = tillageOperations.data.map((op) => cleanTillage(op, dieselPrices));
    }

    let withZones = []
    for (const op of tillage) {
      let toplevelTill = {
        ...op,
        zones: []
      }
      toplevelTill.zones.push(op)
      withZones.push(toplevelTill)
    }

    let map = new Map()

    for(let op of withZones){
      if (!map.get(op.fieldID)) map.set(op.fieldID, [])
      let data = map.get(op.fieldID)
      //console.log(data)
      data.push(op)
      map.set(op.fieldID, data)
    }

    console.log('tillage map', map)
    return map;
  }
  catch(err){
    console.log('error getting tillage ops', err)
  }
}

const cleanTillage = (op, dieselPrices) => {
  //Format tillage operations to match other field passes

  // split date for formatting
  const d = op.tillageDate.split("T")[0].split("-");
  // users requested feature to name tillage operations, so first check if named
  if (op.productName === null) {
    const date = `${d[1]}/${d[2]}/${d[0]}`;
    const name = `${date} Tillage (depth: ${op.tillageDepth} ${op.tillageDepthUnit})`;
    op.productName = name;
  }

  if (op.price === null) {
    op.price = calc.getTillagePrice(op, dieselPrices);
  }

  op.area = op.area;
  op.operationCost = op.area * op.price;
  op.year = d[0]; // currently using selected year for this?
  op.cellsize = "0.0001";

  return op;
};

import React, {
  useState, useEffect, useRef, useContext,
} from 'react';
import PropTypes from 'prop-types';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

// MUI components and styles
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, Tabs, Tab, Fade, Checkbox, Typography, Divider,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { green, grey } from '../../styles/colors';

// Context
import { FieldContext } from '../Context/FieldContext';
import { UserContext } from '../Context/UserContext';

// Components
import { Overview } from './Overview';
import { FieldSelection } from '../Shared/Vendors/FieldSelection';
import { SelectReports } from './Ordering/SelectReports';
import { SelectedFieldImage } from '../Shared/Vendors/SelectedFieldImage';
import { SurveyQuestions } from './Ordering/SurveyQuestions';
import { InfoModal } from './InfoModal';
import { SpinningLoader } from '../Shared/SpinningLoader';
import { uavTerms } from './Ordering/vendorTerms';
import { OrderForm } from '../Shared/Vendors/OrderForm';
import { DisplayOrder } from './Ordering/DisplayOrder';
import { OrderComplete } from './Ordering/OrderComplete';
import { terms } from '../Shared/Vendors/TermsAndConditions';
import { ReportDisplay } from './Reports/ReportDisplay';
import { OrderFailAlert } from '../Shared/Vendors/OrderFailAlert';
import { PopupManager } from '../Shared/Popups/PopupManager';

// Functions
import { useWindowDimensions } from '../../utils/dimensions';
import {
  numFormat, dollarFormat, getImage, sleep, round, checkIsMobile,
} from '../../utils/helpers';
import { submit, updateOrder } from './Ordering/submit';
import {
  canPlaceOrder,
  incompleteOrderMessage,
} from '../Shared/Vendors/validateBeforeSubmit';
import poweredBy from '../../images/Ag-Analytics_Full_Black_Powered.svg';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  body: {
    display: 'flex',
    flexGrow: 1,
    overflow: 'auto',
  },
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  appBarSelections: {
    ...theme.appBarSelections,
    margin: '0 32px',
  },
  mobileTabs: { ...theme.mobileTabs, height: 40 },
  footer: theme.footer,
  cart: {
    borderRadius: 4,
    border: `solid ${theme.palette.greys.dark} 2px`,
    margin: 16,
    padding: 16,
    maxWidth: 800,
    color: theme.palette.text.primary,
  },
  termsBox: {
    width: 'auto',
    display: 'block',
    margin: '0 8px',
  },
  terms: {
    height: 120,
    overflowY: 'auto',
    borderRadius: 4,
    border: `solid ${theme.palette.greys.dark} 1px`,
  },
  checkbox: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  termsText: {
    fontColor: theme.palette.text.primary,
    fontWeight: 500,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.secondary,
    },
  },
  orderForm: {
    margin: 4,
  },
  fullLogo: {
    width: '250px',
    height: 'auto',
    marginRightt: '20px',
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginRight: '8px',
  },
  smallLogo: {
    width: '90px',
    height: 'auto',
    marginRight: '4px',
  },
  stickyCart: {
    position: 'sticky',
    top: 0,
    marginBottom: 32,
  },
}));

/**
 * Drone Analysis ordering
 * @param {Function} steHomeSection Sets navbar highlight
 * @returns {JSX} Drone Analysis ordering
 */
export function DroneAnalysis({ setHomeSection }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { height, width } = useWindowDimensions();

  // Pixel height of body section, changes on nav display
  const [bodyHeight, setBodyHeight] = useState(height - 88);

  const [isMobile, setIsMobile] = useState(false);
  // Pixel width to render components with mobile formatting (little larger than actual mobile test)
  const mobileBreakPoint = 880;
  // Breakpoint steps overflow screen width
  const stepSize = 1079;

  const user = useContext(UserContext)[0];
  const fieldData = useContext(FieldContext)[0];

  const [infographicOpen, setInfographicOpen] = useState(false);
  const [step, setStep] = useState(1);

  // Field selection
  const fieldCount = useRef(1);
  const [selectedField, setSelectedField] = useState(null);
  const [initialBounds, setInitialBounds] = useState(null);

  // Track images with field IDs
  const [fieldImages, setFieldImages] = useState({});

  // Cart
  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [flightCost, setFlightCost] = useState(210);

  const [formData, setFormData] = useState({
    orderName: 'UAV Analysis',
    address: '',
    city: '',
    email: '',
    organization: '',
    phone: '',
    state: '',
    userName: '',
    zipCode: '',
    preferredDate: '',
    disableEmailUpdate: false,
    comments: '',
  });
  const [fileData, setFileData] = useState(null);

  // Order Submission
  const nirCost = 175;
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [incompleteMessage, setIncompleteMessage] = useState(null);
  const [nirAdded, setNirAdded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [orderFail, setOrderFail] = useState(false);
  const [orderResponse, setOrderResponse] = useState(null);
  const [orderComplete, setOrderComplete] = useState(false);

  useEffect(() => {
    // Sets Navigation to Services
    if (setHomeSection) {
      setHomeSection(3);
    }
  }, [setHomeSection]);

  useEffect(() => {
    const url = document.URL.split('?');
    const extension = url[1];
    if (extension === 'fromSignIn') {
      url.pop();
      window.history.replaceState({}, document.title, url[0]);
    } else if (document.URL.includes('overview')) {
      // user came here from /overview
      const uavUrl = document.URL.replace('/overview', '');
      window.history.replaceState({}, document.title, uavUrl);
    } else {
      const hasViewed = JSON.parse(sessionStorage.getItem('viewed'));
      if (!hasViewed?.uavInfo) {
        setInfographicOpen(true);
      }
    }
    setIsMobile(checkIsMobile());
  }, []);

  useEffect(() => {
    if (!selectedField) {
      // Select most recently user choosen field from fieldData
      try {
        if (fieldData?.selectedField?.boundary) {
          selectField(fieldData.selectedField);
        } else if (fieldData?.fieldToClaim?.feature) {
          selectField(fieldData?.fieldToClaim);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [fieldData]);

  useEffect(() => {
    // Set height of page body based on which step navigation displayed
    if (isMobile) {
      setBodyHeight(height - 86);
    } else if (width > stepSize) {
      setBodyHeight(height - 181);
    } else if (width > mobileBreakPoint) {
      setBodyHeight(height - 156);
    } else {
      setBodyHeight(height - 141);
    }
  }, [width, height, isMobile]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [step])

  // ------------------------- Field Selection -------------------------\\
  const selectField = (field) => {
    if (field) {
      if (cart?.length >= 5) {
        enqueueSnackbar('If you would like to order services for more than 5 fields, please submit this order and place another with your additional fields');
        return;
      }
      const index = cart.findIndex((entry) => entry.id === field.id);
      if (index >= 0) {
        // Field already in cart. Likely edited boundary
        const updatedField = {
          ...field,
          addedReports: cart[index].addedReports,
          costsPerAcre: cart[index].costsPerAcre,
          reports: cart[index].reports,
          subTotal: cart[index].subTotal,
        };

        setSelectedField(updatedField);
        getFieldImage(field);

        const tempCart = cloneDeep(cart);
        tempCart.splice(index, 1, updatedField);
        setCart(tempCart);
        setCartTotal(calculateTotal(tempCart));
        setFlightCost(calculateFlightCost(tempCart));
      } else {
        // New field
        const updatedField = {
          ...field,
          addedReports: [{ name: 'Standard Report', price: 0 }],
          costsPerAcre: [0],
          name: field.name || `Field - ${fieldCount.current}`,
          reports: ['Standard Report'],
          reportName: 'Flight Cost',
          subTotal: 0,
        };
        fieldCount.current += 1;
        setSelectedField(updatedField);
        getFieldImage(field);

        const updatedCart = [...cart, updatedField];
        setCart(updatedCart);
        setFlightCost(calculateFlightCost(updatedCart));

        // If user selects another field, starts map zoom at around previous field location
        setInitialBounds(field.boundary);

        if (formData.organization === '' && field?.org) {
          setFormData((prev) => ({ ...prev, organization: field.org }));
        }

        if (field?.state) {
          setFormData((prev) => ({ ...prev, state: field.state }));
        }
      }
    }
  };

  const handleMapSelect = (field) => {
    if (field) {
      selectField(field);
      if (cart.length < 5) {
        // Move to select reports page
        setStep(2);
      } else {
        // User has full allotment of fields, so move to submission page
        setStep(3);
      }
    }
  };

  const getFieldImage = async (field) => {
    try {
      // give map time to render
      await sleep(1000);
      const fieldImage = await getImage('field-image');
      setFieldImages((prev) => ({ ...prev, [field.id]: fieldImage }));
    } catch (err) {
      console.error(err);
    }
  };

  // ------------------------- Order interactions -------------------------\\
  const AlwaysScrollToTop = () => {
    const elementRef = useRef();
    useEffect(() => elementRef.current.scrollIntoView());
    return <div ref={elementRef} />;
  };

  const addToOrder = (report) => {
    try {
      const updatedField = {
        ...selectedField,
        addedReports: [...selectedField.addedReports, report],
        costsPerAcre: [...selectedField.costsPerAcre, report.price],
        reports: [...selectedField.reports, report.name],
        subTotal: selectedField.subTotal + getSubTotal(report.price),
      };
      setSelectedField(updatedField);

      const updatingIndex = cart.findIndex((entry) => entry.id === selectedField.id);
      if (updatingIndex >= 0) {
        // updating an existing field
        const tempCart = cloneDeep(cart);
        tempCart.splice(updatingIndex, 1, updatedField);
        setCart(tempCart);
        setCartTotal(calculateTotal(tempCart));
      } else {
        // new field, shouldn't happen
        const updatedCart = [...cart, updatedField];

        setCart(updatedCart);
        setCartTotal(calculateTotal(updatedCart));
        setFlightCost(calculateFlightCost(updatedCart));

        // If user selects another field, starts map zoom at around previous field location
        setInitialBounds(selectedField.boundary);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const calculateFlightCost = (updatedCart) => {
    try {
      const acres = updatedCart.reduce(
        (accumulator, currentValue) => accumulator + currentValue.acres, 0,
      );
      const cost = getFlightCost(acres);
      return cost > 210 ? cost : 210;
    } catch (err) {
      console.error(err);
      return 0;
    }
  };

  const getFlightCost = (acres) => {
    if (acres <= 60) {
      return 210;
    }
    if (acres <= 200) {
      return round(acres * 3.5);
    }
    if (acres <= 350) {
      return round(acres * 3);
    }
    if (acres <= 400) {
      return round(acres * 2.8);
    }
    if (acres <= 500) {
      return round(acres * 2.6);
    }

    return acres * 2.4;
  };

  const calculateTotal = (updatedCart) => {
    const total = updatedCart.reduce(
      (accumulator, currentValue) => accumulator + currentValue.subTotal, 0,
    );
    return total;
  };

  const getSubTotal = (price) => (
    Math.round(((price * selectedField.acres) + Number.EPSILON) * 100) / 100
  );

  const removeFromOrder = (reportToRemove) => {
    const costIndex = selectedField.costsPerAcre.findIndex((cost) => cost === reportToRemove.price);
    const costsPerAcre = { ...selectedField }.costsPerAcre.splice(costIndex, 1);

    const updatedField = {
      ...selectedField,
      costsPerAcre,
      addedReports: selectedField.addedReports.filter((x) => x.name !== reportToRemove.name),
      reports: selectedField.reports.filter((x) => x !== reportToRemove.name),
      subTotal: selectedField.subTotal - getSubTotal(reportToRemove.price),
    };
    setSelectedField(updatedField);
    const updatingIndex = cart.findIndex((entry) => entry.id === selectedField.id);
    const tempCart = cloneDeep(cart);
    tempCart.splice(updatingIndex, 1, updatedField);

    setCart(tempCart);

    if (tempCart.length) {
      setCartTotal(calculateTotal(tempCart));
    } else {
      // Empty cart
      setCartTotal(0);
    }
  };

  // Code to delete field from cart
  const removeFieldFromCart = (deletedFieldId) => {
    // Remove unwanted field
    const updatedCart = cart.filter((x) => x.id !== deletedFieldId);

    // Update cart
    setCart([...updatedCart]);
    setCartTotal(calculateTotal([...updatedCart]));
    setFlightCost(calculateFlightCost(updatedCart));

    // If deleted was currently selected field, remove
    if (selectedField?.id === deletedFieldId) {
      setSelectedField(null);
    }
  };

  // This is only called when the user wants to edit a field already in the cart
  const editFieldInCart = (field) => {
    setSelectedField(field);
    setStep(2);
  };

  const editFieldName = (id, updatedName) => {
    // Edit field name in cart

    // Get index of field to edit
    const fieldIndex = cart.findIndex((x) => x.id === id);

    const cartCopy = [...cart];

    // Remove and return field to update
    const fieldToUpdate = cartCopy.splice(fieldIndex, 1)[0];
    fieldToUpdate.name = updatedName;

    // Add back at correct index
    cartCopy.splice(fieldIndex, 0, fieldToUpdate);
    setCart(cartCopy);
  };

  // ------------------------- Order Submission -----------------------------\\
  // Terms and conditions checkbox control
  const check = () => {
    setTermsAccepted(!termsAccepted);
  };

  const handleSubmit = async () => {
    try {
      setIncompleteMessage(null);
      setOrderFail(false);
      if (canPlaceOrder(cart, formData, termsAccepted)) {
        setLoading(true);
        const response = await submit(
          cart,
          fieldImages,
          formData,
          fileData,
          cartTotal,
          flightCost,
          nirAdded,
          nirCost,
        );

        if (response?.status === 200) {
          setOrderResponse(response.response);
        } else {
          setOrderFail(true);
        }
      } else {
        // Show message to user informing which data is missing
        const { message } = incompleteOrderMessage(
          cart, formData, termsAccepted,
        );

        setIncompleteMessage(message);
      }
    } catch (err) {
      setOrderFail(true);
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateSubmit = async () => {
    try {
      setLoading(true);
      const response = await updateOrder(orderResponse);

      if (response?.status === 200) {
        setOrderComplete(true);
      } else {
        setOrderFail(false);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleUpdate = (value, key, subOrderIndex) => {
    const response = cloneDeep(orderResponse);
    response.subOrderDetails[subOrderIndex].surveyQuestions[key] = value;
    setOrderResponse(response);
  };

  const startNewOrder = () => {
    fieldCount.current = 1;
    setSelectedField(null);
    setFieldImages([]);
    setCart([]);
    setCartTotal(0);
    setFlightCost(0);
    setStep(1);
  };

  // ------------------------- Navigation and footer -------------------------\\
  // Navigation through component
  const steps = () => (
    <Box className={classes.appBar}>
      <Box display="flex">
        <Box
          className={classes.appBarSelections}
          color={step === 0 ? green : grey}
          onClick={() => handleStepChange(0)}
        >
          Overview
        </Box>

        <Box
          className={classes.appBarSelections}
          color={step === 1 ? green : grey}
          onClick={() => handleStepChange(1)}
        >
          Select Field
        </Box>

        <Box
          className={classes.appBarSelections}
          color={step === 2 ? green : grey}
          onClick={() => handleStepChange(2)}
        >
          Select Reports
        </Box>

        <Box
          className={classes.appBarSelections}
          color={step === 3 ? green : grey}
          onClick={() => handleStepChange(3)}
        >
          Submit Order
        </Box>
      </Box>

      <Box
        className={classes.appBarSelections}
        color={step === 4 ? green : grey}
        onClick={() => handleStepChange(4)}
      >
        Completed Reports
      </Box>
    </Box>
  );

  const mobileSteps = () => (
    <Box style={{ width }}>
      <Tabs
        value={step}
        onChange={(e, val) => handleStepChange(val)}
        variant="scrollable"
        scrollButtons="on"
        style={width < mobileBreakPoint ? { maxHeight: 30, minHeight: 30 } : {}}
      >
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Overview"
        />
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Select Field"
        />
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Select Reports"
        />
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Submit Order"
        />
      </Tabs>
    </Box>
  );

  const handleStepChange = (value) => {
    // scroll to top
    window.scrollTo(0, 0);
    setStep(value);
  };

  const handleContinue = () => {
    if (step < 3) {
      setStep(step + 1);
    } else {
      handleSubmit();
    }
  };

  const addAnotherField = () => {
    if (cart?.length >= 5) {
      enqueueSnackbar('If you would like to order services for more than 5 fields, please submit this order and place another with your additional fields');
      return;
    }
    setSelectedField(null);
    setStep(1);
  };

  // Determine text of continue button based on current step
  const footerContinueText = {
    0: 'Select Field',
    1: 'Select Reports',
    2: 'Submit Order',
    3: 'Submit Order',
  };

  const footer = () => (
    <Box className={classes.footer}>
      <Box px={2} display="flex">
        {/* Show back button if not on step one or view results */}
        { step > 0 && (
          <Box bgcolor="#ffffff">
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setStep(step - 1)}
            >
              Back
            </Button>
          </Box>
        )}

        { selectedField?.name && (
          <Box
            mx={1}
            ml={2}
            fontSize={width > mobileBreakPoint ? 14 : 12}
            fontWeight={600}
          >
            <Box color={grey} fontSize={12}>
              FIELD
            </Box>
            {selectedField.name}
          </Box>
        )}

        { selectedField?.acres && (
          <Box
            mx={1}
            fontSize={width > mobileBreakPoint ? 14 : 12}
            fontWeight={600}
          >
            <Box color={grey} fontSize={12}>
              ACRES
            </Box>
            { numFormat(selectedField.acres) }
          </Box>
        )}

        {(cart.length > 0 && width > mobileBreakPoint) && (
          <Box
            mx={2}
            ml={2}
            fontSize={14}
            fontWeight={600}
          >
            <Box color={grey} fontSize={12}>
              SUBTOTAL
            </Box>
            { dollarFormat(cartTotal + flightCost) }
          </Box>
        )}
      </Box>

      <Box display="flex">
        {width > 760 && (
          <Box>
            <img
              src={poweredBy}
              className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
              alt="Powered By Ag-Analytics"
            />
          </Box>
        )}

        { (step === 2 || step === 3) && (
          <Box mx={1} bgcolor="#ffffff">
            <Button
              variant="outlined"
              color="primary"
              onClick={addAnotherField}
            >
              {width > 700 ? 'Add Another Field' : 'Add Field'}
            </Button>
          </Box>
        )}

        <Box mx={1} bgcolor="#ffffff">
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleContinue()}
            disableElevation
          >
            {(width > 700 && step < 3) && 'Continue to '}
            {`${footerContinueText[step]}`}
          </Button>
        </Box>
      </Box>

    </Box>
  );

  // ------------------------- Order Submission -------------------------\\
  const orderSubmissionPage = () => (
    <Box display="flex" flexGrow={1} justifyContent="center" flexWrap="wrap">
      <AlwaysScrollToTop />

      {/* User information form */}
      <Box m={2} p={1} border={2} borderColor="greys.dark" borderRadius={4}>
        <Box display="flex" flexDirection="column" alignItems="center" maxWidth={520}>
          {/* Order Form */}
          <Box className={classes.orderForm}>
            <OrderForm
              formData={formData}
              setFormData={setFormData}
              dateSelection="Optionally, select the date when you would like our agents to collect samples from your field"
              fileData={fileData}
              setFileData={setFileData}
            />
          </Box>

          {/* Terms and Condition Box */}
          <Box className={classes.termsBox}>
            <Box display="flex" alignItems="center">
              <Checkbox
                checked={termsAccepted}
                icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
                checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
                onChange={() => check()}
              />

              <Box className={classes.termsText} onClick={() => check()}>
                I have read and agree to the Terms of Use & Privacy Policy
              </Box>
            </Box>

            <Box pl={1} className={classes.terms}>
              { terms(uavTerms)}
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Cart info */}
      <Box display="flex" flexDirection="column" alignItems="center" maxWidth={width - 20}>
        {/* Cart */}
        <Box className={classes.stickyCart}>
          <Box className={classes.cart}>
            <DisplayOrder
              orderDetails={cart}
              fieldImages={fieldImages}
              cartTotal={cartTotal}
              flightCost={flightCost}
              deleteField={removeFieldFromCart}
              editField={editFieldInCart}
              editFieldName={editFieldName}
              nirAdded={nirAdded}
              setNirAdded={setNirAdded}
              nirCost={nirCost}
            />
          </Box>

          <Box m={1} textAlign="center">
            <Button
              color="primary"
              variant="contained"
              onClick={handleSubmit}
              size="large"
              disableElevation
            >
              Submit No Obligation Order
            </Button>
          </Box>

          { incompleteMessage && (
            <Box my={1} display="flex" justifyContent="center">
              <Box
                p={1}
                style={{ maxWidth: 380 }}
                fontWeight={500}
                border={1}
                borderColor="red"
                borderRadius="borderRadius"
              >
                { incompleteMessage }
              </Box>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );

  const survey = () => (
    <Box p={1} display="flex" flexDirection="column" alignItems="center" flexGrow={1}>
      <Typography align="center" variant="h5">
        Additional Information
      </Typography>
      <Typography align="center">
        For more accurate reports, please fill out and submit the forms below
      </Typography>
      <Divider />
      <Box mt={1} display="flex" justifyContent="center" flexGrow={1}>
        {orderResponse.subOrderDetails.map((subOrder, i) => (
          <SurveyQuestions
            key={subOrder.fieldId}
            subOrder={subOrder}
            index={i}
            handleUpdate={handleUpdate}
          />
        ))}
      </Box>

      <Box p={1}>
        <Button
          color="primary"
          variant="contained"
          onClick={handleUpdateSubmit}
          disableElevation
        >
          Update Order
        </Button>
      </Box>
    </Box>
  );

  return (
    <Box className={classes.root}>
      { width > stepSize ? steps() : mobileSteps() }

      <Box
        id="body"
        className={classes.body}
        height={bodyHeight}
      >
        {/* Step 0 - Overview page */}
        { step === 0 && (
          <>
            <AlwaysScrollToTop />
            <Overview
              setStep={setStep}
            />
          </>
        )}

        {/* Step 1 - Field Selection page */}
        <Fade
          in={step === 1}
          direction="right"
          style={step !== 1 ? { display: 'none' } : {}}
        >
          <Box display="flex" flexGrow={1}>
            <FieldSelection
              mobileBreakPoint={mobileBreakPoint}
              isAuthenticated={user?.isAuthenticated}
              selectField={handleMapSelect}
              selectedField={selectedField}
              initialBounds={initialBounds}
              nextStep={2}
              showMap={step === 1}
            />
          </Box>
        </Fade>

        {/* Step 2 - Select Reports page */}
        { step === 2 && (
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <AlwaysScrollToTop />

            {/* Availale reports */}
            <SelectReports
              cart={cart}
              addToOrder={addToOrder}
              removeFromOrder={removeFromOrder}
              handleStepChange={handleStepChange}
              field={selectedField}
            />

            {/* Cart */}
            <Box
              display="flex"
              flexDirection="column"
              maxWidth={width - 20}
            >
              <Box className={classes.stickyCart}>
                <Box className={classes.cart}>
                  <DisplayOrder
                    orderDetails={cart}
                    fieldImages={fieldImages}
                    cartTotal={cartTotal}
                    flightCost={flightCost}
                    deleteField={removeFieldFromCart}
                    editField={editFieldInCart}
                    editFieldName={editFieldName}
                    nirAdded={nirAdded}
                    setNirAdded={setNirAdded}
                    nirCost={nirCost}
                  />
                </Box>

                {/* Add button to continue */}
                <Box
                  mx={1}
                  bgcolor="#ffffff"
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                  }}
                >
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => handleContinue()}
                    disableElevation
                  >
                    Continue to Submit Order
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>
        )}

        {/* Step 3 - Submit Order page */}
        { step === 3 && (
          orderComplete ? (
            <OrderComplete
              order={cart}
              fieldImages={fieldImages}
              cartTotal={cartTotal}
              flightCost={flightCost}
              nirCost={nirCost}
              nirAdded={nirAdded}
              startNewOrder={startNewOrder}
            />
          ) : orderResponse?.subOrderDetails ? (
            survey()
          ) : (
            orderSubmissionPage()
          )
        )}

        {/* Step 4 - Completed Reports page */}
        { step === 4 && (
          <ReportDisplay />
        )}
      </Box>

      { (!orderResponse && step < 4 && width > 520 && !isMobile) && footer() }

      {infographicOpen && (
        <InfoModal
          open={infographicOpen}
          setOpen={setInfographicOpen}
          setStep={setStep}
        />
      )}

      { selectedField?.boundary && (
        <Box
          style={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              right: '-5000px',
              top: '5000px',
            }}
          >
            <SelectedFieldImage field={selectedField} />
          </Box>
        </Box>
      )}

      { loading && <SpinningLoader />}

      { orderFail && (
        <OrderFailAlert
          open={orderFail}
          setOpen={setOrderFail}
        />
      )}

      {/* 12/01/2021 - We want to remove popups from services */}
      {/* <PopupManager
        acreageReporting
        soilTesting
        waterManagement
      /> */}
    </Box>
  );
}

DroneAnalysis.propTypes = {
  setHomeSection: PropTypes.func.isRequired,
};

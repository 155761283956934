import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

export const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <Box
      flexGrow={1}
      role="tabpanel"
      hidden={value !== index}
      id={`soil-tabpanel-${index}`}
      aria-labelledby={`soil-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box flexGrow={1}>
          { children }
        </Box>
      )}
    </Box>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { difference } from "d3-array";
import { COLORS, SINGLE_DECIMAL_FORMAT } from "../../../../../constants";
import ThemedChip from "../../../../Helpers/ThemedChip";

const useStyles = makeStyles(() => ({
  filterRow: {
    display: "inline",
    flexDirection: "row",
    alignItems: "center",
    color: COLORS.text,
    fontSize: 14,
    lineHeight: "19px",
    margin: "20px 0",
  },
  text: {
    marginRight: 20,
    textTransform: "uppercase",
  },
}));

/**
 * Renders the filtering row in the farm overview table
 * @param {array} options array of objects with option information
 * @param {function} setOptions set state function
 */

const FilterRow = ({ options, setOptions, showDetails=false }) => {
  const classes = useStyles();

  const numericOptions = options
    .filter((d) => d.numeric)
    .filter(
      (option) =>
        !option.range.every(
          (d, i) =>
            SINGLE_DECIMAL_FORMAT(d) ===
            SINGLE_DECIMAL_FORMAT(option.filteredRange[i])
        )
    );
  const nonNumericOptions = options
    .filter((d) => !d.numeric)
    .filter((option) => !!difference(option.range, option.filteredRange).size);

  const isRowVisible = numericOptions.length + nonNumericOptions.length > 0;

  const onNumericOptionChange = (deletedOption) => () =>
    setOptions(
      options.map((option) => {
        if (option.label === deletedOption.label) {
          return {
            ...option,
            filteredRange: option.range,
          };
        }
        return option;
      })
    );

  const onNonNumericOptionChange = (deletedOption, label) => () =>
    setOptions(
      options.map((option) => {
        if (option.label === deletedOption.label) {
          const newFilteredRange = option.filteredRange.filter(
            (d) => d !== label
          );
          return {
            ...option,
            filteredRange: newFilteredRange.length
              ? newFilteredRange
              : option.range,
          };
        }
        return option;
      })
    );

  return (
    <>
      {!showDetails && isRowVisible && (
        <div className={classes.filterRow}>
          <div className={classes.text}>Filters Applied</div>
          {nonNumericOptions.map((option) =>
            option.filteredRange.map((d, i) => (
              <ThemedChip
                key={`numeric-option-for-${option.label}-${i}`}
                label={d}
                onDelete={onNonNumericOptionChange(option, d)}
              />
            ))
          )}
          {numericOptions.map((option) => (
            <ThemedChip
              key={`numeric-option-for-${option.label}`}
              label={`${option.label} Range: ${SINGLE_DECIMAL_FORMAT(
                option.filteredRange[0]
              )} to ${SINGLE_DECIMAL_FORMAT(option.filteredRange[1])}`}
              onDelete={onNumericOptionChange(option)}
            />
          ))}
        </div>
      )}
    </>
  );
};

export default FilterRow;

FilterRow.propTypes = {
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
};

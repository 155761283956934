import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Button, Link, Divider, LinearProgress } from '@material-ui/core/';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Endpoints } from '../../constants/Endpoints'
import ksu_logo from '../../images/ksu_logo.jpg'
import logo from '../../images/Logo.png'
import { Page, Text, View, Document, StyleSheet, Font, Image, PDFDownloadLink } from '@react-pdf/renderer';

Font.register({ family: 'Roboto', src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf' });

const styles = StyleSheet.create({
  body: {
    paddingTop: 15,
    paddingBottom: 65,
    paddingHorizontal: 15,
  },
  title: {
    fontSize: 18,
    fontFamily: 'Roboto',
    color: '#1b1b1b',
    marginLeft: '25px',
  },
  text: {
    fontSize: 12,
    fontFamily: 'Roboto',
    color: '#424242',
  },
  date: {
    fontSize: 12,
    fontFamily: 'Roboto',
    color: '#757575',
  },
  logo: {
    width: 50,
  },
  page: {
    flexDirection: 'row',
    backgroundColor: '#E4E4E4',
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '10px 20px',
  },
  titleSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
    margin: '20px',
  },
  imageSection: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    alignItems: 'center',
  }
})



const useStyles = makeStyles(theme => ({
  icon: {
    margin: '10px 0',
    color: '#616161',
    '&:hover': {
       color: '#1565c0',
       cursor: 'pointer'
    },
  },
  iconBox: {
    position: 'sticky',
    top: 100,
    bottom: 20,
    height: '150px',
  },
  logo: {
    width: '60px',
    margin: '0 30px',
  }
}))

const title = 'Ag-Analytics and Kansas State University Announce Research Data Partnership'
const date = 'Ag-Analytics June, 23, 2020'
const paragraphs = [
  `Ag-Analytics® and Associate Professors Mykel Taylor and Nathan Hendricks of Kansas State University’s Department of Agricultural Economics are pleased to announce the establishment of a research partnership. Using precision agriculture data, the Kansas State University research team will develop farm management strategies that optimize economic outcomes.`,

  `“Convenient and relevant analysis of precision agricultural data is key to maintaining the profitability and sustainability of today’s farms,” says Joshua Woodard, CEO and founder of Ag-Analytics®. “This partnership helps the experts at Kansas State University develop farm management strategies that will positively impact farms across the nation.”`,

  `Ag-Analytics® is a farm management platform that specializes in data collection, management, and analysis of precision agriculture data. Through innovative tools and services for farmers, crop consultants, and more, Ag-Analytics® is working to help users mitigate risks, decrease environmental impacts, improve yields, and increase profits by making better-informed farm management decisions.`,

   `Farmers can easily sign up to participate at https://app.profit.ag/KSU. All data gathered will remain strictly confidential, and personally identifiable information will be stripped from the data sets. Sign-up is completely free, and farmers who sign-up will receive a free Ag-Analytics® account, with access to high-resolution satellite NDVI imagery, historical and current weather conditions, insurance estimates, and much more.`
]

const KsuPdf = () => (
  <Document>
    <Page wrap size="A4" style={styles.body}>

      <View fixed style={styles.titleSection}>
        <Image style={styles.logo} src={logo}/>
        <Image style={styles.logo} src={ksu_logo}/>
        <View>
          <Text style={styles.title} wrap={true} fixed>Ag-Analytics and Kansas State University</Text>
          <Text style={styles.title} wrap={true} fixed>Announce Research Data Partnership</Text>
        </View>
      </View>

      <View style={{marginLeft: '20px'}}>
        <Text style={styles.date}>
          {date}
        </Text>
      </View>

      {paragraphs.map((content, i) =>
        <View style={styles.section}>
          <Text style={styles.text}>
            {content}
          </Text>
        </View>
      )}

    </Page>
  </Document>
)


export const Ksu = () => {
  const classes = useStyles()

  return (
    <Container maxWidth='md'>
      <Box display='flex'>
        <Box>
          <Box mt={2}>
            <Box display='flex' flexWrap='wrap'>
              <Typography variant="h4">{title}</Typography>
            </Box>
          </Box>
          <Box mt={1} display="flex" justifyContent='space-between' alignItems='center'>
            <Typography style={{color: '#757575'}}>{date}</Typography>
            {/* <Button variant='outlined' style={{width: '145px', height: '40px'}}>
              <PDFDownloadLink document={<KsuPdf />} fileName="AgAnalytics-Announcement.pdf" style={{color: '#455a64', textDecoration: 'none'}}>
                {({ blob, url, loading, error }) => (loading ? <LinearProgress color="secondary" style={{width: '100px'}} /> : 'Download PDF')}
              </PDFDownloadLink>
            </Button> */}
          </Box>
          {paragraphs.map((text, i) =>
            <Box my={1} display='flex'>
              <Typography variant='body1'>
                {text}
              </Typography>
            </Box>
          )}
        </Box>
        <Box className={classes.iconBox} m={3} mt={3} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' boxShadow={1} borderRadius={16}>
          <Link href={`https://twitter.com/share?url=${Endpoints.BASEURL}/press/ksu`} target="_blank"><TwitterIcon className={classes.icon} /></Link>
          <Link href={`https://www.facebook.com/sharer/sharer.php?u=${Endpoints.BASEURL}/press/ksu`} target="_blank"><FacebookIcon className={classes.icon}/></Link>
          <Link href={`https://www.linkedin.com/shareArticle?mini=true&url=${Endpoints.BASEURL}/press/ksu`} target="_blank"><LinkedInIcon  className={classes.icon}/></Link>
        </Box>
      </Box>
    </Container>
  )
}

import React from 'react';
import { Box, Typography, Link } from '@material-ui/core';
import { Endpoints } from '../../constants/Endpoints'
import deere from '../../images/deere_clear.png'
import climate from '../../images/climate_clear.png'
import cnh from '../../images/cnh_clear.png'

const deereLink = `${Endpoints.BASEURL}${Endpoints.DEEREINTEGRATE}`
const climateLink = `${Endpoints.BASEURL}${Endpoints.CLIMATEINTEGRATE}`
const cnhLink = `${Endpoints.BASEURL}${Endpoints.CNHINTEGRATE}`

const integrationLink = `${Endpoints.HOME}/app/integrate`

export const connect = () => (
  <Box display="flex" flexWrap="wrap" justifyContent='center' alignItems='center' p={1} mx={1} >
    <Typography variant='h6' style={{marginLeft: '25px'}}>To seamlessly load in your field boundaries, you can integrate with:</Typography>
    <Box display='flex' flexWrap="wrap" >
      <Link href={integrationLink} target='_blank' rel="noopener"><img style={{width:'150px', margin: '0 25px'}} src={deere} alt='deere'/></Link>
      <Link href={integrationLink} target='_blank' rel="noopener"><img style={{width:'150px', margin: '0 25px'}} src={climate} alt='climate'/></Link>
      <Link href={integrationLink} target='_blank' rel="noopener"><img style={{width:'150px', margin: '0 25px'}} src={cnh} alt='cnh'/></Link>
    </Box>
  </Box>
)

export const connectPrecision = () => (
  <Box display="flex" flexWrap="wrap" justifyContent='center' alignItems='center' p={1} boxShadow={1} style={{backgroundColor: '#ffffff'}}>
    <Box style={{width: '300px'}}>
      <Typography variant='h6'>CONNECT PRECISION AG</Typography>
      <Typography variant='body1'>Connect you preferred Precision Ag system for advanced insights & features.</Typography>
    </Box>

    <Box display='flex' flexWrap="wrap" >
      <Link href={integrationLink} target='_blank' rel="noopener"><img style={{width:'150px', margin: '0 25px'}} src={deere} alt='deere'/></Link>
      <Link href={integrationLink} target='_blank' rel="noopener"><img style={{width:'150px', margin: '0 25px'}} src={climate} alt='climate'/></Link>
      <Link href={integrationLink} target='_blank' rel="noopener"><img style={{width:'150px', margin: '0 25px'}} src={cnh} alt='cnh'/></Link>
    </Box>
  </Box>
)

export const integrationLinks = () => (
  <Box
    p={1}
    display="flex"
    flexWrap="wrap"
    justifyContent='center'
    alignItems='center'
  >
    <Box display='flex' flexWrap="wrap" >
      <Link href={integrationLink}><img style={{width:'150px', margin: '0 25px'}} src={deere} alt='deere'/></Link>
      <Link href={integrationLink}><img style={{width:'150px', margin: '0 25px'}} src={climate} alt='climate'/></Link>
      <Link href={integrationLink}><img style={{width:'150px', margin: '0 25px'}} src={cnh} alt='cnh'/></Link>
    </Box>
  </Box>
)

import * as L from 'leaflet';
import * as wkt from 'terraformer-wkt-parser';
import * as turf from '@turf/turf';
import { getPoly, getMultiPoly, getPolyFromMulti }from '../../../utils/GeoHelper';
import { getStateAndCounty } from '../../../utils/dataFetchers';

/**
 * Takes field data from database and adds additional needed information.
 * @param  {Object} data Field info returned from controller
 * @param  {Number} orgId ID of field's organization
 * @param  {String} orgName Name of field's organization
 * @param  {Number} farmId ID of field's farm
 * @param  {String} farmName Name of field's farm
 * @param  {String} fieldName Name of field
 * @return {Object} Field with all data needed in other components
 */
export const cleanField = async (data, orgId, orgName, farmId, farmName, fieldName) => {

  if (data === undefined) {
    return {
      id: '',
      name: '',
      orgId: '',
      org: '',
      farmId: '',
      farm: '',
      state: '',
      county: '',
      acres: 0,
      latitude: 0,
      longitude: 0,
      coordinates: [{ type: '', coordinates: [] }],
      points: [],
      source: '',
      boundaryId: ''
    };
  }

  try {
    // extract and clean coordinates from boundary
    const coordinates = data.Shape[0] === 'P'
      ? { type: 'Polygon', coordinates: getPoly(data.Shape) }
      : { type: 'MultiPolygon', coordinates: getMultiPoly(data.Shape) };

    // get points, previously for centroid. if multi, get from first poly
    // now using turf to get centroid so shouldnt be used anywhere, but leaving for now
    const points = data.Shape[0] === 'P'
      ? getPoly(data.Shape)
      : getPolyFromMulti(data.Shape);
    const geojson = L.GeoJSON.geometryToLayer(wkt.parse(data.Shape)).toGeoJSON();

    const featureCollection = {
      type: 'FeatureCollection',
      features: [geojson],
    };

    const centroid = turf.centroid(featureCollection);
    const centerPoints = centroid.geometry.coordinates;
    const [state, county] = await getStateAndCounty(centerPoints[1], centerPoints[0])
    // if acres are null (all climate fields) calculate from boundary coordinates
    const acres = data.Area !== null ? data.Area : turf.convertArea(turf.area(featureCollection), 'meters', 'acres');

    const field = {
      acres,
      centroid,
      county,
      coordinates,
      farmId,
      geojson,
      orgId,
      points,
      state,
      boundary: JSON.stringify(geojson),
      boundaryId: data.BoundaryID,
      id: data.FieldID,
      farm: farmName,
      latitude: centerPoints[1],
      longitude: centerPoints[0],
      name: fieldName,
      org: orgName,
      source: data.Source,
      shape: data.Shape,
    };
    return field;
  } catch (err) {
    console.log(`problem cleaning field ${err}`, data);
    return {
      id: '',
      name: '',
      orgId: '',
      org: '',
      farmId: '',
      farm: '',
      state: '',
      county: '',
      acres: 0,
      latitude: 0,
      longitude: 0,
      coordinates: [{ type: '', coordinates: [] }],
      points: [],
      source: '',
      boundaryId: ''
    };
  }
};

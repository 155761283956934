import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Modal, Button, Fade } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blackText } from '../../../styles/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    margin: 4,
    padding: 16,
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    fontSize: 16,
    color: blackText,
  },
  close: {
    position: 'absolute',
    top: 6,
    right: 6,
    fontSize: 18,
    color: theme.palette.primary.main,
    '&:hover': {
        cursor: 'pointer',
    },
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
  }
}));

function getModalStyle() {
  const top = 20;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    height: 'auto',
  };
}

/**
 * Prompt to user with no operations to generate from template or
 * integrate precision ag data
 * @param {Bool} open Open modal
 * @param {Function} setOpen Sets modal open to true or false
 * @param {Function} start Closes modal and opens template mode
 * @param {Function} openIntegrate Closes modal and open Integration modal
 */
export function TemplatePrompt({open, setOpen, start, openIntegrate, deereEtlByField, source}) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);

  const handleClose = () => {
    setOpen(false)
  }

  const handleStart = () => {
    setOpen(false)
    start()
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Fade in={open} timeout={500}>
        <Box
          className={classes.root}
          style={modalStyle}
          borderRadius="borderRadius"
          boxShadow={2}
          color={blackText}
        >
          <HighlightOffIcon
            className={classes.close}
            onClick={() => handleClose()}
          />
          <Box my={1} fontWeight={600}>
            To begin, choose to:
          </Box>
          <Box className={classes.options} mb={1}>
            <Box my={1}>
              Generate an operation from a predefined, editable template
            </Box>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => handleStart()}
            >
              Generate Operations From Template
            </Button>
          </Box>

          <Box className={classes.options} mb={1}>
            <Box my={1}>
              Integrate your precision ag data
            </Box>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => openIntegrate()}
            >
              Integrate Precision Ag Data
            </Button>
          </Box>

          {
            source !== undefined && source.toUpperCase() === 'DEERE' && ( 
              <Box className={classes.options}>
                <Box my={1}>
                  Missing Field Operations?
                </Box>
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => deereEtlByField()}
                >
                  Reload Field Data
                </Button>
              </Box>
            )
          }
          
        </Box>
      </Fade>

    </Modal>
  )
}

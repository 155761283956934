import React, { useState, useEffect } from 'react';
import { Snackbar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { GetSoilTesting } from '../ServiceLinks';
import { sleep } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    backgroundColor: '#ffffff',
  },
  close: {
    zIndex: 1005,
    color: theme.palette.greys.main,
    position: 'absolute',
    top: '4px',
    right: '4px',
    fontSize: 20,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const SoilTestingPopUp = () => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  useEffect(() => {
    let hasSeen = false;

    try {
      const viewed = sessionStorage.getItem('viewed');
      const userHasSeen = JSON.parse(viewed);
      hasSeen = userHasSeen?.soiltesting;
    } catch (err) {
      console.error(err);
    }
    if (!hasSeen) {
      sessionStorage.setItem('viewed', JSON.stringify({ soilTesting: true }));
      beginOpenCount();
    }
  }, []);

  const beginOpenCount = async () => {
    await sleep(4000);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      key="bottomright"
      style={{ marginBottom: 46, zIndex: 1001 }}
    >
      <Box className={classes.root} boxShadow={5}>
        <HighlightOffIcon id="close_popup_soil" className={classes.close} onClick={handleClose} />
        <GetSoilTesting />
      </Box>
    </Snackbar>
  );
};

/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Divider, Container,
} from '@material-ui/core/';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Endpoints } from '../../constants/Endpoints';
import { PromoVideo } from './Ordering/PromoVideo';
import infographic from '../../images/uav_infographic.png';

const useStyles = makeStyles((theme) => ({
  dot: {
    fontSize: 12,
    marginRight: 4,
    color: theme.palette.greys.dark,
  },
  link: {
    whiteSpace: 'nowrap',
    color: '#007cba',
    '&:hover': {
      cursor: 'pointer',
      color: '#2f5597',
    },
  },
  options: {
    height: 180,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 8,
    borderRadius: 4,
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      cursor: 'pointer',
      border: `2px solid ${theme.palette.primary.dark}`,
    },

  },
  price: {
    margin: 4,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
    width: 140,
  },
  acres: {
    padding: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: 18,
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
}));

/**
 * UAV testing overview
 * @returns {JSX} UAV overview
 */
export function Overview({ setStep }) {
  const classes = useStyles();

  const prices = [
    { acres: '0-60 acres', price: '$210' },
    { acres: '60-200 acres', price: '$3.5' },
    { acres: '201-350 acres', price: '$3' },
    { acres: '351-400 acres', price: '$2.8' },
    { acres: '401-500 acres', price: '$2.6' },
    { acres: '500+ acres', price: '$2.4' },
  ];

  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="column">

        <Typography align="center" style={{ fontWeight: 500, fontSize: '2.3rem' }}>
          On-Demand UAV Service
        </Typography>

        <Box flexGrow={1} display="flex" justifyContent="space-around" alignItems="center" flexWrap="wrap">
          <Box p={1} display="flex" flexDirection="column" width={520} minWidth={380}>
            <Typography align="center" variant="h5">
              How To Order
            </Typography>
            <Divider />
            <Box mt={1}>
              <Typography style={{ fontSize: '1.1rem' }}>
                Order fast, high-quality imagery and accurate diagnostics reports. Begin by selecting your field boundary on
                the mapping tool. You will recieve a standard flight report for each field in order.
                Next, choose additional reports to get even more in depth data on your field.
                Finally, complete and place your order by filling in your contact information. After completion of the service,
                all results, maps, and reports will be distributed to the customer through the Ag-Analytics
                site. For any other questions, please
                {' '}
                <a className={classes.link} target="_blank" rel="noreferrer" href="https://www.analytics.ag/contact">contact us</a>
                {' '}
                at support@analytics.ag.
              </Typography>
            </Box>
          </Box>

          {/* <Box mt={1} p={1} display="flex" justifyContent="center">
            <PromoVideo />
          </Box> */}

          <Box p={1} display="flex" border={1} borderColor="greys.main" borderRadius={4} width={600}>
            <img src={infographic} alt="infographic" height="auto" width="100%" />
          </Box>
        </Box>

        <Box flexGrow={1} display="flex" justifyContent="space-around" alignItems="center" flexWrap="wrap">
          <Box p={1} display="flex" flexDirection="column" minWidth={380}>
            <Typography align="center" variant="h5">
              Reports
            </Typography>
            <Divider />

            <Box display="flex" flexWrap="wrap" justifyContent="center">
              <Box m={0.5} maxWidth="380px">
                <Typography align="center" variant="h6">
                  Standard Flight and Report
                </Typography>
                <Box className={classes.options} onClick={() => setStep(1)}>
                  <Typography style={{ fontSize: '1.1rem' }}>
                    The Standard Report includes High Resolution RGB, Elevation and VARI Plant Health maps to spot differences and
                    locate problem areas in your field. Interactive 3D maps to help you gain a better understanding of your field.
                  </Typography>
                </Box>
              </Box>

              <Box m={0.5} maxWidth="380px" fontSize="1.1rem">
                <Typography align="center" variant="h6">
                  Additional Services
                </Typography>
                <Box className={classes.options} onClick={() => setStep(1)}>
                  <Box my={1} display="flex">
                    <FiberManualRecordIcon className={classes.dot} style={{ marginTop: 8 }} />
                    <span>Request NIR Band* for an added surcharge</span>
                  </Box>
                  <Box my={1} display="flex" alignItems="center">
                    <FiberManualRecordIcon className={classes.dot} />
                    LIDAR (may entail an extra fee)
                  </Box>
                  <Box my={1} display="flex" alignItems="center">
                    <FiberManualRecordIcon className={classes.dot} />
                    Other imaging services
                  </Box>
                  <Typography variant='subtitle2' style={{ marginTop: 18 }}>*NIR may add 1-2 days to flight time</Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box p={1} display="flex" flexDirection="column" width={520} minWidth={380}>
            <Typography align="center" variant="h5">
              Flight Pricing
            </Typography>
            <Divider />

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              {prices.map((x, i) => (
                <Box key={x.acres} className={classes.price}>
                  <Box className={classes.acres}>
                    {x.acres}
                  </Box>

                  <Typography align="center" style={{ fontWeight: 600, fontSize: 20, padding: '8px 4px' }}>
                    {x.price}
                    {i !== 0 && <span style={{ fontWeight: 500, fontSize: 14 }}>/Acre</span>}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box mt={1}>
              <Typography style={{ fontSize: '1.1rem' }}>
                For pricing on flights over 500 acres, discounted pricing for frequently scheduled flights and weekly
                subscription services, or if you have requests regarding hardware or sensors
                {' '}
                <a className={classes.link} target="_blank" rel="noreferrer" href="https://www.analytics.ag/contact">contact us</a>
                {' '}
                at support@analytics.ag.
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box m={1} display="flex" flexWrap="wrap" alignItems="center" justifyContent="space-around">


          <Box p={1} display="flex" flexDirection="column" width={520} minWidth={380}>
            <Typography align="center" variant="h5">
              Results
            </Typography>
            <Divider />

            <Box mt={1} display="flex" justifyContent="center">
              <Box>
                <Typography style={{ fontSize: '1.1rem' }}>
                  Upon completion, the customer will receive results as interactive maps, PDF reports, and downloadable files.
                </Typography>

                <Typography style={{ fontSize: '1.1rem' }}>
                  When your results are ready, you may view them at
                  {' '}
                  <a className={classes.link} target="_blank" rel="noreferrer" href={`${Endpoints.HOME}/app/uav/results`}>app.profit.ag/app/uav/results</a>
                </Typography>
              </Box>

            </Box>
          </Box>
        </Box>

        <Typography align="center" color="textPrimary" variant="subtitle2">
          *Please note this service is currently only available in the contiental U.S.
        </Typography>

      </Box>
    </Container>

  );
}

Overview.propTypes = {
  setStep: PropTypes.func.isRequired,
};

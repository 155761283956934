import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core'
import { drainageResponse } from '../Files/DrainageResponse'
import { order } from '../Files/DrainageOrder'
import ReactJson from 'react-json-view'

export function Drainage({ }) {
  return (
    <Box>
      <Box fontSize={16} fontWeight={500}>
        <Box>JSON Structure of Drainage Service Results:</Box>
        <Box mt={2}>
          Find JSON structure document <a href="https://drive.google.com/uc?export=download&id=13DoF09xmzw3CEIWNX5pa6nmz-JrjDgaw" download>here</a>.
        </Box>
        <Box mt={2}>
          Below is the JSON structure that will be sent to DIGS after customer has placed order. Order and Suborder information which needs to be in results can be found from order request
          <Box>
          <ReactJson
            src={order}
            theme="monokai"
            name={false}
            indentWidth={2}
            collapseStringsAfterLength={50}
          />
          </Box>
        </Box>
        <Box mt={2} width="100%">
          <Box>Result JSON Schema</Box>                    
          <ReactJson
            src={drainageResponse}
            theme="monokai"
            name={false}
            indentWidth={2}
            collapseStringsAfterLength={50}
          />
        </Box>
      </Box>
    </Box>
  )
}
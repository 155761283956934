import React, { useEffect } from 'react';

import {
	Box,
	Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
	container:{
		display: "flex",
		flexDirection: "column",
		alignItems: "center"
	}
}))

export const NotFound = ({setPageNotFound}) => {
	const classes = useStyles()

	useEffect(() => {
		if (setPageNotFound) {
			setPageNotFound(true);
		}
	}, [setPageNotFound])

	return (
		<Box className={classes.container}>
			<Box
				m={2}
			>
				<Typography
					variant="h2"
					style={{ fontWeight: 400 }}
				>
					Page Not Found
				</Typography>
			</Box>
			<Box>
				<Typography variant="subtitle1">
					We are sorry but the page you are looking for does not exist.
				</Typography>
			</Box>
			{/* <Box>
				<Typography variant="p">
					You will be redirected in {countdown} seconds.
				</Typography>
			</Box> */}
		</Box>
	)
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Typography } from "@material-ui/core";
import HelpOutlineOutlinedIcon from "@material-ui/icons/HelpOutlineOutlined";
import { grey, green } from '../../../styles/colors'

const useStyles = makeStyles((theme) => ({
  link: theme.greenLink,
  helpBox: theme.helpBox,
}));


export const PriceToolInfo = ({openPriceTool, opType}) => {
  const classes = useStyles();

  return (
    <Box className={classes.helpBox}>
      <HelpOutlineOutlinedIcon fontSize="small" />
      <Box ml={1}>
        {'Notice a problem with these prices? Head over to our '}
        <span
          className={classes.link}
          onClick={() => openPriceTool(opType)}>
          Price Converter
        </span>
        {' to update them across all your fields.'}
      </Box>
    </Box>
  )
}

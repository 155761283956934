import React from "react";

export const rotateIcon = `<svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="Icons" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Two-Tone" transform="translate(-236.000000, -2724.000000)">
            <g id="Image" transform="translate(100.000000, 2626.000000)">
                <g id="Two-Tone-/-Image-/-crop_rotate" transform="translate(136.000000, 98.000000)">
                    <g>
                        <polygon id="Path" points="0 0 24 0 24 24 0 24"></polygon>
                        <path d="M11.95,24 C12.18,24 12.39,23.98 12.61,23.97 L8.8,20.15 L7.47,21.49 C4.2,19.93 1.86,16.76 1.5,13 L0,13 C0.51,19.16 5.66,24 11.95,24 Z M12.05,0 C11.82,0 11.61,0.02 11.39,0.04 L15.2,3.85 L16.53,2.52 C19.8,4.07 22.14,7.24 22.5,11 L24,11 C23.49,4.84 18.34,0 12.05,0 Z M16,6 L10,6 L10,8 L16,8 L16,14 L18,14 L18,8 C18,6.89 17.1,6 16,6 Z M18,18 L20,18 L20,16 L8,16 L8,4 L6,4 L6,6 L4,6 L4,8 L6,8 L6,16 C6,17.1 6.89,18 8,18 L16,18 L16,20 L18,20 L18,18 Z" id="🔹-Primary-Color" fill="#1D1D1D"></path>
                    </g>
                </g>
            </g>
        </g>
    </g>
</svg>`

export const horizontalIcon = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="24px" height="24px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
  <polyline fill="none" stroke="#000000" stroke-width="2" stroke-linejoin="bevel" stroke-miterlimit="10" points="54.083,51
  	63.083,42 54.083,33 "/>
  <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="63" y1="42" x2="9" y2="42"/>
  <polyline fill="none" stroke="#000000" stroke-width="2" stroke-linejoin="bevel" stroke-miterlimit="10" points="10.083,13
  	1.083,22 10.083,31 "/>
  <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="1" y1="22" x2="55" y2="22"/>
</svg>`


export const verticalIcon = `<svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
	 width="24px" height="24px" viewBox="0 0 64 64" enable-background="new 0 0 64 64" xml:space="preserve">
  <polyline fill="none" stroke="#000000" stroke-width="2" stroke-linejoin="bevel" stroke-miterlimit="10" points="51.083,10
  	42.083,1 33.083,10 "/>
  <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="42.083" y1="1" x2="42" y2="55"/>
  <polyline fill="none" stroke="#000000" stroke-width="2" stroke-linejoin="bevel" stroke-miterlimit="10" points="13.083,54
  	22.083,63 31.083,54 "/>
  <line fill="none" stroke="#000000" stroke-width="2" stroke-miterlimit="10" x1="22.083" y1="63" x2="22" y2="9"/>
</svg>`

export const acresIcon = `<div style='font-size: 12px; fontWeight:500; color: #424242;'>Acres</div>`


export const opacityIcon = `<svg version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 411.947 411.947" style="enable-background:new 0 0 411.947 411.947;" xml:space="preserve" width="16px" height="16px">
    <g>
        <g>
            <path d="M326.72,120.533L205.973,0L85.227,120.533c-33.28,33.28-49.92,77.653-49.92,120.32s16.64,87.68,49.92,120.96
                c33.28,33.28,77.013,50.133,120.747,50.133s87.467-16.853,120.747-50.133c33.28-33.28,49.92-78.293,49.92-120.96
                S360,153.813,326.72,120.533z M77.973,248.64c0.213-42.667,13.227-69.867,37.547-93.973l90.453-92.373l90.453,93.333
                c24.32,24.213,37.333,50.347,37.547,93.013H77.973z"/>
        </g>
    </g>
</svg>`
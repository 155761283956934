import React, { useEffect, useState } from "react";
import { makeStyles, Grid, Paper, Box, Typography } from "@material-ui/core";
import { useDimensions } from "../../../utils";
import { COLORS } from "../../../constants";
import { ImageModal } from "../../Shared/ImageModal"
import ProfitLayersGen from "../../../images/FarmReport/Profit layer generation.PNG"
import FarmBreakdown from "../../../images/FarmReport/farm breakdown.PNG";
import OverviewTable from "../../../images/FarmReport/overview table.PNG";
import Analytics from "../../../images/FarmReport/analytics.png";
import DisaggregateByVariety from "../../../images/FarmReport/disaggregate by variety.png"

const useStyles = makeStyles((theme) => ({
  container: ({ mobileView }) => ({
    display: "flex",
    flex: `1 1 100%`,
    flexDirection: "column",
    padding: 25,
    marginLeft: mobileView ? 0 : 300,
    maxWidth: 1000,
  }),
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  title: {
    color: COLORS.green,
    fontSize: 32,
    fontWeight: "normal",
    lineHeight: 1.34,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    height: "250px"
  },
}));

const FarmReportOverview = ({
  mobileView
}) => {
  const classes = useStyles({ mobileView });

  const { width, height } = useDimensions();    

  const [open, setOpen] = useState(false)
  const [source, setSource] = useState("")

  const handleImage = (src) => {
    setOpen(true)
    setSource(src)
  }

  return (
    <div style={{ height: height - 195, overflow: "auto" }}>
      <div className={classes.container}>
        <Box mb={1}><Typography style={{fontSize:24, fontWeight:"bolder", textAlign:"center"}}>Farm Report Overview</Typography></Box>
        <Grid container spacing={3}>
          <Grid item xs={6}>
            <Box onClick={(e) => handleImage(ProfitLayersGen)}>
              <img src={ProfitLayersGen} alt="Profit Layers Generation" height="100%" width="100%" />
            </Box>            
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.paper}><Typography style={{fontSize:20, fontWeight:"bold", textAlign:"justify"}}>Generate ProfitLayers for multiple fields in a single go.</Typography></Box>
          </Grid>

          <Grid item xs={6}>
            <Box className={classes.paper}>
              <Typography style={{fontSize:20, fontWeight:"bold", textAlign:"justify"}}>
                View farm breakdown to analyze crop vs fertilizer and also compare profits generated for all fields over years.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box onClick={(e) => handleImage(FarmBreakdown)}>
              <img src={FarmBreakdown} alt="Farm Breakdown" height="100%" width="100%" />
            </Box>            
          </Grid>


          <Grid item xs={6}>
            <Box onClick={(e) => handleImage(OverviewTable)}>
              <img src={OverviewTable} alt="Overview Table" height="100%" width="100%" />
            </Box>            
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.paper}>
              <Typography style={{fontSize:20, fontWeight:"bold", textAlign:"justify"}}>
                View overview expenses, revnues for all fields at once and save detailed reports in PDF or Excel format.
              </Typography>
            </Box>
          </Grid>


          <Grid item xs={6}>
            <Box className={classes.paper}>
              <Typography style={{fontSize:20, fontWeight:"bold", textAlign:"justify"}}>
                Compare two variables across your farm to better understand yur sources of Profitability and cost.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box onClick={(e) => handleImage(Analytics)}>
              <img src={Analytics} alt="Analytics" height="100%" width="100%" />
            </Box>            
          </Grid>

          <Grid item xs={6}>
            <Box onClick={(e) => handleImage(DisaggregateByVariety)}>
              <img src={DisaggregateByVariety} alt="Disaggregate By Variety" height="100%" width="100%" />
            </Box>            
          </Grid>
          <Grid item xs={6}>
            <Box className={classes.paper}>
              <Typography style={{fontSize:20, fontWeight:"bold", textAlign:"justify"}}>
                Compare your farm fields by variety applied on different fields.
              </Typography>
            </Box>
          </Grid>

        </Grid>

        <ImageModal
          open={open}
          setOpen={setOpen}
          image={source}
        />
      </div>      
    </div>
  );
};

export default FarmReportOverview;

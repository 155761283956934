import { format } from "d3-format";
import { ascending, descending } from "d3-array";

export const ORG_ID = "119210";
export const DEFAULT_FARM_NAME = "Bacon Farms Inc";
export const DEFAULT_YEAR = 2017;
export const DURATION = 250;

export const REPORT_TYPE = {
  Farm: "farm",
  Market: "market",
};

export const FIELD_OPERATION_TYPE = {
  Seeding: "Seeding",
  Harvest: "Harvest",
  Application: "Application",
  Tillage: "Tillage",
  Generic: "Generic",
};

export const PERCENT_FORMAT = format(".1%");
export const DOLLAR_FORMAT = format("$,.0f");
export const NO_DECIMAL_PERCENT_FORMAT = format(",.0%");
export const SINGLE_DECIMAL_FORMAT = format(",.1f");
export const PREFIX_FORMAT = format(".2s");

export const DL_TIME_LIMIT = 4000;

export const COLORS = {
  blue: "#1c71c6",
  red: "#cc0000",
  text: "#3a3a3a",
  veryLightGrey: "#f3f3f3",
  darkGray: "#555555",
  white: "#fff",
  offWhite: "#fafafa",
  gray: "#999999",
  lightGray: "#dddddd",
  mediumGray: "#767676",
  black: "#000",
  green: "#67b346",
  lightGreen: "#eefce8",
};

export const COLOR_RANGE_COPY = [
  { backgroundColor: "#8F1721", color: COLORS.white },
  { backgroundColor: "#ec1f27", color: COLORS.white },
  { backgroundColor: "#f58020", color: COLORS.white },
  { backgroundColor: "#FFF200", color: COLORS.white },
  { backgroundColor: "#71bf44", color: COLORS.white },
  { backgroundColor: "#11a74b", color: COLORS.white },
];

export const COLOR_RANGE = [
  { backgroundColor: "#8F1721", color: COLORS.white }, //negative
  { backgroundColor: "#ec1f27", color: COLORS.white }, //negative
  { backgroundColor: "#f58020", color: COLORS.white }, //negative
  { backgroundColor: "#FFF200", color: COLORS.text }, //positive
  { backgroundColor: "#71BF44", color: COLORS.white }, //positive
  { backgroundColor: "#0E8037", color: COLORS.white }, //positive
];

export const NEGATIVE_COLOR_RANGE = [
  { backgroundColor: "#8F1721", color: COLORS.white }, //negative
  { backgroundColor: "#ec1f27", color: COLORS.text }, //negative
  { backgroundColor: "#f58020", color: COLORS.text }, //negative
];

export const POSITIVE_COLOR_RANGE = [
  { backgroundColor: "#FFF200", color: COLORS.text }, //positive
  { backgroundColor: "#71BF44", color: COLORS.text }, //positive
  { backgroundColor: "#0E8037", color: COLORS.white }, //positive
]

export const BLUE_COLOR_RANGE = [
  { backgroundColor: "#e5fbff", color: COLORS.text },
  { backgroundColor: "#bef4fd", color: COLORS.text },
  { backgroundColor: "#88d9f7", color: COLORS.text },
  { backgroundColor: "#5ca8f4", color: COLORS.text },
  { backgroundColor: "#1d6bd6", color: COLORS.white },
  { backgroundColor: "#3110bc", color: COLORS.white },
];

export const CHART_TYPES = {
  Scatter: "scatter",
  Beeswarm: "beeswarm",
  ScatterCompare: "scatter-compare",
  Histogram: "histogram",
};

export const Theme = {
  Title: {
    fontSize: "20px",
    fontWeight: "bold",
    lineHeight: "1.3",
    color: COLORS.darkGray,
  },
};
export const SORT = {
  Desc: "desc",
  Asc: "asc",
};

export const SORT_LOOKUP = new Map([
  [SORT.Desc, descending],
  [SORT.Asc, ascending],
]);

export const ADVANCED_CONTROLS = {
  Filter: "Filter Results",
  Select: "Select Columns",
};

export const MONTHS = [
  { id: "Jan", active: false },
  { id: "Feb", active: false },
  { id: "Mar", active: false },
  { id: "Apr", active: false },
  { id: "May", active: false },
  { id: "Jun", active: true },
  { id: "Jul", active: true },
  { id: "Aug", active: true },
  { id: "Sep", active: true },
  { id: "Oct", active: false },
  { id: "Nov", active: false },
  { id: "Dec", active: false },
];

export const Nutrients = [
  "Nitrogen",
  "Sulfur",
  "Calcium",
  "Phosphorus",
  "Copper",
  "Cobalt",
  "Potassium",
  "Molybdenum",
  "Manganese",
  "Boron",
  "Zinc",
  "Magnesium",
  "Iron",
  "Chlorine",
];

export const MARKET_TABS = {
  Seeding: "Seeding",
  Yields: "Yields",
  Soil: "Soil & Weather",
  Chemicals: "Chemicals",
};

export const SOIL_CATEGORIES = [
  "NCCPI",
  "Soil PH",
  "Organic Matter %",
  "Silt %",
  "Sand %",
  "GDD",
  "Precipitation",
];

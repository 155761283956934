/* eslint-disable react/jsx-props-no-spreading */
// -------------------- IMPORTS --------------------
// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box } from '@material-ui/core';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import GetAppIcon from '@material-ui/icons/GetApp';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import { roundIcon } from '../../../styles/icons';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { grey, grey2, green } from '../../../styles/colors';
import { useWindowDimensions } from '../../../utils/dimensions';

// Custom Components and Functionality
import { CustomToolTip } from '../../../utils/customComponents';
import { checkIsMobile } from '../../../utils/helpers';


// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  appBarSelections: theme.appBarSelections,
  appBarSelectionsMobile: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    color: theme.palette.primary.main,
    fontSize: '22px',
    fontWeight: 700,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: theme.navigationArrows,
  mobileStep: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  mobileTabs: theme.mobileTabs,
  interactionIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: 28,
    margin: '0 4px',
  },
  disabledInteractionIcon: {
    color: grey2,
    fontSize: 28,
    margin: '0 4px',
  },
}));

// -------------------- MAIN FUNCTIONALITY --------------------
/**
 * Rendered in AcreageReporting/AcreageReporting
 * Show current and future steps, link to view completed reports.
 * Includes icons for settings, file upload, and more information
 * @param {Boolean} authenticated User is logged in or not
 * @param {Function} changeSection Changes step along with performing required checks and actions
 * @param {String} gartPath 'GART' or 'CART'
 * @param {Boolean} initialLoadComplete Whether it has been attempted to load data
 * @param {Boolean} loading Loading state for disabling
 * @param {Number} mobileBreakPoint Screen width in pixels to shift to mobile formatting
 * @param {Function} openFileDownload Opens modal for downloading personal files
 * @param {Function} openFileUpload Open ReportFileUpload modal and set other necessary states
 * @param {Number} section Current step user is on
 * @param {Function} setSettingsOpen Open Settings Modal
 * @param {Boolean} successfullyLoadedData Initial load is complete with no error
 * @returns {JSX} Step navigation
 */
export const Steps = ({
  authenticated,
  changeSection,
  gartPath,
  initialLoadComplete,
  loading,
  mobileBreakPoint,
  openFileDownload,
  openFileUpload,
  section,
  setSettingsOpen,
  successfullyLoadedData,
}) => {
  // -------------------- VARIABLES --------------------
  const classes = useStyles();
  const { width } = useWindowDimensions();
  // Screen width in pixels to start adjusting text and margins
  const getSmaller = 1400;
  const [mobileDisplay, setMobileDisplay] = useState(false);

  // Steps
  const setupPage = 0;
  const reviewPage = 1;
  const finalizePage = 2;
  const viewPage = 9;


  // -------------------- USE EFFECTS --------------------
  useEffect(() => {
    // Used for tooltips and eventually row or column for selection boxes
    setMobileDisplay(checkIsMobile());
  }, []);


  // -------------------- RETURN --------------------
  return (
    width > mobileBreakPoint ? (
      // For Wider Screens
      <Box display="flex" justifyContent="space-between" width="100%">
        <Box display="flex">
          {/* Step 0 - Setup Page */}
          <Box
            className={classes.appBarSelections}
            color={green}
            onClick={() => changeSection(setupPage)}
            style={{ margin: width > getSmaller ? '0 60px' : '0 16px' }}
          >
            {section > setupPage ? (
              <CheckBoxRoundedIcon
                style={{ marginRight: '10px', fontSize: 27 }}
              />
            ) : (
              <Box {...roundIcon}>{setupPage + 1}</Box>
            )}
            Setup Acreage Report
          </Box>

          {/* Step 1 - Review Page */}
          <Box
            className={classes.appBarSelections}
            color={section > setupPage ? green : grey}
            onClick={() => changeSection(reviewPage)}
            style={{ margin: width > getSmaller ? '0 60px' : '0 16px' }}
          >
            {section > reviewPage ? (
              <CheckBoxRoundedIcon
                style={{ marginRight: '10px', fontSize: 27 }}
              />
            ) : (
              <Box {...roundIcon}>{reviewPage + 1}</Box>
            )}
            Review {gartPath ? 'Plantings' : 'CLUs'}
          </Box>
          
          {/* Step 2 - Finalize Page */}
          <Box
            className={classes.appBarSelections}
            color={section > reviewPage ? green : grey}
            onClick={() => changeSection(finalizePage)}
            style={{ margin: width > getSmaller ? '0 60px' : '0 16px' }}
          >
            {section > finalizePage ? (
              <CheckBoxRoundedIcon
                style={{ marginRight: '10px', fontSize: 27 }}
              />
            ) : (
              <Box {...roundIcon}>{finalizePage + 1}</Box>
            )}
            Finalize Report
          </Box>

          {/* Step 9 - View Reports Page */}
          <Box
            className={classes.appBarSelections}
            color={section === viewPage ? green : grey}
            onClick={() => changeSection(viewPage)}
          >
            {width > getSmaller ? 'View Generated Reports' : 'View Reports'}
          </Box>
        </Box>

        {/* File Upload, Settings, and Info Buttons */}
        <Box px={0.5} display="flex" alignItems="center">
          {successfullyLoadedData &&
            <>
              <CustomToolTip
                title="Upload Files"
                placement="bottom"
              >
                <AttachFileIcon
                  className={classes.interactionIcon}
                  onClick={() => openFileUpload('general')}
                />
              </CustomToolTip>
              <CustomToolTip
                title="View Uploaded Files"
                placement="bottom"
              >
                {/* <GetAppIcon */}
                <PageviewOutlinedIcon
                  className={classes.interactionIcon}
                  style={{ margin: "0 8px 0 6px" }}
                  onClick={() => openFileDownload('general')}
                />
              </CustomToolTip>
            </>
          }
          {authenticated && (!gartPath || successfullyLoadedData) &&
            <CustomToolTip
              title="Settings"
              placement="bottom"
            >
              <SettingsIcon
                className={loading ? classes.disabledInteractionIcon : classes.interactionIcon}
                onClick={() => loading ? {} : setSettingsOpen(true)}
              />
            </CustomToolTip>
          }
          {/* Will leave this here as a placeholder reminder, but need to remove if never used. Wanted to disable for now so it is less confusing but it actually stands out more...*/}
          {/* <CustomToolTip
            title="Upload Files"
            placement="bottom"
          >
            <InfoOutlinedIcon
              // className={classes.disabledInteractionIcon}
              className={classes.interactionIcon}
            />
          </CustomToolTip> */}
        </Box>
      </Box>
    ) : (
      // For Mobile
      // NOTE: Currently, view reports page is not available in mobile view
      // NOTE: file upload/download, settings, and info buttons are not available here either
      <>
        {/* Step 0 - Setup Page */}
        { section === setupPage && (
          <Box className={classes.mobileStep}>
            <Box/>
            <Box className={classes.appBarSelectionsMobile}>
              <Box {...roundIcon}>{setupPage + 1}</Box>
              Setup Acreage Report
            </Box>
            <ArrowForwardIosIcon
              className={classes.icon}
              onClick={() => changeSection(reviewPage)}
            />
          </Box>
        )}

        {/* Step 1 - Review Page */}
        { section === reviewPage && (
          <Box className={classes.mobileStep}>
            <ArrowBackIosIcon
              className={classes.icon}
              onClick={() => changeSection(setupPage)}
            />
            <Box className={classes.appBarSelectionsMobile}>
              <Box {...roundIcon}>{reviewPage + 1}</Box>
              Review {gartPath ? 'Plantings' : 'CLUs'}
            </Box>
            <ArrowForwardIosIcon
              className={classes.icon}
              onClick={() => changeSection(finalizePage)}
            />
          </Box>
        )}

        {/* Step 2 - Finalize Page */}
        { section === finalizePage && (
          <Box className={classes.mobileStep}>
            <ArrowBackIosIcon
              className={classes.icon}
              onClick={() => changeSection(reviewPage)}
            />
            <Box className={classes.appBarSelectionsMobile}>
              <Box {...roundIcon}>{finalizePage + 1}</Box>
              Finalize Report
            </Box>
            <Box/>
          </Box>
        )}

        {/* Step 9 - View Reports Page */}
      </>
    )
  );
};

Steps.propTypes = {
  authenticated: PropTypes.bool.isRequired,
  changeSection: PropTypes.func.isRequired,
  gartPath: PropTypes.bool.isRequired,
  initialLoadComplete: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  mobileBreakPoint: PropTypes.number.isRequired,
  openFileDownload: PropTypes.func.isRequired,
  openFileUpload: PropTypes.func.isRequired,
  section: PropTypes.number.isRequired,
  setSettingsOpen: PropTypes.func.isRequired,
  successfullyLoadedData: PropTypes.bool.isRequired,
};

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable require-jsdoc */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  Button,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  AppBar,
  Tabs,
  Tab,
  Typography,
  CircularProgress,
  FormControl,
  TextField,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useSnackbar } from 'notistack';
import ReactJson from 'react-json-view';
import { useWindowDimensions } from '../../utils/dimensions';
import { darkGrey, green } from '../../styles/colors';
import * as df from '../../utils/dataFetchers';
import { KeyPoints } from './APIdocumentation/KeyPoints';
import { response } from './Files/sampleResponse';
import { FarmFlight } from './APIdocumentation/FarmFlightDoc';
import { Drainage } from './APIdocumentation/DrainageDoc';
import { SoilTest } from './APIdocumentation/SoilTestDoc';

const lightBlue = '#007cba';
const greyBlue = '#006ba1';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    flexGrow: 1,
    backgroundColor: theme.palette.greys.light,
    color: theme.palette.text.primary,
    overflowY: 'auto',
  },
  logo: {
    width: 'auto',
    margin: '0 30px',
  },
  aglogo: {
    width: 'auto',
    margin: '0 30px',
  },
  dot: {
    fontSize: '12px',
    margin: '3px 5px 0 0',
    color: greyBlue,
  },
  square: {
    fontSize: '12px',
    margin: '3px 3px 0 0',
    color: lightBlue,
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  product: {
    minWidth: 200,
    padding: '8px',
    margin: '16px',
    fontWeight: 500,
    borderColor: darkGrey,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      borderColor: greyBlue,
    },
  },
  package: {
    maxWidth: '832px',
    padding: '8px',
    margin: 8,
    fontWeight: 500,
    borderColor: darkGrey,
    '&:hover': {
      cursor: 'pointer',
      borderColor: greyBlue,
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 18,
  },
  link: {
    color: lightBlue,
    '&:hover': {
      cursor: 'pointer',
      color: greyBlue,
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  form: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
}));

function TabPanel(props) {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          {children}
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

export function APIDocumentation() {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  const [jsonBody, setJsonBody] = useState(response);
  const [value, setValue] = useState(0);

  const [displayMessge, setDisplayMessage] = useState('');
  const [showLoader, setShowLoader] = useState(false);

  const [clientkey, setClientKey] = useState('v0ob98uhkj2r7fmv');
  const [clientSecret, setClientSecret] = useState('LtQkRgG5cxjpSTOFN4FDZyJg');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleReqChange = (e) => {
    setJsonBody(e.updated_src);
  };

  const postRequest = async () => {
    try {
      setShowLoader(true);
      const jsonRes = await df.postVendorResponse(jsonBody, clientkey, clientSecret);
      setShowLoader(false);
      setDisplayMessage(JSON.stringify(jsonRes));
    } catch (err) {
      setShowLoader(false);
      enqueueSnackbar('Error posting order. Please try again and contact us if this problem persists.');
      console.error('error posting request', err);
    }
  };

  return (
    <Box display="flex">
      <Box className={classes.root}>
        <Container
          maxWidth="lg"
          style={{ backgroundColor: '#ffffff', height: '100%', paddingBottom: 8 }}
        >

          <Box mt={2}>
            <Box fontSize={20} fontWeight={500} style={{ textAlign: 'center' }}>
              API Documentation
            </Box>
          </Box>
          <Box mt={2}>
            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="apiDetails"
                id="apiDetails"
              >
                <Box fontWeight="bold" fontSize={18}>API Details</Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box fontSize={16} fontWeight={500}>
                  <Box>
                    <strong>API URL: </strong>
                    <a href="https://app.profit.ag/api/VendorResponse">https://app.profit.ag/api/VendorResponse</a>
                  </Box>
                  {' '}
                  <br />
                  <Box>
                    <strong>Request Method: </strong>
                    POST
                  </Box>
                  {' '}
                  <br />
                  <Box>
                    <strong>Request Body: </strong>
                    JSON Body
                  </Box>
                  <br />
                  <Box>
                    <strong>Request Headers: </strong>
                    <Box>Client Key: Client key provided to vendor</Box>
                    <Box>Client Secret: Client secret provided to vendor</Box>
                  </Box>
                  <br />
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion defaultExpanded>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="apiInfo"
                id="apiInfo"
              >
                <Box fontWeight="bold" fontSize={18}>Working of API</Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box fontSize={16} fontWeight={500} textAlign="justify">
                  The VendorResponse API accepts the response structure described below. Along with the
                  json body, the VendorResponse API will expect a clientKey and clientSecret to be
                  passed in the headers. The purpose of clientKey and clientSecret is to check,
                  whether the user posting the response is a valid user. After validation, the
                  database is updated with the response posted. Then the API checks if there are
                  any PDF, Shapefile, or TIFF file links provided. If any links exist, the API will download
                  them as zip files or .pdf files. Once files are downloaded and extracted, the
                  next step is to load the files to blob storage. The last step performed by the API
                  is to send an email to the User that has a tokenized link which allows them to view their
                  responses and order summary. The tokenized links will also allow user to download PDF,
                  images, and TIFF files. If the size of downloadable links are large, then the
                  API might take some time to download them and upload to blob storage.
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="apiDetails"
                id="apiDetails"
              >
                <Box fontWeight="bold" fontSize={18}>Result Structure Parameter</Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box fontSize={16} fontWeight={500}>
                  <Box>
                    <Box>JSON Structure of Results:</Box>
                    <Box>
                      Find JSON structure document
                      {' '}
                      <a href="https://drive.google.com/uc?export=download&id=1vBDAEqSvoVZ9TTKE2e---3s6-mMVdzf9" download>here</a>
                      .
                    </Box>
                  </Box>

                  <Box mt={2}>
                    <Box>
                      <form className={classes.form} noValidate autoComplete="off">
                        <FormControl className={classes.formControl}>
                          <TextField required label="Client Key" onChange={(e) => setClientKey(e.target.value)} value={clientkey} />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField required label="Client Secret" onChange={(e) => setClientSecret(e.target.value)} value={clientSecret} />
                        </FormControl>
                      </form>
                    </Box>
                  </Box>

                  <Box mt={2}>
                    <Box>Use the form below to submit a result to the API</Box>
                  </Box>
                  <Box mt={2} width="100%">
                    <Box>JSON Schema</Box>
                    <ReactJson
                      src={response}
                      theme="monokai"
                      name={false}
                      onEdit={(e) => handleReqChange(e)}
                      indentWidth={2}
                      collapseStringsAfterLength={50}
                    />
                  </Box>

                  <Box mt={2}>
                    <Button variant="outlined" color="primary" style={{ float: 'right' }} onClick={() => postRequest()}>
                      Submit Test Request
                    </Button>
                  </Box>

                  <Box mt={2}>
                    <Box fontWeight="bold">
                      Message from API:
                      {displayMessge}
                    </Box>
                  </Box>
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="apiDetails"
                id="apiDetails"
              >
                <Box fontWeight="bold" fontSize={18}>Result Structure Key Points</Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <KeyPoints />
                </Box>
              </AccordionDetails>
            </Accordion>

            <Accordion>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="apiDetails"
                id="apiDetails"
              >
                <Box fontWeight="bold" fontSize={18}>Result Structure for Existing Vendors</Box>
              </AccordionSummary>
              <AccordionDetails>
                <Box>
                  <AppBar position="static" color="default">
                    <Tabs
                      value={value}
                      onChange={handleChange}
                      indicatorColor="primary"
                      textColor="primary"
                      variant="scrollable"
                      scrollButtons="auto"
                      aria-label="scrollable auto tabs example"
                    >
                      <Tab label="UAV Service" {...a11yProps(0)} />
                      <Tab label="DIGS Water Management" {...a11yProps(1)} />
                      <Tab label="Soil Testing" {...a11yProps(2)} />
                    </Tabs>
                  </AppBar>
                  <TabPanel value={value} index={0}>
                    <FarmFlight />
                  </TabPanel>
                  <TabPanel value={value} index={1}>
                    <Drainage />
                  </TabPanel>
                  <TabPanel value={value} index={2}>
                    <SoilTest />
                  </TabPanel>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>

          <Box mt={1}>
            <Button variant="outlined" color="primary" style={{ float: 'right' }} href="/app/VendorResponse">
              <span style={{ color: green }}>Go to Vendor Response Page</span>
            </Button>
          </Box>

        </Container>
        {showLoader && (
        <CircularProgress
          size={width > 768 ? 140 : 60}
          style={{
            position: 'absolute',
            top: '30%',
            right: 0,
            left: 0,
            margin: 'auto',
            zIndex: 1005,
            color: green,
          }}
        />
        )}
      </Box>
    </Box>
  );
}

import React from 'react';
import {
  Box, Typography, Button, Link,
} from '@material-ui/core';
import Iframe from 'react-iframe';
import { Endpoints } from '../../../constants/Endpoints';
import { useWindowDimensions } from '../../../utils/dimensions';

export function ViewPDFs({ reportPdfs, token }) {
  const { height, width } = useWindowDimensions();

  const displayPdf = (pdf, i) => {
    const pdfLink = token !== '' ? `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${pdf}&token=${token}` : `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${pdf}`;
    return (
      width >= 1080

        ? (
          <Box key={i} p={2}>
            <Typography variant="h6" align="center">{pdf.split('/')[pdf.split('/').length - 1].replace('.pdf', '').split('_').join(' ')}</Typography>
            <Iframe
              src={pdfLink}
              width={width - 245}
              height="650px"
            />
          </Box>
        )
        : (
          <Box key={i} m={2} p={2} boxShadow={2} display="flex" flexDirection="column" alignItems="center">
            <Typography variant="h6" align="center">{pdf.split('/')[pdf.split('/').length - 1].replace('.pdf', '').split('_').join(' ')}</Typography>
            <Link color="secondary" href={pdfLink} target="_blank" rel="noreferrer"><Button variant="outlined" color="primary">View PDF</Button></Link>
          </Box>
        )
    );
  };

  return (
    <Box display="flex" flexDirection="column" style={{ width: '100%' }}>
      { (reportPdfs !== undefined && reportPdfs !== null) && reportPdfs.map((pdf, i) => displayPdf(pdf, i)) }
    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import { Tooltip, Box, Modal } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import { blackText } from '../styles/colors';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.primary,
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    padding: 1,
    minWidth: 240,
    borderRadius: 4,
  },
  close: theme.close,
  infoToolTip: theme.infoToolTip,
}));

// Custom styled MUI Components for re-use
export const AgToolTip = withStyles({
  tooltip: {
    fontSize: '16px',
    color: 'white',
    backgroundColor: '#67b444',
  },
})(Tooltip);

export const CustomToolTip = withStyles({
  tooltip: {
    fontSize: 14,
    fontWeight: 500,
    color: blackText,
    backgroundColor: '#fefefe',
    border: '1px solid #555555',
  },
})(Tooltip);

/**
 * Typical tooltips will not work on mobile, so replace with this
 * @param {String|Object} text Infomation to display to user on click
 * @param {Object} iconStyles Styles to use for the icon for this tooltip
 * @returns {JSX} Modal popup with tooltip text
 */
export const MobileToolTip = ({ text, iconStyles }) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  return (
    <Box display="inline">
      {iconStyles ? (
        <InfoOutlinedIcon
          style={{ ...iconStyles, cursor: 'pointer' }}
          onClick={() => setOpen(true)}
        />
      ) : (
        <InfoOutlinedIcon
          className={classes.infoToolTip}
          fontSize="small"
          onClick={() => setOpen(true)}
        />
      )}

      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        aria-labelledby="tooltip-popup"
        aria-describedby="instructions"
      >
        <Box className={classes.paper}>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => { setOpen(false); }}
          />
          <Box mt={1} p={2}>
            {text}
          </Box>
        </Box>
      </Modal>
    </Box>
  );
};

/**
 * Based on if mobile or not, returns standard tooltip or
 * mobile friendly tooltip (modal).
 * @param {String|Object} text Tooltip text
 * @param {Bool} mobileDisplay If viewing on mobile device
 * @param {Object} iconStyles Styles to use for the icon for this tooltip
 * @returns {JSX} tooltip
 */
export const ToolTip = ({ text, mobileDisplay, iconStyles }) => {
  const classes = useStyles();
  return (
    mobileDisplay ? (
      <MobileToolTip text={text} iconStyles={iconStyles}/>
    ) : (
      <CustomToolTip
        title={text}
        placement="right-start"
      >
        {iconStyles ? (
          <InfoOutlinedIcon
            style={iconStyles}
          />
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
          />
        )}
      </CustomToolTip>
    )
  );
};

export const TabPanel = (props) => {
  const {
    children, value, index, ...other
  } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box>
          { children }
        </Box>
      )}
    </div>
  );
};

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

// AutoComplete with internal padding to match commonly used Select and TextFields
export const CustomAutocomplete = withStyles({
  inputRoot: {
    '&&[class*="MuiOutlinedInput-root"] $input': {
      padding: 1,
    },
  },
  input: {},
})(Autocomplete);

export const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

export const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .00)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionSummaryCentered = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .00)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    flexGrow: 0,
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

export const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

// Used in top selection tabs
export const roundIcon = {
  width: '25px',
  height: '25px',
  minWidth: '25px',
  minHeight: '25px',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 2,
  borderRadius: '50%',
  marginRight: '10px',
};

MobileToolTip.propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  iconStyles: PropTypes.object,
};

ToolTip.propTypes = {
  mobileDisplay: PropTypes.bool.isRequired,
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.object,
  ]).isRequired,
  iconStyles: PropTypes.object,
};

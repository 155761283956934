import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import * as L from 'leaflet';
import 'leaflet-easyprint';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../../Maps/leaflet.css';
import {
  drawFieldLayer,
  getMinHeight,
} from '../../../Maps/MapFunctions/helpers';
import { drawBasicMap } from '../../../Maps/MapFunctions/drawMap';

/**
 * Displays GeoJSON and field boundary on map. Attatches click interactions to
 * zone and point selection. Colors zones and points based on their index from
 * imported zoneColors array.
 * @param {Object} boundary Field boundary
 * @param {Object} mapGeo GeoJSON from shapefile
 * @returns {JSX} Result map
 */
export function ResultsMap({
  boundary, mapGeo,
}) {
  const [map, setMap] = useState(null);

  const fieldLayer = L.featureGroup(null);
  const geoLayer = useRef(L.featureGroup(null));

  useEffect(() => {
    drawBasicMap(setMap, fieldLayer, 'digs-results-map');
  }, []);

  useEffect(() => {
    if (map) {
      try {
        clearGeoLayer();
        if (mapGeo) {
          drawFieldLayer(mapGeo.features[0], geoLayer.current, map, true);
          geoLayer.current.bringToFront();
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [map, mapGeo]);

  useEffect(() => {
    if (map && boundary) {
      const geo = JSON.parse(boundary);
      drawFieldLayer(geo, fieldLayer, map, true, '#8e24aa');
    }
  }, [map, boundary]);

  const clearGeoLayer = () => {
    geoLayer.current.eachLayer((layer) => {
      map.removeLayer(layer);
    });
  };

  return (
    <div
      id="digs-results-map"
      style={{
        height: '100%',
        minHeight: getMinHeight(),
        width: '100%',
        borderRadius: 2,
      }}
    />
  );
}

ResultsMap.propTypes = {
  boundary: PropTypes.string.isRequired,
  mapGeo: PropTypes.object,
};

ResultsMap.defaultProps = {
  mapGeo: undefined,
};

import React, { useState } from "react";
import { makeStyles } from "@material-ui/core";
import BaseChart from "./../../../Helpers/BaseChart";
import Beeswarm from "./Beeswarm";
import Tooltip from "../Tooltip";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  plot: {
    display: "flex",
    flexDirection: "row",
    position: "relative",
    alignItems: "center",
  },
  chartContainer: {
    flex: "1 1 100%",
    width: "100%",
    height: "100px",
  },
  label: {
    width: 175,
    fontSize: 14,
  },
}));

const BeeswarmContainer = ({
  points,
  radius,
  isDisaggregated,
  firstVariableOption,
  secondVariableOption,
  xUnit,
  yUnit,
}) => {
  const classes = useStyles();
  const [state, setState] = useState({
    activeMarker: null,
  });
  const { activeMarker } = state;

  return (
    <div className={classes.wrapper}>
      {points.map((pointGroup, i) => {
        const isLastChart = i === points.length - 1;
        const firstItem = pointGroup[0];
        if (firstItem == undefined) return <div> </div>;
        else {
          return (
            <div
              className={classes.plot}
              key={`plot-for-${firstItem.variety}-${i}`}
              onMouseOut={() =>
                setState((prevState) => ({ ...prevState, activeMarker: null }))
              }
            >
              {isDisaggregated && (
                <div className={classes.label}>{firstItem.variety}</div>
              )}
              <BaseChart
                className={classes.chartContainer}
                margin={{
                  left: 25,
                  right: 25,
                  top: 25,
                  bottom: 50,
                }}
              >
                <Beeswarm
                  points={pointGroup}
                  setState={setState}
                  radius={radius}
                  isDisaggregated={isDisaggregated}
                  isLastChart={isLastChart}
                  state={state}
                  firstVariableOption={firstVariableOption}
                />
              </BaseChart>
              {activeMarker && activeMarker.variety === firstItem.variety && (
                <Tooltip
                  activeMarker={activeMarker}
                  isDisaggregated={isDisaggregated}
                  firstVariableOption={firstVariableOption}
                  secondVariableOption={secondVariableOption}
                  xUnit={xUnit}
                  yUnit={yUnit}
                />
              )}
            </div>
          );
        }
      })}
    </div>
  );
};

export default BeeswarmContainer;

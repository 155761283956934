import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Link, Divider } from '@material-ui/core/';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Endpoints } from '../../constants/Endpoints'
import ksu_logo from '../../images/ksu_logo.jpg'
import logo from '../../images/Logo.png'

const useStyles = makeStyles(theme => ({
  icon: {
    margin: '10px 0',
    color: '#616161',
    '&:hover': {
       color: '#1565c0',
       cursor: 'pointer'
    },
  },
  iconBox: {
    position: 'sticky',
    top: 100,
    bottom: 20,
    height: '150px',
  },
  logo: {
    width: '60px',
    margin: '0 30px',
  }
}))


export const MachineLearning = () => {
  const classes = useStyles()

  return (
    <Container maxWidth='md'>
      <Box display='flex'>
        <Box>
          <Box mt={2}>
            <Box display='flex' flexWrap='wrap'>
              <Typography variant="h4">Ag-Analytics to Release Several Machine Learning Driven Environmental APIs on Azure, Supported by Microsoft's AI for Earth Program</Typography>
            </Box>
          </Box>
          <Box mt={1} display="flex">
            <Typography style={{color: '#757575'}}>Ag-Analytics July, 2019</Typography>
          </Box>
          <Box my={1} display='flex'>
            <Typography variant='body1'>
              {/* <strong>ITHACA, N.Y., June 24, 2019 - </strong> */}
              Ag-Analytics&reg;, through a new strategic partnership with Microsoft’s AI for Earth program,
              today announced the launch of several new APIs and data feeds for public use,
              including mirroring over 100 TB of Harmonized Landsat Sentinel-2 (HLS) data on Azure.
              By enabling optimized, efficient, flexible cloud data access for machine learning and AI applications,
              academics and developers alike will now be able to easily access production-ready HLS and other data
              for use in development, research, and modeling.
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
                The API Service will be made available for free to academics and trial use,
                and as a paid service for corporate or intensive use. These feeds are also mirrored
                in the Ag-Analytics® free mapping application for farmers to access.
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              Source HLS data will be made freely available for Azure users on Azure Blob Storage,
              as part of the Azure Open Datasets program. Ag-Analytics® will also offer an optimized
              HLS API service allowing users to pass an area-of-interest (AOI) - coordinates of the
              desired area one wants to retrieve data for - and receive a full history of imagery and statistics for that AOI.
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              The Ag-Analytics® platform offers a variety of farm management, insurance, financial,
              and other decision-making tools for agricultural producers, including its flagship profit mapping tool,
              ProfitLayers™​, and its high-resolution profit map forecast tool powered by YieldAI™​.
              The platform is available throughout the U.S. and is connected with the John Deere Operations Center,
              allowing producers to securely integrate their precision agriculture and field data effortlessly & confidentially.
            </Typography>
            <Typography variant='body1'>
              Ag-Analytics is one of the newest organizations to be recognized by Microsoft for its impact and potential
              and will join more than 380 AI for Earth grantees and strategic partners around the world. AI for Earth is a
              $50 million, 5-year program that brings the full advantage of Microsoft technology to those working to solve
              global environmental challenges in the key focus areas of climate, agriculture, water, and biodiversity.
              Through grants that provide access to cloud and AI tools, opportunities for education and training on AI,
              and investments in innovative, scalable solutions, AI for Earth works to advance sustainability across the globe.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.iconBox} m={3} mt={3} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' boxShadow={1} borderRadius={16}>
          <Link href={`https://twitter.com/share?url=${Endpoints.BASEURL}/press/ksu`} target="_blank"><TwitterIcon className={classes.icon} /></Link>
          <Link href={`https://www.facebook.com/sharer/sharer.php?u=${Endpoints.BASEURL}/press/ksu`} target="_blank"><FacebookIcon className={classes.icon}/></Link>
          <Link href={`https://www.linkedin.com/shareArticle?mini=true&url=${Endpoints.BASEURL}/press/ksu`} target="_blank"><LinkedInIcon  className={classes.icon}/></Link>
        </Box>
      </Box>
    </Container>
  )
}

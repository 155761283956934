import React, { useEffect, useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { UserContext } from '../Context/UserContext';
import { useWindowDimensions } from '../../utils/dimensions';
import infographic from '../../images/digs_infographic.png';
import adsLogo from '../../images/ADS-logo-large.png'
import digsLogo from '../../images/DigsLOGOS.png'
import { grey } from '../../styles/colors';
import { Endpoints } from '../../constants/Endpoints';

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
    backgroundColor: theme.palette.greys.light,
  },
  head: theme.updateOperationHeader,
  close: {
    fontSize: 24,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 6,
    right: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonBox: {
    backgroundColor: '#ffffff',
    margin: '8px 0',
    borderRadius: 8,
  },
  customButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
    fontWeight: 500,
    padding: 8,
    width: 200,
    textTransform: 'none',
  },
  infoBox: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

/**
 * Informative modal with user to see when first visit Soil Testing.
 * Inclides infographic on order steps, description, link to sign in or start ordering.
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Function} setOpen Sets if modal is open
 * @param {Function} setStep Sets step
 * @returns {JSX} Info modal
 */
export const InfoModal = ({ open, setOpen, setStep, ads }) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [authenticated, setAuthenticated] = useState(false);
  const user = useContext(UserContext)[0];

  useEffect(() => {
    sessionStorage.setItem('viewed', JSON.stringify({ digsInfo: true }));
  }, []);

  useEffect(() => {
    if (user) {
      setAuthenticated(user?.isAuthenticated);
    }
  }, [user]);

  const learnMore = () => {
    setStep(0);
    setOpen(false);
  };

  const signIn = () => {
    window.location.assign(Endpoints.BASEURL + Endpoints.SIGNINFROMDIGS);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="digs-testing-info"
      aria-describedby="digs-testing-order-process-description"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
        height="auto"
        width={width - 20}
        maxWidth={880}
        maxHeight={height - 20}
      >
        <Box py={1} bgcolor="#ffffff">
          <Typography align="center" variant="h4" style={{ fontWeight: 500, margin: '0 20px' }}>
            Drainage and Water Management Solutions
          </Typography>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => { setOpen(false); }}
          />

        </Box>

        <Divider style={{ backgroundColor: grey }} />

        <Box my={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          {ads &&
            <Box display="flex" style={{justifyContent:"center", flexDirection:"column", alignItems:"center"}}>{/** DIGS & ADS logos and text*/}
              <Box display="flex" width="70%" justifyContent="space-around">
                <Box display="flex" flexDirection="column" justifyContent="center" width="20%">
                  <img src={adsLogo} width="100%"/>
                </Box>
                <Box width="20%">
                  <img src={digsLogo} width="100%"/>
                </Box>
              </Box>
              <Box py={1} width="90%">
                <Typography style={{textAlign:'center'}}>
                Welcome to the ADS-DIGS Associates partnership – powered by ProfitLayers® precision ag insights – designed to 
                provide you the absolute best in agriculture water management system design and installation. 
                Please follow these simple instructions to engage our team of experts.
                </Typography>
              </Box>
            </Box>
          }
          <Box border={1} borderColor="greys.main" width={ads ? '70%' : '90%'}>
            <img src={infographic} alt="infographic" height="auto" width="100%" />
          </Box>

          
          <Box py={1} width="90%">
            <Typography style={{fontSize: '1.05rem', textAlign:'center' }}>
              Maximize drainage water management investments by ordering high-quality
              engineering services.
              Easily select your field on the map, choose your services, then place a&nbsp;
              <span style={{ textDecoration: 'underline' }}>no-obligation</span>
              &nbsp;order. No payment due at time of order, pricing is estimate only.
              An engineer will typically contact you within 1-2 days to confirm
              order details and plan next steps.
            </Typography>
          </Box>

          {!authenticated && (
            <Box mt={1} bgcolor="#ffffff">
              <Button
                color="primary"
                variant="outlined"
                onClick={learnMore}
              >
                Learn More
              </Button>
            </Box>
          )}

        </Box>

        <Divider style={{ height: 2, backgroundColor: grey }} />

        <Box
          display="flex"
          justifyContent="space-between"
          flexDirection={width > 800 ? 'row' : 'column'}
        >
          {!authenticated ? (
            <Box className={classes.infoBox} width={width > 800 ? '45%' : '100%'}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '1.8rem' }}>
                Have an account?
              </Typography>

              <Box width="80%">
                <Typography style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                  Select your integrated field boundary.
                </Typography>
              </Box>

              <Box className={classes.buttonBox}>
                <Button className={classes.customButton} onClick={signIn}>
                  Sign In
                </Button>
              </Box>
            </Box>
          ) : (
            <Box className={classes.infoBox} width={width > 800 ? '45%' : '100%'}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '1.8rem' }}>
                Overview
              </Typography>

              <Box width="80%">
                <Typography style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                  Click below to read more about how to order and products offered.
                </Typography>
              </Box>

              <Box className={classes.buttonBox}>
                <Button className={classes.customButton} onClick={learnMore}>
                  Learn More
                </Button>
              </Box>
            </Box>
          )}

          {width > 800 ? (
            <Box display="flex" flexDirection="column" alignItems="center" width="10%">
              <Divider orientation="vertical" style={{ height: '40%', width: 2, backgroundColor: grey }} />
              <Box height="20%" fontSize={26}>Or</Box>
              <Divider orientation="vertical" style={{ height: '40%', width: 2, backgroundColor: grey }} />
            </Box>
          ) : (
            <Divider style={{ height: 2, backgroundColor: grey }} />
          )}

          <Box className={classes.infoBox} width={width > 800 ? '45%' : '100%'}>
            <Typography align="center" style={{ fontWeight: 500, fontSize: '1.8rem' }}>
              Quick Start
            </Typography>

            <Box width="80%">
              <Typography style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                Search by address, coordinates, or zoom on the map.
              </Typography>
            </Box>

            <Box className={classes.buttonBox}>
              <Button className={classes.customButton} onClick={() => setOpen(false)}>
                Start Now
              </Button>
            </Box>

          </Box>
        </Box>

      </Box>
    </Modal>
  );
};

InfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
};

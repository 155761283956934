export const response = {
  "orderId": "7d7219a7",
  "orderName": "Test",
  "orderCompletedDate": "Fri Jan 22 2021 11:00:50 GMT-0500 (Eastern Standard Time)",
  "orderLink": "",
  "farmerDetails": {
    "fullName": "Client Name",
    "eMail": "swathi@ag-analytics.org",
    "phone": "1111111111",
    "mailingAddress": "asd",
    "city": "asd",
    "state": "IN",
    "zipCode": "14850"
  },
  "organizationName": "Test Org",
  "organizationID": "123",
  "totalAcres": "680",
  "totalCost": "10210.685",
  "subOrderDetails": [
    {
      "subOrderId": "39599713-c6f0-4fa2-91d2-2b20dc246cd7",
      "fieldId": "e9c7c963-b396-4b7c-aec9-c38546380e80",
      "fieldName": "Field-1",
      "acres": "680",
      "XYCoordinates": "",
      "reports": [
        ""
      ],
      "costPerAcre": [
        "13"
      ],
      "summary": [
        {
          "reportName": "Analysis Summary",
          "reportSummary": [
            {
              "title": "Average Soil OM",
              "description": "2.614%"
            },
            {
              "title": "Average Soil CEC",
              "description": "10.66 meq/100g"
            },
            {
              "title": "Average Soil MG",
              "description": "226.40 ppm"
            }
          ]
        }
      ],
      "tiffImages": [
        {
          "bandName": "",
          "tiffLink": ""
        }
      ],
      "previewImages": [
        {
          "base64": "",
          "mapExtent": ""
        }
      ],
      "pdfLink": [
        "https://drive.google.com/uc?export=download&id=1WZF_CE-kdxK1G1vLzIzvcwCobpXro6vj"
      ],
      "shapefiles": [
        "https://drive.google.com/uc?export=download&id=1Yiw71jbTn8oftLjKbJSHzXQG3GuQ8jb0"
      ],
      "interactiveLink": "",
      "operator": "Swathi",
      "operatorEmail": "swathi@ag-analytics.org",
      "operatorPhone": "1111111111",
      "operatorCompany": "test",
      "resolution": "",
      "equipmentType": "",
      "equipmentBrand": "",
      "fieldImage": "",
      "subTotal": "25.5",
      "state": "",
      "county": "",
      "digsReports": "",
      "sampleZones": [
        ""
      ],
      "package": "",
      "boundary": "{\\\"type\\\":\\\"Feature\\\",\\\"geometry\\\":{\\\"type\\\":\\\"Polygon\\\",\\\"coordinates\\\":[[[-89.95609760284425,39.72191004668272],[-89.95609760284425,39.73732332196632],[-89.93734359741212,39.73732332196632],[-89.93734359741212,39.72191004668272],[-89.95609760284425,39.72191004668272]]]},\\\"properties\\\":{\\\"CALCACRES\\\":680.7123752445572}}"
    }
  ]
}
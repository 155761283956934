import React, { useEffect, useState } from 'react'
import { Box, Button } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from '@material-ui/core/styles';
import { grey, green, blackText } from "../../../styles/colors";
import { numFormat } from '../../../utils/helpers'
import { useWindowDimensions } from '../../../utils/dimensions'
import poweredBy from '../../../images/Ag-Analytics_Full_Black_Powered.svg'


const useStyles = makeStyles(theme => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    width: '100%',
    padding: 16,
  },
  getPdf: {
    display: "flex",
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
    },
  },
  fullLogo: {
    width: '250px',
    height: 'autox',
    marginRight: '20px',
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginRight: '8px',
  },
  smallLogo: {
    width: '90px',
    height: 'auto',
    marginRight: '4px',
  },
}))

export const Footer = ({
  changeSection,
  field,
  openPdf,
  section,
  setSection,
}) => {
  const {height, width} = useWindowDimensions()
  const classes = useStyles()
  const [openClaim, setOpenClaim] = useState(false);

  useEffect(()=>{
    console.log(field)
  },[field])

  return (
    <Box className={classes.footer}>
      <Box
        display='flex'
        fontSize={14}
        fontWeight={600}
        color={blackText}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => changeSection(0)}
        >
          <ArrowBackIcon />
          Back
        </Button>

        {
          (field.org !== undefined && field.org !== '') &&
          <Box mx={2}>
            <Box fontSize={12} color={grey}>
              ORGANIZATION
            </Box>
            {field.org}
          </Box>
        }

        {
          (field.farm !== undefined && field.farm !== '') &&
          <Box ml={2} mx={2}>
            <Box fontSize={12} color={grey}>
              FARM
            </Box>
            {field.farm}
          </Box>
        }

        {
          (field.name !== undefined && field.name !== '') &&
          <Box mx={2}>
            <Box fontSize={12} color={grey}>
              FIELD
            </Box>
            {field.name}
          </Box>
        }

        {
          (field.acres !== '' && field.acres !== undefined) && 
          <Box mx={2}>
            <Box fontSize={12} color={grey}>
              ACRES
            </Box>
            {numFormat(field.acres, 0, 2)}
          </Box>
        }

      </Box>
      {width > 760 &&
        <Box>
          <img
            src={poweredBy}
            className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
            alt={"Powered By Ag-Analytics"}
          />
        </Box>
      }
    </Box>
  )
}

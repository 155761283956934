import React from "react";
import { Box, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  icon: {...theme.icon, fontSize: '1rem'},
  footer: theme.updateOperationFooter,
}));

export function ConfirmationPopup({setOpen, cancel, save, disableSave}) {
  const classes = useStyles();

  return (
    <Box
      display='flex'
      flexDirection='column'
      justifyContent='space-between'
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        top: '30%',
        zIndex: 1010,
        background: '#ffffff',
        width: '300px',
      }}
      boxShadow={2}
      borderRadius='borderRadius'
    >
      <Box mt={1} display='flex' justifyContent='space-between'>
        <Box>

        </Box>
        <HighlightOffIcon
          className={classes.icon}
          onClick={() => setOpen(false)}
        />
      </Box>

      <Box p={2} fontWeight={500} fontSize={16}>
        { !disableSave ? 
            'Would you like to save your operation or close without saving?'
        :
            'Would you like to close without saving? To save, you must add at least one zone to your operation.'
        }
      </Box>

      <Box p={2} className={classes.footer}>
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: "0 25px", backgroundColor: '#ffffff' }}
          onClick={() => cancel()}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => save()}
          disableElevation
          disabled={disableSave}
        >
          Save
        </Button>
      </Box>
    </Box>
  )
}

import React, { useState } from "react";
import InfoIcon from "../../../images/info-outline.svg";
import Popover from "@material-ui/core/Popover";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  popOver: {
    height: "20px",
    display: "flex",
  },
  icon: { color: COLORS.lightGray, marginLeft: "5px" },
  root: { pointerEvents: "none", backgroundColor: "rgba(#fff, 0.1)" },
  content: {
    padding: "5px 15px",
  },
}));

const InfoPopover = ({ text }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  return (
    <div className={classes.popOver}>
      <img
        alt="popover to show more helpful explanation of element"
        src={InfoIcon}
        className={classes.icon}
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      />
      <Popover
        className={classes.root}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={classes.content}>
          <p>{text}</p>
        </div>
      </Popover>
    </div>
  );
};
export default InfoPopover;

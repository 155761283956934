import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Button,
  Chip,
  Input,
  ListItemText,
  Checkbox,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  grey,
  lightGrey,
  darkGrey,
  green,
  blackText,
  darkText,
} from "../../../styles/colors";
import { MenuProps } from '../../../styles/select';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import * as df from '../../../utils/dataFetchers'
import { weightedAvgYield, weightedNutrientValue} from './helper'

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export function NBalance({ field, crops, cropYears, nFactorData, nFactorData1, nRate, nRate1, grainYield, grainYield1, precipData, gddData, setGddData, setPrecipData,
  setNRate, setNRate1, setGrainYield, setGrainYield1, yieldInfo, appYears, ayears, acres, nutrientParser }) {

  /**
   * Update Field Information
   * @param {Object} field selected field data
   */
  const classes = useStyles();

  const [weather, setWeather] = useState(['Precipitation', 'GDD'])
  const [selectedWeather, setSelectedWeather] = useState('Precipitation')
  var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'Sepetember', 'October', 'November', 'December']
  const [selectedMonth, setSelectedMonth] = useState(months[0])
  const [selectedCrop, setSelectedCrop] = useState('')
  const [years, setYears] = useState([])

  const [selectedYears, setSelectedYears] = useState([])
  const [selectedMonths, setSelectedMonths] = useState(['July', 'August'])
  const [isMonthChange, setIsMonthChange] = useState(false)

  const [display, setDisplay] = useState('yield')
  const [yieldData, setYieldData] = useState(null);
  const [weatherData, setWeatherData] = useState(null);


  useEffect(() => {
    if(crops.length > 0){
      setSelectedCrop(crops[0])
    }
  }, [crops])

  useEffect(() => {
    //console.log('selectedCrop', selectedCrop, yieldInfo)
    var weightedYieldRes = weightedAvgYield(selectedCrop, 'Nitrogen', yieldInfo)
    setGrainYield(weightedYieldRes.grainYield)
    setGrainYield1(weightedYieldRes.grainYield1)

    let nutrientRes = weightedNutrientValue('Nitrogen', nutrientParser, appYears, ayears, acres)
    setNRate1(nutrientRes.nRate1)
    setNRate(nutrientRes.nRate)

  }, [selectedCrop, yieldInfo,nutrientParser, appYears, ayears, acres])

  useEffect(() => {
    let menuList = cropYears.filter(op => op.crop == selectedCrop)
    let yearsList = []
    for (let menu of menuList) {
      yearsList.push(menu.year)
    }
    yearsList = [...new Set(yearsList)]
    setYears(yearsList)
    setSelectedYears(yearsList.sort((a, b) => a - b))
  }, [cropYears, selectedCrop]) 

  useEffect(() => {
    drawNBalance()
  }, [nFactorData, selectedCrop, nRate, grainYield])

  useEffect(() => {
    drawBalWeather()
  }, [nFactorData1, nRate1, grainYield1, selectedWeather, selectedCrop, precipData, gddData, selectedYears])

  useEffect(() => {    
    let vals = []
    for(let i=0; i<months.length;i++){
      for(let j=0; j<selectedMonths.length;j++){
        if(months[i] === selectedMonths[j]){
          vals.push(i)
        }
      }
    }
    let startMonth = Math.min(...vals) + 1
    let endMonth = Math.max(...vals) + 1
    if(isMonthChange){
      getPrecipData(startMonth, endMonth)
      getGdd(startMonth, endMonth)
      setIsMonthChange(false)
    }
  }, [selectedMonths, isMonthChange])

  const handleMonthChange = (event) => {
    setIsMonthChange(true)
    setSelectedMonths(event.target.value)
  }

  const handleYearChange = (event) => {
    setSelectedYears(event.target.value)
  }

  const getPrecipData = async (startMonth, endMonth) => {
    let allData = []

    if(selectedYears.length > 0){
      for(let year of selectedYears){
        let res = await df.getPrecipitation(field.longitude, field.latitude, year, startMonth, endMonth)
        res = JSON.parse(res)
        allData.push(res[0])
      }   
      setPrecipData(allData)
    }
  }

  const getGdd = async (startMonth, endMonth) => {
    let allData = []

    if(selectedYears.length > 0){
      for(let year of selectedYears){
        let res = await df.getGDD(field.longitude, field.latitude, year, startMonth, endMonth)
        res = JSON.parse(res)
        allData.push(res[0])
      }   
      setGddData(allData)
    }    
  }

  function drawNBalance() {
    //console.log('drawNBalance', selectedCrop, nFactorData)
    var cropName = selectedCrop
    var grainNRemoval = []

    var filteredCrop = nFactorData.filter((obj) => obj.cropName === cropName);
    console.log('filteredCrop 1', filteredCrop)

    for (var i = 0; i < grainYield.length; i++) {
        for (var j = 0; j < filteredCrop.length; j++) {
            if (grainYield[i].x == filteredCrop[j].cropSeason) {
                var obj = {
                    season: filteredCrop[j].cropSeason,
                    prod: filteredCrop[j].nFactor * grainYield[i].y
                }
                grainNRemoval.push(obj)
            }
        }
    }

    //console.log('grainNRemoval', grainNRemoval)

    let nBalance = []
    for (var i = 0; i < nRate.length; i++) {
        for (var j = 0; j < grainNRemoval.length; j++) {
            if (nRate[i].x == grainNRemoval[j].season) {
                var obj = {
                    x: grainNRemoval[j].season,
                    y: Math.round((nRate[i].y - grainNRemoval[j].prod) * 100)/100
                }
                nBalance.push(obj)
            }
        }
    }

    //console.log('nBalance', nBalance)

    nBalance = nBalance.slice(0);
    nBalance.sort(function (a, b) {
        var x = a.x;
        var y = b.x;
        return x < y ? -1 : x > y ? 1 : 0;
    })

    grainYield = grainYield.slice(0);
    grainYield.sort(function (a, b) {
        var x = a.x;
        var y = b.x;
        return x < y ? -1 : x > y ? 1 : 0;
    })

    var options = {
        title: {
            text: '<b>N-Balance vs. Yield</b>'
        },
        xAxis: {
            title: {
                text: '<b>Crop Season</b>'
            }
        },
        yAxis: [{
            title: {
                text: '<b>N-Balance (lbs)</b>'
            },
        }, {
            title: {
                text: '<b>Yield (bu./acre)</b>'
            },
            opposite: true
        }],
        legend: {
            enabled: true
        },
        series: [{
            name: 'N-Balance (lbs)',
            type: 'column',
            data: [{}],
            states: {
                inactive: {
                    opacity: 1
                }
            }
        },
        {
            name: 'Yield (bu./acre)',
            type: 'line',
            yAxis: 1,
            data: [{}],
            states: {
                inactive: {
                    opacity: 1
                }
            }
        }
        ],

        navigation: {
            menuItemStyle: {
                borderLeft: '10px solid #FFFFFF',
                fontSize: '15px',
                padding: "2px 10px"
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            //formatter: function () {
            //    return 'Amount of <b>' + type + '</b> applied per acre is <b>' + Math.round(this.y*100)/100 + 'lbs</b> in the year <b>' + this.x + '</b>.'
            //}
        },
    }
    //console.log('nBalance', nBalance)
    //console.log('grainYield', grainYield)
    options.series[0].data = nBalance;
    options.series[1].data = grainYield;
    //console.log('options',options)
    setYieldData(options)
  }

  function drawBalWeather() {
    var cropName = selectedCrop
    var weather = selectedWeather

    var grainNRemoval1 = [], nBalance1 = []

    var filteredCrop1 = nFactorData1.filter((obj) => obj.cropName === cropName);

    for (var i = 0; i < grainYield1.length; i++) {
        for (var j = 0; j < filteredCrop1.length; j++) {
            if (grainYield1[i].x == filteredCrop1[j].cropSeason) {
                var obj = {
                    season: filteredCrop1[j].cropSeason,
                    prod: filteredCrop1[j].nFactor * grainYield1[i].y
                }
                grainNRemoval1.push(obj)
            }
        }
    }

    //console.log('grainNRemoval1', grainNRemoval1)

    for (var i = 0; i < nRate1.length; i++) {
        for (var j = 0; j < grainNRemoval1.length; j++) {
            if (nRate1[i].x == grainNRemoval1[j].season) {
                var obj = {
                    x: grainNRemoval1[j].season,
                    y: Math.round((nRate1[i].y - grainNRemoval1[j].prod) * 100) / 100
                }
                nBalance1.push(obj)
            }
        }
    }

    nBalance1 = nBalance1.slice(0);
    nBalance1.sort(function (a, b) {
        var x = a.x;
        var y = b.x;
        return x < y ? -1 : x > y ? 1 : 0;
    })

    var selectedWeatherList = [];
    let precipitation = [], gdd =[]
    try{
      precipitation = precipData.filter(pre => selectedYears.includes(pre?.Year))
      gdd = gddData.filter(g => selectedYears.includes(g?.Year))
    }
    catch(err){
      console.log('error filtering precip/gdd data', err)
      precipitation = []
      gdd = []
    }

    //console.log('precipitationData',precipitationData)
    if (weather == 'Precipitation') {
        for (var i = 0; i < precipitation.length; i++) {
            if (precipitation[i] != undefined) {
                var obj = {
                    Year: precipitation[i].Year,
                    cumPrecip: precipitation[i].cumPrecip * 0.0394
                }
                selectedWeatherList.push(obj);
            }
        }
    }
    else if (weather == 'GDD') {
        for (var i = 0; i < gdd.length; i++) {
            if (gdd[i] != undefined) {
                var obj = {
                    Year: gdd[i].Year,
                    cumPrecip: gdd[i].cumGDD
                }
                selectedWeatherList.push(obj);
            }
        }
    }
    //console.log('selectedWeatherList', selectedWeatherList)

    let resultYears = selectedWeatherList.map(a => a.Year);
    let nBalanceYears = nBalance1.map(a => a.x);
    //console.log('resultYears', resultYears)
    //console.log('nBalanceYears', nBalanceYears)
    let finalUniqueYears = [], finalRepYears = []
    finalRepYears = resultYears.concat(nBalanceYears);
    finalUniqueYears = resultYears.filter(value => nBalanceYears.includes(value))
    //console.log(finalRepYears, finalUniqueYears)
    let finalYears = finalRepYears;
    finalYears = [...new Set(finalYears)]

    //console.log('finalYears', finalYears)

    var finalGraphData = [], objx, objy;

    for (var i = 0; i < finalYears.length; i++) {
        //console.log('finalYears[i]', finalYears[i])
        for (var x = 0; x < selectedWeatherList.length; x++) {
            //console.log('selectedWeatherList[x]', selectedWeatherList[x].Year)
            if (finalYears[i] == selectedWeatherList[x].Year) {
                objx = selectedWeatherList[x].cumPrecip
                //console.log('objx', objx)
            }
        }
        for (var y = 0; y < nBalance1.length; y++) {
            //console.log('nBalance1[y].x', nBalance1[y].x)
            if (finalYears[i] == nBalance1[y].x) {
                objy = nBalance1[y].y
            }
        }
        var obj = {
            x: Math.round(objx*100)/100,
            y: objy
        }
        finalGraphData.push(obj)
    }
    //console.log('finalGraphData', finalGraphData)

    for (var i = 0; i < finalGraphData.length; i++) {
        finalGraphData[i].x = finalGraphData[i].x == undefined ? 0 : finalGraphData[i].x
        finalGraphData[i].y = finalGraphData[i].y == undefined ? 0 : finalGraphData[i].y
    }
    //console.log('finalGraphData', finalGraphData)

    finalGraphData = finalGraphData.slice(0);
    finalGraphData.sort(function (a, b) {
        var x = a.x;
        var y = b.x;
        return x < y ? -1 : x > y ? 1 : 0;
    })

    var unit2 = '';
    if (weather == 'Precipitation') unit2 = '(inch)'

    var options = {
        title: {
            text: '<b>N-Balance vs. Weather</b>'
        },
        xAxis: {
            title: {
                text: '<b>' + weather + ' ' + unit2 + '</b>'
            }
        },
        yAxis: [{
            title: {
                text: '<b>N-Balance (lbs)</b>'
            },
        }],
        legend: {
            enabled: true
        },
        series: [{
            name: 'N-Balance (lbs)',
            type: 'scatter',
            data: [{}],
            states: {
                inactive: {
                    opacity: 1
                }
            }
        }],

        navigation: {
            menuItemStyle: {
                borderLeft: '10px solid #FFFFFF',
                fontSize: '15px',
                padding: "2px 10px"
            }
        },
        tooltip: {
            crosshairs: true,
            shared: true,
            formatter: function () {
                return '<b>N-Balance: ' + this.y + '(lbs), ' + weather + ': ' + this.x + unit2 + '</b>'
            }
        },
    }

    options.series[0].data = finalGraphData;
    setWeatherData(options)
    //console.log('finalGraphData', finalGraphData)
    //console.log('options', options)
  }

  return (
    <Box my={1} style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center">
        <Button
          color='primary'
          variant={display === 'yield' ? 'contained' : 'outlined'}
          onClick={() => setDisplay('yield')}
          style={{margin: '0 8px'}}
          disableElevation
        >
          N-Balance X Yield
        </Button>
        <Button
          color='primary'
          variant={display === 'weather' ? 'contained' : 'outlined'}
          onClick={() => setDisplay('weather')}
          style={{margin: '0 8px'}}
          disableElevation
        >
          N-Balance X Weather
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box
          display="flex"
          flexWrap="wrap"
          fontSize={18}
        >
          <Box m={1}>
            <Box>
              Crop
            </Box>
            <Select
              variant="outlined"
              MenuProps={MenuProps}
              style={{ width: 200, height: 40 }}
              value={selectedCrop}
              onChange={(e) => setSelectedCrop(e.target.value)}
            >
              {
                crops.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x}
                  >
                    {x}
                  </MenuItem>
                ))
              }
            </Select>
          </Box>
          {display === 'weather' && (
            <Box m={1}>
              <Box>
                Weather
              </Box>
              <Select
                variant="outlined"
                MenuProps={MenuProps}
                style={{ width: 200, height: 40 }}
                value={selectedWeather}
                onChange={(e) => setSelectedWeather(e.target.value)}
              >
                {
                  weather.map((x, i) => (
                    <MenuItem
                      key={i}
                      value={x}
                    >
                      {x}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
          )}
        </Box>
          { display === 'weather' && (
            <Box
              display="flex"
              flexWrap="wrap"
              fontSize={18}
            >
              <Box m={1}>
                <Box>
                  Crop Year
                </Box>
                <Select
                  id="years-mutiple"
                  multiple
                  value={selectedYears}
                  onChange={handleYearChange}
                  input={<Input id="select-multiple-years" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value, i) => (
                        <Chip key={i} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {years.map((year, i) => (
                    <MenuItem key={i} value={year}>
                      <Checkbox
                        color="primary"
                        checked={selectedYears.includes(year)}
                      />
                      <ListItemText primary={year} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>

              <Box m={1}>
                <Box>
                  Season Month
                </Box>
                <Select
                  id="months-mutiple"
                  multiple
                  value={selectedMonths}
                  onChange={handleMonthChange}
                  input={<Input id="select-multiple-months" />}
                  renderValue={(selected) => (
                    <div className={classes.chips}>
                      {selected.map((value, i) => (
                        <Chip key={i} label={value} className={classes.chip} />
                      ))}
                    </div>
                  )}
                  MenuProps={MenuProps}
                >
                  {months.map((month, i) => (
                    <MenuItem key={i} value={month}>
                      <Checkbox
                        color="primary"
                        checked={selectedMonths.includes(month)}
                      />
                      <ListItemText primary={month} />
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            </Box>
          )}

        </Box>


      <Box
        id='n-balance'
      >
        {
          (display === 'yield' && yieldData !== null) && (
            <HighchartsReact
              highcharts={Highcharts}
              options={yieldData}
            />
          )
        }

        {
          (display === 'weather' && weatherData !== null) && (
            <HighchartsReact
              highcharts={Highcharts}
              options={weatherData}
            />
          )
        }
      </Box>
    </Box>
  )
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { COLORS, MARKET_TABS } from "../../../../../../constants";
import InfoPopover from "../../../../../Helpers/InfoPopover/index";

const useStyles = makeStyles(() => ({
  row: {
    width: "100%",
    color: COLORS.text,
    fontSize: 14,
  },
  barContainer: {
    display: "inline-block",
    position: "relative",
    width: "100%",
    height: "20px",
    paddingLeft: 8,
    "&.second-row": {
      fontSize: 12,
      fontWeight: "normal",
    },
    "&.farm": {
      color: COLORS.green,
      paddingLeft: 20,
    },
  },
  differenceContainer: {
    fontSize: 12,
    width: "10%",
    minWidth: 60,
    fontWeight: "normal",
    color: COLORS.green,
    whiteSpace: "nowrap",
    "&.negative": {
      color: COLORS.red,
    },
    "& :first-child": {
      marginRight: "3px",
    },
  },
  label: {
    fontSize: 12,
    width: "10%",
    minWidth: 75,
    fontWeight: "normal",
    whiteSpace: "nowrap",
    paddingLeft: 10,
    color: COLORS.gray,
    "&.farm": {
      color: COLORS.green,
    },
  },
  title: {
    width: "10%",
    minWidth: 80,
    "&.wide": {
      whiteSpace: "nowrap",
      paddingLeft: 10,
    },
  },
  marker: {
    height: 17,
    width: 3,
    backgroundColor: COLORS.gray,
    float: "left",
    marginRight: 10,
    "&.farm": {
      backgroundColor: COLORS.green,
    },
  },
  markerInner: {
    position: "relative",
    height: "100%",
    width: 0,
    marginLeft: 1.5,
    backgroundColor: "inherit",
  },
  circle: {
    position: "absolute",
    width: 7,
    height: 7,
    borderRadius: "50%",
    top: "calc(50% - 3.5px)",
    left: -3.5,
    backgroundColor: "inherit",
  },
}));

/**
 * Renders the market report overview table header row
 * @param {object} headings object with column headers
 * @param {number} index used for styles and col width
 * @param {string} category the name of the active category
 */

const HeaderRow = ({ headings, index, category }) => {
  const classes = useStyles();

  return (
    <tr className={classes.row}>
      <td className={classes.title} />
      {!index ? (
        <td className={classes.barContainer}>
          <span style={{ float: "left" }}>{headings.col2[category]} </span>
          {category !== MARKET_TABS.Soil ? (
            <span style={{ float: "left" }}>
              <InfoPopover text="Some info goes here" />
            </span>
          ) : null}
        </td>
      ) : (
        <td className={`${classes.barContainer} second-row`}>
          <table>
            <tbody>
              <tr>
                <td>
                  <div className={classes.marker}>
                    <div className={classes.markerInner}>
                      <span className={classes.circle} />
                    </div>
                  </div>
                  <span>{headings.col2.one}</span>
                </td>
                <td className={`${classes.barContainer} second-row farm`}>
                  <div className={`${classes.marker} farm`}>
                    <div className={classes.markerInner}>
                      <span className={classes.circle} />
                    </div>
                  </div>
                  <span>{headings.col2.two}</span>
                </td>
              </tr>
            </tbody>
          </table>
        </td>
      )}
      <td className={classes.differenceContainer}>{headings.col3}</td>
      {category !== MARKET_TABS.Soil ? (
        <>
          <td />
          <td
            className={index ? classes.label : `${classes.title} wide`}
            colSpan={index ? "1" : "2"}
          >
            {!index ? (
              <>
                <span style={{ float: "left" }}>{headings.col4[category]}</span>
                <span style={{ float: "left" }}>
                  <InfoPopover text="info goes here" />
                </span>
              </>
            ) : (
              headings.col4
            )}
          </td>
          {index ? (
            <td className={`${classes.label} farm`}>{headings.col5}</td>
          ) : null}
        </>
      ) : null}
    </tr>
  );
};

export default HeaderRow;

HeaderRow.propTypes = {
  headings: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  category: PropTypes.string.isRequired,
};

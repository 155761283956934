// -------------------- IMPORTS --------------------
// React
import React from 'react';

// material-ui
import {
	Box,
	Button,
	Divider,
	Modal,
	Tooltip,
	Typography
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { darkText } from '../../../styles/colors';


// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
	body: {
		height: 'auto',
		width: '100%',
		padding: '0 20px',
		fontSize: 16,
		fontWeight: 500,
		color: theme.palette.text.black,
	},
	buttonBox: {
		...theme.buttonBox,
		alignItems: 'center',
		height: '60px',
		marginTop: '24px',
	},
	description: {
		fontSize: 14,
		color: theme.palette.text.grey,
	},
	head: {
		height: '50px',
		padding: '20px',
		alignItems: 'center',
		width: '100%',
		color: darkText,
		fontSize: 20,
		fontWeight: 500,
		display: 'flex',
	},
  icon: {
		...theme.icon,
	},
	paper: {
		...theme.modalPaper,
		position: 'absolute',
		height: 'auto',
		maxWidth: '500px',
	},
}));


// -------------------- MAIN FUNCTION --------------------

/**
 * User will be prompted to choose whether to save data for the new vs. loaded year 
 * @param {Number} commodityYear Current planting year
 * @param {Boolean} open Determine if modal is opened or closed
 * @param {Function} saveReportData Formats and saves the data to Zones table
 * @param {Function} saveWithOldYear Resets year to one used when loading data and calls saveReportData
 * @param {Function} setOpen Sets if modal is open
 * @param {Number} yearDataLoaded Planting year when data was loaded
 * @return {JSX} Modal for choosing
 */
export function SaveYearWarning({
	commodityYear,
	open,
	saveReportData,
	saveWithOldYear,
	setOpen,
	yearDataLoaded,
}) {
	// -------------------- VARIABLES --------------------
	const classes = useStyles();


  // -------------------- FUNCTIONALITY --------------------
	const warningModal = () => (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<Box
				className={classes.paper}
				borderRadius="borderRadius"
			>
				{/* X icon */}
				<Box display="flex" justifyContent="flex-end">
          <HighlightOffIcon
            className={classes.icon}
            style={{ position: 'absolute', marginTop: '10px' }}
            onClick={() => setOpen(false)}
          />
        </Box>

				{/* Modal Title */}
				<Box className={classes.head}>
					Warning: Different Year Selected
        </Box>

				{/* Modal Body */}
				<Box className={classes.body}>
					<Divider />
					<Box
						mt={2}
						display="flex"
						justifyContent="space-between"
						flexWrap="wrap"
					>
      			<Box p='8px 16px 8px' fontWeight={500} fontSize={16}>
							The year you have chosen to associate with your saved data is different from the year you originally loaded the data with.

							<Box className={classes.description} mt={1}>
								Click "Revert and Save" to use the original year ({yearDataLoaded}) for saving.
							</Box>
							<Box className={classes.description}>
								Click "Save" to save with the current year ({commodityYear}).
							</Box>
						</Box>
					</Box>
				</Box>

				{/* Modal Footer */}
				<Box className={classes.buttonBox}>
					<Box m={1}>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setOpen(false)}
							style={{ backgroundColor: '#ffffff' }}
						>
							Cancel
						</Button>
					</Box>

					<Box m={1}>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => {
								saveReportData();
								setOpen(false);
							}}
							style={{ backgroundColor: '#ffffff' }}
						>
							Save
						</Button>
					</Box>

					<Box m={1}>
						<Button
							variant="contained"
							color="primary"
							onClick={() => {
								saveWithOldYear();
								setOpen(false);
							}}
						>
							Revert and Save
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	)


	// -------------------- RETURN --------------------
	return (warningModal());
}
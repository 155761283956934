import { Endpoints } from "../../../constants/Endpoints";

export const getPrices = async (orgId, operationType, userToken = '', retry=true) => {
    let url = `${Endpoints.BASEURL}${Endpoints.GETPRICES}${orgId}&operationType=${operationType}`
    // &fieldId=${fieldId}
    // for (var y of years) {
    //     url += `&years[]=${y}`;
    // }
  try {
    let response; 
    if(userToken !== ''){
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          'Authorization': 'Bearer '+userToken
        },
        credentials: "include",
      });
    }else{
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
    }
    
    const jsonResponse = await response.json();
    const data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
    if(retry){
      getPrices(orgId, operationType, userToken, false)
    }
    return;
  }
}

import * as d3 from 'd3-geo'
import * as wkt from 'terraformer-wkt-parser';
import React, { useEffect, useState, useContext, Fragment } from 'react'
import {
  AppBar,
  Box,
  Button,
  Typography,
  Divider,
  Checkbox,
  Select,
  Modal,
  TextField,
  Card,
  CardActionArea, 
  CardContent, 
  Grid,
  Tooltip,
  MenuItem
 } from '@material-ui/core';
 import Autocomplete from '@material-ui/lab/Autocomplete';
 import { makeStyles } from '@material-ui/core/styles';
 import { MenuProps } from "../../styles/select";
 import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
 import HighlightOffIcon from '@material-ui/icons/HighlightOff';
 import ArrowBackIcon from "@material-ui/icons/ArrowBack";
 import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
 import SaveOutlinedIcon from '@material-ui/icons/SaveOutlined';
 import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
 import { FieldContext } from '../Context/FieldContext'
 import { UserContext } from "../Context/UserContext";
 import { useWindowDimensions } from '../../utils/dimensions'
 import { FieldSelection } from "../FieldSelection/FieldSelection";
 import { useSnackbar } from "notistack";
//  import { Steps } from './Navigation/steps'
//  import { Footer } from './Navigation/footer'
 import { lightGrey, green, blackText, grey } from '../../styles/colors'
 import { ComingSoon } from '../Home/ComingSoon';
 import * as mf from '../Maps/MapFunctions/helpers'
 import * as df from '../../utils/dataFetchers'
 import * as turf from "@turf/turf";
 import { cloneDeep, update } from "lodash";
 import { scrollTo, dollarFormat, exists } from '../../utils/helpers'
 import { WeatherChart } from './WeatherChart'
 import {ParcelDetails} from './ParcelDetails'


 const useStyles = makeStyles(theme => ({
  root: {
    // display: 'flex',
    // flexDirection: 'column',
    // justifyContent: 'space-between',
  },
  content: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'start',
    alignContent: 'flex-start',
    height: '100%',
    overflowY: 'auto',
    backgroundColor: theme.palette.greys.light
  },
  fieldItem: {
    background: 'white',
    height: '22%',
    minHeight: '200px',
    width: '23%',
    minWidth: '325px',
    borderRadius: '20px',
    padding: '10px',
    boxShadow: 'rgba(0, 0, 0, 0.21) 3px 3px 3px 3px',
    display: 'flex',
    justifyContent: 'space-evenly',
    marginLeft: '7px',
    marginRight:'3px',
    marginTop: '10px'
  },
  actionContainer:{
    marginLeft: '-10px',
    display: "flex",
    justifyContent: "space-between"
  },
  checkBox:{
    marginRight: '-10px',
  },
  checkContainer:{
    display:"flex",
    alignItems: "center"
  },
  detailsModal: {
    position: 'absolute',
    // backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    top: "20%",
    left:"40%",
    width: "25%",
    minHeight: '45%'
  },
  detailsContainer: {
    margin: '5px',
    display: "flex",
    flexDirection: 'column',
    marginLeft: '10%',
    backgroundColor: 'white'
  },
  disabledInput: theme.disabledInput,
  input: theme.plInput,
  close:{
    backgroundColor:'white',
    display:"flex",
    flexDirection: "column",
    justifyContent: "center",
    borderTopLeftRadius: "5px",
    borderTopRightRadius: "5px",
    marginTop: '5px',
    marginBottom: '-5px'
  },
  tab: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    padding: '5px',
    backgroundColor: '#cacaca',
    cursor: 'pointer'
  },
  activeTab: {
    borderTopLeftRadius: "10px",
    borderTopRightRadius: "10px",
    backgroundColor:'white',
    padding: '5px',
    // borderStyle: 'solid',
    // borderWidth: 'thin',
    // borderColor: '#cacaca'
  },
  activeWindowContainer: {
    backgroundColor:'white',
    borderBottomRightRadius: "10px",
    borderBottomLeftRadius: "10px",
    height: "100%"
  }
}))

function getModalStyle() {
  return {
    margin: 'auto',
    zIndex: 1002,
    height: '22%',
    padding: 1,
    // backgroundColor: 'white',
    borderRadius: '10px'
  };
}

 export function LoanSummary({
   loading,
   parcels,
   setParcels,
   refreshParcels,
   getComparables,
   documents,
   setDocuments,
   reports,
   setReports,
   summaryAverage,
   backText,
   setSection
 }){

  const mobileBreakPoint = 860
  const classes = useStyles()
  const modalStyle = getModalStyle()
  const [user, setUser] = useContext(UserContext);
  const fieldData = useContext(FieldContext)[0];
  
  const { enqueueSnackbar } = useSnackbar();
  const {height, width} = useWindowDimensions()

  const [selectedParcel, setSelectedParcel] = useState({})

  const [displayDetails, setDisplayDetails] = useState(false)
  const [selectedParcelDate, setSelectedParcelDate] = useState("")

  const [editName, setEditName] = useState(false)
  const [selectedParcelName, setSelectedParcelName] = useState("")
  const [editDocument, setEditDocument] = useState(false)
  const [selectedParcelDoc, setSelectedParcelDoc] = useState({})
  const [documentInput, setDocumentInput] = useState("")
  const [editReport, setEditReport] = useState(false)
  const [selectedParcelReport, setSelectedParcelReport] = useState({})
  const [reportInput, setReportInput] = useState("")
  const [editAppraisal, setEditAppraisal] = useState(false)

  const [activeDetails, setActiveDetails] = useState(true)



  useEffect(()=>{
    console.log("reports",reports)
    console.log("docs",documents)
  },[])


  useEffect(()=>{
    console.log(selectedParcel)
  }, [selectedParcel])


  const handleBackClick = () => {
    setSection(0)
  }

  const getPath = (field) => {
    //console.log(field)
    try {
      const shape = wkt.parse(field.WKT);
      // Logic below will attempt to get shape from fieldInformation, but causes deleting fields to break
      // let shape
      // if (field?.shape !== undefined) {
      //   console.log('field shape',field.shape)
      //   shape = wkt.parse(field.shape);
      // } else {
      //   // When field is claimed in another component, it can be missing shape
      //   console.log('field info', fieldInformation)
      //   shape = wkt.parse(fieldInformation[0].boundary);
      // }
      const width = 100
      const projection = d3.geoMercator()
      projection.fitExtent([[10, 10], [width, width]], shape);
      const geoPath = d3.geoPath(projection);
      return geoPath(shape)

    } catch (err) {
      console.log(err)
      return ''
    }
  }

  const formatDate = (dateString) => {
    if(exists(dateString)){
      let noT = dateString.split("T")[0]
      let brkn = noT.split("-")
      let correct = [brkn[1], brkn[2], brkn[0]].join("/")
      return correct
    }
    else{
      return
    }
    
  }

  const changeTabs = (tab) => {
    if(activeDetails && tab === "details"){
      return
    }
    if(!activeDetails && tab === "info"){
      return
    }
    setActiveDetails(!activeDetails)
  }

  const openDetails = (field, i) => {
    setSelectedParcel(field)
    setSelectedParcelName(field.FieldName)
    setEditAppraisal(!exists(field.AppraisalDate))
    setDisplayDetails(true)
  }

  const closeDetails = () => {
    //setSelectedParcel({})
    setDisplayDetails(false)
    setSelectedParcelDate("")
  }


  const updateParcel = async (type, value) => {
    let parcelCopy = {...selectedParcel}
    let exists = []
    let updateTo = null;
    let req = {
      FieldName: selectedParcel.FieldName, 
      FieldID: selectedParcel.FieldID,
      OrganizationID: selectedParcel.OrganizationID,
      BoundaryID: selectedParcel.BoundaryID
    }

    switch(type.toLowerCase()){
      case "appraisal":
        //call to update appraisal date
        parcelCopy.AppraisalDate = selectedParcelDate
        df.updateAppraisalDate(user.token, selectedParcel.BoundaryID, selectedParcelDate)
        setSelectedParcel(parcelCopy)
        setEditAppraisal(false)
        break;
      case "name":
        
        req['FieldName'] = selectedParcelName
        req['ReportName'] = selectedParcel.ReportName
        req['DocumentName'] = selectedParcel.DocumentID 
        let status = await df.updateParcelInfo(req, user.token)
        parcelCopy.FieldName = selectedParcelName
        //call to update in db
        setSelectedParcel(parcelCopy)
        setEditName(false)
        break

      case "document":
        let docName = "";
        if(selectedParcelDoc !== null){
          docName = selectedParcelDoc.name ? selectedParcelDoc.name : documentInput
        }
        else{
          docName = documentInput
        }

        exists = documents.filter(x => x.name === docName)
        if(exists.length === 0){
          //create new document
          let newDoc = await createNewDocument(docName)
          exists[0] = newDoc
        }
        
        //update parcels associated document
        updateTo = exists[0]

        //get all needed info for update endpoint
        // let parcelReport = reports.filter(x => x.name === selectedParcel.ReportName)

        //dont include id of report or endpoint may think we are updating report
        req['DocumentID'] = updateTo.id
        req['DocumentName'] = updateTo.name
        req['ReportName'] = selectedParcel.ReportName //need report name for fieldinfo table update

        let docStatus = await df.updateParcelInfo(req, user.token)
        console.log(docStatus)

        let docCopy = [...documents]
        docCopy.push(updateTo)
        setDocuments(docCopy)

        parcelCopy.DocumentID = docName
        setSelectedParcel(parcelCopy)
        setSelectedParcelDoc({})
        setDocumentInput("")
        setEditDocument(false)

        break;
      case "report":
        let reportName = ""
        if(selectedParcelReport !== null){
          reportName = selectedParcelReport.name ? selectedParcelReport.name : reportInput
        }
        else{
          reportName = reportInput
        }

        exists = reports.filter(x => x.name === reportName)
        if(exists.length === 0){
          //create new report
          let newReport = await createNewReport(reportName)
          exists[0] = newReport
        }

        //update parcels associated report
        updateTo = exists[0]

        req['ReportName'] = updateTo.name
        req['ReportID'] = updateTo.id
        req['DocumentName'] = selectedParcel.DocumentID

        let reportStatus = await df.updateParcelInfo(req, user.token)

        let repCopy = [...reports]
        repCopy.push(updateTo)
        setReports(repCopy)
        
        parcelCopy.ReportName = reportName
        setSelectedParcel(parcelCopy)
        setSelectedParcelReport({})
        setReportInput("")
        setEditReport(false)
        break
      default:
        break;
    }
    refreshParcels()
  }

  const createNewDocument = async (name) => {
    let newID = await df.getNewGUID("Farms")
    let orgID = selectedParcel.OrganizationID
    console.log(newID, name, orgID)
    await df.createNewFarm(newID, name, orgID.toString(), 'Ag-Analytics', user.token)
    let newDoc = {id: newID, name: name}
    //update document list?

    return newDoc
  }

  const createNewReport = async (name) => {
    let newID = await df.getNewGUID("Clients")
    let orgID = selectedParcel.OrganizationID
    await df.createClient(newID, name, orgID, 'Ag-Analytics', user.token)
    let newReport = {id: newID, name:name}
    //update report list?

    return newReport
  }

  const handleAutoCompleteChange = (val, type) => {
    if(type === "document"){
      if(val !== null){
        setDocumentInput(val.name)
        setSelectedParcelDoc(val)
      }
      else{
        setSelectedParcelDoc(val)
        setDocumentInput("")
      }
    }
    else{
      if(val !== null){
        setReportInput(val.name)
        setSelectedParcelReport(val)
      }
      else{
        setReportInput("")
        setSelectedParcelReport(val)
      }
    }
  }

  //send request to server to insert/update record into db
  const markCollateral = async (checked, index) => {
    console.log(checked)
    let collat = checked ? 1 : 0
    console.log("collat", collat)
    let field = parcels[index]
    df.updateCollateral(user.token, field.BoundaryID, field.FieldID, field.OrganizationID, collat)
    //set value?
    let parcelCopy = [...parcels]
    parcelCopy[index].Collateral = collat
    setParcels(parcelCopy)
  }

  const drawFieldShape = (field, i) => {
    return (
      <Box key={i} className={classes.fieldItem}>
        <Box style={{marginRight: '5px'}}>
          <Typography variant="h5" component="h2">
            {field.FieldName}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            <svg id={"shapesvg" + i} width='110' height='110'>
              <path fill="none" stroke="#F05922" strokeWidth="3" d={getPath(field, i)} />
            </svg>
          </Typography>
        </Box>

        <Box style={{marginLeft: '5px'}}>
          <Typography variant="h6" color="textSecondary" >
            Acres: {Math.round(field.Acres * 100) / 100}
          </Typography>
          <Typography variant="h6" color="textSecondary" style={{fontSize: '12pt'}}>
            AutoComp Value: {field.Value ? `${dollarFormat(field.Value)}/acre` : 'N/A'}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Document: {field.DocumentID}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Report: {field.ReportName}
          </Typography>
          <Typography variant="body2" color="textSecondary" component="p">
            Appraisal Date: {formatDate(field.AppraisalDate)}
          </Typography>
          <Box className={classes.actionContainer}>
            <Typography className={classes.checkContainer} variant="body1">
              <Checkbox className={classes.checkBox} checked={field.Collateral} size={"small"} onChange={(e) => markCollateral(e.target.checked, i)}/> 
              <span>Collateral</span>
            </Typography>
            <Button size="small" variant="outlined" onClick={() => openDetails(field, i)}>Parcel Details</Button>
          </Box>
        </Box>
      </Box>
    )
  }

  const summaryFooter = () => (
    <Box
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ height: "60px", width: '100%' }}
    >
      
      <Button
        variant="contained"
        color="primary"
        style={{ height: "45px", marginLeft: '1rem'}}
        onClick={() => handleBackClick()}
        disableElevation
      >
        <ArrowBackIcon />
        {`${backText} `}
      </Button>
      
    </Box>
  )

  const parcelDetails = () => (
    <Modal
      open={displayDetails}
      onClose={() => closeDetails()}
    >
      <Box
        className={classes.detailsModal}
        style={modalStyle}
      >
        <Box p={1} display="flex" justifyContent="space-between" style={{ width: '100%', padding:"0px" }}>
          <Box display="flex">
            <Box onClick={() => changeTabs("details")} fontWeight={500} fontSize={18} color={blackText} className={activeDetails ? classes.activeTab : classes.tab}>
              Parcel Details
            </Box>
            <Box onClick={() => changeTabs("info")} fontWeight={500} fontSize={18} color={blackText} className={!activeDetails ? classes.activeTab : classes.tab}>
              Other Information
            </Box>
          </Box>
          <Box className={classes.close}>
            <HighlightOffIcon onClick={() => closeDetails()} style={{cursor: 'pointer'}}/>
          </Box>
        </Box>
        {/* <Divider /> */}
        <Box className={classes.activeWindowContainer}>
          {activeDetails ?
            <Box className={classes.detailsContainer}>{/** details container */}
              <Box>
                <Box>
                  Parcel Name
                </Box>
                {editName ?
                  <Box display="flex">
                    <TextField
                      className={classes.input}
                      variant="outlined"
                      type="text"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={selectedParcelName}
                      onChange={(event) => setSelectedParcelName(event.target.value)}
                    />
                    <Tooltip title="Save Changes">
                      <Button onClick={() => updateParcel("name")}>
                        <SaveOutlinedIcon/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Close">
                      <Button onClick={() => setEditName(false)}>
                        <HighlightOffIcon/>
                      </Button>
                    </Tooltip>
                  </Box>
                :
                  <Box display="flex">
                    <Box className={classes.disabledInput}>
                      {selectedParcel.FieldName}
                    </Box>
                    <Tooltip title="Edit Parcel Name">
                      <Button onClick={() => setEditName(true)}>
                        <EditOutlinedIcon/>
                      </Button>
                    </Tooltip>

                  </Box>
                }
              </Box>
              
              <Box>
                <Box>
                  Document
                </Box>
                {editDocument ?
                  <Box display="flex">

                    <Autocomplete
                      className={classes.input}
                      freeSolo
                      options={documents}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                      onChange={(e, val) => handleAutoCompleteChange(val, "document")}
                      value={selectedParcelDoc}
                      inputValue={documentInput}
                      onInputChange={(event, newInputValue) => {
                        setDocumentInput(newInputValue);
                      }}
                    />

                    <Tooltip title="Save Changes">
                      <Button onClick={() => updateParcel("document")}>
                        <SaveOutlinedIcon/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Close">
                      <Button onClick={() => setEditDocument(false)}>
                        <HighlightOffIcon/>
                      </Button>
                    </Tooltip>
                  </Box>
                :
                  <Box display="flex">
                    <Box className={classes.disabledInput}>
                      {selectedParcel.DocumentID}
                    </Box>
                    <Tooltip title="Edit Document">
                      <Button onClick={() => setEditDocument(true)}>
                        <EditOutlinedIcon/>
                      </Button>
                    </Tooltip>

                  </Box>
                }
              </Box>
              
              <Box>
                <Box>
                  Report
                </Box>
                {editReport ?
                  <Box display="flex">

                    <Autocomplete
                      className={classes.input}
                      freeSolo
                      options={reports}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => <TextField {...params} variant="outlined" />}
                      onChange={(e, val) => handleAutoCompleteChange(val, "report")}
                      value={selectedParcelReport}
                      inputValue={reportInput}
                      onInputChange={(event, newInputValue) => {
                        setReportInput(newInputValue);
                      }}
                    />

                    <Tooltip title="Save Changes">
                      <Button onClick={() => updateParcel("report")}>
                        <SaveOutlinedIcon/>
                      </Button>
                    </Tooltip>
                    <Tooltip title="Close">
                      <Button onClick={() => setEditReport(false)}>
                        <HighlightOffIcon/>
                      </Button>
                    </Tooltip>
                  </Box>
                :
                  <Box display="flex">
                    <Box className={classes.disabledInput}>
                      {selectedParcel.ReportName}
                    </Box>
                    <Tooltip title="Edit Report">
                      <Button onClick={() => setEditReport(true)}>
                        <EditOutlinedIcon/>
                      </Button>
                    </Tooltip>

                  </Box>
                }
              </Box>
            
            <Box>
                <Box>
                  Appraisal Date
                </Box>
                {editAppraisal ?
                  <Box
                  display='flex'
                  flexWrap='wrap'
                  alignItems='center'
                  >
                    <TextField
                      className={classes.input}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={selectedParcelDate}
                      onChange={(event) => setSelectedParcelDate(event.target.value)}
                    />
                    <Tooltip title="Save Changes">
                      <Button onClick={() => updateParcel("appraisal")}>
                        <SaveOutlinedIcon/>
                      </Button>
                    </Tooltip>

                    {exists(selectedParcel.AppraisalDate) && 
                      <Tooltip title="Close">
                        <Button onClick={() => setEditAppraisal(false)}>
                          <HighlightOffIcon/>
                        </Button>
                      </Tooltip>
                    }
                    
                  </Box>
                :
                  <Box display="flex">
                    <Box className={classes.disabledInput}>
                      {formatDate(selectedParcel.AppraisalDate)}
                    </Box>
                    <Tooltip title="Edit Appraisal Date">
                      <Button onClick={() => setEditAppraisal(true)}>
                        <EditOutlinedIcon/>
                      </Button>
                    </Tooltip>
                  </Box>
                  

                }
                
              </Box>
            </Box>
          :
          <Box>
            {selectedParcel.SSURGO &&
              <Box display="flex">{/** SSURGO info */}
                <Box>
                  Field NCCPI: {selectedParcel.SSURGO.NCCPI.filter(x => x.Variable_Name==="NCCPI")[0].Value.toFixed(3)}
                </Box>
                <Box style={{marginLeft: "10px"}}>
                  County Average NCCPI: {selectedParcel.SSURGO.NCCPI.filter(x => x.Variable_Name==="County_Avg_NCCPI")[0].Value.toFixed(3)}
                </Box>
              </Box>
            }
            {selectedParcel.Polaris && 
              <Box display="flex">{/** POLARIS info */}
                <Box>
                  Field Mean PH: {selectedParcel.Polaris.ph_mean_30_60_Mean[0].Value.toFixed(3)}
                </Box>
                <Box style={{marginLeft: "10px"}}>
                  County Average PH: {selectedParcel.Polaris.ph_mean_30_60_CountyAvg[0].Value.toFixed(3)}
                </Box>
              </Box>
            }
            {selectedParcel.Weather && 
              <Box>
                <WeatherChart data={selectedParcel.Weather} type={"Precipitation"}/>
              </Box>
            }
            
          </Box>
          }

        </Box>
      </Box>
    </Modal>
  )

  return(
    <Box>
      <Box className={classes.content} style={{height: height - 215}}>
        {parcels.map((field, i) => drawFieldShape(field, i))}
      </Box>
      {/* {parcelDetails()} */}
      <ParcelDetails 
        selectedParcel={selectedParcel} 
        setSelectedParcel={setSelectedParcel}
        getComparables={getComparables}
        open={displayDetails}
        setOpen={setDisplayDetails}
        documents={documents}
        setDocuments={setDocuments}
        reports={reports}
        setReports={setReports}
        refreshParcels={refreshParcels}
      />
      {summaryFooter()}
    </Box>

  )

 }
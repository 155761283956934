import React from "react";
import PropTypes from "prop-types";
import { makeStyles, MenuItem, Select } from "@material-ui/core";
import { COLORS } from "../../../constants";
import InfoPopover from "../../Helpers/InfoPopover";
import CogIcon from "../../../images/gear.svg";
import SyncArrowsIcon from "../../../images/sync_arrows.svg";
import ThreeDotsIcon from "../../../images/three_dots.svg";
import { useDimensions } from "../../../utils";
import { IntegrationLinks } from "../../Partners/Integrate";

const useStyles = makeStyles({
  container: ({ mobileView }) => ({
    width: 250,
    //height: "100%",
    backgroundColor: COLORS.veryLightGrey,
    display: mobileView ? "none" : "inline",
    position: "fixed",
    zIndex: 1,
    //top: 56,
    left: 0,
    overflowX: "hidden",
    padding: 15,
    maxHeight: "750px"
  }),
  integrationContainer: ({ mobileView }) => ({
    width: 300,
    height: "100%",
    backgroundColor: COLORS.veryLightGrey,
    display: mobileView ? "none" : "inline",
    position: "fixed",
    zIndex: 1,
    //top: 56,
    left: 0,
    overflowX: "hidden",
    padding: 15,
  }),
  yearContainer: {
    width: "60%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: 15,
    paddingBottom: 10,
    "& .inner__container": {
      display: "flex",
      flexDirection: "column",
      fontWeight: "bold",
      "& .year": {
        fontSize: 14,
        color: COLORS.gray,
      },
      "& .MuiInputBase-root": {
        fontWeight: "bold",
        fontSize: 16,
        color: COLORS.darkGray,
        "&:before": {
          content: "none",
        },
      },
    },
  },
  dataSyncContainer: {
    display: "flex",
    flexDirection: "column",
    padding: "10px 0",
    margin: "10px 0",
    borderTop: `1px solid ${COLORS.lightGray}`,
    borderBottom: `1px solid ${COLORS.lightGray}`,
    fontWeight: "normal",
    lineHeight: "22px",
    "& .ag__data": {
      display: "flex",
      flexDirection: "row",
      color: COLORS.darkGray,
      fontSize: 14,
      fontWeight: "bold",
      justifyContent: "space-between",
      alignItems: "center",
      "& .inner__container": {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        "& :first-child": {
          paddingRight: 5,
        },
      },
    },
    "& .last": {
      color: COLORS.gray,
      fontSize: 13,
    },
    "& .now": {
      color: COLORS.green,
      fontSize: 13,
      fontWeight: "bold",
    },
  },
  organizationContainer: {
    fontSize: 16,
    fontWeight: "bold",
    "& .name": {
      color: COLORS.darkGray,
      marginBottom: 15,
    },
  },
  farmContainer: {
    color: COLORS.gray,
    display: "flex",
    flexDirection: "column",
    borderRadius: 5,
    "& .farm": {
      cursor: "pointer",
      padding: "12px 2px 8px 14px",
      width: 214,
      height: 42,
      backgroundColor: "inherit",
      "&.active": {
        backgroundColor: COLORS.white,
        color: COLORS.green,
      },
    },
  },
});

/**
 * Renders the field report
 * @param {array} farms array of farm objects with data, id, name and active properties
 * @param {function} setFarms set state function to set active farm
 * @param {array} years array of objects with value and active properties
 * @param {function} setYears set state function to set active year
 * @param {function} nameAccessor function to access farm name
 * @param {boolean} mobileView for mobile views
 */

const Sidebar = ({
  farms,
  setFarms,
  years,
  setYears,
  nameAccessor = (d) => d.name,
  idAccessor = (d) => d.id,
  mobileView,
  orgs,
  setOrgs,
  fromProfitLayers,
  authenticated,
}) => {
  const classes = useStyles({ mobileView });
  const { width, height } = useDimensions();

  const onClick = (farm) => () => {
    setFarms(
      farms.map((d) => ({
        ...d,
        active: idAccessor(d) === idAccessor(farm),
      }))
    );
  };

  const onYearChange = (evt) =>
    setYears(
      years.map((year) => ({
        ...year,
        active: year.value === evt.target.value,
      }))
    );

  const onOrgChange = (evt) =>
    setOrgs(
      orgs.map((org) => ({
        ...org,
        active: org.ID === evt.target.value,
      }))
    );

  // Get active year
  const activeYear = years.find((year) => year.active).value;
  let activeOrg = "";
  if (orgs.length > 0) {
    activeOrg = orgs.find((org) => org.active).ID;
  }

  return (
    <>
      {authenticated ? (
        <div className={classes.container} style={{ height: height - 195 }}>
          <div className={classes.yearContainer}>
            <div className="inner__container">
              <div className="year">Year</div>
              <Select
                id="year-select"
                value={activeYear}
                onChange={onYearChange}
              >
                {years.map((year) => (
                  <MenuItem
                    key={`year-menu-item-for-${year.value}`}
                    value={year.value}
                  >
                    {year.value}
                  </MenuItem>
                ))}
              </Select>
            </div>
            {/* <img src={CogIcon} alt="more options related to year select" /> */}
          </div>

          {!fromProfitLayers && (
            <div className={classes.yearContainer}>
              <div className="inner__container">
                <div className="org">Organizations</div>
                <Select
                  id="org-select"
                  value={activeOrg}
                  onChange={onOrgChange}
                >
                  {orgs.map((org) => (
                    <MenuItem
                      key={`org-menu-item-for-${org.ID}`}
                      value={org.ID}
                    >
                      {org.Name}
                    </MenuItem>
                  ))}
                </Select>
              </div>
            </div>
          )}

          <div className={classes.organizationContainer}>
            <div className="name">Farms</div>
            <div className={classes.farmContainer}>
              {farms.map((farm) => {
                return (
                  <div
                    key={`farm-row-for-${idAccessor(farm)}`}
                    onClick={onClick(farm)}
                    className={`farm ${farm.active ? "active" : ""}`}
                  >
                    {nameAccessor(farm)}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      ) : (
        <div
          className={classes.integrationContainer}
          style={{ height: height - 195 }}
        >
          <IntegrationLinks />
        </div>
      )}
    </>
  );
};

export default Sidebar;

Sidebar.propTypes = {
  farms: PropTypes.array.isRequired,
  setFarms: PropTypes.func.isRequired,
  years: PropTypes.array.isRequired,
  setYears: PropTypes.func.isRequired,
  nameAccessor: PropTypes.func,
  mobileView: PropTypes.bool.isRequired,
};

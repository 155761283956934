import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
	Box,
  CircularProgress,
  LinearProgress,
  Button,
  Divider,
  TextField,
  Popover,
  Chip,
  Typography,
  Checkbox,
  Menu,
  MenuItem,
} from '@material-ui/core';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { Endpoints } from '../../../constants/Endpoints';
import { useWindowDimensions } from '../../../utils/dimensions';
import { green } from '../../../styles/colors';


const useStyles = makeStyles((theme) => ({
  paper: theme.updateOperationModal,
  head: theme.updateOperationHeader,
  body: theme.updateOperationBody,
  footer: theme.updateOperationFooter,
  mapControls: theme.mapControls,
  details: theme.plDetails,
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  errorMessageBox: theme.errorMessageBox,
  selections: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: 16,
    width: '200px',
  },
  dropdown: {
    ...theme.hover,
    fontSize: '14px',
    marginLeft: '5px',
  },
}));

export function DownloadOperationFiles({
	operationInfo
}) {
	const classes = useStyles();
  const { height, width } = useWindowDimensions();


	const [downloadsPopoverOpen, setPopoverOpen] = useState(false);
  const [downloadsPopoverAnchor, setPopoverAnchor] = useState(null);
  const [downloads, setDownloads] = useState(undefined);
  const [downloadsLoading, setDlLoading] = useState(false);


	useEffect(() => {
		if(operationInfo !== null && operationInfo !== undefined){
			let url = `${Endpoints.BASEURL}/api/ProfitTool/DeereOperation/blob`;
			url += `?orgID=${operationInfo.orgID}`;
			url += `&fieldID=${operationInfo.fieldID}`;
			url += `&operationID=${operationInfo.operationID}`;
			url += `&operationType=Seeding`;
			url += `&source=${operationInfo.source}`;

			setDlLoading(true);
			fetch(url, {
				method: 'GET',
				credentials: 'include',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
				},
			})
				.then((res) => {
					if (res.status === 200) {
						return res.json();
					}
					// console.log("Failed to get file manifest");
					// console.log(res);
					setDlLoading(false);
				})
				.then((res) => {
					setDownloads(JSON.parse(res));
					setDlLoading(false);
				})
				.catch((err) => {
					// console.log("Download file manifest failed", err);
					setDlLoading(false);
				});
		}
  }, [operationInfo]);


	const handleDownloadClick = (fileType, fileName) => {
    /** When a filename is clicked on for download, send the request and try to dl it * */
    let url = `${Endpoints.BASEURL}/api/ProfitTool/DeereOperation/blob/download`;
    url += `?orgID=${operationInfo.orgID}`;
    url += `&fieldID=${operationInfo.fieldID}`;
    url += `&operationID=${operationInfo.operationID}`;
    url += `&operationType=Seeding`;
    url += `&fileType=${fileType}`;
    url += `&fileName=${fileName}`;
    url += `&source=${operationInfo.source}`;
    // console.log("url", url);

    // init the download
    document.location.href = url;
  };


	return (
		<Box mx={2} color={green} fontWeight={600} style={{marginBottom: '10px'}}>
			<Button
				color="primary"
				variant="contained"
				onClick={(e) => {
					setPopoverAnchor(e.currentTarget);
					setPopoverOpen(!downloadsPopoverOpen);
				}}
				disabled={downloads === undefined}
			>
				Operation Files
				{downloadsLoading && (
					<CircularProgress size={16} style={{ marginLeft: 2 }} />
				)}
				{downloads && (
					<>
						<ExpandMoreIcon />
						<Popover
							id="downloads-popover"
							open={downloadsPopoverOpen}
							anchorEl={downloadsPopoverAnchor}
							onClose={() => {
								setPopoverAnchor(null);
								setPopoverOpen(false);
							}}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center',
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'center',
							}}
						>
							<Box style={{ padding: '1em' }}>
								{downloads.shapefiles && downloads.shapefiles.length > 0 && (
									<>
										<Box className={classes.edit} fontSize={18}>
											Shapefiles
											{downloads.shapefiles.map((s, i) => (
												<Box
													key={i}
													onClick={() => handleDownloadClick('shapefile', s)}
													style={{ marginLeft: '2em', cursor: 'pointer' }}
												>
													{s}
												</Box>
											))}
										</Box>
									</>
								)}

								{downloads.rasters && downloads.rasters.length > 0 && (
									<>
										<Divider />
										<Box
											className={classes.edit}
											fontSize={18}
											style={{ paddingTop: '0.5em' }}
										>
											Rasters
											{downloads.rasters.map((r, i) => (
												<Box
													key={i}
													onClick={() => handleDownloadClick('raster', r)}
													style={{ marginLeft: '2em', cursor: 'pointer' }}
												>
													{r}
												</Box>
											))}
										</Box>
									</>
								)}

								{downloads.adapt && downloads.adapt.length > 0 && (
									<>
										<Divider />
										<Box
											className={classes.edit}
											fontSize={18}
											style={{ paddingTop: '0.5em' }}
										>
											Adapt Files
											{downloads.adapt.map((a, i) => (
												<Box
													key={i}
													onClick={() => handleDownloadClick('adapt', a)}
													style={{ marginLeft: '2em', cursor: 'pointer' }}
												>
													{a}
												</Box>
											))}
										</Box>
									</>
								)}
							</Box>
						</Popover>
					</>
				)}
			</Button>
		</Box>
	)

}





import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Select,
  MenuItem,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ResultsMap } from './ResultsMap';
import { fileDisplay } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  table: {
    minWidth: 280,
  },
  selections: theme.selections,
}));

/**
 * Displays shape file and associated data in table. User will be able to select
 * their shape file from dropdown, at which point we will call getGeoJsonFromShape
 * to convert the shape file into a GeoJSON which can be displayed on map. The
 * GeoJSON features may come back as polygons or points. Type will be set to
 * alert map to which format to expect. Shape files will be included in an object,
 * where key seperates the different types and value is array of files.
 * @param {String} boundary Stringified field boundary
 * @param {Object} shapeFiles Files to be selected for GeoJSON conversion and display
 * @param {Funtion} getGeoJson Converts shape file to geoJsons and adds to list
 * @returns {JSX} Map and table
 */
export function ResultsMapDisplay({ boundary, shapeFiles, getGeoJson }) {
  const classes = useStyles();

  const [geoJson, setGeoJson] = useState(null);
  const [selected, setSelected] = useState('');

  useEffect(() => {
    if (shapeFiles?.length) {
      handleChange(shapeFiles[0]);
    }
  }, [shapeFiles]);

  const handleChange = async (file) => {
    setSelected(file);
    const convertedShape = await getGeoJson(file);
    if (convertedShape?.GeoJSON) {
      setGeoJson(convertedShape.GeoJSON);
    }
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="h6">
          View your Shape Files
        </Typography>
        <Box pl={1}>
          <Typography>
            Select a Shape File from the dropdown, then once its loaded, view on map.
          </Typography>
        </Box>
      </Box>

      <Box pb={0.5}>
        <Select
          className={classes.selections}
          variant="outlined"
          value={selected || ''}
          onChange={(e) => handleChange(e.target.value)}
        >
          {shapeFiles.map((file) => (
            <MenuItem key={file} value={file}>
              {fileDisplay(file)}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <ResultsMap
        boundary={boundary}
        mapGeo={geoJson}
      />
    </Box>
  );
}

ResultsMapDisplay.propTypes = {
  boundary: PropTypes.string.isRequired,
  shapeFiles: PropTypes.array.isRequired,
  getGeoJson: PropTypes.func.isRequired,
};

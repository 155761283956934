import React, { useEffect, useState } from 'react';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import * as _ from 'lodash'
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import '../../Maps/leaflet.css'
import { exists } from '../../../utils/helpers'

/**
 * Displays image overlayers on field boundary determined by extent. Attaches
 * click event listener function to field.
 * @param  {String || Object} boundary Field feature boundary containing polygon points
 * @param  {Function} selectField Attach to field click
 * @param  {String} mapImage png base64 image to overlay on field
 * @param  {Array} extent Coordinates used to overlay image
 */
export function Map({ boundary, selectField, mapImage, extent }) {
  /*
    Map used simply for image overlays. Example uses are Profit Map and UAV Reports.
    Current version likely retains more functionality than will be necessary.
   */
    const [map, setMap] = useState(null)
    const [newPoint, setNewPoint] = useState([])
    const [selectedField, setSelectedField] = useState({ feature: null })

    const fieldLayer = L.featureGroup(null)

    useEffect(() => {
        drawMap()
    }, [])

    useEffect(() => {
      if(exists(boundary) && map !== null) {
        try {
          const features = typeof(boundary) === 'object' ? boundary : JSON.parse(boundary)
          const geoJson = {
              "type": "FeatureCollection",
              "features": [features]
          }
          drawFieldLayer(geoJson)
          fieldLayer.on('click', function () {
            selectField();
          })
        } catch (err) {
          console.log(err)
        }

      }
    }, [boundary, map])

    useEffect(() => {
      if(exists([map, extent, mapImage])) {
        if (extent[0] !== undefined && extent[0][0] !== undefined) {
          const image = L.imageOverlay(mapImage, extent, {opacity:0.8, interactive: true})
          image.on('click', function(d) {selectField()})
          image.addTo(map)
          const lat = (extent[0][0] + extent[1][0]) / 2
          const long = (extent[0][1] + extent[1][1]) / 2
          map.panTo(new L.LatLng(lat, long));
        }
      }

    }, [mapImage, extent, map])

    useEffect(() => {
      if(exists([map, boundary, mapImage]) && extent.length < 2) {
        try {
          const features = typeof(boundary) === 'object' ? boundary : JSON.parse(boundary)
          const bounds = features.geometry.coordinates[0].map(x => [x[1], x[0]])
          const image = L.imageOverlay(mapImage, bounds, {opacity:0.8, interactive: true})
          image.on('click', function(d) {selectField()})
          image.addTo(map)
        } catch (err) {
          console.log(err)
        }

      }
    }, [mapImage, boundary, map])


    async function drawMap() {
        const mapboxTiles = L.tileLayer(
            "https://api.mapbox.com/styles/v1/reactmap/ck899atuy0qrh1irw3x0pv2fl/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmVhY3RtYXAiLCJhIjoiY2s4OTk3dGphMDN2MTNlcGJvMDdlM3F4MCJ9.w1Mq06KCEsO1RQ-LA_s5Iw"
        )

        const provider = new OpenStreetMapProvider();
        const map = L.map('map', {
            editable: true,
            editOptions: {
                lineGuideOptions: {
                    opacity: 0
                }
            }
        }).setView([41.0160, -92.4083], 5).addLayer(mapboxTiles);

        function getGeom() {
            var bounds = map.getBounds()
            var max = bounds.getNorthEast()
            var min = bounds.getSouthWest()

            var geomParam = {
                "xmin": min.lng,
                "ymin": min.lat,
                "xmax": max.lng,
                "ymax": max.lat,
                "spatialReference": { "wkid": 4326 }
            }
            return geomParam
        }

        setMap(map)
        map.addLayer(fieldLayer);
    }

    function drawFieldLayer(geo) {
        try {
            const geometry = geo.features[0].geometry
            const boundary = geometry.type === 'Polygon' ? turf.polygon(geo.features[0].geometry.coordinates) : turf.multiPolygon(geo.features[0].geometry.coordinates)
            let newCollection = L.geoJson(boundary, {
                onEachFeature: (feature, layer) => {
                    fieldLayer.addLayer(layer).setStyle({ color: "red", "opacity": 0.99, "fillOpacity": .20 })
                }
            })
            map.fitBounds(newCollection.getBounds());
        } catch (err) {
            console.log(err)
        }
    }

    return (
      <div id="map" style={{ height: '100%', width: '100%' }}></div>
    )
}

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, IconButton } from "@material-ui/core";
import SettingsIcon from "@material-ui/icons/Settings";
import SyncIcon from '@material-ui/icons/Sync';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { CustomToolTip } from '../../../../utils/customComponents'
import { numFormat } from '../../../../utils/helpers'

const useStyles = makeStyles((theme) => ({
  infoToolTip: theme.infoToolTip,
}));

export function MobileHeader({
  yearSelection,
  seededAcres,
  openSettings,
  resetData
}) {
  const classes = useStyles();

  return (
    <Box ml={1} display='flex' alignItems='center'>
      { yearSelection() }

      <Box ml={1} fontWeight={500}>
        <Box display='flex'>
          {'Net Seeded Area'}
          <CustomToolTip
            title='Acreage is the area encompassed by the operation and recalculates area to correct for overlap. This may differ from other precision ag systems that do not remove overlap or account for the width of equipment. This will not affect the accuracy of your ProfitLayers themselves, only the calculation of certain summaries. To manually override your Net Seeded Acreage, open the report settings by selecting the Gear Icon, then enter your desired value and Save to exit.'
            placement='bottom-end'
          >
            <InfoOutlinedIcon className={classes.infoToolTip}/>
          </CustomToolTip>
        </Box>

        <Box>
          { numFormat(seededAcres) }
          {' acres'}
        </Box>
      </Box>

      <IconButton
        style={{margin: '0 0 14px 4px'}}
        color='primary'
        onClick={() => openSettings(true)}
      >
        <SettingsIcon />
      </IconButton>

      <CustomToolTip
        placement="top"
        title="Refresh Operations"
        style={{margin: '0 0 14px 4px'}}
      >
        <IconButton
          color='primary'
          onClick={()=>resetData(true)}
        >
          <SyncIcon />
        </IconButton>

      </CustomToolTip>
    </Box>
  )
}

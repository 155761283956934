// #region - imports
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

// Map imports
import * as L from 'leaflet';
import { FieldContext } from '../Context/FieldContext';

// Layer styles
import {
  stateStyle,
  countyStyle,
  plssStyle,
  sectionStyle,
  cluStyle,
  highlightMousover,
  highlightFeature,
  resetHighlight,
} from './Styles/layerStyles';

// Functions
import { drawMap } from './MapFunctions/drawMap';
import {
  createGeoFromBoundary,
  drawFieldsInBounds,
} from './MapFunctions/helpers';
import {
  updateFieldAfterBoundaryEdit,
  createField,
  createMultiPolygonField,
  calculateFieldAcres,
} from './MapFunctions/fieldInteractions';
import { handleShapeFileUpload } from './MapFunctions/uploadShapefile';
import { exists } from '../../utils/helpers';
import { fieldToolTip } from './Styles/tooltips';

// Map controls
import {
  FieldSelectionToolBar,
  MobileFieldSelectionToolBar,
  EditToolBar,
  DrawingToolBar,
} from './Toolbars/fieldSelectionControls';
import { SelectionInstructions } from './Modals/SelectionInstructions';
// #endregion

/**
 * Map used for field selection and zone drawing for vendor orders.
 * @param {Number} height Height to set map
 * @param {Function} selectField Select field from map
 * @param {Object} selectedField Selected field for order. May be different than fieldData.selectedField
 * @param {String} initialBounds Optional boundary to fit map to if no field selected
 * @param {Number} nextStep Optionally move to next step after field select
 * @returns {JSX} Map for field selection
 */
export function VendorsMap({
  height,
  selectField,
  selectedField,
  initialBounds,
  nextStep,
}) {
  // #region - variables
  const { enqueueSnackbar } = useSnackbar();

  const [fieldData, setFieldData] = useContext(FieldContext);

  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(6);

  // Modal field selection instructions for mobile view
  const [showInstructions, setShowInstructions] = useState('');

  // Fit all fields to bounds if no field is selected
  const [fitNewBounds, setFitNewBounds] = useState(false);

  // Create new field from drawn or CLU
  const [newField, setNewField] = useState(null);

  // From shape file upload
  const [fieldFromShape, setFieldFromShape] = useState(null);

  // CLU toggles. state needed for switch, ref for drawMap
  const [enableClus, setEnableClus] = useState(true);
  const showClus = useRef(true);

  // Used to determine buttons to show when a tool is active
  const [activeTool, setActiveTool] = useState('');

  const [editedBoundary, setEditedBoundary] = useState(null);

  // Layers
  const stateLayer = useRef(
    L.geoJSON(null, {
      style: stateStyle,
      onEachFeature: highlightMousover,
    }),
  );

  const countyLayer = useRef(
    L.geoJSON(null, {
      style: countyStyle,
      onEachFeature: highlightMousover,
    }),
  );

  const plssLayer = useRef(
    L.geoJSON(null, {
      style: plssStyle,
      onEachFeature: highlightMousover,
    }),
  );

  const sectionLayer = useRef(
    L.geoJSON(null, {
      style: sectionStyle,
      onEachFeature: highlightMousover,
    }),
  );

  const cluEvents = (feature, layer) => {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: selectClu,
    });
  };

  const cluLayer = useRef(
    L.geoJSON(null, {
      style: cluStyle,
      onEachFeature: cluEvents,
      pmIgnore: false,
    }),
  );

  const fieldLayer = useRef(L.featureGroup(null));
  const inBounds = useRef(L.layerGroup()); // currently unused in this component
  // #endregion

  // #region - useEffects
  // draw map when component mounts
  useEffect(() => {
    drawMap(
      stateLayer,
      countyLayer,
      plssLayer,
      sectionLayer,
      cluLayer,
      fieldLayer,
      inBounds,
      setMap,
      null,
      setZoomLevel,
      setNewField,
      showClus,
      false,
      drawFieldsInBounds,
      'select-field-map',
      false,
    );
  }, []);

  useEffect(() => {
    // Add selected field from field selection to map
    if (map !== null && exists(selectedField?.boundary)) {
      drawField(selectedField, true);
    }
  }, [selectedField, map]);

  useEffect(() => {
    if (map !== null) {
      clearDrawn();
      clearLayers();

      fieldData.fields.forEach((field) => {
        if (field.id !== fieldData.selectedField.id) {
          drawField(field);
        }
      });

      // If no selected field, fit to field layer bounds
      if (!exists(fieldData.selectedField.id) && !exists(selectedField)) {
        if (initialBounds) {
          fitToInitialBounds(initialBounds);
        } else {
          setFitNewBounds(true);
        }
      } else if (exists(selectedField)) {
        // Draw selected fields
        drawField(selectedField, true);
        // drawField(fieldData.selectedField, false);
      } else if (exists(fieldData.selectedField)) {
        drawField(fieldData.selectedField, true);
      } else {
        // No field selected
      }
    }
  }, [fieldData, map]);

  useEffect(() => {
    // perfrom potential breaking checks
    try {
      if (
        fitNewBounds
        && fieldLayer.current !== null
        && fieldLayer.current.getBounds() !== null
        && map !== null
      ) {
        // get field layer bounds
        const bounds = fieldLayer.current.getBounds();
        // check to make has properties to fit

        if (Object.prototype.hasOwnProperty.call(bounds, '_southWest')) {
          map.fitBounds(bounds);
        }
      }
    } catch (err) {
      console.error(err);
    }

    setFitNewBounds(false);
  }, [fieldLayer.current, fitNewBounds]);

  useEffect(() => {
    if (newField !== null) {
      clearLayers();
      clearDrawn();
      setActiveTool('');
      handleNewField(
        newField.feature,
        newField.type,
        fieldData.selectedField,
        newField.layer,
      );
      setNewField(null);
    }
  }, [newField]);

  useEffect(() => {
    if (editedBoundary !== null) {
      const updatedField = updateFieldAfterBoundaryEdit(
        selectedField,
        editedBoundary,
      );
      selectField(updatedField);

      clearLayers();
      clearDrawn();

      drawField(updatedField, true);
    }
  }, [editedBoundary]);

  useEffect(() => {
    if (fieldFromShape !== null) {
      createFieldFromShape();
    }
  }, [fieldFromShape]);
  // #endregion

  // #region - select field functions
  // ---------------------------------------------------------------------------
  // ######################  Select Field  ######################
  // ---------------------------------------------------------------------------
  const handleNewField = (feature, type, data, layer) => {
    if (type === 'clu') {
      // If CLU, set as selected field
      createNewField(feature, type, data, layer);
    } else if (selectedField === null) {
      // if no field has been selected, create field from feature and select
      createNewField(feature, type, data, layer);
    } else {
      // Add polygon to field, field will be multiPolygon
      addPolygonToField(feature, type, data, layer);
    }
  };

  const addPolygonToField = async (feature, type, data, layer) => {
    try {
      let points = [];
      let { geometry } = feature;
      let featureToUpdate = { ...feature };
      let acres = 0;

      if (selectedField?.points.length === 1) {
        acres = calculateFieldAcres(
          selectedField.coordinates,
          feature,
          selectedField.acres,
          'polygon',
        );

        // begin creating multi polygon field
        // add new coordinates to points array
        points = [selectedField.points, feature.geometry.coordinates];

        geometry = {
          type: 'MultiPolygon',
          coordinates: points,
        };
        featureToUpdate = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry,
            },
          ],
        };
      } else {
        // Field is alrady multiPolygon
        // Points array is nested, spread it out
        points = [...selectedField.points, feature.geometry.coordinates];
        acres = calculateFieldAcres(
          selectedField.coordinates,
          feature,
          selectedField.acres,
          'multipolygon',
        );

        geometry = {
          type: 'MultiPolygon',
          coordinates: points,
        };
        featureToUpdate = {
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              geometry,
            },
          ],
        };
      }

      // Make feature collection and fit bounds
      const newCollection = L.geoJson(featureToUpdate);
      map.fitBounds(newCollection.getBounds());

      const updatedField = await createMultiPolygonField(
        acres,
        featureToUpdate,
        geometry,
        points,
      );

      updatedField.name = selectedField?.name;
      updatedField.id = selectedField?.id;
      updatedField.sournce = 'new';

      selectField(updatedField);

      // Remove poly layer
      map.removeLayer(layer);
      drawField(updatedField);
    } catch (err) {
      console.error(err);
    }
  };

  const createNewField = async (feature, type, data, layer) => {
    const field = await createField(feature, data);
    selectField(field, nextStep);
    drawField(field, true);
    // after field has been drawn remove polylayer
    if (type === 'polygon') {
      map.removeLayer(layer);
    }
  };

  const selectClu = (e) => {
    // sets new field from clu feature
    const layer = e.target;
    setNewField({ feature: layer.feature, type: 'clu', layer });
  };

  const createFromShapeFile = async (e) => {
    handleShapeFileUpload(e, setFieldFromShape);
  };

  const createFieldFromShape = () => {
    const field = {
      ...fieldFromShape,
      org: selectedField?.org,
      orgId: selectedField?.orgId,
      farm: selectedField?.farm,
      farmId: selectedField?.farmId,
    };
    selectField(field);
  };

  /**
   * Draw field boundaries on map and add appropriate functions to their layer.
   * Convert stored field boundary to geoJson.
   * If selected field, fit map to its bounds.
   * @param  {Object}  field Field data, including boundary and name
   * @param  {Boolean} [snapTo=false] If selected field, snap to its bounds
   * @returns {void}
   */
  const drawField = (field, snapTo = false) => {
    try {
      const geo = createGeoFromBoundary(field.boundary);

      const newCollection = L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          fieldLayer.current.addLayer(layer).setStyle({
            color: '#8e24aa',
            fillColor: '#f3e5f5',
            opacity: 1,
            fillOpacity: 0,
          });

          // Hoverable info for field
          const toolTip = fieldToolTip(field);
          layer.bindTooltip(toolTip, { className: 'leaftletTooltipClass' });

          fieldLayer.current.on('mouseover', (e) => {
            e.layer.setStyle({ color: '#6a1b9a', fillOpacity: 0.05 });
          });

          fieldLayer.current.on('mouseout', (e) => {
            e.layer.setStyle({ color: '#8e24aa', fillOpacity: 0 });
          });

          fieldLayer.current.on('click', (e) => {
            if (activeTool === '') {
              e.layer.setStyle({
                color: '#561280',
                opacity: 0.99,
                fillOpacity: 0,
              });
            }
          });

          layer.on('pm:update', ({ layer, shape }) => {
            setEditedBoundary(layer.toGeoJSON());
          });

          layer.on('pm:cut', ({ layer }) => {
            // layer has been cut
          });

          layer.on({
            click: (e) => {
              if (activeTool === '') {
                // Selects field and moves to next step
                selectField(field, nextStep);
              }
            },
          });
        },
      }).addTo(map);

      if (snapTo) {
        map.fitBounds(newCollection.getBounds(), {
          padding: [30, 30],
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fitToInitialBounds = (boundary) => {
    try {
      const geo = createGeoFromBoundary(boundary);
      const newCollection = L.geoJson(geo);
      map.fitBounds(newCollection.getBounds(), {
        padding: [300, 300],
      });
    } catch (err) {
      console.error(err);
    }
  };
  // #endregion

  // #region - clear functions
  // ---------------------------------------------------------------------------
  // ######################  Clear Functions  ######################
  // ---------------------------------------------------------------------------
  const clear = () => {
    clearLayers();
    clearDrawn();
    handleCluSwitch(false);
    selectField(null);
    setFieldData({
      ...fieldData,
      selectedField: {
        id: '',
        name: '',
        farmId: '',
        farm: '',
        orgId: '',
        org: '',
        boundaryId: '',
        clientId: '',
        client: '',
      },
      fieldToClaim: {
        feature: null,
        geometry: {},
        coordinates: [],
        points: [],
        boundary: {},
        acres: 0,
      },
    });
  };

  const clearLayers = () => {
    // clear current layers
    map.removeLayer(cluLayer.current);
    map.removeLayer(sectionLayer.current);
    map.removeLayer(plssLayer.current);
    map.removeLayer(fieldLayer.current);
    fieldLayer.current.clearLayers();
  };

  const clearDrawn = () => {
    map.eachLayer((layer) => {
      try {
        // we can use this test to identify if the layer contains a drawn feature or not
        // and if so - we can remove it
        const currLayerCoords = layer.feature.geometry.coordinates[0];
        if (isUserLayer(layer)) {
          map.removeLayer(layer);
        }
      } catch (err) {
        // console.log(err)
      }
    });
  };

  const isUserLayer = (layer) => {
    const state = stateLayer.current.hasLayer(layer);
    const county = countyLayer.current.hasLayer(layer);
    const plss = plssLayer.current.hasLayer(layer);
    const section = sectionLayer.current.hasLayer(layer);
    const clu = cluLayer.current.hasLayer(layer);
    const notGov = !state && !county && !plss & !section & !clu;
    return notGov;
  };

  const handleCluSwitch = (checked) => {
    /**
     * Update state (needed for switch control and re-renders) and ref (needed
     * for map draw controls) on switch change. After checking that map is
     * rendered, if switch is false, remove layer. If setting to true, set map
     * zoom to level CLUs are drawn at. A map event is needed to fire draw
     * function which is initiated inside of drawMap function.
     * @type {Object} event Event from switch
     */

    showClus.current = checked;
    setEnableClus(checked);

    if (map !== null) {
      if (checked === false) {
        map.removeLayer(cluLayer.current);
      } else {
        // if map is zoomed to far out for clu display, zoom to that point
        // eslint-disable-next-line no-lonely-if
        if (zoomLevel < 14) {
          map.setZoom(14);
        } else {
          // need some kind of map even to start clu draw
          map.setZoom(zoomLevel);
        }
      }
    }
  };
  // #endregion

  // #region - draw/edit tools functions
  // ---------------------------------------------------------------------------
  // ######################  Draw and Edit Tools  ######################
  // ---------------------------------------------------------------------------
  const addPolygon = () => {
    setActiveTool('polygon');

    // Turn off CLUs
    handleCluSwitch(false);

    // start polygon draw
    const draw = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polygon',
    );
    draw[0].click();
  };

  const addSquare = () => {
    setActiveTool('polygon');

    // Turn off CLUs
    handleCluSwitch(false);

    // start square polygon draw
    const draw = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-rectangle',
    );
    draw[0].click();
  };

  const editBoundary = () => {
    setActiveTool('edit');

    // switch CLUs off
    handleCluSwitch(false);

    // Clear layers so can only edit selected field
    clearDrawn();

    drawField(selectedField, true);

    const control = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-edit',
    )[0];
    fieldLayer.current.pm.enable();
    control.click();
  };

  const finishEdit = () => {
    setActiveTool('');
    const control = document.getElementsByClassName(
      'leaflet-pm-action  action-finishMode',
    );
    control[0].click();
  };

  const cancel = () => {
    map.pm.Draw.disable();
    setActiveTool('');
  };
  // #endregion

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={height || '100%'}
      width="100%"
    >
      {activeTool === 'edit' ? (
        <EditToolBar finishEdit={finishEdit} />
      ) : activeTool === 'polygon' ? (
        <DrawingToolBar cancel={cancel} />
      ) : window.innerWidth >= 1140 ? (
        <FieldSelectionToolBar
          addPolygon={addPolygon}
          addSquare={addSquare}
          editBoundary={selectedField ? editBoundary : undefined}
          createFromShapeFile={createFromShapeFile}
          clear={clear}
          enableClus={enableClus}
          handleCluSwitch={handleCluSwitch}
          setShowInstructions={setShowInstructions}
          zoomLevel={zoomLevel}
        />
      ) : (
        <MobileFieldSelectionToolBar
          addPolygon={addPolygon}
          addSquare={addSquare}
          editBoundary={selectedField ? editBoundary : undefined}
          createFromShapeFile={createFromShapeFile}
          clear={clear}
          enableClus={enableClus}
          handleCluSwitch={handleCluSwitch}
          setShowInstructions={setShowInstructions}
          zoomLevel={zoomLevel}
        />
      )}

      {/* Way to show tooltips in mobile view */}
      <SelectionInstructions
        setOpen={setShowInstructions}
        text={showInstructions}
      />

      <Box id="select-field-map" height="100%" width="auto" />
    </Box>
  );
}

VendorsMap.propTypes = {
  selectField: PropTypes.func.isRequired,
  selectedField: PropTypes.object,
  initialBounds: PropTypes.string,
  nextStep: PropTypes.number,
  height: PropTypes.string,
};

VendorsMap.defaultProps = {
  height: undefined,
  selectedField: null,
  initialBounds: undefined,
  nextStep: undefined,
};

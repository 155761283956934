export function weightedAvgYield(cropType, type, yieldInfo) {
  let grainYield = [], grainYield1 = []
  let yieldYears = [], finalYieldData = [];
  //console.log('yieldInfo', yieldInfo)
  var filteredCropYield = yieldInfo.filter((obj) => obj.CropName === cropType);

  for (var i = 0; i < filteredCropYield.length; i++) {
    yieldYears.push(filteredCropYield[i].CropSeason);
  }
  yieldYears = [...new Set(yieldYears)]
  //console.log('yieldYears', yieldYears)

  var yProduct = 0, ySum = 0, acresSum = 0;

  for (var i = 0; i < yieldYears.length; i++) {
    yProduct = 0; ySum = 0; acresSum = 0;
    for (var j = 0; j < filteredCropYield.length; j++) {
      if (yieldYears[i] == filteredCropYield[j].CropSeason) {
        yProduct = filteredCropYield[j].AverageYield * filteredCropYield[j].Area;
        ySum += yProduct;
        acresSum += filteredCropYield[j].Area
      }
    }
    var yieldObj = {
      x: yieldYears[i],
      y: Math.round(ySum / acresSum * 100) / 100
    }
    finalYieldData.push(yieldObj)
    if (type == 'Nitrogen') {
      grainYield.push(yieldObj)
      grainYield1.push(yieldObj)
    }
  }
  //console.log('finalYieldData', finalYieldData)
  //console.log('grainYield', grainYield)
  let resObj = {
    'grainYield': grainYield,
    grainYield1: grainYield1,
    finalYieldData: finalYieldData
  }

  return resObj
}

export function weightedNutrientValue(type, nutrientParser, appYears, years, acres) {
  let finalNutrientData = [], finalAcres = [], nutrientData = [], nRate = [], nRate1 = [];
  //console.log('appYears', appYears)
  //console.log('nutrientParser', nutrientParser)

  for (var i = 0; i < nutrientParser.length; i++) {
    var object = {};
    if (type == 'Nitrogen') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].N,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Phosphorus') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].P,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Potassium') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].K,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Manganese') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].Mn,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Chlorine') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].Cl,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Calcium') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].Ca,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Copper') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].Cu,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Sulphur') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].S,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Zinc') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].Z,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Magnesium') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].Mg,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    else if (type == 'Boron') {
      for (var j = 0; j < appYears.length; j++) {
        if (nutrientParser[i].id == appYears[j].id) {
          object = {
            year: appYears[j].year,
            rate: nutrientParser[i].B,
            acres: appYears[j].acres,
            id: nutrientParser[i].id
          }
        }
      }
    }
    nutrientData.push(object)
  }

  //console.log('nutrientData', nutrientData)
  var count = [], hpacres = [];

  //console.log('acres', acres)
  //console.log('years', years)

  for (var i = 0; i < years.length; i++) {
    var hsum = 0, psum = 0
    for (var j = 0; j < acres.length; j++) {
      if (years[i] == acres[j].HarvestSeason) {
        hsum += acres[j].HarvestedAcres
      }
      if (years[i] == acres[j].PlantingSeason) {
        psum += acres[j].PlantedAcres
      }
    }
    var object = {
      operationAcres: Math.max(hsum, psum),
      year: years[i]
    }
    hpacres.push(object)
  }

  //console.log('hpacres', hpacres)
  for (var i = 0; i < hpacres.length; i++) {
    for (var j = 0; j < nutrientData.length; j++) {
      if (hpacres[i].year == nutrientData[j].year) {
        var minAcres = Math.min(acres[0].FieldAcres, hpacres[i].operationAcres)
      }

      else {
        var minAcres = acres[0].FieldAcres
      }
      minAcres = hpacres[i].operationAcres == 0 ? acres[0].FieldAcres : minAcres
    }
    var obj = {
      year: hpacres[i].year,
      finalAcre: minAcres
    }
    finalAcres.push(obj)
  }
  //console.log('finalAcres', finalAcres)

  finalAcres = finalAcres.slice(0);
  finalAcres.sort(function (a, b) {
    var x = a.year;
    var y = b.year;
    return x < y ? -1 : x > y ? 1 : 0;
  })

  for (var i = 0; i < years.length; i++) {
    var n = nutrientData.filter((obj) => obj.year === years[i]).length;
    var object = {
      year: years[i],
      count: n
    }
    count.push(object)
  }
  //console.log('count', count)
  var product = 0, sum = 0;


  for (var i = 0; i < count.length; i++) {
    product = 0; sum = 0;
    for (var j = 0; j < nutrientData.length; j++) {
      if (nutrientData[j].year == count[i].year) {
        product = nutrientData[j].rate * nutrientData[j].acres;
        sum += product
      }
    }
    for (var x = 0; x < finalAcres.length; x++) {
      if (count[i].year == finalAcres[x].year) {
        var finalAcreage = finalAcres[x].finalAcre
      }
    }
    var obj = {
      x: count[i].year,
      y: Math.round(sum / finalAcreage * 100) / 100
    }
    finalNutrientData.push(obj)
    if (type == 'Nitrogen') {
      nRate.push(obj)
      nRate1.push(obj)
    }
  }

  //console.log('finalNutrientData', finalNutrientData)
  //console.log('nRate', nRate)
  let objRes = {
    nRate: nRate,
    nRate1: nRate1,
    finalNutrientData: finalNutrientData
  }

  return objRes
}
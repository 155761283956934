import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Modal,
  CircularProgress,
  TextField,
  Typography,
  Divider,
  IconButton,
} from "@material-ui/core";
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import VisibilityIcon from "@material-ui/icons/Visibility";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { PriceToolInfo } from "../../PriceTool/PriceToolInfo";
import { UpdateOther } from "./UpdateOther";
import { numFormat } from "../../../../utils/helpers";
import { useWindowDimensions } from "../../../../utils/dimensions";
import { Integrate } from '../../../Partners/Integrate'
import { blackText, lightGrey, grey } from '../../../../styles/colors'
import { Instructions } from '../GetStarted'
import { SpinningLoader } from '../../../Shared/SpinningLoader';
import { MobileHeader } from '../Shared/MobileHeader'
import { CustomToolTip } from '../../../../utils/customComponents';
import { hiddenInfo } from '../Shared/tooltips'


const useStyles = makeStyles((theme) => ({
  head: theme.tableHeader,
  headText: theme.tableHeaderText,
  labels: {
    display: "flex",
    padding: "6px 16px",
    alignItems: "center",
    fontWeight: 600,
    color: theme.palette.text.black,
  },
  tableRow: {
    display: "flex",
    alignItems: 'center',
    flexGrow: 1,
    padding: "8px",
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  icon: theme.plIcon,
  add: {
    ...theme.addOperation,
    width: "200px",
  },
  hidden: theme.hiddenOperation,
  infoToolTip: theme.infoToolTip,
}));

const fieldPassInfo = 'The cost of field passes are derived based on the total acreage of all operations for your seeding, application, harvest and tillage. In cases where multiple operations only span part of the field, the blended cost associated with the field passes is redistributed over the entire field'

export function Others({
  fieldAcres,
  templateMode,
  operations,
  setOperations,
  displayed,
  hidden,
  seededAcres,
  year,
  field,
  type,
  save,
  handleIndividualOperation,
  openPriceTool,
  saving,
  combineGenericOperations,
  openTemplateMode,
  integrated,
  refreshGenericOperations,
  templatesLoading,
  yearSelection,
  mobileBreakPoint,
  openSettings,
  resetData,
  uploadPrecisionFile,
  getTillageOperations
}) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const [viewHidden, setViewHidden] = useState(false);
  const [open, setOpen] = useState(false);
  const [edit, setEdit] = useState({ operationID: "" });
  const [displaySummaries, setDisplaySummaries] = useState([]);
  const [action, setAction] = useState("Add")

  useEffect (()=> {
    for (const op of operations){
      const id = Math.floor(Math.random() * Math.floor(100000));
      op["identifier"] = id;
    }
  },[operations])

  const handleEdit = (op) => {
    setAction("Edit")
    setEdit(op);
    setOpen(true);
  };

  const getTillageProductName = (op) =>{
    let productName = op.productName
    let ndx = productName.indexOf("-");
    let newName = productName.slice(0,ndx);
    newName += " - " + op.tillageType;
    return newName;
  }
  const handleHide = async (op) => {
    let filteredOps;
    if(op.fileType === 'TILLAGE'){
      filteredOps = operations.filter(
        (x) => x.operationID !== op.operationID
      );
    }
    else{
      filteredOps = operations.filter(
        (x) => x.genericOperationID !== op.genericOperationID
      );
    }

    op.isDeleted = !op.isDeleted;
    op['operationID'] = op.fileType === 'TILLAGE' ? op.operationID : op.genericOperationID

    let savedOpn = await handleIndividualOperation([op]);
    setOperations([...filteredOps, op]);
  };

  const handleSave = (op, zones, auto=true) => {
    if(op.fileType === "Tillage"){
      save("Tillage", op, zones, auto);
    }
    else{
      save(type, op, zones, auto);
    }
  };

  const addTemplateOperation = () =>{
    let newOp;
    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();
    today = mm + '/' + dd + '/' + yyyy;
        newOp = {area: fieldAcres,
          aux1: type,
          aux2: "NULL",
          cellsize: "0.0001",
          cropSeason: year,
          date: today,
          endDate: "",
          fileType: "Generic",
          genericOperationID: "",
          id: "",
          isDeleted: false,
          logID: 0,
          name: "",
          price: 0,
          productName: "",
          rate: 1,
          rateUnit: "unit1unit-",
          scenarioID: 1,
          source: "TEMPLATE",
          startDate: today,
          total: null,
          totalUnit: "unit"}

    const newOps = [...operations, newOp]
    setOperations(newOps);
  }

  const handleRemoveTemplateOp = (op) =>{
    const newOps = [];
    for (const currOp of operations){
      if(JSON.stringify(currOp) !== JSON.stringify(op)){
        newOps.push(currOp);
      }
    }
    setOperations(newOps);
  }

  const handleChange = (val, op, key) => {
    const allOps = [];
    for (const currOp of operations){
      if(JSON.stringify(currOp) === JSON.stringify(op)){
        if(key === "productName"){
          currOp.productName = val;
          currOp.name = val;
        }else if(key === "price"){
          currOp.price = val;
        }
      }
      allOps.push(currOp);
    }
    setOperations(allOps);
  }

  const createTable = (operations, showingHidden=false) => {
    return (
      <Box
        my={1}
        border={1}
        boxShadow={1}
        borderColor={grey}
        borderRadius='borderRadius'
        style={{ minWidth: '400px', maxWidth: '1000px', overflowX: 'auto' }}
      >
        <Box
          className={classes.labels}
          borderBottom={1}
          borderColor="grey.500"
        >
          <Box style={{ width: (templateMode || width < 1024) ? "35%" : "25%" }}>
            Description
            {type === "Field Pass" &&
              <CustomToolTip placement="right" title={fieldPassInfo}>
                <InfoOutlinedIcon className={classes.infoToolTip} />
              </CustomToolTip>
            }

          </Box>
          <Box style={{ width: "15%" }}>
            Acres
          </Box>
          {type !== "Revenue" ? (
            <Box style={{ width: templateMode ? '20%' : "25%" }}>
              {type === "Field Pass" ? 'Cost ($/Acre Applied)' : 'Cost ($/Acre)' }
            </Box>
          ) : (
            <Box style={{ width: "25%" }}>
              Revenue ($/Acre)
            </Box>
          )}
          {type !== "Revenue" ? (
            <Box style={{ width: "20%" }}>
              Total Cost ($)
            </Box>
          ) : (
            <Box style={{ width: "20%" }}>
              Total Revenue ($)
            </Box>
          )}
          {(!templateMode && width >= 1024) && (
            <Box style={{ width: "7.5%" }}>
              Edit
            </Box>
          )}
          {!templateMode && (
            <Box style={{ width: "7.5%" }}>
              {!showingHidden ? 'Hide' : 'Show'}
            </Box>
          )}
        </Box>

        {operations.map((op, i) => createRow(op, i))}

      </Box>
    )
  }

  const createRow = (operation, i) => {
    return (
      <Box
        key={i}
        className={classes.tableRow}
        borderBottom={templateMode ? 1 : 0}
        borderColor={grey}
        style={{ backgroundColor: (i % 2 !== 0 || templateMode) ? "#ffffff" : lightGrey }}
      >
        <Box p={1} style={{ width: (templateMode || width < 1024) ? "35%" : "25%" }}>
          {
            templateMode ?
              <TextField
                variant="outlined"
                type='text'
                style={{
                  height: '40px',
                  width: '90%',
                  minWidth: '200px',
                }}
                inputProps={{
                  style: {
                    padding: 10,
                    fontWeight: 500,
                    fontSize: 14,
                  }
                }}
                value={operation.fileType === "Tillage" ? getTillageProductName(operation) : operation.productName}
                onChange={(e) => handleChange(e.target.value, operation, "productName")}
              />
            :
            operation.productName
          }
        </Box>

        {/* Show acres, cost, and total cost */}
        <Box p={1} style={{ width: "15%" }}>
          {numFormat(operation.area)}
        </Box>
        <Box p={1} style={{ width: templateMode ? '20%' : "25%" }}>
          {templateMode ?
            <TextField
              variant="outlined"
              type='number'
              style={{
                height: '40px',
                width: '35%',
                minWidth: '80px',
              }}
              inputProps={{
                style: {
                  padding: 10,
                  fontWeight: 500,
                  fontSize: 14,
                }
              }}
              value={operation.price}
              onChange={(e) => handleChange(e.target.value, operation, "price")}
            />
          :
          numFormat(operation.price)}
        </Box>
        <Box p={1} style={{ width: "20%" }}>
          {numFormat(Math.abs(operation.price * operation.area))}
        </Box>

        {/* Edit button */}
        {(!templateMode && width >= 1024) && (
          <Box style={{ width: "7.5%" }}>
            <Box className={classes.icon} onClick={() => handleEdit(operation)}>
              <EditIcon fontSize="small" />
            </Box>
          </Box>
        )}

        {/* Hide/Remove button */}
        {!templateMode && (
          <Box style={{ width: "7.5%" }}>
            <Box className={classes.icon} onClick={() => handleHide(operation)}>
              {operation.isDeleted ? (
                <VisibilityIcon fontSize="small" />
              ) : (
                <VisibilityOffIcon fontSize="small" />
              )}
            </Box>
          </Box>
        )}
        {templateMode && !operation.isDeleted &&(
          <Box
            className={classes.icon}
            mx={1}
            onClick={() => handleRemoveTemplateOp(operation)}
            style={{ width: "10%" }}
          >
            <DeleteForeverIcon fontSize="small" />
            Remove
          </Box>
        )}
      </Box>
    );
  };

  const handleNew = () => {
    setAction("Add");
    setOpen(true)
  }

  const handleViewHidden = () => {

  }

  return (
    <Box
      p={1}
      px={2}
      style={{ width: "100%" }}
    >
      <Box my={2} style={{ minWidth: templateMode ? '700px' : '600px', maxWidth: '1000px', overflowX: 'auto' }}>
        <Box ml={1} fontWeight={500} color={blackText} fontSize={18}>
          {type === 'Field Pass' ? 'Cost of Field Passes' : `Other ${type}s`}
          {' Summary'}
        </Box>

        <Divider />

        {
          (
            yearSelection !== null &&
            width < mobileBreakPoint &&
            !templateMode
          ) && (
            <MobileHeader
              yearSelection={yearSelection}
              seededAcres={seededAcres}
              openSettings={openSettings}
              resetData={resetData}
            />
        )}

        {
          (displayed?.length > 0) && createTable(displayed)
        }

        {
          (operations && operations.length === 0 && !templateMode) && (
            <Instructions
              type={'applications'}
              createNew={handleNew}
              canAdd={width > 1024}
              openTemplate={openTemplateMode}
              integrated={integrated}
            />
        )}

        {saving && (
          <SpinningLoader />
        )}
      </Box>

      {
        templateMode && templatesLoading && (
          <Box p={1} fontWeight={500} fontSize={20}>
            <Box p={1}>
              Loading Templates
            </Box>
            <Box p={1} display='flex' justifyContent='center' style={{width: '100%'}}>
              <CircularProgress size={60}/>
            </Box>
          </Box>
        )
      }

      <Box display='flex' flexWrap='wrap'>
        {(!templateMode && width >= 1024) && (
          <Box
            className={classes.add}
            border={1}
            borderRadius="borderRadius"
            onClick={() => handleNew()}
            style={{marginRight: '18px'}}
          >
            <AddIcon />
            {' Add Operation'}
          </Box>
        )}

        {operations && operations.length === 0 && !templateMode &&
          <Box
            className={classes.add}
            border={1}
            borderRadius="borderRadius"
            onClick={() => openTemplateMode()}
            style={{ width: '280px', marginRight: '18px' }}
          >
            <AddIcon />
            {' Generate Operation From Template'}
          </Box>
        }

        {templateMode && (
          <Box p={1}>
            {
              type === 'Cost' ? (
                <Box my={1} fontWeight={500}>
                  Add any other costs, such as labor
                </Box>
              ) : type === 'Revenue' ? (
                <Box my={1} fontWeight={500}>
                  Add any other revenues, such as subsidies
                </Box>
              ) : (
                ''
              )
            }

            <Box
              className={classes.add}
              border={1}
              borderRadius="borderRadius"
              onClick={() => addTemplateOperation()}
              style={{width: '220px'}}
            >
              <AddIcon /> Add Template Operation
            </Box>
          </Box>
        )}

        {!templateMode && (
          <Box
            className={classes.add}
            border={1}
            borderRadius="borderRadius"
            onClick={() => uploadPrecisionFile()}
            style={{width: '220px'}}
          >
            <AddIcon />
            Upload Precision Ag Data
          </Box>
        )}
      </Box>

      <Box my={3}>
        {(!templateMode && hidden.length > 0) && (
          <Box
            className={classes.hidden}
            onClick={() => setViewHidden(!viewHidden)}
          >
            {viewHidden ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            Hidden Entries ({hidden.length})
            <CustomToolTip placement="right" title={hiddenInfo}>
              <InfoOutlinedIcon className={classes.infoToolTip} />
            </CustomToolTip>
          </Box>
        )}

        { viewHidden && hidden.length > 0 && ( createTable(hidden, true)) }

      </Box>

      {(!templateMode && operations.length > 0) &&
        (<PriceToolInfo
          openPriceTool={openPriceTool}
          opType={"Other Expenses/Revenue"}
        />
      )}

      { (!templateMode && operations.length === 0 && !integrated) && <Integrate /> }

      <Modal
        open={open}
        aria-labelledby="add-other"
        aria-describedby="add-other-costs-and-revenue"
      >
        <div>
          <UpdateOther
            seededAcres={seededAcres}
            year={year}
            orgId={field.orgId}
            setOpen={setOpen}
            save={handleSave}
            edit={edit}
            handleIndividualOperation={handleIndividualOperation}
            field={field}
            action={action}
            origin={"Other"}
            operations={operations}
            setOperations={setOperations}
            otherType={type}
            refreshGenericOperations={refreshGenericOperations}
            getTillageOperations={getTillageOperations}
          />
        </div>
      </Modal>
    </Box>
  );
}

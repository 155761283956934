/* eslint-disable default-case */
/* eslint-disable valid-jsdoc */
/* eslint-disable react/prop-types */
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import {
  Box,
  Button,
  IconButton,
  Radio,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// Map imports
import * as L from 'leaflet';
import * as turf from '@turf/turf';

// Layer styles
import { cloneDeep } from 'lodash';
import {
  stateStyle,
  countyStyle,
  plssStyle,
  sectionStyle,
  cluStyle,
  highlightMousover,
  zoneColors,
} from './Styles/layerStyles';

// Functions
import { drawMap } from './MapFunctions/drawMap';
import {
  createGeoFromBoundary,
  sumZoneAcres,
} from './MapFunctions/helpers';
import {
  createBoundingBox,
  getSelectedTilesData,
  updateGridOnRotateValue,
  getSelectedForZone,
  createGridControls,
  createGridZones,
} from './MapFunctions/gridTool';
import {
  checkZoneOverlap,
  checkZoneIntersectsWithField,
} from './MapFunctions/fieldInteractions';
import { handleSliceVertex } from './MapFunctions/splitTool';
import { getSsurgo } from './MapFunctions/ssurgo';
import { handleShapeFileUpload } from './MapFunctions/uploadShapefile';
import {
  exists, numFormat,
} from '../../utils/helpers';
import { FieldContext } from '../Context/FieldContext';

// Tooltips
import { CustomToolTip } from '../../utils/customComponents';
import { GridInstructions } from '../Shared/Instructions/GridInstructions';
import { SoilZoneInstructions } from '../Shared/Instructions/SoilZoneInstructions';

import { ZoneToolBar, DrawingToolBar } from './Toolbars/fieldSelectionControls';
import { ZoneCreation } from './Modals/ZoneCreation';
import { SsurgoTable } from './Modals/SsurgoTable';
import { SpinningLoader } from '../Shared/SpinningLoader';

const useStyles = makeStyles((theme) => ({
  mapControls: {
    ...theme.mapControls,
    flexWrap: 'nowrap',
    justifyContent: 'center',
    padding: 8,
  },
  mobileControls: {
    ...theme.mapControls,
    flexWrap: 'nowrap',
    padding: 4,
    backgroundColor: '#ffffff',
    borderTop: '1px solid #555555',
  },
  icon: theme.greenIcon,
}));

let zoneNum = 1; // The number of zones created so far (used in tooltip display and
// let gridSize = 0; // The length of an edge of a grid tile in KM

// eslint-disable-next-line valid-jsdoc
/**
 * Map used for field selection and zone drawing for vendor orders.
 * @param {Number} height Height to set map
 * @param {Function} selectField Select field from map
 * @param {Object} selectedField Selected field for order. May be different than fieldData.selectedField
 * @param {returns} Zone Selection Map
 */
export function ZoneSelectionMap({
  height,
  selectField,
  selectedField,
  goToSelectPackage,
  goToFieldSelection,
  toolTip,
  autoGenerateGrids,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [fieldData, setFieldData] = useContext(FieldContext);

  // Modal to choose how to start
  const [open, setOpen] = useState(false);

  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(6);

  // Create new field from drawn or CLU
  const [newPolygonZone, setNewPolygonZone] = useState(null);

  // Used to determine buttons to show when a tool is active
  const [activeTool, setActiveTool] = useState('');

  // SSURGO data
  const [averageNCCPI, setAverageNCCPI] = useState('');
  const [ssurgoLegend, setSsurgoLegend] = useState([]);
  const [showSsurgoTable, setShowSsurgoTable] = useState(null);

  // Shape file data
  const [fieldFromShape, setFieldFromShape] = useState(null);

  // Grid data
  const maxZoneNumber = useRef(0);
  const rotateVal = useRef(0);
  const gridSizeAcres = useRef('2.5');
  const northSouthOffset = useRef(0);
  const eastWestOffset = useRef(0);

  // Checkbox needs state to update
  const [checkedGridSize, setCheckedGridSize] = useState(gridSizeAcres.current);

  const gridTiles = useRef([]);
  const [selectedTiles, setSelectedTiles] = useState([]);
  const [drawnZones, setDrawnZones] = useState([]);
  const [zones, setZones] = useState([]);
  const [tooltip, setToolTip] = useState(`Editing Zone: ${zoneNum}`);
  const gridBoundingBox = useRef([]);
  const gridSliders = useRef({
    slider1: L.control.slider(),
    slider2: L.control.slider(),
    slider3: L.control.slider(),
  });
  const [gridInstructionsOpen, setGridInstructionsOpen] = useState(true);
  const [zoneInstructionsOpen, setZoneInstructionsOpen] = useState(true);

  // Split tool
  const slicingMode = useRef(false);
  const currentSlice = useRef(null);
  const slicedZones = useRef([]);
  const slicedMap = useRef(L.geoJson(null));

  // Store zone to update edited boundary for
  const [zonesToUpdate, setZonesToUpdate] = useState([]);

  // Finished with zones
  const [loading, setLoading] = useState(false);

  // Layers
  const stateLayer = useRef(L.geoJSON(null, {
    style: stateStyle,
    onEachFeature: highlightMousover,
  }));

  const countyLayer = useRef(L.geoJSON(null, {
    style: countyStyle,
    onEachFeature: highlightMousover,
  }));

  const plssLayer = useRef(L.geoJSON(null, {
    style: plssStyle,
    onEachFeature: highlightMousover,
  }));

  const sectionLayer = useRef(L.geoJSON(null, {
    style: sectionStyle,
    onEachFeature: highlightMousover,
  }));

  const cluLayer = useRef(L.geoJSON(null, {
    style: cluStyle,
    pmIgnore: false,
  }));

  const fieldLayer = useRef(L.featureGroup(null));
  const drawnPolygons = useRef(L.featureGroup(null));
  const gridlayer = useRef(L.geoJSON(null));
  const createdZones = useRef(L.geoJSON(null));
  const inBounds = useRef(L.layerGroup()); // currently unused in this component

  // Layer to draw zones from field geo to
  const geoZones = useRef(L.geoJSON(null));

  // draw map when component mounts
  useEffect(() => {
    drawMap(
      stateLayer,
      countyLayer,
      plssLayer,
      sectionLayer,
      cluLayer,
      fieldLayer,
      inBounds,
      setMap,
      null,
      setZoomLevel,
      setNewPolygonZone,
      false,
      false,
      drawFieldsInBounds,
      'select-zones-map',
      false,
      null,
      true,
    );
  }, []);

  useEffect(() => {
    // Add selected field from field selection to map
    if (map !== null && selectedField !== null) {
      if (
        selectedField.source === 'shape'
        && !selectedField.hasOwnProperty('zones')
      ) {
        handleShapeField(selectedField);
      } else {
        drawField(selectedField, true);
        getBoundingBox(selectedField.boundary, 0, 0);

        // If selected field has zones, draw them, if not open zone creation modal
        if (selectedField?.zones?.length) {
          drawFieldZones(selectedField.zones, selectedField?.zoneType);
        } else if (activeTool === '') {
          setOpen(true);
        }
      }
    }
  }, [selectedField, map]);

  useEffect(() => {
    // Add component specific controls to map
    if (map) {
      map.on('pm:drawstart', ({ workingLayer }) => {
        workingLayer.on('pm:vertexadded', (e) => {
          if (slicingMode.current) {
            // drawnPolygons.current.clearLayers();
            // clearLayers();
            const newZones = handleSliceVertex(
              e,
              selectedField.boundary,
              currentSlice,
              slicedZones,
              setZones,
              drawnZones,
              setDrawnZones,
              map,
              slicedMap,
              zoneColors,
            );

            addSlicedZones(newZones);
          }
        });
      });
    }
  }, [map]);

  useEffect(() => {
    let flag = false;
    try {
      gridTiles.current.forEach((item) => {
        if (item.selected === true && item.zone == zoneNum) {
          flag = true;
        }
      });
    } catch (err) {}
    if (zoneNum > maxZoneNumber.current) {
      maxZoneNumber.current = zoneNum;
    }
  }, [zoneNum]);

  useEffect(() => {
    if (newPolygonZone !== null) {
      // Only use parts of zone that are within field boundary
      let newZone = checkZoneIntersectsWithField(
        selectedField.coordinates, newPolygonZone.feature,
      );

      if (newZone === null) {
        // If no part of zones falls within boundary, alert user and reset
        enqueueSnackbar('Please draw zones inside of your selected field boundary');
        // Remove poly layer
        map.removeLayer(newPolygonZone.layer);

        setNewPolygonZone(null);
        setActiveTool('zoneCreation');
        return;
      }

      // Get acres of zone after clipping outside of boundary
      newZone.properties.CALCACRES = turf.convertArea(
        turf.area(newZone.geometry), 'meters', 'acres',
      );

      if (selectedField?.zones !== undefined) {
        const vettedZone = checkZoneOverlap(selectedField.zones, newZone);

        if (vettedZone !== null) {
          newZone = vettedZone;
        } else {
          // If zone was compeltely contained inside another zone
          enqueueSnackbar('Your recently drawn zone was already contained inside of a previously drawn zone');
          // Remove poly layer
          map.removeLayer(newPolygonZone.layer);

          setNewPolygonZone(null);
          setActiveTool('zoneCreation');
          return;
        }
      }

      const zoneNumber = selectedField?.zones !== undefined ? (selectedField.zones.length + 1) : 1;
      newZone.properties.zone = zoneNumber;
      newZone.properties.color = zoneColors[zoneNumber];
      newZone.properties.mukey = Math.floor(1000000 + Math.random() * 9000000);

      const updatedField = cloneDeep(selectedField);

      if (selectedField.zones !== undefined) {
        updatedField.zones.push(newZone);
      } else {
        updatedField.zones = [newZone];
      }

      updatedField.zoneType = 'polygon';
      updatedField.zoneAcres = sumZoneAcres(updatedField.zones);
      updatedField.gridSizeAcres = '2.5';

      // If image exists, clear it since zones have changed
      updatedField.fieldImage = '';

      // Remove poly layer
      map.removeLayer(newPolygonZone.layer);

      setNewPolygonZone(null);
      selectField(updatedField);
      setActiveTool('zoneCreation');
    }
  }, [newPolygonZone]);

  useEffect(() => {
    if (zonesToUpdate?.length) {
      const updatedZones = [];
      const updatedZoneKeys = [];
      for (const zone of zonesToUpdate) {
        const geoJson = zone.toGeoJSON();

        const updatedZone = updateEditedZone(geoJson);
        updatedZones.push(updatedZone);
        updatedZoneKeys.push(geoJson?.properties?.mukey);
      }

      const notUpdateZones = selectedField.zones.filter(
        (x) => !updatedZoneKeys.includes(x.properties.mukey),
      );

      const allZones = [...updatedZones, ...notUpdateZones];

      selectField({
        ...selectedField,
        fieldImage: '',
        zoneAcres: sumZoneAcres(allZones),
        zones: allZones,
      });

      setZonesToUpdate([]);
    }
  }, [zonesToUpdate]);

  useEffect(() => {
    if (autoGenerateGrids) {
      selectWholeField(gridSizeAcres.current, selectedField);
    }
  }, [autoGenerateGrids]);

  const handleShapeField = (field) => {
    if (field?.coordinates?.coordinates.length === 1) {
      // Create field some polyon
      drawField(field, true);
      getBoundingBox(field.boundary, 0, 0);
    } else {
      // Create zones from multipolygon
      const zones = createZonesFromBoundary(field.coordinates.coordinates);

      selectField({
        ...field,
        gridSizeAcres: '2.5',
        fieldImage: '',
        zoneAcres: sumZoneAcres(zones),
        zones,
        zoneType: 'shapefile',
      });
    }
    setActiveTool('zoneCreation');
  };

  const drawFieldZones = (zones, zoneType) => {
    const featureCollection = {
      type: 'FeatureCollection',
      features: zones,
    };

    // Clear layers so we don't increase opacity by drawing on top
    geoZones.current.clearLayers();

    geoZones.current = L.geoJSON(featureCollection);
    geoZones.current.eachLayer((layer) => {
      try {
        const { color } = layer.feature.properties;
        layer.setStyle({
          color: color !== '' ? color : '#3b93ff',
          fillColor: color !== '' ? color : '#f3e5f5',
          fillOpacity: zoneType === 'ssurgo' ? '.5' : '.15',
        });

        const toolTip = `<div style="height: 30; width: 30;">
          <div style="margin: 2px 6px;">Zone: ${layer?.feature?.properties?.zone}</div>
          <div style="margin: 2px 6px;">Acres: ${numFormat(layer?.feature?.properties?.CALCACRES)}</div>
        </div>`;
        layer.bindTooltip(toolTip, { className: 'leaftletTooltipClass' });

        // On edit complete
        layer.on('pm:update', ({ layer, shape }) => {
          setZonesToUpdate((prev) => [...prev, layer]);
        });
      } catch (err) {
        console.log(err);
      }
    });

    geoZones.current.addTo(map);
  };

  const drawFieldsInBounds = () => {
    // drawMap expects this function, but not currently used in this component
  };

  /**
   * Draw field boundaries on map and add appropriate functions to thier layer.
   * Convert stored field boundary to geoJson.
   * If selected field, fit map to its bounds.
   * @param  {Object}  field Field data, including boundary and name
   */
  const drawField = (field) => {
    try {
      const geo = createGeoFromBoundary(field.boundary);

      fieldLayer.current.clearLayers();

      const newCollection = L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          fieldLayer.current.addLayer(layer).setStyle({
            color: '#8e24aa', fillColor: '#f3e5f5', opacity: 1, fillOpacity: 0,
          });

          layer.on('pm:update', ({ layer, shape }) => {
            updateFieldBoundary(layer);
          });
        },
      }).addTo(map);

      map.fitBounds(newCollection.getBounds(), {
        padding: [30, 30],
      });
    } catch (err) {
      console.log(err);
    }
  };

  const resetZones = () => {
    if (originalFieldBoundary.current !== null) {
      // field was edited
      selectField({
        ...selectedField,
        acres: originalFieldBoundary.current.acres,
        boundary: originalFieldBoundary.current.boundary,
        coordinates: originalFieldBoundary.current.coordinates,
        geojson: originalFieldBoundary.current.geojson,
        fieldImage: '',
        points: originalFieldBoundary.current.points,
        zoneAcres: 0,
        zones: [],
      });
    } else {
      selectField({
        ...selectedField, fieldImage: '', zoneAcres: 0, zones: [],
      });
    }

    clearLayers();
    clearGrid(false);
    removeGridControls();
    setShowSsurgoTable(null);
    setSsurgoLegend([]);
    setAverageNCCPI('');
    setOpen(true);
  };

  const clearLayers = () => {
    // fieldLayer.current.clearLayers()
    drawnPolygons.current.clearLayers();
    gridlayer.current.clearLayers();
    createdZones.current.clearLayers();
    geoZones.current.clearLayers();
  };

  // ---------------------------------------------------------------------------
  // ######################  Initial Selection  ######################
  // ---------------------------------------------------------------------------
  const selectWholeField = (acres, field = selectedField) => {
    try {
      addGrid(acres, true);
      gridSizeAcres.current = acres;

      const allSelected = [];

      for (let i = 0; i < gridTiles.current.length; i++) {
        const selectTile = { ...gridTiles.current[i], selected: true, zone: i + 1 };
        allSelected.push(selectTile);
      }

      gridTiles.current = allSelected;
      maxZoneNumber.current = gridTiles.current.length;

      finishGridCreation(field, true);
    } catch (err) {
      console.log(err);
    }
  };

  const createFromShapeFile = async (e) => {
    clearLayers();
    handleShapeFileUpload(e, selectField);
  };

  // ---------------------------------------------------------------------------
  // ######################  Grid tools  ######################
  // ---------------------------------------------------------------------------
  const startGridCreation = () => {
    clearLayers();
    addGrid(gridSizeAcres.current, true);
    addGridControls();
    setActiveTool('grid');
  };

  /**
   * This is the main function for the grid tool. Anytime the grid moves, or
   * changes it gets re-rendered here. This will handle styling so that clicking
   * next zone will start highlighting in a new color, it will also create a
   * bounding box for a field and apply changes to it to reflect the movements
   * made with the controls. i.e. rotate, shift x or y and change size (e)
   * @param {int} gridSizeInAcres Size of the grids
   * @param {bool} firstRender this exists because if its the first time drawing there grid there are some
   * things have to happen a little differently
   */
  const addGrid = (gridSizeInAcres, firstRender = false) => {
    try {
      // lets begin by clearing any old grids, and revealing controls
      const gridSize = Math.sqrt(turf.convertArea(gridSizeInAcres, 'acres', 'kilometers'));
      gridlayer.current.clearLayers();
      const squareGrid = turf.squareGrid(gridBoundingBox.current, gridSize);

      // Now we will get intro tracking grid tiles
      const selectedTilesData = getSelectedTilesData(zoneNum, squareGrid, gridTiles.current, firstRender);

      if (rotateVal.current !== 0) {
        updateGridOnRotateValue(gridTiles.current, squareGrid, rotateVal.current);
      }

      gridlayer.current.addData(squareGrid);

      gridlayer.current.eachLayer((layer) => {
        layer.setStyle({
          weight: 0.75,
          color: 'white',
          opacity: 0.75,
          fillOpacity: 0.1,
        });
      });

      gridTiles.current = selectedTilesData;
      gridlayer.current.addTo(map);
    } catch (err) {
      console.error(err);
    }
    setActiveTool('grid');
  };

  const getBoundingBox = (boundary, EWoffset, NSoffset, baseOffset = 0.005) => {
    try {
      const parsed = JSON.parse(boundary);
      const feature = parsed.type === 'FeatureCollection' ? JSON.stringify(parsed.features[0]) : boundary;
      const geo = createGeoFromBoundary(feature);

      const bbox = createBoundingBox(geo, EWoffset, NSoffset, baseOffset);
      gridBoundingBox.current = bbox;
    } catch (err) {
      console.error(err);
    }
  };

  const prevZone = () => {
    if (zoneNum > 1) {
      zoneNum--;
    }
    setToolTip(`Editing Zone: ${zoneNum}`);
    addGrid(gridSizeAcres.current, false);
  };

  const addNewGridZone = () => {
    if (getSelectedForZone(gridTiles.current, zoneNum) > 0) {
      zoneNum++;
      setToolTip(`Editing Zone: ${zoneNum}`);
      addGrid(gridSizeAcres.current, false);
    } else {
      enqueueSnackbar('Please Select At Least One Tile Before Changing Zones');
    }
  };

  const addGridControls = () => {
    if ('slider' in gridSliders.current.slider1) {
      // controls already added
      return;
    }

    const sliders = createGridControls(handleRotateSlider, moveGrid);
    gridSliders.current = { slider1: sliders[0], slider2: sliders[1], slider3: sliders[2] };
    // after we define the controls we can add them to the map
    gridSliders.current.slider1.addTo(map);
    gridSliders.current.slider2.addTo(map);
    gridSliders.current.slider3.addTo(map);
  };

  const handleRotateSlider = (newvalue) => {
    rotateVal.current = newvalue;
    addGrid(gridSizeAcres.current, false);
  };

  /**
   * @param {int} newvalue amount to move by
   * @param {string} dir either "x" or "y" to decide which direction to move the grid
   */
  const moveGrid = (newvalue, dir) => {
    switch (dir) {
      case 'x':
        eastWestOffset.current = newvalue;
        getBoundingBox(selectedField.boundary, newvalue, northSouthOffset.current);
        addGrid(gridSizeAcres.current);
        break;
      case 'y':
        northSouthOffset.current = newvalue;
        getBoundingBox(selectedField.boundary, eastWestOffset.current, newvalue);
        addGrid(gridSizeAcres.current);
        break;
    }
  };

  // remove controls that are anchored to the map view for grid movements
  const removeGridControls = () => {
    gridSliders.current.slider1.remove();
    gridSliders.current.slider2.remove();
    gridSliders.current.slider3.remove();

    gridSliders.current = {
      slider1: L.control.slider(),
      slider2: L.control.slider(),
      slider3: L.control.slider(),
    };
  };

  const clearGrid = (addBack = true) => {
    rotateVal.current = 0;
    zoneNum = 1;

    gridTiles.current = [];
    setSelectedTiles(0);

    setToolTip(`Editing Zone: ${zoneNum}`);
    gridlayer.current.clearLayers();
    createdZones.current.clearLayers();
    geoZones.current.clearLayers();

    if (addBack) {
      startGridCreation();
    }
  };

  const finishGridCreation = (field, removeColors = false) => {
    let zones = createGridZones(
      gridTiles.current,
      createdZones.current,
      drawnZones.current,
      gridlayer.current,
      setZones,
      zoneColors,
      clearGrid,
      maxZoneNumber.current,
      field.boundary,
      map,
    );
    setActiveTool('');
    removeGridControls();

    if (zones?.length) {
      if (removeColors && zones !== undefined) {
        zones = zones.map((x) => (
          {
            ...x,
            properties: { ...x.properties, color: '' },
          }));
      }

      const fieldWithZones = {
        ...field,
        zones,
        gridSizeAcres: gridSizeAcres.current,
        fieldImage: '',
        zoneAcres: sumZoneAcres(zones),
        zoneType: 'grid',
      };

      selectField(fieldWithZones);
      goToSelectPackage(true);
    } else {
      enqueueSnackbar('Unable to create grid for this field. Please try a different method such as creating zones or select a different field.');
      setOpen(true);
    }
  };

  // Radio button control to toggle between 2.5 and 5 acre grid
  const handleGridSizeChange = (event) => {
    setCheckedGridSize(event.target.value);
    gridSizeAcres.current = event.target.value;
    clearGrid(false);
    addGrid(event.target.value, true);
  };

  // ---------------------------------------------------------------------------
  // ######################  Split Tool  ######################
  // ---------------------------------------------------------------------------
  const startSlice = () => {
    setActiveTool('slice');
    setZoneInstructionsOpen(false);
    slicingMode.current = true;
    const lineControl = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polyline',
    )[0];
    lineControl.click();
  };

  const addSlicedZones = (sliced) => {
    if (!sliced) { return; }
    const newZones = [];

    for (let i = 0; i < sliced.length; i++) {
      const zone = {
        geometry: sliced[i].geometry,
        properties: {
          color: sliced[i].properties.color,
          CALCACRES: sliced[i].properties.CALCACRES,
          zone: i + 1,
        },
        type: 'Feature',
      };
      newZones.push(zone);
    }

    const updatedField = cloneDeep(selectedField);
    updatedField.zones = newZones;
    updatedField.zoneType = 'polygon';
    updatedField.zoneAcres = sumZoneAcres(newZones);
    updatedField.gridSizeAcres = '2.5';

    // If image exists, clear it since zones have changed
    updatedField.fieldImage = '';

    selectField(updatedField);
  };

  const finishSlice = () => {
    setActiveTool('');
    slicingMode.current = false;

    slicingMode.current = false;
    const lineControl = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polyline',
    )[0];
    const control = lineControl.parentNode;
    const lineEditControls = control.nextSibling;
    let finishLine = null;
    for (let i = 0; i < lineEditControls.childNodes.length; i++) {
      if (
        lineEditControls.childNodes[i].className
        === 'leaflet-pm-action  action-cancel'
      ) {
        finishLine = lineEditControls.childNodes[i];
        break;
      }
    }
    finishLine.click();

    map.removeLayer(slicedZones.current);
    goToSelectPackage();
  };

  // ---------------------------------------------------------------------------
  // ######################  SSURGO  ######################
  // ---------------------------------------------------------------------------
  const createFromSsurgo = async () => {
    clearLayers();
    setZoneInstructionsOpen(false);
    setActiveTool('ssurgo');
    setLoading(true);

    const { ssurgoGeo, legend, average } = await getSsurgo(selectedField.boundary, zoneColors);

    setLoading(false);

    setSsurgoLegend(legend);
    setAverageNCCPI(average);

    if (exists(legend)) {
      // If window is big enough, display ssurgo table, otherwise give user option
      if (window.innerWidth > 1080) {
        setShowSsurgoTable('table');
      } else {
        setShowSsurgoTable('option');
      }
    }

    if (ssurgoGeo?.features !== undefined) {
      const field = {
        ...selectedField,
        gridSizeAcres: '2.5',
        fieldImage: '',
        zoneAcres: sumZoneAcres(ssurgoGeo.features),
        zones: ssurgoGeo.features,
        zoneType: 'ssurgo',
      };

      selectField(field);
    } else {
      enqueueSnackbar('Unable to create SSURGO zones for this field. Please try a different method such as grid or select a different field.');
    }
  };

  // ---------------------------------------------------------------------------
  // ######################  Zones  ######################
  // ---------------------------------------------------------------------------
  const createZonesFromBoundary = (coordinates) => {
    const zones = [];
    let zoneNumber = 1;
    // eslint-disable-next-line no-restricted-syntax
    for (const coordinate of coordinates) {
      const zone = createZone(coordinate, zoneNumber);
      zones.push(zone);
      zoneNumber++;
    }
    return zones;
  };

  const createZone = (coordinate, zoneNumber) => {
    const zone = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: coordinate,
      },
      properties: {
        CALCACRES: 5,
        zone: zoneNumber,
        color: zoneColors[zoneNumber - 1],
      },
    };

    return zone;
  };

  const startZoneCreation = () => {
    setActiveTool('zoneCreation');
  };

  const addPolygon = () => {
    setZoneInstructionsOpen(false);
    setActiveTool('polygon');

    // start polygon draw
    const draw = document.getElementsByClassName('control-icon leaflet-pm-icon-polygon');
    draw[0].click();
  };

  const addSquare = () => {
    setZoneInstructionsOpen(false);
    setActiveTool('polygon');

    // start square polygon draw
    const draw = document.getElementsByClassName('control-icon leaflet-pm-icon-rectangle');
    draw[0].click();
  };

  const cancelDraw = () => {
    map.pm.Draw.disable();
    setActiveTool('zoneCreation');
  };

  const editBoundaries = () => {
    setActiveTool('editing');
    setZoneInstructionsOpen(false);
    const edit = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-edit',
    );
    edit[0].click();
  };

  const finishEdit = () => {
    map.pm.disableGlobalEditMode();
    setActiveTool('zoneCreation');
  };

  const originalFieldBoundary = useRef(null);

  const updateFieldBoundary = (layer) => {
    originalFieldBoundary.current = {
      acres: selectedField.acres,
      boundary: selectedField.boundary,
      coordinates: selectedField.coordinates,
      geojson: selectedField.geojson,
      points: selectedField.points,
    };

    const geoJson = layer.toGeoJSON();
    const collection = {
      type: 'FeatureCollection',
      features: [geoJson],
    };

    const meters = turf.area(collection);
    const acres = turf.convertArea(meters, 'meters', 'acres');

    const updatedField = {
      ...selectedField,
      acres,
      boundary: JSON.stringify(geoJson),
      coordinates: geoJson.geometry,
      geojson: geoJson,
      points: geoJson.geometry.coordinates,
    };

    selectField(updatedField);
  };

  const updateEditedZone = (updatedZone) => {
    const updateZones = selectedField.zones.filter(
      (x) => x.properties.mukey !== updatedZone.properties.mukey,
    );

    const collection = {
      type: 'FeatureCollection',
      features: [updatedZone],
    };

    const meters = turf.area(collection);
    const acres = turf.convertArea(meters, 'meters', 'acres');

    // Update area
    const newZone = { ...updatedZone };
    newZone.properties.CALCACRES = acres;
    newZone.properties.area = meters;

    return newZone;
  };

  // ---------------------------------------------------------------------------
  // ######################  Map Controls  ######################
  // ---------------------------------------------------------------------------
  const gridToolBar = () => (
    <Box className={classes.mapControls} style={{ justifyContent: 'space-between' }}>
      <Fade in={!gridInstructionsOpen}>
        <IconButton color="primary" onClick={() => setGridInstructionsOpen(true)}>
          <InfoOutlinedIcon />
        </IconButton>
      </Fade>

      <Box display="flex" flexWrap="wrap">
        <Box>
          <Box
            px={1}
            display="flex"
            alignItems="center"
            borderRadius="borderRadius"
            border={1}
            bgcolor="#ffffff"
            style={window.innerWidth > 540 ? {} : { width: 180 }}
          >
            <Box display="flex" fontSize="0.8rem">
              {'Grid Size (Acres' }
              {window.innerWidth > 540 ? '/Tile)' : ')'}
            </Box>

            <Box display="flex" alignItems="center" height={34} mr={1}>
              <Radio
                checked={checkedGridSize === '2.5'}
                onChange={handleGridSizeChange}
                disabled={selectedTiles > 0}
                value="2.5"
                name="grid-size-control"
                inputProps={{ 'aria-label': '2.5' }}
                color="primary"
                style={{ padding: 2 }}
              />
              <Box>
                2.5
              </Box>
            </Box>

            <Box display="flex" alignItems="center" height={34}>
              <Radio
                checked={checkedGridSize === '5'}
                onChange={handleGridSizeChange}
                disabled={selectedTiles > 0}
                value="5"
                name="grid-size-control"
                inputProps={{ 'aria-label': '5' }}
                color="primary"
                style={{ padding: 2 }}
              />
              <Box>
                5
              </Box>
            </Box>
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap">
          <Button
            variant="contained"
            color="primary"
            style={{ margin: window.innerWidth > 500 ? '0 8px' : '0 4px' }}
            size={window.innerWidth > 500 ? 'medium' : 'small'}
            disableElevation
            onClick={() => selectWholeField(gridSizeAcres.current, selectedField)}
          >
            {'Confirm'}
            {window.innerWidth > 500 && ' Grid'}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            style={{ margin: window.innerWidth > 500 ? '0 8px' : '0 4px', backgroundColor: '#ffffff' }}
            size={window.innerWidth > 500 ? 'medium' : 'small'}
            disableElevation
            onClick={() => resetZones()}
          >
            Reset
          </Button>

        </Box>
      </Box>

      <Box width={window.innerWidth > 600 ? 24 : 0}>{' '}</Box>
    </Box>
  );

  const editToolbar = () => (
    <Box className={classes.mapControls}>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: '0 8px' }}
        disableElevation
        onClick={() => finishEdit()}
      >
        Finish
      </Button>
    </Box>
  );

  const slicingToolBar = () => (
    <Box className={classes.mapControls}>
      <Button
        variant="contained"
        color="primary"
        style={{ margin: '0 8px' }}
        disableElevation
        onClick={() => finishSlice()}
      >
        Finish
      </Button>
    </Box>
  );

  const genericToolBar = () => (
    <Box className={classes.mapControls}>
      { loading ? (
        <Box height={36}>{' '}</Box>
      ) : selectedField?.zones !== undefined || selectedField?.zones?.length > 0 ? (
        <>
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 8px' }}
            disableElevation
            onClick={() => goToSelectPackage()}
          >
            {window.innerWidth > 500 && 'Continue to '}
            {'Select Package'}
          </Button>

          { selectedField?.zoneType === 'ssurgo' && (
          <CustomToolTip
            title="Edit existing boundaries"
            placement="top-start"
          >
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '0 8px', backgroundColor: '#ffffff' }}
              onClick={() => editBoundaries()}
            >
              Edit Zones
            </Button>
          </CustomToolTip>
        )}

          <Button
            variant="outlined"
            color="primary"
            style={{ margin: '0 8px 0 8px', backgroundColor: '#ffffff' }}
            onClick={() => resetZones()}
          >
            Reset
          </Button>
        </>
      ) : (
        <Box height={36} />
      )}
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      height={height}
      width="100%"
    >
      { activeTool === 'grid' ? (
        gridToolBar()
      ) : activeTool === 'zoneCreation' ? (
        <ZoneToolBar
          addPolygon={addPolygon}
          addSquare={addSquare}
          editBoundaries={editBoundaries}
          createFromSsurgo={selectedField?.state !== '' ? createFromSsurgo : null}
          addGrid={startGridCreation}
          slice={startSlice}
          resetZones={resetZones}
          showFinish={selectedField?.zones?.length > 0}
          finish={goToSelectPackage}
          instructionsOpen={zoneInstructionsOpen}
          showInstructions={setZoneInstructionsOpen}
        />
      ) : (activeTool === 'polygon') ? (
        <DrawingToolBar cancel={cancelDraw} />
      ) : activeTool === 'editing' ? (
        editToolbar()
      ) : activeTool === 'slice' ? (
        slicingToolBar()
      ) : genericToolBar() }

      <Box
        id="select-zones-map"
        display="flex"
        flexGrow={1}
      />

      <ZoneCreation
        open={open}
        setOpen={setOpen}
        createGrids={startGridCreation}
        createZones={startZoneCreation}
        goToFieldSelection={goToFieldSelection}
        createFromShapeFile={createFromShapeFile}
        toolTip={toolTip}
        fieldSource={selectedField?.source}
      />

      { (showSsurgoTable !== null) && (
        <SsurgoTable
          average={averageNCCPI}
          legend={ssurgoLegend}
          fieldAcres={selectedField.acres}
          maxHeight={height - 100}
          display={showSsurgoTable}
          setShowSsurgoTable={setShowSsurgoTable}
        />
      )}

      { loading && <SpinningLoader /> }

      { activeTool === 'grid' && gridInstructionsOpen && (
        <GridInstructions
          open={gridInstructionsOpen}
          setOpen={setGridInstructionsOpen}
        />
      )}

      { activeTool === 'zoneCreation' && zoneInstructionsOpen && (
        <SoilZoneInstructions
          open={zoneInstructionsOpen}
          setOpen={setZoneInstructionsOpen}
        />
      )}

    </Box>
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { Cancel } from "@material-ui/icons";
import { without } from "lodash";
import { COLORS } from "../../../../../constants";
import ThemedChip from "../../../../Helpers/ThemedChip";

const useStyles = makeStyles((theme) => ({
  formControl: {
    width: "100%",
    padding: "10px 24px 24px 24px",
    borderRadius: 5,
    "& .MuiInputBase-root": {
      height: 'auto',
    },
    "& .MuiSelect-select:focus": {
      backgroundColor: "rgba(0, 0, 0, 0)",
    },
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    marginBottom: theme.spacing(1),
    color: COLORS.darkGray,
    paddingLeft: 26,
    paddingTop: 26,
  },
  chips: {
    display: "flex",
    flexWrap: "wrap",
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    // marginTop: theme.spacing(3),
  },
}));

/**
 * Handles the multi select field in the filtered results.
 * @param {object} label for label
 * @param {object} option object containing information
 * (including the starting range and the filtered range)
 * about the object appearing in the multiselect
 * @param {array} options array of objects
 * @param {function} options  set state function needed to update the option's filtering
 */

const CustomMultiSelect = ({ label, option, options, setOptions }) => {
  const classes = useStyles();
  const [selectedItems, setSelectedItems] = useState(
    options.filter((op) => op.label === option.label).length > 0
      ? options.filter((op) => op.label === option.label)[0].filteredRange
      : []
  );

  // const handleChange = (evt, value) => {
  //   let idx = option.range.indexOf(evt.target.value[0]);

  //   for (let i = 0; i < option.range.length; i++) {
  //     let index = value.filter((o) => o === option.range[i]);
  //     if (index.length == 0) {
  //       idx = i;
  //     }
  //   }

  //   let filter = options.map((d) => {
  //     if (d.label === option.label) {
  //       return {
  //         ...d,
  //         filteredRange:
  //           idx > -1
  //             ? d.filteredRange.splice(idx, 1)
  //             : d.filteredRange.concat(evt.target.value),
  //       };
  //     }
  //     return d;
  //   });

  //   setOptions(options);
  // };
  const handleChange = (event) => {
    setSelectedItems(event.target.value);
  };

  const handleDelete = (e, value) => {
    // e.preventDefault();
    setSelectedItems((current) => without(current, value));
  };

  useEffect(() => {
    setOptions(
      options.map((item) =>
        item.label === option.label
          ? selectedItems.length
            ? { ...item, filteredRange: selectedItems }
            : { ...item, filteredRange: option.range }
          : item
      )
    );
  }, [selectedItems]);

  // FYI: 'selected' in renderValue is a MUI thing -- the array of your selections
  return (
    <div>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <FormControl className={classes.formControl}>
        <Select
          id="demo-mutiple-chip"
          multiple
          variant="outlined"
          value={selectedItems}
          placeholder="Select Items"
          onChange={handleChange}
          renderValue={(selected) => (
            <div className={classes.chips}>
              {selected.map((value) => (
                value !== "" &&
                <ThemedChip
                  key={value}
                  label={value}
                  className={classes.chip}
                  deleteIcon={
                    <Cancel onMouseDown={(event) => event.stopPropagation()} />
                  }
                  onDelete={(e) => handleDelete(e, value)}
                />
              ))}
            </div>
          )}
        >
          <MenuItem disabled value="">
            <em>Choose items</em>
          </MenuItem>
          {option.range.map((item) => (
            item !== "" &&
            (<MenuItem key={item} value={item}>
              {item}
            </MenuItem>)
          ))}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomMultiSelect;

CustomMultiSelect.propTypes = {
  label: PropTypes.object.isRequired,
  option: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
};

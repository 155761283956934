import * as turf from "@turf/turf";

export const fieldToolTip = (field) => {
  let name = ''
  let coordinates = ''
  let state = ''
  let county = ''

  if (field.name !== undefined) {
    // Field from database
    name = `Field: ${field.name}`
    state = field.state !== '' ? `State: ${field.state}` : ''
    county = field.county ? `County: ${field.county}` : ''
    try {
      coordinates = field.centroid !== undefined && field.centroid.geometry.coordinates[1] !== undefined ? `Coordinates: ${field.centroid.geometry.coordinates[1].toFixed(2)}, ${field.centroid.geometry.coordinates[0].toFixed(2)}` : ""
    } catch (e) {
      console.log(`Problem getting centroid: ${e}`)
    }
  } else {
    // CLU or drawn field
    try {
      const centroid = turf.centroid(field.feature);
      const centerPoints = centroid.geometry.coordinates;
      coordinates = `Coordinates: ${centroid.geometry.coordinates[1].toFixed(2)}, ${centroid.geometry.coordinates[0].toFixed(2)}`
      name = field.fieldName !== undefined ? `Field: ${field.fieldName}` : ""
      // get state and county maybe?
    } catch (e) {
      console.log(`Problem getting centroid: ${e}`)
    }
  }

  return (
    `<div style="height: 30; width: 30;">
      <div style="margin: 2px 6px;">${name}</div>
      <div style="margin: 2px 6px;">Acres: ${field.acres !== undefined ? field.acres.toFixed(2) : ''}</div>
      <div style="margin: 2px 6px;">${coordinates}</div>
      <div style="margin: 2px 6px;">${state}</div>
      <div style="margin: 2px 6px;">${county}</div>
    </div>`
  )
}

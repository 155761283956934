import React, { useEffect, useState, useContext, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Select, MenuItem, Link, Divider } from '@material-ui/core';
import { blackText, grey, lightGrey } from "../../styles/colors";
import { useWindowDimensions } from '../../utils/dimensions'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { FieldContext } from '../Context/FieldContext'
import { scrollTo, dollarFormat } from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  table: { 
    minWidth: '400px', 
    maxWidth: '800px', 
    backgroundColor: "white"
  },
  comp: {
    borderStyle: 'solid',
    borderColor: 'rgb(217,217,217)',
    borderRadius: '3pt',
    margin: '2pt'
  }
}))

const leftTitleWidth = '60%'
const rightTitleWidth = '500%'

/**
 * Table that displays other parcesl in a scrollable list
 * expanding the details of a parcel will zoom the map to view the boundary
 * @param {object} comps response object from getParcels() in the LoanUnderwriting component
 */
export function ParcelSalesTable({comps, activeParcel, setActiveParcel}){
  const {width, height} = useWindowDimensions();
  const tableBreakPoint = 660;
  const [fieldData, setFieldData] = useContext(FieldContext);


  const classes = useStyles()
  const [expanded, setExpanded] = useState(-1)

  const [landComps, setLandComps] = useState([])

  /**
   * comps are an object, easier to render as list for table
   * so we convert to list and set state variable
   */
  useEffect(()=>{
    if(comps !== undefined && comps !== null){
      let items = []
      for(let i in comps.parcels){
        items.push(comps.parcels[i])
      }
      setLandComps(items)
    }
  },[comps])

  /**
   * when the activeParcel index is updated from the map
   * expand the corresponding details and scroll to the item
   */
  useEffect(() => {
    if(fieldData.activeParcel !== undefined && fieldData.activeParcel.index !== -1 && fieldData.activeParcel.index !== expanded){
      setExpanded(fieldData.activeParcel.index)
      scrollTo("comp"+fieldData.activeParcel.index)
    }
  },[fieldData.activeParcel])

  useEffect(() => {
    console.log(activeParcel)
    if(activeParcel !== undefined && activeParcel.index !== -1 && activeParcel.index !== expanded){
      setExpanded(activeParcel.index)
      scrollTo("comp"+activeParcel.index)
    }
  },[activeParcel])

  /**
   * when an item is expanded fire the onclick event for the corresponding marker to zoom to the property
   * also update the activeParcel index to keep it consistent with expanded
   */
  useEffect(() => {
    if(fieldData.activeParcel !== undefined && expanded !== -1 && fieldData.activeParcel.index !== expanded){
      const marker = fieldData.activeParcel.parcelMarkers.current[expanded]
      marker.fire('click', marker._latlng)
      let updatedField = {...fieldData}
      updatedField.activeParcel.index = expanded
      setFieldData(updatedField)
    }
    if(activeParcel !== undefined && activeParcel.index !== expanded && expanded !== -1){
      const marker = activeParcel.parcelMarkers.current[expanded]
      marker.fire('click', marker._latlng)
      setActiveParcel({...activeParcel, index:expanded})
    }
  }, [expanded])


  return(
    <Box>
    
      <Box
        m={1}
        border={1}
        borderColor={grey}
        borderRadius='borderRadius'
        className={classes.table}
      >
        <Box
          p={1}
          display='flex'
          fontSize={width > tableBreakPoint ? 14 : 12}
          style={{textAlign: "center"}}
        >
          <Box style={{width: '100%'}}>
            Comparable Sales
          </Box>
        </Box>
        <Divider/>
        <Box
          style={{overflowY:'auto', maxHeight:'175px'}}
        >
          {landComps.map((item, i) => (
            <Box display='flex' key={i} id={"comp"+i} className={classes.comp}>
              <Box style={{width:'100%'}}>
                {expanded !== i ?
                <ExpandMoreIcon onClick={() => setExpanded(i)} style={{cursor: 'pointer'}}/>
                :
                <ExpandLessIcon onClick={() => setExpanded(-1)} style={{cursor: 'pointer'}}/>
                }
                {`${item.parcels[0].state_name} | ${item.parcels[0].County} | ${item.Total_Acres} Acres`}
                {expanded === i && 
                  <Box>
                    <Divider/>
                    <Box display='flex'>
                      <Box style={{width:'50%'}}>
                        {/** AVG CSR  PERCENT TILL  TILL ACRES */}
                        <Box display='flex'>
                          <Box style={{width: leftTitleWidth}}>
                            Avg CSR2
                          </Box>
                          <Box>
                            {item.Avg_CSR2.toFixed(3)}
                          </Box>
                        </Box>
                        <Box display='flex' style={{backgroundColor: 'rgb(244, 244, 244)'}}>
                          <Box style={{width: leftTitleWidth}}>
                            Percent Tillable
                          </Box>
                          <Box>
                            {`${item.Percent_Tillable}%`}
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Box style={{width: leftTitleWidth}}>
                            Tillable Acres
                          </Box>
                          <Box>
                            {item.Tillable_Acres.toFixed(3)}
                          </Box>
                        </Box>
                      </Box>
                      <Divider orientation="vertical" flexItem/>
                      <Box style={{width:'50%'}}>
                        <Box display='flex' style={{backgroundColor: 'rgb(244, 244, 244)'}}>
                          <Box style={{width: rightTitleWidth}}>
                            Sale Price
                          </Box>
                          <Box>
                            {dollarFormat(item.Sale_Price)}
                          </Box>
                        </Box>
                        <Box display='flex'>
                          <Box style={{width: rightTitleWidth}}>
                            Price Per Acre
                          </Box>
                          <Box>
                            {dollarFormat(item.Price_Acre)}
                          </Box>
                        </Box>
                        <Box display='flex' style={{backgroundColor: 'rgb(244, 244, 244)'}}>
                          <Box style={{width: rightTitleWidth}}>
                            Sale Date
                          </Box>
                          <Box>
                            {new Date(item.Sale_Date).toLocaleDateString()}
                          </Box>
                        </Box>
                      </Box>
                    </Box>
                  </Box>
                }
              </Box>
            </Box>
          )
          )}

        </Box>

      </Box>

    </Box>
  )
}
import React from "react";
import PropTypes from "prop-types";
import { geoPath, geoMercator } from "d3-geo";
import { makeStyles } from "@material-ui/core/styles";
import ActivePath from "./ActivePath";
import Path from "../../../../../Helpers/Path";
import { useDimensions } from "../../../../../../utils";
import { SINGLE_DECIMAL_FORMAT } from "../../../../../../constants";

const useStyles = makeStyles(() => ({
  map: {
    width: "100%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: 0,
  },
}));

/**
 * Renders the map
 * @param {object} counties object with county data
 * @param {function} colorScale function that assigns color according to scale
 * @param {object} activeFarm object with farm data
 * @param {object} activeMarker starts as null and is given properties
   from features data in this component
 * @param {function} setActiveMarker sets properties of active marker
 * @param {function} idAccessor accesses id property
 * @param {string} topic either 'Yield/Acre'  or 'Precipitation'

 */

const Map = ({
  counties,
  colorScale,
  activeFarm,
  activeMarker,
  setActiveMarker = () => null,
  idAccessor = (f) => f.GEOID,
  topic,
}) => {
  const classes = useStyles();
  const [visualizationRef, { width, height }] = useDimensions({
    width: 100,
    height: 100,
  });

  // Fit to the bounding box
  const padding = 10;
  const projection = geoMercator().fitExtent(
    [
      [padding, padding],
      [width - padding, height - padding],
    ],
    counties
  );
  const path = geoPath().projection(projection);

  const getCentroidOfActiveMarker = (feature) => {
    // Get centroid of active polygon
    const activePolygon = counties.features.find(
      (polygon) => idAccessor(polygon.properties) === idAccessor(feature)
    );
    setActiveMarker({
      ...feature,
      countyName: activePolygon.properties.NAME,
      farmName: activeFarm.name,
      centroid: path.centroid(activePolygon),
      farmValueText: `${SINGLE_DECIMAL_FORMAT(activeFarm.yield)} bushels/acre`,
      countyValueText: `${SINGLE_DECIMAL_FORMAT(
        activePolygon.properties.value[`${topic}`]
      )} ${topic === "Yield/Acre" ? "bushels/acre" : "inches"}`,
    });
  };

  return (
    <svg
      width={width}
      height={height}
      ref={visualizationRef}
      className={classes.map}
    >
      <g className="map">
        <g className="county-paths" onMouseOut={() => setActiveMarker(null)}>
          {counties.features.map((county) => {
            const id = idAccessor(county.properties);
            return (
              <g key={`county-path-for-${id}`}>
                <Path
                  feature={{
                    ...county.properties,
                    color: colorScale(
                      +county.properties.value[`${topic}`] || 0
                    ),
                  }}
                  d={path(county)}
                  setActiveMarker={getCentroidOfActiveMarker}
                />
              </g>
            );
          })}
        </g>
        <g className="active-county-path">
          {activeMarker &&
            counties.features
              .filter(
                (county) =>
                  idAccessor(county.properties) === idAccessor(activeMarker)
              )
              .map((county) => {
                const id = idAccessor(county.properties);
                return (
                  <g key={`active-county-path-for-${id}`}>
                    <ActivePath d={path(county)} />
                  </g>
                );
              })}
        </g>
      </g>
    </svg>
  );
};

export default Map;

Map.propTypes = {
  counties: PropTypes.object.isRequired,
  colorScale: PropTypes.func.isRequired,
  activeFarm: PropTypes.object.isRequired,
  activeMarker: PropTypes.object,
  setActiveMarker: PropTypes.func.isRequired,
  idAccessor: PropTypes.func,
  topic: PropTypes.string.isRequired,
};

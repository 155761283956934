import domtoimage from 'dom-to-image';

/**
 * Displays a message in console with formatting that marks it as important.
 * It only accepts one argument. So make sure to concatenate all you want to display.
 * Preferrably, also have a normal console.log (with actual error) after call to this.
 *
 * e.g.
 * consoleImportant('An error occurred');
 * console.log('error:', error);
 *
 * @param {String} msg message to display in console
 */
export function consoleImportant(msg) {
  console.log(
    '%c %s %s %s',
    'color: brown; font-weight: bold; text-decoration: underline;',
    '–',
    msg,
    '–',
  );
}

/**
 * Takes array, string, or number. Checks if value is not undefined, null, or empty.
 * Note that this classifies nested empty arrays as existing, ie. [[]] => true
 * Allows 0 as a valid value
 *
 * @param {Any} check variable to check for existence
 */
export function exists(check) {
  try {
    // necessary before array check since null would make it there and break map
    if (check === undefined || check === null || check === '') {
      return false;
    }
    if (typeof check === 'object') {
      if (Array.isArray(check)) {
        if (check.length === 0) {
          return false;
        }
        const exist = check.map(
          (item) => item !== undefined && item !== null && item !== '',
        );
        return exist.every((x) => x === true);
      }
      if (Object.keys(check).length === 0) {
        return false;
      }
      return true;
    }
    return true;
  } catch (err) {
    console.log(err);
    return false;
  }
}

/**
 * Based on current year. Used to set dropdowns that need a list of years.
 * @param {Number} numberOfYears numbers of years to include in array (minus startYear)
 * @param {Bool} includeNextYear Optionally include next year in array
 * @param {Number} startYear numbers of years to exclude in array (eg. if startYear=2 and current year is 2020, 2020 and 2019 will be excluded)
 * @return {Array} List of years as Numbers
 */
export const createYearArray = (
  numberOfYears,
  includeNextYear = false,
  startYear = 0,
) => {
  const years = [];
  const currentYear = new Date().getFullYear();

  if (includeNextYear) {
    years.push(currentYear + 1);
  }

  for (let i = currentYear - startYear; i >= currentYear - numberOfYears; i--) {
    years.push(i);
  }

  return years;
};

/**
 * Creates an array of years from the startYear to the endYear (inclusive for both).
 * Used (as an alternative to createYearArray) to set dropdowns that need a list of years.
 * @param {Number} startYear first year to include in array
 * @param {Number} endYear first year to include in array
 * @return {Array} List of years as Numbers
 */
export const createYearArrayRange = (startYear, endYear) => {
  const years = [];

  for (let i = startYear; i <= endYear; i++) {
    years.push(i);
  }

  return years;
};

/**
 * Formats the given number for displaying to user.
 * ex. 36245.923532 -> 36,245.92
 * This is good for disabled fields that are used purely for display as values can't and won't be changed. But it might not format
 * correctly if used for formatting a number that the user can modify as user might want to enter a large number (>999).
 * It only formats what is displayed. And, then, what is displayed is not a valid number because of the use of commas.
 * You may want to use NumberFormatCustom.js. Search for examples if necessary.
 * Either ways, make sure to remove all the commas before using the number.
 *
 * @param {String|Number} n number to format
 * @param {Number} min minimum number of decimal places
 * @param {Number} max maximum number of decimal places
 * @param {Boolean} cast whether or not to cast result back to a Number
 */
export const numFormat = (n, min = 2, max = 2, cast = false) => {
  try {
    // max = max > 6 ? 6 : max
    const format = Number(n).toLocaleString(undefined, {
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    });
    return cast ? Number(format) : format;
  } catch (err) {
    console.log(`error converting ${n}`);
    return 0;
  }
};

export const dollarFormat = (n) => {
  const dollars = n < 0 ? '-$' : '$';
  try {
    const format = Number(Math.abs(n)).toLocaleString(undefined, {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    });
    return `${dollars}${format}`;
  } catch (err) {
    console.log(`error converting ${n}`);
    return '$0';
  }
};

/**
 * Determines minimum number of decimal places needed greater than 2 to
 * display value greater than 0
 * @param {Number} cost
 * @return {Number} Decimal places to show to get positive value
 */
export const getDecimalPlaces = (num, valueIfZero = 2) => {
  const value = num.toString();

  if (!value.includes('.')) {
    // If cost has no decimal values, return 2 for standard rounding
    return 2;
  }

  // Split cost to get decimal values
  const splitValue = value.split('.');
  const decimals = splitValue[1];

  // If int value of value of first two decimal places is greater than 0
  if (Number(splitValue[0]) > 0 || Number(decimals.substring(0, 2)) > 0) {
    return 2;
  }

  if (decimals.length < 3) {
    return valueIfZero;
  }

  // Number is less than 0.00 so deterine min decimal places to show to get >0
  for (let i = 3; i < decimals.length; i++) {
    if (Number(decimals.substring(0, i)) > 0) {
      return i;
    }
  }

  // If all else fails, return 2 for standard formatting
  return 2;
};

// for low cost, we'll need to change default decimal places shown
export const roundTo = (price) => {
  const splitPrice = price.toString().split('.');
  const decimals = splitPrice[1];
  if (decimals === undefined || Number(splitPrice[0]) > 0) {
    return 2;
  }
  let toShow = 2;
  for (const num of decimals) {
    if (num === '0') {
      toShow += 1;
    } else {
      break;
    }
  }
  return toShow <= 5 ? toShow : 5;
};

export async function getImage(id) {
  try {
    const node = document.getElementById(id);
    const dataUrl = await domtoimage.toPng(node);
    return dataUrl;
  } catch (err) {
    console.log(`problem getting image: ${err}`);
    return '';
  }
}

export function matcher(a, b) {
  const match = a.map((x) => arraysEqual(x, b));
  return match.some((x) => x === true);
}

export function arraysEqual(a, b) {
  /* Check is array a and array b are equal. Start with easy fast checks first. */
  if (a === b) return true;
  if (a == null || b == null) return false;
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; ++i) {
    if (a[i] !== b[i]) return false;
  }
  return true;
}

export const scrollTo = (id, block = 'start') => {
  try {
    const element = document.getElementById(id);
    element.scrollIntoView({ block, behavior: 'smooth' });
  } catch (err) {
    // console.log(err)
  }
};

export const capitalizeFirstLetter = (string) => {
  try {
    return string.charAt(0).toUpperCase() + string.slice(1);
  } catch (e) {
    // breaks on null
    return '';
  }
};

// Milliseconds to wait for
export function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export const formatImageBounds = (profitMapExtents) => {
  // Takes image extents from profitMapResponse and formats in way map needs
  try {
    const bounds = profitMapExtents.split(',');
    const imageBounds = [
      [parseFloat(bounds[3]), parseFloat(bounds[0])],
      [parseFloat(bounds[1]), parseFloat(bounds[2])],
    ];
    return imageBounds;
  } catch (err) {
    console.log(`Problem getting image extent: ${err}`);
    return null;
  }
};

export function cachingDecorator(func, hash) {
  const cache = new Map();
  return function () {
    const key = hash(arguments);
    if (cache.has(key)) {
      // if there's such key in cache read the result from it
      return cache.get(key);
    }

    // otherwise call func
    const result = func.call(this, ...arguments);

    // and cache (remember) the result
    cache.set(key, result);
    return result;
  };
}

function hash() {
  alert([].join.call(arguments)); // 1,2
}

// Remove references that refer to each other, which cause JSON.stringify to break
export const getCircularReplacer = () => {
  const seen = new WeakSet();
  return (key, value) => {
    if (typeof value === 'object' && value !== null) {
      if (seen.has(value)) {
        return;
      }
      seen.add(value);
    }
    return value;
  };
};

// Return true if every value in array is > 0
export const noZeroValues = (arr) => arr.every((x) => Number(x) > 0);

export const validEmail = (email) => {
  if (!exists(email)) {
    return false;
  }
  const reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return email.match(reEmail);
};

export const validPhone = (phoneNumber) => {
  const rePhone = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g;
  return phoneNumber.match(rePhone);
};

/**
 * Pass extention to token would follow.
 * @param {String} extension Check for token after given extension
 * @return {String} Token if found, otherwise empty string
 */
export const checkTokenLink = (extension) => {
  const url = window.location.href;
  const splitUrl = url.toLowerCase().split(extension);
  if (splitUrl.length > 1 && splitUrl[1].length > 1) {
    return splitUrl[1].replace('/', '');
  }
  return '';
};

/**
 * Takes UTC date and formats, with hours converted to local time
 * @param {Object} date Date to format
 * @returns {String} formated date
 */
export function formatTime(date) {
  let hh = date.getHours();
  let m = date.getMinutes();
  let s = date.getSeconds();

  // Offset utc hours by time zone
  const hoursDifference = date.getTimezoneOffset() / 60;
  hh -= hoursDifference;

  let dd = 'AM';
  let h = hh;
  if (h >= 12) {
    h = hh - 12;
    dd = 'PM';
  }
  if (h === 0) {
    h = 12;
  }
  m = m < 10 ? `0${m}` : m;

  s = s < 10 ? `0${s}` : s;

  return `${h}:${m}:${s} ${dd}`;
}

export const formatDate = (date) => {
  // split date on T for time, format month/day/year
  const d = date.split('T')[0].split('-');
  return `${d[1]}/${d[2]}/${d[0]}`;
};

export const formatDateObject = (date) => {
  try {
    // January is 0
    const month = date.getMonth() + 1;
    const day = date.getDate();
    const year = date.getFullYear();

    return `${month}/${day}/${year}`;
  } catch (err) {
    console.error(err);
    return 'Invalid Date';
  }
};

export const checkIsMobile = () => window.matchMedia('only screen and (max-width: 760px)').matches;

export const checkImage = (image) => (image.substring(0, 4) === 'data' ? image : `data:image/jpeg;base64,${image}`);

/**
 * Takes an object and array of strings. Returns object entries who's keys are included
 * in allowedKeys array
 * @param {Object} raw Object to check
 * @param {Array} allowedKeys Acceptable keys
 * @returns {Object} Filtered Object
 */
export const filterObject = (raw, allowedKeys) => Object.keys(raw)
  .filter((key) => allowedKeys.includes(key))
  .reduce((obj, key) => {
    obj[key] = raw[key];
    return obj;
  }, {});

export const convertKseedsToSeeds = (variety) => {
  // Object contains no objects as values, so we can shallow copy
  const converted = { ...variety };
  converted.averageMaterialResult = variety.averageMaterialResult * 1000;
  converted.averageMaterialTarget = variety.averageMaterialTarget * 1000;
  converted.price = variety.price / 1000;
  converted.quantity = variety.quantity * 1000;
  converted.rate = variety.rate * 1000;
  converted.totalMaterial = variety.totalMaterial * 1000;

  // Original unit should be present for all converted seeding ops, but check in case
  converted.unit = variety?.originalUnit !== undefined ? variety.originalUnit : 'seeds';

  return converted;
};

// Takes an array of objects and reduces to an object with the original nested objects
// keys as keys, and original objects values as the new object values.
export const convertArrayToObject = (arr) => {
  const object = arr.reduce(
    (obj, item) => Object.assign(obj, item), {},
  );

  return object;
};

// Safely as JavaScrtipt allows round to 2 decimal places
export const round = (n) => (
  Math.round(((n) + Number.EPSILON) * 100) / 100
);

export const getRandomInt = (max) => Math.floor(Math.random() * Math.floor(max));

/**
 * Given a file and fileName, causes a download to start from the user's browser.
 * 
 * This is an example for creating a file from some string
 * 
 * `dataStr = "data:text/json;charset=utf-8," + fileString;`
 * @param {String} file File path or correctly formatted string containing what to include in file (see example above)
 * @param {String} fileName Name of file to download (INCLUDING extension)
 * @returns {void} undefined 
 */
export const downloadFile = (file, fileName) => {
  const downloadAnchorNode = document.createElement("a");
  downloadAnchorNode.setAttribute("href", file);
  downloadAnchorNode.setAttribute("download", fileName);
  document.body.appendChild(downloadAnchorNode); // required for firefox
  downloadAnchorNode.click();
  downloadAnchorNode.remove();
}

import React, { useEffect, useState } from "react";
import ReactGA from 'react-ga';
import { makeStyles } from "@material-ui/core/styles";
import { Box, Button, Divider, Modal } from "@material-ui/core";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import { CustomToolTip } from "../../../utils/customComponents";
import { blackText, grey, lightGrey } from "../../../styles/colors";

import {
  uploadForAgIntegrated,
  checkAgIntegratedStatuses,
} from "../../../utils/dataFetchers";
import { SpinningLoader } from "../../Shared/SpinningLoader";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  close: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
  },
  modalStyle: {
    position: "absolute",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    zIndex: 1002,
    top: "20%",
    padding: 8,
    width: 370,
  },
  statusStyle: {
    position: "absolute",
    backgroundColor: "#ffffff",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    margin: "auto",
    zIndex: 1002,
    top: "20%",
    padding: 8,
    maxHeight: 700,
  },
  // statusStyle min and max width should be 30 greater than table
  table: {
    display: "flex",
    flexDirection: "column",
    maxHeight: 512,
    width: "800px",
  },
}));

/**
 * Displays a modal that allows the user to upload their zipped ag files or check the status of their uploads.
 * @param {Boolean} uploadFile whether to open modal to upload ag file
 * @param {Function} setUploadFile function to change uploadFile
 * @param {String} orgID organization ID for field
 */
export function UploadAgFile({ uploadFile, setUploadFile, orgID }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = useState(true);
  const [openCheckStatus, setOpenCheckStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const [statusArr, setStatusArr] = useState([]);

  // Only call create table if populateStatusArr has finished running
  useEffect(() => {
    if (statusArr?.length) {
      setOpenCheckStatus(true);
    }
  }, [statusArr]);

  /**
   * Handles calling the flask api to upload and convert the user's files.
   * api returns a conversion message and status.
   * @param {Event} e event created by input of user file
   */
  const handleChange = async (e) => {
    ReactGA.event({
      category: "Feature Usage",
      action: "Upload Precision File"
    })
    // console.log("e.target.files", e.target.files)
    setLoading(true);
    const maxSize = 500;
    let oneValid = false;

    // Attempt to upload every inputted file
    for (const file of e.target.files) {
      const fileName = file.name;
      // console.log('fileName :>> ', fileName);

      if (fileName.includes(".zip")) {
        const sizeInMB = file.size / 1024 ** 2;
        // console.log('sizeInMB :>> ', sizeInMB);
        if (sizeInMB > maxSize) {
          enqueueSnackbar(
            "Your file (" +
              fileName +
              ") is too large. Please upload zip files under " +
              maxSize +
              " MB"
          );
          continue;
        }

        oneValid = true;
        // Attempt to upload the file and call conversion endpoint
        try {
          let formData = new FormData();
          formData.append("fileUpload", file);
          const response = await uploadForAgIntegrated(
            formData,
            orgID,
            fileName
          );

          // Make sure call succeeded
          if (response === undefined) {
            enqueueSnackbar(
              "An error occurred while uploading your file (" + fileName + ")"
            );
            console.log(
              "An error occurred while uploading the file (" +
                fileName +
                ") in UploadAgFile"
            );
          } else {
            // Notify user
            enqueueSnackbar(
              "Your file (" + fileName + ") was successfully uploaded!"
            );
            // console.log('await response.text() :>> ', await response.text());
          }
        } catch (err) {
          enqueueSnackbar("Error");
          console.log(
            "An error occurred while uploading the file (" +
              fileName +
              "): " +
              err
          );
        }
      } else {
        enqueueSnackbar(
          "File (" +
            fileName +
            ") format is incorrect. Please upload a zip file."
        );
      }
    }

    // Only continue to status screen if there was at least one valid entry
    if (!oneValid) {
      setLoading(false);
      // setOpen(false);
      // setUploadFile(false);
    } else {
      // When all uploads have been attempted, bring user to status screen
      populateStatusArr();
    }
  };

  /**
   * Closes the modal and this component used in ProfitLayers.js
   */
  const handleClose = () => {
    setOpen(false);
    setUploadFile(false);
  };

  /**
   * Calls an endpoint to query the database for status information for uploaded files by this organization.
   * @param {Array} statusArr array to fill with status information
   */
  const populateStatusArr = async () => {
    setLoading(true);
    const response = await checkAgIntegratedStatuses(orgID);
    // console.log('response :>> ', response);

    let tempArr = [];

    for (const entry of response) {
      // Extract the information from the response
      const name = entry.native_name ? entry.native_name : "Unavailable";
      const date = new Date(entry.createdAt).toLocaleString();
      const date2 = new Date(entry.updatedAt).toLocaleString();

      // tempArr.push({fileName: name, status: status, date: date})
      tempArr.push({
        fileName: name,
        status: entry.flagdesc,
        createDate: date,
        updateDate: date2,
      });
    }

    // Add true here to statusArr always has length>0 even if tempArr is empty
    setStatusArr([true, tempArr]);
    setLoading(false);
  };

  /**
   * Function for creating the display for user to check the status of their uploaded files.
   * Creates status table from call to endpoint for getting user-org file upload statuses
   * @param {Boolean} noValues whether or not checkAgIntegratedStatuses returned data
   */
  const createStatusTable = (noValues) => (
    <Box
      className={classes.statusStyle}
      borderRadius="borderRadius"
      boxShadow={2}
      color={blackText}
      fontWeight={500}
      top={noValues ? "20%" : ""}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        px={1}
        pt="5px"
        pb="10px"
        style={{ width: "100%" }}
      >
        <Button
          variant="outlined"
          color="primary"
          size="small"
          style={{
            maxWidth: "40px",
            maxHeight: "30px",
            minWidth: "40px",
            minHeight: "30px",
            padding: "5px",
          }}
          onClick={() => setOpenCheckStatus(false)}
        >
          <ArrowBackIcon/>
        </Button>

        <Box fontSize={18}>Check the status of your uploaded files</Box>

        <HighlightOffIcon
          className={classes.close}
          style={{ position: "relative", top: "-5px" }}
          onClick={() => handleClose()}
        />
      </Box>

      {!noValues && (
        <Box
          id="status-table"
          m={1}
          border={1}
          borderColor={grey}
          borderRadius="borderRadius"
          align="center"
          className={classes.table}
        >
          <Box
            p={1}
            display="flex"
            fontSize={18}
            width={statusArr[1].length > 8 ? 780 : 798}
          >
            <Box style={{ width: "20%" }}>Organization ID</Box>
            <Box style={{ width: "25%" }}>File Uploaded</Box>
            <Box style={{ width: "25%" }}>Upload Status</Box>
            <Box style={{ width: "15%" }}>Created At</Box>
            <Box style={{ width: "15%" }}>Updated At</Box>
          </Box>

          <Divider style={{ color: grey, height: 4 }} />
          <Box style={{ overflow: "auto" }}>
            {statusArr[1].map((x, i) => createStatusRow(x, i))}
          </Box>
        </Box>
      )}

      {/* If there are no entries, do not show table */}
      {noValues && (
        <Box m={1}>
          No files have been uploaded from this organization by this user.
        </Box>
      )}
    </Box>
  );

  /**
   * Takes Object from an array and it's index. Displays info about object for table.
   * @param  {Object} x    Entry from array
   * @param  {Number} i    Index
   * @return {JSX}         Table row
   */
  const createStatusRow = (x, i) => (
    <Box
      key={i}
      p={1}
      display="flex"
      alignItems="center"
      style={{ backgroundColor: i % 2 === 0 ? "#ffffff" : lightGrey }}
    >
      <Box style={{ width: "20%" }}>{orgID}</Box>

      <CustomToolTip title={x.fileName} placement="bottom">
        <Box
          overflow="hidden"
          textOverflow="ellipsis"
          whiteSpace="nowrap"
          style={{ width: "25%" }}
        >
          {x.fileName}
        </Box>
      </CustomToolTip>

      <Box style={{ width: "25%" }}>{x.status}</Box>

      <Box style={{ width: "15%" }}>{x.createDate}</Box>

      <Box style={{ width: "15%" }}>{x.updateDate}</Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => handleClose()}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <>
        {!openCheckStatus && (
          <Box
            className={classes.modalStyle}
            borderRadius="borderRadius"
            boxShadow={2}
          >
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ width: "100%" }}
            >
              <HighlightOffIcon
                className={classes.close}
                onClick={() => handleClose()}
              />
            </Box>

            <Box px={1} fontWeight={500} fontSize={16} color={blackText}>
              Upload a zipped file (max. 500MB) to import your precision data.
              You can use this feature whether or not you have already
              integrated your precision ag data from other platforms. We support
              all types of precision data!
            </Box>
            <Box my={1}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                disableElevation
                style={{ marginTop: "8px" }}
              >
                Upload zip File
                <input
                  type="file"
                  style={{ display: "none" }}
                  onChange={(e) => handleChange(e)}
                  multiple
                />
              </Button>
            </Box>

            <Box
              px={1}
              fontWeight={500}
              fontSize={16}
              color={blackText}
              marginTop={3}
            >
              Check the statuses for the processing of your already uploaded
              zipped files.
            </Box>
            <Box my={1}>
              <Button
                variant="contained"
                component="label"
                color="primary"
                disableElevation
                style={{ marginTop: "8px" }}
                onClick={() => populateStatusArr()}
              >
                Check Status
              </Button>
            </Box>
          </Box>
        )}

        {openCheckStatus && createStatusTable(!statusArr[1].length)}

        {loading && <SpinningLoader />}
      </>
    </Modal>
  );
}

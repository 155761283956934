import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as turf from '@turf/turf';
import { blackText, lightGrey, grey } from '../../../styles/colors';
import { numFormat } from '../../../utils/helpers';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  info: {
    marginBottom: 8,
    fontSize: '12px',
    color: theme.palette.greys.dark,
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

/**
 * Creates table to display SSURGO data. Used in Data Layers and Loan Underwriting
 * @param {Number} average NCCPI average
 * @param {Array} legend Soil data legend
 * @param {Number} fieldAcres Field acres
 * @param {Bool} underwriting If called from loan underwriting
 * @param {Number} countyAverage County average value
 * @returns {JSX} SSURGO data table
 */
export const CreateSsurgoTable = ({
  average, legend, fieldAcres, underwriting, countyAverage,
}) => {
  const classes = useStyles();
  return (
    <Box
      m={1}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      display="flex"
      flexDirection="column"
      style={{ overflowX: 'auto', minWidth: 380 }}
    >
      {
          average !== null
          && (
            <Box m={1} display="flex" alignItems="center" justifyContent="center" fontSize={16} fontWeight={500}>
              <span>Average NCCPI</span>
              <CustomToolTip
                title="The National Commodity Crop Productivity Index (NCCPI) model is a national soil interpretation generated in the National Soil Information System (NASIS) environment"
              >
                <InfoOutlinedIcon className={classes.info} />
              </CustomToolTip>
              <span>&nbsp;value:&nbsp;</span>
              {numFormat(average)}
            </Box>
          )
        }
      {
          countyAverage !== null && countyAverage !== undefined
          && (
          <Typography
            align="center"
            style={{
              color: blackText, fontWeight: 500, fontSize: 16, margin: 4,
            }}
          >
            {'County Average NCCPI value: '}
            {numFormat(countyAverage)}
          </Typography>
          )
        }

      <Divider />

      <Box p={1} display="flex" fontSize={16}>
        <Box style={{ width: '50%' }}>
          Soil Name
        </Box>
        <Box pl={1} style={{ width: '15%' }}>
          Acres
        </Box>
        <Box style={{ width: '20%' }}>
          Percent
        </Box>
        <Box style={{ width: '15%' }}>
          NCCPI
        </Box>

      </Box>

      <Divider style={{ color: grey, height: 4 }} />
      { legend.map((x, i) => createRow(x, fieldAcres, i)) }
    </Box>
  );
};

const createRow = (x, fieldAcres, i) => {
  const acres = turf.convertArea(x.area, 'meters', 'acres');
  return (
    <Box
      key={i}
      p={1}
      display="flex"
      style={{ backgroundColor: i % 2 === 0 ? '#ffffff' : lightGrey }}
    >
      <Box style={{ width: '50%' }}>
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon
            style={{ color: x.color, marginRight: 8 }}
          />
          <Box>
            {x.Soil_Type}
          </Box>
        </Box>
      </Box>
      <Box pl={1} style={{ width: '15%' }}>
        {numFormat(acres)}
      </Box>
      <Box style={{ width: '20%' }}>
        { fieldAcres !== undefined && (
          `${numFormat((acres / fieldAcres) * 100)}%`
        )}
      </Box>
      <Box style={{ width: '15%' }}>
        {numFormat(x.nccpi2all)}
      </Box>
    </Box>
  );
};

CreateSsurgoTable.propTypes = {
  average: PropTypes.number,
  legend: PropTypes.array.isRequired,
  fieldAcres: PropTypes.number.isRequired,
  underwriting: PropTypes.bool,
  countyAverage: PropTypes.number,
};

CreateSsurgoTable.defaultProps = {
  average: null,
  underwriting: undefined,
  countyAverage: undefined,
};

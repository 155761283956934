export const displayNames = (key) => {
  const conversions = {
    "Dataset": "Sample Date",
    "Obj__Id": "Zone Number",
    "Sample_ID": "Sample ID",
    "Soil_B_ppm": "B (ppm)",
    "Soil_BpH__": "Soil BpH",
    "SOIL_Bray_": "Bray P1",
    "Soil_CA_pp": "Ca (ppm)",
    "SOIL_Ca__p": "Ca (ppm)",
    "Soil_CEC_m": "CEC (meq/100g)",
    "Soil_K_ppm": "K (ppm)",
    "SOIL_K__pp": "K (ppm)",
    "Soil_MG_pp": "Mg (ppm)",
    "SOIL_Mg__p": "Mg (ppm)",
    "Soil_MN_pp": "Mn (ppm)",
    "Soil_OM___": "OM (%)",
    "SOIL_OM_Li": "OM (%)",
    "Soil_P1_pp": "P1 (ppm)",
    "Soil_ZN_pp": "Zn (ppm)",
    "Soil___CA_": "Percent Ca (%)",
    "Soil___H__": "Percent H (%)",
    "Soil___K__": "Percent K (%)",
    "Soil___MG_": "Percent Mg (%)",
    "Soil_pH__1": "Soil pH",
    "Rec_Sulfur": "Sulfur",
    "SOIL_Limit": "1st Limiting Nutrient",
    "SOIL_Limit1": "1st Limiting Nutrient",
    "SOIL_Limit2": "2nd Limiting Nutrient",
    "SOIL_Limit3": "3rd Limiting Nutrient",
    "S_O_I_L__B": "B (ppm)",
    "S_O_I_L__C": "Percent Ca (%)",
    "S_O_I_L__K": "Percent K (%)",
    "S_O_I_L__M": "Percent Mg (%)",
    "S_O_I_L__M_1": "Mn (ppm)",
    "S_O_I_L__M_2": "Mn (ppm)",
    "S_O_I_L__Z": "Zn (ppm)",
    "S_O_I_L__p": "Soil pH",
    "Soil_CEC_m": "CEC (meq/100g)",
    "Target_Rat": "Target Rate (lb/ac)",
  };

  return conversions[key] !== undefined ? conversions[key] : key;
};
import React from "react";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  varietyLegend: {
    display: "block",
    //flexDirection: "row",
    //justifyContent: "space-around",
    //alignItems: "center",
  },
  legendItem: {
    display: "inline-flex",
    flexDirection: "row",
    alignItems: "center",
    margin: "1px"
  },
  circle: {
    borderRadius: "50%",
    width: "10px",
    height: "10px",
    marginRight: "5px",
  },
}));

const VarietyLegend = ({ varieties }) => {
  const classes = useStyles();

  return (
    <div className={classes.varietyLegend}>
      {varieties.map((variety) => {
        return (
          <div
            key={`variety-item-for-${variety.name}`}
            className={classes.legendItem}
          >
            <div
              className={classes.circle}
              style={{ backgroundColor: variety.color }}
            />
            <div>{variety.name}</div>
          </div>
        );
      })}
    </div>
  );
};

export default VarietyLegend;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
	Container, Box, Typography, Button, Checkbox
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { grey, lightGrey, blackText, green } from "../../styles/colors";

import background from '../../images/tractors.jpg';
import climate from "../../images/climate_clear.png";
import cnh from "../../images/cnh_clear.png";

import { Endpoints } from '../../constants/Endpoints';
import { useWindowDimensions } from "../../utils/dimensions";
import { terms } from "../Partners/Terms";
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		minHeight: 'calc(100vh - 56px)',
		flexGrow: 1,
		backgroundImage: `url(${background})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'noRepeat',
		backgroundSize: 'cover',
		overflowY: 'auto',
	},
	darken: {
		display: 'flex',
		flexGrow: 1,
		width: '100%',
		minHeight: 'calc(100vh - 56px)',
		backgroundColor: 'rgba(0,0,0,.6)',
		backgroundPosition: 'center',
		backgroundRepeat: 'noRepeat',
		backgroundSize: 'cover',
		overflowY: 'auto',
	},
	special: {
		marginTop: 8,
		padding: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
		border: `2px solid ${theme.palette.greys.main}`,
		backgroundColor: theme.palette.greys.light,
		color: theme.palette.text.primary,
	},
  tac: {
    margin: 10,
    padding: 16,
    overflowY: "auto",
    backgroundColor: "white",
    color: theme.palette.text.dark,
    maxWidth: 740,
  },
  checkbox: {
    fontSize: 38,
    color: green,
  },
  connect: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: 16,
		margin: 10,
		fontWeight: 500,
		fontSize: 16,
		color: theme.palette.text.dark,
	},
  logo: {
		height: "80px",
		width: "auto",
		marginBottom: 18,
	},
}));


export const ConnectAccount = ({
	setSection
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { height, width } = useWindowDimensions();

  const [dealer, setDealer] = useState('')
  const [source, setSource] = useState('')
  const [token, setToken] = useState(null)

  const [termsChecked, setTermsChecked] = useState(false);
  const [alert, setAlert] = useState(false);

  useEffect(() => {
    // on render, get url and check foremail,  source, and token
    const url = document.URL.split("?");
    if(url.length > 1){
      const extension = url[1];
      const urlParams = new URLSearchParams(extension);
      const urlDealer = urlParams.get('email')
      const urlSource = urlParams.get('source')
      const urlToken = urlParams.get('integrateToken')
      setDealer(urlDealer ? urlDealer : '')
      setSource(urlSource ? urlSource : '')
      setToken(urlToken ? urlToken : '')
    }
  }, [])

  useEffect(() => {
    if (setSection) {
      setSection(-1);
    }
  }, [setSection]);

  const check = () => {
    setAlert(false);
    setTermsChecked(!termsChecked);
  };

  const connect = () => {
    if (!termsChecked) {
      setAlert(true);
      enqueueSnackbar(
        "Please read and agree to the terms and conditions before integrating"
      );
      const elmnt = document.getElementById("checkbox");
      elmnt.scrollIntoView();
      return;
    }
    if(token === null || token === ''){
      enqueueSnackbar(
        `Looks like there was an issue with your link, please contact ${dealer}.`,
        {autoHideDuration: 10000}
      )
      return;
    }

    const precisionEndpoint = source.toUpperCase() === 'CLIMATE' ? Endpoints.CLIMATEINTEGRATE : Endpoints.CNHINTEGRATE

    const integrateUrl = `${Endpoints.BASEURL}${precisionEndpoint}?integrateToken=${encodeURIComponent(token)}`
    // redirect to integrate
    window.location.assign(integrateUrl)
  }

	return(
		<Box className={classes.root}>
			<Box className={classes.darken}>
				<Container maxWidth="md">
					<Box
						display="flex"
						justifyContent="center"
						flexDirection="column"
						alignItems="center"
					>

						<Box
							my={2}
							borderRadius="borderRadius"
							boxShadow={1}
							width="70%"
						>
							<Box
								px={1}
								display="flex"
								flexDirection="column"
								alignItems="center"
								flexGrow={1}
								style={{ backgroundColor: lightGrey, textAlign: 'center', borderRadius: 10, padding: 20 }}
								color={blackText}
							>
                {/* Only show the connection modal and prompt if token exists */}
                {token ? (
                  <>
                    {/** Info text */}
                    <Box>
                      <Typography variant="h6">
                        You have been invited to connect your {source ? source.toUpperCase() : ''} account with {dealer ? dealer : 'your contact'} on ProfitLayers.
                      </Typography>
                      <Typography variant="body1">
                        Press the Connect Your Account button below to begin the integration process.
                      </ Typography>
                    </Box>

                    {/** Precision Logo */}
                    <Box
                      className={classes.connect}
                      border={1}
                      style={{ backgroundColor: '#ffffff' }}
                      borderColor={green}
                      borderRadius="borderRadius"
                    >
                      <img
                        className={classes.logo}
                        src={source.toUpperCase() === 'CLIMATE' ? climate : cnh}
                        alt={source.toUpperCase() === 'CLIMATE' ? "Connect to Climate" : "Connect to CNH"}
                      />
                    </Box>

                    {/** Connect button */}
                    <Box style={{marginTop: 20, marginBottom: 15}}>
                      <Button 
                        variant="contained" 
                        color="primary"
                        onClick={() => connect()}
                      >
                        Connect Your Account
                      </Button>
                    </Box>

                    {/** Terms checkbox */}
                    <Box
                      mx={1}
                      p={1}
                      display="flex"
                      flexDirection="column"
                      flexGrow={1}
                      borderRadius="borderRadius"
                      style={{ backgroundColor: "#ffffff", maxWidth: 740 }}
                      border={1}
                      borderColor={alert ? "red" : grey}
                    >
                      <Typography variant="subtitle2" align="center">
                        * Please note, availability of operational data varies with each
                        precision ag system.
                      </Typography>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="center"
                        fontWeight={500}
                        fontSize={18}
                        id="checkbox"
                      >
                        <Checkbox
                          checked={termsChecked}
                          icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
                          checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
                          onChange={() => check()}
                        />
                        <Box style={{fontColor: blackText}}>
                          I have read and agree to the Terms of Use & Privacy Policy
                        </Box>
                      </Box>
                    </Box>
                    {/** Terms */}
                    <Box
                      className={classes.tac}
                      id="terms-of-use"
                      border={1}
                      borderColor={grey}
                      borderRadius="borderRadius"
                      style={{ height: 370 }}
                    >
                      {terms()}
                    </Box>
                  </>
                ) : (
                  // {/** Missing info text */}
                  <Box>
                    <Typography variant="h6">
                      You have been invited to connect your account on ProfitLayers but it seems you are missing the verification token. Please check your email for the correct link, contact the sender or contact us.
                    </Typography>
                  </Box>
                )}
							</Box>
						</Box>
					</Box>
				</Container>
			</Box>
		</Box>
	)
}
import React, {
  useState, useEffect, useContext, Fragment, useRef,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Menu,
  MenuItem,
  InputAdornment,
  Tooltip,
  TextField,
  Divider,
  CircularProgress,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import SaveIcon from '@material-ui/icons/Save';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SearchIcon from '@material-ui/icons/Search';
import { exists } from '../../utils/helpers';
import {fetchFarms, getNewGUID, getNewOrgID,
   createNewFarm, createNewOrganization, createNewBoundary,
   addFarmsForFields, createNewField, fetchField, getStateAndCounty,
   fetchClients, createClient, addClientsForFarms, addClientsForFields,
   fetchOrganizations, fetchFields, fetchBoundaries, claimFieldForClient
 } from '../../utils/dataFetchers'
import Terraformer from 'terraformer';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import { useSnackbar } from 'notistack';
import {
  getPoly, getMultiPoly, getPolyFromMulti, convertBoundaryToGeoJson, getCentroid, calculateClimateAcreage
}
  from '../../utils/GeoHelper';
import { UserContext } from '../Context/UserContext';
import { FieldContext } from '../Context/FieldContext';
import { blackText, darkText } from '../../styles/colors';
import { useWindowDimensions } from '../../utils/dimensions';
import { CustomToolTip } from '../../utils/customComponents';
import * as wkt from 'terraformer-wkt-parser';

const WKT = require('terraformer-wkt-parser');

const sectionStyle = {
  padding: '6px 12px',
  color: blackText,
  fontWeight: 500,
};

const useStyles = makeStyles((theme) => ({
  head: { ...theme.modalHead, padding: '20px' },
  mapControls: theme.mapControls,
  details: theme.plDetails,
  buttonBox: theme.buttonBox,
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  close: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoToolTip: theme.infoToolTip,
}));

const loaderStyle = {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center'
}

/**
 *
 * @param {bool} open boolean for modal display
 * @param {function} setOpen change boolean for modal
 * @param {object} field object containing relavent information about field being claimed
 * @param {function} setSection changes the current app section
 * @param {function} setField set the field object to a new object, use after claiming a field
 */
export function FieldClaim({
  open, setOpen, field, setSection, parent, loanID, underwriting
}) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [user, setUser] = useContext(UserContext);
  const [fieldData, setFieldData] = useContext(FieldContext);

  const [organizations, setOrganizations] = useState([])
  const [farms, setFarms] = useState([])
  const [clients, setClients] = useState([])

  const [fieldName, setFieldName] = useState("NewField")
  const [selectedOrg, setSelectedOrg] = useState("")
  const [selectedFarm, setSelectedFarm] = useState("")
  const [selectedClient, setSelectedClient] = useState("")

  const [newOrgName, setNewOrgName] = useState("")
  const [newFarmName, setNewFarmName] = useState("")
  const [newClientName, setNewClientName] = useState("")

  const [newOrg, setNewOrg] = useState(false)
  const [newFarm, setNewFarm] = useState(false)
  const [newClient, setNewClient] = useState(false)

  const [appraisalDate, setAppraisalDate] = useState("")

  // Autocomplete inputs
  const [orgInput, setOrgInput] = useState('')
  const [clientInput, setClientInput] = useState('')
  const [farmInput, setFarmInput] = useState('')

  const [selectedField, setSelectedField] = useState({});

  const [loaded, setLoaded] = useState(false);

  const [processing, setProcessing] = useState(false)

  useEffect(() => {
    // get organizations from UserContext
    if (exists(user.organizations) && !loaded) {
      async function getData() {
        await load();
      }
      getData();
    }
  }, [user]);

  async function load() {
    // console.log("field claim load() function")
    setLoaded(true)
    const orgs = user.allOrgs.map((org) => ({ id: org.ID, name: org.Name }));
    setOrganizations(orgs);

    let selectedOrg = orgs[0]

    if(fieldData.selectedField.orgId !== ''){
      selectedOrg = orgs.filter(x => x.id === fieldData.selectedField.orgId)[0]
    }

    if (selectedOrg){
      setSelectedOrg(selectedOrg)
      setOrgInput(selectedOrg.name)
      let clients = await getClients(selectedOrg.id)
      let farms = await getFarms(selectedOrg.id)
      setClients(clients)
      setFarms(farms)
  
      if(clients.length > 0){
        setSelectedClient(clients[0]);
      }
      if (farms.length > 0) {
        setSelectedFarm(farms[0]);
      }
    }
    
  }

  useEffect(() => {
    if (farms.length === 0) {
      setNewFarm(true);
    } else {
      setNewFarm(false);
    }
  }, [farms]);


  useEffect(()=>{
    if(clients.length === 0){
      setNewClient(true)
    }else{
      setNewClient(false)
      setSelectedClient(clients[0])
    }
  }, [clients])



  useEffect(()=>{
    async function fieldToClaimUpdate(){
      let field = JSON.parse(JSON.stringify(fieldData.fieldToClaim))
      if(field.acres === 0 && field.coordinates.coordinates){
        try{
          let poly = turf.polygon(field.coordinates.coordinates)
          let area = turf.area(poly) / 4046.86;
          let center = turf.center(poly)
          console.log("FIELD CENTER", center)
          field.acres = area
          field.center = center.geometry.coordinates
        }
        catch(err){
          console.log(err)
        }
      }

      setSelectedField(field);

      let orgExist = [], farmsList, fieldFromLoad;

      if(fieldData.fieldToClaim.feature !== null && fieldData.fieldToClaim.feature.properties !== undefined && fieldData.fieldToClaim.feature.properties.Grower !== undefined){
        orgExist = user.organizations.filter(org => org.Name === fieldData.fieldToClaim.feature.properties.Grower)
        if(orgExist.length === 0){
          setNewOrgName(fieldData.fieldToClaim.feature.properties.Grower)
          await createNewOrg(fieldData.fieldToClaim.feature.properties.Grower)
        }
        else{
          setSelectedOrg({id: orgExist[0].ID, name: orgExist[0].Name})
          farmsList = await getFarms(orgExist[0].ID)
        }
      }

      if (
        fieldData.fieldToClaim.feature !== null &&
        fieldData.fieldToClaim.feature.properties !== undefined &&
        fieldData.fieldToClaim.feature.properties.Farm !== undefined
      ) {
        //let farmExist = []
        let farmExist = farmsList.length > 0 ? farmsList.filter(farm => farm.Name === fieldData.fieldToClaim.feature.properties.Farm) : []

        if(farmExist != undefined && farmExist.length === 0){
          setNewFarmName(fieldData.fieldToClaim.feature.properties.Farm)
          createFarm(fieldData.fieldToClaim.feature.properties.Farm, orgExist[0].ID)
        }
        else{
          setSelectedFarm({id: farmExist[0].ID, name: farmExist[0].Name})
          //farms = await getFarms(orgExist[0].ID)
        }
      }

      if (
        fieldData.fieldToClaim.feature !== null &&
        fieldData.fieldToClaim.feature.properties !== undefined &&
        fieldData.fieldToClaim.feature.properties.Field !== undefined
      ) {
        setFieldName(fieldData.fieldToClaim.feature.properties.Field)
      }
    }
    fieldToClaimUpdate()

  }, [fieldData.fieldToClaim]);


  async function getFarms(orgId) {
    const farms = await fetchFarms(orgId, true, null, user.token);
    if (farms !== undefined) {
      return farms.map((farm) => ({ id: farm.FarmID, name: farm.FarmName }));
    }
    return [];
  }

  async function getClients(orgId) {
    // console.log("Token in get clients", user.token)
    const clientsList = await fetchClients(orgId, user.token);
    if (clientsList !== undefined) {
      return clientsList.map((client) => ({ id: client.ClientID, name: client.ClientName }));
    }
    return []
  }

  const handleChange = async (val, source) =>{
    if(source === "org"){
      setFarms([])
      setSelectedFarm('')
      setClients([])
      setSelectedClient('')
      setFarmInput('')
      setClientInput('')
      if(val !== null){
        let orgClients = await getClients(val.id)
        let orgFarms = await getFarms(val.id)
        setFarms(orgFarms)
        setClients(orgClients)
        setSelectedOrg(val)

        // check val exists then get name, otherwise breaks on X click in
        // autocomplete component
        setOrgInput(val?.name)

        if (orgFarms.length > 0) {
          setSelectedFarm(orgFarms[0]);
        }
      }
      else{
        setFarms([])
        setSelectedFarm('')
        setClients([])
        setSelectedOrg(val)

        // check val exists then get name, otherwise breaks on X click in
        // autocomplete component
        setOrgInput(val?.name)
      }
    } else if (source ==='farm') {
      setSelectedFarm(val);
    } else if (source === 'client') {
      setSelectedClient(val)
    }
  };

  const createNewOrg = async (orgName = null) => {
    // call to get new org id then call to create
    const newId = await getNewOrgID('Organizations');
    orgName = orgName === null ? newOrgName : orgName
    if(underwriting){
      await createNewOrganization(newId, orgName, 'Ag-Analytics', 'Loan');
    }
    else{
      await createNewOrganization(newId, orgName, 'Ag-Analytics');
    }
    const newOrg = { id: newId, name: orgName };
    const orgsCopy = [...organizations];
    orgsCopy.push(newOrg);
    orgsCopy.sort(function(a, b) {
      return a.name.localeCompare(b.name);
    });
    setOrganizations(orgsCopy);
    setSelectedOrg(newOrg);
    setOrgInput(newOrg.name)
    setNewOrg(false);

    // clear client and farm data, set to create new
    setClients([]);
    setSelectedClient('');
    //setClientInput('')
    setFarms([]);
    setSelectedFarm('');
    setNewClient(true);
    setNewFarm(true);

    // update users orgs in context from database with new org
    const updatedOrgs = await fetchOrganizations()
    setUser({
      ...user,
      allOrgs: updatedOrgs
    })
  };

  const createNewClient = async () => {
    if(!exists(newClientName)){
      let type = loanID === undefined ? 'client' : 'report'
      enqueueSnackbar("Please enter a valid "+type+ " name")
      return
    }
    let newId = await getNewGUID("Clients")
    await createClient(newId, newClientName, selectedOrg.id, 'Ag-Analytics', user.token)
    let newClient = {id: newId, name: newClientName}
    let clientsCopy = [...clients]
    clientsCopy.push(newClient)
    setClients(clientsCopy)
    setSelectedClient(newClient)
    setNewClient(false)
  }

 const createFarm = async (farmName = null, orgID = null) => {
   //console.log('create farm')
   let newId = await getNewGUID("Farms")
   const name = farmName !== null ? farmName : newFarmName !== '' ? newFarmName : 'Default-Farm'
   let orgid = orgID === null ? selectedOrg.id : orgID
   await createNewFarm(newId, name, orgid, 'Ag-Analytics', user.token)
   let allFarms = await getFarms(orgid)
   let newFarm = {id: newId, name: name}
   let farmsCopy = [...allFarms]
   //farmsCopy.push(newFarm)
   setSelectedFarm(newFarm)
   setFarms(farmsCopy)
   setNewFarm(false)
   return newFarm
 }

  const createField = async () => {
    setProcessing(true)
    if (selectedOrg === '') {
      enqueueSnackbar('Please Select an Organization', {
        variant: 'error',
      });
      setProcessing(false)
      return;
    }

    if(loanID !== undefined){
      if(!exists(selectedClient)){
        enqueueSnackbar('Please Select a Report', {variant: 'error'})
        setProcessing(false)
        return
      }
      if(!exists(selectedFarm)){
        enqueueSnackbar('Please Select a Document', {variant: 'error'})
        setProcessing(false)
        return
      }
    }

    const farmNames = farms.map(x => x.name)
    let farmData = selectedFarm
    if (selectedFarm === '') {
      // if user has not selected a farm, create one
      farmData = await createFarm()
      setSelectedFarm(farmData)
    } else if (!farmNames.includes(newFarmName) && newFarmName !== '') {
      farmData = await createFarm(newFarmName, selectedOrg.id)
      setSelectedFarm(farmData)
    }

    const boundaryId = await getNewGUID('Boundaries');
    const fieldId = await getNewGUID('Fields');

    try {
      const wktShape = WKT.convert(selectedField.geometry);
      let centroid = turf.center(selectedField.geometry)
      const centerPoints = centroid.geometry.coordinates;

      const location = await getStateAndCounty(centerPoints[1], centerPoints[0], true)

      const boundaryReq = {
        Area: selectedField.acres,
        AreaUnit: 'ac',
        BoundaryID: boundaryId,
        BoundaryName: '',
        FieldID: fieldId,
        IsActive: 1,
        OrganizationID: selectedOrg.id,
        Source: 'Ag-Analytics',
        isDeleted: 0,
        Shape: wktShape,
      };

      await createNewBoundary(boundaryReq, user.token);

      const fieldReq = {
        BoundaryID: boundaryId,
        ClientID: exists(selectedClient) ? selectedClient.id : 'noClient',
        FarmID: farmData.id,
        FieldName: fieldName,
        FieldID: fieldId,
        OrganizationID: selectedOrg.id,
        Source: 'Ag-Analytics',
        acres: selectedField.acres,
        boundary: wktShape,
        clu_id: boundaryId,
        isDeleted: 0,
        date_modified: new Date(),
        state: location ? parseInt(location.STATEFP) : null,
        county: location ? parseInt(location.COUNTYFP) : null
      };

      await createNewField(fieldReq, user.token);

      await addFarmsForFields(fieldId, farmData.id, selectedOrg.id, 'Ag-Analytics', user.token)

      if(exists(selectedClient)){
        await addClientsForFarms(selectedClient.id, farmData.id, selectedOrg.id, 'Ag-Analytics', user.token)
        await addClientsForFields(fieldId, selectedClient.id, selectedOrg.id, 'Ag-Analytics', user.token)
      }

      // What is updatedField for?
      const updatedField = { ...selectedField };
      updatedField.farm = farmData.name;
      updatedField.farmId = farmData.id;
      updatedField.id = fieldId;
      updatedField.name = fieldName;
      updatedField.org = selectedOrg.name;
      updatedField.orgId = selectedOrg.id;

      /// send request to /select for field info
      const nField = await fetchField(selectedOrg.id, farmData.id, fieldId, user.token);
      let fieldsList = await fetchFields(selectedOrg.id, farmData.id, user.token)
      fieldsList = fieldsList.map(x => ({...x, name: x.Name, id:x.ID}))
      //console.log(fieldsList)
      const cleanedField = await cleanField(nField[0], fieldName, farmData);

      const resolvedFields = await getFieldData(selectedOrg.id, selectedOrg.name, farmData.id, farmData.name, fieldsList)
      // console.log('resolved fields',resolvedFields)

      

      //if user.token exists then send info to the clientCliam as well
      if(underwriting){
        let poly = JSON.parse(fieldData.fieldToClaim.boundary)
        console.log(fieldData.fieldToClaim)
        let center = turf.center(poly).geometry.coordinates
        // console.log(user.token, selectedOrg.name, farmData.name, fieldName, selectedClient.name, boundaryId, fieldId, center[0], center[1], appraisalDate)
        // return
        claimFieldForClient(user.token, selectedOrg.name, farmData.name, fieldName, selectedClient.name, boundaryId, fieldId, selectedOrg.id, center[1], center[0], appraisalDate)
      }
      else{
        setSection(1); //this will have different effects for pl/dl field claim and loanUnderwriting claim
      }

      cleanedField['boundaryId'] = boundaryId
      
      setFieldData({
        ...fieldData,
        fields: resolvedFields,
        claimedFlag: true,
        selectedField: cleanedField,
        fieldToClaim: {feature: null, coordinates: [], points: [], acres: 0},
        fieldsList: fieldsList
      })

     

    } catch (err) {
      console.log(`Problem creating field ${err}`);
      enqueueSnackbar('This was a problem creating your field. Please try again.');
    }
    setProcessing(false)
    setOpen(false);
  };

  async function getFieldData(orgId, orgName, farmId, farmName, farmsFields = []) {
    // get fields for farm with boundaries
    const data = await fetchBoundaries(orgId, farmId, true, user.token)
    const cleanedFields = []

    if (exists(data)) {
      for (const field of data) {
        // console.log(farmId, farmName)
        // console.log(orgId, selectedOrg.name)
        //console.log(field)
        const cleanedField = await cleanFields(field, orgId, orgName, farmId, farmName, farmsFields)
        cleanedFields.push(cleanedField)
      }
      cleanedFields.sort((a, b) => a.name.localeCompare(b.name))
    }

    return cleanedFields
  }

  const cleanField = async (data, fieldName, farmData) => {
    if (data === undefined) {
      return {
        id: '',
        name: '',
        orgId: '',
        org: '',
        farmId: '',
        farm: '',
        state: '',
        county: '',
        acres: 0,
        latitude: 0,
        longitude: 0,
        coordinates: [{ type: '', coordinates: [] }],
        points: [],
      };
    }

    try {
      // extract and clean coordinates from boundary
      const coordinates = data.Shape[0] === 'P'
        ? { type: 'Polygon', coordinates: getPoly(data.Shape) }
        : { type: 'MultiPolygon', coordinates: getMultiPoly(data.Shape) };

      // get points, previously for centroid. if multi, get from first poly
      // now using turf to get centroid so shouldnt be used anywhere, but leaving for now
      const points = data.Shape[0] === 'P'
        ? getPoly(data.Shape)
        : getPolyFromMulti(data.Shape);
      const geojson = L.GeoJSON.geometryToLayer(WKT.parse(data.Shape)).toGeoJSON();

      const featureCollection = {
        type: 'FeatureCollection',
        features: [geojson],
      };

      const centroid = turf.centroid(featureCollection);
      const centerPoints = centroid.geometry.coordinates;
      const [state, county] = await getStateAndCounty(centerPoints[1], centerPoints[0]);

      // if acres are null (all climate fields) calculate from boundary coordinates
      const acres = data.Area !== null ? data.Area : turf.convertArea(turf.area(featureCollection), 'meters', 'acres');

      const field = {
        acres,
        centroid,
        county,
        coordinates,
        geojson,
        points,
        state,
        id: data.FieldID,
        name: fieldName,
        org: selectedOrg.name,
        orgId: selectedOrg.id,
        farm: farmData.name,
        farmId: farmData.id,
        latitude: centerPoints[1],
        longitude: centerPoints[0],
        boundary: JSON.stringify(geojson),
        shape: data.shape,
      };

      return field;
    } catch (err) {
      console.log(`problem cleaning field ${err}`, data);
      return {
        id: '',
        name: '',
        orgId: '',
        org: '',
        farmId: '',
        farm: '',
        state: '',
        county: '',
        acres: 0,
        latitude: 0,
        longitude: 0,
        coordinates: [{ type: '', coordinates: [] }],
        points: [],
      };
    }
  };

  const cleanFields = async (data, orgId, orgName, farmId, farmName, farmsFields) => {
    //console.log(data)
    if (data === undefined) {
      return {
        id: '',
        name: '',
        orgId: '',
        org: '',
        farmId: '',
        farm: '',
        state: '',
        county: '',
        acres: 0,
        latitude: 0,
        longitude: 0,
        coordinates: [{ type: '', coordinates: [] }],
        points: [],
        source: '',
        boundaryId: ''
      };
    }

    try {
      // extract and clean coordinates from boundary
      const coordinates = data.Shape[0] === 'P'
        ? { type: 'Polygon', coordinates: getPoly(data.Shape) }
        : { type: 'MultiPolygon', coordinates: getMultiPoly(data.Shape) };

      // get points, previously for centroid. if multi, get from first poly
      // now using turf to get centroid so shouldnt be used anywhere, but leaving for now
      const points = data.Shape[0] === 'P'
        ? getPoly(data.Shape)
        : getPolyFromMulti(data.Shape);
      const geojson = L.GeoJSON.geometryToLayer(wkt.parse(data.Shape)).toGeoJSON();

      const featureCollection = {
        type: 'FeatureCollection',
        features: [geojson],
      };

      const centroid = turf.centroid(featureCollection);
      const centerPoints = centroid.geometry.coordinates;
      const [state, county] = await getStateAndCounty(centerPoints[1], centerPoints[0])
      // if acres are null (all climate fields) calculate from boundary coordinates
      const acres = data.Area !== null ? data.Area : turf.convertArea(turf.area(featureCollection), 'meters', 'acres');

      let org = ''

      if (selectedOrg.name !== '') {
        org = selectedOrg.name
      } else {
        const match = organizations.filter((x) => x.id === orgId);
        if (match.length > 0) {
          org = match[0].name
        }
      }

      let name = ''

      const fieldsToMatch = farmsFields

      if (data.FieldName !== undefined) {
        name = data.FieldName
      } else {
        const matchingField = fieldsToMatch.filter(x => x.id === data.FieldID)
        if (matchingField.length > 0) {
          name = matchingField[0].name
        }
      }

      const field = {
        acres,
        centroid,
        county,
        coordinates,
        farmId,
        geojson,
        name,
        orgId,
        org: orgName,
        points,
        state,
        id: data.FieldID,
        farm: farmName,
        latitude: centerPoints[1],
        longitude: centerPoints[0],
        boundary: JSON.stringify(geojson),
        source: data.Source,
        shape: data.Shape,
        boundaryId: data.BoundaryID
      };
      return field;
    } catch (err) {
      console.log(`problem cleaning field ${err}`, data);
      return {
        id: '',
        name: '',
        orgId: '',
        org: '',
        farmId: '',
        farm: '',
        state: '',
        county: '',
        acres: 0,
        latitude: 0,
        longitude: 0,
        coordinates: [{ type: '', coordinates: [] }],
        points: [],
        source: '',
        boundaryId: ''
      };
    }
  };

  return (
    <Box>
      {processing
      ?
        <Box style={loaderStyle}>
          <Box m={2}>
            <CircularProgress/>
          </Box>
          <Box m={1} fontSize={16} fontWeight={500} color={blackText}>
            Your Claim Is Being Processed
          </Box>
        </Box>

      :
      <Box>
        <Box p={1} display="flex" justifyContent="space-between" style={{ width: '100%' }}>
          <Box fontWeight={500} fontSize={18} color={blackText}>
            Claim your field
          </Box>
          <HighlightOffIcon className={classes.close} onClick={() => setOpen(false)} />
        </Box>
        <Divider />
        {newOrg
          ? (
            <Box
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              style={sectionStyle}
            >

              <TextField
                label={underwriting ? 'Loan ID' :'Organization Name'}
                variant='outlined'
                className={classes.input}
                onChange={(e) => setNewOrgName(e.target.value)}
              />
              <Button
                color='primary'
                onClick={() => createNewOrg()}
              >
                <SaveIcon />
                {' Save'}
              </Button>

              <Button
                color='primary'
                onClick={() => setNewOrg(false)}
              >
                <HighlightOffIcon />
              </Button>
            </Box>
          )
          :
          underwriting === undefined
          ?
          (
            <Box
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              style={sectionStyle}
            >
              <Box>
                <Box fontWeight={500}>Select An Organization</Box>
                <Autocomplete
                  className={classes.input}
                  options={organizations}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  onChange={(e, val) => handleChange(val, 'org')}
                  value={selectedOrg}
                  inputValue={orgInput}
                  onInputChange={(event, newInputValue) => {
                    setOrgInput(newInputValue);
                  }}
                />
              </Box>

              <Button
                color="primary"
                style={{height: 40}}
                onClick={() => setNewOrg(true)}
              >
                <AddCircleOutlineOutlinedIcon />
                {' New'}
              </Button>
            </Box>
          )
          :
          (underwriting !== undefined && user.token !== undefined)?
          (
            <Box
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              style={sectionStyle}
            >
              <Box>
                <Box fontWeight={500}>Loan ID</Box>
                <Box className={classes.disabledInput}>{loanID}</Box>
              </Box>

            </Box>
          )
          :
          (
            <Box
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              style={sectionStyle}
            >
              <Box>
                <Box fontWeight={500}>Select A Loan</Box>
                <Autocomplete
                  className={classes.input}
                  options={organizations}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  onChange={(e, val) => handleChange(val, 'org')}
                  value={selectedOrg}
                  inputValue={orgInput}
                  onInputChange={(event, newInputValue) => {
                    setOrgInput(newInputValue);
                  }}
                />
              </Box>

              <Button
                color="primary"
                style={{height: 40}}
                onClick={() => setNewOrg(true)}
              >
                <AddCircleOutlineOutlinedIcon />
                {' New'}
              </Button>
            </Box>
          )
        }

          <Divider />

          {newClient ?
            <Box
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              style={sectionStyle}
            >

              <TextField
                label={underwriting === undefined ? 'Client Name' : 'Report Name'}
                variant='outlined'
                className={classes.input}
                onChange={(e)=>setNewClientName(e.target.value)}
              />
              <Button
                color='primary'
                onClick={()=>createNewClient()}
              >
                <SaveIcon />
                {' Save'}
              </Button>
              

              <Button
                color='primary'
                onClick={() =>setNewClient(false)}
              >
                <HighlightOffIcon />
              </Button>
            </Box>
            :
            <Box
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              style={sectionStyle}
            >
              <Box>
                <Box fontWeight={500}>
                  {underwriting === undefined ? 'Select A Client' : 'Select A Report'}
                </Box>
                <Autocomplete
                  className={classes.input}
                  options={clients}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  onChange={(e, val)=>handleChange(val, "client")}
                  value={selectedClient}
                  inputValue={clientInput}
                  onInputChange={(event, newInputValue) => {
                    setClientInput(newInputValue);
                  }}
                />
              </Box>

              <Button
                style={{height: 40}}
                color="primary"
                onClick={()=>setNewClient(true)}
              >
                <AddCircleOutlineOutlinedIcon />
                {' New'}
              </Button>
            </Box>
          }
          <Divider/>

          {newFarm ?
            <Box
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              style={sectionStyle}
            >

              <TextField
                label={underwriting === undefined ? 'Farm Name' : 'Document ID'}
                variant='outlined'
                className={classes.input}
                onChange={(e) => setNewFarmName(e.target.value)}
              />
              <Button
                color='primary'
                onClick={() => createFarm()}
              >
                <SaveIcon />
                {' Save'}
              </Button>

              <Button
                color='primary'
                onClick={() => setNewFarm(false)}
              >
                <HighlightOffIcon />
              </Button>
            </Box>
          :
            <Box
              display='flex'
              flexWrap='wrap'
              alignItems='center'
              style={sectionStyle}
            >
              <Box>
                <Box fontWeight={500}>
                  {underwriting === undefined ? 'Select A Farm' : 'Select A Document'}
                </Box>
                <Autocomplete
                  className={classes.input}
                  options={farms}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => <TextField {...params} variant="outlined" />}
                  onChange={(e, val) => handleChange(val, 'farm')}
                  value={selectedFarm}
                  inputValue={farmInput}
                  onInputChange={(event, newInputValue) => {
                    setFarmInput(newInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>

              <Button
                color="primary"
                style={{height: 40}}
                onClick={() => setNewFarm(true)}
              >
                <AddCircleOutlineOutlinedIcon />
                {' New'}
              </Button>
            </Box>
          }

        <Divider />

        {underwriting !== undefined && /** Appraisal date is only for field claim if in loan underwriting */
        <> 
          <Box style={sectionStyle}>
            <Box fontWeight={500}>
              Appraisal Date
              <CustomToolTip
                title='Appraisal Date can also be set at a later date on the summary page'
                placement='right'
              >
                <InfoOutlinedIcon className={classes.infoToolTip}/>
              </CustomToolTip>
            </Box>
            <TextField
              className={classes.input}
              variant="outlined"
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              value={appraisalDate}
              onChange={(event) => setAppraisalDate(event.target.value)}
            />
            {/* 
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker
                className={classes.dateSelection}
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                InputAdornmentProps={{ position: "start" }}
                value={appraisalDate}
                onChange={(date, val) => setAppraisalDate(date.toISOString())}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
           */}
          </Box>
          <Divider/>
        </>
        }

        <Box style={sectionStyle}>
          <Box fontWeight={500}>{underwriting ? 'Parcel Name' : 'Field Name'}</Box>
          <TextField
            variant='outlined'
            className={classes.input}
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
          />
        </Box>

        <Divider />

        <Box p={1}>
          <Button
            color="primary"
            variant="contained"
            style={{ margin: '10px' }}
            onClick={() => createField()}
            disableElevation
          >
            Claim Field
          </Button>

          <Button
            color="primary"
            variant="outlined"
            style={{ margin: '10px' }}
            onClick={() => setOpen(false)}
          >
            Cancel
          </Button>
        </Box>

      </Box>
      }

    </Box>
  );
}

import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  row: {
    border: "none",
  },
  cell: {
    border: "none",
    fontSize: 14,
  },
}));

/**
 * Renders the row for the field report table
 * @param {array} headerCells  array of objects with metadata functions/information
 * to access row data for fields
 * @param {object} row  object with row data
 */
const Row = ({ row, headerCells }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      {headerCells.map((headerCell) => {
        const matchingValue = headerCell.accessor(row);
        const unit = headerCell.unitAccessor
          ? headerCell.unitAccessor(row)
          : null;
        return (
          <TableCell
            key={`table-cell-for-${headerCell.id}`}
            className={classes.cell}
          >
            {headerCell.format
              ? headerCell.format(matchingValue)
              : matchingValue}
            {headerCell.unit !== null && typeof headerCell.unit !== "string"
              ? headerCell.unit(row)
              : headerCell.unit !== null
              ? headerCell.unit
              : ""}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
export default Row;

Row.propTypes = {
  row: PropTypes.object.isRequired,
  headerCells: PropTypes.array.isRequired,
};

export const tooltips = {
      'Relative_Elevation': 'Shows elevation relative to the field average. Derived by '+
        'calculating the z- score, or difference from the average, ' +
        'for every point on the field',
      'elevationMap': 'Elevation from the USGS Digital Elevation Model',
      'Elevationmap': 'Elevation from the USGS Digital Elevation Model',
      'Slope': 'Shows the maximum gradient or steepness of the field',
      'TPI': 'Shows the elevation extremes - points of maximum and minimum elevation where '+
             'water might pool or run off',
      'TRI': 'Shows how rough a given area is in terms of local hilliness and steepness of gradients'
};

/* eslint-disable max-len */
import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Container, Button, Divider,
} from '@material-ui/core';
import { UserContext } from '../../Context/UserContext';
import { DisplayOrder } from './DisplayOrder';
import { Endpoints } from '../../../constants/Endpoints';
import profitGif from '../../../images/plgif.gif';
import { GetSoilTesting, GetWaterManagement, Connections } from '../../Shared/ServiceLinks';
import { IntegrateModal } from '../../Shared/IntegrateModal';

const useStyles = makeStyles((theme) => ({
  root: {
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.primary,
  },
  appBarSelections: {
    ...theme.appBarSelections,
    margin: '0 32px',
  },
  buttonLink: theme.buttonLink,
  card: {
    borderRadius: 4,
    border: `solid ${theme.palette.greys.dark} 1px`,
    padding: 16,
    maxWidth: 800,
    color: theme.palette.text.primary,
  },
  link: {
    color: theme.palette.text.primary,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
    },
  },
  messageDisplay: {
    margin: 8,
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
  },
  imageContainer: {
    borderRadius: 4,
    margin: 8,
    paddingBottom: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}));

/**
 * Displays compelted order information from API Response. Includes images and
 * links to DataLayers and ProfitLayers. Also includes button for user to begin
 * a new order. Will retry failed order once automatically and display message
 * to user.
 * @param {Function} startNewOrder Allows user to easily start a new order
 * @param {Object} apiResponse Response after submitting order
 * @param {Number} status Status code from api after submitting order
 * @param {Array} order Order information for resubmit
 * @param {Object} formData User form data for resubmit
 * @param {Function} handleCompletedOrder Pass order response and status code after submit
 * @param {Object} fileData User uploaded files
 * @param {Function} setFileData Sets user uploaded files
 * @returns {JSX} Display order info and links to other tools and services
 */
export const OrderComplete = ({
  startNewOrder,
  order,
  fieldImages,
  cartTotal,
  flightCost,
  nirCost,
  nirAdded,
}) => {
  const classes = useStyles();
  const user = useContext(UserContext)[0];
  const [openIntegration, setOpenIntegration] = useState(false);

  const onConnectClick = () => {
    setOpenIntegration(true);
  };

  const success = () => (
    <Box className={classes.messageDisplay}>
      <Box padding={2}>
        <Typography align="center" variant="h5">
          Thank you for your order!
        </Typography>

        <Box p={1}>
          A soil specialist will be in contact with you shortly to schedule your services.
        </Box>

        <Box className={classes.card}>

          <DisplayOrder
            orderDetails={order}
            fieldImages={fieldImages}
            cartTotal={cartTotal}
            flightCost={flightCost}
            nirCost={nirCost}
            nirAdded={nirAdded}
          />

          <Divider />
          <Box mt={1} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              onClick={() => startNewOrder()}
              disableElevation
            >
              Create Another Order
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const links = () => (
    <Box mt={2} display="flex" flexDirection="column">
      <Box className={classes.imageContainer} boxShadow={1}>
        <a
          target="_blank"
          rel="noreferrer"
          href={`${Endpoints.HOME}/app/ProfitLayers`}
        >
          <img
            src={profitGif}
            style={{ borderRadius: '4px', width: '400px', maxWidth: window.innerWidth - 12 }}
            alt="Profit Layers link"
          />
        </a>

        <Box mt={1}>
          <Button
            variant="outlined"
            color="primary"
            target="_blank"
            href={`${Endpoints.HOME}/app/ProfitLayers`}
          >
            <span className={classes.buttonLink}>Go To ProfitLayers</span>
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const serviceLinks = () => (
    <Box display="flex" justifyContent="center" flexWrap="wrap">
      <Box p={1}>
        <GetWaterManagement />
      </Box>
      <Box p={1}>
        <GetSoilTesting />
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="xl">
      <Box className={classes.root}>
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          <Box display="flex" flexDirection="column">
            {success()}

            {serviceLinks()}
          </Box>

          <Box display="flex" flexDirection="column" alignItems="center">
            {links()}
            <Box mt={1} display="flex">
              <Connections
                onConnect={onConnectClick}
                authenticated={user.isAuthenticated}
              />
            </Box>

          </Box>
        </Box>

      </Box>

      <IntegrateModal
        open={openIntegration}
        setOpen={setOpenIntegration}
      />
    </Container>
  );
};

OrderComplete.propTypes = {
  startNewOrder: PropTypes.func.isRequired,
  order: PropTypes.array.isRequired,
  fieldImages: PropTypes.object.isRequired,
  flightCost: PropTypes.number.isRequired,
  cartTotal: PropTypes.number.isRequired,
  nirCost: PropTypes.number.isRequired,
  nirAdded: PropTypes.bool.isRequired,
};

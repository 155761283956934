import { validEmail } from '../../../utils/helpers';
import { sendSharingInvite } from '../../../utils/dataFetchers';
import { Endpoints } from '../../../constants/Endpoints';

/**
 * Send invite email to grower from agent inputed email address.
 * Check that email is valid before attempting to send.
 * On success or error provide message for display.
 * @param {String} emailAddress Email address to send invite too
 * @returns {String} message to disaply an error or success
 */
export const sendInviteEmail = async (emailAddress) => {
  // validate input email then send invite
  if (!validEmail(emailAddress)) {
    return('Please enter a valid email address');
  }

  const result = await sendSharingInvite(emailAddress);
  if (result !== undefined && result.status === 'Success') {
    return(`An invitation has been sent to ${emailAddress}`);
  } else {
    return(`We were unable to send your invitation to ${emailAddress}. Please try again or generate an invite link.`);
  }
};

export const generateInviteLink = async (currentUser, setInviteLink) => {
  const link = `${Endpoints.BASEURL}${Endpoints.SIGNUPDATASHARE}?dataShare=${currentUser}`;
  setInviteLink(link);
};

/**
 * Copies generated invite link to user's clipboard
 * @param {Object} inviteRef Reference to TextField containing invite link
 * @return {void}
 */
export const copyToClipboard = (inviteRef) => {
  inviteRef.current.select();
  document.execCommand('copy');
  inviteRef.current.setSelectionRange(0, 0);
};

/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable camelcase */
/* eslint-disable no-await-in-loop */
/* eslint-disable no-restricted-syntax */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Chip,
  Modal,
  Button,
  TextField,
  Typography,
  IconButton,
  LinearProgress,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import { useSnackbar } from 'notistack';
import { PDFDownloadLink } from '@react-pdf/renderer';
import { AcreageReportingPDF } from './AcreageReportingPDF';
import { green, textPrimary, textSecondary } from '../../../styles/colors';
import { PdfFieldMap } from '../../Maps/AcreageReporting/PDFMap';
import { sleep, getImage } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
    maxHeight: window.innerHeight - 20,
    maxWidth: window.innerWidth - 20,
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 5,
    right: 5,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  label: {
    fontSize: '.9rem',
    fontWeight: 500,
  },
  getPdf: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  select: {
    margin: '0 10px',
    backgroundColor: '#ffffff',
    width: 400,
  },
}));

/**
 * User can re-name report, enter notes, then start process to create and download PDF of CLU data.
 * Hidden map component is used for gathering images. Multi select autocomplete are available for
 * farm, tract, and clu selection, with a max of 20 CLUs selected a time due to PDF render
 * limitations.
 * @param {Object} commoditiesSeen Data relating to clus user has viewed
 * @param {Object} commodityTypesSeen Data relating to commodityTypes user has viewed
 * @param {Bool} open Open modal
 * @param {Array} operationSummary Summary of Operations for 'GART'
 * @param {Array} pdfGeneratedFor CLU identifiers for which PDF has been generated this session
 * @param {String} printedName User's name, used with digital signature
 * @param {String} reportName Name of report for pdf title
 * @param {Function} setOpen Control modal open state
 * @param {Function} setPdfGeneratedFor Updates CLU identifiers for which PDF has been generated
 * @param {Function} setReportName Update report name
 * @param {String} signature Base64 PNG of digital signature
 * @param {Date} signatureTimeStamp Date object of time of digital signature
 * @param {Object} statesSeen Data relating to states user has viewed
 * @returns {JSX} PDF creation modal
 */
export const CreateGARTPdf = ({
  commoditiesSeen,
  commodityTypesSeen,
  open,
  operationSummary,
  pdfGeneratedFor,
  printedName,
  reportName,
  setOpen,
  setPdfGeneratedFor,
  setReportName,
  signature,
  signatureTimeStamp,
  statesSeen,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [notes, setNotes] = useState('');

  const [pdfLoading, setPdfLoading] = useState(false);
  const [pdfDownloaded, setPdfDownloaded] = useState(false);
  const [fieldData, setFieldData] = useState([]);

  // Populates farm dropdown
  const [farms, setFarms] = useState([]);
  // Farms selected from dropdown
  const [selectedFarms, setSelectedFarms] = useState([]);

  // Stores all fields
  const [fields, setFields] = useState([]);
  // Fields for selected farms, these will populate dropdown
  const [farmFields, setFarmFields] = useState([]);
  // Fields selected from dropdown
  const [selectedFields, setSelectedFields] = useState([]);

  // Control multiselects, needed to auto-close when all options selected
  const [farmSelectionOpen, setFarmSelectionOpen] = useState(false);
  const [fieldSelectionOpen, setFieldSelectionOpen] = useState(false);

  // Boundaries for map
  const [fieldInfo, setFieldInfo] = useState(null);
  const [completeBoundary, setCompleteBoundary] = useState(null);

  const [cluData, setCluData] = useState([]);

  // Remnants from CreateCARTPDF
  // NOTE: Fix getNextFields, then remove these and unnecessary props and should be good for now
  //      Will still need to add field boundary for later
  // Stores all CLUs
  const [clus, setClus] = useState([]);
  // CLUs for slected tracts, these will populate dropdown
  const [tractsClus, setTractsClus] = useState([]);
  // CLUs selected from dropdown
  const [selectedClus, setSelectedClus] = useState([]);

  const [tractSelectionOpen, setTractSelectionOpen] = useState(false);
 
  const [clu, setClu] = useState(null);


  useEffect(() => {
    const farmMaps = {};
    const farmNames = [];
    const fieldNames = [];

    // Build selection options from all fields in operationSummary
    Object.values(operationSummary).forEach((fieldArray) => {
      const fieldInfo = fieldArray[0];
      const farm = {id: fieldInfo.farmID, name: fieldInfo.farmName};
      if (!farmMaps.hasOwnProperty(fieldInfo.farmID)) {
        farmMaps[fieldInfo.farmID] = true;
        farmNames.push(farm);
      }
      fieldNames.push({
        id: fieldInfo.fieldID, name: fieldInfo.fieldName, farm
      });
    });

    setFarms(farmNames);
    setFields(fieldNames);

    // Set selection to first farm by default
    handleFarmChange([farmNames[0]], fieldNames);
  }, [operationSummary]);

  // This is the part of the generation that takes time: getting all the images
  const getFieldImages = async () => {
    try {
      setPdfLoading(true);

      // Store field info with images added
      const fieldsWithImages = [];

      // Get field IDs for those selected in dropdown
      const selectedIdentifiers = selectedFields.map((field) => field.id);
      const summariesForSelectedFields = operationSummary.filter(
        (field) => selectedIdentifiers.includes(field[0].fieldID),
      );

      for (const opInfo of summariesForSelectedFields) {
        const fieldSortedInfo = [...opInfo];
        // Sort so larger operations are drawn first
        fieldSortedInfo.sort((a, b) => b.acres - a.acres);
        // NOTE: Prob want to get the field boundary as well. Would probably be good to show it in Review Page as well
        // const cluBoundary = clusSeen[opInfo[0].clu_identifier].cluShape;

        // Boundary data map needs
        setFieldInfo(fieldSortedInfo);
        // setCompleteBoundary(cluBoundary);

        // We need this here for map to have time to load new boundary
        await sleep(1000);
        const mapImage = await getImage('pdf-map-image');

        fieldSortedInfo[0].image = mapImage;
        fieldsWithImages.push(fieldSortedInfo);
      }
      setFieldData(fieldsWithImages);
      setPdfLoading(false);
      
      const updatedGenerated = [...pdfGeneratedFor, ...selectedIdentifiers];
      setPdfGeneratedFor(updatedGenerated);
      // getNextFields(updatedGenerated);
    } catch (err) {
      console.error(err);
    }
  };

  const getNextFields = (generated) => {
    try {
      const notGeneratedFor = tractsClus.filter((x) => !generated.includes(x.cluIdentifier));
      if (notGeneratedFor.length) {
        // Tract had not yet generated CLUs, so add one
        setSelectedClus([notGeneratedFor[0]]);
      } else {
        // Selected tracts have had all their CLUs generated
        const notGeneratedForAll = clus.filter((x) => !generated.includes(x.cluIdentifier));

        // Add new clus farm and tract, then update selected clu
        handleFarmChange([...selectedFarms, notGeneratedForAll[0].farmNumber], clus);
        handleFieldChange([notGeneratedForAll[0]]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const maxSelected = 20;
  // Select all avaialble farms, up to 20
  const selectAllFarms = () => {
    if (farms.length < maxSelected) {
      handleFarmChange(farms, fields);
    } else {
      handleFarmChange(farms.slice(0, maxSelected), fields);
      enqueueSnackbar(`Your top ${maxSelected} farms have been selected. There is a maximum of ${maxSelected} fields per PDF.`);
    }
  };

  // Select all avaialble fields, up to 20
  const selectAllFields = () => {
    if (farmFields.length <= maxSelected) {
      setSelectedFields(farmFields);
    } else {
      setSelectedFields(farmFields.slice(0, maxSelected));
      enqueueSnackbar(`Your top ${maxSelected} fields have been selected. There is a maximum of ${maxSelected} fields per PDF.`);
    }
  };

  const handleFarmChange = (farmsSelected, allFields) => {
    // console.log('farmsSelected :>> ', farmsSelected);
    setSelectedFarms(farmsSelected);

    // Get fields for all selected farms
    const newFields = allFields.filter((field) => farmsSelected.filter((farm) => farm.id === field.farm.id).length > 0);
    if (newFields.length) {
      // Important to sort for grouping
      newFields.sort((a, b) => a.farm.name - b.farm.name);

      // Only keep the fields that were previously selected that are still in newFields
      let fieldsSelected = selectedFields.filter((field) => newFields.includes(field));
      if (!fieldsSelected.length) {
        fieldsSelected = [newFields[0]];
      }

      setFarmFields(newFields);
      handleFieldChange(fieldsSelected);
    }
    else {
      setFarmFields([]);
      setSelectedFields([]);
    }
  };

  const handleFieldChange = (fieldsSelected) => {
    if (fieldsSelected.length <= maxSelected) {
      setSelectedFields(fieldsSelected);
    } else {
      enqueueSnackbar(`There is a maximum of ${maxSelected} fields per PDF.`);
    }
  };

  const handleDownloadClick = () => {
    // Clear data so user can begin generating fresh pdf
    setFieldData([]);
    setPdfDownloaded(true);

    // Close modal on download or keep open?
    // setOpen(false)
  };

  const downloadReady = () => {
    try {
      const element = document.getElementById('pdf-download');
      if (element) {
        setTimeout(() => { element.click() }, 0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const fieldSelection = () => (
    <Box display="flex" flexDirection="column">
      <Box p={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            Select Farms
          </Box>
          <IconButton
            color="primary"
            onClick={selectAllFarms}
          >
            <DoneAllIcon fontSize="small" />
            <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Select All</span>
          </IconButton>
        </Box>

        <Autocomplete
          className={classes.select}
          multiple
          disableClearable
          disableCloseOnSelect
          filterSelectedOptions
          noOptionsText="All Farms Selected"
          open={farmSelectionOpen}
          onOpen={() => setFarmSelectionOpen(true)}
          onClose={() => setFarmSelectionOpen(false)}
          disabled={pdfLoading}
          options={farms}
          value={selectedFarms}
          getOptionLabel={(option) => `Farm: ${option.name}`}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
            />
          )}
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={option.name}
              {...getTagProps({ index })}
            />
          ))}
          onChange={(event, value) => handleFarmChange(value, fields)}
        />
      </Box>

      <Box p={1}>
        <Box display="flex" alignItems="center" justifyContent="space-between">
          <Box>
            Select Fields
          </Box>
          <IconButton
            color="primary"
            onClick={selectAllFields}
          >
            <DoneAllIcon fontSize="small" />
            <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Select All</span>
          </IconButton>
        </Box>
        <Autocomplete
          className={classes.select}
          multiple
          disableClearable
          disableCloseOnSelect
          filterSelectedOptions
          noOptionsText="All Fields Selected"
          open={fieldSelectionOpen}
          onOpen={() => setFieldSelectionOpen(true)}
          onClose={() => setFieldSelectionOpen(false)}
          options={farmFields}
          disabled={pdfLoading}
          groupBy={(option) => option.farm.name}
          value={selectedFields}
          getOptionLabel={(option) => `Farm: ${option.farm.name} - Field: ${option.name}`}
          renderOption={(option) => (
            <span
              style={{
                color: pdfGeneratedFor.includes(option.id) ? textSecondary : textPrimary,
                fontStyle: pdfGeneratedFor.includes(option.id) ? 'italic' : 'normal',
              }}
            >
              {`Farm: ${option.farm.name} - Field: ${option.name}`}
            </span>
          )}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
            />
          )}
          renderTags={(value, getTagProps) => value.map((option, index) => (
            <Chip
              variant="outlined"
              label={`${option.name}`}
              {...getTagProps({ index })}
            />
          ))}
          onChange={(event, value) => handleFieldChange(value)}
        />
        <Box p={0.5} fontWeight={400} fontSize="0.9rem" maxWidth={400}>
          *Fields included in PDFs generated this session will appear&nbsp;
          <span style={{ color: textSecondary, fontWeight: 500 }}><em>grey italic&nbsp;</em></span>
          in the selection menu.
        </Box>
      </Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        className={classes.paper}
        boxShadow={2}
      >
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpen(false)}
        />

        <Box p={1} borderBottom={1}>
          <Typography align="center" variant="h6">
            Generate PDF
          </Typography>
        </Box>

        <Box display="flex" flexGrow={1}>
          { fieldSelection() }

          <Box p={1} display="flex" alignItems="center" flexDirection="column">
            <Box p={1}>
              <Box className={classes.label}>Report Name</Box>
              <TextField
                variant="outlined"
                value={reportName}
                onChange={(event) => setReportName(event.target.value)}
                disabled={pdfLoading}
                style={{ width: 340, height: 28 }}
                InputLabelProps={{
                  shrink: false,
                }}
                inputProps={{
                  style: {
                    padding: 10,
                  },
                }}
              />
            </Box>
            <Box p={1}>
              <Box className={classes.label}>Notes</Box>
              <TextField
                id="pdf-notes"
                multiline
                variant="outlined"
                rows={8}
                rowsMax={12}
                value={notes}
                onChange={(e) => setNotes(e.target.value)}
                disabled={pdfLoading}
                style={{ width: 340 }}
              />
            </Box>

            {(fieldData.length === 0 && !pdfLoading && selectedFields.length > 0) && (
              <Box mb={1} display="flex" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  size="large"
                  onClick={getFieldImages}
                  disableElevation
                >
                  Generate
                  {pdfDownloaded ? ' Another ' : ' '}
                  PDF
                </Button>
              </Box>
            )}

            { pdfLoading && (
              <Box display="flex" alignItems="center" color={green}>
                <span>Generating Images</span>
                <LinearProgress style={{ height: 6, width: 80, marginLeft: 8 }} />
              </Box>
            )}

            { fieldData.length > 0 && (
              <PDFDownloadLink
                onLoadError={console.error}
                document={(
                  <AcreageReportingPDF
                    commoditiesSeen={commoditiesSeen}
                    commodityTypesSeen={commodityTypesSeen}
                    enqueueSnackbar={enqueueSnackbar}
                    notes={notes}
                    operationSummary={fieldData}
                    printedName={printedName}
                    reportName={reportName}
                    signature={signature}
                    signatureTimeStamp={signatureTimeStamp}
                    statesSeen={statesSeen}
                  />
                )}
                fileName={`${reportName}.pdf`}
                style={{ textDecoration: 'none', color: green }}
              >
                {({ loading }) => (
                  loading
                    ? <Box>Loading PDF...</Box>
                    : (
                      <>
                        <Button
                          id="pdf-download"
                          color="primary"
                          variant="outlined"
                          size="large"
                          onClick={handleDownloadClick}
                        >
                          Download PDF
                        </Button>
                        {downloadReady()}
                      </>
                    )
                )}
              </PDFDownloadLink>
            )}
          </Box>
        </Box>

        {/* { (fieldInfo && completeBoundary) && ( */}
        { fieldInfo && (
          <Box
            style={{
              position: 'relative',
              overflow: 'hidden',
            }}
          >
            <Box
              style={{
                position: 'absolute',
                right: '-5000px',
                top: '5000px',
              }}
            >
              <PdfFieldMap
                fieldInfo={fieldInfo}
                completeBoundary={completeBoundary}
              />
            </Box>
          </Box>
        )}
      </Box>
    </Modal>
  );
};

CreateGARTPdf.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  reportName: PropTypes.string.isRequired,
  setReportName: PropTypes.func.isRequired,
  commoditiesSeen: PropTypes.shape().isRequired,
  commodityTypesSeen: PropTypes.shape().isRequired,
  statesSeen: PropTypes.shape({
    stateAbbr: PropTypes.shape(),
  }).isRequired,
  pdfGeneratedFor: PropTypes.arrayOf(PropTypes.string).isRequired,
  setPdfGeneratedFor: PropTypes.func.isRequired,
  signature: PropTypes.string,
  printedName: PropTypes.string.isRequired,
  signatureTimeStamp: PropTypes.instanceOf(Date),
};

CreateGARTPdf.defaultProps = {
  signature: null,
  signatureTimeStamp: null,
};

/* eslint-disable max-len */
import { submitVendorOrder, uploadOtherFiles } from '../../../utils/dataFetchers';
import { round } from '../../../utils/helpers';

/**
 * Submit order and returns status and response
 * @param {Object} order Order information
 * @param {Object} fieldImages Images with field ids as keys
 * @param {Object} formData User information
 * @param {Object} fileData User uploaded files
 * @param {Number} cartTotal Total for reports
 * @param {Number} flightCost Total for flight
 * @param {Boolean} addNir NIR option selected
 * @param {Number} nirCost Cost of adding NIR
 * @returns {Object} Status and response
 */
export const submit = async (
  order,
  fieldImages,
  formData,
  fileData,
  cartTotal,
  flightCost,
  addNir,
  nirCost,
) => {
  try {
    const { subOrderDetails, totalAcres } = getSubOrderDetails(order, fieldImages);
    const flightCostperAcre = totalAcres > 500 ? 2.4 : totalAcres > 350 ? 2.8 : totalAcres > 200 ? 3 : 3.5;
    let totalCost = cartTotal + flightCost;

    if (addNir) {
      totalCost += nirCost;
    }

    const organizationID = order[0].orgId ? order[0].orgId : '';
    const organizationName = (order[0].org !== '' && order[0].org !== undefined) ? order[0].org : formData.organization;

    let orderID = '';

    try {
      if (fileData != null) {
        const res = await uploadOtherFiles(fileData);
        orderID = res.orderID;
      }
    } catch (err) {
      console.error('Problem uploading files', err);
    }

    const completeOrder = {
      orderDate: String(new Date()),
      orderId: orderID,
      orderName: formData.orderName,
      vendorName: 'Farm Flight',
      ProductName: 'AgAnalyticsUAV',
      ProductType: 'UAV',
      ProductID: '1',
      farmerDetails: {
        fullname: formData.userName,
        email: formData.email,
        phone: formData.phone,
        mailingAddress: formData.address,
        city: formData.city,
        zipCode: formData.zipCode,
        state: formData.state,
      },
      organizationName,
      organizationID,
      isNIR: addNir,
      totalCost,
      totalAcres,
      flightCostperAcre,
      totalFlightCost: flightCost,
      subOrderDetails,
      preferredDate: formData.preferredDate,
      comments: formData.comments,
    };
    // Response from endpoint
    const apiResponse = await submitVendorOrder(completeOrder);

    const { status } = apiResponse;

    // Response JSON for order
    const response = await apiResponse.json();

    return { status, response };
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getSubOrderDetails = (order, fieldImages) => {
  const subOrderDetails = [];

  let totalAcres = 0;
  let totalCost = 0;

  order.forEach((field) => {
    const subTotal = field.costsPerAcre.reduce(
      (accumulator, currentValue) => accumulator + round(currentValue * field.acres), 0,
    );

    const structuredForOrder = {
      subTotal,
      acres: field.acres,
      boundary: field.boundary,
      costPerAcre: field.costsPerAcre,
      county: field.county,
      farm: field?.farm,
      farmId: field?.farmId,
      fieldImage: fieldImages[field.id],
      fieldId: field.id,
      fieldname: field.name,
      latitude: field.latitude,
      longitude: field.longitude,
      reports: field.reports,
      state: field.state,
      surveyQuestions: {},
    };
    
    totalAcres += field.acres;
    totalCost += subTotal;

    subOrderDetails.push(structuredForOrder);
  });

  return { subOrderDetails, totalAcres, totalCost };
};

export const updateOrder = async (order) => {
  try {
    // Response from endpoint
    const apiResponse = await submitVendorOrder(order);
    const { status } = apiResponse;

    // Response JSON for order
    const response = await apiResponse.json();

    return { status, response };
  } catch (err) {
    console.error(err);
  }
};

import React, { useState, useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Container,
  Box,
  Typography,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  StepConnector,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Endpoints } from '../../../constants/Endpoints';
import { IntegrateModal } from '../../Shared/IntegrateModal';
import { UserContext } from '../../Context/UserContext';

import arrow from '../../../images/arrow.png';
import cluImage from '../images/1-CLU.png';
import interfaceImage from '../images/2-Interface.png';
import submitImage from '../images/3-Submit.png';
import downloadImage from '../images/4-Download.png';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    padding: 8,
    fontWeight: 500,
    fontSize: '1.8rem',
    textAlign: 'center',
  },
  connectorHeight: {
    minHeight: 10,
    maxHeight: 50,
  },
  stepLabel: {
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  stepContent: {
    width: 380,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  image: {
    height: 155,
    width: 276,
    border: '2px solid black',
  },
}));

/**
 * Overview page to display some info for user before they start using the tool
 * @param {Boolean} gartPath Denotes whether this interface instance is for creating GART files
 * @param {Boolean} loading Loading state for disabling
 * @param {Function} setAgentPortalOpen Opens the agent portal
 * @returns {JSX} Overview to display info for user
 */
export const Overview = ({
  gartPath,
  loading,
  setAgentPortalOpen,
}) => {
  const classes = useStyles();
  const user = useContext(UserContext)[0];
  const [open, setOpen] = useState(false);

  // Screen pixel with to hide images
  const imageBreakPoint = 1080;

  const signIn = () => {
    window.location.assign(`${Endpoints.BASEURL}${Endpoints.SIGNINFROMACREAGE}`);
  };

  const contact = () => {
    window.open('https://www.profit.ag/#comp-iock1pqk');
  };

  const purchase = () => {
    window.open(`${Endpoints.HOME}/app/purchase`);
  };

  const notLoggedIn = () => (
    <Container className={classes.root} maxWidth="lg">
      <Box className={classes.header}>
        Welcome to PARS&trade;
      </Box>

      <Box p={1} display="flex" justifyContent="center">
        <Typography>
          Use the ProfitLayers
          <sup style={{ fontSize: '60%' }}>&reg;</sup>
          {' '}
          Acreage Reporting platform to save time and energy on Acreage Reporting.
          Quickly create digital acreage reports with greater speed and accuracy by using
          the field boundaries and operations from your precision ag data.
          Review and save the report, then submit the report directly to your AIP or USDA.
          View precision ag operations and completed reports in your account, making reviews
          and audits easy. Get started today!
        </Typography>
      </Box>

      <Box p={1} display="flex" justifyContent="center">
        <Typography align="center" style={{ fontWeight: 500, fontSize: '1.4rem' }}>
          Start Your Digital Acreage Reporting Experience
        </Typography>
      </Box>

      <Box display="flex" flexWrap="wrap" justifyContent="space-around">
        <Box px={2}>
          <Stepper orientation="vertical" style={{ padding: 0 }}>
            <Step active key="connect">
              <StepLabel>
                <Typography className={classes.stepLabel}>
                  Connect Your Precision Ag Data
                </Typography>
              </StepLabel>
              <StepContent>
                <Box display="flex" flexWrap="wrap">
                  <Box px={1} width={400}>
                    <Typography>
                      The system automatically maps your precision operation boundaries to your CLUs
                      and stores your precision ag records for easier organization and management.
                    </Typography>
                  </Box>

                  <Box p={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      disableElevation
                      onClick={() => setOpen(true)}
                      style={{ width: 240 }}
                    >
                      connect precision ag
                    </Button>
                  </Box>

                </Box>
              </StepContent>
            </Step>
            <Step active key="signin">
              <StepLabel>
                <Typography className={classes.stepLabel}>
                  Sign in and Begin Creating Reports
                </Typography>

              </StepLabel>
              <StepContent>
                <Box display="flex" flexWrap="wrap">
                  <Box px={1} width={400}>
                    <Typography>
                      Quickly review and declare planting practices for each CLU,
                      then save and submit the report directly to your AIP or FSA.
                      View and download past reports.
                    </Typography>
                  </Box>

                  <Box p={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      onClick={signIn}
                      disableElevation
                      style={{ width: 240 }}
                    >
                      sign in
                    </Button>
                  </Box>

                </Box>
              </StepContent>
            </Step>
            <Step active key="valueAdd">
              <StepLabel>
                <Typography className={classes.stepLabel}>
                  Access Value-Added Data Services
                </Typography>
              </StepLabel>
              <StepContent>
                <Box display="flex" flexWrap="wrap">
                  <Box px={1} width={400}>
                    <Typography>
                      Purchase a subscription to access other integrated services,
                      including ProfitLayers
                      <sup style={{ fontSize: '60%' }}>&reg;</sup>
                      {' '}
                      in-field profit mapping and DataLayers
                      <sup style={{ fontSize: '60%' }}>&#8482;</sup>
                      {' '}
                      field reports.
                    </Typography>
                  </Box>

                  <Box p={1}>
                    <Button
                      color="primary"
                      variant="contained"
                      size="large"
                      disableElevation
                      style={{ width: 240 }}
                      onClick={purchase}
                    >
                      purchase
                    </Button>
                  </Box>

                </Box>
              </StepContent>
            </Step>
          </Stepper>
        </Box>

        { contactButtons() }
      </Box>

      <IntegrateModal
        open={open}
        setOpen={setOpen}
      />
    </Container>
  );

  /**
   * Overview display for user when logged in (user must be logged in to reach this point so notLoggedIn() isn't used anymore)
   * @returns JSX
   */
  const loggedIn = () => (
    <Box className={classes.root} display="flex">
      <Box display="flex" flexWrap="wrap" flexGrow={1} maxWidth={1500} justifyContent="space-around">
        {/* Start Here Arrow */}
        <Box
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            height: 170,
            margin: "0px 52px",
          }}
        >
          <img src={arrow} alt="arrow" />
          <Box fontWeight={500} fontSize="1.2rem" textAlign="center">
            Start Here!
          </Box>
        </Box>

        {/* Instructions */}
        <Box px={2} margin="auto">
          <Box className={classes.header}>
            Welcome to PARS&trade;
          </Box>
          <Box display="flex" pt={1}>
            <Stepper
              orientation="vertical"
              style={{ padding: 0 }}
              connector={(
                <StepConnector
                  classes={{
                    root: classes.connectorHeight
                    // line: classes.connectorHeight
                  }}
                />
              )}
            >
              <Step active key="step1">
                <StepLabel>
                  <Typography className={classes.stepLabel}>
                    Select your Organization, Farms, and Fields
                  </Typography>
                </StepLabel>
                <StepContent
                  className={classes.stepContent}
                  style={window.innerWidth >= imageBreakPoint ? { height: 90 } : {}}
                >
                  <Typography>
                    The system automatically maps your precision operation boundaries
                    {!gartPath && ' to your FSA CLUs'}, so you don&apos;t have to.
                  </Typography>
                </StepContent>
              </Step>
              <Step active key="step2">
                <StepLabel>
                  <Typography className={classes.stepLabel}>
                    Load and Report Acreage Data
                  </Typography>

                </StepLabel>
                <StepContent
                  className={classes.stepContent}
                  style={window.innerWidth >= imageBreakPoint ? { height: 90 } : {}}
                >
                  <Typography>
                  Quickly report all cropland data and digitally submit your generated report directly to the USDA Clearinghouse, 
                  which automatically distributes it to FSA and your AIPs
                  </Typography>
                </StepContent>
              </Step>
              <Step active key="step3">
                <StepLabel>
                  <Typography className={classes.stepLabel}>
                    Save and Submit Your Report
                  </Typography>
                </StepLabel>
                <StepContent
                  className={classes.stepContent}
                  style={window.innerWidth >= imageBreakPoint ? { height: 90 } : {}}
                >
                  <Typography>
                    Keep verified records of all your plantings in one place,
                    making audits simple and painless.
                  </Typography>
                </StepContent>
              </Step>
              <Step active key="step4">
                <StepLabel>
                  <Typography className={classes.stepLabel}>
                    View and Download Completed Reports
                  </Typography>
                </StepLabel>
                <StepContent
                  className={classes.stepContent}
                  style={
                    window.innerWidth >= imageBreakPoint ? { height: 90, justifyContent: 'flex-start' } : { justifyContent: 'flex-start' }
                  }
                >
                  <Typography>
                    Don&apos;t just submit acreage reports: use your precision data to
                    analyze in-field profits, and make data-driven decisions.
                  </Typography>
                </StepContent>
              </Step>
            </Stepper>

            {window.innerWidth >= imageBreakPoint && (
              <Box pl={1} display="flex" flexDirection="column">
                {/* Images */}
                <Box p="0px 8px 8px">
                  <img src={cluImage} className={classes.image} alt="Precision" />
                </Box>

                <Box p={1}>
                  <img src={interfaceImage} className={classes.image} alt="Interface" />
                </Box>

                <Box p={1}>
                  <img src={submitImage} className={classes.image} alt="Submit" />
                </Box>

                <Box p={1} pb={0}>
                  <img src={downloadImage} className={classes.image} alt="Download" />
                </Box>
              </Box>
            )}
          </Box>
        </Box>

        {/* Contact and Agent Button Section */}
        { contactButtons() }
      </Box>
    </Box>
  );

  /**
   * Handles showing the contact us and agent portal buttons with some associated text.
   * @returns JSX
   */
  const contactButtons = () => (
    <Box
      style={{
        padding: 16,
        margin: "0px 52px",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-evenly",
        maxHeight: 440,
      }}
    >
      {/* Contact Us */}
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box p={1} fontWeight={500}>
          Interested in a Demo?
        </Box>
        
        <Box display="flex" justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            disableElevation
            style={{ width: 200 }}
            onClick={contact}
          >
            contact us
          </Button>
        </Box>
      </Box>

      {/* Agent Portal */}
      <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center">
        <Box p={1} fontWeight={500}>
          Are you an agent?
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            disableElevation
            style={{ width: 200 }}
            onClick={() => setAgentPortalOpen(true)}
            disabled={loading}
          >
            Agent Portal
          </Button>
        </Box>
      </Box>
    </Box>
  );

  return (
    user?.isAuthenticated ? loggedIn() : notLoggedIn()
  );
};

Overview.propTypes = {
  gartPath: PropTypes.bool.isRequired,
  loading: PropTypes.bool.isRequired,
  setAgentPortalOpen: PropTypes.func.isRequired,
};

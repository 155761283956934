import React, { useEffect, useState, useRef } from "react";
import * as L from "leaflet";
import * as turf from "@turf/turf";
import * as _ from "lodash";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "leaflet-path-transform";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "./leaflet.css";
import { exists } from "../../utils/helpers";
import { Tiles } from "../../constants/Tiles";
import "leaflet-easyprint";
import { DOLLAR_FORMAT, COLOR_RANGE, NEGATIVE_COLOR_RANGE, POSITIVE_COLOR_RANGE } from "../../constants"
import { range, mean, sum, extent } from "d3-array";
import { kebabCase } from "lodash";
import { scaleQuantize } from "d3-scale";
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import ListItemText from '@material-ui/core/ListItemText';
import Select from '@material-ui/core/Select';
import Checkbox from '@material-ui/core/Checkbox';
import Chip from '@material-ui/core/Chip';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  chips: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  chip: {
    margin: 2,
  },
  noLabel: {
    marginTop: theme.spacing(3),
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250,
    },
  },
};

export function FarmReportMap({
  boundary,
  fieldBoundaries,
  fields,
}) {
  /*
    Map used simply for image overlays. Example uses are Profit Map and UAV Reports.
    Current version likely retains more functionality than will be necessary.
   */
  //console.log("mapExtents", mapExtents)
  const classes = useStyles();
  const theme = useTheme();

  const [map, setMap] = useState(null);
  const imageOverlay = useRef(null);
  const [newPoint, setNewPoint] = useState([]);
  const [selectedField, setSelectedField] = useState({ feature: null });
  const options = ["Total Expenses", "Total Revenue", "Profits", "Primary Crop",
    "Profitable (%)", "Unprofitable (%)", "Harvest Revenue", "Seeding Expenses",
    "Chemicals Expenses", "Field Passes", "Other Costs", "Other Revenues"]

  const [selectedOptions, setSelectedOptions] = useState(["Total Expenses", "Total Revenue", "Profits"])

  const [headerCells] = useState(
    [
      {
        heading: "Field Name",
        accessor: (d) => d.name,
        format: null,
        helper: "All field names",
        aggregateFunction: null,
        showCarat: true,
        key: "name",
      },
      {
        heading: "Total Expenses",
        accessor: (d) => d.expenses,
        format: DOLLAR_FORMAT,
        helper: "Total Expenses",
        aggregateFunction: sum,
        key: "expenses",
      },
      {
        heading: "Seeding Expenses",
        accessor: (d) => d.seeding,
        format: DOLLAR_FORMAT,
        helper: "Seeding",
        aggregateFunction: sum,
        key: "seeding",
      },
      {
        heading: "Chemicals Expenses",
        accessor: (d) => d.chemicals,
        format: DOLLAR_FORMAT,
        helper: "Chemicals",
        aggregateFunction: sum,
        key: "chemicals",
      },
      {
        heading: "Field Passes",
        accessor: (d) => d.fieldPasses,
        format: DOLLAR_FORMAT,
        helper: "Field Passes",
        aggregateFunction: sum,
        key: "fieldPasses",
      },
      {
        heading: "Other Costs",
        accessor: (d) => d.otherCosts,
        format: DOLLAR_FORMAT,
        helper: "Other Costs",
        aggregateFunction: sum,
        key: "otherCosts",
      },
      {
        heading: "Total Revenue",
        accessor: (d) => d.revenue,
        format: DOLLAR_FORMAT,
        helper: "Total Revenue",
        aggregateFunction: sum,
        key: "revenue",
      },
      {
        heading: "Harvest Revenue",
        accessor: (d) => d.harvest,
        format: DOLLAR_FORMAT,
        helper: "Harvest",
        aggregateFunction: sum,
        key: "harvest",
      },
      {
        heading: "Other Revenues",
        accessor: (d) => d.otherRevenues,
        format: DOLLAR_FORMAT,
        helper: "Other Revenues",
        aggregateFunction: sum,
        key: "otherRevenues",
      },
      {
        heading: "Profits",
        accessor: (d) => d.profit,
        format: DOLLAR_FORMAT,
        helper: "Profits",
        aggregateFunction: sum,
        key: "profit",
      },
      {
        heading: "Profitable (%)",
        accessor: (d) => d.percentProfitable,
        format: null,
        helper: "Profitable Percentage",
        aggregateFunction: null,
        key: "percentProfitable",
      },
      {
        heading: "Unprofitable (%)",
        accessor: (d) => d.unprofitablePercent,
        format: null,
        helper: "Unprofitable Percentage",
        aggregateFunction: null,
        key: "unprofitablePercent",
      },
      {
        heading: "Primary Crop",
        accessor: (d) => d.primaryCrop,
        format: null,
        helper: "Primary crop for field",
        aggregateFunction: null,
        key: "primaryCrop",
      },
      {
        heading: "Include in Detailed Report?",
        accessor: (d) => d.active,
        format: null,
        isCheckBox: true,
        disableSort: true,
        aggregateFunction: null,
        showPrintButton: true,
      },
    ].map((headerCell) => ({
      ...headerCell,
      id: kebabCase(headerCell.heading),
      scale: headerCell.aggregateFunction
        ? scaleQuantize()
          .range(COLOR_RANGE.map((d) => d.backgroundColor))
          .domain(extent(fields, headerCell.accessor))
        : null,
      extentValue: headerCell.aggregateFunction
        ? extent(fields, headerCell.accessor) : null
    }))
  );

  const fieldLayer = L.featureGroup(null);
  const drawnFields = useRef(null);

  const boundaryLayer = useRef(L.geoJSON(null))

  useEffect(() => {
    //console.log("In Display Map");
    drawMap();
  }, []);

  // useEffect(() => {
  //   console.log('fields', fields)
  //   console.log('fieldBoundaries', fieldBoundaries)
  // },[fields, fieldBoundaries])

  useEffect(() => {
    if (map !== null && fields.length > 0) {
      // console.log(map)
      // for (const field of fieldBoundaries) {
      //   drawField(field);
      // }
      drawFields(fieldBoundaries)
    }
  }, [map, fields, selectedOptions])

  async function drawMap() {
    const mapboxTiles = L.tileLayer(Tiles.MAPBOXTOPO);
    const provider = new OpenStreetMapProvider();
    const map = L.map("map", {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    function getGeom() {
      var bounds = map.getBounds();
      var max = bounds.getNorthEast();
      var min = bounds.getSouthWest();

      var geomParam = {
        xmin: min.lng,
        ymin: min.lat,
        xmax: max.lng,
        ymax: max.lat,
        spatialReference: { wkid: 4326 },
      };
      return geomParam;
    }

    setMap(map);
    map.addLayer(fieldLayer);
  }

  /**
   * Draw field boundaries on map and add appropriate functions to thier layer.
   * Convert stored field boundary to geoJson.
   * If selected field, fit map to its bounds.
   * @param  {Object}  field Field data, including boundary and name
   * @param  {Boolean} [snapTo=false] If selected field, snap to its bounds
   */
  const drawField = (field, snapTo = false) => {
    try {
      const features = JSON.parse(field.boundary);

      let option = headerCells.filter(x => x.heading === "Profits")[0]
      let fieldInfo = fields.filter(x => x.id === field.id)
      let matchingValue = 0;
      let color = "#8e24aa";
      let fillColor = "#f3e5f5"; // white default fill color, used for 'hidden' fields
      if (fieldInfo.length > 0) {
        matchingValue = fieldInfo[0].profit
        let finalScale = option.scale;

        if (option.extentValue != null && Math.min(...option.extentValue) < 0 && Math.max(...option.extentValue) == 0) {
          finalScale = scaleQuantize()
            .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(option.extentValue);
        }
        else if (option.extentValue != null && Math.max(...option.extentValue) >= 0 && Math.min(...option.extentValue) == 0) {
          finalScale = scaleQuantize()
            .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(option.extentValue);
        }
        else if (option.extentValue !== null && Math.min(...option.extentValue) < 0 && Math.max(...option.extentValue) >= 0) {
          //console.log("matchingValue", matchingValue)
          if (matchingValue < 0) {
            finalScale = scaleQuantize()
              .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
              .domain([option.extentValue[0], 0]);
          }
          else if (matchingValue >= 0) {
            finalScale = scaleQuantize()
              .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
              .domain([0, option.extentValue[0]]);
          }
        }
        fillColor = finalScale ? finalScale(matchingValue) : fillColor
        //console.log("fillColor", fillColor)
      }
      //console.log('scale', option)
      features.properties.color = fillColor

      const geo = {
        type: "FeatureCollection",
        features: [features],
      };

      drawnFields.current = L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          layer.setStyle({
            color: feature.properties.color,
            fillColor: feature.properties.color,
            opacity: 1,
            fillOpacity: 0.8,
          });
          fieldLayer.addLayer(layer);
          try {
            layer.feature.properties.fieldID = field.id;
          } catch (err) {
            console.log(err);
          }
          // Hoverable info for field

          if (fieldInfo.length > 0) {
            const toolTip = fieldToolTip(fieldInfo[0]);
            layer.bindTooltip(toolTip, { className: "leaftletTooltipClass" });
          }

          fieldLayer.on("mouseover", (e) => {
            e.layer.setStyle({
              color: e.layer.feature.properties.color,
              fillColor: e.layer.feature.properties.color,
              opacity: 1,
              fillOpacity: 1,
            });
          });

          fieldLayer.on("mouseout", (e) => {
            e.layer.setStyle({
              color: e.layer.feature.properties.color,
              fillColor: e.layer.feature.properties.color,
              opacity: 1,
              fillOpacity: 0.8,
            });
          });
        },
      }).addTo(map);

      map.fitBounds(drawnFields.current.getBounds(), { padding: [30, 30] });
    } catch (err) {
      console.log(err);
    }
  };

  const drawFields = (boundaries) => {
    //construct geojson object with all field boundaries
    let geojson={
      type: 'FeatureCollection',
      features: []
    }
    let option = headerCells.filter(x => x.heading === "Profits")[0]

    boundaries.forEach((field) => {
      let feature = JSON.parse(field.boundary)
      let fieldInfo = fields.filter(x => x.id === field.id)
      let matchingValue = 0;
      let fillColor = "#f3e5f5";
      if(fieldInfo.length > 0){
        matchingValue = fieldInfo[0].profit
        let finalScale = option.scale;

        if (option.extentValue != null && Math.min(...option.extentValue) < 0 && Math.max(...option.extentValue) == 0) {
          finalScale = scaleQuantize()
            .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(option.extentValue);
        }
        else if (option.extentValue != null && Math.max(...option.extentValue) >= 0 && Math.min(...option.extentValue) == 0) {
          finalScale = scaleQuantize()
            .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(option.extentValue);
        }
        else if (option.extentValue !== null && Math.min(...option.extentValue) < 0 && Math.max(...option.extentValue) >= 0) {
          //console.log("matchingValue", matchingValue)
          if (matchingValue < 0) {
            finalScale = scaleQuantize()
              .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
              .domain([option.extentValue[0], 0]);
          }
          else if (matchingValue >= 0) {
            finalScale = scaleQuantize()
              .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
              .domain([0, option.extentValue[0]]);
          }
        }
        fillColor = finalScale ? finalScale(matchingValue) : fillColor
        feature.properties.color = fillColor
        feature.properties.fieldID = field.id
        feature.properties.fieldInfo = fieldInfo[0]
        geojson.features.push(feature)
      }
    })

    boundaryLayer.current.clearLayers()

    boundaryLayer.current.addData(geojson)

    console.log(boundaryLayer.current)

    boundaryLayer.current.eachLayer((layer) => {
      let feature = layer.feature
      layer.setStyle({
        color: feature.properties.color,
        fillColor: feature.properties.color,
        opacity: 1,
        fillOpacity: 0.8,
      })

      let tooltip = fieldToolTip(feature.properties.fieldInfo)
      layer.bindTooltip(tooltip, {className: 'leftletTooltopClass'})

      // layer.on("mouseover", (e) => {
      //   e.layer.setStyle({
      //     color: e.layer.feature.properties.color,
      //     fillColor: e.layer.feature.properties.color,
      //     opacity: 1,
      //     fillOpacity: 1,
      //   });
      // });

      // layer.on("mouseout", (e) => {
      //   e.layer.setStyle({
      //     color: e.layer.feature.properties.color,
      //     fillColor: e.layer.feature.properties.color,
      //     opacity: 1,
      //     fillOpacity: 0.8,
      //   });
      // });

    })
    
    if(!map.hasLayer(boundaryLayer.current)){
      boundaryLayer.current.addTo(map)
      map.fitBounds(boundaryLayer.current.getBounds())
    }
  }

  const handleChange = (event) => {
    setSelectedOptions(event.target.value);
  };

  const fieldToolTip = (field) => {
    let info = '';
    //console.log("selectedOptions", selectedOptions)
    for(let opt of selectedOptions){
      //console.log("opt", opt)
      let option = headerCells.filter(x => x.heading === opt)[0]
      //console.log("option", option)
      info += `<div style="margin: 2px 6px;">${opt}: ${typeof(field[option.key]) !== "string" ? field[option.key].toFixed(2) : field[option.key]}</div>`
    }
    return (
      `<div style="height: 30; width: 30;">
        <div style="margin: 2px 6px;">Field: ${field.name}</div>
        <div style="margin: 2px 6px;">Acres: ${field.acres.toFixed(2)}</div>       
        ${info}
      </div>`
    );
  };

  return (
    <div>
      <div>
        <FormControl className={classes.formControl}>
          <InputLabel id="demo-mutiple-checkbox-label">Select Options</InputLabel>
          <Select
            labelId="demo-mutiple-chip-label"
            id="demo-mutiple-chip"
            multiple
            value={selectedOptions}
            onChange={handleChange}
            input={<Input id="select-multiple-chip" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip key={value} label={value} className={classes.chip} />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
          >
            {options.map((opt) => (
              <MenuItem key={opt} value={opt}>
                <Checkbox checked={selectedOptions.indexOf(opt) > -1} />
                <ListItemText primary={opt} />
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
      <div
        id="map"
        style={{
          height: "400px",
          width: "1000px",
        }}
      ></div>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
    Box,
    Typography,
    Divider,
    Link,
    Modal,
    Tooltip,
    CircularProgress,
    TextField,
    Button
  } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import GridOnRoundedIcon from "@material-ui/icons/GridOnRounded";
import rewind from "@mapbox/geojson-rewind";
import "../../../theme"
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import * as df from "../../../utils/dataFetchers";

function getModalStyle() {
    const top = 50;
    const left = 50;
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
      height: 'auto',
      width: '50%'
    };
  }
const useStyles = makeStyles((theme) => ({
  paper: theme.modalPaper,
  head: { ...theme.modalHead, padding: "20px" },
  mapControls: theme.mapControls,
  details: theme.plDetails,
  buttonBox: theme.buttonBox,
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
}));

export function Template({open, setOpen, setCurrentTemplate, setAllTemplates}) {
    const classes = useStyles();
    const [modalStyle] = React.useState(getModalStyle);
    const [scenarios, setScenarios] = useState();
    const [scenariosOptions, setScenariosOptions] = useState();
    const [scenarioID, setScenarioID] = useState();
    const [year, setYear] = useState("2020");
    const [yearDisplay, setYearDisplay] = useState(2020);
    let dataReady = true;

    useEffect(()=>{
      if(open){
        getScenarios();
      }
      //  //TEST DATA:
      //  const data = [{name: "corn following corn", id :1}, {name: "wheat template", id: 1}];
      //  setScenariosOptions(data);
    },[open])

    const handleClose = () =>{
      //console.log("called", open);
      setOpen(false);
    }

    async function getScenarios() {
        let data = await df.getScenarioIDs();
       // console.log("scenarios: ",data);
        setScenarios(JSON.parse(data));
        setAllTemplates(JSON.parse(data));
    }


    const handleChange = (value) =>{
      //console.log("value", value);
      setYear(value);
      setYearDisplay(value);
    }

    const handleTemplateChange = async (value) => {
      //console.log("scenario id", value)
     // console.log('handleTemplateChange', value)
      setScenarioID(value)
      let operations = await df.getTemplateOperations(value)
      //console.log('operations', operations)
      setCurrentTemplate(operations);
      //console.log("template operations", operations)
      handleClose()
    }

  const body = (
    <Box
      style={modalStyle}
      className={classes.paper}
      borderRadius="borderRadius"
    >

      <Box  display="flex " justifyContent='left' className={classes.head} style={{height: "80px"}}>
          <Box>
            <InputLabel> Select the Year to Create a Crop Plan</InputLabel>
            <Select
              value={yearDisplay}
              onChange={(e) => handleChange(e.target.value)}
            >
              <MenuItem value={"2020"}>2021</MenuItem>
              <MenuItem value={"2020"}>2020</MenuItem>
              <MenuItem value={"2019"}>2019</MenuItem>
              <MenuItem value={"2018"}>2018</MenuItem>
              <MenuItem value={"2017"}>2017</MenuItem>
              <MenuItem value={"2016"}>2016</MenuItem>
              <MenuItem value={"2015"}>2015</MenuItem>
              <MenuItem value={"2014"}>2014</MenuItem>
              <MenuItem value={"2013"}>2013</MenuItem>
              <MenuItem value={"2012"}>2012</MenuItem>
              <MenuItem value={"2011"}>2011</MenuItem>
              <MenuItem value={"2010"}>2010</MenuItem>
            </Select>
          </Box>

          <Box>
            <InputLabel>Select Crop Plan Template</InputLabel>
            <Select onChange={(e) => handleTemplateChange(e.target.value)}>
              {scenarios!=undefined && (
            scenarios.map((scenario, i) =>
              <MenuItem key={i} value={scenario.ID}>{scenario.Name}</MenuItem>
            )
            )}
            </Select>
          </Box>
        <Button variant="contained"
          onClick={handleClose}
          >Close</Button>
      </Box>

    </Box>
  );


    return(
      <Modal
      open={false}
      onClose={handleClose}
      aria-labelledby="simple-modal-title"
      aria-describedby="simple-modal-description"
      style={{}}
    >

     {body}
      </Modal>
    )
}

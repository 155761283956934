import React from "react";
import { Box, Radio } from "@material-ui/core";


export function AcreSelection({
  allAcres, 
  setAllAcres,
  disableUserInputs,
  whatIfInputs,
  acres,
  updateWhatIfInputs
}) {

  const handleChange = (event) => {
    const newState = event.target.value === 'yes';
    setAllAcres(newState);
    // If newState, then changing from Per Acre to Total Acres
    if (newState) {
      updateWhatIfInputs('actualRevenue', whatIfInputs.actualRevenue * acres);
    }
    else {
      updateWhatIfInputs('actualRevenue', whatIfInputs.actualRevenue / acres);
    }
  };

  return (
    <Box
      mx={1}
      p={1}
      display='flex'
      flexDirection='column'
      justifyContent='center'
    >
      <Box display="flex" alignItems="center">
        <Radio
          color="primary"
          checked={!allAcres}
          onChange={handleChange}
          value='no'
          name="Per-Acre"
          inputProps={{ 'aria-label': 'Per Acre' }}
          style={{padding: '0 6px 0 0'}}
          disabled={!!disableUserInputs}
        />
        <Box>
          Per Acre
        </Box>
      </Box>

      <Box display="flex" alignItems="center">
        <Radio
          color="primary"
          checked={allAcres}
          onChange={handleChange}
          value='yes'
          name="All-Acres"
          inputProps={{ 'aria-label': 'All Acres' }}
          style={{padding: '0 6px 0 0'}}
          disabled={!!disableUserInputs}
        />
        <Box>
          Total Acres
        </Box>
      </Box>

    </Box>
  )
}

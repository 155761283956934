import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import _ from 'underscore';

import {
  Box,
  Typography,
  TextField,
  Button,
  Modal,
  Container,
  TableContainer,
  Table,
  TableBody,
  TableHead,
  TableCell,
  TableRow,
  Checkbox,
  Divider,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { makeStyles } from '@material-ui/styles';
import { lightGrey, blackText, green } from '../../styles/colors';

import { useWindowDimensions } from '../../utils/dimensions';
import { Endpoints } from '../../constants/Endpoints';
import { updatePreferredOrgs, getNewOrgID, createNewOrganization } from '../../utils/dataFetchers';

import { UserContext } from '../Context/UserContext';
import { Connect } from '../Shared/Connect';
import { SpinningLoader } from '../Shared/SpinningLoader';

const useStyles = makeStyles((theme) => ({
  contentContainer: (props) => ({
    width: props.width,
    height: props.height - 80,
  }),
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 57px)',
    flexGrow: 1,
    overflowY: 'auto',
    backgroundColor: lightGrey,
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    padding: 20,
    alignItems: 'center',
  },
  paper: {
    ...theme.centeredModal,
  },
  close: {
    fontSize: 24,
    // color: theme.palette.primary.main,
    position: 'absolute',
    top: 6,
    right: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  confirmTextbox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 15,
  },
  tableContainer: {
    maxHeight: 600,
    borderLeft: green,
    borderLeftStyle: 'solid',
    borderLeftWidth: 2,
    borderBottom: green,
    borderBottomStyle: 'solid',
    borderBottomWidth: 2,
    borderRight: green,
    borderRightStyle: 'solid',
    borderRightWidth: 2,
  },
  controlsContainer: {
    marginTop: 10,
    display: 'flex',
    justifyContent: 'space-evenly',
    width: '80%',
  },
  input: theme.plInput,
}));

export const OrganizationPreferences = ({
  setSection,
}) => {
  const { height, width } = useWindowDimensions();
  const [user, setUser] = useContext(UserContext);
  const classes = useStyles({ height, width });
  const [orgList, setOrgList] = useState([]);
  const [updating, setUpdating] = useState(false);
  const [createOrg, setCreateOrg] = useState(false);
  const [newOrgName, setNewOrgName] = useState('');
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);

  // Show no highlight in navbar
  useEffect(() => {
    if (setSection) {
      setSection(-1);
    }
  }, [setSection]);

  // Force user to log in if they are not
  useEffect(() => {
    // Exit unless we are sure we retrieved user data - do this to avoid flashing the modal
    if (_.isEmpty(user)) return;
    // console.log('user', user);

    if (!user.isAuthenticated) {
      setLoginPromptOpen(true);
    } else {
      // Make sure to close modal in case a retry has been implemented
      setLoginPromptOpen(false);
    }
  }, [user]);

  useEffect(() => {
    if (user.preferredOrgs && user.allOrgs) {
      const orgFinal = [];
      // loop over all of users orgs
      user.allOrgs.map((org) => {
        const orgCopy = { ...org };
        // search for org in preferred org list
        const preferred = user.preferredOrgs.filter((x) => x.ID === orgCopy.ID);

        // Below code should match code in App.js
        // If org has been added to [UserPreferredOrganizations] table, then check its status there to decide whether it's shown or not. If not, it's always shown
        if (preferred.length) {
          // if in list then use IsPrefered value
          orgCopy.IsPrefered = preferred[0].IsPrefered;
        } else {
          // default to true if not in list
          orgCopy.IsPrefered = true;
        }
        orgFinal.push(orgCopy);
      });
      setOrgList(orgFinal);
    }
  }, [user]);

  /**
   * Update the value of the IsPrefered key in the orglist
   * for the organization at the provided index
   * @param {int} index
   */
  const updateOrg = (index) => {
    const orgs = [...orgList];
    orgs[index].IsPrefered = !orgs[index].IsPrefered;
    setOrgList(orgs);
  };

  /**
   * send the request to the backend to update the list
   */
  const updateOrgs = async () => {
    setUpdating(true);
    const updated = await updatePreferredOrgs({ orgList });
    setUpdating(false);
    // if successful send user back to main page
    if (updated == 'Updated') {
      window.location.assign(`${Endpoints.HOME}/app/ProfitLayers`);
    }
  };

  /**
   * Function to create a new organization and add to the current list
   */
  const createNewOrg = async () => {
    // start loading spinner and close modal
    setUpdating(true);
    setCreateOrg(false);
    // send requests
    const newId = await getNewOrgID('Organizations');
    await createNewOrganization(newId, newOrgName, 'Ag-Analytics');
    // close spinner
    setUpdating(false);
    // create new org obj and insert into list, sort by name
    const newOrg = {
      ID: newId,
      Name: newOrgName,
      source: 'Ag-Analytics',
      IsPrefered: false,
    };
    const orgs = [...orgList];
    orgs.push(newOrg);
    orgs.sort((a, b) => ((a.Name > b.Name) ? 1 : ((b.Name > a.Name) ? -1 : 0)));
    setOrgList(orgs);
    // add org to user lists as well to keep in sync
    const updateUser = { ...user };
    updateUser.allOrgs = orgs;
    setUser(updateUser);
  };

  const OrgCreation = () => (
    <Modal
      open={createOrg}
    >
      <Box className={classes.paper}>
        <Box>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => { setCreateOrg(false); }}
          />
        </Box>
        <Box style={{ padding: 15, paddingTop: 30 }}>
          <Typography variant="h4">
            Create New Organization
          </Typography>

          <Divider />

          <Box
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'baseline',
              paddingTop: 15,
              paddingBottom: 15,
            }}
          >
            <Typography variant="body1">
              Enter New Organization Name
            </Typography>
            <TextField
              variant="outlined"
              className={classes.input}
              style={{ width: '100%' }}
              onChange={(e) => setNewOrgName(e.target.value)}
            />
          </Box>

          <Divider />

          <Box style={{ display: 'flex', justifyContent: 'end' }}>
            <Button
              style={{ margin: 15, marginLeft: 5 }}
              variant="contained"
              color="primary"
              onClick={() => createNewOrg()}
            >
              Submit
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <Box className={classes.root}>
      <Container>
        <Box
          display="flex"
          justifyContent="center"
          flexDirection="column"
          alignItems="center"
        >
          <Box
            my={2}
            borderRadius="borderRadius"
            boxShadow={1}
            width="70%"
          >
            <Box
              px={1}
              display="flex"
              flexDirection="column"
              alignItems="center"
              flexGrow={1}
              color={blackText}
              style={{ paddingTop: 15, paddingBottom: 20, backgroundColor: 'white' }}
            >
              <Box>
                {' '}
                {/** Top text */}
                <Typography variant="body1">
                  Please select which organizations you would like displayed for use in our tools.
                </Typography>
                <Typography variant="body1">
                  When finished hit, "Update & Continue" to proceed.
                </Typography>
              </Box>
              <Box className={classes.controlsContainer}>
                {/** Controls */}
                <Button variant="contained" color="secondary" onClick={() => setCreateOrg(true)}>
                  Create Organization
                </Button>
                <Button variant="contained" color="primary" onClick={updateOrgs}>
                  Update & Continue
                </Button>
              </Box>
              <Box width="85%">
                {/** Org table */}
                <Box
                  style={{
                    backgroundColor: green,
                    padding: 10,
                    marginTop: 15,
                    borderTopLeftRadius: 5,
                    borderTopRightRadius: 5,
                  }}
                >
                  <Typography style={{ color: 'white' }}>
                    Organization Preferences
                  </Typography>
                </Box>
                <TableContainer className={classes.tableContainer}>
                  <Table stickyHeader>
                    <TableHead>
                      <TableRow>
                        <TableCell />
                        {/** blank cell for check  boxs */}
                        <TableCell>
                          Organization ID
                        </TableCell>
                        <TableCell>
                          Name
                        </TableCell>
                        <TableCell>
                          Source
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {orgList.map((org, i) => (
                        <TableRow key={`org_${i}`}>
                          <TableCell key={`check_${i}`}>
                            {/** checkbox cell */}
                            <Checkbox
                              checked={org.IsPrefered}
                              onChange={() => updateOrg(i)}
                            />
                          </TableCell>
                          <TableCell key={`ID_${i}`}>
                            {org.ID}
                          </TableCell>
                          <TableCell key={`Name_${i}`}>
                            {org.Name}
                          </TableCell>
                          <TableCell key={`Source_${i}`}>
                            {org.source}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>

      {/* Modals & stuff */}
      {updating && <SpinningLoader />}
      {createOrg && OrgCreation()}
      <Connect
        open={loginPromptOpen}
        setOpen={setLoginPromptOpen}
        message="Sign in and Connect Precision Ag"
        shouldForceLogIn
        signInFrom="SIGNINFROMAPP"
        signInParam="?source=/OrganizationPreferences"
      />
    </Box>
  );
};

import React from 'react';
import { Box, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.primary,
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    padding: 1,
    minWidth: 240,
    borderRadius: 4,
  },
  close: theme.close,
}))

/**
 * This component is used when screen width is < 701. When this is the case, the tooltips for map navigation
 * do not popup anymore and so the user can click to open a modal to show them through this component.
 * @param {Function} setOpen controls the text that is used to decide whether to keep this open
 * @param {String} text string that is used for display 
 */
export function SelectionInstructions({setOpen, text}) {
  const classes = useStyles();

  // Split the message so it is not all displayed in one paragraph. 
  const display = text.split('.')
  // Remove empty string at the end
  display.pop()

  return (
    <Modal
      open={text !== ''}
      onClose={() => setOpen('')}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="field-selection-instructions"
      aria-describedby="instructions-to-select-field-boundary-on-map"
    >
      <Box className={classes.paper}>
        <HighlightOffIcon
          className={classes.close}
          onClick={() => {setOpen('')}}
        />

        {/* Display the split message */}
        <Box mt={1} p={2}>
          {display.map((x, i) => (
            <Box key={i} mt={.5}>
              {`${x}.`}
            </Box>
          ))}
        </Box>
      </Box>
    </Modal>
  )
}

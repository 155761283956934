import React, { useEffect, useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Tabs,
  Tab,
  Divider,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import { exists } from '../../../utils/helpers'
import {
  lightGrey,
  green,
  blackText,
  darkText,
  grey,
} from '../../../styles/colors'

import { GetWaterManagement } from '../../Shared/ServiceLinks'


const useStyles = makeStyles(theme => ({
  forecasts: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
  },
  historical: {
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: green,
    fontWeight: 500,
    height: 40,
    minWidth: 260,
    '&:hover': {
        cursor: 'pointer',
        backgroundColor: '#fafafa',
        color: theme.palette.primary.dark,
    },
  },
  viewOther: {
    color: green,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.light,
    },
  },
  errorMessageBox: theme.errorMessageBox,
}))


/**
 * [Weather description]
 * @param {Object} loading Determines if loading
 * @param {Function} setSelectedLayer To move to historical weather tab
 * @param {Array} hourly Current days hourly forecast
 * @param {Array} weekly Weekly forecast
 * @param {Bool} weatherFail Encountered error getting weather
 * @param {String} errorMessage Message to display to user if do not have data
 */
export function Weather({
  loading,
  setSelectedLayer,
  hourly,
  weekly,
  weatherFail,
  errorMessage,
}) {
  const classes = useStyles();

  const [day, setDay] = useState(0)
  const [forecasts, setForecasts] = useState([])
  const [loaded, setLoaded] = useState(false);

  useEffect(() => {
    if (exists(hourly)) {
      const today = hourly.filter(x => x.startTime.split('T')[0] === hourly[0].startTime.split('T')[0])
      const tomorrow = hourly.filter(x => x.startTime.split('T')[0] === today[today.length - 1].endTime.split('T')[0])
      const dayThree = hourly.filter(x => x.startTime.split('T')[0] === tomorrow[tomorrow.length - 1].endTime.split('T')[0])
      const dayFour = hourly.filter(x => x.startTime.split('T')[0] === dayThree[dayThree.length - 1].endTime.split('T')[0])
      setForecasts([today, tomorrow, dayThree, dayFour])
    }
  }, [hourly])

  const handleDayChange = (event, value) => {
    setDay(value)
  }

  const showLoader = () => (
    <Box
      m={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      height={100}
    >
      <Box
        my={1}
        color={blackText}
        fontWeight={500}
        fontSize={16}
      >
        Loading Current Weather
      </Box>
      <CircularProgress size={60}/>
    </Box>
  )

  const displayForecast = (forecast, i, weekly = false) => (
    <Box
      key={i}
      m={1}
      p={1}
      display='flex'
      flexDirection='column'
      alignItems='center'
      border={1}
      borderRadius={16}
      borderColor={grey}
      fontWeight={500}
      color={blackText}
      fontSize={20}
      style={{width: 200}}
    >

      <Box m={2} fontSize={18}>
        Current Weather
      </Box>

      <Box p={1}>
        { weekly ? forecast.name : getTime(forecast.startTime) }
      </Box>

      <img
        src={forecast.icon}
        style={{width: '120px', borderRadius: '10%'}}
        alt='forecast'
      />

      <Box style={{padding: 4}}>
        {`${forecast.temperature}° ${forecast.temperatureUnit}`}
      </Box>

      <Box style={{padding: 4}}>
        {`${forecast.windSpeed} ${forecast.windDirection}`}
      </Box>

      <Box style={{padding: 4}}>
        {forecast.shortForecast}
      </Box>
    </Box>
  )

  const getTime = (time) => {
    const hour = time.split('T')[1].split(':')[0]
    return hour === '00' ? '12AM ' : hour < 10 ? `${hour.substring(1)} AM` : hour < 12 ? hour + ' AM' : hour === '12' ? '12 PM' : (hour - 12) + ' PM'
  }

  const currentConditions = () => {

    return (
      <Box
        display='flex'
        justifyContent='center'
        alignItems='center'
        style={{ width: '100%'}}
      >
        <Box
          display='flex'
          flexDirection='column'
          justifyContent='center'
          alignItems='center'
          fontWeight={500}
          color={blackText}
          borderRadius={16}
          border={2}
          borderColor={blackText}
          fontSize={20}
          style={{width: 240}}
        >
          <Box mt={1} fontSize={24}>
            Current Conditions
          </Box>

          <img
            src={hourly[0].icon}
            style={{width: '140px', borderRadius: '10%'}}
            alt='forecast'
          />

          <Box display='flex'>
            <Box p={1}>
              {hourly[0].temperature}°
              {hourly[0].temperatureUnit}
            </Box>
            <Box p={1}>
              {hourly[0].shortForecast}
            </Box>
          </Box>

        </Box>
      </Box>
    )
  }

  return (
    loading ? (
      showLoader()
    ) : (
      <Fade in={true}>
        <Box style={{width: '100%', height: '100%'}} pt={1}>

          <Box
            display='flex'
            justifyContent='space-around'
            alignItems='center'
            flexWrap='wrap'
            style={{width: '100%'}}
          >
            { errorMessage !== '' && (
              <Box m={1} display='flex'>
                <Box className={classes.errorMessageBox}>
                  {errorMessage}
                </Box>
              </Box>
            )}

            <Box p={1}>
              <GetWaterManagement />
            </Box>

            <Box px={1}>
              { exists(hourly[0]) && currentConditions() }

              { (weatherFail && !loading) && (
                <Box
                  p={1}
                  fontWeight={500}
                  fontSize={16}
                  color={blackText}
                  style={{marginTop: 20}}
                >
                  <Box>
                    We encountered a problem getting your weather forecast.
                  </Box>
                  <Box>
                    {'Please '}
                    <span
                      className={classes.viewOther}
                      onClick={() => setSelectedLayer(2)}
                    >
                      view our other tools
                    </span>
                    {' and check back later.'}
                  </Box>
                </Box>
              )}

            </Box>

            <Box>
              <Box
                className={classes.historical}
                border={1}
                borderRadius="borderRadius"
                onClick={() => setSelectedLayer(8)}
                style={{margin: '16px'}}
              >
                View Historical Precipitation Data
              </Box>

              <Box
                className={classes.historical}
                border={1}
                borderRadius="borderRadius"
                onClick={() => setSelectedLayer(7)}
                style={{margin: '16px'}}
              >
                View Historical Growing Degree Days
              </Box>
            </Box>

          </Box>

          {
            exists(forecasts) && (
              <Box>
                <Box
                  mt={1}
                  display='flex'
                  justifyContent='center'
                  borderTop={1}
                  borderColor={grey}
                >
                  <Tabs
                    value={day}
                    indicatorColor="primary"
                    textColor="primary"
                    onChange={handleDayChange}
                    variant='scrollable'
                  >
                    <Tab label="Today" />
                    <Tab label="Tomorrow" />
                    {exists(forecasts[2][0]) && <Tab label={new Date(forecasts[2][0].startTime).toDateString()} />}
                    {exists(forecasts[3][0]) && <Tab label={new Date(forecasts[3][0].startTime).toDateString()} />}
                    <Tab label="Week" />
                  </Tabs>
                </Box>

                <Divider style={{width: '100%', height: 1, color: grey}}/>

                <Box className={classes.forecasts}>
                  {
                    day < 4
                    ? forecasts[day].map((forecast, i) => displayForecast(forecast, i))
                    : weekly.map((forecast, i) => displayForecast(forecast, i, true)
                  )}
                </Box>
              </Box>
            )
          }
        </Box>
      </Fade>
    )

  )
}

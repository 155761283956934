import * as fr from "../../../utils/farmReportDataFetchers";
import { exists } from "../../../utils/helpers";
import { getChemicalPrice } from "../../../utils/dataFetchers";
import { formatDate } from "./helper";

export async function getApplicationForFarm(orgId, farmId, year) {
  try {
    let applicationOps = await fr.getFarmOperations(
      orgId,
      farmId,
      year,
      "Application"
    );
    //console.log('application Ops', applicationOps)

    let map = await getApplicationMapData(applicationOps, year);
    return map;
  } catch (err) {
    console.log("err", err);
  }
}

export const getApplicationMapData = async (
  applicationOps,
  year,
  setMessage
) => {
  try {
    //console.log("applicationOps", applicationOps, year);
    const applications = await cleanApplication(
      applicationOps.data,
      year,
      setMessage
    );
    //console.log("Cleaned applications >> ", applications);
    const singleApplications = applications.filter((x) => x.tankMix === 0);
    //console.log("singleApplications", singleApplications);
    const mixed = applications.filter((x) => x.tankMix !== 0);

    const tankMixes = combineApplications(mixed);
    //console.log("Combined Applications", tankMixes);

    const applicationOperations = [...singleApplications, ...tankMixes];
    //console.log("applicationOperations", applicationOperations);

    const map = formatAplicationOnFieldId(applicationOperations);
    //console.log('app map', map)
    return map;
  } catch (err) {}
};

const cleanApplication = async (ops, year, setMessage) => {
  /*  If application has no price, get price from getChemicalPrice */
  const applications = [];
  let chemPriceMap =  new Map();

  for (const op of ops) {
    if (op.price === null || op.price.toString() === '0') {
      //console.log("application price", op, op.price)
      if (exists(op.componentName) && exists(op.summaryTotalAppliedUnit)) {
        if (op.componentName.toUpperCase() !== "WATER") {
          if(chemPriceMap.has(op.componentName + "-" + op.summaryTotalAppliedUnit)){
            op.price = chemPriceMap.get(op.componentName + "-" + op.summaryTotalAppliedUnit);
          }
          else{
            setMessage("Fetching chemical price...");
            const price = await getChemicalPrice(
              op.componentName,
              op.summaryTotalAppliedUnit
            );
            op.price = price === undefined ? 0 : price;
            chemPriceMap.set(op.componentName + "-" + op.summaryTotalAppliedUnit, op.price)
          }
          
        } else {
          // Set water price to 0 if null
          op.price = 0;
        }
      } else {
        // alternatively handle all null, may mess with tank mixes though?
        op.isDeleted = true;
      }
    }

    // Don't miss this step - create the object we need for displaying
    // console.log("Formatting App: ",op)
    const application = formatApplication(op, year);
    applications.push(application);
  }
  return applications;
};

const formatApplication = (op, year) => ({
  ...op,
  applicationComponents: [
    {
      ...op,
      area: op.summaryArea === undefined ? op.fieldAcres : op.summaryArea,
      productName: op.componentName,
      quantity:
        op.componentTotalApplied === null ||
        op.componentTotalApplied === undefined
          ? op.fieldAcres * op.summaryApplicationRate
          : op.componentTotalApplied,
      unit:
        op.componentTotalAppliedUnit === null
          ? op.summaryTotalAppliedUnit
          : op.componentTotalAppliedUnit,
    },
  ],
  area: op.summaryArea === undefined ? op.fieldAcres : op.summaryArea,
  averageMaterialResult: op.summaryApplicationRate,
  attribute: "AppliedRate",
  cellsize: "0.0001",
  date: formatDate(op.applicationDate),
  fileType: "APPLICATION",
  isIrrigated: null,
  isOrganic: null,
  operationCost: op.price * op.summaryApplicationRate,
  operationID: op.operationID,
  price: op.price,
  productName: op.applicationName,
  quantity:
    op.componentTotalApplied === null || op.componentTotalApplied === undefined
      ? op.fieldAcres * op.summaryApplicationRate
      : op.componentTotalApplied,
  unit:
    op.componentTotalAppliedUnit === null
      ? op.summaryTotalAppliedUnit
      : op.componentTotalAppliedUnit,
  variety: null,
  tankMix: op.isTankMix,
  year:
    op.applicationYear === undefined
      ? new Date(op.applicationDate).getFullYear()
      : op.applicationYear,
});

function combineApplications(aps) {
  /* Combine tank mixes */
  const mixed = []; // ids of mixes found
  const applicationOperations = [];
  // console.log('aps :>> ', aps);
  aps.forEach((ap) => {
    //check if this mix has already been found
    if (!mixed.includes(ap.operationID)) {
      mixed.push(ap.operationID);
      const tank = aps.filter((x) => x.operationID === ap.operationID);
      // console.log('tank :>> ', tank);
      const applicationComponents = tank.map((x) => x.applicationComponents[0]);

      const names = applicationComponents.map((x) => x.productName);
      // console.log('names :>> ', names);

      // sum cost of applications by multipling each price and quanity, then dividing by area
      const opCost = applicationComponents
        .map(
          (x) =>
            (parseFloat(x.price) * parseFloat(x.quantity)) / parseFloat(x.area)
        )
        .reduce((a, b) => a + b, 0);

      // sum cost of applications divided by averageMaterialResult
      const applicationPrice = applicationComponents
        .map(
          (x) =>
            (parseFloat(x.price) * parseFloat(x.quantity)) /
            parseFloat(x.area) /
            parseFloat(x.averageMaterialResult)
        )
        .reduce((a, b) => a + b, 0);

      const application = {
        ...ap,
        applicationComponents,
        area: ap.area,
        attribute: ap.attribute,
        averageMaterialResult: ap.averageMaterialResult,
        cellsize: ap.cellsize,
        date: ap.date,
        fileType: ap.fileType,
        id: ap.operationID,
        isDeleted: ap.isDeleted,
        isIrrigated: ap.isIrrigated,
        isOrganic: ap.isOrganic,
        logID: ap.logID,
        operationCost: Math.round(opCost * 100) / 100,
        operationID: ap.operationID,
        price: parseFloat(applicationPrice.toFixed(2)),
        productName: names.join(", "),
        quantity: ap.quantity,
        unit: ap.unit,
        variety: ap.variety,
        year: ap.year,
        startDate: ap.startDate,
        isIncluded: ap.isIncluded,
      };

      applicationOperations.push(application);
    }
  });

  return applicationOperations;
}

function formatAplicationOnFieldId(ops) {
  let map = new Map();

  for (let op of ops) {
    if (!map.get(op.fieldID)) map.set(op.fieldID, []);
    let data = map.get(op.fieldID);
    //console.log(data)
    data.push(op);
    map.set(op.fieldID, data);
  }

  return map;
}

import React, { useState, useEffect } from "react";
import ReactExport from "react-data-export";
import { Button } from "@material-ui/core";
const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;

export function ExcelExportSummary({ selectedFields }) {
  console.log("ExcelExportSummary");
  const [data, setData] = useState([
    {
      value: "",
    },
    {
      value: 0,
    },
    {
      value: 0,
    },
    {
      value: 0,
    },
    {
      value: 0,
    },
    {
      value: 0,
    },
    {
      value: 0,
    },
    {
      value: 0,
    },
    {
      value: 0,
    },
    {
      value: 0,
    },
    {
      value: "",
    },
  ]);

  useEffect(() => {
    let formatData = [];

    for (let f of selectedFields) {
      let rowData = [];
      rowData.push(
        {
          value: f.name,
        },
        {
          value: +f.acres,
        },
        {
          value: +f.harvest.toFixed(2),
        },
        {
          value: +f.seeding.toFixed(2),
        },
        {
          value: +f.chemicals.toFixed(2),
        },
        {
          value: +f.fieldPasses.toFixed(2),
        },
        {
          value: +f.otherCosts.toFixed(2),
        },
        {
          value: +f.otherRevenues.toFixed(2),
        },
        {
          value: +f.expenses.toFixed(2),
        },
        {
          value: +f.revenue.toFixed(2),
        },
        {
          value: +f.profit.toFixed(2),
        },
        {
          value: f.primaryCrop,
        }
      );
      formatData.push(rowData);
    }
    //console.log("formatData", formatData)
    setData(formatData);
  }, [selectedFields]);

  const multiDataSet = [
    {
      columns: [
        { title: "Field Name", width: { wpx: 80 } }, //pixels width
        { title: "Acres", width: { wpx: 80 } },
        { title: "Harvest Revenue ($/Acre)", width: { wpx: 80 } }, //char width
        { title: "Seeding Cost ($/Acre)", width: { wpx: 80 } },
        { title: "Chemical Cost ($/Acre)", width: { wpx: 80 } },
        { title: "Field Passes Cost ($/Acre)", width: { wpx: 80 } },
        { title: "Other Cost ($/Acre)", width: { wpx: 80 } },
        { title: "Other Revenue ($/Acre)", width: { wpx: 80 } },
        { title: "Total Expense ($/Acre)", width: { wpx: 80 } },
        { title: "Total Revenue ($/Acre)", width: { wpx: 80 } },
        { title: "Profit ($/Acre)", width: { wpx: 80 } },
        { title: "Primary Crop", width: { wpx: 80 } },
      ],
      data: data,
    },
  ];

  //console.log("multiDataSet", multiDataSet)

  return (
    <div>
      <ExcelFile element={<a>Print Summary Report (Excel)</a>}>
        <ExcelSheet dataSet={multiDataSet} name="Farm Summary" />
      </ExcelFile>
    </div>
  );
}

import React, {useEffect, useState} from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

/**
 * Precip chart
 * @param {OBject} data data to display
 * @param {Number} startYear start year of data
 * @param {Number} endYear end year of data
 * @returns {JSX} Precip chart
 */
export function PrecipChart({data, startYear, endYear}) {
  const [currentData, setCurrentData] = useState([]);
  const [averageData, setAverageData] = useState([]);
  const [minData, setMinData] = useState([]);
  const [maxData, setMaxData] = useState([]);

  const [weeks, setWeeks] = useState([])

  useEffect(() => {
    if (data !== null && data !== undefined && data.length > 0) {
      const curData = data[0].CumPrecipitation.map(val => Math.round(val['Accumulated Precipitation'] * 1000) / 1000.0)
      const sorted = curData.sort((a,b) => a - b)
      const aveData = data[1].AvePrecipitation.map(val => Math.round(val['Accumulated Precipitation'] * 1000) / 1000.0)
      const min = data[2].MinPrecipitation.map(val => Math.round(val['Accumulated Precipitation'] * 1000) / 1000.0)
      const max = data[3].MaxPrecipitation.map(val => Math.round(val['Accumulated Precipitation'] * 1000) / 1000.0)

      const dataWeeks = []

      for (let i = 0; i < aveData.length; ++i) {
        dataWeeks.push(moment(data[1].AvePrecipitation[i].Date, "MM-DD").format('DD-MMM'));
      }
      setWeeks(dataWeeks)

      setCurrentData(sorted)
      setAverageData(aveData)
      setMinData(min)
      setMaxData(max)
    }
  }, [data])

  const options = {
    chart: {
        type: 'line',
        backgroundColor: null,
        zoomType: 'x'
    },
    title: {
        text: 'Precipitation Data'
    },
    xAxis: {
        categories: weeks
    },
    yAxis: {
        title: {
            text: 'Accumalated Precipitation (mm)'
        }
    },
    series: [
      {
        name: endYear + ' Precipitation',
        data: currentData,
        color: '#B1DD27',
        zIndex: 4,
        lineWidth: 4,
        states: {
          inactive: {
              opacity: 1
          }
        }
      },
      {
        name: (endYear - startYear) + ' Year Ave',
        data: averageData,
        color: '#808080',
        zIndex: 3,
        lineWidth: 1,
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      {
        name: 'Historic Min',
        data: minData,
        color: '#B22222',
        zIndex: 2,
        lineWidth: 1,
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      {
        name: 'Historic Max',
        data: maxData,
        color: '#00B2EE',
        zIndex: 1,
        lineWidth: 1,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    ],
    navigation: {
      menuItemStyle: {
        borderLeft: '10px solid #FFFFFF',
        fontSize: '15px',
        padding: "2px 10px"
      }
    }
  }

  return (
    <div id='precip-chart'>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}

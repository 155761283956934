import { useState, useCallback, useLayoutEffect } from "react";
import { debounce } from "lodash";
import { randomNormal } from "d3-random";
import { forceCollide, forceSimulation, forceX, forceY } from "d3-force";

export const randomX = randomNormal(185, 25);
export const randomY = randomNormal(17, 5);

export const disaggregatePoints = (
  varieties,
  fields,
  nameAccessor,
  xAccessor,
  yAccessor,
  year,
  cropName
) => {
  return varieties.map((variety) => {
    return fields
      .map((field) =>
        field.varieties
          .filter((d) => d.name === nameAccessor(variety))
          .map((d) => ({ ...d, fieldName: nameAccessor(field) }))
      )
      .flat()
      .map((field) => {
        return {
          x: xAccessor(field),
          y: yAccessor(field),
          name: field.fieldName,
          year: year(field),
          variety: nameAccessor(variety),
          cropName: cropName(field),
          color: variety.color,
          county: field.county,
        };
      });
  });
};

export const calculateLayout = (items, spacing = 0.01) => {
  // Calculate a force directed placement for each point
  const MAX_STEPS = 300,
    STRENGTH = 10,
    ALPHA = 0.3;

  if (!items.length) return [];

  const getY = (d) => d.y;
  const getX = (d) => d.x;
  const getCollision = (d) => d.r + spacing;
  const sim = forceSimulation(items)
    .force("collide", forceCollide(getCollision))
    .force("x", forceX(getX))
    .force("y", forceY(getY).strength(STRENGTH))
    .alpha(ALPHA)
    .stop();

  const upperBound = Math.ceil(
    Math.log(sim.alphaMin()) / Math.log(1 - sim.alphaDecay())
  );

  for (let i = 0; i < Math.min(MAX_STEPS, upperBound); ++i) sim.tick();

  return items;
};

function getDimensionObject(node) {
  const rect = node.getBoundingClientRect();

  return {
    width: rect.width,
    height: rect.height,
    top: "x" in rect ? rect.x : rect.top,
    left: "y" in rect ? rect.y : rect.left,
    x: "x" in rect ? rect.x : rect.left,
    y: "y" in rect ? rect.y : rect.top,
    right: rect.right,
    bottom: rect.bottom,
  };
}

export const useDimensions = (initialDimensions) => {
  const [dimensions, setDimensions] = useState(initialDimensions);
  const [node, setNode] = useState(null);

  const ref = useCallback((node) => {
    setNode(node);
  }, []);

  useLayoutEffect(() => {
    if (node) {
      const measure = debounce(
        () =>
          window.requestAnimationFrame(() =>
            setDimensions({ ...getDimensionObject(node), isResized: true })
          ),
        250
      );
      measure();

      window.addEventListener("resize", measure);

      return () => {
        window.removeEventListener("resize", measure);
      };
    }
  }, [node]);

  return [ref, dimensions, node];
};

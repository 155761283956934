/* eslint-disable camelcase */
import * as turf from '@turf/turf';
import { addCluBoundary, getStateAndCounty } from '../../../utils/dataFetchers';
import { alternateFieldNames } from '../presetData';

const WKT = require('terraformer-wkt-parser');
const shapefile = require('shapefile');
const JSZip = require('jszip');

/**
 * Handle uploading of zipped shape files and GeoJSON
 * Used in AcreageReporting/Modals/CLUUpload.js
 * Similar functions used in AcreageReporting/helpers.js (should be reusing these...)
 * @param {Object} e Event from file input
 * @param {Function} setMessage Sets message to display on failure or success
 * @param {String} impersonating User whose data Agent is using
 * @param {Object} selectedOrg Organization to associate uploaded CLU information
 * @returns {Bool} True is successful, False if error encountered
 */
export const parseAndUpload = async (e, setMessage, impersonating, selectedOrg) => {
  let validCLUs;
  const fileobj = e.target.files[0];

  // Parse through zip file
  if (fileobj.name.includes('.zip')) {
    const reader = new FileReader();
    reader.readAsArrayBuffer(fileobj);

    reader.onload = function (e) {
      const zip = new JSZip(reader.result);
      try {
        const shpString = zip.file(/.shp$/i)[0].name;
        const dbfString = zip.file(/.dbf$/i)[0].name;
        const shp = zip.file(shpString).asArrayBuffer();
        const dbf = zip.file(dbfString).asArrayBuffer();

        const valid = loadShpZip(fileobj, shp, dbf, setMessage);
        if (valid) validCLUs = valid;
        else return false;
      } catch (err) {
        setMessage("Couldn't find .shp or .dbf file!");
        return false;
      }
    };
  }
  // Parse through json file
  else if (fileobj.name.includes('.json') || fileobj.name.includes('.geojson')) {
    const jsonString = await fileobj.text();
    const jsonObj = JSON.parse(jsonString);
    // Parse geojson into obj to send to backend
    let clu_reqs = parseGeoJSON(jsonObj);
    clu_reqs = await getMissingStateCounty(clu_reqs);

    // Filter out CLUS with missing data
    const [invalid, valid] = filterInvalid(clu_reqs);
    // console.log("VALID CLUS", valid)
    if (valid.length === 0) {
      setMessage('No valid CLU(s) found in the Uploaded file');
      return false;
    }
    else if (invalid.length > 0 && valid.length > 0) {
      const ratio = `${invalid.length}/${clu_reqs.length}`;
      setMessage(`Could not upload ${ratio} CLUS from the file due to invalid data`);
    }
    validCLUs = valid;
  }

  // Attempt to save CLU info to DB
  try {
    const OrgID = selectedOrg ? selectedOrg.id : -1;
    const res = await addCluBoundary({ clus: validCLUs, ownerEmail: impersonating, OrgID });
    if (res.status === 'Success') {
      setMessage(res.msg);
      return true;
    }
    else {
      setMessage('Failed to add CLU boundary. Please try again.');
      return false;
    }
  } catch (err) {
    console.error('Error inserting CLU boundary: ', err);
    setMessage('Failed to add CLU boundary. Please try again.');
    return false;
  }
};

const loadShpZip = async (file1, shpFile1, dbfFile1, setMessage) => {
  const multiGeojsons = [];
  const shapes = [];

  if (file1 !== null) {
    shapefile.open(shpFile1, dbfFile1, null)
    .then((source) => source.read()
      .then(function log(result) {
        if (result.done) return;

        if (result.value.geometry.type !== 'Point' && multiGeojsons.length <= 20) {
          multiGeojsons.push(result.value);
          shapes.push(result.value);
          return source.read().then(log);
        }
        if (result.value.geometry.type === 'Point') {
          setMessage('Cannot process point shape file, try with a different file');
          return false;
        }
      })
    ).then(async (data) => {
      let clu_reqs = [];
      for (let i = 0; i < shapes.length; i++) {
        const req_obj = {
          shape: WKT.convert(shapes[i].geometry),
          clu_identifier: getPropertyValue(shapes[i].properties, 'clu_identifier'),
          clu_number: getPropertyValue(shapes[i].properties, 'clu_number'),
          tract_number: getPropertyValue(shapes[i].properties, 'tract_number'), // should not be null
          farm_number: getPropertyValue(shapes[i].properties, 'farm_number'), // should not be null
          clu_classification_code: getPropertyValue(shapes[i].properties, 'clu_classification'),
          clu_caculated_acreage: getPropertyValue(shapes[i].properties, 'clu_caculated_acreage'),
          highly_erodible_land_type_code: getPropertyValue(shapes[i].properties, 'highly_erodible_land_type_code'),
          comments: getPropertyValue(shapes[i].properties, 'comments'),
          state_code: getPropertyValue(shapes[i].properties, 'state_code'),
          county_code: getPropertyValue(shapes[i].properties, 'county_code'),
          data_source_site_identifier: getPropertyValue(shapes[i].properties, 'data_source_site_identifier', 'number'),
          data_source: getPropertyValue(shapes[i].properties, 'data_source'),
          admin_state: getPropertyValue(shapes[i].properties, 'admin_state'), // should not be null
          admin_county: getPropertyValue(shapes[i].properties, 'admin_county'), // should not be null
          cropland_indicator_3CM: getPropertyValue(shapes[i].properties, 'cropland_indicator_3CM'),
          sap_crp: getPropertyValue(shapes[i].properties, 'sap_crp'),
          clu_status: getPropertyValue(shapes[i].properties, 'clu_status'),
          cdist_fips: getPropertyValue(shapes[i].properties, 'cdist_fips'),
          edit_reason: getPropertyValue(shapes[i].properties, 'edit_reason'),
          clu_alt_id: getPropertyValue(shapes[i].properties, 'clu_alt_id'),
          last_chg_user_nm: getPropertyValue(shapes[i].properties, 'last_chg_user_nm'),
          CIMSFILE: getPropertyValue(shapes[i].properties, 'CIMSFILE'),
          CIMS_LOC_STATE: getPropertyValue(shapes[i].properties, 'CIMS_LOC_STATE'),
          CIMS_LOC_COUNTY: getPropertyValue(shapes[i].properties, 'CIMS_LOC_COUNTY'),
          Source: 'USERENTERED',
          OrganizationID: null,
          FieldID: null,
          last_change_date: getPropertyValue(shapes[i].properties, 'last_change_date'),
          geojson: shapes[i],
        };
        clu_reqs.push(req_obj);
      }

      // Add state and county if missing at this point
      clu_reqs = await getMissingStateCounty(clu_reqs);

      // Filter out CLUS with missing data
      const [invalid, valid] = filterInvalid(clu_reqs);
      if (valid.length === 0) {
        setMessage('No valid CLU(s) found in the Uploaded file');
        return false;
      }
      else if (invalid.length > 0 && valid.length > 0) {
        const ratio = `${invalid.length}/${clu_reqs.length}`;
        setMessage(`Could not upload ${ratio} CLUS from the file due to invalid data`);
      }

      return valid;
    })
    .catch((error) => console.error(error.stack));
  }
};

const filterInvalid = (clus) => {
  const invalid = [];
  const valid = [];
  for (let i = 0; i < clus.length; i++) {
    const currentCLU = clus[i];
    // Delete any invalid keys if present
    delete currentCLU.shared_id;
    delete currentCLU.OrganizationID;

    if (
      (currentCLU.clu_identifier === null || currentCLU.clu_identifier === undefined)
      || (currentCLU.clu_number === null || currentCLU.clu_number === undefined)
      || (currentCLU.tract_number === null || currentCLU.tract_number === undefined)
      || (currentCLU.farm_number === null || currentCLU.farm_number === undefined)
      || (currentCLU.admin_state === null || currentCLU.admin_state === undefined)
      || (currentCLU.admin_county === null || currentCLU.admin_county === undefined)
    ) {
      invalid.push(currentCLU);
    } else {
      valid.push(currentCLU);
    }
  }
  return [invalid, valid];
};

const getPropertyValue = (propertiesObj, key, type = null) => {
  try {
    if (propertiesObj[key]) {
      return propertiesObj[key];
    }
    if (propertiesObj[key.toUpperCase()]) {
      return propertiesObj[key.toUpperCase()];
    }
    if (alternateFieldNames[key]) {
      // Loop over alternate fieldnames and attempt each
      const alternates = alternateFieldNames[key];
      let value = null;
      for (let i = 0; i < alternates.length; i++) {
        if (propertiesObj[alternates[i]]) {
          value = propertiesObj[alternates[i]];
        } else if (propertiesObj[alternates[i].toUpperCase()]) {
          value = propertiesObj[alternates[i].toUpperCase()];
        }
      }
      return value;
    }

    const truncated = key.substring(0, 10);
    if (propertiesObj[truncated]) {
      if (type !== null) {
        if (typeof (propertiesObj[truncated]) === type) {
          return propertiesObj[truncated];
        }

        return null;
      }

      return propertiesObj[truncated];
    }
    return null;
  } catch (err) {
    console.error(err);
    return null;
  }
};

const getMissingStateCounty = async (clus) => {
  try {
    for (let i = 0; i < clus.length; i++) {
      const currentCLU = clus[i];
      if (currentCLU.admin_state === null || currentCLU.admin_county === null) {
        // Get the centroid
        const center = turf.centroid(currentCLU.geojson.geometry);
        const location = await getStateAndCounty(
          center.geometry.coordinates[1],
          center.geometry.coordinates[0], true,
        );

        currentCLU.admin_state = location.STATEFP;
        currentCLU.admin_county = location.COUNTYFP;
      }
    }
    return clus;
  } catch (err) {
    console.error(err);
    return null;
  }
};

/**
 * FSA's geojson for clus uses the following format:
 * FeatureCollection with a feature for each clu included
 * each CLU feature has geometry type GeometryCollection with a
 * polygon (CLU Boundary) and a point (center of clu)
 * the last feature in the list is the tract_boundary and is the
 * border of all CLUS in the feature collection (Union of all CLUs)
 * @param {Object} geojson FSA Specific Geojson object
 * @returns {Array} array of clu objects used to upload data to database
 */
const parseGeoJSON = async (geojson) => {
  const clus = [];
  if (geojson.type === 'FeatureCollection') {
    // console.log("FEATURE COLLECTION", geojson)
    // loop over features
    // eslint-disable-next-line no-restricted-syntax
    for (const feature of geojson.features) {
      // loop over features only considering the features with geometry type : geometrycollection
      if (feature.geometry.type === 'GeometryCollection') {
        const boundary = feature.geometry.geometries.filter((x) => x.type === 'Polygon' || x.type === 'MultiPolygon');
        if (boundary.length > 0) {
          const clu = {
            shape: WKT.convert(boundary[0]),
            ...feature.properties,
          };
          if (clu.last_change_date) {
            const changeDate = new Date(clu.last_change_date);
            clu.last_change_date = changeDate;
          }
          if (clu.creation_date) {
            const creationDate = new Date(clu.creation_date);
            clu.creation_date = creationDate;
          }
          clus.push(clu);
        }
      }
      else if (feature.geometry.type === 'Polygon'){
        // if polygon is the tract's boundary, skip it, as it is the overall boundary for the CLU's in the tract
        // if it is the ONLY polygon in the object then let it through as there is nothing else to work off of
        if (feature.properties.tract_boundary === true && geojson.features.length > 1) {
          continue;
        }
        const clu = {
          shape: WKT.convert(feature.geometry),
          ...feature.properties,
        };
        if (clu.last_change_date) {
          const changeDate = new Date(clu.last_change_date);
          clu.last_change_date = changeDate;
        }
        if (clu.creation_date) {
          const creationDate = new Date(clu.creation_date);
          clu.creation_date = creationDate;
        }
        clus.push(clu);
      }
    }
  } else {
    const clu = {
      shape: WKT.convert(geojson.geometry),
      ...geojson.properties,
    };
    if (clu.last_change_date) {
      const changeDate = new Date(clu.last_change_date);
      clu.last_change_date = changeDate;
    }
    if (clu.creation_date) {
      const creationDate = new Date(clu.creation_date);
      clu.creation_date = creationDate;
    }
    clus.push(clu);
  }
  // console.log('CLUS', clus)
  return clus;
};

import React, { useEffect, useState, useRef } from "react";
import * as L from "leaflet";
import * as turf from "@turf/turf";
import * as _ from "lodash";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "leaflet-path-transform";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "./leaflet.css";
import { exists } from "../../utils/helpers";
import { Tiles } from "../../constants/Tiles";
import "leaflet-easyprint";
import { Endpoints } from "../../constants/Endpoints"
import * as wkt from 'terraformer-wkt-parser';
import { stateCoordinates } from "../ReportContainer/MarketReport/Analytics/MapContainer/StateCoordinates/index"

const countyStyle = {
  color: '#e81c62',
  weight: 2,
  opacity: 1
};

export function MarketReportMap({
  boundary,
  print,
  id,
  counties,
  colorScale,
  activeMarker,
  setActiveMarker,
  activeFarm,
  topic,
  harvestDataForCounty,
  selectedState,
  selectedCrop,
  precipitationDataForCounty,
  gddDataForCounty
}) {
  /*
    Map used simply for image overlays. Example uses are Profit Map and UAV Reports.
    Current version likely retains more functionality than will be necessary.
   */
  // console.log("harvestDataForCounty", harvestDataForCounty, colorScale)
  // console.log("precipitationDataForCounty", precipitationDataForCounty)
  // console.log("topic", topic)
  const [map, setMap] = useState(null);
  const imageOverlay = useRef(null);
  const [newPoint, setNewPoint] = useState([]);
  const [selectedField, setSelectedField] = useState({ feature: null });
  const [countyBdy, setCountyBdy] = useState(null)

  const fieldLayer = L.featureGroup(null);

  const countiesForMap = useRef(L.geoJSON(null, {
    style: countyStyle,
  }));

  useEffect(() => {
    //console.log("In Display Map");
    drawMap();
  }, [selectedState]);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      //console.log("Adding field boundary");
      const features =
        typeof boundary === "object" ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: "FeatureCollection",
        features: [features],
      };
      drawFieldLayer(geoJson);
    }
  }, [boundary, map]);

  useEffect(() => {
    if (map !== null) {
      const [fips, ids] = getFips()
      drawCountyLayer(fips, ids)
    }

  }, [selectedState, harvestDataForCounty, map])

  useEffect(() => {
    if (map !== null && countyBdy !== null) {
      const [fips, ids] = getFips()
      countiesForMap.current.clearLayers();
      displayDataOnMap(countyBdy, fips, ids)
    }
  }, [selectedCrop, map, topic])

  const getFips = () => {
    let ids = [], fips = []
    let countyIds = []
    if(topic == "Yield/Acre"){
      countyIds = harvestDataForCounty.map(x => x.County)
    }
    else{
      countyIds = precipitationDataForCounty.map(x => x.FIPS)
      countyIds = countyIds.map(x => x.toString().replace(selectedState.id.toString(), ""))
    }
    // console.log("countyIds", countyIds)
    for (let id of countyIds) {
      let stateStr = selectedState.id
      if(stateStr.toString().length == 1){
        stateStr = '0' + stateStr;
      }
      let str = id.toString()
      if (str.length == 1) {
        str = '00' + str
      }
      else if (str.length == 2) {
        str = '0' + str
      }
      fips.push(stateStr + str)
      ids.push({ fips: stateStr + str })
    }
    // console.log("fips", fips)
    return [fips, ids]
  }

  function getGeom(wkt = false) {
    var bounds = map.getBounds();
    var max = bounds.getNorthEast();
    var min = bounds.getSouthWest();

    var geomParam = {
      xmin: min.lng,
      ymin: min.lat,
      xmax: max.lng,
      ymax: max.lat,
      spatialReference: { wkid: 4326 },
    };
    if (wkt) {
      const bboxCoords = `POLYGON ((${geomParam.xmin} ${geomParam.ymin}, ${geomParam.xmax} ${geomParam.ymin}, ${geomParam.xmax} ${geomParam.ymax}, ${geomParam.xmin} ${geomParam.ymax},${geomParam.xmin} ${geomParam.ymin}))`;
      return bboxCoords;
    }
    return geomParam;
  }

  function drawCountyLayer(fips, ids) {
    //console.log("ids", fips, ids)
    try {
      let geom = getGeom(true);
      let type = "envelope";
      let level = "County";
      let qString =
        "?Geometry=" +
        geom +
        "&Geometry_type=" +
        type +
        "&Level=" +
        level;
      fetch(Endpoints.BASEURL + "/api/Boundary/GetBoundary" + qString, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((info) => {
          const results = JSON.parse(info);
          countiesForMap.current.clearLayers();
          if (info !== undefined && results !== undefined) {
            setCountyBdy(results)
            displayDataOnMap(results, fips, ids)
            //setWeatherAlertsOnMap((prev) => [...prev, ...alertsOnMap]);
          }
        }).catch(error => console.log(error));
    } catch (err) {
      console.log(`Failed to draw county layer, error: ${err}`)
    }
  }

  const displayDataOnMap = (results, fips, ids) => {
    try {
      const alertsOnMap = [];
      const alertCounties = [];
      //console.log("results,", results)
      for (const item of results) {
        const temp = { ...item };
        const { shape } = item;
        const geo = wkt.parse(shape);
        const feature = { type: 'Feature', geometry: {}, properties: {} };
        feature.geometry = geo;
        delete temp.shape;
        feature.properties = { ...temp, name: [] };
        // console.log(feature)
        const completeFip = feature.properties.STATEFP + feature.properties.CNTY_FIPS;
        feature.properties.name = completeFip;

        if (Number(feature.properties.STATEFP) == Number(selectedState.id)) {
          // alertCounties.push(feature);
          //console.log("fips", fips, completeFip)
          if (fips.includes(completeFip)) {
            //console.log("inside if")
            const fipID = ids.filter((id) => completeFip === id.fips);
            feature.properties.name = fipID;
            // console.log("precipitationDataForCounty.filter(x => Number(x.FIPS) == Number(feature.properties.GEOID))", 
            // precipitationDataForCounty.filter(x => Number(x.FIPS) == Number(feature.properties.GEOID)))
            if (topic == "Yield/Acre"  && harvestDataForCounty.filter(x => x.County === feature.properties.CNTY_FIPS && x.CropName === selectedCrop.id).length > 0) {
              let val = harvestDataForCounty.filter(x => x.County === feature.properties.CNTY_FIPS && x.CropName === selectedCrop.id)[0].AverageHarvestYield.toFixed(2)
              //console.log("color", colorScale(val))
              feature.properties.value = val;
              feature.properties.color = colorScale(val)
              alertCounties.push(feature);
            }            
            else if(topic == "Precipitation" && precipitationDataForCounty.filter(x => Number(x.FIPS) == Number(feature.properties.GEOID)).length > 0){
              let val = precipitationDataForCounty.filter(x => Number(x.FIPS) == Number(feature.properties.GEOID))[0].Pcpn.toFixed(2)
              //console.log("color", colorScale(val))
              feature.properties.value = val;
              feature.properties.color = colorScale(val)
              alertCounties.push(feature);
            }
            else {
              let val = 0
              feature.properties.value = val;
              feature.properties.color = colorScale(val)
              alertCounties.push(feature);
            }
            // featuresWithAlerts.push(county);
          }
          else {
            let val = 0
            feature.properties.value = val;
            feature.properties.color = colorScale(val)
            alertCounties.push(feature);
          }
        }

      }
      //console.log("alertCounties", alertCounties)
      countiesForMap.current.addData(alertCounties);

      countiesForMap.current.eachLayer((layer) => {
        layer.setStyle({
          color: layer.feature.properties.color,
          weight: 2,
          opacity: 1,
        })
      })

      countiesForMap.current.eachLayer((layer) => {
        layer.bindTooltip(`${layer.feature.properties.NAME}: ${layer.feature.properties.value}`,
          { permanent: true, direction: "center", className: "underwritingTooltip" }).openTooltip()
      })

      //console.log('countiesForWeatherAlert.current', countiesForWeatherAlert.current);
      // add click event to each shape
      countiesForMap.current.eachLayer((layer) => {
        //console.log("layer", layer)
        layer.on('click', (e) => {
          // flash feature in highlight color
          e.target.setStyle({
            color: layer.feature.properties.color,
            weight: 2,
            opacity: 1,
          });
          if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
            e.target.bringToFront();
          }
          // then after 1000 ms return feature to previous color
          setTimeout(() => {
            e.target.setStyle({
              color: layer.feature.properties.color,
              weight: 2,
              opacity: 1,
            });
          }, 1000);
        });
      });
      //console.log('countiesForMap.current', countiesForMap.current);
      map.addLayer(countiesForMap.current);
      map.setZoom(7);
    }
    catch (err) {
      console.log('error displaying data on map', err)
    }
  }

  async function drawMap() {
    try{
      const mapboxTiles = L.tileLayer(Tiles.ESRIBASEMAP);
      let coord = stateCoordinates.filter(f => f.id == selectedState.id)[0].coordinates
      const provider = new OpenStreetMapProvider();
      let newMap = null;
      if(map == null){
        newMap = L.map("map", {
          editable: true,
          editOptions: {
            lineGuideOptions: {
              opacity: 0,
            },
          },
        })
          .setView(coord, 6)
          .addLayer(mapboxTiles);
    
      }
      else{
        map.setView(coord, 6)
        return;
      }
      
      function getGeom() {
        var bounds = newMap.getBounds();
        var max = bounds.getNorthEast();
        var min = bounds.getSouthWest();
  
        var geomParam = {
          xmin: min.lng,
          ymin: min.lat,
          xmax: max.lng,
          ymax: max.lat,
          spatialReference: { wkid: 4326 },
        };
        return geomParam;
      }
  
      setMap(newMap);
      newMap.addLayer(fieldLayer);
    }
    catch(err){
      console.log("drawMap() error", err)
    }
  }

  function drawFieldLayer(geo) {
    try {
      const geometry = geo.features[0].geometry;
      const boundary =
        geometry.type === "Polygon"
          ? turf.polygon(geo.features[0].geometry.coordinates)
          : turf.multiPolygon(geo.features[0].geometry.coordinates);
      let newCollection = L.geoJson(boundary, {
        onEachFeature: (feature, layer) => {
          fieldLayer.addLayer(layer).setStyle({ fillOpacity: 0 });
        },
      });
      map.fitBounds(newCollection.getBounds());
      newCollection.addTo(map);
    } catch (err) {
      console.log(err);
    }
  }

  return (
    <div
      id="map"
      style={{
        marginTop: "10%",
        height: "90%",
        width: "100%",
        position: "relative",
      }}
    ></div>
  );
}

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
	Container, Box, Typography, Button,
} from '@material-ui/core';
import background from '../../images/tractors.jpg';
import climateLogo from '../../images/CFV_STK_RGB.png';
import { checkIsMobile } from '../../utils/helpers';
import { grey, lightGrey, blackText, darkText, green } from "../../styles/colors";

const useStyles = makeStyles((theme) => ({
	root: {
		display: 'flex',
		width: '100%',
		minHeight: 'calc(100vh - 56px)',
		flexGrow: 1,
		backgroundImage: `url(${background})`,
		backgroundPosition: 'center',
		backgroundRepeat: 'noRepeat',
		backgroundSize: 'cover',
		overflowY: 'auto',
	},
	darken: {
		display: 'flex',
		flexGrow: 1,
		width: '100%',
		minHeight: 'calc(100vh - 56px)',
		backgroundColor: 'rgba(0,0,0,.6)',
		backgroundPosition: 'center',
		backgroundRepeat: 'noRepeat',
		backgroundSize: 'cover',
		overflowY: 'auto',
	},
	special: {
		marginTop: 8,
		padding: 8,
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
		flexWrap: 'wrap',
		border: `2px solid ${theme.palette.greys.main}`,
		backgroundColor: theme.palette.greys.light,
		color: theme.palette.text.primary,
	},
}));


export const IntegrationThanks = ({
	setSection
}) => {
  const classes = useStyles();
	
  useEffect(() => {
    if (setSection) {
      setSection(-1);
    }
  }, [setSection]);

	return(
		<Box className={classes.root}>
			<Box className={classes.darken}>
				<Container maxWidth="md">
					<Box
						display="flex"
						justifyContent="center"
						flexDirection="column"
						alignItems="center"
					>

						<Box
							my={2}
							borderRadius="borderRadius"
							boxShadow={1}
							width="70%"
						>
							<Box
								px={1}
								display="flex"
								flexDirection="column"
								alignItems="center"
								flexGrow={1}
								style={{ backgroundColor: lightGrey, textAlign: 'center', borderRadius: 10, padding: 20 }}
								color={blackText}
							>
								<Typography variant="h3">
									Thank You For Integrating And Connecting Your Precision Account.
								</Typography>
								<Typography variant="body1" style={{paddingTop: 5}}>
									If you'd like to know more about ProfitLayers®  please visit our <a href="https://profit.ag">main website</a>.
								</Typography>
							</Box>
						</Box>
					</Box>

				</Container>
			</Box>
		</Box>
	)
}
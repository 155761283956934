import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Modal,
  Button,
  Typography,
  Divider,
  CircularProgress,
  Fade,
} from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DoneIcon from '@material-ui/icons/Done';
import { Endpoints } from '../../constants/Endpoints';
import { getETLStatus } from '../../utils/dataFetchers';
import { green } from '../../styles/colors';
import digsLogo from '../Digs/images/digs-logo.png';
import uavImage from '../../images/Farmer_Drone_Pilot.jpeg';
import datalayers from '../../images/DataLayersSlide.png';
import arcGis from '../../images/ArcGISProAdd-In.png'
import { formatTime } from '../../utils/helpers'

const mobileRoundIcon = {
  width: '20px',
  height: '20px',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 2,
  borderRadius: '50%',
  marginRight: '6px',
  color: '#67b444',
  backgroundColor: '#67b444',
};

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: '4px 8px',
    backgroundColor: theme.palette.greys.light,
    borderRadius: 8,
    border: `1px solid ${theme.palette.greys.dark}`,
    width: 780,
    maxWidth: '98vw',
  },
  close: theme.close,
  infoToolTip: theme.infoToolTip,
  box: {
    display: 'flex',
    padding: 8,
    borderRadius: 16,
    border: `1px solid ${theme.palette.greys.dark}`,
    backgroundColor: '#d9d9d9',
  },
  dot: {
    fontSize: 8,
    marginRight: 8,
    marginTop: 5,
  },
  cardText: {
    display: 'flex',
    margin: 4,
    fontSize: '1rem,',
  },
}));

export function IntegrationStatus({ open, setOpen }) {
  const classes = useStyles();

  const [integrationSource, setSource] = useState('...');
  const [integrationStatus, setIntegrationStatus] = useState('...');
  const [lastUpdated, setLastUpdated] = useState('...');
  const [integrationFlag, setFlag] = useState(0);
  const [message, setMessage] = useState('');

  const [step, setStep] = useState(1);

  const [countdown, setCountdown] = useState(5);
  const [completed, setCompleted] = useState(false);

  useEffect(() => {
    if (message !== '') {
      const both = message.split(' | ');
      setIntegrationStatus(both[0]);
      //setLastUpdated(both[1]);
    }
  }, [message]);

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const timer = async () => {
    let count = 5;
    while (count > 0) {
      await sleep(1000);
      --count;
      setCountdown(count);
    }
    // close modal and refresh page
    handleClose();
  };

  const handleClose = () => {
    setOpen(false);
    const url = document.URL.split("?")[0];
    window.location.assign(url);
  };

  useEffect(() => {
    if (open) {
      alertETLstatus();
    }
  }, [open]);

  // Get ETL status for integration, update the page with the current status, and keep updating until integration is complete
  const alertETLstatus = async (triggerRepeat = true, noResultAtempt = 0) => {
    let repeat = true;
    const status = await getETLStatus();

    // if nothing in status, could either be from etl tracker being slow
    // or user not having integration. (they most likely would not be here without one though)
    if (!status || status?.length === 0) {
      // wait some seconds then try again a couple of times
      if (noResultAtempt > 2) {
        console.log('Exiting integration: getETLStatus returned no status.');
        // NOTE: Might be good to notify user that there's nothing and to contact us
        return;
      }

      setTimeout(() => {
        alertETLstatus(true, noResultAtempt+1)
      }, 3000);
      return;
    }

    // On next line, we inject data for testing flows
    // status[0].statusDescription = "Processing"
    const currDateObj = new Date();
    const yesterday = new Date();
    yesterday.setDate(currDateObj.getDate() - 1);

    let updating = status.filter((x) => new Date(x.updatedAt) >= yesterday);
    updating.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

    if (updating.length == 0) {
      // if none currently updating, then end here
      updating = status;
      repeat = false;
    }

    // Set other items
    const { source, flag } = updating[0];
    const url = document.URL.split("?");
    if (url.length > 1) {
      const extension = url[1];
      const urlParams = new URLSearchParams(extension);
      const urlSource = urlParams.get("source");
      setSource(urlSource);
    }
    else {
      setSource(source);
    }
    if (flag !== integrationFlag) {
      setFlag(flag);
    }

    // Let's begin constructing the notification to the user
    const baseMessage = '';
    const { statusDescription } = updating[0];
    let extraDetails = '';

    // Let's format the created date and last updated date
    const updatedDateObj = new Date(updating[0].updatedAt);

    let updatedOn = String(updatedDateObj);
    let sliceNdx = updatedOn.indexOf(':');
    //updatedOn = updatedOn.slice(0, sliceNdx - 2);
    updatedOn = updatedOn.split(' ')
    const time = formatTime(updatedDateObj)

    setLastUpdated(`${updatedOn[1]} ${updatedOn[2]}, ${updatedOn[3]}Time: ${time}`);

    let createdOn = String(updatedDateObj);
    sliceNdx = createdOn.indexOf(':');
    createdOn = createdOn.slice(0, sliceNdx - 2);

    switch (flag) {
      case 1:
        extraDetails = ` | ${updatedOn}`;
        break;
      case 3:
        setStep(1);
        break;
      case 4:
        setStep(2);
        break;
      case 5:
      case 6:
        setStep(3);
        break;
      case 7:
        extraDetails = ` | ${createdOn}`;
        setStep(5);
        break;
    }

    const finalMessage = baseMessage + statusDescription + extraDetails;
    setMessage(finalMessage);

    // If the flag is somewhere in the processing stage
    if (flag >= 3 && flag <= 7) {
      if (triggerRepeat && repeat) {
        // NOTE: maxAlerts not actually used.
        // Send max of 5 notifications, counter starts at 1 (1,2,3,4,5)
        repeatNotifications(5, 1, flag);
      }
    } else {
      // if it's less than 3 or greater than 7, we assume it finished successfully.
      // Reload the page so it shows the now loaded in user integrated data (orgs, farms, fields)
      // Wait 5 seconds so user has time to see complete status notification

      setCompleted(true);
      timer();
    }
    return flag;
  };


  // If integration isn't completed, call alertETLstatus again and repeat until integration is complete
  const repeatNotifications = (maxAlerts, currAlertNum, flag) => {
    // Still processing, send heartbeat
    setTimeout(async () => {
      flag = await alertETLstatus(false); // false, so we dont trigger an infinite loop
      repeatNotifications(maxAlerts, currAlertNum + 1, flag);
    }, 10000);
  };

  const completeStatus = () => (
    <Box p={1}>
      <Box fontSize={14} color={green}>
        Your integration is now complete!
      </Box>
      <Box mt={1} fontSize={12}>
        {`This will automatically close in ${countdown} seconds`}
      </Box>

    </Box>
  );

  const completedCircle = (number) => (
    <Box
      mr={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="#ffffff"
      bgcolor="primary.main"
      height={24}
      width={24}
      borderRadius="50%"
      fontSize={12}
    >
      { number }
    </Box>
  );

  const inProgressCircle = (number) => (
    <Box
      mr={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="primary.main"
      bgcolor="#ffffff"
      height={24}
      width={24}
      borderRadius="50%"
      border={1}
      borderColor="primary.main"
      fontSize={12}
    >
      { number }
    </Box>
  );

  const waitingCircle = (number) => (
    <Box
      mr={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      color="#767676"
      bgcolor="#d9d9d9"
      height={24}
      width={24}
      borderRadius="50%"
      border={1}
      borderColor="#767676"
      fontSize={12}
    >
      { number }
    </Box>
  );

  function Item({
    title, descriptions, buttonText, buttonLink, image,
  }) {
    return (
      <Box
        className={classes.box}
        display="flex"
        maxWidth={640}
        maxHeight={window.innerHeight - 20}
        fontWeight={400}
        style={{ overflowY: 'auto' }}
      >
        <Box display="flex">
          { window.innerWidth > 580 && (
            <Box
              my={2}
              p={2}
              display="flex"
              maxWidth={200}
              style={buttonLink === `${Endpoints.HOME}/app/WaterManagement` ? { margin: '60px 0' } : {}}
            >
              <img style={{ width: '100%', height: 'auto', borderRadius: 8 }} src={image} />
            </Box>
          )}

          <Box
            pl={1}
            display="flex"
            flexDirection="column"
            justifyContent="space-between"
            maxWidth={340}
          >
            <Box>

              <Typography
                align="center"
                style={{ fontSize: '1.4rem', fontWeight: 600 }}
              >
                { title }
              </Typography>

              <Box mt={1}>
                { descriptions.map((x, i) => (
                  <Box key={i} className={classes.cardText}>
                    <FiberManualRecordIcon className={classes.dot} />
                    { x }
                  </Box>
                ))}
              </Box>
            </Box>

            <Box mt={2} textAlign="center">
              <Button
                variant="contained"
                color="primary"
                target="_blank"
                href={`${buttonLink}`}
                style={{ textDecoration: 'none', color: '#ffffff' }}
                disableElevation
              >
                { buttonText }
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>
    );
  }

  const iconDisplay = (stepToCheck) => {
    if (stepToCheck < step) {
      return completedCircle(stepToCheck);
    } if (stepToCheck === step) {
      return inProgressCircle(stepToCheck);
    }
    return waitingCircle(stepToCheck);
  };

  const statusDisplay = (stepToCheck) => {
    if (stepToCheck < step) {
      return <DoneIcon color="primary" style={{ marginBottom: 6 }} />;
    } if (stepToCheck === step) {
      return <CircularProgress color="primary" size={16} />;
    }
    return '';
  };

  const cards = [
    {
      title: <span>
        DATA LAYERS
        <sup>&trade;</sup>
             </span>,
      descriptions: [
        <span>
          Field level soil data (SSURGO and POLARIS) and elevation maps
        </span>,
        <span>
          Satellite NDVI and growth metrics (weather, precipitation, warmth)
        </span>,
        <span style={{ fontWeight: 600 }}>
          ACCESS FOR FREE!
        </span>,
      ],
      buttonText: 'EXPLORE NOW',
      buttonLink: `${Endpoints.HOME}/app/DataLayers`,
      image: datalayers,
    },
    {
      title: <span>
        DIGS DRAINAGE AND
        <br />
        WATER MANAGEMENT
      </span>,
      descriptions: [
        <span>
          Quality
          {' '}
          <span style={{ fontWeight: 600 }}>water management and drainage solutions</span>
          {' '}
          from DIGS
        </span>,
        <span>
          Engineers provide support through proposal planning and execution stages
        </span>,
      ],
      buttonText: 'ORDER NOW',
      buttonLink: `${Endpoints.HOME}/app/WaterManagement`,
      image: digsLogo,
    },
    {
      title: 'ON-DEMAND UAV SERVICE',
      descriptions: [
        <span>
          Sub-meter resolution field imagery and AI analysis reports for crop health and more
        </span>,
        <span>
          Fly your field and
          {' '}
          <span style={{ fontWeight: 600 }}>receive results in 3-6 days!</span>
          {' '}
          (Weather permitting)
        </span>,
      ],
      buttonText: 'ORDER NOW',
      buttonLink: `${Endpoints.HOME}/app/uav`,
      image: uavImage,
    },
    {
      title: 'ARCGIS PRO ADD-IN',
      descriptions: [
        <span>
          Download, view and manage precision ag data
          {' '}
          <span style={{fontWeight: 500}}>directly in the ESRI ArcGIS Pro</span>
          {' '}
          platform.
        </span>,
        <span>
          Analyze data and generate PROFIT LAYERS<sup>&reg;</sup>&nbsp; maps in ArcGIS Pro.
        </span>,
      ],
      buttonText: 'LEARN MORE',
      buttonLink: 'https://www.profit.ag/esri',
      image: arcGis,
    },
  ];



  const displayCards = () => {
    return (
      cards.map((item, i) => (
        <Item
          key={i}
          title={item.title}
          descriptions={item.descriptions}
          buttonText={item.buttonText}
          buttonLink={item.buttonLink}
          image={item.image}
        />
      ))
    )
  }

  return (
    <Modal
      open={open}
      onClose={handleClose}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="field-selection-instructions"
      aria-describedby="instructions-to-select-field-boundary-on-map"
    >
      <Box
        className={classes.paper}
        style={{ maxHeight: window.innerHeight - 40, overflowY: 'auto' }}
      >

        <HighlightOffIcon
          className={classes.close}
          onClick={handleClose}
        />

        <Typography align="center" variant="h5">
          YOUR INTEGRATION
        </Typography>

        <Box
          mt={1}
          display="flex"
          flexWrap="wrap"
          alignItems="center"
          justifyContent="center"
        >
          <Box className={classes.box} maxWidth={400} flexDirection="column">
            <Box>
              <Box display="flex" my={1}>
                <Box width="38%" fontWeight={500}>
                  Integration Status:
                </Box>
                <Box px={.5} width="62%" fontWeight={400}>
                  {integrationStatus}
                </Box>
              </Box>
              <Box display="flex" my={1} flexWrap='wrap'>
                <Box width="38%" fontWeight={500}>
                  Last Updated:
                </Box>
                <Box display='flex' flexWrap='wrap' px={.5} width="62%" fontWeight={400} >
                  <span style={{whiteSpace: 'nowrap'}}>{lastUpdated.split('Time:')[0]}&nbsp;</span>
                  <span style={{whiteSpace: 'nowrap'}}>{lastUpdated.split('Time:')[1]}</span>

                </Box>
              </Box>
              <Box display="flex" my={1}>
                <Box width="38%" fontWeight={500}>
                  Source:
                </Box>
                <Box px={.5} fontWeight={400} width="62%">
                  {integrationSource}
                </Box>
              </Box>
            </Box>

            <Box px={2} fontSize=".75rem">
              This automatic, one-time integration process may take longer depending on the number of fields and operations integrated.
            </Box>
          </Box>

          <Box ml={2}>
            <Box
              m={1}
              display="flex"
              align="center"
              justifyContent="space-between"
              height={30}
              width={290}
            >
              <Box display="flex">
                { iconDisplay(1) }
                <Box style={{whiteSpace: 'nowrap'}}>
                 LOADING FIELD BOUNDARIES
                </Box>
              </Box>

              <Box ml={2} mb={1}>
                { statusDisplay(1) }
              </Box>
            </Box>

            <Box
              m={1}
              display="flex"
              align="center"
              justifyContent="space-between"
              height={30}
              width={290}
            >
              <Box display="flex">
                { iconDisplay(2) }
                <Box>
                  LOADING OPERATIONS
                </Box>
              </Box>

              <Box ml={2} mb={1}>
                { statusDisplay(2) }
              </Box>
            </Box>

            <Box
              m={1}
              display="flex"
              align="center"
              justifyContent="space-between"
              height={30}
              width={290}
            >
              <Box display="flex">
                { iconDisplay(3) }
                <Box>
                  PROCESSING OPERATIONS
                </Box>
              </Box>

              <Box ml={2} mb={1}>
                { statusDisplay(3) }
              </Box>
            </Box>

            <Box
              m={1}
              display="flex"
              align="center"
              justifyContent="space-between"
              height={30}
              width={290}
            >
              <Box display="flex">
                { iconDisplay(4) }
                <Box>
                  INTEGRATION COMPLETE
                </Box>
              </Box>

              <Box ml={2} mb={1}>
                { statusDisplay(4) }
              </Box>
            </Box>
          </Box>
        </Box>

        <Fade in={completed} mountOnEnter unmountOnExit>
          <Box my={1} textAlign="center">
            { completeStatus() }
          </Box>
        </Fade>

        <Fade in={step > 2}>
          <Box my={1} textAlign="center">
            <Button
              variant="contained"
              color="primary"
              target="_blank"
              href={`${Endpoints.HOME}/app/purchase`}
              style={{ textDecoration: 'none', color: '#ffffff' }}
              disableElevation
            >
              Purchase Profit Layers<sup>&reg;</sup>&nbsp;Subscription
            </Button>
          </Box>
        </Fade>

        <Divider />

        <Typography align="center">
          Thank you for integrating. In the meantime, explore some other tools and services.
        </Typography>

        <Box
          mt={1}
          display="flex"
          justifyContent="center"
          alignItems="center"
        >
          <Carousel interval={15000}>
            { displayCards() }
          </Carousel>
        </Box>
      </Box>
    </Modal>
  );
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Modal,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blackText } from '../../../styles/colors';

import { uploadReportFile } from '../../../utils/dataFetchers';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { useSnackbar } from "notistack";


const useStyles = makeStyles((theme) => ({
  close: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  modalStyle: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    margin: 'auto',
    zIndex: 1002,
    top: '20%',
    padding: 8,
    width: 370,
  },
}));

/**
 * Rendered in AcreageReporing/AcreageReporting.js
 * Upload files associated with an operation
 * @param {Bool} open Modal opened or closed
 * @param {Function} setOpen Set modal opened or closed
 * @param {Number} cropYear Year crop was planted
 * @param {String} operationID Operation ID
 * @returns {JSX} Modal
 */
export function ReportFileUpload({ open, setOpen, cropYear, operationID }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);

  const handleChange = async (e) => {
    // console.log("e.target.files", e.target.files)
    setLoading(true);
    const maxSize = 500;
    let oneValid = false;

    // Attempt to upload every inputted file
    for (const file of e.target.files) {
      const fileName = file.name;
      // console.log('fileName :>> ', fileName);

      const sizeInMB = file.size / (1024 ** 2);
      // console.log('sizeInMB :>> ', sizeInMB);
      if (sizeInMB > maxSize) {
        enqueueSnackbar("Your file (" + fileName + ") is too large. Please upload zip files under " + maxSize + " MB");
        continue;
      }

      oneValid = true;
      // Attempt to upload the file and call conversion endpoint
      try {
        let formData = new FormData();
        formData.append("uploadFile", file)
        if (cropYear !== undefined && cropYear !== null) {
          formData.append("cropYear", cropYear)
        }
        if (operationID !== undefined && operationID !== null) {
          formData.append("operationID", operationID)
        }
        const response = await uploadReportFile(formData);

        // Make sure call succeeded
        if (response === undefined) {
          enqueueSnackbar("An error occurred while uploading your file (" + fileName + ")")
          // console.log("An error occurred while uploading the file (" + fileName + ") in UploadAgFile");
        }
        else {
          // Notify user
          enqueueSnackbar("Your file (" + fileName + ") was successfully uploaded!");
          // console.log('await response.text() :>> ', await response.text());
        }
      } catch (err) {
        enqueueSnackbar("Error")
        // console.log("An error occurred while uploading the file (" + fileName + "): " + err);
      }
    }
    setLoading(false);

  }


  return (
		<Modal
			open={open}
			onClose={() => setOpen()}
			style={{
			  display: 'flex',
			  alignItems: 'center',
			  justifyContent: 'center'
			}}
		>
			<Box
				className={classes.modalStyle}
				borderRadius="borderRadius"
				boxShadow={2}
			>
				<Box display="flex" justifyContent='flex-end' style={{ width: '100%' }}>
					<HighlightOffIcon
						className={classes.close}
						onClick={() => setOpen()}
					/>
				</Box>

				<Box
					px={1}
					fontWeight={500}
					fontSize={16}
					color={blackText}
				>
					{`Upload a file to cloud storage to be associated with your selected ${(operationID === undefined || operationID === null) ? "crop year" : "operation"} `}
				</Box>

				<Box my={1}>
					<Button
						variant="contained"
						component="label"
						color='primary'
						disableElevation
						style={{ marginTop: '8px' }}
					>
						Upload File(s)
						<input
							type="file"
							style={{ display: "none" }}
							onChange={(e) => handleChange(e)}
							multiple
						/>
					</Button>
				</Box>
        
				{loading && <SpinningLoader />}
			</Box>
		</Modal>
  )
}

ReportFileUpload.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  cropYear: PropTypes.number.isRequired,
  operationID: PropTypes.string,
};

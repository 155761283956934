import React, {
  useState, useEffect, Fragment, useContext,
} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Select,
  Menu,
  MenuItem,
  Divider,
  InputAdornment,
  TextField,
  Checkbox,
  Radio,
  Tooltip,
  Container,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import CloseIcon from '@material-ui/icons/Close';
import SearchIcon from '@material-ui/icons/Search';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import { useParams } from 'react-router-dom';
import _ from 'underscore';
import {
  darkText,
  darkGrey,
  grey,
  green,
  blackText,
} from '../../styles/colors';
import { MenuProps } from '../../styles/select';
import {
  seedingUnits,
  harvestUnits,
  units,
  unitConversion,
  applicationConversions,
} from '../ProfitLayers/utils/conversions';
import { capitalizeFirstLetter, exists } from '../../utils/helpers';
import * as df from '../../utils/dataFetchers';
import { Endpoints } from '../../constants/Endpoints';
// import { handleCropInput } from '../utils/autocompletes'
import { getPrices } from '../ProfitLayers/PriceTool/getPrices';
import { UserContext } from '../Context/UserContext';
import { useWindowDimensions } from '../../utils/dimensions';
import { Connect } from '../Shared/Connect';

const useStyles = makeStyles((theme) => ({
  dropdownBox: {
    display: 'flex',
    justifyContent: 'left',
    borderTop: '1px solid #d5d5d5',
  },
  noBluehover: {
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: '#ffffff',
    },
  },
  root: {
    width: '450px',
    minWidth: '450px',
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: 'solid 1px #cccccc',
    backgroundColor: theme.palette.greys.light,
  },
  priceToolRoot: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    border: 'solid 1px #cccccc',
    backgroundColor: theme.palette.greys.light,

  },
  header: {
    fontWeight: 500,
    fontSize: 14,
  },
  backButton: {
    marginTop: 15,
    marginBottom: 15,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.primary.dark,
    },
  },
  addNew: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    color: theme.palette.text.grey,
    fontWeight: 500,
    fontSize: 14,
    padding: 16,
  },
  operationsList: {
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
    marginTop: 5,
    color: theme.palette.text.black,
  },
  select: {
    width: '200px',
  },
  filterSelect: {
    width: '150px',
    height: '40px',
    marginLeft: '2px',
  },
  add: {
    color: theme.palette.primary.main,
    padding: '6px',
    marginLeft: 4,
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 600,
    width: '70px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  icon: {
    ...theme.greenIcon,
    fontSize: 14,
    fontWeight: 600,
  },
  dropDown: {
    fontSize: 14,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  input: {
    width: '160px',
    height: 'auto',
    backgroundColor: '#ffffff',
  },
  autoComplete: {
    width: '160px',
    backgroundColor: '#ffffff',
  },
  select: {
    width: '320px',
    height: '40px',
    backgroundColor: '#ffffff',
  },
}));

/**
 * Update prices at organization level for Seeding, Harvest, and Applications.
 * Update prices at farm level for Other Costs and Revenue.
 * @param {Function} setOpen Open or close Price Tool
 * @param {String} field Selected field (For other costs/rev)
 * @param {Array} operations Harvest, Seeding, Application, Other Costs/Rev
 * @param {String} type Type of operation
 * @param {String} user User data needed for all organizations
 * @param {Array} selectedYears Selected years of operations
 * @param {Function} refreshGenericOperations refreshes list of generic operations in ProfitLayers.js
 * @param {Function} setSaving toggles loading circle at bottom of operation tables
 * @param {Function} refreshSeedingOperations refreshed list of seeding operations in ProfitLayers.js
 * @param {Function} refreshHarvestOperations refreshed list of harvest operations in ProfitLayers.js
 * @param {Function} refreshApplicationOperations refreshed list of application operations in ProfitLayers.js
 * @param {Boolean} isProfitLayers boolean value to check if it is being used in ProfitLayers or in stand alone price tool app
 * @returns {JSX} PriceConverter JSX
 */
export function PriceConverter({ selectedYears = [2020] }) {
  const classes = useStyles();
  // Breakpoint for reorganizing dropdowns
  const mobileBreakPoint = 750;
  const user = useContext(UserContext)[0];
  const { height, width } = useWindowDimensions();

  const [userToken, setUserToken] = useState('');
  const [userTokenExists, setUserTokenExists] = useState(true);
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);

  const [organization, setOrganization] = useState({
    ID: '',
    Name: '',
    source: '',
  });

  // Type of operation selected
  const [operationType, setOperationType] = useState('Seeding');
  const [selectedOperations, setSelectedOperations] = useState([]);
  const [filteredOperations, setFilteredOperations] = useState([]);// list of filtered operations

  const [selectedUnits, setSelectedUnits] = useState(['']);

  const [searchFilter, setSearchFilter] = useState('');

  // Create new price
  const [add, setAdd] = useState(false);
  const [product, setProduct] = useState('');
  const [variety, setVariety] = useState('');
  const [price, setPrice] = useState('');
  const [unit, setUnit] = useState(selectedUnits[0]);
  const [year, setYear] = useState(2020);
  const [validInput, setValidInput] = useState(true);

  // Searchable dropdowns
  const [crops, setCrops] = useState([]);
  const [cropInputValue, setCropInputValue] = useState('');
  const [allVarieties, setAllVarieties] = useState([]);
  const [varietyInputValue, setVarietyInputValue] = useState('');
  const [allChemicals, setAllChemicals] = useState([]);
  const [chemicalInputValue, setChemicalInputValue] = useState('');

  // For bags and boxes get weight
  const [weight, setWeight] = useState('');
  const [weightUnit, setWeightUnit] = useState('');

  const [organic, setOrganic] = useState(false);
  const [otherType, setOtherType] = useState('Cost');

  const [weightUnits, setWeightUnits] = useState(['pound', 'kilogram']);

  // Organizations to apply price update to
  const [applyToOrgs, setApplyToOrgs] = useState([]);

  const [orgSelectionOpen, setOrgSelectionOpen] = useState(false);
  const [fieldSelectionOpen, setFieldSelectionOpen] = useState(false);

  // Fields to apply price update to (used for other cost/rev)
  const [currentField, setCurrentField] = useState({ Name: '', ID: '' });
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');

  // Fields for sorting & filter
  const [yearSorted, setYearSorted] = useState('Descending');
  const [cropSorted, setCropSorted] = useState('Ascending');
  const [varietySorted, setVarietySorted] = useState('Ascending');
  const plantingFilterOptions = ['Crop', 'Variety', 'Year'];
  const otherFilterOptions = ['Product', 'Year'];
  const [filterType, setFilterType] = useState(plantingFilterOptions[0]);
  const [lastSort, setLastSort] = useState(null);
  const [lastDirection, setLastDirection] = useState(null);

  const [applyToFields, setApplyToFields] = useState(['']);
  const [fields, setFields] = useState([]);

  const years = [
    2020,
    2019,
    2018,
    2017,
    2016,
    2015,
    2014,
    2013,
    2012,
    2011,
    2010,
  ];

  const options = [
    'Seeding',
    'Application',
    'Harvest',
    'Other Expenses/Revenue',
  ];

  const { pageSource = 'ProfitLayers' } = useParams();

  useEffect(() => {
    const divToScrollTo = document.getElementById('price-tool');
    if (divToScrollTo) {
      divToScrollTo.scrollIntoView(true, { behavior: 'smooth' });
    }
  }, []);

  useEffect(() => {
    const url = document.URL.split('?');
    if (url.length > 1) {
      const extension = url[1];
      if (extension.includes('token')) {
        const token = extension.replace('token=', '');
        setUserToken(token);
        // remove token from url so user can't grab it
        // NOTE: token still shows up in dev tools & network tab
        url.pop();
        window.history.replaceState({}, document.title, url[0]);
      } else {
        setUserTokenExists(false);
      }
    } else {
      setUserTokenExists(false);
    }
  }, []);

  // Force user to log in if they are not, if no token was passed in the URL
  // An invalid token easily bypasses this
  useEffect(() => {
    // console.log('user', user);
    // console.log('userTokenExists :>> ', userTokenExists);

    // Exit unless we are sure we retrieved user data - do this to avoid flashing the modal
    if (_.isEmpty(user) || userTokenExists) return;

    if (!user.isAuthenticated) {
      setLoginPromptOpen(true);
    } else {
      // Make sure to close modal in case a retry has been implemented
      setLoginPromptOpen(false);
    }
  }, [user, userTokenExists]);

  useEffect(() => {
    if (user.isAuthenticated !== undefined || userToken !== '') {
      if (user.isAuthenticated || userToken !== '') {
        getOrganizations();
      }
    }
  }, [user, userToken]);

  useEffect(() => {
    // varieties or components
    const ops = [];
    console.log('operationType', operationType);
    switch (operationType) {
      case 'Seeding':

        setProduct('Corn');
        setVariety('DKC26-40RIB');
        setSelectedUnits(seedingUnits);
        setUnit('bag');
        setWeight(80);
        setWeightUnit('lb');
        setWeightUnits(['pound', 'kilogram', 'seeds', 'bushel']);
        setSearchFilter('');
        setFilterType(plantingFilterOptions[0]);
        if (selectedOrg !== '') { setPlantingPrices(selectedOrg, 'seeding'); }
        break;
      case 'Harvest':
        setSearchFilter('');
        setFilterType(plantingFilterOptions[0]);
        setProduct('Corn');
        setVariety('DKC26-40RIB');
        setSelectedUnits(harvestUnits);
        setWeightUnits(['pound', 'kilogram', 'bushel']);
        if (selectedOrg !== '') { setPlantingPrices(selectedOrg, 'harvest'); }
        break;
      case 'Application':
        setSearchFilter('');
        setFilterType(otherFilterOptions[0]);
        setProduct('');
        setWeight('');
        setSelectedUnits([
          ...units,
          'bag',
          'box',
          'bulk',
          'case',
          'container',
          'drum',
          'jug',
          'mini bulk',
          'tote',
        ]);
        setUnit(units[0]);
        setWeightUnits(['fluid ounce', 'gallon', 'kilogram', 'liter', 'metric ton', 'ounce', 'pound', 'quart', 'ton']);
        if (selectedOrg !== '') { setApplicationPrices(selectedOrg); }
        break;
      default:
        // Other Costs and Revenue
        // setSearchFilter("")
        setSelectedUnits([]);
        setProduct('Crop Insurance');
        setPrice(20);
        setUnit('');
        setFilterType(otherFilterOptions[0]);
        if (selectedOrg !== '') { setGenericPrices(selectedOrg); }
    }
  }, [selectedOrg, operationType]);

  useEffect(() => {
    if (searchFilter !== '') {
      console.log(searchFilter);
      filterOperations();
    } else {
      setFilteredOperations(selectedOperations);
    }
  }, [searchFilter, selectedOperations]);

  function dynamicSort(property) {
    let sortOrder = 1;
    if (property[0] === '-') {
      sortOrder = -1;
      property = property.substr(1);
    }
    return function (a, b) {
      /* next line works with strings and numbers,
         * and you may want to customize it to your needs
         */
      const result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
      return result * sortOrder;
    };
  }

  function dynamicSortMultiple() {
    /*
     * save the arguments object as it will be overwritten
     * note that arguments object is an array-like object
     * consisting of the names of the properties to sort by
     */
    const props = arguments;
    return function (obj1, obj2) {
      let i = 0; let result = 0; const
        numberOfProperties = props.length;
      /* try getting a different result from 0 (equal)
         * as long as we have extra properties to compare
         */
      while (result === 0 && i < numberOfProperties) {
        result = dynamicSort(props[i])(obj1, obj2);
        i++;
      }
      return result;
    };
  }

  const getOrganizations = async () => {
    try {
      let finalOrgs = [];
      const orgs = await df.fetchOrganizations(userToken);

      if (userToken !== '' || userToken !== null) {
        const preferredOrgs = await df.getPreferredOrgs();
        if (orgs.length > 0) {
          for (var i = 0; i < orgs.length; i++) {
            const record = preferredOrgs.filter((org) => org.ID === orgs[i].ID);
            if (record.length > 0) {
              if (record[0].IsPrefered === true) {
                finalOrgs.push(orgs[i]);
              }
            } else {
              finalOrgs.push(orgs[i]);
            }
          }
        }
      } else {
        finalOrgs = [...orgs];
      }

      setOrganizations(finalOrgs);
      setSelectedOrg(finalOrgs[0].ID);
      setApplyToOrgs([finalOrgs[0]]);
    } catch {
      setOrganizations([]);
      setSelectedOrg('');
      setApplyToOrgs([]);
    }
  };

  const setPlantingPrices = async (orgId, opType) => {
    // all ops in price tool
    const allOps = await getPrices(orgId, opType, userToken);
    // console.log("allOps", allOps)

    const filtered = allOps.filter(
      (x) => x.ProductName !== null
        && x.ProductName !== ''
        && x.Price !== 0
        && x.Price !== ''
        && selectedYears.includes(x.Year),
    );

    const ops = allOps.map((x) => ({
      ...x,
      price: x.Price,
      productName: x.ProductName,
      variety: x.Variety,
      isOrganic: x.IsOrganic,
      unit: x.Unit,
      year: x.Year,
    }));
    ops.sort(dynamicSortMultiple('-year', 'productName', 'variety'));

    setSelectedOperations(ops);
    // setFilteredOperations(ops)
  };

  const setApplicationPrices = async (orgId) => {
    // all ops in price tool
    const allOps = await getPrices(orgId, 'application', userToken);

    const filtered = allOps.filter(
      (x) => x.ProductName !== null
        && x.ProductName !== ''
        && x.Price !== 0
        && x.Price !== ''
        && selectedYears.includes(x.Year)
        && x.Unit !== '',
    );

    const ops = allOps.map((x) => ({
      ...x,
      price: x.Price,
      productName: x.ProductName,
      unit: x.Unit,
      year: x.Year,
    }));
    ops.sort(dynamicSortMultiple('-year', 'productName'));
    setSelectedOperations(ops);
    // setFilteredOperations(ops);
  };

  const setGenericPrices = async (orgId) => {
    const allOps = await getPrices(orgId, 'generic', userToken);
    // const filtered = allOps.filter((x) => selectedYears.includes(x.Year));
    const ops = allOps.map((x) => ({
      ...x,
      price: x.Price,
      productName: x.ProductName,
      year: x.Year,
      unit: x.Unit,
    }));
    ops.sort(dynamicSortMultiple('-year', 'productName'));
    setSelectedOperations(ops);
    // setFilteredOperations(ops);
  };

  const checkFilter = (list) => {
    /* Takes in full list of options, return only those
       who start with characters matching searchFilter state
   */
    const available = list.filter(
      (x) => x.productName !== undefined && x.productName.slice(0, searchFilter.length).toLowerCase()
        === searchFilter.toLowerCase(),
    );
    return available;
  };

  const clearSorts = () => {
    setYearSorted('Descending');
    setCropSorted('Ascending');
    setVarietySorted('Ascending');
    setLastDirection(null);
    setLastSort(null);
  };

  /**
   * Filters the displayed operation list based on input from the search bar and the
   * selected fitler parameter (this is only present when operationType is Harvest or Seeding)
   */
  const filterOperations = () => {
    console.log(filterType, searchFilter, operationType);
    let filtered;
    if (operationType.toUpperCase() === 'HARVEST' || operationType.toUpperCase() === 'SEEDING') {
      if (filterType == 'Crop') {
        filtered = selectedOperations.filter((x) => x.productName.toUpperCase().includes(searchFilter.toUpperCase()));
      } else if (filterType == 'Variety') {
        filtered = selectedOperations.filter((x) => x.variety.toUpperCase().includes(searchFilter.toUpperCase()));
      } else {
        filtered = selectedOperations.filter((x) => {
          const year = x.year !== null ? x.year : x.CropSeason;
          return year.toString().toUpperCase().includes(searchFilter);
        });
      }
    } else if (filterType == 'Product') {
      filtered = selectedOperations.filter((x) => x.productName.toUpperCase().includes(searchFilter.toUpperCase()));
    } else {
      filtered = selectedOperations.filter((x) => {
        const year = x.year !== null ? x.year : x.CropSeason;
        return year.toString().toUpperCase().includes(searchFilter);
      });
    }
    if (lastSort !== null && lastDirection !== null) {
      console.log(filtered);
      filtered = sortItems(lastSort, lastDirection, filtered);
    }
    console.log(filtered);
    setFilteredOperations(filtered);
  };

  const handleFilterChange = (val) => {
    console.log('Filter selected', val);
    setFilterType(val);
    setSearchFilter('');
    setFilteredOperations(selectedOperations);
  };

  /**
  * DEPRICATED THIS STACKS THE SORT OPTIONS
  */
  // const sortItems = (sortBy, direction, input=null) => {
  //   console.log(sortBy, direction)
  //   setLastSort(sortBy)
  //   setLastDirection(direction)
  //   let operations = input === null ? filteredOperations : input
  //   let year = yearSorted === 'Descending' ? '-year' : 'year'
  //   let crop = cropSorted === 'Descending' ? '-productName' : 'productName'
  //   let variety = varietySorted === 'Descending' ? '-variety' : 'variety'
  //   if (sortBy == 'Year'){
  //     setYearSorted(direction)
  //     year = direction === 'Ascending' ? 'year' : '-year'
  //   }
  //   else if(sortBy == 'Crop'){
  //     setCropSorted(direction)
  //     crop = direction === 'Ascending' ? 'productName' : '-productName'
  //   }
  //   else if(sortBy == "Variety"){
  //     setVarietySorted(direction)
  //     variety = direction === 'Ascending' ? 'variety' : '-variety'
  //   }

  //   if(operationType.toUpperCase() === "HARVEST" || operationType.toUpperCase() === "SEEDING"){
  //     console.log(year, crop, variety)
  //     operations.sort(dynamicSortMultiple(year, crop, variety))
  //   }
  //   else{
  //     operations.sort(dynamicSortMultiple(year, crop))
  //   }

  //   if(input === null){
  //     setFilteredOperations(operations)
  //   }
  //   else{
  //     return operations
  //   }

  // }

  /**
   * sorts the list of operations being displayed in price converter by the sortBy arg in order of direction arg
   * @param {String} sortBy what to sort operations by ('Year', 'Crop', 'Variety')
   * @param {String} direction what direction to sort in ('Ascending', 'Descending')
   * @param {Array} input list of operations to sort (this arg will not be null if being called by filterOperations function)
   */
  const sortItems = (sortBy, direction, input = null) => {
    setLastSort(sortBy);
    setLastDirection(direction);
    const operations = input === null ? filteredOperations : input;
    if (sortBy == 'Year') {
      if (direction == 'Ascending') {
        setYearSorted(direction);
        operations.sort((a, b) => {
          const aYear = a.year !== null ? a.year : a.cropSeason;
          const bYear = b.year !== null ? b.year : b.cropSeason;
          return aYear - bYear;
        });
      } else {
        setYearSorted(direction);
        operations.sort((a, b) => {
          const aYear = a.year !== null ? a.year : a.cropSeason;
          const bYear = b.year !== null ? b.year : b.cropSeason;
          return bYear - aYear;
        });
      }
      setCropSorted('Descending');
      setVarietySorted('Descending');
    } else if (sortBy == 'Crop') {
      if (direction == 'Ascending') {
        setCropSorted(direction);
        operations.sort((a, b) => {
          if (a.productName.toUpperCase() < b.productName.toUpperCase()) { return -1; }
          if (a.productName.toUpperCase() > b.productName.toUpperCase()) { return 1; }
          return 0;
        });
      } else {
        setCropSorted(direction);
        operations.sort((a, b) => {
          if (a.productName.toUpperCase() > b.productName.toUpperCase()) { return -1; }
          if (a.productName.toUpperCase() < b.productName.toUpperCase()) { return 1; }
          return 0;
        });
      }
      setYearSorted('Descending');
      setVarietySorted('Descending');
    } else if (sortBy == 'Variety') {
      if (direction == 'Ascending') {
        setVarietySorted(direction);
        operations.sort((a, b) => {
          if (a.variety.toUpperCase() < b.variety.toUpperCase()) { return -1; }
          if (a.variety.toUpperCase() > b.variety.toUpperCase()) { return 1; }
          return 0;
        });
      } else {
        setVarietySorted(direction);
        operations.sort((a, b) => {
          if (a.variety.toUpperCase() > b.variety.toUpperCase()) { return -1; }
          if (a.variety.toUpperCase() < b.variety.toUpperCase()) { return 1; }
          return 0;
        });
      }
      setYearSorted('Descending');
      setCropSorted('Descending');
    }
    if (input === null) {
      setFilteredOperations(operations);
    } else {
      return operations;
    }
  };

  // currently show weight unit and value no matter unit, if decide dont need to can uncomment
  const needWeight = () => (operationType.toUpperCase() === 'SEEDING' || operationType.toUpperCase() === 'APPLICATION' || operationType.toUpperCase() === 'HARVEST');
  // unit === 'bag' ||
  // unit === 'bulk bag' ||
  // unit === 'bulk' ||
  // unit === 'bulk box' ||
  // unit === 'mini bulk' ||
  // unit === 'drum'||
  // unit === 'case' ||
  // unit === 'container' ||
  // unit === 'jug' ||
  // unit === 'other' ||
  // unit === 'tote'

  const handleCropChange = async (val) => {
    /** This is the function from the old PL tool to retrieve crop names
     * based on user input. The api performs the autocomplete functionaity on keypress. * */

    if (val && val !== '' && !crops.includes(val)) {
      let url = Endpoints.BASEURL;
      url
        += operationType === 'Harvest' ? Endpoints.HARVESTCROPNAME : Endpoints.SEEDCROPNAME;
      url += `/${val}`;
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error('Failed to get crop names');
        })
        .then((crops) => setCrops(crops))
        .catch((err) => console.log(err));
    }
    setProduct(val);
    setCropInputValue(val);
  };

  const handleVarietyInput = async (val) => {
    /** This is the function from the old PL tool to retrieve crop varieties
     * based on user input. The api performs the autocomplete functionaity on keypress. * */

    let url = Endpoints.BASEURL;
    url
      += operationType === 'Harvest' ? Endpoints.HARVESTVARIETYNAME : Endpoints.SEEDVARIETY;
    url += `/${val}`;

    if (val && val !== '' && !allVarieties.includes(val)) {
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error('Failed to get variety names');
        })
        .then((vars) => setAllVarieties(vars))
        .catch((err) => console.log(err));
    }
    setVariety(val);
    setVarietyInputValue(val);
  };

  const handleProductInput = async (val) => {
    /** This is the function from the old PL tool to retrieve product names
     * based on user input. The api performs the autocomplete functionaity on keypress. * */

    if (val === undefined || val === null) {
      val = '';
    }

    if (val !== '' && !(allChemicals.includes(val))) {
      let url = Endpoints.BASEURL;
      url += Endpoints.APPMATERIALNAME;
      url += `/${val}`;
      await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error('Failed to get product names');
        })
        .then((prods) => setAllChemicals(prods))
        .catch((err) => console.log(err));
    }

    setProduct(val);
    setChemicalInputValue(val);
  };

  const handleOperationChange = (e) => {
    setOperationType(e.target.value);
  };

  const handleOrgChange = (e) => {
    setSelectedOrg(e.target.value);
    const org = organizations.filter((o) => o.ID === e.target.value);

    setApplyToOrgs(org);
  };

  const handleUnitChange = (e) => {
    setUnit(e.target.value);
  };

  const handleWeightUnitChange = (e) => {
    setWeightUnit(e.target.value);
  };

  const handlePriceChange = (val, i) => {
    // console.log(val, i)
    const opCopy = [...selectedOperations];
    const updateOp = opCopy.splice(i, 1)[0];
    updateOp.price = parseFloat(val);
    opCopy.splice(i, 0, updateOp);
    setSelectedOperations(opCopy);
  };

  const handleEdit = (op) => {
    // set values to that of operation to edit
    console.log('op', op, unitConversion(op.Unit), unitConversion(op.Unit, true), unitConversion(op.UnitBasis, true), operationType);
    setProduct(op.ProductName);
    setUnit(unitConversion(op.Unit, true));
    setPrice(op.Price);
    setVariety(op.Variety);
    setOrganic(op.IsOrganic);
    setAdd(true);
    setYear(op.Year);
    if (operationType === 'Other Expenses/Revenue') {
      // if name fits field pass names then set to field pass, otherwise look at price value
      let types = ['Harvest','Seeding','Application'].map(x => x+' Costs - '+op.Year.toString())
      if(types.includes(op.productName)){
        setOtherType("Field Pass")
      }
      else{
        if(op.Price < 0){
          setOtherType("Cost")
        }
        else{
          setOtherType("Revenue")
        }
      }
    }

    if (operationType.toUpperCase() === 'SEEDING' || operationType.toUpperCase() === 'HARVEST') {
      const weight = op.UnitBasisValue === '' ? 80 : op.UnitBasisValue;
      const weightUnit = op.UnitBasis === '' ? 'lb' : op.UnitBasis;
      setWeight(weight);
      setWeightUnit(unitConversion(weightUnit, true));
    } else {
      setWeight(op.UnitBasisValue);
      setWeightUnit(unitConversion(op.UnitBasis, true));
    }
  };

  const handleUpdate = (op) => {
    // delete saved price
    console.log('op', op, operationType);
    let request;

    if (operationType.toUpperCase() === 'SEEDING' || operationType.toUpperCase() === 'HARVEST') {
      request = {
        isDeleted: 0,
        isOrganic: op.isOrganic,
        isPreferred: 1,
        operationType,
        orgId: op.OrgID,
        price: Number(op.price),
        productName: op.ProductName,
        unit: unitConversion(op.Unit),
        unitBasis: unitConversion(op.UnitBasis),
        unitBasisValue: Number(op.UnitBasisValue),
        variety: op.Variety,
        year: Number(op.Year),
      };
    } else if (operationType.toUpperCase() === 'APPLICATION') {
      request = {
        isDeleted: 0,
        isPreferred: 1,
        operationType,
        orgId: op.OrgID,
        price: Number(op.price),
        productName: op.ProductName,
        unit: unitConversion(op.Unit),
        unitBasis: unitConversion(op.UnitBasis),
        unitBasisValue: Number(op.Weight),
        year: Number(op.Year),
      };
    } else {
      // Generic
      request = {
        fieldId: null,
        isDeleted: 0,
        isPreferred: 1,
        operationType: 'Generic',
        orgId: op.OrgID,
        price: Number(op.price),
        productName: op.ProductName,
        year: Number(op.Year),
      };
    }
    post(request, operationType);
    saveConvertedPrices(request, operationType);
  };

  const validateSaveInputs = () => {
    if (exists(year) && exists(product) && exists(price) && exists(unit)) {
      return true;
    }

    return false;
  };

  const saveNew = async () => {
    // save new operation
    // console.log("applyToOrgs", applyToOrgs)
    const valid = validateSaveInputs();
    if (!valid) {
      setValidInput(false);
      return;
    }

    setValidInput(true);

    switch (operationType) {
      case 'Seeding':
        for (const org of applyToOrgs) {
          savePrice('Seeding', org.ID);
        }
        break;
      case 'Application':
        for (const org of applyToOrgs) {
          savePrice('APPLICATION', org.ID);
        }
        break;
      case 'Harvest':
        for (const org of applyToOrgs) {
          savePrice('HARVEST', org.ID);
        }
        break;
      default:
        // other costs and revenue
        const info = {};
        const ids = [];
        const prices = [];
        for (const org of applyToOrgs) {
          await savePrice('Generic', org.ID);
          ids.push(org.ID);

          const request = {
            fieldId: null,
            isDeleted: 0,
            isPreferred: 1,
            operationType: 'Generic',
            orgId: org.ID,
            price: otherType === 'Cost' ? 0 - Number(price) : Number(price),
            productName: product,
            year: Number(year),
          };
          prices.push(request);
        }
        console.log(prices);
        const requestObj = {
          prices, orgs: ids, ignoredFields: [], currentField: null, isFieldPass: otherType === 'Field Pass',
        };
        if (otherType !== 'Field Pass'){
          await df.priceToolOtherOperations(requestObj);
        }
    }
  };

  const savePrice = async (operationType, orgId, fieldId) => {
    let request;
    console.log(unitConversion(weightUnit), unitConversion(weightUnit, true));
    if (operationType.toUpperCase() === 'SEEDING' || operationType.toUpperCase() === 'HARVEST') {
      request = {
        isDeleted: 0,
        isOrganic: organic ? 1 : 0,
        isPreferred: 1,
        operationType,
        orgId,
        price: Number(price),
        productName: product,
        unit: unitConversion(unit),
        unitBasis: unitConversion(weightUnit),
        unitBasisValue: Number(weight),
        variety,
        year: Number(year),
      };
    } else if (operationType.toUpperCase() === 'APPLICATION') {
      request = {
        isDeleted: 0,
        isPreferred: 1,
        operationType,
        orgId,
        price: Number(price),
        productName: product,
        unit: unitConversion(unit),
        unitBasis: unitConversion(weightUnit),
        unitBasisValue: Number(weight),
        year: Number(year),
      };
    } else {
      // Generic
      request = {
        fieldId: null,
        isDeleted: 0,
        isPreferred: 1,
        operationType: 'Generic',
        orgId,
        price: otherType === 'Revenue' ? Number(price) : 0 - Number(price),
        productName: product,
        year: Number(year),
      };
    }
    console.log('Initial post', request);
    post(request, operationType);
    saveConvertedPrices(request, operationType);
    setAdd(false);
  };

  const post = async (request, operationType, refreshOperations = true) => {
    const url = Endpoints.BASEURL + Endpoints.ADDPRICES;
    let response;
    try {
      if (userToken !== '') {
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          credentials: 'include',
          body: JSON.stringify(request),
        });
      } else {
        response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
          body: JSON.stringify(request),
        });
      }

      if (response.status === 200) {
        refreshPrices(selectedOrg);
      } else {
        console.log(`Failed to save: ${response.status}`);
      }
    } catch (error) {
      console.log('Error saving price', error);
    }
  };

  const saveConvertedPrices = (req, operationType) => {
    if (operationType.toUpperCase() === 'SEEDING') {
      convertSeedPrices(req);
    } else if (operationType.toUpperCase() === 'HARVEST') {
      convertHarvestPrices(req);
    } else if (operationType.toUpperCase() === 'APPLICATION') {
      convertApplicationPrices(req);
    }
  };

  const convertSeedPrices = async (req) => {
    console.log('convertSeedPrices req', req);

    const modifiyRequest = JSON.parse(JSON.stringify(req));
    const seedConversions = [];
    const primaryUnit = modifiyRequest.unit;
    const primaryValue = modifiyRequest.price;
    const secondaryUnit = modifiyRequest.unitBasis;
    const secondaryValue = modifiyRequest.unitBasisValue;
    const crop = modifiyRequest.productName;

    console.log(primaryUnit, primaryValue, secondaryValue, secondaryUnit, crop);

    if ((primaryUnit == 'bag' || primaryUnit == 'unit' || primaryUnit == 'bulk bag' || primaryUnit == 'bulk box' || primaryUnit == 'mini bulk bag' || primaryUnit == 'other'
      || primaryUnit == 'seeds' || primaryUnit == 'lb' || primaryUnit == 'bu') && secondaryValue !== 0) {
      // convert primary to secondary
      var obj = {
        price: primaryValue / secondaryValue,
        unit: secondaryUnit,
      };
      seedConversions.push(obj);

      // convert above value to kseeds
      if (secondaryUnit == 'seeds') {
        var obj = {
          price: primaryValue / (secondaryValue / 1000),
          unit: 'K seeds',
        };
        seedConversions.push(obj);
      }
    } else if (primaryUnit == 'K seeds') {
      // kseeds to seeds
      var obj = {
        price: primaryValue / 1000,
        unit: 'seeds',
      };
      seedConversions.push(obj);
      // convert to secondary unit if exsits
      if (secondaryValue !== 0) {
        var obj = {
          price: primaryValue / secondaryValue,
          unit: secondaryUnit,
        };
        seedConversions.push(obj);
      }
    } else if (primaryUnit == 'metric ton') {
      // metric ton to kg
      var obj = {
        price: primaryValue / 1000,
        unit: 'kg',
      };
      seedConversions.push(obj);

      // metric ton to lb
      var obj = {
        price: (primaryValue / 1000) / 2.20462,
        unit: 'lb',
      };
      seedConversions.push(obj);

      // metric ton to ton
      var obj = { //
        price: primaryValue / 1.10231,
        unit: 'ton',
      };
      seedConversions.push(obj);

      // metric ton to secondary unit
      if (secondaryValue !== 0) {
        var obj = {
          price: primaryValue / secondaryValue,
          unit: secondaryUnit,
        };
        seedConversions.push(obj);
      }
    }
    // console.log('seedConversions', seedConversions)

    if (primaryUnit == 'bag' && secondaryUnit != 'seeds') {
      if (crop.toLowerCase().includes('corn')) {
        var obj = {
          price: primaryValue / 80000,
          unit: 'seeds',
        };
        seedConversions.push(obj);

        var obj = {
          price: primaryValue / 80,
          unit: 'K seeds',
        };
        seedConversions.push(obj);
      } else if (crop.toLowerCase().includes('soybeans')) {
        var obj = {
          price: primaryValue / 140000,
          unit: 'seeds',
        };
        seedConversions.push(obj);

        var obj = {
          price: primaryValue / 140,
          unit: 'K seeds',
        };
        seedConversions.push(obj);
      } else if (crop.toLowerCase().includes('sunflower')) {
        var obj = {
          price: primaryValue / 200000,
          unit: 'seeds',
        };
        seedConversions.push(obj);

        var obj = {
          price: primaryValue / 200,
          unit: 'K seeds',
        };
        seedConversions.push(obj);
      } else if (crop.toLowerCase().includes('cotton')) {
        var obj = {
          price: primaryValue / 250000,
          unit: 'seeds',
        };
        seedConversions.push(obj);

        var obj = {
          price: primaryValue / 250,
          unit: 'K seeds',
        };
        seedConversions.push(obj);
      } else if (crop.toLowerCase().includes('canola')) {
        var obj = {
          price: primaryValue / 22700,
          unit: 'seeds',
        };
        seedConversions.push(obj);

        var obj = {
          price: primaryValue / 22.7,
          unit: 'K seeds',
        };
        seedConversions.push(obj);
      }
    }

    // pound to ton, kg, metric ton
    if (primaryUnit == 'lb') {
      var obj = {};

      obj = { //
        price: primaryValue / 0.0005,
        unit: 'ton',
      };
      seedConversions.push(obj);

      if (secondaryUnit !== 'kg') {
        obj = { //
          price: primaryValue / 0.453592,
          unit: 'kg',
        };
        seedConversions.push(obj);
      }

      obj = { //
        price: primaryValue / 0.0005,
        unit: 'metric ton',
      };
      seedConversions.push(obj);
    }

    // ton to lb, metric ton, kg
    if (primaryUnit == 'ton') {
      var obj = {};

      if (secondaryUnit !== 'lb') {
        obj = { //
          price: primaryValue / 2000,
          unit: 'lb',
        };
        seedConversions.push(obj);
      }

      obj = { //
        price: primaryValue / 0.907185,
        unit: 'metric ton',
      };
      seedConversions.push(obj);

      if (secondaryValue !== 'kg') {
        obj = { //
          price: primaryValue / 907.185,
          unit: 'kg',
        };
        seedConversions.push(obj);
      }
    }

    // kg to metric ton, lb, ton
    if (primaryUnit == 'kg') {
      var obj = {};

      obj = { //
        price: primaryValue / 0.001,
        unit: 'metric ton',
      };
      seedConversions.push(obj);

      if (secondaryUnit !== 'lb') {
        obj = { //
          price: primaryValue / 2.20462,
          unit: 'lb',
        };
        seedConversions.push(obj);
      }

      obj = { //
        price: primaryValue / 0.00110231,
        unit: 'ton',
      };
      seedConversions.push(obj);
    }

    // Secomdary unit conversions
    // pound to ton, kg, metric ton
    if (secondaryUnit == 'lb') {
      var obj = {};
      const val = primaryValue / secondaryValue;

      obj = { //
        price: val / 0.0005,
        unit: 'ton',
      };
      seedConversions.push(obj);

      obj = { //
        price: val / 0.453592,
        unit: 'kg',
      };
      seedConversions.push(obj);

      obj = { //
        price: val / 0.0005,
        unit: 'metric ton',
      };
      seedConversions.push(obj);
    }

    // ton to lb, metric ton, kg
    if (secondaryUnit == 'ton') {
      var obj = {};
      const val = primaryValue / secondaryValue;

      obj = { //
        price: val / 2000,
        unit: 'lb',
      };
      seedConversions.push(obj);

      obj = { //
        price: val / 0.907185,
        unit: 'metric ton',
      };
      seedConversions.push(obj);

      obj = { //
        price: val / 907.185,
        unit: 'kg',
      };
      seedConversions.push(obj);
    }

    // kg to metric ton, lb, ton
    if (secondaryUnit == 'kg') {
      var obj = {};
      const val = primaryValue / secondaryValue;

      obj = { //
        price: val / 0.001,
        unit: 'metric ton',
      };
      seedConversions.push(obj);

      obj = { //
        price: val / 2.20462,
        unit: 'lb',
      };
      seedConversions.push(obj);

      obj = { //
        price: val / 0.00110231,
        unit: 'ton',
      };
      seedConversions.push(obj);
    }

    //
    // console.log("seedConversions", seedConversions)
    const modifiedReq = [];
    if (seedConversions.length > 0) {
      for (let i = 0; i < seedConversions.length; i++) {
        console.log('seedConversions[i]', seedConversions[i]);
        const obj = {
          isDeleted: 0,
          isOrganic: modifiyRequest.isOrganic,
          // isPreferred: (req.unit === 'K seeds' && seedConversions[i].unit === 'seeds' ) ? 1 : 0,
          isPreferred: 0,
          operationType: modifiyRequest.operationType,
          orgId: modifiyRequest.orgId,
          price: Number(seedConversions[i].price),
          productName: modifiyRequest.productName,
          unit: seedConversions[i].unit,
          unitBasis: '',
          unitBasisValue: 0,
          variety,
          year: Number(modifiyRequest.year),
        };
        modifiedReq.push(obj);
        // console.log(obj)
        if (obj.price !== Infinity) {
          await post(obj, 'SEEDING', false);
        }
      }
    }
    console.log('conversion reqs', modifiedReq);
  };

  const convertHarvestPrices = async (req, forDelete = false) => {
    console.log('convertHarvestPrices', req);
    const modifiyRequest = JSON.parse(JSON.stringify(req));
    const primaryUnit = modifiyRequest.unit;
    const primaryValue = modifiyRequest.price;
    const secondaryUnit = modifiyRequest.unitBasis;
    const secondaryValue = modifiyRequest.unitBasisValue;
    const crop = modifiyRequest.productName;

    const conversions = [];

    if (secondaryValue != 0) {
      var obj = {
        price: primaryValue / secondaryValue,
        unit: secondaryUnit,
      };
      conversions.push(obj);
    }

    if (primaryUnit == 'ton' && secondaryValue == 0) {
      var obj = {
        price: primaryValue / 2000,
        unit: 'lb',
      };
      conversions.push(obj);

      if (crop.toLowerCase().includes('corn') || (crop.toLowerCase().includes('bean') && crop.toLowerCase().includes('lima')) || crop.toLowerCase().includes('sorghum')
          || crop.toLowerCase().includes('flax') || crop.toLowerCase().includes('rye') || crop.toLowerCase().includes('triticale')) {
        var obj = {
          price: primaryValue / 35.714,
          unit: 'bu',
        };
        conversions.push(obj);
      } else if (crop.toLowerCase().includes('soybeans') || crop.toLowerCase().includes('bean') || crop.toLowerCase().includes('wheat') || crop.toLowerCase().includes('peas')
          || crop.toLowerCase().includes('chickpeas')) {
        var obj = {
          price: primaryValue / 33.33,
          unit: 'bu',
        };
        conversions.push(obj);
      } else if (crop.toLowerCase().includes('apple')) {
        var obj = {
          price: primaryValue / 50,
          unit: 'bu',
        };
        conversions.push(obj);
      } else if (crop.toLowerCase().includes('barley')) {
        var obj = {
          price: primaryValue / 41.66,
          unit: 'bu',
        };
        conversions.push(obj);
      } else if (crop.toLowerCase().includes('rice')) {
        var obj = {
          price: primaryValue / 44.44,
          unit: 'bu',
        };
        conversions.push(obj);
      } else if (crop.toLowerCase().includes('canola') || crop.toLowerCase().includes('mustard') || crop.toLowerCase().includes('canaryseed') || crop.toLowerCase().includes('camelina')) {
        var obj = {
          price: primaryValue / 40,
          unit: 'bu',
        };
        conversions.push(obj);
      }
    }

    // pound to ton, kg, metric ton
    if (primaryUnit == 'lb') {
      var obj = {};

      obj = { //
        price: primaryValue / 0.0005,
        unit: 'ton',
      };
      conversions.push(obj);

      if (secondaryValue !== 'kg') {
        obj = { //
          price: primaryValue / 0.453592,
          unit: 'kg',
        };
        conversions.push(obj);
      }

      obj = { //
        price: primaryValue / 0.0005,
        unit: 'metric ton',
      };
      conversions.push(obj);
    }

    // ton to lb, metric ton, kg
    if (primaryUnit == 'ton') {
      var obj = {};

      if (secondaryUnit !== 'lb') {
        obj = { //
          price: primaryValue / 2000,
          unit: 'lb',
        };
        conversions.push(obj);
      }

      obj = { //
        price: primaryValue / 0.907185,
        unit: 'metric ton',
      };
      conversions.push(obj);

      if (secondaryValue !== 'kg') {
        obj = { //
          price: primaryValue / 907.185,
          unit: 'kg',
        };
        conversions.push(obj);
      }
    }

    // kg to metric ton, lb, ton
    if (primaryUnit == 'kg') {
      var obj = {};

      obj = { //
        price: primaryValue / 0.001,
        unit: 'metric ton',
      };
      conversions.push(obj);

      if (secondaryUnit !== 'lb') {
        obj = { //
          price: primaryValue / 2.20462,
          unit: 'lb',
        };
        conversions.push(obj);
      }

      obj = { //
        price: primaryValue / 0.00110231,
        unit: 'ton',
      };
      conversions.push(obj);
    }

    // Secondary unit conversions
    // pound to ton, kg, metric ton
    if (secondaryUnit == 'lb') {
      const val = primaryValue / secondaryValue;
      var obj = {};

      obj = { //
        price: val / 0.0005,
        unit: 'ton',
      };
      conversions.push(obj);

      obj = { //
        price: val / 0.453592,
        unit: 'kg',
      };
      conversions.push(obj);

      obj = { //
        price: val / 0.0005,
        unit: 'metric ton',
      };
      conversions.push(obj);
    }

    // ton to lb, metric ton, kg
    if (secondaryUnit == 'ton') {
      var obj = {};
      const val = primaryValue / secondaryValue;

      obj = { //
        price: val / 2000,
        unit: 'lb',
      };
      conversions.push(obj);

      obj = { //
        price: val / 0.907185,
        unit: 'metric ton',
      };
      conversions.push(obj);

      obj = { //
        price: val / 907.185,
        unit: 'kg',
      };
      conversions.push(obj);
    }

    // kg to metric ton, lb, ton
    if (secondaryUnit == 'kg') {
      var obj = {};
      const val = primaryValue / secondaryValue;

      obj = { //
        price: val / 0.001,
        unit: 'metric ton',
      };
      conversions.push(obj);

      obj = { //
        price: val / 2.20462,
        unit: 'lb',
      };
      conversions.push(obj);

      obj = { //
        price: val / 0.00110231,
        unit: 'ton',
      };
      conversions.push(obj);
    }

    const modifiedReq = [];
    if (conversions.length > 0) {
      for (let i = 0; i < conversions.length; i++) {
        // console.log("conversions[i]", conversions[i])
        const obj = {
          isDeleted: 0,
          isOrganic: modifiyRequest.isOrganic,
          isPreferred: 0,
          operationType: modifiyRequest.operationType,
          orgId: modifiyRequest.orgId,
          price: Number(conversions[i].price),
          productName: modifiyRequest.productName,
          unit: conversions[i].unit,
          unitBasis: '',
          unitBasisValue: 0,
          variety: req.variety,
          year: Number(modifiyRequest.year),
        };
        modifiedReq.push(obj);
        // console.log(obj)
        if (obj.price !== Infinity) {
          await post(obj, 'HARVEST', false);
        }
      }
    }
    // console.log("modifiedReq", modifiedReq)
    // if(forDelete){
    //   return modifiedReq
    // }
  };

  const convertApplicationPrices = async (req) => {
    console.log('convertApplicationPrices', req);
    const modifiyRequest = JSON.parse(JSON.stringify(req));
    const primaryUnit = modifiyRequest.unit;
    const primaryValue = modifiyRequest.price;
    const secondaryUnit = modifiyRequest.unitBasis;
    const secondaryValue = modifiyRequest.unitBasisValue;
    const conversions = [];

    if (secondaryValue == 0) {
      if (primaryUnit == 'metric ton') {
        const kgtolb = (primaryValue / 1000) / 2.20462;
        const toKg = primaryValue / 1000;
        var obj = {};

        obj = {
          price: toKg,
          unit: 'kg',
        };
        conversions.push(obj);

        obj = {
          price: kgtolb,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1.10231,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 264.17,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1056.69,
          unit: 'qt',

        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1000,
          unit: 'l',

        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 35274,
          unit: 'oz',

        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 33814.02,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 2113.38,
          unit: 'pt',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'lb') {
        var obj = {};

        obj = {
          price: primaryValue / 0.9586,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0005,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 15.33,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 16,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.45,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.4793,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.1198,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.453592,
          unit: 'kg',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0005,
          unit: 'metric ton',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'ton') {
        var obj = {};

        obj = {
          price: primaryValue / 1917.2228,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 2000,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 30675.5654,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 32000,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 907.18,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 958.6116,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 239.6529,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.907185,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 907.185,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'floz') {
        var obj = {};

        obj = {
          price: primaryValue / 0.0625,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0652,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.00003,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1.0432,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0296,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.03125,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0078125,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.00003,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.03,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'oz') {
        var obj = {};

        obj = {
          price: primaryValue / 0.0599,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0625,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.00003125,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.9586,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0283,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0300,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0075,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0000283,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0283495,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'l') {
        var obj = {};

        obj = {
          price: primaryValue / 2.11338,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 2.2,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0011,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 33.814,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 35.2740,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1.05669,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.264,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0010,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'qt') {
        var obj = {};

        obj = {
          price: primaryValue / 2,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 2.0864,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0010,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 32,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 33.3816,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.94635,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.25,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0009463,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.9464,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'gal') {
        var obj = {};

        obj = {
          price: primaryValue / 8,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 8.35,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0042,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 128,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 133.5265,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 3.78541,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 4,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0038,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 3.79,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'kg') {
        var obj = {};

        obj = {
          price: primaryValue / 2.1134,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.001,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 2.20462,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.00110231,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 33.814,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 35.274,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.2641,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1.0567,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1,
          unit: 'l',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'pt') {
        var obj = {};

        obj = {
          price: primaryValue / 0.4732,
          unit: 'kg',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0004,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 1.0432,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.0005,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 16,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 16.6908,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.125,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.5,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: primaryValue / 0.4732,
          unit: 'l',
        };
        conversions.push(obj);
      }
    } else {
      const perUnitPrice = primaryValue / secondaryValue;

      if (secondaryUnit == 'kg') {
        var obj = {};

        obj = {
          price: perUnitPrice / 2.1134,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice,
          unit: secondaryUnit,
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 2.20462,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.001,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.00110231,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 33.814,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 35.274,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.2641,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1.0567,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1,
          unit: 'l',
        };
        conversions.push(obj);
      } else if (primaryUnit == 'ton') {
        var obj = {};
        if (secondaryUnit == 'gal') {
          obj = {
            price: perUnitPrice / 8,
            unit: 'pt',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice,
            unit: 'gal',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 8.3454,
            unit: 'lb',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 128,
            unit: 'floz',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 133.5265,
            unit: 'oz',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 3.78541,
            unit: 'l',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 4,
            unit: 'qt',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 0.0038,
            unit: 'metric ton',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 3.79,
            unit: 'kg',
          };
          conversions.push(obj);
        } else if (secondaryUnit == 'lb') {
          obj = {
            price: perUnitPrice / 0.9586,
            unit: 'pt',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice,
            unit: 'lb',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 15.33,
            unit: 'floz',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 16,
            unit: 'oz',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 0.45,
            unit: 'l',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 0.4793,
            unit: 'qt',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 0.1198,
            unit: 'gal',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 0.453592,
            unit: 'kg',
          };
          conversions.push(obj);

          obj = {
            price: perUnitPrice / 0.000453592,
            unit: 'metric ton',
          };
          conversions.push(obj);
        }
      } else if (secondaryUnit == 'lb') {
        var obj = {};

        obj = {
          price: perUnitPrice / 0.9586,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice,
          unit: secondaryUnit,
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0005,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 15.33,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 16,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.45,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.4793,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.1198,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.453592,
          unit: 'kg',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.000453592,
          unit: 'metric ton',
        };
        conversions.push(obj);
      } else if (secondaryUnit == 'floz') {
        var obj = {};

        obj = {
          price: perUnitPrice / 0.0625,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice,
          unit: secondaryUnit,
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0652,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.00003,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1.0432,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0296,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.03125,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0078125,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.00003,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0296,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (secondaryUnit == 'oz') {
        var obj = {};

        obj = {
          price: perUnitPrice / 0.0599,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice,
          unit: secondaryUnit,
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0625,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.00003125,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.9586,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0283,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.03,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0075,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0000283,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0283495,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (secondaryUnit == 'l') {
        var obj = {};
        obj = {
          price: perUnitPrice / 2.1134,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice,
          unit: secondaryUnit,
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 2.2,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0011,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 33.814,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 35.2740,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1.05669,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.264,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0010,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (secondaryUnit == 'qt') {
        var obj = {};

        obj = {
          price: perUnitPrice / 2,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice,
          unit: secondaryUnit,
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 2.0864,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0010,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 32,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 33.3816,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.94635,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.25,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.9464,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (secondaryUnit == 'gal') {
        var obj = {};

        obj = {
          price: perUnitPrice / 8,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice,
          unit: secondaryUnit,
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 8.35,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0042,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 128,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 133.5265,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 3.78541,
          unit: 'l',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 4,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0038,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 3.79,
          unit: 'kg',
        };
        conversions.push(obj);
      } else if (secondaryUnit == 'metric ton') {
        var obj = {};

        obj = {
          price: perUnitPrice / 2113.38,
          unit: 'pt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice,
          unit: secondaryUnit,
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1000,
          unit: 'kg',
        };
        conversions.push(obj);

        obj = {
          price: (perUnitPrice / 1000) / 2.20462,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1.10231,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 264.17,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1056.69,
          unit: 'qt',

        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1000,
          unit: 'l',

        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 35274,
          unit: 'oz',

        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 33814.02,
          unit: 'floz',
        };
        conversions.push(obj);
      } else if (secondaryUnit == 'pt') {
        var obj = {};

        obj = {
          price: perUnitPrice / 0.4732,
          unit: 'kg',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0004,
          unit: 'metric ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 1.0432,
          unit: 'lb',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.0005,
          unit: 'ton',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 16,
          unit: 'floz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 16.6908,
          unit: 'oz',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.125,
          unit: 'gal',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.5,
          unit: 'qt',
        };
        conversions.push(obj);

        obj = {
          price: perUnitPrice / 0.4732,
          unit: 'l',
        };
        conversions.push(obj);
      }
    }

    if (conversions.length > 0) {
      for (let i = 0; i < conversions.length; i++) {
        console.log('conversions[i]', conversions[i]);
        const obj = {
          isDeleted: 0,
          isOrganic: modifiyRequest.isOrganic,
          isPreferred: 0,
          operationType: modifiyRequest.operationType,
          orgId: modifiyRequest.orgId,
          price: Number(conversions[i].price),
          productName: modifiyRequest.productName,
          unit: conversions[i].unit,
          unitBasis: '',
          unitBasisValue: 0,
          variety,
          year: Number(modifiyRequest.year),
        };
        // modifiedReq.push(obj)
        console.log(obj);
        if (obj.price !== Infinity) {
          await post(obj, 'APPLICATION', false);
        }
      }
    }
  };

  const handleDelete = async (op) => {
    const url = `${Endpoints.BASEURL}${Endpoints.DELETEPRICE}${op.ID}`;
    let response;

    try {
      if (userToken !== '') {
        response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
            Authorization: `Bearer ${userToken}`,
          },
          credentials: 'include',
        });
      } else {
        response = await fetch(url, {
          method: 'POST',
          credentials: 'include',
          headers: {
            'Content-Type': 'application/json',
            Accept: 'application/json',
          },
        });
      }
      if (response.status === 200) {
        refreshPrices(selectedOrg);
      } else {
        console.log(`Failed to save: ${response.status}`);
      }
    } catch (error) {
      console.log('Error saving price', error);
    }
  };

  const refreshPrices = (orgId) => {
    switch (operationType) {
      case 'Harvest':
        setPlantingPrices(orgId, 'harvest');
        break;
      case 'Seeding':
        setPlantingPrices(orgId, 'seeding');
        break;
      case 'Application':
        setApplicationPrices(orgId);
        break;
      default:
        // Generic
        setGenericPrices(orgId);
    }
  };

  const orgSelection = () => (
    <Menu
      anchorEl={orgSelectionOpen}
      keepMounted
      open={Boolean(orgSelectionOpen)}
      onClose={() => setOrgSelectionOpen(null)}
    >
      <MenuItem
        onClick={() => handleOrgSelect('all')}
        style={{
          color: blackText,
          fontWeight: 500,
          borderBottom: '1px solid #767676',
        }}
      >
        {applyToOrgs.length === organizations.length ? (
          `Apply only to ${organization.Name}`
        ) : (
          <>
            <ArrowDownwardIcon style={{ fontSize: 14, marginRight: '4px' }} />
            Apply to all
          </>
        )}
      </MenuItem>
      {organizations.map((x, i) => (
        <MenuItem
          key={i}
          onClick={() => handleOrgSelect(x)}
          style={{
            color: applyToOrgs.includes(x) ? green : blackText,
            fontWeight: 500,
          }}
        >
          {x.Name}
        </MenuItem>
      ))}
    </Menu>
  );

  const handleOrgSelect = (org) => {
    if (org === 'all') {
      if (applyToOrgs.length === organizations.length) {
        // all already selected, revert to only original org
        setApplyToOrgs([organization]);
      } else {
        // set to all orgs
        setApplyToOrgs(organizations);
      }
    } else if (applyToOrgs.includes(org)) {
      // remove selected org
      const filteredOrgs = applyToOrgs.filter((x) => x.ID !== org.ID);
      setApplyToOrgs(filteredOrgs);
    } else {
      // add selected org
      setApplyToOrgs([...applyToOrgs, org]);
    }
  };

  const fieldSelection = () => (
    <Menu
      anchorEl={fieldSelectionOpen}
      keepMounted
      open={Boolean(fieldSelectionOpen)}
      onClose={() => setFieldSelectionOpen(null)}
    >
      <MenuItem
        onClick={() => handleFieldSelect('all')}
        style={{
          color: blackText,
          fontWeight: 500,
          borderBottom: '1px solid #767676',
        }}
      >
        {applyToFields.length === fields.length ? (
          `Apply only to ${currentField.Name}`
        ) : (
          <>
            <ArrowDownwardIcon style={{ fontSize: 14, marginRight: '4px' }} />
            Apply to all
          </>
        )}
      </MenuItem>
      {fields.map((x, i) => (
        <MenuItem
          key={i}
          onClick={() => handleFieldSelect(x)}
          style={{
            color: applyToFields.includes(x) ? green : blackText,
            fontWeight: 500,
          }}
        >
          {x.Name}
        </MenuItem>
      ))}
    </Menu>
  );

  const handleFieldSelect = (field) => {
    if (field === 'all') {
      if (applyToFields.length === fields.length) {
        // all already selected, revert to only original org
        setApplyToFields([currentField]);
      } else {
        // set to all orgs
        setApplyToFields(fields);
      }
    } else if (applyToFields.includes(field)) {
      // remove selected org
      const filteredFields = applyToFields.filter((x) => x.ID !== field.ID);
      setApplyToFields(filteredFields);
    } else {
      // add selected org
      setApplyToFields([...applyToFields, field]);
    }
  };

  const edits = () => (
    <Box
      p={2}
      style={{
        backgroundColor: '#ffffff',
        overflowY: 'auto',
        margin: '15px',
        alignItems: 'center',
      }}
    >
      <Box display="flex" justifyContent="space-between">
        <Box display="flex">
          <TextField
            onChange={(e) => setSearchFilter(e.target.value)}
            variant="outlined"
            value={searchFilter}
            InputLabelProps={{
              shrink: false,
            }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <SearchIcon style={{ fontSize: '16px', color: grey }} />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <CloseIcon style={{ fontSize: '16px', color: grey, cursor: 'pointer' }} onClick={() => setSearchFilter('')} />
                </InputAdornment>
              ),
            }}
            inputProps={{
              style: {
                padding: 10,
              },
            }}
            style={{ width: '240px', height: '40px' }}
          />
          <Box display="flex" style={{ alignItems: 'center', marginLeft: '8px' }}>
            Search by:
            <Select
              className={classes.filterSelect}
              variant="outlined"
              MenuProps={MenuProps}
              onChange={(e) => handleFilterChange(e.target.value)}
              value={filterType}
            >
              {
                (operationType.toUpperCase() === 'HARVEST' || operationType.toUpperCase() === 'SEEDING')
                  ? plantingFilterOptions.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x}
                    </MenuItem>
                  ))
                  : otherFilterOptions.map((x, i) => (
                    <MenuItem key={i} value={x}>
                      {x}
                    </MenuItem>
                  ))
              }
            </Select>
          </Box>
        </Box>

        <Box
          className={classes.add}
          border={1}
          borderRadius="borderRadius"
          onClick={() => setAdd(true)}
        >
          <AddIcon />
          {' '}
          Add
        </Box>
      </Box>

      <Box
        mt={1}
        display="flex"
        fontWeight={500}
        style={{ margin: '15px' }}
        fontSize={width > 560 ? 14 : 12}
      >
        <Box style={{ width: '10%' }}>
          Year
          {
            yearSorted === 'Ascending'
              ? <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={() => sortItems('Year', 'Descending')} />
              : <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={() => sortItems('Year', 'Ascending')} />
          }
        </Box>
        <Box style={{ width: '20%' }}>
          {
            (operationType.toUpperCase() === 'HARVEST' || operationType.toUpperCase() === 'SEEDING')
              ? 'Crop'
              : operationType.toUpperCase() === 'APPLICATION'
                ? 'Product'
                : 'Description'
          }
          {
            cropSorted == 'Ascending'
              ? <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={() => sortItems('Crop', 'Descending')} />
              : <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={() => sortItems('Crop', 'Ascending')} />
          }
        </Box>

        {(operationType.toUpperCase() === 'HARVEST' || operationType.toUpperCase() === 'SEEDING')
          && (
          <Box style={{ width: '20%' }}>
            Variety
            {
              varietySorted == 'Ascending'
                ? <ExpandLessIcon style={{ cursor: 'pointer' }} onClick={() => sortItems('Variety', 'Descending')} />
                : <ExpandMoreIcon style={{ cursor: 'pointer' }} onClick={() => sortItems('Variety', 'Ascending')} />
            }
          </Box>
          )}

        <Box style={{ width: '20%' }}>
          {
            width > 480 ? (
              'Price ($/Unit)'
            ) : (
              '($/Unit)'
            )
          }

        </Box>
        <Box style={{ width: '10%' }}>
          Edit
        </Box>
        <Box style={{ width: '10%' }}>
          Delete
        </Box>
      </Box>

      <Divider />

      <Box my={1}>
        {operationsList()}
      </Box>

      <Divider />
    </Box>
  );

  const operationsList = () => filteredOperations.map((x, i) => (
    <Box
      key={i}
      className={classes.operationsList}
      style={{ margin: '15px' }}
      fontSize={width > 560 ? 14 : width > 480 ? 12 : 10}
    >
      <Box style={{ width: '10%' }}>
        {x.year !== null ? x.year : x.cropSeason}
      </Box>
      <Box style={{ width: '20%' }}>
        {x.productName}
      </Box>

      {
          (operationType.toUpperCase() === 'HARVEST' || operationType.toUpperCase() === 'SEEDING')
          && (
          <Box style={{ width: '20%' }}>
            {x.variety}
          </Box>
          )
        }

      <Box style={{ width: '20%' }}>
        {x.price !== '' ? `$ ${parseFloat(Math.abs(Number(x.price).toFixed(5)))}${x.unit !== '' ? '/' : ''}${x.unit}` : '-'}
      </Box>

      <Box style={{ width: '10%' }}>
        <Tooltip
          title="Edit price, unit, and weight"
          placement="top"
        >
          <EditOutlinedIcon
            className={classes.icon}
            style={{ fontSize: 20 }}
            onClick={() => handleEdit(x)}
          />
        </Tooltip>

      </Box>

      <Box style={{ width: '10%' }}>
        {x.ID !== '' ? (
          <Tooltip
            title="Delete"
            placement="top"
          >
            <DeleteOutlineIcon
              className={classes.icon}
              style={{ fontSize: 20 }}
              onClick={() => handleDelete(x)}
            />
          </Tooltip>
        ) : ''}

      </Box>
    </Box>
  ));

  const addNew = () => (
    <Box
      className={classes.addNew}
    >
      <Box pb={1} fontWeight={600}>
        {`Add ${operationType}`}
      </Box>

      <Divider />

      <Box display="flex" mt={1}>
        <Box>
          <Box pb={1}>
            Year
          </Box>
          <TextField
            className={classes.input}
            variant="outlined"
            type="number"
            value={year}
            onChange={(e) => setYear(e.target.value)}
          />
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap">
        <Box mr={2} mt={1}>
          <Box pb={1}>
            {operationType.toUpperCase() === 'APPLICATION'
              ? 'Product'
              : operationType === 'Other Expenses/Revenue'
                ? 'Description'
                : 'Crop'}
          </Box>
          {
            (operationType.toUpperCase() === 'SEEDING' || operationType.toUpperCase() === 'HARVEST')
              ? (
                <Autocomplete
                  freeSolo
                  className={classes.autoComplete}
                  value={product}
                  onChange={(event, newValue) => setProduct(newValue)}
                  inputValue={cropInputValue}
                  onInputChange={(event, newInputValue) => handleCropChange(newInputValue)}
                  options={crops}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              )
              : operationType.toUpperCase() === 'APPLICATION'
                ? (
                  <Autocomplete
                    freeSolo
                    className={classes.autoComplete}
                    value={product}
                    onChange={(event, newValue) => setProduct(newValue)}
                    inputValue={chemicalInputValue}
                    onInputChange={(event, newInputValue) => handleProductInput(newInputValue)}
                    options={allChemicals}
                    renderInput={(params) => (
                      <TextField {...params} variant="outlined" />
                    )}
                    style={{ width: '200px' }}
                  />
                )
                : (
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    value={product}
                    onChange={(e) => setProduct(e.target.value)}
                  />
                )
          }
        </Box>

        {(operationType.toUpperCase() === 'SEEDING' || operationType.toUpperCase() === 'HARVEST') && (
          <Box mr={2} mt={1}>
            <Box pb={1}>Variety</Box>
            <Autocomplete
              className={classes.autoComplete}
              freeSolo
              value={variety}
              onChange={(event, newValue) => setVariety(newValue)}
              inputValue={varietyInputValue}
              onInputChange={(event, newInputValue) => handleVarietyInput(newInputValue)}
              options={allVarieties}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Box>
        )}

        <Box mr={2} mt={1}>
          <Box pb={1}>
            {operationType !== 'Other Expenses/Revenue'
              ? `Price ($/${capitalizeFirstLetter(unit)})`
              : 'Price ($/Acre)'}
          </Box>
          <TextField
            className={classes.input}
            variant="outlined"
            type="number"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
          />
        </Box>

        {operationType !== 'Other Expenses/Revenue' && (
          <Box mr={2} mt={1}>
            <Box pb={1}>Unit</Box>
            <Select
              className={classes.input}
              variant="outlined"
              value={unit}
              onChange={handleUnitChange}
              MenuProps={MenuProps}
            >
              {selectedUnits.map((x, i) => (
                <MenuItem key={i} value={x}>
                  {x}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>

      {(operationType.toUpperCase() === 'SEEDING' || operationType.toUpperCase() === 'HARVEST') && (
        <Box
          my={1}
          display="flex"
          alignItems="center"
          borderRadius="borderRadius"
          border={1}
          borderColor={grey}
          width={160}
          height={32}
          style={{ backgroundColor: '#ffffff' }}
        >
          <Checkbox
            color="primary"
            checked={organic}
            onChange={() => setOrganic(!organic)}
          />
          Organic
        </Box>
      )}

      {/* Harvest and seeding if bag or box is user needs to enter weight */}
      {needWeight() && (
        <Box>
          <Box display="flex" alignItems="center" fontWeight={600}>
            Edit Unit Details (Optional)
            <Tooltip
              placement="top"
              title="Define unit conversions, for example, if a bag is 80 lbs, you can define this here"
            >
              <InfoOutlinedIcon style={{ paddingLeft: '5px' }} />
            </Tooltip>

          </Box>

          <Divider />

          <Box display="flex" mt={1}>
            <Box>
              <Box pb={1}>Weight</Box>
              <TextField
                className={classes.input}
                variant="outlined"
                type="number"
                value={weight}
                onChange={(e) => setWeight(e.target.value)}
              />
            </Box>

            <Box ml={1}>
              <Box pb={1}>Unit</Box>
              <Select
                className={classes.input}
                variant="outlined"
                value={weightUnit}
                onChange={handleWeightUnitChange}
                MenuProps={MenuProps}
              >
                {weightUnits.map((x, i) => (
                  <MenuItem key={i} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>

        </Box>
      )}

      {operationType === 'Other Expenses/Revenue' && (
        <Box display="flex" mt={1}>
          <Box display="flex" alignItems="center">
            <Radio
              color="primary"
              checked={otherType === 'Cost'}
              onChange={() => setOtherType('Cost')}
              value="Cost"
              inputProps={{ 'aria-label': 'Cost' }}
            />
            <Box>Expense</Box>
          </Box>

          <Box ml={1} display="flex" alignItems="center">
            <Radio
              color="primary"
              checked={otherType === 'Revenue'}
              onChange={() => setOtherType('Revenue')}
              value="Revenue"
              inputProps={{ 'aria-label': 'Revenue' }}
            />
            <Box>Revenue</Box>
          </Box>

          <Box ml={1} display="flex" alignItems="center">
            <Radio
              color="primary"
              checked={otherType === "Field Pass"}
              onChange={() => setOtherType("Field Pass")}
              value="Field Pass"
              inputProps={{ "aria-label": "Field Pass" }}
            />
            <Box>Field Pass</Box>
          </Box>
        </Box>
      )}

      <Box mt={1}>
        <Box
          p={1}
          display="flex"
          alignItems="center"
          border={1}
          borderRadius="borderRadius"
          style={{
            backgroundColor: '#fff',
            width: '270px',
          }}
        >
          <Box mr={1}>Apply to below organizations</Box>
          <ArrowDropDownCircleIcon
            className={classes.dropDown}
            onClick={(e) => setOrgSelectionOpen(e.currentTarget)}
          />
        </Box>

        {orgSelection()}

        <Box
          my={1}
          display="flex"
          flexWrap="wrap"
          style={{
            overflowY: 'auto',
            maxHeight:
              operationType === 'Other Expenses/Revenue'
                ? '200px'
                : needWeight()
                  ? '80px'
                  : '150px',
          }}
        >
          {
            applyToOrgs.map((x, i) => (
              <Box
                key={i}
                p={1}
                border={1}
                borderRadius="borderRadius"
                fontWeight={500}
                color={blackText}
                style={{ backgroundColor: '#ffffff', margin: '3px' }}
              >
                {x.Name}
              </Box>
            ))
          }
        </Box>
      </Box>

      {!validInput
        && (
        <Box style={{ color: 'red' }}>
          Invalid Input, Please Enter Valid Information.
        </Box>
        )}

      <Box display="flex" my={2}>
        <Button
          variant="contained"
          color="primary"
          style={{ height: '40px', width: '77px', margin: '0 8px' }}
          onClick={() => saveNew()}
          disableElevation
        >
          Save
        </Button>

        <Button
          variant="outlined"
          color="primary"
          style={{ height: '40px', width: '77px', margin: '0 8px' }}
          onClick={() => setAdd(false)}
          disableElevation
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );

  return (
    <Container maxWidth="md" style={width < 580 ? { padding: 0 } : {}}>
      <Box className={classes.backButton}>
        {
          pageSource === 'FarmReport'
            ? (
              <Button
                className={classes.noBluehover}
                href="/app/FarmReport"
                variant="contained"
                color="primary"
              >
                Back To Farm Report
              </Button>
            )
            : (
              <Button
                className={classes.noBluehover}
                href="/app"
                variant="contained"
                color="primary"
              >
                Back To ProfitLayers
              </Button>
            )
        }

      </Box>

      <Box mt={1} className={classes.priceToolRoot} id="price-tool">
        {/* Header */}
        <Box className={classes.header}>
          <Box padding="16px 16px 8px 16px">
            <Box fontSize={20} color={darkText}>
              Price Converter
            </Box>
            <Box padding="8px 8px 8px 8px">
              Use the price converter to assign prices and units to individual operations. Start by selecting
              an organization and operation type below. Prices will be updated for all fields within the selected organization.
            </Box>
          </Box>

          <Box
            className={classes.dropdownBox}
            padding="12px 16px 12px 16px"
            style={{ display: width > mobileBreakPoint ? 'flex' : 'block' }}
          >
            <Box marginRight={2}>
              <Box mr={1}>Select organization</Box>
              <Select
                variant="outlined"
                className={classes.select}
                value={selectedOrg}
                onChange={handleOrgChange}
                MenuProps={MenuProps}
              >
                {organizations.map((x, i) => (
                  <MenuItem key={i} value={x.ID}>
                    {x.Name}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box>
              <Box mr={1}>Select Operation Type</Box>
              <Select
                variant="outlined"
                className={classes.select}
                value={operationType}
                onChange={handleOperationChange}
                MenuProps={MenuProps}
              >
                {options.map((x, i) => (
                  <MenuItem key={i} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>

        <Divider />

        <Box>
          {add ? addNew() : edits()}
        </Box>
      </Box>

      {/* Modals & stuff */}
      <Connect
        open={loginPromptOpen}
        setOpen={setLoginPromptOpen}
        message="Sign in and Connect Precision Ag"
        shouldForceLogIn
        signInFrom="SIGNINFROMAPP"
        signInParam="?source=/PriceConverter"
      />

    </Container>
  );
}

import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TableBody,
  TableContainer,
  Table,
  makeStyles, 
  Typography
} from "@material-ui/core";
import { COLORS, SORT, SORT_LOOKUP } from "../../../../constants";
import Row from "./FieldReportTableRow";
import Summary from "./FieldReportTableSummary";
import Header from "../../../Helpers/Table/Header";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    overflowX: "visible",
  },
  table: {
    minWidth: "100%",
    color: COLORS.text,
    borderCollapse: "collapse",
  },
  tableBody: {
    borderColor: COLORS.green,
    borderTop: "2px solid",
    borderBottom: "2px solid",
    "& .MuiTableRow-root": {
      "&:nth-of-type(odd)": {
        backgroundColor: COLORS.veryLightGrey,
      },
    },
  },
}));

/**
 * Renders the field report table
 * @param {object} headerCells  array of objects with metadata functions/information
 * to access row data for fields
 * @param {object} data  object with category and values properties
 */
const FieldReportTable = ({ data, headerCells }) => {
  const classes = useStyles();
  let { values } = data;

  const [state, setState] = useState({
    order: SORT.Asc,
    category: data.category,
    orderBy: headerCells[data.category][0].id,
  });

  useEffect(() => {
    setState({
      order: SORT.Asc,
      category: data.category,
      orderBy: headerCells[data.category][0].id,
    });
  }, [data]);

  const tableRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === SORT.Asc;
    const isSameIndicator = property === state.orderBy;
    setState({
      ...state,
      orderBy: property,
      order: isSameIndicator ? (isAsc ? SORT.Desc : SORT.Asc) : SORT.Asc,
    });
  };
  // Handle the sort, by the selected header
  const selectedHeader = headerCells[state.category].find(
    (d) => d.id === state.orderBy
  );
  values =
    selectedHeader !== undefined
      ? values.sort((a, b) => {
          const sortFn = SORT_LOOKUP.get(state.order);
          return sortFn(selectedHeader.accessor(a), selectedHeader.accessor(b));
        })
      : values;
      
  return (
    <TableContainer id="field-report-table" className={classes.container}>
      <Table className={classes.table} ref={tableRef}>
        <Header
          order={state.order}
          orderBy={state.orderBy}
          onRequestSort={handleRequestSort}
          headerCells={headerCells[state.category]}
        />
        <TableBody className={classes.tableBody}>
          {values.length > 0 ? values.map((row, i) => (
            <Row
              key={`field-table-row-for-${i}`}
              row={row}
              headerCells={headerCells[state.category]}
            />
          )) : <Typography style={{marginTop: "10px", marginBottom: "10px"}}>No Operation Found</Typography>}
        </TableBody>
        <Summary values={values} headerCells={headerCells[state.category]} />
      </Table>
    </TableContainer>
  );
};

export default FieldReportTable;

FieldReportTable.propTypes = {
  data: PropTypes.object.isRequired,
  headerCells: PropTypes.object.isRequired,
};

import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container, Box, 
} from '@material-ui/core';
import background from '../../images/tractors.jpg';
import { terms } from '../Shared/Vendors/TermsAndConditions';
import { lightGrey, blackText } from '../../styles/colors'


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
  },
  darken: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    backgroundColor: 'rgba(0,0,0,.6)',
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
  },
  special: {
    marginTop: 8,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: `2px solid ${theme.palette.greys.main}`,
    backgroundColor: theme.palette.greys.light,
    color: theme.palette.text.primary,
  },
}));

export function TermsAndConditions({setHomeSection}) {
  const classes = useStyles();

  useEffect(() =>{
    setHomeSection(-1);
  },[]);

  return (
    <Box className={classes.root}>
      <Box className={classes.darken}>
        <Container maxWidth="md" style={{ paddingTop: 16 }}>

          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              my={2}
              borderRadius="borderRadius"
              boxShadow={1}
              width="100%"
            >
              <Box
                p={2}
                display="flex"
                flexDirection="column"
                alignItems="center"
                flexGrow={1}
                style={{ backgroundColor: lightGrey, overflowY: 'auto' }}
                color={blackText}
                height={window.innerHeight - 120}
              >
                { terms() }
              </Box>
            </Box>
          </Box>

        </Container>
      </Box>
    </Box>
  );
}

/* eslint-disable max-len */
/* eslint-disable react/jsx-no-duplicate-props */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Modal, TextField, Select, Button, MenuItem, Typography, Divider, Radio,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { MenuProps } from '../../../styles/select';
import { cartDropdownData, additionalTextInputs, radioInputs } from '../presetData';
import { NumberFormatCustom, NumberFormatZero } from '../../../utils/NumberFormatCustom';

const useStyles = makeStyles((theme) => ({
  close: theme.close,
  option: {
    padding: '8px',
  },
  optionsRow: {
    display: 'flex',
    flexWrap: 'wrap',
    marginTop: '8px',
  },
  paper: {
    ...theme.centeredModal,
    width: window.innerWidth > 560 ? 520 : window.innerWidth - 20,
    maxHeight: window.innerHeight > 900 ? 880 : window.innerHeight - 20,
    padding: 8,
    overflowY: 'auto',
  },
  radioBox: {
    display: 'flex',
    border: '1px solid rgba(0, 0, 0, 0.23)',
    borderRadius: 4,
    padding: '8px 4px 8px 0px',
    width: '227px',
    height: '47px',
    justifyContent: 'space-around',
  },
  selects: {
    height: 47,
    width: 227,
    backgroundColor: '#ffffff',
  },
}));

/**
 * User will be prompted to provide an explanation for change when certain values are updated
 * @param {String} gartPath Denotes whether this interface instance is for creating GART files
 * @param {Function} inclusionControl Decides whether to show icon to add or remove element to CLUs
 * @param {Object} indexes Indexes needed for updating operation
 * @param {Array} mismatchOptions Options for displaying mismatches between CLUs and operation
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Object} operation Operation being updated
 * @param {Function} setOpen Sets if modal is open
 * @param {Function} updateOperation Updates operation on user input change
 * @return {JSX} Modal for change explanation
 */
export const AdvancedOptions = ({
  gartPath,
  inclusionControl,
  indexes,
  mismatchOptions,
  open,
  operation,
  setOpen,
  updateOperation,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { farmIndex, fieldIndex, opIndex } = indexes;

  // For displaying mismatches between CLUs and operation
  const [displayMarker, greyedOut, white] = mismatchOptions;

  // Track states independently in case we want to only call update on save or combine updates
  // Would need to set these with onChange and only call updateOperation if user clicks save
  const [micsCode, setMicsCode] = useState(operation.micsCode);
  const [micsName, setMicsName] = useState(operation.micsName);
  const [skipRowPatternCode, setSkipRowPatternCode] = useState(operation?.skipRowPatternCode ? operation.skipRowPatternCode : '');
  const [cropRowCount, setCropRowCount] = useState(operation?.cropRowCount ? operation.cropRowCount : '');
  const [cropRowWidth, setCropRowWidth] = useState(operation?.cropRowWidth ? operation.cropRowWidth : '');
  const [skipRowCount, setSkipRowCount] = useState(operation?.skipRowCount ? operation.skipRowCount : '');
  const [skipRowWidth, setSkipRowWidth] = useState(operation?.skipRowWidth ? operation.skipRowWidth : '');
  const [skipRowConversionFactor, setSkipRowConversionFactor] = useState(
    operation?.skipRowConversionFactor ? operation?.skipRowConversionFactor : '',
  );
  const [productPlantingCode, setProductPlantingCode] = useState(operation?.productPlantingCode ? operation.productPlantingCode : '');
  const [cluReviewRequested, setCluReviewRequested] = useState(operation.cluProducerReviewRequestIndicator);
  const [includeGeospatialIndicator, setIncludeGeospatialIndicator] = useState(operation.includeGeospatialInReport);

  // Decide what should be the state of the skip row elements based on passed code
  const decideDisabledState = (code) => {
    if (code === '99') {
      return false;
    }
    if (!cartDropdownData.skipRowPatternCodes.specificWidths.has(code) && code !== displayMarker) {
      return 'butWidth';
    }
    // Return true if code is one of these: ['01', '02', '03', '11'] that do not allow any modification
    // or is displayMarker - this should not be necessary as they won't show if code is displayMarker (but it doesn't hurt)
    else {
      return true;
    }
  }

  // This needs above function to be above it to work
  // Controls what is shown to the user as editable
  const [disableElements, setDisableElements] = useState(decideDisabledState(operation?.skipRowPatternCode));

  // Handlers for updating micsCode and micsName values
  const updateMicsCode = (code) => {
    setMicsCode(code);
    updateOperation(farmIndex, fieldIndex, opIndex, [{ micsCode: code }]);
  };

  const updateMicsName = (name) => {
    // The following cannot be done here onBlur (as housing component is not a dropdown so needs to happen onChange) (and as does not use NumberFormat so we need to track state ourselves)
    // setMicsName(name);
    if (validInput(name)) {
      updateOperation(farmIndex, fieldIndex, opIndex, [{ micsName: name }]);
    } else {
      setMicsName(operation.micsName);
    }
  };

  // Handler for updating skipRowPatternCode value
  const updateSkipRowPatternCode = (code) => {
    setSkipRowPatternCode(code);
    updateOperation(farmIndex, fieldIndex, opIndex, [{ skipRowPatternCode: code }]);

    // Just skip if empty - no more updates necessary, disable could be set to true but elements are hidden anyway
    if (code === '') {
      return;
    }
    // If not empty, check what should be disabled
    const state = decideDisabledState(code);
    setDisableElements(state);
    // Only stop here if code is '99', so no need to change the other values
    if (!state) {
      return;
    }

    // Get the appropriate mappings
    const updateMappings = cartDropdownData.skipRowPatternCodes.mappings[code];
    // Update these associated state variables whenever skipRowPatternCode changes
    setCropRowCount(updateMappings.cropRowCount);
    setCropRowWidth(updateMappings.cropRowWidth);
    setSkipRowCount(updateMappings.skipRowCount);
    setSkipRowWidth(updateMappings.skipRowWidth);
    setSkipRowConversionFactor(updateMappings.skipRowConversionFactor);
  };

  /**
   * This function validates the passed input.
   * First paramater is number or string to check.
   * If input is a number, second param is min value it
   * can be equal to and third param is max value it can be equal to.
   * Will return true if first param meets conditions.
   *
   * We want to check here (instead of updateOperation) as no cloneDeep is performed
   * and helps with having correct values for internal state vars.
   *
   * @param {Number|String} input Value to check
   * @param {Number} min Min value input can be equal to
   * @param {Number} max Max value input can be equal to
   * @returns {Bool} If number is valid
   */
  const validInput = (input, min, max) => {
    try {
      // For micsName specifically
      if (input === '') {
        // empty string should cause invalid message
        enqueueSnackbar('MICS Name cannot be blank.');
        // enqueueSnackbar(`Please enter a valid MICS Name`);
        return false;
      }
      if (typeof input === 'string') {
        return true;
      }

      // For skip row options, valid input will be between or equal to min and max
      if (input >= min && input <= max) {
        return true;
      }
      enqueueSnackbar(`Please enter a value between ${min} and ${max}`);
      return false;
    } catch (err) {
      enqueueSnackbar(`Please enter a value between ${min} and ${max}`);
      return false;
    }
  };

  // Handlers for updating the rest of the skip row options' values
  const handleCropRowCountChange = (count) => {
    if (
      validInput(
        count,
        additionalTextInputs.cropRowCount.allowedValues.min,
        additionalTextInputs.cropRowCount.allowedValues.max,
      )
    ) {
      setCropRowCount(count);
      updateOperation(farmIndex, fieldIndex, opIndex, [{ cropRowCount: count }]);
    }
  };

  const handleCropRowWidthChange = (width) => {
    // Pick the right min and max
    let min;
    let max;
    if (disableElements === 'butWidth') {
      min = additionalTextInputs.cropRowWidth.restrictedValues.min;
      max = additionalTextInputs.cropRowWidth.restrictedValues.max;
    } else {
      min = additionalTextInputs.cropRowWidth.allowedValues.min;
      max = additionalTextInputs.cropRowWidth.allowedValues.max;
    }

    if (
      validInput(
        width,
        min,
        max,
      )
    ) {
      setCropRowWidth(width);
      updateOperation(farmIndex, fieldIndex, opIndex, [{ cropRowWidth: width }]);
    }
  };

  const handleSkipRowCountChange = (count) => {
    if (
      validInput(
        count,
        additionalTextInputs.skipRowCount.allowedValues.min,
        additionalTextInputs.skipRowCount.allowedValues.max,
      )
    ) {
      setSkipRowCount(count);
      updateOperation(farmIndex, fieldIndex, opIndex, [{ skipRowCount: count }]);
    }
  };

  const handleSkipRowWidthChange = (width) => {
    // Pick the right min and max
    let min;
    let max;
    if (disableElements === 'butWidth') {
      min = additionalTextInputs.skipRowWidth.restrictedValues.min;
      max = additionalTextInputs.skipRowWidth.restrictedValues.max;
    } else {
      min = additionalTextInputs.skipRowWidth.allowedValues.min;
      max = additionalTextInputs.skipRowWidth.allowedValues.max;
    }

    if (
      validInput(
        width,
        min,
        max,
      )
    ) {
      setSkipRowWidth(width);
      updateOperation(farmIndex, fieldIndex, opIndex, [{ skipRowWidth: width }]);
    }
  };

  const handleConversionFactorChange = (factor) => {
    if (
      validInput(
        factor,
        additionalTextInputs.skipRowConversionFactor.allowedValues.min,
        additionalTextInputs.skipRowConversionFactor.allowedValues.max,
      )
    ) {
      setSkipRowConversionFactor(factor);
      updateOperation(farmIndex, fieldIndex, opIndex, [{ skipRowConversionFactor: factor }]);
    }
  };

  // Handlers for updating productPlantingCode, cluProducerReviewRequestIndicator, and includeGeospatialInReport values
  const updateProductPlantingCode = (code) => {
    setProductPlantingCode(code);
    updateOperation(farmIndex, fieldIndex, opIndex, [{ productPlantingCode: code }]);
  };

  const updateReviewRequested = (val) => {
    setCluReviewRequested(val);
    updateOperation(farmIndex, fieldIndex, opIndex, [{ cluProducerReviewRequestIndicator: val }]);
  };

  const updateIncludeGeospatial = (val) => {
    setIncludeGeospatialIndicator(val);
    updateOperation(farmIndex, fieldIndex, opIndex, [{ includeGeospatialInReport: val }]);
  };

  // Decides whether the background for this item should be greyed out or white
  const displayBackground = (input) => (input === displayMarker ? greyedOut : white);

  // Product Planting Code Box. This is reused so split out of options for simplicity
  const productPlantingBox = () => (
    <Box className={classes.option}>
      <Box display="flex">
        <span style={{ marginRight: 4 }}>{cartDropdownData.plantingCodes.label}</span>
        { inclusionControl('plantingCodes') }
      </Box>

      <Select
        displayEmpty
        variant="outlined"
        MenuProps={MenuProps}
        value={productPlantingCode}
        className={classes.selects}
        onChange={(e) => updateProductPlantingCode(e.target.value)}
        style={{ background: displayBackground(productPlantingCode) }}
      >
        {
          cartDropdownData.plantingCodes.options.map((x) => (
            <MenuItem
              key={x.code}
              value={x.code}
            >
              {x.display}
            </MenuItem>
          ))
        }
      </Select>
    </Box>
  );

  const options = () => (
    <>
      {/* MICS Code and MICS Name */}
      <Box className={classes.optionsRow}>
        <Box className={classes.option}>
          <Box display="flex">
            <span style={{ marginRight: 4 }}>{cartDropdownData.micsCodes.label}</span>
            { inclusionControl('micsCodes') }
          </Box>

          <Select
            displayEmpty
            variant="outlined"
            MenuProps={MenuProps}
            value={micsCode}
            className={classes.selects}
            onChange={(e) => updateMicsCode(e.target.value)}
            style={{ background: displayBackground(micsCode) }}
          >
            {
              cartDropdownData.micsCodes.options.map((x) => (
                <MenuItem
                  key={x.code}
                  value={x.code}
                >
                  {x.display}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        {/* Only if code is other */}
        { micsCode === 'O' && (
          <Box className={classes.option}>
            <Box display="flex">
              <span style={{ marginRight: 4 }}>MICS Name</span>
              { inclusionControl('micsName') }
            </Box>

            <TextField
              variant="outlined"
              value={micsName === displayMarker ? '' : micsName}
              onChange={(e) => setMicsName(e.target.value)}
              onBlur={(e) => updateMicsName(e.target.value)}
              inputProps={{
                style: {
                  padding: 14,
                },
              }}
              style={{ background: displayBackground(micsName) }}
            />
          </Box>
        )}
      </Box>

      {/* Skip row pattern code and ( Skip Row Conversion Factor or Product Planting) */}
      <Box className={classes.optionsRow}>
        <Box className={classes.option}>
          <Box display="flex">
            <span style={{ marginRight: 4 }}>{cartDropdownData.skipRowPatternCodes.label}</span>
            { inclusionControl('skipRowPatternCodes') }
          </Box>

          <Select
            displayEmpty
            variant="outlined"
            MenuProps={MenuProps}
            value={skipRowPatternCode}
            className={classes.selects}
            onChange={(e) => updateSkipRowPatternCode(e.target.value)}
            style={{ background: displayBackground(skipRowPatternCode) }}
          >
            {
              cartDropdownData.skipRowPatternCodes.options.map((x) => (
                <MenuItem
                  key={x.code}
                  value={x.code}
                >
                  {x.display}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        {/* Show Planting Code box here instead if skipRowPatternCode is '' or displayMarker */}
        { !['', displayMarker].includes(skipRowPatternCode) ? (
          <Box className={classes.option}>
            <Box display="flex">
              <span style={{ marginRight: 4 }}>Skip Row Conversion Factor</span>
              { inclusionControl('skipRowConversionFactor') }
            </Box>

            <TextField
              variant="outlined"
              value={skipRowConversionFactor}
              // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
              onBlur={(e) => handleConversionFactorChange(Number(e.target.value.replaceAll(',', '')))}
              inputProps={{
                style: {
                  padding: 14,
                  width: '174px',
                },
              }}
              InputProps={{
                inputComponent: NumberFormatCustom,
                endAdornment: '%',
              }}
              disabled={!!disableElements}
              style={{ background: displayBackground(skipRowConversionFactor) }}
            />
          </Box>
        ) : (
          productPlantingBox()
        )}
      </Box>

      {/* The next three rows (Skip row and Product Planting) are shown only if skipRowPatternCode is not N/A or displayMarker */}
      { !['', displayMarker].includes(skipRowPatternCode) && (
        <>
          <Box className={classes.optionsRow}>
            <Box className={classes.option}>
              <Box display="flex">
                <span style={{ marginRight: 4 }}>Crop Row Count</span>
                { inclusionControl('cropRowCount') }
              </Box>

              <TextField
                variant="outlined"
                value={cropRowCount}
                // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                onBlur={(e) => handleCropRowCountChange(Number(e.target.value.replaceAll(',', '')))}
                inputProps={{
                  style: {
                    padding: 14,
                  },
                }}
                InputProps={{
                  inputComponent: NumberFormatZero,
                }}
                disabled={!!disableElements}
                style={{ background: displayBackground(cropRowCount) }}
              />
            </Box>

            <Box className={classes.option}>
              <Box display="flex">
                <span style={{ marginRight: 4 }}>Crop Row Width</span>
                { inclusionControl('cropRowWidth') }
              </Box>

              <TextField
                variant="outlined"
                value={cropRowWidth}
                // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                onBlur={(e) => handleCropRowWidthChange(Number(e.target.value.replaceAll(',', '')))}
                inputProps={{
                  style: {
                    padding: 14,
                    width: '174px',
                  },
                }}
                InputProps={{
                  inputComponent: NumberFormatZero,
                  endAdornment: 'in',
                }}
                disabled={!((disableElements === 'butWidth' || !disableElements))}
                style={{ background: displayBackground(cropRowWidth) }}
              />
            </Box>
          </Box>

          <Box className={classes.optionsRow}>
            <Box className={classes.option}>
              <Box display="flex">
                <span style={{ marginRight: 4 }}>Skip Row Count</span>
                { inclusionControl('skipRowCount') }
              </Box>

              <TextField
                variant="outlined"
                value={skipRowCount}
                // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                onBlur={(e) => handleSkipRowCountChange(Number(e.target.value.replaceAll(',', '')))}
                inputProps={{
                  style: {
                    padding: 14,
                  },
                }}
                InputProps={{
                  inputComponent: NumberFormatZero,
                }}
                disabled={!!disableElements}
                style={{ background: displayBackground(skipRowCount) }}
              />
            </Box>

            <Box className={classes.option}>
              <Box display="flex">
                <span style={{ marginRight: 4 }}>Skip Row Width</span>
                { inclusionControl('skipRowWidth') }
              </Box>

              <TextField
                variant="outlined"
                value={skipRowWidth}
                // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                onBlur={(e) => handleSkipRowWidthChange(Number(e.target.value.replaceAll(',', '')))}
                inputProps={{
                  style: {
                    padding: 14,
                    width: '174px',
                  },
                }}
                InputProps={{
                  inputComponent: NumberFormatZero,
                  endAdornment: 'in',
                }}
                disabled={!((disableElements === 'butWidth' || !disableElements))}
                style={{ background: displayBackground(skipRowWidth) }}
              />
            </Box>
          </Box>

          <Box className={classes.optionsRow}>
            {productPlantingBox()}
          </Box>
        </>
      )}

      {/* Include CLU geospatial boundary and related info in report? and Request CLU review options */}
      {!gartPath
        && (
        <Box className={classes.optionsRow}>
          <Box className={classes.option}>
            <Box display="flex">
              <span style={{ marginRight: 4 }}>{radioInputs.includeGeospatial.title}</span>
              { inclusionControl('includeGeospatial') }
            </Box>
            <Box
              className={classes.radioBox}
              style={{ background: displayBackground(includeGeospatialIndicator) }}
            >
              <Box display="flex" alignItems="center">
                <Radio
                  color="primary"
                  checked={includeGeospatialIndicator === 'Y'}
                  onChange={() => updateIncludeGeospatial('Y')}
                  value="a"
                  name="radio-button-include"
                  inputProps={{ 'aria-label': 'Y' }}
                  style={{ padding: '0 4px' }}
                />
                <Box>{radioInputs.includeGeospatial.options[0].display}</Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Radio
                  color="primary"
                  checked={includeGeospatialIndicator === 'N'}
                  onChange={() => updateIncludeGeospatial('N')}
                  value="a"
                  name="radio-button-include"
                  inputProps={{ 'aria-label': 'N' }}
                  style={{ padding: '0 4px' }}
                />
                <Box>{radioInputs.includeGeospatial.options[1].display}</Box>
              </Box>
            </Box>
          </Box>

          <Box className={classes.option}>
            <Box display="flex">
              <span style={{ marginRight: 4 }}>{radioInputs.reviewRequested.title}</span>
              { inclusionControl('reviewRequested') }
            </Box>
            <Box
              className={classes.radioBox}
              style={{ background: displayBackground(cluReviewRequested) }}
            >
              <Box display="flex" alignItems="center">
                <Radio
                  color="primary"
                  checked={cluReviewRequested === 'Y'}
                  onChange={() => updateReviewRequested('Y')}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'Y' }}
                  style={{ padding: '0 4px' }}
                />
                <Box>{radioInputs.reviewRequested.options[0].display}</Box>
              </Box>
              <Box display="flex" alignItems="center">
                <Radio
                  color="primary"
                  checked={cluReviewRequested === 'N'}
                  onChange={() => updateReviewRequested('N')}
                  value="a"
                  name="radio-button-demo"
                  inputProps={{ 'aria-label': 'N' }}
                  style={{ padding: '0 4px' }}
                />
                <Box>{radioInputs.reviewRequested.options[1].display}</Box>
              </Box>
            </Box>
          </Box>
        </Box>
        )}
    </>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="change-explanation"
      aria-describedby="explain-reason-data-was-changed"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
      >

        <HighlightOffIcon
          className={classes.close}
          onClick={() => { setOpen(false); }}
        />

        <Typography variant="h6" align="center">
          Advanced Options
        </Typography>

        <Divider />

        {options()}

        <Box display="flex" justifyContent="center">
          <Box>
            <Button
              color="primary"
              variant="contained"
              onClick={() => setOpen(false)}
              disableElevation
            >
              Close
            </Button>
          </Box>
        </Box>

      </Box>
    </Modal>
  );
};

AdvancedOptions.propTypes = {
  gartPath: PropTypes.bool.isRequired,
  inclusionControl: PropTypes.func.isRequired,
  indexes: PropTypes.shape({
    fieldIndex: PropTypes.number.isRequired,
    farmIndex: PropTypes.number.isRequired,
    opIndex: PropTypes.number.isRequired,
  }).isRequired,
  mismatchOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  open: PropTypes.bool.isRequired,
  operation: PropTypes.shape({
    acres: PropTypes.number.isRequired,
    crop: PropTypes.string.isRequired,
    crops: PropTypes.string.isRequired,
    croppingPracticeCode: PropTypes.number.isRequired,
    cropRowCount: PropTypes.number,
    cropRowWidth: PropTypes.number,
    cropType: PropTypes.string.isRequired,
    cropTypes: PropTypes.string.isRequired,
    CLUInfo: PropTypes.arrayOf(PropTypes.shape({
      shown: PropTypes.bool.isRequired,
      zoneID: PropTypes.string.isRequired,
    })),
    cluProducerReviewRequestIndicator: PropTypes.string,
    error: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
    finalReportedAcreage: PropTypes.number,
    includeGeospatialInReport: PropTypes.string.isRequired,
    intendedUse: PropTypes.string.isRequired,
    intendedUses: PropTypes.string.isRequired,
    irrigationPracticeCode: PropTypes.string.isRequired,
    micsCode: PropTypes.string.isRequired,
    micsName: PropTypes.string.isRequired,
    operationID: PropTypes.string.isRequired,
    organicPracticeTypeCode: PropTypes.string.isRequired,
    productPlantingCode: PropTypes.string,
    reportedAcreageModifiedIndicator: PropTypes.string,
    sharePercentage: PropTypes.number.isRequired,
    shown: PropTypes.bool,
    skipRowConversionFactor: PropTypes.number,
    skipRowCount: PropTypes.number,
    skipRowWidth: PropTypes.number,
    skipRowPatternCode: PropTypes.string,
    stateCode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    varietyList: PropTypes.string.isRequired,
    varietyNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    year: PropTypes.number.isRequired,
    zoneID: PropTypes.string,
    zoneIDsFromDB: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  setOpen: PropTypes.func.isRequired,
  updateOperation: PropTypes.func.isRequired,
};

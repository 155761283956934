export const reports = [
  {
    name: 'Sub-Surface Design & Cost Estimate',
    type: 'report',
    description: [
      'A farm specific full-scale sub-surface drainage design and industry standard estimate',
    ],
    included: [],
    optional: [],
    price: 5,
    value: 5,
    discount: 0,
  },
  {
    name: 'Surface Design & Cost Estimate',
    type: 'report',
    description: [
      'A farm-specific full-scale surface drainage design and industry standard estimate',
    ],
    included: [],
    optional: [],
    price: 5,
    value: 5,
    discount: 0,
  },
  {
    name: 'Topographic Map',
    type: 'report',
    description: [
      'Elevations and contours determined using DIGS topography',
    ],
    included: [],
    optional: [],
    price: 5,
    value: 5,
    discount: 0,
  },
  {
    name: 'Watershed Assessment',
    type: 'report',
    description: [
      `The identification of outlets and neighboring watershed stakeholders.
      DIGS topography is leveraged to determine cost-share opportunities
      and holistic analysis`,
    ],
    included: [],
    optional: [],
    price: 5,
    value: 5,
    discount: 0,
  },
  {
    name: 'Remediation Response Analysis',
    type: 'report',
    description: [
      `Field-specific return on investment realized through proprietary
       analytical approaches correlating DIGS topography, soil science,
       hydrological analysis, flow accumulation, spatial yield data, and
       historical/predictive weather modeling`,
    ],
    included: [],
    optional: [],
    price: 10,
    value: 10,
    discount: 0,
  },
  {
    name: 'DIGS Facilitation',
    type: 'report',
    description: [
      `Professional design and project implementation by convening neighboring
      stakeholders for a precise engineered solution, cost-share analysis,
      and bid solicitation for a one-time project`,
    ],
    included: [],
    optional: [],
    price: 15,
    value: 15,
    discount: 0,
  },
  {
    name: 'Irrigation Services',
    type: 'report',
    description: [
      'A farm-specific full-scale irrigation design and industry standard estimate',
    ],
    included: [],
    optional: [],
    price: 25,
    value: 25,
    discount: 0,
  },
];

export const bundles = [
  {
    name: 'Engineering Due Diligence',
    type: 'bundle',
    description: [
      'Sub-Surface Design & Cost Estimate OR Surface Design & Cost Estimate',
      'Watershed Assessment',
    ],
    included: ['Watershed Assessment'],
    optional: [
      'Sub-Surface Design & Cost Estimate',
      'Surface Design & Cost Estimate',
    ],
    value: 10,
    discount: 2,
    price: 8,
    remediationPrice: 8,
  },
  {
    name: 'Watershed Facilitation',
    type: 'bundle',
    description: [
      'Sub-Surface Design & Cost Estimate OR Surface Design & Cost Estimate',
      'Watershed Assessment',
      'DIGS Facilitation',
    ],
    included: ['Watershed Assessment', 'DIGS Facilitation'],
    optional: [
      'Sub-Surface Design & Cost Estimate',
      'Surface Design & Cost Estimate',
    ],
    value: 25,
    discount: 5,
    price: 20,
    remediationPrice: 8,
  },
  {
    name: 'Holistic Drainage Services',
    type: 'bundle',
    description: [
      'Sub-Surface Design & Cost Estimate',
      'Surface Design & Cost Estimate',
      'Watershed Assessment',
      'Topographic Map',
      'DIGS Facilitation',
    ],
    included: [
      'Sub-Surface Design & Cost Estimate',
      'Surface Design & Cost Estimate',
      'Watershed Assessment',
      'Topographic Map',
      'DIGS Facilitation',
    ],
    optional: [],
    value: 35,
    discount: 10,
    price: 25,
    remediationPrice: 8,
  },
  {
    name: 'Comprehensive Water Management Services',
    type: 'bundle',
    description: [
      'Sub-Surface Design & Cost Estimate',
      'Surface Design & Cost Estimate',
      'Watershed Assessment',
      'Topographic Map',
      'DIGS Facilitation',
      'Irrigation Services',
    ],
    included: [
      'Sub-Surface Design & Cost Estimate',
      'Surface Design & Cost Estimate',
      'Watershed Assessment',
      'Topographic Map',
      'DIGS Facilitation',
      'Irrigation Services',
    ],
    optional: [],
    value: 60,
    discount: 10,
    price: 50,
    remediationPrice: 6,
  },
];

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Select, MenuItem, TextField } from '@material-ui/core';
import { CustomAutocomplete } from '../../../utils/customComponents';
import { MenuProps } from '../../../styles/select';
import { createYearArray, exists } from '../../../utils/helpers';
import { blackText, darkText, grey } from '../../../styles/colors';


const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  option: {
    color: blackText,
    fontWeight: 500,
  },
  boxLabel: theme.boxLabel,
  inputName: {
    height: 25,
    whiteSpace: 'nowrap'
  }
}));


export function GeneralSettings({
  handleChange,
  commodityYear,
  crops,
  crop,
  disableUserInputs
}) {
  const classes = useStyles();
  const commodityYears = createYearArray(20, true);

  const [inputValue, setInputValue] = useState('')

  useEffect(() => {
    if (crop?.Name) {
      setInputValue(crop.Name)
    } else {
      setInputValue('')
    }
  }, [crop])

  return (
    <Box
      mx={1}
      display='flex'
      flexDirection='column'
      color={blackText}
      fontWeight={500}
    >
      <Box
        color={grey}
        className={classes.boxLabel}
      >
        General
      </Box>
      <Box
        p={1}
        display="flex"
        border={1}
        borderRadius="borderRadius"
        borderColor={grey}
        minHeight={98}
        paddingTop="13px"
      >
        <Box mr={2}>
          <Box className={classes.inputName}>
            Commodity Year
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={commodityYear}
            onChange={(e) => handleChange(e.target.value, 'year')}
            disabled={(!disableUserInputs || disableUserInputs === 'ButYear' || disableUserInputs === 'ButYearAndCrop') ? false : true}
          >
            {
              commodityYears.map((year, i) => (
                <MenuItem
                  key={i}
                  value={year}
                >
                  {year}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box>
          <Box className={classes.inputName}>
            Crop
          </Box>

          <CustomAutocomplete
            style={{ width: 200 }}
            options={crops}
            getOptionLabel={(option) => option.Name}
            renderInput={(params) => <TextField {...params} variant="outlined" />}
            onChange={(e, val) => handleChange(val, 'crop')}
            value={crop}
            inputValue={inputValue}
            onInputChange={(event, newInputValue) => {
              setInputValue(newInputValue);
            }}
            disableClearable={true}
            disabled={(!disableUserInputs || disableUserInputs === 'ButYearAndCrop') ? false : true}
          />

        </Box>

      </Box>
    </Box>
  );
}

import _ from "lodash";

export const seedingUnits = [
  "seeds",
  "K seeds",
  "pound",
  "unit",
  "bag",
  "bulk bag",
  "bulk box",
  "mini bulk bag",
  "bushel",
  "metric ton"
];

export const harvestUnits = [
  "bushel",
  "pound",
  "ton",
  "kilogram",
  "bale",
  "bale, net",
  "bale, gross",
  "gallon",
  "cwt",
  "other",
  "bag"
];

export const costUnits = [
  "pound",
  "K seeds",
  "seeds",
  "unit",
  "bag",
  "bulk bag",
  "bulk box",
  "mini bulk bag",
  "gallon",
  "fluid ounce",
  "ounce",
  "liter",
  "quart",
  "pint",
  "pound",
  "kilogram",
  "ton",
  "other"
]

export const units = [
  "gallon",
  "fluid ounce",
  "ounce",
  "liter",
  "quart",
  "pint",
  "pound",
  "kilogram",
  "ton",
  "metric ton",
  // "jug",
  // "box",
  // "container",
  // "bulk",
  // "minibulk",
  // "drum",
  // "tote",
];

export const unitConversion = (unit, abrev = false) => {
  /* If unit in conversions, return value, else return unit.
       Optionally pass true to abrev to invert conversions key value pairs.
    */
  const conversions = {
    bushel: "bu",
    pound: "lb",
    gallon: "gal",
    kilogram: "kg",
    "bale, net": "bale,net",
    "bale, gross": "bale,gross",
    "fluid ounce": "floz",
    ounce: "oz",
    liter: "l",
    quart: "qt",
    pint: "pt",
    inch: "in",
    feet: "ft",
    acre: "ac",
    //gallons: 'gal'
  };
  const units = abrev ? _.invert(conversions) : conversions;
  return units[unit] !== undefined ? units[unit] : unit;
};

// convert this to switch that takes a type
export const formatDate = (date) => {
  /* Converts date (mm/dd/yyyy) stored in operations to format material ui
       TextField can display (yyyy-mm-dd), date from TextField (yyyy-mm-dd)
       to format operations tables expects (mm/dd/yyyy),
       and new Date() to yyyy-mm-dd.
    */
  if (date[2] === "/") {
    let a = date.split("/");
    return `${a[2]}-${a[0]}-${a[1]}`;
  } else if (date[4] === "-") {
    let b = date.split("-");
    return `${b[1]}/${b[2]}/${b[0]}`;
  } else if (typeof date === "object") {
    // new Date()
    return new Date().toISOString().substr(0, 19).split("T")[0];
  } else {
    console.log("unexpected date format", date, typeof date);
    return date;
  }
};

export const formatForProfitMap = (op) => {
  // reset operation to format from database
  switch (op.fileType.toUpperCase()) {
    case "SEEDING":
      let seeding = { ...op };
      seeding.seedingOperationsID = op.operationID;
      seeding.seedingLoggedID = op.logID;
      seeding.totalMaterial = op.quantity;
      seeding.totalMaterialUnit = op.unit;
      seeding.cropName = op.productName;
      seeding.name = op.variety;
      return seeding;
    case "HARVEST":
      let harvest = { ...op };
      harvest.harvestOperationID = op.operationID;
      harvest.harvestLoggedID = op.logID;
      harvest.yield = op.quantity;
      harvest.yieldUnit = op.unit;
      harvest.cropName = op.productName;
      harvest.name = op.variety;
      return harvest;
    case "APPLICATION":
      let application = { ...op };
      application.areaResult = op.area;
      application.applicationLoggedID = op.loggedID;
      application.applicationOperationID = op.operationID;
      application.totalMaterialResult = op.quantity;
      application.totalMaterialUnit = op.unit;
      application.name = op.productName;
      application.totalMaterialResult = op.quantity;
      application.totalMaterialUnit = op.unit;
      application.price = isNaN(op.price) || op.price === null ? 1 : op.price;
      return application;
    default:
      console.log(`unexpected operation type: ${op.fileType}`);
  }
};

// const deleteUnusedKeys = (op) => {
//   // delete keys created for re-use not wanted in Profit Map, return object
//   delete op.uid;
//   delete op.operationID;
//   delete op.quantity;
//   delete op.unit;
//   delete op.productName;
//   delete op.variety;
//   return op;
// };

export const convertOperationVarietiesForEdit = (operationRecord) => {
  const formattedVariety = {
    date: "",
    area: 0,
    cellsize: "0.0001",
    fileType: "",
    isIrrigated: false,
    isOrganic: false,
    productName: "",
    operationCost: 0,
    operationID: "",
    price: 0,
    rate: 0,
    source: "USERENTERED",
    total: 0,
    unit: "",
    variety: "",
  };

  let converted = [];
  for (const opVar of operationRecord.Operation_Record.varietyList) {
    let fCopy = { ...formattedVariety };
    fCopy.date = operationRecord.Operation_Record.StartDate.split("-").join(
      "/"
    );
    fCopy.area = opVar.Area;
    fCopy.fileType = operationRecord.Operation_Type;
    fCopy.productName = operationRecord.Operation_Record.CropName;
    fCopy.operationID = operationRecord.Operation_Record.ID;
    fCopy.rate =
      operationRecord.Operation_Type === "Harvest"
        ? parseFloat(opVar.AverageYield).toFixed(2)
        : opVar.AverageMaterialResult;
    fCopy.total =
      operationRecord.Operation_Type === "Harvest"
        ? opVar.Yield
        : opVar.TotalMaterialResult;
    fCopy.unit =
      operationRecord.Operation_Type === "Harvest"
        ? unitConversion(opVar.YieldUnit, true)
        : unitConversion(opVar.TotalMaterialResultUnit, true);
    fCopy.variety = opVar.Name;

    converted.push(fCopy);
  }
  return converted;
};

// export const generateLogId = (operationIds) => {
//   /* Generate random three digit number. Return if not matching id
//        in operations, otherwise try again
//     */
//   let id = Math.floor(Math.random() * 300) + 1;
//   if (operationIds.includes(id)) {
//     generateLogId(operationIds);
//   } else {
//     return id;
//   }
// };

//dictionary with units needed for conversion
//formatted as applicationConversion['inputUnit']['targetUnitUnit'] = conversion amount
export const applicationConversions = {
  "fluid ounce": {
    "fluid ounce": 1,
    gallon: 0.0078125,
    ounce: 1.043175552,
    liter: 0.0295735,
    quart: 0.03125,
    pint: 0.0652,
    pound: 0.065198472,
    kilogram: 0.0296,
    ton: 0.00003,
    "metric ton": 0.0000272
  },
  gallon: {
    gallon: 1,
    "fluid ounce": 128,
    ounce: 133.526471232,
    liter: 3.78541,
    quart: 4,
    pint: 8,
    pound: 8.345404452,
    kilogram: 3.78541,
    ton: 0.004172702226,
    "metric ton": 0.003786
  },
  ounce: {
    ounce: 1,
    gallon: 0.007489,
    "fluid ounce": 0.9586,
    liter: 0.0283,
    quart: 0.0300,
    pint: 0.0599,
    pound: 0.0625,
    kilogram: 0.0283495,
    ton: 0.00003125,
    "metric ton": 0.00002835
  },
  liter: {
    liter: 1,
    gallon: 0.264,
    "fluid ounce": 33.814,
    ounce: 35.2740,
    quart: 1.05669,
    pint: 2.11338,
    pound: 2.2,
    kilogram: 1,
    ton: 0.0011,
    "metric ton": 0.0009981
  },
  quart: {
    quart: 1,
    gallon: 0.25,
    "fluid ounce": 32,
    ounce: 33.3816,
    liter: 0.94635,
    pint: 2.00,
    pound: 2.0864,
    kilogram: 0.9464,
    ton: 0.0010,
    "metric ton": 0.0009466
  },
  pint: {
    pint: 1,
    gallon: 0.125,
    "fluid ounce": 16,
    ounce: 16.6908,
    liter: 0.4732,
    quart: 0.500,
    pound: 1.0432,
    kilogram: 0.473176475,
    ton: 0.0005,
    "metric ton": 0.0004733
  },
  pound: {
    pound: 1,
    gallon: 0.1198,
    "fluid ounce": 15.3377827,
    ounce: 16,
    liter: 0.45359237,
    quart: 0.479305709,
    pint: 0.9586,
    kilogram: 0.453592,
    ton: 0.0005,
    "metric ton": 0.0004537
  },
  kilogram: {
    kilogram: 1,
    gallon: 0.2642,
    "fluid ounce": 33.814,
    ounce: 35.274,
    liter: 1,
    quart: 1.0567,
    pint: 2.1134,
    pound: 2.20462,
    ton: 0.00110231,
    "metric ton": 0.001
  },
  ton: {
    ton: 1,
    gallon: 239.6529,
    "fluid ounce": 30675.5654,
    ounce: 32000,
    liter: 907.1800,
    quart: 958.6116,
    pint: 1917.2228,
    pound: 2000,
    kilogram: 907.185,
    "metric ton": 0.907
  },
  "metric ton": {
    ton: 1.102,
    gallon: 264.172,
    "fluid ounce": 33814.016,
    ounce: 35274,
    liter: 1000,
    quart: 1056.69,
    pint: 2113.38,
    pound: 2204.62,
    kilogram: 1000,
    "metric ton": 1
  }
};

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

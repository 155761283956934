export const combineVarities = (varieties) => {
  const correct = [];

  const matchesFound = [];
  for (const variety of varieties) {
    const varMatches = varieties.filter((x) => x.variety === variety.variety);

    if (
      varMatches.length > 1
      && matchesFound.indexOf(varMatches[0].variety) === -1
    ) {
      matchesFound.push(varMatches[0].variety);

      let totalAcre = 0;
      let totalMaterial = 0;
      let totalPrice = 0;
      for (const match of varMatches) {
        totalAcre += parseFloat(match.area);
        totalMaterial += parseFloat(match.quantity);
        totalPrice += parseFloat(match.price);
      }

      const newVariety = { ...varMatches[0] };
      newVariety.area = totalAcre;
      newVariety.quantity = totalMaterial / varMatches.length;
      newVariety.price = totalPrice / varMatches.length;
      correct.push(newVariety);
    } else if (matchesFound.indexOf(varMatches[0].variety) === -1) {
      correct.push(variety);
    }
  }
  return correct;
};

import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Box, Button, Typography, Paper, Link, Modal, Divider, AppBar, TextField, FormLabel, RadioGroup, Radio, FormControlLabel,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card,
  CardActionArea, CardContent, Grid, Select, MenuItem, FormControl, InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Endpoints } from '../../constants/Endpoints'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  grey,
  lightGrey,
  darkGrey,
  green,
  blackText,
  darkText,
} from "../../styles/colors";
import * as d3 from 'd3-geo'
import * as wkt from 'terraformer-wkt-parser';
import { CheckBox, SettingsOverscanRounded } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export function ConservationPractices({ field, nrcsList, crpList, cspList, otherList, waterMgmtInfo, updateWaterMgmt }) {

  /**
   * Update Field Information
   * @param {Object} field selected field data
   * @param {Object} fieldInformation field information of field selected
   * @param {Function} deleteField deletes field from fieldManager.Fields table (sets isDeleted flag to 1)
   * @param {Function} updateField updates field information
   * @param {Array} fields list all fields associated to selected org and farm
   * @param {Function} handleFieldSelect set new field selected
   */

  // console.log("field", field)
  // console.log("fieldInformation", fieldInformation)

  const classes = useStyles();
  const [nrcs, setNRCS] = useState('no')
  const [csp, setCSP] = useState('no')
  const [crp, setCRP] = useState('no')
  const [other, setOther] = useState('no')
  const [shownrcs, setshownrcs] = useState(false)
  const [showcsp, setshowcsp] = useState(false)
  const [showcrp, setshowcrp] = useState(false)
  const [showOther, setShowOther] = useState(false)
  const [selectednrcs, setSelectednrcs] = useState([])
  const [selectedcrp, setSelectedcrp] = useState([])
  const [selectedcsp, setSelectedcsp] = useState([])
  const [selectedother, setSelectedother] = useState([])
  const [finalInfo, setFinalInfo] = useState({})

  useEffect(() => {
    console.log('waterMgmtInfo', waterMgmtInfo)
    if (waterMgmtInfo.length > 0) {
      let waterInfo = waterMgmtInfo.length > 1 ? waterMgmtInfo[1] : waterMgmtInfo[0]
      setFinalInfo(waterInfo)
      if (waterInfo.isNrcs == null || waterInfo.isNrcs == 0 || waterInfo.isNrcs == false) {
        handleNRCS('no')
      }
      else {
        handleNRCS('yes')
        let list = waterInfo.nrcsList.split(",")
        for (let i = 0; i < list.length; i++) {
          list[i] = list[i].trim()
        }
        setSelectednrcs(list)
      }

      if (waterInfo.isCrp == null || waterInfo.isCrp == 0 || waterInfo.isCrp == false) {
        handleCRP('no')
      }
      else {
        handleCRP('yes')
        let list = waterInfo.crpList.split(",")
        for (let i = 0; i < list.length; i++) {
          list[i] = list[i].trim()
        }
        setSelectedcrp(list)
      }

      if (waterInfo.isCsp == null || waterInfo.isCsp == 0 || waterInfo.isCsp == false) {
        handleCSP('no')
      }
      else {
        handleCSP('yes')
        let list = waterInfo.cspList.split(",")
        for (let i = 0; i < list.length; i++) {
          list[i] = list[i].trim()
        }
        setSelectedcsp(list)
      }

      if (waterInfo.isOther == null || waterInfo.isOther == 0 || waterInfo.isOther == false) {
        handleOther('no')
      }
      else {
        handleOther('yes')
        let list = waterInfo.conservationPractices.split(",")
        for (let i = 0; i < list.length; i++) {
          list[i] = list[i].trim()
        }
        setSelectedother(list)
      }
    }
  }, [waterMgmtInfo])

  const handleNRCS = (val) => {
    setNRCS(val)
    if (val == 'yes') {
      setshownrcs(true)
    }
    else {
      setshownrcs(false)
    }
  }

  const handleCSP = (val) => {
    setCSP(val)
    if (val == 'yes') {
      setshowcsp(true)
    }
    else {
      setshowcsp(false)
    }
  }

  const handleCRP = (val) => {
    setCRP(val)
    if (val == 'yes') {
      setshowcrp(true)
    }
    else {
      setshowcrp(false)
    }
  }

  const handleOther = (val) => {
    setOther(val)
    if (val == 'yes') {
      setShowOther(true)
    }
    else {
      setShowOther(false)
    }
  }

  const confirmUpdate = () => {
    console.log("confirmUpdate")

    var isnrcs = (nrcs == 'yes') ? 1 : 0;

    var iscsp = (csp == 'yes') ? 1 : 0;

    var iscrp = (crp == 'yes') ? 1 : 0;

    var isunfunded = (other == 'yes') ? 1 : 0;

    var request = {
      fieldInstanceId: field.id,
      fieldWatershed: finalInfo.fieldWatershed !== undefined ? finalInfo.fieldWatershed : "",
      isIrrigated: finalInfo.isIrrigated !== undefined ? Number(finalInfo.isIrrigated) : 0,
      fieldIrrigationMethod: finalInfo.fieldIrrigationMethod !== undefined ? finalInfo.fieldIrrigationMethod : "",
      irrgationSource: finalInfo.irrgationSource !== undefined ? finalInfo.irrgationSource : "",
      pam: finalInfo.pam !== undefined ? Number(finalInfo.pam) : 0,
      energySource: finalInfo.energySource !== undefined ? finalInfo.energySource : "",
      isDrainage: finalInfo.isDrainage !== undefined ? Number(finalInfo.isDrainage) : 0,
      drainageType: finalInfo.drainageType !== undefined ? finalInfo.drainageType : "",
      fieldTiled: finalInfo.fieldTiled !== undefined ? Number(finalInfo.fieldTiled) : 0,
      useDrainage: finalInfo.useDrainage !== undefined ? Number(finalInfo.useDrainage) : 0,
      isNrcs: isnrcs,
      nrcsList: selectednrcs.join(','),
      isCrp: iscrp,
      crpList: selectedcrp.join(','),
      isCsp: iscsp,
      cspList: selectedcsp.join(','),
      isOther: isunfunded,
      conservationList: selectedother.join(','),
      waterCost: finalInfo.waterCost !== undefined ? finalInfo.waterCost : 0,
      waterCostUnit: finalInfo.waterCostUnit !== undefined ? finalInfo.waterCostUnit : "",
      maintenanceCost: finalInfo.maintenanceCost !== undefined ? finalInfo.maintenanceCost : 0,
      OrganizationID: field.orgId
    }

    updateWaterMgmt(request)
  }

  return (
    <Box my={1} style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center">
        <Typography variant="h6" style={{ color: green }}>Conservation Practices</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Is this field enrolled in NRCS program</FormLabel>
            <RadioGroup value={nrcs} onChange={(e) => handleNRCS(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!shownrcs}>
          <Box mx={2} my={1}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">List the funded conservation practices</InputLabel>
              <Select
                id="months-mutiple"
                multiple
                value={selectednrcs}
                onChange={(e) => setSelectednrcs(e.target.value)}
                style={{ minWidth: "350px" }}
                label="Funded Conservation Practices" helperText="Select funded conservation practices"
              >
                {
                  nrcsList.map((x, i) => (
                    <MenuItem key={i} value={x.name}>
                      {x.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Is this field enrolled in CSP program</FormLabel>
            <RadioGroup value={csp} onChange={(e) => handleCSP(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showcsp}>
          <Box mx={2} my={1}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">List the funded conservation practices</InputLabel>
              <Select
                id="months-mutiple"
                multiple
                value={selectedcsp}
                onChange={(e) => setSelectedcsp(e.target.value)}
                style={{  minWidth: "350px" }}
                label="Funded Conservation Practices" helperText="Select funded conservation practices"
              >
                {
                  cspList.map((x, i) => (
                    <MenuItem key={i} value={x.name}>
                      {x.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Is this field enrolled in CRP program</FormLabel>
            <RadioGroup value={crp} onChange={(e) => handleCRP(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showcrp}>
          <Box mx={2} my={1}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">List the funded conservation practices</InputLabel>
              <Select
                id="months-mutiple"
                multiple
                value={selectedcrp}
                onChange={(e) => setSelectedcrp(e.target.value)}
                style={{ minWidth: "350px" }}
                label="Funded Conservation Practices" helperText="Select funded conservation practices"
              >
                {
                  crpList.map((x, i) => (
                    <MenuItem key={i} value={x.name}>
                      {x.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Other conservation practices?</FormLabel>
            <RadioGroup value={other} onChange={(e) => handleOther(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showOther}>
          <Box mx={2} my={1}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">List your independent conservation practices</InputLabel>
              <Select
                id="months-mutiple"
                multiple
                value={selectedother}
                onChange={(e) => setSelectedother(e.target.value)}
                style={{  minWidth: "350px" }}
                label="Unfunded Conservation Practices" helperText="Select unfunded conservation practices"
              >
                {
                  otherList.map((x, i) => (
                    <MenuItem key={i} value={x.name}>
                      {x.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex"> {/** edit buttons */}
          <Box mx={2} my={1}>
            <Button variant="contained" color="primary" onClick={() => confirmUpdate()} disableElevation>Save Changes</Button>
          </Box>
        </Box>

      </Box>
    </Box>
  )
}

import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// Map imports
import * as L from 'leaflet';

// Functions
import { drawBasicMap } from '../MapFunctions/drawMap';
import { createGeoFromBoundary } from '../MapFunctions/helpers';

import { zoneColors } from '../Styles/layerStyles';

/**
 * Takes a Field and draws associated boundaries
 * @param {Object} field Field info, including operation boundaries
 * @returns {JSX} map
 */
export function FieldMap({ field }) {
  const generateID = () => { return Math.floor(Math.random() * Math.floor(10000)).toString(); }
  const mapId = useRef(generateID());
  const [map, setMap] = useState(null);
  const [drawn, setDrawn] = useState(false);
  const operationLayer = useRef(L.featureGroup(null));

  useEffect(() => {
    if (map === null) {
      try {
        drawBasicMap(setMap, operationLayer.current, mapId.current);
      } catch (error) {
        // Incase generate duplicate ID
        // NOTE: I think there is a try-catch in leaflet for this case that will just cause this catch to never occur
        mapId.current = generateID();
      }
    }
  }, [mapId, map]);

  useEffect(() => {
    // console.log('(field && map && !drawn) :>> ', (field && map && !drawn));
    if (field && map && !drawn) {
      setDrawn(true);

      // Add operation boundaries
      field.forEach((operation, idx) => {
        const geo = createGeoFromBoundary(operation.boundary);
        const collection = L.geoJson(geo, {
          onEachFeature: (feature, layer) => {
            const color = zoneColors[idx];
            const weight = 2 + idx;
            layer.setStyle({
              color,
              weight,
              opacity: 1,
              fillOpacity: 0.35,
            });
            operationLayer.current.addLayer(layer);
            // const toolTip = `<div style="height: 30; width: 30;"><div style="margin: 2px 6px;">Subfield: ${subField.subfieldNumber}</div></div>`;
            // layer.bindTooltip(toolTip, { className: 'leaftletTooltipClass' });
          },
        });
        collection.addTo(map);
      });
      
      // Fit map to bounds of drawn shapes
      map.fitBounds(operationLayer.current.getBounds(), {
        padding: [30, 30],
      });
    }
  }, [field, map, drawn]);

  return (
    <Box
      id={`${mapId.current}`}
      height="100%"
      width="100%"
    />
  );
}

FieldMap.propTypes = {
  field: PropTypes.array.isRequired,
};

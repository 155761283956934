import React from "react";
import { scaleLinear } from "d3-scale";
import Axes from "../../../../Helpers/BaseChart/Axes";
import FieldMarker from "../../FieldMarker";
import { extent } from "d3-array";
import PolygonHull from "./PolygonHull";
import { calculateLayout } from "../../../../../utils";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../../constants";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: "12px",
    stroke: "none",
    fill: COLORS.text,
  },
  line: {
    strokeWidth: "1px",
    stroke: COLORS.lightGray,
  },
}));

const Beeswarm = ({
  innerWidth,
  innerHeight,
  setState,
  points,
  radius,
  state,
  firstVariableOption,
  nameAccessor = (d) => d.name,
  xAccessor = (d) => d.x,
  yAccessor = (d) => d.y,
  isLastChart = true,
}) => {
  const classes = useStyles();
  const xScale = scaleLinear()
    .range([0, innerWidth])
    .domain(extent(points, xAccessor))
    .nice();
  const { activeMarker } = state;

  // Run through the group of points through the force-directed layout, while keeping the x position fixed
  const items = calculateLayout(
    points.map((point) => ({
      ...point,
      x: xScale(xAccessor(point)),
      y: innerHeight / 2,
      fx: xScale(xAccessor(point)),
      r: radius,
      originalX: xAccessor(point),
    }))
  );

  let firstItem = items[0];

  return (
    <g className="beeswarm__plot">
      <Axes
        height={innerHeight}
        width={innerWidth}
        xScale={xScale}
        showXAxisLabel={true}
        xAxisLabel={firstVariableOption.label}
        showYAxis={false}
        showSimpleXAxis={true}
        isLastChart={isLastChart}
      />
      <line
        className={classes.line}
        x1={0}
        x2={innerWidth}
        y1={innerHeight / 2}
        y2={innerHeight / 2}
      />
      <PolygonHull
        values={items}
        radius={10}
        color={firstItem.color}
        xAccessor={xAccessor}
        yAccessor={yAccessor}
      />
      {items.map((point, i) => {
        return (
          <FieldMarker
            key={`field-marker-for-${point.name}-${point.variety}-${point.year}-${i}`}
            marker={{
              ...point,
              isActive:
                !activeMarker ||
                nameAccessor(activeMarker) === nameAccessor(point),
            }}
            radius={radius}
            setState={setState}
            xAccessor={xAccessor}
            yAccessor={yAccessor}
            onlyOneVariable={true}
          />
        );
      })}
    </g>
  );
};

export default Beeswarm;

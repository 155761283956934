import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Grid, Box, Typography, Paper, Divider, Link } from '@material-ui/core/';
import { connectPrecision } from '../Partners/Connect'
import { Ksu } from './KSU'
import { Lsu } from './LSU'
import { MachineLearning } from './MachineLearning'
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import ksu_logo from '../../images/ksu_logo.jpg'
import profit_example from '../../images/profit_example.png'
import lsu_logo from '../../images/AgCenterLogo.png'
import { useWindowDimensions } from '../../utils/dimensions'

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    backgroundColor: '#fcfcfc',
    paddingTop: 16,
  },
  logo: {
    width: '100px',
    '&:hover': {
       cursor: 'pointer'
    },
  },
  title: {
    fontSize: '18px',
    '&:hover': {
       cursor: 'pointer'
    },
  },
  twitterIcon: {
    height: '100px',
    width: '100px',
    margin: '20px 0',
    color: '#1DA1F2',
    '&:hover': {
       color: '#1565c0',
       cursor: 'pointer'
    },
  },
  facebookIcon: {
    height: '100px',
    width: '100px',
    margin: '20px 0',
    color: '#4267B2',
    '&:hover': {
       color: '#1565c0',
       cursor: 'pointer'
    },
  },
  linkedinIcon: {
    height: '100px',
    width: '100px',
    margin: '20px 0',
    color: '#0e76a8',
    '&:hover': {
       color: '#1565c0',
       cursor: 'pointer'
    },
  },
}))

export function Press() {
  const classes = useStyles()
  const {height, width} = useWindowDimensions()

  useEffect(() => {
    let url = window.location.href
    let splitUrl = url.toUpperCase().split('PRESS')
    if (splitUrl.length > 1 && splitUrl[1].length > 1) {
      const initial = initialDisplay(splitUrl[1])
      setDisplay(initial)
    }
    socialFeed()
  },[])

  const initialDisplay = (extension) => {
    switch (extension) {
      case '/MACHINELEARNING':
        return 1
      case '/LSU':
        return 2
      default:
        return 0
    }
  }


  const ksu_release = {
    release: Ksu,
    title: 'Ag-Analytics and Kansas State University Announce Research Data Partnership',
    date: 'June 22, 2020',
    image: ksu_logo,
    summary: `Ag-Analytics and Associate Professors Mykel Taylor and Nathan Hendricks of Kansas State University’s
              Department of Agricultural Economics are pleased to announce the establishment of a research partnership.`
  }

  const ml_release = {
    release: MachineLearning,
    title: "Ag-Analytics to Release Several Machine Learning Driven Environmental APIs on Azure, Supported by Microsoft's AI for Earth Program",
    date: 'July 24, 2019',
    image: profit_example,
    summary: `Ag-Analytics, through a new strategic partnership with Microsoft’s AI for Earth program,
              today announced the launch of several new APIs and data feeds for public use,
              including mirroring over 100 TB of Harmonized Landsat Sentinel-2 (HLS) data on Azure.`
  }

  const lsu_release = {
    release: Lsu,
    title: 'Ag-Analytics and LSU AgCenter Partnership Brings AI and Machine Learning to Yield Predictions',
    date: 'June 20, 2019',
    image: lsu_logo,
    summary: `The Ag-Analytics cloud platform makes big data and machine learning applications in agriculture
              a reality for universities by securely connecting farmers with researchers and extension.`
  }

  const releases = [ksu_release, ml_release, lsu_release]
  const [display, setDisplay] = useState(0)

  const releaseSummary = (release, i) => (
    <Box key={i} p={2} mb={1} mx={1} boxShadow={1} style={{width: '450px', backgroundColor: '#ffffff'}}>
      <Box display='flex' alignItems='center'>
        <Box mr={1} style={{width: '100px'}}>
          <img className={classes.logo} src={release.image} onClick={() => setDisplay(i)}/>
          <Typography variant='subtitle2'>{release.date}</Typography>
        </Box>
        <Box>
          <Typography className={classes.title} variant='h6' onClick={() => setDisplay(i)}>{release.title}</Typography>
        </Box>
      </Box>

      <Box>
        <Typography variant='body1'>{release.summary}</Typography>
      </Box>
    </Box>
  )

  const socialFeed = () => {
    var i, e, d = document, s = "script"; i = d.createElement("script"); i.async = 1;
    i.src = "https://cdn.curator.io/published/637dee79-720a-4899-8aaa-3dee503f0c37.js";
    e = d.getElementsByTagName(s)[0]; e.parentNode.insertBefore(i, e);
  }

  return (
    <Grid container className={classes.root}>
      <Grid item lg={4}>
        <Box ml={1} display="flex" flexDirection="column" alignItems='center' justifyContent='center' style={{width: width < 1280 ? width - 30 : '100%'}}>
          <Box display="flex" mb={1} flexDirection="column" alignItems='center'>
            <Typography variant='h4'>PRESS & MEDIA</Typography>
            <Typography variant='h6' style={{color: '#1b1b1b'}}>Check out what's new at Ag-Analytics</Typography>
          </Box>
          <Box display='flex' flexWrap='wrap'>
            {releases.map((release, i) => releaseSummary(release, i))}
          </Box>

        </Box>
      </Grid>
      <Grid item lg={8}>
        <Paper style={{margin: '15px', paddingBottom: '50px'}}>
          {releases[display].release()}
        </Paper>
      </Grid>
      <Grid item xs={12}>
        <Box display='flex' justifyContent='center'>
          <Link href='https://twitter.com/aganalytics' target="_blank"><TwitterIcon className={classes.twitterIcon} /></Link>
          <Link href='https://www.facebook.com/agriculturalanalytics/' target="_blank"><FacebookIcon className={classes.facebookIcon}/></Link>
          <Link href='https://www.linkedin.com/company/agriculture-analytics/' target="_blank"><LinkedInIcon  className={classes.linkedinIcon}/></Link>
        </Box>
        
        <Box id="curator-feed" style={{width: '80%', margin: 'auto'}}>
          <a href="https://curator.io" target="_blank" className="crt-logo crt-tag">Powered by Curator.io</a>
        </Box>
        {connectPrecision()}
      </Grid>
    </Grid>
  )
}

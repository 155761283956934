import * as turf from '@turf/turf';
import * as L from 'leaflet';
import { createGeoFromBoundary, makeFeature, getRandomColor } from './helpers';
import { cloneDeep } from "lodash";

import {
  rotateIcon, horizontalIcon, verticalIcon, acresIcon,
} from '../Styles/icons';

export const createBoundingBox = (
  geo,
  EWoffset,
  NSoffset,
  baseOffset = 0.005,
) => {
  const bbox = turf.bbox(geo);
  bbox[0] += -baseOffset + EWoffset;
  bbox[1] += -baseOffset + NSoffset;
  bbox[2] += baseOffset + EWoffset;
  bbox[3] += baseOffset + NSoffset;

  return bbox;
};

/**
 * If its the first time drawing the grid, create the dictionary. Otherwise, use
 * selected array, with features updated from squareGrid.
 * @param {Number} zoneNum    Set to 1 on first render
 * @param {Array} squareGrid  Turf square grid from bounding box and grid size
 * @param {Array} selected    Zones selected
 * @param {Bool} firstRender  Check condition to use squareGrid or selected
 * @return {Array} Data of selected tiles
 */
export const getSelectedTilesData = (
  zoneNum,
  squareGrid,
  selected,
  firstRender,
) => {
  let selectedTilesData = [];

  if (firstRender === true) {
    zoneNum = 1;
    // setToolTip(`Editing Zone: ${zoneNum}`);
    // if its the first time drawing the grid, lets create the dictionary
    selectedTilesData = squareGrid.features.map((feature, index) => {
      const data = {
        feature, selected: false, key: index, zone: -1,
      };
      return data;
    });
  } else {
    // we have re-drawn/re-rendered the map, lets update coordinates of the dictionary instead
    selectedTilesData = selected;
    for (let x = 0; x < selectedTilesData.length; x++) {
      selected[x].feature = squareGrid.features[x];
    }
  }

  return selectedTilesData;
};

/**
 * Rotates grid based on set value by updating selected
 * @param {Array} squareGrid  Turf square grid from bounding box and grid size
 * @param {Array} selected    Zones selected
 * @param {Number} rotateVal  Value to rotate grid by
 */
export const updateGridOnRotateValue = (selected, squareGrid, rotateVal) => {
  const pivot = turf.center(squareGrid); // This should be the center of the entire square grid
  turf.featureEach(squareGrid, (currentFeature, featureIndex) => {
    // for-each feature/square
    const options = { pivot, mutate: true };
    const tempFeature = currentFeature; // grab the current square
    squareGrid.features[featureIndex] = turf.transformRotate(
      tempFeature,
      rotateVal,
      options,
    );
    const rotatedCopy = turf.transformRotate(tempFeature, rotateVal, options);

    try {
      selected[featureIndex].feature = rotatedCopy;
    } catch (err) {
      console.log(`Problem rotating grid: ${err}`);
    }
  });
};

/**
 * Updates style for selected zones
 * @param {Object} gridLayer Reference object for map layer.
 * @param {Object} layer Layer to style from gridLayer.current
 * @param {Array} selectedTilesData Data for tracking selected tiles
 * @param {Array} zoneColors Hex codes to color zones
 * @param {Number} zoneNum Zone number
 * @param {Function} setSelectedTiles tracks number of selected tiles on grid
 */
export const styleGridLayers = (
  gridLayer,
  layer,
  selectedTilesData,
  selected,
  zoneColors,
  zoneNum,
  setSelectedTiles,
) => {
  try {
    const ndx = gridLayer.getLayers().indexOf(layer);
    const selectedBool = selectedTilesData[ndx].selected;

    const layerZnum = selectedTilesData[ndx].zone;

    if (zoneNum > zoneColors.length) {
      // if out of preset colors, get a random color and push to zoneColors array
      zoneColors.push(getRandomColor());
    }

    const fillColor = zoneColors[zoneNum - 1];

    layer.setStyle({
      weight: 0.75,
      color: 'white',
      opacity: 0.75,
      fillOpacity: '0.2',
    });

    if (selectedBool) {
      layer.setStyle({
        fillColor: zoneColors[layerZnum - 1],
        fillOpacity: '0.6',
      });
    }

    layer.on('mouseover', function () {
      if (layerZnum > 0) {
        this.setStyle({
          fillColor: zoneColors[layerZnum - 1],
        });
      } else {
        this.setStyle({ fillColor, fillOpacity: '0.6' });
      }
    });

    layer.on('mouseout', function () {
      const ndx = gridLayer.getLayers().indexOf(this);
      const selectedBool = selectedTilesData[ndx].selected;
      if (selectedBool === false) {
        this.setStyle({
          fillColor: 'white',
          fillOpacity: '0.2',
        });
      }
    });

    layer.on('click', function (e) {
      // console.log(e.target.feature);
      const ndx = gridLayer.getLayers().indexOf(this);
      const selectedBool = selectedTilesData[ndx].selected;
      if (selectedBool === false) {
        selectedTilesData[ndx].selected = true;
        selectedTilesData[ndx].zone = zoneNum;
        selected = selectedTilesData;
        e.target.setStyle({
          fillColor,
          fillOpacity: '0.6',
        });
      } else {
        selectedTilesData[ndx].selected = false;
      }
      setSelectedTiles(countSelectedTiles(selectedTilesData));
    });
  } catch (err) {
    console.log(err);
  }
};

const countSelectedTiles = (tiles) => tiles.filter((x) => x.selected).length;

export const getSelectedForZone = (selected, zoneNum) => {
  let selectedForZone = 0;
  for (const square of selected) {
    if (square.selected && square.zone === zoneNum) {
      selectedForZone += 1;
    }
  }
  return selectedForZone;
};

export const createGridControls = (
  handleRotateSlider,
  moveGrid,
  acresToGrid,
) => {
  const sliders = [];

  if (acresToGrid !== undefined) {
    // grid size
    const slider1 = L.control.slider(
      (value) => {
        acresToGrid(value);
      },
      {
        id: 'slider1',
        orientation: 'horizontal',
        position: 'topleft',
        title: 'Grid Size',
        min: 0.5,
        max: 10,
        step: 0.5,
        value: 2,
        collapsed: true,
        increment: true,
        size: '200px',
        logo: acresIcon,
        syncSlider: true,
      },
    );

    sliders.push(slider1);
  }

  // grid rotation
  const slider2 = L.control.slider(
    (value) => {
      handleRotateSlider(parseInt(value));
    },
    {
      id: 'slider2',
      orientation: 'horizontal',
      position: 'topleft',
      title: 'Rotate Grid',
      min: -90,
      max: 90,
      step: 0.5,
      value: 0,
      collapsed: true,
      increment: true,
      size: '200px',
      logo: rotateIcon,
      syncSlider: true,
    },
  );
  sliders.push(slider2);

  // move E/W
  const slider3 = L.control.slider(
    (value) => {
      moveGrid(parseFloat(value), 'x');
    },
    {
      id: 'slider3',
      orientation: 'horizontal',
      position: 'topleft',
      title: 'Move East/West',
      min: -0.005,
      max: 0.005,
      step: 0.00006,
      value: 0,
      collapsed: true,
      size: '200px',
      increment: true,
      logo: horizontalIcon,
      showValue: false,
    },
  );
  sliders.push(slider3);

  // move N/S
  const slider4 = L.control.slider(
    (value) => {
      moveGrid(parseFloat(value), 'y');
    },
    {
      id: 'slider4',
      orientation: 'horizontal',
      position: 'topleft',
      title: 'Move North/South',
      min: -0.005,
      max: 0.005,
      step: 0.00006,
      value: 0,
      collapsed: true,
      increment: true,
      size: '200px',
      logo: verticalIcon,
      showValue: false,
    },
  );

  sliders.push(slider4);

  return sliders;
};

/**
* This function will take a feature and a list of other features and return the number of tiles
* the new one shares sides with as well as a list of who it shares sides with.
* @param {feature} newTile
* @param {array of features} allTiles
*/
export const sharedEdges = (newTile, allTiles) => {
  const returnArry = []; // 0 = shared edges, 1 neighbors array
  const edgeFriends = [];
  newTile = newTile.coordinates[0];
  let matches = 0;

  for (let j = 0; j < allTiles.length; j++) {
    // list of all tiles
    const currentTile = allTiles[j].coordinates[0]; // get a specific tile
    matches = 0;
    for (let x = 0; x < 4; x++) {
      // current tiles coords
      for (let y = 0; y < 4; y++) {
        // new tiles coords
        if (
          currentTile[x][0] == newTile[y][0]
          && currentTile[x][1] == newTile[y][1]
        ) {
          matches++;
          if (matches === 2) {
            edgeFriends.push(allTiles[j]);
          }
        }
      }
    }
  }
  returnArry.push(parseInt(edgeFriends.length));
  returnArry.push(edgeFriends);
  return returnArry;
};



/**
 * Here is the grand finale for the grid tool. After zones have been created
 * with the grid and the finish button gets clicked, this function will
 * combine the tiles into larger features and pass them on to the zone handler
 * with the information needed for colors and deleting functionality and then
 * it will remove the grid tools from the screen putting the user into a
 * finished editing view
 */
export const createGridZones = (
  allTiles,
  createdZones,
  drawnZones,
  gridlayer,
  setZones,
  zoneColors,
  clearGrid,
  maxZoneNum,
  fieldBoundary,
  map,
) => {
  const zonesToMerge = maxZoneNum; // number of zones we're creating
  const zones = [];
  const selectedTiles = [];

  // -----------------check if we have selected any tiles-----------------------
  let numTilesSelected = 0;
  for (var x = 0; x < allTiles.length; x++) {
    if (allTiles[x].selected === true) numTilesSelected++;
  }

  // after check that we have selected a tile - push empty arrays
  // that structure will look like: [[], [], []...]
  if (numTilesSelected > 0) {
    for (var x = 0; x < zonesToMerge; x++) {
      selectedTiles.push([]);
    }
    //----------------------------------------------------------------------------

    // now that we set up nested arrays lets make that previous structure look like this:
    //  [ [zone1 feature, zone1 feature...], [zone2 feature, zone2 feature...] ...]
    for (var x = 0; x < allTiles.length; x++) {
      const currentTile = allTiles[x];
      if (currentTile.selected === true) {
        const zoneNdx = currentTile.zone - 1;
        selectedTiles[zoneNdx].push(currentTile.feature);
      }
    }

    try {
    //----------------------------------------------------------------------------
    // here is where we are combining the polygons to make zones
    // [ [big zone1 polygon], [big zone2 polygon] ... ]
      for (var x = 0; x < zonesToMerge; x++) {
        const featureList = selectedTiles[x];
        const tempZone = turf.union(...featureList);
        zones.push(tempZone);
      }
    } catch (err) {
      console.log(err)
      return []
    }
    //----------------------------------------------------------------------------
    // At this point we have an array with sub-arrays per zone containing big polygons
    // next is to mask them so that they exist only within the field boundaries
    const fieldMask = {};
    const polysForField = [];

    const fieldGeo = createGeoFromBoundary(fieldBoundary);

    let tempPoly = {}
    let maskedZones = []
    // If field is a MultiPolygon, we convert to same structure as polygon so
    // we can use the same intersect logic to clip
    if (fieldGeo.features[0].geometry.type === 'Polygon') {
      tempPoly = fieldGeo.features[0];
    } else {
      const intersects = checkMultipolygonIntersection(fieldGeo.features[0].geometry.coordinates)
      if (intersects) {
        // If multiPolygon is self contained, we can simply convert to polygon
        // to check for intersection
        tempPoly = createPolygonFromMulti(fieldGeo.features[0].geometry.coordinates);
      } else {
        // If multiPolygon is separate polygons, we will need to clip
        // Turf functions don't really expect a multipolygon to be two
        // completely separate polygons. The expected format is first array of
        // coordinates is the containing polygon, the subsequent coordinates
        // are holes in the boundary. Below function is work around.
        maskedZones = handleMultiplePolygons(fieldGeo.features[0].geometry.coordinates, zones)
      }
    }

    if (!maskedZones.length) {
      try {
        maskedZones = zones.map((x) => turf.intersect(x, tempPoly)).filter((i) => i !== null);

      } catch(err) {
        console.log(err)
        if (err?.message === 'side location conflict') {
          maskedZones = getClippedZones(
            fieldGeo.features[0].geometry.coordinates,
            zones
          )
        } else {
          maskedZones = []
        }
      }
    }

    // here we are going to make one more list of the created zones structured how the zone handler wants them
    const forZoneHandler = [];

    const allZones = []; // old zones plus new zones
    try {
      for (var x = 0; x < maskedZones.length; x++) {
        const tempFeature = {
          type: 'Feature',
          geometry: maskedZones[x].geometry,
          properties: {
            featureRef: createdZones,
            mapRef: map,
            CALCACRES: turf.convertArea(
              turf.area(maskedZones[x]),
              'meters',
              'acres',
            ),
            zone: x + 1,
          },
        };
        forZoneHandler.push(tempFeature);
      }
    } catch (err) {
      console.log(err);
      return;
    }

    allZones.push(...forZoneHandler);
    createdZones.eachLayer((layer) => {
      allZones.push(layer.feature);
    });

    let colorOffset = 0;
    createdZones.eachLayer((layer) => {
      colorOffset++;
    });

    let z = 0;
    for (const zone of forZoneHandler) {
      zone.properties.color = getZoneColor(z, zoneColors)
      z++;
    }

    // send our data to the handler
    const forZones = forZoneHandler.map((x) => ({ zone: x, type: 'add' }));
    setZones(forZones);

    // cleanup the map and reset states
    // clear grid with addBack set to false
    clearGrid(false);

    gridlayer.eachLayer((layer) => {
      layer.feature.properties.id = layer._leaflet_id;
    });

    gridlayer.clearLayers();

    forZoneHandler.map((zone) => createdZones.addData(zone));

    createdZones.eachLayer((layer) => {
      layer.feature.properties.id = layer._leaflet_id;
      layer.feature.featureRef = createdZones;
    });

    drawnZones = [createdZones];
    createdZones.addTo(map);

    return forZoneHandler;
  }
  clearGrid();
};

const getZoneColor = (index, zoneColors) => {
  if (index < zoneColors.length) {
    return zoneColors[index]
  } else {
    return getRandomColor()
  }
}

const checkMultipolygonIntersection = (coordinates) => {
  const mainPoly = createPolygonFromCoordinates(coordinates[0])
  for (let i = 1; i < coordinates.length; i++) {
    const secondaryPoly = createPolygonFromCoordinates(coordinates[i])

    const intersectionTest = turf.intersect(mainPoly, secondaryPoly);
    if (intersectionTest === null) {
      return false
    }
  }
  return true
}

const createPolygonFromCoordinates = (coordinates) => {
  const polygon = {
    type: 'Feature',
    properties: {},
    geometry: {
      type: 'Polygon',
      coordinates: coordinates
    }
  }

  return polygon
}

const createPolygonFromMulti = (multiPolygon) => {
  // For using same intersect logic that multiple polygon fields stored as Polygon
  let coordinates = []
  for (const x of multiPolygon) {
    // de-nest coordinates
    coordinates.push(x[0])
  }

  return createPolygonFromCoordinates(coordinates)
}

/**
  * Turf functions don't really expect a multipolygon to be two completely
  * separate polygons. The expected format is first array of coordinates is the
  * containing polygon, the subsequent coordinates are holes in the boundary.
  * Here we will work our way around that.
 * @param {[type]} coordinates [description]
 * @param {[type]} zones       [description]
 * @return {[type]} [description]
 */
const getClippedZones = (coordinates, zones) => {
  let clippedZones = []
  try {
    if(coordinates.length > 1){
      //loop over suspected multi poly coords
      let fieldDifferences = [];
      for(let j = 0; j<coordinates.length; j++){
        let multiCoords = coordinates[j]
        //console.log(multiCoords)
        let poly;
        try{
          if(multiCoords.length > 1){
            poly = turf.polygon([multiCoords])
          }
          else{
            poly = turf.polygon(multiCoords)
          }

        }
        catch (err) {
          console.log(err)
          poly = null;
        }

        //check to see if multiCoords was for a boundary or an actual multi
        if(poly !== null){
          //need to distinguish multi polygon boundary from regular boundary.
          if(poly.geometry.coordinates.length > 1){//boundary is multi poly
            //loop over boundary coordinates here, skip first becuase it is outer boundary
            let differences = [] // all differences between holes of multi and zones
            for(var i = 1; i < poly.geometry.coordinates.length; i++){
              let tempPoly = turf.polygon([poly.geometry.coordinates[i]])
              for(const zone of zones){
                if(turf.intersect(zone, tempPoly) !== null){
                  differences.push(turf.difference(zone, tempPoly))
                }
              }
            }
            //if differences exist, now apply them to the outer boundary
            if(differences.length > 0){
              let outerBoundary = turf.polygon([poly.geometry.coordinates[0]])
              for(const diffZone of differences){
                let outterClip = turf.intersect(diffZone, outerBoundary)
                if(outterClip !== null){
                  clippedZones.push(outterClip)
                }
              }
            }
          }
          else{
            //if boundary is single polygon
            let tempPoly = poly
            let clips = zones.map(x => turf.intersect(x, tempPoly)).filter(i => i !== null)
            clippedZones = [...clippedZones, ...clips]
          }
        }
      }
    }
  } catch (err) {
    console.log(err)
  }

  return clippedZones
}

const handleMultiplePolygons = (coordinates, zones) => {
  let polygons = []

  const features = coordinates.map((x, i) => makeFeature(x, i))

  for (let i = 0; i < coordinates.length; i++) {
    let feature = makeFeature(coordinates[i], i)

    const differentFeatures = features.filter(x => x.properties.id !== feature.properties.id)

    let contains = false
    let container = null
    for (const dif of differentFeatures) {
      try {
        const check = turf.booleanContains(dif, feature);
        if (check) {
          contains = true
          container = dif
        }
      } catch (e) {
        console.log(e)
      }
    }
    if (!contains) {
      polygons.push(feature)
    } else {
      let multiPolygon = {
        ...container
      }
      multiPolygon.geometry.type = "MultiPolygon"
      multiPolygon.geometry.coordinates = [multiPolygon.geometry.coordinates, coordinates[i]]

      const removedContainer = polygons.filter(x => x.properties.id !== container.properties.id)

      removedContainer.push(multiPolygon)
      polygons = removedContainer
    }
  }

  let finalZones = []

  // Use copy since we update directly
  let polyCopy = cloneDeep(polygons)

  for (const poly of polyCopy) {
    try {
      let tempPoly = poly
      if (poly.geometry.type === 'MultiPolygon') {
        // Intersects does not handle multi polygons, but will know what to do
        // with reformatted polygon. It is important that the hole in multiPolygon
        // comes after the containing bounds in the resulting coordinates array
        tempPoly = createPolygonFromMulti(poly.geometry.coordinates);
      }

      let maskedZones = zones.map((x) => turf.intersect(x, tempPoly)).filter((i) => i !== null);

      finalZones = [...finalZones, ...maskedZones]
    } catch (e) {
      console.log(e)
    }

  }

  return finalZones
}

import React, {
  useState, useEffect, useContext,
} from 'react';
import PropTypes from 'prop-types';

import {
  Box, Button, AppBar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import { useSnackbar } from 'notistack';

import {
  green,
  blackText,
} from '../../styles/colors';

import { UserContext } from '../Context/UserContext';
import { Steps } from './Navigation/Steps';
import { useWindowDimensions } from '../../utils/dimensions';
import { FieldContext } from '../Context/FieldContext';
import { FieldSelection } from '../FieldSelection/FieldSelection';
import { FieldInformation } from './FieldInformation';
import { Footer } from './Navigation/Footer';
import { ManageFarm } from './ManageFarm';
import { VarietyByWeather } from './Analytics/VarietyByWeather';
import { NutrientByYield } from './Analytics/NutrientByYield';
import { NBalance } from './Analytics/NBalance';
import { WaterManagement } from './WaterManagement';
import { ConservationPractices } from './ConservationPractices';
import { PopupManager } from '../Shared/Popups/PopupManager';

import { Endpoints } from '../../constants/Endpoints';
import * as df from '../../utils/dataFetchers';
import * as fmd from '../../utils/fieldManagerDataFetchers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selections: {
    backgroundColor: theme.palette.greys.light,
    height: '100%',
    padding: '15px',
    overflowY: 'auto',
    width: 220,
    minWidth: 220,
  },
  select: {
    margin: '1px 0',
    padding: '10px',
    borderRadius: '5px',
    color: blackText,
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f8f8ff',
      color: '#1B1B1B',
    },
  },
  selected: {
    margin: '1px 0',
    padding: '10px',
    borderRadius: '5px',
    color: green,
    fontWeight: 600,
    backgroundColor: '#ffffff',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  layers: {
    display: 'flex',
    width: '100%',
  },
  label: theme.label,
  connectButton: {
    width: 180,
    margin: '6px 0',
    backgroundColor: '#f8f8ff',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
}));

/**
 * Provides user with ability to edit farm and field names and some other related information.
 * @param {Bool} digitizing Changes field selection behavior when digitizing
 * @param {Function} setDataLayersStep Starts user in DataLayers when already selected field
 * @param {Function} setPlStep Starts user in ProfitLayers when already selected field
 * @param {Function} setSection Navbar highlighting
 * @returns {JSX} FieldManager
 */
export function FieldManager({
  digitizing,
  setDataLayersStep,
  setPlStep,
  setSection,
}) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  const user = useContext(UserContext)[0];
  // const fieldData = useContext(FieldContext)[0];
  const [step, setStep] = useState(0); // field select or DL
  const [selectedLayer, setSelectedLayer] = useState(0); // tabs in DL
  const [fieldData, setFieldData] = useContext(FieldContext);

  const [authenticated, setAuthenticated] = useState(false);
  const [precisionField, setPrecisionField] = useState(false);

  const [isLoading, setIsLoading] = useState(false);

  // Open Modals
  const [openIntegration, setOpenIntegration] = useState(false);

  const layers = [
    'Field Information',
    'Variety X Weather',
    'Nutrient X Yield',
    'N-Balance',
    'Water Management',
    'Conservation Practices',
  ];

  const farmLayers = ['Farm Information'];

  const precisionSources = ['deere', 'climate', 'cnh'];
  // Field info
  const [field, setField] = useState({ id: '', latitude: '', longitude: '' });

  const [hideFieldSelection, setHideFieldSelection] = useState(false);
  const [fieldInformation, setFieldInformation] = useState([]);
  const [crops, setCrops] = useState([]);
  const [selectedCrop, setSelectedCrop] = useState('');
  const [cropYears, setCropYears] = useState([]);
  const [yieldInfo, setYieldInfo] = useState([]);
  const [varietyInfo, setVarietyInfo] = useState([]);
  const [applicationComp, setApplicationComp] = useState([]);
  const [nutrientParser, setNutrientParser] = useState([]);
  const [appYears, setAppYears] = useState([]);
  const [aYears, setAYears] = useState([]);
  const [acres, setAcres] = useState([]);
  const [appdates, setDates] = useState([]);
  const [nFactorData, setNFactorData] = useState([]);
  const [nFactorData1, setNFactorData1] = useState([]);
  const [yieldUnit, setYieldUnit] = useState([]);
  const [nRate, setNRate] = useState([]);
  const [nRate1, setNRate1] = useState([]);
  const [grainYield, setGrainYield] = useState([]);
  const [grainYield1, setGrainYield1] = useState([]);
  const [precipData, setPrecipData] = useState([]);
  const [gddData, setGddData] = useState([]);

  const [nrcsList, setNRCSList] = useState([]);
  const [crpList, setCRPList] = useState([]);
  const [cspList, setCSPList] = useState([]);
  const [otherList, setOtherList] = useState([]);

  const [waterMgmtInfo, setWaterMgmtInfo] = useState([]);

  // ensure navbar has correct section
  useEffect(() => {
    setSection(2);
  }, []);

  useEffect(() => {
    setAuthenticated(user.isAuthenticated);

    if (user.new) {
      // setNewUser(true)
      if (field.id !== '') {
        // start saving fields
      } else {
        enqueueSnackbar('Please draw or select a field on map to get started');
      }
    }
  }, [user]);

  useEffect(() => {
    if (fieldData.selectedField.orgId !== '' && fieldData.selectedField.id !== '') {
      getConservationPractices();

      getFieldInformation(fieldData.selectedField.orgId, fieldData.selectedField.id);
      getWaterManagementInfo(fieldData.selectedField.id);

      getCropInformation(fieldData.selectedField.orgId, fieldData.selectedField.id);
      getYieldInfo(fieldData.selectedField.orgId, fieldData.selectedField.id);
      getApplicationComp(fieldData.selectedField.orgId, fieldData.selectedField.id);
      getFieldAcres(fieldData.selectedField.orgId, fieldData.selectedField.id);
    } else {
      setFieldInformation([]);
    }
  }, [fieldData]);

  const getConservationPractices = async () => {
    const nrcs = await fmd.getNrcs();
    setNRCSList(nrcs);

    const crp = await fmd.getCrp();
    setCRPList(crp);

    const csp = await fmd.getCSP();
    setCSPList(csp);

    const other = await fmd.getOther();
    setOtherList(other);
  };

  const getFieldInformation = async (orgId, fieldId) => {
    try {
      const response = await df.getFieldInfo(orgId, fieldId);
      // console.log("field info response", response)
      setFieldInformation(JSON.parse(response));
    } catch (err) {

    }
  };

  const getWaterManagementInfo = async (fieldId) => {
    const info = await fmd.getWaterMgmt(fieldId);
    setWaterMgmtInfo(info);
  };

  const getCropInformation = async (orgId, fieldId) => {
    try {
      let response = await df.getVariety(orgId, fieldId);
      response = JSON.parse(response);
      // console.log(response)
      setVarietyInfo(response);
      let cropsList = []; const varietyList = []; const years = []; let
        yearsList = [];
      for (const op of response) {
        cropsList.push(op.CropName);
        const obj = {
          crop: op.CropName,
          variety: op.Variety,
          year: op.CropSeason,
        };
        yearsList.push(op.CropSeason);
        years.push(obj);
      }
      cropsList = [...new Set(cropsList)];
      yearsList = [...new Set(yearsList)];
      setCrops(cropsList);
      setCropYears(years);
      getPrecipitationData(yearsList);
      getGddData(yearsList);

      if (cropsList.length > 0) {
        setSelectedCrop(cropsList[0]);
      }
    } catch (err) {

    }
  };

  const getPrecipitationData = async (years) => {
    const allData = [];

    for (const year of years) {
      let res = await df.getPrecipitation(fieldData.selectedField.longitude, fieldData.selectedField.latitude, year, 7, 8);
      res = JSON.parse(res);
      allData.push(res[0]);
    }
    setPrecipData(allData);
  };

  const getGddData = async (years) => {
    const allData = [];

    for (const year of years) {
      let res = await df.getGDD(fieldData.selectedField.longitude, fieldData.selectedField.latitude, year, 7, 8);
      res = JSON.parse(res);
      allData.push(res[0]);
    }
    setGddData(allData);
  };

  const getYieldInfo = async (orgId, fieldId) => {
    try {
      let res = await df.getYield(orgId, fieldId);
      // console.log('res', res)
      res = JSON.parse(res);

      const avgYieldUnit = [];
      for (let i = 0; i < res.length; i++) {
        avgYieldUnit.push(res[i].AverageYieldUnit);
      }
      // setYieldUnit(avgYieldUnit)
      setYieldInfo(res);
      nBalanceFactors(res, avgYieldUnit);
    } catch (err) {
      console.log('getYieldInfo error', err);
    }
  };

  const getFieldAcres = async (orgId, fieldId) => {
    try {
      let res = await df.getAcres(orgId, fieldId);
      res = JSON.parse(res);
      setAcres(res);
    } catch (err) {
      console.log('getFieldAcres error', err);
    }
  };

  const getApplicationComp = async (orgId, fieldId) => {
    try {
      let res = await df.getApplicationComponents(orgId, fieldId);
      // console.log('res', res)
      res = JSON.parse(res);
      setApplicationComp(res);

      let opnId = []; let
        years = [];
      for (var i = 0; i < res.length; i++) {
        opnId[i] = res[i].ID;
        years[i] = res[i].CropSeason;
      }
      opnId = [...new Set(opnId)];
      years = [...new Set(years)];
      setAYears(years);

      let cropSeason = []; const
        dates = [];

      for (var i = 0; i < opnId.length; i++) {
        for (let x = 0; x < res.length; x++) {
          if (res[x].ID == opnId[i]) {
            const obj = {
              id: (res[x].ID).trim(),
              year: res[x].CropSeason,
              acres: res[x].appAcres,
            };
            const obj1 = {
              id: (res[x].ID).trim(),
              year: res[x].CropSeason,
              date: res[x].StartDate,
            };
            cropSeason.push(obj);
            dates.push(obj1);
          }
        }
      }

      cropSeason = Array.from(new Set(cropSeason.map(JSON.stringify))).map(JSON.parse);
      setAppYears(cropSeason);
      setDates(dates);
      const parserRes = [];

      for (const id of opnId) {
        let applicationOp = await df.getApplicationForParser(orgId, fieldId, id);
        applicationOp = JSON.parse(applicationOp);

        const componentBody = [];

        const obj = {};

        for (var i = 0; i < applicationOp.length; i++) {
          const varName = `Comp_${i + 1}`;
          const modifiedObj = {
            Name: applicationOp[i].Name,
            Rate: applicationOp[i].Rate,
            RateUnit: applicationOp[i].RateUnit,
            TotalMaterialResult: applicationOp[i].TotalMaterialResult,
            TotalMaterialResultUnit: applicationOp[i].TotalMaterialResultUnit,
            TotalMaterialTarget: applicationOp[i].TotalMaterialTarget,
            TotalMaterialTargetUnit: applicationOp[i].TotalMaterialTargetUnit,
            AverageMaterialResult: applicationOp[i].AverageMaterialResult,
            AverageMaterialResultUnit: applicationOp[i].AverageMaterialResultUnit,
            AverageMaterialTarget: applicationOp[i].AverageMaterialTarget,
            AverageMaterialTargetUnit: applicationOp[i].AverageMaterialTargetUnit,
            IsCarrier: applicationOp[i].IsCarrier === true ? 'true' : 'false',
            ApplicationOperationID: applicationOp[i].ApplicationOperationID,
            IsTankMix: applicationOp[i].IsTankMix === true ? 'true' : 'false',
          };

          obj[varName] = modifiedObj;
          componentBody.push(obj);
        }

        const totalOps = {
          ID: id,
          Name: applicationOp[0].OpName,
          Rate: applicationOp[0].OpRate,
          RateUnit: applicationOp[0].OpRateUnit,
          IsTankMix: applicationOp[0].IsTankMix === true ? 'true' : 'false',
          CarrierName: applicationOp[0].CarrierName,
          CarrierRate: applicationOp[0].CarrierRate,
          CarrierRateUnit: applicationOp[0].CarrierRateUnit,
          AreaResult: applicationOp[0].OpAreaResult,
          AreaResultUnit: applicationOp[0].OpAreaResultUnit,
          AreaTarget: applicationOp[0].OpAreaTarget,
          AreaTargetUnit: applicationOp[0].OpAreaTargetUnit,
          TotalMaterialResult: applicationOp[0].OpTotalMaterialResult,
          TotalMaterialResultUnit: applicationOp[0].OpTotalMaterialResultUnit,
          TotalMaterialTarget: applicationOp[0].OpTotalMaterialTarget,
          TotalMaterialTargetUnit: applicationOp[0].OpTotalMaterialTargetUnit,
          AverageMaterialResult: applicationOp[0].OpAverageMaterialResult,
          AverageMaterialResultUnit: applicationOp[0].OpAverageMaterialResultUnit,
          AverageMaterialTarget: applicationOp[0].OpAverageMaterialTarget,
          AverageMaterialTargetUnit: applicationOp[0].OpAverageMaterialTargetUnit,
        };

        const request = {
          Application_Operation: `{"Application_Operation": {"Entry_Type": "Precision", "Process_Types": ["npk"], "Total": ${JSON.stringify(totalOps)}, "Components": ${JSON.stringify(obj)}}}`,
        };
        // console.log("request", request, JSON.stringify(request))
        const response = await df.nutrientParser(request);
        const nutrientObj = {};
        for (const n in response.NPK.npks) {
          nutrientObj[n] = response.NPK.npks[n] * applicationOp[0].OpAverageMaterialResult;
        }
        nutrientObj.id = id;
        parserRes.push(nutrientObj);
      }
      // console.log('nutrient parser res', parserRes)
      setNutrientParser(parserRes);
    } catch (err) {
      console.log('getApplicationComp function error', err);
    }
  };

  const nBalanceFactors = async (yieldData, avgYieldUnit) => {
    const nFactorRes = []; const nFactor = []; const
      nFactor1 = [];
    for (let i = 0; i < yieldData.length; i++) {
      let substringUnit = '';
      if (yieldData[i].AverageYieldUnit.includes('1')) {
        const startUnit = yieldData[i].AverageYieldUnit.indexOf('1', 1);
        // var end = currentCluField.boundary.indexOf(",", 1)
        substringUnit = avgYieldUnit[i].substring(0, startUnit);
      } else {
        substringUnit = yieldData[i].AverageYieldUnit;
      }
      // console.log(substringUnit)
      if (yieldData[i].CropName.includes('_')) {
        const startCrop = yieldData[i].CropName.indexOf('_', 1);
        var substringCrop = yieldData[i].CropName.substring(0, startCrop);

        // console.log('substringCrop', substringCrop)
      } else {
        var substringCrop = yieldData[i].CropName.substring(0, yieldData[i].CropName.length - 1);
        // console.log('substringCrop', substringCrop)
      }

      // yieldData[i].CropName, substringUnit, yieldData[i].CropSeason, yieldData[i].AverageYield, yieldData[i].CropName
      const res = await df.getNFactor(yieldData[i].CropName, substringUnit);
      const parsedContent = JSON.parse(res);
      // console.log(res, parsedContent)
      nFactorRes.push(parsedContent);
      if (parsedContent.length == 0) {
        var obj = {};
        nFactor.push(obj);
        nFactor1.push(obj);
      } else {
        var obj = {
          cropSeason: yieldData[i].CropSeason,
          nFactor: parsedContent[0].N,
          nFactorUnit: parsedContent[0].nunit,
          cropName: yieldData[i].CropName,
          yield: yieldData[i].AverageYield,
          grainNRemoval: parsedContent[0].N * yieldData[i].AverageYield,
        };
        nFactor.push(obj);
        nFactor1.push(obj);
      }
    }
    // console.log('nFactor', nFactor)
    setNFactorData(nFactor);
    setNFactorData1(nFactor1);
  };

  const changeSection = (section) => {
    if (section === 0) {
      setStep(section);
    } else if (section === 1) {
      setStep(section);
    } else {
      enqueueSnackbar('Please draw or select a field on map to get started');
    }
  };

  const changeSectionOnFieldClick = (section) => {
    if (!digitizing) {
      setStep(1);
    }
  };

  const goToProfitLayers = () => {
    const step = fieldData.selectedField.id !== '' ? 1 : 0;
    const url = window.location.href.split('/');
    const extension = url[url.length - 1];
    if (extension.toUpperCase() === 'FIELDMANAGER') {
      window.location.assign('/app');
    } else {
      setPlStep(step);
      setSection(0);
    }
    // if (fieldData.selectedField.id !== '') {
    //   setPlStep(1)
    //   setSection(0)
    // } else {
    //   enqueueSnackbar('Please select a field before setting up ProfitLayers')
    // }
  };

  // Delete field
  const deleteField = async (orgId, fieldId) => {
    try {
      const response = await df.deleteField(orgId, fieldId);

      if (response === '[]') {
        //
        const deleteBdyRes = await df.deleteFieldBoundary(orgId, fieldId);
        // console.log("fieldData.fields", fieldData.fields)
        const filteredFields = fieldData.fields.filter((x) => x.id !== fieldId);
        // console.log("filteredFields", filteredFields)
        setFieldData({
          ...fieldData,
          fields: filteredFields,
          selectedField: {
            id: '',
            name: '',
            farmId: fieldData.selectedField.farmId,
            farm: fieldData.selectedField.farm,
            orgId: fieldData.selectedField.orgId,
            org: fieldData.selectedField.org,
          },
        });
      }
      setStep(0);
    } catch (err) {
      console.log('deletefield failed', err);
    }
  };

  // Delete farms
  const deleteFarm = async (orgId, farmId) => {
    try {
      const response = await df.deleteFarm(orgId, farmId);

      if (response === '[]') {
        // console.log("fieldData.fields", fieldData.fields)

        for (let i = 0; i < fieldData.fields.length; i++) {
          await deleteField(orgId, fieldData.fields[i].id);
        }
        const filteredFields = fieldData.fields.filter((x) => x.farmId !== farmId);
        // console.log("filteredFields", filteredFields)
        setFieldData({
          ...fieldData,
          fields: filteredFields,
          selectedField: {
            id: '',
            name: '',
            farmId: '',
            farm: '',
            orgId: fieldData.selectedField.orgId,
            org: fieldData.selectedField.org,
          },
        });
      }
      setStep(0);
    } catch (err) {

    }
  };

  // Update Farm
  const updateFarm = async (orgId, farmId, farmName) => {
    try {
      const response = await df.updateFarm(orgId, farmId, farmName);

      if (response === '[]') {
        // console.log("fieldData.fields", fieldData.fields)

        // const filteredFields = fieldData.fields.filter(x => x.farmId !== farmId)
        // console.log("filteredFields", filteredFields)
        setFieldData({
          ...fieldData,
          ...fieldData.fields,
          selectedField: {
            id: '',
            name: '',
            farmId: fieldData.selectedField.farmId,
            farm: farmName,
            orgId: fieldData.selectedField.orgId,
            org: fieldData.selectedField.org,
          },
        });
      }
      setStep(0);
    } catch (err) {

    }
  };

  // Update Field
  const updateField = async (orgId, fieldId, request) => {
    /// console.log("fieldData", fieldData)
    try {
      const response = await df.updateField(orgId, fieldId, request);
      // console.log("response", response)
      if (response === '[]') {
        // console.log("fieldData.fields", fieldData.fields)
        const name = request.FieldName;

        const updatedField = fieldData.fields.filter((x) => x.id === fieldId && x.acres !== undefined);
        // console.log("updatedField", updatedField)
        const update = updatedField.map((x) => ({
          ...x,
          name,
        }));
        const nonUpdatedFields = fieldData.fields.filter((x) => x.id !== fieldId);

        // console.log("updatedField", update, nonUpdatedFields)

        // console.log("[...update, ...nonUpdatedFields]", [...update, ...nonUpdatedFields])

        setFieldData({
          ...fieldData,
          fields: [...update, ...nonUpdatedFields],
          selectedField: update[0],
        });
        getFieldInformation();
        // setStep(0)
      }
    } catch (err) {

    }
  };

  const updateWaterMgmt = async (request) => {
    if (waterMgmtInfo.length > 0) {
      const res = await fmd.updateWaterMgmt(request);
    } else {
      const res = await fmd.addWaterMgmt(request);
    }
    getWaterManagementInfo(fieldData.selectedField.id);
  };

  const handleFieldSelect = (field) => {
    console.log('fieldData', fieldData);
    setFieldData({
      fields: fieldData.fields,
      fieldToClaim: {
        feature: null, geometry: {}, coordinates: [], points: [], boundary: {}, acres: 0,
      },
      selectedField: field,
    });
  };

  const selections = () => (
    <Box className={classes.selections}>
      {fieldData.selectedField.id !== '' && fieldData.selectedField.name !== '' ? (
        <Box>
          {layers.map((layer, i) => (
            <Box
              key={i}
              className={selectedLayer === i ? classes.selected : classes.select}
              onClick={() => setSelectedLayer(i)}
            >
              { layer}
            </Box>
          ))}
        </Box>
      )
        : (
          <Box>
            {farmLayers.map((layer, i) => (
              <Box
                key={i}
                className={selectedLayer === i ? classes.selected : classes.select}
                onClick={() => setSelectedLayer(i)}
              >
                { layer}
              </Box>
            ))}
          </Box>
        )}

      <Box>
        {/* {
          !precisionField && (
            <Button
              className={classes.connectButton}
              variant='outlined'
              color='primary'
              onClick={() => setOpenIntegration(true)}
            >
              Connect Precision
            </Button>
          )
        } */}

        {
          !authenticated && (
            <>
              <Button
                className={classes.connectButton}
                variant="outlined"
                color="primary"
                href={Endpoints.BASEURL + Endpoints.SIGNIN}
              >
                <span style={{ color: green }}>Sign in</span>
              </Button>
              <Button
                className={classes.connectButton}
                variant="outlined"
                color="primary"
                href={Endpoints.BASEURL + Endpoints.SIGNUP}
              >
                <span style={{ color: green }}>Create Account</span>
              </Button>
            </>
          )
        }
      </Box>
    </Box>
  );

  return (
    <Box className={classes.root} style={{ height: height - 80 }}>
      <AppBar
        position="static"
        color="transparent"
        style={{ boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.05)' }}
      >
        <Box className={classes.appBar}>
          <Steps
            section={step}
            changeSection={changeSection}
            goToProfitLayers={goToProfitLayers}
            field={fieldData.selectedField}
          />
        </Box>

      </AppBar>

      <Box className={classes.body}>
        {
          step === 0 ? (
            <FieldSelection
              changePage={setSection}
              changeSection={changeSectionOnFieldClick}
              continueText={fieldData.selectedField.id !== '' && fieldData.selectedField.name !== '' ? 'Manage Field' : 'Manage Farm'}
              digitizing={digitizing}
              fieldManager
              hideFieldSelection={hideFieldSelection}
              mobileView={width <= 920}
              setDataLayersStep={setDataLayersStep}
              setIsLoading={setIsLoading}
              showMap={step === 0}
            />
          ) : (
            <Box>
              <Box className={classes.layers} style={{ height: height - 195 }}>
                {selections()}
                <Box style={{ overflowY: 'auto', width: width - 220, overflowX: 'hidden' }}>
                  {
                    selectedLayer === 0 && (fieldData.selectedField.id !== '' && fieldData.selectedField.name !== '') ? (
                      <FieldInformation
                        field={fieldData.selectedField}
                        fieldInformation={fieldInformation}
                        deleteField={deleteField}
                        deleteFarm={deleteFarm}
                        updateField={updateField}
                        fields={fieldData.fields}
                        handleFieldSelect={handleFieldSelect}
                      />
                    ) : selectedLayer === 1 ? (
                      <VarietyByWeather
                        field={fieldData.selectedField}
                        crops={crops}
                        selectedCrop={selectedCrop}
                        setSelectedCrop={setSelectedCrop}
                        cropYears={cropYears}
                        yieldInfo={yieldInfo}
                        varietyInfo={varietyInfo}
                        precipData={precipData}
                        gddData={gddData}
                        setGddData={setGddData}
                        setPrecipData={setPrecipData}
                      />
                    ) : selectedLayer === 2 ? (
                      <NutrientByYield
                        field={fieldData.selectedField}
                        nutrientParser={nutrientParser}
                        yieldInfo={yieldInfo}
                        crops={crops}
                        appYears={appYears}
                        years={aYears}
                        acres={acres}
                        dates={appdates}
                        setNRate={setNRate}
                        setNRate1={setNRate1}
                        setGrainYield={setGrainYield}
                        setGrainYield1={setGrainYield1}
                      />
                    ) : selectedLayer === 3 ? (
                      <NBalance
                        field={fieldData.selectedField}
                        crops={crops}
                        cropYears={cropYears}
                        nFactorData={nFactorData}
                        nFactorData1={nFactorData1}
                        nRate={nRate}
                        nRate1={nRate1}
                        grainYield={grainYield}
                        grainYield1={grainYield1}
                        precipData={precipData}
                        gddData={gddData}
                        setGddData={setGddData}
                        setPrecipData={setPrecipData}
                        setNRate={setNRate}
                        setNRate1={setNRate1}
                        setGrainYield={setGrainYield}
                        setGrainYield1={setGrainYield1}
                        appYears={appYears}
                        ayears={aYears}
                        acres={acres}
                        yieldInfo={yieldInfo}
                        nutrientParser={nutrientParser}
                      />
                    ) : selectedLayer === 4 ? (
                      <WaterManagement
                        field={fieldData.selectedField}
                        waterMgmtInfo={waterMgmtInfo}
                        updateWaterMgmt={updateWaterMgmt}
                      />
                    ) : selectedLayer === 5 ? (
                      <ConservationPractices
                        field={fieldData.selectedField}
                        nrcsList={nrcsList}
                        cspList={cspList}
                        crpList={crpList}
                        otherList={otherList}
                        waterMgmtInfo={waterMgmtInfo}
                        updateWaterMgmt={updateWaterMgmt}
                      />
                    ) : (
                      <ManageFarm
                        field={fieldData.selectedField}
                        deleteFarm={deleteFarm}
                        updateFarm={updateFarm}
                        fields={fieldData.fields}
                        handleFieldSelect={handleFieldSelect}
                        isLoading={isLoading}
                      />
                    )
                  }
                </Box>
              </Box>

              <Footer
                section={step}
                changeSection={changeSection}
                field={fieldData.selectedField}
                section={selectedLayer}
                setSection={setSelectedLayer}
              />
            </Box>
          )
        }
      </Box>

      <PopupManager
        acreageReporting
        droneAnalysis
        soilTesting
        waterManagement
      />
    </Box>
  );
}

FieldManager.propTypes = {
  digitizing: PropTypes.bool.isRequired,
  setDataLayersStep: PropTypes.func.isRequired,
  setPlStep: PropTypes.func.isRequired,
  setSection: PropTypes.func.isRequired,
};
import React from "react";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../../../../constants";
import GreenCheckbox from "../../GreenCheckbox";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginTop: theme.spacing(2),
  },
  form: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
  },
  title: Theme.Title,
}));

const Header = ({
  state,
  setState,
  xAxis,
  yAxis,
  year,
  crop,
  firstVariableOption,
  secondVariableOption,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.title}>
        {`${firstVariableOption.id} for ${crop.join(", ")} vs. ${secondVariableOption.id}`}
      </div>
      <div
        className={classes.form}
        onClick={() =>
          setState((prevState) => ({
            ...prevState,
            isDisaggregated: !prevState.isDisaggregated,
          }))
        }
      >
        <GreenCheckbox
          checked={state.isDisaggregated}
          inputProps={{ "aria-label": "toggle variety disaggregation" }}
        />
        Disaggregate by Variety?
      </div>
    </div>
  );
};

export default Header;

import React, { useEffect, useState, useContext, useRef } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Switch,
  Menu,
  MenuItem,
  TextField,
  Select,
} from "@material-ui/core";
import Autocomplete from '@material-ui/lab/Autocomplete';
import AddIcon from "@material-ui/icons/Add";
import CancelIcon from "@material-ui/icons/Cancel";
import AddBoxOutlinedIcon from "@material-ui/icons/AddBoxOutlined";
import AddLocationIcon from "@material-ui/icons/AddLocation";
import EditLocationIcon from "@material-ui/icons/EditLocation";
import HighlightOffOutlinedIcon from "@material-ui/icons/HighlightOffOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { useSnackbar } from "notistack";
import { FieldContext } from "../Context/FieldContext";
import { UserContext } from "../Context/UserContext";

// MUI components and icons
import { MenuProps } from "../../styles/select";

// image for icon
import scissors from "../../images/scissors.png";
import home from "../../images/houseIcon.png";
import dollar from "../../images/dollarIcon.png";

// Tooltips
import { AgToolTip } from "../../utils/customComponents";
import { CustomToolTip } from "../../utils/customComponents";
import { fieldToolTip } from "./MapFunctions/helpers";
import { SelectionInstructions } from "./Modals/SelectionInstructions";

// Leaflet and Turf
import * as L from "leaflet";
import * as turf from "@turf/turf";
import * as owm from "leaflet-openweathermap";
import * as LE from "esri-leaflet";
import * as wkt from "terraformer-wkt-parser";

// Functions
import { drawMap } from "./MapFunctions/drawMap";
import { drawParcels } from "./MapFunctions/parcelDrawing";
import {
  createField,
  createMultiPolygonField,
  calculateFieldAcres,
} from "./MapFunctions/fieldInteractions";
import { cleanField } from "../FieldSelection/utils/cleanField";
import { exists, numFormat } from "../../utils/helpers";
import { useWindowDimensions } from "../../utils/dimensions";
import {
  getNaipImagery,
  getAvailableNaip,
  getBoundary,
  getStateAndCounty,
  getOWMImagery,
  getFieldsInBounds,
  searchBoundaries,
  getSearchOptions
} from '../../utils/dataFetchers';
import { reprojectCalcArea } from '../../utils/reprojectCalcArea';
import { cloneDeep } from "lodash";
import { Endpoints } from "../../constants/Endpoints";

// Naip
// TODO: move out name functionality to seperate file

// Layer styles
import {
  colorHash,
  stateStyle,
  countyStyle,
  plssStyle,
  sectionStyle,
  cluStyle,
  selectedStyle,
  highlightMousover,
  highlightFeature,
  resetHighlight,
} from "./Styles/layerStyles";
import { blackText } from "../../styles/colors";
import { dsvFormat } from "d3-dsv";
import { result } from "underscore";
import { ca } from "date-fns/locale";

const useStyles = makeStyles((theme) => ({
  mapControls: theme.mapControls,
  mobileControls: {
    ...theme.mapControls,
    padding: 4,
    backgroundColor: "#ffffff",
    borderTop: "1px solid #555555",
  },
  icon: {
    ...theme.greenIcon,
    backgroundColor: "#ffffff",
    border: "1px solid",
    borderRadius: 6,
    paddingRight: 6,
  },
  infoToolTip: theme.infoToolTip,
  button: {
    color: "#67b444",
    backgroundColor: "white",
    margin: "20px 0px",
    display: "flex",
    padding: "10px",
    alignItems: "center",
    fontWeight: 500,
    justifyContent: "center",
    cursor: "pointer",
    borderRadius: "5px",
  },
  searchMethod: {
    zIndex: 1001,
    position: "absolute",
    display: "flex",
    alignItems: "center",
    marginLeft: "50px",
    marginTop: "50px",
  },
  latlngSearch: {
    display: "flex",
    alignItems: "center",
  },
  latLngItem: {
    backgroundColor: "white",
    minWidth: "100px",
    borderRadius: "5px"
  },
  stateSearch: {
    backgroundColor: "white",
    width: "200px",
    borderRadius: "5px"
  },
  countySearch: {
    backgroundColor: "white",
    width: "170px",
    borderRadius: "5px"
  },
  townshipSearch: {
    backgroundColor: "white",
    minWidth: "100px",
    borderRadius: "5px"
  },
  rangeSearch: {
    backgroundColor: "white",
    minWidth: "100px",
    borderRadius: "5px",
    maxWidth: "100px",
    borderRadius: "5px",
  },
  itemContainer: {
    marginLeft: "5px",
  },
}));

const underwritingSearchMethods = [
  { name: "Lat, Lng", value: "latlng" },
  { name: "State, County", value: "county" },
  { name: "Township/Range", value: "township" },
];

/**
 * Map used in ProfitLayers and Data Layers. Allows for field selection,
 * claiming new fields, and object classification.
 * @param {Number} containerHeight So map can set its height with controls bar
 * @param {Function} handleFieldSelect Moves parent component to next section (pending checks)
 * @param {Function} beginClaim Start field claim process by opening modal
 * @param {Object} uploadedField Field uploaded by user to claim
 * @param {Bool} editingClaim User has selected to edit or add more parcels to claiming field
 * @param {Function} saveBoundaryModal Opens save boundary modal
 * @param {Bool} digitzing Disable or change functionality to optimize for digitzing
 * @returns {JSX} map
 */
export function Map({
  containerHeight,
  handleFieldSelect,
  beginClaim,
  uploadedField,
  editingClaim,
  saveBoundaryModal,
  digitizing,
  parcels,
  activeParcel,
  setActiveParcel,
  underwriting,
  userHasNotIntegratedButHasField,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { height, width } = useWindowDimensions();
  const [fieldData, setFieldData] = useContext(FieldContext);
  const user = useContext(UserContext)[0];

  // Create random ID so component may be rendered more than once at a time
  let mapId = Math.floor(Math.random() * Math.floor(10000)).toString();

  const [map, setMap] = useState(null);
  const [zoomLevel, setZoomLevel] = useState(5);

  const [BBOXCoords, setBBOXCoords] = useState("");

  const [selectedSearchMethod, setSelectedSearchMethod] = useState(
    underwritingSearchMethods[0]
  );

  // Create new field from drawn or CLU
  const [newField, setNewField] = useState(null);
  // After all fields are drawn, fit to bounds
  const [fitNewBounds, setFitNewBounds] = useState(false);

  // In use tools
  const [editing, setEditing] = useState(false);
  const [editedBoundary, setEditedBoundary] = useState(null);
  const editingLayer = useRef({});
  const editedLayer = useRef({});

  // Anchor to tool menu
  const [anchorEl, setAnchorEl] = useState(null);

  // Show instruction modal for mobile
  const [showSelectionInstructions, setShowSelectionInstructions] = useState(
    ""
  );

  // CLU toggles. state needed for switch, ref for drawMap
  const [enableClus, setEnableClus] = useState(true);
  const showClus = useRef(true);

  // Layers
  const stateLayer = useRef(
    L.geoJSON(null, {
      style: stateStyle,
      onEachFeature: highlightMousover,
    })
  );

  const countyLayer = useRef(
    L.geoJSON(null, {
      style: countyStyle,
      onEachFeature: highlightMousover,
    })
  );

  const plssLayer = useRef(
    L.geoJSON(null, {
      style: plssStyle,
      onEachFeature: highlightMousover,
    })
  );

  const sectionLayer = useRef(
    L.geoJSON(null, {
      style: sectionStyle,
      onEachFeature: highlightMousover,
    })
  );

  const cluLayer = useRef(
    L.geoJSON(null, {
      style: cluStyle,
      onEachFeature: cluEvents,
      pmIgnore: false,
    })
  );

  const fieldLayer = useRef(L.featureGroup(null));
  const inBounds = useRef(L.layerGroup());
  const drawnPolygons = useRef(L.featureGroup(null));
  const ssurgoLayers = useRef(L.geoJSON(null));

  const markers = useRef(L.featureGroup(null));
  const polygons = useRef(L.featureGroup(null));

  // Currently using draw tools. Used to block layer draws
  const drawingMode = useRef(false);
  const [drawing, setDrawing] = useState(false);

  // NAIP
  const naips = useRef({});
  const naipYears = useRef([]);
  const controls = useRef({});
  const block = useRef(false); // used to block Naip notifications
  const naipTile = useRef(
    L.tileLayer(null, { maxZoom: 20, crossOrigin: "use-credentials" })
  );

  // Radar
  const radarLayers = useRef([]);
  const index = useRef(0);
  // Toggle for radar map
  const showRadar = useRef(true);
  const [isRadar, setIsRadar] = useState(true)

  //Open weather map options
  const owmOptions = useRef({})
  const owm = useRef([])
  const [selectedOwm, setSelectedOwm] = useState("")
  const [owmImage, setOWMImage] = useState(null)

  //ESRI NAIP
  const showEsriNaip = useRef(false)
  const [isEsriNaip, setIsEsriNaip] = useState(false)
  const naipImageLayer = useRef(null)

  //Land Value items
  const valueRadius = useRef(L.geoJSON())
  const inputProperty = useRef(null)
  const foundParcels = useRef([])
  const parcelLayer = useRef(L.geoJSON())
  const [searchLatLng, setSearchLatLng] = useState(null)
  const [stateOptions, setStateOptions] = useState([])
  const [countyOptions, setCountyOptions] = useState([])
  const [townshipOptions, setTownshipOptions] = useState([])
  const [rangeOptions, setRangeOptions] = useState([])
  const [stateInput, setStateInput] = useState(null)
  const [countyInput, setCountyInput] = useState(null)
  const [townshipInput, setTownshipInput] = useState(null)
  const [rangeInput, setRangeInput] = useState(null)
  const [searchState, setSearchState] = useState(null)
  const [searchCounty, setSearchCounty] = useState(null)
  const [searchTownship, setSearchTownship] = useState(null)
  const [searchRange, setSearchRange] = useState(null)

  // draw map when component mounts
  useEffect(() => {
    if (map === null) {
      try {
        drawMap(
          stateLayer,
          countyLayer,
          plssLayer,
          sectionLayer,
          cluLayer,
          fieldLayer,
          inBounds,
          setMap,
          controls,
          setZoomLevel,
          setNewField,
          showClus,
          digitizing,
          drawFieldsInBounds,
          mapId,
          showEsriNaip,
          valueRadius,
          underwriting, // using the underwriting bool to pass as 'hideSearchBar' param to hide it in underwriting app
          underwriting
        );
      } catch (err) {
        // Incase generate duplicate ID
        mapId = Math.floor(Math.random() * Math.floor(10000)).toString();
      }
    }
  }, [map, mapId]);

  useEffect(() => {
    //get county options
    if(searchState !== null){
      console.log("Search state", searchState)
      getCountyOptions(searchState.STATEFP)
    }
  },[searchState])

  useEffect(() => {
    if(searchCounty !== null){
      //get township range info
      console.log("Search county", searchCounty)
      getTownshipRangeOptions(searchCounty.STATEFP, searchCounty.COUNTYFP)
    }
  },[searchCounty])

  useEffect(() => {
    if (map !== null) {
      // to load radar map
      loadRadar();
      drawNAIP();
    }
  }, [map]);

  useEffect(() => {
    if (map !== null && BBOXCoords !== "") {
      if (fieldData.selectedField.farmId !== "") {
        // send request for fields in bounds
        fieldsInBounds();
      }
    }
  }, [map, BBOXCoords, fieldData.selectedField]);

  useEffect(() => {
    if (uploadedField !== null) {
      setNewField({
        feature: uploadedField.feature,
        type: "uploaded",
        layer: L.GeoJSON.geometryToLayer(uploadedField.feature),
      });
    }
  }, [uploadedField]);

  useEffect(() => {
    if (editedBoundary) {
      const updatedField = JSON.parse(JSON.stringify(fieldData.fieldToClaim));
      updatedField.points = editedBoundary.geometry.coordinates;
      updatedField.geometry = editedBoundary.geometry;
      updatedField.features = editedBoundary;
      updatedField.boundary = JSON.stringify(editedBoundary);
      updatedField.acres = turf.convertArea(
        turf.area(editedBoundary),
        "meters",
        "acres"
      );
      const centroid = turf.centroid(editedBoundary);
      updatedField.latitude = centroid.geometry.coordinates[1];
      updatedField.longitude = centroid.geometry.coordinates[0];

      updatedField.id = fieldData.selectedField.id;
      updatedField.orgId = fieldData.selectedField.orgId;
      updatedField.boundaryId = fieldData.selectedField.boundaryId;
      updatedField.org = fieldData.selectedField.org;
      if (underwriting) {
        setFieldData({
          ...fieldData,
          fieldToClaim: updatedField,
        });
      } else {
        setFieldData({
          ...fieldData,
          fieldToClaim: updatedField,
          selectedField: {
            id: "",
            name: "",
            farmId: "",
            farm: "",
            orgId: "",
            org: "",
            boundaryId: "",
          },
        });
      }
    }
  }, [editedBoundary]);

  useEffect(() => {
    if (editing) {
      // need to do this here to has access to fresh state
      if (fieldData.fieldToClaim.feature !== null) {
        drawField(fieldData.fieldToClaim, true);
      } else if (fieldData.selectedField.id !== "") {
        drawField(fieldData.selectedField, true);
      }
    }
  }, [editing]);

  useEffect(() => {
    // After an org or farm has been selected, fieldData.fields will populate
    // with 20-30 fields to be drawn on map for user to chose from
    if (map !== null) {
      if (!digitizing) {
        clearDrawn();
        clearLayers();
      }

      // once map is drawn hide overlay button
      try {
        // check to see that options don't currently exist
        const options = document.getElementsByClassName(
          "leaflet-control-layers-selector"
        );
        if (options.length === 0) {
          const control = document.getElementsByClassName(
            "leaflet-control-layers"
          )[0];
          control.style.display = "none";
        }
      } catch (err) {
        console.error("couldn't locate leaflet overlay control");
      }

      // Toggle off CLUs for logged in user with fields drawn on map
      if (
        (fieldData.fields.length > 0 || fieldData.selectedField.id !== "") &&
        user.isAuthenticated
      ) {
        showClus.current = false;
        setEnableClus(false);
      }

      for (const field of fieldData.fields) {
        if (field.id !== fieldData.selectedField.id) {
          drawField(field);
        }
      }

      if (
        !exists(fieldData.selectedField.id) &&
        !exists(fieldData.fieldToClaim.feature)
      ) {
        // After all fields are drawn, fit to bounds if no field is selected
        setFitNewBounds(true);
      } else if (exists(fieldData.selectedField.id)) {
        // draw selected field and snap to bounds if selected
        drawField(fieldData.selectedField, !digitizing);
      } else if (exists(fieldData.fieldToClaim.feature)) {
        // draw field to claim and snap to bounds if selected
        drawField(fieldData.fieldToClaim, !digitizing);
      }
    }
  }, [fieldData.fields, fieldData.selectedField, fieldData.fieldToClaim, map]);

  useEffect(() => {
    // perfrom potential breaking checks and that individual field not selected
    if (
      fitNewBounds &&
      fieldLayer.current !== null &&
      fieldLayer.current.getBounds() !== null &&
      map !== null
    ) {
      // get field layer bounds
      const bounds = fieldLayer.current.getBounds();
      // check to make has properties to fit
      if (bounds.hasOwnProperty("_southWest")) {
        map.fitBounds(bounds);
      }
    }
    setFitNewBounds(false);
  }, [fieldLayer.current, fitNewBounds]);

  useEffect(() => {
    // New field has been selected
    if (map !== null && exists(fieldData.selectedField.boundary)) {
      drawField(fieldData.selectedField, true);

      // clear out existing options
      const overlays = document.getElementsByClassName(
        "leaflet-control-layers-overlays"
      )[0];
      overlays.innerHTML = "";

      // clear any existing naips from map
      // if(Object.keys(naips.current))
      for (const i in naips.current) {
        if (naips.current[i].overlay !== null) {
          naips.current[i].overlay.remove();
        }
      }

      getNaip();
    }
  }, [fieldData.selectedField, map]);

  useEffect(() => {
    // New field has been selected
    if (map !== null && exists(fieldData.fieldToClaim.boundary)) {
      drawField(fieldData.fieldToClaim, !digitizing);
    }
  }, [fieldData.fieldToClaim, map]);

  useEffect(() => {
    if (newField && fieldData.fieldToClaim) {
      handleNewField();
    }
  }, [newField]);

  useEffect(() => {
    if (zoomLevel > 5) {
      handleRadarSwitch(false);
    }
    if (selectedOwm !== "") {
      handleOWMSelect(selectedOwm);
    }
  }, [zoomLevel]);

  useEffect(() => {
    // delete old marker/poly
    markers.current.clearLayers(); // delete old marker/poly
    polygons.current.clearLayers(); // delete old marker/poly
    if (isEsriNaip) {
      GetLatestNAIPDateForCentroidOfMap(map, markers.current, polygons.current);
      // add new marker/poly
      markers.current.addTo(map); // delete old marker/poly
      polygons.current.addTo(map); // delete old marker/poly
    }

    if (isEsriNaip) {
      naipImageLayer.current = LE.imageMapLayer({
        url:
          "https://naip.imagery1.arcgis.com/arcgis/rest/services/NAIP/ImageServer",
      });
      naipImageLayer.current.addTo(map);
    } else {
      // remove esri naip layer
      if (
        naipImageLayer.current !== null &&
        map.hasLayer(naipImageLayer.current)
      ) {
        console.log(naipImageLayer.current);
        map.removeLayer(naipImageLayer.current);
      }
      // console.log(map)
    }
  }, [isEsriNaip]);

  useEffect(() => {
    if (parcels !== undefined && map !== null && parcels !== null) {
      drawParcels(
        parcels,
        map,
        valueRadius,
        inputProperty,
        foundParcels,
        parcelLayer,
        setActiveParcel
      );
    } else if (parcels == null && map !== null) {
      if (map.hasLayer(valueRadius.current)) {
        map.removeLayer(valueRadius.current);
      }
      if (map.hasLayer(inputProperty.current)) {
        map.removeLayer(inputProperty.current);
      }
      for (const item of foundParcels.current) {
        if (map.hasLayer(item)) {
          map.removeLayer(item);
        }
      }
    }
  }, [map, parcels]);

  useEffect(() => {
    if (map && userHasNotIntegratedButHasField) map.invalidateSize();
  }, [map, userHasNotIntegratedButHasField])

  const findParcelGeoJson = (parcels, latlng) => {
    let parcelGeo = null;
    let parcelID = null;
    for (const i in parcels.parcels) {
      const item = parcels.parcels[i];
      for (const parcel of item.parcels) {
        const bounds = L.geoJSON(parcel.GeoJSON).getBounds();
        if (bounds.contains(latlng)) {
          parcelGeo = parcel.GeoJSON;
          parcelID = i;
          break;
        }
      }
      if (parcelGeo !== null) {
        break;
      }
    }

    // updated field context with the selected index so that the table component can expand it
    if (parcelID !== null) {
      const parcelNdx = Object.keys(parcels.parcels).indexOf(parcelID);
      if (parcelNdx !== -1) {
        const fieldUpdate = { ...fieldData };
        fieldUpdate.activeParcel = {
          index: parcelNdx,
          parcelMarkers: foundParcels,
        };
        setFieldData(fieldUpdate);
      }
    }

    return parcelGeo;
  };

  const getStateOptions = async () => {
    try{  
      let states = await getSearchOptions("state");
      let stateresults = JSON.parse(states)
      console.log(stateresults)
      setStateOptions(stateresults)
    }
    catch(e){
      console.log(e)
    }
  }

  const getCountyOptions = async (stateFP) => {
    try{
      let counties = await getSearchOptions("county", stateFP)
      let countyResults = JSON.parse(counties)
      console.log(countyResults)
      setCountyOptions(countyResults)
    }
    catch(e){
      console.log(e)
    }
  }

  const getTownshipRangeOptions = async (stateFP, countyFP) => {
    try{
      let twnsRange = await getSearchOptions("twnsRange", stateFP, countyFP)
      let results = JSON.parse(twnsRange)
      console.log(results)
      //format results to set the appropriate options
      let townships = []
      for(const item of results){
        let twnName = item.TOWNSHIP
        if(townships.filter(x => x.TOWNSHIP === twnName).length === 0){
          let obj = {
            TOWNSHIP: twnName,
            RANGES: results.filter(x => x.TOWNSHIP === twnName).map(y => ({NAME:y.RANGE, TOWNSHIP:y.TOWNSHIP, STATEFP: stateFP, COUNTYFP: countyFP})),
            STATEFP: stateFP,
            COUNTYFP: countyFP
          }
          townships.push(obj)
        }
      }
      console.log(townships)
      setTownshipOptions(townships)
    }
    catch(e){
      console.log(e)
    }
  }

  const handleNewField = async () => {
    // console.log("Claiming new field")
    let points = [];
    let { geometry } = newField.feature;
    let { feature } = newField;
    
    // Use contractor's reprojection code to project to UTM zone and get CLU area
    const reprojObj = reprojectCalcArea(geometry, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
    let acres = reprojObj.area;

    // console.log('newField:', newField, acres);
    // console.log("relevant vars:", !editingClaim, fieldData.fieldToClaim.points);

    // if user has not selected a clu or drawn a field and has not selected
    // option to edit claim or add more parcels, create a new field
    if (!editingClaim || fieldData.fieldToClaim.points.length === 0) {
      // NOTE: Seems like this is the only conditional that gets hit as `!editingClaim` is always true...
      if (newField.feature.geometry.coordinates.length > 1) {
        const multiCoords = [];
        for (const coord of newField.feature.geometry.coordinates) {
          multiCoords.push([coord]);
        }
        points = multiCoords;
      } else {
        // No field to claim has been selected yet
        points = newField.feature.geometry.coordinates;
      }
    } else if (fieldData.fieldToClaim.points.length === 1) {
      // begin creating multi polygon field

      // Get new acres, accounting for overlapping polygons
      acres = calculateFieldAcres(
        fieldData.fieldToClaim.geometry,
        newField.feature,
        fieldData.fieldToClaim.acres,
        "polygon"
      );

      // add new coordinates to points array
      points = [
        fieldData.fieldToClaim.points,
        newField.feature.geometry.coordinates,
      ];

      // checkIntersection()

      geometry = {
        type: "MultiPolygon",
        coordinates: points,
      };
      feature = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry,
          },
        ],
      };
    } else if (fieldData.fieldToClaim.points.length > 1) {
      // Get new acres, accounting for overlapping polygons
      acres = calculateFieldAcres(
        fieldData.fieldToClaim.coordinates,
        newField.feature,
        fieldData.fieldToClaim.acres,
        "multipolygon"
      );

      // if points array is nested, spread it out
      points = [
        ...fieldData.fieldToClaim.points,
        newField.feature.geometry.coordinates,
      ];

      // checkIntersection(true)

      geometry = {
        type: "MultiPolygon",
        coordinates: points,
      };
      feature = {
        type: "FeatureCollection",
        features: [
          {
            type: "Feature",
            geometry,
          },
        ],
      };
    }

    // boundary for draw
    const boundary = JSON.stringify(feature);

    // fit to new bounds
    if (!digitizing) {
      const newCollection = L.geoJson(feature);
      map.fitBounds(newCollection.getBounds());
    }

    const centroid = turf.centroid(feature);
    const [longitude, latitude] = centroid.geometry.coordinates;
    const [state, county] = await getStateAndCounty(latitude, longitude);
    const { coordinates } = geometry;

    // set Field To Claim with updated data
    const field = {
      acres,
      boundary,
      coordinates,
      county,
      geometry,
      feature,
      latitude,
      longitude,
      points,
      state,
    };

    setFieldData({
      ...fieldData,
      fieldToClaim: field,
      selectedField: {
        id: "",
        name: "",
        farmId: "",
        farm: "",
        orgId: "",
        org: "",
        boundaryId: "",
      },
    });

    // Remove poly layer
    map.removeLayer(newField.layer);

    // No longer drawing
    drawingMode.current = false;
    setDrawing(false);

    // Begin field claim process
    beginClaim();
  };

  const checkIntersection = (multi = false) => {
    // const claimingField = JSON.parse(JSON.stringify(fieldData.fieldToClaim))
    const claimingField = cloneDeep(fieldData.fieldToClaim);
    const cleanPoints = [];

    for (const polyPoints of fieldData.fieldToClaim.points) {
      const points = !multi ? polyPoints : polyPoints[0];
      const polyFeature = {
        type: "Feature",
        geometry: {
          type: "Polygon",
          coordinates: [points],
        },
      };
      const intersect = turf.intersect(newField.feature, polyFeature);
      if (intersect === null) {
        // no intersection, add to array
        cleanPoints.push(points);
      } else {
        cleanPoints.push(intersect.geometry.coordinates[0]);
      }
    }
    claimingField.points = cleanPoints;
    claimingField.geometry.coordinates = cleanPoints;

    const updatedBoundary = JSON.parse(claimingField.boundary);
    updatedBoundary.geometry.coordinates = cleanPoints;
    claimingField.boundary = JSON.stringify(updatedBoundary);

    // setFieldData({
    //   ...fieldData,
    //   fieldToClaim: claimingField
    // })
  };

  const fieldsInBounds = async () => {
    const fields = await getFieldsInBounds(
      BBOXCoords,
      fieldData.selectedField.farmId,
      fieldData.selectedField.orgId
    );

    if (fields !== undefined) {
      // quick formatting
      for (const field of fields) {
        if (field.state === "NULL") {
          field.state = "";
        }
        if (field.county == "NULL") {
          field.county = "";
        }
      }

      const onField = [];
      fieldLayer.current.eachLayer((layer) => {
        const layerFieldID = layer.feature.properties.fieldID;
        onField.push(layerFieldID);
      });
      // get only fields not already on map
      const notOnField = fields.filter((x) => !onField.includes(x.FieldID));

      // loop over remaining fields and add to map
      for (const fieldInfo of notOnField) {
        const geo = JSON.parse(fieldInfo.geojson);

        // Add in all the other important keys for selectedField
        const orgId = fieldData.selectedField.orgId;
        const orgName = fieldData.selectedField.org;
        const farmId = fieldData.selectedField.farmId;
        const farmName = fieldData.selectedField.farm;
        const field = await cleanField(fieldInfo, orgId, orgName, farmId, farmName, fieldInfo.name);

        const newCollection = L.geoJson(geo, {
          onEachFeature: (feature, layer) => {
            fieldLayer.current.addLayer(layer).setStyle({
              color: "#8e24aa",
              fillColor: "#f3e5f5",
              opacity: 1,
              fillOpacity: 0,
            });

            layer.feature.properties.fieldID = field.FieldID;

            // Hoverable info for field
            const toolTip = fieldToolTip(field);
            layer.bindTooltip(toolTip, { className: "leaftletTooltipClass" });

            fieldLayer.current.on("mouseover", (e) => {
              e.layer.setStyle({ color: "#6a1b9a", fillOpacity: 0.05 });
            });

            fieldLayer.current.on("mouseout", (e) => {
              e.layer.setStyle({ color: "#8e24aa", fillOpacity: 0 });
            });

            fieldLayer.current.on("click", (e) => {
              e.layer.setStyle({
                color: "#561280",
                opacity: 0.99,
                fillOpacity: 0,
              });
            });

            layer.on("pm:edit", ({ layer, shape }) => {
              // layer is being edited
            });

            layer.on("pm:update", ({ layer, shape }) => {
              setEditedBoundary(layer.toGeoJSON());
            });

            layer.on("pm:cut", ({ layer }) => {
              // layer has been cut
            });
            layer.on({
              click: (e) => {
                // field in our system
                block.current = true;
                handleFieldSelect(field);
              },
            });
          },
        }).addTo(map);
      }
    }
  };

  const handleCluSwitch = (checked, startDraw = false) => {
    /**
     * Update state (needed for switch control and re-renders) and ref (needed
     * for map draw controls) on switch change. After checking that map is
     * rendered, if switch is false, remove layer. If setting to true, set map
     * zoom to level CLUs are drawn at. A map event is needed to fire draw
     * function which is initiated inside of drawMap function.
     * @type {Object} event Event from switch
     */

    showClus.current = checked;
    setEnableClus(checked);

    try {
      if (map !== null) {
        if (checked === false) {
          map.removeLayer(cluLayer.current);
          if (startDraw && zoomLevel > 13) {
            if (fieldData.selectedField.id === "" || !user.isAuthenticated) {
              // Start draw if user is zoomed in enough.
              // Unless they are logged in and have a field selected.
              addPolygon();
              enqueueSnackbar(
                "You can begin drawing your field boundary by clicking any point on the map"
              );
            }
          }
        } else {
          // if map is zoomed to far out for clu display, zoom to that point
          if (zoomLevel < 14) {
            map.setZoom(14);
          } else {
            // need some kind of map even to start clu draw
            map.setZoom(zoomLevel);
          }
        }
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleRadarSwitch = (checked) => {
    showRadar.current = checked;
    setIsRadar(checked);
    // loadRadar(checked)
  };

  const handleEsriNaipDisplay = (checked) => {
    showEsriNaip.current = checked;
    setIsEsriNaip(checked);
    console.log(map);
  };

  const clearLayers = () => {
    // clear current layers
    map.removeLayer(cluLayer.current);
    map.removeLayer(sectionLayer.current);
    map.removeLayer(plssLayer.current);
    map.removeLayer(fieldLayer.current);
    fieldLayer.current.clearLayers();
  };

  const clearDrawn = () => {
    map.eachLayer((layer) => {
      try {
        // we can use this test to identify if the layer contains a drawn feature or not
        // and if so - we can remove it
        const currLayerCoords = layer.feature.geometry.coordinates[0];
        if (isUserLayer(layer)) {
          map.removeLayer(layer);
        }
      } catch (err) {
        // console.log(err)
      }
    });
  };

  const isUserLayer = (layer) => {
    const state = stateLayer.current.hasLayer(layer);
    const county = countyLayer.current.hasLayer(layer);
    const plss = plssLayer.current.hasLayer(layer);
    const section = sectionLayer.current.hasLayer(layer);
    const clu = cluLayer.current.hasLayer(layer);
    const notGov = !state && !county && !plss & !section & !clu;
    return notGov;
  };

  const clear = () => {
    clearDrawn();
    clearLayers();
    setFieldData({
      fields: [],
      selectedField: {
        id: "",
        name: "",
        farmId: fieldData.selectedField.farmId,
        farm: fieldData.selectedField.farm,
        orgId: fieldData.selectedField.orgId,
        org: fieldData.selectedField.org,
      },
      fieldToClaim: {
        feature: null,
        geometry: {},
        coordinates: [],
        points: [],
        acres: 0,
      },
    });
  };

  const addPolygon = () => {
    // start polygon draw
    drawingMode.current = true;
    setDrawing(true);
    handleCluSwitch(false);
    handleRadarSwitch(false);

    const draw = document.getElementsByClassName(
      "control-icon leaflet-pm-icon-polygon"
    );
    draw[0].click();
  };

  const addSquare = () => {
    drawingMode.current = true;
    setDrawing(true);
    handleCluSwitch(false);
    handleRadarSwitch(false);
    const draw = document.getElementsByClassName(
      "control-icon leaflet-pm-icon-rectangle"
    );
    draw[0].click();
  };

  const editBoundary = () => {
    // drawingMode.current = true

    // switch CLUs off
    handleCluSwitch(false);
    handleRadarSwitch(false);
    setEditing(true);

    // Clear layers so can only edit fieldToClaim
    clearDrawn();

    const control = document.getElementsByClassName(
      "control-icon leaflet-pm-icon-edit"
    )[0];
    fieldLayer.current.pm.enable();
    control.click();
  };

  const finishEdit = () => {
    setEditing(false);
    const control = document.getElementsByClassName(
      "leaflet-pm-action  action-finishMode"
    );
    control[0].click();
    if (fieldData.fieldToClaim.feature !== null) {
      beginClaim();
    } else if (fieldData.selectedField.id !== "") {
      saveBoundaryModal();
    }
  };

  const cancelClickHandler = () => {
    map.pm.Draw.disable();
    setDrawing(false);
    drawingMode.current = false;
  };

  // CLU selection
  function cluEvents(feature, layer) {
    layer.on({
      mouseover: highlightFeature,
      mouseout: resetHighlight,
      click: selectClu,
    });
  }

  async function selectClu(e) {
    // sets new field from clu feature
    const layer = e.target;
    setNewField({ feature: layer.feature, type: "clu", layer });
  }

  /**
   * Draws a field boundary on map and adds appropriate functions to its layer.
   * Convert stored field boundary to geoJson.
   * If selected field, fit map to its bounds.
   * @param  {Object}  field Field data, including boundary and name
   * @param  {Boolean} [snapTo=false] If selected field, snap to its bounds
   */
  const drawField = (field, snapTo = false) => {
    try {
      const features = JSON.parse(field.boundary);
      const geo = {
        type: "FeatureCollection",
        features: [features],
      };

      const newCollection = L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          fieldLayer.current.addLayer(layer).setStyle({
            color: "#8e24aa",
            fillColor: "#f3e5f5",
            opacity: 1,
            fillOpacity: 0,
          });
          // console.log(layer.feature)
          try {
            layer.feature.properties = { fieldID: field.id };
          } catch (err) {
            console.log(err);
          }

          // console.log(layer.feature.properties)

          // Hoverable info for field
          const toolTip = fieldToolTip(field);
          layer.bindTooltip(toolTip, { className: "leaftletTooltipClass" });

          fieldLayer.current.on("mouseover", (e) => {
            e.layer.setStyle({ color: "#6a1b9a", fillOpacity: 0.05 });
          });

          fieldLayer.current.on("mouseout", (e) => {
            e.layer.setStyle({ color: "#8e24aa", fillOpacity: 0 });
          });

          fieldLayer.current.on("click", (e) => {
            e.layer.setStyle({
              color: "#561280",
              opacity: 0.99,
              fillOpacity: 0,
            });
          });

          layer.on("pm:edit", ({ layer, shape }) => {
            // layer is being edited
          });

          layer.on("pm:update", ({ layer, shape }) => {
            setEditedBoundary(layer.toGeoJSON());
          });

          layer.on("pm:cut", ({ layer }) => {
            // layer has been cut
          });
          layer.on({
            click: (e) => {
              if (field.id !== undefined) {
                // field in our system
                block.current = true;
                handleFieldSelect(field);
              } else {
                // field to claim
                beginClaim();
              }
            },
          });
        },
      }).addTo(map);

      if (snapTo) {
        if(field.state === "" && field.county === ""){
          map.setMaxZoom(17)
        }
        else{
          map.setMaxZoom(18)
        }
        map.fitBounds(newCollection.getBounds(), { padding: [30, 30] });
      }

      // to load radar map
      // getOwm()
    } catch (err) {
      console.log(err);
    }
  };

  // Function to get latest NAIP date and populate pin and polygon of tile.
  function GetLatestNAIPDateForCentroidOfMap(map, markers, polygons) {
    const width = map.getBounds().getEast() - map.getBounds().getWest();
    const height = map.getBounds().getNorth() - map.getBounds().getSouth();

    // Get the latest date of the centroid for map using query object for ESRI Image Server
    const query = new LE.query({
      url:
        "https://naip.imagery1.arcgis.com/arcgis/rest/services/NAIP/ImageServer/",
    });

    // Set up query with necessary options for finding last date.
    query.where("SrcImgDate is not null"); // condition in query to filter null tiles
    query.intersects(map.getCenter()); // intersect with center of screen. This is where we'll drop pin.
    query.orderBy("SrcImgDate", "DESC"); // order by date desc to get last time
    query.limit(1); // limit to top 1 result, so this will get latest image date

    // Run query against service to count records, ensure ordered and ensure 1.
    // This is only for testing and dev and can be left or deleted.
    query.count((error, count, response) => {
      if (error) {
        console.log(error);
      }
      // console.log('Found Top ' + count + ' intersecting tiles for this query.');
    });

    let LatestNAIPDateForCentroidOfMap = "";

    // Query image service. THis is a build in esri leaflet function for use with esri image servers.
    // https://esri.github.io/esri-leaflet/api-reference/tasks/query.html
    // This will run the query to get the last date and find that centroid tile
    // and return the polygon and pin with pop up and toast.
    query.run((error, featureCollection, response) => {
      if (error) {
        // console.log(error);
        return;
      }

      LatestNAIPDateForCentroidOfMap = DisplayFriendlyDate(
        featureCollection.features[0].properties.SrcImgDate
      );

      // sleep (1000) // 1 second. For testing/dev.
      // console.log(featureCollection.features[0]);
      // console.log('Found latest date of centroid: ' +
      //   DisplayFriendlyDate(featureCollection.features[0].properties.SrcImgDate) + ' in yyymmdd format');

      // Show toast (optional, mostly for fun)
      // Display Lat/Long of centroid and last date imagery avail.
      const options = { settings: { duration: 1000 } };
      // iqwerty.toast.toast(
      // map.getCenter() + ' -- Date of Latest NAIP Imagery:' + LatestNAIPDateForCentroidOfMap
      // , options);

      // Create dynamic marker and extract polygon of last tile.
      const marker = L.marker(map.getCenter()).addTo(markers);
      const exteriorStyle = {
        weight: 1,
        fillOpacity: 0,
      };
      const polygon = L.geoJSON(featureCollection.features[0], {
        style: exteriorStyle,
      }).addTo(polygons);
      marker
        .bindPopup(
          `Date of Latest NAIP Imagery:${LatestNAIPDateForCentroidOfMap}`
        )
        .openPopup();
    });
  }

  // Helper Function for formatting and displaying friendly date for popup (required)
  function DisplayFriendlyDate(str) {
    // Convert YYYYMMDD -> month DD, YYYY (e.g.,20121217 -> December 17, 2012
    // validate year as 4 digits, month as 01-12, and day as 01-31
    if (
      (str = str
        .toString()
        .match(/^(\d{4})(0[1-9]|1[0-2])(0[1-9]|[12]\d|3[01])$/))
    ) {
      // make a date
      str[0] = new Date(+str[1], +str[2] - 1, +str[3]);
      // check if month stayed the same (ie that day number is valid)
      if (str[0].getMonth() === +str[2] - 1)
        var options = { year: "numeric", month: "long", day: "numeric" };
      const dateFormatted = new Date(str[0]);
      // Tweak it to get correct date format.
      return dateFormatted.toLocaleDateString("en-US", options);
    }
    return undefined;
  }

  const drawNAIP = () => {
    map.on("zoomend", (e) => {
      // On zoom get the latest date of the centroid for map and alert with toast
      // The polygon and pin represent the NAIP tile at the Centroid of map
      // current extent and displays the last NAIP image date.

      // delete old marker/poly
      markers.current.clearLayers(); // delete old marker/poly
      polygons.current.clearLayers(); // delete old marker/poly
      if (showEsriNaip.current) {
        GetLatestNAIPDateForCentroidOfMap(
          map,
          markers.current,
          polygons.current
        );
        // add new marker/poly
        markers.current.addTo(map); // delete old marker/poly
        polygons.current.addTo(map); // delete old marker/poly
      }
    });

    // Update on drag events
    map.on("dragend", () => {
      // On drag get the latest date of the centroid for map and alert with toast
      // The polygon and pin represent the NAIP tile at the Centroid of map
      // current extent and displays the last NAIP image date.

      // delete old marker/poly
      markers.current.clearLayers();
      polygons.current.clearLayers();

      if (showEsriNaip.current) {
        // Function to update pin/polygon and get last date image for the centroid
        GetLatestNAIPDateForCentroidOfMap(
          map,
          markers.current,
          polygons.current
        );
        // add new marker/poly
        markers.current.addTo(map);
        polygons.current.addTo(map);
      }
    });

    // Add NAIP baselayer from NAIP Azure Image Server using Esri Leaflet
    // Map will autoupdate when dragged or zoomed.
    // Note by default this service will show last available.

    if (showEsriNaip.current) {
      naipImageLayer.current = LE.imageMapLayer({
        url:
          "https://naip.imagery1.arcgis.com/arcgis/rest/services/NAIP/ImageServer",
      });
      naipImageLayer.current.addTo(map);
    }
  };

  const drawFieldsInBounds = (bounds) => {
    const max = bounds.getNorthEast();
    const min = bounds.getSouthWest();
    const box = {
      xmin: min.lng,
      ymin: min.lat,
      xmax: max.lng,
      ymax: max.lat,
    };
    const bboxCoords = `POLYGON ((${box.xmax} ${box.ymax}, ${box.xmax} ${box.ymin}, ${box.xmin} ${box.ymin}, ${box.xmin} ${box.ymax},${box.xmax} ${box.ymax}))`;
    setBBOXCoords(bboxCoords);
  };

  // NAIP
  const getNaip = async () => {
    // Get available years

    const yearStr = await getAvailableNaipYears(
      fieldData.selectedField.boundary
    );
    if (yearStr === null) {
      return;
    }
    try {
      const control = document.getElementsByClassName(
        "leaflet-control-layers"
      )[0];
      control.style.display = "block";
    } catch (err) {
      // console.log("couldn't find controls")
      return;
    }

    const years = yearStr.split(",");
    naipYears.current = years;
    naips.current = {};
    for (const year of naipYears.current) {
      naips.current[year] = { overlay: null };
    }
    const optionsSet = setNaipOptions(naipYears.current);
  };

  const getOwm = async () => {
    // console.log('getOwm')
    try {
      const control = document.getElementsByClassName(
        "leaflet-control-layers"
      )[0];
      control.style.display = "block";
    } catch (err) {
      // console.log("couldn't find controls")
      return;
    }

    const options = ["temp", "clouds", "pressure", "rain", "wind"];
    owm.current = options;
    owmOptions.current = {};
    for (const op of owm.current) {
      owmOptions.current[op] = { overlay: null };
    }

    openWeatherMap(owm.current);
  };

  const getNaipTiles = (year) => {
    const tileUrl = Endpoints.BASEURL + Endpoints.NAIPTILE;
    const tileReq = `${tileUrl}?z={z}&x={x}&y={y}&year=${year}`;
    naipTile.current.setUrl(tileReq);
    if (!map.hasLayer(naipTile.current)) {
      naipTile.current.addTo(map);
    }
  };

  const requestNaipImagery = async (year, boundary) => {
    const selected = JSON.parse(fieldData.selectedField.boundary);

    const naipReq = {
      aoi: JSON.stringify(selected),
      years: JSON.stringify([year]),
      display_option: "True_color",
      result_type: "png",
      Projection: "EPSG:3857",
      Inflation_Rate: "0.1",
    };
    let res;
    if (!block.current) {
      enqueueSnackbar(`Loading NAIP Imagery for ${year}`, {
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        autoHideDuration: 4000,
      });
    }
    try {
      res = await getNaipImagery(naipReq);
    } catch (err) {
      console.log(err);
      naips.current[year] = { overlay: null, loading: false };
      return null;
    }
    if (res === undefined) {
      naips.current[year] = { overlay: null, loading: false };
      return;
    }
    const info = JSON.parse(res);
    for (const i in info) {
      if (typeof info[i] !== "string") {
        let extent = info[i].Extent.split(", ");
        extent = extent.map((x) => parseFloat(x));

        const imgbounds = [
          L.CRS.EPSG3857.unproject(L.point([extent[0], extent[1]])),
          L.CRS.EPSG3857.unproject(L.point([extent[2], extent[3]])),
        ];
        const bounds = L.latLngBounds(imgbounds[0], imgbounds[1]);

        // check that bounds intersect
        try {
          const intersects = bounds.intersects(L.geoJSON(selected).getBounds());
          // let intersects = true;
          if (intersects) {
            map.setMaxZoom(20);
            const overlay = L.imageOverlay();
            overlay.setUrl(info[i].file);
            overlay.setBounds(bounds);
            naips.current[i].overlay = overlay;
            naips.current[i].loading = false;
          }
        } catch (err) {
          console.log("Naip intersection error");
          return;
        }
      } else {
        naips.current[i].overlay = null;
        naips.current[i].loading = false;
      }
    }
    // for(const year of naipYears.current){
    //   if(naips.current[year] === undefined){
    //     naips.current[year] = {overlay : null, loading: false}
    //   }
    // }

    if (!block.current) {
      enqueueSnackbar(`NAIP Imagery Loaded For ${year}`, {
        anchorOrigin: { horizontal: "center", vertical: "bottom" },
        autoHideDuration: 4000,
      });
    }
  };

  const getAvailableNaipYears = async (boundary) => {
    const bounds = getGeom();
    // let center = L.geoJSON(JSON.parse(fieldData.selectedField.boundary)).getBounds().getCenter()
    const center = L.geoJSON(JSON.parse(boundary)).getBounds().getCenter();
    // get state based off center point
    const [state, county] = await getStateAndCounty(center.lat, center.lng);

    // use state to get available years
    if (!exists(state)) {
      return null;
    }
    try {
      const res = await getAvailableNaip(state);
      const years = JSON.parse(res);
      if (!block.current) {
        enqueueSnackbar(
          "Available Years Found For Selected Area, Beginning To Load Most Recent Imagery.",
          {
            anchorOrigin: { horizontal: "center", vertical: "bottom" },
            autoHideDuration: 4000,
          }
        );
      }

      return years[0].Years;
    } catch (err) {
      return null;
    }
  };

  // Radar map
  function radarLoop() {
    setTimeout(() => {
      //  call a 3s setTimeout when the loop is called
      radarLayers.current.map((layer) => {
        layer.setOpacity(0);
      });
      // console.log(map.getZoom())
      if (!showRadar.current) {
        radarLayers.current[index.current].setOpacity(0);
        // console.log('radarLayers', radarLayers.current)
      } else {
        radarLayers.current[index.current].setOpacity(1.0);
        // console.log('radarLayers', radarLayers.current)
      }
      // console.log(index);
      index.current++;
      // info.update(i);                  //  increment the counter
      if (index.current < 5) {
        // console.log('showRadar', showRadar.current)          //  if the counter < 10, call the loop function
        radarLoop(); //  ..  again which will trigger another
      } else {
        index.current = 0;
        // console.log('showRadar', showRadar.current)
        radarLoop();
      } //  ..  setTimeout()
    }, 2000);
    return index.current;
  }

  function loadRadar() {
    // console.log('loadRadar', showRadar.current)
    for (let hour = 0; hour <= 5; hour++) {
      // console.log("hour = " + hour);
      const time = 50 - hour * 10;
      // console.log("time = " + time);
      if (hour < 5) {
        const layer = `nexrad-n0q-900913-m${time}m`;
        radarLayers.current[hour] = L.tileLayer
          .wms("https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0q.cgi", {
            layers: layer,
            format: "image/png",
            transparent: true,
            opacity: showRadar.current ? 1.0 : 0,
            zIndex: 100,
          })
          .addTo(map);
      }
      if (hour == 5) {
        const layer = "nexrad-n0q-900913";
        radarLayers.current[10] = L.tileLayer
          .wms("https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0q.cgi", {
            layers: layer,
            format: "image/png",
            transparent: true,
            opacity: showRadar.current ? 1.0 : 0,
            zIndex: 100,
          })
          .addTo(map);
      }
    }
    radarLoop();
  }

  /**
   * Inject html into Leaflet Layer Control
   * and add event listeners / handlers for naip selection
   * returns false if error, true if succesful
   */
  function setNaipOptions() {
    // need to add html to controls manually
    const controlDiv = document.getElementsByClassName(
      "leaflet-control-layers-list"
    )[0];

    // overlay section
    const overlays = document.getElementsByClassName(
      "leaflet-control-layers-overlays"
    )[0];

    // Naip label
    const nl = document.createElement("label");
    nl.innerText = "Aerial Imagery";

    // add label to overlay section
    try {
      overlays.appendChild(nl);
    } catch (err) {
      console.log("No control found");
      return false;
    }

    // add each option

    for (let i = 0; i < naipYears.current.length; i++) {
      // naipLayers[years[i]] = L.imageOverlay(null, geoJsonBorder)
      const innerLabel = document.createElement("label");
      const innerDiv = document.createElement("div");

      const checkbox = document.createElement("input");
      checkbox.setAttribute("type", "checkbox");
      checkbox.setAttribute("class", "leaflet-control-layers-selector");
      checkbox.setAttribute("name", naipYears.current[i]);

      const innerSpan = document.createElement("span");
      innerSpan.innerText = ` ${naipYears.current[i]}`;

      checkbox.addEventListener("input", (e) => {
        handleNaipSelect(e.target);
      });

      innerDiv.appendChild(checkbox);
      innerDiv.appendChild(innerSpan);

      innerLabel.appendChild(innerDiv);

      try {
        overlays.appendChild(innerLabel);
      } catch (err) {
        console.log("Error adding to overlay section");
        return false;
      }
    }

    try {
      controlDiv.appendChild(overlays);
    } catch (err) {
      console.log("Error adding to control div section");
      return false;
    }

    return true;
  }

  function openWeatherMap() {
    // need to add html to controls manually
    const controlDiv = document.getElementsByClassName(
      "leaflet-control-layers-list"
    )[0];

    // overlay section
    const overlays = document.getElementsByClassName(
      "leaflet-control-layers-overlays"
    )[0];

    // open weather map label
    const nl = document.createElement("label");
    nl.innerText = "Open Weather Map";

    console.log("nl", nl);
    // add label to overlay section
    try {
      overlays.appendChild(nl);
    } catch (err) {
      console.log("No control found");
      return false;
    }

    // add each option
    // const options = ["temp", "clouds", "pressure", "rain", "wind"]
    // owm.current = options
    // owmOptions.current = {}
    // for(const op of owm.current){
    //   owmOptions.current[op] = {overlay : null}
    // }

    for (let i = 0; i < owm.current.length; i++) {
      // naipLayers[years[i]] = L.imageOverlay(null, geoJsonBorder)
      const innerLabel = document.createElement("label");
      const innerDiv = document.createElement("div");

      const checkbox = document.createElement("input");
      checkbox.setAttribute("type", "checkbox");
      checkbox.setAttribute("class", "leaflet-control-layers-selector");
      checkbox.setAttribute("name", owm.current[i]);

      const innerSpan = document.createElement("span");
      innerSpan.innerText = ` ${owm.current[i]}`;

      checkbox.addEventListener("input", (e) => {
        setSelectedOwm(e.target);
        handleOWMSelect(e.target);
      });

      innerDiv.appendChild(checkbox);
      innerDiv.appendChild(innerSpan);

      innerLabel.appendChild(innerDiv);

      try {
        overlays.appendChild(innerLabel);
      } catch (err) {
        console.log("Error adding to overlay section");
      }
    }

    try {
      controlDiv.appendChild(overlays);
    } catch (err) {
      console.log("Error adding to control div section");
    }
  }

  const handleOWMSelect = async (target) => {
    console.log("e.target", target);
    const layer = target.nextSibling.textContent.trim();

    if (target.checked) {
      // uncheck all other boxes
      const overlaySection = document.getElementsByClassName(
        "leaflet-control-layers-overlays"
      )[0];
      const checkboxes = overlaySection.getElementsByClassName(
        "leaflet-control-layers-selector"
      );
      for (let i = 0; i < checkboxes.length; i++) {
        if (
          checkboxes[i].name !== layer &&
          checkboxes[i].name !== "changeToggle"
        ) {
          const box = checkboxes[i];
          box.checked = false;
          if (owmOptions.current[box.name].overlay !== null) {
            try {
              map.removeLayer(owmOptions.current[box.name].overlay);
            } catch (err) {
              console.log("Unable to remove layer", err);
            }
          }
        }
      }

      // send request to get for selected year
      if (!owmOptions.current[layer].loading) {
        owmOptions.current[layer].loading = true;
        const zoom = map.getZoom();
        const bounds = map.getBounds();
        // console.log(bounds, bounds._northEast)
        const { lat } = bounds._southWest;
        const long = bounds._southWest.lng;

        let x = parseInt(Math.floor(((long + 180) / 360) * (1 << zoom)));
        let y = parseInt(
          Math.floor(
            ((1 -
              Math.log(
                Math.tan((lat * Math.PI) / 180) +
                  1 / Math.cos((lat * Math.PI) / 180)
              ) /
                Math.PI) /
              2) *
              (1 << zoom)
          )
        );

        x = Math.abs(parseInt(x));
        y = Math.abs(parseInt(y));
        // console.log(layer, zoom, x, y)
        let res;
        try {
          // console.log(Endpoints.BASEURL+Endpoints.OWM+"?layerName="+layer+"&zoom="+zoom +"&x="+x+"&y="+y)
          res = `${
            Endpoints.BASEURL + Endpoints.OWM
          }?layerName=${layer}&zoom=${zoom}&x=${x}&y=${y}`;
          // console.log(res)
          if (map.hasLayer(owmOptions.current[layer].overlay)) {
            map.removeLayer(owmOptions.current[layer].overlay);
          }
          const overlay = L.imageOverlay();
          overlay.setUrl(res);
          overlay.setBounds(bounds);
          owmOptions.current[layer].overlay = overlay;
          owmOptions.current[layer].loading = false;
        } catch (err) {
          if (map.hasLayer(owmOptions.current[layer].overlay)) {
            map.removeLayer(owmOptions.current[layer].overlay);
          }
          console.log('couldn"t get open weather map imagery');
        }
      }
      try {
        // go check that year is checked before adding to map (user may have unchecked)
        const isYearChecked = document.getElementsByName(layer.toString())[0];
        if (isYearChecked.checked) {
          owmOptions.current[layer].overlay.addTo(map);
          owmOptions.current[layer].overlay.bringToFront();
        }
      } catch (err) {
        console.log("unable to add new layer after request", err);
      }
    } else {
      // console.log('set to 18')
      // map.setMaxZoom(18)
      // if item was deslected
      if (map.hasLayer(owmOptions.current[layer].overlay)) {
        map.removeLayer(owmOptions.current[layer].overlay);
      }
    }
  };

  const handleNaipSelect = async (target) => {
    const year = target.nextSibling.textContent.trim();
    // if a selection was made:
    if (target.checked) {
      // uncheck all other boxes
      const overlaySection = document.getElementsByClassName(
        "leaflet-control-layers-overlays"
      )[0];
      const checkboxes = overlaySection.getElementsByClassName(
        "leaflet-control-layers-selector"
      );
      for (let i = 0; i < checkboxes.length; i++) {
        if (
          checkboxes[i].name !== year &&
          checkboxes[i].name !== "changeToggle"
        ) {
          const box = checkboxes[i];
          box.checked = false;
          if (naips.current[box.name].overlay !== null) {
            try {
              map.removeLayer(naips.current[box.name].overlay);
            } catch (err) {
              console.log("Unable to remove layer", err);
            }
          }
        }
      }
      if (naips.current[year].overlay === null) {
        // send request to get for selected year
        if (!naips.current[year].loading) {
          naips.current[year].loading = true;
          await requestNaipImagery(year);
          // getNaipTiles(year)
        }
        try {
          // go check that year is checked before adding to map (user may have unchecked)
          const isYearChecked = document.getElementsByName(year.toString())[0];
          if (isYearChecked.checked) {
            naips.current[year].overlay.addTo(map);
            naips.current[year].overlay.bringToFront();
          }
        } catch (err) {
          console.log("unable to add new layer after request", err);
        }
      } else {
        console.log("set to 20");
        map.setMaxZoom(20);
        // set correct overlay
        try {
          naips.current[year].overlay.addTo(map);
        } catch (err) {
          console.log("unable to add existing layer", err);
        }
      }
    } else {
      console.log("set to 18");
      map.setMaxZoom(18);
      // if item was deslected
      if (map.hasLayer(naips.current[year].overlay)) {
        map.removeLayer(naips.current[year].overlay);
      }
    }
  };

  function getGeom() {
    const bounds = map.getBounds();
    const max = bounds.getNorthEast();
    const min = bounds.getSouthWest();

    const geomParam = {
      xmin: min.lng,
      ymin: min.lat,
      xmax: max.lng,
      ymax: max.lat,
      spatialReference: { wkid: 4326 },
    };
    return geomParam;
  }

  const mobileMapControls = () => (
    <Box className={classes.mobileControls}>
      <Box display="flex" alignItems="center">
        {zoomLevel > 13 && toolMenu()}

        <Box>
          {drawing ? (
            <Button
              color="primary"
              variant="outlined"
              onClick={() => cancelClickHandler()}
            >
              <CancelIcon />
              {" Cancel"}
            </Button>
          ) : editing ? (
            <Box>Adjust field boundary</Box>
          ) : (
            fieldSelectionInstructions()
          )}
        </Box>
      </Box>

      <Box display="flex" pr={0.5}>
        <Box display="flex" alignItems="center" style={{ height: "24px" }}>
          <Switch
            color="primary"
            checked={enableClus}
            onChange={(e) => handleCluSwitch(e.target.checked, true)}
            inputProps={{ "aria-label": "boundary toggle" }}
          />
          Boundaries
        </Box>

        <Box display="flex" alignItems="center" style={{ height: "24px" }}>
          <Switch
            color="primary"
            checked={isRadar}
            onChange={() => handleRadarSwitch(!isRadar)}
            inputProps={{ "aria-label": "boundary toggle" }}
          />
          Radar
        </Box>

        <Box display="flex" alignItems="center" style={{ height: "24px" }}>
          <Switch
            color="primary"
            checked={isEsriNaip}
            onChange={() => handleEsriNaipDisplay(!isEsriNaip)}
            inputProps={{ "aria-label": "boundary toggle" }}
          />
          NAIP
        </Box>
      </Box>
    </Box>
  );

  const toolMenu = () =>
    !editing ? (
      <Box pr={1}>
        <Button
          color="primary"
          variant="outlined"
          aria-controls="tool-menu"
          aria-haspopup="true"
          onClick={handleOpenSelect}
        >
          Map Tools
        </Button>

        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleCloseSelect}
        >
          <MenuItem onClick={handleCloseSelect}>
            <Box className={classes.mobileIcon} onClick={() => addPolygon()}>
              <AddIcon />
              {" Draw"}
            </Box>
          </MenuItem>
          <MenuItem onClick={handleCloseSelect}>
            <Box className={classes.mobileIcon} onClick={() => addSquare()}>
              <AddBoxOutlinedIcon />
              {" Square"}
            </Box>
          </MenuItem>
          {((fieldData.fieldToClaim !== undefined &&
            fieldData.fieldToClaim.points.length > 0) ||
            fieldData.selectedField.id !== "") && (
            <MenuItem onClick={handleCloseSelect}>
              <Box
                className={classes.mobileIcon}
                onClick={() => editBoundary()}
              >
                <EditLocationIcon />
                {" Edit Boundary"}
              </Box>
            </MenuItem>
          )}

          {(fieldData.fields.length > 0 ||
            fieldData.selectedField.id !== "" ||
            (fieldData.fieldToClaim.feature !== null &&
              !editing &&
              !drawing)) && (
            <MenuItem onClick={handleCloseSelect}>
              <Box className={classes.mobileIcon} onClick={() => clear()}>
                <HighlightOffOutlinedIcon />
                Clear
              </Box>
            </MenuItem>
          )}
        </Menu>
      </Box>
    ) : (
      <Button
        variant="outlined"
        color="primary"
        style={{ margin: "0 5px", whiteSpace: "nowrap" }}
        onClick={() => finishEdit()}
      >
        Finish Edit
      </Button>
    );

  const handleOpenSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorEl(null);
  };

  const stateLevelInstructions =
    "Use the interactive map to select your field boundary. Begin by clicking your state or the plus icon on map to zoom in to your field boundary. You may also search for your field by address or coordinates by clicking the magnifying glass on the map.";

  const countyLevelInstuctions =
    "Use the interactive map to select your field boundary. Continue by clicking your county or the plus icon on map to zoom in to your field boundary. You may also search for your field by address or coordinates by clicking the magnifying glass on the map.";

  const continueInstructions =
    "Use the interactive map to select your field boundary. When you find your field on the map, you can select it by clicking inside its boundary. If you would like to customize your field boundary you can do so by selecting one of our draw tools. Additionally, after your field is selected you will have the option to edit the boundary.";

  const selectionInstructions =
    "When you see your field on the map, select it by clicking inside its boundary. If you would like to customize your field boundary you can do so by selecting one of our draw tools. Additionally, after your field is selected you will have the option to edit the boundary.";

  const fieldSelectionInstructions = () =>
    zoomLevel <= 6 ? (
      <span style={{ marginRight: 4 }}>
        Begin by clicking your state or zooming in to select your field boundary
        {width > 700 ? (
          <CustomToolTip title={stateLevelInstructions} placement="right-start">
            <InfoOutlinedIcon
              className={classes.infoToolTip}
              fontSize="small"
            />
          </CustomToolTip>
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
            onClick={() => setShowSelectionInstructions(stateLevelInstructions)}
          />
        )}
      </span>
    ) : zoomLevel <= 10 ? (
      <span style={{ marginRight: 4 }}>
        Continue by clicking your county or zooming in to select your field
        boundary
        {width > 700 ? (
          <CustomToolTip title={countyLevelInstuctions} placement="right-start">
            <InfoOutlinedIcon
              className={classes.infoToolTip}
              fontSize="small"
            />
          </CustomToolTip>
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
            onClick={() => setShowSelectionInstructions(countyLevelInstuctions)}
          />
        )}
      </span>
    ) : zoomLevel <= 13 ? (
      <span style={{ marginRight: 4 }}>
        Continue zooming in on the map to view and select your field boundary
        {width > 700 ? (
          <CustomToolTip title={continueInstructions} placement="right-start">
            <InfoOutlinedIcon
              className={classes.infoToolTip}
              fontSize="small"
            />
          </CustomToolTip>
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
            onClick={() => setShowSelectionInstructions(continueInstructions)}
          />
        )}
      </span>
    ) : (
      <span style={{ marginRight: 4 }}>
        Select or draw your field boundary
        {width > 700 ? (
          <CustomToolTip title={selectionInstructions} placement="right-start">
            <InfoOutlinedIcon
              className={classes.infoToolTip}
              fontSize="small"
            />
          </CustomToolTip>
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
            onClick={() => setShowSelectionInstructions(selectionInstructions)}
          />
        )}
      </span>
    );

  const mapControls = () => (
    <Box
      className={width > 920 ? classes.mapControls : classes.mobileControls}
      style={width > 920 ? {} : { padding: "4px 16px" }}
    >
      {/*
        We dont need to switch to mobile menu at <920px, but it will now be under
        field selection now, so give top border and change background color
      */}

      {/* Left justified */}

      <Box display="flex" alignItems="center">
        {fieldSelectionInstructions()}

        {zoomLevel > 13 && !editing && !drawing ? (
          <>
            <AgToolTip
              title="Draw polygons by clicking to place vertices. Click the first vertex to complete the polygon"
              placement="top-start"
            >
              <Box className={classes.icon} onClick={() => addPolygon()}>
                <AddIcon /> Draw
              </Box>
            </AgToolTip>

            <AgToolTip
              title="Draw square polygon. After completion you can edit the boundary"
              placement="top-start"
            >
              <Box className={classes.icon} onClick={() => addSquare()}>
                <AddBoxOutlinedIcon /> Square
              </Box>
            </AgToolTip>

            {((fieldData.fieldToClaim !== undefined &&
              fieldData.fieldToClaim.points.length > 0) ||
              fieldData.selectedField.id !== "") && (
              <AgToolTip
                title="Edit boundary of selected field by dragging polygon points"
                placement="top-start"
              >
                <Box className={classes.icon} onClick={() => editBoundary()}>
                  <EditLocationIcon />
                  {width < 1103 ? "Edit" : "Edit Boundary"}
                </Box>
              </AgToolTip>
            )}
          </>
        ) : editing ? (
          <Box>
            <Button
              variant="outlined"
              color="primary"
              style={{
                height: "24px",
                margin: "0 5px",
                backgroundColor: "#ffffff",
              }}
              onClick={() => finishEdit()}
            >
              Finish Edit
            </Button>
          </Box>
        ) : (
          <Box />
        )}

        {drawing && (
          <AgToolTip title="Cancel current polygon draw" placement="top-start">
            <Box className={classes.icon} onClick={() => cancelClickHandler()}>
              <CancelIcon />
              {" Cancel"}
            </Box>
          </AgToolTip>
        )}

        {(fieldData.fields.length > 0 ||
          fieldData.selectedField.id !== "" ||
          (fieldData.fieldToClaim.feature !== null &&
            !editing &&
            !drawing)) && (
          <AgToolTip
            title="Clear field boundaries from map"
            placement="top-start"
          >
            <Box className={classes.icon} onClick={() => clear()}>
              <HighlightOffOutlinedIcon />
              Clear
            </Box>
          </AgToolTip>
        )}
      </Box>

      {/* Right justified */}
      <Box display="flex" alignItems="center">
        <Box
          display="flex"
          alignItems="center"
          style={{ width: "200px", height: "24px" }}
        >
          <Switch
            color="primary"
            checked={enableClus}
            disabled={editing}
            onChange={(e) => handleCluSwitch(e.target.checked, true)}
            inputProps={{ "aria-label": "boundary toggle" }}
          />
          {width > 660 ? "Show Boundary Layer" : "Boundaries"}
        </Box>

        <Box
          display="flex"
          alignItems="center"
          style={{ width: "100px", height: "24px" }}
        >
          <Switch
            color="primary"
            checked={isRadar}
            onChange={() => handleRadarSwitch(!isRadar)}
            inputProps={{ "aria-label": "boundary toggle" }}
          />
          Radar
        </Box>

        <Box
          display="flex"
          alignItems="center"
          style={{ width: "100px", height: "24px" }}
        >
          <Switch
            color="primary"
            checked={isEsriNaip}
            onChange={() => handleEsriNaipDisplay(!isEsriNaip)}
            inputProps={{ "aria-label": "boundary toggle" }}
          />
          NAIP
        </Box>
      </Box>
    </Box>
  );

  const resetMapSearchValues = () => {
    try {
      if (selectedSearchMethod.value === "latlng") {
        document.getElementById("latSearch").value = "";
        document.getElementById("lngSearch").value = "";
      } else if (selectedSearchMethod.value === "county") {
        document.getElementById("stateSearch").value = "";
        document.getElementById("countySearch").value = "";
      } else {
        document.getElementById("stateSearch").value = "";
        document.getElementById("countySearch").value = "";
        document.getElementById("twsSearch").value = "";
        document.getElementById("rangeSearch").value = "";
      }
    } catch (err) {
      console.log("Couldn't reset input value");
    }
  };

  const changeSearchMethod = (target) => {
    //resetMapSearchValues()
    let val = target.value
    let selected = underwritingSearchMethods.filter(x => x.value === val)[0]
    setSelectedSearchMethod(selected)
  }

  const zoomToCoords = () => {
    if(map !== null && searchLatLng !== null){
      //parse latlng input
      let trimmed = searchLatLng.trim()
      let latlng = []
      if(trimmed.includes(",")){
        latlng = trimmed.split(",").map(x => x.trim())
      }
      else if(trimmed.includes(" ")){
        latlng = trimmed.split(" ").map(x => x.trim())
      }
      else{
        return
      }

      //remove special charecters (if pasted from google or somewhere else with the degree symbol)
      latlng = latlng.map(x => x.replace(/[`~!@#$%^&*()_|+=?;:°'"<>\{\}\[\]\\\/]/gi, ''))

      var latLng = L.latLng(latlng[0], latlng[1])
      map.flyTo(latLng, 16)
    }
    
  }
  

  const getBoundary = async (level) => {
    let response = await searchBoundaries(searchState.STUSPS, searchCounty.NAME, searchTownship?.TOWNSHIP, searchRange?.NAME, level)
    console.log(response)
    if(response.status !== "Success"){
      enqueueSnackbar("No results found")
      return
    }
    let polygon = wkt.parse(response.results)
    let polyLayer = L.geoJSON(polygon)
    let bbox = polyLayer.getBounds()
    if(level === 'township'){
      map.fitBounds(bbox, {duration: 0.5})
    }
    else{
      map.fitBounds(bbox, {duration: 0.5, maxZoom:10})
    }
  };

  const searchInputChange = (type, event, value, reason) =>{
    console.log(type, event, value, reason)
  }

  const searchValueChange = (type, event, value, reason) => {
    // console.log(event, value, reason)
    
    if(type === "state"){
      if(reason === 'clear'){
        setSearchState(null)
        setStateInput(null)

        setCountyOptions([])
        setSearchCounty(null)
        console.log("countySearch", document.getElementById("countySearch"))
        try{document.getElementById('countySearch').value=''}catch{console.log("couldnt reset county")}

        setTownshipOptions([])
        setSearchTownship(null)
        try{document.getElementById('twsSearch').value=''}catch{}

        setRangeOptions([])
        setSearchRange(null)
        try{document.getElementById('rangeSearch').value=''}catch{}
      }
      else{
        let selected = stateOptions.filter(x => x.NAME === value)
        if(selected.length > 0){
          setSearchState(selected[0])
        }
        
      }
    }
    else if(type === "county"){
      if(reason === 'clear'){
        setSearchCounty(null)

        setTownshipOptions([])
        setSearchTownship(null)
        try{document.getElementById('twsSearch').value=''}catch{}

        setRangeOptions([])
        setSearchRange(null)
        try{document.getElementById('rangeSearch').value=''}catch{}
      }
      else{
        let selected = countyOptions.filter(x => x.NAME === value)
        if(selected.length > 0){
          setSearchCounty(selected[0])
        }
        
      }
    }
    else if(type === "township"){
      if(reason === 'clear'){
        setSearchTownship(null)

        setRangeOptions([])
        setSearchRange(null)
        try{document.getElementById('rangeSearch').value=''}catch{}
      }
      else{
        let selected = townshipOptions.filter(x => x.TOWNSHIP === value)
        if(selected.length > 0){
          setSearchTownship(selected[0])
          setRangeOptions(selected[0].RANGES)
        }
      }
    }
    else{
      if(reason === 'clear'){
        setSearchRange(null)
      }
      else{
        let selected = rangeOptions.filter(x => x.NAME === value)
        if(selected.length > 0){
          setSearchRange(selected[0])
        }
      }
    }
  }

  const latLngSearch = () => (
    <Box className={classes.latlngSearch}>
      <Box className={classes.itemContainer}>
        {/** Latitude input */}
        <TextField
          className={classes.latLngItem}
          id="latLngSearch"
          variant="filled"
          value={searchLatLng}
          label="Lat, Lng"
          onChange={(e) => setSearchLatLng(e.target.value)}
        />
      </Box>
      <Box className={classes.itemContainer}>
        {/** submit box */}
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => zoomToCoords()}
        >
          Search
        </Button>
      </Box>
    </Box>
  );

  const stateCountySearch = () => (
    <Box className={classes.latlngSearch}>
      <Box className={classes.itemContainer}>
        <Autocomplete
          id="stateSearch"
          options={stateOptions.map((option) => option.NAME)}
          onInputChange={(event, value, reason) => searchValueChange("state", event, value, reason)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.stateSearch}
              variant="filled"
              label="State"
            />
          )}
        />
      </Box>
      <Box className={classes.itemContainer}>
        <Autocomplete
          id="countySearch" 
          options={countyOptions.map((option) => option.NAME)}
          onInputChange={(event, value, reason) => searchValueChange("county", event, value, reason)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.countySearch}
              variant="filled"
              label="County"
            />
          )}
        />
      </Box>
      <Box className={classes.itemContainer}>
        {/** submit box */}
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => getBoundary("county")}
        >
          Search
        </Button>
      </Box>
    </Box>
  );

  const townshipRangeSearch = () => (
    <Box className={classes.latlngSearch}>
      <Box className={classes.itemContainer}>
        <Autocomplete
          id="stateSearch"
          options={stateOptions.map((option) => option.NAME)}
          onInputChange={(event, value, reason) => searchValueChange("state", event, value, reason)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.stateSearch}
              variant="filled"
              label="State"
            />
          )}
        />
      </Box>
      <Box className={classes.itemContainer}>
        <Autocomplete
          id="countySearch" 
          options={countyOptions.map((option) => option.NAME)}
          onInputChange={(event, value, reason) => searchValueChange("county", event, value, reason)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.countySearch}
              variant="filled"
              label="County"
            />
          )}
        />
      </Box>
      <Box className={classes.itemContainer}>
        <Autocomplete
          id="twsSearch"
          options={townshipOptions.map((option) => option.TOWNSHIP)}
          onInputChange={(event, value, reason) => searchValueChange("township", event, value, reason)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.townshipSearch}
              variant="filled"
              label="Township"
            />
          )}
        />
      </Box>
      <Box className={classes.itemContainer}>
        <Autocomplete
          id="rangeSearch"
          options={rangeOptions.map((option) => option.NAME)}
          onInputChange={(event, value, reason) => searchValueChange("range", event, value, reason)}
          renderInput={(params) => (
            <TextField
              {...params}
              className={classes.rangeSearch}
              variant="filled"
              label="Range"
            />  
          )}
        />
      </Box>
      <Box className={classes.itemContainer}>
        {/** submit box */}
        <Button
          variant="contained"
          className={classes.button}
          onClick={() => getBoundary("township")}
        >
          Search
        </Button>
      </Box>
    </Box>
  );

  const searchMethod = () => (
    <Box className={classes.searchMethod}>
      <Box>
        <Select
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedSearchMethod.value}
          style={{ backgroundColor: "white" }}
          onChange={(e) => changeSearchMethod(e.target)}
        >
          {underwritingSearchMethods.map((x, i) => (
            <MenuItem key={i} value={x.value} name={x.name}>
              {x.name}
            </MenuItem>
          ))}
        </Select>
      </Box>
      {selectedSearchMethod.value === "latlng"
        ? latLngSearch()
        : selectedSearchMethod.value === "county"
        ? stateCountySearch()
        : townshipRangeSearch()}
    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      style={{
        height: containerHeight,
        width: "auto",
      }}
    >
      {width > 700 ? mapControls() : mobileMapControls()}

      {underwriting && searchMethod()}

      <SelectionInstructions
        setOpen={setShowSelectionInstructions}
        text={showSelectionInstructions}
      />

      <Box
        id={mapId}
        height="100%"
        width="auto"
        style={{ marginRight: width <= 768 ? 10 : 0 }}
      />
    </Box>
  );
}

import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import {
  COLOR_RANGE,
  COLORS,
  DOLLAR_FORMAT,
  NEGATIVE_COLOR_RANGE, 
  POSITIVE_COLOR_RANGE
} from "../../../../../../constants";
import { scaleQuantize } from "d3-scale";

const useStyles = makeStyles(() => ({
  row: {
    border: "none",
    "& :first-child": {
      paddingLeft: 0,
    },
  },
  cell: {
    fontSize: 14,
    color: COLORS.text,
    padding: 7,
    "&.active": {
      borderLeft: `3px solid ${COLORS.green}`,
    },
  },
}));

/**
 * Renders the row
 * @param {object} row object with row data
 * @param {array} headerCells  array of objects with functions/information
 * needed to access data in row object
 * @param {function} profitScale function to assign color according to scale
 */

const Row = ({ row, headerCells, showHeatmap }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      {headerCells.map((headerCell, i) => {
       // console.log("headerCell", headerCell)
        let matchingValue = headerCell.accessor(row);
        let extentValue = headerCell.extentValue
        let finalScale = headerCell.scale;
          
          if(extentValue != null && Math.min(...extentValue) < 0 && Math.max(...extentValue) ==0){
            finalScale = scaleQuantize()
            .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(extentValue);
          }
          else if(extentValue != null && Math.max(...extentValue) >=0 && Math.min(...extentValue) == 0){
            finalScale = scaleQuantize()
            .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(extentValue);
          }
          else if(extentValue !== null && Math.min(...extentValue) < 0 && Math.max(...extentValue) >=0){
            //console.log("matchingValue", matchingValue)
            if(matchingValue < 0){
              finalScale = scaleQuantize()
              .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
              .domain([extentValue[0], 0]);
            }
            else if(matchingValue >= 0){
              finalScale = scaleQuantize()
              .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
              .domain([0, extentValue[0]]);
            }      
          }
          //console.log("headerCell.id", headerCell.id)
          if(headerCell.id == "average-profit"){
            matchingValue = DOLLAR_FORMAT(matchingValue) + "/Acre"
          }
          else if(headerCell.id == "variety-acreage"){
            matchingValue = Number(matchingValue)
            matchingValue = Math.round(matchingValue*100)/100
            //console.log("matchingValue", matchingValue)
          }  

          const backgroundColor = showHeatmap
            ? finalScale
              ? finalScale(matchingValue)
              : row.active
              ? "unset"
              : COLORS.white
            : row.active
            ? "unset"
            : COLORS.white;
          const color = showHeatmap
            ? finalScale
              ? COLOR_RANGE.find((d) => d.backgroundColor === backgroundColor)
              : { color: "inherit" }
            : { color: "inherit" };
        
        return (
          <TableCell
            key={`table-cell-for-${headerCell.id}`}
            className={classes.cell}
            align={"left"}
            style={{
              backgroundColor: matchingValue
                ? i
                  ? backgroundColor
                  : row.active
                  ? COLORS.offWhite
                  : COLORS.white
                : row.active
                ? COLORS.offWhite
                : COLORS.white,
              color: finalScale != "$0.00/Acre" ? color.color : COLORS.text,
              border: `4px solid ${COLORS.white}`,
            }}
          >
            {matchingValue}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
export default Row;

Row.propTypes = {
  row: PropTypes.object.isRequired,
  headerCells: PropTypes.array.isRequired
};

import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { OrderSummary } from './OrderSummary'
import { ProfitLayersLink } from '../../../Shared/ServiceLinks'


const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    color: theme.palette.text.primary,
  },
  reportCard: {
    margin: 4,
    border: `2px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    paddingBottom: 4,
  },
  summaryTitle: {
    marginRight: 4,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  summaryDescription: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  }
}));

export const Summary = ({report, selectedSubOrder}) => {
  const classes = useStyles();

  function displaySubReport(subReport) {
    /* Display details of selected sub order for report. Sub Orders will contain
    the actual info for orders, including results of individual reports ordered,
    images, pdfs and potentially more. */

    return (
      <Box
        mx={1}
        mb={1}
        display="flex"
        flexWrap="wrap"
      >
        {subReport?.summary?.length > 0 && subReport.summary.map(
          (reportSummary, i) => createReportCards(reportSummary),
        )}
      </Box>
    );
  }

  // create cards for report summaries
  const createReportCards = (report) => (
    <Box key={report.reportName} className={classes.reportCard}>
      <Box bgcolor='#f2f2f2' p={1}>
        <Typography align="center" style={{ fontWeight: 500, fontSize: 20 }}>
          {report.reportName}
        </Typography>
      </Box>

      <Divider />

      { report?.reportSummary?.length > 0 && report.reportSummary.map(summary =>(

        <Box key={summary.title} my={0.5} px={1.5}>
          <Box py={1} display='flex'>
            <Typography className={classes.summaryTitle}>{summary.title}:</Typography>
            <Typography className={classes.summaryDescription}>{summary.description}</Typography>
          </Box>
          <Divider />
        </Box>
      ))}

    </Box>
  );

  return (
    <Box className={classes.root}>
      <Box> 
        <Typography variant='h6'>
          View your soil analysis results in one convenient table
        </Typography>
        <Box pl={1}>
          <Typography>
          More even more details, view complete reports in the Detailed Reports tab
        </Typography>
        </Box>
      </Box>

      <Box p={1} display='flex' flexWrap='wrap'>
        { displaySubReport(selectedSubOrder) }

        <OrderSummary 
          report={report}
          selectedSubOrder={selectedSubOrder}
        />

        <Box p={1}>
          <ProfitLayersLink />
        </Box>
      </Box>
    </Box>
  )
}

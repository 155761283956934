// React
import React, {
  useState,
  useContext,
  useEffect,
  useRef,
} from 'react';
import PropTypes from 'prop-types';

// Context
import { UserContext } from '../../Context/UserContext';

// Popups
import { MailChimpForm } from '../MailChimp/MailChimpForm';
import { AcreageReportingPopUp } from './AcreageReporting';
import { DroneAnalysisPopUp } from './DroneAnalysis';
import { SoilTestingPopUp } from './SoilTesting';
import { WaterManagementPopUp } from './WaterManagement';

// Helpers
import { sleep, getRandomInt } from '../../../utils/helpers';

/**
 * Manages which, if any, popup to display
 * USED IN: DataLayers/DataLayers.js, ProfitLayers/ProfitLayer.js, /DroneAnalysisDroneAnalysis.js,
 * SoilTesting/SoilTesting.js, Digs/Digs.js
 * @param {Bool} mailingList Determines if MailChimp signup should be shown
 * @param {Bool} acreageReporting Determines if AcreageReportingPopUp should be an available option
 * @param {Bool} droneAnalysis Determines if DroneAnalysisPopup should be an available option
 * @param {Bool} soilTesting Determines if SoilTestingPopup should be an available option
 * @param {Bool} waterManagement Determines if WaterManagementPopup should be an available option
 * @param {Number} delay Time to delay before attempting to show/reshow service popup
 * @returns {JSX} Popup
 */
export const PopupManager = ({
  mailingList = true,
  acreageReporting = false,
  droneAnalysis = false,
  soilTesting = false,
  waterManagement = false,
  delay = 3000,
}) => {
  const user = useContext(UserContext)[0];
  const [popup, setPopup] = useState('');

  // Our async recursive function is unable to rely on state for this check
  const subscribeOpen = useRef(false);

  useEffect(() => {
    // Get options parent component wants to have potentially shown
    const popupsToChoseFrom = availableOptions();

    // This will leave a popup length / popup length + 1 chance of seeing a popup
    const indexToDisplay = getRandomInt(popupsToChoseFrom.length + 1);
    if (indexToDisplay < popupsToChoseFrom.length) {
      // Start display count for randomly chosen popup
      startCount(popupsToChoseFrom[indexToDisplay]);
    }
  }, []);

  // useEffect(() => {
  //   // Check isAuthenticated actually equals false, rather than undefined (not yet loaded)
  //   if (user?.isAuthenticated === false && mailingList) {
  //     setPopup('mailchimp');
  //     subscribeOpen.current = true;
  //   }
  // }, [user]);

  const startCount = async (popupToDisplay) => {
    // Note: the service components have their own built in delays in addition to time we set here
    // wait time in miliseconds
    await sleep(delay);
    if (!subscribeOpen.current) {
      setPopup(popupToDisplay);
    } else {
      // Restart count
      startCount(popupToDisplay);
    }
  };

  const availableOptions = () => {
    const options = [];
    if (droneAnalysis) {
      options.push('uav');
    }
    if (soilTesting) {
      options.push('soil');
    }
    if (waterManagement) {
      options.push('water');
    }
    if (acreageReporting){
      options.push('acreageReporting')
    }
    return options;
  };

  const handleSubscribeOpenStatus = () => {
    setPopup('');
    subscribeOpen.current = false;
  };

  return (
    <>
      {/* { popup === 'mailchimp' && <MailChimpForm alertClose={handleSubscribeOpenStatus} />} */}
      { popup === 'uav' && <DroneAnalysisPopUp />}
      { popup === 'soil' && <SoilTestingPopUp />}
      { popup === 'water' && <WaterManagementPopUp />}
      { popup === 'acreageReporting' && <AcreageReportingPopUp />}
    </>
  );
};

PopupManager.propTypes = {
  mailingList: PropTypes.bool,
  droneAnalysis: PropTypes.bool,
  soilTesting: PropTypes.bool,
  waterManagement: PropTypes.bool,
  delay: PropTypes.number,
};

PopupManager.defaultProps = {
  mailingList: true,
  droneAnalysis: false,
  soilTesting: false,
  waterManagement: false,
  delay: 30000,
};

// -------------------- IMPORTS --------------------
// React
import React from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
	Box,
	Button,
	Divider,
	Modal,
	Tooltip,
	Typography
} from '@material-ui/core';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { darkText } from '../../../styles/colors';

// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
	body: {
		height: 'auto',
		width: '100%',
		padding: '0 20px',
		fontSize: 16,
		fontWeight: 500,
		color: theme.palette.text.black,
	},
	buttonBox: {
		...theme.buttonBox,
		alignItems: 'center',
		height: '60px',
		marginTop: '24px',
	},
	description: {
		fontSize: 14,
		color: theme.palette.text.grey,
	},
	head: {
		height: '50px',
		padding: '20px',
		alignItems: 'center',
		width: '100%',
		color: darkText,
		fontSize: 20,
		fontWeight: 500,
		display: 'flex',
	},
	paper: {
		...theme.modalPaper,
		position: 'absolute',
		height: 'auto',
		width: '50%',
	},
}));


// -------------------- MAIN FUNCTION --------------------

/**
 * Rendered in AcreageReporting/AcreageReporting.js
 * Modal for confirming reset of data from settings
 * @param {Bool} warningOpen Modal opened or closed
 * @param {Function} setWarningOpen Sets modal opened or closed
 * @param {Function} resetData Sends request to backend to delete the currently selected entries from the Zones table
 * @returns {JSX} Modal
 */
export function ResetWarning({
	warningOpen,
	setWarningOpen,
	resetData,
}) {
	// -------------------- VARIABLES --------------------
	const classes = useStyles();


	const warningModal = () => (
		<Modal
			open={warningOpen}
			onClose={() => setWarningOpen(false)}
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<Box
				className={classes.paper}
				borderRadius="borderRadius"
			>
				{/* Settings Title */}
				<Box className={classes.head}>
					Data Reset Warning
        </Box>

				<Box className={classes.body}>
					<Divider />
					<Box
						mt={3}
						display="flex"
						justifyContent="space-between"
						flexWrap="wrap"
					>
						<Typography variant="h5">
							Warning! Resetting your data can result in the loss of any plantings created in the Acreage Reporting interface.
						</Typography>
						<Typography variant="subtitle1">
							To continue and remove all saved data please press the 'I Wish To Remove All Data' button, to preserve created plantings press the 'Avoid Removing Created Plantings' button.
						</Typography>
					</Box>
					<Box
						mt={3}
						display="flex"
						justifyContent="space-between"
						flexWrap="wrap"
					>
						<Box>
							<Tooltip title="Remove All Saved Data">
								<Button
									color="primary"
									variant="contained"
									onClick={() => { setWarningOpen(false); resetData(true) }}
								>
									I Wish To Remove All Data
								</Button>
							</Tooltip>
						</Box>
						<Box>

							<Tooltip title="Keep Created Plantings">
								<Button
									color="secondary"
									variant="contained"
									onClick={() => { setWarningOpen(false); resetData() }}
								>
									Avoid Removing Created Plantings
								</Button>
							</Tooltip>
						</Box>
					</Box>

				</Box>

				{/* Bottom footer */}
				<Box className={classes.buttonBox}>
					<Box m={1}>
						<Button
							variant="outlined"
							color="primary"
							onClick={() => setWarningOpen(false)}
							style={{ backgroundColor: '#ffffff' }}
						>
							Close
						</Button>
					</Box>
				</Box>
			</Box>
		</Modal>
	)


	// -------------------- RETURN --------------------
	return (warningModal());
}

ResetWarning.propTypes = {
  warningOpen: PropTypes.bool.isRequired,
  setWarningOpen: PropTypes.func.isRequired,
  resetData: PropTypes.func.isRequired,
};

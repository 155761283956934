export const formatDate = (date) => {
  /* format date as expected for profit map */
  let dash = date.includes("-")
  const d = dash ? date.split("T")[0].split("-") : date.split("T")[0].split("/");
  return `${d[1]}/${d[2]}/${d[0]}`;
};

/**
* First time a user loads fields we will bring in operations for years
* before and after selected year. These will be set to hidden initially
 * @param  {Object} op Operation data
 * @param  {Number} year Year selected
 * @return {Boolean} To hide or not to hide
 */
export const determineInitialHide = (op, year, years) => {

  // add selected year to array to account for user default year selected
  let yearsToInclude = [...years, year]
  for(let i=0; i<yearsToInclude.length; i++){
    yearsToInclude[i] = +yearsToInclude[i]
  }
  // If created from template we don't do these checks (why?)
  if (op.source !== "TEMPLATE") {
    if (
      op.isDeleted === false &&
      (yearsToInclude.includes(op.year) || yearsToInclude.includes(new Date(op.endDate).getFullYear()))) {
      return false
    } else {
      return true
    }
  }
};

/**
 * Combine operations with matching IDs.
 * @param  {Array} ops List of operation objects
 * @return {Array} List of combined operations
 */
export const combineOps = (ops) => {
  const operations = [];

  for (const op of ops) {
    const match = operations.filter((x) => x.operationID === op.operationID);

    if (match.length > 0) {
      const index = operations.indexOf(match[0]);
      operations[index].varieties.push(op);

      operations[index].hidden = checkHidden(operations[index].varieties);
    } else {
      const operation = {
        varieties: [op],
        date: op.date,
        hidden: op.isDeleted,
        name: op.productName,
        operationID: op.operationID,
        source: op.source,
      };

      operations.push(operation);
    }
  }

  return operations;
};

const checkHidden = (varieties) => {
  // if any varieties are deleted (hidden), hide operation
  const hidden = varieties.filter((x) => x.isDeleted);
  return hidden.length > 0;
};

import React from "react";
import PropTypes from "prop-types";
import { Button, makeStyles } from "@material-ui/core";
import { produce } from "immer";
import { COLORS } from "../../../../../constants";
import AdvancedControlsIcon from "../../../../../images/advanced-controls.svg";
import AdvancedControlsActiveIcon from "../../../../../images/advanced-controls-active.svg";
import ToggleHeatmapIcon from "../../../../../images/toggle-heatmap.svg";
import ToggleHeatmapActiveIcon from "../../../../../images/toggle-heatmap-active.svg";

const useStyles = makeStyles(() => ({
  header: ({ mobileView }) => ({
    marginTop: 60,
    marginBottom: 30,
    display: "flex",
    flexDirection: mobileView ? "column" : "row",
    justifyContent: "space-between",
    alignItems: mobileView ? "flex-start" : "center",
  }),
  buttonGroup: ({ mobileView }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiButton-root": {
      padding: 6,
    },
    width: 330,
    marginTop: mobileView ? "1rem" : "0 .5rem",
  }),
  button: ({ mobileView }) => ({
    color: COLORS.green,
    backgroundColor: COLORS.white,
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: "bold",
    border: `1px solid ${COLORS.green}`,
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "none",
    minWidth: 158,
    margin: mobileView ? 0 : "0 .5rem",
    "&.active": {
      backgroundColor: COLORS.green,
      color: COLORS.white,
    },
  }),
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: COLORS.darkGray,
  },
}));

/**
 * Renders the header row
 * @param {object} tableControls handles controls (buttons) in header for displaying table
 * @param {function} setTableControls  set state function
 * @param {object} activeFarm object with farm data
 * @param {boolean} mobileView for mobile views
 */

const Header = ({
  tableControls,
  setTableControls,
  activeFarm,
  mobileView,
}) => {
  const classes = useStyles({ mobileView });

  return (
    <div className={classes.header}>
      <div className={classes.title}>
        Overview Table for <span>{` ${activeFarm.name}`}</span>
      </div>
      <div className={classes.buttonGroup}>
        <Button
          className={`${classes.button} ${
            tableControls.showHeatmap ? "active" : ""
          }`}
          onClick={() =>
            setTableControls(
              produce((draft) => {
                draft.showHeatmap = !tableControls.showHeatmap;
              })
            )
          }
          variant="contained"
          startIcon={
            <img
              alt="toggle between table and the heat map"
              src={
                tableControls.showHeatmap
                  ? ToggleHeatmapActiveIcon
                  : ToggleHeatmapIcon
              }
            />
          }
        >
          Toggle Heatmap
        </Button>
        <Button
          className={`${classes.button} ${
            tableControls.showAdvancedControls ? "active" : ""
          }`}
          onClick={() =>
            setTableControls(
              produce((draft) => {
                draft.showAdvancedControls = !tableControls.showAdvancedControls;
              })
            )
          }
          variant="contained"
          startIcon={
            <img
              alt="open the advanced controls modal"
              src={
                tableControls.showAdvancedControls
                  ? AdvancedControlsActiveIcon
                  : AdvancedControlsIcon
              }
            />
          }
        >
          Advanced Controls
        </Button>
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  activeFarm: PropTypes.object.isRequired,
  mobileView: PropTypes.bool.isRequired,
  tableControls: PropTypes.object.isRequired,
  setTableControls: PropTypes.func.isRequired,
};

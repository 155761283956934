import React from "react";
import ReactGA from 'react-ga'
import { makeStyles } from "@material-ui/core/styles";
import { Container, Box, Button, Typography } from "@material-ui/core";
import { Integrate } from '../Partners/Integrate'
import { Endpoints } from '../../constants/Endpoints';
import { blackText, lightGrey, grey } from '../../styles/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
    backgroundColor: lightGrey,
    color: blackText,
    fontWeight: 500,
  }
}));

/**
 * Show sign in/up and integration page when user is not authenticated and
 * tries to view layers they cannot access or claim a field.
 * 
 * Rendered in:
 * components\DataLayers\DataLayers.js
 * 
 * @param {Boolean} fromFooter whether this component was generated from click to 'Claim Field' in footer 
 * @returns {JSX} sign in/up & integration page
 */
export function ConnectAndIntegrate({fromFooter, shouldForceLogIn}) {
  const classes = useStyles();

  const handleLogin = () => {
    ReactGA.event({
      category: "User",
      action: "Sign In"
    })
    window.location.assign(Endpoints.BASEURL + Endpoints.SIGNIN)
  }

  const handleSignUp = () => {
    ReactGA.event({
      category: "User",
      action: "Sign Up"
    })
    window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUP)
  }

  return (
      <Container maxWidth='md' style={{height: '100%', backgroundColor: lightGrey}}>
        <Box className={classes.root}>
          <Box py={2} px={1}>
            <Typography variant='h5' align='center' style={{color: blackText}}>
              To {fromFooter ? 'claim this field' : 'use this tool'}, please sign in or create an account
            </Typography>
          </Box>
          <Box
            display='flex'
            justifyContent='center'
            flexGrow={1}
            style={{width: '100%'}}
            flexWrap='wrap'
          >
            <Button
              color='primary'
              variant='outlined'
              onClick={() => handleLogin()}
              style={{margin: '8px 16px', fontSize: 20, width: '220px', backgroundColor: '#ffffff'}}
            >
              SIGN IN
            </Button>

            <Button
              color='primary'
              variant='outlined'
              onClick={() => handleSignUp()}
              style={{margin: '8px 16px', fontSize: 20, backgroundColor: '#ffffff'}}
            >
              CREATE AN ACCOUNT
            </Button>
          </Box>

          <Integrate />
        </Box>
      </Container>
  )
}

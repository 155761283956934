/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Divider, Container,
} from '@material-ui/core/';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import StopIcon from '@material-ui/icons/Stop';
import digslogo from './images/digs-logo.png';
import aglogo from '../../images/AgAnalytics.png';
import { useWindowDimensions } from '../../utils/dimensions';
import { blackText, darkGrey } from '../../styles/colors';
import infographic from '../../images/digs_infographic.png';

const lightBlue = '#007cba';
const greyBlue = '#006ba1';
const blue = '#2f5597';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 'auto',
    margin: '0 18px',
  },
  aglogo: {
    width: 'auto',
    margin: '0 18px',
  },
  dot: {
    fontSize: '12px',
    margin: '3px 5px 0 0',
    color: greyBlue,
  },
  square: {
    fontSize: '12px',
    margin: '3px 3px 0 0',
    color: lightBlue,
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  product: {
    width: '400px',
    padding: '8px',
    margin: '16px',
    fontWeight: 500,
    borderColor: darkGrey,
    '&:hover': {
      cursor: 'pointer',
      borderColor: greyBlue,

    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 18,
  },
  link: {
    color: lightBlue,
    '&:hover': {
      cursor: 'pointer',
      color: greyBlue,
    },
  },
  mobileHeader: {
    paddingTop: 8,
    paddingBottom: 4,
    borderBottom: `1px solid ${theme.palette.greys.dark}`,
  },
  pageHeader: {
    paddingTop: 8,
    paddingBottom: 4,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: `1px solid ${darkGrey}`,
  },
}));

/**
 * DIGS overview
 * @param {Function} setSection Move to field selection
 * @returns {JSX} About DIGS
 */
export function About({ setSection }) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const mobileHeader = () => (
    <Box className={classes.mobileHeader}>
      <Box display="flex" justifyContent="space-around">
        <img
          className={classes.aglogo}
          src={aglogo}
          alt="ag analytics"
          style={{ height: '52px' }}
        />
        <img
          className={classes.logo}
          src={digslogo}
          alt="digs"
          style={{ height: '50px' }}
        />
      </Box>
      <Typography align="center" style={{ fontWeight: 500, fontSize: '2.2rem' }}>
        Drainage and Water Management Solutions
      </Typography>
    </Box>
  );

  const header = () => (
    <Box className={classes.pageHeader}>
      <img
        className={classes.aglogo}
        src={aglogo}
        alt="ag analytics"
        style={{ height: '64px' }}
      />
      <Box pr="20px">
        <Typography align="center" style={{ fontWeight: 500, fontSize: '2.2rem' }}>
          Drainage and Water Management Solutions
        </Typography>
      </Box>

      <img
        className={classes.logo}
        src={digslogo}
        alt="digs"
        style={{ height: '78px' }}
      />
    </Box>
  );

  return (
    <Box
      color={blackText}
      style={{ backgroundColor: '#f3f3f3', height: '100%', width: '100%' }}
    >
      <Container maxWidth="md" style={{ backgroundColor: '#ffffff' }}>
        { width > 952 ? header() : mobileHeader()}

        <Box my={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
          <Box border={2} borderColor={blue} width="90%">
            <img src={infographic} alt="infographic" height="auto" width="100%" />
          </Box>
        </Box>

        <Box mt={2}>
          <Typography align="center" variant="h5">
            How To Order
          </Typography>
          <Divider style={{ marginBottom: 4 }} />
          <Typography>
            Order high-quality water management and engineering consultations and services. Begin by
            {' '}
            <span className={classes.link} onClick={() => setSection(1)}>selecting your field boundary on the mapping tool</span>
            . Next, add services or service bundles to your selected fields.
            Finally, complete and place your
            {' '}
            <span style={{ fontWeight: 500 }}>no-obligation order</span>
            {' '}
            order by filling in your contact information.
            No payment due at time of order, pricing is estimate only.
            An engineer will typically contact you within 1-2 days to confirm your order and plan a process of action.
            After completion of the service, all results, maps, and reports will be distributed to the customer through the Ag-Analytics site.
            For any other questions, please
            {' '}
            <a className={classes.link} target="_blank" rel="noreferrer" href="https://www.analytics.ag/contact">contact us</a>
            {' '}
            at support@analytics.ag.
          </Typography>

        </Box>

        <Box mt={2}>
          <Typography align="center" variant="h5">
            Management and Engineering Products
          </Typography>
          <Divider style={{ marginBottom: 4 }} />
          <Typography>
            DIGS offers a comprehensive suite of professional water management and engineering services to meet your needs.
          </Typography>
        </Box>

        <Box
          mt={1}
          display="flex"
          flexWrap="wrap"
        >
          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Drainage Design and Cost Estimates
              </Box>
              <Box>
                $5/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Sub-Surface Design and Cost Estimates: A farm-specific full-scale sub-surface tile drainage design and industry standard estimate.
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Surface Design and Cost Estimates: A farm-specific full-scale surface tile drainage design and industry standard estimate.
              </Box>
            </Box>
          </Box>

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Topography
              </Box>
              <Box>
                $5/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Topographic Map: Elevations and contours determined using DIGS topography.
              </Box>
            </Box>
          </Box>

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Watersheds
              </Box>
              <Box>
                $5/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Watershed Assessment: The identification of outlets and neighboring watershed stakeholders. DIGS topography is leveraged to determine cost-share opportunities and holistic analysis.
              </Box>
            </Box>
          </Box>

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Analysis
              </Box>
              <Box>
                $10/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Remediation Response Analysis (RRA): Field-specific return on investment realized through proprietary analytical approaches correlating DIGS topography, soil science, hydrological analysis, flow accumulation, spatial yield data, and historical/predictive weather modeling.
              </Box>
            </Box>
          </Box>

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Facilitation
              </Box>
              <Box>
                $15/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                DIGS Facilitation: Professional design and project implementation by convening neighboring stakeholders for a precise engineered solution, cost-share analysis, and bid solicitation for a one-time project.
              </Box>
            </Box>
          </Box>

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Comprehensive
              </Box>
              <Box>
                $25/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Irrigation Services: A farm-specific full-scale irrigation design and industry standard estimate.
              </Box>
            </Box>
          </Box>

        </Box>

        <Box mt={2}>
          <Typography align="center" variant="h5">
            Bundles
          </Typography>
          <Divider style={{ marginBottom: 4 }} />

          <Typography>
            DIGS also offers conveniently-packaged Service Bundles that include many of the individual water management services at a discounted rate.
          </Typography>
        </Box>

        <Box
          mt={1}
          display="flex"
          flexWrap="wrap"
        >

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Engineering Due-Diligence
              </Box>
              <Box>
                $8/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Tile Drainage Design and Cost Estimates: Sub-Surface OR Surface
              </Box>
            </Box>
            <Box ml={2} display="flex">
              <StopIcon className={classes.square} />
              <Box>
                Sub-Surface Design and Cost Estimate
              </Box>
            </Box>
            <Box ml={2} display="flex">
              <StopIcon className={classes.square} />
              <Box>
                Surface Design and Cost Estimate
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Watershed Assessment
              </Box>
            </Box>
          </Box>

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Watershed Facilitation
              </Box>
              <Box>
                $20/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Tile Drainage Design and Cost Estimates: Sub-Surface OR Surface
              </Box>
            </Box>
            <Box ml={2} display="flex">
              <StopIcon className={classes.square} />
              <Box>
                Sub-Surface Design and Cost Estimate
              </Box>
            </Box>
            <Box ml={2} display="flex">
              <StopIcon className={classes.square} />
              <Box>
                Surface Design and Cost Estimate
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Watershed Assessment
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                DIGS Facilitation
              </Box>
            </Box>
          </Box>

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Holistic Drainage Services
              </Box>
              <Box>
                $25/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Tile Drainage Design and Cost Estimates: Sub-Surface AND Surface
              </Box>
            </Box>
            <Box ml={2} display="flex">
              <StopIcon className={classes.square} />
              <Box>
                Sub-Surface Design and Cost Estimate
              </Box>
            </Box>
            <Box ml={2} display="flex">
              <StopIcon className={classes.square} />
              <Box>
                Surface Design and Cost Estimate
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Watershed Assessment
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Topographic Map
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                DIGS Facilitation
              </Box>
            </Box>
          </Box>

          <Box
            className={classes.product}
            borderRadius="borderRadius"
            border={2}
            onClick={() => setSection(1)}
          >
            <Box className={classes.header}>
              <Box>
                Comprehensive Water Management
              </Box>
              <Box>
                $50/Acre
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Tile Drainage Design and Cost Estimates: Sub-Surface AND Surface
              </Box>
            </Box>
            <Box ml={2} display="flex">
              <StopIcon className={classes.square} />
              <Box>
                Sub-Surface Design and Cost Estimate
              </Box>
            </Box>
            <Box ml={2} display="flex">
              <StopIcon className={classes.square} />
              <Box>
                Surface Design and Cost Estimate
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Watershed Assessment
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Topographic Map
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                DIGS Facilitation
              </Box>
            </Box>
            <Box m={1} display="flex">
              <FiberManualRecordIcon className={classes.dot} />
              <Box>
                Irrigation Services
              </Box>
            </Box>
          </Box>

        </Box>

      </Container>
    </Box>
  );
}

import React, { useState, useEffect, useContext } from "react";
import * as df from "../../utils/dataFetchers";
import * as fr from "../../utils/farmReportDataFetchers";

import { FieldContext } from "../Context/FieldContext";

import {
  Box,
  Select,
  MenuItem,
  Typography,
  Input,
  Checkbox,
  ListItemText,
  Chip,
  Button,
  LinearProgress,
  FormControl,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { formatForProfitMap } from "../ProfitLayers/utils/conversions";
import * as calc from "../ProfitLayers/utils/calculations";
import {
  formatForProfitMapGen,
  convertPlantings,
} from "../Helpers/FarmOperation/helper";
import { WarningMessage } from "./WarningMessageModal";
import { COLORS } from "../../constants"

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    "& .MuiTextField-root": {
      margin: theme.spacing(1),
    },
  },
  chips: theme.chips,
  chip: theme.chip,
  infoToolTip: theme.infoToolTip,
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      //width: 250,
    },
  },
};

function LinearProgressWithLabel(props) {
  return (
    <Box display="flex" alignItems="center">
      <Box width="100%" mr={1}>
        <LinearProgress variant="determinate" {...props} />
      </Box>
      <Box minWidth={35}>
        <Typography variant="body2" color="textSecondary">{`${Math.round(
          props.value
        )}%`}</Typography>
      </Box>
    </Box>
  );
}

export function FarmReport({
  dieselPrices,
  year,
  field,
  highRes,
  netSeededAcres,
  seedMap,
  harMap,
  appMap,
  fpMap,
  otherCostMap,
  otherRevMap,
  orgId,
  farmId,
  fields,
  setReload,
  access,
  setAccess,
  processing,
  setProcessing,
  checkAccess,
  initialLoad,
  setInitialLoad,
  fieldIds,
  setFieldsIds
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [fieldData, setFieldData] = useContext(FieldContext);

  const [fieldInfo, setFieldInfo] = useState([{ fieldId: "", fieldName: "" }]);

  const [selectedFields, setSelectedFields] = useState([]);

  const [tillage, setTillage] = useState(new Map());

  const [message, setMessage] = useState("");
  const [progress, setProgress] = useState(0);

  const [open, setOpen] = useState(false);

  const [selectedFieldsWithSummary, setSelectedFieldsWithSummary] = useState([
    {
      fieldId: "",
      fieldName: "",
    },
  ]);

  //const [fieldIds, setFieldsIds] = useState([])

  useEffect(() => {
    (async() => {
      if(initialLoad){
        let res = await fr.getFieldWithProfitSummary(orgId, farmId, year);
        res = JSON.parse(res);

        setFieldsIds(res)
        setInitialLoad(false)
      }  
    })()
    //getFarmData()
  }, [initialLoad]); 

  useEffect(() => {
    getFieldWithNoSummary();
  }, [fieldIds])

  useEffect(() => {
    let fieldsToWarn = [];
    if (fieldInfo.length > 0 && selectedFields.length > 0) {
      for (let id of selectedFields) {
        let data = fieldInfo.filter((x) => x.fieldId === id);

        if (data.length > 0 && data[0].profitMapGenerated) {
          fieldsToWarn.push({
            fieldId: id,
            fieldName: data[0].fieldName,
          });
        }
      }
    }
    //console.log("fieldsToWarn", fieldsToWarn);
    setSelectedFieldsWithSummary(fieldsToWarn);
  }, [selectedFields]);

  const getFieldWithNoSummary = async () => {
    //console.log("fields", fields)
    let allFieldIds = fields.map((value) => value.id);
    let fieldsWithSummary = fieldIds.map((value) => value.field_id);

    let fieldsWithNoSummary = allFieldIds.filter(
      (o) => fieldsWithSummary.indexOf(o) === -1
    );
    let fieldWithName = [];
    let defualtFields = [];

    for (let f of fields) {
      let data = fieldsWithSummary.filter((x) => x === f.id);
      //console.log("data", data);

      if (data.length == 0) {
        defualtFields.push(f.id);
      }

      fieldWithName.push({
        fieldId: f.id,
        fieldName: f.name,
        profitMapGenerated: data.length > 0,
        backgroundColor: data.length > 0 ? "maroon" : "orange",
        text:
          data.length > 0
            ? "Profit Layer Already Generated"
            : "Profit Layer Not Generated",
      });
    }
    //console.log("fieldWithName", fieldWithName)
    setFieldInfo(fieldWithName);
    setSelectedFields(defualtFields);
  };

  const handleFieldSelect = (event) => {
    setSelectedFields(event.target.value);
  };

  const checkFields = () => {
    if (selectedFieldsWithSummary.length > 0) {
      setOpen(true);
    } else {
      generateMap();
    }
  };

  const generateMap = async () => {
    setOpen(false);
    setProgress(1);
    for (let i = 0; i < selectedFields.length; i++) {
      let fieldID = selectedFields[i];
      let fieldName = fieldInfo.filter((f) => f.fieldId === fieldID)[0];
      setMessage("Generating ProfitLayers for field: " + fieldName.fieldName);

      let seed = seedMap.get(fieldID) === undefined ? [] : seedMap.get(fieldID);
      let har = harMap.get(fieldID) === undefined ? [] : harMap.get(fieldID);
      let app = appMap.get(fieldID) === undefined ? [] : appMap.get(fieldID);
      let fp = fpMap.get(fieldID) === undefined ? [] : fpMap.get(fieldID);
      let othCost =
        otherCostMap.get(fieldID) === undefined
          ? []
          : otherCostMap.get(fieldID);
      let othRev =
        otherRevMap.get(fieldID) === undefined ? [] : otherRevMap.get(fieldID);
      let till = tillage.get(fieldID) === undefined ? [] : tillage.get(fieldID);

      const harvests = convertPlantings(har).map((x) => formatForProfitMap(x));
      const seedings = convertPlantings(seed).map((x) => formatForProfitMap(x));
      const applications = app.map((x) => formatForProfitMap(x));

      let fieldOps = [
        ...seedings,
        ...harvests,
        ...applications,
        ...othCost,
        ...othRev,
        ...till,
        ...fp
      ];

      if (fieldOps.length > 0) {
        //console.log("fieldOps", fieldOps)

        const operationsCopy = formatForProfitMapGen(fieldOps);

        //console.log("operationsCopy", operationsCopy)

        let orgId = fieldOps[0].organizationID;

        let request = {
          FieldOperationList: operationsCopy,
          Constant: 0,
          Year: year,
          IsResizeMapRequest: false,
          ProfitMapLegendRange: null,
          LegendColors: null,
          ProfitByZones: false,
          Zones: null,
          boundarygeojson: null,
          IsHighResolutionMapRequest: false,
          MultiYears: [year],
        };

        let res = await df.getProfitMap(orgId, fieldID, request);
        setProgress(((i + 1) * 100) / selectedFields.length);

        if(res.profitMap !== undefined){
          if (res.profitMap.code === 500) {
            // enqueueSnackbar(
            //   "Failed to generate ProfitLayers for field: " + fieldName.fieldName
            // );
            setMessage(
              "Failed to generate ProfitLayers for field: " + fieldName.fieldName
            );
            continue;
          } else {
            //Log profit map obj
            // enqueueSnackbar(
            //   "Generated ProfitLayer for field: " + fieldName.fieldName
            // );
            setMessage("Generated ProfitLayer for field: " + fieldName.fieldName);
  
            res.profitMap[0].legend = calc.formatProfitLegend(
              res.profitMap[0].legend,
              request
            );
            res.profitMap = calc.updateProfitMapValues(
              fields.filter((f) => f.id === fieldID)[0].profit,
              res.profitMap,
              true,
              fields.filter((f) => f.id === fieldID)[0].acres
            );
  
            let profitLog = {
              ProfitMap_Response: null, //json string of response from get profitmap
              isLegendSaved: null, //bool -> false if range # changed, otherwise true
              zoneBoundary: null, // boundary of zone if map was for zone
              zoneCost: null, // cost if map was for zone
            };
            profitLog.ProfitMap_Response = JSON.stringify(res.profitMap);
            profitLog.isLegendSaved = 0;
  
            const logged = await df.logProfitMapresponse(
              orgId,
              fieldID,
              year,
              profitLog
            );
  
            let summaryObj = {
              NetSeededAcre: fields.filter((f) => f.id === fieldID)[0].acres,
              Profit: res.profitMap[0].mean,
            };
            const summary = await df.logProfitMapSummary(
              orgId,
              fieldID,
              year,
              summaryObj
            );
  
            //let profitAccess = await checkAccess(orgId)
          }
        }
        else if(res.message.toUpperCase().includes("TRIAL LIMIT HAS BEEN REACHED")){
          setAccess(false);
          break;
        }        
      } else {
        setProgress(((i + 1) * 100) / selectedFields.length);
        setMessage("No data found for field: " + fieldName.fieldName);
      }
    }
    setInitialLoad(true);
    setReload(true);
    setSelectedFields([]);
    setMessage("");
    setProgress(0);
    /* CALL PROFITMAPSUMMARY API IN FARM REPORT TO UPDATE SUMMARY TABLE */
  };

  return (
    <Box width="100%">
      <Box ml={2}>
        <Typography style={{fontWeight: "bold", fontSize: 20, lineHeight: 1.3, color: COLORS.darkGray}}>Generate ProfitLayers</Typography>
      </Box>
      <Box m={2}>
        <Typography>
          Generate ProfitLayers for fields that don't have profit map generated,
          by selecting field names from the dropdown.
        </Typography>
        <Typography>
          Note: This might take sometime to generate the maps, do not refresh
          the page.
        </Typography>
      </Box>

      <Box m={2}>
        <FormControl className={classes.formControl}>
          <Box>Select Fields</Box>
          <Select
            id="field-mutiple"
            multiple
            value={selectedFields}
            onChange={handleFieldSelect}
            input={<Input id="select-multiple-fields" />}
            renderValue={(selected) => (
              <div className={classes.chips}>
                {selected.map((value, i) => (
                  <Chip
                    key={i}
                    label={
                      fieldInfo.filter((f) => f.fieldId === value)[0].fieldName
                    }
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
            MenuProps={MenuProps}
            style={{ minWidth: 250 }}
          >
            {fieldInfo.map((x, i) => (
              <MenuItem key={i} value={x.fieldId} style={{ width: "500px" }}>
                <Checkbox
                  color="primary"
                  checked={
                    selectedFields !== undefined &&
                    selectedFields.includes(x.fieldId)
                  }
                />
                {x.fieldName} &nbsp;{" "}
                <span
                  style={{
                    backgroundColor: x.backgroundColor,
                    color: "white",
                    padding: 3,
                    position: "absolute",
                    right: 10,
                  }}
                >
                  {x.text}
                </span>
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Box>

      <Box m={2}>
        <Button
          color="primary"
          variant="contained"
          onClick={(e) => /*generateMap()*/ checkFields()}
          style={{ margin: "10px", float: "right" }}
        >
          Generate Profit Layers For Selected Fields
        </Button>
      </Box>

      {progress > 0 && (
        <Box m={2}>
          <LinearProgressWithLabel value={progress} />
          <Typography>{message}</Typography>
        </Box>
      )}

      <Box>
        {open && (
          <WarningMessage
            open={open}
            setOpen={setOpen}
            generateMap={generateMap}
            selectedFieldsWithSummary={selectedFieldsWithSummary}
          />
        )}
      </Box>
    </Box>
  );
}

/* eslint-disable camelcase */
/* eslint-disable react/jsx-props-no-spreading */
// -------------------- IMPORTS --------------------
// React
import React, { useEffect, useState } from 'react';

// Material-ui
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  IconButton,
  Modal,
  Switch,
  TextField,
  Typography,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '../../../styles/colors';

// Custom components, and helpful packages
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  ToolTip,
} from '../../../utils/customComponents';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { useSnackbar } from 'notistack';

// Functionality
import { parseAndUpload } from '../Functionality/uploadClus';
import { checkIsMobile } from '../../../utils/helpers';


// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  button: {
    textTransform: 'none',
    fontSize: '1.2rem',
    fontFamily: 'OpenSans',
    color: 'white',
    height: 40,
    width: 220,
    marginTop: 16,
  },
  close: {
    position: 'absolute',
    top: -6,
    right: 0,
    color: grey,
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 4,
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  infoIcon: {
    ...theme.infoToolTip,
    marginBottom: '10px',
  },
  label: {
    color: theme.palette.greys.dark,
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  modalDescription: {
    maxWidth: 576,
    marginLeft: 8,
  },
  modalInfo: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
    maxWidth: 855,
    boxShadow: '16px',
    maxHeight: '90vh',
  },
  select: {
    width: 200,
    margin: '0px 16px',
    backgroundColor: '#ffffff',
  },
  settingAction: {
    display: 'flex',
    justifyContent: 'center',
    padding: '8px',
  },
  shapeFileContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    fontSize: 18,
  }
}));

// -------------------- MAIN FUNCTION 1 --------------------
/**
 * Rendered in AcreageReporting/AcreageReporting.js
 * Modal for displaying info for uploading CLU shape files or GeoJSON
 * @param {String} impersonating User whose data Agent is using
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Array} organizations User organizations
 * @param {Object} selectedCLUOrg Org to associate uploaded CLU data with ({id: -1, name: ''})
 * @param {Function} setAgentPortalOpen Sets Agent Portal Modal open or close
 * @param {Function} setOpen Sets if modal is open
 * @param {Function} setSelectedCLUOrg Changes state of selectedCLUOrg
 * @param {Function} setUseOrgCLUData Expects true or false
 * @param {Boolean} useOrgCLUData CLU data to use. Either true or false
 * @returns {JSX} CLU Shapefile/GeoJSON upload modal
 */
export const CLUUploadModal = ({
  impersonating,
  open,
  organizations,
  selectedCLUOrg,
  setAgentPortalOpen,
  setOpen,
  setSelectedCLUOrg,
  setUseOrgCLUData,
  useOrgCLUData,
}) => {
  // -------------------- VARIABLES --------------------
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  // -------------------- FUNCTIONALITY --------------------
  // Main display for information related to uploading CLU files
  const cluUpload = () => (
    <Box
      className={classes.paper}
    >
      <IconButton className={classes.close} onClick={() => { setOpen(false); }}>
        <HighlightOffIcon />
      </IconButton>

      {/** CLU file upload title and body */}
      <Box className={classes.shapeFileContainer}>
        <Box p={1} borderBottom={1} width="100%">
          <Typography align="center" style={{ fontWeight: 500, fontSize: 24 }}>
            CLU Upload
          </Typography>
        </Box>

        <CLUUploadOptions
          impersonating={impersonating}
          loading={loading}
          organizations={organizations}
          primaryColor={''}
          selectedCLUOrg={selectedCLUOrg}
          setAgentPortalOpen={setAgentPortalOpen}
          setClusUploaded={() => {}}
          setLoading={setLoading}
          setOpen={setOpen}
          setSelectedCLUOrg={setSelectedCLUOrg}
          setUseOrgCLUData={setUseOrgCLUData}
          useOrgCLUData={useOrgCLUData}
        />

        {loading && <SpinningLoader size="sm" />}
      </Box>
    </Box>
  );

  // -------------------- RETURN --------------------
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="shape-file-upload"
      aria-describedby="upload-zipped-shapefiles-or-json"
    >
      {cluUpload()}
    </Modal>
  );
};

CLUUploadModal.propTypes = {
  impersonating: PropTypes.string.isRequired,
  open: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setAgentPortalOpen: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  setUseOrgCLUData: PropTypes.func.isRequired,
  useOrgCLUData: PropTypes.bool.isRequired,
};


// -------------------- MAIN FUNCTION 2 --------------------
/**
 * Rendered above and
 * Rendered in AcreageReporting/Onboarding/UploadClus.js
 * Displays a way for user to pick shared_id and OrganizationID info to associate with uploaded CLU 
 * and button for uploading CLU shape files or GeoJSON
 * @param {String} impersonating User whose data Agent is using
 * @param {Boolean} loading Whether data is being processed/uploaded to show loading spinner
 * @param {Array} organizations User organizations
 * @param {Object} selectedCLUOrg Org to associate uploaded CLU data with ({id: -1, name: ''})
 * @param {Function} setAgentPortalOpen Sets Agent Portal Modal open or close
 * @param {Function} setClusUploaded Sets whether data was successfully uploaded (for Onboarding to proceed)
 * @param {Function} setLoading Sets whether data is being processed/uploaded to show loading spinner
 * @param {Function} setOpen Sets if modal is open
 * @param {Function} setSelectedCLUOrg Changes state of selectedCLUOrg
 * @param {Function} setUseOrgCLUData Expects true or false
 * @param {Boolean} useOrgCLUData CLU data to use. Either true or false
 * @returns {JSX} CLU Shapefile/GeoJSON upload modal info
 */
export const CLUUploadOptions = ({
  impersonating,
  loading,
  organizations,
  primaryColor,
  selectedCLUOrg,
  setAgentPortalOpen,
  setClusUploaded,
  setLoading,
  setOpen,
  setSelectedCLUOrg,
  setUseOrgCLUData,
  useOrgCLUData,
}) => {
  // -------------------- VARIABLES --------------------
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [mobileDisplay, setMobileDisplay] = useState(false);

  const [errorMessage, setErrorMessage] = useState(null);
  
  // -------------------- USE EFFECTS --------------------
  useEffect(() => {
    // Used for tooltips, could use for more
    setMobileDisplay(checkIsMobile());
  }, []);

  // Display any error messages
  useEffect(() => {
    if (errorMessage) {
      enqueueSnackbar(errorMessage);
      setErrorMessage('');
    }
  }, [errorMessage])

  // -------------------- FUNCTIONALITY --------------------
  // Changes the chosen organization appropriately
  const handleOrgChange = (value) => {
    let finalValue = value || {id: -1, name: ''};
    setSelectedCLUOrg(finalValue);

    // Change the state of this variable based on the user's selection
    if (value) {
      setUseOrgCLUData(true);
    }
    else {
      setUseOrgCLUData(false);
    }
  }

  // Handles calling the function that will process and upload the CLU to the DB
  const uploadFile = async (e) => {
    try {
      setLoading(true);
      const uploadSuccess = await parseAndUpload(e, setErrorMessage, impersonating, selectedCLUOrg);
      setClusUploaded(uploadSuccess);
    } catch (err) {
      console.error(err);
      setErrorMessage('We encountered a problem uploading your CLU boundaries. Please try again or contact us for assistance');
    } finally {
      setLoading(false);
    }
  };

  // Helpful description for user - DO NOT INDENT
  // Can also mention here that CLU data associated with the overall account will be available to all organizations but that might be confusing with the CLU Data setting
  const instructions = () => (
    <>
      <b>For Growers:</b>
{`
  You can just click "Upload File" to directly upload your CLU files and associate them with your account.

`}
      <b>For Agents:</b>
{`
  - Did you invite your grower to integrate their precision data?
    Then select which grower you want to associate this CLU data with from the agent portal by selecting a grower to complete a report for. First, your grower must have accepted to share their precision data with you.
  - Alternatively, if you integrated or uploaded their data to your account, then you can just select which organization you want to associate this CLU data with.`}
    </>
  );

  const uploadOptions = () => (
    <>
      {/** Helpful expandable description of options for user */}
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="cluUpload-content"
          id="cluUpload-header"
          style={{ background: 'rgba(0, 0, 0, .05)' }}
        >
          <Box className={classes.header}>
            Instructions
          </Box>
        </AccordionSummary>

        <AccordionDetails
          style={{ borderBottom: "1px solid rgba(0, 0, 0, .125)" }}
        >
          <Typography style={{ width: "100%", padding: "16px", whiteSpace: 'pre-wrap' }}>
            {instructions()}
          </Typography>
        </AccordionDetails>
      </Accordion>

      {/** CLU user association */}
      <Box className={classes.modalInfo} p={2}>
        <Box className={classes.modalDescription}>
          <Typography>
            Currently, any uploaded CLU boundaries will be associated with
            {impersonating === '' ?
              <> <b>your</b> account.</>
            :
              <> the following account: <b>{impersonating}</b>.</>
            }
          </Typography>
          <Typography component="div">
            To change this, please open the agent portal and select a grower to complete a report for.
            <ToolTip
              text="You can only choose to complete a report for a grower that you have invited and has accepted your invitation."
              mobileDisplay={mobileDisplay}
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </ToolTip>
          </Typography>
        </Box>

        <Button
          variant="contained"
          color="primary"
          size="large"
          style={{
            width: 200,
            height: 36.5,
            margin: "0px 16px",
            background: primaryColor,
            color: (primaryColor === '') ? "" : "#fff",
          }}
          onClick={() => {
            // setOpen(false);
            setAgentPortalOpen('uploadCLU');
          }}
          disableElevation
        >
          Agent Portal
        </Button>
      </Box>

      {/** CLU org association */}
      <Box width="100%" p={2}>
        <Box className={classes.modalInfo} pb={selectedCLUOrg.id !== -1 ? 2 : 0}>
          <Box className={classes.modalDescription}>
            <Typography>
              You can also choose which specific organization you want any uploaded CLU boundaries to be associated with by selecting from the dropdown.
            </Typography>
            <Typography>
              Currently, any imported CLU boundaries will be associated with

              {/* selectedCLUOrg.id === -1 means that user has not picked an org */}
              {selectedCLUOrg.id === -1 ? (
                // impersonating === '' means that user is not impersonating a user
                impersonating === '' ?
                  <> <b>your</b> overall account.</>
                :
                  <> your <b>grower's</b> overall account.</>
              )
              : impersonating === '' ?
                <> your organization: <b>{selectedCLUOrg.name}</b>.</>
              :
                // To emphasize "grower's" more, could also make it bold
                <> your grower's organization: <b>{selectedCLUOrg.name}</b>.</>
              }
            </Typography>
          </Box>

          <Autocomplete
            className={classes.select}
            filterSelectedOptions
            options={organizations}
            value={selectedCLUOrg}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Select Organization"
              />
            )}
            onChange={(event, value) => { handleOrgChange(value); }}
            disabled={loading}
          />
        </Box>

        {/* Only show this when an organization has been selected by the user */}
        {selectedCLUOrg.id !== -1 && (
          <Box className={classes.modalInfo} pb={1}>
            <Box className={classes.modalDescription}>
              <Typography>
                <b>CLU Data</b>: Account vs. Organization
              </Typography>
              <Typography component="div">
                This setting being checked will let you use CLU Data associated with your selected organization for your report.
                <ToolTip
                  text="You can also find this option in the Settings in the top right."
                  mobileDisplay={mobileDisplay}
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </ToolTip>
              </Typography>
            </Box>

            <Box className={classes.settingAction} alignItems="center">
              <Typography>
              Account
              </Typography>

              <Switch
                color="primary"
                checked={useOrgCLUData}
                onChange={() => setUseOrgCLUData(!useOrgCLUData) }
                name="display-format"
                inputProps={{ 'aria-label': 'display-format' }}
                disabled={loading}
              />

              <Typography>
                Organization
              </Typography>
            </Box>
          </Box>
        )}
      </Box>

      {/** CLU file upload text and button */}
      <Box className={classes.modalInfo} p={2} pb={3}>
        <Box className={classes.modalDescription}>
          <Typography>
            Import your CLU boundaries by uploading a zipped shapefile or a GeoJSON file.
          </Typography>
        </Box>

        <Button
          // className={classes.button} // Can maybe replace style to match Onboarding style?.. And similar for text
          variant="contained"
          color="primary"
          size="large"
          component="label" // Not having this stops the event propagation to the input element...
          style={{ 
            width: 200,
            height: 36.5,
            margin: "0px 16px",
            background: primaryColor,
            color: (primaryColor === '') ? "" : "#fff",
          }}
          disableElevation
          disabled={loading}
        >
          Upload File
          <input
            type="file"
            style={{ display: 'none' }}
            onChange={(e) => uploadFile(e)}
          />
        </Button>
      </Box>
    </>
  );

  // -------------------- RETURN --------------------
  return (uploadOptions())
};
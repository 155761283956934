import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  Button,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import PublishIcon from '@material-ui/icons/Publish';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import ArrowBackRoundedIcon from '@material-ui/icons/ArrowBackRounded';
import LinkIcon from '@material-ui/icons/Link';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  CustomToolTip,
} from '../../../utils/customComponents';

import { Endpoints } from '../../../constants/Endpoints';

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    backgroundColor: theme.palette.greys.light,
    width: 360,
    border: `2px solid ${theme.palette.primary.main}`,
    maxHeight: window.innerHeight - 40,
    overflowY: 'auto',
  },
  close: theme.close,
  column: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    color: theme.palette.primary.main,
  },
  upload: {
    margin: '0 8px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoToolTip: theme.infoToolTip,
}));

/**
 * Provides user options for starting zone creation
 * @param {Bool} open Modal is open
 * @param {Function} setOpen Sets modal is open or closed
 * @param {Function} createGrids Starts grid creation
 * @param {Function} createZones Starts zone creation
 * @param {Function} goToFieldSelection Goes back to field selection screen
 * @param {Function} createFromShapeFile Create zones from shape file
 * @param {Object} toolTip Title and text for tooltip
 * @param {String} fieldSource To check if precision ag field
 * @returns {JSX} Modal with options
 */
export function ZoneCreation({
  open,
  setOpen,
  createGrids,
  createZones,
  goToFieldSelection,
  createFromShapeFile,
  toolTip,
  fieldSource,
}) {
  const classes = useStyles();
  const [mobileView, setMobileView] = useState(false);

  const precisionSources = ['deere', 'climate', 'cnh'];

  useEffect(() => {
    if (window.matchMedia('only screen and (max-width: 760px)').matches) {
      setMobileView(true);
    }
  }, []);

  const selectCreateGrids = () => {
    setOpen(false);
    createGrids();
  };

  const selectCreateZones = () => {
    setOpen(false);
    createZones();
  };

  const handleShapeField = (e) => {
    setOpen(false);
    createFromShapeFile(e);
  };

  const information = () => (
    <Box p={2}>
      {toolTip.title}
      <CustomToolTip
        title={toolTip.text}
        placement="top"
      >
        <InfoOutlinedIcon
          className={classes.infoToolTip}
          fontSize="small"
        />
      </CustomToolTip>
    </Box>
  );

  const mobileInformation = () => (
    <Accordion>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Box>
          {toolTip.title}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <Box>
          {toolTip.text}
        </Box>
      </AccordionDetails>
    </Accordion>
  );

  return (
    <Modal
      open={open}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="field-selection-instructions"
      aria-describedby="instructions-to-select-field-boundary-on-map"
    >
      <Box className={classes.paper}>
        <Box p={2} bgcolor="#ffffff">
          Select your soil sampling area by using one of the following:
        </Box>

        <Divider />

        <Box p={1}>
          <Box className={classes.column}>
            <Box mb={1}>
              <Box>
                Declare your soil sample locations by grid.
              </Box>
              <Box>
                Easily grid your field with 2.5 or 5 acre tiles.
              </Box>
            </Box>

            <Button
              color="primary"
              variant="outlined"
              style={{ width: 230, backgroundColor: '#ffffff' }}
              onClick={() => selectCreateGrids()}
              disableElevation
            >
              <GridOnOutlinedIcon style={{ marginRight: 8 }} />
              {' Create Grids'}
            </Button>
          </Box>
        </Box>

        <Divider />

        <Box p={1}>
          <Box className={classes.column}>
            <Box mb={1}>
              <Box>
                Create custom zones across your field.
              </Box>
              <Box>
                Use our drawing tools or SSURGO for zones.
              </Box>

            </Box>

            <Button
              color="primary"
              variant="outlined"
              style={{ width: 230, backgroundColor: '#ffffff' }}
              onClick={() => selectCreateZones()}
              disableElevation
            >
              <TimelineIcon style={{ marginRight: 8 }} />
              Create Zones
            </Button>
          </Box>
        </Box>

        <Divider />

        <Box p={1}>
          <Box className={classes.column}>
            <Box mb={1}>
              <Box>
                Upload Shapefile containing your zones.
              </Box>
              <Box>
                Note this will replace current field boundary.
              </Box>
            </Box>

            <Button
              color="primary"
              variant="outlined"
              style={{ width: 230, backgroundColor: '#ffffff' }}
              disableElevation
            >
              <Box
                className={classes.upload}
                component="label"
              >
                <PublishIcon />
                Upload Shape File
                <input
                  type="file"
                  style={{ display: 'none' }}
                  onChange={(e) => handleShapeField(e)}
                  multiple
                />
              </Box>
            </Button>

          </Box>
        </Box>

        { fieldSource === undefined || !precisionSources.includes(fieldSource.toLowerCase()) && (
          <>
            <Divider />

            <Box p={1}>
              <Box className={classes.column}>
                <Box mb={1}>
                  Create from Precision Yield Data
                </Box>

                <Button
                  style={{ width: 230, backgroundColor: '#ffffff' }}
                  color="primary"
                  variant="outlined"
                  target="_blank"
                  href={`${Endpoints.HOME}/app/Integrate`}
                >
                  <LinkIcon className={classes.link} />
                  <Box className={classes.link} ml={0.5}>
                    Integrate Precision
                  </Box>
                </Button>
              </Box>
            </Box>
          </>
        )}

        <Divider />

        <Box p={1}>
          <Box className={classes.column}>
            <Box mb={1}>
              Edit existing or select a new field
            </Box>

            <Button
              style={{ width: 230, backgroundColor: '#ffffff' }}
              color="primary"
              variant="outlined"
              onClick={() => goToFieldSelection()}
            >
              <ArrowBackRoundedIcon style={{ marginRight: 8 }} />
              {' Field selection'}
            </Button>
          </Box>
        </Box>

        { toolTip !== undefined && (
          <>
            <Divider />
            { mobileView ? mobileInformation() : information() }
          </>
        )}

      </Box>
    </Modal>
  );
}

ZoneCreation.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  createGrids: PropTypes.func.isRequired,
  createZones: PropTypes.func.isRequired,
  goToFieldSelection: PropTypes.func.isRequired,
  createFromShapeFile: PropTypes.func.isRequired,
  toolTip: PropTypes.object,
  fieldSource: PropTypes.string,
};

ZoneCreation.defaultProps = {
  toolTip: undefined,
  fieldSource: undefined,
};

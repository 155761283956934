import React from 'react';
import { AppBar, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { grey, green, blackText } from '../../../styles/colors';
import { useWindowDimensions } from '../../../utils/dimensions';

const useStyles = makeStyles((theme) => ({
  appBarSelections: theme.appBarSelections,
  appBarSelectionsMobile: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    color: green,
    fontSize: '22px',
    fontWeight: 700,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: theme.navigationArrows,
}));

const roundIcon = {
  width: '25px',
  height: '25px',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 2,
  borderRadius: '50%',
  marginRight: '10px',
};

export const Steps = ({
 section, changeSection, goToProfitLayers, field 
}) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const stepsBreakPoint = 960;
  const mobileBreakPoint = 860;

  return (
    width > mobileBreakPoint ? (
      <Box display="flex">
        <Box
          className={classes.appBarSelections}
          color={green}
          onClick={() => changeSection(0)}
          style={{ width: '180px', margin: width > stepsBreakPoint ? '0 50px' : '0 16px' }}
        >
          {section > 0 ? (
            <CheckBoxRoundedIcon
              style={{ marginRight: '10px', fontSize: 27 }}
            />
          ) : (
            <Box {...roundIcon}>1</Box>
          )}
          Select Field
        </Box>

        <Box
          className={classes.appBarSelections}
          color={section > 0 ? green : grey}
          onClick={() => changeSection(1)}
          style={{ width: '180px', margin: width > stepsBreakPoint ? '0 50px' : '0px 16px 0px 8px' }}
        >
          {section > 1 ? (
            <CheckBoxRoundedIcon
              style={{ marginRight: '10px', fontSize: 27 }}
            />
          ) : (
            <Box {...roundIcon}>2</Box>
          )}
          {field.id !== '' && field.name !== '' ? 'Manage Field' : 'Manage Farm'}
        </Box>

        <Box
          className={classes.appBarSelections}
          color={section > 1 ? green : grey}
          onClick={() => goToProfitLayers()}
          style={{ width: '230px', margin: width > stepsBreakPoint ? '0 50px' : '0 16px' }}
        >
          {section > 2 ? (
            <CheckBoxRoundedIcon
              style={{ marginRight: '10px', fontSize: 27 }}
            />
          ) : (
            <Box {...roundIcon}>3</Box>
          )}
          Setup ProfitLayers
        </Box>
      </Box>
    ) : (
      // {/* Mobile */}
      <Box
        display="flex"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: '100%' }}
      >
        {
          section === 0 ? (
            <>
              <Box className={classes.icon} />
              <Box className={classes.appBarSelectionsMobile}>
                <Box {...roundIcon}>1</Box>
                Select Field
              </Box>
              <ArrowForwardIosIcon
                className={classes.icon}
                onClick={() => changeSection(1)}
              />
            </>
          ) : (
            <>
              <ArrowBackIosIcon
                className={classes.icon}
                onClick={() => changeSection(0)}
              />
              <Box className={classes.appBarSelectionsMobile}>
                <Box {...roundIcon}>2</Box>
                {field.id !== '' && field.name !== '' ? 'Manage Field' : 'Manage Farm'}
              </Box>
              <Box className={classes.icon} />
            </>
          )
        }
      </Box>
    )
  );
};

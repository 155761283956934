import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Button,
  Box,
  Typography,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
} from '@material-ui/core/';

// Images
import stand from '../images/Stand.png';
import weed from '../images/Weed.png';
import pests from '../images/Pest.png';
import drought from '../images/Drought.png';
import waterLog from '../images/Water.png';
import disease from '../images/Disease.png';
import nitrogen from '../images/Nitrogen.png';
import flowering from '../images/Flowering.png';

const useStyles = makeStyles(() => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '320px',
    height: '440px',
    margin: 8,
  },
  cardContent: {
    display: 'flex',
    height: '250px',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  imageBox: {
    height: '160px',
    width: 'auto',
    backgroundColor: '#b3e5fc',
  },
}));

/**
 * Add reports to selected field
 * @param {Function} addToOrder Add selected report to order
 * @param {Function} removeFromOrder Remove selected report from order
 * @param {Function} handleStepChange User can easily go back to field selection
 * @param {Object} field Selected field
 * @returns {JSX} Select reports
 */
export function SelectReports({
  addToOrder,
  removeFromOrder,
  handleStepChange,
  field,
}) {
  const classes = useStyles();

  const reports = [
    {
      name: 'Stand Count', description: 'Plant population reports can help inform you with a high degree of accuracy how many plants are growing in your field.', price: 1.25, image: stand,
    },
    {
      name: 'Weed Detection', description: 'Have you found weeds in your field? We will help you better understand where they may have spread.', price: 1.25, image: weed,
    },
    {
      name: 'Flowering Analysis', description: 'Flowering estimators are perfect for finding out what percentage of your field has flowered in a timely manner.', price: 1.25, image: flowering,
    },
    {
      name: 'Nitrogen Status', description: 'This analysis is used for checking the nitrogen status in your crops. Better understand which areas of your field are healthy and which areas need additional nutrients.', price: 1.25, image: nitrogen,
    },
    {
      name: 'Drought Detection', description: 'Identify and quantify all the plants with drought stress, their current drought status and areas of varying drought conditions.', price: 1.25, image: drought,
    },
    {
      name: 'Pest Detection', description: 'Have you found pests in your field? We will help you better understand where they have spread.', price: 1.25, image: pests,
    },
    {
      name: 'Water Logging', description: 'Identify waterlogged areas.', price: 1.25, image: waterLog,
    },
    {
      name: 'Disease Detection', description: 'Have you found disease in your field? We will help you better understand where they may have spread.', price: 1.25, image: disease,
    },
  ];

  const reportCard = (report) => (
    <Card className={classes.card} key={report.name}>
      <CardActionArea onClick={() => handleSelect(report)}>
        <Box className={classes.imageBox} display="flex" justifyContent="center" alignItems="center">
          <img src={report.image} style={{ height: '100%' }} alt={report.name} />
        </Box>
        <CardContent>
          <Typography gutterBottom variant="h5" component="h2">
            { report.name }
          </Typography>
          <Typography variant="body1" component="p">
            { report.description }
          </Typography>
          <Typography variant="body1" component="p" style={{ marginTop: 8 }}>
            {'$'}
            { report.price }
            {'/Acre'}
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions style={{ justifyContent: 'center' }}>
        { !field ? (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleStepChange(1)}
          >
            Select a Field to Add Reports
          </Button>
        ) : field?.reports.includes(report.name) ? (
          <Button
            color="primary"
            variant="outlined"
            onClick={() => removeFromOrder(report)}
          >
            {`Remove from ${field?.name}`}
          </Button>
        ) : (
          <Button
            color="primary"
            variant="contained"
            onClick={() => addToOrder(report)}
            disableElevation
          >
            {`Add Report to ${field?.name}`}
          </Button>
        )}

      </CardActions>
    </Card>
  );

  const handleSelect = (report) => {
    if (field && !field?.reports.includes(report.name)) {
      addToOrder(report);
    }
  };

  return (
    <Container maxWidth="lg">
      <Box p={1} display="flex" justifyContent="space-around" flexWrap="wrap">
        { reports.map((report) => reportCard(report))}
      </Box>

    </Container>
  );
}

SelectReports.propTypes = {
  addToOrder: PropTypes.func.isRequired,
  removeFromOrder: PropTypes.func.isRequired,
  handleStepChange: PropTypes.func.isRequired,
  field: PropTypes.object,
};

SelectReports.defaultProps = {
  field: null,
};

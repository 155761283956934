import React from 'react';
import { Container, Box, Typography, Button } from '@material-ui/core';
import Carousel from 'react-material-ui-carousel';
import { Endpoints } from '../../../constants/Endpoints';

const SlideDisplay= ({image}) => {
  return (
    <Box>
      <img width={600} height='auto' src={image}/>
    </Box>
  )
}

export const NoResults = ({authenticated, samples}) => {
  return (
    <Container maxWidth='lg'>
      <Box py={1}>
        { authenticated ? (
          <>
            <Typography align='center' variant='h6'>
              Your results are not yet available
            </Typography>
            <Typography align='center'>
              We apologize for any inconvenience. If you expected your results to be ready today, please <a target='_blank' href={`https://www.analytics.ag/contact`}>contact us</a> at support@analytics.ag and we will do our best to help.
            </Typography>
          </>
        ) : (
          <>
            <Typography align='center' variant='h6'>
              { authenticated ? (
                'Please sign in or use the tokenized link in your order email to view reports'
              ) : (
                'Your results are not yet ready'
              )}
            </Typography>

            <Box mt={1} display='flex' justifyContent='center'>
              <Button
                variant="contained"
                color="primary"
                size='large'
                href={`${Endpoints.BASEURL}${Endpoints.SIGNIN}`}
                disableElevation
              >
                <span style={{color: '#ffffff'}}>Sign In</span>
              </Button>
            </Box>
          </>
        )}

        <Box mt={1}>
          <Typography align='center' color='textPrimary' style={{fontWeight: 500}}>
            When your results are ready, you will be able to view PDF charts and maps, as well as maps and zone results in downloadable shapefile format.
          </Typography>
        </Box>

      </Box>
      <Box
        mt={1}
        display="flex"
        justifyContent="center"
        alignItems="center"
      >
        <Carousel interval={5000} navButtonsAlwaysVisible>
          {
            samples.map((item, i) => (
              <SlideDisplay
                key={i}
                image={item}
              />
            ))
          }
        </Carousel>
      </Box>
    </Container>
  );
};

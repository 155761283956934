import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Modal, Button, Divider } from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blackText } from '../../styles/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function getModalStyle() {
  const top = 20;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    width: 300,
    padding: 4,
  };
}


export function SaveBoundary({ open, setOpen, saveEditedBoundary }) {
  const classes = useStyles();
  const modalStyle = getModalStyle();

  const handleClose = () => {
    setOpen(false)
  }

  const handleSaveBoundary = () => {
    saveEditedBoundary()
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="field-claim-prompt"
      aria-describedby="procede-field-claim-prompt"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        className={classes.root}
        style={modalStyle}
        height={260}
        borderRadius="borderRadius"
        boxShadow={2}
        fontWeight={500}
        fontSize={16}
        color={blackText}
      >

        <Box
          display="flex"
          justifyContent='space-between'
          style={{ width: '100%', padding: 4 }}
        >
          <Box>
            &nbsp;
          </Box>
          <Box fontSize={18}>
            Save Edited Boundary
          </Box>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => handleClose()}
          />
        </Box>

        <Divider style={{ width: '100%', height: 1 }} />

        <Box p={1}>
          Would you like save edited boundary for this field?
        </Box>

        <Button
          color='primary'
          variant='outlined'
          style={{ width: 200, margin: "10px" }}
          onClick={() => handleSaveBoundary()}
        >
          Save
        </Button>

        <Button
          color='primary'
          variant='outlined'
          style={{ width: 200, margin: "10px" }}
          onClick={() => handleClose()}
        >
          Cancel
        </Button>

      </Box>
    </Modal>
  )
}

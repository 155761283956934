/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Tabs, Tab, AppBar,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FieldDisplay } from './FieldDisplay';
import { TabPanel } from '../../Shared/TabPanel';
import { useWindowDimensions } from '../../../utils/dimensions';
import { textPrimary } from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    color: theme.palette.text.primary,
  },
  header: {
    padding: 6,
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: theme.palette.primary.dark,
    color: '#ffffff',
    fontWeight: 500,
    fontSize: '1.5rem',
  },
}));

const a11yProps = (index) => ({
  id: `field-selection-tab-${index}`,
  'aria-controls': `field-selection-tabpanel-${index}`,
});

/**
 * Houses display of farm's fields. Field components rendered
 * here will display operation level data.
 * @param {Object} clusSeen reference for CLU information
 * @param {Object} commoditiesSeen key:value mappings of commodities seen and their associated commodity types
 * @param {Object} commodityTypesSeen key:value mappings of commodity types seen and their associated intended uses
 * @param {Number} commodityYear Planting year
 * @param {Function} editOperationWithClu Helps setup the modal to edit the CLU boundary
 * @param {array} farmInfo Farm data
 * @param {number} farmIndex Farms index in ordered mappings
 * @param {Boolean} gartPath Denotes whether this interface instance is for creating GART files
 * @param {String} impersonating email address for user being impersonated by agent
 * @param {Array} mismatchOptions Options for displaying mismatches between CLUs and operation
 * @param {Function} openFileDownload Opens modal for downloading personal files
 * @param {Function} openFileUpload Opens modal for uploading personal files
 * @param {Boolean} refreshTableDates lets component know whether or not dates array should be refreshed
 * @param {Number} reinsuranceYear Year for submission of acreage report
 * @param {Function} setRefreshTableDates to change state of refreshTableDates
 * @param {Function} setViewingField Sets field being viewed
 * @param {Object} statesSeen key:value mappings of states seen and their associated commodities
 * @param {Function} updateIntersection Updates data for a CLU, that intersects with operations
 * @param {Function} updateIntersections Updates which CLUs intersect with operations
 * @param {Function} updateLastSaved Calls function to update last saved time shown in footer
 * @param {Function} updateOperation Updates operation
 * @param {Function} updateSelectedItems Updates which farm/field is currently selected
 * @returns {JSX} Farm Section
 */
export function FarmSection({
  clusSeen,
  commoditiesSeen,
  commodityTypesSeen,
  commodityYear,
  editOperationWithClu,
  farmInfo,
  farmIndex,
  gartPath,
  impersonating,
  mismatchOptions,
  openFileDownload,
  openFileUpload,
  refreshTableDates,
  reinsuranceYear,
  setRefreshTableDates,
  statesSeen,
  setViewingField,
  updateIntersections,
  updateOperation,
  updateIntersection,
  updateLastSaved,
  updateSelectedItems,
}) {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const [tab, setTab] = useState(0);

  // Vars to handle dates for farm's operations
  // NOTE: Should probably consider having something to realize if the day changes.. If it does, then today variable would be wrong.
  const [dates, setDates] = useState([]);

  const handleChange = (event, newValue) => {
    setTab(newValue);
    updateLastSaved(farmIndex, newValue);
    updateSelectedItems(farmIndex, newValue);
    displayedField(newValue);
  };

  // This refreshTableDates is passed from AcreageReporting and trigger a refresh when date is reverted (and after onInitialLoad)
  // Instead of recreating the whole array, I could pass in the index of farm, field, and operation that has been reverted and update just that
  useEffect(() => {
    if (!refreshTableDates) { return; }

    const extractedDates = [];
    for (const field of farmInfo[1]) {
      const fieldDates = [];
      for (const operation of field[1]) {
        // const date = formatDate("07/07/1997");
        const date = operation[1].finalPlantedDate;
        fieldDates.push(date);
      }
      extractedDates.push(fieldDates);
    }
    // console.log('extractedDates :>> ', extractedDates);

    setDates(extractedDates);
    setRefreshTableDates(false);
  }, [refreshTableDates]);

  useEffect(() => {
    displayedField(0);
  }, [farmInfo]);

  const displayedField = (tabValue) => {
    const operation = farmInfo[1][tabValue][1][0][1];
    const field = {
      countyCode: operation.countyCode,
      id: operation.fieldID,
      farmId: operation.farmID,
      farmName: farmInfo[0],
      name: farmInfo[1][tabValue][0],
      orgId: +operation.orgID,
      stateCode: operation.stateCode,
    };
    // console.log('field :>> ', field);
    setViewingField(field);
  };

  return (
    <Box className={classes.root}>
      <AppBar position="static" style={{ maxWidth: width, backgroundColor: 'rgba(25, 104, 49, 0.14)' }}>
        <Tabs
          value={tab}
          onChange={handleChange}
          aria-label="field selection"
          variant="scrollable"
          scrollButtons="on"
          indicatorColor="primary"
          style={{ color: textPrimary }}
        >
          {(farmInfo[1]).map((fieldInfo, fieldIndex) => (
            <Tab key={fieldInfo[1][0][1].fieldID} label={fieldInfo[0]} {...a11yProps(fieldIndex)} />
          ))}
        </Tabs>
      </AppBar>

      <Box display="flex" flexDirection="column">
        {(farmInfo[1]).map((fieldInfo, fieldIndex) => (
          <TabPanel key={fieldInfo[1][0][1].fieldID} value={tab} index={fieldIndex}>
            <FieldDisplay
              clusSeen={clusSeen}
              commoditiesSeen={commoditiesSeen}
              commodityTypesSeen={commodityTypesSeen}
              commodityYear={commodityYear}
              dates={dates}
              editOperationWithClu={editOperationWithClu}
              farmIndex={farmIndex}
              fieldIndex={fieldIndex}
              fieldInfo={fieldInfo}
              gartPath={gartPath}
              impersonating={impersonating}
              mismatchOptions={mismatchOptions}
              openFileDownload={openFileDownload}
              openFileUpload={openFileUpload}
              reinsuranceYear={reinsuranceYear}
              setDates={setDates}
              statesSeen={statesSeen}
              updateIntersection={updateIntersection}
              updateIntersections={updateIntersections}
              updateOperation={updateOperation}
            />
          </TabPanel>
        ))}
      </Box>

    </Box>
  );
}

FarmSection.propTypes = {
  clusSeen: PropTypes.shape().isRequired,
  commoditiesSeen: PropTypes.shape().isRequired,
  commodityTypesSeen: PropTypes.shape().isRequired,
  commodityYear: PropTypes.number.isRequired,
  editOperationWithClu: PropTypes.func.isRequired,
  farmInfo: PropTypes.arrayOf(
    PropTypes.oneOfType([
      PropTypes.string.isRequired,
      PropTypes.array.isRequired,
    ])
  ).isRequired,
  farmIndex: PropTypes.number.isRequired,
  gartPath: PropTypes.bool.isRequired,
  impersonating: PropTypes.string.isRequired,
  mismatchOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  openFileDownload: PropTypes.func.isRequired,
  openFileUpload: PropTypes.func.isRequired,
  refreshTableDates: PropTypes.bool.isRequired,
  reinsuranceYear: PropTypes.number.isRequired,
  setRefreshTableDates: PropTypes.func.isRequired,
  statesSeen: PropTypes.shape({
    stateAbbr: PropTypes.shape(),
  }).isRequired,
  setViewingField: PropTypes.func.isRequired,
  updateIntersections: PropTypes.func.isRequired,
  updateOperation: PropTypes.func.isRequired,
  updateIntersection: PropTypes.func.isRequired,
  updateLastSaved: PropTypes.func.isRequired,
  updateSelectedItems: PropTypes.func.isRequired,
};

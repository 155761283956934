import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Box, Typography, Container, Modal,
} from '@material-ui/core/';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { CreateBundle } from './SimpleBundles.js';
import { reports, bundles } from './Services'
import { Cart } from '../Checkout/Cart'

const greyBlue = '#006ba1'

function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.greys.light,
    paddingTop: 10,
  },
  report: {
    width: '300px',
    minHeight: 260,
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 18,
    backgroundColor: '#006ba1',
    color: 'white',
    borderRadius: '8px 8px 0 0',
  },
  content: {
    // minHeight: '340px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bundles: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  selectField: {
    display: "flex",
    justifyContent: "center",
    fontSize: 20,
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
         cursor: 'pointer',
         color: theme.palette.primary.dark,
      },
  },
  paper: {
    position: 'absolute',
    width: '450px',
    height: 'auto',
    backgroundColor: '#ffffff',
  },
  icon: {
    color: greyBlue,
    padding: 3,
    '&:hover': {
        cursor: 'pointer',
    },
  }
}));

export function SimpleReports({ order, addToOrder, remove, includesFields }) {
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);

  // Names of services in order
  const [inOrder, setInOrder] = useState([])
  const [inBundle, setInBundle] = useState([])

  // Open cart in modal
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setInOrder(order.map(x => x.name))

    const bundle = order.filter(x => x.type === 'bundle')
    if (bundle.length > 0) {
      setInBundle(bundle[0].included)
    } else {
      setInBundle([])
    }

  }, [order])


  const addBundle = (bundle, remediation, option, all) => {

    if (bundle.optional.length > 0) {
      bundle.included.push(bundle.optional[option])
    }

    if (remediation) {
      // add remediation to included reports and update price
      bundle.included.push('Remediation Response Analysis')
      bundle.price = bundle.price + bundle.remediationPrice
    }

    addToOrder(bundle)

    let elmnt = document.getElementById('digs-reports');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  const createReportCard = (report, i) => (
    <Box
      key={i}
      m={2}
      className={classes.report}
      boxShadow={3}
      border={5}
      borderColor='#006ba1'
      borderRadius={16}
      fontWeight={500}
      fontSize={14}
    >
      <Box className={classes.title}>
        <Typography align="center" style={{fontWeight: 500, fontSize: 16}}>
          {report.name}
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        style={{height: '100%'}}
      >

        {/* Content */}
        <Box px={1}>
          {report.description.map((info, i) => (
            <Box key={i} p={1}>
              {info}
            </Box>
          ))}
        </Box>

        <Box>
          <Box
            display="flex"
            justifyContent="center"
            fontSize={16}
          >
            {'$'}
            {report.price}
            {'/Acre'}
          </Box>

          <Box m={1}>
            {
              inOrder.includes(report.name) ?
              <Box
                display="flex"
                justifyContent="center"
                fontWeight={500}
                fontSize={12}
                color={'#006ba1'}
              >
                In Order
              </Box>
              :
              inBundle.includes(report.name) ?
              <Box
                display="flex"
                justifyContent="center"
                fontWeight={500}
                fontSize={10}
                color={'#006ba1'}
              >
                Included in selected bundle
              </Box>
              :
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => addToOrder(report)}
                  style={{
                    backgroundColor: '#006ba1',
                  }}
                >
                  Add to Order
                </Button>
              </Box>
            }
          </Box>
        </Box>

      </Box>

    </Box>
  );

  const cartModal = () => (
    <Box
      style={modalStyle}
      className={classes.paper}
      borderRadius="borderRadius"
    >
      <Box display='flex' justifyContent='flex-end' flexGrow={1}>
        <CancelOutlinedIcon
          className={classes.icon}
          onClick={() => setOpen(false)}
        />
      </Box>

      <Cart
        order ={order}
        remove={remove}
        includesFields={includesFields}
        inModal={true}
      />
    </Box>
  )

  return (
    <Box className={classes.root}>

      <Container maxWidth='md'>

        {/* TODO add logic to track acres and pass to submit from modal cart */}
        {/* <Box
          style={{
            position: 'absolute',
            top: '100px',
            right: '40px'
          }}
        >
          <ShoppingCartIcon
            className={classes.icon}
            onClick={() => setOpen(true)}
          />
        </Box> */}

        <Box borderBottom={1}>
          <Typography variant="h4" align="center">Bundles</Typography>
        </Box>

        <Box className={classes.bundles}>
          {
            bundles.map((bundle, i) =>
            (
              <CreateBundle
                key={i}
                report={bundle}
                addBundle={addBundle}
                inOrder={inOrder}
              />
            )
          )}
        </Box>
      </Container>

      <Box m={2} id='digs-reports'>
        <Box borderBottom={1}>
          <Typography variant="h4" align="center">
            Individual Services
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          {reports.map((report, i) => createReportCard(report, i))}
        </Box>
      </Box>

      {/* <Modal
        open={open}
        onClose={() => setOpen(false)}
        aria-labelledby="cart-modal"
        aria-describedby="order-cart"
      >
        {cartModal()}
      </Modal> */}
    </Box>
  );
}

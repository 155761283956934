import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { CustomToolTip } from '../../../utils/customComponents'

const stateLevelInstructions = "Use the interactive map to select your field boundary. Begin by clicking your state or the plus icon on map to zoom in to your field boundary. You may also search for your field by address or coordinates by clicking the magnifying glass on the map."

const countyLevelInstuctions = "Use the interactive map to select your field boundary. Continue by clicking your county or the plus icon on map to zoom in to your field boundary. You may also search for your field by address or coordinates by clicking the magnifying glass on the map."

const continueInstructions = "Use the interactive map to select your field boundary. When you find your field on the map, you can select it by clicking inside its boundary. If you would like to customize your field boundary you can do so by selecting one of our draw tools. Additionally, after your field is selected you will have the option to edit the boundary."

const selectionInstructions = "When you see your field on the map, select it by clicking inside its boundary. If you would like to customize your field boundary you can do so by selecting one of our draw tools. Additionally, after your field is selected you will have the option to edit the boundary."

const useStyles = makeStyles((theme) => ({
  infoToolTip: theme.infoToolTip,
}));


export const FieldSelectionInstructions = ({
  zoomLevel,
  width,
  setShowInstructions
}) => {
  const classes = useStyles();

  return (
    zoomLevel <= 6 ? (
      <span style={{marginRight: 4, fontSize: width > 700 ? 14 : 13}}>
        {`Begin by clicking your state or zooming in to select your field${width > 700 ? ' boundary' : ''}`}
        { width > 700 ? (
          <CustomToolTip
            title={stateLevelInstructions}
            placement="right-start"
          >
            <InfoOutlinedIcon
              className={classes.infoToolTip}
              fontSize="small"
            />
          </CustomToolTip>
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
            onClick={() => setShowInstructions(stateLevelInstructions)}
          />
        )}
      </span>
    ) : zoomLevel <= 10 ? (
      <span style={{marginRight: 4, fontSize: width > 700 ? 14 : 12.5}}>
        {`Continue by clicking your county or zooming in to select your field${width > 700 ? ' boundary' : ''}`}

        { width > 700 ? (
          <CustomToolTip
            title={countyLevelInstuctions}
            placement="right-start"
          >
            <InfoOutlinedIcon
              className={classes.infoToolTip}
              fontSize="small"
            />
          </CustomToolTip>
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
            onClick={() => setShowInstructions(countyLevelInstuctions)}
          />
        )}

      </span>
    ) : zoomLevel <= 13 ? (
      <span style={{marginRight: 4, fontSize: width > 700 ? 14 : 13}}>
        {`Continue zooming in on the map to view and select your field${width > 700 ? ' boundary' : ''}`}

        { width > 700 ? (
          <CustomToolTip
            title={continueInstructions}
            placement="right-start"
          >
            <InfoOutlinedIcon
              className={classes.infoToolTip}
              fontSize="small"
            />
          </CustomToolTip>
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
            onClick={() => setShowInstructions(continueInstructions)}
          />
        )}

      </span>
    ) : (
      <span style={{marginRight: 4}}>
        Select or draw your field boundary
        { width > 700 ? (
          <CustomToolTip
            title={selectionInstructions}
            placement="right-start"
          >
            <InfoOutlinedIcon
              className={classes.infoToolTip}
              fontSize="small"
            />
          </CustomToolTip>
        ) : (
          <InfoOutlinedIcon
            className={classes.infoToolTip}
            fontSize="small"
            onClick={() => setShowInstructions(selectionInstructions)}
          />
        )}
      </span>
    )

  )
}

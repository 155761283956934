import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';

import {
	Box,
	Modal,
	IconButton,
	Typography,
	Divider,
	TextField,
	Tooltip,
	Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useWindowDimensions } from '../../utils/dimensions';
import LinkIcon from '@material-ui/icons/Link';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import {
	green,
} from "../../styles/colors";
import climate from "../../images/climate_clear.png";
import cnh from "../../images/cnh_clear.png";
import { createIntegrationInvite } from '../../utils/dataFetchers'
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
	paper: {
		position: 'absolute',
		display: 'flex',
		backgroundColor: '#ffffff',
		flexDirection: 'column',
		fontWeight: 500,
		fontSize: 16,
		top: `${50}%`,
		left: `${50}%`,
		transform: `translate(-${50}%, -${50}%)`,
		padding: 1,
		borderRadius: 4,
	},
	head: theme.updateOperationHeader,
	select: {
		margin: 4,
		width: 200,
		backgroundColor: '#ffffff',
	},
	close: {
		position: 'absolute',
		top: 6,
		right: 6,
	},
	root: {
		color: theme.palette.text.primary,
		display: 'flex',
		flexDirection: 'column',
		padding: '0 28px 8px',
	},
	header: {
		fontWeight: 600,
		fontSize: '1.8rem',
	},
	label: {
		fontSize: '12pt',
		fontWeight: 500,
	},
	description: {
		fontWeight: 500,
	},
	portalEntry: {
		display: 'flex',
		flexDirection: 'column',
		marginTop: '8px',
		padding: '8px',
		maxWidth: '690px',
	},
	connect: {
		display: "flex",
		alignItems: "center",
		flexDirection: "column",
		justifyContent: "space-between",
		padding: 16,
		margin: 10,
		fontWeight: 500,
		fontSize: 16,
		color: theme.palette.text.dark,
		"&:hover": {
			cursor: "pointer",
			backgroundColor: "#f8f8ff",
		},
	},
	logo: {
		height: "80px",
		width: "auto",
		marginBottom: 18,
	},
}));

/**
 * Modal containing the integration data sharing controls
 * @param {bool} open bool to determine if modal should be open
 * @param {Function} setOpen function to change value of open
 * @returns JSX
 */
export const IntegrationInvite = ({
	open,
	setOpen
}) => {
	const classes = useStyles();
	const { height, width } = useWindowDimensions();
	const inviteRef = useRef(null);
	const { enqueueSnackbar } = useSnackbar();

	const [inviteEmail, setInviteEmail] = useState('');
	const [inviteLink, setInviteLink] = useState(null);
	const [dataSource, setDataSource] = useState(null);

	const createInviteLink = async (useEmail) => {
		if (dataSource === null) {
			enqueueSnackbar("Please select a data source for the integration", { autoHideDuration: 5000 })
			return
		}
		let emailTo = useEmail ? inviteEmail : null
		let response = await createIntegrationInvite(dataSource, emailTo)
		if (response.status === 'Ok') {
			if (response.data.emailSent) {
				enqueueSnackbar(`An invitation email has been sent to ${emailTo}`, { autoHideDuration: 5000 })
			}
			else {
				setInviteLink(response.data.inviteLink)
			}
		}
	}

	const copyToClipboard = () => {
		inviteRef.current.select();
		document.execCommand('copy');
		inviteRef.current.setSelectionRange(0, 0);
	};

	const linkIcon = (
		<Tooltip placement="left" title="Copy Link To Clipboard">
			<LinkIcon onClick={copyToClipboard} color="secondary" style={{ cursor: 'pointer', marginRight: 8 }} />
		</Tooltip>
	);

	const invitePlaceholder = (
		<Box className={classes.linkPlaceholder}>
			Get your invite link
			{width > 538 ? (
				<ArrowForwardIcon color="primary" style={{ marginLeft: 4 }} />
			) : (
				<ArrowDownwardIcon color="primary" style={{ marginLeft: 4 }} />
			)}
		</Box>
	);

	const inviteLinkDisplay = (
		<Box display="flex" alignItems="center">
			<TextField
				inputRef={inviteRef}
				variant="outlined"
				value={inviteLink}
				// disabled
				style={{
					width: 320,
					minHeight: 60,
					padding: 8,
				}}
				InputProps={{
					startAdornment: linkIcon,
				}}
			/>
		</Box>
	);



	return (
		<Modal
			open={!!open}
			onClose={() => setOpen(false)}
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
			aria-labelledby="invite-portal"
			aria-describedby="invite-portal"
		>
			<Box
				className={classes.paper}
				boxShadow={2}
				width={width >= 960 ? 700 : width - 40}
				maxHeight={height - 40}
			>
				<Box>
					<IconButton className={classes.close} color="primary" onClick={() => { setOpen(false); }}>
						<HighlightOffIcon />
					</IconButton>
				</Box>

				<Box
					className={classes.root}
					style={{ maxHeight: height - 40, overflowY: 'auto' }}
				>
					{/* Modal Title */}
					<Box mt={'24px'}>
						<Typography align="center" className={classes.header}>
							Connect Account Invite
						</Typography>
					</Box>

					{/* Modal Description */}
					<Box p={1} margin="4px 0px 8px">
						<Typography className={classes.description} component="div">
							Invite a grower to have them connect their account and integrate their precision data for you or generate an invite link that you can send to your growers.
						</Typography>
					</Box>

					<Divider/>

					{/** Datasource select */}
					<Box display="flex" flexDirection="column" style={{marginTop: 8}}>
						<Typography className={classes.label}>
							Select an integration source.
						</Typography>
						<Box p={1} display="flex" justifyContent="center" flexWrap="wrap">
							<Box
								className={classes.connect}
								border={1}
								style={dataSource === 'Climate' ? { backgroundColor: '#dbd8d8' } : { backgroundColor: '#ffffff' }}
								borderColor={green}
								borderRadius="borderRadius"
								onClick={() => setDataSource("Climate")}
							>
								<img
									className={classes.logo}
									src={climate}
									alt="Connect to Climate"
								/>
							</Box>

							<Box
								className={classes.connect}
								border={1}
								style={dataSource === 'CNH' ? { backgroundColor: '#dbd8d8' } : { backgroundColor: '#ffffff' }}
								borderColor={green}
								borderRadius="borderRadius"
								onClick={() => setDataSource("CNH")}
							>
								<img className={classes.logo} src={cnh} alt="Connect to CNH" />
							</Box>
						</Box>
					</Box>
					
					<Divider/>

					{/** Email entry */}
					<Box className={classes.portalEntry}>
						<Typography className={classes.label}>
							Send an email invitation to a grower.
						</Typography>

						<Box p={1} display="flex" alignItems="flex-end" flexWrap="wrap" justifyContent="space-between" maxWidth={520}>
							<TextField
								variant="outlined"
								label="Enter email"
								onChange={(e) => setInviteEmail(e.target.value)}
								style={{ width: 320 }}
							/>
							<Box m={1}>
								<Button
									color="primary"
									variant="contained"
									onClick={() => createInviteLink(true)}
									size="large"
								>
									Invite Grower
								</Button>
							</Box>
						</Box>
					</Box>

					{/** Link generation */}
					<Box className={classes.portalEntry}>
						<Typography className={classes.label}>
							Generate a direct link to distribute to growers.
						</Typography>
						<Box p={1} display="flex" alignItems="center" flexWrap="wrap" justifyContent="space-between" maxWidth={568}>

							{inviteLink ? inviteLinkDisplay : invitePlaceholder}

							<Box m={1}>
								<Button
									color="primary"
									variant="contained"
									onClick={() => createInviteLink(false)}
									size="large"
								>
									Generate Invite Link
								</Button>
							</Box>
						</Box>
					</Box>
				</Box>
			</Box>
		</Modal>
	)
}
import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Box, Button, Typography, Paper, Link, Modal, Divider, AppBar, TextField, FormLabel, RadioGroup, Radio, FormControlLabel,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card,
  CardActionArea, CardContent, Grid
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Endpoints } from '../../constants/Endpoints'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  grey,
  lightGrey,
  darkGrey,
  green,
  blackText,
  darkText,
} from "../../styles/colors";
import * as d3 from 'd3-geo'
import * as wkt from 'terraformer-wkt-parser';

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export function FieldInformation({ field, fieldInformation, deleteField, deleteFarm, updateField, fields, handleFieldSelect }) {

  /**
   * Update Field Information
   * @param {Object} field selected field data
   * @param {Object} fieldInformation field information of field selected
   * @param {Function} deleteField deletes field from fieldManager.Fields table (sets isDeleted flag to 1)
   * @param {Function} updateField updates field information
   * @param {Array} fields list all fields associated to selected org and farm
   * @param {Function} handleFieldSelect set new field selected
   */

  // console.log("field", field)
  // console.log("fieldInformation", fieldInformation)

  const classes = useStyles();
  //Dialog controls
  const [open, setOpen] = useState(false);
  //Field informatipon
  const [fieldName, setFieldName] = useState('')
  const [fieldFSATractNum, setFieldFSATractNum] = useState('')
  const [transferYear, setTransferYear] = useState('')
  const [acres, setAcres] = useState('')
  const [address, setAddress] = useState('')
  const [collateralAcres, setCollateralAcres] = useState('')
  const [county, setCounty] = useState('')
  const [cropShare, setCropShare] = useState('')
  const [cropSharePerc, setCropSharePerc] = useState('')
  const [cropShareSince, setCropShareSince] = useState('')
  const [isInherited, setIsInHerited] = useState('purchased')
  const [otherAcres, setOtherAcres] = useState('')
  const [purchaseYear, setPurchaseYear] = useState('')
  const [purchasePrice, setPurchasePrice] = useState('')
  const [rentalRate, setRentalRate] = useState('')
  const [rented, setRented] = useState('')
  const [rentedYear, setRentedYear] = useState('')
  const [state, setState] = useState('')
  const [township, setTownship] = useState('')
  const [yearPurchased, setYearPurchased] = useState('')
  const [showPurchase, setShowPurchase] = useState(false)
  const [showRented, setShowRented] = useState(true)
  const [showSharePerc, setShowSharePerc] = useState(false)
  const [ownership, setOwnership] = useState('rented')
  const [isPurchase, setIsPurchased] = useState(false)
  const [showInherited, setShowInherited] = useState(false)

  useEffect(() => {
    if (fieldInformation.length > 0) {
      setFieldName(fieldInformation[0].FieldName)
      setAcres(fieldInformation[0].acres)
      setAddress(fieldInformation[0].address)
      setCounty(fieldInformation[0].county)
      setState(fieldInformation[0].state)
      setFieldFSATractNum(fieldInformation[0].Field_FSA_Tract_Number)
      setRentalRate(fieldInformation[0].rental_rate_all_acres)
      setRentedYear(fieldInformation[0].rentedYear)
      setCollateralAcres(fieldInformation[0].collateralAcres)
      setOtherAcres(fieldInformation[0].otherAcres)
      setPurchasePrice(fieldInformation[0].purchase_price)
      setPurchaseYear(fieldInformation[0].purchaseYear)
      setTransferYear(fieldInformation[0].transferYear)
      setCropShareSince(fieldInformation[0].cropShareSince)
      setCropSharePerc(fieldInformation[0].cropSharePercentage)

      if (fieldInformation[0].rented == null || fieldInformation[0].rented == 0 || fieldInformation[0].rented == false) {
        if (fieldInformation[0].cropShare == null || fieldInformation[0].cropShare == 0 || fieldInformation[0].cropShare == false) {
          handleOwership('owned')
          if (fieldInformation[0].isInherited == null || fieldInformation[0].isInherited == 0 || fieldInformation[0].isInherited == false) {
            handlePurchase('purchased')
            setPurchasePrice((Math.round(fieldInformation[0].purchase_price * 100) / 100))
            setPurchaseYear(fieldInformation[0].purchaseYear)
          }
          else {
            handlePurchase('inherited')
            setTransferYear(fieldInformation[0].TransferYear)
          }
        }
        else {
          handleOwership('cropShare')
          setCropSharePerc(Math.round(fieldInformation[0].cropSharePercentage * 100) / 100);
          setCropShareSince(fieldInformation[0].cropShareSince);
        }
      }
      else {
        handleOwership('rented')
        setRentalRate((Math.round(fieldInformation[0].rental_rate_all_acres * 100) / 100))
        setRentedYear(fieldInformation[0].rentedYear)
      }
    }
  }, [fieldInformation])

  const confirmDelete = () => {
    setOpen(false);
    deleteField(field.orgId, field.id)
  }

  const confirmUpdate = () => {
    var isCropShare, isRented
    if (ownership == 'rented') {
      isRented = 1;
      isCropShare = 0
    }
    else if (ownership == 'owned') {
      isRented = 0;
      isCropShare = 0;
    }
    else if (ownership == 'cropShare') {
      isRented = 0;
      isCropShare = 1;
    }

    let IsInherited = (isInherited == 'purchased') ? 0 : 1;

    var request = {
      FieldID: field.id,
      acres: acres,
      address: address,
      FieldName: fieldName,
      purchase_price: purchasePrice,
      rented: isRented,
      rental_rate_all_acres: rentalRate,
      field_fsa_tract_number: fieldFSATractNum,
      rentedYear: rentedYear,
      purchaseYear: purchaseYear,
      transferYear: transferYear,
      isInherited: IsInherited,
      cropShare: isCropShare,
      cropSharePercent: cropSharePerc,
      cropShareSince: cropShareSince,
      collateralAcres: collateralAcres,
      otherAcres: otherAcres,
      OrganizationID: field.orgId
    }
    updateField(field.orgId, field.id, request)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOwership = (val) => {
    //console.log('handleownership', val)
    setOwnership(val)
    if (val == 'rented') {
      setShowRented(true)
      setShowPurchase(false)
      setShowSharePerc(false)
      setIsPurchased(false)
      setShowInherited(false)
    }
    else if (val == 'owned') {
      setShowRented(false)
      setShowPurchase(true)
      setShowSharePerc(false)
    }
    else if (val == 'cropShare') {
      setShowRented(false)
      setShowPurchase(false)
      setShowSharePerc(true)
      setIsPurchased(false)
      setShowInherited(false)
    }
  }

  const handlePurchase = (val) => {
    setIsInHerited(val)
    if (val == 'purchased') {
      setIsPurchased(true)
      setShowInherited(false)
    } else {
      setIsPurchased(false)
      setShowInherited(true)
    }
  }


  const confirmModal = () => (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete Field</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this field?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => handleClose()} color="primary" variant='outlined'>
            Cancel
          </Button>
          <Button onClick={() => confirmDelete()} color="primary" variant='contained' disableElevation>
            Delete Field
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )

  const getPath = (field, id) => {
    //console.log(field)
    try {
      const shape = wkt.parse(field.shape);
      // Logic below will attempt to get shape from fieldInformation, but causes deleting fields to break
      // let shape
      // if (field?.shape !== undefined) {
      //   console.log('field shape',field.shape)
      //   shape = wkt.parse(field.shape);
      // } else {
      //   // When field is claimed in another component, it can be missing shape
      //   console.log('field info', fieldInformation)
      //   shape = wkt.parse(fieldInformation[0].boundary);
      // }
      const width = 100
      const projection = d3.geoMercator()
      projection.fitExtent([[20, 20], [width, width]], shape);
      const geoPath = d3.geoPath(projection);
      return geoPath(shape)

    } catch (err) {
      console.log(err)
      return ''
    }
  }

  const drawFieldShape = (field, i) => {
    return (
      <Grid key={i} item xs={4} style={{ margin: '10px', overflow: 'unset' }}>
        <Card className={classes.root} onClick={() => handleFieldSelect(field)}>
          <CardActionArea>
            <CardContent>
              <Typography gutterBottom variant="h5" component="h2">
                {field.name}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                Acres: {Math.round(field.acres * 100) / 100}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                State: {field.state}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                County:  {field.county}
              </Typography>
              <Typography variant="body2" color="textSecondary" component="p">
                <svg id={"shapesvg" + i} width='100' height='100'>
                  <path fill="none" stroke="#F05922" strokeWidth="3" d={getPath(field, i)} />
                </svg>
              </Typography>
            </CardContent>
          </CardActionArea>
        </Card>
      </Grid>
    )
  }

  return (
    <Box my={1} style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center">
        <Typography variant="h6" style={{ color: green }}>Field Information</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box mx={2} my={1}>
          <TextField
            id="outlined-helperText"
            label="Field Name"
            helperText="Edit field name"
            variant="outlined"
            value={fieldName}
            onChange={(e) => setFieldName(e.target.value)}
          />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Acreage"
              helperText="Edit acreage"
              variant="outlined"
              style={{ width: "200%" }}
              value={acres}
              onChange={(e) => setAcres(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Address"
              helperText="Edit field address"
              variant="outlined"
              style={{ width: "200%" }}
              value={address}
              onChange={(e) => setAddress(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="County"
              helperText="State"
              variant="outlined"
              style={{ width: "200%" }}
              value={county}
              onChange={(e) => setCounty(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="State"
              helperText="County"
              variant="outlined"
              style={{ width: "200%" }}
              value={state}
              onChange={(e) => setState(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="FSA Tract Number"
              helperText="Edit FSA Tract Number"
              variant="outlined"
              style={{ width: "200%" }}
              value={fieldFSATractNum}
              onChange={(e) => setFieldFSATractNum(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Field Ownership</FormLabel>
            <RadioGroup aria-label="Owner" name="Owner" onChange={(e) => handleOwership(e.target.value)} value={ownership}>
              <FormControlLabel value="rented" control={<Radio />} label="Rented" />
              <FormControlLabel value="owned" control={<Radio />} label="Owner" />
              <FormControlLabel value="cropShare" control={<Radio />} label="Crop Share" />
            </RadioGroup>
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" hidden={!showPurchase}>
          <Box mx={2} my={1}>
            <FormLabel component="legend">Purchased/Inherited?</FormLabel>
            <RadioGroup aria-label="purchase" name="purchase" onChange={(e) => handlePurchase(e.target.value)} value={isInherited}>
              <FormControlLabel value="purchased" control={<Radio />} label="Purchased" />
              <FormControlLabel value="inherited" control={<Radio />} label="Inherited" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showRented}>
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Rental Rate"
              helperText="Edit rental rate"
              variant="outlined"
              style={{ width: "200%" }}
              value={rentalRate}
              onChange={(e) => setRentalRate(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" hidden={!showRented}>
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Rented Since"
              helperText="Edit rented year"
              variant="outlined"
              style={{ width: "200%" }}
              value={rentedYear}
              onChange={(e) => setRentedYear(e.target.value)}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!isPurchase}>
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Purchase Price"
              helperText="Edit purchase price"
              variant="outlined"
              style={{ width: "200%" }}
              value={purchasePrice}
              onChange={(e) => setPurchasePrice(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" hidden={!isPurchase}>
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Purchase Year"
              helperText="Edit purchase year"
              variant="outlined"
              style={{ width: "200%" }}
              value={purchaseYear}
              onChange={(e) => setPurchaseYear(e.target.value)}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showInherited}>
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Ownership transfer year"
              helperText="Edit purchase year"
              variant="outlined"
              style={{ width: "200%" }}
              value={transferYear}
              onChange={(e) => setTransferYear(e.target.value)}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showSharePerc}>
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Percent to landlord(%)"
              helperText="Enter percent of crop that goes to landlord"
              variant="outlined"
              style={{ width: "200%" }}
              value={cropSharePerc}
              onChange={(e) => setCropSharePerc(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between" hidden={!showSharePerc}>
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Shared since"
              helperText="Edit  year"
              variant="outlined"
              style={{ width: "200%" }}
              value={cropShareSince}
              onChange={(e) => setCropShareSince(e.target.value)}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Collateral Acres"
              helperText="Edit collateral acres"
              variant="outlined"
              style={{ width: "200%" }}
              value={collateralAcres}
              onChange={(e) => setCollateralAcres(e.target.value)}
            />
          </Box>
        </Box>
        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Other acres"
              helperText="Edit other acres"
              variant="outlined"
              style={{ width: "200%" }}
              value={otherAcres}
              onChange={(e) => setOtherAcres(e.target.value)}
            />
          </Box>
        </Box>



        <Box display="flex"> {/** edit buttons */}
          <Box mx={2} my={1}>
            <Button variant="outlined" color="primary" onClick={() => handleClickOpen()}>Delete Field</Button>
          </Box>
          <Box mx={2} my={1}>
            <Button variant="contained" color="primary" onClick={() => confirmUpdate()} disableElevation>Save Changes</Button>
          </Box>
        </Box>
      </Box>
      { confirmModal()}

      <Grid container spacing={2}>
        {fields.map((field, i) => drawFieldShape(field, i))}
      </Grid>
    </Box>
    
  )
}

import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import {
  COLOR_RANGE,
  COLORS,
  DOLLAR_FORMAT,
  NEGATIVE_COLOR_RANGE, 
  POSITIVE_COLOR_RANGE
} from "../../../../../../constants";
import { scaleQuantize } from "d3-scale";

const useStyles = makeStyles(() => ({
  row: {
    border: "none",
    "& :first-child": {
      paddingLeft: 0,
    },
  },
  cell: {
    fontSize: 14,
    color: COLORS.text,
    padding: 7,
    border: `4px solid ${COLORS.white}`,
  },
}));

/**
 * Renders the row
 * @param {object} row object with row data
 * @param {array} headerCells  array of objects with functions/information
 * needed to access data in row object
 * @param {function} profitScale function to assign color according to scale
 */

const Row = ({ row, headerCells, showHeatmap, profitScale, negativeScale, positiveScale, showVarietyAcreage }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      {headerCells.map((headerCell, i) => {
       // console.log("headerCell", headerCell)
        let matchingValue = headerCell.accessor(row);
        let backgroundColor = "";

        if(matchingValue < 0){
          backgroundColor = negativeScale(matchingValue);
        }
        else if(matchingValue >= 0){
          backgroundColor = positiveScale(matchingValue);
        }
        else{
          backgroundColor = profitScale(matchingValue);
        }

        let color = COLOR_RANGE.find(
          (d) => d.backgroundColor === backgroundColor
        ) == undefined ? {
          color: COLORS.text
        } : COLOR_RANGE.find(
          (d) => d.backgroundColor === backgroundColor
        );
        
        //console.log("matchingValue", matchingValue, headerCell);

        if(showVarietyAcreage){
          if(typeof(matchingValue) != "string" && matchingValue !== null){
            matchingValue = matchingValue.toFixed(2) + " Acres"
          }          
        }
        else{
          if(headerCell.id !== "variety" && matchingValue !== null){
            matchingValue = DOLLAR_FORMAT(matchingValue) + " /Acre"
          }
        }
        
        return (
          <TableCell
            key={`table-cell-for-${headerCell.id}`}
            className={classes.cell}
            align={"left"}
            style={{
              backgroundColor: showHeatmap ? matchingValue
                ? i
                  ? backgroundColor
                  : COLORS.white
                : COLORS.white : COLORS.offWhite,
              color: showHeatmap ? i
                ? matchingValue
                  ? color.color
                  : COLORS.gray
                : "inherit" : COLORS.text,
            }}
          >
            {matchingValue
              ? !i
                ? matchingValue
                : matchingValue
              : "---"}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
export default Row;

Row.propTypes = {
  row: PropTypes.object.isRequired,
  headerCells: PropTypes.array.isRequired
};

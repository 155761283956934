import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { lightBlue, grey } from '@material-ui/core/colors';
import { Button, Typography, Box, Divider } from '@material-ui/core/';
import { blackText, darkText } from '../../../styles/colors'

const useStyles = makeStyles(theme => ({
  root: {
    fontColor: blackText,
    fontWeight: 500,
  },
  title: {
    color: darkText,
    display: 'inline',
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  value: {
    color: blackText,
    display: 'inline',
    marginLeft: '5px',
    fontWeight: 500,
    fontSize: '1.2rem'
  },
  invoiceButton: {
    marginLeft: 32,
    '&:hover': {
         cursor: 'pointer',
         color: theme.palette.primary.dark
      },

  }
}));


export function OrderInformation({report, subReport}) {
  const classes = useStyles()

  return (
    <Box className={classes.root} px={1}>

      <Box pt={1} display='flex' alignItems='center'>
        <Box px={2} fontSize={20}>
          Order #: {report.orderId}
        </Box>

        {
          (report.orderLink !== undefined && report.orderLink !== '' && report.orderLink !== null) &&
            <Button
              className={classes.invoiceButton}
              variant='outlined'
              color='primary'
              href={report.orderLink}
              target="_blank"
              rel="noopener"
            >
              <Box fontSize={18}>
                invoice
              </Box>

            </Button>
        }

      </Box>

      <Box
        m={2}
        border={1}
        borderColor="greys.main"
        borderRadius='borderRadius'
      >
        <Box
          p={2}
          display='flex'
          justifyContent='center'
          style={{width: '100%'}}
          fontSize={20}
        >
          {'Operator Information'}
        </Box>

        <Divider />
        <Box p={1}>
          <Box m={1} display='flex' flexWrap='wrap'>
            <Typography className={classes.title}>Flight operator:</Typography>
            <Typography className={classes.value}>{subReport.flightOperator}</Typography>
          </Box>
          <Box m={1} display='flex' flexWrap='wrap'>
            <Typography className={classes.title}>Email:</Typography>
            <Typography className={classes.value}>{subReport.flightOperatorEmail}</Typography>
          </Box>
          <Box m={1} display='flex' flexWrap='wrap'>
            <Typography className={classes.title}>Phone:</Typography>
            <Typography className={classes.value}>{subReport.flightOperatorPhone}</Typography>
          </Box>
          <Box m={1} display='flex' flexWrap='wrap'>
            <Typography className={classes.title}>Company:</Typography>
            <Typography className={classes.value}>{subReport.flightOperatorCompany}</Typography>
          </Box>
          <Box m={1} display='flex' flexWrap='wrap'>
            <Typography className={classes.title}>Resolution:</Typography>
            <Typography className={classes.value}>{subReport.resolution}</Typography>
          </Box>
          <Box m={1} display='flex' flexWrap='wrap'>
            <Typography className={classes.title}>Equipment:</Typography>
            <Typography className={classes.value}>{subReport.equipmentType}</Typography>
          </Box>
          <Box m={1} display='flex' flexWrap='wrap'>
            <Typography className={classes.title}>Equipment Brand:</Typography>
            <Typography className={classes.value}>{subReport.equipmentBrand}</Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

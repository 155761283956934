/* eslint-disable no-nested-ternary */
// region - imports
// React
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import ReactGA from 'react-ga'

// Styling
import {
  grey,
  lightGrey,
  offWhite,
  darkGrey,
  green,
  blackText,
  darkText,
} from '../../../styles/colors';

// material-ui
import {
  Box,
  Button,
  Typography,
  Link,
  Modal,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  TextField,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import FiberManualRecordOutlinedIcon from '@material-ui/icons/FiberManualRecordOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// Images
import climate from '../../../images/climate.png';
import climateLogo from '../../../images/CFV_STK_RGB.png';
import dataLayers from '../../../images/datalayers.png';
import poweredBy from '../../../images/Ag-Analytics_Full_Black_Powered.svg'
import profitGif from '../../../images/plgif.gif';

// Important
import { Endpoints } from '../../../constants/Endpoints';
import { UserContext } from '../../Context/UserContext';
import * as df from '../../../utils/dataFetchers';

// Custom Components
import { Integrate } from '../../Partners/Integrate';
import { BrainTreeDropIn } from '../../ProfitLayers/Reports/BrainTreeDropInReact';
import { PaymentApp } from '../../ProfitLayers/Reports/PaymentApp';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { CustomToolTip } from '../../../utils/customComponents';
import { useWindowDimensions } from '../../../utils/dimensions';
import { dollarFormat } from '../../../utils/helpers';

// Useful Packages
import { useSnackbar } from 'notistack';
// endregion

// region - styling
const useStyles = makeStyles((theme) => ({
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
  },
  appBarSelections: theme.appBarSelections,
  appBarSelectionsMobile: {
    ...theme.appBarSelections,
    margin: '0 10px',
    overflowX: 'auto',
  },
  arrows: theme.navigationArrows,
  buttonLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    backgroundColor: '#ffffff',
  },
  cardText: {
    fontWeight: 500,
    fontSize: 18,
    color: blackText,
    padding: 4,
  },
  centerContent: {
    display: 'flex',
    justifyContent: 'center',
  },
  check: {
    marginRight: 20,
    fontSize: '1.75rem',
  },
  checkbox: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  checkout: {
    minWidth: 380,
    margin: '16px 8px',
    padding: '8px 16px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 16,
    color: theme.palette.text.primary,
    backgroundColor: '#ffffff',
  },
  circle: {
    height: '28px',
    marginRight: 20,
    fontSize: '0.9rem',
  },
  claimOffer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    padding: '12px 6px',
    backgroundColor: green,
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  close: {
    fontSize: 18,
    color: theme.palette.greys.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  connect: theme.connect,
  ctaLinks: {
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  discountText: {
    marginBottom: 8,
    fontSize: '1rem',
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  divider: {
    height: 2,
    backgroundColor: grey,
  },
  dot: {
    fontSize: 8,
    marginRight: 8,
    marginTop: 5,
  },
  edit: {
    marginLeft: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  featuresLink: {
    fontSize: '1rem',
    fontWeight: 600,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  fullLogo: {
    width: '250px',
    height: 'auto',
    marginRight: '20px',
  },
  getPdf: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  greenClick: theme.plIcon,
  hover: {
    fontColor: theme.palette.text.primary,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.secondary,
    },
  },
  icon: theme.icon,
  imageContainer: {
    borderRadius: 4,
    border: `solid ${theme.palette.greys.dark} 1px`,
    margin: 16,
    paddingBottom: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  infoToolTip: theme.infoToolTip,
  label: theme.label,
  logIn: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.lightGrey,
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginRight: '8px',
  },
  messageDisplay: {
    margin: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  mobileConnect: {
    ...theme.connect,
    fontSize: 16,
    padding: 6,
    margin: 6,
  },
  mobileSteps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  operationBox: theme.centeredColumn,
  operations: {
    backgroundColor: theme.palette.greys.light,
    height: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
  },
  paper: {
    position: 'absolute',
    width: '90vw',
    maxWidth: 1000,
    height: '85vh',
    backgroundColor: theme.palette.greys.light,
    overflow: 'auto',
  },
  partner: {
    height: '60px',
    width: 'auto',
    margin: 6,
  },
  partner2: {
    height: '80px',
    width: 'auto',
    margin: 18,
  },
  planLabel: {
    fontWeight: 600,
    fontSize: 16,
    color: blackText,
  },
  planText: {
    fontWeight: 500,
    fontSize: 16,
    color: blackText,
  },
  plan: {
    width: '380px',
    maxWidth: '380px',
    height: 'auto',
    flexGrow: 1,
  },
  planBoldText: {
    margin: '6px 0px',
    fontWeight: 600,
    textAlign: 'center',
  },
  planBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  planCard: {
    display: 'flex',
    flexDirection: 'column',
    width: 370,
    height: 480,
    justifyContent: 'space-between',
    padding: '8px 16px',
    border: `4px solid ${theme.palette.greys.main}`,
    backgroundColor: offWhite,
    color: theme.palette.text.primary,
  },
  planCardText: {
    margin: '20px 4px',
    display: 'flex',
  },
  planCost: {
    marginBottom: 24,
    fontSize: 24,
    textAlign: 'center',
  },
  planHeader: {
    backgroundColor: '#67b346',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px 15px 0 0',
  },
  planTitle: {
    marginTop: 8,
    fontSize: '2rem',
    fontWeight: 500,
    textAlign: 'center',
  },
  select: {
    padding: '10px',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sync: {
    ...theme.greenHover,
    fontSize: 18,
    marginLeft: '5px',
  },
  terms: {
    height: 300,
    overflowY: 'auto',
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 640,
    margin: 8,
    paddingTop: 8,
    color: theme.palette.text.primary,
  },
  typography: {
    lineHeight: '28px',
  },
}));

/**
 * Modal style
 * @returns {Object} style for modal
 */
function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
  };
}
// endregion

export const specialPricing = () => (
  <Box
    mt={1}
    padding={1}
    display="flex"
    flexDirection={window.innerWidth < 680 ? 'column' : 'row'}
    alignItems="center"
    border={2}
    borderRadius={16}
    borderColor={green}
    maxWidth={776}
    boxShadow={2}
  >
    <Box
      display="flex"
      alignItems="center"
      flexDirection={window.innerWidth < 680 ? 'row' : 'column'}
    >
      <Box mx={1} style={{ fontSize: 26, fontWeight: 600, color: blackText }}>
        SPECIAL OFFER
      </Box>
      <Box mx={1}>
        <img style={{ height: 60, width: 'auto' }} src={climate} alt="Climate Logo" />
      </Box>
    </Box>

    <Box mx={1}>
      <Typography>
        Take 50% off your first year of PROFIT LAYERS
        <sup>&reg;</sup>
        {' '}
        for users who have integrated with Climate FieldView.
        <span style={{ fontWeight: 500 }}>Special pricing is automatically applied upon purchase.</span>
        {' '}
        Offer ends 2/28.
      </Typography>
      <span>*First year is $250, the subscription will renew automatically at $500 per year.</span>
    </Box>
    <Box mr={1} fontWeight={600} fontSize={28}>
      $250
    </Box>
  </Box>
);

/**
 * Show packages available for purchase, purchase screen, the success page
 * @param {Function} setSection sets section for Navbar highlight
 * @returns {JSX} Pricing/Purchase page
 */
export function Pricing({ setSection }) {
  // region - variables
  const { height, width } = useWindowDimensions();
  const user = useContext(UserContext)[0];
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [authenticated, setAuthenticated] = useState(false);
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);
  const [openFeatureList, setOpenFeatureList] = useState(false);

  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [showPayment, setShowPayment] = useState(false);
  const [field, setField] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(-1);
  const [newUser, setNewUser] = useState(false);
  const [selectedOrgSource, setSelectedOrgSource] = useState('');
  const [checkingOut, setCheckingOut] = useState(false);
  const [termsChecked, setTermsChecked] = useState(false);

  const basePrice = Endpoints.PROFITLAYERS_PRICE;
  const [discount, setDiscount] = useState(0);
  const [referralcode, setReferralcode] = useState('');
  const [focusReferral, setFocusReferral] = useState(false);
  // endregion

  // region - useEffects
  useEffect(() => {
    const url = document.URL.split('?');
    if (url.length > 1) {
      const extension = url[1];
      if (extension.includes('token')) {
        // let token = extension.replace("token=", '')
        // //console.log(token)
        // setUserToken(token)
        // //remove token from url so user can't grab it
        // url.pop()
        // window.history.replaceState({}, document.title, url[0])
      } else if (extension.includes('SignUp')) {
        setNewUser(true);
      }
    }
  }, []);

  useEffect(() => {
    // console.log('user.isAuthenticated', user.isAuthenticated);
    if (user.isAuthenticated !== undefined) {
      if (user.isAuthenticated) {
        setAuthenticated(true);
        getOrganizations();
        setLoginPromptOpen(false);
      } else if ((user.isAuthenticated && newUser)) {
        // NOTE: This condition will never happen given the previous one... 
        setAuthenticated(true);
        // needs to create default org and farm here.
        handleNewUser();
      } else {
        // Let user see pricing before having them log in. (Do not open modal directly)
        // setLoginPromptOpen(true);
      }
    }
  }, [user, newUser]);

  // Ensure navbar has correct section
  useEffect(() => {
    if (setSection !== undefined) {
      setSection(4);
    }
  }, [setSection]);

  useEffect(() => {
    if (orgs.length > 0) {
      const source = orgs.filter((org) => selectedOrg === org.ID);
      if (source.length > 0) {
        setSelectedOrgSource(source[0].source);
      }
    }
  }, [orgs, selectedOrg]);
  // endregion

  // region - handle new user & org changes
  /**
   * Create default client
   * @param {string} orgId Id of org to create farm for
   * @returns {void}
   */
  async function createDefaultClient(orgId) {
    const newId = await df.getNewGUID('Clients');
    await df.createClient(newId, 'Default-Client', orgId, 'Ag-Analytics');
  }

  /**
   * Create default farm
   * @param {string} orgId Id of org to create farm for
   * @returns {void}
   */
  async function createDefaultFarm(orgId) {
    const newId = await df.getNewGUID('Farms');
    await df.createNewFarm(newId, 'Default-Farm', orgId, 'Ag-Analytics');
  }

  const handleOrgChange = (orgID) => {
    // console.log('handleOrgChange');
    setSelectedOrg(orgID);
    const fieldObj = {
      orgId: orgID,
    };
    setShowPayment(true);
    setField(fieldObj);
    setSelectedOrgId(orgID);
  };

  const getOrganizations = async () => {
    try {
      const response = await df.fetchOrganizations();
      const purchasedOrgs = await df.alreadyPurchasedOrgs('PROFITTOOL');

      for (let i = 0; i < response.length; i++) {
        for (let j = 0; j < purchasedOrgs.length; j++) {
          if (response[i].ID === purchasedOrgs[j].organizationId) {
            response[i].disabled = true;
          }
        }
      }

      // console.log("response", response)
      setOrgs(response);

      const firstOrg = response.filter((org) => org.disabled === undefined);
      // console.log(firstOrg)
      if (firstOrg !== undefined && firstOrg.length > 0) {
        handleOrgChange(firstOrg[0].ID);
      }
    } catch (err) {
      console.error('getOrganizations', err);
      enqueueSnackbar('Error getting Organizations!');
    }
  };

  const handleNewUser = async () => {
    const newID = await df.getNewOrgID('Organizations');
    await df.createNewOrganization(newID, 'Default', 'Ag-Analytics');

    // create default client and farm for org
    await createDefaultClient(newID);
    await createDefaultFarm(newID);
    setLoginPromptOpen(false);
    getOrganizations();
  };
  // endregion

  // region - handle unauthenticated user
  const handleLogin = () => window.location.assign(Endpoints.BASEURL + Endpoints.SIGNINPURCHASE);

  const handleSignUp = () => window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUPPURCHASE);

  const modalText = () => (
    <Box
      my={1}
      p={1}
      display="flex"
      flexDirection="column"
      fontSize={16}
      fontWeight={500}
      style={{ maxWidth: 740 }}
    >
      <Typography align="center" variant="h4">
        First-Time Visitors:
      </Typography>
      <Typography>
        Connect your precision ag system for advanced insights, analysis, and features. This connection will securely pull in your field boundaries, information, and any operations data.*
      </Typography>
    </Box>
  );

  const showIntegrate = () => (
    <Modal
      open={loginPromptOpen}
      onClose={() => setLoginPromptOpen(false)}
      aria-labelledby="log-in-sign-up"
      aria-describedby="log-in-sign-up-prompt"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        style={modalStyle}
        className={classes.paper}
        borderRadius="borderRadius"
        boxShadow={2}
      >
        <Box
          p={1}
          display="flex"
          justifyContent="space-between"
          style={{ width: '100%', backgroundColor: '#ffffff' }}
        >
          <Box>&nbsp;</Box>

          <Box fontWeight={500} fontSize={28} color={blackText}>
            To Continue Your Purchase:
          </Box>

          <CancelOutlinedIcon
            className={classes.close}
            onClick={() => setLoginPromptOpen(false)}
          />
        </Box>

        <Divider />
        {!authenticated && (
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: '100%' }}
            flexWrap="wrap"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleLogin()}
              style={{ margin: '8px 16px', fontSize: 20, width: '220px' }}
              disableElevation
            >
              SIGN IN
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSignUp()}
              style={{ margin: '8px 16px', fontSize: 20 }}
              disableElevation
            >
              CREATE AN ACCOUNT
            </Button>
          </Box>
        )}

        <Integrate
          setChecked={setTermsChecked}
          customText={modalText}
        />
      </Box>
    </Modal>
  );
  // endregion

  // region - plan helpers
  const showIngerateModal = () => {
    ReactGA.event({
      category: "Plan Choice",
      action: "Basic Selected"
    })
    setLoginPromptOpen(true);
  };

  const PlanCardText = ({ normalHeight, style, typographyContent }) => (
    <Box
      display="flex"
      margin={normalHeight ? '4px' : '20px 4px'}
    >
      <CheckRoundedIcon className={classes.check} />
      <Typography
        className={classes.typography}
        style={style}
      >
        {typographyContent}
      </Typography>
    </Box>
  )

  const selectStandardPlan = () => {
    ReactGA.event({
      category: "Plan Choice",
      action: "Premium Selected"
    })
    if (authenticated) {
      // returnCheckoutDetails('standard');
      setCheckingOut(true);
    } else {
      showIngerateModal();
    }
  };

  const handleCheckoutWithDiscount = () => {
    setFocusReferral(true);
    if (!checkingOut) {
      selectStandardPlan();
    }
  };

  const selectContact = () => {
    ReactGA.event({
      category: "Plan Choice",
      action: "Enterprise Selected"
    })
    window.open("https://profit.ag/contact", '_blank').focus()
  }

  const planButton = (buttonText, larger, callback) => (
    <Button
      variant="contained"
      color="primary"
      size="large"
      style={{
        borderRadius: 8,
        fontSize: larger ? 20 : 15,
      }}
      onClick={() => callback()}
      disableElevation
    >
      {buttonText}
    </Button>
  )
  // endregion

  // region - features list
  const NestedCardText = ({ typographyContent }) => (
    <Box
      display="flex"
      margin={'4px'}
    >
      <FiberManualRecordOutlinedIcon className={classes.circle} />
      <Typography
        className={classes.typography}
      >
        {typographyContent}
      </Typography>
    </Box>
  )

  const fullFeatureList = [
    <>
      Generate custom&nbsp;
      <b>profit maps</b>
      &nbsp;based on your selected operation layers
    </>,
    <>
      Automatic precision ag integration for&nbsp;
      <b>John Deere Operations Center</b>
      &nbsp;and&nbsp;
      <b>Climate FieldView</b>
    </>,
    <>
      Use the&nbsp;
      <b>Zone Analysis</b>
      &nbsp;tools to create custom management zones within your field or automatically generate zones based on applications or SSURGO soils
    </>,
    'Pull your local files into our automatic file converter as needed',
    'Create manual operations as needed or to use as seeding prescriptions',
    <>
      Use the&nbsp;
      <b>Price Converter</b>
      &nbsp;to update your input costs
    </>,
    'Compare your profit map to Elevation maps, Operation layers, or a Variety map',
    <>
      Use the&nbsp;
      <b>Variety Analysis</b>
      &nbsp;tool to see how your hybrids performed
    </>,
    'See a cost chart of how money is being spent for your field',
    'Compare multiple variables with our scatter plot',
    'Explore our educational insurance tools',
    'Split your landlord-tenant costs and print the results',
    <>
      Push your profit maps back to your&nbsp;
      <b>John Deere Operations Center</b>
    </>,
    'Export a PDF of your profit map',
  ];

  const dataLayersList = [
    'Access public soils data with SSURGO and POLARIS',
    'See historic crop history',
    'View elevation data, weather, seasonal GDD & precipitation',
    'Access aerial imagery, and satellite radar',
    'Check your crop health with NDVI satellite coverage',
    'Investigate crop insurance concepts with educational tools',
    'Explore various beta tools (guidance lines, land value, tile drainage)',
    'Generate custom PDF reports of this data',
  ];

  const farmReportsList = [
    'View variety prevalence and performance across an entire farm for each year',
    'Discover historical trends for your fields with the profitability matrix',
    'See a breakdown of total expenses, revenue, and profits for your farm',
    'Export either a PDF or Excel',
  ];

  const featureList = () => (
    <Modal
      open={openFeatureList}
      onClose={() => setOpenFeatureList(false)}
      aria-labelledby="feature-list"
      aria-describedby="feature-list"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <Box
        className={classes.paper}
        style={{
          ...modalStyle,
          maxWidth: 700,
        }}
        maxWidth={700}
        p="12px 16px 4px"
        borderRadius="borderRadius"
        boxShadow={2}
      >
        {/* Title */}
        <Box
          className={classes.planTitle}
          style={{
            margin: "16px 0px",
            fontWeight: 600,
          }}
        >
          Full List of PROFIT LAYERS&reg; Features
        </Box>

        <CancelOutlinedIcon
          className={classes.close}
          style={{
            position: 'absolute',
            top: 8,
            right: 8,
          }}
          onClick={() => setOpenFeatureList(false)}
        />

        {/* Notice */}
        <Box className={classes.centerContent}>
          <Box
            maxWidth={500}
            marginBottom={2}
          >
            <Divider className={classes.divider}/>
              <Box className={classes.planBoldText}>
                Integrate your precision data to see your ROIs and investigate what’s impacting your bottom line!
              </Box>
            <Divider className={classes.divider}/>
          </Box>
        </Box>

        {/* Profit Layers Features */}
        <Box>
          {fullFeatureList.map((currentFeature, index) => (
            <PlanCardText
              key={index}
              normalHeight={true}
              typographyContent={currentFeature}
            />
          ))}
        </Box>
        
        {/* Data Layers Features */}
        {/* Need all this for the divider to not be the full width when modal size is big... */}
        <Box className={classes.centerContent} m={2}>
          <Divider
            className={classes.divider}
            style={{
              width: '100%',
              maxWidth: 500,
            }}
          />
        </Box>
        <PlanCardText
          normalHeight={true}
          typographyContent={
            <>
              Learn more about your fields, or scout fields of interest with <b>Data Layers&trade;</b>
            </>
          }
        />
        <Box ml="48px">
          {dataLayersList.map((currentFeature, index) => (
            <NestedCardText
              key={index}
              typographyContent={currentFeature}
            />
          ))}
        </Box>

        {/* Farm Reports Features */}
        <Box className={classes.centerContent} m={2}>
          <Divider
            className={classes.divider}
            style={{
              width: '100%',
              maxWidth: 500,
            }}
          />
        </Box>
        <PlanCardText
          normalHeight={true}
          typographyContent={
            <>
              Determine trends impacting your entire farm with <b>Farm Reports</b>
            </>
          }
        />
        <Box ml="48px">
          {farmReportsList.map((currentFeature, index) => (
            <NestedCardText
              key={index}
              typographyContent={currentFeature}
            />
          ))}
        </Box>

        {/* CTA Button and Links */}
        <Box
          textAlign="center"
          margin="24px 12px 16px"
        >
          {planButton('Go To Pricing', false, () => {setOpenFeatureList(false)})}
        </Box>
        <Box 
          style={{
            margin: '12px',
            textAlign: 'center',
          }}
        >
          <Box>
            <span>Want to learn more? Read our&nbsp;</span>
            <span className={classes.ctaLinks} onClick={() => {window.open("http://www.profit.ag/blog", "_blank")}}>
              PROFIT LAYERS&reg; Blog
            </span><br></br>
            or&nbsp;
            <span className={classes.ctaLinks} onClick={() => {window.open("http://www.profit.ag/contact", "_blank")}}>
              Contact Support
            </span>
          </Box>
        </Box>
      </Box>
    </Modal>
  )
  // endregion

  // region - plans
  const freePlanFeatures = [
    'Integrate your data',
    'Generate a few profit maps on us',
    'Explore all the analysis tools available*',
  ];

  const freePlan = () => (
    <Box className={classes.planCard}>
      <Box>
        {/* Title */}
        <Box className={classes.planTitle}>
          LIMITED
        </Box>

        {/* Cost */}
        <Box className={classes.planCost}>
          Free Trial
        </Box>

        {/* Notice */}
        <Divider className={classes.divider}/>
          <Box className={classes.planBoldText}>
            Try out the tools for yourself!
          </Box>
        <Divider className={classes.divider}/>

        {/* Features */}
        <Box>
          {freePlanFeatures.map((currentFeature, index) => (
            <PlanCardText
              key={index}
              typographyContent={currentFeature}
            />
          ))}
        </Box>
      </Box>

      {/* CTA button */}
      <Box textAlign="center" mb={1}>
        {planButton('Get Started', false, showIngerateModal)}
      </Box>
    </Box>
  );

  const standardPlanFeatures = [
    <>
      Unlimited&nbsp;
      <b>
        PROFIT LAYERS<sup>&reg;</sup>
      </b>
      &nbsp;maps
    </>,
    <>
      <b>Full</b> access to&nbsp;
      <b>Farm Reports</b> to analyze your data on the farm level*
    </>,
    'Timely customer support',
  ];

  const standardPlan = () => (
    <Box
      className={classes.planCard}
      style={{
        border: `4px solid ${green}`,
        backgroundColor: checkingOut ? '#ffffff' : lightGrey,
        width: 385,
        height: 500,
      }}
    >
      <Box>
        {/* Title */}
        <Box
          className={classes.planTitle}
          style={{ fontWeight: 600 }}
        >
          PREMIUM
        </Box>

        {/* Cost */}
        <Box
          className={classes.planCost}
          style={{
            top: '-6px',
            position: 'relative',
            marginBottom: 10,
            fontSize: '1.7rem',
          }}
        >
          <Box>
            $
            {Endpoints.PROFITLAYERS_PRICE}
            /Year
          </Box>
          <Typography variant="subtitle2" style={{ color: darkGrey, fontSize: '0.8rem' }}>
            {"Per seat per integrated organization with <2K acres"}
          </Typography>
        </Box>

        {/* Notice */}
        <Divider className={classes.divider}/>
          <Box
            className={classes.planBoldText}
            style={{ fontSize: '1rem' }}
          >
            Continue using all the tools!
          </Box>
        <Divider className={classes.divider}/>

        {/* Features */}
        <Box>
          {standardPlanFeatures.map((currentFeature, index) => (
            <PlanCardText
              key={index}
              style={{ fontSize: '1.1rem' }}
              typographyContent={currentFeature}
            />
          ))}
        </Box>
      </Box>

      {/* CTA button */}
      <Box textAlign="center" my={1}>
        <Box className={classes.discountText} onClick={() => handleCheckoutWithDiscount()}>
          Have a discount code?
        </Box>
        { !checkingOut && (
          planButton('Get Premium', true, selectStandardPlan)
        )}
      </Box>
    </Box>
  );

  const enterprisePlan = () => (
    <Box className={classes.planCard}>
      <Box>
        {/* Title */}
        <Box className={classes.planTitle}>
          ENTERPRISE
        </Box>

        {/* Cost */}
        <Box className={classes.planCost}>
          Contact Us
        </Box>

        {/* Notice and Features */}
        <>
          <Divider
            className={classes.divider}
            style={{ margin: '0px 0px 12px' }}
          />
          <Box
            className={classes.planBoldText}
            style={{ fontSize: 16 }}
          >
            INDIVIDUAL
          </Box>
          <Typography className={classes.typography}>
            If your single organization is <b>bigger than 2,000 acres</b>, contact us for a custom option tailored to your needs.
          </Typography>

          <Divider
            className={classes.divider}
            style={{ margin: '16px 0px 12px' }}
          />
          <Box
            className={classes.planBoldText}
            style={{ fontSize: 16 }}
          >
            MULTIPLE ACCOUNTS
          </Box>
          <Typography className={classes.typography}>
            If you want to manage <b>more than 10 different organizations</b> on behalf of your growers, contact us for a custom bulk discount!
          </Typography>
        </>
      </Box>

      {/* CTA button */}
      <Box textAlign="center" mb={1}>
        {planButton('Contact Us', false, selectContact)}
      </Box>
    </Box>
  );

  const climateSpecialPlan = () => (
    <>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={40}
        bgcolor={green}
      >
        <Typography
          align="center"
          variant="h6"
          style={{ color: 'white' }}
        >
          MOST POPULAR
        </Typography>

      </Box>
      <Box display="flex" flexGrow={1}>
        <Box
          className={classes.premiumCard}
        >
          <Box>
            <Box className={classes.planTitle}>
              SPECIAL 50% OFF
            </Box>

            <Box mx={2} my={1} textAlign="center">
              <Box fontSize={24} fontWeight={500}>
                $250/Year
              </Box>
              <Typography variant="subtitle2" style={{ color: darkGrey }}>
                Per seat per integrated organization
              </Typography>
            </Box>

            <Divider />

            <Box mt={1}>
              <Box className={classes.planCardText}>
                <Typography style={{ fontWeight: 500 }}>
                  All Basic Features +
                </Typography>
              </Box>

              <Box className={classes.planCardText}>
                <CheckRoundedIcon className={classes.check} />
                <Typography>
                  Unlimited&nbsp;
                  <span style={{ fontWeight: 500 }}>
                    PROFIT LAYERS
                    <sup>&reg;</sup>
                  </span>
                  &nbsp;profit maps
                </Typography>
              </Box>

              <Box className={classes.planCardText}>
                <CheckRoundedIcon className={classes.check} />
                <Typography>
                  <span style={{ fontWeight: 500 }}>
                    Full&nbsp;
                  </span>
                  Precision Ag Integration for&nbsp;
                  <span style={{ fontWeight: 500 }}>John Deere Operations Center&nbsp;</span>
                  and&nbsp;
                  <span style={{ fontWeight: 500 }}>Climate FieldView</span>
                </Typography>

              </Box>

              <Box className={classes.planCardText}>
                <CheckRoundedIcon className={classes.check} />
                <Typography>
                  Unlimited elevation maps, guidance lines and integrated insurance tools
                </Typography>
              </Box>

            </Box>
          </Box>
          <Typography style={{ fontSize: '.8rem', fontWeight: 500 }}>
            $250 pricing applies to first year. The subscription will renew automatically at $500 per year. Offer ends 2/28.
          </Typography>

        </Box>
      </Box>
    </>
  );
  // endregion

  // region - checkout
  const checkoutHeader = () => (
    <Box
      p={1.5}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      flexWrap="wrap"
      bgcolor="#ffffff"
    >
      <Button
        variant="contained"
        color="primary"
        onClick={() => setCheckingOut(false)}
        disableElevation
        size="small"
      >
        <ArrowBackIcon />
        <span>Back</span>
      </Button>

      <Typography
        align="center"
        style={{ fontSize: '1.35rem', color: blackText, whiteSpace: 'nowrap' }}
      >
        COMPLETE YOUR PURCHASE
      </Typography>

      <Box>
        {' '}
      </Box>
    </Box>
  );

  const selectedPackage = () => (
    <Box m={2}>
      <Box>
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          height={40}
          bgcolor={green}
        >
          <Typography
            align="center"
            variant="h6"
            style={{ color: 'white' }}
          >
            Subscription
          </Typography>
        </Box>
        { standardPlan() }
      </Box>
    </Box>
  );

  const handlePayment = async (customerData, subscriptionData) => {
    setIsPaymentProcessing(true);
    setShowPayment(false);
    let customerInfo = '';
    try {
      fetch(Endpoints.BASEURL + Endpoints.API_CUSTOMER, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(customerData),
      })
        .then((response) => {
          if (response.ok) {
            const jsonresult = response.json();
            return jsonresult;
          }
          throw new Error(response);
        })
        .then((response) => {
          const customer = JSON.stringify(response.data);
          const dontgotosubscritionpage = sessionStorage.getItem(
            'dontGoToSubscriptionList',
          );

          sessionStorage.setItem('customerInfo', customer);
          customerInfo = customer;

          subscriptionData.ApiKey = customerInfo.apiKey;

          fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(subscriptionData),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error(response);
            })
            .then((response) => {
              // console.log('response', response);
              if (response.isSuccessful === true) {
                setCheckingOut(false);
                setShowPayment(false);
                setIsPaymentProcessing(false);
                setIsSuccessful(true);
                setIsFail(false);
              } else {
                setShowPayment(false);
                setIsPaymentProcessing(false);
                setIsSuccessful(false);
                setIsFail(true);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error('error', error);
        });
    } catch (err) {
      console.error('handlePaymentMethod', err);
    }
  };

  const handleReferralCode = async (value) => {
    setReferralcode(value);

    try {
      const res = await df.checkReferralCode(value);
      if (Object.keys(res).length > 0 && res.percentageDiscount !== undefined) {
        setDiscount(res.percentageDiscount);
      } else {
        setDiscount(0);
      }
    } catch (err) {
      console.error('error verifying disocunt code', err);
      setDiscount(0);
    }
  };

  const openProfitLayers = () => {
    window.location.assign(`${Endpoints.HOME}/app/ProfitLayers`);
  };

  const returnCheckoutDetails = (plan) => {
    if (plan === 'standard') {
      return (
        <Box id="checkout info" color={blackText}>
          <Typography
            style={{
              fontWeight: 500,
              fontSize: 18,
              margin: '4px 0',
            }}
          >
            Plan Details
          </Typography>

          <Typography variant="body1" style={{ margin: 4 }}>
            <span className={classes.planLabel}>Plan Type: </span>
            <span className={classes.planText}>Standard </span>
          </Typography>

          <Typography variant="body1" style={{ margin: 4 }}>
            <span className={classes.planLabel}>Plan Cost: </span>
            <span className={classes.planText}>
              $
              {basePrice}
            </span>
          </Typography>

          <Typography variant="body1" style={{ margin: 4 }}>
            <span className={classes.planLabel}>Discount Applied (1st Year): </span>
            <span className={classes.planText}>
              $
              {basePrice * (discount / 100)}
            </span>
            {/* <CustomToolTip
              title={(
                <span>
                  <p>
                    Take {discount}% off your first year of PROFIT LAYERS.
                  </p>
                  *First year is ${basePrice - (basePrice * (discount / 100))}, the subscription will renew automatically at ${basePrice} per year.
                </span>
              )}
              placement="right-start"
            >
              <InfoOutlinedIcon className={classes.infoToolTip} />
            </CustomToolTip> */}
          </Typography>

          <Typography variant="body1" style={{ margin: 4 }}>
            <span className={classes.planLabel}>Total (1st Year): </span>
            <span className={classes.planText}>
              $
              {basePrice - (basePrice * (discount / 100))}
            </span>
            <CustomToolTip
              title={(
                <span>
                  <p>
                    Take
                    {' '}
                    {discount}
                    % off your first year of PROFIT LAYERS.
                    {/* <sup>&reg;</sup>
                    {' '}
                    for users who have integrated with Climate FieldView.
                    <span style={{ fontWeight: 500 }}>Special pricing is automatically applied upon purchase.</span>
                    {' '}
                    Offer ends 2/28. */}
                  </p>
                  *First year is $
                  {basePrice - (basePrice * (discount / 100))}
                  , the subscription will renew automatically at $
                  {basePrice}
                  {' '}
                  per year.
                </span>
              )}
              placement="right-start"
            >
              <InfoOutlinedIcon className={classes.infoToolTip} />
            </CustomToolTip>
          </Typography>

          <Typography variant="body1" style={{ margin: 4 }}>
            <span className={classes.planLabel}>Renews: </span>
            <span className={classes.planText}>Annually</span>
          </Typography>

          <Divider />

          <Box my={1}>
            <Typography variant="subtitle2">
              Select Organization to Link Subscription
            </Typography>
          </Box>

          <Box my={1}>
            <FormControl variant="outlined">
              <InputLabel id="demo-simple-select-outlined-label">
                Organization
              </InputLabel>

              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedOrg}
                onChange={(e) => handleOrgChange(e.target.value)}
                label="Select Organization"
                style={{ width: '227px' }}
              >
                {orgs.map((x) => (
                  <MenuItem
                    key={x.ID}
                    style={{
                      fontWeight: 500,
                    }}
                    value={x.ID}
                    disabled={x.disabled}
                  >
                    {x.Name}
                    {' '}
                    (
                    {x.ID}
                    )
                    {x.disabled ? ' (Already Purchased)' : ''}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Divider />

          <Box my={1}>
            <Typography variant="subtitle2" style={{ marginBottom: 6 }}>
              Enter Discount Code:
            </Typography>

            <FormControl variant="outlined">
              <TextField
                inputRef={(input) => 
                  {
                    if (input && focusReferral) {
                      input.focus();
                      setFocusReferral(false);
                    }
                  }
                }
                variant="outlined"
                value={referralcode}
                onChange={(event) => handleReferralCode(event.target.value)}
                label="Discount Code"
              />
            </FormControl>
          </Box>
          <Divider />

          <Box id="bt" style={{ marginTop: '10px' }}>
            {showPayment && (
              <PaymentApp
                authenticated={authenticated}
                source="Pricing"
                field={field}
                handlePayment={handlePayment}
                setProcessing={setProcessing}
                selectedOrgId={selectedOrgId}
                orgs={orgs}
                referralcode={referralcode}
              />
            )}
            {isPaymentProcessing && (
              <Box className={classes.head}>
                Processing your payment...
              </Box>
            )}
            {isSuccessful && !isFail && (
              <Box ml={1}>
                <Typography style={{ fontWeight: 500 }}>
                  <span
                    style={{ cursor: 'pointer' }}
                    onClick={() => {
                      openProfitLayers();
                    }}
                  >
                    Thank you for your purchase. Click Here to go to
                    PROFIT LAYERS
                    <sup>&reg;</sup>
                    .
                  </span>
                </Typography>
              </Box>
            )}
            {!isSuccessful && isFail && (
              <Box ml={1}>
                <Typography style={{ fontWeight: 500 }}>
                  Couldn&#39;t process your payment, please contact us at
                  {' '}
                  <b>support@analytics.ag</b>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      );
    }
  };

  const checkout = () => (
    <Box display='flex' flexDirection='column'>
      <Box id="checkout" className={classes.checkout}>
        <Typography variant="h6" align="center">
          Checkout
        </Typography>

        <Divider />

        { returnCheckoutDetails('standard')}

        <Box mt={1}>
          <Typography align='center' variant='subtitle2'>
            View&nbsp;
            <Link
              href={`${Endpoints.HOME}/app/termsandconditions`}
              target="_blank"
              color="primary"
            >
              terms and conditions here
            </Link>
          </Typography>
        </Box>
      </Box>
    </Box>
  );

  const checkoutTerms = () => (
    <Box my={2}>
      <Box
        p={1}
        width={390}
        border={1}
        borderColor={grey}
        borderRadius={4}
        bgcolor="#ffffff"
      >
        <Typography align="center" style={{ fontWeight: 500, fontSize: '1.1rem' }}>Terms of Use</Typography>
        <Divider style={{ marginBottom: 4 }}/>
        <Typography paragraph>
          Intended for business use only, and in those geographic regions and countries where allowed by law, including the United States. This tool is not intended for use by non-business consumers.
        </Typography>
        <Typography paragraph>
          This plan is available to individuals with &#60;10 employees, &#60;10,000 acres, and servicing less than 10 organizations. If you you do not meet these requirements, please&nbsp;
          <Link
            color="primary"
            target="_blank"
            href="https://www.analytics.ag/contact"
          >
            contact us
          </Link>
        &nbsp;for enterprise rates.
        </Typography>
        <Typography paragraph>
          Please note, any discounts apply to
          {' '}
          <b>first year only</b>
          . After the first year, your subscription will automatically renew at
          {' '}
          {dollarFormat(Endpoints.PROFITLAYERS_PRICE)}
          {' '}
          per year.
        </Typography>
        <Typography paragraph>
          Money back guarantee within
          {' '}
          <b>30 days</b>
          {' '}
          of purchase.
        </Typography>
        <Typography>
          View complete&nbsp;
          <Link
            href={`${Endpoints.HOME}/app/termsandconditions`}
            target="_blank"
            color="primary"
          >
            terms and conditions here
          </Link>
        </Typography>
      </Box>
    </Box>
  );
  // endregion

  // region - checkout successful
  const explore = () => (
    <Box px={1}>
      <Typography
        variant="h4"
        align="center"
        style={{ color: blackText, whiteSpace: 'nowrap' }}
      >
        EXPLORE
      </Typography>

      <Box className={classes.messageDisplay}>
        <Box padding={2} boxShadow={2}>
          <Typography align="center" variant="h5">
            Your subscription is active!
          </Typography>

          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box px={1} className={classes.imageContainer}>
              <Typography align="center" style={{ fontWeight: 500 }}>
                Generate custom profit maps using your unlimited Profit Layers
                <sup>&reg;</sup>
                {' '}
                access.
              </Typography>
              <a
                target="_blank"
                href={`${Endpoints.HOME}/app/ProfitLayers`}
              >
                <img
                  src={profitGif}
                  height="300"
                  style={{ borderRadius: '4px' }}
                  alt="Profit Layers link"

                />
              </a>

              <Box mt={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  target="_blank"
                  href={`${Endpoints.HOME}/app/ProfitLayers`}
                >
                  <span className={classes.buttonLink}>Go To ProfitLayers</span>
                </Button>
              </Box>
            </Box>

            <Box px={1} className={classes.imageContainer}>
              <Typography align="center" style={{ fontWeight: 500 }}>
                Explore soil, weather, and more data by visiting Data Layers
                <sup>&trade;</sup>
              </Typography>
              <a
                target="_blank"
                href={`${Endpoints.HOME}/app/DataLayers`}
              >
                <img
                  src={dataLayers}
                  height="300"
                  style={{ borderRadius: '4px' }}
                  alt="Data Layers link"
                />
              </a>

              <Box mt={1}>
                <Button
                  variant="outlined"
                  color="primary"
                  target="_blank"
                  href={`${Endpoints.HOME}/app/DataLayers`}
                >
                  <span className={classes.buttonLink}>Go To DataLayers</span>
                </Button>
              </Box>

            </Box>
          </Box>

        </Box>

        <Box m={1}>
          <Button
            color="primary"
            variant="outlined"
            onClick={subscribeAdditional}
          >
            Subscribe for another organization
          </Button>
        </Box>
      </Box>

    </Box>
  );

  const subscribeAdditional = () => {
    setCheckingOut(true);
    setIsSuccessful(false);
  };
  // endregion

  // region - old features (special messaging)
  const climatePricing = () => (
    <Box
      mt={1}
      padding={1}
      display="flex"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="center"
      border={2}
      borderColor={green}
      bgcolor={offWhite}

    >
      <Box
        display="flex"
        alignItems="center"
      >
        <Box
          p={0.5}
          mx={1}
          textAlign="center"
          fontWeight={500}
          color="white"
          bgcolor="#ac0000"
        >
          <Box style={{ whiteSpace: 'nowrap' }}>SALE ENDS</Box>
          <Box>2/28!</Box>
        </Box>
        <Box
          mx={1}
          textAlign="center"
          fontSize={22}
          color={blackText}
          fontWeight={600}
        >
          <Box>SPECIAL</Box>
          <Box>OFFER</Box>
        </Box>
      </Box>

      <Box mx={1} maxWidth={482}>
        <Typography style={{ fontSize: '.9rem' }}>
          <span style={{ fontWeight: 500 }}>Take 50%</span>
          {' '}
          off your first year of PROFIT LAYERS
          <sup>&reg;</sup>
          {' '}
          by integrating with Climate FieldView. Special pricing is automatically applied upon purchase.
        </Typography>
      </Box>

      <Box display="flex" alignItems="center">
        <Box mx={1}>
          <img style={{ height: 60, width: 'auto' }} src={climateLogo} alt="Climate Logo" />
        </Box>
        <Box
          className={classes.claimOffer}
          onClick={() => selectStandardPlan()}
        >
          CLAIM 50% OFF
        </Box>
      </Box>
    </Box>
  );
  // endregion

  return (
    <Box>
      {/* Pricing and Checkout pages */}
      { !isSuccessful ? (
        !checkingOut ? (
          <Box
            p="24px 8px 16px"
            // -56px because there is margin on the parent component of PurchasePL
            style={{
              height: 'calc(100vh - 56px)',
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'space-between',
            }}
          >
            <Box>
              <Typography
                variant="h3"
                align="center"
                style={{ fontWeight: 600, color: blackText, whiteSpace: 'nowrap' }}
              >
                SIMPLE PRICING
              </Typography>

              <Typography align="center" style={{ fontSize: '1rem', marginTop: 4 }}>
                Every plan includes a <b>30-day money back guarantee</b>.
              </Typography>

              { authenticated && (
                <Box
                  m={3}
                  display="flex"
                  justifyContent="center"
                  style={{
                    position: width > 940 ? 'absolute' : 'static',
                    right: 0,
                    top: '72px',
                  }}
                >
                  <Link
                    style={{ textDecoration: 'none', color: green }}
                    href={`${Endpoints.HOME}/app/ManageSubscription`}
                    target="_blank"
                  >
                    <Box
                      p={1}
                      border={2}
                      borderRadius="borderRadius"
                      borderColor={green}
                      fontWeight={500}
                    >
                      Manage Current Subscriptions
                    </Box>
                  </Link>
                </Box>
              )}
            </Box>

            <Box marginTop="30px">
              <Box display="flex" flexWrap="wrap" justifyContent="center" alignItems="end">
                <Box display="flex" style={{ margin: '0px 8px 12px' }}>
                  { freePlan() }
                </Box>

                <Box display="flex" flexDirection="column" style={{ margin: '0px 20px' }}>
                  <Box
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    height={40}
                    bgcolor={green}
                  >
                    <Typography
                      align="center"
                      variant="h6"
                      style={{ 
                        color: 'white',
                        position: 'relative',
                        top: '2px',
                      }}
                    >
                      TYPICAL USER
                    </Typography>

                  </Box>
                  <Box display="flex" flexGrow={1}>
                    { standardPlan() }
                  </Box>
                </Box>

                <Box display="flex" style={{ margin: '12px 8px 12px' }}>
                  { enterprisePlan() }
                </Box>
              </Box>

              <Box style={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                padding: '24px'
              }}>
                <Box
                  className={classes.featuresLink}
                  onClick={() => setOpenFeatureList(true)}
                >
                  <span style={{ color: blackText }}>* Click here for a </span>
                  full list of features
                </Box>
              </Box>
            </Box>

            <Box>
              {width > 760 &&
                <Box style={{width: '100%', display: 'flex', justifyContent: 'end', paddingBottom: '10px'}}>
                  <img
                    src={poweredBy}
                    className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
                    alt={"Powered By Ag-Analytics"}
                  />
                </Box>
              }

              <Typography align="center" style={{ fontSize: '.9rem' }}>
                <em>Intended for business use only, and in those geographic regions and countries where allowed by law, including the United States. This tool is not intended for use by non-business consumers.</em>
              </Typography>
            </Box>
          </Box>
        ) : (
          <Box
            display="flex"
            flexDirection="column"
            minHeight={height - 56}
            height="100%"
            bgcolor={offWhite}
          >
            { checkoutHeader() }
            <Box
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              flexGrow={1}
            >
              { selectedPackage() }
              { checkout() }
              {/* { checkoutTerms() } */}
            </Box>
          </Box>
        )
      ) : (
        <Fade
          in
          mountOnEnter
          unmountOnExit
        >
          <Box p={1}>
            { explore() }
          </Box>
        </Fade>
      )}

      {/* Spinning loader and modals */}
      { isPaymentProcessing && <SpinningLoader />}
      { showIntegrate() }
      { featureList() }
    </Box>
  );
}

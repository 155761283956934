import React from 'react'
import { Box } from '@material-ui/core'
import { farmFlightRes } from '../Files/FarmFlightResponse'
import {farmFlightOrder} from '../Files/FarmFLightOrder'
import ReactJson from 'react-json-view'

export function FarmFlight({ }) {
  return (
    <Box fontSize={16} fontWeight={500}>
      <Box>JSON Structure of UAV Service Results:</Box>
      <Box mt={2}>
        Find JSON structure document <a href="https://drive.google.com/uc?export=download&id=1ZjraX-bqxTQD4bUEuMiLrO3sCLGy8ENY" download>here</a>.
      </Box>
      <Box mt={2}>
        Below is the JSON structure that will be sent to UAV after customer has placed order. Order and Suborder information which needs to be in results can be found from order request
        <Box>
        <ReactJson
          src={farmFlightOrder}
          theme="monokai"
          name={false}
          indentWidth={2}
          collapseStringsAfterLength={50}
        />
        </Box>
      </Box>
      <Box mt={2} width="100%">
        <Box>Result JSON Schema</Box>                    
        <ReactJson
          src={farmFlightRes}
          theme="monokai"
          name={false}
          indentWidth={2}
          collapseStringsAfterLength={50}
        />
      </Box>
    </Box>
  )
}
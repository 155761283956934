import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga'
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Modal,
  Button,
  Divider,
  TextField,
  LinearProgress,
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer';
import domtoimage from 'dom-to-image';
import { blackText, darkText, green } from '../../../styles/colors';
import { ProfitLayerPdf } from './ProfitLayerPdf';
import { exists } from '../../../utils/helpers';

function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    width: 400,
    height: 560,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 5,
    right: 5,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  getPdf: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
}));

/**
 * Interface for creating PDF.
 * @param {Boolean} open boolean for modal display
 * @param {Function} setOpen change boolean for modal
 * @param {*} errorMessage
 * @param {*} mapLoading
 * @param {*} field
 * @param {*} selectedYear
 * @param {*} netSeeded
 * @param {*} totalRevenue
 * @param {*} totalCosts
 * @param {*} profitMapResponse
 * @param {*} seedings
 * @param {*} harvests
 * @param {*} chemicals
 * @param {*} fieldPasses
 * @param {*} otherCosts
 * @param {*} otherRevenue
 * @param {*} scatterLoaded
 * @param {*} perAcre
 * @param {*} mapLoadedOnViewProfitLayers
 * @returns {JSX} Interface for creating PDF
 */
export function CreatePdf({
  open,
  setOpen,
  errorMessage,
  mapLoading,
  field,
  selectedYear,
  netSeeded,
  totalRevenue,
  totalCosts,
  profitMapResponse,
  seedings,
  harvests,
  chemicals,
  fieldPasses,
  otherCosts,
  otherRevenue,
  scatterLoaded,
  perAcre,
  mapLoadedOnViewProfitLayers,
}) {
  const classes = useStyles();
  const modalStyle = getModalStyle();

  const [reportName, setReportName] = useState('ProfitLayerReport');
  const [notes, setNotes] = useState('');

  const [profitImage, setProfitImage] = useState('');
  const [costChart, setCostChart] = useState('');
  const [scatterPlot, setScatterPlot] = useState('');

  // in process of getting images
  const [gettingScatter, setGettingScatter] = useState(false);

  const [profitLegend, setProfitLegend] = useState([]);
  // profit total for summary table
  const [summary, setSummary] = useState({});
  // varieties for summary table
  const [varieties, setVarieties] = useState([]);

  const [pdfReady, setPdfReady] = useState(false);
  const [pdfLoading, setPdfLoading] = useState(false);
  const [errorNotice, setErrorNotice] = useState(false);

  // // To view what's going on with vars important for letting user generate and download PDF
  // useEffect(() => {
  //   console.log('errorNotice || pdfLoading || mapLoading || !mapLoadedOnViewProfitLayers || profitImage === "" || profitLegend === [] || !exists(summary) || varieties === [] || gettingScatter :>> ', 
  //   errorNotice || pdfLoading || mapLoading || !mapLoadedOnViewProfitLayers || profitImage === "" || profitLegend === [] || !exists(summary) || varieties === [] || gettingScatter);

  //   console.log({errorNotice, pdfLoading, mapLoading, "mapLoadedOnViewProfitLayers": mapLoadedOnViewProfitLayers, profitImage, profitLegend, summary, varieties, gettingScatter});
  // }, [errorNotice, pdfLoading, mapLoading, mapLoadedOnViewProfitLayers, profitImage, profitLegend, summary, varieties, gettingScatter])

  useEffect(() => {
    if (!mapLoading && !pdfLoading) {
      getMapImage();
    }
  }, [mapLoading]);

  useEffect(() => {
    if (exists(profitMapResponse) && !pdfLoading) {
      const mapVarieties = profitMapResponse.filter(
        (x) => x.variety.toUpperCase() !== 'NOVARIETY'
        && x.variety.toUpperCase() !== 'VARIETY',
      );
      setVarieties(mapVarieties);
      setSummary(profitMapResponse[0]);
      setProfitLegend(profitMapResponse[0].legend);
    } else if (!exists(profitMapResponse) && errorMessage) {
      setErrorNotice(errorMessage);
    }
  }, [profitMapResponse]);

  useEffect(() => {
    if (scatterLoaded) {
      getScatter();
    }
  }, [scatterLoaded]);

  useEffect(() => {
    if (field.name !== undefined && field.name !== '') {
      const date = new Date();
      setReportName(
        `${
          field.name
        }- ${date.getMonth()}/${date.getDay()}/${date.getFullYear()}`,
      );
    }
  }, [field]);

  const resetStates = () => {
    setPdfReady(false);

    setProfitImage('');
    setCostChart('');
    setScatterPlot('');
    setProfitLegend([]);
    setVarieties([]);
    setSummary({});
    setOpen(false);
  };

  const handleChange = (event) => {
    setNotes(event.target.value);
  };

  const getMapImage = async () => {
    setPdfLoading(true);
    try {
      const pImage = await getImage('profit-map-pdf');
      if (pImage !== null && pImage !== '') {
        setProfitImage(pImage);
      }
    } catch (e) {
      console.log(e);
      setErrorNotice('An error occured. Please try again later.');
    } finally {
      setPdfLoading(false);
    }
  };

  const getScatter = async () => {
    setGettingScatter(true);
    try {
      const scatImage = await getImage('scatter-plot');
      if (scatImage !== null && scatImage !== '') {
        setScatterPlot(scatImage);
      }
    } catch (e) {
      console.log(e);
    }

    setGettingScatter(false);
  };

  const getPdfData = async () => {
    setPdfLoading(true);

    try {
      // Get the rest of images
      const costImage = await getImage('cost-chart-image');

      if (costImage !== null && costImage !== '') {
        setCostChart(costImage);
      }
      setPdfReady(true);
    } catch (e) {
      console.log(e);
    } finally {
      setPdfLoading(false);
    }
  };

  const getImage = async (id) => {
    try {
      const node = document.getElementById(id);
      const dataUrl = await domtoimage.toPng(node);
      if (dataUrl !== null) {
        return dataUrl;
      }
      return '';
    } catch (e) {
      console.log('error getting image for ', id);
      return '';
    }
  };

  const handleDowload = () => {
    ReactGA.event({
      category: "Feature Usage",
      action: "PDF Download",
      label: "Downloaded PDF"
    })
    try {
      const element = document.getElementById('download-pdf');
      if (element) {
        setTimeout(() => { element.click(); }, 0);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const pdfButton = () => (
    <>
      <Button
        variant="outlined"
        color="primary"
        size="large"
        id="download-pdf"
        onClick={() => { resetStates(); }}
      >
        <GetAppIcon />
        {' Download PDF'}
      </Button>
      {setTimeout(() => { handleDowload(); }, 0)}
    </>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        className={classes.root}
        style={modalStyle}
        borderRadius="borderRadius"
        boxShadow={2}
        fontWeight={500}
        fontSize={16}
        color={blackText}
      >
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpen(false)}
        />

        <Box p={1}>
          Generate PDF
        </Box>

        <Divider style={{ width: 400, height: 1 }} />

        <Box
          m={1}
          display="flex"
        >
          <Box
            fontSize={16}
            color={darkText}
            style={{ margin: '8px 6px 0 4px' }}
          >
            Report Name:
          </Box>

          <Box>
            <TextField
              variant="outlined"
              value={reportName}
              onChange={(event) => setReportName(event.target.value)}
              style={{ width: 260, height: 28 }}
              InputLabelProps={{
                shrink: false,
              }}
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
            />
          </Box>
        </Box>

        <Box p={1} display="flex" justifyContent="center">
          <TextField
            id="pdf-notes"
            label="Add Notes"
            multiline
            variant="outlined"
            rows={18}
            value={notes}
            onChange={handleChange}
            style={{ width: 340 }}
          />
        </Box>

        <Box
          p={1}
          display="flex"
          justifyContent="center"
        >
          {
            !!errorNotice ? (
              errorNotice
            ) : pdfLoading ? (
              <Box mt={2}>
                <LinearProgress style={{ height: 6, width: 60 }} />
              </Box>
            ) : (mapLoading
              || !mapLoadedOnViewProfitLayers
              || profitImage === ''
              || profitLegend === []
              || !exists(summary)
              || varieties === []
              || gettingScatter
            ) ? (
              <Box mt={2}>
                <LinearProgress style={{ height: 6, width: 60 }} />
              </Box>
            ) : !pdfReady ? (
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => getPdfData()}
              >
                Generate PDF
              </Button>
            ) : (
              <PDFDownloadLink
                onLoadError={console.error}
                document={(
                  <ProfitLayerPdf
                    field={field}
                    year={selectedYear}
                    reportName={reportName}
                    notes={notes}
                    netSeeded={netSeeded}
                    totalRevenue={totalRevenue}
                    totalCosts={totalCosts}
                    profitImage={profitImage}
                    profitLegend={profitLegend}
                    costChart={costChart}
                    scatterPlot={scatterPlot}
                    varieties={varieties}
                    summary={summary}
                    seedingOps={seedings}
                    harvestOps={harvests}
                    chemicalOps={chemicals}
                    fieldPassOps={fieldPasses}
                    otherCostOps={otherCosts}
                    otherRevenueOps={otherRevenue}
                    perAcre={perAcre}
                  />
                )}
                fileName={`${reportName}.pdf`}
                style={{ textDecoration: 'none', color: green }}
              >
                {({
                  blob, url, loading, error,
                }) => (
                  loading
                    ? <Box color={green}>Loading PDF...</Box>
                    : (
                      pdfButton()
                    )
                )}
              </PDFDownloadLink>
            )
          }
        </Box>
      </Box>
    </Modal>
  );
}

import React, { useState, useEffect, Fragment } from "react";
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
  Divider,
  Modal,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TableHead,
  InputAdornment,
  CircularProgress
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import Slider from '@material-ui/core/Slider';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { darkText, blackText, green, grey } from '../../../../styles/colors';
import { MenuProps } from '../../../../styles/select';
import { getAllTemplates, saveNewTemplate } from '../../../../utils/dataFetchers';
import { numFormat } from '../../../../utils/helpers'
import { useWindowDimensions } from "../../../../utils/dimensions";
import { NumberFormatCustom } from '../../../../utils/NumberFormatCustom';
import { useSnackbar } from 'notistack';

function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
  };
}

const RangeSlider = withStyles({
  root: {
    color: '#67b444',
    height: 8,
  },
  thumb: {
    height: 20,
    width: 20,
    marginLeft: -12,
    backgroundColor: '#fff',
    border: '2px solid currentColor',
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 8,
    borderRadius: 4,
  },
  rail: {
    height: 8,
    borderRadius: 4,
    border: '2px solid #999999',
    backgroundColor: 'white',
    color: grey,
  },
  markLabel: {
    fontWeight: 500,
    color: green,
  },
  mark: {
    width: '0px',
  }
})(Slider);

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.modalPaper,
    justifyContent: 'space-between',
  },
  head: {
    height: '50px',
    padding: '20px',
    alignItems: 'center',
    width: '100%',
    color: darkText,
    fontSize: 20,
    fontWeight: 500,
    display: 'flex',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    width: '100%',
    padding: '0 20px',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  buttonBox: {
    ...theme.buttonBox,
    alignItems: 'center',
    height: '60px',
    borderRadius: '0px 0px 4px 4px',
  },
  description: {
    fontSize: 14,
    color: theme.palette.text.grey,
  },
  sliderButton: {
    ...theme.greenHover,
    margin: '0 10px',
    color: theme.palette.primary.main,
  },
  input: {
    width: '190px',
    height: '40px',
  },
  legend: {
    overflowY: 'auto',
    display: 'flex',
    flexDirection: 'column',
    padding: '12px',
  },
  legendRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
  },
  values: {
    ...theme.disabledInput,
    width: '125px',
    margin: '0 5px 0 10px',
  }
}));

export function EditRanges(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { height, width } = useWindowDimensions();
  const [modalStyle] = useState(getModalStyle);
  const [legend, setLegend] = useState([]);
  const ranges = [5, 6, 7, 8, 9, 10, 11, 20, 30];
  const [selectedRange, setSelectedRange] = useState(ranges[0]);
  const [templateName, setTemplateName] = useState('');
  const [savedTemplate, setSavedTemplate] = useState([])
  const [selectedTemplate, setSelectedTemplate] = useState([])
  const [loading, setLoading] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [reload, setReload] = useState(false)

  useEffect(() => {
    if (props.legend.length > 0 && props.legend !== undefined) {
      //console.log(props.legend)
      //props.legend["ranges"] = props.legend.length;
      setLegend(props.legend);
      setSelectedRange(props.legend.length);
    }
  }, [props.legend]);

  useEffect(() => {
    if (props.savedTemplate !== undefined) {
      setSavedTemplate(props.savedTemplate)
    }
  }, [props.savedTemplate])

  useEffect(() => {
    console.log(props.selectedTemplate)
    setSelectedTemplate(props.selectedTemplate)
  }, [props.selectedTemplate])

  useEffect(() => {
    console.log('selectedTemplate', selectedTemplate)
  }, [selectedTemplate])

  const handleRangeChange = (prop, ndx) => (e) => {
    setShowMessage(true)
    let legendCopy = JSON.parse(JSON.stringify(legend));
    //let ndx = parseInt(e.target.id.replace(prop, ""));
    legendCopy[ndx][prop] = e.target.value;
    if (prop === "min" && ndx !== legendCopy.length - 1) {
      //if min change previous max
      legendCopy[ndx + 1]["max"] = e.target.value;
    } else if (prop === "max" && ndx !== 0) {
      legendCopy[ndx - 1]["min"] = e.target.value;
    }

    setLegend(legendCopy);
    props.setSelectedTemplate('')
  };

  const handleColorChange = (prop) => (e) => {
    let legendCopy = JSON.parse(JSON.stringify(legend));
    let ndx = parseInt(e.target.id.replace(prop, ""));
    legendCopy[ndx][prop] = e.target.value;
    setLegend(legendCopy);
  };

  const handleRangeNumberChange = (e, newValue) => {
    setSelectedRange(newValue)
  };

  const handleSliderRelease = () => {
    setLoading(true)

    props.binRange(selectedRange)
    getTemplates(selectedRange)
    props.setSelectedTemplate('')

    saveChanges(false, true)
  }

  // From select box on mobile
  const handleRangeSelect = (val) => {
    //console.log('handle select change')
    //console.log(val);
    setSelectedRange(val)
    setLoading(true)

    props.binRange(val)
    getTemplates(val)
    props.setSelectedTemplate('')

    saveChanges(false, true)
  };

  const getTemplates = async (range, updateList = false) => {
    let res = await getAllTemplates(range)
    //console.log(res)
    if (res.length > 0) {
      res.sort((a, b) => a.id - b.id)
      //console.log('res', res)
      setSavedTemplate(res)
      props.setTemplateRes(res)
      //console.log(res, res[res.length - 1], updateList)
      // if (updateList) {
      //   props.setSelectedTemplate(res[res.length - 1])
      //   setSelectedTemplate(res[res.length - 1])
      // }
    }
    else {
      setSavedTemplate([])
      props.setTemplateRes([])
      props.setSelectedTemplate('')
      setSelectedTemplate('')
    }
  }

  ///Check to make sure that the legend items are in the correct order
  ///doing this before getting to ProfitLayers component to allow user to fix mistakes
  const validateLegend = (inputLegend) =>{
    let validLegend = true
    for(let i=0; i<inputLegend.length; i++){
      if(parseFloat(inputLegend[i].min) > parseFloat(inputLegend[i].max)){
        console.log("MIN > MAX IN LEGEND")
        validLegend = false
      }
    }

    return validLegend
  }

  ///Check to make sure taht the amount of items matches and that the bins being passed to profitmap
  ///service are in the correct order. doing this before getting to ProfitLayers component
  ///to allow user to fix mistakes
  const validateBins = (bins) => {
    console.log(bins)
    let validBins = true
    let binCheck = bins.binRange.split(", ").map(x=> parseFloat(x))
    if(!bins.countChange){
      let sortedBins = binCheck.sort((a,b) => a-b) //sort in acsending order
      let correctBins = sortedBins.join(", ")
      if(bins.binRange != correctBins){
        console.log(bins.binRange, correctBins)
        validBins=false
      }
    }
    return validBins;
  }

  const saveChanges = async (closeOnSave = true, rangeUpdate = false, templateUsed = null, isReset = false) => {
    //call back to profit map to call LogBinRange and GetProfitMap
    let binRanges = {};
    let binvals = [];
    let colvals = [];

    if (templateUsed !== null) {
      binRanges["binRange"] = templateUsed.rangesList
      binRanges["colors"] = templateUsed.colorsList
      binRanges["range"] = selectedRange;
    } else {
      if (isReset) {
        binRanges = null;
      } else {
        if (props.selectedTemplate === '') {
          for (let i = legend.length - 1; i >= 0; i--) {
            colvals.push(legend[i].color);
            binvals.push(legend[i].min);
            if (i === 0) {
              binvals.push(legend[i].max);
            }
          }

          binRanges["binRange"] = binvals.join(", ");
          binRanges["colors"] = JSON.stringify(colvals);
          binRanges["range"] = selectedRange;
          if (legend.length !== selectedRange) {
            //set colors to preset list
            console.log("\n Range count changed! \n")
            binRanges["colors"] = getColorsList(selectedRange)
            binRanges["countChange"] = true;
          }
        }
        else {
          binRanges["binRange"] = props.selectedTemplate.rangesList
          binRanges["colors"] = props.selectedTemplate.colorsList
          binRanges["range"] = selectedRange;
        }
      }

    }

    //validate inputs before sending to profitmap service
    let validLegend = validateLegend(legend)
    //let validBins = validateBins(binRanges)
    if(!validLegend){
      enqueueSnackbar("Invalid legend values", {autoHideDuration: 5000})
      return;
    }
    //console.log('apply update')
    props.applyUpdate(binRanges, legend, !rangeUpdate, isReset);

    if (closeOnSave) {
      props.closeModal()
    }

    setLoading(false)
  };

  function getColorsList(range) {
    /* Get colors for Profit Map legend*/
    switch (range) {
      case (5):
        return '[#ec1f27, #f58a33, #fff200, #7cc453, #3d6924]';
      case (6):
        return '[#770a10, #ec1f27, #f58a33, #fff200, #7cc453, #3d6924]';
      case (7):
        return '[#770a10, #ec1f27, #f58a33, #fff200, #7cc453, #61f90a, #3d6924]';
      case (8):
        return '[#770a10, #ec1f27, #f3750e, #ff9d00, #fff200, #7cc453, #61f90a, #3d6924]';
      case (9):
        return '[#770a10, #ec1f27, #f3750e, #ff9d00, #ffc800, #faff62, #7cc453, #61f90a, #3d6924]';
      case (10):
        return '[#770a10, #ec1f27, #f3750e, #ff9d00, #ffc800, #faff62, #bde7a6, #7cc453, #61f90a, #3d6924]';
      case (11):
        return '[#770a10, #ec1f27, #f3750e, #ff9d00, #ffc800, #faff62, #bde7a6, #7cc453, #579553, #61f90a, #3d6924]';
      case (20):
        return "[#53070b, #770a10, #ad0f17, #ec1f27, #ab5108, #d0630a, #f3750e, #f58a33, #ffb200, #ffc800, #fff200, #f7ff00, #71bf44, #7cc453, #87c961, #6ec93a, #69dc27, #64ef14, #61f90a, #3d6924]";
      case (30):
        return "[#410509, #53070b, #770a10, #ad0f17, #ec1f27, #f2676d, #ab4308, #ab5108, #d0630a, #f3750e, #f58a33, #f5aa33, #ff9d00, #ffb200, #ffc800, #fff200, #f7ff00, #faff62, #579553, #71bf44, #7cc453, #87c961, #6ec93a, #bde7a6, #98e76b, #69dc27, #64ef14, #61f90a, #3d6924, #348a03]";
    }
  }

  const saveTemplate = async () => {
    // save template here then clear name

    let binRanges = {};
    let binvals = [];
    let colvals = [];

    for (let i = legend.length - 1; i >= 0; i--) {
      colvals.push(legend[i].color);
      binvals.push(legend[i].min);
      if (i === 0) {
        binvals.push(legend[i].max);
      }
    }

    binRanges["binRange"] = binvals.join(", ");
    binRanges["colors"] = JSON.stringify(colvals);
    binRanges["range"] = selectedRange;

    if(!validateBins(binRanges)){
      enqueueSnackbar("Cannot save invalid template")
      return;
    }

    let request = {
      range: binRanges.range,
      colorsList: binRanges.colors,
      rangesList: binRanges.binRange,
      templateName: templateName
    }

    let res = await saveNewTemplate(request)
    //console.log("res", res)
    if (res.length === 0) {
      getTemplates(selectedRange, true)
    }
    setTemplateName('')
  }

  const handleTemplateChange = (id) => {
    for (var i = 0; i < savedTemplate.length; i++) {
      if (id == savedTemplate[i].id) {
        props.setSelectedTemplate(savedTemplate[i])
        saveChanges(true, false, savedTemplate[i])
      }
    }
  }

  const marks = [
    {
      value: 5,
      label: '5',
    },
    {
      value: 6,
      label: '6',
    },
    {
      value: 7,
      label: '7',
    },
    {
      value: 8,
      label: '8',
    },
    {
      value: 9,
      label: '9',
    },
    {
      value: 10,
      label: '10',
    },
    {
      value: 11,
      label: '11',
    },
    {
      value: 20,
      label: '20',
    },
    {
      value: 30,
      label: '30',
    },
  ];

  const handleReset = () => {
    props.setSelectedTemplate('')
    saveChanges(true, false, null, true)
  }

  const profitRange = () => (
    <Box>
      <Box
        p={2}
        display='flex'
        justifyContent='space-between'
        flexWrap='wrap'
      >
        <Box>

          <Box
            display='flex'
            justifyContent='space-between'
            alignItems='center'
          >
            <Box>
              {'Number of ranges'}
              {
                props.mapLoading &&
                <CircularProgress size={14} style={{ marginLeft: 8 }} />
              }

            </Box>
            <Box display='flex'>
              <Box
                className={classes.sliderButton}
                onClick={() => handleReset()}
              >
                Reset
                </Box>
              {
                templateName !== '' ?
                  <Box ml={1}>
                    <TextField
                      variant="outlined"
                      className={classes.input}
                      label='Name'
                      InputLabelProps={{
                        shrink: true,
                      }}
                      inputProps={{
                        style: {
                          padding: 10,
                        }
                      }}
                      value={templateName}
                      onChange={(e) => setTemplateName(e.target.value)}
                    />
                    <SaveIcon
                      className={classes.sliderButton}
                      onClick={() => saveTemplate()}
                    />
                  </Box>
                  :
                  <Box
                    className={classes.sliderButton}
                    onClick={() => setTemplateName('Template')}
                  >
                    Save template
                  </Box>
              }
            </Box>
          </Box>

          {
            width > 550 ?
              <Box style={{ width: '450px' }}>
                <RangeSlider
                  valueLabelDisplay="off"
                  min={5}
                  max={30}
                  defaultValue={selectedRange}
                  value={selectedRange}
                  track={false}
                  step={null}
                  marks={marks}
                  onChange={handleRangeNumberChange}
                  onChangeCommitted={handleSliderRelease}
                  perAcre={props.perAcre}
                />
              </Box>
              :
              <Select
                variant='outlined'
                value={selectedRange}
                onChange={(e) => handleRangeSelect(e.target.value)}
                style={{ width: 80, height: 40 }}
              >
                {
                  marks.map((x, i) => (
                    <MenuItem key={i} value={x.value}>
                      {x.label}
                    </MenuItem>
                  ))
                }
              </Select>
          }


        </Box>

        <Box
          display='flex'
        >
          <Box>
            <Box ml={1}>
              Select template
              </Box>

            <Select
              className={classes.input}
              variant="outlined"
              MenuProps={MenuProps}
              label='Select existing template'
              onChange={(e) => handleTemplateChange(e.target.value)}
              value={props.selectedTemplate.id}
            >
              {props.templateRes.map((x, i) => (
                <MenuItem value={x.id} key={x.id}>{x.templateName}</MenuItem>
              ))}
            </Select>
          </Box>

        </Box>

      </Box>

      <Divider />

      {
        loading ?
          <Box display='flex' justifyContent='center' alignItems='center'>
            <CircularProgress />
          </Box>
          :
          profitLegend()
      }
    </Box>
  )

  const profitLegend = () => (
    <Box className={classes.legend}>

      <Box display='flex' style={{ width: '100%' }}>
        <Box style={{ width: width > 500 ? '70%' : '100%' }}>
          Profit Range ($/acre)
          </Box>
        {
          width > 500 &&
          <React.Fragment>
            <Box style={{ width: '15%' }}>
              Acres
              </Box>
            <Box style={{ width: '15%' }}>
              % of total
              </Box>
          </React.Fragment>
        }
      </Box>

      <Box>
        {
          legend.map((row, i) => (
            <Box key={i} className={classes.legendRow}>
              <Box
                display='flex'
                alignItems='center'
                style={{ width: width > 500 ? '70%' : '100%' }}
              >

                <Box
                  display='flex'
                  justifyContent='center'
                  alignItems='center'
                  borderRadius='50%'
                  style={{ maxWidth: '20px', maxHeight: '20px', overflow: 'hidden' }}
                >
                  <input
                    id={"color" + i}
                    type="color"
                    data-color={row.color}
                    value={row.color}
                    onChange={handleColorChange("color")}
                    style={{
                      minWidth: '60px',
                      minHeight: '60px',
                      padding: 0,
                      border: 0,
                    }}
                  />
                </Box>

                <TextField
                  style={{ width: '125px', margin: '0 5px 0 10px' }}
                  id={"min" + i}
                  variant="outlined"
                  value={row.min}
                  onChange={handleRangeChange("min", i)}
                  InputProps={{
                    startAdornment: <InputAdornment>$</InputAdornment>,
                    inputComponent: NumberFormatCustom,
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />

                <Box mx={2}>
                  to
                  </Box>

                <TextField
                  style={{ width: '125px', margin: '0 5px' }}
                  id={"max" + i}
                  variant="outlined"
                  value={row.max}
                  onChange={handleRangeChange("max", i)}
                  InputProps={{
                    startAdornment: <InputAdornment>$</InputAdornment>,
                    inputComponent: NumberFormatCustom,
                  }}
                  inputProps={{
                    style: {
                      padding: 10,
                    },
                  }}
                />
              </Box>
              {
                width > 500 &&
                <React.Fragment>
                  <Box style={{ width: '15%' }}>
                    {
                      row.areaPercent === 0 ? 0 : (props.netSeededAcres * parseFloat(row.area.split(' ')[0]) / 100).toFixed(2)
                    }
                  </Box>

                  <Box style={{ width: '15%' }}>
                    {row.area.split(' ')[0]}
                      %
                    </Box>
                </React.Fragment>
              }
            </Box>
          ))
        }
      </Box>
    </Box>
  )

  const mapLegend = (type) => (
    <Box className={classes.legend} style={{ height: '100%' }}>
      <Box display='flex'>
        <Box style={{ width: '425px', paddingLeft: '40px' }}>
          {
            type === 'elevation' ? 'Feet' :
              type === 'operation' ? `${props.unit}/acre` :
                'Profit Range ($/acre)'
          }
        </Box>
        <Box style={{ width: '200px', paddingLeft: '20px' }}>
          Acres
          </Box>
        <Box style={{ paddingLeft: '8px' }}>
          % of total
          </Box>
      </Box>

      {
        legend.map((row, i) => (
          <Box key={i} className={classes.legendRow}>
            <Box display='flex' alignItems='center' style={{ width: '425px' }}>
              <Box
                display='flex'
                justifyContent='center'
                alignItems='center'
                borderRadius='50%'
                style={{
                  width: '20px',
                  height: '20px',
                  overflow: 'hidden',
                  backgroundColor: row.color
                }}
              >
              </Box>

              <Box className={classes.values}>
                {type === 'variety' && '$'}
                {row.min.toFixed(2)}
                {
                  type === 'elevation' ? 'ft' :
                    type === 'operation' ? `${props.unit}` :
                      ''
                }
              </Box>

              <Box mx={2}>
                to
                </Box>

              <Box className={classes.values}>
                {type === 'variety' && '$'}
                {row.max.toFixed(2)}
                {
                  type === 'elevation' ? 'ft' :
                    type === 'operation' ? `${props.unit}` :
                      ''
                }
              </Box>

            </Box>

            <Box style={{ width: '200px', paddingLeft: '20px' }}>
              {
                (props.netSeededAcres / parseFloat(
                  row.area.split(' ')[0])).toFixed(2)
              }
            </Box>

            <Box ml={1}>
              {row.area.split(' ')[0]}
              {'%'}
            </Box>

          </Box>
        ))
      }
    </Box>
  )


  return (
    <Box>
      <Box className={classes.head}>
        <Box>
          {props.map === 'Profit Map' ? 'Edit Profit ' : ''}
            Ranges
          </Box>
      </Box>

      <Divider />

      {showMessage && (
        <Box
          className={classes.head}
          style={{
            fontSize: '1em',
            color: 'black'
          }}
        >
          <Box>
            Note: The minimum and maximum profit range values are taken from the profit map calculations, and will default to the minimum and maximum values calculated.
            </Box>
          <Divider />
        </Box>
      )}


      <Box className={classes.body}>
        {
          props.map === 'Profit Map' ? profitRange() :
            props.map === 'Variety Map' ? mapLegend('variety') :
              props.map === 'Elevation Map' ? mapLegend('elevation') :
                props.map === 'Operation Layers' ? mapLegend('operation') :
                  ''
        }
      </Box>

      <Box className={classes.buttonBox}>
        {
          props.map === 'Profit Map' ?
            <Fragment>
              <Box m={1} style={{ backgroundColor: '#ffffff' }}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => props.closeModal()}
                >
                  Cancel
                  </Button>
              </Box>
              <Box m={1}>
                <Button
                  variant="contained"
                  color="primary"
                  disableElevation
                  onClick={() => saveChanges()}
                  style={{ marginRight: 16 }}
                >
                  Save
                  </Button>
              </Box>
            </Fragment>
            :
            <Box m={1}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => props.closeModal()}
              >
                Close
                </Button>
            </Box>
        }

      </Box>

    </Box>
  );
}

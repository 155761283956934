/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */
import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import * as L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import {
  drawFieldLayer,
  getMinHeight,
} from '../../Maps/MapFunctions/helpers';
import { exists, sleep } from '../../../utils/helpers';
import { Tiles } from '../../../constants/Tiles';
import { SpinningLoader } from '../../Shared/SpinningLoader';

export function SARMap({
  boundary,
  location,
  mapImage,
  mapExtents,
  showSar,
  setErrorMessage,
  retryAttempts,
}) {
  const [map, setMap] = useState(null);
  const [loaded, setLoaded] = useState(false);
  const [loading, setLoading] = useState(true);
  const imageLoaded = useRef(false);
  const imageOverlay = useRef(null);
  const fieldLayer = L.featureGroup(null);

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (map && location === '') {
      map.setMaxZoom(17);
    }
  }, [location, map]);

  useEffect(() => {
    console.log(boundary);
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      drawFieldLayer(geoJson, fieldLayer, map);
    }
  }, [boundary, map]);

  useEffect(() => {
    if (exists([map, boundary, mapImage, mapExtents])) {
      if (showSar) {
        setLoading(true);
        drawImage(imageOverlay, mapImage, boundary, mapExtents, map);
        if (!loaded) {
          // Only fit bounds on first image load
          setLoaded(true);
          map.fitBounds(mapExtents);
        }
      } else {
        // remove image layer
        map.removeLayer(imageOverlay.current);
      }
    }
  }, [mapImage, boundary, map, mapExtents, showSar]);

  function drawImage(imageOverlay, mapImage, boundary, mapExtents, map) {
    const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
    const bounds = Object.keys(mapExtents).length === 0
      ? L.geoJSON(features).getBounds()
      : mapExtents;

    if (imageOverlay.current === null) {
      imageOverlay.current = L.imageOverlay(mapImage, bounds, {
        opacity: 0.8,
        interactive: true,
      });

      imageOverlay.current.addTo(map);
      imageOverlay.current.bringToFront();
      if (setErrorMessage) {
        errorOnNoLoad();
      }

      imageOverlay.current.on('load', (ev) => {
        setLoading(false);
        imageLoaded.current = true;
        if (setErrorMessage) {
          setErrorMessage('');
        }
      });
    } else {
      imageOverlay.current.setUrl(mapImage);
      imageOverlay.current.setBounds(bounds);
      imageOverlay.current.addTo(map);
      errorOnNoLoad();
      imageOverlay.current.on('load', (ev) => {
        setLoading(false);
        imageLoaded.current = true;
        if (setErrorMessage) {
          setErrorMessage('');
        }
      });
    }
  }

  // If image has not loaded after 20 seconds assume failed
  const errorOnNoLoad = async () => {
    await sleep(20000);
    if (!imageLoaded.current) {
      if (retryAttempts < 1) {
        setErrorMessage('Trying again');
      } else {
        setErrorMessage('We were unable to get Satellite Radar Imagery for your field. Please check back later or try another field');
      }
      setLoading(false);
    }
  };

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('sar-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current'],
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }

  return (
    <Box display="flex" flexGrow={1}>
      <Box
        id="sar-map"
        display="flex"
        flexGrow={1}
        borderRadius={4}
        minHeight={getMinHeight()}
      />
      { (loading && setErrorMessage) && <SpinningLoader />}
    </Box>
  );
}

// #region - imports
// React
import React, {
  useEffect, useState, useContext, useRef, Fragment
} from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  AppBar,
  Box,
  Button,
  Divider,
  Select,
  MenuItem,
  Slide,
} from '@material-ui/core';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { green, blackText, grey } from '../../styles/colors';
import { ComingSoon } from '../Home/ComingSoon';
import { convertExtentToBounds } from '../Maps/MapFunctions/helpers';

// Helpful packages
import { useSnackbar } from 'notistack';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import * as wkt from 'terraformer-wkt-parser';
import * as _ from 'underscore';
import { cloneDeep } from 'lodash';

// Contexts and Shared
import { FieldContext } from '../Context/FieldContext';
import { UserContext } from '../Context/UserContext';
import { Connect } from '../Shared/Connect';
import { ConnectAndIntegrate } from '../Shared/ConnectAndIntegrate';
import { IntegrateModal } from '../Shared/IntegrateModal';
import { PopupManager } from '../Shared/Popups/PopupManager';

// Components
import { CropLand } from './CropLand/CropLand';
import { NDVI } from './CropHealth/NDVI';
import { Polaris } from './Polaris/Polaris';
import { SoilData } from './SSURGO/SoilData';
import { LandValue } from './LandValue/LandValue';
import { SAR } from './SAR/SAR';
import { RealTimeWeather } from './Weather/RealTimeWeather';
import { GrowingDays } from './GrowingDays/GrowingDays';
import { Precipitation } from './Precipitation/Precipitation';
import { Topography } from './Topography/Topography';
import { AerialImagery } from './AerialImagery/AerialImagery';
import { InsuranceTools } from '../InsuranceTools/InsuranceTools';
import { GuidanceLines } from './GuidanceLines/GuidanceLines';
import { Drainage } from './TileDrainage/Drainage';
import { CreatePdf } from './PDF/CreatePdf';
import { Steps } from './Navigation/steps';
import { Footer } from './Navigation/footer';
import { FieldSelection } from '../FieldSelection/FieldSelection';
import { FieldClaim } from '../FieldSelection/FieldClaim';

// Requests and Other Helpers
import {
  getStateAndCounty,
  processPolaris,
  processCDL,
  ssurgoData,
  HLSforNDVI,
  getGrowingDegreeDays,
  getPrecipitationData,
  getSevereWeatherAlerts,
  elevationIndex,
  getElevationMap,
  getTileDrainage,
} from '../../utils/dataFetchers';
import { useWindowDimensions } from '../../utils/dimensions';
import {
  exists,
  createYearArray,
  sleep,
} from '../../utils/helpers';
import { Endpoints } from '../../constants/Endpoints';
import {
  getAvailableNaipYears,
  requestNaipImagery,
} from '../Maps/MapFunctions/naip_functions';
// #endregion

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  selections: {
    backgroundColor: theme.palette.greys.light,
    height: '100%',
    padding: '15px',
    overflowY: 'auto',
    width: 220,
    minWidth: 220,
  },
  select: {
    margin: '1px 0',
    padding: '8px 10px',
    borderRadius: '5px',
    color: blackText,
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#f8f8ff',
      color: '#1B1B1B',
    },
  },
  selected: {
    margin: '1px 0',
    padding: '8px 10px',
    borderRadius: '5px',
    color: green,
    fontWeight: 600,
    backgroundColor: '#ffffff',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  layers: {
    display: 'flex',
    width: '100%',
  },
  label: theme.label,
  connectButton: {
    width: 180,
    margin: '6px 0',
    backgroundColor: '#f8f8ff',
    '&:hover': {
      backgroundColor: '#ffffff',
    },
  },
  dropDown: {
    height: 40,
    minWidth: 50,
  },
}));

const zonesColors = [
  '#c34016',
  '#f3750e',
  '#f4ae62',
  '#fff200',
  '#ccee5f',
  '#69dc27',
  '#97e8d1',
  '#13a28c',
  '#3053c9',
  '#5712da',
  '#e74daa',
  '#742a4d',
];

/**
 * Controls renders, state management, and API calls for DataLayers components.
 * @param {Function} setSection Sets navbar section, to ensure DataLayers is highlighted when visited
 * @param {Function} setPlStep For navigation to Setup ProfitLayers
 * @param {Number} dataLayersStep Used when user navigates to DataLayers from somewhere else
 * @returns {JSX} Data Layers
 */
export function DataLayers({ setSection, setPlStep, dataLayersStep }) {
  // #region - variables
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const user = useContext(UserContext)[0];
  const fieldData = useContext(FieldContext)[0];
  const [authenticated, setAuthenticated] = useState(false);
  const [precisionField, setPrecisionField] = useState(false);

  // Width in pixels side nav changes to mobile view
  const mobileBreakPoint = 860;

  // Open Modals
  const [openIntegration, setOpenIntegration] = useState(false);
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);

  const years = createYearArray(20);
  const gddYears = createYearArray(40);
  const months = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];
  const precisionSources = ['deere', 'climate', 'cnh'];

  const [statusTracker, setStatusTracker] = useState({
    Polaris: false,
    CDL: false,
    SSURGO: false,
    NDVI: false,
    SAR: false,
    GDD: false,
    Precip: false,
    CurrentWeather: false,
    SevereWeatherAlerts: false,
    elevationIndexStatus: false,
    Naip: false,
    RMA: false,
  });

  // Loading statuses
  const [polarisLoading, setPolarisLoading] = useState(true);
  const [cdlLoading, setCdlLoading] = useState(true);
  const [tillableLoading, setTillableLoading] = useState(true);
  const [ssurgoLoading, setSsurgoLoading] = useState(true);
  const [ndviLoading, setNdviLoading] = useState(true);
  const [sarLoading, setSarLoading] = useState(true);
  const [gddLoading, setGddLoading] = useState(true);
  const [precipLoading, setPrecipLoading] = useState(true);
  const [weatherLoading, setWeatherLoading] = useState(true);
  const [weatherAlertsLoading, setWeatherAlertsLoading] = useState(true);
  const [elevationLoading, setElevationLoading] = useState(true);
  const [naipLoading, setNaipLoading] = useState(true);
  const [insuranceToolLoading, setInsuranceToolLoading] = useState(true);
  const [guidanceLoading, setGuidanceLoading] = useState(true);
  const [landValueLoading, setLandValueLoading] = useState(true);
  const [drainageLoading, setDrainageLoading] = useState(true);

  // Error messages
  const [polarisError, setPolarisError] = useState('');
  const [ssurgoError, setSSurgoError] = useState('');
  const [cdlError, setCdlError] = useState('');
  const [tillableError, setTillableError] = useState('');
  const [ndviError, setNdviError] = useState(''); // Message text is not dynamic in component
  const [sarError, setSarError] = useState('');
  const [gddError, setGddError] = useState('');
  const [precipError, setPrecipError] = useState('');
  const [weatherError, setWeatherError] = useState('');
  const [weatherAlertsError, setWeatherAlertsError] = useState('');
  const [elevationError, setElevationError] = useState('');
  const [naipError, setNaipError] = useState('');
  // Insurance tools can't use this as they are also used in ProfitLayers.js
  const [insuranceToolError, setInsuranceToolError] = useState('');
  const [guidanceError, setGuidanceError] = useState('');
  const [landValueError, setLandValueError] = useState('');
  const [drainageError, setDrainageError] = useState('');
  const showDebugOutput = false;

  // Navigation
  const [step, setStep] = useState(dataLayersStep); // field select or DL
  const [selectedLayer, setSelectedLayer] = useState(0); // tabs in DL
  const [hideFieldSelection, setHideFieldSelection] = useState(false);

  // Weather
  const [hourly, setHourly] = useState([]);
  const [weekly, setWeekly] = useState([]);

  // Polaris Info
  const [polarisImage, setPolarisImage] = useState('');
  const [polarisLegend, setPolarisLegend] = useState([]);
  const [polarisExtent, setPolarisExtent] = useState({});
  const [polarisAverage, setPolarisAverage] = useState(0);

  // CDL
  const [cdlImage, setCdlImage] = useState('');
  const [cdlLegend, setCdlLegend] = useState([]);
  const [cdlExtent, setCdlExtent] = useState({});
  const cdlYears = createYearArray(20, false, 1);
  const [selectedCdlYear, setSelectedCdlYear] = useState(cdlYears[1]);

  // tillable
  const [tillableImage, setTillableImage] = useState('');
  const [tillableLegend, setTillableLegend] = useState([]);
  const [tillableExtent, setTillableExtent] = useState({});
  const tillableYears = createYearArray(2, false, 1);
  const [selectedTillableYear, setSelectedTillableYear] = useState(
    tillableYears[0],
  );

  // SSURGO
  const [soilGeo, setSoilGeo] = useState({});
  const [soilAverage, setSoilAverage] = useState(null);
  const [soilLegend, setSoilLegend] = useState([]);

  // NDVI
  const [ndviImage, setNdviImage] = useState('');
  const [ndviExtent, setNdviExtent] = useState({});
  const [ndviLegend, setNdviLegend] = useState([]);
  const [ndviAttributes, setNdviAttributes] = useState(null);

  // GDD
  const gdds = [32, 50, 60];
  const [gddStartYear, setGddStartYear] = useState(
    gddYears[gddYears.length - 1],
  );
  const [gddEndYear, setGddEndYear] = useState(gddYears[0]);
  const [gddMonth, setGddMonth] = useState(months[0]);
  const [gdd, setGdd] = useState(gdds[1]);
  const [growingData, setGrowingData] = useState(null);

  // Precipitation
  const [precipStartYear, setPrecipStartYear] = useState(
    gddYears[gddYears.length - 1],
  );
  const [precipEndYear, setPrecipEndYear] = useState(gddYears[0]);
  const [precipMonth, setPrecipMonth] = useState(months[0]);
  const [precipData, setPrecipData] = useState(null);

  // Drainage
  const [drainageImage, setDrainageImage] = useState('');
  const [drainageExtent, setDrainageExtent] = useState({});
  const [drainageLegend, setDrainageLegend] = useState([]);

  // SAR
  const [sarImage, setSarImage] = useState('');
  const [sarExtent, setSarExtent] = useState({});

  // Topography and slope
  const [elevationImage, setElevationImage] = useState('');
  const [elevationExtent, setElevationExtent] = useState({});
  const [elevationLegend, setElevationLegend] = useState([]);
  const elevationIndexSelection = [
    { name: 'Elevation Map', value: 'Elevationmap', unit: 'ft' },
    {
      name: 'Relative Elevation',
      value: 'Relative_Elevation',
      unit: 'z-score',
    },
    { name: 'Slope', value: 'Slope', unit: 'ft' },
    {
      name: 'Topographic Position Index (TPI)',
      value: 'TPI',
      unit: 'Index Value',
    },
    {
      name: 'Terrain Ruggedness Index (TRI)',
      value: 'TRI',
      unit: 'Index Value',
    },
  ];

  const [selectedElevation, setSelectedElevation] = useState(
    elevationIndexSelection[0],
  );

  // Land Value
  const [summary, setSummary] = useState({
    Parcel_Area: 0,
    Total_Price: 0,
    Sale_Date: '',
    Unit: '',
    Price_Acre: 0,
  });
  const [saleDetails, setSaleDetails] = useState({
    Percentile_10: 0,
    Percentile_90: 0,
    avg_price: 0,
    max_price: 0,
    min_price: 0,
    num_list: 0,
  });
  const [soldDetails, setSoldDetails] = useState({
    Percentile_10: 0,
    Percentile_90: 0,
    avg_price: 0,
    max_price: 0,
    min_price: 0,
    num_list: 0,
  });

  // Severe Weather Alerts
  const [severeWeatherAlerts, setSevereWeatherAlerts] = useState(null);

  // NAIP  (Aerial Imagery)
  const [naipYears, setNaipYears] = useState([]);
  const [selectedNaipYear, setSelectedNaipYear] = useState('');
  const [naipData, setNaipData] = useState(null);

  // Field info
  const [field, setField] = useState({ id: '', latitude: '', longitude: '' });

  // Data Layers
  // NOTE: The next seven variables are the only things that need to be changed for reordering/adding a new Data Layer(s). They ALL NEED to match.
  //       First four are for actual order and next three for index mappings
  // NOTE: This will also update mobileOperationSelection in steps.js but make sure to test it

  // Used on wide screens to show clickable layers on the left
  const layers = [
    'SSURGO Soil',
    'Crop History',
    'Topography and Slope',
    'Polaris Soil',
    'Satellite Crop Health',
    'ProfitLayers',
    'Aerial Imagery',
    'Real-Time Weather',
    'Seasonal Growing Degree Days',
    'Seasonal Precipitation',
    'Land Value (BETA)',
    'Satellite Radar',
    'Insurance Tools',
  ];
  const insuranceTools = [
    'What-If Analysis',
    'Premium Calculator',
    'Insurance AI',
  ];
  const layers2 = [
    'Guidance Lines (BETA)',
    'Tillable Acres',
    'Tile Drainage (BETA)',
  ];

  // Used in mobile view as a replacement for left section on PC
  const mobileLayers = [
    'SSURGO Soil',
    'Crop History',
    'Topography',
    'Polaris Soil',
    'Crop Health',
    'ProfitLayers', // Will not be shown on mobile but here for consistency of indices 
    'Aerial Imagery',
    'Real-Time Weather',
    'Growing Days',
    'Seasonal Precipitation',
    'Land Value (BETA)',
    'Satellite Radar',
    'What-If',
    'Premium Calculator',
    'Insurance AI',
    'Guidance Lines (BETA)',
    'Tillable Acres',
    'Tile Drainage (BETA)',
    'Setup ProfitLayers',
  ];

  // Singular source of truth for layers' current indices
  // Also used for mapping when coming from another link with AOI and layer tag
  const layerMappings = {
    ssurgo: 0,          // 'SSURGO Soil',
    cdl: 1,             // 'Crop History',
    elevation: 2,       // 'Topography and Slope',
    polaris: 3,         // 'Polaris Soil',
    hls: 4,             // 'Satellite Crop Health',
    pl: 5,              // 'ProfitLayers' - Temporary PL link for non-logged in users
    naip: 6,            // 'Aerial Imagery',
    weather: 7,         // 'Real-Time Weather',
    gdd: 8,             // 'Seasonal Growing Degree Days',
    precipitation: 9,   // 'Seasonal Precipitation',
    landvalue: 10,       // 'Land Value (BETA)',
    sar: 11,            // 'Satellite Radar',
    // 'Insurance Tools',
    whatif: 12,         // 'What-If Analysis',
    premium: 13,        // 'Premium Calculator',
    insurance: 14,      // 'Insurance AI',
    guidancelines: 15,  // 'Guidance Lines (BETA)',
    tillableacres: 16,  // 'Tillable Acres',
    tiledrainage: 17,   // 'Tile Drainage (BETA)',
  };
  // whatIf layer Index - start of Insurance Tools
  const whatIfLyrIdx = layerMappings['whatif'];
  // A single source for pdfLayer - should always be the last index (eg. tiledrainage + 1)
  const pdfLayer = 18;

  const initLoaded = useRef(false);
  // #endregion

  // #region - useEffects
  // ------ UseEffects to update upon changes ------
  // ensure navbar has correct section
  useEffect(() => {
    setSection(1);
  }, []);

  // If user navigated with an AOI in the request, directly switch to Data Layers view
  useEffect(() => {
    const url = document.URL.split('?');
    if (url.length > 1) {
      // split url should never be more than 1 unless we put 2 '?' in url
      // if that happens take the first paramatr or loop?
      const extension = url[1];
      const urlParams = new URLSearchParams(extension);

      if (urlParams.has('AOI')) {
        let target;
        // If layer key is included and exists, direct user to appropriate layer
        if (urlParams.has('layer')) {
          // Get layer information
          const layer = urlParams.get('layer');
          target = getLayerIndex(layer);
        }

        // Do in this order so transition is smoother for user
        if (target) setSelectedLayer(target);
        setStep(1);
      }
    }
  }, []);

  useEffect(() => {
    setAuthenticated(user.isAuthenticated);
    if (!user.isAuthenticated) {
      // These won't be called, so set loading false
      setNaipLoading(false);
      setElevationLoading(false);
      setLandValueLoading(false);
      setInsuranceToolLoading(false);
      setGuidanceLoading(false);
    }

    if (user.new) {
      // setNewUser(true)
      if (field.id !== '') {
        // start saving fields
      } else {
        enqueueSnackbar('Please draw or select a field on map to get started');
      }
    }
  }, [user]);

  useEffect(() => {
    if (fieldData.selectedField && fieldData.selectedField.id !== '') {
      resetInit();
      handleSelectField(fieldData.selectedField);
    } else if (
      fieldData.fieldToClaim !== undefined
      && fieldData.fieldToClaim.feature !== null
    ) {
      resetInit();
      handleSelectField(fieldData.fieldToClaim);
    }
  }, [fieldData]);

  useEffect(() => {
    // console.log('field data has changed')
    // console.trace()
    // check field data has loaded
    if (step === 1 && field.latitude !== '') {
      checkFieldInUS(field);
    }
  }, [step, field]);

  useEffect(() => {
    // when gdd inputs change
    getGrowingDays();
  }, [field, gddStartYear, gddEndYear, gddMonth, gdd]);

  useEffect(() => {
    // when gdd inputs change
    getPrecipData();
  }, [field, precipStartYear, precipEndYear, precipMonth]);
  // #endregion

  // #region - initial
  // ------ Field checks and updates ------
  // Reset initial data layer upon field change to avoid showing data from old field
  // Still shows old info if not on the first data layer..
  const resetInit = () => {
    setSoilGeo({});
    setSoilAverage(null);
    setSoilLegend([]);

    // setPolarisImage('');
    // setPolarisLegend([]);
    // setPolarisExtent({});
    // setPolarisAverage(0);

    // setCdlImage('');
    // setCdlLegend([]);
    // setCdlExtent({});
    // setSelectedCdlYear(cdlYears[1]);

    // setTillableImage('')
    // setTillableLegend([])
    // setTillableExtent({})
    // setSelectedTillableYear(tillableYears[0])

    // setNdviImage('')
    // setNdviExtent({})
    // setNdviLegend([])
    // setNdviAttributes(null)
  };

  const handleSelectField = (field) => {
    const fieldCopy = cloneDeep(field);
    const boundary = JSON.parse(fieldCopy.boundary);
    initLoaded.current = false;
    if (boundary.type === 'FeatureCollection') {
      // need to set to feature instead (flask doesn't like feature collections)
      const feature = boundary.features[0];
      fieldCopy.boundary = JSON.stringify(feature);
    }

    if (
      field.source !== undefined
      && precisionSources.includes(field.source.toLowerCase())
    ) {
      setPrecisionField(true);
    } else {
      setPrecisionField(false);
    }
    setField(fieldCopy);
  };

  const checkFieldInUS = async (field) => {
    // console.log('In check field')
    // console.log('field :>> ', field);

    // Most tools do not work outside of U.S.
    if (exists(field?.state)) {
      // Check if field has state (in U.S.)
      initialLoad();
    } else {
      // Confirm state is not just missing because of one bad call
      const [state, county] = await getStateAndCounty(
        field.latitude,
        field.longitude,
      );
      // console.log('location :>> ', location);
      if (exists(state)) {
        // Add state and county
        setField({
          ...field,
          state,
          county,
        });
      } else {
        // Field is not in US
        setOutsideUSErrorMessages();

        // get data for Components that work outside of US
        initialLoadForFieldsOutsideOfUS();
      }
    }
  };

  // ------ Functions to run upon initial load or similar ------
  const getLayerIndex = (layer) => {
    return layerMappings[layer.toLowerCase()];
  }

  // Retry passed function once after 2 seconds if response returns false
  const getWithRetry = async (func) => {
    // console.log("In get with retry. func:", func)

    // For use with async function that will return something on success
    // NOTE: A lot of these functions are not actually using this "limit" key
    let response = await func({ limit: true, lastCall: false });
    // Try two more times in case of failure
    // One
    if (!response) {
      // Need sleep in here for calls that do not have other timeouts, currently:
      // naip, weather,
      await sleep(750);
      response = await func({ limit: true, lastCall: false });
    }
    // Two
    if (!response) {
      await sleep(750);
      response = await func();
    }
  };

  const initialLoad = async () => {
    // console.log('calling initial load')
    // check that things are in fact empty before calling everything
    // TODO put that in ^^^^^
    if (!initLoaded.current) {
      clearErrorMessages();

      getWithRetry(getSsurgoNew);
      await sleep(1000);

      getWithRetry(getCDL);
      await sleep(1000);

      if (authenticated) {
        getWithRetry(getElevationIndex);
        await sleep(1000);
      }

      getWithRetry(getPolaris);
      await sleep(1000);

      getWithRetry(getNDVI);
      await sleep(1000);

      if (authenticated) {
        getWithRetry(getNaipYears);
        await sleep(1000); 
      }

      // getWeatherAlerts - Weather alerts rely on data that may not always be available on initialLoad. This is then instead called in WeatherAlertMap directly from RealTimeWeather layer/component
      getWithRetry(getWeather);
      await sleep(1000);

      getWithRetry(getGrowingDays);
      await sleep(1000);

      getWithRetry(getPrecipData);
      await sleep(1000);

      // LandValue data is not automatically fetched

      // NOTE: why getWithRetry is not used for getSAR
      // "getSAR is not conducive to a retry as it is loading a base layer dynamically from the source and catching an error to retry from is very difficult.
      // getSAR() call is really baked into a call made from the leaflet map to get map tiles and error catching + retrying would be very difficult. (error catching has been attempted before but did not totally work out)" - Tim
      getSAR();
      await sleep(1000);

      // InsuranceTools' data is not automatically fetched

      // Guidance Lines data is not automatically fetched

      getWithRetry(getTillable);
      await sleep(1000);

      getWithRetry(getDrainage);
      await sleep(1000);

      initLoaded.current = true;
    }

  };

  const initialLoadForFieldsOutsideOfUS = () => {
    // console.log('calling initial load for outside of US')
    getWithRetry(getWeather);
    getWithRetry(getNDVI);

    // See reason in initialLoad for why this is not using getWithRetry
    getSAR();
  };

  const setOutsideUSErrorMessages = () => {
    const error = 'is only available in the U.S.';

    setPolarisError(`Polaris data ${error}`);
    setSSurgoError(`SSURGO data ${error}`);
    setCdlError(`Crop Land Data Layers ${error}`);
    setGddError(`Growing Degree Days ${error}`);
    setPrecipError(`Precipitation ${error}`);
    // setWeatherError(`Weather data ${error}`);
    // setWeatherAlertsError('Weather alerts are only available in the U.S.');
    setElevationError(`Topography and slope data ${error}`);
    setNaipError(`NAIP data ${error}`);
    setInsuranceToolError(`Insurance Tools ${error}`);
    setLandValueError(`Land Value data ${error}`);
    setTillableError(`Tillable data ${error}`);
    setDrainageError(`Drainage data ${error}`);

    // After setting error message, ensure loading is false
    setPolarisLoading(false);
    setSsurgoLoading(false);
    setCdlLoading(false);
    setGddLoading(false);
    setPrecipLoading(false);
    // setWeatherLoading(false);
    // setWeatherAlertsLoading(false);
    setElevationLoading(false);
    setNaipLoading(false);
    setInsuranceToolLoading(false);
    setLandValueLoading(false);
    setTillableLoading(false);
    setDrainageLoading(false);

    // Location shouldn't matter for: guidanceLoading, sarLoading, weatherLoading, ndviLoading
  };

  const clearErrorMessages = () => {
    setPolarisError('');
    setSSurgoError('');
    setCdlError('');
    setGddError('');
    setPrecipError('');
    setWeatherError('');
    setWeatherAlertsError('');
    setElevationError('');
    setNaipError('');
    setInsuranceToolError('');
    setLandValueError('');
    setNdviError('');
    setSarError('');
    setGuidanceError('');
    setTillableError('');
    setDrainageError('');
  };
  // #endregion

  // #region - get information for all datalayers
  const getPolaris = async ({variable = 'ph', depth = '0-5', stat = 'mean',  limit = false, lastCall = true } = {}) => {
    setPolarisLoading(true);
    try {
      const polarisReq = {
        Soil_Parameter: variable,
        Depth_Range: depth,
        Statistic: stat,
        Legend_Ranges: null,
        aoi: field.boundary,
        Resolution: '0.0001',
      };
      const polarisProcessing = await processPolaris(polarisReq, limit);
      const polarisResponse = JSON.parse(polarisProcessing);
      if (polarisResponse.Error) {
        if (showDebugOutput) console.log('Error getting polaris', polarisResponse.Error);

        // Only set the error message and stop the loading after all retries
        if (lastCall) {
          if (selectedLayer === getLayerIndex('polaris')) {
            enqueueSnackbar("Couldn't find imagery for those settings");
          }
          setPolarisError(
            'We were unable to get Polaris Data for your field. Please check back later or try another field',
          );
          setPolarisLoading(false);
        }
        return false;
      }
      setPolarisError('');

      const polarisImage = polarisResponse.Features[0].attributes.pngb64;
      const polarisExtent = polarisResponse.Features[0].attributes.Extent;
      const convertedExtent = polarisExtent
        .split(', ')
        .map((x) => parseFloat(x));
      const bounds = convertExtentToBounds(convertedExtent);
      const sum = polarisResponse.Features[0].attributes.Legend.map(
        (x) => x.Mean,
      ).reduce((a, b) => a + b, 0);
      const legend = polarisResponse.Features[0].attributes.Legend;
      setPolarisImage(polarisImage);
      setPolarisExtent(bounds);
      setPolarisLegend(polarisResponse.Features[0].attributes.Legend);
      setPolarisAverage(
        sum / polarisResponse.Features[0].attributes.Legend.length,
      );
      setPolarisLoading(false);
      return true;
    } catch (err) {
      // console.log(`Problem getting Polaris: ${err}`);
      setPolarisLoading(false);
      setPolarisError(
        'We were unable to get Polaris Data for your field. Please check back later or try another field',
      );
      return false;
    }
  };

  const getCDL = async ({year = selectedCdlYear,  limit = false, lastCall = true } = {}) => {
    setCdlLoading(true);
    try {
      const CDLReq = {
        aoi: field.boundary,
        Projection: 'EPSG:4326',
        Resolution: '0.0001',
        years: null,
        product: null,
      };

      const years = [year.toString()];
      CDLReq.years = JSON.stringify(years);

      const CDLProcessing = await processCDL(CDLReq, limit);
      const CDLResponse = JSON.parse(CDLProcessing);
      if (
        !CDLResponse
        || CDLResponse.error
        || CDLResponse.message
        || CDLResponse.status === 'FAILURE'
      ) {
        // Only set the error message and stop the loading after all retries
        if (lastCall) {
          if (selectedLayer === getLayerIndex('cdl')) {
            enqueueSnackbar("Couldn't find data for the selected year");
          }
          setCdlImage('');
          setCdlExtent([]);
          setCdlLegend([]);
          setCdlLoading(false);
          getCdlError(year);
        }
        return false;
      }
      setCdlError('');

      const info = CDLResponse[year].attributes;
      const CDLImage = info.pngb64;

      const CDLExtent = convertExtentToBounds(
        info.Extent.split(', ').map((x) => parseFloat(x)),
      );

      setCdlImage(CDLImage);
      setCdlExtent(CDLExtent);
      setCdlLegend(CDLResponse[`${year}`].attributes.Legend);
      setCdlLoading(false);
      return true;
    } catch (e) {
      if (showDebugOutput) console.log(`Problem getting cdl: ${e}`);
      setCdlLoading(false);
      getCdlError(year);
      return false;
    }
  };

  const tillableFail = (lastCall, year, err="") => {
    if (showDebugOutput) console.error(`Problem getting cdl: ${err}`);

    // Only set the error message and stop the loading after all retries
    if (lastCall) {
      if (selectedLayer === getLayerIndex('tillableacres')) {
        enqueueSnackbar("Couldn't find data for the selected year");
      }
      setTillableImage('');
      setTillableExtent([]);
      setTillableLegend([]);
      setTillableLoading(false);
      getTillableError(year);
    }
    return false;
  }

  const getTillable = async ({year = selectedTillableYear,  limit = false, lastCall = true } = {}) => {
    setTillableLoading(true);
    try {
      const CDLReq = {
        aoi: field.boundary,
        Projection: 'EPSG:4326',
        Resolution: '0.0001',
        years: null,
        product: "['CultivatedLayer']",
      };

      const years = [year.toString()];
      CDLReq.years = JSON.stringify(years);

      const CDLProcessing = await processCDL(CDLReq, limit);
      const CDLResponse = JSON.parse(CDLProcessing);
      // console.log(CDLResponse)
      if (
        !CDLResponse || CDLResponse.error
        || CDLResponse.message
        || CDLResponse.status === 'FAILURE'
      ) {
        return tillableFail(lastCall, year);
      }
      setTillableError('');

      const info = CDLResponse.CultivatedLayer.attributes;
      const CDLImage = info.pngb64;

      const CDLExtent = convertExtentToBounds(
        info.Extent.split(', ').map((x) => parseFloat(x)),
      );

      const legend = [];
      let tilledArea = 0;
      let tilledAcres = 0;

      info.Legend.forEach((x) => {
        legend.push(x);
        tilledArea += Number(x.Area.replace(' %', ''));
        tilledAcres += x.Acres;
      });

      // Add entry
      legend.push({
        Acres: field.acres - tilledAcres,
        Area: `${(100 - tilledArea).toFixed(2)} %`,
        Count: 0,
        CountAllPixels: 0,
        Cultivated: 'No',
        color: '',
      });

      setTillableImage(CDLImage);
      setTillableExtent(CDLExtent);
      setTillableLegend(legend);
      setTillableLoading(false);
      return true;
    } catch (e) {
      return tillableFail(lastCall, year, e);
    }
  };

  const getTillableError = (year) => {
    // We may not yet have data for previous year
    if (year === new Date().getFullYear() - 1) {
      setTillableError(
        `Tillable Acres for ${year} are currently not available. Please check back soon.`,
      );
    } else {
      setTillableError(
        'We could not find data for the selected year. Please try another year or field.',
      );
    }
  };

  const getCdlError = (year) => {
    // We may not yet have data for previous year
    if ((year = new Date().getFullYear() - 1)) {
      setCdlError(
        `Crop Land Data Layers for ${year} are currently not available. Please check back soon.`,
      );
    } else {
      setCdlError(
        'We could not find data for the selected year. Please try another year or field.',
      );
    }
  };

  const drainageFail = (lastCall, err="", warning="") => {
    if (showDebugOutput) console.log(`Problem getting Drainage: ${err}`);

    // Only set the error message and stop the loading after all retries
    if (lastCall) {
      setDrainageLoading(false);
      setDrainageError(
        warning ? warning : 'Unable to get Tile Drainage data for selected field at this time',
      );

      setDrainageImage('');
      setDrainageLegend([]);
      setDrainageExtent({});
      setDrainageLoading(false);
    }
    return false;
  }

  const getDrainage = async ({ limit = false, lastCall = true } = {}) => {
    setDrainageLoading(true);
    setDrainageError('');
    try {
      const req = {
        aoi: field.boundary,
        Projection: 'EPSG:4326',
        Resolution: 0.00001,
        return_geojson: 1,
      };

      const drainageResponse = await getTileDrainage(req, limit);
      const drainageResObj = JSON.parse(drainageResponse);
      const warning = drainageResObj.Warning;

      if (warning !== undefined) {
        return drainageFail(lastCall, warning, warning);
      } else {
        const drainage = drainageResObj.TileDrainage;
        const drainImage = drainage.attributes.pngb64;
        const drainExtent = drainage.attributes.Extent;
        const drainLegend = drainage.attributes.Legend;
        const convertedExtent = drainExtent.split(', ').map((x) => parseFloat(x));
        const bounds = convertExtentToBounds(convertedExtent);

        setDrainageImage(drainImage);
        setDrainageLegend(drainLegend);
        setDrainageExtent(bounds);
        setDrainageLoading(false);
        return true;
      }
    } catch (err) {
      return drainageFail(lastCall, err);
    }
  };

  const getRandomColor = () => {
    let letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  const getSsurgoNew = async ({ limit = false, lastCall = true } = {}) => {
    setSsurgoLoading(true);
    try {
      // Send request to backend to get ssurgo data out of database
      const boundary = JSON.parse(field.boundary);
      const aoi = wkt.convert(boundary.geometry);
      const SsurgoReq = {
        AOI: aoi,
        Projection: null,
        Resolution: null,
        Soil_Parameter: null,
        Product: null,
      };

      const ssurgoProcessing = await ssurgoData(SsurgoReq, limit);
      if (ssurgoProcessing === undefined) {
        // Only set the error message and stop the loading after all retries
        if (lastCall) {
          if (selectedLayer === getLayerIndex('ssurgo')) {
            enqueueSnackbar("Couldn't load soil data");
          }
          setSsurgoLoading(false);
          setSSurgoError(
            'We were unable to load SSURGO soil data for this field. Please try another field.',
          );
        }
        return false;
      }
      setSSurgoError('');

      // process response
      const ssurgoResponse = JSON.parse(ssurgoProcessing);
      const ssurgoGeo = { type: 'FeatureCollection', features: [] };
      const data = [];
      // loop over results and convert to correct geojson format
      for (let i = 0; i < ssurgoResponse.Result.length; i++) {
        const feature = { type: 'Feature', properties: {}, geometry: {} };
        feature.geometry = wkt.parse(ssurgoResponse.Result[i].shapes);
        feature.properties = ssurgoResponse.Result[i];
        delete feature.properties.shapes;
        const indx = ssurgoGeo.features.findIndex((f) => f.properties.Soil_Type === ssurgoResponse.Result[i].Soil_Type);
        if (indx != -1) {
          // group geometry into multi poly
          if (ssurgoGeo.features[indx].geometry.type === 'Polygon') {
            ssurgoGeo.features[indx].geometry.type = 'MultiPolygon';
            ssurgoGeo.features[indx].geometry.coordinates = [ssurgoGeo.features[indx].geometry.coordinates];
          }
          // if item is polygon then add to list, if multi then spread into list
          if (feature.geometry.type === 'Polygon') {
            ssurgoGeo.features[indx].geometry.coordinates.push(feature.geometry.coordinates);
          } else {
            ssurgoGeo.features[indx].geometry.coordinates.push(...feature.geometry.coordinates);
          }
          ssurgoGeo.features[indx].properties.area += feature.properties.area;
        } else {
          ssurgoGeo.features.push(feature);
        }
      }

      // loop over features and add color values, add items to summary list
      const used_colors = [];
      for (let i = 0; i < ssurgoGeo.features.length; i++) {
        // if we've already used all our default colors start randomly generating
        if (i > zonesColors.length) {
          let used = true;
          let color = null;
          while (used) {
            color = getRandomColor();
            if (!used_colors.includes(color) && !zonesColors.includes(color)) {
              used = false;
            }
          }
          ssurgoGeo.features[i].properties.color = color;
        } else {
          ssurgoGeo.features[i].properties.color = zonesColors[i];
        }
        data.push(ssurgoGeo.features[i].properties);
      }

      // sort data/summary by acres desc
      data.sort((a, b) => b.area - a.area);

      setSoilAverage(ssurgoResponse.Average);
      setSoilGeo(ssurgoGeo);
      setSoilLegend(data);
      setSsurgoLoading(false);
      return true;
    } catch (e) {
      if (showDebugOutput) console.log(`Problem getting SSURGO: ${e}`);
      setSsurgoLoading(false);
      setSSurgoError(
        'We were unable to load SSURGO soil data for this field. Please try another field.',
      );
      return false;
    }
  };

  /**
   * DEPRICATED CODE
   * This function was previously used for handling ssurgo requests that passed through to the flask API's ssurgo service
   * ssurgo is now handled via .NET endpoint. See getSsurgoNew() for the currently used function
   */
  const getSsurgo = async () => {
    setSSurgoError('');
    setSsurgoLoading(true);
    try {
      // console.log(field.boundary)
      const boundary = JSON.parse(field.boundary);
      const SsurgoReq = {
        AOI: field.boundary,
        Projection: null,
        Resolution: null,
        Soil_Parameter: null,
        Product: null,
      };

      const ssurgoProcessing = await ssurgoData(SsurgoReq);
      if (ssurgoProcessing === undefined) {
        if (selectedLayer === getLayerIndex('ssurgo')) {
          enqueueSnackbar("Couldn't load soil data");
        }
        setSsurgoLoading(false);
        setSSurgoError(
          'We were unable to load SSURGO soil data for this field. Please try another field.',
        );
        return false;
      }
      const ssurgoGeo = ssurgoProcessing.attributes.GeoJSON;
      const data = [];
      for (let i = 0; i < ssurgoGeo.features.length; i++) {
        ssurgoGeo.features[i].properties.color = zonesColors[i];
        data.push(ssurgoGeo.features[i].properties);
      }

      setSoilAverage(ssurgoProcessing.attributes.Weighted_Average);
      setSoilGeo(ssurgoGeo);
      setSoilLegend(data);
      setSsurgoLoading(false);
      return true;
    } catch (e) {
      if (showDebugOutput) console.log(`Problem getting SSURGO: ${e}`);
      setSsurgoLoading(false);
      setSSurgoError(
        'We were unable to load SSURGO soil data for this field. Please try another field.',
      );
      return false;
    }
  };

  const getNDVI = async ({
    date = new Date().toISOString(),
    satOption = 'Landsat,Sentinel',
    latest = true,
    scale = 'Relative',
    clouds = true,
    interp = false,
    selectedBand = 'NDVI',
    limit = false,
    lastCall = true, } = {},
  ) => {
    setNdviLoading(true);
    try {
      const Startdate = new Date(date);
      const day = Startdate.getDate() + 7;
      const month = Startdate.getMonth() + 1;
      const Enddate = new Date(`${month}/${day}/${Startdate.getFullYear()}`);

      const req = {
        aoi: field.boundary,
        Band: JSON.stringify([selectedBand]),
        satellite: satOption,
        Startdate: Startdate.toLocaleDateString(),
        Enddate: Enddate.toLocaleDateString(),
        legendtype: scale,
        resolution: '0.00001',
        showlatest: latest ? '1' : '0',
        filter: clouds ? '1' : '0',
        qafilter: '1',
        qacloudperc: '85',
        interpolate: interp ? '1' : '',
        interpolatedim: interp ? '3' : null,
        interpolatemethod: interp ? 'inpaintcustom' : null,
        byweek: '1',
        interpolateweeks: interp ? '3' : null,
        interpolateweekstoreturn: interp ? '[4]' : null,
      };

      const NDVIProcessing = await HLSforNDVI(req, limit);
      if (NDVIProcessing === undefined) {
        // request failed
        return ndviFail(lastCall, "no response or error occured");
      }

      const NDVIResult = JSON.parse(NDVIProcessing);
      if (NDVIResult.status === 'FAILURE') {
        return ndviFail(lastCall, "NDVIResult.status === 'FAILURE'");
      }

      if (NDVIResult[0].error) {
        return ndviFail(lastCall, NDVIResult[0].error);
      }
      setNdviError('');

      const { attributes } = NDVIResult[0].features[0];
      const ndviImage = attributes.pngb64;
      const ndviLegend = attributes.Legend;
      const ndviExtent = convertExtentToBounds(
        attributes.Extent.split(', ').map((x) => parseFloat(x)),
      );

      setNdviImage(ndviImage);
      setNdviLegend(ndviLegend);
      setNdviExtent(ndviExtent);
      setNdviAttributes({
        average: attributes.Mean,
        low: attributes.Percentile5,
        high: attributes.Percentile95,
        date: NDVIResult[0].tiledate,
      });
      setNdviLoading(false);
      return true;
    } catch (e) {
      return ndviFail(lastCall, e);
    }
  };

  const ndviFail = (lastCall, err) => {
    if (showDebugOutput) console.log(`Problem getting NDVI: ${err}`);

    // Only set the error message and stop the loading after all retries
    if (lastCall) {
      if (selectedLayer === getLayerIndex('hls')) {
        enqueueSnackbar("Sorry, we couldn't find imagery for your area!");
      }
      setNdviImage('');
      setNdviLegend([]);
      setNdviExtent({});
      setNdviAttributes(null);
      setNdviLoading(false);
      setNdviError(
        'We were unable to get Satellite Crop Health Data for your field. Please check back later or try another field',
      );
    }
    return false;
  };

  const getSAR = async (layers = 'IW_VV', date = null, latest = true) => {
    setSarLoading(true);
    setSarError('');

    try {
      const boundary = JSON.parse(field.boundary);
      let bounds = L.geoJSON(boundary).getBounds();

      const coords = [
        [
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
          [bounds.getNorthEast().lng, bounds.getNorthEast().lat],
          [bounds.getSouthEast().lng, bounds.getSouthEast().lat],
          [bounds.getSouthWest().lng, bounds.getSouthWest().lat],
          [bounds.getNorthWest().lng, bounds.getNorthWest().lat],
        ],
      ];

      const poly = turf.polygon(coords);
      turf.transformScale(poly, 5, { mutate: true });
      bounds = L.geoJSON(poly).getBounds();

      const bbox = bounds.toBBoxString();
      const srs = 'EPSG:4326';
      const format = 'image/png';
      const width = 2000;
      const height = 2000;

      let params = `?bbox=${bbox}&srs=${srs}&format=${format}&layers=${layers}&width=${width}&height=${height}`;
      if (!latest) {
        params += `&date=${date.toISOString()}`;
      }

      const imgUrl = `${Endpoints.BASEURL}${Endpoints.SAR}${params}`;
      setSarExtent(bounds);
      setSarImage(imgUrl);
      setSarLoading(false);
    } catch (e) {
      if (showDebugOutput) console.log(`Problem getting SAR: ${e}`);
      setSarError('We encounted a problem getting SAR data for selected area');
      setSarLoading(false);
    }
  };

  const gddFail = (lastCall, err="") => {
    if (showDebugOutput) console.log(`Problem getting GDD: ${err}`);

    // Only set the error message and stop the loading after all retries
    if (lastCall) {
      setGddLoading(false);
      setGddError(
        'We encounted a problem getting Growing Degree Data. Please check back later or try a different field',
      );
    }
    return false;
  }

  const getGrowingDays = async ({ limit = false, lastCall = true } = {}) => {
    setGddLoading(true);
    try {
      const gddResponse = await getGrowingDegreeDays(
        gddMonth,
        1,
        gddEndYear,
        field.longitude,
        field.latitude,
        gddStartYear,
        gdd,
        limit,
      );

      if (gddResponse === undefined) {
        return gddFail(lastCall, "no response or error occured");
      } else {
        setGddError('');
        setGrowingData(gddResponse);
        setGddLoading(false);
        return true;
      }
    } catch (e) {
      return gddFail(lastCall, e);
    }
  };

  const precipFail = (lastCall, err="") => {
    if (showDebugOutput) console.log(`Problem getting Precip Data: ${err}`);

    // Only set the error message and stop the loading after all retries
    if (lastCall) {
      setPrecipError(
        'We encounted a problem getting Precipitation Data. Please check back later or try a different field',
      );
      setPrecipLoading(false);
    }
    return false;
  }

  const getPrecipData = async ({ limit = false, lastCall = true } = {}) => {
    setPrecipLoading(true);
    try {
      const precipResponse = await getPrecipitationData(
        precipMonth,
        1,
        precipEndYear,
        field.longitude,
        field.latitude,
        precipStartYear,
        limit,
      );

      if (precipResponse === undefined) {
        return precipFail(lastCall, "no response or error occured");
      } else {
        setPrecipData(precipResponse);
        setPrecipLoading(false);
        setPrecipError('');
        return true;
      }
    } catch (e) {
      return precipFail(lastCall, e);
    }
  };

  const weatherFail = (lastCall, err = "") => {
    // Only set the error message and stop the loading after all retries
    if (lastCall) {
      setWeatherLoading(false);
      setWeatherError(
        'We could not get current weather for this field. Please check back later or try another field.',
      );
      if (showDebugOutput) console.log(`Problem getting weather: ${err}`);
    }
    return false;
  }

  const getWeather = async ({ limit = false, lastCall = true } = {}) => {
    setWeatherLoading(true);
    try {
      const response = await fetch(
        `https://api.weather.gov/points/${field.latitude},${field.longitude}`,
        { method: 'GET' },
      );

      const data = await response.json();
      if (data.properties !== undefined) {
        setWeatherError('');
        await getForecast(data.properties.forecast, setWeekly);
        await getForecast(data.properties.forecastHourly, setHourly);
        setWeatherLoading(false);
        return true;
      }
      return weatherFail(lastCall, "no response or error occured");
    } catch (err) {
      return weatherFail(lastCall, err);
    }
  };

  const getForecast = async (url, setter) => {
    try {
      const response = await fetch(url, {
        method: 'GET',
      });
      const data = await response.json();
      // console.log(data)
      if (data?.properties !== undefined && data.status !== 503) {
        setter(data.properties.periods);
      } else {
        setWeatherError(
          'We could not get current weather for this field. Please check back later or try another field.',
        );
      }
    } catch (err) {
      if (showDebugOutput) console.log(`Problem getting weather forecast: ${err}`);
    }
  };

  const getWeatherAlerts = async (shape) => {
    setWeatherAlertsLoading(true);
    setSevereWeatherAlerts([]);
    try {
      const weatherAlerts = await getSevereWeatherAlerts(shape);
      setSevereWeatherAlerts(JSON.parse(weatherAlerts));
      setWeatherAlertsLoading(false);
    } catch (err) {
      if (showDebugOutput) console.log(`Problem getting weather alerts: ${err}`);
      setWeatherAlertsError(
        'We were unable to get Weather Alerts at this time. Please try again later',
      );
      setWeatherAlertsLoading(false);
    }
  };

  const getElevationIndex = async ({index = selectedElevation.value, limit = false, lastCall = true } = {}) => {
    setElevationLoading(true);
    let legend = null;
    try {
      let res = null;
      if (index.toUpperCase() !== 'ELEVATIONMAP') {
        const req = {
          FieldOperationList: [],
          aoi: field.boundary,
        };
        res = await elevationIndex(0, '', index, req, limit);
        if (res && res.length > 0 && res[0].pngb64) {
          const extent = convertExtentToBounds(
            res[0].extent.split(', ').map((x) => parseFloat(x)),
          );
          const image = `data:image/jpeg;base64,${res[0].pngb64}`;
          setElevationImage(image);
          setElevationLegend(res[0].legend);
          legend = res[0].legend;
          setElevationExtent(extent);
        } else {
          return elevationFail(lastCall, "no response or error occured");
        }
      } else {
        res = await getElevationMap(0, '', [], field.boundary, false, limit);
        if (res && res.pngb64) {
          const extent = convertExtentToBounds(
            res.extent.split(', ').map((x) => parseFloat(x)),
          );
          const image = `data:image/jpeg;base64,${res.pngb64}`;
          setElevationImage(image);
          setElevationLegend(res.legend);
          legend = res.legend;
          setElevationExtent(extent);
        } else {
          return elevationFail(lastCall, "no response or error occured");
        }
      }
      setElevationError('');

      setElevationLoading(false);
      // pdf will use this to store multiple legends
      return legend;
    } catch (e) {
      return elevationFail(lastCall, e);
    }
  };

  const elevationFail = (lastCall, err) => {
    if (showDebugOutput) console.log(`Problem getting elevation: ${err}`);

    // Only set the error message and stop the loading after all retries
    if (lastCall) {
      setElevationImage('');
      setElevationLegend([]);
      setElevationExtent({});
      if (exists(field?.state)) {
        // Field is in US
        setElevationError(
          'We encounted a problem getting selected elevation. Please try another index of field.',
        );
      } else {
        setElevationError(
          'Topography and slope data is only available in the US',
        );
      }
      setElevationLoading(false);
    }
    return false;
  };

  const naipFail = (lastCall, err="") => {
    // Only set the error message and stop the loading after all retries
    if (lastCall) {
      if (showDebugOutput) console.log(`Problem getting naip: ${err}`);
      setNaipLoading(false);
      setNaipError("We didn't find any NAIP imagery for this area.");
    }
    return false;
  }

  const getNaipYears = async ({ limit = false, lastCall = true } = {}) => {
    setNaipLoading(true);
    try {
      const years = await getAvailableNaipYears(field.boundary);
      const yearList = years.split(',').reverse();
      setNaipYears(yearList);
      if (exists(yearList) && exists(field?.state)) {
        // If available NAIP years and state in US
        setSelectedNaipYear(yearList[0]);
        return await getNaip(yearList[0], field.boundary, lastCall);
      } else {
        // set outside of US error message
        setNaipError('NAIP data is only available in the U.S.');
        return true;
      }
    } catch (e) {
      return naipFail(lastCall, e);
    }
  };

  const changeNaipYear = (year) => {
    setSelectedNaipYear(year);
    getNaip(year, field.boundary);
  };

  const getNaip = async (year, boundary, lastCall = true) => {
    setNaipLoading(true);
    try {
      const naipImagery = await requestNaipImagery(year, boundary);
      if (!naipImagery) {
        return naipFail(lastCall, "no response or error occured");
      } else {
        setNaipError('');
        setNaipData(naipImagery);
        setNaipLoading(false);
        return true;
      }
    } catch (e) {
      return naipFail(lastCall, e);
    }
  };
  // #endregion

  // #region - step control
  const changeSection = (section) => {
    if (section === 0) {
      setStep(section);
    } else if (section === 1) {
      if (
        fieldData.selectedField.id !== ''
        || fieldData.fieldToClaim.feature !== null
      ) {
        setStep(section);
      } else {
        enqueueSnackbar('Please draw or select a field on map to get started');
      }
    }
  };

  /** Sets selectedLayer to current pdf one whenever "Create PDF" button is clicked */
  const openPdf = () => {
    setSelectedLayer(pdfLayer);
  };

  const onConnectClick = () => {
    setOpenIntegration(true);
  };

  /**
   * Handles displaying the left pane of Data Layers for the user to select which tool to display
   */
  const selections = () => (
    <Box className={classes.selections}>
      <Box>
        {/* Layers before Insurance Tools */}
        {layers.map((layer, i) => (
          <Box
            id={layer.toLowerCase().split(' ').join('_')}
            key={i}
            className={
              selectedLayer === i && selectedLayer !== whatIfLyrIdx
                ? classes.selected
                : classes.select
            }
            onClick={() => (
              layer.toLowerCase() === 'profitlayers' && authenticated ?
              window.open(Endpoints.HOME + '/app/ProfitLayers', '_blank')
              : setSelectedLayer(i)
            )}
          >
            {layer}
          </Box>
        ))}

        {/* Insurance Tools layers - 3 long and treated differently */}
        {/* "Insurance Tools" itself is never selected but expands when clicked on */}
        {selectedLayer >= whatIfLyrIdx
          && selectedLayer <= (whatIfLyrIdx + 2)
          && insuranceTools.map((tool, i) => (
            <Box key={i} ml={2}>
              <Box
                className={
                  selectedLayer === i + whatIfLyrIdx ? classes.selected : classes.select
                }
                onClick={() => setSelectedLayer(i + whatIfLyrIdx)}
              >
                {tool}
              </Box>
              <Divider style={{ margin: '4px 0' }} />
            </Box>
          ))}

        {/* Layers after Insurance Tools */}
        {layers2.map((layer, i) => (
          <Box
            id={layer.toLowerCase().split(' ').join('_')}
            key={i}
            className={
              selectedLayer === i + (whatIfLyrIdx + 3) ? classes.selected : classes.select
            }
            onClick={() => setSelectedLayer(i + (whatIfLyrIdx + 3))}
          >
            {layer}
          </Box>
        ))}
      </Box>
      <Box>
        {!precisionField && (
          <Button
            className={classes.connectButton}
            variant="outlined"
            color="primary"
            onClick={() => onConnectClick()}
          >
            Connect Precision
          </Button>
        )}

        {!authenticated && (
          <>
            <Button
              className={classes.connectButton}
              variant="outlined"
              color="primary"
              href={Endpoints.BASEURL + Endpoints.SIGNIN}
            >
              <span style={{ color: green }}>Sign in</span>
            </Button>
            <Button
              className={classes.connectButton}
              variant="outlined"
              color="primary"
              href={Endpoints.BASEURL + Endpoints.SIGNUP}
            >
              <span style={{ color: green }}>Create Account</span>
            </Button>
          </>
        )}
      </Box>
    </Box>
  );

  // Handle actually changing to the PL page and section
  const handlePLChange = (step) => {
    window.history.replaceState({}, document.title, `${Endpoints.HOME}/app/ProfitLayers`);
    setPlStep(step);
    setSection(0);
  }

  // Handle user clicking 'Setup ProfitLayers' step
  const goToProfitLayers = () => {
    if (!user.isAuthenticated) {
      setLoginPromptOpen(true);
    } else {
      // Only skip the first PL step if the user has a claimed field selected
      // Seems idea is that only cleanedFields (claimed fields: from dropdown, etc.) will have this id key
      const step = fieldData.selectedField.id !== '' ? 1 : 0;
      // console.log('PL STEP:', step)
      handlePLChange(step);
    }
  };

  /**
   * Function for Field Claim functionality to set selected layer to first Data Layer when done claiming field.
   */
  const endFieldClaim = () => {
    setSelectedLayer(0);
  };
  // #endregion

  return (
    <Box className={classes.root} style={{ height: height - 80 }}>
      {/* Steps */}
      <AppBar
        position="static"
        color="transparent"
        style={{ boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.05)' }}
      >
        <Box className={classes.appBar}>
          <Steps
            changeSection={changeSection}
            goToProfitLayers={goToProfitLayers}
            mobileBreakPoint={mobileBreakPoint}
            mobileLayers={mobileLayers}
            pdfLayer={pdfLayer}
            section={step}
            selectedLayer={selectedLayer}
            setSelectedLayer={setSelectedLayer}
          />
        </Box>
      </AppBar>

      {/* Main display */}
      <Box className={classes.body}>
        {/* Step 0 - Field Selection */}
        <Slide
          in={step === 0}
          style={step !== 0 ? { display: 'none' } : {}}
          direction="right"
        >
          <Box display="flex">
            <FieldSelection
              mobileView={width <= 920}
              hideFieldSelection={hideFieldSelection}
              showMap={step === 0}
              changeSection={setStep}
              continueText="View Data Layers"
              isDataLayers
            />
          </Box>
        </Slide>

        {/* Step 1 - Data Layers */}
        {step > 0 && (
          <>
            <Box className={classes.layers} style={{ height: height - 195 }}>
              {/* Left pane for selecting which Data Layers tool to display */}
              {width > mobileBreakPoint && selections()}

              <Box
                style={{
                  overflowY: 'auto',
                  width: width > mobileBreakPoint ? width - 220 : width,
                }}
              >
                {selectedLayer === getLayerIndex('ssurgo') ? (
                  <SoilData
                    field={field}
                    loading={ssurgoLoading}
                    getSsurgo={getSsurgoNew}
                    geo={soilGeo}
                    setGeo={setSoilGeo}
                    legend={soilLegend}
                    setLegend={setSoilLegend}
                    average={soilAverage}
                    setAverage={setSoilAverage}
                    mobileBreakPoint={mobileBreakPoint}
                    errorMessage={ssurgoError}
                    precisionField={precisionField}
                    onConnectClick={onConnectClick}
                  />
                ) : selectedLayer === getLayerIndex('cdl') ? (
                  <CropLand
                    field={field}
                    loading={cdlLoading}
                    getCDL={getCDL}
                    years={cdlYears}
                    selectedYear={selectedCdlYear}
                    setSelectedYear={setSelectedCdlYear}
                    image={cdlImage}
                    setImage={setCdlImage}
                    legend={cdlLegend}
                    setLegend={setCdlLegend}
                    extent={cdlExtent}
                    setExtent={setCdlExtent}
                    mobileBreakPoint={mobileBreakPoint}
                    errorMessage={cdlError}
                    tillable={false}
                    precisionField={precisionField}
                    onConnectClick={onConnectClick}
                  />
                ) : selectedLayer === getLayerIndex('elevation') ? (
                  <>
                    {authenticated ? (
                      <Topography
                        field={field}
                        loading={elevationLoading}
                        getElevationIndex={getElevationIndex}
                        image={elevationImage}
                        legend={elevationLegend}
                        extent={elevationExtent}
                        elevationIndexSelection={elevationIndexSelection}
                        selectedElevation={selectedElevation}
                        setSelectedElevation={setSelectedElevation}
                        mobileBreakPoint={mobileBreakPoint}
                        errorMessage={elevationError}
                      />
                    ) : (
                      <ConnectAndIntegrate />
                    )}
                  </>
                ) : selectedLayer === getLayerIndex('polaris') ? (
                  <Polaris
                    field={field}
                    loading={polarisLoading}
                    getPolaris={getPolaris}
                    image={polarisImage}
                    setImage={setPolarisImage}
                    legend={polarisLegend}
                    setLegend={setPolarisLegend}
                    extent={polarisExtent}
                    setExtent={setPolarisExtent}
                    average={polarisAverage}
                    setAverage={setPolarisAverage}
                    mobileBreakPoint={mobileBreakPoint}
                    errorMessage={polarisError}
                    precisionField={precisionField}
                    onConnectClick={onConnectClick}
                  />
                ) : selectedLayer === getLayerIndex('hls') ? (
                  <NDVI
                    field={field}
                    loading={ndviLoading}
                    getNDVI={getNDVI}
                    image={ndviImage}
                    setImage={setNdviImage}
                    extent={ndviExtent}
                    setExtent={setNdviExtent}
                    legend={ndviLegend}
                    setLegend={setNdviLegend}
                    attributes={ndviAttributes}
                    setAttributes={setNdviAttributes}
                    mobileBreakPoint={mobileBreakPoint}
                    errorMessage={ndviError}
                  />
                ) : selectedLayer === getLayerIndex('pl') ? (
                  <ConnectAndIntegrate />
                ) : selectedLayer === getLayerIndex('naip') ? (
                  <>
                    {authenticated ? (
                      <AerialImagery
                        field={field}
                        loading={naipLoading}
                        naipYears={naipYears}
                        selectedNaipYear={selectedNaipYear}
                        changeNaipYear={changeNaipYear}
                        naipData={naipData}
                        mobileBreakPoint={mobileBreakPoint}
                        errorMessage={naipError}
                        precisionField={precisionField}
                        onConnectClick={onConnectClick}
                      />
                    ) : (
                      <ConnectAndIntegrate />
                    )}
                  </>
                ) : selectedLayer === getLayerIndex('weather') ? (
                  <RealTimeWeather
                    field={field}
                    setSelectedLayer={setSelectedLayer}
                    obileBreakPoint={mobileBreakPoint}
                    weatherLoading={weatherLoading}
                    weatherAlertsLoading={weatherAlertsLoading}
                    weatherError={weatherError}
                    weatherAlertsError={weatherAlertsError}
                    hourly={hourly}
                    weekly={weekly}
                    severeWeatherAlerts={severeWeatherAlerts}
                    getSevereWeatherAlerts={getWeatherAlerts}
                    setSevereWeatherAlerts={setSevereWeatherAlerts}
                  />
                ) : selectedLayer === getLayerIndex('gdd') ? (
                  <GrowingDays
                    field={field}
                    loading={gddLoading}
                    gdds={gdds}
                    years={gddYears}
                    months={months}
                    startYear={gddStartYear}
                    setStartYear={setGddStartYear}
                    endYear={gddEndYear}
                    setEndYear={setGddEndYear}
                    month={gddMonth}
                    setMonth={setGddMonth}
                    gdd={gdd}
                    setGdd={setGdd}
                    data={growingData}
                    errorMessage={gddError}
                    precisionField={precisionField}
                    onConnectClick={onConnectClick}
                  />
                ) : selectedLayer === getLayerIndex('precipitation') ? (
                  <Precipitation
                    field={field}
                    loading={precipLoading}
                    years={gddYears}
                    months={months}
                    startYear={precipStartYear}
                    setStartYear={setPrecipStartYear}
                    endYear={precipEndYear}
                    setEndYear={setPrecipEndYear}
                    month={precipMonth}
                    setMonth={setPrecipMonth}
                    data={precipData}
                    errorMessage={precipError}
                  />
                ) : selectedLayer === getLayerIndex('landvalue') ? (
                  <>
                    {/* <ComingSoon
                        text={['Land Value Comps']}
                      /> */}
                    {authenticated ? (
                      <LandValue
                        field={field}
                        summary={summary}
                        setSummary={setSummary}
                        saleDetails={saleDetails}
                        setSaleDetails={setSaleDetails}
                        soldDetails={soldDetails}
                        setSoldDetails={setSoldDetails}
                        errorMessage={landValueError}
                        mobileBreakPoint={mobileBreakPoint}
                      />
                    ) : (
                      <ConnectAndIntegrate />
                    )}
                  </>
                ) : selectedLayer === getLayerIndex('sar') ? (
                  <SAR
                    field={field}
                    loading={sarLoading}
                    getSAR={getSAR}
                    image={sarImage}
                    setImage={setSarImage}
                    extent={sarExtent}
                    setExtent={setSarExtent}
                    errorMessage={sarError}
                    mobileBreakPoint={mobileBreakPoint}
                    precisionField={precisionField}
                    onConnectClick={onConnectClick}
                  />
                ) : selectedLayer === getLayerIndex('whatif') ? (
                  <>
                    {/* Handled by InsuranceTools component that is NOT rendered conditionally so we don't have to wait for data to load */}
                  </>
                ) : selectedLayer === getLayerIndex('premium') ? (
                  <>
                    {/* Handled by InsuranceTools component that is NOT rendered conditionally so we don't have to wait for data to load */}
                  </>
                ) : selectedLayer === getLayerIndex('insurance') ? (
                  <>
                    {/* Handled by InsuranceTools component that is NOT rendered conditionally so we don't have to wait for data to load */}
                  </>
                ) : selectedLayer === getLayerIndex('guidancelines') ? (
                  <>
                    {authenticated ? (
                      <GuidanceLines
                        field={field}
                        loading={guidanceLoading}
                        setLoading={setGuidanceLoading}
                        errorMessage={guidanceError}
                        setErrorMessage={setGuidanceError}
                        mobileBreakPoint={mobileBreakPoint}
                        precisionField={precisionField}
                        onConnectClick={onConnectClick}
                      />
                    ) : (
                      <ConnectAndIntegrate />
                    )}
                  </>
                ) : selectedLayer === getLayerIndex('tillableacres') ? (
                  <>
                    <CropLand
                      field={field}
                      loading={tillableLoading}
                      getCDL={getTillable}
                      years={tillableYears}
                      selectedYear={selectedTillableYear}
                      setSelectedYear={setSelectedTillableYear}
                      image={tillableImage}
                      setImage={setTillableImage}
                      legend={tillableLegend}
                      setLegend={setTillableLegend}
                      extent={tillableExtent}
                      setExtent={setTillableExtent}
                      mobileBreakPoint={mobileBreakPoint}
                      errorMessage={tillableError}
                      tillable
                    />
                  </>
                ) : selectedLayer === getLayerIndex('tiledrainage') ? (
                  <>
                    <Drainage
                      field={field}
                      image={drainageImage}
                      loading={drainageLoading}
                      legend={drainageLegend}
                      extent={drainageExtent}
                      errorMessage={drainageError}
                      mobileBreakPoint={mobileBreakPoint}
                    />
                  </>
                ) : selectedLayer === pdfLayer ? (
                  <CreatePdf
                    field={field}
                    polarisAverage={polarisAverage}
                    polarisLegend={polarisLegend}
                    polarisImage={polarisImage}
                    polarisExtent={polarisExtent}
                    cropLandImage={cdlImage}
                    cropLandLegend={cdlLegend}
                    cropLandExtent={cdlExtent}
                    soilGeo={soilGeo}
                    soilLegend={soilLegend}
                    soilAverage={soilAverage}
                    ndviImage={ndviImage}
                    ndviExtent={ndviExtent}
                    ndviLegend={ndviLegend}
                    ndviAttributes={ndviAttributes}
                    gddData={growingData}
                    gddStart={gddStartYear}
                    gddEnd={gddEndYear}
                    precipData={precipData}
                    precipStart={precipStartYear}
                    precipEnd={precipEndYear}
                    elevationImage={elevationImage}
                    elevationLegend={elevationLegend}
                    elevationExtent={elevationExtent}
                    elevationIndexSelection={elevationIndexSelection}
                    getElevationIndex={getElevationIndex}
                    naipData={naipData}
                    sarImage={sarImage}
                    sarExtent={sarExtent}
                    selectedNaipYear={selectedNaipYear}
                    setSelectedNaipYear={setSelectedNaipYear}
                    naipYears={naipYears}
                    getNaip={getNaip}
                    authenticated={authenticated}
                    status={{
                      Polaris: polarisLoading,
                      CDL: cdlLoading,
                      SSURGO: ssurgoLoading,
                      NDVI: ndviLoading,
                      SAR: sarLoading,
                      GDD: gddLoading,
                      Precip: precipLoading,
                      elevationIndexStatus: elevationLoading,
                      Naip: naipLoading,
                      insuranceTool: insuranceToolLoading,
                    }}
                  />
                ) : selectedLayer === pdfLayer + 1 ? (
                  <>
                    {authenticated ? (
                      <Box
                        m={1}
                        borderRadius="borderRadius"
                        borderColor={grey}
                        border={1}
                        style={{ minWidth: 400, maxWidth: 480 }}
                      >
                        <FieldClaim
                          setSection={endFieldClaim}
                          setOpen={endFieldClaim}
                        />
                      </Box>
                    ) : (
                      <ConnectAndIntegrate
                        fromFooter
                      />
                    )}
                  </>
                ) : (
                  <ComingSoon />
                )}

                {/* Special handling for InsuranceTools so as to not have to wait for data to load. */}
                <>
                  {authenticated ? (
                    <InsuranceTools
                      mobileView={width < mobileBreakPoint}
                      displayTool={
                        selectedLayer === getLayerIndex('whatif')
                          ? 'What-If Analysis'
                          : selectedLayer === getLayerIndex('premium')
                            ? 'Premium Calculator'
                            : selectedLayer === getLayerIndex('insurance')
                              ? 'Insurance AI'
                              : selectedLayer === pdfLayer
                                ? 'All'
                                : ''
                      }
                      field={field}
                      setInsuranceToolLoading={setInsuranceToolLoading}
                    />
                  ) : selectedLayer === getLayerIndex('whatif')
                    || selectedLayer === getLayerIndex('premium')
                    || selectedLayer === getLayerIndex('insurance') ? (
                      <ConnectAndIntegrate />
                    ) : (
                      ''
                    )}
                </>
              </Box>
            </Box>

            <Footer
              changeSection={changeSection}
              field={field}
              openPdf={openPdf}
              pdfLayer={pdfLayer}
              section={selectedLayer}
              setSection={setSelectedLayer}
            />
          </>
        )}
      </Box>

      {/* Modals */}
      <Connect
        open={loginPromptOpen}
        setOpen={setLoginPromptOpen}
        message="Please sign in to use ProfitLayers"
      />

      <IntegrateModal open={openIntegration} setOpen={setOpenIntegration} />

      {/* 12/01/2021 - We want to remove popups from DataLayers */}
      {/* { step === 0 && (
        <PopupManager
          acreageReporting
          droneAnalysis
          soilTesting
          waterManagement
        />
      )} */}

    </Box>
  );
}

DataLayers.propTypes = {
  setSection: PropTypes.func.isRequired,
  setPlStep: PropTypes.func.isRequired,
  dataLayersStep: PropTypes.number.isRequired,
};

// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import { Box, Button, Switch, Tooltip } from '@material-ui/core';
import CallMergeIcon from '@material-ui/icons/CallMerge';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import CropFreeIcon from '@material-ui/icons/CropFree';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import TimelineIcon from '@material-ui/icons/Timeline';
import scissors from '../../../images/scissors.png';

// Styling
import { makeStyles } from '@material-ui/core/styles';

// Custom Components
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  icon: {
    ...theme.greenIcon,
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderRadius: 6,
    paddingRight: 6,
    margin: '2px 10px',
    padding: 4,
  },
  infoToolTip: theme.infoToolTip,
  mapControls: {
    ...theme.mapControls,
    justifyContent: 'center',
    flexWrap: 'wrap',
    padding: '2px 4px',
    backgroundColor: theme.palette.greys.light,
    borderRadius: 4,
  },
  mobileControls: {
    ...theme.mapControls,
    padding: 4,
    backgroundColor: '#ffffff',
    borderTop: '1px solid #555555',
    flexWrap: 'wrap',
  },
  mobileIcon: theme.greenIcon,
  section: {
    margin: '0px 8px',
    display: 'flex',

  },
  toolbarButton: {
    backgroundColor: '#ffffff',
    margin: 0.5,
  },
}));

/**
 * Tool bar for Acreage reporting editing of operation CLU intersections or when fixing overlapping CLU boundaries
 * Used in:
 *    ...\ClientApp\src\components\Maps\AcreageReporting\UpdateOverlapping.js
 *    ...\ClientApp\src\components\Maps\AcreageReporting\UpdateIntersection.js
 *    ...\ClientApp\src\components\Maps\AcreageReporting\UpdateOperation.js
 * @param {String} activeTool Tool currently selected
 * @param {Number} boundaryNo Number of boundary to edit
 * @param {Function} cancel Cancel current action
 * @param {Function} clip Removes any intersecting areas from the underlying boundary
 * @param {Function} colorsToUse Colors to use for selected boundaries
 * @param {Function} connect Allows user to fill the gap between areas
 * @param {Function} cut Cuts off part of polygon
 * @param {Function} draw Draw polygon
 * @param {Function} edit Being editing boundary
 * @param {Function} fillBoundary Fills inner boundary to outer
 * @param {Function} finishEdit Finished editing boundary
 * @param {Function} handleTwoLayers Flag to set toolbar state
 * @param {Function} reset Revert changes
 * @param {Function} resetToolbar Function to reset the toolbar
 * @param {Function} setBoundaryNo Function to change boundaryNo
 * @param {Function} setHandleTwoLayers Function to change handleTwoLayers
 * @param {Function} sliceBoundary Slices boundary in to sections
 * @param {Function} toggle Hide/Show toggled layer
 * @param {String} toggleText Text for toggle switch
 * @param {Boolean} toggleValue Toggle is on or off
 * @param {Boolean} zonesSliced Whether zones have been sliced - disable other features as not being handled
 * @returns {JSX} Toolbar for editing boundaries
 */
export const EditBoundary = ({
  activeTool,
  boundaryNo,
  cancel,
  clip,
  colorsToUse,
  cut,
  edit,
  draw,
  fillBoundary,
  finishEdit,
  handleTwoLayers,
  reset,
  resetToolbar,
  setBoundaryNo,
  setHandleTwoLayers,
  sliceBoundary,
  toggle,
  toggleText,
  toggleValue,
  connect,
  zonesSliced
}) => {
  const classes = useStyles();

  const handleLayerChange = (setting, value) => {
    setBoundaryNo(value);
    setHandleTwoLayers(setting);
  };

  // Reusable button to limit repetition
  const FormattedButton = ({ displayText, icon, onClick }) => (
    <Box className={classes.toolbarButton}>
      <Button
        color="primary"
        variant="outlined"
        size="small"
        style={{ height: '32px' }}
        onClick={() => onClick()}
      >
        {icon && icon}
        {displayText}
      </Button>
    </Box>
  );

  // Add a tooltip to the bottom of the component element
  const AddToolTip = ({ title, component }) => (
    <CustomToolTip
      title={title}
      placement="bottom"
    >
      <div>
        {component}
      </div>
    </CustomToolTip>
  )

  // Decides what should be displayed for edit boundary toolbar
  const displayControls = () => (
    <>
      {/* Toggle - This is currently onlu used in UpdateOperation*/}
      {toggle && (
        <Box display="flex" alignItems="center" marginRight="18px">
          <Switch
            checked={toggleValue}
            onChange={toggle}
            color="primary"
            name="toggle-complete-clus"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
          <span>
            {toggleText}
          </span>
        </Box>
      )}

      {/* Buttons */}
      <Box display="flex" alignItems="center">
        {/* Selected color */}
        { handleTwoLayers === 'edit' && (
          <FiberManualRecordIcon style={{ color: colorsToUse[boundaryNo - 1], marginRight: '8px' }} />
        )}

        {/* Finish edit button */}
        {activeTool === 'edit' && (
          <Box className={classes.section}>
            <AddToolTip
              title="Attempt to finalize current edits"
              component={
                <Box className={classes.toolbarButton}>
                  <Button
                    id="finish-edit-boundary" // this one is not a FormattedButton as needs an id
                    color="primary"
                    variant="outlined"
                    size="small"
                    style={{ height: '32px' }}
                    onClick={() => finishEdit()}
                  >
                    Finish Edit
                  </Button>
                </Box>
              }
            />
         </Box>
        )}

        {/* Pick subfield */}
        { handleTwoLayers === 'initial' ? (
          <>
            <FormattedButton
              displayText="Edit First Subfield"
              onClick={() => handleLayerChange('edit', 1)}
            />
            <FormattedButton
              displayText="Edit Second Subfield"
              onClick={() => handleLayerChange('edit', 2)}
            />
          </>
        ) : activeTool === '' && (
          // Back, Edit, Cut, Reset, Fill, Slice, and Draw buttons
          <>
            {/* Back Button */}
            {handleTwoLayers === 'edit' && (
              <Box className={classes.section}>
                <AddToolTip
                  title="Go back to pick which boundary to edit"
                  component={
                    <FormattedButton
                      displayText="Back"
                      onClick={() => resetToolbar()}
                    />
                  }
                />
              </Box>
            )}

            {/* Boundary Edits */}
            {(edit || reset || fillBoundary) &&
              <Box className={classes.section}>
                {edit && (
                  <AddToolTip
                    title="Edit current boundary's vertices"
                    component={
                      <FormattedButton
                        displayText="Edit Boundary"
                        icon={<EditLocationIcon />}
                        onClick={edit}
                      />
                    }
                  />
                )}

                {reset && (
                  <AddToolTip
                    title="Reset boundary to original one or sliced boundary"
                    component={
                      <FormattedButton
                        displayText="Reset Boundary"
                        onClick={reset}
                      />
                    }
                  />
                )}

                {fillBoundary && (
                  <AddToolTip
                    title="Make current boundary equal to full CLU boundary"
                    component={
                      <FormattedButton
                        displayText="Fill Boundary"
                        onClick={fillBoundary}
                      />
                    }
                  />
                )}
              </Box>
            }

            {/* Clip */}
            {clip && (
              <Box className={classes.section}>
                <AddToolTip
                  title="Attempt to remove parts of underlying boundary intersecting with current boundary"
                  component={
                    <FormattedButton
                      displayText="Clip"
                      // icon={<EditLocationIcon />}
                      onClick={clip}
                    />
                  }
                />
              </Box>
            )}

            {/* Cut and Connect */}
            {((cut && !zonesSliced) || (connect && !zonesSliced)) &&
              <Box className={classes.section}>
                {(cut && !zonesSliced) && (
                  <AddToolTip
                    title="Modify existing boundary to remove a particular section"
                    component={
                      <FormattedButton
                        displayText={<span style={{ marginLeft: 5 }}>Cut</span>}
                        icon={<img src={scissors} width={16} alt="Cut" />}
                        onClick={cut}
                      />
                    }
                  />
                )}

                {(connect && !zonesSliced) && (
                  <AddToolTip
                    title="Modify existing boundary to add a particular section"
                    component={
                      <FormattedButton
                        displayText="Connect"
                        icon={<CallMergeIcon />}
                        onClick={connect}
                      />
                    }
                  />
                )}
              </Box>
            }

            {/* Slices and Draw */}
            {(sliceBoundary || draw) &&
              <Box className={classes.section}>
                {sliceBoundary && (
                  <AddToolTip
                    title="Split existing boundaries. Your first and last vertices must be outside of any existing boundaries."
                    component={
                      <FormattedButton
                        displayText="Slice"
                        icon={<TimelineIcon style={{ marginRight: 4 }}/>}
                        onClick={sliceBoundary}
                      />
                    }
                  />
                )}

                {(draw && sliceBoundary) && (
                  <AddToolTip
                    title="Split out a section from existing boundaries. Your vertices should all be within existing boundaries."
                    component={
                      <FormattedButton
                        displayText="Slice Out"
                        icon={<CropFreeIcon style={{ marginRight: 5 }}/>}
                        onClick={draw}
                      />
                    }
                  />
                )}

                {draw && (
                  <AddToolTip
                    title="Add shape to existing boundaries. Your vertices should all be outside of any existing boundaries."
                    component={
                      <FormattedButton
                        displayText="Draw"
                        icon={<CreateOutlinedIcon style={{ marginRight: 2 }}/>}
                        onClick={draw}
                      />
                    }
                  />
                )}
              </Box>
            }
          </>
        )}

        {/* Cancel button for cut, slice, edit, and draw ops */}
        {(cancel && activeTool !== '') && (
          <Box className={classes.section}>
            <AddToolTip
              title="Cancel current edit"
              component={
                <FormattedButton
                  displayText="Cancel"
                  icon={<CancelOutlinedIcon style={{ marginRight: 2 }}/>}
                  onClick={cancel}
                />
              }
            />
          </Box>
        )}
      </Box>
    </>
  );

  return (
    <Box className={classes.mapControls}>
      {displayControls()}
    </Box>
  );
};

EditBoundary.propTypes = {
  activeTool: PropTypes.string.isRequired,
  edit: PropTypes.func.isRequired,
  finishEdit: PropTypes.func.isRequired,
  fillBoundary: PropTypes.func,
  cut: PropTypes.func,
  toggle: PropTypes.func,
  toggleValue: PropTypes.bool,
  toggleText: PropTypes.string,
  reset: PropTypes.func,
  sliceBoundary: PropTypes.func,
  draw: PropTypes.func,
  cancel: PropTypes.func,
};

EditBoundary.defaultProps = {
  fillBoundary: undefined,
  cut: undefined,
  toggle: undefined,
  toggleValue: undefined,
  toggleText: undefined,
  reset: undefined,
  sliceBoundary: undefined,
  draw: undefined,
  cancel: undefined,
};

import React, { useState, useEffect } from 'react';
import YouTube from 'react-youtube';
import { useWindowDimensions } from '../../../utils/dimensions';
import { checkIsMobile } from '../../../utils/helpers';

/** Renders YouTube video from videoId. Video ID in this case being the last url exentsion.
 *  Example being for UAV promo video, the complete URL is: https://youtu.be/wco8S4KoeBY.
 *  This string to pass to video id would be: "wco8S4KoeBY"
 * @returns {JSX} Embedded YouTube video
 */
export const IntroVideo = () => {
  const { width } = useWindowDimensions();
  const [opts, setOpts] = useState({
    width: 640,
    height: 385,
  });

  useEffect(() => {
    if (checkIsMobile()) {
      const videoWidth = width - 40;
      setOpts({
        width: videoWidth,
        height: videoWidth / 2,
      });
    }
  }, []);

  const onReady = (event) => {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  };

  return (
    <YouTube
      videoId="VIDEO-ID-GOES-HERE"
      opts={opts}
      onReady={onReady}
    />
  );
};

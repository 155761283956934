import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { schemeCategory10 } from "d3-scale-chromatic";
import BaseChart from "../BaseChart";
import ScatterPlot from "./ScatterPlot";
import { CHART_TYPES, COLORS } from "../../../constants";
import Tooltip from "./Tooltip";
import VarietyLegend from "./VarietyLegend";
import BeeswarmContainer from "./BeeswarmContainer";
import { disaggregatePoints } from "../../../utils";
import Header from "./Header";
import Footer from "./Footer";
import ScatterComparePlot from "./ScatterComparePlot";

const useStyles = makeStyles(() => ({
  scatterPlot: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    position: "relative",
  },
  chartContainer: {
    width: "100%",
    height: "600px",
  },
}));

/**
 * Renders the scatterplot container
 * @param {string} type indicates type of chart to show: scatter, beeswarm, etc
 * @param {array} fields array of objects with mock field data
 * @param {object} firstVariableOption object with id, label and active properties
 * @param {object} secondVariableOption object with id, label and active properties
 * @param {object} crop object with id and active properties
 */

const ScatterPlotContainer = ({
  type,
  fields,
  firstVariableOption,
  secondVariableOption,
  xUnit,
  yUnit,
  activeYear,
  selectedCrop,
}) => {
  const chartHeight = [
    CHART_TYPES.Scatter,
    CHART_TYPES.ScatterCompare,
  ].includes(type)
    ? 600
    : 300;
  const classes = useStyles({
    height: chartHeight,
  });
  const [state, setState] = useState({
    activeMarker: null,
    isDisaggregated: false,
  });

  // Once you have the desired data on your field object, you can change the lines below:
  const xAccessor = (d) => d.x; // const xAccessor = (d) => d[`${firstVariableOption.id}`];
  const yAccessor = (d) => d.y; // const yAccessor = (d) => d[`${secondVariableOption.id}`]
  const nameAccessor = (d) => d.name;
  const year = (d) => d.year;
  const cropName = (d) => d.cropName;
  const radius = 5;

  //console.log('colors', schemeCategory10)
  // Get the unique variety names
  const varieties = [
    ...new Set(fields.map((field) => field.varieties.map(nameAccessor)).flat()),
  ].map((variety, i) => ({
    name: variety,
    color: schemeCategory10[i % schemeCategory10.length],
  }));

  // const varieties = Array.from(
  //   new Set(...fields.map((field) => field.varieties.map(nameAccessor)))
  // ).map((variety, i) => ({ name: variety, color: schemeCategory10[i] }));

  // Get the points either disaggregated by variety or keep at field/farm level
  const xs = state.isDisaggregated
    ? disaggregatePoints(varieties, fields, nameAccessor, xAccessor, yAccessor, year, cropName)
    : [
        fields.map((field) => ({
          x: xAccessor(field),
          y: yAccessor(field),
          name: nameAccessor(field),
          year: year(field),
          cropName: cropName(field),
          variety: null,
          color: COLORS.darkGray,
          county: field.county,
        })),
      ];

  //console.log("xs", xs)

  const renderChart = () => {
    switch (type) {
      case CHART_TYPES.Scatter:
        return (
          <BaseChart
            className={classes.chartContainer}
            margin={{
              left: 50,
              right: 25,
              top: 25,
              bottom: 50,
            }}
          >
            <ScatterPlot
              points={xs.flat()}
              setState={setState}
              radius={radius}
              activeMarker={state.activeMarker}
              firstVariableOption={firstVariableOption}
              secondVariableOption={secondVariableOption}
            />
          </BaseChart>
        );
      case CHART_TYPES.Beeswarm:
        return (
          <BeeswarmContainer
            points={xs}
            setState={setState}
            chartHeight={chartHeight}
            radius={radius}
            isDisaggregated={state.isDisaggregated}
            firstVariableOption={secondVariableOption}
            secondVariableOption={firstVariableOption}
            xUnit={xUnit}
            yUnit={yUnit}
          />
        );
      case CHART_TYPES.ScatterCompare:
        return (
          <BaseChart
            className={classes.chartContainer}
            margin={{
              left: 50,
              right: 50,
              top: 25,
              bottom: 50,
            }}
          >
            <ScatterComparePlot
              points={xs.flat()}
              setState={setState}
              radius={radius}
              isDisaggregated={state.isDisaggregated}
              activeMarker={state.activeMarker}
              firstVariableOption={firstVariableOption}
              secondVariableOption={secondVariableOption}
            />
          </BaseChart>
        );
      default:
        return null;
    }
  };

  return (
    <div className={classes.scatterPlot}>
      <Header
        setState={setState}
        state={state}
        xAxis={firstVariableOption.id}
        yAxis={secondVariableOption.id}
        crop={selectedCrop}
        year={activeYear}
        firstVariableOption={firstVariableOption}
        secondVariableOption={secondVariableOption}
      />
      {renderChart()}
      {state.isDisaggregated &&
        [CHART_TYPES.Scatter, CHART_TYPES.ScatterCompare].includes(type) && (
          <VarietyLegend varieties={varieties} />
        )}
      <Footer type={type} />
      {state.activeMarker &&
        [CHART_TYPES.Scatter, CHART_TYPES.ScatterCompare].includes(type) && (
          <Tooltip
            activeMarker={state.activeMarker}
            isDisaggregated={state.isDisaggregated}
            firstVariableOption={firstVariableOption}
            secondVariableOption={secondVariableOption}
            xUnit={xUnit}
            yUnit={yUnit}
          />
        )}
    </div>
  );
};

export default ScatterPlotContainer;

ScatterPlotContainer.propTypes = {
  type: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  firstVariableOption: PropTypes.object.isRequired,
  secondVariableOption: PropTypes.object.isRequired,
  crop: PropTypes.object.isRequired,
};

// -------------------- IMPORTS --------------------
// React
import React, { useEffect, useState } from "react";

// material-ui
import { Box, Button, CircularProgress, Divider, Modal } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Styling and helpful packages
import {
  convertToLocalDatetime,
} from '../helpers';
import { useWindowDimensions } from '../../../utils/dimensions';
import { darkText } from '../../../styles/colors';


// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  body: {
		height: 'auto',
		width: '100%',
		padding: '0 20px',
		fontSize: 16,
		fontWeight: 500,
		color: theme.palette.text.black,
	},
  buttonBox: {
		...theme.buttonBox,
		alignItems: 'center',
		height: '60px',
		marginTop: '24px',
    justifyContent: 'center',
	},
  description: {
		fontSize: 14,
		color: theme.palette.text.grey,
	},
  head: {
		height: '50px',
		padding: '20px',
		alignItems: 'center',
		width: '100%',
		color: darkText,
		fontSize: 20,
		fontWeight: 500,
		display: 'flex',
	},
  icon: {
    ...theme.icon,
  },
  paper: {
		...theme.modalPaper,
		position: 'absolute',
		height: 'auto',
    maxWidth: 500,
	},
  saveStatus: {
    marginLeft: 12,
    marginRight: 12,
  },
}));


// -------------------- MAIN FUNCTION --------------------
/**
 * User will be prompted to decide whether they want to save or load new data. 
 * @param {Function} getFieldsEarliestSavedDate Gets the earliest saved_at value from passed field's operations
 * @param {Function} load Calls the appropriate function to load data for the selected fields and year
 * @param {Boolean} open Determine if modal is opened or closed
 * @param {Array} orderedMappings All the loaded org, farm, & field data
 * @param {Function} save Formats and saves the data to Zones table
 * @param {Boolean} savingReport Whether or not we are currently saving data to Zones table
 * @param {Function} setOpen Sets if modal is open
 * @return {JSX} Modal for choosing
 */
export function ConfirmationPopup({
  getFieldsEarliestSavedDate,
  load,
	open,
  orderedMappings,
  save,
  savingReport,
  setOpen,
}) {
	// -------------------- VARIABLES --------------------
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  // Breakpoints for changing style
  const phoneBreakpoint = 466;

  const [lastSaved, setLastSaved] = useState(-2); 
  const [handleSaveFn, setHandleSaveFn] = useState(0);

	// -------------------- USE EFFECTS --------------------
  // Gets the earliest any of the selected fields' data has been saved
  useEffect(() => {
    const allDates = []
    orderedMappings.forEach(farmInfo => {
      farmInfo[1].forEach(fieldInfo => {
        allDates.push(getFieldsEarliestSavedDate(fieldInfo[1]))
      })
    })
    const chosenDate = allDates.reduce((a, b) => (new Date(a) < new Date(b) ? a : b));

    if (chosenDate) {
      const formattedDate = new Date(chosenDate);
      convertToLocalDatetime(formattedDate);
      setLastSaved(formattedDate);
    } else {
      setLastSaved(-2);
    }
  }, [])
  
  // Handle component unmounting when trying to update state in ConfirmationPopup - only update is component is still mounted
  useEffect(() => {
    if (handleSaveFn) {
      // Track whether component is mounted
      let isMounted = true;

      // Call save function and update lastSaved as appropriate
      save().then(response =>{
        if (isMounted) {
          // Update last saved date based on request response
          if (response && typeof response !== 'string') {
            const savedDate = new Date();
            setLastSaved(savedDate);
          } else {
            setLastSaved(-1);
          }
        }
      });

      return () => {
        // clean up
        isMounted = false;
      };
    }
  }, [handleSaveFn]);


  // -------------------- FUNCTIONALITY --------------------
  const confirmationModal = () => (
    <Modal
			open={open}
			onClose={() => setOpen(false)}
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<Box
				className={classes.paper}
				borderRadius="borderRadius"
			>
				{/* X icon */}
				<Box display="flex" justifyContent="flex-end">
          <HighlightOffIcon
            className={classes.icon}
            style={{ position: 'absolute', marginTop: '10px' }}
            onClick={() => setOpen(false)}
          />
        </Box>

				{/* Modal Title */}
				<Box className={classes.head}>
					Warning: Unsaved Changes
        </Box>

				{/* Modal Body */}
				<Box className={classes.body}>
					<Divider />
          <Box p='8px 16px 8px' mt={1} fontWeight={500} fontSize={16}>
            Some changes have been made since the last save. Loading without saving will cause you to lose your most recent changes.
            <Box className={classes.description} mt={1}>
              Would you like to Save your changes or Load new data?
            </Box>
          </Box>
        </Box>

				{/* Modal Footer */}
        <Box className={classes.buttonBox}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => {
              // To get this to work if user decides to save multiple times in a row, have handleSaveFn be a number instead of a boolean
              setHandleSaveFn(handleSaveFn+1);
            }}
            style={{ margin: "8px" }}
          >
            Save
          </Button>

          <Box className={classes.saveStatus}>
            {savingReport ? (
              <CircularProgress />
            ) : lastSaved === -1 ? (
              'Error on Save. Please try again.'
            ) : lastSaved !== -2 ? (
              <Box fontSize={width > phoneBreakpoint ? "12px" : "10px"}>
                <Box>
                  Last Saved:
                </Box>
                <Box>
                  {lastSaved.toDateString()}
                </Box>
                <Box>
                  {lastSaved.toTimeString()}
                </Box>
              </Box>
            ) : (
              'Data is Not Saved'
            )}
          </Box>

          <Button
            variant="outlined"
            color="primary"
            onClick={() => {
              setOpen(false);
              load();
            }}
            style={{ margin: "8px", backgroundColor: '#ffffff' }}
          >
            Load
          </Button>
        </Box>
      </Box>
		</Modal>
  )

  
	// -------------------- RETURN --------------------
	return (confirmationModal());
}

import * as L from 'leaflet';
import * as turf from '@turf/turf';
import { getStateAndCounty } from './dataFetchers'


export function getPoly(boundary) {
  let polyRe = /\(\((.*?)\)\)/
  return polyRe.exec(boundary)[1].split(',').map(x => x.trim().split(' ')).map(x => [parseFloat(x[0]), parseFloat(x[1])]).filter(x => isGoodCoordinate(x))
}

export function getPolyFromMulti(boundary) {
  /*Get the first polygon from tuple of multipolygons*/
  let multipolygon = boundary.substr(boundary.indexOf(' ')+1)
  let firstPolygon = multipolygon.split('),')[0]
  return firstPolygon.split(',').map(x => x.trim().split(' ')).map(x => [parseFloat(x[0]), parseFloat(x[1])]).filter(x => isGoodCoordinate(x))
}

export function getMultiPoly(boundary) {
  /*take data boundary of multipolygon and return array of arrays of numbers */
  let multipolygon = boundary.substr(boundary.indexOf(' ')+1)
  let polygons = multipolygon.split('),')
  let cleaned = []
  polygons.forEach(function(item){
    cleaned.push(item.split(',').map(x => x.trim().split(' ')).map(x => [parseFloat(x[0]), parseFloat(x[1])]).filter(x => isGoodCoordinate(x)))
  })
  return cleaned
}

function isGoodCoordinate(coordinateArray) {
  /*Check that all coordinates are valid floats after converting from string*/
  let floats = [parseFloat(coordinateArray[0]), parseFloat(coordinateArray[1])]
  if (!Number.isNaN(floats[0]) && !Number.isNaN(floats[1])) {
    return true
  } else {
    return false
  }
}

export function getCentroid(arr) {
  /*Get centroid from array of polygon*/
  var twoTimesSignedArea = 0;
  var cxTimes6SignedArea = 0;
  var cyTimes6SignedArea = 0;

  var length = arr.length

  var x = function (i) { return arr[i % length][0] };
  var y = function (i) { return arr[i % length][1] };

  for ( var i = 0; i < arr.length; i++) {
      var twoSA = x(i)*y(i+1) - x(i+1)*y(i);
      twoTimesSignedArea += twoSA;
      cxTimes6SignedArea += (x(i) + x(i+1)) * twoSA;
      cyTimes6SignedArea += (y(i) + y(i+1)) * twoSA;
  }
  var sixSignedArea = 3 * twoTimesSignedArea;
  return [ cxTimes6SignedArea / sixSignedArea, cyTimes6SignedArea / sixSignedArea];
}

export function convertBoundaryToGeoJson(boundary) {
  /*Convert boundary stored in field data to GeoJSON*/
  var wkt = require('terraformer-wkt-parser');
  try {
    var polygon = wkt.convert(
      {
        "type": "Polygon",
        "coordinates": [boundary]
      }
    );
    //console.log('boundary', boundary)
    //console.log('polygon', polygon)
    var primitive = wkt.parse(polygon);
    let layer = L.GeoJSON.geometryToLayer(primitive)
    return layer.toGeoJSON();
  } catch(err) {
    console.log(err)
    console.log(boundary)
    return {failedConversion: boundary}
  }
}

export function calculateAcreage(points) {
    /*Calculate acreage for user drawn fields. Then get state and county*/
    try {
      if (points[0] !== points[points.length - 1]) {
        const complete = [...points, points[0]] // complete boundary by connecting to first point
        const acres = turf.area(turf.polygon([complete])) / 4047 // convert square meters to acres
        return acres
      } else {
        const acres = turf.area(turf.polygon([points])) / 4047 // convert square meters to acres
        return acres
      }
    } catch(err) {
        console.log('coordinates broken', points)
        console.log('calc err', err)
    }
}

export function calculateClimateAcreage(coordinates) {
  try {
    if (coordinates[0] !== coordinates[coordinates.length - 1]) {
      // if fist and last not equal turf calculation breaks
      const complete = [...coordinates, coordinates[0]] // complete boundary by connecting to first point
      const polygon = turf.polygon([complete])
      const acres = turf.area(polygon) / 4047 // convert square meters to acres
      return acres
    } else {
      const polygon = turf.polygon([coordinates])
      const acres = turf.area(polygon) / 4047 // convert square meters to acres
      return acres
    }
  } catch(err) {
    console.log('calc err', err)
    return 0
  }
}

export async function getLocationData(coordinates) {
  const centroid = getCentroid(coordinates)
  const location = await getStateAndCounty(centroid[1], centroid[0])
  return [...centroid, ...location]
}

import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import {
  TableBody,
  TableContainer,
  Table,
  makeStyles,
} from "@material-ui/core";

import { ascending, groups } from "d3-array";
import { scaleQuantize } from "d3-scale";
import {
  COLOR_RANGE,
  COLORS,
  SORT,
  SORT_LOOKUP,
  NEGATIVE_COLOR_RANGE,
  POSITIVE_COLOR_RANGE
} from "../../../../../../constants";
import Header from "../../../../../Helpers/Table/Header";
import Row from "./ProfitMatrixTableRow";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    overflow: "auto",
  },
  table: {
    // minWidth: "100%",
    color: COLORS.text,
  },
  tableBody: {},
}));

/**
 * Renders the profitability matrix and the handles the sort
 * @param {object} values object with data array and metadata object
 * fetched in the container component
 * @param {function} profitAccessor function to access data in rows
 */

const ProfitabilityMatrixTable = ({
  values,
  profitAccessor = (d) => d.profit,
}) => {
  const classes = useStyles();
  const { data, metadata } = values;

  // Get the header cells based on selected indicators
  const headerCells = [
    {
      id: "year",
      heading: null,
      accessor: (d) => d[0],
    },
  ].concat(
    Array.from(new Set(data.map((d) => d.year)))
      .sort(ascending)
      .map((d) => ({
        id: d,
        heading: d,
        accessor: (f) => {
          const match = f[1].find((g) => g.year === d);
          return match ? profitAccessor(match) : null;
        },
      }))
  );

  //console.log("values profit", values)

  const [state, setState] = useState({
    order: SORT.Asc,
    orderBy: headerCells[0].id,
  });
  const tableRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === SORT.Asc;
    const isSameIndicator = property === state.orderBy;
    setState({
      orderBy: property,
      order: isSameIndicator ? (isAsc ? SORT.Desc : SORT.Asc) : SORT.Asc,
    });
  };

  // Get unique rows
  let rows = groups(data, (d) => d.fieldName);
  // Handle the sort, by the selected header
  const selectedHeader = headerCells.find((d) => d.id === state.orderBy);
  rows = rows.sort((a, b) => {
    const sortFn = SORT_LOOKUP.get(state.order);
    return sortFn(selectedHeader.accessor(a), selectedHeader.accessor(b));
  });

  // console.log("rows", rows)
  // Get profit scale
  const profitScale = scaleQuantize()
    .range(COLOR_RANGE.map((d) => d.backgroundColor))
    .domain([metadata.Min, metadata.Max]);

  const negativeScale = scaleQuantize()
  .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
  .domain([metadata.Min, 0]);

  const positiveScale = scaleQuantize()
  .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
  .domain([0, metadata.Max]);

  // console.log("min, max", metadata.Min, metadata.Max)
  // console.log("profitScale", profitScale);

  return (
    <TableContainer id="heat-map-table" className={classes.container}>
      <Table className={classes.table} ref={tableRef}>
        <Header
          order={state.order}
          orderBy={state.orderBy}
          onRequestSort={handleRequestSort}
          headerCells={headerCells}
          showBorderCells={false}
          center
        />
        <TableBody className={classes.tableBody}>
          {rows.map((row, i) => (
            <Row
              key={`heat-map-table-row-for-${i}`}
              row={row}
              headerCells={headerCells}
              profitScale={profitScale}
              negativeScale={negativeScale}
              positiveScale={positiveScale}
            />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProfitabilityMatrixTable;

ProfitabilityMatrixTable.propTypes = {
  values: PropTypes.object.isRequired,
  profitAccessor: PropTypes.func.isRequired,
};

import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Checkbox, Typography, Button, TextField, Switch, IconButton, CircularProgress,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import ClearIcon from '@material-ui/icons/Clear';
import SignatureCanvas from 'react-signature-canvas';
import { useSnackbar } from 'notistack';
import { checkIsMobile, getImage } from '../../../utils/helpers';

/**
 * User can digitally sign, print name, and check to confirm required consent
 * @param {bool} checked Consent checked
 * @param {funtion} setChecked Set consent checked
 * @param {string} printedName User name in plaint text for use along with digital signature
 * @param {function} setPrintedName Store user name in plain text
 * @param {function} setSigned All conditions have been met for valid signature
 * @param {function} setSignature Store base64 png of digital signature
 * @param {function} setSignatureTimeStamp Time user digitally signed
 * @returns {JSX} Consent Form
 */
export const Consent = ({
  checked,
  setChecked,
  printedName,
  setPrintedName,
  setSigned,
  setSignatureImage,
  setSignatureTimeStamp,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const sigCanvas = useRef(null);

  // Default to signature pad if on mobile
  const [signByPad, setSignByPad] = useState(checkIsMobile() || false);
  const [signature, setSignature] = useState('');

  // Awating image
  const [loading, setLoading] = useState(false);

  // Missing input highlights
  const [highlighted, setHighlight] = useState(false);
  const [printNameHighlighted, setPrintNameHighlighted] = useState(false);
  const [highlightCheckbox, setHighlightCheckbox] = useState(false);

  const handleSign = async () => {
    try {
      setLoading(true);
      const image = await getImage('digital-signature');
      if (image) {
        setSigned(true);
        setSignatureImage(image);
        setSignatureTimeStamp(new Date());
      }
    } catch (err) {
      enqueueSnackbar('Sorry, we encounted an error storing your digital signature. Please try to digitally sign again or contact us for assistance');
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const checkValid = () => {
    let valid = true;
    if (printedName.length < 1) {
      setPrintNameHighlighted(true);
      enqueueSnackbar('Please print your name in the highlighted text input');
      valid = false;
    }

    if (!checked) {
      setHighlightCheckbox(true);
      enqueueSnackbar('Please check the highlighted box to confirm your consent to terms');
      valid = false;
    }

    if (signByPad && sigCanvas.current.isEmpty()) {
      enqueueSnackbar('Please use the signature pad to sign your name');
      valid = false;
    }

    if (!signByPad && signature.length === 0) {
      setHighlight(true);
      enqueueSnackbar('Please type your name in the highlighted box to generate a digital signature');
      valid = false;
    }

    if (valid) {
      handleSign();
    }
  };

  const handleSignatureText = (val) => {
    if (highlighted) {
      // Turn off when no signature error when enter value
      setHighlight(false);
    }
    setSignature(val);
  };

  const handlePrintedName = (val) => {
    if (printNameHighlighted) {
      setPrintNameHighlighted(false);
    }
    setPrintedName(val);
  };

  const handleCheck = (e) => {
    setChecked(e.target.checked);

    if (e.target.checked) {
      setHighlightCheckbox(false);
    }
  };

  const clear = () => {
    try {
      if (!sigCanvas.current.isEmpty()) {
        sigCanvas.current.clear();
      }
    } catch (err) {
      console.error(err);
    }
  };

  return (
    <Box px={0.5} display="flex" alignItems="center" flexDirection="column">
      <Box display="flex" alignItems="center" flexDirection="column">
        <Box display="flex" alignItems="center">
          <Typography>
            Type to Digitally Sign
          </Typography>
          <Switch
            color="primary"
            checked={signByPad}
            onChange={() => setSignByPad(!signByPad)}
          />
          <Typography>
            Sign by signature pad
          </Typography>
        </Box>

        { signByPad ? (
          <Box mt="10px">
            <Box
              bgcolor="#FAF9F6"
              id="digital-signature"
            >
              <SignatureCanvas
                penColor="black"
                canvasProps={{ width: 380, height: 80, className: 'sigCanvas' }}
                ref={(ref) => { sigCanvas.current = ref; }}
              />
            </Box>
            <IconButton
              id="clear-signature-pad"
              variant="outlined"
              onClick={() => clear()}
              size="small"
              style={{ position: 'relative', bottom: 80, left: 348 }}
            >
              <ClearIcon />
            </IconButton>
          </Box>
        ) : (
          <Box height={80} display="flex" alignItems="center" mt="10px">
            <TextField
              style={{ width: 380, backgroundColor: '#FAF9F6' }}
              label="Type your name to digitally sign"
              error={highlighted}
              id="digital-signature"
              variant="outlined"
              value={signature}
              onChange={(e) => handleSignatureText(e.target.value)}
              inputProps={{
                style: { fontFamily: 'Allura-Regular', fontSize: 28 },
              }}
            />
          </Box>
        )}

        <Box mt={signByPad ? '-16px' : '20px'}>
          <TextField
            style={{ width: 380, backgroundColor: '#FAF9F6' }}
            label="Print your name to digitally sign"
            error={printNameHighlighted}
            variant="outlined"
            value={printedName}
            onChange={(e) => handlePrintedName(e.target.value)}
          />
        </Box>

        <Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" height={80}>
          { !loading ? (
            <Button
              color="primary"
              variant="contained"
              onClick={checkValid}
              style={{ marginTop: 8 }}
              disableElevation
            >
              Digitally Sign
            </Button>
          ) : (
            <CircularProgress />
          )}
        </Box>

        <Box
          py="2px"
          display="flex"
          alignItems="flex-start"
          style={highlightCheckbox ? { border: '1px solid red', borderRadius: 4 } : {}}
        >
          <Checkbox
            checked={checked}
            icon={<CheckBoxOutlineBlankIcon color="primary" size="large" />}
            checkedIcon={<CheckBoxIcon color="primary" size="large" />}
            onChange={handleCheck}
          />
          <Box width={420}>
            <Typography>
              The parties agree that this agreement may be electronically signed and electronic
              signatures are the same as handwritten signatures for the purposes of validity and
              enforcability. By digitially signing you are consenting to terms outlined below:
            </Typography>
          </Box>
        </Box>
      </Box>

      <Box mt={0.5} p={1} maxWidth={462} maxHeight={205} border={1} borderRadius={4} style={{ overflowY: 'auto' }}>
        <Typography gutterBottom>
          You are accessing a U.S. Government information system, which includes (1) this computer
          network, (2) all computers connected to this network, and (3) all devices and storage
          media attached to this network or to a computer on this network. This information system
          is provided for U.S. Government-authorized use only.
        </Typography>
        <Typography gutterBottom>
          Unauthorized or improper use of this system may result in disciplinary action,
          as well as civil and criminal penalties.
        </Typography>
        <Typography gutterBottom>
          By using this information system, you understand and consent to the following:
        </Typography>
        <Typography gutterBottom>
          You have no reasonable expectation of privacy regarding any communications or data
          transiting or stored on this information system. At any time, the government may for
          any lawful government purpose monitor, intercept, and search and seize any
          communication or data transiting or stored on this information system.
        </Typography>
        <Typography gutterBottom>
          Any communication or data transiting or stored on this information system may be
          disclosed or used for any lawful government purpose.
        </Typography>
        <Typography gutterBottom>
          Your consent is final and irrevocable. You may not rely on any statements or informal
          policies purporting to provide you with and expectation of privacy regarding communication
          on this system, whether oral or written, by your supervisor or any other official, except
          USDA’s Chief Information Officer.
        </Typography>
      </Box>

    </Box>
  );
};

Consent.propTypes = {
  checked: PropTypes.bool.isRequired,
  setChecked: PropTypes.func.isRequired,
  printedName: PropTypes.string.isRequired,
  setPrintedName: PropTypes.func.isRequired,
  setSigned: PropTypes.func.isRequired,
  setSignatureImage: PropTypes.func.isRequired,
  setSignatureTimeStamp: PropTypes.func.isRequired,
};

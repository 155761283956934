/* eslint-disable max-len */
/* eslint-disable no-nested-ternary */
// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  Box, Select, MenuItem, TextField, Radio,
} from '@material-ui/core';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { grey } from '../../../styles/colors';
import { MenuProps } from '../../../styles/select';

// Functionality
import { NumberFormatRMAArea } from '../../../utils/NumberFormatCustom';
import { useSnackbar } from 'notistack';

// Advanced Options
import {
  cartDropdownData,
  additionalTextInputs,
  radioInputs,
} from '../presetData';

// -------------------- STYLING --------------------

const useStyles = makeStyles((theme) => ({
  cluElements: {
    padding: '8px',
    paddingBottom: '0px',
    height: '75.2px',
  },
  icon: theme.plIcon,
  label: {
    fontSize: '.9rem',
    fontWeight: 500,
    // color: theme.palette.text.secondary,
  },
  textInput: {
    height: 44.6,
    width: 120,
    backgroundColor: '#ffffff',
  },
  selects: {
    height: 44.6,
    width: 180,
    maxWidth: 200,
    backgroundColor: '#ffffff',
  },
}));

// -------------------- MAIN FUNCTION --------------------
/**
 * Creates CLU row for a specific farm, field, and operation with editable data
 * @param {Array} additionalCluOptions Keeps tracks of the elements that need to be shown in each CLU row
 * @param {Object} cluRow CLU data
 * @param {Number} cluIndex Index of CLU in orderedMappings state array
 * @param {Object} clusSeen reference for CLU information
 * @param {Object} commoditiesSeen key:value mappings of commodities seen and their associated commodity types
 * @param {Object} commodityTypesSeen key:value mappings of commodity types seen and their associated intended uses
 * @param {Number} fieldIndex Index of field in orderedMappings state array
 * @param {Number} farmIndex Index of farm in orderedMappings state array
 * @param {Number} opIndex Index of operation in orderedMappings state array
 * @param {Object} operationInfo Operation data, including dropdown items
 * @param {Object} statesSeen key:value mappings of states seen and their associated commodities
 * @param {Function} updateIntersection Updates some elements for a CLU intersection
 * @returns {JSX} Farm's table
 */
export function CreateCLURow({
  additionalCluOptions,
  cluRow,
  cluIndex,
  clusSeen,
  commoditiesSeen,
  commodityTypesSeen,
  fieldIndex,
  farmIndex,
  opIndex,
  operationInfo,
  statesSeen,
  updateIntersection,
}) {
  // -------------------- VARIABLES --------------------
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Indexes of CLU for calling updateIntersection
  const indexes = {
    farmIndex,
    fieldIndex,
    opIndex,
    cluIndex,
  };
  // Store micsName for CLU
  // Needed as it is a Textfield without an associated NumberFormat component so it needs an onChange...
  // and don't want to have to update all of orderedMappings each time the user presses a character
  // so split off createCLURow into its own component.
  // This is all stored in orderedMappings on onBlur call
  const [micsName, setMicsName] = useState('');

  // Pass down the current micsName value from operation whenever its set/changes
  useEffect(() => {
    setMicsName(cluRow.micsName);
  }, [cluRow.micsName]);

  // Handles making sure that user entered a valid string for MICS Name element
  const handleMicsName = (value) => {
    if (value === '') {
      enqueueSnackbar(`MICS Name cannot be blank.`);
    }
    else {
      setMicsName(value);
    }
  }

  // Returns the JSX to display the row
  const displayRow = () => {

    return (
      <Box
        display="flex"
        alignItems="center"
        flexWrap="wrap"
        bgcolor="#f2f2f2"
        width="100%"
        style={{ borderBottom: `1px solid ${grey}` }}
      >
        {additionalCluOptions.includes('acres') && (
          <Box className={classes.cluElements}>
            <Box className={classes.label}>Acres</Box>
            <TextField
              className={classes.textInput}
              variant="outlined"
              value={
                cluRow?.finalReportedAcreage
                  ? cluRow.finalReportedAcreage
                  : cluRow.acres
              }
              // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
              onBlur={(e) => updateIntersection(
                [ { finalReportedAcreage: Number(e.target.value.replaceAll(',', '')) } ],
                indexes,
              )}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatRMAArea,
              }}
            />
          </Box>
        )}

        {additionalCluOptions.includes('crop') && (
          <Box className={classes.cluElements}>
            <Box className={classes.label}>Crop</Box>
            <Select
              labelId="crop-selection-label"
              label="Crop"
              variant="outlined"
              MenuProps={MenuProps}
              value={cluRow.crop}
              className={classes.selects}
              onChange={(e) => {
                updateIntersection([{ crop: e.target.value }], indexes);
              }}
            >
              {statesSeen[operationInfo.crops]?.map((x) => (
                <MenuItem key={x.commodityCode} value={x.commodityCode}>
                  {x.displayValue}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}

        {additionalCluOptions.includes('cropType') && (
          <Box className={classes.cluElements}>
            <Box className={classes.label}>Crop Type</Box>
            <Select
              labelId="type-selection-label"
              label="Type"
              variant="outlined"
              MenuProps={MenuProps}
              value={cluRow.cropType}
              className={classes.selects}
              onChange={(e) => {
                updateIntersection([{ cropType: e.target.value }], indexes);
              }}
            >
              {commoditiesSeen[cluRow.cropTypes]?.map((x) => (
                <MenuItem key={x.commodityTypeCode} value={x.commodityTypeCode}>
                  {x.displayValue}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}

        {additionalCluOptions.includes('intendedUse') && (
          <Box className={classes.cluElements}>
            <Box className={classes.label}>Intended Use</Box>
            <Select
              labelId="intendedUse-selection-label"
              label="Intended Use"
              variant="outlined"
              MenuProps={MenuProps}
              value={cluRow.intendedUse}
              className={classes.selects}
              onChange={(e) => {
                updateIntersection([{ intendedUse: e.target.value }], indexes);
              }}
            >
              {commodityTypesSeen[cluRow.intendedUses]?.map((x) => (
                <MenuItem key={x.intendedUseCode} value={x.intendedUseCode}>
                  {x.displayValue}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}

        {additionalCluOptions.includes('sharePercentage') && (
          <Box className={classes.cluElements}>
            <Box className={classes.label}>Share %</Box>
            <TextField
              className={classes.textInput}
              variant="outlined"
              value={cluRow.sharePercentage}
              // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
              onBlur={(e) => updateIntersection(
                [
                  {
                    sharePercentage: Number(e.target.value.replaceAll(',', '')),
                  },
                ],
                indexes,
              )}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatRMAArea,
                endAdornment: '%',
              }}
            />
          </Box>
        )}

        {additionalCluOptions?.length > 0
          && additionalCluOptions.map(
            // This is used to map any dropdown style items that need to be added
            // eslint-disable-next-line no-nested-ternary
            (addition) => (Object.prototype.hasOwnProperty.call(cartDropdownData, addition) ? (
              <Box className={classes.cluElements} key={addition}>
                <Box className={classes.label}>
                  {cartDropdownData[addition].label}
                </Box>
                <Select
                  displayEmpty
                  variant="outlined"
                  labelId="demo-simple-select-placeholder-label-label"
                  id="demo-simple-select-placeholder-label"
                  MenuProps={MenuProps}
                  value={
                    cluRow[cartDropdownData[addition].updateKey]
                      ? cluRow[cartDropdownData[addition].updateKey]
                      : ''
                  }
                  className={classes.selects}
                  onChange={(e) => updateIntersection(
                    [
                      {
                        [cartDropdownData[addition].updateKey]:
                              e.target.value,
                      },
                    ],
                    indexes,
                  )}
                >
                  {cartDropdownData[addition].options.map((op) => (
                    <MenuItem key={op.code} value={op.code}>
                      {op.display}
                    </MenuItem>
                  ))}
                </Select>
              </Box>
            ) 
            // This is used for any text input items that need to be added
            : Object.prototype.hasOwnProperty.call(
              additionalTextInputs,
              addition,
            ) ? (
              <Box className={classes.cluElements} key={addition}>
                <Box className={classes.label}>
                  {additionalTextInputs[addition].label}
                </Box>
                <TextField
                  className={classes.textInput}
                  variant="outlined"
                  inputProps={{
                    style: {
                      padding: 12,
                      height: 20.6,
                    },
                  }}
                  // eslint-disable-next-line react/jsx-no-duplicate-props
                  InputProps={{
                    inputComponent: additionalTextInputs[addition].inputComponent,
                    endAdornment: additionalTextInputs[addition].endAdornment,
                  }}
                  value={
                    // Handle micsName differently as has no component inputComponent (as is text not number)
                    addition === 'micsName'
                      ? micsName
                      : cluRow[additionalTextInputs[addition].updateKey]
                        ? cluRow[additionalTextInputs[addition].updateKey]
                        : ''
                  }
                  onChange={(e) => (addition === 'micsName' ? handleMicsName(e.target.value) : '')}
                  onBlur={(e) => updateIntersection(
                    [
                      {
                        [additionalTextInputs[addition].updateKey]:
                            e.target.value,
                      },
                    ],
                    indexes,
                  )}
                  disabled={
                    // Ignore for micsName
                    addition === 'micsName'
                      ? false
                      : // If it is a width, handle differently, else just check what disableElements is
                      additionalTextInputs[addition].exception
                        ? !(
                          cluRow.disableElements === 'butWidth'
                          || !cluRow.disableElements
                        )
                        : !!cluRow.disableElements
                  }
                />
              </Box>
            ) 
            // This is used to map any radio inputs that need to be added
            : Object.prototype.hasOwnProperty.call(radioInputs, addition) ? (
              <Box
                key={addition}
                className={classes.cluElements}
                display="flex"
                flexDirection="column"
                textAlign="start"
              >
                <Box className={classes.label}>
                  {radioInputs[addition].title}
                </Box>
                <Box
                  display="flex"
                  py={1}
                  pr={0.5}
                  mb={1}
                  border={1}
                  borderColor="rgba(0, 0, 0, 0.23)"
                  borderRadius={4}
                  justifyContent="space-around"
                  style={{ backgroundColor: '#ffffff' }}
                >
                  <Box display="flex" alignItems="center">
                    <Radio
                      color="primary"
                      checked={
                        addition === 'reviewRequested'
                          ? clusSeen[cluRow.clu_identifier].cluProducerReviewRequestIndicator === radioInputs[addition].options[0].code
                          : cluRow[radioInputs[addition].updateKey]
                            === radioInputs[addition].options[0].code
                      }
                      onChange={() => updateIntersection(
                        [
                          {
                            [radioInputs[addition].updateKey]:
                              radioInputs[addition].options[0].code,
                          },
                        ],
                        indexes,
                      )}
                      value="a"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'I' }}
                      style={{ padding: '0 4px' }}
                    />
                    <Box>{radioInputs[addition].options[0].display}</Box>
                  </Box>
                  <Box display="flex" alignItems="center">
                    <Radio
                      color="primary"
                      checked={
                        addition === 'reviewRequested'
                          ? clusSeen[cluRow.clu_identifier].cluProducerReviewRequestIndicator === radioInputs[addition].options[1].code
                          : cluRow[radioInputs[addition].updateKey]
                            === radioInputs[addition].options[1].code
                      }
                      onChange={() => updateIntersection(
                        [
                          {
                            [radioInputs[addition].updateKey]:
                              radioInputs[addition].options[1].code,
                          },
                        ],
                        indexes,
                      )}
                      value="a"
                      name="radio-button-demo"
                      inputProps={{ 'aria-label': 'N' }}
                      style={{ padding: '0 4px' }}
                    />
                    <Box>{radioInputs[addition].options[1].display}</Box>
                  </Box>
                </Box>
              </Box>
            ) : (
              ''
            )),
          )}
      </Box>
    )
  }

  return ( displayRow() );
}

CreateCLURow.propTypes = {
  additionalCluOptions: PropTypes.array.isRequired,
  cluRow: PropTypes.object.isRequired,
  cluIndex: PropTypes.number.isRequired,
  clusSeen: PropTypes.object.isRequired,
  commoditiesSeen: PropTypes.object.isRequired,
  commodityTypesSeen: PropTypes.object.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  farmIndex: PropTypes.number.isRequired,
  opIndex: PropTypes.number.isRequired,
  operationInfo: PropTypes.object.isRequired,
  statesSeen: PropTypes.object.isRequired,
  updateIntersection: PropTypes.func.isRequired,
};

import React, { useState, useEffect, useContext } from 'react';

import { UserContext } from './UserContext';

import { cloneDeep } from 'lodash';
import * as turf from "@turf/turf";
import * as wkt from "terraformer-wkt-parser";
import { useSnackbar } from "notistack";

const FieldContext = React.createContext([{}, () => {}]);

/**
 * When load from storage, check that email matches user.
 * Nulls/undefines will work for non-logged in.
 * If some how we load a selected field for a different user,
 * clear it from local storage rather than loading.
 * For fieldsToClaim, we don't want this check, since a non-logged in user
 * can start field claim process, log in, then claim.
 * @param {Object} props cpntains props to be passed to FieldProvider
 * @returns {JSX} FieldContext.Provider
 */
const FieldProvider = (props) => {
  const { enqueueSnackbar } = useSnackbar();

  const [fieldData, setFieldData] = useState({
    fields: [],
    selectedField: {
      id: '', name: '', farmId: '', farm: '', orgId: '', org: '', boundaryId: '', clientId: '', client: '',
    },
    fieldToClaim: {
      feature: null, geometry: {}, coordinates: [], points: [], boundary: {}, acres: 0,
    },
    farms: []
  });

  const user = useContext(UserContext)[0];

  useEffect(() => {
    try {
      // Get and parse if fields are found in storage
      const storedField = localStorage.getItem('selectedField');
      let parsedField = JSON.parse(storedField);

      const storedClaim = localStorage.getItem('fieldToClaim');
      let parsedClaim = JSON.parse(storedClaim);

      const storedFields = localStorage.getItem('fields');
      const parsedFields = JSON.parse(storedFields) !== null ? JSON.parse(storedFields) : [];
      //console.log("parsedFields", parsedFields)

      const storedFarms = localStorage.getItem('farms');
      //console.log("storedFarms", storedFarms)
      const parsedFarms = JSON.parse(storedFarms) !== null ? JSON.parse(storedFarms) : [];
      //console.log("parsedFarms", parsedFarms)

      // Proceed differently if some data is being passed
      const url = document.URL.split('?');
      if (url?.length > 1) {
        // split url should never be more than 1 unless we put 2 '?' in url
        // if that happens take the first paramatr or loop?
        const extension = url[1];

        // if url contains ? and token, we don't want to set field data
        if (extension.includes('token')) {
          return;
        } else if (extension.includes('AOI')) {
          // Only do this for DataLayers
          if (!url[0].toLowerCase().includes('datalayers')) return;

          // Get AOI information
          const urlParams = new URLSearchParams(extension);
          const aoi = urlParams.get('AOI');
          console.log('aoi :>> ', aoi);
          let shape;
          try {
            shape = wkt.parse(aoi);
          } catch (err) {
            enqueueSnackbar('Your AOI is not valid. Please pass a valid WKT.')
            return;
          }
          const feature = turf.polygon(shape.coordinates)
          // console.log('feature :>> ', feature);
          const centroid = turf.centroid(feature);
          const latitude = centroid.geometry.coordinates[1];
          const longitude = centroid.geometry.coordinates[0];
          const acres = turf.convertArea(
            turf.area(feature),
            "meters",
            "acres"
          );

          // Ignore any previously stored selected field
          parsedField = null;
          parsedClaim = {
            acres,
            boundary: JSON.stringify(feature),
            // coordinates:,
            // county:,
            feature,
            geometry: shape,
            latitude,
            longitude,
            // name:,
            points: shape.coordinates,
            // selectedAt:,
            // state:,
          };
        }
      }
      // console.log('parsedClaim :>> ', parsedClaim);

      if (Object.prototype.hasOwnProperty.call(user, 'isAuthenticated')) {
        // Wait for user context to be loaded to we don't do any of this twice
        const data = cloneDeep(fieldData);
        let field;
        let drawnField;

        if (parsedField?.boundary) {
          if (parsedField?.user === user?.email) {
          // Set field if field was found in storage and matches user
            field = parsedField;
          } else if (Object.prototype.hasOwnProperty.call(user, 'email')) {
          // If field does not belong to logged in user, remove it
            localStorage.removeItem('selectedField');
          }
        }

        // Dont check for user here since we want a non-logged in user to be able to start claim
        if (parsedClaim?.feature) {
          drawnField = parsedClaim;
        }

        if (field && drawnField) {
          // Both exists, so take most recent
          const selectedTime = new Date(field.selectedAt);
          const claimedTime = new Date(drawnField.selectedAt);

          if (selectedTime.getTime() > claimedTime.getTime()) {
            data.selectedField = field;
          } else {
            data.fieldToClaim = drawnField;
          }
        } else if (field) {
          data.selectedField = field;
        } else if (drawnField) {
          data.fieldToClaim = drawnField;
        }

        data.farms = parsedFarms;
        data.fields = parsedFields;
        data.hasLoaded = true;

        setFieldData(data);
      }
    } catch (err) {
      console.error(err);
    }
  }, [user]);

  useEffect(() => {
    //console.log("fieldData", fieldData)
    if (fieldData?.selectedField?.boundary) {
      localStorage.setItem(
        'selectedField',
        JSON.stringify({ ...fieldData.selectedField, user: user?.email, selectedAt: new Date() }),
      );
    }

    if (fieldData?.fieldToClaim?.feature) {
      localStorage.setItem('fieldToClaim', JSON.stringify({ ...fieldData.fieldToClaim, selectedAt: new Date() }));
    }

    if (fieldData?.fields) {
      try{
        localStorage.setItem('fields', JSON.stringify(fieldData.fields));  
      }
      catch(err){
        console.log("Could not set fields in localstorage", err)
      }
      
    }

    if (fieldData?.farms) {
      localStorage.setItem('farms', JSON.stringify(fieldData.farms));
    }
  }, [fieldData]);

  return (
    <FieldContext.Provider value={[fieldData, setFieldData]}>
      {props.children}
    </FieldContext.Provider>
  );
};

export { FieldContext, FieldProvider };

import React, { useEffect, useState, useRef } from "react";
import * as L from "leaflet";
import * as turf from "@turf/turf";
import * as _ from "lodash";
import "@geoman-io/leaflet-geoman-free";
import "@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css";
import "leaflet-path-transform";
import { GeoSearchControl, OpenStreetMapProvider } from "leaflet-geosearch";
import "./leaflet.css";
import { exists } from "../../utils/helpers";
import {Tiles} from '../../constants/Tiles'


export function DisplayMap({
  boundary,
  selectField,
  mapImage,
  isProfitMap,
  mapExtents,
  containerHeight,
  containerWidth,
  maxZoom
}) {
  /*
    Map used simply for image overlays. Example uses are Profit Map and UAV Reports.
    Current version likely retains more functionality than will be necessary.
   */
  //console.log("mapExtents", mapExtents)
  const [map, setMap] = useState(null);
  const imageOverlay = useRef(null);
  const [newPoint, setNewPoint] = useState([]);
  const [selectedField, setSelectedField] = useState({ feature: null });

  const fieldLayer = L.featureGroup(null);

  useEffect(() => {
    //console.log("In Display Map");
    drawMap();
  }, []);


  useEffect(() => {
    if (exists(boundary) && map !== null) {
      //console.log("Adding field boundary");
      const features =
        typeof boundary === "object" ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: "FeatureCollection",
        features: [features],
      };
      drawFieldLayer(geoJson);
      if (isProfitMap === undefined) {
        fieldLayer.on("click", function () {
          selectField();
        });
      }
    }
  }, [boundary, map]);


  useEffect(() => {
    if (exists([map, boundary]) && mapImage !== undefined && mapImage !== null) {
      //console.log(boundary, mapImage)
      const features =
        typeof boundary === "object" ? boundary : JSON.parse(boundary);
      const bounds =
        mapExtents.length === 0
          ? features.geometry.coordinates[0].map((x) => [x[1], x[0]])
          : mapExtents;
      //const bounds = mapExtents;
      //console.log("bounds", bounds);
      if (imageOverlay.current === null) {
        imageOverlay.current = L.imageOverlay(mapImage, bounds, {
          opacity: 0.8,
          interactive: true,
        });
        if (isProfitMap === undefined) {
          imageOverlay.current.on("click", function (d) {
            selectField();
          });
        }
        imageOverlay.current.addTo(map);
      } else {
        imageOverlay.current.setUrl(mapImage);
        imageOverlay.current.setBounds(bounds);
      }
    }
  }, [mapImage, boundary, map]);

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map("map", {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current']
    }).addTo(map);

    function getGeom() {
      var bounds = map.getBounds();
      var max = bounds.getNorthEast();
      var min = bounds.getSouthWest();

      var geomParam = {
        xmin: min.lng,
        ymin: min.lat,
        xmax: max.lng,
        ymax: max.lat,
        spatialReference: { wkid: 4326 },
      };
      return geomParam;
    }
    if(maxZoom){
      map.setMaxZoom(maxZoom)
    }

    setMap(map);
    map.addLayer(fieldLayer);
  }

  function drawFieldLayer(geo) {
    try {
      const geometry = geo.features[0].geometry;
      const boundary =
        geometry.type === "Polygon"
          ? turf.polygon(geo.features[0].geometry.coordinates)
          : turf.multiPolygon(geo.features[0].geometry.coordinates);
      let newCollection = L.geoJson(boundary, {
        onEachFeature: (feature, layer) => {
          fieldLayer.addLayer(layer).setStyle({fillOpacity: 0});
        },
      });
      map.fitBounds(newCollection.getBounds());
      newCollection.addTo(map);
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <div
      id="map"
      style={{
        height: containerHeight !== undefined ? containerHeight : "100%",
        width: containerWidth !== undefined ? containerWidth : "100%",
        minWidth: 400,
      }}
    >

    </div>
  )

}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Select, MenuItem, Divider, CircularProgress, Slide, Fade,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MenuProps } from '../../../styles/select';
import { TopographyMap } from './TopographyMap';
import { createYearArray, numFormat, exists } from '../../../utils/helpers';
import { blackText, grey, lightGrey } from '../../../styles/colors';
import { useWindowDimensions } from '../../../utils/dimensions';

import { SpinningLoader } from '../../Shared/SpinningLoader';
import { GetWaterManagement, VisitApimElevation } from '../../Shared/ServiceLinks';
import { CustomToolTip } from '../../../utils/customComponents';
import { ImageSwitch } from '../Shared/ImageSwitch'

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  messageBox: theme.messageBox,
  errorMessageBox: theme.errorMessageBox,
}));

export function Topography({
  field,
  loading,
  getElevationIndex,
  image,
  legend,
  extent,
  elevationIndexSelection,
  selectedElevation,
  setSelectedElevation,
  mobileBreakPoint,
  errorMessage,
}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  const [showImagery, setShowImagery] = useState(true);

  const [error, setError] = useState(false);
  const [retryAttempts, setRetryAttempts] = useState(0)

  const tooltips = {
    Relative_Elevation: 'Shows elevation relative to the field average. Derived by '
          + 'calculating the z- score, or difference from the average, '
          + 'for every point on the field',
    Elevationmap: 'Elevation from the USGS Digital Elevation Model',
    Slope: 'Shows the maximum gradient or steepness of the field',
    TPI: 'Shows the elevation extremes - points of maximum and minimum elevation where '
               + 'water might pool or run off',
    TRI: 'Shows how rough a given area is in terms of local hilliness and steepness of gradients',
  };

  useEffect(() => {
    if (
      errorMessage !== undefined &&
      errorMessage !== '' &&
      retryAttempts < 1
    ) {
      // If encounter error, try request with different index
      // Currently we only re-try once, but can be updated
      // If we want to try all options, can make retry array
      const newElevation = elevationIndexSelection.filter(x => x.value !== selectedElevation.value)
      if (newElevation.length > 0) {
        // If we found elevations that havent been tried, get data for next in array
        setSelectedElevation(newElevation[0])
        getData({index: newElevation[0].value})
        setRetryAttempts((prev) => prev + 1)
      }
    }
  }, [errorMessage])

  const getData = async (value) => {
    const success = await getElevationIndex(value);
  };

  const handleChange = (e) => {
    for (let i = 0; i < elevationIndexSelection.length; i++) {
      if (e.target.value === elevationIndexSelection[i].value) {
        setSelectedElevation(elevationIndexSelection[i]);
      }
    }
    getData(e.target.value);
  };

  const handleSwitch = (checked) => {
    setShowImagery(checked);
  };

  const createTable = () => {
    const unit = selectedElevation.unit === 'ft' ? 'Feet' : selectedElevation.unit;
    const roundTo = selectedElevation.value === 'Elevationmap' ? 2 : 5;
    return (
      <Box
        m={1}
        border={1}
        borderColor={grey}
        borderRadius="borderRadius"
        style={{ minWidth: 300, maxWidth: '1000px', overflowX: 'auto' }}
      >
        <Box p={1} display="flex" justifyContent="space-between" fontSize={16}>

          <Box display="flex" style={{ width: '60%' }}>
            {unit}
          </Box>
          <Box ml={2} style={{ width: '40%' }}>
            % of Total
          </Box>

        </Box>

        <Divider style={{ color: grey, height: 4 }} />
        {
          legend.map((x, i) => createRow(x, i, roundTo))
        }
      </Box>
    );
  };

  const createRow = (x, i, roundTo) => (
    <Box
      key={i}
      p={1}
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      style={{ backgroundColor: i % 2 == 0 ? '#ffffff' : lightGrey }}
    >
      <Box display="flex" alignItems="center" style={{ width: '60%' }}>
        <FiberManualRecordIcon
          style={{ color: x.color }}
        />
        <Typography nowrap="true" style={{ fontWeight: 500, fontSize: 14, whiteSpace: 'nowrap' }}>
          {` ${numFormat(x.min, 0, roundTo)} - ${numFormat(x.max, 0, roundTo)}`}
        </Typography>
        {/* {Math.round(x.min * 100000) / 100000} - {Math.round(x.max * 100000) / 100000} */}
      </Box>

      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        ml={2}
        style={{ width: '40%' }}
      >
        {x.area.replace(/\s/g, '')}
      </Box>

    </Box>
  );

  const controls = () => (
    <Box display='flex' alignItems='center' flexWrap='wrap'>
      <Box mx={1}>
        <Box display="flex" alignItems="center">
          <Box>
            Select Elevation Index
          </Box>

          <CustomToolTip
            title={tooltips[selectedElevation.value]}
            placement="right"
          >
            <InfoOutlinedIcon
              style={{ fontSize: '16px', color: grey, margin: '0 0 4px 6px' }}
            />
          </CustomToolTip>
        </Box>
        <Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={selectedElevation.value}
            onChange={(e) => { handleChange(e); }}
          >
            {
              elevationIndexSelection.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x.value}
                >
                  {x.name}
                </MenuItem>
              ))
            }
          </Select>
        </Box>
      </Box>

      <ImageSwitch
        checked={showImagery}
        handleSwitch={handleSwitch}
      />
    </Box>
  );

  return (
    <Slide direction="right" in>
      <Box color={blackText} fontWeight={500}>
        <Box mt={1} mx={1}>
          <Box fontSize={18}>
            Topography and Slope
          </Box>

          <Divider />
        </Box>


        <Box
          mt={.5} 
          display='flex' 
          flexDirection={width > 1080 ? 'row' : 'column'}
        >
          <Box display='flex' flexDirection='column' flexGrow={1}>
            { controls() }

            { errorMessage === '' ? (
              <Box
                p={.5}
                display='flex'
                flexGrow={1}
                minWidth={width >= 1080 ? width - 800 : width > mobileBreakPoint ? width - 240 : width - 30}
                borderRadius={4}
              >
                <TopographyMap
                  boundary={field.boundary}
                  mapImage={image}
                  mapExtents={extent}
                  showImagery={showImagery}
                  height={'100%'}
                  width={'100%'}
                  location={field?.state}
                />
              </Box>
            ) : (
              <Box m={1} display='flex'>
                <Box className={classes.errorMessageBox}>
                  {errorMessage}
                </Box>
              </Box>
            )}
          </Box>

          <Box
            mt={1}
            display='flex'
            flexDirection={(width < mobileBreakPoint || width > 1080) ? 'column' : 'row'}
            flexWrap='wrap'
          >
            <Fade in={legend?.length > 0 && !loading} mountOnEnter unmountOnExit>
              {createTable()}
            </Fade>
            <Box
              m={1}
              p={1}
              width={350}
              borderRadius="borderRadius"
              borderColor={grey}
              border={1}
            >
              <VisitApimElevation />
            </Box>
            <Box m={1}>
              <GetWaterManagement />
            </Box>

          </Box>
        </Box>

        {loading && <SpinningLoader />}
      </Box>
    </Slide>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../../../constants";

const useStyles = makeStyles(() => ({
  container: {
    position: "absolute",
    padding: 12,
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #dddddd",
    backgroundColor: COLORS.white,
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "1.36",
    color: COLORS.text,
    pointerEvents: "none",
    minWidth: "187px",
  },
  countyContainer: {
    display: "flex",
    flexDirection: "column",
    color: COLORS.text,
    paddingBottom: 10,
    borderBottom: `1px solid ${COLORS.lightGray}`,
  },
  countyTitle: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "21px",
  },
  value: {
    lineHeight: "19px",
  },
  farmContainer: {
    display: "flex",
    flexDirection: "column",
    color: COLORS.text,
    paddingTop: 10,
  },
  farmTitle: {
    fontSize: 16,
    fontWeight: "bold",
    lineHeight: "21px",
    color: COLORS.green,
  },
}));

/**
 * Renders the tooltip
 * @param {object} activeMarker object that is given properties in Map component
 */

const Tooltip = ({ activeMarker }) => {
  const classes = useStyles();
  const {
    top,
    left,
    countyName,
    countyValueText,
    farmName,
    farmValueText,
  } = activeMarker;

  return (
    <div className={classes.container} style={{ top, left }}>
      <div className={classes.countyContainer}>
        <div className={classes.countyTitle}>{countyName}</div>
        <div className={classes.value}>{countyValueText}</div>
      </div>
      <div className={classes.farmContainer}>
        <div className={classes.farmTitle}>{farmName}</div>
        <div className={classes.value}>{farmValueText}</div>
      </div>
    </div>
  );
};

export default Tooltip;

Tooltip.propTypes = {
  activeMarker: PropTypes.object.isRequired,
};

import React, {useEffect, useState, useRef} from 'react'
import { Box, Typography, Select, MenuItem, Link, Divider, Slide } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from "../../../styles/select";
import { blackText, grey, lightGrey } from "../../../styles/colors";
import {LandValueComps} from '../../../utils/dataFetchers'
import {LandValueMap} from './LandValueMap'
import { useWindowDimensions } from '../../../utils/dimensions'
import { numFormat, exists } from '../../../utils/helpers'
import { LandForecastTable } from '../../LoanUnderwriting/LandForecastTable'
import { ParcelSalesTable } from '../../LoanUnderwriting/ParcelSalesTable'
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import {SpinningLoader} from '../../Shared/SpinningLoader'
import {useSnackbar} from 'notistack'
import * as df from '../../../utils/dataFetchers'
import * as turf from "@turf/turf";


const useStyles = makeStyles(theme => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  errorMessageBox: theme.errorMessageBox,
  dateSelection: theme.dateSelection,
}))

const containerStyle = {
  display: 'flex',
  flexDirection: 'column'
}

const radiusOptions = [{name:'5 mi', value:5}, {name:'10 mi', value:10}, {name:'25 mi', value:25}, {name:'50 mi', value:50}]
const statusOptions = ["Sold", "For Sale"]

export function LandValue({field, summary, setSummary, saleDetails, setSaleDetails, soldDetails, setSoldDetails, mobileBreakPoint}){
  const classes = useStyles();
  const {width, height} = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const tableBreakPoint = 660;
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const today = new Date();
  let year = today.getFullYear()
  let month = today.getMonth()
  let day = today.getDate()

  let sales = new Date();
  sales.setMonth(sales.getMonth() - 6)

  const [selectedRadius, setSelectedRadius] = useState(radiusOptions[radiusOptions.length-1])
  const [selectedSaleStatus, setSelectedSaleStatus] = useState(statusOptions[0])
  const [startDate, setStartDate] = useState(new Date(year-1, month, day).toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [saleDate, setSaleDate] = useState(sales.toISOString());

  const [landValueForecast, setLandValueForecast] = useState(null)
  const [parcels, setParcels] = useState(null);
  const [displayParcels, setDisplayParcels] = useState(false);

  const [activeParcel, setActiveParcel] = useState({})

  const limitReached = useRef(false)

  useEffect(() => {
    getParcels(selectedRadius.value, selectedSaleStatus, startDate, endDate, saleDate)
    getComps(saleDate)
  },[])

  /**
   * Gets the other parcels that match the provided params
   * Data used in ParcelSalesTable
   * @param {*} radius 
   * @param {*} status 
   * @param {*} startDate 
   * @param {*} endDate 
   * @param {*} saleDate 
   */
  const getParcels = async (radius, status, startDate, endDate, saleDate) => {
    //get selected fields center, create circle with diam of radius around it
    let fieldBoundary = JSON.parse(field.boundary);
    let center = turf.center(fieldBoundary)
    let aoi = turf.circle(center, radius, {units: "miles"})
    let request = {
      State: field.state,
      County: field.county,
      Bounding_Box: JSON.stringify(aoi),
      Status: status,
      StartDate: startDate,
      EndDate: endDate,
      SaleDate: saleDate
    }

    let result = await df.parcelService(request)
    // console.log("parcel result", result)
    if(result === null){
      return
    }
    if(result.isApiLimitReach){
      if(!limitReached.current){
        enqueueSnackbar("You hav reached the limit of your API usage for this service", {autoHideDuration: 5000})
        limitReached.current = true
      }
      return
    }
    let parcels = JSON.parse(result)
    // console.log(parcels)
    if(parcels.records){
      let parcelObjects = {
        field: fieldBoundary,
        center: center,
        radius: radius,
        parcels: parcels.records
      }
      setParcels(parcelObjects)
      setDisplayParcels(true)
    }
    else{
      let parcelObjects = {
        field: fieldBoundary,
        center: center,
        radius: radius,
        parcels: null
      }
      setParcels(parcelObjects)
      setDisplayParcels(false)
    }

  }

  /**
   * Gets data for the LandForecastTable
   * @param {string} saleDate 
   */
  const getComps = async (saleDate) => {
    //auto comps
    let autoReq = {
      aoi: field.boundary,
      model: 'Auto',
      Sale_Date: saleDate
    }

    let comps = await df.getComps(autoReq)
    // console.log("Comps result", comps)
    if(comps === null){
      return
    }
    if(comps.isApiLimitReach){
      if(!limitReached.current){
        enqueueSnackbar("You hav reached the limit of your API usage for this service", {autoHideDuration: 5000})
        limitReached.current = true
      }
      return
    }
    let compsResult = JSON.parse(comps)
    // console.log(compsResult)
    if(compsResult.status !== 'Not Found'){
      setLandValueForecast(compsResult.Result)
    }
  }
 
  const handleChange = (value, type) => {
    switch (type){
      case 'radius':
        setSelectedRadius(value)
        getParcels(value.value, selectedSaleStatus, startDate, endDate, saleDate)
        break;
      case 'status':
        setSelectedSaleStatus(value.value)
        getParcels(selectedRadius.value, value.value, startDate, endDate, saleDate)
        break;
      case 'startDate':
        setStartDate(value)
        getParcels(selectedRadius.value, selectedSaleStatus, value, endDate, saleDate)
        break;
      case 'endDate':
        setEndDate(value)
        getParcels(selectedRadius.value, selectedSaleStatus, startDate, value, saleDate)
        break;
      case 'saleDate':
        setSaleDate(value)
        getParcels(selectedRadius.value, selectedSaleStatus, startDate, endDate, value)
        getComps(value)
        break;
    }
  }


  return(
      <Slide direction="right" in>
        <Box color={blackText} fontWeight={500}>
          <Box m={2}>
            <Box fontSize={18}>
              Land Value Estimation
            </Box>

            <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
              Land Value Estimation is a tool that calculates the price of your field based on other fields in your area.
            </Typography>
            <Divider />
          </Box>
        
          <Box display="flex" flexWrap="wrap">
            <Box mx={1}>
              <Box>
                Search Radius
              </Box>
              <Select
                className={classes.selections}
                variant="outlined"
                MenuProps={MenuProps}
                value={selectedRadius.value}
                onChange={(e) => handleChange(e.target, 'radius')}
              >
                {
                  radiusOptions.map((x, i) => (
                    <MenuItem
                      key={i}
                      value={x.value}
                      name={x.name}
                    >
                      {x.name}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
            <Box mx={1}>
              <Box>
                Sale Status
              </Box>
              <Select
                className={classes.selections}
                variant="outlined"
                MenuProps={MenuProps}
                value={selectedSaleStatus}
                onChange={(e) => handleChange(e.target, 'status')}
              >
                {
                  statusOptions.map((x, i) => (
                    <MenuItem
                      key={i}
                      value={x}
                      name={x}
                    >
                      {x}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
            {selectedSaleStatus === 'Sold' &&
              <>
                <Box mx={1}>
                  <Box>
                    Start Date
                  </Box>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker
                      className={classes.dateSelection}
                      disableToolbar
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      InputAdornmentProps={{ position: "start" }}
                      value={startDate}
                      onChange={(date, val) => handleChange(date, 'startDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
                <Box mx={1}>
                  <Box>
                    End Date
                  </Box>
                  <MuiPickersUtilsProvider utils={DateFnsUtils} >
                    <KeyboardDatePicker
                      className={classes.dateSelection}
                      disableToolbar
                      autoOk
                      variant="inline"
                      inputVariant="outlined"
                      format="MM/dd/yyyy"
                      margin="normal"
                      id="date-picker-inline"
                      InputAdornmentProps={{ position: "start" }}
                      value={endDate}
                      onChange={(date, val) => handleChange(date, 'endDate')}
                      KeyboardButtonProps={{
                        'aria-label': 'change date',
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Box>
              </>
            }

            <Box mx={1}>
              <Box>
                Sale Date
              </Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils} >
                <KeyboardDatePicker
                  className={classes.dateSelection}
                  disableToolbar
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  InputAdornmentProps={{ position: "start" }}
                  value={saleDate}
                  onChange={(date, val) => handleChange(date, 'saleDate')}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>
          </Box>
        
          <Box display="flex" flexWrap="wrap">
            <Box m={1}>
              <LandValueMap
                boundary={field.boundary}
                parcels={parcels}
                activeParcel={activeParcel}
                setActiveParcel={setActiveParcel}
                height={window.innerHeight - 400}
                width={width > 840 ? 600 : width > mobileBreakPoint ? width - 240 : width - 30}
                location={field?.state}
              />
            </Box>
            <Box>
              <Box>
                {displayParcels &&
                  <ParcelSalesTable comps={parcels} activeParcel={activeParcel} setActiveParcel={setActiveParcel}/>
                }
                {landValueForecast !== null &&
                  <LandForecastTable 
                    forecast={landValueForecast}
                    status={selectedSaleStatus}
                  />
                }
              </Box>
            </Box>
          </Box>
        </Box>
      </Slide>
  )


}

import React, { useState } from 'react'
import { Box, Button } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import GetAppIcon from "@material-ui/icons/GetApp";
import { makeStyles } from '@material-ui/core/styles';
import { grey, green, blackText } from "../../../styles/colors";
import { numFormat } from '../../../utils/helpers'
import { useWindowDimensions } from '../../../utils/dimensions'
import poweredBy from '../../../images/Ag-Analytics_Full_Black_Powered.svg'


const useStyles = makeStyles(theme => ({
  footer: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    height: 60,
    width: '100%',
    padding: 16,
  },
  fullLogo: {
    width: '250px',
    height: 'auto',
    marginRight: '20px',
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginRight: '8px',
  },
  smallLogo: {
    width: '90px',
    height: 'auto',
    marginRight: '4px',
  },
}))

export const Footer = ({
  changeSection,
  field,
  openPdf,
  pdfLayer,
  section,
  setSection,
}) => {

  const classes = useStyles()
  const {height, width} = useWindowDimensions()

  return (
    <Box className={classes.footer}>
      {/* Left-side of footer */}
      <Box
        display='flex'
        fontSize={14}
        fontWeight={600}
        color={blackText}
      >
        {/* Back button */}
        <Button
          variant="outlined"
          color="primary"
          onClick={() => changeSection(0)}
          style={{width: width > 466 ? 94 : 22}}
        >
          <ArrowBackIcon />
          {width > 466 ? 'Back' : ''}
        </Button>

        {/* Org info */}
        {
          (field.org !== undefined && field.org !== '' && width > 680) &&
          <Box mx={2}>
            <Box fontSize={12} color={grey}>
              ORGANIZATION
            </Box>
            {field.org}
          </Box>
        }

        {/* Farm info */}
        {
          (field.farm !== undefined && field.farm !== '' && width > 780) &&
          <Box ml={2} mx={2}>
            <Box fontSize={12} color={grey}>
              FARM
            </Box>
            {field.farm}
          </Box>
        }

        {/* Field info or Claim Field button */}
        {
          (field.name !== undefined && field.name !== '') ? (
            <Box mx={2}>
              <Box fontSize={12} color={grey}>
                FIELD
              </Box>
              {field.name}
            </Box>
          ) : section !== pdfLayer+1 ? (
            <Button
              variant='contained'
              color='primary'
              onClick={() => setSection(pdfLayer+1)}
              style={{margin: '0 14px', width: 120}}
              disableElevation
            >
              Claim Field
            </Button>
          ) : (
            <Box>{''}</Box>
          )
        }

        {/* Acres info */}
        {
          field.acres !== '' && (
            <Box mx={2}>
              <Box fontSize={12} color={grey}>
                ACRES
              </Box>
              {numFormat(field.acres, 0, 2)}
            </Box>
          )
        }
      </Box>

      {/* Right-side of footer */}
      <Box display="flex" alignItems="center">
        {/* Logo */}
        {width > 760 &&
          <Box>
            <img
              src={poweredBy}
              className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
              alt={"Powered By Ag-Analytics"}
            />
          </Box>
        }

        {/* Create PDF button */}
        {
          section !== pdfLayer ? (
            <Button
              variant='contained'
              color='primary'
              style={{margin: '0 14px', minWidth: width > 434 ? 120 : 60}}
              disableElevation
              onClick={() => openPdf()}
            >
              {width > 434 ? 'Create ' :  <GetAppIcon />}
              {'PDF'}
            </Button>
          ) : (
            <Box>{' '}</Box>
          )
        }
      </Box>
    </Box>
  )
}

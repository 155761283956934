import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS, DURATION } from "../../../constants";
import { pointer } from "d3-selection";

const useStyles = makeStyles(() => ({
  path: {
    strokeWidth: "1px",
    fill: COLORS.white,
    transition: `${DURATION}ms fill`,
    cursor: "pointer",
  },
}));

const Path = ({ feature, d, setActiveMarker }) => {
  const classes = useStyles();
  const [currentColor, setCurrentColor] = useState(COLORS.white);

  const { color } = feature;

  useEffect(() => {
    setCurrentColor(color);
  }, [color]);

  const onMouseOver = (evt) => {
    const [left, top] = pointer(evt);
    setActiveMarker({
      ...feature,
      top,
      left,
    });
  };

  return (
    <path
      className={classes.path}
      d={d}
      onMouseOver={onMouseOver}
      style={{ fill: currentColor, stroke: feature.stroke || COLORS.white }}
    />
  );
};

export default Path;

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, IconButton, Divider } from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { makeStyles } from '@material-ui/core/styles';
import { Endpoints } from '../../../constants/Endpoints';
import { useWindowDimensions } from '../../../utils/dimensions';

const useStyles = makeStyles((theme) => ({
  downloadButton: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  downloadLink: {
    //margin: '8px 0',
    display: 'flex',
    alignItems: 'center',
    borderBottom: `1px soild ${theme.palette.greys.light}`,
  }
}));

export function DisplayDownloads({ downloads, token }) {
  const { height, width } = useWindowDimensions();
  const classes = useStyles();

  const [zips, setZips] = useState([]);
  const [tiffs, setTiffs] = useState([]);
  const [others, setOthers] = useState([]);

  useEffect(() => {
    if (downloads?.length) {
      const zipFiles = [];
      const tiffFiles = [];
      const otherFiles = [];
      downloads.forEach((file) => {
        const extension = file.slice(file.length - 3);
        if (extension === 'zip') {
          zipFiles.push(file);
        } else if (extension === 'tif') {
          tiffFiles.push(file);
        } else {
          otherFiles.push(file);
        }
      })
      setZips(zipFiles);
      setTiffs(tiffFiles);
      setOthers(otherFiles);
    }
  }, [downloads])

  const downloadAll = async () => {
    for (const file of downloads) {
      const fileLink = token !== '' ? `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${file}&token=${token}` : `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${file}`;
      window.open(fileLink)
    }
  }

  const displayDownloadable = (file, i) => {
    const fileLink = token !== '' ? `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${file}&token=${token}` : `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${file}`;
    const name = file.split('/')[file.split('/').length - 1];

    return (
      <Box key={`${name}-${i}`}>
        <Box  className={classes.downloadLink}>
          <IconButton
            color='primary'
            id={`download-${name}-${i}`}
            href={fileLink}
            style={{padding: 4}}
          >
            <GetAppRoundedIcon />
          </IconButton>

          <Typography variant='subtitle2' style={{ margin: '0 10px' }}>
            { name }
          </Typography>
        </Box>
        <Divider/>
      </Box>
    )
  };

  return (
    <Box p={2}>
      <Box>
        <Typography variant='h6'>
          Download your reports files
        </Typography>
        <Typography>
          Click the download button next to the file name to begin download
        </Typography>
      </Box>
      <Box display='flex' flexWrap='wrap'>
        { zips.length > 0 && (
          <Box m={1}>
            <Box display='flex' flexDirection='column' boxShadow={1}>
              <Typography align='center' style={{fontWeight: 500, padding: 4}}>
                Zip Files
              </Typography>
              <Divider />
              {zips.map((file, i) => displayDownloadable(file, i))}
            </Box>
          </Box>

        )}

        { tiffs.length > 0 && (
          <Box m={1} >
            <Box display='flex' flexDirection='column' boxShadow={1}>
              <Typography align='center' style={{fontWeight: 500, padding: 4}}>
                Tiff Files
              </Typography>
              <Divider />
              {tiffs.map((file, i) => displayDownloadable(file, i))}
            </Box>
          </Box>
        )}

        { others.length > 0 && (
          <Box m={1}>
            <Box display='flex' flexDirection='column' boxShadow={1}>     
              <Typography align='center' style={{fontWeight: 500, padding: 4}}>
                Miscellaneous Files
              </Typography>
              <Divider />
              {others.map((file, i) => displayDownloadable(file, i))}
            </Box>
          </Box>
        )}

      </Box>

      
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Select, MenuItem, Divider, Slide, Fade,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import RadioButtonUncheckedOutlinedIcon from '@material-ui/icons/RadioButtonUncheckedOutlined';
import { MenuProps } from '../../../styles/select';
import { CropLandMap } from './CropLandMap';
import { numFormat } from '../../../utils/helpers';
import { blackText, grey, lightGrey } from '../../../styles/colors';
import { useWindowDimensions } from '../../../utils/dimensions';

import { SpinningLoader } from '../../Shared/SpinningLoader';
import { GetSoilTesting, IntegrationLinks } from '../../Shared/ServiceLinks';
import { ImageSwitch } from '../Shared/ImageSwitch';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  messageBox: theme.messageBox,
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * Display CropLand map and data
 * @param {Object} field  Field to display data for
 * @param {Object} loading Loading status
 * @param {Function} getCDL Get crop land data
 * @param {Array} years Years for drop down
 * @param {String} selectedYear year used for initial call and dropdown set
 * @param {Function} setSelectedYear change selected year from dropdown
 * @param {String} image  Image for map
 * @param {Array} legend Legend data for table
 * @param {Array} extent Extent of image for map
 * @param {Number} mobileBreakPoint Pixel width side navigation changes to dropdown
 * @param {String} errorMessage Message to display to user if do not have data
  * @param {Bool} precisionField If field is from precision source
 * @param {onConnectClick} onConnectClick Open integration modal
 * @returns {JSX} CropLand
 */
export function CropLand({
  field,
  loading,
  getCDL,
  years,
  selectedYear,
  setSelectedYear,
  image,
  legend,
  extent,
  mobileBreakPoint,
  errorMessage,
  tillable,
  precisionField,
  onConnectClick,
  underwriting, // Everything below this is used by LoanUnderwriting to customize render
  slideDirection,
  loaderPosition,

}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  const [showImagery, setShowImagery] = useState(true);

  const [yearMsg, setYearMsg] = useState('');

  const getData = async (year) => {
    const success = await getCDL({year: year});
  };

  const handleSwitch = (checked) => {
    setShowImagery(checked);
  };

  useEffect(() => {
    if (tillable) {
      const msg = ` The ${selectedYear} Cultivated Layer is based on land cover information derived from the ${selectedYear - 4} through ${selectedYear} USDA Cropland Data Layers (CDL).`;
      setYearMsg(msg);
    } else {
      setYearMsg('');
    }
  }, [selectedYear]);

  const createTable = () => (
    <Box
      m={1}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      style={{
        minWidth: 360, width: 400, maxWidth: 1000, overflowX: 'auto',
      }}
    >
      <Box p={1} display="flex" alignItems="center" fontSize={16}>
        <Box style={{ width: '50%' }}>
          {!tillable ? 'Crop' : 'Tillable'}
        </Box>
        <Box style={{ width: '30%' }} px={1}>
          {!tillable ? 'Area per part (Acres)' : 'Acres'}
        </Box>
        <Box style={{ width: '20%' }}>
          % of Total
        </Box>

      </Box>

      <Divider style={{ color: grey, height: 4 }} />
      {
        legend.map((x, i) => createRow(x, i))
      }
    </Box>
  );

  const createRow = (x, i) => (
    <Box
      key={i}
      p={1}
      display="flex"
      alignItems="center"
      style={{ backgroundColor: i % 2 == 0 ? '#ffffff' : lightGrey }}
    >
      <Box style={{ width: '50%' }}>
        {x.color !== '' && (
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon
            style={{ color: x.color, marginRight: 8 }}
          />
          {x.CropName}
        </Box>
        )}
        {x?.Cultivated === 'No' && (
            'Not Tillable'
          )}
      </Box>

      <Box px={1} style={{ width: '30%' }}>
        {numFormat(x.Acres)}
      </Box>

      <Box style={{ width: '20%' }}>
        {x.Area}
      </Box>

    </Box>
  );

  const controls = () => (
    <Box display="flex" alignItems="center" flexWrap="wrap">
      <Box mx={2}>
        <Box>
          Select Year
        </Box>
        <Select
          className={classes.selections}
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedYear}
          onChange={(e) => { getData(e.target.value); setSelectedYear(e.target.value); }}
        >
          {
            years.map((x, i) => (
              <MenuItem
                key={i}
                value={x}
              >
                {x}
              </MenuItem>
            ))
          }
        </Select>
      </Box>

      <ImageSwitch
        checked={showImagery}
        handleSwitch={handleSwitch}
      />
    </Box>
  );

  return (
    <Slide direction={slideDirection || 'right'} in>
      <Box color={blackText} fontWeight={500}>
        <Box mt={1} mx={1}>
          <Box fontSize={18}>
            {!tillable ? 'Crop History' : 'Tillable Acres' }
          </Box>

          <Box fontSize="1rem">
            {!tillable
              ? 'Crop Land Data Layers, also known as CDLs, are published by USDA and provide estimates of historical crops cover.'
              : `Tillable Acres shows the percentage of the selected field that can be cultivated.${yearMsg}`}
          </Box>
          <Divider />
        </Box>

        <Box
          mt={0.5}
          display="flex"
          flexDirection={width > 1080 ? 'row' : 'column'}
        >
          <Box display="flex" flexDirection="column" flexGrow={1}>
            { controls() }

            { errorMessage === '' ? (
              <Box
                p={0.5}
                display="flex"
                flexGrow={1}
                minWidth={underwriting ? 'auto' : width >= 1080 ? width - 800 : width > mobileBreakPoint ? width - 240 : width - 30}
                borderRadius={4}
              >
                <CropLandMap
                  boundary={field.boundary}
                  mapImage={image}
                  mapExtents={extent}
                  showImagery={showImagery}
                  height="100%"
                  width="100%"
                  location={field?.state}
                />
              </Box>
            ) : (
              <Box m={1} display="flex">
                <Box className={classes.errorMessageBox}>
                  {errorMessage}
                </Box>
              </Box>
            )}
          </Box>

          <Box
            mt={1}
            display="flex"
            flexDirection={(width < mobileBreakPoint || width > 1080) ? 'column' : 'row'}
            flexWrap="wrap"
            style={underwriting ? { minWidth: 450 } : {}}
          >
            <Fade in={legend.length > 0 && !loading} mountOnEnter unmountOnExit>
              <Box p={0.5}>
                {createTable()}
              </Box>
            </Fade>

            { underwriting === undefined && (
              <Box
                display="flex"
                flexWrap="wrap"
                flexDirection={(width < mobileBreakPoint || width > 1080) ? 'column' : 'row'}
              >
                <Box p={0.5} display="flex" justifyContent="center">
                  <GetSoilTesting />
                </Box>

                { !precisionField && tillable && (
                  <Box display="flex" justifyContent="center">
                    <Box p={0.5}>
                      <IntegrationLinks onConnect={onConnectClick} />
                    </Box>
                  </Box>
                )}
              </Box>
            )}

          </Box>
        </Box>

        {loading && <SpinningLoader top={loaderPosition} />}
      </Box>
    </Slide>
  );
}

CropLand.propTypes = {
  field: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  legend: PropTypes.array.isRequired,
  getCDL: PropTypes.func.isRequired,
  years: PropTypes.array.isRequired,
  mobileBreakPoint: PropTypes.number.isRequired,
  errorMessage: PropTypes.string.isRequired,
  precisionField: PropTypes.bool.isRequired,
  onConnectClick: PropTypes.func.isRequired,
};
import React, {useState, useEffect} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Divider,
  TextField,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { lightGrey, blackText } from '../../../../styles/colors';
import organic from '../../images/organic.svg';
import irrigated from '../../images/irrigated.svg';
import { numFormat, capitalizeFirstLetter } from '../../../../utils/helpers';
import { unitConversion } from '../../utils/conversions';
import { NumberFormatCustom, NumberFormatSeeds } from '../../../../utils/NumberFormatCustom';
import { CustomToolTip } from '../../../../utils/customComponents'
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import * as df from '../../../../utils/dataFetchers';
import { de } from "date-fns/locale";
import { useSnackbar } from "notistack";


const useStyles = makeStyles((theme) => ({
  header: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    fontWeight: 500,
    color: 'white',
    backgroundColor: '#464646',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    fontWeight: 500,
    color: theme.palette.text.black,
    backgroundColor: "#d1d1d1"
  },
  traitHeader: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    fontWeight: 500,
    color: 'white',
    backgroundColor: '#5e5e5d'
  },
  trait: {
    padding: '8px',
    fontWeight: 500,
    color: 'white',
    backgroundColor: '#5e5e5d'
  },
  traitItem: {
    padding: '8px',
    fontWeight: 500,
    color: theme.palette.text.black,
    backgroundColor: "#d1d1d1"
  },
}));

/**
 * Component for displaying further details about varieties, chemicals, and application operations
 * @param {String} name Name of the variety, chemical, or operation
 * @param {String} type type of parser (variety or chemical)
 * @param {Object} operation (optional) this is for the application operation option, pass the operation in to parser
 */
export function Parser({name, type, operation}){
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [expanded, setExpanded] = useState(false)
  const [details, setDetails] = useState({})
  const [failed, setFailed] = useState(false)


  /**
   * Capitilizes the first letter of every key in a dictionary.
   * only handles top level. NOT RECURSIVE
   * @param {Object} dictionary
   */
  const capitilizeDictionary = (dictionary) => {
    let obj = dictionary;
    for(var key in obj){
      var temp;
      if(obj.hasOwnProperty(key)){
        temp = obj[key];
        delete obj[key]
        obj[key.charAt(0).toUpperCase()+key.substring(1)] = temp
      }
    }
    return obj
  }


  /**
   * Format the application operation into the correct request format
   * @param {Object} op
   */
  const buildOperationRequest = (op) =>{
    let request = {"Application_Operation":{}}
    let source = 'Precision'
    let total ={...op}
    let components = {}

    for(var i=0; i < op.applicationComponents.length; i++){
      let comp = "Comp_"+(i+1);

      if(!op.applicationComponents[i].productName.toUpperCase().includes("WATER")){
        components[comp] = capitilizeDictionary({...op.applicationComponents[i]})
      }
    }

    request.Application_Operation["Entry_Type"] = source
    request.Application_Operation["Total"] = capitilizeDictionary(total)
    request.Application_Operation["Components"] = components

    return request
  }


  /**
   * Sends request out to backend for Chem/Variety Parser
   * sets state variables based on response
   */
  const parse = async () => {
    let request;
    let result;
    if(type === "variety"){
      let varieties = [name]
      request = {"Variety": varieties}
      result = await df.varietyParser(request)
    }
    else{
      // construct request for chem and send
      if(operation === undefined){
        // if for single chemical
        let requestBody = {"input":{"Chemical": name}}
        request = {"requestBody": JSON.stringify(requestBody)}

        result = await df.chemicalParser(request)
      }
      else{
        // for full operation
        let requestBody = {"input": buildOperationRequest(operation)}
        request = {"requestBody": JSON.stringify(requestBody)}
        result = await df.chemicalParser(request)
      }
    }

    if (operation !== undefined) {
      setDetails({BestMatches:{}})
    } else {
      setDetails({Bestmatch: "false"})
    }

    if(result === undefined){
      setFailed(true)
      setDetails({Bestmatch: "false"})
      setExpanded(true)
      return
    }

    try {
      let parsed = JSON.parse(result)
      console.log(parsed)
      if(parsed.Error || parsed.msg){
        setFailed(true)
        setDetails({Bestmatch: "false"})
        setExpanded(true)
        return
      }
      if(type === "variety"){
        setDetails(parsed[Object.keys(parsed)[0]])
      }
      else{
        let chemDetails = {
          ...parsed,
          Bestmatch: operation === undefined ? parsed.BestMatch.toString() : "False",
          Chemicals: operation === undefined ? [] : Object.keys(parsed.ChemicalTypes)
        }
        setDetails(chemDetails)
      }
      setFailed(false)
      setExpanded(true)
    } catch (e) {
      console.log(e)
      enqueueSnackbar('We encounted a problem get variety details. Please try again or later or select another variety')
    }

  }

  const handleVarietyClick = () => {
    if (!expanded) {
      parse()
    } else {
      setExpanded(false)
    }
  }

  return(
    <Box> {/*variety parser*/}
      <Box className={classes.header}>
        <Box display='flex' alignItems='center' onClick={handleVarietyClick} style={{cursor: 'pointer'}}>
          <CustomToolTip
            placement="top-end"
            title={!expanded ? "Click to view details" : ''}
          >
            <Box>
              {' '}
              { name }
              {' Details'}
            </Box>
          </CustomToolTip>

          {!expanded
            ?
            <ExpandMoreIcon />
            :
            <ExpandLessIcon />
          }
        </Box>
        {(expanded && details?.Bestmatch?.toUpperCase() === "TRUE") &&
          <Box>
            Exact Match Could Not Be Found, Viewing: {type==="variety" ? details.Variety : details.ProductName}
          </Box>
        }

      </Box>
      {(expanded && operation === undefined && !failed) ?
        <Box borderBottom={2} borderColor='black'> {/*parser details*/}
          <Box> {/** display brand/Type and code/state */}
            <Box className={classes.item}>
              <Box style={{width: '15%'}}>
                {type ==="variety" ? "Brand" : "Type"}
              </Box>
              <Box>
              {type ==="variety" ? details.Brand : details.Type}
              </Box>
            </Box>
            <Box className={classes.item}>
              <Box style={{width: '15%'}}>
              {type ==="variety" ? "Code" : "State" }
              </Box>
              <Box>
              {type ==="variety" ? details.Code : details.State}
              </Box>
            </Box>
          </Box>
          {/** need different display methods for variety/chem and fertilizer */}
          { (details.Traits!==undefined || (type === "chemical" && details.Type !== "Fertilizer")) &&
            <Box> {/* Traits */}
              <Box className={classes.traitHeader}>{/** define trait headers */}
                <Box style={{width: '25%'}}>
                {type ==="variety" ? "Trait" : "Main Ingredient" }
                </Box>
                <Box style={{width: '15%'}}>
                {type ==="variety" ? "Genes" : "Pounds/Gallon" }
                </Box>
                <Box style={{width: '30%'}}>
                {type ==="variety" ? "Function" : "Density" }
                </Box>
              </Box>

              <Box>{/** loop over variety traits, each trait is one row */}
              {type ==="variety" ? details.Traits.map((trait, i) => (
                  <Box key={i} className={classes.item}>
                    <Box style={{width: '25%'}}>
                      {trait.Trait} - {trait.TraitName}
                    </Box>
                    <Box style={{width: '15%'}}>
                      {trait.Attributes[0].Attribute}
                    </Box>
                    <Box style={{width: '30%'}}>
                      {trait.Attributes[0].AttributeProperty}
                    </Box>
                  </Box>
                ))
                :
                <Box className={classes.item}> {/** display normal chem details */}
                    <Box style={{width: '25%'}}>
                      {details.PrimaryIngredient}
                    </Box>
                    <Box style={{width: '15%'}}>
                      {details.PrimaryLbsPerGal} Lbs/Gal
                    </Box>
                    <Box style={{width: '30%'}}>
                      {details.Density} {details.DensityUnit}
                    </Box>
                  </Box>
              }
              </Box>
            </Box>
          }
          { (type==="chemical" && details.Type === "Fertilizer") &&
            <Box>  {/** fertilizer specific display */}
              <Box className={classes.traitHeader}>
                <Box style={{width: "20%"}}>
                  Density
                </Box>
                {/** loop over detail keys that have "Percent" in name and have value. Add them as headers */}
                {Object.keys(details).filter(x => x.includes("Percent") && details[x] !== "").map(i => (
                  <Box style={{width: "20%"}}>
                    {`Percent ${i.split("Percent")[1]}`}
                  </Box>
                ))}
              </Box>
              <Box className={classes.item}>
                  <Box style={{width: "20%"}}>
                    {`${details.FertilizerDensity} ${details.FertilizerDensityUnit}`}
                  </Box>
                  {/** loop over detail keys that have "Percent" in name and have value. Add their value */}
                  {Object.keys(details).filter(x => x.includes("Percent") && details[x] !== "").map(i => (
                  <Box style={{width: "20%"}}>
                    {details[i]}%
                  </Box>
                ))}
              </Box>
            </Box>
          }
        </Box>
        : (expanded && operation !== undefined) ?
        <Box> {/** Application Operation Specific display */}
        {details.BestMatches !== undefined && Object.keys(details.BestMatches).length > 0 ?
          <Box display='flex' width='100%'>
            {Object.keys(details.BestMatches).map(x => (
              <>
              <Box display='flex' flexDirection='column'>
                <Box className={classes.trait}>
                  Chemical
                </Box>
                <Box className={classes.traitItem}>
                  {x}
                </Box>
              </Box>

              <Box display='flex' flexDirection='column'>
                <Box className={classes.trait}>
                  Best Match
                </Box>
                <Box className={classes.traitItem}>
                  {details.BestMatches[x][0]}
                </Box>
              </Box>
              <Box display='flex' flexDirection='column'>
                <Box className={classes.trait}>
                  Match Confidence
                </Box>
                <Box className={classes.traitItem}>
                  {details.BestMatches[x][1]}%
                </Box>
              </Box>
              </>
            ))}
            <Box display='flex' flexDirection='column' flexGrow={1}>
              <Box display='flex' flexGrow={1} style={{'backgroundColor': '#5e5e5d', width: '100%'}}>
                {' '}
              </Box>
              <Box display='flex' flexGrow={1} style={{'backgroundColor': "#d1d1d1", width: '100%'}}>
                {' '}
              </Box>
            </Box>
          </Box> :
          details.BestMatches == undefined ? (
            <Box className={classes.traitItem}>
              No Information Could Be Found
            </Box>
          )
          :
          (
            <Box display='flex' flexDirection='column' flexGrow={1}>
              <Box display='flex' flexGrow={1} style={{'backgroundColor': '#5e5e5d', width: '100%'}}>
                {' '}
              </Box>
              <Box display='flex' flexGrow={1} style={{'backgroundColor': "#d1d1d1", width: '100%'}}>
                {' '}
              </Box>
            </Box>
          )
        }


          {details.Chemicals !== undefined && details.Chemicals.map((chem, i) => (
            <Box key={i}>
              <Box className={classes.traitHeader}>
                <Box style={{width: '100%'}}>{/** if chem was best match display matches name */}
                  {details.BestMatches[chem] === undefined ? chem : details.BestMatches[chem]}
                </Box>
              </Box>

              <Box className={classes.item}> {/** Type row */}
                <Box style={{width: "20%"}}>
                  Chemical Type:
                </Box>
                <Box style={{width: "20%"}}>
                  {details.ChemicalTypes[chem]}
                </Box>
              </Box>

              <Box className={classes.item}>{/** Properties */}
                <Box style={{width: "20%"}}>
                  Chemical Properties:
                </Box>
                {/** handle display for chemical properties, some chemicals have Properties & Constiuents
                 * others will just be details.ChemicalProperties
                 */}
                {
                  <Box width='80%'>
                    { details.ChemicalProperties[chem].Properties === undefined ? (
                      Object.keys(details.ChemicalProperties[chem]).map(i => (
                          `${i}: ${(details.ChemicalProperties[chem][i]*100).toFixed(2)}%`
                      )).join(', ')
                    ) : (
                      details.ChemicalProperties[chem].Properties.join(', ')
                    )}
                  </Box>
                }
              </Box>

              {details.ChemicalProperties[chem].Constituents !== undefined &&
                <Box className={classes.item}> {/** Constituents */}
                  <Box style={{width: "20%"}}>
                    Chemical Constituents:
                  </Box>
                  <Box style={{width: "80%"}}>
                    {details.ChemicalProperties[chem].Constituents.join(', ')}
                  </Box>
                </Box>
              }

            </Box>
          ))}
        </Box>

        : (failed && expanded) ?
          <Box>
            <Box className={classes.item}>
              No Information Could Be Found
            </Box>
          </Box>
        :
        <Box></Box>
      }
    </Box>
  )

}

import React, { useEffect, useState, useRef } from 'react';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import {
  drawFieldLayer,
  drawImage,
  getMinHeight
} from '../../Maps/MapFunctions/helpers';
import { exists } from '../../../utils/helpers';
import {Tiles} from '../../../constants/Tiles'

/**
 * Given a field boundary, image, and extent, draws field boundary and overlays
 * image in proper extent on field.
 * @param {Object} boundary Field boundary with polygon points
 * @param {String} mapImage pngb64 image to overlay on map
 * @param {Object} mapExtents Latitude and longitude extents for image display
 * @param {Boolean} showImagery Used to toggle on/off map image
 * @param {Number} height Map height in pixels
 * @param {Number} width  Map width in pixels
 * @return {JSX} NDVI Map
 */
export function NdviMap({
  boundary, mapImage, mapExtents, showImagery, height, width, location
}) {
  const [map, setMap] = useState(null);
  const imageOverlay = useRef(null);
  const fieldLayer = L.featureGroup(null);

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (map && location === '') {
      map.setMaxZoom(17);
    }
  }, [location, map]);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      drawFieldLayer(geoJson, fieldLayer, map);
    }
  }, [boundary, map]);

  useEffect(() => {
    if (exists([map, boundary, mapImage, mapExtents])) {
      if (showImagery) {
        drawImage(imageOverlay, mapImage, boundary, mapExtents, map);
      } else {
        //remove image layer
        map.removeLayer(imageOverlay.current);
      }
    }
    else if(exists(map) && mapImage === ""){
      if (imageOverlay.current !== null) {
        if (map.hasLayer(imageOverlay.current)) {
           map.removeLayer(imageOverlay.current)
        }
      }
    }
  }, [mapImage, boundary, map, mapExtents, showImagery]);

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('ndvi-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current']
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }

  return (
    <div
      id="ndvi-map"
      style={{
        height: height,
        minHeight: getMinHeight(),
        width: width,
        borderRadius: 4,
      }}
    />
  );
}

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button, Box, Typography, Container
} from '@material-ui/core/';
import { CreateBundle } from './Bundles.js';
import { reports, bundles } from './Services'
import { blackText } from '../../../styles/colors'

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.greys.light,
    paddingTop: 10,
  },
  report: {
    width: '300px',
    minHeight: 260,
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  title: {
    padding: '8px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 18,
    backgroundColor: '#006ba1',
    color: 'white',
    borderRadius: '8px 8px 0 0',
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  bundles: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-around',
  },
  selectField: {
    display: "flex",
    justifyContent: "center",
    fontSize: 12,
    fontWeight: 500,
    color: theme.palette.primary.main,
    '&:hover': {
         cursor: 'pointer',
         color: theme.palette.primary.dark,
      },
  }
}));

export function Reports({ addToOrder, selectedField, fields, setSection }) {
  const classes = useStyles();
  const [inOrder, setInOrder] = useState([])
  const [inBundle, setInBundle] = useState([])

  //current field name
  const [fieldName, setFieldName] = useState('')

  useEffect(() => {
    if (selectedField !== null && selectedField !== undefined) {
      canOrder(selectedField)
      setFieldName(selectedField.name)
    }
  }, [selectedField, fields])

  const canOrder = (field) => {
    setInOrder([])
    setInBundle([])
    // selected gets updated before field copy on removal, causing this
    if (field.reports !== undefined) {
      for (const report of field.reports) {
        setInOrder((inOrder) => [...inOrder, report.name])
        if (report.type === 'bundle') {
          setInBundle((inBundle) => [...inBundle, ...report.included])
        }
      }
    }
  }

  const addBundle = (bundle, remediation, option, all) => {

    if (bundle.optional.length > 0) {
      // const added = {...reports[option], price: 0}
      bundle.included.push(bundle.optional[option])
      // addToOrder(added)
    }

    if (remediation) {
      // const remediationPackage = {...reports[4], price: bundle.remediationPrice}
      // addToOrder(remediationPackage)

      // add remediation to included reports and update price
      bundle.included.push('Remediation Response Analysis')
      bundle.price = bundle.price + bundle.remediationPrice
    }

    addToOrder(bundle)

    let elmnt = document.getElementById('digs-reports');
    elmnt.scrollIntoView({ behavior: 'smooth' });
  }

  const createReportCard = (report, i) => (
    <Box
      key={i}
      m={2}
      className={classes.report}
      boxShadow={3}
      border={5}
      borderColor='#006ba1'
      borderRadius={16}
      fontWeight={500}
      fontSize={14}
    >
      <Box className={classes.title}>
        <Typography align="center" style={{fontWeight: 500, fontSize: 16}}>
          {report.name}
        </Typography>
      </Box>

      <Box
        display='flex'
        flexDirection='column'
        justifyContent='space-between'
        style={{height: '100%'}}
      >
        {/* Content */}
        <Box px={1}>
          {report.description.map((info, i) => (
            <Box key={i} p={1}>
              {info}
            </Box>
          ))}
        </Box>

        <Box>
          <Box
            display="flex"
            justifyContent="center"
            fontSize={16}
          >
            {'$'}
            {report.price}
            {'/Acre'}
          </Box>

          <Box m={1}>
            {
              fields.length === 0 ?
              <Box
                fontSize={12}
                className={classes.selectField}
                onClick={() => setSection(1)}
              >
                Select a field to begin adding services
              </Box>
              :
              inOrder.includes(report.name) ?
              <Box
                display="flex"
                justifyContent="center"
                fontWeight={500}
                fontSize={12}
                color={'#006ba1'}
              >
                In Order
              </Box>
              :
              inBundle.includes(report.name) ?
              <Box
                display="flex"
                justifyContent="center"
                fontWeight={500}
                fontSize={10}
                color={'#006ba1'}
              >
                Included in selected bundle
              </Box>
              :
              <Box display="flex" justifyContent="center">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => addToOrder(report)}
                  style={{
                    backgroundColor: '#006ba1',
                  }}
                >
                  {'Add to '}
                  {fieldName}
                </Button>
                {/* <Button
                  variant="contained"
                  color="primary"
                  onClick={() => addToOrder(report, true)}
                >
                  Add to All Fields
                </Button> */}
              </Box>
            }
          </Box>

        </Box>

      </Box>
    </Box>
  );

  return (
    <Box className={classes.root}>

      <Container maxWidth='md'>
        <Box borderBottom={1} id='digs-bundles'>
          <Typography
            variant="h4"
            align="center"
            style={{color: blackText}}
          >
            Bundles
          </Typography>
        </Box>

        <Box className={classes.bundles}>
          {
            bundles.map((bundle, i) =>
            (
              <CreateBundle
                key={i}
                report={bundle}
                addBundle={addBundle}
                inOrder={inOrder}
                fields={fields}
                fieldName={fieldName}
                setSection={setSection}
              />
            )
          )}
        </Box>
      </Container>

      <Box m={2} id='digs-reports'>
        <Box borderBottom={1}>
          <Typography
            variant="h4"
            align="center"
            style={{color: blackText}}
          >
            Individual Services
          </Typography>
        </Box>

        <Box display="flex" justifyContent="space-around" flexWrap="wrap">
          {reports.map((report, i) => createReportCard(report, i))}
        </Box>
      </Box>
    </Box>
  );
}

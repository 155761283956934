/* eslint-disable react/jsx-filename-extension */
import React from 'react';
import PropTypes from 'prop-types';
import {
  Box, Typography, Divider, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
  },
  card: {
    margin: 4,
    border: `2px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    paddingBottom: 4,
  },
  title: {
    marginRight: 4,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  value: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  operatorInfo: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  buttonLink: theme.buttonLink,
}));

/**
 * Order Summary
 * @param {Object} report Main report
 * @param {Object} selectedSubOrder Suborder
 * @returns {JSX} Order summary
 */
export const OrderSummary = ({ report, selectedSubOrder }) => {
  const classes = useStyles();
  const completeDate = new Date(report.orderCompletedDate);

  // Format date to American standard
  const date = `${completeDate.getMonth() + 1}/${completeDate.getDate()}/${completeDate.getFullYear()}`;
  return (
    <Box className={classes.root}>
      <Box m={1} className={classes.card}>
        <Box bgcolor="#f2f2f2" p={1}>
          <Typography align="center" style={{ fontWeight: 500, fontSize: 20 }}>
            Order Details
          </Typography>
        </Box>

        <Divider />

        { report?.orderName === '' && (
          <>
            <Box my={0.5} px={1.5} display="flex" flexWrap="wrap">
              <Typography className={classes.title}>Order #:</Typography>
              <Typography className={classes.value}>{report.orderId}</Typography>
            </Box>
            <Divider />
          </>
        )}

        <Box my={0.5} px={1.5} display="flex" flexWrap="wrap">
          <Typography className={classes.title}>Completed on:</Typography>
          <Typography className={classes.value}>{date}</Typography>
        </Box>

        <Divider />

        { selectedSubOrder?.OperatorCompany && (
          <>
            <Box my={0.5} px={1.5} display="flex" flexWrap="wrap">
              <Typography className={classes.title}>Operator Company:</Typography>
              <Typography className={classes.value}>{selectedSubOrder.OperatorCompany}</Typography>
            </Box>
            <Divider />
          </>
        )}

        { selectedSubOrder?.Operator && (
          <>
            <Box my={0.5} px={1.5} display="flex" flexWrap="wrap">
              <Typography className={classes.title}>Operator:</Typography>
              <Typography className={classes.value}>{selectedSubOrder.Operator}</Typography>
            </Box>
            <Divider />
          </>
        )}

        { selectedSubOrder?.OperatorEmail && (
        <>
          <Box my={0.5} px={1.5} display="flex" flexWrap="wrap">
            <Typography className={classes.title}>Email:</Typography>
            <Typography className={classes.value}>{selectedSubOrder.OperatorEmail}</Typography>
          </Box>
          <Divider />
        </>
        )}

        {selectedSubOrder?.OperatorPhone && (
          <>
            <Box my={0.5} px={1.5} display="flex" flexWrap="wrap">
              <Typography className={classes.title}>Phone:</Typography>
              <Typography className={classes.value}>{selectedSubOrder.OperatorPhone}</Typography>
            </Box>
          </>
        )}

        {report?.orderLink && (
          <Box display="flex" justifyContent="center" my={0.5}>
            <Button
              variant="outlined"
              color="primary"
              target="_blank"
              href={`${report.orderLink}`}
            >
              <span className={classes.buttonLink}>View Invoice</span>
            </Button>
          </Box>
        )}

      </Box>
    </Box>
  );
};

OrderSummary.propTypes = {
  report: PropTypes.object.isRequired,
  selectedSubOrder: PropTypes.object.isRequired,
};

import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { COLORS, SORT } from "../../../../../constants";
import InfoPopover from "../../../InfoPopover";

const useStyles = makeStyles(() => ({
  sort: {
    display: "flex",
    height: "100%",
    alignItems: "center",
    "&:hover, &.active": {
      color: COLORS.green,
    },
    color: COLORS.text,
  },
  sortIcons: {
    display: "flex",
    flexDirection: "row",
    justifySelf: "flex-end",
    alignItems: "center",
    maxHeight: 20,
    "& > svg": {
      cursor: "pointer",
      color: COLORS.lightGray,
      "&:hover, &.active": {
        color: COLORS.green,
      },
    },
  },
  name: {
    lineHeight: 1.33,
    fontWeight: "bold",
    maxHeight: "100%",
    flex: "1 1 100%",
  },
}));

const TableCellInner = ({
  text,
  sortId,
  order,
  orderBy,
  createSortHandler,
  helperText,
  disableSort,
}) => {
  const classes = useStyles();

  return (
    text && (
      <span
        role="button"
        onClick={createSortHandler(sortId)}
        className={`${classes.sort} ${orderBy === sortId ? "active" : ""}`}
      >
        <span className={classes.name}>{text}</span>
        {helperText && <InfoPopover text={helperText} />}
        {!disableSort && (
          <span className={`${classes.sortIcons}`}>
            {orderBy === sortId ? (
              order === SORT.Desc ? (
                <ExpandLessIcon
                  className={orderBy === sortId ? "active" : ""}
                />
              ) : (
                <ExpandMoreIcon
                  className={orderBy === sortId ? "active" : ""}
                />
              )
            ) : (
              <ExpandMoreIcon />
            )}
          </span>
        )}
      </span>
    )
  );
};

export default TableCellInner;

import React from "react";
import PropTypes from "prop-types";
import { makeStyles, TextField } from "@material-ui/core";
import Header from "./HistogramHeader";
import BaseChart from "../../../../../Helpers/BaseChart";
import Histogram from "./Histogram/Histogram";
import Brush from "./HistogramBrush";
import { SINGLE_DECIMAL_FORMAT } from "../../../../../../constants";

const useStyles = makeStyles((theme) => ({
  wrapper: ({ isSmallScreen }) => ({
    display: "flex",
    flexDirection: "column",
    padding: isSmallScreen ? "0px 25px 0px 0px" : "25px 25px 0px 25px",
    marginBottom: "1rem",
  }),
  histogram: ({ isSmallScreen }) => ({
    display: "flex",
    flexDirection: "row",
    alignItems: isSmallScreen ? "flex-start" : "flex-end",
    justifyContent: "space-between",
  }),
  chartContainer: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    marginLeft: theme.spacing(1),
  },
  chart: {
    width: "100%",
    height: 80,
  },
  inputWrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginTop: "18px",
    "& > *": {
      borderRadius: "5px",
    },
  },
  input: {
    "& .MuiOutlinedInput-input": {
      padding: "10.5px 14px",
    },
  },
  middle: {
    margin: "0 10px",
  },
}));

/**
 * Container for histogram elements
 * @param {array} data array of values
 * @param {object} option the current option
 * @param {array} options array of all the options
 * @param {function} setOptions set state function
 * @param {boolean} isSmallScreen for mobile view
 */

const HistogramContainer = ({
  data,
  option,
  options,
  setOptions,
  isSmallScreen,
}) => {
  const classes = useStyles({ isSmallScreen });

  // console.log("options", options)
  // console.log("option", option)

  const updateFilterRange = (value) => {
    setOptions(
      options.map((d) => {
        if (d.label === option.label) {
          return {
            ...d,
            filteredRange: value,
          };
        }
        return d;
      })
    );
  };

  const updateFilterRangeC = (value) => {
    console.log(
      options.map((d) => {
        if (d.label === option.label) {
          return {
            ...d,
            filteredRange: value,
          };
        }
        return d;
      })
    );
  };

  return (
    <div className={classes.wrapper}>
      {isSmallScreen ? <Header option={option} /> : null}
      <div className={classes.histogram}>
        {isSmallScreen ? null : <Header option={option} />}
        <div className={classes.chartContainer}>
          <BaseChart
            className={classes.chart}
            margin={{
              left: 10,
              right: 10,
              top: 10,
              bottom: 0,
            }}
          >
            <Histogram data={data} />
          </BaseChart>
          <Brush
            value={option.filteredRange}
            updateFilterRange={updateFilterRange}
            initialValues={option.range}
          />
        </div>
      </div>
      <div className={classes.inputWrapper}>
        <TextField
          className={classes.input}
          type="number"
          variant="outlined"
          value={option.filteredRange[0]}
          onChange={(e) => updateFilterRange([e.target.value, option.filteredRange[1]])}
        />
        <div className={classes.middle}>to</div>
        <TextField
          className={classes.input}
          variant="outlined"
          value={option.filteredRange[1]}
          onChange={(e) => updateFilterRange([option.filteredRange[0], e.target.value])}
        />
      </div>
    </div>
  );
};

export default HistogramContainer;

HistogramContainer.propTypes = {
  data: PropTypes.array.isRequired,
  option: PropTypes.object.isRequired,
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

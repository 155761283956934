import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  Button,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  confirmation: {
    ...theme.centeredModal,
    border: `2px solid ${theme.palette.greys.dark}`,
    borderRadius: 8,
  },
  close: {
    ...theme.icon,
    marginTop: 12,
  },
}));

export const ConfirmationPopup = ({
  open, setOpen, handleClose, handleSave,
}) => {
  const classes = useStyles();

  const close = () => {
    handleClose(false);
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box className={classes.confirmation}>
        <Box display="flex" justifyContent="flex-end">
          <HighlightOffIcon
            className={classes.close}
            style={{ marginTop: '4px' }}
            onClick={() => setOpen(false)}
          />
        </Box>

        <Box px={1} fontSize="1.2rem" textAlign="center">
          Would you like to save your changes?
        </Box>
        <Box m={1} display="flex" flexWrap="wrap" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            onClick={() => close(false)}
            style={{ margin: 8 }}
          >
            Close Without Saving
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            style={{ margin: 8 }}
            disableElevation
          >
            Save Changes
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ConfirmationPopup.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleSave: PropTypes.func.isRequired,
};

import * as fm from "../../../utils/farmReportDataFetchers";

export async function getStateData(state, year) {
  let harvestVarietyForState = await fm.getHarvestVarietyData(year, state);
  //console.log("harvestVarietyForState", harvestVarietyForState);
  let seedingVarietyForState = await fm.getSeedingVarietyData(year, state);
  let harvestDataForState = await fm.getHarvestData(year, state);
  let seedingDataForState = await fm.getSeedingData(year, state);
  let nutrientDataForState = await fm.getNutrientData(year, state);
  let precipData = await fm.getPrecipitationForState(state, year);
  let gddData = await fm.getGddForState(state, year);
  let soilData = await fm.getSoilData(state, year);
  let nccpi = await fm.getNccpiForState(state);
  let harDataForCounty = await fm.getHarvestDataForCounty(year, state)
  let precipDataForCounties = await fm.getPrecipDataForCounty(year, state)
  let gddDataForCounties = await fm.getGddDataForCounty(year, state)

  return [
    JSON.parse(harvestVarietyForState),
    JSON.parse(seedingVarietyForState),
    JSON.parse(harvestDataForState),
    JSON.parse(seedingDataForState),
    JSON.parse(nutrientDataForState),
    JSON.parse(precipData),
    JSON.parse(soilData),
    JSON.parse(nccpi),
    JSON.parse(gddData),
    JSON.parse(harDataForCounty),
    JSON.parse(precipDataForCounties),
    JSON.parse(gddDataForCounties),
  ];
}

export async function getFarmData(orgId, farmId, year) {
  let harvestVarietyForFarm = await fm.getHarvestVarietyForFarm(
    orgId,
    farmId,
    year
  );
  //console.log("harvestVarietyForFarm", harvestVarietyForFarm);
  let seedingVarietyForFarm = await fm.getSeedingVarietyForFarm(
    orgId,
    farmId,
    year
  );
  let harvestDataForFarm = await fm.getHarvestDataForFarm(orgId, farmId, year);
  let seedingDataForFarm = await fm.getSeedingDataForFarm(orgId, farmId, year);
  let nutrientDataForFarm = await fm.getNutrientDataForSelectedFarm(
    orgId,
    farmId,
    year
  );

  return [
    JSON.parse(harvestVarietyForFarm),
    JSON.parse(seedingVarietyForFarm),
    JSON.parse(harvestDataForFarm),
    JSON.parse(seedingDataForFarm),
    JSON.parse(nutrientDataForFarm),
  ];
}

export const getSoilAndWeatherFormat = (
  precipForState,
  precipDataForFarm,
  soilDataForState,
  soilDataForFarm,
  nccpiForState,
  nccpiForFarm,
  gddForFarm,
  gddForState
) => {
  try {
    let soilAndWeather = [];

    //nccpi section
    let nccpi = {
      item: "NCCPI",
      values: [
        {
          name: "State",
          value: nccpiForState[0].NCCPI,
        },
        {
          name: "Your Farm",
          value: nccpiForFarm[0].NCCPI,
        },
      ],
    };

    //soil ph section
    let soilph = {
      item: "Soil pH",
      values: [
        {
          name: "State",
          value: soilDataForState.filter((x) => x.Property == "ph")[0].Mean,
        },
        {
          name: "Your Farm",
          value: soilDataForFarm.filter((x) => x.Property == "ph")[0].Mean,
        },
      ],
    };

    //organic matter section
    let om = {
      item: "Organic Matter (%)",
      values: [
        {
          name: "State",
          value: soilDataForState.filter((x) => x.Property == "om")[0].Mean,
        },
        {
          name: "Your Farm",
          value: soilDataForFarm.filter((x) => x.Property == "om")[0].Mean,
        },
      ],
    };

    //silt section
    let silt = {
      item: "Silt (%)",
      values: [
        {
          name: "State",
          value: soilDataForState.filter((x) => x.Property == "silt")[0].Mean,
        },
        {
          name: "Your Farm",
          value: soilDataForFarm.filter((x) => x.Property == "silt")[0].Mean,
        },
      ],
    };

    //sand section
    let sand = {
      item: "Sand (%)",
      values: [
        {
          name: "State",
          value: soilDataForState.filter((x) => x.Property == "sand")[0].Mean,
        },
        {
          name: "Your Farm",
          value: soilDataForFarm.filter((x) => x.Property == "sand")[0].Mean,
        },
      ],
    };

    let statePrecip = precipForState
      .map((x) => x.Precipitation)
      .reduce((a, b) => a + b, 0);
    //console.log("statePrecip", statePrecip)
    let farmPrecip = precipDataForFarm
      .map((x) => x.Precipitation)
      .reduce((a, b) => a + b, 0);
    //console.log("farmPrecip", farmPrecip)
    statePrecip *= 0.03937;
    farmPrecip *= 0.03937;

    //console.log("after multiply", statePrecip, farmPrecip)

    //precipitation section
    let precip = {
      item: "Precipitation (inches)",
      values: [
        {
          name: "State",
          value: statePrecip,
        },
        {
          name: "Your Farm",
          value: farmPrecip,
        },
      ],
    };

    //GDD section
    let stateGdd = gddForState.map((x) => x.GDD).reduce((a, b) => a + b, 0);
    let farmGdd = gddForFarm.map((x) => x.GDD).reduce((a, b) => a + b, 0);

    let gdd = {
      item: "GDD (C)",
      values: [
        {
          name: "State",
          value: stateGdd,
        },
        {
          name: "Your Farm",
          value: farmGdd,
        },
      ],
    };

    soilAndWeather.push(nccpi);
    soilAndWeather.push(soilph);
    soilAndWeather.push(om);
    soilAndWeather.push(silt);
    soilAndWeather.push(sand);
    soilAndWeather.push(gdd);
    soilAndWeather.push(precip);

    return soilAndWeather;
  } catch (err) {
    console.log(err);
  }
};

export const Endpoints = {
  // production
  BASEURL: 'https://app.profit.ag',
  HOME: 'https://app.profit.ag',
  APIM: 'https://ag-analytics.portal.azure-api.net',
  GA_TRACKING_CODE: 'UA-124191603-2',

  /* INTEGRATION */
  DEEREINTEGRATE: '/Deere/linkMyJohnDeereV2',
  CLIMATEINTEGRATE: '/Climate/Authorize',
  CNHINTEGRATE: '/CNH/Authorize',
  INTEGRATEINVITE: '/api/IntegrationInvite/CreateInviteLink',

  /* SESSION CONTROLS */
  SIGNIN: '/Session/SignInFromProfitLayers',
  SIGNUP: '/Session/SignUpFromProfitLayers',
  SIGNOUT: '/Session/SignOutFromProfitLayers',
  EDITPROFILE: '/Session/EditProfile',
  ISAUTHENTICATED: '/Session/IsAuthenticated',
  ISINTEGRATED: '/api/User/IsIntegrated',

  SIGNINESRI: '/Session/SignInFromEsriPurchase',
  SIGNUPESRI: '/Session/SignUpFromEsriPurchase',
  SIGNININTEGRATEESRI: '/Session/SignInFromIntegrateEsri',
  SIGNINPURCHASE: '/Session/SignInFromPurchase',
  SIGNUPPURCHASE: '/Session/SignUpFromPurchase',
  SIGNINSUBSCRIPTION: '/Session/SignInFromSubscription',
  SIGNUPSUBSCRIPTION: '/Session/SignUpFromSubscription',
  SIGNINFROMSOIL: '/Session/SignInFromSoilTesting',
  SIGNINFROMDIGS: '/Session/SignInFromWaterManagement',
  SIGNINFROMACREAGE: '/Session/SignInFromAcreageReporting',
  SIGNINFROMACREAGEONBOARD: '/Session/SignInFromAcreageReportingOnboarding',
  SIGNUPFROMACREAGE: '/Session/SignUpAcreageReporting',
  SIGNUPFROMACREAGEONBOARD: '/Session/SignUpAcreageReportingOnboarding',
  SIGNINFROMUAV: '/Session/SignInFromUav',
  SIGNUPDATASHARE: '/Session/ShareSignUp',
  SIGNINFROMAPP: '/Session/SignInFromApp',
  SIGNUPFROMAPP: '/Session/SignUpFromApp',

  /* FIELD SELECTION */
  // - AgAnalytics\APIControllers\Accounts\UserController.cs
  GETORGS: '/api/User/GetOrgsName',
  GETTOPORG: '/api/User/GetTopOrg',
  GETPREFERREDORGS: '/api/User/GetPreferredOrganizations',
  UPDATEPREFERREDORGS: '/api/User/UpdateOrganizationPreferences',
  GETFIELDS: '/api/User/GetFields',
  GETFIELDDATA: '/api/User/UserFarmData?OrganizationId=',
  GETFARMS: '/api/User/FarmsFromFarmView',
  // - AgAnalytics\APIControllers\ProfitTool\FieldSelectionController.cs
  SELECTFIELD: '/api/FieldSelection/Select',
  GETFIELDSINBOUNDS: '/api/FieldSelection/GetFieldsInBounds',
  AVAILABLENAIP: '/api/FieldSelection/AvailableNaip',
  NAIPIMAGERY: '/api/FieldSelection/GetNaipImagery',
  NAIPTILE: '/api/FieldSelection/NaipTiles',
  GETSTATEANDCOUNTY: '/api/location/getLocation?wkt=POINT',
  PAYMENT: '/paymentprocessing/ClimatePlans',

  /* VENDOR ENDPOINTS */
  GETREPORTS: '/api/VendorResponse/GetOrderResponse?orderId=',
  GETREPORTSFROMTOKEN: '/api/VendorResponse/GetOrderResponse?token=',
  GETORDER: '/api/VendorResponse/GetOrder',
  GETORDERIDS: '/api/VendorResponse/GetOrderIds',
  GETPENDINGORDERS: '/api/VendorResponse/GetPendingOrders',
  ORDERREQUEST: '/api/VendorResponse/OrderRequest',
  GETPDFS: '/api/VendorResponse/GetPDFNames?orderId=',
  DOWNLOADPDFS: '/api/VendorResponse/GetPDFReports?filePath=',
  GETGEOJSONFROMSHAPE: '/api/VendorResponse/ConvertShapeToGeoJSON?blobPath=',
  CONVERTSHAPETOTIFF: '/api/VendorResponse/ConvertShapeToTIFF?blobpath=',
  DOWNLOADRASTERFILE: '/api/VendorResponse/DownloadFile?FileType=',
  VENDORRESPONSE: '/api/VendorResponse',
  CHECKVENDORAUTHENTICATION: '/api/VendorResponse/CheckVendorAuthentication',
  UPLOADOTHERFILES: '/api/VendorResponse/UploadOtherFile',
  GETLEGENDDATA: '/api/VendorResponse/GetLegendData',
  SAVEORDERPAYMENT: '/api/VendorPayment/SaveOrderPayment',
  SEARCHAGENTPROMO: '/api/VendorPayment/SearchAgentPromo',

  /* NEW PROFIT LAYER ENDPOINTS */
  // ProfitLayers - AgAnalytics\APIControllers\ProfitTool\ProfitLayersController.cs
  GETETLSTATUS: '/api/ProfitTool/ProfitLayers/getETLStatus',
  FIELDOPERATIONS: '/api/ProfitTool/ProfitLayers/FieldOperations',
  UPDATEHIDDEN: '/api/ProfitTool/ProfitLayers/UpdateHidden',
  GETGEOJSON: '/api/ProfitTool/ProfitLayers/BlobShapeToGeojson?blobPath=',
  YIELDFORECAST: '/api/YieldForecast/Forecast',
  VECTORIMAGE: '/api/VectorImage/GetVectorImage',

  /*  PROFIT LAYER ENDPOINTS   */
  // DeereOperation - AgAnalytics\APIControllers\ProfitTool\DeereOperationController.cs
  CROPPRICES: '/api/ProfitTool/DeereOperation/GetCropPrices',
  SINGLECROPPRICE: '/api/ProfitTool/DeereOperation/CropPrice',
  SEEDPRICES: '/api/ProfitTool/DeereOperation/GetSeedPrices',
  SINGLESEEDPRICE: '/api/ProfitTool/DeereOperation/SeedPrice',
  DIESELPRICES: '/api/ProfitTool/DeereOperation/GetDieselPrices',
  CHEMICALPRICE: '/api/ProfitTool/DeereOperation/ChemicalPrice',
  // FIELDOPERATIONS: '/api/ProfitTool/DeereOperation/FieldOperations',
  HARVESTYEARS: '/api/ProfitTool/DeereOperation/HarvestYears',
  PREPROCESSFIELD: '/api/ProfitTool/DeereOperation/PreprocessField',
  PREPROCESSHIGHRES:
    '/api/ProfitTool/DeereOperation/PreprocessFieldForHighResolution',
  UPDATEORGANIZATION: '/api/ProfitTool/DeereOperation/UpdateOrganization',
  DEEREHARDUPDATE: '/api/ProfitTool/DeereOperation/UpdateDeereDataHardUpdate',
  PROFITMAPSUMMARY: '/api/ProfitTool/DeereOperation/ProfitMapSummary',
  PROFITMAPACESS: '/api/ProfitTool/DeereOperation/GetAceessToProfitMap',
  SECTIONMAP: '/api/ProfitTool/DeereOperation/GetSectionMap',
  ELEVATIONMAP: '/api/ProfitTool/ProfitLayers/GetElevationMap',
  PROFITMAP: '/api/ProfitTool/ProfitLayers/GetProfitMap',
  SYNCDATA: '/api/ProfitTool/ProfitLayers/SyncData',
  PUSHPROFITMAP: '/api/ProfitTool/ProfitLayers/PushProfitMap',
  UPDATEDEEREFIELDDATA: '/api/ProfitTool/ProfitLayers/UpdateDeereFieldData',

  FIELDBOUNDARY: '/api/ProfitTool/DeereOperation/GetFieldBoundary',
  LOGPROFITSUMMARY: '/api/ProfitTool/DeereOperation/LogProfitSummary',
  GETSOURCE: '/api/ProfitTool/DeereOperation/GetSource',
  BINRANGE: '/api/ProfitTool/DeereOperation/BinRange',
  LOGBINRANGE: '/api/ProfitTool/DeereOperation/LogBinRange',
  PROFITMAPRESPONSE: '/api/ProfitTool/DeereOperation/ProfitMapResponse',
  LOGPROFITRESPONSE: '/api/ProfitTool/DeereOperation/LogProfitMapResponse',
  HARVESTOPERATIONUNIT: '/api/ProfitTool/DeereOperation/HarvestOperationUnit',
  SEEDMATERIALUNIT: '/api/ProfitTool/DeereOperation/SeedMaterialUnit',
  APPMATERIALUNIT: '/api/ProfitTool/DeereOperation/ApplicationMaterialUnit',
  HARVESTCROPNAME: '/api/ProfitTool/ProfitToolLookup/HarvestCropName',
  HARVESTVARIETYNAME: '/api/ProfitTool/ProfitToolLookup/HarvestVarietyName',
  SEEDCROPNAME: '/api/ProfitTool/ProfitToolLookup/SeedCropName',
  SEEDVARIETY: '/api/ProfitTool/ProfitToolLookup/SeedVarietyName',
  APPMATERIALNAME: '/api/ProfitTool/ProfitToolLookup/ApplicationName',
  COLORTEMPLATE: '/api/ProfitTool/ProfitLayers/GetColorTemplate',
  SAVETEMPLATE: '/api/ProfitTool/ProfitLayers/SaveTemplate',
  ALLTEMPLATE: '/api/ProfitTool/ProfitLayers/GetAllTemplates',
  UPDATETEMPLATE: '/api/ProfitTool/ProfitLayers/UpdateTemplate',
  ELEVATIONSCATTER: '/api/ProfitTool/ProfitLayers/GetScatterforElevation',
  ZONEANALYSIS: '/api/ProfitTool/ProfitLayers/ZoneAnalysisForApplication',
  RESETDATA: '/api/ProfitTool/ProfitLayers/ResetData',

  // Create Operations - AgAnalytics\APIControllers\ProfitTool\ProfitLayersController.cs
  MANUALENTRY: '/api/ProfitTool/ProfitLayers/ManualOperation',
  OPERATIONFROMPRICE: '/api/ProfitTool/ProfitLayers/CreateOperationFromPrice',
  CREATEFIELDPASSES: '/api/ProfitTool/ProfitLayers/CreateManualFieldPasses',
  OTHERSFORORG: '/api/ProfitTool/ProfitLayers/CreateOtherGenerics',

  CurrentUser: '/api/ProfitTool/ProfitLayers/GetCurrentUser',
  SAVEOPERATIONS: '/api/ProfitTool/ProfitLayers/SaveOperations',
  LASTSYNCDATE: '/api/ProfitTool/ProfitLayers/GetLastSyncDate',
  ORGSYNCDATE: '/api/ProfitTool/ProfitLayers/OrganizationSyncDate',
  BOUNDARYSYNCDATE: '/api/ProfitTool/ProfitLayers/BoundarySyncDate',
  GETZONES: '/api/ProfitTool/ProfitLayers/GetZoneBoundaries',
  SAVEZONES: '/api/ProfitTool/ProfitLayers/SaveZone',
  ELEVATIONINDEX: '/api/ProfitTool/ProfitLayers/GetElevationIndex',
  SSURGOFORZONES: '/api/FarmscopeAPI/SSURGO',
  SSURGODATA: '/api/FarmscopeAPI/NewSSURGO',

  // Price Tool - AgAnalytics\APIControllers\PriceCollection\ProductPricesV2Controller.cs
  GETSEEDCROP: '/api/ProductPricesV2/GetSeedCrop',
  GETSEEDVARIETY: '/api/ProductPricesV2/GetSeedVariety',
  GETAPPLICATIONNAME: '/api/ProductPricesV2/GetApplicationName',
  GETHARVESTCROP: '/api/ProductPricesV2/GetHarvestCrop',
  GETHARVESTVARIETIES: '/api/ProductPricesV2/GetHarvestVariety',
  ADDPRICES: '/api/ProductPricesV2/AddPrices',
  DELETEPRICE: '/api/ProductPricesV2/DeletePrice?Id=',
  GETPRICES: '/api/ProductPricesV2/GetPrices?orgId=',
  DELETECONVERSION: '/api/ProductPricesV2/DeleteConversion',

  // AgIntegrated - AgAnalytics\APIControllers\AgIntegrated
  UPLOADFORAGINTEGRATED: '/api/AgIntegrated/UploadForAgIntegrated',
  CHECKAGINTEGRATEDSTATUSES: '/api/AgIntegrated/CheckAgIntegratedStatuses',

  // Cell Size
  defaultCellSize: '0.0001',
  highResCellSize: '0.000025',

  // NPK parser
  NUTRIENTPARSER: '/api/NutrientParser/NutrientParser',
  GETAPPLICATIONOPS: '/api/ProfitTool/ProfitLayers/GetApplicationForParser',
  GETNUTRIENTSUMMARY: '/api/ProfitTool/ProfitLayers/GetNutrientSummary',

  // Variety and Chem Parser
  VARIETYPARSER: '/api/ChemVarietyParser/Variety',
  CHEMICALPARSER: '/api/ChemVarietyParser/Chemical',

  // Map boundaries - AgAnalytics\APIControllers\BoundaryController.cs
  GETBOUNDARY: '/api/Boundary/GetBoundary',

  // ProfitTemplate
  GETTEMPLATESCENARIOS: '/api/ProfitTool/ProfitTemplate/GetCropScenarioIDS',
  DELETECROPTEMPLATE: '/api/ProfitTool/ProfitTemplate/DeleteCropTemplate',
  GETTEMPLATEOPERATIONS:
    '/api/ProfitTool/ProfitTemplate/GetCropTemplateOperations',
  SAVECROPTEMPLATE: '/api/ProfitTool/ProfitTemplate/SaveCropTemplate',

  // Braintree Merchant ID
  API_BRAINTREE_GOOGLEPAY_MERCHANTID: '128306854',
  API_CUSTOMER_PAYMENT_NONCE: '/api/PaymentProcessing/Customer/nonce',
  API_CUSTOMER: '/api/PaymentProcessing/Customer',
  API_SUBSCRIPTION: '/api/PaymentProcessing/Subscription',
  CURRENT: '/api/PaymentProcessing/Customer/Current',
  CUSTOMER_EXISTS: '/api/PaymentProcessing/Customer/exists',
  API_SUBSCRIPTION_BY_CUSTOMER: '/api/PaymentProcessing/Subscription/customer',
  API_SUBSCRIPTION_FEATURE_BY_SUBSCRIPTION:
    '/api/PaymentProcessing/SubscriptionFeature/subscription/',
  API_PLAN: '/api/PaymentProcessing/Plan',
  ALREADY_PURCHASED_ORGS:
    '/api/PaymentProcessing/Plan/GetPurchasedOrganizationsForPlan',
  API_DEALER_INFORMATION: '/api/PaymentProcessing/DealerInformation',
  API_SUBSCRIPTION_PAYMENT_METHOD: '/api/PaymentProcessing/Subscription/SubscriptionPaymentMethod',
  API_UPDATE_SUBSCRIPTION_PAYMENT_METHOD: '/api/PaymentProcessing/Subscription/UpdateSubscriptionPaymentMethod',
  API_GET_MULTIPLE_SUBSCRIPTION_FEATURES: '/api/PaymentProcessing/SubscriptionFeature/GetSubscriptionsFeatures',

  // Payment app V2 endpoints for single transactions
  ANON_API_CUSTOMER_PAYMENT_NONCE: '/api/PaymentProcessing/AnonymousCustomerV2/nonce',
  ANON_API_CUSTOMER: '/api/PaymentProcessing/AnonymousCustomerV2',
  ANON_CUSTOMER_EXISTS: '/api/PaymentProcessing/AnonymousCustomerV2/exists',
  ANON_API_HANDLE_TRANSACTION: '/api/PaymentProcessing/AnonymousCustomerV2/HandleTransaction',
  API_CUSTOMER_PAYMENT_NONCE_V2: '/api/PaymentProcessing/CustomerV2/nonce',
  API_CUSTOMER_V2: '/api/PaymentProcessing/CustomerV2',
  API_PAYMENT_METHODS_V2: '/api/PaymentProcessing/CustomerV2/paymentmethods',
  API_HANDLE_TRANSACTION: '/api/PaymentProcessing/CustomerV2/HandleTransaction',

  /* FIELD CLAIM */
  NEWORGID: '/api/DeereAdapter/NewOrgID',
  ADDORGANIZATION: '/api/CreateOrganization/AddOrganization',
  NEWGUID: '/api/DeereAdapter/NewGUID',
  ADDFARM: '/api/CreateOrganization/AddFarm',
  ADDBOUNDARY: '/api/User/AddBoundary',
  CREATEBARECLU: '/api/User/CreateBareCLU',
  ADDFARMSFORFIELDS: '/api/CreateOrganization/AddFarmsForFields',
  ADDCLIENT: '/api/CreateOrganization/AddClient',
  GETCLIENTS: '/api/User/GetClients',
  ADDCLIENTSFORFIELDS: '/api/CreateOrganization/AddClientsForFields',
  ADDCLIENTSFORFARMS: '/api/CreateOrganization/AddClientsForFarms',

  /* DATA LAYERS */
  NDVIHLS: '/api/Ndvi/HLSForNDVI',
  POLARIS: '/api/FarmScopeAPI/Polaris',
  CROPLAND: '/api/FarmScopeAPI/CDL',
  HLSFORNDVI: '/api/Ndvi/HLSForNDVI',
  SEVEREWEATHERALERTS: '/api/weatheralerts/getCurrentNWSAlertsForBounds',
  LANDVALUE: '/api/FarmScopeAPI/LandValue',
  SAR: '/api/FarmScopeAPI/SAR',
  TILEDRAIN: '/api/FarmScopeAPI/TileDrainage',
  SOILAI: '',
  // - AgAnalytics\APIControllers\ApiManagementController.cs
  GDD: '/api/ApiManagement/PRISMGDD',
  PRECIP: '/api/ApiManagement/PRISMPrecipitation',
  FARMCONDITIONS: 'api/ApiManagement/PRISMGDD?',
  PRECIPITATIONDATA: 'api/ApiManagement/PRISMPrecipitation?',
  RMARATES: '/api/ApiManagement/RMARATECALCULATE',

  // NDVIHLS: '/api/Ndvi/HLSForNDVI',
  // POLARIS: '/api/ApiManagement/POLARISGP/execute',
  // CROPLAND: '/api/CropID',
  // FARMCONDITIONS: 'api/ApiManagement/PRISMGDD?',
  // PRECIPITATIONDATA: 'api/ApiManagement/PRISMPrecipitation?',
  // SOILAI: '',

  // Insurance Tools - AgAnalytics\APIControllers\RMARatesData
  GETTYPE: '/api/RMARatesData/getType',
  GETCROPS: '/api/RMARatesData/getCrops',
  CROPSFORCOUNTY: '/api/RMARatesData/getCropsForLocation',
  PRICEANDVOLATILITY: '/api/RMARatesData/getPriceAndVolatility',
  PRACTICE: '/api/RMARatesData/getPractice',
  TAFACTOR: '/api/RMARatesData/getTAFactor',
  TYIELDS: '/api/RMARatesData/getTYields',
  STATENAMES: '/api/RMARatesData/getStateNames',
  COUNTYNAMES: '/api/RMARatesData/getCountyNames',
  EXCLUSIONYEARS: '/api/RMARatesData/getHarvestExclusionYears',
  // Insurance Tools - AgAnalytics\APIControllers\RMARates
  REFINEYIELD: '/api/RMARates/CalculateYieldFromHistory',
  CALCULATEYIELDFROMHISTORY: '/api/RMARates/CalculateYieldFromHistory',
  // YieldInsurance - AgAnalytics\APIControllers\RMARates\YieldInsuranceController.cs
  YIELDINSURANCE: '/api/YieldInsurance',
  YIELDFORINS: '/api/User/YieldForInsurance',
  // Others
  OWM: '/api/FarmScopeAPI/OWM',
  GUIDANCELINES: '/api/GuidanceLines/GuidanceLines',

  /* ACREAGE REPORTING */
  // Multiple Fields Information - AgAnalytics\APIControllers\AcreageReporting\MultipleFieldsController.cs
  GETSHAREDCLUEMAILS: '/api/MultipleFields/GetSharedCLUEmails',
  GETCLUINTERSECTIONSOLD: '/api/MultipleFields/GetCLUIntersections_old',
  GETCLUINTERSECTIONS: '/api/MultipleFields/GetCLUIntersections',
  GETNONCLUBOUNDARIES: '/api/MultipleFields/GetNonCLUBoundaries',
  GETNONCLUBOUNDARIESGIVENBOUNDS: '/api/MultipleFields/GetNonCLUBoundariesGivenBounds',
  GETCLUINFO: '/api/MultipleFields/GetCLUInfo',
  GETUSERCLUS: '/api/MultipleFields/GetUserCLUs',
  GETCLUACCESSES: '/api/MultipleFields/GetCLUAccesses',
  DELETECLUACCESS: '/api/MultipleFields/DeleteCLUAccess',
  SAVECLUACCESS: '/api/MultipleFields/SaveCLUAccess',
  GETMULTIPLEOPERATIONS: '/api/MultipleFields/GetMultipleOperations',
  GETOPERATIONZONES: '/api/MultipleFields/GetOperationZones',
  DELETEZONES: '/api/MultipleFields/DeleteZones',
  GETFIELDSINFO: '/api/MultipleFields/GetFieldsInfo',
  SAVEACREAGEZONES: '/api/MultipleFields/SaveZones',
  GETNEWZONEID: '/api/MultipleFields/CreateZoneID',
  // GETNEWCLUID: '/api/MultipleFields/CreateSudoCluID', // see datafetchers before using
  // RMA Info - AgAnalytics\APIControllers\AcreageReporting\RMAInfoController.cs
  GETCVTCROPS: '/api/RMAInfo/getCVTCrops',
  GETCVTMAPPING: '/api/RMAInfo/getCVTMapping',
  GETCVTCOMMODITYTYPES: '/api/RMAInfo/getCVTCommodityTypes',
  GETCVTINTENDEDUSES: '/api/RMAInfo/getCVTIntendedUses',
  GETRMACROPS: '/api/RMAInfo/getRMACrops',
  GETRMATYPES: '/api/RMAInfo/getRMATypes',
  GETRMAPRACTICES: '/api/RMAInfo/getRMAPractices',
  GETCLUDOWNLOADGUIDE: '/api/RMAInfo/getCLUDownloadGuide',
  // Operation Boundaries - AgAnalytics\APIControllers\BoundaryController.cs
  GETOPERATIONBOUNDARY: '/api/Boundary/GetOperationBoundary',
  UPDATEOPERATIONBOUNDARY: '/api/Boundary/UpdateOperationBoundary',
  ADDCLUBOUNDARY: '/api/Boundary/InsertCLU',
  // Reports - AgAnalytics\APIControllers\AcreageReporting\AcreageReportsController.cs
  GETUSERSACREAGEREPORTS: '/api/AcreageReports/GetUsersReports',
  GETHISTROICACREAGEREPORT: '/api/AcreageReports/GetHistoricReport',
  SAVEACREAGEREPORT: '/api/AcreageReports/SaveReport',
  UPLOADREPORTFILE: '/api/AcreageReports/UploadFile',
  GETUPLOADEDFILES: '/api/AcreageReports/GetUploadedFiles',
  DOWNLOADFILEFROMBLOB: '/api/AcreageReports/DownloadFileFromBlob',
  SUBMITRMAFILE: '/api/AcreageReports/SubmitRMAFile',
  GETRMASTATUS: '/api/AcreageReports/GetRMAStatus',
  GETRMASTATUSFROMDB: '/api/AcreageReports/GetRMAStatusFromDB',
  SAVEINQUIRYINFO: '/api/AcreageReports/SaveInquiryInfo',
  // Agent - AgAnalytics\APIControllers\AcreageReporting\AgentController.cs
  GETSHAREDEMAILS: '/api/Agent/GetSharedUsers',
  GETIMPERSONATEDORGS: '/api/Agent/GetImpersonatedOrgs',
  SENDDATASHARINGINVITE: '/api/Agent/SendDataSharingInvite',

  /* FIELD MANAGER APIs */
  GETFIELDINFORMATION: '/api/FieldManager/GetFieldInformation',
  DELETEFIELD: '/api/FieldManager/DeleteField',
  DELETEBOUNDARY: '/api/FieldManager/DeleteFieldBoundary',
  DELETEFARM: '/api/FieldManager/DeleteFarm',
  UPDATEFARM: '/api/FieldManager/UpdateFarm',
  UPDATEFIELD: '/api/FieldManager/UpdateField',
  UPDATEBOUNDARY: '/api/FieldManager/UpdateBoundary',
  UPDATECLIENTSFORFARMS: '/api/FieldManager/UpdateClientsForFarms',
  UPDATECLIENTSFORFIELDS: '/api/FieldManager/UpdateClientsForFields',

  // FieldManager anaytics endpoints
  APPLICATIONCOMPONENT: '/api/Analytics/ApplicationComponent',
  GETACRES: '/api/Analytics/GetAcres',
  GETYIELD: '/api/Analytics/GetYield',
  GETVARIETY: '/api/Analytics/GetVariety',
  GETPRECIPITATION: '/api/Analytics/GetPrecipitation',
  GETGDD: '/api/Analytics/GetGDD',
  GETNFACTOR: '/api/Analytics/GetNFactor',
  NUTRIENTPARSERFM: '/api/NutrientParser',

  /* LOAN UNDERWRITING */
  VALIDATETOKEN: '/api/Token/ValidateToken',
  GETINFOFROMTOKEN: '/api/LandValue/GetInfoFromToken',
  GETLOANORG: '/api/Token/GetOrgForLoan',
  CLAIMCLIENTFIELD: '/api/LandValue/ClaimClientField',
  PARCELSERVICE: '/api/LandValue/ParcelService',
  LANDVALUECOMP: '/api/LandValue/Comps',
  UPDATEPARCELBOUNDARY: '/api/LandValue/UpdateParcelBoundary',
  GETPARCELSUMMARIES: '/api/LoanSummary/ParcelValues',
  PDFEXTRACT: '/api/LandValue/PDFExtract',
  MARKCOLLAT: '/api/LoanSummary/MarkCollateral',
  UPDATEAPPRAISALDATE: '/api/LoanSummary/UpdateAppraisalDate',
  UPDATEPARCELINFO: '/api/LoanSummary/UpdateParcelInfo',
  BOUNDARYSEARCH: '/api/Boundary/SearchLegalBoundary',
  GETPREVIOUSBOUNDARIES: '/api/LoanSummary/GetPreviousBoundaries',
  GETSEARCHOPTIONS: '/api/Boundary/GetSearchOptions',
  GETCOMPSINFO: '/api/LoanSummary/GetComparablesInformation',
  GETCOUNTYAVERAGES: '/api/LoanSummary/GetCountyAverages',

  COGTILELAYER: '/api/VendorResponse/CogTileService',
  CHECKAUTHORIZATION: '/api/FarmscopeAPI/CheckUserAuthentication',

  GETNRCS: '/api/User/GetNRCS',
  GETCRP: '/api/User/GetCRP',
  GETCSP: '/api/User/GetCSP',
  GETOTHER: '/api/User/GetUnfunded',
  GETWATERMANAGEMENT: '/api/User/GetWaterManagement',
  UPDATEWATERMGMT: '/api/User/UpdateWaterManagement',
  ADDWATERMGMT: '/api/User/AddWaterManagement',

  REFERRALCODE: '6G3BRG3G',
  PROFITLAYERS_PRICE: 1000,
  PROFITLAYERS_50PER: 'GJFJGUSJ',
  ESRIADDIN_PRICE: 1000,

  // INTEGRATING SOIL TEST RESULTS WITHIN PROFITLAYERS
  GETORDERDETAILS: '/api/VendorResponse/GetOrderDetails',

  // Farm report API
  GETFARMOPERATIONS: '/api/FarmReport/GetFarmOperations',
  GETFIELDWITHPROFITSUMMARY: '/api/FarmReport/FieldWithProfitSummary',

  DONOTCALL: '/api/DoNotCall/LookUp',

  /* FARM REPORT LAYER ENDPOINTS */
  PROFIT_MAP_SUMMARIES: '/api/FarmReport/GetProfitSummary',
  FARM_OPERATIONS: '/api/FarmReport/GetFarmOperations',
  CROP_TYPES: '/api/FarmReport/GetCropType',
  CROP_VARIETIES: '/api/FarmReport/GetCropVariety',
  CHEMICAL_INPUTS: '/api/FarmReport/GetChemicalInput',
  ZONE_ANALYSIS_FOR_APPLICATION: '/api/FarmReport/ZoneAnalysisForApplication',
  GETSTATEANDCOUNTYOFFIELD: '/api/FarmReport/GetFarmsStateAndCounty',
  GETPRECIPITATIONDATA: '/api/FarmReport/GetPrecipitationData',
  GETNUTRIENTDATAFARM: '/api/FarmReport/GetNutrientRate',
};

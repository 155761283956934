import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { COLORS } from "../../../../constants";
import TableCellInner from "./TableCellInner";

const useStyles = makeStyles(() => ({
  heading: {
    fontSize: 14,
    display: "flex",
    flexDirection: "row",
    marginBottom: 5,
    height: "100%",
  },
  cell: {
    borderBottom: ({ showBorderCells }) =>
      showBorderCells ? `2px solid ${COLORS.green}` : "none",
  },
}));

const Header = ({
  order,
  orderBy,
  onRequestSort,
  headerCells,
  showBorderCells = true,
  center = false,
}) => {
  const classes = useStyles({ showBorderCells });

  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  return (
    <TableHead>
      <TableRow>
        {headerCells.map(({ heading, id, helper, disableSort }) => {
          return (
            <TableCell
              key={`table--header-for-${id}`}
              className={classes.cell}
              align={center ? "center" : "left"}
            >
              <div className={classes.heading}>
                <TableCellInner
                  text={heading}
                  disableSort={!!disableSort}
                  sortId={id}
                  order={order}
                  orderBy={orderBy}
                  helperText={helper}
                  createSortHandler={createSortHandler}
                />
              </div>
            </TableCell>
          );
        })}
      </TableRow>
    </TableHead>
  );
};
export default Header;

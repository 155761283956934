import React from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { ProfitLayersLink } from '../../Shared/ServiceLinks';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    color: theme.palette.text.primary,
  },
  reportCard: {
    margin: 4,
    border: `2px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    paddingBottom: 4,
  },
  summaryTitle: {
    marginRight: 4,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  summaryDescription: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
}));

/**
 * View testing summary
 * @param {Object} selectedSubOrder Suborder viewing
 * @returns {JSX} summary
 */
export const Summary = ({ selectedSubOrder }) => {
  const classes = useStyles();

  /* Display details of selected sub order for report. Sub Orders will contain
    the actual info for orders, including results of individual reports ordered,
    images, pdfs and potentially more. */
  const displaySubReport = (subReport) => (
    <Box
      mx={1}
      mb={1}
      display="flex"
      flexWrap="wrap"
    >
      {subReport?.summary?.length > 0 && subReport.summary.map(
        (reportSummary) => createReportCards(reportSummary),
      )}
    </Box>
  );

  // create cards for report summaries
  const createReportCards = (report) => {
    // When using vendor response page, if vendor inputs nothing we still get report
    // Check if empty here so don't show table with no data
    if (!validateReport(report.reportSummary)) {
      return '';
    }

    return (
      <Box key={report.reportName} className={classes.reportCard}>
        <Box bgcolor="#f2f2f2" p={1}>
          <Typography align="center" style={{ fontWeight: 500, fontSize: 20 }}>
            {report.reportName}
          </Typography>
        </Box>

        <Divider />

        { report?.reportSummary?.length > 0 && report.reportSummary.map((summary) => (

          <Box key={summary.title} my={0.5} px={1.5}>
            <Box py={1} display="flex">
              <Typography className={classes.summaryTitle}>
                {summary.title}
                :
              </Typography>
              <Typography className={classes.summaryDescription}>{summary.description}</Typography>
            </Box>
            <Divider />
          </Box>
        ))}
      </Box>
    );
  };

  const validateReport = (summary) => {
    for (let i = 0; i < summary.length; i++) {
      if (summary[i].title !== '' || summary[i].description !== '') {
        return true;
      }
    }
    return false;
  };

  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="h6">
          View your drainage and water management solutions
        </Typography>
        <Box pl={1}>
          <Typography>
            More even more details, view complete reports in the Detailed Reports tab
          </Typography>
        </Box>
      </Box>

      <Box p={1} display="flex" flexWrap="wrap">
        { displaySubReport(selectedSubOrder) }

        <Box p={1}>
          <ProfitLayersLink />
        </Box>
      </Box>
    </Box>
  );
};

Summary.propTypes = {
  selectedSubOrder: PropTypes.object.isRequired,
};

import React, { useEffect, useState, useRef } from 'react';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import * as _ from 'lodash'
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import { GeoSearchControl, OpenStreetMapProvider } from 'leaflet-geosearch';
import '../../Maps/leaflet.css'
import { exists } from '../../../utils/helpers'
import {
  Box, Select, Menu, MenuItem
} from '@material-ui/core/';
import { Endpoints } from '../../../constants/Endpoints';
import * as df from '../../../utils/dataFetchers'

export function HighResMap({ boundary, selectField, mapImage, extent, selectedFile, cogFiles, token }) {
  /*
    Map used simply for image overlays. Example uses are Profit Map and UAV Reports.
    Current version likely retains more functionality than will be necessary.
   */
  const [map, setMap] = useState(null)
  const cogLayer = useRef(null)
  const [colorBand, setColorBand] = useState('')

  const fieldLayer = L.featureGroup(null)

  useEffect(() => {
    drawMap()
  }, [])

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      try {
        const features = typeof (boundary) === 'object' ? boundary : JSON.parse(boundary)
        const geoJson = {
          "type": "FeatureCollection",
          "features": [features]
        }
        drawFieldLayer(geoJson)
        // fieldLayer.on('click', function () {
        //   selectField();
        // })
      } catch (err) {
        console.log(err)
      }

    }
  }, [boundary, map])

  useEffect(() => {
    if (exists([map, extent, mapImage])) {
      if (extent[0] !== undefined && extent[0][0] !== undefined) {
        //const image = L.imageOverlay(mapImage, extent, { opacity: 0.8, interactive: true })
        //image.on('click', function (d) { selectField() })
        //image.addTo(map)
        const lat = (extent[0][0] + extent[1][0]) / 2
        const long = (extent[0][1] + extent[1][1]) / 2
        map.panTo(new L.LatLng(lat, long));
      }
    }

  }, [mapImage, extent, map])

  // useEffect(() => {
  //   if(cogFiles.length>0){
  //     checkAuthorization(cogFiles[0]);
  //   }
  // }, [cogFiles])
  
  useEffect(() => {    
    //console.log('selectedFile', selectedFile)      
    if(selectedFile !== '' && map !== null){      
      getTileCogLayer()
    }    
  }, [map, selectedFile])

  const checkAuthorization = async(path) => {
    let res = df.checkAuthentication(path, token)
    console.log('res', res)
  }

  const getTileCogLayer = async () => {
    try{
      let blobPath = cogFiles[selectedFile]
     
      if(cogLayer.current !== null && map.hasLayer(cogLayer.current)){
        map.removeLayer(cogLayer.current);
      }
      try{
        let colorBand = "bidx=1,2,3"        
        let layer = L.tileLayer(`${Endpoints.BASEURL}${Endpoints.COGTILELAYER}?name=uav&z={z}&x={x}&y={y}&blobpath=${blobPath}&colorBand=${colorBand}&token=${token}`,
          { 
           maxNativeZoom: 25, 
           maxZoom: 25,
           crossOrigin: "use-credentials",
           fetchOptions: {credentials:'include'},
          }
         ).addTo(map)    
        cogLayer.current = layer
        //console.log('layer', layer)
      }
      catch(err){
        let colorBand = "color_map=viridis&bidx=1"
        let layer = L.tileLayer(`${Endpoints.BASEURL}${Endpoints.COGTILELAYER}?name=uav&z={z}&x={x}&y={y}&blobpath=${blobPath}&colorBand=${colorBand}&token=${token}`,
         { maxNativeZoom: 25, maxZoom: 25, fetchOptions: {credentials:'include'},  crossOrigin: "use-credentials" }).addTo(map)    
        cogLayer.current = layer
      }
    }
    catch(err){      
      console.log('couldn"t get open weather map imagery', err)
    }
  }

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      "https://api.mapbox.com/styles/v1/reactmap/ck899atuy0qrh1irw3x0pv2fl/tiles/256/{z}/{x}/{y}@2x?access_token=pk.eyJ1IjoicmVhY3RtYXAiLCJhIjoiY2s4OTk3dGphMDN2MTNlcGJvMDdlM3F4MCJ9.w1Mq06KCEsO1RQ-LA_s5Iw"
    )

    const provider = new OpenStreetMapProvider();
    const map = L.map('map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0
        }
      }
    }).setView([41.0160, -92.4083], 5).addLayer(mapboxTiles);

    function getGeom() {
      var bounds = map.getBounds()
      var max = bounds.getNorthEast()
      var min = bounds.getSouthWest()

      var geomParam = {
        "xmin": min.lng,
        "ymin": min.lat,
        "xmax": max.lng,
        "ymax": max.lat,
        "spatialReference": { "wkid": 4326 }
      }
      return geomParam
    }

    setMap(map)
   
    map.addLayer(fieldLayer);
  }

  function drawFieldLayer(geo) {
    try {
      const geometry = geo.features[0].geometry
      const boundary = geometry.type === 'Polygon' ? turf.polygon(geo.features[0].geometry.coordinates) : turf.multiPolygon(geo.features[0].geometry.coordinates)
      let newCollection = L.geoJson(boundary, {
        onEachFeature: (feature, layer) => {
          fieldLayer.addLayer(layer).setStyle({ color: "red", "opacity": 0.99, "fillOpacity": .20 })
        }
      })
      map.fitBounds(newCollection.getBounds());
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <div id="map" style={{ height: '100%', width: '100%' }}></div>
  )
}

import React from 'react';
import {
  CircularProgress,
} from '@material-ui/core';
import { green } from '../../styles/colors';
import { useWindowDimensions } from '../../utils/dimensions';

export const SpinningLoader = ({ top = '30%', position = 'absolute', size = 'lg' }) => {
  const { height, width } = useWindowDimensions();

  const pxl_size = size === 'lg' ? 140 : 60

  return (
    <CircularProgress
      size={width > 768 ? pxl_size : 60}
      style={{
        position,
        top,
        right: 0,
        left: 0,
        margin: 'auto',
        zIndex: 2005,
        color: green,
      }}
    />
  );
};

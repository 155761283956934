import React from "react";
import {
  makeStyles,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { COLORS } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
    color: COLORS.darkGray,
  },
  wrapper: ({ isFullWidth, isFlush }) => ({
    margin: isFlush ? theme.spacing(1, 0) : theme.spacing(1),
    minWidth: 120,
    borderRadius: 5,
    "& .MuiInputBase-root": {
      height: 40,
    },
  }),
  formControl: ({ isFullWidth }) => ({
    ...(isFullWidth ? { width: "100%" } : {}),
    "& .MuiSelect-select:focus": {
      backgroundColor: `rgba(0,0,0,0)`,
    },
  }),
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CustomSelectForMultiples = ({
  label,
  options = [],
  valueAccessor = (d) => d?.id,
  isFullWidth = false,
  isFlush = false,
  handleChange,
  index,
}) => {
  const classes = useStyles({ isFullWidth, isFlush });

  const selectedOption = options.find((option) => option.active);

  return (
    <div className={classes.wrapper}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <FormControl className={classes.formControl} variant="outlined">
        <Select
          value={valueAccessor(selectedOption)}
          onChange={(e) => handleChange(e, index)}
        >
          {options.map((option) => {
            return (
              <MenuItem
                key={`menu-item-for-${valueAccessor(option)}`}
                value={valueAccessor(option)}
              >
                {valueAccessor(option)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelectForMultiples;

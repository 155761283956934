import React from 'react';
import PropTypes from 'prop-types';

import {
  Box, Modal, Button, IconButton, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// For downloading 2021_PARS_Download CLU Files from FSA+.pdf
import { downloadFile } from '../../../utils/helpers';
import { Endpoints } from "../../../constants/Endpoints";

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: '8px 0',
    overflowY: 'auto',
    maxWidth: 440,
  },
  header: {
    fontSize: 28,
    fontWeight: 700,
    margin: '18px 0 8px 0',
    fontFamily: 'OpenSans',
    textAlign: 'center',
  },
  text: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '1rem',
    color: theme.palette.text.primary,
    // textAlign: 'center',
  },
  close: {
    position: 'absolute',
    top: -6,
    right: 0,
  },
  button: {
    width: 220,
    height: 40,
    textTransform: 'none',
    fontSize: '1rem',
    fontFamily: 'OpenSans',
    color: 'white',
  },
}));

export const Help = ({ open, setOpen, primaryColor }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="shape-file-upload"
      aria-describedby="upload-zipped-shapefiles-or-json"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
      >
        <IconButton
          className={classes.close}
          style={{ color: primaryColor }}
          onClick={() => setOpen(false)}
        >
          <HighlightOffIcon />
        </IconButton>

        <Box px={4} display="flex" flexDirection="column" justifyContent="center">
          <Typography className={classes.header}>
            Need Help?
          </Typography>

          <Box mt={1} px={3}>
            <Typography className={classes.text}>
              Here is a detailed guide on how you can download your CLU files from FSA Farm+.
            </Typography>
          </Box>

          <Box my={3} display="flex" justifyContent="center">
            <Button
              className={classes.button}
              style={{ background: primaryColor }}
              onClick={async () => {
                const downloadLink = `${Endpoints.BASEURL}${Endpoints.GETCLUDOWNLOADGUIDE}`;
                downloadFile(downloadLink, 'PARS Guide to Download CLU Files from FSA+.pdf');
              }}
            >
              Download Guide
            </Button>
          </Box>
        </Box>

      </Box>
    </Modal>
  );
};

Help.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  primaryColor: PropTypes.string.isRequired,
};

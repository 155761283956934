import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import {
  Box, 
  Button, 
  FormControl,
  FormHelperText,
  MenuItem, 
  Modal, 
  TextField, 
  Typography,
  Select, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import {
  acresModifiedReasons,
  geospatialShapeModifiedReasonCodes,
  plantingDateModifiedReasonCodes,
} from '../presetData';
import * as _ from 'underscore';
import { useSnackbar } from 'notistack';


const useStyles = makeStyles((theme) => ({
  explanationOption: {
    margin: '10px 24px 16px 16px',
  },
  paper: {
    ...theme.centeredModal,
    padding: 8,
    // minWidth: 360,
  },
  reasonBox: {
    marginBottom: '8px',
  },
  selects: {
    height: 44.6,
    // minWidth: 200,
    // maxWidth: 200,
    width: 244,
    backgroundColor: '#ffffff',
  },
}));

/**
 * Rendered in AcreageReporting/AgreageReporing.js
 * User will be prompted to provide an explanation for change when certain values are updated
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Function} setOpen Sets if modal is open
 * @param {Array} explanations List of explanations required
 * @param {Function} updateOperation Updates operation with explanations
 * @param {Function} updateIntersection Updates some elements for a CLU intersection
 * @param {Object} operation Needed for indexes for updating and values to prefill
 * @param {String} providingExplanationFor operation or clu
 * @return {JSX} Modal for change explanation
 */
export const ChangeExplanation = ({
  open,
  setOpen,
  explanations,
  updateOperation,
  updateIntersection,
  operation,
  providingExplanationFor,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [updates, setUpdates] = useState({});
  // Used to not create an infinte recursion in useEffect below and to update what is shown to user from here
  // Could have just set explanations initially to include new option but would require different checks for errors
  const [usedExplanations, setUsedExplanations] = useState([]);

  const [dateReason, setDateReason] = useState('');
  const [dateText, setDateText] = useState('');
  const [acreageReason, setAcreageReason] = useState('');
  const [acreageText, setAcreageText] = useState('');
  const [geospatialReason, setGeospatialReason] = useState('');
  const [geospatialText, setGeospatialText] = useState('');

  // Load in values from operation/CLU on open
  useEffect(() => {
    // console.log('providingExplanationFor :>> ', providingExplanationFor);
    // console.log('operation :>> ', operation);
    // console.log('_.size(operation) :>> ', _.size(operation));
    // Stop if operation does not exist yet
    if (!(_.size(operation)) || !(_.size(explanations))) {
      return;
    }
    // We want this to always be up-to-date in case explanations somehow changes again
    setUsedExplanations((prevState) => [...prevState, ...explanations]);

    // Operation
    if (providingExplanationFor === 'operation') {
      const dateCode = operation.plantedDateModifiedReasonCode;
      const dateText = operation.plantedDateModifiedOtherReasonText;
      const acrCode = operation.reportedAcreageModifiedReasonCode;
      const acrText = operation.reportedAcreageModifiedOtherReasonText;
      const geoCode = operation.geospatialShapeModifiedReasonCode;
      const geoText = operation.geospatialShapeModifiedOtherReasonText;
      setDateReason(dateCode ? dateCode : '');
      setDateText(dateText ? dateText : '');
      setAcreageReason(acrCode ? acrCode : '');
      setAcreageText(acrText ? acrText : '');
      setGeospatialReason(geoCode ? geoCode : '');
      setGeospatialText(geoText ? geoText : '');
      
      // Store values that will need to be modified on save
      if (explanations.includes('finalPlantedDate')) {
        const updates = {
          finalPlantedDate: operation.finalPlantedDate, 
          plantedDateModifiedIndicator: 'Y',
        };
        // These could be needed after loading data (if there's a mismatch between operation and CLUs)
        if (dateCode) {updates.plantedDateModifiedReasonCode = dateCode};
        if (dateText) {updates.plantedDateModifiedOtherReasonText = dateText};
        setUpdates(updates);
      }
      else if (explanations.includes('geospatialShapeModifiedIndicator')) {
        const updates = {
          geospatialShapeModifiedIndicator: 'Y',
          geospatialShapeProcessDate: new Date().toISOString(),
          reportedAcreageModifiedIndicator: 'Y', // this will not be set automatically
        };
        // Include any existing geoCode and geoText in updates as new CLU intersections are gotten in this case and just saving wouldn't including these
        if (geoCode) {updates.geospatialShapeModifiedReasonCode = geoCode};
        if (geoText) {updates.geospatialShapeModifiedOtherReasonText = geoText};
        if (acrCode) {updates.reportedAcreageModifiedReasonCode = acrCode};
        if (acrText) {updates.reportedAcreageModifiedOtherReasonText = acrText};
        setUpdates(updates);
        setUsedExplanations((prevState) => [...prevState, 'finalReportedAcreage']);
      }
      // Updating finalReportedAcreage only happens when zoneType is 'GART' so no need to worry about updating the CLUs
      else if (explanations.includes('finalReportedAcreage')) {
        const updates = {
          finalReportedAcreage: operation.finalReportedAcreage,
          reportedAcreageModifiedIndicator: 'Y',
        };
        setUpdates(updates);
      }

      // Verifying explanations
      if (!(explanations.includes('finalPlantedDate') || explanations.includes('geospatialShapeModifiedIndicator') || explanations.includes('finalReportedAcreage')) || _.size(explanations > 1)) {
        // console.log('Revisit logic if explanations for operation does not contain either finalPlantedDate, geospatialShapeModifiedIndicator, or finalReportedAcreage or contains something more than just one of those.');
        // console.log('explanations :>> ', explanations);
      }
    // CLU
    } else {
      if (operation.cluIndex !== undefined) {
        const cluToChange = operation.CLUInfo[operation.cluIndex];
        // console.log('cluToChange :>> ', cluToChange);
        setDateReason(cluToChange.plantedDateModifiedReasonCode ? cluToChange.plantedDateModifiedReasonCode : '');
        setDateText(cluToChange.plantedDateModifiedOtherReasonText ? cluToChange.plantedDateModifiedOtherReasonText : '');
        setAcreageReason(cluToChange.reportedAcreageModifiedReasonCode ? cluToChange.reportedAcreageModifiedReasonCode : '');
        setAcreageText(cluToChange.reportedAcreageModifiedOtherReasonText ? cluToChange.reportedAcreageModifiedOtherReasonText : '');
        setGeospatialReason(cluToChange.geospatialShapeModifiedReasonCode ? cluToChange.geospatialShapeModifiedReasonCode : '');
        setGeospatialText(cluToChange.geospatialShapeModifiedOtherReasonText ? cluToChange.geospatialShapeModifiedOtherReasonText : '');

        // Store values that will need to be modified on save
        if (explanations.includes('finalReportedAcreage')) {
          setUpdates((prevState) => ({ 
            ...prevState, 
            finalReportedAcreage: cluToChange.finalReportedAcreage,
            reportedAcreageModifiedIndicator: 'Y',
          }));
        }

        // Verifying explanations
        if (!(explanations.includes('finalReportedAcreage')) || _.size(explanations > 1)) {
          // console.log('Revisit logic if explanations for CLU does not contain finalReportedAcreage or contains something other it.');
          // console.log('explanations :>> ', explanations);
        }
      }
    }
  }, [providingExplanationFor, operation, explanations])


  // On cancel, will reset the affected value back to what they were before changes and save those
  // The only things affected would have been the modified values and none of their associated elements
  const cancel = () => {
    let toUpdate;
    let indexesToUpdate = {
      farmIndex: operation.farmIndex,
      fieldIndex: operation.fieldIndex,
      opIndex: operation.opIndex,
    };

    if (providingExplanationFor === 'operation') {
      if (usedExplanations.includes('finalPlantedDate')) {
        toUpdate = [
          {finalPlantedDate: operation.prevDateInfo[0]},
          {plantedDateModifiedIndicator: operation.prevDateInfo[1]},
        ]
      }
      else if (usedExplanations.includes('finalReportedAcreage')) {
        toUpdate = [
          {finalReportedAcreage: operation.prevAreaInfo[0]},
          {reportedAcreageModifiedIndicator: operation.prevAreaInfo[1]},
        ]
      }
      updateOperation(toUpdate, indexesToUpdate, true);
    } else {
      const prevAreaInfo = operation.CLUInfo[operation.cluIndex].prevAreaInfo;
      // When we cancel, we don't know what the previous reportedAcreageModifiedIndicator was so need to store it too
      toUpdate = [
        {finalReportedAcreage: prevAreaInfo[0]},
        {reportedAcreageModifiedIndicator: prevAreaInfo[1]},
      ];
      indexesToUpdate.cluIndex = operation.cluIndex;
      updateIntersection(toUpdate, indexesToUpdate);
    }

    setOpen(false);
  }

  // Validates that user has entered a value for all required inputs
  const checkInputs = () => {
    // Check appropriate boxes shown to user
    if (usedExplanations.includes('finalPlantedDate')) {
      if (dateReason === '') return false;
      if (dateReason === 'O' && dateText === '') return false;
    }
    if (usedExplanations.includes('finalReportedAcreage')) {
      if (acreageReason === '') return false;
      if (acreageReason === 'O' && acreageText === '') return false;
    }
    if (usedExplanations.includes('geospatialShapeModifiedIndicator')) {
      if (geospatialReason === '') return false;
      if (geospatialReason === 'O' && geospatialText === '') return false;
      if (acreageReason === '') return false;
      if (acreageReason === 'O' && acreageText === '') return false;
    }
    return true;
  }

  // On save, will save the user entered reason and text as well as officially update plantingDate/Acres in orderedMappings
  const save = () => {
    // Make sure the user has entered all required values before allowing them to save
    const valid = checkInputs();
    if (!valid) {
      enqueueSnackbar('Please provide required explanations for all changes');
      return;
    }

    // Convert object used for tracking state in to array of objects
    const updatesToMake = Object.entries(updates).map((e) => ({ [e[0]]: e[1] }));
    let indexesToUpdate = {
      farmIndex: operation.farmIndex,
      fieldIndex: operation.fieldIndex,
      opIndex: operation.opIndex,
    };

    // These will call the basic versions: basicUpdateIntersection and basicUpdateOperation as we do not need any side effects
    if (providingExplanationFor === 'operation') {
      updateOperation(updatesToMake, indexesToUpdate);
    } else {
      // Right now, finalReportedAcreage is the only thing that uses this at CLU level. 
      // The acreage, indicator and prevInfo do not need to change, but we DO need to store user input code and text.
      indexesToUpdate.cluIndex = operation.cluIndex;
      updateIntersection(updatesToMake, indexesToUpdate);
    }

    setOpen(false);
  };

  const handleDateText = (event) => {
    setUpdates({ ...updates, plantedDateModifiedOtherReasonText: event.target.value });
  };

  const handleAcreageText = (event) => {
    setUpdates({ ...updates, reportedAcreageModifiedOtherReasonText: event.target.value });
  };

  const handleGeoShapeText = (event) => {
    setUpdates({ ...updates, geospatialShapeModifiedOtherReasonText: event.target.value });
  };

  return (
    <Modal
      open={open}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="change-explanation"
      aria-describedby="explain-reason-data-was-changed"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
      >
        {/* Date Reason Box */}
        {usedExplanations && usedExplanations.includes('finalPlantedDate') && (
          <Box className={classes.explanationOption}>
            <Box className={classes.reasonBox}>
              <Typography>Reason for Modifying Date *</Typography>
              <FormControl error={dateReason === ''}>
                <Select
                  variant="outlined"
                  displayEmpty
                  value={dateReason}
                  className={classes.selects}
                  onChange={(e) => {
                    setDateReason(e.target.value);
                    setUpdates({ ...updates, plantedDateModifiedReasonCode: e.target.value });
                  }}
                >
                  <MenuItem value="">
                    <em>--Select--</em>
                  </MenuItem>
                  {
                    plantingDateModifiedReasonCodes.map((reason) => (
                      <MenuItem
                        key={reason.code}
                        value={reason.code}
                      >
                        {reason.display}
                      </MenuItem>
                    ))
                  }
                </Select>
                {dateReason === '' && (
                  <FormHelperText >Please select a modification reason</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box>
              {dateReason === 'O' ?
                <Typography>Further Explanation (max. 80 characters) *</Typography>
              :
                <Typography>Further Explanation (max. 80 characters)</Typography>
              }
              <TextField
                variant="outlined"
                multiline
                rows={4}
                value={dateText}
                onChange={(event) => setDateText(event.target.value)}
                onBlur={handleDateText}
                inputProps={{ maxLength: 80 }}
                error={dateReason === 'O' && dateText === ''} 
                helperText={dateReason === 'O' && dateText === '' && 'Please enter a modification reason'}
              />
            </Box>
          </Box>
        )}

        {/* Geospatial Shape Reason Box */}
        {usedExplanations && usedExplanations.includes('geospatialShapeModifiedIndicator') && (
          <Box className={classes.explanationOption}>
            <Box className={classes.reasonBox}>
              <Typography>Reason for Modifying Geospatial Shape *</Typography>
              <FormControl error={geospatialReason === ''}>
                <Select
                  variant="outlined"
                  displayEmpty
                  value={geospatialReason}
                  className={classes.selects}
                  onChange={(e) => {
                    setGeospatialReason(e.target.value);
                    setUpdates({ ...updates, geospatialShapeModifiedReasonCode: e.target.value });
                  }}
                >
                  <MenuItem value="">
                    <em>--Select--</em>
                  </MenuItem>
                  {
                    geospatialShapeModifiedReasonCodes.map((reason) => (
                      <MenuItem
                        key={reason.code}
                        value={reason.code}
                      >
                        {reason.display}
                      </MenuItem>
                    ))
                  }
                </Select>
                {geospatialReason === '' && (
                  <FormHelperText >Please select a modification reason</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box>
              {geospatialReason === 'O' ?
                <Typography>Further Explanation (max. 80 characters) *</Typography>
              :
                <Typography>Further Explanation (max. 80 characters)</Typography>
              }
              <TextField
                variant="outlined"
                multiline
                rows={4}
                value={geospatialText}
                onChange={(event) => setGeospatialText(event.target.value)}
                onBlur={handleGeoShapeText}
                inputProps={{ maxLength: 80 }}
                error={geospatialReason === 'O' && geospatialText === ''} 
                helperText={geospatialReason === 'O' && geospatialText === '' && 'Please enter a modification reason'}
              />
            </Box>
          </Box>
        )}

        {/* Acreage Reason Box */}
        {usedExplanations && usedExplanations.includes('finalReportedAcreage') && (
          <Box className={classes.explanationOption}>
            <Box className={classes.reasonBox}>
              <Typography>Reason for Modifying Acreage *</Typography>
              <FormControl error={acreageReason === ''}>
                <Select
                  variant="outlined"
                  displayEmpty
                  value={acreageReason}
                  className={classes.selects}
                  onChange={(e) => {
                    setAcreageReason(e.target.value);
                    setUpdates({ ...updates, reportedAcreageModifiedReasonCode: e.target.value });
                  }}
                >
                  <MenuItem value="">
                    <em>--Select--</em>
                  </MenuItem>
                  {
                    acresModifiedReasons.map((reason) => (
                      <MenuItem
                        key={reason.code}
                        value={reason.code}
                      >
                        {reason.display}
                      </MenuItem>
                    ))
                  }
                </Select>
                {acreageReason === '' && (
                  <FormHelperText >Please select a modification reason</FormHelperText>
                )}
              </FormControl>
            </Box>

            <Box>
              {acreageReason === 'O' ?
                <Typography>Further Explanation (max. 80 characters) *</Typography>
              :
                <Typography>Further Explanation (max. 80 characters)</Typography>
              }
              <TextField
                variant="outlined"
                multiline
                rows={4}
                value={acreageText}
                onChange={(event) => setAcreageText(event.target.value)}
                onBlur={handleAcreageText}
                inputProps={{ maxLength: 80 }}
                error={acreageReason === 'O' && acreageText === ''} 
                helperText={acreageReason === 'O' && acreageText === '' && 'Please enter a modification reason'}
              />
            </Box>
          </Box>
        )}

        {/* Cancel and Save buttons */}
        <Box m={1} display="flex" flexWrap="wrap" justifyContent="center">
          {(usedExplanations && usedExplanations.includes('geospatialShapeModifiedIndicator')) ? ( '' ) 
          : (
            <Button
              color="primary"
              variant="outlined"
              size="large"
              onClick={() => cancel()}
              disableElevation
              style={{ margin: 8 }}
            >
              Cancel
            </Button>
          )}

          <Button
            color="primary"
            variant="contained"
            size="large"
            onClick={() => save()}
            disableElevation
            style={{ margin: 8 }}
          >
            Save
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

ChangeExplanation.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  explanations: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  updateOperation: PropTypes.func.isRequired,
  updateIntersection: PropTypes.func.isRequired,
  operation: PropTypes.shape({
    acres: PropTypes.number.isRequired,
    crop: PropTypes.string.isRequired,
    crops: PropTypes.string.isRequired,
    croppingPracticeCode: PropTypes.number.isRequired,
    cropRowCount: PropTypes.number,
    cropRowWidth: PropTypes.number,
    cropType: PropTypes.string.isRequired,
    cropTypes: PropTypes.string.isRequired,
    CLUInfo: PropTypes.arrayOf(PropTypes.shape({
      shown: PropTypes.bool.isRequired,
      zoneID: PropTypes.string.isRequired,
    })),
    cluProducerReviewRequestIndicator: PropTypes.string,
    error: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
    finalReportedAcreage: PropTypes.number,
    includeGeospatialInReport: PropTypes.string.isRequired,
    intendedUse: PropTypes.string.isRequired,
    intendedUses: PropTypes.string.isRequired,
    irrigationPracticeCode: PropTypes.string.isRequired,
    micsCode: PropTypes.string.isRequired,
    micsName: PropTypes.string.isRequired,
    operationID: PropTypes.string.isRequired,
    organicPracticeTypeCode: PropTypes.string.isRequired,
    productPlantingCode: PropTypes.string,
    reportedAcreageModifiedIndicator: PropTypes.string,
    sharePercentage: PropTypes.number.isRequired,
    shown: PropTypes.bool,
    skipRowConversionFactor: PropTypes.number,
    skipRowCount: PropTypes.number,
    skipRowWidth: PropTypes.number,
    skipRowPatternCode: PropTypes.string,
    stateCode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    varietyList: PropTypes.string.isRequired,
    varietyNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    year: PropTypes.number.isRequired,
    zoneID: PropTypes.string,
    zoneIDsFromDB: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  providingExplanationFor: PropTypes.string.isRequired,
};

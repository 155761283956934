import React from 'react';

import {
  Box, Tabs, Tab,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';

import { grey, green } from '../../../styles/colors';
import { roundIcon, mobileRoundIcon } from '../../../styles/icons';

import { useWindowDimensions } from '../../../utils/dimensions';

const useStyles = makeStyles((theme) => ({
  appBarSelections: theme.appBarSelections,
  appBarSelectionsMobile: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 10px',
    color: green,
    fontSize: '22px',
    fontWeight: 700,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  icon: theme.navigationArrows,
  mobileSteps: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
  },
  mobileTabs: theme.mobileTabs,
}));

export const Steps = ({
  changeSection,
  goToProfitLayers,
  mobileBreakPoint,
  mobileLayers,
  pdfLayer,
  section,
  selectedLayer,
  setSelectedLayer,
}) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const stepsBreakPoint = 960;

  const handleLayerChange = (event, value) => {
    // Changing to pdf layer is not handled by the steps so it will never call this (handleLayerChange).
    // This next line will only happen when user attempts to go to profit layers through the steps on mobile (and in this case, layer number should be the same as pdf layer)
    if (value === pdfLayer) {
      goToProfitLayers();
    } else {
      setSelectedLayer(value);
    }
  };

  const mobileOperationSelection = () => (
    <Box style={{ width }}>
      <Tabs
        value={selectedLayer}
        indicatorColor="primary"
        textColor="primary"

        onChange={handleLayerChange}
        variant="scrollable"
        scrollButtons="on"
        style={{ maxHeight: 30, minHeight: 30 }}
      >
        {mobileLayers.map((layerName) => (
          // Do not show the temporary ProfitLayers tab on mobile
          layerName !== 'ProfitLayers' && (
            <Tab 
              key={layerName}
              className={classes.mobileTabs}
              label={layerName}
            />
          )
        ))}
      </Tabs>
    </Box>
  );

  return (
    width > mobileBreakPoint ? (
      <Box display="flex">
        <Box
          className={classes.appBarSelections}
          color={green}
          onClick={() => changeSection(0)}
          style={{ width: '180px', margin: width > stepsBreakPoint ? '0 50px' : '0 16px' }}
        >
          {section > 0 ? (
            <CheckBoxRoundedIcon
              style={{ marginRight: '10px', fontSize: 27 }}
            />
          ) : (
            <Box {...roundIcon}>1</Box>
          )}
          Select Field
        </Box>

        <Box
          id="view_data_layers"
          className={classes.appBarSelections}
          color={section > 0 ? green : grey}
          onClick={() => changeSection(1)}
          style={{ width: '230px', margin: width > stepsBreakPoint ? '0 50px' : '0 8px' }}
        >
          {section > 1 ? (
            <CheckBoxRoundedIcon
              style={{ marginRight: '10px', fontSize: 27 }}
            />
          ) : (
            <Box {...roundIcon}>2</Box>
          )}
          View Data Layers
        </Box>

        <Box
          className={classes.appBarSelections}
          color={section > 1 ? green : grey}
          onClick={() => goToProfitLayers()}
          style={{ width: '230px', margin: width > stepsBreakPoint ? '0 50px' : '0 16px' }}
        >
          {section > 2 ? (
            <CheckBoxRoundedIcon
              style={{ marginRight: '10px', fontSize: 27 }}
            />
          ) : (
            <Box {...roundIcon}>3</Box>
          )}
          Setup ProfitLayers
        </Box>
      </Box>
    ) : (
      <>
        {/* Mobile */}
        { section === 0 ? (
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            style={{ width: '100%' }}
          >
            <Box className={classes.icon} />
            <Box className={classes.appBarSelectionsMobile}>
              <Box {...roundIcon}>1</Box>
              Select Field
            </Box>
            <ArrowForwardIosIcon
              className={classes.icon}
              onClick={() => changeSection(1)}
            />
          </Box>
        ) : (
          <Box className={classes.mobileSteps}>
            <Box
              display="flex"
              flexDirection="column"
              justifyContent="center"
              alignItems="center"
              style={{ width: '100%' }}
            >
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                style={{ marginTop: 8 }}
                color={green}
                fontWeight={500}
              >
                <Box
                  {...mobileRoundIcon}
                >
                  2
                </Box>
                <Box id="view_data_layers" fontSize={20}>
                  View Data Layers
                </Box>
              </Box>

              { mobileOperationSelection() }
            </Box>
          </Box>
        )}
      </>
    )
  );
};

// -------------------- IMPORTS --------------------
import React, { useEffect, useState } from 'react';

// MUI and Styles
import {
  Box, Button, Divider, FormControl, Select, MenuItem, Modal,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from '../../../styles/select';
import { convertToLocalDatetime } from '../helpers';

// API calls and other functionality
import {
  getHistoricAcreageReport,
  getRMAStatus,
  getUsersAcreageReports,
  saveInquiryInfo,
} from '../../../utils/dataFetchers';
import { ViewRMAStatus } from './ViewRMAStatus';
import { SpinningLoader } from '../../Shared/SpinningLoader';

// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  displayTime: {
    height: 44.6,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    backgroundColor: theme.palette.greys.light,
    borderRadius: 4,
    border: `1px solid ${theme.palette.greys.main}`,
  },
  display: {
    height: 44.6,
    width: 130,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 8px',
    backgroundColor: theme.palette.greys.light,
    borderRadius: 4,
    border: `1px solid ${theme.palette.greys.main}`,
  },
  entry: {
    padding: "8px",
  },
  reportBox: {
    margin: "8px 16px",
  },
  reportData: {
    padding: 8,
    border: `2px solid ${theme.palette.greys.dark}`,
    borderRadius: 4,
    textAlign: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  root: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  select: {
    height: 44.6,
    minWidth: 200,
  },
}));

// -------------------- MAIN FUNCTION --------------------
/**
 * Rendered in AcreageReporting/AcreageReporting.js
 * User may view and download previously submitted reports
 * @returns {JSX} ViewSubmitted
 */
export function ViewSubmitted() {
  // -------------------- VARIABLES --------------------
  //#region - variables
  const classes = useStyles();

  // List of reports to show to user
  const [reports, setReports] = useState([]);
  // Selection from reports list
  const [selectedReport, setSelectedReport] = useState('');
  // Actaul data of selected report from DB
  const [selectedReportData, setSelectedReportData] = useState(null);

  // Variables to deal with viewing RMA submission status
  const rmaSubmission = 'RMA ACRSI Intake Endpoint'; // RMA Intake transmission stored as 'RMA ACRSI Intake Endpoint'
  const [RMAResponseToUse, setRMAResponseToUse] = useState(null);
  const [openACRSIStatusModal, setOpenACRSIStatusModal] = useState(false);
  const [loading, setLoading] = useState(false);
  //#endregion

  // -------------------- USEEFFECTS --------------------
  useEffect(() => {
    getReports();
  }, []);

  // -------------------- FUNCTIONALITY --------------------
  //#region - handle reports
  // Get report names and IDs so user can pick which one to get more info about.
  // Automatically get more info on the first returned report
  const getReports = async () => {
    try {
      setLoading(true);
      const acreageReports = await getUsersAcreageReports();
      if (acreageReports?.length) {
        setReports(acreageReports);
        selectReport(acreageReports[0]);
      }
      else {
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Upon user selecting a report, get more information on that report by its ID and parse out AcrsiFile for displaying info
  const selectReport = async (report) => {
    try {
      setLoading(true);
      setSelectedReport(report);
      const response = await getHistoricAcreageReport(report.ID);

      if (response) {
        const reportData = response[0];
        reportData.AcrsiFile = JSON.parse(reportData.AcrsiFile);
        // console.log('reportData :>> ', reportData);
        setSelectedReportData(reportData);
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  // Downloads user-selected report when user clicks on appropriate button
  const downloadReport = () => {
    try {
      const dataStr = `data:text/json;charset=utf-8, ${JSON.stringify(selectedReportData.AcrsiFile)}`;
      const downloadAnchorNode = document.createElement('a');
      downloadAnchorNode.setAttribute('href', dataStr);
      downloadAnchorNode.setAttribute('download', `${selectedReport.reportName}.json`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    } catch (err) {
      console.error(err);
    }
  };
  //#endregion

  // Opens a modal to view status message from ACRSI RMA inquiry endpoint
  // If last saved status was '{"status":"File still processing"}', then get an updated status from RMA endpoint, 
  // display it to the user, and save it in Reports table 
  const handleACRSIStatus = async () => {
    setLoading(true);

    // Helpful vars
    let stillProcessing = '{"status":"File still processing"}'; // "{\"status\":\"File still processing\"}";
    let status = selectedReportData.RMAResponse;
    const transmissionID = selectedReportData.originalTransmissionId;

    // If there was no saved transmissionID, then that's a problem - shouldn't happen but not much we can do now
    if (transmissionID === '') {
      status = '{"error": "There is no transmissionID associated with your submission. You might have lost connection before this was properly stored in our database. Unfortunately, with no transmissionID, we cannot check the status of your submission. If you lost connection when submitting your data, there is no guarantee the report data was properly sent. You may try submitting the report data again."}';
    }
    // Check whether status was '{"status":"File still processing"}'
    else if (status === '' || status === stillProcessing) {
      // Needed vars
      const tableID = selectedReportData.ID;

      // Get updated status - Call RMA ACRSI inquiry endpoint
      const response = await getRMAStatus(transmissionID);
      // console.log('response :>> ', response);

      const requestBody = {tableID, transmissionID};
      // Check return
      if (!response || typeof response === 'string' || !response.status) {
        // Something went wrong. Notify user
        status = '{"error": "Something went wrong while attempting to retrieve the status of your submission. Please try again later."}';
      }
      else {
        // Use newly retrieved status
        status = response.status;
        requestBody.RMAResponse = response.status;

        // Save transmissionID and updated status to Reports table
        await saveInquiryInfo(requestBody);
      }
    }

    // Display status to user
    setRMAResponseToUse(status);
    setOpenACRSIStatusModal(true);
    setLoading(false);
  }

  //#region - handle JSX display
  const dateDisplay = (date) => {
    try {
      // This wasn't displaying the right date so changed it for now. Maybe should make sure util helper functions are working right
      // const fullDate = formatDate(date);
      // const time = formatTime(new Date(date));
      // return `${fullDate} ${time}`;
      const newDate = new Date(date);
      convertToLocalDatetime(newDate);
      return newDate.toLocaleString();
    } catch (err) {
      console.error(err);
      return date;
    }
  };
  
  const nameDisplay = (customer) => {
    const name = `${customer.firstName} ${customer.lastName}`;
    return (
      <Box className={classes.entry} key={name}>
        <Box>
          Customer
        </Box>
        <Box className={classes.display}>
          {name}
        </Box>
      </Box>
    );
  };

  // JSX showing some metadata on chosen report
  const reportMetaData = () => (
    <Box className={classes.reportBox} >
      <Box className={classes.reportData} maxWidth={400}>
        <Box p={1} textAlign="center" fontSize="1.2rem">
          {selectedReportData.reportName}
        </Box>
        <Box display="flex" flexWrap="wrap" width="100%" justifyContent="space-evenly">
          <Box className={classes.entry}>
            <Box>
              Reinsurance Year
            </Box>
            <Box className={classes.display}>
              {selectedReportData['Reinsurance Year']}
            </Box>

          </Box>
          <Box className={classes.entry}>
            <Box>
              Commodity Year
            </Box>
            <Box className={classes.display}>
              {selectedReportData.AcrsiFile.cropYear}
            </Box>
          </Box>
        </Box>

        <Box className={classes.entry}>
          <Box>
            Created At
          </Box>
          <Box className={classes.displayTime}>
            {dateDisplay(selectedReportData.created_at)}
          </Box>
        </Box>

        <Box className={classes.entry}>
          <Box>
            Transmitted At
          </Box>
          <Box className={classes.displayTime}>
            {dateDisplay(selectedReportData.transmission_date)}
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap" width="100%" justifyContent="space-evenly">
          {selectedReportData.AcrsiFile.customer.length && (
            selectedReportData.AcrsiFile.customer.map((customerInfo) => nameDisplay(customerInfo))
          )}

          <Box className={classes.entry}>
            <Box>
              Tax Identification
            </Box>
            <Box className={classes.display}>
              {selectedReportData.taxIdentification}
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  // Displays the content of this step. JSX of header and body
  const submittedReports = () => (
    <Box className={classes.root}>
      {/* Step header */}
      <Box>
        <Box
          textAlign="center"
          fontSize={20}
          pt={3}
          pb={2}
        >
          <Box fontWeight={500}>
            View and download your generated reports
          </Box>
        </Box>

        <Divider />
      </Box>

      {/* Step body */}
      <Box p={1} display="flex" justifyContent="center">
        {/* Report Selection */}
        <Box className={classes.reportBox} minWidth={300}>
          <Box className={classes.reportData}>
            <Box className={classes.entry} mb={1}>
              <Box fontWeight={500}>
                Select Report
              </Box>
              <FormControl variant="outlined" className={classes.formControl}>
                <Select
                  className={classes.select}
                  id="report-select"
                  value={selectedReport || ''}
                  onChange={(e) => selectReport(e.target.value)}
                  inputProps={{ 'aria-label': 'Without label' }}
                  MenuProps={MenuProps}
                >
                  { reports.map((report) => (
                    <MenuItem key={report.ID} value={report}>{report.reportName}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>

            <Box className={classes.entry}>
              {selectedReportData && (
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={downloadReport}
                >
                  {`Download Report`}
                  {/* {`Download ${selectedReport.reportName}`} */}
                </Button>
              )}
            </Box>

            {(selectedReportData && selectedReportData.transmitted_to === rmaSubmission) && (
              <Box className={classes.entry}>
                <Button
                  color="primary"
                  variant="contained"
                  disableElevation
                  onClick={handleACRSIStatus}
                >
                  {`View ACRSI Status`}
                </Button>
              </Box>
            )}
          </Box>
        </Box>
        
        {/* Information on selected report */}
        { selectedReportData && reportMetaData() }
      </Box>

      {openACRSIStatusModal && RMAResponseToUse &&
        <ViewRMAStatus
          open={openACRSIStatusModal}
          setOpen={setOpenACRSIStatusModal}
          statusInfo={RMAResponseToUse}
        />
      }

      {/* Loading Spinner */}
      {loading && <SpinningLoader />}
    </Box>
  )
  //#endregion

  // -------------------- RETURN --------------------
  return (submittedReports());
}

import React from "react";
import { makeStyles } from "@material-ui/core";
import { CHART_TYPES, COLORS } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  footerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    marginTop: "33px",
  },
  legendBox: {
    padding: "7px 14.5px 8px 9px",
    borderRadius: "5px",
    backgroundColor: COLORS.veryLightGrey,
    color: COLORS.text,
    fontStyle: "italic",
    fontSize: "14px",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .circle": {
      backgroundColor: COLORS.darkGray,
      borderRadius: "50%",
      width: "10px",
      height: "10px",
      "&.county": {
        backgroundColor: COLORS.gray,
      },
      "&.farm": {
        backgroundColor: COLORS.gray,
        border: `2px solid ${COLORS.text}`,
      },
    },
    "& :first-child": {
      marginRight: "10.5px",
    },
  },
  wrapper: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
}));

const Footer = ({ type }) => {
  const classes = useStyles();

  return (
    <div className={classes.footerContainer}>
      {type === CHART_TYPES.ScatterCompare ? (
        <div className={classes.legendBox}>
          <div className={classes.wrapper}>
            <div className="circle county" />
            <div>= County</div>
          </div>
          <div className={classes.wrapper}>
            <div className="circle farm" />
            <div>= Your Farm</div>
          </div>
        </div>
      ) : (
        <div className={classes.legendBox}>
          <div className="circle" />
          <div>Each dot is a field, hover to see details</div>
        </div>
      )}
    </div>
  );
};

export default Footer;

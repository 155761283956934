import React, { useState, useEffect } from 'react';
import { Snackbar, Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import Mailchimp from './Mailchimp';
import { sleep, getRandomInt } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px 2px 8px',
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    backgroundColor: '#ffffff',
  },
  closeIcon: {
    ...theme.closeIcon,
    fontSize: 18,
  },
}));

/**
 * Display mailing list sign up form. Utilizes session storage to make sure user does not
 * see popup multiple times in same visit. Randomly display different message options to
 * keep it fresh if same user visits again not logged in.
 * @param {Function} alertClose Optional. If called from PopupManager to alert to close
 * @returns {JSX} modal poppup
 */
export const MailChimpForm = ({ alertClose }) => {
  const classes = useStyles();

  const [open, setOpen] = useState(false);

  // Which message from list user will see
  const [messageNumber, setMessageNumber] = useState(0);

  const messages = [
    'Sign up for our newsletter to stay at the forefront of agricultural technology!',
    <span>
      Get Email Updates including ProfitLayers
      <sup>&reg;</sup>
      {' '}
      feature additions, promotional offers, events, and more!
    </span>,
  ];

  useEffect(() => {
    let hasSeen = false;
    try {
      const viewed = sessionStorage.getItem('viewed');
      const userHasSeen = JSON.parse(viewed);
      hasSeen = userHasSeen?.newsletter;
      if (!hasSeen) {
        sessionStorage.setItem('viewed', JSON.stringify({ newsletter: true }));
        // Randomly choose message
        setMessageNumber(getRandomInt(2));
        // Countdown to open
        beginOpenCount();
      }
    } catch (err) {
      console.error(err);
    }
  }, []);

  const beginOpenCount = async () => {
    await sleep(4000);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    // If called from popup mananger, this will let it know popup has closed
    if (alertClose) {
      alertClose();
    }
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      open={open}
      key="bottomright"
      style={{ marginBottom: 46, zIndex: 1001 }}
    >
      <Box className={classes.root} boxShadow={5} width={messageNumber === 0 ? 280 : 334}>
        <Box display="flex" justifyContent="flex-end">
          <HighlightOffIcon className={classes.closeIcon} onClick={handleClose} />
        </Box>

        <Typography variant="subtitle2">
          {messages[messageNumber]}
        </Typography>

        <Mailchimp
          action="https://ag-analytics.us20.list-manage.com/subscribe/post?u=8af96436d9a631880769a768b&amp;id=a5e28f194f"
          fields={[
            {
              name: 'EMAIL',
              placeholder: 'Email',
              type: 'email',
              required: true,
            },
          ]}
        />
      </Box>
    </Snackbar>
  );
};

MailChimpForm.propTypes = {
  alertClose: PropTypes.func,
};

MailChimpForm.defaultProps = {
  alertClose: undefined,
};

import React, { Fragment } from 'react';
import { Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Endpoints } from '../../../constants/Endpoints';
import { blackText } from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  link: {
    color: blackText,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
}));

export const Instructions = ({createNew, openTemplate, type, canAdd, integrated}) => {
  const classes = useStyles();

  return (
    <Box pl={1} fontWeight={500} fontSize={16} color={blackText}>

      <Box py={1}>
        {`To begin, generate an operation from a `}
        {
          <span
            className={classes.link}
            onClick={() => openTemplate()}
          >
            {`predefined editable template`}
          </span>
        }

        { canAdd &&
          <Fragment>
            {` or `}
            {
              <span
                className={classes.link}
                onClick={() => createNew()}
              >
                {`create a new operation with optional zones`}
              </span>}
            {''}
          </Fragment>
        }
        {'.'}
      </Box>

      {
        !integrated &&
        <Box py={1}>
          {'You can also seamlessly load operations by '}
          {
            <a
              className={classes.link}
              href={`${Endpoints.BASEURL}/integrate`}
            >
              connecting your Precision Ag system.
            </a>
          }
        </Box>
      }

    </Box>
  )
}

import React, { useState, useEffect, useContext, useRef } from 'react'
import {
  Box, Button, Typography, TextField, FormLabel, RadioGroup, Radio, FormControlLabel, Select, MenuItem, Checkbox, FormControl, InputLabel
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Endpoints } from '../../constants/Endpoints'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  grey,
  lightGrey,
  darkGrey,
  green,
  blackText,
  darkText,
} from "../../styles/colors";
import * as d3 from 'd3-geo'
import * as wkt from 'terraformer-wkt-parser';
import { CheckBox } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export function WaterManagement({ field, waterMgmtInfo, updateWaterMgmt }) {

  /**
   * Update Field Information
   * @param {Object} field selected field data
   * @param {Object} fieldInformation field information of field selected
   * @param {Function} deleteField deletes field from fieldManager.Fields table (sets isDeleted flag to 1)
   * @param {Function} updateField updates field information
   * @param {Array} fields list all fields associated to selected org and farm
   * @param {Function} handleFieldSelect set new field selected
   */

  // console.log("field", field)
  // console.log("fieldInformation", fieldInformation)

  const classes = useStyles();
  //Dialog controls
  const [open, setOpen] = useState(false);
  //Field informatipon
  const [fieldName, setFieldName] = useState('')
  const [isIrrigated, setIsIrrigated] = useState('no')
  const [showIrrigated, setShowIrrigated] = useState(false)
  const [irrigationMethod, setIrrigationMethod] = useState('')
  const [waterSource, setWaterSource] = useState('')
  const [energySource, setEnergySource] = useState('')
  const [waterCostUnit, setWaterCostUnit] = useState('')
  const [isPam, setIsPam] = useState('yes')
  const [surfaceDrainage, setSurfaceDrainage] = useState('yes')
  const [tile, setTile] = useState('no')
  const [drainage, setDrainage] = useState('yes')
  const [showTileType, setShowTileType] = useState(false)
  const [tileType, setTileType] = useState('')

  const [waterShed, setWaterShed] = useState('')
  const [waterCost, setWaterCost] = useState(0)
  const [maintaianceCost, setMaintainanceCost] = useState(0)

  const [standardDensity, setStandardDensity] = useState(false)
  const [highDensity, setHighDensity] = useState(false)

  const [finalInfo, setFinalInfo] = useState({})

  useEffect(() => {
    console.log('waterMgmtInfo', waterMgmtInfo)
    if (waterMgmtInfo.length > 0) {
      let waterInfo = waterMgmtInfo.length > 1 ? waterMgmtInfo[1] : waterMgmtInfo[0]
      setFinalInfo(waterInfo)
      setWaterShed(waterInfo.fieldWatershed);
      setWaterCost(waterInfo.waterCost);
      setWaterCostUnit(waterInfo.waterCostUnit);
      setMaintainanceCost(waterInfo.maintenanceCost)

      if (waterInfo.isIrrigated == null || waterInfo.isIrrigated == 0 || waterInfo.isIrrigated == false) {
        handleIsIrrigated('no')
      }
      else {
        handleIsIrrigated('yes')
      }

      setIrrigationMethod(waterInfo.fieldIrrigationMethod);
      setWaterSource(waterInfo.fieldIrrigationSource);
      setEnergySource(waterInfo.energySource);

      if (waterInfo.isDrainage == null || waterInfo.isDrainage == 0 || waterInfo.isDrainage == false) {
        setSurfaceDrainage('no')
      }
      else {
        setSurfaceDrainage('yes')
      }

      if (waterInfo.fieldTiled == null || waterInfo.fieldTiled == 0 || waterInfo.fieldTiled == false) {
        handleTileDrainage('no')
      }
      else {
        handleTileDrainage('yes')

        if (waterInfo.drainageType.includes('standardDensity')) {
          setStandardDensity(true)
        }

        if (waterInfo.drainageType.includes('highDensity')) {
          setHighDensity(true)
        }
      }

      if (waterInfo.pam == null || waterInfo.pam == 0 || waterInfo.pam == false) {
        setIsPam('no')
      }
      else {
        setIsPam('yes')
      }

      if (waterInfo.useDrainage == null || waterInfo.useDrainage == 0 || waterInfo.useDrainage == false) {
        setDrainage('no')
      }
      else {
        setDrainage('yes')
      }
    }

  }, [waterMgmtInfo])

  const confirmUpdate = () => {
    console.log("confirmUpdate")

    var isirrigated = (isIrrigated == 'yes') ? 1 : 0;

    var issurface = (surfaceDrainage == 'yes') ? 1 : 0;

    var ispam = (isPam == 'yes') ? 1 : 0;

    var isdrainage = (drainage == 'yes') ? 1 : 0;

    var fieldTiled = (tile == 'yes') ? 1 : 0;

    var tileDrainage = [];
    if (standardDensity) {
      tileDrainage.push('standardDensity')
    }
    if (highDensity) {
      tileDrainage.push('highDensity')
    }
    var tileDrainageList = tileDrainage.join(", ");

    var request = {
      fieldInstanceId: field.id,
      fieldWatershed: waterShed,
      isIrrigated: isirrigated,
      fieldIrrigationMethod: irrigationMethod,
      irrgationSource: waterSource,
      pam: ispam,
      energySource: energySource,
      isDrainage: issurface,
      drainageType: tileDrainageList,
      fieldTiled: fieldTiled,
      useDrainage: isdrainage,
      isNrcs: finalInfo.isNrcs !== undefined ? Number(finalInfo.isNrcs) : 0,
      nrcsList: finalInfo.nrcsList !== undefined ? finalInfo.nrcsList : "",
      isCrp: finalInfo.isCrp !== undefined ? Number(finalInfo.isCrp) : 0,
      crpList: finalInfo.crpList !== undefined ? finalInfo.crpList : "",
      isCsp: finalInfo.isCsp !== undefined ? Number(finalInfo.isCsp) : 0,
      cspList: finalInfo.cspList !== undefined ? finalInfo.cspList : "",
      isOther: finalInfo.isOther !== undefined ? Number(finalInfo.isOther) : 0,
      conservationList: finalInfo.conservationList !== undefined ? finalInfo.conservationList : "",
      waterCost: waterCost,
      waterCostUnit: waterCostUnit,
      maintenanceCost: maintaianceCost,
      OrganizationID: field.orgId
    }

    updateWaterMgmt(request)
  }

  const handleIsIrrigated = (val) => {
    setIsIrrigated(val)
    if (val == 'yes') {
      setShowIrrigated(true)
    }
    else {
      setShowIrrigated(false)
    }
  }

  const handleTileDrainage = (val) => {
    setTile(val)
    if (val == 'yes') {
      setShowTileType(true)
    }
    else {
      setShowTileType(false)
    }
  }

  return (
    <Box my={1} style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center">
        <Typography variant="h6" style={{ color: green }}>Water Management</Typography>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box mx={2} my={1}>
          <TextField
            id="outlined-helperText"
            label="Field Watershed"
            helperText="Enter field watershed"
            variant="outlined"
            value={waterShed}
            onChange={(e) => setWaterShed(e.target.value)}
          />
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Is this field irrigated</FormLabel>
            <RadioGroup aria-label="purchase" name="isIrrigated" value={isIrrigated} onChange={(e) => handleIsIrrigated(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showIrrigated}>
          <Box mx={2} my={1} style={{ width: "100%" }}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <TextField
                id="outlined-helperText"
                label="Irrigation Water Cost"
                helperText="Enter irrigation water cost"
                variant="outlined"
                value={waterCost}
                style={{ marginRight: "5px" }}
                onChange={(e) => setWaterCost(e.target.value)}
              />
            </FormControl>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">Water Cost Unit</InputLabel>
              <Select style={{ width: "150px" }} value={waterCostUnit} onChange={(e) => setWaterCostUnit(e.target.value)} label="Water Cost Unit" helperText="Select water cost unit">
                <MenuItem value='$/year'>$/year</MenuItem>
                <MenuItem value='$/gallon'>$/gallon</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showIrrigated}>
          <Box mx={2} my={1}>
            <TextField
              id="outlined-helperText"
              label="Maintenance Cost"
              helperText="Enter irrigation maintenance cost"
              variant="outlined"
              style={{ width: "200%" }}
              value={maintaianceCost}
              onChange={(e) => setMaintainanceCost(e.target.value)}
            />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showIrrigated}>
          <Box mx={2} my={1}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">Irrigation method used</InputLabel>
              <Select style={{ width: "300px" }} value={irrigationMethod} onChange={(e) => setIrrigationMethod(e.target.value)} label="Irrigation method used" helperText="Select irrigation method used">
                <MenuItem value='Overhead - Center pivot sprinkler'>Overhead - Center pivot sprinkler</MenuItem>
                <MenuItem value='Overhead - Lateral move sprinkler'>Overhead - Lateral move sprinkler</MenuItem>
                <MenuItem value='Overhead - Traveling gun'>Overhead - Traveling gun</MenuItem>
                <MenuItem value='Subsurface - Drip/Trickle'>Subsurface - Drip/Trickle</MenuItem>
                <MenuItem value='Surface - Drip/Trickle'>Surface - Drip/Trickle</MenuItem>
                <MenuItem value='Surface - Every furrow'>Surface - Every furrow</MenuItem>
                <MenuItem value='Surface - Every other furrow'>Surface - Every other furrow</MenuItem>
                <MenuItem value='Surface - Flood'>Surface - Flood</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showIrrigated}>
          <Box mx={2} my={1}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">Irrigation water source</InputLabel>
              <Select style={{ width: "300px" }} value={waterSource} onChange={(e) => setWaterSource(e.target.value)} label="Irrigation water source" helperText="Select irrigation water source">
                <MenuItem value='Irrigation District'>Irrigation District</MenuItem>
                <MenuItem value='Municipal Pipe'>Municipal Pipe</MenuItem>
                <MenuItem value='On-Farm Holding Pond'>On-Farm Holding Pond</MenuItem>
                <MenuItem value='Reservoir'>Reservoir</MenuItem>
                <MenuItem value='River/Stream'>River/Stream</MenuItem>
                <MenuItem value='Well (Aquifer)'>Well (Aquifer)</MenuItem>
                <MenuItem value='Well (Groundwater)'>Well (Groundwater)</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showIrrigated}>
          <Box mx={2} my={1}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">Energy source for pump</InputLabel>
              <Select style={{ width: "300px" }} value={energySource} onChange={(e) => setEnergySource(e.target.value)} label="Energy source for pump" helperText="Select energy source for pump">
                <MenuItem value='Diesel'>Diesel</MenuItem>
                <MenuItem value='Electric-Grid'>Electric-Grid</MenuItem>
                <MenuItem value='Electric-Solar'>Electric-Solar</MenuItem>
                <MenuItem value='Electric-Wind'>Electric-Wind</MenuItem>
                <MenuItem value='Natural Gas'>Natural Gas</MenuItem>
                <MenuItem value='Propane'>Propane</MenuItem>
              </Select>
            </FormControl>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Do you use Polyacrylamide (PAM) for runoff control/infiltration</FormLabel>
            <RadioGroup value={isPam} onChange={(e) => setIsPam(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Surface drainage installed? (grassed waterways, in-field ditchs)</FormLabel>
            <RadioGroup value={surfaceDrainage} onChange={(e) => setSurfaceDrainage(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Tile Drainage Installed?</FormLabel>
            <RadioGroup value={tile} onChange={(e) => handleTileDrainage(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between" hidden={!showTileType}>
          <Box mx={2} my={1}>
            <FormLabel component="legend">Tile Drainage</FormLabel>
            <FormControlLabel control={<Checkbox checked={standardDensity} onChange={(e) => setStandardDensity(!standardDensity)} name='standardDensity' />} label="Standard Density (50 feet or more between laterals)" />
            <FormControlLabel control={<Checkbox checked={highDensity} onChange={(e) => setHighDensity(!highDensity)} name='highDensity' />} label="High Density (less than 50 feet between laterals)" />
          </Box>
        </Box>

        <Box display="flex" justifyContent="space-between">
          <Box mx={2} my={1}>
            <FormLabel component="legend">Do you use Drainage Water Management in the field?</FormLabel>
            <RadioGroup value={drainage} onChange={(e) => setDrainage(e.target.value)}>
              <FormControlLabel value="yes" control={<Radio />} label="Yes" />
              <FormControlLabel value="no" control={<Radio />} label="No" />
            </RadioGroup>
          </Box>
        </Box>

        <Box display="flex"> {/** edit buttons */}
          <Box mx={2} my={1}>
            <Button variant="contained" color="primary" onClick={() => confirmUpdate()} disableElevation>Save Changes</Button>
          </Box>
        </Box>
      </Box>
    </Box>
  )
}

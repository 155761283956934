import React, { useEffect, useState } from "react";
import ReportContainer from "../../ReportContainer/index";
import {
  Box,
  TextField,
  FormControl,
  CircularProgress,
} from "@material-ui/core";
import { Endpoints } from "../../../constants/Endpoints";
import * as df from "../../../utils/dataFetchers";
import { PaymentApp } from "../Reports/PaymentApp";

export function FarmReportContainer({
  field,
  selectedYears,
  convertPlantings,
  years,
  profitMapAccess,
  processing,
  setProcessing,
  authenticated,
  setProfitMapAccess,
  handlePayment,
  isDisabled,
  orgs,
}) {
  const [basePrice, setBasePrice] = useState(Endpoints.PROFITLAYERS_PRICE);
  const [discount, setDiscount] = useState(0);
  const [referralcode, setReferralcode] = useState("");

  const handleReferralCode = async (value) => {
    setReferralcode(value);

    try {
      let res = await df.checkReferralCode(value);
      console.log(res);
      if (Object.keys(res).length > 0 && res.percentageDiscount !== undefined) {
        setDiscount(res.percentageDiscount);
      } else {
        setDiscount(0);
      }
    } catch (err) {
      console.log("error verifying disocunt code", err);
      setDiscount(0);
    }
  };

  return (
    <>
      {profitMapAccess ? (
        <ReportContainer
          reportType={"farm"}
          orgId={field.orgId}
          selectedYear={selectedYears[0]}
          selectedFarm={field.farmId}
          yearsList={years}
          fromProfitLayers={true}
          profitMapAccess={profitMapAccess}
          setProfitMapAccess={setProfitMapAccess}
        />
      ) : (
        <Box
          display="flex"
          style={{
            height: "100%",
            overflow: "auto",
          }}
        >
          {!processing ? (
            <Box
              style={{
                paddingTop: 16,
                display: "flex",
                //justifyContent: 'center',
                alignItems: "center",
                flexDirection: "column",
                margin: "auto",
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                px={1}
                style={{
                  fontSize: "2em",
                  textAlign: "center",
                }}
              >
                Please sign up for a yearly subscription to get unlimited Profit
                Layers and access to Farm Report for Organization: {field.org} (
                {field.orgId})
              </Box>
              <Box textAlign="center" fontSize={"2em"}>
                {"($" + Endpoints.PROFITLAYERS_PRICE + " per year)"}
              </Box>
              <Box
                mb={1}
                style={{
                  fontSize: "1em",
                  textAlign: "center",
                }}
              >
                Note: The subscription comes with a{" "}
                <span style={{ fontWeight: 500 }}>
                  30-day money back guarantee
                </span>{" "}
                and renews automatically at ${Endpoints.PROFITLAYERS_PRICE} per
                year.
              </Box>
              <Box style={{ width: "25%" }}>
                <FormControl
                  variant="outlined"
                  style={{ position: "relative", display: "flex" }}
                >
                  <TextField
                    value={referralcode}
                    onChange={(event) => handleReferralCode(event.target.value)}
                    label="Discount Code"
                  />
                </FormControl>
              </Box>

              <PaymentApp
                authenticated={authenticated}
                setProfitMapAccess={setProfitMapAccess}
                setProcessing={setProcessing}
                field={field}
                handlePayment={handlePayment}
                isDisabled={isDisabled}
                orgs={orgs}
                referralcode={referralcode}
              />
            </Box>
          ) : (
            <Box p={3}>
              <Box
                p={3}
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                boxShadow={2}
                borderRadius="borderRadius"
                fontWeight={500}
                fontSize={20}
              >
                <Box mb={2} px={1}>
                  Thank your for your purchase. We're processing your order.
                </Box>
                <CircularProgress color="primary" />
              </Box>
            </Box>
          )}
        </Box>
      )}
    </>
  );
}

import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import Description from "./Description";
import VisualizationContainer from "./VisualizationContainer";
import { formatDate } from "../../../Helpers/FarmOperation/helper";
import { Nutrients } from "../../../../constants";
import { getSoilAndWeatherFormat } from "../../../Helpers/MarketReportHelper/helper";
import { MARKET_TABS } from "../../../../constants";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 0px 25px 0",
  },
}));

/**
 * Renders the market report overview
 * @param {boolean} mobileView for mobile views
 */
const Overview = ({
  mobileView,
  selectedState,
  orgId,
  harvestVarietyForState,
  seedingVarietyForState,
  harvestDataForState,
  seedingDataForState,
  harvestVarietyForFarm,
  seedingVarietyForFarm,
  harvestDataForFarm,
  seedingDataForFarm,
  loadedStateData,
  loadedFarmData,
  nutrientDataForState,
  nutrientDataForFarm,
  precipForState,
  precipDataForFarm,
  soilDataForState,
  soilDataForFarm,
  nccpiForState,
  nccpiForFarm,
  gddForFarm,
  gddForState,
}) => {
  const classes = useStyles();

  const [categories, setCategories] = useState([   
    { name: MARKET_TABS.Yields, active: true },
    { name: MARKET_TABS.Seeding, active: false },
    // { name: MARKET_TABS.Soil, active: false }, //commented this to hide soil and weather info 
    { name: MARKET_TABS.Chemicals, active: false },
  ]);
  // This is example data
  const [data] = useState({
    Seeding: [
      {
        item: "Soybeans",
        values: [
          { name: "County", value: 32000, avgPlantingDate: "3/21" },
          { name: "Your Farm", value: 30000, avgPlantingDate: "3/13" },
        ],
        varieties: [
          {
            item: "Variety 1",
            values: [
              { name: "County", value: 43000, avgPlantingDate: "3/08" },
              { name: "Your Farm", value: 39000, avgPlantingDate: "4/13" },
            ],
          },
          {
            item: "Variety 2",
            values: [
              { name: "County", value: 54000, avgPlantingDate: "5/07" },
              { name: "Your Farm", value: 67000, avgPlantingDate: "2/02" },
            ],
          },
          {
            item: "Variety 3",
            values: [
              { name: "County", value: 65000, avgPlantingDate: "8/19" },
              { name: "Your Farm", value: 68000, avgPlantingDate: "7/10" },
            ],
          },
        ],
      },
      {
        item: "Corn",
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
        varieties: [],
      },
    ],
    Yields: [
      {
        item: "Soybeans",
        values: [
          { name: "County", value: 32000, avgPlantingDate: "3/21" },
          { name: "Your Farm", value: 30000, avgPlantingDate: "3/13" },
        ],
        varieties: [
          {
            item: "Variety 1",
            values: [
              { name: "County", value: 43000, avgPlantingDate: "3/08" },
              { name: "Your Farm", value: 39000, avgPlantingDate: "4/13" },
            ],
          },
          {
            item: "Variety 2",
            values: [
              { name: "County", value: 54000, avgPlantingDate: "5/07" },
              { name: "Your Farm", value: 67000, avgPlantingDate: "2/02" },
            ],
          },
          {
            item: "Variety 3",
            values: [
              { name: "County", value: 65000, avgPlantingDate: "8/19" },
              { name: "Your Farm", value: 68000, avgPlantingDate: "7/10" },
            ],
          },
        ],
      },
      {
        item: "Corn",
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
    ],
    "Soil & Weather": [
      {
        values: [
          { name: "County", value: 32000, avgPlantingDate: "3/21" },
          { name: "Your Farm", value: 30000, avgPlantingDate: "3/13" },
        ],
      },
      {
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
      {
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
      {
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
      {
        values: [
          { name: "County", value: 32000, avgPlantingDate: "3/21" },
          { name: "Your Farm", value: 30000, avgPlantingDate: "3/13" },
        ],
      },
      {
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
      {
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
    ],
    Chemicals: [
      {
        item: "Nitrogen",
        values: [
          { name: "County", value: 32000, avgPlantingDate: "3/21" },
          { name: "Your Farm", value: 30000, avgPlantingDate: "3/13" },
        ],
      },
      {
        item: "Phosporous",
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
      {
        item: "Potassium",
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
      {
        item: "Sulfur",
        values: [
          { name: "County", value: 58, avgPlantingDate: "5/23" },
          { name: "Your Farm", value: 73, avgPlantingDate: "5/17" },
        ],
      },
    ],
  });
  const activeCategory = categories.find((category) => category.active);

  const [overviewData, setOverviewData] = useState([]);
  const [harvestData, setHarvestData] = useState([]);
  const [seedingData, setSeedingData] = useState([]);
  const [nutrients, setNutrients] = useState([]);
  const [soilWeather, setSoilWeather] = useState([]);

  useEffect(() => {
    if (loadedFarmData && loadedStateData) {
      //Group harvest related data
      let harCropsState = harvestDataForState !== null ? harvestDataForState.map((x) => x.CropName) : [];
      let harCropsFarm = harvestDataForFarm !== null ? harvestDataForFarm.map((x) => x.CropName) : [];
      let harvestCrops = [...new Set([...harCropsState, ...harCropsFarm])];
      let harData = [];

      for (let crop of harvestCrops) {
        let farmVar = harvestVarietyForFarm
          .filter((h) => h.Crop == crop)
          .map((x) => x.Variety);
        let stateVar = harvestVarietyForState
          .filter((h) => h.Crop == crop)
          .map((x) => x.Variety);
        let state = harvestDataForState.filter((h) => h.CropName == crop)[0];
        let farm = harvestDataForFarm.filter((h) => h.CropName == crop)[0];

        let variety = [...new Set([...farmVar, ...stateVar])];
        //console.log("variety", variety)

        let obj = {
          item: crop,
          values: [
            {
              name: "State",
              value: state == undefined ? 0 : state?.AverageHarvestYield,
              avgPlantingDate:
                state == undefined ? "" : formatDate(state?.HarvestingDate),
            },
            {
              name: "Your Farm",
              value: farm == undefined ? 0 : farm?.AverageHarvestYield,
              avgPlantingDate:
                farm == undefined ? "" : formatDate(farm?.HarvestingDate),
            },
          ],
        };
        let varietyInfo = [];

        for (let v of variety) {
          let statev = harvestVarietyForState.filter(
            (h) => h.Crop == crop && h.Variety == v
          )[0];
          let farmv = harvestVarietyForFarm.filter(
            (h) => h.Crop == crop && h.Variety == v
          )[0];

          let varobj = {
            item: v,
            values: [
              {
                name: "State",
                value: statev == undefined ? 0 : statev?.VarietyYield,
                avgPlantingDate:
                  statev == undefined ? "" : formatDate(statev?.HarvestingDate),
              },
              {
                name: "Your Farm",
                value: farmv == undefined ? 0 : farmv?.VarietyYield,
                avgPlantingDate:
                  farmv == undefined ? "" : formatDate(farmv?.HarvestingDate),
              },
            ],
          };
          varietyInfo.push(varobj);
        }

        obj.varieties = varietyInfo;
        harData.push(obj);
      }
      //console.log("harData", harData);
      if (harData !== undefined) setHarvestData(harData);

      //Group seeding related data
      let seedCropsState = seedingDataForState !== null ? seedingDataForState.map((x) => x.CropName) : [];
      let seedCropsFarm = seedingDataForFarm !== null ? seedingDataForFarm.map((x) => x.CropName) : [];
      let seedCrops = [...new Set([...seedCropsState, ...seedCropsFarm])];
      let seedData = [];

      for (let crop of seedCrops) {
        let farmVar = seedingVarietyForFarm
          .filter((h) => h.Crop == crop)
          .map((x) => x.Variety);
        let stateVar = seedingVarietyForState
          .filter((h) => h.Crop == crop)
          .map((x) => x.Variety);
        let state = seedingDataForState.filter((h) => h.CropName == crop)[0];
        let farm = seedingDataForFarm.filter((h) => h.CropName == crop)[0];

        let variety = [...new Set([...farmVar, ...stateVar])];

        let obj = {
          item: crop,
          values: [
            {
              name: "State",
              value: state == undefined ? 0 : state?.AverageSeedingRate,
              avgPlantingDate:
                state == undefined ? "" : state?.PlantingDate !== "" ? formatDate(state?.PlantingDate) : "",
            },
            {
              name: "Your Farm",
              value: farm == undefined ? 0 : farm?.AverageSeedingRate,
              avgPlantingDate:
                farm == undefined ? "" : farm?.PlantingDate !== "" ? formatDate(farm?.PlantingDate) : "",
            },
          ],
        };

        let varietyInfo = [];

        for (let v of variety) {
          let statev = seedingVarietyForState.filter(
            (h) => h.Crop == crop && h.Variety == v
          )[0];
          let farmv = seedingVarietyForFarm.filter(
            (h) => h.Crop == crop && h.Variety == v
          )[0];

          let varobj = {
            item: v,
            values: [
              {
                name: "State",
                value: statev == undefined ? 0 : statev?.VarietySeedingRate,
                avgPlantingDate:
                  statev == undefined ? "" : statev?.PlantingDate !== "" ? formatDate(statev?.PlantingDate) : "",
              },
              {
                name: "Your Farm",
                value: farmv == undefined ? 0 : farmv?.VarietySeedingRate,
                avgPlantingDate:
                  farmv == undefined ? "" : formatDate(farmv?.PlantingDate),
              },
            ],
          };
          varietyInfo.push(varobj);
        }

        obj.varieties = varietyInfo;

        seedData.push(obj);
      }
      //console.log("seedData", seedData);
      if (seedData !== undefined) setSeedingData(seedData);

      //Group nutrient data
      let nutrientData = [];

      for (let n of Nutrients) {
        let state = nutrientDataForState !== null ? nutrientDataForState.filter((h) => h.NutrientAlias == n)[0] : [];
        let farm = nutrientDataForFarm !== null ? nutrientDataForFarm.filter((h) => h.NutrientAlias == n)[0] : [];

        let obj = {
          item: n,
          values: [
            {
              name: "State",
              value: state == undefined ? 0 : state?.NutrientRate,
              avgPlantingDate:
                state == undefined ? "" : formatDate(state?.NutrientDate),
            },
            {
              name: "Your Farm",
              value: farm == undefined ? 0 : farm?.NutrientRate,
              avgPlantingDate:
                farm == undefined ? "" : formatDate(farm?.NutrientDate),
            },
          ],
        };
        nutrientData.push(obj);
      }
      //console.log("seedData", seedData);
      if (nutrientData !== undefined) setNutrients(nutrientData);

      //Group soil and weather data
      let soilAndWeather = getSoilAndWeatherFormat(
        precipForState,
        precipDataForFarm,
        soilDataForState,
        soilDataForFarm,
        nccpiForState,
        nccpiForFarm,
        gddForFarm,
        gddForState
      );

      if (soilAndWeather !== undefined) setSoilWeather(soilAndWeather);

      //console.log(soilAndWeather);

      setOverviewData({
        Seeding: seedData === undefined ? [] : seedData,
        Yields: harData === undefined ? [] : harData,
        "Soil & Weather": soilAndWeather === undefined ? [] : soilAndWeather,
        Chemicals: nutrientData === undefined ? [] : nutrientData,
      });
    }
  }, [
    loadedStateData,
    loadedFarmData,
    harvestVarietyForState,
    seedingVarietyForState,
    harvestDataForState,
    seedingDataForState,
    harvestVarietyForFarm,
    seedingVarietyForFarm,
    harvestDataForFarm,
    seedingDataForFarm,
    precipForState,
    precipDataForFarm,
    soilDataForState,
    soilDataForFarm,
    nccpiForState,
    nccpiForFarm,
    gddForFarm,
    gddForState,
  ]);

  // useEffect(() => {
  //   console.log("activeCategory", activeCategory);
  //   if (activeCategory.name == "Seeding") {
  //     setOverviewData(seedingData);
  //   } else if (activeCategory.name == "Yields") {
  //     setOverviewData(harvestData);
  //   } else if (activeCategory.name == "Chemicals") {
  //     setOverviewData(nutrients);
  //   } else if (activeCategory.name == "Soil & Weather") {
  //     setOverviewData(soilWeather);
  //   }
  // }, [activeCategory, harvestData, seedingData, nutrients, soilWeather]);

  return (
    <div className={classes.wrapper}>
      <Description
        categories={categories}
        setCategories={setCategories}
        valueAccessor={(d) => d.name}
        mobileView={mobileView}
      />
      <VisualizationContainer
        data={overviewData}
        activeCategory={activeCategory.name}
      />
    </div>
  );
};

export default Overview;

Overview.propTypes = {
  mobileView: PropTypes.bool.isRequired,
};

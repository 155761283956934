/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container,
  Box,
  Typography,
  Button,
  Tabs,
  Tab,
  Divider,
  Slide,
  Fade,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { UserContext } from '../../Context/UserContext';
import {
  getReport,
  getReportFromToken,
  getOrders,
  getReportFiles,
  getGeoJsonFromShape,
} from '../../../utils/dataFetchers';
import { useWindowDimensions } from '../../../utils/dimensions';
import { checkTokenLink, formatDate } from '../../../utils/helpers';
import { lightGrey } from '../../../styles/colors';
import { TabPanel } from '../../Shared/TabPanel';
import { DisplayPdfs } from '../../Shared/Vendors/DisplayPdfs';
import { NoResults } from '../../Shared/Vendors/NoResults';
import { DisplayDownloads } from '../../Shared/Vendors/DisplayDownloads';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { ResultsMapDisplay } from './Map/ResultsMapDisplay';
import { ShapeFileSelection } from './ShapeFileSelection';
import { Summary } from './Summary/Summary';
import { ProfitLayersLink } from '../../Shared/ServiceLinks';
import sampleOne from '../../../images/soilSampleOne.png';
import sampleTwo from '../../../images/soilSampleTwo.png';
import sampleThree from '../../../images/soilSampleThree.png';

/**
 * Tab props
 * @param {Number} index Tab index
 * @returns {Object} Tab id and aria-controls
 */
function a11yProps(index) {
  return {
    id: `field-selection-tab-${index}`,
    'aria-controls': `report-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
  },
  mobileTabs: theme.mobileTabs,
  tabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    minWidth: 180,
  },
  sideTabs: {
    backgroundColor: theme.palette.greys.light,
  },
  buttonLink: theme.buttonLink,
  orderCard: {
    margin: 8,
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
  },
  reportCard: {
    padding: 8,
    margin: 4,
    border: `1px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
  },
  summaryTitle: {
    marginRight: 4,
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
  summaryDescription: {
    color: theme.palette.text.primary,
    fontWeight: 500,
  },
}));

/**
 * Display soil testing results. User can select from results or use tokenized link
 * @param {Function} setHomeSection Set section for navbar
 * @returns {JSX} Results display
 */
export function SoilTestingResults({ setHomeSection }) {
  const user = useContext(UserContext)[0];
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const mobileBreakPoint = 860;

  const [loading, setLoading] = useState(true);
  const [dataLoading, setDataLoading] = useState(false);
  const [showOrders, setShowOrders] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [noResults, setNoResults] = useState(false);

  const [token, setToken] = useState(null);
  const [orders, setOrders] = useState([]);
  const [report, setReport] = useState(null);
  const [selectedSubOrder, setSelectedSubOrder] = useState(null);

  // Report files
  const [pdfs, setPdfs] = useState([]);
  const [images, setImages] = useState([]);
  const [downloads, setDownloads] = useState([]);
  const [shapeFiles, setShapeFiles] = useState({});

  const [errorMessage, setErrorMessage] = useState('');

  const [fieldIndex, setFieldIndex] = useState(0);
  const [reportTab, setReportTab] = useState(0);

  const [geoJsons, setGeoJsons] = useState([]);
  const convertedShapes = useRef([]);

  useEffect(() => {
    setHomeSection(-1);

    const tokenLink = checkTokenLink('results');
    if (tokenLink) {
      setToken(tokenLink);
      getReportFromTokenizedLink(tokenLink);
    }
  }, []);

  useEffect(() => {
    const tokenLink = checkTokenLink('results');
    if (tokenLink) {
      getReportFromTokenizedLink(tokenLink);
    } else if (user?.isAuthenticated) {
      getUserOrders();
    } else if (user?.isAuthenticated === false) {
      setLoading(false);
      setNoResults(true);
    }
  }, [user]);

  const getReportFromTokenizedLink = async (tokenLink) => {
    const reportFromToken = await getReportFromToken(tokenLink);
    handleReport(reportFromToken, tokenLink);
  };

  const getOrdersReport = async (id) => {
    setLoading(true);
    const ordersReport = await getReport(id);
    handleReport(ordersReport, '');
  };

  const handleReport = async (newReport, tokenLink) => {
    setReport(newReport);
    setLoading(false);
    setShowReport(true);
    setShowOrders(false);
    setSelectedSubOrder(newReport.subOrderDetails[0]);
    const reportFiles = await getReportFiles(
      newReport.orderId,
      newReport.subOrderDetails[0].subOrderId,
      tokenLink,
    );

    setPdfs(reportFiles.pdfs);
    setImages(reportFiles.images);
    setDownloads(reportFiles.downloads);
    const shapes = reportFiles.downloads.filter((x) => x.substring(x.length - 3) === 'zip');

    setShapeFiles(organizeShapes(shapes));
  };

  const organizeShapes = (files) => {
    try {
      const recomendations = [];
      const results = [];
      const zones = [];
      files.forEach((shape) => {
        if (shape.includes('Fertility')) {
          recomendations.push(shape);
        } else if (shape.includes('zone')) {
          zones.push(shape);
        } else {
          results.push(shape);
        }
      });

      const orderedZones = zones.sort();
      const samplesPoly = orderedZones.findIndex((x) => x.includes('samples_poly'));
      if (samplesPoly > -1) {
        const moveToTop = orderedZones.splice(samplesPoly, 1)[0];
        orderedZones.unshift(moveToTop);
      }

      return {
        results: results.sort(),
        recomendations: recomendations.sort(),
        zones: orderedZones,
      };
    } catch (err) {
      console.error(err);
      return {
        results: [],
        recomendations: [],
        zones: [],
      };
    }
  };

  const getGeoJson = async (file) => {
    try {
      if (convertedShapes.current.includes(file)) {
        const selectedFile = geoJsons.filter((geo) => geo.file === file);
        return selectedFile[0];
      }

      setDataLoading(true);

      const requestUrl = file.replace('#', '%23');
      const geoJson = await getGeoJsonFromShape(requestUrl, token);
      const parsed = JSON.parse(geoJson);

      if (parsed) {
        convertedShapes.current.push(file);
        setGeoJsons((prev) => [...prev, { ...parsed, file }]);
      }

      return parsed;
    } catch (e) {
      // console.error(e);
      return null;
    } finally {
      setDataLoading(false);
    }
  };

  const getUserOrders = async () => {
    // get sorted by date orders
    const userOrders = await getOrders(true, 3);
    setLoading(false);
    if (userOrders?.length === 1) {
      getOrdersReport(userOrders[0].orderID);
    } else if (userOrders?.length > 1) {
      setShowOrders(true);
      setOrders(userOrders);
    } else {
      setNoResults(true);
    }
  };

  const handleFieldChange = (event, newValue) => {
    setFieldIndex(newValue);
  };

  const handleReportChange = (event, newValue) => {
    setReportTab(newValue);
  };

  const backToOrders = () => {
    setShowReport(false);
    setShowOrders(true);
  };

  const orderDisplay = () => (
    <Container maxWidth="lg">
      <Box py={1}>
        <Typography align="center" variant="h6">
          Your Orders
        </Typography>
        <Typography align="center">
          Select an order below to view your report results
        </Typography>
        <Divider />
      </Box>
      <Box py={1} display="flex" justifyContent="center" flexWrap="wrap">
        {orders.map((x, i) => createOrderCards(x, i))}
      </Box>

      <Box m={0.5} display="flex" justifyContent="center">
        <ProfitLayersLink />
      </Box>
    </Container>
  );

  /* Create cards for completed orders. User will select individual report to
    view from these cards. */
  const createOrderCards = (order) => (
    <Box key={order.orderID} className={classes.orderCard}>
      <Box>
        <Typography>
          Order:&nbsp;
          {order.orderID}
        </Typography>
        <Typography>
          Created on:&nbsp;
          {formatDate(order.dateCreated)}
        </Typography>
        {order.dateModified !== null
            && (
              <Typography>
                Completed on:&nbsp;
                {formatDate(order.dateModified)}
              </Typography>
            )}
      </Box>

      <Box mt={1}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => getOrdersReport(order.orderID)}
          disableElevation
        >
          View Order
        </Button>
      </Box>
    </Box>
  );

  const fieldSelection = () => (
    <Tabs
      value={fieldIndex}
      onChange={handleFieldChange}
      aria-label="field selection tabs"
      indicatorColor="primary"
      // textcolor="primary"
      variant="scrollable"
      scrollButtons="on"
      style={{ marginLeft: orders?.length ? 10 : 120 }}
    >
      { report?.subOrderDetails.map(
        (x, i) => (
          <Tab
            key={`${x.fieldId}-${x.fieldName}`}
            label={`${x.fieldName}`}
            {...a11yProps(i)}
          />
        )
      )}

    </Tabs>
  );

  const reportDetailsSelection = () => (
    <Tabs
      className={classes.tabs}
      value={reportTab}
      onChange={handleReportChange}
      aria-label="report selection tabs"
      indicatorColor="primary"
      variant="scrollable"
      orientation={width > mobileBreakPoint ? 'vertical' : 'horizontal'}
      scrollButtons={width > mobileBreakPoint ? 'off' : 'on'}
      style={
        width > mobileBreakPoint
          ? { height: height - 105, width: 160, backgroundColor: lightGrey } : {}
      }
    >
      <Tab label="Results Map" {...a11yProps(0)} />
      <Tab label="Soil Test Summary" {...a11yProps(1)} />
      <Tab label="Detailed Reports" {...a11yProps(2)} />
      <Tab label="Convert Shape Files to GeoTIFF" {...a11yProps(3)} />
      <Tab label="Downloads" {...a11yProps(4)} />
    </Tabs>
  );

  const displayPanels = () => (
    <Box display="flex" flexGrow={1} height={height - 104} style={{ overflowY: 'auto' }}>
      <TabPanel value={reportTab} index={0}>
        <ResultsMapDisplay
          boundary={selectedSubOrder.boundary}
          shapeFiles={shapeFiles}
          token={token}
          getGeoJson={getGeoJson}
        />
      </TabPanel>
      <TabPanel value={reportTab} index={1}>
        <Summary
          report={report}
          selectedSubOrder={selectedSubOrder}
        />
      </TabPanel>
      <TabPanel value={reportTab} index={2}>
        <DisplayPdfs
          pdfs={pdfs}
          token={token}
        />
      </TabPanel>
      <TabPanel value={reportTab} index={3}>
        <ShapeFileSelection
          boundary={selectedSubOrder.boundary}
          shapeFiles={shapeFiles}
          token={token}
          getGeoJson={getGeoJson}
        />
      </TabPanel>
      <TabPanel value={reportTab} index={4}>
        <DisplayDownloads
          downloads={downloads}
          token={token}
        />
      </TabPanel>
    </Box>
  );

  const reportDisplay = () => (
    <Box>
      <Box display="flex">
        { orders?.length > 0 && (
          <Button
            color="primary"
            onClick={() => backToOrders()}
            style={{ marginLeft: 16 }}
          >
            <ArrowBackIcon color="primary" />
            {' Orders'}
          </Button>
        )}
        { fieldSelection() }
      </Box>

      <Box display="flex" flexDirection={width > mobileBreakPoint ? 'row' : 'column'}>
        { reportDetailsSelection()}

        { selectedSubOrder !== null && displayPanels()}
      </Box>
    </Box>
  );

  const loadingReports = () => (
    <Container maxWidth="lg">
      <Box py={2}>
        <Typography align="center" variant="h6">
          Loading Reports...
        </Typography>
      </Box>
      <SpinningLoader />
    </Container>
  );

  return (
    <div className={classes.root}>
      { loading && loadingReports()}

      { noResults && (
        <NoResults
          authenticated={user.isAuthenticated}
          samples={[sampleOne, sampleTwo, sampleThree]}
        />
      )}

      <Fade
        in={showOrders}
        mountOnEnter
        unmountOnExit
      >
        { orderDisplay() }
      </Fade>

      <Slide
        in={showReport}
        direction="right"
        mountOnEnter
        unmountOnExit
      >
        {reportDisplay()}
      </Slide>
      { dataLoading && <SpinningLoader /> }
    </div>
  );
}

SoilTestingResults.propTypes = {
  setHomeSection: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import { Box, Typography, Checkbox } from '@material-ui/core';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { useWindowDimensions } from '../../../utils/dimensions';
import { exists, numFormat } from '../../../utils/helpers';
import { useSnackbar } from "notistack";
// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)

export function CostsChart({
  displayed, perAcre, netSeededAcres, hideControls, containerHeight, containerWidth
}) {
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState([]);
  const [total, setTotal] = useState(0);

  const operationNames = [
    'Seeding', 'Applications', 'Field Passes', 'Other Costs',
  ];
  const [selected, setSelected] = useState([
    'Seeding', 'Applications', 'Field Passes', 'Other Costs',
  ]);

  const options = {
    chart: {
      type: 'pie',
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      spacingLeft: 0,
      spacingRight: 0,
    },
    title: {
      text: `Total Costs per ${perAcre ? 'Acre*' : 'Field'} $${numFormat(total)}`,
      fontWeight: 500,
      fontSize: '22px',
    },
    tooltip: {
      formatter: function () {
        return `${this.key}: <b>${numFormat(this.percentage)}%</b><br>$${formatToolTip(this.y, 2, 5)}`;
      }
    },
    accessibility: {
      point: {
        valueSuffix: '%',
      },
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          connectorColor: 'silver',
        },
        showInLegend: true,
      },
    },
    series: [
      {
        data,
        dataLabels: {
          style: {
            fontSize: '14px',
          }
        },
      },
    ],
  };

  const formatToolTip = (val) => {
    if (val[0] === 0) {
      return numFormat(val, 2, 5)
    } else {
      return numFormat(val)
    }

  }

  useEffect(() => {
    getSelected(displayed);
  }, [displayed, selected]);


  const getSelected = (operations) => {
    // filter on selected operations
    const filtered = [...operations.filter((x) => selected.includes(x.type))];
    const data = [];
    let total = 0;
    //console.log("OPERATIONS IN COST CHART", filtered)
    for (const op of filtered) {
      total += Math.abs(op.total);
      if (op.type === 'Other Costs') {
        const others = op.ops.map((x) => cleanOthers(x));
        data.push(...others);
      } else if (op.type === 'Seeding') {
        const varieties = op.ops.map((x) => x.varieties);
        for (const operation of varieties) {
          for (const variety of operation) {
            data.push(cleanSeeding(variety));
          }
        }
      } else if (op.type === 'Applications') {
        for (const ap of op.ops) {
          const name = `${ap.productName} - ${ap.date}`;
          // price coming back as string?
          // console.log("app", ap)
          const cost = perAcre ? (ap.operationCost * ap.area)/netSeededAcres : ap.operationCost * ap.area ;
          data.push({ name, y: cost !== null ? +Number(cost).toFixed(2) : 0 });
        }
      } else if (op.type === 'Field Passes') {
        for (const fieldPass of op.ops) {
          // console.log(fieldPass)
          const name = fieldPass.productName;
          // let adjustedPrice = fieldPass.price * (fieldPass.area / netSeededAcres)
          // console.log("adjusted price",adjustedPrice)
          const cost = perAcre ? (fieldPass.price *fieldPass.area)/netSeededAcres : fieldPass.price * fieldPass.area;
          data.push({ name, y: cost !== null ? +Number(cost).toFixed(2) : 0 });
        }
      }
    }
    // console.log(data)
    setData(data.filter(x=> Number(x.y) > 0));
    setTotal(+Number(total).toFixed(2))
  };

  const cleanSeeding = (op) => {
    // console.log(op)
    const total = op.price * op.quantity
    const name = `${op.productName} - ${op.variety}`;
    const cost = perAcre ? total / netSeededAcres : total;
    let formatCost = 0
    if (cost.toString()[0] === '0') {
      // if cost is only cents, show 5 decimal places
      formatCost = +Number(cost).toFixed(5)
    } else {
      formatCost = +Number(cost).toFixed(2)
    }
    return { name, y: cost };
  };

  const cleanOthers = (op) => {
    // console.log("other cost",op)
    const cost = perAcre ? op.price * (op.area / netSeededAcres) : op.price * op.area;
    return { name: op.productName, y: cost !== null ? +Number(cost).toFixed(2) : 0 };
  };

  /**
   *
   * @param  {Object} e  Event from checkbox
   * @param  {String} op Operation type to select or unselect
   */
  const handleCheck = (e, op) => {
    if (selected.includes(op)) {
      if (selected.length > 1) {
        setSelected(selected.filter((x) => x !== op))
      } else {
        enqueueSnackbar('You must select atleast one operation type')
      }
    } else {
      setSelected([...selected, op])
    }
  };

  return (
    <Box
      id="costs-chart"
      p={1}
      style={{
        width: '100%',
        minWidth: '500px',
        height: '100%',
        minHeight: '500px',
        overflow: 'auto',
      }}
    >
      {/* hide checkboxes for pdf render and on smaller devices*/}
      {
        (!hideControls && displayed.length > 0 && width > 1024)
        && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap='wrap'
        >
          <Typography variant="h6">Include:</Typography>
          { operationNames.map((op, i) => (
            <Box key={i} m={1} p={1} display="flex" alignItems="center">
              <Checkbox
                color='primary'
                label={op}
                checked={selected.includes(op)}
                onChange={(e) => handleCheck(e, op)}
              />
              <Typography>{op}</Typography>
            </Box>
          ))}
        </Box>
        )
      }

      {
        (data.length > 0)
          ? (
            <div id="cost-chart-image">
              <HighchartsReact
                containerProps={{
                  style: {
                    height: containerHeight,
                  } }}
                highcharts={Highcharts}
                options={options}
              />
            </div>
          )
          : (
            <Box fontSize={24} fontWeight={500}>
              This field has no costs for selected year
            </Box>
          )
    }
    </Box>
  );
}

export const comptest = [
  {
    "Assessed_Land": null,
    "Avg_CSR2": 87.8,
    "Broker_URL": "http://realestate.hertz.ag/Listings/tabid/84/agentType/View/PropertyID/1462/Default.aspx",
    "Buyer": null,
    "CRP": "Yes",
    "CRP_Acres": 5.7,
    "Entry_Updated": "2017-07-25T00:00:00",
    "Listing_Agent": "Hertz Real Estate",
    "Listing_id": "4FE861AD-D021-43B9-8E95-FC17F444A0A9",
    "Percent_Tillable": 93,
    "Price_Acre": 7500,
    "Sale_Condition": "Auction",
    "Sale_Date": "2017-07-31",
    "Sale_Price": 872700,
    "Status": "Sold",
    "Taxes_total": null,
    "Tillable_Acres": 108,
    "Total_Acres": 116.36,
    "parcels": [
      {
        "Acres": 27.76171148548837,
        "County": "Howard",
        "FIPS": "19089",
        "GeoJSON": {
          "coordinates": [
            [
              [
                -92.45574996736666,
                43.25274633466293
              ],
              [
                -92.46069836538682,
                43.25274264873006
              ],
              [
                -92.46074155850351,
                43.25526267976107
              ],
              [
                -92.4557782901243,
                43.25525202477415
              ],
              [
                -92.45574996736666,
                43.25274633466293
              ]
            ]
          ],
          "type": "Polygon"
        },
        "Parcel": 1,
        "Parcel_ID": "5ea6c665526a870ec72e836e",
        "STATEFP": "19",
        "Shape": "POLYGON((-92.45574996736666 43.25274633466293, -92.46069836538682 43.25274264873006, -92.46074155850351 43.25526267976107, -92.4557782901243 43.25525202477415, -92.45574996736666 43.25274633466293))",
        "State": "Iowa",
        "county_name": "howard",
        "lat_center": 43.254,
        "lng_center": -92.45824,
        "range": "14W",
        "sect": "2",
        "state_name": "Iowa",
        "twnshp": "97N",
        "NCCPI": 0.8481818181818181,
        "Precipitation": 43.371334108333336
      },
      {
        "Acres": 39.94052206685903,
        "County": "Howard",
        "FIPS": "19089",
        "GeoJSON": {
          "coordinates": [
            [
              [
                -92.45570893416,
                43.24911576632104
              ],
              [
                -92.46063622654093,
                43.24911687790301
              ],
              [
                -92.46069836538682,
                43.25274264873006
              ],
              [
                -92.45574996736666,
                43.25274633466293
              ],
              [
                -92.45570893416,
                43.24911576632104
              ]
            ]
          ],
          "type": "Polygon"
        },
        "Parcel": 2,
        "Parcel_ID": "5ea6c665526a870ec72e836f",
        "STATEFP": "19",
        "Shape": "POLYGON((-92.45570893416 43.24911576632104, -92.46063622654093 43.24911687790301, -92.46069836538682 43.25274264873006, -92.45574996736666 43.25274633466293, -92.45570893416 43.24911576632104))",
        "State": "Iowa",
        "county_name": "howard",
        "lat_center": 43.25093,
        "lng_center": -92.4582,
        "range": "14W",
        "sect": "2",
        "state_name": "Iowa",
        "twnshp": "97N",
        "NCCPI": 0.8492857142857145,
        "Precipitation": 43.371334108333336
      },
      {
        "Acres": 20.299971408960857,
        "County": "Howard",
        "FIPS": "19089",
        "GeoJSON": {
          "coordinates": [
            [
              [
                -92.45428310147656,
                43.25447921899486
              ],
              [
                -92.45426384706262,
                43.2527721404599
              ],
              [
                -92.45426365720603,
                43.25275530063826
              ],
              [
                -92.45422282358139,
                43.24913469828267
              ],
              [
                -92.45570893416,
                43.24911576632104
              ],
              [
                -92.45574996736666,
                43.25274633466293
              ],
              [
                -92.4557782901243,
                43.25525202477415
              ],
              [
                -92.45452152094036,
                43.25525080769692
              ],
              [
                -92.45429180217683,
                43.25525058380394
              ],
              [
                -92.45428310147656,
                43.25447921899486
              ]
            ]
          ],
          "type": "Polygon"
        },
        "Parcel": 3,
        "Parcel_ID": "5ea6c665526a870ec72e8370",
        "STATEFP": "19",
        "Shape": "POLYGON((-92.45428310147656 43.25447921899486, -92.45426384706262 43.2527721404599, -92.45426365720603 43.25275530063826, -92.45422282358139 43.24913469828267, -92.45570893416 43.24911576632104, -92.45574996736666 43.25274633466293, -92.4557782901243 43.25525202477415, -92.45452152094036 43.25525080769692, -92.45429180217683 43.25525058380394, -92.45428310147656 43.25447921899486))",
        "State": "Iowa",
        "county_name": "howard",
        "lat_center": 43.25219,
        "lng_center": -92.455,
        "range": "14W",
        "sect": "1",
        "state_name": "Iowa",
        "twnshp": "97N",
        "NCCPI": 0.8268125000000003,
        "Precipitation": 43.371334108333336
      },
      {
        "Acres": 28.74421094065429,
        "County": "Howard",
        "FIPS": "19089",
        "GeoJSON": {
          "coordinates": [
            [
              [
                -92.46069836538682,
                43.25274264873006
              ],
              [
                -92.46063622654093,
                43.24911687790301
              ],
              [
                -92.46269628484288,
                43.24911728043403
              ],
              [
                -92.46287609833519,
                43.25526719885138
              ],
              [
                -92.46074442667867,
                43.25526268586952
              ],
              [
                -92.46074155850351,
                43.25526267976107
              ],
              [
                -92.46069836538682,
                43.25274264873006
              ]
            ]
          ],
          "type": "Polygon"
        },
        "Parcel": 4,
        "Parcel_ID": "5ea6c665526a870ec72e8371",
        "STATEFP": "19",
        "Shape": "POLYGON((-92.46069836538682 43.25274264873006, -92.46063622654093 43.24911687790301, -92.46269628484288 43.24911728043403, -92.46287609833519 43.25526719885138, -92.46074442667867 43.25526268586952, -92.46074155850351 43.25526267976107, -92.46069836538682 43.25274264873006))",
        "State": "Iowa",
        "county_name": "howard",
        "lat_center": 43.25221,
        "lng_center": -92.46174,
        "range": "14W",
        "sect": "2",
        "state_name": "Iowa",
        "twnshp": "97N",
        "NCCPI": 0.8807500000000001,
        "Precipitation": 43.371334108333336
      }
    ],
    "GeoJSON": {
      "type": "Feature",
      "geometry": {
        "type": "Polygon",
        "coordinates": [
          [
            [
              -92.46074155850351,
              43.25526267976107
            ],
            [
              -92.4557782901243,
              43.25525202477415
            ],
            [
              -92.45452152094036,
              43.25525080769692
            ],
            [
              -92.45429180217683,
              43.25525058380394
            ],
            [
              -92.45428310147656,
              43.25447921899486
            ],
            [
              -92.45426384706262,
              43.2527721404599
            ],
            [
              -92.45426365720603,
              43.25275530063826
            ],
            [
              -92.45422282358139,
              43.24913469828267
            ],
            [
              -92.45570893416,
              43.24911576632104
            ],
            [
              -92.46063622654093,
              43.24911687790301
            ],
            [
              -92.46269628484288,
              43.24911728043403
            ],
            [
              -92.46287609833519,
              43.25526719885138
            ],
            [
              -92.46074442667867,
              43.25526268586952
            ],
            [
              -92.46074155850351,
              43.25526267976107
            ]
          ]
        ]
      },
      "properties": {}
    }
  },
  {
    "Assessed_Land": null,
    "Avg_CSR2": 75.8,
    "Broker_URL": "https://www.hertz.ag/real-estate/auction/chickasaw-co.-ia-179.5-ac.-m-l-020-0413-01-bw41447",
    "Buyer": null,
    "CRP": "No",
    "CRP_Acres": null,
    "Entry_Updated": "2019-09-25T00:00:00",
    "Listing_Agent": "Hertz Real Estate",
    "Listing_id": "86211388-751F-4048-8871-9A7311E94E19",
    "Percent_Tillable": 71,
    "Price_Acre": 0,
    "Sale_Condition": "Auction",
    "Sale_Date": "2019-09-24",
    "Sale_Price": 0,
    "Status": "Sold",
    "Taxes_total": 4660,
    "Tillable_Acres": 127.03,
    "Total_Acres": 179.5,
    "parcels": [
      {
        "Acres": 187.21806534009679,
        "County": "Chickasaw",
        "FIPS": "19037",
        "GeoJSON": {
          "coordinates": [
            [
              [
                -92.42701292037964,
                43.1911993021648
              ],
              [
                -92.427077293396,
                43.188484968629425
              ],
              [
                -92.4296522140503,
                43.188484968629425
              ],
              [
                -92.42956638336182,
                43.183900834486366
              ],
              [
                -92.4168848991394,
                43.184010357063485
              ],
              [
                -92.4168848991394,
                43.18799215161087
              ],
              [
                -92.41729259490967,
                43.1880625542857
              ],
              [
                -92.4173355102539,
                43.19120712427722
              ],
              [
                -92.42701292037964,
                43.1911993021648
              ]
            ]
          ],
          "type": "Polygon"
        },
        "Parcel": 1,
        "Parcel_ID": "5ea6c666526a870ec72e8c09",
        "STATEFP": "19",
        "Shape": "POLYGON((-92.42701292037964 43.1911993021648, -92.427077293396 43.188484968629425, -92.4296522140503 43.188484968629425, -92.42956638336182 43.183900834486366, -92.4168848991394 43.184010357063485, -92.4168848991394 43.18799215161087, -92.41729259490967 43.1880625542857, -92.4173355102539 43.19120712427722, -92.42701292037964 43.1911993021648))",
        "State": "Iowa",
        "county_name": "Chickasaw",
        "lat_center": 43.18755397938179,
        "lng_center": -92.42326855659485,
        "range": "13W",
        "sect": "30",
        "state_name": "Iowa",
        "twnshp": "97N"
      }
    ],
    "GeoJSON": {
      "type": "Feature",
      "geometry": {
        "coordinates": [
          [
            [
              -92.42701292037964,
              43.1911993021648
            ],
            [
              -92.427077293396,
              43.188484968629425
            ],
            [
              -92.4296522140503,
              43.188484968629425
            ],
            [
              -92.42956638336182,
              43.183900834486366
            ],
            [
              -92.4168848991394,
              43.184010357063485
            ],
            [
              -92.4168848991394,
              43.18799215161087
            ],
            [
              -92.41729259490967,
              43.1880625542857
            ],
            [
              -92.4173355102539,
              43.19120712427722
            ],
            [
              -92.42701292037964,
              43.1911993021648
            ]
          ]
        ],
        "type": "Polygon"
      },
      "properties": {}
    }
  },
  {
    "Assessed_Land": null,
    "Avg_CSR2": 88.6,
    "Broker_URL": "http://www.burkecresco.com/properties/62-acres-howard-co-146-43-acres-chickasaw-co-elma-iowa-50628",
    "Buyer": null,
    "CRP": "No",
    "CRP_Acres": null,
    "Entry_Updated": "2018-02-24T00:00:00",
    "Listing_Agent": "Burke Real Estate",
    "Listing_id": "C314E732-0DED-4910-A448-C4BF939F01FB",
    "Percent_Tillable": 98,
    "Price_Acre": 9250,
    "Sale_Condition": "Auction",
    "Sale_Date": "2018-02-14",
    "Sale_Price": 573500,
    "Status": "Sold",
    "Taxes_total": null,
    "Tillable_Acres": 60.5,
    "Total_Acres": 62,
    "parcels": [
      {
        "Acres": 62.07122755540012,
        "County": "Howard",
        "FIPS": "19089",
        "GeoJSON": {
          "coordinates": [
            [
              [
                -92.3709225654602,
                43.22025153358747
              ],
              [
                -92.37087965011597,
                43.227459661637646
              ],
              [
                -92.3669958114624,
                43.22738148694639
              ],
              [
                -92.36706018447876,
                43.2202202600255
              ],
              [
                -92.3709225654602,
                43.22025153358747
              ]
            ]
          ],
          "type": "Polygon"
        },
        "Parcel": 1,
        "Parcel_ID": "5ea6c664526a870ec72e7a3c",
        "STATEFP": "19",
        "Shape": "POLYGON((-92.3709225654602 43.22025153358747, -92.37087965011597 43.227459661637646, -92.3669958114624 43.22738148694639, -92.36706018447876 43.2202202600255, -92.3709225654602 43.22025153358747))",
        "State": "Iowa",
        "county_name": "howard",
        "lat_center": 43.22383996083157,
        "lng_center": -92.3689591884613,
        "range": "13W",
        "sect": "15",
        "state_name": "Iowa",
        "twnshp": "97N",
        "NCCPI": 0.8728571428571429,
        "Precipitation": 43.12858316666666
      }
    ],
    "GeoJSON": {
      "type": "Feature",
      "geometry": {
        "coordinates": [
          [
            [
              -92.3709225654602,
              43.22025153358747
            ],
            [
              -92.37087965011597,
              43.227459661637646
            ],
            [
              -92.3669958114624,
              43.22738148694639
            ],
            [
              -92.36706018447876,
              43.2202202600255
            ],
            [
              -92.3709225654602,
              43.22025153358747
            ]
          ]
        ],
        "type": "Polygon"
      },
      "properties": {}
    }
  },
  {
    "Assessed_Land": null,
    "Avg_CSR2": 86.51,
    "Broker_URL": "http://www.farmersnational.com/",
    "Buyer": null,
    "CRP": "No",
    "CRP_Acres": null,
    "Entry_Updated": "2020-09-29T00:00:00",
    "Listing_Agent": "Farmers National Company",
    "Listing_id": "DDB11D5A-C77C-4FDA-9570-187E6C0D6FF7",
    "Percent_Tillable": 91,
    "Price_Acre": 6400,
    "Sale_Condition": "Auction",
    "Sale_Date": "2020-09-24",
    "Sale_Price": 1011200,
    "Status": "Sold",
    "Taxes_total": 5035.98,
    "Tillable_Acres": 144,
    "Total_Acres": 158,
    "parcels": [
      {
        "Acres": 139.12396250243302,
        "County": "Chickasaw",
        "FIPS": "19037",
        "GeoJSON": {
          "coordinates": [
            [
              [
                -92.39685620084681,
                43.12703452769465
              ],
              [
                -92.39677815651108,
                43.13071880111277
              ],
              [
                -92.3967143045937,
                43.13423475869201
              ],
              [
                -92.39185070317643,
                43.13424041125967
              ],
              [
                -92.39182849170156,
                43.13065250574541
              ],
              [
                -92.38687917869696,
                43.13061286414366
              ],
              [
                -92.3868486855048,
                43.12864152015968
              ],
              [
                -92.38205208235674,
                43.12865566094526
              ],
              [
                -92.38203626175837,
                43.12689065919154
              ],
              [
                -92.38451202401143,
                43.12691258441107
              ],
              [
                -92.3867980659306,
                43.12692919683385
              ],
              [
                -92.39185475101287,
                43.12700636961326
              ],
              [
                -92.39186655029646,
                43.12700642802815
              ],
              [
                -92.39190554382026,
                43.12700663778871
              ],
              [
                -92.39685620084681,
                43.12703452769465
              ]
            ]
          ],
          "type": "Polygon"
        },
        "Parcel": 1,
        "Parcel_ID": "600556d7526a87158b3055de",
        "STATEFP": "19",
        "Shape": "POLYGON((-92.39685620084681 43.12703452769465, -92.39677815651108 43.13071880111277, -92.3967143045937 43.13423475869201, -92.39185070317643 43.13424041125967, -92.39182849170156 43.13065250574541, -92.38687917869696 43.13061286414366, -92.3868486855048 43.12864152015968, -92.38205208235674 43.12865566094526, -92.38203626175837 43.12689065919154, -92.38451202401143 43.12691258441107, -92.3867980659306 43.12692919683385, -92.39185475101287 43.12700636961326, -92.39186655029646 43.12700642802815, -92.39190554382026 43.12700663778871, -92.39685620084681 43.12703452769465))",
        "State": "Iowa",
        "county_name": "chickasaw",
        "lat_center": 43.1297,
        "lng_center": -92.39153,
        "range": "13W",
        "sect": "16",
        "state_name": "Iowa",
        "twnshp": "96N",
        "NCCPI": 0.845888888888889,
        "Precipitation": 41.580083461666675
      }
    ],
    "GeoJSON": {
      "type": "Feature",
      "geometry": {
        "coordinates": [
          [
            [
              -92.39685620084681,
              43.12703452769465
            ],
            [
              -92.39677815651108,
              43.13071880111277
            ],
            [
              -92.3967143045937,
              43.13423475869201
            ],
            [
              -92.39185070317643,
              43.13424041125967
            ],
            [
              -92.39182849170156,
              43.13065250574541
            ],
            [
              -92.38687917869696,
              43.13061286414366
            ],
            [
              -92.3868486855048,
              43.12864152015968
            ],
            [
              -92.38205208235674,
              43.12865566094526
            ],
            [
              -92.38203626175837,
              43.12689065919154
            ],
            [
              -92.38451202401143,
              43.12691258441107
            ],
            [
              -92.3867980659306,
              43.12692919683385
            ],
            [
              -92.39185475101287,
              43.12700636961326
            ],
            [
              -92.39186655029646,
              43.12700642802815
            ],
            [
              -92.39190554382026,
              43.12700663778871
            ],
            [
              -92.39685620084681,
              43.12703452769465
            ]
          ]
        ],
        "type": "Polygon"
      },
      "properties": {}
    }
  }
]
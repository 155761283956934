import React, { useEffect, useState } from 'react';
import {
  Page, Text, View, Document, StyleSheet, Font, Image, Link
} from '@react-pdf/renderer';
import logo from '../../../../../images/AgAnalyticsLogo_Black_Inline_Lower_Powered1.png'
import pl_logo from '../../../../../images/ProfitLayers_Full_Gradient.png'
import { grey, blackText, darkText } from '../../../../../styles/colors'
import { numFormat, dollarFormat, exists } from '../../../../../utils/helpers'


Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf',
  crossorigin: 'anonymous'
});

const styles = StyleSheet.create({
  body: {
    paddingBottom: 65,
    fontFamily: 'Roboto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f3f3f3',
    height: '50px'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
  },
  legendValue: {
    fontSize: 10,
    fontWeight: 500,
    color: blackText,
    padding: 1,
  },
  text: {
    fontSize: 10,
    fontWeight: 500,
    color: blackText,
  },
  plLogo: {
    height: 'auto',
    width: '150px',
  },
  footerLogo: {
    position: 'absolute',
    bottom: 30,
    left: 20,
    width: '91px',
    height: 'auto',
  },
  footerLink: {
    position: 'absolute',
    bottom: 35,
    left: 130,
    fontSize: 8,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})

export function TenantLandlordPDF({
  harvestOps,
  seedingOps,
  applicationOps,
  fieldpassOps,
  otherCostOps,
  otherRevOps,
  totalRevenue,
  totalCosts,
  totalProfit,
  landlordCost,
  landlordRev,
  landlordProfit,
  tenantCost,
  tenantRev,
  tenantProfit,
  reportName,
  notes,
  netSeededAcres
}) {

  const check = (item) => {
    if (item !== undefined && item !== null) {
      return item
    } else {
      return ''
    }
  }

  const [report, setReport] = useState('Data Layers')

  useEffect(() => {
    if (check(reportName) !== '') {
      setReport(reportName)
    }
  }, [reportName])

  function displayPlantings(type) {

    return (
      <View
        style={{ padding: '5px 10px' }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            color: darkText,
            fontSize: 10,
            fontWeight: 600,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <View style={{ width: '10%' }}>
            <Text>
              Year
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Crop Name
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              {type.toUpperCase() == 'HARVEST' ? 'Yield' : 'Applied Rate'}
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Acres
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Price ($/unit)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Landlord (%)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Tenant (%)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              {type.toUpperCase() == 'HARVEST' ? 'Total Revenue($/ac)' : 'Total Cost($/ac)'}
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              {type.toUpperCase() == 'HARVEST' ? 'Landlord Revenue($/ac)' : 'Landlord Cost($/ac)'}
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              {type.toUpperCase() == 'HARVEST' ? 'Tenant Revenue($/ac)' : 'Tenant Cost($/ac)'}
            </Text>
          </View>
        </View>

        {type.toUpperCase() == 'HARVEST' ? harvestOps.map((op, i) => createPlantingRow(op, i)) : seedingOps.map((op, i) => createPlantingRow(op, i))}

      </View>
    )
  }

  function displayChemicals() {
    return (
      <View
        style={{ padding: '5px 10px' }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            color: darkText,
            fontSize: 10,
            fontWeight: 600,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <View style={{ width: '10%' }}>
            <Text>
              Year
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Product Name
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Applied Rate
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Acres
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Price ($/unit)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Landlord (%)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Tenant (%)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Total Cost ($/ac)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Landlord Cost ($/ac)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Tenant Cost ($/ac)
            </Text>
          </View>
        </View>

        {applicationOps.map((op, i) => createChemicalRow(op, i))}

      </View>
    )
  }

  function createPlantingRow(op, i) {
    return (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: blackText,
          fontSize: 9,
          fontWeight: 500,
          padding: 8,
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <View style={{ width: '10%' }}>
          <Text>
            {op.cropSeason}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.productName}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {Math.round((op.quantity / op.area * 100) / 100)} {op.unit + '/ac'}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {Math.round(op.area * 100) / 100}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.operationCost / (op.quantity / op.area)) * 100) / 100}/{op.unit}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.landlordShare}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.tenantShare}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.rate * op.area)/netSeededAcres * 100) / 100}/ac
            </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.rate * op.area)/netSeededAcres * (op.landlordShare / 100) * 100) / 100}/ac
            </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.rate * op.area)/netSeededAcres * (op.tenantShare / 100) * 100) / 100}/ac
            </Text>
        </View>
      </View>
    )
  }

  function createChemicalRow(op, i) {
    return (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: blackText,
          fontSize: 9,
          fontWeight: 500,
          padding: 8,
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <View style={{ width: '10%' }}>
          <Text>
            {op.cropSeason}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.productName}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {Math.round((op.quantity / op.area * 100) / 100)} {op.unit + '/ac'}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {Math.round(op.area * 100) / 100}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.operationCost / (op.quantity / op.area)) * 100) / 100}/{op.unit}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.landlordShare}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.tenantShare}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.averageMaterialResult * op.area)/netSeededAcres * 100) / 100}/ac
            </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.averageMaterialResult * op.area)/netSeededAcres * (op.landlordShare / 100) * 100) / 100}/ac
            </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.averageMaterialResult * op.area)/netSeededAcres * (op.tenantShare / 100) * 100) / 100}/ac
            </Text>
        </View>
      </View>
    )
  }

  function displayFieldPasses(type) {
    return (
      <View
        style={{ padding: '5px 10px' }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            color: darkText,
            fontSize: 10,
            fontWeight: 600,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <View style={{ width: '10%' }}>
            <Text>
              Year
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Description
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Acres
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Price ($/ac)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Landlord (%)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              Tenant (%)
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              {type.toUpperCase() === "REVENUE" ? "Total Revenue ($/ac)" : "Total Cost ($/ac)"}
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              {type.toUpperCase() === "REVENUE" ? "Landlord Revenue ($/ac)" : "Landlord Cost ($/ac)"}
            </Text>
          </View>

          <View style={{ width: '10%' }}>
            <Text>
              {type.toUpperCase() === "REVENUE" ? "Tenant Revenue ($/ac)" : "Tenant Cost ($/ac)"}
            </Text>
          </View>
        </View>

        {type.toUpperCase() === "FIELDPASS" && fieldpassOps.map((op, i) => createFieldPassRow(op, i))}
        {type.toUpperCase() === "COSTS" && otherCostOps.map((op, i) => createFieldPassRow(op, i))}
        {type.toUpperCase() === "REVENUE" && otherRevOps.map((op, i) => createFieldPassRow(op, i))}

      </View>
    )
  }

  function createFieldPassRow(op, i) {
    return (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: blackText,
          fontSize: 9,
          fontWeight: 500,
          padding: 8,
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <View style={{ width: '10%' }}>
          <Text>
            {op.cropSeason}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.productName}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {Math.round(op.area * 100) / 100}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round(op.price * 100) / 100}/ac
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.landlordShare}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            {op.tenantShare}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.area)/netSeededAcres * 100) / 100}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.area)/netSeededAcres * (op.landlordShare / 100) * 100) / 100}
          </Text>
        </View>

        <View style={{ width: '10%' }}>
          <Text>
            ${Math.round((op.price * op.area)/netSeededAcres * (op.tenantShare / 100) * 100) / 100}
          </Text>
        </View>
      </View>
    )
  }

  const displayProfitSummary = () => {
    return (
      <View
        style={{ padding: '5px 10px' }}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            color: darkText,
            fontSize: 10,
            fontWeight: 600,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <View style={{ width: '40%' }}>
            <Text>
              Summary
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              Total ($/ac)
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              Landlord ($/ac)
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              Tenant ($/ac)
            </Text>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: blackText,
            fontSize: 9,
            fontWeight: 500,
            padding: 8,
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <View style={{ width: '40%' }}>
            <Text>
              Revenue
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${Math.abs(Math.round(totalRevenue * 100) / 100)}/ac
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${landlordRev}/ac
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${tenantRev}/ac
            </Text>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: blackText,
            fontSize: 9,
            fontWeight: 500,
            padding: 8,
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <View style={{ width: '40%' }}>
            <Text>
              Costs
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${Math.abs(Math.round(totalCosts * 100) / 100)}/ac
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${landlordCost}/ac
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${tenantCost}/ac
            </Text>
          </View>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: blackText,
            fontSize: 9,
            fontWeight: 500,
            padding: 8,
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <View style={{ width: '40%' }}>
            <Text>
              Profit
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${Math.round(totalProfit * 100) / 100}/ac
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${landlordProfit}/ac
            </Text>
          </View>

          <View style={{ width: '20%' }}>
            <Text>
              ${tenantProfit}/ac
            </Text>
          </View>
        </View>

      </View>
    )
  }

  return (
    <Document>
      <Page wrap size='A4' style={styles.body}>

        <View style={styles.header}>
          <View style={{ width: '200px' }}>

          </View>
          <View style={{ width: '200px' }}>
            {pl_logo !== null && (
              <Image src={pl_logo} data-img={pl_logo} style={styles.plLogo} />
            )}
          </View>
          <View style={{ width: '200px' }}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 800,
                color: blackText,
                paddingLeft: 40,
              }}
            >
              {report}
            </Text>
          </View>
        </View>

        {notes !== '' && (
          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '8px 0 0 0'
            }}
          >
            <View
              style={{
                margin: '8px',
                padding: 8,
                border: 1,
                borderColor: grey,
                borderRadius: 2,
                width: 400,
              }}
            >
              <Text
                style={{ fontWeight: 500, fontSize: 12, color: darkText }}
              >
                {'Notes: '}
              </Text>
              <Text
                style={{
                  fontWeight: 500,
                  fontSize: 10,
                  color: blackText,
                  marginTop: 4
                }}
              >
                {notes}
              </Text>
            </View>
          </View>
        )}

        {
          (
            harvestOps !== null &&
            harvestOps !== undefined &&
            harvestOps.length > 0) && (
            <View>
              <View style={{ padding: '10px' }} wrap={harvestOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{ fontWeight: 500, fontSize: 12, color: blackText }}>
                      Harvest Revenue
                    </Text>
                  </View>
                </View>

                {displayPlantings('Harvest')}
              </View>
            </View>
          )}

        {
          (
            seedingOps !== null &&
            seedingOps !== undefined &&
            seedingOps.length > 0) && (
            <View>
              <View style={{ padding: '10px' }} wrap={seedingOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{ fontWeight: 500, fontSize: 12, color: blackText }}>
                      Seed Costs
                    </Text>
                  </View>
                </View>

                {displayPlantings('Seeding')}

              </View>
            </View>
          )}

        {
          (
            applicationOps !== null &&
            applicationOps !== null &&
            applicationOps.length > 0
          ) && (
            <View>
              <View style={{ padding: '10px' }} wrap={applicationOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{ fontWeight: 500, fontSize: 12, color: blackText }}>
                      Chemical and Fertilizer Costs
                    </Text>
                  </View>
                </View>

                {displayChemicals()}

              </View>
            </View>
          )
        }

        {
          (
            fieldpassOps !== null &&
            fieldpassOps !== undefined &&
            fieldpassOps.length > 0) && (
            <View>
              <View style={{ padding: '10px' }} wrap={fieldpassOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{ fontWeight: 500, fontSize: 12, color: blackText }}>
                      Cost of Field Passes
                      </Text>
                  </View>
                </View>

                {displayFieldPasses('fieldpass')}

              </View>
            </View>
          )
        }

        {
          (
            otherCostOps !== null &&
            otherCostOps !== undefined &&
            otherCostOps.length > 0) && (
            <View>
              <View style={{ padding: '10px' }} wrap={otherCostOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{ fontWeight: 500, fontSize: 12, color: blackText }}>
                      Other Costs
                      </Text>
                  </View>
                </View>

                {displayFieldPasses('costs')}

              </View>
            </View>
          )}

        {
          (
            otherRevOps !== null &&
            otherRevOps !== undefined &&
            otherRevOps.length > 0) && (
            <View>
              <View style={{ padding: '10px' }} wrap={otherRevOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{ fontWeight: 500, fontSize: 12, color: blackText }}>
                      Other Revenue
                      </Text>
                  </View>
                </View>

                {displayFieldPasses('revenue')}

              </View>
            </View>
          )}


        <View>
          <View style={{ padding: '10px' }} wrap={otherRevOps.length > 14}>
            <View
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: '13px 10px 3px 10px',
              }}
            >
              <View
                style={{
                  width: '100%',
                  padding: '5px',
                  borderBottomColor: grey,
                  borderBottomWidth: 1,
                }}
              >
                <Text style={{ fontWeight: 500, fontSize: 12, color: blackText }}>
                  Profit Summary
                  </Text>
              </View>
            </View>

            {displayProfitSummary()}

          </View>
        </View>

        {logo !== null && (
          <Image src={logo} data-img={logo} style={styles.footerLogo} fixed />
        )}

        <Link style={styles.footerLink} src={'https://profit.ag'} fixed>
          profit.ag
        </Link>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />

      </Page>
    </Document>
  )
}

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  TextField,
  Select,
  MenuItem,
  Divider,
  Button,
  Modal,
  Radio,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { blackText, grey } from '../../../styles/colors';
import { MenuProps } from '../../../styles/select';
import { calculateYieldFromHistory } from '../../../utils/dataFetchers';
import { createYieldBodyForHistory } from '../getValues';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  paper: theme.modalPaper,
  close: {
    ...theme.close,
    top: '10px',
    right: '10px',
  },
  selections: {
    height: 40,
    minWidth: 50,
  },
  infoIcon: {
    ...theme.infoToolTip,
    marginLeft: '2px',
    marginBottom: '10px'
  }
}));

export function RefineEstimates({
  yields,
  setYields,
  updateYields,
  open,
  setOpen,
  mobileView,
  commodityYear,
  exclusionYears,
  getCalculateYieldFromHistoryData,
  getInputAndSendRequest,
  setHistoryYields,
  historyYields
}) {
  const classes = useStyles();

  const yieldTypes = [
    'A', 'Other', 'AY', 'NA', 'PA', 'DA', 'P', 'J', 'NW', 'PW', 'WY', 'AX',
  ];

  // 12 Yields after commodity year. Filled with defaults if less than 12 exists
  const [yieldsToRefine, setYieldsToRefine] = useState([]);

  const [loading, setLoading] = useState(false);

  const defaultYield = {
    inputedYieldValue: '',
    tYield: '',
    tUnit: 'BU',
    year: '',
    yieldType: 'A',
  }

  useEffect(() => {
    // Find most recent 12 years after selected commodity year
    let yieldsBeforeCommoditYear = [...yields].filter(
      (x) => +x.year < commodityYear,
    ).slice(-12)

    if (yieldsBeforeCommoditYear.length >= 12) {
      setYieldsToRefine(yieldsBeforeCommoditYear)
    } else if (yieldsBeforeCommoditYear.length > 0) {
      addDefaultYields(yieldsBeforeCommoditYear)
    } else {
      // No yields
      let defaultYields = [{...defaultYield, year: commodityYear - 1}]
      addDefaultYields(defaultYields)
    }
  }, [yields])

  // If there are less than 12 yields, add defaults with no estimates
  const addDefaultYields = (yields) => {
    let twelveYields = [...yields].sort((a, b) => a.year - b.year)

    // Start with last year we have - 1
    let yieldYear = twelveYields[0].year - 1

    while (twelveYields.length < 12) {
      let yieldToAdd = {...defaultYield, year: yieldYear.toString() }

      twelveYields.push(yieldToAdd)

      //decrement year
      --yieldYear

    }

    setYieldsToRefine(twelveYields.sort((a, b) => a.year - b.year))
  }


  useEffect(() => {
    if (yields.length > 0) {
      addExclusions(yields, exclusionYears);
    }
  }, [exclusionYears]);

  const addExclusions = (arr, exclusions) => {
    const yieldsWithExclusions = arr.map(
      (x) => ({ ...x, exclusionApplied: exclusions.includes(x.year) }),
    );
    setYields(yieldsWithExclusions);
  };

  const handleChange = (val, key, year) => {
    const yieldsCopy = [...yieldsToRefine];

    // Find index of object with matching year to update
    // Yields array can contain values not displayed, so can't just pass index from createRow
    const indexToUpdate = yieldsCopy.findIndex((x) => x.year === year);

    // Remove object to be updated from array
    const yieldToUpdate = yieldsCopy.splice(indexToUpdate, 1)[0];

    // Update value
    yieldToUpdate[key] = val;

    // Add updated yield back at index
    yieldsCopy.splice(indexToUpdate, 0, yieldToUpdate);

    setYieldsToRefine(yieldsCopy);
  };

  const handleSave = async () => {
    setLoading(true);
    const bodyData = getCalculateYieldFromHistoryData();
    const histYields = createYieldBodyForHistory(yieldsToRefine, commodityYear);

    const calculateBody = {
      ...bodyData,
      HistYield: histYields,
    };

    const refinedEstimates = await calculateYieldFromHistory(calculateBody);
    const parsed = JSON.parse(refinedEstimates);

    updateYields(parsed.TAAPHYield, parsed.AverageYield);
    getInputAndSendRequest();
    setLoading(false);
    // update yields to refined
    setYields(yieldsToRefine)
    setOpen(false);
  };

  const body = () => (
    <Box
      p={2}
      fontWeight={"fontWeightBold"}
      color={blackText}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      style={{ width: 500 }}
    >
      <Box px={1} pb='4px' fontSize={18} fontWeight={500}>
        Refine Estimates
      </Box>
      <Box px={1} pb={1} fontWeight={500}>
        Input yield information for each year to calculate historic yield.
      </Box>

      <Divider />

      <Box display="flex" py={1} mt={1} textAlign='center'>
        <Box style={{ width: '15%' }}>
          Year
        </Box>
        <Box style={{ width: '16%' }}>
          Yield
        </Box>
        <Box pl={2} style={{ width: '32%' }}>
          Apply Exclusion?
          <CustomToolTip
            placement="right-start"
            title="For revenue protection coverage, implied volatility as published by RMA. Generally this is the implied volatility of the option contract corresponding to the futures contract specified inthe applicable crop’s projected price or harvest price definition."
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </CustomToolTip>
        </Box>
        <Box pl={1} style={{ width: '19%', whiteSpace: 'nowrap' }}>
          Yield Type
          <CustomToolTip
            placement="right-start"
            title="The yield type as defined by the RMA's document 'Yield Type Master P15_1 Exhibit' In Appendix III on the RMA website."
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </CustomToolTip>
        </Box>
        <Box pl={2} style={{ width: '18%' }}>
          T-Yield
          <CustomToolTip
            placement="right-start"
            title="Trend yield for your county."
          >
            <InfoOutlinedIcon className={classes.infoIcon} />
          </CustomToolTip>
        </Box>
      </Box>

      <Box textAlign='center'>
      {/* Create row from 12 entries previous to commodity year */}
      { yieldsToRefine.map((x, i) => createRow(x, i))}
      </Box>

      <Divider />

      <Box
        px={1}
        pt={1}
        display="flex"
        justifyContent="flex-end"
        style={{ width: '100%' }}
      >
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleSave()}
          disableElevation
        >
          Recalculate Premium
        </Button>
      </Box>
    </Box>
  );

  const createRow = (x, i) => (
    <Box key={i}>
      <Divider />
      <Box
        display="flex"
        alignItems="center"
      >
        <Box style={{ width: '15%' }}>
          {x.year}
        </Box>
        <Box style={{ width: '16%' }}>
          <TextField
            className={classes.selections}
            variant="outlined"
            onChange={(e) => handleChange(e.target.value, 'inputedYieldValue', x.year)}
            value={x.inputedYieldValue}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
          />
        </Box>
        <Box style={{ width: '32%' }}>
          { exclusionYears.includes(x.year) && (
            <Box display="flex" alignItems="center">
              <Radio
                color="primary"
                checked={x.exclusionApplied}
                onChange={(e) => handleChange(e.target.value === 'yes', 'exclusionApplied', x.year)}
                value="yes"
                name="Apply-Exclusion"
                inputProps={{ 'aria-label': 'Exclude' }}
                style={{ padding: '0 6px 0 0' }}
              />
              <Box mr={1}>
                Yes
              </Box>
              <Radio
                color="primary"
                checked={!x.exclusionApplied}
                onChange={(e) => handleChange(e.target.value === 'yes', 'exclusionApplied', x.year)}
                value="no"
                name="Do-Not-Apply-Exlcusion"
                inputProps={{ 'aria-label': 'No Exclude' }}
                style={{ padding: '0 6px 0 0' }}
              />
              <Box>
                No
              </Box>
            </Box>
          )}
        </Box>
        <Box pt={.5} style={{ width: '19%' }}>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={x.yieldType}
            onChange={(e) => handleChange(e.target.value, 'yieldType', x.year)}
          >
            {
                yieldTypes.map((type, i) => (
                  <MenuItem
                    key={i}
                    value={type}
                  >
                    {type}
                  </MenuItem>
                ))
              }
          </Select>
        </Box>
        <Box style={{ width: '18%' }}>
          {x.tYield}
        </Box>
      </Box>
    </Box>
  );

  return (
    mobileView ? (
      <Box
        mx={1}
        mt={2}
        borderRadius="borderRadius"
        border={1}
        borderColor={grey}
        style = {{ width: '500px', }}
      >
        { body() }
        {loading && <SpinningLoader />}
      </Box>
    ) : (
      <Modal
        open={open}
        onClose={() => setOpen(false)}
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'auto',
        }}
      >
        <Box
          className={classes.paper}
          borderRadius="borderRadius"
          boxShadow={2}
          style={{
            top: '5%',
            margin: 'auto',
            zIndex: 1002,
            height: 'auto',
            width: '500px',
          }}
        >
          <HighlightOffIcon
            className={classes.close}
            onClick={() => setOpen(false)}
          />
          { body() }
          {loading && <SpinningLoader />}
        </Box>
      </Modal>
    )
  );
}

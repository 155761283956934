import * as L from 'leaflet';
import * as wkt from 'terraformer-wkt-parser';
import * as turf from '@turf/turf';

/**
 * Takes a wkt field shape returned from database and returns GeoJSON
 * @param {String} shape WKT field shape
 * @returns {Object} converted geoJSON
 */
export const getGeoJson = (shape) => (
  L.GeoJSON.geometryToLayer(wkt.parse(shape)).toGeoJSON()
);

export const getAcres = (geoJson) => {
  const featureCollection = {
    type: 'FeatureCollection',
    features: [geoJson],
  };
  return turf.convertArea(turf.area(featureCollection), 'meters', 'acres');
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../../../constants";
import InfoPopover from "../../../../../Helpers/InfoPopover";

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    width: "150px",
  },
  title: {
    fontSize: "14px",
    fontWeight: "bold",
    lineHeight: "19px",
    color: COLORS.darkGray,
    display: "flex",
    alignItems: "center",
  },
}));

/**
 * Shows the label and info for each filter
 * @param {object} option array of values
 */

const Header = ({ option }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.title}>
        {option.label}
        <InfoPopover text={option.helper} />
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  option: PropTypes.object.isRequired,
};

/* eslint-disable react/jsx-props-no-spreading */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, AppBar, Typography, Divider, TextField, Container,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DoneIcon from '@material-ui/icons/Done';
import ClearIcon from '@material-ui/icons/Clear';
import * as turf from '@turf/turf';
import { cloneDeep } from 'lodash';
import { SelectFieldV2 } from '../FieldSelection/SelectFieldV2';
import { DigsMap } from '../Maps/DigsMap';
import { Reports } from './Services/Reports';
import { Checkout } from './Checkout/Checkout';
import { About } from './About';
import { roundIcon, CustomToolTip } from '../../utils/customComponents';
import { useWindowDimensions } from '../../utils/dimensions';
import { FieldContext } from '../Context/FieldContext';
import { UserContext } from '../Context/UserContext';
import {
  exists, getImage, numFormat, validEmail, getCircularReplacer,
} from '../../utils/helpers';
import { getStateAndCounty } from '../../utils/dataFetchers';
import {
  grey,
  lightGrey,
  blackText,
  lightBlue,
  greyBlue,
} from '../../styles/colors';
import { SpinningLoader } from '../Shared/SpinningLoader';

import poweredBy from '../../images/Ag-Analytics_Full_Black_Powered.svg';
import { InfoModal } from './InfoModal';
import { PopupManager } from '../Shared/Popups/PopupManager';
import ADSLogo from '../../images/ADS_Logo.png'

const useStyles = makeStyles((theme) => ({
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  centeredAppBar: {
    ...theme.appBar,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  appBarSelections: theme.appBarSelections,
  smallAppBarSelection: {
    ...theme.appBarSelections,
    margin: '0 10px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  fieldBox: {
    backgroundColor: '#ffffff',
    color: theme.palette.greys.dark,
    padding: '10px',
    fontWeight: 500,
    width: '350px',
    position: 'fixed',
    top: 145,
    right: 20,
    overflowY: 'auto',
  },
  select: {
    padding: '8px',
    margin: '8px',
    display: 'flex',
    fontSize: 18,
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  remove: {
    fontSize: '12px',
    maringBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  info: {
    color: lightBlue,
    '&:hover': {
      color: greyBlue,
      cursor: 'pointer',
    },
  },
  arrows: {
    color: lightBlue,
    '&:hover': {
      color: greyBlue,
      cursor: 'pointer',
    },
  },
  fullLogo: {
    width: '250px',
    height: 'auto',
    marginRight: '20px'
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
  },
  smallLogo: {
    width: '90px',
    height: 'auto',
  },
}));

/**
 * This component will serve as a parent for all components necessary for selecting
 * fields, adding packages, and submitting a DIGS order.
 * @param  {Function} setHomeSection Used for navbar highlighting
 * @param  {Bool} hideNav Hide navigation bar for DIGS hosted pages
 * @param  {Bool} ads Bool to determine if being rendered for ADS or DIGS (initailly passed down from App.js)
 * @returns {JSX} Digs ordering
 */
export function Digs({ setHomeSection = undefined, hideNav = false, ads = false }) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { height, width } = useWindowDimensions();
  const [loading, setLoading] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);

  const user = useContext(UserContext)[0];
  const [fieldData, setFieldData] = useContext(FieldContext);

  // for DIGS specific page we start at field selction
  // const [section, setSection] = useState(hideNav ? 1 : 0);
  const [section, setSection] = useState(1);

  // selected field
  const [selectedField, setSelectedField] = useState(null);

  // index of field to edit name for
  const [editFieldName, setEditFieldName] = useState(false);

  // all fields in order
  const [fields, setFields] = useState([]);

  // id of field to remove
  const [fieldToRemove, setFieldToRemove] = useState('');
  const [coordinatesToRemove, setCoordinatesToRemove] = useState([]);

  const [formData, setFormData] = useState({
    orderName: 'Water Management',
    address: '',
    city: '',
    email: '',
    organization: '',
    phone: '',
    state: 'New York',
    userName: '',
    zipCode: '',
    preferredDate: '',
    disableEmailUpdate: false,
    comments: '',
  });

  const [fileData, setFileData] = useState(null);

  // created fields count
  const count = useRef(1);

  useEffect(() => {
    const hasViewed = JSON.parse(sessionStorage.getItem('viewed'));
    if (!hasViewed?.digsInfo) {
      setInfoModalOpen(true);
    }
  }, []);

  useEffect(() => {
    loadField(fieldData);
  }, [fieldData]);

  // Select most recently user choosen field from fieldData
  const loadField = (data) => {
    const field = data?.selectedField?.selectedAt ? data.selectedField : null;
    const drawnField = data?.fieldToClaim?.selectedAt ? data.fieldToClaim : null;

    if (field && drawnField) {
      // Both exists, so take most recent
      const selectedTime = new Date(field.selectedAt);
      const claimedTime = new Date(drawnField.selectedAt);

      if (selectedTime.getTime() > claimedTime.getTime()) {
        select(field);
      } else {
        select({ ...drawnField, name: makeName(fieldData.fieldToClaim) });
      }
    } else if (field) {
      select(field);
    } else if (drawnField) {
      select(drawnField);
    }
  };

  const makeName = (field) => {
    let name = 'Field';

    if (field?.latitude) {
      name += `- ${numFormat(field?.latitude)}, ${numFormat(field?.longitude)}`;
    }

    return name;
  };

  useEffect(() => {
    // Sets Navigation to Services
    if (setHomeSection !== undefined) {
      setHomeSection(3);
    }
  }, [setHomeSection]);

  useEffect(() => {
    const updatedFields = fields.filter((x) => x.id !== fieldToRemove);
    setFields(updatedFields);
    setFieldToRemove('');
  }, [fieldToRemove]);

  useEffect(() => {
    if (section === 4) {
      // Order completed
      setFields([]);
      count.current = 1;
    }
  }, [section]);

  useEffect(() => {
    if (fields.length === 0) {
      setSelectedField(null);
    } else if (selectedField !== null) {
      const fieldIds = fields.map((x) => x.id);
      if (!fieldIds.includes(selectedField.id)) {
        changeSelectedField(fields[0]);
        setSelectedField(fields[0]);
      }
    }
  }, [fields]);

  useEffect(() => {
    if (user?.email !== null && validEmail(user?.email)) {
      setFormData({
        ...formData,
        email: user.email,
        disableEmailUpdate: true,
        validEmail: true,
      });
    }
  }, [user]);

  const goToAddServices = () => {
    setSection(2);
  };

  const select = async (newField) => {
    // If 5 fields are already in order and user tries to add another, prompt and return
    if (fields.length >= 5) {
      const fieldsInOrder = fields.map((field) => field.id);
      if (!fieldsInOrder.includes(newField.id)) {
        enqueueSnackbar('If you would like to add services for more than 5 fields, please submit this order and place another with your additional fields');
        return;
      }
    }
    /* get currently selected field from map */
    setLoading(true);
    const boundaries = fields.map((x) => x.boundary);
    if (boundaries.includes(newField.boundary)) {
      // moving to services here prevented being able to return to select field
    } else if (Object.keys(newField).length > 0) {
      const fieldToAdd = { ...newField, reports: [], color: '' };

      if (!fieldToAdd?.name || fieldToAdd.name === '') {
        fieldToAdd.name = `Field-${count.current}`;
        count.current += 1;
      }

      setSelectedField({ ...fieldToAdd, reports: [] });

      // Update form data to selected fields state
      setFormData({ ...formData, state: fieldToAdd?.state });

      if (selectedField !== null && newField.id === selectedField.id) {
        // edited field
        const filteredFields = fields.filter((x) => x.id !== newField.id);
        setFields([...filteredFields, fieldToAdd]);
      } else {
        setFields([...fields, fieldToAdd]);
      }
    }
    setLoading(false);
  };

  // to ensure tooltip has been drawn, this will be called in map component after draw
  const getFieldImage = async (field) => {
    setLoading(true);

    const fieldImage = await getImage('map-digs');

    const updateField = { ...field, fieldImage };
    setSelectedField(updateField);

    const filteredFields = fields.filter((x) => x.id !== field.id);
    setFields([...filteredFields, updateField]);
    setLoading(false);
  };

  const deleteField = (field) => {
    const filtered = fields.filter((x) => x.boundary !== field.boundary);
    setFields(filtered);
    setCoordinatesToRemove(field.coordinates.coordinates);

    if (field.id === fieldData.selectedField.id) {
      setFieldData({
        ...fieldData,
        selectedField: {
          id: '', name: '', farmId: '', farm: '', orgId: '', org: '', boundaryId: '',
        },
      });
    }
  };

  const changeSelectedField = (field) => {
    setSelectedField(field);
  };

  const handleZone = async (data, action, fieldId, zoneNumber, color) => {
    switch (action) {
      case 'selectField': {
        // add field to selected if not already present
        const selectedIds = fields.map((x) => x.id);
        if (!selectedIds.includes(data.id)) {
          const newField = {
            ...data, name: `Field-${count.current}`, reports: [], color: '',
          };
          setFields((prev) => [...prev, newField]);
          changeSelectedField(newField);
          count.current += 1;
        } else {
          // edited boundary
          const fieldCopy = cloneDeep(fields);
          const filtered = fieldCopy.filter((x) => x.id !== data.id);
          const updatedField = {
            ...data,
            fieldImage: selectedField.fieldImage,
            name: selectedField.name,
            reports: selectedField.reports,
            color: selectedField.color,
          };
          setFields([updatedField, ...filtered]);
          changeSelectedField(updatedField);
        }
        if (width < 1200) {
          const elmnt = document.getElementById('mobile-fields');
          elmnt.scrollIntoView();
        }
      }
        break;
      case 'add':
        // remove selected field since this will be pieces of it
        setFieldToRemove(fieldId);
        createField(data, zoneNumber, color);
        break;
      case 'edit':
        // not yet used
        break;
      case 'undo':
        removeField(fields.length - 1);
        break;
      case 'clear':
        setFields([]);
        setSelectedField(null);
        break;
      default:
        console.error(`unexpected action: ${action}`);
    }
  };

  const createField = async (data, zoneNumber, color) => {
    /**
     * @param {Object} data
     * Build a field from CLU data. Use id for editing with segment
     * and grid tools.
     */
    count.current += 1;
    setLoading(true);

    // get centroid, then state and county
    const polygon = turf.polygon(data.geometry.coordinates);
    const centroid = turf.centroid(polygon);
    const latitude = centroid.geometry.coordinates[1];
    const longitude = centroid.geometry.coordinates[0];
    const [state, county] = await getStateAndCounty(latitude, longitude);

    const id = data.id !== undefined ? data.id : Math.random().toString(36).substring(7);
    const name = `Section-${zoneNumber}`;

    const newField = {
      color,
      county,
      id,
      latitude,
      longitude,
      name,
      state,
      acres: data.properties.CALCACRES,
      boundary: JSON.stringify(data, getCircularReplacer()),
      coordinates: data.geometry,
      farm: '',
      farmId: '',
      org: '',
      orgId: '',
      points: data.geometry.coordinates,
      reports: [],
    };

    setFields((prev) => [...prev, newField]);
    changeSelectedField(newField);
    setLoading(false);
  };

  const addToOrder = (report, all = false) => {
    // add to order for selected field or all fields
    // if (all) {
    //   fields.map((field) => addReportToField(field, report));
    // } else {
    //   addReportToField(selectedField, report);
    // }
    addReportToField(selectedField, report);
  };

  const addReportToField = (field, report) => {
    const fieldsCopy = [];

    fields.forEach((x) => {
      // copy report object
      const fieldCopy = cloneDeep(x);
      // confirm report key added
      if (!('reports' in fieldCopy)) {
        fieldCopy.reports = [];
      }

      fieldsCopy.push(fieldCopy);
    });

    const fieldToUpdate = fieldsCopy.filter((x) => x.id === field.id)[0];

    const reportNames = field.reports.map((x) => x.name);

    // remove other bundles
    if (report.type === 'bundle') {
      // index of bundle
      const index = fieldToUpdate.reports.findIndex((x) => x.type === 'bundle');
      if (index >= 0) {
        // remove bundle
        fieldToUpdate.reports.splice(index, 1);
      }
      // remove reports included with added bundle
      fieldToUpdate.reports = fieldToUpdate.reports.filter(
        (x) => !report.included.includes(x.name),
      );
    }
    if (!reportNames.includes(report.name)) {
      fieldToUpdate.reports = [...fieldToUpdate.reports, report];
      // fieldsCopy.splice(fieldIndex, 0, fieldToUpdate);
      setFields(fieldsCopy);
      changeSelectedField(fieldToUpdate);
    }
  };

  const removeField = (i) => {
    const fieldsCopy = [...fields];
    const removedField = fieldsCopy.splice(i, 1);

    setCoordinatesToRemove(removedField[0].coordinates.coordinates);
    // console.log('selected field', selectedField)
    if (fields[i].id === selectedField.id) {
      setSelectedField(null);
      // removed field is currently selected field
      if (fieldsCopy.length > 0) {
        // set new selected field to most recently added
        setSelectedField(fieldsCopy[0]);
      } else {
        setSelectedField(null);
      }
    }
    setFields(fieldsCopy);
  };

  const handleNameChange = (val, i) => {
    const fieldCopy = [...fields];
    const fieldToUpdate = fieldCopy.splice(i, 1)[0];
    fieldToUpdate.name = val;
    fieldCopy.splice(i, 0, fieldToUpdate);
    setFields(fieldCopy);
  };

  const removeFromOrder = (fieldIndex, reportIndex, report) => {
    // make a copy
    const fieldCopy = [...fields];

    // remove and store field to update from copy
    const fieldToUpdate = fieldCopy.splice(fieldIndex, 1)[0];

    // remove selected report
    fieldToUpdate.reports.splice(reportIndex, 1);

    if (report.type === 'bundle') {
      // update price if remediation was included
      report.price = report.included.includes('Remediation Response Analysis')
        ? report.price - report.remediationPrice : report.price;

      // remove remediation and selected optional reports from includes
      const updatedIncluded = report.included.filter(
        (x) => x !== 'Remediation Response Analysis' && !report.optional.includes(x),
      );
      report.included = updatedIncluded;
    }

    // add updated field back to copy at correct location
    fieldCopy.splice(fieldIndex, 0, fieldToUpdate);

    setFields(fieldCopy);
  };

  const emptyCartInstructions = () => (
    <Box color={blackText} fontWeight={500} fontSize={16}>
      <Box>
        To add services to your cart, select a field on the map using the Field Selection tool.
      </Box>
      <Box mt={1}>
        {'Learn more about the services provided on our '}
        <span className={classes.arrows} onClick={() => setSection(0)}>about page</span>
        .
      </Box>

    </Box>
  );

  const hasReports = (field) => (
    field.reports.length > 0
  );

  // Displays
  const fieldSelect = () => (
    <Box display="flex">
      {user.isAuthenticated && (
        <Box style={{ height: !hideNav ? height - 200 : height - 150, width: '300px' }}>
          <SelectFieldV2 containerHeight={height - 200} select={select} />
        </Box>
      )}

      <Box
        boxShadow={1}
        style={{
          height: !hideNav ? height - 255 : height - 76.5,
          width: hideNav ? width : user.isAuthenticated ? width - 675 : width - 375,
        }}
      >
        <DigsMap
          selectField={select}
          selectedField={selectedField}
          goToAddServices={goToAddServices}
          getFieldImage={getFieldImage}
          removeFromOrder={deleteField}
          fields={fields}
          coordinatesToRemove={coordinatesToRemove}
          setCoordinatesToRemove={setCoordinatesToRemove}
          handleZone={handleZone}
          canEdit
          includeClus
          includeSSURGO={false}
          containerHeight={!hideNav ? height - 200 : height - 76.5}
        />
      </Box>
    </Box>
  );

  const mobileFieldSelect = () => (
    <Box>

      {
        user.isAuthenticated && <SelectFieldV2 containerHeight={500} mobile />
      }
      <Box style={{ width: width - 6 }}>
        <DigsMap
          selectField={select}
          selectedField={selectedField}
          removeFromOrder={deleteField}
          goToAddServices={goToAddServices}
          getFieldImage={getFieldImage}
          fields={fields}
          coordinatesToRemove={coordinatesToRemove}
          setCoordinatesToRemove={setCoordinatesToRemove}
          handleZone={handleZone}
          canEdit
          includeClus
          includeSSURGO={false}
          containerHeight={height - 200}
        />
      </Box>

      {
        (section === 1 && width <= 1200 && !hideNav && fields.length > 0) && mobileSelectedFields()
      }
    </Box>
  );

  const selectedFields = () => (
    <Box
      className={classes.fieldBox}
      boxShadow={1}
      style={{ maxHeight: height - 220 }}
    >
      <Box>
        <Box style={{ height: '75px' }}>
          <Typography
            align="center"
            variant="h6"
            style={{ margin: '10px 0' }}
          >
            Selected Fields
          </Typography>
          <Divider style={{ width: '280px' }} />
        </Box>
        {
          fields.length > 0 ? fields.map((field, i) => fieldsInOrder(field, i))
            : emptyCartInstructions()
        }
      </Box>
    </Box>
  );

  const mobileSelectedFields = () => (
    <Box style={{ paddingBottom: '3px' }} id="mobile-fields">
      <Container maxWidth="sm">
        {
          fields.length > 0 && fields.map((field, i) => fieldsInOrder(field, i))
        }
      </Container>
    </Box>

  );

  const fieldsInOrder = (field, i) => (
    <Box
      key={i}
      px={1}
      border={selectedField !== null && selectedField.id !== null && field.id === selectedField.id ? 2 : 1}
      borderColor={selectedField !== null && selectedField.id !== null && field.id === selectedField.id ? greyBlue : grey}
      borderRadius="borderRadius"
      boxShadow={1}
      className={classes.select}
      onClick={() => changeSelectedField(field)}
      style={{ backgroundColor: selectedField !== null && selectedField.id !== null && field.id === selectedField.id ? 'white' : '#fafafa' }}
    >
      <Box
        display="flex"
        justifyContent="space-between"
        style={{ width: '100%' }}
      >

        <Box display="flex">
          {i !== editFieldName
            ? field.name
            : (
              <TextField
                value={field.name}
                onChange={(e) => handleNameChange(e.target.value, i)}
                style={{ width: '100px' }}
                label="Field"
              />
            )}

          {editFieldName === i
            ? (
              <DoneIcon
                color="primary"
                style={{ fontSize: 16, fontWeight: 400 }}
                onClick={() => setEditFieldName(false)}
              />
            )
            : (
              <EditOutlinedIcon
                color="primary"
                style={{ fontSize: 14, fontWeight: 400, marginLeft: '7px' }}
                onClick={() => setEditFieldName(i)}
              />
            )}
          {
              field.color !== '' && <FiberManualRecordIcon style={{ color: field.color }} />
            }
        </Box>

        <Box display="flex" fontSize={14}>
          Acres:
          {' '}
          { field.acres !== undefined ? numFormat(field.acres) : 0 }
          <ClearIcon
            style={{ fontSize: '14px', margin: '0 0 30px 10px' }}
            onClick={() => removeField(i)}
          />
        </Box>
      </Box>

      <Box my={1} style={{ width: '100%' }} fontSize={14}>
        Reports:
      </Box>

      {exists(field?.reports) && field.reports.map((x, j) => (
        <Box key={j} m={1} style={{ width: '100%' }}>
          <Box display="flex" justifyContent="space-between" fontSize={16}>
            <Box>
              {x.name}
            </Box>
            <Box display="flex">
              <Box>
                {'$'}
                {numFormat(x.price * field.acres)}
              </Box>
              <ClearIcon
                className={classes.remove}
                onClick={() => removeFromOrder(i, j, x)}
              />
            </Box>

          </Box>
          <Divider />
        </Box>
      ))}
    </Box>
  );

  const footer = () => (
    <Box
      p={2}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ height: '60px', width: '100%' }}
    >

      {
        (section === 0 || (section === 1 && fields.length > 0))
        && (
        <Box>
          &nbsp;
        </Box>
        )
      }


      {
        section === 0
        && (
        <Box display="flex" alignItems="center">
          {width > 760 &&
            <Box>
              <img
                src={poweredBy}
                className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
                alt={"Powered By Ag-Analytics"}
              />
            </Box>
          }

          <Button
            variant="outlined"
            color="primary"
            style={{ height: '45px', marginRight: '10px' }}
            onClick={() => setSection(1)}
            disableElevation
          >
            Select Fields
            {' '}
            <ArrowForwardIcon />
          </Button>
        </Box>
        )
      }

      {
        section === 1 && (
          fields.length === 0 ? (
            <Box
              display="flex"
              justifyContent="center"
              flexWrap="wrap"
              fontWeight={500}
              fontSize="1.25rem"
              color={blackText}
              style={{ width: '100%' }}
            >
              <Box style={{ marginRight: 4 }}>
                {'Select or draw your fields, then '}
                <span className={classes.arrows} onClick={() => setSection(2)}>add services</span>
                .
              </Box>
              <Box>
                {'For more information '}
                <span className={classes.arrows} onClick={() => setSection(0)}>click here</span>
                .
              </Box>
            </Box>
          ) : (
            <Box display="flex" alignItems="center">
              {width > 760 &&
                <Box>
                  <img
                    src={poweredBy}
                    className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
                    alt={"Powered By Ag-Analytics"}
                  />
                </Box>
              }
              <Button
                variant="outlined"
                color="primary"
                style={{ height: '45px', marginRight: '10px' }}
                onClick={() => setSection(2)}
                disableElevation
              >
                {'Continue '}
                <ArrowForwardIcon />
              </Button>
            </Box>
          )
        )
      }

      {
        (section === 2 || section === 3)
        && (
        <Button
          variant="outlined"
          color="primary"
          style={{ height: '45px' }}
          onClick={() => setSection(section - 1)}
        >
          <ArrowBackIcon />
          Back
        </Button>
        )
      }

      {
        (section === 2)
        && (
          <Box display="flex" alignItems="center">
            {width > 760 &&
              <Box>
                <img
                  src={poweredBy}
                  className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
                  alt={"Powered By Ag-Analytics"}
                />
              </Box>
            }
            <Button
              variant="outlined"
              color="primary"
              style={{ height: '45px', marginRight: '10px' }}
              onClick={() => setSection(section + 1)}
              disableElevation
            >
              {'Continue '}
              <ArrowForwardIcon />
            </Button>
          </Box>
        )
      }

      {
        (section === 3)
        && (
        <Box>
          &nbsp;
        </Box>
        )
      }

      { ((section === 1 && fields.length === 0) || section === 3) && (width > 760) &&
        <Box>
          <img
            src={poweredBy}
            className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
            alt={"Powered By Ag-Analytics"}
          />
        </Box>
      }
    </Box>
  );

  const navBar = () => (
    <Box className={!hideNav ? classes.appBar : classes.centeredAppBar}>
      <Box display="flex">
        {
          !hideNav
          && (
          <Box
            className={classes.appBarSelections}
            color={section === 0 ? greyBlue : grey}
            style={{ marginRight: '100px' }}
            onClick={() => setSection(0)}
          >
            About
          </Box>
          )
        }

        <Box
          className={classes.appBarSelections}
          color={section === 1 ? greyBlue : grey}
          onClick={() => setSection(1)}
        >
          {section > 1
            ? (
              <CheckBoxRoundedIcon
                style={{ marginRight: '10px', fontSize: 27 }}
              />
            )
            : <Box {...roundIcon}>1</Box>}
          Select Fields
        </Box>
        <Box
          className={classes.appBarSelections}
          color={section === 2 ? greyBlue : grey}
          onClick={() => setSection(2)}
        >
          {section > 2
            ? (
              <CheckBoxRoundedIcon
                style={{ marginRight: '10px', fontSize: 27 }}
              />
            )
            :	<Box {...roundIcon}>2</Box>}

          Add Services
        </Box>
        <Box
          className={classes.appBarSelections}
          color={section === 3 ? greyBlue : grey}
          onClick={() => setSection(3)}
        >
          {section > 3
            ? (
              <CheckBoxRoundedIcon
                style={{ marginRight: '10px', fontSize: 27 }}
              />
            )
            :	<Box {...roundIcon}>3</Box>}
          Complete Order
        </Box>
          
      </Box>
      {/* {(ads && width > 1158) &&
        // <Box>
        //   <img src={ADSLogo} style={{width:'100px'}}/>
        // </Box>
      } */}
    </Box>
  );

  const mobileSteps = () => (
    section === 0
      ? (
        <Box className={classes.appBar}>
          <Box
            className={classes.smallAppBarSelection}
            color={greyBlue}
          >
            About
          </Box>

          <Box
            className={classes.smallAppBarSelection}
            color={grey}
            onClick={() => setSection(1)}
          >
            <Box {...roundIcon}>1</Box>
            <Box>
              Select Fields
            </Box>
          </Box>

          <CustomToolTip
            title="Select fields"
            placement="right"
          >
            <ArrowForwardIosIcon
              className={classes.arrows}
              onClick={() => setSection(1)}
            />
          </CustomToolTip>

        </Box>
      )
      : section === 1
        ? (
          <Box className={classes.appBar}>
            { !hideNav
              ? (
                <InfoOutlinedIcon
                  className={classes.info}
                  onClick={() => setSection(0)}
                />
              )
              : <Box>&nbsp;</Box>}

            <Box
              className={classes.smallAppBarSelection}
              color={grey}
            >
              <Box {...roundIcon}>1</Box>
              <Box>
                Select Fields
              </Box>
            </Box>

            <CustomToolTip
              title="Select services"
              placement="left"
            >
              <ArrowForwardIosIcon
                className={classes.arrows}
                onClick={() => setSection(2)}
              />
            </CustomToolTip>

          </Box>
        )
        : section === 2
          ? (
            <Box className={classes.appBar}>
              <CustomToolTip
                title="Back to field selection"
                placement="right"
              >
                <ArrowBackIosIcon
                  className={classes.arrows}
                  onClick={() => setSection(1)}
                />
              </CustomToolTip>

              <Box
                className={classes.smallAppBarSelection}
                color={grey}
              >
                <Box {...roundIcon}>2</Box>
                <Box>
                  Add Services
                </Box>
              </Box>

              <CustomToolTip
                title="Complete order"
                placement="left"
              >
                <ArrowForwardIosIcon
                  className={classes.arrows}
                  onClick={() => setSection(3)}
                />
              </CustomToolTip>

            </Box>
          )
          : section === 3
            ? (
              <Box className={classes.appBar}>
                <CustomToolTip
                  title="Edit order"
                  placement="right"
                >
                  <ArrowBackIosIcon
                    className={classes.arrows}
                    onClick={() => setSection(2)}
                  />
                </CustomToolTip>

                <Box
                  className={classes.smallAppBarSelection}
                  color={grey}
                >
                  <Box {...roundIcon}>3</Box>
                  <Box>
                    Complete Order
                  </Box>
                </Box>

                { !hideNav
                  ? (
                    <InfoOutlinedIcon
                      className={classes.info}
                      onClick={() => setSection(0)}
                    />
                  )
                  : <Box>&nbsp;</Box>}
              </Box>
            )
            : <Box className={classes.appBar} />
  );

  return (
    <Box style={{ height: hideNav ? '100%' : height - 65 }}>
      <AppBar
        position="fixed"
        color="transparent"
        style={{
          boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.05)',
          marginTop: hideNav ? '0px' : '56px',
        }}
      >
        { width > 800 ? navBar() : mobileSteps() }
      </AppBar>

      <Box
        className={classes.body}
        style={{
          paddingTop: hideNav ? '0px' : '75px',
          marginTop: hideNav ? '20px' : '0px',
          backgroundColor: section !== 0 ? lightGrey : '#ffffff',
          overflowY: hideNav ? 'hidden' : 'auto',
        }}
      >
        <Box
          style={{
            height: hideNav ? '100%' : section === 4 ? height - 145 : height - 200,
            overflowY: hideNav ? 'hidden' : 'auto',
            overflowX: 'hidden',
          }}
        >
          <Box display="flex">
            <Box
              style={{
                width: (section === 1 && (width <= 1200 || hideNav)) ? width : (section === 2 && width <= 1200) ? width : (section === 1 || section === 2) ? width - 350 : width,
              }}
            >
              {
                (section === 2 && width <= 1200) && mobileSelectedFields()
              }

              {
                section === 0 ? <About setSection={setSection} />
                  : section === 1 ? ((width > 1200 || hideNav) ? fieldSelect() : mobileFieldSelect())
                    : section === 2
                      ? (
                        <Reports
                          addToOrder={addToOrder}
                          selectedField={selectedField}
                          fields={fields}
                          setSection={setSection}
                          includesFields
                        />
                      )
                      : (
                        <Checkout
                          order={fields.filter((x) => x.reports.length > 0)}
                          remove={removeFromOrder}
                          includesFields
                          setSection={setSection}
                          hideNav={hideNav}
                          formData={formData}
                          setFormData={setFormData}
                          fileData={fileData}
                          setFileData={setFileData}
                          ads={ads}
                        />
                      )
              }
            </Box>
            <Box style={{ backgroundColor: lightGrey }}>
              {
                ((section === 1 && width > 1200 && !hideNav) || (section === 2 && width > 1200)) && selectedFields()
              }
            </Box>
          </Box>
        </Box>
      </Box>

      { hideNav && (
        <Box
          display="flex"
          borderRadius="borderRadius"
          style={{
            backgroundColor: '#ffffff',
            position: 'fixed',
            bottom: 4,
            right: 10,
            zIndex: 1020,
          }}
        >
          <img
            src={poweredBy}
            alt="Powered by AgAnalytics"
            style={{
              height: 30,
              width: 'auto',
            }}
          />
        </Box>
      )}

      { (hideNav && fields.length > 0 && section === 1) && (
        <Box
          display="flex"
          borderRadius="borderRadius"
          style={{
            backgroundColor: '#ffffff',
            position: 'fixed',
            top: 178,
            right: 10,
            zIndex: 1020,
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setSection(2)}
          >
            Continue
          </Button>
        </Box>
      )}

      {(
        hideNav
        && section === 2
        && fields.some(hasReports)
      ) && (
        <Box
          display="flex"
          borderRadius="borderRadius"
          style={{
            backgroundColor: '#ffffff',
            position: 'fixed',
            bottom: 40,
            right: 10,
            zIndex: 1021,
          }}
        >
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setSection(3)}
          >
            Continue
          </Button>
        </Box>
      )}

      { (!hideNav && section < 4) && footer() }
      { loading && <SpinningLoader /> }

      { !hideNav && infoModalOpen && (
        <InfoModal
          open={infoModalOpen}
          setOpen={setInfoModalOpen}
          setStep={setSection}
          ads={ads}
        />
      )}

      {/* 12/01/2021 - We want to remove popups from services */}
      {/* { !hideNav && (
        <PopupManager
          acreageReporting
          droneAnalysis
          soilTesting
        />
      )} */}
    </Box>
  );
}

Digs.propTypes = {
  setHomeSection: PropTypes.func,
  hideNav: PropTypes.bool,
};

Digs.defaultProps = {
  setHomeSection: undefined,
  hideNav: false,
};

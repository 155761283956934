import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Divider, TextField,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ImageModal } from '../../Shared/ImageModal';
import { dollarFormat } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  fieldInfo: {
    borderRadius: '10px',
    border: `1px solid ${theme.palette.greys.dark}`,
    margin: 'auto',
    padding: '10px',
    marginBottom: '10px',
    fontWeight: 500,
  },
  icons: {
    color: theme.palette.primary.main,
    border: `solid ${theme.palette.primary.main} 2px`,
    borderRadius: '360px',
    fontSize: 24,
    padding: '2px',
    marginLeft: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editNameIcon: {
    color: theme.palette.primary.main,
    fontSize: 18,
    marginLeft: 2,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dot: {
    fontSize: 10,
    color: theme.palette.greys.dark,
    margin: '0 4px',
  },
  image: {
    marginTop: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  input: {
    height: 40,
  },
  textInput: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  infoToolTip: theme.infoToolTip,
  reportCost: {
    color: theme.palette.primary.main,
    paddingLeft: 8,
  },
}));

/**
 * Displays items in user's order. Will be used in order cart. Optionally, if
 * delete and edit fields are not an option they can be left out of paramaters
 * and component will not render those icon options.
 * @param {Object} fieldOrderInfo Info for field to be displayed
 * @param {Array} fieldImages Images of fields
 * @param {Function} deleteField Removes field from order
 * @param {Function} editField Removes field from order and allows user to edit
 * @param {Function} editFieldName Update field name in cart and if selected
 * @returns {JSX} Item in order
 */
export const OrderItem = ({
  fieldOrderInfo,
  fieldImages,
  deleteField,
  editField,
  editFieldName,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const [updateName, setUpdateName] = useState(false);
  const [updatedFieldName, setUpdatedFieldName] = useState(fieldOrderInfo.name);

  const completeNameEdit = () => {
    editFieldName(fieldOrderInfo.id, updatedFieldName);
    setUpdateName(false);
  };

  return (
    <Box className={classes.fieldInfo}>
      <Box display="flex" alignItems="center" height={40}>
        { deleteField && (
          <DeleteIcon
            className={classes.icons}
            onClick={() => deleteField(fieldOrderInfo.id)}
          />
        )}

        { editField && (
          <EditOutlinedIcon
            className={classes.icons}
            onClick={() => editField(fieldOrderInfo)}
          />
        )}

        <Box p={1} minWidth="240px">
          { !updateName ? (
            <Typography style={{ fontWeight: 500, fontSize: '1.2rem' }}>
              {`${fieldOrderInfo.name}`}
              { editFieldName && (
                <EditOutlinedIcon
                  className={classes.editNameIcon}
                  onClick={() => setUpdateName(true)}
                />
              )}
            </Typography>
          ) : (
            <Box display="flex">
              <TextField
                value={updatedFieldName}
                onChange={(e) => setUpdatedFieldName(e.target.value)}
                InputProps={{
                  className: classes.textInput,
                }}
                onBlur={() => completeNameEdit()}
              />
              <CheckCircleIcon
                className={classes.editNameIcon}
                onClick={() => completeNameEdit()}
              />
            </Box>
          )}
        </Box>

        <Typography style={{ marginLeft: 'auto', fontWeight: 500 }}>
          {dollarFormat(fieldOrderInfo.subTotal)}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex">
        <Box className={classes.image} onClick={() => setOpen(true)}>
          <img
            style={{ height: 140, borderRadius: 4 }}
            src={fieldImages[fieldOrderInfo.id]}
            alt="field"
          />
        </Box>

        <Box pl={1}>
          { fieldOrderInfo.addedReports.map((report) => (
            <Box key={report.name} display="flex" alignItems="center">
              <FiberManualRecordIcon className={classes.dot} />
              <Box display="flex" justifyContent="space-between" flexGrow={1}>
                <Typography>{ report.name }</Typography>
                <Box width="82px" textAlign="center">
                  { report.price > 0 ? (
                    <Typography className={classes.reportCost}>
                      {`${dollarFormat(report.price)}/Acre`}
                    </Typography>
                  ) : (
                    <Typography className={classes.reportCost}>
                      FREE
                    </Typography>
                  )}
                </Box>

              </Box>

            </Box>
          ))}

        </Box>
      </Box>

      <ImageModal
        open={open}
        setOpen={setOpen}
        image={fieldImages[fieldOrderInfo.id]}
      />

    </Box>
  );
};

OrderItem.propTypes = {
  fieldOrderInfo: PropTypes.object.isRequired,
  fieldImages: PropTypes.object,
  deleteField: PropTypes.func,
  editField: PropTypes.func,
  editFieldName: PropTypes.func,
};

OrderItem.defaultProps = {
  fieldImages: undefined,
  deleteField: undefined,
  editField: undefined,
  editFieldName: undefined,
};

import React, { useState, useEffect } from 'react';
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Modal,
  Button,
  Divider,
  TextField,
  LinearProgress
} from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blackText, darkText, green } from '../../../../../styles/colors'
import { PDFDownloadLink, Document, Page } from '@react-pdf/renderer'
import { TenantLandlordPDF } from './TenantLandlordPDF'

function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    width: 400,
    height: 560,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 5,
    right: 5,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  getPdf: {
    display: "flex",
    color: theme.palette.primary.main,
    fontWeight: 500,
    fontSize: 16,
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.dark,
    },
  },
}));

/**
 *
 * @param {bool} open boolean for modal display
 * @param {function} setOpen change boolean for modal
 */
export function CreatePDF({
  open,
  setOpen,
  mapLoadedOnViewProfitLayers,
  harvestOps,
  seedingOps,
  applicationOps,
  fieldpassOps,
  otherCostOps,
  otherRevOps,
  totalRevenue,
  totalCosts,
  totalProfit,
  landlordCost,
  landlordRev,
  landlordProfit,
  tenantCost,
  tenantRev,
  tenantProfit,
  netSeededAcres,
}) {

  const classes = useStyles();
  const modalStyle = getModalStyle();

  const [reportName, setReportName] = useState('LandlordTenantReport')
  const [notes, setNotes] = useState('')

  const [pdfReady, setPdfReady] = useState(false)
  const [pdfLoading, setPdfLoading] = useState(false)
  const [pdfCanDownload, setPdfCanDownload] = useState(false)

  useEffect(() => {
    if (pdfCanDownload) {
      download()
    }
  }, [pdfCanDownload])

  const download = async () => {
    try {
      await new Promise(r => setTimeout(r, 1000));
      const toClick = document.getElementById('download-pdf')
      toClick !== null && toClick.click()
      resetStates()
    } catch (err) {
      console.log(err)
    }
  }

  const resetStates = () => {
    setPdfCanDownload(false)
    setPdfReady(false)

    setOpen(false)
  }

  const handleChange = (event) => {
    setNotes(event.target.value);
  };

  const handleDowload = () => {
    setPdfCanDownload(true)
  }

  const pdfButton = () => {
    handleDowload()
    return (
      <Button
        variant="outlined"
        color='primary'
        size='large'
        id='download-pdf'
      >
        <GetAppIcon />
        {' Download PDF'}
      </Button>
    )
  }

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box
        className={classes.root}
        style={modalStyle}
        borderRadius="borderRadius"
        boxShadow={2}
        fontWeight={500}
        fontSize={16}
        color={blackText}
      >
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpen(false)}
        />

        <Box p={1}>
          Generate PDF
        </Box>

        <Divider style={{ width: 400, height: 1 }} />

        <Box
          m={1}
          display='flex'
        >
          <Box
            fontSize={16}
            color={darkText}
            style={{ margin: '8px 6px 0 4px' }}
          >
            Report Name:
          </Box>

          <Box>
            <TextField
              variant="outlined"
              value={reportName}
              onChange={(event) => setReportName(event.target.value)}
              style={{ width: 260, height: 28 }}
              InputLabelProps={{
                shrink: false,
              }}
              inputProps={{
                style: {
                  padding: 10,
                },
              }}
            />
          </Box>
        </Box>

        <Box p={1} display='flex' justifyContent='center'>
          <TextField
            id="pdf-notes"
            label="Add Notes"
            multiline
            variant='outlined'
            rows={18}
            value={notes}
            onChange={handleChange}
            style={{ width: 340 }}
          />
        </Box>

        <Box
          p={1}
          display='flex'
          justifyContent='center'
        >
          {
            pdfLoading ? (
              <Box mt={2}>
                <LinearProgress style={{ height: 6, width: 60 }} />
              </Box>
            ) :
              <PDFDownloadLink
                onLoadError={console.error}
                fileName={`${reportName}.pdf`}
                style={{ textDecoration: 'none', color: green }}
                document={
                  <TenantLandlordPDF
                    harvestOps={harvestOps}
                    seedingOps={seedingOps}
                    applicationOps={applicationOps}
                    fieldpassOps={fieldpassOps}
                    otherCostOps={otherCostOps}
                    otherRevOps={otherRevOps}
                    totalRevenue={totalRevenue}
                    totalCosts={totalCosts}
                    totalProfit={totalProfit}
                    landlordCost={landlordCost}
                    landlordRev={landlordRev}
                    landlordProfit={landlordProfit}
                    tenantCost={tenantCost}
                    tenantRev={tenantRev}
                    tenantProfit={tenantProfit}
                    reportName={reportName}
                    notes={notes}
                    netSeededAcres={netSeededAcres}
                  />
                }
              >
                {({ blob, url, loading, error }) => (
                  loading ?
                    <Box color={green}>Loading PDF...</Box>
                    :
                    <Button
                      color='primary'
                      variant='outlined'
                      size='large'
                      onClick={() => { setOpen(false) }}
                    >
                      Download PDF
                    </Button>
                )}
              </PDFDownloadLink>
          }
        </Box>

      </Box>
    </Modal>
  )
}

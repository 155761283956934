import { Endpoints } from "../constants/Endpoints";
import { getCircularReplacer } from './helpers'

export async function getNrcs() {
  /* Sample response:
      {Data: bool, DisplayMessage: bool, EmailId: string, Exception: null, IsSuccessful: bool, Message: null}
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETNRCS, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

export async function getCrp() {
  /* Sample response:
      {Data: bool, DisplayMessage: bool, EmailId: string, Exception: null, IsSuccessful: bool, Message: null}
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETCRP, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

export async function getCSP() {
  /* Sample response:
      {Data: bool, DisplayMessage: bool, EmailId: string, Exception: null, IsSuccessful: bool, Message: null}
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETCSP, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

export async function getOther() {
  /* Sample response:
      {Data: bool, DisplayMessage: bool, EmailId: string, Exception: null, IsSuccessful: bool, Message: null}
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETOTHER, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

export async function getWaterMgmt(fieldID){
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETWATERMANAGEMENT + "?fieldId=" + fieldID, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

export async function addWaterMgmt(request){
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.ADDWATERMGMT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify(request)
    });
    let jsonResponse = await response.json();
    let data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

export async function updateWaterMgmt(request){
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.UPDATEWATERMGMT, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      credentials: "include",
      body: JSON.stringify(request)
    });
    let jsonResponse = await response.json();
    let data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

export async function updateClientsForFarms(orgId, farmId, clientId){
  try{
    let res = await fetch(Endpoints.BASEURL + Endpoints.UPDATECLIENTSFORFARMS + "?OrganizationID=" + orgId + "&FarmID=" + farmId + "&ClientID=" + clientId, {
      method: "POST", 
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    })
    let jsonResponse = await res.json()
    return jsonResponse;
  }
  catch(err){
    console.log('error while updating clients for farms', err)
  }
}

export async function updateClientsForFields(orgId, fieldId, clientId){
  try{
    let res = await fetch(Endpoints.BASEURL + Endpoints.UPDATECLIENTSFORFIELDS + "?OrganizationID=" + orgId + "&FieldID=" + fieldId + "&ClientID=" + clientId, {
      method: "POST", 
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    })
    let jsonResponse = await res.json()
    return jsonResponse;
  }
  catch(err){
    console.log('error while updating clients for fields', err)
  }
}
import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import 'leaflet-easyprint';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import {
  drawFieldLayer,
  drawImage,
  getMinHeight
} from '../../Maps/MapFunctions/helpers';
import { exists } from '../../../utils/helpers';
import {Tiles} from '../../../constants/Tiles'

const fillColors = [
  "#c34016",
  "#f3750e",
  "#f4ae62",
  "#fff200",
  "#ccee5f",
  "#69dc27",
  "#97e8d1",
  "#13a28c",
  "#3053c9",
  "#5712da",
  "#e74daa",
  "#742a4d",
];


/**
 * Given a field boundary, image, and extent, draws field boundary and overlays
 * image in proper extent on field.
 * @param {Object} boundary Field boundary with polygon points
 * @param {String} mapImage pngb64 image to overlay on map
 * @param {Object} mapExtents Latitude and longitude extents for image display
 * @param {Boolean} showImagery Used to toggle on/off map image
 * @param {Number} height Map height in pixels
 * @param {Number} width  Map width in pixels
 */
export function SoilDataMap({
  boundary, mapGeo, showImagery, setSnapBounds, height, width, location
}) {
  const [map, setMap] = useState(null);
  const imageOverlay = useRef(null);
  const fieldLayer = L.featureGroup(null);
  const geoLayer = useRef(null)

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      drawFieldLayer(geoJson, fieldLayer, map);
    }
  }, [boundary, map]);

  useEffect(() => {
    if (map && location === '') {
      map.setMaxZoom(17);
    }
  }, [location, map]);

  useEffect(()=>{
    if (exists(mapGeo) && map !== null){
      if (showImagery) {
        try {
          geoLayer.current = L.geoJSON(mapGeo)
          let i = 0;
          geoLayer.current.eachLayer(function(layer){

            let color = fillColors[i++];
            layer.setStyle({
              fillOpacity: 0.75,
              fillColor: color,
            });

            // create popup contents
            let customPopup = `<span>${layer.feature.properties.Soil_Type}</span>`;

            // specify popup options
            let customOptions = {'className': 'popupCustom'}
            layer.bindPopup(customPopup, customOptions)

          })
          geoLayer.current.addTo(map)
        } catch (e) {
          console.log(`Problem converting soil data to GeoJson: ${e}`)
        }
      } else if (geoLayer.current !== null ){
        try {
          map.removeLayer(geoLayer.current)
        } catch (e) {
          console.log(e)
        }

      }
    }
  }, [map, mapGeo, showImagery])

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('soil-data-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current']
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }

  return (
    <Box
      id="soil-data-map"
      display='flex'
      flexGrow={1}
      borderRadius={4}
      minHeight={getMinHeight()}
    />
  );
}

/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-props-no-spreading */

// React
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// MUI and Custom Componetnts
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormHelperText,
  MenuItem,
  Modal,
  Select,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSnackbar } from 'notistack';
import * as turf from '@turf/turf';
import { ConfirmationPopup } from '../../Shared/ConfirmationPopup';

// Library imports

// Map
import { UpdateOverlappingMap } from '../../Maps/AcreageReporting/UpdateOverlapping';

// Helper functions
import { useWindowDimensions } from '../../../utils/dimensions';
import { reprojectCalcArea } from '../../../utils/reprojectCalcArea';
import { exists, numFormat } from '../../../utils/helpers';
import { createFeature } from '../helpers';

// Other
import {
  acresModifiedReasons,
  geospatialShapeModifiedReasonCodes,
} from '../presetData';

const useStyles = makeStyles((theme) => ({
  close: {
    ...theme.icon,
  },
  cluElements: {
    padding: '0px 8px',
  },
  display: {
    height: 42.6,
    width: 180,
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    backgroundColor: theme.palette.greys.light,
    borderRadius: 4,
    border: `1px solid ${theme.palette.greys.main}`,
    fontWeight: 400,
  },
  footer: theme.updateOperationFooter,
  head: {
    ...theme.updateOperationHeader,
    alignItems: 'center',
  },
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
  },
  selects: {
    height: 44.6,
    width: 180,
    maxWidth: 200,
    backgroundColor: '#ffffff',
  },
  select: {
    height: 44.6,
    margin: 4,
    width: 200,
  },
  texts: {
    margin: 4,
    // For some reason, these textfields, with width of 100%, do not respect allowed space (maximum width including padding) defined by parent component
    paddingRight: 10,
  },
}));

/**
 * Fix overlapping boundaries between CLUs.
 * Rendered in: AcreageReporting/Functionality/ReviewPage.js
 * @param {Object} cluFullBoundary CLU complete boundary
 * @param {Array} cluSection Section containing information about CLUs to compare
 * @param {Array} cluSectionIndex Index of current cluSection in CLUSummary
 * @param {Function} getIntersections Checks whether there are any intersections between passed boundaries
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Function} setOpen Sets if modal is open
 * @param {Function} updateCLU Updates CLU values with no sideeffects
 * @param {Function} updateCLUSummarySection Updates CLUSummary values
 * @return {JSX} CreateOperation
 */
export const FixOverlappingBoundaries = ({
  cluFullBoundary,
  cluSection,
  cluSectionIndex,
  getIntersections,
  open,
  setOpen,
  updateCLU,
  updateCLUSummarySection,
}) => {
  // -------------- VARIABLES (AND SOME REQUIRED FCNS)  -------------- //
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  // State management
  const subFieldsToCheck = cluSection[0].intersectingSubfields;
  const allKeys = Object.keys(subFieldsToCheck);
  const [firstCLUIndex, setFirstCLUIndex] = useState(allKeys[0]);
  const [secondCLUIndex, setSecondCLUIndex] = useState(subFieldsToCheck[allKeys[0]][0].index);

  // Get the new acreage of the intersection of the two given boundaries
  const getIntersectingAcres = (boundary1, boundary2) => {
    const intersections = getIntersections(boundary1, boundary2);
    const area = turf.convertArea(turf.area(turf.multiPolygon(intersections)), 'meters', 'acres');
    return area;
    // reprojectCalcArea(JSON.stringify(intersections), 'UTM', 'GeoJSON', 'WGS84', 'Acres');
    // reprojectCalcArea is not being used here as this area will not be reported.
    // So, assuming turf is faster, the turf method was chosen. This can easily be replaced however.
  };

  // Get initial values
  const fb = cluSection[firstCLUIndex].boundary;
  const sb = cluSection[secondCLUIndex].boundary;
  const [acres, setAcres] = useState(getIntersectingAcres(fb, sb));
  const [error, setError] = useState('');

  // To track boundary changes from map
  const [firstModifiedBoundary, setFirstModifiedBoundary] = useState(fb);
  const [secondModifiedBoundary, setSecondModifiedBoundary] = useState(sb);
  // Colors for map
  const firstAPPColor = '#1e88e5';
  const secondAPPColor = '#a7eb88';
  // To track which of the boundaries is being editted
  const [boundaryNo, setBoundaryNo] = useState(-1);
  const [whichBoundary, setWhichBoundary] = useState('');
  const [userHasClipped, setUserHasClipped] = useState('');
  // handleTwoLayers should be 'initial' if (boundaryNo === -1) else 'edit'
  const [handleTwoLayers, setHandleTwoLayers] = useState('initial');

  // Trying to Exit/Change Without Saving
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const changesOutstanding = useRef(false);

  // Depending on whether value exists, provide their state or the given defaultValue
  const decideState = (value, defaultVal, firstIdx, secondIdx, passedSection) => {
    const firstIndex = exists(firstIdx) ? firstIdx : firstCLUIndex;
    const secondIndex = exists(secondIdx) ? secondIdx : secondCLUIndex;
    const sectionToUse = passedSection || cluSection;
    const firstValue = sectionToUse[firstIndex][value];
    const secondValue = sectionToUse[secondIndex][value];

    const state = {
      first: exists(firstValue) ? firstValue : defaultVal,
      second: exists(secondValue) ? secondValue : defaultVal,
    };

    // Mark it as text for notifying the user of character limit
    if (value === 'geospatialShapeModifiedOtherReasonText' || value === 'reportedAcreageModifiedOtherReasonText') {
      state.type = 'text';
    }
    return state;
  };

  // For keeping track of reasons for modifying boundaries
  const [boundaryModified, setBoundaryModified] = useState(decideState('geospatialShapeModifiedIndicator', 'N'));
  const [boundaryModifiedCode, setBoundaryModifiedCode] = useState(decideState('geospatialShapeModifiedReasonCode', ''));
  const [boundaryModifiedNotes, setBoundaryModifiedNotes] = useState(decideState('geospatialShapeModifiedOtherReasonText', ''));
  const [acreageModified, setAcreageModified] = useState(decideState('reportedAcreageModifiedIndicator', 'N'));
  const [acreageModifiedCode, setAcreageModifiedCode] = useState(decideState('reportedAcreageModifiedReasonCode', ''));
  const [acreageModifiedNotes, setAcreageModifiedNotes] = useState(decideState('reportedAcreageModifiedOtherReasonText', ''));

  // To deal with continuing the process if user tries to save without having finished an edit
  const [callHandleSave, setCallHandleSave] = useState('');

  // -------------- USE EFFECTS -------------- //

  useEffect(() => {
    // console.log('cluSection :>> ', cluSection);
    // console.log('subFieldsToCheck :>> ', subFieldsToCheck);
    // console.log('allKeys :>> ', allKeys);
  }, []);

  // When user tries to edit one of the subfields, change this to show the reason boxes
  useEffect(() => {
    if (boundaryNo !== -1) {
      if (boundaryNo === 1) {
        setWhichBoundary('first');
      } else if (boundaryNo === 2) {
        setWhichBoundary('second');
      } else {
        handleError(`Something is wrong with ${boundaryNo}`);
      }
    }
  }, [boundaryNo]);

  useEffect(() => {
    if (callHandleSave !== '') {
      setCallHandleSave('');
      // Assuming here that 'Finish Edit' should definitely be clicked by now
      const summary = handleSave();
      // Continue what the user was trying to do
      if (summary) {
        if (callHandleSave === 'SaveAndContinue') {
          findNextIntersection(summary);
        } else if (callHandleSave === 'SaveAndExit') {
          setOpen(false);
        } else {
          handleError('Incorrect "callHandleSave" option: can only be "SaveAndContinue" or "SaveAndExit"');
        }
      }
    }
  }, [callHandleSave]);

  // -------------- MAIN FUNCTIONALITY -------------- //
  const handleError = (errorMessage) => {
    enqueueSnackbar(errorMessage, {
      preventDuplicate: true,
    });
    setError(errorMessage);
  };

  /**
   * Check, for a specific boundary, whether all its reasons have been entered
   * @param {String} whichToCheck boundary to check
   * @returns {Bool} true or false
   */
  const checkReasonsForBoundary = (whichToCheck) => {
    // console.log('whichToCheck :>> ', whichToCheck);
    if (acreageModified[whichToCheck] === 'Y') {
      if (acreageModifiedCode[whichToCheck] === '') {
        return false;
      }
      if (acreageModifiedCode[whichToCheck] === 'O' && acreageModifiedNotes[whichToCheck] === '') {
        return false;
      }
    }

    if (boundaryModified[whichToCheck] === 'Y') {
      if (boundaryModifiedCode[whichToCheck] === '') {
        return false;
      }
      if (boundaryModifiedCode[whichToCheck] === 'O' && boundaryModifiedNotes[whichToCheck] === '') {
        return false;
      }
    }

    return true;
  };

  /**
   * Check the relevant vars to make sure that all reason codes and text have been entered
   * before saving
   * @returns {Bool} true or false
   */
  const checkForReasons = () => {
    // console.log('acreageModified, boundaryModified :>> ', acreageModified, boundaryModified);
    let allSet = checkReasonsForBoundary(whichBoundary);
    if (userHasClipped !== '') {
      allSet = allSet && checkReasonsForBoundary(userHasClipped);
    }

    if (!allSet) {
      enqueueSnackbar('Please provide required explanations for all changes');
    } else if (userHasClipped !== '') {
      // Reset this var if all reasons have been entered.
      setUserHasClipped('');
    }

    return allSet;
  };

  const handleCLUChange = (firstIndex, secondIndex, which, passedSection) => {
    // console.log('firstIndex, secondIndex, which :>> ', firstIndex, secondIndex, which);
    // We want to make sure that all codes and text has been entered before changing subfields
    if (!checkForReasons()) { return; }
    if (changesOutstanding.current) {
      enqueueSnackbar('Please press "Save and Continue" so as to not lose your changes');
      return;
    }

    // Depending on which dropwdown was used, proceed appropriately
    if (which === 'first') {
      setFirstCLUIndex(firstIndex);
      handleCLUChange(firstIndex, subFieldsToCheck[firstIndex][0].index, 'second', passedSection);
    } else if (which === 'second') {
      setSecondCLUIndex(secondIndex);

      // Need to recalculate area
      const sectionToUse = passedSection || cluSection;
      const fb = sectionToUse[firstIndex].boundary;
      const sb = sectionToUse[secondIndex].boundary;
      setAcres(getIntersectingAcres(fb, sb));
      // Update boundary trackers
      setFirstModifiedBoundary(fb);
      setSecondModifiedBoundary(sb);

      // Also need to update the boundaryModified and acreageModified vars
      setBoundaryModified(decideState('geospatialShapeModifiedIndicator', 'N', firstIndex, secondIndex, sectionToUse));
      setBoundaryModifiedCode(decideState('geospatialShapeModifiedReasonCode', '', firstIndex, secondIndex, sectionToUse));
      setBoundaryModifiedNotes(decideState('geospatialShapeModifiedOtherReasonText', '', firstIndex, secondIndex, sectionToUse));
      setAcreageModified(decideState('reportedAcreageModifiedIndicator', 'N', firstIndex, secondIndex, sectionToUse));
      setAcreageModifiedCode(decideState('reportedAcreageModifiedReasonCode', '', firstIndex, secondIndex, sectionToUse));
      setAcreageModifiedNotes(decideState('reportedAcreageModifiedOtherReasonText', '', firstIndex, secondIndex, sectionToUse));
    } else {
      console.error('Something is wrong here.');
    }
  };

  // Updates reason codes or texts appropriately and marks that a change was made
  const handleUpdatesToReasons = (whichToUse, currentVar, setCurrentVar, value) => {
    if (currentVar.type === 'text' && value.length >= 80) {
      enqueueSnackbar('Please limit your explanation to 80 characters', { preventDuplicate: true });
    } else {
      setCurrentVar({ ...currentVar, [whichToUse]: value });
      changesOutstanding.current = true;
    }
  };

  // Show the boxes for entering a reason for modifying boundary and acres when needed (boundary has been modified)
  const showReasonBoxes = (passedWhichValue) => {
    const whichToUse = passedWhichValue || whichBoundary;

    // NOTE: These reason codes can maybe be defaulted to "Corrected Acres" to make it easier for user? Will still need to make them fill it though
    const acreageChanged = acreageModified[whichToUse];
    const acreageChangedCode = acreageModifiedCode[whichToUse];
    const acreageChangedNotes = acreageModifiedNotes[whichToUse];
    // console.log('acreageModifiedCode, acreageChangedCode :>> ', acreageModifiedCode, acreageChangedCode);
    // console.log('acreageModified, acreageChanged :>> ', acreageModified, acreageChanged);
    const boundaryChanged = boundaryModified[whichToUse];
    const boundaryChangedCode = boundaryModifiedCode[whichToUse];
    const boundaryChangedNotes = boundaryModifiedNotes[whichToUse];

    return (
      // Only show this if either the acreage or boundary has been modified
      (acreageChanged === 'Y' || boundaryChanged === 'Y') && (
        <>
          <Divider style={{ margin: '10px 0px' }} />
          <Box>
            <FiberManualRecordIcon style={{ color: whichToUse === 'first' ? firstAPPColor : secondAPPColor, marginRight: '8px' }} />
            {whichToUse === 'first' ? 'First' : 'Second'}
            {' '}
            Subfield Reasons
          </Box>

          {acreageChanged === 'Y' && (
            <>
              <Box className={classes.cluElements} mt={1}>
                <Box>
                  Acres Modified Reason *
                </Box>
                <FormControl error={acreageChangedCode === ''}>
                  <Select
                    id="acreage-modified-code"
                    className={classes.select}
                    variant="outlined"
                    value={acreageChangedCode}
                    onChange={(e) => handleUpdatesToReasons(whichToUse, acreageModifiedCode, setAcreageModifiedCode, e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>--Select--</em>
                    </MenuItem>
                    {
                      acresModifiedReasons.map((x) => (
                        <MenuItem
                          key={x.code}
                          value={x.code}
                        >
                          {x.display}
                        </MenuItem>
                      ))
                    }
                  </Select>
                  {acreageChangedCode === '' && (
                    // <FormHelperText style={{margin: "3px 14px 0px"}}>Please select a modification reason</FormHelperText>
                    <FormHelperText>Please select a modification reason</FormHelperText>
                  )}
                </FormControl>
              </Box>

              { acreageChangedCode === 'O'
                && (
                <Box className={classes.cluElements} mt={1}>
                  <Box>
                    Please provide notes on why this acreage was updated *
                  </Box>
                  <TextField
                    id="acreage-modified-notes"
                    className={classes.texts}
                    variant="outlined"
                    multiline
                    rowsMax={2}
                    value={acreageChangedNotes}
                    onChange={(e) => handleUpdatesToReasons(whichToUse, acreageModifiedNotes, setAcreageModifiedNotes, e.target.value)}
                    style={{ width: '100%' }}
                    inputProps={{ maxLength: 80 }}
                    error={acreageChangedNotes === ''}
                    helperText={acreageChangedNotes === '' && 'Please enter a modification reason'}
                  />
                </Box>
                )}
            </>
          )}

          {boundaryChanged === 'Y' && (
            <>
              <Box className={classes.cluElements} mt={1}>
                <Box>
                  Boundary Modified Reason *
                </Box>
                <FormControl error={boundaryChangedCode === ''}>
                  <Select
                    id="boundary-modified-code"
                    className={classes.select}
                    variant="outlined"
                    value={boundaryChangedCode}
                    onChange={(e) => handleUpdatesToReasons(whichToUse, boundaryModifiedCode, setBoundaryModifiedCode, e.target.value)}
                    displayEmpty
                  >
                    <MenuItem value="">
                      <em>--Select--</em>
                    </MenuItem>
                    {
                    geospatialShapeModifiedReasonCodes.map((x) => (
                      <MenuItem
                        key={x.code}
                        value={x.code}
                      >
                        {x.display}
                      </MenuItem>
                    ))
                  }
                  </Select>
                  {boundaryChangedCode === '' && (
                    // <FormHelperText style={{margin: "3px 14px 0px"}}>Please select a modification reason</FormHelperText>
                    <FormHelperText>Please select a modification reason</FormHelperText>
                  )}
                </FormControl>
              </Box>

              { boundaryChangedCode === 'O'
                && (
                <Box className={classes.cluElements} mt={1}>
                  <Box>
                    Please provide notes on why this boundary was updated *
                  </Box>
                  <TextField
                    id="boundary-modified-notes"
                    className={classes.texts}
                    variant="outlined"
                    multiline
                    rowsMax={8}
                    value={boundaryChangedNotes}
                    onChange={(e) => handleUpdatesToReasons(whichToUse, boundaryModifiedNotes, setBoundaryModifiedNotes, e.target.value)}
                    style={{ width: '100%' }}
                    inputProps={{ maxLength: 80 }}
                    error={boundaryChangedNotes === ''}
                    helperText={boundaryChangedNotes === '' && 'Please enter a modification reason'}
                  />
                </Box>
                )}
            </>
          )}
        </>
      )
    );
  };

  const cluSelection = () => (
    // minWidth is to avoid issue with y scrollbar staying on screen after changing a reason to Other then to not 'Other'
    // It also helps keep the size more consistent
    <Box px={1} mb="10px" height="748px" minWidth="260px" style={{ overflowY: 'auto' }}>
      <Box className={classes.cluElements} mt={1}>
        <Box>
          <FiberManualRecordIcon style={{ color: firstAPPColor, marginRight: '8px' }} />
          First Subfield
        </Box>
        <Select
          className={classes.selects}
          variant="outlined"
          value={firstCLUIndex}
          onChange={(e) => handleCLUChange(e.target.value, null, 'first')}
        >
          {allKeys.map((intersectIndex) => (
            <MenuItem key={intersectIndex} value={intersectIndex}>
              {cluSection[intersectIndex].subfieldNumber}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box className={classes.cluElements}>
        <Box>
          <FiberManualRecordIcon style={{ color: secondAPPColor, marginRight: '8px' }} />
          Second Subfield
        </Box>
        <Select
          className={classes.selects}
          variant="outlined"
          value={secondCLUIndex}
          onChange={(e) => handleCLUChange(firstCLUIndex, e.target.value, 'second')}
        >
          {subFieldsToCheck[firstCLUIndex].map((intersect) => (
            <MenuItem key={intersect.index} value={intersect.index}>
              {intersect.subfield}
            </MenuItem>
          ))}
        </Select>
      </Box>

      <Box className={classes.cluElements}>
        <Box>
          Intersecting Acres
        </Box>
        <Box className={classes.display}>
          { numFormat(acres) }
        </Box>
      </Box>

      {/* Only show the reason boxes when boundaryNo is set to 1 or 2 (not -1) */}
      { boundaryNo !== -1 && showReasonBoxes() }
      {/* Show the boxes for the other boundary when user has used the clip tool */}
      { userHasClipped !== '' && showReasonBoxes(userHasClipped) }

    </Box>
  );

  // NOTE: Would need something like boundaryHasBeenModified from EditMap.js if we want reason boxes to popup as soon as user clicks edit... (don't think we need to have this)
  // When boundary gets updated in map, update the appropriate boundary variables here, the relevant indicators, and the intersecting acres.
  const handleBoundaryUpdate = (modifying, newBoundary, passedwhichBoundary) => {
    try {
      let newAcres;
      let modifiedValue = 'Y';
      const whichBoundaryToUse = passedwhichBoundary || whichBoundary;
      const boundaryToUse = JSON.stringify(newBoundary);

      if (!modifying) {
        // Reverting back to original boundary
        modifiedValue = 'N';
        if (whichBoundaryToUse === 'first') {
          boundaryToUse = cluSection[firstCLUIndex].originalBoundary;
        } else if (whichBoundaryToUse === 'second') {
          boundaryToUse = cluSection[secondCLUIndex].originalBoundary;
        }
      }

      // Update the correct boundary info
      if (whichBoundaryToUse === 'first') {
        setFirstModifiedBoundary(boundaryToUse);
        newAcres = getIntersectingAcres(boundaryToUse, secondModifiedBoundary);
        setAcreageModified({ ...acreageModified, first: modifiedValue });
        setBoundaryModified({ ...boundaryModified, first: modifiedValue });
      } else if (whichBoundaryToUse === 'second') {
        setSecondModifiedBoundary(boundaryToUse);
        newAcres = getIntersectingAcres(boundaryToUse, firstModifiedBoundary);
        setAcreageModified({ ...acreageModified, second: modifiedValue });
        setBoundaryModified({ ...boundaryModified, second: modifiedValue });
      } else { console.error('Incorrect "which" option'); }
      
      // Notify user if overlaps were fixed for these two subfields
      if (newAcres < 0.01) {
        enqueueSnackbar('Overlap has been fixed for these two subfields.');
      } 

      // Update necessary variables
      setAcres(newAcres);
      changesOutstanding.current = true;
    } catch (e) {
      enqueueSnackbar('Your new boundary is invalid. This is likely caused by overlapping boundary points.');
      console.error(e);
      return true;
    }
  };

  /**
   * Get acres for necessary reprojection format
   * @param {Object} geometry Area to project acres for
   * @returns {Number} reprojected Acres
   */
  const getAcres = (geometry) => {
    try {
      const projection = reprojectCalcArea(geometry, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
      return projection.area;
    } catch (err) {
      console.error(err);
      return 0;
    }
  };

  // For saving purposes, get all CLU data that the user can change in this modal
  const getChangedCLUData = (which) => {
    // Get the appropriate boundary value
    let boundary;
    if (which === 'first') {
      boundary = firstModifiedBoundary;
    } else {
      boundary = secondModifiedBoundary;
    }

    try {
      const updates = [
        { boundary },
        { finalReportedAcreage: getAcres(boundary) },
        { reportedAcreageModifiedIndicator: acreageModified[which] },
        { geospatialShapeModifiedIndicator: boundaryModified[which] },
      ];

      if (acreageModified[which] === 'Y') {
        updates.push({ reportedAcreageModifiedReasonCode: acreageModifiedCode[which] });

        if (acreageModifiedCode[which] === 'O') {
          updates.push({ reportedAcreageModifiedOtherReasonText: acreageModifiedNotes[which] });
        }
      }

      if (boundaryModified[which] === 'Y') {
        updates.push({ geospatialShapeModifiedReasonCode: boundaryModifiedCode[which] });

        if (boundaryModifiedCode[which] === 'O') {
          updates.push({ geospatialShapeModifiedOtherReasonText: boundaryModifiedNotes[which] });
        }
      }
      return updates;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  // Will actually save the modified boundaries into their respective subfields.
  const handleSave = (from) => {
    // Check if user is in process of boundary edit.
    const editing = document.getElementById('finish-edit-boundary');

    if (editing) {
      // Click finish edit
      editing.click();
      // Have a small timeout so that the process after clicking 'Finish Edit' can happen
      window.setTimeout(() => { setCallHandleSave(from); }, 10);
      return;
    }
    // We want to make sure that all codes and text has been entered before saving
    if (!checkForReasons()) { return; }

    // Get the data to be saved
    const firstUpdates = getChangedCLUData('first');
    const secondUpdates = getChangedCLUData('second');
    // Store and pass mappings as updates for first one will be lost if not
    const mappings = updateCLU(firstUpdates, cluSection[firstCLUIndex].indexes);
    updateCLU(secondUpdates, cluSection[secondCLUIndex].indexes, mappings);
    // Also update above for CLUSummary as that will not be updated from above calls.
    let summary = updateCLUSummarySection(firstUpdates, firstCLUIndex);
    summary = updateCLUSummarySection(secondUpdates, secondCLUIndex, summary);
    // console.log('Done updating CLUs');

    changesOutstanding.current = false;
    return summary;
  };

  const saveAndExit = () => {
    const summary = handleSave('SaveAndExit');
    // If handleSave worked, can then just exit
    if (summary) { setOpen(false); }
  };

  /**
   * Called when user presses "Back" button in toolbar. Resets the toolbar to its initial state.
   * If skip is true, then this was called after handleSave, which already calls checkForReasons.
   * @param {Boolean} skip Used to decide whether to checkForReasons
   * @returns {void}
   */
  const resetToolbar = (skip) => {
    // We want to make sure that all codes and text has been entered before resetting
    if (!skip && !checkForReasons()) { return; }
    setBoundaryNo(-1);
    setHandleTwoLayers('initial');
  };

  // Find the next set of intersecting subfields
  // Can just call handleCLUChange after found indices and it should handle the rest
  // (pass it cluSection - from summary, to make sure it uses up-to-date boundaries for intersection)
  const findNextIntersection = (summary) => {
    // Find position of currently selected "second subfield" in subFieldsToCheck[firstCLUIndex]
    const idxOfSecondIndex = subFieldsToCheck[firstCLUIndex].findIndex((elem) => elem.index === secondCLUIndex);
    const nextIdxOfSecondIndex = idxOfSecondIndex + 1;

    // See if there is another intersection left with first subfield
    if (nextIdxOfSecondIndex < subFieldsToCheck[firstCLUIndex].length) {
      const nextSecondIndex = subFieldsToCheck[firstCLUIndex][nextIdxOfSecondIndex].index;
      handleCLUChange(firstCLUIndex, nextSecondIndex, 'second', summary[cluSectionIndex]);
    }
    // If there isn't, see if there is another first subfield to go through
    else {
      const idxOfFirstIndex = allKeys.findIndex((elem) => elem === firstCLUIndex);
      const nextIdxOfFirstIndex = idxOfFirstIndex + 1;
      if (nextIdxOfFirstIndex < allKeys.length) {
        const nextFirstIndex = allKeys[nextIdxOfFirstIndex];
        handleCLUChange(nextFirstIndex, null, 'first', summary[cluSectionIndex]);
      }
      // If there isn't, then notify user and exit modal
      else {
        setOpen(false);

        // NOTE: Here, we could instead rerun the intersections function to make sure that all have been resolved. And give the snackbar message based on this result?
        // // For now, will just go back to first pair of subfields (so, loop around)
        // handleCLUChange(allKeys[0], null, 'first', summary[cluSectionIndex]);
        // enqueueSnackbar('The last pair of intersections has been saved.');
      }
    }

    // When "Save and Continue" is pressed, reset these values to reset map toolbar state
    resetToolbar(true);
  };

  const footer = () => (
    <Box className={classes.footer}>
      <Button
        variant="outlined"
        color="primary"
        style={{ backgroundColor: '#ffffff' }}
        onClick={() => setOpenConfirmation(true)}
      >
        Exit
      </Button>

      <Button
        variant="outlined"
        color="primary"
        style={{ margin: '0 25px', backgroundColor: '#ffffff' }}
        onClick={() => saveAndExit()}
        disableElevation
      >
        Save and Exit
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          const summary = handleSave('SaveAndContinue');
          // If handleSave worked, can then just move on to the next subfield combination
          if (summary) { findNextIntersection(summary); }
        }}
        disableElevation
      >
        Save and Continue
      </Button>
    </Box>
  );

  // Try to save and exit but make sure to close confirmation modal in case saving fails
  const handleSaveAndExit = () => {
    setOpenConfirmation(false);
    saveAndExit();
  };

  // -------------- RETURN -------------- //

  return (
    <Modal
      open={open}
      onClose={() => setOpenConfirmation(true)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="Fix-Overlapping-Boundaries"
      aria-describedby="Fix-Overlapping-Boundaries"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
        height={height - 60}
        width={width - 20}
        maxWidth={1800}
        maxHeight={height - 20}
      >
        <Box className={classes.head}>
          <Box p={2}>
            Fix Overlapping Boundaries
          </Box>

          <HighlightOffIcon
            className={classes.close}
            onClick={() => setOpenConfirmation(true)}
          />
        </Box>

        <Box display="flex" flexWrap="wrap" flexGrow={1}>
          { cluSelection() }

          <Box display="flex" flexGrow={1} minWidth={500}>
            <UpdateOverlappingMap
              boundaryNo={boundaryNo}
              cluFullBoundary={cluFullBoundary}
              colorsToUse={[firstAPPColor, secondAPPColor]}
              createFeature={createFeature}
              firstSubfield={cluSection[firstCLUIndex]}
              getIntersectingAcres={getIntersectingAcres}
              getIntersections={getIntersections}
              handleBoundaryUpdate={handleBoundaryUpdate}
              handleTwoLayers={handleTwoLayers}
              resetToolbar={resetToolbar}
              secondSubfield={cluSection[secondCLUIndex]}
              setBoundaryNo={setBoundaryNo}
              setHandleTwoLayers={setHandleTwoLayers}
              setUserHasClipped={setUserHasClipped}
              userHasClipped={userHasClipped}
            />
          </Box>
        </Box>

        { footer() }

        <ConfirmationPopup
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          handleClose={setOpen}
          handleSave={handleSaveAndExit}
        />
      </Box>
    </Modal>
  );
};

FixOverlappingBoundaries.propTypes = {
  cluFullBoundary: PropTypes.string.isRequired,
  cluSection: PropTypes.arrayOf(
    PropTypes.shape(),
  ).isRequired,
  cluSectionIndex: PropTypes.number.isRequired,
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
  updateCLU: PropTypes.func.isRequired,
  updateCLUSummarySection: PropTypes.func.isRequired,
};

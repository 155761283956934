import React, {
  useState, useEffect, useContext, useRef,
} from 'react';

import {
  Box,
  Button,
  Typography,
  CircularProgress,
  Modal,
  Divider,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Grid,
  TextField,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Checkbox from '@material-ui/icons/CheckBox';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  green,
  blackText,
} from '../../../styles/colors';

import climate from "../../../images/climate.png";
import deere from "../../../images/deereconnected.png";

import * as df from '../../../utils/dataFetchers';
import { useWindowDimensions } from '../../../utils/dimensions';
import { sleep } from '../../../utils/helpers';
import { Endpoints } from "../../../constants/Endpoints";

import { Connect } from '../../Shared/Connect';
import { CustomToolTip } from '../../../utils/customComponents';
import { PaymentApp } from '../../ProfitLayers/Reports/PaymentApp';
import { UserContext } from '../../Context/UserContext';

import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  planBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  plan: {
    width: '400px',
    maxWidth: '400px',
    height: 'auto',
    flexGrow: 1,
  },
  planHeader: {
    backgroundColor: '#67b346',
    height: '200px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px 15px 0 0',
  },
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
  },
  appBarSelections: theme.appBarSelections,
  appBarSelectionsMobile: {
    ...theme.appBarSelections,
    margin: '0 10px',
    overflowX: 'auto',
  },
  mobileSteps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  paper: {
    position: 'absolute',
    width: '80vw',
    height: '85vh',
    backgroundColor: theme.palette.greys.light,
    overflow: 'auto',
  },
  operationBox: theme.centeredColumn,
  operations: {
    backgroundColor: theme.palette.greys.light,
    height: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
  },
  label: theme.label,
  greenClick: theme.plIcon,
  icon: theme.icon,
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  edit: {
    marginLeft: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  getPdf: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  select: {
    padding: '10px',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sync: {
    ...theme.greenHover,
    fontSize: 18,
    marginLeft: '5px',
  },
  partner: {
    height: '60px',
    width: 'auto',
    margin: 6,
  },
  partner2: {
    height: '80px',
    width: 'auto',
    margin: 18,
  },
  connect: theme.connect,
  mobileConnect: {
    ...theme.connect,
    fontSize: 16,
    padding: 6,
    margin: 6,
  },
  logIn: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.lightGrey,
  },
  arrows: theme.navigationArrows,
}));

function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    // height: '100%',
    // width: '100%',
  };
}

/**
 * Page for purchasing a subscription to ESRI add-in.
 * @returns {JSX} Esri JSX
 */
export function Esri() {
  const user = useContext(UserContext)[0];
  const classes = useStyles();
  const [modalStyle] = React.useState(getModalStyle);
  const [authenticated, setAuthenticated] = useState(false);
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);
  const [orgs, setOrgs] = useState([]);
  const [selectedOrg, setSelectedOrg] = useState('');
  const { enqueueSnackbar } = useSnackbar();
  const [showPayment, setShowPayment] = useState(false);
  const [field, setField] = useState(null);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [processing, setProcessing] = useState(false);
  const [selectedOrgId, setSelectedOrgId] = useState(-1);
  const [userToken, setUserToken] = useState('');
  const [newUser, setNewUser] = useState(false);
  const { height, width } = useWindowDimensions();
  const [termsChecked, setTermsChecked] = useState(false);
  const isAuthenticated = useRef(false);

  const [basePrice, setBasePrice] = useState(Endpoints.ESRIADDIN_PRICE);
  const [discount, setDiscount] = useState(0);
  const [referralcode, setReferralcode] = useState('');

  useEffect(() => {
    const url = document.URL.split('?');
    if (url.length > 1) {
      const extension = url[1];
      if (extension.includes('token')) {
        const token = extension.replace('token=', '');
        // console.log(token)
        setUserToken(token);
        // remove token from url so user can't grab it
        url.pop();
        window.history.replaceState({}, document.title, url[0]);
      } else if (extension.includes('SignUp')) {
        setNewUser(true);
      }
    }
  }, []);

  useEffect(() => {
    // console.log('user', user);
    if (user.isAuthenticated !== undefined || userToken !== '') {
      if ((user.isAuthenticated && !newUser) || userToken !== '') {
        setAuthenticated(true);
        isAuthenticated.current = true;
        getOrganizations();
        setLoginPromptOpen(false);
      } else if ((user.isAuthenticated && newUser)) {
        setAuthenticated(true);
        isAuthenticated.current = true;
        // needs to create default org and farm here.
        handleNewUser();
      } else {
        // This can happen when user's login times out?
        setLoginPromptOpen(true);
      }
    } else if (Object.keys(user).length === 0) {
      autoOpenLoginPrompt();
    }
  }, [user, userToken, newUser]);

  const autoOpenLoginPrompt = async () => {
    // give user data a chance to load
    await sleep(1000);
    if (Object.keys(user).length === 0 && isAuthenticated.current === false) {
      setLoginPromptOpen(true);
    }
  };

  useEffect(() => {
    // console.log("OPEN", loginPromptOpen);
  }, [loginPromptOpen]);

  const handleNewUser = async () => {
    const newID = await df.getNewOrgID('Organizations');
    await df.createNewOrganization(newID, 'Default', 'Ag-Analytics');

    // create default client and farm for org
    await createDefaultClient(newID);
    await createDefaultFarm(newID);
    setLoginPromptOpen(false);
    getOrganizations();
  };

  async function createDefaultClient(orgId) {
    const newId = await df.getNewGUID('Clients');
    await df.createClient(newId, 'Default-Client', orgId, 'Ag-Analytics');
  }

  async function createDefaultFarm(orgId) {
    const newId = await df.getNewGUID('Farms');
    await df.createNewFarm(newId, 'Default-Farm', orgId, 'Ag-Analytics');
  }

  const getOrganizations = async () => {
    try {
      const response = await df.fetchOrganizations(userToken);
      const purchasedOrgs = await df.alreadyPurchasedOrgs('esri_toolbox', userToken);

      for (let i = 0; i < response.length; i++) {
        for (let j = 0; j < purchasedOrgs.length; j++) {
          if (response[i].ID == purchasedOrgs[j].organizationId) {
            response[i].disabled = true;
          }
        }
      }

      // console.log("orgs", response, response[0].ID)
      setOrgs(response);

      const firstOrg = response.filter((org) => org.disabled === undefined);
      // console.log(firstOrg)
      if (firstOrg !== undefined && firstOrg.length > 0) {
        handleChange(firstOrg[0].ID);
      }
    } catch (err) {
      console.log('getOrganizations', err);
      enqueueSnackbar('Error getting Organizations!');
    }
  };

  const handleChange = (orgID) => {
    // console.log("handleChange");
    setSelectedOrg(orgID);
    const fieldObj = {
      orgId: orgID,
    };
    setShowPayment(true);
    setField(fieldObj);
    setSelectedOrgId(orgID);
  };

  const handlePayment = async (customerData, subscriptionData) => {
    // console.log("\n\n\n\n");
    // console.log(
    //   "customer data: ",
    //   customerData,
    //   " subscriptionData: ",
    //   subscriptionData
    // );
    setIsPaymentProcessing(true);
    setShowPayment(false);
    let customerInfo = '';
    try {
      fetch(Endpoints.BASEURL + Endpoints.API_CUSTOMER, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: `Bearer ${  userToken}`,
        },
        credentials: 'include',
        body: JSON.stringify(customerData),
      })
        .then((response) => {
          if (response.ok) {
            const jsonresult = response.json();
            return jsonresult;
          }
          throw new Error(response);
        })
        .then((response) => {
          const customer = JSON.stringify(response.data);
          const dontgotosubscritionpage = sessionStorage.getItem(
            'dontGoToSubscriptionList',
          );

          sessionStorage.setItem('customerInfo', customer);
          customerInfo = customer;

          subscriptionData.ApiKey = customerInfo.apiKey;

          fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION, {
            method: 'POST',
            headers:
              userToken === ''
                ? {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                }
                : {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${  userToken}`,
                },
            credentials: 'include',
            body: JSON.stringify(subscriptionData),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error(response);
            })
            .then((response) => {
              // console.log("response", response);
              if (response.isSuccessful === true) {
                setShowPayment(false);
                setIsPaymentProcessing(false);
                setIsSuccessful(true);
                setIsFail(false);
              } else {
                setShowPayment(false);
                setIsPaymentProcessing(false);
                setIsSuccessful(false);
                setIsFail(true);
              }
            })
            .then((response) => {
              const customer = JSON.stringify(response.data);
              const dontgotosubscritionpage = sessionStorage.getItem(
                'dontGoToSubscriptionList',
              );

              sessionStorage.setItem('customerInfo', customer);
              customerInfo = customer;

              subscriptionData.ApiKey = customerInfo.apiKey;

              fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION, {
                method: 'POST',
                headers: {
                  Accept: 'application/json',
                  'Content-Type': 'application/json',
                  Authorization: `Bearer ${  userToken}`,
                },
                credentials: 'include',
                body: JSON.stringify(subscriptionData),
              })
                .then((response) => {
                  if (response.ok) {
                    return response.json();
                  }
                  throw new Error(response);
                })
                .then((response) => {
                  // console.log("response", response);
                  if (response.isSuccessful === true) {
                    setShowPayment(false);
                    setIsPaymentProcessing(false);
                    setIsSuccessful(true);
                    setIsFail(false);
                  } else {
                    setShowPayment(false);
                    setIsPaymentProcessing(false);
                    setIsSuccessful(false);
                    setIsFail(true);
                  }
                })
                .catch((error) => {
                  console.log(error);
                });
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log('error', error);
        });
    } catch (err) {
      console.log('handlePaymentMethod', err);
    }
  };

  const handleReferralCode = async (value) => {
    setReferralcode(value);

    try {
      const res = await df.checkReferralCode(value);
      console.log(res);
      if (Object.keys(res).length > 0 && res.percentageDiscount !== undefined) {
        setDiscount(res.percentageDiscount);
      } else {
        setDiscount(0);
      }
    } catch (err) {
      console.log('error verifying disocunt code', err);
      setDiscount(0);
    }
  };

  const returnCheckoutDetails = (plan) => {
    if (plan === 'standard') {
      return (
        <Box id="checkout info" style={{ width: '100%' }}>
          <Typography variant="subtitle2">Plan Details</Typography>
          <List>
            <ListItem>
              <ListItemText>
                <Typography variant="inherit">
                  <span style={{ fontWeight: 'bold' }}>Plan Type: </span>
                  Standard
                </Typography>
              </ListItemText>
            </ListItem>
            <ListItem>
              <ListItemText>
                <Typography variant="inherit">
                  <span style={{ fontWeight: 'bold' }}>Plan Cost: </span>
                  $
                  {basePrice}
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography variant="inherit">
                  <span className={classes.planLabel} style={{ fontWeight: 'bold' }} style={{ fontWeight: 'bold' }}>Discount Applied (1st Year): </span>
                  <span className={classes.planText}>
                    $
                    {basePrice * (discount / 100)}
                  </span>
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography variant="inherit">
                  <span className={classes.planLabel} style={{ fontWeight: 'bold' }}>Total (1st Year): </span>
                  <span className={classes.planText}>
                    $
                    {basePrice - (basePrice * (discount / 100))}
                  </span>
                  <CustomToolTip
                    title={(
                      <span>
                        <p>
                          Take
                          {' '}
                          {discount}
                          % off your first year of ESRI Addin.
                          {/* <sup>&reg;</sup>
                    {' '}
                    for users who have integrated with Climate FieldView.
                    <span style={{ fontWeight: 500 }}>Special pricing is automatically applied upon purchase.</span>
                    {' '}
                    Offer ends 2/28. */}
                        </p>
                        *First year is $
                        {basePrice - (basePrice * (discount / 100))}
                        , the subscription will renew automatically at $
                        {basePrice}
                        {' '}
                        per year.
                      </span>
                    )}
                    placement="right-start"
                  >
                    <InfoOutlinedIcon className={classes.infoToolTip} />
                  </CustomToolTip>
                </Typography>
              </ListItemText>
            </ListItem>

            <ListItem>
              <ListItemText>
                <Typography variant="inherit">
                  <span style={{ fontWeight: 'bold' }}>Renews: </span>
                  Annually
                </Typography>
              </ListItemText>
            </ListItem>
          </List>
          <Divider />

          <Typography variant="subtitle2">
            Select Organization to Link Subscription to
          </Typography>
          <br />

          <Box style={{ width: '100%' }}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative', display: 'flex' }}
            >
              <InputLabel id="demo-simple-select-outlined-label">
                Organization
              </InputLabel>
              <Select
                labelId="demo-simple-select-helper-label"
                id="demo-simple-select-helper"
                value={selectedOrg}
                onChange={(e) => handleChange(e.target.value)}
                label="Select Organization"
              >

                {
                  orgs.map((x, i) => (
                    <MenuItem
                      key={i}
                      style={{
                        fontWeight: 500,
                      }}
                      value={x.ID}
                      disabled={x.disabled === true}
                    >
                      {x.Name}
                      {' '}
                      (
                      {x.ID}
                      )
                    </MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Box>

          <Box style={{ width: '100%' }}>
            <FormControl
              variant="outlined"
              style={{ position: 'relative', display: 'flex' }}
            >
              <TextField value={referralcode} onChange={(event) => handleReferralCode(event.target.value)} label="Discount Code" />
            </FormControl>
          </Box>
          <Divider />

          <Box id="bt" style={{ marginTop: '10px' }}>
            {showPayment && (
              <PaymentApp
                authenticated={authenticated}
                source="esri"
                field={field}
                handlePayment={handlePayment}
                setProcessing={setProcessing}
                selectedOrgId={selectedOrgId}
                userToken={userToken}
                orgs={orgs}
                referralcode={referralcode}
              />
            )}
            {isPaymentProcessing && (
              <Box className={classes.head}>
                We are processing your payment, please wait...
                <CircularProgress
                  style={{
                    position: 'absolute',
                    right: '50%',
                    top: '50%',
                    zIndex: 1005,
                    color: '#1e88e5',
                  }}
                />
              </Box>
            )}
            {isSuccessful && !isFail && (
              <Box ml={1}>
                <Typography style={{ fontWeight: 500 }}>
                  Thank you for your purchase. You can close this window now
                </Typography>
              </Box>
            )}
            {!isSuccessful && isFail && (
              <Box ml={1}>
                <Typography style={{ fontWeight: 500 }}>
                  Couldn't process your payment, please contact us at
                  {' '}
                  <b>support@analytics.ag</b>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      );
    }
  };

  return (
    <Box
      id="containter"
      style={{ width: '80%', height: '80%', margin: 'auto' }}
    >
      <Grid container spacing={3} direction={width >= 1400 ? 'row' : 'column'}>
        <Grid item xs>
          <Box
            id="headerRow"
            style={{
              borderBottom: 'solid',
              borderWidth: 'thin',
              margin: '10px',
            }}
          >
            <Typography
              variant="h4"
              style={{ textAlign: 'center', marginTop: '25px' }}
            >
              Complete Your Purchase
            </Typography>
          </Box>

          <Box display="flex">
            <Box style={{ width: '75%', margin: 'auto' }}>
              <Typography
                style={{
                  borderStyle: 'solid',
                  borderRadius: '20px 20px 0px 0px',
                  borderColor: green,
                  backgroundColor: green,
                  color: '#ffffff',
                  padding: '15px',
                  textAlign: 'center',
                  fontSize: '20px',
                  marginTop: '10px',
                }}
              >
                <strong>Ag-Analytics Add-In for ESRI ArcGIS Pro</strong>
              </Typography>

              <Box
                display="flex"
                style={{
                  borderStyle: 'solid',
                  borderColor: green,
                  padding: '0px 15px 0px 15px',
                  borderBottom: 'none',
                }}
              >
                <List>
                  <Typography style={{ padding: '0px 15px 0px 15px' }}>
                    The Ag-Analytics Add-In for ArcGIS Pro allows users to
                    securely integrate their precision agriculture system data
                    (John Deere Operations Center, Climate FieldView, CNH) and
                    access key Ag-Analytics features within the ESRI ArcGIS Pro
                    platform.
                    {' '}
                    <br />
                  </Typography>
                  <ListItem>
                    <ListItemIcon>
                      <Checkbox style={{ fontSize: 'large', color: green }} />
                    </ListItemIcon>
                    <ListItemText>
                      Sign in and automatically download, view, and manage your
                      integrated precision ag data layers directly in ArcGIS Pro
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Checkbox style={{ fontSize: 'large', color: green }} />
                    </ListItemIcon>
                    <ListItemText>
                      Analyze your precision ag data and field profits in ArcGIS
                      Pro using the ProfitLayers custom profit mapping system
                    </ListItemText>
                  </ListItem>
                  <ListItem>
                    <ListItemIcon>
                      <Checkbox style={{ fontSize: 'large', color: green }} />
                    </ListItemIcon>
                    <ListItemText>
                      Access a suite of Ag-Analytics datasets and data
                      processing services, including satellite and soils data,
                      yield predictions, file converters, and more
                    </ListItemText>
                  </ListItem>
                </List>
              </Box>
              <Divider style={{ backgroundColor: green }} />
              <Box
                display="flex"
                style={{
                  borderStyle: 'solid',
                  padding: '10px 15px 15px 15px',
                  borderRadius: '0px 0px 20px 20px',
                  borderColor: green,
                  borderTop: 'none',
                }}
              >
                <Typography>
                  The Ag-Analytics Add-In is accessed through the desktop client
                  of ArcGIS Pro. Users will install the Ag-Analytics Add-In
                  through the ArcGIS Add-In Manager.
                </Typography>
              </Box>
            </Box>
          </Box>
        </Grid>

        <Grid item style={{ width: '25%', margin: 'auto', minWidth: '300px' }}>
          <Box id="checkout" flex="1">
            <Box
              style={{
                padding: '10px',
                border: 'solid',
                borderWidth: '2pt',
                borderColor: green,
                borderRadius: '20px',
                marginTop: '35px',
              }}
            >
              <Typography
                variant="h6"
                style={{ textAlign: 'center', margin: '10px' }}
              >
                Checkout
              </Typography>
              <Divider />
              {returnCheckoutDetails('standard')}
            </Box>
          </Box>
        </Grid>
      </Grid>

      <Connect
        open={loginPromptOpen}
        setOpen={setLoginPromptOpen}
        message={"Sign in and Connect Precision Ag"}
        shouldForceLogIn
        signInFrom={"SIGNINESRI"}
      />
    </Box>
  );
}

// -------------------- IMPORTS --------------------
// React
import React, { useState, useRef, useEffect } from 'react';
import { PropTypes } from 'prop-types';

// MUI
import {
  Box, Button, Typography, Divider, TextField, Tooltip,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import ArrowDownwardIcon from '@material-ui/icons/ArrowDownward';
import LinkIcon from '@material-ui/icons/Link';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MailIcon from '@material-ui/icons/Mail';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import { grey2 } from '../../../styles/colors';

// Custom Components
import { ToolTip } from '../../../utils/customComponents';

// Functionality
import { sendSharingInvite } from '../../../utils/dataFetchers';
import { useWindowDimensions } from '../../../utils/dimensions';
import { Endpoints } from '../../../constants/Endpoints';
import { checkIsMobile } from '../../../utils/helpers';

// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  autocomplete: {
    width: 300,
    margin: 6,
  },
  display: {
    padding: 8,
    margin: 8,
    width: 350,
    maxHeight: 144.8,
    border: `2px solid ${theme.palette.greys.main}`,
    borderRadius: 8,
  },
  emailDisplay: {
    maxHeight: 248,
    width: 350,
    padding: 8,
    margin: '8px 0px',
    border: `2px solid ${theme.palette.greys.main}`,
    borderRadius: 8,
    overflowY: 'auto',
  },
  header: {
    fontWeight: 600,
    fontSize: '3.2rem',
  },
  label: {
    fontSize: '1.3rem',
    fontWeight: 500,
  },
  linkPlaceholder: {
    padding: 8,
    minWidth: 320,
    minHeight: 60,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${grey2}`,
    borderRadius: 4,
    fontColor: theme.palette.greys.dark,
    fontSize: '1.1rem',
  },
  portalEntry: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '8px',
    padding: '8px',
    maxWidth: '690px',
  },
  root: {
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    padding: '0 28px 16px',
  },
  selects: {
    margin: 6,
    height: 44.6,
    minWidth: 226,
    width: 300,
    maxWidth: 400,
  },
}));

// -------------------- MAIN FUNCTIONALITY --------------------
/**
 * Rendered in AcreageReporting/Agents/AgentPortalModal.js
 * Rendered in AcreageReporting/Onboarding/Onboarding.js
 * Agents will handle inviting growers, getting invite links, and selecting user
 * to impersonate from here.
 * @param {String} currentUser Current user email
 * @param {String} impersonating User whose data Agent is using
 * @param {Bool} inModal Initiate different formatting for modal display
 * @param {Function} loadSharedUsers Get Growers who have shared data
 * @param {Bool|String} open Whether or not the modal containing this is open (and maybe what opened it)
 * @param {Function} setImpersonating Set user who's data Agent is using
 * @param {Function} setOpen Determine modal open state
 * @param {Array} sharedUsers Growers who have shared data with current user
 * @returns {JSX} Agent Portal
 */
export const AgentPortal = ({
  currentUser,
  impersonating,
  inModal,
  loadSharedUsers,
  open,
  setImpersonating,
  setOpen,
  sharedUsers,
}) => {
  // -------------------- VARIABLES --------------------
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { height, width } = useWindowDimensions();
  const [mobileDisplay, setMobileDisplay] = useState(false);
  const inviteRef = useRef(null);

  // Email to invite
  const [invite, setInvite] = useState('');

  // Generated invite link
  const [inviteLink, setInviteLink] = useState(null);

  // User's who have accepted invite to share data
  const [acceptedUsers, setAcceptedUsers] = useState([]);

  // -------------------- USE EFFECTS --------------------
  useEffect(() => {
    // Used for tooltips and eventually row or column for selection boxes
    setMobileDisplay(checkIsMobile());
  }, []);
  
  useEffect(() => {
    if (sharedUsers?.length) {
      setAcceptedUsers(
        sharedUsers.filter((user) => user.accepted).map((user) => user.owner_email_address),
      );
    }
  }, [sharedUsers]);

  const sendInviteEmail = async () => {
    // validate input email then send invite
    const brkn = invite.split('@');
    if (brkn.length !== 2) {
      enqueueSnackbar('Please enter a valid email address');
      return;
    }

    const result = await sendSharingInvite(invite);
    if (result !== undefined && result.status === 'Success') {
    enqueueSnackbar(`An invitation has been sent to ${invite}`);
      setInvite('');
      loadSharedUsers();
    }
  };

  const generateInviteLink = async () => {
    const link = `${Endpoints.HOME}/app/integrate?dataShare=${currentUser}`;
    setInviteLink(link);
  };

  const copyToClipboard = () => {
    inviteRef.current.select();
    document.execCommand('copy');
    inviteRef.current.setSelectionRange(0, 0);
  };

  const growerDisplay = (grower) => (
    <Box key={grower.owner_email_address} m={1}>
      <Box>
        <Divider />
        <Box m={1} display="flex" alignItems="center" bgcolor="white">
          {grower.accepted
            ? (
              <Tooltip placement="left" title="User Has Accepted Invitation">
                <MailIcon color="primary" />
              </Tooltip>
            )
            : (
              <Tooltip placement="left" title="User Has Not Accepted Invitation">
                <MailOutlineIcon color="primary" />
              </Tooltip>
            )}
          <Typography style={{ fontSize: '1.2rem', marginLeft: '4px' }}>
            {grower.owner_email_address}
          </Typography>
        </Box>
        <Divider />
      </Box>
    </Box>
  );

  const invitePlaceholder = (
    <Box className={classes.linkPlaceholder}>
      Get your invite link
      { width > 603 ? (
        <ArrowRightAltIcon color="primary" style={{ marginLeft: 4 }} />
      ) : (
        <ArrowDownwardIcon color="primary" style={{ marginLeft: 4 }} />
      )}
    </Box>
  );

  const linkIcon = (
    <Tooltip placement="left" title="Copy Link To Clipboard">
      <LinkIcon onClick={copyToClipboard} color="secondary" style={{ cursor: 'pointer', marginRight: 8 }} />
    </Tooltip>
  );

  const inviteLinkDisplay = (
    <Box display="flex" alignItems="center">
      <TextField
        inputRef={inviteRef}
        variant="outlined"
        value={inviteLink}
        // disabled
        style={{
          width: 320,
          minHeight: 60,
          padding: 8,
        }}
        InputProps={{
          startAdornment: linkIcon,
        }}
      />
    </Box>
  );

  const selectUserToImpersonate = () => (
    <Box p={1}>
      <Typography id="grower-selection" className={classes.label}>
        Select a grower to complete a report for
      </Typography>
      <Autocomplete
        className={classes.autocomplete}
        options={acceptedUsers}
        value={impersonating}
        getOptionLabel={(option) => option}
        renderInput={(params) => (
          <TextField
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...params}
            variant="outlined"
            placeholder="Select Grower"
          />
        )}
        onChange={(event, value) => {
          setImpersonating(value || '');
          if (open === 'uploadCLU') {
            let message = 'You can now associate uploaded CLU boundaries with your grower\'s account.';
            if (!value) message = 'You can now associate uploaded CLU boundaries with your account.';
            enqueueSnackbar(message);
            setOpen(false);
          }
        }}
      />
    </Box>
  );

  // -------------------- RETURN --------------------
  return (
    <Box
      className={classes.root}
      style={inModal ? { maxHeight: height - 40, overflowY: 'auto' } : {}}
    >
      {/* Modal Title */}
      <Box mt={inModal ? '24px' : 0}>
        <Typography align="center" className={classes.header}>
          Agent Portal
        </Typography>
      </Box>

      {/* Modal Description */}
      <Box p={1} margin="8px 0px 16px">
        <Typography className={classes.label} component="div">
          Invite a grower to have them integrate and share their precision data or choose from the growers who have accepted your invite.
          <ToolTip
            text={
              <Typography style={{ fontSize: '18px' }}>Once you select a grower to complete a report for, you will have access to their precision data and you can then also upload CLU data to associate with their specific account.</Typography>
            }
            mobileDisplay={mobileDisplay}
            iconStyles={{ fontSize: '18px', margin: '0 0 10px 4px' }}
          >
          </ToolTip>
        </Typography>
      </Box>

      {/* First row: User can see invited growers and pick who to complete a report for */}
      {sharedUsers && sharedUsers?.length > 0 ? (
        <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems={acceptedUsers.length > 0 ? "" : "center"}>
          <Box p={1}>
            <Typography className={classes.label}>
              Growers you have invited
            </Typography>
            <Box className={classes.emailDisplay} >
              {sharedUsers.map((grower) => growerDisplay(grower))}
            </Box>
          </Box>

          {acceptedUsers.length > 0 ? 
            selectUserToImpersonate()
          :
            <Typography className={`${classes.label} ${classes.display}`}>
              You cannot complete a report for any growers currently. Please invite a grower and wait until they have accepted your invitation.
            </Typography>
          }
        </Box>
      ) : (
        <Typography className={classes.label} style={{ padding: "8px 0px 16px" }}>
          Begin connecting with your growers by sending an email invite
          or generating a link to share
        </Typography>
      )}

      {/* Second row: user can invite a grower by email */}
      <Box className={classes.portalEntry}>
        <Typography className={classes.label}>
          Would you like to invite a grower to share their precision data with you by sending an email?
        </Typography>

        <Box p={1} display="flex" alignItems="flex-end" flexWrap="wrap" justifyContent="space-between" maxWidth={520}>
          <TextField
            variant="outlined"
            label="Enter email"
            onChange={(e) => setInvite(e.target.value)}
            style={{ width: 320 }}
          />
          <Box m={1}>
            <Button
              color="primary"
              variant="contained"
              onClick={sendInviteEmail}
              size="large"
            >
              Invite Grower
            </Button>
          </Box>
        </Box>
      </Box>

      {/* Third row: user can generate a link to send to user as an invitation */}
      <Box className={classes.portalEntry}>
        <Typography className={classes.label}>
          Would you like to generate a link to distribute to users allowing them to
          easily share their data with you?
        </Typography>
        <Box p={1} display="flex" alignItems="flex-end" flexWrap="wrap" justifyContent="space-between" maxWidth={568}>

          {inviteLink ? inviteLinkDisplay : invitePlaceholder}

          <Box m={1}>
            <Button
              color="primary"
              variant="contained"
              onClick={generateInviteLink}
              size="large"
            >
              Generate Invite Link
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

AgentPortal.propTypes = {
  currentUser: PropTypes.string,
  impersonating: PropTypes.string.isRequired,
  inModal: PropTypes.bool,
  loadSharedUsers: PropTypes.func.isRequired,
  open: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  setImpersonating: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  sharedUsers: PropTypes.arrayOf(
    PropTypes.shape(
      {
        accepted: PropTypes.number.isRequired,
        owner_email_address: PropTypes.string.isRequired,
      },
    ),
  ),
};

AgentPortal.defaultProps = {
  inModal: undefined,
  currentUser: undefined,
  sharedUsers: null,
};

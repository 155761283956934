import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Container, Checkbox, Button } from "@material-ui/core";
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import weather from '../../../images/ExtremeWeather.png'
import { blackText, darkText, darkGrey, green } from '../../../styles/colors'
import { Endpoints } from '../../../constants/Endpoints'
import { terms } from '../../Partners/Terms'
import { IntegrationLinks } from '../../Partners/Integrate'

const useStyles = makeStyles((theme) => ({
  checkbox: {
    fontSize: 38,
    color: green,
  },
  hover: {
    fontColor: blackText,
    '&:hover': {
        cursor: 'pointer',
        color: darkText,
      },
  },
  tac: {
    margin: 10,
    padding: 16,
    overflowY: 'auto',
    backgroundColor: 'white',
    color: theme.palette.text.dark
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 8,
    right: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export function Subscribe({setOpen}) {
  const classes = useStyles();

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [alert, setAlert] = useState(false);

  const check = () => {
    setAlert(false)
    setTermsAccepted(!termsAccepted);
  }

  const subscribe = () => {
    if (termsAccepted) {
      setAlert(false);
      window.open(`${Endpoints.BASEURL}/Deere/linkMyJohnDeereV2/AgAnalyticsPaymentProcessing`, '_blank')
    } else {
      setAlert(true);
    }
  }

  return (
    <Box>
      <HighlightOffIcon
        className={classes.close}
        onClick={() => setOpen(false)}
      />
      <Box mt={1} p={1} fontWeight={500} fontSize={18} color={blackText}>
        <Box display='flex' justifyContent='center' fontSize={20}>
          Avoid being caught off guard
        </Box>

        <Box display='flex' justifyContent='center'>
          Subscribe now for FREE to Extreme Weather Alerts.
        </Box>
      </Box>

      <Box>
        <Box display='flex' justifyContent="center">
          <Button
            variant='contained'
            color='primary'
            size='large'
            disableElevation
            onClick={() => subscribe()}
          >
            Subscribe
          </Button>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          fontWeight={500}
          fontSize={18}
          id='checkbox'
        >
          <Checkbox
            checked={termsAccepted}
            icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
            checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
            onChange={() => check()}
          />
          <Box className={classes.hover} onClick={() => check()}>
            I have read and agree to the Terms of Use & Privacy Policy
          </Box>
        </Box>

        <Box
          className={classes.tac}
          id='terms-of-use'
          border={2}
          borderColor={alert ? "red" : darkGrey}
          borderRadius='borderRadius'
          style={{height: 200}}
        >
          {terms()}
        </Box>

      </Box>

      <Box p={1} fontSize={12}>
        * Extreme Weather Alerts are for agricultural and informational purposes and are not intended to be used to obtain information during and emergency or severe weather events.
      </Box>

      <Box display="flex" justifyContent='center'>
        <img src={weather} style={{maxHeight: 300, width: 'auto'}} alt='extreme_weather'/>
      </Box>
    </Box>
  )
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../../constants";

const useStyles = makeStyles(() => ({
  marker: {
    position: "absolute",
    color: COLORS.gray,
    width: 3,
    height: "100%",
    fontSize: 12,
    fontWeight: "bold",
    lineHeight: 1.1,
    backgroundColor: COLORS.gray,
    "&.farm": {
      color: COLORS.green,
      backgroundColor: COLORS.green,
    },
    display: "flex",
    alignItems: "center",
    "&.last": {
      marginRight: 9,
    },
  },
  circle: {
    position: "absolute",
    width: 7,
    height: 7,
    borderRadius: "50%",
    top: "calc(50% - 3.5px)",
    left: -3.5,
    backgroundColor: "inherit",
  },
  markerInner: {
    position: "relative",
    height: "100%",
    width: 0,
    marginLeft: 1.5,
    backgroundColor: "inherit",
  },
  value: {
    position: "absolute",
    // whiteSpace: 'nowrap',
    "&.last": {
      paddingLeft: 9,
    },
    "&.first": {
      right: 0,
      paddingRight: 9,
      textAlign: "right",
    },
  },
}));

/**
 * Renders the market report overview table marker on row
 * @param {number} position currently a randomly generated number. Ultimately, this will come from your value
 * (When you know the formats of your value data you can write functions to generate positions)
 * @param {string} value label for marker
 * @param {boolean} isFarm styles your farm green
 * @param {boolean} isLarger styles the labels accordingly
 */

const GeographyMarker = ({
  position,
  value,
  isFarm = false,
  isLarger = false,
}) => {
  const classes = useStyles();

  return (
    <div
      className={`${classes.marker} ${isFarm ? "farm" : ""}`}
      style={{ left: position }}
    >
      <div className={classes.markerInner}>
        <span className={classes.circle} />
      </div>
      <span className={`${classes.value} ${isLarger ? "last" : "first"}`}>
        {value}
      </span>
    </div>
  );
};

export default GeographyMarker;

GeographyMarker.propTypes = {
  position: PropTypes.number.isRequired,
  value: PropTypes.string.isRequired,
  isFarm: PropTypes.bool,
  isLarger: PropTypes.bool,
};

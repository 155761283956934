import * as L from "leaflet";
import { exists } from "../../../utils/helpers";
import { getGeom } from './helpers'
import {
  getNaipImagery,
  getAvailableNaip,
  getStateAndCounty
} from '../../../utils/dataFetchers';

export const getAvailableNaipYears = async (boundary) => {
  let bounds = getGeom()

  let center = L.geoJSON(JSON.parse(boundary)).getBounds().getCenter()
  //get state based off center point
  const [state, county] = await getStateAndCounty(center.lat, center.lng)

  //use state to get available years
  if(!exists(state)){
    return null
  }

  let res = await getAvailableNaip(state)
  let years = JSON.parse(res)

  return years[0].Years
}


export const requestNaipImagery = async (year, boundary) =>{
  let selected = JSON.parse(boundary)

  let naipReq = {
    aoi: JSON.stringify(selected),
    years: JSON.stringify([year]),
    display_option: "True_color",
    result_type: "png",
    Projection: "EPSG:3857",
    Inflation_Rate:"0.1"
  }
  let res;

  try{
    res = await getNaipImagery(naipReq)
    let info = JSON.parse(res)
    return info
  }
  catch(err){
    console.log(err)
    return null
  }
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { kebabCase } from "lodash";
import CustomSelectWithAsync from "../../../../Helpers/CustomSelectWithAsync";

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexWrap: "wrap",
    flexDirection: "row",
    "& :first-child": {
      marginLeft: 0,
    },
  },
}));

/**
 * Handles the selects in the farm breakdown.
 * @param {array} state array of objects with info for each select {id: string, active: bool, disabled: bool}
 * @param {object} onChanges object containing function to change each select
 */

const SelectGroup = ({ state, onChanges }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      {Object.keys(state).map((key) => {
        const option = state[key];
        return option.values.length ? (
          <CustomSelectWithAsync
            key={`select-option-for-${kebabCase(option.label)}`}
            label={option.label}
            options={option.values}
            onChange={onChanges[key]}
          />
        ) : null;
      })}
    </div>
  );
};

export default SelectGroup;

SelectGroup.propTypes = {
  state: PropTypes.object.isRequired,
  onChanges: PropTypes.object.isRequired,
};

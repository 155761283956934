/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Link,
  Divider,
  Slide,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from '../../../styles/select';
import { blackText } from '../../../styles/colors';
import { PolarisMap } from './PolarisMap';
import { createPolarisTable } from './PolarisTable';
import { useWindowDimensions } from '../../../utils/dimensions';
import { exists } from '../../../utils/helpers';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { ImageSwitch } from '../Shared/ImageSwitch';
import { GetSoilTesting } from '../../Shared/ServiceLinks';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  messageBox: theme.messageBox,
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * Display Polaris map and data
 * @param {Object} field  Field to display data for
 * @param {Object} status Loading status
 * @param {Function} getPolaris Get Polaris data
 * @param {String} image  Image for map
 * @param {Array} legend Legend data for table
 * @param {Array} extent Extent of image for map
 * @param {Number} mobileBreakPoint Pixel width side navigation changes to dropdown
 * @param {String} errorMessage Message to display to user if do not have data
 * @param {Bool} precisionField If field is from precision source
 * @param {onConnectClick} onConnectClick Open integration modal
 * @returns {JSX} Polaris component
 */
export function Polaris({
  field,
  loading,
  getPolaris,
  image,
  legend,
  extent,
  average,
  mobileBreakPoint,
  errorMessage,
  precisionField,
  onConnectClick,
  underwriting, // everything below this prop is used by loanunderwriting to customize component render
  slideDirection,
  loaderPosition,
  countyAverage,
}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  const soilVariables = [
    { name: 'Soil pH in H2O', value: 'ph' },
    { name: 'Organic Matter %', value: 'om' },
    { name: 'Silt Percentage %', value: 'silt' },
    { name: 'Sand Percentage, %', value: 'sand' },
    { name: 'Clay Percentage, %', value: 'clay' },
    { name: 'Bulk Density, g/cm3', value: 'bd' },
    { name: 'Available Water Content, m3/m3', value: 'awc' },
    { name: 'Saturated Soil Water Content, m3/m3', value: 'theta_s' },
    { name: 'Residual Soil Water Content, m3/m3', value: 'theta_r' },
    { name: 'Soil Water Content at Field Capacity, m3/m3', value: 'theta_33' },
    { name: 'Soil Water Content at the Wilting Point, m3/m3', value: 'theta_1500' },
    { name: 'Saturated Hydraulic Conductivity, cm/hr', value: 'ksat' },
    { name: 'Depth to Restriction Layer, cm', value: 'resdt' },
    { name: 'Calcium Carbonate in Soil, %', value: 'caco3' },
    { name: 'Cation Exchange Capacity of Soil, meq/100g', value: 'cec' },
    { name: 'Pore Size Distribution Index (brooks-corey)', value: 'lambda' },
    { name: 'Bubbling Pressure (brooks-corey), cm', value: 'hb' },
    { name: 'Measure of the Pore Size Distribution (van genuchten)', value: 'n' },
    { name: 'Scale Parameter Inversely Proportional to Mean Pore Diameter (van genuchten), cm-1', value: 'alpha' },
  ];

  const statistics = [
    { name: 'Average', value: 'mean' },
    { name: 'Variance', value: 'var' },
  ];

  const soilDepth = [
    { name: '0-5 cm', value: '0-5' },
    { name: '5-15 cm', value: '5-15' },
    { name: '15-30 cm', value: '15-30' },
    { name: '30-60 cm', value: '30-60' },
    { name: '60-100 cm', value: '60-100' },
    { name: '100-200 cm', value: '100-200' },
  ];

  const [selectedVariable, setSelectedVariable] = useState(soilVariables[0]);
  const [selectedStat, setSelectedStat] = useState(statistics[0]);
  const [selectedDepth, setSelectedDepth] = useState(soilDepth[0]);
  const [showImagery, setShowImagery] = useState(true);

  const getData = async (variable, depth, stat) => {
    const success = await getPolaris(variable, depth, stat);
  };

  const handleChange = (val, type) => {
    if (type === 'depth') {
      const depth = { name: val.name, value: val.value };
      setSelectedDepth(depth);
      getData({variable: selectedVariable.value, depth: depth.value, stat: selectedStat.value});
    } else if (type === 'variable') {
      const variable = soilVariables.filter((x) => x.value === val.value)[0];
      setSelectedVariable(variable);
      getData({variable: variable.value, depth: selectedDepth.value, stat: selectedStat.value});
    } else {
      const stat = { name: val.name, value: val.value };
      setSelectedStat(stat);
      getData({variable: selectedVariable.value, depth: selectedDepth.value, stat: stat.value});
    }
  };

  const handleSwitch = (checked) => {
    setShowImagery(checked);
  };

  const header = () => (
    <Box mt={1} mx={1}>
      <Box fontSize={18}>
        POLARIS Soils Data
      </Box>

      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        Polaris is a recently developed soil data set that provides additional soil attributes, available
        {' '}
        <Link color="primary" href="https://pubs.er.usgs.gov/publication/70170912" target="_black" rel="noreferrer">here</Link>
        .
      </Typography>
      <Divider />
    </Box>
  );

  const controls = () => (
    <Box display="flex" flexWrap="wrap">
      <Box mx={1}>
        <Box>
          Statistic
        </Box>
        <Select
          className={classes.selections}
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedStat.value}
          onChange={(e) => handleChange(e.target, 'statistic')}
        >
          {
            statistics.map((x, i) => (
              <MenuItem
                key={i}
                value={x.value}
                name={x.name}
              >
                {x.name}
              </MenuItem>
            ))
          }
        </Select>
      </Box>

      <Box mx={1}>
        <Box>
          Soil Depth
        </Box>
        <Select
          className={classes.selections}
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedDepth.value}
          onChange={(e) => handleChange(e.target, 'depth')}
        >
          {
            soilDepth.map((x, i) => (
              <MenuItem
                key={i}
                value={x.value}
                name={x.name}
              >
                {x.name}
              </MenuItem>
            ))
          }
        </Select>
      </Box>
      <Box mx={1}>
        <Box>
          Variable
        </Box>
        <Select
          className={classes.selections}
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedVariable.value}
          onChange={(e) => handleChange(e.target, 'variable')}
        >
          {
            soilVariables.map((x, i) => (
              <MenuItem
                key={i}
                value={x.value}
                name={x.name}
              >
                {x.name}
              </MenuItem>
            ))
          }
        </Select>
      </Box>

      <ImageSwitch
        checked={showImagery}
        handleSwitch={handleSwitch}
      />
    </Box>
  );

  return (
    <Slide direction={slideDirection || 'right'} in>
      <Box color={blackText} fontWeight={500}>
        { header() }

        <Box
          mt={0.5}
          display="flex"
          flexDirection={width > 1080 ? 'row' : 'column'}
        >
          <Box display="flex" flexDirection="column" flexGrow={1}>
            { controls() }

            { errorMessage === '' ? (
              <Box
                p={0.5}
                display="flex"
                flexGrow={1}
                minWidth={underwriting ? 'auto' : width >= 1080 ? width - 800 : width > mobileBreakPoint ? width - 240 : width - 30}
                borderRadius={4}
              >
                <PolarisMap
                  boundary={field.boundary}
                  mapImage={image}
                  mapExtents={extent}
                  showImagery={showImagery}
                  height="100%"
                  width="100%"
                  location={field?.state}
                />
              </Box>
            ) : (
              <Box p={0.5} display="flex">
                <Box className={classes.errorMessageBox}>
                  {errorMessage}
                </Box>
              </Box>
            )}
          </Box>

          <Box
            mt={1}
            display="flex"
            flexDirection={(width < mobileBreakPoint || width > 1080) ? 'column' : 'row'}
            flexWrap="wrap"
            style={countyAverage !== undefined ? { minWidth: 380, margin: '0 auto' } : {}}
          >
            <Fade in={exists(legend) && !loading} mountOnEnter unmountOnExit>
              <Box p={0.5}>
                {createPolarisTable(field, average, legend, selectedVariable, countyAverage)}
              </Box>
            </Fade>

            { countyAverage === undefined && (
              <Box
                display="flex"
                flexWrap="wrap"
                flexDirection={(width < mobileBreakPoint || width > 1080) ? 'column' : 'row'}
              >
                <Box p={0.5} display="flex" justifyContent="center">
                  <GetSoilTesting />
                </Box>

                {/* { !precisionField && (
                    <Box display='flex' justifyContent='center'>
                      <Box p={.5}>
                        <IntegrationLinks onConnect={onConnectClick} />
                      </Box>
                    </Box>
                  )} */}
              </Box>
            )}

          </Box>
        </Box>

        {loading && <SpinningLoader top={loaderPosition} />}
      </Box>
    </Slide>
  );
}

Polaris.propTypes = {
  field: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  legend: PropTypes.array.isRequired,
  average: PropTypes.number.isRequired,
  mobileBreakPoint: PropTypes.number.isRequired,
  errorMessage: PropTypes.string.isRequired,
  precisionField: PropTypes.bool.isRequired,
  onConnectClick: PropTypes.func.isRequired,
};

import React from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Container, Box, Typography, Link, Divider } from '@material-ui/core/';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import { Endpoints } from '../../constants/Endpoints'
import logo from '../../images/Logo.png'

const useStyles = makeStyles(theme => ({
  icon: {
    margin: '10px 0',
    color: '#616161',
    '&:hover': {
       color: '#1565c0',
       cursor: 'pointer'
    },
  },
  iconBox: {
    position: 'sticky',
    top: 100,
    bottom: 20,
    height: '150px',
  },
  logo: {
    width: '60px',
    margin: '0 30px',
  }
}))


export const Lsu = () => {
  const classes = useStyles()

  return (
    <Container maxWidth='md'>
      <Box display='flex'>
        <Box>
          <Box mt={2}>
            <Box display='flex' flexWrap='wrap'>
              <Typography variant="h4">Ag-Analytics and LSU AgCenter Partnership Brings AI and Machine Learning to Yield Predictions</Typography>
            </Box>
          </Box>
          <Box mt={1} display="flex">
            <Typography style={{color: '#757575'}}>Ag-Analytics June, 2019</Typography>
          </Box>
          <Box my={1} display='flex'>
            <Typography variant='body1'>
              {/* <strong>ITHACA, N.Y., June 20, 2019 - </strong> */}
              Ag-Analytics is partnering with the LSU AgCenter
              to help farmers maintain and improve yields through the use of machine learning and big data.
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              Ag-Analytics, a cloud-based secure farm management platform, works with farmers, industry
              and universities with the goal of helping farmers make better management decisions and mitigate risk.
              The data partnership with the LSU AgCenter is a first of its kind with any university.
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              “The Ag-Analytics/LSU AgCenter partnership will allow farmers to opt-in to confidentially and securely share data
              with their land-grant university researchers in a seamless manner with protocols in place to protect privacy,”
              says Josh Woodard, CEO of Ag-Analytics and an associate professor of agribusiness at Cornell University.
              “This research and development will directly result in new tools and models that will allow us to better
              serve our farmers by leveraging Ag-Analytics and the university extension system.”
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              The system allows growers with John Deere Operations Center to sign up for free and easily link their agricultural equipment to the Ag-Analytics platform.
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              Thanos Gentimis, LSU AgCenter mathematician, plans to take the massive volume of data Ag-Analytics collects,
              apply machine learning techniques to it — teaching a computer program to recognize patterns in the data —
              and develop models that can predict yield.
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              “We can turn a tangled set of data into a valuable asset, but we can’t do this with data from just one field,” Gentimis said.
              “If we have a million acres, then we can train the model to make more accurate predictions and then apply it to a farmer’s field.”
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              Improving yield-predictor models also would allow farmers to account for volatile weather, fluctuations in temperatures or precipitation and other events that affect yields.
            </Typography>
          </Box>
          <Box my={1}>
            <Typography variant='body1'>
              Farmers can sign up with Ag-Analytics for free and opt-in to share data confidentially with the LSU AgCenter at
              <Link href={`${Endpoints.BASEURL}/LSUAgCenter`} target="_blank" color='secondary'> https://ag-analytics.org/LSUAgCenter.</Link>
              The information gathered on individual farms will remain confidential,
              and personally identifiable information will be stripped. Farmers who sign up can also get access to resources,
              such as a real-time feed with information about their fields, weather and insurance forecasts.
            </Typography>
          </Box>
        </Box>
        <Box className={classes.iconBox} m={3} mt={3} p={2} display='flex' flexDirection='column' justifyContent='center' alignItems='center' boxShadow={1} borderRadius={16}>
          <Link href={`https://twitter.com/share?url=${Endpoints.BASEURL}/press/ksu`} target="_blank"><TwitterIcon className={classes.icon} /></Link>
          <Link href={`https://www.facebook.com/sharer/sharer.php?u=${Endpoints.BASEURL}/press/ksu`} target="_blank"><FacebookIcon className={classes.icon}/></Link>
          <Link href={`https://www.linkedin.com/shareArticle?mini=true&url=${Endpoints.BASEURL}/press/ksu`} target="_blank"><LinkedInIcon  className={classes.icon}/></Link>
        </Box>
      </Box>
    </Container>
  )
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Switch, Menu, MenuItem, IconButton, Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Icons
import AddIcon from '@material-ui/icons/Add';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import TimelineIcon from '@material-ui/icons/Timeline';
import { useWindowDimensions } from '../../../utils/dimensions';

import { FieldSelectionInstructions } from './Instructions';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  mapControls: {
    ...theme.mapControls,
    justifyContent: 'center',
    padding: 8,
  },
  mobileControls: {
    ...theme.mapControls,
    padding: 4,
    backgroundColor: '#ffffff',
    borderTop: '1px solid #555555',
  },
  zoneControls: {
    ...theme.mapControls,
    padding: 8,
    flexWrap: 'nowrap',
  },
  icon: {
    ...theme.greenIcon,
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderRadius: 6,
    paddingRight: 6,
    margin: '2px 10px',
  },
  uploadButton: {
    ...theme.greenIcon,
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderRadius: 4,
    padding: '2px 8px 2px 2px',
    margin: '8px',
  },
  mobileIcon: theme.greenIcon,
  infoToolTip: theme.infoToolTip,
}));

/**
 * Toolbar for creating new fields. This will usually be initial controls user
 * sees for interacting with map and drawing or editing a field boundary.
 * @param {Function} addPolygon Begin polygon draw
 * @param {Function} addSquare Begin square polygon draw
 * @param {Function} editBoundary Update fields boundary
 * @param {Function} clear Clears layers, toggles off clus, and sets field to null
 * @param {Bool} enableClus Determines switch status for clu toggle
 * @param {Function} handleCluSwitch Toggle on and off clu layer
 * @returns {JSX} toolbar
 */
export const FieldSelectionToolBar = ({
  addPolygon,
  addSquare,
  editBoundary,
  createFromShapeFile,
  clear,
  enableClus,
  handleCluSwitch,
  setShowInstructions,
  zoomLevel,
}) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <Box className={classes.mapControls}>
      <Box display="flex" alignItems="center">
        <FieldSelectionInstructions
          zoomLevel={zoomLevel}
          width={width}
          setShowInstructions={setShowInstructions}
        />

        { zoomLevel > 13 && (
        <Box display="flex" flexWrap="wrap">
          <Box mx={1} bgcolor="#ffffff">
            <CustomToolTip title="Draw polygons by clicking to place vertices. Click the first vertex to complete the polygon" placement="top-start">
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => addPolygon()}
              >
                <AddIcon />
                {' '}
                {' Draw'}
              </Button>
            </CustomToolTip>
          </Box>

          <Box mx={1} bgcolor="#ffffff">
            <CustomToolTip title="Draw square polygon. After completion you can edit the boundary" placement="top-start">
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => addSquare()}
              >
                <AddBoxOutlinedIcon />
                {' '}
                {' Square'}
              </Button>
            </CustomToolTip>
          </Box>

          { editBoundary && (
            <Box mx={1} bgcolor="#ffffff">
              <CustomToolTip title="Edit boundary of selected field by dragging polygon points" placement="top-start">
                <Button
                  color="primary"
                  variant="outlined"
                  size="small"
                  onClick={() => editBoundary()}
                >
                  <EditLocationIcon />
                  {' '}
                  {'Edit Boundary'}
                </Button>
              </CustomToolTip>
            </Box>

          )}

          <Box mx={1} bgcolor="#ffffff">
            <CustomToolTip title="Clear field boundaries from map" placement="top-start">
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => clear()}
              >
                <HighlightOffOutlinedIcon />
                {' Clear'}
              </Button>
            </CustomToolTip>
          </Box>

        </Box>
        )}

        <CustomToolTip title="Upload a zipped shape file and we will create your field from it." placement="top-start">
          <Box
            className={classes.uploadButton}
            component="label"
          >
            <PublishIcon />
            Upload Shape File
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => createFromShapeFile(e)}
              multiple
            />
          </Box>
        </CustomToolTip>

      </Box>

      <Box display="flex" alignItems="center">
        <Switch
          color="primary"
          checked={enableClus}
          onChange={(e) => handleCluSwitch(e.target.checked)}
          inputProps={{
            'aria-label': 'boundary toggle',
          }}
        />
        Show Boundary Layer
      </Box>
    </Box>
  );
};

export const MobileFieldSelectionToolBar = ({
  addPolygon,
  addSquare,
  editBoundary,
  createFromShapeFile,
  clear,
  enableClus,
  handleCluSwitch,
  setShowInstructions,
  zoomLevel,
}) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  // Anchor to tool menu
  const [anchorEl, setAnchorEl] = useState(null);

  const handleOpenSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classes.mobileControls}>

      { zoomLevel > 13 && (
        <Button
          color="primary"
          variant="outlined"
          aria-controls="tool-menu"
          aria-haspopup="true"
          onClick={handleOpenSelect}
        >
          Map Tools
        </Button>
      )}

      <Menu
        id="mobile-maptoolbar-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseSelect}
      >
        <MenuItem onClick={handleCloseSelect}>
          <Box
            className={classes.mobileIcon}
            onClick={() => addPolygon()}
          >
            <AddIcon />
            {' Draw'}
          </Box>
        </MenuItem>

        <MenuItem onClick={handleCloseSelect}>
          <Box
            className={classes.mobileIcon}
            onClick={() => addSquare()}
          >
            <AddBoxOutlinedIcon />
            {' Square'}
          </Box>
        </MenuItem>

        { editBoundary && (
          <MenuItem onClick={handleCloseSelect}>
            <Box
              className={classes.mobileIcon}
              onClick={() => editBoundary()}
            >
              <EditLocationIcon />
              {' Edit Boundary'}
            </Box>
          </MenuItem>
        )}

        <MenuItem onClick={handleCloseSelect}>
          <Box
            className={classes.mobileIcon}
            onClick={() => clear()}
          >
            <HighlightOffOutlinedIcon />
            Clear
          </Box>
        </MenuItem>

        <MenuItem onClick={handleCloseSelect}>
          <Box
            className={classes.mobileIcon}
            component="label"
            style={{ margin: '0 8px' }}
          >
            <PublishIcon />
            Upload Shape File
            <input
              type="file"
              style={{ display: 'none' }}
              onChange={(e) => createFromShapeFile(e)}
              multiple
            />
          </Box>
        </MenuItem>
      </Menu>

      <FieldSelectionInstructions
        zoomLevel={zoomLevel}
        width={width}
        setShowInstructions={setShowInstructions}
      />

      <Box display="flex" alignItems="center" height={24}>
        <Switch
          color="primary"
          checked={enableClus}
          onChange={(e) => handleCluSwitch(e.target.checked)}
          inputProps={{
            'aria-label': 'boundary toggle',
          }}
        />
        Boundaries
      </Box>

    </Box>
  );
};

// Toolbar to display while editing
export const EditToolBar = ({ finishEdit }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mapControls}>
      <Button
        variant="outlined"
        color="primary"
        style={{ backgroundColor: '#ffffff' }}
        onClick={() => finishEdit()}
      >
        Finish Edit
      </Button>
    </Box>
  );
};

// Toolbar to display while drawing
export const DrawingToolBar = ({ cancel }) => {
  const classes = useStyles();

  return (
    <Box className={classes.mapControls}>
      <Button
        variant="outlined"
        color="primary"
        style={{ backgroundColor: '#ffffff' }}
        onClick={() => cancel()}
      >
        Cancel
      </Button>
    </Box>
  );
};

/**
 * Takes functions from maps, and create interactive toolbar for user to
 * create zones on existing field.
 * @param {Function} addPolygon Free form polygon draw
 * @param {Function} addSquare Draw square polygons
 * @param {Function} slice Split field into zones
 * @param {Function} createFromSsurgo Creates zones from SSURGO data
 * @param {Function} editBoundaries Edits currently drawn zones
 * @param {Function} resetZones Removes drawn zones
 * @param {Function} showFinish Show finish button if tool in use
 * @param {Function} finish Complete using current tool
 * @param {Bool} instructionsOpen Instuctions currently open
 * @param {Function} showInstructions Shows tool instuctions
 * @returns {JSX} Map controls toolbar
 */
export const ZoneToolBar = ({
  addPolygon,
  addSquare,
  addGrid,
  slice,
  createFromSsurgo,
  editBoundaries,
  resetZones,
  showFinish,
  finish,
  instructionsOpen,
  showInstructions,
}) => {
  const classes = useStyles();
  const { width } = useWindowDimensions();

  return (
    <Box
      className={width > 920 ? classes.zoneControls : classes.mobileControls}
      style={width > 920 ? {} : { padding: '4px 16px' }}
    >
      <Fade in={!instructionsOpen}>
        <IconButton color="primary" onClick={() => showInstructions(true)} style={{ padding: 0 }}>
          <InfoOutlinedIcon />
        </IconButton>
      </Fade>
      <Box display="flex" flexWrap="wrap">
        <CustomToolTip
          title="Draw polygons by clicking to place vertices. Click the first vertex to complete the polygon"
          placement="top-start"
        >
          <Box className={classes.icon} onClick={() => addPolygon()}>
            <AddIcon />
            {' '}
            {' Draw'}
          </Box>
        </CustomToolTip>

        <CustomToolTip
          title="Draw square polygon. After completion you can edit the boundary"
          placement="top-start"
        >
          <Box className={classes.icon} onClick={() => addSquare()}>
            <AddBoxOutlinedIcon />
            {' '}
            {' Square'}
          </Box>
        </CustomToolTip>

        <CustomToolTip
          title="Edit existing boundaries"
          placement="top-start"
        >
          <Box className={classes.icon} onClick={() => editBoundaries()}>
            <AddBoxOutlinedIcon />
            {' '}
            {' Edit'}
          </Box>
        </CustomToolTip>

        { createFromSsurgo && (
          <CustomToolTip
            title="Generate zones based on SSURGO soil data"
            placement="top-start"
          >
            <Box className={classes.icon} onClick={() => createFromSsurgo()}>
              <AddCircleOutlineOutlinedIcon />
              {' SSURGO'}
            </Box>
          </CustomToolTip>
        )}

        <CustomToolTip
          title="Create grid from your field boundary"
          placement="top-start"
        >
          <Box className={classes.icon} onClick={() => slice()}>
            <TimelineIcon />
            {' Split Field'}
          </Box>
        </CustomToolTip>

        <CustomToolTip
          title="Create grid from your field boundary"
          placement="top-start"
        >
          <Box className={classes.icon} onClick={() => addGrid()}>
            <GridOnOutlinedIcon />
            {' Grid Field'}
          </Box>
        </CustomToolTip>

        <CustomToolTip
          title="Clear zones and choose a new way to create them"
          placement="top-start"
        >
          <Box
            className={classes.icon}
            onClick={() => resetZones()}
            style={{ paddingLeft: 6 }}
          >
            Reset
          </Box>
        </CustomToolTip>

        { showFinish && (
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 8px' }}
            disableElevation
            onClick={() => finish()}
          >
            {width > 660 && 'Continue to '}
            {'Select Package'}
          </Button>
        )}

      </Box>

      <Box width={window > 740 ? 24 : 0}>{' '}</Box>
    </Box>
  );
};

FieldSelectionToolBar.propTypes = {
  addPolygon: PropTypes.func.isRequired,
  addSquare: PropTypes.func.isRequired,
  editBoundary: PropTypes.func,
  createFromShapeFile: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  handleCluSwitch: PropTypes.func.isRequired,
  enableClus: PropTypes.bool.isRequired,
  setShowInstructions: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number.isRequired,
};

FieldSelectionToolBar.defaultProps = {
  editBoundary: undefined,
};

MobileFieldSelectionToolBar.propTypes = {
  addPolygon: PropTypes.func.isRequired,
  addSquare: PropTypes.func.isRequired,
  editBoundary: PropTypes.func,
  createFromShapeFile: PropTypes.func.isRequired,
  clear: PropTypes.func.isRequired,
  handleCluSwitch: PropTypes.func.isRequired,
  enableClus: PropTypes.bool.isRequired,
  setShowInstructions: PropTypes.func.isRequired,
  zoomLevel: PropTypes.number.isRequired,
};

MobileFieldSelectionToolBar.defaultProps = {
  editBoundary: undefined,
};

ZoneToolBar.propTypes = {
  addPolygon: PropTypes.func.isRequired,
  addSquare: PropTypes.func.isRequired,
  addGrid: PropTypes.func.isRequired,
  slice: PropTypes.func.isRequired,
  createFromSsurgo: PropTypes.func.isRequired,
  editBoundaries: PropTypes.func.isRequired,
  resetZones: PropTypes.func.isRequired,
  showFinish: PropTypes.bool.isRequired,
  finish: PropTypes.func.isRequired,
  instructionsOpen: PropTypes.bool.isRequired,
  showInstructions: PropTypes.func.isRequired,
};

import React from "react";
import Checkbox from "@material-ui/core/Checkbox";
import { COLORS } from "../../../constants";
import { withStyles } from "@material-ui/core";

const GreenCheckbox = withStyles({
  root: {
    color: COLORS.green,
    "&$checked": {
      color: COLORS.green,
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

export default GreenCheckbox;

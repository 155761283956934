import React, { useEffect , useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Button,
  Slide,
  Select,
  MenuItem,
  Switch,
  Divider
} from "@material-ui/core";
import { MenuProps } from "../../../styles/select";
import { AerialImageryMap } from './AerialImageryMap'
import { blackText, grey } from "../../../styles/colors";
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { exists } from '../../../utils/helpers'
import { Endpoints } from "../../../constants/Endpoints";
import { useWindowDimensions } from '../../../utils/dimensions'
import { GetUav, GetWaterManagement, IntegrationLinks } from '../../Shared/ServiceLinks'

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  buttonLink: {
    textTransform: 'none',
    '&:hover': {
         color: theme.palette.primary.dark,
      },
  },
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * [AerialImagery description]
 * @param {Object} field  Field to display data for
 * @param {Object} status Loading status
 * @param {String} errorMessage Message to display to user if do not have data
 * @param {Bool} precisionField If field is from precision source
 * @param {onConnectClick} onConnectClick Open integration modal
 */
export function AerialImagery({
  field,
  loading,
  naipYears,
  selectedNaipYear,
  changeNaipYear,
  naipData,
  mobileBreakPoint,
  errorMessage,
  precisionField,
  onConnectClick
}) {
  const classes = useStyles();
  const {width, height} = useWindowDimensions();

  const [imageDate, setImageDate] = useState(null)
  const [showNaip, setShowNaip] = useState(true)

  useEffect(() => {
    if (exists(naipData)) {
      let dataForSelectedYear = naipData[selectedNaipYear]
      if (exists([dataForSelectedYear, dataForSelectedYear?.image_date])) {
        try {
          let formatDate = dataForSelectedYear.image_date[0].replace('.tif', '')
          let year = formatDate.substring(0, 4)
          let month = formatDate.substring(4, 6)
          let day = formatDate.substring(6)
          setImageDate(`${month}-${day}-${year}`)
        } catch (err) {
          console.log(err)
        }
      }
    }
  }, [naipData])


  const handleNaipSwitch = (checked) => {
    setShowNaip(checked)
  }

  const changeYear = (newYear) => {
    setShowNaip(true)
    changeNaipYear(newYear)
  }

  const controls = () => (
    <Box
      display='flex'
      alignItems='center'
      flexWrap='wrap'
    >
      <Box style={{width: 140, margin: '0 8px 0 16px'}}>
        <Box>
          Imagery Year
        </Box>
        <Select
          className={classes.selections}
          variant='outlined'
          MenuProps={MenuProps}
          value={selectedNaipYear}
          onChange={(e) => changeYear(e.target.value)}
        >
          {
            naipYears.map((x, i) => (
              <MenuItem
                key={i}
                value={x}
                name={x}
              >
                {x}
              </MenuItem>
            ))
          }
        </Select>
      </Box>

      {
        (naipData !== null && naipData !== undefined) && (
          <Box mx={1} ml={4} fontSize={18}>
            <Box>
              {'Date of image: '}
              {imageDate}
            </Box>
            <Box
              display='flex'
              alignItems='center'
              fontSize={14}
            >
              <Switch
                color='primary'
                checked={showNaip}
                onChange={(e) => handleNaipSwitch(e.target.checked)}
                inputProps={{ 'aria-label': 'boundary toggle' }}
              />
              Toggle NAIP Imagery
            </Box>
          </Box>
        )
      }

      <Box>
        {' '}
      </Box>
    </Box>
  )

  return (
    <Slide direction='right' in={true}>
      <Box color={blackText} fontWeight={500}>
        <Box mt={1} mx={1}>
          <Box fontSize={18}>
            Aerial Imagery (NAIP)
          </Box>
          <Box fontSize={'1rem'}>
            Acquired by the National Agriculture Imagery Program during growing seasons across the continental U.S.
          </Box>
          <Divider />
        </Box>

        <Box
          mt={.5} 
          display='flex' 
          flexDirection={width > 1080 ? 'row' : 'column'}
        >
          <Box display='flex' flexDirection='column' flexGrow={1}>
            {
              (naipYears !== undefined && naipYears.length > 0) ? (
                controls()
              ) : (
                <Box m={1} display='flex'>
                  <Box className={classes.errorMessageBox}>
                    {errorMessage}
                  </Box>
                </Box>
              )
            }

            <Box
              p={.5}
              display='flex'
              flexGrow={1}
              minWidth={width >= 1080 ? width - 800 : width > mobileBreakPoint ? width - 240 : width - 30}
              borderRadius={4}
            >
              <AerialImageryMap
                boundary={field.boundary}
                naipData={naipData}
                selectedYear={selectedNaipYear}
                showNaip={showNaip}
                location={field?.state}
              />
            </Box>
          </Box>

          <Box 
            display='flex' 
            flexWrap='wrap'
            flexDirection={(width < mobileBreakPoint || width > 1080) ? 'column' : 'row'}
          >
            { !precisionField ? (
              <Box display='flex' justifyContent='center'>
                <Box p={.5}>
                  <IntegrationLinks onConnect={onConnectClick} />
                </Box>
              </Box>  
            ) : (
              <Box p={.5} display='flex' justifyContent='center'>
                <GetWaterManagement />
              </Box>
            )}

            <Box p={.5} display='flex' justifyContent='center'>
              <GetUav />
            </Box>
          </Box>
        </Box>

        {loading && <SpinningLoader />}
      </Box>
    </Slide>

  )
}

import React from "react";
import { Box } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles';
import { FieldImage } from '../../Maps/FieldImage'
import { blackText, darkText, darkGrey } from '../../../styles/colors';
import { numFormat } from '../../../utils/helpers'

/**
 * Hidden component that can be used to take an image of field
 * @param {Object} field Field with data such as acres and coordinates
 */
export function SelectedFieldImage({field}) {
  return (
    <Box>
      <Box
        id='field-image'
        display='flex'
        flexDirection='column'
        borderRadius='borderRadius'
        border={1}
        borderColor={darkGrey}
        bgcolor='#ffffff'
        fontWeight={500}
        color={blackText}
        fontSize={16}
      >
        <Box px={1}>
          <Box display='flex' flexWrap='wrap'>
            <Box mr={2}>
              {`Field Acres: ${numFormat(field?.acres)}`}
            </Box>
            <Box>
              {`Zone Acres: ${numFormat(field?.zoneAcres)}`}
            </Box>
          </Box>


          <Box display='flex' flexWrap='wrap'>
            <Box mr={2}>
              {`Latitude: ${numFormat(field?.latitude, 2, 5)}`}
            </Box>
            <Box>
              {`Longitude: ${numFormat(field?.longitude, 2, 5)}`}
            </Box>
          </Box>
        </Box>

        <FieldImage
          field={field}
        />
      </Box>

    </Box>
  )
}

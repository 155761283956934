import React, { useEffect, useState, useContext, Fragment } from 'react'
import {
  AppBar,
  Box,
  Button,
  Typography,
  Divider,
  Select,
  MenuItem,
  TextField,
  Slide,
  Modal
 } from '@material-ui/core';
 import { makeStyles } from '@material-ui/core/styles';
 import { useWindowDimensions } from '../../utils/dimensions'
 import {getDoNotCall} from '../../utils/dataFetchers'
 import CheckCircleOutlineIcon from '@material-ui/icons/CheckCircleOutline';
 import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
 import {SpinningLoader} from '../Shared/SpinningLoader'
 import { useSnackbar } from "notistack";

 const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginTop: "50px"
  },
  secretCode: {
    marginBottom: '20px'
  },
  phoneNumber:{
    display: 'flex',
    alignItems: 'center'
  },
  numberItem:{
    margin:"5px"
  }

 }))

  export function DoNotCall({}){

    const classes = useStyles()
    const {height, width} = useWindowDimensions()
    const { enqueueSnackbar } = useSnackbar();

    const [secretCode, setSecretCode] = useState("")
    const [areaCode, setAreaCode] = useState("")
    const [number, setNumber] = useState("")

    const [canCall, setCanCall] = useState(null)

    const [loading, setLoading] = useState(false)

    const handleLookup = async () => {
      //validate inputs:
      let secret = null
      if(secretCode !== ""){
        secret = secretCode
      }

      if(areaCode !== "" && number !== ""){
        setLoading(true)
        let response = await getDoNotCall(areaCode, number, secret)
        console.log(response)
        setLoading(false)
        if (response === "Incorrect Code"){
          enqueueSnackbar(response, {autoHideDuration: 6000})
        }
        else{
          setCanCall(response)
        }
      }
      
    }


    return (
      <Box className={classes.root} style={{height: height - 80}}>
        <Box className={classes.container}>
          <Box className={classes.secretCode}>
            <TextField 
            label="Secret Code" 
            variant="outlined" 
            value={secretCode}
            onChange={(e) => setSecretCode(e.target.value)}
            />
          </Box>
          <Box className={classes.phoneNumber}>
            <Box className={classes.numberItem}>
              <TextField
              label="Area Code" 
              variant="outlined" 
              value={areaCode}
              onChange={(e) => setAreaCode(e.target.value)}
              />
            </Box>
            <Box className={classes.numberItem}>
              <TextField
              label="Phone Number" 
              variant="outlined" 
              value={number}
              onChange={(e) => setNumber(e.target.value)}
              />
            </Box>
            <Box className={classes.numberItem}>
              <Button
              color='primary'
              variant='outlined'
              onClick={handleLookup}
              >
                Lookup Number
              </Button>
            </Box>
          </Box>
          <Box>
            <Typography variant="h3">
              Result:
              {canCall !== null &&
                <Box>
                  {canCall === true
                  ?
                  <Typography variant="h4" style={{fontSize: "larger", color:"green"}}>
                    <CheckCircleOutlineIcon style={{fontSize: "larger"}}/>
                    Ok To Call
                  </Typography>
                  :
                  <Typography variant="h4" style={{fontSize: "larger", color:"red"}}>
                    <CancelOutlinedIcon style={{fontSize: "larger"}}/>
                    DO NOT CALL
                  </Typography>
                  }
                </Box>
              } 
            </Typography>

          </Box>
        </Box>
        {loading && 
          <SpinningLoader/>
        }
      </Box>
    )




  }
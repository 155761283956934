/* eslint-disable radix */
/* eslint-disable no-plusplus */
/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';

// MUI and Custom Componetnts
import {
  Box, 
  Button, 
  CircularProgress, 
  Divider,
  Fade, 
  MenuItem, 
  Modal, 
  Radio, 
  Select, 
  TextField, 
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { useSnackbar } from 'notistack';

// Library imports
import * as turf from '@turf/turf';
import { cloneDeep } from 'lodash';
import * as wkt from 'terraformer-wkt-parser';

// Custom components
import { MenuProps } from '../../../styles/select';
import { ConfirmationPopup } from '../../Shared/ConfirmationPopup';
import { CustomAutocomplete, CustomToolTip } from '../../../utils/customComponents';

// Map
import { CreateOperation } from '../../Maps/CreateOperation';

// Data
import { cartDropdownData } from '../presetData';

// Helper functions
import { useWindowDimensions } from '../../../utils/dimensions';
import { getGeoJson } from '../../FieldSelection/utils/helpers';
import { reprojectCalcArea } from '../../../utils/reprojectCalcArea';
import { numFormat } from '../../../utils/helpers';
import { handleCluFile, parseDateObject } from '../helpers';

// API calls and other functionality
import {
  fetchFarms,
  fetchFields,
  fetchField,
  getCLUIntersectionsOld,
  getNonCLUBoundariesGivenBounds,
  getStateAndCounty,
  getCVTCrops,
  getNewGUID,
  getNewZoneID,
  addCluBoundary,
} from '../../../utils/dataFetchers';

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
  },
  head: {
    ...theme.updateOperationHeader,
    alignItems: 'center',
  },
  close: {
    ...theme.icon,
  },
  details: theme.plDetails,
  display: {
    height: 40,
    width: 200,
    display: 'flex',
    alignItems: 'center',
    margin: 4,
    padding: '0 8px',
    backgroundColor: theme.palette.greys.light,
    borderRadius: 4,
    border: `1px solid ${theme.palette.greys.main}`,
    fontWeight: 400,
  },
  select: {
    height: 44.6,
    margin: 4,
    width: 200,
  },
  dateSelection: {
    margin: 4,
    '& .MuiInputBase-root': {
      padding: 4,
      '& .MuiButtonBase-root': {
        padding: 0,
        paddingLeft: 10,
      },
      '& .MuiInputBase-input': {
        padding: 4,
        paddingLeft: 0,
      },
    },
    width: 200,
  },
  label: {
    color: theme.palette.greys.dark,
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  radios: {
    padding: 4,
    border: `1px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
    margin: 4,
    width: 200,
  },
  footer: theme.updateOperationFooter,
}));

/**
 * Crate a new seeding operation or prevented planting.
 * @param {Function} addOperation Adds newly created operation to orderedMappings
 * @param {Boolean} allowCLUSelection whether 'Load Acreage Data' has been clicked and no error has occured
 * @param {Object} chosenOrg Name and ID of chosen org
 * @param {Object} clusSeen Seen CLUs
 * @param {Object} commoditiesSeen Options already loaded
 * @param {Object} commodityTypesSeen Options already loaded
 * @param {Number} commodityYear Commodity year. Needed for default date
 * @param {Object} defaultValues Defaults for intial values
 * @param {Boolean} gartPath Whether or not current zoneType is 'GART'
 * @param {Function} getCommodityTypes Gets types associated with crop
 * @param {Function} getIntendedUses Gets intended use options associated with crop and type
 * @param {Function} handleNonCLUBoundary Properly handle adding a new nonCLUBoundary
 * @param {String} impersonating email address for user being impersonated by agent
 * @param {Boolean} includeNonCLUBoundaries Whether to include "orphaned boundaries"
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Array} organizations User organizations
 * @param {Boolean} preventedPlanting Start operation as prevented
 * @param {Object} setClusSeen Updated seen CLUS
 * @param {Function} setCommoditiesSeen Sets loaded commotities
 * @param {Function} setCommodityTypesSeen Update commodity types
 * @param {Function} setOpen Sets if modal is open
 * @param {Object} statesSeen Options already loaded
 * @param {Boolean} useOrgCLUData CLU data to use. Either true or false
 * @param {String} useOwnCLUData whether to own user's or another's CLU data
 * @param {Object} viewingField Field user is currently viewing
 * @return {JSX} CreateOperation
 */
export const CreateNewOperation = ({
  addOperation,
  allowCLUSelection,
  chosenOrg,
  clusSeen,
  commoditiesSeen,
  commodityTypesSeen,
  commodityYear,
  defaultValues,
  gartPath,
  getCommodityTypes,
  getIntendedUses,
  handleNonCLUBoundary,
  impersonating,
  includeNonCLUBoundaries,
  open,
  organizations,
  preventedPlanting,
  setClusSeen,
  setCommoditiesSeen,
  setCommodityTypesSeen,
  setOpen,
  statesSeen,
  useOrgCLUData,
  useOwnCLUData,
  viewingField,
}) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  // Field selection
  const [selectedOrg, setSelectedOrg] = useState(null);
  const [farms, setFarms] = useState([]);
  const [selectedFarm, setSelectedFarm] = useState(null);
  const [fields, setFields] = useState([]);
  const [selectedField, setSelectedField] = useState(null);

  // CLU selection
  const userClus = useRef([]);
  const [cluFarms, setCluFarms] = useState([]);
  const [selectedCluFarm, setSelectedCluFarm] = useState(null);
  const [tracts, setTracts] = useState([]);
  const [selectedTract, setSelectedTract] = useState(null);
  const [clus, setClus] = useState([]);
  const [selectedClu, setSelectedClu] = useState(null);
  // Only selected CLUs and those which map has viewed area of will have boundaries
  const [cluBoundaries, setCluBoundaries] = useState(new Set());

  // Toggle between field and clu selection
  const [selectBy, setSelectBy] = useState('Field');
  const [operationBoundary, setOperationBoundary] = useState({});

  // Acreage Reporting Data
  const [acres, setAcres] = useState(0);
  const [plantingDate, setPlantingDate] = useState(new Date(new Date().setYear(commodityYear)));
  const [crop, setCrop] = useState('');
  const [cropType, setCropType] = useState('');
  const [croppingPractice, setCroppingPractice] = useState(
    defaultValues?.croppingPracticeCode || 997,
  );
  const [intendedUse, setIntendedUse] = useState('');
  const [intendedUses, setIntendedUses] = useState(defaultValues?.intendedUses || '');
  const [organic, setOrganic] = useState('N');
  const [irrigated, setIrrigated] = useState(defaultValues?.irrigationPracticeCode || 'N');
  const [productPlantingCode, setProductPlantingCode] = useState(preventedPlanting ? 'P' : '');

  const [crops, setCrops] = useState([]);
  const [cropTypes, setCropTypes] = useState([]);
  const [intendedUseOptions, setIntendedUseOptions] = useState([]);

  const [error, setError] = useState('');

  // Saving
  const [openConfirmation, setOpenConfirmation] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (organizations.length) {
      selectInitialField();
    }
  }, [organizations]);

  useEffect(() => {
    // Set default values
    if (crop === '') {
      try {
        const initialCrops = statesSeen[defaultValues?.crops];
        if (initialCrops) {
          setCrops(initialCrops);
          const defaultCrop = initialCrops.filter((x) => x.commodityCode === defaultValues.crop)[0];
          setCrop(defaultCrop.commodityCode);
        } else {
          getDefaultCrops();
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [defaultValues]);

  useEffect(() => {
    if (!cropTypes.length) {
      try {
        const initialCropTypes = commoditiesSeen[defaultValues?.cropTypes];
        if (initialCropTypes) {
          setCropTypes(initialCropTypes);
          const defaultCropType = initialCropTypes.filter(
            (x) => x.commodityTypeCode === defaultValues.cropType,
          )[0];
          setCropType(defaultCropType.commodityTypeCode);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [defaultValues, commodityTypesSeen]);

  useEffect(() => {
    if (!intendedUseOptions.length) {
      try {
        const intendedUsesList = commodityTypesSeen[defaultValues?.intendedUses];
        if (intendedUsesList) {
          setIntendedUseOptions(intendedUsesList);
          const defaultUse = intendedUsesList.filter(
            (x) => x.intendedUseCode === defaultValues.intendedUse,
          )[0];
          setIntendedUse(defaultUse.intendedUseCode);
        }
      } catch (err) {
        console.error(err);
      }
    }
  }, [defaultValues, commodityTypesSeen]);


  // Gets the initial data for farms (for selected org) and for fields (for selected farm)
  // Defaults to organization, farm, and field selected in interface, if it exists
  const selectInitialField = async () => {
    // viewingField is set by displayedField() in ClientApp\src\components\AcreageReporting\Display\FarmSection.js
    if (viewingField?.orgId) {
      const orgId = viewingField.orgId;
      const matchingOrg = organizations.filter(org => Number(org.id) === Number(orgId));
      const viewingFarm = {orgId, id: viewingField.farmId, name: viewingField.farmName};
      handleOrgChange(matchingOrg[0], viewingFarm, viewingField);
    }
    else {
      handleOrgChange(organizations[0]);
    }
  }

  /**
   * Get identifying data for users CLUs around a certain max area. This will include clu_identifier,
   * clu_number, tract_number, farm_number, admin_county, and admin_state.
   * We do NOT include boundary here, as a user could have thousands of CLUs.
   * Boundaries are acquired for a selected CLU and those in currently viewed map bounds.
   * NOTE: This is placeholder data in dev and some fields may even be undefined
   * @param {Array} viewedClus Identifying data for users CLUs
   * @returns {void}
   */
  const handleClus = async (viewedClus) => {
    try {
      let newUserClus = viewedClus;
      if (selectedCluFarm) {
        // Keep all seen CLUs with selected farm_number to avoid some weird bugs. 
        const previousTracts = userClus.current.filter((clu) => clu.farm_number === selectedCluFarm);

        // Make sure to only include CLUs once to avoid repetition
        const mergedClusIDs = new Set();
        const mergedClus = [];
        for (const clu of [...previousTracts, ...viewedClus]) {
          if (!mergedClusIDs.has(clu.clu_identifier)) {
            mergedClusIDs.add(clu.clu_identifier);
            mergedClus.push(clu);
          }
        }
        newUserClus = mergedClus;
      }
      userClus.current = newUserClus;

      // Collect unique farm numbers
      const farmNumbers = new Set();
      viewedClus.forEach((clu) => {
        farmNumbers.add(clu.farm_number);
      });

      // Convert set to array and sort in ascending order
      const farmsArray = Array.from(farmNumbers).sort((a, b) => a - b);
      setCluFarms(farmsArray);

      // If a user has already selected a farm and/or tract, update those tract and clu lists accordingly.
      if (selectedCluFarm) {
        // Get new tracts that match selected farm number and sort all in ascending order
        const selectedTracts = newUserClus.filter((clu) => clu.farm_number === selectedCluFarm);
        const newTracts = new Set();
        selectedTracts.forEach((clu) => {
          newTracts.add(clu.tract_number);
        });
        const tractsArray = Array.from(newTracts).sort((a, b) => a - b);
        setTracts(tractsArray);

        if (selectedTract) {
          // Get new clus that match selected farm number and sort all in ascending order
          const selectedClus = newUserClus.filter((clu) => 
            (clu.farm_number === selectedCluFarm && clu.tract_number === selectedTract)
          );
          const clusArray = selectedClus.sort((a, b) => a.clu_number - b.clu_number);
          setClus(clusArray);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Currently, user's available farms, tracts, and clus will update when map is moved.
  const handleFarmNumberChange = (number) => {
    try {
      setSelectedCluFarm(number);
      setSelectedTract(null);
      setSelectedClu(null);

      // Get tracts that match farm number sort in ascending order
      const selectedTracts = userClus.current.filter((clu) => clu.farm_number === number);
      const newTracts = new Set();
      selectedTracts.forEach((clu) => {
        newTracts.add(clu.tract_number);
      });
      const tractsArray = Array.from(newTracts).sort((a, b) => a - b);
      setTracts(tractsArray);
    } catch (err) {
      console.error(err);
      setTracts([]);
    }
  };

  const handleTractChange = (tract) => {
    try {
      setSelectedTract(tract);
      setSelectedClu(null);

      // Get CLUs that match tract number and sort in ascending order
      const tractsClus = userClus.current.filter((clu) => 
        (clu.farm_number === selectedCluFarm && clu.tract_number === tract)
      );
      const cluArray = Array.from(tractsClus).sort((a, b) => a.clu_number - b.clu_number);
      setClus(cluArray);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCluChange = async (clu) => {
    try {
      setLoading(true);
      setSelectedClu(clu);
      selectClu(clu.cluShape);
    } catch (err) {
      // console.log('Error: ', err);
    } finally {
      setLoading(false);
    }
  };

  const getDefaultCrops = async () => {
    try {
      const defaultCrops = await getCVTCrops();

      if (typeof defaultCrops !== 'string' && defaultCrops?.length) {
        const displayCrops = defaultCrops.map((x) => ({ ...x, displayValue: `${x.commodityName} ${x.commodityCode}` }));
        setCrops(displayCrops);

        // Look for default crop by code, in this case Corn, Field
        const defaultCrop = displayCrops.filter((x) => x.commodityCode === '0041');
        if (defaultCrop) {
          handleCropChange(defaultCrop[0].commodityCode);
        } else {
          // Expected default crop not found, so just set to first entry in default crops
          handleCropChange(displayCrops[0].commodityCode);
        }
      }
      else {
        enqueueSnackbar('We could not retrieve some needed data. Please try again later.');
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleCropChange = async (selectedCropCode) => {
    try {
      setCrop(selectedCropCode);
      let commodityTypes;

      if (!commoditiesSeen.hasOwnProperty(selectedCropCode)) {
        setLoading(true);
        commodityTypes = await getCommodityTypes(selectedCropCode);
        const prevCommoditiesSeen = cloneDeep(commoditiesSeen);
        const updatedCommodities = {
          ...prevCommoditiesSeen,
          [selectedCropCode]: commodityTypes,
        };
        setCommoditiesSeen(updatedCommodities);

        if (!commodityTypes) {
          const errorMessage = 'There are no crop types available for insurance coverage for this operation\'s crop.';
          handleError(errorMessage);
        }
      }
      else {
        commodityTypes = commoditiesSeen[selectedCropCode];
      }

      setCropTypes(commodityTypes);
      handleCropTypeChange(selectedCropCode, commodityTypes[0].commodityTypeCode);
    } catch (err) {
      const errorMessage = 'An issue occured while getting this crop\'s crop type.';
      handleError(errorMessage);
      setLoading(false);
    }
  };

  const handleCropTypeChange = async (selectedCropCode, typeCode) => {
    try {
      setCropType(typeCode);
      let uses;
      const cropCodeAndCropTypeCode = `${selectedCropCode}${typeCode}`;

      if (!commodityTypesSeen.hasOwnProperty(cropCodeAndCropTypeCode)) {
        setLoading(true);
        uses = await getIntendedUses(selectedCropCode, typeCode);
        const prevCommodityTypesSeen = cloneDeep(commodityTypesSeen);
        const updatedCommodityTypesSeen = {
          ...prevCommodityTypesSeen,
          [cropCodeAndCropTypeCode]: uses,
        };
        setCommodityTypesSeen(updatedCommodityTypesSeen);

        if (!uses) {
          const errorMessage = 'There are no intended uses available for insurance coverage for this operation\'s crop and chosen crop type.';
          handleError(errorMessage);
        }
        // If this temporary stop of the loading process really bothers someone, we can just have a state var here (ranOnce - default false) and setRanOnce to true the first time and execute this setLoading the following times
        setLoading(false);
      }
      else {
        uses = commodityTypesSeen[cropCodeAndCropTypeCode];
      }

      setIntendedUseOptions(uses);
      setIntendedUses(cropCodeAndCropTypeCode);
      setIntendedUse(uses[0].intendedUseCode);
    } catch (err) {
      const errorMessage = 'An issue occured while getting this crop\'s and chosen crop type\'s intended uses.';
      handleError(errorMessage);
      setLoading(false);
    }
  };

  const handleError = (errorMessage) => {
    enqueueSnackbar(errorMessage, {
      preventDuplicate: true,
    });
    setError(errorMessage);
  };

  // -------------- Field Interactions -------------- //
  const handleOrgChange = async (org, viewingFarm, viewingField) => {
    try {
      setLoading(true);
      setSelectedOrg(org);

      // Get farms for selected Org
      const orgFarms = await getFarms(org);
      setFarms(orgFarms);

      if (orgFarms.length) {
        if (viewingFarm) { handleFarmChange(viewingFarm, viewingField); }
        else { handleFarmChange(orgFarms[0]); }
      } else {
        // Reset all relevant states
        setFields([]);
        setSelectedFarm(null);
        setSelectedField(null);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('A problem occured while selecting your organization. Please try again or contact us if the problem persists.');
      setLoading(false);
    }
  };

  const getFarms = async (org) => {
    try {
    // const orgsFarms = await fetchFarms(org.id, false, clientId, user?.token, impersonating, true);
    const orgsFarms = await fetchFarms(org.id, false, null, null, impersonating, true);
      if (orgsFarms?.length) {
        return orgsFarms.map((farm) => ({
          org: org.name, orgId: org.id, id: farm.FarmID, name: farm.FarmName,
        }));
      }

      // If no farms were found alert user and return empty array
      enqueueSnackbar('No farms found for this organization');
      return [];
    } catch (err) {
      console.error(err);
      enqueueSnackbar('A problem occured while selecting your organization. Please try again or contact us if the problem persists.');
      return [];
    }
  };

  const handleFarmChange = async (farm, viewingField) => {
    try {
      setLoading(true);
      setSelectedFarm(farm);
      const farmsFields = await getFields(farm.orgId, farm.id, farm.name);
      setFields(farmsFields);

      if (farmsFields.length) {
        if (viewingField) { selectField(viewingField); }
        else { selectField(farmsFields[0]); }
      } else {
        setSelectedField(null);
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('We encounted an unexpected problem selecting your farm. Please try again or contact us if the problem persists.');
      setLoading(false);
    }
  };

  const getFields = async (orgId, farmId, farmName) => {
    try {
      // const farmsFields = await fetchFields(orgId, farmId, user?.token, impersonating);
      const farmsFields = await fetchFields(orgId, farmId, null, impersonating);
      if (farmsFields?.length) {
        const fieldsWithFarm = farmsFields.map((x) => ({
          farmId, farmName, orgId, id: x.ID, name: x.Name,
        }));
        fieldsWithFarm.sort((a, b) => a.name.localeCompare(b.name));
        return fieldsWithFarm;
      }

      // Alert user and return empty array
      enqueueSnackbar('No fields found for this farm');
      return [];
    } catch (err) {
      console.error(err);
      enqueueSnackbar('We encounted an unexpected problem selecting your farm. Please try again or contact us if the problem persists.');
      return [];
    }
  };

  const selectField = async (chosenField) => {
    try {
      setLoading(true);
      const { orgId, farmId, id } = chosenField;
      const fieldData = await fetchField(orgId, farmId, id, null, impersonating);

      if (fieldData?.length) {
        const geoJson = getGeoJson(fieldData[0]?.Shape);
        const projectedAcres = getAcres(geoJson.geometry);
        const location = await getLocation(geoJson);
        const completeField = {
          id,
          farmId,
          orgId,
          acres: projectedAcres,
          boundary: geoJson,
          county: location.CountyName,
          countyCode: location.COUNTYFP,
          farmName: chosenField.farmName,
          name: chosenField.name,
          points: geoJson.geometry.coordinates,
          shape: fieldData[0]?.Shape,
          state: location.StateName,
          stateCode: location.STATEFP,
        };
        setSelectedField(completeField);
        setOperationBoundary(geoJson);
        setAcres(projectedAcres);
      }
      else {
        // Alert user and setLoading to false
        enqueueSnackbar('We encounted an unexpected problem selecting your field. Please try again or contact us if the problem persists.');
        setLoading(false);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('We encounted an unexpected problem selecting your field. Please try again or contact us if the problem persists.');
      setLoading(false);
    }

    // If 'CART' zoneType, updating operation boundary (setOperationBoundary above) will always cause loading to be set to true 
    // in CreateOperation (getCluBoundariesInBounds), so no need to set it to false here
    // This won't happen for 'GART' zoneType however
    if (gartPath) {
      setLoading(false);
    }
  };

  const handleBoundaryFromGeoJson = (feature, fromCLU) => {
    try {
      setOperationBoundary(feature);
      // Get acres in needed projection
      const projectedAcres = getAcres(feature.geometry);
      setAcres(projectedAcres);

      if (!gartPath && !fromCLU) {
        // Check for intersections with users CLUs. If none found, alert
        let intersectionFound = false;
        // eslint-disable-next-line no-restricted-syntax
        for (const clu of cluBoundaries) {
          // Uploaded CLU should be Polygons but there is no check for that. If this causes issues at some point however, then should use getIntersections from AR.js
          const intersects = turf.intersect(feature.geometry, clu.cluShape);
          if (intersects) {
            intersectionFound = true;
            break;
          }
        }
        if (!intersectionFound) {
          enqueueSnackbar('Please note if you do not have a CLU boundary that intersects with this planting boundary you will be unable to view it in the interface after reloading.');
        }
      }
    } catch (err) {
      // console.log('Error :>> ', err);
      handleError('An error occured while handling your created boundary.');
    }
  };

  /**
   * Takes CLU boundary, gets reprojected acres, creates feature then sets as operation boundary
   * @param {String} boundary CLU shape
   * @returns {void}
   */
  const selectClu = (boundary) => {
    const geometry = boundary;
    const cluAcres = getAcres(geometry);
    setAcres(cluAcres);

    const cluBoundary = {
      geometry,
      type: 'Feature',
      properties: { reprojectedAcres: cluAcres },
    };

    setOperationBoundary(cluBoundary);
  };

  /**
   * Uses centroid of geoJson to get state and county data.
   * NOTE: Only for coordinates in United States.
   * @param {Object} geoJson GeoJson to use for location
   * @returns {Object} {CountyName, COUNTYFP, StateName, STATEFP}
   */
  const getLocation = async (geoJson) => {
    try {
      const centroid = turf.centroid(geoJson);
      const centerPoints = centroid.geometry.coordinates;
      const location = await getStateAndCounty(centerPoints[1], centerPoints[0], true);
      return location;
    } catch (err) {
      return {
        CountyName: '', COUNTYFP: '', StateName: '', STATEFP: '',
      };
    }
  };

  /**
   * Get acres for necessary reprojection format
   * @param {Object} geometry Area to project acres for
   * @returns {Number} reprojected Acres
   */
  const getAcres = (geometry) => {
    try {
      const projection = reprojectCalcArea(JSON.stringify(geometry), 'UTM', 'GeoJSON', 'WGS84', 'Acres');
      return projection.area;
    } catch (err) {
      console.error(err);
      return 0;
    }
  };

  // Updates the operation boundary and associated acres
  // Called after a draw, update, or cut event on boundary from CreateOperation
  const updateOperation = (feature) => {
    setOperationBoundary(feature);
    setAcres(getAcres(feature.geometry));
  };

  // Add needed operationData to this individual CLU
  const addOperationData = async (clu, CLUInfo, operationID) => {
    const reprojection = reprojectCalcArea(clu.boundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
    const newZoneID = await getNewZoneID();
    const cluData = {
      acres: reprojection.area,
      boundary: clu.boundary,
      clu_identifier: clu.clu_identifier,
      clu_number: clu.clu_number,
      crop,
      cropType,
      cropTypes: crop,
      croppingPracticeCode: croppingPractice,
      displayNumber: clu.clu_number,
      finalReportedAcreage: reprojection.area,
      geospatialShapeProcessDate: new Date().toISOString(),
      includeGeospatialInReport: 'Y',
      intendedUse,
      intendedUses,
      irrigationPracticeCode: irrigated,
      micsCode: 'O',
      micsName: 'UserEntered',
      operationID,
      organicPracticeTypeCode: organic,
      originalBoundary: clu.boundary,
      productPlantingCode,
      projCode: reprojection.epsgCode,
      section: clu.section,
      sharePercentage: defaultValues?.sharePercentage || 100,
      shown: true,
      zoneID: newZoneID.result,
    };
    CLUInfo.push(cluData);
    return newZoneID.result;
  };

  // Handles aggregating the necessary CLU and operation data for the input information,
  // saving this data to the DB, and adding it to the field chosen by the user 
  // (or the one selected in the interface if selectBy === 'CLU')
  const handleSave = async () => {
    // Do not allow user to save with no selected field
    if (selectBy === 'Field' && !selectedField) {
      enqueueSnackbar(`Please select a field before saving`);
      return;
    }

    try {
      setLoading(true);
      const opBoundary = operationBoundary.geometry;

      // Get CLU info, if needed
      let cluIntersections;
      let clusCopy;
      let nonCLUResponse;
      if (!gartPath) {
        // Get intersecting CLUs for operation
        const shape = wkt.convert(opBoundary);
        const cluRequestBody = {
          boundaries: shape,
          count: 1,
          cluOwnerEmail: useOwnCLUData,
          orgID: useOrgCLUData ? selectedOrg.id : '', 
          ownerEmail: impersonating,
        };
        cluIntersections = await getCLUIntersectionsOld(cluRequestBody);
        // NOTE: Should probably have a retry and then error message if this fails...

        // Now, get "orphaned boundaries", if appropriate
        if (includeNonCLUBoundaries) {
          // NOTE: This call will not use the updated boundary to find the nonCLUBoundaries...
          // So, those ones will not be exactly accurate but then again, nonCLUBoundaries are not really meant to be used..
          // And it won't get any data if the operation does not intersect with any CLU. So, functionality just disabled for those cases
          nonCLUResponse = await getNonCLUBoundariesGivenBounds(cluRequestBody);
          // console.log('nonCLUResponse :>> ', nonCLUResponse);

          // NOTE: In case getNonCLUBoundaries fails for some reason. Automatically try again first before telling user to try another time.
          if (!nonCLUResponse || typeof nonCLUResponse === 'string') {
            enqueueSnackbar('An issue occurred while retrieving your planting\'s information. Please try again later.');
            return false;
          }
        }

        // Process here mostly mimics what is happening in updateIntersections in AcreageReporting.js
        clusCopy = cloneDeep(clusSeen);
        cluIntersections.forEach((clu) => {
          const existingCLU = clusCopy[clu.clu_identifier];
          if (!existingCLU) {
            // Only copy over needed info (excluding boundary and acres)
            clusCopy[clu.clu_identifier] = { 
              farm_number: clu.farm_number, 
              tract_number: clu.tract_number, 
              clu_number: clu.clu_number, 
              clu_identifier: clu.clu_identifier, 
              cluShape: clu.cluShape, 
              admin_county: clu.admin_county, 
              admin_state: clu.admin_state, 
              cluProducerReviewRequestIndicator: 'N' 
            };
          }
        });
        setClusSeen(clusCopy);
      }

      // Handle choosing which field to save data for
      let fieldToUse = selectedField;
      if (selectBy === 'CLU') {
        // Even if there is a selected field, if select is by "CLU", then save for field selected in interface
        // Could also use selectedField but that mgiht be confusing for user.
        fieldToUse = viewingField;
        enqueueSnackbar(`This planting will be associated with field: ${viewingField?.name}`);
      }
      
      // Get necessary variables
      const guid = await getNewGUID('SeedingOperations');
      const operationID = `ACREAGEREPORTS-${guid}`;
      const year = plantingDate.getFullYear();
      // Format date as is expected for rest of the tool
      const date = parseDateObject(plantingDate);
      const fullDate = plantingDate.toISOString();
      const nowDate = (new Date()).toISOString();
      // Get the chosen crop
      const chosenCrop = crops.filter((x) => x.commodityCode === crop)[0];

      // Create the new operation object
      const operation = {
        EndDate: fullDate,
        StartDate: fullDate,
        acres,
        countyCode: fieldToUse.countyCode,
        created_at: nowDate,
        crop,
        cropType,
        cropTypes: crop,
        croppingPracticeCode: croppingPractice,
        crops: 'allStates',
        date,
        expanded: true,
        farmID: fieldToUse.farmId,
        fieldID: fieldToUse.id,
        finalPlantedDate: date,
        geospatialDataSubmissionMethodCode: 'D',
        includeGeospatialInReport: 'Y',
        intendedUse,
        intendedUses,
        irrigationPracticeCode: irrigated,
        micsCode: 'O',
        micsName: 'UserEntered',
        name: chosenCrop.commodityName,
        operationBoundary: opBoundary,
        operationID,
        orgID: fieldToUse.orgId,
        organicPracticeTypeCode: organic,
        plantingDate: fullDate,
        precisionAgriculturalSubMeterAccuracyIndicator: 'N',
        productPlantingCode,
        saved_at: nowDate,
        sharePercentage: defaultValues?.sharePercentage || 100,
        shown: true,
        stateCode: fieldToUse.stateCode,
        title: `${year} ${chosenCrop.displayValue}`,
        varietyList: '',
        varietyNames: [''],
        year,
      };

      // If appropriate, add needed operation data to CLUs
      if (!gartPath) {
        const CLUInfo = [];
        const zoneIDsFromDB = [];

        // Create proper CLU entries from cluIntersections
        for (const clu of cluIntersections) {
          const zoneID = await addOperationData(clu, CLUInfo, operationID);
          zoneIDsFromDB.push(zoneID);
        }

        // Do the same for nonCLUBoundaries
        if (includeNonCLUBoundaries && nonCLUResponse.length) {
          // For each new nonCLUboundary, compare to all new CLU intersections
          for (const nonCLU of nonCLUResponse) {
            const [noMatchFound, newCLU] = await handleNonCLUBoundary(CLUInfo, nonCLU.boundary, nonCLU.operationID, undefined, clusCopy);

            // Only do this if a new CLU for an "orphaned boundary" was created
            if (noMatchFound) {
              // This already has a zoneID but it's fine to overwrite it
              // However, we need to make sure not to override section
              const zoneID = await addOperationData(newCLU, CLUInfo, operationID);
              zoneIDsFromDB.push(zoneID);
            }
            else {
              // Just need to update a few vars - however
              // addOperationData doesn't work as it both creates a new object and adds a new entry to CLUInfo 
              const reprojection = reprojectCalcArea(newCLU.boundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
              newCLU.originalBoundary = newCLU.boundary;
              newCLU.acres = reprojection.area;
              newCLU.finalReportedAcreage = reprojection.area;;
              newCLU.projCode = reprojection.epsgCode;
            }
          }
        }

        // If after both of the above we still do not have any CLUs, then this operation intersected with no CLUs
        // TODO: Add whole operation boundary as a "fake CLU"

        // NOTE: This currently DOES NOT break up incoming MultiPolygons into Polygons as does initialLoad...
        // ADD THAT

        operation.CLUInfo = CLUInfo;
        operation.cluProducerReviewRequestIndicator = 'N';
        operation.zoneIDsFromDB = zoneIDsFromDB;
      }
      // If 'GART' zoneType, add extra needed operation data
      else {
        // NOTE: In here, we want to check if new boundary is a MultiPolygon and break it up if it is
        const reprojObj = reprojectCalcArea(opBoundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
        // If new GART entries - instead of CLU, operation info needs zoneID
        const newZoneID = await getNewZoneID();

        operation.finalReportedAcreage = reprojObj.area; // acres should be the same as this
        operation.geospatialShapeProcessDate = nowDate;
        operation.originalBoundary = opBoundary;
        operation.projCode = reprojObj.epsgCode;
        operation.reportedAcreageModifiedIndicator = 'N';
        operation.zoneID = newZoneID.result;
      }

      // If any errors were encounted add them to operation data
      if (error !== '') {
        operation.error = error;
      }
      // console.log('operation :>> ', operation);

      // Add operation created in CreateNewOperation to orderedMappings for display in tool
      addOperation(operation, fieldToUse, clusCopy);
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setOpen(false);
    }
  };

  const fixMonth = () => {
    try {
      const month = plantingDate.getMonth() + 1;
      if (month.toString().length === 1) {
        return `0${month}`;
      }
      return month;
    } catch (err) {
      console.error(err);
      return null;
    }
  };

  const handleFile = async (e) => {
    const parsedFile = await handleCluFile(e);
    if (parsedFile === undefined) {
      enqueueSnackbar('Could not parse CLU from uploaded File');
    } else if (typeof (parsedFile) === 'string') {
      enqueueSnackbar(parsedFile);
    } else {
      let [cluReq, geojson] = parsedFile;
      // Add CLU to DB
      const upload = await addCluBoundary(cluReq);
      if (upload.status === 'Success') {
        // center map on CLU geojson
        if (geojson.type !== 'Feature') {
          geojson = {
            type: 'Feature',
            properties: {},
            geometry: geojson,
          };
        }
        handleBoundaryFromGeoJson(geojson);
      } else {
        enqueueSnackbar('There was a problem saving your CLU, please try again');
      }
    }
  };

  const handleSelectionChange = (event) => {
    setSelectBy(event.target.value);
  };

  const selectionToggle = () => (
    <Box p={1}>
      <Box>
        {!gartPath ? 'Select by Field or CLU' : 'Select by Field'} 
      </Box>

      { !gartPath && (
        <Box>
          <Box display="flex" alignItems="center">
            <Radio
              color="primary"
              checked={selectBy === 'Field'}
              onChange={handleSelectionChange}
              value="Field"
              inputProps={{ 'aria-label': 'Field' }}
            />
            <Box>Field</Box>
          </Box>
          <CustomToolTip
            title={allowCLUSelection ? 'Selecting by CLU will let you select between the CLUs you can currently view on the map' : 'Please click "Load Acreage Data" on the previous screen before creating plantings by CLU.'}
            placement="bottom"
            // disableHoverListener={allowCLUSelection}
          >
            <Box display="flex" alignItems="center">
              <Radio
                color="primary"
                checked={selectBy === 'CLU'}
                onChange={handleSelectionChange}
                value="CLU"
                inputProps={{ 'aria-label': 'CLU' }}
                disabled={!allowCLUSelection}
              />
              <Box>CLU</Box>
            </Box>
          </CustomToolTip>
        </Box>
      )}
    </Box>
  );

  const selection = () => (
    <Box display="flex" flexWrap="wrap">
      <Box display="flex" flexDirection="column" justifyContent="space-between">
        { selectionToggle() }

        <Box p={1} mb={0.5}>
          <Box>
            Acres
          </Box>
          <Box className={classes.display}>
            { numFormat(acres) }
          </Box>
        </Box>
      </Box>

      { selectBy === 'Field' ? fieldSelection() : cluSelection() }

    </Box>
  );

  const fieldSelection = () => (
    <Box display="flex" flexDirection="column">
      <Box p={1}>
        <Box>
          Select Organization
        </Box>
        <CustomAutocomplete
          className={classes.select}
          disableClearable
          disabled={loading}
          options={organizations}
          value={selectedOrg}
          getOptionLabel={(option) => option?.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Organization"
            />
          )}
          onChange={(event, value) => handleOrgChange(value)}
        />
      </Box>

      <Fade in={farms?.length > 0}>
        <Box p={1}>
          <Box>
            Select Farm
          </Box>
          <CustomAutocomplete
            className={classes.select}
            disableClearable
            disabled={loading}
            options={farms}
            value={selectedFarm}
            getOptionLabel={(option) => option?.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Select Farm"
              />
            )}
            onChange={(event, value) => handleFarmChange(value)}
          />
        </Box>
      </Fade>
      <Fade in={fields?.length > 0}>
        <Box display="flex" flexWrap="wrap">
          <Box p={1}>
            <Box>
              Select Field
            </Box>
            <CustomAutocomplete
              className={classes.select}
              disableClearable
              filterSelectedOptions
              disabled={loading}
              options={fields}
              value={selectedField}
              getOptionLabel={(option) => option?.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Field"
                />
              )}
              onChange={(event, value) => selectField(value)}
            />
          </Box>
        </Box>
      </Fade>
    </Box>
  );

  const cluSelection = () => (
    <Box display="flex" flexDirection="column" height="274.45px">

      {cluFarms && 
        <Box p={1}>
          <Box>
            Select Farm
          </Box>
          <CustomAutocomplete
            className={classes.select}
            disableClearable
            disabled={loading}
            options={cluFarms}
            value={selectedCluFarm}
            getOptionLabel={(option) => option}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                placeholder="Select Farm"
              />
            )}
            onChange={(event, value) => handleFarmNumberChange(value)}
          />
        </Box>
      }

      {selectedCluFarm && 
        <Fade in={tracts?.length > 0}>
          <Box p={1}>
            <Box>
              Select Tract
            </Box>
            <CustomAutocomplete
              className={classes.select}
              disableClearable
              disabled={loading}
              options={tracts}
              value={selectedTract}
              getOptionLabel={(option) => option}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select Tract"
                />
              )}
              onChange={(event, value) => handleTractChange(value)}
            />
          </Box>
        </Fade>
      }

      {selectedTract && 
        <Fade in={tracts?.length > 0}>
          <Box p={1}>
            <Box>
              Select CLU
            </Box>
            <CustomAutocomplete
              className={classes.select}
              disableClearable
              disabled={loading}
              options={clus}
              value={selectedClu}
              getOptionLabel={(option) => option.clu_number}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                  placeholder="Select CLU"
                />
              )}
              onChange={(event, value) => handleCluChange(value)}
            />
          </Box>
        </Fade>
      }
    </Box>
  );

  const operationInformation = () => (
    <Box pl={1}>
      { selection() }

      <Divider />
      <Box pt={1} display="flex" flexWrap="wrap">
        <Box px={1}>
          <Box>
            Planting Date
          </Box>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.dateSelection}
              disableToolbar
              autoOk
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              inputVariant="outlined"
              value={plantingDate}
              onChange={(date) => setPlantingDate(date)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              inputProps={{
                style: {
                  height: 24.6,
                },
              }}
              InputAdornmentProps={{ position: 'start' }}
            />
          </MuiPickersUtilsProvider>
        </Box>

        { crops.length > 0 && (
          <Box px={1}>
            <Box>
              Crop
            </Box>
            <Select
              className={classes.select}
              variant="outlined"
              MenuProps={MenuProps}
              value={crop}
              onChange={(e) => handleCropChange(e.target.value)}
            >
              {
                crops.map((x) => (
                  <MenuItem
                    key={x.commodityCode}
                    value={x.commodityCode}
                  >
                    {x.displayValue}
                  </MenuItem>
                ))
              }
            </Select>
          </Box>
        )}
      </Box>

      <Box pt={1} display="flex" flexWrap="wrap">

        {cropTypes?.length > 0 && (
          <Box px={1}>
            <Box>
              Crop Type
            </Box>
            <Select
              className={classes.select}
              variant="outlined"
              MenuProps={MenuProps}
              value={cropType}
              onChange={(e) => handleCropTypeChange(crop, e.target.value)}
            >
              { cropTypes.map((x) => (
                <MenuItem
                  key={x.commodityTypeCode}
                  value={x.commodityTypeCode}
                >
                  {x.displayValue}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}

        { intendedUseOptions && (
          <Box px={1}>
            <Box>
              Intended Use
            </Box>
            <Select
              className={classes.select}
              variant="outlined"
              MenuProps={MenuProps}
              value={intendedUse}
              onChange={(e) => setIntendedUse(e.target.value)}
            >
              { intendedUseOptions.map((x) => (
                <MenuItem
                  key={x.intendedUseCode}
                  value={x.intendedUseCode}
                >
                  {x.displayValue}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}
      </Box>

      <Box pt={1} display="flex" flexWrap="wrap">
        { croppingPractice && (
          <Box px={1}>
            <Box>
              Cropping Practice
            </Box>
            <Select
              className={classes.select}
              variant="outlined"
              MenuProps={MenuProps}
              value={croppingPractice}
              onChange={(e) => setCroppingPractice(e.target.value)}
            >
              {cartDropdownData.croppingPractice.options.map((x) => (
                <MenuItem
                  key={x.code}
                  value={x.code}
                >
                  {x.display}
                </MenuItem>
              ))}
            </Select>
          </Box>
        )}

        <Box px={1}>
          <Box>
            Planting Code
          </Box>
          <Select
            className={classes.select}
            displayEmpty
            variant="outlined"
            MenuProps={MenuProps}
            value={productPlantingCode || ''}
            onChange={(e) => setProductPlantingCode(e.target.value)}
          >
            { cartDropdownData.plantingCodes.options.map((x) => (
              <MenuItem
                key={x.code}
                value={x.code}
              >
                {x.display}
              </MenuItem>
            ))}
          </Select>
        </Box>
      </Box>

      <Box pt={1} display="flex" flexWrap="wrap">
        <Box px={1}>
          <Box>
            Organic Status
          </Box>
          <Select
            className={classes.select}
            displayEmpty
            variant="outlined"
            MenuProps={MenuProps}
            value={organic}
            onChange={(e) => setOrganic(e.target.value)}
          >
            <MenuItem value="">
              <em>--Select--</em>
            </MenuItem>
            { cartDropdownData.organicPracticeType.options.map((x) => (
              <MenuItem
                key={x.code}
                value={x.code}
              >
                {x.display}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box px={1}>
          <Box>
            Irrigation Status
          </Box>
          <Box className={classes.radios}>
            <Box display="flex" alignItems="center">
              <Radio
                color="primary"
                checked={irrigated === 'I'}
                onChange={() => setIrrigated('I')}
                value="a"
                name="irrigated-radio-input"
                inputProps={{ 'aria-label': 'I' }}
                style={{ padding: '0 4px' }}
              />
              <Box>Irrigated</Box>
            </Box>
            <Box display="flex" alignItems="center">
              <Radio
                color="primary"
                checked={irrigated === 'N'}
                onChange={() => setIrrigated('N')}
                value="a"
                name="non-irrigated-radio-input"
                inputProps={{ 'aria-label': 'N' }}
                style={{ padding: '0 4px' }}
              />
              <Box>Non-Irrigated</Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  const footer = () => (
    <Box className={classes.footer}>
      {/* For now, just removed it so it doesn't cause issues.
      - In Create Planting, "Upload CLU File" button should use the "Upload CLU" modal... But that would cause issues so maybe just have it use the currently selected uploadCLU modal settings (impersonating and org). But that could also be confusing as CLU could be associated with something (eg. org) that's not loaded in. 
      Best solution I can think of is to ONLY let them do it in the create op modal when they haven't loaded in data yet and can then open CLU modal from there. */}
      {/* This should also be using `import { parseAndUpload } from '../Functionality/uploadClus';` and not `import { handleCluFile } from '../helpers';` as that is the most up-to-date */}
      {/* { !gartPath &&
        <Button
          variant="contained"
          component="label"
          color="primary"
          disableElevation
        >
          Upload CLU File
          <input
            type="file"
            style={{ display: 'none' }}
            multiple
            onChange={(e) => handleFile(e)}
            // accept=".zip"
          />
        </Button>
      } */}

      <Button
        variant="outlined"
        color="primary"
        style={{ margin: '0 25px', backgroundColor: '#ffffff' }}
        onClick={() => setOpenConfirmation(true)}
      >
        Cancel
      </Button>

      <Button
        variant="contained"
        color="primary"
        onClick={() => handleSave()}
        disableElevation
      >
        Save
      </Button>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpenConfirmation(true)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="field-selection-instructions"
      aria-describedby="instructions-to-select-field-boundary-on-map"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
        height={height - 60}
        width={width - 20}
        maxWidth={1800}
        maxHeight={height - 20}
      >
        <Box className={classes.head}>
          <Box p={2}>
            {preventedPlanting ? 'Create Prevented Planting' : 'Create Planting'}
          </Box>

          { loading && <CircularProgress disableShrink/> }

          <HighlightOffIcon
            className={classes.close}
            onClick={() => setOpenConfirmation(true)}
          />
        </Box>

        <Box display="flex" flexWrap="wrap" flexGrow={1}>

          { operationInformation() }

          <Box display="flex" flexGrow={1}>
            <CreateOperation
              selectedOrg={selectedOrg}
              cluBoundaries={cluBoundaries}
              field={selectedField}
              gartPath={gartPath}
              handleBoundaryFromGeoJson={handleBoundaryFromGeoJson}
              handleClus={handleClus}
              impersonating={impersonating}
              operation={operationBoundary}
              setLoading={setLoading}
              updateCluBoundaries={setCluBoundaries}
              updateOperation={updateOperation}
              useOrgCLUData={useOrgCLUData}
              useOwnCLUData={useOwnCLUData}
            />
          </Box>

        </Box>

        { footer() }
        <ConfirmationPopup
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          handleClose={setOpen}
          handleSave={handleSave}
        />
      </Box>
    </Modal>
  );
};


CreateNewOperation.propTypes = {
  addOperation: PropTypes.func.isRequired,
  chosenOrg: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  clusSeen: PropTypes.objectOf(
    PropTypes.shape({
      farm_number: PropTypes.string.isRequired,
      tract_number: PropTypes.string.isRequired,
      clu_number: PropTypes.string.isRequired,
      clu_identifier: PropTypes.string.isRequired,
      cluShape: PropTypes.string.isRequired,
      admin_county: PropTypes.string.isRequired,
      admin_state: PropTypes.string.isRequired,
      cluProducerReviewRequestIndicator: PropTypes.string.isRequired,
    }).isRequired,
  ).isRequired,
  commodityTypesSeen: PropTypes.shape().isRequired,
  commoditiesSeen: PropTypes.shape().isRequired,
  commodityYear: PropTypes.number.isRequired,
  defaultValues: PropTypes.shape({
    crop: PropTypes.string,
    crops: PropTypes.string,
    croppingPracticeCode: PropTypes.number,
    cropType: PropTypes.string,
    cropTypes: PropTypes.string,
    error: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
    intendedUse: PropTypes.string,
    intendedUses: PropTypes.string,
    irrigationPracticeCode: PropTypes.string,
    sharePercentage: PropTypes.number,
  }),
  gartPath: PropTypes.bool.isRequired,
  getCommodityTypes: PropTypes.func.isRequired,
  getIntendedUses: PropTypes.func.isRequired,
  handleNonCLUBoundary: PropTypes.func.isRequired,
  impersonating: PropTypes.string.isRequired,
  includeNonCLUBoundaries: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  preventedPlanting: PropTypes.bool,
  setClusSeen: PropTypes.func.isRequired,
  setCommoditiesSeen: PropTypes.func.isRequired,
  setCommodityTypesSeen: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  statesSeen: PropTypes.shape({
    stateAbbr: PropTypes.shape(),
  }).isRequired,
  useOrgCLUData: PropTypes.bool.isRequired,
  useOwnCLUData: PropTypes.string.isRequired,
  viewingField: PropTypes.shape({
    countyCode: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    farmId: PropTypes.string.isRequired,
    farmName: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    orgId: PropTypes.number.isRequired,
    stateCode: PropTypes.string.isRequired,
  }),
};

CreateNewOperation.defaultProps = {
  preventedPlanting: false,
};

import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Button, Link, Divider,
} from '@material-ui/core';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import YouTube from 'react-youtube';
import { Endpoints } from '../../constants/Endpoints';
import { green, lightGrey } from '../../styles/colors';
import { connect } from '../Partners/Connect';
import { CustomToolTip } from '../../utils/customComponents';
import arcImage from '../../images/arcGIS.png';
import soilInfographic from '../../images/soiltesting_infographic.png';
import digsInfographic from '../../images/digs_infographic.png';
import ARInfo from '../../images/AR_IPAD_New.png'
import { useWindowDimensions } from '../../utils/dimensions';

const useStyles = makeStyles((theme) => ({
  service: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.primary,
    border: `1px solid ${theme.palette.greys.main}`,
    borderRadius: 8,
    margin: 8,
    padding: 8,
    backgroundColor: '#ffffff',
  },
  buttonLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
  },
  containedButtonLink: {
    color: '#ffffff',
    textDecoration: 'none',
  },
  dot: {
    fontSize: 8,
    margin: '8px 4px',
    color: theme.palette.text.primary,
  },
  link: {
    color: theme.palette.secondary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.secondary.dark,
    },
  },
  info: theme.infoToolTip,
  infographic: {
    border: '1px solid #2f5597',
    borderRadius: 4,
    padding: 4,
  },
  divider: {
    height: 2,
    backgroundColor: theme.palette.greys.dark,
    marginBottom: 4,
  },
}));

/**
 * Render services components. Currently includes ESRI Add-in, DIGS,
 * UAV, and Soil Testing.
 * @param {Function} setSection Used for navbar hightlighting
 * @returns {JSX} Service page
 */
export function Services({ setSection }) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  useEffect(() => {
    setSection(3);
  }, []);

  const uavPrices = [
    { acres: '0-60 acres', price: '$210' },
    { acres: '60-200 acres', price: '$3.5' },
    { acres: '201-350 acres', price: '$3' },
    { acres: '351-400 acres', price: '$2.8' },
    { acres: '401-500 acres', price: '$2.6' },
    { acres: '500+ acres', price: '$2.4' },
  ];

  const waterManagement = () => (
    <Box
      className={classes.service}
      maxWidth={width > 640 ? 600 : width - 10}
    >
      <Box>
        <Box style={{ padding: width > 640 ? '0 80px 8px' : '0 0 8px' }}>
          <Typography align="center" style={{ fontWeight: 500, fontSize: 24 }}>
            Drainage Water Management Consulting & Engineering
          </Typography>
        </Box>

        <Divider className={classes.divider} />

        <Box display="flex" justifyContent="center">
          <img src={digsInfographic} style={{ height: 'auto', width: '96%' }} alt="soil testing info graphic" />
        </Box>

        <Box mt={1} py={1} display="flex" justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            target="_blank"
            size="large"
            href={`${Endpoints.HOME}/app/WaterManagement`}
            disableElevation
          >
            <span className={classes.containedButtonLink}>
              Get Water Management Service
            </span>
          </Button>
        </Box>

        <Box>
          <Box fontSize={18}>
            Services Offered:
          </Box>

          <Box ml={1}>
            <Box display="flex" style={{ marginTop: 8 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Tile Drainage Design and Cost Estimates
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 8 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Elevations and contours determined using DIGS topography
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 8 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Identification of outlets and neighboring watershed stakeholders
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 8 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Remediation Response Analysis
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 8 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Professional design and project implementation
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 8 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Farm-specific full-scale irrigation design
              </Typography>
            </Box>
          </Box>
        </Box>

        <Box mt={1.5} py={1}>
          <Typography variant="body1">
            Complete and place your order by filling in your contact information. An engineer will typically contact you within 1-2 days to confirm your order and plan a process of action. After completion of the service, all results, maps, and reports will be distributed to the customer through the Ag-Analytics site. For any other questions, please
            {' '}
            <a className={classes.link} target="_blank" rel="noreferrer" href="https://www.analytics.ag/contact">contact us</a>
            {' '}
            at support@analytics.ag.
          </Typography>
        </Box>
      </Box>

      <Box py={1} display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="outlined"
          target="_blank"
          href={`${Endpoints.HOME}/app/watermanagement/results`}
          size="large"
          disableElevation
        >
          <span className={classes.buttonLink}>
            View Completed Reports
          </span>
        </Button>
      </Box>

    </Box>
  );

  const soilTesting = () => (
    <Box
      className={classes.service}
      maxWidth={width > 640 ? 600 : width - 10}
    >
      <Box>
        <Box style={{ padding: width > 640 ? '0 80px 8px' : '0 0 8px' }}>
          <Typography align="center" style={{ fontWeight: 500, fontSize: 24 }}>
            Soil Testing Solutions
          </Typography>
        </Box>

        <Divider className={classes.divider} />

        <Box display="flex" justifyContent="center">
          <img src={soilInfographic} style={{ height: 'auto', width: '96%' }} alt="soil testing info graphic" />
        </Box>

        <Box mt={1} py={1} display="flex" justifyContent="center">
          <Button
            color="primary"
            variant="contained"
            target="_blank"
            size="large"
            href={`${Endpoints.HOME}/app/soil`}
            disableElevation
          >
            <span className={classes.containedButtonLink}>
              Get Soil Testing Service
            </span>
          </Button>
        </Box>

        <Box>
          <Box fontSize={18}>
            Services Offered:
          </Box>

          <Box ml={1}>
            <Box display="flex" style={{ marginTop: 6 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Professional collection of soil samples from your field.
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 6 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Laboratory soil tests and fertility analysis based on best management practices from the region. Customers can select Basic or Advanced Fertility tests and analysis. Results are generated and returned as data tables.
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 6 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Yield-based management zones created by combining your declared 2.5-acre or 5-acre sampling grids with your latest yield maps (provided by the customer) and bare-earth imagery.
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 6 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                A collection of maps representing the results returned by the Lab Fertility Analysis.
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 6 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Visual analysis of limiting nutrients and overall nutrient performance, which combines your yield maps (provided by the customer) and Lab Fertility Analysis results.
              </Typography>
            </Box>
            <Box display="flex" style={{ marginTop: 6 }}>
              <FiberManualRecordRoundedIcon className={classes.dot} />
              <Typography style={{ fontSize: '1.1rem' }}>
                Professional design and project implementation
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box py={1} display="flex" justifyContent="center">
        <Button
          color="primary"
          variant="outlined"
          target="_blank"
          href={`${Endpoints.HOME}/app/soil/results`}
          size="large"
          disableElevation
        >
          <span className={classes.buttonLink}>
            View Completed Reports
          </span>
        </Button>
      </Box>

    </Box>
  );

  const arcGIS = () => (
    <Box
      className={classes.service}
      maxWidth={width > 640 ? 600 : width - 10}
    >
      <Box>
        <Box>
          <Box pb={1}>
            <Typography align="center" style={{ fontWeight: 500, fontSize: 24 }}>
              ESRI ArcGIS Pro Add-In
            </Typography>
          </Box>

          <Divider className={classes.divider} />
        </Box>

        <Box p={1} display="flex" justifyContent="center">
          <img src={arcImage} style={{ height: 60 }} alt="ArcGIS pro logo" />
        </Box>

        <Box display="flex" style={{ marginTop: 2 }}>
          <FiberManualRecordRoundedIcon className={classes.dot} />
          <Typography>
            Analyze your precision ag data and field profits in ArcGIS Pro using the
            {' '}
            <b>
              PROFIT LAYERS
              <sup>&reg;</sup>
            </b>
            {' '}
            custom profit mapping system
          </Typography>
        </Box>

        <Box display="flex" style={{ marginTop: 2 }}>
          <FiberManualRecordRoundedIcon className={classes.dot} />
          <Typography>
            Automatically download, view, and manage your integrated precision ag data layers directly in ArcGIS Pro
          </Typography>
        </Box>

        <Box display="flex" style={{ marginTop: 2 }}>
          <FiberManualRecordRoundedIcon className={classes.dot} />
          <Typography>
            <b>ADAPT</b>
            {' '}
            Precision Data to Shapefile Conversion
          </Typography>
        </Box>

        <Box display="flex" style={{ marginTop: 2 }}>
          <FiberManualRecordRoundedIcon className={classes.dot} />
          <Typography>
            No additional charge for existing ArcGIS PRO users to connect to their
            {' '}
            <b>
              PROFIT LAYERS
              <sup>&reg;</sup>
            </b>
            {' '}
            <Link className={classes.link} href={`${Endpoints.HOME}/app/purchase`} target="_blank">subscription</Link>
            <CustomToolTip
              title="Don't have a Profit Layers subscription? Click the link to subscribe with a 30 day money back guarantee!"
              placement="top"
            >
              <InfoOutlinedIcon
                className={classes.info}
                fontSize="small"
              />
            </CustomToolTip>
          </Typography>

        </Box>

        <Box display="flex" style={{ marginTop: 2 }}>
          <FiberManualRecordRoundedIcon className={classes.dot} />
          <Typography>
            Don&#39;t have ArcGIS? Save
            {' '}
            <b>$200</b>
            {' '}
            when you bundle
            {' '}
            <b>ArcGIS Pro</b>
            {' '}
            with
            {' '}
            <b>
              PROFIT LAYERS
              <sup>&reg;</sup>
            </b>
          </Typography>
        </Box>

        <Box py={0.5} display="flex" justifyContent="center" flexWrap="wrap">
          <Button
            color="primary"
            variant="contained"
            target="_blank"
            href={`${Endpoints.HOME}/app/Esri/purchase`}
            style={{ margin: '4px 8px' }}
            size="large"
            disableElevation
          >
            <span className={classes.containedButtonLink}>
              Get Premium
            </span>
          </Button>

          <Button
            color="primary"
            variant="contained"
            target="_blank"
            href="https://www.analytics.ag/contact"
            size="large"
            style={{ margin: '4px 8px' }}
            disableElevation
          >
            <span className={classes.containedButtonLink}>
              Contact for Bundle
            </span>
          </Button>
        </Box>
      </Box>

      <Box>
        <Box p={1} display="flex" justifyContent="center">
          <YouTube
            opts={{
              width: width > 420 ? 400 : width - 40,
            }}
            videoId="UnbUPY3JDYw"
          />
        </Box>

        <Box py={0.5} display="flex" justifyContent="center">
          <Button
            color="primary"
            variant="outlined"
            target="_blank"
            href="https://www.profit.ag/esri"
            style={{ margin: '4px 8px' }}
            size="large"
            disableElevation
          >
            <span className={classes.buttonLink}>
              Learn More
            </span>
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const uav = () => (
    <Box
      className={classes.service}
      maxWidth={width > 640 ? 600 : width - 10}
    >
      <Box>
        <Box>
          <Box pb={1}>
            <Typography align="center" style={{ fontWeight: 500, fontSize: 24 }}>
              On-Demand UAV Service
            </Typography>
          </Box>

          <Divider className={classes.divider} />
        </Box>

        <Typography style={{ margin: 4, fontSize: '1.1rem' }}>
          On-Demand high-quality imagery and accurate diagnostics reports. A pilot will typically contact you within 1-2 days to schedule and confirm your flight, with final images delivered in as little as 3-6 days
        </Typography>

        <Typography variant="subtitle1" style={{ fontWeight: 400 }}>
          Please note that we currently only service select areas in the United States.
        </Typography>

        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          {
            uavPrices.map((x, i) => (
              <Box
                key={x.acres}
                m={0.5}
                border={2}
                borderColor={green}
                borderRadius={6}
                width={140}
              >
                <Box
                  p={0.5}
                  display="flex"
                  justifyContent="center"
                  alignItems="center"
                  color="white"
                  fontSize={18}
                  style={{ backgroundColor: green, borderTopRightRadius: 3, borderTopLeftRadius: 3 }}
                >
                  {x.acres}
                </Box>

                <Typography align="center" style={{ fontWeight: 600, fontSize: 16, padding: '8px 4px' }}>
                  {x.price}
                  {i !== 0 && <span style={{ fontWeight: 500, fontSize: 14 }}>/Acre</span>}
                </Typography>
              </Box>
            ))
          }
        </Box>

        <Box display="flex" justifyContent="center">
          <Button
            variant="contained"
            color="primary"
            style={{ margin: '4px' }}
            size="large"
            target="_blank"
            href={`${Endpoints.HOME}/app/uav`}
            disableElevation
          >
            <span className={classes.containedButtonLink}>
              get On-Demand uav service
            </span>
          </Button>
        </Box>

        <Box style={{ marginTop: 2 }}>
          <Typography>
            <Link className={classes.link} href="https://www.analytics.ag/contact">Contact us</Link>
            {' '}
            for
            pricing on flights over 500 acres, discounted pricing for frequent flights and
            subscription services, or requests regarding hardware or sensors.
          </Typography>
        </Box>
      </Box>

      <Box>
        <Box p={1} display="flex" justifyContent="center" >
          <YouTube
            opts={{
              width: width > 420 ? 400 : width - 40,
            }}
            videoId="wco8S4KoeBY"
          />
        </Box>

        <Box textAlign="center">
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: '4px' }}
            target="_blank"
            href={`${Endpoints.HOME}/app/uav/reports`}
            size="large"
            disableElevation
          >
            <span className={classes.buttonLink}>
              View Completed Reports
            </span>
          </Button>
        </Box>
      </Box>
    </Box>
  );

  const pars = () => (
    <Box
      className={classes.service}
      maxWidth={width > 640 ? 600 : width - 10}
    >
      <Box>
        <Box>
          <Box pb={1}>
            <Typography align="center" style={{ fontWeight: 500, fontSize: 24 }}>
              Precision Acreage Reporting System (PARS™)
            </Typography>
          </Box>

          <Divider className={classes.divider} />
        </Box>

        <Box p={1} display="flex" flexDirection="column" justifyContent="center" alignItems="center">
          <img src={ARInfo} alt="Acreage Reporting Info" style={{width:'100%', marginBottom:"10px"}}/>
          <Typography variant='body1' style={{ fontSize: '1.1rem', fontWeight: 500 }}> 
            Free for a limited time
          </Typography>
        </Box>

        <Box mt={1.5} py={1}>
          <Typography variant="body1" style={{ fontSize: '1.1rem' }}>
            The Precision Acreage Reporting System (PARS™) automatically associates integrated or uploaded precision ag data with CLU boundaries to simplify your acreage reporting process. Easily create and submit acreage reports for federal crop insurance directly to the USDA Clearinghouse, for distribution to FSA and your AIP.
          </Typography>
        </Box>

        <Box display="flex" style={{ marginTop: 10 }}>
          <FiberManualRecordRoundedIcon className={classes.dot} />
          <Typography style={{ fontSize: '1.1rem' }}>
            Load your precision data quickly using Climate FieldView, John Deere Operations Center, and CNH
          </Typography>
        </Box>

        <Box display="flex" style={{ marginTop: 6 }}>
          <FiberManualRecordRoundedIcon className={classes.dot} />
          <Typography style={{ fontSize: '1.1rem' }}>
            Review the information associated with each CLU and easily update the data
          </Typography>
        </Box>

        <Box display="flex" style={{ marginTop: 6 }}>
          <FiberManualRecordRoundedIcon className={classes.dot} />
          <Typography style={{ fontSize: '1.1rem' }}>
            Submit the acreage report data directly to AIPs or the FCIC
          </Typography>
        </Box>

      </Box>
      <Box 
          py={1} 
          display="flex" 
          justifyContent="center" 
          flexWrap="wrap">

          <Button
            color="primary"
            variant="contained"
            target="_blank"
            href={`${Endpoints.HOME}/app/AcreageReporting`}
            style={{ margin: '4px 8px' }}
            size="large"
            disableElevation
          >
            <span className={classes.containedButtonLink}>
              Try PARS™
            </span>
          </Button>

          <Button
            color="primary"
            variant="contained"
            target="_blank"
            href={"https://www.profit.ag/acreagereporting"}
            style={{ margin: '4px 8px' }}
            size="large"
            disableElevation
          >
            <span className={classes.containedButtonLink}>
              Learn More
            </span>
          </Button>

        </Box>

    </Box>
  )

  return (
    <Box
      py={2}
      display="flex"
      flexGrow={1}
      style={{ height: '100%', backgroundColor: lightGrey, overflow: 'auto' }}
    >
      <Box
        px={2}
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
        style={{ width: '100%' }}
      >
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          { pars() }
          { arcGIS() }
          { waterManagement() }
          { soilTesting() }
          { uav() }
        </Box>

        <Box
          borderRadius="borderRadius"
          style={{ backgroundColor: '#ffffff' }}
        >
          {connect()}
        </Box>
      </Box>
    </Box>
  );
}

Services.propTypes = {
  setSection: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Divider,
  TextField,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import { darkGrey, grey, blackText } from '../../../styles/colors';
import { numFormat } from '../../../utils/helpers';

const greyBlue = '#006ba1';

const useStyles = makeStyles((theme) => ({
  divider: {
    backgroundColor: greyBlue,
    height: '2px',
  },
  cart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 4px',
    padding: '0 8px',
    minWidth: '400px',
    minHeight: '250px',
    backgroundColor: '#ffffff',
    fontSize: 18,
    border: `1px solid ${theme.palette.greys.dark}`,
    borderRadius: 4,
  },
  remove: {
    fontSize: '12px',
    marginLeft: '5px',
    maringBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

/**
 * DIGS order cart
 * @param {Array} order Selected services and bundles
 * @param {Bool} includesFields Determines if fields are includes in order
 * @param {Function} getTotal Pass total up to Checkout component
 * @param {Function} remove Remove services from order
 * @param {Function} getAcres For orders without fields, pass set acres to parent
 * @returns {JSX} Order cart
 */
export const Cart = ({
  order,
  getTotal,
  remove,
  includesFields,
  getAcres,
  ads,
  discount
}) => {
  const classes = useStyles();
  const [total, setTotal] = useState(0);
  const [acres, setAcres] = useState(1);
  const [finalPrice, setFinalPrice] = useState(0);

  useEffect(() => {
    calculateTotal();
    if(order.length == 0){
      setTotal(0)
      setFinalPrice(0)
    }
  }, [order, acres]);

  useEffect(() => {
    if(ads && total>0){
  //  console.log(total)
      setFinalPrice(total-discount)
    }
  },[ads, total, discount])

  const calculateTotal = () => {
    let orderTotal = 0;

    if (includesFields) {
      order.forEach((field) => {
        const prices = field.reports.map((x) => x.price);
        const fieldTotal = prices.reduce((a, b) => a + b, 0);
        orderTotal += fieldTotal * field.acres;
      });
    } else {
      order.forEach((service) => {
        orderTotal += service.price * acres;
      });
    }

    setTotal(orderTotal);

    if (getTotal) {
      // if total needs to be passed up to parent
      getTotal(orderTotal);
    }
  };

  const updateAcres = (val) => {
    // For orders with no field
    const value = !Number.isNaN(val) ? val : '';
    setAcres(value);
    getAcres(value);
  };

  const displayOrder = (field, i) => (
    <Box key={i} px={2} color={blackText} fontWeight={500}>
      <Box mt={1} display="flex" justifyContent="space-between">
        <Box>
          <Box fontSize={16}>{field.name}</Box>
          <Box fontSize={12}>
            {'Acres: '}
            {numFormat(field.acres)}
          </Box>
        </Box>
      </Box>

      {field.reports.map((report, j) => (
        <Box key={j}>
          <Box
            pt={1}
            px={1}
            display="flex"
            justifyContent="space-between"
            flexGrow={1}
            fontSize="1rem"
          >
            <Box style={{ width: '60%' }}>
              {report.name}
            </Box>

            <Box
              pl={1}
              display="flex"
              justifyContent="flex-end"
              style={{ width: '40%' }}
            >
              <Box style={{ width: '40%' }}>
                {'$'}
                {report.price}
              </Box>
              <Box style={{ width: '55%' }}>
                {'$'}
                {numFormat(report.price * field.acres)}
              </Box>
              <Box style={{ width: '5%' }}>
                <ClearIcon
                  className={classes.remove}
                  onClick={() => remove(i, j, report)}
                />
              </Box>
            </Box>

          </Box>
          <Divider style={{ backgroundColor: grey }} />
        </Box>
      ))}
    </Box>
  );

  // Order format with no fields
 
  const simpleDisplay = (report, i) => (
    <Box key={i} px={2} color={blackText} fontWeight={500}>
      <Box mt={1} display="flex" justifyContent="space-between">
        <Box
          pt={1}
          px={1}
          display="flex"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Box style={{ width: '60%' }}>
            {report.name}
          </Box>

          <Box
            pl={1}
            display="flex"
            justifyContent="flex-end"
            style={{ width: '40%' }}
          >
            <Box style={{ width: '40%' }}>
              $
              {report.price}
            </Box>
            <Box style={{ width: '55%' }}>
              $
              { Number.isNaN(acres) ? report.price : numFormat(report.price * acres) }
            </Box>
            <Box style={{ width: '5%' }}>
              <ClearIcon
                className={classes.remove}
                onClick={() => remove(report)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

  return (
    <Box className={classes.cart}>
      <Box>
        <Typography
          variant="h5"
          align="center"
          style={{ margin: '8px 0', color: darkGrey }}
        >
          Your No Obligation Order
        </Typography>

        <Box
          pt={1}
          px={1}
          display="flex"
          justifyContent="space-between"
          flexGrow={1}
          fontSize={14}
        >
          <Box style={{ width: '60%', paddingLeft: '15px' }}>
            {
              !includesFields ? 'Services' : ''
            }
          </Box>

          <Box
            display="flex"
            justifyContent="flex-end"
            style={{ width: '40%' }}
            fontWeight={500}
          >
            <Box style={{ width: '40%' }}>
              $/Acre
            </Box>
            <Box style={{ width: '55%' }}>
              {' Total'}
            </Box>
            <Box style={{ width: '5%' }} />
          </Box>
        </Box>

        <Divider className={classes.divider} variant="middle" />

        {
          includesFields ? order.map((field, i) => displayOrder(field, i, remove))
            : order.map((report, i) => simpleDisplay(report, i))
        }

      </Box>

      <Box p={2} fontWeight={500} fontSize={18}>
        <Divider className={classes.divider} style={{ margin: '10px 0' }} />
        <Box display="flex">
          {
            !includesFields
            && (
            <Box display="flex" alignItems="center">
              {'Acres: '}
              <TextField
                style={{ width: '100px', marginLeft: '3px' }}
                variant="outlined"
                type="number"
                inputProps={{
                  style: {
                    padding: 7,
                  },
                }}
                InputLabelProps={{
                  shrink: true,
                }}
                value={acres}
                onChange={(e) => updateAcres(e.target.value)}
              />
            </Box>
            )
          }

          {ads ?
            <Box 
              display="flex" 
              flexGrow={1} 
              justifyContent="flex-end"
              flexDirection="column"
              >
              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                {'Discount (USD): -$'}
                {numFormat(discount)}
              </Box>
              <Box display="flex" flexGrow={1} justifyContent="flex-end">
                {'Total (USD): $'}
                {numFormat(finalPrice)}
              </Box>
            </Box>
            :
            <Box display="flex" flexGrow={1} justifyContent="flex-end">
              {'Total (USD): $'}
              {numFormat(total)}
            </Box>
          }
          
        </Box>

      </Box>

    </Box>
  );
};

Cart.propTypes = {
  order: PropTypes.array.isRequired,
  includesFields: PropTypes.bool.isRequired,
  getTotal: PropTypes.func.isRequired,
  remove: PropTypes.func.isRequired,
  getAcres: PropTypes.func.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import numberToWords from "number-to-words";
import { startCase } from "lodash";
import CustomSelectAlternate from "../../../Helpers/CustomSelectAlternate";

const useStyles = makeStyles((theme) => ({
  wrapper: {},
  desc: ({ isSmallScreen }) => ({
    width: isSmallScreen ? "80%" : "100%",
  }),
  selects: ({ isSmallScreen }) => ({
    width: isSmallScreen ? "80%" : "100%",
    display: "flex",
    flexDirection: isSmallScreen ? "column" : "row",
    flexWrap: "wrap",
    justifyContent: "space-between",
    "& > *": {
      flex: `0 0 calc(50% - ${theme.spacing(4) / 2}px)`,
    },
  }),
  select: {},
}));

/**
 * Handles the four selects in the advanced controls.
 * @param {array} selectedColumns array of objects with info for each select {id: string, active: bool, disabled: bool}
 * @param {func} setSelectedColumns set state function
 * @param {boolean} isSmallScreen  boolean for mobile views
 */

const ColumnSelects = ({
  selectedColumns,
  setSelectedColumns,
  isSmallScreen,
}) => {
  const classes = useStyles({ isSmallScreen });

  const handleSelectChange = (e, index) => {
    const newSelectedColumns = selectedColumns.map((selectGroup, i) => {
      if (i === index) {
        return selectGroup.map((select) =>
          select.id === e.target.value
            ? { ...select, active: true }
            : { ...select, active: false }
        );
      }
      return selectGroup;
    });

    const trueSelects = newSelectedColumns
      .map((selectGroup) => selectGroup.find((select) => select.active))
      .map((item) => item.id);

    const newDisabledSelectedColumns = newSelectedColumns.map(
      (selectGroup, i) => {
        if (i !== index) {
          return selectGroup.map((select) =>
            select.id === e.target.value
              ? select.id === "None selected"
                ? select
                : { ...select, disabled: true }
              : trueSelects.includes(select.id)
              ? select
              : { ...select, disabled: false }
          );
        }
        return selectGroup;
      }
    );
    setSelectedColumns(newDisabledSelectedColumns);
  };

  // using alternate version of CustomSelect to pass in unique
  // OnChange function to handle a nested state array.
  return (
    <div className={classes.wrapper}>
      <p className={classes.desc}>
        Switch out the variables presented in the Overview Table. You can select
        up to four.
      </p>
      <div className={classes.selects}>
        {selectedColumns.map((select, i) => (
          <CustomSelectAlternate
            key={`select-option-for-group-${i}`}
            label={`${startCase(numberToWords.toWordsOrdinal(i + 1))} Variable`}
            options={select}
            handleChange={handleSelectChange}
            isFlush
            isFullWidth
            index={i}
          />
        ))}
      </div>
    </div>
  );
};

export default ColumnSelects;

ColumnSelects.propTypes = {
  selectedColumns: PropTypes.array.isRequired,
  setSelectedColumns: PropTypes.func.isRequired,
  isSmallScreen: PropTypes.bool.isRequired,
};

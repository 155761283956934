import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Menu, MenuItem, Tooltip } from "@material-ui/core";
import FiberManualRecordRoundedIcon from "@material-ui/icons/FiberManualRecordRounded";
import RadioButtonUncheckedOutlinedIcon from "@material-ui/icons/RadioButtonUncheckedOutlined";
import { green, blackText, lightGrey } from '../../../styles/colors'

/**
 * Year selection with ability to select on or multiple years
 * @param {Bool} open Open menu
 * @param {Function} setOpen Determines if menu is open
 * @param {Array} years Year to include in menu
 * @param {Array} selectedYears Years that have been selected
 * @param {Function} selectMultipleYears Add year to selected array and leave menu open
 * @param {Function} handleYearSelect Select year and close menu
 * @param {Function} handleMultiYearSelect One menu close, get data for selected year
 */
export function YearSelection({
  open,
  setOpen,
  years,
  selectedYears,
  selectMultipleYears,
  handleYearSelect,
  handleMultiYearSelect,
}) {

  return (
    <Menu
      anchorEl={open}
      keepMounted
      open={Boolean(open)}
      onClose={() => {
        setOpen(null);
        handleMultiYearSelect();
      }}
      style={{ margin: "44px 0 0 20px", padding: 0 }}
    >
      {years.map((year, i) => (
        <MenuItem
          key={i}
          style={{
            padding: "5px 0",
            borderRadius: "3px",
            fontWeight: 500,
            color: selectedYears.includes(year) ? green : blackText,
            backgroundColor: selectedYears.includes(year)
              ? "#ffffff"
              : lightGrey,
          }}
        >
          <Box
            display="flex"
            alignItems="center"
            justifyContent="space-between"
          >
            <Tooltip
              title="Click icon to select multiple years"
              placement="right"
            >
              <Box
                style={{width: '25px', padding: '0 5px'}}
                onClick={() => selectMultipleYears(year)}
              >
                {selectedYears.includes(year) ? (
                  <FiberManualRecordRoundedIcon
                    style={{ color: green, fontSize: 18 }}

                  />
                ) : (
                  <RadioButtonUncheckedOutlinedIcon
                    style={{ color: blackText, fontSize: 18 }}
                  />
                )}
              </Box>
            </Tooltip>

            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ width: "80px", padding: "0 20px" }}
              onClick={() => {
                setOpen(null);
                handleYearSelect(year);
              }}
            >
              {year}
            </Box>
          </Box>
        </MenuItem>
      ))}
    </Menu>
  )
}

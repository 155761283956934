import React, { useEffect, useState, useContext, Fragment } from 'react'
import {
  AppBar,
  Box,
  Button,
  Typography,
  Divider,
  Select,
  MenuItem,
  TextField,
  Slide,
  Modal
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete'
import { MenuProps } from "../../styles/select";
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { FieldContext } from '../Context/FieldContext'
import { UserContext } from "../Context/UserContext";
import { useWindowDimensions } from '../../utils/dimensions'
import { FieldSelection } from "../FieldSelection/FieldSelection";
import { useSnackbar } from "notistack";
//  import { Steps } from './Navigation/steps'
//  import { Footer } from './Navigation/footer'
import { lightGrey, green, blackText, grey } from '../../styles/colors'
import { ComingSoon } from '../Home/ComingSoon';
import * as mf from '../Maps/MapFunctions/helpers'
import * as df from '../../utils/dataFetchers'
import * as turf from "@turf/turf";
import { cloneDeep, set } from "lodash";
import { LandForecastTable } from './LandForecastTable'
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { ParcelSalesTable } from './ParcelSalesTable'
import { LoanSummary } from './LoanSummary'
import { eachWeekendOfInterval } from 'date-fns/fp';
import { scrollTo, dollarFormat } from '../../utils/helpers'


const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  appBar: {
    ...theme.appBar,
    // justifyContent: "space-between",
    justifyContent: "center",
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between'
  },
  selections: {
    height: 40,
    minWidth: 50,
  },
  filterSelect: {
    height: 40,
    minWidth: 200,
  },
  select: {
    margin: '1px 0',
    padding: "8px 10px",
    borderRadius: "5px",
    color: blackText,
    fontWeight: 600,
    "&:hover": {
      cursor: "pointer",
      backgroundColor: '#f8f8ff',
      color: '#1B1B1B',
    },
  },
  selected: {
    margin: '1px 0',
    padding: "8px 10px",
    borderRadius: "5px",
    color: green,
    fontWeight: 600,
    backgroundColor: '#ffffff',
    "&:hover": {
      cursor: "pointer",
    },
  },
  layers: {
    display: 'flex',
    width: '100%',
  },
  label: theme.label,
  connectButton: {
    width: 180,
    margin: '6px 0',
    backgroundColor: '#f8f8ff',
    "&:hover": {
      backgroundColor: '#ffffff',
    },
  },
  dropDown: {
    height: 40,
    minWidth: 50,
  },
  dateSelection: theme.dateSelection,
  itemTables: {
    position: 'absolute',
    zIndex: 5001,
    right: '0%',
    top: '20%'
  },
  postClaimModalBox: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
}))

function getModalStyle() {
  return {
    margin: 'auto',
    zIndex: 1002,
    height: '22%',
    padding: 1,
    backgroundColor: 'white'
  };
}

const radiusOptions = [{ name: '5 mi', value: 5 }, { name: '10 mi', value: 10 }, { name: '25 mi', value: 25 }, { name: '50 mi', value: 50 }, { name: '100 mi', value: 100 }, { name: '200 mi', value: 200 }]
const statusOptions = ["Sold", "For Sale"]

/**
 * Loan Underwriting Main Component
 * This renders the FieldSelection and tables and makes the datafetcher calls to populate the tables
 */
export function LoanUnderwriting({ }) {

  const mobileBreakPoint = 860
  const classes = useStyles()
  const modalStyle = getModalStyle()
  const [user, setUser] = useContext(UserContext);
  const [fieldData, setFieldData] = useContext(FieldContext);

  const { enqueueSnackbar } = useSnackbar();
  const { height, width } = useWindowDimensions()

  const [step, setStep] = useState(0); //field select or DL
  const [hideFieldSelection, setHideFieldSelection] = useState(false);
  const [field, setField] = useState({ id: '', latitude: '', longitude: '' });

  const [clientKey, setClientKey] = useState("");
  const [clientName, setClientName] = useState("")

  const [loanID, setLoanID] = useState(null)
  const [reports, setReports] = useState([])
  const [documents, setDocuments] = useState([])

  const [displayInputs, setDisplayInputs] = useState(false);

  const [postClaim, setPostClaim] = useState(false);

  const [selectedRadius, setSelectedRadius] = useState(radiusOptions[0])
  const [selectedSaleStatus, setSelectedSaleStatus] = useState(statusOptions[0])

  const [landValueForecast, setLandValueForecast] = useState(null)

  const [summaryLoading, setSummaryLoading] = useState(false)
  const [parcelSummaries, setParcelSummaries] = useState([])
  const [filteredSummaries, setFilteredSummaries] = useState([])
  const [summaryAverage, setSummaryAverage] = useState(null); //should this be the overall total? or the total of filtered?

  const [summaryDocuments, setSummaryDocuments] = useState([])
  const [documentFilter, setDocumentFilter] = useState("")
  const [summaryReports, setSummaryReports] = useState([])
  const [reportFilter, setReportFilter] = useState("")

  const today = new Date();
  let year = today.getFullYear();
  let month = today.getMonth();
  let day = today.getDate();

  let sales = new Date();
  sales.setMonth(sales.getMonth() - 6)

  const [startDate, setStartDate] = useState(new Date(year - 1, month, day).toISOString());
  const [endDate, setEndDate] = useState(new Date().toISOString());
  const [saleDate, setSaleDate] = useState(sales.toISOString());

  const [parcels, setParcels] = useState(null);
  const [displayParcels, setDisplayParcels] = useState(false);
  const [activeParcel, setActiveParcel] = useState({})

  useEffect(() => {
    if (user.token && user.organizations === undefined) {
      // console.log("Getting user info from token")
      getUserInfo();
    }
  }, [user]);

  useEffect(() => {
    if (loanID !== null) {
      //get parcel summary values
      getParcelSummaries();
      //on loanID change reset parcel and selected field info
      setDisplayParcels(false);
      setParcels(null);
      setLandValueForecast(null);
    }
  }, [loanID])

  useEffect(() => { console.log("Reports", reports) }, [reports])
  useEffect(() => { console.log("documents", documents) }, [documents])

  useEffect(() => {
    if (loanID !== null) {
      //get parcel summary values
      getParcelSummaries()
    }
  }, [landValueForecast])

  useEffect(() => {
    //load in the parcel info when selected field changes
    if (fieldData.selectedField.id !== '' && (fieldData.claimedFlag === undefined || !fieldData.claimedFlag)) {
      setDisplayInputs(true)
      if (user.isAuthenticated) {
        getParcels(selectedRadius.value, selectedSaleStatus, startDate, endDate, saleDate)
        getComps(saleDate)
      }
    }
    if (fieldData.selectedField.org !== '' && fieldData.selectedField.org !== loanID) {
      setLoanID(fieldData.selectedField.org)
    }
    if (fieldData.selectedField.id !== '' && fieldData.claimedFlag !== undefined && fieldData.claimedFlag) {
      setPostClaim(true)
    }

    console.log(fieldData.selectedField)
  }, [fieldData.selectedField, user])

  useEffect(() => {
    if (fieldData.selectedField.orgId) {
      getDocuments(fieldData.selectedField.orgId)
      getReports(fieldData.selectedField.orgId)
    }
  }, [fieldData.selectedField.orgId])

  useEffect(() => {
    if (step === 1) {
      if (fieldData.activeParcel) {
        let updatedField = { ...fieldData }
        updatedField.activeParcel.index = -1
        setFieldData(updatedField)
      }

      setDisplayInputs(false)
    }
    else if (step == 0 && fieldData.selectedField.id !== '') {
      setDisplayInputs(true)
    }
  }, [step])

  useEffect(() => {
    let filtered = [...parcelSummaries];
    if (reportFilter !== "") {
      filtered = filtered.filter(x => x.ReportName === reportFilter)
      console.log("filter report", reportFilter, filtered)
    }
    if (documentFilter !== "") {
      filtered = filtered.filter(x => x.DocumentID === documentFilter)
      console.log("filter document", documentFilter, filtered)
    }
    setFilteredSummaries(filtered)
  }, [reportFilter, documentFilter])

  /**
   * gets information about user based off the token provided
   * this will only be used for enterpise users who have
   * provided an access token
   */
  const getUserInfo = async () => {
    let info = await df.getInfoFromToken(user.token)
    let userInfo = JSON.parse(info)
    //console.log("user info", userInfo)
    setClientName(userInfo.clientName)
    setClientKey(userInfo.clientKey)
    setLoanID(userInfo.loanID)

    //get orgs and preffered orgs
    const loanOrg = await df.getOrgForLoan(user.token)
    let updatedUser = { ...user }
    let orgs = JSON.parse(loanOrg)
    updatedUser['organizations'] = orgs
    updatedUser['allOrgs'] = orgs
    updatedUser['clientName'] = userInfo.clientName
    setUser(updatedUser)

  }

  /**
   * Gets the Documents associated with the Organization (Loan)
   * @param {int} orgID 
   */
  const getDocuments = async (orgID) => {
    const farms = await df.fetchFarms(orgID, true, null, user.token)
    let items = []
    if (farms !== undefined) {
      items = farms.map((farm) => ({ id: farm.FarmID, name: farm.FarmName }));
    }
    setDocuments(items)
  }

  /**
   * Gets the Reports associated with the Organization (Loan)
   * @param {*} orgID 
   */
  const getReports = async (orgID) => {
    const clientslist = await df.fetchClients(orgID, user.token);
    let items = []
    if (clientslist !== undefined) {
      items = clientslist.map((client) => ({ id: client.ClientID, name: client.ClientName }));
    }
    setReports(items)
  }

  /**
   * Gets the other parcels that match the provided params
   * Data used in ParcelSalesTable
   * @param {int} radius 
   * @param {string} status 
   * @param {string} startDate 
   * @param {string} endDate 
   * @param {string} saleDate 
   */
  const getParcels = async (radius, status, startDate, endDate, saleDate, boundary = null, returnResult = false) => {
    //get selected fields center, create circle with diam of radius around it
    let fieldBoundary = boundary === null ? fieldData.selectedField.boundary : boundary
    let field = JSON.parse(fieldBoundary);
    let center = turf.center(field)
    let aoi = turf.circle(center, radius, { units: "miles" })
    let request = {
      State: fieldData.selectedField.state,
      County: fieldData.selectedField.county,
      Bounding_Box: JSON.stringify(aoi),
      Status: status,
      StartDate: startDate,
      EndDate: endDate,
      SaleDate: saleDate
    }

    let result = await df.parcelService(request, user.token)
    console.log(result)
    if (result === null) {
      return
    }
    let parcels = JSON.parse(result)
    console.log(parcels)
    if (parcels.records) {
      let parcelObjects = {
        field: field,
        center: center,
        radius: radius,
        parcels: parcels.records
      }
      if (!returnResult) {
        setParcels(parcelObjects)
        setDisplayParcels(true)
      }
      else {
        return parcelObjects
      }
    }
    else {
      let parcelObjects = {
        field: field,
        center: center,
        radius: radius,
        parcels: null
      }
      if (!returnResult) {
        setParcels(parcelObjects)
        setDisplayParcels(false)
      }
      else {
        return parcelObjects
      }
    }

  }

  /**
   * Gets data for the LandForecastTable
   * @param {string} saleDate 
   */
  const getComps = async (saleDate) => {
    //auto comps
    console.log(fieldData.selectedField)
    console.log(fieldData)
    let autoReq = {
      aoi: fieldData.selectedField.boundary,
      model: 'Auto',
      Sale_Date: saleDate,
      boundaryId: fieldData.selectedField.boundaryId,
      fieldID: fieldData.selectedField.id,
      orgID: fieldData.selectedField.orgId
    }

    let comps = await df.getComps(autoReq, user.token)
    if (comps === null) {
      return
    }
    let compsResult = JSON.parse(comps)
    // console.log(compsResult)
    if (compsResult.status !== 'Not Found') {
      setLandValueForecast(compsResult.Result)
    }
  }

  /**
   * Gets the data from the FieldData table for each field under the current loanID
   * this is the primary information provider for the Loan Summary page
   * @returns undefined if there is an error or no results to the initial request
   */
  const getParcelSummaries = async () => {
    setSummaryLoading(true)
    let result = await df.getParcelSummaryValues(loanID, user.token)
    if (result === null) {
      return
    }
    let summaries = JSON.parse(result)

    let combined = await combineSummaryRecords(summaries)

    setFilteredSummaries(combined)
    setParcelSummaries(combined)

    let totalCompValue = 0;
    let totalArea = 0;
    // The top level Value key will be the Parcels Auto Comp estimated value
    for (const item of combined) {
      // console.log(item)
      if (item['Value'] !== undefined) {
        totalCompValue = totalCompValue + (item.Value * item.Acres)
        totalArea = totalArea + item.Acres
      }
    }
    let compsWeightedAverage = totalCompValue / totalArea
    setSummaryAverage(compsWeightedAverage)

    // get list of the documents and reports in the summary values to use for filters later
    let documents = new Set();
    let reports = new Set();
    for (const summary of combined) {
      documents.add(summary.DocumentID)
      reports.add(summary.ReportName)
    }
    setSummaryDocuments(Array.from(documents))
    setSummaryReports(Array.from(reports))

    setSummaryLoading(false)
  }


  /**
   * Takes the response list from the getParcelSummaryValues request
   * and combines the values based on the BoundaryID to create single objects
   * for each claimed parcel from the FieldInfo table with the Summary values included
   * as sub-level objects
   * @param {Array} records 
   * @returns {Array} of objects as described above
   */
  const combineSummaryRecords = async (records) => {
    let combined = []
    for (const summary of records) {
      let exists = combined.filter(x => x.BoundaryID === summary.BoundaryID)
      if (exists.length === 0) {
        //get all items with the boundary id of summary
        let boundaryID = summary.BoundaryID
        let matching = records.filter(x => x.BoundaryID === boundaryID)

        let parcelRecord = {
          BoundaryID: summary.BoundaryID,
          FieldID: summary.FieldID,
          OrganizationID: summary.OrganizationID,
          FieldName: summary.FieldName,
          DocumentID: summary.DocumentID,
          ReportName: summary.ReportName,
          AppraisalDate: summary.AppraisalDate,
          Acres: summary.Acres,
          Latitude: summary.Latitude,
          Longitude: summary.Longitude,
          WKT: summary.WKT
        }

        const location = await df.getStateAndCounty(parcelRecord.Latitude, parcelRecord.Longitude, true)

        parcelRecord['State'] = location.StateName
        parcelRecord['County'] = location.CountyName
        parcelRecord['StateFips'] = location.STATEFP
        parcelRecord['CountyFips'] = location.COUNTYFP

        //get different types
        let classes = new Set()
        matching.map(x => classes.add(x.Variable_Class))
        for (var item of classes) {
          let matchingItems = matching.filter(x => x.Variable_Class === item)
          let types = new Set()
          matchingItems.map(x => types.add(x.Type))
          let classRecord = {}
          for (const type of types) {
            let matchingTypes = matchingItems.filter(x => x.Type === type)
            classRecord[type] = matchingTypes
          }
          parcelRecord[item] = classRecord
        }

        //create aggregate value for auto comps
        if (parcelRecord.LandValue) {
          let compsTotal = parcelRecord.LandValue.AutoComps.map(x => x.Value).reduce((prev, next) => prev + next, 0)
          parcelRecord.Value = compsTotal / parcelRecord.LandValue.AutoComps.length
        }

        if (parcelRecord.Collateral) {
          parcelRecord.Collateral = parcelRecord.Collateral.Included[0].Value
        }

        combined.push(parcelRecord)

      }
    }
    // console.log(combined)
    return combined
  }

  /**
   * Function to handle the change of the different input values used for 
   * the parcel service and auto comps requests
   * @param {Object} value event target value 
   * @param {String} type type of change to account for
   */
  const handleChange = (value, type) => {
    switch (type) {
      case 'radius':
        setSelectedRadius(value)
        getParcels(value.value, selectedSaleStatus, startDate, endDate, saleDate)
        break;
      case 'status':
        setSelectedSaleStatus(value.value)
        getParcels(selectedRadius.value, value.value, startDate, endDate, saleDate)
        break;
      case 'startDate':
        setStartDate(value)
        getParcels(selectedRadius.value, selectedSaleStatus, value, endDate, saleDate)
        break;
      case 'endDate':
        setEndDate(value)
        getParcels(selectedRadius.value, selectedSaleStatus, startDate, value, saleDate)
        break;
      case 'saleDate':
        setSaleDate(value)
        getParcels(selectedRadius.value, selectedSaleStatus, startDate, endDate, value)
        getComps(value)
        break;
    }
  }

  /**
   * Function to handle the change of the filter input values used on the Parcel Summary Page
   * @param {Object} value event target value 
   * @param {String} type type of change to account for 
   */
  const handleSummaryFilter = (value, type) => {
    console.log(value, type)
    switch (type) {
      case "document":
        setDocumentFilter(value)
        break;
      case "report":
        setReportFilter(value)
        break
    }
  }

  /** Close the post claim prompt from the close icon 
   * this will set the fieldData.selectedfield claimed bool to false
   * and will act the same as if user hit 'Continue' button in prompt
  */
  const handleClose = () => {
    setPostClaim(false)
    // let updateField = {...fieldData}
    // updateField.selectedField.claimedFlag = false;
    // setFieldData(updatedField)
  }

  /** Closes the prompt and calls to view details
   * calls autocomps and landvalue service for parcel info
   */
  const handleViewDetails = () => {
    setPostClaim(false)
    // let updateField = {...fieldData}
    // updateField.selectedField.claimedFlag = false;
    // setFieldData(updatedField)
    setDisplayInputs(true)
    if (user.isAuthenticated) {
      getParcels(selectedRadius.value, selectedSaleStatus, startDate, endDate, saleDate)
      getComps(saleDate)
    }
  }

  /**
   * Renders the different input components for the header bar
   * controls inputs for Parcel Service and Comps
   * @returns JSX Component
   */
  const landValueInputs = () => (
    <Box display="flex" flexWrap="wrap">
      <Box mx={1}>
        <Box>
          Search Radius
        </Box>
        <Select
          className={classes.selections}
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedRadius.value}
          onChange={(e) => handleChange(e.target, 'radius')}
        >
          {
            radiusOptions.map((x, i) => (
              <MenuItem
                key={i}
                value={x.value}
                name={x.name}
              >
                {x.name}
              </MenuItem>
            ))
          }
        </Select>
      </Box>
      <Box mx={1}>
        <Box>
          Sale Status
        </Box>
        <Select
          className={classes.selections}
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedSaleStatus}
          onChange={(e) => handleChange(e.target, 'status')}
        >
          {
            statusOptions.map((x, i) => (
              <MenuItem
                key={i}
                value={x}
                name={x}
              >
                {x}
              </MenuItem>
            ))
          }
        </Select>
      </Box>
      {selectedSaleStatus === 'Sold' &&
        <>
          <Box mx={1}>
            <Box>
              Start Date
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker
                className={classes.dateSelection}
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                InputAdornmentProps={{ position: "start" }}
                value={startDate}
                onChange={(date, val) => handleChange(date, 'startDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          <Box mx={1}>
            <Box>
              End Date
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils} >
              <KeyboardDatePicker
                className={classes.dateSelection}
                disableToolbar
                autoOk
                variant="inline"
                inputVariant="outlined"
                format="MM/dd/yyyy"
                margin="normal"
                id="date-picker-inline"
                InputAdornmentProps={{ position: "start" }}
                value={endDate}
                onChange={(date, val) => handleChange(date, 'endDate')}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </Box>
        </>
      }

      <Box mx={1}>
        <Box>
          Sale Date
        </Box>
        <MuiPickersUtilsProvider utils={DateFnsUtils} >
          <KeyboardDatePicker
            className={classes.dateSelection}
            disableToolbar
            autoOk
            variant="inline"
            inputVariant="outlined"
            format="MM/dd/yyyy"
            margin="normal"
            id="date-picker-inline"
            InputAdornmentProps={{ position: "start" }}
            value={saleDate}
            onChange={(date, val) => handleChange(date, 'saleDate')}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
      </Box>
    </Box>
  )

  /**
   * Renders the filter inputs for the summary page
   * @returns JSX Element
   */
  const summaryFilters = () => (
    <Box display="flex" style={{ width: '100%', justifyContent: 'space-evenly' }}>
      <Box mx={1} style={{ fontSize: 'x-large', marginTop: '10px' }}>
        Loan: <b>{loanID}</b>
      </Box>
      <Box mx={1} style={{ fontSize: 'x-large', marginTop: '10px' }}>
        Average Auto Comp Value: <b>{dollarFormat(summaryAverage)}/Acre</b>
      </Box>
      <Box display="flex" flexWrap="wrap">
        <Box mx={1}>
          <Typography variant="h6" style={{ marginTop: '15px' }}>
            Summary Filters
        </Typography>
        </Box>
        <Box mx={1} style={{ marginBottom: "15px" }}>
          <Autocomplete
            className={classes.filterSelect}
            id="document-filter"
            options={summaryDocuments}
            onInputChange={(event, value, reason) => handleSummaryFilter(value, 'document')}
            renderInput={(params) => <TextField {...params} variant="outlined" label='Document' />}
          />
        </Box>
        <Box mx={1} style={{ marginBottom: "15px" }}>
          <Autocomplete
            className={classes.filterSelect}
            id="report-filter"
            options={summaryReports}
            onInputChange={(event, value, reason) => handleSummaryFilter(value, 'report')}
            renderInput={(params) => <TextField {...params} variant="outlined" label='Report' />}
          />
        </Box>

      </Box>
    </Box>

  )

  /**
   * Renders the modal that pops up after a user claims a field
   * to allow them to skip seeing the parcel service and autocomps details
   * @returns JSX Element
   */
  const postClaimModal = () => (
    <Modal
      open={postClaim}
      onClose={() => setPostClaim(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'
      }}
    >
      <Box
        className={classes.postClaimModalBox}
        style={modalStyle}
        borderRadius="borderRadius"
        boxShadow={2}
        fontWeight={500}
        fontSize={16}
      >
        <Box
          display="flex"
          justifyContent='space-between'
          style={{ width: '100%', padding: 4 }}
        >
          <Box>
            &nbsp;
          </Box>
          <Box fontSize={18}>
            Successful Claim
          </Box>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => handleClose()}
          />
        </Box>
        <Divider style={{ width: '100%', height: 1 }} />

        <Box p={1}>
          Would you like to view more details about this parcel?
        </Box>
        <Button
          color='primary'
          variant='outlined'
          onClick={() => handleViewDetails()}
          style={{ width: 200 }}
        >
          View Details
        </Button>

        <Box p={1}>
          Continue to claim your next parcel.
        </Box>
        <Button
          color='primary'
          variant='outlined'
          onClick={() => handleClose()}
          style={{ width: 200 }}
        >
          Continue
        </Button>

      </Box>
    </Modal>
  )

  return (
    <Box className={classes.root} style={{ height: height - 80 }}>
      <AppBar
        position="static"
        color="transparent"
        style={{ boxShadow: "0 1px 10px 5px rgba(0, 0, 0, 0.05)" }}
      >
        <Box className={classes.appBar}>
          {displayInputs &&
            landValueInputs()
          }
          {(step === 1 && parcelSummaries.length > 0) &&
            summaryFilters()
          }

        </Box>
      </AppBar>

      {step === 0 ?
        // <Slide 
        // direction="right"
        // in={width >= mobileBreakPoint}
        // timeout={{enter: 300, exit:200}}
        // mountOnEnter
        // unmountOnExit
        // >
        <Box className={classes.body}>
          <FieldSelection
            showMap={true}
            mobileView={width <= 920}
            hideFieldSelection={hideFieldSelection}
            changeSection={setStep}
            continueText={'View Summary'}
            underwriting={true}
            loanID={loanID}
            parcels={parcels}
            activeParcel={activeParcel}
            setActiveParcel={setActiveParcel}
            setReports={setReports}
            setDocuments={setDocuments}
          />

          <Box className={classes.itemTables}>
            {displayParcels &&
              <ParcelSalesTable comps={parcels} activeParcel={activeParcel} setActiveParcel={setActiveParcel} />
            }
            {landValueForecast !== null &&
              <LandForecastTable
                forecast={landValueForecast}
                status={selectedSaleStatus}
              />
            }
          </Box>

          {postClaimModal()}

        </Box>
        // </Slide>

        :
        // <Slide 
        //   direction="left"
        //   in={width >= mobileBreakPoint}
        //   timeout={{exit:200, enter:300}}
        //   mountOnEnter
        //   unmountOnExit
        // >
        <Box style={{ height: "100%" }}>
          <LoanSummary
            loading={summaryLoading}
            parcels={filteredSummaries}
            setParcels={setFilteredSummaries}
            refreshParcels={getParcelSummaries}
            getComparables={getParcels}
            reports={reports}
            setReports={setReports}
            documents={documents}
            setDocuments={setDocuments}
            summaryAverage={summaryAverage}
            backText={'Parcel Claim'}
            setSection={setStep}
          />
        </Box>
        // </Slide>


      }

    </Box>
  )




}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Button } from "@material-ui/core";
import { COLORS } from "../../../constants";

const useStyles = makeStyles(() => ({
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    boxShadow: "0px 8px 8px 0px rgba(0, 0, 0, 0.08)",
    "& .MuiButtonBase-root": {
      color: COLORS.gray,
      fontWeight: "regular",
      fontSize: 12,
      borderRadius: 0,
      borderBottom: "4px solid transparent",
      textTransform: "uppercase",
      "&.MuiButton-text": {
        padding: "6px 12px",
      },
      "&.active": {
        color: COLORS.green,
        borderBottom: `4px solid ${COLORS.green}`,
      },
    },
  },
}));

/**
 * Renders the farm report
 * @param {object} views object with id, label and active properties
 * @param {function} setViews set state function to switch between overview and analytics
 */

const MobileNav = ({ views, setViews }) => {
  const classes = useStyles();
  return (
    <div className={classes.buttonGroup}>
      {views.map((view) => (
        <Button
          key={`button-for-${view.id}`}
          onClick={() =>
            setViews(views.map((item) => ({ ...item, active: !item.active })))
          }
          className={view.active ? "active" : ""}
        >
          {view.label}
        </Button>
      ))}
    </div>
  );
};

export default MobileNav;

MobileNav.propTypes = {
  views: PropTypes.object.isRequired,
  setViews: PropTypes.func.isRequired,
};

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState} from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, AppBar,
} from '@material-ui/core';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import { SimpleReports } from './Services/SimpleReports';
import { Checkout } from './Checkout/Checkout';
import { roundIcon } from '../../utils/customComponents';
import { useWindowDimensions } from '../../utils/dimensions';
import { grey } from '../../styles/colors';
import poweredBy from '../../images/Ag-Analytics_Full_Black_Powered.svg';

const lightBlue = '#007cba';
const greyBlue = '#006ba1';

const useStyles = makeStyles((theme) => ({
  appBar: {
    ...theme.appBar,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  mobileAppBar: {
    height: '75px',
    width: '100%',
    padding: '5px',
    boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.05)',
    display: 'flex',
    alignItems: 'center',
    backgroundColor: 'white',
  },
  appBarSelections: theme.appBarSelections,
  smallAppBarSelection: {
    ...theme.appBarSelections,
    margin: '0 10px',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    marginTop: '20px',
    overflowY: 'auto',
  },
  fieldBox: {
    backgroundColor: theme.palette.greys.light,
    color: theme.palette.greys.dark,
    padding: '10px',
    fontWeight: 500,
    width: '350px',
    position: 'absolute',
    top: 135,
    right: 20,
  },
  select: {
    padding: '10px',
    margin: '15px',
    display: 'flex',
    fontSize: 18,
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  remove: {
    fontSize: '12px',
    maringBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  info: {
    color: lightBlue,
    '&:hover': {
      color: greyBlue,
      cursor: 'pointer',
    },
  },
}));

/**
 * Allows for ordering of services without field selection.
 * User simply selects their packages, then completes order form.
 * @returns {JSX} Simple ordering for DIGS services
 */
export function SimpleOrder({
  ads
}) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const [section, setSection] = useState(0);

  const [order, setOrder] = useState([]);

  const [formData, setFormData] = useState({
    orderName: 'Water Management',
    address: '',
    city: '',
    email: '',
    organization: '',
    phone: '',
    state: '',
    userName: '',
    zipCode: '',
    preferredDate: '',
    disableEmailUpdate: false,
    comments: '',
  });

  const [fileData, setFileData] = useState(null);

  const addToOrder = (report) => {
    // reports in order
    const reportNames = order.map((x) => x.name);

    let orderCopy = [...order];

    // remove other bundles
    if (report.type === 'bundle') {
      // index of bundle
      const index = orderCopy.findIndex((x) => x.type === 'bundle');
      if (index >= 0) {
        // remove bundle
        orderCopy.splice(index, 1);
      }

      // remove reports included with added bundle
      orderCopy = orderCopy.filter((x) => !report.included.includes(x.name));
    }

    if (!reportNames.includes(report.name)) {
      orderCopy.push(report);
      setOrder(orderCopy);
    }
  };

  const removeFromOrder = (report) => {
    const orderCopy = [...order];

    if (report.type === 'bundle') {
      // update price if remediation was included
      report.price = report.included.includes('Remediation Response Analysis')
        ? report.price - report.remediationPrice : report.price;

      // remove remediation and selected optional reports from includes
      const updatedIncluded = report.included.filter(
        (x) => x !== 'Remediation Response Analysis' && !report.optional.includes(x),
      );
      report.included = updatedIncluded;
    }
    const updatedOrder = orderCopy.filter((x) => x.name !== report.name);

    setOrder(updatedOrder);
  };

  const footer = () => (
    <Box
      p={2}
      boxShadow={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{
        height: '60px',
        width: '100%',
        position: 'fixed',
        left: 0,
        bottom: 0,
        backgroundColor: '#ffffff',
      }}
    >
      <Box>
        {
          section > 0
            ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setSection(section - 1)}
                disableElevation
              >
                <ArrowBackIcon />
                Back
              </Button>
            )
            : ''
        }
      </Box>

      <Box>
        <img
          src={poweredBy}
          alt="Powered by AgAnalytics"
          style={{
            height: width > 450 ? 50 : 40,
            width: 'auto',
          }}
        />
      </Box>

      <Box>
        {
          (section === 0)
            ? (
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => setSection(section + 1)}
                disableElevation
              >
                Continue
                <ArrowForwardIcon />
              </Button>
            )
            : ''
        }
      </Box>

    </Box>
  );

  const navBar = () => (
    <Box className={width > 520 ? classes.appBar : classes.mobileAppBar}>
      <Box display="flex">

        <Box
          className={width > 520 ? classes.appBarSelections : classes.smallAppBarSelection}
          color={section === 0 ? greyBlue : grey}
          onClick={() => setSection(0)}
        >
          {section > 0
            ? (
              <CheckBoxRoundedIcon
                style={{ marginRight: '10px', fontSize: 27 }}
              />
            )
            : (
              <Box {...roundIcon}>1</Box>
            )}

          Services
        </Box>
        <Box
          className={width > 520 ? classes.appBarSelections : classes.smallAppBarSelection}
          color={section === 1 ? greyBlue : grey}
          onClick={() => setSection(1)}
        >
          {section > 1
            ? (
              <CheckBoxRoundedIcon
                style={{ marginRight: '10px', fontSize: 27 }}
              />
            )
            : (
              <Box {...roundIcon}>2</Box>
            )}
          Complete Order
        </Box>
      </Box>

    </Box>
  );

  return (
    <Box>
      <AppBar
        position="fixed"
        color="transparent"
        style={{
          boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.05)',
        }}
      >
        {navBar()}
      </AppBar>

      <Box className={classes.body} style={{ height: '100%' }}>
        {
          section === 0
            ? (
              <SimpleReports
                order={order}
                addToOrder={addToOrder}
                remove={removeFromOrder}
                includesFields={false}
              />
            )
            : (
              <Checkout
                hideNav
                ads={ads}
                order={order}
                remove={removeFromOrder}
                includesFields={false}
                setSection={setSection}
                formData={formData}
                setFormData={setFormData}
                fileData={fileData}
                setFileData={setFileData}
              />
            )
        }
      </Box>

      { footer() }

    </Box>
  );
}

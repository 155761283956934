import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, FormControl, TextField, InputLabel, Select, MenuItem,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import { MuiPickersUtilsProvider, KeyboardDatePicker } from '@material-ui/pickers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    margin: 8,
    padding: 4,
    borderRadius: 4,
    border: `1px solid ${theme.palette.greys.main}`,
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 200,
    maxWidth: 300,
  },

}));

/**
 * Survey Questions
 * @param {Object} subOrder Suborder questions relate to
 * @param {Number} index index of subOrder in subOrderDetails, used for updating
 * @param {Function} handleUpdate Update on change
 * @returns {JSX} Survey Questions
 */
export function SurveyQuestions({ subOrder, index, handleUpdate }) {
  const classes = useStyles();

  const growingStages = ['Sprout', 'Seedling', 'Vegetative', 'Budding', 'Flowering', 'Ripening'];
  const chemicalSuppliers = ['Syngenta', 'Bayer', 'BASF', 'Corteva', 'FMC', 'ADAMA', 'UPL', 'Sumitomo Chemical', 'Nufar', 'Huapont Life Sciences'];
  const seedSuppliers = ['Monsanto', 'Dupont', 'Syngenta', 'Groupe Limagrain', 'Bayer', "Land O' Lakes", 'KWS AG', 'Sakata', 'DLF-Trifolium', 'Takii'];
  const irrigationMethods = ['Surface irrigation', 'Localized irrigation', 'Drip irrigation', 'Sprinkler irrigation', 'Center pivot irrigation', 'Sub-irrigation', 'Manual irrigation'];
  const fertilizers = ['Ammonium nitrogen fertilizer', 'Nitrate nitrogen fertilizer', 'Amide nitrogen fertilizer', 'Phosphorus fertilizer', 'Mixing soluble phosphate fertilizer', 'Citrate-soluble phosphatic fertilizer', 'Agricultural waste', 'Livestock manure'];

  return (
    <Box className={classes.root}>
      <Typography variant="h5" align="center">{subOrder?.fieldName}</Typography>

      <Box display="flex" flexDirection="column">
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={subOrder.surveyQuestions.cropGrowing || ''}
            onChange={(e) => handleUpdate(e.target.value, 'cropGrowing', index)}
            label="Crop"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              autoOk
              inputVariant="outlined"
              value={subOrder.surveyQuestions.plantedDate || new Date()}
              onChange={(date) => handleUpdate(date, 'plantedDate', index)}
              format="MM/dd/yyyy"
            />
          </MuiPickersUtilsProvider>

        </FormControl>

        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel id="growing-stage">Growing Stage</InputLabel>
          <Select
            variant="outlined"
            labelId="growing-stage"
            label="Growing Stage"
            value={subOrder.surveyQuestions.growingStage || ''}
            onChange={(e) => handleUpdate(e.target.value, 'growingStage', index)}
          >
            {growingStages.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel id="chemicals-purchased-label">Chemicals Purchased At</InputLabel>
          <Select
            variant="outlined"
            labelId="chemicals-purchased-label"
            label="Chemicals Purchased At"
            value={subOrder.surveyQuestions.chemicalsPurchasedAt || ''}
            onChange={(e) => handleUpdate(e.target.value, 'chemicalsPurchasedAt', index)}
          >
            {chemicalSuppliers.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel id="seed-suppliers">Seeds Purchased At</InputLabel>
          <Select
            variant="outlined"
            labelId="seed-suppliers"
            label="Seeds Purchased At"
            value={subOrder.surveyQuestions.seedsPurchasedAt || ''}
            onChange={(e) => handleUpdate(e.target.value, 'seedsPurchasedAt', index)}
          >
            {seedSuppliers.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel id="irrigation-method">Irrigation Method</InputLabel>
          <Select
            variant="outlined"
            labelId="irrigation-method"
            label="Irrigation Method"
            value={subOrder.surveyQuestions.irrigationMethodUsed || ''}
            onChange={(e) => handleUpdate(e.target.value, 'irrigationMethodUsed', index)}
          >
            {irrigationMethods.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
          </Select>
        </FormControl>

        <FormControl className={classes.formControl} variant="outlined">
          <InputLabel id="fertilizers-used-label">Fertilizers Used</InputLabel>
          <Select
            variant="outlined"
            labelId="fertilizers-used-label"
            label="Fertilizers Used"
            value={subOrder.surveyQuestions.fertilizersUsed || ''}
            onChange={(e) => handleUpdate(e.target.value, 'fertilizersUsed', index)}
          >
            {fertilizers.map((s) => <MenuItem value={s} key={s}>{s}</MenuItem>)}
          </Select>
        </FormControl>

      </Box>
    </Box>
  );
}

SurveyQuestions.propTypes = {
  subOrder: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
  handleUpdate: PropTypes.func.isRequired,
};

// -------------------- IMPORTS --------------------
// React
import React from 'react';
import ReactDOM from 'react-dom';

// Styling
import './index.css'
import theme from './theme';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Functionality
import { App } from './App';
import registerServiceWorker from './registerServiceWorker';
import { UserProvider } from './components/Context/UserContext'
import { FieldProvider } from './components/Context/FieldContext'

// Helpful plugins, modules, etc.
// Webpack will make sure to make these available to all files so this should be imported at the top
import "./utils/leaflet-slider";
import "./styles/leaflet-slider.css";
import { SnackbarProvider } from 'notistack';
import { Button } from 'react-bootstrap/lib/InputGroup';


// -------------------- MAIN FUNCTIONALITY --------------------
const notistackRef = React.createRef();
const onClickDismiss = key => () => { 
    notistackRef.current.closeSnackbar(key);
}

ReactDOM.render(
    <ThemeProvider theme={theme}>
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
          <SnackbarProvider
            ref={notistackRef}
            action={(key) => (
              <Button onClick={onClickDismiss()} style={{ cursor: 'pointer' }}>
                <HighlightOffIcon />
              </Button>
            )}
          >
            <UserProvider>
              <FieldProvider>
                <App />
              </FieldProvider>
            </UserProvider>
          </SnackbarProvider>
    </ThemeProvider>,
    document.querySelector('#root'),
);

registerServiceWorker();

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableBody,
  TableCell,
  TableRow,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { SoilResultsMap } from './ResultsMap';
import { zoneColors } from '../../../Maps/Styles/layerStyles';
import { checkIsMobile, checkImage } from '../../../../utils/helpers';
import { getLegendData } from '../../../../utils/dataFetchers';
import { SpinningLoader } from '../../../Shared/SpinningLoader';
import { SelectionMenu } from '../SelectionMenu';
import { displayNames } from '../helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
  },
  table: {
    minWidth: 280,
  },
}));

/**
 * Displays shape file and associated data in table. User will be able to select
 * their shape file from dropdown, at which point we will call getGeoJsonFromShape
 * to convert the shape file into a GeoJSON which can be displayed on map. The
 * GeoJSON features may come back as polygons or points. Type will be set to
 * alert map to which format to expect. Shape files will be included in an object,
 * where key seperates the different types and value is array of files.
 * @param {String} boundary Stringified field boundary
 * @param {Object} shapeFiles Files to be selected for GeoJSON conversion and display
 * @param {String} token To make back end requests for non-logged in users
 * @param {Funtion} getGeoJson Converts shape file to geoJsons and adds to list
 * @returns {JSX} Map and table
 */
export function ResultsMapDisplay({
  boundary, shapeFiles, token, getGeoJson,
}) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [isMobile, setIsMobile] = useState(false);
  const [selectedZones, setSelectedZones] = useState(null);
  const [geoJson, setGeoJson] = useState(null);
  const [selected, setSelected] = useState('');
  const [type, setType] = useState(null);
  const [base64, setBase64] = useState('');
  const [mapExtent, setMapExtent] = useState('');
  const [legend, setLegend] = useState([]);

  useEffect(() => {
    setIsMobile(checkIsMobile());
  }, []);

  useEffect(() => {
    if (shapeFiles) {
      if (shapeFiles?.zones?.length) {
        handleChange(shapeFiles.zones[0]);
      } else if (shapeFiles?.results?.length) {
        handleChange(shapeFiles.results[0]);
      } else if (shapeFiles?.recomendations?.length) {
        handleChange(shapeFiles.recomendations[0]);
      }
    }
  }, [shapeFiles]);

  useEffect(() => {
    if (geoJson?.features[0]?.properties) {
      setSelectedZones([geoJson.features[0]]);
    } else {
      setSelectedZones(null);
    }
  }, [geoJson]);

  const handleChange = async (file) => {
    try {
      setSelected(file);
      setLoading(true);
      const convertedShape = await getGeoJson(file);
      if (convertedShape.GeoJSON.features.length <= 100) {
        setGeoJson(convertedShape.GeoJSON);
        setBase64('');
        setMapExtent('');
        setLegend([]);
        if (convertedShape.GeoJSON.features[0].geometry.type === 'Point') {
          setType('points');
        } else {
          setType('zones');
        }
      } else {
        setGeoJson(null);
        setType(null);
        const shapeLegend = await getLegendData(file, 'Target_Rat', '0', token);
        if (shapeLegend?.legendData) {
          const mapImage = checkImage(shapeLegend.legendData[1].pngb64);
          setBase64(mapImage);
          setMapExtent(shapeLegend.legendData[1].extent);
          setLegend(shapeLegend.legendData[1].legend);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const selectZone = (zoneId) => {
    const selectedResults = geoJson?.features.filter(
      (x) => zoneId === x.properties.Obj__Id);
    setSelectedZones(selectedResults);
  };

  const selectedZoneDisplay = (properties, i) => {
    const data = Object.entries(properties);
    return (
      <TableContainer component={Paper} key={`zone-table-${i}`}>
        <Table
          className={classes.table}
          size="small"
          aria-label="soil results table"
          style={{ border: `3px solid ${zoneColors[properties.Obj__Id - 1]}` }}
        >
          <TableBody>
            {data.map((row) => (
              <TableRow key={row[0]}>
                <TableCell component="th" scope="row">
                  {displayNames(row[0])}
                </TableCell>
                <TableCell align="right">{row[1]}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const createTable = () => (
    selectedZones ? (
      <Box mt={1}>
        <Box>
          <Typography align="center" style={{ fontWeight: 500 }}>
            {!isMobile ? 'Click ' : 'Touch '}
            your
            {' '}
            {type}
            {' '}
            on map to see their details below
          </Typography>
        </Box>

        { selectedZones.map(
          (zone, i) => selectedZoneDisplay(zone.properties, i),
        )}
      </Box>
    ) : (<div />)
  );

  const createLegendTable = () => (
    legend ? (
      <Box mt={1}>

        <TableContainer component={Paper} key="zone-table">
          <Table
            className={classes.table}
            size="small"
            aria-label="soil results table"
          >
            <TableBody>
              <TableCell component="th">Color</TableCell>
              <TableCell component="th">Target_Rat</TableCell>
              <TableCell component="th">Area (%)</TableCell>
            </TableBody>

            <TableBody>
              {legend.map((x, i) => (
                <TableRow>
                  <TableCell style={{ backgroundColor: x.color, height: '25px', width: '25px' }} />
                  <TableCell>{x.value}</TableCell>
                  <TableCell>{x.area}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    ) : (<div />)
  );

  return (
    <Box className={classes.root}>
      <Box>
        <Typography variant="h6">
          View your Shape Files on map
        </Typography>
        <Box pl={1}>
          <Typography>
            Select a Shape File from the dropdown, then once its loaded, view on map.
          </Typography>
          <Typography>
            *Note due to the amount of data included, fertility recommendations may take some time to load.
          </Typography>
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap">
        <Box
          display="flex"
          flexGrow={1}
          minWidth={window.innerWidth > 840 ? 600 : window.innerWidth - 30}
          borderRadius="borderRadius"
        >
          <Box display="flex" flexDirection="column" flexGrow={1}>
            <Box pb={0.5}>
              {(
                shapeFiles?.results?.length > 0
                || shapeFiles?.recomendations?.length > 0
                || shapeFiles?.zones?.length > 0
              ) && (
              <SelectionMenu
                shapeFiles={shapeFiles}
                selected={selected}
                handleChange={handleChange}
                loading={loading}
              />
              )}
            </Box>
            <SoilResultsMap
              boundary={boundary}
              mapGeo={geoJson}
              selectZone={selectZone}
              type={type}
              base64={base64}
              extent={mapExtent}
            />
          </Box>
        </Box>

        <Box px={0.5} m="0 auto">
          <Box display="flex" minWidth={280}>
            <Fade
              in={!loading && selectedZones !== null && selectedZones?.length > 0}
              mountOnEnter
              unmountOnExit
            >
              {createTable()}
            </Fade>
          </Box>

          <Box display="flex" minWidth={280} maxHeight={700} style={{ overflowY: 'scroll' }}>
            <Fade
              in={!loading && legend !== null && legend?.length > 0}
              mountOnEnter
              unmountOnExit
            >
              {createLegendTable()}
            </Fade>
          </Box>

        </Box>

      </Box>

      {/* { loading && <SpinningLoader />} */}
    </Box>
  );
}

ResultsMapDisplay.propTypes = {
  boundary: PropTypes.string.isRequired,
  shapeFiles: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  getGeoJson: PropTypes.func.isRequired,
};

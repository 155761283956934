/* eslint-disable max-len */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Divider, Container,
} from '@material-ui/core/';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { Endpoints } from '../../constants/Endpoints';
import { PromoVideo } from './Ordering/PromoVideo';

const useStyles = makeStyles((theme) => ({
  row: {
    flexGrow: 1,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    flexWrap: 'wrap',
  },
  dot: {
    fontSize: 12,
    marginRight: 4,
    color: theme.palette.greys.dark,
  },
  link: {
    whiteSpace: 'nowrap',
    color: '#007cba',
    '&:hover': {
      cursor: 'pointer',
      color: '#2f5597',
    },
  },
  options: {
    marginTop: 4,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    padding: 8,
  },
  price: {
    margin: 4,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 6,
    width: 140,
  },
  acres: {
    padding: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: '#ffffff',
    fontSize: 18,
    backgroundColor: theme.palette.primary.main,
    borderTopRightRadius: 3,
    borderTopLeftRadius: 3,
  },
}));

/**
 * UAV testing overview
 * @returns {JSX} UAV overview
 */
export function Overview() {
  const classes = useStyles();

  const prices = [
    { acres: '0-60 acres', price: '$210' },
    { acres: '60-200 acres', price: '$3.5' },
    { acres: '201-350 acres', price: '$3' },
    { acres: '351-400 acres', price: '$2.8' },
    { acres: '401-500 acres', price: '$2.6' },
    { acres: '500+ acres', price: '$2.4' },
  ];

  return (
    <Container maxWidth="xl">
      <Box display="flex" flexDirection="column">

        <Typography align="center" style={{ fontWeight: 500, fontSize: '2.2rem' }}>
          On-Demand UAV Service
        </Typography>

        <Box className={classes.row}>
          <Box px={1} display="flex" flexDirection="column" width={520} minWidth={380}>
            <Typography align="center" variant="h5">
              How To Order
            </Typography>
            <Divider />
            <Box mt={1}>
              <Typography gutterBottom style={{ fontSize: '1.1rem' }}>
                Order you high-quality imagery and accurate diagnostics reports and hear from a pilot within 1-2 days.
                They&apos;ll schedule a time to fly your field, and generally get your results back within 1-2 weeks!
              </Typography>
              <Typography gutterBottom style={{ fontSize: '1.1rem' }}>
                Begin by selecting your field boundary on
                the mapping tool. You will recieve a standard flight report for each field in order.
                Next, choose additional reports to get even more in depth data on your field.
                Finally, complete and place your order by filling in your contact information.
              </Typography>
              <Typography style={{ fontSize: '1.1rem' }}>
                Orders start around $200, and gain increased discounts for larger fields. UAV subscription service also available at heavily discounted rates.
              </Typography>
            </Box>
          </Box>

          <Box mt={2} p={1} display="flex" justifyContent="center">
            <PromoVideo />
          </Box>
        </Box>

        <Box className={classes.row}>
          <Box>
            <Box p={1} display="flex" flexDirection="column" width={520} minWidth={380}>
              <Typography align="center" variant="h5">
                Standard Flight and Report
              </Typography>
              <Divider />

              <Box className={classes.options}>
                <Typography gutterBottom style={{ fontSize: '1.1rem' }}>
                  The Standard Report includes High Resolution RGB, Elevation and VARI Plant Health maps to spot differences and
                  locate problem areas in your field. Interactive 3D maps to help you gain a better understanding of your field.
                </Typography>

                <Typography style={{ fontSize: '1.1rem' }}>
                  Additional optional reports such as Flowering Analysis, Nitrogen Status, Pest Detection and more available for only $1.25/Acre.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box p={1} display="flex" flexDirection="column" width={520} minWidth={380}>
            <Typography align="center" variant="h5">
              Flight Pricing
            </Typography>
            <Divider />

            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              flexWrap="wrap"
            >
              {prices.map((x, i) => (
                <Box key={x.acres} className={classes.price}>
                  <Box className={classes.acres}>
                    {x.acres}
                  </Box>

                  <Typography align="center" style={{ fontWeight: 600, fontSize: 20, padding: '8px 4px' }}>
                    {x.price}
                    {i !== 0 && <span style={{ fontWeight: 500, fontSize: 14 }}>/Acre</span>}
                  </Typography>
                </Box>
              ))}
            </Box>

            <Box mt={1}>
              <Typography style={{ fontSize: '1.1rem' }}>
                For pricing on flights over 500 acres, discounted pricing for frequently scheduled flights and weekly
                subscription services, or if you have requests regarding hardware or sensors
                {' '}
                <a className={classes.link} target="_blank" rel="noreferrer" href="https://www.analytics.ag/contact">contact us</a>
                {' '}
                at support@analytics.ag.
              </Typography>
            </Box>
          </Box>

        </Box>

        <Box className={classes.row}>
          <Box p={1} display="flex" flexDirection="column" width={520} minWidth={380}>
            <Typography align="center" variant="h5">
              Additional Services
            </Typography>
            <Divider />

            <Box className={classes.options}>
              <Box mb={0.5} display="flex" alignItems="center">
                <FiberManualRecordIcon className={classes.dot} />
                <Typography style={{ fontSize: '1.1rem' }}>
                  Request NIR Band* for an added surcharge
                </Typography>
              </Box>
              <Box mb={1} display="flex" alignItems="center">
                <FiberManualRecordIcon className={classes.dot} />
                <Typography style={{ fontSize: '1.1rem' }}>
                  LIDAR (may entail an extra fee)
                </Typography>
              </Box>
              <Box display="flex" alignItems="center">
                <FiberManualRecordIcon className={classes.dot} />
                <Typography style={{ fontSize: '1.1rem' }}>
                  Other imaging services
                </Typography>
              </Box>
              <Typography variant="subtitle2" style={{ marginTop: 18 }}>*NIR may add 1-2 days to flight time</Typography>
            </Box>
          </Box>

          <Box p={1} display="flex" flexDirection="column" width={520} minWidth={380}>
            <Typography align="center" variant="h5">
              Results
            </Typography>
            <Divider />

            <Box mt={1}>
              <Typography gutterBottom style={{ fontSize: '1.1rem' }}>
                Upon completion, the customer will receive results as interactive maps, PDF reports, and downloadable files.

              </Typography>
              <Typography gutterBottom style={{ fontSize: '1.1rem' }}>
                When your results are ready, you may view them at
                {' '}
                <a className={classes.link} target="_blank" rel="noreferrer" href={`${Endpoints.HOME}/app/uav/results`}>app.profit.ag/app/uav/results</a>
              </Typography>
              <Typography style={{ fontSize: '1.1rem' }}>
                Reports are high-resolution, and can be downloaded locally for your convenience!
              </Typography>
            </Box>
          </Box>
        </Box>

        <Typography align="center" color="textPrimary" variant="subtitle2">
          *Please note this service is currently only available in the contiental U.S.
        </Typography>

      </Box>
    </Container>

  );
}

import React from 'react';
import PropTypes from 'prop-types';
import { Box, Slide, Fade } from '@material-ui/core';
import { VendorsMap } from '../../Maps/VendorsMap';
import { SelectFieldV2 } from '../../FieldSelection/SelectFieldV2';

/**
 * For authenticatd users, display org/farm/field dropdowns with map.
 * Non logged in user will be shown full screen map to select their fields.
 * @param {Number} mobileBreakPoint Pixel with to switch to mobile view
 * @param {Bool} isAuthenticated User if logged in
 * @param {Function} selectFields Selects field
 * @param {Object} selectedField Currently selected field
 * @param {String} initialBounds Bounds to start map at, usually a previously selected field
 * @param {Number} nextStep Step to move to after selecting field
 * @param {Bool} showMap Unmounts map
 * @returns {JSX} FieldSelection
 */
export function FieldSelection({
  mobileBreakPoint,
  isAuthenticated,
  selectField,
  selectedField,
  initialBounds,
  nextStep,
  showMap = true,
}) {
  return (
    <Box
      display="flex"
      height="100%"
      flexGrow={1}
      style={{ flexDirection: window.innerWidth < mobileBreakPoint ? 'column' : 'row' }}
    >
      { isAuthenticated && (
        <Slide
          direction="right"
          mountOnEnter
          unmountOnExit
          in
        >
          <div>
            <SelectFieldV2
              select={selectField}
              mobile={window.innerWidth < mobileBreakPoint}
            />
          </div>
        </Slide>
      )}

      <Fade
        in={showMap}
        unmountOnExit
        mountOnEnter
      >
        <VendorsMap
          selectField={selectField}
          selectedField={selectedField}
          initialBounds={initialBounds}
          nextStep={nextStep}
        />
      </Fade>

    </Box>
  );
}

FieldSelection.propTypes = {
  mobileBreakPoint: PropTypes.number.isRequired,
  isAuthenticated: PropTypes.bool,
  selectField: PropTypes.func.isRequired,
  selectedField: PropTypes.object,
  initialBounds: PropTypes.string,
  nextStep: PropTypes.number.isRequired,
  showMap: PropTypes.bool,
};

FieldSelection.defaultProps = {
  isAuthenticated: undefined,
  selectedField: null,
  initialBounds: null,
  showMap: true,
};

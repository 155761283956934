import { ssurgoForZones } from '../../../utils/dataFetchers'
import * as turf from '@turf/turf';

export const getSsurgo = async (fieldBoundary, zoneColors) =>{
  try {

    let boundary = JSON.parse(fieldBoundary)

    if (boundary.type === 'FeatureCollection') {
      // Endpoint doesn't take feature collections
      boundary = boundary.features[0]
    }
    let ssurgoReq = {
      AOI: JSON.stringify(boundary),
      Projection: null,
      Resolution: null,
      Soil_Parameter: null,
      Product: null
    }

    let ssurgoProcessing = await ssurgoForZones(ssurgoReq)

    if(
      ssurgoProcessing === undefined ||
      !ssurgoProcessing?.attributes?.GeoJSON
    ) {
      return false
    }

    let ssurgoGeo = ssurgoProcessing?.attributes?.GeoJSON
    let average = ssurgoProcessing?.attributes?.Weighted_Average

    let legend = []
    for(let i =0; i< ssurgoGeo.features.length; i++){
      try {
        ssurgoGeo.features[i].properties.color = zoneColors[i]
        ssurgoGeo.features[i].properties.CALCACRES = turf.convertArea(ssurgoGeo.features[i].properties.area, 'meters', 'acres')
        ssurgoGeo.features[i].properties.zone = i + 1
        legend.push(ssurgoGeo.features[i].properties)
      } catch (e) {
        console.log(e)
      }
    }
    console.log(ssurgoGeo.features)
    return { ssurgoGeo, legend, average }

  } catch (e) {
    console.log(`Problem getting SSURGO: ${e}`)
    return false
  }
}

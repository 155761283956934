import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Divider } from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import MapIcon from '@material-ui/icons/Map';
import EditIcon from '@material-ui/icons/Edit';
import SyncIcon from '@material-ui/icons/Sync';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import { CreateRow } from './CreateRow';
import { useWindowDimensions } from '../../../../utils/dimensions';
import { numFormat, roundTo, capitalizeFirstLetter, getDecimalPlaces } from '../../../../utils/helpers';
import { combineVarities } from './utils/helpers';
import { unitConversion } from '../../utils/conversions';
import { grey } from '../../../../styles/colors';
import { CustomToolTip } from '../../../../utils/customComponents';
import { outliersAndAnomalies } from '../Shared/tooltips'
import { Tooltip } from 'react-bootstrap';

const useStyles = makeStyles((theme) => ({
  head: theme.tableHeader,
  headText: theme.tableHeaderText,
  icon: theme.plIcon,
  labels: {
    display: 'flex',
    padding: '6px 16px',
    alignItems: 'center',
    fontWeight: 600,
    color: theme.palette.text.black,
  },
  summaryTableRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  infoToolTip: theme.infoToolTip,
}));

export function CreateTable({
  op,
  i,
  shown,
  type,
  handleEdit,
  displayOperationMap,
  handleDisplay,
  displaySummaries,
  displayedLength,
  setDisplaySummaries,
  perAcre,
  handlePriceChange,
  savePrice,
  savingPrice,
  templateMode,
  syncOperation
}) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const varieties = combineVarities(op.varieties);
  const { unit } = op.varieties[0];
  const index = op.hidden ? i + displayedLength : i;
  let wholeOperationMap = {
    operationID: op.varieties[0].operationID,
    productName: op.varieties[0].productName,
    date: op.varieties[0].date,
    variety: null,
    fileType: op.varieties[0].fileType,
    attribute: op.varieties[0].attribute,
    cellsize: op.varieties[0].cellsize,
    operationCost: op.varieties[0].operationCost,
    price: op.varieties[0].price,
    unit: op.varieties[0].unit,
    name: op.varieties[0].productName + ' (' + op.varieties[0].date + ')',
    seedingOperationID: op.varieties[0].operationID
  }

  const summaryRow = (operation, i) => {
    const { unit } = operation[0];
    const acres = operation.map((x) => x.area).reduce((a, b) => (a + b), 0);
    let quantity = 0;
    let price = 0;
    let costPerAcre = 0;

    for (const op of operation) {
      const percent = op.area / acres;
      quantity += (op.quantity / op.area) * percent;
      const opPrice = op.price * percent;
      const opCostPerAcre = (op.price * (op.quantity / op.area)) * percent;
      if (!(Number.isNaN(opPrice))) {
        price += opPrice;
      }
      if (!Number.isNaN(opCostPerAcre)) {
        costPerAcre += opCostPerAcre;
      }
    }

    return (
      <Box key={i} className={classes.summaryTableRow}>
        <Box mx={1} style={{ width: '20%' }}>
          Summary:
        </Box>

        <Box px={1} style={{ width: '15%', whiteSpace: 'nowrap' }}>
          {numFormat(acres)}
          {' Acres'}
        </Box>

        <Box mx={1} style={{ width: '20%', whiteSpace: 'nowrap' }}>
          <CustomToolTip
            placement="top"
            title="Weighted average of unit per acre"
          >
            <Box>
              {`${numFormat(quantity, 0, 2)} `}
              {capitalizeFirstLetter(unitConversion(unit, true))}
              {'/Acre'}
            </Box>
          </CustomToolTip>
        </Box>

        <Box mx={1} style={{ width: '25%', whiteSpace: 'nowrap' }}>
          <CustomToolTip
            placement="top"
            title="Weighted average of variety prices"
          >
            <Box>
              {'$'}
              { perAcre
                ? numFormat(price, 2, getDecimalPlaces(price))
                : numFormat((price * acres), 2, getDecimalPlaces(price))}
              {`/${capitalizeFirstLetter(unitConversion(unit, true))}`}
            </Box>
          </CustomToolTip>
        </Box>

        <Box mx={1} style={{ width: '20%' }}>
          <CustomToolTip
            placement="top"
            title="Weighted average of ($/Acre)"
          >
            <Box>
              {'$'}
              {
                perAcre
                  ? `${numFormat(costPerAcre, 2, getDecimalPlaces(costPerAcre))}/Acre`
                  : numFormat((costPerAcre * acres), 2, getDecimalPlaces(costPerAcre * acres))
               }
            </Box>
          </CustomToolTip>
        </Box>
      </Box>
    );
  };

  return (
    <Box
      key={i}
      my={2}
      border={1}
      boxShadow={1}
      borderColor={grey}
      borderRadius="borderRadius"
      style={{ minWidth: '700px', maxWidth: '1000px', overflowX: 'auto' }}
    >
      <Box className={classes.head}>
        <Box mx={1} p={1} className={classes.headText}>
          {op.source === ('Ag-Analytics' || 'USERENTERED' || undefined) && (
            <SyncAltIcon />
          )}
          <Box mx={1}>{op.name}</Box>
          <Divider orientation="vertical" />
          <Box mx={1}>{op.date}</Box>
        </Box>
        <Box mx={1} display="flex" alignItems="center">
          {(width >= 1024 && op.source.toLowerCase() === "deere") && (
            <Box className={classes.icon} mx={1} onClick={() => syncOperation(op)}>
              <CustomToolTip
                title="Sync Operation With Precision Data"
                placement="top"
              >
                <SyncIcon fontSize="small" />
              </CustomToolTip>
            </Box>
          )}
              
          { width >= 1024 && (
            <Box className={classes.icon} mx={1} onClick={() => handleEdit(op, wholeOperationMap)}>
              <EditIcon fontSize="small" />
              {' Edit'}
            </Box>
          )}

          { width >= 1024 && (
            <Box
              mx={1}
              className={classes.icon}
              onClick={() => displayOperationMap(wholeOperationMap/*, varieties.length > 0*/)}
            >
              <MapIcon fontSize="small" />
              {' MAP'}
            </Box>
          )}

          {shown > 0 ? (
            <Box
              className={classes.icon}
              mx={1}
              onClick={() => handleDisplay(op, 1)}
            >
              <VisibilityOffIcon fontSize="small" />
              Hide
            </Box>
          ) : (
            <Box
              className={classes.icon}
              mx={1}
              onClick={() => handleDisplay(op, 0)}
            >
              <VisibilityIcon fontSize="small" />
              Show
            </Box>
          )}
        </Box>

      </Box>

      <Divider style={{ height: '3px' }} />

      <Box className={classes.labels}>
        <Box display="flex" alignItems="center" style={{ width: '20%' }}>
          <Box style={{ width: '25px' }}>
            {
              (displaySummaries.includes(index) && varieties.length > 1)
                ? (
                  <ExpandLessIcon
                    className={classes.hidden}
                    onClick={() => {
                      const filtered = displaySummaries.filter((x) => x !== index);
                      setDisplaySummaries(filtered);
                    }}
                  />
                )
                : varieties.length > 1
                  ? (
                    <ExpandMoreIcon
                      className={classes.hidden}
                      onClick={() => setDisplaySummaries([...displaySummaries, i])}
                    />
                  )
                  : ''
            }
          </Box>

          <Box flexGrow={1}>
            { varieties.length === 1 ? ' Variety' : ' Varieties' }
          </Box>
        </Box>
        <Box px={1} style={{ width: '15%' }}>
          {'Acres'}
          <CustomToolTip
            title={outliersAndAnomalies}
            placement="right-start"
          >
            <InfoOutlinedIcon className={classes.infoToolTip} />
          </CustomToolTip>
        </Box>
        <Box px={1} style={{ width: '20%' }}>
          {type === 'Harvest'
            ? 'Yield/Acre'
            : `${capitalizeFirstLetter(unitConversion(unit, true))}/Acre`}
          <CustomToolTip
            title={outliersAndAnomalies}
            placement="right-start"
          >
            <InfoOutlinedIcon className={classes.infoToolTip} />
          </CustomToolTip>
        </Box>
        <Box px={1} style={{ width: '25%' }}>
          { `Price: $/${capitalizeFirstLetter(unitConversion(unit, true))}` }
          <CustomToolTip
            title={outliersAndAnomalies}
            placement="right-start"
          >
            <InfoOutlinedIcon className={classes.infoToolTip} />
          </CustomToolTip>
        </Box>
        <Box px={1} style={{ width: '20%' }}>
          {
            type === 'Harvest'
              ? 'Revenue'
              : 'Cost'
          }
          { perAcre ? ' ($/Acre)' : '($)' }
        </Box>
      </Box>

      <Box>
        {
          (varieties.length === 1 || displaySummaries.includes(index))
            && varieties.map((x, j) => (
              <CreateRow
                key={j}
                variety={x}
                i={j}
                opIndex={i}
                shown={shown}
                perAcre={perAcre}
                handlePriceChange={handlePriceChange}
                savePrice={savePrice}
                savingPrice={savingPrice}
                templateMode={templateMode}
              />
            ))
        }
      </Box>

      {
        varieties.length > 0
        && (
        <Box>
          <Divider style={{ height: '3px' }} />

          {summaryRow(varieties)}
        </Box>
        )
      }
      <Divider />
    </Box>
  );
}

import React, { useState, useEffect, } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Modal, Button} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { Integrate } from '../Partners/Integrate'

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.modalPaper,
    backgroundColor: theme.palette.greys.light,
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 8,
    right: 8,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    height: 'auto',
    minWidth: 400,
    width: '80%',
    maxWidth: 1000,
    padding: 1,
  };
}

export function IntegrateModal({open, setOpen}) {
  const classes = useStyles();
  const modalStyle = getModalStyle()

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto'
      }}
    >
      <Box
        style={modalStyle}
        className={classes.paper}
        borderRadius="borderRadius"
        boxShadow={2}
      >
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpen(false)}
        />
        <Box mt={2}>
          <Integrate
            header={false}
          />
        </Box>

      </Box>
    </Modal>
  )
}

import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import {
  blackText, grey,
} from '../../../styles/colors';
import { MenuProps } from '../../../styles/select';
import { NumberFormatCustom } from '../../../utils/NumberFormatCustom';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  input: {
    width: 120,
    margin: '0px',
  },
  boxLabel: theme.boxLabel,
  infoIcon: {
    ...theme.infoToolTip,
    marginLeft: '2px',
    marginBottom: '10px'
  },
  quoteBox: {
    height: 74.5,
    marginRight: '16px'
  },
  inputName: {
    height: 25
  },
}));

export function QuoteInputs({
  handleChange,
  types,
  type,
  practices,
  practice,
  acres,
  projectedPrice,
  approvedYield,
  enqueueSnackbar,
  disableUserInputs
}) {
  const classes = useStyles();

  const [buttonPressed, setButtonPressed] = useState(0);

  const remindOfButton = () => {
    if (buttonPressed === 0 || buttonPressed === 9) {
      enqueueSnackbar(`Note: You must click "RECALCULATE PREMIUM" after changing any of the Quote Inputs for the changes to apply.`);
    }
    else if (buttonPressed > 9) {
      return;
    }
    setButtonPressed(prev => prev+1)
  }

  return (
    <Box
      mx={1}
      color={blackText}
      fontWeight={500}
    >
      <Box
        color={grey}
        className={classes.boxLabel}
      >
        Quote Inputs
      </Box>
      <Box
        p={1}
        border={1}
        borderRadius="borderRadius"
        borderColor={grey}
        display='flex'
        flexWrap='wrap'
        minHeight={98}
        paddingTop="13px"
      >
        <Box className={classes.quoteBox}>
          <Box className={classes.inputName}>
            Type
            <CustomToolTip
              placement="right"
              title="Commodity covered."
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={type !== null ? type : ''}
            onChange={(e) => {
              handleChange(e.target.value, 'type')
              remindOfButton()
            }}
            disabled={!!disableUserInputs}
          >
            {
              types.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x}
                >
                  {x.typeName}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box className={classes.quoteBox}>
          <Box className={classes.inputName}>
            Practice
            <CustomToolTip
              placement="right"
              title="Practices covered under crop insurance. For example, Irrigation status or Organic production."
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={practice !== null ? practice : ''}
            onChange={(e) => {
              handleChange(e.target.value, 'practice')
              remindOfButton()
            }}
            disabled={!!disableUserInputs}
          >
            {
              practices.map((x, i) => (
                <MenuItem
                  key={i}
                  value={x}
                >
                  {x.practiceName}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box className={classes.quoteBox}>
          <Box className={classes.inputName}>
            Acres
          </Box>
          <TextField
            className={classes.input}
            variant="outlined"
            value={acres}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onKeyUp={(e) => {
              handleChange(Number(e.target.value.replaceAll(',', '')), 'acres')
              remindOfButton()
            }}
            inputProps={{
              style: {
                padding: 12,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
            }}
            disabled={!!disableUserInputs}
          />
        </Box>

        <Box className={classes.quoteBox}>
          <Box className={classes.inputName}>
            Projected Price
            <CustomToolTip
              placement="right"
              title="The Projected Price is the predicted price for the selected crop at the harvesting period of the crop. This field is populated for you, but you may enter your own value if desired."
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>
          <TextField
            className={classes.input}
            variant="outlined"
            value={projectedPrice}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onKeyUp={(e) => {
              handleChange(Number(e.target.value.replaceAll(',', '')), 'projectedPrice')
              remindOfButton()
            }}
            inputProps={{
              style: {
                padding: 12,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              startAdornment: '$',
            }}
            disabled={!!disableUserInputs}
          />
        </Box>

        <Box className={classes.quoteBox} style={{ margin:0 }}>
          <Box className={classes.inputName}>
            Approved Yield
            <CustomToolTip
              placement="right"
              title="Historic Yield level covered under crop insurance. These are prepopulated based on previous years for your county. If you are using Trend Adjustment (TA) or Yield Exclusion (YE), this should include the yield adjustment according to those adjustment practices. If not using these adjustments, this is your average yield over past years."
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </CustomToolTip>
          </Box>
          <TextField
            className={classes.input}
            style={{width: "150px"}}
            variant="outlined"
            value={approvedYield}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onKeyUp={(e) => {
              handleChange(Number(e.target.value.replaceAll(',', '')), 'approvedYield')
              remindOfButton()
            }}
            inputProps={{
              style: {
                padding: 12,
                fontWeight: 500,
                fontSize: 14,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: 'Bu./Acre',
            }}
            disabled={!!disableUserInputs}
          />
        </Box>
      </Box>

    </Box>
  );
}

/* eslint-disable max-len */
/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, Typography, Card, CardActionArea, CardContent, CardActions,
} from '@material-ui/core';
import ReactGA from 'react-ga'
import YouTube from 'react-youtube';
import { blackText } from '../../styles/colors';
import { Endpoints } from '../../constants/Endpoints';
import digsLogo from '../Digs/images/digs-logo.png';
import deere from '../../images/deere_clear.png';
import climate from '../../images/climate_clear.png';
import cnh from '../../images/cnh_clear.png';
import soilInfo from '../../images/soiltesting_infographicLargerText.png';
import ARInfo from '../../images/AR_infographic.png'

const useStyles = makeStyles((theme) => ({
  card: {
    maxWidth: 345,
  },
  media: {
    height: 90,
    // paddingTop: '56.25%', // 16:9
  },
  buttonLink: {
    marginTop: 4,
    textTransform: 'none',
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  containedButtonLink: {
    marginTop: 4,
    textTransform: 'none',
    '&:hover': {
      color: '#ffffff',
    },
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 8,
    maxWidth: 380,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  link: {
    '&:hover': {
      color: theme.palette.primary.dark,
    },
  },
  cardText: {
    '&:hover': {
      color: theme.palette.text.primary,
    },
  },
  integrateImage: {
    height: 42,
    width: 'auto',
    margin: '6px 12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },

  integrateImageMedium: {
    height: 62,
    width: 'auto',
    margin: '6px 12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  integrateImageLarge: {
    height: 74,
    width: 'auto',
    margin: '6px 12px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

export const GetUav = ({ direction = 'column' }) => {
  const classes = useStyles();

  return (
    <Card style={{ maxWidth: direction === 'column' ? 345 : 600 }}>
      <CardActionArea
        target="_blank"
        href={`${Endpoints.HOME}/app/uav`}
        style={{ textDecoration: 'none', display: 'flex', flexDirection: direction }}
      >
        <Box mt={0.5} display="flex" justifyContent="center" height={200}>
          <YouTube
            opts={{
              width: 'auto',
              height: 200,
            }}
            videoId="wco8S4KoeBY"
          />
        </Box>

        <CardContent
          target="_blank"
          href={`${Endpoints.HOME}/app/uav`}
          className={classes.cardText}
        >
          <Typography gutterBottom variant="h6" component="h2">
            Looking for high-quality imagery?
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            On-Demand high-quality imagery and accurate diagnostics reports for as little as $2.40/Acre
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          className={classes.link}
          size="small"
          color="primary"
          href="https://www.profit.ag/contact"
          target="_blank"
        >
          Request Demo
        </Button>
        <Button
          className={classes.link}
          size="small"
          color="primary"
          target="_blank"
          href={`${Endpoints.HOME}/app/uav`}
          style={{ textDecoration: 'none' }}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export const GetWaterManagement = ({ direction = 'column' }) => {
  const classes = useStyles();

  return (
    <Card style={{ maxWidth: direction === 'column' ? 345 : 600 }}>
      <CardActionArea
        target="_blank"
        href={`${Endpoints.HOME}/app/watermanagement`}
        style={{ textDecoration: 'none', display: 'flex', flexDirection: direction }}
      >
        <Box display="flex" justifyContent="center">
          <img src={digsLogo} alt="digs logo" />
        </Box>

        <CardContent
          target="_blank"
          href={`${Endpoints.HOME}/app/watermanagement`}
        >
          <Typography gutterBottom variant="h6" component="h2">
            Need Water Management?
          </Typography>
          <Typography variant="body2" color="textPrimary" component="p">
            Easily schedule high-quality water management and engineering consultations and services for your field
          </Typography>
        </CardContent>
      </CardActionArea>
      <CardActions>
        <Button
          className={classes.link}
          size="small"
          color="primary"
          href="https://www.profit.ag/contact"
          target="_blank"
        >
          Request Demo
        </Button>
        <Button
          className={classes.link}
          size="small"
          color="primary"
          target="_blank"
          href={`${Endpoints.HOME}/app/watermanagement`}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export const UseAcreageReporting = ({direction = 'column'}) => {
  const classes = useStyles();

  return (
    <Card style={{ maxWidth: direction === 'column' ? 345 : 600 }}>
      <CardActionArea
        target="_blank"
        href={`${Endpoints.HOME}/app/AcreageReporting`}
        style={{ textDecoration: 'none', display: 'flex', flexDirection: direction }}
      >
        <CardContent
          py={1}
          target="_blank"
          href={`${Endpoints.HOME}/app/AcreageReporting`}
        >
          <Typography gutterBottom variant="h6" component="h2">
            Simplify  Your Acreage Reporting
          </Typography>
          <Box display="flex" justifyContent="center">
            <img src={ARInfo} alt="Acreage Reporting" style={{width: direction === 'column' ? 325 : 550}}/>
          </Box>
          <Typography variant="body2" color="textPrimary" component="p">
            Easily create and submit acreage reports for federal crop insurance directly to the USDA Clearinghouse, for distribution to FSA and your AIP
          </Typography>
        </CardContent>
      </CardActionArea>

      <CardActions>
        <Button
          className={classes.link}
          size="small"
          color="primary"
          href="https://www.profit.ag/contact"
          target="_blank"
        >
          Request Demo
        </Button>
        <Button
          className={classes.link}
          size="small"
          color="primary"
          target="_blank"
          href={"https://www.profit.ag/acreagereporting"}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
}

export const GetSoilTesting = ({ direction = 'column' }) => {
  const classes = useStyles();

  return (
    <Card style={{ maxWidth: direction === 'column' ? 380 : 600 }}>
      <CardActionArea
        target="_blank"
        href={`${Endpoints.HOME}/app/soil`}
        style={{ textDecoration: 'none', display: 'flex', flexDirection: direction }}
      >

        <Box
          py={1}
          target="_blank"
          href={`${Endpoints.HOME}/app/soil`}
        >
          <Typography gutterBottom variant="h6" style={{ padding: '0 8px' }}>
            Looking for Soil Testing Services?
          </Typography>

          <Box display="flex">
            <img src={soilInfo} alt="soil info-graphic" height="auto" width="100%" />
          </Box>
        </Box>
      </CardActionArea>

      <CardActions>
        <Button
          className={classes.link}
          size="small"
          color="primary"
          href="https://www.profit.ag/contact"
          target="_blank"
        >
          Request Demo
        </Button>
        <Button
          className={classes.link}
          size="small"
          color="primary"
          target="_blank"
          href={`${Endpoints.HOME}/app/soil`}
        >
          Learn More
        </Button>
      </CardActions>
    </Card>
  );
};

export const VisitApimElevation = () => {
  const classes = useStyles();
  return (
    <Box
      display="flex"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      fontWeight={500}
      fontSize={16}
      color={blackText}
    >
      <Box>
        Learn more about elevation data
      </Box>
      <Button
        className={classes.buttonLink}
        variant="outlined"
        color="primary"
        href={`${Endpoints.APIM}/docs/services/elevation-index`}
        target="_blank"
      >
        Learn More
      </Button>
    </Box>
  );
};

export const ProfitLayersLink = () => {
  const classes = useStyles();
  return (
    <Box className={classes.root}>
      <Typography align="center">
        See in depth analysis on how your Soil Data relates to profits by visiting
        {' '}
        <b>PROFIT LAYERS</b>
        <sup>&reg;</sup>
      </Typography>
      <Box p={1} display="flex" justifyContent="space-around">
        <Button
          className={classes.containedButtonLink}
          variant="contained"
          color="primary"
          target="_blank"
          href={`${Endpoints.HOME}/app/ProfitLayers`}
          disableElevation
        >
          Go to PROFIT LAYERS
          <sup>&reg;</sup>
        </Button>
        <Button
          className={classes.buttonLink}
          variant="outlined"
          color="primary"
          target="_blank"
          href="https://www.profit.ag/"
          disableElevation
        >
          Learn More
        </Button>
      </Box>

    </Box>
  );
};

export const IntegrationLinks = ({ onConnect }) => {
  const classes = useStyles();

  return (
    <Card className={classes.card}>
      <Typography align="center" variant="subtitle2">
        Connect your preferred Precision Ag system
      </Typography>

      <Box display="flex" justifyContent="center">
        <Button onClick={() => onConnect()}>
          <img className={classes.integrateImage} src={climate} alt="climate logo" />
        </Button>

        <Button onClick={() => onConnect()}>
          <img className={classes.integrateImage} src={deere} alt="deere logo" />
        </Button>

        <Button onClick={() => onConnect()}>
          <img className={classes.integrateImage} src={cnh} alt="cnh logo" />
        </Button>
      </Box>
    </Card>
  );
};

export const Connections = ({ onConnect, authenticated }) => {
  const classes = useStyles();

  return (
    <Card>
      <Typography align="center" style={{ fontSize: '1.2rem', fontWeight: 500 }}>
        Connect your preferred Precision Ag system
      </Typography>

      <Box display="flex" justifyContent="space-around" alignItems="center" flexWrap="wrap">
        <Box
          className={classes.hover}
          onClick={() => onConnect()}
        >
          <img className={classes.integrateImageLarge} src={climate} alt="climate logo" />
        </Box>

        <Box
          className={classes.hover}
          onClick={() => onConnect()}
        >
          <img className={classes.integrateImageMedium} src={deere} alt="deere logo" />
        </Box>

        <Box
          className={classes.hover}
          onClick={() => onConnect()}
        >
          <img className={classes.integrateImageMedium} src={cnh} alt="cnh logo" />
        </Box>
      </Box>

      {!authenticated && (
        <Box my={1} display="flex" justifyContent="center">
          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleLogin()}
            style={{ margin: '0 8px' }}
            disableElevation
          >
            SIGN IN
          </Button>

          <Button
            color="primary"
            variant="outlined"
            onClick={() => handleSignUp()}
            style={{ margin: '0 8px' }}
            disableElevation
          >
            CREATE AN ACCOUNT
          </Button>
        </Box>
      )}
    </Card>
  );
};

const handleLogin = () => {
  ReactGA.event({
    category: "User",
    action: "Sign In"
  })
  window.location.assign(Endpoints.BASEURL + Endpoints.SIGNIN)
}

const handleSignUp = () => {
  ReactGA.event({
    category: "User",
    action: "Sign Up"
  })
  window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUP)
}

export const IntegrateLink = () => {
  const classes = useStyles();
  return (
    <Box>
      <Typography>
        Connect your Precision Ag system for more advanced insights, analysis, and features.
      </Typography>
      <Button
        className={classes.buttonLink}
        variant="contained"
        color="primary"
        target="_blank"
        href={`${Endpoints.HOME}/app/integrate`}
        disableElevation
      >
        integrate now
      </Button>
    </Box>
  );
};

export const drainageResponse = {
  "orderId": "b0b4d5b3",
  "orderName": "Water Management",
  "orderDate": "Wed Aug 05 2020 13:35:21 GMT-0400 (Eastern Daylight Time)",
  "orderCompletedDate": "Wed Jul 15 2020 17: 53: 38 GMT-0700 (Pacific Daylight Time)",
  "orderLink": "https: //www.paypal.com/invoice/p/#*********",
  "farmerDetails":{
    "fullName": "Swathi",
    "eMail": "swathi@ag-analytics.org",
    "phone": "1111111111",
    "mailingAddress": "asd",
    "city": "asd",
    "state": "Indiana",
    "zipCode": "14850"
  },
  "organizationName": "Test",
  "organizationID": "",
  "totalCost": 645.4478211000236,
  "totalAcres": 80.68097763750295,
  "subOrderDetails": [
    {
      "subOrderId": "fe36b2ae-0c67-477f-816a-f557ebdc5ede",
      "fieldId": "8rl33n",
      "fieldName": "Field-2",
      "acres": 80.68098,
      "state": "Illinois",
      "county": "Livingston",
      "Latitude": 40.864646829824892,
      "Longitude": -88.430625825758725,
      "boundary": "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-88.42788090298126,40.8691524868978],[-88.43262175738818,40.869150639690304],[-88.43255538562249,40.86629777252426],[-88.43244294608508,40.86178027553933],[-88.43241208944636,40.86174633782326],[-88.43154587324341,40.86175709281565],[-88.42773814190167,40.8618092040316],[-88.4278095094013,40.86548082927692],[-88.42788090298126,40.8691524868978]]],\"bbox\":[-88.43262175738818,40.86174633782326,-88.42773814190167,40.8691524868978]},\"properties\":{\"CALCACRES\":80.56999969,\"OBJECTID\":4908470}}",
      "shapefiles": [
        "https://drive.google.com/uc?export=download&id=1rSx0WDA_0J4mxN37C5neoSLUDmoziV0s"
      ],
      "pdfLink": [
        "https://drive.google.com/uc?export=download&id=1C_byAVXszKzE178k__dW3pTVebWFcsG1",
        "https://drive.google.com/uc?export=download&id=1UmGEA9rjLcGfHBJzT_EUS-Mi-Qjh717L"
      ],
      "engineeringOperator": "abc",
      "engineeringOperatorEmail": "abc@xyz.com",
      "engineeringOperatorPhone": "*******",
      "engineeringOperatorCompany": "DIGS"
    }
  ]
}
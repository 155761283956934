/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Snackbar, Box, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { green } from '../../../styles/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.greys.dark}`,
    borderRadius: 8,
    backgroundColor: '#ffffff',
    fontSize: '1.1rem',
    width: 300,
  },
  closeIcon: {
    ...theme.closeIcon,
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  image: {
    height: 24,
    width: 'auto',
  },
}));

export const roundIcon = {
  width: '18px',
  height: '18px',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 2,
  borderRadius: '50%',
  marginRight: '6px',
  borderColor: green,
};

/**
 * Instructions for grid selection
 * @param {Bool} open open
 * @param {function} setOpen sets open or close
 * @returns {JSX} Instructions
 */
export const GridInstructions = ({ open, setOpen }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      key="bottomleft"
      style={{ marginBottom: 46 }}
    >
      <Box className={classes.root} boxShadow={5}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box width={26}>{' '}</Box>
          <Box pt={1} fontSize="1.5rem" fontWeight={500}>
            Set Sample Grids
          </Box>
          <Box pt={0.5} px={0.5} display="flex" justifyContent="flex-end">
            <HighlightOffIcon className={classes.closeIcon} onClick={handleClose} />
          </Box>
        </Box>

        <Box my={1} px={2}>
          <Box display="flex" alignItems="center">
            <Box {...roundIcon} color={green}>1</Box>
            <Box pb="2px">Select Grid Size</Box>
          </Box>
          <Box p={0.5}>
            <Typography>
              Choose from 2.5 or 5 acre grid tiles
            </Typography>
          </Box>
        </Box>

        <Box my={1} px={2}>
          <Box display="flex" alignItems="center">
            <Box {...roundIcon} color={green}>2</Box>
            <Box pb="2px">Align the grid</Box>
          </Box>
          <Box p={1}>
            <Box mt={0.5} display="flex" alignItems="center">
              <svg width="24px" height="24px" viewBox="0 0 24 24" version="1.1">
                <g id="Icons" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                  <g id="Two-Tone" transform="translate(-236.000000, -2724.000000)">
                    <g id="Image" transform="translate(100.000000, 2626.000000)">
                      <g id="Two-Tone-/-Image-/-crop_rotate" transform="translate(136.000000, 98.000000)">
                        <g>
                          <polygon id="Path" points="0 0 24 0 24 24 0 24" />
                          <path d="M11.95,24 C12.18,24 12.39,23.98 12.61,23.97 L8.8,20.15 L7.47,21.49 C4.2,19.93 1.86,16.76 1.5,13 L0,13 C0.51,19.16 5.66,24 11.95,24 Z M12.05,0 C11.82,0 11.61,0.02 11.39,0.04 L15.2,3.85 L16.53,2.52 C19.8,4.07 22.14,7.24 22.5,11 L24,11 C23.49,4.84 18.34,0 12.05,0 Z M16,6 L10,6 L10,8 L16,8 L16,14 L18,14 L18,8 C18,6.89 17.1,6 16,6 Z M18,18 L20,18 L20,16 L8,16 L8,4 L6,4 L6,6 L4,6 L4,8 L6,8 L6,16 C6,17.1 6.89,18 8,18 L16,18 L16,20 L18,20 L18,18 Z" id="🔹-Primary-Color" fill="#1D1D1D" />
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </svg>
              <Box ml={1}>
                <Typography>
                  Rotate
                </Typography>
              </Box>
            </Box>
            <Box mt={0.5} display="flex" alignItems="center">
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
                enableBackground="new 0 0 64 64"
                xmlSpace="preserve"
              >
                <polyline
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinejoin="bevel"
                  strokeMiterlimit="10"
                  points="54.083,51 63.083,42 54.083,33 "
                />
                <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="63" y1="42" x2="9" y2="42" />
                <polyline
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinejoin="bevel"
                  strokeMiterlimit="10"
                  points="10.083,13 1.083,22 10.083,31 "
                />
                <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="1" y1="22" x2="55" y2="22" />
              </svg>
              <Box ml={1}>
                <Typography>
                  Shift East-West
                </Typography>
              </Box>
            </Box>
            <Box mt={0.5} display="flex" alignItems="center">
              <svg
                version="1.1"
                id="Layer_1"
                x="0px"
                y="0px"
                width="24px"
                height="24px"
                viewBox="0 0 64 64"
                enableBackground="new 0 0 64 64"
                xmlSpace="preserve"
              >
                <polyline
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinejoin="bevel"
                  strokeMiterlimit="10"
                  points="51.083,10 42.083,1 33.083,10 "
                />
                <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="42.083" y1="1" x2="42" y2="55" />
                <polyline
                  fill="none"
                  stroke="#000000"
                  strokeWidth="2"
                  strokeLinejoin="bevel"
                  strokeMiterlimit="10"
                  points="13.083,54 22.083,63 31.083,54 "
                />
                <line fill="none" stroke="#000000" strokeWidth="2" strokeMiterlimit="10" x1="22.083" y1="63" x2="22" y2="9" />
              </svg>
              <Box ml={1}>
                <Typography>
                  Shift North-South
                </Typography>
              </Box>
            </Box>
          </Box>

        </Box>

        <Box my={1} px={2}>
          <Box display="flex" alignItems="center">
            <Box {...roundIcon} color={green}>3</Box>
            <Box pb="2px">Confirm the grid</Box>
          </Box>
          <Box p={0.5}>
            <Typography>
              Click the &#34;Confirm Grid&#34; button above the map when complete
            </Typography>
          </Box>

        </Box>

      </Box>
    </Snackbar>
  );
};

import React, { useState, useEffect, useRef } from 'react';
import braintree from 'braintree-web-drop-in';
import { BrainTreeDropIn } from './BrainTreeDropInReact';
import { Endpoints } from '../../../constants/Endpoints';
import {
  Box, Button, Typography, Tabs, Tab, Divider, CircularProgress,
} from '@material-ui/core';
import { SpinningLoader } from '../../Shared/SpinningLoader'

const renderSubmitButton = ({ onClick, isDisabled, text }) => {
  return (
    <Button variant="contained"
      color="primary"
      onClick={onClick}
      disabled={isDisabled}
      style={{ width: '100%', marginBottom: '10px' }}
      disableElevation
    >
      PURCHASE
    </Button>
  )
}

export function PaymentApp({ authenticated, setProcessing, setProfitMapAccess, field, handlePayment, source = "ProfitLayers", selectedOrgId, userToken, orgs, referralcode = "" }) {
  const [isLoading, setIsLoading] = useState(true)
  const [paymentNonce, setPaymentNonce] = useState(null)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [displayMessage, setDisplayMessage] = useState(false)
  const [message, setMessage] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [conuntryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [taxAmount, setTaxAmount] = useState(0)
  const [purchaseHasBeenClicked, setPurchaseHasBeenClicked] = useState(false)
  const [disablePurchaseButton, setDisablePurchaseButton] = useState(false)
  const [savingCustomer, setSavingCustomer] = useState(false)
  const [stateId, setStateId] = useState(null)
  const [dealerInformation, setDealerInformation] = useState(null)
  const [showErrorMessgae, setShowErrorMessage] = useState(false)
  const [customerInformation, setCustomerInfotmation] = useState(null)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)

  useEffect(() => {
    if (authenticated && paymentNonce === null) {
      //getCurrentCustomerInfo()
      if (selectedOrgId !== -1) {
        checkCustomerExists()
      }
    }
  }, [authenticated, selectedOrgId])

  useEffect(() => {
    // console.log("SELECTED OG ID", selectedOrgId)
    if (customerInformation !== null) {
      if (selectedOrgId !== customerInformation.organization[0].organizationId) {
        getCurrentCustomerInfo(selectedOrgId)
      }
    }

  }, [selectedOrgId])

  useEffect(() => {
    //console.log("Customer information", customerInformation)
  }, [customerInformation])

  const handleSave = () => {
    setSavingCustomer(true)
  }

  const checkCustomerExists = async () => {
    setIsLoading(true)
    try {
      let response = await fetch(Endpoints.BASEURL + Endpoints.CUSTOMER_EXISTS, {
        method: 'GET',
        credentials: 'include',
        headers: userToken !== '' ?
          {
            Accept: "application/json",
            "Authorization": 'Bearer ' + userToken
          }
          :
          {
            Accept: "application/json",
          }
        ,
      })
      let jsonResponse = await response.json();
      if (jsonResponse === true) {
        getCurrentCustomerInfo(selectedOrgId)
      }
      else {
        saveCustomerInfo()
        //getPlanInfo()
      }
    }
    catch (ex) {
      console.log("checkCustomerExists", ex)
      setIsLoading(false)
    }
  }

  const saveCustomerInfo = async () => {
    let request = {
      IsAzureRegistration: true
    }

    let response = await fetch(Endpoints.BASEURL + Endpoints.API_CUSTOMER, {
      method: 'POST',
      headers: userToken !== '' ?
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
          'Authorization': 'Bearer ' + userToken
        }
        :
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      , credentials: 'include',
      body: JSON.stringify(request)
    })
    let jsonResponse = await response.json();
    getCurrentCustomerInfo(selectedOrgId)
  }

  const getPlanInfo = async () => {

  }

  const getCurrentCustomerInfo = async (orgId = selectedOrgId) => {
    try {
      let url;
      if (orgId != null) {
        url = `${Endpoints.BASEURL}${Endpoints.CURRENT}?OrgID=${orgId}`
      } else {
        url = `${Endpoints.BASEURL}${Endpoints.CURRENT}`
      }
      let response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: userToken !== '' ? {
          Accept: "application/json",
          'Authorization': 'Bearer ' + userToken
        }
          : {
            Accept: "application/json",
          },
      })
      let jsonResponse = await response.json();
      //console.log("jsonResponse", jsonResponse)
      setCustomerInfotmation(jsonResponse)
      generatePaymentMethodNonce()
    }
    catch (ex) {
      setIsLoading(false)
      console.log("getCurrentCustomerInfo", ex)
    }
  }

  const handlePaymentMethod = async (payload) => {
    setProcessing(true)
    let referralCode = "", orgSource = ""

    //Check if user has enetered referral code, if yes use entered referral code, if not use standard 50% discount
    if(referralcode !== ''){
      referralCode = referralcode
    }
    else{
      //referralCode = Endpoints.PROFITLAYERS_50PER
    }

    let customer = '', customerInfo = '', subscription = ''
    let customerOrgId = ''

    if (selectedOrgId === undefined) {
      customerOrgId = customerInformation.organization.filter(org => field.orgId === org.organizationId)[0].customerOrganizationId
    }
    else {
      customerOrgId = customerInformation.organization.filter(org => selectedOrgId === org.organizationId)[0].customerOrganizationId
    }

    //let customerOrgId = selectedOrgId;

    let feature;
    let customerData;
    let subscriptionData;

    if (source === "esri") {
      feature = {
        "featureId": 49,
        "price": Endpoints.ESRIADDIN_PRICE,
        "quantity": 1,
        "NeverExpires": true,
        "NumberOfBillingCycles": 0,
        "ReferralCode": referralCode,
        "customerOrganizationId": customerOrgId
      }

      subscriptionData = {
        PlanId: 2,
        StringSubscriptionStatus: "Pending",
        PaymentMethodNonce: payload.nonce,
        BillingDayOfMonth: 5,
        NeverExpires: true,
        NumberOfBillingCycles: 0,
        TrialDuration: 1,
        TrialDurationUnit: true,
        TaxAmount: customerInformation.state !== null && customerInformation.state.code === "NY" ? "20.00" : "",
        ReferralCode: referralCode,
        Features: [feature]
      }
    } else {
      feature = {
        "featureId": 48,
        "price": Endpoints.PROFITLAYERS_PRICE,
        "quantity": 1,
        "NeverExpires": true, //this means that we charge monthly/yearly based on the plan
        "NumberOfBillingCycles": 0,
        "ReferralCode": referralCode,
        "customerOrganizationId": customerOrgId
      }
      subscriptionData = {
        PlanId: 2,
        StringSubscriptionStatus: "Pending",
        PaymentMethodNonce: payload.nonce,
        BillingDayOfMonth: 5,
        NeverExpires: true,
        NumberOfBillingCycles: 0,
        TrialDuration: 1,
        TrialDurationUnit: true,
        TaxAmount: customerInformation.state !== null && customerInformation.state.code === "NY" ? "20.00" : "",
        ReferralCode: referralCode,
        Features: [feature]
      }
    }


    customerData = {
      EmailId: customerInformation.emailId,
      FirstName: "",
      LastName: "",
      Phone: customerInformation.phone,
      StateId: customerInformation.stateId,
      CountryId: customerInformation.countryId,
      ApiKey: customerInformation.apiKey,
      Organization: customerInformation.organization
    }

    //console.log("customerData", customerData)
    setIsSubmitButtonDisabled(true)
    await handlePayment(customerData, subscriptionData)
    
  }

  const onError = () => {
    setShowErrorMessage(true)
  }
  const onCreate = () => {

  }

  const generatePaymentMethodNonce = async () => {
    let url = Endpoints.BASEURL + Endpoints.API_CUSTOMER_PAYMENT_NONCE
    // console.log("userToken", userToken)
    try {
      let response;
      if (userToken !== undefined && userToken !== '') {
        response = await fetch(url, {
          headers: {
            'Authorization': 'Bearer ' + userToken
          },
          method: "GET"
        })
      }
      else {
        response = await fetch(url, {
          headers: {
            'Authorization': 'Bearer ' + sessionStorage.getItem("jwtAccessToken")
          },
          method: "GET",
          credentials: 'include'
        })
      }

      let jsonResponse = await response.json();
      // console.log("jsonResponse.clientToken", jsonResponse)
      setIsLoading(false)
      setPaymentNonce(jsonResponse.clientToken)
    }
    catch (err) {
      console.log(err)
      setIsLoading(false)
    }
  }

  return (
    <Box>
      {isLoading && (
        <SpinningLoader />
      )}

      <BrainTreeDropIn
        braintree={braintree}
        paypal={{ flow: 'vault' }}
        authorizationToken={paymentNonce}
        renderSubmitButton={renderSubmitButton}
        setProcessing={setProcessing}
        handlePaymentMethod={handlePaymentMethod}
        onError={onError}
        onCreate={onCreate}
        googlePay={{
          merchantId: Endpoints.API_BRAINTREE_GOOGLEPAY_MERCHANTID,
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPrice: 500,
            currencyCode: 'USD'
          },
          cardRequirements: {
            billingAddressRequired: false
          }
        }}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
        source={source}
      />

    </Box>

  )
}

import * as fr from "../../../utils/farmReportDataFetchers";
import { exists, formatDate } from "../../../utils/helpers";
import { getIndividualSeedPrice } from "../../../utils/dataFetchers";

export async function getSeedingForFarm(orgId, farmId, year) {
  try {
    let seedingOps = await fr.getFarmOperations(orgId, farmId, year, "Seeding");
    //console.log('Seeding Ops', seedingOps)

    let map = getSeedingMapData(seedingOps);

    return map;
  } catch (err) {
    console.log("err", err);
  }
}

export const getSeedingMapData = async (seedingOps, setMessage) => {
  //console.log("seedingOps", seedingOps);
  try {
    let map = new Map();
    let seedPriceMap = new Map();

    for (let op of seedingOps.data) {
      //console.log('price ', op.price.toString(), op.price, op)
      if (op.price === null || op.price.toString() === '0') {
        //console.log('get seed price')
        if (exists(op.crop) && exists(op.totalUnit)) {     
          if(seedPriceMap.has(op.crop + "-" + op.totalUnit))   {
            op.price = seedPriceMap.get(op.crop + "-" + op.totalUnit)
          }  
          else{
            // get price if name and unit are available
            setMessage("Fetching seed price...");
            const cropPrice = await getIndividualSeedPrice(op.crop, op.totalUnit);

            // PRICE RETURNED FOR SEEDS ARE FOR 1K SEEDS NEED TO DIVIDE
            const seedPrice = cropPrice / 1000;
            op.price = seedPrice;

            seedPriceMap.set(op.crop + "-" + op.totalUnit, op.price);
            //console.log("seedPriceMap", seedPriceMap)
          }          
        } else {
          op.price = 0;
        }
      }

      op = {
        ...op,
        fileType: "SEEDING",
        cellsize: "0.0001",
        attribute:
          op.varietyRate === null &&
          op.varietyRate === 0 &&
          op.summaryRate === null &&
          op.summaryRate === 0
            ? "TargetRate"
            : "AppliedRate",
        area:
          op.varietyArea === null &&
          op.varietyArea === 0 &&
          op.summaryArea === null &&
          op.summaryArea === 0
            ? op.fieldAcres
            : op.varietyArea,
        operationCost: op.area > 0 ? -op.varietyRate * op.price : 0,
        price: ((op.price + Number.EPSILON) * 10000) / 10000,
        productName: op.crop,
        unit: op.totalUnit,
        date: formatDate(op.seedingDate),
        rate: op.varietyRate !== null ? op.varietyRate : op.summaryRate,
      };
      if (!map.get(op.fieldID)) map.set(op.fieldID, []);
      let data = map.get(op.fieldID);
      //console.log(data)
      data.push(op);
      map.set(op.fieldID, data);
    }
    //console.log('seeding map', map)
    return map;
  } catch (err) {
    console.log("error formatting seeding data", err);
  }
};

export const combineSeedingOps = (ops) => {
  const operations = [];

  for (const op of ops) {
    const match = operations.filter((x) => x.operationID === op.operationID);

    if (match.length > 0) {
      const index = operations.indexOf(match[0]);
      operations[index].varieties.push(op);

      operations[index].hidden = checkHidden(operations[index].varieties);
    } else {
      const operation = {
        varieties: [op],
        date: op.date,
        hidden: op.isDeleted,
        name: op.productName,
        operationID: op.operationID,
        source: op.source,
      };

      operations.push(operation);
    }
  }

  return operations;
};

const checkHidden = (varieties) => {
  // if any varieties are deleted (hidden), hide operation
  const hidden = varieties.filter((x) => x.isDeleted);
  return hidden.length > 0;
};

export const convertSeedsToKseeds = (operations) => {
  for (const operation of operations) {
    if (allVarietiesKseeds(operation)) {
      const updatedVarietes = operation.varieties.map((variety) =>
        convertVarietyToKseeds(variety)
      );
      operation.varieties = updatedVarietes;
    }
  }

  return operations;
};

/**
 * Checks if all varieties of operation are >= 1,000 seeds
 * @param  {Object} operation Operation to check
 * @return {Bool} Should operation varieties be converted to Kseeds
 */
export const allVarietiesKseeds = (operation) => {
  //console.log('allvarietiesKseeds', operation)
  const convertToK =
    operation.varieties.filter(
      (x) =>
        (x.unit.toLowerCase() === "seeds" || x.unit.toLowerCase() === "seed") &&
        x.rate >= 1000
    ).length === operation.varieties.length;

  return convertToK;
};

/**
 * Converts seeding variety to K seeds price and quantity
 * @param  {Object} variety Seeding variety to convert to K seeds
 * @return {Object} Seeding variety updated to K seeds
 */
const convertVarietyToKseeds = (variety) => {
  //console.log("convertVarietyToKseeds", variety)
  // Object contains no objects as values, so we can shallow copy
  const converted = { ...variety };

  converted.averageMaterialResult = variety.averageMaterialResult / 1000;
  converted.averageMaterialTarget = variety.averageMaterialTarget / 1000;
  converted.orginalUnit = variety.unit;
  converted.price = Number((variety.price * 1000).toFixed(5));
  converted.quantity = variety.quantity / 1000;
  converted.rate = variety.rate / 1000;
  converted.varietyRate = variety.rate / 1000;
  converted.totalMaterial = variety.totalMaterial / 1000;
  converted.unit = "K seeds";
  converted.totalUnit = "K seeds";

  return converted;
};

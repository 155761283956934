import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Typography } from "@material-ui/core";
import { COLORS } from "../../../../../constants";
import Header from "./OverviewTableHeader";
import FarmOverviewTable from "./FarmOverviewTable/FarmOverviewTable";
import FilterRow from "./OverviewTableFilters";
import GreenCheckbox from "../../../../Helpers/GreenCheckbox";
import { FarmReportMap } from "../../../../Maps/FarmReportMap"

const useStyles = makeStyles(() => ({
  wrapper: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    marginTop: 60,
    marginBottom: 30,
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiButton-root": {
      padding: 6,
    },
    width: 330,
  },
  button: {
    color: COLORS.green,
    backgroundColor: COLORS.white,
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: "bold",
    border: `1px solid ${COLORS.green}`,
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "none",
    minWidth: 158,
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    color: COLORS.darkGray,
  },
  selectAll: {
    color: COLORS.green,
  },
}));

/**
 * Renders the farm overview table container
 * @param {object} activeFarm object with farm data
 * @param {string} activeYear the year
 * @param {array} fields  array of objects with field data
 * @param {function} setFields set state function
 * @param {object} tableControls handles controls (buttons) in header for displaying table
 * @param {function} setTableControls  set state function
 * @param {array} options array of objects with option information
 * @param {function} setOptions set state function
 * @param {array} selectedColumns  array of objects with properties for id,
 * active and disabled state of the selects
 * @param {boolean} mobileView for mobile views
 * @param {object} fieldHeaderCells keyed object for the categories for
 * the field report. Being passed down into pdf report.
 * @param {array} data  array of objects with data to be passed down into pdf report
 * @param {boolean} areAllActive needed to pass down the state of the select all checkbox
 * @param {function} setAreAllActive  needed to pass down the ability to change
 * the state of the select all checkbox
 */

const FarmOverviewTableContainer = ({
  activeFarm,
  activeYear,
  fields,
  setFields,
  tableControls,
  setTableControls,
  options,
  setOptions,
  selectedColumns,
  mobileView,
  fieldHeaderCells,
  data,
  selectedOrg,
  seedMap,
  harMap,
  appMap,
  fpMap,
  otherCostMap,
  otherRevMap,
  areAllActive,
  setAreAllActive,
  noData,
  setNoData,
  originalFields,
  showMap,
  setShowMap,
  fieldBoundaries
}) => {
  const classes = useStyles();

  const handleNoData = (value) => {
    //console.log("value", value)
    setNoData(value)
    if(value){
      //console.log("fields.filter(x => x.noData === true)", originalFields.filter(x => x.noData !== true))
      setFields(originalFields.filter(x => x.noData !== true))
    }
    else{
      //console.log("fields.filter((x) => x.noData === true || x.noData === false)", originalFields.filter((x) => x.noData === true || x.noData === false))
      setFields(originalFields.filter((x) => x.noData === true || x.noData === false))
    }
  }

  return (
    <div className={classes.wrapper}>
      <Header
        tableControls={tableControls}
        setTableControls={setTableControls}
        activeFarm={activeFarm}
        mobileView={mobileView}
      />
      <FilterRow options={options} setOptions={setOptions} />
      <div>
        <Typography style={{float: 'right'}} className={classes.selectAll}>Show Map View?
          <GreenCheckbox
            checked={showMap}
            onChange={(e) => setShowMap(e.target.checked)}
          />
        </Typography>
        <Typography style={{float: 'right', marginRight: "25px"}} className={classes.selectAll}>Hide Fields with No Data?
          <GreenCheckbox
            checked={noData}
            onChange={(e) => handleNoData(e.target.checked)}
          />
        </Typography>
        <Typography style={{float: 'right', marginRight: "25px"}} className={classes.selectAll}>Select All? 
          <GreenCheckbox
            checked={areAllActive}
            onChange={() => {
              setFields(
                fields.map((field) => ({
                  ...field,
                  active: !areAllActive,
                }))
              );
              setAreAllActive(!areAllActive);
            }}
          />
        </Typography>
      </div>
      {
        showMap ? 
        <FarmReportMap 
        fieldBoundaries={fieldBoundaries}
        fields={fields}
      /> : <FarmOverviewTable
      fields={fields}
      setFields={setFields}
      showHeatmap={tableControls.showHeatmap}
      options={options}
      selectedColumns={selectedColumns}
      activeFarm={activeFarm}
      activeYear={activeYear}
      fieldHeaderCells={fieldHeaderCells}
      data={data}
      selectedOrg={selectedOrg}
      seedMap={seedMap}
      harMap={harMap}
      appMap={appMap}
      fpMap={fpMap}
      otherCostMap={otherCostMap}
      otherRevMap={otherRevMap}
      areAllActive={areAllActive}
      setAreAllActive={setAreAllActive}
    />
      }
      
    </div>
  );
};

export default FarmOverviewTableContainer;

FarmOverviewTableContainer.propTypes = {
  activeFarm: PropTypes.object.isRequired,
  activeYear: PropTypes.string.isRequired,
  fields: PropTypes.array.isRequired,
  setFields: PropTypes.func.isRequired,
  tableControls: PropTypes.object.isRequired,
  setTableControls: PropTypes.func.isRequired,
  options: PropTypes.array.isRequired,
  setOptions: PropTypes.func.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  mobileView: PropTypes.bool.isRequired,
  fieldHeaderCells: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  areAllActive: PropTypes.bool.isRequired,
  setAreAllActive: PropTypes.func.isRequired,
};

import React, { useState, useEffect, Fragment } from 'react';
import { makeStyles, createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Chip,
  Checkbox,
  Tooltip,
  IconButton,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditIcon from '@material-ui/icons/Edit';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { useSnackbar } from 'notistack';
import * as con from '../../utils/conversions';
import { Endpoints } from '../../../../constants/Endpoints';
import { getVarietyNames } from '../../../../utils/dataFetchers';
import { NumberFormatCustom, NumberFormatSeeds } from '../../../../utils/NumberFormatCustom';
import { capitalizeFirstLetter, numFormat } from '../../../../utils/helpers';
import { darkGrey } from '../../../../styles/colors';

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ??
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: 'red',
        height: 5,
        width: 15,
        marginLeft: '10px',
      },
    },
  },
});

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  infoToolTip: theme.infoToolTip,
}));

export function Zone({
  allClickedFeatures,
  clickedFeature,
  zone,
  index,
  type,
  displayZones,
  handleZoneChange,
  handleChange,
  handleUpdate,
  remove,
  manualEntry,
}) {
  /**
     * Enter data for harvest and seeding zones.
     * @param {Object} zone Zone to add data for.
     * @param {Number} index Index of zone.
     * @param {String} type Harvest or Seeding.
     * @param {Object} field Field data.
     * @param {Array} displayZones Display data for zones in array.
     * @param {Function} handleZoneChange Add or remove zone from displayZones.
     * @param {Array} varieties List of varieties for operation.
     * @param {Function} setVarieties Updates list of varieties for operation.
     */

  const classes = useStyles();
  const [display, setDisplay] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [organic, setOrganic] = useState(false);
  const [irrigated, setIrrigated] = useState(false);

  const [variety, setVariety] = useState('');
  const [allVarieties, setAllVarieties] = useState([]);
  const [varietyInputValue, setVarietyInputValue] = useState('');
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    const show = !!displayZones.includes(index);
    setDisplay(show);
  }, [displayZones]);

  useEffect(() => {
    if (zone != undefined && clickedFeature != undefined) {
      // if(JSON.stringify(zone.zone.geometry.coordinates[0]) == JSON.stringify(clickedFeature.feature.geometry.coordinates[0])){
      // setIsSelected(clickedFeature.selected);
      // }
    }
  }, [clickedFeature]);

  const handleVarietyInput = async (index, val) => {
    // This is the function from the old PL tool to retrieve crop varieties
    // based on user input. The api performs the autocomplete functionaity on keypress.
    let url = Endpoints.BASEURL;
    url += type === 'Harvest' ? Endpoints.HARVESTVARIETYNAME : Endpoints.SEEDVARIETY;
    url += `/${val}`;
    //val.replace(/\s/g, '').length removes all white space and checks lenght of string -> if 0 then input is all white space
    if (val && val !== '' && val.replace(/\s/g, '').length > 0 && !allVarieties.includes(val)) {
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error('Failed to get variety names');
        })
        .then((vars) => setAllVarieties(vars))
        .catch((err) => enqueueSnackbar(err));
    }

    setVarietyInputValue(val);
    setVariety(val);
    handleUpdate(index, val, 'variety');
  };

  const displayZoneName = (zone, i) => {
    let zoneName = ''

    if ('soilType' in zone) {
      // Display soil type as zone name for SSURGO
      zoneName =  zone.soilType
    } else if (zone.productName !== '') {
      zoneName = `${zone.productName} - ${zone.variety}`
    } else {
      zoneName = `Zone ${i + 1}`
    }

    return `${zoneName} - ${numFormat(zone.area)} Acres`
  }

  return (
    <Box my={1} style={isSelected ? { borderColor: 'red', border: 'solid', borderWidth: 'medium' } : { border: 'none' }}>
      <Box display="flex" justifyContent="space-between">
        <Box
          display="flex"
          style={{ cursor: 'pointer', userSelect: 'none' }}
          onClick={() => { handleZoneChange(index); }}
        >
          {display ? (
            <ExpandLessIcon
              className={classes.icon}
              // onClick={() => handleZoneChange(index)}
            />
          ) : (
            <ExpandMoreIcon
              className={classes.icon}
              // onClick={() => handleZoneChange(index)}
            />
          )}

          { displayZoneName(zone, index) }

          {zone.color != undefined && (
          <ThemeProvider theme={theme}>
            <Button disabled style={{ background: zone.color }} />
          </ThemeProvider>
          )}
        </Box>

        <Box display="flex">

          {manualEntry && (
          <Box className={classes.greenIcon}>
            <DeleteOutlineOutlinedIcon
              fontSize="small"
              onClick={() => remove(index)}
            />
          </Box>
          )}
        </Box>
      </Box>

      {display && (
      <>
        <Box mt={1} display="flex">
          {manualEntry ? (
            <>
              <Box>
                Variety
                <Autocomplete
                  className={classes.input}
                  freeSolo
                  value={zone.variety != '' ? zone.variety : ''}
                  onChange={(event, newValue) => handleUpdate(index, newValue, 'variety')}
                  inputValue={varietyInputValue}
                  onInputChange={(event, newInputValue) => handleVarietyInput(index, newInputValue)}
                  options={allVarieties}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>
              <Box>
                {type === 'Harvest' && 'Avg. Yield ('}
                {capitalizeFirstLetter(zone.unit)}
                {'/Acre'}
                {type === 'Harvest' && ')'}
                <TextField
                  className={classes.input}
                  variant="outlined"
                  type="number"
                  value={!isNaN(zone.rate) ? zone.rate : ''}
                  onChange={(e) => handleChange(index, parseFloat(e.target.value), 'rate')}
                  onBlur={(e) => handleUpdate(index, parseFloat(e.target.value), 'rate')}
                />
              </Box>
            </>
          ) : (
            <>
              <Box>
                Variety
                <Box
                  className={classes.disabledInput}
                >
                  {zone.variety}
                </Box>
              </Box>
              <Box>
                {type === 'Harvest' && 'Avg. Yield ('}
                {capitalizeFirstLetter(zone.unit)}
                {'/Acre'}
                {type === 'Harvest' && ')'}
                <Box
                  className={classes.disabledInput}
                >
                  {zone.rate}
                </Box>
              </Box>
            </>
          )}
        </Box>

        <Box mt={1} display="flex">
          <Box>

            <Box display="flex" alignItems="center">
              <Box>
                {'Price ($/'}
                {capitalizeFirstLetter(zone.unit)}
                {')'}
              </Box>
              <Tooltip
                title="See an incorrect price? Update it for your entire organization in the Price Converter by clicking the info icon."
                placement="right"
              >
                <a href={`${Endpoints.HOME}/app/priceconverter`} target="_blank">
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </a>
              </Tooltip>
            </Box>

            <TextField
              className={classes.input}
              variant="outlined"
              value={zone.price}
              // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
              onChange={(e) => handleChange(index, Number(e.target.value.replaceAll(',', '')), 'price')}
              onBlur={(e) => handleUpdate(index, Number(e.target.value.replaceAll(',', '')), 'price')}
              InputProps={{
                inputComponent: type === 'Seeding' ? NumberFormatSeeds : NumberFormatCustom,
              }}
            />
          </Box>
          <Box>
            {type === 'Seeding' ? 'Cost' : 'Revenue'}
            {' ($/Acre)'}
            { manualEntry ? (
              <TextField
                className={classes.input}
                variant="outlined"
                value={zone.total}
                // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                onChange={(e) => handleChange(index, Number(e.target.value.replaceAll(',', '')), 'total')}
                onBlur={(e) => handleUpdate(index, Number(e.target.value.replaceAll(',', '')), 'total')}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
              />
            ) : (
              <Box className={classes.disabledInput}>
                {numFormat(zone.total)}
              </Box>
            )}
          </Box>
        </Box>

        <Box mt={1} display="flex">
          <Box className={classes.input}>
            <Checkbox
              color="primary"
              checked={zone.isOrganic}
              onChange={() => {
                handleUpdate(index, !zone.isOrganic, 'isOrganic');
              }}
            />
            Organic
          </Box>
          <Box className={classes.input}>
            <Checkbox
              color="primary"
              checked={zone.isIrrigated}
              onChange={() => {
                handleUpdate(index, !zone.isIrrigated, 'isIrrigated');
              }}
            />
            Irrigated
          </Box>
        </Box>
      </>
      )}
    </Box>
  );
}

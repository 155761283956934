import React from 'react';
import { Box, Typography, Divider } from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { blackText, grey, lightGrey } from '../../../styles/colors';
import { numFormat, exists } from '../../../utils/helpers';

export const createPolarisTable = (field, average, legend, selectedVariable, countyAverage) => (
  <Box
    id="polaris-table"
    m={1}
    border={1}
    borderColor={grey}
    borderRadius="borderRadius"
    style={{ minWidth: 380, maxWidth: 1000, overflowX: 'auto' }}
  >

    <Box p={1}>
      <Typography
        align="center"
        style={{ color: blackText, fontWeight: 500, fontSize: 16 }}
      >
        {selectedVariable.name}
        {' '}
        {numFormat(average)}
      </Typography>
      {(countyAverage && selectedVariable.value === 'ph') &&
        <Typography
          align="center"
          style={{ color: blackText, fontWeight: 500, fontSize: 16 }}
        >
          {'County Average Soil pH in H20'}
          {' '}
          {numFormat(countyAverage)}
        </Typography>

      }
    </Box>

    <Divider />

    <Box p={1} display="flex">
      <Box style={{ width: '40%' }}>
        {selectedVariable.name}
      </Box>
      <Box style={{ width: '40%' }}>
        Area per part (Acres)
      </Box>
      <Box style={{ width: '20%' }}>
        % of Total
      </Box>

    </Box>

    <Divider style={{ color: grey, height: 4 }} />
    { legend.map((x, i) => createRow(x, i, field)) }
  </Box>
);

const createRow = (x, i, field) => {
  const percent = Number(x.Area.replace('%', ''));

  return (
    <Box
      key={i}
      p={1}
      display="flex"
      alignItems="center"
      style={{ backgroundColor: i % 2 == 0 ? '#ffffff' : lightGrey }}
    >
      <Box style={{ width: '40%' }}>
        <Box display="flex" alignItems="center">
          <FiberManualRecordIcon
            style={{ color: x.color, marginRight: 8 }}
          />
          {numFormat(x.Min)}
          {' - '}
          {numFormat(x.Mean)}
        </Box>
      </Box>

      <Box style={{ width: '40%' }}>
        {numFormat(field.acres * (percent / 100))}
      </Box>

      <Box style={{ width: '20%' }}>
        {percent}
        {'%'}
      </Box>
    </Box>
  );
};

import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from "@material-ui/core";
import { extent, sum } from "d3-array";
import { kebabCase } from "lodash";
import { scaleQuantize } from "d3-scale";
import Header from "../../../../../Helpers/Table/Header";
import {
  COLOR_RANGE,
  COLORS,
  DOLLAR_FORMAT,
  SORT,
  SORT_LOOKUP,
  PERCENT_FORMAT, NEGATIVE_COLOR_RANGE, POSITIVE_COLOR_RANGE
} from "../../../../../../constants";
import Row from "./OverviewTableRow";
import Summary from "./OverviewTableSummary";
import GreenCheckbox from "../../../../../Helpers/GreenCheckbox";
import Spinner from "../../../../../Helpers/Spinner";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
  },
  table: {
    minWidth: "100%",
    color: COLORS.text,
    borderCollapse: "collapse",
  },
  tableBody: {
    borderColor: COLORS.green,
    borderTop: "2px solid",
    borderBottom: "2px solid",
    "& .MuiTableRow-root": {
      height: 36,
      "&.active": {
        backgroundColor: COLORS.veryLightGrey,
        borderLeft: `3px solid ${COLORS.green}`,
      },
    },
  },
  selectAll: {
    color: COLORS.green,
  },
}));

/**
 * Renders the farm overview table
 * @param {array} fields  array of objects with field data
 * @param {function} setFields set state function
 * @param {boolean} showHeatMap to determine whether or not to show heat map
 * @param {array} options array of objects with option information
 * @param {array} selectedColumns  array of objects with properties for id,
 * @param {object} activeFarm object with farm data
 * @param {string} activeYear the year
 * @param {object} fieldHeaderCells keyed object for the categories for
 * the field report. Being passed down into pdf report.
 * @param {array} data  array of objects with data to be passed down into pdf report
 * @param {boolean} areAllActive needed to pass down the state of the select all checkbox
 * @param {function} setAreAllActive  needed to pass down the ability to change
 * the state of the select all checkbox
 */

const FarmOverviewTable = ({
  fields,
  setFields,
  showHeatmap,
  options,
  selectedColumns,
  activeFarm,
  activeYear,
  fieldHeaderCells,
  data,
  selectedOrg,
  seedMap,
  harMap,
  appMap,
  fpMap,
  otherCostMap,
  otherRevMap,
  areAllActive,
  setAreAllActive,
}) => {
  const classes = useStyles();

  const [isDownload, setIsDownload] = useState(false)
  // make array names of columns to show...
  // filter out duplicates (this is a failsafe b/c already shouldn't have dups)
  const columnsToShow = [
    ...new Set(
      selectedColumns
        .map((selectGroup) =>
          selectGroup
            .map((el) =>
              el.id === "None selected"
                ? null
                : el.active === true
                ? el.id
                : null
            )
            .filter((el) => el)
        )
        .flat()
    ),
  ];
  // Get the header cells based on selected indicators. See filteredHeaderCells below.
  const [headerCells] = useState(
    [
      {
        heading: "Field Name",
        accessor: (d) => d.name,
        format: null,
        helper: "All field names",
        aggregateFunction: null,
        showCarat: true,
      },
      {
        heading: "Total Expenses",
        accessor: (d) => d.expenses,
        format: DOLLAR_FORMAT,
        helper: "Total Expenses",
        aggregateFunction: sum,
      },
      {
        heading: "Seeding Expenses",
        accessor: (d) => d.seeding,
        format: DOLLAR_FORMAT,
        helper: "Seeding",
        aggregateFunction: sum,
      },
      {
        heading: "Chemicals Expenses",
        accessor: (d) => d.chemicals,
        format: DOLLAR_FORMAT,
        helper: "Chemicals",
        aggregateFunction: sum,
      },
      {
        heading: "Field Passes",
        accessor: (d) => d.fieldPasses,
        format: DOLLAR_FORMAT,
        helper: "Field Passes",
        aggregateFunction: sum,
      },
      {
        heading: "Other Costs",
        accessor: (d) => d.otherCosts,
        format: DOLLAR_FORMAT,
        helper: "Other Costs",
        aggregateFunction: sum,
      },
      {
        heading: "Total Revenue",
        accessor: (d) => d.revenue,
        format: DOLLAR_FORMAT,
        helper: "Total Revenue",
        aggregateFunction: sum,
      },
      {
        heading: "Harvest Revenue",
        accessor: (d) => d.harvest,
        format: DOLLAR_FORMAT,
        helper: "Harvest",
        aggregateFunction: sum,
      },
      {
        heading: "Other Revenues",
        accessor: (d) => d.otherRevenues,
        format: DOLLAR_FORMAT,
        helper: "Other Revenues",
        aggregateFunction: sum,
      },
      {
        heading: "Profits",
        accessor: (d) => d.profit,
        format: DOLLAR_FORMAT,
        helper: "Profits",
        aggregateFunction: sum,
      },
      {
        heading: "Profitable (%)",
        accessor: (d) => d.percentProfitable,
        format: null,
        helper: "Profitable Percentage",
        aggregateFunction: null,
      },
      {
        heading: "Unprofitable (%)",
        accessor: (d) => d.unprofitablePercent,
        format: null,
        helper: "Unprofitable Percentage",
        aggregateFunction: null,
      },
      {
        heading: "Primary Crop",
        accessor: (d) => d.primaryCrop,
        format: null,
        helper: "Primary crop for field",
        aggregateFunction: null,
      },
      {
        heading: "Include in Detailed Report?",
        accessor: (d) => d.active,
        format: null,
        isCheckBox: true,
        disableSort: true,
        aggregateFunction: null,
        showPrintButton: true,
      },
    ].map((headerCell) => ({
      ...headerCell,
      id: kebabCase(headerCell.heading),
      scale: headerCell.aggregateFunction
        ? scaleQuantize()
            .range(COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(extent(fields, headerCell.accessor))
        : null,
      extentValue: headerCell.aggregateFunction
      ? extent(fields, headerCell.accessor) : null
    }))
  );

  //console.log("headerCells", headerCells)

  const [state, setState] = useState({
    order: SORT.Asc,
    orderBy: headerCells[0].id,
  });
  const tableRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === SORT.Asc;
    const isSameIndicator = property === state.orderBy;
    setState({
      orderBy: property,
      order: isSameIndicator ? (isAsc ? SORT.Desc : SORT.Asc) : SORT.Asc,
    });
  };

  // Handle the sort, by the selected header
  const selectedHeader = headerCells.find((d) => d.id === state.orderBy);
  const filteredHeaderCells = headerCells.filter(
    (header, i) =>
      i === 0 ||
      i === headerCells.length - 1 ||
      columnsToShow.includes(header.heading)
  );
  const selectedFields = fields.filter((field) => field.active);

  return (
    <TableContainer id="farm-report-table" className={classes.container}>
      {
        isDownload && <Spinner message="Generating PDF File" />
      }
      
      <Table className={classes.table} size="small" ref={tableRef}>
        <Header
          order={state.order}
          orderBy={state.orderBy}
          onRequestSort={handleRequestSort}
          headerCells={filteredHeaderCells}
        />
        <TableBody className={classes.tableBody}>
          {fields
            .filter((field) =>
              options.every((option) => {
                const { numeric, accessor, filteredRange } = option;
                const value = accessor(field);
                if (numeric) {
                  return value >= filteredRange[0] && value <= filteredRange[1];
                }
                return filteredRange.includes(value);
              })
            )
            .sort((a, b) => {
              const sortFn = SORT_LOOKUP.get(state.order);
              return sortFn(
                selectedHeader.accessor(a),
                selectedHeader.accessor(b)
              );
            })
            .map((row, i) => (
              <Row
                key={`farm-table-row-for-${i}`}
                row={row}
                headerCells={filteredHeaderCells}
                fields={fields}
                setFields={setFields}
                showHeatmap={showHeatmap}
                areAllActive={areAllActive}
                setAreAllActive={setAreAllActive}
              />
            ))}
          <TableRow>
            {filteredHeaderCells.map((d, i) =>
              i === filteredHeaderCells.length - 1 ? (
                <TableCell
                  className={classes.selectAll}
                  key={`last-row-for-${d.id}`}
                >
                  Select All?
                  <GreenCheckbox
                    checked={areAllActive}
                    onChange={() => {
                      setFields(
                        fields.map((field) => ({
                          ...field,
                          active: !areAllActive,
                        }))
                      );
                      setAreAllActive(!areAllActive);
                    }}
                  />
                </TableCell>
              ) : (
                <TableCell key={`last-row-for-${d.id}`} />
              )
            )}
          </TableRow>
        </TableBody>
        <Summary
          values={fields}
          headerCells={filteredHeaderCells}
          selectedFields={selectedFields}
          activeFarm={activeFarm}
          activeYear={activeYear}
          fieldHeaderCells={fieldHeaderCells}
          data={data}
          selectedOrg={selectedOrg}
          seedMap={seedMap}
          harMap={harMap}
          appMap={appMap}
          fpMap={fpMap}
          otherCostMap={otherCostMap}
          otherRevMap={otherRevMap}
          setIsDownload={setIsDownload}
        />
      </Table>
    </TableContainer>
  );
};

export default FarmOverviewTable;

FarmOverviewTable.propTypes = {
  fields: PropTypes.array.isRequired,
  setFields: PropTypes.func.isRequired,
  showHeatmap: PropTypes.bool.isRequired,
  options: PropTypes.array.isRequired,
  selectedColumns: PropTypes.array.isRequired,
  activeFarm: PropTypes.object.isRequired,
  activeYear: PropTypes.string.isRequired,
  fieldHeaderCells: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
  areAllActive: PropTypes.bool.isRequired,
  setAreAllActive: PropTypes.func.isRequired,
};

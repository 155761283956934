import React, {useEffect, useState} from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';
import { curry } from 'lodash';
import {underwritingWeatherColors} from '../Maps/Styles/layerStyles'


export function WeatherChart({data, type}){

  const [series, setSeries] = useState([])

  const months = [1,2,3,4,5,6,7,8,9,10,11,12]


  useEffect(() => {

    if(data!== null && data !== undefined){
      const curData = data[type].map(x => Math.round(x.Value*1000)/1000)

      let projYears = {}
      for (const item of data[`${type}_Projection`]){
        if(!Object.keys(projYears).includes(item.Year)){
          let yearItems = data[`${type}_Projection`].filter(x => x.Year === item.Year)
          projYears[item.Year] = yearItems.map(x => Math.round(x.Value*1000)/1000)
        }
      }

      let projSeries = []
      let i=1;
      for(const key of Object.keys(projYears)){
        let seriesItem={
          name: key,
          data: projYears[key],
          color: underwritingWeatherColors[i+1],
          zIndex: i,
          lineWidth: 2,
          states: {
            inactive: {
              opacity: 1
            }
          },
          dashStyle: 'LongDash'
        }
        projSeries.push(seriesItem)
        i+=1
      }

      let currItem = {
        name: data[type][0].Year,
        data: curData,
        color: underwritingWeatherColors[0],
        zIndex: projSeries.length+2,
        lineWidth: 4,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }

      let seriesItems = [currItem, ...projSeries]

      setSeries(seriesItems)
    }
    


  }, [data, type])


  const options = {
    chart: {
        type: 'line',
        backgroundColor: null,
        zoomType: 'x'
    },
    title: {
        text: `${type} Data`
    },
    xAxis: {
        categories: months,
        title:{
          text: "Month"
        }
    },
    yAxis: {
        title: {
            text: type === 'Precipitation' ? 'Accumalated Precipitation (mm)' : 'Accumulate Temperature (°C)'
        }
    },
    series: series,
    navigation: {
      menuItemStyle: {
        borderLeft: '10px solid #FFFFFF',
        fontSize: '15px',
        padding: "2px 10px"
      }
    }
  }


  return(
    <div id={`${type}-chart`}>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>
  )
}
export function calculateNetSeededAcres(
  field,
  seedingOperations,
  harvestOperations
) {
  /* Used to get net seeded acres when profitMap summary does not have this value yet.
     If seeding and harvest acres are 0, return field acres.
     If field acres are greater than 0 and less than seeding acres return field acres.
     If seeding acres are 0, return field acres if field acres are greater than 0 and less than harvest acres.
     If seeding acres are 0 and harvest acres are less than field acres, return harvest acres.
     Else return seeding acres.
  */

  let displayedSeeding = seedingOperations.filter((x) => !x.hidden);
  let displayedHarvest = harvestOperations.filter((x) => !x.hidden);
  const seedingAcres = getAcres(displayedSeeding);
  const harvestAcres = getAcres(displayedHarvest);
  if (seedingAcres === 0 && harvestAcres === 0) {
    return Number(field.acres);
  }

  //console.log(field.acres)
  const netSeededArea =
    seedingAcres !== 0 && seedingAcres >= harvestAcres
      ? seedingAcres
      : harvestAcres;
  // console.log("netseeded:", netSeededArea, "field:", field.acres)
  if (
    field.acres !== undefined &&
    field.acres > 0 &&
    field.acres < netSeededArea
  ) {
    return Number(field.acres);
  } else {
    return Number(netSeededArea);
  }
}

/**
 * Gets the seeded acres for the passed in operations
 * takes all seedings or harvest operations and
 * returns the value of seededAcres for the operation type
 * for use in determining the NetSeededAcres
 * @param {Array} operations
 */
const getAcres = (operations) => {
  //sum all operation's areas (area is in each variety)
  const areas = [];
  for (const op of operations) {
    let opArea = 0;
    for (const variety of op.varieties) {
      opArea += variety.area;
    }
    areas.push(opArea);
  }
  // const areas = operations.map(x => x.varieties.reduce((a,b) => a.area+b.area,0))

  let totalArea = areas.reduce((a, b) => a + b, 0);
  return totalArea;
};

export function reCalculateAcres(operations) {
  /*  Combine operations on ID, then return max seeded area */

  // unique operations from ID, update area here
  //console.log(operations)
  const uniqueOperations = [
    ...new Set(operations.map((op) => op.operationID)),
  ].map((id) => ({ id: id, area: 0 }));
  //console.log(uniqueOperations)

  // combine area for each operation under unique ids
  operations.forEach((item) => {
    uniqueOperations.forEach((op) => {
      if (op.id === item.operationID) {
        op.area = op.area + item.area;
      }
    });
  });

  // find operation with largest area
  const maxSeeded = uniqueOperations.reduce(
    (a, b) => (a.area > b.area ? a : b),
    0
  );
  console.log(maxSeeded);
  return maxSeeded.area > 0 ? maxSeeded.area : 0;
}

export function getTotal(operations, perAcre, acres) {
  /* Get total cost for operations that include price and quantity.
     Examples being Seeding and Harvest. If perAcre is true, divide
     total by netSeededAcres.
  */
  let totalPrice = 0;
  for (const op of operations) {
    //let percent = op.area / acres
    let price =
      !Number.isNaN(op.price) && !Number.isNaN(op.quantity)
        ? op.price * op.quantity
        : 0;
    totalPrice += price;
  }

  return perAcre ? totalPrice / acres : totalPrice;
}

export function getApplicationTotal(operations, perAcre, acres) {
  /* Get total cost for Applications. If perAcre is true, divide
     total by netSeededAcres
  */
  //  console.log("APPLICATIONS", operations)
  let totalcost = 0;
  for (const op of operations) {
    let cost =
      !Number.isNaN(op.operationCost) && !Number.isNaN(op.area)
        ? op.operationCost * op.area
        : 0;
    totalcost += cost;
  }

  return perAcre ? totalcost / acres : totalcost;
}

export function getFieldPassTotal(operations, perAcre, netSeededAcres) {
  if (operations.length > 0) {
    const cost = perAcre
      ? operations
          .map((x) => Number(x.price) * Number(x.area))
          .reduce((a, b) => a + b, 0) / netSeededAcres
      : operations
          .map((x) => Number(x.price) * Number(x.area))
          .reduce((a, b) => a + b, 0);
    return parseFloat(Number(cost).toFixed(2));
  }
  // if no operations return 0 cost
  return 0;
}

export function getOtherTotal(operations, perAcre, acres) {
  /* Other costs and reveue price is stored as perAcre value, rather
     than field total like other operations
  */
  if (operations.length > 0) {
    const total = operations
      .map((x) => x.price * x.area)
      .reduce((a, b) => a + b, 0);
    const cost = perAcre ? total / acres : total;
    return parseFloat(Number(cost).toFixed(2));
  }
  // if no operations return 0 cost
  return 0;
}

// Field Pass Calculatons

export function getTillagePrice(op, dieselPrices) {
  /*
    source: http://farmdoc.illinois.edu/manage/machinery/field_operations_2015.pdf
    assumes <= 2 in: field cultivator, 3-4 in: tandem disk, 5-7 in: (chisel plow if Fall else strip till), 8+ moldboard plow.
    these assumptions roughly per: https://extension.umn.edu/soil-management-and-health/economics-tillage
    and... https://extension.umn.edu/soil-management-and-health/tillage-implements
    Note: wageRate is hard coded below.
  */
  const wageRate = 17.0;
  const depth = op.targetAverageDepth;
  const acres = op.resultArea;
  const start = new Date(op.startDate);
  const end = new Date(op.endDate);

  // cultivation tandem disk moldboard plow chisel plow strip tillage
  const cost =
    depth <= 2
      ? 10.1
      : depth <= 4
      ? 12.1
      : depth >= 8
      ? 36.4
      : start.getMonth() >= 6
      ? 15.4
      : 23.2;

  // same value for chisel and strip
  const fuel = depth <= 2 ? 0.6 : depth <= 4 ? 0.5 : depth >= 8 ? 2.0 : 0.8;

  // same for cult and disk
  const labor =
    depth <= 4 ? 0.9 : depth >= 8 ? 4.0 : start.getMonth() >= 6 ? 1.3 : 5.8;

  const actualLaborHours = getLaborHours(start, end, acres);
  const dieselPrice = getMonthlyDieselPrice(dieselPrices, start);

  const laborCostAdjusted = wageRate * 1.1 * actualLaborHours - labor;
  const fueldCostAdjusted = fuel * 1.1 * (dieselPrice - 2.5);
  const total = cost + laborCostAdjusted + fueldCostAdjusted;
  return total;
}

export function getHarvestCost(op, dieselPrices) {
  /**
   * @param {Object} op Harvest operation to calculate cost for
   * @param {Array} dieselPrices List of diesel prices acquired from database
   * Get harvest costs for FieldPasses
   */

  // start and end are supposed to be same?
  // console.log(op)
  const start = new Date(op.date);
  const end = new Date(op.date);
  const acres = op.area;

  const grainHaulingCost = 0.1 * op.quantity;
  const actualLaborHours = acres > 0 ? getLaborHours(start, end, acres) : 0;
  //console.log("harvest hours", actualLaborHours)
  const dieselPrice = getMonthlyDieselPrice(dieselPrices, start);
  // console.log("har diesel", dieselPrice)
  // eventually this should be a lookup like the diesel price, and should be user-modifiable
  const wageRate = 17.0;

  let combineCost;
  let combineFuelCost;
  let combineLaborHours;
  let grainCartCost;
  let grainCartFuelCost;
  let grainCartLaborHours;

  // instead of hardcoding 17.00 should we use wageRate?
  if (op.productName === "SOYBEANS") {
    combineCost = 31.1;
    combineFuelCost = 1.7;
    combineLaborHours = 1.9 / (17.0 * 1.1);

    grainCartCost = 6.6;
    grainCartFuelCost = 1.0;
    grainCartLaborHours = 1.2 / (17.0 * 1.1);
  } else {
    combineCost = 35.2;
    combineFuelCost = 3.3;
    combineLaborHours = 2.9 / (17.0 * 1.1);

    grainCartCost = 12.5;
    grainCartFuelCost = 2.6;
    grainCartLaborHours = 1.7 / (17.0 * 1.1);
  }

  // actual*2 for grain cart
  const laborCostAdjusted =
    wageRate *
    (actualLaborHours * 2 * 1.1 - combineLaborHours - grainCartLaborHours);
  const fueldCostAdjusted =
    (dieselPrice * (combineFuelCost + grainCartFuelCost)) / 2.5;
  // console.log("Combine Cost", combineCost, "grainCart cost", grainCartCost, "laborCost", laborCostAdjusted, "fueld cost", fueldCostAdjusted)
  const cost =
    acres *
      (combineCost + grainCartCost + laborCostAdjusted + fueldCostAdjusted) +
    grainHaulingCost;

  return cost;
}

export function getSeedingCost(op, dieselPrices) {
  /**
   * @param {Object} op Harvest operation to calculate cost for
   * @param {Array} dieselPrices List of diesel prices acquired from database
   * Get Seeding costs for FieldPasses
   */
  const start = new Date(op.date);
  const end = new Date(op.date);
  const acres = op.area;

  const plantingCost = op.productName === "SOYBEANS" ? 12.3 : 13.9;
  const plantingFuelGallons = op.productName === "SOYBEANS" ? 0.4 : 0.3;
  const plantingLaborHours = 0.9 / (17.0 * 1.1);

  const actualLaborHours = acres > 0 ? getLaborHours(start, end, acres) : 0;
  //console.log("Seeding hours", actualLaborHours)
  const dieselPrice = getMonthlyDieselPrice(dieselPrices, start);
  //console.log("seed diesel", dieselPrice)
  const wageRate = 17.0;
  const laborCost = wageRate * (actualLaborHours * 1.1 - plantingLaborHours);
  const fuelCost = plantingFuelGallons * 1.1 * (dieselPrice - 2.5);
  const cost = acres * (plantingCost + laborCost + fuelCost);

  return cost;
}

export function getApplicationCost(op, dieselPrices) {
  /**
   * @param {Object} op Application operation to calculate cost for
   * @param {Array} dieselPrices List of diesel prices acquired from database
   * Get Application costs for FieldPasses
   */

  const start = new Date(op.date);
  const end = new Date(op.date);
  const acres = op.area;
  const appCost = 3.75;
  const appFuelGallons = 0.15;
  const appLaborHours = 0.3 / (17.0 * 1.1);

  const actualLaborHours = acres > 0 ? getLaborHours(start, end, acres) : 0;
  //console.log("Application Hours", actualLaborHours)
  const dieselPrice = getMonthlyDieselPrice(dieselPrices, start);
  // console.log("app diesel", dieselPrice)
  const wageRate = 17.0;
  const laborCost = wageRate * (actualLaborHours * 1.1 - appLaborHours);
  const fuelCost = appFuelGallons * 1.1 * (dieselPrice - 2.5);

  const cost = acres * (appCost + laborCost + fuelCost);
  return cost;
}

const getLaborHours = (start, end, acres) => {
  const maxHoursPA = 0.2;
  const minHoursPA = 0.03;
  return Math.max(
    Math.min(maxHoursPA, (end - start) / (1000 * 60 * 60 * acres)),
    minHoursPA
  );
};

const getMonthlyDieselPrice = (dieselPrices, date) => {
  const months = {
    0: "jan",
    1: "feb",
    2: "march",
    3: "april",
    4: "may",
    5: "june",
    6: "july",
    7: "aug",
    8: "sept",
    9: "oct",
    10: "nov",
    11: "dec",
  };

  const y = date.getFullYear().toString();
  const m = date.getMonth(); // 0-indexed
  const month = months[m];

  const matchingResult = dieselPrices.filter((price) => price.year === y);

  // if no matching prices found, return 1.0
  const price = matchingResult.length > 0 ? matchingResult[0][month] : 1.0;
  return price;
};

export const getTotalAcres = (ops) => {
  // return total acres for array of operations
  let total = 0;
  ops.forEach((item) => {
    total += item.area;
  });

  return total;
};

export const updateProfitMapValues = (
  totalProfit,
  profitMap,
  perAcre,
  netSeededAcres
) => {
  totalProfit = perAcre ? totalProfit : totalProfit / netSeededAcres;
  let adjustFactor = totalProfit / profitMap[0].mean;
  for (const profit of profitMap) {
    if (profit.variety !== "Variety") {
      profit.mean = profit.mean * adjustFactor;
    }
  }

  return profitMap;
};

export const formatProfitLegend = (inputLegend, pmRequest) => {
  // console.log("original legend", inputLegend)
  const legend = JSON.parse(JSON.stringify(inputLegend));
  const len = legend.length;
  if (
    legend[0].max < legend[0].min ||
    legend[len - 1].max < legend[len - 1].min
  ) {
    // this will create a set of values equal to all max values and smallest min value
    const valueSet = new Set();
    legend.map((x) => {
      valueSet.add(x.max);
      valueSet.add(x.min);
    });
    // convert to array for sorting
    const valueArray = [];
    valueSet.forEach((x) => valueArray.push(x));
    valueArray.sort((a, b) => b - a); // sort in descending order
    // console.log(valueArray)

    for (let i = 0; i < legend.length; i++) {
      legend[i].max = valueArray[i];
      legend[i].min = valueArray[i + 1];
    }
  }

  if (pmRequest.ProfitMapLegendRange) {
    let legendRanges = pmRequest.ProfitMapLegendRange.split(", ").reverse();
    legendRanges = legendRanges.map((x) => parseFloat(x));
    // console.log("Legend ranges from req", legendRanges)
    if (legendRanges[0] !== legend[0].max) {
      legend[0].max = legendRanges[0];
    }
    if (
      legendRanges[legendRanges.length - 1] !== legend[legend.length - 1].min
    ) {
      legend[legend.length - 1].min = legendRanges[legendRanges.length - 1];
    }
    legend[0].max = legend[0].max - 0.01;
    legend[legend.length - 1].min = legend[legend.length - 1].min + 0.01;
  }

  // console.log("fixed", legend)
  return legend;
};

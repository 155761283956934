/* eslint-disable max-len */
import { NumberFormatCustom, NumberFormatRMAArea } from '../../../utils/NumberFormatCustom';
import { parseDate } from '../helpers';
import * as preset from '../presetData';
import * as _ from 'underscore';

/** DO NOT CHANGE THE ORDERING
 * Contains all the info to display on summary page for each CLU/Field.
 * One CLU/Field could have various subfields/operations with a differing combination of these, so will just show them all always.
 *
 * Will show N/A for some of them based on the indicator's value (changeExplanation, Geospatial shape)
 * or whether they exist (skip row stuff based on skipRowPatternCode, planting code, CLU review)
 *
 * Types are:
 *    date - will need to change order from YYYY-MM-DD to MM/DD/YYYY
 *    presetDirect - contains a code from presetData, mapping does not contain options, link is var name
 *    multipleIndicators - elements that rely or more than one other element for deciding inclusion
 *    formattedNumber - number, use given endAdornment and inputComponent to display
 *    text - plain text, no formatting required, is not a code in subfield/operation variable, just display
 *    cvt - one of the cvt elements, mappings are from seen state vars
 *    preset - contains a code from presetData, mapping does contain options, link contains var name and key in var
 *
 * They will all be displayed in the same textfield style box, the only thing types shows is how to get the data 
 */
export const allCLUInfo = [
  { header: 'Planting Date', type: 'date', source: 'finalPlantedDate' },
  { header: 'Acres', type: 'formattedNumber', source: 'finalReportedAcreage', endAdornment: 'acres', inputComponent: NumberFormatRMAArea, },
  { header: 'Crop', type: 'cvt', source: 'crop', link: ['crops', 'commodityCode'], },
  { header: 'Crop Type', type: 'cvt', source: 'cropType', link: ['cropTypes', 'commodityTypeCode'], },
  { header: 'Intended Use', type: 'cvt', source: 'intendedUse', link: ['intendedUses', 'intendedUseCode'], },
  { header: 'Share %', type: 'formattedNumber', source: 'sharePercentage', endAdornment: '%', inputComponent: NumberFormatRMAArea, },
  { header: 'Organic Status', type: 'preset', source: 'organicPracticeTypeCode', link: ['cartDropdownData', 'organicPracticeType'], },
  { header: 'Irrigation Status', type: 'preset', source: 'irrigationPracticeCode', link: ['radioInputs', 'irrigationCodes'], },
  { header: 'Cropping Practice', type: 'preset', source: 'croppingPracticeCode', link: ['cartDropdownData', 'croppingPractice'], },
  { header: 'Planting Code', type: 'preset', source: 'productPlantingCode', link: ['cartDropdownData', 'plantingCodes'], },
  { header: 'Reason for Modifying Date', type: 'presetDirect', source: 'plantedDateModifiedReasonCode', indicator: 'plantedDateModifiedIndicator', link: 'plantingDateModifiedReasonCodes', },
  { header: 'Explanation', type: 'multipleIndicators', source: 'plantedDateModifiedOtherReasonText', indicators: ['plantedDateModifiedIndicator', 'plantedDateModifiedReasonCode'], conditions: ['Y', 'O'], },
  { header: 'Reason for Modifying Acreage', type: 'presetDirect', source: 'reportedAcreageModifiedReasonCode', indicator: 'reportedAcreageModifiedIndicator', link: 'acresModifiedReasons', },
  { header: 'Explanation', type: 'multipleIndicators', source: 'reportedAcreageModifiedOtherReasonText', indicators: ['reportedAcreageModifiedIndicator', 'reportedAcreageModifiedReasonCode'], conditions: ['Y', 'O'], },
  { header: 'Skip Row Pattern Code', type: 'preset', source: 'skipRowPatternCode', link: ['cartDropdownData', 'skipRowPatternCodes'], },
  { header: 'Skip Row Conversion Factor', type: 'formattedNumber', indicator: 'skipRowPatternCode', source: 'skipRowConversionFactor', endAdornment: '%', inputComponent: NumberFormatCustom, },
  { header: 'Crop Row Count', type: 'formattedNumber', indicator: 'skipRowPatternCode', source: 'cropRowCount', },
  { header: 'Crop Row Width', type: 'formattedNumber', indicator: 'skipRowPatternCode', source: 'cropRowWidth', endAdornment: 'in', },
  { header: 'Skip Row Count', type: 'formattedNumber', indicator: 'skipRowPatternCode', source: 'skipRowCount', },
  { header: 'Skip Row Width', type: 'formattedNumber', indicator: 'skipRowPatternCode', source: 'skipRowWidth', endAdornment: 'in', },
  { header: 'Ask FSA CLU Review?', type: 'preset', source: 'cluProducerReviewRequestIndicator', link: ['radioInputs', 'reviewRequested'], },
  { header: 'Geospatial Shape', type: 'text', indicator: 'includeGeospatialInReport', source: 'boundary', },
  { header: 'MICS Code', type: 'preset', source: 'micsCode', link: ['cartDropdownData', 'micsCodes'], },
  { header: 'MICS Name', type: 'text', indicator: 'micsCode', source: 'micsName', },
  { header: 'Reason for Modifying Geospatial Shape', type: 'multipleIndicators', source: 'geospatialShapeModifiedReasonCode', indicators: ['includeGeospatialInReport', 'geospatialShapeModifiedIndicator'], conditions: ['Y', 'Y'], link: 'geospatialShapeModifiedReasonCodes', },
  { header: 'Explanation', type: 'multipleIndicators', source: 'geospatialShapeModifiedOtherReasonText', indicators: ['includeGeospatialInReport', 'geospatialShapeModifiedIndicator', 'geospatialShapeModifiedReasonCode'], conditions: ['Y', 'Y', 'O'], },
];

// Get the value to display - appropriate method mainly depends on info.type
export const getAppropriateValue = (info, entry, seenCVTElements, clusSeen, handleChangeReason, enqueueSnackbar) => {
  try {
    let [value, inputComponent, endAdornment] = [];

    // Parse date
    if (info.type === 'date') {
      value = parseDate(entry[info.source]);
    } else if (info.type === 'presetDirect') {
      // Get presetDirect mapping, depenging on indicator
      const indicator = entry[info.indicator];
      // Ignore if indicator shows this should not be included (was not modified)
      if (!indicator || indicator === 'N') {
        value = 'N/A';
      } else {
        // If indicator is 'Y', then get values to display
        value = handleChangeReason(info, entry);
      }
    } else if (info.type === 'multipleIndicators') {
      // Set values for element, depends on various other elements
      const { indicators } = info;
      let include = true;

      // eslint-disable-next-line no-restricted-syntax
      for (const [index, indicator] of indicators.entries()) {
        // Check to make sure all indicators match the required condition
        if (!entry[indicator] || entry[indicator] !== info.conditions[index]) {
          include = false;
          break;
        }
      }

      // If any of the indicators do not match the required condition, do not include this value
      if (include) {
        if (info.source === 'geospatialShapeModifiedReasonCode') {
          value = handleChangeReason(info, entry);
        } else {
          value = entry[info.source];
        }
      } else {
        value = 'N/A';
      }
    } else if (info.type === 'formattedNumber') {
      // Set values for formattedNumber, depenging on indicator
      const { indicator } = info;
      // If it's not one of the skip row options
      // or if it is and they should be included
      if (!indicator || (entry[indicator] && entry[indicator] !== '')) {
        value = entry[info.source];
        endAdornment = info.endAdornment;
        inputComponent = info.inputComponent;
      } else {
        // Handle differently if skip row option does not matter
        value = 'N/A';
      }
    } else if (info.type === 'text') {
      // Set value for plain text, depenging on indicator
      const { indicator } = info;

      // If there is an indicator
      if (indicator) {
        const indicatorValue = entry[indicator];

        // Handle (micsName) OR (Explanation texts / Geospatial Shape)
        if ((indicator === 'micsCode' && indicatorValue === 'O') || (indicatorValue === 'Y')) {
          value = entry[info.source];
        } else {
          value = 'N/A';
        }
      } else {
        // Else (This shouldn't actually happen for now..)
        console.error('Something is wrong here. info.indicator should have a value for info: ', info, ' | and subfield/operation: ', entry);
      }
    } else if (info.type === 'cvt') {
      // Get proper cvt element code's mapping's displayValue
      // NOTE: Could temporarily store found mappings to speed things up (mainly for cvt - but this will prob be pretty fast..)

      // seenCVTElements -> seen state var -> appropriate state var entry
      const stateVarEntry = seenCVTElements[info.source][entry[info.link[0]]];
      if (stateVarEntry?.length) {
        // appropriate state var entry -> matched code's mapping
        const match = stateVarEntry.filter((elem) => elem[info.link[1]] === entry[info.source]);
        
        // matched code's mapping -> mapping's displayValue
        // console.log('stateVarEntry, entry, info :>> ', stateVarEntry, entry, info);
        value = match[0].displayValue;
      } else {
        // If something is missing, then something is wrong...
        console.error('Something is wrong here. stateVarEntry should have a value for info: ', info, ' | and subfield/operation: ', entry);
      }
    } else if (info.type === 'preset') {
      // Get preset mapping from options
      let code;
      if (info.source === 'cluProducerReviewRequestIndicator') {
        code = clusSeen[entry.clu_identifier].cluProducerReviewRequestIndicator;
      } else {
        code = entry[info.source];
      }
      const presetOptions = preset[info.link[0]][info.link[1]].options;
      const match = presetOptions.filter((elem) => elem.code === code);

      if (match.length) {
        value = match[0].display;
      } else {
        // If something is missing, then something is wrong...
        console.error('Something is wrong here. match should have a value for all preset options. For info: ', info, ' | and subfield/operation: ', entry);
      }
    } else {
      console.error('Something is wrong here. Another type shouldn\'t exist');
    }

    return [value, endAdornment, inputComponent];
  } catch (err) {
    console.error(err);
    if (_.size(clusSeen)) {
      enqueueSnackbar(`Missing Data: An issue occured while getting your data for CLU #: ${entry.clu_number}`);
    }
    else {
      enqueueSnackbar(`Missing Data: An issue occured while getting your data for Field: ${entry.fieldName}`);
    }
    return ['', undefined, undefined]
  }
};

export const getValueForPdfDisplay = (info, entry, seenCVTElements, clusSeen, handleChangeReason, enqueueSnackbar) => {
  try {
    let text = ''

    // Parse date
    if (info.type === 'date') {
      text = parseDate(entry[info.source]);
    } else if (info.type === 'presetDirect') {
      // Get presetDirect mapping, depenging on indicator
      const indicator = entry[info.indicator];
      // Ignore if indicator shows this should not be included (was not modified)
      if (!indicator || indicator === 'N') {
        text = 'N/A';
      } else {
        // If indicator is 'Y', then get values to display
        text = handleChangeReason(info, entry);
      }
    } else if (info.type === 'multipleIndicators') {
      // Set values for element, depends on various other elements
      const { indicators } = info;
      let include = true;

      // eslint-disable-next-line no-restricted-syntax
      for (const [index, indicator] of indicators.entries()) {
        // Check to make sure all indicators match the required condition
        if (!entry[indicator] || entry[indicator] !== info.conditions[index]) {
          include = false;
          break;
        }
      }

      // If any of the indicators do not match the required condition, do not include this value
      if (include) {
        if (info.source === 'geospatialShapeModifiedReasonCode') {
          text = handleChangeReason(info, entry);
        } else {
          text = entry[info.source];
        }
      } else {
        text = 'N/A';
      }
    } else if (info.type === 'formattedNumber') {
      // Set values for formattedNumber, depenging on indicator
      const { indicator } = info;
      // If it's not one of the skip row options
      // or if it is and they should be included
      if (!indicator || (entry[indicator] && entry[indicator] !== '')) {
        text = entry[info.source];
      } else {
        // Handle differently if skip row option does not matter
        text = 'N/A';
      }
    } else if (info.type === 'text') {
      // Set value for plain text, depenging on indicator
      const { indicator } = info;

      // If there is an indicator
      if (indicator) {
        const indicatorValue = entry[indicator];

        // Handle (micsName) OR (Explanation texts / Geospatial Shape)
        if ((indicator === 'micsCode' && indicatorValue === 'O') || (indicatorValue === 'Y')) {
          text = entry[info.source];
        } else {
          text = 'N/A';
        }
      } else {
        // Else, just show text. (This shouldn't actually happen for now..)
        console.error('Something is wrong here. info.indicator should have a value for info: ', info, ' | and subfield/operation: ', entry);
      }
    } else if (info.type === 'cvt') {
      // Get proper cvt element code's mapping's displayValue
      // NOTE: Could temporarily store found mappings to speed things up (mainly for cvt - but this will prob be pretty fast..)

      // seenCVTElements -> seen state var -> appropriate state var entry
      const stateVarEntry = seenCVTElements[info.source][entry[info.link[0]]];
      // appropriate state var entry -> matched code's mapping
      const match = stateVarEntry.filter((elem) => elem[info.link[1]] === entry[info.source]);
      // matched code's mapping -> mapping's displayValue
      text = match[0].displayValue;
    } else if (info.type === 'preset') {
      // Get preset mapping from options
      let code;
      if (info.source === 'cluProducerReviewRequestIndicator') {
        code = clusSeen[entry.clu_identifier].cluProducerReviewRequestIndicator;
      } else {
        code = entry[info.source];
      }
      const presetOptions = preset[info.link[0]][info.link[1]].options;
      const match = presetOptions.filter((elem) => elem.code === code);

      if (match.length) {
        text = match[0].display;
      } else {
        // If something is missing, then something is wrong...
        console.error('Something is wrong here. match should have a value for all preset options. For info: ', info, ' | and subfield/operation: ', entry);
      }
    } else {
      console.error('Something is wrong here. Another type shouldn\'t exist');
    }

    return text;
  } catch (err) {
    console.error(err);
    if (_.size(clusSeen)) {
      enqueueSnackbar(`Missing Data: An issue occured while getting your data for CLU #: ${entry.clu_number}`);
    }
    else {
      enqueueSnackbar(`Missing Data: An issue occured while getting your data for Field: ${entry.fieldName}`);
    }
    return '';
  }
};

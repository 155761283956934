import React, { useState, useEffect } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Button,
} from '@material-ui/core';
import { AccountBoxOutlined } from '@material-ui/icons';
import { useWindowDimensions } from '../../../../utils/dimensions';
import {
  numFormat, roundTo, capitalizeFirstLetter, getDecimalPlaces,
} from '../../../../utils/helpers';
import { unitConversion } from '../../utils/conversions';
import { grey, lightGrey } from '../../../../styles/colors';
import { CreatePDF } from './PDF/CreatePDF';
import { combineVarities } from '../../Operations/Plantings/utils/helpers';
import { NumberFormatCustom } from '../../../../utils/NumberFormatCustom';


const useStyles = makeStyles((theme) => ({
  head: theme.tableHeader,
  headText: theme.tableHeaderText,
  tableLable: {
    fontWeight: 500,
    padding: '8px 16px',
  },
  icon: theme.plIcon,
  operationContainer: {
    margin: '8px 0',
    display: 'flex',
    maxWidth: '95%',
    overflowX: 'auto',
  },
  tableContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    border: `1px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
  },
  labels: {
    display: 'flex',
    padding: '6px 16px',
    alignItems: 'center',
    fontWeight: 600,
    color: theme.palette.text.primary,
  },
  summaryTableRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  infoToolTip: theme.infoToolTip,
  input: {
    backgroundColor: '#ffffff',
    width: 120,
  },
}));

const StyledTableCell = withStyles((theme) => ({
  head: {
    // backgroundColor: theme.palette.greys.light,
    color: theme.palette.text.primary,
    padding: 4,
    whiteSpace: 'nowrap',
  },
  body: {
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
    padding: 8,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
      padding: '10px',
    },
  },
}))(TableRow);

export function CreateTable({
  totalRevenue,
  totalCosts,
  totalProfit,
  netSeededAcres,
  harvestOps,
  seedingOps,
  applicationOps,
  fieldpassOps,
  otherCostOps,
  otherRevOps,
  handleTenantLandlordShare,
  landlordCost,
  landlordRev,
  landlordProfit,
  tenantCost,
  tenantRev,
  tenantProfit,
  perAcre,
}) {

  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const [generatePdf, setGeneratePdf] = useState(false);

  const unitDisplay = (unit) => (
    capitalizeFirstLetter(unitConversion(unit, true))
  )

  const createHarvestTable = () => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }}>Year</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Crop Name</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Yield</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Acres</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Price ($/Unit)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '20%' }} colSpan={2}>Shares (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '30%' }} colSpan={3}>Total Revenue ($/Acre)</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Total ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant ($/Acre)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {harvestOps.map((op) => (
            <StyledTableRow key={op.operationID}>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.cropSeason}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.productName}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                {Math.round((op.quantity / op.area * 100) / 100)}
                {' '}
                {`${unitDisplay(op.yieldUnit)}/Acre`}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{Math.round(op.area * 100) / 100}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.operationCost / (op.quantity / op.area)) * 100) / 100}
                /
                {unitDisplay(op.unit)}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.landlordShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'harvest', 'landlord')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.tenantShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'harvest', 'tenant')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.rate * op.area) / netSeededAcres * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.rate * op.area) / netSeededAcres * (op.landlordShare / 100) * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.rate * op.area * (op.tenantShare / 100)) / netSeededAcres * 100) / 100}
                /Acre
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const createSeedingTable = () => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }}>Year</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Crop Name</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Applied Rate</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Acres</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Price ($/Unit)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '20%' }} colSpan={2}>Shares (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '30%' }} colSpan={3}>Total Cost ($/Acre)</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Total ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant ($/Acre)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {seedingOps.map((op) => (
            <StyledTableRow key={op.operationID}>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.cropSeason}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.productName}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                {Math.round((op.quantity / op.area * 100) / 100)}
                {' '}
                {`${unitDisplay(op.unit)}/Acre`}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{Math.round(op.area * 100) / 100}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.operationCost / (op.quantity / op.area)) * 100) / 100}
                /
                {unitDisplay(op.unit)}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.landlordShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'seeding', 'landlord')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.tenantShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'seeding', 'tenant')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.rate * op.area) / netSeededAcres * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.rate * op.area * (op.landlordShare / 100)) / netSeededAcres * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.rate * op.area * (op.tenantShare / 100)) / netSeededAcres * 100) / 100}
                /Acre
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const createApplicationTable = () => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }}>Year</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Product Name</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Applied Rate</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Acres</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Price ($/Unit)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '20%' }} colSpan={2}>Shares (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '30%' }} colSpan={3}>Total Cost ($/Acre)</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Total ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant ($/Acre)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {applicationOps.map((op) => (
            <StyledTableRow key={op.operationID}>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.cropSeason}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.productName}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                {Math.round((op.quantity / op.area * 100) / 100)}
                {' '}
                {`${op.unit}/Acre`}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{Math.round(op.area * 100) / 100}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.operationCost / (op.quantity / op.area)) * 100) / 100}
                /
                {op.unit}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.landlordShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'application', 'landlord')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.tenantShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'application', 'tenant')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.averageMaterialResult * op.area) / netSeededAcres * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.averageMaterialResult * op.area * (op.landlordShare / 100)) / netSeededAcres * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.averageMaterialResult * op.area * (op.tenantShare / 100)) / netSeededAcres * 100) / 100}
                /Acre
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const createFieldpassTable = () => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }}>Year</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Description</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Acres</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Price ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '20%' }} colSpan={2}>Shares (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '30%' }} colSpan={3}>Total Cost ($/Acre)</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Total ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant ($/Acre)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {fieldpassOps.map((op) => (
            <StyledTableRow key={op.operationID}>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.cropSeason}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.productName}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{Math.round(op.area * 100) / 100}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round(op.price * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.landlordShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'fieldpass', 'landlord')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.tenantShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'fieldpass', 'tenant')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * 100) / 100}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * (op.landlordShare / 100) * 100) / 100}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * (op.tenantShare / 100) * 100) / 100}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const createOtherCostTable = () => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }}>Year</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Description</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Acres</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Price ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '20%' }} colSpan={2}>Shares (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '30%' }} colSpan={3}>Total Cost ($/Acre)</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Total ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant ($/Acre)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otherCostOps.map((op) => (
            <StyledTableRow key={op.operationID}>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.cropSeason}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.productName}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{Math.round(op.area * 100) / 100}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round(op.price * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.landlordShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'costs', 'landlord')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.tenantShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'costs', 'tenant')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * 100) / 100}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * (op.landlordShare / 100) * 100) / 100}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * (op.tenantShare / 100) * 100) / 100}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const createOtherRevenueTable = () => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }}>Year</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Description</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Acres</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Price ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '20%' }} colSpan={2}>Shares (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '30%' }} colSpan={3}>Total Revenue ($/Acre)</StyledTableCell>
          </TableRow>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }} />
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant (in %)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Total ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Landlord ($/Acre)</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '10%' }}>Tenant ($/Acre)</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {otherRevOps.map((op) => (
            <StyledTableRow key={op.operationID}>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.cropSeason}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{op.productName}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>{Math.round(op.area * 100) / 100}</StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round(op.price * 100) / 100}
                /Acre
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.landlordShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'revenue', 'landlord')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                <TextField
                  className={classes.input}
                  variant="outlined"
                  value={op.tenantShare}
                  onChange={(e) => handleTenantLandlordShare(op.operationID, e.target.value, 'revenue', 'tenant')}
                  InputProps={{
                    inputComponent: NumberFormatCustom,
                    endAdornment: '%',
                    style: {
                      height: 40,
                      padding: '0 14px',
                    },
                  }}
                />
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * 100) / 100}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * (op.landlordShare / 100) * 100) / 100}
              </StyledTableCell>
              <StyledTableCell align="center" style={{ width: '10%' }}>
                $
                {Math.round((op.price * op.area) / netSeededAcres * (op.tenantShare / 100) * 100) / 100}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );

  const summaryTable = () => (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label="customized table">
        <TableHead>
          <TableRow>
            <StyledTableCell align="center" style={{ width: '25%', padding: "4px 16px" }}>{' '}</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "4px 16px" }}>{perAcre ? 'Total ($/Acre)' : 'Total ($)'}</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "4px 16px" }}>{perAcre ? 'Landlord ($/Acre)' : 'Landlord ($)'}</StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "4px 16px" }}>{perAcre ? 'Tenant ($/Acre)' : 'Tenant ($)'}</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow style={{paddingRight: 16, backgroundColor: lightGrey}}>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              Revenue
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              {perAcre ? `$${Math.abs(Math.round(totalRevenue * 100) / 100)}/Acre` : `$${Math.abs(Math.round(totalRevenue * 100) / 100)}`}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              {perAcre ? `$${landlordRev}/Acre` : `$${Math.abs(Math.round(landlordRev * netSeededAcres * 100) / 100)}`}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              {perAcre ? `$${tenantRev}/Acre` : `$${Math.abs(Math.round(tenantRev * netSeededAcres * 100) / 100)}`}
            </StyledTableCell>
          </TableRow>

          <TableRow style={{paddingRight: 16, backgroundColor: lightGrey}}>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              Costs
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              {perAcre ? `$${Math.abs(Math.round(totalCosts * 100) / 100)}/Acre` : `$${Math.abs(Math.round(totalCosts * 100) / 100)}`}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              {perAcre ? `$${landlordCost}/Acre` : `$${Math.abs(Math.round(landlordCost * netSeededAcres * 100) / 100)}`}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              {perAcre ? `$${tenantCost}/Acre` : `$${Math.abs(Math.round(tenantCost * netSeededAcres * 100) / 100)}`}
            </StyledTableCell>
          </TableRow>

          <TableRow style={{paddingRight: 16}}>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              Profit
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              {perAcre ? `$${Math.round(totalProfit * 100) / 100}/Acre` : `$${Math.round(totalProfit * 100) / 100}`}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px"}}>
              {perAcre ? `$${landlordCost}/Acre` : `$${Math.abs(Math.round(landlordCost * netSeededAcres * 100) / 100)}`}
            </StyledTableCell>
            <StyledTableCell align="center" style={{ width: '25%', padding: "8px 16px" }}>
              {perAcre ? `$${tenantProfit}/Acre` : `$${Math.round(tenantProfit * netSeededAcres * 100) / 100}`}
            </StyledTableCell>
          </TableRow>

        </TableBody>
      </Table>
    </TableContainer>
  )

  return (
    <Box display="flex" flexDirection="column" style={{ overflowY: 'auto' }}>

      {harvestOps.length > 0
        && (
        <Box className={classes.operationContainer}>
          <Box className={classes.tableContainer}>
            <Typography className={classes.tableLable}>Harvest Operations</Typography>
            <Divider />
            {createHarvestTable()}
            <Divider />
          </Box>
        </Box>
        )}

      {seedingOps.length > 0
        && (
        <Box className={classes.operationContainer}>
          <Box className={classes.tableContainer}>
            <Typography className={classes.tableLable}>Seeding Operations</Typography>
            <Divider />
            {createSeedingTable()}
            <Divider />
          </Box>
        </Box>
        )}

      {applicationOps.length > 0
        && (
        <Box className={classes.operationContainer}>
          <Box className={classes.tableContainer}>
            <Typography className={classes.tableLable}>Application Operations</Typography>
            <Divider />
            {createApplicationTable()}
            <Divider />
          </Box>
        </Box>
        )}

      {fieldpassOps.length > 0
        && (
        <Box className={classes.operationContainer}>
          <Box className={classes.tableContainer}>
            <Typography className={classes.tableLable}>Fieldpass Operations</Typography>
            <Divider />
            {createFieldpassTable()}
            <Divider />
          </Box>
        </Box>
        )}

      {otherCostOps.length > 0
        && (
        <Box className={classes.operationContainer}>
          <Box className={classes.tableContainer}>
            <Typography className={classes.tableLable}>Other Cost Operations</Typography>
            <Divider />
            {createOtherCostTable()}
            <Divider />
          </Box>
        </Box>
        )}

      {otherRevOps.length > 0
        && (
        <Box className={classes.operationContainer}>
          <Box className={classes.tableContainer}>
            <Typography className={classes.tableLable}>Other Revenue Operations</Typography>
            <Divider />
            {createOtherRevenueTable()}
            <Divider />
          </Box>
        </Box>
        )}

        <Box className={classes.operationContainer}>
          <Box className={classes.tableContainer} style={{flexGrow: 0}}>
            <Typography className={classes.tableLable}>Summary</Typography>
            <Divider />
            {summaryTable()}
            <Divider />
          </Box>
        </Box>

      <Divider />

      <div display="grid" p={1} style={{ marginTop: '10px' }}>
        <Button
          variant="outlined"
          color="primary"
          style={{ float: 'right' }}
          onClick={() => setGeneratePdf(true)}
        >
          Create Tenant-Landlord Analysis PDF
        </Button>
      </div>

      { generatePdf
        && (
        <CreatePDF
          open={generatePdf}
          setOpen={setGeneratePdf}
          harvestOps={harvestOps}
          seedingOps={seedingOps}
          applicationOps={applicationOps}
          fieldpassOps={fieldpassOps}
          otherCostOps={otherCostOps}
          otherRevOps={otherRevOps}
          totalRevenue={totalRevenue}
          totalCosts={totalCosts}
          totalProfit={totalProfit}
          landlordCost={landlordCost}
          landlordRev={landlordRev}
          landlordProfit={landlordProfit}
          tenantCost={tenantCost}
          tenantRev={tenantRev}
          tenantProfit={tenantProfit}
          netSeededAcres={netSeededAcres}
        />
        )}

    </Box>
  );
}

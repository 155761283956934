import React, { useState, useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Select,
  Divider,
  Link,
  Modal,
  CircularProgress,
  TextField,
  MenuItem,
  IconButton,
} from '@material-ui/core';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SaveIcon from '@material-ui/icons/Save';
import AddIcon from '@material-ui/icons/Add';
import EditIcon from '@material-ui/icons/Edit';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import MonetizationOnIcon from '@material-ui/icons/MonetizationOn';
import MapIcon from '@material-ui/icons/Map';
import { useSnackbar } from 'notistack';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Input from '@material-ui/core/Input';
import InputLabel from '@material-ui/core/InputLabel';
import { YearSelection } from '@material-ui/pickers/views/Year/YearView';
import { PriceToolInfo } from '../../PriceTool/PriceToolInfo';
import { Endpoints } from '../../../../constants/Endpoints';
import { UpdatePlantings } from './UpdatePlantings';
import { formatForProfitMap } from '../../utils/conversions';
import { exists, numFormat, roundTo, sleep } from '../../../../utils/helpers';
import organic from '../../images/organic.svg';
import irrigated from '../../images/irrigated.svg';
import * as df from '../../../../utils/dataFetchers';
import { green, blackText, lightGrey, grey, darkGrey } from '../../../../styles/colors';
import * as con from '../../utils/conversions';
import { useWindowDimensions } from '../../../../utils/dimensions';
import { Integrate } from '../../../Partners/Integrate';
import { IntegrateModal } from '../../../Shared/IntegrateModal';
import { Instructions } from '../GetStarted';
import { MenuProps } from "../../../../styles/select";
import { UploadShapeFile } from "../ManualFromShape/UploadShapeFile";
import { SpinningLoader } from '../../../Shared/SpinningLoader';
import { InfoModal, AcreageInfo } from '../../../Shared/InfoModal';
import { TemplatePrompt } from '../TemplatePrompt';
import { NumberFormatCustom, NumberFormatSeeds } from '../../../../utils/NumberFormatCustom';
import { cloneDeep } from "lodash";
import { CreateTable } from './CreateTable'
import { CustomToolTip } from '../../../../utils/customComponents'
import { MobileHeader } from '../Shared/MobileHeader'
import { hiddenInfo } from '../Shared/tooltips'


const useStyles = makeStyles((theme) => ({
  head: theme.tableHeader,
  headText: theme.tableHeaderText,
  labels: {
    display: 'flex',
    padding: '6px 16px',
    alignItems: 'center',
    fontWeight: 600,
    color: theme.palette.text.black,
  },
  tableRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  summaryTableRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  mapIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 500,
    fontSize: 14,
    display: 'flex',
  },
  icon: theme.plIcon,
  add: {
    ...theme.addOperation,
    width: '200px',
  },
  forecast: {
    ...theme.addOperation,
    width: '250px',
  },
  hidden: theme.hiddenOperation,
  link: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  begin: {
    color: blackText,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  selections: {
    height: 40,
    minWidth: 50,
  },
  hover: {
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.main,
      textDecoration: 'none',
    },
  },
  infoToolTip: theme.infoToolTip,
}));


/**
 * Display tables for harvest and seeding operations. From this screen users
 * will be able to add new operations and edit existing ones. Harvest
 * operations will recieve displayedSeedings in seeding paramater to be used
 * for optional yield forecasting.
 * @param {*} appliedTemplateYear 
 * @param {*} cleanTemplateYear 
 * @param {*} setAppliedTemplateYear 
 * @param {*} appliedTemplateYears 
 * @param {*} fieldAcres 
 * @param {*} setCustomTemplateName 
 * @param {*} setAllTemplates 
 * @param {*} setCurrentTemplate 
 * @param {*} allTemplates 
 * @param {*} currentTemplate 
 * @param {*} templateMode 
 * @param {Array} operations All operations of type for selected year
 * @param {Function} setOperations Updates operations
 * @param {Array} displayed Operations user has not hidden
 * @param {Array} hidden Operations user has hidden
 * @param {Array} seedings Seeding operations used for yield forecast
 * @param {String} type Type of operations. Harvest or Seeding.
 * @param {*} save 
 * @param {Bool} perAcre Data is per acre or per field
 * @param {Number} seededAcres Acres of field that have been seeded.
 * @param {Number} year Selected year of operations.
 * @param {Array} years list of selected years
 * @param {Object} field Field data
 * @param {*} convertPlantings 
 * @param {*} saveOperation 
 * @param {*} combineOps 
 * @param {Bool} saving While in process of saving, show loading circle.
 * @param {*} getProfitMap 
 * @param {Function} openPriceTool Open price tool to selected operation type.
 * @param {Array} loaded Operations who's data has been loaded.
 * @param {Function} refreshOperations call to get<type>Operations function in profitLayers.js
 * @param {*} selectedTemplate 
 * @param {*} setSelectedTemplate 
 * @param {*} openTemplateMode 
 * @param {*} integrated 
 * @param {Bool} templatesLoading
 * @param {Function} setTemplatesLoading
 * @param {*} hasOperations 
 * @param {*} yearSelection 
 * @param {*} mobileBreakPoint 
 * @param {Function} openSettings Open settings modal
 * @param {Function} resetData Refresh operation data
 * @param {*} deereEtlByField 
 * @param {*} source 
 * @param {*} uploadPrecisionFile 
 * @param {*} clipByBoundary 
 * @param {*} syncOperation 
 * @returns {JSX} Seedings or harvest tab in Setup ProfitLayers
 */
export function Plantings({
  appliedTemplateYear,
  cleanTemplateYear,
  setAppliedTemplateYear,
  appliedTemplateYears,
  fieldAcres,
  setCustomTemplateName,
  setAllTemplates,
  setCurrentTemplate,
  allTemplates,
  currentTemplate,
  templateMode,
  operations,
  setOperations,
  displayed,
  hidden,
  seedings,
  type,
  save,
  perAcre,
  seededAcres,
  year,
  years,
  field,
  convertPlantings,
  saveOperation,
  combineOps,
  saving,
  getProfitMap,
  openPriceTool,
  loaded,
  refreshOperations,
  selectedTemplate,
  setSelectedTemplate,
  openTemplateMode,
  integrated,
  templatesLoading,
  setTemplatesLoading,
  hasOperations,
  yearSelection,
  mobileBreakPoint,
  openSettings,
  resetData,
  deereEtlByField,
  source,
  uploadPrecisionFile,
  clipByBoundary,
  syncOperation
}) {
  // console.log(templateMode, templatesLoading)  
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const operation = type === 'Harvest' ? 'harvest revenue' : 'seed cost';
  const { enqueueSnackbar } = useSnackbar();

  const [viewHidden, setViewHidden] = useState(false);
  const [open, setOpen] = useState(false);
  const [integrateModalOpen, setIntegrateModalOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [action, setAction] = useState('Add');

  // Used to add year title above first operation in group
  const addedYears = useRef([]);

  // indexes of operations to display varieties for (as opposed to collapse)
  const [displaySummaries, setDisplaySummaries] = useState([]);

  const [forecastYield, setForecastYield] = useState(false);

  // operation to be edited
  const [toEdit, setToEdit] = useState({});

  const [isOperationMap, setOperationMap] = useState(false);
  const [operationMapDis, setOperationMapDis] = useState([]);
  const [operationUnit, setOperationUnit] = useState('');
  const [profitMapResponse, setProfitMapResponse] = useState([]);
  const [mapLoading, setMapLoading] = useState(false)

  const [allcrops, setCrops] = useState([]);
  const units = type === 'Seeding' ? con.seedingUnits : con.harvestUnits;
  const [allVarieties, setAllVarieties] = useState([]);
  const [yearDisplay, setYearDisplay] = useState('2020');
  const [templateYears, setTemplateYears] = useState([]);
  const [filteredTemplates, setFilteredTemplates] = useState([]);

  const [openTemplatePrompt, setOpenTemplatePrompt] = useState(false);
  const [templateModalHasOpened, setTemplateModalHasOpened] = useState(false);

  //cost map vs quantity map
  const [isQuantityMap, setIsQuantityMap] = useState(false)
  const [selectedOp, setSelectedOp] = useState('')
  const [mapType, setMapType] = useState('vector')

  // Check to make sure don't send out new save request while still processing
  const [savingPrice, setSavingPrice] = useState('')

  const [isVarietyMap, setIsVarietyMap] = useState(false)

  useEffect(() => {
    setDisplaySummaries([...Array(operations.length).keys()]);
  }, [operations]);

  useEffect(() => {
   // console.log('currentTemplate', currentTemplate)
    if (currentTemplate !== undefined) {
      //console.log('inside useeffect')
      setTemplatesLoading(false)
    }
  }, [currentTemplate])

  useEffect(() => {
    if (!hasOperations() && !templateMode) {
      templatePrompt()
    } else {
      //setOpenTemplatePrompt(false)
    }
  }, [operations, hasOperations])

  const templatePrompt = async () => {
    sleep(2000)
    if (!hasOperations() && !templateMode && !templateModalHasOpened) {
      setOpenTemplatePrompt(true)
      setTemplateModalHasOpened(true)
    }
  }

  const handleDeereEtlByField = () => {
    setOpenTemplatePrompt(false)
    deereEtlByField()
  }

  useEffect(()=>{
    //GET UNIQUE YEARS OF TEMPLATES - this will populate year select
    const allYears = [];
    if(templateMode === true){
      if (allTemplates != undefined) {
        for (const template of allTemplates) {
          allYears.push(template.Year);
        }

        // Here we're checking if we need to load a default template
        if (selectedTemplate.Name == '') {
          //console.log('currentTemplate', currentTemplate)
          if (currentTemplate == undefined) {
            loadDefaultTemplate();
          }
        }
      }
      const unique = [...new Set(allYears)];
      unique.sort((a,b) => a < b)
      setTemplateYears(unique);
      setYearDisplay(unique[0]);
    }

  }, [allTemplates]);

  useEffect(() => {
    if (yearDisplay != '' && allTemplates != undefined) {
      filterTemplatesByYear(yearDisplay);
    }
  }, [yearDisplay]);

  useEffect(() => {
    if (templateMode == false) {
      selectedTemplate.Name = '';
    }
  }, [templateMode]);

  useEffect(() => {
    if(selectedOp !== ''){
      console.log('displayOperationMap')
      displayOperationMap(selectedOp)
    }
  }, [isQuantityMap])

  /**
   * Fills in the missing type information missing from UpdatePlantings and calls createManualOperation in ProfitLayers.js
   * @param {Object} operation object containing necessary information about operation
   * @param {Boolean} auto boolean that indicates if manual operation being created was automatically generated or not
   */
  const saveUpdate = (operation, auto = true) => {
    save(type, operation, operation.zones, auto);
  };

  const filterTemplatesByYear = (year) => {
    const filtered = [];
    if (allTemplates != undefined) {
      for (const template of allTemplates) {
        // console.log("COMPARE: ", template["Year"], year);
        if (template.Year == year) {
          filtered.push(template);
        }
      }
      setFilteredTemplates(filtered);
    }
  };

  const getInitialTemplate = async () => {
    const operations = await df.getTemplateOperations(1);
    setCurrentTemplate(operations);
    setTemplatesLoading(false);
  };

  const handleEdit = (op, varieties, varietyMap) => {
    // add org and field ids to be used in getting geoJson
    console.log("handle edit")
    if(op.source.toUpperCase() == "USERENTERED"){
      const operation = { ...op, orgId: field.orgId, fieldId: field.id };
      setToEdit(operation);
      setAction('Edit');
      setOperationMap(false);
      setOperationMapDis([]);
      setOpen(true);
    }
    else{
      displayOperationMap(varieties, varietyMap)
    }
  };

  const updateView = (opns) => {
    setOperations(opns);
    setTemplatesLoading(false);
  };

  const handleNew = (forecast = false) => {
    setForecastYield(forecast);
    setToEdit({});
    setAction('Add');
    setOpen(true);
  };

  const handleVarietyInput = async (variety, val) => {
    /** This is the function from the old PL tool to retrieve crop varieties
     * based on user input. The api performs the autocomplete functionaity on keypress. * */

    let url = Endpoints.BASEURL;
    url += type === 'Harvest' ? Endpoints.HARVESTVARIETYNAME : Endpoints.SEEDVARIETY;
    url += `/${val}`;

    if (val && val !== '' && !allVarieties.includes(val)) {
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error('Failed to get variety names');
        })
        .then((vars) => setAllVarieties(vars))
        .catch((err) => enqueueSnackbar(err));
    }
    if (val == null || val == undefined) {
      val = '';
    }
    if (val != '' || val != null) {
      const newOps = [];
      for (const op of operations) {
        for (const vari of op.varieties) {
          if (JSON.stringify(vari) === JSON.stringify(variety)) {
            vari.variety = val;
            vari.name = val;
          }
        }
        newOps.push(op);
      }
      setOperations(newOps);
    }
  };
  const combineVarities = (varieties) => {
    const correct = [];

    const matchesFound = [];
    for (const variety of varieties) {
      const varMatches = varieties.filter((x) => x.variety === variety.variety);

      if (
        varMatches.length > 1
        && matchesFound.indexOf(varMatches[0].variety) === -1
      ) {
        matchesFound.push(varMatches[0].variety);

        let totalAcre = 0;
        let totalMaterial = 0;
        let totalPrice = 0;
        for (const match of varMatches) {
          totalAcre += parseFloat(match.area);
          totalMaterial += parseFloat(match.quantity);
          totalPrice += parseFloat(match.price);
        }

        const newVariety = { ...varMatches[0] };
        newVariety.area = totalAcre;
        newVariety.quantity = totalMaterial / varMatches.length;
        newVariety.price = totalPrice / varMatches.length;
        correct.push(newVariety);
      } else if (matchesFound.indexOf(varMatches[0].variety) === -1) {
        correct.push(variety);
      }
    }
    return correct;
  };

  const newHandleDisplay = async (op, hidden) => {
    const modifiedOpn = operations.filter(
      (ops) => ops.operationID === op.operationID,
    );

    const orgID = field.orgId;
    const fieldID = field.id;
    if (modifiedOpn != undefined) {
      await df.updateHidden(modifiedOpn);
      refreshOperations(orgID, fieldID, year, years, false, true);
    }
  };

  const handleDisplay = async (op) => {
    const modifiedOpn = operations.filter(
      (ops) => ops.operationID === op.operationID,
    );
    const nonModifiedOpn = operations.filter((op) => !modifiedOpn.includes(op));

    modifiedOpn[0].hidden = !modifiedOpn[0].hidden;

    for (let i = 0; i < modifiedOpn[0].varieties.length; i++) {
      modifiedOpn[0].varieties[i].isDeleted = !modifiedOpn[0].varieties[i]
        .isDeleted;
    }

    const saveOpn = convertPlantings(modifiedOpn).map((x) => formatForProfitMap(x));
    const otherCombined = combineOps(saveOpn)

    const savedOpns = saveOperation(saveOpn);
    // const combinedOps = combineOps(savedOpns);
    // console.log(JSON.stringify(combinedOps) === JSON.stringify(otherCombined), otherCombined, combinedOps)

    setOperations([...otherCombined, ...nonModifiedOpn]);
  };

  const updateToValueMap = (price) => {
    let mapCopy = operationMapDis;
    mapCopy[0].max = mapCopy[0].max * price;
    mapCopy[0].min = mapCopy[0].min * price;
    mapCopy[0].mean = mapCopy[0].mean * price;
    for(let entry of mapCopy[0].legend){
      entry.max = entry.max * price;
      entry.min = entry.min * price;
      entry.mean = entry.mean * price;
    }
    return mapCopy
  }

  const displayOperationMap = async (variety, varietyMap = false, index = 0, ) => {
    console.log(variety, varietyMap)
    let opn = operations.filter(
      (op) => op.operationID === variety.operationID,
    )[0];

    opn = { ...opn, orgId: field.orgId, fieldId: field.id };
    console.log(opn)
    //console.log(opn);
    setToEdit(opn);
    setAction('Edit');
    setOpen(true);

    if (opn.source !== 'USERENTERED') {
      setMapLoading(true)
      const op = JSON.parse(JSON.stringify(variety));
      if(varietyMap){
        op.variety = null
      }
      if(!isQuantityMap){
        op.price = 1
      }
      else{
        if(op.variety !== null){
          op.price = op.unit == 'K seeds' ? Math.abs(op.operationCost / op.rate)/1000 : Math.abs(op.operationCost / op.rate)
          let priceMap = updateToValueMap(op.price)
        }
        else{
          let allOps = operations.filter(
            (op) => op.operationID === variety.operationID,
          )
          //console.log('allOps', allOps)

          const acres = allOps[0].varieties.map((x) => x.area).reduce((a, b) => (a + b), 0);
          let quantity = 0;
          let price = 0;

          for (const op of allOps[0].varieties) {
            const percent = op.area / acres;
            quantity += (op.quantity / op.area) * percent;
            const opPrice = op.price * percent;
            if (!(Number.isNaN(opPrice))) {
              price += opPrice;
            }
          }
          //console.log(price)
          op.price = allOps[0].varieties[0] !== undefined && allOps[0].varieties[0].unit === 'K seeds' ? price/1000 : price
          
        }
      }
      let opsCopy = JSON.parse(JSON.stringify(op))
      opsCopy.price = opsCopy.unit === 'K seeds' ? opsCopy.price *1000 : opsCopy.price
      setSelectedOp(opsCopy)
      const request = {
        FieldOperationList: [op],
        Constant: 0, // this is a left hardcoded for operation maps
        Year: year,
        IsResizeMapRequest: false,
        ProfitMapLegendRange: null,
        LegendColors: null,
        ProfitByZones: false,
        Zones: null,
        boundarygeojson: null,
        IsHighResolutionMapRequest: false,
        IsOperationMapRequest: !varietyMap,
        IsVarietyMapRequest: true
      };

      if(clipByBoundary){
        request.boundarygeojson = field.boundary
      }

      let res;
      if (index === 0 && !isQuantityMap){
        res = await df.getVectorImage(field.orgId, field.id, request)
        console.log('res', res)
        if (res.message !== undefined && res.message === "Vector Image Generating"){
          // send request for normal profitmap
          res = await df.getProfitMap(field.orgId, field.id, request);
          console.log('res', res)
        }
      }
      else{
        res = await df.getProfitMap(field.orgId, field.id, request);
        console.log('res', res)
      }
      setMapLoading(false)
      if (res && res.profitMap !== undefined && res.profitMap.code === undefined) {
        setIsVarietyMap(varietyMap)
        setOperationMap(true);
        setOperationMapDis(res.profitMap);
        setOperationUnit(variety.unit);
        setProfitMapResponse(res.profitMap);
      } else {
        if (res?.profitMap?.message){
          enqueueSnackbar('Failed to Get ProfitLayer: ' + res?.profitMap?.message);
        }
        else if (res?.message){
          enqueueSnackbar('Failed to Get ProfitLayer: ' + res.message, {autoHideDuration: 8000});
        }
        else{
          enqueueSnackbar('Failed to Get ProfitLayer');
        }
      }
    }
  };

  const savePrice = async (opIndex, opId, shown) => {
    setSavingPrice(opId)

    const op = operations.filter(
      x => x.varieties[0].operationID === opId)[0]

    // Check that operations was found, has varieties, and all updated prices
    // are valid. All of these are probably unnecessary now but lets be safe.
    if (op?.varieties !== undefined && allPricesValid(op.varieties)) {
      // console.log(op)
      // if(op.source === "USERENTERED"){
      //   //send to manual operation to update geojson
      //   await save(op)
      // }
      // else{
        const res = await saveOperation(op.varieties);
        for(let i=0; i<op.varieties.length; i++){
          op.varieties[i].logID = res[i].logID
        }
      // }
    } else {
      enqueueSnackbar('Please enter a valid price to save')
    }
    setSavingPrice('')
  };

  // Check that all variety prices are valid before trying to save
  const allPricesValid = (varieties) => {
    let valid = true
    for (const variety of varieties) {
      if (isNaN(variety.price)) {
        valid = false
      }
    }
    return valid
  }

  const handleCropInput = async (val, variety) => {
    /** This is the function from the old PL tool to retrieve crop names
     * based on user input. The api performs the autocomplete functionaity on keypress. * */
    if (val && val !== '' && !allcrops.includes(val)) {
      let url = Endpoints.BASEURL;
      url
        += type === 'Harvest' ? Endpoints.HARVESTCROPNAME : Endpoints.SEEDCROPNAME;
      url += `/${val}`;
      fetch(url, {
        method: 'GET',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          }
          throw new Error('Failed to get crop names');
        })
        .then((crops) => setCrops(crops))
        .catch((err) => enqueueSnackbar(err));
    }

    if (val == null || val == undefined) {
      val = '';
    }
    if (val != '' || val != null) {
      const newOps = [];
      for (const op of operations) {
        if (op.operationID == variety.operationID) {
          op.name = val;
        }
        for (const vari of op.varieties) {
          if (JSON.stringify(vari) === JSON.stringify(variety)) {
            vari.cropName = val;
          }
        }
        newOps.push(op);
      }
      setOperations(newOps);
    }
  };
  const defaultTemplateForSelectMenu = () => {
    // default template
    const name = allTemplates[0].Name;
    const { ID } = allTemplates[0];
    const defaultTemplate = { ID, Name: name };
    setSelectedTemplate(defaultTemplate);
  };

  const loadDefaultTemplate = async () => {
    //console.log('loadDefaultTemplate')
    const value = allTemplates[0].ID;
    const operations = await df.getTemplateOperations(value);
    setSelectedTemplate(allTemplates[0]);
    setCurrentTemplate(operations);
    setTemplatesLoading(false);
  };

  const handleTemplateChange = async (e) => {
    //console.log('handleTemplateChange')
    const { value } = e.target;
    setSelectedTemplate(value);
    // setCurrentTemplateID(value);
    const operations = await df.getTemplateOperations(value.ID);
    setCurrentTemplate(operations);
    setTemplatesLoading(false);
    // setCurrentTemplateName(getCurrentTemplateName);
    // handleClose()
  };

  const handleRemoveTemplateOp = (op) => {
    const newOps = [];

    for (const currOp of operations) {
      if (JSON.stringify(currOp) !== JSON.stringify(op)) {
        newOps.push(currOp);
      }
    }
    setOperations(newOps);
  };

  const handlePriceChange = (val, variety, varietyIndex) => {
    let operationsCopy = cloneDeep(operations)
    let operationToCopyIndex = operationsCopy.findIndex(x => x.operationID === variety.operationID)
    let operationToUpdate = operationsCopy[operationToCopyIndex]
    let varietyToUpdate = operationToUpdate.varieties[varietyIndex]
    varietyToUpdate.price = val
    operationsCopy.splice(operationToCopyIndex, 1, operationToUpdate)
    setOperations(operationsCopy)
  };

  const handleCropNameChange = (val, variety, varietyIndex, opIndex, key) => {
    const operationCopy = operations.map((x) => JSON.parse(JSON.stringify(x)));
    const opToEdit = operationCopy.splice(opIndex, 1)[0];

    const varietyToEdit = opToEdit.varieties.splice(varietyIndex, 1)[0];

    if (key === 'productName') {
      if (val == null || val == undefined) {
        val = '';
      }
      if (val != '' || val != null) {
        const newOps = [];
        for (const op of operations) {
          for (const vari of op.varieties) {
            if (JSON.stringify(vari) === JSON.stringify(variety)) {
              vari.cropName = val;
            }
          }
          newOps.push(op);
        }
        setOperations(newOps);
      }
      opToEdit.varieties.splice(varietyIndex, 0, { ...varietyToEdit, productName: val });
    } else if (key === 'name') {
      if (val == null || val == undefined) {
        val = '';
      }
      if (val != '' || val != null) {
        const newOps = [];
        for (const op of operations) {
          for (const vari of op.varieties) {
            if (JSON.stringify(vari) === JSON.stringify(variety)) {
              vari.variety = val;
              vari.name = val;
            }
          }
          newOps.push(op);
        }
        setOperations(newOps);
      }
      opToEdit.varieties.splice(varietyIndex, 0, { ...varietyToEdit, variety: val });
    } else if (key === 'quantity') {
      if (type.toUpperCase() === 'SEEDING') {
        console.log(varietyToEdit)
        opToEdit.varieties.splice(varietyIndex, 0, {
          ...varietyToEdit, quantity: val * field.acres, averageMaterialTarget: val, rate: val,
        });
      } else if (type.toUpperCase() === 'HARVEST') {
        opToEdit.varieties.splice(varietyIndex, 0, {
          ...varietyToEdit, quantity: val * field.acres, averageYield: val, rate: val,
        });
      }
    } else if (key === 'unit') {
      console.log('unit', val)
      if (val == null || val == undefined) {
        val = '';
        console.log('unit', val)
      }
      if (val != '' || val != null) {
        const newOps = [];
        for (const op of operations) {
          for (const vari of op.varieties) {
            if (JSON.stringify(vari) === JSON.stringify(variety)) {
              vari.unit = val;
            }
          }

          newOps.push(op);
        }
        setOperations(newOps);
      }
      if (type === 'Seeding') {
        opToEdit.varieties.splice(varietyIndex, 0, { ...varietyToEdit, unit: val, averageMaterialTargetUnit: `${con.unitConversion(val)}1ac-1` });
      } else if (type === 'Harvest') {
        opToEdit.varieties.splice(varietyIndex, 0, { ...varietyToEdit, unit: val, averageYieldUnit: `${con.unitConversion(val)}1ac-1` });
      }
    }
    operationCopy.splice(opIndex, 0, opToEdit);
    setOperations(operationCopy);
  };

  const handleOpenIntegrateFromTemplatePrompt = () => {
    setOpenTemplatePrompt(false);
    setIntegrateModalOpen(true);
  }

  const showYear = (i, shown) => {
    if (!shown) {
      return '';
    }

    if (i === 0 && exists(displayed) && displayed.length > 0) {
      return (
        <Box mt={1} ml={2}>
          {displayYear(displayed[i].date.split('/')[2])}
        </Box>

      );
    }

    if (displayed.length > i) {
      if (displayed[i].date.split('/')[2] !== displayed[i - 1].date.split('/')[2]) {
        return (
          displayYear(displayed[i].date.split('/')[2])
        );
      }
    }
    return '';
  };

  const displayYear = (year) => (
    <Box
      mb={1}
      fontSize={18}
      fontWeight={500}
      color={blackText}
      style={{ width: '50px' }}
    >
      {year}
      <Divider />
    </Box>
  );

  function capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  const addTemplateOperation = () => {
    // lets make our own deep copy
    if (operations.length > 0) {
      // if there is an operation we can deep copy, lets do that
      let ndx = 0;
      let deepCopyOp;
      const allOps = [];
      for (const op of operations) {
        if (ndx == 0) {
          deepCopyOp = JSON.parse(JSON.stringify(op));
        }
        allOps.push(op);
        ndx++;
      }

      // lets hollow out the new op:
      deepCopyOp.operationID = '';
      deepCopyOp.name = '';
      for (const variety of deepCopyOp.varieties) {
        const indentifier = Math.floor(Math.random() * Math.floor(100000));
        variety.identifier = indentifier;
        variety.operationID = '';
        if (type == 'Seeding') {
          variety.seedingOperationID = '';
        } else if (type == 'Harvest') {
          variety.harvestOperationID = '';
        }
      }

      allOps.push(deepCopyOp);
      setOperations(allOps);

    } else { // no op to deep copy, we need to start from scratch
      let today = new Date();
      today.setFullYear(appliedTemplateYear)
      const dd = String(today.getDate()).padStart(2, '0');
      const mm = String(today.getMonth() + 1).padStart(2, '0'); // January is 0!
      const yyyy = today.getFullYear();
      today = `${mm}/${dd}/${yyyy}`;
      let endDay = `${yyyy}-05-01`;

      let newOp;
      switch (type) {
        case 'Seeding':
          newOp = {
            date: `05/01/${yyyy}`,
            hidden: false,
            name: '',
            operationID: '',
            source: 'TEMPLATE',
            varieties: [{
              area: fieldAcres,
              attribute: 'AppliedRate',
              averageMaterialTarget: 0,
              averageMaterialTargetUnit: 'seeds1ac-1',
              cellsize: '0.0001',
              cropName: 'CORN',
              cropSeason: year,
              date: `05/01/${yyyy}`,
              endDate: endDay,
              fileType: 'SEEDING',
              isDeleted: false,
              logID: 0,
              name: 'CORN',
              operationCost: 0,
              operationID: '',
              price: 0.00,
              productName: 'CORN',
              quantity: '',
              rate: 0,
              scenarioID: 1,
              seedingOperationID: '',
              source: 'TEMPLATE',
              startDate: `05/01/${yyyy}`,
              totalMaterialUnit: 'seeds',
              uid: '',
              unit: 'seeds',
              variety: 'DKC64-35',
            }],
          };
          break;
        case 'Harvest':
          newOp = {
            date: today,
            hidden: false,
            name: 'CORN',
            operationID: '',
            source: 'TEMPLATE',
            varieties: [{
              area: fieldAcres,
              attribute: 'VRYIELDVOL',
              averageMoisture: null,
              averageMoistureUnit: 'NULL',
              averageYield: 0,
              averageYieldUnit: 'bu1ac-1',
              cellsize: '0.0001',
              cropName: 'CORN',
              cropSeason: year,
              date: today,
              endDate: endDay,
              fileType: 'Harvest',
              harvestOperationID: '',
              isDeleted: false,
              logID: 0,
              name: 'CORN',
              operationCost: 0,
              operationID: '',
              price: 0.00,
              productName: 'CORN',
              quantity: 0,
              rate: NaN,
              scenarioID: 1,
              source: 'TEMPLATE',
              startDate: today,
              uid: '',
              unit: 'bu',
              variety: 'DKC64-35',
              wetMass: null,
              wetMassUnit: 'NULL',
              yieldUnit: 'bu',
            }],
          };
          break;
      }
      const allOps = [];
      for (const op of operations) {
        allOps.push(op);
      }
      allOps.push(newOp);
      setOperations(allOps);
      //cleanTemplateYear(appliedTemplateYear, true)
    }
  };

  const createTable = (op, i, shown) => {
    console.log(op)
    const combinedVarieties = combineVarities(op.varieties);
    // deep copy varieties for editing price
    const varieties = combinedVarieties.map((x) => JSON.parse(JSON.stringify(x)));
    // hidden operations get created after all displayed
    const index = op.hidden ? i + displayed.length : i;

    const unit = op.varieties[0].unit;

    return (
      <Box
        key={i}
        my={2}
        border={1}
        boxShadow={1}
        borderColor={grey}
        borderRadius='borderRadius'
        style={{ minWidth: '700px', maxWidth: '1000px', overflowX: 'auto' }}
      >
        <Box className={classes.head}>
          <Box mx={1} p={1} className={classes.headText}>
            {op.source === ('Ag-Analytics' || 'USERENTERED' || undefined) && (
              <SyncAltIcon />
            )}
            <Box mx={1}>{op.name}</Box>
            <Divider orientation={'vertical'}/>
            <Box mx={1}>{op.date}</Box>
          </Box>
          <Box mx={1} display="flex" alignItems="center">
            {(!templateMode && width >= 1024) && (
              <Box className={classes.icon} mx={1} onClick={() => handleEdit(op)}>
                <EditIcon fontSize="small" />
                Edit
              </Box>
            )}
            {(!templateMode && width >= 1024) && (
              <Box
                mx={1}
                className={classes.icon}
                onClick={() => displayOperationMap(varieties[0], true)}
              >
                <MapIcon fontSize="small" />
                {' '}
                MAP
              </Box>
            )}

            {shown > 0 && !templateMode ? (
              <Box
                className={classes.icon}
                mx={1}
                onClick={() => handleDisplay(op, 1)}
              >
                <VisibilityOffIcon fontSize="small" />
                Hide
              </Box>
            ) : !templateMode && (
              <Box
                className={classes.icon}
                mx={1}
                onClick={() => handleDisplay(op, 0)}
              >
                <VisibilityIcon fontSize="small" />
                Show
              </Box>
            )}
            {shown > 0 && templateMode && (
              <Box
                className={classes.icon}
                mx={1}
                onClick={() => handleRemoveTemplateOp(op)}
              >
                <DeleteForeverIcon fontSize="small" />
                Remove
              </Box>
            )}
          </Box>

        </Box>

        <Divider style={{ height: '3px' }} />

        <Box className={classes.labels}>
          <Box display="flex" alignItems='center' style={{ width: '20%' }}>
            <Box style={{ width: '25px' }}>
              {
                (displaySummaries.includes(index) && varieties.length > 1)
                  ? (
                    <ExpandLessIcon
                      className={classes.hidden}
                      onClick={() => {
                        const filtered = displaySummaries.filter((x) => x !== index);
                        setDisplaySummaries(filtered);
                      }}
                    />
                  )
                  : varieties.length > 1
                    ? (
                      <ExpandMoreIcon
                        className={classes.hidden}
                        onClick={() => setDisplaySummaries([...displaySummaries, i])}
                      />
                    )
                    : ''
              }
            </Box>

            <Box flexGrow={1}>
              {
                templateMode ? 'Crop' :
                varieties.length === 1 ? ' Variety' :
                ' Varieties'
              }
            </Box>
          </Box>
          <Box px={1} style={{ width: '15%' }}>
            Acres
          </Box>
          <Box px={1} style={{ width: '20%' }}>
            {type === 'Harvest'
              ? 'Yield/Acre'
              : `${capitalizeFirstLetter(con.unitConversion(unit, true))}/Acre`
            }
          </Box>
          <Box px={1} style={{ width: '25%' }}>
            {
              type === 'Harvest'
              ? `$/${capitalizeFirstLetter(con.unitConversion(unit, true))}`
              : `$/${capitalizeFirstLetter(con.unitConversion(unit, true))}`
            }
          </Box>
          <Box px={1} style={{ width: '20%' }}>
            {
              type === 'Harvest'
              ? 'Revenue'
              : `${capitalizeFirstLetter(con.unitConversion(unit, true))} Cost`
            }
            { perAcre ? ' ($/Acre)' : '($)' }
          </Box>
        </Box>

        <Box>
        </Box>

        {
          varieties.length > 0
          && (
          <Box>
            <Divider style={{ height: '3px' }} />

            {summaryRow(varieties)}
          </Box>
          )
        }
        <Divider />
      </Box>
    );
  };


  // if is NaN, display 0
  const showCost = (cost) => {
    const formatedCost = numFormat(cost)
    return formatedCost !== 'NaN' ? formatedCost : 0
  }

  const summaryRow = (operation, i) => {
    const { unit } = operation[0];
    const acres = operation.map((x) => x.area).reduce((a, b) => (a + b), 0);
    let quantity = 0;
    let price = 0;
    let costPerAcre = 0;

    for (const op of operation) {
      const percent = op.area / acres;
      quantity += (op.quantity / op.area) * percent;
      const opPrice = op.price * percent
      const opCostPerAcre = (op.price * (op.quantity / op.area)) * percent;
      if (!(Number.isNaN(opPrice))) {
        price += opPrice;
      }
      if (!Number.isNaN(opCostPerAcre)) {
        costPerAcre += opCostPerAcre
      }
    }

    return (
      <Box key={i} className={classes.summaryTableRow}>
        <Box px={1} style={{ width: '20%' }}>
          Summary:
        </Box>

        <Box px={1} style={{ width: '15%' }}>
          {numFormat(acres)}
          {' '}
          Acres
        </Box>

        <Box px={1} style={{ width: '20%' }}>
          <CustomToolTip
            placement="top"
            title="Weighted average of unit per acre"
          >
            <Box>
              {`${numFormat(quantity, 0, 2)} ${capitalizeFirstLetter(con.unitConversion(unit, true))}/Acre`}
            </Box>
          </CustomToolTip>

        </Box>

        <Box px={1} style={{ width: '25%' }}>
          <CustomToolTip
            placement="top"
            title="Weighted average of variety prices"
          >
            <Box>
              {'$'}
              { perAcre
                ? numFormat(price, 2, roundTo(price))
                : numFormat((price * acres), 2, roundTo(price))
              }
              {`/${capitalizeFirstLetter(con.unitConversion(unit, true))}`}
            </Box>
          </CustomToolTip>
        </Box>

        <Box px={1} style={{ width: '20%' }}>
          <CustomToolTip
            placement="top"
            title="Weighted average of ($/Acre)"
          >
            <Box>
              {'$'}
              {
                perAcre
                  ? `${numFormat(costPerAcre)}/Acre`
                  : numFormat(costPerAcre * acres)
               }
            </Box>
          </CustomToolTip>
        </Box>
      </Box>
    );
  };

  const summaryPrice = (price, acres, decimalPlaces) => {
    const formattedPrice = perAcre
      ? numFormat(price, 2, decimalPlaces)
      : numFormat((price * acres), 2, decimalPlaces)

    return formattedPrice === 'NaN' ? 0 : formattedPrice
  }

  const templateTable = (op, i) => {
    let area = 0
    let price = ''
    let quantity = 0

    //console.log(op)
    const convertToK = convertToKseeds(op.varieties)
    const unit =  op.varieties[0].unit;

    for (const variety of op.varieties) {
      area += variety.area
      price += variety.price !== '' ? variety.price * 1000 : '';
      quantity += variety.quantity
    }

    return (
      <Box
        key={i}
        my={2}
        border={1}
        boxShadow={1}
        borderColor={grey}
        borderRadius='borderRadius'
        style={{ minWidth: '800px', maxWidth: '1000px', overflowX: 'auto' }}
      >
        <Box className={classes.head}>
          <Box p={1} className={classes.headText}>
            <Box mx={1}>
              {op.date}
            </Box>

            <Divider orientation={'vertical'}/>

            <Box mx={1}>
              {'Acres: '}
              {numFormat(area)}
            </Box>

          </Box>

          <Box
            className={classes.icon}
            mx={1}
            onClick={() => handleRemoveTemplateOp(op)}
          >
            <DeleteForeverIcon fontSize="small" />
            Remove
          </Box>

        </Box>

        <Divider style={{ height: '3px' }} />

        <Box className={classes.labels}>
          <Box px={1} style={{width: '20%', minWidth: 180}}>
            Crop
          </Box>
          <Box px={1} style={{width: '20%', minWidth: 180}}>
            Variety
          </Box>
          <Box px={1} style={{width: '30%', minWidth: 300}}>
            {`${capitalizeFirstLetter(con.unitConversion(unit, true))}/Acre`}
          </Box>
          <Box px={1} style={{width: '15%', minWidth: 140}}>
            {`$/${capitalizeFirstLetter(con.unitConversion(unit, true))}`}
          </Box>
          <Box px={1} style={{width: '15%', minWidth: 140}}>
            <Box mx={1}>
              {
                type === 'Harvest'
                ? 'Revenue: '
                : `Cost: `
              }
              {'($)'}
              { perAcre && '/Acre' }
            </Box>
          </Box>
        </Box>

        {
          op.varieties.map((variety, j) => createTemplateRow(variety, j, i, convertToK))
        }

      </Box>
    )
  }

  const createTemplateRow = (variety, i, opIndex, convertToK) => {
    let total = 0

    if (perAcre) {
      let test = numFormat(variety.price * variety.rate)
      total = numFormat(variety.price * variety.rate) !== 'NaN' ? numFormat(variety.price * variety.rate) : 0
    } else {
      total =numFormat(variety.price * variety.quantity) !== 'NaN' ? numFormat(variety.price * variety.quantity) : 0
    }
    return (
      <Box key={i}>
        <Divider />
        <Box className={classes.tableRow}>
          <Box px={1} style={{width: '20%', minWidth: 180}}>
            <Autocomplete
              freeSolo
              value={variety.cropName !== null ? variety.cropName : ''}
              inputValue={variety.cropName !== null ? variety.cropName : ''}
              onChange={(event, newValue) => handleCropNameChange(newValue, variety, i, opIndex, 'productName')}
              onInputChange={(event, newInputValue) => handleCropInput(newInputValue, variety)}
              options={allcrops}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Box>

          <Box px={1} style={{width: '20%', minWidth: 180}}>
            <Autocomplete
              freeSolo
              value={variety.variety !== null ? variety.variety : ''}
              onChange={(event, newValue) => handleCropNameChange(newValue, variety, i, opIndex, 'name')}
              inputValue={variety.variety !== null ? variety.variety : ''}
              onInputChange={(event, newInputValue) => handleVarietyInput(variety, newInputValue)}
              options={allVarieties}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Box>

          <Box display='flex' alignItems='center' px={1} style={{width: '30%', minWidth: 300}}>
            <TextField
              variant="outlined"
              type="text"
              value={variety.rate}
              onChange={(e) => handleCropNameChange(e.target.value, variety, i, opIndex, 'quantity')}
              style={{minWidth: 100, marginRight: 6,}}
            />

            <Autocomplete
              className={classes.input}
              value={variety.unit}
              onChange={(event, newValue) => handleCropNameChange(newValue, variety, i, opIndex, 'unit')}
              inputValue={variety.unit}
              onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
              }}
              options={units}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
              style={{minWidth: 180}}
            />

          </Box>

          <Box display='flex' alignItems='center' px={1} style={{width: '15%', minWidth: 140}}>
            <TextField
              variant="outlined"
              style={{
                minWidth: '80px',
              }}
              InputProps={{
                startAdornment: '$',
              }}
              value={variety.price}
              onChange={(e) => handlePriceChange(e.target.value, variety, i)}
            />
          </Box>
          <Box
            px={1}
            display='flex'
            alignItems='center'
            fontSize={16}
            style={{width: '15%', minWidth: 140}}

          >
            {'$'}
            { total }
          </Box>
        </Box>
      </Box>
    )
  }

  const convertToKseeds = (varieties) => (
    varieties.filter(
      (x) => (x.unit === 'seeds' && x.rate > 1000),
    ).length === varieties.length
  )

  const setInputValue = (value) => {
    console.log('val');
  };

  const tableDisplay = () => {
    if (templateMode) {
      return (
        operations.filter(x => !x.varieties[0].isDeleted).map(
          (op, i) => templateTable(op, i))
      )
    } else {
      return (
        operations.filter(x => !x.varieties[0].isDeleted).map((op, i) => (
          <React.Fragment key={i}>
            { showYear(i, true) }

            <CreateTable
              key={i}
              op={op}
              i={i}
              shown={true}
              type={type}
              handleEdit={handleEdit}
              displayOperationMap={displayOperationMap}
              handleDisplay={handleDisplay}
              displaySummaries={displaySummaries}
              setDisplaySummaries={setDisplaySummaries}
              displayedLength={displayed.length}
              perAcre={perAcre}
              handlePriceChange={handlePriceChange}
              savePrice={savePrice}
              savingPrice={savingPrice}
              templateMode={templateMode}
              syncOperation={syncOperation}
            />
          </React.Fragment>
          )
        )
      )
    }
  }

  return (

    loaded.includes(type)
      ? (
        <Box p={1} px={2} style={{ width: '100%' }}>
          <Box mt={1} ml={1} fontWeight={500}>
            <Box mt={2} color={blackText} fontSize={18}>
              {type === 'Seeding' ? 'Seed Costs' : 'Harvest '}
              {' Summary'}
            </Box>

            <Box p={1}>
              {'View your '}
              {operation}
              {'s, update their prices, or '}
              <span className={classes.begin} onClick={() => handleNew()}>add a new operation</span>
            </Box>

            {templateMode && <Divider />}

            {templateMode && (
              <Box
                p={1}
                display='flex'
                flexWrap='wrap'
                fontWeight={500}
                color={blackText}
              >

                <Box px={1}>
                  <Box>
                    Year to Apply Template
                  </Box>
                  <Select
                    className={classes.selections}
                    value={
                      appliedTemplateYear !== undefined
                      ? appliedTemplateYear
                      : ''
                    }
                    onChange={(e) => setAppliedTemplateYear(e.target.value)}
                    MenuProps={MenuProps}
                    variant='outlined'
                    style={{minWidth: 90}}
                  >
                    {
                      appliedTemplateYears.map((year, i) => (
                        <MenuItem
                          key={i}
                          value={year.value}
                          name={year.name}
                        >
                          {year.name}
                        </MenuItem>
                      ))
                    }
                  </Select>
                </Box>

                <Divider orientation='vertical' flexItem={true}/>

                <Box px={1}>
                  <Box>
                    {
                      width > 1024 ? 'Select Crop Plan Template' : 'Select Template'
                    }

                  </Box>
                  <Select
                    className={classes.selections}
                    value={selectedTemplate}
                    onChange={handleTemplateChange}
                    MenuProps={MenuProps}
                    variant='outlined'
                  >
                    {
                      filteredTemplates != undefined && (
                        filteredTemplates.map((scenario, i) => (
                          <MenuItem
                            key={i}
                            value={scenario}
                            name={scenario.Name}
                          >
                            {scenario.Name}
                          </MenuItem>
                        ))
                      )
                    }
                  </Select>
                </Box>

                <Divider orientation='vertical' flexItem={true}/>

                <Box px={1}>
                  <Box>
                    {
                      width > 1024 ? 'Enter Custom Template Name' : 'Template Name'
                    }
                  </Box>
                  <TextField
                    className={classes.selections}
                    variant='outlined'
                    onChange={(e) => { setCustomTemplateName(e.target.value); }}
                    inputProps={{
                      style: {
                        padding: 10,
                      },
                    }}
                  />
                </Box>

                <Divider orientation='vertical' flexItem={true}/>

                <Box px={1}>
                  <Box>
                    {
                      width > 1024
                      ? 'Filter Templates by Year (optional)'
                      : 'Year Filter'
                    }
                  </Box>

                  <Select
                    className={classes.selections}
                    value={yearDisplay}
                    onChange={(e) => setYearDisplay(e.target.value)}
                    variant='outlined'
                    MenuProps={MenuProps}
                    style={{minWidth: 90}}
                  >
                   {
                    templateYears !=undefined && (
                      templateYears.map((year, i) =>
                        <MenuItem key={i} value={year} name={year}>{year}</MenuItem>
                      )
                    )}
                  </Select>
                </Box>

              </Box>
            )}
          </Box>

          <Divider />

          {
            (
              width < mobileBreakPoint &&
              !templateMode
            ) && (
              <MobileHeader
                yearSelection={yearSelection}
                seededAcres={seededAcres}
                openSettings={openSettings}
                resetData={resetData}
              />
          )}

          { tableDisplay() }

          {templateMode && (
            <Box mt={1} fontWeight={500} fontSize={16} color={blackText}>
              To add multiple varieties to one layer, edit your zones after generating template operations
            </Box>
          )}

          {saving && (
            <SpinningLoader />
          )}

          {
            (operations && operations.length === 0 && !templateMode) && (
            <Instructions
              type={type}
              createNew={handleNew}
              canAdd={width > 1024}
              openTemplate={openTemplateMode}
              integrated={integrated}
            />
          )}

          {
            templateMode && templatesLoading && (
              <Box p={1} fontWeight={500} fontSize={20}>
                <Box p={1}>
                  Loading Templates
                </Box>
                <Box p={1} display='flex' justifyContent='center' style={{width: '100%'}}>
                  <CircularProgress size={60}/>
                </Box>
              </Box>
            )
          }

          <Box display='flex' flexWrap='wrap'>
            {(!templateMode && width >= 1024) && (
              <Box
                className={classes.add}
                border={1}
                borderRadius="borderRadius"
                onClick={() => handleNew()}
                style={{marginRight: '18px'}}
              >
                <AddIcon />
                {` Add Manual ${type}`}
              </Box>
            )}

            {operations && operations.length === 0 && !templateMode &&
              <Box
                className={classes.add}
                border={1}
                borderRadius="borderRadius"
                onClick={() => openTemplateMode()}
                style={{width: '280px', marginRight: '18px'}}
              >
                <AddIcon />
                {' Generate Operation From Template'}
              </Box>
            }

            {templateMode && (
              <Box
                className={classes.add}
                border={1}
                borderRadius="borderRadius"
                onClick={addTemplateOperation}
                style={{width: '300px', marginRight: '18px'}}
              >
                <AddIcon />
                {` Add Template ${type} Operation`}
              </Box>
            )}

            {!templateMode && type === 'Harvest' && (
              <Box
                className={classes.forecast}
                border={1}
                borderRadius="borderRadius"
                onClick={() => handleNew(true)}
                style={{marginRight: '18px'}}
              >
                <AddIcon />
                {' '}
                Forecast Harvest Yield (BETA)
              </Box>
            )}

            {!templateMode && (
              <Box
                className={classes.add}
                border={1}
                borderRadius="borderRadius"
                onClick={() => uploadPrecisionFile()}
                style={{width: '220px'}}
              >
                <AddIcon />
                Upload Precision Ag Data
              </Box>
            )}
          </Box>

          {(!templateMode && hidden.length > 0) && (
          <Box
            className={classes.hidden}
            onClick={() => setViewHidden(!viewHidden)}
          >
            {viewHidden ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            {` Hidden Entries (${hidden.length})`}
            <CustomToolTip placement="right" title={hiddenInfo}>
              <InfoOutlinedIcon style={{ fontSize: '14px', marginLeft: '5px' }} />
            </CustomToolTip>
          </Box>
          )}

          { viewHidden &&
            operations.filter(x => x.varieties[0].isDeleted).map((op, i) => (
              <CreateTable
                key={i}
                op={op}
                i={i}
                shown={false}
                type={type}
                handleEdit={handleEdit}
                displayOperationMap={displayOperationMap}
                handleDisplay={handleDisplay}
                displaySummaries={displaySummaries}
                displayedLength={displayed.length}
                perAcre={perAcre}
                handlePriceChange={handlePriceChange}
                savePrice={savePrice}
                savingPrice={savingPrice}
              />
            )
          )}

          {(!templateMode && operations.length > 0) && (
            <Box>
              <Box display='flex'>
                <PriceToolInfo
                  openPriceTool={openPriceTool}
                  opType={type}
                />
              </Box>

              <Box display='flex'>
                <AcreageInfo
                  openModal={setInfoModalOpen}
                />
              </Box>

            </Box>
          )}

          { (!templateMode && operations.length === 0 && !integrated) && <Integrate /> }

          <Modal
            open={open}
            aria-labelledby="add-other"
            aria-describedby="add-other-costs-and-revenue"
          >
            <div>
              <UpdatePlantings
                type={type}
                action={action}
                operationToEdit={toEdit}
                field={field}
                perAcre={perAcre}
                setOpen={setOpen}
                save={saveUpdate}
                operations={operations}
                setOperations={setOperations}
                saveOperation={saveOperation}
                seedings={seedings}
                year={year}
                years={years}
                forecast={forecastYield}
                isOperationMap={isOperationMap}
                setOperationMap={setOperationMap}
                setOperationMapDis={setOperationMapDis}
                setProfitMapResponse={setProfitMapResponse}
                setOperationUnit={setOperationUnit}
                operationMapDis={operationMapDis}
                operationUnit={operationUnit}
                profitMapResponse={profitMapResponse}
                refreshOperations={refreshOperations}
                getOperationMap={displayOperationMap}
                isQuantityMap={isQuantityMap}
                setIsQuantityMap={setIsQuantityMap}
                selectedOp={selectedOp}
                isVarietyMap={isVarietyMap}
                setSelectedOp={setSelectedOp}
              />
              {mapLoading &&
                <SpinningLoader/>
              }
            </div>
          </Modal>

          <TemplatePrompt
            open={openTemplatePrompt}
            setOpen={setOpenTemplatePrompt}
            start={openTemplateMode}
            openIntegrate={handleOpenIntegrateFromTemplatePrompt}
            deereEtlByField={handleDeereEtlByField}
            source={source}
          />

          <IntegrateModal
            open={integrateModalOpen}
            setOpen={setIntegrateModalOpen}
          />

          <InfoModal
            open={infoModalOpen}
            setOpen={setInfoModalOpen}
            info={'acreage'}
          />

        </Box>
      )
      : (
        <SpinningLoader />
      )
  );
}

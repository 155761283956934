import React, { useEffect, useState } from 'react';
import {
  Box, Typography, Select, MenuItem, Link, Divider, Button,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from '../../../styles/select';
import { useWindowDimensions } from '../../../utils/dimensions';
import { blackText, lightGrey, grey } from '../../../styles/colors';
import { createYearArray } from '../../../utils/helpers';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { PrecipChart } from './PrecipChart';
import { Endpoints } from '../../../constants/Endpoints';
import { GetWaterManagement, GetUav } from '../../Shared/ServiceLinks';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * Precip chart
 * @param {bool} loading If data is loading
 * @returns {JSX} Precip chart
 */
export function Precipitation({
  field,
  loading,
  years,
  months,
  startYear,
  setStartYear,
  endYear,
  setEndYear,
  month,
  setMonth,
  data,
  errorMessage,
}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  return (
    <Box color={blackText} fontWeight={500}>

      <Box mt={1} mx={1}>
        <Box fontSize={18}>
          Seasonal Precipitation
        </Box>

        <Typography style={{ fontSize: '1rem', fontWeight: 500, color: blackText }}>
          Precipitation data. This graph shows accumulated precipitation to date compared to historical norms. These data are updated daily. Source data obtained from PRISM Climate Group, Oregon State,
          {' '}
          <Link style={{ textDecoration: 'none' }} href="http://prism.oregonstate.edu" target="_blank" rel="Noreferrer">http://prism.oregonstate.edu</Link>
          .
        </Typography>
        <Divider />
      </Box>

      <Box mt={0.5} display="flex" flexWrap="wrap">
        <Box mx={1}>
          <Box>
            End Year
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={endYear}
            onChange={(e) => setEndYear(e.target.value)}
          >
            {
                years.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x}
                  >
                    {x}
                  </MenuItem>
                ))
              }
          </Select>
        </Box>

        <Box mx={1}>
          <Box>
            Start Year
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={startYear}
            onChange={(e) => setStartYear(e.target.value)}
          >
            {
                years.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x}
                  >
                    {x}
                  </MenuItem>
                ))
              }
          </Select>
        </Box>

        <Box mx={1}>
          <Box>
            Start Month
          </Box>
          <Select
            className={classes.selections}
            variant="outlined"
            MenuProps={MenuProps}
            value={month}
            onChange={(e) => setMonth(e.target.value)}
          >
            {
                months.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x}
                  >
                    {x}
                  </MenuItem>
                ))
              }
          </Select>
        </Box>
      </Box>

      { errorMessage === '' ? (
        <PrecipChart
          data={data}
          startYear={startYear}
          endYear={endYear}
        />
      ) : (
        <Box m={1} display="flex">
          <Box className={classes.errorMessageBox}>
            {errorMessage}
          </Box>
        </Box>
      )}

      <Box display="flex" justifyContent="center">
        <Box m={0.5}>
          <GetWaterManagement direction="row" />
        </Box>
      </Box>

      {loading && <SpinningLoader />}
    </Box>
  );
}

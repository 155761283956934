import React, { useState, useEffect } from 'react';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  Box,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  CircularProgress,
  Typography,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { blackText } from '../../../styles/colors';
import { getScatterPoints, processPolaris, ssurgoForZones, HLSforNDVI, getNutrientSummary, getGeoJsonFromShape, convertShapeToTIFF } from '../../../utils/dataFetchers';
import { useWindowDimensions } from "../../../utils/dimensions";
import { useSnackbar } from "notistack";
import { backText } from '../../../styles/colors'
import { numFormat } from '../../../utils/helpers';

// Load Highcharts modules
require('highcharts/modules/exporting')(Highcharts)
require('highcharts/modules/export-data')(Highcharts)


const axios = require('axios');

const useStyles = makeStyles((theme) => ({
  input: {
    ...theme.plInput,
  },
}));

export function Scatter({
  displayed, field, year, operations, hideControls, showing, setLoaded, containerHeight,
  orderID, subOrderID, files
}) {
  /**
   * Takes displayed operations. Use selected to filter for ones we want to use
   * for scatter plot data.
   */

  const { height, width } = useWindowDimensions();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // index of data to select for x and y axis
  const [xAxis, setXAxis] = useState(0);
  const [yAxis, setYAxis] = useState(1);
  const [scatterXPoints, setScatterXPoints] = useState([]);
  const [scatterYPoints, setScatterYPoints] = useState([]);

  const [initialLoad, setInitialLoad] = useState(false)

  // data to select from to use for x and y axis on
  const [data, setData] = useState([
    { productName: 'Elevation', operationID: 'Elevationmap', unit: 'ft' },
    { productName: 'Profit', operationID: 'Profitmap', unit: '$/ac' },
    { productName: 'Section', operationID: 'Sectionmap', unit: 'Section Id' },
    { productName: 'Relative Elevation', operationID: 'Relative_Elevation', unit: 'z-score' },
    { productName: 'Slope', operationID: 'Slope', unit: 'ft' },
    { productName: 'Topographic Position Index (TPI)', operationID: 'TPI', unit: 'Index Value' },
    { productName: 'Terrain Ruggedness Index (TRI)', operationID: 'TRI', unit: 'Index Value' },
    { productName: 'Polaris', operationID: 'Polaris', unit: '' },
    { productName: 'SSURGO', operationID: 'SSURGO', unit: '' },
    { productName: 'NDVI', operationID: 'NDVI', unit: '' }
  ]);

  const soilVariables = [
    { name: 'Soil pH in H2O', value: 'ph' },
    { name: 'Organic Matter, %', value: 'om' },
    { name: 'Silt Percentage, %', value: 'silt' },
    { name: 'Sand Percentage, %', value: 'sand' },
    { name: 'Clay Percentage, %', value: 'clay' },
    { name: 'Bulk Density, g/cm3', value: 'bd' },
    { name: 'Available Water Content, m3/m3', value: 'awc' },
    { name: 'Saturated Soil Water Content, m3/m3', value: 'theta_s' },
    { name: 'Residual Soil Water Content, m3/m3', value: 'theta_r' },
    { name: 'Soil Water Content at Field Capacity, m3/m3', value: 'theta_33' },
    { name: 'Soil Water Content at the Wilting Point, m3/m3', value: 'theta_1500' },
    { name: 'Saturated Hydraulic Conductivity, cm/hr', value: 'ksat' },
    { name: 'Depth to Restriction Layer, cm', value: 'resdt' },
    { name: 'Calcium Carbonate in Soil, %', value: 'caco3' },
    { name: 'Cation Exchange Capacity of Soil, meq/100g', value: 'cec' },
    { name: 'Pore Size Distribution Index (brooks-corey)', value: 'lambda' },
    { name: 'Bubbling Pressure (brooks-corey), cm', value: 'hb' },
    { name: 'Measure of the Pore Size Distribution (van genuchten)', value: 'n' },
    { name: 'Scale Parameter Inversely Proportional to Mean Pore Diameter (van genuchten), cm-1', value: 'alpha' },
  ];

  const [selectedSoilVar1, setSelectedSoilVar1] = useState('')
  const [selectedSoilVar2, setSelectedSoilVar2] = useState('')

  const [showSoilVarX, setShowSoilVarX] = useState(false)
  const [showSoilVarY, setShowSoilVarY] = useState(false)
  const [rasterX, setRasterX] = useState(null)
  const [rasterY, setRasterY] = useState(null)
  const [xUnit, setXunit] = useState(null)
  const [yUnit, setYUnit] = useState(null)

  // selected data
  const [selectedX, setSelectedX] = useState(data[xAxis]);
  const [selectedY, setSelectedY] = useState(data[yAxis]);

  const [seriesData, setSeriesData] = useState([])

  const [loading, setLoading] = useState(false)

  //to save SSURGO response
  const [ssurgox, setSsurgoX] = useState({})
  const [ssurgoy, setSsurgoY] = useState({})

  //to set nutrients name
  const [nutrientsListX, setNutrientsListX] = useState([])
  const [nutrientsListY, setNutrientsListY] = useState([])
  const [showNutrientX, setShowNutrientX] = useState(false)
  const [showNutrientY, setShowNutrientY] = useState(false)
  const [selectedNutrientX, setSelectedNutrientX] = useState('')
  const [selectedNutrientY, setSelectedNutrientY] = useState('')

  // operaton to use
  const selected = ['Seeding', 'Harvest', 'Applications'];

  // scatter plot options
  const options = {
    chart: {
      type: 'scatter',
    },
    title: {
      text: `<b>Scatter Plot Analysis</b>`,
      style: {
        fontSize: '20px',
      },
    },
    xAxis: {
      title: {
        text: `<b>${selectedX.productName} (${(selectedX.unit)}${selectedX.areaUnit === undefined ? "" : "/ac"})</b>`,
        style: {
          fintSize: '16px',
        },
      },
      categories: [],
    },
    yAxis: {
      title: {
        text: `<b>${selectedY.productName} (${(selectedY.unit)}${selectedY.areaUnit === undefined ? "" : "/ac"})</b>`,
        style: {
          fontSize: '16px',
        },
      },
    },
    plotOptions: {
      line: {
        dataLabels: {
          enabled: true,
        },
        enableMouseTracking: false,
      },
    },
    series: [{
      data: seriesData,
      name: 'Percentile Ranges',
    }]
  };

  const [scatterData, setScatterData] = useState(options)

  //Soil test results
  const [soilTestResExist, setSoilTestResExist] = useState(false)
  const [fileAttrX, setFileAttrX] = useState([])
  const [fileAttrY, setFileAttrY] = useState([])
  const [showFileAttrX, setShowFileAttrX] = useState(false)
  const [showFileAttrY, setShowFileAttrY] = useState(false)

  const [selectedFileAttrX, setSelectedFileAttrX] = useState('')
  const [selectedFileAttrY, setSelectedFileAttrY] = useState('')

  useEffect(() => {
    // filter ops to use (harvest, seeding, applications)
    // then extract varieties and add to data to use for requesting plots

    const filtered = filterOps(displayed);
    getData(filtered);
  }, [displayed]);


  useEffect(() => {
    if (
      operations !== undefined &&
      operations.length > 0 &&
      (scatterXPoints.length === 0 && scatterYPoints.length === 0) &&
      !initialLoad
    ) {
      setInitialLoad(true)
      getScatterPlotData(operations, selectedX, selectedY);
    }
  }, [operations]);

  useEffect(() => {
    if (orderID !== '' && subOrderID !== '' && files.length > 0) {
      setSoilTestResExist(true)
    }
  }, [orderID, subOrderID, files])

  // useEffect(() => {
  //   console.log('scatterData', scatterData)
  // }, [scatterData]) 

  const getScatterPlotData = async (ops, X, Y, rasterPathX = null, rasterPathY = null, unitX = null, unitY = null, xssurgo = null, yssurgo = null, soil1 = null, soil2 = null) => {
    const xType = X.operationID === 'Profitmap' || X.operationID === 'Elevationmap' || X.operationID === 'Sectionmap' || X.operationID === 'Relative_Elevation'
      || X.operationID === 'Slope' || X.operationID === 'TRI' || X.operationID === 'TPI' || X.operationID === 'Polaris' ? X.operationID : X.fileType;
    const yType = Y.operationID === 'Profitmap' || Y.operationID === 'Elevationmap' || Y.operationID === 'Sectionmap' || Y.operationID === 'Relative_Elevation'
      || Y.operationID === 'Slope' || Y.operationID === 'TRI' || Y.operationID === 'TPI' || Y.operationID === 'Polaris' ? Y.operationID : Y.fileType;
    const request = {
      FieldOperationList: ops,
      aoi: field.boundary,
      mapname: [X.operationID.slice(-4).toUpperCase() === ".ZIP" ? "SOIL_TEST" : X.operationID, Y.operationID.slice(-4).toUpperCase() === ".ZIP" ? "SOIL_TEST" : Y.operationID],
      operationid: [rasterPathX == null ? X.operationID : rasterPathX, rasterPathY === null ? Y.operationID : rasterPathY],
      scatterplot: [xType, yType],
      MultiYears: year,
      Variety: [X.variety, Y.variety],
    };
    //console.log('getting scatter plot data')
    setLoaded(false)
    setLoading(true)
    const res = await getScatterPoints(field.orgId, field.id, year, request);
    if (res !== undefined) {
      let xAxisUnit = unitX === null ? X.unit : unitX
      let yAxisUnit = unitY === null ? Y.unit : unitY

      //console.log(xAxisUnit, yAxisUnit)
      setScatterXPoints(res.xperclist);
      setScatterYPoints(res.yperclist);
      let data = [], xCat = [], yCat = [], yData = []
      if (X.operationID === 'SSURGO') {
        for (let j = 0; j < res.xperclist.length; j++) {
          for (let i = 0; i < xssurgo.attributes.Legend.length; i++) {
            if (res.xperclist[j] == xssurgo.attributes.Legend[i].Value) {
              res.xperclist[j] = xssurgo.attributes.Legend[i].Soil_Type
            }
          }
        }
      }

      if (Y.operationID === 'SSURGO') {
        for (let j = 0; j < res.yperclist.length; j++) {
          for (let i = 0; i < yssurgo.attributes.Legend.length; i++) {
            if (res.yperclist[j] == yssurgo.attributes.Legend[i].Value) {
              res.yperclist[j] = yssurgo.attributes.Legend[i].Soil_Type
            }
          }
        }
      }

      for (let point in res.xperclist) {
        // let xy = {x: res.xperclist[point], y: res.yperclist[point]}
        // data.push(xy)
        if (X.operationID === 'SSURGO') {
          xCat.push(res.xperclist[point])
        } else {
          xCat.push(Math.round(res.xperclist[point] * 1000) / 1000)
        }

        if (Y.operationID === 'SSURGO') {
          data.push(res.yperclist[point])
        }
        else {
          data.push(Math.round(res.yperclist[point] * 1000) / 1000)
        }
      }

      //console.log('data', data)
      setSeriesData(data)

      setScatterData({
        ...scatterData,
        title: {
          text: `<b>Scatter Plot Analysis: ${X.productName !== 'Polaris' ? X.productName : soil1} vs ${Y.productName !== 'Polaris' ? Y.productName : soil2}</b>`,
          style: {
            fontSize: '20px',
          },
        },
        xAxis: {
          title: {
            text: `<b>${X.productName !== 'Polaris' ? X.productName : soil1} (${xAxisUnit}${X.areaUnit === undefined ? "" : "/ac"})</b>`,
            style: {
              fintSize: '16px',
            },
          },
          categories: xCat,
        },
        yAxis: {
          title: {
            text: `<b>${Y.productName !== 'Polaris' ? Y.productName : soil2} (${(yAxisUnit)}${Y.areaUnit === undefined ? "" : "/ac"})</b>`,
            style: {
              fontSize: '16px',
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `<b>${X.productName !== 'Polaris' ? X.productName : soil1}</b>: ${X.operationID !== 'SSURGO' ? numFormat(this.x) : this.x} <br/><b>${Y.productName !== 'Polaris' ? Y.productName : soil2}</b>: ${Y.operationID !== 'SSURGO' ? numFormat(this.y) : this.y}`
          }
        },
        series: [{
          data: data,
          name: 'Percentile Ranges',
        }]
      })
    }
    else {
      setScatterXPoints([]);
      setScatterYPoints([]);
      setSeriesData([])
      setScatterData({
        ...scatterData,
        title: {
          text: `<b>Scatter Plot Analysis: ${selectedX.productName} vs ${selectedY.productName}</b>`,
          style: {
            fontSize: '20px',
          },
        },
        xAxis: {
          title: {
            text: `<b>${selectedX.productName} (${(selectedX.unit)}${selectedX.areaUnit === undefined ? "" : "/ac"})</b>`,
            style: {
              fintSize: '16px',
            },
          },
        },
        yAxis: {
          title: {
            text: `<b>${selectedY.productName} (${(selectedY.unit)}${selectedY.areaUnit === undefined ? "" : "/ac"})</b>`,
            style: {
              fontSize: '16px',
            },
          },
        },
        tooltip: {
          formatter: function () {
            return `${this.key}: <b>${numFormat(this.percentage)}%</b><br>$${numFormat(this.y, 2, 5)}`;
          }
        },
        series: [{
          data: [],
          name: 'Percentile Ranges',
        }]
      })
    }

    setLoading(false)

    // for pdf
    setLoaded(true)
  };


  const getData = (operations) => {
    const opData = [];

    for (const operation of operations) {
      if (operation.type === 'Applications') {
        for (const op of operation.ops) {
          opData.push(op);
        }
      } else {
        // harvest and seeding
        for (const op of operation.ops) {
          for (const variety of op.varieties) {
            opData.push(variety);
          }
        }
      }
    }

    let fileNames = []
    for (let file of files) {
      let name = file.split("/")
      let obj = {
        productName: name[name.length - 1],
        operationID: file,
        unit: ''
      }
      fileNames.push(obj)
    }

    setData([
      { productName: 'Elevation', operationID: 'Elevationmap', unit: 'ft' },
      { productName: 'Profit', operationID: 'Profitmap', unit: '$/ac' },
      { productName: 'Section', operationID: 'Sectionmap', unit: 'Section Id' },
      { productName: 'Relative Elevation', operationID: 'Relative_Elevation', unit: 'z-score' },
      { productName: 'Slope', operationID: 'Slope', unit: 'ft' },
      { productName: 'Topographic Position Index (TPI)', operationID: 'TPI', unit: 'Index Value' },
      { productName: 'Terrain Ruggedness Index (TRI)', operationID: 'TRI', unit: 'Index Value' },
      { productName: 'Polaris', operationID: 'Polaris', unit: '' },
      { productName: 'SSURGO', operationID: 'SSURGO', unit: '' },
      { productName: 'NDVI', operationID: 'NDVI', unit: '' },
      ...opData,
      ...fileNames
    ])

  };

  const filterOps = (displayed) => displayed.filter((x) => selected.includes(x.type));

  const handleAxisChange = async (value, type) => {
    // value to change and type (x or y)
    let soil1 = selectedSoilVar1 == '' ? null : soilVariables[selectedSoilVar1].name
    let soil2 = selectedSoilVar2 == '' ? null : soilVariables[selectedSoilVar2].name
    let opsCopy = JSON.parse(JSON.stringify(operations))
    if (type === 'x') {
      setXAxis(value)
      setSelectedX(data[value])
      
      if (data[value].productName !== 'Polaris' && data[value].productName !== 'SSURGO' && data[value].productName !== 'NDVI' &&
       data[value].productName.slice(-4).toUpperCase() !== '.ZIP' && data[value].productName.slice(-4).toUpperCase() !== '.TIF') {
        setXunit(null)
        let nutrients = []
        if (data[value]?.fileType?.toUpperCase() == "APPLICATION") {
          nutrients = await getNutrientSummary(field.orgId, field.id, data[value].operationID)
          nutrients = JSON.parse(nutrients)
          if (nutrients.length > 0) {
            setShowNutrientX(true)
          }
          else {
            setShowNutrientX(false)
          }
          setSelectedNutrientX('')
        }
        setNutrientsListX(nutrients)
        getScatterPlotData(opsCopy, data[value], selectedY, null, rasterY, null, yUnit, null, ssurgoy, null, soil2);
        setShowSoilVarX(false)
        setRasterX(null)
        setXunit(null)
        setSelectedSoilVar1('')
        setSsurgoX(null)
        setShowFileAttrX(false)
      }
      else if (data[value].productName === 'SSURGO') {
        setShowSoilVarX(false)
        let req = {
          AOI: field.boundary,
          Projection: 'EPSG:4326',
          Resolution: '0.00001',
          Soil_Parameter: 'nccpi2all',
          Product: 'raster'
        }
        let res = await ssurgoForZones(req)
        setSsurgoX(res)
        setRasterX(res.filename)
        getScatterPlotData(opsCopy, data[value], selectedY, res.filename, rasterY, null, null, res, ssurgoy, soil1, soil2)
        setShowFileAttrX(false)
      }
      else if (data[value].productName === 'Polaris') {
        setShowSoilVarX(true)
        setShowFileAttrX(false)
      }
      else if (data[value].productName === 'NDVI') {
        setShowSoilVarX(false)
        let Startdate = new Date()
        let day = Startdate.getDate() + 7
        let month = Startdate.getMonth() + 1
        let Enddate = new Date(month + "/" + day + "/" + Startdate.getFullYear())

        let req = {
          aoi: field.boundary,
          Band: JSON.stringify(['NDVI']),
          satellite: 'Landsat,Sentinel',
          Startdate: Startdate.toLocaleDateString(),
          Enddate: Enddate.toLocaleDateString(),
          legendtype: 'Relative',
          resolution: "0.00001",
          showlatest: "1",
          filter: "1",
          qafilter: "1",
          qacloudperc: "85",
          interpolate: "",
          interpolatedim: null,
          interpolatemethod: null,
          byweek: "1",
        }

        let res = await HLSforNDVI(req)
        res = JSON.parse(res)
        setRasterX(res[0].download_url)
        setShowFileAttrX(false)
        getScatterPlotData(opsCopy, data[value], selectedY, res[0].download_url, rasterY, null, null, ssurgox, ssurgoy, soil1, soil2)
      }
      else if(data[value].productName.slice(-4).toUpperCase() === '.ZIP'){
        let attr = await convertShapeToTIFF(data[value].operationID)
        console.log('attr', attr)
        setShowFileAttrX(true)
        setFileAttrX(attr.Shape_FieldNames)
      }
    } else {
      setYAxis(value)
      setSelectedY(data[value])
      if (data[value].productName !== 'Polaris' && data[value].productName !== 'SSURGO' && data[value].productName !== 'NDVI' &&
      data[value].productName.slice(-4).toUpperCase() !== '.ZIP' && data[value].productName.slice(-4).toUpperCase() !== '.TIF') {
        setYUnit(null)
        let nutrients = []
        if (data[value]?.fileType?.toUpperCase() == "APPLICATION") {
          nutrients = await getNutrientSummary(field.orgId, field.id, data[value].operationID)
          nutrients = JSON.parse(nutrients)

          if (nutrients.length > 0) {
            setShowNutrientY(true)
          }
          else {
            setShowNutrientY(false)
          }
          setSelectedNutrientY('')
        }
        setNutrientsListY(nutrients)
        getScatterPlotData(opsCopy, selectedX, data[value], rasterX, null, xUnit, null, ssurgox, null, soil1, null);
        setShowSoilVarY(false)
        setRasterY(null)
        setYUnit(null)
        setSelectedSoilVar2('')
        setSsurgoY(null)
        setShowFileAttrY(false)
      }
      else if (data[value].productName === 'SSURGO') {
        setShowSoilVarY(false)
        let req = {
          AOI: field.boundary,
          Projection: 'EPSG:4326',
          Resolution: '0.00001',
          Soil_Parameter: 'nccpi2all',
          Product: 'raster'
        }
        let res = await ssurgoForZones(req)
        setSsurgoY(res)
        setRasterY(res.filename)
        getScatterPlotData(opsCopy, selectedX, data[value], rasterX, res.filename, null, null, ssurgox, res, soil1, soil2)
        setShowFileAttrY(false)
      }
      else if (data[value].productName === 'Polaris') {
        setShowSoilVarY(true)
        setShowFileAttrY(false)
      }
      else if (data[value].productName === 'NDVI') {
        setShowSoilVarY(false)
        let Startdate = new Date()
        let day = Startdate.getDate() + 7
        let month = Startdate.getMonth() + 1
        let Enddate = new Date(month + "/" + day + "/" + Startdate.getFullYear())

        let req = {
          aoi: field.boundary,
          Band: JSON.stringify(['NDVI']),
          satellite: 'Landsat,Sentinel',
          Startdate: Startdate.toLocaleDateString(),
          Enddate: Enddate.toLocaleDateString(),
          legendtype: 'Relative',
          resolution: "0.00001",
          showlatest: "1",
          filter: "1",
          qafilter: "1",
          qacloudperc: "85",
          interpolate: "",
          interpolatedim: null,
          interpolatemethod: null,
          byweek: "1",
        }

        let res = await HLSforNDVI(req)
        res = JSON.parse(res)
        setRasterY(res[0].download_url)
        getScatterPlotData(opsCopy, selectedX, data[value], rasterX, res[0].download_url, null, null, ssurgox, ssurgoy, soil1, soil2)
        setShowFileAttrY(false)
      }
      else if(data[value].productName.slice(-4).toUpperCase() === '.ZIP'){
        let attr = await convertShapeToTIFF(data[value].operationID)
        console.log('attr', attr)
        setShowFileAttrY(true)
        setFileAttrY(attr.Shape_FieldNames)
      }
    }
  }

  const handleSoil = async (value, type) => {
    console.log(selectedSoilVar1)
    let opsCopy = JSON.parse(JSON.stringify(operations))
    if (type === 'x') {
      setSelectedSoilVar1(value)
      if (selectedX.productName === 'Polaris') {
        let xAxisUnit = soilVariables[value].name.split(",")
        xAxisUnit = xAxisUnit[1] == undefined ? null : xAxisUnit[1]
        setXunit(xAxisUnit)

        let req = {
          Soil_Parameter: soilVariables[value].value,
          Depth_Range: '0-5',
          Statistic: 'mean',
          Legend_Ranges: null,
          aoi: field.boundary,
          Resolution: "0.0001"
        }
        try {
          let res = await processPolaris(req)
          res = JSON.parse(res)
          //console.log(res)
          setRasterX(res.FileName)
          let soil2 = selectedSoilVar2 == '' ? null : soilVariables[selectedSoilVar2].name
          getScatterPlotData(opsCopy, selectedX, selectedY, res.FileName, rasterY, xAxisUnit, yUnit, ssurgox, ssurgoy, soilVariables[value].name, soil2)
        }
        catch (err) {
          console.log('error processing polaris', err)
        }
      }
    } else {
      setSelectedSoilVar2(value)
      if (selectedY.productName === 'Polaris') {
        let yAxisUnit = soilVariables[value].name.split(",")
        yAxisUnit = yAxisUnit[1] == undefined ? null : yAxisUnit[1]
        setYUnit(yAxisUnit)

        let req = {
          Soil_Parameter: soilVariables[value].value,
          Depth_Range: '0-5',
          Statistic: 'mean',
          Legend_Ranges: null,
          aoi: field.boundary,
          Resolution: "0.0001"
        }
        try {
          let res = await processPolaris(req)
          res = JSON.parse(res)
          setRasterY(res.FileName)
          let soil1 = selectedSoilVar1 == '' ? null : soilVariables[selectedSoilVar1].name
          getScatterPlotData(opsCopy, selectedX, selectedY, rasterX, res.FileName, xUnit, yAxisUnit, ssurgox, ssurgoy, soil1, soilVariables[value].name)
        }
        catch (err) {
          console.log('error processing polaris', err)
        }
      }
    }
  }

  const handleNutrient = (value, type) => {
    //console.log('value, type', value, type)  
    let soil1 = selectedSoilVar1 == '' ? null : soilVariables[selectedSoilVar1].name
    let soil2 = selectedSoilVar2 == '' ? null : soilVariables[selectedSoilVar2].name

    let opsCopy = JSON.parse(JSON.stringify(operations))
    if (type == 'x') {
      for (let i = 0; i < opsCopy.length; i++) {
        if (selectedX.operationID == opsCopy[i].operationID) {
          opsCopy[i].IsNMap = true;
          opsCopy[i].price = nutrientsListX[value].NutrientRate
        }
        if (selectedNutrientY !== '' && selectedY.operationID == opsCopy[i].operationID) {
          opsCopy[i].IsNMap = true;
          opsCopy[i].price = nutrientsListY[value].NutrientRate
        }
      }
      setSelectedNutrientX(value)
      getScatterPlotData(opsCopy, selectedX, selectedY, null, rasterY, null, yUnit, null, ssurgoy, null, soil2);
    } else {
      for (let i = 0; i < opsCopy.length; i++) {
        if (selectedY.operationID == opsCopy[i].operationID) {
          opsCopy[i].IsNMap = true;
          opsCopy[i].price = nutrientsListY[value].NutrientRate
        }
        if (selectedNutrientX !== '' && selectedX.operationID == opsCopy[i].operationID) {
          opsCopy[i].IsNMap = true;
          opsCopy[i].price = nutrientsListX[value].NutrientRate
        }
      }
      setSelectedNutrientY(value)
      getScatterPlotData(opsCopy, selectedX, selectedY, rasterX, null, xUnit, null, ssurgox, null, soil1, null);
    }
  }

  const handleAttrSelect = async (value, type) => {
    let opsCopy = JSON.parse(JSON.stringify(operations))
    let soil1 = selectedSoilVar1 == '' ? null : soilVariables[selectedSoilVar1].name
    let soil2 = selectedSoilVar2 == '' ? null : soilVariables[selectedSoilVar2].name
    if (type === 'x') {
      setSelectedFileAttrX(value)
      let isCategory = fileAttrX[value].type.toUpperCase() == "STRING" ? 1 : 0
      if(isCategory == 1){
        enqueueSnackbar("Cannot perform scatter plot analysis on category raster, please select a different attribute")
      }
      else{
        let fileDetails = await convertShapeToTIFF(selectedX.operationID, isCategory, fileAttrX[value].name)
        let xRaster = fileDetails.File_ID + "-" + fileDetails.Converted_To
        setRasterX(xRaster)
        setXunit(fileAttrX[value].name)
        getScatterPlotData(opsCopy, selectedX, selectedY, xRaster, rasterY, fileAttrX[value].name, yUnit, ssurgox, ssurgoy, soil1, soil2)
      }      
    } else {
      setSelectedFileAttrY(value)
      let isCategory = fileAttrX[value].type.toUpperCase() == "STRING" ? 1 : 0
      if(isCategory == 1){
        enqueueSnackbar("Cannot perform scatter plot analysis on category raster, please select a different attribute")
      }
      else{
        let fileDetails = await convertShapeToTIFF(selectedX.operationID, isCategory, fileAttrX[value].name)
        let yRaster = fileDetails.File_ID + "-" + fileDetails.Converted_To
        setRasterY(yRaster)
        setYUnit(fileAttrY[value].name)
        getScatterPlotData(opsCopy, selectedX, selectedY, rasterX, yRaster, xUnit, fileAttrY[value].name, ssurgox, ssurgoy, soil1, soil2)
      }     
    }
  }

  return (
    <Box
      p={1}
      fontWeight={500}
      color={blackText}
      style={{
        width: '100%',
        minWidth: '500px',
        height: '100%',
        minHeight: '500px',
        overflow: 'auto',
      }}
    >
      <Box p={1} fontSize={18} color={blackText}>
        See a side-by-side breakdown across your field for profit or other metrics against key variables such as seeding density and elevation to better understand your sources of profitability and cost.
      </Box>
      <Divider />
      {
        !hideControls &&
        <Box display="flex" pt={1}>
          <Box>
            <Box>
              X Axis
            </Box>
            <FormControl variant="outlined">
              <Select
                className={classes.input}
                value={xAxis}
                onChange={(e) => handleAxisChange(e.target.value, 'x')}
              >
                {data.map((x, i) => (
                  <MenuItem value={i} key={i}>
                    {x.productName === undefined ? '' : `${x.productName}`} {x.variety === null || x.variety === undefined ? "" : "(" + x.variety + ")"}
                    {' '}
                    {x.date === undefined ? '' : `-${x.date}`}
                    {' '}
                    {x.fileType === undefined ? '' : `(${x.fileType})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {showSoilVarX && (
              <FormControl variant="outlined">
                <Select
                  className={classes.input}
                  value={selectedSoilVar1}
                  onChange={(e) => handleSoil(e.target.value, 'x')}
                >
                  {soilVariables.map((x, i) => (
                    <MenuItem value={i} key={i}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>)
            }

            { showFileAttrX && (
              <FormControl variant="outlined">
                <Select
                  className={classes.input}
                  value={selectedFileAttrX}
                  onChange={(e) => handleAttrSelect(e.target.value, 'x')}
                >
                  {fileAttrX.map((x, i) => (
                    <MenuItem value={i} key={i}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
              )}

            {showNutrientX && (
              <FormControl variant="outlined">
                <Select
                  className={classes.input}
                  value={selectedNutrientX}
                  onChange={(e) => handleNutrient(e.target.value, 'x')}
                >
                  {nutrientsListX.map((x, i) => (
                    <MenuItem value={i} key={i}>
                      {x.NutrientAlias}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>)
            }
          </Box>


          <Box>
            <Box>
              Y Axis
            </Box>
            <FormControl variant="outlined">
              <Select
                className={classes.input}
                value={yAxis}
                onChange={(e) => handleAxisChange(e.target.value, 'y')}
              >
                {data.map((x, i) => (
                  <MenuItem value={i} key={i}>
                    {x.productName === undefined ? '' : `${x.productName}`} {x.variety === null || x.variety === undefined ? "" : "(" + x.variety + ")"}
                    {' '}
                    {x.date === undefined ? '' : `-${x.date}`}
                    {' '}
                    {x.fileType === undefined ? '' : `(${x.fileType})`}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            {showSoilVarY &&
              <FormControl variant="outlined">
                <Select
                  className={classes.input}
                  value={selectedSoilVar2}
                  onChange={(e) => handleSoil(e.target.value, 'y')}
                >
                  {soilVariables.map((x, i) => (
                    <MenuItem value={i} key={i}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }

            {showFileAttrY && (
              <FormControl variant="outlined">
                <Select
                  className={classes.input}
                  value={selectedFileAttrY}
                  onChange={(e) => handleAttrSelect(e.target.value, 'y')}
                >
                  {fileAttrY.map((x, i) => (
                    <MenuItem value={i} key={i}>
                      {x.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}

            {showNutrientY &&
              <FormControl variant="outlined">
                <Select
                  className={classes.input}
                  value={selectedNutrientY}
                  onChange={(e) => handleNutrient(e.target.value, 'y')}
                >
                  {nutrientsListY.map((x, i) => (
                    <MenuItem value={i} key={i}>
                      {x.NutrientAlias}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            }

          </Box>
        </Box>

      }

      {
        (loading && showing) &&
        <CircularProgress
          size={100}
          style={{
            position: 'absolute',
            left: '50%',
            top: '50%',
            zIndex: 1005,
          }}
        />
      }

      <Box
        id='scatter-plot'
      >
        {
          seriesData.length > 0 && (
            <HighchartsReact
              containerProps={{
                style: {
                  height: containerHeight,
                }
              }}
              highcharts={Highcharts}
              options={scatterData}
            />
          )
        }
      </Box>
    </Box>
  );
}

import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Typography, Divider } from '@material-ui/core';
import { dollarFormat } from '../../../utils/helpers';
import { OrderItem } from './OrderItem';

/**
 * Displays items in users order. Will be used as order cart and to display
 * completed orders. Optionally, if delete and edit fields are not an option
 * they can be left out of paramaters and component will not render those icons.
 * @param {Array} orderDetails Objects of order information
 * @param {Function} deleteField Removes field from order
 * @param {Function} editField Removes field from order and allows user to edit
 * @param {Function} editFieldName Update field name in cart and if selected
 * @param {Number} total Order total
 * @returns {JSX} Order details
 */
export const DisplayOrder = ({
  orderDetails,
  deleteField,
  editField,
  editFieldName,
  total,
  orderErrors,
}) => {
  const [customPricing, setCustomPricing] = useState(false);

  useEffect(() => {
    const containsCustom = orderDetails.filter((x) => x.package === 'Custom Package');
    setCustomPricing(containsCustom?.length > 0);
  }, [orderDetails]);

  return (
    <Box>
      <Typography align="center" style={{ fontWeight: 500, fontSize: 18 }}>
        Your Order:
      </Typography>

      <Box mt={1}>
        { orderDetails.length > 0 ? (
          orderDetails.map((fieldOrderInfo) => (
            <OrderItem
              key={fieldOrderInfo?.latitude}
              fieldOrderInfo={fieldOrderInfo}
              deleteField={deleteField}
              editField={editField}
              editFieldName={editFieldName}
              orderErrors={orderErrors}
            />
          ))
        ) : (
          <Typography align="center">
            Your cart is empty
          </Typography>
        )}

      </Box>

      <Divider style={{ marginTop: 18 }} />

      <Box mt={1}>
        <Box display="flex">
          <Typography style={{ fontWeight: 500 }}>
            {`Package${orderDetails.length > 1 ? 's' : ''} Subtotal:`}
          </Typography>
          <Typography style={{ marginLeft: 'auto', fontWeight: 500 }}>
            {dollarFormat(total)}
            {customPricing && '*'}
          </Typography>
        </Box>

        <Box mt={1} display="flex">
          <Typography style={{ fontWeight: 500 }}>Total Cost:</Typography>
          <Typography style={{ marginLeft: 'auto', fontWeight: 500 }}>
            {dollarFormat(Number(total))}
            {customPricing && '*'}
          </Typography>
        </Box>

        { customPricing && (
          <Box mt={1} display="flex" maxWidth={460}>
            <Typography variant="subtitle2">
              *Does not include pricing for custom packages. Custom pricing will be determined when you speak with your soil specialist.
            </Typography>

          </Box>
        )}

      </Box>
    </Box>
  );
};

DisplayOrder.propTypes = {
  orderDetails: PropTypes.array.isRequired,
  deleteField: PropTypes.func,
  editField: PropTypes.func,
  editFieldName: PropTypes.func,
  total: PropTypes.number.isRequired,
};

DisplayOrder.defaultProps = {
  deleteField: undefined,
  editField: undefined,
  editFieldName: undefined,
};

import React, { useContext, useEffect, useState } from 'react';
import ReactGA from 'react-ga';

import _ from 'underscore';

import {
  Box,
  Typography,
  TextField,
  Button,
  Modal,
  Container,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from '@material-ui/styles';
import {
  grey, lightGrey, blackText, darkText, green,
} from '../../styles/colors';

import background from '../../images/tractors.jpg';

import { useWindowDimensions } from '../../utils/dimensions';
import { Endpoints } from '../../constants/Endpoints';

import { Connect } from '../Shared/Connect';
import { UserContext } from '../Context/UserContext';

const useStyles = makeStyles((theme) => ({
  contentContainer: (props) => ({
    width: props.width,
    height: props.height - 80,
  }),
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
  },
  darken: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    backgroundColor: 'rgba(0,0,0,.6)',
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
  },
  profileContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '80%',
    padding: 20,
    alignItems: 'center',
  },
  paper: {
    ...theme.centeredModal,
  },
  close: {
    fontSize: 24,
    // color: theme.palette.primary.main,
    position: 'absolute',
    top: 6,
    right: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  confirmTextbox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: 15,
  },
}));

/**
 * React Component that renders the EditProfile page used for updating user information or deleting an account
 * @returns {JSX} JSX For the Edit Profile Page
 */
export const EditProfile = ({
  setSection,
}) => {
  const { height, width } = useWindowDimensions();
  const user = useContext(UserContext)[0];
  const classes = useStyles({ height, width });
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);

  // Show no highlight in navbar
  useEffect(() => {
    if (setSection) {
      setSection(-1);
    }
  }, [setSection]);

  // Force user to log in if they are not
  useEffect(() => {
    // Exit unless we are sure we retrieved user data - do this to avoid flashing the modal
    if (_.isEmpty(user)) return;
    // console.log('user', user);

    if (!user.isAuthenticated) {
      setLoginPromptOpen(true);
    } else {
      // Make sure to close modal in case a retry has been implemented
      setLoginPromptOpen(false);
    }
  }, [user]);

  const handleDeleteAccount = () => {
    window.location.assign(`${Endpoints.BASEURL}/Profile/DeleteAccount`);
  };

  const openEditProfile = () => {
    const url = `${Endpoints.BASEURL}${Endpoints.EDITPROFILE}`;
    window.location.assign(url);
  };

  const Confirm = () => (
    <Modal
      open={deleteConfirm}
    >
      <Box className={classes.paper}>
        <Box>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => { setDeleteConfirm(false); }}
          />
        </Box>
        <Box style={{ padding: 15, paddingTop: 30 }}>
          <Typography variant="h4">
            Are You Sure You Want To Delete Your Account?
          </Typography>

          <Box style={{ display: 'flex', justifyContent: 'center' }}>
            <Button
              style={{ margin: 15, marginRight: 5 }}
              variant="contained"
              color="secondary"
              onClick={() => setDeleteConfirm(false)}
            >
              Cancel
            </Button>
            <Button
              style={{
                margin: 15, marginLeft: 5, backgroundColor: '#db3535', color: 'white',
              }}
              variant="contained"
              onClick={() => handleDeleteAccount()}
            >
              Delete
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.darken}>
        {/* NOTE: There's no way we need these 4 levels of Boxes. Should simplify */}
        <Container>
          <Box
            display="flex"
            justifyContent="center"
            flexDirection="column"
            alignItems="center"
          >
            <Box
              my={2}
              borderRadius="borderRadius"
              boxShadow={1}
              width="70%"
            >
              <Box
                px={1}
                display="flex"
                flexDirection="column"
                alignItems="center"
                flexGrow={1}
                style={{ backgroundColor: lightGrey }}
                color={blackText}
              >
                <Box className={classes.profileContainer}>
                  <Box style={{ paddingBottom: 15 }}>
                    <Typography variant="h3">
                      Your Profile
                    </Typography>
                  </Box>

                  {/* Profile Info */}
                  <Box style={{
                    display: 'flex', flexDirection: 'column', height: '45%', justifyContent: 'space-around',
                  }}
                  >
                    {/** User Details */}
                    <Box display="flex" style={{ paddingBottom: 15 }}>
                      {' '}
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box style={{ marginRight: 10, padding: 10 }}>
                          <Typography variant="body1">
                            Email:
                          </Typography>
                        </Box>
                        <Box style={{ marginRight: 10, padding: 10 }}>
                          <Typography variant="body1">
                            Name:
                          </Typography>
                        </Box>
                        <Box style={{ marginRight: 10, padding: 10 }}>
                          <Typography variant="body1">
                            City:
                          </Typography>
                        </Box>
                      </Box>
                      <Box style={{ display: 'flex', flexDirection: 'column' }}>
                        <Box style={{ marginLeft: 5, padding: 5 }}>
                          <Typography variant="body1" style={{ backgroundColor: '#e1e1e1', padding: 5, height: 34 }}>
                            {user.email}
                          </Typography>
                        </Box>
                        <Box style={{ marginLeft: 5, padding: 5 }}>
                          <Typography variant="body1" style={{ backgroundColor: '#e1e1e1', padding: 5, height: 34 }}>
                            {`${user.firstname ? user.firstname : ''} ${user.lastname ? user.lastname : ''}`}
                          </Typography>
                        </Box>
                        <Box style={{ marginLeft: 5, padding: 5 }}>
                          <Typography variant="body1" style={{ backgroundColor: '#e1e1e1', padding: 5, height: 34 }}>
                            {user.city}
                          </Typography>
                        </Box>
                      </Box>
                    </Box>
                    {/** Controls */}
                    <Box style={{ display: 'flex', justifyContent: 'center' }}>
                      {' '}
                      <Button
                        style={{ marginRight: 10 }}
                        variant="contained"
                        color="secondary"
                        onClick={() => openEditProfile()}
                      >
                        Edit Profile
                      </Button>
                      <Button
                        style={{ marginLeft: 10, backgroundColor: '#db3535', color: 'white' }}
                        variant="contained"
                        onClick={() => setDeleteConfirm(true)}
                      >
                        Delete Account
                      </Button>
                    </Box>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Container>
      </Box>

      {/* Modals & stuff */}
      {deleteConfirm && <Confirm />}
      <Connect
        open={loginPromptOpen}
        setOpen={setLoginPromptOpen}
        message="Sign in and Connect Precision Ag"
        shouldForceLogIn
        signInFrom="SIGNINFROMAPP"
        signInParam="?source=/EditProfile"
      />
    </Box>
  );
};

import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Divider,
  Button,
  Typography,
} from '@material-ui/core';
import * as d3 from 'd3';
import d3tip from 'd3-tip';
import * as _ from 'underscore';
import {
  blackText, grey,
} from '../../../styles/colors';
import { exists } from '../../../utils/helpers';
import { GeneralSettings } from '../Settings/GeneralSettings';
import { QuoteInputs } from '../Settings/QuoteInputs';
import { OptionsBox } from '../Settings/OptionsBox';
import { AdvancedOptions } from '../Settings/AdvancedOptions';
import { RefineEstimates } from '../Settings/RefineEstimates';
import { AcreSelection } from '../Settings/AcreSelection';
import { createTable } from '../createInsuranceTable';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  paper: theme.modalPaper,
  close: theme.close,
  boxLabel: theme.boxLabel,
  errorMessageBox: theme.errorMessageBox,
}));

function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    height: 'auto',
    width: '400px',
  };
}

export function PremiumCalculator({
  mobileView,
  handleChange,
  updateAdvancedOptions,
  advancedOptions,
  commodityYear,
  crops,
  crop,
  types,
  type,
  practices,
  practice,
  insuranceType,
  setInsuranceType,
  acres,
  projectedPrice,
  approvedYield,
  insuranceUnit,
  setInsuranceUnit,
  liability,
  liabilityPerAcre,
  subsidizedPremium,
  subsidizedPremiumAllAcres,
  premium,
  totalPremium,
  totalPremiumAllAcres,
  guarantee,
  allAcres,
  setAllAcres,
  yields,
  setYields,
  updateYields,
  exclusionYears,
  getCalculateYieldFromHistoryData,
  getInputAndSendRequest,
  locationErrorMessage,
  enqueueSnackbar,
  disableUserInputsOnLoad,
  errorMessage,
  whatIfInputs,
  updateWhatIfInputs,
}) {
  const classes = useStyles();
  const modalStyle = getModalStyle();

  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [refiningEstimates, setRefiningEstimates] = useState(false);
  const [tableInput, setTableInput] = useState('YP_Basic');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [disableUserInputs, setDisableUserInputs] = useState(false);

  const toggleOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  useEffect(() => {
    determineTableInput(insuranceUnit, insuranceType);
  }, [insuranceUnit, insuranceType]);

  // Figures out if there is an errorMessage and whether it applies to this tool
  useEffect(() => {
    // Should always have the tools it's meant for, the error message, and what to disable
    if (errorMessage.length > 2 && (errorMessage[0] === 'PremiumCalculator' || errorMessage[0] === 'All')) {
      setShowErrorMessage(true);
      setDisableUserInputs(errorMessage[2]);
    } else {
      setShowErrorMessage(false);
      setDisableUserInputs(false);
    }
  }, [errorMessage]);

  useEffect(() => {
    if (
      exists([insuranceType, insuranceUnit])
      && liability.length > 0
      && liabilityPerAcre.length > 0
      && !_.isEmpty(subsidizedPremium)
      && !_.isEmpty(subsidizedPremiumAllAcres)
    ) {
      try {
        drawPremGraph();
      } catch (err) {
        console.log(err);
      }
    }
  }, [
    insuranceType,
    insuranceUnit,
    liability,
    liabilityPerAcre,
    subsidizedPremium,
    subsidizedPremiumAllAcres,
    allAcres,
  ]);

  const determineTableInput = (unit, insurance) => {
    const insuranceAbbreviaton = insurance === 'Yield Protection' ? 'YP' : insurance === 'Revenue Protection' ? 'RP' : 'RPHPE';
    const key = `${insuranceAbbreviaton}_${unit}`;
    setTableInput(key);
  };

  const removeElements = (elms) => elms.forEach((el) => el.remove());

  /* Function to create premium calculator graph */
  const drawPremGraph = () => {
    if (document.getElementById('premGraph') !== null) {
      document.getElementById('premGraph').innerHTML = '';
      removeElements(document.querySelectorAll('.d3-tip.n'));
    }
    const type = insuranceType === 'Yield Protection' ? 'yp' : insuranceType === 'Revenue Protection' ? 'rp' : 'rphpe';
    const defaultacre_option = !allAcres;
    const data = !allAcres
      ? subsidizedPremium[`${type.toUpperCase()}_${insuranceUnit}`]
      : subsidizedPremiumAllAcres[`${type.toUpperCase()}_${insuranceUnit}`];

    const title = defaultacre_option ? 'Premium Per Acre' : 'Premium All Acres';
    const useWidth = 100;
    const barclass = 'premium';
    const container = '#premGraph';
    const defaultrevenue = _.find(liability, (value) => value.name == '50%').value;

    const containerwidth = Math.floor(500); const
      containerheight = Math.floor(containerwidth * (2 / 3));

    const margin = {
      top: containerheight * 0.15, left: containerwidth * 0.15, bottom: containerheight * 0.05, right: container.split('_')[1] == 'chart' ? 0 : containerwidth * 0.05,
    };
    const width = containerwidth - margin.left - margin.right;
    const height = containerheight - margin.top - margin.bottom;
    const titlefontsize = Math.floor(margin.top / 2);
    const leftaxispadding = Math.floor(margin.left * 0.7);
    const leftaxisfontsize = Math.floor(leftaxispadding / 2.5);
    const labelfontsize = Math.floor(margin.bottom);

    const x = d3.scaleBand()
      .domain(data.map((d) => d.name))
      .range([margin.left, width])
      .padding(0.1)
      .round(true);

    const y = d3.scaleLinear()
      .domain([0, d3.max(data, (d) => d.value)])
      .range([height, margin.top]);

    const chart = d3.select(container)
      .attr('width', margin.left + margin.right + width)
      .attr('height', (height + margin.top + margin.bottom));

    const tooltip_format = d3.format('$,.2f');

    // Set the show value on hover tip
    const tip = d3tip()
      .attr('class', 'd3-tip')
      .offset([-10, 0]);
    const acre_appendage = ` ${title.split(' ').slice(1).join(' ')}`;
    if (barclass == 'liability') {
      tip.html((d) => `<strong>Your Coverage${acre_appendage}: </strong><span>${tooltip_format(d.value)}</span><br/>`
          + `<strong>Indemnity${acre_appendage}: </strong><span>${d.indemnity == undefined ? tooltip_format(0) : tooltip_format(d.indemnity)}</span>`);
    } else {
      tip.html((d) => `<strong>${title}: </strong><span>${tooltip_format(d.value)}</span>`);
    }

    chart.call(tip);

    const xAxis = d3.axisBottom()
      .tickSize(0)
      .scale(x);

    const yAxis = d3.axisLeft()
      .ticks(5)
      .tickSize(-width + margin.left, 0, 0)
      .tickSizeOuter(0)
      .tickFormat(d3.format('$,.0f'))
      .scale(y);

    const bar = chart.selectAll('g')
      .data(data)
      .enter().append('g')
      .attr('class', 'bar')
      .attr('transform', (d) => `translate(${x(d.name)},0)`)
      .on('mouseover', tip.show)
      .on('mouseout', tip.hide);

    bar.append('rect')
      .attr('class', barclass)
      .attr('y', (d) => y(d.value))
      .attr('width', x.bandwidth())
      .attr('height', (d) => height - y(d.value))
      .style('fill', '#5590B3');

    chart.append('g')
      .attr('class', 'x axis')
      .attr('transform', `translate(0,${height})`)
      .call(xAxis)
      .selectAll('text')
      .attr('transform', `translate(${[0, 3]})`)
      .style('font-size', `${labelfontsize}px`);

    chart.insert('g', ':first-child')
      .attr('class', 'y axis grid')
      .attr('transform', `translate(${margin.left}, 0)`)
      .call(yAxis)
      .selectAll('text')
      .style('font-size', `${labelfontsize}px`);

    const title_container = chart.append('g')
      .attr('class', 'title_container')
      .attr('transform', `translate(${(width + (margin.left) + margin.right) / 2},0)`);

    title_container.append('text')
      .attr('class', 'title')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'hanging')
      .text(title)
      .style('font-size', `${titlefontsize}px`)
      .style('fill', '#5590B3');

    chart.append('text')
      .attr('class', 'title')
      .attr('text-anchor', 'middle')
      .attr('dominant-baseline', 'hanging')
      .attr('y', 0)
      .attr('transform', `translate(${(width + (margin.left) + margin.right) / 2},0)`)
      .text(title)
      .style('font-size', `${titlefontsize}px`)
      .style('fill', '#5590B3');

    const tooltip_radius = 10;
    // Labels

    chart.append('text')
      .attr('class', 'xlabel')
      .attr('text-anchor', 'middle')
      .attr('transform', `translate(${[(margin.left + width) / 2, height + margin.top]})`)
      .style('font-size', `${leftaxisfontsize}px`)
      .style('fill', '#5590B3')
      .text('Coverage Level');
  };

  const header = () => (
    <Box mt={1} mx={1}>
      <Box fontSize={18}>
        Crop Insurance Premium Quoter
      </Box>

      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        This tool provides real-time premium costs and prices.
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        This information is a premium estimate only, and is only for
        educational purposes. You must refer to an authorized crop
        insurance agent for official quote(s) and specific information
        regarding insurance coverage, actuarial information, conditions
        and exclusions.
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        Note: You must click &quot;RECALCULATE PREMIUM&quot; after changing any of the Quote Inputs for the changes to apply.
      </Typography>
      <Divider />
    </Box>
  );

  return (
    <Box color={blackText} fontWeight={500}>

      { header() }

      { locationErrorMessage === '' ? (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
          >
            {/* Selections */}
            <GeneralSettings
              handleChange={handleChange}
              commodityYear={commodityYear}
              crops={crops}
              crop={crop}
              disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
            />

            <QuoteInputs
              handleChange={handleChange}
              types={types}
              type={type}
              practices={practices}
              practice={practice}
              acres={acres}
              projectedPrice={projectedPrice}
              approvedYield={approvedYield}
              enqueueSnackbar={enqueueSnackbar}
              disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
            />

            <Box display="flex" flexWrap="wrap" width="100%">
              <Box
                mx={1}
                display="flex"
                flexDirection="column"
                color={blackText}
                fontWeight={500}
              >
                <Box
                  color={grey}
                  className={classes.boxLabel}
                >
                  Data Format
                </Box>
                <Box
                  p={1}
                  display="flex"
                  border={1}
                  borderRadius="borderRadius"
                  borderColor={grey}
                  style={{ height: 98 }}
                >
                  <AcreSelection
                    allAcres={allAcres}
                    setAllAcres={setAllAcres}
                    disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
                    whatIfInputs={whatIfInputs}
                    acres={acres}
                    updateWhatIfInputs={updateWhatIfInputs}
                  />
                </Box>
              </Box>

              <OptionsBox
                insuranceType={insuranceType}
                setInsuranceType={setInsuranceType}
                unit={insuranceUnit}
                setUnit={setInsuranceUnit}
                disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
              />

              { showAdvancedOptions && (
                <AdvancedOptions
                  open={showAdvancedOptions}
                  setOpen={setShowAdvancedOptions}
                  options={advancedOptions}
                  updateOptions={updateAdvancedOptions}
                  mobileView={mobileView}
                  getInputAndSendRequest={getInputAndSendRequest}
                />
              )}

              { refiningEstimates && (
                <RefineEstimates
                  yields={yields}
                  setYields={setYields}
                  updateYields={updateYields}
                  open={refiningEstimates}
                  setOpen={setRefiningEstimates}
                  mobileView={mobileView}
                  commodityYear={commodityYear}
                  exclusionYears={exclusionYears}
                  getCalculateYieldFromHistoryData={getCalculateYieldFromHistoryData}
                  getInputAndSendRequest={getInputAndSendRequest}
                />
              )}

              <Box
                my={2}
                display="flex"
                flexWrap="wrap"
                alignItems="center"
              >
                <Button
                  color="primary"
                  variant="contained"
                  onClick={() => getInputAndSendRequest()}
                  style={{ margin: 8, height: 40, whiteSpace: 'nowrap' }}
                  disabled={disableUserInputsOnLoad || showErrorMessage}
                  disableElevation
                >
                  Recalculate Premium
                </Button>

                <Button
                  color="primary"
                  variant="outlined"
                  onClick={() => setRefiningEstimates(!refiningEstimates)}
                  style={{ margin: 8, height: 40, whiteSpace: 'nowrap' }}
                  disabled={disableUserInputsOnLoad || showErrorMessage}
                  disableElevation
                >
                  Refine Estimates
                </Button>

                <Button
                  color="primary"
                  variant="outlined"
                  style={{ margin: 8, height: 40, whiteSpace: 'nowrap' }}
                  onClick={() => toggleOptions()}
                  disabled={disableUserInputsOnLoad || showErrorMessage}
                  disableElevation
                >
                  Advanced Options
                </Button>
              </Box>
            </Box>
          </Box>

          { !showErrorMessage ? (
            <>
              <Box
                display="flex"
                flexWrap="wrap"
              >
                <Box
                  m={2}
                  p={1}
                  display="flex"
                  flexWrap="wrap"
                  alignItems="center"
                  justifyContent="center"
                >
                  <svg style={{ margin: '8px' }} id="premGraph" />

                  { allAcres ? (
                    exists(subsidizedPremiumAllAcres[tableInput]) && (
                      createTable(
                        subsidizedPremiumAllAcres[tableInput],
                        liability,
                        tableInput.slice(0, 3) === 'YP_' ? guarantee : false,
                        allAcres,
                      )
                    )
                  ) : (
                    exists(subsidizedPremium[tableInput]) && (
                      createTable(
                        subsidizedPremium[tableInput],
                        liabilityPerAcre,
                        tableInput.slice(0, 3) === 'YP_' ? guarantee : false,
                        allAcres,
                      )
                    )
                  )}
                </Box>
              </Box>

              { (exists(subsidizedPremiumAllAcres[tableInput]) || exists(subsidizedPremium[tableInput])) && (
                <Box
                  m={1}
                  color='#e60000'
                  display="flex"
                  justifyContent="center"
                  style={{ minWidth: '450px', maxWidth: '1000px' }}
                >
                  ** This information is a premium estimate only, and is only for educational purposes. You must refer to an authorized crop insurance agent for official quote(s) and specific information regarding insurance coverage, actuarial information, conditions and exclusions.
                </Box>
              )}
            </>
          ) : (
            <Box m={1} mt={3} display="flex">
              <Box className={classes.errorMessageBox}>
                {errorMessage[1]}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box m={1} display="flex">
          <Box className={classes.errorMessageBox}>
            {locationErrorMessage}
          </Box>
        </Box>
      )}
    </Box>
  );
}

import React, { useState, useEffect } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import {
  Box,
  Typography,
  Divider,
  Modal,
  CircularProgress,
  TextField,
  IconButton,
  Checkbox,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteForeverIcon from "@material-ui/icons/DeleteForever";
import EditIcon from "@material-ui/icons/Edit";
import SyncIcon from "@material-ui/icons/Sync";
import VisibilityIcon from "@material-ui/icons/Visibility";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import { useSnackbar } from "notistack";
import VisibilityOffIcon from "@material-ui/icons/VisibilityOff";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import MapIcon from "@material-ui/icons/Map";
import { PriceToolInfo } from "../../PriceTool/PriceToolInfo";
import { UpdateApplications } from "./UpdateApplications";
import { numFormat, capitalizeFirstLetter } from "../../../../utils/helpers";
import {
  applicationConversions,
  unitConversion,
} from "../../utils/conversions";
import * as df from "../../../../utils/dataFetchers";
import { blackText, lightGrey, grey } from "../../../../styles/colors";
import { Endpoints } from "../../../../constants/Endpoints";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { useWindowDimensions } from "../../../../utils/dimensions";
import { Integrate } from "../../../Partners/Integrate";
import { Instructions } from "../GetStarted";
import { SpinningLoader } from "../../../Shared/SpinningLoader";
import { CheckBox } from "@material-ui/icons";
import { CustomToolTip } from "../../../../utils/customComponents";
import { InfoModal, AcreageInfo } from "../../../Shared/InfoModal";
import { MobileHeader } from "../Shared/MobileHeader";
import { Parser } from "../Shared/Parser";
import { hiddenInfo, outliersAndAnomalies } from "../Shared/tooltips";

const useStyles = makeStyles((theme) => ({
  head: theme.tableHeader,
  headText: theme.tableHeaderText,
  tableRow: {
    display: "flex",
    padding: "16px",
    color: theme.palette.text.black,
    fontWeight: 500,
  },
  summaryTableRow: {
    display: "flex",
    alignItems: "center",
    padding: "16px",
    color: theme.palette.text.black,
    fontWeight: 500,
  },
  icon: theme.plIcon,
  add: {
    ...theme.addOperation,
    width: 215,
  },
  hidden: theme.hiddenOperation,
  priceTool: theme.priceToolInfo,
  labels: {
    display: "flex",
    padding: "6px 16px",
    alignItems: "center",
    fontWeight: 600,
    color: theme.palette.text.black,
  },
  addNew: {
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
      textDecoration: "none",
    },
  },
  infoToolTip: theme.infoToolTip,
}));

export function Applications({
  templateMode,
  operations,
  setOperations,
  displayed,
  hidden,
  netSeededAcres,
  perAcre,
  total,
  field,
  manualEntry,
  handleIndividualOperation,
  refreshOperations,
  year,
  years,
  saving,
  openPriceTool,
  loaded,
  openTemplateMode,
  integrated,
  templatesLoading,
  yearSelection,
  mobileBreakPoint,
  openSettings,
  resetData,
  uploadPrecisionFile,
  clipByBoundary,
  syncOperation,
}) {
  /**
   * Display tables for chemical application operations. From this screen users
   * will be able to add new operations and edit existing ones.
   * @param {Array} operations All operations of type for selected year
   * @param {Function} setOperations Updates operations
   * @param {Array} displayed Operations user has not hidden
   * @param {Array} hidden Operations user has hidden
   * @param {Bool} perAcre Data is per acre or per field
   * @param {Number} seededAcres Acres of field that have been seeded.
   * @param {Number} year Selected year of operations.
   * @param {Object} field Field data
   * @param {Bool} saving While in process of saving, show loading circle.
   * @param {Function} openPriceTool Open price tool to selected operation type.
   * @param {Array} loaded Operations who's data has been loaded.
   * @param {Function} openSettings Open settings modal
   * @param {Function} resetData Refresh operation data
   */

  //console.log('operations', operations)
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [viewHidden, setViewHidden] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const [open, setOpen] = useState(false);
  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [action, setAction] = useState("Add");
  const [acres, setAcres] = useState(0);

  const [editOperation, setEditOperation] = useState({});

  const [isOperationMap, setOperationMap] = useState(false);
  const [operationMapDis, setOperationMapDis] = useState([]);
  const [operationUnit, setOperationUnit] = useState("");

  // indexes of operations to display mixes components for (as opposed to collapse)
  const [displayMixes, setDisplayMixes] = useState([]);

  const [allProducts, setAllProducts] = useState([]);
  const [profitMapResponse, setProfitMapResponse] = useState([]);
  // const { enqueueSnackbar } = useSnackbar();

  //cost map vs quantity map
  const [isQuantityMap, setIsQuantityMap] = useState(false);
  const [selectedOp, setSelectedOp] = useState("");

  useEffect(() => {
    //console.log("FIELD ACRES", field.acres);
    setAcres(field.acres);
  }, [field]);

  useEffect(() => {
    if (operations != undefined) {
      let Opidentifier;
      for (const op of operations) {
        for (const comp of op.applicationComponents) {
          Opidentifier = Math.floor(Math.random() * Math.floor(100000));
          comp.identifier = Opidentifier;
        }
      }
    }
  }, [operations]);

  useEffect(() => {
    if (selectedOp !== "") {
      displayOperationMap(selectedOp);
    }
  }, [isQuantityMap]);

  // uncomment below if want mix components to be displayed by default
  // useEffect(() => {
  //   console.log("OPERATIONS", operations)
  //   //setDisplayMixes([...Array(operations.length).keys()])
  // }, [operations])

  const handleUpdate = async (op) => {
    console.log("UPDATING:", op);
    const operationsCopy = operations.filter(
      (x) => x.operationID !== op.operationID
    );
    //convert op to fieldoperation list
    let appComponentProduct = [];
    for (const mix of op.tankMix) {
      let product = {
        ApplicationOperationID: mix.applicationOperationID,
        ProductName: mix.productName,
        Price: mix.price,
        Unit: mix.totalMaterialResultUnit,
        LogID: mix.applicationLoggedID,
        Area: mix.area,
        Quantity: mix.quantity,
      };
      appComponentProduct.push(product);
    }

    let fieldOp = {
      OperationID: appComponentProduct[0].ApplicationOperationID,
      OperationCost: op.operationCost.toString(),
      FileType: "Application",
      Price: op.price,
      Unit: appComponentProduct[0].totalMaterialResultUnit,
      Cellsize: op.cellsize,
      ProductName: op.productName,
      ApplicationComponents: appComponentProduct,
      //Date: op.date,
      LogID: appComponentProduct[0].LogID,
      Area: op.area,
      IsDeleted: false,
    };
    //console.log("SAVING", fieldOp)
    let savedOpn = await handleIndividualOperation([fieldOp]);
    //console.log("SAVED", savedOpn)

    refreshOperations(field.orgId, field.id, year, years, false, true);
    // savedOpn[0].averageMaterialResult = op.rate;
    // savedOpn[0].price = parseFloat(savedOpn[0].price).toFixed(2)
    // savedOpn[0].totalMaterialUnit = unitConversion(op.unit)

    // let components = []
    // // quantity is coming back as rate, and not all key/values exist so fix that here
    // for (const component of savedOpn[0].applicationComponents) {
    //     components.push({
    //       ...component,
    //       totalMaterialResultUnit: component.unit,
    //       quantity: component.quantity * component.area
    //     })
    // }
    // savedOpn[0].applicationComponents = components

    // //replace application with udpated version
    // let ndx = operations.findIndex(op => op.applicationOperationID === savedOpn[0].operationID)
    // let opCopy = JSON.parse(JSON.stringify(operations))

    // for(var i=0;i<savedOpn[0].applicationComponents.length;i++){
    //   savedOpn[0].applicationComponents[i].applicationLoggedID = savedOpn[0].applicationComponents[i].logID
    // }
    // let splitDate = savedOpn[0].date.split("-")
    // savedOpn[0].date = splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0]
    // savedOpn[0].unit = savedOpn[0].totalMaterialUnit
    // savedOpn[0].rate = savedOpn[0].quantity
    // opCopy.splice(ndx, 1)
    // opCopy.splice(ndx, 0, savedOpn[0])

    // setOperations(opCopy)
    setOpen(false);
  };

  const handleEdit = (op) => {
    //console.log('op', op)
    if (op.source.toUpperCase() == "USERENTERED") {
      setEditOperation(op);
      setAction("Edit");
      setOperationMap(false);
      setOperationMapDis([]);
      setOpen(true);
    } else {
      displayOperationMap(op.applicationComponents[0]);
    }
  };

  const handleNew = () => {
    setEditOperation([]);
    setAction("Add");
    setOperationMap(false);
    setOperationMapDis([]);
    setOpen(true);
  };

  const handleHide = async (op) => {
    const operationsCopy = operations.filter(
      (x) => x.operationID !== op.operationID
    );
    op.isDeleted = !op.isDeleted; // flip it

    console.log("op", op);
    let savedOpn = await handleIndividualOperation([op]);
    op.logId = savedOpn[0].logId;
    console.log("savedOpn", savedOpn);
    for (let i = 0; i < savedOpn[0].applicationComponents.length; i++) {
      for (let j = 0; j < op.applicationComponents.length; j++) {
        if (
          op.applicationComponents[j].productName ==
          savedOpn[0].applicationComponents[i].productName
        ) {
          op.applicationComponents[j].logID =
            savedOpn[0].applicationComponents[i].logID;
        }
      }
    }
    setOperations([...operationsCopy, op]);
  };

  const handleSave = (application, zones, auto = true) => {
    for (const zone of application.zones) {
      delete zone.properties.mapRef;
      delete zone.properties.featureRef;
    }
    manualEntry("Application", application, zones, auto);
  };


  const updateToValueMap = (price) => {
    let mapCopy = operationMapDis;
    mapCopy[0].max = mapCopy[0].max * price;
    mapCopy[0].min = mapCopy[0].min * price;
    mapCopy[0].mean = mapCopy[0].mean * price;
    for(let entry of mapCopy[0].legend){
      entry.max = entry.max * price;
      entry.min = entry.min * price;
      entry.mean = entry.mean * price;
    }
    return mapCopy
  }


  const displayOperationMap = async (op) => {
    let operation = operations.filter(
      (ops) => ops.operationID === op.applicationOperationID
    );
    setEditOperation(operation[0]);
    setOperationUnit(operation[0].totalMaterialUnit);
    setOpen(true);

    let ops = JSON.parse(JSON.stringify(operation));
    //ops[0].price = 1;

    if (!isQuantityMap) {
      ops[0].price = 1;
    } else {
      ops[0].price = Math.abs(
        ops[0].operationCost / ops[0].averageMaterialResult
      );
    }

    setSelectedOp(ops[0]);
    setAction("Edit");

    // console.log(ops[0])
    if(ops[0].source !== 'USERENTERED'){
      const request = {
        FieldOperationList: ops,
        Constant: 0, //this is a left hardcoded for operation maps
        Year: year,
        IsResizeMapRequest: false,
        ProfitMapLegendRange: null,
        LegendColors: null,
        ProfitByZones: false,
        Zones: null,
        boundarygeojson: null,
        IsHighResolutionMapRequest: false,
        IsOperationMapRequest: true,
      };
  
      if (clipByBoundary) {
        request.boundarygeojson = field.boundary;
      }
  
      let res;
      if (!isQuantityMap){
        res = await df.getVectorImage(field.orgId, field.id, request)
        console.log('res', res)
        if (res.message !== undefined && res.message === "Vector Image Generating"){
          // send request for normal profitmap
          res = await df.getProfitMap(field.orgId, field.id, request);
        }
      }
      else{
        res = await df.getProfitMap(field.orgId, field.id, request);
      }
      
      // let res = await df.getProfitMap(field.orgId, field.id, request);
      // console.log("res", res);
  
      if (res && res.profitMap !== undefined && res.profitMap.code === undefined) {
        setOperationMap(true);
        setOperationMapDis(res.profitMap);
        setProfitMapResponse(res.profitMap);
      } else {
        if (res?.profitMap !== undefined && res.profitMap.message !== undefined) {
          enqueueSnackbar(
            "Failed to Get ProfitLayer: " + res?.profitMap?.message
          );
        } else if (res?.message !== undefined) {
          enqueueSnackbar("Failed to Get ProfitLayer: " + res.message, {
            autoHideDuration: 8000,
          });
        } else {
          enqueueSnackbar("Failed to Get ProfitLayer");
        }
      }
    }
    
  };

  const units = [
    "gallon",
    "fluid ounce",
    "ounce",
    "liter",
    "quart",
    "pint",
    "pound",
    "kilogram",
    "ton",
    // "jug",
    // "box",
    // "container",
    // "bulk",
    // "minibulk",
    // "drum",
    // "tote",
  ];

  const showYear = (i, shown) => {
    if (!shown) {
      return "";
    }

    if (i === 0) {
      return (
        <Box mt={1} ml={2}>
          {displayYear(displayed[i].date.split("/")[2])}
        </Box>
      );
    }

    if (displayed.length > i) {
      if (
        displayed[i].date.split("/")[2] !== displayed[i - 1].date.split("/")[2]
      ) {
        return displayYear(displayed[i].date.split("/")[2]);
      }
    }
    return "";
  };

  const handleCarrierUpdate = (val, ap) => {
    if (val === null || val === undefined) {
      val = "";
    }
    if (val !== "" || val !== null) {
      const newOps = [];
      for (const op of operations) {
        for (const comp of op.applicationComponents) {
          if (JSON.stringify(comp) == JSON.stringify(ap)) {
            comp.unit = val;
            comp.totalMaterialUnit = val;
            comp.totalMaterialResultUnit = val;
            op.unit = val;
          }
        }
        newOps.push(op);
      }
      setOperations(newOps);
    }
  };

  const handleAppChange = (val, componentIndex, operationID, key, ap) => {
    const operationCopy = [...operations];
    const opToEdit = operationCopy.filter(
      (x) => x.applicationOperationID === operationID
    )[0];
    let operationIndex = operationCopy.indexOf(opToEdit);

    const componentToEdit = opToEdit.applicationComponents[componentIndex];

    if (key === "productName") {
      if (val === null || val === undefined) {
        val = "";
      }
      if (val !== "" || val !== null) {
        componentToEdit.name = val;
        componentToEdit.productName = val;
      }

      opToEdit.applicationComponents[componentIndex] = componentToEdit;
    } else if (key === "quantity") {
      if (val === null || val === undefined) {
        val = "";
      }
      if (val !== "" || val !== null) {
        componentToEdit.quantity = Number(val * field.acres);
        componentToEdit.averageMaterialResult = Number(val);
        componentToEdit.rate = Number(val);
      }
      opToEdit.applicationComponents[componentIndex] = componentToEdit;
    } else if (key === "unit") {
      opToEdit.applicationComponents.splice(componentIndex, 0, {
        ...componentToEdit,
        unit: val,
      });
    } else if (key === "price") {
      if (val === null || val === undefined) {
        val = "";
      }
      if (val !== "" || val !== null) {
        componentToEdit.price = val;
      }

      opToEdit.applicationComponents[componentIndex] = componentToEdit;

      // If in template mode and value is valid, update total application price
      if (val !== "" || (val !== null && templateMode)) {
        let newApplicationPrice = 0;
        for (const component of opToEdit.applicationComponents) {
          newApplicationPrice = Number(component.price);
        }
        opToEdit.price = newApplicationPrice;
        opToEdit.operationCost =
          newApplicationPrice * opToEdit.averageMaterialResult;
      }

      operationCopy[operationIndex] = opToEdit;
    } else if (key === "rate") {
      componentToEdit.rate = val;
      if (val !== "" || val !== null) {
        componentToEdit.quantity = Number(val * field.acres);
        componentToEdit.averageMaterialResult = Number(val);
      } else {
        componentToEdit.quantity = 0;
        componentToEdit.averageMaterialResult = 0;
      }
      opToEdit.applicationComponents[componentIndex] = componentToEdit;

      // sum components rates
      let applicationRate = opToEdit.applicationComponents
        .map((x) => Number(x.rate))
        .reduce((a, b) => a + b, 0);
      opToEdit.rate = applicationRate;
      opToEdit.averageMaterialResult = applicationRate;
      opToEdit.operationCost = opToEdit.price * applicationRate;
      operationCopy[operationIndex] = opToEdit;
    }

    setOperations(operationCopy);
  };

  const displayYear = (year) => (
    <Box
      mb={1}
      fontSize={18}
      fontWeight={500}
      color={blackText}
      style={{ width: "50px" }}
    >
      {year}
      <Divider />
    </Box>
  );

  const handleProductInput = async (ap, val) => {
    /** This is the function from the old PL tool to retrieve product names
     * based on user input. The api performs the autocomplete functionaity on keypress. **/
    //console.log("handleProductInput", val)

    // if (val === undefined || val === null) {
    //   val = '';
    // }

    if (val && val !== "" && !allProducts.includes(val)) {
      let url = Endpoints.BASEURL;
      url += Endpoints.APPMATERIALNAME;
      url += "/" + val;
      fetch(url, {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
        .then((res) => {
          if (res.status === 200) {
            return res.json();
          } else {
            throw new Error("Failed to get product names");
          }
        })
        .then((prods) => setAllProducts(prods))
        .catch((err) => console.log(err));
    }

    if (val === null || val === undefined) {
      val = "";
    }
    if (val !== "" || val !== null) {
      const newOps = [];
      for (const op of operations) {
        if (op.applicationOperationID == ap.applicationOperationID) {
          op.productName = val;
          op.name = val;
        }
        for (const comp of op.applicationComponents) {
          //console.log("comp", comp)
          if (JSON.stringify(comp) === JSON.stringify(ap)) {
            comp.productName = val;
            comp.name = val;
          }
        }
        //console.log("op", op)
        newOps.push(op);
      }
      setOperations(newOps);

      // const newOps = []
      // for (const op of operations) {
      //   //console.log("application: ", op);
      //   for (const comp of op.applicationComponents) {
      //     if (comp.applicationOperationID === ap.applicationOperationID) {
      //       console.log("comp", comp)
      //       op.productName = val;
      //       op.name = val;
      //       comp.productName = val;
      //       comp.name = val;
      //     }
      //   }
      //   console.log("op", op)
      //   newOps.push(op);
      // }
      // setOperations(newOps);
    }
  };

  const handleRemoveTemplateOp = (op) => {
    const newOps = [];
    for (const currOp of operations) {
      if (JSON.stringify(currOp) !== JSON.stringify(op)) {
        newOps.push(currOp);
      }
    }
    setOperations(newOps);
  };

  const handleIncludeApplication = async (op) => {
    let ndx = operations.findIndex((x) => x.operationID === op.operationID);
    let operationsCopy = [...operations];
    operationsCopy[ndx].isIncluded = !operationsCopy[ndx].isIncluded;
    setOperations(operationsCopy);
    await handleIndividualOperation([operationsCopy[ndx]]);
    refreshOperations(field.orgId, field.id, year, years, false, true);
  };

  const createTable = (op, i, shown) => {
    // hidden operations get created after all displayed
    const index = op.isDeleted ? i + displayed.length : i;
    return (
      <Box
        key={i}
        my={2}
        border={1}
        boxShadow={1}
        borderColor={grey}
        borderRadius="borderRadius"
        style={{ minWidth: "600px", maxWidth: "1000px", overflowX: "auto" }}
      >
        <Box className={classes.head}>
          <Box mx={1} p={1} className={classes.headText}>
            {op.source === ("Ag-Analytics" || "USERENTERED" || undefined) && (
              <SyncAltIcon />
            )}
            <Box mx={1}>{op.productName}</Box>
            <Divider orientation={"vertical"} />
            <Box mx={1}>{op.date}</Box>
          </Box>

          <Box mx={1} display="flex" alignItems="center">
            {!templateMode && (
              <Box display="flex" alignItems="center">
                <Checkbox
                  color="primary"
                  icon={
                    <CheckBoxOutlineBlankIcon
                      color="primary"
                      fontSize="small"
                    />
                  }
                  checkedIcon={
                    <CheckBoxIcon color="primary" fontSize="small" />
                  }
                  checked={op.isIncluded}
                  onChange={() => handleIncludeApplication(op)}
                  name="include-fieldpass"
                />
                <Typography
                  style={{ fontWeight: 500, fontSize: ".9rem", marginLeft: -8 }}
                >
                  Auto-calculate Field Pass Costs
                </Typography>
                <CustomToolTip
                  title="Click this to have us automatically include the machinery and labor costs for your application field passes on a per acre basis. You can change those costs in the Field Passes tab to the left."
                  placement="right-start"
                >
                  <InfoOutlinedIcon className={classes.infoToolTip} />
                </CustomToolTip>
              </Box>
            )}

            {!templateMode &&
              width >= 1024 &&
              op.source.toLowerCase() === "deere" && (
                <Box
                  className={classes.icon}
                  mx={1}
                  onClick={() => syncOperation(op)}
                >
                  <SyncIcon fontSize="small" />
                </Box>
              )}

            {!templateMode && width >= 1024 && (
              <Box
                className={classes.icon}
                mx={1}
                onClick={() => handleEdit(op)}
              >
                <EditIcon fontSize="small" />
                Edit
              </Box>
            )}

            {!templateMode && width >= 1024 && (
              <Box
                mx={1}
                className={classes.icon}
                onClick={() => displayOperationMap(op.applicationComponents[0])}
              >
                <MapIcon fontSize="small" />
                MAP
              </Box>
            )}

            {shown
              ? !templateMode && (
                  <Box
                    className={classes.icon}
                    mx={1}
                    onClick={() => handleHide(op)}
                  >
                    <VisibilityOffIcon fontSize="small" />
                    {"Hide"}
                  </Box>
                )
              : !templateMode && (
                  <Box
                    className={classes.icon}
                    mx={1}
                    onClick={() => handleHide(op)}
                  >
                    <VisibilityIcon fontSize="small" />
                    {"Show"}
                  </Box>
                )}
            {templateMode && shown && (
              <Box
                className={classes.icon}
                mx={1}
                onClick={() => handleRemoveTemplateOp(op)}
              >
                <DeleteForeverIcon fontSize="small" />
                {"Remove"}
              </Box>
            )}
          </Box>
        </Box>

        <Divider style={{ height: "3px" }} />

        <Box className={classes.labels}>
          <Box display="flex" alignItems="center" style={{ width: "30%" }}>
            <Box style={{ width: "25px" }}>
              {displayMixes.includes(index) &&
              op.applicationComponents.length > 1 ? (
                <ExpandLessIcon
                  className={classes.hidden}
                  onClick={() => {
                    let filtered = displayMixes.filter((x) => x !== index);
                    setDisplayMixes(filtered);
                  }}
                />
              ) : op.applicationComponents.length > 1 ? (
                <ExpandMoreIcon
                  className={classes.hidden}
                  onClick={() => setDisplayMixes([...displayMixes, index])}
                />
              ) : (
                ""
              )}
            </Box>
            {op.applicationComponents.length}
            {op.applicationComponents.length === 1
              ? " Component"
              : " Components"}
          </Box>
          <Box mx={1} style={{ width: "15%" }}>
            {"Acres"}
            <CustomToolTip title={outliersAndAnomalies} placement="right-start">
              <InfoOutlinedIcon className={classes.infoToolTip} />
            </CustomToolTip>
          </Box>
          <Box mx={1} style={{ width: "18%", whiteSpace: "nowrap" }}>
            {"Quantity/Acre"}
            <CustomToolTip title={outliersAndAnomalies} placement="right-start">
              <InfoOutlinedIcon className={classes.infoToolTip} />
            </CustomToolTip>
          </Box>
          <Box mx={1} style={{ width: "17%", whiteSpace: "nowrap" }}>
            {"Cost ($/Unit)"}
            <CustomToolTip title={outliersAndAnomalies} placement="right-start">
              <InfoOutlinedIcon className={classes.infoToolTip} />
            </CustomToolTip>
          </Box>
          <Box mx={1} style={{ width: "20%" }}>
            {perAcre ? "Cost ($/Acre)" : "Cost ($)"}
          </Box>
        </Box>

        <Box fontWeight={op.applicationComponents.length === 1 ? 500 : 400}>
          {!templateMode
            ? (op.applicationComponents.length === 1 ||
                displayMixes.includes(index)) &&
              op.applicationComponents
                .filter((x) => !x.isCarrier)
                .map((op, i) => createRow(op, i))
            : (op.applicationComponents.length === 1 ||
                displayMixes.includes(index)) &&
              op.applicationComponents.map((op, i) => createRow(op, i))}
        </Box>

        {op.applicationComponents.length > 1 && !templateMode && (
          <Box>
            <Divider style={{ height: "3px" }} />
            {summaryRow(op)}
            {!templateMode && (
              <Parser name={op.ProductName} type={"chemical"} operation={op} />
            )}
          </Box>
        )}

        <Divider />
      </Box>
    );
  };
  const addTemplateApplication = () => {
    //lets make our own deep copy
    let ndx = 0;
    let deepCopyOp = {};

    const allOps = [];

    if (operations.length > 0) {
      for (const op of operations) {
        if (ndx == 0) {
          console.log("clone soruce: ", op);
          deepCopyOp = JSON.parse(JSON.stringify(op));
        }
        allOps.push(op);
        ndx++;
      }

      // lets hollow out the new op:
      deepCopyOp.operationID = "";
      deepCopyOp.averageMaterialResult = "";
      deepCopyOp.price = "";
      deepCopyOp.rate = "";
      deepCopyOp.name = "";
      deepCopyOp.productName = "";
      deepCopyOp.applicationOperationID = "";
      deepCopyOp.id = "";
      for (const comp of deepCopyOp.applicationComponents) {
        comp.averageMaterialResult = "";
        comp.price = "";
        comp.rate = "";
        comp.quantity = "";
        const identifier = Math.floor(Math.random() * Math.floor(100000));
        comp.applicationOperationID = "";
        comp.name = "";
        comp.productName = "";
        comp.identifier = identifier;
      }
      console.log("copy op", deepCopyOp);
      allOps.push(deepCopyOp);
    } else {
      let today = new Date();
      const dd = String(today.getDate()).padStart(2, "0");
      const mm = String(today.getMonth() + 1).padStart(2, "0"); // January is 0!
      const yyyy = today.getFullYear();
      today = `${mm}/${dd}/${yyyy}`;

      const newComponent = {
        applicationOperationID: "",
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        cropSeason: yyyy,
        isTankMix: false,
        carrierName: "NULL",
        averageMaterialResult: "",
        averageMaterialResultUnit: "gal1ac-1",
        name: "",
        rate: "",
        rateUnit: "gal1ac-1",
        isCarrier: false,
        source: "TEMPLATE",
        price: "",
        unit: "gal",
        scenarioID: 1,
        totalMaterialUnit: "gal",
        cropYear: yyyy,
        totalMaterialResultUnit: "gal",
        area: field.acres,
        logID: 0,
        productName: "",
        quantity: "",
        identifier: Math.floor(Math.random() * Math.floor(100000)),
      };
      const newOp = {
        applicationOperationID: "",
        startDate: new Date().toISOString(),
        endDate: new Date().toISOString(),
        cropSeason: yyyy,
        isTankMix: false,
        carrierName: "NULL",
        averageMaterialResult: "",
        averageMaterialResultUnit: "gal1ac-1",
        name: "",
        rate: "",
        rateUnit: "gal1ac-1",
        isCarrier: false,
        source: "TEMPLATE",
        price: "",
        unit: "gal",
        scenarioID: 1,
        totalMaterialUnit: "gal",
        cropYear: yyyy,
        totalMaterialResultUnit: "gal",
        applicationComponents: [newComponent],
        area: field.acres,
        attribute: "AppliedRate",
        cellsize: "0.0001",
        date: "06/01/2021",
        fileType: "APPLICATION",
        isIrrigated: null,
        isOrganic: null,
        logID: 0,
        operationCost: 0,
        operationID: "",
        productName: "",
        variety: null,
        tankMix: false,
        year: yyyy,
        id: "",
      };

      allOps.push(newOp);
    }

    setOperations(allOps);
  };

  const createRow = (op, i) => (
    <Box key={i}>
      <Divider />
      <Box
        className={classes.tableRow}
        style={{ backgroundColor: i % 2 == 0 ? lightGrey : "#ffffff" }}
      >
        <Box style={{ width: "30%" }}>
          {/*op.productName*/}
          {/*from price tool && <Box mx={1}><MonetizationOnIcon /></Box>*/}
          {templateMode ? (
            <Autocomplete
              className={classes.mixInput}
              freeSolo
              value={op.productName}
              onChange={(e, newValue) =>
                handleAppChange(
                  newValue,
                  i,
                  op.applicationOperationID,
                  "productName",
                  op
                )
              }
              inputValue={op.productName}
              onInputChange={(event, newInputValue) =>
                handleProductInput(op, newInputValue)
              }
              options={allProducts}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          ) : (
            op.productName
          )}
        </Box>
        <Box mx={1} style={{ width: "15%" }}>
          {numFormat(op.area)}
        </Box>
        <Box mx={1} style={{ width: "18%" }}>
          {templateMode ? (
            <div>
              <TextField
                variant="outlined"
                type="text"
                style={{
                  height: "40px",
                  width: "35%",
                  minWidth: "80px",
                }}
                inputProps={{
                  style: {
                    padding: 10,
                    fontWeight: 500,
                    fontSize: 14,
                  },
                }}
                value={parseFloat(op.quantity / op.area)}
                onChange={(e) =>
                  handleAppChange(
                    e.target.value,
                    i,
                    op.applicationOperationID,
                    "quantity",
                    op
                  )
                }
              />{" "}
              <Box style={{ width: "150px" }}>
                <Autocomplete
                  className={classes.input}
                  value={op.unit}
                  onChange={(event, newValue) => {
                    handleCarrierUpdate(newValue, op);
                  }}
                  inputValue={op.unit}
                  onInputChange={(event, newInputValue) => {
                    handleCarrierUpdate(newInputValue, op);
                  }}
                  options={units}
                  renderInput={(params) => (
                    <TextField {...params} variant="outlined" />
                  )}
                />
              </Box>
            </div>
          ) : (
            <Box style={{ whiteSpace: "nowrap" }}>
              {numFormat(op.quantity / op.area, 0, 3)}{" "}
              {capitalizeFirstLetter(op.unit)}
              {"/Acre"}
            </Box>
          )}
        </Box>
        <Box
          mx={1}
          display="flex"
          style={{ width: "17%", minWidth: templateMode ? 160 : 20 }}
        >
          {templateMode ? (
            <React.Fragment>
              <TextField
                variant="outlined"
                type="number"
                style={{
                  height: "40px",
                  width: "70%",
                  minWidth: "80px",
                }}
                inputProps={{
                  style: {
                    padding: 10,
                    fontWeight: 500,
                    fontSize: 14,
                  },
                }}
                InputProps={{
                  startAdornment: "$",
                  style: {
                    fontWeight: 500,
                    fontSize: 14,
                  },
                }}
                value={op.price}
                onChange={(e) =>
                  handleAppChange(
                    e.target.value,
                    i,
                    op.applicationOperationID,
                    "price",
                    op
                  )
                }
              />
              <Box style={{ paddingTop: 4 }}>
                {`/${capitalizeFirstLetter(op.unit)}`}
              </Box>
            </React.Fragment>
          ) : (
            <Box style={{ whiteSpace: "nowrap" }}>
              {"$"}
              {numFormat(op.price, 2, 3)}
              {`/${capitalizeFirstLetter(op.unit)}`}
            </Box>
          )}
        </Box>
        <Box mx={1} style={{ width: "20%", whiteSpace: "nowrap" }}>
          {"$"}
          {perAcre
            ? `${numFormat(op.price * (op.quantity / op.area))}/Acre`
            : numFormat(op.price * op.quantity)}
        </Box>
      </Box>
      {!templateMode && op.productName.toLowerCase() !== "water" && (
        <Parser name={op.productName} type={"chemical"} />
      )}
    </Box>
  );

  const summaryRow = (op) => {
    return (
      <Box className={classes.tableRow} style={{ fontWeight: 500 }}>
        <Box style={{ width: "30%" }}>{op.productName}</Box>
        <Box mx={1} style={{ width: "15%" }}>
          {numFormat(op.area)}
        </Box>
        <Box mx={1} style={{ width: "20%", whiteSpace: "nowrap" }}>
          <CustomToolTip
            title="Tank's components quantity per acre including carrier"
            placement="top"
          >
            <Box style={{ whiteSpace: "nowrap" }}>
              {numFormat(op.averageMaterialResult, 0, 2)}
              {` ${capitalizeFirstLetter(op.totalMaterialUnit)}/Acre`}
            </Box>
          </CustomToolTip>
        </Box>
        <Box mx={1} style={{ width: "15%", whiteSpace: "nowrap" }}>
          <CustomToolTip
            title="Average cost per unit of tank's components"
            placement="top"
          >
            <Box style={{ whiteSpace: "nowrap" }}>
              {"$"}
              {numFormat(+op.operationCost / +op.averageMaterialResult, 2, 3)}
              {`/${capitalizeFirstLetter(op.totalMaterialUnit)}`}
            </Box>
          </CustomToolTip>
        </Box>
        <Box mx={1} style={{ width: "20%", whiteSpace: "nowrap" }}>
          {"$"}
          {perAcre
            ? `${numFormat(op.operationCost, 2, 3)}/Acre`
            : numFormat(op.operationCost * op.area)}
        </Box>
      </Box>
    );
  };

  const templateTable = (op, i) => {
    return (
      <Box
        key={i}
        my={2}
        border={1}
        boxShadow={1}
        borderColor={grey}
        borderRadius="borderRadius"
        style={{ minWidth: "700px", maxWidth: "1000px", overflowX: "auto" }}
      >
        <Box className={classes.head}>
          <Box mx={1} p={1} className={classes.headText}>
            <Box mx={1}>{op.date}</Box>
            <Divider orientation={"vertical"} />
            <Box mx={1}>Acres: {numFormat(op.area)}</Box>
          </Box>

          <Box
            className={classes.icon}
            mx={1}
            onClick={() => handleRemoveTemplateOp(op)}
          >
            <DeleteForeverIcon fontSize="small" />
            Remove
          </Box>
        </Box>

        <Divider style={{ height: "3px" }} />

        <Box className={classes.labels}>
          <Box style={{ width: "25%" }}>Component</Box>
          <Box style={{ width: "15%" }}>Quantity/Acre</Box>
          <Box style={{ width: "25%" }}>Unit</Box>
          <Box style={{ width: "20%" }}>Cost/Unit</Box>
          <Box style={{ width: "15%" }}>Cost ($/Acre)</Box>
        </Box>

        {op.applicationComponents.map((x, i) => templateRow(x, i))}
      </Box>
    );
  };

  const templateRow = (op, i) => {
    return (
      <Box key={i}>
        <Divider />
        <Box className={classes.summaryTableRow} fontWeight={500}>
          <Box px={1} style={{ width: "25%" }}>
            <Autocomplete
              freeSolo
              value={op.productName}
              inputValue={op.productName}
              onChange={(event, newValue) =>
                handleAppChange(
                  newValue,
                  i,
                  op.applicationOperationID,
                  "productName",
                  op
                )
              }
              onInputChange={(event, newInputValue) =>
                handleProductInput(op, newInputValue)
              }
              options={allProducts}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Box>

          <Box px={1} style={{ width: "15%" }}>
            <TextField
              variant="outlined"
              type="text"
              value={op.rate}
              onChange={(e) =>
                handleAppChange(
                  e.target.value,
                  i,
                  op.applicationOperationID,
                  "rate",
                  op
                )
              }
            />
          </Box>

          <Box px={1} style={{ width: "25%" }}>
            <Autocomplete
              value={op.unit}
              onChange={(event, newValue) => {
                handleCarrierUpdate(newValue, op);
              }}
              inputValue={op.unit}
              onInputChange={(event, newInputValue) => {
                handleCarrierUpdate(newInputValue, op);
              }}
              options={units}
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </Box>

          <Box px={1} style={{ width: "20%" }}>
            <TextField
              variant="outlined"
              type="number"
              InputProps={{
                startAdornment: "$",
              }}
              value={op.price}
              onChange={(e) =>
                handleAppChange(
                  e.target.value,
                  i,
                  op.applicationOperationID,
                  "price",
                  op
                )
              }
            />
          </Box>

          <Box px={1} style={{ width: "15%" }}>
            {"$"}
            {perAcre
              ? `${numFormat(op.price * (op.quantity / op.area))}/Acre`
              : numFormat(op.price)}
          </Box>
        </Box>
      </Box>
    );
  };

  return loaded.includes("Applications") ? (
    <Box p={1} px={2} style={{ width: "100%" }}>
      <Box mt={1} ml={1} fontWeight={500}>
        <Box mt={2} color={blackText} fontSize={18}>
          Chemical and Fertilizer Costs Summary
        </Box>

        <Box>
          {"View your applications below or "}
          <span className={classes.addNew} onClick={() => handleNew()}>
            add a new operation
          </span>
        </Box>
      </Box>

      <Divider />

      {yearSelection !== null && width < mobileBreakPoint && !templateMode && (
        <MobileHeader
          yearSelection={yearSelection}
          seededAcres={netSeededAcres}
          openSettings={openSettings}
          resetData={resetData}
        />
      )}

      {operations && operations.length === 0 && !templateMode && (
        <Instructions
          type={"Application"}
          createNew={handleNew}
          canAdd={width > 1024}
          openTemplate={openTemplateMode}
          integrated={integrated}
        />
      )}

      {displayed.map((op, i) => (
        <React.Fragment key={i}>
          {showYear(i, true)}
          {!templateMode ? createTable(op, i, true) : templateTable(op, i)}
        </React.Fragment>
      ))}

      {templateMode && (
        <Box mt={1} fontWeight={500} fontSize={16} color={blackText}>
          To create tank mixes, edit your zones after generating template
          operations
        </Box>
      )}

      {saving && <SpinningLoader />}

      {templateMode && templatesLoading && (
        <Box p={1} fontWeight={500} fontSize={20}>
          <Box p={1}>Loading Templates</Box>
          <Box
            p={1}
            display="flex"
            justifyContent="center"
            style={{ width: "100%" }}
          >
            <CircularProgress size={60} />
          </Box>
        </Box>
      )}

      <Box display="flex" flexWrap="wrap">
        {!templateMode && width > 1024 && (
          <Box
            className={classes.add}
            border={1}
            borderRadius={"borderRadius"}
            onClick={() => handleNew()}
            style={{ marginRight: "18px" }}
          >
            <AddIcon /> Add Manual Application
          </Box>
        )}

        {operations && operations.length === 0 && !templateMode && (
          <Box
            className={classes.add}
            border={1}
            borderRadius="borderRadius"
            onClick={() => openTemplateMode()}
            style={{ width: "280px", marginRight: "18px" }}
          >
            <AddIcon />
            {" Generate Operation From Template"}
          </Box>
        )}

        {templateMode && (
          <Box
            className={classes.add}
            border={1}
            borderRadius={"borderRadius"}
            onClick={addTemplateApplication}
          >
            <AddIcon /> Add Template Application
          </Box>
        )}

        {!templateMode && (
          <Box
            className={classes.add}
            border={1}
            borderRadius="borderRadius"
            onClick={() => uploadPrecisionFile()}
            style={{ width: "220px" }}
          >
            <AddIcon />
            Upload Precision Ag Data
          </Box>
        )}
      </Box>

      {!templateMode && hidden.length > 0 && (
        <Box
          className={classes.hidden}
          onClick={() => setViewHidden(!viewHidden)}
        >
          {viewHidden ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          Hidden Entries ({hidden.length})
          <CustomToolTip placement="right" title={hiddenInfo}>
            <InfoOutlinedIcon className={classes.infoToolTip} />
          </CustomToolTip>
        </Box>
      )}

      {viewHidden && hidden.map((op, i) => createTable(op, i, false))}

      {!templateMode && operations.length > 0 && (
        <Box>
          <Box display="flex">
            <PriceToolInfo
              openPriceTool={openPriceTool}
              opType={"Application"}
            />
          </Box>
          <Box display="flex">
            <AcreageInfo openModal={setInfoModalOpen} />
          </Box>
        </Box>
      )}

      {!templateMode && operations.length === 0 && !integrated && <Integrate />}

      <Modal
        open={open}
        aria-labelledby="add-application"
        aria-describedby="add-and-update-applications"
      >
        <div>
          <UpdateApplications
            action={action}
            toEdit={editOperation}
            field={field}
            seededAcres={acres}
            perAcre={perAcre}
            setOpen={setOpen}
            save={handleSave}
            year={year}
            saveUpdate={handleUpdate}
            isOperationMap={isOperationMap}
            operationMapDis={operationMapDis}
            operationUnit={operationUnit}
            profitMapResponse={profitMapResponse}
            isQuantityMap={isQuantityMap}
            setIsQuantityMap={setIsQuantityMap}
            selectedOp={selectedOp}
          />
        </div>
      </Modal>

      <InfoModal
        open={infoModalOpen}
        setOpen={setInfoModalOpen}
        info={"acreage"}
      />
    </Box>
  ) : (
    <SpinningLoader />
  );
}

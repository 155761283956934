import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import * as L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { drawFieldLayer, getMinHeight } from '../../Maps/MapFunctions/helpers';
import { exists } from '../../../utils/helpers';
import { Tiles } from '../../../constants/Tiles'


export function AerialImageryMap({
  boundary, naipData, selectedYear, showNaip, location,
}) {
  const [map, setMap] = useState(null);

  const imageOverlay = useRef(null);
  const fieldLayer = L.featureGroup(null);

  const [currentImage, setCurrentImage] = useState('')
  const [currentBounds, setCurrentBounds] = useState('')

  // NAIP
  const naips = useRef({});

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (map && location === '') {
      map.setMaxZoom(17);
    }
  }, [location, map]);

  useEffect(() => {
    if (map !== null && exists(naipData) && exists(boundary)) {
      addNaip(naipData)
    }
  }, [naipData, map, boundary])

  useEffect(() => {
    if (!showNaip && map !== null) {
      map.removeLayer(imageOverlay.current)
    } else if (map !== null && exists(naipData) && exists(boundary)){
      addNaip(naipData)
    }
  }, [showNaip])

  const addNaip = (info) => {
    try{
      const data = info[selectedYear]
      if (data !== undefined) {
        const image = data.file
        const extent  = data.Extent.split(", ").map((x) => parseFloat(x))
  
        const imgbounds = [
          L.CRS.EPSG3857.unproject(L.point([extent[0], extent[1]])),
          L.CRS.EPSG3857.unproject(L.point([extent[2], extent[3]]))
        ]
        const bounds = L.latLngBounds(imgbounds[0], imgbounds[1]);
        if(imageOverlay.current === null){
              imageOverlay.current = L.imageOverlay(image, bounds);
            }
            else{
              imageOverlay.current.setUrl(image)
              imageOverlay.current.setBounds(bounds)
            }
        imageOverlay.current.addTo(map);
        imageOverlay.current.bringToFront()
        setCurrentImage(image)
        setCurrentBounds(bounds)
      }
    }
    catch(err){
      console.log('something went wrong while displaying aerial imagery map', err)
    }  

  }

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      drawFieldLayer(geoJson, fieldLayer, map);
    }
  }, [boundary, map]);


  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('aerial-imagery-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current']
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }


  return (
    <Box
      id="aerial-imagery-map"
      display='flex'
      flexGrow={1}
      borderRadius={4}
      minHeight={getMinHeight()}
    />
  );
}

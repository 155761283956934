import React, {useEffect, useState} from 'react'
import * as _ from "lodash";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import moment from 'moment';

export function GddChart({data, endYear, startYear}){

  const [currentData, setCurrentData] = useState([]);
  const [averageData, setAverageData] = useState([]);
  const [minData, setMinData] = useState([]);
  const [maxData, setMaxData] = useState([]);

  useEffect(() => {
    if (data !== null && data !== undefined && data.length > 0) {
      const curData = data[0].CumGDD.map(val => [getDate(moment(val['Date'], "MM-DD").valueOf()), Math.round(val['Accumulated GDD'] * 1000) / 1000.0])
      const sorted = curData.sort((a,b) => a[1] - b[1])
      const aveData = data[1].AveGDD.map(val => [getDate(moment(val['Date'], "MM-DD").valueOf()), Math.round(val['Accumulated GDD'] * 1000) / 1000.0])
      const min = data[2].MinGDD.map(val => [getDate(moment(val['Date'], "MM-DD").valueOf()), Math.round(val['Accumulated GDD'] * 1000) / 1000.0])
      const max = data[3].MaxGDD.map(val => [getDate(moment(val['Date'], "MM-DD").valueOf()), Math.round(val['Accumulated GDD'] * 1000) / 1000.0])

      setCurrentData(sorted)
      setAverageData(aveData)
      setMinData(min)
      setMaxData(max)
    }
  }, [data])

  function getDate(date) {
    if (isNaN(date)) {
        let dateVal = moment('02-28', "MM-DD").valueOf();
        return dateVal
    }
    else {
        return date
    }
  }

  const options = {
    chart: {
        type: 'line',
        backgroundColor: null,
        zoomType: 'x'
    },
    title: {
        text: 'GDD Data'
    },
    xAxis: {
      type: 'datetime',
      dateTimeLabelFormats: {
        // don't display the dummy year
          month: '%e %b',
          year: '%b'
      },
      title: {
          text: 'Date'
      }
    },
    yAxis: {
        title: {
            text: 'Accumulate GDD (°F)'
        }
    },
    series: [
      {
        name: endYear + ' GDD',
        data: currentData,
        color: '#B1DD27',
        zIndex: 4,
        lineWidth: 4,
        states: {
            inactive: {
                opacity: 1
            }
        }
      },
      {
        name: (endYear - startYear) + ' Year Ave',
        data: averageData,
        color: '#808080',
        zIndex: 3,
        lineWidth: 1,
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      {
        name: 'Historic Min',
        data: minData,
        color: '#00B2EE',
        zIndex: 2,
        lineWidth: 1,
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      {
        name: 'Historic Max',
        data: maxData,
        color: '#B22222',
        zIndex: 1,
        lineWidth: 1,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    ],
    navigation: {
      menuItemStyle: {
        borderLeft: '10px solid #FFFFFF',
        fontSize: '15px',
        padding: "2px 10px"
      }
    }
  }

  return (
    <div id='gdd-chart'>
      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
    </div>

  )
}

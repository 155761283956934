import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Button, makeStyles, Switch, CircularProgress, 
  InputLabel, FormControl, Select, Input, Chip, Checkbox, ListItemText, MenuItem } from "@material-ui/core";
import { CHART_TYPES, MONTHS, COLORS,  } from "../../../../constants";
import ScatterPlotContainer from "../../../Helpers/ScatterPlotContainer";
import CustomSelect from "../../../Helpers/CustomSelect";
import SeasonSelect from "../../../Helpers/SeasonSelect";
import PopularComparisonChip from "../../../Helpers/PopularComparisonChip";
import {
  calcuatePrecipitation,
  calcuateGDD,
  calcuatePrecipitationForYear,
  calcuateGDDForYear
} from "../../../Helpers/FarmOperation/helper";
import { MultiYearGraphs } from "./MultiYearGraphs";
import { green } from '../../../../styles/colors';
import { MenuProps } from "../../../../styles/select";
import CustomSelectForMultiples from "../../../Helpers/CustomSelectForMultiples";

const useStyles = makeStyles((theme) => ({
  text: {
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "22px",
    marginTop: 40,
    marginBottom: 30,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  innerActionsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
    color: COLORS.darkGray,
  },
  wrapper: ({ isFullWidth, isFlush }) => ({
    margin: isFlush ? theme.spacing(1, 0) : theme.spacing(1),
    minWidth: 120,
    borderRadius: 5,
    "& .MuiInputBase-root": {
      height: 40,
    },
  }),
  formControl: ({ isFullWidth }) => ({
    ...(isFullWidth ? { width: "100%" } : {}),
    "& .MuiSelect-select:focus": {
      backgroundColor: `rgba(0,0,0,0)`,
    },
  }),
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  button: {
    "&:focus": {
      outline: "none",
    }
  },
}));

/**
 * Renders the selects and the scatterplot container.
 * @param {array} fields array of objects with mock field data
 */
const Analytics = ({
  fields,
  cropNames,
  selectedCropData,
  setSelectedCropData,
  precipitationData,
  activeYear,
  nutrientInfo,
  gddData,
  multiYearHarvestData,
  multiYearNutrientData,
  multiYears,
  multiYearCrops,
  allFieldsInfo,
  profitSummaries,
  multiYearVarietyData,
  multiYearVarietyProfits,
  getPrecipitationForFields,
  fieldBoundaries,
  getGDDForFields,
  loading
}) => {
  const classes = useStyles();

  const [seasons, setSeasons] = useState(MONTHS);
  const [seasonYears, setSeasonYears] = useState([])
  // console.log("multiYearVarietyProfits", multiYearVarietyProfits)

  // These are currently set to default values in the constant.
  // Nothing is currently being done with activeSeasons as there is no real data to filter against.
  const activeSeasons = seasons.filter((season) => season.active);

  const [cropOptions, setCropOptions] = useState(multiYearCrops);
  const selectedCrop = cropOptions.find((option) => option.active);

  const [crops, setCrops] = useState([]);
  const [selectedCrops, setSelectedCrops] = useState([]);

  // These are examples. These variables should come from your API.
  const [firstVariableOptions, setFirstVariableOptions] = useState([
    { id: "Yield", label: "Yield (bu./Acre)", active: true },
    { id: "Profit", label: "Profit ($/Acre)", active: true },
    { id: "Precipitation", label: "Precipitation (inches)", active: true },
    { id: "GDD", label: "GDD", active: true },
    { id: "Nutrients", label: "Nutrients (lbs/Acre)", active: true },
  ]);
  const activeFirstVariableOption = firstVariableOptions.find(
    (option) => option.active
  );
  const [yUnit, setYUnit] = useState("");

  const [secondVariableOptions, setSecondVariableOptions] = useState([
    { id: "Precipitation", label: "Precipitation (inches)", active: true },
    { id: "GDD", label: "GDD", active: true },
    { id: "Nutrients", label: "Nutrients (lbs/Acre)", active: true },
    { id: "Yield", label: "Yield (bu./Acre)", active: true },
    { id: "Profit", label: "Profit ($/Acre)", active: true },
    { id: "None selected", active: false },
  ]);
  const activeSecondVariableOption = secondVariableOptions.find(
    (option) => option.active
  );
  const [xUnit, setXUnit] = useState("");

  const [nutrientOptions, setNutrientOptions] = useState([
    { id: "Nitrogen", label: "Nitrogen", active: true },
    { id: "Sulfur", label: "Sulfur", active: false },
    { id: "Calcium", label: "Calcium", active: false },
    { id: "Phosphorus", label: "Phosphorus", active: false },
    { id: "Copper", label: "Copper", active: false },
    { id: "Cobalt", label: "Cobalt", active: false },
    { id: "Potassium", label: "Potassium", active: false },
    { id: "Molybdenum", label: "Molybdenum", active: false },
    { id: "Manganese", label: "Manganese", active: false },
    { id: "Boron", label: "Boron", active: false },
    { id: "Zinc", label: "Zinc", active: false },
    { id: "Magnesium", label: "Magnesium", active: false },
    { id: "Iron", label: "Iron", active: false },
    { id: "Chlorine", label: "Chlorine", active: false },
  ]);

  const nutrientVariableOption = nutrientOptions.find(
    (option) => option.active
  );

  const [isMultiYear, setIsMultiYear] = useState(false)

  useEffect(() => {
    if (multiYears !== null && multiYears.length > 0) {
      let years = []

      for (let i of multiYears) {
        years.push({
          id: i,
          active: i.toString() == activeYear.toString()
        })
      }
      setSeasonYears(years)
    }

  }, [multiYears])

  useEffect(() => {
    if(multiYearCrops !== null && multiYearCrops.length > 0){
      console.log("crops", multiYearCrops.map(x => x.id))
      console.log("selectedCrop", [multiYearCrops.map(x => x.id)[0]])
      setCrops(multiYearCrops.map(x => x.id))
      setSelectedCrops([multiYearCrops.map(x => x.id)[0]])
    }
  }, [multiYearCrops])

  useEffect(() => {
    let fieldsClone = [...fields];
    let fieldIds = fields.map(x => x.id)
    let graphData1 = [];
    let activeYears = seasonYears.filter(x => x.active === true).map(x => x.id)

    for (let f of fieldIds) {
      for(let crp of selectedCrops){
        for (let year of activeYears) {
          //Yield vs Precipitation (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null) {
            if (activeFirstVariableOption.id == "Yield" && activeSecondVariableOption.id == "Precipitation") {
              setYUnit("bu/Acre");
              setXUnit("inches");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let precipData = precipitationData.get(f)
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let precip = precipData.filter(x => x.Year == info.CropSeason)
                  // console.log("precip", precip)
                  //let precipitation = calcuatePrecipitationForYear(precip, seasons);
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      y: v.VarietyYield,
                      x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    y: info.AverageYield,
                    x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
          //PRecipitation vs Yield (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null) {
            if (activeFirstVariableOption.id == "Precipitation" && activeSecondVariableOption.id == "Yield") {
              setYUnit("inches");
              setXUnit("bu/Acre");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let precipData = precipitationData.get(f)
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let precip = precipData.filter(x => x.Year == info.CropSeason)
                  // console.log("precip", precip)
                  //let precipitation = calcuatePrecipitationForYear(precip, seasons);
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      x: v.VarietyYield,
                      y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: info.AverageYield,
                    y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
          //Yield vs GDD (Note this Y-Axis vs X-Axis)
          if (gddData !== null) {
            if (activeFirstVariableOption.id == "Yield" && activeSecondVariableOption.id == "GDD") {
              setYUnit("bu/Acre");
              setXUnit("");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
  
              let data = gddData.get(f)
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let gdd = data.filter(x => x.Year == info.CropSeason)
                  // console.log("precip", precip)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      y: v.VarietyYield,
                      x: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    y: info.AverageYield,
                    x: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    varieties: varieties
                  })
                }              
              }
            }
          }
  
          //GDD vs Yield (Note this Y-Axis vs X-Axis)
          if (gddData !== null) {
            if (activeFirstVariableOption.id == "GDD" && activeSecondVariableOption.id == "Yield") {
              setXUnit("bu/Acre");
              setYUnit("");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
  
              let data = gddData.get(f)
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let gdd = data.filter(x => x.Year == info.CropSeason)
                  // console.log("precip", precip)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      x: v.VarietyYield,
                      y: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: info.AverageYield,
                    y: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    varieties: varieties
                  })
                }              
              }
            }
          }
  
          //Yield vs Nutrients (Note this Y-Axis vs X-Axis)
          if (activeFirstVariableOption.id == "Yield" && activeSecondVariableOption.id == "Nutrients") {
            setYUnit("bu/Acre");
            setXUnit("lbs/Acre");
            let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
            let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
            const data = nutrientInfo.get(nutrientVariableOption.id);
            for (let info of cropData) {
              if(year == info.CropSeason){
                let nutrient = data.filter(x => x.Year == info.CropSeason
                  && x.FieldID == f)
                let varieties = [];
                let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                for (let v of varietyInfo) {
                  varieties.push({
                    cropName: crp,
                    name: v.Variety,
                    year: v.Year,
                    y: v.VarietyYield,
                    x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                  })
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.CropSeason,
                  name: fields.filter(x => x.id === f)[0].name,
                  y: info.AverageYield,
                  x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                  varieties: varieties
                })
              }            
            }
          }
  
          //Nutrients vs Yield (Note this Y-Axis vs X-Axis)
          if (activeFirstVariableOption.id == "Nutrients" && activeSecondVariableOption.id == "Yield") {
            setYUnit("lbs/Acre");
            setXUnit("bu/Acre");
            let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
            let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
            const data = nutrientInfo.get(nutrientVariableOption.id);
            for (let info of cropData) {
              if(year == info.CropSeason){
                let nutrient = data.filter(x => x.Year == info.CropSeason
                  && x.FieldID == f)
                let varieties = [];
                let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                for (let v of varietyInfo) {
                  varieties.push({
                    cropName: crp,
                    name: v.Variety,
                    year: v.Year,
                    x: v.VarietyYield,
                    y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                  })
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.CropSeason,
                  name: fields.filter(x => x.id === f)[0].name,
                  x: info.AverageYield,
                  y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                  varieties: varieties
                })
              }            
            }
          }
  
          //Yield vs None-Selected (Note this Y-Axis vs X-Axis)
          if (activeFirstVariableOption.id == "Yield" && (activeSecondVariableOption.id == "None selected" || activeSecondVariableOption.id == "Yield")) {
            setYUnit("bu/Acre");
            setXUnit("bu/Acre");
            let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
            //console.log("varietyData", varietyData)
            let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
  
            for (let info of cropData) {
              if(year == info.CropSeason){
                let varieties = [];
                let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
    
                for (let v of varietyInfo) {
                  varieties.push({
                    cropName: crp,
                    name: v.Variety,
                    year: v.Year,
                    y: v.VarietyYield,
                    x: v.VarietyYield,
                  })
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.CropSeason,
                  name: fields.filter(x => x.id === f)[0].name,
                  y: info.AverageYield,
                  x: info.AverageYield,
                  varieties: varieties,
                })
              }            
            }
          }
  
          //Profit vs Precipitation (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null) {
            if (activeFirstVariableOption.id == "Profit" && activeSecondVariableOption.id == "Precipitation") {
              setYUnit("$/Acre");
              setXUnit("inches");
              let data = profitSummaries.data.filter(x => x.fieldID === f)
              let precipData = precipitationData.get(f)
  
              let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
              // console.log("varietyData", varietyData)
  
              for (let info of data) {
                if(year == info.year){
                  let precip = precipData.filter(x => x.Year == info.year)
                  // console.log("precip", precip)
                  let varietyInfo = varietyData.filter(x => x.year == info.year);
                  // console.log("varietyInfo", varietyInfo)
                  let varieties = [];
                  let cropData = multiYearVarietyData.filter(x => x.FieldID === f &&
                    x.CropName === crp && x.Year == info.year)
    
                  for (let v of varietyInfo) {
                    for (let x of cropData) {
                      if (x.Variety == v.varietyName) {
                        varieties.push({
                          cropName: crp,
                          name: v.varietyName,
                          year: v.year,
                          y: v.profitVariety.toFixed(2),
                          x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                        })
                      }
                    }
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.year,
                    name: fields.filter(x => x.id === f)[0].name,
                    y: info.profit.toFixed(2),
                    x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    varieties: varieties
                  })
                }              
              }
            }
          }
  
          //Precipation vs Proit (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null) {
            if (activeFirstVariableOption.id == "Precipitation" && activeSecondVariableOption.id == "Profit") {
              setYUnit("inches");
              setXUnit("$/Acre");
              let data = profitSummaries.data.filter(x => x.fieldID === f)
              let precipData = precipitationData.get(f)
  
              let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
              // console.log("varietyData", varietyData)
  
              for (let info of data) {
                if(year == info.year){
                  let precip = precipData.filter(x => x.Year == info.year)
                  // console.log("precip", precip)
                  let varietyInfo = varietyData.filter(x => x.year == info.year);
                  // console.log("varietyInfo", varietyInfo)
                  let varieties = [];
                  let cropData = multiYearVarietyData.filter(x => x.FieldID === f &&
                    x.CropName === crp && x.Year == info.year)
    
                  for (let v of varietyInfo) {
                    for (let x of cropData) {
                      if (x.Variety == v.varietyName) {
                        varieties.push({
                          cropName: crp,
                          name: v.varietyName,
                          year: v.year,
                          x: v.profitVariety.toFixed(2),
                          y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                        })
                      }
                    }
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.year,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: info.profit.toFixed(2),
                    y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    varieties: varieties
                  })
                }              
              }
            }
          }
  
          //Profit vs GDD (Note this Y-Axis vs X-Axis)
          if (gddData !== null) {
            if (activeFirstVariableOption.id == "Profit" && activeSecondVariableOption.id == "GDD") {
              setYUnit("$/Acre");
              setXUnit("");
              let data = profitSummaries.data.filter(x => x.fieldID === f)
              let fipsCode = fields.filter(x => x.id === f)[0].fips
              let datag = gddData.get(f)
  
              let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
              for (let info of data) {
                if(year == info.year){
                  let gdd = datag.filter(x => x.Year == info.year)
                  // console.log("precip", precip)
    
                  let varietyInfo = varietyData.filter(x => x.year == info.year);
                  // console.log("varietyInfo", varietyInfo)
                  let varieties = [];
                  let cropData = multiYearVarietyData.filter(x => x.FieldID === f &&
                    x.CropName === crp && x.Year == info.year)
    
                  for (let v of varietyInfo) {
                    for (let x of cropData) {
                      if (x.Variety == v.varietyName) {
                        varieties.push({
                          cropName: crp,
                          name: v.varietyName,
                          year: v.year,
                          y: v.profitVariety.toFixed(2),
                          x: gdd.length > 0 ? gdd[0].cumGDD : 0,
                        })
                      }
                    }
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.year,
                    name: fields.filter(x => x.id === f)[0].name,
                    y: info.profit.toFixed(2),
                    x: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    varieties: varieties
                  })
                }              
              }
            }
          }
  
          //GDD vs Profit (Note this Y-Axis vs X-Axis)
          if (gddData !== null) {
            if (activeFirstVariableOption.id == "GDD" && activeSecondVariableOption.id == "Profit") {
              setYUnit("$/Acre");
              setXUnit("");
              let data = profitSummaries.data.filter(x => x.fieldID === f)
              let fipsCode = fields.filter(x => x.id === f)[0].fips
              let datag = gddData.get(f)
  
              let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
              for (let info of data) {
                if(year == info.year){
                  let gdd = datag.filter(x => x.Year == info.year)
                  // console.log("precip", precip)
    
                  let varietyInfo = varietyData.filter(x => x.year == info.year);
                  // console.log("varietyInfo", varietyInfo)
                  let varieties = [];
                  let cropData = multiYearVarietyData.filter(x => x.FieldID === f &&
                    x.CropName === crp && x.Year == info.year)
    
                  for (let v of varietyInfo) {
                    for (let x of cropData) {
                      if (x.Variety == v.varietyName) {
                        varieties.push({
                          cropName: crp,
                          name: v.varietyName,
                          year: v.year,
                          x: v.profitVariety.toFixed(2),
                          y: gdd.length > 0 ? gdd[0].cumGDD : 0,
                        })
                      }
                    }
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.year,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: info.profit.toFixed(2),
                    y: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    varieties: varieties
                  })
                }              
              }
            }
          }
  
          //Profit vs Nutrients (Note this Y-Axis vs X-Axis)
          if (activeFirstVariableOption.id == "Profit" && activeSecondVariableOption.id == "Nutrients") {
            setYUnit("$/Acre");
            setXUnit("lbs/Acre");
            let data = profitSummaries.data.filter(x => x.fieldID === f)
            const nutrientData = nutrientInfo.get(nutrientVariableOption.id);
  
            let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
  
            for (let info of data) {
              if(year == info.year){
                let nutrient = nutrientData.filter(x => x.Year == info.year
                  && x.FieldID == f)
    
                let varietyInfo = varietyData.filter(x => x.year == info.year);
                // console.log("varietyInfo", varietyInfo)
                let varieties = [];
                let cropData = multiYearVarietyData.filter(x => x.FieldID === f &&
                  x.CropName === crp && x.Year == info.year)
    
                for (let v of varietyInfo) {
                  for (let x of cropData) {
                    if (x.Variety == v.varietyName) {
                      varieties.push({
                        cropName: crp,
                        name: v.varietyName,
                        year: v.year,
                        y: v.profitVariety.toFixed(2),
                        x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                      })
                    }
                  }
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.year,
                  name: fields.filter(x => x.id === f)[0].name,
                  y: info.profit.toFixed(2),
                  x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                  varieties: varieties
                })
              }            
            }
          }
  
          //Nutrients vs Profit (Note this Y-Axis vs X-Axis)
          if (activeFirstVariableOption.id == "Nutrients" && activeSecondVariableOption.id == "Profit") {
            setYUnit("lbs/Acre");
            setXUnit("$/Acre");
            let data = profitSummaries.data.filter(x => x.fieldID === f)
            const nutrientData = nutrientInfo.get(nutrientVariableOption.id);
  
            let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
  
            for (let info of data) {
              if(year == info.year){
                let nutrient = nutrientData.filter(x => x.Year == info.year
                  && x.FieldID == f)
    
                let varietyInfo = varietyData.filter(x => x.year == info.year);
                // console.log("varietyInfo", varietyInfo)
                let varieties = [];
                let cropData = multiYearVarietyData.filter(x => x.FieldID === f &&
                  x.CropName === crp && x.Year == info.year)
    
                for (let v of varietyInfo) {
                  for (let x of cropData) {
                    if (x.Variety == v.varietyName) {
                      varieties.push({
                        cropName: crp,
                        name: v.varietyName,
                        year: v.year,
                        x: v.profitVariety.toFixed(2),
                        y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                      })
                    }
                  }
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.year,
                  name: fields.filter(x => x.id === f)[0].name,
                  x: info.profit.toFixed(2),
                  y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                  varieties: varieties
                })
              }            
            }
          }
  
          //Profit vs None-Selected (Note this Y-Axis vs X-Axis)
          if (activeFirstVariableOption.id == "Profit" && (activeSecondVariableOption.id == "None selected" || activeSecondVariableOption.id == "Profit")) {
            setYUnit("$/Acre");
            setXUnit("$/Acre");
            let data = profitSummaries.data.filter(x => x.fieldID === f)
            let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
  
            for (let info of data) {
              if(year == info.year){
                let varietyInfo = varietyData.filter(x => x.year == info.year);
                // console.log("varietyInfo", varietyInfo)
                let varieties = [];
    
                let cropData = multiYearVarietyData.filter(x => x.FieldID === f &&
                  x.CropName === crp && x.Year == info.year)
    
                for (let v of varietyInfo) {
                  for (let x of cropData) {
                    if (x.Variety == v.varietyName) {
                      varieties.push({
                        cropName: crp,
                        name: v.varietyName,
                        year: v.year,
                        y: v.profitVariety.toFixed(2),
                        x: v.profitVariety.toFixed(2),
                      })
                    }
                  }
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.year,
                  name: fields.filter(x => x.id === f)[0].name,
                  y: info.profit.toFixed(2),
                  x: info.profit.toFixed(2),
                  varieties: varieties
                })
              }            
            }
          }
  
          //Yield vs Profit (Note this Y-Axis vs X-Axis)
          if (activeFirstVariableOption.id == "Yield" && activeSecondVariableOption.id == "Profit") {
            setYUnit("bu/Acre");
            setXUnit("$/Acre");
            let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
            let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
            let data = profitSummaries.data.filter(x => x.fieldID === f)
            for (let info of cropData) {
              if(year == info.CropSeason){
                let profit = data.filter(x => x.year == info.CropSeason)
                let varieties = [];
                let varietyInfo = varietyData.filter(x => x.year == info.year);
                let crpData = multiYearVarietyData.filter(x => x.FieldID === f &&
                  x.CropName === crp && x.Year == info.year)
  
                for (let v of varietyInfo) {
                  for (let x of crpData) {
                    if (x.Variety == v.varietyName) {
                      varieties.push({
                        cropName: crp,
                        name: v.varietyName,
                        year: v.year,
                        y: x.VarietyYield,
                        x: v.profitVariety.toFixed(2),
                      })
                    }
                  }
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.CropSeason,
                  name: fields.filter(x => x.id === f)[0].name,
                  y: info.AverageYield,
                  x: profit.length > 0 ? profit[0].profit.toFixed(2) : 0,
                  varieties: varieties
                })
              }            
            }
          }
  
          //Profit vs Yield (Note this Y-Axis vs X-Axis)
          if (activeFirstVariableOption.id == "Profit" && activeSecondVariableOption.id == "Yield") {
            setYUnit("$/Acre");
            setXUnit("bu/Acre");
            let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
            let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
            let data = profitSummaries.data.filter(x => x.fieldID === f)
            for (let info of cropData) {
              if(year == info.CropSeason){
                let profit = data.filter(x => x.year == info.CropSeason)
                let varieties = [];
                let varietyInfo = varietyData.filter(x => x.year == info.year);
                let crpData = multiYearVarietyData.filter(x => x.FieldID === f &&
                  x.CropName === crp && x.Year == info.year)
  
                for (let v of varietyInfo) {
                  for (let x of crpData) {
                    if (x.Variety == v.varietyName) {
                      varieties.push({
                        cropName: crp,
                        name: v.varietyName,
                        year: v.year,
                        x: x.VarietyYield,
                        y: v.profitVariety.toFixed(2),
                      })
                    }
                  }
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.CropSeason,
                  name: fields.filter(x => x.id === f)[0].name,
                  x: info.AverageYield,
                  y: profit.length > 0 ? profit[0].profit.toFixed(2) : 0,
                  varieties: varieties
                })
              }            
            }
          }
  
          //Precipitation vs Precipitation (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null) {
            if (activeFirstVariableOption.id == "Precipitation" && activeSecondVariableOption.id == "Precipitation") {
              setYUnit("inches");
              setXUnit("inches");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let precipData = precipitationData.get(f)
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let precip = precipData.filter(x => x.Year == info.CropSeason)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                      y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
          //Precipitaion vs Gdd (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null && gddData !== null) {
            if (activeFirstVariableOption.id == "Precipitation" && activeSecondVariableOption.id == "GDD") {
              setYUnit("inches");
              setXUnit("");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let precipData = precipitationData.get(f)
              let data = gddData.get(f);
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let precip = precipData.filter(x => x.Year == info.CropSeason)
                  let gdd = data.filter(x => x.Year == info.CropSeason)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                      x: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    x: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
          //Precipitation vs Nutrients (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null) {
            if (activeFirstVariableOption.id == "Precipitation" && activeSecondVariableOption.id == "Nutrients") {
              setYUnit("inches");
              setXUnit("lbs/Acre");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let precipData = precipitationData.get(f)
              const data = nutrientInfo.get(nutrientVariableOption.id);
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let precip = precipData.filter(x => x.Year == info.CropSeason)
                  let nutrient = data.filter(x => x.Year == info.CropSeason
                    && x.FieldID == f)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                      y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                    y: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
          //GDD vs Precipitation (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null && gddData !== null) {
            if (activeFirstVariableOption.id == "GDD" && activeSecondVariableOption.id == "Precipitation") {
              setXUnit("inches");
              setYUnit("");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let precipData = precipitationData.get(f)
              let data = gddData.get(f);
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let precip = precipData.filter(x => x.Year == info.CropSeason)
                  let gdd = data.filter(x => x.Year == info.CropSeason)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                      y: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    y: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
          //GDD vs Gdd (Note this Y-Axis vs X-Axis)
          if (gddData !== null) {
            if (activeFirstVariableOption.id == "GDD" && activeSecondVariableOption.id == "GDD") {
              setXUnit("");
              setYUnit("");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
  
              let data = gddData.get(f)
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let gdd = data.filter(x => x.Year == info.CropSeason)
                  // console.log("precip", precip)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      x: gdd.length > 0 ? gdd[0].cumGDD : 0,
                      y: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    y: gdd.length > 0 ? gdd[0].cumGDD : 0,
                    varieties: varieties
                  })
                }              
              }
            }
          }
  
          //GDD vs Nutrients (Note this Y-Axis vs X-Axis)
          if (gddData !== null) {
            if (activeFirstVariableOption.id == "GDD" && activeSecondVariableOption.id == "Nutrients") {
              setYUnit("");
              setXUnit("lbs/Acre");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let gddDt = gddData.get(f)
              const data = nutrientInfo.get(nutrientVariableOption.id);
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let gdd = gddDt.filter(x => x.Year == info.CropSeason)
                  let nutrient = data.filter(x => x.Year == info.CropSeason
                    && x.FieldID == f)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                      y: gdd.length > 0 ? gdd[0].cumGDD * 0.0394 : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                    y: gdd.length > 0 ? gdd[0].cumGDD * 0.0394 : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
          //Nutrients vs Precipitation (Note this Y-Axis vs X-Axis)
          if (precipitationData !== null) {
            if (activeFirstVariableOption.id == "Nutrients" && activeSecondVariableOption.id == "Precipitation") {
              setXUnit("inches");
              setYUnit("lbs/Acre");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let precipData = precipitationData.get(f)
              const data = nutrientInfo.get(nutrientVariableOption.id);
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let precip = precipData.filter(x => x.Year == info.CropSeason)
                  let nutrient = data.filter(x => x.Year == info.CropSeason
                    && x.FieldID == f)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                      x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                    x: precip.length > 0 ? precip[0].cumPrecip * 0.0394 : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
          //Nutrients vs Gdd
          if (gddData !== null) {
            if (activeFirstVariableOption.id == "Nutrients" && activeSecondVariableOption.id == "GDD") {
              setYUnit("lbs/Acre");
              setXUnit("");
              let varietyData = multiYearVarietyData.filter(x => x.FieldID === f && x.CropName === crp)
              //console.log("varietyData", varietyData)
              let cropData = multiYearHarvestData.filter(x => x.FieldID === f && x.CropName === crp)
              let gddDt = gddData.get(f)
              const data = nutrientInfo.get(nutrientVariableOption.id);
  
              for (let info of cropData) {
                if(year == info.CropSeason){
                  let varieties = [];
                  let varietyInfo = varietyData.filter(x => x.Year == info.CropSeason);
                  let gdd = gddDt.filter(x => x.Year == info.CropSeason)
                  let nutrient = data.filter(x => x.Year == info.CropSeason
                    && x.FieldID == f)
    
                  for (let v of varietyInfo) {
                    varieties.push({
                      cropName: crp,
                      name: v.Variety,
                      year: v.Year,
                      y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                      x: gdd.length > 0 ? gdd[0].cumGDD * 0.0394 : 0,
                    })
                  }
    
                  graphData1.push({
                    cropName: crp,
                    year: info.CropSeason,
                    name: fields.filter(x => x.id === f)[0].name,
                    y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                    x: gdd.length > 0 ? gdd[0].cumGDD * 0.0394 : 0,
                    varieties: varieties,
                  })
                }             
              }
            }
          }
  
  
          //Nutrients vs Nutrients
          if (activeFirstVariableOption.id == "Nutrients" && activeSecondVariableOption.id == "Nutrients") {
            setYUnit("lbs/Acre");
            setXUnit("lbs/Acre");
            let data = profitSummaries.data.filter(x => x.fieldID === f)
            const nutrientData = nutrientInfo.get(nutrientVariableOption.id);
  
            let varietyData = multiYearVarietyProfits.profitByVarietyWithField.filter(x => x.fieldID == f)
  
            for (let info of data) {
              if(year == info.year){
                let nutrient = nutrientData.filter(x => x.Year == info.year
                  && x.FieldID == f)
    
                let varietyInfo = varietyData.filter(x => x.year == info.year);
                // console.log("varietyInfo", varietyInfo)
                let varieties = [];
                let cropData = multiYearVarietyData.filter(x => x.FieldID === f &&
                  x.CropName === crp && x.Year == info.year)
    
                for (let v of varietyInfo) {
                  for (let x of cropData) {
                    if (x.Variety == v.varietyName) {
                      varieties.push({
                        cropName: crp,
                        name: v.varietyName,
                        year: v.year,
                        x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                        y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                      })
                    }
                  }
                }
    
                graphData1.push({
                  cropName: crp,
                  year: info.year,
                  name: fields.filter(x => x.id === f)[0].name,
                  x: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                  y: nutrient.length > 0 ? nutrient[0].NutrientRate : 0,
                  varieties: varieties
                })
              }            
            }
          }
        }
      }
      
    }    

    //console.log("graphData1", graphData1)
    setSelectedCropData(graphData1);
  }, [
    selectedCrops,
    activeFirstVariableOption,
    activeSecondVariableOption,
    nutrientVariableOption,
    profitSummaries,
    multiYearVarietyProfits,
    precipitationData,
    gddData,
    seasonYears
  ]);

  // const [cropOptions, setCropOptions] = useState([
  //   { id: "Corn Wet", active: true },
  //   { id: "Soybeans", active: false },
  // ]);

  useEffect(() => {
    getPrecipitationForFields(fieldBoundaries, multiYears, seasons);
    getGDDForFields(fieldBoundaries, multiYears, seasons);
  }, [seasons])

  const activeCropOption = cropOptions.find((option) => option.active);

  const handleDropdowns = (first, second) => {
    setFirstVariableOptions(
      firstVariableOptions.map((d) => ({
        ...d,
        active: d.id === first,
      })))

    setSecondVariableOptions(
      secondVariableOptions.map((d) => ({
        ...d,
        active: d.id === second,
      })))
  }

  const handleChange = (e) => {
    setSelectedCrops(e.target.value)
  }

  //This is an example of how you will filter by crop.
  //It is using a fictional property on the mocked up field data object.
  // const filteredFields = fields.filter((field) => {
  //   return field.primaryCrop === activeCropOption.id;
  // });
  //You will need to do something similar to filter your incoming data by active seasons.
  return (
    <div>
      {loading && <CircularProgress
        size={60}
        style={{
          position: 'absolute',
          top: '50%',
          right: 0,
          left: 0,
          margin: 'auto',
          zIndex: 1005,
          color: green,
        }}
      />}

      <div className={classes.text}>
        Compare two variables across your farm to better understand your sources
        of profitability and cost. First, select which Crop to analyze, and
        across which months. Then, select which variables you’d like to compare.
        Here are some of the most popular comparisons:
        <span>
          <Button className={classes.button} onClick={(e) => handleDropdowns("Yield", "Precipitation")} style={{padding: "0px !important"}}>
            <PopularComparisonChip label="YIELD vs. PRECIPITATION" />
          </Button>
        </span>
        <span>
          <Button onClick={(e) => handleDropdowns("Yield", "GDD")} style={{padding: "0px !important"}}>
            <PopularComparisonChip label="YIELD vs. GDD" />
          </Button>
        </span>
        <span>
          <Button onClick={(e) => handleDropdowns("Yield", "Nutrients")} style={{padding: "0px !important"}}>
            <PopularComparisonChip label="YIELD vs. NUTRIENTS" ></PopularComparisonChip>
          </Button>
        </span>

        {/* <div style={{float: "right"}}>
          Single Year
          <Switch
            checked={isMultiYear}
            onChange={(e) => setIsMultiYear(!isMultiYear)}
            name="checkedA"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          Multi Year
        </div> */}
      </div>

      {!isMultiYear && <div className={classes.actionsContainer}>
        <div className={classes.innerActionsContainer}>
          {/* Multi-select for crops */}
          <div className={classes.wrapper}>
            <InputLabel className={classes.label}>Crop</InputLabel>
            <FormControl className={classes.formControl} variant="outlined">
              <Select
                id="years-mutiple"
                multiple
                value={selectedCrops}
                onChange={handleChange}
                input={<Input id="select-multiple-years" />}
                renderValue={(selected) => (
                  <div className={classes.chips}>
                    {selected.map((value, i) => (
                      <Chip key={i} label={value} className={classes.chip} />
                    ))}
                  </div>
                )}
                MenuProps={MenuProps}
              >
                {crops.map((crop, i) => (
                  <MenuItem key={i} value={crop}>
                    <Checkbox
                      color="primary"
                      checked={selectedCrops.includes(crop)}
                    />
                    <ListItemText primary={crop} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
          {/* Multi-select for years */}
          <SeasonSelect seasons={seasonYears} setSeasons={setSeasonYears} message={"Select Year"} />
          {/* Multi-select for years */}
          <SeasonSelect seasons={seasons} setSeasons={setSeasons} message={"Select Month"} />
        </div>
        <div className={classes.innerActionsContainer}>
          <CustomSelect
            options={firstVariableOptions}
            setOptions={setFirstVariableOptions}
            label="First Variable"
          />
          <CustomSelect
            options={secondVariableOptions}
            setOptions={setSecondVariableOptions}
            label="Second Variable"
          />
          {activeSecondVariableOption.id == "Nutrients" && (
            <CustomSelect
              options={nutrientOptions}
              setOptions={setNutrientOptions}
              label="Nutrients"
            />
          )}
        </div>
      </div>}

      {
        !isMultiYear ?
          activeSecondVariableOption.id === "None selected" ? (
            <ScatterPlotContainer
              firstVariableOption={activeSecondVariableOption}
              secondVariableOption={activeFirstVariableOption}
              type={CHART_TYPES.Beeswarm}
              fields={selectedCropData}
              xUnit={xUnit}
              yUnit={yUnit}
              selectedCrop={selectedCrops}
              activeYear={activeYear}
              // fields={filteredFields}
              crop={activeCropOption}
            />
          ) : (
              <ScatterPlotContainer
                firstVariableOption={activeSecondVariableOption}
                secondVariableOption={activeFirstVariableOption}
                type={CHART_TYPES.Scatter}
                fields={selectedCropData}
                xUnit={xUnit}
                yUnit={yUnit}
                activeYear={activeYear}
                selectedCrop={selectedCrops}
                //fields={filteredFields}
                crop={activeCropOption}
              />
            ) : <MultiYearGraphs
            multiYearHarvestData={multiYearHarvestData}
            multiYearNutrientData={multiYearNutrientData}
            multiYears={multiYears}
            multiYearCrops={multiYearCrops}
            allFieldsInfo={allFieldsInfo}
            fields={fields}
            profitSummaries={profitSummaries}
          />
      }
    </div>
  );
};

export default Analytics;

Analytics.propTypes = {
  fields: PropTypes.array.isRequired,
};

import React, { useEffect, useState } from 'react';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Link,
  Divider,
  Slide,
  CircularProgress,
  Checkbox,
  Switch,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { makeStyles } from '@material-ui/core/styles';
import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import { date } from 'jszip/lib/defaults';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MenuProps } from '../../../styles/select';
import { blackText, grey, lightGrey } from '../../../styles/colors';
import { SARMap } from './SARMap';
import { useWindowDimensions } from '../../../utils/dimensions';
import { numFormat, exists } from '../../../utils/helpers';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { GetUav, IntegrationLinks } from '../../Shared/ServiceLinks';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  selections: theme.selections,
  messageBox: theme.messageBox,
  customWidth: {
    maxWidth: 1000,
  },
  errorMessageBox: theme.errorMessageBox,
  dateSelection: theme.dateSelection,
}));

export function SAR({
  field,
  loading,
  getSAR,
  image,
  setImage,
  extent,
  setExtent,
  mobileBreakPoint,
  precisionField,
  onConnectClick
}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());
  const [errorMessage, setErrorMessage] = useState('')

  const [retryAttempts, setRetryAttempts] = useState(0);

  // Toggle imagery
  const [showSar, setShowSar] = useState(true);

  const layers = [
    { name: 'IW - VV [linear sigma0]', value: 'IW_VV' },
    { name: 'IW - VH [linear sigma0]', value: 'IW_VH' },
  ];

  const [selectedLayer, setSelectedlayer] = useState(layers[0]);
  const [showLatest, setShowLatest] = useState(true);

  useEffect(() => {
    if (
      errorMessage !== undefined
      && errorMessage !== ''
      && retryAttempts < 1
    ) {
      // If initial load had an error, try again with different band
      const layerForSecondAttempt = layers.filter((x) => x.name !== selectedLayer.name);
      if (layerForSecondAttempt.length > 0) {
        setSelectedlayer(layerForSecondAttempt[0]);
        getData(layerForSecondAttempt[0].value);
        setRetryAttempts((prev) => prev + 1);
      }
    }
  }, [errorMessage]);

  const getData = async (layer, date, latest) => {
    await getSAR(layer, date, latest);
    setShowSar(true);
  };

  const handleSwitch = (checked) => {
    setShowSar(checked);
  };

  const handleChange = (val, type) => {
    switch (type) {
      case 'Layer':
        const layer = { name: val.name, value: val.value };
        setSelectedlayer(layer);
        getData(val.value, selectedDate);
        break;
      case 'Date':
        if (val.toString() !== 'Invalid Date') {
          if (showLatest) {
            setShowLatest(false);
            getData(selectedLayer.value, val, false);
          } else {
            getData(selectedLayer.value, val, showLatest);
          }
          setSelectedDate(val);
        }
        break;
      case 'Latest':
        setShowLatest(val);
        getData(selectedLayer.value, selectedDate, val);
        break;
    }
  };

  const layerInfo = () => (
    <>
      <Box>
        <Typography color="inherit">
          VV bands pick up on more vertically oriented waves which will highlight things like structures or equipment
        </Typography>
      </Box>
      <Box>
        <Typography color="inherit">
          VH bands pick up on more horizontally oriented waves which will highlight things like clearing or grading
        </Typography>
      </Box>
    </>
  );

  const controls = () => (
    <Box display="flex" flexWrap="wrap">
      <Box mx={1}>
        <Box display="flex" alignItems="center">
          <Box>
            Layer
          </Box>
          <CustomToolTip classes={{ tooltip: classes.customWidth }} title={layerInfo()} placement="right" arrow>
            <InfoOutlinedIcon style={{ fontSize: '16px', color: grey, margin: '0 0 4px 6px' }} />
          </CustomToolTip>
        </Box>

        <Select
          className={classes.selections}
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedLayer.value}
          onChange={(e) => handleChange(e.target, 'Layer')}
        >
          {
            layers.map((x, i) => (
              <MenuItem
                key={i}
                value={x.value}
                name={x.name}
              >
                {x.name}
              </MenuItem>
            ))
          }
        </Select>
      </Box>

      <Box mx={1}>
        <Box>
          Date
        </Box>
        <Box id="datepicker">
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              className={classes.dateSelection}
              disableToolbar
              autoOk
              variant="inline"
              format="MM/dd/yyyy"
              margin="normal"
              id="date-picker-inline"
              inputVariant="outlined"
              value={selectedDate}
              onChange={(date, val) => handleChange(date, 'Date')}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
              InputAdornmentProps={{ position: "start" }}
            />
          </MuiPickersUtilsProvider>
        </Box>
      </Box>

      <Box display='flex' flexWrap='wrap'>
        <Box
          display="flex"
          alignItems="center"
        >
          <Checkbox
            color="primary"
            checked={showLatest}
            onChange={(e) => handleChange(e.target.checked, 'Latest')}
          />
          <Box>
            Show Latest Available Data
          </Box>
        </Box>

        <Box
          display="flex"
          alignItems="center"
          fontSize={14}
        >
          <Switch
            color="primary"
            checked={showSar}
            onChange={(e) => handleSwitch(e.target.checked)}
            inputProps={{ 'aria-label': 'boundary toggle' }}
          />
          Toggle SAR Imagery
        </Box>
      </Box>
    </Box>
  )

  return (

    <Slide direction="right" in>
      <Box color={blackText} fontWeight={500}>
        <Box mt={1} mx={1}>
          <Box fontSize={18}>
            Satellite Radar Imagery
          </Box>
          <Divider />
        </Box>

        <Box
          mt={.5} 
          display='flex' 
          flexDirection={width > 1080 ? 'row' : 'column'}
        >
          <Box display='flex' flexDirection='column' flexGrow={1}>
            { controls() }

            { errorMessage !== '' && errorMessage !== 'Trying again' && (
              <Box m={1} display="flex">
                <Box className={classes.errorMessageBox}>
                  {errorMessage}
                </Box>
              </Box>
            )}

            <Box
              p={.5}
              display='flex'
              flexGrow={1}
              minWidth={width >= 1080 ? width - 800 : width > mobileBreakPoint ? width - 240 : width - 30}
              borderRadius={4}
            >
              <SARMap
                boundary={field.boundary}
                location={field?.state}
                mapImage={image}
                mapExtents={extent}
                showSar={showSar}
                setErrorMessage={setErrorMessage}
                retryAttempts={retryAttempts}
              />
            </Box>
          </Box>
          <Box
              mt={1}
              display='flex'
              flexDirection={(width < mobileBreakPoint || width > 1080) ? 'column' : 'row'}
              flexWrap='wrap'
            >
              <Box p={.5} display='flex' justifyContent='center'>
                <GetUav />
              </Box>

              { !precisionField && (
                <Box display='flex' justifyContent='center'>
                  <Box p={.5}>
                    <IntegrationLinks onConnect={onConnectClick} />
                  </Box>
                </Box>  
              )}
            </Box>
        </Box>
        
        {loading && <SpinningLoader />}
      </Box>
    </Slide>
  );
}

/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Divider,
  IconButton,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from '../../../styles/select';
import {
  convertShapeToTIFF,
  getLegendData,
} from '../../../utils/dataFetchers';
import { SoilResultsMap } from './Map/ResultsMap';
import { Endpoints } from '../../../constants/Endpoints';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { SelectionMenu } from './SelectionMenu';
import { displayNames } from './helpers';
import { checkImage } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    color: theme.palette.text.primary,
  },
  selections: theme.selections,
}));

/**
 * User may choose their shape file, then fields to have converted to geoTiff for download.
 * @param {String} boundary Stringified field boundary
 * @param {Object} shapeFiles Files to be selected for GeoJSON conversion and display
 * @param {String} token To make back end requests for non-logged in users
 * @param {Funtion} getGeoJson Converts shape file to geoJsons and adds to list
 * @returns {JSX} Dropdowns for shape file selection, map display, downloads
 */
export const ShapeFileSelection = ({ boundary, shapeFiles, token, getGeoJson }) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(shapeFiles[0] ? shapeFiles[0] : '');
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFieldName, setSelectedFieldName] = useState('');
  const [downloadables, setDownloadables] = useState([]);

  // Map display
  const [geoJson, setGeoJson] = useState(null);
  const [type, setType] = useState(null);
  const [base64, setBase64] = useState('');
  const [mapExtent, setMapExtent] = useState('');

  useEffect(() => {
    if (shapeFiles) {
      if (shapeFiles?.zones?.length) {
        handleChange(shapeFiles.zones[0]);
      } else if (shapeFiles?.results?.length) {
        handleChange(shapeFiles.results[0]);
      } else if (shapeFiles?.recomendations?.length) {
        handleChange(shapeFiles.recomendations[0]);
      }
    }
  }, [shapeFiles]);

  const handleChange = async (path) => {
    try {
      setLoading(true);
      const requestUrl = path.replace('#', '%23');
      setSelected(path);

      const convertedShape = await getGeoJson(requestUrl);
      if (convertedShape?.GeoJSON?.features?.length <= 100) {
        setGeoJson(convertedShape.GeoJSON);
        setBase64('');
        setMapExtent('');
        if (convertedShape.GeoJSON.features[0].geometry.type === 'Point') {
          setType('points');
        } else {
          setType('zones');
        }
      } else {
        setGeoJson(null);
        setType(null);
        const legend = await getLegendData(requestUrl, 'Target_Rat', '0', token);
        if (legend?.legendData) {
          const mapImage = checkImage(legend.legendData[1].pngb64);
          setBase64(mapImage);
          setMapExtent(legend.legendData[1].extent);
        }
      }

      const file = await convertShapeToTIFF(requestUrl, 0, null, token);

      if (file?.Shape_FieldNames) {
        setSelectedFile(file);
        setSelectedFieldName('');
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleFieldChange = async (field) => {
    try {
      setLoading(true);
      setSelectedFieldName(field);
      const requestUrl = selected.replace('#', '%23');
      const newFile = await convertShapeToTIFF(requestUrl, 0, field.name, token);

      setLoading(false);
      if (newFile?.File_ID && newFile?.Shape_FieldNames[0]?.name) {
        setDownloadables((prev) => [...prev, { file: newFile, shape: requestUrl }]);
        window.location.assign(`${Endpoints.BASEURL}${Endpoints.DOWNLOADRASTERFILE}${'GeoTIFF'}&FileID=${newFile.File_ID}&Name=${newFile.Shape_FieldNames[0].name}`);
      } else {
        enqueueSnackbar('Unable to download selected file');
      }
    } catch (err) {
      enqueueSnackbar('Unable to download selected file');
      console.error(err);
    }
  };

  const downloadButton = (download, i) => {
    const shapeName = download.shape.split('/')[download.shape.split('/').length - 1];
    const fileName = download.file.Shape_FieldNames[0].name;
    const name = `${shapeName} - ${fileName}`;
    const fileLink = `${Endpoints.BASEURL}${Endpoints.DOWNLOADRASTERFILE}${'GeoTIFF'}&FileID=${download.file.File_ID}&Name=${fileName}`;
    return (
      <Box
        key={`${name}-${i}`}
        my={1}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
        >

          <IconButton
            color="primary"
            id={`download-${name}-${i}`}
            href={fileLink}
          >
            <GetAppRoundedIcon />
          </IconButton>

          <Typography variant="subtitle2" style={{ margin: '0 10px' }}>
            { name }
          </Typography>
        </Box>
        <Divider />
      </Box>
    );
  };

  const selectZone = () => {
    //
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" flexWrap="wrap" width="100%">
        <Box pr={1}>
          <Box>
            <Typography variant="h6">
              Easily convert your Shape Files to downloadable GeoTIFFs.
            </Typography>
            <Box pl={1}>
              <Typography>
                First select a Shape File from the dropdown.
              </Typography>
              <Typography>
                Once its loaded, selected the variable you would like to convert.
              </Typography>
              <Typography>
                After the conversion is complete, your field download will begin automatically.
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Box mt={1}>
            Select a Shape File to convert
          </Box>
          <Box>
            { (
              shapeFiles?.results?.length > 0
              || shapeFiles?.recomendations?.length > 0
              || shapeFiles?.zones?.length > 0
            ) && (
              <SelectionMenu
                shapeFiles={shapeFiles}
                selected={selected}
                handleChange={handleChange}
                loading={loading}
              />
            )}
          </Box>

          { selectedFile?.Shape_FieldNames && (
            <Box mt={1}>
              <Box>
                Select a field to download
              </Box>
              <Box m={1}>
                <Select
                  className={classes.selections}
                  variant="outlined"
                  MenuProps={MenuProps}
                  value={selectedFieldName}
                  onChange={(e) => handleFieldChange(e.target.value)}
                  disabled={loading}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>-- Select Variable to download--</em>
                  </MenuItem>
                  {
                    selectedFile.Shape_FieldNames.map((x) => (
                      <MenuItem
                        key={x}
                        value={x}
                        name={x.name}
                      >

                        {displayNames(x.name)}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Box>
            </Box>
          )}

          { downloadables?.length > 0 && (
            <Box mt={0.5}>
              <Divider />
              <Box mt={0.5} p={1}>
                <Typography>
                  Files available for download:
                </Typography>

                <Box display="flex" flexDirection="column">
                  { downloadables.map((file, i) => downloadButton(file, i)) }
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        { (selected && geoJson) && (
          <Box
            my={2}
            px={1}
            py={0.5}
            display="flex"
            flexDirection="column"
            flexGrow={1}
          >
            <Typography align="center" variant="h6">
              {`${selected.split('/')[selected.split('/').length - 1]}`}
            </Typography>
            <Divider />

            <SoilResultsMap
              boundary={boundary}
              mapGeo={geoJson}
              selectZone={selectZone}
              type={type}
              base64={base64}
              extent={mapExtent}
            />
          </Box>
        )}
      </Box>

      { loading && <SpinningLoader />}
    </Box>
  );
};

ShapeFileSelection.propTypes = {
  boundary: PropTypes.string.isRequired,
  shapeFiles: PropTypes.object.isRequired,
  token: PropTypes.string.isRequired,
  getGeoJson: PropTypes.func.isRequired,
};
/* eslint-disable no-nested-ternary */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Divider, Slide, Fade, Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowDimensions } from '../../../utils/dimensions';
import { blackText } from '../../../styles/colors';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { ImageSwitch } from '../Shared/ImageSwitch';
import { SoilDataMap } from './SoilDataMap';
import { CreateSsurgoTable } from './ssurgoTable';
import { IntegrationLinks, GetSoilTesting } from '../../Shared/ServiceLinks';

const useStyles = makeStyles((theme) => ({
  messageBox: theme.messageBox,
  errorMessageBox: theme.errorMessageBox,
  link: {
    color: theme.palette.text.primary,
  },
}));

/**
 * Display SSURGO map and table
 * @param {Object} field  Field to display data for
 * @param {Object} loading Loading status
 * @param {Object} geo GeoJson for map display
 * @param {Array} legend Data for table display
 * @param {Number} average Average NCCPI value
 * @param {Number} mobileBreakPoint Pixel width side navigation changes to dropdown
 * @param {String} errorMessage Message to display to user if do not have data
 * @param {Bool} precisionField If field is from precision source
 * @param {Function} onConnectClick Open integration modal
 * @returns {JSX} SSURGO
 */
export function SoilData({
  field,
  loading,
  geo,
  legend,
  average,
  mobileBreakPoint,
  errorMessage,
  precisionField,
  onConnectClick,
  slideDirection, // Props from here down are used for the LoanUnderwriting app to customize how component is rendered
  loaderPosition,
  underwriting,
  countyAverage,
}) {
  const { width, height } = useWindowDimensions();
  const classes = useStyles();

  const [extent, setExtent] = useState('');
  const [showImagery, setShowImagery] = useState(true);

  const handleSwitch = (checked) => {
    setShowImagery(checked);
  };

  return (
    <Slide direction={slideDirection || 'right'} in>
      <Box color={blackText} fontWeight={500}>
        <Box mt={1} mx={1}>
          <Box fontSize={18}>
            SOILS
          </Box>

          <Box fontSize="1rem">
            SSURGO Soil type data from&nbsp;
            <Link className={classes.link} href="https://www.nrcs.usda.gov/wps/portal/nrcs/detail/soils/survey/?cid=nrcs142p2_053627" target="_blank" rel="noreferrer">USDA-NRCS</Link>
            .
          </Box>
          <Divider />
        </Box>

        <Box
          mt={0.5}
          display="flex"
          flexDirection={width > 1080 ? 'row' : 'column'}
        >
          <Box display="flex" flexDirection="column" flexGrow={1}>
            { errorMessage === '' && (
              <ImageSwitch
                checked={showImagery}
                handleSwitch={handleSwitch}
              />
            )}

            { errorMessage === '' ? (
              <Box
                p={0.5}
                display="flex"
                flexGrow={1}
                minWidth={underwriting ? 'auto' : width >= 1080 ? width - 800 : width > mobileBreakPoint ? width - 240 : width - 30}
                borderRadius="borderRadius"
              >
                <SoilDataMap
                  boundary={field.boundary}
                  mapGeo={geo}
                  mapExtents={extent}
                  showImagery={showImagery}
                  height="100%"
                  width="100%"
                  location={field?.state}
                />
              </Box>

            ) : (
              <Box m={1} display="flex">
                <Box className={classes.errorMessageBox}>
                  {errorMessage}
                </Box>
              </Box>
            )}
          </Box>

          <Box
            mt={1}
            display="flex"
            flexDirection={(width < mobileBreakPoint || width > 1080) ? 'column' : 'row'}
            flexWrap="wrap"
            style={underwriting ? { minWidth: 380, margin: '0 auto' } : {}}
          >

            <Fade in={legend?.length > 0 && !loading}>
              <Box p={0.5}>
                <CreateSsurgoTable
                  average={average}
                  legend={legend}
                  fieldAcres={field?.acres}
                  underwriting={underwriting}
                  countyAverage={countyAverage}
                />
              </Box>
            </Fade>

            { ((!legend?.length || legend?.length < 6) && !loading && !underwriting) && (
              <Box p={0.5} display="flex" justifyContent="center">
                <GetSoilTesting />
              </Box>
            )}

            { !precisionField && !underwriting && (
            <Box display="flex" justifyContent="center">
              <Box p={0.5}>
                <IntegrationLinks onConnect={onConnectClick} />
              </Box>
            </Box>
            )}

          </Box>
        </Box>

        {loading && <SpinningLoader top={loaderPosition} />}
      </Box>
    </Slide>
  );
}

SoilData.propTypes = {
  field: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  geo: PropTypes.object.isRequired,
  legend: PropTypes.array.isRequired,
  average: PropTypes.number,
  mobileBreakPoint: PropTypes.number.isRequired,
  errorMessage: PropTypes.string.isRequired,
  precisionField: PropTypes.bool.isRequired,
  onConnectClick: PropTypes.func.isRequired,
};

SoilData.defaultProps = {
  average: null,
};

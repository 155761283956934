import React, { useState, useEffect, useContext, useRef } from 'react'
import { Box, Button, Typography, Paper, Link, Modal, Divider, AppBar, TextField, FormLabel, RadioGroup, Radio, FormControlLabel,
  Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Card, CardActionArea,
  CardContent, Grid, CircularProgress } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Endpoints } from '../../constants/Endpoints'
import { Integrate } from '../Partners/Integrate'
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  grey,
  lightGrey,
  darkGrey,
  green,
  blackText,
  darkText,
} from "../../styles/colors";
import { UserContext } from '../Context/UserContext';

import * as d3 from 'd3-geo'
import * as wkt from 'terraformer-wkt-parser';

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export function ManageFarm({ field, deleteFarm, updateFarm, fields, handleFieldSelect, isLoading }) {

  /**
   * Update Field Information
   * @param {Object} field selected field data
   * @param {Function} deleteFarm deletes selected farm
   * @param {Function} updateFarm updates farm name
   * @param {Array} fields list all fields associated to selected org and farm
   * @param {Function} handleFieldSelect set new field selected
   */

  const classes = useStyles();
  //console.log("field", field)
  const [farmName, setFarmName] = useState('')
  //Dialog controls
  const [open, setOpen] = useState(false);
  const user = useContext(UserContext)[0];

  useEffect(() => {
    setFarmName(field.farm)
  }, [field])

  const confirmDeleteFarm = () => {
    setOpen(false);
    deleteFarm(field.orgId, field.farmId)
  }

  const confirmUpdateFarm = () => {
    updateFarm(field.orgId, field.farmId, farmName)
  }

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };


  const confirmModal = () => (
    <div>
      <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Delete Farm</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Are you sure to delete this farm?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={()=>handleClose()} color="primary" variant='outlined'>
            Cancel
          </Button>
          <Button onClick={()=>confirmDeleteFarm()} color="primary" variant='contained' disableElevation>
            Delete Farm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )

  const getPath = (field, id) => {
    const width = 100
    //console.log(field)
    if (field.shape !== undefined) {
      const shape = wkt.parse(field.shape);

      const projection = d3.geoMercator()
      projection.fitExtent([[20, 20], [width, width]], shape);
      // console.log(field.name)
      // console.log('shape', shape)
      // console.log('projection',projection)
      // console.log('--------------------------------')
      const geoPath = d3.geoPath(projection);
      return geoPath(shape)
    } else {
      return ''
    }

  }

  const drawFieldShape = (field, i) => {
    //console.log(field)
    return(
      <Grid item xs={4} style={{margin: '10px', overflow: 'unset'}}>
      <Card className={classes.root} onClick={()=>handleFieldSelect(field)}>
        <CardActionArea>
          <CardContent>
            <Typography gutterBottom variant="h5" component="h2">
              {field.name}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              Acres: {Math.round(field.acres*100)/100}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              State: {field.state}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
              County: {field.county}
            </Typography>
            <Typography variant="body2" color="textSecondary" component="p">
            <svg id={"shapesvg" + i} width='100' height='100'>
              <path fill="none" stroke="#F05922" stroke-width="3" d={getPath(field, i)} />
            </svg>
            </Typography>
          </CardContent>
        </CardActionArea>
      </Card>
    </Grid>
    )
  }

  const usageText = () => (
    <Box
      my={1}
      p={1}
      display="flex"
      flexDirection="column"
      fontSize={24}
      fontWeight={500}
      style={{ maxWidth: 740, textAlign: 'center' }}
    >
      <Box>
        Sign in or Create an account and Integrate your precision data to start managing your farms and fields.
      </Box>
    </Box>
  )

  return (
    <Box my={1} style={{width: "100%"}}>
      {user.isAuthenticated ?
        <>
          <Box display="flex" justifyContent="center">
            <Typography variant="h6" style={{color: green}}>Farm Information</Typography>
          </Box>
          <Box display="flex" justifyContent="space-between" flexDirection="column">
            <Box mx={2} my={1}>
              <TextField
                id="outlined-helperText"
                label="Farm Name"
                helperText="Edit farm name"
                variant="outlined"
                value={farmName}
                onChange={(e) => setFarmName(e.target.value)}
              />
            </Box>
            <Box display="flex">
              <Box mx={2} my={1}>
                <Button variant="outlined" color="primary" onClick={() => handleClickOpen()}>Delete Farm</Button>
              </Box>
              <Box mx={2} my={1}>
                <Button variant="contained" color="primary" onClick={() => confirmUpdateFarm()} disableElevation>Save Changes</Button>
              </Box>
            </Box>
          </Box>
        </>
        :
        <Box>
          <Integrate showLogin={true} customText={usageText} customBackground={"#ffffff"}/>
        </Box>
      }
      

      { confirmModal() }

      {isLoading && <CircularProgress
                      style={{
                        position: 'absolute',
                        top: '30%',
                        right: 0,
                        left: 0,
                        margin: 'auto',
                        zIndex: 1005,
                        color: green,
                      }}
                  />}
      <Grid container spacing={2}>
        {fields.map((field, i) => drawFieldShape(field, i))}
      </Grid>
    </Box>
  )
}

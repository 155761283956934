import React, { useEffect, useState, useContext } from "react";
import {
  makeStyles,
  Box,
  FormControl,
  TextField,
  CircularProgress,
} from "@material-ui/core";
import {
  DEFAULT_FARM_NAME,
  DEFAULT_YEAR,
  ORG_ID,
  REPORT_TYPE,
} from "../../constants";
import Sidebar from "./Sidebar";
import {
  fetchFarmsForOrg,
  fetchOrganizations,
  getProfitMapAccess,
  checkReferralCode,
  fetchFarms,
  fetchFieldsForOrgFarm
} from "../../utils/dataFetchers";
import MobileNav from "./MobileNav/index";
import LowerMobileNav from "./LowerMobileNav/index";
import FarmReport from "./FarmReport/FarmReport";
import MarketReport from "./MarketReport";
import { ascending, range } from "d3-array";
import Spinner from "../Helpers/Spinner";
import { useParams } from "react-router-dom";
import { useWindowDimensions } from "../../utils/dimensions";
import { UserContext } from "../Context/UserContext";
import { PaymentApp } from "../ProfitLayers/Reports/PaymentApp";
import { Endpoints } from "../../constants/Endpoints";
import FarmReportOverview from "../Shared/Overview/FarmReportOverview";
import { FieldContext } from "../Context/FieldContext";

const useStyles = makeStyles(() => ({
  overall: {},
  container: {},
  mobileNavbar: ({ width }) => ({
    paddingTop: width <= 767 ? 0 : 25,
    marginTop: width <= 767 ? "10px" : 0,
  }),
}));

/**
 * Renders the report container, if reportType is 'farm' then Farm Report componenets will be displayed. If report type ps 'market' then market report is displayed
 * @param {String} reportType string that can be either 'farm'/'market'. Defaulted to farm.
 * @param {Number} orgId selected Organization ID passed from ProfitLayers. This will be used to get farms/fields. If this is undefined then first org will be selected 
 * @param {Number} selectedYear selected year that will be passed from ProfitLayers to set dropdown year. If this is undefined then, current year will be selected
 * @param {String} selectedFarm selected farm ID passed from ProfitLayers. This will be used to get fields and operations. If this is undefined then first farm will be selected 
 * @param {Bool} fromProfitLayers object with id and active property
 * @param {Bool} profitMapAccess Boolean value to check if user has access to farm report and market report
 * @param {Function} setProfitMapAccess FUnction that updates profitMapAccess (above variable value)
 */
const ReportContainer = ({
  reportType = "farm",
  orgId,
  selectedYear,
  selectedFarm,
  fromProfitLayers = false,
  profitMapAccess,
  setProfitMapAccess,
}) => {
  const { width, height } = useWindowDimensions();
  const user = useContext(UserContext)[0];
  const classes = useStyles({ width });
  const mobileBreakPoint = 860;
  const [authenticated, setAuthenticated] = useState(false);

  const [fieldData, setFieldData] = useContext(FieldContext);
  //console.log("fieldData", fieldData)

  // const { reportType = REPORT_TYPE.Farm } = useParams();
  // console.log("reportType", reportType, REPORT_TYPE)
  //const [reportType, setReportType]

  const [views, setViews] = useState([
    { id: "overview", label: "Overview", active: true },
    { id: "analytics", label: "Analytics", active: false },
  ]);

  //currently this is being used to display overview tab only, when decided to show analytics tab too pass the above [views, setViews] to FarmReport 
  const [farmReportView, setFarmReportView] = useState([
    { id: "overview", label: "Overview", active: true },
  ]);

  const yearsList = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear + 1; i >= currentYear - 10; i--) {
    yearsList.push(i);
  }

  const [orgs, setOrgs] = useState([]);
  const [farms, setFarms] = useState([]);
  const [fields, setFields] = useState([]);

  const [years, setYears] = useState(
    yearsList.map((year) => {
      return {
        value: year.toString(),
        active:
          year === (selectedYear == undefined ? yearsList[1] : selectedYear), // set an active year
      };
    })
  );

  const [isFieldReport, setIsFieldReport] = useState(false);

  const [message, setMessage] = useState("");

  //Payment app
  const [access, setAccess] = useState(
    profitMapAccess === undefined ? false : profitMapAccess
  );
  const [processing, setProcessing] = useState(false);
  const [discount, setDiscount] = useState(0);
  const [referralcode, setReferralcode] = useState("");
  const [field, setField] = useState({
    orgId: "",
  });

  const [loadedStateData, setLoadedStateData] = useState(false);
  const [loadedFarmData, setLoadedFarmData] = useState(false);

  useEffect(() => {
    (async () => {
      if (user.isAuthenticated) {
        setAuthenticated(true);
        setMessage("Fetching Organization Information...");
        const orgsRes = await fetchOrganizations();
        setOrgs(
          orgsRes
            .sort((a, b) => ascending(a.Name, b.Name))
            .map((d, i) => ({
              ...d,
              id: d.ID,
              name: d.Name,
              active: orgId == undefined ? i == 0 : d.ID == orgId,
            }))
        );
      }
    })();
  }, [user]);

  useEffect(() => {
    (async () => {
      setFarms([]);
      //const xs = await fetchFarmsForOrg(orgId == undefined ? ORG_ID : orgId);
      if (orgs.length > 0 && user.isAuthenticated) {
        setField({
          orgId: orgs.find((org) => org.active).ID,
        });
        checkAccess(orgs.find((org) => org.active).ID);
        setMessage("Fetching Farms Information...");       

        if(fromProfitLayers && fieldData.selectedField.farmId !== "" && fieldData.farms !== undefined &&fieldData.farms.length > 0){
          let xs = fieldData.farms;

          setFarms(
            xs
              .sort((a, b) => ascending(a.name, b.name))
              .map((d, i) => ({
                ...d,
                id: d.id,
                name: d.name,
                active:
                  selectedFarm === undefined ? i == 0 : d.id == selectedFarm, //commented this for to be accessible from url
              }))
          );

        }else{
          const xs = await fetchFarms(orgs.find((org) => org.active).ID, false);

          setFarms(
            xs
              .sort((a, b) => ascending(a.FarmName, b.FarmName))
              .map((d, i) => ({
                ...d,
                id: d.FarmID,
                name: d.FarmName,
                active:
                  selectedFarm === undefined ? i == 0 : d.FarmID == selectedFarm, //commented this for to be accessible from url
              }))
          );
        }
        
      }
    })();
  }, [orgs, fieldData]);

  useEffect(() => {
    (async () => {
      setFields([]);
      //const xs = await fetchFarmsForOrg(orgId == undefined ? ORG_ID : orgId);
      if (farms.length > 0 && user.isAuthenticated) {
        setMessage("Fetching Fields Information...");       

        const xs = await fetchFieldsForOrgFarm(orgs.find((org) => org.active).ID, farms.find((farm) => farm.active).id);

        setFields(xs);
      }
    })(); 
  }, [farms])

  const [areAllActive, setAreAllActive] = useState(true);
  const [noData, setNoData] = useState(false);

  const checkAccess = async (orgId) => {
    try {
      const accesstoorg = await getProfitMapAccess(orgId);
      //console.log("accesstoorg", accesstoorg)
      setAccess(accesstoorg);
    } catch (err) {
      console.log("error getting access to profit map", err);
    }
  };

  const handleReferralCode = async (value) => {
    setReferralcode(value);

    try {
      let res = await checkReferralCode(value);
      if (Object.keys(res).length > 0 && res.percentageDiscount !== undefined) {
        setDiscount(res.percentageDiscount);
      } else {
        setDiscount(0);
      }
    } catch (err) {
      console.log("error verifying disocunt code", err);
      setDiscount(0);
    }
  };

  const handlePayment = async (customerData, subscriptionData) => {
    let customerInfo = "";
    try {
      fetch(Endpoints.BASEURL + Endpoints.API_CUSTOMER, {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(customerData),
      })
        .then((response) => {
          if (response.ok) {
            const jsonresult = response.json();
            return jsonresult;
          }
          throw new Error(response);
        })
        .then((response) => {
          const customer = JSON.stringify(response.data);
          const dontgotosubscritionpage = sessionStorage.getItem(
            "dontGoToSubscriptionList"
          );
          sessionStorage.setItem("customerInfo", customer);
          customerInfo = customer;

          subscriptionData.ApiKey = customerInfo.apiKey;

          fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION, {
            method: "POST",
            headers: {
              Accept: "application/json",
              "Content-Type": "application/json",
            },
            credentials: "include",
            body: JSON.stringify(subscriptionData),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error(response);
            })
            .then((response) => {
              if (response.isSuccessful === true) {
                const subscription = response.data;
                sessionStorage.setItem(
                  "SubscriptionIdentifier",
                  subscription.subscriptionIdentifier
                );
                setAccess(true);
                setProcessing(false);
              } else {
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log("error", error);
        });
    } catch (err) {
      console.log("handlePaymentMethod", err);
    }
  };

  return (
    <div display="flex" style={{ height: "100%", width: "100%" }}>
      <Sidebar
        farms={farms}
        setFarms={setFarms}
        years={years}
        setYears={setYears}
        mobileView={width <= 880}
        orgs={orgs}
        setOrgs={setOrgs}
        fromProfitLayers={fromProfitLayers}
        authenticated={authenticated}
      />
      <div
        display="flex"
        style={{
          height: "100%",
          overflow: "auto",
        }}
      >
        {width <= 880 && (
          <div className={classes.mobileNavbar}>
            {!isFieldReport && <MobileNav views={views} setViews={setViews} />}
            <LowerMobileNav
              years={years}
              setYears={setYears}
              farms={farms}
              setFarms={setFarms}
            />
          </div>
        )}

        {authenticated ? (
          !access ? (
            <Box
              display="flex"
              style={{
                height: "100%",
                overflow: "auto",
                marginLeft: "250px",
              }}
            >
              {!processing ? (
                <Box
                  style={{
                    paddingTop: 16,
                    display: "flex",
                    //justifyContent: 'center',
                    alignItems: "center",
                    flexDirection: "column",
                    margin: "auto",
                    height: "100%",
                    width: "100%",
                  }}
                >
                  <Box
                    px={1}
                    style={{
                      fontSize: "2em",
                      textAlign: "center",
                    }}
                  >
                    Please sign up for a yearly subscription to get unlimited
                    Profit Layers and access to Farm Report for Organization:{" "}
                    {orgs.length > 0 && orgs.find((org) => org.active).Name} (
                    {orgs.length > 0 && orgs.find((org) => org.active).ID})
                  </Box>
                  <Box textAlign="center" fontSize={"2em"}>
                    {"($" + Endpoints.PROFITLAYERS_PRICE + " per year)"}
                  </Box>
                  <Box
                    mb={1}
                    style={{
                      fontSize: "1em",
                      textAlign: "center",
                    }}
                  >
                    Note: The subscription comes with a{" "}
                    <span style={{ fontWeight: 500 }}>
                      30-day money back guarantee
                    </span>{" "}
                    and renews automatically at ${Endpoints.PROFITLAYERS_PRICE}{" "}
                    per year.
                  </Box>
                  <Box style={{ width: "25%" }}>
                    <FormControl
                      variant="outlined"
                      style={{ position: "relative", display: "flex" }}
                    >
                      <TextField
                        value={referralcode}
                        onChange={(event) =>
                          handleReferralCode(event.target.value)
                        }
                        label="Discount Code"
                      />
                    </FormControl>
                  </Box>

                  <PaymentApp
                    authenticated={authenticated}
                    setProfitMapAccess={setAccess}
                    setProcessing={setProcessing}
                    field={field}
                    handlePayment={handlePayment}
                    referralcode={referralcode}
                  />
                </Box>
              ) : (
                <Box p={3}>
                  <Box
                    p={3}
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    boxShadow={2}
                    borderRadius="borderRadius"
                    fontWeight={500}
                    fontSize={20}
                  >
                    <Box mb={2} px={1}>
                      Thank your for your purchase. We're processing your order.
                    </Box>
                    <CircularProgress color="primary" />
                  </Box>
                </Box>
              )}
            </Box>
          ) : !!farms.length && !!fields.length ? (
            reportType === REPORT_TYPE.Farm ? (
              <FarmReport
                years={years}
                views={views} //use {views} when decide to show analytics tab
                setViews={setViews} // use {setViews} when decide to show analytics tab
                activeFarm={farms.find((farm) => farm.active)}
                activeYear={years.find((year) => year.active).value}
                mobileView={width <= 880}
                isFieldReport={isFieldReport}
                setIsFieldReport={setIsFieldReport}
                ORG_ID={orgs.find((org) => org.active).ID}
                selectedOrg={orgs.find((org) => org.active)}
                areAllActive={areAllActive}
                setAreAllActive={setAreAllActive}
                message={message}
                setMessage={setMessage}
                access={access}
                setAccess={
                  setProfitMapAccess === undefined
                    ? setAccess
                    : setProfitMapAccess
                }
                processing={processing}
                setProcessing={setProcessing}
                checkAccess={checkAccess}
                noData={noData}
                setNoData={setNoData}
                fromProfitLayers={fromProfitLayers}
                allFieldsInfo={fields}
                reportType='farm'
                user={user}
              />
            ) : (
              <MarketReport
                years={years}
                views={views}
                setViews={setViews}
                activeFarm={farms.find((farm) => farm.active)}
                activeYear={years.find((year) => year.active).value}
                mobileView={width <= 880}
                orgId={orgs.find((org) => org.active).ID}
                loadedFarmData={loadedFarmData}
                setLoadedFarmData={setLoadedFarmData}
                loadedStateData={loadedStateData}
                setLoadedStateData={setLoadedStateData}
                reportType='market'
              />
            )
          ) : (
            <Spinner message={message} />
          )
        ) : (
          <FarmReportOverview mobileView={width <= 880} />
        )}
      </div>
    </div>
  );
};

export default ReportContainer;

export const stateCoordinates = [
  {
    id: "1", //Alabama
    coordinates: [32.7794, -86.8287]
  },
  {
    id: "2", //Alaska
    coordinates: [64.0685, -152.2782]
  },
  {
    id: "4", //Arizona
    coordinates: [34.2744, -111.6602]
  },
  {
    id: "5", //Arkansas
    coordinates: [34.8938, -92.4426]
  },
  {
    id: "6", //California
    coordinates: [37.1841, -119.4696]
  },
  {
    id: "8", //Colorado
    coordinates: [38.9972, -105.5478]
  },
  {
    id: "9", //Connecticut
    coordinates: [41.6219, -72.7273]
  },
  {
    id: "10", //Delaware
    coordinates: [38.9896, -75.5050]
  },
  {
    id: "12", //Florida
    coordinates: [28.6305, -82.4497]
  },
  {
    id: "13", //Georgia
    coordinates: [32.6415, -83.4426]
  },
  {
    id: "15", //Hawaii
    coordinates: [20.2927, -156.3737]
  },
  {
    id: "16", //Idaho
    coordinates: [44.3509, -114.6130]
  },
  {
    id: "17", //Illinois
    coordinates: [40.0417, -89.1965]
  },
  {
    id: "18", //Indiana
    coordinates: [39.8942, -86.2816]
  },
  {
    id: "19", //Iowa
    coordinates: [42.0751, -93.4960]
  },
  {
    id: "20", //Kansas
    coordinates: [38.4937, -98.3804]
  },
  {
    id: "21", //Kentucky
    coordinates: [37.5347, -85.3021]
  },
  {
    id: "22", //Louisiana
    coordinates: [31.0689, -91.9968]
  },
  {
    id: "23", //Maine
    coordinates: [45.3695, -69.2428]
  },
  {
    id: "24", //Maryland
    coordinates: [39.0550, -76.7909]
  },
  {
    id: "25", //Massachusetts
    coordinates: [42.2596, -71.8083]
  },
  {
    id: "26", //Michigan
    coordinates: [44.3467, -85.4102]
  },
  {
    id: "27", //Minnesota
    coordinates: [46.2807, -94.3053]
  },
  {
    id: "28", //Mississippi
    coordinates: [32.7364, -89.6678]
  },
  {
    id: "29", //missouri
    coordinates: [38.3566, -92.4580]
  },
  {
    id: "30", //montana
    coordinates: [47.0527, -109.6333]
  },
  {
    id: "31", //Nebraska
    coordinates: [41.5378, -99.7951]
  },
  {
    id: "32", //Nevada
    coordinates: [39.3289, -116.6312]
  },
  {
    id: "33", //new hampshire
    coordinates: [43.6805, -71.5811]
  },
  {
    id: "34", //new jersey
    coordinates: [40.1907, -74.6728]
  },
  {
    id: "35", //new mexico
    coordinates: [34.4071, -106.1126]
  },
  {
    id: "36", //new york
    coordinates: [42.9538, -75.5268]
  },
  {
    id: "37", //north carolina
    coordinates: [35.5557, -79.3877]
  },
  {
    id: "38", //north dakota
    coordinates: [47.4501, -100.4659]
  },
  {
    id: "39", //ohio
    coordinates: [40.2862, -82.7937]
  },
  {
    id: "40", //oklahoma
    coordinates: [35.5889, -97.4943]
  },
  {
    id: "41", //oregon
    coordinates: [43.9336, -120.5583]
  },
  {
    id: "42", //pennsylvania
    coordinates: [40.8781, -77.7996]
  },
  {
    id: "44", //rhode island
    coordinates: [41.6762, -71.5562]
  },
  {
    id: "45", //south carolina
    coordinates: [33.9169, -80.8964]
  },
  {
    id: "46", //south dakota
    coordinates: [44.4443, -100.2263]
  },
  {
    id: "47", //tennessee
    coordinates: [35.8580, -86.3505]
  },
  {
    id: "48", //texas
    coordinates: [31.4757, -99.3312]
  },
  {
    id: "49", //utah
    coordinates: [39.3055, -111.6703]
  },
  {
    id: "50", //vermont
    coordinates: [44.0687, -72.6658]
  },
  {
    id: "51", //virginia
    coordinates: [37.5215, -78.8537]
  },
  {
    id: "53", //washington
    coordinates: [47.3826, -120.4472]
  },
  {
    id: "54", //west virginia
    coordinates: [38.6409, -80.6227]
  },
  {
    id: "55", //wisconsin
    coordinates: [44.6243, -89.9941]
  },
  {
    id: "56", //wyoming
    coordinates: [42.9957, -107.5512]
  },
]
import * as turf from '@turf/turf';
import * as L from 'leaflet';
import PropTypes from 'prop-types';

export const getFeature = (layer) => {
  try {
    // eslint-disable-next-line no-underscore-dangle
    const coordinates = layer._latlngs[0].map((x) => [x.lng, x.lat]);
    coordinates.push(coordinates[0]); // ensure closed polygon
    const acres = turf.area(turf.polygon([coordinates])) / 4047;

    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
      properties: {
        CALCACRES: acres,
      },
    };

    return feature;
  } catch (err) {
    console.error(err);
    return undefined;
  }
};

/**
 * Gets geomParam from either map bounds or given input bounds
 * @param {Object} map Map to use for bounds
 * @param {Object} inputBounds Optional bounds
 * @returns {Object} geomParam
 */
export function getGeom(map, inputBounds = null) {
  try {
    let bounds;
    if (inputBounds === null) {
      bounds = map.getBounds();
    } else {
      bounds = inputBounds;
    }
    const max = bounds.getNorthEast();
    const min = bounds.getSouthWest();

    const geomParam = {
      xmin: min.lng,
      ymin: min.lat,
      xmax: max.lng,
      ymax: max.lat,
      spatialReference: { wkid: 4326 },
    };
    return geomParam;
  } catch (err) {
    // console.log(`error getting geom: ${err}`)
  }
}

/**
 * takes an array of points and converts to leaflets Bounds object
 * @param {Array} extent (should be length 4)
 * @returns {Object} Bounds from given extent
 */
export function convertExtentToBounds(extent) {
  const p1 = L.latLng(extent[1], extent[0]);
  const p2 = L.latLng(extent[3], extent[2]);
  const bounds = L.latLngBounds(p1, p2);
  return bounds;
}

export const createGeoFromBoundary = (boundary) => {
  let features = boundary;

  if (typeof boundary === 'string') {
    features = JSON.parse(boundary);
  }

  if (features?.type === 'Feature') {
    const geo = {
      type: 'FeatureCollection',
      features: [features],
    };
    return geo;
  }
  return features;
};

export const drawFieldsInBounds = (bounds) => {
  const max = bounds.getNorthEast();
  const min = bounds.getSouthWest();
  const box = {
    xmin: min.lng,
    ymin: min.lat,
    xmax: max.lng,
    ymax: max.lat,
  };
  const bboxCoords = `POLYGON ((${box.xmax} ${box.ymax}, ${box.xmax} ${box.ymin}, ${box.xmin} ${box.ymin}, ${box.xmin} ${box.ymax},${box.xmax} ${box.ymax}))`;
  return bboxCoords;
};

/**
 * Draws geoJson boundary on given layer on given map
 * @param {Object} boundary boundary to draw
 * @param {Object} boundaryLayer Layer to draw geoJson to
 * @param {Object} map Map layer is on
 * @param {Bool} snapTo Focus on draw boundary
 * @param {String} color Color to draw
 * @returns {void}
 */
export function drawFieldLayer(boundary, boundaryLayer, map, snapTo = true, color = '#3388ff') {
  try {
    const newCollection = L.geoJson(boundary, {
      onEachFeature: (feature, layer) => {
        boundaryLayer.addLayer(layer).setStyle({
          color,
          fillOpacity: 0,
        });
      },
    });
    newCollection.addTo(map);

    if (snapTo) {
      map.fitBounds(newCollection.getBounds());
    }
  } catch (err) {
    console.error(err);
  }
}

/**
 * Draws image to layer.
 * NOTE: Function expects imageoverlay layer to be a useRef.
 * @param {Object} imageOverlay Layer will draw image to. MUST BE REF
 * @param {String} mapImage Image to add
 * @param {Object|String} boundary Boundary to fit image to
 * @param {*} mapExtents Map extents as backup if cant get feature bounds
 * @param {*} map Map to add overlay to
 * @returns {void}
 */
export function drawImage(imageOverlay, mapImage, boundary, mapExtents, map) {
  const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
  const bounds = Object.keys(mapExtents).length === 0
    ? L.geoJSON(features).getBounds()
    : mapExtents;

  if (imageOverlay.current === null) {
    imageOverlay.current = L.imageOverlay(mapImage, bounds, {
      opacity: 0.8,
      interactive: true,
    });
    imageOverlay.current.addTo(map);
    imageOverlay.current.bringToFront();
  } else {
    imageOverlay.current.setUrl(mapImage);
    imageOverlay.current.setBounds(bounds);
    imageOverlay.current.addTo(map);
  }
}

export const fieldToolTip = (field) => {
  let name = '';
  let coordinates = '';
  let state = '';
  let county = '';

  if (field.name !== undefined) {
    // Field from database
    name = `Field: ${field.name}`;
    state = field.state !== '' ? `State: ${field.state}` : '';
    county = field.county ? `County: ${field.county}` : '';
    try {
      coordinates = field.centroid !== undefined && field.centroid.geometry.coordinates[1] !== undefined ? `Coordinates: ${field.centroid.geometry.coordinates[1].toFixed(2)}, ${field.centroid.geometry.coordinates[0].toFixed(2)}` : '';
    } catch (e) {
      console.log(`Problem getting centroid: ${e}`);
    }
  } else {
    // CLU or drawn field
    try {
      const centroid = turf.centroid(field.feature);
      const centerPoints = centroid.geometry.coordinates;
      coordinates = `Coordinates: ${centroid.geometry.coordinates[1].toFixed(2)}, ${centroid.geometry.coordinates[0].toFixed(2)}`;
      name = field.fieldName !== undefined ? `Field: ${field.fieldName}` : '';
      // get state and county maybe?
    } catch (e) {
      console.log(`Problem getting centroid: ${e}`);
    }
  }

  return (
    `<div style="height: 30; width: 30;">
        <div style="margin: 2px 6px;">${name}</div>
        <div style="margin: 2px 6px;">Acres: ${field.acres !== undefined ? field.acres.toFixed(2) : ''}</div>
        <div style="margin: 2px 6px;">${coordinates}</div>
        <div style="margin: 2px 6px;">${state}</div>
        <div style="margin: 2px 6px;">${county}</div>
      </div>`
  );
};

/**
   * Highlights given layer by indexing items
   * @param {Integer} index the index of the selected item
   * @param {Array} items an array of items that contain zones of a map (for plantings this is the 'varieties' state variable)
   * @returns {void}
   */
export function highlightFeature(index, items) {
  try {
    if (items[index].zone.properties.featureRef) {
      const map = items[index].zone.properties.mapRef;
      const layer = items[index].zone.properties.featureRef.current;
      // get the correct layer
      layer.eachLayer((layer) => {
        // check for inner layers (this is usually only from the draw tool)
        let innerLayers = false;
        try {
          innerLayers = layer.getLayers().length > 0;
        } catch (err) {
          //
        }

        // eslint-disable-next-line no-underscore-dangle
        if (layer._leaflet_id === items[index].zone.properties.id) {
          // if inner layers, then assuming this is a zone from Draw tool - there should only be one inner layer
          let layerToHighlight;
          if (innerLayers) {
            layerToHighlight = layer.getLayers()[0];
          } else {
            layerToHighlight = layer;
          }

          const layerStyle = {
            color: layerToHighlight.options.color,
            fillColor: layerToHighlight.options.fillColor,
            fillOpacity: layerToHighlight.options.fillOpacity,
            strokeWidth: layerToHighlight.options.strokeWidth,
          };

          const highlightStyle = { ...layerStyle };
          highlightStyle.fillOpacity = 0.8;
          highlightStyle.strokeWidth = 10;
          const layerGeo = layerToHighlight.toGeoJSON();

          const scaledfeature = turf.transformScale(layerGeo.geometry, 1.05);
          const largeLayer = L.geoJSON(scaledfeature);
          largeLayer.setStyle(highlightStyle);

          map.removeLayer(layerToHighlight);
          largeLayer.addTo(map);

          setTimeout(() => {
            map.removeLayer(largeLayer);
            layerToHighlight.addTo(map);
          }, 800);
        }
      });
    }
  } catch (err) {
    console.log('something went wrong', err);
  }
}

export const sumZoneAcres = (zones) => {
  if (!zones) { return 0; }

  const acres = zones.reduce(
    (accumulator, currentValue) => accumulator + currentValue.properties.CALCACRES, 0,
  );
  return acres;
};

export const makeFeature = (x, i) => {
  const feature = {
    type: 'Feature',
    geometry: { type: 'Polygon', coordinates: x },
    properties: { id: i },
  };
  return feature;
};

export const getRandomColor = () => {
  const letters = '0123456789ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * 16)];
  }
  return color;
};

export const getMinHeight = () => {
  if (window.innerHeight - 365 > 500) {
    return window.innerHeight - 365;
  }
  return 500;
};

drawFieldLayer.propTypes = {
  geoJson: PropTypes.object.isRequired,
  boundaryLayer: PropTypes.object.isRequired,
  map: PropTypes.object.isRequired,
};

drawFieldLayer.defaultProps = {
  color: '#3388ff',
  snapTo: false,
};

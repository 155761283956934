import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, MenuItem, Select, Button } from '@material-ui/core/';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import RemoveIcon from '@material-ui/icons/Remove';
import Draggable from 'react-draggable';
import { Profit } from './Maps/Profit'
import { Elevation } from './Maps/Elevation'
import { Operation } from './Maps/Operation'
import { Variety } from './Maps/Variety'
import { AdditionalMap1 } from './Maps/AdditionalMap1'
import { AdditionalMap2 } from './Maps/AdditionalMap2'
import { AdditionalMap3 } from './Maps/AdditionalMap3'
import { AdditionalMap4 } from './Maps/AdditionalMap4'
import { useWindowDimensions } from "../../../utils/dimensions";
import { getProfitMap } from '../../../utils/dataFetchers'
import { blackText } from '../../../styles/colors'
import { numFormat, dollarFormat, capitalizeFirstLetter, exists } from '../../../utils/helpers'

const useStyles = makeStyles((theme) => ({
  dropdown: {
    ...theme.hover,
    fontSize: '14px',
    marginLeft: '5px'
  },
  cardContainer: {
    display: 'flex',
    flexDirection: 'column',
    width: '600px',
    minWidth: '600px',
    maxWidth: '600px',
  },
  card: {
    margin: 20,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    flexGrow: 1,
    backgroundColor: theme.palette.greys.light,
  },
  title: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontWeight: 500,
    fontSize: 20,
    flexGrow: 1,
    margin: 4,
    '&:hover': {
         cursor: 'grabbing',
      },
  },
  mapBox: {
    display: 'flex',
    justifyContent: 'center',
    alignItems:'center',
    height: '550px',
    width: '550px',
    padding: '0 8px',
  },
  selections: {
    height: 40,
    minWidth: 120,
    marginLeft: 6,
    backgroundColor: '#ffffff',
  },
  legend: {
    display: 'flex',
    alignItems:'center',
    backgroundColor: '#ffffff',
    borderRadius: '4px',
    margin: 3,
    padding: 7,
    fontWeight: 500,
    fontSize: 14,
  },
}));


const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

export const MenuProps = {
  PaperProps: {
    style: {
      marginTop: '38px',
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    }
  },
  variant: "menu",
  getContentAnchorEl: null
};


export function MultiMaps({
  field,
  boundary,
  perAcre,
  profitMap,
  profitMapResponse,
  profitData,
  elevationData,
  operationData,
  varietyData,
  clipByBoundary
}) {

  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [selected, setSelected] = useState('')

  // Determinte if varieties should be displayed
  const [showVarietyDropDown, setShowVarietyDropDown] = useState(false);

  // Elevation selection
  const [elevationTypes, setElevationTypes] = useState([])
  const [selectedElevation, setSelectedElevation] = useState('')

  // Number of additional maps to display
  const [additionalMaps, setAdditionalMaps] = useState(0);
  const [mapOneType, setMapOneType] = useState('Variety');
  const [mapTwoType, setMapTwoType] = useState('Variety');
  const [mapThreeType, setMapThreeType] = useState('Variety');
  const [mapFourType, setMapFourType] = useState('Variety');

  const [snapBounds, setSnapBounds] = useState(false)
  const [snapBoundsOperations, setSnapBoundsOperations] = useState(false)
  const [snapBoundsVarieties, setSnapBoundsVarieties] = useState(false)
  const [snapBoundsElevation, setSnapBoundsElevation] = useState(false)
  const [snapMapOneBounds, setSnapMapOneBounds] = useState(false)
  const [snapMapTwoBounds, setSnapMapTwoBounds] = useState(false)
  const [snapMapThreeBounds, setSnapMapThreeBounds] = useState(false)
  const [snapMapFourBounds, setSnapMapFourBounds] = useState(false)


  // Types of map to select from for additional maps
  const mapTypes = ['Variety', 'Operation', 'Elevation', 'Profit Layers']

  // Determine what data to display in additional maps based on selected
  const [additionalMapOneDisplay, setAdditionalMapOneDisplay] = useState(null)
  const [additionalMapTwoDisplay, setAdditionalMapTwoDisplay] = useState(null)
  const [additionalMapThreeDisplay, setAdditionalMapThreeDisplay] = useState(null)
  const [additionalMapFourDisplay, setAdditionalMapFourDisplay] = useState(null)

  useEffect(() => {
    if (varietyData.varietyList !== undefined && varietyData.varietyList.length > 0) {
      setShowVarietyDropDown(true);
    } else {
      setShowVarietyDropDown(false);
    }
  }, [varietyData])

  useEffect(() => {
    if (
      exists(elevationData?.elevationIndex) &&
      exists(elevationData?.selectedElevationIndex?.name)
    ) {
      setElevationTypes(elevationData.elevationIndex.map(x => x.name))
      setSelectedElevation(elevationData.selectedElevationIndex.name)
    }

  }, [elevationData])

  const handleAdditionalMapOperationChange = async (i) => {
    const operationCopy = {...additionalMapOneDisplay.Operation}
    const updatedOperation = await updateOperation(operationCopy, i)

    setAdditionalMapOneDisplay({
      ...additionalMapOneDisplay,
      Operation: updatedOperation
    })
  }

  const handleAdditionalMapTwoOperationChange = async (i) => {
    const operationCopy = {...additionalMapTwoDisplay.Operation}
    const updatedOperation = await updateOperation(operationCopy, i)

    setAdditionalMapTwoDisplay({
      ...additionalMapTwoDisplay,
      Operation: updatedOperation
    })
  }

  const handleAdditionalMapThreeOperationChange = async (i) => {
    const operationCopy = {...additionalMapThreeDisplay.Operation}
    const updatedOperation = await updateOperation(operationCopy, i)

    setAdditionalMapThreeDisplay({
      ...additionalMapThreeDisplay,
      Operation: updatedOperation
    })
  }

  const handleAdditionalMapFourOperationChange = async (i) => {
    const operationCopy = {...additionalMapFourDisplay.Operation}
    const updatedOperation = await updateOperation(operationCopy, i)

    setAdditionalMapFourDisplay({
      ...additionalMapFourDisplay,
      Operation: updatedOperation
    })
  }

  const updateOperation = async (operationCopy, i) => {
    let year = ''
    if (operationData.operations[i].year !== null && operationData.operations[i].year !== undefined) {
      year = operationData.operations[i].year
    } else if (operationData.operations[i].cropSeason !== null && operationData.operations[i].cropSeason !== undefined) {
      year = operationData.operations[i].cropSeason
    } else if (operationData.operations[i].date !== null && operationData.operations[i].date !== undefined) {
      year = operationData.operations[i].date.slice(operationData.operations[i].date.length - 4)
    }

    const ops = {
      Constant: 0,
      FieldOperationList: [operationData.operations[i]],
      IsHighResolutionMapRequest: false,
      IsOperationMapRequest: true,
      IsResizeMapRequest: false,
      LegendColors: null,
      MultiYears: [year],
      ProfitByZones: false,
      ProfitMapLegendRange: null,
      Year: year,
      Zones: null,
      boundarygeojson: clipByBoundary ? field.boundary : null,
    }

    operationCopy.selectedIndex = i

    const data = await getProfitMap(field.orgId, field.id, ops)

    if (data !== undefined && data.profitMap !== undefined && data.profitMap[0] !== undefined) {
      try {
        const bounds = data.profitMap[0].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];

        operationCopy.extent = imageBounds;
        operationCopy.image = `data:image/jpeg;base64,${data.profitMap[0].pngb64}`;
        operationCopy.legend = data.profitMap[0].legend;
      } catch (err) {
        console.log(`problem setting operation data: ${err}`)
      }
    } else{
      console.log('problem getting data')
      console.log(data)
      operationCopy.image = ''
      operationCopy.legend = []
    }

    return operationCopy
  }

  const handleMapOneVarietyChange = (i) => {
    const varietyCopy = {...additionalMapOneDisplay.Variety}
    varietyCopy.selectedIndex = i

    updateVarietySelections(varietyCopy, i)

    setAdditionalMapOneDisplay({
      ...additionalMapOneDisplay,
      Variety: varietyCopy
    })
  }

  const handleMapTwoVarietyChange = (i) => {
    const varietyCopy = {...additionalMapTwoDisplay.Variety}
    varietyCopy.selectedIndex = i

    updateVarietySelections(varietyCopy, i)

    setAdditionalMapTwoDisplay({
      ...additionalMapTwoDisplay,
      Variety: varietyCopy
    })
  }

  const handleMapThreeVarietyChange = (i) => {
    const varietyCopy = {...additionalMapThreeDisplay.Variety}
    varietyCopy.selectedIndex = i

    updateVarietySelections(varietyCopy, i)

    setAdditionalMapThreeDisplay({
      ...additionalMapThreeDisplay,
      Variety: varietyCopy
    })
  }

  const handleMapFourVarietyChange = (i) => {
    const varietyCopy = {...additionalMapFourDisplay.Variety}
    varietyCopy.selectedIndex = i

    updateVarietySelections(varietyCopy, i)

    setAdditionalMapFourDisplay({
      ...additionalMapFourDisplay,
      Variety: varietyCopy
    })
  }

  const updateVarietySelections = (varietyCopy, index) => {

    if (Object.keys(profitMap).length > 0 && profitMap.profitMap.length > 1) {
      varietyCopy.image = `data:image/jpeg;base64,${
        profitMap.profitMap[index + 1].pngb64
      }`;
      varietyCopy.legend = profitMap.profitMap[index + 1].legend
      if (profitMap.profitMap[index + 1].extent !== undefined) {
        const bounds = profitMap.profitMap[index + 1].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];
        varietyCopy.extent = imageBounds
      }
    } else if (profitMapResponse.length > 1) {
      varietyCopy.image = `data:image/jpeg;base64,${profitMapResponse[index + 1].pngb64}`;
      varietyCopy.legend = profitMapResponse[index + 1].legend
      if (profitMapResponse[index + 1].extent !== undefined) {
        const bounds = profitMapResponse[index + 1].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];
        varietyCopy.extent = imageBounds
      }
    } else {
      varietyCopy.image = ''
      varietyCopy.legend = []
      varietyCopy.extent = []
    }

    return varietyCopy
  }

  const displayProfitLegend = (legend) => (
    <Box p={1} display='flex' flexWrap='wrap'>
      {
        legend !== undefined && legend.map((x, i) => (
          <Box key={i} className={classes.legend}>
            <FiberManualRecordIcon style={{color: x.color}}/>
            {dollarFormat(x.max)}
            <RemoveIcon style={{fontSize: '1rem'}} />
            {dollarFormat(x.min)}
            {perAcre && ' /Acre'}
          </Box>
        ))
      }
    </Box>
  )

  const displayVarietyLegend = (legend) => (
    <Box p={1} display='flex' flexWrap='wrap'>
      {
        legend !== undefined && legend.map((x, i) => (
          <Box key={i} className={classes.legend}>
            <FiberManualRecordIcon style={{color: x.color}}/>
            {`${x.variety} - Seeding Density: ${x.area}`}
            {perAcre && ' /Acre'}
          </Box>
        ))
      }
    </Box>
  )

  const displayElevationLegend = (legend) => {
    return (
      <Box p={1} display='flex' flexWrap='wrap'>
        {
          legend !== undefined && legend.map((x, i) => (
            <Box key={i} className={classes.legend}>
              <FiberManualRecordIcon style={{color: x.color}}/>
              { numFormat(x.min) }
              {' ft'}
              <RemoveIcon style={{fontSize: '1rem'}} />
              { numFormat(x.max) }
              {' ft'}
            </Box>
          ))
        }
      </Box>
    )
  }

  const displayOperationLegend = (legend, operation) => {
    return (
      <Box p={1} display='flex' flexWrap='wrap'>
        {
          legend.map((x, i) => (
            <Box key={i} className={classes.legend}>
              <FiberManualRecordIcon style={{color: x.color}}/>
              { numFormat(x.min) }
              <RemoveIcon style={{fontSize: '1rem'}} />
              { numFormat(x.max) }
              {operation !== undefined && ` ${capitalizeFirstLetter(operation.unit)}`}
              {perAcre && ' /Acre'}
            </Box>
          ))
        }
      </Box>
    )
  }

  const cleanName = (op) => {
    let name = op.productName

    if (op.fileType !== undefined && op.fileType.toUpperCase() !== 'APPLICATION' && op.variety !== null) {
      name += `${op.variety} ${op.fileType}`
    } else {
      name += `${op.date}`
    }
    return name
  }

  const addMapPanel = () => {
    const initialData = {
      boundary: boundary,
      "Profit Layers": {
        image: profitData.image,
        extent: profitData.extent,
        legend: profitData.legend,
      },
      "Elevation": {
        image: elevationData.image,
        extent: elevationData.extent,
        legend: elevationData.legend,
        type: elevationData.selectedElevationIndex.name
      },
      "Operation": {
        selectedIndex: 0,
        image: operationData.image,
        extent: operationData.extent,
        legend: operationData.legend,
      },
      "Variety": {
        selectedIndex: 0,
        image: varietyData.image,
        extent: varietyData.extent,
        legend: varietyData.legend,
      },
    }

    if (additionalMaps === 0) {
      // add data for additional map one
      setAdditionalMapOneDisplay(initialData)
    } else if (additionalMaps === 1) {
      setAdditionalMapTwoDisplay(initialData)
    } else if (additionalMaps === 2) {
      setAdditionalMapThreeDisplay(initialData)
    } else if (additionalMaps === 3) {
      setAdditionalMapFourDisplay(initialData)
    }

    setAdditionalMaps(additionalMaps + 1)
  }

  /**
   * Dropdown for operation selection. Takes setter function that will be used to
   * determine with state to update data for. Used for operation map panel and
   * additional added maps with variety as selected type.
   * @param  {Function} setter Sets operation in proper state
   * @param  {Object} selectedIndex Index of selected operation
   */
  const operationDropdown = (setter, selectedIndex) => {
    return (
      <Select
        value={operationData.operations[selectedIndex] !== undefined ? cleanName(operationData.operations[selectedIndex]) : ''}
        variant="outlined"
        className={classes.selections}
        style={{maxWidth: '550px'}}
        MenuProps={MenuProps}
      >
        {
          operationData.operations.map((value, i) =>
            <MenuItem
              key={i}
              onClick={() =>
                setter(i)
              }
              style={{
                fontWeight: 500,
                minWidth: '250px',
                maxWidth: '530px',
              }}
              value={cleanName(value)}

            >
              <Box>
                {cleanName(value)}
              </Box>
            </MenuItem>
        )}
      </Select>
    )
  }

  /**
   * Dropdown for variety selection. Takes setter function that will be used to
   * determine with state to update data for. Used for variety map panel and
   * additional added maps with variety as selected type.
   * @param  {Function} setter Sets variety in proper state
   */
  const varietyDropdown = (setter, selectedIndex) => (
    <Select
      value={
        (operationData.operations[selectedIndex] !== undefined &&
          varietyData.varietyList[selectedIndex] !== undefined &&
          varietyData.varietyList[selectedIndex].name !== undefined)
          ? varietyData.varietyList[selectedIndex].name
          :'Variety Map'
      }
      variant="outlined"
      className={classes.selections}
      MenuProps={MenuProps}
    >
      {
        varietyData.varietyList.map((variety, i) =>
          <MenuItem
            key={i}
            onClick={() => {
              setter(i)
            }}
            style={{
              fontWeight: 500,
              minWidth: '250px',
              maxWidth: '530px',
            }}
            value={variety.name}
          >
            {variety.name}
          </MenuItem>
      )}
    </Select>
  )

  return (
    <Box
      display='flex'
      flexDirection='column'
      style={{hight: '100%'}}
    >
      <Box pl={2} pt={2}>
        <Button
          color='primary'
          variant='outlined'
          onClick={() => addMapPanel()}
          disabled={additionalMapFourDisplay !== null}
        >
          <AddCircleOutlineOutlinedIcon />
          {' Add Map Panel'}
        </Button>
      </Box>

      <Box>


        <Box
          display='flex'
          flexWrap='wrap'
          p={1}
        >

          {
            additionalMaps > 0 &&
            <Draggable
              handle="strong"
              bounds="parent"
              onDrag={() => setSelected('mapOne')}

            >
              <Box style={{zIndex: selected === 'mapOne' ? 1001 : 1000}}>
                <Box className={classes.cardContainer}>
                  <Box
                    className={classes.card}
                    boxShadow={2}
                  >
                    <div className="box no-cursor">
                      <strong className="cursor">
                        <Box className={classes.title} flexWrap='wrap'>
                          {'Map type'}
                          <Select
                            value={mapOneType}
                            variant="outlined"
                            className={classes.selections}
                            style={{marginLeft: '5px'}}
                            MenuProps={MenuProps}
                            onChange={(e) => setMapOneType(e.target.value)}
                          >
                            {
                              mapTypes.map((type, i) => (
                                <MenuItem
                                  key={i}
                                  value={type}
                                  fontWeight={500}
                                >
                                  { type }
                                  { type === 'Elevation' && (
                                    ` (${additionalMapOneDisplay.Elevation.type})`
                                  ) }
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </Box>

                        <Box>
                          {
                            mapOneType === 'Operation' ?
                              operationDropdown(
                                handleAdditionalMapOperationChange,
                                additionalMapOneDisplay.Operation.selectedIndex
                              )
                            :
                            (mapOneType === 'Variety' && showVarietyDropDown) ?
                              varietyDropdown(
                                handleMapOneVarietyChange,
                                additionalMapOneDisplay.Variety.selectedIndex
                              )
                            :
                            ''
                          }
                        </Box>
                      </strong>

                      <Box className={classes.mapBox}>
                        <AdditionalMap1
                          boundary={additionalMapOneDisplay.boundary}
                          mapImage={additionalMapOneDisplay[mapOneType].image}
                          mapExtents={additionalMapOneDisplay[mapOneType].extent}
                          updateExtent={snapMapOneBounds}
                          setSnapBounds={setSnapMapOneBounds}
                        />
                      </Box>
                    </div>
                    <Button
                      onClick={() => setSnapMapOneBounds(true)}
                      color='primary'
                      variant='outlined'
                      style={{margin: 2, backgroundColor: '#ffffff'}}
                    >
                      Zoom to field
                    </Button>
                    {
                      mapOneType === 'Operation' ?
                      displayOperationLegend(additionalMapOneDisplay[mapOneType].legend, operationData.operations[additionalMapOneDisplay[mapOneType].selectedIndex])
                      : mapOneType === 'Elevation' ?
                      displayElevationLegend(additionalMapOneDisplay[mapOneType].legend)
                      : (mapOneType === 'Variety' && additionalMapOneDisplay.Variety.selectedIndex === 0) ?
                      displayVarietyLegend(additionalMapOneDisplay[mapOneType].legend)
                      :
                      displayProfitLegend(additionalMapOneDisplay[mapOneType].legend)
                    }
                  </Box>
                </Box>
              </Box>
            </Draggable>
          }

          {
            additionalMaps > 1 &&
            <Draggable
              handle="strong"
              bounds="parent"
              onDrag={() => setSelected('mapTwo')}
            >
              <Box style={{zIndex: selected === 'mapTwo' ? 1001 : 1000}}>
                <Box className={classes.cardContainer}>
                  <Box
                    className={classes.card}
                    boxShadow={2}
                  >
                    <div className="box no-cursor">
                      <strong className="cursor">
                        <Box className={classes.title}>
                          Map type
                          <Select
                            value={mapTwoType}
                            variant="outlined"
                            className={classes.selections}
                            style={{marginLeft: '5px'}}
                            MenuProps={MenuProps}
                            onChange={(e) => setMapTwoType(e.target.value)}
                          >
                            {
                              mapTypes.map((type, i) => (
                                <MenuItem
                                  key={i}
                                  value={type}
                                  fontWeight={500}
                                >
                                  {type}
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </Box>

                        <Box>
                          {
                            mapTwoType === 'Operation' ?
                              operationDropdown(
                                handleAdditionalMapTwoOperationChange,
                                additionalMapTwoDisplay.Operation.selectedIndex
                              )
                            :
                            (mapTwoType === 'Variety' && showVarietyDropDown) ?
                              varietyDropdown(
                                handleMapTwoVarietyChange,
                                additionalMapTwoDisplay.Variety.selectedIndex
                              )
                            :
                            ''
                          }
                        </Box>
                      </strong>

                      <Box className={classes.mapBox}>
                        <Box style={{height: '550px', width: '550px'}}>
                          <AdditionalMap2
                            boundary={additionalMapTwoDisplay.boundary}
                            mapImage={additionalMapTwoDisplay[mapTwoType].image}
                            mapExtents={additionalMapTwoDisplay[mapTwoType].extent}
                            updateExtent={snapMapTwoBounds}
                            setSnapBounds={setSnapMapTwoBounds}
                          />
                        </Box>

                      </Box>
                    </div>
                    <Button
                      onClick={() => setSnapMapTwoBounds(true)}
                      color='primary'
                      variant='outlined'
                      style={{margin: 2, backgroundColor: '#ffffff'}}
                    >
                      Zoom to field
                    </Button>
                    {
                      mapTwoType === 'Operation' ?
                      displayOperationLegend(additionalMapTwoDisplay[mapTwoType].legend, operationData.operations[additionalMapTwoDisplay[mapTwoType].selectedIndex])
                      : mapTwoType === 'Elevation' ?
                      displayElevationLegend(additionalMapTwoDisplay[mapTwoType].legend)
                      : (mapTwoType === 'Variety' && additionalMapTwoDisplay.Variety.selectedIndex === 0) ?
                      displayVarietyLegend(additionalMapTwoDisplay[mapTwoType].legend)
                      :
                      displayProfitLegend(additionalMapTwoDisplay[mapTwoType].legend)
                    }
                  </Box>
                </Box>
              </Box>
            </Draggable>
          }

          {
            additionalMaps > 2 &&
            <Draggable
              handle="strong"
              bounds="parent"
              onDrag={() => setSelected('mapThree')}
            >
              <Box style={{zIndex: selected === 'mapThree' ? 1001 : 1000}}>
                <Box className={classes.cardContainer}>
                  <Box
                    className={classes.card}
                    boxShadow={2}
                  >
                    <div className="box no-cursor">
                      <strong className="cursor">
                        <Box className={classes.title}>
                          Map type
                          <Select
                            value={mapThreeType}
                            variant="outlined"
                            className={classes.selections}
                            style={{marginLeft: '5px'}}
                            MenuProps={MenuProps}
                            onChange={(e) => setMapThreeType(e.target.value)}
                          >
                            {
                              mapTypes.map((type, i) => (
                                <MenuItem
                                  key={i}
                                  value={type}
                                  fontWeight={500}
                                >
                                  { type }
                                  { type === 'Elevation' && (
                                    ` (${additionalMapOneDisplay.Elevation.type})`
                                  ) }
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </Box>

                        <Box>
                          {
                            mapThreeType === 'Operation' ?
                              operationDropdown(
                                handleAdditionalMapThreeOperationChange,
                                additionalMapThreeDisplay.Operation.selectedIndex
                              )
                            :
                            (mapThreeType === 'Variety' && showVarietyDropDown) ?
                              varietyDropdown(
                                handleMapThreeVarietyChange,
                                additionalMapThreeDisplay.Variety.selectedIndex
                              )
                            :
                            ''
                          }
                        </Box>
                      </strong>

                      <Box className={classes.mapBox}>
                        <Box style={{height: '550px', width: '550px'}}>
                          <AdditionalMap3
                            boundary={additionalMapThreeDisplay.boundary}
                            mapImage={additionalMapThreeDisplay[mapThreeType].image}
                            mapExtents={additionalMapThreeDisplay[mapThreeType].extent}
                            updateExtent={snapMapThreeBounds}
                            setSnapBounds={setSnapMapThreeBounds}
                          />
                        </Box>

                      </Box>
                    </div>
                    <Button
                      onClick={() => setSnapMapThreeBounds(true)}
                      color='primary'
                      variant='outlined'
                      style={{margin: 2, backgroundColor: '#ffffff'}}
                    >
                      Zoom to field
                    </Button>
                    {
                      mapThreeType === 'Operation' ?
                      displayOperationLegend(additionalMapThreeDisplay[mapThreeType].legend, operationData.operations[additionalMapThreeDisplay[mapThreeType].selectedIndex])
                      : mapThreeType === 'Elevation' ?
                      displayElevationLegend(additionalMapThreeDisplay[mapThreeType].legend)
                      : (mapThreeType === 'Variety' && additionalMapThreeDisplay.Variety.selectedIndex === 0) ?
                      displayVarietyLegend(additionalMapThreeDisplay[mapThreeType].legend)
                      :
                      displayProfitLegend(additionalMapThreeDisplay[mapThreeType].legend)
                    }
                  </Box>
                </Box>
              </Box>
            </Draggable>
          }

          {
            additionalMaps > 3 &&
            <Draggable
              handle="strong"
              bounds="parent"
              onDrag={() => setSelected('mapFour')}
            >
              <Box style={{zIndex: selected === 'mapFour' ? 1001 : 1000}}>
                <Box className={classes.cardContainer}>
                  <Box
                    className={classes.card}
                    boxShadow={2}
                  >
                    <div className="box no-cursor">
                      <strong className="cursor">
                        <Box className={classes.title}>
                          Map type
                          <Select
                            value={mapFourType}
                            variant="outlined"
                            className={classes.selections}
                            style={{marginLeft: '5px'}}
                            MenuProps={MenuProps}
                            onChange={(e) => setMapFourType(e.target.value)}
                          >
                            {
                              mapTypes.map((type, i) => (
                                <MenuItem
                                  key={i}
                                  value={type}
                                  fontWeight={500}
                                >
                                  { type }
                                  { type === 'Elevation' && (
                                    ` (${additionalMapOneDisplay.Elevation.type})`
                                  ) }
                                </MenuItem>
                              ))
                            }
                          </Select>
                        </Box>
                        <Box>
                          {
                            mapFourType === 'Operation' ?
                              operationDropdown(
                                handleAdditionalMapFourOperationChange,
                                additionalMapFourDisplay.Operation.selectedIndex
                              )
                            :
                            (mapFourType === 'Variety' && showVarietyDropDown) ?
                              varietyDropdown(
                                handleMapFourVarietyChange,
                                additionalMapTwoDisplay.Variety.selectedIndex
                              )
                            :
                            ''
                          }
                        </Box>
                      </strong>

                      <Box className={classes.mapBox}>
                        <Box style={{height: '550px', width: '550px'}}>
                          <AdditionalMap4
                            boundary={additionalMapFourDisplay.boundary}
                            mapImage={additionalMapFourDisplay[mapFourType].image}
                            mapExtents={additionalMapFourDisplay[mapFourType].extent}
                            updateExtent={snapMapFourBounds}
                            setSnapBounds={setSnapMapFourBounds}
                          />
                        </Box>

                      </Box>
                    </div>
                    <Button
                      onClick={() => setSnapMapFourBounds(true)}
                      color='primary'
                      variant='outlined'
                      style={{margin: 2, backgroundColor: '#ffffff'}}
                    >
                      Zoom to field
                    </Button>
                    {
                      mapFourType === 'Operation' ?
                      displayOperationLegend(additionalMapFourDisplay[mapFourType].legend, operationData.operations[additionalMapFourDisplay[mapFourType].selectedIndex])
                      : mapFourType === 'Elevation' ?
                      displayElevationLegend(additionalMapFourDisplay[mapFourType].legend)
                      : (mapFourType === 'Variety' && additionalMapFourDisplay.Variety.selectedIndex === 0) ?
                      displayVarietyLegend(additionalMapFourDisplay[mapFourType].legend)
                      :
                      displayProfitLegend(additionalMapFourDisplay[mapFourType].legend)
                    }
                  </Box>
                </Box>
              </Box>
            </Draggable>
          }


          <Draggable
            handle="strong"
            bounds="parent"
            onDrag={() => setSelected('profit')}

          >
            <Box style={{zIndex: selected === 'profit' ? 1001 : 1000}}>
              <Box className={classes.cardContainer}>
                <Box
                  className={classes.card}
                  boxShadow={2}
                >
                  <div className="box no-cursor">
                    <strong className="cursor">
                    <Box className={classes.title}>
                      Profit Layers
                    </Box>
                    </strong>
                    <Box className={classes.mapBox} id='profit-map-pdf'>
                      <Profit
                        boundary={boundary}
                        mapImage={profitData.image}
                        mapExtents={profitData.extent}
                        updateExtent={snapBounds}
                        setSnapBounds={setSnapBounds}
                      />
                    </Box>
                  </div>
                  <Button
                    onClick={() => setSnapBounds(true)}
                    color='primary'
                    variant='outlined'
                    style={{margin: 2, backgroundColor: '#ffffff'}}
                  >
                    Zoom to field
                  </Button>
                  {displayProfitLegend(profitData.legend)}
                </Box>
              </Box>
            </Box>
          </Draggable>

          <Draggable
            handle="strong"
            bounds="parent"
            onDrag={() => setSelected('elevation')}
          >
            <Box style={{zIndex: selected === 'elevation' ? 1001 : 1000}}>
              <Box className={classes.cardContainer}>
                <Box
                  className={classes.card}
                  boxShadow={2}
                >
                  <div className="box no-cursor">
                    <strong className="cursor">
                      <Box className={classes.title}>
                        Elevation Map
                      </Box>

                      <Select
                        value={selectedElevation}
                        variant="outlined"
                        className={classes.selections}
                        MenuProps={MenuProps}
                      >
                        {
                          elevationTypes.map((value, i) =>
                            <MenuItem
                              key={i}
                              onClick={() =>
                                elevationData.selectElevation(i)
                              }
                              style={{ fontWeight: 500 }}
                              value={value}
                            >
                              <Box>
                                {value}
                              </Box>
                            </MenuItem>
                        )}
                      </Select>
                    </strong>
                    <Box className={classes.mapBox}>
                      <Elevation
                        boundary={boundary}
                        mapImage={elevationData.image}
                        mapExtents={elevationData.extent}
                        updateExtent={snapBoundsElevation}
                        setSnapBounds={setSnapBoundsElevation}
                      />
                    </Box>
                  </div>
                  <Button
                    onClick={() => setSnapBoundsElevation(true)}
                    color='primary'
                    variant='outlined'
                    style={{margin: 2, backgroundColor: '#ffffff'}}
                  >
                    Zoom to field
                  </Button>
                  {displayElevationLegend(elevationData.legend)}
                </Box>
              </Box>
            </Box>
          </Draggable>

          <Draggable
            handle="strong"
            bounds="parent"
            onDrag={() => setSelected('operation')}
          >
            <Box style={{ zIndex: selected === 'operation' ? 1001 : 1000 }}>
              <Box className={classes.cardContainer}>
                <Box
                  className={classes.card}
                  boxShadow={2}
                >
                  <div className="box no-cursor">
                    <strong className="cursor">
                      <Box className={classes.title}>
                        Operation Map
                      </Box>

                      <Select
                        value={cleanName(operationData.selectedOperation)}
                        variant="outlined"
                        className={classes.selections}
                        style={{maxWidth: '550px'}}
                        MenuProps={MenuProps}
                      >
                        {
                          operationData.operations.map((value, i) =>
                            <MenuItem
                              key={i}
                              onClick={() =>
                                operationData.selectOperation(i)
                              }
                              style={{
                                fontWeight: 500,
                                minWidth: '250px',
                                maxWidth: '530px',
                              }}
                              value={cleanName(value)}
                            >
                              <Box>
                                {cleanName(value)}
                              </Box>
                            </MenuItem>
                        )}
                      </Select>
                    </strong>
                    <Box className={classes.mapBox}>
                      <Operation
                        boundary={boundary}
                        mapImage={operationData.image}
                        mapExtents={operationData.extent}
                        updateExtent={snapBoundsOperations}
                        setSnapBounds={setSnapBoundsOperations}
                      />
                    </Box>
                  </div>
                  <Button
                    onClick={() => setSnapBoundsOperations(true)}
                    color='primary'
                    variant='outlined'
                    style={{margin: 2, backgroundColor: '#ffffff'}}
                  >
                    Zoom to field
                  </Button>
                  {displayOperationLegend(operationData.legend, operationData.selectedOperation)}
                </Box>
              </Box>
            </Box>
          </Draggable>

          <Draggable
            handle="strong"
            bounds="parent"
            onDrag={() => setSelected('variety')}
          >
            <Box style={{ zIndex: selected === 'variety' ? 1001 : 1000 }}>
              <Box className={classes.cardContainer}>
                <Box
                  className={classes.card}
                  boxShadow={2}
                >
                  <div className="box no-cursor">
                    <strong className="cursor">
                    <Box className={classes.title}>
                      Variety Map
                    </Box>

                    {
                        showVarietyDropDown &&
                       <Select
                         value={varietyData.selectedVariety.name !== undefined ? varietyData.selectedVariety.name :'Variety Map'}
                         variant="outlined"
                         className={classes.selections}
                         MenuProps={MenuProps}
                       >
                         {
                           varietyData.varietyList.map((variety, i) =>
                             <MenuItem
                               key={i}
                               onClick={() => {
                                 varietyData.selectVariety(i)
                               }}
                               style={{
                                 fontWeight: 500,
                                 minWidth: '250px',
                                 maxWidth: '530px',
                               }}
                               value={variety.name}
                             >
                               {variety.name}
                             </MenuItem>
                         )}
                       </Select>
                    }

                    </strong>
                    <Box className={classes.mapBox}>
                      <Variety
                        boundary={boundary}
                        mapImage={varietyData.image}
                        mapExtents={varietyData.extent}
                        updateExtent={snapBoundsVarieties}
                        setSnapBounds={setSnapBoundsVarieties}
                      />
                    </Box>
                  </div>
                  <Button
                    onClick={() => setSnapBoundsVarieties(true)}
                    color='primary'
                    variant='outlined'
                    style={{margin: 2, backgroundColor: '#ffffff'}}
                  >
                    Zoom to field
                  </Button>
                  {
                    varietyData.selectedVariety.name !== 'Variety Map' ?
                    displayProfitLegend(varietyData.legend) :
                    displayVarietyLegend(varietyData.legend)
                  }
                </Box>
              </Box>
            </Box>
          </Draggable>
        </Box>
      </Box>

    </Box>
  );
}

/* eslint-disable react/jsx-props-no-spreading */
// React
import React, {
  useContext, useEffect, useState,
} from 'react';
import PropTypes from 'prop-types';

// Material-ui
import {
  Box,
  Chip,
  TextField,
  IconButton,
  Divider,
} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import SettingsBackupRestoreOutlinedIcon from '@material-ui/icons/SettingsBackupRestoreOutlined';
import { useSnackbar } from 'notistack';

// Styling
import { makeStyles } from '@material-ui/core/styles';

// Custom Components
import { ToolTip, CustomToolTip } from '../../../utils/customComponents';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { IntegrationLinks } from '../../Partners/Integrate';
import { IntegrateModal } from '../../Shared/IntegrateModal';
import * as _ from 'underscore';

// Functionality
import { checkIsMobile, filterObject } from '../../../utils/helpers';
import { UserContext } from '../../Context/UserContext';
import {
  fetchFarms,
  fetchFields,
} from '../../../utils/dataFetchers';
import { useWindowDimensions } from '../../../utils/dimensions';

const useStyles = makeStyles((theme) => ({
  displayAll: {
    margin: 8,
    marginRight: 14,
    display: 'flex',
    flexDirection: 'column',
    overflowY: 'auto',
    backgroundColor: '#ffffff',
    border: `1px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
  },
  dot: {
    fontSize: 12,
    color: theme.palette.primary.main,
    marginRight: 4,
  },
  errorMessageBox: {
    ...theme.errorMessageBox,
    fontSize: '16px',
  },
  farmName: {
    paddingLeft: 4,
    color: theme.palette.text.secondary,
    fontWeight: 500,
  },
  fieldName: {
    paddingLeft: 8,
    display: 'flex',
    alignItems: 'center',
    fontWeight: 400,
  },
  infoIcon: {
    ...theme.infoToolTip,
    marginBottom: '10px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 6,
    color: theme.palette.text.primary,
    width: 280,
  },
  select: {
    width: 240,
    margin: '0 10px',
    backgroundColor: '#ffffff',
  },
}));

/**
 * Org/Farm/Field selection with ability to select multiple farms & fields.
 * @param {Array} organizations,
 * @param {Array} chosenFields Fields user has selected
 * @param {Object} chosenOrg Selected organization
 * @param {Object} farmMaps Farm ids as keys, name as value
 * @param {Object} fieldMaps Field ids as keys, name as value
 * @param {String} impersonating email address for user being impersonated by agent
 * @param {Function} setChosenFields Sets user selected fields
 * @param {Function} setChosenOrg Selects organization
 * @param {Function} setFarmMaps Fields with ID as key, storing name and farm data
 * @param {Function} setFieldMaps Fields with ID as key, storing name and farm data
 * @param {Function} setSelectedFields Sets selected fields set with field IDs
 * @returns {JSX} Multi farm and field selection
 */
export function FieldSelection({
  organizations,
  chosenFields,
  chosenOrg,
  farmMaps,
  fieldMaps,
  impersonating,
  setChosenFields,
  setChosenOrg,
  setFarmMaps,
  setFieldMaps,
  setSelectedFields,
}) {
  const classes = useStyles();
  const { height } = useWindowDimensions();
  const [mobileDisplay, setMobileDisplay] = useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const user = useContext(UserContext)[0];
  const [openIntegration, setOpenIntegration] = useState(false);

  const [farmSelectionOpen, setFarmSelectionOpen] = useState(false);
  const [farms, setFarms] = useState([]);
  const [selectedFarms, setSelectedFarms] = useState([]);
  const [allFarmsSelected, setAllFarmsSelected] = useState(false);

  const [fieldSelectionOpen, setFieldSelectionOpen] = useState(false);
  const [fields, setFields] = useState([]);
  const [allSelected, setAllSelected] = useState(false);

  // Maximum number of fields user may have selected at a time
  const maxAllowed = 30;

  // Fields loading/error
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  useEffect(() => {
    // Used for tooltips and eventually row or column for selection boxes
    setMobileDisplay(checkIsMobile());
  }, []);

  // If user decides to impersonate another user (organization selection will change), reset all these vars
  useEffect(() => {
    setFarms([]);
    deselectAllFarms();
  }, [organizations]);

  // ---- Change handlers ----
  const handleOrgChange = async (org) => {
    try {
      setLoading(true);
      setChosenOrg(org);

      // Get farms for selected Org
      const orgFarms = await getFarms(org);
      setFarms(orgFarms);

      // Resets all selected states
      deselectAllFarms();
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Problem updating organizations. Please try again or contact us if the problem persists.');
    } finally {
      setLoading(false);
    }
  };

  const handleFarmChange = async (value) => {
    try {
      setLoading(true);
      // Check if change is org being de-selected
      const removed = selectedFarms.filter((farm) => !value.includes(farm));
      if (removed?.length) {
        // On farm de-selected, remove its fields from field array
        const updatedFields = fields.filter((field) => field.farmId !== removed[0].id);
        setFields(updatedFields);
        const updatedChosenFields = chosenFields.filter((field) => field.farmId !== removed[0].id);
        setChosenFields(updatedChosenFields);

        const fieldIds = updatedChosenFields.map((x) => x.id);
        setSelectedFields(new Set(fieldIds));

        const fieldMapsCopy = { ...fieldMaps };
        const updatedFieldMaps = filterObject(fieldMapsCopy, fieldIds);
        setFieldMaps(updatedFieldMaps);

        // Remove de-selected from farm maps
        const farmMapsCopy = { ...farmMaps };
        delete farmMapsCopy[removed[0].id];
        setFarmMaps(farmMapsCopy);
      } else {
        // Get fields for new farm
        const recentFarm = value[value.length - 1];
        setFarmMaps((prev) => ({
          ...prev,
          [recentFarm.id]: recentFarm.name,
        }));

        const farmsFields = await getFields(recentFarm.orgId, recentFarm.id, recentFarm.name);
        if (farmsFields?.length) {
          setFields((prev) => [...prev, ...farmsFields]);
          // If we add more fields, we need to show dropdown again
          setAllSelected(false);
        }

        if (value.length === farms.length) {
          // Close dropdown if all options are selected
          setFarmSelectionOpen(false);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
      setSelectedFarms(value);
    }
  };

  const handleFieldChange = (value) => {
    try {
      // Get field ids from updated value
      const fieldIds = value.map((x) => x.id);
      setSelectedFields(new Set(fieldIds));

      // Check for removed by seeing if a field from new value is missing from fieldMaps
      const removed = Object.keys(fieldMaps).filter((x) => !fieldIds.includes(x));
      // console.log('removed', removed)

      if (removed?.length) {
        // If field has been de-selected, remove it from field maps
        const fieldMapsCopy = { ...fieldMaps };
        delete fieldMapsCopy[removed[0]];
        setFieldMaps(fieldMapsCopy);
      } else {
        // Add new field to fieldMaps
        const recent = value[value.length - 1];
        const { id } = recent;
        const fieldName = recent.name;
        setFieldMaps((prev) => ({
          ...prev,
          [id]: { name: fieldName, farm: { id: recent.farmId, name: recent.farm } },
        }));
      }
      setChosenFields(value);

      if (value.length === fields.length) {
        // Close dropdown if all options are selected
        setFieldSelectionOpen(false);
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('We encounted a problem selecting this field. Please try another or contact us if the problem persists.');
    }
  };

  const selectAllFarms = async () => {
    try {
      setSelectedFarms(farms);

      const mappedFarms = {};
      farms.forEach((farm) => {
        mappedFarms[farm.id] = farm.name;
      });
      setFarmMaps((mappedFarms));

      const fieldPromises = [];
      // eslint-disable-next-line no-restricted-syntax
      for (const farm of farms) {
        fieldPromises.push(getFields(farm.orgId, farm.id, farm.name));
      }

      // Resolve promises for field data
      const fieldData = await Promise.all(fieldPromises);
      // Flatten array then set fields
      setFields(fieldData.flat());
      setAllFarmsSelected(true);
    } catch (err) {
      enqueueSnackbar('We encounted a problem selecting farms. Please try selecting your farms individually or contact us for assistance.');
    }
  };

  const deselectAllFarms = () => {
    setSelectedFarms([]);
    setFarmMaps({});
    setAllFarmsSelected(false);

    // Reset all field values
    setFields([]);
    deselectAllFields();
  };

  const selectAllFields = () => {
    try {
      const selected = {};

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < fields.length; i++) {
        if (i < maxAllowed) {
          const field = fields[i];
          selected[field.id] = {
            name: field.name,
            farm: { id: field.farmId, name: field.farm },
          };
        } else {
          enqueueSnackbar(`We currently only allow a maximum of ${maxAllowed} fields to be selected at a time. Please complete your reports for selected fields, then come back to select more.`);
          break;
        }
      }
      setChosenFields(fields);
      // Fields Maps is full object we created
      setFieldMaps(selected);
      // Selected fields is just a set of field IDs
      setSelectedFields(new Set(Object.keys(selected)));
      setAllSelected(true);
    } catch (err) {
      console.error(err);
      enqueueSnackbar('We encounted a problem selecting fields. Please try selecting your fields individually or contact us for assistance.');
    }
  };

  const deselectAllFields = () => {
    setChosenFields([]);
    setFieldMaps({});
    setSelectedFields(new Set());
    setAllSelected(false);
  };

  const removeField = (id) => {
    const fieldMapsCopy = { ...fieldMaps };
    delete fieldMapsCopy[id];
    setFieldMaps(fieldMapsCopy);

    const updatedChosen = chosenFields.filter((field) => field.id !== id);
    setChosenFields(updatedChosen);
    setSelectedFields(new Set(updatedChosen.map((field) => field.id)));

    // Reset field selection if there are no fields left to choose from
    if ( !(_.size(updatedChosen)) ) setAllSelected(false);
  };

  // ---- Data Fetchers ----

  /**
   * Gets the selected organization's farms, checks for existence, and formats them.
   * @param {String} org chosen organization
   * @param {String} clientId chosen client ID
   * @returns {Array} Array of farms
   */
  async function getFarms(org, clientId = null) {
    const orgsFarms = await fetchFarms(org.id, false, clientId, user?.token, impersonating, true);
    if (orgsFarms?.length) {
      return orgsFarms.map((farm) => ({
        org: org.name, orgId: org.id, id: farm.FarmID, name: farm.FarmName,
      }));
    }
    
    // Check whether it is undefined
    if (!orgsFarms) {
      console.error('An error occurred while getting farms for this organization.');
      enqueueSnackbar('No farms found for this organization');
      setError('No farms found for this organization. You might be logged out, please refresh the page and try again.');
    } else {
      // Response could just be an empty array - shouldn't really happen but...
      console.error('No farms were returned for this organization.');
      enqueueSnackbar('No farms found for this organization');
    }

    return [];
  }

  /**
   * Gets the selected farm's fields, checks for existence, and formats them.
   * @param {String} orgId chosen organization ID
   * @param {String} farmId chosen farm ID
   * @param {String} farm chosen farm name
   * @returns {Array} Fields
   */
  const getFields = async (orgId, farmId, farm) => {
    const farmsFields = await fetchFields(orgId, farmId, user?.token, impersonating);
    // console.log('farmsFields :>> ', farmsFields);
    if (farmsFields?.length) {
      const fieldsWithFarm = farmsFields.map((x) => ({
        farmId, farm, orgId, id: x.ID, name: x.Name,
      }));
      fieldsWithFarm.sort((a, b) => a.name.localeCompare(b.name));
      return fieldsWithFarm;
    }
    
    // Check whether it is undefined
    if (!farmsFields) {
      console.error('An error occurred while getting fields for this farm.');
      enqueueSnackbar('No fields found for this farm');
      setError('No fields found for this farm. You might be logged out, please refresh the page and try again.');
    } else {
      // Response could just be an empty array - shouldn't really happen but...
      console.error('No fields were returned for this farm.');
      enqueueSnackbar('No fields found for this farm');
    }
    return [];
  };

  // Display shown when all fields selected
  const fieldDisplay = () => {
    const farmMap = new Map();

    Object.values(chosenFields).forEach((field) => {
      if (farmMap.has(field.farm)) {
        // Already found farm
        farmMap.set(field.farm, [...farmMap.get(field.farm), { name: field.name, id: field.id }]);
      } else {
        // New farm
        farmMap.set(field.farm, [{ name: field.name, id: field.id }]);
      }
    });
    const display = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const [key, value] of farmMap.entries()) {
      display.push(
        <Box mt={0.5} key={key}>
          <Box className={classes.farmName}>
            {key}
          </Box>
          {value.map((field) => (
            <Box
              key={field.id}
              pr="4px"
              py="2px"
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              <Box className={classes.fieldName}>
                <FiberManualRecordRoundedIcon className={classes.dot} />
                <span>{field.name}</span>
              </Box>
              <IconButton
                color="primary"
                onClick={() => removeField(field.id)}
                style={{ padding: '0 0 2px 4px' }}
              >
                <HighlightOffIcon style={{ fontSize: '1rem' }} />
              </IconButton>
            </Box>
          ))}
          <Divider />
        </Box>,
      );
    }

    return (
      <Box
        className={classes.displayAll}
        maxHeight={height - 790}
        minHeight={chosenFields?.length > 1 ? 84 : 56}
      >
        { display }
      </Box>
    );
  };

  /**
   * Body of multi-selection screen. The autocompletes and the error message box are all here.
   * Separated for cleaner code.
   * @returns {JSX} Org/Farm/Field selections
   */
  const selectionBody = () => (
    <>
      <Box p={1}>
        <Box height={21} mb="4px" pl={0.5} display="flex">
          <span>Select Organization</span>
          {chosenOrg.id !== -1 && (
            <ToolTip
              text="Changing organization will reset your selected farms and fields."
              mobileDisplay={mobileDisplay}
            >
              <InfoOutlinedIcon className={classes.infoIcon} />
            </ToolTip>
          )}
        </Box>
        <Autocomplete
          className={classes.select}
          disableClearable
          filterSelectedOptions
          noOptionsText="All Organizations Selected"
          disabled={loading}
          options={organizations}
          value={chosenOrg}
          getOptionLabel={(option) => option.name}
          renderInput={(params) => (
            <TextField
              {...params}
              variant="outlined"
              placeholder="Select Organization"
            />
          )}
          onChange={(event, value) => { handleOrgChange(value); }}
        />
      </Box>

      { farms.length > 0 && (
        <Box p={1}>
          <Box height={21} mb="4px" pl={0.5} display="flex" justifyContent="space-between">
            <Box display="flex">
              <span>Select Farms</span>
              <ToolTip
                text="Select farms from dropdown for configuring your acreage report."
                mobileDisplay={mobileDisplay}
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </ToolTip>
            </Box>
            { !allFarmsSelected ? (
              <IconButton
                color="primary"
                onClick={selectAllFarms}
              >
                <DoneAllIcon fontSize="small" />
                <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Select All</span>
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={deselectAllFarms}
              >
                <SettingsBackupRestoreOutlinedIcon fontSize="small" />
                <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Reset Selected</span>
              </IconButton>
            )}
          </Box>

          <Autocomplete
            className={classes.select}
            multiple
            disableClearable
            disableCloseOnSelect
            filterSelectedOptions
            noOptionsText="All Farms Selected"
            open={farmSelectionOpen}
            onOpen={() => setFarmSelectionOpen(true)}
            onClose={() => setFarmSelectionOpen(false)}
            disabled={loading}
            options={farms}
            // groupBy={(option) => option.org}
            value={selectedFarms}
            getOptionLabel={(option) => option.name}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
              />
            )}
            renderTags={(value, getTagProps) => value.map((option, index) => (
              <Chip
                variant="outlined"
                label={option.name}
                {...getTagProps({ index })}
              />
            ))}
            onChange={(event, value) => { handleFarmChange(value); }}
          />
        </Box>
      )}

      { fields.length > 0 && (
        <Box p={1}>
          <Box height={21} mb="4px" pl={0.5} display="flex" justifyContent="space-between">
            <Box display="flex">
              <span style={{ whiteSpace: 'nowrap' }}>
                {`Select${allSelected ? 'ed' : ''} Fields`}
              </span>
              <ToolTip
                text={`Select fields to include in your acreage report. Choose the select all option to include up to ${maxAllowed} fields.`}
                mobileDisplay={mobileDisplay}
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </ToolTip>
            </Box>

            { !allSelected ? (
              <IconButton
                color="primary"
                onClick={selectAllFields}
              >
                <DoneAllIcon fontSize="small" />
                <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Select All</span>
              </IconButton>
            ) : (
              <IconButton
                color="primary"
                onClick={deselectAllFields}
              >
                <SettingsBackupRestoreOutlinedIcon fontSize="small" />
                <span style={{ fontSize: '.9rem', fontWeight: 500 }}>Reset Selected</span>
              </IconButton>
            )}

          </Box>
          { !allSelected ? (
            <Autocomplete
              className={classes.select}
              multiple
              disableClearable
              disableCloseOnSelect
              filterSelectedOptions
              noOptionsText="All Fields Selected"
              open={fieldSelectionOpen}
              onOpen={() => setFieldSelectionOpen(true)}
              onClose={() => setFieldSelectionOpen(false)}
              disabled={loading}
              options={fields}
              value={chosenFields}
              groupBy={(option) => option.farm}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="outlined"
                />
              )}
              renderTags={(value, getTagProps) => value.map((option, index) => (
                selectedFarms?.length > 1 ? (
                  <CustomToolTip key={option.farmId+option.id} title={`Farm: ${option?.farm}`} placement="top-end">
                    <Chip
                      variant="outlined"
                      label={option.name}
                      {...getTagProps({ index })}
                    />
                  </CustomToolTip>
                ) : (
                  <Chip
                    variant="outlined"
                    label={option.name}
                    {...getTagProps({ index })}
                  />
                )
              ))}
              onChange={(event, value) => { handleFieldChange(value); }}
            />
          ) : (
            fieldDisplay()
          )}
        </Box>
      )}

      {/* Show error message */}
      {!!error && (
        <Box m={1} width={260}>
          <Box className={classes.errorMessageBox}>
            {error}
          </Box>
        </Box>
      )}
    </>
  );

  /* ------ RETURN ------ */

  return (
    <Box className={classes.root}>
      { user.isAuthenticated && organizations?.length > 0 ? (
        selectionBody()
      ) : (
        <Box>
          <IntegrationLinks
            mobileView={false}
            signedIn={user.isAuthenticated}
            onConnect={() => setOpenIntegration(true)}
            signInFrom="SIGNINFROMACREAGE"
          />
        </Box>
      )}

      {loading && <SpinningLoader />}

      <IntegrateModal
        open={openIntegration}
        setOpen={setOpenIntegration}
      />
    </Box>
  );
}

FieldSelection.propTypes = {
  organizations: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  chosenOrg: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }).isRequired,
  setChosenOrg: PropTypes.func.isRequired,
  farmMaps: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setFarmMaps: PropTypes.func.isRequired,
  chosenFields: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired,
    }),
  ).isRequired,
  setChosenFields: PropTypes.func.isRequired,
  fieldMaps: PropTypes.shape({
    id: PropTypes.string,
  }).isRequired,
  setFieldMaps: PropTypes.func.isRequired,
  setSelectedFields: PropTypes.func.isRequired,
  impersonating: PropTypes.string.isRequired,
};

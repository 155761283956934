import React, { useEffect, useState } from "react";
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Radio,
  RadioGroup,
  FormControl,
  FormLabel,
  FormControlLabel,
  Checkbox,
  Divider,
  Slide,
  CircularProgress,
  Fade,
} from "@material-ui/core";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import { makeStyles } from "@material-ui/core/styles";
import { useSnackbar } from "notistack";
import { MenuProps } from "../../../styles/select";
import { blackText, grey, lightGrey, green } from "../../../styles/colors";
import { NdviMap } from "./ndviMap";
import * as df from "../../../utils/dataFetchers";
import { useWindowDimensions } from "../../../utils/dimensions";
import { exists, numFormat } from "../../../utils/helpers";
import { ComingSoon } from "../../Home/ComingSoon";
import { SpinningLoader } from "../../Shared/SpinningLoader";
import { GetUav } from "../../Shared/ServiceLinks";
import { CustomToolTip } from "../../../utils/customComponents";
import { ImageSwitch } from "../Shared/ImageSwitch";

const useStyles = makeStyles((theme) => ({
  selections: theme.selections,
  messageBox: theme.messageBox,
  errorMessageBox: theme.errorMessageBox,
  dateSelection: theme.dateSelection,
}));

/**
 * Display SSURGO map and table
 * @param {Object} field  Field to display data for
 * @param {Object} loading Loading status
 * @param {Function} getNDVI Get NDVI data
 * @param {String} image  Image for map
 * @param {Array} legend Legend data for table
 * @param {Array} extent Extent of image for map
 * @param {Object} attributes Data such as image data and min/max
 * @param {Number} mobileBreakPoint Pixel width side navigation changes to dropdown
 * @param {String} errorMessage Message to display to user if do not have data
 */
export function NDVI({
  field,
  loading,
  getNDVI,
  image,
  legend,
  extent,
  attributes,
  mobileBreakPoint,
  errorMessage,
}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  const bandNames = [
    { name: "Normalized Difference Vegetation Index (NDVI)", value: "NDVI" },
    { name: "Blue (0.45-0.51µm)", value: "Blue" },
    { name: "Green (0.53-0.59µm)", value: "Green" },
    { name: "Red (0.64-0.67µm)", value: "Red" },
    { name: "Coastal Aerosol (0.43-0.45µm)", value: "Coastal Aeorsal" },
    { name: "Near Infrared (0.76-0.90µm)", value: "NIR" },
    { name: "Near Infrared (.842 µm central)", value: "NIR_Broad" },
    { name: "Red Edge (.69 - .71µm)", value: "Red_Edge1" },
    { name: "Red Edge (0.73 – 0.75 µm)", value: "Red_Edge2" },
    { name: "Red Edge (0.77 – 0.79 µm)", value: "Red_Edge3" },
    { name: "Short-wave Infrared (1.57-1.65µm)", value: "SWIR1" },
    { name: "Short-wave Infrared 2 (2.08-2.35µm)", value: "SWIR2" },
    { name: "Quality Assessment", value: "QA" },
    { name: "Red-Green-Blue", value: "RGB" },
    { name: "Normalized Difference Water Index (NDWI)", value: "NDWI" },
    { name: "Normalized Difference Buildup Index (NDBI)", value: "NDBI" },
    { name: "Normalized Difference Tillage Index (NDTI)", value: "NDTI" },
    { name: "Urban Index (UI)", value: "UI" },
    { name: "Green Chlorophyll Vegetation Index (GCVI)", value: "GCVI" },
    { name: "MERIS-based Terrestrial Chlorophyll Index (MTCI)", value: "MTCI" },
    { name: "Normalized Difference Red Edge (NDRE)", value: "NDRE" },
    // {name: 'Traditional Color Infrared Image (CIR)', value: 'CIR'},
    // {name: 'Urban Environment (UE)', value: 'UE'},
    // {name: 'Land and Water (LW)', value: 'LW'},
    // {name: 'Atmospheric Penetration (AP)', value: 'AP'},
    // {name: 'Agriculture (AGR)', value: 'AGR'},
    // {name: 'Forest Fire Burn Scars (FFBS)', value: 'FFBS'},
    // {name: 'Bare Earth (BE)', value: 'BE'},
    // {name: 'Vegetation and Water (VW)', value: 'VW'}
  ];

  const bands = [
    "NDVI",
    "Blue",
    "Green",
    "Red",
    "Coastal Aeorsal",
    "NIR",
    "NIR_Broad",
    "Red_Edge1",
    "Red_Edge2",
    "Red_Edge3",
    "SWIR1",
    "SWIR2",
    "QA",
    "RGB",
    "NDWI",
    "NDBI",
    "NDTI",
    "UI",
    "GCVI",
    "MTCI",
    "NDRE",
    "CIR",
    "UE",
    "LW",
    "AP",
    "AGR",
    "FFBS",
    "BE",
    "VW",
  ];
  const legendScales = ["Relative", "Absolute"];

  const [band, setBand] = useState(bandNames[0].value);
  const [legendScale, setLegendScale] = useState(legendScales[0]);
  const [satellite, setSatellite] = useState("Landsat,Sentinel");
  const [selectedDate, setSelectedDate] = useState(new Date().toISOString());

  // checkboxes
  const [showLatest, setShowLatest] = useState(true);
  const [cloudFilter, setCloudFilter] = useState(true);
  const [interpolate, setInterpolate] = useState(false);

  const [showImagery, setShowImagery] = useState(true);

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };

  const handleSwitch = (checked) => {
    setShowImagery(checked);
  };

  const getData = async (
    date,
    satOption,
    latest,
    scale,
    clouds,
    interp,
    selectedBand
  ) => {
    const success = await getNDVI({
      date: date,
      satOption: satOption,
      latest: latest,
      scale: scale,
      clouds: clouds,
      interp: interp,
      selectedBand: selectedBand
    });
  };

  const handleOptionChange = (val, type) => {
    switch (type) {
      case "Date":
        setSelectedDate(val);
        getData(
          val,
          satellite,
          showLatest,
          legendScale,
          cloudFilter,
          interpolate,
          band
        );
        break;
      case "Satellite":
        setSatellite(val);
        getData(
          selectedDate,
          val,
          showLatest,
          legendScale,
          cloudFilter,
          interpolate,
          band
        );
        break;
      case "Latest":
        setShowLatest(val);
        getData(
          selectedDate,
          satellite,
          val,
          legendScale,
          cloudFilter,
          interpolate,
          band
        );
        break;
      case "Scale":
        setLegendScale(val);
        getData(
          selectedDate,
          satellite,
          showLatest,
          val,
          cloudFilter,
          interpolate,
          band
        );
        break;
      case "Cloud":
        setCloudFilter(val);
        getData(
          selectedDate,
          satellite,
          showLatest,
          legendScale,
          val,
          interpolate,
          band
        );
        break;
      case "Interpolate":
        setInterpolate(val);
        getData(
          selectedDate,
          satellite,
          showLatest,
          legendScale,
          cloudFilter,
          val,
          band
        );
        break;
      case "Band":
        setBand(val);
        getData(
          selectedDate,
          satellite,
          showLatest,
          legendScale,
          cloudFilter,
          interpolate,
          val
        );
        break;
    }
  };

  const createTable = () => (
    <Box
      m={1}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      style={{ minWidth: 360, maxWidth: "1000px", overflowX: "auto" }}
    >
      {attributes !== null && (
        <Box p={1} fontSize={16}>
          <Box>
            {"Image Date: "}
            {attributes.date}
          </Box>
          <Box>
            {"Average: "}
            {numFormat(attributes.average, 3, 3)}
          </Box>
          <Box>
            {"Low (5th Percentile): "}
            {numFormat(attributes.low, 3, 3)}
          </Box>
          <Box>
            {"High (95th Percentile): "}
            {numFormat(attributes.high, 3, 3)}
          </Box>
        </Box>
      )}

      <Divider />

      <Box p={1} display="flex" fontSize={16}>
        <Box style={{ width: "60%" }}>Range</Box>
        <Box style={{ width: "40%" }}>Area (%)</Box>
      </Box>

      <Divider style={{ color: grey, height: 4 }} />
      {legend.map((x, i) => createRow(x, i))}
      <Box p={1} display="flex" align="center">
        <FiberManualRecordIcon style={{ color: "#000000", marginRight: 8 }} />
        <Box>Cloud Cover</Box>
      </Box>
    </Box>
  );

  const createRow = (x, i) => {
    const percent = Number(x.Area.replace("%", ""));

    return (
      <Box key={i} p={1} display="flex" alignItems="center" borderBottom={1}>
        <Box style={{ width: "60%" }}>
          <Box display="flex" alignItems="center">
            <FiberManualRecordIcon style={{ color: x.color, marginRight: 8 }} />
            {numFormat(x.Min)}
            {" - "}
            {numFormat(x.Mean)}
          </Box>
        </Box>

        <Box style={{ width: "40%" }}>
          {percent}
          {"%"}
        </Box>
      </Box>
    );
  };

  return (
    <Slide direction="right" in style={{ width: "100%" }}>
      <Box color={blackText} fontWeight={500}>
        <Box mt={1} mx={1}>
          <Box fontSize={18}>Satellite Crop Health - NDVI data</Box>
          <Box>
            Harmonized Landsat - Sentinel Data. Please note, this might take
            some time to process the data.
          </Box>
          <Divider />
        </Box>

        <Box>
          <Box display="flex" flexWrap="wrap" fontSize={18}>
            <Box mx={1}>
              <Box>Band</Box>
              <Select
                variant="outlined"
                MenuProps={MenuProps}
                value={band}
                onChange={(e) => handleOptionChange(e.target.value, "Band")}
                style={{ width: 200, height: 40 }}
              >
                {bandNames.map((x, i) => (
                  <MenuItem key={i} value={x.value}>
                    {x.name}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box mx={1} style={{ width: 200 }}>
              <Box>Legend Scale</Box>
              <Select
                variant="outlined"
                MenuProps={MenuProps}
                value={legendScale}
                onChange={(e) => handleOptionChange(e.target.value, "Scale")}
                style={{ width: 200, height: 40 }}
              >
                {legendScales.map((x, i) => (
                  <MenuItem key={i} value={x}>
                    {x}
                  </MenuItem>
                ))}
              </Select>
            </Box>

            <Box mx={1}>
              <Box>Image Date</Box>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  className={classes.dateSelection}
                  disableToolbar
                  autoOk
                  variant="inline"
                  inputVariant="outlined"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="date-picker-inline"
                  InputAdornmentProps={{ position: "start" }}
                  value={selectedDate}
                  onChange={(date, val) => handleOptionChange(date, "Date")}
                  KeyboardButtonProps={{
                    "aria-label": "change date",
                  }}
                />
              </MuiPickersUtilsProvider>
            </Box>

            <Box display="flex" flexWrap="wrap">
              <Box mx={1}>
                <FormControl component="fieldset">
                  <Box>Satellite</Box>
                  <RadioGroup
                    aria-label="satellite"
                    name="satellite"
                    value={satellite}
                    onChange={(e) =>
                      handleOptionChange(e.target.value, "Satellite")
                    }
                    color="primary"
                    style={{ flexDirection: "row", fontWeight: 500 }}
                  >
                    <FormControlLabel
                      value="Landsat,Sentinel"
                      control={<Radio color="primary" />}
                      label="Landsat & Sentinel"
                    />
                    <FormControlLabel
                      value="Landsat"
                      control={<Radio color="primary" />}
                      label="Landsat"
                    />
                    <FormControlLabel
                      value="Sentinel"
                      control={<Radio color="primary" />}
                      label="Sentinel"
                    />
                  </RadioGroup>
                </FormControl>
              </Box>
              <Divider />
            </Box>
          </Box>

          <Box mt={0.5} display="flex" flexWrap="wrap">
            <Box display="flex" alignItems="center">
              <Checkbox
                color="primary"
                checked={showLatest}
                onChange={(e) => handleOptionChange(e.target.checked, "Latest")}
              />
              <Box>Show Latest Available Data</Box>
            </Box>

            <Divider orientation="vertical" />

            <Box display="flex" alignItems="center">
              <Checkbox
                color="primary"
                checked={cloudFilter}
                onChange={(e) => handleOptionChange(e.target.checked, "Cloud")}
              />
              <Box>Cloud Filter</Box>
            </Box>

            <Divider orientation="vertical" />

            <Box display="flex" alignItems="center">
              <Checkbox
                color="primary"
                checked={interpolate}
                onChange={(e) =>
                  handleOptionChange(e.target.checked, "Interpolate")
                }
              />
              <CustomToolTip
                title="This could take up to 30 seconds"
                placement="top"
              >
                <Box>Interpolate</Box>
              </CustomToolTip>
            </Box>

            <ImageSwitch checked={showImagery} handleSwitch={handleSwitch} />
          </Box>

          {errorMessage !== "" && (
            <Box m={1} display="flex">
              <Box className={classes.errorMessageBox} flexDirection="column">
                <Typography color="textPrimary" style={{ fontWeight: 500 }}>
                  The date you picked or location is not available.
                </Typography>
                <Typography color="textPrimary" style={{ fontWeight: 500 }}>
                  {
                    "Please try another date or see the geographic coverage for HLS here: "
                  }
                  <span>
                    <a
                      href="https://hls.gsfc.nasa.gov/test-sites/map/"
                      target="_blank"
                      style={{ color: green }}
                    >
                      https://hls.gsfc.nasa.gov/test-sites/map/
                    </a>
                  </span>
                </Typography>
              </Box>
            </Box>
          )}

          <Box display="flex" flexWrap="wrap">
            <Box
              m={1}
              display="flex"
              flexGrow={1}
              minWidth={
                width > 840
                  ? 600
                  : width > mobileBreakPoint
                  ? width - 240
                  : width - 30
              }
              borderRadius="borderRadius"
            >
              <NdviMap
                boundary={field.boundary}
                mapImage={image}
                mapExtents={extent}
                showImagery={showImagery}
                height={'100%'}
                width={'100%'}
                location={field.state}
              />
            </Box>

            <Box display="flex" minWidth={380} m={"0 auto"}>
              <Box>
                <Fade
                  in={legend.length > 0 && !loading}
                  mountOnEnter
                  unmountOnExit
                >
                  {createTable()}
                </Fade>
                {/* <Box p={1}>
                  <GetUav />
                </Box> */}
              </Box>
            </Box>
          </Box>
        </Box>
        {loading && <SpinningLoader />}
      </Box>
    </Slide>
  );
}

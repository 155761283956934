import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Modal, Typography, Divider } from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.modalPaper,
    margin: 'auto',
    zIndex: 1002,
    height: 'auto',
    width: '400px',
    borderRadius: '4px',
    padding: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  header: {
    ...theme.modalHead,
    fontSize: 18,
  },
  close: theme.close,
  root: theme.helpBox,
  link: theme.greenLink,
}));


export function InfoModal({open, setOpen, info}) {

  const classes = useStyles();

  const displayInfo = {
    'acreage': 'The ProfitLayers system automatically removes certain outliers and anomalies in your precision agriculture sensor readings that other platforms may ignore, and as a result may be slightly different when processed through from other systems. However, this will not affect the patterns in your ProfitLayers, and our research indicates that your ProfitLayers and the summaries will be most accurate when processed by our system using the methods established.',
  }

  return(
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="info-popup"
      aria-describedby="info-popup"
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        overflowY: 'auto'
      }}
    >
      <Box
        className={classes.paper}
        borderRadius="borderRadius"
        boxShadow={2}
      >
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpen(false)}
        />
        <Box className={classes.header}>
          Differences in Operation Averages of Acres
        </Box>

        <Divider style={{margin: '12px 0'}}/>

        <Box>
          {displayInfo[info]}
        </Box>
      </Box>
    </Modal>
  )
}

export function AcreageInfo({openModal}) {
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <HelpOutlineIcon fontSize="small"/>
      <Box ml={1}>
        {`"My Operation Averages or Acres seem different." `}
        <span
          className={classes.link}
          onClick={() => openModal(true)}>
          Click Here to see why.
        </span>
      </Box>
    </Box>
  )
}

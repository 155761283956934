/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  Box,
  Modal,
  Button,
  Select,
  MenuItem,
  TextField,
  Fade,
  FormControl,
  FormHelperText,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';

// Components
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { UpdateIntersectionMap } from '../../Maps/AcreageReporting/UpdateIntersection';

// Functions
import { numFormat, convertArrayToObject } from '../../../utils/helpers';
import { formatZoneForSave, parseDateObject } from '../helpers';
import { useWindowDimensions } from '../../../utils/dimensions';
import {
  acresModifiedReasons,
  geospatialShapeModifiedReasonCodes,
  plantingDateModifiedReasonCodes,
} from '../presetData';
import { saveAcreageZones, getNewZoneID } from '../../../utils/dataFetchers';

const useStyles = makeStyles((theme) => ({
  close: {
    ...theme.icon,
    marginTop: 12,
  },
  confirmation: {
    ...theme.centeredModal,
    border: `2px solid ${theme.palette.greys.dark}`,
    borderRadius: 8,
  },
  dateSelection: {
    margin: 4,
    '& .MuiInputBase-root': {
      padding: 4,
      '& .MuiButtonBase-root': {
        padding: 0,
        paddingLeft: 10,
      },
      '& .MuiInputBase-input': {
        padding: 10,
        paddingLeft: 0,
      },
    },
    width: 200,
  },
  details: theme.plDetails,
  display: theme.disabledInput,
  footer: theme.updateOperationFooter,
  head: theme.updateOperationHeader,
  label: {
    color: theme.palette.greys.dark,
    fontWeight: 500,
    fontSize: '0.875rem',
  },
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
  },
  required: {
    fontWeight: 500,
    color: 'red',
    fontSize: '1.2rem',
  },
  select: {
    margin: 4,
    width: 200,
    backgroundColor: '#ffffff',
  },
  texts: {
    margin: 4,
    // For some reason, these textfields, with width of 100%, do not respect allowed space (maximum width including padding) defined by parent component
    paddingRight: 10,
  },
}));

/**
 * Rendered in AcreageReporting/AcreageReporting.js
 * Modal for editing CLU intersection boundary
 * @param {Object} clu CLU information
 * @param {Object} cluFullBoundary CLU complete boundary
 * @param {Object} cluSeen Contains CLU data needed for save.
 * @param {Number} commodityYear Commodity year. Needed for save
 * @param {Object} commoditySeen crop type information
 * @param {Object} commodityTypesSeen intended use information
 * @param {Function} getCommodityTypes Called when crop changes
 * @param {Function} getIntendedUses Called when crop type changes
 * @param {Function} getIntersections Checks whether there are any intersections between passed boundaries
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Object} operation operation information
 * @param {Number} reinsuranceYear Reinsurance year. Needed for save
 * @param {Function} setCommoditiesSeen When necessary, update commodities
 * @param {Function} setCommodityTypesSeen When necessary, update commodity types
 * @param {Function} setClusSeen update seens clus
 * @param {Function} setOpen Sets if modal is open
 * @param {Object} statesSeen crop information
 * @param {Function} updateClu Updates CLU values with no sideeffects
 * @param {Function} updateOperationInMapping Updates state variable containing op data
 * @param {String} zoneType Type of zone. Needed for save
 * @return {JSX} Edit CLU intersection map with operation information
 */
export const EditMap = ({
  clu,
  cluFullBoundary,
  clusSeen,
  commodityYear,
  commoditiesSeen,
  commodityTypesSeen,
  getCommodityTypes,
  getIntendedUses,
  getIntersections,
  open,
  operation,
  reinsuranceYear,
  setCommoditiesSeen,
  setCommodityTypesSeen,
  setClusSeen,
  setOpen,
  statesSeen,
  updateClu,
  updateOperationInMapping,
  zoneType,
}) => {
  //#region - variables
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  const [loading, setLoading] = useState(false);
  const [openConfirmation, setOpenConfirmation] = useState(false);

  const [acres, setAcres] = useState(clu?.finalReportedAcreage);
  const [plantingDate, setPlantingDate] = useState(null);

  const [crop, setCrop] = useState(clu?.crop);
  const [cropType, setCropType] = useState(clu?.cropType);
  const [intendedUse, setIntendedUse] = useState(clu?.intendedUse);
  const [cropTypes, setCropTypes] = useState(commoditiesSeen[clu.cropTypes]);
  const [intendedUses, setIntendedUses] = useState(commodityTypesSeen[clu.intendedUses]);

  // Boundary displayed to user
  const [updatedBoundary, setUpdatedBoundary] = useState(JSON.parse(clu.boundary));

  const [acreageModifiedCode, setAcreageModifiedCode] = useState(clu?.reportedAcreageModifiedReasonCode ? clu.reportedAcreageModifiedReasonCode : '');
  const [boundaryModifiedCode, setBoundaryModifiedCode] = useState(clu?.geospatialShapeModifiedReasonCode ? clu.geospatialShapeModifiedReasonCode : '');
  const [dateModifiedCode, setDateModifiedCode] = useState(clu?.plantedDateModifiedReasonCode ? clu.plantedDateModifiedReasonCode : '');

  const [acreageModifiedNotes, setAcreageModifiedNotes] = useState(clu.reportedAcreageModifiedOtherReasonText ? clu.reportedAcreageModifiedOtherReasonText : '');
  const [boundaryModifiedNotes, setBoundaryModifiedNotes] = useState(clu?.geospatialShapeModifiedOtherReasonText ? clu.geospatialShapeModifiedOtherReasonText : '');
  const [dateModifiedNotes, setDateModifiedNotes] = useState(clu?.plantedDateModifiedOtherReasonText ? clu.plantedDateModifiedOtherReasonText : '');

  const [acreageModified, setAcreageModified] = useState(clu?.reportedAcreageModifiedIndicator === 'Y');
  const [boundaryModified, setBoundaryModified] = useState(clu?.geospatialShapeModifiedIndicator === 'Y');
  const [boundaryHasBeenReset, setBoundaryHasBeenReset] = useState(false);
  const [dateModified, setDateModified] = useState(clu?.plantedDateModifiedIndicator === 'Y');

  // Zone creation
  // We do not want to modify acreageModified or boundaryModified when zones are sliced as we do not know whether the boundary was actually modified. We will just keep track of their current state and keep it as that as that will be a better indicator of whether the boundary has been modified.
  const [intersectionZones, setIntersectionZones] = useState([]);

  const indexes = {
    farmIndex: operation.farmIndex,
    fieldIndex: operation.fieldIndex,
    opIndex: operation.opIndex,
    cluIndex: operation.cluIndex,
  };

  const [callHandleSave, setCallHandleSave] = useState(false);
  //#endregion

  //#region - useEffects
  // Move the day forward by one as KeyboardDatePicker will think it's smart as it changes it to previous day
  useEffect(() => {
    // To avoid the date changing (going back by one day if TimezoneOffset is negative, etc.) when changing to Date object, add time
    const changedDate = new Date(`${clu?.finalPlantedDate ? clu.finalPlantedDate : operation.finalPlantedDate} 00:00`);
    // Then make the time go the opposite way as KeyboardDatePicker will make it go back to local time
    const offset = new Date().getTimezoneOffset();
    changedDate.setMinutes(changedDate.getMinutes() + offset);
    setPlantingDate(changedDate);
  }, []);

  useEffect(() => {
    if (callHandleSave) {
      handleSave();
      setCallHandleSave(false);
    }
  }, [callHandleSave]);
  //#endregion

  //#region - handle boundary updates
  // Updates acres, boundary indicator, and actual boundary
  const handleBoundaryUpdate = (newAcres, newBoundary, modifying) => {
    if (modifying) {
      setAcres(newAcres);
      boundaryHasBeenModified(true);
      setUpdatedBoundary(newBoundary);
    } else {
      // Reverting back to original boundary and acres
      setAcres(clu.acres);
      const oldBoundary = JSON.parse(clu.originalBoundary);
      boundaryHasBeenModified(false, clu.acres, oldBoundary);

      // We want to reset to original, unmodified boundary.
      // This, however, will not reset to intermittent changes..
      setUpdatedBoundary(oldBoundary);
      if (clu.boundary !== clu.originalBoundary) {
        // If boundary has been modified in previously saved edit
        setBoundaryHasBeenReset(true);
      }
    }
  };

  // Decides how to update the state variables for showing acre and boundary modified reason dropdowns
  // Called with modified = false on "Reset" and "Edit->Cancel" clicks
  const boundaryHasBeenModified = (modified, modifiedAcres = null, oldBoundary = null) => {
    if (modified) {
      setAcreageModified(true);
      setBoundaryModified(true);
    } else {
      // If this boundary/zone has been sliced, never remove it's acreage or boundary modified indicators..
      if (intersectionZones.length || clu.displayNumber !== clu.clu_number) return;

      const acresToUse = modifiedAcres || acres;
      // First, check to confirm boundary and acres do in fact match original values
      if (Number(acresToUse.toFixed(2)) === clu.acres) {
        setAcreageModified(false);
      }

      const boundaryToUse = oldBoundary || updatedBoundary;
      // Yes, all those JSON methods are actualy necessary
      // Original string clu.boundary contains spaces, which parse removes
      // Could possible replace all spaces, but not 100% sure will catch every case
      const isSameBoundary = JSON.stringify(boundaryToUse) === JSON.stringify(JSON.parse(clu.originalBoundary));
      if (isSameBoundary) {
        setBoundaryModified(false);
      }
    }
  };
  //#endregion

  //#region - handle date changes
  const handleBlur = (e) => {
    setPlantingDate(e.target.value);

    const d = e.target.value.split('/');
    const newDate = `${d[2]}-${d[0]}-${d[1]}`;
    const originalDate = operation.date;

    if (newDate === originalDate) {
      setDateModified(false);
    } else {
      setDateModified(true);
    }
  };

  const dateChange = (d) => {
    const newDate = formatDate(d);
    const originalDate = operation.date;

    if (newDate === originalDate) {
      setDateModified(false);
    } else {
      setDateModified(true);
    }
  };

  const formatDate = (d) => {
    let month = `${d.getMonth() + 1}`;
    let day = `${d.getDate()}`;
    const year = d.getFullYear();

    if (month.length < 2) month = `0${month}`;
    if (day.length < 2) day = `0${day}`;

    return [year, month, day].join('-');
  };
  //#endregion

  //#region - handle dropdown updates
  const cropChange = async (newcrop) => {
    setCrop(newcrop);
    const commodityTypes = await getCommodityTypes(newcrop);
    if (commodityTypes) {
      setCropTypes(commodityTypes);
      cropTypeChange(newcrop, commodityTypes[0].commodityTypeCode);

      if (!Object.prototype.hasOwnProperty.call(commoditiesSeen, newcrop)) {
        setCommoditiesSeen((prev) => ({ ...prev, [newcrop]: commodityTypes }));
      }
    }
  };

  const cropTypeChange = async (newcrop, newtype) => {
    setCropType(newtype);
    const uses = await getIntendedUses(newcrop, newtype);
    if (uses) {
      setIntendedUses(uses);
      setIntendedUse(uses[0].intendedUseCode);
      const cropAndTypeCode = newcrop + newtype;

      if (!Object.prototype.hasOwnProperty.call(commodityTypesSeen, cropAndTypeCode)) {
        setCommodityTypesSeen((prev) => ({ ...prev, [cropAndTypeCode]: uses }));
      }
    }
  };

  /**
   * Takes updated value and state setter function. Checks note does not exceed max length.
   * If valid, update proper state
   * @param {String} val Value fron text field
   * @param {Function} update State setter
   * @return {Void} void
   */
  const handleNoteUpdate = (val, update) => {
    if (val.length >= 80) {
      enqueueSnackbar('Please limit your explanation to 80 characters', { preventDuplicate: true });
    } else {
      update(val);
    }
  };
  //#endregion

  //#region - handle saving modal data
  // For saving purposes, get all CLU data that the user can change in this modal
  // While there is no need to include all of this for update all the time, as most of the time some of these don't change;
  // to do that however, we would need another var to track which ones have changed... Prob faster to just call update function
  const getCluData = () => {
    try {
      // Format date as is expected for rest of the tool
      const formattedDate = parseDateObject(plantingDate);
      const updates = [
        { crop },
        { cropType },
        { cropTypes: crop },
        { intendedUse },
        { intendedUses: crop + cropType },
        { finalReportedAcreage: acres },
        { finalPlantedDate: formattedDate },
        { plantedDateModifiedIndicator: dateModified ? 'Y' : 'N' },
        { reportedAcreageModifiedIndicator: acreageModified ? 'Y' : 'N' },
        { geospatialShapeModifiedIndicator: boundaryModified ? 'Y' : 'N' },
      ];

      if (dateModified) {
        updates.push({ plantedDateModifiedReasonCode: dateModifiedCode });

        if (dateModifiedCode === 'O') {
          updates.push({ plantedDateModifiedOtherReasonText: dateModifiedNotes });
        }
      }

      if (acreageModified) {
        updates.push({ reportedAcreageModifiedReasonCode: acreageModifiedCode });

        if (acreageModifiedCode === 'O') {
          updates.push({ reportedAcreageModifiedOtherReasonText: acreageModifiedNotes });
        }
      }

      if (boundaryModified) {
        updates.push({ boundary: JSON.stringify(updatedBoundary) });
        updates.push({ geospatialShapeModifiedReasonCode: boundaryModifiedCode });
        // console.log('boundary modified');
        // Request review by default if boundary has been changed
        updates.push({ cluProducerReviewRequestIndicator: 'Y' });

        if (boundaryModifiedCode === 'O') {
          updates.push({ geospatialShapeModifiedOtherReasonText: boundaryModifiedNotes });
        }
      }

      // Special case - make sure to update boundary but only on save
      if (boundaryHasBeenReset) {
        updates.push({ boundary: JSON.stringify(updatedBoundary) });
      }

      return updates;
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  // Copy CLU, then update values that user can change in this modal
  const addUpdatedValues = (newClu, updates) => {
    try {
      const updatedClu = {
        ...newClu,
        crop: updates.crop,
        cropType: updates.cropType,
        cropTypes: updates.cropTypes,
        intendedUse: updates.intendedUse,
        intendedUses: updates.intendedUses,
        // Do not change finalReportedAcreage here
        finalPlantedDate: updates.finalPlantedDate,
        plantedDateModifiedIndicator: updates.plantedDateModifiedIndicator,
        reportedAcreageModifiedIndicator: updates.reportedAcreageModifiedIndicator,
        geospatialShapeModifiedIndicator: updates.geospatialShapeModifiedIndicator,
      };

      if (updates.plantedDateModifiedIndicator) {
        updatedClu.plantedDateModifiedReasonCode = updates.plantedDateModifiedReasonCode;
        updatedClu.plantedDateModifiedOtherReasonText = updates.plantedDateModifiedOtherReasonText;
      }

      if (updates.reportedAcreageModifiedIndicator) {
        updatedClu.reportedAcreageModifiedReasonCode = updates.reportedAcreageModifiedReasonCode;
        updatedClu.reportedAcreageModifiedOtherReasonText = updates.reportedAcreageModifiedOtherReasonText;
      }

      if (updates.geospatialShapeModifiedIndicator) {
        updatedClu.reportedAcreageModifiedReasonCode = updates.reportedAcreageModifiedReasonCode;
        updatedClu.geospatialShapeModifiedReasonCode = updates.geospatialShapeModifiedReasonCode;
        updatedClu.reportedAcreageModifiedOtherReasonText = updates.reportedAcreageModifiedOtherReasonText;
        updatedClu.geospatialShapeModifiedOtherReasonText = updates.geospatialShapeModifiedOtherReasonText;
        // Do not want to update the boundary from here
      }

      return updatedClu;
    } catch (err) {
      console.error(err);
      return newClu;
    }
  };

  // This is called after user tries to save
  const handleSave = async () => {
    try {
      setLoading(true);

      // Check if user is in process of boundary edit.
      const editing = document.getElementById('finish-edit-boundary');

      if (editing) {
        // Click finish edit
        editing.click();
        // Have a small timeout so that the process after clicking 'Finish Edit' can happen
        window.setTimeout(() => { setCallHandleSave(true); }, 10);
        return;
      }
      // If not editting, make sure that all required explanations were provided
      if (!explanationsProvided()) {
        enqueueSnackbar('Please provide required explanations for all changes');
        return;
      }

      if (intersectionZones.length) {
        // Create zones for this intersection
        const newClus = intersectionZones.map((zone) => (
          {
            // We want to be storing everything from CLU so all keys (dates, skip row info, etc.) are added here
            ...clu,
            acres: zone.properties.CALCACRES,
            boundary: JSON.stringify(zone.geometry),
          }
        ));
        // Sort the array so numbering follows acres order as it does for data gotten from DB
        newClus.sort((a,b) => b.acres - a.acres);
        // Access to all state variable with the optional user inputs
        const updates = convertArrayToObject(getCluData());
        const staticCluInfo = clusSeen[clu.clu_identifier];

        const clusToSave = [];
        const finalCLUS = [];
        // This doesn't allow anything outside of the full CLU boundary, so no sense in changing clusSeen
        let i = 1;
        for (const newClu of newClus) {
          // We want to assign a new ZoneID to all these
          const newZoneID = await getNewZoneID();
          newClu.zoneID = newZoneID.result;
          operation.zoneIDsFromDB.push(newZoneID.result);
          // Also updating final acreage and others for display purposes
          newClu.finalReportedAcreage = newClu.acres;
          newClu.displayNumber = `${clu.displayNumber}_${i}`;
          newClu.originalBoundary = newClu.boundary;

          const updatedClu = addUpdatedValues(newClu, updates);
          const cluForSave = formatZoneForSave(
            updatedClu,
            staticCluInfo,
            zoneType,
            commodityYear,
            reinsuranceYear,
            operation,
          );
          clusToSave.push(cluForSave);
          finalCLUS.push(updatedClu);

          i++;
        }

        // Add any remaining CLUs from the operation (except the CLU currently being editted)
        operation.CLUInfo.forEach((x) => {
          if (x.zoneID !== clu.zoneID) {
            const staticCluInfo = clusSeen[x.clu_identifier];
            const cluForSave = formatZoneForSave(
              x,
              staticCluInfo,
              zoneType,
              commodityYear,
              reinsuranceYear,
              operation,
            );

            clusToSave.push(cluForSave);
            finalCLUS.push(x);
          }
        });

        // await this save before closing modal
        const savedZones = await saveAcreageZones(operation.zoneIDsFromDB, clusToSave);
        // console.log('savedZones :>> ', savedZones);

        // make copy of operation, then update it and reset it
        const updatedOp = { ...operation };

        // Add and store this operation's updates 
        updatedOp.CLUInfo = finalCLUS;
        updatedOp.saved_at = new Date().toISOString().split('Z')[0];
        updateOperationInMapping(updatedOp);
        setOpen(false);
      } else {
        // Simply update current intersection
        const updates = getCluData();
        // console.log('updates :>> ', updates);
        updateClu(updates, indexes);
        setOpen(false);
      }

      setLoading(true);
    } catch (err) {
      enqueueSnackbar('Unable to save your changes. Please try again or contact us for assistance');
      setLoading(true);
    }
  };
  //#endregion

  //#region - JSX display
  // Shows whether an explanation has been provided for all the necessary fields
  const explanationsProvided = () => {
    if (acreageModified) {
      if (acreageModifiedCode === '') {
        // Acreage has been changed with no reason provided
        return false;
      }
      if (acreageModifiedCode === 'O' && acreageModifiedNotes === '') {
        // Reason 'other' has been given without an explanation
        return false;
      }
    }

    if (boundaryModified) {
      // Boundary has been changed with no reason provided
      if (boundaryModifiedCode === '') {
        return false;
      }
      if (boundaryModifiedCode === 'O' && boundaryModifiedNotes === '') {
        // Reason 'other' has been given without an explanation
        return false;
      }
    }

    if (dateModified) {
      // Boundary has been changed with no reason provided
      if (dateModifiedCode === '') {
        return false;
      }
      if (dateModifiedCode === 'O' && dateModifiedNotes === '') {
        // Reason 'other' has been given without an explanation
        return false;
      }
    }

    return true;
  };

  const operationInformation = () => (
    <Box pt={1} pl={1} display="flex" flexDirection="column">
      <Box display="flex" flexWrap="wrap">
        <Box p={1}>
          <Box className={classes.label}>
            Acres
          </Box>
          <Box className={classes.display}>
            {numFormat(acres)}
          </Box>
        </Box>

        { plantingDate
          && (
          <Box p={1}>
            <Box className={classes.label}>
              Planting Date
            </Box>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                className={classes.dateSelection}
                disableToolbar
                autoOk
                variant="inline"
                format="MM/dd/yyyy"
                margin="normal"
                inputVariant="outlined"
                value={plantingDate}
                onChange={(date) => {
                  setPlantingDate(date);
                }}
                onAccept={dateChange}
                onBlur={handleBlur}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
                inputProps={{
                  style: {
                    height: 24.6,
                  },
                }}
                InputAdornmentProps={{ position: 'start' }}
              />
            </MuiPickersUtilsProvider>
          </Box>
          )}
      </Box>

      <Box display="flex" flexWrap="wrap">
        <Box p={1}>
          <Box className={classes.label}>
            Crop
          </Box>
          <Select
            className={classes.select}
            variant="outlined"
            value={crop}
            onChange={(e) => cropChange(e.target.value)}
          >
            {
              statesSeen[operation.crops].map((x) => (
                <MenuItem
                  key={x.commodityCode}
                  value={x.commodityCode}
                >
                  {x.displayValue}
                </MenuItem>
              ))
            }
          </Select>
        </Box>

        <Box p={1}>
          <Box className={classes.label}>
            Crop Type
          </Box>
          <Select
            className={classes.select}
            variant="outlined"
            value={cropType}
            onChange={(e) => cropTypeChange(crop, e.target.value)}
          >
            {
              cropTypes.map((x) => (
                <MenuItem
                  key={x.commodityTypeCode}
                  value={x.commodityTypeCode}
                >
                  {x.displayValue}
                </MenuItem>
              ))
            }
          </Select>
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap">
        <Box p={1}>
          <Box className={classes.label}>
            Intended Use
          </Box>
          <Select
            className={classes.select}
            variant="outlined"
            value={intendedUse}
            onChange={(e) => setIntendedUse(e.target.value)}
          >
            {
              intendedUses.map((x) => (
                <MenuItem
                  key={x.intendedUseCode}
                  value={x.intendedUseCode}
                >
                  {x.displayValue}
                </MenuItem>
              ))
            }
          </Select>
        </Box>
      </Box>

      <Box>
        <Fade
          in={dateModified}
          mountOnEnter
          unmountOnExit
        >
          <Box p={1}>
            <Box className={classes.label}>
              Date Modified Reason *
            </Box>
            <FormControl error={dateModifiedCode === ''}>
              <Select
                id="date-modified-code"
                className={classes.select}
                variant="outlined"
                value={dateModifiedCode}
                onChange={(e) => setDateModifiedCode(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                {
                plantingDateModifiedReasonCodes.map((x) => (
                  <MenuItem
                    key={x.code}
                    value={x.code}
                  >
                    {x.display}
                  </MenuItem>
                ))
              }
              </Select>
              {dateModifiedCode === '' && (
                <FormHelperText>Please select a modification reason</FormHelperText>
              )}
            </FormControl>
          </Box>
        </Fade>
        <Fade
          in={dateModifiedCode === 'O'}
          mountOnEnter
          unmountOnExit
        >
          <Box p={1}>
            <Box className={classes.label}>
              Please provide notes on why this planting date was updated *
            </Box>
            <TextField
              id="date-modified-notes"
              className={classes.texts}
              variant="outlined"
              multiline
              rowsMax={8}
              value={dateModifiedNotes}
              onChange={(e) => handleNoteUpdate(e.target.value, setDateModifiedNotes)}
              style={{ width: '100%' }}
              inputProps={{ maxLength: 80 }}
              error={dateModifiedNotes === ''}
              helperText={dateModifiedNotes === '' && 'Please enter a modification reason'}
            />
          </Box>
        </Fade>
      </Box>

      <Box display="flex" flexWrap="wrap">
        <Fade
          in={acreageModified}
          mountOnEnter
          unmountOnExit
        >
          <Box p={1}>
            <Box className={classes.label}>
              Acres Modified Reason *
            </Box>
            <FormControl error={acreageModifiedCode === ''}>
              <Select
                id="acreage-modified-code"
                className={classes.select}
                variant="outlined"
                value={acreageModifiedCode}
                onChange={(e) => setAcreageModifiedCode(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                {
                  acresModifiedReasons.map((x) => (
                    <MenuItem
                      key={x.code}
                      value={x.code}
                    >
                      {x.display}
                    </MenuItem>
                  ))
                }
              </Select>
              {acreageModifiedCode === '' && (
                <FormHelperText>Please select a modification reason</FormHelperText>
              )}
            </FormControl>

          </Box>
        </Fade>
        <Fade
          in={boundaryModified}
          mountOnEnter
          unmountOnExit
        >
          <Box p={1}>
            <Box className={classes.label}>
              Boundary Modified Reason *
            </Box>
            <FormControl error={boundaryModifiedCode === ''}>
              <Select
                id="boundary-modified-code"
                className={classes.select}
                variant="outlined"
                value={boundaryModifiedCode}
                onChange={(e) => setBoundaryModifiedCode(e.target.value)}
                displayEmpty
              >
                <MenuItem value="">
                  <em>--Select--</em>
                </MenuItem>
                {
                geospatialShapeModifiedReasonCodes.map((x) => (
                  <MenuItem
                    key={x.code}
                    value={x.code}
                  >
                    {x.display}
                  </MenuItem>
                ))
              }
              </Select>
              {boundaryModifiedCode === '' && (
                <FormHelperText>Please select a modification reason</FormHelperText>
              )}
            </FormControl>

          </Box>
        </Fade>
      </Box>

      <Fade
        in={acreageModified && acreageModifiedCode === 'O'}
        mountOnEnter
        unmountOnExit
      >
        <Box p={1}>
          <Box className={classes.label}>
            Please provide notes on why this acreage was updated *
          </Box>
          <TextField
            id="acreage-modified-notes"
            className={classes.texts}
            variant="outlined"
            multiline
            rowsMax={2}
            value={acreageModifiedNotes}
            onChange={(e) => handleNoteUpdate(e.target.value, setAcreageModifiedNotes)}
            style={{ width: '100%' }}
            inputProps={{ maxLength: 80 }}
            error={acreageModifiedNotes === ''}
            helperText={acreageModifiedNotes === '' && 'Please enter a modification reason'}
          />
        </Box>
      </Fade>

      <Fade
        in={boundaryModified && boundaryModifiedCode === 'O'}
        mountOnEnter
        unmountOnExit
      >
        <Box p={1}>
          <Box className={classes.label}>
            Please provide notes on why this boundary was updated *
          </Box>
          <TextField
            id="boundary-modified-notes"
            className={classes.texts}
            variant="outlined"
            multiline
            rowsMax={8}
            value={boundaryModifiedNotes}
            onChange={(e) => handleNoteUpdate(e.target.value, setBoundaryModifiedNotes)}
            style={{ width: '100%' }}
            inputProps={{ maxLength: 80 }}
            error={boundaryModifiedNotes === ''}
            helperText={boundaryModifiedNotes === '' && 'Please enter a modification reason'}
          />
        </Box>
      </Fade>

    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="field-selection-instructions"
      aria-describedby="instructions-to-select-field-boundary-on-map"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
        display="flex"
        flexDirection="column"
        height={height - 60}
        width={width - 20}
        maxWidth={1800}
        maxHeight={height - 20}
      >
        <Box className={classes.head}>
          <Box p={2}>
            CLU -
            {' '}
            {clu?.displayNumber}
            {' '}
            Details
          </Box>

          <HighlightOffIcon
            className={classes.close}
            onClick={() => setOpenConfirmation(true)}
          />
        </Box>

        <Box display="flex" flexWrap="wrap" flexGrow={1}>

          { operationInformation() }

          <Box display="flex" flexGrow={1} minWidth={500}>
            <UpdateIntersectionMap
              boundaryHasBeenModified={boundaryHasBeenModified}
              clu={clu}
              cluFullBoundary={cluFullBoundary}
              getIntersections={getIntersections}
              handleBoundaryUpdate={handleBoundaryUpdate}
              setAcres={setAcres}
              setIntersectionZones={setIntersectionZones}
              operation={operation}
            />
          </Box>
        </Box>

        <Box className={classes.footer}>
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: '0 25px', backgroundColor: '#ffffff' }}
            onClick={() => setOpenConfirmation(true)}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
            disableElevation
          >
            Save
          </Button>
        </Box>
        <Modal
          open={openConfirmation}
          style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
        >
          <Box className={classes.confirmation}>
            <Box display="flex" justifyContent="flex-end">
              <HighlightOffIcon
                className={classes.close}
                style={{ marginTop: '4px' }}
                onClick={() => setOpenConfirmation(false)}
              />
            </Box>

            <Box px={1} fontSize="1.2rem" textAlign="center">
              Would you like to save your changes?
            </Box>
            <Box m={1} display="flex" flexWrap="wrap" justifyContent="center">
              <Button
                variant="outlined"
                color="primary"
                onClick={() => setOpen(false)}
                style={{ margin: 8 }}
              >
                Close Without Saving
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={() => handleSave()}
                style={{ margin: 8 }}
                disableElevation
              >
                Save Changes
              </Button>
            </Box>
          </Box>
        </Modal>
        
        {/* Loading Spinner */}
        {loading && <SpinningLoader />}
      </Box>
    </Modal>
  );
  //#endregion
};

EditMap.propTypes = {
  clu: PropTypes.shape({
    boundary: PropTypes.string.isRequired,
    originalBoundary: PropTypes.string.isRequired,
  }).isRequired,
  cluFullBoundary: PropTypes.string.isRequired,
  clusSeen: PropTypes.shape().isRequired,
  commoditiesSeen: PropTypes.shape().isRequired,
  commodityTypesSeen: PropTypes.shape().isRequired,
  commodityYear: PropTypes.number.isRequired,
  getCommodityTypes: PropTypes.func.isRequired,
  getIntendedUses: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  operation: PropTypes.shape({
    acres: PropTypes.number.isRequired,
    crop: PropTypes.string.isRequired,
    crops: PropTypes.string.isRequired,
    croppingPracticeCode: PropTypes.number.isRequired,
    cropRowCount: PropTypes.number,
    cropRowWidth: PropTypes.number,
    cropType: PropTypes.string.isRequired,
    cropTypes: PropTypes.string.isRequired,
    CLUInfo: PropTypes.arrayOf(PropTypes.shape({
      shown: PropTypes.bool.isRequired,
      zoneID: PropTypes.string.isRequired,
    })),
    cluProducerReviewRequestIndicator: PropTypes.string,
    error: PropTypes.oneOfType([
      PropTypes.array,
      PropTypes.string,
    ]),
    finalReportedAcreage: PropTypes.number,
    includeGeospatialInReport: PropTypes.string.isRequired,
    intendedUse: PropTypes.string.isRequired,
    intendedUses: PropTypes.string.isRequired,
    irrigationPracticeCode: PropTypes.string.isRequired,
    micsCode: PropTypes.string.isRequired,
    micsName: PropTypes.string.isRequired,
    operationID: PropTypes.string.isRequired,
    organicPracticeTypeCode: PropTypes.string.isRequired,
    productPlantingCode: PropTypes.string,
    reportedAcreageModifiedIndicator: PropTypes.string,
    sharePercentage: PropTypes.number.isRequired,
    shown: PropTypes.bool,
    skipRowConversionFactor: PropTypes.number,
    skipRowCount: PropTypes.number,
    skipRowWidth: PropTypes.number,
    skipRowPatternCode: PropTypes.string,
    stateCode: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    varietyList: PropTypes.string.isRequired,
    varietyNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
    year: PropTypes.number.isRequired,
    zoneID: PropTypes.string,
    zoneIDsFromDB: PropTypes.arrayOf(PropTypes.string.isRequired),
  }).isRequired,
  reinsuranceYear: PropTypes.number.isRequired,
  setCommodityTypesSeen: PropTypes.func.isRequired,
  setCommoditiesSeen: PropTypes.func.isRequired,
  setClusSeen: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  statesSeen: PropTypes.shape({
    stateAbbr: PropTypes.shape(),
  }).isRequired,
  updateClu: PropTypes.func.isRequired,
  updateOperationInMapping: PropTypes.func.isRequired,
  zoneType: PropTypes.string.isRequired,
};

import React, {useState, useEffect} from 'react';
import {
  Page, Text, View, Document, StyleSheet, Font, Image, Link
} from '@react-pdf/renderer';
import logo from '../../../images/AgAnalyticsLogo_Black_Inline_Lower_Powered1.png'
import pl_logo from '../../../images/ProfitLayers_Full_Gradient.png'
import { grey, blackText, darkText } from '../../../styles/colors'
import { numFormat, exists } from '../../../utils/helpers'
import * as turf from "@turf/turf";

Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf',
  crossorigin: 'anonymous'
});

const styles = StyleSheet.create({
  body: {
    paddingBottom: 65,
    fontFamily: 'Roboto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f3f3f3',
    height: '50px'
  },
  section: {
    display: 'flex',
    flexDirection: 'row'
  },
  legendText: {
    fontSize: 10,
    fontColor: blackText,
    fontWeight: 500,
  },
  label: {
    fontSize: 10,
    fontWeight: 700,
    color: '#b3b3b3',
  },
  value: {
    fontSize: 12,
    fontWeight: 800,
    color: '#777777',
    margin: '5px 0'
  },
  plLogo: {
    height: 'auto',
    width: '150px',
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
  footerLogo: {
    position: 'absolute',
    bottom: 30,
    left: 20,
    width: '91px',
    height: 'auto',
  },
  footerLink: {
    position: 'absolute',
    bottom: 35,
    left: 130,
    fontSize: 8,
  }
});

export const DataLayersPdf = ({
  field,
  notes,
  reportName,
  polarisMap,
  polarisAverage,
  polarisLegend,
  cropLandMap,
  cropLandLegend,
  soilDataMap,
  soilLegend,
  soilAverage,
  ndviMap,
  ndviLegend,
  ndviAttributes,
  topographyMap,
  elevationLegend,
  selectedElevation,
  gddChart,
  precipChart,
  sarMap,
  naipMap,
  naipYear,
  premiumGraph,
  whatIfAnalysis,
  insuranceAI
}) => {

  const [org, setOrg] = useState('')
  const [farm, setFarm] = useState('')
  const [name, setName] = useState('')

  useEffect(() => {
    setOrg(check(field.org))
    setFarm(check(field.farm))
    setName(check(field.name))
  }, [field])

  const check = (item) => {
    if (item !== undefined && item !== null) {
      return item
    } else {
      return ''
    }
  }

  function displayField() {
    return (
      <View
        style={{display: 'flex', flexDirection: 'row', padding: '5px 8px 0 8px'}}
      >
        <View style={{width: '150px'}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
            {org !== '' && 'ORGANIZATION'}
          </Text>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
            {org !== '' && org}
          </Text>
        </View>

        <View style={{width: '150px'}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
            {farm !== '' && 'FARM'}
          </Text>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
            {farm !== '' && farm}
          </Text>
        </View>
        <View style={{width: '150px'}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
            {name !== '' && 'FIELD'}
          </Text>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
            {name !== '' && name}
          </Text>
        </View>
      </View>
    )
  }

  function displayLocationData() {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <View
          style={{display: 'flex', flexDirection: 'row', padding: '5px 8px 0 8px'}}
        >
          <View style={{width: '150px'}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
              {'ACRES'}
            </Text>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
              {numFormat(field.acres)}
            </Text>
          </View>

          <View style={{width: '150px'}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
              {'LATITUDE'}
            </Text>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
              {numFormat(field.latitude)}
            </Text>
          </View>
          <View style={{width: '150px'}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
              {'LONGITUDE'}
            </Text>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
              {numFormat(field.longitude)}
            </Text>
          </View>
        </View>

        { field.state !== '' && (
          <View
            style={{display: 'flex', flexDirection: 'row', padding: '5px 8px 0 8px'}}
          >
            <View style={{width: '150px'}}>
              <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
                {'STATE'}
              </Text>
              <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
                {field.state}
              </Text>
            </View>

            <View style={{width: '150px'}}>
              <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
                {'COUNTY'}
              </Text>
              <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
                {field.county}
              </Text>
            </View>
          </View>
        )}


      </View>
    )
  }

  // Naip
  const displayNaipMap = (data, i) => {
    return (
      <View wrap={false} key={i}>
        <View
          style={{
            width: '100%',
            padding: '5px 8px',
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
            Aerial Imagery (NAIP)
          </Text>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
            Acquired by the National Agriculture Imagery Program during growing seasons across the continental U.S.
          </Text>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
            {`Imagery year: ${data.year}`}
          </Text>
        </View>

        {
          exists(data.image) && (
            <Image
              src={data.image}
              data-img={data.image}
              style={{height:400, width: 400, margin: '4px, 20px'}}
            />
          )
        }
      </View>
    )
  }

  // SAR
  const displaySarMap = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px 8px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Satellite Radar Imagery
        </Text>
      </View>

      {
        exists(sarMap) && (
          <Image
            src={sarMap}
            data-img={sarMap}
            style={{height:400, width: 400, margin: '4px, 20px'}}
          />
        )
      }
    </View>
  )

  // Polaris
  const displayPolarisMap = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px 8px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Polaris Soil Data
        </Text>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          Polaris is a recently developed soil data set that provides additional soil attributes
        </Text>
      </View>

      {
        polarisMap !== null && polarisMap !== '' && exists(polarisMap) && (
          <Image
            src={polarisMap}
            data-img={polarisMap}
            style={{height:400, width: 400, margin: '4px, 20px'}}
          />
        )
      }

      { displayPolarisLegend() }
    </View>
  )

  const displayPolarisLegend = () => {
    return (
      <View
        style={{
          margin: '0 20px',
          padding: 8,
          border: 1,
          borderColor: grey,
          borderRadius: 2,
          width: 400,
        }}
      >
        <Text
          style={{
            color: blackText,
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {`Average soil pH value in H2O: ${numFormat(polarisAverage)}`}
        </Text>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 8,
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <View style={{width: 140}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Soil pH in H2O
            </Text>
          </View>

          <View style={{width: 140}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Area per part (Acres)
            </Text>
          </View>

          <View style={{width: 100}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              % of Total
            </Text>
          </View>

        </View>

        {
          (
            polarisLegend !== null &&
            polarisLegend !== undefined &&
            polarisLegend.length > 0
          ) && (
            polarisLegend.map((x, i) => polarisRow(x, i))
        )}
      </View>
    )
  }

  const polarisRow = (x, i) => {
    const percent = Number(x.Area.replace('%', ''))

    return (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '4px 8px',
        }}
      >
        <View
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: x.color,
            borderRadius: 50,
            marginRight: 10,
          }}
        >
        </View>

        <View style={{width: 130}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {`${numFormat(x.Min)} - ${numFormat(x.Max)}`}
          </Text>
        </View>

        <View style={{width: 140}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {numFormat(field.acres * (percent / 100))}
          </Text>
        </View>

        <View style={{width: 100}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {`${percent}%`}
          </Text>
        </View>
      </View>
    )
  }

  //CropLand
  const displayCropLandMap = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px 8px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Crop Land Data
        </Text>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          Crop Land Data Layers, also known as CDLs, are published by USDA and provide estimates of historical crops cover.
        </Text>

      </View>

      {
        cropLandMap !== null && cropLandMap !== '' && exists(cropLandMap) && (
          <Image
            src={cropLandMap}
            data-img={cropLandMap}
            style={{height:400, width: 400, margin: '4px, 20px'}}
          />
        )
      }

      {
        (
          cropLandLegend !== null &&
          cropLandLegend !== undefined &&
          cropLandLegend.length > 0
        ) && (
          displayCropLandLegend()
      )}

    </View>
  )

  const displayCropLandLegend = () => {
    return (
      <View style={{
        margin: '0 20px',
        padding: 8,
        border: 1,
        borderColor: grey,
        borderRadius: 2,
        width: 400,
      }}>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 8,
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <View style={{width: 140}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Crop
            </Text>
          </View>

          <View style={{width: 140}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Area per part (Acres)
            </Text>
          </View>

          <View style={{width: 100}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              % of Total
            </Text>
          </View>
        </View>

        { cropLandLegend.map((x, i) => cropLandRow(x, i)) }

      </View>
    )
  }

  const cropLandRow =(x, i) => (
    <View
      key={i}
      style={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        padding: '4px 8px',
      }}
    >
      <View
        style={{
          width: '10px',
          height: '10px',
          backgroundColor: x.color,
          borderRadius: 50,
          marginRight: 10,
        }}
      >
      </View>

      <View style={{width: 130}}>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
          {x.CropName}
        </Text>
      </View>

      <View style={{width: 140}}>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
          {numFormat(x.Acres)}
        </Text>
      </View>

      <View style={{width: 100}}>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
          {x.Area}
        </Text>
      </View>

    </View>
  )

  //Soil Data
  const displaySoilMap = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px 8px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Soil Data
        </Text>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          SSURGO Soil type data from USDA-NRCS.
        </Text>
      </View>

      {
        soilDataMap !== null && soilDataMap !== '' && exists(soilDataMap) && (
          <Image
            src={soilDataMap}
            data-img={soilDataMap}
            style={{
              height: 340,
              width: 340,
              margin: '4px, 20px'
            }}
          />
        )
      }

        {
          (
            soilLegend !== null &&
            soilLegend !== undefined &&
            soilLegend.length > 0
          ) && (
            displaySoilLegend()
        )}
    </View>
  )

  const displaySoilLegend = () => {
    return (
      <View
        style={{
          margin: '0 20px',
          padding: 8,
          border: 1,
          borderColor: grey,
          borderRadius: 2,
          width: 560,
        }}
      >
        <Text
          style={{
            color: blackText,
            fontSize: 12,
            fontWeight: 500,
          }}
        >
          {`Average NCCPI value: ${numFormat(soilAverage)}`}
        </Text>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 8,
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <View style={{width: 260}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Soil Name
            </Text>
          </View>

          <View style={{width: 100}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Acres
            </Text>
          </View>

          <View style={{width: 100}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Percent
            </Text>
          </View>

          <View style={{width: 100}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              NCCPI
            </Text>
          </View>
        </View>

        { soilLegend.map((x, i) => soilRow(x, i)) }

      </View>
    )
  }

  const soilRow =(x, i) => {
    const acres = turf.convertArea(x.area, 'meters', 'acres')

    return (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '4px 8px',
        }}
      >
        <View
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: x.color,
            borderRadius: 50,
            marginRight: 10,
          }}
        >
        </View>

        <View style={{width: 250}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {x.Soil_Type}
          </Text>
        </View>

        <View style={{width: 100}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {numFormat(acres)}
          </Text>
        </View>

        <View style={{width: 100}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {`${numFormat((acres / field.acres) * 100)}%`}
          </Text>
        </View>

        <View style={{width: 100}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {numFormat(x.nccpi2all)}
          </Text>
        </View>

      </View>
    )
  }

  //NDVI Data
  const displayNdviMap = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px 8px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Satellite Crop Health - NDVI data
        </Text>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          Harmonized Landsat - Sentinel Data. Please note, this might take some time to process the data.
        </Text>
      </View>

      {
        ndviMap !== null && ndviMap !== '' && exists(ndviMap) && (
          <Image
            src={ndviMap}
            data-img={ndviMap}
            style={{
              height: 340,
              width: 340,
              margin: '4px, 20px'
            }}
          />
        )
      }

      {
        (
          ndviLegend !== null &&
          ndviLegend !== undefined &&
          ndviLegend.length > 0
        ) && (
          displayNdviLegend()
      )}

    </View>
  )

  const displayNdviLegend = () => {
    return (
      <View
        style={{
          margin: '0 20px',
          padding: 8,
          border: 1,
          borderColor: grey,
          borderRadius: 2,
          width: 560,
        }}
      >
        {
          ndviAttributes !== null && (
            <View>
              <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
                {`Image Date: ${ndviAttributes.date}`}
              </Text>
              <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
                {`Average: ${numFormat(ndviAttributes.average, 3, 3)}`}
              </Text>
              <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
                {`Low (5th Percentile): ${numFormat(ndviAttributes.low, 3, 3)}`}
              </Text>
              <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
                {`High (95th Percentile): ${numFormat(ndviAttributes.high, 3, 3)}`}
              </Text>
            </View>
          )
        }

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 8,
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <View style={{width: 260}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Range
            </Text>
          </View>

          <View style={{width: 100}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              Area (%)
            </Text>
          </View>

        </View>

        { ndviLegend.map((x, i) => ndviRow(x, i)) }

      </View>
    )
  }

  const ndviRow =(x, i) => {
    return (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '4px 8px',
        }}
      >
        <View
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: x.color,
            borderRadius: 50,
            marginRight: 10,
          }}
        >
        </View>

        <View style={{width: 250}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {`${numFormat(x.Min)} - ${numFormat(x.Mean)}`}
          </Text>
        </View>

        <View style={{width: 100}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {x.Area}
          </Text>
        </View>

      </View>
    )
  }

  const displayTopographyMap = (topography, i) => (
    <View key={i} wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px 8px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Topography and Slope - {topography.name}
        </Text>
      </View>

      {
        topography.image !== null && topography.image !== '' && exists(topography.image) &&(
          <Image
            src={topography.image !== null ? topography.image : ''}
            data-img={topography.image  !== null ? topography.image  : ''}
            style={{
              height: 340,
              width: 340,
              margin: '4px, 20px'
            }}
          />
        )
      }

      {
        (
          topography.legend !== null &&
          topography.legend !== undefined &&
          topography.legend.length > 0
        ) && (
          displayTopographyLegend(topography.legend, topography.unit, topography.name)
      )}

    </View>
  )

  const displayTopographyLegend = (elevationLegend, unit, name) => {
    let roundTo = name === 'Elevation Map' ? 2 : 5

    return (
      <View
        style={{
          margin: '0 20px',
          padding: 8,
          border: 1,
          borderColor: grey,
          borderRadius: 2,
          width: 300,
        }}
      >

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            padding: 8,
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <View style={{width: 200}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              {unit}
            </Text>
          </View>

          <View style={{width: 100}}>
            <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
              % of Total
            </Text>
          </View>

        </View>

        { elevationLegend.map((x, i) => topographyRow(x, i, roundTo)) }

      </View>
    )
  }

  const topographyRow = (x, i, roundTo) => {
    return (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '4px 8px',
        }}
      >
        <View
          style={{
            width: '10px',
            height: '10px',
            backgroundColor: x.color,
            borderRadius: 50,
            marginRight: 10,
          }}
        >
        </View>

        <View style={{width: 180}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {` ${numFormat(x.min, 0, roundTo)} - ${numFormat(x.max, 0, roundTo)}`}
          </Text>
        </View>

        <View style={{width: 100}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText}}>
            {x.area.replace(/\s/g, "")}
          </Text>
        </View>

      </View>
    )
  }

  const growingDaysDisplay = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Growing Degree Days
        </Text>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          Growing degrees days are the cumulative sum of degrees above a specified cutoff value. Default is 50°F, possible values include 32°F and 60°F. The more growing degree days, the hotter the growing season. This graph shows accumulation to date compared to historical norms and is updated daily. Source data obtained from PRISM Climate Group, Oregon State University, http://prism.oregonstate.edu, tabulation created now. Non-Commercial Use Only.
        </Text>
      </View>

      {
        gddChart !== null && gddChart !== '' && exists(gddChart) && (
          <Image
            src={gddChart}
            data-img={gddChart}
            style={{
              height: 500,
              width: 500,
              margin: '4px, 12px'
            }}
          />
        )
      }

    </View>
  )

  const precipDisplay = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Growing Degree Days
        </Text>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          Growing degrees days are the cumulative sum of degrees above a specified cutoff value. Default is 50°F, possible values include 32°F and 60°F. The more growing degree days, the hotter the growing season. This graph shows accumulation to date compared to historical norms and is updated daily. Source data obtained from PRISM Climate Group, Oregon State University, http://prism.oregonstate.edu, tabulation created now. Non-Commercial Use Only.
        </Text>
      </View>

      {
        precipChart !== null && precipChart !== '' && exists(precipChart) && (
          <Image
            src={precipChart !== null ? precipChart : ''}
            data-img={precipChart  !== null ? precipChart  : ''}
            style={{
              height: 500,
              width: 500,
              margin: '4px, 12px'
            }}
          />
        )
      }

    </View>
  )

  const whatIfAnalysisDisplay = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          What-If Analysis
        </Text>

        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          Enter hypothetical end of season results to see the resulting insurance payout.
          The "Net Payment" graph is the loss payment you would recieve, minus what you paid in premium.
          The "Liability" graph shows the maximum you would be paid in the event of total loss.
        </Text>
      </View>

      {
        whatIfAnalysis !== null && whatIfAnalysis !== '' && exists(whatIfAnalysis) && (
          <Image
            src={whatIfAnalysis !== null ? whatIfAnalysis : ''}
            data-img={whatIfAnalysis  !== null ? whatIfAnalysis  : ''}
            style={{
              height: 500,
              width: 500,
              margin: '4px, 12px'
            }}
          />
        )
      }
    </View>
  )

  const premiumCalculatorDisplay = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Premium Calculator
        </Text>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          This tool provides real-time premium costs and prices. This information is a premium estimate only, and is only for educational purposes.
          You must refer to an authorized crop insurance agent for official quote(s) and specific information regarding insurance coverage, actuarial information, conditions and exclusions.
        </Text>
      </View>

      {
        premiumGraph !== null && premiumGraph !== '' && exists(premiumGraph) && (
          <Image
            src={premiumGraph !== null ? premiumGraph : ''}
            data-img={premiumGraph  !== null ? premiumGraph  : ''}
            style={{
              height: 500,
              width: 500,
              margin: '4px, 12px'
            }}
          />
        )
      }
    </View>
  )

  const insuranceAIDisplay = () => (
    <View wrap={false}>
      <View
        style={{
          width: '100%',
          padding: '5px',
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
          Insurance AI
        </Text>
        <Text style={{fontWeight: 500, fontSize: 10, color: blackText, marginTop: 2}}>
          This tool factors in your fields, soil, climate and location to create a customized yield risk model, estimates your likelihood of insurance payments for different types of insurance, and compares it to the cost of the insurance.
          Enter your own yield information in the "Advanced" Tab to refine your estimates. This tool assumes Optional Units and works for Corn (Grain) and Soybeans in select counties.
        </Text>
      </View>

      {
        insuranceAI !== null && insuranceAI !== '' && exists(insuranceAI) && (
          <Image
            src={insuranceAI !== null ? insuranceAI : ''}
            data-img={insuranceAI  !== null ? insuranceAI  : ''}
            style={{
              height: 500,
              width: 500,
              margin: '4px, 12px'
            }}
          />
        )
      }
    </View>
  )

  return (
     <Document>
      <Page wrap size='A4' style={styles.body}>

        <View style={styles.header} fixed>
          <View style={{width: '200px'}}>

          </View>
          <View style={{width: '200px'}}>
            { pl_logo !== null && exists(pl_logo) && <Image src={pl_logo} data-img={pl_logo} style={styles.plLogo} />}
          </View>
          <View style={{width: '200px'}}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 800,
                color: blackText,
                paddingLeft: 40,
              }}
            >
              { reportName }
            </Text>
          </View>
        </View>

        { displayField() }

        { displayLocationData() }

        {
          ( polarisMap !== null &&
            polarisMap !== undefined &&
            polarisMap !== '') && (
            displayPolarisMap()
        )}

        {
          ( soilDataMap !== null &&
            soilDataMap !== undefined &&
            soilDataMap !== '') && (
            displaySoilMap()
        )}

        {
          ( topographyMap !== null &&
            topographyMap !== undefined &&
            topographyMap !== ''
          ) && (
            topographyMap.map((x, i) => displayTopographyMap(x, i))
        )}

        {
          ( naipMap !== null &&
            naipMap !== undefined &&
            naipMap !== '') && (
            naipMap.map((x, i) => displayNaipMap(x, i))
        )}

        {
          ( ndviMap !== null &&
            ndviMap !== undefined &&
            ndviMap !== ''
          ) && (
            displayNdviMap()
        )}

        {
          ( cropLandMap !== null &&
            cropLandMap !== undefined &&
            cropLandMap !== '') && (
            displayCropLandMap()
        )}

        {
          ( precipChart !== null &&
            precipChart !== undefined &&
            precipChart !== ''
          ) && (
            growingDaysDisplay()
        )}

        {
          ( gddChart !== null &&
            gddChart !== undefined &&
            gddChart !== ''
          ) && (
            precipDisplay()
        )}

        {
          ( sarMap !== null &&
            sarMap !== undefined &&
            sarMap !== '') && (
            displaySarMap()
        )}

        {
          ( whatIfAnalysis !== null &&
            whatIfAnalysis !== undefined &&
            whatIfAnalysis !== '') && (
            whatIfAnalysisDisplay()
        )}

        {
          ( premiumGraph !== null &&
            premiumGraph !== undefined &&
            premiumGraph !== '') && (
            premiumCalculatorDisplay()
        )}

        {
          ( insuranceAI !== null &&
            insuranceAI !== undefined &&
            insuranceAI !== '') && (
            insuranceAIDisplay()
        )}

        { notes !== '' && (
          <View
            style={{
              margin: '0 20px',
              padding: 8,
              border: 1,
              borderColor: grey,
              borderRadius: 2,
              width: 400,
            }}
            wrap={false}
          >
            <Text
              style={{fontWeight: 500, fontSize: 12, color: darkText}}
              >
              {'Notes: '}
            </Text>
            <Text
              style={{fontWeight: 500, fontSize: 10, color: blackText}}
            >
              {notes}
            </Text>
          </View>
        )}

        { logo !== null && exists(logo) && (
           <Image src={logo} data-img={logo} style={styles.footerLogo} fixed />
        )}

        <Link style={styles.footerLink} src={'https://profit.ag'} fixed>
          profit.ag
        </Link>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />

      </Page>
     </Document>
  )
}

import { cloneDeep } from "lodash";

export const updateEditedZones = (editedZones, operations) => {
  // make a clone to to update
  const operationsClone = cloneDeep(operations)
  for (const operation of operationsClone) {
    let matchingEditedZone = editedZones.filter(
      x => x.zone.properties.SECTIONID === operation.zone.properties.SECTIONID)
    if (matchingEditedZone.length > 0) {
      operation.zone = matchingEditedZone[0].zone
      operation.area = matchingEditedZone[0].acres
    }
  }
  return operationsClone
}

export const addZoneColor = (zoneColors, usedZoneColors) => {
  const unusedColors = zoneColors.filter(x => !usedZoneColors.includes(x))
  console.log(unusedColors)
  return unusedColors[0]
}

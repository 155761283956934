import React from "react";
import { withStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { COLORS } from "../../../constants";

const PopularComparisonChip = withStyles((theme) => ({
  root: {
    backgroundColor: COLORS.gray,
    color: COLORS.white,
    //marginBottom: theme.spacing(1),
    //marginLeft: theme.spacing(1),
    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
  },
}))((props) => <Chip {...props} />);

export default PopularComparisonChip;

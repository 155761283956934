/**
 * Date: 04/06/2021
 * Most of this data was taken directly from the ACRSI CART Schema document. 
 * Might need to update this file, have different versions of it, or move some of these to a database if the sources change.
 */

import { NumberFormatCustom, NumberFormatZero } from '../../utils/NumberFormatCustom';


export const cartDropdownData = {
  croppingPractice: {
    label: 'Cropping Practice',
    updateKey: 'croppingPracticeCode',
    options: [
      { code: 4, display: 'Continuous Cropping' },
      { code: 5, display: 'Summerfallow' },
      { code: 6, display: 'Water Fallow' },
      { code: 12, display: 'Spring' },
      { code: 19, display: 'Following Another Crop' },
      { code: 20, display: 'Fall Direct Seeded' },
      { code: 21, display: 'Fall' },
      { code: 25, display: 'Fall Transplanted' },
      { code: 28, display: 'Winter Direct Seeded' },
      { code: 29, display: 'Winter Transplanted' },
      { code: 30, display: 'Fall Planted' },
      { code: 31, display: 'Spring Direct Seeded' },
      { code: 33, display: 'Spring Transplanted' },
      { code: 37, display: 'Following Another Crop (Skip row)' },
      { code: 40, display: 'Natural' },
      { code: 41, display: 'Not Following Another Crop (Skip Row)' },
      { code: 43, display: 'Skip Row' },
      { code: 46, display: 'Spring Planted' },
      { code: 53, display: 'Tray Dried' },
      { code: 57, display: 'With Frost Protection' },
      { code: 59, display: 'Without Frost Protection' },
      { code: 60, display: 'Sprinkler Irrigated' },
      { code: 65, display: 'Standard Density' },
      { code: 66, display: 'High Density' },
      { code: 67, display: 'Super High Density' },
      { code: 68, display: 'Container' },
      { code: 81, display: 'Fall Planted Early Season' },
      { code: 82, display: 'Fall Planted Mid Season' },
      { code: 83, display: 'Fall Planted Late Season' },
      { code: 84, display: 'Spring Planted Early Season' },
      { code: 85, display: 'Spring Planted Late Season' },
      { code: 86, display: 'Winter Planted Early Season' },
      { code: 87, display: 'Winter Planted Late Season' },
      { code: 108, display: 'Non-Conventional' },
      { code: 120, display: 'Not Following Another Crop' },
      { code: 210, display: 'Standard Planting' },
      { code: 211, display: 'Winter Planted' },
      { code: 212, display: 'Summer Planted' },
      { code: 220, display: 'Interplanting' },
      { code: 703, display: 'Early Spring Planted' },
      { code: 997, display: 'No Cropping Practice Specified' },
    ],
  },
  micsCodes: {
    label: 'MICS Code',
    updateKey: 'micsCode',
    options: [
      { code: 'AG', display: 'AGCO' },
      { code: 'AL', display: 'AgLeader' },
      { code: 'CNH', display: 'Case IH/Ford/New Holland' },
      { code: 'FS', display: 'FSA' },
      { code: 'JD', display: 'John Deere' },
      { code: 'PP', display: 'Precision Planting' },
      { code: 'RA', display: 'Raven' },
      { code: 'TR', display: 'Trimble' },
      { code: 'O', display: 'Other' },
      { code: 'UN', display: 'Unknown' },
    ],
  },
  organicPracticeType: {
    label: 'Organic Status',
    updateKey: 'organicPracticeTypeCode',
    options: [
      { code: 'N', display: 'Not Specified (Conventional)' },
      { code: 'OC', display: 'Organic (USDA Certified)' },
      { code: 'OT', display: 'Organic (Transitional) ' },
    ],
  },
  plantingCodes: {
    label: 'Planting Code',
    updateKey: 'productPlantingCode',
    options: [
      { code: '', display: 'N/A' },
      { code: 'F', display: 'Failed Acreage' },
      { code: 'P', display: 'Prevented Planting' },
      { code: 'V', display: 'Volunteer' },
    ],
  },
  skipRowPatternCodes: {
    label: 'Skip Row Pattern Code',
    updateKey: 'skipRowPatternCode',
    options: [
      { code: '', display: 'N/A' },
      { code: '01', display: '1 planted 1 skipped - 30 in' },
      { code: '02', display: '1 planted 1 skipped - 32 in' },
      { code: '03', display: '1 planted 1 skipped - 36 in' },
      { code: '11', display: '1 planted 1 skipped - 40 in' },
      { code: '21', display: '2 planted 1 skipped - 30 to 40 in' },
      { code: '22', display: '2 planted 2 skipped - 30 to 40 in' },
      { code: '31', display: '3 planted 1 skipped - 30 to 40 in' },
      { code: '32', display: '3 planted 2 skipped - 30 to 40 in' },
      { code: '41', display: '4 planted 1 skipped - 30 to 40 in' },
      { code: '42', display: '4 planted 2 skipped - 30 to 40 in' },
      { code: '44', display: '4 planted 4 skipped - 30 to 40 in' },
      { code: '51', display: '5 planted 1 skipped - 30 to 40 in' },
      { code: '52', display: '5 planted 2 skipped - 30 to 40 in' },
      { code: '61', display: '6 planted 1 skipped - 30 to 40 in' },
      { code: '62', display: '6 planted 2 skipped - 30 to 40 in' },
      { code: '71', display: '7 planted 1 skipped - 30 to 40 in' },
      { code: '72', display: '7 planted 2 skipped - 30 to 40 in' },
      { code: '81', display: '8 planted 1 skipped - 30 to 40 in' },
      { code: '82', display: '8 planted 2 skipped - 30 to 40 in' },
      { code: '99', display: 'Other patterns' },
    ],
    specificWidths: new Set(['01', '02', '03', '11']),
    mappings: {
      '01': { cropRowCount: 1, cropRowWidth: 30, skipRowCount: 1, skipRowWidth: 30, skipRowConversionFactor: 66.67 },
      '02': { cropRowCount: 1, cropRowWidth: 32, skipRowCount: 1, skipRowWidth: 32, skipRowConversionFactor: 62.50 },
      '03': { cropRowCount: 1, cropRowWidth: 36, skipRowCount: 1, skipRowWidth: 36, skipRowConversionFactor: 55.56 },
      '11': { cropRowCount: 1, cropRowWidth: 40, skipRowCount: 1, skipRowWidth: 40, skipRowConversionFactor: 50.00 },
      '21': { cropRowCount: 2, cropRowWidth: 35, skipRowCount: 1, skipRowWidth: 35, skipRowConversionFactor: 66.67 },
      '22': { cropRowCount: 2, cropRowWidth: 35, skipRowCount: 2, skipRowWidth: 35, skipRowConversionFactor: 50.00 },
      '31': { cropRowCount: 3, cropRowWidth: 35, skipRowCount: 1, skipRowWidth: 35, skipRowConversionFactor: 75.00 },
      '32': { cropRowCount: 3, cropRowWidth: 35, skipRowCount: 2, skipRowWidth: 35, skipRowConversionFactor: 60.00 },
      '41': { cropRowCount: 4, cropRowWidth: 35, skipRowCount: 1, skipRowWidth: 35, skipRowConversionFactor: 80.00 },
      '42': { cropRowCount: 4, cropRowWidth: 35, skipRowCount: 2, skipRowWidth: 35, skipRowConversionFactor: 66.67 },
      '44': { cropRowCount: 4, cropRowWidth: 35, skipRowCount: 4, skipRowWidth: 35, skipRowConversionFactor: 50.00 },
      '51': { cropRowCount: 5, cropRowWidth: 35, skipRowCount: 1, skipRowWidth: 35, skipRowConversionFactor: 83.33 },
      '52': { cropRowCount: 5, cropRowWidth: 35, skipRowCount: 2, skipRowWidth: 35, skipRowConversionFactor: 71.43 },
      '61': { cropRowCount: 6, cropRowWidth: 35, skipRowCount: 1, skipRowWidth: 35, skipRowConversionFactor: 85.71 },
      '62': { cropRowCount: 6, cropRowWidth: 35, skipRowCount: 2, skipRowWidth: 35, skipRowConversionFactor: 75.00 },
      '71': { cropRowCount: 7, cropRowWidth: 35, skipRowCount: 1, skipRowWidth: 35, skipRowConversionFactor: 87.50 },
      '72': { cropRowCount: 7, cropRowWidth: 35, skipRowCount: 2, skipRowWidth: 35, skipRowConversionFactor: 77.77 },
      '81': { cropRowCount: 8, cropRowWidth: 35, skipRowCount: 1, skipRowWidth: 35, skipRowConversionFactor: 88.89 },
      '82': { cropRowCount: 8, cropRowWidth: 35, skipRowCount: 2, skipRowWidth: 35, skipRowConversionFactor: 80.00 },
    },
  },
};

export const additionalTextInputs = {
  micsName: {
    label: 'MICS Name',
    updateKey: 'micsName',
    description: 'Name of the Mobile Implement Control System (MICS) used in the collection of geospatial data',
    inputComponent: 'input',
  },
  skipRowCount: {
    label: 'Skip Row Count',
    updateKey: 'skipRowCount',
    description: 'Number of non-crop (idle) rows in the skip-row planting pattern',
    allowedValues: { min: 1, max: 999999 },
    inputComponent: NumberFormatZero,
  },
  skipRowWidth: {
    label: 'Skip Row Width',
    updateKey: 'skipRowWidth',
    description: 'Width in inches of the non-crop rows in the skip-row planting pattern',
    allowedValues: { min: 1, max: 999999 },
    restrictedValues: { min: 30, max: 40 },
    exception: true,
    endAdornment: 'in',
    inputComponent: NumberFormatZero,
  },
  skipRowConversionFactor: {
    label: 'Skip Row Conversion Factor',
    updateKey: 'skipRowConversionFactor',
    description: 'Converts total acreage to percentage of land devoted to the crop in the skip-row planting pattern',
    allowedValues: { min: 0.01, max: 99.99 },
    endAdornment: '%',
    inputComponent: NumberFormatCustom,
  },
  cropRowCount: {
    label: 'Crop Row Count',
    updateKey: 'cropRowCount',
    description: 'Number of crop rows in the skip-row planting pattern',
    allowedValues: { min: 1, max: 999999 },
    inputComponent: NumberFormatZero,
  },
  cropRowWidth: {
    label: 'Crop Row Width',
    updateKey: 'cropRowWidth',
    description: 'Width in inches of the crop rows in the skip-row planting pattern',
    allowedValues: { min: 1, max: 999999 },
    restrictedValues: { min: 30, max: 40 },
    exception: true,
    endAdornment: 'in',
    inputComponent: NumberFormatZero,
  },
};

export const radioInputs = {
  irrigationCodes: {
    title: 'Irrigation Status',
    updateKey: 'irrigationPracticeCode',
    options: [ 
      {code: 'I', display: 'Irrigated'},
      {code: 'N', display: 'Non-Irrigated'},
    ],
  },
  reviewRequested: {
    title: 'Ask FSA CLU Review?',
    updateKey: 'cluProducerReviewRequestIndicator',
    options: [
      {code: 'Y', display: 'Yes'}, 
      {code: 'N', display: 'No'}, 
    ],
  },
  includeGeospatial: {
    title: 'Include Geospatial Data?',
    updateKey: 'includeGeospatialInReport',
    options: [
      {code: 'Y', display: 'Yes'}, 
      {code: 'N', display: 'No'}, 
    ],
  },
};

export const acresModifiedReasons = [
  { code: 'G', display: 'GPS Issue' },
  { code: 'C', display: 'Corrected Acres' },
  { code: 'MD', display: 'Missing Data' },
  { code: 'O', display: 'Other' },
];

export const plantingDateModifiedReasonCodes = [
  { code: 'MP', display: 'Multiple Planting Dates' },
  { code: 'C', display: 'Correction' },
  { code: 'O', display: 'Other' },
];

export const geospatialShapeModifiedReasonCodes = [
  { code: 'G', display: 'GPS Issue' },
  { code: 'C', display: 'Corrected Acres Calculation Difference' },
  { code: 'MD', display: 'Missing Data' },
  { code: 'S', display: 'Shape Drawn Incorrectly' },
  { code: 'O', display: 'Other' },
];

export const precisionSources = {
  deere: 'JD',
  cnh: 'CNH',
};

export const taxIDTypes = [
  { code: 1, display: 'Social Security Number (SSN)' },
  { code: 2, display: 'Employer ID Number (EIN)' },
]


export const alternateFieldNames = {
  'clu_identifier': ['id'],
  'clu_number': ['field'],
  'tract_number': ['tract'],
  'farm_number': ['farm'],
}
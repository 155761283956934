import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { scaleBand, scaleLinear, scaleQuantize } from "d3-scale";
import { bin, max, quantile, min } from "d3-array";
import Bar from "./Bar";
import { COLOR_RANGE, COLORS } from "../../../../../../../constants";

const useStyles = makeStyles((theme) => ({
  median: {
    fill: "none",
    stroke: COLORS.gray,
    strokeWidth: "1px",
  },
  text: {
    fill: COLORS.text,
    stroke: "none",
    textAnchor: "start",
    fontSize: 11,
  },
}));

/**
 * Creates scales and elements for histogram charts
 * @param {number} innerWidth bounded width of container, not currently used
 * @param {number} innerHeight bounded height of container, not currently used
 * @param {array} data array of values
 */

const Histogram = ({ innerWidth, innerHeight, data }) => {
  const classes = useStyles();
  const xDomain = [Math.min(0, min(data)), max(data)];
  const bins = bin().domain(xDomain).thresholds(10)(data);

  const xScale = scaleBand()
    .paddingInner(0.05)
    .range([0, innerWidth])
    .domain([xDomain[0]].concat(bins.map((d) => d.x1)));

  const yScale = scaleLinear()
    .range([innerHeight, 0])
    .domain([0, max(bins, (d) => d.length)]);

  // Get the x-position of the median value
  const medianScale = scaleLinear().range([0, innerWidth]).domain(xDomain);
  const medianXPosition = medianScale(quantile(data, 0.5));

  // Calculate a color scale
  const colorScale = scaleQuantize()
    .range(COLOR_RANGE.map((d) => d.backgroundColor))
    .domain(xDomain);

  return (
    <g className="histogram">
      {bins.map((d, i) => {
        return (
          <Bar
            key={`histogram-bar-for-${i}`}
            point={{
              x: xScale(d.x1),
              y: yScale(d.length),
              width: xScale.bandwidth(),
              height: yScale(0) - yScale(d.length),
              color: colorScale(d.x1),
            }}
          />
        );
      })}
      <g
        className="median__line__group"
        transform={`translate(${medianXPosition},0)`}
      >
        <line
          x1={0}
          x2={0}
          y1={yScale.range()[0]}
          y2={yScale.range()[1]}
          className={classes.median}
        />
        <text
          className={classes.text}
          dx=".5em"
          y={yScale.range()[1]}
        >{`Median`}</text>
      </g>
    </g>
  );
};

export default Histogram;

Histogram.propTypes = {
  innerWidth: PropTypes.number,
  innerHeight: PropTypes.number,
  data: PropTypes.array.isRequired,
};

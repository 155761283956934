import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container, Box, Typography, Button,
} from '@material-ui/core';
import { Integrate } from './Integrate';
import background from '../../images/tractors.jpg';
import climateLogo from '../../images/CFV_STK_RGB.png';
import { checkIsMobile } from '../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
  },
  darken: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    backgroundColor: 'rgba(0,0,0,.6)',
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
  },
  special: {
    marginTop: 8,
    padding: 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    border: `2px solid ${theme.palette.greys.main}`,
    backgroundColor: theme.palette.greys.light,
    color: theme.palette.text.primary,
  },
}));

/**
 * Allows user to integrate with precision ag. After agreeing to terms and conditions
 * user can click their preferred precisiona ag system icon to be redirected to the
 * relevant integration page.
 * @param {function} setSection Sets section for navbar highlight
 * @returns {JSX} IntegrationPage
 */
export function IntegrationPage({ setSection }) {
  const classes = useStyles();
  const [climateSpecialClick, setClimateSpecialClick] = useState(false);
  const [mobileDisplay, setMobileDisplay] = useState();

  useEffect(() => {
    setMobileDisplay(checkIsMobile());
  }, []);

  useEffect(() => {
    if (setSection) {
      setSection(-1);
    }
  }, [setSection]);

  const handleClimateClick = () => {
    setClimateSpecialClick(true);
  };

  const specialPricing = () => (
    <Box className={classes.special}>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="center"
        flexWrap="wrap"
      >
        <Box mx={0.5} style={{ fontSize: 18, fontWeight: 600 }}>
          <Box style={{ whiteSpace: 'nowrap' }}>SPECIAL OFFER:</Box>
          <Box style={{ whiteSpace: 'nowrap' }}>TAKE 50% OFF</Box>
        </Box>

        <Box mx={0.5}>
          <img style={{ height: 50, width: 'auto' }} src={climateLogo} alt="climate logo" />
        </Box>
      </Box>

      <Box mx={0.5}>
        <Typography>
          Continue to your special offer by integrating with Climate FieldView.
        </Typography>
        <Typography>
          You will be guided to the Purchase page after the integration starts.
        </Typography>
      </Box>

      <Box mx={0.5} textAlign="center">
        <Button
          color="primary"
          variant="contained"
          onClick={() => handleClimateClick()}
          size="large"
          style={{ padding: '10px 6px' }}
          disableElevation
        >
          <span style={{ whiteSpace: 'nowrap' }}>integrate below</span>
        </Button>
      </Box>
    </Box>
  );

  return (
    mobileDisplay ? (
      <Integrate />
    ) : (
      <Box className={classes.root}>
        <Box className={classes.darken}>
          <Container maxWidth="md">
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
            >

              <Box
                my={2}
                borderRadius="borderRadius"
                boxShadow={1}
                width="100%"
              >
                <Integrate/>
              </Box>
            </Box>

          </Container>
        </Box>
      </Box>
    )  
  );
}

IntegrationPage.propTypes = {
  setSection: PropTypes.func.isRequired,
};

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useWindowDimensions } from "../../utils/dimensions";

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: "auto",
  },
  head: theme.updateOperationHeader,
  close: {
    ...theme.icon,
    marginTop: 12,
  },
  details: theme.plDetails,
  display: theme.disabledInput,
  select: {
    // height: 44.6,
    margin: 4,
    width: 200,
    backgroundColor: "#ffffff",
  },
  dateSelection: {
    margin: 4,
    "& .MuiInputBase-root": {
      padding: 4,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10,
      },
      "& .MuiInputBase-input": {
        padding: 10,
        paddingLeft: 0,
      },
    },
    width: 200,
    backgroundColor: "#ffffff",
  },
  footer: theme.updateOperationFooter,
  label: {
    color: theme.palette.greys.dark,
    fontWeight: 500,
    fontSize: "0.875rem",
  },
}));

function getModalStyle() {
  const top = 20;
  return {
    top: `${top}%`,
    margin: "auto",
    zIndex: 1002,
    width: 300,
    height: 240,
    padding: 4,
  };
}

/**
 * Modal for editing CLU intersection boundary
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Function} setOpen Sets if modal is open
 */
export const WarningMessage = ({
  open,
  setOpen,
  generateMap,
  selectedFieldsWithSummary,
}) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const [fieldNames, setFieldNames] = useState("");

  useEffect(() => {
    let names = selectedFieldsWithSummary.map((x) => x.fieldName);
    setFieldNames(names.join(", "));
  }, [selectedFieldsWithSummary]);

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
      aria-labelledby="change-explanation"
      aria-describedby="explain-reason-data-was-changed"
    >
      <Box className={classes.paper} boxShadow={2}>
        <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => {
              setOpen(false);
            }}
          />
        </Box>

        <Box p={1} fontSize={16}>
          Profit Layer already generated for fields: {fieldNames}
        </Box>

        <Box p={1} fontSize={16}>
          Regenerating Profit Layers will overwrite any customizations on those
          fields. Cancel and deselect those fields to retain any customizations.
        </Box>

        <Box m={2} display="flex" justifyContent="flex-end">
          <Button variant="contained" onClick={() => setOpen(false)}>
            Cancel
          </Button>{" "}
          &nbsp; &nbsp;
          <Button color="primary" variant="contained" onClick={generateMap}>
            Continue
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

import { DL_TIME_LIMIT } from "../constants";
import { Endpoints } from "../constants/Endpoints";
import { exists, getCircularReplacer } from "./helpers";

/**
 * This was an initial attempt at making fetch requests timeout after a specified time period
 * and then retrying, but did not come to fruition
 * @param {*} timelimit 
 * @param {*} url 
 * @param {*} request 
 */
//  const requestWithLimit = async (timelimit, url, request, attempt = 0) => {
//   try{
//     if(attempt < 2){
//       const controller = new AbortController();
//       request['signal'] = controller.signal
//       const timeout = setTimeout(() => 
//         requestWithLimit(timelimit, url, request, attempt+1),
//         timelimit
//       )
//       fetch(url, request).then(response => {
//         clearTimeout(timeout)
//         return response.json();
//       }).catch((error) => {
//         console.log(error)
//       });
//     }
//     else if (attempt === 3){
//       fetch(url, request).then(response => {
//         return response.json();
//       }).catch((error) => {
//         console.log(error)
//       });
//     }
//   }
//   catch(err){
//     console.log(err)
//   }
// }

const requestWithLimit = async (timelimit, url, request, attempt = 0) => {
  const controller = new AbortController();
  const id = setTimeout(() => controller.abort(), timelimit);
  const response = await fetch(url, {
    ...request,
    signal: controller.signal
  })
  clearTimeout(id)
  return response
}


export async function isAuthenticated() {
  /* Sample response:
      {Data: bool, DisplayMessage: bool, EmailId: string, Exception: null, IsSuccessful: bool, Message: null}
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.ISAUTHENTICATED, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let data = await JSON.parse(jsonResponse);
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

export async function isIntegrated(){
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.ISINTEGRATED, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(`Error getting authentification: ${err}`);
  }
}

// Organizations, Farms, Fields
//userToken is Bearer token used for auth from ESRI addin
//client token is token for LandValue/LoanUnderwriting
export async function fetchOrganizations(userToken = "", clientToken) {
  /* Sample response:
      [{ID: Number, Name: string, source: string}, ...]
    */
  //const url = Endpoints.BASEURL + Endpoints.GETORGS
  let url = Endpoints.BASEURL + Endpoints.SELECTFIELD;
  if (clientToken !== undefined) {
    url = url + `?token=${clientToken}`;
  }
  try {
    let response;
    //console.log("In dataFetchers.fetchOrganizations. Just in:");
    if (userToken !== "") {
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + userToken,
        },
        credentials: "include",
      });
    } else {
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
    }

    let jsonResponse = await response.json();
    let orgs = JSON.parse(jsonResponse);
    return orgs;
  } catch (err) {
    console.log(`Error getting organizations: ${err}`);
  }
}

export const fetchTopOrg = async (token) => {
  let url = `${Endpoints.BASEURL}${Endpoints.GETTOPORG}`;
  if (token !== undefined) {
    url = url + `?token=${token}`;
  }
  try {
    let response = await fetch(`${Endpoints.BASEURL}${Endpoints.GETTOPORG}`, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let topOrg = JSON.parse(jsonResponse);
    //console.log(topOrg)
    return topOrg;
  } catch (err) {
    console.log(`Error getting top org: ${err}`);
  }
};

export async function getPreferredOrgs() {
  try {
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.GETPREFERREDORGS}`,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      }
    );
    let jsonResponse = await response.json();
    let preferredOrgs = JSON.parse(jsonResponse);
    //console.log(preferredOrgs)
    return preferredOrgs;
  } catch (err) {
    console.log(`Error getting preferred orgs: ${err}`);
  }
}

export async function updatePreferredOrgs(orgList){
  try{
    let url = `${Endpoints.BASEURL}${Endpoints.UPDATEPREFERREDORGS}`
    let response = await fetch(url,{
      method: 'POST',
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json"
      },
      body: JSON.stringify(orgList),
      credentials: "include"
    })
    let jsonResponse = await response.json()
    return jsonResponse
  }
  catch(err){

  }
}

export async function fetchFarmsForOrg(orgId, orgBoundary = false) {
  /* Sample response:
      [{FarmID: Number, FarmName: string} ...]
    */
  const url = `${Endpoints.BASEURL}${Endpoints.SELECTFIELD}?OrgID=${orgId}&orgBoundary=${orgBoundary}`;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let farms = JSON.parse(jsonResponse);
    return farms;
  } catch (err) {
    console.log(`Error getting farms: ${err}`);
  }
}

export async function fetchBoundaries(
  orgId,
  farmId,
  orgBoundary = true,
  token = undefined
) {
  /* Sample response:
      [{FarmID: Number, FarmName: string} ...]
    */
  const url = `${Endpoints.BASEURL}${Endpoints.SELECTFIELD}?OrgID=${orgId}`;
  let params =
    farmId !== undefined
      ? `&FarmID=${farmId}&orgBoundary=${orgBoundary}`
      : `&orgBoundary=${orgBoundary}`;
  if (token !== undefined) {
    params = params + `&token=${token}`;
  }
  try {
    let response = await fetch(url + params, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let farms = JSON.parse(jsonResponse);
    return farms;
  } catch (err) {
    console.log(`Error getting farms: ${err}`);
  }
}

export async function getFieldsInBounds(bbox, FarmID, OrgID) {
  const url = `${Endpoints.BASEURL}${Endpoints.GETFIELDSINBOUNDS}?bbox=${bbox}&FarmID=${FarmID}&OrgID=${OrgID}`;
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let boundaries = JSON.parse(jsonResponse);
    return boundaries;
  } catch (err) {
    console.log(`Error getting boundaries in map bounds: ${err}`);
  }
}

// Old field endpoints

export async function fetchFarms(
  orgId,
  isClaim = false,
  clientID = null,
  token = undefined,
  ownerEmail = undefined,
  acreageReporting = false,
) {
  /* Sample response:
      [{FarmID: Number, FarmName: string} ...]
    */
  let url = "";
  if (clientID !== null) {
    url = `${Endpoints.BASEURL}${Endpoints.GETFARMS}?orgId=${orgId}&isClaim=${isClaim}&clientID=${clientID}&acreageReporting=${acreageReporting}`;
  } else {
    url = `${Endpoints.BASEURL}${Endpoints.GETFARMS}?orgId=${orgId}&isClaim=${isClaim}&acreageReporting=${acreageReporting}`;
  }

  // const url = Endpoints.BASEURL + Endpoints.GETFARMS + orgId + "&isClaim=false"
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  if (ownerEmail !== undefined && ownerEmail !== ''){
    url = url + `&ownerEmail=${ownerEmail}`
  }
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let farms = JSON.parse(jsonResponse);
    return farms;
  } catch (err) {
    console.log(`Error getting farms: ${err}`);
  }
}

export async function fetchField(orgId, farmId, fieldId, token, ownerEmail = undefined) {
  /* Sample response:
    [{ID: Number, Name: string}, ...]
  */
  let url = `${Endpoints.BASEURL}${
    Endpoints.SELECTFIELD
  }?OrgID=${orgId}&FarmID=${farmId}&FieldID=${fieldId}&orgBoundary=${false}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  if (ownerEmail) url += `&ownerEmail=${ownerEmail}&acreageReport=${true}`;

  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let fields = JSON.parse(jsonResponse);
    return fields;
  } catch (err) {
    console.log(`Error getting fields: ${err}`);
  }
}

//vars needed: type, isOrganic, price, description, variety
export async function updateHidden(modifiedOp) {
  const url = `${Endpoints.BASEURL}${Endpoints.UPDATEHIDDEN}`;
  console.log("update hidden", modifiedOp);
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
      },
      body: JSON.stringify(modifiedOp),
    });
  } catch (err) {
    console.log(`Error Updating Hidden: ${err}`);
  }
}

export async function fetchFields(orgId, farmId, token, ownerEmail) {
  /* Sample response:
      [{ID: Number, Name: string}, ...]
    */
  let url =
    Endpoints.BASEURL +
    Endpoints.GETFIELDS +
    "?orgId=" +
    orgId +
    "&farm_id=" +
    farmId;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  if (ownerEmail !== undefined && ownerEmail !== '') {
    url = url + `&ownerEmail=${ownerEmail}`;
  }
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let fields = JSON.parse(jsonResponse);
    return fields;
  } catch (err) {
    console.log(`Error getting fields: ${err}`);
  }
}

export async function fetchOrgsFields(orgId, token) {
  /* Sample response:
      [{ID: Number, Name: string}, ...]
    */
  let url = `${Endpoints.BASEURL}${Endpoints.GETFIELDS}?OrgID=${orgId}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let fields = JSON.parse(jsonResponse);
    return fields;
  } catch (err) {
    console.log(`Error getting fields: ${err}`);
  }
}

export async function fetchFarmsFields(orgId, farmId) {
  /* Sample response:
      [{ID: Number, Name: string}, ...]
    */

  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETFIELDS +
        "?orgId=" +
        orgId +
        "&farm_id=" +
        farmId,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      }
    );
    let jsonResponse = await response.json();
    let fields = JSON.parse(jsonResponse);
    return fields;
  } catch (err) {
    console.log(`Error getting fields: ${err}`);
  }
}

export async function fetchFieldData(orgId, farmId, fieldId, num = 5) {
  /** Sample response
   *  { CLUS: null, CluHistory: null, FieldmanagerCLUS:
   *  { FieldID: {acres: Number, boundary: String ....} },
   *  FieldManagerCluHistory: null }
   */
  const all = farmId === undefined;
  const url = all
    ? `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&NoOfFields=${num}`
    : `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&FarmID=${farmId}&FieldID=${fieldId}`;
  //const url = `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&FarmID=${farmId}&FieldID=${fieldId}`

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let cluData = jsonResponse["FieldmanagerCLUs"];
    let data = all ? Object.values(cluData) : Object.values(cluData)[0];
    // const data = Object.values(cluData)
    return data;
  } catch (err) {
    console.log(`Error getting field data: ${err}`);
  }
}

export async function fetchFieldData2(orgId, farmId, fieldId, num = 5) {
  /** Sample response
   *  { CLUS: null, CluHistory: null, FieldmanagerCLUS:
   *  { FieldID: {acres: Number, boundary: String ....} },
   *  FieldManagerCluHistory: null }
   */
  let url = "";

  if (farmId === undefined && fieldId === undefined) {
    url = `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&NoOfFields=${num}`;
  } else if (fieldId === undefined) {
    url = `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&FarmID=${farmId}`;
  } else {
    url = `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&FarmID=${farmId}&FieldID=${fieldId}`;
  }
  //url = all ? `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&NoOfFields=${num}`
  //        : `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&FarmID=${farmId}&FieldID=${fieldId}`;
  //const url = `${Endpoints.BASEURL}${Endpoints.GETFIELDDATA}${orgId}&FarmID=${farmId}&FieldID=${fieldId}`

  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let cluData = jsonResponse["FieldmanagerCLUs"];
    let data = Object.values(cluData);
    // const data = Object.values(cluData)
    return data;
  } catch (err) {
    console.log(`Error getting field data: ${err}`);
  }
}

export async function getStateAndCounty(lat, long, returnAll = false) {
  /* Sample response
      [ {COUNTYFP: string, CountyName: string, STATEFP: string, ST_ABBR: string, StateName: string} ]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETSTATEANDCOUNTY +
        "(" +
        long +
        "%20" +
        lat +
        ")",
      {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      }
    );
    let jsonResponse = await response.json();
    let location = await JSON.parse(jsonResponse);
    if (returnAll) {
      return location[0];
    } else {
      return [location[0].StateName, location[0].CountyName];
    }
  } catch (err) {
    // coordinates not in US
    //console.log(`Problem getting state and county. This is likely caused by latitude and longitude (${lat}, ${long}) being outside of the U.S.`)
    return ["", ""];
  }
}

export async function getAvailableNaip(state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.AVAILABLENAIP + "?State=" + state,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("Error occurred while trying to get naip years", err);
  }
}

export async function getETLStatus() {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETETLSTATUS, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
    });
    if (response.ok) {
      // console.log("ETL STATUS: ", response.json());
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("Error occurred while trying to get etl status", err);
  }
}

export async function getNaipImagery(naipReq) {
  //console.log('naip request', naipReq)
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.NAIPIMAGERY,
      // +
      // "?aoi="+naipReq.aoi+
      // "&years="+naipReq.years +
      // "&display_option="+naipReq.display_option+
      // "&projection="+naipReq.Projection+
      // "&result_type="+naipReq.result_type+
      // "&inflation_rate="+naipReq.Inflation_Rate
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify(naipReq),
        credentials: "include",
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("Error occurred while trying to get naip imagery", err);
  }
}

export async function getOWMImagery(layerName, zoom, x, y) {
  console.log(Endpoints.BASEURL + Endpoints.OWM);
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.OWM +
        "?layerName=" +
        layerName +
        "&zoom=" +
        zoom +
        "&x=" +
        x +
        "&y=" +
        y,
      {
        method: "GET",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
      }
    );
    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("Error occurred while trying to get naip imagery", err);
  }
}

// Crops

export async function getCropPrices(year) {
  /* Sample response
      [{crop: string, harvestYear: number, price: float, priceUnit: string, productRegex: string}]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.CROPPRICES + "?Year=" + year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    let data = response.json();
    return data;
  } catch (err) {
    console.log(`error getting crop prices: ${err}`);
  }
}

export async function getIndividualCropPrice(name, unit, year) {
  /* Sample call: getIndividualCropPrice('barley', 'bu', 2018)
       Sample response: 3.03
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.SINGLECROPPRICE +
        "?Cropname=" +
        name +
        "&Unit=" +
        unit +
        "&year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(`error getting crop ${name} price: ${err}`);
  }
}

export async function getSeedPrices() {
  /* Sample response
      [{cropName: string, pricePerLb: float, pricePerK: float, kPerLb: float, productRegex: string}]
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.SEEDPRICES, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(`error getting seed price: ${err}`);
  }
}

export async function getIndividualSeedPrice(crop, unit) {
  /* Sample call getIndividualSeedPrice('barley', 'pricePerLb')
       Sample response: 0.07413
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.SINGLESEEDPRICE +
        "?Cropname=" +
        crop +
        "&Unit=" +
        unit,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    let data = await response.json();
    return data;
  } catch (err) {
    console.log(`problem getting ${crop} seed price: ${err}`);
  }
}

export async function getDieselPrices(year) {
  /*Sample response:
      [{ year: 2018, jan: 2.973, feb: 2.995, marchL 2.913... }]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.DIESELPRICES + "?Year=" + year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    let data = response.json();
    return data;
  } catch (err) {
    console.log(`problem getting diesel prices: ${err}`);
  }
}

export async function getChemicalPrice(name, unit) {
  /* Sample call getIndividualSeedPrice('accurate', 'oz')
       Sample response: 3.25
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.CHEMICALPRICE +
        "?ChemName=" +
        name +
        "&ChemUnit=" +
        unit,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    let data = response.json();
    return data;
  } catch (err) {
    console.log(`problem getting chemical ${name} price: ${err}`);
  }
}

export async function getFieldOperations(
  orgId,
  fieldId,
  type,
  year,
  selectedYears,
  updateFromPriceTool = false
) {
  /* Sample call getFieldOperations(136975, 'f2a556c6-0557-4cd4-8e1d-e6edc569600c', 'seeding', 2014)
     Sample response: [
                        { area: 160.16
                          areaUnit: "ac"
                          averageMaterialResult: 91.23
                          averageMaterialTarget: 104.98
                          averageMaterialTargetUnit: "lb1ac-1"
                          cropName: "WHEAT_HRD_RD_SPR"
                          cropSeason: 2014
                          endDate: "2014-05-29T15:16:14.42"
                          isDeleted: true
                          isIrrigated: false
                          isOrganic: false
                          manualEntry: 0
                          name: "Cardale"
                          price: 7
                          seedingLoggedID: 148
                          seedingOperationID: "MTM2OTc1XzU1Zjc4ZTkwNzM0ZTA1MGI0Y2JkMTY5YQ"
                          startDate: "2014-05-23T15:54:45.4"
                          totalMaterial: 14612.13
                          totalMaterialUnit: "lb"
                          year: 2014
                        },
                        ...
                      ]
    */
  try {
    let requestURL =
      Endpoints.BASEURL +
      Endpoints.FIELDOPERATIONS +
      "?OrgID=" +
      orgId +
      "&FieldID=" +
      fieldId +
      "&Type=" +
      type +
      "&Year=" +
      year +
      "&isRefreshRequest=" +
      updateFromPriceTool;
    for (var y of selectedYears) {
      requestURL += `&years[]=${y}`;
    }
    let response = await fetch(requestURL, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    let data = response.json();
    return data;
  } catch (err) {
    console.log(`problem getting field operations: ${err}`);
  }
}

/** Get geo json for field to edit zones */
export async function getGeoJson(orgID, fieldID, operationID, opType) {
  try {
    const data = {
      orgID: orgID.toString(),
      fieldID,
      operationID,
      opType,
    };
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.GETGEOJSON}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(data),
      }
    );
    const jsonResponse = await response.json();
    return jsonResponse.GeoJSON;
  } catch (err) {
    console.log(`Error getting operation geojson: ${err}`);
  }
}

export async function getProfitMapAccess(orgId, fieldId = null) {
  /* Sample call: getProfitMapAccess(136975, 'f2a556c6-0557-4cd4-8e1d-e6edc569600c')
       Sample resonse: true
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.PROFITMAPACESS +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(err);
  }
}

export async function getHarvestYears(orgId, fieldId) {
  /* Sample call: getProfitMapAccess(136975, 'f2a556c6-0557-4cd4-8e1d-e6edc569600c')
       Sample response: [ 2014, 2013 ]
       Note: some responses may contain invalid years (example: '2'), so check year is valid before using
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.HARVESTYEARS +
        "?OrgID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting harvest years: ${err}`);
  }
}

export async function preprocessFieldHighRes(
  orgId,
  fieldId,
  resolution = "0.00001"
) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.PREPROCESSHIGHRES +
        "?OrgID=" +
        orgId +
        "&FieldID=" +
        fieldId +
        "&Resolution=" +
        resolution,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`preprocess field high res error: ${err}`);
  }
}

export async function getProfitMapSummary(orgId, fieldId, year) {
  /* Sample call getProfitMapSummary(218871, '05c191cd-c48e-46e0-bc7a-d6b1c04237c0', 2018)
        Sample response: [{
          field_id: "05c191cd-c48e-46e0-bc7a-d6b1c04237c0"
          id: 226
          netSeededAcre: 1.18
          orgID: 218871
          otherFlatCost: 0
          profit: -320.17600000000004
          profitByZones: false
          time_stamp: "2020-06-03T16:51:28.08"
          year: 2018
      }]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.PROFITMAPSUMMARY +
        "?OrgID=" +
        orgId +
        "&FieldID=" +
        fieldId +
        "&Year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting profit map summary: ${err}`);
  }
}

export async function getBinRange(orgId, range) {
  /* Sample response:
       [{ binRange: "", colors: "", createdAt: "2020-02-18T11:35:01.987", id: 333,
          orgID: 218871, ranges: 10, token: null, updatedAt: null }]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.BINRANGE +
        "?OrgID=" +
        orgId +
        "&range=" +
        range,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting bins: ${err}`);
  }
}

export async function logBinRange(OrgID, legendInfo) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.LOGBINRANGE + "?OrgID=" + OrgID,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(legendInfo),
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function getProfitMapResponse(OrgID, FieldID, Year) {
  /* Sample call getProfitMapResponse(218871, '05c191cd-c48e-46e0-bc7a-d6b1c04237c0', 2018)
        Sample response: [{
          field_id: "05c191cd-c48e-46e0-bc7a-d6b1c04237c0"
          id: 3152
          isLegendSaved: false
          orgID: 218871
          profitMap_Response: "[{"std":83.2844465206257,"base64String":0,"min":-4"
          time_stamp: "2020-06-03T16:51:29.257"
          year: 2018
          zoneBoundary: null
          zoneCosts: null
      }]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.PROFITMAPRESPONSE +
        "?OrgID=" +
        OrgID +
        "&FieldID=" +
        FieldID +
        "&Year=" +
        Year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting profitmap response: ${err}`);
  }
}

export async function getVectorImage(orgId, fieldId, fieldOperations){
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.VECTORIMAGE +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(fieldOperations),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting profit map: ${err}`);
  }
}


export async function getHarvestOperationVariety() {
  /*
      Sample response: ["Soybeans", "CDC Limerick", "3197RA"...]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.HARVESTVARIETYNAME,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting harvest operation variety: ${err}`);
  }
}

export async function getSeedCropName() {
  /*
      Sample response: ["ALFALFA", "ALMONDS", "Apples"...]
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.SEEDCROPNAME, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting seed names: ${err}`);
  }
}

export async function getSeedMaterialUnit() {
  /*
      Sample response: ["lb", "seeds", "bag", "K seeds"]
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.SEEDMATERIALUNIT, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting seed material unit: ${err}`);
  }
}

export async function getSeedVariety() {
  /*
      Sample response: ["3197RA", "unknown", "RPM-3519AM", ...]
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.SEEDVARIETY, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting seed variety: ${err}`);
  }
}

export async function getApplicationMaterialUnit() {
  /*
      Sample response: ["lb", "gal", "seeds", "qt", "oz", "floz", "pt", "container"]
    */
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.APPMATERIALUNIT, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting applcation material unit: ${err}`);
  }
}

export async function getFieldBoundary(orgId, fieldId) {
  /*
      Sample response: [{ fieldID: "f65d7ee8-0166-4ed5-86ec-33a853123641", organizationID: 218871, isDeleted: false, acres: 31.79, boundary: "..." }]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.FIELDBOUNDARY +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting field boundary: ${err}`);
  }
}

export async function getColorTemplate(orgId, ranges) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.COLORTEMPLATE +
        "?OrganizationID=" +
        orgId +
        "&ranges=" +
        ranges,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting color template: ${err}`);
  }
}

export async function getAllTemplates(ranges) {
  /*
      Sample response: [{ id: 38, range: 10, rangesList: "-4583.19, -4379.62, ... }, ...]
    */
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.ALLTEMPLATE + "?ranges=" + ranges,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting tempaltes: ${err}`);
  }
}

export async function getSource(orgId) {
  /* Sample response: 'Deere' */
  try {
    let response = await await fetch(
      Endpoints.BASEURL + Endpoints.GETSOURCE + "?OrgID=" + orgId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting ${err}`);
  }
}

/*******************************
 *  SECTION FOR POST REQUESTS  *
 *******************************/

export async function getElevationMap(
  orgId,
  fieldId,
  fieldOperations,
  aoi,
  scatterPlot,
  limit = false
) {
  try {
    if(!limit){
      let response = await fetch(
        Endpoints.BASEURL +
          Endpoints.ELEVATIONMAP +
          "?OrganizationID=" +
          orgId +
          "&FieldID=" +
          fieldId +
          "&scatterPlot=" +
          scatterPlot,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ FieldOperationList: fieldOperations, aoi: aoi }),
        }
      );
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        Endpoints.BASEURL +
        Endpoints.ELEVATIONMAP +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId +
        "&scatterPlot=" +
        scatterPlot,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify({ FieldOperationList: fieldOperations, aoi: aoi }),
        }
      );
      return result ? result.json() : null
    }
    
  } catch (err) {
    console.log(`problem getting elevation map: ${err}`);
  }
}

export async function sectionMap(
  orgId,
  fieldId,
  year,
  isApplicationRaster,
  fieldOperations,
  scatterPlot
) {
  try {
    let requestURL =
      Endpoints.BASEURL +
      Endpoints.SECTIONMAP +
      "?OrganizationID=" +
      orgId +
      "&FieldID=" +
      fieldId +
      "&Year=" +
      year +
      "&isApplicationRaster=" +
      isApplicationRaster +
      "&scatterPlot" +
      scatterPlot;
    let response = await fetch(requestURL, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ fieldOperations: fieldOperations }),
    });
    //return response.json();
  } catch (err) {
    console.log(`problem getting sectionMap: ${err}`);
  }
}

export async function getProfitMap(orgId, fieldId, fieldOperations) {
  //console.log("PROFIT MAP REQUEST", fieldOperations);
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.PROFITMAP +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(fieldOperations),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting profit map: ${err}`);
  }
}

export async function logProfitMapresponse(
  orgId,
  fieldID,
  year,
  profitMapResponse
) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.LOGPROFITRESPONSE +
        "?OrgID=" +
        orgId +
        "&FieldID=" +
        fieldID +
        "&Year=" +
        year,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(profitMapResponse),
      }
    );
    return response.json();
  } catch (error) {
    console.log(`problem getting logging profit map: ${error}`);
  }
}

export async function logProfitMapSummary(orgID, fieldID, year, summary) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.LOGPROFITSUMMARY +
        "?OrgID=" +
        orgID +
        "&FieldID=" +
        fieldID +
        "&Year=" +
        year,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(summary),
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error, failed to log profit summary", err);
  }
}

export async function preprocessField(orgId, fieldId, resolution = "0.0001") {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.PREPROCESSFIELD +
        "?OrgID=" +
        orgId +
        "&FieldID=" +
        fieldId +
        "&Resolution=" +
        resolution,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem preprocessing field: ${err}`);
  }
}

// Deere Data
export async function hardUpdateDeereData(
  orgId,
  fieldId,
  operationId = null,
  operationType = null
) {
  try {
    let response = fetch(
      Endpoints.BASEURL +
        Endpoints.DEEREHARDUPDATE +
        "?OrgID=" +
        orgId +
        "&FieldID=" +
        fieldId +
        "&OperationId=" +
        operationId +
        "&OperationType=" +
        operationType,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem updating Deere data: ${err}`);
  }
}

//push profitmap
export async function pushProfitMap(orgId, fieldID, year, profitMapResponse) {
  //console.log("year", year)
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.PUSHPROFITMAP +
        "?OrgID=" +
        orgId +
        "&FieldID=" +
        fieldID +
        "&Year=" +
        year,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(profitMapResponse),
      }
    );
    if (response.status === 200) return response.json();
    else return undefined;
  } catch (error) {
    console.log(`problem pushing profit map to deere ops center: ${error}`);
  }
}

export async function manualOperation(requestObj) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.MANUALENTRY, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestObj),
    });

    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("There was a problem creating a manual operation", err);
    return undefined;
  }
}

export async function uploadForAgIntegrated(file, orgID, filename) {
  try {
    const url =
      Endpoints.BASEURL +
      Endpoints.UPLOADFORAGINTEGRATED +
      "?OrgID=" +
      orgID +
      "&nativeName=" +
      filename;
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: file,
    });

    if (response.ok) {
      return response;
      // return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log(
      "There was a problem while uploading to blob storage for AgIntegrated",
      err
    );
    return undefined;
  }
}

export async function checkAgIntegratedStatuses(orgID) {
  try {
    const url =
      Endpoints.BASEURL +
      Endpoints.CHECKAGINTEGRATEDSTATUSES +
      "?OrgID=" +
      orgID;
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log(
      "There was a problem while trying to get AgIntegrated statuses",
      err
    );
    return undefined;
  }
}

export async function requestYieldForecast(request, fieldID, orgID, seedingID) {
  try {
    const response = await fetch(
      `${
        Endpoints.BASEURL + Endpoints.YIELDFORECAST
      }?FieldID=${fieldID}&OrgID=${orgID}&seedingOperationID=${seedingID}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(request),
      }
    );
    
    if (response.ok) {
      const data = await response.json();
      return JSON.parse(data);
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("There was a problem creating yield forecast", err);
  }
}

export async function priceToolOtherOperations(requestObj) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.OPERATIONFROMPRICE,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestObj),
      }
    );

    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("There was a problem creating an operation from price", err);
    return undefined;
  }
}

export async function createManualFieldPasses(requestObj) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.CREATEFIELDPASSES,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestObj),
      }
    );

    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("There was a problem creating Field Operations", err);
    return undefined;
  }
}

export async function createOthersForOrg(requestObj) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.OTHERSFORORG, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestObj),
    });

    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log("There was a problem creating Field Operations", err);
    return undefined;
  }
}

export async function saveOperations(orgId, fieldId, fieldOperations) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.SAVEOPERATIONS +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(fieldOperations, getCircularReplacer()),
      }
    );

    if (response.ok) {
      return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log(`problem saving operations: ${err}`);
    return undefined;
  }
}

export async function getHarvestNames() {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.HARVESTVARIETYNAME,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`error getting harvest crop names: ${err}`);
  }
}

/** Get seeding or harvest variety names - deprecated 8/26 in favor of profitTool/ProfitToolLookup **/
// export async function getVarietyNames(type) {
//   const api =
//     type === "Seeding" ? Endpoints.SEEDVARIETY : Endpoints.HARVESTVARIETYNAME;
//   const url = `${Endpoints.BASEURL}${api}`;
//   try {
//     let response = await fetch(url, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//       },
//       credentials: "include",
//     });
//     return response.json();
//   } catch (err) {
//     console.log(`error getting varieties: ${err}`);
//   }
// }

export async function getLastSyncDate(orgId, fieldId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.LASTSYNCDATE +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem saving operations: ${err}`);
  }
}

export async function getOrgSyncDate(orgId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.ORGSYNCDATE + "?OrganizationID=" + orgId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem saving operations: ${err}`);
  }
}

export async function getBoundarySyncDate(orgId, fieldId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.BOUNDARYSYNCDATE +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem saving operations: ${err}`);
  }
}

export async function syncData(orgID, fieldID, type, source) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.SYNCDATA +
        "?OrgID=" +
        orgID +
        "&FieldID=" +
        fieldID +
        "&type=" +
        type +
        "&source=" +
        source,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response;
  } catch (err) {
    console.log("Error syncing data");
  }
}

export async function resetData(orgID, fieldID, year) {
  console.log("resetData");
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.RESETDATA +
        "?OrgID=" +
        orgID +
        "&FieldID=" +
        fieldID +
        "&Year=" +
        year,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error syncing data");
  }
}

export async function getScatterPoints(orgId, fieldID, year, requestObj) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.ELEVATIONSCATTER +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldID +
        "&year=" +
        year[0],
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestObj),
      }
    );
    if (response.ok && response.status !== 204) {
      return response.json();
    } else {
      //console.log(response);
    }
  } catch (err) {
    console.log("There was a problem creating a manual operation", err);
  }
}

export async function saveNewTemplate(templateInfo) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.SAVETEMPLATE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(templateInfo),
    });
    return response.json();
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function zoneAnalysis(
  orgID,
  fieldID,
  year,
  isApplicationRaster,
  opnId,
  operations
) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.ZONEANALYSIS +
        "?OrganizationID=" +
        orgID +
        "&FieldID=" +
        fieldID +
        "&year=" +
        year +
        "&isApplicationRaster=" +
        isApplicationRaster +
        "&opnId=" +
        opnId,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(operations),
      }
    );
    //console.log(response.status)
    if (response.status === 204) {
      return [];
    } else {
      return response.json();
    }
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function getZoneBoundaries(orgID, fieldID) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETZONES +
        "?OrgID=" +
        orgID +
        "&FieldID=" +
        fieldID,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function saveZones(orgID, fieldID, request) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.SAVEZONES +
        "?OrgID=" +
        orgID +
        "&FieldID=" +
        fieldID,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(request),
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function ssurgoForZones(request, limit = false) {
  try {
    if(!limit){
      let response = await fetch(Endpoints.BASEURL + Endpoints.SSURGOFORZONES, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(request),
      });
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        Endpoints.BASEURL + Endpoints.SSURGOFORZONES,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(request),
        }
      );
      return result ? result.json() : null
    }
    
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function ssurgoData(request, limit = false) {
  try {
    if(!limit){
      let response = await fetch(Endpoints.BASEURL + Endpoints.SSURGODATA, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(request),
      });
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        Endpoints.BASEURL + Endpoints.SSURGODATA,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(request),
        }
      );
      return result ? result.json() : null
    }
    
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function elevationIndex(orgId, fieldId, product, request, limit = false) {
  try {
    if (!limit){
      let response = await fetch(
        Endpoints.BASEURL +
          Endpoints.ELEVATIONINDEX +
          "?OrganizationID=" +
          orgId +
          "&FieldID=" +
          fieldId +
          "&Product=" +
          product,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(request),
        }
      );
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        Endpoints.BASEURL +
          Endpoints.ELEVATIONINDEX +
          "?OrganizationID=" +
          orgId +
          "&FieldID=" +
          fieldId +
          "&Product=" +
          product,
          {
            method: "POST",
            credentials: "include",
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
            },
            body: JSON.stringify(request),
          }
      );
      return result ? result.json() : null
    }
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function nutrientParser(request) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.NUTRIENTPARSER, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    return response.json();
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function getApplicationForParser(orgID, fieldID, id) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETAPPLICATIONOPS +
        "?OrgID=" +
        orgID +
        "&FieldID=" +
        fieldID +
        "&ID=" +
        id,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error logging Bins", error);
  }
}

export async function getBoundary(level) {
  const url = `${Endpoints.BASEURL}${Endpoints.GETBOUNDARY}?Geometry=envelope&Geometry_type=polygon&level=${level}`;
  try {
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    console.log(response);
    console.log(response.json());
    return response.json();
  } catch (error) {
    console.log("Error getting boundaries", error);
  }
}

//Profit Template API Calls
export async function getScenarioIDs() {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETTEMPLATESCENARIOS,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting profit templates", error);
  }
}

export async function getTemplateOperations(scenarioID) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETTEMPLATEOPERATIONS +
        "?ScenarioID=" +
        scenarioID,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting profit template operations", error);
  }
}

export async function saveCropTemplate(request) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.SAVECROPTEMPLATE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    return response.json();
  } catch (error) {
    console.log("Error getting profit template operations", error);
  }
}

export async function deleteCropTemplate(scenarioID) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.DELETECROPTEMPLATE +
        "?ScenarioID=" +
        scenarioID,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting profit template operations", error);
  }
}

// FIELD CLAIMS REQUESTS

export async function getNewOrgID(table) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.NEWORGID + "?table=" + table,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting creating new org id", error);
  }
}

export async function getNewGUID(table) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.NEWGUID + "?table=" + table,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting creating new guid", error);
  }
}

export async function createNewOrganization(OrgId, OrgName, source, type) {
  try {
    let url = `${Endpoints.BASEURL}${Endpoints.ADDORGANIZATION}?OrgId=${OrgId}&OrgName=${OrgName}&source=${source}`;
    if (type) {
      url = url + `&type=${type}`;
    }
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.ADDORGANIZATION +
        "?OrgId=" +
        OrgId +
        "&OrgName=" +
        OrgName +
        "&source=" +
        source,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting creating new org", error);
  }
}

export async function createNewFarm(FarmId, FarmName, OrgId, source, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.ADDFARM}?OrgId=${OrgId}&FarmName=${FarmName}&FarmID=${FarmId}&source=${source}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (error) {
    console.log("Error getting creating new farm", error);
  }
}

export async function createNewBoundary(reqObject, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.ADDBOUNDARY}`;
  if (token !== undefined) {
    url = url + `?token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(reqObject),
    });
    return response;
  } catch (error) {
    console.log("Error getting creating new boundary", error);
  }
}

export async function createNewField(reqObject, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.CREATEBARECLU}`;
  if (token !== undefined) {
    url = url + `?token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(reqObject),
    });
    return response;
  } catch (error) {
    console.log("Error getting creating new field", error);
  }
}

export async function addFarmsForFields(
  FieldID,
  FarmID,
  OrganizationID,
  source,
  token
) {
  let url = `${Endpoints.BASEURL}${Endpoints.ADDFARMSFORFIELDS}`;
  let params = `?OrganizationID=${OrganizationID}&FieldID=${FieldID}&FarmID=${FarmID}&source=${source}`;
  if (token !== undefined) {
    params = params + `&token=${token}`;
  }
  try {
    let response = await fetch(url + params, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (error) {
    console.log("Error getting creating farms for fields", error);
  }
}

export async function fetchClients(orgId, token) {
  /* Sample response:
      [{ClientID: Number, ClientName: string} ...]
    */
  let url = `${Endpoints.BASEURL}${Endpoints.GETCLIENTS}?OrgId=${orgId}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let jsonResponse = await response.json();
    let clients = JSON.parse(jsonResponse);
    return clients;
  } catch (err) {
    console.log(`Error getting farms: ${err}`);
  }
}

export async function createClient(ClientID, ClientName, OrgId, source, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.ADDCLIENT}?OrgID=${OrgId}&ClientID=${ClientID}&ClientName=${ClientName}&source=${source}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (error) {
    console.log("Error getting creating new farm", error);
  }
}

export async function addClientsForFields(
  FieldID,
  ClientID,
  OrganizationID,
  source,
  token
) {
  let url = `${Endpoints.BASEURL}${Endpoints.ADDCLIENTSFORFIELDS}?OrganizationID=${OrganizationID}&FieldID=${FieldID}&ClientID=${ClientID}&source=${source}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (error) {
    console.log("Error getting creating farms for fields", error);
  }
}

export async function addClientsForFarms(
  ClientID,
  FarmID,
  OrganizationID,
  source,
  token
) {
  console.log("farmID", FarmID);
  let url = `${Endpoints.BASEURL}${Endpoints.ADDCLIENTSFORFARMS}?orgId=${OrganizationID}&farmId=${FarmID}&clientId=${ClientID}&source=${source}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (error) {
    console.log("Error getting creating farms for fields", error);
  }
}

/* Data Layers */


export async function processPolaris(polarisReq, limit = false) {
  try {
    if(!limit){
      let response = await fetch(Endpoints.BASEURL + Endpoints.POLARIS, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(polarisReq),
      });
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        Endpoints.BASEURL + Endpoints.POLARIS,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(polarisReq),
        }
      );
      return result ? result.json() : null
    }
    
  } catch (error) {
    // console.log("Error processing Polaris", error);
  }
}

export async function fetchPolaris(filename) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.POLARIS + "?filename=" + filename,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function processCDL(CDLReq, limit = false) {
  try {
    if (!limit){
      let response = await fetch(Endpoints.BASEURL + Endpoints.CROPLAND, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(CDLReq),
      });
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        Endpoints.BASEURL + Endpoints.CROPLAND,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(CDLReq),
        }
      );
      return result ? result.json() : null
    }
    
  } catch (error) {
    // console.log("Error processing CDL", error);
  }
}

export async function HLSforNDVI(NDVIReq, limit = false) {
  try {
    if(!limit){
      let response = await fetch(Endpoints.BASEURL + Endpoints.HLSFORNDVI, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(NDVIReq),
      });
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        Endpoints.BASEURL + Endpoints.HLSFORNDVI,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(NDVIReq),
        }
      );
      return result ? result.json() : null
    }
  } catch (error) {
    // console.log("Error processing NDVI", error);
  }
}

export async function LandValueComps(CompsReq) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.LANDVALUE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(CompsReq),
    });

    return response.json();
  } catch (error) {
    console.log("Error processing Comps", error);
  }
}

export async function getGrowingDegreeDays(
  month,
  day,
  year,
  x,
  y,
  startYear,
  gdd,
  limit = false
) {
  try {
    let parmams = `?month=${month}&day=${day}&year=${year}&coordinateX=${x}&coordinateY=${y}&startYear=${startYear}&gddCut=${gdd}`;
    if(!limit){
      let response = await fetch(
        `${Endpoints.BASEURL}${Endpoints.GDD}${parmams}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        `${Endpoints.BASEURL}${Endpoints.GDD}${parmams}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return result ? result.json() : null
    }
    
  } catch (err) {
    // console.log(`Error getting growing degree days: ${err}`);
  }
}

export async function getPrecipitationData(month, day, year, x, y, startYear, limit = false) {
  try {
    let parmams = `?month=${month}&day=${day}&year=${year}&coordinateX=${x}&coordinateY=${y}&startYear=${startYear}`;
    if (!limit){
      let response = await fetch(
        `${Endpoints.BASEURL}${Endpoints.PRECIP}${parmams}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        `${Endpoints.BASEURL}${Endpoints.PRECIP}${parmams}`,
        {
          method: "GET",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
        }
      );
      return result ? result.json() : null
    }
  } catch (err) {
    // console.log(`Error getting growing degree days: ${err}`);
  }
}

export async function getSarImagery(bbox, srs, format, layers) {
  try {
    let params = `?bbox=${bbox}&srs=${srs}&format=${format}&layers=${layers}`;
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.SAR}${params}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("error getting sar", err);
  }
}

export async function getSevereWeatherAlerts(shape) {
  try {
    let params = `?wkt=${shape}`;
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.SEVEREWEATHERALERTS}${params}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error getting severe weather alerts: ", err);
  }
}

export async function getAndConvertGuidanceLines(requestBody) {
  try {
    const url = Endpoints.BASEURL + Endpoints.GUIDANCELINES;
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (err) {
    console.log("There was an error getting Guidance Lines:", err);
  }
}

export async function getTileDrainage(request, limit) {
  try {
    const url = Endpoints.BASEURL + Endpoints.TILEDRAIN;
    if(!limit){
      let response = await fetch(url, {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(request),
      });
  
      return response.json();
    }
    else{
      let result = await requestWithLimit(
        DL_TIME_LIMIT,
        url,
        {
          method: "POST",
          credentials: "include",
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          body: JSON.stringify(request),
        }
      );
      return result ? result.json() : null
    }
  } catch (err) {
    // console.log("There was an error getting Tile Drainage:", err);
  }
}

// --------------------------- Insurance Tools' Endpoints ---------------------------

export const noDataInResponse = "No data for request";

export async function getRAMARates(rmaRequest) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.RMARATES, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(rmaRequest),
    });
    return response.json();
  } catch (err) {
    console.log("There was an error getting RMA Rates:", err);
  }
}

export const getStateNames = async () => {
  try {
    let response = await fetch(`${Endpoints.BASEURL}${Endpoints.STATENAMES}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log("Error getting severe weather alerts: ", err);
  }
};

export const getCountyNames = async () => {
  try {
    let response = await fetch(`${Endpoints.BASEURL}${Endpoints.COUNTYNAMES}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log("Error getting severe weather alerts: ", err);
  }
};

export const refineYield = async (refineRequest) => {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.REFINEYIELD, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(refineRequest),
    });
    return response.json();
  } catch (err) {
    console.log("There was an error getting RMA Rates:", err);
  }
};

// Fetcher for yield insurance info
export const getYieldInsurance = async (yieldRequest) => {
  try {
    const url = "https://app.profit.ag/api/YieldInsurance";
    // NOTE: The dev side of this does not seem to have ever worked - it should be fixed...
    // const url = Endpoints.BASEURL + Endpoints.YIELDINSURANCE
    const response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
      },
      body: yieldRequest,
    });
    // console.log("getYieldInsurance response:", response)
    if (response.statusText === "No Content") {
      return noDataInResponse;
    } else if (response.status === 400) {
      return "Bad request";
    } else {
      const finalResponse = await response.json();
      // console.log("getYieldInsurance response:", finalResponse)
      return finalResponse;
    }
  } catch (err) {
    console.log("There was an error getting yield insurance information:", err);
  }
};

// For Insurance Tools Refine Estimates
export const calculateYieldFromHistory = async (body) => {
  try {
    const url = Endpoints.BASEURL + Endpoints.CALCULATEYIELDFROMHISTORY;
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json; charset=utf-8",
        Accept: "application/json",
      },
      body: JSON.stringify(body),
    });
    return response.json();
  } catch (err) {
    console.log("There was an error getting yield insurance information:", err);
  }
};

// Insurance modal endpoints that return csv.
// See DataLayers/InsuranceTools/GeneralSettings updateCrops function for as
// example of how to parse returned response in to an object.
export async function getCrops(year) {
  const url = `${Endpoints.BASEURL}${Endpoints.GETCROPS}?year=${year}`;
  const decoded = await decodeCsv(url);
  return decoded;
}

/**
 * Gets the crop codes and names for a particular county and year
 * @param {String} stateCode code for selected state
 * @param {String} countyCode code for selected county
 * @param {String} year selected year
 * @return {Promise} Decoded CSV
 */
export async function getCropsForCounty(stateCode, countyCode, year) {
  const url = `${Endpoints.BASEURL}${Endpoints.CROPSFORCOUNTY}?stateCode=${stateCode}&countyCode=${countyCode}&year=${year}`;
  const decoded = await decodeCsv(url);
  return decoded;
}

export async function getType(stateCode, cropCode, year, countyCode) {
  const url = `${Endpoints.BASEURL}${Endpoints.GETTYPE}?stateCode=${stateCode}&cropCode=${cropCode}&year=${year}&countyCode=${countyCode}`;
  const decoded = await decodeCsv(url);
  return decoded;
}

export const getPractice = async (stateCode, cropCode, year, countyCode) => {
  const url = `${Endpoints.BASEURL}${Endpoints.PRACTICE}?stateCode=${stateCode}&cropCode=${cropCode}&year=${year}&countyCode=${countyCode}`;
  const decoded = await decodeCsv(url);
  return decoded;
};

export const getPriceAndVolatility = async (
  stateCode,
  cropCode,
  year,
  countyCode,
  typeCode,
  practiceCode
) => {
  const url = `${Endpoints.BASEURL}${Endpoints.PRICEANDVOLATILITY}?stCode=${stateCode}&cropCode=${cropCode}&year=${year}&ctyCode=${countyCode}&typeCode=${typeCode}&practiceCode=${practiceCode}`;
  const decoded = await decodeCsv(url);
  return decoded;
};

export const getHarvestExclusionYears = async (code, year) => {
  try {
    const url = `${Endpoints.BASEURL}${Endpoints.EXCLUSIONYEARS}?code=${code}&year=${year}`;
    const decoded = await decodeCsv(url);
    return decoded;
  } catch (err) {
    console.log("Error getting exclusion years: ", err);
    return [];
  }
};

/**
 * Get TA factor for a particular TA code and year
 * @param  {String}  code Code calculated from State FIPS, County FIPS, Commodity code, type code and practice code
 * @param  {String}  year Concerned year
 * @return {Promise}      Decoded CSV
 */
export const getTAFactor = async (code, year) => {
  const url = `${Endpoints.BASEURL}${Endpoints.TAFACTOR}?code=${code}&year=${year}`;
  const decoded = await decodeCsv(url);
  return decoded;
};

export const getTYields = async (code) => {
  const url = `${Endpoints.BASEURL}${Endpoints.TYIELDS}?code=${code}`;
  const decoded = await decodeCsv(url);
  return decoded;
};

const decodeCsv = async (url) => {
  return fetch(url).then(function (response) {
    let reader = response.body.getReader();
    let decoder = new TextDecoder("utf-8");

    return reader.read().then(function (result) {
      return decoder.decode(result.value);
    });
  });
};

/**
 * [getNdvi description]
 * @param  {[type]} year         [description]
 * @param  {[type]} selectedYear [description]
 * @param  {[type]} shape        [description]
 * @return {[type]}              [description]
 */
// export async function getNdvi(year, selectedYear, shape) {
//   try {
//     const url = `${Endpoints.BASEURL}${Endpoints.NDVIHLS}`
//   } catch (err) {
//     console.log(`Problem getting NDVI: ${err}`)
//   }
// }

//To get field information
export async function getFieldInfo(orgID, fieldID) {
  try {
    let parmams = `?OrganizationID=${orgID}&FieldID=${fieldID}`;
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.GETFIELDINFORMATION}${parmams}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error getting field information: ", err);
  }
}

export async function deleteField(orgID, fieldID) {
  try {
    let params = `?OrganizationID=${orgID}&FieldID=${fieldID}`;
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.DELETEFIELD}${params}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error deleting field: ", err);
  }
}

export async function deleteFieldBoundary(orgID, fieldID) {
  try {
    let params = `?OrganizationID=${orgID}&FieldID=${fieldID}`;
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.DELETEBOUNDARY}${params}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error deleting field: ", err);
  }
}

export async function deleteFarm(orgID, farmID) {
  try {
    let params = `?OrganizationID=${orgID}&FarmID=${farmID}`;
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.DELETEFARM}${params}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error deleting field: ", err);
  }
}

export async function updateFarm(orgID, farmID, farmName) {
  try {
    let params = `?OrganizationID=${orgID}&FarmID=${farmID}&FarmName=${farmName}`;
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.UPDATEFARM}${params}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error deleting field: ", err);
  }
}

export async function updateField(orgID, fieldID, request) {
  try {
    let params = `?OrganizationID=${orgID}&FieldID=${fieldID}`;
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.UPDATEFIELD}${params}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(request),
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error deleting field: ", err);
  }
}

export async function updateBoundary(request) {
  try {
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.UPDATEBOUNDARY}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(request),
      }
    );
    return response.json();
  } catch (err) {
    console.log("Error deleting field: ", err);
  }
}

export async function alreadyPurchasedOrgs(featureCode, userToken = "") {
  try {
    let response;
    let url = `${Endpoints.BASEURL}${Endpoints.ALREADY_PURCHASED_ORGS}?FeatureCode=${featureCode}`;
    if (userToken !== "") {
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
          Authorization: "Bearer " + userToken,
        },
        credentials: "include",
      });
    } else {
      response = await fetch(url, {
        method: "GET",
        headers: {
          Accept: "application/json",
        },
        credentials: "include",
      });
    }
    return response.json();
  } catch (err) {
    console.log("Error deleting field: ", err);
  }
}

export async function getYieldsForInsurance(orgId, fieldId, year) {
  try {
    let response;
    let url = `${Endpoints.BASEURL}${Endpoints.YIELDFORINS}?orgId=${orgId}&fieldId=${fieldId}&year=${year}`;
    response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    return response.json();
  } catch (err) {
    console.log("Error getting yield for insurance: ", err);
  }
}

export async function syncDeereFieldData(
  orgID,
  fieldID,
  type = null,
  source = null,
  operationId = null
) {
  let url = `${Endpoints.BASEURL}${Endpoints.SYNCDATA}?OrgID=${orgID}&FieldID=${fieldID}`;
  if (type !== null) {
    url += `&type=${type}`;
  }
  if (source !== null) {
    url += `&source=${source}`;
  }
  if (operationId !== null) {
    url += `&OperationID=${operationId}`;
  }

  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response;
  } catch (err) {
    console.log("Error updating field data");
  }
}

export async function varietyParser(request) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.VARIETYPARSER, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    return response.json();
  } catch (err) {
    console.log("error parsing variety", err);
  }
}

export async function chemicalParser(request) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.CHEMICALPARSER, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    return response.json();
  } catch (err) {
    console.log("error parsing chemical", err);
  }
}

/* FieldManager Analytics API calls start */
export async function getApplicationComponents(orgId, fieldId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.APPLICATIONCOMPONENT +
        "?fieldId=" +
        fieldId +
        "&orgId=" +
        orgId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("error getting application components", err);
  }
}

export async function getAcres(orgId, fieldId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETACRES +
        "?fieldId=" +
        fieldId +
        "&orgId=" +
        orgId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("error getting acres", err);
  }
}

export async function getYield(orgId, fieldId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETYIELD +
        "?fieldId=" +
        fieldId +
        "&orgId=" +
        orgId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("error getting yield", err);
  }
}

export async function getVariety(orgId, fieldId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETVARIETY +
        "?fieldId=" +
        fieldId +
        "&orgId=" +
        orgId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("error getting variety", err);
  }
}

export async function getPrecipitation(x, y, year, startMonth, endMonth) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETPRECIPITATION +
        "?xcoord=" +
        x +
        "&ycoord=" +
        y +
        "&year=" +
        year +
        "&startMonth=" +
        startMonth +
        "&endMonth=" +
        endMonth,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("error getting precip", err);
  }
}

export async function getGDD(x, y, year, startMonth, endMonth) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETGDD +
        "?xcoord=" +
        x +
        "&ycoord=" +
        y +
        "&year=" +
        year +
        "&startMonth=" +
        startMonth +
        "&endMonth=" +
        endMonth,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("error getting gdd", err);
  }
}

export async function getNFactor(crop, unit) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETNFACTOR +
        "?crop=" +
        crop +
        "&harvestUnit=" +
        unit,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("error getting n factor", err);
  }
}

export async function nutrientParserFM(request) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.NUTRIENTPARSERFM, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    return response.json();
  } catch (error) {
    console.log("Error getting nutrient parser for fieldmanager", error);
  }
}
/* FieldManager Analytics API calls end */

export async function checkAuthentication(path, token) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.CHECKAUTHORIZATION +
        "?blobpath=" +
        path +
        "&token=" +
        token,

      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting nutrient parser for fieldmanager", error);
  }
}

/** LOAN UNDERWRITING CALLS */
export async function validateToken(token) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.VALIDATETOKEN + "?token=" + token,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting nutrient parser for fieldmanager", error);
  }
}

export async function getInfoFromToken(token) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETINFOFROMTOKEN + "?token=" + token,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting nutrient parser for fieldmanager", error);
  }
}

export async function getOrgForLoan(token) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETLOANORG + "?token=" + token,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting nutrient parser for fieldmanager", error);
  }
}

export async function claimFieldForClient(
  token,
  loanID,
  documentID,
  fieldName,
  reportName,
  boundaryID,
  fieldID,
  orgID,
  centerLat,
  centerLng,
  appraisalDate
) {
  let url = Endpoints.BASEURL + Endpoints.CLAIMCLIENTFIELD;
  let params = `?loanID=${loanID}&documentID=${documentID}&fieldName=${fieldName}&reportName=${reportName}&boundaryID=${boundaryID}&fieldID=${fieldID}&OrgID=${orgID}&centerLat=${centerLat}&centerLng=${centerLng}&appraisalDate=${appraisalDate}`;
  if (token !== undefined) {
    params = params + `&token=${token}`;
  }
  try {
    let response = await fetch(url + params, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (error) {
    console.log("Error getting nutrient parser for fieldmanager", error);
  }
}

export async function parcelService(request, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.PARCELSERVICE}`;
  if (token !== undefined) {
    url = url + `?token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error getting parcels", err);
  }
}

export async function getComps(request, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.LANDVALUECOMP}`;
  if (token !== undefined) {
    url = url + `?token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error getting Comps", err);
  }
}

export async function getSearchOptions(option, stateFips, countyFips){
  let url = `${Endpoints.BASEURL}${Endpoints.GETSEARCHOPTIONS}?option=${option}`
  if(stateFips !== undefined && stateFips !== null){
    url = url +`&stateFP=${stateFips}`
  }
  if(countyFips !== undefined && countyFips !== null){
    url = url + `&countyFP=${countyFips}`
  }
  try{
    let response = await fetch(url,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  }
  catch(err){
    console.log("Error getting search options", err)
  }
}

export async function getPreviousBoundaries(fieldID, orgID, token){
  let url = `${Endpoints.BASEURL}${Endpoints.GETPREVIOUSBOUNDARIES}?FieldID=${fieldID}&OrgID=${orgID}`
  if(token !== undefined){
    url = url + `&token=${token}`
  }
  try {
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log("Error getting previous boundaries", err);
  }
}

export async function updateParcelBoundary(request, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.UPDATEPARCELBOUNDARY}`;
  if (token !== undefined) {
    url = url + `?token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error getting Comps", err);
  }
}

export async function getParcelSummaryValues(loanID, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.GETPARCELSUMMARIES}?loanID=${loanID}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error getting Summary", err);
  }
}

export async function updateCollateral(
  token,
  boundaryID,
  fieldID,
  orgID,
  collateral
) {
  let url = `${Endpoints.BASEURL}${Endpoints.MARKCOLLAT}?boundaryID=${boundaryID}&fieldID=${fieldID}&orgID=${orgID}&collateral=${collateral}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error updating collateral", err);
  }
}

export async function updateAppraisalDate(token, boundaryID, date) {
  let url = `${Endpoints.BASEURL}${Endpoints.UPDATEAPPRAISALDATE}?boundaryID=${boundaryID}&date=${date}`;
  if (token !== undefined) {
    url = url + `&token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error updating appraisal date", err);
  }
}

export async function updateParcelInfo(request, token) {
  let url = `${Endpoints.BASEURL}${Endpoints.UPDATEPARCELINFO}`;
  if (token !== undefined) {
    url = url + `?token=${token}`;
  }
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    if (response.ok) {
      return response.json();
    } else {
      return null;
    }
  } catch (err) {
    console.log("Error updating parcel info", err);
  }
}

export async function extractPDF(token, filename, file) {
  try {
    let url =
      Endpoints.BASEURL + Endpoints.PDFEXTRACT + "?filename=" + filename;
    if (token) {
      url = url + "&token=" + token;
    }
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: file,
    });

    if (response.ok) {
      return response;
      // return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log(
      "There was a problem while uploading to blob storage for AgIntegrated",
      err
    );
    return undefined;
  }
}

export async function searchBoundaries(state, county, township, range, level) {
  try {
    let url =
      Endpoints.BASEURL +
      Endpoints.BOUNDARYSEARCH +
      "?state=" +
      state +
      "&county=" +
      county +
      "&township=" +
      township +
      "&range=" +
      range +
      "&level=" +
      level;
    let response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });

    if (response.ok) {
      return response.json();
      // return response.json();
    } else {
      return undefined;
    }
  } catch (err) {
    console.log(
      "There was a problem while uploading to blob storage for AgIntegrated",
      err
    );
    return undefined;
  }
}


export async function getComparablesInformation(compInfo, token){
  try{
    let url = Endpoints.BASEURL + Endpoints.GETCOMPSINFO;
    if (token) {
      url = url + '?token=' + token
    }
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(compInfo),
    });

    if (response.ok) {
      // return response;
      return response.json();
    } else {
      return undefined;
    }
  }
  catch(err){
    console.log("There was a problem getting Comps info", err)
    return undefined;
  }
}


export async function getCountyAverages(stateFips, countyFips, token){
  try{
    let url = `${Endpoints.BASEURL}${Endpoints.GETCOUNTYAVERAGES}?StateFips=${stateFips}&CountyFips=${countyFips}`;
    if (token) {
      url = url + '?token=' + token
    }
    let response = await fetch(url, {
      method: "GET",
      credentials: "include"
    });

    if (response.ok) {
      // return response;
      return response.json();
    } else {
      return undefined;
    }
  }
  catch(err){
    console.log("There was a problem getting county averages", err)
    return undefined;
  }
}


/** ACREAGE REPORTING CALLS */
export const getSharedCLUEmails = async () => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.GETSHAREDCLUEMAILS}`,
      {
        method: "GET",
        credentials: "include",
      }
    )
    return response.json();
  } catch (err) {
    console.error('Error getting shared CLU emails: ', err);
    return undefined;
  }
}

/**
 * Calls endpoint that merges passes boundaries, then gets their intersection with the current CLUs being used.
 * Returns info about intersected CLUs and their properties.
 * @param {String} boundaries WKT boundaries separated by semi-colon (;)
 * @param {Number} count number of boundaries in string
 */
export async function getCLUIntersectionsOld(requestBody) {
  /* Sample response: ?? */
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETCLUINTERSECTIONSOLD,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting CLU intersections: ${err}`);
    return undefined;
  }
}

/**
 * Calls endpoint that finds operations associated with fieldIDs passed and returns info them and their properties.
 *
 * Sample response:
 *      [
 *         {
 *           "operationID": "5bfcad42-6e02-4c8b-80d8-aa960e6cfc55",
 *           "clu_number": "6",
 *           "boundary": "{\"type\": \"Polygon\",\"coordinates\":[[[-93.169047908833875,42.1008919903731],[-93.168863841892744,42.100983758993323],[-93.168756844153,42.101178375881375],[-93.168696104841331,42.101441544491763],[-93.1685585373466,42.101693388838896],[-93.16839029307755,42.1019567157299],[-93.168221751132876,42.102117107118829],[-93.168053389951922,42.102346119477886],[-93.167989315996451,42.1023940234229],[-93.168328278816958,42.1023940330909],[-93.168375890434959,42.1023227703796],[-93.168529315675357,42.102253915585777],[-93.168774811708147,42.102162048939476],[-93.168897565570148,42.102116115166609],[-93.1689586394296,42.101978757213942],[-93.168958274304885,42.101841498186673],[-93.168988477136452,42.101647003606388],[-93.169018788786047,42.101498261135873],[-93.169156535245861,42.101315045852743],[-93.169140824089709,42.101177810207844],[-93.169094321046032,42.101017742574811],[-93.169047908833875,42.1008919903731]]]}",
 *           "acres": 1.4112031643459455,
 *           "clu_identifier": "EBAD51B2-6F00-4F8D-965E-00D9C976F17E",
 *           "cluShape": "{\"type\": \"Polygon\",\"coordinates\":[[[-93.169047908833875,42.1008919903731],[-93.168863841892744,42.100983758993323],[-93.168756844153,42.101178375881375],[-93.168696104841331,42.101441544491763],[-93.1685585373466,42.101693388838896],[-93.16839029307755,42.1019567157299],[-93.168221751132876,42.102117107118829],[-93.168053389951922,42.102346119477886],[-93.167989315996451,42.1023940234229],[-93.168328278816958,42.1023940330909],[-93.168375890434959,42.1023227703796],[-93.168529315675357,42.102253915585777],[-93.168774811708147,42.102162048939476],[-93.168897565570148,42.102116115166609],[-93.1689586394296,42.101978757213942],[-93.168958274304885,42.101841498186673],[-93.168988477136452,42.101647003606388],[-93.169018788786047,42.101498261135873],[-93.169156535245861,42.101315045852743],[-93.169140824089709,42.101177810207844],[-93.169094321046032,42.101017742574811],[-93.169047908833875,42.1008919903731]]]}"
 *         },
 *         ...
 *      ]
 *
 * @param {Object} requestBody body of request containing the orgID, fieldIDs, commodityYears and operation type information
 */
export async function getCLUIntersections(requestBody) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETCLUINTERSECTIONS,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting CLU intersections: ${err}`);
    return undefined;
  }
}

export async function getNonCLUBoundaries(requestBody) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETNONCLUBOUNDARIES,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting non CLU boundaries: ${err}`);
    return undefined;
  }
}

export async function getNonCLUBoundariesGivenBounds(requestBody) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETNONCLUBOUNDARIESGIVENBOUNDS,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting non CLU boundaries: ${err}`);
    return undefined;
  }
}

/**
 * Currently will get full CLU boundaries for passed clu_identifiers
 */ 
export async function getCLUInfo(requestBody) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETCLUINFO,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting CLU information: ${err}`);
    return undefined;
  }
}

export const getUserClus = async (boundary) => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.GETUSERCLUS}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(boundary),
      }
    )
    let res = await response.json();
    return res
  } catch (err) {
    console.error('Error getting User CLUs: ', err);
    return [];
  }
}

export const getCluAccesses = async () => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.GETCLUACCESSES}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    return response.json();
  } catch (err) {
    console.error('Error getting CLU_Accesses info: ', err);
    return undefined;
  }
}

export const sendSharingInvite = async (email) => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.SENDDATASHARINGINVITE}?email=${email}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    )
    return response.json();
  } catch (err) {
    console.error('Error sending data sharing invitation: ', err);
    return undefined;
  }
}


export const deleteCluAccess = async (requestBody) => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.DELETECLUACCESS}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    )

    return response.json();
  } catch (err) {
    console.error('Error deleting CLU_Access info: ', err);
    return undefined;
  }
}

export const saveCluAccess = async (requestBody) => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.SAVECLUACCESS}`,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    )

    return response;
  } catch (err) {
    console.error('Error saving CLU_Access info: ', err);
    return undefined;
  }
}

/**
 * Calls endpoint that finds boundaries associated with fieldIDs passed, then gets their intersections with the current CLUs being used.
 * Returns info about intersected CLUs and their properties for all the fields.
 * Currently only accepts calls for seeding operations.
 *
 * Sample response:
 * [
 *     {
 *         "seedingOperationID": "124823b7-6850-4411-8377-3a0e741ee2bf",
 *         "fieldID": "8b31c5c0-ed47-433e-abad-3c6f05ac3a76",
 *         "startDate": "2018-05-01T12:00:00",
 *         "endDate": "2018-05-01T12:00:00",
 *         "cropSeason": 2018,
 *         "cropName": "CORN",
 *         "averageMaterialResult": 30000,
 *         "averageMaterialTarget": 30000,
 *         "averageMaterialTargetUnit": "seeds1ac-1",
 *         "name": "DKC62-98",
 *         "area": 154.40540241635057,
 *         "areaUnit": "ac",
 *         "totalMaterial": 4632162.072490517,
 *         "totalMaterialUnit": "seeds",
 *         "seedingLoggedID": 0,
 *         "isOrganic": false,
 *         "isIrrigated": false,
 *         "isDeleted": false,
 *         "year": null,
 *         "source": "USERENTERED",
 *         "price": null
 *     },
 *     ...
 * ]
 * @param {Object} requestBody body of request containing the orgID, fieldIDs, commodityYear, commodityYears and operation type
 */
export async function getMultipleOperations(requestBody) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETMULTIPLEOPERATIONS,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting multiple operations: ${err}`);
    return undefined;
  }
}

/**
 *
 * @param {Object} requestBody body of request containing the orgID and fieldIDs and Years
 */
export async function getOperationZones(requestBody) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.GETOPERATIONZONES,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting operations zones: ${err}`);
    return undefined;
  }
}

/**
 * Calls endpoint that gets field shape for list of passed fields.
 *
 * Sample response:
 *   [
 *     {
 *       "fieldID": "41e3071c-0106-4bcd-bef8-e8b205e04949",
 *       "shape": "POLYGON ((-93.167281150817871 42.0955475393133, -93.167281150817871 42.102393712392875, -93.1756067276001 42.102393712392875, -93.1756067276001 42.0955475393133, -93.167281150817871 42.0955475393133))"
 *     },
 *     {
 *       "fieldID": "8b31c5c0-ed47-433e-abad-3c6f05ac3a76",
 *       "shape": "POLYGON ((-93.1403475239999 42.0973243110001, -93.140289238 42.1099142, -93.152762554 42.1099724860001, -93.152529407 42.097440884, -93.1403475239999 42.0973243110001))"
 *     }
 *   ]
 *
 * @param {Object} requestBody body of request containing the orgID and fieldIDs
 */
export async function getFieldsInfo(requestBody) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETFIELDSINFO, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting field info: ${err}`);
    return undefined;
  }
}

export async function getSharedEmails(){
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETSHAREDEMAILS, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting shared users: ${err}`);
    return undefined;
  }
}

export async function getSharedOrgs(email){
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.GETIMPERSONATEDORGS + `?email=${email}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting shared orgs: ${err}`);
    return undefined;
  }
}

export async function saveAcreageZones(toDelete, requestBody) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.SAVEACREAGEZONES, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify({ zonesToDelete: toDelete, zones: requestBody }),
    });
    return response.json();
  } catch (err) {
    console.log(`problem saving acreage zones: ${err}`);
    return undefined;
  }
}

export async function deleteZones(requestBody){
  try {
    let response = await fetch(
      Endpoints.BASEURL + Endpoints.DELETEZONES,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem deleting zones: ${err}`);
    return undefined;
  }
}

export async function getCVTCrops(requestBody) {
  /* Sample response: ?? */
  try {
    const response = await fetch(Endpoints.BASEURL + Endpoints.GETCVTCROPS, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting CVT crops: ${err}`);
    return undefined;
  }
}

export async function getCVTMapping(requestBody) {
  /* Sample response: ?? */
  try {
    const response = await fetch(Endpoints.BASEURL + Endpoints.GETCVTMAPPING, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting CVT mapping: ${err}`);
    return undefined;
  }
}

export async function getCVTCropTypes(requestBody) {
  /* Sample response: ?? */
  try {
    const response = await fetch(
      Endpoints.BASEURL + Endpoints.GETCVTCOMMODITYTYPES,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting CVT crop types: ${err}`);
    return undefined;
  }
}

export async function getCVTIntendedUses(requestBody) {
  /* Sample response: ?? */
  try {
    const response = await fetch(
      Endpoints.BASEURL + Endpoints.GETCVTINTENDEDUSES,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting CVT intended uses: ${err}`);
    return undefined;
  }
}

export async function getRMACrops(requestBody) {
  /* Sample response: ?? */
  try {
    const response = await fetch(Endpoints.BASEURL + Endpoints.GETRMACROPS, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting RMA crops: ${err}`);
    return undefined;
  }
}

export async function getRMATypes(requestBody) {
  /* Sample response: ?? */
  try {
    const response = await fetch(Endpoints.BASEURL + Endpoints.GETRMATYPES, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(requestBody),
    });
    return response.json();
  } catch (err) {
    console.log(`problem getting RMA types: ${err}`);
    return undefined;
  }
}

export async function getRMAPractices(requestBody) {
  /* Sample response: ?? */
  try {
    const response = await fetch(
      Endpoints.BASEURL + Endpoints.GETRMAPRACTICES,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    return response.json();
  } catch (err) {
    console.log(`problem getting RMA practices: ${err}`);
    return undefined;
  }
}

export const getOperationBoundary = async (
  orgId,
  fieldId,
  operationId,
  operationType,
  ownerEmail = undefined
) => {
  try {
    let url = `${Endpoints.BASEURL}${Endpoints.GETOPERATIONBOUNDARY}?OrgID=${orgId}&FieldID=${fieldId}&OperationID=${operationId}&OperationType=${operationType}`;
    if (ownerEmail) url += `&ownerEmail=${ownerEmail}`;

    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    let result = await response.json();
    return JSON.parse(result);
  } catch (err) {
    console.log(err);
  }
};

export async function updateOperationBoundary(requestBody) {
  try {
    const response = await fetch(
      Endpoints.BASEURL + Endpoints.UPDATEOPERATIONBOUNDARY,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify(requestBody),
      }
    );
    const result = await response.json();
    return result;
  } catch (err) {
    console.log(`problem updating operation boundary: ${err}`);
    return undefined;
  }
}

export async function getNewZoneID(){
  try{
    const response = await fetch(
      Endpoints.BASEURL + Endpoints.GETNEWZONEID,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      }
    );
    const result = await response.json();
    return result;
  }
  catch(err){
    console.log(`problem getting new zone id: ${err}`);
    return undefined;
  }
}

/**
 * The clu's original cluID should never actually be modified. 
 * If a shape does not have a clu_identifier, then it might not be an actual CLU.
 * Please be careful if using this.
 * @returns new cluID
 */
export async function getNewCluID(){
  try{
    const response = await fetch(
      Endpoints.BASEURL + Endpoints.GETNEWCLUID,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      }
    );
    const result = await response.json();
    return result;
  }
  catch(err){
    console.log(`problem getting new zone id: ${err}`);
    return undefined;
  }
}

export async function uploadReportFile(formBody){
  try{
    const url = Endpoints.BASEURL+Endpoints.UPLOADREPORTFILE
    const response = await fetch(url, {
      method: "POST",
      credentials: 'include',
      body: formBody
    })
    if (response.ok) {
      return response;
      // return response.json();
    } else {
      return undefined;
    }
  }
  catch(err){
    console.log("There was a problem uploading file to blob", err)
    return undefined;
  }
}

export async function getUploadedFiles(cropYear, operationID){
  try{
    let url = Endpoints.BASEURL+Endpoints.GETUPLOADEDFILES+`?cropYear=${cropYear}`;
    if (operationID) url += `&operationID=${operationID}`;
    
    const response = await fetch(url, {
      method: "GET",
      credentials: 'include',
    })

    return response.json();
  }
  catch(err){
    console.log("There was a problem gettings the files uploaded to blob", err)
    return undefined;
  }
}

export async function submitRMAFile(request){
  try{
    const url = Endpoints.BASEURL+Endpoints.SUBMITRMAFILE
    const response = await fetch(url, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    })

    return response.json();
  }
  catch(err){
    console.log("There was a problem submitting the file to RMA: ", err)
    return undefined;
  }
}

export async function getRMAStatus(transmissionID){
  try{
    const url = `${Endpoints.BASEURL}${Endpoints.GETRMASTATUS}?transmissionID=${transmissionID}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })

    return response.json();
  }
  catch (err){
    console.log(`There was a problem getting the status from RMA: ${err}`);
    return undefined;
  }
}

export async function getRMAStatusFromDB(tableID){
  try{
    const url = `${Endpoints.BASEURL}${Endpoints.GETRMASTATUSFROMDB}?tableID=${tableID}`;
    const response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        Accept: 'application/json',
      },
    })

    return response.json();
  }
  catch (err){
    console.log(`There was a problem getting the RMA status from Reports table: ${err}`);
    return undefined;
  }
}

export async function saveInquiryInfo(request){
  try{
    const url = Endpoints.BASEURL+Endpoints.SAVEINQUIRYINFO
    const response = await fetch(url, {
      method: "POST",
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    })

    return response.json();
  }
  catch(err){
    console.log("There was a problem saving the inquiry info: ", err)
    return undefined;
  }
}

export async function addCluBoundary(request) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.ADDCLUBOUNDARY, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request),
    });
    return response.json();
  } catch (error) {
    console.log("Error inserting clu boundary: ", error);
  }
}

export const saveAcreageReport = async (report) => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.SAVEACREAGEREPORT}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify(report),
      }
    );
    return response.json();
  } catch (err) {
    console.error(err);
  }
};

export const getUsersAcreageReports = async () => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.GETUSERSACREAGEREPORTS}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    let res = await response.json();
    return JSON.parse(res);
  } catch (err) {
    console.error(err);
  }
};

export const getHistoricAcreageReport = async (reportId) => {
  try {
    const response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.GETHISTROICACREAGEREPORT}?ReportID=${reportId}`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    let res = await response.json();
    return JSON.parse(res);
  } catch (err) {
    console.error(err);
  }
};


// -----------------------------------------------------------------------------
// ###########################  Vendor Requests  ###############################
// -----------------------------------------------------------------------------
export async function submitVendorOrder(order, agentEmail = null) {
  try {
    let url = `${Endpoints.BASEURL}${Endpoints.ORDERREQUEST}`;
    if (agentEmail !== null){
      url = url + `?agentEmail=${agentEmail}`
    }
    console.log("Vendor response url", url)
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(order),
    });
    return response;
  } catch (err) {
    console.log(`Error Submitting Order: ${err}`);
    return err;
  }
}

/**
 * Gets order IDs for a logged in user
 * @param {Boolean} [sortOrders=true] Sort orders by data created
 * @return {Array} Orders matching passed id
 */
export async function getOrders(sortOrders = true, productId = 1) {
  const response = await fetch(
    `${Endpoints.BASEURL}${Endpoints.GETORDERIDS}?ProductID=${productId}`,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    }
  );
  try {
    const orders = await response.json();

    if (sortOrders) {
      orders.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
    }

    return orders;
  } catch (err) {
    console.log(`problem getting report: ${err}`);
    return err;
  }
}

export async function saveOrderPayment(request){
  try{
    let url = Endpoints.BASEURL + Endpoints.SAVEORDERPAYMENT
    let response = await fetch(url, {
      method: 'POST',
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
      body: JSON.stringify(request)
    })
    return response.json();
  }
  catch(err){
    console.log(err)
  }
}

export async function searchAgentPromo(code, vendor){
  try{
    let url = `${Endpoints.BASEURL}${Endpoints.SEARCHAGENTPROMO}?code=${code}&vendor=${vendor}`
    let response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      }
    })
    return response.json();
  }
  catch(err){
    console.log(err)
  }
}

/**
 * If token is present in link get that individual report for display.
 * This will happen instead of loading all orders, even for logged in user.
 * @param {String} token We can get individual order from database by url token
 * @return {Object} Report matching token
 */
export async function getReportFromToken(token) {
  const response = await fetch(
    Endpoints.BASEURL + Endpoints.GETREPORTSFROMTOKEN + token,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    }
  );
  try {
    const jsonResponse = await response.json();
    const data = await jsonResponse[0];
    const report = JSON.parse(data.orderResponse);
    return report;
  } catch (err) {
    console.log(`problem getting report: ${err}`);
    return err;
  }
}

/**
 * Gets a report from database by ID
 * @param {String} reportId Report ID
 */
export async function getReport(reportId) {
  const response = await fetch(
    Endpoints.BASEURL + Endpoints.GETREPORTS + reportId,
    {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    }
  );
  try {
    const jsonResponse = await response.json();
    const data = await jsonResponse[0];
    const report = JSON.parse(data.orderResponse);
    return report;
  } catch (err) {
    console.log(`problem getting report: ${err}`);
    return err;
  }
}

/**
 * Post method that gets PDFs, images, Tiffs, and other files associated with
 * selected report from blob storage. First check if token is present, if so
 * use this in fetch url if not request as normal for logged in user.
 * @param {String} orderId    Id of order containing suborder
 * @param {String} subOrderId Id of suborder to get reports for
 * @param {String} [token=''] Optional token from url
 * @return {Array} All files associated with report
 */
export async function getReportFiles(orderId, subOrderId, token = "") {
  // if we have a token, use it in request url
  const url =
    token !== ""
      ? `${
          Endpoints.BASEURL + Endpoints.GETPDFS + orderId
        }&suborderId=${subOrderId}&token=${token}`
      : `${
          Endpoints.BASEURL + Endpoints.GETPDFS + orderId
        }&suborderId=${subOrderId}`;

  try {
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    const reportFiles = await response.json();
    return reportFiles;
  } catch (err) {
    console.log(`problem getting pdfs: ${err}`);
    return err;
  }
}

export const getGeoJsonFromShape = async (path, token) => {
  let url = `${Endpoints.BASEURL}${Endpoints.GETGEOJSONFROMSHAPE}${path}`;

  if (token) {
    url += `&token=${token}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });

    if (response.status === 200) {
      const geo = await response.json();
      return geo;
    } else {
      console.log(`problem getting geojson: ${response?.statusText}`);
      return response?.statusText;
    }
  } catch (err) {
    console.log(`problem getting geojson: ${err}`);
    return err;
  }
};

export const convertShapeToTIFF = async (
  path,
  category,
  field = null,
  token = null
) => {
  let url = `${Endpoints.BASEURL}${Endpoints.CONVERTSHAPETOTIFF}${path}&isCategory=${category}`;

  if (field) {
    url += `&Field=${field}`;
  }

  if (token) {
    url += `&token=${token}`;
  }

  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });

    if (response.status === 200) {
      const files = await response.json();
      return JSON.parse(files);
    } else {
      console.log(`problem getting geojson: ${response?.statusText}`);
      return response?.statusText;
    }
  } catch (err) {
    console.log(`problem getting geojson: ${err}`);
    return err;
  }
};

export const downloadRaster = async (id, name, type) => {
  let url = `${Endpoints.BASEURL}${Endpoints.DOWNLOADRASTERFILE}${type}&FileID=${id}&Name=${name}`;

  console.log("url", url);
  try {
    const response = await fetch(url, {
      method: "GET",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });

    if (response.status === 200) {
      console.log(response);
      const file = await response.json();
      return file;
    } else {
      console.log(`problem getting geojson: ${response?.statusText}`);
      return response?.statusText;
    }
  } catch (err) {
    console.log(`problem getting geojson: ${err}`);
    return err;
  }
};

/**
 * Get method that gets nutrient summary for application operation
 * @param {Number} orgID  selected organization ID
 * @param {String} fieldID selected field ID
 * @param {String} id selected application operation ID
 * @return {Array} All nutrients data associated with application operation
 */
export async function getNutrientSummary(orgID, fieldID, id) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETNUTRIENTSUMMARY +
        "?OrgID=" +
        orgID +
        "&FieldID=" +
        fieldID +
        "&ID=" +
        id,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting nutrient summary data", error);
  }
}

/**
 * Get method that gets order ID and subOrderID
 * @param {Number} orgID  selected organization ID
 * @param {String} fieldID selected field ID
 * @return {Object} Returns OrderID and SubOrderID
 */
export async function getOrderDetails(orgId, fieldId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETORDERDETAILS +
        "?OrganizationID=" +
        orgId +
        "&FieldID=" +
        fieldId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting nutrient summary data", error);
  }
}

/**
 * Get method checks if entered referral code is valid or not
 * @param {String} referralCode  selected organization ID
 * @return {Object} referral code details
 */
export async function checkReferralCode(referralCode) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.API_DEALER_INFORMATION +
        "/" +
        referralCode.trim(),
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error checking referral code", error);
  }
}

/* To post vendor order response */
export async function postVendorResponse(request, clientKey, clientSecret) {
  try {
    let response = await fetch(Endpoints.BASEURL + Endpoints.VENDORRESPONSE, {
      method: "POST",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        clientKey: clientKey,
        clientSecret: clientSecret,
      },
      body: JSON.stringify(request),
    });
    return response.json();
  } catch (error) {
    console.log("Error checking referral code", error);
  }
}

/* Check vendor authentication */
export async function checkVendorAuthentication(clientKey, clientSecret) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.CHECKVENDORAUTHENTICATION +
        "?clientKey=" +
        clientKey +
        "&clientSecret=" +
        clientSecret,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.text();
  } catch (error) {
    console.log("Error checking vendor authentication", error);
  }
}

export async function uploadOtherFiles(file) {
  try {
    const url = Endpoints.BASEURL + Endpoints.UPLOADOTHERFILES;
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      body: file,
    });

    return response.json();
  } catch (err) {
    console.log(
      "There was a problem while uploading to blob storage for vendor order",
      err
    );
    return "";
  }
}

//Get legend info and base64 for shapefiles with large number of points
export async function getLegendData(blobPath, field, isCategory, token = null) {
  try {
    let url = "";
    if (token !== null) {
      url =
        Endpoints.BASEURL +
        Endpoints.GETLEGENDDATA +
        "?BlobPath=" +
        blobPath +
        "&Field=" +
        field +
        "&IsCategory=" +
        isCategory +
        "&token=" +
        token;
    } else {
      url =
        Endpoints.BASEURL +
        Endpoints.GETLEGENDDATA +
        "?BlobPath=" +
        blobPath +
        "&Field=" +
        field +
        "&IsCategory=" +
        isCategory;
    }

    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      credentials: "include",
    });
    return response.json();
  } catch (err) {
    console.log("getlegend data error", err);
  }
}

export async function getDoNotCall(areaCode, number, secretCode) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.DONOTCALL +
        "?areaCode=" +
        areaCode +
        "&number=" +
        number +
        "&code=" +
        secretCode,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error checking vendor authentication", error);
  }
}

// Fetch crop types by farm
export async function fetchFarmCropTypes(orgId, year, farmId) {
  /* Sample response:
                [{CropName: string} ...]
              */
  try {
    return await globalFetch(
      `${Endpoints.BASEURL}${Endpoints.CROP_TYPES}?OrgID=${orgId}&Year=${year}&FarmID=${farmId}`
    );
  } catch (err) {
    console.log(`Error getting crop types: ${err}`);
    return [];
  }
}

// Fetch crop varieties by farm and crop name
export async function fetchFarmCropVarieties(orgId, year, farmId, cropName) {
  /* Sample response:
                [{Name: string, FieldID: string, Area: number} ...]
              */
  try {
    return await globalFetch(
      `${Endpoints.BASEURL}${Endpoints.CROP_VARIETIES}?OrgID=${orgId}&Year=${year}&FarmID=${farmId}&CropName=${cropName}`
    );
  } catch (err) {
    console.log(`Error getting crop varieties: ${err}`);
  }
}

export async function fetchFarmOperations(
  orgId,
  year,
  farmId,
  type = "Seeding",
  refreshPrices = false
) {
  /* Sample response:
                [{FarmID: Number, FarmName: string} ...]
              */
  try {
    //console.log("fetchFarmOperations")
    let data = await globalFetch(
      `${Endpoints.BASEURL}${Endpoints.FARM_OPERATIONS}?OrgID=${orgId}&year=${year}&farmID=${farmId}&type=${type}&refreshPrices=${refreshPrices}`
    );
    data.type = type;
    return data;
  } catch (err) {
    console.log(`Error getting farm operations: ${err}`);
    return [];
  }
}

export async function fetchFieldsForOrgFarm(orgId, farmId, boundary = false) {
  /* Sample response:
                [{FarmID: Number, FarmName: string} ...]
              */
  const url = `${Endpoints.BASEURL}${Endpoints.SELECTFIELD}?OrgID=${orgId}&FarmID=${farmId}`;

  try {
    return await globalFetch(url);
  } catch (err) {
    console.log(`Error getting farms: ${err}`);
  }
}

// Fetch chemical inputs by organization and year
export async function fetchOrganizationChemicalInputs(orgId, year, fieldIds) {
  /* Sample response:
                [{ComponentName: string, FieldID: string, AreaResult: number, ID: string} ...]
              */
  try {
    let response = await fetch(
      `${Endpoints.BASEURL}${Endpoints.CHEMICAL_INPUTS}?OrgID=${orgId}&Year=${year}`,
      {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        credentials: "include",
        body: JSON.stringify({
          FieldID: fieldIds,
        }),
      }
    );
    let jsonResponse = await response.json();
    return await JSON.parse(jsonResponse);
  } catch (err) {
    console.log(`Error getting chemical inputs: ${err}`);
    return [];
  }
}

// Perform a zone analysis for each application id and field id pair
export async function performApplicationZoneAnalysis(
  orgId,
  fieldId,
  year,
  operationId,
  isApplicationRaster = 1
) {
  /* Sample response:
                [{std: number, base64string: number, acres: number, ...} ...]
              */
  const url = `${Endpoints.BASEURL}${Endpoints.ZONE_ANALYSIS_FOR_APPLICATION}?OrganizationID=${orgId}&FieldID=${fieldId}&year=${year}&isApplicationRaster=${isApplicationRaster}&opnId=${operationId}`;
  const body = JSON.stringify({
    FieldOperationList: [
      {
        attribute: "AppliedRate",
        cellsize: "0.0001",
        fileType: "APPLICATION",
        operationID: operationId,
      },
    ],
  });
  try {
    let response = await fetch(url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      credentials: "include",
      body,
    });
    let jsonResponse = await response.json();
    return jsonResponse;
  } catch (err) {
    console.log(`Error getting chemical inputs: ${err}`);
    return [];
  }
}

// Fetch profit map summaries for farm level
export async function fetchProfitMapSummaries(orgId, farmId) {
  /* Sample response:
                [{FarmID: Number, FarmName: string} ...]
              */
  try {
    return await globalFetch(
      `${Endpoints.BASEURL}${Endpoints.PROFIT_MAP_SUMMARIES}?OrgID=${orgId}&FarmID=${farmId}`
    );
  } catch (err) {
    console.log(`Error getting farms: ${err}`);
    return [];
  }
}

async function globalFetch(url) {
  let response = await fetch(url, {
    method: "GET",
    headers: {
      Accept: "application/json",
    },
    credentials: "include",
  });
  let jsonResponse = await response.json();

  //console.log('response', jsonResponse)
  try {
    return await JSON.parse(jsonResponse);
  } catch (err) {
    return jsonResponse;
  }
}

export const getVarieties = async (val, type) => {
  try {
    // This is the function from the old PL tool to retrieve crop varieties
    // based on user input. The api performs the autocomplete functionaity on keypress.
    let url = Endpoints.BASEURL;
    url += type === 'Harvest' ? Endpoints.HARVESTVARIETYNAME : Endpoints.SEEDVARIETY;
    url += `/${val}`;
    const response = await fetch(url, {
      method: "GET",
      credentials: "include",
      headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
      },
    })
    const varieties = await response.json()
    return varieties;
  } catch(err) {
    console.error(err)
  }
};




//Payment app requests

export const customerExists = async (email, userToken) => {
  try{
    let url = Endpoints.BASEURL + Endpoints.CUSTOMER_EXISTS
    if (exists(email)){
      url = url+`?email=${email}`
    }
    let response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: userToken !== '' ?
        {
          Accept: "application/json",
          "Authorization": 'Bearer ' + userToken
        }
        :
        {
          Accept: "application/json",
        }
      ,
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}

export const anymousCustomerExists = async (email) => {
  try{
    let url = Endpoints.BASEURL + Endpoints.ANON_CUSTOMER_EXISTS
    if (exists(email)){
      url = url+`?email=${email}`
    }
    let response = await fetch(url, {
      method: 'GET',
      credentials: 'include',
      headers: 
        {
          Accept: "application/json",
        }
      ,
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}

export const getOrCreateCustomer = async (request) => {
  try{
    let response = await fetch(Endpoints.BASEURL + Endpoints.API_CUSTOMER_V2, {
      method: 'POST',
      headers: 
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      , credentials: 'include',
      body: JSON.stringify(request)
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}


export const getOrCreateAnonymousCustomer = async (request) => {
  try{
    let response = await fetch(Endpoints.BASEURL + Endpoints.ANON_API_CUSTOMER, {
      method: 'POST',
      headers: 
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }
      , credentials: 'include',
      body: JSON.stringify(request)
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}

export const generatePaymentNonce = async (userToken) => {
  try{
    let url = Endpoints.BASEURL + Endpoints.API_CUSTOMER_PAYMENT_NONCE_V2
    let response;
    if (userToken !== undefined && userToken !== '') {
      response = await fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + userToken
        },
        method: "GET"
      })
    }
    else {
      response = await fetch(url, {
        method: "GET",
        credentials: 'include'
      })
    
    }
    return response.json();
  } 
  catch(err){
    console.log(err)
  }
}

export const generateAnonymousPaymentNonce = async (email, userToken) => {
  try{
    let url = Endpoints.BASEURL + Endpoints.ANON_API_CUSTOMER_PAYMENT_NONCE
    if (exists(email)){
      url = url +`?email=${email}`
    }
    let response;
    if (userToken !== undefined && userToken !== '') {
      response = await fetch(url, {
        headers: {
          'Authorization': 'Bearer ' + userToken
        },
        method: "GET"
      })
    }
    else {
      response = await fetch(url, {
        method: "GET",
        credentials: 'include'
      })
    
    }
    return response.json();
  } 
  catch(err){
    console.log(err)
  }
}

export const createTransaction = async (request) => {
  try{
    let url = Endpoints.BASEURL + Endpoints.API_HANDLE_TRANSACTION
    let response = await fetch(url,{
      method: 'POST',
      headers: 
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }, 
      credentials: 'include',
      body: JSON.stringify(request)
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}

export const createAnonymousTransaction = async (request) => {
  try{
    let url = Endpoints.BASEURL + Endpoints.ANON_API_HANDLE_TRANSACTION
    let response = await fetch(url,{
      method: 'POST',
      headers: 
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }, 
      credentials: 'include',
      body: JSON.stringify(request)
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}

export const getSubscriptionPaymentMethod = async (subscriptionIdentifier) => {
  try{
    let url = `${Endpoints.BASEURL}${Endpoints.API_SUBSCRIPTION_PAYMENT_METHOD}?subscriptionIdentifier=${subscriptionIdentifier}`
    let response = await fetch(url,{
      method: 'GET',
      headers: 
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }, 
      credentials: 'include',
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}

export const updateSubscriptionPaymentMethod = async (subscriptionIdentifier, nonce) => {
  try{
    let url = `${Endpoints.BASEURL}${Endpoints.API_UPDATE_SUBSCRIPTION_PAYMENT_METHOD}?subscriptionID=${subscriptionIdentifier}&nonce=${nonce}`
    let response = await fetch(url,{
      method: 'GET',
      headers: 
        {
          'Accept': 'application/json',
          'Content-Type': 'application/json',
        }, 
      credentials: 'include',
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}


export const createIntegrationInvite = async (dataSource, inviteEmail) => {
  try{
    let url = `${Endpoints.BASEURL}${Endpoints.INTEGRATEINVITE}?dataSource=${dataSource}`
    if (inviteEmail != null){
      url = `${url}&inviteEmail=${inviteEmail}`
    }
    let response = await fetch(url,{
      method: 'GET',
      credentials: 'include',
      headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json',
      }, 
    })
    return response.json()
  }
  catch(err){
    console.log(err)
  }
}

import Papa from 'papaparse';
import * as _ from 'underscore';
import { exists } from '../../utils/helpers';
import {
  noDataInResponse,
  getCrops,
  getCropsForCounty,
  getType,
  getPractice,
  getStateNames,
  getCountyNames,
  getPriceAndVolatility,
  getTYields,
  getTAFactor,
  getHarvestExclusionYears,
  getRAMARates,
} from '../../utils/dataFetchers';

/**
 * Get all crops available for insurance calculations in this county.
 * @param {String} stateCode code for selected state
 * @param {String} countyCode code for selected county
 * @param {String} year selected year
 * @return {Object} all crops and selected crop
 */
export const getInsuranceCropsForCounty = async (stateCode, countyCode, year) => {
  // const insuranceCrops = await getCrops(year);
  const insuranceCrops = await getCropsForCounty(stateCode, countyCode, year);
  // console.log("Call returns", insuranceCrops);
  // getCropsForCounty returns a csv, this will parse to array of objects
  const parsed = Papa.parse(insuranceCrops, {
    header: true,
  });
  // Parser returns an object, data we want is stored under key data.
  // Set crops and crop from parsed data
  if (exists(parsed?.data)) {
    const crops = parsed.data.sort((a, b) => a.Name.localeCompare(b.Name));
    const crop = chooseCrop(crops);
    return { crops, crop };
  }
  return noDataInResponse;
  // return { crops: parsed.data, crop: { CropCode: '', Name: '' } };
};

const chooseCrop = (crops) => {
  for (const crop of crops) {
    // If crop array contains Corn or Wheat, select as crop
    if (crop.Name === 'Corn' || crop.Name === 'Wheat') {
      return crop;
    }
  }
  // If no crop has been selected, return first in array
  return crops[0];
};

export const getInsuranceTypes = async (stateCode, cropCode, year, countyCode) => {
  const insuranceTypes = await getType(stateCode, cropCode, year, countyCode);
  // returns a csv, this will parse to array of objects
  const parsedTypes = Papa.parse(insuranceTypes, {
    header: true,
  });
  // Parser returns an object, data we want is stored under key data.
  // Set types and type from parsed data

  if (exists(parsedTypes?.data)) {
    return { types: parsedTypes.data, type: parsedTypes.data[0] };
  }
  // Default if none found matching in database
  const defaultType = { typeCode: '997', typeName: 'No Type Specified' };
  return { types: [defaultType], type: defaultType };
};

export const getCropPractices = async (stateCode, cropCode, year, countyCode) => {
  const cropPractices = await getPractice(stateCode, cropCode, year, countyCode);
  // returns a csv, this will parse to array of objects
  const parsedTypes = Papa.parse(cropPractices, {
    header: true,
  });
  // Parser returns an object, data we want is stored under key data.
  // Set types and type from parsed data
  if (exists(parsedTypes?.data)) {
    // get Index of non-irragted if exists in practices, to be used as default
    const nonIrrigated = parsedTypes.data.findIndex((x) => x.practiceCode === '3');

    // if non irrigated index not found, practice will be first entry in array
    const index = nonIrrigated !== -1 ? nonIrrigated : 0;
    return { practices: parsedTypes.data, practice: parsedTypes.data[index] };
  }
  // Default if none found matching in database
  const defaultPractice = { practiceCode: '997', practiceName: 'No Practice Specified' };
  return { practices: [defaultPractice], practice: defaultPractice };
};

export const getInsurancePriceAndVolatility = async (
  stateCode,
  cropCode,
  year,
  countyCode,
  typeCode,
  practiceCode,
) => {
  const updated = await getPriceAndVolatility(
    stateCode,
    cropCode,
    year,
    countyCode,
    typeCode,
    practiceCode,
  );
  // returns a csv, this will parse to array of objects
  const parsed = Papa.parse(updated, {
    header: true,
  });

  // Parser returns an object, data we want is stored under key data.
  // Set types and type from parsed data

  if (exists(parsed?.data)) {
    return processPriceAndVolatility(parsed.data[0]);
  }
  return null;
};

const processPriceAndVolatility = (data) => {
  let volatility = 0;
  let price = 0;
  // console.log(data)
  if (data.establishedPrice !== 'NULL') {
    price = data.establishedPrice;
  } else if (data.projectedPrice !== 'NULL') {
    price = data.projectedPrice;
  }

  if (data.pVol !== 'NULL') {
    volatility = data.pVol;
  }

  return { price, volatility };
};

export const getInsuranceLocationData = async (state, county) => {
  const states = await getStateNames();
  const counties = await getCountyNames();
  const stateCode = exists(states) ? getStateCode(states, state) : '';
  const countyCode = exists(counties) ? getCountyCode(counties, county, stateCode) : '';
  return { state: stateCode, county: countyCode };
};

/**
 * Gets codes for selected field's state and county
 * @param  {Object} locations    State object with codes as keys, names as values
 * @param  {String} userLocation Field's state
 * @return {[type]}              [description]
 */
const getStateCode = (locations, userLocation) => {
  for (const [code, name] of Object.entries(locations)) {
    if (name === userLocation) {
      return code;
    }
  }
};

/**
 * Select county by matching leading digits of couny code with state code.
 * @param  {Array} counties
 * @param  {String} userCounty
 * @param  {String} stateCode
 * @return {String} county code stripped of state code
 */
const getCountyCode = (counties, userCounty, stateCode) => {
  for (const [code, name] of Object.entries(counties)) {
    if (name === userCounty && countyInState(code, stateCode)) {
      return code.slice(stateCode.length);
    }
  }
};

const countyInState = (countyCode, stateCode) => (
  countyCode.slice(0, stateCode.length) === stateCode
);

export const getYield = async (code, year) => {
  const yields = await getTYields(code);
  // returns a csv, this will parse to array of objects
  const parsed = Papa.parse(yields, {
    header: true,
  });

  // Add yield type, default to A
  const yieldsWithType = parsed.data.map((x) => addYieldKeyValuePairs(x));

  // Find yield for selected commodity year
  const tYield = yieldsWithType.filter((x) => x.year === year.toString());
  // console.log(tYield)
  return {
    yields: yieldsWithType,
    selectedYield: tYield.length > 0 ? tYield[0] : null,
  };
};

const addYieldKeyValuePairs = (yieldObject) => ({ ...yieldObject, inputedYieldValue: '', yieldType: 'A' });

export const getFactor = async (code, year) => {
  const factors = await getTAFactor(code, year);
  // returns a csv, this will parse to array of objects
  const parsed = Papa.parse(factors, {
    header: true,
  });
  if (parsed.data.length > 0) {
    return Number(Number(parsed.data[0].factor).toFixed(2));
  }
  return null;
};

export const getExclusionYears = async (code, year) => {
  const factors = await getHarvestExclusionYears(code, year);
  // returns a csv, this will parse to array of objects
  const parsed = Papa.parse(factors, {
    header: true,
  });
  return parsed.data;
};

// Formats state, county, crop codes needed for api call
export function formatCode(numDigits, x) {
  try {
    return new Array(numDigits + 1 - (`${x}`).length).join('0') + x;
  } catch (e) {
    console.log(e);
    return x;
  }
}

// Creates the body of the calculateYieldFromHistory request 
export const createYieldBodyForHistory = (tYields, commodityYear) => {
  // Filter for years starting at commodityYear
  // Slice down to 12 entries then map to createYieldObjectForHistory
  const yieldsForDatabase = tYields.filter(
    (x) => +x.year <= commodityYear,
  ).slice(-12).map((x) => createYieldObjectForHistory(x));

  return yieldsForDatabase;
};

// Handles creating each individual yield object for each year for calculateYieldFromHistory request 
const createYieldObjectForHistory = (yieldObj) => {
  // If user has not entered a value it will be an empty, so set to -1
  const inputedYield = yieldObj.inputedYieldValue !== '' ? parseInt(yieldObj.inputedYieldValue) : -1;
  const excluded = yieldObj.exclusionApplied ? 1 : 0;
  
  const newYieldObj = {
    Excluded: excluded,
    TYield: +yieldObj.tYield,
    Year: +yieldObj.year,
    Yield: inputedYield,
    YieldType: getYieldTypeValue(yieldObj.yieldType),
  };

  return newYieldObj;
};

const getYieldTypeValue = (yieldType) => {
  const types = {
    A: 1,
    Other: 2,
    AY: 1,
    NA: 1,
    PA: 1,
    DA: 1,
    P: 0,
    J: 0,
    NW: 1,
    PW: 1,
    WY: 1,
    AX: 0,
  };
  return types[yieldType];
};


// -------------------- IMPORTS --------------------
// React
import React, {useState, useEffect} from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Modal,
  TextField,
  Tooltip,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import RefreshIcon from '@material-ui/icons/Refresh';
import SaveIcon from '@material-ui/icons/Save';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { darkText } from '../../../styles/colors';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

// Functionality
import { deleteCluAccess, getCluAccesses, saveCluAccess } from '../../../utils/dataFetchers';
import { convertToLocalDatetime } from '../helpers';
import { SpinningLoader } from '../../Shared/SpinningLoader';


// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  accessesRow: {
    display: 'flex',
    justifyContent: 'right',
    width: '798px',
  },
  body: {
    height: 'auto',
    maxHeight: '650px',
    overflowY: 'auto',
    width: '100%',
    padding: '0 20px',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  buttonBox: {
    ...theme.buttonBox,
    alignItems: 'center',
    height: '60px',
    marginTop: '24px',
  },
  cluElements: {
    padding: '8px',
    paddingBottom: '0px',
    height: '75.2px',
  },
  description: {
    fontSize: 14,
    color: theme.palette.text.grey,
  },
  head: {
    height: '50px',
    padding: '20px',
    alignItems: 'center',
    width: '100%',
    color: darkText,
    fontSize: 20,
    fontWeight: 500,
    display: 'flex',
  },
  interactionIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: 28,
    margin: '0 4px',
  },
  label: {
    fontSize: '.9rem',
    fontWeight: 500,
    // color: theme.palette.text.secondary,
  },
  paper: {
    ...theme.modalPaper,
    position: 'absolute',
    height: 'auto',
    width: '855px',
  },
  textInput: {
    height: 44.6,
    width: 250,
    backgroundColor: '#ffffff',
  },
}));


// -------------------- MAIN FUNCTION --------------------
/**
 * Rendered in AcreageReporting/AcreageReporting.js
 * Modal to edit who has access to user's CLU data
 * @param {Bool} editAccessesOpen Modal opened or closed
 * @param {Function} setEditAccessesOpen Set modal opened or closed
 * @returns {JSX} Modal
 */
export function EditCLUAccesses({
  editAccessesOpen,
  setEditAccessesOpen,
}) {
  // -------------------- VARIABLES --------------------
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(true);

  const [userAccesses, setUserAccesses] = useState(null);
  const [addingEntry, setAddingEntry] = useState(false);


  // -------------------- USE EFFECTS --------------------
  // Get the initial CLU_Accesses data for user
  useEffect(() => {
    getOwnerInfo();
  }, [])

  // Scroll to bottom when a new entry is being added
  useEffect(() => {
    if (addingEntry) {
      scrollToBottom();
    }
  }, [addingEntry])

  // -------------------- FUNCTIONALITY --------------------
  // Get all the info from CLU_Accesses table
  const getOwnerInfo = async () => {
    try {
      setLoading(true);
      const accesses = await getCluAccesses();
      // console.log('accesses :>> ', accesses);

      // Error check first
      if (typeof accesses === 'string') {
        enqueueSnackbar('We could not retrieve your data. Please try again later.');
        setUserAccesses('We could not retrieve your data. Please try again later.');
        return;
      }
      // Do not add new info entry if response was undefined
      else if (accesses?.length) {
        // If user was adding new entry info, get that info from userAccesses instead of losing it.
        if (addingEntry) {
          accesses.push(userAccesses[userAccesses.length-1]);
        }
      }

      setUserAccesses(accesses);
    } catch (err) {
      enqueueSnackbar('An error occured while loading data');
      console.error('An error occured while loading data: ', err);
    } finally {
      setLoading(false);
    }
  }

  // Gets the body by id
  const getBodyElement = () => {
    return document.getElementById('clu-accesses-body');
  }
  
  // Scroll to bottom
  const scrollToBottom = () => {
    const myElement = getBodyElement();
    if (myElement) { myElement.scrollTop = myElement.scrollHeight; } //0; }
    // If this doesn't work on a browser, might want to try other method found at:
    // https://stackoverflow.com/questions/19492776/how-to-reset-scroll-position-in-a-div-using-javascript/19493284
  }

  // Scroll to top
  const scrollToTop = () => {
    const myElement = getBodyElement();
    if (myElement) { myElement.scrollTop = 0; }
    // If this doesn't work on a browser, might want to try other method found at:
    // https://stackoverflow.com/questions/19492776/how-to-reset-scroll-position-in-a-div-using-javascript/19493284
  }

  // Deletes this access's details in CLU_Accesses table
  const deleteAccess = async (entryID) => {
    try {
      setLoading(true);
      const response = await deleteCluAccess({entryID});

      // Appropriately handle the response
      if (response) {
        enqueueSnackbar(response);
        getOwnerInfo();
        // scrollToTop(); // To make it obvious a change has happened.
      }
      else {
        enqueueSnackbar('An error occured while deleting');
      }
    } catch (err) {
      enqueueSnackbar('An error occured while deleting');
      console.error('An error occured while deleting: ', err);
    } finally {
      setLoading(false);
    }
  }

  // Saves (either updates or creates a new entry) this access's details in CLU_Accesses table
  const saveAccess = async (access) => {
    if (access.email_address === '') {
      enqueueSnackbar('Please enter an email_address before saving.');
      return;
    }

    try {
      setLoading(true);
      const response = saveCluAccess(access);

      // Appropriately handle the response
      if (response) {
        const result = await response;
        const resultBody = await result.json();

        // Handle an error
        if (result.status === 500) {
          enqueueSnackbar('An error occured while saving');
          console.error('An error occured while saving: ', resultBody);
        }
        else {
          enqueueSnackbar(resultBody);
          // If saving was successful, update entries to display new one and have it's entryID for deletion.
          if (result.status === 200) {
            getOwnerInfo();
            setAddingEntry(false);
            // scrollToTop(); // To make it obvious a change has happened.
          }
        }
      }
      else {
        enqueueSnackbar('An error occured while saving');
      }
    } catch (err) {
      enqueueSnackbar('An error occured while saving');
      console.error('An error occured while saving: ', err);
    } finally {
      setLoading(false);
    }
  }

  // Copies original list, updates the appropriate key and stores the update
  const updateAccesses = (key, index, value) => {
    const accessesCopy = cloneDeep(userAccesses);
    accessesCopy[index][key] = value;

    setUserAccesses(accessesCopy);
  }

  // Displays the date and time in the local timezone
  const dateDisplay = (date) => {
    try {
      const newDate = new Date(date);
      convertToLocalDatetime(newDate);
      return newDate.toLocaleString();
    } catch (err) {
      console.error(err);
      return date;
    }
  };

  // Display the info about each user who has access to owner's data
  const listUserAccess = (accessEntry, index) => {
    const newEntry = accessEntry.entryID === -1;

    return (
      <Box 
        key={index} 
        display="flex" 
        flexDirection="column" 
        alignItems="center"
        border={newEntry ? "1px solid rgba(200, 20, 20, 0.7)" : ""}
        pb={newEntry ? 1 : ""}
      >
        <Box className={classes.accessesRow} alignItems="center">
          {/* I should have at least 150px of space left */}

          <Box width="100%" m="20px 20px 0px" display="flex" justifyContent="space-between">
            {/* TODO: Buttons for saving changes and deleting entries - See PL for similar icons */}
            {/* Do NOT allow user to save without entering an email_address */}
            {/* When saving, it's probably best to reload all the data. Can make sure to get all new entryIDs that way */}
            {newEntry ?
              <Box color="rgba(200, 20, 20, 0.7)">
                NEW
              </Box>
            : 
              <Box> </Box>
            }

            {!newEntry &&
              <DeleteIcon
                className={classes.interactionIcon}
                onClick={() => deleteAccess(accessEntry.entryID)}
              />
            }
            
            <SaveIcon
              className={classes.interactionIcon}
              onClick={() => saveAccess(accessEntry)}
            />

          </Box>

          <Box className={classes.cluElements}>
            <Box className={classes.label}>Email Address *</Box>
            <TextField
              width="350px"
              variant="outlined"
              value={accessEntry.email_address}
              onChange={(e) => updateAccesses('email_address', index, e.target.value)}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                  width: 326,
                },
                maxLength: 255,
              }}
            />
          </Box>

          <Box className={classes.cluElements}>
            <Box className={classes.label}>User Name</Box>
            <TextField
              className={classes.textInput}
              variant="outlined"
              value={accessEntry.userName}
              onChange={(e) => updateAccesses('userName', index, e.target.value)}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                },
                maxLength: 50,
              }}
            />
          </Box>
        </Box>

        <Box className={classes.accessesRow}>
          <Box className={classes.cluElements}>
            <Box className={classes.label}>Comments</Box>
            <TextField
              className={classes.textInput}
              variant="outlined"
              value={accessEntry.comments || ''}
              onChange={(e) => updateAccesses('comments', index, e.target.value)}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                },
                maxLength: 100,
              }}
            />
          </Box>

          <Box className={classes.cluElements}>
            <Box className={classes.label}>Date Created</Box>
            <TextField
              className={classes.textInput}
              variant="outlined"
              value={newEntry ? accessEntry.agAnalyticsDateInserted : dateDisplay(accessEntry.agAnalyticsDateInserted)}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                },
              }}
              disabled
            />
          </Box>

          <Box className={classes.cluElements}>
            <Box className={classes.label}>Last Modified</Box>
            <TextField
              className={classes.textInput}
              variant="outlined"
              value={newEntry ? accessEntry.agAnalyticsDateModified : dateDisplay(accessEntry.agAnalyticsDateModified)}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                },
              }}
              disabled
            />
          </Box>
        </Box>

        {/* Bottom separator for different entries */}
        { (index < userAccesses.length - 1) && 
          <Divider style={{
            width: "798px", 
            margin: "16px 0px 8px 0px",
            backgroundColor: "rgba(0,0,0,0.6)",
          }}/>
        }
      </Box>
    )
  }

  // Add spots for user to create a new entry
  // Tell them "Please save the last new entry before adding another one". Keep track with var and reset on save and load data.
  const addNewEntry = () => {
    // Make sure to limit user to adding one new entry at a time. 
    if (addingEntry) {
      enqueueSnackbar('Please save the last new entry before adding another one');
      scrollToBottom();
      return;
    }

    const accessesCopy = cloneDeep(userAccesses);
    const now = new Date().toLocaleString();
    const newEntry = {
      agAnalyticsDateInserted: now,
      agAnalyticsDateModified: now,
      comments: '',
      email_address: '',
      entryID: -1,
      // isDeleted: ,
      userName: '',
    };
    accessesCopy.push(newEntry);
    setAddingEntry(true);
    setUserAccesses(accessesCopy);
  }

  const editAccesses = () => (
    <Modal
      open={editAccessesOpen}
      onClose={() => setEditAccessesOpen(false)}
      style={{display:'flex', alignItems:'center', justifyContent:'center'}}
    >
      <Box
        className={classes.paper}
        borderRadius="borderRadius"
      >
        {/* Modal Title */}
        <Box className={classes.head}>
          Edit CLU Permissions

          {/* Refresh button */}
          <RefreshIcon
            className={classes.interactionIcon}
            style={{marginLeft: "8px"}}
            onClick={async () => {
              await getOwnerInfo();
              enqueueSnackbar('Data refreshed');
            }}
          />

          {loading && <CircularProgress style={{margin: "auto"}}/>}

        </Box>

        <Box id="clu-accesses-body" className={classes.body}>
          <Divider />
          
          {
            // Show appropriate information based on state of userAccesses
            userAccesses === null ? (
              ''
            ) : userAccesses === undefined ? (
              <Box mt={3}>
                An error occured. You might be logged out. Please refresh the page and try again.
              </Box>
            ) : typeof userAccesses === 'string' ? (
              <Box mt={3}>
                {userAccesses}
              </Box>
            ) : userAccesses.length > 0 ? (
              userAccesses.map((accessEntry, index) => listUserAccess(accessEntry, index))
            ) : (
              // No records found in CLU_Accesses
              <Box mt={3}>
                No one has been given access to any existing CLU data associated with this account.
              </Box>
            )
          }
        </Box> {/* End of Body Box */}

        {/* Bottom footer */}
        <Box className={classes.buttonBox}>
          {/* Button for adding a new entry */}
          <Box m={1}>
            <Button
              variant="contained"
              color="secondary"
              onClick={() => addNewEntry()}
            >
              Add New Entry
            </Button>
          </Box>

          <Box m={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setEditAccessesOpen(false)}
              style={{backgroundColor: '#ffffff'}}
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  )


  // -------------------- RETURN --------------------
  return ( editAccesses() );
}

EditCLUAccesses.propTypes = {
  editAccessesOpen: PropTypes.bool.isRequired,
  setEditAccessesOpen: PropTypes.func.isRequired,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles, Button } from "@material-ui/core";
import { COLORS } from "../../../../constants";

const useStyles = makeStyles(() => ({
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    borderBottom: `1px solid ${COLORS.lightGray}`,
    justifyContent: "space-between",
  },
  title: ({ mobileView }) => ({
    color: COLORS.green,
    fontWeight: "normal",
    lineHeight: 1.34,
    fontSize: mobileView ? 24 : 32,
  }),
  buttonGroup: {
    display: "flex",
    flexDirection: "row",
    "& .MuiButtonBase-root": {
      color: COLORS.gray,
      fontWeight: "bold",
      fontSize: 20,
      borderRadius: 0,
      borderBottom: "4px solid transparent",
      textTransform: "none",
      paddingBottom: 20,
      "&.active": {
        color: COLORS.green,
        borderBottom: `4px solid ${COLORS.green}`,
      },
    },
  },
}));

/**
 * Renders the title container
 * @param {array} views array of objects with id, label and active properties
 * @param {function} setViews set state function to switch between overview and analytics
 * @param {string} activeYear the year
 * @param {boolean} mobileView for mobile views
 */

const TitleContainer = ({ views, setViews, activeYear, mobileView }) => {
  const classes = useStyles({ mobileView });

  return (
    <div className={classes.titleContainer}>
      <div className={classes.title}>{`Market ${activeYear} Report`}</div>
      {!mobileView && (
        <div className={classes.buttonGroup}>
          {views.map((view) => (
            <Button
              key={`button-for-${view.id}`}
              onClick={() =>
                setViews(
                  views.map((item) => ({ ...item, active: !item.active }))
                )
              }
              className={view.active ? "active" : ""}
            >
              {view.label}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export default TitleContainer;

TitleContainer.propTypes = {
  views: PropTypes.array.isRequired,
  setViews: PropTypes.func.isRequired,
  activeYear: PropTypes.string.isRequired,
  mobileView: PropTypes.bool.isRequired,
};

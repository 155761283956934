import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Slider } from '@material-ui/core';

export const MapSlider = withStyles({
  root: {
    color: '#67b444',
    height: '12px',
    width: '100%',
  },
  rail: {
    height: 0,
  },
  track: {
    height: 0,
  },
  thumb: {
    width: '3px',
    height: '40px',
    color: '#67b346',
    margin: '-10px 0 0 0',
    borderRadius: 0,
  },
  active: {},
  valueLabel: {
    transform: 'rotate(0deg)',
    left: -40,
    top: -15,
    '& *': {
      transform: 'rotate(0deg)',
      minWidth: '80px',
      height: '27px',
      borderRadius: '10px',
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
      border: 'solid 1px #67b346',
      backgroundColor: '#ffffff',
      color: '#67b346',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: 14,
    },
  }
})(Slider);

export const ElevationSlider = withStyles({
  root: {
    color: '#67b444',
    height: '12px',
    width: '100%',
  },
  rail: {
    height: 0,
  },
  track: {
    height: 0,
  },
  thumb: {
    width: '3px',
    height: '40px',
    color: '#67b346',
    margin: '-10px 0 0 0',
    borderRadius: 0,
  },
  active: {},
  valueLabel: {
    transform: 'rotate(0deg)',
    left: -40,
    top: -15,
    '& *': {
      transform: 'rotate(0deg)',
      minWidth: '120px',
      height: '27px',
      borderRadius: '10px',
      boxShadow: '0 1px 5px 0 rgba(0, 0, 0, 0.15)',
      border: 'solid 1px #67b346',
      backgroundColor: '#ffffff',
      color: '#67b346',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      fontWeight: 500,
      fontSize: 14,
    },
  }
})(Slider);

import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  Grid,
  Select,
  Typography,
  TextField,
  Slider,
  Menu,
  MenuItem,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import PublishIcon from '@material-ui/icons/Publish';
import UndoIcon from '@material-ui/icons/Undo';
import ClearIcon from '@material-ui/icons/Clear';
import SaveIcon from '@material-ui/icons/Save';
import Tooltip from '@material-ui/core/Tooltip';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
import CheckCircleOutlineSharpIcon from '@material-ui/icons/CheckCircleOutlineSharp';
import HighlightOffOutlinedIcon from '@material-ui/icons/HighlightOffOutlined';
import CancelIcon from '@material-ui/icons/Cancel';
import DoneIcon from '@material-ui/icons/Done';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { coordAll, polygon, getType } from '@turf/turf';
import { max } from 'date-fns/esm';
import { min } from 'date-fns';
import * as wkt from 'terraformer-wkt-parser';
import { useSnackbar } from 'notistack';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import { exists } from '../../utils/helpers';
import { FieldContext } from '../Context/FieldContext';
import { useWindowDimensions } from '../../utils/dimensions';
import { green } from '../../styles/colors';
import { Tiles } from '../../constants/Tiles';

const useStyles = makeStyles((theme) => ({
  mapControls: theme.mapControls,
  buttonBox: {
    width: '100%',
    backgroundColor: theme.palette.greys.light,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    color: theme.palette.greys.dark,
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  greenIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
    textTransform: 'none',
  },
  sizeSlider: {},
  otherSlider: {
    marginLeft: ' 20px',
    marginRight: '20px',
    flex: 2,
    flexDirection: 'row',
  },
}));
let zoneNum = 1;
let EWoffset = 0;
let NSoffset = 0;
let rotateVal = 0;
let gridSize = 0;
let slider1;
let slider2;
let slider3;
let slider4;
let selected = {};
let userDrawnZone = [];
// -----------------------------------colors to be used by SSURGO, Split Field, Grid-------------------------------------
const fillColors = [
  '#c34016',
  '#f3750e',
  '#f4ae62',
  '#fff200',
  '#ccee5f',
  '#69dc27',
  '#97e8d1',
  '#13a28c',
  '#3053c9',
  '#5712da',
  '#e74daa',
  '#742a4d',
];

const zonesColors = [
  '#c34016',
  '#f3750e',
  '#f4ae62',
  '#fff200',
  '#ccee5f',
  '#69dc27',
  '#97e8d1',
  '#13a28c',
  '#3053c9',
  '#5712da',
  '#e74daa',
  '#742a4d',
];

let usedColors = []; // so we dont accidentally repeat colors

/**
 * Handle all user interactions with drawing and editing polygons.
 * Function handleZone to allow parent function to interact with drawn layers.
 * It takes two paramters. The first can vary in structure based on the second,
 * which is the action the function will perform.
 */
export function ZoneAnalysisMap({
  canEdit,
  isOperationMap,
  operationMap,
  selectedOption,
  listOfZonesDrawn,
  userSavedZones,
  ssurgoLayers,
  containerHeight,
  containerWidth,
  mapLoading,
  drawingZones,
  maxZoom,
  clearLegend
}) {

  // Anchor to tool menu
  // console.log('drawingZones', drawingZones, ssurgoLayers)
  const classes = useStyles();
  const mobileBreakPoint = 680;
  const [anchorEl, setAnchorEl] = useState(null);
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [fieldData, setFieldData] = useContext(FieldContext);
  const [map, setMap] = useState(null);
  const [zone, setZone] = useState({});
  // const [drawnZones, setDrawnZones] = useState([]);
  const drawnZones = useRef([]);
  const [fieldGeo, setFieldGeo] = useState();
  const [gridSlider, setGridSlider] = useState(true);
  const gridlayer = useRef(L.geoJSON(null));
  const createdZones = useRef(L.geoJSON(null));
  const [zoneToolsDropdown, setZoneToolsDropdown] = useState(null); // is it open or not
  // const [gridSize, setGridSize] = useState();
  let fillColor = '#E7745F';
  // const [selected, setSelected] = useState();
  const [tooltip, setToolTip] = useState(`Editing Zone: ${zoneNum}`);
  const [activeTool, setActiveTool] = useState(''); // "grid, polygon, *the new tool tim made*"
  const [cancelButton, setCancelButton] = useState(false);
  // to handle proper state updating for passing up
  const [drawnLayer, setDrawnLayer] = useState(null);
  // possible to edit multiple before clicking finish
  // store them here, when finished useEffect to update
  // drawnZones appropriately
  const [editedZones, setEditedZones] = useState([]);
  const savedLayers = useRef(L.geoJSON(null));

  const imageOverlay = useRef(null);
  const [mapImage, setMapImage] = useState('');
  const [imageBounds, setImageBounds] = useState([]);

  const numberOfZones = useRef(0);
  const maxZoneNum = useRef(1);

  const fieldLayer = L.featureGroup(null);
  const drawnField = useRef(null);
  const ssurgoMapLayer = useRef(L.geoJSON(null));


  const [slicing, setSlicing] = useState(false);
  const slicingMode = useRef(false);
  const currentSlice = useRef(null);
  const slicedZones = useRef([]);
  const slicedMap = useRef(L.geoJson(null));
  const [isDrawZone, setDrawZone] = useState(false);
  const [userEnteredZoneName, setUserEnteredZoneName] = useState('');
  const [selectedZone, setSelectedZone] = useState('');
  // const [userDrawnZone, setUserDrawnZone] = useState([])
  const [typeSelected, setTypeSelected] = useState('');
  const [zoneLayers, setZoneLayers] = useState([]);

  const [saveBtn, setSaveBtn] = useState(true);

  const drawnPolygons = useRef(L.featureGroup(null));

  const editedFieldGeo = useRef(null);


  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (activeTool !== '') {
      setCancelButton(true);
    }
  }, [activeTool]);

  useEffect(() => {
    // console.log('map', map)
    // console.log('selectedOption', selectedOption)
    // console.log('fieldData.selectedField', fieldData, fieldData.selectedField)
    // console.log('fieldData.selectedField.boundary', fieldData.selectedField.boundary)
    if (
      exists(fieldData.selectedField)
      && exists(fieldData.selectedField.boundary)
      && map !== null
    ) {
      // console.log('map', map)
      // console.log('inside useEffect')
      map.eachLayer((layer) => {
        if (layer._url === undefined) {
          map.removeLayer(layer);
        }
      });

      const features = JSON.parse(fieldData.selectedField.boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      map.on('pm:drawstart', ({ workingLayer }) => {
        workingLayer.on('pm:vertexadded', (e) => {
          handleSliceVertex(e);
        });
      });
      drawField(geoJson);
      setFieldGeo(geoJson);
    }
  }, [fieldData, map]);

  useEffect(() => {
    if (map !== null & drawingZones === 'draw') {
      // console.log("in draw zone useEffect")
      clear(true);
      map.eachLayer((layer) => {
        if (layer._url === undefined) {
          map.removeLayer(layer);
        }

        const features = JSON.parse(fieldData.selectedField.boundary);
        const geoJson = {
          type: 'FeatureCollection',
          features: [features],
        };
        // map.on("pm:drawstart", ({ workingLayer }) => {
        //   workingLayer.on("pm:vertexadded", (e) => {
        //     handleSliceVertex(e);
        //   });
        // });
        drawField(geoJson);
        setFieldGeo(geoJson);
      });
    }
  }, [map, drawingZones]);

  useEffect(() => {
    if (isOperationMap && operationMap.length > 0 && exists([map])) {
      // console.log("Adding map image")
      clearPreviousLayers();

      const extent = operationMap[0].extent === undefined
        ? operationMap[0].Extent
        : operationMap[0].extent;
      const pngb64 = operationMap[0].pngb64 === undefined
        ? operationMap[0].Pngb64
        : operationMap[0].pngb64;
      const bounds = extent.split(',');
      const imageBounds = [
        [parseFloat(bounds[3]), parseFloat(bounds[0])],
        [parseFloat(bounds[1]), parseFloat(bounds[2])],
      ];

      const mapImage = `data:image/jpeg;base64,${pngb64}`;
      setMapImage(mapImage);
      setImageBounds(imageBounds);
    }
  }, [operationMap, map]);

  useEffect(() => {
    if (map !== null && mapImage !== '' && exists(imageBounds)) {
      if (imageOverlay.current === null) {
        imageOverlay.current = L.imageOverlay(mapImage, imageBounds, {
          opacity: 0.8,
          interactive: true,
        });
        imageOverlay.current.addTo(map);
      } else {
        imageOverlay.current.setUrl(mapImage);
        imageOverlay.current.setBounds(imageBounds);
        imageOverlay.current.addTo(map);
      }
    }
  }, [mapImage, imageBounds, map]);

  useEffect(() => {
    exists(zone) && handleZone(zone, 'add');
  }, [zone]);

  useEffect(() => {
    if (drawnLayer !== null) {
      drawnZones.current = [...drawnZones, drawnLayer];
    }
  }, [drawnLayer]);

  useEffect(() => {
    if (ssurgoLayers.length > 0 && map !== null) {
      // console.log("ssurgo layers",ssurgoLayers)
      for (let i = 0; i < ssurgoLayers.length; i++) {
        setZone(ssurgoLayers[i]);
      }
      const geoJson = {
        type: 'FeatureCollection',
        features: ssurgoLayers,
      };
      drawSsurgo(geoJson)
    }
  }, [ssurgoLayers, map]);

  useEffect(() => {
    if (!gridSlider) {
      addGridControls();
    }
  }, [gridSlider]);

  useEffect(() => {
    let flag = false;
    try {
      selected.forEach((item) => {
        if (item.selected === true && item.zone == zoneNum) {
          flag = true;
        }
      });
    } catch (err) { }
    if (zoneNum > maxZoneNum.current) {
      maxZoneNum.current = zoneNum;
    }
  }, [zoneNum]);

  // useEffect(() => {
  //   // when zones are edited, update drawn zones and get updated features
  //   // for handleZone to update at top level.
  //   if (editedZones.length > 0) {
  //     const editDrawnZones = [...drawnZones];
  //     const editedFeatures = [];
  //     for (const edited of editDrawnZones) {
  //       let index = drawnZones.findIndex(
  //         (x) => x._leaflet_id === edited._leaflet_id
  //       );
  //       const feature = getFeature(edited);
  //       editedFeatures.push(feature);
  //     }
  //     drawnZones.current = editDrawnZones;
  //     handleZone(editedFeatures, "edit");
  //   }
  // }, [editedZones]);

  // useEffect(() => {
  //   clearPreviousLayers();
  //   if (selectedOption.value !== "drawZones") {
  //     setDrawZone(false)
  //   }
  //   else {
  //     setDrawZone(true)
  //   }
  // }, [selectedOption])

  useEffect(() => {
    clearPreviousLayers();
    if (drawingZones) {
      setDrawZone(true);
    } else {
      setDrawZone(false);
    }
  }, [drawingZones]);

  const updateZone = (editedZone, drawnZoneCopy) => {
    const index = drawnZones.findIndex(
      (x) => x._leaflet_id === editedZone._leaflet_id,
    );
    drawnZoneCopy[index] = editedZone;
  };

  const clearPreviousLayers = () => {
    setUserEnteredZoneName('');
    if (userDrawnZone.length > 0) {
      userDrawnZone = [];
      clear();
    }
  };

  const getFeature = (layer) => {
    const coordinates = layer._latlngs[0].map((x) => [x.lng, x.lat]);
    coordinates.push(coordinates[0]); // ensure closed polygon
    const acres = turf.area(turf.polygon([coordinates])) / 4047;

    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
      properties: {
        CALCACRES: acres,
      },
    };

    return feature;
  };

  const labels = ['0.25AC', '0.5AC', '1AC', '2AC', '4AC', '5AC', '10AC'];

  const marks = [
    {
      value: 1,
      label: '',
      // label: '0.25',
    },
    {
      value: 2,
      label: '',
      // label: '0.5',
    },
    {
      value: 3,
      label: '',
      // label: '1',
    },
    {
      value: 4,
      label: '',
      // label: '2',
    },
    {
      value: 5,
      label: '',
      // label: '4',
    },
    {
      value: 6,
      label: '',
      // label: '5',
    },
    {
      value: 7,
      label: '',
      // label: '5',
    },
  ];

  const gridSliderValue = (newvalue) => {
    let gridVal = 0;
    switch (newvalue) {
      case 1:
        gridVal = 0.032;
        break;
      case 2:
        gridVal = 0.045;
        break;
      case 3:
        gridVal = 0.0638;
        break;
      case 4:
        gridVal = 0.09;
        break;
      case 5:
        gridVal = 0.128;
        break;
      case 6:
        gridVal = 0.1421;
        break;
      case 7:
        gridVal = 0.201;
        break;
      default:
        gridVal = 0.0638;
        break;
    }
    addGrid(gridVal, true);
  };

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('zoneAnalysisMap')
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    map.pm.addControls({
      drawMarker: false,
      drawCircle: false,
      drawCircleMarker: false,
      drawRectangle: true,
      dragMode: true,
      drawPolygon: true,
      cutPolygon: true,
      editPolygon: true,
      drawPolyline: true,
      deleteLayer: true,
    });

    map.on('pm:create', (e) => {
      try {
        // register event handler for polygon drawing
        if (e.shape !== 'Line') {
          const polyLayer = e.layer;
          const feature = getFeature(polyLayer);
          feature.properties.mapRef = map;
          setZone(feature);
          // setDrawnLayer(polyLayer);

          polyLayer.on('pm:edit', (layer) => {
            setEditedZones([...editedZones, layer.target]);
          });
          // add new polygon to the featureGroup
          const newPoly = L.geoJSON(feature);
          drawnPolygons.current.addLayer(newPoly);
          let x = 0;
          drawnPolygons.current.eachLayer((layer) => {
            layer.eachLayer((innerLayer) => {
              if (usedColors.includes(zonesColors[x])) {
                innerLayer.feature.properties.color = getRandomColor();
              } else {
                innerLayer.feature.properties.color = zonesColors[x];
                usedColors.push(zonesColors[x]);
              }
            });
            x++;
          });

          colorZones([], 'draw');
          map.removeLayer(polyLayer);
          map.addLayer(drawnPolygons.current);

          setCancelButton(false);
        } else {
          map.removeLayer(e.layer);
        }
      } catch (e) {
        console.log('check for circles');
        console.log(e);
      }
    });
    if (maxZoom) {
      map.setMaxZoom(maxZoom);
    }
    setMap(map);
  }

  function drawField(geo, isSelectedZone = false, isSsurgo = false) {

    const { geometry } = geo.features[0];
    let boundary = null
    if(isSsurgo){
      boundary = geo
    }
    else{
      boundary = geometry.type === 'Polygon'
      ? turf.polygon(geo.features[0].geometry.coordinates)
      : turf.multiPolygon(geo.features[0].geometry.coordinates);
    }
    
    if(drawnField.current !== null){
      if(map.hasLayer(drawnField.current)){
        drawnField.current.remove()
      }
    }
    else{
      drawnField.current = L.geoJson(boundary, {
        onEachFeature: (feature, layer) => {
          fieldLayer.addLayer(layer).setStyle({
            fillOpacity: 0,
          });
          // console.log("geo", geo, layer)
  
          if (geo.features[0].properties.Soil_Type !== undefined) {
            const customPopup = `<span>${geo.features[0].properties.Soil_Type}</span>`;
            // specify popup options
            const customOptions = { className: 'popupCustom' };
            layer.bindPopup(customPopup, customOptions);
          }
        },
      })
    }
    drawnField.current.addTo(map)


    if (!isSelectedZone) {
      map.fitBounds(drawnField.current.getBounds());
    }
  }


  function drawSsurgo(geo){
    // console.log("Drawing SSURGO", geo)
    if(map.hasLayer(ssurgoMapLayer.current)){
      ssurgoMapLayer.current.remove()
    }
    ssurgoMapLayer.current.clearLayers()
    ssurgoMapLayer.current = L.geoJson(geo, {
      onEachFeature: (feature, layer) => {
        console.log("Feature", feature)
        fieldLayer.addLayer(layer).setStyle({
          fillOpacity: 0,
        });
        // console.log("geo", geo, layer)

        if (feature.properties.Soil_Type !== undefined) {
          const customPopup = `<span>${feature.properties.Soil_Type}</span>`;
          // specify popup options
          const customOptions = { className: 'popupCustom' };
          layer.bindPopup(customPopup, customOptions);
        }
      },
    })
    ssurgoMapLayer.current.addTo(map)
  }


  const addPolygon = () => {
    setCancelButton(true);
    setActiveTool('polygon');

    // start polygon draw
    const draw = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polygon',
    );
    draw[0].click();
  };

  const handleClick = (event) => {
    setZoneToolsDropdown(event.currentTarget);
  }; // for the dropdown

  const handleClose = () => {
    setZoneToolsDropdown(null);
  }; // for the dropdown

  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  /**
   * Basic function to take an integer value in acres and convert it to Km
   * @param {int} value
   */
  const acToGrid = (value) => {
    const val = Math.sqrt(turf.convertArea(value, 'acres', 'kilometers'));
    addGrid(val, true);
  };

  /**
   * after zones are created from a drawing tool and sent to the zone handler with the unique
   * colors in the properties, we can take an array of colors and the name of a tool
   * then we iterate through the zones on the corresponding layer and apply styling
   * @param {string array} zoneColors
   * @param {string) tool either grid or slice
    * @param {optional array of features} existing  FOR SLICE ONLY - Deprecated
    */
  const colorZones = (zoneColors, tool, existing = null) => {
    let x = 0;
    switch (tool) {
      case 'grid':
        createdZones.current.eachLayer((layer) => {
          const zoneTheme = {
            color: zoneColors[x],
            fillColor: zoneColors[x],
            fillOpacity: '0.3',
            strokeWidth: '5',
          };
          x++;
          layer.setStyle(zoneTheme);
        });
        break;
      case 'slice':
        const newZonesNum = zoneColors.length;
        slicedMap.current.eachLayer((layer) => {
          if (x <= newZonesNum) {
            var zoneTheme = {
              color: zoneColors[x],
              fillColor: zoneColors[x],
              fillOpacity: '0.3',
              strokeWidth: '5',
            };
          }
          x++;
          layer.setStyle(zoneTheme);
        });
        break;
      case 'draw':
        x = 0;
        drawnPolygons.current.eachLayer((layer) => {
          const zoneTheme = {
            color: zonesColors[x],
            fillColor: zonesColors[x],
            fillOpacity: '0.3',
            strokeWidth: '5',
          };
          layer.setStyle(zoneTheme);
          x++;
        });
    }
  };

  /**
   * This is the main function for the grid tool. Anytime the grid moves, or changes it gets re-rendered here
   * This will handle styling so that clicking next zone will start highlighting in a new color, it will also create
   * a bounding box for a field and apply changes to it to reflect the movements made with the controls
   * i.e. rotate, shift x or y and change size (e)
   * @param {int} e this is the size of the grid, it has a default value in case the grid accidentally
   * gets re rendered with no size set
   * @param {bool} firstRender this exists because if its the first time drawing there grid there are some
   * things have to happen a little differently
   */
  const addGrid = (e = 0.0636149354, firstRender = false) => {
    setActiveTool('grid');
    setCancelButton(true);
    // lets begin by clearing any old grids, and revealing controls
    gridSize = e;
    gridlayer.current.clearLayers();
    setGridSlider(false);
    const geo = fieldGeo;

    const bbox = turf.bbox(fieldGeo);
    bbox[0] += -0.002 + EWoffset;
    bbox[1] += -0.002 + NSoffset;
    bbox[2] += 0.002 + EWoffset;
    bbox[3] += 0.002 + NSoffset;

    const cellSide = e;
    const squareGrid = turf.squareGrid(bbox, cellSide);

    // Now we will get intro tracking grid tiles
    let selectedTilesData = [];

    if (firstRender === true) {
      zoneNum = 1;
      setToolTip(`Editing Zone: ${zoneNum}`);
      // if its the first time drawing the grid, lets create the dictionary
      selectedTilesData = squareGrid.features.map((feature, index) => {
        const data = {
          feature, selected: false, key: index, zone: -1,
        };
        return data;
      });
    } else {
      // we have re-drawn/re-rendered the map, lets update coordinates of the dictionary instead
      selectedTilesData = selected;
      for (let x = 0; x < selectedTilesData.length; x++) {
        selected[x].feature = squareGrid.features[x];
      }
    }

    if (rotateVal != 0) {
      const pivot = turf.center(squareGrid); // This should be the center of the entire square grid
      turf.featureEach(squareGrid, (currentFeature, featureIndex) => {
        // for-each feature/square
        const options = { pivot, mutate: true };
        const tempFeature = currentFeature; // grab the current square
        squareGrid.features[featureIndex] = turf.transformRotate(
          tempFeature,
          rotateVal,
          options,
        );
        const rotatedCopy = turf.transformRotate(tempFeature, rotateVal, options);

        try {
          selected[featureIndex].feature = rotatedCopy;
        } catch (err) {}
      });
    }
    gridlayer.current.addData(squareGrid);

    // Here we will get into styling the tiles based on if they are selected or not etc...
    gridlayer.current.eachLayer((layer) => {
      const ndx = gridlayer.current.getLayers().indexOf(layer);
      const selectedBool = selectedTilesData[ndx].selected;

      const layerZnum = selectedTilesData[ndx].zone;
      fillColor = fillColors[zoneNum];
      layer.setStyle({
        weight: 0.75,
        color: 'white',
        opacity: 0.75,
        fillOpacity: '0.2',
      });

      if (selectedBool) {
        layer.setStyle({
          fillColor: fillColors[layerZnum],
          fillOpacity: '0.6',
        });
      }

      layer.on('mouseover', function () {
        if (layerZnum > 0) {
          this.setStyle({
            fillColor: fillColors[layerZnum],
          });
        } else {
          this.setStyle({ fillColor, fillOpacity: '0.6' });
        }
      });

      layer.on('mouseout', function () {
        const ndx = gridlayer.current.getLayers().indexOf(this);
        const selectedBool = selectedTilesData[ndx].selected;
        if (selectedBool === false) {
          this.setStyle({
            fillColor: 'white',
            fillOpacity: '0.2',
          });
        }
      });

      layer.on('click', function (e) {
        const ndx = gridlayer.current.getLayers().indexOf(this);
        const selectedBool = selectedTilesData[ndx].selected;

        if (selectedBool === false) {
          selectedTilesData[ndx].selected = true;
          selectedTilesData[ndx].zone = zoneNum;
          selected = selectedTilesData;
          e.target.setStyle({
            fillColor,
            fillOpacity: '0.6',
          });
        } else {
          selectedTilesData[ndx].selected = false;
        }
      });
    });
    // once its all said and done, lets copy that dictionary to a state variable so we dont lose it
    selected = selectedTilesData;
    gridlayer.current.addTo(map);
  };

  /**
   * Here is the grand finale for the grid tool. After zones have been created with the grid and the finish button
   * gets clicked, this function will combine the tiles into larger features and pass them on to the zone handler
   * with the information needed for colors and deleting functionality and then it will remove the grid tools from the screen
   * putting the user into a finished editing view
   *
   */
   const createGridZones = () => {
    const zonesToMerge = maxZoneNum.current; // number of zones we're creating
    const zones = [];
    const allTiles = selected;
    const selectedTiles = [];
    let maskedZones = [];

    // -----------------check if we have selected any tiles-----------------------
    let numTilesSelected = 0;
    for (var x = 0; x < allTiles.length; x++) {
      if (allTiles[x].selected === true) numTilesSelected++;
    }

    // after check that we have selected a tile - push empty arrays
    // that structure will look like: [[], [], []...]
    if (numTilesSelected > 0) {
      for (var x = 0; x < zonesToMerge; x++) {
        selectedTiles.push([]);
      }
      //----------------------------------------------------------------------------

      // now that we set up nested arrays lets make that previous structure look like this:
      //  [ [zone1 feature, zone1 feature...], [zone2 feature, zone2 feature...] ...]
      for (var x = 0; x < allTiles.length; x++) {
        const currentTile = allTiles[x];
        if (currentTile.selected === true) {
          const zoneNdx = currentTile.zone - 1;
          selectedTiles[zoneNdx].push(currentTile.feature);
        }
      }

      try {
      //----------------------------------------------------------------------------
      // here is where we are combining the polygons to make zones
      // [ [big zone1 polygon], [big zone2 polygon] ... ]
        for (var x = 0; x < zonesToMerge; x++) {
          const featureList = selectedTiles[x];
          const tempZone = turf.union(...featureList);
          zones.push(tempZone);
        }
      } catch (err) {
        console.log(err)
        enqueueSnackbar('Something Went Wrong Clipping Your Field to the Boundary');
      }
      //----------------------------------------------------------------------------
      // At this point we have an array with sub-arrays per zone containing big polygons
      // next is to mask them so that they exist only within the field boundaries

      let fieldMask = {};
      const polysForField = [];

      // five potential field boundary cases:
      // normal field, single polygon
      // single field, multi polygon (holes)
      // multi boundary, (only single poly boundaries)
      // multi boundary, single poly and multi poly boundaries
      // multi boundary, only multi poly boundaries
      let clippedZones = [];
      try {
        if (fieldGeo.features[0].geometry.coordinates.length > 1) {
          // loop over suspected multi poly coords
          const fieldDifferences = [];
          for (let j = 0; j < fieldGeo.features[0].geometry.coordinates.length; j++) {
            const multiCoords = fieldGeo.features[0].geometry.coordinates[j];
            console.log('multi coords', multiCoords);
            let poly;
            try {
              if (multiCoords.length > 1) {
                poly = turf.polygon([multiCoords]);
              } else {
                poly = turf.polygon(multiCoords);
              }
              // poly = turf.polygon(multiCoords)
            } catch {
              poly = null;
            }
            console.log('poly', poly);

            // check to see if multiCoords was for a boundary or an actual multi
            if (poly !== null) {
              // need to distinguish multi polygon boundary from regular boundary.
              if (poly.geometry.coordinates.length > 1) { // boundary is multi poly
                console.log('MULTI POLY FROM MULTI BOUNDARY');
                // loop over boundary coordinates here, skip first becuase it is outer boundary
                const differences = []; // all differences between holes of multi and zones
                console.log(poly.geometry.coordinates.length);
                for (let i = 1; i < poly.geometry.coordinates.length; i++) {
                  const tempPoly = turf.polygon([poly.geometry.coordinates[i]]);
                  console.log('tempPoly', tempPoly);
                  for (const zone of zones) {
                    if (turf.intersect(zone, tempPoly) !== null) {
                      console.log('adding difference for multi');
                      differences.push(turf.difference(zone, tempPoly));
                    }
                  }
                }
                console.log('differences', differences);
                // if differences exist, now apply them to the outer boundary
                if (differences.length > 0) {
                  const outerBoundary = turf.polygon([poly.geometry.coordinates[0]]);
                  for (const diffZone of differences) {
                    const outterClip = turf.intersect(diffZone, outerBoundary);
                    if (outterClip !== null) {
                      clippedZones.push(outterClip);
                    }
                  }
                }
                console.log('clipped', clippedZones);
              } else {
                // if boundary is single polygon
                console.log('SINGLE POLYGON FROM MULTI BOUNDARY');
                const tempPoly = poly;
                const clips = zones.map((x) => turf.intersect(x, tempPoly)).filter((i) => i !== null);
                clippedZones = [...clippedZones, ...clips];
              }
            } else {
              // if not a multi boundary and just multi poly:
              if (j !== 0) {
                // still skip first to avoid outer boundary
                console.log(multiCoords);
                const tempPoly = turf.polygon([multiCoords]);
                console.log('tempPoly', tempPoly);
                // list of differnce between current hole and all drawn zones
                for (const zone of zones) {
                  if (turf.intersect(zone, tempPoly) !== null) {
                    // console.log("pushing to fieldDifference")
                    fieldDifferences.push(turf.difference(zone, tempPoly));
                  }
                }
                // let holeDiff = zones.map(x => turf.difference(x, tempPoly)).filter(p => p!==null);
                // fieldDifferences = [...fieldDifferences, ...holeDiff]
                // console.log(fieldDifferences)
              }
              // if j === coord length then do the outer bounds clipping after all hole clipping is done
              console.log(j, fieldGeo.features[0].geometry.coordinates.length);
              if (j === fieldGeo.features[0].geometry.coordinates.length - 1) {
                if (fieldDifferences.length > 0) {
                  const outerBoundary = turf.polygon([fieldGeo.features[0].geometry.coordinates[0]]);
                  // console.log("outerBoundary",outerBoundary)
                  for (const diffZone of fieldDifferences) {
                    const outterClip = turf.intersect(diffZone, outerBoundary);
                    if (outterClip === null) {
                      // if outter clip is null then the
                    }
                    clippedZones.push(outterClip);
                  }
                }
              }
            }
          }
        } else { // Just a single polygon single field
          // if not a multi polygon just intersect to field
          console.log('simple', fieldGeo.features[0]);
          const tempPoly = fieldGeo.features[0];
          const clips = zones.map((x) => turf.intersect(x, tempPoly)).filter((i) => i !== null);
          clippedZones = [...clippedZones, ...clips];
        }
      } catch (err) {
        console.log(err);
        return;
      }
      console.log('clipped zones', clippedZones);
      // compare clipped zones with eachother to see if any are overlapping and take difference
      if (clippedZones.length > 1) {
        try {
          const intersected = [];
          for (let i = 0; i < clippedZones.length; i++) {
            let evalZone = clippedZones[i];
            if (!intersected.includes(i)) {
              for (let j = i + 1; j < clippedZones.length; j++) {
                const clippedIntersect = turf.intersect(evalZone, clippedZones[j]);
                console.log('clipped intersect', clippedIntersect);
                if (clippedIntersect !== null
                  && (clippedIntersect.geometry.type.toLowerCase() === 'polygon' || clippedIntersect.geometry.type.toLowerCase() === 'multipolygon')) {
                  evalZone = turf.difference(evalZone, clippedZones[j]);
                  intersected.push(j);
                }
              }
              maskedZones.push(evalZone);
            }
          }
        } catch (err) {
          console.log(err);
          maskedZones = clippedZones;
        }
      } else {
        maskedZones = clippedZones;
      }

      console.log('MaskedZones', maskedZones);
      
      const forZoneHandler = [];
      const zoneColors = [];
      const allZones = []; // old zones plus new zones
      try {
        for (var x = 0; x < maskedZones.length; x++) {
          const tempFeature = {
            type: 'Feature',
            geometry: maskedZones[x].geometry,
            properties: {
              featureRef: createdZones,
              mapRef: map,
              CALCACRES: turf.convertArea(
                turf.area(maskedZones[x]),
                'meters',
                'acres',
              ),
              zone: x + 1,
            },
          };
          forZoneHandler.push(tempFeature);
        }
      } catch (err) {
        console.log(err);
        return;
      }

      allZones.push(...forZoneHandler);
      createdZones.current.eachLayer((layer) => {
        allZones.push(layer.feature);
      });

      let y = numberOfZones.current;
      for (const zone of allZones) {
        // console.log("all zone loop: ", zone);
        const tempColor = zonesColors[y];
        zone.properties.color = tempColor;
        zoneColors.push(tempColor);
        usedColors.push(tempColor);
        y++;
      }
      let colorOffset = numberOfZones.current;
      createdZones.current.eachLayer((layer) => {
        colorOffset++;
      });

      let z = 0;
      for (const zone of forZoneHandler) {
        zone.properties.color = zonesColors[z + colorOffset];
        z++;
      }

      // send our data to the handler
      const forZones = forZoneHandler.map((x) => ({ zone: x, type: 'add' }));
      //setZones(forZones);
      userDrawnZone = forZones

      // Track number of zones created
      numberOfZones.current += forZones.length;

      // cleanup the map and reset states
      clearGrid();

      gridlayer.current.eachLayer((layer) => {
        layer.feature.properties.id = layer._leaflet_id;
      });

      gridlayer.current.clearLayers();

      forZoneHandler.map((zone) => createdZones.current.addData(zone));

      // console.log(createdZones.current)
      createdZones.current.eachLayer((layer) => {
        layer.feature.properties.id = layer._leaflet_id;
        layer.feature.featureRef = createdZones;
      });
      // console.log("CREATED ZONES", createdZones.current)
      drawnZones.current = [createdZones.current];
      createdZones.current.addTo(map);
      colorZones(zoneColors, 'grid');
      setCancelButton(false);
      setActiveTool('');
      //setCreatingZones(false);
    } else {
      clearGrid();
    }
  };

  const addGridControls = () => {
    // grid size
    slider1 = L.control.slider((value) => { acToGrid(value); }, {
      id: slider1, orientation: 'horizontal', position: 'topleft', title: 'Grid Size', min: 0.5, max: 10, step: 0.5, value: 2, collapsed: false, increment: true, size: '200px', logo: 'S',
    });

    // grid rotation
    slider2 = L.control.slider((value) => { handleRotateSlider(parseInt(value)); }, {
      id: slider2, orientation: 'horizontal', position: 'topleft', title: 'Rotate Grid', min: -90, max: 90, step: 0.5, value: 0, collapsed: true, increment: true, size: '400px', logo: 'R',
    });

    // move E/W
    slider3 = L.control.slider((value) => { moveGrid(parseFloat(value), gridSize, 'x'); }, {
      id: slider3, orientation: 'horizontal', position: 'topleft', title: 'Move East/West', min: -0.005, max: 0.005, step: 0.00006, value: 0, collapsed: true, size: '400px', increment: true, logo: 'X',
    });

    // move N/S
    slider4 = L.control.slider((value) => { moveGrid(parseFloat(value), gridSize, 'y'); }, {
      id: slider4, orientation: 'horizontal', position: 'topleft', title: 'Move North/South', min: -0.005, max: 0.005, step: 0.00006, value: 0, collapsed: true, increment: true, size: '450px', logo: 'Y',
    });

    if (width > mobileBreakPoint) {
      slider1.addTo(map);
    }

    slider2.addTo(map);
    slider3.addTo(map);
    slider4.addTo(map);
  };

  const removeGridControls = () => {
    slider1.remove();
    slider2.remove();
    slider3.remove();
    slider4.remove();
  };

  // returns the number of shared edges and array of who it shares edges with
  const sharedEdges = (newTile, allTiles) => {
    const returnArry = []; // 0 = shared edges, 1 neighbors array
    const edgeFriends = [];
    newTile = newTile.coordinates[0];
    let matches = 0;

    for (let j = 0; j < allTiles.length; j++) {
      // list of all tiles
      const currentTile = allTiles[j].coordinates[0]; // get a specific tile
      matches = 0;
      for (let x = 0; x < 4; x++) {
        // current tiles coords
        for (let y = 0; y < 4; y++) {
          // new tiles coords
          if (
            currentTile[x][0] == newTile[y][0]
            && currentTile[x][1] == newTile[y][1]
          ) {
            matches++;
            if (matches === 2) {
              edgeFriends.push(allTiles[j]);
            }
          }
        }
      }
    }
    returnArry.push(parseInt(edgeFriends.length));
    returnArry.push(edgeFriends);
    return returnArry;
  };

  const undo = () => {
    if (slicingMode.current) {
      // also needs to go through and remove from currentSlice
      const sliceGeo = currentSlice.current.toGeoJSON();

      if (sliceGeo.geometry.coordinates.length > 1) {
        const newCoords = sliceGeo.geometry.coordinates;
        newCoords.splice(newCoords - 1, 1);
        currentSlice.current.setLatLngs(newCoords);
        currentSlice.current.redraw();
      } else {
        currentSlice.current = null;
      }

      const lineControl = document.getElementsByClassName(
        'control-icon leaflet-pm-icon-polyline',
      )[0];
      const control = lineControl.parentNode;
      const lineEditControls = control.nextSibling;
      let removeVertex = null;
      for (let i = 0; i < lineEditControls.childNodes.length; i++) {
        if (
          lineEditControls.childNodes[i].className
          === 'leaflet-pm-action  action-removeLastVertex'
        ) {
          removeVertex = lineEditControls.childNodes[i];
          break;
        }
      }
      removeVertex.click();
    } else if (drawnZones.current.length > 0) {
      // make a copy so we can mutate with pop to remove and use layer
      const zoneCopy = [...drawnZones.current];
      const recentZone = zoneCopy.pop();
      map.removeLayer(recentZone);
      drawnZones.current = zoneCopy;
      handleZone(zone, 'undo');
    }
  };

  /**
   * when the clear button gets clicked, it will call a few different functions to clean stuff up from various
   * zone creating tools to maintain modularity. This function will clean everything up from the grid
   */
  const clearGrid = (removeAll = false) => {
    try {
      removeGridControls();
    } catch (err) {}
    rotateVal = 0;
    setCancelButton(false);
    EWoffset = 0;
    NSoffset = 0;
    zoneNum = 1;
    gridSize = 0.0636149354;
    setGridSlider(true);
    setToolTip(`Editing Zone: ${zoneNum}`);
    gridlayer.current.clearLayers();
    if (removeAll) {
      createdZones.current.clearLayers();
    }
  };

  const clearSlice = () => {
    setSlicing(false);
    setCancelButton(false);
    slicingMode.current = false;
    if (map !== null) {
      map.pm.Draw.disable();
    }
    slicedMap.current.clearLayers();
    slicedZones.current = [];
  };

  const clearSavedZones = () => {
    savedLayers.current.clearLayers();
  };

  const clear = (button=false) => {
    drawnPolygons.current.clearLayers();
    ssurgoMapLayer.current.clearLayers();
    if(button){
      clearLegend([])
    }
    try {
      setMapImage('');
      setImageBounds([]);
      map.removeLayer(imageOverlay.current);
    } catch (err) {
      // console.log(err)
    }

    usedColors = [];
    clearSlice();
    clearGrid(true);
    clearSavedZones();

    drawnZones.current.map((zone) => map.removeLayer(zone));
  };

  function valueLabelFormat(value) {
    return marks.findIndex((mark) => mark.value === value) + 1;
  }

  function valuetext(value) {
    return `${value}ac`;
  }

  /**
   *
   * @param {int} newvalue amount to move by
   * @param {int} gridSize length of cell-side in km for grid re-render
   * @param {string} dir either "x" or "y" to decide which direction to move the grid
   */
  const moveGrid = (newvalue, gridSize, dir) => {
    switch (dir) {
      case 'x':
        EWoffset = newvalue;
        addGrid(gridSize);
        break;
      case 'y':
        NSoffset = newvalue;
        addGrid(gridSize);
        break;
    }
  };

  /**
   * used for decrimenting the current zone number being edited
   */
  const prevZone = () => {
    if (zoneNum > 1) {
      zoneNum--;
    }
    setToolTip(`Editing Zone: ${zoneNum}`);
    addGrid(gridSize, false);
  };

  /**
   * used for incrementing the current zone number being edited
   */
  const addNewZone = () => {
    let selectedForZone = 0;
    for (const square of selected) {
      if (square.selected && square.zone === zoneNum) {
        selectedForZone += 1;
      }
    }
    if (selectedForZone > 0) {
      zoneNum++;
      setToolTip(`Editing Zone: ${zoneNum}`);
      addGrid(gridSize, false);
    } else {
      enqueueSnackbar('Please Select At Least One Tile Before Changing Zones');
    }
  };

  const cancelClickHandler = () => {
    const tool = activeTool;
    setActiveTool('');
    switch (tool) {
      case 'grid':
        removeGridControls();
        setCancelButton(false);
        rotateVal = 0;
        EWoffset = 0;
        NSoffset = 0;
        gridSize = 0.0638;
        setGridSlider(true);
        setToolTip(`Editing Zone: ${zoneNum}`);
        gridlayer.current.clearLayers();
        break;
      case 'polygon':
        map.pm.Draw.disable();
        setCancelButton(false);
        break;
      case 'slice':
        setSlicing(false);
        setCancelButton(false);
        slicingMode.current = false;
        map.pm.Draw.disable();
        break;
    }
  };


  const handleSliceVertex = (e) => {
    if (!slicingMode.current) {
      return;
    }

    setDrawnLayer(null);
    drawnPolygons.current.clearLayers();

    if (currentSlice.current === null) {
      currentSlice.current = L.polyline([e.latlng]);
    } else {
      currentSlice.current.addLatLng(e.latlng);
      const sliceGeo = currentSlice.current.toGeoJSON();

      // use field boundary if zones don't exist, other wise use zones
      let intersection = {
        features: [],
      };
      let features = [];
      const existing = [];

      if (slicedZones.current.length === 0) {
        // if no slices exist, push the field geojson into the features list
        if (editedFieldGeo.current === null) {
          features.push(JSON.parse(fieldData.selectedField.boundary));
        } else {
          features.push(editedFieldGeo.current);
        }
        // intersect the slice with the feature
        intersection = turf.lineIntersect(features[0], sliceGeo);
        // console.log("initial intersection",intersection);
      } else {
        // need to do a intersect check on each existing zone
        for (const sz of slicedZones.current) {
          const zoneIntersection = turf.lineIntersect(sz, sliceGeo);
          // console.log("zone intersection", zoneIntersection)
          // if intersects, add sz to features for cut
          if (zoneIntersection.features.length > 1) {
            intersection = zoneIntersection;
            // console.log("adding sz to feature", sz)
            features.push(sz);
          } else {
            // need to make sure that existing zones not in a slice are saved
            // console.log("adding sz to existing list")
            existing.push(sz);
          }
        }
      }

      // if intersection was successful?
      if (intersection.features.length > 1) {
        // clear lists of previous items
        slicedZones.current = [];
        //setZones([{ zone: null, type: 'clear' }]);
        userDrawnZone = [];
        // line has connected to other edge
        // split polygon based on line
        // loop over features, checking for a multipoly. if multi, split into polys and remake list
        const multiPolyNdxs = new Set();
        const newPolys = [];
        // console.log("Features", features)
        for (const feat of features) {
          // console.log(feat);
          if (feat.geometry.type == 'MultiPolygon' || feat.geometry.coordinates.length > 1) {
            // loop over coordinates and create new Polygon feature
            for (const coordinates of feat.geometry.coordinates) {
              const newP = {
                geometry: {
                  coordinates: [],
                  type: 'Polygon',
                },
                properties: {},
                type: 'Feature',
              };
              if (coordinates.length > 1) {
                newP.geometry.coordinates.push(coordinates);
              } else {
                newP.geometry.coordinates = coordinates;
              }
              newPolys.push(newP);
              multiPolyNdxs.add(features.indexOf(feat));
            }
          }
        }
        // console.log("new polys from features",newPolys)
        // console.log("features multipoly ndxs",multiPolyNdxs)

        // remove multi polygons from list and add in new created polygons
        const multiNdxArr = Array.from(multiPolyNdxs);
        multiNdxArr.map((x) => features.splice(x, 1));

        features = [...features, ...newPolys];
        // this might be the palce to take difference of overlapping polygons?
        features = removeHolePolygons(features);

        // console.log("features after poly editing", features)
        const zoneColors = [];
        let finalZones = null;

        for (const toSplit of features) {
          let split;
          // console.log(toSplit, sliceGeo.geometry)
          try {
            split = polygonCut(toSplit.geometry, sliceGeo.geometry);
            // console.log(toSplit, split);
          } catch (err) {
            // console.log("Whhops", err)
            // clear vertexes + line
            // clearSlice()
            // enqueueSnackbar("Whoops, looks like something went wrong. The Split tool struggles with fields that have strange shapes and holes. Please try another tool.",{
            //   autoHideDuration: 8000
            // } )
            return;
          }

          if (split != null) {
            // console.log("Split", split)
            const splitFeats = split.features;
            for (const feature of splitFeats) {
              const featurePoly = turf.polygon(feature.geometry.coordinates);
              const area = turf.area(featurePoly) / 4046.86;
              feature.properties.CALCACRES = area;
              slicedZones.current.push(feature);
            }
          } else {
            // console.log("Split null, tosplit:", toSplit)
            const featurePoly = turf.polygon(toSplit.geometry.coordinates);
            const area = turf.area(featurePoly) / 4046.86;
            featurePoly.properties.CALCACRES = area;
            slicedZones.current.push(featurePoly);
          }

          if (finalZones === null) {
            if (split === null) {
              finalZones = {
                type: 'FeatureCollection',
                features: [toSplit],
              };
            } else {
              finalZones = split;
            }
          } else if (split === null) {
            finalZones.features.push(toSplit);
          } else {
            split.features.map((x) => finalZones.features.push(x));
          }
        }

        slicedZones.current = [...slicedZones.current, ...existing];

        const currentZones = [];

        // if (deletedOps.current.length > 0) {
        //   for (const deletedOp of deletedOps.current) {
        //     // console.log('\n\n', "delete props from : ", deletedOp);
        //     delete deletedOp.zone.properties.mapRef;
        //     delete deletedOp.zone.properties.featureRef;
        //   }
        //   // console.log(deletedOps.current);

        //   for (const sz of slicedZones.current) {
        //     let flag = false;

        //     for (const dz of deletedOps.current) {
        //       if (JSON.stringify(sz) == JSON.stringify(dz.zone)) {
        //         flag = true;
        //       }
        //     }

        //     if (!flag) { // lets only add it to the list if it hasnt been deleted
        //       currentZones.push(sz);
        //     }
        //   }

        //   slicedZones.current = [...currentZones];
        // }

        // by this point if finalZones is null then there is no new completed split
        // return to avoid clearing zones and map errors
        if (finalZones === null) {
          return;
        }

        // if(Object.keys(geoJson).length === 0){
        //setZones([{ zone: null, type: 'clear' }]);
        // }
        // console.log("slicedzones", slicedZones)
        const currColors = [];
        for (const feature of slicedZones.current) {
          let tempColor = zonesColors[Math.floor(Math.random() * zonesColors.length)];
          if (usedColors.includes(tempColor)) {
            tempColor = getRandomColor();
          }
          zoneColors.push(tempColor);
          usedColors.push(tempColor);
          currColors.push(tempColor);

          feature.properties.color = tempColor;
          feature.properties.featureRef = slicedMap;
          feature.properties.mapRef = map;
        }

        existing.map((x) => finalZones.features.push(x));
        userDrawnZone = [];
        // loop finalzones and add color and refs if not there
        for (const feat of finalZones.features) {
          if (feat.properties.color === undefined) {
            feat.properties.color = usedColors[finalZones.features.indexOf(feat)];
            feat.properties.mapRef = map;
            feat.properties.featureRef = slicedMap;
            const featurePoly = turf.polygon(feat.geometry.coordinates);
            const area = turf.area(featurePoly) / 4046.86;
            feat.properties.CALCACRES = area;
          }
        }
        // console.log("finalZones after adding color", finalZones)

        if (origin === 'Other') {
          // handleZone(slicedZones.current, "multiple")
          //setZones([{ zone: slicedZones.current, type: 'multiple' }]);
        } else {
          // slicedZones.current.map((x) => handleZone(x, "add"));
          const forZones = slicedZones.current.map((x) => ({ zone: x, type: 'add' }));
          //setZones(forZones);
          userDrawnZone = forZones;
        }

        // need to create zones with geojson and keep track of zones
        if (map.hasLayer(slicedMap.current)) {
          map.removeLayer(slicedMap.current);
        }
        slicedMap.current.clearLayers();
        // console.log("final zones", finalZones)

        slicedMap.current.addData(finalZones);

        // loop over layers and set feature id to layer id
        slicedMap.current.eachLayer((layer) => {
          layer.feature.properties.id = layer._leaflet_id;
        });

        // remove previously drawn from map
        drawnZones.current.forEach((item) => {
          item.eachLayer((layer) => {
            map.removeLayer(layer);
          });
          item.remove();
        });

        // set drawn zones to slicedzone for tracking purposes
        drawnZones.current = [slicedMap.current];
        slicedMap.current.addTo(map);
        colorZones(zoneColors, 'slice', slicedZones.current);

        // finish drawing line to restart
        const lineControl = document.getElementsByClassName(
          'control-icon leaflet-pm-icon-polyline',
        )[0];
        const control = lineControl.parentNode;
        const lineEditControls = control.nextSibling;
        let finishLine = null;
        for (let i = 0; i < lineEditControls.childNodes.length; i++) {
          if (
            lineEditControls.childNodes[i].className
            === 'leaflet-pm-action  action-finish'
          ) {
            finishLine = lineEditControls.childNodes[i];
            break;
          }
        }
        finishLine.click();
        // clear currentSlice
        currentSlice.current = null;

        // click start again for continued slicing
        const start = document.getElementsByClassName(
          'control-icon leaflet-pm-icon-polyline',
        )[0];
        start.click();
      }
    }
  };

  function polygonCut(polygon, line, idPrefix) {
    // this is a function to cut a pulygon into seperate polygons
    // based on a line.
    // taken from - https://gis.stackexchange.com/questions/344068/splitting-a-polygon-by-multiple-linestrings-leaflet-and-turf-js

    const THICK_LINE_UNITS = 'kilometers';
    const THICK_LINE_WIDTH = 0.001;
    let i; let j; let id; let intersectPoints; var lineCoords; let forCut; let
      forSelect;
    let thickLineString; let thickLinePolygon; let clipped; let polyg; let
      intersect;
    let polyCoords = [];
    let cutPolyGeoms = [];
    const cutFeatures = [];
    const offsetLine = [];
    let retVal = null;

    if (
      (polygon.type != 'Polygon' && polygon.type != 'MultiPolygon')
      || line.type != 'LineString'
    ) {
      return retVal;
    }

    if (typeof idPrefix === 'undefined') {
      idPrefix = '';
    }

    intersectPoints = turf.lineIntersect(polygon, line);
    if (intersectPoints.features.length == 0) {
      return retVal;
    }

    var lineCoords = turf.getCoords(line);
    if (
      turf.booleanWithin(turf.point(lineCoords[0]), polygon)
      || turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)
    ) {
      return retVal;
    }
    console.log(line, THICK_LINE_WIDTH);

    offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS,
    });
    offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS,
    });
    console.log('offsetLine', offsetLine);
    for (i = 0; i <= 1; i++) {
      forCut = i;
      forSelect = (i + 1) % 2;
      polyCoords = [];
      for (j = 0; j < line.coordinates.length; j++) {
        polyCoords.push(line.coordinates[j]);
      }
      for (
        j = offsetLine[forCut].geometry.coordinates.length - 1;
        j >= 0;
        j--
      ) {
        polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
      }
      polyCoords.push(line.coordinates[0]);
      // console.log('line', line)

      thickLineString = turf.lineString(polyCoords);
      thickLinePolygon = turf.lineToPolygon(thickLineString);
      clipped = turf.difference(polygon, thickLinePolygon);

      cutPolyGeoms = [];
      for (j = 0; j < clipped.geometry.coordinates.length; j++) {
        polyg = turf.polygon(clipped.geometry.coordinates[j]);
        intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
        if (intersect.features.length > 0) {
          cutPolyGeoms.push(polyg.geometry.coordinates);
        }
      }

      cutPolyGeoms.forEach((geometry, index) => {
        id = `${idPrefix + (i + 1)}.${index + 1}`;
        cutFeatures.push(turf.polygon(geometry, { id }));
      });
    }

    if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);

    return retVal;
  }


  /**
 * checks list of polygons to see if any polygon lies within another and takes the difference
 * removing any holes from what should be a multipolygon
 * @param {*} features
 */
   const removeHolePolygons = (features) => {
    try {
      const maskedZones = [];
      const intersected = [];
      for (let i = 0; i < features.length; i++) {
        let evalZone = features[i];
        if (!intersected.includes(i)) {
          for (let j = i + 1; j < features.length; j++) {
            if (turf.booleanContains(evalZone, features[j])) {
              evalZone = turf.difference(evalZone, features[j]);
              intersected.push(j);
            }
          }
          maskedZones.push(evalZone);
        }
      }
      return maskedZones;
    } catch (err) {
      console.log(err);
      return features;
    }
  };

  const handleTextInputSize = (event) => {
    if (event.target.value > 0 && event.target.value < 50) {
      const val = Math.sqrt(turf.convertArea(event.target.value, 'acres', 'kilometers'));
      addGrid(val, true);
    }
  };
  const sliceMap = () => {
    setCancelButton(false);
    setActiveTool('slice');
    setSlicing(true);
    slicingMode.current = true;
    const lineControl = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polyline',
    )[0];
    lineControl.click();
  };

  /**
   * Function to handle the slider for grid rotation and then re-render grid
   * @param {float from slider} newvalue
   */
  const handleRotateSlider = (newvalue) => {
    rotateVal = newvalue;
    addGrid(gridSize, false);
  };

  const completeSlice = () => {
    setSlicing(false);
    setCancelButton(false);
    slicingMode.current = false;
    const lineControl = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polyline',
    )[0];
    const control = lineControl.parentNode;
    const lineEditControls = control.nextSibling;
    let finishLine = null;
    for (let i = 0; i < lineEditControls.childNodes.length; i++) {
      if (
        lineEditControls.childNodes[i].className
        === 'leaflet-pm-action  action-cancel'
      ) {
        finishLine = lineEditControls.childNodes[i];
        break;
      }
    }
    finishLine.click();
  };

  const sendToZone = (feature) => {
    const fCopy = { ...feature };
    fCopy.geometry.coordinates = fCopy.geometry.coordinates[0];
    handleZone(fCopy, 'add');
  };

  const handleZone = (data, action) => {
    userDrawnZone.push(data);
  };

  const handleSaveZones = () => {
    if (ssurgoLayers.length > 0) {
      listOfZonesDrawn(ssurgoLayers, userEnteredZoneName, 'ssurgo');
    } else {
      listOfZonesDrawn(userDrawnZone, userEnteredZoneName, selectedZone);
    }
  };

  const handleZoneName = (e) => {
    setUserEnteredZoneName(e.target.value);
  };

  const handleGetSavedZone = (e) => {
    try {
      if (userDrawnZone.length > 0) {
        userDrawnZone = [];
      }
      console.log('userSavedZones', userSavedZones);

      savedLayers.current.clearLayers();
      const zoneSelected = userSavedZones.filter((zone) => zone.id === e.target.value)[0];
      setSelectedZone(zoneSelected.id);
      console.log('zoneSelected', zoneSelected);
      const geoJsons = JSON.parse(zoneSelected.zoneBoundary);
      console.log('geoJsons', geoJsons);

      for (let i = 0; i < geoJsons.length; i++) {
        console.log('zone', JSON.parse(geoJsons[i].geojson).zone);
        userDrawnZone.push(JSON.parse(geoJsons[i].geojson).zone);
        setZone(JSON.parse(geoJsons[i].geojson).zone);
        savedLayers.current.addData(JSON.parse(geoJsons[i].geojson).zone);
      }
      savedLayers.current.addTo(map);
    } catch (err) {
      console.log('error using saved zones', err);
    }
  };

  const AgToolTip = withStyles({
    tooltip: {
      fontSize: '16px',
      color: 'white',
      backgroundColor: '#67b444',
    },
  })(Tooltip);

  const handleOpenSelect = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleCloseSelect = () => {
    setAnchorEl(null);
  };

  const toolMenu = () => (
    <Box>
      <Button
        color="primary"
        variant="outlined"
        aria-controls="tool-menu"
        aria-haspopup="true"
        onClick={handleOpenSelect}
      >
        Map Tools
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleCloseSelect}
      >
        <MenuItem onClick={handleCloseSelect}>
          <Box
            className={classes.greenIcon}
            onClick={() => {
              try { sliceMap(); } catch (err) {
                console.log(err); enqueueSnackbar('Whoops, looks like something went wrong. The Split tool struggles with fields that have strange shapes and holes. Please try another tool.', {
                  autoHideDuration: 8000,
                });
              }
            }}
          >
            <AddIcon />
            {' Split Field'}
          </Box>
        </MenuItem>
        <MenuItem onClick={handleCloseSelect}>
          <Box
            className={classes.greenIcon}
            onClick={() => addPolygon()}
          >
            <AddIcon />
            {' Draw'}
          </Box>
        </MenuItem>
        <MenuItem onClick={handleCloseSelect}>
          <Box
            className={classes.greenIcon}
            onClick={() => addGrid(0.0636149354, true)}
          >
            <GridOnOutlinedIcon />
            {' '}
            Gridded
          </Box>
        </MenuItem>
      </Menu>
    </Box>
  );

  return (
    <Box
      style={{
        height: canEdit && isOperationMap ? '85%' : containerHeight,
        width: containerWidth !== undefined ? containerWidth : '100%',
      }}
    >

      {isDrawZone && (
        <Box
          className={classes.mapControls}
          style={{ padding: width > mobileBreakPoint ? 15 : '15px 4px' }}
        >
          <Box display="flex " style={{ width: '100%' }}>
            {
              (width < mobileBreakPoint && isDrawZone && !cancelButton) && toolMenu()
            }

            { (!cancelButton && width >= mobileBreakPoint) && (
              <>
                <AgToolTip
                  title="Draw lines to dissect the field by clicking to place endpoints"
                  placement="top-start"
                  className={classes.tootlTipLight}
                >
                  <Button
                    disabled={mapLoading}
                    className={classes.greenIcon}
                    onClick={() => {
                      try { sliceMap(); } catch (err) {
                        console.log(err); enqueueSnackbar('Whoops, looks like something went wrong. The Split tool struggles with fields that have strange shapes and holes. Please try another tool.', {
                          autoHideDuration: 8000,
                        });
                      }
                    }}
                  >
                    <AddIcon />
                    Split Field
                  </Button>
                </AgToolTip>

                <AgToolTip
                  title="Draw polygons by clicking to place vertices. Click the first vertex to complete the polygon"
                  placement="top-start"
                >
                  <Button
                    disabled={mapLoading}
                    className={classes.greenIcon}
                    onClick={() => addPolygon()}
                  >
                    <AddIcon />
                    {' '}
                    Draw
                  </Button>
                </AgToolTip>

                <AgToolTip
                  title="Click tiles to add to a zone. Use sliders to control grid postion and size. To create additional zones: click 'Next Zone'. Click 'Finish' to finalize zones"
                  placement="top-start"
                >
                  <Button
                    className={classes.greenIcon}
                    disabled={mapLoading}
                    onClick={() => addGrid(0.0636149354, true)}
                  >
                    <GridOnOutlinedIcon />
                    Gridded
                  </Button>
                </AgToolTip>
              </>
            )}

            {cancelButton && (
              <Box
                className={classes.greenIcon}
                onClick={() => cancelClickHandler()}
                fontSize={width > mobileBreakPoint ? 14 : 12}
                style={{ margin: width > mobileBreakPoint ? '0px 10px' : '0px 2px' }}
              >
                <CancelIcon />
                Cancel
              </Box>
            )}
            {/* <Box className={classes.icon} onClick={() => undo()}>
              <UndoIcon />
              Undo
            </Box> */}
            <Box
              className={classes.greenIcon}
              onClick={() => clear(true)}
              fontSize={width > mobileBreakPoint ? 14 : 12}
              style={{ margin: width > mobileBreakPoint ? '0px 10px' : '0px 2px' }}
            >
              <HighlightOffOutlinedIcon />
              Clear
            </Box>
            { slicing && (
              <Box className={classes.icon}>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => completeSlice()}
                >
                  Finish Edit
                </Button>
              </Box>
            )}

            { !gridSlider && (

              <Box mx={2}>
                <TextField
                  id="standard-basic"
                  variant="outlined"
                  label={width > mobileBreakPoint ? 'Size(AC)' : 'AC'}
                  onChange={(event) => handleTextInputSize(event)}
                  style={{
                    height: 30,
                    width: 34,
                    marginRight: width > mobileBreakPoint ? 12 : 6,
                  }}
                  inputProps={{
                    style: {
                      padding: 5,
                    },
                  }}
                  InputLabelProps={{ shrink: true }}
                />
              </Box>
            )}

            {!gridSlider && (
              <Button
                variant="outlined"
                color="primary"
                style={{
                  height: 30,
                  margin: '0px 16px',
                  whiteSpace: 'nowrap',
                  backgroundColor: '#ffffff',
                }}
                onClick={() => prevZone()}
              >
                Prev Zone
              </Button>
            )}

            {!gridSlider && (
              width > mobileBreakPoint ? (
                <AgToolTip title={tooltip} open style={{ fontSize: '20px' }}>
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => addNewZone()}
                    style={{ height: '30px', marginRight: '5px' }}
                  >
                    Next Zone
                  </Button>
                </AgToolTip>
              ) : (
                <Button
                  variant="outlined"
                  color="primary"
                  size="small"
                  onClick={() => addNewZone()}
                  style={{ height: '30px', marginRight: '5px' }}
                >
                  Next
                </Button>
              )
            )}

            {!gridSlider && (
            <Button
              variant="outlined"
              color="primary"
              size={width > mobileBreakPoint ? 'medium' : 'small'}
              onClick={() => {
                try {
                  createGridZones();
                } catch (err) {
                  console.log(err)
                  enqueueSnackbar('Something Went Wrong Creating Your Zone(s)');
                }
              }}
              style={{ height: '30px' }}
            >
              Finish
            </Button>
            )}
          </Box>

          <Box display="flex">
            <TextField
              variant="outlined"
              id="zoneName"
              label={width > mobileBreakPoint ? 'Zone Name' : 'Name'}
              onChange={(event) => handleZoneName(event)}
              style={{ height: 30, width: width > mobileBreakPoint ? 100 : 80 }}
              inputProps={{
                style: {
                  padding: 5,
                },
              }}
              InputLabelProps={{ shrink: true }}
            />
          </Box>

          {
            userSavedZones.length > 0 && (
              <Box display="flex">
                <Box className={classes.icon}>
                  <FolderOpenIcon color="primary" />
                  <Select
                    variant="outlined"
                    onChange={(e) => handleGetSavedZone(e)}
                    style={{ height: 30, width: width > mobileBreakPoint ? 100 : 80 }}
                    inputProps={{
                      style: {
                        padding: 5,
                      },
                    }}
                  >
                    {userSavedZones.map((x, i) => (
                      <MenuItem key={i} value={x.id}>
                        {x.zoneName}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>
              </Box>
            )
}

        </Box>
      )}
      { !gridSlider && <Box className={classes.gridControls} /> }
      <div
        id="zoneAnalysisMap"
        style={{
          height: isDrawZone ? containerHeight - 80 : containerHeight - 40,
          width: '100%',
        }}
      />

      { (userDrawnZone.length > 0 || ssurgoLayers.length > 0) && (
        <Box
          display="flex"
          justifyContent="center"
          style={{
            width: '100%', height: 38, marginTop: 2, position: 'absolute',
          }}
        >
          <Button
            id="run_zone_analysis"
            variant="contained"
            color="primary"
            onClick={() => handleSaveZones()}
            style={{ right: 0, position: 'absolute', marginRight: '20px' }}
            disableElevation
          >
            Run Zone Analysis
          </Button>
        </Box>
      )}
    </Box>
  );
}

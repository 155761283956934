import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import produce from "immer";
import { ascending, sum } from "d3-array";
import SelectGroup from "./BreakdownSelectGroup";
import {
  COLORS,
  DOLLAR_FORMAT,
  SINGLE_DECIMAL_FORMAT,
} from "../../../../../constants";
import Spinner from "../../../../Helpers/Spinner";
import {
  fetchFarmCropTypes,
  fetchFarmCropVarieties,
  fetchOrganizationChemicalInputs,
  performApplicationZoneAnalysis,
} from "../../../../../utils/dataFetchers";
import { CircularProgress } from "@material-ui/core";
import { set } from "lodash";

const useStyles = makeStyles(() => ({
  breakdownContainer: {
    display: "flex",
    flexDirection: "column",
    flex: 1,
    marginBottom: 24,
    marginRight: 32,
    maxWidth: 460,
    minWidth: 340,
  },
  formContainer: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
  },
  title: {
    fontSize: 20,
    fontWeight: "bold",
    lineHeight: 1.3,
    marginBottom: 25,
    color: COLORS.darkGray,
  },
  description: {
    fontSize: 16,
    lineHeight: 1.31,
    marginBottom: 25,
    minWidth: "18rem",
  },
}));

const NONE_SELECTED_OPTION = {
  id: "None selected",
  active: true,
};

/**
 * Handles the content in the selects in the farm breakdown
 * @param {object} activeFarm the selected farm
 * @param {string} activeYear the selected year
 */
const FarmBreakdown = ({
  activeFarm,
  activeYear,
  ORG_ID,
  message,
  setMessage,
  metrics,
  setMetrics,
  state,
  setState,
  cropInitialLoad,
  setCropInitialLoad,
  varietyInitialLoad,
  setVarietyInitialLoad,
  chemInitialLoad,
  setChemInitialLoad,
  show,
  setShow,
}) => {
  const classes = useStyles();
  // const [metrics, setMetrics] = useState({
  //   profit: 0,
  //   acres: 0,
  // });

  // const [state, setState] = useState({
  //   cropTypes: {
  //     label: "Crop type",
  //     values: [],
  //   },
  //   cropVarieties: {
  //     label: "Crop variety",
  //     values: [],
  //     data: [],
  //   },
  //   chemicalInputs: {
  //     label: "Chemical input",
  //     values: [],
  //     data: [],
  //   },
  // });

  // const [cropInitialLoad, setCropInitialLoad] = useState(true);
  // const [varietyInitialLoad, setVarietyInitialLoad] = useState(true);
  // const [chemInitialLoad, setChemInitialLoad] = useState(true);
  // const [show, setShow] = useState(false)

  useEffect(() => {
    (async () => {
      if(cropInitialLoad){
        const xs = await fetchFarmCropTypes(ORG_ID, activeYear, activeFarm.id);
        setState(
          produce((draft) => {
            draft.cropTypes.values = [NONE_SELECTED_OPTION].concat(
              xs
                .sort((a, b) => ascending(a.CropName, b.CropName))
                .map((d, i) => {
                  return {
                    id: d.CropName,
                    active: false,
                  };
                })
            );
            for (const name of ["cropVarieties", "chemicalInputs"]) {
              draft[name].values = [];
              draft[name].data = [];
            }
          })
        );
      }
    })();
  }, [activeYear, activeFarm.id, cropInitialLoad]);

  useEffect(() => {
    //console.log("state", state)
    if (cropInitialLoad && state.cropTypes.values.length > 1) {
      onCropTypeChange(state.cropTypes.values[1].id);
    }

    if (varietyInitialLoad && state.cropVarieties.values.length > 1) {
      onCropVarietyChange(state.cropVarieties.values[1].id);
    }

    if (chemInitialLoad && state.chemicalInputs.values.length > 1) {
      //console.log("inside chem if")
      onChemicalInputChange(state.chemicalInputs.values[1].id);
    }
  }, [state]);

  const onCropTypeChange = async (cropType) => {
    // When the crop type select changes, trigger a call to get the crop varieties
    // Clear crop varieties and chemical inputs
    setCropInitialLoad(false);
    setState(
      produce((draft) => {
        draft.cropTypes.values = state.cropTypes.values.map((d) => {
          return {
            ...d,
            active: d.id === cropType,
          };
        });
        for (let name of ["cropVarieties", "chemicalInputs"]) {
          draft[name].values = [];
          draft[name].data = [];
        }
      })
    );

    // Retrieve crop varieties and fields for given crop type
    const xs = await fetchFarmCropVarieties(
      ORG_ID,
      activeYear,
      activeFarm.id,
      cropType
    );

    setVarietyInitialLoad(true);
    // Get unique crop varieties for select options
    const uniqueCropVarieties = Array.from(
      new Set(xs.map((d) => d.Name))
    ).map((d) => ({ id: d, active: false }));

    // Update the state
    setState(
      produce((draft) => {
        draft.cropTypes.values = state.cropTypes.values.map((d) => ({
          ...d,
          active: d.id === cropType,
        }));
        draft.cropVarieties.data = xs;
        draft.cropVarieties.values = [NONE_SELECTED_OPTION].concat(
          uniqueCropVarieties
        );
      })
    );
  };

  const onCropVarietyChange = async (cropVariety) => {
    // When the crop variety select changes, trigger a call to get the chemical inputs
    // Clear the chemical inputs
    setVarietyInitialLoad(false);
    setState(
      produce((draft) => {
        draft.cropVarieties.values = state.cropVarieties.values.map((d) => {
          return {
            ...d,
            active: d.id === cropVariety,
          };
        });
        draft.chemicalInputs.values = [];
        draft.chemicalInputs.data = [];
      })
    );

    // Using the field ids, retrieve all chemical inputs
    const fieldIds = state.cropVarieties.data.filter(
      (d) => d.Name === cropVariety
    );

    // Get chemical inputs for the selected organization
    const xs = await fetchOrganizationChemicalInputs(
      ORG_ID,
      activeYear,
      Array.from(new Set(fieldIds.map((d) => d.FieldID))).join(",")
    );

    // Get unique chemical inputs for select options
    const uniqueChemicalInputs = Array.from(
      new Set(xs.map((d) => d.ComponentName))
    ).map((d) => ({ id: d, active: false }));

    //console.log("uniqueChemicalInputs", uniqueChemicalInputs)

    // Update the state
    setChemInitialLoad(true);
    setState(
      produce((draft) => {
        draft.cropVarieties.values = state.cropVarieties.values.map((d) => ({
          ...d,
          active: d.id === cropVariety,
        }));
        draft.chemicalInputs.data = xs;
        draft.chemicalInputs.values = xs.length
          ? [NONE_SELECTED_OPTION].concat(uniqueChemicalInputs)
          : [];
      })
    );
  };

  const onChemicalInputChange = async (chemicalInput) => {
    // When the chemical input select changes, trigger a call to perform the application zone analysis
    // State update
    //console.log("onChemicalInputChange")
    setChemInitialLoad(false);
    setState(
      produce((draft) => {
        draft.chemicalInputs.values = state.chemicalInputs.values.map((d) => {
          return {
            ...d,
            active: d.id === chemicalInput,
          };
        });
      })
    );

    // Get all the combinations of fields and operation using the chemical
    const matchingOperations = state.chemicalInputs.data.filter(
      (d) => d.ComponentName === chemicalInput
    );

    setShow(true)
    let results = [];
    for (let matchingOperation of matchingOperations) {
      //console.log(matchingOperation);
      results = results.concat(
        await performApplicationZoneAnalysis(
          ORG_ID,
          matchingOperation.FieldID,
          activeYear,
          matchingOperation.ID
        )
      );
    }

    //console.log("results", results)
    // Find where variety = 2.0
    const matchingApplicationZones = results.filter((d) => d.variety === "2.0");

    //console.log("matchingApplicationZones", matchingApplicationZones)
    // Update the profit and acres
    setMetrics(
      produce((draft) => {
        draft.acres = sum(matchingOperations, (d) => d.AreaResult);
        draft.profit = sum(
          matchingApplicationZones,
          (d) => d.mean * draft.acres 
        );
      })
    );
    setShow(false)
  };

  const activeCropType = state.cropTypes.values.find((d) => d.active);
  const activeCropVariety = state.cropVarieties.values.find((d) => d.active);
  const activeChemicalInput = state.chemicalInputs.values.find((d) => d.active);

  //console.log("activeCropType", activeCropType)

  return (
    <div className={classes.breakdownContainer}>
      <div className={classes.title}>Farm Breakdown</div>
      {
        show && <CircularProgress
        size={60}
        style={{
          position: "absolute",
          top: "60%",
          right: 7,
          left: 0,
          margin: "auto",
          zIndex: 1005,
          color: COLORS.green,
        }}
      />
      }
      {!state.cropTypes.values.length ? (
        <Spinner message={message} />
      ) : (
        <>
          <div className={classes.description}>
            <b>{`${SINGLE_DECIMAL_FORMAT(metrics.acres)}`} acres</b> of
            <b>{` ${
              activeCropVariety !== undefined
                ? activeCropVariety?.id
                : "<Variety Name>"
            }`}</b>
            ,
            <b>{` ${
              activeCropType !== undefined &&
              activeCropType?.id !== "None selected"
                ? activeCropType?.id
                : "<Crop Type>"
            }`}</b>{" "}
            that have been subject to
            <b>{` ${
              activeChemicalInput !== undefined
                ? activeChemicalInput?.id
                : "<Chemical Input>"
            }`}</b>{" "}
            yielded profits of
            <b>
              {` ${DOLLAR_FORMAT(metrics.profit / metrics.acres)}`}/Acre
            </b>
          </div>
          <div className={classes.formContainer}>
            <SelectGroup
              state={state}
              onChanges={{
                cropTypes: onCropTypeChange,
                cropVarieties: onCropVarietyChange,
                chemicalInputs: onChemicalInputChange,
              }}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default FarmBreakdown;

FarmBreakdown.propTypes = {
  activeFarm: PropTypes.object.isRequired,
  activeYear: PropTypes.string.isRequired,
};

import PropTypes from 'prop-types';
import { getETLStatus } from './dataFetchers';
import { formatTime } from './helpers';

/**
 * Get ETL status for integration, update the page with the current status,
 * and keep updating until integration is complete
 * @param {Array} integrationStatusOpen Whether or not component is being rendered
 * @param {String} integrationSource Deere, Climate, or CNH
 * @param {Function} setSource Sets integration source
 * @param {Number} integrationFlag Current flag
 * @param {Function} setFlag Sets integration flag
 * @param {Function} setLastUpdated Set time last updated
 * @param {Function} setMessage Set message to user
 * @param {Function} setIntegrationStatus Shows status of integration prcoess
 * @param {Function} setStep Set current integration step
 * @param {Function} setCompleted Sets integration complete
 * @param {Function} timer Begins close countdown
 * @param {Boolean} triggerRepeat Determine if we should repeat
 * @param {Number} noResultAtempt If greater than 0, do not repeat on no result found
 * @returns {String} flag
 */
export const alertETLstatus = async (
  integrationStatusOpen,
  integrationSource,
  setSource,
  integrationFlag,
  setFlag,
  setLastUpdated,
  setMessage,
  setIntegrationStatus,
  setStep,
  setCompleted,
  timer = undefined,
  triggerRepeat = true,
  noResultAtempt = 0,
) => {
  let repeat = true;
  const status = await getETLStatus();
  // console.log(status)

  // console.log('integrationStatusOpen :>> ', integrationStatusOpen);
  // Abort execution if integrationStatus' calling component is not being rendered
  // This is an array containing a boolean instead of just a boolean as a variable containing just a boolean does not get its reference passed on to alertETLstatus for some reason (it works for components JSX however...)
  if (!integrationStatusOpen[0]) return false;

  // if nothing in status, could either be from etl tracker being slow
  // or user not having integrated. (they most likely would not be here without one though)
  if (!status || status?.length === 0) {
    // wait 1 second then call function again
    if (noResultAtempt > 0) {
      console.error('Exiting integration: getETLStatus returned no status.');

      // Put in some placeholder values to notify user
      setIntegrationStatus('No existing integration found.');
      const date = new Date();
      setLastUpdated(`${date.toDateString()}Time: ${date.toLocaleTimeString()}`)
      setSource('N/A');
      setStep(0);
      return false;
    }

    setTimeout(async () => {
      alertETLstatus(integrationStatusOpen, integrationSource, setSource, integrationFlag, setFlag, 
        setLastUpdated, setMessage, setIntegrationStatus, setStep, setCompleted, timer, true, 1);
    }, 3000);
    return false;
  }

  // On next line, we inject data for testing flows
  // status[0].statusDescription = "Processing"
  const currDateObj = new Date();
  const yesterday = new Date();
  yesterday.setDate(currDateObj.getDate() - 1);

  let updating = status.filter((x) => new Date(x.updatedAt) >= yesterday);
  updating.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));

  if (updating.length === 0) {
    // if none currently updating, then end here
    updating = status;
    repeat = false;
  }

  // Set other items
  const source = updating[0].issuer;
  const { flag } = updating[0];

  if (source !== integrationSource) {
    setSource(source);
  }
  if (flag !== integrationFlag) {
    setFlag(flag);
  }

  // Let's begin constructing the notification to the user
  const baseMessage = '';
  const { statusDescription } = updating[0];
  let extraDetails = '';

  // Let's format the created date and last updated date
  const updatedDateObj = new Date(updating[0].updatedAt);

  let updatedOn = String(updatedDateObj);
  let sliceNdx = updatedOn.indexOf(':');
  // updatedOn = updatedOn.slice(0, sliceNdx - 2);
  updatedOn = updatedOn.split(' ');
  const time = formatTime(updatedDateObj);

  setLastUpdated(`${updatedOn[1]} ${updatedOn[2]}, ${updatedOn[3]}Time: ${time}`);

  let createdOn = String(updatedDateObj);
  sliceNdx = createdOn.indexOf(':');
  createdOn = createdOn.slice(0, sliceNdx - 2);

  // eslint-disable-next-line default-case
  switch (flag) {
    case 1:
      extraDetails = ` | ${updatedOn}`;
      setStep(5);
      break;
    case 3:
      setStep(1);
      break;
    case 4:
      setStep(2);
      break;
    case 5:
    case 6:
      setStep(3);
      break;
    case 7:
      extraDetails = ` | ${createdOn}`;
      setStep(5);
      break;
  }

  const finalMessage = baseMessage + statusDescription + extraDetails;
  setMessage(finalMessage);
  setIntegrationStatus(finalMessage.split(' | ')[0]);

  if (flag >= 3 && flag <= 7) {
    // If the flag is somewhere in the processing stage
    if (triggerRepeat && repeat) {
      const alertParams = [integrationStatusOpen, integrationSource, setSource, integrationFlag, setFlag, 
        setLastUpdated, setMessage, setIntegrationStatus, setStep, setCompleted, timer];
      // Send max of 5 notifications, counter starts at 1 (1,2,3,4,5)
      repeatNotifications(5, 1, flag, alertParams);
    }
  } else {
    // if it's less than 3 or greater than 7, we assume it finished successfully.
    setCompleted(true);
    if (timer) {
      // In modal, timer will start a close countdown
      timer();
    }
  }
  return flag;
};

/**
 * If integration isn't completed, call alertETLstatus again
 * and repeat until integration is complete
 * @param {Number} maxAlerts Max alerts
 * @param {Number} currAlertNum Current alert counter
 * @param {String} flag Flag to update
 * @returns {void}
 */
const repeatNotifications = (maxAlerts, currAlertNum, flag, alertParams) => {
  const [integrationStatusOpen, integrationSource, setSource, integrationFlag, setFlag, 
    setLastUpdated, setMessage, setIntegrationStatus, setStep, setCompleted, timer] = alertParams;

  // Check if etl has completed
  if (flag < 3 || flag > 7) {
    // This if should never run but here just in case.
  } else {
    // Still processing, send heartbeat
    setTimeout(async () => {
      // triggerRepeat is false, so we dont trigger an infinite loop
      flag = await alertETLstatus(integrationStatusOpen, integrationSource, setSource, integrationFlag, setFlag, 
        setLastUpdated, setMessage, setIntegrationStatus, setStep, setCompleted, timer, false);
      repeatNotifications(maxAlerts, currAlertNum + 1, flag, alertParams);
    }, 10000);
  }
};

alertETLstatus.propTypes = {
  integrationStatusOpen: PropTypes.bool.isRequired,
  integrationSource: PropTypes.string.isRequired,
  setSource: PropTypes.func.isRequired,
  integrationFlag: PropTypes.number.isRequired,
  setFlag: PropTypes.func.isRequired,
  setLastUpdated: PropTypes.func.isRequired,
  setMessage: PropTypes.func.isRequired,
  setIntegrationStatus: PropTypes.func.isRequired,
  setStep: PropTypes.func.isRequired,
  setCompleted: PropTypes.func.isRequired,
  timer: PropTypes.func,
  triggerRepeat: PropTypes.bool,
  noResultAtempt: PropTypes.number,
};

alertETLstatus.defaultProps = {
  timer: undefined,
  triggerRepeat: true,
  noResultAtempt: 0,
};

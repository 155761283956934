import React from "react";
import { scaleLinear } from "d3-scale";
import Axes from "../../../Helpers/BaseChart/Axes";
import FieldMarker from "../FieldMarker";
import { extent } from "d3-array";

const ScatterPlot = ({
  innerWidth,
  innerHeight,
  setState,
  points,
  radius,
  activeMarker,
  firstVariableOption = {},
  secondVariableOption = {},
  xAccessor = (d) => d.x,
  yAccessor = (d) => d.y,
  nameAccessor = (d) => d.name,
}) => {
  const xScale = scaleLinear()
    .range([0, innerWidth])
    .domain(extent(points, xAccessor))
    .nice();
  const yScale = scaleLinear()
    .range([innerHeight, 0])
    .domain(extent(points, yAccessor));

  return (
    <g
      className="scatter__plot"
      onMouseOut={() =>
        setState((prevState) => ({ ...prevState, activeMarker: null }))
      }
    >
      <Axes
        height={innerHeight}
        width={innerWidth}
        xScale={xScale}
        yScale={yScale}
        showXAxisLabel={true}
        xAxisLabel={firstVariableOption.label}
        showYAxisLabel={true}
        yAxisLabel={secondVariableOption.label}
      />
      {points.map((point, i) => {
        return (
          <FieldMarker
            key={`field-marker-for-${point.name}-${point.variety}-${point.year}-${i}`}
            marker={{
              ...point,
              xPosition: xScale(xAccessor(point)),
              yPosition: yScale(yAccessor(point)),
              isActive:
                !activeMarker ||
                nameAccessor(activeMarker) === nameAccessor(point),
            }}
            radius={radius}
            setState={setState}
          />
        );
      })}
    </g>
  );
};

export default ScatterPlot;

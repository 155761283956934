import React from "react";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../constants";
import { format } from "d3-format";

const useStyles = makeStyles((theme) => ({
  container: {
    position: "absolute",
    padding: "12px",
    boxShadow: "0 2px 4px 0 rgba(0, 0, 0, 0.1)",
    border: "solid 1px #dddddd",
    backgroundColor: COLORS.white,
    fontSize: "14px",
    fontWeight: "normal",
    lineHeight: "1.36",
    color: COLORS.text,
    pointerEvents: "none",
    minWidth: "187px",
  },
  title: {
    fontSize: "16px",
    fontWeight: "bold",
    lineHeight: "1.31",
    color: COLORS.green,
    marginBottom: "8px",
  },
  legendItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    "& span": {
      borderRadius: "50%",
      width: "10px",
      height: "10px",
      marginRight: "5px",
    },
  },
}));

const Tooltip = ({
  activeMarker,
  isDisaggregated,
  xAccessor = (d) => d.x,
  yAccessor = (d) => d.y,
  xFormat = format(".1f"),
  yFormat = format(".1f"),
  firstVariableOption,
  secondVariableOption,
  xUnit,
  yUnit,
}) => {
  const classes = useStyles();
  const { top, left, name, variety, color, onlyOneVariable, year, cropName } = activeMarker;
  // console.log("firstVariableOption", firstVariableOption)
  // console.log("xUnit", xUnit)
  // console.log("secondVariableOption", secondVariableOption)

  return (
    <div className={classes.container} style={{ top: top - 50, left }}>
      <div className={classes.title}>{name}</div>
      <div className={classes.title}>Crop Name: {cropName}</div>
      <div className={classes.title}>Crop Season: {year}</div>
      {isDisaggregated && (
        <div className={classes.legendItem}>
          Variety:&nbsp;
          <span style={{ backgroundColor: color }} />
          <b>{variety}</b>
        </div>
      )}
      <div>
        {firstVariableOption !== undefined ? 
        firstVariableOption.id == "None selected" ? secondVariableOption.id 
        : firstVariableOption.id : ""}:{" "}
       
        <b>
          {activeMarker.originalX === undefined
            ? `${xFormat(xAccessor(activeMarker))} ${xUnit}`
            : `${xFormat(activeMarker.originalX)} ${xUnit}`}
        </b>
      </div>
      {!onlyOneVariable && (
        <div>
          {secondVariableOption !== undefined ? secondVariableOption.id : ""}:{" "}
          <b>{`${yFormat(yAccessor(activeMarker))} ${yUnit}`}</b>
        </div>
      )}
    </div>
  );
};

export default Tooltip;

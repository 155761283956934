import React from "react";
import PropTypes from "prop-types";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { makeStyles } from "@material-ui/core/styles";
import {
  COLOR_RANGE,
  COLORS,
  DOLLAR_FORMAT,
} from "../../../../../../constants";

const useStyles = makeStyles(() => ({
  row: {
    border: "none",
    "& :first-child": {
      paddingLeft: 0,
    },
  },
  cell: {
    border: `4px solid ${COLORS.white}`,
    fontSize: 12,
    fontWeight: "bold",
    color: COLORS.text,
    padding: 7,
  },
}));

/**
 * Renders the row
 * @param {object} row object with row data
 * @param {array} headerCells  array of objects with functions/information
 * needed to access data in row object
 * @param {function} profitScale function to assign color according to scale
 */

const Row = ({ row, headerCells, profitScale, negativeScale, positiveScale }) => {
  const classes = useStyles();

  return (
    <TableRow className={classes.row}>
      {headerCells.map((headerCell, i) => {
        const matchingValue = headerCell.accessor(row);
        let backgroundColor = "";

        if(matchingValue < 0){
          backgroundColor = negativeScale(matchingValue);
        }
        else if(matchingValue >= 0){
          backgroundColor = positiveScale(matchingValue);
        }
        else{
          backgroundColor = profitScale(matchingValue);
        }
        // const backgroundColor = profitScale(matchingValue);
        // console.log("backgroundColor", backgroundColor, matchingValue)
        // const neg = negativeScale(matchingValue)
        // console.log("neg", neg)

        // const pos = positiveScale(matchingValue)
        // console.log("pos", pos)

        const color = COLOR_RANGE.find(
          (d) => d.backgroundColor === backgroundColor
        );
        return (
          <TableCell
            key={`table-cell-for-${headerCell.id}`}
            className={classes.cell}
            align={!i ? "left" : "center"}
            style={{
              backgroundColor: matchingValue
                ? i
                  ? backgroundColor
                  : COLORS.white
                : COLORS.white,
              color: i
                ? matchingValue
                  ? color.color
                  : COLORS.gray
                : "inherit",
            }}
          >
            {matchingValue
              ? !i
                ? matchingValue
                : DOLLAR_FORMAT(matchingValue) + "/Acre"
              : "---"}
          </TableCell>
        );
      })}
    </TableRow>
  );
};
export default Row;

Row.propTypes = {
  row: PropTypes.object.isRequired,
  headerCells: PropTypes.array.isRequired,
  profitScale: PropTypes.func.isRequired,
};

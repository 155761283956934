import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import {
  TableRow,
  TableCell,
  TableFooter,
  Button,
  Box,
  Grid,
  ButtonGroup,
  Grow,
  Paper,
  MenuItem,
  MenuList,
  Popper,
  ClickAwayListener,
  Modal,
  Typography,
  LinearProgress,
  CircularProgress
} from "@material-ui/core";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { makeStyles } from "@material-ui/core";
import { ExcelExportSummary } from "../../../../../Helpers/ExcelDownload/ExcelExportSummary";
import { ExcelExportDetailed } from "../../../../../Helpers/ExcelDownload/ExcelExportDetailed";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import { pdf, PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import PrintFarmReport from "../../../PrintFarmReport";
import { COLORS } from "../../../../../../constants";
import DownloadWhiteIcon from "../../../../../../images/download_white.svg";
import { useSnackbar } from 'notistack';

const useStyles = makeStyles(() => ({
  cell: {
    color: COLORS.text,
    fontSize: 14,
    fontWeight: "bold",
    border: "none",
    borderTop: `2px solid ${COLORS.green}`,
    paddingTop: 22,
  },
  button: {
    color: COLORS.white,
    backgroundColor: COLORS.green,
    display: "flex",
    alignItems: "center",
    fontSize: 14,
    fontWeight: "bold",
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "uppercase",
    width: "100%",
    lineHeight: 1.36,
  },
  close: {
    zIndex: 1005,
    color: 'black',
    position: 'absolute',
    fontSize: 20,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

/**
 * Renders the summary row of the farm overview table
 * @param {object} values  array of row data
 * @param {array} headerCells  array of objects with functions/information
 * needed to access data in row object
 * @param {array} selectedFields array of selected field data objects
 * @param {object} activeFarm object with farm data
 * @param {string} activeYear the year
 * @param {object} fieldHeaderCells keyed object for the categories for
 * the field report. Being passed down into pdf report
 * @param {array} data  array of objects with data to be passed down into pdf report
 */

const Summary = ({
  values,
  headerCells,
  selectedFields,
  activeFarm,
  activeYear,
  fieldHeaderCells,
  data,
  selectedOrg,
  seedMap,
  harMap,
  appMap,
  fpMap,
  otherCostMap,
  otherRevMap,
  setIsDownload
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const options = [
    "Generate Detailed Report (PDF)",
    "Print Detailed Report (Excel)",
    "Print Summary Report (Excel)",
  ];

  const categories = Object.keys(fieldHeaderCells);
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [selectedIndex, setSelectedIndex] = React.useState(0);

  const [orderedFields, setOrderedFields] = useState([])

  const maxFields = 10
  const [numPdfs, setNumPdfs] = useState(0)
  const [pdfModalOpen, setPdfModalOpen] = useState(false);
  const [pdfGenerating, setPdfGenerating] = useState(false);
  const [pdfLoaderValue, setPdfLoaderValue] = useState(0);
  const [pdfsGenerated, setPdfsGenerated] = useState(0);

  const [canceling, setCanceling] = useState(false)
  const cancelGeneration = useRef(false);

  const [pdfReady, setPdfReady] = useState(false);

  const [updatedData, setUpdatedData] = useState([
    {
      fieldId: "",
      category: "Harvest",
      values: [
        {
          variety: "",
          acres: 0,
          seedsPerAcre: 0,
          seedsPrice: 0,
          seedCost: 0,
        },
      ],
    },
  ]);

  useEffect(() => {
    //console.log("data", data);
    let fieldsList = [...selectedFields];
    fieldsList.sort((a,b) => {
      if(a.name < b.name) { return -1; }
      if(a.name > b.name) { return 1; }
      return 0;
    })
    setOrderedFields(fieldsList)
    generateUpdatedData();
  }, [selectedFields]);

  useEffect(() => {
    if(numPdfs > 0){
      setPdfModalOpen(true)
    }
  },[numPdfs])


  const generateUpdatedData = (returnData=false) => {
    let fieldData = [];

    for (let selectedField of selectedFields) {
      for (let c of categories) {
        let obj = {};
        let values = [];
        let defaultVal = {
          "acres": 0,
          "seedsPrice": 0,
          "seedsPerAcre": 0,
          "seedCost": 0,
          "variety": "N/A"
        }

        if (c == "Harvest") {
          defaultVal['unit']='N/A'
          obj.category = "Harvest";
          obj.fieldId = selectedField.id;
          let opData = harMap.get(selectedField.id);
          for (let op of opData) {
            if (!op.hidden) {
              for (let va of op.varieties) {
                let innerObj = {
                  acres: va.varietyArea,
                  seedsPrice: va.price,
                  seedsPerAcre: va.varietyYieldRate,
                  seedCost: va.price * va.varietyYieldRate,
                  variety: va.productName + " - " + va.variety,
                  unit: va.totalUnit,
                  fieldAcres: selectedField.acres,
                };
                values.push(innerObj);
              }
            }
          }
          obj.values = values;
        }

        if (c == "Seeding") {
          defaultVal['unit']='N/A'
          obj.category = "Seeding";
          obj.fieldId = selectedField.id;
          let opData = seedMap.get(selectedField.id);
          for (let op of opData) {
            if (!op.hidden) {
              for (let va of op.varieties) {
                let innerObj = {
                  acres: va.varietyArea,
                  seedsPrice: va.price,
                  seedsPerAcre: va.varietyRate,
                  seedCost: va.price * va.varietyRate,
                  variety: va.productName + " - " + va.variety,
                  unit: va.totalUnit,
                  fieldAcres: selectedField.acres,
                };
                values.push(innerObj);
              }
            }
          }
          obj.values = values;
        }

        if (c == "Chemicals") {
          defaultVal['unit']='N/A'
          obj.category = "Chemicals";
          obj.fieldId = selectedField.id;
          let opData = appMap.get(selectedField.id);
          for (let op of opData) {
            if (!op.isDeleted) {
              let innerObj = {
                acres: op.summaryArea,
                seedsPrice: op.operationCost / op.summaryApplicationRate,
                seedsPerAcre: op.summaryApplicationRate,
                seedCost: op.operationCost,
                variety: op.productName,
                unit: op.summaryTotalAppliedUnit,
                fieldAcres: selectedField.acres,
              };
              values.push(innerObj);
            }
          }
          obj.values = values;
        }

        if (c == "Field Passes") {
          obj.category = "Field Passes";
          obj.fieldId = selectedField.id;
          let opData = fpMap.get(selectedField.id);

          for (let op of opData) {
            if (!op.isDeleted) {
              let innerObj = {
                acres: op.area,
                seedsPrice: +op.price * +op.area,
                seedsPerAcre: +op.price,
                seedCost: +op.price * +op.area,
                variety: op.name,
                fieldAcres: selectedField.acres,
              };
              values.push(innerObj);
            }
          }
          obj.values = values;
        }

        if (c == "Other Costs") {
          obj.category = "Other Costs";
          obj.fieldId = selectedField.id;
          let opData = otherCostMap.get(selectedField.id);

          for (let op of opData) {
            if (!op.isDeleted) {
              let innerObj = {
                acres: op.total,
                seedsPrice: +op.price * +op.total,
                seedsPerAcre: +op.price,
                seedCost: +op.price * +op.total,
                variety: op.name,
                fieldAcres: selectedField.acres,
              };
              values.push(innerObj);
            }
          }
          obj.values = values;
        }

        if (c == "Other Revenue") {
          obj.category = "Other Revenue";
          obj.fieldId = selectedField.id;
          let opData = otherRevMap.get(selectedField.id);

          for (let op of opData) {
            if (!op.isDeleted) {
              let innerObj = {
                acres: op.total,
                seedsPrice: +op.price * +op.total,
                seedsPerAcre: +op.price,
                seedCost: +op.price,
                variety: op.name,
                fieldAcres: selectedField.acres,
              };
              values.push(innerObj);
            }
          }
          obj.values = values;
        }

        if(values.length == 0){
          values.push(defaultVal)
        }

        fieldData.push(obj);
      }
    }
    setUpdatedData(fieldData);
    if(returnData){
      return fieldData
    }
  };

  const handleClick = (e) => {
    // console.log("selectedIndex", selectedIndex);
    // console.log(`You clicked ${options[selectedIndex]}`);
    if (selectedIndex == 0) {
      handlePdfClick(e)
    } else if (selectedIndex == 1) {
    } else {
      handleSummaryExcelClick(e);
    }
  };

  const handleMenuItemClick = (event, index) => {
    setSelectedIndex(index);
    setOpen(false);

    if (index == 0) {
      handlePdfClick(event);
    }
  };

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  /**
   * Function to generate and save a PDF report for the user's selected fields
   * @param {Object} e event object created from a button click, used to prevent default behaviour 
   */
  const handlePdfClick = async (e) => {
    e.preventDefault();
    // console.log("data", JSON.stringify(newdata))
    setIsDownload(true)
    if(orderedFields.length > maxFields){
      let numBuckets = Math.ceil(orderedFields.length / maxFields)
      setNumPdfs(numBuckets)
    }
    else{
      let newdata = generateUpdatedData(true);
      let pdfReport = createPdfReport(orderedFields, newdata);
      try{
        const report = await pdfReport.toBlob()
        saveAs(report, "FarmReport.pdf");
      }
      catch(err){
        console.log("Failed to save report")
        console.log(err)
        
      }
    }

    setIsDownload(false)
  };

  /**
   * Creates a react-pdf pdf object containing the pdf JSX
   * @param {Array} fields list of fields from the orderedFields list
   * @param {Array} newdata list of objects from the generateUpdatedData function
   * @returns 
   */
  const createPdfReport = (fields, newdata) => {
    let pdfReport;
    try{
      pdfReport = pdf(
        <PrintFarmReport
          headerCells={headerCells}
          selectedFields={fields}
          activeFarm={activeFarm}
          activeYear={activeYear}
          fieldHeaderCells={fieldHeaderCells}
          data={newdata}
          selectedOrg={selectedOrg}
          seedMap={seedMap}
          harMap={harMap}
          appMap={appMap}
          fpMap={fpMap}
          otherCostMap={otherCostMap}
          otherRevMap={otherRevMap}
        />
      );
    }
    catch(err){
      console.log("Failed to generate report")
      console.log(err)
    }
    return pdfReport
  }

  /**
   * function for splitting fields into buckets and creating pdfs for each
   * subset of fields to avoid potential crashes from excessivley large pdf files
   * @returns undefined
   */
  const createMultiplePdfs = async () => {
    let newdata = generateUpdatedData(true);
    setPdfGenerating(true)
    // split orderedFields into buckets
    let tempList = orderedFields
    let buckets = []
    let numBuckets = Math.ceil(tempList.length / maxFields)
    let i;
    for(i=0; i<numBuckets; i++){
      let end = maxFields > tempList.length ? tempList.length : maxFields
      let bucket = tempList.splice(0, end)
      buckets.push(bucket)
    }

    // loop over the buckets and create a PDF for each, use this loop for progress tracking/indicator
    let pdfBlobs = []
    for(i=0; i<buckets.length; i++){
      // if user pressed the cancel button while generating then return from function and reset states
      if (cancelGeneration.current){
        cancelGeneration.current = false
        setCanceling(false)
        setPdfGenerating(false)
        setPdfsGenerated(0)
        setPdfLoaderValue(0)
        return
      }
      setPdfsGenerated(i+1)
      setPdfLoaderValue(((i+1)/numBuckets)*100)
      let pdf = createPdfReport(buckets[i], newdata)
      try{
        let report = await pdf.toBlob()
        pdfBlobs.push(report)
      }
      catch(err){
        console.log("Failed to create pdf "+i.toString(), err)
      }
    }

    // loop over reports in reverse and save each
    // loop in reverse so that windows opened are in correct order
    for(i=pdfBlobs.length; i>=1; i--){
            // if user pressed the cancel button while generating then return from function and reset states
      if (cancelGeneration.current){
        cancelGeneration.current = false
        setCanceling(false)
        setPdfGenerating(false)
        setPdfsGenerated(0)
        setPdfLoaderValue(0)
        return
      }
      let name = "FarmReport_"+i.toString()+".pdf"
      saveAs(pdfBlobs[i-1], name) 
    }
    setPdfsGenerated(0)
    setPdfLoaderValue(0)
    setPdfGenerating(false)
  }

  const handleSummaryExcelClick = (e) => {
    e.preventDefault();
    return <ExcelExportSummary selectedFields={selectedFields} />;
  };

  /**
   * Handles the cancle button and close button action
   * in the pdfModal
   */
  const handleCancel = () => {
    if(pdfGenerating){
      setCanceling(true)
      cancelGeneration.current = true
    }
    else{
      setPdfModalOpen(false)
    }
  }

  const pdfModal = () => (
    <Modal
      open={pdfModalOpen}
      style={{
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
      }}
    >
      <Box
        style={{
          margin: 'auto',
          marginTop:'15%',
          borderRadius: '10px',
          width: '400px',
          padding: '15px',
          paddingTop: '5px',
          backgroundColor: 'white',
        }}
      >
        <Box display="flex" justifyContent="end" style={{marginBottom: '25px', marginRight: '-5px'}}>
          <HighlightOffIcon className={classes.close} onClick={() => handleCancel()}/>
        </Box>
        <Box>
          <Typography variant="h6">
            Looks like you have selected quite a few fields to include in the report. For this many fields it is required to split them into {`${numPdfs}`} PDFs.
          </Typography>
          <Typography variant="subtitle1">
            This can take between 0.5 - 2 minutes per PDF depending on the data included. Would you like to continue generating your PDF report? 
          </Typography>
        </Box>
        <Box
          style={{
            display: "flex",
            justifyContent: 'space-evenly',
            marginTop: '10px'
          }}
        >
          {!pdfGenerating ?
          
            <Button variant='contained' color="primary" onClick={() => createMultiplePdfs()}>
              Generate PDFs
            </Button>
            :
            <Button variant='contained' color="primary" disabled>
              Generate PDFs
            </Button>
          }
          {canceling ?
            <Button variant='contained' color="secondary" disabled>
              Cancel
            </Button>
            :
            <Button variant='contained' color="secondary" onClick={() => handleCancel()}>
              Cancel
            </Button>
          }
        </Box>

        {(pdfGenerating && !canceling) &&
          <Box style={{marginTop: '10px'}}>
            <Box display="flex">
              <Box style={{marginRight: '10px'}}>
                {`Generating ${pdfsGenerated}/${numPdfs}`}
              </Box>
              <CircularProgress size={15} />
            </Box>
            <LinearProgress variant='determinate' value={pdfLoaderValue}/>
          </Box>
        }

        {(pdfGenerating && canceling) &&
          <Box style={{marginTop: '10px'}}>
            <Box display="flex">
              <Box style={{marginRight: '10px'}}>
                {`Canceling`}
              </Box>
              <CircularProgress size={15} />
            </Box>
            <LinearProgress color="secondary"/>
          </Box>
        }
        

      </Box>

    </Modal>
  )

  return (
    <TableFooter>
      {pdfModalOpen && pdfModal()}
      <TableRow>
        {headerCells.map(
          ({ id, format, accessor, aggregateFunction, showPrintButton }, i) => {
            const aggregateValue =
              aggregateFunction && aggregateFunction(values, accessor);
            return (
              <TableCell
                key={`table--summary-for-${id}`}
                className={classes.cell}
              >
                {!i ? (
                  "Totals"
                ) : format ? (
                  format(aggregateValue)
                ) : showPrintButton ? (
                  <Grid container direction="column" alignItems="center">
                    <Grid item xs={12}>
                      <ButtonGroup
                        variant="contained"
                        color="primary"
                        ref={anchorRef}
                        aria-label="split button"
                      >
                        <Button onClick={(e) => handleClick(e)}>
                          {
                            selectedIndex === 0 &&
                            options[selectedIndex]
                          }
                        </Button>
                        <Button
                          color="primary"
                          size="small"
                          aria-controls={open ? "split-button-menu" : undefined}
                          aria-expanded={open ? "true" : undefined}
                          aria-label="select merge strategy"
                          aria-haspopup="menu"
                          onClick={handleToggle}
                        >
                          <ArrowDropDownIcon />
                        </Button>
                      </ButtonGroup>
                      <Popper
                        open={open}
                        anchorEl={anchorRef.current}
                        role={undefined}
                        transition
                        disablePortal
                      >
                        {({ TransitionProps, placement }) => (
                          <Grow
                            {...TransitionProps}
                            style={{
                              transformOrigin:
                                placement === "bottom"
                                  ? "center top"
                                  : "center bottom",
                            }}
                          >
                            <Paper>
                              <ClickAwayListener onClickAway={handleClose}>
                                <MenuList id="split-button-menu">
                                  {options.map((option, index) => (
                                    <MenuItem
                                      key={option}
                                      selected={index === selectedIndex}
                                      onClick={(event) =>
                                        handleMenuItemClick(event, index)
                                      }
                                    >
                                      {index === 0 ? 
                                        option
                                       : index === 1 ? (
                                        <ExcelExportDetailed
                                          selectedFields={selectedFields}
                                          seedMap={seedMap}
                                          harMap={harMap}
                                          appMap={appMap}
                                          fpMap={fpMap}
                                          otherCostMap={otherCostMap}
                                          otherRevMap={otherRevMap}
                                          activeYear={activeYear}
                                          selectedOrg={selectedOrg}
                                          activeFarm={activeFarm}
                                        />
                                      ) : (
                                        <ExcelExportSummary
                                          selectedFields={selectedFields}
                                        />
                                      )}
                                    </MenuItem>
                                  ))}
                                </MenuList>
                              </ClickAwayListener>
                            </Paper>
                          </Grow>
                        )}
                      </Popper>
                    </Grid>
                  </Grid>
                ) : (
                  aggregateValue
                )}
              </TableCell>
            );
          }
        )}
      </TableRow>
    </TableFooter>
  );
};

export default Summary;

Summary.propTypes = {
  values: PropTypes.array.isRequired,
  headerCells: PropTypes.array.isRequired,
  selectedFields: PropTypes.array.isRequired,
  activeFarm: PropTypes.object.isRequired,
  activeYear: PropTypes.string.isRequired,
  fieldHeaderCells: PropTypes.object.isRequired,
  data: PropTypes.array.isRequired,
};

// -------------------- IMPORTS --------------------
//#region - imports
// React
import React, { useRef, useState, useEffect, useContext } from 'react';
import PropTypes from 'prop-types';

// Styling and helpful packages
import { useSnackbar } from 'notistack';

// Contexts and Shared
import { UserContext } from '../Context/UserContext';

// Components
import { AcreageReporting } from './AcreageReporting';
import { Landing } from './Onboarding/Landing';
import { Onboarding } from './Onboarding/Onboarding';

// Functions
import { 
  getSharedEmails,
  getSharedOrgs,
} from '../../utils/dataFetchers';
//#endregion


// -------------------- MAIN FUNCTION --------------------
/**
 * Rendered in App.js
 * Controls Acreage Reporting display. User starts on landing page, with ability to
 * go directly to reporting or complete the onboarding walkthrough. If onboarding flow
 * is entered, the user will be able to naviagte to reporting from within at any time
 * @param {Function} setSection Sets navbar section
 * @returns {JSX} Based on stage
 */
export const AcreageHome = ({ setSection }) => {
  // -------------------- VARIABLES --------------------
  //#region - variables
  // ---- Stage and Onboarding flow vars
  const [stage, setStage] = useState('landing'); // default to 'landing'
  
  // Grower or Agent
  const [userType, setUserType] = useState('grower'); // default to 'grower'

  // To decide where to bring user in Onboarding flow (after logging in/integrating)
  const [resumeOnboarding, setResumeOnBoarding] = useState(false); // default to false
  const [userIntegrated, setUserIntegrated] = useState(false) // default to false

  // ---- Report Type vars
  // Variables for controlling the setting of reportType and thus zoneType
  const [initialStep, setInitialStep] = useState(0); // 0 is grower vs. agent selection
  const pickFormat = 99.5; // Last step to pick report format if user decides to skip through onboarding

  // These default to 'CART', but will be set by user to 'CART' or 'GART'
  const [zoneType, setZoneType] = useState('CART'); // Internal (for validation)
  const [reportType, setReportType] = useState('CART'); // Can be changed by user in Onboarding stage

  // ---- Modal vars
  // Agent portal
  const [sharedUsers, setSharedUsers] = useState(null); // default to null
  const [impersonating, setImpersonating] = useState(''); // default to ''

  // Organization related vars, moved up here For Upload CLU modal functionality
  const [organizations, setOrganizations] = useState([]); // Also used for FieldSelection
  const [selectedCLUOrg, setSelectedCLUOrg] = useState({id: -1, name: ''}); // Store this higher up to store state between stages
  const [useOrgCLUData, setUseOrgCLUData] = useState(false); // default to false

  // ---- Other vars
  // Contexts and user info
  const user = useContext(UserContext)[0];
  const { enqueueSnackbar } = useSnackbar();
  
  const justLoaded = useRef(true);
  // Whether or not to show outputs (in console) meant for debugging
  const debuggingOutputs = false; // default to false
  //#endregion


  // -------------------- USE EFFECTS --------------------
  //#region - useEffects
  useEffect(() => {
    // Check URL. If user singed in from onboarding, return them to next step
    const url = window.location.href;

    if (url.includes('Onboarding')) {
      if (url.includes('init')){
        // user is coming back from integration, take them to the CLU step
        handleFromOnboarding(url, '/Onboarding?init=1')
      }
      else{
        // If user signed in through onboarding, bring them back to next step
        handleFromOnboarding(url, '/Onboarding');
      }
      
    } else if (url.includes('SignUpFromOnboard')) {
      handleFromOnboarding(url, '/SignUpFromOnboard');
    } else if (url.includes('NewUser')) {
      setStage('reporting');
      const baseUrl = url.replace('/NewUser', '');
      window.history.replaceState({}, document.title, baseUrl);
    }
  }, []);

  // Ensure navbar has correct section
  useEffect(() => {
    if (setSection) {
      // NOTE: set home section to none for now
      setSection(-1);
    }
  }, [setSection]);

  // Attempt to set user orgs
  useEffect(() => {
    setUserOrgs();
  }, [user]);

  // When whose precision data should be used changes, reset available organizations to choose from
  useEffect(() => {
    // Ignore this when user has just loaded the page
    if (justLoaded.current) {
      justLoaded.current = false;
      return;
    }

    // Load appropriate org data
    if (impersonating !== '') {
      loadUsersOrgs(impersonating);
    }
    else {
      setUserOrgs(true);
    }
  }, [impersonating]);

  useEffect(() => {
    if (sharedUsers === null) {
      loadSharedUsers();
    }
  }, [sharedUsers]);

  // Layer for validation purposes. Make sure option is always CART or GART and is always uppercased
  useEffect(() => {
    const verifiedType = reportType.toUpperCase();
    if (verifiedType === 'CART' || verifiedType === 'GART') {
      setZoneType(verifiedType);
    } else {
      console.error('ERROR: reportType is being set to an invalid value. This can result in loss of data.');
    }
  }, [reportType]);
  //#endregion


  // -------------------- FUNCTIONALITY --------------------
  //#region - useEffect helpers
  // Attempt to get orgs for the user being impersonated and reset FieldSelection
  const loadUsersOrgs = async (email) => {
    try {
      const orgs = await getSharedOrgs(email);
      if (debuggingOutputs) {
        // console.log(orgs);
      }

      if (orgs !== undefined && typeof orgs !== 'string') {
        let formatted = orgs.map(x => ({id: x.id, name: x.name}));
        setOrganizations(formatted);
        enqueueSnackbar('You can now select from your grower\'s organizations.');
      }
      else {
        // Should do something here since impersonating will still have user even though no data will exist
        // Not doing anything could cause issues when loading or saving?
        enqueueSnackbar('An issue occured while getting your grower\'s organizations. You might be logged out, please refresh and try again.');
        setImpersonating('');
      }
    } catch(err) {
      console.error('An error occured while getting orgs from sharing user: ', err);
    }
  };
  
  // Get and set organizations from UserContext
  const setUserOrgs = (fromImpersonationChange = false) => {
    if (user?.organizations?.length) {
      setOrganizations(
        user.organizations.map((org) => ({
          id: org.ID, name: org.Name,
        })),
      );
      if (fromImpersonationChange) enqueueSnackbar('You can now select from your organizations.');
    }
  }

  // Bring user to correct next step
  // When user returns from sign in/integration, they will already have selected agent or grower
  const handleFromOnboarding = (url, extension) => {
    // Default is grower so no need to update userType unless agent is selected
    if (localStorage.getItem('acreageReportingUserType') === 'agent') {
      setUserType('agent');
    }

    // Bring user to correct next step
    setStage('onboarding');
    setResumeOnBoarding(true);
    if (extension.includes('init')){
      setUserIntegrated(true);
    }

    // Remove from url and history?
    const baseUrl = url.replace(extension, '');
    window.history.replaceState({}, document.title, baseUrl);
  };

  // Load information about which users have shared their precision data with current user
  const loadSharedUsers = async () => {
    const emails = await getSharedEmails();

    if (typeof emails !== 'string' && emails !== undefined) {
      setSharedUsers(
        emails.sort((a, b) => a.owner_email_address.localeCompare(b.owner_email_address)),
      );
    } else {
      // Set to empty if want useEffect to only make call once
      setSharedUsers([]);
    }
  };
  //#endregion

  // -------------------- RETURN --------------------
  return (
    <>
      {stage === 'landing' && (
        <Landing
          pickFormat={pickFormat}
          setInitialStep={setInitialStep}
          setStage={setStage}
        />
      )}

      {stage === 'onboarding' && (
        <Onboarding
          impersonating={impersonating}
          initialStep={initialStep}
          integrated={userIntegrated}
          loadSharedUsers={loadSharedUsers}
          organizations={organizations}
          pickFormat={pickFormat}
          resume={resumeOnboarding}
          selectedCLUOrg={selectedCLUOrg}
          setImpersonating={setImpersonating}
          setReportType={setReportType}
          setSelectedCLUOrg={setSelectedCLUOrg}
          setStage={setStage}
          setUseOrgCLUData={setUseOrgCLUData}
          setUserType={setUserType}
          sharedUsers={sharedUsers}
          useOrgCLUData={useOrgCLUData}
          userType={userType}
        />
      )}

      {stage === 'reporting' && (
        <AcreageReporting
          debuggingOutputs={debuggingOutputs}
          impersonating={impersonating}
          loadSharedUsers={loadSharedUsers}
          organizations={organizations}
          selectedCLUOrg={selectedCLUOrg}
          setImpersonating={setImpersonating}
          setSelectedCLUOrg={setSelectedCLUOrg}
          setUseOrgCLUData={setUseOrgCLUData}
          sharedUsers={sharedUsers}
          useOrgCLUData={useOrgCLUData}
          zoneType={zoneType}
        />
      )}
    </>
  );
};

AcreageHome.propTypes = {
  setSection: PropTypes.func.isRequired,
};

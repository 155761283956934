/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  Snackbar, Box, Typography,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { green } from '../../../styles/colors';
import { CustomToolTip } from '../../../utils/customComponents';
import { checkIsMobile } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    border: `2px solid ${theme.palette.greys.dark}`,
    borderRadius: 8,
    backgroundColor: '#ffffff',
    fontSize: '1.1rem',
    width: 320,
  },
  closeIcon: {
    ...theme.closeIcon,
    fontSize: 18,
    color: theme.palette.primary.main,
  },
  image: {
    height: 24,
    width: 'auto',
  },
  tooltip: {
    ...theme.infoToolTip,
    fontSize: '1rem',
  },
}));

export const roundIcon = {
  width: '18px',
  height: '18px',
  fontSize: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 2,
  borderColor: green,
  borderRadius: '50%',
  marginRight: '6px',
};

/**
 * Instructions for grid selection
 * @param {Bool} open open
 * @param {function} setOpen sets open or close
 * @returns {JSX} Instructions
 */
export const SoilZoneInstructions = ({ open, setOpen }) => {
  const classes = useStyles();

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
      open={open}
      key="bottomleft"
      style={{ marginBottom: 46 }}
    >
      <Box className={classes.root} boxShadow={5}>
        <Box
          display="flex"
          justifyContent="space-between"
        >
          <Box width={26}>{' '}</Box>
          <Box pt={1} fontSize="1.5rem" fontWeight={500}>
            Create Your Zones
          </Box>
          <Box pt={0.5} px={0.5} display="flex" justifyContent="flex-end">
            <HighlightOffIcon className={classes.closeIcon} onClick={handleClose} />
          </Box>
        </Box>

        <Box my={1} px={2}>
          <Box display="flex" alignItems="center">
            <Box {...roundIcon} color={green}>1</Box>
            <Box pb="2px">Choose zone creation tool</Box>
          </Box>
          <Box p={0.5}>
            <Typography>
              Choose from toolbar above the map
            </Typography>
          </Box>
        </Box>

        <Box my={1} px={2}>
          <Box display="flex" alignItems="center">
            <Box {...roundIcon} color={green}>2</Box>
            <Box pb="2px">Create Zones</Box>
          </Box>
          <Box p={0.5}>
            <Typography>
              Choose Draw or Square to create free form zones
            </Typography>
          </Box>
          <Box p={0.5} display="flex">
            <Typography>
              Create zones from soil type data by choosing SSURGO
              { !checkIsMobile() && (
                <CustomToolTip
                  placement="top-start"
                  title="Digital soils data containing information on soil types and their distribution, produced and distributed by the Natural Resources Conservation Service"
                >
                  <InfoOutlinedIcon className={classes.tooltip} />
                </CustomToolTip>
              )}

            </Typography>
          </Box>
          <Box p={0.5}>
            <Typography>
              Grid Field to create a grid of 2.5 or 5 acre zones
            </Typography>
          </Box>
        </Box>

        <Box my={1} px={2}>
          <Box display="flex" alignItems="center">
            <Box {...roundIcon} color={green}>3</Box>
            <Box pb="2px">Select Package</Box>
          </Box>
          <Box p={0.5}>
            <Typography>
              When finished creating zones, press &quot;Continue to Select Package&quot;
            </Typography>
          </Box>

        </Box>

      </Box>
    </Snackbar>
  );
};

import * as turf from '@turf/turf';
import { getStateAndCounty } from "../../../utils/dataFetchers"

export async function handleShapeFileUpload(e, setFieldFromShape) {
  let fileobj = e.target.files[0]

  if(e.target.files[0].name.includes(".zip")){
    let reader = new FileReader();
    reader.readAsArrayBuffer(e.target.files[0])
    reader.onload = async function(e) {
      let JSZip = require('jszip')
      let zip = new JSZip(reader.result)
      try{
        var shpString =  zip.file(/.shp$/i)[0].name
        var dbfString =  zip.file(/.dbf$/i)[0].name

        loadShapeFileZip(
          fileobj,
          zip.file(shpString).asArrayBuffer(),
          zip.file(dbfString).asArrayBuffer(),
          setFieldFromShape
        )

      }
      catch(err){
        console.log(err)
      }
    }
  }
  else{
    //enqueueSnackbar("File format is wrong, upload a zip file!")
  }
}

async function loadShapeFileZip(file1, shpFile1, dbfFile1, setFieldFromShape, shapeField) {
  let multiGeojsons = [];
  var shapefile = require("shapefile");
  if(file1 !== null){
    shapefile.open(shpFile1, dbfFile1, null)
    .then(source => source.read()
    .then(function log(result) {
      if (result.done) return;
      if(result.value.geometry.type !== 'Point' && multiGeojsons.length <= 20){
        multiGeojsons.push(result.value)
        return source.read().then(log);
      }
      else if(result.value.geometry.type === 'Point'){
        return null
      }
  })).then(async function (data){
    let newBoundary = null
    if(multiGeojsons.length > 1){
      let coordinates = []
      for(let polygon of multiGeojsons){
        coordinates.push(polygon.geometry.coordinates)
      }
      let geom = {
        type: 'MultiPolygon',
        coordinates: coordinates
      }
      newBoundary = {
        type: 'Feature',
        geometry: geom,
        properties: multiGeojsons[0].properties
      }

    }else if(multiGeojsons.length === 1){
      newBoundary = {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: multiGeojsons[0].geometry.coordinates
        },
        properties: {...multiGeojsons[0].properties}
      }
    }

    newBoundary.properties.CALCACRES = turf.convertArea(turf.area(newBoundary), 'meters', 'acres')
    const centroid = turf.centroid(newBoundary);
    const centerPoints = centroid.geometry.coordinates;
    const [state, county] = await getStateAndCounty(centerPoints[1], centerPoints[0]);

    let newField = {
      centroid,
      boundary: JSON.stringify(newBoundary),
      state: state,
      county: county,
      latitude: centerPoints[1],
      longitude: centerPoints[0],
      id: 'uploadShapeFile',
      org: multiGeojsons[0].properties.Grower,
      farm: multiGeojsons[0].properties.Farm,
      geometry: newBoundary.geometry,
      name: multiGeojsons[0].properties.Field,
      acres:  turf.convertArea(turf.area(newBoundary), 'meters', 'acres') ,
      source: 'shape',
      feature: newBoundary,
      coordinates: newBoundary.geometry,
      points: newBoundary.geometry.coordinates
    }

    setFieldFromShape(newField)
  })
  .catch(error => console.error(error.stack));
  }
}

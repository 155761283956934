import React, { useEffect, useState, useContext, Fragment } from 'react'
import {
  AppBar,
  Box,
  Button,
  Typography,
  Divider,
  Checkbox,
  Select,
  Modal,
  TextField,
  Card,
  CardActionArea, 
  CardContent, 
  Grid,
  Tooltip,
  MenuItem,
  Drawer
 } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from "../../../styles/select";
import {PreviousBoundariesMap} from './PreviousBoundariesMap'
import { useWindowDimensions } from '../../../utils/dimensions';
import { blackText, lightGrey, grey } from '../../../styles/colors';
import { numFormat } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 50,
  },
  table:{
    minWidth: 380, 
    margin: '0 auto' 
  },
  messageBox: theme.messageBox,
  errorMessageBox: theme.errorMessageBox,
}));

export function PreviousBoundariesInfo({
  parcelBoundaries
}){
  const { width, height } = useWindowDimensions();
  const classes = useStyles();

  const [selectedBoundary, setSelectedBoundary] = useState(parcelBoundaries[0])
  const mobileBreakPoint = 860;

  const handleBoundaryChange = (target) => {
    console.log(target)
    let selected = parcelBoundaries.filter(x => x.created_at === target.value)[0]
    setSelectedBoundary(selected)
  }

  const controls = () => (
    <Box display="flex" flexWrap="wrap">
      <Box mx={1}>
        <Box>
          Boundary Date
        </Box>
        <Select
          className={classes.selections}
          variant="outlined"
          MenuProps={MenuProps}
          value={selectedBoundary.created_at}
          onChange={(e) => handleBoundaryChange(e.target)}
        >
          {
            parcelBoundaries.map((x,i) => (
              <MenuItem
                key={i}
                value={x.created_at}
                name={x.date}
              >
                {`${x.date} (${numFormat(x.Area)})`}
              </MenuItem>
            ))
          }
        </Select>
      </Box>
    </Box>
  )
  
  return(
    <Box>
      <Box
        mt={0.5}
        display="flex"
        flexDirection={width > 1080 ? 'row' : 'column'}
      >
        <Box display="flex" flexDirection="column" flexGrow={1}>
          { controls() }
          <Box
            p={0.5}
            display="flex"
            flexGrow={1}
            minWidth={'auto'}
            borderRadius={4}
          >
            <PreviousBoundariesMap
              boundary={selectedBoundary.geojson}
            />
          </Box>

        </Box>
        {/** Boundary info table */}
        <Box
            mt={1}
            className={classes.table}
          >
            <Box
              m={1}
              border={1}
              borderColor={grey}
              borderRadius="borderRadius"
              display='flex'
              flexDirection='column'
              style={{ overflowX: 'auto', minWidth: 380 }}
            >
              <Typography
                align="center"
                style={{
                  color: blackText, fontWeight: 500, fontSize: 16, margin: 4,
                }}
              >
                Boundary Information
              </Typography>
              <Divider/>
              <Box p={1} display="flex" fontSize={16}>
                <Box style={{ width: '33%' }}>
                  Area (acres)
                </Box>
                <Box pl={1} style={{ width: '33%' }}>
                  Date Created
                </Box>
                <Box style={{ width: '33%' }}>
                  In Use
                </Box>
              </Box>
              <Divider style={{ color: grey, height: 4 }} />
              <Box
                p={1}
                display="flex"
                style={{ backgroundColor: lightGrey }}
              >
                <Box style={{ width: '33%' }}>
                  {numFormat(selectedBoundary.Area)}
                </Box>
                <Box style={{ width: '33%' }}>
                  {selectedBoundary.date}
                </Box>
                <Box style={{ width: '33%' }}>
                  {selectedBoundary.IsActive ? 'True': 'False'}
                </Box>
              </Box>
            </Box>
          </Box>
      </Box>
    </Box>
  )

}
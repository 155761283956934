import React, { useState, useEffect, useRef } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors"
import {
  Box,
  Button,
  Divider,
  TextField,
  Tooltip,
  Chip,
  Checkbox,
  FormControlLabel,
  LinearProgress,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import { useWindowDimensions } from "../../../../utils/dimensions";
import { getGeoJson } from '../../../../utils/dataFetchers';
import { Zone } from "./Zone";
import { ZoneMap } from "../../../Maps/ZoneMap";
import * as con from "../../utils/conversions";
import { exists, capitalizeFirstLetter } from "../../../../utils/helpers";
import { useSnackbar } from "notistack";
import { highlightFeature } from '../../../Maps/MapFunctions/helpers'
import { SpinningLoader } from '../../../Shared/SpinningLoader';
import { ConfirmationPopup } from '../Shared/ConfirmationPopup'
import { NumberFormatCustom } from '../../../../utils/NumberFormatCustom';
import { updateEditedZones } from '../Shared/zoneFunctions'
import { zoneColors } from '../../../Maps/Styles/layerStyles'
import { cloneDeep } from "lodash";


function getModalStyle() {
  const top = 50;
  const left = 50;
  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const GreenCheckbox = withStyles({
  root: {
    color: green[400],
    '&$checked': {
      color: green[600],
    },
  },
  checked: {},
})((props) => <Checkbox color="default" {...props} />);

const useStyles = makeStyles((theme) => ({
  paper: theme.updateOperationModal,
  head: theme.updateOperationHeader,
  body: theme.updateOperationBody,
  footer: theme.updateOperationFooter,
  mapControls: theme.mapControls,
  details: theme.plDetails,
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: {
    ...theme.plInput,
    width: '212px',
  },
  disabledInput: {
    ...theme.disabledInput,
    width: '212px',
  },
  infoToolTip: theme.infoToolTip,
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * Add or edit other costs and revenue. To create a new operation, edit param
 * should be set to an object with operationID: ''.
 * @param {Number} seededAcres Net seeded area for field
 * @param {Number} year Selected year
 * @param {Number} orgId Organization operationID
 * @param {Function} setOpen Opens and closes modal
 * @param {Function} save Saves new and edited operations
 * @param {Object} edit Operation to be edited, or {operationID: ''} for new
 * @param {}
 * @param {}
 * @param {String} action Add or Edit
 * @param {}
 * @param {}
 * @param {}
 * @param {}
 */
export function UpdateOther({
  seededAcres,
  year,
  orgId,
  setOpen,
  save,
  edit,
  handleIndividualOperation,
  field,
  action,
  operations,
  setOperations,
  otherType,
  refreshGenericOperations,
  getTillageOperations
}) {

  //console.log("edit", edit, action)
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);

  // confirmation dialog
  const [openConfirm, setOpenConfirm] = useState(false);

  const [operationID, setId] = useState("");
  const [logId, setLogId] = useState(0);
  const [name, setName] = useState("");
  const [area, setAcres] = useState(0);
  const [price, setPrice] = useState(0);
  const [total, setTotal] = useState(0);
  const [isDeleted, setisDeleted] = useState(false);
  const [displayDetails, setDisplayDetails] = useState(true);

  const [clickedFeature, setClickedFeature] = useState();
  const [modifiable, setModifiable] = useState(true);
  const d = new Date()
  const [date, setDate] = useState(`${year}-${d.getMonth()+1}-${d.getDate()}`);
  // array of index of zones to display
  const [displayZones, setDisplayZones] = useState([0]);
  const [manualEntry, setManualEntry] = useState(true);
  const [otherOps, setOtherOps] = useState([]);
  const [zoneGeo, setZoneGeo] = useState({});
  const [type, setType] = useState("")

  const units = type === "Cost" ? con.costUnits : con.harvestUnits;

  const [unit, setUnit] = useState("acre")
  const costPerUnit =  useRef(0)
  const costFromTotal = useRef(0)
  const priceDisplay = useRef(0)
  const [inputValue, setInputValue] = useState("")

  // Zones from map to pass to handle zone
  const [zones, setZones] = useState([]);
  const [deletedSliceZones, setDeletedSliceZones] = useState([]);

  // Zone colors from loaded geoJson to pass to map
  const [usedZoneColors, setUsedZoneColors] = useState([])

  const [zoneCount, setZoneCount] = useState(0)
  let numOfZones = zoneCount
  //let zoneCount = 0;
  const [editLayers, setEditLayers] = useState([]);


  useEffect(()=>{
    if(type === "Field Pass"){
      setUnit("acre")
    }
    else if(type === "Tillage"){
      setUnit("inches")
    }
  },[type])

  useEffect(() => {
    numOfZones = zoneCount;
  },[zoneCount])

  useEffect(() => {
    updateTotal()
    updateUsedColors()
  }, [otherOps])

  const updateTotal = () => {
    if(type !== "Tillage"){
      let operationsTotal = 0;
      let ops = [...otherOps]
      console.log('ops', ops)
      // update total so will match what is displayed for individual zone total costs
      for (const op of ops) {
        operationsTotal += Number((op.rate * op.price * op.area))
      }
      console.log("Seeting total from otherOps update", operationsTotal)
      setTotal(operationsTotal)
    }
    
  }

  const updateUsedColors = () => {
    const colors = otherOps.map(
      x => x?.color).filter(x => x !== undefined)
    setUsedZoneColors(colors)
  }

  const formatType = (type) => {
    if(type === "Generic"){
      return edit.aux1
    }
    else{
      let words = type.split(" ")
      for(let i=0; i<words.length; i++ ){
        words[i] = words[i].charAt(0)+words[i].slice(1).toLowerCase()
      }
      let fixed = words.join(" ")
      return fixed
    }
  }

  useEffect(() => {
    // console.log('edit :>> ', edit);
    if(action === "Edit"){
      // let words = formatType(edit.fileType)
      // setType(words)
      if (edit.operationID !== "") {
        handleOperationToEdit(edit)
      }
    }
    else{
      setType(otherType)
    }

  }, [edit, action]);

  useEffect(() => {
    //console.log(zones)
    exists(zones) && zones.map(zone => handleZone(zone.zone, zone.type));
  }, [zones])

  useEffect(() => {
    setAcres(parseFloat(seededAcres));
  }, [seededAcres]);

  useEffect(() => {
    if(field && action.toLowerCase() === 'add'){
      handleNewOperation();
    }
  }, [field, action]);

  useEffect(()=> {
    if(clickedFeature != undefined){
      //console.log("varietiesL ", varieties, '\n', " clicked feature: ", clickedFeature);
      let ndx = -1;
      let i = 0;
      const clickedCoords = clickedFeature.geometry.coordinates[0];
      for (const other of otherOps){
        const zoneRef = other.zone.geometry.coordinates[0];
        if(JSON.stringify(clickedCoords) === JSON.stringify(zoneRef)){
          ndx = i;
        }
        i++;
      }
      if(ndx !== -1){
        setDisplayZones([ndx]);
      }
    }
  },[clickedFeature])



  const formatDate = (date) => {
    let noT = date.split("T")[0]
    let broken = noT.split("-")
    let fixed = [broken[2], broken[1], broken[0]].join("/")
    return fixed
  }


  const handleOperationToEdit = async (edit) =>{
    setLoading(true);
    let opType = formatType(edit.fileType)
    setType(opType)
    //console.log(edit)

    //console.log(opType)
    if(opType === "Tillage"){
      setId(edit.operationID)
      setLogId(edit.logID)
      setName(edit.productName)
      let unit = con.unitConversion(edit.targetAverageDepthUnit,true)

      setUnit(unit)
      costPerUnit.current = parseFloat(edit.price)
      priceDisplay.current = parseFloat(edit.price)
      setDate(edit.startDate.split("T")[0])
    }
    else{
      setId(edit.genericOperationID)
      setLogId(edit.genericLoggedID)
      setName(edit.productName)
      costPerUnit.current = parseFloat(edit.price)
      priceDisplay.current = parseFloat(edit.price)
      setUnit(con.unitConversion(edit.totalUnit, true))
      setDate(edit.startDate.split("T")[0])
    }

    //console.log('edit :>> ', edit);
    if(edit.zones !== undefined) {
      // Fixes some issues with zones coming back with no price after save then
      // edit before refresh. Currently all zones have $/Acre price equal to
      // that of operation cost per unit, but if that changes in future this
      // logic will need to be re-visisted.

      edit.zones = edit.zones.map(x => ({...x, price: +costPerUnit.current}))
      let totalCost = 0
      //console.log('edit.zones', edit.zones)
      
      if(edit.fileType.toUpperCase() === "TILLAGE"){
        totalCost = edit.zones.reduce((prev,next) => prev+next.price*next.area,0)
      }
      else{
        totalCost = edit.zones.reduce((prev,next) => prev+next.price,0)
      }
      
      setTotal(totalCost)
      //console.log('totalCost', totalCost)      
    }

    let totalArea = edit.zones.map(x => x.area).reduce((prev, next)=>prev+next,0)

    // get geoJson to overlay on map. each variety will have also their own
    if(edit.source === "USERENTERED"){
      // Disable editing name of generic field passes that are automatically generated
      // Beware changing this - this is based on code in ProfitLayers.js that calls createFieldPasses
      let isAutoPass = false
      if (otherType === "Field Pass") {
        const names = ["Application Costs ", "Harvesting Costs ", "Seeding Costs "];
        if ( names.includes(edit.productName.split('-')[0]) ) {
          setModifiable(false);
          totalArea = edit.area
          edit.zones[0].area = edit.area
          isAutoPass = true
        }
      }

      const geo = await getGeoJson(field.orgId, field.id, opType === "Tillage" ? edit.operationID : edit.genericOperationID, opType === "Tillage" ? opType : "Generic");
      //console.log('geo :>> ', geo);

      const neededColors = [];
      if(geo === undefined){
        enqueueSnackbar("Failed to load zone boundaries, please close and re-open the modal")
      }
      else{

        // lets figure out what colors we need here and set them
        let numColorsNeeded = geo.features.length;

        for (var x = 0; x<numColorsNeeded; x++){
          let tempColor = zoneColors[x];
          neededColors.push(tempColor);
        }

        for (var x = 0; x<geo.features.length; x++){
          let tempFeature = geo.features[x];
          tempFeature.properties.color = neededColors[x]
        }
        setZoneGeo(geo)
      }


      // add other ops based on zones
      // might need to reconstruct from geo.....
      let editZones = []
      let i =0;

      for(const zone of edit.zones){
        const rate = opType === 'Field Pass' ? 1 : (zone.total/zone.area)
        let basic = {
          area: zone.area,
          price: zone.price,
          rate: rate,
          total: zone.total,
          percentage: zone.area / totalArea,
          color: geo !== undefined ? geo.features[i].properties.color : ''
        }

        //console.log(zone.zoneName)
        let opZone = geo.features.filter(x => x.properties.Name === zone.zoneName)
        //console.log('geo['+i+'], opZone :>> ', geo[i], opZone);

        // Just skip it if there's no match. It was probably not updated correctly before fixes below
        // To fix this
        // ExistingRecord[0].description = op.ProductName; had to be added in ProfitLayersUserData.cs 
        // And processing.py had a typo in -- if 'genericZones' in genericOperation: -- section missing space after "... AND"s
        if (!opZone.length) {
          continue;
        }

        if(geo !== undefined) {
          //console.log('geo', geo)
          let opZone = geo.features.filter(x => x.properties.Name === zone.zoneName)

          if(opZone.length > 0){
            console.log('opZone', opZone)
            basic.zone = opZone[0]
            // set area to that of zone
            basic.area = opZone[0].properties.Area !== undefined ? opZone[0].properties.Area : opZone[0].properties.TargetArea
          }
        }

        if(opType === "Tillage"){
          basic.rate = zone.targetAverageDepth
        }
        i++
        editZones.push(basic)
      }
      //console.log("otherOps from input",editZones)
      setOtherOps(editZones)
    }
    // For imported tillage information from ag data
    else{
      setManualEntry(false)
      if(edit.fileType.toUpperCase() === "TILLAGE"){
        setType("Tillage")
        let others = []
        //console.log('edit znnes', edit.zones)
        for(const zone of edit.zones){
          let basic = {
            area: zone.area,
            price: zone.price,
            rate: (zone.total/zone.area),
            total: zone.total,
            percentage: zone.area / totalArea,
            rate: zone.targetAverageDepth,
            color: null
          }
          others.push(basic)
        }
        //console.log('others', others)
        setOtherOps(others)
      }
    }
    
    setLoading(false);
  }

  const [editedZones, setEditedZones] = useState([])

  useEffect(() => {
    if (editedZones.length > 0) {
      updatedEditedOtherZones(editedZones)
    }
  }, [editedZones])

  const updatedEditedOtherZones = (editedZones) => {
    const updated = updateEditedZones(editedZones, otherOps)
    setOtherOps(updated)
    setEditedZones([])
  }

  const updateGeoJson = (geoJson, newAcres) => {
    if ('color' in geoJson) {
      // This means we are editing an existing zone. The way this function is
      // use in ZoneMap we won't have access to varieties state, so we need to
      // set editedVariety state and let a useEffect call function to update
      // edited variety in varieties array.
      setEditedZones((prev) => [...prev, {zone: geoJson, acres: newAcres}])

    } else {
      updateZonesFromEditedBoundary(geoJson, newAcres)
    }
  };

  const updateZonesFromEditedBoundary = (geoJson, newAcres) => {
    if (otherOps.length === 1) {
      const updatedOtherOps = {
        ...otherOps[0],
        area: newAcres,
        zone: geoJson
      }
      setOtherOps([updatedOtherOps])
    } else {
      console.log(otherOps)
    }
  }

  const handleSave = async () => {
    let opsCopy = [...otherOps]
    console.log("opsCopy", opsCopy)
    console.log(costFromTotal.current)
    for(const op of opsCopy){
      // console.log('op :>> ', op);
      if(op.zone !== undefined && op.zone.properties !== undefined){
        delete op.zone.properties.featureRef
        delete op.zone.properties.mapRef
        delete op.zone?.featureRef
      }
      op['price'] = costPerUnit.current;
    }

    let operation;
    switch(type){
      case "Tillage":
        operation = {
          area,
          date,
          fileType: type,
          isDeleted,
          logId,
          operationID,
          orgId,
          price:costPerUnit.current,
          productName: name,
          targetAverageDepth: opsCopy[0].rate,
          targetAverageDepthUnit: 'in',
          year,
          zones: opsCopy,
        };
        opsCopy[0]['CropSeason'] = year
        break
      case "Field Pass":
        operation = {
          area,
          operationID,
          isDeleted,
          logId,
          productName: name,
          fileType: type,
          orgId,
          price:costPerUnit.current,
          year,
          total,
          date,
          unit,
          zones: opsCopy,
        };
        break
      default:
        operation = {
          area,
          operationID,
          isDeleted,
          logId,
          productName: name,
          fileType: `OTHER ${type.toUpperCase()}S`,
          orgId,
          price:costPerUnit.current,
          year,
          total,
          date,
          unit,
          zones: opsCopy,
        };
    }

    if (type !== "Revenue") {
      operation.price = 0 - operation.price;
      operation.total = 0 - operation.total;
    }

    if(edit.operationID !== "" && action==="Edit"){
      operation['operationID'] = edit.operationID
      let res = await handleIndividualOperation([operation]);
      //console.log('res', res)
    }
    console.log('edit.source', edit.source)
    if(type.toUpperCase() == "TILLAGE" && edit.source !== 'USERENTERED' && action.toUpperCase() === "EDIT"){
      getTillageOperations(field.orgId, field.id, year, [year])
    }
    else{
      save(operation, opsCopy.map(x=> x.zone), false);
    }
    
    setOpen(false);
    clear();

    // if (edit.operationID === "") {
    //   save(operation, opsCopy.map(x=> x.zone), false);
    //   setOpen(false);
    //   clear();
    // } else {
    //   operation.price = costPerUnit.current;
    //   operation['operationID'] = edit.genericOperationID
    //   console.log("operation to save",operation)
    //   let savedOpn = await handleIndividualOperation([operation]);
    //   //make sure operation log id is updated / correct
    //   operation.logId = savedOpn[0].logId
    //   //console.log([...filteredOps, operation])
    //   await refreshGenericOperations(field.orgId, field.id, year, [year], false, true)
    //   setOpen(false);
    //   clear();
    // }
    // setOpen(false);
    // clear();
  };

  const clear = () => {
    setId("");
    setLogId(0);
    setName("");
    setPrice(0);
    setTotal(0);
    setOtherOps([])
  };

  const cancel = () => {
    clear();
    setOpen(false);
  };

  const removeZone = (i) => {
    const removedZones = [...deletedSliceZones];
    try{ //This first try is in case something goes wrong identifying the zone to be removed
      //this way it wont crash, but instead the zone just wont be removed from the map

      const zoneToRem = otherOps[i];

      setDeletedSliceZones(removedZones);
      const zoneToRemCoords = zoneToRem.zone.geometry.coordinates[0];
      let map = zoneToRem.zone.properties.mapRef;

      map.eachLayer(function (layer) { //here we're going to identify the zone we want to remove
      //then do a map.removeLayer(layer);
        try{
          var currLayerCoords = layer.feature.geometry.coordinates[0];
          console.log(currLayerCoords)
          if(JSON.stringify(currLayerCoords) == JSON.stringify(zoneToRemCoords)){
            map.removeLayer(layer);
          }
        }catch(err){
        }
      });
    }catch(err){console.log("Failed to remove from map")}

    const otherOpsCopy = [...otherOps];
    otherOpsCopy.splice(i, 1);
    setOtherOps(otherOpsCopy);

  };

  const handleZoneChange = (i) => {
    if (displayZones.includes(i)) {
      setDisplayZones(displayZones.filter((x) => x !== i));
    } else {
      setDisplayZones([...displayZones, i]);
    }

    highlightFeature(i, otherOps)
  };

  const getAcres = (acres) => {
    // get acres calculated from field polygon on draw
    setAcres(acres);
  };

  const handleOpUpdate = (index, val) => {
    const opsCopy = [...otherOps];
    const edit = opsCopy.splice(index, 1)[0];

    const value = !Number.isNaN(val) ? val : "";

    //need to calculate percentages of total with new value in here
    edit["rate"] = value;
    if(type !== "Tillage"){
      let areaTotal = value * edit.area;
      let otherTotal = opsCopy.map(x => x.area * x.rate).reduce((prev, next) => prev+next, 0)
      //console.log("area total:", areaTotal, "otherTotal:", otherTotal)

      let newTotal = parseFloat(areaTotal + otherTotal);

      edit['percentage'] = parseFloat(areaTotal / newTotal)
      for(const op of opsCopy){
        let newPerc = parseFloat((op.area * op.rate) / newTotal)
        op['percentage'] = newPerc
      }

      edit["total"] = (value * edit.area)

      let zoneTotalPrice = (edit.rate * edit.price * edit.area)
      let otherTotalPrice = opsCopy.map(x=>x.rate*x.price*x.area).reduce((prev, next)=>prev+next,0)
      let newTotalPrice = zoneTotalPrice + otherTotalPrice
      setTotal(newTotalPrice)
    }

    opsCopy.splice(index, 0, edit);
    setOtherOps(opsCopy);
  };


  const handleCostUpdate = (val, type) => {
    const opsCopy = [...otherOps];

    const value = !Number.isNaN(val) ? val : "";

    //calculate new total:
    let otherVal;
    let areaTotal;
    if(type === "total"){
      console.log("total", val)
      areaTotal = otherOps.map(x=>x.area).reduce((prev, next)=>prev+next,0)
      otherVal = (value / areaTotal)
    }else{
      areaTotal = otherOps.map(x=>x.area).reduce((prev, next)=>prev+next,0)
      otherVal = (areaTotal * value)
    }

    for(const op of opsCopy){
      //recalculate area percentage
      let percentage = op.area / areaTotal
      let newRate = type === "total" ? parseFloat((value * percentage) / op.area) : value
      op.price = newRate
      op.percentage = percentage
    }

    if(type === "total"){
      console.log("otherVal", otherVal)
      setTotal(value);
      costPerUnit.current = otherVal
      priceDisplay.current = otherVal
    }
    else{
      console.log("Val from cost")
      costPerUnit.current = value
      setTotal(otherVal)
    }
    setOtherOps(opsCopy);
  }

  const handleZone = (data, zoneAction) => {
    /* Takes data, usually features, from map. Uses action string to detemine
     *  how to handle.
     */

     // Adding and editing need different checks to determinte if need to
     // remove first zone when it is complete field
    let acresToCheck = -1

    if (action === 'Add' && otherOps.length>0) {
      acresToCheck = otherOps[0].area
    } else {
      // New operation
      if (data !== undefined && data !== null && data.properties !== undefined) {
        acresToCheck = data.properties.CALCACRES
      } else {
        // Field has no zones
        acresToCheck = field.acres
      }
    }

    switch (zoneAction.toLowerCase()) {
      case "add":
        if (otherOps.length === 1 && acresToCheck === field.acres) {
          // update initial zone to current drawn acres
          const newZone = [...otherOps][0];
          newZone.area = data.properties.CALCACRES;
          newZone.zone = data;
          newZone.total = (data.properties.CALCACRES * newZone.rate)
          if(edit.operationID !== ""){
            newZone.rate = edit.price
          }
          if (data.properties.color !== undefined) {
            newZone.color = data.properties.color;
          } else {
            // Replacing first zone, so use first zone color
            newZone.color = getZoneColor(0)
          }

          // Add soil type from SSURGO
          if (data.properties.Soil_Type !== undefined) {
            newZone.soilType = data.properties.Soil_Type;
          }
          //console.log("setting newZone", newZone)
          setOtherOps([newZone]);
        } else {
          // create new zone from feature
          handleNewOperation(data);
        }
        break;
      case "multiple":
        handleMultipleOperations(data)
        break
      case "edit":
        let otherCopy = [...otherOps]
        let ndx = otherCopy.findIndex(x => x.zone.properties.id === data.properties.id)

        otherCopy[ndx].area = data.properties.CALCACRES
        otherCopy[ndx].zone = data
        otherCopy[ndx].total = (data.properties.CALCACRES * otherCopy[ndx].rate)

        if (data.properties.color !== undefined) {
          otherCopy[ndx].color = data.properties.color;
        }
        setOtherOps(otherCopy)
        break;
      case "undo":
        removeZone(otherOps.length - 1);
        break;
      case "clear":
        setOtherOps([]);
        setDisplayZones([]);
        break;
      case "reset":
        let resetCopy = [...otherOps]
        for(const feature of data.features){
          let ndx = resetCopy.findIndex(x => x.zone.properties.id === feature.properties.id)

          resetCopy[ndx].area = feature.properties.CALCACRES
          resetCopy[ndx].zone = feature
          resetCopy[ndx].total = (feature.properties.CALCACRES * resetCopy[ndx].rate)

          if (feature.properties.color !== undefined) {
            resetCopy[ndx].color = feature.properties.color;
          }
        }
        setOtherOps(resetCopy)
        break
      default:
        console.log(`unexpected action: ${action}`);
    }
  };

  const getZoneColor = (index) => {
    const available = zoneColors.filter(x => !usedZoneColors.includes(x))
    if (index < available.length) {
      return available[index]
    } else {
      return ''
    }
  }

  const handleUnitUpdate = (newUnit) => {
    setUnit(newUnit);
    setInputValue(newUnit)
    // update for varieties
    const opsCopy = [...otherOps];
    const updated = opsCopy.map((x) => ({ ...x, unit: newUnit }));
    setOtherOps(updated);
  };

  const handleNewOperation = (data = null) => {
    //this is what is used to display information for zones
    numOfZones++;
    setZoneCount(numOfZones);

    const operation = {
      name,
      fileType: "Generic",
      area: 0,
      rate: 1,
      percentage: 0,
      total: 0,
      price: costPerUnit.current
    };

    if (data !== null) {
      operation.zone = data;
      operation.area = data.properties.CALCACRES;
      operation.total = data.properties.CALCACRES
      if (data.properties.color !== undefined) {
        operation.color = data.properties.color;
      } else {
        operation.color = getZoneColor(otherOps.length)
      }

      if (data.properties.Soil_Type !== undefined) {
        operation.soilType = data.properties.Soil_Type
      }
    } else if (field.boundary !== "") {
      const feature = JSON.parse(field.boundary);
      feature.properties.CALCACRES = field.acres;
      operation.zone = feature;
      operation.area = field.acres;
      operation.total = field.acres
    } else {
      //this probably shouldnt happen
      operation.zone = {};
    }

    /* Code below is updating otherOps state directly, causing some issues
     */

    // let updatedOtherOps = []
    //
    // let geoVarieties = otherOps
    // //console.log("geoVarieties",geoVarieties)
    // if(Object.keys(zoneGeo).length > 0 && numOfZones <= zoneGeo.features.length && geoVarieties[numOfZones-1] !== undefined){
    //
    //   geoVarieties[numOfZones-1].zone = data;
    //   geoVarieties[numOfZones-1].area = data.properties.CALCACRES;
    //
    //   if (data.properties.color !== undefined) {
    //     geoVarieties[numOfZones-1].color = data.properties.color;
    //   }
    //
    //   updatedOtherOps = [...geoVarieties];
    // }
    // else{
    //   if(otherOps.length > 0){
    //     operation.price = otherOps[0].price
    //   }
    //   updatedOtherOps = [...otherOps, operation]
    // }

    // for(const op of updatedOtherOps){
    //   let opPercent = op.area / totalArea
    //   op.percentage = opPercent
    // }
    console.log(otherOps)

    setDisplayZones([ [...otherOps, operation].length - 1 ])

    setOtherOps((prev) =>[...prev, operation])

    // set display to newly created zones index
    //setDisplayZones([updatedOtherOps.length - 1]);
  };


  const handleMultipleOperations = (data) => {
    let newOps = []
    for(const item of data){
      const operation = {
        name,
        fileType: "Generic",
        area: 0,
        rate: 1,
        percentage: 0,
        total: 0,
        price: costPerUnit.current
      };

      if (item !== null) {
        operation.zone = item;
        operation.area = item.properties.CALCACRES;
        operation.total = item.properties.CALCACRES;
        if (item.properties.color !== undefined) {
          operation.color = item.properties.color;
        }
      } else if (field.boundary !== "") {
        const feature = JSON.parse(field.boundary);
        feature.properties.CALCACRES = field.acres;
        operation.zone = feature;
        operation.area = field.acres;
        operation.total = field.acres
      } else {
        //this probably shouldnt happen
        operation.zone = {};
      }

      if(otherOps.length > 0){
        operation.price = otherOps[0].price
      }

      newOps.push(operation)
    }

    let totalArea = newOps.map(x => x.area).reduce((prev, next) => prev+next,0)
    for(const op of newOps){
      let opPercent = op.area / totalArea
      op.percentage = opPercent
    }
    setOtherOps(newOps);
    // set display to newly created zones index
    setDisplayZones([newOps.length - 1]);
  }

  const handleTypeCheck = () => {
    setType(type==="Tillage" ? "Field Pass" : "Tillage")
  }

  const updatePerAcrePrice = (val) => {
    console.log("peracre price", val)
    priceDisplay.current = val
  }

  return (
    <Box
      className={classes.paper}
      borderRadius="borderRadius"
      style={{
        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
        padding: 1,
        height: height - 20,
        width: width - 20,
      }}
    >
      { /* Head */}
      <Box className={classes.head}>
        <Box p={2}>
          {action} {type} Operation
        </Box>

        <Box display="flex" style={{ marginTop: 10 }}>
          {!manualEntry && (
            <Tooltip
              title="This operation is imported from your Precision Ag Data"
              placement="left"
            >
              <Chip
                style={{ margin: "0 5px" }}
                label={
                  <Box>
                    <SyncAltIcon />
                    AG DATA
                  </Box>
                }
              />
            </Tooltip>
          )}

          <HighlightOffIcon
            className={classes.icon}
            onClick={() => setOpenConfirm(true)}
          />
        </Box>
      </Box>

      {/* Body */}
      <Box className={classes.body}>
        <Box className={classes.details} style={{ width: 490, height: height - 200 }}>
          <Box>
            <Box display="flex">
              {displayDetails ? (
                <ExpandLessIcon
                  className={classes.icon}
                  onClick={() => setDisplayDetails(false)}
                />
              ) : (
                <ExpandMoreIcon
                  className={classes.icon}
                  onClick={() => setDisplayDetails(true)}
                />
              )}
              {type} Details
              {((type === "Field Pass" || type === "Tillage") && action !== 'Edit') &&
               <Box id="check" style={{marginLeft: "30px", marginTop: "-12px"}}>
                <FormControlLabel
                  control={<GreenCheckbox checked={type === "Tillage"} onChange={handleTypeCheck} name="tillage-check"/>}
                  label="Tillage Operation"
                />
              </Box>
              }


            </Box>

            {displayDetails && (
              <Box mt={1}>
                <Box display="flex">
                  <Box>
                    <Box>Description</Box>
                    {(manualEntry && modifiable) ?
                      <TextField
                      variant="outlined"
                      className={classes.input}
                      value={name}
                      onChange={(event) => setName(event.target.value)}
                      />
                    :
                      <Box className={classes.disabledInput}>{name}</Box>
                    }

                  </Box>
                  <Box>
                    <Box>Total Price</Box>
                    <TextField
                      variant="outlined"
                      className={classes.input}
                      value={total}
                      // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                      onChange={(event) => setTotal(Number(event.target.value.replaceAll(',', '')))}
                      onBlur={(event) =>
                        handleCostUpdate(parseFloat(Number(event.target.value.replaceAll(',', ''))), "total")
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      style={{ width: "100px" }}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                    <Box>
                      Unit
                      {type === "Field Pass" ?
                        <Box className={classes.disabledInput}>
                          {unit}
                        </Box>
                      :
                      type === "Tillage" ?
                        <Box className={classes.disabledInput}>
                          {unit}
                        </Box>
                      :
                        <Autocomplete
                          className={classes.input}
                          value={unit}
                          freeSolo
                          onChange={(event, newValue) => {
                            setUnit(newValue);
                          }}
                          inputValue={inputValue}
                          onInputChange={(event, newInputValue) => {
                            handleUnitUpdate(newInputValue);
                          }}
                          options={units}
                          renderInput={(params) => (
                            <TextField {...params} variant="outlined" />
                          )}
                          disableClearable={true}
                        />
                      }

                    </Box>
                    <Box>
                    <Box>
                      Cost/{type !== "Tillage" ? capitalizeFirstLetter(unit) : "Acre"}
                    </Box>
                    <TextField
                      variant="outlined"
                      className={classes.input}
                      value={priceDisplay.current}
                      // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                      onChange={(event) => updatePerAcrePrice(Number(event.target.value.replaceAll(',', '')))}
                      onBlur={(event) =>
                        //update unit: setUnit(Number(event.target.value.replaceAll(',', '')))
                        handleCostUpdate(Number(event.target.value.replaceAll(',', '')), "perUnit")
                      }
                      InputProps={{
                        inputComponent: NumberFormatCustom,
                      }}
                      style={{ width: "100px" }}
                    />
                  </Box>
                </Box>
                <Box display="flex">
                  <Box>
                    <Box>Date</Box>
                    {manualEntry ?
                    <TextField
                      className={classes.input}
                      variant="outlined"
                      type="date"
                      InputLabelProps={{
                        shrink: true,
                      }}
                      value={date}
                      onChange={(event) => setDate(event.target.value)}
                    />
                    :
                    <Box className={classes.disabledInput}>{date}</Box>
                    }

                  </Box>
                </Box>
              </Box>
            )}

            <Divider />
          </Box>
          {otherOps.map((op, i) => (
            <Zone
              // allClickedFeatures={allClickedFeatures}
              clickedFeature={clickedFeature}
              key={i}
              zone={op}
              index={i}
              type={type}
              displayZones={displayZones}
              handleZoneChange={handleZoneChange}
              handleUpdate={handleOpUpdate}
              remove={removeZone}
              manualEntry={manualEntry}
              unit={unit}
            />
          ))}
          {/* In case there are no varieties/zones, show a message to user that they cannot save */}
          {!otherOps.length && (
            <Box m={1} display='flex'>
              {loading ?
                <Box display='flex' alignItems='center'>
                  {'Loading zones '}
                  <LinearProgress style={{width: 40, marginLeft: 8}}/>
                </Box>
                : <Box className={classes.errorMessageBox}>
                  To save, you must have at least one zone for each operation.
                </Box>
              }
            </Box>
          )}
        </Box>

        {/* width minus the size of the left details (490) and minus border (20) and padding (2) around modal */}
        <Box width={width - 490 - 20 - 2} height='100%'>
          <ZoneMap
            setClickedFeature={setClickedFeature}
            canEdit={action.toUpperCase() === "ADD" || (action.toUpperCase() === "EDIT" && edit.source.toUpperCase() === "USERENTERED")}
            geoJson={zoneGeo}
            updateGeoJson={updateGeoJson}
            getAcres={getAcres}
            isOperationMap={false}
            operationMap={null}
            origin={"Other"}
            setZones={setZones}
            deletedSliceZones={deletedSliceZones}
            operationType={"Other"}
            action={action}
            containerHeight={height - 135}
            updateZones={setEditLayers}
            usedZoneColors={usedZoneColors}
            maxZoom={field.state === "" ? 17 : 18}
          />
        </Box>
      </Box>

      {/* Footer */}
      <Box p={2} className={classes.footer}>
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: "0 25px", backgroundColor: '#ffffff' }}
          onClick={() => cancel()}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleSave()}
          disableElevation
          disabled={!otherOps.length}
        >
          Save
        </Button>

        { openConfirm && (
          <ConfirmationPopup
            setOpen={setOpenConfirm}
            save={handleSave}
            cancel={cancel}
            disableSave={!otherOps.length}
          />
        )}
      </Box>

      {loading && <SpinningLoader />}
    </Box>
  );
}

import React, {useEffect, useState} from 'react';
import {
  Page, Text, View, Document, StyleSheet, Font, Image, Link
} from '@react-pdf/renderer';
import logo from '../../../images/AgAnalyticsLogo_Black_Inline_Lower_Powered1.png'
import pl_logo from '../../../images/ProfitLayers_Full_Gradient.png'
import { grey, blackText, darkText } from '../../../styles/colors'
import organic from '../images/organic.svg';
import irrigated from '../images/irrigated.svg';
import { numFormat, dollarFormat, exists } from '../../../utils/helpers'


Font.register({
  family: 'Roboto',
  src: 'https://fonts.gstatic.com/s/roboto/v15/Uxzkqj-MIMWle-XP2pDNAA.ttf',
  crossorigin: 'anonymous'
});

const styles = StyleSheet.create({
  body: {
    paddingBottom: 65,
    fontFamily: 'Roboto',
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: '#f3f3f3',
    height: '50px'
  },
  section: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    padding: '10px',
  },
  legendValue: {
    fontSize: 10,
    fontWeight: 500,
    color: blackText,
    padding: 1,
  },
  text: {
    fontSize: 10,
    fontWeight: 500,
    color: blackText,
  },
  plLogo: {
    height: 'auto',
    width: '150px',
  },
  footerLogo: {
    position: 'absolute',
    bottom: 30,
    left: 20,
    width: '91px',
    height: 'auto',
  },
  footerLink: {
    position: 'absolute',
    bottom: 35,
    left: 130,
    fontSize: 8,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
})

export function ProfitLayerPdf({
  field,
  year,
  reportName,
  notes,
  netSeeded,
  totalCosts,
  totalRevenue,
  profitImage,
  profitLegend,
  costChart,
  scatterPlot,
  varieties,
  summary,
  seedingOps,
  harvestOps,
  chemicalOps,
  fieldPassOps,
  otherCostOps,
  otherRevenueOps,
  perAcre
}) {

  const check = (item) => {
    if (item !== undefined && item !== null) {
      return item
    } else {
      return ''
    }
  }

  const [org, setOrg] = useState('')
  const [farm, setFarm] = useState('')
  const [name, setName] = useState('')
  const [report, setReport] = useState('Data Layers')

  // for display summary
  const colors = [
    '#ff9797', '#729cff', '#7FFFD4', '#F0FFFF', '#FFF8DC', '#6495ED', '#CD5C5C'
  ]

  useEffect(() => {
    setOrg(check(field.org))
    setFarm(check(field.farm))
    setName(check(field.name))
  }, [field])

  useEffect(() => {
    if (check(reportName) !== '') {
      setReport(reportName)
    }
  }, [reportName])

  useEffect(() => {
    console.log(chemicalOps)
  }, [chemicalOps])


  function displayField() {
    return (
      <View
        style={{display: 'flex', flexDirection: 'row', padding: '5px 8px 0 8px'}}
      >
        <View style={{width: '150px'}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
            ORGANIZATION
          </Text>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
            {org}
          </Text>

        </View>
        <View style={{width: '150px'}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
            FARM
          </Text>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
            {farm}
          </Text>
        </View>
        <View style={{width: '150px'}}>
          <Text style={{fontWeight: 500, fontSize: 10, color: darkText}}>
            FIELD
          </Text>
          <Text style={{fontWeight: 500, fontSize: 10, color: blackText, margin: '4px 0'}}>
            {name}
          </Text>
        </View>
      </View>
    )
  }

  function displayProfitMap() {
    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          padding: '10px'
        }}
      >
        <Image
          src={profitImage !== null ? profitImage : ''}
          data-img={profitImage !== null ? profitImage : ''}
          style={{width: 380, height: 380}}
        />

        <View>
          { (profitLegend !== null && profitLegend.length > 0) && (
            displayProfitLegend()
          )}
        </View>


      </View>
    )
  }

  function displayProfitLegend() {
    return (
      <View
        style={{
          margin: '0 4px',
          padding: 4,
          border: 1,
          borderColor: grey,
          borderRadius: 2,
        }}
      >
        <View
          style={{
            width: '100%',
            padding: '5px 8px',
            borderBottomColor: grey,
            borderBottomWidth: 1,
          }}
        >
          <Text
            style={{
              color: blackText,
              fontSize: 12,
              fontWeight: 600,
              paddingLeft: 20,
            }}
          >
            Profit Range ($/Acre)
          </Text>
        </View>

        {
          profitLegend.map((x, i) => (
            <View
              key={i}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                padding: profitLegend.length === 30 ? '1px' : profitLegend.length === 20 ? '4px' : '8px'
              }}
            >
              <View
                style={{
                  width: '10px',
                  height: '10px',
                  backgroundColor: x.color,
                  borderRadius: 50,
                }}
              >
              </View>

              <View style={{width: '65px', margin: '0 2px'}}>
                <Text style={styles.legendValue}>
                  {dollarFormat(x.min)}

                </Text>
              </View>

              <Text style={styles.legendValue}>
                to
              </Text>

              <View style={{width: '65px', marginLeft: '5px'}}>
                <Text style={styles.legendValue}>
                  {dollarFormat(x.max)}
                </Text>
              </View>
            </View>
          ))
        }
      </View>
    )
  }

  function displaySummaryTable() {

    return (
      <View
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          padding: '10px',
        }}
        wrap={varieties.length < 12 ? false : true}
      >
        <View>
          {
            varieties.map((x, i) => (
              <View
                key={i}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  paddingBottom: 12,
                }}
              >
                <View style={{width: '100px'}}>
                  <Text
                    style={{
                      fontSize: 10,
                      fontWeight: 500,
                      color: '#606060',
                    }}
                  >
                    {x.variety}
                  </Text>
                </View>
                <View
                  style={{width: '450px', display: 'flex', flexDirection: 'column'}}
                >
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      justifyContent:'space-between'
                    }}
                  >
                    <Text style={{color: '#8c8c8c', fontSize: 10, fontWeight: 500}}>
                      Low:&nbsp;
                      {dollarFormat(x.min)}
                    </Text>
                    <Text style={{color: '#8c8c8c', fontSize: 10, fontWeight: 500}}>
                      Avg:&nbsp;
                      {dollarFormat(x.mean)}
                    </Text>
                    <Text style={{color: '#8c8c8c', fontSize: 10, fontWeight: 500}}>
                      High:&nbsp;
                      {dollarFormat(x.max)}
                    </Text>
                  </View>

                  <View
                    style={{
                      width: '450px',
                      height: '7px',
                      backgroundColor: i <= colors.length ? colors[i] : colors[i - colors.length]
                    }}
                  >
                  </View>
                </View>

              </View>
            ))
          }

          <View
            style={{
              borderBottomColor: grey,
              borderBottomWidth: 1,
              width: '75px',
              margin: '15px 0 3px 0'
            }}
          >
          </View>

          {
            summary &&
            <View style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}>
              <View style={{width: '100px'}}>
                <Text
                  style={{
                    fontSize: 10,
                    fontWeight: 500,
                    color: '#606060'
                  }}
                >
                  TOTAL PROFIT
                </Text>
              </View>
              <View
                style={{width: '450px', display: 'flex', flexDirection: 'column'}}
              >
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent:'space-between'
                  }}
                >
                  <Text style={{color: '#8c8c8c', fontSize: 10, fontWeight: 500}}>
                    {'Low $'}
                    {numFormat(summary.min)}
                  </Text>
                  <Text style={{color: '#8c8c8c', fontSize: 10, fontWeight: 500}}>
                    {'Average '}
                    {numFormat(summary.mean)}
                  </Text>
                  <Text style={{color: '#8c8c8c', fontSize: 10, fontWeight: 500}}>
                    {'High $'}
                    {numFormat(summary.max)}
                  </Text>
                </View>

                <View
                  style={{
                    width: '450px',
                    height: '7px',
                    backgroundColor: '#9e9e9e'
                  }}
                >
                </View>
              </View>

            </View>
          }
        </View>
      </View>
    )
  }

  function displayPlantings(op, i, type){

    return(
      <View
        key={i}
        style={{padding: '5px 10px'}}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            color: darkText,
            fontSize: 10,
            fontWeight: 600,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <Text>
            {op.date}
          </Text>
          <Text style={{marginLeft: 15}}>
            {op.name}
            {' - '}
            {op.varieties.length}
            {op.varieties.length === 1 ? ' Variety' : ' Varieties'}
          </Text>
        </View>

        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            color: blackText,
            fontSize: 10,
            fontWeight: 500,
            padding: '5px 8px',
          }}
        >
          <View style={{ width: '150px' }}>
            <Text>
              Variety
            </Text>
          </View>

          <View  style={{ width: '100px' }}>
            <Text>
              Acres
            </Text>
          </View>

          <View style={{ width: '100px' }}>
            <Text>
              {type === "Harvest" ? "Yield per Acre" : "Seeds per Acre"}
            </Text>
          </View>

          <View style={{ width: '100px' }}>
            <Text>
              {type === "Harvest" ? "Price" : "Seeds Price"}
            </Text>
          </View>

          <View style={{ width: '100px' }}>
            <Text>
              {type === "Harvest" ? "Revenue" : "Seed Cost"}
              {" ($/Acre)"}
              {/* {perAcre ? " ($/Acre)" : " ($)"} */}
            </Text>
          </View>
        </View>

        { op.varieties.map((x, i) => createPlantingRow(x, i)) }

      </View>
    )
  }

  function createPlantingRow(variety, i) {
     return(
       <View
         key={i}
         style={{
           display: 'flex',
           flexDirection: 'row',
           alignItems: 'center',
           color: blackText,
           fontSize: 9,
           fontWeight: 500,
           padding: 8,
           borderBottomColor: grey,
           borderBottomWidth: 1,
         }}
       >
         <View style={{ width: '150px' }}>
           <Text>
             {variety.variety}
           </Text>
         </View>

         <View  style={{ width: '100px' }}>
           <Text>
             {numFormat(variety.area)} Acres
           </Text>
         </View>

         <View style={{ width: '100px' }}>
           <Text>
             { numFormat((variety.quantity / variety.area))} {variety.unit} /Acre
           </Text>
         </View>

         <View style={{ width: '100px' }}>
           <Text>
             {dollarFormat(variety.price)} {variety.unit}
           </Text>
         </View>

         <View style={{ width: '100px' }}>
           <Text>
             { dollarFormat((variety.price * (variety.quantity / variety.area))) }
           </Text>
         </View>

       </View>
     )
  }

  function displayChemicals() {

    return (
      <View
        style={{padding: '5px 10px'}}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            color: darkText,
            fontSize: 10,
            fontWeight: 600,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <View style={{ width: '120px' }}>
            <Text>
              Application Date
            </Text>
          </View>

          <View  style={{ width: '250px' }}>
            <Text>
              Description
            </Text>
          </View>

          <View style={{ width: '100px' }}>
            <Text>
              Acres
            </Text>
          </View>

          <View style={{ width: '100px' }}>
            <Text>
              {'Cost ($/Acre)'}
            </Text>
          </View>

        </View>

        {chemicalOps.map((op, i) => createChemicalRow(op, i))}

      </View>
    )
  }

  function createChemicalRow(op, i) {
    return(
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: blackText,
          fontSize: 9,
          fontWeight: 500,
          padding: 8,
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <View style={{ width: '120px' }}>
          <Text>
            {op.date}
          </Text>
        </View>

        <View  style={{ width: '250px' }}>
          <Text>
            {op.productName}
          </Text>
        </View>

        <View style={{ width: '100px' }}>
          <Text>
            {numFormat(op.area)}
          </Text>
        </View>

        <View style={{ width: '100px' }}>
          <Text>
            {`${dollarFormat(op.operationCost, 2, 3)}/Acre`}
          </Text>
        </View>
      </View>
    )
  }

  function displayFieldPasses() {
    return (
      <View
        style={{padding: '5px 10px'}}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            color: darkText,
            fontSize: 10,
            fontWeight: 600,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <View style={{ width: '200px' }}>
            <Text>
              Description
            </Text>
          </View>

          <View  style={{ width: '100px' }}>
            <Text>
              Acres
            </Text>
          </View>

          <View style={{ width: '150px' }}>
            <Text>
              Average Cost ($ / acre)
            </Text>
          </View>

          <View style={{ width: '100px' }}>
            <Text>
              Total Cost ($)
            </Text>
          </View>
        </View>

        { fieldPassOps.map((op, i) => createFieldPassRow(op, i)) }
      </View>
    )
  }

  function createFieldPassRow(op, i){
    return(
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: blackText,
          fontSize: 9,
          fontWeight: 500,
          padding: 8,
          borderBottomColor: grey,
          borderBottomWidth: 1,
        }}
      >
        <View style={{ width: '200px' }}>
          <Text>
            {op.productName}
          </Text>
        </View>

        <View  style={{ width: '100px' }}>
          <Text>
            {numFormat(op.area)}
          </Text>
        </View>

        <View style={{ width: '150px' }}>
          <Text>
            $
            {numFormat(op.price)}
          </Text>
        </View>

        <View style={{ width: '100px' }}>
          <Text>
            $
            {numFormat((op.price * op.area))}
          </Text>
        </View>
      </View>
    )
  }

  function displayOthers(type) {

    return (
      <View
        style={{padding: '5px 10px'}}
      >
        <View
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            backgroundColor: '#f3f3f3',
            color: darkText,
            fontSize: 10,
            fontWeight: 600,
            padding: 8,
            borderRadius: 4,
          }}
        >
          <View style={{ width: '200px' }}>
            <Text>
              Description
            </Text>
          </View>

          <View  style={{ width: '100px' }}>
            <Text>
              Acres
            </Text>
          </View>

          <View style={{ width: '150px' }}>
            <Text>
              Average {type} ($ / acre)
            </Text>
          </View>

          <View style={{ width: '100px' }}>
            <Text>
              Total {type}($)
            </Text>
          </View>
        </View>
        {
          type === 'Cost'
          ? otherCostOps.map((x, i) => createOtherRow(x, i))
          : otherRevenueOps.map((x, i) => createOtherRow(x, i))
        }
      </View>
    )
  }

  function createOtherRow(x, i) {
    return (
      <View
        key={i}
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          color: blackText,
          fontSize: 9,
          fontWeight: 500,
          padding: 8,
          backgroundColor: i % 2 === 0 ?  '#ffffff' : '#f3f3f3'
        }}
      >
        <View style={{ width: '200px' }}>
          <Text>
            {x.productName}
          </Text>
        </View>

        <View  style={{ width: '100px' }}>
          <Text>
            {numFormat(x.area)}
          </Text>
        </View>

        <View style={{ width: '150px' }}>
          <Text>
            {numFormat(x.price)}
          </Text>
        </View>

        <View style={{ width: '100px' }}>
          <Text>
            {numFormat((x.price * x.area))}
          </Text>
        </View>
      </View>
    )
  }

  return(
    <Document>
      <Page wrap size='A4' style={styles.body}>

        <View style={styles.header}>
          <View style={{width: '200px'}}>

          </View>
          <View style={{width: '200px'}}>
            { pl_logo !== null && (
              <Image src={pl_logo} data-img={pl_logo} style={styles.plLogo} />
            )}
          </View>
          <View style={{width: '200px'}}>
            <Text
              style={{
                fontSize: 12,
                fontWeight: 800,
                color: blackText,
                paddingLeft: 40,
              }}
            >
              {report}
            </Text>
          </View>
        </View>

        { displayField() }

        { (profitImage !== null && profitImage !== '') && displayProfitMap() }

        {
          (varieties !== undefined && varieties !== null && varieties.length > 0 && (
            displaySummaryTable()
          ))
        }

        { notes !== '' && (
          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              margin: '8px 0 0 0'
            }}
          >
            <View
              style={{
                margin: '8px',
                padding: 8,
                border: 1,
                borderColor: grey,
                borderRadius: 2,
                width: 400,
              }}
            >
              <Text
                style={{fontWeight: 500, fontSize: 12, color: darkText}}
                >
                {'Notes: '}
              </Text>
              <Text
                style={{
                  fontWeight: 500,
                  fontSize: 10,
                  color: blackText,
                  marginTop: 4
                }}
              >
                {notes}
              </Text>
            </View>
          </View>
        )}

        {
          (
            harvestOps !== null &&
            harvestOps !== undefined &&
            harvestOps.length > 0) && (
            <View>
              <View style={{padding: '10px'}} wrap={harvestOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
                      Harvest Revenue
                    </Text>
                  </View>
                </View>

                { harvestOps.map((x, i) => displayPlantings(x, i, 'Harvest')) }

              </View>
            </View>
        )}

        {
          (
            seedingOps !== null &&
            seedingOps !== undefined &&
            seedingOps.length > 0) && (
            <View>
              <View style={{padding: '10px'}} wrap={seedingOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
                      Seed Costs
                    </Text>
                  </View>
                </View>

                { seedingOps.map((x, i) => displayPlantings(x, i, 'Seeding')) }

              </View>
            </View>
        )}

        {
          (
            chemicalOps !== null &&
            chemicalOps !== null &&
            chemicalOps.length > 0
          ) && (
            <View>
              <View style={{padding: '10px'}} wrap={chemicalOps.length > 14}>
                <View
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    alignItems: 'center',
                    padding: '13px 10px 3px 10px',
                  }}
                >
                  <View
                    style={{
                      width: '100%',
                      padding: '5px',
                      borderBottomColor: grey,
                      borderBottomWidth: 1,
                    }}
                  >
                    <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
                      Chemical and Fertilizer Costs
                    </Text>
                  </View>
                </View>

                { displayChemicals() }

              </View>
            </View>
          )
        }

        {
          (
            fieldPassOps !== null &&
            fieldPassOps !== undefined &&
            fieldPassOps.length > 0) && (
              <View>
                <View style={{padding: '10px'}} wrap={fieldPassOps.length > 14}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '13px 10px 3px 10px',
                    }}
                  >
                    <View
                      style={{
                        width: '100%',
                        padding: '5px',
                        borderBottomColor: grey,
                        borderBottomWidth: 1,
                      }}
                    >
                      <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
                        Cost of Field Passes
                      </Text>
                    </View>
                  </View>

                  { displayFieldPasses() }

                </View>
              </View>
            )
        }

        {
          (
            otherCostOps !== null &&
            otherCostOps !== undefined &&
            otherCostOps.length > 0) && (
              <View>
                <View style={{padding: '10px'}} wrap={otherCostOps.length > 14}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '13px 10px 3px 10px',
                    }}
                  >
                    <View
                      style={{
                        width: '100%',
                        padding: '5px',
                        borderBottomColor: grey,
                        borderBottomWidth: 1,
                      }}
                    >
                      <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
                        Other Costs
                      </Text>
                    </View>
                  </View>

                  { displayOthers('Cost') }

                </View>
              </View>
        )}

        {
          (
            otherRevenueOps !== null &&
            otherRevenueOps !== undefined &&
            otherRevenueOps.length > 0) && (
              <View>
                <View style={{padding: '10px'}} wrap={otherRevenueOps.length > 14}>
                  <View
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      alignItems: 'center',
                      padding: '13px 10px 3px 10px',
                    }}
                  >
                    <View
                      style={{
                        width: '100%',
                        padding: '5px',
                        borderBottomColor: grey,
                        borderBottomWidth: 1,
                      }}
                    >
                      <Text style={{fontWeight: 500, fontSize: 12, color: blackText}}>
                        Other Revenue
                      </Text>
                    </View>
                  </View>

                  { displayOthers('Revenue') }

                </View>
              </View>
        )}

        {
          (costChart !== null && costChart !=='') && (
            <View
              wrap={false}
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                margin: '10px 0 0 0'
              }}
            >
              <Image src={costChart} style={{width: '580px', height: 'auto', margin: '6px'}} />
            </View>
          )
        }

        {
          (scatterPlot !== null && scatterPlot !== '') &&
          <View
            wrap={false}
            style={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'center',
              margin: '10px 0 0 0'
            }}
          >
            <Image src={scatterPlot} style={{width: '580px', height: 'auto', margin: '6px'}} />
          </View>
        }

        { logo !== null && (
          <Image src={logo} data-img={logo} style={styles.footerLogo} fixed />
        )}

        <Link style={styles.footerLink} src={'https://profit.ag'} fixed>
          profit.ag
        </Link>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />

      </Page>
    </Document>
  )
}

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  FormControl,
  InputLabel,
  TextField,
  Select,
  MenuItem,
} from '@material-ui/core';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDateTimePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import { states } from '../../../utils/conversions';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 200,
  },
}));

/**
 * Order form shared between vendor components
 * @param {Object} formData User info
 * @param {Function} setFormData Sets user info
 * @param {String} dateSelection Date selection instructions
 * @param {Function} setFileData Sets user uploaded files
 * @returns {JSX} Order form
 */
export function OrderForm({
  formData, setFormData, dateSelection, setFileData,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [selectedDate, setSelectedDate] = useState(new Date());

  const reEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  const rePhone = /([+]?\d{1,2}[.-\s]?)?(\d{3}[.-]?){2}\d{4}/g;

  const handleEmailChange = (e) => {
    setFormData({ ...formData, email: e, validEmail: e.match(reEmail) });
  };

  const handlePhoneChange = (e) => {
    setFormData({ ...formData, phone: e, validPhone: e.match(rePhone) });
  };

  const handleChange = (e, type) => {
    switch (type) {
      case 'organization':
        setFormData({ ...formData, organization: e });
        break;
      case 'orderName':
        setFormData({ ...formData, orderName: e });
        break;
      case 'name':
        setFormData({ ...formData, userName: e });
        break;
      case 'address':
        setFormData({ ...formData, address: e });
        break;
      case 'city':
        setFormData({ ...formData, city: e });
        break;
      case 'zip':
        setFormData({ ...formData, zipCode: e });
        break;
      case 'state':
        setFormData({ ...formData, state: e });
        break;
      case 'date':
        setFormData({ ...formData, preferredDate: e });
        setSelectedDate(e);
        break;
      case 'comments':
        setFormData({ ...formData, comments: e });
        break;
      default:
        console.error('unexpected type: ', type);
    }
  };

  const handleFiles = (e) => {
    // console.log(e.target.files)
    const formFileData = new FormData();
    const maxSize = 10;
    let sizeInMB = 0;

    // eslint-disable-next-line no-restricted-syntax
    for (const file of e.target.files) {
      sizeInMB = file.size / (1024 ** 2);

      if (sizeInMB > maxSize) {
        enqueueSnackbar(`Your file (${file.name}) is too large. Please upload files under ${maxSize} MB`);
        continue;
      }

      formFileData.append('files', file);
    }

    setFileData(formFileData);
  };

  return (
    <form noValidate style={{ padding: 6 }}>
      <Box display="flex" flexWrap="wrap">
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={formData.orderName}
            onChange={(event) => handleChange(event.target.value, 'orderName')}
            label="Order Name"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={formData.organization}
            onChange={(event) => handleChange(event.target.value, 'organization')}
            label="Organization"
          />
        </FormControl>
      </Box>

      <Box display="flex" flexWrap="wrap">
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={formData.userName}
            onChange={(event) => handleChange(event.target.value, 'name')}
            label="Name"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <Box display="flex">
            <TextField
              variant="outlined"
              value={formData.email}
              onChange={(event) => handleEmailChange(event.target.value)}
              helperText={
                formData?.validEmail === false && formData.email.length !== 0 ? 'Please enter valid email' : ''
              }
              label="Email"
              disabled={formData?.disableEmailUpdate}
            />
            <Box color="red" style={{paddingLeft: '10px'}}>*</Box>
          </Box>
        </FormControl>
      </Box>

      <FormControl className={classes.formControl}>
        <TextField
          variant="outlined"
          value={formData.phone}
          onChange={(event) => handlePhoneChange(event.target.value)}
          helperText={
          formData?.validPhone === false && formData.phone.length !== 0
            ? 'Please enter valid phone number' : ''
        }
          label="Phone Number"
        />
      </FormControl>

      <Box display="flex" flexWrap="wrap">

        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={formData.address}
            onChange={(event) => handleChange(event.target.value, 'address')}
            label="Address"
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={formData.zipCode}
            onChange={(event) => handleChange(event.target.value, 'zip')}
            label="Zip"
          />
        </FormControl>
      </Box>

      <Box display="flex" flexWrap="wrap">
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            value={formData.city}
            onChange={(event) => handleChange(event.target.value, 'city')}
            label="City"
          />
        </FormControl>

        <FormControl variant="outlined" className={classes.formControl}>
          <InputLabel id="state-label">State</InputLabel>
          <Select
            variant="outlined"
            value={formData.state}
            onChange={(event) => handleChange(event.target.value, 'state')}
            labelId="state-label"
            label="state"
          >
            {states.map((s) => <MenuItem value={s.name} key={s.abbreviation}>{s.name}</MenuItem>)}
          </Select>
        </FormControl>

      </Box>

      { dateSelection && dateSelection !== '' && (
        <Box p={1} maxWidth={360}>
          <Typography variant="body1">
            { dateSelection }
          </Typography>
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDateTimePicker
              autoOk
              inputVariant="outlined"
              value={selectedDate}
              onChange={(date) => handleChange(date, 'date')}
              minDate={new Date()}
              format="MM/dd/yyyy hh:mm a"
            />
          </MuiPickersUtilsProvider>
        </Box>
      )}

      <FormControl className={classes.formControl}>
        <TextField
          variant="outlined"
          value={formData?.comments ? formData.comments : ''}
          onChange={(event) => handleChange(event.target.value, 'comments')}
          label="Comments"
          multiline
          rowsMax={4}
        />
      </FormControl>

      <Box p={1}>
        <Typography variant="body1">
          Optionally, upload any files relevant to your order.
        </Typography>
        <FormControl className={classes.formControl}>
          <input
            type="file"
            onChange={(event) => handleFiles(event)}
            label="Upload files"
            multiple
          />
        </FormControl>
      </Box>

    </form>
  );
}

OrderForm.propTypes = {
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  dateSelection: PropTypes.string,
  setFileData: PropTypes.func.isRequired,
};

OrderForm.defaultProps = {
  dateSelection: undefined,
};

/* eslint-disable require-jsdoc */
import React, {
  useEffect, useState, useContext, useRef,
} from 'react';

// MUI components and icons
import {
  createMuiTheme,
  MuiThemeProvider,
  withStyles,
  makeStyles,
} from '@material-ui/core/styles';
import {
  Box,
  Button,
  Divider,
  Grid,
  CircularProgress,
  Select,
  Typography,
  TextField,
  Menu,
  MenuItem,
  Tooltip,
  Slider,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import AddCircleOutlineOutlinedIcon from '@material-ui/icons/AddCircleOutlineOutlined';
import CreateOutlinedIcon from '@material-ui/icons/CreateOutlined';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import OpenWithIcon from '@material-ui/icons/OpenWith';
import PublishIcon from '@material-ui/icons/Publish';
import UndoIcon from '@material-ui/icons/Undo';
import ClearIcon from '@material-ui/icons/Clear';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import SaveIcon from '@material-ui/icons/Save';
import EditAttributesIcon from '@material-ui/icons/EditAttributes';
import TimelineIcon from '@material-ui/icons/Timeline';
import SkipPreviousOutlinedIcon from '@material-ui/icons/SkipPreviousOutlined';
import FolderOpenIcon from '@material-ui/icons/FolderOpen';
import GridOnOutlinedIcon from '@material-ui/icons/GridOnOutlined';
import RedoIcon from '@material-ui/icons/Redo';

// custom icons
// Leaflet and Turf
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import * as wkt from 'terraformer-wkt-parser';
import { coordAll, polygon, getType } from '@turf/turf';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { max } from 'date-fns/esm';
import { min } from 'date-fns';
import scissors from '../../images/scissors.png';
import {
  rotateIcon, horizontalIcon, verticalIcon, acresIcon,
} from './Styles/icons';

import { FieldContext } from '../Context/FieldContext';
import { exists } from '../../utils/helpers';

import { create, update } from 'lodash';

import { useSnackbar } from 'notistack';
import * as df from '../../utils/dataFetchers';
import { UploadShapeFile } from '../ProfitLayers/Operations/ManualFromShape/UploadShapeFile';
import { Properties } from '../ProfitLayers/Operations/ManualFromShape/Properties';
import { highlightFeature } from './MapFunctions/helpers';

import { zoneColors } from './Styles/layerStyles';
import { Tiles } from '../../constants/Tiles';
import { json } from 'd3';

const useStyles = makeStyles((theme) => ({
  mapControls: {
    ...theme.mapControls,
    justifyContent: 'flex-start',
    height: 50,
    padding: '0 8px',
  },
  buttonBox: {
    width: '100%',
    backgroundColor: theme.palette.greys.light,
    display: 'flex',
    justifyContent: 'flex-end',
  },
  icon: {
    color: theme.palette.greys.dark,
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  greenIcon: {
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
    fontWeight: 500,
    display: 'flex',
    alignItems: 'center',
    margin: '0 10px',
  },
  sizeSlider: {},
  otherSlider: {
    marginLeft: ' 20px',
    marginRight: '20px',
    flex: 2,
    flexDirection: 'row',
  },
}));
// ------------------------Here we will define variables for the grid ---------------------------------------------------
let zoneNum = 1; // The number of zones created so far (used in tooltip display and CreateGridZones())
let EWoffset = 0; // How far east or west we want to shift the grid on each render
let NSoffset = 0; // How far north or south we want to shift the grid on each render
let rotateVal = 0; // How much we want to rotate the grid on each render
let gridSize = 0; // The length of an edge of a grid tile in KM
let slider1; // Grid size slider on map
let slider2; // rotate slider for grid on map
let slider3; // move grid east/west on map
let slider4; // move grid north/south on map
let selected = {}; // this big array tracks every tile in the grid and if its selected, what zone it belongs to etc...

// -----------colors to be used by SSURGO, Split Field, Grid--------------------
const fillColors = zoneColors;
const zonesColors = zoneColors;
let usedColors = []; // so we dont accidentally repeat colors
//------------------------------------------------------------------------------

export function ZoneMap({
  setClickedFeature,
  deletedSliceZones,
  canEdit,
  geoJson,
  updateGeoJson,
  isOperationMap,
  operationMap,
  containerHeight,
  origin,
  setZones,
  operationType,
  combination,
  setCombination,
  handleCombinationFromShp,
  action,
  isVarietyMap,
  handleVarietyMapClick,
  usedZoneColors,
  userSelectedField,
  width,
  maxZoom
}) {
  /**
   * Handle all user interactions with drawing and editing polygons.
   * Function handleZone to allow parent function to interact with drawn layers.
   * It takes two paramters. The first can vary in structure based on the second,
   * which is the action the function will perform.
   */

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();
  const [fieldData, setFieldData] = useContext(FieldContext);
  const [map, setMap] = useState(null);
  const [zone, setZone] = useState({});
  const drawnZones = useRef([]);
  const [fieldGeo, setFieldGeo] = useState(null);
  const [gridSlider, setGridSlider] = useState(true);
  const gridlayer = useRef(L.geoJSON(null));
  const createdZones = useRef(L.geoJSON(null)); // bad naming here, this is for grid only
  const ssurgoLayers = useRef(L.geoJSON(null)); // for SSURGO zones
  const uploadedZones = useRef(L.geoJSON(null)); // shape file zones
  const [zoneToolsDropdown, setZoneToolsDropdown] = useState(null); // is it open or not
  let fillColor = '#74eded'; // light blue
  const availableColors = useRef([zoneColors]);
  const [tooltip, setToolTip] = useState(`Editing Zone: ${zoneNum}`);
  const [activeTool, setActiveTool] = useState(''); // "grid, polygon, *the new tool tim made*"
  const [cancelButton, setCancelButton] = useState(false);
  const [saveBtn, setSaveBtn] = useState(true);
  // to handle proper state updating for passing up
  const [drawnLayer, setDrawnLayer] = useState(null);
  // possible to edit multiple before clicking finish
  // store them here, when finished useEffect to update
  // drawnZones appropriately
  const [editedZones, setEditedZones] = useState([]);
  const maxZoneNum = useRef(1);
  const imageOverlay = useRef(null);

  // Track total number of zones for setting zone color
  const numberOfZones = useRef(0);

  const fieldLayer = L.featureGroup(null);
  const editGeo = useRef(null); // used for ref to geojson for editing manual operations

  const drawnPolygons = useRef(L.featureGroup(null));
  // for manually created zones that need to be edited
  const manualZones = useRef(L.featureGroup(null));

  const editedFieldGeo = useRef(null);

  // Determine if zone creating or field edit toolbar is shown
  const [creatingZones, setCreatingZones] = useState(false);

  // Determine is show cancel, undo, and finish buttons
  const [usingTool, setUsingTool] = useState(false);

  const [slicing, setSlicing] = useState(false);
  const slicingMode = useRef(false);
  const currentSlice = useRef(null);
  const slicedZones = useRef([]);
  const slicedMap = useRef(L.geoJson(null));

  // circular progress for SSURGO
  const [loading, setLoading] = useState(false);
  const deletedOps = useRef();

  // upload shape file state
  const [uploadFile, setUploadFile] = useState(false);
  const [showPropertyModal, setShowPropertyModal] = useState(false);
  const [uploadedFileGeoJSON, setUploadedFileGeoJSON] = useState(null);

  // check action type
  const [isActionEdit, setActionEdit] = useState(false);

  const [zoneBackup, setZoneBackup] = useState([]);

  useEffect(() => {
    if (action.toUpperCase() === 'EDIT') {
      setActionEdit(true);
    } else {
      setActionEdit(false);
    }
  }, [action]);

  useEffect(() => {
    // console.log("deleted zones:", deletedSliceZones);
    // console.log("length: ", deletedSliceZones.length);
    if (deletedSliceZones !== undefined) {
      deletedOps.current = [...deletedSliceZones];
    } else {
      deletedOps.current = [];
    }
    // setDeletedOps(...deletedSliceZones);
  }, [deletedSliceZones]);

  useEffect(() => {
    if (deletedOps.current.length > 0) {
      for (const deletedOp of deletedOps.current) {
        console.log('\n\n', 'delete props from : ', deletedOp);
        delete deletedOp.zone.properties.mapRef;
        delete deletedOp.zone.properties.featureRef;
      }
    }
  }, [deletedOps.current]);

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    // console.log(geoJson)
    if (map && fieldLayer) {
      if (geoJson && geoJson.features && geoJson.features.length > 0) {
        drawVarietyZone(geoJson);
      }
      manualZones.current.addTo(map);
    }
  }, [geoJson, map]);

  useEffect(() => {
    if (activeTool !== '') {
      setCancelButton(true);
    }
    if (activeTool !== '') {
      setSaveBtn(false);
    } else {
      setSaveBtn(true);
    }
  }, [activeTool]);

  useEffect(() => {
    if (!gridSlider) {
      addGridControls();
    }
  }, [gridSlider]);

  useEffect(() => {
    let flag = false;
    try {
      selected.forEach((item) => {
        if (item.selected === true && item.zone == zoneNum) {
          flag = true;
        }
      });
    } catch (err) {}
    if (zoneNum > maxZoneNum.current) {
      maxZoneNum.current = zoneNum;
    }
  }, [zoneNum]);

  useEffect(() => {
    if (
      exists(fieldData.selectedField)
      && exists(fieldData.selectedField.boundary)
      && map !== null
    ) {
      const features = JSON.parse(fieldData.selectedField.boundary);
      const newGeo = {
        type: 'FeatureCollection',
        features: [features],
      };
      map.on('pm:drawstart', ({ workingLayer }) => {
        workingLayer.on('pm:vertexadded', (e) => {
          handleSliceVertex(e);
        });
      });
      drawField(newGeo);
      setFieldGeo(newGeo);
    }
  }, [fieldData, map]);

  useEffect(() => {
    if (map && userSelectedField?.boundary) {
      try {
        const newGeo = {
          type: 'FeatureCollection',
          features: [userSelectedField.boundary],
        };
        map.on('pm:drawstart', ({ workingLayer }) => {
          workingLayer.on('pm:vertexadded', (e) => {
            handleSliceVertex(e);
          });
        });
        drawField(newGeo);
        setFieldGeo(newGeo);
      } catch (err) {
        console.error(err);
      }
    }
  }, [userSelectedField, map]);

  useEffect(() => {
    if (isOperationMap && operationMap.length > 0 && exists([map])) {
      /// /console.log(boundary, mapImage)
      let extent = ''; let
        pngb64 = '';
      if (isVarietyMap !== undefined && isVarietyMap === true) {
        extent = operationMap[1].extent === undefined
          ? operationMap[1].Extent
          : operationMap[1].extent;
        pngb64 = operationMap[1].pngb64 === undefined
          ? operationMap[1].Pngb64
          : operationMap[1].pngb64;
      } else {
        extent = operationMap[0].extent === undefined
          ? operationMap[0].Extent
          : operationMap[0].extent;
        pngb64 = operationMap[0].pngb64 === undefined
          ? operationMap[0].Pngb64
          : operationMap[0].pngb64;
      }

      const bounds = extent.split(',');
      const imageBounds = [
        [parseFloat(bounds[3]), parseFloat(bounds[0])],
        [parseFloat(bounds[1]), parseFloat(bounds[2])],
      ];
      // const bounds = mapExtents;
      // console.log("imageBounds", imageBounds);
      const mapImage = `data:image/jpeg;base64,${pngb64}`;
      /// /console.log("mapImage", mapImage)

      if (imageOverlay.current === null) {
        const overlay = L.imageOverlay(mapImage, imageBounds, {
          opacity: 0.8,
          interactive: true,
        });
        overlay.on('click', (e) => {
          console.log(e);
        });
        console.log('making overlay');
        imageOverlay.current = overlay;
        // imageOverlay.current.on('click', (e) => {
        //   console.log(e)
        // })
        imageOverlay.current.addTo(map);
        imageOverlay.current.bringToFront();
      } else {
        imageOverlay.current.setUrl(mapImage);
        imageOverlay.current.setBounds(imageBounds);
      }
    }
  }, [operationMap, map]);

  useEffect(() => {
    // exists(zone) && handleZone(zone, "add");
    exists(zone) && setZones([{ zone, type: 'add' }]);
  }, [zone]);

  useEffect(() => {
    if (drawnLayer !== null) {
      drawnZones.current = [...drawnZones, drawnLayer];
    }
  }, [drawnLayer]);

  useEffect(() => {
    drawnZones.current.forEach((item) => item.eachLayer((layer) => {
      layer.on('pm:edit', (e) => {
        // convert updated layer to geojson
        const geo = e.layer.toGeoJSON();

        geo.properties.id = layer._leaflet_id;

        // calculate new acreage
        const updatedAcres = turf.convertArea(turf.area(geo), 'meters', 'acres');
        geo.properties.CALCACRES = updatedAcres;
        setZones([{ zone: geo, type: 'edit' }]);
      });
    }));
  }, [drawnZones.current]);

  useEffect(() => {
    const used = [...usedColors, ...usedZoneColors];
    const available = zoneColors.filter((x) => !used.includes(x));
    if (available === 0) {
      // If we've used all predetermined zone colors, add a random color
      availableColors.current = getRandomColor();
    } else {
      availableColors.current = available;
    }
  }, [usedColors, usedZoneColors]);

  const updateZone = (editedZone, drawnZoneCopy) => {
    const index = drawnZones.findIndex(
      (x) => x._leaflet_id === editedZone._leaflet_id,
    );
    drawnZoneCopy[index] = editedZone;
  };

  const getFeature = (layer) => {
    const coordinates = layer._latlngs[0].map((x) => [x.lng, x.lat]);
    coordinates.push(coordinates[0]); // ensure closed polygon
    const acres = turf.area(turf.polygon([coordinates])) / 4047;

    const feature = {
      type: 'Feature',
      geometry: {
        type: 'Polygon',
        coordinates: [coordinates],
      },
      properties: {
        CALCACRES: acres,
      },
    };

    return feature;
  };

  const handleMultiPolyDraw = (feature, fieldBoundary) => {
    let clippedZones = [];
    const fieldDifferences = [];
    for (let i = 0; i < fieldBoundary.geometry.coordinates.length; i++) {
      let poly = null;
      let multiCoords;
      multiCoords = fieldBoundary.geometry.coordinates[i];
      // console.log("Curr coord set", multiCoords)
      try {
        if (multiCoords.length > 1) {
          poly = turf.polygon([multiCoords]);
        } else {
          poly = turf.polygon(multiCoords);
        }
      } catch {
        poly = null;
      }
      // console.log("Polygon", poly)
      if (poly !== null) {
        // need to distinguish multi polygon boundary from regular boundary.
        if (poly.geometry.coordinates.length > 1) { // boundary is multi poly
          // loop over boundary coordinates here, skip first becuase it is outer boundary
          const differences = []; // all differences between holes of multi and zones
          for (let j = 1; j < poly.geometry.coordinates.length; j++) {
            const tempPoly = turf.polygon([poly.geometry.coordinates[j]]);
            // console.log("tempPoly",tempPoly)
            const intersect = turf.intersect(feature, tempPoly);
            if (intersect !== null) {
              differences.push(turf.difference(feature, tempPoly));
            }
          }
          // console.log("differences", differences)
          // if differences exist, now apply them to the outer boundary
          if (differences.length > 0) {
            const outerBoundary = turf.polygon([poly.geometry.coordinates[0]]);
            for (const diffZone of differences) {
              const outterClip = turf.intersect(diffZone, outerBoundary);
              if (outterClip !== null) {
                clippedZones.push(outterClip);
              }
            }
          }
        } else {
          // if boundary is single polygon
          // console.log("SINGLE POLYGON FROM MULTI BOUNDARY")
          const tempPoly = poly;
          const intersect = turf.intersect(feature, tempPoly);
          if (intersect !== null) {
            clippedZones = [...clippedZones, intersect];
          }
        }
      } else {
        // if not a multi boundary and just multi poly:
        if (i !== 0) {
          // still skip first to avoid outer boundary
          // console.log(multiCoords)
          const tempPoly = turf.polygon([multiCoords]);
          // console.log("tempPoly", tempPoly)
          // list of differnce between current hole and all drawn zones
          if (turf.intersect(feature, tempPoly) !== null) {
            // console.log("pushing to fieldDifference")
            fieldDifferences.push(turf.difference(feature, tempPoly));
          }
          // let holeDiff = zones.map(x => turf.difference(x, tempPoly)).filter(p => p!==null);
          // fieldDifferences = [...fieldDifferences, ...holeDiff]
          // console.log(fieldDifferences)
        }
        // if i === coord length then do the outer bounds clipping after all hole clipping is done
        // console.log(j, fieldBoundary.geometry.coordinates.length)
        if (i === fieldBoundary.geometry.coordinates.length - 1) {
          if (fieldDifferences.length > 0) {
            const outerBoundary = turf.polygon([fieldBoundary.geometry.coordinates[0]]);
            // console.log("outerBoundary",outerBoundary)
            for (const diffZone of fieldDifferences) {
              const outterClip = turf.intersect(diffZone, outerBoundary);
              clippedZones.push(outterClip);
            }
          }
        }
      }
    }
    let maskedZones = [];
    if (clippedZones.length > 1) {
      try {
        const intersected = [];
        for (let i = 0; i < clippedZones.length; i++) {
          let evalZone = clippedZones[i];
          if (!intersected.includes(i)) {
            for (let j = i + 1; j < clippedZones.length; j++) {
              const clippedIntersect = turf.intersect(evalZone, clippedZones[j]);
              // console.log("clipped intersect",clippedIntersect)
              if (clippedIntersect !== null && (clippedIntersect.geometry.type.toLowerCase() === 'polygon' || clippedIntersect.geometry.type.toLowerCase() === 'multipolygon')) {
                const bigger = turf.transformScale(clippedZones[j], 1.01); // need to upscale the difference a little or can get weird artifacts leftover
                evalZone = turf.difference(evalZone, bigger);
                intersected.push(j);
              }
            }
            maskedZones.push(evalZone);
          }
        }
      } catch (err) {
        console.log(err);
        maskedZones = clippedZones;
      }
    } else {
      maskedZones = clippedZones;
    }

    // console.log("MaskedZones", maskedZones)
    // console.log(JSON.stringify(maskedZones))
    return maskedZones;
  };

  // const reformatMultiPoly = (feature) => {
  //   let multiCoords = []
  //   for(const coordinates of feature.geometry.coordinates){
  //     multiCoords.push([coordinates])
  //   }
  //   let multiPolygon = turf.multiPolygon(multiCoords)
  //   return multiPolygon
  // }

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('zoneMap')
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    map.pm.addControls({
      drawMarker: false,
      drawCircle: false,
      drawCircleMarker: false,
      drawRectangle: true,
      dragMode: true,
      drawPolygon: true,
      cutPolygon: true,
      editPolygon: true,
      drawPolyline: true,
      deleteLayer: true,
    });

    map.on('pm:create', (e) => {
      try {
        // register event handler for polygon drawing
        if (e.shape !== 'Line') {
          const polyLayer = e.layer;

          const feature = getFeature(polyLayer);
          const fieldBoundary = JSON.parse(fieldData.selectedField.boundary);
          let polys = [];
          console.log('fieldBounday', fieldBoundary);
          console.log('drawnPoly', feature);
          if (fieldBoundary.geometry.type === 'MultiPolygon' || fieldBoundary.geometry.coordinates.length > 1) {
            // then field is multipolygon -> need to check intersection of each poly
            console.log('sending to multipoly handler');
            polys = handleMultiPolyDraw(feature, fieldBoundary);
          } else {
            const overlap = turf.intersect(feature, fieldBoundary);
            console.log('overlap', overlap);
            if (overlap !== null) {
              polys.push(overlap);
            }
          }
          const final = { type: 'FeatureCollection', features: [] };
          // add items to properties for zone
          // const zoneColors = []; use imported zoneColors for consistency
          const currColors = [];
          const usedColors = [];
          const polyGeo = L.geoJSON();
          for (const item of polys) {
            // console.log(numberOfZones.current);
            const tempColor = zoneColors[numberOfZones.current];
            // zoneColors.push(tempColor);
            usedColors.push(tempColor);
            currColors.push(tempColor);

            item.properties.color = tempColor;

            item.properties.mapRef = map;
            item.properties.featureRef = drawnPolygons;
            item.properties.CALCACRES = turf.convertArea(turf.area(turf.polygon(item.geometry.coordinates)), 'meters', 'acres');
            numberOfZones.current++;
            polyGeo.addData(item);
          }
          // add into a feature collection
          polys.map((x) => final.features.push(x));

          polyGeo.eachLayer((layer) => {
            drawnPolygons.current.addLayer(layer);
          });

          polyLayer.on('pm:edit', (layer) => {
            setEditedZones([...editedZones, layer.target]);
          });

          const forZones = polys.map((x) => ({ zone: x, type: 'add' }));
          setZones(forZones);

          colorZones(zoneColors, 'draw');

          let x = 0;
          drawnPolygons.current.eachLayer((layer) => {
            // commenting this out as updates zone colors inaccurately for
            // edits and removing does not seem to have any negative effects
            // innerLayer.feature.properties.color = zoneColor
            layer.feature.properties.id = layer._leaflet_id;
            layer.on('pm:edit', (e) => {
              // convert updated layer to geojson
              const geo = e.layer.toGeoJSON();

              // calculate new acreage
              const updatedAcres = turf.convertArea(turf.area(geo), 'meters', 'acres');
              geo.properties.CALCACRES = updatedAcres;

              setZones([{ zone: geo, type: 'edit' }]);
            });
            x++;

            if (setClickedFeature) {
              layer.on('click', (e) => {
                setClickedFeature(e.target.feature);
              });
            }
          });

          map.removeLayer(polyLayer);

          map.addLayer(drawnPolygons.current);
          drawnZones.current = [drawnPolygons.current];
          setCancelButton(false);

          // if(overlap !== null){
          //   overlap.properties['mapRef'] = map
          //   overlap.properties['featureRef'] = drawnPolygons;
          //   overlap.properties.CALCACRES = turf.convertArea(turf.area(turf.polygon(overlap.geometry.coordinates)), 'meters', 'acres');
          //   overlap.properties.color = zoneColor

          //   setZone(overlap);

          //   let newPoly = L.geoJSON(overlap)

          //   const zoneTheme = {
          //     color: zoneColor,
          //     fillColor: zoneColor,
          //     fillOpacity: '0.3',
          //     strokeWidth: '5',
          //   };

          //   newPoly.setStyle(zoneTheme);
          //   drawnPolygons.current.addLayer(newPoly)
          //   let x = 0;
          //   drawnPolygons.current.eachLayer(function (layer) {
          //     layer.eachLayer(function (innerLayer) {
          //       // commenting this out as updates zone colors inaccurately for
          //       // edits and removing does not seem to have any negative effects
          //       //innerLayer.feature.properties.color = zoneColor
          //       innerLayer.feature.properties['id'] = layer._leaflet_id
          //       innerLayer.on("pm:edit", e =>{
          //         //convert updated layer to geojson
          //         let geo = e.layer.toGeoJSON()

          //         //calculate new acreage
          //         let updatedAcres = turf.convertArea(turf.area(geo), 'meters', 'acres');
          //         geo.properties.CALCACRES = updatedAcres

          //         setZones([{zone: geo, type: "edit"}])
          //       })
          //     });
          //     x++;

          //     layer.on('click', (e) => {
          //       setClickedFeature(e.target.feature);
          //     });
          //   });

          //   //colorZones([], 'draw');
          //   map.removeLayer(polyLayer);

          //   map.addLayer(drawnPolygons.current);
          //   drawnZones.current = [drawnPolygons.current];
          //   setCancelButton(false);
          // }
          // else{
          //   map.removeLayer(e.layer);
          // }
        } else {
          map.removeLayer(e.layer);
        }
      } catch (err) {
        console.log(err);
        map.removeLayer(e.layer);
      }
    });

    // map.on('click', (e) => {
    //   handleVarietyMapClick(e, imageOverlay.current)
    // })

    if(maxZoom){
      map.setMaxZoom(maxZoom)
    }
    setMap(map);
  }

  function drawVarietyZone(geo) {
    let ndx = 0;
    const layers = [];

    editGeo.current = L.geoJSON(geo);
    editGeo.current.eachLayer((layer) => {
      let color;
      if (layer.feature.color !== undefined) {
        color = layer.feature.color;
      } else if (layer.feature.properties.color !== undefined) {
        color = layer.feature.properties.color;
      } else {
        color = zonesColors[ndx];
      }

      const zoneColor = color;
      layer.feature.color = zoneColor;

      layer.setStyle({
        color: zoneColor,
        fillColor: zoneColor,
        fillOpacity: '.35',
      });

      layer.defaultOptions.selected = false;

      layer.on('click', (e) => {
        const { target } = e;
        target.defaultOptions.selected = !target.defaultOptions.selected;
        try { setClickedFeature(target.feature); } catch (err) {}
      });

      layer.on('pm:dragend', ({ layer, shape }) => {
        updatedFieldBoundary(layer);
      });

      // On edit complete
      layer.on('pm:update', ({ layer, shape }) => {
        updatedFieldBoundary(layer);
      });

      layer.feature.properties.featureRef = editGeo;
      layer.feature.properties.mapRef = map;
      layer.feature.properties.id = layer._leaflet_id;

      ndx++;
    });

    editGeo.current.addTo(map);
  }

  function drawField(geo) {
    const { geometry } = geo.features[0];
    const boundary = geometry.type === 'Polygon'
      ? turf.polygon(geo.features[0].geometry.coordinates)
      : turf.multiPolygon(geo.features[0].geometry.coordinates);

    const newCollection = L.geoJson(boundary, {
      onEachFeature: (feature, layer) => {
        fieldLayer.addLayer(layer).setStyle({
          color: '#8e24aa',
          fillColor: '#f3e5f5',
          fillOpacity: '.1',
        });

        layer.on('pm:dragend', ({ layer, shape }) => {
          updatedFieldBoundary(layer);
        });

        // On edit complete
        layer.on('pm:update', ({ layer, shape }) => {
          updatedFieldBoundary(layer);
        });
      },

      // this blocks editing and interacting with geo tools
      // pmIgnore: true,
    }).addTo(map);

    map.fitBounds(newCollection.getBounds());
  }

  const updatedFieldBoundary = (layer) => {
    const geoJson = layer.toGeoJSON();
    const collection = {
      type: 'FeatureCollection',
      features: [geoJson],
    };

    const acres = turf.convertArea(turf.area(geoJson), 'meters', 'acres');

    editedFieldGeo.current = geoJson;
    updateGeoJson(geoJson, acres);
    setFieldGeo(collection);
    // const newCollection = L.geoJson(collection)
    // map.fitBounds(newCollection.getBounds());
  };

  const addPolygon = () => {
    setCancelButton(true);
    setActiveTool('polygon');
    // start polygon draw
    const draw = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polygon',
    );
    draw[0].click();
  };

  const editBoundaries = () => {
    setCancelButton(true);
    setActiveTool('edit');
    const edit = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-edit',
    );
    // make a copy of current zones so that edits can be replaced on cancel
    const zoneCopies = drawnZones.current.map((x) => x.toGeoJSON());
    setZoneBackup(zoneCopies);

    edit[0].click();
  };

  const movePolygons = () => {
    setCancelButton(true);
    setActiveTool('move');
    const move = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-drag',
    );
    move[0].click();
  };

  // resets polygons on map to what is in zonesBackup
  const resetZones = () => {
    // remove current zones from map
    drawnZones.current.forEach((item) => {
      item.eachLayer((layer) => {
        map.removeLayer(layer);
      });
    });

    // recreate zones based on backup
    zoneBackup.forEach((item) => {
      // containing geojson layer
      const itemLayer = L.geoJSON(item);

      // re-style the layers
      itemLayer.eachLayer((layer) => {
        const { color } = layer.feature.properties;
        const style = {
          color,
          fillColor: color,
          fillOpacity: '0.3',
          strokeWidth: '5',
        };
        layer.setStyle(style);
        const updateAcres = turf.convertArea(turf.area(layer.feature), 'meters', 'acres');
        layer.feature.properties.CALCACRES = updateAcres;
      });

      // reset info in varieties
      setZones([{ zone: item, type: 'reset' }]);

      itemLayer.addTo(map);
      drawnZones.current = [itemLayer];
    });
  };

  const finishEdit = () => {
    map.pm.disableGlobalEditMode();
    // do any clean up here

    if (activeTool === 'slice') {
      currentSlice.current = null;
    }

    setCancelButton(false);
    setActiveTool('');
    setCreatingZones(false);
  };

  const handleClick = (event) => {
    setZoneToolsDropdown(event.currentTarget);
  }; // for the dropdown

  const handleClose = () => {
    setZoneToolsDropdown(null);
  }; // for the dropdown

  /**
   * Basic function to take an integer value in acres and convert it to Km
   * @param {int} value
   */
  const acToGrid = (value) => {
    const val = Math.sqrt(turf.convertArea(value, 'acres', 'kilometers'));
    addGrid(val, true);
  };

  /**
   *
   * @param {int} newvalue amount to move by
   * @param {int} gridSize length of cell-side in km for grid re-render
   * @param {string} dir either "x" or "y" to decide which direction to move the grid
   */
  const moveGrid = (newvalue, gridSize, dir) => {
    switch (dir) {
      case 'x':
        EWoffset = newvalue;
        addGrid(gridSize);
        break;
      case 'y':
        NSoffset = newvalue;
        addGrid(gridSize);
        break;
    }
  };

  /**
   * remove controls that are anchored to the map view for grid movements
   */
  const removeGridControls = () => {
    slider1.remove();
    slider2.remove();
    slider3.remove();
    slider4.remove();
  };

  /**
   * add controls for the grid to the map view
   */
  const addGridControls = () => {
    // grid size
    slider1 = L.control.slider(
      (value) => {
        acToGrid(value);
      },
      {
        id: slider1,
        orientation: 'horizontal',
        position: 'topleft',
        title: 'Grid Size',
        min: 0.5,
        max: 10,
        step: 0.5,
        value: 2,
        collapsed: true,
        increment: true,
        size: '200px',
        logo: acresIcon,
      },
    );

    // grid rotation
    slider2 = L.control.slider(
      (value) => {
        handleRotateSlider(parseInt(value));
      },
      {
        id: slider2,
        orientation: 'horizontal',
        position: 'topleft',
        title: 'Rotate Grid',
        min: -90,
        max: 90,
        step: 0.5,
        value: 0,
        collapsed: true,
        increment: true,
        size: '400px',
        logo: rotateIcon,
      },
    );

    // move E/W
    slider3 = L.control.slider(
      (value) => {
        moveGrid(parseFloat(value), gridSize, 'x');
      },
      {
        id: slider3,
        orientation: 'horizontal',
        position: 'topleft',
        title: 'Move East/West',
        min: -0.005,
        max: 0.005,
        step: 0.00006,
        value: 0,
        collapsed: true,
        size: '400px',
        increment: true,
        logo: horizontalIcon,
        showValue: false,
      },
    );

    // move N/S
    slider4 = L.control.slider(
      (value) => {
        moveGrid(parseFloat(value), gridSize, 'y');
      },
      {
        id: slider4,
        orientation: 'horizontal',
        position: 'topleft',
        title: 'Move North/South',
        min: -0.005,
        max: 0.005,
        step: 0.00006,
        value: 0,
        collapsed: true,
        increment: true,
        size: '450px',
        logo: verticalIcon,
        showValue: false,
      },
    );

    // after we define the controls we can add them to the map
    slider1.addTo(map);
    slider2.addTo(map);
    slider3.addTo(map);
    slider4.addTo(map);
  };

  /**
   * generates a random color #aabbcc and sends it back
   */
  const getRandomColor = () => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  };

  /**
   * after zones are created from a drawing tool and sent to the zone handler with the unique
   * colors in the properties, we can take an array of colors and the name of a tool
   * then we iterate through the zones on the corresponding layer and apply styling
   * @param {string array} zoneColors
   * @param {string) tool either grid or slice
   * @param {optional array of features} existing  FOR SLICE ONLY - Deprecated
   */
  const colorZones = (zoneColors, tool, existing = null) => {
    let x = 0;

    switch (tool) {
      case 'grid':
        // console.log("colors: ", zoneColors);
        createdZones.current.eachLayer((layer) => {
          if (setClickedFeature) {
            layer.on('click', (e) => {
              setClickedFeature(e.target.feature);
            });
          }

          // console.log(x);
          const zoneTheme = {
            color: zoneColors[x],
            fillColor: zoneColors[x],
            fillOpacity: '0.3',
            strokeWidth: '5',
          };
          x++;
          layer.setStyle(zoneTheme);
        });
        break;
      case 'slice':
        const newZonesNum = zoneColors.length;
        slicedMap.current.eachLayer((layer) => {
          if (setClickedFeature) {
            layer.on('click', (e) => {
              setClickedFeature(e.target.feature);
            });
          }

          if (x <= newZonesNum) {
            var zoneTheme = {
              color: zoneColors[x],
              fillColor: zoneColors[x],
              fillOpacity: '0.3',
              strokeWidth: '5',
            };
          }
          x++;
          layer.setStyle(zoneTheme);
        });
        break;
      case 'draw':
        drawnPolygons.current.eachLayer((layer) => {
          if (setClickedFeature) {
            layer.on('click', (e) => {
              setClickedFeature(e.target.feature);
            });
          }
          const zoneTheme = {
            color: zonesColors[x],
            fillColor: zonesColors[x],
            fillOpacity: '0.3',
            strokeWidth: '5',
          };
          layer.setStyle(zoneTheme);
          x++;
        });
        break;
    }
  };

  /**
   * This is the main function for the grid tool. Anytime the grid moves, or changes it gets re-rendered here
   * This will handle styling so that clicking next zone will start highlighting in a new color, it will also create
   * a bounding box for a field and apply changes to it to reflect the movements made with the controls
   * i.e. rotate, shift x or y and change size (e)
   * @param {int} e this is the size of the grid, it has a default value in case the grid accidentally
   * gets re rendered with no size set
   * @param {bool} firstRender this exists because if its the first time drawing there grid there are some
   * things have to happen a little differently
   */
  const addGrid = (e = 0.0636149354, firstRender = false) => {
    // check active tool

    // console.log("adding grid")
    // clearManualZones()
    setActiveTool('grid');
    setCancelButton(true);
    // lets begin by clearing any old grids, and revealing controls
    gridSize = e;
    gridlayer.current.clearLayers();
    setGridSlider(false);
    const geo = fieldGeo;

    const bbox = turf.bbox(fieldGeo);
    bbox[0] += -0.005 + EWoffset;
    bbox[1] += -0.005 + NSoffset;
    bbox[2] += 0.005 + EWoffset;
    bbox[3] += 0.005 + NSoffset;

    const cellSide = e;
    const squareGrid = turf.squareGrid(bbox, cellSide);

    // Now we will get intro tracking grid tiles
    let selectedTilesData = [];

    if (firstRender === true) {
      zoneNum = 1;
      setToolTip(`Editing Zone: ${zoneNum}`);
      // if its the first time drawing the grid, lets create the dictionary
      selectedTilesData = squareGrid.features.map((feature, index) => {
        const data = {
          feature, selected: false, key: index, zone: -1,
        };
        return data;
      });
    } else {
      // we have re-drawn/re-rendered the map, lets update coordinates of the dictionary instead
      selectedTilesData = selected;
      for (let x = 0; x < selectedTilesData.length; x++) {
        selected[x].feature = squareGrid.features[x];
      }
    }

    if (rotateVal != 0) {
      // console.log(rotateVal)
      const pivot = turf.center(squareGrid); // This should be the center of the entire square grid
      turf.featureEach(squareGrid, (currentFeature, featureIndex) => {
        // for-each feature/square
        const options = { pivot, mutate: true };
        const tempFeature = currentFeature; // grab the current square
        squareGrid.features[featureIndex] = turf.transformRotate(
          tempFeature,
          rotateVal,
          options,
        );
        const rotatedCopy = turf.transformRotate(tempFeature, rotateVal, options);

        try {
          selected[featureIndex].feature = rotatedCopy;
        } catch (err) {}
      });
    }
    gridlayer.current.addData(squareGrid);

    // Here we will get into styling the tiles based on if they are selected or not etc...
    gridlayer.current.eachLayer((layer) => {
      const ndx = gridlayer.current.getLayers().indexOf(layer);
      const selectedBool = selectedTilesData[ndx].selected;

      const layerZnum = selectedTilesData[ndx].zone;
      fillColor = fillColors[zoneNum - 1];
      layer.setStyle({
        weight: 0.75,
        color: 'white',
        opacity: 0.75,
        fillOpacity: '0.2',
      });

      if (selectedBool) {
        layer.setStyle({
          fillColor: fillColors[layerZnum - 1],
          fillOpacity: '0.6',
        });
      }

      layer.on('mouseover', function () {
        if (layerZnum > 0) {
          this.setStyle({
            fillColor: fillColors[layerZnum - 1],
          });
        } else {
          this.setStyle({ fillColor, fillOpacity: '0.6' });
        }
      });

      layer.on('mouseout', function () {
        const ndx = gridlayer.current.getLayers().indexOf(this);
        const selectedBool = selectedTilesData[ndx].selected;
        if (selectedBool === false) {
          this.setStyle({
            fillColor: 'white',
            fillOpacity: '0.2',
          });
        }
      });

      layer.on('click', function (e) {
        // console.log(e.target.feature);
        const ndx = gridlayer.current.getLayers().indexOf(this);
        const selectedBool = selectedTilesData[ndx].selected;

        if (selectedBool === false) {
          selectedTilesData[ndx].selected = true;
          selectedTilesData[ndx].zone = zoneNum;
          selected = selectedTilesData;
          e.target.setStyle({
            fillColor,
            fillOpacity: '0.6',
          });
        } else {
          selectedTilesData[ndx].selected = false;
        }
      });
    });
    // once its all said and done, lets copy that dictionary to a state variable so we dont lose it
    selected = selectedTilesData;
    gridlayer.current.addTo(map);
  };

  /**
 * This function will take a feature and a list of other features and return the number of tiles
 * the new one shares sides with as well as a list of who it shares sides with.
 * @param {feature} newTile
 * @param {array of features} allTiles
 */
  const sharedEdges = (newTile, allTiles) => {
    const returnArry = []; // 0 = shared edges, 1 neighbors array
    const edgeFriends = [];
    newTile = newTile.coordinates[0];
    let matches = 0;

    for (let j = 0; j < allTiles.length; j++) {
      // list of all tiles
      const currentTile = allTiles[j].coordinates[0]; // get a specific tile
      matches = 0;
      for (let x = 0; x < 4; x++) {
        // current tiles coords
        for (let y = 0; y < 4; y++) {
          // new tiles coords
          if (
            currentTile[x][0] == newTile[y][0]
            && currentTile[x][1] == newTile[y][1]
          ) {
            matches++;
            if (matches === 2) {
              edgeFriends.push(allTiles[j]);
            }
          }
        }
      }
    }
    returnArry.push(parseInt(edgeFriends.length));
    returnArry.push(edgeFriends);
    return returnArry;
  };

  /**
   * Here is the grand finale for the grid tool. After zones have been created with the grid and the finish button
   * gets clicked, this function will combine the tiles into larger features and pass them on to the zone handler
   * with the information needed for colors and deleting functionality and then it will remove the grid tools from the screen
   * putting the user into a finished editing view
   *
   */
  const createGridZones = () => {
    const zonesToMerge = maxZoneNum.current; // number of zones we're creating
    const zones = [];
    const allTiles = selected;
    const selectedTiles = [];
    let maskedZones = [];

    // -----------------check if we have selected any tiles-----------------------
    let numTilesSelected = 0;
    for (var x = 0; x < allTiles.length; x++) {
      if (allTiles[x].selected === true) numTilesSelected++;
    }

    // after check that we have selected a tile - push empty arrays
    // that structure will look like: [[], [], []...]
    if (numTilesSelected > 0) {
      for (var x = 0; x < zonesToMerge; x++) {
        selectedTiles.push([]);
      }
      //----------------------------------------------------------------------------

      // now that we set up nested arrays lets make that previous structure look like this:
      //  [ [zone1 feature, zone1 feature...], [zone2 feature, zone2 feature...] ...]
      for (var x = 0; x < allTiles.length; x++) {
        const currentTile = allTiles[x];
        if (currentTile.selected === true) {
          const zoneNdx = currentTile.zone - 1;
          selectedTiles[zoneNdx].push(currentTile.feature);
        }
      }

      try {
      //----------------------------------------------------------------------------
      // here is where we are combining the polygons to make zones
      // [ [big zone1 polygon], [big zone2 polygon] ... ]
        for (var x = 0; x < zonesToMerge; x++) {
          const featureList = selectedTiles[x];
          const tempZone = turf.union(...featureList);
          zones.push(tempZone);
        }
      } catch (err) {
        enqueueSnackbar('Something Went Wrong Clipping Your Field to the Boundary');
      }
      //----------------------------------------------------------------------------
      // At this point we have an array with sub-arrays per zone containing big polygons
      // next is to mask them so that they exist only within the field boundaries

      let fieldMask = {};
      const polysForField = [];

      // five potential field boundary cases:
      // normal field, single polygon
      // single field, multi polygon (holes)
      // multi boundary, (only single poly boundaries)
      // multi boundary, single poly and multi poly boundaries
      // multi boundary, only multi poly boundaries
      let clippedZones = [];
      try {
        if (fieldGeo.features[0].geometry.coordinates.length > 1) {
          // loop over suspected multi poly coords
          const fieldDifferences = [];
          for (let j = 0; j < fieldGeo.features[0].geometry.coordinates.length; j++) {
            const multiCoords = fieldGeo.features[0].geometry.coordinates[j];
            console.log('multi coords', multiCoords);
            let poly;
            try {
              if (multiCoords.length > 1) {
                poly = turf.polygon([multiCoords]);
              } else {
                poly = turf.polygon(multiCoords);
              }
              // poly = turf.polygon(multiCoords)
            } catch {
              poly = null;
            }
            console.log('poly', poly);

            // check to see if multiCoords was for a boundary or an actual multi
            if (poly !== null) {
              // need to distinguish multi polygon boundary from regular boundary.
              if (poly.geometry.coordinates.length > 1) { // boundary is multi poly
                console.log('MULTI POLY FROM MULTI BOUNDARY');
                // loop over boundary coordinates here, skip first becuase it is outer boundary
                const differences = []; // all differences between holes of multi and zones
                console.log(poly.geometry.coordinates.length);
                for (let i = 1; i < poly.geometry.coordinates.length; i++) {
                  const tempPoly = turf.polygon([poly.geometry.coordinates[i]]);
                  console.log('tempPoly', tempPoly);
                  for (const zone of zones) {
                    if (turf.intersect(zone, tempPoly) !== null) {
                      console.log('adding difference for multi');
                      differences.push(turf.difference(zone, tempPoly));
                    }
                  }
                }
                console.log('differences', differences);
                // if differences exist, now apply them to the outer boundary
                if (differences.length > 0) {
                  const outerBoundary = turf.polygon([poly.geometry.coordinates[0]]);
                  for (const diffZone of differences) {
                    const outterClip = turf.intersect(diffZone, outerBoundary);
                    if (outterClip !== null) {
                      clippedZones.push(outterClip);
                    }
                  }
                }
                console.log('clipped', clippedZones);
              } else {
                // if boundary is single polygon
                console.log('SINGLE POLYGON FROM MULTI BOUNDARY');
                const tempPoly = poly;
                const clips = zones.map((x) => turf.intersect(x, tempPoly)).filter((i) => i !== null);
                clippedZones = [...clippedZones, ...clips];
              }
            } else {
              // if not a multi boundary and just multi poly:
              if (j !== 0) {
                // still skip first to avoid outer boundary
                console.log(multiCoords);
                const tempPoly = turf.polygon([multiCoords]);
                console.log('tempPoly', tempPoly);
                // list of differnce between current hole and all drawn zones
                for (const zone of zones) {
                  if (turf.intersect(zone, tempPoly) !== null) {
                    // console.log("pushing to fieldDifference")
                    fieldDifferences.push(turf.difference(zone, tempPoly));
                  }
                }
                // let holeDiff = zones.map(x => turf.difference(x, tempPoly)).filter(p => p!==null);
                // fieldDifferences = [...fieldDifferences, ...holeDiff]
                // console.log(fieldDifferences)
              }
              // if j === coord length then do the outer bounds clipping after all hole clipping is done
              console.log(j, fieldGeo.features[0].geometry.coordinates.length);
              if (j === fieldGeo.features[0].geometry.coordinates.length - 1) {
                if (fieldDifferences.length > 0) {
                  const outerBoundary = turf.polygon([fieldGeo.features[0].geometry.coordinates[0]]);
                  // console.log("outerBoundary",outerBoundary)
                  for (const diffZone of fieldDifferences) {
                    const outterClip = turf.intersect(diffZone, outerBoundary);
                    if (outterClip === null) {
                      // if outter clip is null then the
                    }
                    clippedZones.push(outterClip);
                  }
                }
              }
            }
          }
        } else { // Just a single polygon single field
          // if not a multi polygon just intersect to field
          console.log('simple', fieldGeo.features[0]);
          const tempPoly = fieldGeo.features[0];
          const clips = zones.map((x) => turf.intersect(x, tempPoly)).filter((i) => i !== null);
          clippedZones = [...clippedZones, ...clips];
        }
      } catch (err) {
        console.log(err);
        return;
      }
      console.log('clipped zones', clippedZones);
      // compare clipped zones with eachother to see if any are overlapping and take difference
      if (clippedZones.length > 1) {
        try {
          const intersected = [];
          for (let i = 0; i < clippedZones.length; i++) {
            let evalZone = clippedZones[i];
            if (!intersected.includes(i)) {
              for (let j = i + 1; j < clippedZones.length; j++) {
                const clippedIntersect = turf.intersect(evalZone, clippedZones[j]);
                console.log('clipped intersect', clippedIntersect);
                if (clippedIntersect !== null
                  && (clippedIntersect.geometry.type.toLowerCase() === 'polygon' || clippedIntersect.geometry.type.toLowerCase() === 'multipolygon')) {
                  evalZone = turf.difference(evalZone, clippedZones[j]);
                  intersected.push(j);
                }
              }
              maskedZones.push(evalZone);
            }
          }
        } catch (err) {
          console.log(err);
          maskedZones = clippedZones;
        }
      } else {
        maskedZones = clippedZones;
      }

      console.log('MaskedZones', maskedZones);
      // maskedZones = clippedZones

      // console.log("zones before checking intersections", [...zones])
      // console.log("FIELD GEO", fieldGeo)
      // //if the field has multiple boundaries, we need to find those intersections
      // if (fieldGeo.features[0].geometry.coordinates.length > 1) {
      //   try{
      //   for (
      //     var t = 0;
      //     t < fieldGeo.features[0].geometry.coordinates.length;
      //     t++
      //   ) {
      //     if(fieldGeo.features[0].geometry.coordinates[t].length === 1){
      //       //field with multiple boundaries (different clus)
      //       const tempPoly = turf.polygon(
      //         fieldGeo.features[0].geometry.coordinates[t]
      //       );
      //       polysForField.push(tempPoly);
      //     }
      //     else{
      //       // //multi poly field with holes
      //       console.log("making turf polygon")
      //       console.log(fieldGeo.features[0].geometry.coordinates[t])
      //       if(fieldGeo.features[0].geometry.coordinates[t].length > 2){ // if coordinate length greater than 2 then it is list of coords
      //         for(const coords of fieldGeo.features[0].geometry.coordinates[t]){
      //           console.log("handling multi poly")
      //           const tempPoly = turf.polygon(
      //             [coords]
      //           );
      //           polysForField.push(tempPoly);
      //         }
      //       }
      //       else{
      //         const tempPoly = turf.polygon(
      //           [fieldGeo.features[0].geometry.coordinates[t]]
      //         );
      //         polysForField.push(tempPoly);
      //       }

      //       //fieldMask = fieldGeo.features[0].geometry
      //     }

      //   }
      // }catch(err){console.log(err);enqueueSnackbar("Something Went Wrong")}
      // } else {
      //   // or if the field has one boundary we can create a 'mask'
      //   fieldMask = fieldGeo.features[0].geometry;
      // }
      // //console.log("Polygons for field", polysForField)

      // // for multi polygons the first set of coords is always the outer boundary and the rest are the inner holes
      // // -> skip the first and just do differences with the inner holes
      // // -> after differences are done get intersection with outer ring.

      // const intersectingPolys = [];
      // let intersectZoneNdx = -1;
      // console.log("zones", zones)
      // //loop over drawn grid zones
      // for (var x = 0; x < zones.length; x++) {
      //   var tempZone = zones[x];
      //   //if field is multi polygon
      //   if (fieldGeo.features[0].geometry.coordinates.length > 1) {
      //     //loop over polygons in field (index at 1 to skip outter boundary)
      //     let holeIntersects = 0
      //     console.log("looping over field polys")
      //     for (var i = 0; i < polysForField.length; i++) {
      //       let intersection = turf.intersect(zones[x], polysForField[i])
      //       console.log("poly", polysForField[i], "intersect", intersection)
      //       //if zone intersects with field polygon take difference
      //       if (intersection !== null) {
      //         let difference = turf.difference(tempZone, polysForField[i])
      //         console.log("adding dif to polys",difference)
      //         if(difference !== null){
      //           intersectingPolys.push(difference)
      //           holeIntersects++
      //         }
      //       }
      //     }
      //     //if zone had no intersections with field holes, still add to list
      //     //to compare to field boundary later
      //     if(holeIntersects === 0){
      //       console.log("adding tempzone to intersecting polys")
      //       intersectingPolys.push(tempZone)
      //     }
      //   } else {
      //     zones[x] = turf.intersect(tempZone, fieldMask);
      //     maskedZones.push(turf.intersect(tempZone, fieldMask)); // instead of overwriting the array lets try this?
      //   }
      // }
      // console.log("INTERSECTING POLYGONS", intersectingPolys)

      // //first coord set being outer ring is only true for single boundary fields
      // //a field with multiple boundaries will have coordinate sets for each boundary
      // //each of which can be multi polygons, so need to check against each.
      // if (fieldGeo.features[0].geometry.coordinates.length > 1) {

      //   let outerBoundary = polysForField[0]
      //   for (var r = 0; r < intersectingPolys.length; r++) {
      //     let boundaryIntersect = turf.intersect(intersectingPolys[r], outerBoundary)
      //     maskedZones.push(boundaryIntersect)
      //   }
      // }

      // here we are going to make one more list of the created zones structured how the zone handler wants them

      const forZoneHandler = [];
      const zoneColors = [];
      const allZones = []; // old zones plus new zones
      try {
        for (var x = 0; x < maskedZones.length; x++) {
          const tempFeature = {
            type: 'Feature',
            geometry: maskedZones[x].geometry,
            properties: {
              featureRef: createdZones,
              mapRef: map,
              CALCACRES: turf.convertArea(
                turf.area(maskedZones[x]),
                'meters',
                'acres',
              ),
              zone: x + 1,
            },
          };
          forZoneHandler.push(tempFeature);
        }
      } catch (err) {
        console.log(err);
        return;
      }

      allZones.push(...forZoneHandler);
      createdZones.current.eachLayer((layer) => {
        allZones.push(layer.feature);
      });

      let y = numberOfZones.current;
      for (const zone of allZones) {
        // console.log("all zone loop: ", zone);
        const tempColor = zonesColors[y];
        zone.properties.color = tempColor;
        zoneColors.push(tempColor);
        usedColors.push(tempColor);
        y++;
      }
      let colorOffset = numberOfZones.current;
      createdZones.current.eachLayer((layer) => {
        colorOffset++;
      });

      let z = 0;
      for (const zone of forZoneHandler) {
        zone.properties.color = zonesColors[z + colorOffset];
        z++;
      }

      // send our data to the handler
      const forZones = forZoneHandler.map((x) => ({ zone: x, type: 'add' }));
      setZones(forZones);

      // Track number of zones created
      numberOfZones.current += forZones.length;

      // cleanup the map and reset states
      clearGrid();

      gridlayer.current.eachLayer((layer) => {
        layer.feature.properties.id = layer._leaflet_id;
      });

      gridlayer.current.clearLayers();

      forZoneHandler.map((zone) => createdZones.current.addData(zone));

      // console.log(createdZones.current)
      createdZones.current.eachLayer((layer) => {
        layer.feature.properties.id = layer._leaflet_id;
        layer.feature.featureRef = createdZones;
      });
      // console.log("CREATED ZONES", createdZones.current)
      drawnZones.current = [createdZones.current];
      createdZones.current.addTo(map);
      colorZones(zoneColors, 'grid');
      setCancelButton(false);
      setActiveTool('');
      setCreatingZones(false);
    } else {
      clearGrid();
    }
  };

  const undo = () => {
    if (slicingMode.current) {
      // also needs to go through and remove from currentSlice
      const sliceGeo = currentSlice.current.toGeoJSON();

      if (sliceGeo.geometry.coordinates.length > 1) {
        const newCoords = sliceGeo.geometry.coordinates;
        newCoords.splice(newCoords - 1, 1);
        currentSlice.current.setLatLngs(newCoords);
        currentSlice.current.redraw();
      } else {
        currentSlice.current = null;
      }

      const lineControl = document.getElementsByClassName(
        'control-icon leaflet-pm-icon-polyline',
      )[0];
      const control = lineControl.parentNode;
      const lineEditControls = control.nextSibling;
      let removeVertex = null;
      for (let i = 0; i < lineEditControls.childNodes.length; i++) {
        if (
          lineEditControls.childNodes[i].className
          === 'leaflet-pm-action  action-removeLastVertex'
        ) {
          removeVertex = lineEditControls.childNodes[i];
          break;
        }
      }
      removeVertex.click();
    } else if (drawnZones.current.length > 0) {
      // make a copy so we can mutate with pop to remove and use layer
      const zoneCopy = [...drawnZones.current];
      const recentZone = zoneCopy.pop();
      map.removeLayer(recentZone);
      drawnZones.current = zoneCopy;
      // handleZone(zone, "undo");
      setZones([{ zone, type: 'undo' }]);
    }
  };

  /**
   * when the clear button gets clicked, it will call a few different functions to clean stuff up from various
   * zone creating tools to maintain modularity. This function will clean everything up from the grid
   */
  const clearGrid = (removeAll = false) => {
    try {
      removeGridControls();
    } catch (err) {}
    rotateVal = 0;
    setCancelButton(false);
    EWoffset = 0;
    NSoffset = 0;
    zoneNum = 1;
    gridSize = 0.0636149354;
    setGridSlider(true);
    setToolTip(`Editing Zone: ${zoneNum}`);
    gridlayer.current.clearLayers();
    if (removeAll) {
      createdZones.current.clearLayers();
    }
  };

  const clearSlice = () => {
    setSlicing(false);
    setCancelButton(false);
    slicingMode.current = false;
    map.pm.Draw.disable();
    slicedMap.current.clearLayers();
    slicedZones.current = [];
  };

  // clear ssurgo zones
  const clearSSURGOzones = () => {
    // console.log("ssurgoLayers", ssurgoLayers);
    ssurgoLayers.current.clearLayers();
  };

  const clear = () => {
    numberOfZones.current = 0;
    drawnPolygons.current.clearLayers();
    usedColors = [];
    setActiveTool('');
    clearSlice();
    clearGrid(true);
    clearSSURGOzones();
    // handleZone([], "clear");
    setZones([{ zone: [], type: 'clear' }]);
    drawnZones.current.map((zone) => map.removeLayer(zone));
  };

  /**
   * used for decrimenting the current zone number being edited
   */
  const prevZone = () => {
    if (zoneNum > 1) {
      zoneNum--;
    }
    setToolTip(`Editing Zone: ${zoneNum}`);
    addGrid(gridSize, false);
  };

  /**
   * used for incrementing the current zone number being edited
   */
  const addNewZone = () => {
    let selectedForZone = 0;
    for (const square of selected) {
      if (square.selected && square.zone === zoneNum) {
        selectedForZone += 1;
      }
    }
    if (selectedForZone > 0) {
      zoneNum++;
      setToolTip(`Editing Zone: ${zoneNum}`);
      addGrid(gridSize, false);
    } else {
      enqueueSnackbar('Please Select At Least One Tile Before Changing Zones');
    }
  };

  /**
   * when the cancel button is clicked, there are some things that need to happen depending on what tool was
   * just canceled...also note that canceling is a different cleanup process than clicking the clear button
   * biggest difference being not wiping the created zones off of the map
   */
  const cancelClickHandler = () => {
    const tool = activeTool;
    setActiveTool('');
    switch (tool) {
      case 'grid':
        zoneNum = 1;
        removeGridControls();
        setCancelButton(false);
        rotateVal = 0;
        EWoffset = 0;
        NSoffset = 0;
        gridSize = 0.0636149354;
        setGridSlider(true);
        setToolTip(`Editing Zone: ${zoneNum}`);
        gridlayer.current.clearLayers();
        break;
      case 'polygon':
        map.pm.Draw.disable();
        setCancelButton(false);
        break;
      case 'slice':
        setSlicing(false);
        setCancelButton(false);
        slicingMode.current = false;
        currentSlice.current = null;
        map.pm.Draw.disable();
        break;
      case 'edit':
        map.pm.disableGlobalEditMode();
        setCancelButton(false);
        resetZones();
        break;
      case 'move':
        map.pm.disableGlobalDragMode();
        setCancelButton(false);
        break;
    }
  };

  const handleSliceVertex = (e) => {
    if (!slicingMode.current) {
      return;
    }

    setDrawnLayer(null);
    drawnPolygons.current.clearLayers();

    if (currentSlice.current === null) {
      currentSlice.current = L.polyline([e.latlng]);
    } else {
      currentSlice.current.addLatLng(e.latlng);
      const sliceGeo = currentSlice.current.toGeoJSON();

      // use field boundary if zones don't exist, other wise use zones
      let intersection = {
        features: [],
      };
      let features = [];
      const existing = [];

      if (slicedZones.current.length === 0) {
        // if no slices exist, push the field geojson into the features list
        if (editedFieldGeo.current === null) {
          features.push(JSON.parse(fieldData.selectedField.boundary));
        } else {
          features.push(editedFieldGeo.current);
        }
        // intersect the slice with the feature
        intersection = turf.lineIntersect(features[0], sliceGeo);
        // console.log("initial intersection",intersection);
      } else {
        // need to do a intersect check on each existing zone
        for (const sz of slicedZones.current) {
          const zoneIntersection = turf.lineIntersect(sz, sliceGeo);
          // console.log("zone intersection", zoneIntersection)
          // if intersects, add sz to features for cut
          if (zoneIntersection.features.length > 1) {
            intersection = zoneIntersection;
            // console.log("adding sz to feature", sz)
            features.push(sz);
          } else {
            // need to make sure that existing zones not in a slice are saved
            // console.log("adding sz to existing list")
            existing.push(sz);
          }
        }
      }

      // if intersection was successful?
      if (intersection.features.length > 1) {
        // clear lists of previous items
        slicedZones.current = [];
        setZones([{ zone: null, type: 'clear' }]);

        // line has connected to other edge
        // split polygon based on line
        // loop over features, checking for a multipoly. if multi, split into polys and remake list
        const multiPolyNdxs = new Set();
        const newPolys = [];
        // console.log("Features", features)
        for (const feat of features) {
          console.log(feat);
          if (feat.geometry.type == 'MultiPolygon' || feat.geometry.coordinates.length > 1) {
            // loop over coordinates and create new Polygon feature
            for (const coordinates of feat.geometry.coordinates) {
              const newP = {
                geometry: {
                  coordinates: [],
                  type: 'Polygon',
                },
                properties: {},
                type: 'Feature',
              };
              if (coordinates.length > 1) {
                newP.geometry.coordinates.push(coordinates);
              } else {
                newP.geometry.coordinates = coordinates;
              }
              newPolys.push(newP);
              multiPolyNdxs.add(features.indexOf(feat));
            }
          }
        }
        // console.log("new polys from features",newPolys)
        // console.log("features multipoly ndxs",multiPolyNdxs)

        // remove multi polygons from list and add in new created polygons
        const multiNdxArr = Array.from(multiPolyNdxs);
        multiNdxArr.map((x) => features.splice(x, 1));

        features = [...features, ...newPolys];
        // this might be the palce to take difference of overlapping polygons?
        features = removeHolePolygons(features);

        // console.log("features after poly editing", features)
        const zoneColors = [];
        let finalZones = null;

        for (const toSplit of features) {
          let split;
          // console.log(toSplit, sliceGeo.geometry)
          try {
            split = polygonCut(toSplit.geometry, sliceGeo.geometry);
            // console.log(toSplit, split);
          } catch (err) {
            // console.log("Whhops", err)
            // clear vertexes + line
            // clearSlice()
            // enqueueSnackbar("Whoops, looks like something went wrong. The Split tool struggles with fields that have strange shapes and holes. Please try another tool.",{
            //   autoHideDuration: 8000
            // } )
            return;
          }

          if (split != null) {
            // console.log("Split", split)
            const splitFeats = split.features;
            for (const feature of splitFeats) {
              const featurePoly = turf.polygon(feature.geometry.coordinates);
              const area = turf.area(featurePoly) / 4046.86;
              feature.properties.CALCACRES = area;
              slicedZones.current.push(feature);
            }
          } else {
            // console.log("Split null, tosplit:", toSplit)
            const featurePoly = turf.polygon(toSplit.geometry.coordinates);
            const area = turf.area(featurePoly) / 4046.86;
            featurePoly.properties.CALCACRES = area;
            slicedZones.current.push(featurePoly);
          }

          if (finalZones === null) {
            if (split === null) {
              finalZones = {
                type: 'FeatureCollection',
                features: [toSplit],
              };
            } else {
              finalZones = split;
            }
          } else if (split === null) {
            finalZones.features.push(toSplit);
          } else {
            split.features.map((x) => finalZones.features.push(x));
          }
        }

        slicedZones.current = [...slicedZones.current, ...existing];

        const currentZones = [];

        if (deletedOps.current.length > 0) {
          for (const deletedOp of deletedOps.current) {
            // console.log('\n\n', "delete props from : ", deletedOp);
            delete deletedOp.zone.properties.mapRef;
            delete deletedOp.zone.properties.featureRef;
          }
          // console.log(deletedOps.current);

          for (const sz of slicedZones.current) {
            let flag = false;

            for (const dz of deletedOps.current) {
              if (JSON.stringify(sz) == JSON.stringify(dz.zone)) {
                flag = true;
              }
            }

            if (!flag) { // lets only add it to the list if it hasnt been deleted
              currentZones.push(sz);
            }
          }

          slicedZones.current = [...currentZones];
        }

        // by this point if finalZones is null then there is no new completed split
        // return to avoid clearing zones and map errors
        if (finalZones === null) {
          return;
        }

        // if(Object.keys(geoJson).length === 0){
        setZones([{ zone: null, type: 'clear' }]);
        // }
        // console.log("slicedzones", slicedZones)
        const currColors = [];
        for (const feature of slicedZones.current) {
          let tempColor = zonesColors[Math.floor(Math.random() * zonesColors.length)];
          if (usedColors.includes(tempColor)) {
            tempColor = getRandomColor();
          }
          zoneColors.push(tempColor);
          usedColors.push(tempColor);
          currColors.push(tempColor);

          feature.properties.color = tempColor;
          feature.properties.featureRef = slicedMap;
          feature.properties.mapRef = map;
        }

        existing.map((x) => finalZones.features.push(x));

        // loop finalzones and add color and refs if not there
        for (const feat of finalZones.features) {
          if (feat.properties.color === undefined) {
            feat.properties.color = usedColors[finalZones.features.indexOf(feat)];
            feat.properties.mapRef = map;
            feat.properties.featureRef = slicedMap;
            const featurePoly = turf.polygon(feat.geometry.coordinates);
            const area = turf.area(featurePoly) / 4046.86;
            feat.properties.CALCACRES = area;
          }
        }
        // console.log("finalZones after adding color", finalZones)

        if (origin === 'Other') {
          // handleZone(slicedZones.current, "multiple")
          setZones([{ zone: slicedZones.current, type: 'multiple' }]);
        } else {
          // slicedZones.current.map((x) => handleZone(x, "add"));
          const forZones = slicedZones.current.map((x) => ({ zone: x, type: 'add' }));
          setZones(forZones);
        }

        // need to create zones with geojson and keep track of zones
        if (map.hasLayer(slicedMap.current)) {
          map.removeLayer(slicedMap.current);
        }
        slicedMap.current.clearLayers();
        // console.log("final zones", finalZones)

        slicedMap.current.addData(finalZones);

        // loop over layers and set feature id to layer id
        slicedMap.current.eachLayer((layer) => {
          layer.feature.properties.id = layer._leaflet_id;
        });

        // remove previously drawn from map
        drawnZones.current.forEach((item) => {
          item.eachLayer((layer) => {
            map.removeLayer(layer);
          });
          item.remove();
        });

        // set drawn zones to slicedzone for tracking purposes
        drawnZones.current = [slicedMap.current];
        slicedMap.current.addTo(map);
        colorZones(zoneColors, 'slice', slicedZones.current);

        // finish drawing line to restart
        const lineControl = document.getElementsByClassName(
          'control-icon leaflet-pm-icon-polyline',
        )[0];
        const control = lineControl.parentNode;
        const lineEditControls = control.nextSibling;
        let finishLine = null;
        for (let i = 0; i < lineEditControls.childNodes.length; i++) {
          if (
            lineEditControls.childNodes[i].className
            === 'leaflet-pm-action  action-finish'
          ) {
            finishLine = lineEditControls.childNodes[i];
            break;
          }
        }
        finishLine.click();
        // clear currentSlice
        currentSlice.current = null;

        // click start again for continued slicing
        const start = document.getElementsByClassName(
          'control-icon leaflet-pm-icon-polyline',
        )[0];
        start.click();
      }
    }
  };

  function polygonCut(polygon, line, idPrefix) {
    // this is a function to cut a pulygon into seperate polygons
    // based on a line.
    // taken from - https://gis.stackexchange.com/questions/344068/splitting-a-polygon-by-multiple-linestrings-leaflet-and-turf-js
    const THICK_LINE_UNITS = 'kilometers';
    const THICK_LINE_WIDTH = 0.001;
    let i; let j; let id; let intersectPoints; var lineCoords; let forCut; let
      forSelect;
    let thickLineString; let thickLinePolygon; let clipped; let polyg; let
      intersect;
    let polyCoords = [];
    let cutPolyGeoms = [];
    const cutFeatures = [];
    const offsetLine = [];
    let retVal = null;

    if (
      (polygon.type != 'Polygon' && polygon.type != 'MultiPolygon')
      || line.type != 'LineString'
    ) {
      return retVal;
    }

    if (typeof idPrefix === 'undefined') {
      idPrefix = '';
    }

    intersectPoints = turf.lineIntersect(polygon, line);
    if (intersectPoints.features.length == 0) {
      return retVal;
    }

    var lineCoords = turf.getCoords(line);
    if (
      turf.booleanWithin(turf.point(lineCoords[0]), polygon)
      || turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)
    ) {
      return retVal;
    }

    offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS,
    });
    offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS,
    });

    for (i = 0; i <= 1; i++) {
      forCut = i;
      forSelect = (i + 1) % 2;
      polyCoords = [];
      for (j = 0; j < line.coordinates.length; j++) {
        polyCoords.push(line.coordinates[j]);
      }
      for (
        j = offsetLine[forCut].geometry.coordinates.length - 1;
        j >= 0;
        j--
      ) {
        polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
      }
      polyCoords.push(line.coordinates[0]);

      thickLineString = turf.lineString(polyCoords);
      thickLinePolygon = turf.lineToPolygon(thickLineString);
      clipped = turf.difference(polygon, thickLinePolygon);

      cutPolyGeoms = [];
      for (j = 0; j < clipped.geometry.coordinates.length; j++) {
        polyg = turf.polygon(clipped.geometry.coordinates[j]);
        intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
        if (intersect.features.length > 0) {
          cutPolyGeoms.push(polyg.geometry.coordinates);
        }
      }

      cutPolyGeoms.forEach((geometry, index) => {
        id = `${idPrefix + (i + 1)}.${index + 1}`;
        cutFeatures.push(turf.polygon(geometry, { id }));
      });
    }

    if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);

    return retVal;
  }

  /**
 * checks list of polygons to see if any polygon lies within another and takes the difference
 * removing any holes from what should be a multipolygon
 * @param {*} features
 */
  const removeHolePolygons = (features) => {
    try {
      const maskedZones = [];
      const intersected = [];
      for (let i = 0; i < features.length; i++) {
        let evalZone = features[i];
        if (!intersected.includes(i)) {
          for (let j = i + 1; j < features.length; j++) {
            if (turf.booleanContains(evalZone, features[j])) {
              evalZone = turf.difference(evalZone, features[j]);
              intersected.push(j);
            }
          }
          maskedZones.push(evalZone);
        }
      }
      return maskedZones;
    } catch (err) {
      console.log(err);
      return features;
    }
  };

  /**
   * The text input field for grid size will send the value here to convert an input value in AC to
   * a value to be used for the grid cell side in Km, and then the grid gets re rendered with the new size
   * @param {float} event
   */
  const handleTextInputSize = (event) => {
    const floorForSize = 0.25;
    const ceilingForSize = 50;
    if (event.target.value >= floorForSize && event.target.value <= ceilingForSize) {
      const val = Math.sqrt(
        turf.convertArea(event.target.value, 'acres', 'kilometers'),
      );
      gridSize = val;
      addGrid(val, true);
    } else if (event.target.value != '' || event.target.value != 0) {
      if (event.target.value < floorForSize) {
        event.target.value = floorForSize;
        const val = Math.sqrt(
          turf.convertArea(event.target.value, 'acres', 'kilometers'),
        );
        gridSize = val;
        addGrid(val, true);
      } else if (event.target.value > ceilingForSize) {
        event.target.value = ceilingForSize;
        const val = Math.sqrt(
          turf.convertArea(event.target.value, 'acres', 'kilometers'),
        );
        gridSize = val;
        addGrid(val, true);
      }
    }
  };

  const sliceMap = () => {
    // clearManualZones();
    setCancelButton(false);
    setActiveTool('slice');
    setSlicing(true);
    slicingMode.current = true;
    const lineControl = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polyline',
    )[0];
    lineControl.click();
  };

  /**
   * Function to handle the slider for grid rotation and then re-render grid
   * @param {float from slider} newvalue
   */
  const handleRotateSlider = (newvalue) => {
    rotateVal = newvalue;
    addGrid(gridSize, false);
  };

  const completeSlice = () => {
    setSlicing(false);
    setCancelButton(false);
    setActiveTool('');
    setCreatingZones(false);
    slicingMode.current = false;
    const lineControl = document.getElementsByClassName(
      'control-icon leaflet-pm-icon-polyline',
    )[0];
    const control = lineControl.parentNode;
    const lineEditControls = control.nextSibling;
    let finishLine = null;
    for (let i = 0; i < lineEditControls.childNodes.length; i++) {
      if (
        lineEditControls.childNodes[i].className
        === 'leaflet-pm-action  action-cancel'
      ) {
        finishLine = lineEditControls.childNodes[i];
        break;
      }
    }
    currentSlice.current = null;
    finishLine.click();
  };

  const sendToZone = (feature) => {
    const fCopy = { ...feature };
    fCopy.geometry.coordinates = fCopy.geometry.coordinates[0];
    // handleZone(fCopy, "add");
    setZones([{ zone: fCopy, type: 'add' }]);
  };

  const completeMove = () => {
    try {
      const finish = document.getElementsByClassName('leaflet-pm-action  action-finishMode');
      finish[1].click();
    } catch (err) {
      console.log(err);
    }
    setActiveTool('');
  };

  /**
   * heres a customized tooltip that is used in a few different spots.
   * Just looks better than default mui tooltip
   */
  const AgToolTip = withStyles({
    tooltip: {
      fontSize: '16px',
      color: 'white',
      backgroundColor: '#67b444',
    },
  })(Tooltip);

  // To get and add SSURGO zones
  const addSSURGOzones = async () => {
    try {
      setLoading(true);
      const aoi = JSON.stringify(fieldGeo.features[0]);
      const request = {
        AOI: aoi,
        Product: 'GeoJSON',
        Soil_Parameter: 'nccpi2all',
        Projection: 'EPSG:4326',
        Resolution: '0.0001',
      };

      const res = await df.ssurgoForZones(request);
      if (res !== undefined) {
        setZones([{ zone: null, type: 'clear' }]);
        // console.log('res', res.attributes.GeoJSON.features);
        const colors = [
          '#f707a7',
          '#f55ffa',
          '#db07f7',
          '#07f7df',
          '#07cbf7',
          '#0793f7',
          '#8bf707',
          '#40e609',
          '#4bbf24',
          '#2a991e',
        ];

        for (let i = 0; i < res.attributes.GeoJSON.features.length; i++) {
          res.attributes.GeoJSON.features[i].properties.CALCACRES = turf.area(res.attributes.GeoJSON.features[i]) * 0.000247105;
          res.attributes.GeoJSON.features[i].properties.zone = i + 1;
          res.attributes.GeoJSON.features[i].properties.color = zonesColors[i];
          res.attributes.GeoJSON.features[i].properties.mapRef = map;
          res.attributes.GeoJSON.features[i].properties.featureRef = ssurgoLayers;
          setZone(res.attributes.GeoJSON.features[i]);

          ssurgoLayers.current.addData(res.attributes.GeoJSON.features[i]);
        }
        let j = 0;
        ssurgoLayers.current.eachLayer((layer) => {
          const color = fillColors[j++];

          layer.setStyle({
            fillOpacity: '0.3',
            fillColor: color,
            color,
          });

          layer.feature.properties.id = layer._leaflet_id;
        });
        // Clear other zones, otherwise will have duplicates
        clearManualZones();
        drawnZones.current = [ssurgoLayers.current];
        ssurgoLayers.current.addTo(map);
      }
      setLoading(false);
    } catch (err) { enqueueSnackbar('Failed to get SSURGO Zones'); setLoading(false); }
  };

  const displayUploadedZones = (boundary) => {
    setUploadedFileGeoJSON(boundary);

    uploadedZones.current.clearLayers();

    for (let i = 0; i < boundary.zones.length; i++) {
      boundary.zones[i].properties = {};
      boundary.zones[i].properties.CALCACRES = turf.area(boundary.zones[i]) * 0.000247105;
      boundary.zones[i].properties.zone = i + 1;
      boundary.zones[i].properties.color = zonesColors[i];
      // console.log("zonesColors[i];", zonesColors[i])
      boundary.zones[i].properties.mapRef = map;
      boundary.zones[i].properties.featureRef = uploadedZones;
      boundary.varieties[i].color = zonesColors[i];
      boundary.zones[i].properties.variety = boundary.varieties[i];
      uploadedZones.current.addData(boundary.zones[i]);
      // setZone(boundary.zones[i]);
    }

    let j = 0;
    uploadedZones.current.eachLayer((layer) => {
      const color = zonesColors[j];
      j++;

      layer.setStyle({
        fillOpacity: 0.75,
        fillColor: color,
      });
    });
    uploadedZones.current.addTo(map);
    setUploadFile(false);
    setShowPropertyModal(true);
  };

  // Function to clear created zones
  const clearManualZones = () => {
    // setZones([{zone: [], type: 'clear'}])
    manualZones.current.clearLayers();
    map.eachLayer((layer) => {
      if (layer._url === undefined) {
        map.removeLayer(layer);
      }
    });
    setActionEdit(false);
    drawField(fieldGeo);
  };

  const editFieldBoundaryToolBar = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <Box display="flex">
        <AgToolTip
          title="Edit polygons by clicking and dragging vertices."
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => editBoundaries()}
          >
            <EditLocationIcon />
            <Box>
              {' Edit'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Move polygons by clicking anywhere inside the polygon and dragging it around."
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => movePolygons()}
          >
            <OpenWithIcon />
            <Box>
              {' Move'}
            </Box>
          </Box>
        </AgToolTip>

        { operationType.toUpperCase() !== 'OTHER' && (
          <Box
            className={classes.greenIcon}
            onClick={() => setUploadFile(true)}
          >
            <PublishIcon />
            <Box>
              {' Upload Shape File'}
            </Box>
          </Box>
        )}

        <AgToolTip
          title="Generate zones based on SSURGO soil data"
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => addSSURGOzones()}
          >
            <AddCircleOutlineOutlinedIcon />
            <Box>
              {' SSURGO Zones'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Easily create zones using our grid, split, and draw tools."
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => setCreatingZones(true)}
          >
            <AddCircleOutlineOutlinedIcon />
            <Box>
              {' Create Zones'}
            </Box>
          </Box>
        </AgToolTip>
      </Box>

      <Box
        className={classes.icon}
        onClick={() => clear()}
      >
        <ClearIcon />
        <Box>
          {' Clear'}
        </Box>
      </Box>
    </Box>
  );

  const editExistingOperationToolbar = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      width="100%"
    >
      <Box display="flex">
        <AgToolTip
          title="Edit polygons by clicking and dragging vertices."
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => editBoundaries()}
          >
            <EditLocationIcon />
            <Box>
              {' Edit'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Move polygons by clicking anywhere inside the polygon and dragging it around."
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => movePolygons()}
          >
            <OpenWithIcon />
            <Box>
              {' Move'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Draw polygons by clicking to place vertices. Click the first vertex to complete the polygon"
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => addPolygon()}
          >
            <CreateOutlinedIcon />
            <Box>
              {' Draw'}
            </Box>
          </Box>
        </AgToolTip>

        { operationType.toUpperCase() !== 'OTHER' && (
          <Box
            className={classes.greenIcon}
            onClick={() => setUploadFile(true)}
          >
            <PublishIcon />
            <Box>
              {' Upload Shape File'}
            </Box>
          </Box>
        )}

        <AgToolTip
          title="Generate zones based on SSURGO soil data"
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => addSSURGOzones()}
          >
            <AddCircleOutlineOutlinedIcon />
            <Box>
              {' SSURGO Zones'}
            </Box>
          </Box>
        </AgToolTip>

      </Box>
    </Box>
  );

  const createZones = () => (
    <Box
      display="flex"
      justifyContent="space-between"
    >
      <Box display="flex">
        <AgToolTip
          title="Click tiles to add to a zone. Use sliders to control grid postion and size. To create additional zones: click 'Next Zone'. Click 'Finish' to finalize zones"
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => addGrid(0.0636149354, true)}
          >
            <GridOnOutlinedIcon />
            <Box>
              {' Gridded'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Draw lines to dissect the field by clicking to place endpoints"
          placement="top-start"
          className={classes.tootlTipLight}
        >
          <Box
            className={classes.greenIcon}
            onClick={() => startSplitField()}
          >
            <TimelineIcon />
            <Box>
              {' Split Field'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Draw polygons by clicking to place vertices. Click the first vertex to complete the polygon"
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => addPolygon()}
          >
            <CreateOutlinedIcon />
            <Box>
              {' Draw'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Edit polygons by clicking and dragging vertices."
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => editBoundaries()}
          >
            <EditLocationIcon />
            <Box>
              {' Edit'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Move polygons by clicking anywhere inside the polygon and dragging it around."
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => movePolygons()}
          >
            <OpenWithIcon />
            <Box>
              {' Move'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Generate zones based on SSURGO soil data"
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => addSSURGOzones()}
          >
            <AddCircleOutlineOutlinedIcon />
            <Box>
              {' SSURGO Zones'}
            </Box>
          </Box>
        </AgToolTip>

        <AgToolTip
          title="Go back to edit field boundary"
          placement="top-start"
        >
          <Box
            className={classes.greenIcon}
            onClick={() => setCreatingZones(false)}
          >
            <SkipPreviousOutlinedIcon />
            <Box>
              {' Edit Field'}
            </Box>
          </Box>
        </AgToolTip>
      </Box>

      <Box
        className={classes.icon}
        onClick={() => clear()}
      >
        <ClearIcon />
        {' Clear'}
      </Box>

    </Box>
  );

  const usingToolsBar = () => (
    <Box
      className={classes.greenIcon}
      onClick={() => cancelClickHandler()}
    >
      <CancelOutlinedIcon />
      <Box>
        {' Cancel'}
      </Box>
    </Box>
  );

  const gridToolbar = () => (
    <Box
      display="flex"
      justifyContent="space-between"
      alignItems="center"
    >
      <Box
        display="flex"
        alignItems="center"
      >
        <TextField
          defaultValue={2}
          id="grid-acres"
          variant="outlined"
          onChange={(event) => handleTextInputSize(event)}
          inputProps={{
            style: {
              padding: 7,
            },
          }}
          style={{
            height: '40px',
            width: '50px',
            marginTop: 6,
            backgroundColor: '#ffffff',
          }}
        />
        <Box fontWeight={500} ml={0.5}>
          Grid Size (Acres/Tile)
        </Box>

        <Button
          variant="outlined"
          color="primary"
          style={{
            height: 30,
            margin: '0px 16px',
            whiteSpace: 'nowrap',
            backgroundColor: '#ffffff',
          }}
          onClick={() => prevZone()}
        >
          Prev Zone
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => addNewZone()}
          style={{
            height: 30,
            marginRight: 16,
            whiteSpace: 'nowrap',
            backgroundColor: '#ffffff',
          }}
        >
          Next Zone
        </Button>

        <Button
          variant="outlined"
          color="primary"
          onClick={() => {
            try { createGridZones(); } catch (err) {
              enqueueSnackbar('Something Went Wrong Creating Your Zone(s)', err);
            }
          }}
          style={{
            height: 30,
            backgroundColor: '#ffffff',
          }}
        >
          Finish
        </Button>
      </Box>

      <Box
        className={classes.icon}
        onClick={() => cancelClickHandler()}
      >
        <ClearIcon />
        Cancel
      </Box>
    </Box>
  );

  const editToolbar = () => (
    <Box display="flex">
      <Button
        color="primary"
        variant="outlined"
        onClick={() => finishEdit()}
        style={{ height: 30, backgroundColor: '#ffffff' }}
      >
        Finish Edit
      </Button>

      {/* <Box
        className={classes.greenIcon}
        onClick={() => cancelClickHandler()}
      >
        <CancelOutlinedIcon />
        <Box>
          {' Cancel'}
        </Box>
      </Box> */}
    </Box>
  );

  const slicingToolBar = () => (
    <Box display="flex">
      <Button
        color="primary"
        variant="outlined"
        onClick={() => completeSlice()}
        style={{ height: 30, backgroundColor: '#ffffff' }}
      >
        Finish Edit
      </Button>

      {/* <Box
        className={classes.greenIcon}
        onClick={() => cancelClickHandler()}
      >
        <CancelOutlinedIcon />
        <Box>
          {' Cancel'}
        </Box>
      </Box> */}
    </Box>
  );

  const movingToolBar = () => (
    <Box display="flex">
      <Button
        color="primary"
        variant="outlined"
        onClick={() => completeMove()}
        style={{ height: 30, backgroundColor: '#ffffff' }}
      >
        Finish Move
      </Button>

      {/* <Box
        className={classes.greenIcon}
        onClick={() => cancelClickHandler()}
      >
        <CancelOutlinedIcon />
        <Box>
          {' Cancel'}
        </Box>
      </Box> */}
    </Box>
  );

  const startSplitField = () => {
    try {
      sliceMap();
    } catch (err) {
      enqueueSnackbar('Looks like something went wrong. The Split tool struggles with fields that have strange shapes and holes. Please try another tool.', {
        autoHideDuration: 8000,
      });
    }
  };

  return (
    <Box
      style={{
        height: containerHeight !== undefined ? containerHeight : '100%',
        width: width || 'auto',
      }}
    >
      { canEdit && (
        <Box className={classes.mapControls}>
          { activeTool === 'grid' ? (
            gridToolbar()
          ) : activeTool === 'edit' ? (
            editToolbar()
          ) : activeTool === 'slice' ? (
            slicingToolBar()
          ) : activeTool == 'move' ? (
            movingToolBar()
          ) : creatingZones ? (
            createZones()
          ) : isActionEdit ? (
            editExistingOperationToolbar()
          ) : (
            editFieldBoundaryToolBar()
          )}
        </Box>
      )}

      {!gridSlider && <Box className={classes.gridControls} />}
      {uploadFile && (
      <UploadShapeFile
        uploadFile={uploadFile}
        setUploadFile={setUploadFile}
        displayUploadedZones={displayUploadedZones}
      />
      )}
      {showPropertyModal && (
      <Properties
        uploadedFileGeoJSON={uploadedFileGeoJSON}
        showModal={showPropertyModal}
        operationType={operationType}
        setShowPropertyModal={setShowPropertyModal}
        combination={combination}
        setCombination={setCombination}
        handleCombinationFromShp={handleCombinationFromShp}
      />
      )}
      {loading && (
      <Box display="flex" justifyContent="center" alignItems="center">
        <CircularProgress
          style={{
            position: 'absolute',
            right: 'auto',
            top: '50%',
            zIndex: 1005,
            color: '#1e88e5',
          }}
        />
      </Box>
      )}
      <div
        id="zoneMap"
        style={{
          height: (containerHeight !== undefined && !isOperationMap) ? containerHeight - 50 : (containerHeight !== undefined && isOperationMap) ? '93%' : '100%',
          width: width || 'auto',
        }}
      />
    </Box>
  );
}

import React, { useEffect, useState, useRef } from 'react';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import 'leaflet-easyprint';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { drawFieldLayer, drawImage } from '../../../Maps/MapFunctions/helpers';
import { exists } from '../../../../utils/helpers';
import { zoneColors } from '../../../Maps/Styles/layerStyles';
import { Tiles } from '../../../../constants/Tiles';

/**
 * Displays GeoJSON and field boundary on map. Attatches click interactions to
 * zone and point selection. Colors zones and points based on their index from
 * imported zoneColors array.
 * @param {Object} boundary Field boundary
 * @param {Object} mapGeo GeoJSON from shapefile
 * @param {Function} selectZone Selects zone on click for display
 * @param {String} type Determined by geometry type. Zones or Points.
 */
export function SoilResultsMap({
  boundary, mapGeo, selectZone, type, base64, extent
}) {
  const [map, setMap] = useState(null);
  const [showImagery, setShowImagery] = useState(true);

  const imageOverlay = useRef(null);
  const fieldLayer = L.featureGroup(null);
  const geoLayer = useRef(null);

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);

      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      //drawFieldLayer(geoJson, fieldLayer, map);
    }
  }, [boundary, map]);


  useEffect(() => {
    if (map && mapGeo) {

      if (geoLayer.current) {
        map.removeLayer(geoLayer.current);
      }

      if(!base64 || base64 === ''){
        const geoJson = {
          type: 'FeatureCollection',
          features: mapGeo.features,
        };

        let newCollection = L.geoJson(geoJson, {
          onEachFeature: (feature, layer) => {
            fieldLayer.addLayer(layer)
          },
        });
        map.fitBounds(newCollection.getBounds());

        if (type === 'zones') {
          addPolygonFeature(mapGeo);
        } else if (type === 'points') {
          addPointFeature(mapGeo);
        }
      }      
      else{

      }
    }
    else if(map){
      if (geoLayer.current) {
        map.removeLayer(geoLayer.current);
      }
    }
  }, [map, mapGeo, type]);

  useEffect(() => {
    if(exists([map, boundary, base64]) && extent.length < 2) {
      try {
        const features = typeof(boundary) === 'object' ? boundary : JSON.parse(boundary)
        const bounds = features.geometry.coordinates[0].map(x => [x[1], x[0]])
        const image = L.imageOverlay(base64, bounds, {opacity:0.8, interactive: true})
        imageOverlay.current = image
        image.addTo(map)
      } catch (err) {
        console.log(err)
      }

    }
    else if(map){
      if(imageOverlay.current){
        map.removeLayer(imageOverlay.current)
      }      
    }
  }, [base64, boundary, map])

  const addPolygonFeature = (mapGeo) => {
    try {
      geoLayer.current = L.geoJSON(mapGeo);
      let i = 0;
      geoLayer.current.eachLayer((layer) => {
        const color = zoneColors[i];
        i++;

        layer.setStyle({
          fillOpacity: 0.35,
          fillColor: color,
        });

        layer.on('click', (e) => { selectZone(layer.feature.properties.Obj__Id); });
      });
      geoLayer.current.addTo(map);
    } catch (err) {
      //console.log(err);
    }
  };

  const addPointFeature = (mapGeo) => {
    let i = -1;
    try {
      geoLayer.current = L.geoJson(mapGeo, {
        pointToLayer(feature, latlng) {
          i++;
          return L.circleMarker(latlng, {
            radius: 8,
            fillColor: zoneColors[i],
            color: zoneColors[i],
            fillOpacity: 1,
          });
        },
      });

      geoLayer.current.eachLayer((layer) => {
        layer.on('click', (e) => { selectZone(layer.feature.properties.Obj__Id); });
      });

      geoLayer.current.addTo(map);
    } catch (err) {
      console.log(err);
    }
  };

  const geojsonMarkerOptions = {
    radius: 8,
    fillColor: '#ff7800',
    color: '#000',
    weight: 1,
    opacity: 1,
    fillOpacity: 0.8,
  };

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('soil-results-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current'],
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }

  const getMinHeight = () => {
    if (window.innerHeight - 365 > 500) {
      return window.innerHeight - 365;
    }
    return 500;
  }

  return (
    <div
      id="soil-results-map"
      style={{
        height: '100%',
        minHeight: getMinHeight(),
        width: '100%',
        borderRadius: 2,
      }}
    />
  );
}

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { range } from "d3-array";
import CustomSelect from "../../../Helpers/CustomSelect";
import SeasonSelect from "../../../Helpers/SeasonSelect";
import ScatterPlotContainer from "../../../Helpers/ScatterPlotContainer";
import { CHART_TYPES, MONTHS, Nutrients } from "../../../../constants";
import { randomY, randomX } from "../../../../utils";
import MapContainer from "./MapContainer";
import PopularComparisonChip from "../../../Helpers/PopularComparisonChip";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    padding: "25px 0px 25px 0",
  },
  text: {
    fontSize: 14,
    fontWeight: "normal",
    lineHeight: "22px",
    marginTop: 40,
    marginBottom: 20,
  },
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
    marginBottom: 25,
  },
  innerActionsContainer: (isMapVisible) => ({
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",
    flexWrap: isMapVisible ? "wrap" : "nowrap",
  }),
}));

/**
 * Renders the analytics view
 * @param {object} activeVisualizationView with id, label, icon and active properties
 * @param {object} counties objects with county data
 * @param {object} activeFarm object with farm data
 * @param {array} geographies array of objects with county name and active propperites
 */

const Analytics = ({
  activeVisualizationView,
  counties,
  activeFarm,
  geographies,
  cropNames,
  setGeographies,
  activeYear,
  harvestDataForState,
  harvestDataForFarm,
  harvestVarietyForFarm,
  harvestVarietyForState,
  nutrientDataForState,
  nutrientDataForFarm,
  precipDataForFarm,
  precipForState,
  harvestDataForCounty,
  selectedState,
  precipitationDataForCounty,
  gddForFarm,
  gddForState,
  gddDataForCounty
}) => {
  const isMapVisible = activeVisualizationView.id === "map";
  const classes = useStyles(isMapVisible);
  const activeGeography = geographies.find((d) => d.active);

  const [cropOptions, setCropOptions] = useState(cropNames !== null ? cropNames : []);
  const selectedCrop = cropOptions.find((option) => option.active);

  const [xUnit, setXUnit] = useState("");
  const [yUnit, setYUnit] = useState("");
  const [farmData, setFarmData] = useState([]);

  const [farms] = useState(
    range(1).map(() => ({
      x: randomX(),
      y: randomY(),
      name: activeFarm.name,
      varieties: range(3).map((g) => ({
        name: `variety-${g}`,
        x: randomX(),
        y: randomY(),
        county: {
          name: activeGeography.name, //state name
          x: randomX(), //avg yield for selected state
          y: randomY(), //avg precipitation for selected state
        },
      })),
      county: {
        name: activeGeography.name,
        x: randomX(),
        y: randomY(),
      },
    }))
  );

  const [seasons, setSeasons] = useState(MONTHS);
  const activeSeasons = seasons.filter((season) => season.active);

  // These are examples. These variables should come from your API.
  const [firstVariableOptions, setFirstVariableOptions] = useState([
    { id: "Yield", label: "Yield (bu./acre)", active: true },
  ]);
  const activeFirstVariableOption = firstVariableOptions.find(
    (option) => option.active
  );

  const [secondVariableOptions, setSecondVariableOptions] = useState([
    { id: "Precipitation", label: "Precipitation (inches)", active: true },
    { id: "GDD", label: "GDD", active: false },
    { id: "Nutrients", label: "Nutrients (lbs/Acre)", active: false },
  ]);
  const activeSecondVariableOption = secondVariableOptions.find(
    (option) => option.active
  );

  const [nutrientOptions, setNutrientOptions] = useState([
    { id: "Nitrogen", label: "Nitrogen", active: true },
    { id: "Sulfur", label: "Sulfur", active: false },
    { id: "Calcium", label: "Calcium", active: false },
    { id: "Phosphorus", label: "Phosphorus", active: false },
    { id: "Copper", label: "Copper", active: false },
    { id: "Cobalt", label: "Cobalt", active: false },
    { id: "Potassium", label: "Potassium", active: false },
    { id: "Molybdenum", label: "Molybdenum", active: false },
    { id: "Manganese", label: "Manganese", active: false },
    { id: "Boron", label: "Boron", active: false },
    { id: "Zinc", label: "Zinc", active: false },
    { id: "Magnesium", label: "Magnesium", active: false },
    { id: "Iron", label: "Iron", active: false },
    { id: "Chlorine", label: "Chlorine", active: false },
  ]);

  const nutrientVariableOption = nutrientOptions.find(
    (option) => option.active
  );

  useEffect(() => {
    if (selectedCrop != undefined) {
      let xFarm = 0,
        yFarm = 0;
      let xState = 0,
        yState = 0;

      if (activeFirstVariableOption.id == "Yield") {
        setXUnit("bu/Acre");

        let cropYieldFarm = harvestDataForFarm.filter(
          (c) => c.CropName == selectedCrop.id
        )[0];
        let stateYield = harvestDataForState.filter(
          (h) => h.CropName == selectedCrop.id
        )[0];
        xFarm =
          cropYieldFarm == undefined ? 0 : cropYieldFarm?.AverageHarvestYield;
        xState = stateYield == undefined ? 0 : stateYield?.AverageHarvestYield;
      }

      if (activeSecondVariableOption.id == "Precipitation") {
        setYUnit("inches");

        for (let i = 0; i < seasons.length; i++) {
          if (seasons[i].active) {
            if (precipDataForFarm[i] !== undefined) {
              yFarm += precipDataForFarm[i].Precipitation; //average precipitation
            }
            if (precipForState[i] !== undefined) {
              yState += precipForState[i].Precipitation; //average precipitation
              
            }
          }
        }
        //console.log("yState", yState)
        //console.log("yFarm", yFarm)
        yFarm *= 0.03937;
        yState *= 0.03937;

        //console.log("after multiply analytics", yState, yFarm)
      } 
      else if (activeSecondVariableOption.id == "GDD"){
        setYUnit("");
        for (let i = 0; i < seasons.length; i++) {
          if (seasons[i].active) {
            if (gddForFarm[i] !== undefined) {
              yFarm += gddForFarm[i].GDD; //average precipitation
            }
            if (gddForState[i] !== undefined) {
              yState += gddForState[i].GDD; //average precipitation
              
            }
          }
        }
      }
      else if (activeSecondVariableOption.id == "Nutrients") {
        setYUnit("lbs/Acre");

        let farmNutrient = nutrientDataForFarm.filter(
          (n) => n.NutrientAlias == nutrientVariableOption.id
        )[0];
        let stateNutrient = nutrientDataForState.filter(
          (n) => n.NutrientAlias == nutrientVariableOption.id
        )[0];

        yFarm = farmNutrient == undefined ? 0 : farmNutrient?.NutrientRate;
        yState = stateNutrient == undefined ? 0 : stateNutrient?.NutrientRate;
      }

      let farmVarietiesData = [];
      let farmVarieties = harvestVarietyForFarm.filter(
        (c) => c.Crop == selectedCrop.id
      );
      let stateVarieties = harvestVarietyForState.filter(
        (c) => c.Crop == selectedCrop.id
      );

      farmVarieties = farmVarieties.map((x) => x.Variety);
      stateVarieties = stateVarieties.map((x) => x.Variety);

      let varieties = [...new Set([...farmVarieties, ...stateVarieties])];
      console.log("varieties", varieties);

      for (let v of varieties) {
        let farmVar = harvestVarietyForFarm.filter((c) => c.Variety == v)[0];
        let stateVar = harvestVarietyForState.filter((c) => c.Variety == v)[0];

        farmVar = farmVar == undefined ? 0 : farmVar?.VarietyYield;
        stateVar = stateVar == undefined ? 0 : stateVar?.VarietyYield;

        farmVarietiesData.push({
          name: v, //variety name
          x: farmVar, //avg yield for variety for selected farm
          y: yFarm, //avg precipitation for farm
          county: {
            name: activeGeography.name, //state name
            x: stateVar, //avg yield for variety for selected state
            y: yState, //avg precipitation for selected state
          },
        });
      }

      let data = [
        {
          x: xFarm, //avg yield for farm
          y: yFarm, //avg precipitation or nutrients for farm
          name: activeFarm.name,
          varieties: farmVarietiesData,
          county: {
            name: activeGeography.name, //state name
            x: xState, //avg yield for selected state
            y: yState, //avg precipitation or nutrients for selected state
          },
        },
      ];
      console.log("data", data);
      setFarmData(data);
    }
  }, [
    selectedCrop,
    activeFirstVariableOption,
    activeSecondVariableOption,
    seasons,
    harvestDataForState,
    harvestDataForFarm,
    nutrientDataForState,
    nutrientDataForFarm,
    precipDataForFarm,
    precipForState,
    nutrientVariableOption,
    gddForFarm,
    gddForState,
  ]);

  //console.log('farms', farms)
  // const [cropOptions, setCropOptions] = useState([
  //   { id: "Corn Wet", active: true },
  //   { id: "Soybeans", active: false },
  // ]);

  const activeCropOption = cropOptions.find((option) => option.active);
  // You will need to filter the farms by activeCrop and then pass in filteredFarms
  // to ScatterPlot Container. (The farm data above is mocked up so this filtering function
  // will depend on what your data looks like.)
  // See Farm Report Analytics for an example.

  // You will need to do something similar to filter your incoming data by activeSeasons.

  return (
    <div className={classes.wrapper}>
      <div className={classes.text}>
        Compare yields and other metrics from your Farm to the Market as a
        whole. First, select which Crop to analyze, and across which months.
        Then, select which variables you’d like to compare. Here are some of the
        most popular comparisons:
        <span>
          <PopularComparisonChip label="YIELD vs. PRECIPITATION" />
        </span>
        <span>
          <PopularComparisonChip label="YIELD vs. NUTRIENTS" />
        </span>
      </div>
      <div className={classes.actionsContainer}>
        <div className={classes.innerActionsContainer}>
          <CustomSelect
            options={cropOptions}
            setOptions={setCropOptions}
            label="Crop"
          />
          {isMapVisible && (
            <CustomSelect
              options={[{ id: "All", active: true }]}
              setOptions={() => null}
              label="Variety"
            />
          )}
          <SeasonSelect seasons={seasons} setSeasons={setSeasons} />
        </div>
        {!isMapVisible && (
          <div className={classes.innerActionsContainer}>
            <CustomSelect
              options={firstVariableOptions}
              setOptions={setFirstVariableOptions}
              label="First Variable"
            />
            <CustomSelect
              options={secondVariableOptions}
              setOptions={setSecondVariableOptions}
              label="Second Variable"
            />
            {activeSecondVariableOption.id == "Nutrients" && (
              <CustomSelect
                options={nutrientOptions}
                setOptions={setNutrientOptions}
                label="Nutrients"
              />
            )}
          </div>
        )}
      </div>
      {isMapVisible ? (
        <MapContainer
          counties={counties}
          activeFarm={activeFarm}
          crop={activeCropOption}
          harvestDataForCounty={harvestDataForCounty}
          harvestDataForFarm={harvestDataForFarm}
          selectedState={selectedState}
          activeYear={activeYear}
          selectedCrop={selectedCrop}
          precipitationDataForCounty={precipitationDataForCounty}
          precipDataForFarm={precipDataForFarm}
          gddDataForCounty={gddDataForCounty}
        />
      ) : (
        <ScatterPlotContainer
          firstVariableOption={activeFirstVariableOption}
          secondVariableOption={activeSecondVariableOption}
          type={CHART_TYPES.ScatterCompare}
          fields={farmData}
          selectedCrop={selectedCrop}
          activeYear={activeYear}
          xUnit={xUnit}
          yUnit={yUnit}
          // fields={farms}
          // crop={activeCropOption}
        />
      )}
    </div>
  );
};

export default Analytics;

Analytics.propTypes = {
  activeVisualizationView: PropTypes.object.isRequired,
  counties: PropTypes.object.isRequired,
  activeFarm: PropTypes.object.isRequired,
  geographies: PropTypes.array.isRequired,
};

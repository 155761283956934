/* eslint-disable react/prop-types */
/* eslint-disable require-jsdoc */
/* eslint-disable max-len */
/* eslint-disable no-plusplus */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Button, Menu, MenuItem, Select, CircularProgress, Divider,
} from '@material-ui/core';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { convert } from 'terraformer-wkt-parser';
import { useSnackbar } from 'notistack';
import { DisplayMap } from '../../Maps/DisplayMap';
import { useWindowDimensions } from '../../../utils/dimensions';
import { getProfitMap } from '../../../utils/dataFetchers';
import { EditRanges } from './Ranges/EditRanges';
import { EditRangesModal } from './Ranges/EditRangesModal';
import { RangeSlider } from './Ranges/RangeSlider';
import { MenuProps } from '../../../styles/select';
import { MobileProfitSummary } from './Summaries/MobileProfitSummary';
import { MobileElevationSummary } from './Summaries/MobileElevationSummary';
import { MobileOperationSummary } from './Summaries/MobileOperationSummary';
import { MobileVarietySummary } from './Summaries/MobileVarietySummary';
import { MultiMaps } from './MultiMapView';
import { FieldProvider } from '../../Context/FieldContext';
import { ZoneAnalysisMap } from '../../Maps/ZoneAnalysisMap';
import * as df from '../../../utils/dataFetchers';
import {
  lightGrey, grey, darkGrey, green, blackText,
} from '../../../styles/colors';
import { CustomToolTip } from '../../../utils/customComponents';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { updateProfitMapValues } from '../utils/calculations';
import { WaterManagementPopUp } from '../../Shared/Popups/WaterManagement';
import { SoilTestingPopUp } from '../../Shared/Popups/SoilTesting';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    ...theme.hover,
    fontSize: '14px',
    marginLeft: '5px',
  },
  selections: {
    padding: 10,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    fontWeight: 500,
    fontSize: 16,
    width: '200px',
  },
  infoToolTip: theme.infoToolTip,
  zoneTool: {
    height: 30,
    padding: 8,
    display: 'flex',
    borderColor: grey,
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
      borderColor: '#6c757d',
    },
  },
}));

/**
 * Display Profit, Elevation, Variety, and Operation Maps
 * @param {Bool} perAcre If display is per acre or field values
 * @param {Number} seededAcres Net seeded acres for field.
 * @param {String} mapType Type of map.
 * @param {Object} field Field data.
 * @param {Number} netSeededAcres Acres of field that have been seeded.
 * @param {Function} pushProfitMap Pushes profit map to precision ag site.
 * @param {String} source Source for data. Ex. Deere, Climate
 * @param {Bool} profitMapNeedsUpdating Determine if need to udate profit map on load
 * @param {Function} setProfitMapNeedsUpdating Set to false after update
 * @param {Function} setLoading Shows spinning loader when profit map is loading
 */
export function ReportMap({
  field,
  perAcre,
  seededAcres,
  totalProfit,
  operations,
  summary,
  year,
  profitMapResponse,
  profitMap,
  operationMap,
  getProfitMap,
  logBinRange,
  getBinRange,
  pushProfitMap,
  getElevationMap,
  elevationMap,
  displayOperations,
  mapType,
  savedTemplate,
  getAllTemplates,
  setPdfMapImages,
  setPdfMapExtent,
  zoneOptions,
  zoneAnalysis,
  zoneAnalysisRes,
  setZoneAnalysisRes,
  source,
  elevationIndex,
  getElevationIndex,
  highRes,
  NPK,
  getNutrientParser,
  syncData,
  errorMessage,
  isZoneAnalysisRes,
  setZoneAnalysisLoader,
  mobileBreakPoint,
  profitMapNeedsUpdating,
  setProfitMapNeedsUpdating,
  selectedTemplate,
  setSelectedTemplate,
  setMapLoadedOnViewProfitLayers,
  templateRes,
  setTemplateRes,
  legendBinColors,
  setLegendBinColors,
  legendBinRanges,
  setLegendBinRanges,
  clipByBoundary,
}) {

  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();
  const showButtonsOnMap = mobileBreakPoint;
  const [images, setImages] = useState([]);
  const [operationImage, setOperationImage] = useState('');
  const [operationLegend, setOperationLegend] = useState([]);
  const [operationMapExtent, setOperationMapExtent] = useState([]);
  const [selectedOp, setSelectedOp] = useState('');
  const [selectOperationOpen, setSelectOperationOpen] = useState(false);
  const [operationMapRes, setOperationMapRes] = useState([]);

  const [elevationImage, setElevationImage] = useState('');
  const [elevationLegend, setElevationLegend] = useState([]);
  const [elevationMapExtent, setElevationMapExtent] = useState([]);
  const [elevationRelative, setElevationRelative] = useState(false);
  const [varietyImage, setVarietyImage] = useState('');
  const [varietyLegend, setVarietyLegend] = useState([]);
  const [varietyList, setVarietyList] = useState([]);
  const [varietyMapExtent, setVarietyMapExtent] = useState([]);

  const [unit, setUnit] = useState('');

  const [selectedVar, setSelectedVar] = useState('');
  const [selectVarietyOpen, setSelectVarietyOpen] = useState(false);

  const [rangeDisplay, setRangeDisplay] = useState(false);
  const [legend, setLegend] = useState([]);
  const [mapExtent, setMapExtent] = useState([]);
  const [fieldOperations, setFieldOperations] = useState([]);
  const [binRanges, setBinRanges] = useState({
    colors: '',
    binRange: '',
    ranges: null,
  });

  const [netSeededAcres, setNetSeededAcres] = useState(0);

  // flag for generating profit maps
  const [mapLoading, setMapLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(false);
  const [initialLoadStarted, setInitialLoadStarted] = useState(false);

  // Zone Analysis
  const [selectedZoneOp, setSelectedZoneOp] = useState('');
  const [zoneMapImage, setZoneMapImage] = useState('');
  const [zoneMapLegend, setZoneMapLegend] = useState([]);
  const [zoneMapExtent, setZoneMapExtent] = useState([]);
  const [selectedZone, setSelectedZone] = useState('');
  const [zonesList, setZonesList] = useState([]);
  const [zoneMapResult, setZoneMapResult] = useState([]);
  const [savedZones, setSavedZones] = useState([]);
  const [zoneMeanProfit, setZoneMeanProfit] = useState(0);
  const [zonesFailed, setZoneFailed] = useState(false);
  const [drawingZones, setDrawingZones] = useState(false);

  // SSURGO
  const [ssurgoLayers, setssurgoLayers] = useState([]);

  // Elevation index
  const [selectedElevationIndex, setElevationIndex] = useState('');
  const [selectElevationOpen, setSelectElevationOpen] = useState(false);

  // rate for calculating n in lbs
  const [appliedRate, setAppliedRate] = useState(0);
  const [nmap, setnmap] = useState(false);

  // setting for cost map vs quantity map
  const [isQuantityMap, setIsQuantityMap] = useState(false);

  // Toggle for slider vs table
  // const [varietySummary, setVarietySummary] = useState(false)

  useEffect(() => {
    if (!initialLoadStarted && field !== undefined && operations !== undefined) {
      getProfitMapOnInitialLoad();
    }
  }, [field, operations]);

  useEffect(() => {
    const request = {
      FieldOperationList: [selectedOp],
      Constant: 0, // this is a left over harcoded value but is determined by: -(Math.round(100 * (FieldPassCost + OtherCostVal - OtherRevenueVal)) / 100)
      Year: year[0],
      IsResizeMapRequest: false,
      ProfitMapLegendRange: null,
      LegendColors: null,
      ProfitByZones: false,
      Zones: null,
      boundarygeojson: clipByBoundary ? field.boundary : null,
      IsHighResolutionMapRequest: highRes,
      MultiYears: year,
      IsOperationMapRequest: true,
    };

    if (nmap && NPK.N !== undefined) {
      request.FieldOperationList[0].attribute = 'AppliedRate';
      if (isQuantityMap) {
        request.FieldOperationList[0].price = Math.abs(request.FieldOperationList[0].operationCost / request.FieldOperationList[0].averageMaterialResult) * NPK.N;
      } else {
        request.FieldOperationList[0].price = NPK.N;
      }
      getProfitMap(field.orgId, field.id, request, 1, 1);
    } else if (request.FieldOperationList[0] !== '') {
      request.FieldOperationList[0].price = 1;
      getProfitMap(field.orgId, field.id, request, 1, 1);
    }
  }, [nmap]);

  useEffect(() => {
    if (selectedOp !== '') {
      getMap(field.orgId, field.id, [selectedOp], true);
    }
  }, [isQuantityMap]);

  useEffect(() => {
    // When map type changes, check if failed to load and alert user
    failureAlerts(mapType);
  }, [mapType, profitMapNeedsUpdating, operations, field]);

  useEffect(() => {
    summary.length > 0 && setNetSeededAcres(summary[0].netSeededAcre);
    if (summary.length > 0) {
      setNetSeededAcres(summary[0].netSeededAcre);
    } else {
      setNetSeededAcres(seededAcres);
    }
  }, [summary, seededAcres]);

  useEffect(() => {
    // get operation layer map
    if (
      displayOperations.length > 0
            && fieldOperations.length !== displayOperations.length
    ) {
      // console.log(operations);
      setFieldOperations(displayOperations);
      getMap(field.orgId, field.id, [displayOperations[0]], true);

      // set first operation as selectedOperation for Operation map
      const op = displayOperations[0];
      if (op.fileType.toUpperCase() === 'APPLICATION') {
        setAppliedRate(op.averageMaterialResult);
        setUnit(displayOperations[0].totalMaterialUnit);
      } else {
        setUnit(displayOperations[0].unit);
      }

      setSelectedOp(op);

      // if (zoneOptions.length > 0) {
      //   //console.log("initial load")
      //   setSelectedZoneOp(zoneOptions[0]);
      //   if (zoneOptions[0].value !== 'drawZones' && zoneOptions[0].value !== 'ssurgoZones') {
      //     //zoneAnalysis(zoneOptions[0].value, displayOperations);
      //     getZoneAnalysis()
      //   } else if (zoneOptions[0].value === 'drawZones') {
      //     getSavedZones();
      //   } else if (zoneOptions[0].value === 'ssurgoZones') {
      //     getSSURGOZones();
      //   }
      // }
      // Elevation Index Dropdown
      setElevationIndex(elevationIndex[0]);
      getElevationData(elevationIndex[0]);
      // elevationmap();
    }
  }, [field, displayOperations, zoneOptions]);

  const getProfitMapOnInitialLoad = async () => {
    setInitialLoading(true);
    setInitialLoadStarted(true);
    // we need seperate loading tracker because operation layers call same
    // function that sets loading, but will resolve faster, setting to false
    // before profit map is loaded

    // If we have a profitMapResponse, use that legend length and colors
    let legendColors = null;
    if (profitMapResponse?.length > 0) {
      const colors = [];
      // copy and reverse legend to get colors
      const legend = [...profitMapResponse[0].legend].reverse();

      for (const entry of legend) {
        colors.push(entry.color);
      }

      legendColors = {
        colors: JSON.stringify(colors),
        range: colors.length,
      };
      setLegendBinColors(legendColors);
      // console.log(selectedTemplate, templateRes)
    }

    let legendRange = null;
    if (selectedTemplate !== '') {
      const temp = templateRes.filter((t) => t.id === selectedTemplate.id);
      if (temp.length > 0) {
        legendRange = temp[0].rangesList;
        setLegendBinRanges(legendRange);
      }
    } else if (legendBinRanges !== null) {
      legendRange = legendBinRanges;
    }

    await generateMap(field.orgId, field.id, operations, legendColors, legendRange);
    // await generateMap(field.orgId, field.id, operations)

    setInitialLoading(false);
    setMapLoadedOnViewProfitLayers(true);
  };

  const getElevationData = async (index) => {
    if (index.value === 'elevationMap') {
      await elevationmap();
    } else {
      await getElevationIndex(field.orgId, field.id, index.value, displayOperations, field.boundary);
    }
  };

  const getZoneAnalysis = async () => {
    const result = await zoneAnalysis(zoneOptions[0].value, displayOperations);
    // If not valid result, set fail state so when user switches to tab will be alerted
    if (!result) {
      setZoneFailed(true);
    } else {
      setZoneFailed(false);
    }
  };

  useEffect(() => {
    if (operationMap.profitMap && operationMap.profitMap.length > 0 && operationMap.profitMap[0].pngb64) {
      const image = `data:image/jpeg;base64,${operationMap.profitMap[0].pngb64}`;
      if (operationMap.profitMap[0].extent !== undefined) {
        const bounds = operationMap.profitMap[0].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];

        setOperationMapExtent(imageBounds);
      }
      setOperationImage(image);
      setOperationLegend(operationMap.profitMap[0].legend);
      setOperationMapRes(operationMap.profitMap);
    } else {
      setOperationMapRes([]);
      setOperationImage('');
      setOperationLegend([]);
    }
  }, [operationMap]);

  useEffect(() => {
    let mapImages = null;
    if (profitMapResponse.profitMap && profitMapResponse.profitMap.code === undefined) {
      mapImages = profitMapResponse.profitMap.map(
        (x) => `data:image/jpeg;base64,${x.pngb64}`,
      );
      setImages(mapImages);
      setPdfMapImages(mapImages);
      if (profitMapResponse.profitMap.length > 0) {
        setLegend(profitMapResponse.profitMap[0].legend);

        if (profitMapResponse.profitMap[0].extent !== undefined) {
          const bounds = profitMapResponse.profitMap[0].extent.split(',');
          const imageBounds = [
            [parseFloat(bounds[3]), parseFloat(bounds[0])],
            [parseFloat(bounds[1]), parseFloat(bounds[2])],
          ];
          setMapExtent(imageBounds);
          setPdfMapExtent(imageBounds);
        }
      }
    } else if (profitMapResponse.length > 0) {
      mapImages = profitMapResponse.map(
        (x) => `data:image/jpeg;base64,${x.pngb64}`,
      );

      setImages(mapImages);
      setPdfMapImages(mapImages);
      setLegend(profitMapResponse[0].legend);

      if (profitMapResponse[0].extent !== undefined) {
        const bounds = profitMapResponse[0].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];
        setMapExtent(imageBounds);
        setPdfMapExtent(imageBounds);
      }
    }
  }, [profitMapResponse]);

  useEffect(() => {
    if (Object.keys(profitMap).length > 0) {
      if (profitMap.profitMap && profitMap.profitMap.code === undefined) {
        const mapImages = profitMap.profitMap.map(
          (x) => `data:image/jpeg;base64,${x.pngb64}`,
        );
        setImages(mapImages);
        setLegend(profitMap.profitMap[0].legend);

        if (profitMap.profitMap[0].extent !== undefined) {
          const bounds = profitMap.profitMap[0].extent.split(',');
          const imageBounds = [
            [parseFloat(bounds[3]), parseFloat(bounds[0])],
            [parseFloat(bounds[1]), parseFloat(bounds[2])],
          ];
          setMapExtent(imageBounds);
        }
      }
    }
  }, [profitMap]);

  useEffect(() => {
    if (elevationMap !== undefined) {
      const image = `data:image/jpeg;base64,${elevationMap.pngb64}`;

      if (elevationMap.extent !== undefined) {
        const bounds = elevationMap.extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];
        setElevationMapExtent(imageBounds);
      }

      setElevationImage(image);
      setElevationLegend(elevationMap.legend);
    }
  }, [elevationMap]);

  useEffect(() => {
    let mapImages = null;
    if (profitMapResponse.profitMap && profitMapResponse.profitMap.length > 0) {
      mapImages = profitMapResponse.profitMap.map(
        (x) => `data:image/jpeg;base64,${x.pngb64}`,
      );
      setImages(mapImages);
      if (profitMapResponse.profitMap.length > 0) {
        setLegend(profitMapResponse.profitMap[0].legend);

        if (profitMapResponse.profitMap[0].extent !== undefined) {
          const bounds = profitMapResponse.profitMap[0].extent.split(',');
          const imageBounds = [
            [parseFloat(bounds[3]), parseFloat(bounds[0])],
            [parseFloat(bounds[1]), parseFloat(bounds[2])],
          ];
          setMapExtent(imageBounds);
        }
      }
    } else {
      if (profitMapResponse.length > 0) {
        mapImages = profitMapResponse.map(
          (x) => `data:image/jpeg;base64,${x.pngb64}`,
        );
        // console.log(mapImages);
        setImages(mapImages);
        setLegend(profitMapResponse[0].legend);

        if (profitMapResponse[0].extent !== undefined) {
          const bounds = profitMapResponse[0].extent.split(',');
          const imageBounds = [
            [parseFloat(bounds[3]), parseFloat(bounds[0])],
            [parseFloat(bounds[1]), parseFloat(bounds[2])],
          ];
          setMapExtent(imageBounds);
        }
      }

      if (profitMapResponse.length > 1) {
        const varietyNames = [{ name: 'Variety Map' }];
        for (let i = 0; i < profitMapResponse.length; i++) {
          if (
            profitMapResponse[i].variety.toUpperCase() !== 'NOVARIETY'
            && profitMapResponse[i].variety.toUpperCase() !== 'VARIETY'
          ) {
            const obj = {
              name: profitMapResponse[i].variety,
            };
            varietyNames.push(obj);
          }
        }

        setVarietyList(varietyNames);
        setSelectedVar(varietyNames[0]);
        const image = `data:image/jpeg;base64,${profitMapResponse[1].pngb64}`;
        setVarietyImage(image);
        setVarietyLegend(profitMapResponse[1].legend);

        if (profitMapResponse[1].extent !== undefined) {
          const bounds = profitMapResponse[1].extent.split(',');
          const imageBounds = [
            [parseFloat(bounds[3]), parseFloat(bounds[0])],
            [parseFloat(bounds[1]), parseFloat(bounds[2])],
          ];
          setVarietyMapExtent(imageBounds);
        }
      }
    }
  }, [profitMapResponse]);

  useEffect(() => {
    if (Object.keys(profitMap).length > 0 && profitMap.profitMap.code === undefined) {
      const mapImages = profitMap.profitMap.map(
        (x) => `data:image/jpeg;base64,${x.pngb64}`,
      );
      setImages(mapImages);
      setLegend(profitMap.profitMap[0].legend);
      if (profitMap.profitMap[0].extent !== undefined) {
        const bounds = profitMap.profitMap[0].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];
        setMapExtent(imageBounds);
      }
      if (profitMap.profitMap.length > 1) {
        const varietyNames = [{ name: 'Variety Map' }];
        for (let i = 0; i < profitMap.profitMap.length; i++) {
          if (
            profitMap.profitMap[i].variety.toUpperCase() !== 'NOVARIETY'
                        && profitMap.profitMap[i].variety.toUpperCase() !== 'VARIETY'
          ) {
            const obj = {
              name: profitMap.profitMap[i].variety,
            };
            varietyNames.push(obj);
          }
        }

        setVarietyList(varietyNames);
        setSelectedVar(varietyNames[0]);
        const image = `data:image/jpeg;base64,${profitMap.profitMap[1].pngb64}`;
        setVarietyImage(image);
        setVarietyLegend(profitMap.profitMap[1].legend);
        if (profitMap.profitMap[1].extent !== undefined) {
          const bounds = profitMap.profitMap[1].extent.split(',');
          const imageBounds = [
            [parseFloat(bounds[3]), parseFloat(bounds[0])],
            [parseFloat(bounds[1]), parseFloat(bounds[2])],
          ];
          setVarietyMapExtent(imageBounds);
        }
      }
    }
  }, [profitMap]);

  useEffect(() => {
    if (zoneAnalysisRes !== undefined && zoneAnalysisRes.length > 1) {
      const image = `data:image/jpeg;base64,${zoneAnalysisRes[1].pngb64}`;
      if (zoneAnalysisRes[1].extent !== undefined) {
        const bounds = zoneAnalysisRes[0].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];
        // console.log("Setting map extent");
        setZoneMapExtent(imageBounds);
      }
      setZoneMapImage(image);
      setZoneMapLegend(zoneAnalysisRes[1].legend);
      setZoneMeanProfit(zoneAnalysisRes[1].mean);
      const list = [];
      if (selectedZoneOp.value === 'ssurgoZones' || ssurgoLayers.length > 0) {
        for (let i = 2; i < zoneAnalysisRes.length; i++) {
          zoneAnalysisRes[i].variety = ssurgoLayers[i - 2].properties.Soil_Type;
        }
        zoneAnalysisRes[1].legend = zoneAnalysisRes[1].legend.reverse();
        for (let i = 0; i < zoneAnalysisRes[1].legend.length; i++) {
          zoneAnalysisRes[1].legend[i].variety = ssurgoLayers[i].properties.Soil_Type;
        }
        // console.log(zoneAnalysisRes)
      }

      for (let i = 0; i < zoneAnalysisRes.length; i++) {
        // console.log("selectedZoneOp", selectedZoneOp.value)
        if (selectedZoneOp.value !== 'drawZones' && selectedZoneOp.value !== 'ssurgoZones' && selectedZoneOp !== '') {
          if (zoneAnalysisRes[i].variety.toUpperCase() !== 'NOVARIETY') {
            var obj = {
              name: Number(zoneAnalysisRes[i].variety) === 2 ? 'Application Applied' : Number(zoneAnalysisRes[i].variety) === 1 ? 'Application Not Applied' : 'All Zones',
              value: zoneAnalysisRes[i].variety,
            };

            list.push(obj);
          }
        } else if (zoneAnalysisRes[i].variety.toUpperCase() !== 'NOVARIETY') {
          var obj = {
            name: zoneAnalysisRes[i].variety.toUpperCase() === 'VARIETY' ? 'All Zones' : zoneAnalysisRes[i].variety.replace('Variety', 'Zone').replace('.0', ''),
            value: zoneAnalysisRes[i].variety,
          };

          list.push(obj);
        }
      }
      setZonesList(list);
      setSelectedZone(list[0]);
      setZoneMapResult([zoneAnalysisRes[1]]);
    } else {
      setZoneMapResult([]);
      setZonesList([]);
      setSelectedZone('');
      setZoneMapLegend([]);
      setZoneMeanProfit(0);
    }
  }, [zoneAnalysisRes]);

  async function applyLegendUpdates(legendInfo, updateLegend, updateMap = true, isReset = false) {
    setBinRanges(legendInfo);

    if (legendInfo !== null) {
      setLegendBinColors({
        colors: legendInfo.colors,
        range: legendInfo.range,
      });
      setLegendBinRanges(legendInfo.binRange);
    } else {
      setLegendBinColors(null);
      setLegendBinRanges(null);
    }
    if (legendInfo !== null && JSON.stringify(legend) !== JSON.stringify(updateLegend)) {
      setLegend(updateLegend);
      logBinRange(field.orgId, legendInfo);
    }
    if (updateMap) {
      getMap(field.orgId, field.id, operations, false, legendInfo, isReset);
    }
  }

  const failureAlerts = (mapTab) => {
    if (mapTab === 'Zone Analysis' && zonesFailed) {
      enqueueSnackbar('Zone analysis could not be performed');
    } else if (mapTab === 'Operation Layers' && operationMap.length === 0 && !mapLoading) {
      enqueueSnackbar('Failed to generate operation map');
    }
  };

  async function getMap(
    orgId,
    fieldId,
    operations,
    isOperation,
    legendInfo = null,
    isReset = false,
  ) {
    // from what i can tell fieldop prices are always 1 no matter what the op is, so hard code it here
    const opsCopy = JSON.parse(JSON.stringify(operations));
    if (isOperation) {
      if (!isQuantityMap) {
        if (opsCopy[0].fileType.toUpperCase() === 'APPLICATION' && nmap && NPK.N !== undefined) {
          opsCopy[0].attribute = 'AppliedRate';
          opsCopy[0].price = NPK.N;
        } else {
          opsCopy[0].price = 1;
        }
      } else if (opsCopy[0].unit.toUpperCase() === 'K SEEDS') {
        if (opsCopy[0].variety === null) {
          opsCopy[0].price = operations[0].price / 1000;
        } else {
          opsCopy[0].price = Math.abs(operations[0].operationCost / operations[0].rate) / 1000;
        }
        opsCopy[0].unit = opsCopy[0]?.originalUnit !== undefined ? opsCopy[0].originalUnit : 'seeds';
        opsCopy[0].totalMaterialUnit = opsCopy[0]?.originalUnit !== undefined ? opsCopy[0].originalUnit : 'seeds';
        opsCopy[0].averageMaterialTarget = operations[0].averageMaterialTarget * 1000;
        opsCopy[0].quantity = operations[0].quantity * 1000;
        opsCopy[0].rate = operations[0].rate * 1000;
      } else if (opsCopy[0].fileType.toUpperCase() === 'APPLICATION' && nmap && NPK.N !== undefined) {
        opsCopy[0].attribute = 'AppliedRate';
        opsCopy[0].price = Math.abs(operations[0].operationCost / operations[0].averageMaterialResult) * NPK.N;
      } else if (opsCopy[0].variety === null) {
        opsCopy[0].price = operations[0].price;
      } else {
        const rate = operations[0].fileType.toUpperCase() === 'APPLICATION' ? operations[0].averageMaterialResult : operations[0].rate;
        opsCopy[0].price = Math.abs(operations[0].operationCost / rate);
      }
    }

    const request = {
      FieldOperationList: opsCopy,
      Constant: 0, // this is a left hardcoded for operation maps
      Year: year[0],
      IsResizeMapRequest: false,
      ProfitMapLegendRange: null,
      LegendColors: null,
      ProfitByZones: false,
      Zones: null,
      boundarygeojson: clipByBoundary ? field.boundary : null,
      IsHighResolutionMapRequest: highRes,
      MultiYears: year,
    };

    setMapLoading(true);
    // setLoading(true);

    // if range # changed then ProfitMapLegendRange should be null
    if (!isOperation) {
      // profitmap request
      if (binRanges !== null && binRanges.colors !== '' && legendInfo == null) {
        if (!isReset) {
          request.LegendColors = {
            colors: binRanges.colors,
            range: binRanges.range,
          };
          request.ProfitMapLegendRange = binRanges.binRange;
        }
        await getProfitMap(orgId, fieldId, request);
      } else {
        request.LegendColors = {
          colors: legendInfo.colors,
          range: legendInfo.range,
        };
        if (legendInfo.countChange) {
          await getProfitMap(orgId, fieldId, request, 0);
        } else {
          request.ProfitMapLegendRange = legendInfo.binRange;
          await getProfitMap(orgId, fieldId, request);
        }
      }
    } else {
      // operation Layer request
      request.IsOperationMapRequest = true;
      await getNutrientParser(opsCopy[0]);
      await getProfitMap(orgId, fieldId, request, 1, 1);
    }

    setMapLoading(false);
    // setLoading(false)
  }

  async function generateMap(orgId, fieldId, operations, legendColors = null, legendRange = null) {
    // console.log('generating profit map')
    operations.map((x) => {
      if (x.operationID === undefined) {
        x.operationID = x.genericOperationID;
      }
    });
    const request = {
      FieldOperationList: operations,
      Constant: 0, // this is a left over harcoded value but is determined by: -(Math.round(100 * (FieldPassCost + OtherCostVal - OtherRevenueVal)) / 100)
      Year: year[0],
      IsResizeMapRequest: false,
      ProfitMapLegendRange: legendRange,
      LegendColors: legendColors,
      ProfitByZones: false,
      Zones: null,
      boundarygeojson: clipByBoundary ? field.boundary : null,
      IsHighResolutionMapRequest: highRes,
      MultiYears: year,
    };
    await getProfitMap(orgId, fieldId, request);
  }

  const handleOperationSelect = (index) => {
    // let ndx = e.target.value;
    // let op = displayOperations[ndx];
    const op = displayOperations[index];

    setSelectedOp(op);

    // Check if selected operation is Application type and set appliedRate value that will be used to calculate N rate in lbs/ac
    if (op.fileType.toUpperCase() === 'APPLICATION') {
      setAppliedRate(op.averageMaterialResult);
      // set unit to be displayed in ranges tab for a selected operation
      setUnit(op.totalMaterialUnit);
    } else {
      setAppliedRate(0);
      // set unit to be displayed in ranges tab for a selected operation
      setUnit(op.unit);
    }
    // send request for new operation map
    getMap(field.orgId, field.id, [op], true);
  };

  const handleVarietySelect = (index) => {
    if (Object.keys(profitMap).length > 0 && profitMap.profitMap.length > 1) {
      // let ndx = e.target.value;
      const name = varietyList[index];
      setSelectedVar(name);
      const image = `data:image/jpeg;base64,${
        profitMap.profitMap[index + 1].pngb64
      }`;
      setVarietyImage(image);
      setVarietyLegend(profitMap.profitMap[index + 1].legend);

      if (profitMap.profitMap[index + 1].extent !== undefined) {
        const bounds = profitMap.profitMap[index + 1].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];
        setVarietyMapExtent(imageBounds);
      }
    } else if (profitMapResponse.length > 1) {
      // let ndx = e.target.value;
      const name = varietyList[index];
      // console.log("name", name)
      setSelectedVar(name);

      const image = `data:image/jpeg;base64,${profitMapResponse[index + 1].pngb64}`;
      setVarietyImage(image);
      setVarietyLegend(profitMapResponse[index + 1].legend);

      if (profitMapResponse[index + 1].extent !== undefined) {
        const bounds = profitMapResponse[index + 1].extent.split(',');
        const imageBounds = [
          [parseFloat(bounds[3]), parseFloat(bounds[0])],
          [parseFloat(bounds[1]), parseFloat(bounds[2])],
        ];
        setVarietyMapExtent(imageBounds);
      }
    }
  };

  const closeModal = () => {
    setRangeDisplay(false);
  };

  async function binRange(ranges) {
    getBinRange(field.orgId, ranges);
  }

  async function getTemplates(range) {
    getAllTemplates(range);
  }

  const operationSelect = () => (
    <Menu
      anchorEl={selectOperationOpen}
      open={Boolean(selectOperationOpen)}
      onClose={() => setSelectOperationOpen(null)}
      style={{ margin: '40px 0 0 0px' }}
    >
      {
        displayOperations.map((value, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setSelectOperationOpen(null);
              handleOperationSelect(i);
            }}
            style={{
              fontWeight: 500,
            }}
          >
            {
              `${value.productName} ${
                value.fileType.toUpperCase() !== 'APPLICATION'
                  && value.variety != null
                  ? `(${value.variety})`
                  : ''
              } - ${value.date} (${value.fileType})`
            }
          </MenuItem>
        ))
      }
    </Menu>
  );

  const varietySelect = () => (
    <Menu
      anchorEl={selectVarietyOpen}
      open={Boolean(selectVarietyOpen)}
      onClose={() => setSelectVarietyOpen(null)}
      style={{ margin: '40px 0 0 0px' }}
    >
      {
        varietyList.map((variety, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setSelectVarietyOpen(null);
              handleVarietySelect(i);
            }}
            style={{
              fontWeight: 500,
            }}
          >
            {variety.name}
          </MenuItem>
        ))
      }
    </Menu>
  );

  const SelectZoneTabs = ({ className, onClick, children }) => (
    <Select
      className={className}
      value={selectedZoneOp}
      onChange={(e) => handleZoneSelect(e)}
      renderValue={(value) => `${value.name}`}
      children={children}
      variant="outlined"
      style={{ height: '30px' }}
    />
  );

  const SelectZones = ({ className, onClick, children }) => (
    <Select
      className={className}
      value={selectedZone}
      onChange={(e) => handleZone(e)}
      renderValue={(value) => `${value.name}`}
      children={children}
      variant="outlined"
      style={{ height: '30px' }}
    />
  );

  // Zone Analysis
  const handleZone = (e) => {
    const { value } = zonesList[e.target.value];
    setSelectedZone(zonesList[e.target.value]);

    for (let i = 0; i < zoneAnalysisRes.length; i++) {
      if (zoneAnalysisRes[i].variety === value) {
        const image = `data:image/jpeg;base64,${zoneAnalysisRes[i].pngb64}`;
        if (zoneAnalysisRes[i].extent !== undefined) {
          const bounds = zoneAnalysisRes[i].extent.split(',');
          const imageBounds = [
            [parseFloat(bounds[3]), parseFloat(bounds[0])],
            [parseFloat(bounds[1]), parseFloat(bounds[2])],
          ];
          // console.log("Setting map extent");
          setZoneMapExtent(imageBounds);
        }
        setZoneMapResult([zoneAnalysisRes[i]]);
        setZoneMapImage(image);
        setZoneMapLegend(zoneAnalysisRes[i].legend);
        setZoneMeanProfit(zoneAnalysisRes[i].mean);
      }
    }
  };

  const handleZoneSelect = async (e) => {
    setSelectedZoneOp(zoneOptions[e.target.value]);
    if (zoneOptions[e.target.value].value !== 'drawZones' && zoneOptions[e.target.value].value !== 'ssurgoZones') {
      let legendColorsReq = null; let
        profitmapLegendRange = null;
      const legendObj = getLegendColorRange();
      legendColorsReq = legendObj.legendColors;
      profitmapLegendRange = legendObj.range;

      zoneAnalysis(zoneOptions[e.target.value].value, displayOperations, legendColorsReq, profitmapLegendRange);
      setssurgoLayers([]);
    } else if (zoneOptions[e.target.value].value === 'drawZones') {
      getSavedZones();
      setZoneMapResult([]);
      setZoneMapImage('');
      setZoneMapLegend([]);
      setZonesList([]);
      setssurgoLayers([]);
      setSelectedZone('');
      setZoneMeanProfit(0);
      setZoneAnalysisRes([]);
    } else if (zoneOptions[e.target.value].value === 'ssurgoZones') {
      setZoneMapResult([]);
      setZoneMapImage('');
      setZoneMapLegend([]);
      setZonesList([]);
      setSelectedZone('');
      setZoneMeanProfit(0);
      getSSURGOZones();
      setZoneAnalysisRes([]);
    }
  };

  const getSSURGOZones = async () => {
    setZoneAnalysisLoader(true);
    const request = {
      AOI: field.boundary,
      Product: 'GeoJSON',
      Soil_Parameter: 'nccpi2all',
      Projection: 'EPSG:4326',
      Resolution: '0.0001',
    };

    const res = await df.ssurgoForZones(request);
    setZoneAnalysisLoader(false);
    setssurgoLayers(res.attributes.GeoJSON.features);
  };

  const listOfZonesDrawn = (zones, userEnteredZoneName, selectedZone) => {
    const zoneCosts = []; const zoneNames = []; const
      geoJson = [];
    const zoneBoundaryList = [];
    for (let i = 0; i < zones.length; i++) {
      try {
        zoneCosts.push(0.0001);
        zoneNames.push(`Zone ${i + 1}`);
        if (zones[i].properties !== undefined && zones[i].properties.featureRef !== undefined) {
          delete zones[i].properties.featureRef;
        }
        if (zones[i].properties !== undefined && zones[i].properties.mapRef !== undefined) {
          delete zones[i].properties.mapRef;
        }

        if (zones[i].zone !== undefined && zones[i].zone.properties.featureRef !== undefined) {
          delete zones[i].zone.properties.featureRef;
        }
        if (zones[i].zone !== undefined && zones[i].zone.properties.mapRef !== undefined) {
          delete zones[i].zone.properties.mapRef;
        }

        if (zones[i].zone === undefined) {
          geoJson.push(JSON.stringify(zones[i]));
        } else {
          if(zones[i].zone.featureRef !== undefined){
            delete zones[i].zone.featureRef
          }
          if(zones[i].zone.mapRef !== undefined){
            delete zones[i].zone.mapRef
          }
          geoJson.push(JSON.stringify(zones[i].zone));
        }
        // console.log(zones[i])
        // zones[i].coordinates.type = "Feature"
        const obj = {
          subZoneName: `Z ${i + 1}`,
          geojson: JSON.stringify(zones[i]),
        };
        // console.log('zones[i]', zones[i])
        zoneBoundaryList.push(obj);
      } catch (err) {
        console.log('zone error', err);
        continue;
      }
    }
    const zonesObj = {
      cost: zoneCosts,
      geojson: geoJson,
      varieties: zoneNames,
    };

    // console.log('zonesObj', zonesObj)
    const req = {
      zoneName: userEnteredZoneName,
      zoneBoundary: JSON.stringify(zoneBoundaryList),
    };
    if (selectedZone === '' && userEnteredZoneName !== '') {
      df.saveZones(field.orgId, field.id, req);
    }

    generateZoneMap(field.orgId, field.id, operations, zonesObj);
  };

  const generateZoneMap = async (orgId, fieldId, operations, zonesObj = null, retry = 0) => {
    retry += 1;
    operations.map((x) => {
      if (x.operationID === undefined) {
        x.operationID = x.genericOperationID;
      }
    });

    const operationsCopy = JSON.parse(JSON.stringify(operations));
    const toPop = [];
    for (const op of operationsCopy) {
      if (op.fileType.toUpperCase() !== 'HARVEST') {
        if (op.fileType.toUpperCase() === 'GENERIC') {
          if (op.aux1 === 'Field Pass' || op.aux1 === 'Cost') {
            if (op.price > 0) {
              op.price = parseFloat(0 - op.price);
            }
            if (op.aux1 === 'Field Pass' && op.area === 0) {
              toPop.push(operationsCopy.map((x) => x.operationID).indexOf(op.operationID));
            }
          }
        } else if (op.fileType.toUpperCase() === 'SEEDING') {
          // Convert values back to seeds
          if (op.unit === 'K seeds') {
            op.unit = op?.originalUnit !== undefined ? op.originalUnit : 'seeds';
            op.totalMaterialUnit = op?.originalUnit !== undefined ? op.originalUnit : 'seeds';
            op.price /= 1000;
            op.averageMaterialTarget *= 1000;
            op.quantity *= 1000;
            op.rate *= 1000;
          }
          op.price = parseFloat(0 - op.price);
        } else if (op.fileType.toUpperCase() === 'TILLAGE') {
          operationsCopy.constant -= op.price;
        } else if (op.price > 0) {
          op.price = parseFloat(0 - op.price);
        }
      }
    }

    toPop.map((x) => operationsCopy.splice(x, 1));

    // console.log('binRanges', binRanges, legend)
    const request = {
      FieldOperationList: operationsCopy,
      Constant: 0, // this is a left hardcoded for operation maps
      Year: year[0],
      IsResizeMapRequest: false,
      ProfitMapLegendRange: null,
      LegendColors: null,
      ProfitByZones: true,
      Zones: zonesObj,
      boundarygeojson: clipByBoundary ? field.boundary : null,
      IsHighResolutionMapRequest: highRes,
      MultiYears: year,
    };

    // Do not attempt any more if both binRanges and legend are empty
    if (binRanges.colors !== '' || legend.length) {
      const legendObj = getLegendColorRange();
      request.LegendColors = legendObj.legendColors;
      request.ProfitMapLegendRange = legendObj.range;

      setZoneAnalysisLoader(true);
      // setLoading(true)
      const res = await df.getProfitMap(orgId, fieldId, request);
      setZoneAnalysisLoader(false);
      // setLoading(false)
      if (res.profitMap.length > 0) {
        res.profitMap = updateProfitMapValues(totalProfit, res.profitMap, perAcre, netSeededAcres);
        setZoneAnalysisRes(res.profitMap);
      } else if (retry <= 1) {
        generateZoneMap(orgId, fieldId, operations, zonesObj, retry);
      } else {
        enqueueSnackbar('Failed to perform zone analysis');
        setZoneAnalysisRes([]);
      }
    }
    else {
      enqueueSnackbar('An error occured. Please try again later.');
      console.log('Error: Neither binRanges nor legend was acquired.');
    }
  };

  const getSavedZones = async () => {
    const res = await df.getZoneBoundaries(field.orgId, field.id);
    const zonesRes = res.filter((zone) => zone.zoneName !== '');
    setSavedZones(zonesRes);
    // console.log('getZoneBoundaries', res);
    // console.log(JSON.parse(res[0].zoneBoundary))
  };

  async function pushMap() {
    pushProfitMap(field.orgId, field.id, profitMapResponse);
  }

  // Elevation Index
  const handleElevationSelect = async (e) => {
    if (elevationIndex[e].name == 'Relative Elevation') {
      setElevationRelative(true);
    }

    setElevationIndex(elevationIndex[e]);
    setMapLoading(true);
    // setLoading(true)

    if (elevationIndex[e].value === 'elevationMap') {
      await elevationmap();
    } else {
      await getElevationIndex(field.orgId, field.id, elevationIndex[e].value, displayOperations, field.boundary);
    }

    setMapLoading(false);
    // setLoading(false)
  };

  const elevationSelect = () => (
    <Menu
      anchorEl={selectElevationOpen}
      open={Boolean(selectElevationOpen)}
      onClose={() => setSelectElevationOpen(null)}
      style={{ margin: '40px 0 0 0px' }}
    >
      {
        elevationIndex.map((value, i) => (
          <MenuItem
            key={i}
            onClick={() => {
              setSelectElevationOpen(null);
              handleElevationSelect(i);
            }}
            style={{
              fontWeight: 500,
            }}
          >
            { value.name }
          </MenuItem>
        ))
      }
    </Menu>
  );

  async function elevationmap() {
    // console.log(field, displayOperations);
    await getElevationMap(field.orgId, field.id, displayOperations, field.boundary);
  }

  const openRangeEdit = async () => {
    setRangeDisplay(true);
    if (width <= mobileBreakPoint) {
      await sleep(300);
      try {
        const element = document.getElementById('edit-ranges');
        element.scrollIntoView({ alignTop: true, behavior: 'smooth' });
      } catch (e) {
        console.log(e);
      }
    }
  };

  function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
  }

  const mobileFooter = () => (
    <Box
      p={1}
      display="flex"
      justifyContent="center"
      alignItems="center"
      flexWrap="wrap"
      style={{ width: '100%' }}
    >

      {
        width < showButtonsOnMap && (
          <>
            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '4px', whiteSpace: 'nowrap' }}
              onClick={() => openRangeEdit()}
            >
              Edit Profit Ranges
            </Button>

            <Button
              variant="outlined"
              color="primary"
              style={{ margin: '4px', whiteSpace: 'nowrap' }}
              onClick={() => generateMap(field.orgId, field.id, operations, legendBinColors, legendBinRanges)}
            >
              Generate Profit Map
            </Button>
          </>
        )
      }

      {
        source === 'Deere'
        && (
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: '8px', width: '380px' }}
          onClick={() => pushMap()}
        >
          Push Profit Map to Deere Operations Center
        </Button>
        )
      }

    </Box>
  );

  const showProfitMap = () => {
    const minWidth = width > mobileBreakPoint ? 540 : width;
    const toolTipTextMaxHeight = 400 - (profitMapResponse.length * 32);

    return (
      <Box
        display="flex"
        justifyContent="center"
        flexWrap="wrap"
        style={{ width: width > mobileBreakPoint ? width - 210 : width }}
      >
        <Box
          mr={1}
          display="flex"
          flexGrow={1}
          style={{
            height: width > mobileBreakPoint ? height - 420 : height - 300,
            minHeight: 480,
            minWidth,
            borderRadius: '0 0 4px 4px',
          }}
        >
          <DisplayMap
            boundary={field.boundary}
            maxZoom={(field.state === '' ? 17 : 18)}
            mapImage={images[0]}
            mapExtents={mapExtent}
            isProfitMap
          />
        </Box>
        <Box
          py={1}
          display="flex"
          flexDirection="column"
          alignItems="center"
        >
          { width > mobileBreakPoint ? (
            source === 'Deere' ? (
              <Button
                variant="outlined"
                color="primary"
                style={{ width: '250px' }}
                onClick={() => pushMap()}
              >
                Push Profit Map to John Deere Operations Center
              </Button>
            ) : (
              ''
            )
          ) : (
            mobileFooter()
          )}

          <MobileProfitSummary
            legend={legend}
            profitMapResponse={profitMapResponse}
            perAcre={perAcre}
            netSeededAcres={netSeededAcres}
          />

          <Box
            m={1}
            display="flex"
            flexDirection="column"
            color={blackText}
            fontWeight={500}
            style={{ maxWidth: 390 }}
          >
            <Box
              borderRadius="borderRadius"
              border={1}
              borderColor={grey}
              fontSize=".85rem"
              style={{ maxHeight: toolTipTextMaxHeight, minHeight: 120, overflowY: 'auto' }}
            >
              <Box p={1}>
                Your ProfitLayers Average Profit in the Operation Summaries may differ slightly from the Average Profit in the individual Operation Tables if the operations have different acreages. This does not affect the accuracy of your $/Acre ProfitLayers maps.
              </Box>
              <Box p={1}>
                The ProfitLayers system automatically removes certain outliers and anomalies in your precision agriculture sensor readings that other platforms may ignore, and as a result may be slightly different when processed through from other systems. However, this will not affect the patterns in your ProfitLayers, and our research indicates that your ProfitLayers and the summaries will be most accurate when processed by our system using the methods established.
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>
    );
  };

  const handleZoneSelection = (action, selected) => {
    console.log(action);
    if (action === 'draw') {
      console.log('start zone draw');
      getSavedZones();
      setZoneMapResult([]);
      setZoneMapImage('');
      setZoneMapLegend([]);
      setZonesList([]);
      setssurgoLayers([]);
      setSelectedZone('');
      setZoneMeanProfit(0);
      setZoneAnalysisRes([]);
      setDrawingZones('draw');
      setSelectedZoneOp('');
    } else if (action === 'ssurgoZones') {
      console.log('start ssurgo');
      setZoneMapResult([]);
      setZoneMapImage('');
      setZoneMapLegend([]);
      setZonesList([]);
      setSelectedZone('');
      setZoneMeanProfit(0);
      getSSURGOZones();
      setZoneAnalysisRes([]);
      setDrawingZones('ssurgo');
      setSelectedZoneOp('');
    } else if (action === 'select') {
      console.log(selected);
      setSelectedZoneOp(zoneOptions[selected]);
      let legendColorsReq = null; 
      let profitmapLegendRange = null;

      // Do not attempt any more if both binRanges and legend are empty
      if (binRanges.colors !== '' || legend.length) {
        const legendObj = getLegendColorRange();
        legendColorsReq = legendObj.legendColors;
        profitmapLegendRange = legendObj.range;

        zoneAnalysis(zoneOptions[selected].value, displayOperations, legendColorsReq, profitmapLegendRange);
        setssurgoLayers([]);
        setDrawingZones(null);
      } else {
        enqueueSnackbar('An error occured. Please try again later.');
        console.log('Error: Neither binRanges nor legend was acquired.');
      }
    }
  };

  const getLegendColorRange = () => {
    const legendObj = {
      legendColors: null,
      range: null,
    };

    if (binRanges.colors !== '') {
      legendObj.legendColors = {
        colors: binRanges.colors,
        range: binRanges.range,
      };
      legendObj.range = binRanges.binRange;
    } else {
      let legendColors = '[';
      let legendRanges = `${legend[legend.length - 1].min},`;
      for (let i = legend.length - 1; i >= 0; i--) {
        legendColors += `${legend[i].color},`;
        legendRanges += `${legend[i].max},`;
      }
      // console.log('legendRanges', legendRanges)
      legendColors = legendColors.slice(0, -1);
      legendColors += ']';
      legendRanges = legendRanges.slice(0, -1);
      // console.log(legendColors, legendRanges)
      legendObj.legendColors = {
        colors: legendColors,
        range: legend.length,
      };
      legendObj.range = legendRanges;
    }
    return legendObj;
  };

  return (
    <Box
      display="flex"
      flexDirection="column"
      justifyContent="center"
      style={{
        height: 'auto',
        width: '100%',
        overflowY: 'auto',
      }}
    >
      {
        mapType === 'Zone Analysis'
          ? (
            <Box style={{ height: '100%' }}>
              <Box>
                <Box
                  p={1}
                  display="flex"
                  flexWrap="wrap"
                  fontWeight={500}
                  color={blackText}
                >
                  <Box>
                    <Box>
                      Create zones to compare profits between areas of your field
                    </Box>
                    <Box display="flex">
                      <Box
                        className={classes.zoneTool}
                        borderRadius="borderRadius"
                        border={1}
                        style={drawingZones === 'draw' ? { borderColor: green } : {}}
                        onClick={() => handleZoneSelection('draw')}
                      >
                        <Box>
                          Draw Zones
                        </Box>
                        <CustomToolTip
                          title="Draw custom zones on your field"
                          placement="right"
                        >
                          <InfoOutlinedIcon className={classes.infoToolTip} />
                        </CustomToolTip>
                      </Box>

                      <Box
                        className={classes.zoneTool}
                        mx={1}
                        borderRadius="borderRadius"
                        border={1}
                        style={drawingZones === 'ssurgo' ? { borderColor: green } : {}}
                        onClick={() => handleZoneSelection('ssurgoZones')}
                      >
                        <Box id="ssurgo_zones">
                          Generate Zones From SSURGO
                        </Box>
                        <CustomToolTip
                          title="Generate zones using SSURGO soil data"
                          placement="right"
                        >
                          <InfoOutlinedIcon className={classes.infoToolTip} />
                        </CustomToolTip>
                      </Box>
                    </Box>
                  </Box>

                  { zoneOptions.length > 0 && (
                  <Box ml={1} mx={1} borderLeft={1} pl={2}>
                    <Box>
                      Select Application
                    </Box>
                    <Select
                      style={{ height: '30px' }}
                      variant="outlined"
                      value={selectedZoneOp.value}
                      onChange={(e) => handleZoneSelection('select', e.target.value)}
                      displayEmpty
                    >
                      <MenuItem value="" disabled>
                        Applications
                      </MenuItem>
                      {zoneOptions.map((x, i) => (
                        <MenuItem value={i} key={i}>
                          {x.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  )}

                  {
                    zonesList.length > 0 && (
                      <Box mx={1}>
                        <Box>
                          View
                        </Box>
                        <SelectZones>
                          {zonesList.map((x, i) => (
                            <MenuItem key={i} value={i}>
                              {x.name}
                            </MenuItem>
                          ))}
                        </SelectZones>
                      </Box>
                    )
                  }
                </Box>
              </Box>

              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                style={{
                  height: 'auto',
                  width: width > mobileBreakPoint ? width - 220 : '100%',
                }}
              >
                { rangeDisplay && (
                  width > mobileBreakPoint
                    ? (
                      <EditRangesModal
                        open={rangeDisplay}
                        closeModal={closeModal}
                        legend={zoneMapLegend}
                        binRange={binRange}
                        isOperation
                        map="zoneAnalysisMap"
                        netSeededAcres={netSeededAcres}
                        perAcre={perAcre}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        templateRes={templateRes}
                        setTemplateRes={setTemplateRes}
                      />
                    ) : (
                      <EditRanges
                        open={rangeDisplay}
                        closeModal={closeModal}
                        legend={zoneMapLegend}
                        binRange={binRange}
                        isOperation
                        map="zoneAnalysisMap"
                        netSeededAcres={netSeededAcres}
                        perAcre={perAcre}
                        selectedTemplate={selectedTemplate}
                        setSelectedTemplate={setSelectedTemplate}
                        templateRes={templateRes}
                        setTemplateRes={setTemplateRes}
                      />
                    )
                )}

                {/* { isZoneAnalysisRes && (
                  <CircularProgress
                    size={width > 768 ? 140 : 60}
                    style={{
                      position: 'absolute',
                      top: '30%',
                      right: 0,
                      left: 0,
                      margin: 'auto',
                      zIndex: 1005,
                      color: green,
                    }}
                  />
                )} */}

                {/* <FieldProvider> */}
                <ZoneAnalysisMap
                  containerHeight={width > mobileBreakPoint ? height - 420 : height - 300}
                  containerWidth={width > mobileBreakPoint ? width - 220 : '100%'}
                  boundary={field.boundary}
                  mapImage={zoneMapImage}
                  isProfitMap
                  mapExtents={zoneMapExtent}
                  canEdit
                  isOperationMap
                  operationMap={zoneMapResult}
                  mapType="zoneAnalysisMap"
                  selectedOption={selectedZoneOp}
                  listOfZonesDrawn={listOfZonesDrawn}
                  userSavedZones={savedZones}
                  ssurgoLayers={ssurgoLayers}
                  mapLoading={mapLoading}
                  drawingZones={drawingZones}
                  maxZoom={field.state === '' ? 17 : 18}
                  clearLegend={setZoneMapLegend}
                />
                {/* </FieldProvider> */}

                <RangeSlider
                  legend={zoneMapLegend}
                  isOperation
                  type="zoneAnalysisMap"
                  openSettings={setRangeDisplay}
                  zoneMeanProfit={zoneMeanProfit}
                  perAcre={perAcre}
                  netSeededAcres={netSeededAcres}
                  profitMapResponse={zoneAnalysisRes}
                  syncData={syncData}
                  errorMessage={errorMessage}
                  mobile={width < mobileBreakPoint}
                />
              </Box>
            </Box>
          )
          : mapType !== 'Comparison View'
            ? (
              <Box
                style={{ width: '100%' }}
              >

                {mapType === 'Profit Map' ? (
                  showProfitMap()
                ) : (
                  <Box
                    display="flex"
                    justifyContent="center"
                    style={{
                      height: width > mobileBreakPoint ? height - 420 : height - 300,
                      width: width > mobileBreakPoint ? width - 220 : '100%',
                      minHeight: 480,
                      overflow: 'hidden',
                      borderRadius: '0 0 4px 4px',
                    }}
                  >
                    <DisplayMap
                      containerHeight="100%"
                      containerWidth={width > mobileBreakPoint ? width - 220 : '100%'}
                      boundary={field.boundary}
                      maxZoom={(field.state === '' ? 17 : 18)}
                      mapImage={
                        mapType === 'Elevation Map' ? elevationImage
                          : mapType === 'Operation Layers' ? operationImage
                            : varietyImage
                      }
                      mapExtents={
                        mapType === 'Elevation Map' ? elevationMapExtent
                          : mapType === 'Operation Layers' ? operationMapExtent
                            : varietyMapExtent
                      }
                      isProfitMap
                    />
                  </Box>
                )}

                {/* Range Edit Modal */}
                <Box id="edit-ranges">
                  { rangeDisplay && (
                    width > mobileBreakPoint
                      ? (
                        <EditRangesModal
                          open={rangeDisplay}
                          closeModal={closeModal}
                          legend={
                        mapType === 'Profit Map' ? legend
                          : mapType === 'Elevation Map' ? elevationLegend
                            : mapType === 'Operation Layers' ? operationLegend
                              : varietyLegend
                      }
                          applyUpdate={mapType === 'Profit Map' ? applyLegendUpdates : null}
                          binRange={binRange}
                          isOperation={(mapType === 'Elevation Map' || mapType === 'Operation Layers')}
                          map={mapType}
                          pushMap={pushMap}
                          netSeededAcres={netSeededAcres}
                          savedTemplate={mapType === 'Profit Map' ? savedTemplate : null}
                          allTemplates={mapType === 'Profit Map' ? getTemplates : null}
                          perAcre={perAcre}
                          mapLoading={mapLoading}
                          selectedTemplate={selectedTemplate}
                          setSelectedTemplate={setSelectedTemplate}
                          templateRes={templateRes}
                          setTemplateRes={setTemplateRes}
                        />
                      ) : (
                        <EditRanges
                          open={rangeDisplay}
                          closeModal={closeModal}
                          legend={
                          mapType === 'Profit Map' ? legend
                            : mapType === 'Elevation Map' ? elevationLegend
                              : mapType === 'Operation Layers' ? operationLegend
                                : varietyLegend
                        }
                          applyUpdate={mapType === 'Profit Map' ? applyLegendUpdates : null}
                          binRange={binRange}
                          isOperation={(mapType === 'Elevation Map' || mapType === 'Operation Layers')}
                          map={mapType}
                          pushMap={pushMap}
                          netSeededAcres={netSeededAcres}
                          savedTemplate={mapType === 'Profit Map' ? savedTemplate : null}
                          allTemplates={mapType === 'Profit Map' ? getTemplates : null}
                          perAcre={perAcre}
                          mapLoading={mapLoading}
                          selectedTemplate={selectedTemplate}
                          setSelectedTemplate={setSelectedTemplate}
                          templateRes={templateRes}
                          setTemplateRes={setTemplateRes}
                        />
                      )
                  )}
                </Box>

                { /* Range Slider */ }
                {
                  width > mobileBreakPoint ? (
                    <RangeSlider
                      legend={
                          mapType === 'Profit Map' ? legend
                            : mapType === 'Elevation Map' ? elevationLegend
                              : mapType === 'Operation Layers' ? operationLegend
                                : varietyLegend
                      }
                      applyUpdate={applyLegendUpdates}
                      mapLoading={mapLoading}
                      isOperation={mapType !== 'Profit Map'}
                      isElevation={mapType === 'Elevation Map'}
                      elevationRelative={elevationRelative}
                      type={mapType.split(' ')[0]}
                      openSettings={setRangeDisplay}
                      profitMapResponse={mapType === 'Elevation Map' ? [elevationMap] : mapType === 'Operation Layers' ? operationMapRes : profitMapResponse}
                      unit={unit}
                      NPK={NPK}
                      appliedRate={appliedRate}
                      nMap={nmap}
                      setNMap={setnmap}
                      perAcre={perAcre}
                      netSeededAcres={netSeededAcres}
                      selectedElevationType={selectedElevationIndex.value}
                      syncData={syncData}
                      errorMessage={errorMessage}
                      isQuantityMap={isQuantityMap}
                      setIsQuantityMap={setIsQuantityMap}
                      selectedOp={selectedOp}
                      selectedVariety={selectedVar}
                    />
                  ) : (
                    <>
                      {/* Mobile Summaries */}
                      {
                        mapType === 'Elevation Map' ? (
                          <MobileElevationSummary
                            legend={elevationLegend}
                            perAcre={perAcre}
                            netSeededAcres={netSeededAcres}
                            type={selectedElevationIndex.value}
                          />
                        ) : mapType === 'Operation Layers' ? (
                          <MobileOperationSummary
                            legend={operationLegend}
                            unit={unit}
                            data={operationMapRes}
                            perAcre={perAcre}
                            netSeededAcres={netSeededAcres}
                          />
                        ) : mapType === 'Variety Analysis' ? (
                          <MobileVarietySummary
                            legend={varietyLegend}
                            data={profitMapResponse}
                            perAcre={perAcre}
                            netSeededAcres={netSeededAcres}
                            selectedVariety={selectedVar}
                          />
                        ) : (
                          <Box />
                        )
                      }
                    </>
                  )
                }

                {/* Selections for Operations or Varieties */}
                {
                  (
                    mapType === 'Operation Layers'
                    || mapType === 'Variety Analysis'
                    || mapType === 'Elevation Map'
                  )
                  && (
                  <Box
                    display="flex"
                    borderRadius="borderRadius"
                    boxShadow={1}
                    style={{
                      position: 'absolute',
                      top: 15,
                      left: 55,
                      zIndex: 1002,
                      backgroundColor: '#ffffff',
                    }}
                  >
                    {
                      mapType === 'Operation Layers'
                        ? (
                          <>
                            {operationSelect()}
                            <Box
                              className={classes.selections}
                              onClick={(e) => setSelectOperationOpen(e.currentTarget)}
                            >
                              {selectedOp !== ''
                              && `${selectedOp.productName} ${
                                selectedOp.fileType.toUpperCase() !== 'APPLICATION'
                                  && selectedOp.variety != null
                                  ? `(${selectedOp.variety})`
                                  : ''
                              } - ${selectedOp.date} (${selectedOp.fileType})`}

                              <ArrowDropDownCircleIcon
                                className={classes.dropdown}
                              />
                            </Box>
                          </>
                        )
                        : mapType === 'Elevation Map'
                          ? (
                            <>
                              {elevationSelect()}
                              <Box
                                id = "elevation_dropdown"
                                className={classes.selections}
                                onClick={(e) => setSelectElevationOpen(e.currentTarget)}
                              >
                                {selectedElevationIndex.name}
                                <ArrowDropDownCircleIcon
                                  className={classes.dropdown}
                                />
                              </Box>
                            </>
                          )
                          : (
                            <>
                              <Box display="flex" alignItems="center">
                                <Box>
                                  {varietySelect()}
                                  {
                              varietyList.length > 0 && (
                                <Box
                                  className={classes.selections}
                                  onClick={(e) => setSelectVarietyOpen(e.currentTarget)}
                                >
                                  {selectedVar.name}
                                  <ArrowDropDownCircleIcon
                                    className={classes.dropdown}
                                  />
                                </Box>
                              )
                            }
                                </Box>
                                <CustomToolTip
                                  title="Select a variety in the dropdown menu to view a profit analysis by variety"
                                >
                                  <InfoOutlinedIcon
                                    style={{ margin: 6, fontSize: 18, color: darkGrey }}
                                  />
                                </CustomToolTip>
                              </Box>
                            </>
                          )
                    }
                  </Box>
                  )
              }

                {/* Generate Map Button */}
                {
                  (mapType === 'Profit Map' && width >= showButtonsOnMap)
                  && (
                    <Box
                      style={{
                        position: 'absolute',
                        top: 15,
                        left: 55,
                        zIndex: 1002,
                      }}
                    >
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ backgroundColor: '#f3f3f3' }}
                        onClick={() => generateMap(field.orgId, field.id, operations, legendBinColors, legendBinRanges)}
                      >
                        Generate Profit Map
                      </Button>
                      <Button
                        variant="outlined"
                        color="primary"
                        style={{ backgroundColor: '#f3f3f3', marginLeft: '5px' }}
                        onClick={() => openRangeEdit()}
                      >
                        Edit Profit Ranges
                      </Button>
                    </Box>
                  )
                }
              </Box>
            )
            : (
              <Box />
            )
      }
      <Box
        style={{
          position: mapType === 'Comparison View' ? 'static' : 'absolute',
          right: '-5000px',
          top: '5000px',
          height: height - 230,
          width: width < mobileBreakPoint ? width : width - 240,
        }}
      >

        <MultiMaps
          field={field}
          boundary={field.boundary}
          perAcre={perAcre}
          profitMap={profitMap}
          profitMapResponse={profitMapResponse}
          profitData={{ image: images[0], extent: mapExtent, legend }}
          elevationData={{
            image: elevationImage,
            extent: elevationMapExtent,
            legend: elevationLegend,
            selectElevation: handleElevationSelect,
            selectedElevationIndex,
            elevationIndex,
          }}
          operationData={{
            image: operationImage,
            extent: operationMapExtent,
            legend: operationLegend,
            selectOperation: handleOperationSelect,
            operations: displayOperations,
            selectedOperation: selectedOp,
          }}
          varietyData={{
            image: varietyImage,
            extent: varietyMapExtent,
            legend: varietyLegend,
            selectVariety: handleVarietySelect,
            varietyList,
            selectedVariety: selectedVar,
          }}
          clipByBoundary={clipByBoundary}
        />

      </Box>
      {isZoneAnalysisRes && 
        <CircularProgress
          size={width > 768 ? 140 : 60}
          style={{
            position: 'absolute',
            top: '30%',
            right: 0,
            left: 0,
            margin: 'auto',
            zIndex: 1005,
            color: green,
          }}
        />
      }
      {/* { initialLoading && <SpinningLoader />} */}

      {/* { mapType === 'Elevation Map' && (
        <WaterManagementPopUp />
      )}

      { mapType === 'Zone Analysis' && (
        <SoilTestingPopUp />
      )} */}
    </Box>
  );
}

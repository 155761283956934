import React, { useEffect, useState } from 'react'
import { Box, Typography, Link, Divider, Fade, CircularProgress, Button, Modal } from '@material-ui/core';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { WeatherAlertMap } from './WeatherAlertMap'
import { Subscribe } from './Subscribe'
import { useWindowDimensions } from '../../../utils/dimensions'
import { blackText, lightGrey, grey, green } from "../../../styles/colors";
import * as df from '../../../utils/dataFetchers'
import { exists, numFormat, sleep } from '../../../utils/helpers'
import * as turf from "@turf/turf";
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import moment from 'moment';

const useStyles = makeStyles(theme => ({
  messageBox: theme.messageBox,
  paper: theme.modalPaper,
  errorMessageBox: theme.errorMessageBox,
  back: {
    color: theme.palette.primary.main,
    '&:hover': {
        cursor: 'pointer',
        color: theme.palette.primary.dark
    },
  }
}))

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: '#ffffff',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

function getModalStyle() {
  const top = 5;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    height: 'auto',
    minWidth: 400,
    width: '80%',
    maxWidth: 600,
    padding: 1,
  };
}

export function WeatherAlerts({
  field,
  getSevereWeatherAlerts,
  severeWeatherAlerts,
  loading,
  mobileBreakPoint,
  errorMessage,
}) {
  const { width, height } = useWindowDimensions();
  const classes = useStyles();
  const modalStyle = getModalStyle()

  const [mapImage, setMapImage] = useState('')
  const [extent, setExtent] = useState('')

  const [expanded, setExpanded] = useState(['']);
  const [highlighted, setHighlighted] = useState(null)
  const [subscribing, setSubscribing] = useState(false);

  const [selectedAlerts, setSelectedAlerts] = useState([])

  const [weatherAlertsOnMap, setWeatherAlertsOnMap] = useState([])
  const [alertsToShow, setAlertsToShow] = useState([])


  useEffect(() => {
    if (severeWeatherAlerts !== null) {
      const alertsOnMap = severeWeatherAlerts.filter(x => weatherAlertsOnMap.includes(x.id))
      setAlertsToShow(alertsOnMap)
    }
  }, [weatherAlertsOnMap, severeWeatherAlerts])

  const handleChange = (panel) => (event, newExpanded) => {
    if(expanded.includes(panel)){
      const removePanel = expanded.filter(x => x !== panel)
      setExpanded(removePanel);
      setSelectedAlerts(removePanel)
    }
    else{
      setExpanded([panel]);
      const selectedAlerts = alertsToShow.filter(x => x.id === panel)
      setSelectedAlerts(selectedAlerts)
      highlight(panel)
    }
  };

  const highlight = async (panel) => {
    setHighlighted(panel)
    await sleep(1000)
    setHighlighted(null)
  }

  const scrollToAlerts = () => {
    const element = document.getElementById('severe-weather-alerts');
    if(element !== null){
      element.scrollIntoView({ block: 'end',  behavior: 'smooth' });
    }
  }

  const startSubscribe = () => {
    setSubscribing(true)
  }

  const changeFromMap = (panel) => {
    setExpanded(panel)
    setSelectedAlerts([])
  }

  useEffect(() => {
    if (alertsToShow !== null) {
      // When weather alerts change, remove selected so all will show
      setSelectedAlerts([])
    }
  }, [alertsToShow])


  const createRow = (x, i, showingSelected = false) => {
    let panelValue = x.id
    const now = moment()
    const sent = moment.utc(x.sent).local()
    return (
      <Box
        id={panelValue}
        key={i}
        m={1}
        border={expanded.includes(panelValue) ? 2 : 1}
        borderColor={expanded.includes(panelValue) ? green : grey}
        borderRadius='borderRadius'
        color={blackText}
      >
        <Accordion
          square
          expanded={expanded.includes(panelValue)}
          onChange={handleChange(panelValue)}
        >
          <AccordionSummary
            style={{
              backgroundColor: highlighted === panelValue ? '#ffff6e' : '#f8f8ff',
            }}
          >
            <Box>
              { showingSelected && (
                <Box
                  className={classes.back}
                  onClick={() => setSelectedAlerts([])}
                >
                  <ArrowBackIcon />
                  {' Back'}
                </Box>
              )}
              <Box display='flex' justifyContent='space-between'>
                <Box fontSize={18} fontWeight={500}>
                  {x._event}
                </Box>
                <Box color={green} fontSize={14}>
                  {moment(now.diff(sent)).format("m[m] s[s]")}
                </Box>
              </Box>

              <Box mt={1}>
                {x.headline}
              </Box>
              <Box>
                {x.areaDesc}
              </Box>
              <Box color={"deeppink"}>
                {x.severity} / {x.urgency} / {x.certainty}
              </Box>
            </Box>
          </AccordionSummary>
          <AccordionDetails
            style={{
              backgroundColor: highlighted === panelValue ? '#ffff6e' : '#ffffff',
            }}
          >
            <Box  fontSize={14} color={blackText}>
              <Box fontSize={18} fontWeight={500}>
                Description
              </Box>
              <Box mt={1}>
                {x.description}
              </Box>
              {
                x.instruction !== '' && (
                  <React.Fragment>
                    <Box my={1} fontSize={18} fontWeight={500}>
                      Instruction
                    </Box>
                    <Box>
                      {x.instruction}
                    </Box>
                  </React.Fragment>
                )
              }
            </Box>
          </AccordionDetails>
        </Accordion>
      </Box>
    )
  }

  return (
    <Fade in={true} style={{width: '100%'}}>
      <Box color={blackText} fontWeight={500}>

        <Box m={2}>
          <Box fontSize={18}>
            Current Weather Alerts on Your Field
          </Box>

          <Box fontSize={'1rem'} style={{maxWidth: 800}}>
            National Weather Service alerts, watches, and warnings. Updated every 5 mins. Weather Alerts are displayed for agricultural and informational purposes and are not intended to be used to obtain information during an emergency or severe weather event.
          </Box>
          <Box display='flex'>
            <Button
              variant='outlined'
              color='primary'
              onClick={() => startSubscribe()}
              style={{margin: 8}}
            >
              Subscribe To Real Time Alerts
            </Button>
            {
              window.innerWidth < 1370 && (
                <Button
                  variant='outlined'
                  color='primary'
                  onClick={() => scrollToAlerts()}
                  style={{margin: 8}}
                >
                  View Alerts
                </Button>
              )
            }
          </Box>


        </Box>

        { errorMessage === '' ? (
          <Box display='flex' flexWrap='wrap'>
            <Box>
              <WeatherAlertMap
                boundary={field.boundary}
                getSevereWeatherAlerts={getSevereWeatherAlerts}
                severeWeatherAlerts={severeWeatherAlerts}
                setExpanded={changeFromMap}
                expanded={expanded}
                highlight={highlight}
                height={window.innerHeight - 400}
                width={width > 840 ? 600 : width > mobileBreakPoint ? width - 240 : width - 30 }
                setWeatherAlertsOnMap={setWeatherAlertsOnMap}
              />
            </Box>

            <Box mx={1}>
              {
                (alertsToShow.length > 0) ? (
                  <Box>
                    <Box
                      id='severe-weather-alerts'
                      p={1}
                      display='flex'
                      flexDirection='column'
                      border={1}
                      borderRadius='borderRadius'

                    >
                      <Typography align='center' style={{fontSize: 20, fontWeight: 500}}>
                        Weather Alerts
                      </Typography>
                      <Divider />
                      <Box
                        mt={1}
                        style={{
                          maxWidth: 500,
                          maxHeight: window.innerHeight - 340,
                          minHeight: 400,
                          overflowY: 'auto',
                        }}
                      >
                        {selectedAlerts.length > 0 ? (
                          selectedAlerts.map((x, i) => createRow(x, i, true))
                        ) : (
                          alertsToShow.map((x, i) => createRow(x, i))
                        )}
                      </Box>
                    </Box>
                  </Box>
                ) : (
                  <Box
                    m={1}
                    p={1}
                    display='flex'
                    alignItems='center'
                    justifyContent='center'
                    fontWeight={500}
                    fontSize={16}
                    color={blackText}
                  >
                    { loading ? (
                      <>
                        <Box mr={1}>
                          Loading Weather Alerts
                        </Box>
                        <CircularProgress size={16}/>
                      </>
                    ) : (
                      <Box>
                        <Box>
                          There are no alerts for the current area at this time.
                        </Box>
                        <Box>
                          Zoom or pan the map to check a different area.
                        </Box>
                      </Box>

                    )}
                  </Box>
                )
              }
            </Box>
          </Box>
        ) : (
          <Box m={1} display='flex'>
            <Box className={classes.errorMessageBox}>
              {errorMessage}
            </Box>
          </Box>
        )}

        <Modal
          open={subscribing}
          onClose={() => setSubscribing(false)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            overflowY: 'auto'
          }}
        >
          <Box
            style={modalStyle}
            className={classes.paper}
            borderRadius="borderRadius"
            boxShadow={2}
          >
            <Subscribe setOpen={setSubscribing}/>
          </Box>
        </Modal>
      </Box>
    </Fade>
  )
}

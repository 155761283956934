import * as L from "leaflet";
import * as turf from "@turf/turf";

export function drawFieldLayer(geo, fieldLayer, map) {
    try {
      const geometry = geo.features[0].geometry;
      const boundary =
        geometry.type === "Polygon"
          ? turf.polygon(geo.features[0].geometry.coordinates)
          : turf.multiPolygon(geo.features[0].geometry.coordinates);
      let newCollection = L.geoJson(boundary, {
        onEachFeature: (feature, layer) => {
          fieldLayer.addLayer(layer).setStyle({fillOpacity: 0});
        },
      });
      map.fitBounds(newCollection.getBounds());
      newCollection.addTo(map);

    } catch (err) {
      console.log(err);
    }
  }

export function drawImage(imageOverlay, mapImage, boundary, mapExtents, map) {
  const features =
    typeof boundary === "object" ? boundary : JSON.parse(boundary);
  const bounds =
    mapExtents.length === 0
      ? features.geometry.coordinates[0].map((x) => [x[1], x[0]])
      : mapExtents;

  if (imageOverlay.current === null) {
    imageOverlay.current = L.imageOverlay(mapImage, bounds, {
      opacity: 0.8,
      interactive: true,
    });
    imageOverlay.current.addTo(map);
  } else {
    imageOverlay.current.setUrl(mapImage);
    imageOverlay.current.setBounds(bounds);
  }
}

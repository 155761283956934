import React, {
  useState, useEffect, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Fade,
  Divider,
  Typography,
  Checkbox,
  Radio,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { dollarFormat, sleep, getImage } from '../../../utils/helpers';
import { green, grey, offWhite } from '../../../styles/colors';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import {
  twoPointFiveAcrePolygonPackages,
  twoPointFiveAcreGridPackages,
  fiveAcrePolygonPackages,
  fiveAcreGridPackages,
} from './packages';

const Accordion = withStyles({
  root: {
    border: `1px solid ${grey}`,
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    borderBottom: `1px solid ${grey}`,
    marginBottom: -1,
    minHeight: 46,
    '&$expanded': {
      minHeight: 46,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
    backgroundColor: 'rgba(0, 0, 0, .02)',
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    height: 'inherit',
    backgroundColor: offWhite,
  },
  cards: {
    display: 'flex',
    flexDirection: 'column',
    margin: 6,
    border: `3px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
    padding: 8,
    backgroundColor: '#ffffff',
    color: theme.palette.text.primary,
    maxWidth: 400,
  },
  name: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  addField: {
    border: `1px solid ${theme.palette.greys.dark}`,
    borderRadius: 4,
    padding: 8,
    color: theme.palette.primary.main,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.greys.light,
    },
  },
  customPackage: {
    display: 'flex',
    flexDirection: 'column',
    // maxWidth: 640,
    border: `1px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
    padding: 8,
    backgroundColor: '#ffffff',
  },
  selectCustom: {
    fontWeight: 500,
    fontSize: '1.2rem',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: offWhite,
    },
  },
}));

/**
 * User will select packages for field here. If no field is selected, button
 * will prompt user to do so. If no zone is selected, button will prompt user
 * to do so. We will also get the field image here. If user selects a package
 * before the image is taken we will await the image and pass it through
 * addPackage function via internal selectPackage function.
 * @param {String} gridSize Determines packages to show
 * @param {Function} addPackage Add selected package to field
 * @param {Object} field Data for currently selected field
 * @param {Function} updateField Updates selected field, used here for adding image
 * @param {Function} goToFieldSelection Brings user to select field component
 * @param {Function} goToCreateZones Brings user to create zones component
 * @returns {JSX} Package selection
 */
export function SelectPackage({
  gridSize,
  addPackage,
  field,
  updateField,
  goToFieldSelection,
  goToCreateZones,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [selectedPackage, setSelectedPackage] = useState(null);
  const [customPackage, setCustomPackage] = useState(false);
  const fieldPackage = useRef(null);

  const imageTaken = useRef(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (
      imageTaken.current === false
      && field !== null
      && (field?.fieldImage === undefined || field?.fieldImage === '')
    ) {
      // If field does not have image, get one
      getFieldImage();
    } else {
      imageTaken.current = true;
    }

    if (field?.package) {
      setSelectedPackage(field.package);
      fieldPackage.current = field?.packageInfo;
      if (field.package.includes('Custom')) {
        setCustomPackage(true);
      }
    }
  }, [field]);

  const getFieldImage = async () => {
    try {
      // give map time to draw boundary
      await sleep(800);

      // get map image
      const fieldImage = await getImage('field-image');

      const fieldWithImage = {
        ...field,
        fieldImage,
        package: fieldPackage.current?.name !== undefined ? fieldPackage.current?.name : '',
        packageInfo: fieldPackage.current,
      };

      updateField(fieldWithImage);
      imageTaken.current = true;

      return fieldImage;
    } catch (err) {
      console.error(err);
      return '';
    }
  };

  const selectPackage = async (selected, custom = customPackage, move = true) => {
    const newPackage = selected;
    setSelectedPackage(selected.name);
    // Ref needed for field image
    fieldPackage.current = selected;

    let fieldImage = '';
    if (!imageTaken.current) {
      // Get field image if user selected package before it was taken
      setLoading(true);
      fieldImage = await getFieldImage();
      setLoading(false);
    }

    if (custom) {
      // Add "Custom" text to package name if option selected
      newPackage.name = `Custom - ${selected.name}`;
    } else {
      // This is necessary for when custom has been selected, then de-selected
      newPackage.name = selected.name.replace('Custom - ', '');
    }

    // Add package will ignore field image if still empty string
    addPackage(newPackage, fieldImage, move);
  };

  const handleCustomPackage = (selected) => {
    setCustomPackage(selected);

    if (fieldPackage.current) {
      // If package is already selected, update custom flag
      selectPackage(fieldPackage.current, selected, false);
    } else if (selected) {
      enqueueSnackbar(
        'Select the plan that most closely meets your needs, then after you submit your no-obligation order a soil testing specialist will contact you.',
        { preventDuplicate: true, autoHideDuration: 10000 },
      );
    }
  };

  const showFiveAcrePackages = () => (
    <Box display="flex" justifyContent="space-around" flexWrap="wrap">
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {fiveAcrePolygonPackages.map((x, i) => createPackageCard(x, i))}
      </Box>
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {fiveAcreGridPackages.map((x, i) => createPackageCard(x, i))}
      </Box>
    </Box>
  );

  const showTwoPointFiveAcrePackages = () => (
    <Box display="flex" justifyContent="space-around" flexWrap="wrap">
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {twoPointFiveAcrePolygonPackages.map((x) => createPackageCard(x))}
      </Box>
      <Box display="flex" justifyContent="space-around" flexWrap="wrap">
        {twoPointFiveAcreGridPackages.map((x) => createPackageCard(x))}
      </Box>
    </Box>
  );

  const createPackageCard = (soilTestPackage) => (
    <Box key={soilTestPackage.name}>
      <Box
        className={classes.cards}
        minHeight={window.innerWidth > 1600 ? 540 : 300}
      >
        <Box
          mb={1}
          align="center"
          flexWrap="wrap"
        >
          <Typography variant="h5">
            {soilTestPackage.displayName}
          </Typography>
        </Box>

        <Divider />

        <Box p={1} display="flex" flexDirection="column" marginBottom="auto">
          { soilTestPackage.deliverables.map((x, i) => deliverableRow(x, i)) }
        </Box>

        <Divider />

        <Typography variant="h6" align="center">
          { `${dollarFormat(soilTestPackage.msrp)} /Acre` }
        </Typography>

        <Divider />

        { packageSelection(soilTestPackage) }

      </Box>
    </Box>
  );

  const deliverableRow = (x, i) => (
    <Box key={i} mb={0.5} width="100%">
      <Accordion square>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Typography className={classes.name}>
            {x.deliverable}
          </Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography color="textPrimary">
            {x.description}
          </Typography>
        </AccordionDetails>
      </Accordion>
    </Box>
  );

  const packageSelection = (soilTestPackage) => (
    <Box
      mt={0.5}
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={50}
    >
      { field === null ? (
        <Box
          className={classes.addField}
          onClick={() => goToFieldSelection()}
        >
          Add a field to select a package
        </Box>
      ) : !field?.zones?.length > 0 ? (
        <Box
          className={classes.addField}
          onClick={() => goToCreateZones()}
        >
          Add zones to select a package
        </Box>
      ) : soilTestPackage.name !== selectedPackage ? (
        <Button
          variant="outlined"
          color="primary"
          onClick={() => selectPackage(soilTestPackage)}
        >
          Select
        </Button>
      ) : (
        <Box color={green} fontSize={18}>
          Package Selected
        </Box>
      )}
    </Box>
  );

  const customPackageSelection = () => (
    <Box className={classes.customPackage} maxWidth={680}>
      <Typography align="center" style={{ fontWeight: 500, fontSize: '1.2rem' }}>
        Need custom results or a specific lab or test? No problem!
      </Typography>
      <Typography align="center" style={{ fontWeight: 500 }}>
        Request a custom order and our soil specialists will be in contact to meet your needs.
      </Typography>
      <Box display="flex" alignItems="center" justifyContent="center">
        <Checkbox
          checked={customPackage}
          onChange={(e) => handleCustomPackage(e.target.checked)}
          name="custom-order"
          color="primary"
        />
        <Typography style={{ fontWeight: 500, fontSize: '1.1rem' }}>
          Ask for Custom Order
        </Typography>
      </Box>

      <Fade
        in={customPackage}
        mountOnEnter
        unmountOnExit
      >
        <Box pl="28px">
          <Typography align="center" style={{ fontWeight: 500 }} gutterBottom>
            Great, we&apos;ll let our soil specialists know you need a special order! Select the plan that most closely meets your needs and we&apos;ll contact
            you to customize once you submit. Don&apos;t worry, you can change or amend your no obligation order at any time
          </Typography>
        </Box>
      </Fade>
    </Box>
  );

  return (
    <Fade in>
      <Box className={classes.root}>
        <Box p={1} display="flex" flexWrap="wrap" bgcolor={offWhite}>
          <Box margin="auto">
            {
              gridSize === '5' ? (
                showFiveAcrePackages()
              ) : gridSize === '2.5' ? (
                showTwoPointFiveAcrePackages()
              ) : (
                <>
                  {showFiveAcrePackages()}
                  {showTwoPointFiveAcrePackages()}
                </>
              )
            }
          </Box>

          { loading && <SpinningLoader />}

        </Box>
        <Box pb={1} display="flex" justifyContent="center" bgcolor={offWhite}>
          {customPackageSelection()}
        </Box>
      </Box>
    </Fade>
  );
}

SelectPackage.propTypes = {
  gridSize: PropTypes.string.isRequired,
  addPackage: PropTypes.func.isRequired,
  field: PropTypes.object.isRequired,
  updateField: PropTypes.func.isRequired,
  goToFieldSelection: PropTypes.func.isRequired,
  goToCreateZones: PropTypes.func.isRequired,
};

/* eslint-disable radix */
/* eslint-disable no-plusplus */
// #region - imports
import React, {
  useState,
  useEffect,
  useContext,
  useRef,
} from 'react';
import ReactGA from 'react-ga'
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  grey,
  lightGrey,
  darkGrey,
  green,
  blackText,
} from '../../styles/colors';
import { roundIcon, mobileRoundIcon } from '../../styles/icons';
import poweredBy from '../../images/Ag-Analytics_Full_Black_Powered.svg'

import {
  Box,
  Button,
  IconButton,
  Popover,
  Typography,
  AppBar,
  Modal,
  TextField,
  Divider,
  LinearProgress,
  Dialog,
  DialogTitle,
  DialogContent,
  Snackbar,
  Slide,
  Switch,
  Grow,
  Tabs,
  Tab,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import CheckBoxRoundedIcon from '@material-ui/icons/CheckBoxRounded';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import EditIcon from '@material-ui/icons/Edit';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowDropDownCircleIcon from '@material-ui/icons/ArrowDropDownCircle';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import SettingsIcon from '@material-ui/icons/Settings';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import SyncIcon from '@material-ui/icons/Sync';
import SyncAltIcon from '@material-ui/icons/SyncAlt';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import CloseIcon from '@material-ui/icons/Close';
import ArrowUpwardRoundedIcon from '@material-ui/icons/ArrowUpwardRounded';
import ArrowDownwardRoundedIcon from '@material-ui/icons/ArrowDownwardRounded';
import FiberManualRecordRoundedIcon from '@material-ui/icons/FiberManualRecordRounded';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

import { useSnackbar } from 'notistack';
import { Endpoints } from '../../constants/Endpoints';
import * as df from '../../utils/dataFetchers';
import { useWindowDimensions } from '../../utils/dimensions';
import {
  exists,
  numFormat,
  dollarFormat,
  scrollTo,
  getCircularReplacer,
  sleep,
} from '../../utils/helpers';
import * as calc from './utils/calculations';
import * as con from './utils/conversions';
import { formatForProfitMap } from './utils/conversions';
import { getSeedingOperations } from './PrepareData/PrepareSeeding';

import { UserContext } from '../Context/UserContext';
import { FieldContext } from '../Context/FieldContext';
import { FieldSelection } from '../FieldSelection/FieldSelection';
import { Applications } from './Operations/Applications/Applications';
import { Plantings } from './Operations/Plantings/Plantings';
import { Others } from './Operations/Others/Others';
import { Reports } from './Reports/Reports';
import { CustomToolTip } from '../../utils/customComponents';
import { UploadAgFile } from './utils/UploadAgFile';
import { YearSelection } from './utils/YearSelection';
import { Settings } from './Settings';
import { PriceTool } from './PriceTool/PriceTool';
import { Template } from './Templates/Template';
import { updateFieldPass } from './Operations/FieldPasses/createPasses';
import { CreatePdf } from './Pdf/CreatePdf';
import { Connect } from '../Shared/Connect';
import { IntegrationStatus } from '../Shared/IntegrationStatus';
import { InfoModal } from './InfoModal';
import ReportContainer from '../ReportContainer/index';
import { FarmReportContainer } from './FarmMarket/FarmReportContainer';
import { MarketReportContainer } from './FarmMarket/MarketReportContainer';
import { PopupManager } from '../Shared/Popups/PopupManager';
// #endregion

const useStyles = makeStyles((theme) => ({
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
  },
  appBarSelections: theme.appBarSelections,
  appBarSelectionsMobile: {
    ...theme.appBarSelections,
    margin: '0 10px',
    overflowX: 'auto',
  },
  templateHeader: {
    ...theme.appBar,
    flexDirection: 'column',
    backgroundColor: 'rgb(103, 180, 68)',
    padding: 8,
  },
  mobileSteps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  mobileTabs: theme.mobileTabs,
  connectModal: {
    position: 'absolute',
    width: '80vw',
    height: '83vh',
    backgroundColor: theme.palette.greys.light,
    overflow: 'auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  operationBox: theme.centeredColumn,
  operations: {
    backgroundColor: theme.palette.greys.light,
    height: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
  },
  label: theme.label,
  greenClick: theme.plIcon,
  icon: theme.icon,
  hover: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
  edit: {
    marginLeft: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  getPdf: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  select: {
    padding: '10px',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sync: {
    ...theme.greenHover,
    fontSize: 18,
    marginLeft: '5px',
  },
  partner: {
    height: '60px',
    width: 'auto',
    margin: 6,
  },
  partner2: {
    height: '80px',
    width: 'auto',
    margin: 18,
  },
  connect: theme.connect,
  mobileConnect: {
    ...theme.connect,
    fontSize: 16,
    padding: 6,
    margin: 6,
  },
  logIn: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.lightGrey,
  },
  arrows: theme.navigationArrows,
  settingsIcon: {
    color: green,
    '&:hover': {
      cursor: 'pointer',
    },
    fontSize: 24,
    marginLeft: 8,
  },
  close: theme.close,
  infoToolTip: theme.infoToolTip,
  fullLogo: {
    width: '250px',
    height: 'auto',
    marginRight: '20px',
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginRight: '8px',
  },
  smallLogo: {
    width: '90px',
    height: 'auto',
    marginRight: '4px',
  },
}));

/**
 * Handles data and controls render for setting up operations, displaying Profit Maps,
 * Elevation Maps, Operation Layers, Variety Analysis, Zone Analysis, Scatter Plots, Costs Charts.
 * Also provides links to Insurance Tools and Landlord - Tenant Split Post Analysis.
 * @param {Number} plStep Step to start on
 * @param {Bool} integrating Precision ag integration in process
 * @param {Function} setSection Navbar highlighting
 * @param {Function} setDataLayersStep Starts user in DataLayers when already selected field
 * @returns {JSX} ProfitLayers
 */
export function ProfitLayers({
  plStep,
  integrating,
  setSection,
  setDataLayersStep,
}) {
  // #region - variables
  const user = useContext(UserContext)[0];
  const [fieldData, setFieldData] = useContext(FieldContext);
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  // Pixel width when display will change for mobile view
  const mobileBreakPoint = 865;
  const { enqueueSnackbar } = useSnackbar();

  const [infoModalOpen, setInfoModalOpen] = useState(false);
  const [integrationStatusOpen, setIntegrationStatusOpen] = useState(false);
  const [integrationStatus, setIntegrationStatus] = useState('');
  const [integrationSource, setIntegrationSource] = useState('');
  const [integrationFlag, setIntegrationFlag] = useState(0);
  const [uploadFile, setUploadFile] = useState(false);

  const [value, setValue] = useState(0); // tab control
  const [opTabValue, setOpTabValue] = useState(0); // operation tab control

  const [field, setField] = useState({
    id: '',
    name: '',
    orgId: '',
    org: '',
    farmId: '',
    farm: '',
    state: '',
    county: '',
    acres: 0,
    latitude: 0,
    longitude: 0,
    coordinates: [{ type: '', coordinates: [] }],
    boundary: '',
    points: [],
    source: '',
  });

  const [hideFieldSelection, setHideFieldSelection] = useState(false);
  const [hideOperationSelection, setHideOperationSelection] = useState(false);
  const [hideMapSelection, setHideMapSelection] = useState(false);

  const [integrated, setIntegrated] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [isNewUser, setNewUser] = useState(false);
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);
  const [toContinuePrompt, setToContinuePrompt] = useState(
    'Please sign in to continue using the tool',
  );
  const [source, setSource] = useState('');
  const [netSeededAcres, setNetSeededAcres] = useState(0);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  // years for multiyear profit map
  const [selectedYears, setSelectedYears] = useState([]);

  const [selectYearsOpen, setSelectYearsOpen] = useState(null);
  const [perAcre, setPerAcre] = useState(true); // show prices per acre or field
  const [loaded, setLoaded] = useState([]); // loaded operations

  // Used to determine if all operations have been loaded before Viewing PL
  const operationsLoaded = useRef([]);

  // Sync times
  const [lastSyncDate, setLastSyncDate] = useState('');
  const [lastSyncTime, setLastSyncTime] = useState('');

  const [orgSyncDate, setOrgSyncDate] = useState('');
  const [orgSyncTime, setOrgSyncTime] = useState('');

  const [boundarySyncDate, setBoundarySyncDate] = useState('');
  const [boundarySyncTime, setBoundarySyncTime] = useState('');

  // harvest operations
  const [harvestYears, setHarvestYears] = useState([]);
  const [harvestOperations, setHarvestOperations] = useState([]);
  const [displayHarvestOps, setDisplayHarvestOps] = useState([]);
  const [hiddenHarvestOps, setHiddenHarvestOps] = useState([]);

  // combined operations, may eventually replace harvestOperations
  const [harvests, setHarvests] = useState([]);
  const [displayedHarvests, setDisplayedHarvests] = useState([]);
  const [hiddenHarvests, setHiddenHarvests] = useState([]);
  const [harvestRevenue, setHarvestRevenue] = useState(0);

  // seeding operations
  const [seedingOperations, setSeedingOperations] = useState([]);
  const [displaySeedingOps, setDisplaySeedingOps] = useState([]);
  const [hiddenSeedingOps, setHiddenSeedingOps] = useState([]);

  // combined operations, may eventually replace seedingOperations
  const [seedings, setSeedings] = useState([]);
  const [displayedSeedings, setDisplayedSeedings] = useState([]);
  const [hiddenSeedings, setHiddenSeedings] = useState([]);
  const [seedCost, setSeedCost] = useState(0);

  // templates
  const appliedTemplateYears = [
    { name: '2021', value: '2021' },
    { name: '2020', value: '2020' },
    { name: '2019', value: '2019' },
    { name: '2018', value: '2018' },
    { name: '2017', value: '2017' },
    { name: '2016', value: '2016' },
    { name: '2015', value: '2015' },
    { name: '2014', value: '2014' },
    { name: '2013', value: '2013' },
    { name: '2012', value: '2012' },
    { name: '2011', value: '2011' },
    { name: '2010', value: '2010' },
  ];
  const [appliedTemplateYear, setAppliedTemplateYear] = useState(2021);

  const [open, setOpen] = useState(false);
  const [templateMode, setTemplateMode] = useState(false);
  const [yearDisplay, setYearDisplay] = useState(2020);
  const [currentTemplate, setCurrentTemplate] = useState();
  const [allTemplates, setAllTemplates] = useState();
  const [templatesLoading, setTemplatesLoading] = useState(false);

  const [progressModal, setProgressModal] = useState(false);
  const [layersProgress, setLayersProgress] = useState(0);
  const [currentLoadingLayer, setCurrentLoadingLayer] = useState('');
  const [loadNextTitle, setLoadNextTitle] = useState(false);

  const [currentTemplateName, setCurrentTemplateName] = useState();
  const [currentTemplateID, setCurrentTemplateID] = useState();
  const [customTemplateName, setCustomTemplateName] = useState('');
  const [openSaveTemplate, setOpenSaveTemplate] = useState(false);
  // confirmation dialog for exit template
  const [openConfirm, setOpenConfirm] = useState(false);
  // confirmation dialog for create layers
  const [openConfirmLayers, setOpenConfirmLayers] = useState(false);
  const [selectedTemplate, setSelectedTemplate] = useState({
    ID: '',
    Name: '',
  });

  const [generatingPrompt, setGeneratingPrompt] = useState(false);

  /**
   * reports and totals calculations need harvest and seeding in this format
   * alternatively could have function that converts displayed operations
   * when needed rather than tracked in state
   */
  const [reportSeedings, setReportSeedings] = useState([]);

  // application operations
  const [applicationOperations, setApplicationOperations] = useState([]);
  const [displayApplicationOps, setDisplayApplicationOps] = useState([]);
  const [hiddenApplicationOps, setHiddenApplicationOps] = useState([]);
  const [dieselPrices, setDieselPrice] = useState([]);
  const [applicationCost, setApplicationCost] = useState(0);

  // Tillage and Field Passes cost
  const [fieldPasses, setFieldPasses] = useState([]);
  const [displayFieldPasses, setDisplayFieldPasses] = useState([]);
  const [hiddenFieldPasses, setHiddenFieldPasses] = useState([]);
  const [fieldPassCost, setFieldPassCost] = useState(0);
  const fieldPassTracker = useRef([]);

  const [tillageOperations, setTillageOperations] = useState([]);
  const [fieldPassOperations, setFieldPassOperations] = useState([]);

  // other costs
  const [otherCosts, setOtherCosts] = useState([]);
  const [displayOtherCosts, setDisplayOtherCosts] = useState([]);
  const [hiddenOtherCosts, setHiddenOtherCosts] = useState([]);
  const [otherCostsTotal, setOtherCostsTotal] = useState(0);

  // other revenue
  const [otherRevenue, setOtherRevenue] = useState([]);
  const [displayOtherRevenue, setDisplayOtherRevenue] = useState([]);
  const [hiddenOtherRevenue, setHiddenOtherRevenue] = useState([]);
  const [otherRevenueTotal, setOtherRevenueTotal] = useState(0);

  const [genericOperations, setGenericOperations] = useState([]);

  // profitmap
  const [profitMapAccess, setProfitMapAccess] = useState(true);
  const [profitMapSummary, setProfitMapSummary] = useState([]);
  const [profitMapResponse, setProfitMapResponse] = useState([]);
  const [profitMap, setProfitMap] = useState({});
  const [operationMap, setOperationMap] = useState({});
  const [elevationMap, setElevationMap] = useState({});
  const [highRes, setHighRes] = useState(false);
  const [savedTemplate, setSavedTemplate] = useState([]);
  const [profitMapIssues, setProfitMapIssues] = useState({
    isMissingOps: false,
    missingFiles: [],
    message: '',
  });
  const [displayIssues, setDisplayIssues] = useState(false);
  const [clipByBoundary, setClipByBoundary] = useState(true);

  const [reportName, setReportName] = useState('');
  const [newReportName, setNewReportName] = useState('');
  const [editReportName, setEditReportName] = useState(false);
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [mapLoading, setMapLoading] = useState(false);
  const [profitMapLoading, setProfitMapLoading] = useState(false)
  const [operationMapLoading, setOperationMapLoading]  = useState(false)
  const [elevationMapLoading, setElevationMapLoading] = useState(false)

  const [profitMapNeedsUpdating, setProfitMapNeedsUpdating] = useState(false);
  const [mapLoadedOnViewProfitLayers, setMapLoadedOnViewProfitLayers] = useState(false);

  // data sync
  const [dataSyncPopoverAnchor, setDataSyncPopoverAnchor] = useState(null);
  const [dataSyncPopoverOpen, setDataSyncPopoverOpen] = useState(false);
  const [reintegrateNecessary, setReintegrateNecessary] = useState(false);
  const [syncStatus, setSyncStatus] = useState('');

  // Actions
  const [actionsPopoverAnchor, setActionsPopoverAnchor] = useState(null);
  const [actionsPopoverOpen, setActionsPopoverOpen] = useState(false);

  // Costs and profits
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalCosts, setTotalCosts] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const nans = ['∞', 'NaN'];

  // Zone analysis response
  const [zoneAnalysisRes, setZoneAnalysisRes] = useState([]);
  const [isZoneAnalysisRes, setZoneAnalysisLoader] = useState(false);

  // Price tool
  const [priceToolOpen, setPriceToolOpen] = useState(false);
  const [priceToolStartingOp, setPriceToolStartingOp] = useState('Seeding');

  const [saving, setSaving] = useState(false);
  const [processing, setProcessing] = useState(false);

  const [claimField, setClaimField] = useState(false);

  // npk values
  const [NPK, setNPK] = useState({});

  // PDF
  const [scatterLoaded, setScatterLoaded] = useState(true);
  const [generatePdf, setGeneratePdf] = useState(false);

  // Profit Map error message
  const [errorMessage, setErrorMessage] = useState(null);

  // template dropdown
  const [selectedProfitTemplate, setSelectedProfitTemplate] = useState('');
  const [templateRes, setTemplateRes] = useState([]);
  const [legendBinColors, setLegendBinColors] = useState(null);
  const [legendBinRanges, setLegendBinRanges] = useState(null);

  const [showSubscribe, setShowSubscribe] = useState(null);

  // Order ID and SubOrder ID for integrating soil test response within profitlayers
  const [orderId, setOrderId] = useState('');
  const [subOrderId, setSubOrderId] = useState('');
  const [filePaths, setFilePaths] = useState([]);

  const [isSaving, setIsSaving] = useState(false);

  // State to handle manual update of netSeededAcre
  const [manuallyUpdated, setManuallyUpdated] = useState(false);

  const operationTypes = [
    'Harvest Revenues',
    'Seed Costs',
    'Chemical and Fertilizer Costs',
    'Cost of Field Passes',
    'Other Costs',
    'Other Revenues',
  ];

  const precisionSources = ['deere', 'climate', 'cnh'];

  const years = [];
  const currentYear = new Date().getFullYear();
  for (let i = currentYear + 1; i >= currentYear - 10; i--) {
    years.push(i);
  }
  // #endregion

  // #region - useEffects
  // ensure navbar has correct section
  useEffect(() => {
    setSection(0);
  }, []);

  useEffect(() => {
    openInfoModal();
  }, [user]);

  useEffect(() => {
    if (plStep === 1) {
      setValue(1);
    }
  }, [plStep]);

  useEffect(() => {
    if (currentTemplate !== undefined) {
      cleanTemplateYear(appliedTemplateYear);
    }
  }, [appliedTemplateYear, currentTemplate]);

  useEffect(() => {
    if (user.isAuthenticated) {
      setAuthenticated(true);
    } else {
      setReportName('Demo Report');
    }
    if (user.new) {
      setNewUser(true);
      if (field.id !== '') {
        // start saving fields
      } else {
        enqueueSnackbar('Please draw or select a field on map to get started');
      }
    }
  }, [user, fieldData.selectedField]);

  // When user selects a field, update the field variable to show this
  // Also check whether the user has integrated with this organization to decide whether to show integration prompt when visiting Setup ProfitLayers (NOTE: This prompt does not even seem to be used anymore... seems Instructions was replaced by TemplatePrompt)
  useEffect(() => {
    // console.log('fieldData.selectedField :>> ', fieldData.selectedField);
    setField(fieldData.selectedField);

    if (exists(user) && exists(user.organizations)) {
      const currentOrg = user.organizations.filter(
        (x) => x.ID === fieldData.selectedField.orgId,
      );

      if (
        exists(currentOrg[0])
        && precisionSources.includes(currentOrg[0].source.toLowerCase())
      ) {
        setIntegrated(true);
      }
    }
  }, [fieldData.selectedField, user]);

  useEffect(() => {
    if (
      profitMapIssues.message !== ''
      || profitMapIssues.missingFiles.length > 0
    ) {
      setDisplayIssues(true);
    }
  }, [profitMapIssues]);

  useEffect(() => {
    const combinedOps = [...fieldPassOperations, ...tillageOperations];
    setFieldPasses(combinedOps);
  }, [fieldPassOperations, tillageOperations]);

  useEffect(() => {
    if (
      netSeededAcres > 0
      && loaded.includes('Generic')
      && (harvests.length > 0
        || seedings.length > 0
        || applicationOperations.length > 0)
    ) {
      // console.log("adjusting acres")
      const adjusted = acreageAdjustment(fieldPasses, netSeededAcres);
      setFieldPasses(adjusted);
    }
  }, [harvests, seedings, applicationOperations, netSeededAcres, loaded]);

  useEffect(() => {
    setLoaded([]);
    operationsLoaded.current = [];
    resetState();
    if (field && exists([field.orgId, field.id])) {
      getAccess(field.orgId, field.id);
      getSource(field.orgId);
      getLastSyncDate(field.orgId, field.id);
      getOrgSyncDate(field.orgId);
      getBoundarySyncDate(field.orgId, field.id);
      // get harvest years to find most recent year, which will be used to get all operations
      getHarvestYears(field.orgId, field.id);
      const date = new Date();
      setReportName(
        `${
          field.name
        }- ${date.getMonth()}/${date.getDay()}/${date.getFullYear()}`,
      );
      getOrderInfo(field.orgId, field.id);
    }
  }, [field]);

  useEffect(() => {
    let displayed = [];
    if (harvests.length > 0) {
      displayed = harvests.filter((op) => !op.hidden);
      const hidden = harvests.filter((op) => op.hidden);
      setDisplayedHarvests(displayed);
      setHiddenHarvests(hidden);
    } else {
      setDisplayedHarvests([]);
      setHiddenHarvests([]);
    }
  }, [harvests]);

  useEffect(() => {
    if (open) {
      setTemplateMode(true);
      // clear operations
      clearOperations();
      // template mode loading functions are called in Planting.js
      setOpTabValue(0);
    }
  }, [open]);

  useEffect(() => {
    const varieties = convertPlantings(displayedHarvests);
    const rev = calc.getTotal(varieties, perAcre, netSeededAcres);
    setHarvestRevenue(rev);
  }, [displayedHarvests, perAcre, netSeededAcres]);

  useEffect(() => {
    let displayed = [];
    if (seedings.length > 0) {
      displayed = seedings.filter((op) => !op.hidden);
      const hidden = seedings.filter((op) => op.hidden);
      setDisplayedSeedings(displayed);
      setHiddenSeedings(hidden);
    } else {
      setDisplayedSeedings([]);
      setHiddenSeedings([]);
    }
    // updateFieldPasses(displayed, "Seeding Costs");
  }, [seedings]);

  useEffect(() => {
    const varieties = convertPlantings(displayedSeedings);
    const cost = calc.getTotal(varieties, perAcre, netSeededAcres) * -1;
    setSeedCost(cost);
  }, [displayedSeedings, perAcre, netSeededAcres]);

  useEffect(() => {
    if (applicationOperations.length > 0) {
      const { displayed, hidden } = getDisplayOps(applicationOperations);
      setDisplayApplicationOps(displayed);
      setHiddenApplicationOps(hidden);
      const cost = calc.getApplicationTotal(displayed, perAcre, netSeededAcres) * -1;
      setApplicationCost(cost);
    } else {
      setDisplayApplicationOps([]);
      setHiddenApplicationOps([]);
      setApplicationCost(0);
    }
  }, [applicationOperations, perAcre, netSeededAcres]);

  useEffect(() => {
    if (fieldPasses.length > 0) {
      // const { displayed, hidden } = getDisplayOps(fieldPasses);

      const hiddenFp = fieldPasses.filter((x) => x.isDeleted === true);
      const displayFp = fieldPasses.filter((x) => x.isDeleted === false);
      // console.log("Setting displayed field passes")
      setHiddenFieldPasses(hiddenFp);
      setDisplayFieldPasses(displayFp);
      const cost = calc.getFieldPassTotal(displayFp, perAcre, netSeededAcres) * -1;
      setFieldPassCost(cost);
    } else {
      setDisplayFieldPasses([]);
      setHiddenFieldPasses([]);
      setFieldPassCost(0);
    }
  }, [fieldPasses, perAcre, netSeededAcres]);

  useEffect(() => {
    if (otherCosts && otherCosts.length > 0) {
      // console.log('otherCosts', otherCosts)
      const { displayed, hidden } = getDisplayOps(otherCosts);
      setDisplayOtherCosts(displayed);
      setHiddenOtherCosts(hidden);
      const cost = calc.getOtherTotal(displayed, perAcre, netSeededAcres) * -1;
      setOtherCostsTotal(cost);
    } else {
      setDisplayOtherCosts([]);
      setHiddenOtherCosts([]);
      setOtherCostsTotal(0);
    }
  }, [otherCosts, perAcre, netSeededAcres]);

  useEffect(() => {
    if (otherRevenue && otherRevenue.length > 0) {
      const { displayed, hidden } = getDisplayOps(otherRevenue);
      setDisplayOtherRevenue(displayed);
      setHiddenOtherRevenue(hidden);
      const rev = calc.getOtherTotal(displayed, perAcre, netSeededAcres);
      setOtherRevenueTotal(rev);
    } else {
      setDisplayOtherRevenue([]);
      setHiddenOtherRevenue([]);
      setOtherRevenueTotal(0);
    }
  }, [otherRevenue, perAcre, netSeededAcres]);

  useEffect(() => {
    getOperationTotals();
  }, [
    seedCost,
    applicationCost,
    fieldPassCost,
    otherCostsTotal,
    harvestRevenue,
    otherRevenueTotal,
  ]);

  useEffect(() => {
    if (netSeededAcres > 0 && fieldPasses.length > 0) {
      const adjusted = acreageAdjustment(fieldPasses, netSeededAcres);
      setFieldPasses(adjusted);
    }
  }, [netSeededAcres]);

  useEffect(() => {
    if (templateRes.length > 0) {
      setSavedTemplate(templateRes);
    }
  }, [templateRes]);

  useEffect(() => {
    if (
      loaded.includes('Seeding')
      && loaded.includes('Harvest')
      && loaded.includes('Applications')
      && loaded.includes('Generic')
      && loaded.includes('Tillage')
      && selectedYears.length > 0
    ) {
      const fp = genericOperations.filter(
        (x) => x.aux1 === 'Field Pass' && selectedYears.includes(x.cropSeason),
      );
      const generated = fp.filter(
        (x) => x.productName.includes('Application Costs')
          || x.productName.includes('Seeding Costs')
          || x.productName.includes('Harvesting Costs'),
      );
      // When changing this code, be aware that you should probably also modify UpdateOther.js
      // Find -- setModifiable(false); -- That code is based off of this.
      // console.log("GENERATED FIELD PASSES", generated)
      if (generated.length < 3 && !templateMode) {
        // eslint-disable-next-line max-len
        if (
          fieldPassTracker.current.filter(
            (x) => parseInt(x) === selectedYears[0]
              || x === selectedYears[0].toString()
              || x === selectedYears[0],
          ).length === 0
        ) {
          // console.log("Create field passes YEAR:", selectedYear, selectedYears)
          fieldPassTracker.current.push(selectedYears[0]);
          createFieldPasses();
        }
      }
      // else if(fieldPasses.length > 0){
      //   //console.log("adjusting for acreage")
      //   //let corrected = acreageAdjustment(fpCopy, applicationOperations, netSeededAcres)
      //   setFieldPasses(fpCopy)

      // }
      if (profitMapSummary.length === 0 && profitMapResponse.length === 0) {
        getProfitMapSummary(field.orgId, field.id, selectedYears[0]);
        getProfitMapResponse(field.orgId, field.id, selectedYears[0]);
      }
    }
  }, [loaded, genericOperations, selectedYear, selectedYears]);

  // ------------------TEMPLATES---------------------
  useEffect(() => {
    if (currentTemplate !== '') {
      getCurrentTemplateName();
      prepareTemplateOperations();
    }
  }, [currentTemplate, allTemplates]);

  useEffect(() => {
    // if user just integrated / re-integrated then open modal
    // with info and status
    if (integrating) {
      // alertETLstatus()
      setIntegrationStatusOpen(true);
    }
  }, [integrating]);

  useEffect(() => {
    // eslint-disable-next-line max-len
    // console.log("effect", harvests, seedings)
    if (!manuallyUpdated) {
      const summaryAcres = profitMapSummary && profitMapSummary.length > 0
        ? profitMapSummary[0].netSeededAcre
        : 0;
      const fieldAcres = calc.calculateNetSeededAcres(field, seedings, harvests);
      const acres = summaryAcres !== 0 && summaryAcres <= fieldAcres
        ? summaryAcres
        : fieldAcres;
      setNetSeededAcres(acres);
    }
  }, [seedings, harvests, profitMapSummary]);
  // #endregion

  // #region - functions
  const openInfoModal = async () => {
    try {
      await sleep(1000);
      const viewed = sessionStorage.getItem('viewed');
      const userHasSeen = JSON.parse(viewed);
      const seenPL = userHasSeen && userHasSeen.profitLayersInfo !== undefined && userHasSeen.profitLayersInfo
      if (!seenPL && width > mobileBreakPoint && user?.isAuthenticated) {
        setInfoModalOpen(true);
      }
    } catch (err) {
      console.error(err);
    }
  };

  // ETL status notifications trigger
  // useEffect(()=>{
  //   if(lastSyncDate != ""){
  //     alertETLstatus();
  //   }
  // }, [lastSyncDate])

  const alertETLstatus = async (triggerRepeat = true) => {
    const status = await df.getETLStatus();
    // inject data for testing flows
    // status[0].statusDescription = "Processing"
    const currDateObj = new Date();
    const yesterday = new Date();
    yesterday.setDate(currDateObj.getDate() - 1);

    const updating = status.filter((x) => new Date(x.updatedAt) >= yesterday);
    updating.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
    // console.log(updating)

    if (updating.length == 0) {
      // if none currently updating then end here
      return;
    }

    // set other items
    const source = updating[0].issuer;
    const { flag } = updating[0];

    if (source !== integrationSource) {
      setIntegrationSource(source);
    }
    if (flag !== integrationFlag) {
      setIntegrationFlag(flag);
    }

    // lets begin constructing notification
    const baseMessage = '';
    const { statusDescription } = updating[0];
    let extraDetails = '';
    const today = new Date();

    // lets format the created date and last updated date
    const updatedDateObj = new Date(updating[0].updatedAt);
    let updatedOn = String(updatedDateObj);
    let sliceNdx = updatedOn.indexOf(':');
    updatedOn = updatedOn.slice(0, sliceNdx - 2);

    let createdOn = String(updatedDateObj);
    sliceNdx = createdOn.indexOf(':');
    createdOn = createdOn.slice(0, sliceNdx - 2);

    // let flag = 3
    // eslint-disable-next-line default-case
    switch (flag) {
      case 1:
        extraDetails = ` | ${updatedOn}`;
        break;
      case 3:
        extraDetails = ` | ${createdOn}`;
        break;
      case 4:
        extraDetails = ` | ${createdOn}`;
        break;
      case 5:
        extraDetails = ` | ${createdOn}`;
        break;
      case 6:
        extraDetails = ` | ${createdOn}`;
        break;
      case 7:
        extraDetails = ` | ${createdOn}`;
        break;
    }

    const finalMessage = baseMessage + statusDescription + extraDetails;
    setIntegrationStatus(finalMessage);

    if (flag >= 3 && flag <= 7) {
      // if the flag is somewhere in the processing stage
      if (triggerRepeat) {
        repeatNotifications(5, 1, 3); // send max of 5 notifications, counter starts at 1 (1,2,3,4,5)
      }
    }
    return flag;
  };

  const repeatNotifications = (maxAlerts, currAlertNum, flag) => {
    if (flag < 3 || flag > 7) {
      // has etl completed?
      return 'done';
    }
    // still processing, send heartbeat
    setTimeout(async () => {
      flag = await alertETLstatus(false); // so we dont trigger an infinite loop
      repeatNotifications(maxAlerts, currAlertNum + 1, flag);
    }, 10000);
  };

  //----------------------------------------------------
  const resetState = () => {
    setLoaded([]);
    // setSource("");
    setNetSeededAcres(0);
    // setSelectedYear(new Date().getFullYear());
    // setSelectedStartYear(new Date().getFullYear());
    setPerAcre(true);
    // loaded operations
    // setLastSyncDate("");
    // setLastSyncTime("");
    // setOrgSyncDate("");
    // setOrgSyncTime("");
    // setBoundarySyncDate("");
    // setBoundarySyncTime("");

    setHarvestYears([]);
    setHarvestOperations([]);
    setDisplayHarvestOps([]);
    setHiddenHarvestOps([]);
    setHarvests([]);
    setDisplayedHarvests([]);
    setHiddenHarvests([]);
    setHarvestRevenue(0);

    setSeedingOperations([]);
    setDisplaySeedingOps([]);
    setHiddenSeedingOps([]);
    setSeedings([]);
    setDisplayedSeedings([]);
    setHiddenSeedings([]);
    setSeedCost(0);
    setReportSeedings([]);

    setApplicationOperations([]);
    setDisplayApplicationOps([]);
    setHiddenApplicationOps([]);
    setDieselPrice([]);
    setApplicationCost(0);

    setGenericOperations([]);

    setFieldPasses([]);
    setDisplayFieldPasses([]);
    setHiddenFieldPasses([]);
    setFieldPassCost(0);

    setOtherCosts([]);
    setDisplayOtherCosts([]);
    setHiddenOtherCosts([]);
    setOtherCostsTotal(0);

    setOtherRevenue([]);
    setDisplayOtherRevenue([]);
    setHiddenOtherRevenue([]);
    setOtherRevenueTotal(0);

    // setProfitMapAccess(false);
    setProfitMapSummary([]);
    setProfitMapResponse([]);
    setProfitMap({});
    setOperationMap({});
    setElevationMap({});

    setZoneAnalysisRes([]);

    setManuallyUpdated(false);
  };

  const clearOperations = () => {
    setHarvests([]);
    setSeedings([]);
    setApplicationOperations([]);
    setFieldPasses([]);
    setOtherCosts([]);
    setOtherRevenue([]);
  };

  const hasOperations = () => {
    if (
      loaded.includes('Seeding')
      && loaded.includes('Harvest')
      && loaded.includes('Applications')
      && loaded.includes('Generic')
      && [
        ...seedings,
        ...harvests,
        ...applicationOperations,
        ...fieldPasses,
        ...otherCosts,
        ...otherRevenue,
      ].length === 0
    ) {
      return false;
    }
    return true;
  };

  const cleanTemplateYear = (
    year = selectedYears[0] + 1,
    fromNewOp = false,
  ) => {
    // keys = applicationOperations,genericOperations, harvestOperations, seedingOperations, tillageOperations
    // applications
    for (const application of currentTemplate.applicationOperations) {
      let date = application.endDate.slice(4);
      application.cropYear = year;
      application.cropSeason = year;
      application.endDate = year + date;

      date = application.startDate.slice(4);
      application.startDate = year + date;
    }

    for (const genericOperation of currentTemplate.genericOperations) {
      let date = genericOperation.endDate.slice(4);
      // application.cropYear = year;
      genericOperation.cropSeason = year;
      genericOperation.endDate = year + date;

      date = genericOperation.startDate.slice(4);
      genericOperation.startDate = year + date;
    }

    for (const harvestOperations of currentTemplate.harvestOperations) {
      let date = harvestOperations.endDate.slice(4);
      // application.cropYear = year;
      harvestOperations.cropSeason = year;
      harvestOperations.endDate = year + date;

      date = harvestOperations.startDate.slice(4);
      harvestOperations.startDate = year + date;
    }

    for (const seedingOperations of currentTemplate.seedingOperations) {
      let date = seedingOperations.endDate.slice(4);
      // application.cropYear = year;
      seedingOperations.cropSeason = year;
      seedingOperations.endDate = year + date;

      date = seedingOperations.startDate.slice(4);
      seedingOperations.startDate = year + date;
    }

    for (const tillageOperations of currentTemplate.tillageOperations) {
      let date = tillageOperations.endDate.slice(4);
      // application.cropYear = year;
      tillageOperations.cropSeason = year;
      tillageOperations.endDate = year + date;

      date = tillageOperations.startDate.slice(4);
      tillageOperations.startDate = year + date;
    }

    if (!fromNewOp) {
      prepareTemplateOperations();
    }
  };

  // This function gets all the operation data displayed in "Setup Profilayers"
  // Get data needed on year or field update
  const getData = (
    orgId,
    fieldId,
    year,
    years = selectedYears,
    updateFromPriceTool = false,
    refresh = false,
  ) => {
    // console.log("LOADED in GetData",loaded)
    getHarvestOperations(
      orgId,
      fieldId,
      year,
      years,
      updateFromPriceTool,
      refresh,
    );
    handleSeedingOperations(
      orgId,
      fieldId,
      year,
      years,
      updateFromPriceTool,
      refresh,
    );
    getApplicationOperations(
      orgId,
      fieldId,
      year,
      years,
      updateFromPriceTool,
      refresh,
    );
    getGenericOperations(
      orgId,
      fieldId,
      year,
      years,
      updateFromPriceTool,
      refresh,
    );
    getTillageOperations(orgId, fieldId, year, years);
    getDieselPrices(year);
  };

  const getOrderInfo = async (orgId, fieldId) => {
    try {
      const res = await df.getOrderDetails(orgId, fieldId);
      if (res) {
        setOrderId(res?.orderID);
        setSubOrderId(res?.subOrderID);

        if (res?.orderID !== '' && res?.subOrderID !== '') {
          getTiffFilePaths(res.orderID, res.subOrderID);
        }
      }
    } catch (err) {
      console.error('error getting order info', err);
    }
  };

  const getTiffFilePaths = async (orderId, subOrderId) => {
    try {
      const res = await df.getReportFiles(orderId, subOrderId);

      // console.log('getTiffFilePath res', res)
      const files = [];
      for (let i = 0; i < res.downloads.length; i++) {
        const fileName = res.downloads[i];
        if (
          fileName.slice(-4).toUpperCase() === '.TIF'
          || fileName.slice(-4).toUpperCase() === '.ZIP'
        ) {
          files.push(fileName);
        }
      }
      setFilePaths(files);
    } catch (err) {
      console.error('get file paths error', err);
    }
  };

  // get data needed on year or field update
  const asyncGetData = async (
    orgId,
    fieldId,
    year,
    years = selectedYears,
    updateFromPriceTool = false,
    refresh = false,
  ) => {
    const requests = [];
    requests.push(
      getHarvestOperations(
        orgId,
        fieldId,
        year,
        years,
        updateFromPriceTool,
        refresh,
      ),
    );
    requests.push(
      handleSeedingOperations(
        orgId,
        fieldId,
        year,
        years,
        updateFromPriceTool,
        refresh,
      ),
    );
    requests.push(
      getApplicationOperations(
        orgId,
        fieldId,
        year,
        years,
        updateFromPriceTool,
        refresh,
      ),
    );
    requests.push(
      getGenericOperations(
        orgId,
        fieldId,
        year,
        years,
        updateFromPriceTool,
        refresh,
      ),
    );
    requests.push(getTillageOperations(orgId, fieldId, year, years));
    requests.push(getDieselPrices(year));

    await Promise.allSettled(requests);
  };

  const getAccess = async (orgId, fieldId) => {
    /* Check if user has access to profit map. It is also possible to check at field level by passing fieldId
       If field has access, preprocess it.
    */
    setShowSubscribe(null);
    const access = await df.getProfitMapAccess(orgId);
    // setProfitMapAccess(false)
    setProfitMapAccess(access);
    setShowSubscribe(!access);

    if (access && exists(fieldId)) {
      const processed = await df.preprocessField(orgId, fieldId);
    }
  };

  const getSource = async (orgId) => {
    const dataSource = await df.getSource(orgId);
    setSource(dataSource);
  };

  /* Operations getters */
  const getHarvestYears = async (orgId, fieldId) => {
    /* Get all harvest years then use most recent to get harvest, seeding, and application operations */
    // resetState();
    const harvestYears = await df.getHarvestYears(orgId, fieldId);

    // Potential for invalid years, so fitler them out, then most recent if exists, if not use current year
    const validYears = harvestYears && harvestYears !== 'NOT_FOUND'
      ? harvestYears.filter(
        (year) => year.toString().length === 4 && !isNaN(year),
      )
      : [];
    const year = validYears.length > 0 ? validYears[0] : new Date().getFullYear();

    setHarvestYears(validYears);
    setSelectedYears([year]);
    getData(orgId, fieldId, year);
  };

  const getLastSyncDate = async (orgId, fieldId) => {
    const date = await df.getLastSyncDate(orgId, fieldId);

    const lastSyncDate = new Date(date).toLocaleDateString();
    const lastSyncTime = new Date(date).toLocaleTimeString();

    setLastSyncDate(lastSyncDate);
    setLastSyncTime(lastSyncTime);
  };

  const getOrgSyncDate = async (orgId) => {
    const date = await df.getOrgSyncDate(orgId);
    const lastSyncDate = new Date(date).toLocaleDateString();
    const lastSyncTime = new Date(date).toLocaleTimeString();

    setOrgSyncDate(lastSyncDate);
    setOrgSyncTime(lastSyncTime);
  };

  const getBoundarySyncDate = async (orgId, fieldId) => {
    const date = await df.getBoundarySyncDate(orgId, fieldId);

    const lastSyncDate = new Date(date).toLocaleDateString();
    const lastSyncTime = new Date(date).toLocaleTimeString();

    setBoundarySyncDate(lastSyncDate);
    setBoundarySyncTime(lastSyncTime);
  };

  const BinRange = async (orgId, range) => {
    df.getBinRange(orgId, range);
  };

  const getAllTemplates = async (range) => {
    df.getAllTemplates(range);
  };

  /**
   * Get field operation data then fitler for harvest operations to be
   * displayed and hidden. Set all harvets operations, displayed,
   * and hidden. Then get crop prices.
   * Year column is from ProfitTool Schema.
   * Use this to check if isDeleted needs stuff to happen.
   */
  const getHarvestOperations = async (
    orgId,
    fieldId,
    year,
    years,
    updateFromPriceTool,
    refresh = false,
  ) => {
    const harvestOps = await df.getFieldOperations(
      orgId,
      fieldId,
      'HARVEST',
      year,
      years,
      updateFromPriceTool,
    );

    if (updateFromPriceTool && harvestOps.length > 0) {
      const request = {
        FieldOperationList: harvestOps,
        Year: year,
      };
      // console.log('req', request)
      request.FieldOperationList.map((op) => {
        op.FileType = 'Harvest';
        op.OperationID = op.harvestOperationID;
        op.LogID = op.harvestLoggedID;
        op.Variety = op.name;
      });
      const res = await df.saveOperations(orgId, fieldId, request);
      getHarvestOperations(orgId, fieldId, year, years, false, false);
    } else if (harvestOps && harvestOps.length > 0) {
      // console.log("YEAR IN GETHARVEST",year)
      const harvestOperations = await cleanHarvest(harvestOps, year, years);
      const combinedOps = combineOps(harvestOperations);
      setHarvestOperations(harvestOperations);

      // console.log("HARVESTS", combinedOps.sort((a, b) => new Date(b.date) - new Date(a.date)))
      setHarvests(
        combinedOps.sort((a, b) => new Date(b.date) - new Date(a.date)),
      );
    }

    if (!refresh) {
      operationsLoaded.current = [...operationsLoaded.current, 'Harvest'];
      setLoaded((prev) => [...prev, 'Harvest']);
    }
  };

  /**
   * First time a user loads fields we will bring in operations for years
   * before and after selected year. These will be set to hidden initially
   */
  const determineInitialHide = (op, year, years = []) => {
    // console.log("selectedYears", selectedYears)
    const yearsToInclude = [...years, year];
    for (let i = 0; i < yearsToInclude.length; i++) {
      yearsToInclude[i] = +yearsToInclude[i];
    }

    if (op.source !== 'TEMPLATE') {
      if (
        op.isDeleted === false
        && (yearsToInclude.includes(op.year)
          || yearsToInclude.includes(new Date(op.endDate).getFullYear()))
      ) {
        return false;
      }
      return true;
    }
  };

  const confirmSaveTemplate = () => (
    <Box
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        top: '30%',
        zIndex: 1010,
        background: '#ffffff',
        width: '300px',
      }}
      boxShadow={2}
      py={1}
    >
      <Box display="flex" justifyContent="space-between">
        <Box />
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpenSaveTemplate(false)}
        />
      </Box>
      <Box p={1} fontWeight={500}>
        Do You Want to Save and Apply Your Template? (Yes - save and apply, No -
        save)
      </Box>

      <Box p={1} display="flex" justifyContent="space-around">
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: '0 25px' }}
          onClick={saveAndApplyTemplate}
        >
          Yes
        </Button>
        <Button
          variant="contained"
          color="primary"
          // onClick={cancelExit}
          disableElevation
        >
          No
        </Button>
      </Box>
    </Box>
  );

  const modifyOperations = (ops, year) => {
    if (ops && ops.length > 0) {
      const showoperations = ops.filter(
        (op) => (selectedYears.includes(op.cropSeason)
            || year === new Date(op.endDate).getFullYear()
            || op.cropSeason === year)
          && op.isDeleted === false,
      );

      const hidden = ops.filter((op) => !showoperations.includes(op));

      for (let i = 0; i < hidden.length; i++) {
        hidden[i].isDeleted = true;
      }

      return [...showoperations, ...hidden];
    }
  };

  const handleTemplateChange = async (e) => {
    const { value } = e.target;
    setCurrentTemplateID(value);
    const operations = await df.getTemplateOperations(value);
    // console.log('operations', operations)
    setCurrentTemplate(operations);
    setCurrentTemplateName(getCurrentTemplateName);
    // handleClose()
  };

  const handleExitBtn = () => {
    setOpenConfirm(true);
    setOpen(false);
    // getData(field.orgId, field.id, selectedYears[0], selectedYears, false, true)
  };

  const cancelExit = () => {
    setOpenConfirm(false);
  };

  const confirmationPopup = () => (
    <Box
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        top: '30%',
        zIndex: 1010,
        background: '#ffffff',
        width: '300px',
      }}
      boxShadow={2}
      py={1}
    >
      <Box display="flex" justifyContent="space-between">
        <Box />
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpenConfirm(false)}
        />
      </Box>
      <Box p={1} fontWeight={500}>
        Are you sure you want to close without saving?
      </Box>

      <Box p={1} display="flex" justifyContent="space-around">
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: '0 25px' }}
          onClick={handleClose}
        >
          Close
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={cancelExit}
          disableElevation
        >
          Cancel
        </Button>
      </Box>
    </Box>
  );

  const prepareTemplateOperations = async () => {
    setSeedings([]);
    setSeedingOperations([]);
    setHarvests([]);
    setHarvestOperations([]);
    setGenericOperations([]);
    setApplicationOperations([]);
    const data = currentTemplate;

    if (data !== undefined) {
      // lets pretend that this is data on an templates operations
      // state vars getting set: HarvestOperations		Harvest			SeedingOperations		Seedings			ApplicationOperations
      // GenericOperations	FieldPasses				OtherCosts			OtherRevenue
      // Modify Data for seeding operations display

      const seedingOperations = await cleanSeeding(data.seedingOperations);
      for (const seeding of seedingOperations) {
        seeding.rate = seeding.quantity / field.acres;
        // seeding.name = "DKC64-35";
        // seeding.variety = "DKC64-35";
        if (seeding.unit === 'seeds') {
          // no fractional seeds
          seeding.rate = Number(seeding.rate.toFixed(0));

          if (seeding.rate >= 1000) {
            // convert seeds to k seeds
            seeding.unit = 'k seeds';
            seeding.averageMaterialTarget /= 1000;
            seeding.rate /= 1000;
            seeding.quantity /= 1000;
            seeding.price = Number(numFormat(seeding.price * 1000, 0, 5));
          }
        }
      }
      // console.log("seeding operations: ", seedingOperations);
      const combinedOps = combineOps(seedingOperations);
      setSeedingOperations(seedingOperations);
      setSeedings(
        combinedOps.sort((a, b) => new Date(b.date) - new Date(a.date)),
      );

      // Modify Data for Harvest operations display
      const harvestOperations = await cleanHarvest(data.harvestOperations);
      for (const harvest of harvestOperations) {
        harvest.rate = harvest.quantity / field.acres;
        // harvest.name = "DKC64-35";
        // harvest.variety = "DKC64-35";
      }
      const combineHardOps = combineOps(harvestOperations);
      setHarvestOperations(harvestOperations);

      setHarvests(
        combineHardOps.sort((a, b) => new Date(b.date) - new Date(a.date)),
      );

      // Modify data for application operations template display
      const apps = data.applicationOperations.map((x) => ({
        ...x,
        totalMaterialResultUnit: x.unit,
        areaResult: field.acres,
      }));

      const applications = await cleanApplication(apps);

      const singleApplications = applications.filter((x) => x.tankMix === 0);
      const mixed = applications.filter((x) => x.tankMix !== 0);

      const tankMixes = combineApplications(mixed);
      const applicationOps = [...singleApplications, ...tankMixes];
      applicationOps.sort((a, b) => new Date(a.date) - new Date(b.date));
      setApplicationOperations(applicationOps);

      // Modify data for generic operations
      const formattedGen = data.genericOperations.map((x) => ({
        ...x,
        fileType: 'Generic',
        logID: 0,
        id: x.genericOperationID,
        cellsize: '0.0001',
        date: formatDate(x.endDate),
        area: field.acres,
        productName: x.name,
        price: x.price === null ? 0 : x.price,
        isDeleted: false,
      }));

      const formattedTillage = data.tillageOperations.map((x) => ({
        ...x,
        fileType: 'Tillage',
        logID: 0,
        id: x.tillageOperationID,
        cellsize: '0.0001',
        date: formatDate(x.endDate),
        area: field.acres,
        productName:
          `Tillage (${x.targetAverageDepth} ${x.targetAverageDepthUnit})`
          + ` - ${formatDate(x.endDate)}`,
        price: x.price === null ? 0 : x.price,
        isDeleted: false,
      }));

      const fp = formattedGen.filter(
        (x) => x.aux1.toUpperCase() === 'FIELDPASS',
      );

      const combinedGenerics = combineGenericOperations(formattedGen);

      for (const op of combinedGenerics) {
        op.isDeleted = true;
      }

      setGenericOperations(combinedGenerics);

      const costs = combinedGenerics.filter(
        (x) => x.aux1.toUpperCase() === 'COST',
      );
      const revs = combinedGenerics.filter(
        (x) => x.aux1.toUpperCase() === 'REVENUE',
      );

      setFieldPasses([...fp, ...formattedTillage]);
      setOtherCosts(costs);
      setOtherRevenue(revs);

      // const tillageOps
    }
  };

  const handleCancelLayers = () => {
    setOpenConfirmLayers(false);
  };
  const confirmGenerateLayers = () => (
    <Box
      style={{
        position: 'absolute',
        left: 0,
        right: 0,
        marginLeft: 'auto',
        marginRight: 'auto',
        textAlign: 'center',
        top: '30%',
        zIndex: 1010,
        background: '#ffffff',
        width: '300px',
      }}
      borderRadius="borderRadius"
      boxShadow={2}
      py={1}
    >
      <Box display="flex" justifyContent="space-between">
        <Box />
        <HighlightOffIcon
          className={classes.close}
          onClick={handleCancelLayers}
        />
      </Box>
      <Box mt={1} p={1} fontWeight={500}>
        All of the operation layers in the template will be created and added to
        your operation for the selected year. You can edit and remove the layers
        in the next screen
      </Box>

      <Box p={1} display="flex" justifyContent="space-around">
        <Button
          variant="outlined"
          color="primary"
          style={{ margin: '0 25px' }}
          onClick={handleCancelLayers}
        >
          Cancel
        </Button>
        <Button
          variant="contained"
          color="primary"
          onClick={handleGenProfitLayerTemplate}
          disableElevation
        >
          Confirm
        </Button>
      </Box>
    </Box>
  );

  const cleanHarvest = async (ops, year, years) => {
    /* If harvest operation has no price, get price from
       getIndividualCropPrice. Format operation object in way expected for
       Profit Map and further components.
    */

    const harvestOperations = [];
    for (const op of ops) {
      if (
        (op.price === null || op.price === 0)
        && exists(op.cropName)
        && exists(op.yieldUnit)
      ) {
        const crop = await df.getIndividualCropPrice(
          op.cropName,
          op.yieldUnit,
          op.cropSeason,
        );
        op.price = crop;
      }

      const operation = formatHarvest(op, year, years);
      harvestOperations.push(operation);
    }

    return harvestOperations;
  };

  // create keys from existing value that can be consistent with seedings
  const formatHarvest = (op, year, years) => ({
    ...op,
    area: op.area === undefined ? field.acres : op.area,
    attribute: 'VRYIELDVOL',
    cellsize: '0.0001',
    date: formatDate(op.endDate),
    fileType: 'Harvest',
    isDeleted: determineInitialHide(op, year, years),
    logID: op.harvestLoggedID === undefined ? 0 : op.harvestLoggedID,
    operationCost: op.area > 0 ? (op.yield * op.price) / op.area : 0,
    operationID: op.harvestOperationID,
    price: Math.round((op.price + Number.EPSILON) * 100) / 100,
    productName: op.cropName,
    quantity: op.yield === undefined ? field.acres * op.averageYield : op.yield,
    rate: +(op.yield / op.area).toFixed(2),
    unit: op.yieldUnit,
    variety: op.name,
    uid: Math.random().toString(36).substr(2, 9),
  });

  const handleSeedingOperations = async (
    orgId,
    fieldId,
    year,
    years,
    updateFromPriceTool,
    refresh = false,
  ) => {
    const seedings = await getSeedingOperations(
      orgId,
      fieldId,
      field.acres,
      year,
      years,
      updateFromPriceTool,
      setSeedingOperations,
    );

    setSeedings(seedings);

    if (!refresh) {
      operationsLoaded.current = [...operationsLoaded.current, 'Seeding'];
      setLoaded((prev) => [...prev, 'Seeding']);
    }
  };

  const cleanSeeding = async (ops, year, years) => {
    /* If seeding operation has no price, get price from
       getIndividualSeedPrice. Format operation object in way expected for
       Profit Map and further components.
    */
    const seedingOperations = [];

    for (const op of ops) {
      if (op.price === null || op.price === 0) {
        if (exists(op.cropName) && exists(op.totalMaterialUnit)) {
          // get price if name and unit are available
          const crop = await df.getIndividualSeedPrice(
            op.cropName,
            op.totalMaterialUnit,
          );

          // PRICE RETURNED FOR SEEDS ARE FOR 1K SEEDS NEED TO DIVIDE
          const seedPrice = crop / 1000;
          op.price = seedPrice;
        } else {
          op.price = 0;
        }
      }

      const operation = formatSeeding(op, year, years);
      seedingOperations.push(operation);
    }
    return seedingOperations;
  };

  // create keys from existing value that can be consistent with seedings
  const formatSeeding = (op, year, years) => ({
    ...op,
    area: op.area === undefined ? field.acres : op.area,
    uid: Math.random().toString(36).substr(2, 9),
    logID: op.seedingLoggedID === undefined ? 0 : op.seedingLoggedID,
    operationID: op.seedingOperationID,
    fileType: 'SEEDING',
    attribute:
      op.averageMaterialResult === null || op.averageMaterialResult === 0
        ? 'TargetRate'
        : 'AppliedRate',
    quantity:
      op.totalMaterial === undefined
        ? field.acres * op.averageMaterialTarget
        : op.totalMaterial,
    date: op.endDate !== undefined ? formatDate(op.endDate) : op.date,
    cellsize: '0.0001',
    isDeleted: determineInitialHide(op, year, years),
    rate:
      op.totalMaterialUnit === 'seeds'
        ? +(op.totalMaterial / op.area).toFixed(0)
        : +(op.totalMaterial / op.area).toFixed(2),
    unit: op.totalMaterialUnit,
    productName: op.cropName,
    variety: op.name,
    operationCost: op.area > 0 ? (-op.totalMaterial * op.price) / op.area : 0,
  });

  const combineGenericOperations = (formattedGen) => {
    const combinedGenerics = [];
    // combine generics with same operation ID for new zone setup
    for (const generic of formattedGen) {
      const opId = generic.genericOperationID;
      const exists = combinedGenerics.filter(
        (x) => x.genericOperationID === opId,
      );

      if (exists.length === 0) {
        const matching = formattedGen.filter(
          (x) => x.genericOperationID === opId,
        );
        const toplevelGen = {
          ...generic,
          zones: [],
        };

        for (const match of matching) {
          toplevelGen.zones.push(match);
        }
        // calculate top level total and rates
        const opTotal = matching
          .map((x) => x.total)
          .reduce((prev, next) => prev + next, 0);
        const areaTotal = matching
          .map((x) => x.area)
          .reduce((prev, next) => prev + next, 0);
        const opRate = (opTotal / areaTotal).toFixed(2);

        toplevelGen.area = areaTotal;
        toplevelGen.total = opTotal;
        toplevelGen.rate = opRate;
        toplevelGen.price = matching[0].price;
        combinedGenerics.push(toplevelGen);
      }
    }
    // console.log('combinedGenerics', combinedGenerics)
    return combinedGenerics;
  };

  const getGenericOperations = async (
    orgId,
    fieldId,
    year,
    years,
    updateFromPriceTool,
    refresh = false,
  ) => {
    const genericOps = await df.getFieldOperations(
      orgId,
      fieldId,
      'GENERIC',
      year,
      years,
      updateFromPriceTool,
    );

    if (updateFromPriceTool && genericOps.length > 0) {
      const request = {
        FieldOperationList: genericOps,
        Year: year,
      };
      request.FieldOperationList.map((op) => {
        op.FileType = 'Generic';
        op.OperationID = op.genericOperationID;
        op.LogID = op.genericLoggedID;
      });
      const res = await df.saveOperations(orgId, fieldId, request);
      getGenericOperations(orgId, fieldId, year, years, false);
    } else {
      try {
        const formattedGen = genericOps.map((x) => ({
          ...x,
          // productName: x.name,
          fileType: 'Generic',
          logID: x.genericLoggedID,
          id: x.operationID,
          cellsize: '0.0001',
          date: formatDate(x.endDate),
        }));

        const combinedGenerics = combineGenericOperations(formattedGen);

        for (const op of combinedGenerics) {
          op.isDeleted = determineInitialHide(op, year, years);
          op.operationID = op.genericOperationID;
        }

        setGenericOperations(combinedGenerics);

        let fp = combinedGenerics.filter((x) => x.aux1 === 'Field Pass');
        fp = fp.map((x) => ({ ...x, originalArea: x.area }));
        if (netSeededAcres > 0) {
          fp = acreageAdjustment(fp, netSeededAcres);
        }

        const costs = combinedGenerics.filter((x) => x.aux1 === 'Cost');
        const revs = combinedGenerics.filter((x) => x.aux1 === 'Revenue');

        // Block setting if user has switched to template mode before loading
        // console.log("FIELD PASSES >>",fp)
        setFieldPassOperations(fp);
        setOtherCosts(costs);
        setOtherRevenue(revs);

        if (!refresh) {
          operationsLoaded.current = [...operationsLoaded.current, 'Generic'];
          setLoaded((loaded) => [...loaded, 'Generic']);
        } else {
          return { costs, revenue: revs };
        }
      } catch (err) {
        console.log(err);
      }
    }
  };

  /**
   * adjusts the acreage value for field pass operations to update to the total
   * acreage covered by the operations (sum of acres)
   * @param {Array} fieldPassOps Field pass operations
   * @param {float} acres Acres
   * @returns {Array} copy of field passes
   */
  const acreageAdjustment = (fieldPassOps, acres) => {
    // make copy of fieldPasses
    // console.log('acreage adjustment', fieldPassOps)

    const types = ['Application Costs', 'Harvesting Costs', 'Seeding Costs'];
    const fieldPassCopy = [...fieldPassOps];
    // console.log("Field pass in adjustment", fieldPassCopy)
    for (const type of types) {
      let displayed = [];
      let totalArea = 0;
      let adjustmentFactor = 0;
      switch (type) {
        case 'Application Costs':
          displayed = applicationOperations.filter(
            (x) => !x.isDeleted && x.isIncluded,
          );
          break;
        case 'Harvesting Costs':
          displayed = harvests.filter((x) => !x.hidden);
          break;
        case 'Seeding Costs':
          displayed = seedings.filter((x) => !x.hidden);
          break;
      }

      if (type === 'Application Costs') {
        totalArea = displayed.map((x) => x.area).reduce((a, b) => a + b, 0);
        adjustmentFactor = totalArea / acres;
      } else {
        for (const op of displayed) {
          const opArea = op.varieties
            .map((x) => x.area)
            .reduce((a, b) => a + b, 0);
          totalArea += opArea;
        }
        adjustmentFactor = totalArea / acres;
      }

      const ndx = fieldPassCopy.findIndex(
        (x) => x.productName === `${type} - ${selectedYears[0]}`
          && x.cropSeason === selectedYears[0],
      );
      // console.log('displayed', displayed)
      let totalCost = 0;
      for (const operation of displayed) {
        // console.log(operation)
        let cost = 0;
        switch (type) {
          case 'Application Costs':
            if (operation.isIncluded) {
              // console.log(operation)
              cost = calc.getApplicationCost(operation, dieselPrices);
            }
            break;
          case 'Harvesting Costs':
            cost = operation.varieties
              .map((x) => calc.getHarvestCost(x, dieselPrices))
              .reduce((a, b) => a + b, 0);
            // cost = calc.getHarvestCost(operation, dieselPrices)
            break;
          case 'Seeding Costs':
            cost = operation.varieties
              .map((x) => calc.getSeedingCost(x, dieselPrices))
              .reduce((a, b) => a + b, 0);
            // cost = calc.getSeedingCost(operation, dieselPrices)
            break;
        }
        // console.log(type+" cost", cost)
        totalCost += cost;
      }

      // update values
      if (ndx >= 0) {
        fieldPassCopy[ndx].area = acres * adjustmentFactor;
        fieldPassCopy[ndx].price = fieldPassCopy[ndx].price === 0
          ? totalCost / acres
          : fieldPassCopy[ndx].price;
      }
    }

    return fieldPassCopy;
  };

  const createFieldPasses = async () => {
    const year = selectedYears[0];
    try {
      // total loaded displayed ops. If no ops dont create field passes
      const numOps = displayedHarvests.length
        + displayedSeedings.length
        + displayApplicationOps.length;
      if (numOps === 0) {
        // console.log("no ops for field passes")
        return;
      }

      const zone = JSON.parse(field.boundary);
      let date = new Date();
      date.setFullYear(selectedYears[0]);
      date = date.toLocaleDateString().split('/');
      date = [date[2], date[0], date[1]].join('-');

      // harvest cost
      let totalHarvestCost = 0;
      for (const harvest of displayedHarvests) {
        if (!harvest.hidden) {
          for (const variety of harvest.varieties) {
            const cost = calc.getHarvestCost(variety, dieselPrices);
            totalHarvestCost += cost;
          }
        }
      }

      const harvestZone = JSON.parse(JSON.stringify(zone));
      const harvestPass = {
        area: field.acres,
        isDeleted: false,
        productName: `Harvesting Costs - ${year.toString()}`,
        fileType: '',
        orgId: field.orgId,
        price: (totalHarvestCost / field.acres).toFixed(2),
        year: selectedYears[0],
        total: totalHarvestCost,
        date: date.toString(),
        unit: 'unit',
      };
      harvestPass.zones = [{ ...harvestPass, rate: 1, zone: harvestZone }];
      // console.log("harvest pass", harvestPass)

      // seeding cost
      let totalSeedingCost = 0;
      for (const seeding of displayedSeedings) {
        if (!seeding.hidden) {
          for (const variety of seeding.varieties) {
            const cost = calc.getSeedingCost(variety, dieselPrices);
            totalSeedingCost += cost;
          }
        }
      }

      const seedZone = JSON.parse(JSON.stringify(zone));
      const seedingPass = {
        area: field.acres,
        isDeleted: false,
        productName: `Seeding Costs - ${year.toString()}`,
        fileType: '',
        orgId: field.orgId,
        price: (totalSeedingCost / field.acres).toFixed(2),
        year: selectedYears[0],
        total: totalSeedingCost,
        date: date.toString(),
        unit: 'unit',
      };
      seedingPass.zones = [{ ...seedingPass, rate: 1, zone: seedZone }];

      // application cost
      let totalApplicationCost = 0;
      for (const application of displayApplicationOps) {
        if (!application.hidden) {
          const cost = calc.getApplicationCost(application, dieselPrices);
          totalApplicationCost += cost;
        }
      }

      const appZone = JSON.parse(JSON.stringify(zone));
      const applicationPass = {
        area: field.acres,
        isDeleted: false,
        productName: `Application Costs - ${year.toString()}`,
        fileType: '',
        orgId: field.orgId,
        price: (totalApplicationCost / field.acres).toFixed(2),
        year: selectedYears[0],
        total: totalHarvestCost,
        date: date.toString(),
        unit: 'unit',
      };
      applicationPass.zones = [{ ...applicationPass, rate: 1, zone: appZone }];

      const harvestReq = {
        Operation_Type: 'Generic',
        Operation_Record: {},
        GeoJSON: {},
      };
      harvestReq.Operation_Record = createOperationRecord(
        harvestPass,
        'Field Pass',
        [harvestZone],
      );
      harvestReq.GeoJSON = createOperationGeojson(
        harvestPass,
        [harvestZone],
        harvestReq.Operation_Record,
        'Field Pass',
        false,
      );
      // console.log(harvestReq.GeoJSON)

      const seedingReq = {
        Operation_Type: 'Generic',
        Operation_Record: {},
        GeoJSON: {},
      };
      seedingReq.Operation_Record = createOperationRecord(
        seedingPass,
        'Field Pass',
        [seedZone],
      );
      seedingReq.GeoJSON = createOperationGeojson(
        seedingPass,
        [seedZone],
        seedingReq.Operation_Record,
        'Field Pass',
        false,
      );

      const applicationReq = {
        Operation_Type: 'Generic',
        Operation_Record: {},
        GeoJSON: {},
      };
      applicationReq.Operation_Record = createOperationRecord(
        applicationPass,
        'Field Pass',
        [appZone],
      );
      applicationReq.GeoJSON = createOperationGeojson(
        applicationPass,
        [appZone],
        applicationReq.Operation_Record,
        'Field Pass',
        false,
      );

      const req = {
        HarvestPass: harvestReq,
        SeedingPass: seedingReq,
        ApplicationPass: applicationReq,
      };

      // console.log(req)
      // return
      // console.log('createManualFieldPass')
      const FieldPasses = await df.createManualFieldPasses(req);

      // add operation id to all passes and send to have price saved
      const passRecords = JSON.parse(FieldPasses);
      // console.log(passRecords)
      // need to handle situation where not ALL versions where created and we only have 1 or 2 of the different passes
      const harvestRecord = passRecords.filter(
        (x) => x.Operation_Record.Aux2 === `Harvesting Costs - ${year.toString()}`,
      ).length > 0
        ? passRecords.filter(
          (x) => x.Operation_Record.Aux2
                === `Harvesting Costs - ${year.toString()}`,
        )[0]
        : null;

      const seedingRecord = passRecords.filter(
        (x) => x.Operation_Record.Aux2 === `Seeding Costs - ${year.toString()}`,
      ).length > 0
        ? passRecords.filter(
          (x) => x.Operation_Record.Aux2 === `Seeding Costs - ${year.toString()}`,
        )[0]
        : null;

      const applicationRecord = passRecords.filter(
        (x) => x.Operation_Record.Aux2 === `Application Costs - ${year.toString()}`,
      ).length > 0
        ? passRecords.filter(
          (x) => x.Operation_Record.Aux2
                === `Application Costs - ${year.toString()}`,
        )[0]
        : null;
      // console.log("harvest record", harvestRecord)
      if (harvestRecord !== null) {
        harvestPass.operationID = harvestRecord.Operation_Record.ID;
      }
      // console.log("seeding record", seedingRecord)
      if (seedingRecord !== null) {
        seedingPass.operationID = seedingRecord.Operation_Record.ID;
      }
      // console.log("application record", applicationRecord)
      if (applicationRecord !== null) {
        applicationPass.operationID = applicationRecord.Operation_Record.ID;
      }

      const saveOps = [harvestPass, seedingPass, applicationPass];

      for (const op of saveOps) {
        if (exists(op.operationID)) {
          await saveNewOp(op, 'Field Pass');
        }
      }

      getGenericOperations(
        field.orgId,
        field.id,
        selectedYears[0],
        selectedYears,
        false,
        true,
      );
      // setFieldPasses(formatNewFieldPasses(passes));
    } catch (err) {
      console.log(`Problem creating field pass: ${err}`);
    }
    const ndx = fieldPassTracker.current.indexOf(year);
    fieldPassTracker.current.splice(ndx, 1);
  };

  const formatNewFieldPasses = (fieldPasses) => {
    const corrected = [];
    for (const pass of fieldPasses) {
      const formatted = { ...pass };
      formatted.name = formatted.productName;
      formatted.isDeleted = false;
      corrected.push(formatted);
    }
    return corrected;
  };

  const addGenericOperation = (op, type) => {
    /*
     * adds an generic operation to the appropriate list based off type
     * used by pricetool to add operation to fronend list after it is created
     */
    // need to format from manual operations Operation_record to a FieldOperation
    if (
      op.CropSeason === selectedYear
      || selectedYears.includes(op.CropSeason)
    ) {
      const newOp = {
        area: op.Area,
        areaUnit: 'ac',
        cropSeason: op.CropSeason,
        endDate: op.EndDate,
        isDeleted: false,
        name: op.Name,
        operationID: op.ID,
        price: op.Rate,
        productName: op.Name,
        source: 'USERENTERED',
        startDate: op.StartDate,
        total: op.Total,
        year: op.CropSeason,
      };

      if (type === 'Cost') {
        setOtherCosts([...otherCosts, newOp]);
      } else {
        setOtherRevenue([...otherRevenue, newOp]);
      }
    }
  };

  const combineOps = (ops) => {
    /*
      Combine operations with matching IDs.
    */
    const operations = [];

    for (const op of ops) {
      const match = operations.filter((x) => x.operationID === op.operationID);

      if (match.length > 0) {
        const index = operations.indexOf(match[0]);
        operations[index].varieties.push(op);

        operations[index].hidden = checkHidden(operations[index].varieties);
      } else {
        const operation = {
          varieties: [op],
          date: op.date,
          hidden: op.isDeleted,
          name: op.productName,
          operationID: op.operationID,
          source: op.source,
        };

        operations.push(operation);
      }
    }

    return operations;
  };

  const checkHidden = (varieties) => {
    // if any varieties are deleted (hidden), hide operation
    const hidden = varieties.filter((x) => x.isDeleted);
    return hidden.length > 0;
  };

  const getApplicationOperations = async (
    orgId,
    fieldId,
    year,
    years,
    updateFromPriceTool,
    refresh = false,
  ) => {
    /* Get field operation data then fitler for application operations to
       be displayed and hidden. Set all application operations, displayed,
       and hidden. Get chemical prices if missing.
    */

    const applicationOps = await df.getFieldOperations(
      orgId,
      fieldId,
      'APPLICATION',
      year,
      years,
      updateFromPriceTool,
    );

    // console.log('applicationOps :>> ', applicationOps);
    if (applicationOps && applicationOps.length > 0) {
      const filteredApplications = applicationOps.filter(
        (x) => x.areaResult !== null,
      );

      // Gets chemical prices if necessary and formats the applications object
      const applications = await cleanApplication(
        filteredApplications,
        year,
        years,
      );
      // console.log("Cleaned applications >> ", applications)
      const singleApplications = applications.filter((x) => x.tankMix === 0);
      const mixed = applications.filter((x) => x.tankMix !== 0);

      const tankMixes = combineApplications(mixed);
      // console.log("Combined Applications", tankMixes)

      const applicationOperations = [...singleApplications, ...tankMixes];
      // console.log("combined", applicationOperations)
      applicationOperations.sort((a, b) => new Date(a.date) - new Date(b.date));

      if (updateFromPriceTool) {
        const request = {
          FieldOperationList: applicationOperations,
          Year: year,
        };
        request.FieldOperationList.map((x) => {
          x.FileType = 'Application';
          x.OperationID = x.applicationOperationID;
          x.LogID = x.applicationLoggedID;
        });
        const res = await df.saveOperations(orgId, fieldId, request);
        getApplicationOperations(orgId, fieldId, year, years, false);
      } else {
        setApplicationOperations(applicationOperations);
      }
    }

    if (!refresh) {
      operationsLoaded.current = [...operationsLoaded.current, 'Applications'];
      setLoaded((prev) => [...prev, 'Applications']);
    }
  };

  /**
   * Gets the chemical price for each operation if it doesn't exist and calls formatApplication
   * @param {Object} ops operations to format
   * @param {Number} year selected year in ProfitLayers
   * @param {Array} years list of possible years to select for field in ProfitLayers
   * @returns {Array} Applications with prices, formated how we need
   */
  const cleanApplication = async (ops, year, years) => {
    /*  If application has no price, get price from getChemicalPrice */
    const applications = [];
    // console.log("Cleaning ops", JSON.parse(JSON.stringify(ops)))
    for (const op of ops) {
      if (op.totalMaterialResultUnit === null) {
        // console.log("Setting totalMaterialResultUnit to totalMaterialUnit", op)
        op.totalMaterialResultUnit = op.totalMaterialUnit;
      }
      if (op.price === null || op.price === 0) {
        if (exists(op.name) && exists(op.totalMaterialResultUnit)) {
          if (op.name.toUpperCase() !== 'WATER') {
            const price = await df.getChemicalPrice(
              op.name,
              op.totalMaterialResultUnit,
            );
            op.price = price === undefined ? 0 : price;
          } else {
            // Set water price to 0 if null
            op.price = 0;
          }
        } else {
          // alternatively handle all null, may mess with tank mixes though?
          op.isDeleted = true;
        }
      }

      // Don't miss this step - create the object we need for displaying
      // console.log("Formatting App: ",op)
      const application = formatApplication(op, year, years);
      applications.push(application);
    }
    return applications;
  };

  /**
   * Given an operation, set and calculate necessary variables with values from operation.
   * @param {Object} op operation to format
   * @param {Number} year selected year in ProfitLayers
   * @param {Array} years list of possible years to select for field in ProfitLayers
   * @returns {Array} formated application operations
   */
  const formatApplication = (op, year, years) => ({
    ...op,
    applicationComponents: [
      {
        ...op,
        area: op.areaResult === undefined ? field.acres : op.areaResult,
        logID:
          op.applicationLoggedID === undefined ? 0 : op.applicationLoggedID,
        productName: op.name,
        quantity:
          op.totalMaterialResult === null
          || op.totalMaterialResult === undefined
            ? op.areaResult * op.averageMaterialResult
            : op.totalMaterialResult,
        unit:
          op.totalMaterialResultUnit === null
            ? op.totalMaterialUnit === null
              ? op.unit
              : op.totalMaterialUnit
            : op.totalMaterialResultUnit,
      },
    ],
    area: op.areaResult === undefined ? field.acres : op.areaResult,
    averageMaterialResult: op.averageMaterialResult,
    // attribute: op.averageMaterialResult === null || op.averageMaterialResult === 0 ? "TargetRate" : "AppliedRate",
    attribute: 'AppliedRate',
    cellsize: '0.0001',
    date: formatDate(op.endDate),
    fileType: 'APPLICATION',
    isDeleted: determineInitialHide(op, year, years),
    isIrrigated: null,
    isOrganic: null,
    logID: op.applicationLoggedID === undefined ? 0 : op.applicationLoggedID,
    operationCost: op.price * op.averageMaterialResult,
    operationID: op.applicationOperationID,
    price: op.price,
    productName: op.name,
    quantity:
      op.totalMaterialResult === null || op.totalMaterialResult === undefined
        ? op.areaResult * op.averageMaterialResult
        : op.totalMaterialResult,
    unit:
      op.totalMaterialResultUnit === null
        ? op.totalMaterialUnit === null
          ? op.unit
          : op.totalMaterialUnit
        : op.totalMaterialResultUnit,
    variety: null,
    tankMix: op.isTankMix,
    year: op.year === undefined ? op.cropSeason : op.year,
    startDate: op.startDate,
    source: op.source,
  });

  function combineApplications(aps) {
    /* Combine tank mixes */
    const mixed = []; // ids of mixes found
    const applicationOperations = [];
    // console.log('aps :>> ', aps);
    aps.forEach((ap) => {
      // check if this mix has already been found
      if (!mixed.includes(ap.operationID)) {
        mixed.push(ap.operationID);
        const tank = aps.filter((x) => x.operationID === ap.operationID);
        // console.log('tank :>> ', tank);
        const applicationComponents = tank.map(
          (x) => x.applicationComponents[0],
        );

        const names = applicationComponents.map((x) => x.productName);
        // console.log('names :>> ', names);

        // sum cost of applications by multipling each price and quanity, then dividing by area
        const opCost = applicationComponents
          .map(
            (x) => (parseFloat(x.price) * parseFloat(x.quantity))
              / parseFloat(x.area),
          )
          .reduce((a, b) => a + b, 0);

        // sum cost of applications divided by averageMaterialResult
        const applicationPrice = applicationComponents
          .map(
            (x) => (parseFloat(x.price) * parseFloat(x.quantity))
              / parseFloat(x.area)
              / parseFloat(x.averageMaterialResult),
          )
          .reduce((a, b) => a + b, 0);

        const application = {
          ...ap,
          applicationComponents,
          area: ap.area,
          attribute: ap.attribute,
          averageMaterialResult: ap.averageMaterialResult,
          cellsize: ap.cellsize,
          date: ap.date,
          fileType: ap.fileType,
          id: ap.operationID,
          isDeleted: ap.isDeleted,
          isIrrigated: ap.isIrrigated,
          isOrganic: ap.isOrganic,
          logID: ap.logID,
          operationCost: Math.round(opCost * 100) / 100,
          operationID: ap.operationID,
          price: parseFloat(applicationPrice.toFixed(2)),
          productName: names.join(', '),
          quantity: ap.quantity,
          unit: ap.unit,
          variety: ap.variety,
          year: ap.year,
          startDate: ap.startDate,
          isIncluded: ap.isIncluded,
        };

        applicationOperations.push(application);
      }
    });

    return applicationOperations;
  }

  const getTillageOperations = async (orgId, fieldId, year, years) => {
    const tillageOperations = await df.getFieldOperations(
      orgId,
      fieldId,
      'TILLAGE',
      year,
      years,
    );

    // console.log("TILLAGE >>", JSON.parse(JSON.stringify(tillageOperations)))
    let tillage = [];
    if (tillageOperations && tillageOperations.length > 0) {
      tillage = tillageOperations.map((op) => cleanTillage(op));
    }

    // console.log('year', year, years)
    for (let i = 0; i < years.length; i++) {
      years[i] = Number(years[i]);
    }

    const withZones = [];
    for (const op of tillage) {
      const toplevelTill = {
        ...op,
        zones: [],
      };
      toplevelTill.zones.push(op);
      withZones.push(toplevelTill);
    }

    setTillageOperations(withZones);
    operationsLoaded.current = [...operationsLoaded.current, 'Tillage'];
    setLoaded((loaded) => [...loaded, 'Tillage']);
  };

  const cleanTillage = (op) => {
    // Format tillage operations to match other field passes

    // split date for formatting
    const d = op.endDate.split('T')[0].split('-');
    // users requested feature to name tillage operations, so first check if named
    if (op.productName === null) {
      const date = `${d[1]}/${d[2]}/${d[0]}`;
      const name = `${date} Tillage (depth: ${op.targetAverageDepth} ${op.targetAverageDepthUnit})`;
      op.productName = name;
    }

    if (op.price === null) {
      op.price = calc.getTillagePrice(op, dieselPrices);
    }

    op.area = op.resultArea;
    op.operationCost = op.resultArea * op.price;
    op.year = d[0]; // currently using selected year for this?
    op.cellsize = '0.0001';

    return op;
  };

  const updateFieldPasses = (ops, type) => {
    const toUpdatePass = fieldPasses.filter((x) => x.productName === type);
    const passes = fieldPasses.filter((x) => x.productName !== type);

    if (toUpdatePass.length > 0) {
      const updatedPass = updateFieldPass(toUpdatePass[0], ops);
      setFieldPasses([...passes, updatedPass]);
    }
  };

  // No Longer in use. All generic operations are gathered in one call
  // const getOtherCosts = async (orgId, fieldId, year, acres) => {
  //   const costs = await df.getFieldOperations(
  //     orgId,
  //     fieldId,
  //     "OTHER COSTS",
  //     year,
  //     selectedYears
  //   );
  //   const otherCostsVal = costs.map((x) =>
  //     formatOperations(x, "OTHER COSTS", acres)
  //   );
  //
  //   setOtherCosts(otherCostsVal);
  //   setLoaded((loaded) => [...loaded, "Other Costs"]);
  // };
  //
  // const getOtherHarvestRevenue = async (orgId, fieldId, year, acres) => {
  //   const revenue = await df.getFieldOperations(
  //     orgId,
  //     fieldId,
  //     "OTHER REVENUES",
  //     year,
  //     selectedYears
  //   );
  //   const otherRevenues = revenue.map((x) =>
  //     formatOperations(x, "OTHER REVENUES", acres)
  //   );
  //   setOtherRevenue(otherRevenues);
  //   setLoaded((loaded) => [...loaded, "Other Revenue"]);
  // };

  const formatOperations = (op, type, acres) => ({
    logID: op.logID,
    operationID: op.id,
    fileType: type,
    price: op.price,
    area: acres,
    cellsize: '0.0001',
    isDeleted: op.isDeleted,
    unit: 'ac',
    productName: op.productName,
    year: op.year,
    operationCost: acres * op.price,
  });

  const getDisplayOps = (ops) => {
    /* Filter for operations to display. Assign not displayed to hidden,
       return both arrays
    */

    const displayed = ops.filter((op) => !op.isDeleted);
    displayed.sort((a, b) => new Date(b.endDate) - new Date(a.endDate));

    const hidden = ops.filter((op) => !displayed.includes(op));

    return { displayed, hidden };
  };

  const convertPlantings = (plantings) => {
    /* Convert harvest and seeding operations to format needed for
       reports and calculations.
    */
    const varieties = [];
    for (const op of plantings) {
      for (const variety of op.varieties) {
        varieties.push(variety);
      }
    }
    return varieties;
  };

  const getCurrentTemplateName = () => {
    if (currentTemplate != undefined) {
      // first lets identify out scenario id
      const data = currentTemplate;
      let name;

      let templateID = -1;
      if (data.seedingOperations.length > 0) {
        templateID = data.seedingOperations[0].scenarioID;
      } else if (data.genericOperations.length > 0) {
        templateID = data.genericOperations[0].scenarioID;
      } else if (data.harvestOperations.length > 0) {
        templateID = data.harvestOperations[0].scenarioID;
      } else if (data.applicationOperations.length > 0) {
        templateID = data.applicationOperations[0].scenarioID;
      } else if (data.tillageOperations.length > 0) {
        templateID = data.tillageOperations[0].scenarioID;
      }

      if (templateID !== -1) {
        // we must have identified our current ID
        for (const template of allTemplates) {
          if (template.ID == templateID) {
            name = template.Name;
            setCurrentTemplateName(name);
            setCurrentTemplateID(template.ID);
          }
        }
      }
    }
  };

  const getProfitMapOperations = () => {
    /**
     * Convert operation to data for formats profit map expects, then combine
     * all operations in to array
     */
    // console.log('getProfitMapOperations', displayedSeedings)
    const harvests = convertPlantings(displayedHarvests).map((x) => formatForProfitMap(x));
    const seedings = convertPlantings(displayedSeedings).map((x) => formatForProfitMap(x));
    const applications = displayApplicationOps.map((x) => formatForProfitMap(x));

    // console.log('seedings', seedings)
    // const generics = [...displayFieldPasses, ...otherCosts, ...otherRevenue]
    // //send to be converted to field ops
    // let genericFieldOps = []
    // for(const op of generics){
    //   let type;
    //   if(op.aux1 === "Field Pass" || op.aux1 === "Cost"){
    //     type = "Cost"
    //   }
    //   else{
    //     type = "Revenue"
    //   }
    //   let genOp = convertToFieldOp(op)
    //   genericFieldOps = [...genericFieldOps, ...genOp]
    // }
    const others = [
      ...displayFieldPasses,
      ...displayOtherCosts,
      ...displayOtherRevenue,
    ];

    const operations = [...harvests, ...seedings, ...applications, ...others];
    // console.log(operations)
    return operations;
  };

  const formatDate = (date) => {
    /* format date as expected for profit map */
    const dash = date.includes('-');
    const d = dash
      ? date.split('T')[0].split('-')
      : date.split('T')[0].split('/');
    return `${d[1]}/${d[2]}/${d[0]}`;
  };

  const getDieselPrices = async (year) => {
    const prices = await df.getDieselPrices(year);
    setDieselPrice(prices);
  };

  // Profit Map responses
  const getProfitMapSummary = async (orgId, fieldId, year) => {
    /* Get data stored from profit map. This will can include net seeded acres.
           If net seeded acres are not includes, use calculateNetSeededAcres().
        */
    const summary = await df.getProfitMapSummary(orgId, fieldId, year);
    setProfitMapSummary(summary);
    // console.log("PMS", summary)
    // const summaryAcres = summary && summary.length > 0 ? summary[0].netSeededAcre : 0;
    // const fieldAcres = calc.calculateNetSeededAcres(field,seedings,harvests);
    // console.log("SUMMARY ACRES", summaryAcres, "CALCULATED", fieldAcres)
    // const acres = summaryAcres !== 0 && summaryAcres <= fieldAcres ? summaryAcres : fieldAcres;
    // // console.log(summaryAcres, fieldAcres)
    // setNetSeededAcres(acres);
    // get other costs and revenue here because they need acres value
    // getOtherCosts(orgId, fieldId, year, year, year, acres);
    // getOtherHarvestRevenue(orgId, fieldId, year, year, year, acres);
  };

  const getProfitMapResponse = async (orgId, fieldId, year) => {
    const response = await df.getProfitMapResponse(orgId, fieldId, year);

    if (response && response.length > 0) {
      const r = JSON.parse(response[0].profitMap_Response);
      if (r && r.code && r.code == 500 && value > 0) {
        // if 500 error and one setup of view tab
        // enqueueSnackbar("Failed to Prepare Profit Layer");
      } else {
        if (r.profitMap) {
          r.profitMap = calc.updateProfitMapValues(
            totalProfit,
            r.profitMap,
            perAcre,
            netSeededAcres,
          );
        }

        setProfitMapResponse(r);

        if (r && r[0] && r[0].legend) {
          const template = await df.getAllTemplates(r[0].legend.length);
          setSavedTemplate(template);
          setTemplateRes(template);
        }
      }
    }
  };

  const formatProfitLegend = (inputLegend, pmRequest) => {
    // console.log("original legend", inputLegend)
    const legend = JSON.parse(JSON.stringify(inputLegend));
    const len = legend.length;
    if (
      legend[0].max < legend[0].min
      || legend[len - 1].max < legend[len - 1].min
    ) {
      // this will create a set of values equal to all max values and smallest min value
      const valueSet = new Set();
      legend.map((x) => {
        valueSet.add(x.max);
        valueSet.add(x.min);
      });
      // convert to array for sorting
      const valueArray = [];
      valueSet.forEach((x) => valueArray.push(x));
      valueArray.sort((a, b) => b - a); // sort in descending order
      // console.log(valueArray)

      for (let i = 0; i < legend.length; i++) {
        legend[i].max = valueArray[i];
        legend[i].min = valueArray[i + 1];
      }
    }

    if (pmRequest.ProfitMapLegendRange) {
      let legendRanges = pmRequest.ProfitMapLegendRange.split(', ').reverse();
      legendRanges = legendRanges.map((x) => parseFloat(x));
      // console.log("Legend ranges from req", legendRanges)
      if (legendRanges[0] !== legend[0].max) {
        legend[0].max = legendRanges[0];
      }
      if (
        legendRanges[legendRanges.length - 1] !== legend[legend.length - 1].min
      ) {
        legend[legend.length - 1].min = legendRanges[legendRanges.length - 1];
      }
      legend[0].max = legend[0].max - 0.01;
      legend[legend.length - 1].min = legend[legend.length - 1].min + 0.01;
    }

    // console.log("fixed", legend)
    return legend;
  };

  const validateLegend = (legend) => {
    let totalPerc = 0;
    for (const item of legend) {
      const percVal = parseFloat(item.area.split(' ')[0]);
      totalPerc += percVal;
    }
    if (totalPerc < 98) {
      enqueueSnackbar(
        'The Profitmap contains values outside of your set profit ranges, these values will appear as black on the map',
        { autoHideDuration: 8000 },
      );
    }
  };

  const getProfitMap = async (
    orgId,
    fieldId,
    operations,
    isLegendSaved = 1,
    isOperationMap = 0,
    retry = 0,
  ) => {
    // console.log('getting profit map')
    setMapLoading(true);
    if(isOperationMap){
      setOperationMapLoading(true)
    }
    else{
      setProfitMapLoading(true)
    }

    if (highRes) {
      const preprocess = await df.preprocessField(
        orgId,
        fieldId,
        Endpoints.highResCellSize,
      );

      if (preprocess !== 'Successful') {
        return;
      }
    }

    // cycle over operations, if generic cost and pice > 0 make price neg
    // if has been converted to K seeds, convert back
    const operationsCopy = JSON.parse(JSON.stringify(operations));
    const toPop = [];
    if (!isOperationMap) {
      for (const op of operationsCopy.FieldOperationList) {
        if (op.fileType.toUpperCase() !== 'HARVEST') {
          if (op.fileType.toUpperCase() === 'GENERIC') {
            if (op.aux1 === 'Field Pass' || op.aux1 === 'Cost') {
              // console.log(op)
              if (
                op.productName.includes('Application Costs - ')
                || op.productName.includes('Harvesting Costs - ')
                || op.productName.includes('Seeding Costs - ')
              ) {
                op.fixedPrice = op.price;
                op.price *= op.area / op.originalArea;
              }
              if (op.price > 0) {
                op.price = parseFloat(0 - op.price);
              }
              if (op.aux1 === 'Field Pass' && op.area === 0) {
                toPop.push(
                  operationsCopy.FieldOperationList.map(
                    (x) => x.operationID,
                  ).indexOf(op.operationID),
                );
              }
            }
          } else if (op.fileType.toUpperCase() === 'SEEDING') {
            // Convert values back to seeds
            if (op.unit === 'K seeds') {
              op.unit = op?.originalUnit !== undefined ? op.originalUnit : 'seeds';
              op.totalMaterialUnit = op?.originalUnit !== undefined ? op.originalUnit : 'seeds';
              op.price /= 1000;
              op.averageMaterialTarget *= 1000;
              op.quantity *= 1000;
              op.rate *= 1000;
            }
            op.price = parseFloat(0 - op.price);
          } else if (op.fileType.toUpperCase() === 'TILLAGE') {
            operationsCopy.constant -= op.price;
          } else if (op.fileType.toUpperCase() === 'APPLICATION') {
            op.price = op.operationCost > 0
              ? parseFloat(0 - op.operationCost / op.averageMaterialResult)
              : op.operationCost / op.averageMaterialResult;
          } else if (op.price > 0) {
            op.price = parseFloat(0 - op.price);
          }
        }
      }
    }

    toPop.map((x) => operationsCopy.FieldOperationList.splice(x, 1));

    if (clipByBoundary) {
      operationsCopy.boundarygeojson = field.boundary;
    }

    const profitMapProblems = { ...profitMapIssues };

    // This is a little hack to try and avoid the response coming back with black values when a user doesnt
    // update either of the bounding values in the legend ranges
    if (
      operationsCopy.ProfitMapLegendRange
      && operationsCopy.ProfitMapLegendRange !== null
    ) {
      let legendRanges = operationsCopy.ProfitMapLegendRange.split(', ');
      legendRanges = legendRanges.map((x) => parseFloat(x));
      legendRanges[0] -= 0.01;
      legendRanges[legendRanges.length - 1] = legendRanges[legendRanges.length - 1] + 0.01;
      operationsCopy.ProfitMapLegendRange = legendRanges.join(', ');
    }
    // if(operationsCopy.ProfitMapLegendRange === null){
    //   delete operationsCopy.ProfitMapLegendRange
    // }
    // console.log("operationsCopy", JSON.stringify(operationsCopy));
    const res = await df.getProfitMap(orgId, fieldId, operationsCopy);
    // console.log(res)
    // if(retry < 1){
    //   res.profitMap.code = 500
    // }

    if (
      res.code !== undefined
      || res.fieldOperationList === undefined
      || res.profitMap === undefined
      || res.profitMap.code == 500
    ) {
      // this is a handler for if df.getProfitMap fails (happens if user quickly changes fields and navigates back to reports)
      let trialExpired = false;
      if (res.message !== undefined) {
        enqueueSnackbar(res.message, { autoHideDuration: 8000 });
        setErrorMessage(res.message);
        trialExpired = res.message.includes('Trial');
      } else if (retry >= 1) {
        if (isOperationMap) {
          enqueueSnackbar('Failed to get a operation map');
          setErrorMessage('Failed to get a operation map');
        } else {
          enqueueSnackbar('Failed to get a profit map');
          setErrorMessage('Failed to get a profit map');
        }
      }

      if (retry < 1 && !trialExpired) {
        // if failed and have tried less than 2 times call again
        retry += 1;
        // console.log("RETRYING PROFITMAP", retry)

        await getProfitMap(
          orgId,
          fieldId,
          operations,
          isLegendSaved,
          isOperationMap,
          retry,
        );
        return;
      }

      // Without undefined checks will crash app when res comes back undefined
      profitMapProblems.message = res?.profitMap?.message || res?.message;
      setProfitMapIssues(profitMapProblems);
      setErrorMessage(profitMapProblems.message);

      setProfitMapResponse([]);
      setMapLoading(false);
      setProfitMapLoading(false);
      setOperationMapLoading(false);
      return;
    }

    if (isOperationMap) {
      setErrorMessage(null);
      if (res.profitMap.code === 500) {
        // check for message
        profitMapProblems.message = res.profitMap.Message;
        // enqueueSnackbar("Failed to generate operation map");
        setOperationMap([]);
      } else {
        setOperationMap(res);
      }
    } else {
      // Gets field operations.
      // Call before getProfitMapSummary so netSeededAcre is not overwritten
      getData(
        field.orgId,
        field.id,
        selectedYears[0],
        selectedYears,
        false,
        true,
      );

      // if (res.profitMap[0] !== undefined) {
      //   setNetSeededAcres(res.profitMap[0].acres)
      // }
      // console.log(res.profitMap[0])

      // console.log("\n PROFITMAP REPONSE: ",res,"\n")
      validateLegend(res.profitMap[0].legend);
      res.profitMap[0].legend = formatProfitLegend(
        res.profitMap[0].legend,
        operationsCopy,
      );
      res.profitMap = calc.updateProfitMapValues(
        totalProfit,
        res.profitMap,
        perAcre,
        netSeededAcres,
      );

      setProfitMap(res);

      if (res.profitMap.code === 500) {
        // check for message
        profitMapProblems.message = res.profitMap.message;
        setProfitMapIssues(profitMapProblems);
        setErrorMessage(profitMapProblems.message);

        setProfitMapResponse([]);
      } else {
        // check for missing operations files
        profitMapProblems.isMissingOps = res.profitMap.isMissingOperationFile;
        if (profitMapProblems.isMissingOps) {
          profitMapProblems.missingList = res.profitMap.missingOperationFile;
          setProfitMapIssues(profitMapProblems);
        } else {
          setProfitMapIssues({
            isMissingOps: false,
            missingFiles: [],
            message: '',
          });
        }

        setErrorMessage(null);

        setProfitMapResponse(res.profitMap);

        // Log profit map obj
        const profitLog = {
          ProfitMap_Response: null, // json string of response from get profitmap
          isLegendSaved: null, // bool -> false if range # changed, otherwise true
          zoneBoundary: null, // boundary of zone if map was for zone
          zoneCost: null, // cost if map was for zone
        };
        profitLog.ProfitMap_Response = JSON.stringify(res.profitMap);
        profitLog.isLegendSaved = isLegendSaved;
        if (selectedYears.length === 1) {
          const logged = await df.logProfitMapresponse(
            orgId,
            fieldId,
            selectedYears[0],
            profitLog,
          );

          const summaryObj = {
            NetSeededAcre: netSeededAcres,
            Profit: res.profitMap[0].mean,
          };
          const summary = await df.logProfitMapSummary(
            orgId,
            fieldId,
            selectedYears[0],
            summaryObj,
          );
          getProfitMapSummary(field.orgId, field.id, selectedYears[0]);
          // console.log("PMS LOGGED", summary)
        }
      }
    }

    setMapLoading(false);
    setProfitMapLoading(false);
    setOperationMapLoading(false);
  };

  /**
   * Formats date for the createOperationRecord function
   * handles different orders based on the seperator used
   * @param {String} date
   */
  const dateFormat = (date) => {
    const dash = date.includes('-');
    const broken = dash ? date.split('-') : date.split('/');
    const fixed = dash
      ? [broken[1], broken[2], broken[0]].join('-')
      : broken.join('-');
    return fixed;
  };

  const getTotal = (varieties) => {
    let max = 0;
    for (let i = 0; i < varieties.length; i++) {
      const val = varieties[i].quantity; // * varieties[i].area;
      max += val;
    }

    return max;
  };

  const getElevationMap = async (orgId, fieldId, operations, aoi) => {
    // setMapLoading(true);
    setElevationMapLoading(true)
    const res = await df.getElevationMap(
      orgId,
      fieldId,
      operations,
      aoi,
      false,
    );
    // setMapLoading(false);
    setElevationMapLoading(false)
    // check resposne to see if it is from DEM or normal
    // if from DEM needs to be reformatted
    if (res.pngb64) {
      setElevationMap(res);
      // console.log("res", res);
    } else {
      setElevationMap({});
    }
    // setMapLoading(false);
  };

  // Elevation Indes
  const getElevationIndex = async (
    orgId,
    fieldId,
    product,
    operations,
    boundary,
  ) => {
    // setMapLoading(true);
    setElevationMapLoading(true)
    const request = {
      FieldOperationList: operations,
      aoi: boundary,
    };

    const res = await df.elevationIndex(orgId, fieldId, product, request);
    setElevationMapLoading(false)
    // setMapLoading(false);
    if (res !== undefined && res.length > 0) {
      setElevationMap(res[0]);
    } else {
      setElevationMap({});
    }
    // setMapLoading(false);
  };

  const combineOpVarieties = (opInfo, record, type) => {
    const newVarieties = [];
    for (const variety of record.varietyList) {
      // check for match with record list
      const opMatches = opInfo.varieties.filter(
        (x) => x.variety === variety.Name,
      );

      if (opMatches.length > 1) {
        // if there are multiple entries in opInfo of a single in record list
        // then update one of the opInfo entries and forget about other
        const opToUpdate = opMatches[0];
        opToUpdate.area = variety.Area;
        opToUpdate.rate = type === 'Harvest' ? variety.AverageYield : variety.AverageMaterial;
        opToUpdate.quantity = type === 'Harvest' ? variety.Yield : variety.TotalMaterial;

        newVarieties.push(opToUpdate);
      } else if (opMatches.length === 1) {
        newVarieties.push(opMatches[0]);
      }
    }
    return newVarieties;
  };

  /**
   * Combines varieties for seeding and harvesting operations with the same name
   * will combine rate, area, and quantity
   * @param {Object} record contains info about the new operation
   */
  const combineManualVarieties = (record, type) => {
    const matchnames = [];
    const matchndxs = [];
    for (let i = 0; i < record.varietyList.length; i++) {
      // if matches were already combined, push the index into list and skip
      if (matchnames.includes(record.varietyList[i].Name)) {
        matchndxs.push(i);
        continue;
      }

      let matches;
      if (type === 'Seeding') {
        matches = record.varietyList.filter(
          (x) => x.Name
            === record.varietyList[i]
              .Name, /* && x.AverageMaterial === record.varietyList[i].AverageMaterial */
        );
      } else {
        matches = record.varietyList.filter(
          (x) => x.Name
            === record.varietyList[i]
              .Name, /* && x.AverageYield === record.varietyList[i].AverageYield */
        );
      }
      // if no matches found continue
      if (matches.length <= 1) {
        continue;
      }

      // calculate and set new values
      matchnames.push(record.varietyList[i].Name);
      const totalArea = record.varietyList
        .map((x) => x.Area)
        .reduce((prev, next) => prev + next, 0);

      const varietyTotal = type === 'Seeding'
        ? matches
          .map((x) => x.Area * x.AverageMaterial)
          .reduce((prev, next) => prev + next, 0)
        : matches
          .map((x) => x.Area * x.AverageYield)
          .reduce((prev, next) => prev + next, 0);

      const combinedRate = parseFloat(varietyTotal / totalArea).toFixed(2);

      const combinedTotal = type === 'Seeding'
        ? matches
          .map((x) => x.TotalMaterial)
          .reduce((prev, next) => prev + next, 0)
        : matches.map((x) => x.Yield).reduce((prev, next) => prev + next, 0);

      const combinedArea = matches
        .map((x) => x.Area)
        .reduce((prev, next) => prev + next, 0);

      if (type === 'Seeding') {
        record.varietyList[i].AverageMaterial = parseFloat(combinedRate);
        record.varietyList[i].TotalMaterial = parseFloat(combinedTotal);
        record.varietyList[i].Area = parseFloat(combinedArea);
      } else {
        record.varietyList[i].AverageYield = parseFloat(combinedRate);
        record.varietyList[i].Yield = parseFloat(combinedTotal);
        record.varietyList[i].Area = parseFloat(combinedArea);
      }
    }
    // sort into ascending order as to not change item indecies while removing
    matchndxs.sort((a, b) => a - b);
    // remove varieties in reverse order to avoid messing up index values
    for (let i = matchndxs.length - 1; i >= 0; i--) {
      record.varietyList.splice(matchndxs[i], 1);
    }
    return record;
  };

  const createVarietyList = (opInfo, type) => {
    const varList = [];

    for (let i = 0; i < opInfo.varieties.length; i++) {
      let v = {};
      if (type === 'Seeding') {
        v = {
          Name: opInfo.varieties[i].variety,
          Area: opInfo.varieties[i].area,
          AreaUnit: 'ac',
          TotalMaterial: parseFloat(
            opInfo.varieties[i].quantity, // * opInfo.varieties[i].area
          ),
          TotalMaterialUnit: con.unitConversion(opInfo.unit),
          AverageMaterial: opInfo.varieties[i].rate,
          AverageMaterialUnit: `${con.unitConversion(opInfo.unit)}1ac-1`,
          FieldID: field.id,
          OrganizationID: field.orgId,
        };
      } else {
        v = {
          Name: opInfo.varieties[i].variety,
          Area: opInfo.varieties[i].area,
          AreaUnit: 'ac',
          Yield: opInfo.varieties[i].quantity, // * opInfo.varieties[i].area,
          YieldUnit: con.unitConversion(opInfo.unit),
          AverageYield: opInfo.varieties[i].rate,
          AverageYieldUnit: `${con.unitConversion(opInfo.unit)}1ac-1`,
          FieldID: field.id,
          OrganizationID: field.orgId,
        };
      }
      if (Object.keys(v).length > 0) {
        varList.push(v);
      }
    }
    return varList;
  };

  const totalAppliedRate = (appZones) => {
    let totalQ = 0;
    for (let i = 0; i < appZones.length; i++) {
      totalQ
        += parseFloat(appZones[i].quantity) * appZones[i].properties.CALCACRES;
    }
    return totalQ;
  };

  const createOperationRecord = (opInfo, type) => {
    // ALL DATES MUST BE converted to MONTH-DAY-YEAR format (MM-DD-YYYY)
    // Seeding and Harvest need to combine varieties that are the same

    let record = null;
    let opAcres = null;
    let opTotalResult = null;
    const generic = type === 'Cost' || type === 'Revenue' || type === 'Field Pass';

    if (type === 'Seeding') {
      opAcres = opInfo.varieties
        .map((x) => x.area)
        .reduce((prev, next) => prev + next, 0);

      opTotalResult = opInfo.varieties
        .map((x) => x.area * x.rate)
        .reduce((a, b) => a + b, 0);

      record = {
        ID: opInfo.operationID === '' ? null : opInfo.operationID,
        CropSeason: opInfo.date.includes('-')
          ? opInfo.date.split('-')[0]
          : opInfo.date.split('/')[2],
        StartDate: dateFormat(opInfo.date),
        CropName:
          opInfo.name === '' ? opInfo.varieties[0].productName : opInfo.name,
        AreaResult: opAcres,
        AreaResultUnit: 'ac',
        TotalMaterialResult: opTotalResult,
        TotalMaterialResultUnit: con.unitConversion(opInfo.unit),
        AverageMaterialResult: parseFloat(opTotalResult / opAcres).toFixed(2),
        AverageMaterialResultUnit: `${con.unitConversion(opInfo.unit)}1ac-1`,
        varietyList: createVarietyList(opInfo, type),
      };
    } else if (type === 'Harvest') {
      opAcres = opInfo.varieties
        .map((x) => x.area)
        .reduce((prev, next) => prev + next, 0);
      opTotalResult = opInfo.varieties
        .map((x) => x.area * x.rate)
        .reduce((a, b) => a + b, 0);

      record = {
        ID: opInfo.operationID === '' ? null : opInfo.operationID,
        CropSeason: opInfo.date.includes('-')
          ? opInfo.date.split('-')[0]
          : opInfo.date.split('/')[2],
        StartDate: dateFormat(opInfo.date),
        CropName:
          opInfo.name === '' ? opInfo.varieties[0].productName : opInfo.name,
        Area: opAcres,
        AreaUnit: 'ac',
        Yield: opTotalResult,
        YieldUnit: con.unitConversion(opInfo.unit),
        AverageYield: parseFloat(opTotalResult / opAcres).toFixed(2),
        AverageYieldUnit: `${con.unitConversion(opInfo.unit)}1ac-1`,
        varietyList: createVarietyList(opInfo, type),
      };
    } else if (type === 'Application') {
      const applicationAcres = opInfo.zones
        .map((x) => x.properties.CALCACRES)
        .reduce((prev, next) => prev + next, 0);

      // get sum of (zone applied rate * zone acreage) and divide by total acres
      const totalApplied = totalAppliedRate(opInfo.zones) / applicationAcres;
      const summaryUnit = con.unitConversion(opInfo.unit);

      // some sanity checking
      const componenttotals = [];
      let comptot = 0;
      for (const comp of opInfo.tankMix) {
        const val = comp.rate
          * con.applicationConversions[con.unitConversion(comp.unit, true)][
            opInfo.unit
          ];
        comptot += val;
        componenttotals.push(val);
      }

      // build record
      record = {
        CropSeason: opInfo.date.split('-')[0],
        StartDate: dateFormat(opInfo.date),
        Name:
          opInfo.isTankMix === true
            ? 'Tank Mix'
            : opInfo.productName === ''
              ? opInfo.tankMix[0].productName
              : opInfo.productName,
        IsTankMix: opInfo.isTankMix,
        AreaResult: opInfo.area,
        AreaResultUnit: 'ac',
        Rate: opInfo.rate, // application 'Rate' is acre weighted 'average'
        RateUnit: `${summaryUnit}1ac-1`,
        TotalMaterialResult: parseFloat((opInfo.rate * opInfo.area).toFixed(2)),
        TotalMaterialResultUnit: summaryUnit,
        AverageMaterialResult: opInfo.rate,
        AverageMaterialResultUnit: `${summaryUnit}1ac-1`,
        componentList: [],
      };

      for (let i = 0; i < opInfo.tankMix.length; i++) {
        const component = {
          Name: opInfo.tankMix[i].productName,
          Rate: parseFloat(opInfo.tankMix[i].rate),
          RateUnit: `${con.unitConversion(opInfo.tankMix[i].unit)}1ac-1`,
          IsCarrier: opInfo.tankMix[i].isCarrier,
          // IsCarrier: opInfo.tankMix.isCarrier !== undefined,
          TotalMaterialResult: parseFloat(opInfo.tankMix[i].rate * opInfo.area),
          TotalMaterialResultUnit: con.unitConversion(opInfo.tankMix[i].unit),
          AverageMaterialResult: opInfo.tankMix[i].rate,
          AverageMaterialResultUnit: `${con.unitConversion(
            opInfo.tankMix[i].unit,
          )}1ac-1`,
          FieldID: field.id,
          OrganizationID: field.orgId,
        };
        record.componentList.push(component);
      }
    } else if (generic) {
      const unit = type === 'Field Pass' ? 'unit' : con.unitConversion(opInfo.unit);
      record = {
        Name: opInfo.productName,
        CropSeason: opInfo.date.split('-')[0],
        StartDate: dateFormat(opInfo.date),
        Area: opInfo.area,
        AreaUnit: 'ac',
        Total: opInfo.area,
        TotalUnit: unit,
        Rate: 1,
        RateUnit: `${unit}1ac-1`,
        Aux1: type,
        Aux2: opInfo.productName,
        Aux3: opInfo.zones.length,
        genericZones: [],
      };
      let i = 1;
      for (const zone of opInfo.zones) {
        // build zone Record and insert into record.genericZones
        const genericZone = {
          Name: opInfo.productName + i.toString(), // this is a place holder until it is decided if zones will have their own names
          Area: zone.area,
          AreaUnit: 'ac',
          Total: (zone.area * zone.rate).toFixed(2),
          TotalUnit: unit,
          Rate: zone.rate,
          RateUnit: `${unit}1ac-1`,
          Aux1: type,
          Aux2: opInfo.productName,
          Aux3: i,
        };
        i++;
        record.genericZones.push(genericZone);
      }
    } else if (type.toUpperCase() === 'TILLAGE') {
      record = {
        CropSeason: opInfo.date.split('-')[0],
        StartDate: dateFormat(opInfo.date),
        TargetArea: opInfo.area,
        TargetAreaUnit: 'ac',
        TargetAverageDepth: opInfo.targetAverageDepth,
        TargetAverageDepthUnit: opInfo.targetAverageDepthUnit,
      };
    } else {
      console.log(`unexpected type: ${type}`);
    }

    record.FieldID = field.id;
    record.OrganizationID = field.orgId;

    let listType = '';

    if (type === 'Application') {
      listType = 'componentList';
    } else if (generic) {
      listType = 'genericZones';
    } else if (
      type.toUpperCase() === 'HARVEST'
      || type.toUpperCase() === 'SEEDING'
    ) {
      listType = 'varietyList';
    } else {
    }

    if (listType !== '') {
      for (var i = 0; i < record[listType].length; i++) {
        record[listType][i].FieldID = field.id;
        record[listType][i].OrganizationID = field.orgId;
      }
    }

    if (opInfo.operationID !== '' && opInfo.operationID !== undefined) {
      record.ID = opInfo.operationID;
      if (listType !== '') {
        for (var i = 0; i < record[listType].length; i++) {
          const idType = `${type}OperationID`;
          record[listType][i][idType] = opInfo.operationID;
        }
      }
    }

    return record;
  };

  /**
   * removes circular references from opinfo
   * @param {Object} opInfo
   */
  const deleteMapReferences = (opInfo) => {
    for (const zone of opInfo.zones) {
      if (zone.featureRef) {
        delete zone.featureRef;
      }
      if (zone.mapRef) {
        delete zone.mapRef;
      }
    }
  };

  const createOperationGeojson = (opInfo, zones, record, type, auto = true) => {
    /** Create a geojson feature collection for an operation * */
    // console.log('opInfo', opInfo)
    deleteMapReferences(opInfo);

    const geojson = {
      type: 'FeatureCollection',
      features: [],
    };

    const generic = type === 'Cost' || type === 'Revenue' || type === 'Field Pass';

    const comps = type === 'Application' || generic || type.toUpperCase() === 'TILLAGE'
      ? opInfo.zones.length
      : record.varietyList.length;
    // console.log(opInfo)
    // console.log("Creating Geojson feature, Comps:", comps)
    for (let i = 0; i < comps; i++) {
      let feature = {};

      if (type === 'Seeding' || type === 'Harvest') {
        feature = opInfo.varieties[i].zone;
      } else if (type === 'Application') {
        feature = opInfo.zones[i];
      } else {
        feature = opInfo.zones[i].zone;
      }

      if (
        zones.length > 1
        && feature.geometry.coordinates[0][0].length !== 2
        && feature.geometry.type !== 'MultiPolygon'
      ) {
        feature.geometry.coordinates = [feature.geometry.coordinates];
      } else if (type === 'Application' && record.Area !== field.area) {
        feature.geometry.coordinates = [feature.geometry.coordinates];
      }

      const opCopy = JSON.parse(JSON.stringify(opInfo, getCircularReplacer()));

      if (type === 'Harvest') {
        feature.properties = opCopy.varieties[i];
        feature.properties.zone = null;
        feature.properties.VRYIELDVOL = record.varietyList[i].AverageYield;
        feature.properties.Variety = record.varietyList[i].Name;
        feature.properties.Crop = i + 1;
      } else if (type === 'Seeding') {
        feature.properties = opCopy.varieties[i];
        feature.properties.zone = null;
        feature.properties.AppliedRat = record.varietyList[i].AverageMaterial;
        feature.properties.Variety = record.varietyList[i].Name;
        feature.properties.Crop = i + 1;
      } else if (type === 'Application') {
        feature.properties.Product = record.Name;
        feature.properties.AppliedRat = parseFloat(opInfo.zones[i].quantity);
        feature.properties.Unit = `${con.unitConversion(opInfo.unit)}1ac-1`;
      } else if (generic) {
        feature.properties = record.genericZones[i];
        feature.properties.color = opCopy.zones[i].color;
        feature.properties.Price = opCopy.zones[i].price;
        if (type === 'Field Pass' && !auto) {
          feature.properties.Rate = 1;
        }
      } else if (type.toUpperCase() === 'TILLAGE') {
        feature.properties = record;
        feature.properties.Depth = record.TargetAverageDepth;
      } else {
        console.log(`unexpected type: ${type}`);
      }

      feature.properties.Time = opInfo.date;
      feature.properties.SECTIONID = i + 1;

      if (feature.quantity !== undefined) {
        delete feature.quantity;
      }
      geojson.features.push(feature);
    }

    return geojson;
  };

  /**
   * Takes the information from UpdatePlanting, UpdateApplication, UpdateOther, or UpdateFieldPass
   * to form the correct object to send to the CreateManualOperation endpoint on backend
   * This is used for creating AND updating a manual operation
   * @param {String} type the type of the operation (Seeding, Harvest, Application, Cost, Revenue, Field Pass)
   * @param {Object} opInfo object containing necessary information about operation
   * @param {List} zones list of zone objects (geojson)
   * @param {bool} auto boolean that indicates if manual operation being created was automatically generated or not
   * @param {Boolean} templateSeeding if true, returns operationID info needed for template yield forecasting
   * @param {Number} attempt used to keep track of number of attempts if manualOperation fails (not actually increased)
   */
  const createManualOperation = async (
    type,
    opInfo,
    zones,
    auto = true,
    templateSeeding = false,
    attempt = 0,
  ) => {
    setSaving(true);
    // console.log('opInfo :>> ', opInfo);
    // boolean for easier comparisons later
    const generic = type === 'Cost' || type === 'Revenue' || type === 'Field Pass';

    // create the operation record which will be stored in the database
    let record = createOperationRecord(opInfo, type);

    // create geojson boundaries which will be converted into shapefiles & rasters
    const geojson = createOperationGeojson({ ...opInfo }, zones, record, type);

    // for seeding and harvest combine variety summary records
    if (type === 'Seeding' || type === 'Harvest') {
      const recordCopy = JSON.parse(JSON.stringify(record));
      record = combineManualVarieties(recordCopy, type);
      if (record.varietyList.length !== opInfo.varieties.length) {
        // if varieties were combined for record then updated opInfo to match
        const updatedVarietes = combineOpVarieties(opInfo, record, type);
        opInfo.varieties = updatedVarietes;
      }
    }

    // format the request to send to the manual operation creation api
    const opReq = {
      Operation_Type: generic ? 'Generic' : type,
      Operation_Record: record,
      GeoJSON: geojson,
    };

    // console.log('opReq :>> ', opReq);
    // return
    // send request to backend to create operation, response will be json string of newly created Manual Operation
    const response = await df.manualOperation(opReq);

    // Before giving failure message retry creation
    if (response === undefined && attempt === 0) {
      setTimeout(async () => {
        await createManualOperation(
          type,
          opInfo,
          zones,
          true,
          false,
          attempt + 1,
        );
      }, 500);
      return;
    }

    if (response === undefined) {
      enqueueSnackbar('Failed to Create Manual Operation');
      setSaving(false);
      return;
    }
    if (!auto) {
      let message = 'Manual Operation Updated';
      if (opInfo.operationID === '' || opInfo.operationID === undefined) {
        message = type === 'Application' || generic
          ? `${type} Operation: ${opReq.Operation_Record.Name} Created`
          : type === 'Tillage'
            ? `${type} Operation: ${opInfo.productName} Created`
            : `${type} Operation: ${record.CropName} Created`;
      }
      enqueueSnackbar(message);
    }
    const newOp = JSON.parse(response);

    // if operation was created, update the record for the profit map schema
    // else if the operation is an edit, don't add operation to profit map schema
    // save would have been completed in Update<Operation> save function
    if (opInfo.operationID === '' || opInfo.operationID === undefined) {
      // add some necessary info before saving to Profit map schema
      opInfo.operationID = newOp.Operation_Record.ID;
      opInfo.area = type === 'Harvest' || generic ? record.Area : record.AreaResult;
      opInfo.quantity = type === 'Seeding' || type === 'Application'
        ? record.TotalMaterialResult
        : record.Yield;
      opInfo.unit = type === 'Seeding' || type === 'Application'
        ? record.TotalMaterialResultUnit
        : record.YieldUnit;

      // console.log (opInfo, type)
      const savedOp = await saveNewOp(opInfo, type);

      if (savedOp === undefined) {
        enqueueSnackbar('Failed to Save Manual Operation');
        setSaving(false);
        return;
      }
      if (!auto) {
        enqueueSnackbar('Manual Operation Saved');
      }

      // format the record for display on the UI
      // let correctOp =
      //   type === "Application"
      //     ? formatSaveRespone(savedOp[0], type)
      //     : formatSaveRespone(savedOp, type);

      // add the operation to the displayed operations list
      if (!auto) {
        switch (type) {
          case 'Seeding':
            // this calls getSeedingOperations then updates state
            await handleSeedingOperations(
              field.orgId,
              field.id,
              selectedYears[0],
              selectedYears,
              false,
              true,
            );
            break;
          case 'Harvest':
            await getHarvestOperations(
              field.orgId,
              field.id,
              selectedYears[0],
              selectedYears,
              false,
              true,
            );
            break;
          case 'Application':
            await getApplicationOperations(
              field.orgId,
              field.id,
              selectedYears[0],
              selectedYears,
              false,
              true,
            );
            break;
          case 'Cost':
            // refresh list
            const costUpdate = await getGenericOperations(
              field.orgId,
              field.id,
              selectedYears[0],
              selectedYears,
              false,
              true,
            );
            // add price to price tool
            const priceUpdate = costUpdate.costs.filter(
              (x) => x.genericOperationID === opInfo.operationID,
            )[0];
            addGenericPriceFromOperation(priceUpdate);
            df.createOthersForOrg({
              ManualOperation: newOp,
              FieldOperation: savedOp[0],
            });
            break;
          case 'Revenue':
            const revUpdate = await getGenericOperations(
              field.orgId,
              field.id,
              selectedYears[0],
              selectedYears,
              false,
              true,
            );
            // add price to price tool
            const revPriceUpdate = revUpdate.revenue.filter(
              (x) => x.genericOperationID === opInfo.operationID,
            )[0];
            addGenericPriceFromOperation(revPriceUpdate);
            df.createOthersForOrg({
              ManualOperation: newOp,
              FieldOperation: savedOp[0],
            });
            break;
          case 'Field Pass':
            if (!auto) {
              // console.log('actually generating field pass')
              await getGenericOperations(
                field.orgId,
                field.id,
                selectedYears[0],
                selectedYears,
              );
            }
            break;
          case 'Tillage':
            // console.log('generating tillage')
            await getTillageOperations(
              field.orgId,
              field.id,
              selectedYears[0],
              selectedYears,
            );
            break;
        }
      }

      setSaving(false);
      // needed for template yield forecasting
      if (templateSeeding) {
        return opInfo.operationID;
      }
    } else {
      // need to update/replace operation in list with updated version after edit
      const indx = null;
      opInfo.source = 'USERENTERED';

      // update this to include application (and generics??)
      if (type === 'Seeding') {
        /// / format date to match others in operation
        // let dashDate = opInfo["date"].split('-')
        // let formattedDate = `${dashDate[1]}/${dashDate[2]}/${dashDate[0]}`
        // opInfo["date"] = formattedDate
        // indx = seedings.findIndex(s => s.operationID === opInfo.operationID)
        // let scopy = JSON.parse(JSON.stringify(seedings))
        // scopy.splice(indx, 1)
        // scopy.splice(indx, 0 , opInfo)
        // setSeedings(scopy)
        // console.log('getting seeding operations')
        await handleSeedingOperations(
          field.orgId,
          field.id,
          selectedYears[0],
          selectedYears,
          false,
          true,
        );
      } else if (type === 'Harvest') {
        // format date to match others in operation
        // let dashDate = opInfo["date"].split('-')
        // let formattedDate = `${dashDate[1]}/${dashDate[2]}/${dashDate[0]}`
        // opInfo["date"] = formattedDate

        // indx = harvests.findIndex(s => s.operationID === opInfo.operationID)
        // let hcopy = JSON.parse(JSON.stringify(harvests))
        // hcopy.splice(indx, 1)
        // hcopy.splice(indx, 0, opInfo)
        //  setHarvests(hcopy)

        await getHarvestOperations(
          field.orgId,
          field.id,
          selectedYears[0],
          selectedYears,
          false,
          true,
        );
      } else if (type === 'Application') {
        await getApplicationOperations(
          field.orgId,
          field.id,
          selectedYears[0],
          selectedYears,
          false,
          true,
        );
      } else if (generic) {
        getGenericOperations(
          field.orgId,
          field.id,
          newOp.Operation_Record.CropSeason,
          selectedYears,
          false,
          true,
        );
      } else if (type === 'Tillage') {
        await getTillageOperations(
          field.orgId,
          field.id,
          selectedYear,
          selectedYears,
        );
      }
    }
    setProfitMapNeedsUpdating(true);
    setSaving(false);
  };

  const addGenericPriceFromOperation = async (operation) => {
    const request = {
      isDeleted: 0,
      isPrefered: 1,
      operationType: 'Generic',
      orgId: field.orgId,
      price: operation.price,
      productName: operation.productName,
      year: operation.year,
    };
    try {
      const response = await fetch(Endpoints.BASEURL + Endpoints.ADDPRICES, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
        },
        body: JSON.stringify(request),
      });
      if (response.status !== 200) {
        console.log(`Failed to save: ${response.status}`);
      }
    } catch (error) {
      console.log('Error saving price', error);
    }
  };

  const formatSaveRespone = (op, type) => {
    if (type === 'Seeding' || type === 'Harvest') {
      const date = op[0].date.split('-');
      const toplevelOp = {
        date: [date[1], date[2], date[0]].join('/'),
        hidden: false,
        name: op[0].productName,
        operationID: op[0].operationID,
        source: 'USERENTERED',
        varieties: op,
      };

      return toplevelOp;
    }
    if (type === 'Application') {
      // add averageMaterialResult and whatever other key is missing
      for (const component of op.applicationComponents) {
        component.rate = component.quantity;
        component.quantity *= component.area;
      }
      return op;
    }
    // generic
    // return
    const date = op[0].date.split('-');
    const area = op.map((x) => x.area).reduce((prev, next) => prev + next, 0);
    const zoneTotal = op
      .map((x) => x.rate * x.area)
      .reduce((prev, next) => prev + next, 0);
    const rate = zoneTotal / area;
    const name = op[0].productName.substring(0, op[0].productName.length - 1);
    const generic = {
      area,
      areaUnit: 'ac',
      aux1: parseFloat(op[0].operationCost) > 0 ? 'Revenue' : 'Cost',
      cellsize: '0.0001',
      cropSeason: date[0],
      date: [date[1], date[2], date[0]].join('/'),
      genericOperationID: op[0].operationID,
      isDeleted: false,
      productName: name,
      rate,
      zones: op,
    };
    op.source = 'USERENTERED';

    return op;
  };

  const convertToFieldOp = (opInfo, type) => {
    const fieldOperationList = [];
    if (type === 'Application') {
      const comps = opInfo.tankMix;

      const fieldOp = {
        OperationID: opInfo.operationID,
        OperationCost: opInfo.operationCost,
        Area: opInfo.area,
        FileType: type,
        Price: opInfo.price,
        Unit: opInfo.unit,
        Cellsize: opInfo.cellsize,
        ProductName: opInfo.productName,
        ApplicationComponents: [],
        Date: opInfo.date,
        LogID: opInfo.logID !== undefined ? opInfo.logID : null,
        Source: 'USERENTERED',
        Quantity: opInfo.quantity,
        Attribute: 'AppliedRate',
        // OperationFilePath: `Rasterized/ApplicationRasters/${field.orgId}/${opInfo.operationID}/`,
      };

      for (let i = 0; i < comps.length; i++) {
        const component = {
          ApplicationOperationID: opInfo.operationID,
          ProductName: comps[i].productName,
          Price: comps[i].price,
          LogID: comps[i].logID !== undefined ? comps[i].LogID : null,
          Area: opInfo.area,
          Quantity:
            comps[i].quantityPerAcre !== undefined
              ? comps[i].quantityPerAcre
              : null,
          Unit: comps[i].unit,
        };
        fieldOp.ApplicationComponents.push(component);
      }
      fieldOperationList.push(fieldOp);
    } else if (type === 'Seeding' || type === 'Harvest') {
      const vars = opInfo.varieties;
      for (let i = 0; i < vars.length; i++) {
        const fieldOp = {
          OperationID: opInfo.operationID,
          Area: vars[i].area,
          OperationCost: vars[i].operationCost,
          FileType: vars[i].fileType,
          Price: vars[i].price,
          Cellsize: vars[i].cellsize,
          ProductName: vars[i].productName,
          Date: vars[i].date,
          IsOrganic: vars[i].isOrganic,
          IsIrrigated: vars[i].isIrrigated,
          Variety: vars[i].variety,
          LogID: vars[i].logID,
          Source: 'USERENTERED',
          Quantity: vars[i].quantity,
          Unit: vars[i].unit,
          Attribute: type === 'Seeding' ? 'AppliedRate' : 'VRYIELDVOL',
          // OperationFilePath: `Rasterized/${type}Rasters/${field.orgId}/${opInfo.operationID}/`,
        };
        fieldOperationList.push(fieldOp);
      }
    } else {
      const { zones } = opInfo;
      let i = 1;
      for (const zone of zones) {
        const cost = type === 'Revenue'
          ? (zone.area * zone.rate * zone.price).toFixed(2)
          : 1 - (zone.area * zone.rate * zone.price).toFixed(2);

        const fOp = {
          OperationID:
            opInfo.operationID !== undefined
              ? opInfo.operationID
              : opInfo.genericOperationID,
          Area: zone.area,
          OperationCost: cost,
          FileType: type === 'Tillage' ? 'Tillage' : 'Generic',
          Price: zone.price,
          Unit: opInfo.unit !== undefined ? opInfo.unit : opInfo.totalUnit,
          Cellsize: '0.0001',
          ProductName:
            type !== 'Tillage'
              ? opInfo.productName + i.toString()
              : opInfo.productName,
          Date: opInfo.date,
          Quantity: (zone.area * zone.rate).toFixed(2),
          Rate: zone.rate !== undefined ? zone.rate : 1,
        };
        i++;
        fieldOperationList.push(fOp);
      }
    }
    return fieldOperationList;
  };

  const saveNewOp = async (opInfo, type) => {
    // console.log('saveNewOp', opInfo, type)
    const fieldOperationList = convertToFieldOp(opInfo, type);
    let res = await handleIndividualOperation(fieldOperationList);

    if (type === 'Application') {
      res[0].averageMaterialResult = res[0].quantity / parseFloat(res[0].area.toFixed(3));
      res[0].totalMaterialUnit = res[0].unit;
      res[0].applicationComponents = res[0].applicationComponents.map((x) => ({
        ...x,
        unit: con.unitConversion(x.unit),
      }));
    } else {
      // add rate to seeding and harvest
      res = res.map((x) => ({ ...x, rate: +(x.quantity / x.area).toFixed(2) }));
    }

    return res;
  };

  const logBinRanges = async (orgID, binRanges) => {
    const res = await df.logBinRange(orgID, binRanges);
  };

  const pushProfitMap = async (orgId, fieldId, response) => {
    ReactGA.event({
      category: "Feature Usage",
      action: "Pushed Profit Map to ops Center"
    })
    const varietyDetail = await setVariety(response);
    const eventTimeLine = await setEventTimeLine();
    let total = displayTotal();
    total = parseFloat(total.replace('$', ''));

    response[0].TotalProfit = total * netSeededAcres;
    response[0].NetSeededAcre = netSeededAcres;
    const totalCost = seedCost + applicationCost + fieldPassCost + otherCostsTotal;
    response[0].Cost = `${totalCost},${seedCost},${applicationCost},${
      fieldPassCost + otherCostsTotal
    }`;
    response[0].varietyDetails = varietyDetail;
    response[0].eventTimeLine = eventTimeLine;

    const pushMap = await df.pushProfitMap(
      orgId,
      fieldId,
      selectedYears[0],
      response[0],
    );

    if (pushMap === undefined) {
      enqueueSnackbar('Failed to Push Profit Map');
    } else {
      enqueueSnackbar('Profit Map Pushed Successfully');
    }
  };

  const zoneAnalysis = async (
    opnId,
    operations,
    legendColor,
    legendRange,
    retry = 0,
  ) => {
    retry += 1;
    setZoneAnalysisLoader(true);
    const request = {
      FieldOperationList: operations,
      MultiYears: selectedYears,
      LegendColors: legendColor,
      ProfitMapLegendRange: legendRange,
    };

    const res = await df.zoneAnalysis(
      field.orgId,
      field.id,
      selectedYears[0],
      1,
      opnId,
      request,
    );
    setZoneAnalysisLoader(false);
    // console.log(res)
    if (res.length > 0) {
      setZoneAnalysisRes(res);
      // Return true for valid response
      return true;
    }

    if (retry <= 1) {
      zoneAnalysis(opnId, operations, legendColor, legendRange, retry);
    } else {
      setZoneAnalysisRes([]);
      enqueueSnackbar('Failed to perform zone analysis');
      // Toast will be set off in ReportMap if viewing zone analysis
      return false;
    }
  };

  /**
   * Sets and returns NPK rates for operation, if passed operation is an application operation. If not returns empty object.
   * 1. Check if passed operation is an application operation or not
   * 2. If application operation,
   *    2a. call "Profittool/ProfitLayers/GetApplicationForParser" api to get all column values from fieldManager.ApplicationOperations and fieldManager.ApplicationComponenets
   *    2b. Format the request to be passed to NutrientParser API
   *    2c. setNPK with response returned by API
   * 3. If not an application operation then setNPK with empty object
   * @param {Object} operation
   */
  const getNutrientParser = async (operation) => {
    try {
      if (operation.fileType.toUpperCase() === 'APPLICATION') {
        let applicationOp = await df.getApplicationForParser(
          field.orgId,
          field.id,
          operation.id,
        );
        applicationOp = JSON.parse(applicationOp);

        const componentBody = [];

        const obj = {};

        for (let i = 0; i < applicationOp.length; i++) {
          const varName = `Comp_${i + 1}`;
          const modifiedObj = {
            Name: applicationOp[i].Name,
            Rate: applicationOp[i].Rate,
            RateUnit: applicationOp[i].RateUnit,
            TotalMaterialResult: applicationOp[i].TotalMaterialResult,
            TotalMaterialResultUnit: applicationOp[i].TotalMaterialResultUnit,
            TotalMaterialTarget: applicationOp[i].TotalMaterialTarget,
            TotalMaterialTargetUnit: applicationOp[i].TotalMaterialTargetUnit,
            AverageMaterialResult: applicationOp[i].AverageMaterialResult,
            AverageMaterialResultUnit:
              applicationOp[i].AverageMaterialResultUnit,
            AverageMaterialTarget: applicationOp[i].AverageMaterialTarget,
            AverageMaterialTargetUnit:
              applicationOp[i].AverageMaterialTargetUnit,
            IsCarrier: applicationOp[i].IsCarrier === true ? 'true' : 'false',
            ApplicationOperationID: applicationOp[i].ApplicationOperationID,
            IsTankMix: applicationOp[i].IsTankMix === true ? 'true' : 'false',
          };

          obj[varName] = modifiedObj;
          componentBody.push(obj);
        }

        const totalOps = {
          ID: operation.id,
          Name: applicationOp[0].OpName,
          Rate: applicationOp[0].OpRate,
          RateUnit: applicationOp[0].OpRateUnit,
          IsTankMix: applicationOp[0].IsTankMix === true ? 'true' : 'false',
          CarrierName: applicationOp[0].CarrierName,
          CarrierRate: applicationOp[0].CarrierRate,
          CarrierRateUnit: applicationOp[0].CarrierRateUnit,
          AreaResult: applicationOp[0].OpAreaResult,
          AreaResultUnit: applicationOp[0].OpAreaResultUnit,
          AreaTarget: applicationOp[0].OpAreaTarget,
          AreaTargetUnit: applicationOp[0].OpAreaTargetUnit,
          TotalMaterialResult: applicationOp[0].OpTotalMaterialResult,
          TotalMaterialResultUnit: applicationOp[0].OpTotalMaterialResultUnit,
          TotalMaterialTarget: applicationOp[0].OpTotalMaterialTarget,
          TotalMaterialTargetUnit: applicationOp[0].OpTotalMaterialTargetUnit,
          AverageMaterialResult: applicationOp[0].OpAverageMaterialResult,
          AverageMaterialResultUnit:
            applicationOp[0].OpAverageMaterialResultUnit,
          AverageMaterialTarget: applicationOp[0].OpAverageMaterialTarget,
          AverageMaterialTargetUnit:
            applicationOp[0].OpAverageMaterialTargetUnit,
        };

        const request = {
          Application_Operation: `{"Application_Operation": {"Entry_Type": "Precision", "Process_Types": ["npk"], "Total": ${JSON.stringify(
            totalOps,
          )}, "Components": ${JSON.stringify(obj)}}}`,
        };
        // console.log("request", request, JSON.stringify(request))
        const res = await df.nutrientParser(request);
        // console.log(res, res.NPK.npks)
        setNPK(res.NPK.npks);
      } else {
        setNPK({});
      }
    } catch (err) {
      console.log(`Problem with nutrient parser: ${err}`);
    }
  };

  const setEventTimeLine = async () => {
    let maxDate = null;
    let eventTimeLine = null;

    if (displayedHarvests.length > 0) {
      for (let i = 0; i < displayedHarvests.length; i++) {
        const harvestDate = new Date(displayedHarvests[i].date).toISOString();
        if (maxDate == null || maxDate < harvestDate) {
          maxDate = harvestDate;
          eventTimeLine = new Date(displayedHarvests[i].date).toISOString();
        }
      }
    } else if (displayApplicationOps.length > 0) {
      for (let i = 0; i < displayApplicationOps.length; i++) {
        const applicationDate = new Date(displayApplicationOps[i].startDate);
        if (maxDate == null || maxDate < applicationDate) {
          maxDate = applicationDate;
          eventTimeLine = displayApplicationOps[i].startDate;
        }
      }
    } else if (displayedSeedings.length > 0) {
      for (let i = 0; i < displayedSeedings.length; i++) {
        const seedingDate = new Date(displayedSeedings[i].date).toISOString();
        if (maxDate == null || maxDate < seedingDate) {
          maxDate = seedingDate;
          eventTimeLine = new Date(displayedSeedings[i].date).toISOString();
        }
      }
    }

    return eventTimeLine;
  };

  const setVariety = async (response) => {
    let varietyDetail = '';

    for (let i = 0; i < response.length; i++) {
      if (
        response[i].variety.toUpperCase() !== 'NOVARIETY'
        && response[i].variety.toUpperCase() !== 'VARIETY'
      ) {
        varietyDetail += `${response[i].variety},${
          Math.round(response[i].percentile5 * 100) / 100
        },${Math.round(response[i].mean * 100) / 100},${
          Math.round(response[i].percentile95 * 100) / 100
        };`;
      }
    }
    return varietyDetail;
  };

  const handleIndividualOperation = async (op) => {
    console.log('handleIndividualOperation');
    try {
      setIsSaving(true);
      let opn;
      let operation = {};
      // If all seeding varieties had seed rate >= 1,000 they have been converted
      // to K seeds. We need to convert back before saving.
      if (op[0].unit === 'K seeds') {
        operation = op.map((variety) => convertKseedsToSeeds(variety));
      } else {
        operation = op;
      }

      opn = {
        FieldOperationList: operation,
        Year:
          op[0].Date !== undefined
            ? new Date(op[0].Date).getFullYear()
            : selectedYears[0],
      };

      const handleOperation = await df.saveOperations(
        field.orgId,
        field.id,
        opn,
      );
      setIsSaving(false);

      if (handleOperation !== undefined) {
        setProfitMapNeedsUpdating(true);
        return handleOperation.fieldOperationList;
      }

      return [];
    } catch (err) {
      setIsSaving(false);
      console.log(err);
    }
  };

  const convertKseedsToSeeds = (variety) => {
    // Object contains no objects as values, so we can shallow copy
    const converted = { ...variety };
    converted.averageMaterialResult = variety.averageMaterialResult * 1000;
    converted.averageMaterialTarget = variety.averageMaterialTarget * 1000;
    converted.price = variety.price / 1000;
    converted.quantity = variety.quantity * 1000;
    converted.rate = variety.rate * 1000;
    converted.totalMaterial = variety.totalMaterial * 1000;

    // Original unit should be present for all converted seeding ops, but check in case
    converted.unit = variety?.originalUnit !== undefined ? variety.originalUnit : 'seeds';

    return converted;
  };

  const handleReportNameChange = (action) => {
    if (action === 'save') {
      setReportName(newReportName);
    }
    setNewReportName('');
    setEditReportName(false);
  };

  const resetData = async (refresh = false) => {
    let response = 'Successful';
    if (!refresh) {
      response = await df.resetData(field.orgId, field.id, selectedYears[0]);
    }
    if (response === 'Successful') {
      setLoaded([]);
      getData(field.orgId, field.id, selectedYears[0]);
      setProfitMapNeedsUpdating(true);
    }
  };

  const updatePrices = async () => {
    setLoaded([]);
    getData(field.orgId, field.id, selectedYears[0], selectedYears, true);
    setProfitMapNeedsUpdating(true);
  };

  const showOpTotal = (i) => {
    // potential not numbers returned from numFormat
    switch (i) {
      case 0:
        const harvestTotal = numFormat(Math.abs(harvestRevenue));
        return `$${nans.includes(harvestTotal) ? '0.00' : harvestTotal}`;
      case 1:
        const seedingTotal = numFormat(Math.abs(seedCost));
        return `$${nans.includes(seedingTotal) ? '0.00' : seedingTotal}`;
      case 2:
        const applicationTotal = numFormat(Math.abs(applicationCost));
        return `$${
          nans.includes(applicationTotal) ? '0.00' : applicationTotal
        }`;
      case 3:
        const fieldPassTotal = numFormat(Math.abs(fieldPassCost));
        return `$${nans.includes(fieldPassTotal) ? '0.00' : fieldPassTotal}`;
      case 4:
        const costsTotal = numFormat(Math.abs(otherCostsTotal));
        return `$${nans.includes(costsTotal) ? '0.00' : costsTotal}`;
      case 5:
        const otherRevTotal = numFormat(Math.abs(otherRevenueTotal));
        return `$${nans.includes(otherRevTotal) ? '0.00' : otherRevTotal}`;
      default:
        return '0.00';
    }
  };

  const getOperationTotals = () => {
    const costs = seedCost + applicationCost + fieldPassCost + otherCostsTotal;
    const revenue = harvestRevenue + otherRevenueTotal;
    const total = costs + revenue;

    setTotalCosts(costs);
    setTotalRevenue(revenue);
    setTotalProfit(total);
  };

  const handleClose = () => {
    // console.log('handleClose')
    setSeedings([]);
    setSeedingOperations([]);
    setHarvests([]);
    setHarvestOperations([]);
    setGenericOperations([]);
    setApplicationOperations([]);
    setFieldPasses([]);

    // console.log("called");
    setOpenConfirm(false);
    getData(
      field.orgId,
      field.id,
      selectedYears[0],
      selectedYears,
      false,
      true,
    );
    // setOpen(false);
    setTemplateMode(false);
    setSelectedTemplate({ ID: '', Name: '' });
    setCurrentTemplate();
  };

  const displayTotal = () => {
    const total = seedCost
      + applicationCost
      + fieldPassCost
      + otherCostsTotal
      + harvestRevenue
      + otherRevenueTotal;
    // setTotalProfit(total)
    return total > 0
      ? `$${total.toFixed(2)}`
      : `-$${Math.abs(total).toFixed(2)}`;
  };

  const syncData = async (type) => {
    ReactGA.event({
      category: "Feature Usage",
      action: "Sync Data",
      label: `Sync ${type} data from settings`
    })
    // close the popover
    setDataSyncPopoverAnchor(null);
    setDataSyncPopoverOpen(false);

    // send the request to start a sync operation
    if (field && field.orgId && field.id) {
      setSyncStatus('sent');
      // console.log('source', source)
      const syncResult = await df.syncData(field.orgId, field.id, type, source);
      setSyncStatus('received');

      // toast
      if (syncResult.status === 200) {
        enqueueSnackbar('Data Sync Started Successfully');
      } else if (syncResult.status === 202) {
        // a 202 means that the user needs to reintegrate
        setReintegrateNecessary(true);
        enqueueSnackbar('Data Sync Failed to Start');
      } else if (syncResult.status !== 200) {
        enqueueSnackbar('Data Sync Failed to Start');
      }
    } else {
      // do nothing
      console.log("Can't sync data right now");
    }
  };

  const deereEtlByField = async () => {
    // close popover
    setDataSyncPopoverAnchor(null);
    setDataSyncPopoverOpen(false);

    if (field && field.orgId && field.id) {
      setSyncStatus('sent');
      const syncResult = await df.syncDeereFieldData(
        field.orgId,
        field.id,
        'operations',
        'Deere',
      );
      setSyncStatus('received');

      // toast
      if (syncResult.status === 200) {
        enqueueSnackbar('Data Sync Started Successfully');
      } else if (syncResult.status === 202) {
        // a 202 means that the user needs to reintegrate
        setReintegrateNecessary(true);
        enqueueSnackbar('Data Sync Failed to Start');
      } else if (syncResult.status !== 200) {
        enqueueSnackbar('Data Sync Failed to Start');
      }
    } else {
      // do nothing
      console.log("Can't sync data right now");
    }
  };

  const syncOperation = async (op) => {
    console.log(op);
    if (field && field.orgId && field.id) {
      setSyncStatus('sent');
      const result = await df.syncDeereFieldData(
        field.orgId,
        field.id,
        'operation',
        'Deere',
        op.operationID,
      );

      if (result.status === 200) {
        await sleep(5000); /// wait 5 seconds then refresh operations
        setSyncStatus('received');
        resetData(true);
        // enqueueSnackbar("Data Sync Started Successfully");
      } else if (result.status === 202) {
        // a 202 means that the user needs to reintegrate
        setSyncStatus('received');
        setReintegrateNecessary(true);
        enqueueSnackbar('Data Sync Failed to Start');
      } else if (result.status !== 200) {
        setSyncStatus('received');
        enqueueSnackbar('Data Sync Failed to Start');
      }
    }
  };

  const refreshPrices = () => {
    setLoaded([]);
    getData(field.orgId, field.id, selectedYears[0], selectedYears, true);
  };

  const openPriceTool = (op) => {
    // open price tool in ProfitLayers to selected operation
    setPriceToolOpen(true);
    setPriceToolStartingOp(op);
  };

  const goToPriceTool = () => {
    ReactGA.event({
      category: "Feature Usage",
      action: "Price Converter",
      label: "Accessed from Actions menu"
    })
    setPriceToolOpen(true);
    if (opTabValue === 0) {
      setPriceToolStartingOp('Seeding');
    } else if (opTabValue === 1) {
      setPriceToolStartingOp('Application');
    } else if (opTabValue === 4) {
      setPriceToolStartingOp('Harvest');
    } else {
      setPriceToolStartingOp('Other Expenses/Revenue');
    }
  };

  const changeSection = (val) => {
    if (authenticated) {
      if (field.id !== '' && field.source !== 'new') {
        if (val === 2) {
          goToViewProfitLayers();
        } else {
          setValue(val);
        }
      } else if (
        field.source === 'new'
        || fieldData.fieldToClaim?.feature !== null
      ) {
        setFieldData({
          ...fieldData,
          fieldToClaim: { ...fieldData.fieldToClaim, claiming: true },
        });
        setClaimField(true);
      } else {
        enqueueSnackbar(
          'Please select a field before setting up Profit Layers',
        );
      }
    } else {
      setLoginPromptOpen(true);
      setToContinuePrompt('Sign in to continue using the tool');
    }
  };

  const goToViewProfitLayers = () => {
    // Check operations are loaded before going to view profit layers
    // console.log('going to pl')
    console.log('isSaving', isSaving);
    if (
      operationsLoaded.current.includes('Harvest')
      && operationsLoaded.current.includes('Seeding')
      && operationsLoaded.current.includes('Applications')
      && operationsLoaded.current.includes('Generic')
      && operationsLoaded.current.includes('Tillage') && !isSaving
    ) {
      setValue(2);
    } else {
      enqueueSnackbar(
        'Please wait to View ProfitLayers until all operations have loaded.',
        { preventDuplicate: true },
      );
      if (value === 0) {
        setValue(1);
      }
    }
  };

  const handleContinue = () => {
    if (
      authenticated
      && field.orgId !== ''
      && field.id !== ''
      && field.source !== 'new'
    ) {
      if (value == 2) {
        changeSection(3);
      } else if(value == 3){
        changeSection(4);
      } 
      else {
        if (opTabValue === 5) {
          if (!templateMode) {
            // If on last operation section, continue will go View Report
            ReactGA.event({
              category: "Navigation",
              action: "Navigate To View Profitlayers",
              label: "Change to View ProfitLayers with continue button"
            })
            changeSection(2);
          } else {
            // If in template mode, circle back to first operation
            setOpTabValue(0);
          }
        } else {
          ReactGA.event({
            category: "Navigation",
            action: "Change Setup Section",
            label: "Change section with continue button"
          })
          setOpTabValue(opTabValue + 1);
        }
      }
    } else if (authenticated && field.source === "new") {
      setClaimField(true);
    } else {
      setLoginPromptOpen(true);
      setToContinuePrompt("Sign in to continue using the tool");
    }
  };

  const openYearSelection = (event) => {
    setSelectYearsOpen(event.currentTarget);
  };

  const handleMultiYearSelect = () => {
    resetState();
    setLoaded([]);

    // sort years before sending to getData?
    // for now only using one year. lots of stuff breaks on multi
    getData(field.orgId, field.id, selectedYears[0]);
  };

  const handleYearSelect = (val) => {
    if (selectedYears.length === 1 && val === selectedYears[0]) {
      console.log('dont update year');
    } else {
      resetState();
      setLoaded([]);
      setSelectedYears([val]);
      setSelectedYear(val);
      getData(field.orgId, field.id, val, [val]);
    }
  };

  const selectMultipleYears = (val) => {
    if (selectedYears.includes(val)) {
      const updated = selectedYears.filter((x) => x !== val);
      setSelectedYears(updated);
    } else {
      setSelectedYears((prev) => [...prev, val]);
    }
  };

  const handleTemplatesAction = () => {
    // resetState();
    // console.log(loaded)
    if (loaded.length < 5) {
      enqueueSnackbar('Please wait for data to finish loading', {
        autoHideDuration: 3000,
        preventDuplicate: true,
      });
    } else {
      setOpen(true);
      setTemplatesLoading(true);
    }
  };
  // if decide to use arrows for continous year selection
  const yearIcon = (year) => {
    if (selectedYears.includes(year)) {
      return <FiberManualRecordRoundedIcon />;
    }

    const sorted = selectedYears.sort((a, b) => a > b);
    if (selectedYears.length === 1) {
      return year > sorted ? (
        <ArrowDownwardRoundedIcon />
      ) : (
        <ArrowUpwardRoundedIcon />
      );
    }
    if (year > sorted[sorted.length - 1]) {
      return <ArrowDownwardRoundedIcon />;
    }
    if (year < sorted[0]) {
      return <ArrowUpwardRoundedIcon />;
    }
    return <FiberManualRecordRoundedIcon style={{ color: green }} />;
  };

  /**
   * forecasts a harvest operation for template creation
   * @param {int} appliedYear the year the template is being applied to
   * @param {Object} seedingOperation the CORN seeding operation the yield is being forecasted for (OPTIONAL)
   */
  const templateYield = async (appliedYear, seedingOperation = null) => {
    let density = 30000;
    if (seedingOperation !== null) {
      const totalArea = seedingOperation.varieties
        .map((x) => x.area)
        .reduce((a, b) => a + b, 0);
      let tempDensity = 0;
      for (const variety of seedingOperation.varieties) {
        tempDensity += (variety.rate * variety.area) / totalArea;
      }
      density = tempDensity;
    }

    let date = `${appliedYear}-05-01`;
    if (seedingOperation !== null) {
      const brkn = seedingOperation.date.split('/');
      const fixed = [brkn[2], brkn[0], brkn[1]].join('-');
      date = fixed;
    }

    const boundary = JSON.parse(field.boundary);
    boundary.properties.CALCACRES = field.acres;
    const shape = JSON.stringify(boundary);

    const request = {
      ModelType: 'NN',
      ModelVersion: 'v4.0.1',
      SHAPE: shape,
      CropSeason: appliedYear,
      CropName: 'CORN',
      PlantingDay: date,
      SeedingDensity: density,
      CornAfterCorn: 0,
      HarvestDay: `09-01-${appliedYear}`,
    };

    const seedingID = seedingOperation === null ? null : seedingOperation.operationID;
    const yieldRes = await df.requestYieldForecast(
      request,
      field.id,
      field.orgId,
      seedingID,
    );
    getHarvestOperations(
      field.orgId,
      field.id,
      selectedYears[0],
      selectedYears,
      false,
      true,
    );
  };

  const saveAndApplyTemplate = async () => {
    const operations = getProfitMapOperations();

    const tempSeedingOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'SEEDING'
        && op.source.toUpperCase() === 'TEMPLATE',
    );
    const tempHarvestOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'HARVEST'
        && op.source.toUpperCase() === 'TEMPLATE',
    );
    const tempapplicationOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'APPLICATION'
        && op.source.toUpperCase() === 'TEMPLATE',
    );
    const tempGenericOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'GENERIC'
        && op.source.toUpperCase() === 'TEMPLATE',
    );
    const tempTillageOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'TILLAGE'
        && op.source.toUpperCase() === 'TEMPLATE',
    );

    const formattedSeeding = [];
    const formattedHarvest = [];
    const formattedApplication = [];
    const formattedGeneric = [];
    const formattedTillage = [];

    for (var i = 0; i < tempSeedingOps.length; i++) {
      // Convert values back to seeds
      if (tempSeedingOps[i].unit === 'k seeds') {
        tempSeedingOps[i].unit = 'seeds';
        tempSeedingOps[i].totalMaterialUnit = 'seeds';
        tempSeedingOps[i].price = tempSeedingOps[i].price / 1000;
        tempSeedingOps[i].averageMaterialTarget = tempSeedingOps[i].averageMaterialTarget * 1000;
        tempSeedingOps[i].quantity = tempSeedingOps[i].quantity * 1000;
        tempSeedingOps[i].rate = tempSeedingOps[i].rate * 1000;
      }

      const obj = {
        CropSeason: tempSeedingOps[i].CropSeason,
        StartDate: tempSeedingOps[i].startDate,
        EndDate: tempSeedingOps[i].endDate,
        CropName: tempSeedingOps[i].productName,
        OpAverageMaterialTarget: tempSeedingOps[i].averageMaterialTarget,
        OpAverageMaterialTargetUnit:
          tempSeedingOps[i].averageMaterialTargetUnit,
        OpAverageMaterialResult: tempSeedingOps[i].averageMaterialTarget,
        OpAverageMaterialResultUnit:
          tempSeedingOps[i].averageMaterialTargetUnit,
        Source: 'TEMPLATE',
        AverageMaterial: tempSeedingOps[i].averageMaterialTarget,
        AverageMaterialUnit: tempSeedingOps[i].averageMaterialTargetUnit,
        Name: tempSeedingOps[i].name,
        TotalMaterialUnit: tempSeedingOps[i].unit,
        rate: tempSeedingOps[i].rate,
        Price: tempSeedingOps[i].price,
      };
      formattedSeeding.push(obj);
    }

    for (var i = 0; i < tempHarvestOps.length; i++) {
      const obj = {
        CropSeason: tempHarvestOps[i].CropSeason,
        StartDate: tempHarvestOps[i].startDate,
        EndDate: tempHarvestOps[i].endDate,
        CropName: tempHarvestOps[i].productName,
        OpAverageYield: tempHarvestOps[i].averageYield,
        OpAverageYieldUnit: tempHarvestOps[i].averageYieldUnit,
        Source: 'TEMPLATE',
        AverageYield: tempHarvestOps[i].averageYield,
        AverageYieldUnit: tempHarvestOps[i].averageYieldUnit,
        rate: tempHarvestOps[i].rate,
        Name: tempHarvestOps[i].name,

        YieldUnit: tempHarvestOps[i].unit,
        Price: tempHarvestOps[i].price,
      };
      formattedHarvest.push(obj);
    }

    for (var i = 0; i < tempapplicationOps.length; i++) {
      const obj = {
        CropSeason: tempapplicationOps[i].cropSeason,
        StartDate: tempapplicationOps[i].startDate,
        EndDate: tempapplicationOps[i].endDate,
        Name:
          tempapplicationOps[i].applicationComponents.length > 1
            ? 'Tank Mix'
            : tempapplicationOps[i].applicationComponents[0].productName,
        OpAverageMaterialTarget: tempapplicationOps[i].rate,
        OpAverageMaterialTargetUnit: tempapplicationOps[i].rateUnit,
        OpAverageMaterialResult: tempapplicationOps[i].rate,
        OpAverageMaterialResultUnit: tempapplicationOps[i].rateUnit,
        Source: 'TEMPLATE',
        OpRate: tempapplicationOps[i].rate,
        OpRateUnit: tempapplicationOps[i].rateUnit,
        IsTankMix: tempapplicationOps[i].applicationComponents.length > 1,
        CarrierName: tempapplicationOps[i].carrierName,
        CarrierRate: tempapplicationOps[i].carrierRate,
        CarrierRateName: tempapplicationOps[i].carrierRateUnit,
      };
      const comp = [];
      for (
        let j = 0;
        j < tempapplicationOps[i].applicationComponents.length;
        j++
      ) {
        const innerObj = {
          Name: tempapplicationOps[i].applicationComponents[j].name,
          AverageMaterialTarget:
            tempapplicationOps[i].applicationComponents[j]
              .averageMaterialResult,
          AverageMaterialTargetUnit:
            tempapplicationOps[i].applicationComponents[j]
              .averageMaterialResultUnit,
          AverageMaterialResult:
            tempapplicationOps[i].applicationComponents[j]
              .averageMaterialResult,
          AverageMaterialResultUnit:
            tempapplicationOps[i].applicationComponents[j]
              .averageMaterialResultUnit,
          Source: 'TEMPLATE',
          IsCarrier: tempapplicationOps[i].applicationComponents[j].isCarrier,
          Rate: tempapplicationOps[i].applicationComponents[j].rate,
          RateUnit: tempapplicationOps[i].applicationComponents[j].rateUnit,
          TotalMaterialResultUnit:
            tempapplicationOps[i].applicationComponents[j].unit,
          Price: Number(tempapplicationOps[i].applicationComponents[j].price),
        };
        comp.push(innerObj);
      }
      obj.applicationComponents = comp;
      formattedApplication.push(obj);
    }

    for (var i = 0; i < tempGenericOps.length; i++) {
      const obj = {
        CropSeason: tempGenericOps[i].cropSeason,
        StartDate: tempGenericOps[i].startDate,
        EndDate: tempGenericOps[i].endDate,
        Aux1: tempGenericOps[i].aux1,
        Aux2: tempGenericOps[i].name,
        Name: tempGenericOps[i].name,
        Rate: tempGenericOps[i].rate,
        RateUnit: tempGenericOps[i].rateUnit,
        Total: tempGenericOps[i].rate * field.acres,
        TotalUnit: tempGenericOps[i].totalUnit,
        Source: 'TEMPLATE',
        Price: tempGenericOps[i].price,
      };
      formattedGeneric.push(obj);
    }

    for (var i = 0; i < tempTillageOps.length; i++) {
      const obj = {
        CropSeason: tempTillageOps[i].cropSeason,
        StartDate: tempTillageOps[i].startDate,
        EndDate: tempTillageOps[i].endDate,
        TargetAverageDepth: tempTillageOps[i].targetAverageDepth,
        TargetAverageDepthUnit: tempTillageOps[i].targetAverageDepthUnit,
        // ResultAverageSpeed: tempTillageOps[i].resultAverageSpeed,
        // ResultAverageSpeedUnit: tempTillageOps[i].resultAverageSpeedUnit,
        Source: 'TEMPLATE',
        Price: tempTillageOps[i].price,
      };
      formattedTillage.push(obj);
    }

    const request = {
      profitTemplateScenario: {
        Name: customTemplateName,
        Region: '',
        Year: appliedTemplateYear,
      },
      seedingOperations: formattedSeeding,
      harvestOperations: formattedHarvest,
      applicationOperations: formattedApplication,
      genericOperations: formattedGeneric,
      tillageOperations: formattedTillage,
    };

    const res = await df.saveCropTemplate(request);
    // Uncomment code to loop through manual api
    handleGenProfitLayerTemplate();
  };
  const handleGenProfitLayerTemplateHelper = () => {
    setOpenConfirmLayers(true);
  };

  const handleSaveCropTemplate = async () => {
    if (customTemplateName !== '') {
      // if they gave the template a name
      saveAndApplyTemplate();
    } else {
      enqueueSnackbar('Please Enter a Name for Your Template');
    }
  };

  const handleCloseGeneratingPrompt = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setGeneratingPrompt(false);
  };

  const handleGenProfitLayerTemplate = async () => {
    setProgressModal(true);
    setOpenConfirmLayers(false);
    setGeneratingPrompt(true);

    const operations = getProfitMapOperations();

    const tempSeedingOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'SEEDING'
        && op.source.toUpperCase() === 'TEMPLATE',
    );
    const tempHarvestOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'HARVEST'
        && op.source.toUpperCase() === 'TEMPLATE',
    );
    const tempapplicationOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'APPLICATION'
        && op.source.toUpperCase() === 'TEMPLATE',
    );
    const tempGenericOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'GENERIC'
        && op.source.toUpperCase() === 'TEMPLATE',
    );
    const tempTillageOps = operations.filter(
      (op) => op.fileType.toUpperCase() === 'TILLAGE'
        && op.source.toUpperCase() === 'TEMPLATE',
    );

    const formatSeedForManualAPI = [];
    const formatHarForManual = [];
    const formatAppForManual = [];
    const formatGenericForManual = [];
    const formatTillageForManual = [];

    const count = tempSeedingOps.length
      + tempHarvestOps.length
      + tempapplicationOps.length
      + tempGenericOps.length
      + tempTillageOps.length;
    let counter = 0;

    // Format Seeding Ops
    for (var i = 0; i < tempSeedingOps.length; i++) {
      const varieties = [];
      const zones = [];

      const boundary = JSON.parse(field.boundary);
      boundary.properties = {
        AppliedRat: tempSeedingOps[i].quantity / field.acres,
        Area: field.acres,
        AreaUnit: 'ac',
        AverageMaterial: tempSeedingOps[i].quantity / field.acres,
        AverageMaterialUnit: `${tempSeedingOps[i].unit}1ac-1`,
        Crop: 1,
        FieldID: field.id,
        Name: tempSeedingOps[i].name,
        OrganizationID: field.orgId,
        TotalMaterial: tempSeedingOps[i].quantity,
        TotalMaterialUnit: tempSeedingOps[i].unit,
        Variety: tempSeedingOps[i].name,
      };

      // Convert values back to seeds
      if (tempSeedingOps[i].unit === 'k seeds') {
        tempSeedingOps[i].unit = 'seeds';
        tempSeedingOps[i].totalMaterialUnit = 'seeds';
        tempSeedingOps[i].price = tempSeedingOps[i].price / 1000;
        tempSeedingOps[i].averageMaterialTarget = tempSeedingOps[i].averageMaterialTarget * 1000;
        tempSeedingOps[i].quantity = tempSeedingOps[i].quantity * 1000;
        tempSeedingOps[i].rate = tempSeedingOps[i].rate * 1000;
      }

      const varietyObj = {
        area: field.acres,
        cellsize: '0.0001',
        date: formatDate(tempSeedingOps[i].endDate),
        fileType: 'Seeding',
        isIrrigated: false,
        isOrganic: false,
        operationCost: 0,
        operationID: '',
        price: tempSeedingOps[i].price,
        productName: tempSeedingOps[i].cropName,
        quantity: tempSeedingOps[i].quantity,
        source: 'USERENTERED',
        rate: tempSeedingOps[i].rate,
        total: tempSeedingOps[i].rate * tempSeedingOps[i].price,
        unit: tempSeedingOps[i].unit,
        variety: tempSeedingOps[i].name,
        zone: boundary,
      };

      varieties.push(varietyObj);
      zones.push(boundary);

      const obj = {
        date: formatDate(tempSeedingOps[i].endDate),
        hidden: false,
        name: tempSeedingOps[i].cropName,
        operationID: '',
        unit: tempSeedingOps[i].totalMaterialUnit,
        varieties,
        zones,
      };
      formatSeedForManualAPI.push(obj);
    }

    // Format Harvest Ops
    for (var i = 0; i < tempHarvestOps.length; i++) {
      const varieties = [];
      const zones = [];

      const boundary = JSON.parse(field.boundary);
      boundary.properties = {
        AppliedRat: tempHarvestOps[i].quantity / field.acres,
        Area: field.acres,
        AreaUnit: 'ac',
        AverageMaterial: tempHarvestOps[i].quantity / field.acres,
        AverageMaterialUnit: `${tempHarvestOps[i].unit}1ac-1`,
        Crop: 1,
        FieldID: field.id,
        Name: tempHarvestOps[i].name,
        OrganizationID: field.orgId,
        TotalMaterial: tempHarvestOps[i].quantity,
        TotalMaterialUnit: tempHarvestOps[i].unit,
        Variety: tempHarvestOps[i].name,
      };

      const varietyObj = {
        area: field.acres,
        cellsize: '0.0001',
        date: formatDate(tempHarvestOps[i].endDate),
        fileType: 'Harvest',
        isIrrigated: false,
        isOrganic: false,
        operationCost: 0,
        operationID: '',
        price: tempHarvestOps[i].price,
        productName: tempHarvestOps[i].cropName,
        quantity: tempHarvestOps[i].quantity,
        source: 'USERENTERED',
        rate: tempHarvestOps[i].rate,
        total:
          (tempHarvestOps[i].quantity / field.acres) * tempHarvestOps[i].price,
        unit: tempHarvestOps[i].unit,
        variety: tempHarvestOps[i].name,
        zone: boundary,
      };
      // console.log(varietyObj.date)

      varieties.push(varietyObj);
      zones.push(boundary);

      const obj = {
        date: formatDate(tempHarvestOps[i].endDate),
        hidden: false,
        name: tempHarvestOps[i].cropName,
        operationID: '',
        unit: tempHarvestOps[i].yieldUnit,
        varieties,
        zones,
      };
      formatHarForManual.push(obj);
    }

    // Format Application Ops
    for (var i = 0; i < tempapplicationOps.length; i++) {
      for (
        let j = 0;
        j < tempapplicationOps[i].applicationComponents.length;
        j++
      ) {
        const appComp = tempapplicationOps[i].applicationComponents[j];
        const tankMix = [];
        const zones = [];
        // console.log(appComp)

        const boundary = JSON.parse(field.boundary);
        boundary.properties = {
          AppliedRate: appComp.rate,
          CALCACRES: field.acres,
          Product: appComp.name,
          SECTIONID: 1,
          Time: formatDate(appComp.endDate),
          Unit: appComp.averageMaterialResultUnit,
        };
        boundary.quantity = appComp.rate;

        const tankMixObj = {
          costPerAcre: appComp.price * appComp.averageMaterialResult,
          inputUnit: con.unitConversion(appComp.unit, true),
          isCarrier: false,
          percent: 1,
          price: appComp.price,
          productName: appComp.name,
          quantity: appComp.rate,
          quantityPerAcre: appComp.rate,
          rate: appComp.rate,
          total: appComp.price * appComp.averageMaterialResult,
          unit: con.unitConversion(appComp.unit, true),
        };

        tankMix.push(tankMixObj);
        zones.push(boundary);
        let date = formatDate(tempapplicationOps[i].endDate);
        const array = date.split('/');
        date = `${array[2]}-${array[1]}-${array[0]}`;

        const obj = {
          area: field.acres,
          cellsize: '0.0001',
          date,
          hidden: false,
          isTankMix: false,
          price: tempapplicationOps[i].price,
          productName: '',
          rate: tempapplicationOps[i].rate,
          unit: con.unitConversion(tempapplicationOps[i].unit, true),
          tankMix,
          zones,
        };
        formatAppForManual.push(obj);
      }
    }

    // Format Generic Ops
    for (var i = 0; i < tempGenericOps.length; i++) {
      const zones = [];
      const fileType = tempGenericOps[i].aux1 === 'Cost'
        ? 'OTHER COSTS'
        : tempGenericOps[i].aux1 === 'Revenue'
          ? 'OTHER REVENUES'
          : 'FIELD PASS';

      const boundary = JSON.parse(field.boundary);
      boundary.properties = {
        Area: field.acres,
        AreaUnit: 'ac',
        Aux1: tempGenericOps[i].aux1,
        Aux2: tempGenericOps[i].name,
        Aux3: 1,
        FieldID: field.id,
        Name: tempGenericOps[i].name,
        OrganizationID: field.orgId,
        Rate: 1,
        RateUnit:
          tempGenericOps[i].aux1 === 'Field Pass' ? 'unit1ac-1' : 'ac1ac-1',
        Total: field.acres * 1,
        TotalUnit: 'unit',
        CropSeason: tempGenericOps[i].cropSeason,
      };

      const zonesObj = {
        area: field.acres,
        fileType: 'Generic',
        name: '',
        percentage: 1,
        price: tempGenericOps[i].price,
        rate: 1,
        total: tempGenericOps[i].price * field.acres,
        zone: boundary,
        CropSeason: tempGenericOps[i].cropSeason,
      };
      zones.push(zonesObj);
      const opDate = tempGenericOps[i].date.split('/');
      // set to next year
      const year = Number(opDate[2]) + 1;
      const formatted = `${appliedTemplateYear}-${opDate[0]}-${opDate[1]}`;

      const obj = {
        area: field.acres,
        date: formatted,
        fileType,
        isDeleted: false,
        logId: 0,
        operationID: '',
        orgId: field.orgId,
        price: tempGenericOps[i].price,
        productName: tempGenericOps[i].name,
        total: tempGenericOps[i].price * field.acres,
        year: appliedTemplateYear,
        unit: tempGenericOps[i].aux1 === 'Field Pass' ? 'unit' : 'acre',
        zones,
      };
      formatGenericForManual.push(obj);
    }

    // Format Tillage Ops for manual entry
    for (var i = 0; i < tempTillageOps.length; i++) {
      const zones = [];
      const boundary = JSON.parse(field.boundary);
      boundary.properties = {
        Area: field.acres,
        AreaUnit: 'ac',
        Depth: tempTillageOps[i].targetAverageDepth,
        FieldID: field.id,
        OrganizationID: field.orgId,
        CropSeason: tempTillageOps[i].cropSeason,
      };

      const zonesObj = {
        area: field.acres,
        fileType: 'Tillage',
        name: '',
        price: tempTillageOps[i].price,
        CropSeason: tempTillageOps[i].cropSeason,
        zone: boundary,
      };
      zones.push(zonesObj);
      let date = formatDate(tempTillageOps[i].endDate);
      const array = date.split('/');
      date = `${array[2]}-${array[1]}-${array[0]}`;

      const obj = {
        area: field.acres,
        date,
        fileType: 'Tillage',
        isDeleted: false,
        logId: 0,
        operationID: '',
        orgId: field.orgId,
        price: tempTillageOps[i].price,
        productName: tempTillageOps[i].productName,
        targetAverageDepth: tempTillageOps[i].targetAverageDepth,
        targetAverageDepthUnit: tempTillageOps[i].targetAverageDepthUnit,
        year: selectedYears[0],
        zones,
      };
      formatTillageForManual.push(obj);
    }

    setTemplateMode(false);

    let cornSeeding = null;
    const requests = [];
    // Loop through formatted ops through manual api
    for (var i = 0; i < formatSeedForManualAPI.length; i++) {
      if (formatSeedForManualAPI[i].name.toUpperCase() === 'CORN') {
        cornSeeding = cornSeeding === null ? i : cornSeeding;
      }
      requests.push(
        createManualOperation(
          'Seeding',
          formatSeedForManualAPI[i],
          formatSeedForManualAPI[i].zones,
          true,
          true,
        ),
      );
      // formatSeedForManualAPI[i].operationID = id
      // hold for quarter second
      await new Promise((r) => setTimeout(r, 200));
      setLayersProgress(100 / (count - counter));
      counter++;
    }

    for (var i = 0; i < formatHarForManual.length; i++) {
      requests.push(
        createManualOperation(
          'Harvest',
          formatHarForManual[i],
          formatHarForManual[i].zones,
        ),
      );
      await new Promise((r) => setTimeout(r, 200));
      setLayersProgress(100 / (count - counter));
      counter++;
    }

    // COMMENTED OUT FOR NOW, YIELD MODEL DOES NOT SEEM TO BE WORKING

    // setCurrentLoadingLayer("Yield Forecasted")
    // setLoadNextTitle(true)
    // //check if field in US, if it is and has corn seeding, forecast a yield
    // console.log(field.state, field.county)
    // if(field.state !== "" && field.county !== ""){
    //   if(cornSeeding !== null){
    //     //get the seeding from seedings
    //     //first get correct seeding from applied template
    //     console.log(cornSeeding)
    //     let templateSeeding = formatSeedForManualAPI[cornSeeding]
    //     let appDate = templateSeeding.date.split("/")
    //     let d = appDate[appDate.length - 1]
    //     await templateYield(d, templateSeeding)
    //   }
    //   else{
    //     //make yield request without seeding op
    //     let templateSeeding = formatSeedForManualAPI[0]
    //     let appDate = templateSeeding.date.split("/")
    //     let d = appDate[appDate.length - 1]
    //     await templateYield(d)
    //   }
    // }
    // setLoadNextTitle(false)

    setInterval(() => {
      setLoadNextTitle((prev) => !prev);
    }, 2000);

    for (var i = 0; i < formatAppForManual.length; i++) {
      requests.push(
        createManualOperation(
          'Application',
          formatAppForManual[i],
          formatAppForManual[i].zones,
        ),
      );
      await new Promise((r) => setTimeout(r, 200));
      setLayersProgress(100 / (count - counter));
      counter++;
    }

    const fieldPasses = formatGenericForManual.filter(
      (x) => x.fileType === 'FIELD PASS',
    );
    for (var i = 0; i < fieldPasses.length; i++) {
      console.log('creating field pass', fieldPasses[i]);
      requests.push(
        createManualOperation(
          'Field Pass',
          fieldPasses[i],
          fieldPasses[i].zones,
        ),
      );
      await new Promise((r) => setTimeout(r, 200));
      setLayersProgress(100 / (count - counter));
      counter++;
    }

    const costs = formatGenericForManual.filter(
      (x) => x.fileType === 'OTHER COSTS',
    );
    for (var i = 0; i < costs.length; i++) {
      requests.push(createManualOperation('Cost', costs[i], costs[i].zones));
      await new Promise((r) => setTimeout(r, 200));
      setLayersProgress(100 / (count - counter));
      counter++;
    }

    const revenues = formatGenericForManual.filter(
      (x) => x.fileType === 'OTHER REVENUES',
    );
    for (var i = 0; i < revenues.length; i++) {
      requests.push(
        createManualOperation('Revenue', revenues[i], revenues[i].zones),
      );
      await new Promise((r) => setTimeout(r, 200));
      setLayersProgress(100 / (count - counter));
      counter++;
    }

    for (var i = 0; i < formatTillageForManual.length; i++) {
      requests.push(
        createManualOperation(
          'Tillage',
          formatTillageForManual[i],
          formatTillageForManual[i].zones,
        ),
      );
      await new Promise((r) => setTimeout(r, 200));
      setLayersProgress(100 / (count - counter));
      counter++;
    }

    await Promise.allSettled(requests);
    setLoaded([]);

    clearInterval();
    setGeneratingPrompt(false);
    setLoadNextTitle(false);
    setProgressModal(false);
    setTemplateMode(false);
    setOpen(false);
    setTemplatesLoading(false);
    setCurrentLoadingLayer('');
    setSelectedYears([appliedTemplateYear]);
    setCurrentTemplate(undefined);
    await new Promise((r) => setTimeout(r, 200));
    getData(field.orgId, field.id, +appliedTemplateYear, [
      +appliedTemplateYear,
    ]);
  };

  const handleFieldSelectShow = () => {
    setHideFieldSelection((prev) => !prev);
  };

  const handleOperationSelectShow = () => {
    setHideOperationSelection((prev) => !prev);
  };

  const handleHideMapSelection = () => {
    setHideMapSelection((prev) => !prev);
  };

  const handleLogin = () => window.location.assign(Endpoints.BASEURL + Endpoints.SIGNIN);

  const handleSignUp = () => window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUP);

  const updateIntegrationModalState = (open) => {
    if (!open && integrationStatusOpen) {
      // refresh the page when the modal is closed
      setIntegrationStatusOpen(false);
      const url = document.URL.split('?')[0];
      window.location.assign(url);
    }
  };

  const footer = () => (
    <Box
      p={1}
      display="flex"
      justifyContent="space-between"
      alignItems="center"
      style={{ height: '60px', width }}
    >
      <Box display="flex" fontSize={14} fontWeight={600}>
        {/* {width > 760 &&
          <Box>
            <img
              src={poweredBy}
              className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
              alt={"Powered By Ag-Analytics"}
            />
          </Box>
        } */}

        {!templateMode && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => handleBack()}
          >
            <ArrowBackIcon />
            {width > 570 ? ' Back' : ''}
          </Button>
        )}

        {!templateMode && width <= mobileBreakPoint && (
          <Box
            ml={1}
            display="flex"
            fontWeight={500}
            fontSize={width > 480 ? 14 : value == 1 ? 12 : 10}
            color={blackText}
          >
            {value === 2 && (
              <>
                <Box ml={1}>
                  <Typography
                    style={{
                      whiteSpace: 'nowrap',
                      color: darkGrey,
                      fontWeight: 500,
                      fontSize: width > 480 ? 14 : value == 1 ? 12 : 10,
                    }}
                  >
                    Total Revenue
                  </Typography>

                  <Box style={{ whiteSpace: 'nowrap' }}>
                    {!nans.includes(numFormat(totalRevenue))
                      ? dollarFormat(Math.abs(totalRevenue))
                      : '$0.00'}
                    {perAcre && <span style={{ fontSize: 10 }}>/Acre</span>}
                  </Box>
                </Box>

                <Box ml={1}>
                  <Typography
                    style={{
                      whiteSpace: 'nowrap',
                      color: darkGrey,
                      fontWeight: 500,
                      fontSize: width > 480 ? 14 : value == 1 ? 12 : 10,
                    }}
                  >
                    Total Expenses
                  </Typography>
                  <Box style={{ whiteSpace: 'nowrap' }}>
                    {!nans.includes(numFormat(totalCosts))
                      ? dollarFormat(Math.abs(totalCosts))
                      : '$0.00'}
                    {perAcre && <span style={{ fontSize: 10 }}>/Acre</span>}
                  </Box>
                </Box>
              </>
            )}
            <Box ml={1}>
              <Typography
                style={{
                  whiteSpace: 'nowrap',
                  color: darkGrey,
                  fontWeight: 500,
                  fontSize: width > 480 ? 14 : value == 1 ? 12 : 10,
                }}
              >
                Total Profit
              </Typography>
              <Box style={{ whiteSpace: 'nowrap' }}>
                {!nans.includes(numFormat(totalProfit))
                  ? dollarFormat(totalProfit)
                  : '$0.00'}
                {perAcre && <span style={{ fontSize: 10 }}>/Acre</span>}
              </Box>
            </Box>
          </Box>
        )}

        {((!templateMode && width > mobileBreakPoint)
          || (templateMode && width > 1320)) && (
          <>
            <Box mx={2} ml={2} fontSize={14} fontWeight={600}>
              <Box color={grey} fontSize={12}>
                ORGANIZATION
              </Box>
              {field.org}
            </Box>

            {value !== 3 && value !== 4 && (
              <>
                <Box mx={2}>
                  <Box color={grey} fontSize={12}>
                    FARM
                  </Box>
                  {field.farm}
                </Box>

                <Box mx={2}>
                  <Box color={grey} fontSize={12}>
                    FIELD
                  </Box>
                  {field.name}
                </Box>
              </>
            )}
          </>
        )}
      </Box>

      {value === 1 ? (
        footerForward()
      ) : value === 2 ? (
        <Box display="flex">
          {width > 760 &&
            <Box>
              <img
                src={poweredBy}
                className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
                alt={"Powered By Ag-Analytics"}
              />
            </Box>
          }
          <Button
            variant="outlined"
            color="primary"
            onClick={() => {setGeneratePdf(true);ReactGA.event({
              category: "Feature Usage",
              action: "PDF Download",
              label: "Open Download PDF"
            })}}
            disabled={mapLoading || !mapLoadedOnViewProfitLayers || !!errorMessage}
            style={{marginLeft: '10px', marginRight:'10px'}}
          >
            {width > 500 && 'Create '}
            {'Pdf'}
          </Button>
          {'  '}
          <Button
            variant="contained"
            color="primary"
            // style={{ height: "45px" }}
            onClick={() => handleContinue()}
            disableElevation
          >
            {templateMode || width <= 570 ? '' : 'Continue'}
            <ArrowForwardIcon />
          </Button>
        </Box>
      ) : (
        <Box display="flex">
          {width > 760 &&
            <Box>
              <img
                src={poweredBy}
                className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
                alt={"Powered By Ag-Analytics"}
              />
            </Box>
          }
          <Button
            variant="outlined"
            color="primary"
            onClick={() => window.print()}
          >
            {'Print'}
            {width > 500 && ' This View'}
          </Button>
          {/* {"  "}
          {value == 3 && <Button
            variant="contained"
            color="primary"
            // style={{ height: "45px" }}
            onClick={() => handleContinue()}
            disableElevation
          >
            {templateMode || width <= 570 ? "" : "Continue"}
            <ArrowForwardIcon />
          </Button> } */}
        </Box>
      )}
    </Box>
  );

  const handleBack = () => {
    if (value == 2) {
      // go from reports to setup
      setValue(1);
    } else if (value == 3) {
      // go from farm reports to view profitlayers
      setValue(2);
    } else if (value == 4) {
      // go from market reports to farm reports
      setValue(3);
    } else {
      // on set up page, go back through operations unless on seeding
      if (opTabValue === 0) {
        setValue(0);
      } else {
        // back to previous op
        setOpTabValue(opTabValue - 1);
      }
    }
  };

  const progressModalDiv = () => (
    <Dialog aria-labelledby="customized-dialog-title" open={progressModal}>
      <DialogTitle id="customized-dialog-title">
        Generating Layers....
      </DialogTitle>
      <DialogContent dividers>
        <Typography gutterBottom>
          All of the operation layers in the template will be created and added
          to your operation for the selected year. You can edit and remove the
          layers in the next screen.
        </Typography>
        <Typography gutterBottom>
          Your operations are being created. Please don't leave this screen
        </Typography>
        <Box>
          <Grow in={loadNextTitle}>
            <Typography>Generating Operation Layers ...</Typography>
          </Grow>
        </Box>
      </DialogContent>
    </Dialog>
  );

  /**
   * Opens the modal for uploading (or checking the status of) a precision ag file.
   */
  const uploadPrecisionFile = () => {
    setUploadFile(true);
  };

  const footerForward = () => (
    <Box mx={1} display="flex" justifyContent="flex-end" alignItems="center">
      {width > 760 &&
        <Box>
          <img
            src={poweredBy}
            className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
            alt={"Powered By Ag-Analytics"}
          />
        </Box>
      }
      {!templateMode && (
        <Box mx={1} color={green} fontWeight={600}>
          <Button
            color="primary"
            onClick={(e) => {
              setActionsPopoverAnchor(e.currentTarget);
              setActionsPopoverOpen(!actionsPopoverOpen);
            }}
          >
            Actions
            <ExpandLessIcon />
            <Popover
              id="data-sync-popover"
              open={actionsPopoverOpen}
              anchorEl={actionsPopoverAnchor}
              onClose={() => {
                setActionsPopoverAnchor(null);
                setActionsPopoverOpen(false);
              }}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <Box p={2} fontWeight={500}>
                <Box onClick={() => {resetData(); ReactGA.event({category:"Feature Usage", action:"Reset Operations"})}}>
                  <Box className={classes.edit} fontSize={18} color="#cc0000">
                    Reset Operations
                  </Box>
                  <Box ml={1} fontSize={14} color={grey}>
                    This will reset any changes you made to the field since the
                    last data import.
                  </Box>
                </Box>

                <Divider />

                <Box onClick={() => updatePrices()}>
                  <Box mt={1} className={classes.edit} fontSize={18}>
                    Update Operation Prices
                  </Box>
                  <Box ml={1} fontSize={14} color={grey}>
                    Refresh your operations with prices from the Price Coverter
                    tool.
                  </Box>
                </Box>

                <Divider />

                <Box onClick={() => goToPriceTool()}>
                  <Box mt={1} className={classes.edit} fontSize={18}>
                    Open Price Converter
                  </Box>
                  <Box ml={1} fontSize={14} color={grey}>
                    Use the Ag-Analytics Price Tool to update crop, seed and
                    applications costs across all the fields.
                  </Box>
                </Box>

                <Divider />

                <Box onClick={() => handleTemplatesAction()}>
                  <Box mt={1} className={classes.edit} fontSize={18}>
                    Generate from Crop Template
                  </Box>
                  <Box ml={1} fontSize={14} color={grey}>
                    Generate operations from a pre-defined, customizable crop
                    template.
                  </Box>
                </Box>

                <Divider />

                <Box onClick={() => setUploadFile(true)}>
                  <Box mt={1} className={classes.edit} fontSize={18}>
                    Upload or Check Status of Precision Ag Data
                  </Box>
                  <Box ml={1} fontSize={14} color={grey}>
                    This will allow you to upload and check the status of any
                    precision data files that you have as a zip file.
                  </Box>
                </Box>
              </Box>
            </Popover>
          </Button>
        </Box>
      )}

      {templateMode && (
        <Box display="flex" justifyContent="center">
          <Button
            variant="outlined"
            color="primary"
            style={{ margin: '0 8px', whiteSpace: 'nowrap' }}
            onClick={() => handleSaveCropTemplate()}
            disableElevation
          >
            {width > 1024
              ? 'Save Crop Template'
              : width > 511
                ? 'Save Template'
                : 'Save'}
          </Button>

          <Button
            variant="outlined"
            color="primary"
            style={{ margin: '0 8px', whiteSpace: 'nowrap' }}
            onClick={() => handleExitBtn()}
            disableElevation
          >
            {width > 1024 ? 'Exit Template Mode' : 'Exit'}
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ margin: '0 8px', whiteSpace: 'nowrap' }}
            onClick={() => handleGenProfitLayerTemplateHelper()}
            disableElevation
          >
            {width > 1024
              ? 'Generate Operations from Crop Template'
              : 'Generate Operations'}
          </Button>
        </Box>
      )}

      <Button
        variant="contained"
        color="primary"
        // style={{ height: "45px" }}
        onClick={() => handleContinue()}
        disableElevation
      >
        {templateMode || width <= 570 ? '' : 'Continue'}
        <ArrowForwardIcon />
      </Button>

      {openConfirm && templateMode && confirmationPopup()}
      {openConfirmLayers && templateMode && confirmGenerateLayers()}
      <Template
        open={open}
        setOpen={setOpen}
        setCurrentTemplate={setCurrentTemplate}
        setAllTemplates={setAllTemplates}
      />
    </Box>
  );

  const reintegrate = () => {
    const buttonHandler = (e) => {
      setReintegrateNecessary(false);

      // Handle click on both button boundary and inner span text content
      if (
        e.target.name === 'Accept'
        || e.target.textContent === 'Reintegrate Now'
      ) {
        window.location.assign(`${Endpoints.BASEURL}/Deere/DeereV2`);
      }
    };

    return (
      <Modal
        open={reintegrateNecessary}
        style={{
          alignItems: 'center',
          justifyContent: 'center',
          textAlign: 'center',
        }}
      >
        <Box
          style={{
            margin: 'auto',
            width: '400px',
            padding: '15px',
            backgroundColor: '#ddd',
          }}
        >
          <Typography variant="h3">
            You are using an old version of the Deere Integration
          </Typography>
          <Typography variant="subtitle1">
            Please reintegrate in order to use updated Deere functionality.
          </Typography>
          <Box style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button
              variant="contained"
              color="secondary"
              name="Deny"
              style={{ height: '45px' }}
              onClick={(e) => buttonHandler(e)}
            >
              No, Thanks
            </Button>
            <Button
              variant="contained"
              color="primary"
              name="Accept"
              style={{ height: '45px' }}
              onClick={(e) => buttonHandler(e)}
            >
              Reintegrate Now
            </Button>
          </Box>
        </Box>
      </Modal>
    );
  };

  const yearSelection = () => (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box fontWeight={500} py={1}>
          <Box className={classes.label}>
            {'Selected Year'}
            {selectedYears.length > 1 && 's'}
          </Box>

          <YearSelection
            open={selectYearsOpen}
            setOpen={setSelectYearsOpen}
            years={years}
            selectedYears={selectedYears}
            selectMultipleYears={selectMultipleYears}
            handleYearSelect={handleYearSelect}
            handleMultiYearSelect={handleMultiYearSelect}
          />

          <Box display="flex" flexWrap="wrap" onClick={openYearSelection}>
            {selectedYears.map((year, i) => (
              <Box
                key={i}
                px={1}
                fontSize={16}
                borderRight={selectedYears.length > 1 ? 1 : 0}
              >
                {year}
              </Box>
            ))}
            <CustomToolTip
              title="Select a single year by clicking on the year, or multiple years by clicking the circle to left of the year."
              placement="right-start"
            >
              <ArrowDropDownCircleIcon
                className={classes.icon}
                fontSize="small"
              />
            </CustomToolTip>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

  const operations = () => (
    <Box display="flex" style={{ height: height - 195, overflowX: 'auto' }}>
      <Slide
        direction="right"
        in={width >= mobileBreakPoint}
        timeout={200}
        mountOnEnter
        unmountOnExit
      >
        <Box
          className={classes.operations}
          style={{ width: width > 1024 ? '400px' : '260px' }}
        >
          <Box>
            <Box
              display="flex"
              justifyContent="space-between"
              style={{ width: '100%' }}
            >
              <Box fontWeight={500}>
                <Box className={classes.label}>
                  Net Seeded Area
                  <CustomToolTip
                    title="Acreage is the area encompassed by the operation and recalculates area to correct for overlap. This may differ from other precision ag systems that do not remove overlap or account for the width of equipment. This will not affect the accuracy of your ProfitLayers themselves, only the calculation of certain summaries. To manually override your Net Seeded Acreage, open the report settings by selecting the Gear Icon to the right, then enter your desired value and Save to exit."
                    placement="bottom-end"
                  >
                    <InfoOutlinedIcon className={classes.infoToolTip} />
                  </CustomToolTip>
                </Box>
                <Box ml={1} fontSize={16}>
                  {numFormat(netSeededAcres)}
                  {' Acres'}
                </Box>
              </Box>
              <Box display="flex" alignItems="center">
                <CustomToolTip placement="right" title="Refresh Operations">
                  <SyncIcon
                    className={classes.settingsIcon}
                    onClick={() => {resetData(true); ReactGA.event({category:"Feature Usage", action: "Refresh Operations"})}}
                  />
                </CustomToolTip>

                <SettingsIcon
                  className={classes.settingsIcon}
                  onClick={() => setSettingsOpen(true)}
                />
              </Box>
            </Box>

            {!templateMode && yearSelection()}

            {templateMode && (
              <Box style={{ marginTop: '10px' }}>
                <Box className={classes.label}>
                  {'Selected Year'}
                  {selectedYears.length > 1 && 's'}
                </Box>
                <Box
                  key={selectedYears[0]}
                  px={1}
                  fontSize={16}
                  borderRight={selectedYears.length > 1 ? 1 : 0}
                  fontWeight={500}
                >
                  {selectedYears[0]}
                </Box>
              </Box>
            )}

            <Box
              p={1}
              display="flex"
              justifyContent="space-between"
              alignItems="center"
            >
              {!templateMode && (
                <Box>
                  <Box display="flex" alignItems="center">
                    <SyncAltIcon />
                    <Box mx={1} fontWeight={500}>
                      Ag Data Sync
                    </Box>
                    <CustomToolTip
                      title="Sync data for your organizations, operations, and boundaries"
                      placement="right"
                    >
                      <InfoOutlinedIcon className={classes.infoToolTip} />
                    </CustomToolTip>
                  </Box>
                  {width > 1024 && (
                    <>
                      <Box color={grey} fontWeight={500}>
                        Last synced:
                        {' '}
                        {orgSyncDate}
                        {' '}
                        -
                        {' '}
                        {orgSyncTime}
                      </Box>
                      <Box
                        className={classes.greenClick}
                        onClick={(e) => {
                          setDataSyncPopoverAnchor(e.currentTarget);
                          setDataSyncPopoverOpen(!dataSyncPopoverOpen);
                        }}
                      >
                        Sync data now
                      </Box>
                    </>
                  )}

                  {/** Use a progress bar to indicate that the sync request was sent
                 we can use a toast notification to display the result of the sync process * */}
                  {syncStatus === 'sent' && (
                    <Box>
                      Sending Sync Request...
                      <LinearProgress style={{ marginTop: '20px' }} />
                    </Box>
                  )}
                </Box>
              )}

              {!templateMode && (
                <Box>
                  <IconButton
                    color="primary"
                    onClick={(e) => {
                      setDataSyncPopoverAnchor(e.currentTarget);
                      setDataSyncPopoverOpen(!dataSyncPopoverOpen);
                    }}
                  >
                    <MoreVertIcon />
                  </IconButton>
                  <Popover
                    id="data-sync-popover"
                    open={dataSyncPopoverOpen}
                    anchorEl={dataSyncPopoverAnchor}
                    onClose={() => {
                      setDataSyncPopoverAnchor(null);
                      setDataSyncPopoverOpen(false);
                    }}
                    anchorOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                    transformOrigin={{
                      vertical: 'bottom',
                      horizontal: 'center',
                    }}
                  >
                    <Box p={2} fontWeight={500}>
                      <Box
                        className={classes.sync}
                        onClick={() => syncData('organizations')}
                      >
                        Sync Organization Fields
                      </Box>
                      <Box ml={1} fontSize={14} color={grey}>
                        Last Synced:
                        {' '}
                        {orgSyncDate}
                        {' '}
                        -
                        {' '}
                        {orgSyncTime}
                      </Box>

                      <Divider />

                      <Box
                        mt={1}
                        className={classes.sync}
                        onClick={() => syncData('operations')}
                      >
                        Sync Field Operations
                      </Box>
                      <Box ml={1} fontSize={14} color={grey}>
                        Last Synced:
                        {' '}
                        {lastSyncDate}
                        {' '}
                        -
                        {' '}
                        {lastSyncTime}
                      </Box>

                      <Divider />

                      <Box
                        mt={1}
                        className={classes.sync}
                        onClick={() => syncData('boundaries')}
                      >
                        Sync This Boundary
                      </Box>
                      <Box ml={1} fontSize={14} color={grey}>
                        Last Synced:
                        {' '}
                        {boundarySyncDate}
                        {' '}
                        -
                        {' '}
                        {boundarySyncTime}
                      </Box>

                      <Divider />
                      {/* {
                      source.toUpperCase() === 'DEERE' && <Box>
                        <Box
                          mt={1}
                          className={classes.sync}
                          onClick={() => deereEtlByField()}
                        >
                          Sync This Field
                        </Box>
                        <Box ml={1} fontSize={14} color={grey}>
                          Last Synced: {lastSyncDate} - {lastSyncTime}
                        </Box>

                        <Divider />
                      </Box>
                    } */}

                      <Box m={1} fontSize={12} color={grey}>
                        These actions can take up to 15 minutes
                      </Box>
                    </Box>
                  </Popover>
                </Box>
              )}
            </Box>

            <Divider />

            <Box my={2}>
              {operationTypes.map((op, i) => (
                <Box
                  key={i}
                  className={classes.select}
                  style={{
                    backgroundColor: opTabValue === i ? 'white' : lightGrey,
                  }}
                  onClick={() => {setOpTabValue(i); ReactGA.event({category:"Navigation", action:"Change Setup Section", label:"Change section from side menu"})}}
                >
                  <Box display="flex" alignItems="center">
                    <Typography
                      style={{
                        color: opTabValue === i ? green : blackText,
                        fontWeight: 600,
                      }}
                    >
                      {op}
                    </Typography>

                    {/* Show tooltip for harvest, seeding, chemical */}
                    {(op === 'Harvest Revenues'
                      || op === 'Seed Costs'
                      || op === 'Chemical and Fertilizer Costs') && (
                      <CustomToolTip
                        title="Your ProfitLayers Average Profit in the Operation Summaries may differ slightly from the Average Profit in the individual Operation Tables if the operations have different acreages. This does not affect the accuracy of your $/Acre ProfitLayers maps."
                        placement="right"
                      >
                        <InfoOutlinedIcon className={classes.infoToolTip} />
                      </CustomToolTip>
                    )}
                  </Box>

                  <Typography
                    style={{ color: blackText, fontWeight: 600, fontSize: 14 }}
                  >
                    {showOpTotal(i)}
                    {perAcre && '/Acre'}
                  </Typography>
                </Box>
              ))}

              <Box display="flex" flexWrap="wrap">
                {!templateMode && (
                  <Box p={1}>
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ backgroundColor: '#ffffff' }}
                      onClick={() => handlePriceToolOpen()}
                    >
                      Price Converter
                    </Button>
                  </Box>
                )}
                <Box p={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    style={{
                      backgroundColor: '#ffffff',
                      textDecoration: 'none',
                      color: green,
                    }}
                    href="/app/FieldManager"
                    target="_blank"
                  >
                    FieldManager
                  </Button>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box>
            <Divider />
            <Box display="flex" flexWrap="wrap">
              <Box mt={1} px={1}>
                <Box fontSize={16} fontWeight={600} color={darkGrey}>
                  Total Profit
                  <CustomToolTip
                    title="Your ProfitLayers Average Profit in the Operation Summaries may differ slightly from the Average Profit in the individual Operation Tables if the operations have different acreages. This does not affect the accuracy of your $/Acre ProfitLayers maps."
                    placement="right"
                  >
                    <InfoOutlinedIcon className={classes.infoToolTip} />
                  </CustomToolTip>
                </Box>
                <Box px={1} fontWeight={600} fontSize={18} color={blackText}>
                  {numFormat(totalProfit) !== 'NaN'
                    ? dollarFormat(totalProfit)
                    : '$0.00'}
                  {perAcre && '/Acre'}
                </Box>
              </Box>

              {showSubscribe && (
                <Box p={1}>
                  <Button
                    variant="outlined"
                    color="primary"
                    // target="_blank"
                    // href="/app/purchase"
                    onClick={() => goToPurchase()}
                    style={{
                      backgroundColor: '#ffffff',
                      textDecoration: 'none',
                      color: green,
                      width: 160,
                    }}
                  >
                    <span>
                      Subscribe to PROFIT LAYERS
                      <sup>&reg;</sup>
                    </span>
                  </Button>
                </Box>
              )}
            </Box>
          </Box>
        </Box>
      </Slide>

      <Box
        display="flex"
        style={{
          height: '100%',

          width:
            width < mobileBreakPoint
              ? width
              : width > 1024
                ? width - 400
                : width - 240,
          overflow: 'auto',
        }}
      >
        <Box>
          {opTabValue === 0 ? (
            <Plantings
              appliedTemplateYear={appliedTemplateYear}
              setAppliedTemplateYear={setAppliedTemplateYear}
              appliedTemplateYears={appliedTemplateYears}
              fieldAcres={field.acres}
              setCustomTemplateName={setCustomTemplateName}
              setAllTemplates={setAllTemplates}
              setCurrentTemplate={setCurrentTemplate}
              allTemplates={allTemplates}
              currentTemplate={currentTemplate}
              templateMode={templateMode}
              operations={harvests}
              setOperations={setHarvests}
              displayed={displayedHarvests}
              hidden={hiddenHarvests}
              seedings={displayedSeedings}
              save={createManualOperation}
              saveOperation={handleIndividualOperation}
              year={selectedYears[0]}
              years={selectedYears}
              perAcre={perAcre}
              seededAcres={netSeededAcres}
              field={field}
              type="Harvest"
              convertPlantings={convertPlantings}
              combineOps={combineOps}
              saving={saving}
              getProfitMap={getProfitMap}
              openPriceTool={openPriceTool}
              loaded={loaded}
              refreshOperations={getHarvestOperations}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              openTemplateMode={handleTemplatesAction}
              integrated={integrated}
              templatesLoading={templatesLoading}
              setTemplatesLoading={setTemplatesLoading}
              hasOperations={hasOperations}
              yearSelection={yearSelection}
              mobileBreakPoint={mobileBreakPoint}
              openSettings={setSettingsOpen}
              resetData={resetData}
              deereEtlByField={deereEtlByField}
              source={source}
              uploadPrecisionFile={uploadPrecisionFile}
              clipByBoundary={clipByBoundary}
              syncOperation={syncOperation}
            />
          ) : opTabValue === 1 ? (
            <Plantings
              appliedTemplateYear={appliedTemplateYear}
              cleanTemplateYear={cleanTemplateYear}
              setAppliedTemplateYear={setAppliedTemplateYear}
              appliedTemplateYears={appliedTemplateYears}
              fieldAcres={field.acres}
              setCustomTemplateName={setCustomTemplateName}
              setAllTemplates={setAllTemplates}
              setCurrentTemplate={setCurrentTemplate}
              allTemplates={allTemplates}
              currentTemplate={currentTemplate}
              templateMode={templateMode}
              operations={seedings}
              setOperations={setSeedings}
              displayed={displayedSeedings}
              hidden={hiddenSeedings}
              save={createManualOperation}
              saveOperation={handleIndividualOperation}
              year={selectedYears[0]}
              years={selectedYears}
              perAcre={perAcre}
              seededAcres={netSeededAcres}
              field={field}
              type="Seeding"
              convertPlantings={convertPlantings}
              combineOps={combineOps}
              setSaving={setSaving}
              saving={saving}
              openPriceTool={openPriceTool}
              loaded={loaded}
              refreshOperations={handleSeedingOperations}
              selectedTemplate={selectedTemplate}
              setSelectedTemplate={setSelectedTemplate}
              openTemplateMode={handleTemplatesAction}
              integrated={integrated}
              templatesLoading={templatesLoading}
              setTemplatesLoading={setTemplatesLoading}
              hasOperations={hasOperations}
              yearSelection={yearSelection}
              mobileBreakPoint={mobileBreakPoint}
              openSettings={setSettingsOpen}
              resetData={resetData}
              uploadPrecisionFile={uploadPrecisionFile}
              clipByBoundary={clipByBoundary}
              syncOperation={syncOperation}
            />
          ) : opTabValue === 2 ? (
            <Applications
              fieldAcres={field.acres}
              templateMode={templateMode}
              operations={applicationOperations}
              setOperations={setApplicationOperations}
              displayed={displayApplicationOps}
              hidden={hiddenApplicationOps}
              netSeededAcres={netSeededAcres}
              total={applicationCost}
              perAcre={perAcre}
              manualEntry={createManualOperation}
              source={source}
              field={field}
              handleIndividualOperation={handleIndividualOperation}
              refreshOperations={getApplicationOperations}
              year={selectedYears[0]}
              years={selectedYears}
              saving={saving}
              openPriceTool={openPriceTool}
              loaded={loaded}
              openTemplateMode={handleTemplatesAction}
              integrated={integrated}
              templatesLoading={templatesLoading}
              yearSelection={yearSelection}
              mobileBreakPoint={mobileBreakPoint}
              openSettings={setSettingsOpen}
              resetData={resetData}
              uploadPrecisionFile={uploadPrecisionFile}
              clipByBoundary={clipByBoundary}
              syncOperation={syncOperation}
            />
          ) : opTabValue === 3 ? (
            <Others
              fieldAcres={field.acres}
              operations={fieldPasses}
              setOperations={setFieldPasses}
              displayed={displayFieldPasses}
              hidden={hiddenFieldPasses}
              perAcre={perAcre}
              seededAcres={netSeededAcres}
              year={selectedYears[0]}
              save={createManualOperation}
              field={field}
              type="Field Pass"
              handleIndividualOperation={handleIndividualOperation}
              saving={saving}
              openPriceTool={openPriceTool}
              combineGenericOperations={combineGenericOperations}
              loaded={loaded}
              templateMode={templateMode}
              openTemplateMode={handleTemplatesAction}
              integrated={integrated}
              refreshGenericOperations={getGenericOperations}
              templatesLoading={templatesLoading}
              yearSelection={yearSelection}
              mobileBreakPoint={mobileBreakPoint}
              openSettings={setSettingsOpen}
              resetData={resetData}
              uploadPrecisionFile={uploadPrecisionFile}
              getTillageOperations={getTillageOperations}
            />
          ) : opTabValue === 4 ? (
            <Others
              fieldAcres={field.acres}
              templateMode={templateMode}
              operations={otherCosts}
              setOperations={setOtherCosts}
              displayed={displayOtherCosts}
              hidden={hiddenOtherCosts}
              seededAcres={netSeededAcres}
              save={createManualOperation}
              year={selectedYears[0]}
              field={field}
              type="Cost"
              handleIndividualOperation={handleIndividualOperation}
              saving={saving}
              openPriceTool={openPriceTool}
              combineGenericOperations={combineGenericOperations}
              openTemplateMode={handleTemplatesAction}
              integrated={integrated}
              refreshGenericOperations={getGenericOperations}
              templatesLoading={templatesLoading}
              yearSelection={yearSelection}
              mobileBreakPoint={mobileBreakPoint}
              openSettings={setSettingsOpen}
              resetData={resetData}
              uploadPrecisionFile={uploadPrecisionFile}
            />
          ) : opTabValue === 5 ? (
            <Others
              fieldAcres={field.acres}
              templateMode={templateMode}
              operations={otherRevenue}
              setOperations={setOtherRevenue}
              displayed={displayOtherRevenue}
              hidden={hiddenOtherRevenue}
              seededAcres={netSeededAcres}
              save={createManualOperation}
              year={selectedYears[0]}
              field={field}
              type="Revenue"
              handleIndividualOperation={handleIndividualOperation}
              saving={saving}
              openPriceTool={openPriceTool}
              combineGenericOperations={combineGenericOperations}
              openTemplateMode={handleTemplatesAction}
              integrated={integrated}
              refreshGenericOperations={getGenericOperations}
              templatesLoading={templatesLoading}
              yearSelection={yearSelection}
              mobileBreakPoint={mobileBreakPoint}
              openSettings={setSettingsOpen}
              resetData={resetData}
              uploadPrecisionFile={uploadPrecisionFile}
            />
          ) : (
            <Box />
          )}
        </Box>

        {priceToolOpen && (
          <PriceTool
            setOpen={setPriceToolOpen}
            field={field}
            operations={{
              seedings,
              harvests,
              applicationOperations,
              other: [...otherCosts, ...otherRevenue],
            }}
            type={priceToolStartingOp}
            user={user}
            selectedYears={selectedYears}
            refreshGenericOperations={getGenericOperations}
            setSaving={setSaving}
            refreshHarvestOperations={getHarvestOperations}
            refreshApplicationOperations={getApplicationOperations}
            refreshSeedingOperations={handleSeedingOperations}
            isProfitLayers
          />
        )}
      </Box>
    </Box>
  );
  const handleChange = (value) => {
    // console.log("value", value);
    setYearDisplay(value);
  };

  const handlePriceToolOpen = () => {
    // for price tool embedded in ProfitLayers
    ReactGA.event({
      category: "Feature Usage",
      action: "Price Converter",
      label: "Accessed from button in setup profitlayers side menu"
    })
    if (opTabValue === 1) {
      setPriceToolOpen('Seeding');
    } else if (opTabValue === 2) {
      setPriceToolOpen('Applications');
    } else if (opTabValue === 0) {
      setPriceToolOpen('Harvest');
    } else {
      setPriceToolOpen('Other Expenses/Revenue');
    }
  };

  const handlePayment = async (customerData, subscriptionData) => {
    let customerInfo = '';
    try {
      fetch(Endpoints.BASEURL + Endpoints.API_CUSTOMER, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(customerData),
      })
        .then((response) => {
          if (response.ok) {
            const jsonresult = response.json();
            return jsonresult;
          }
          throw new Error(response);
        })
        .then((response) => {
          const customer = JSON.stringify(response.data);
          const dontgotosubscritionpage = sessionStorage.getItem(
            'dontGoToSubscriptionList',
          );

          // if (dontgotosubscritionpage === null || dontgotosubscritionpage === false) {

          sessionStorage.setItem('customerInfo', customer);

          // }
          // this.setState({ isEdit: false });
          // this.setState({
          //     savingCustomer: false
          // });
          customerInfo = customer;

          subscriptionData.ApiKey = customerInfo.apiKey;

          fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(subscriptionData),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error(response);
            })
            .then((response) => {
              // console.log("response", response)
              if (response.isSuccessful === true) {
                const subscription = response.data;
                // setIsSuccessful(response.isSuccessful)
                // setMessage(response.message)
                // this.setState({ isSuccessful: response.isSuccessful, displayMessage: response.displayMessage, message: response.message });
                sessionStorage.setItem(
                  'SubscriptionIdentifier',
                  subscription.subscriptionIdentifier,
                );
                setProfitMapAccess(true);
                setShowSubscribe(false);
                setProcessing(false);
              } else {
                // setIsSuccessful(response.isSuccessful)
                // setMessage(response.message)
                // setProfitMapAccess(true)
                // this.setState({ isSuccessful: response.isSuccessful, displayMessage: response.displayMessage, message: response.message });
              }
            })
            .catch((error) => {
              console.log(error);
            });
        })
        .catch((error) => {
          console.log('error', error);
          // this.setState({
          //     isSuccessful: false,
          //     displayMessage: true,
          //     message: error,
          // });
          // this.setState({
          //     savingCustomer: false
          // });
        });
    } catch (err) {
      console.log('handlePaymentMethod', err);
    }
  };

  const goToPurchase = () => {
    ReactGA.event({
      action: "Navigate",
      category: "Navigate to Pricing",
      label: "Subscribe from Setup"
    })
    let url = Endpoints.HOME = "/app/Purchase"
    window.open(url, '_blank').focus()
  }

  const mobileSteps = () => (value === 0 ? (
    <Box className={classes.mobileSteps}>
      <Box display="flex" fontWeight={500} fontSize={14} alignItems="center">
        <Switch
          color="primary"
          checked={hideFieldSelection}
          onChange={handleFieldSelectShow}
          inputProps={{ 'aria-label': 'field selection toggle' }}
        />
        <Box fontSize={12}>Map View</Box>
      </Box>

      <Box
        className={classes.appBarSelectionsMobile}
        color={green}
        style={{ width: '180px' }}
        onClick={() => scrollTo('select-field')}
      >
        <Box {...roundIcon} id="field-select">
          1
        </Box>
        Select Field
      </Box>

      <CustomToolTip title="Create ProfitLayer" placement="left">
        <ArrowForwardIosIcon
          className={classes.arrows}
          onClick={() => changeSection(1)}
        />
      </CustomToolTip>
    </Box>
  ) : value === 1 ? (
    <Box className={classes.mobileSteps}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ width: '100%' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: 8 }}
          color={green}
          fontWeight={500}
        >
          <Box {...mobileRoundIcon}>2</Box>
          <Box fontSize={20}>
            Setup ProfitLayers
            <sup style={{ fontSize: 12 }}>&reg;</sup>
          </Box>
        </Box>

        {mobileOperationSelection()}
      </Box>
    </Box>
  ) : (
    <Box className={classes.mobileSteps}>
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        style={{ width: '100%' }}
      >
        <Box
          display="flex"
          alignItems="center"
          justifyContent="center"
          style={{ marginTop: 8 }}
          color={green}
          fontWeight={500}
        >
          <Box {...mobileRoundIcon}>3</Box>
          <Box fontSize={20}>
            View ProfitLayers
            <sup style={{ fontSize: 12 }}>&reg;</sup>
          </Box>
        </Box>

        {mobileReportSelection()}
      </Box>
    </Box>
  ));

  const firstStepsBreakPoint = 1210;
  const secondStepsBreakPoint = 1050;
  const steps = () => (
    <Box display="flex" style={{ width }}>
      <Box
        id="select_field"
        className={classes.appBarSelections}
        color={green}
        onClick={() => setValue(0)}
        style={{
          width: width > secondStepsBreakPoint ? '180px' : '',
          margin: width > firstStepsBreakPoint ? '0 50px' :
            width > secondStepsBreakPoint ? '0 30px' : '0 10px',
        }}
      >
        {value > 0 ? (
          <CheckBoxRoundedIcon style={{ marginRight: '10px', fontSize: 27 }} />
        ) : (
          <Box {...roundIcon}>1</Box>
        )}
        Select Field
      </Box>

      <Box
        id="setup_profitlayers"
        className={classes.appBarSelections}
        color={value > 0 ? green : grey}
        onClick={() => {changeSection(1); ReactGA.event({
          action: "Navigate",
          category: "Navigate to Setup Profitlayers",
          label: "ProfitLayers Nav bar steps"
        })}}
        style={{
          margin: width > firstStepsBreakPoint ? '0 50px' :
            width > secondStepsBreakPoint ? '0 30px' : '0 10px',
          whiteSpace: 'nowrap',
        }}
      >
        {value > 1 ? (
          <CheckBoxRoundedIcon style={{ marginRight: '10px', fontSize: 27 }} />
        ) : (
          <Box {...roundIcon}>2</Box>
        )}
        {'Setup ProfitLayers'}
        <sup style={{ fontSize: 12 }}>&reg;</sup>
      </Box>

      <Box
        id="view_profitlayers"
        className={classes.appBarSelections}
        color={value > 1 ? green : grey}
        onClick={() => {changeSection(2); ReactGA.event({
          category: "Navigation",
          action: "Navigate To View Profitlayers",
          label: "View ProfitLayers Nav bar step"
        })}}
        style={{
          margin: width > firstStepsBreakPoint ? '0 50px' :
            width > secondStepsBreakPoint ? '0 30px' : '0 10px',
          whiteSpace: 'nowrap',
        }}
      >
        {value > 2 ? (
          <CheckBoxRoundedIcon style={{ marginRight: '10px', fontSize: 27 }} />
        ) : (
          <Box {...roundIcon}>3</Box>
        )}
        {'View ProfitLayers'}
        <sup style={{ fontSize: 12 }}>&reg;</sup>
      </Box>

      <Box
        id="farm_report"
        className={classes.appBarSelections}
        color={value > 2 ? green : grey}
        onClick={() => changeSection(3)}
        style={{
          margin: width > firstStepsBreakPoint ? '0 50px' :
            width > secondStepsBreakPoint ? '0 30px' : '0 10px',
          whiteSpace: 'nowrap',
        }}
      >
        {value > 3 ? (
          <CheckBoxRoundedIcon style={{ marginRight: '10px', fontSize: 27 }} />
        )
          : (
            <Box {...roundIcon}>4</Box>
          )}
        {'Farm Report'}
      </Box>

      {/* <Box
        className={classes.appBarSelections}
        color={value > 1 ? green : grey}
        onClick={() => changeSection(4)}
        style={{
          margin: "0 30px",
          whiteSpace: "nowrap",
        }}
      >
        {value > 4 ? (
          <CheckBoxRoundedIcon style={{ marginRight: "10px", fontSize: 27 }} />
        ) :
        (
          <Box {...roundIcon}>5</Box>
        )}
        {"Market Report"}
      </Box> */}
    </Box>
  );

  const handleOperationChange = (event, value) => {
    if (value < 6) {
      setOpTabValue(value);
    } else {
      // View Reports
      setValue(2);
    }
  };

  const mobileOperationSelection = () => (
    <Box style={{ width, backgroundColor: '#ffffff' }}>
      <Tabs
        value={opTabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleOperationChange}
        variant="scrollable"
        scrollButtons="on"
        style={{ maxHeight: 30, minHeight: 30 }}
      >
        <Tab className={classes.mobileTabs} label="Harvests" />
        <Tab className={classes.mobileTabs} label="Seeding" />
        <Tab className={classes.mobileTabs} label="Chemicals and Fertilizers" />
        <Tab className={classes.mobileTabs} label="Field Passes" />
        <Tab className={classes.mobileTabs} label="Other Costs" />
        <Tab className={classes.mobileTabs} label="Other Revenue" />
        <Tab className={classes.mobileTabs} label="View Report" />
      </Tabs>
    </Box>
  );

  // Mobile report tab navigation. Type Map, Scatter, Chart. Map used for map type
  const [selectedReport, setSelectedReport] = useState({ type: 'Map', map: 0 });
  const [reportTabValue, setReportTabValue] = useState(0);

  const handleReportChange = (event, value) => {
    setReportTabValue(value);
    if (value === 5) {
      setSelectedReport({ type: 'Chart', map: null });
    } else if (value === 6) {
      setSelectedReport({ type: 'Scatter', map: null });
    } else if (value >= 7) {
      setSelectedReport({
        type: 'Insurance',
        tool:
          value === 7
            ? 'What-If Analysis'
            : value === 8
              ? 'Premium Calculator'
              : 'Insurance AI',
      });
    } else if(value === 10){
      setValue(3)
    } else {
      setSelectedReport({ type: 'Map', map: value });
    }
  };

  const mobileReportSelection = () => (
    <Box style={{ width }}>
      <Tabs
        value={reportTabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleReportChange}
        variant="scrollable"
        scrollButtons="on"
        style={{ maxHeight: 30, minHeight: 30 }}
      >
        <Tab className={classes.mobileTabs} label="Profit Map" />
        <Tab className={classes.mobileTabs} label="Elevation Map" />
        <Tab className={classes.mobileTabs} label="Operation Layers" />
        <Tab className={classes.mobileTabs} label="Variety Map" />
        <Tab className={classes.mobileTabs} label="Zone Analysis" />
        <Tab className={classes.mobileTabs} label="Cost Chart" />
        <Tab className={classes.mobileTabs} label="Scatter Plot" />
        <Tab className={classes.mobileTabs} label="What-If Analysis" />
        <Tab className={classes.mobileTabs} label="Premium Calculator" />
        <Tab className={classes.mobileTabs} label="Insurance AI" />
        <Tab className={classes.mobileTabs} label="Farm Report" />
      </Tabs>
    </Box>
  );

  const templateHeader = () => (
    <Box className={classes.templateHeader}>
      <Typography
        align="center"
        style={{ color: '#f8f8ff', fontSize: width > 580 ? 40 : 30 }}
      >
        Crop Plan Template
      </Typography>

      {width < mobileBreakPoint && mobileOperationSelection()}
    </Box>
  );

  const reportNameDisplay = () => (
    <Box>
      <Box
        display="flex"
        alignItems="center"
        justifyContent="flex-end"
        fontSize={12}
        fontWeight={500}
        style={{ width: '200px' }}
        color={templateMode ? '#f8f8ff' : blackText}
      >
        {!editReportName && 'CREATING REPORT'}
      </Box>

      {editReportName ? (
        <Box display="flex" alignItems="center" style={{ width: 180 }}>
          <TextField
            variant="outlined"
            onChange={(event) => setNewReportName(event.target.value)}
            style={{ width: 160, height: 28 }}
            InputLabelProps={{
              shrink: false,
            }}
            inputProps={{
              style: {
                padding: 10,
              },
            }}
          />

          <SaveIcon
            className={classes.greenClick}
            style={{ fontSize: 20 }}
            onClick={() => handleReportNameChange('save')}
          />

          <CancelOutlinedIcon
            className={classes.greenClick}
            style={{ fontSize: 20 }}
            onClick={() => handleReportNameChange('cancel')}
          />
        </Box>
      ) : (
        <Box
          display="flex"
          justifyContent="flex-end"
          alignItems="center"
          fontWeight={500}
          color={templateMode ? '#f8f8ff' : blackText}
        >
          {reportName}
          <EditIcon
            className={classes.edit}
            onClick={() => setEditReportName(true)}
            style={{
              fontSize: 20,
              paddingBottom: 5,
              color: templateMode ? '#f8f8ff' : green,
            }}
          />
        </Box>
      )}
    </Box>
  );
  // #endregion

  return (
    <Box>
      <AppBar
        position="static"
        color="transparent"
        style={{ boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.05)' }}
      >
        {!templateMode ? (
          <Box
            className={classes.appBar}
            style={{ padding: width > mobileBreakPoint ? 20 : '20px 8px' }}
          >
            {width > mobileBreakPoint ? steps() : mobileSteps()}
            {/* Conditionaly show report. Show in settings if hidden here */}
            {/* { width > 1200 && reportNameDisplay() } */}
            
          </Box>
        ) : (
          templateHeader()
        )}
      </AppBar>

      <Slide
        in={value === 0}
        style={value !== 0 ? { display: 'none' } : {}}
        direction="right"
      >
        <Box display="flex">
          <FieldSelection
            mobileView={width <= 920}
            hideFieldSelection={hideFieldSelection}
            showMap={value === 0}
            changeSection={setValue}
            continueText="Create ProfitLayers"
            changePage={setSection}
            setDataLayersStep={setDataLayersStep}
          />
        </Box>
      </Slide>

      {value === 1 ? (
        <Box>
          {/* Display Setup Profit Layers when operations are loaded */}
          {operations()}
          {footer()}
        </Box>
      ) : value === 2 ? (
        <Box style={{ height: height - 195 }}>
          {/* profitMapAccess ?  Reports will go here when not testing : */}
          <Reports
            field={field}
            selectedReport={selectedReport}
            operations={getProfitMapOperations()}
            convertToFieldOperations={convertToFieldOp}
            perAcre={perAcre}
            netSeededAcres={netSeededAcres}
            summary={profitMapSummary}
            year={selectedYears}
            profitMapResponse={profitMapResponse}
            profitMap={profitMap}
            operationMap={operationMap}
            getProfitMap={getProfitMap}
            logBinRange={logBinRanges}
            getBinRange={BinRange}
            pushProfitMap={pushProfitMap}
            getElevationMap={getElevationMap}
            elevationMap={elevationMap}
            totalCosts={totalCosts}
            totalRevenue={totalRevenue}
            totalProfit={totalProfit}
            loading={mapLoading}
            profitMapLoading={profitMapLoading}
            operationMapLoading={operationMapLoading}
            elevationMapLoading={elevationMapLoading}
            hideMapSelection={width < mobileBreakPoint}
            displayed={[
              {
                type: 'Harvest',
                ops: displayedHarvests,
                total: harvestRevenue,
              },
              {
                type: 'Seeding',
                ops: displayedSeedings,
                total: seedCost,
              },
              {
                type: 'Applications',
                ops: displayApplicationOps,
                total: applicationCost,
              },
              {
                type: 'Field Passes',
                ops: displayFieldPasses,
                total: fieldPassCost,
              },
              {
                type: 'Other Costs',
                ops: displayOtherCosts,
                total: otherCostsTotal,
              },
            ]}
            setSavedTemplate={setSavedTemplate}
            savedTemplate={savedTemplate}
            getAllTemplates={getAllTemplates}
            profitMapAccess={profitMapAccess}
            zoneAnalysis={zoneAnalysis}
            zoneAnalysisRes={zoneAnalysisRes}
            setZoneAnalysisRes={setZoneAnalysisRes}
            source={source}
            getElevationIndex={getElevationIndex}
            highRes={highRes}
            NPK={NPK}
            getNutrientParser={getNutrientParser}
            authenticated={authenticated}
            setProfitMapAccess={setProfitMapAccess}
            handlePayment={handlePayment}
            setScatterLoaded={setScatterLoaded}
            processing={processing}
            setProcessing={setProcessing}
            syncData={syncData}
            errorMessage={errorMessage}
            isZoneAnalysisRes={isZoneAnalysisRes}
            setZoneAnalysisLoader={setZoneAnalysisLoader}
            mobileBreakPoint={mobileBreakPoint}
            harvestRevenue={harvestRevenue}
            otherRevenue={otherRevenueTotal}
            profitMapNeedsUpdating={profitMapNeedsUpdating}
            setProfitMapNeedsUpdating={setProfitMapNeedsUpdating}
            selectedTemplate={selectedProfitTemplate}
            setSelectedTemplate={setSelectedProfitTemplate}
            setMapLoadedOnViewProfitLayers={setMapLoadedOnViewProfitLayers}
            templateRes={templateRes}
            setTemplateRes={setTemplateRes}
            legendBinColors={legendBinColors}
            setLegendBinColors={setLegendBinColors}
            legendBinRanges={legendBinRanges}
            setLegendBinRanges={setLegendBinRanges}
            orgs={user.allOrgs}
            orderID={orderId}
            subOrderID={subOrderId}
            combineOps={combineOps}
            clipByBoundary={clipByBoundary}
            files={filePaths}
            dieselPrices={dieselPrices}
          />
          {footer()}
        </Box>
      ) : value === 3 ? (
        <Box style={{ height: height - 195 }}>
          <FarmReportContainer
            field={field}
            selectedYears={selectedYears}
            convertPlantings={convertPlantings}
            years={years}
            profitMapAccess={profitMapAccess}
            processing={processing}
            setProcessing={setProcessing}
            authenticated={authenticated}
            setProfitMapAccess={setProfitMapAccess}
            handlePayment={handlePayment}
            orgs={user.allOrgs}
          />
          {footer()}
        </Box>
      ) : value === 4 ? (
        <Box style={{ height: height - 195 }}>
          <MarketReportContainer
            field={field}
            selectedYears={selectedYears}
            convertPlantings={convertPlantings}
            years={years}
            profitMapAccess={profitMapAccess}
            processing={processing}
            setProcessing={setProcessing}
            authenticated={authenticated}
            setProfitMapAccess={setProfitMapAccess}
            handlePayment={handlePayment}
            orgs={user.allOrgs}
          />
          {footer()}
        </Box>
      ) : (
        ''
      )}

      {settingsOpen && (
        <Settings
          netSeededAcres={netSeededAcres}
          setNetSeededAcres={setNetSeededAcres}
          perAcre={perAcre}
          setPerAcre={setPerAcre}
          highRes={highRes}
          setHighRes={setHighRes}
          settingsOpen={settingsOpen}
          setSettingsOpen={setSettingsOpen}
          handleReportNameChange={handleReportNameChange}
          setEditReportName={setEditReportName}
          editReportName={editReportName}
          reportName={reportName}
          setNewReportName={setNewReportName}
          clipByBoundary={clipByBoundary}
          setClipByBoundary={setClipByBoundary}
          syncData={syncData}
          orgSynced={`${orgSyncDate} - ${orgSyncTime}`}
          operationsSynced={`${lastSyncDate} - ${lastSyncTime}`}
          boundarySynced={`${boundarySyncDate} ${boundarySyncTime}`}
          setManuallyUpdated={setManuallyUpdated}
        />
      )}

      <Connect
        open={loginPromptOpen}
        setOpen={setLoginPromptOpen}
        message={toContinuePrompt}
      />

      <IntegrationStatus
        open={integrationStatusOpen}
        setOpen={updateIntegrationModalState}
      />

      {/* <IntegrationStatusModal
        open={integrationStatusOpen}
        setOpen={updateIntegrationModalState}
        authenticated={user.isAuthenticated}
        message={integrationStatus}
        source={integrationSource}
        flag={integrationFlag}
      /> */}

      {generatePdf && (
        <CreatePdf
          open={generatePdf}
          setOpen={setGeneratePdf}
          errorMessage={errorMessage}
          mapLoading={mapLoading}
          mapLoadedOnViewProfitLayers={mapLoadedOnViewProfitLayers}
          field={field}
          selectedYear={selectedYear}
          netSeeded={netSeededAcres}
          totalRevenue={totalRevenue}
          totalCosts={totalCosts}
          profitMapResponse={profitMapResponse}
          seedings={displayedSeedings}
          harvests={displayedHarvests}
          chemicals={displayApplicationOps}
          fieldPasses={displayFieldPasses}
          otherCosts={displayOtherCosts}
          otherRevenue={displayOtherRevenue}
          scatterLoaded={scatterLoaded}
          perAcre={perAcre}
        />
      )}

      {infoModalOpen && (
        <InfoModal open={infoModalOpen} setOpen={setInfoModalOpen} />
      )}

      {reintegrateNecessary && reintegrate()}

      {/* For when user wants to upload a precision ag file */}
      {uploadFile && (
        <UploadAgFile
          uploadFile={uploadFile}
          setUploadFile={setUploadFile}
          orgID={field.orgId}
        />
      )}

      {progressModalDiv()}

      <PopupManager
        acreageReporting
        droneAnalysis
        soilTesting
        waterManagement
      />

      <Snackbar
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        style={{ backgroundColor: green }}
        open={generatingPrompt}
        autoHideDuration={60000}
        onClose={handleCloseGeneratingPrompt}
        action={(
          <>
            <Box fontWeight={500} fontSize={16} color="#ffffff" display="flex">
              <Typography>
                {'This operation may take a couple minutes. While you wait, '}
                <a
                  href="/app/DataLayers"
                  target="_blank"
                  style={{ color: '#ffffff' }}
                >
                  view slope, real time weather, satellite data and more here
                </a>
              </Typography>
              <IconButton
                style={{ margin: '0 8px' }}
                size="small"
                aria-label="close"
                color="inherit"
                onClick={handleCloseGeneratingPrompt}
              >
                <CloseIcon fontSize="small" />
              </IconButton>
            </Box>
          </>
        )}
      />
    </Box>
  );
}

ProfitLayers.propTypes = {
  plStep: PropTypes.number.isRequired,
  integrating: PropTypes.bool.isRequired,
  setSection: PropTypes.func.isRequired,
  setDataLayersStep: PropTypes.func.isRequired,
};

/* eslint-disable camelcase */
/* eslint-disable guard-for-in */
/* eslint-disable no-prototype-builtins */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-nested-ternary */

// -------------------- IMPORTS --------------------
//#region - imports
// React
import React, { useEffect, useRef, useState, useContext } from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  AppBar,
  Box,
  Button,
  MenuItem,
  Select,
  Slide,
  Typography,
  Tabs,
  Tab,
  Divider,
} from '@material-ui/core';
import PublishIcon from '@material-ui/icons/Publish';
import AssessmentOutlinedIcon from '@material-ui/icons/AssessmentOutlined';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from '../../styles/select';
import { blackText } from '../../styles/colors';
import * as turf from '@turf/turf';
import * as wkt from 'terraformer-wkt-parser';
import * as L from 'leaflet';
import * as _ from 'underscore';
import { cloneDeep } from 'lodash';
import { useSnackbar } from 'notistack';

// Contexts and Shared
import { UserContext } from '../Context/UserContext';
import { Connect } from '../Shared/Connect';
import { IntegrateModal } from '../Shared/IntegrateModal';
import { SpinningLoader } from '../Shared/SpinningLoader';

// API calls
import {
  deleteZones,
  getCLUIntersectionsOld,
  getCLUIntersections,
  getCLUInfo,
  getCVTCrops,
  getCVTMapping,
  getCVTCropTypes,
  getCVTIntendedUses,
  getFieldsInfo,
  getMultipleOperations,
  getNewZoneID,
  getNonCLUBoundaries,
  getNonCLUBoundariesGivenBounds,
  getOperationBoundary,
  getOperationZones,
  getProfitMap,
  getRMACrops,
  getRMATypes,
  getRMAPractices,
  getStateAndCounty,
  getVectorImage,
  saveAcreageZones,
} from '../../utils/dataFetchers';

// Useful functions and components
import { CustomToolTip } from '../../utils/customComponents';
import { useWindowDimensions } from '../../utils/dimensions';
import {
  consoleImportant,
  createYearArray,
  createYearArrayRange,
} from '../../utils/helpers';
import { reprojectCalcArea } from '../../utils/reprojectCalcArea';
import { UploadAgFile } from '../ProfitLayers/utils/UploadAgFile';
import {
  addToMappingObject,
  convertToLocalDatetime,
  createFeature,
  formatOperationZones,
  formatZoneForSave,
  parseDate,
} from './helpers';
import { TabPanel } from '../Shared/TabPanel';

// Navigation and Display
import { Steps } from './Navigation/steps';
import { Footer } from './Navigation/footer';
import { FieldSelection } from './Functionality/FieldSelection';
import { CreateReviewPage } from './Functionality/ReviewPage';
import { FinalPage } from './Functionality/FinalPage';
import { additionalTextInputs, cartDropdownData, precisionSources } from './presetData';
import { FarmSection } from './Display/FarmSection';
import { Overview } from './Display/Overview';
import { ViewSubmitted } from './SubmittedReports/ViewSubmitted';
import { CreateCARTPdf } from './PDF/CreateCARTPdf';
import { CreateGARTPdf } from './PDF/CreateGARTPdf';

// Modals 
import { AgentPortalModal } from './Agents/AgentPortalModal';
import { ChangeExplanation } from './Modals/ChangeExplanation';
import { CLUUploadModal } from './Modals/CLUUpload';
import { ConfirmationPopup } from './Modals/ConfirmationPopup';
import { EditCLUAccesses } from './Modals/EditCLUAccesses';
import { ReportFileDownload } from './Modals/ReportFileDownload';
import { ReportFileUpload } from './Modals/ReportFileUpload';
import { ResetWarning } from './Modals/ResetWarning';
import { SaveYearWarning } from './Modals/SaveYearWarning';
import { Settings } from './Modals/Settings';

// Map Interactions
import { EditMap } from './Modals/EditMap';
import { CreateNewOperation } from './Modals/CreateNewOperation';
//#endregion

// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
  },
  body: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    fontSize: '16px',
  },
  errorMessageBox: {
    ...theme.errorMessageBox,
    flexDirection: 'column',
    fontSize: '22px',
    maxWidth: '1500px',
  },
  fieldSelection: {
    // minWidth instead of width is needed here for some reason
    // minWidth: 286,
    minWidth: 310, // 310 used to avoid x scrollbar on y overflow
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: '0px 4px',
    fontWeight: 500,
    backgroundColor: theme.palette.greys.light,
    overflowY: 'auto',
  },
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    padding: '24px',
    color: blackText,
  },
  step: {
    width: '100%',
    overflowY: 'auto',
    display: 'flex',
  },
}));

// -------------------- MAIN FUNCTION --------------------
/**
 * Controls renders, state management, and API calls for Acreage Reporting component.
 * @param {Bool} debuggingOutputs Whether or not to show outputs (in console) meant for debugging
 * @param {String} impersonating User whose data Agent is using
 * @param {Function} loadSharedUsers Get Growers who have shared data
 * @param {Array} organizations User organizations
 * @param {Object} selectedCLUOrg Org to associate uploaded CLU data with ({id: -1, name: ''})
 * @param {Function} setImpersonating Set user who's data Agent is using
 * @param {Function} setSelectedCLUOrg Changes state of selectedCLUOrg
 * @param {Function} setUseOrgCLUData Expects true or false
 * @param {Array} sharedUsers Growers who have shared data with current user
 * @param {Boolean} useOrgCLUData CLU data to use. Either true or false
 * @param {String} zoneType Whether following 'CART' or 'GART' path
 * @returns {JSX} Main Acreage Reporting Interface
 */
export function AcreageReporting({
  debuggingOutputs,
  impersonating,
  loadSharedUsers,
  organizations,
  selectedCLUOrg,
  setImpersonating,
  setSelectedCLUOrg,
  setUseOrgCLUData,
  sharedUsers,
  useOrgCLUData,
  zoneType,
}) {
  // -------------------- VARIABLES --------------------
  //#region - variables
  const { height, width } = useWindowDimensions();
  // Height is -191 to account for (navbar - 56, steps - 75, footer - 60, and spare - 1)
  const chosenHeight = height - 192;
  // Width in pixels designating when to change to mobile view
  const mobileBreakPoint = 1019; // Updated for enlarged steps, may want separate in future
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Contexts and user info
  const user = useContext(UserContext)[0];
  const [authenticated, setAuthenticated] = useState(false);

  // -------------------------------------------------------- //
  // Open Modals
  const [agentPortalOpen, setAgentPortalOpen] = useState(false);
  const [editAccessesOpen, setEditAccessesOpen] = useState(false);
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);
  const [newOperationDefaults, setNewOperationDefaults] = useState(undefined); // type {} - set to first operation when retrieved
  const [newPreventedPlanting, setNewPreventedPlanting] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false); // Confirmation dialog
  const [openCreateNewOperation, setOpenCreateNewOperation] = useState(false);
  const [openIntegration, setOpenIntegration] = useState(false);
  const [openMap, setOpenMap] = useState(false);
  const [openResetWarning, setOpenResetWarning] = useState(false); // Reset dialog
  const [openSaveYearWarning, setOpenSaveYearWarning] = useState(false);
  const [pdfOpen, setPdfOpen] = useState(false);
  const [toContinuePrompt, setToContinuePrompt] = useState('Please sign in to continue using the tool');
  
  // Open modals and other modal-specific data
  // Settings modal
  const [settingsOpen, setSettingsOpen] = useState(false);
  const [useOwnCLUData, setUseOwnCLUData] = useState(''); // '' or 'EmailGoesHere'
  const [includeNonCLUBoundaries, setIncludeNonCLUBoundaries] = useState(true); // defualt to true

  // Change Explanation modal
  const [openExplanation, setOpenExplanation] = useState(false);
  // Updated values user will provide explanation for in modal
  const [explanations, setExplanations] = useState([]);
  // Explanation modal needs to know to update operation or only an intersection
  const [providingExplanationFor, setProvidingExplanationFor] = useState('');

  // Personal file upload/download modals
  const [reportFileUploadOpen, setReportFileUploadOpen] = useState(false);
  const [reportFileDownloadOpen, setReportFileDownloadOpen] = useState(false);
  const [fileOperationID, setFileOperationID] = useState(null);
  const [fileOperationInfo, setFileOperationInfo] = useState(null);
  // Precision Ag upload modal
  const [uploadPrecisionFile, setUploadPrecisionFile] = useState(false);
  // CLU file upload modal
  const [showCLUFileUpload, setShowCLUFileUpload] = useState(false);
  const notInitialLoad = useRef(false);

  // -------------------------------------------------------- //
  // Navigation
  // ANY calls to change step should go THROUGH changeStep function
  const [step, setStep] = useState(0); // (0) Farm/field selection/Setup Page, (1) Review Page, (2) Finalize Page, (9) View Page 
  const [prevStep, setPrevStep] = useState(0);

  // "Constants"
  // This defaults to 'Seeding' for now, but could eventually be set by user to 'Seeding' or 'Harvest'
  const [opType, setOpType] = useState('Seeding');
  const gartPath = (zoneType === 'GART');
  // console.log('zoneType, gartPath :>> ', zoneType, gartPath);
  const yesCode = 'Y';
  const noCode = 'N';
  const fakeCLUTemplate = {
    admin_county: 'X',
    admin_state: 'X',
    clu_identifier: 'X',
    clu_number: 'X',
    cluShape: 'X',
    displayNumber: 'X',
    farm_number: 'X',
    tract_number: 'X',
  };

  // -------------------------------------------------------- //
  // Functionality
  const [initialLoadComplete, setInitialLoadComplete] = useState(false);
  const [successfullyLoadedData, setSuccessfullyLoadedData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState([]);
  // const defaultYear = 2018; // 2018 is nice for testing
  const defaultYear = new Date().getFullYear(); // This is the official one to have on prod
  const [commodityYear, setCommodityYear] = useState(defaultYear);
  const commodityYears = createYearArray(10, true);
  const yearDataLoaded = useRef(0);
  // Stores the order we want to display selected farms, fields, and operations and all the table data
  const [orderedMappings, setOrderedMappings] = useState([]);
  const [opMapMappings, setOpMapMappings] = useState({});
  const [currentExpanded, setCurrentExpanded] = useState([]);
  const [refreshTableDates, setRefreshTableDates] = useState(true);
  
  // Indicate if items are currently being saved
  const [savingReport, setSavingReport] = useState(false);
  const [lastSaved, setLastSaved] = useState(-2);
  
  // We need to notice that a change was made when the following functions call setOrderedMappings (modify orderedMappings)
  // + resetState needs to reset changesMade
  // - updateOperationsMaps provides nothing that needs to be saved
  // + updateOperation and updateIntersection (handle(Crop/CropType)Update functions are all called from these too)
  // - addOperation already saves so no need for warning in that case
  // + basicUpdateOperation and basicUpdateIntersection
  // - EditMap saves before calling updateOperationInMapping
  // - saveReportData can be ignored
  const [changesMade, setChangesMade] = useState(false);

  // Data for editing clus and operations on map
  const [cluBoundary, setCluBoundary] = useState({});
  // Stores operation info and indexes for prevented planting, edit CLU boundary and storing change explanations
  const [operationToEdit, setOperationToEdit] = useState({});

  // Store values that will be referenced multiple times
  const [statesSeen, setStatesSeen] = useState({});
  const [commoditiesSeen, setCommoditiesSeen] = useState({});
  const [commodityTypesSeen, setCommodityTypesSeen] = useState({});

  // Handle next steps after changes to crops/cropTypes require setting a state var before doing more work
  const [commSeenCallback, setCommSeenCallback] = useState({});
  const [commTypesSeenCallback, setCommTypesSeenCallback] = useState({});

  // Stores seen CLU information - for CLU data that does not change and data that has to stay consistent between all CLUs
  // (ie. things that are not specifically associated with an operation)
  //    Assumption for key is that clu_identifier is unique - which it should be
  //    admin_state, admin_county, farm_number, tract_number, clu_number, clu_identifier (keep in both for matching)
  //    cluShape (full CLU boundary), cluProducerReviewRequestIndicator
  const [clusSeen, setClusSeen] = useState({});

  // -------------------------------------------------------- //
  // MultiSelection Functionality - only keeps needed data for rest of tool. Data only for FieldSelection is in that component
  // Hold org, farm, and field data
  const [chosenOrg, setChosenOrg] = useState({ id: -1, name: '' });
  const [farmMaps, setFarmMaps] = useState({}); // Stores farm id to farm name mappings for quick retrieval
  const [selectedFields, setSelectedFields] = useState(new Set());
  const [chosenFields, setChosenFields] = useState([]);
  const [fieldMaps, setFieldMaps] = useState({}); // Stores mappings from field id to field name and farm it belongs to for quick retrieval

  // Copies of variables above in AcreageReporting so that FieldSelection can have its own state
  const [fs_chosenOrg, fs_setChosenOrg] = useState({ id: -1, name: '' });
  const [fs_farmMaps, fs_setFarmMaps] = useState({});
  const [fs_selectedFields, fs_setSelectedFields] = useState(new Set());
  const [fs_chosenFields, fs_setChosenFields] = useState([]);
  const [fs_fieldMaps, fs_setFieldMaps] = useState({});
  const [selectedFieldsAreLoaded, setSelectedFieldsAreLoaded] = useState(false);

  const [showFieldSelection, setShowFieldSelection] = useState(true);
  const [viewingField, setViewingField] = useState(undefined); // type {} - set to field being viewed later
  // State var to help reload data even when nothing in FieldSelection vars have changed
  const [reloadData, setReloadData] = useState(false);

  // -------------------------------------------------------- //
  // For Acreage Summary Page
  const [CLUSummary, setCLUSummary] = useState([]);
  const [operationSummary, setOperationSummary] = useState([]);
  const [summaryExpanded, setSummaryExpanded] = useState([]);

  // Final page, user entered info for reports - stay here as needed here to maintain states
  // Not all in one state variable to at least slightly limit copying things onChange that do not need to be copied.
  const [enterTaxID, setEnterTaxID] = useState(true);
  const [identifier, setIdentifier] = useState({ interagencyCustomerIdentifier: '', taxIdentification: '', taxIdentificationTypeCode: 2 });
  const [enterPersonalName, setEnterPersonalName] = useState(true);
  const [name, setName] = useState({
    businessName: '', firstName: '', middleName: '', lastName: '', nameSuffix: '',
  });
  const [reportName, setReportName] = useState('');
  const [reinsuranceYear, setReinsuranceYear] = useState(-1);
  const [signature, setSignature] = useState(null);
  const [printedName, setPrintedName] = useState('');
  const [signatureTimeStamp, setSignatureTimeStamp] = useState(null);

  // Object with structure of {farm: index, field: index}. Used for tracking the currently selected/displayed org/farms/fields
  // Also used for updating operationMaps (only updates currently shown farm & field) 
  // and updating operation with new intersections (from updateOperationInMapping used in EditMap)
  const [selectedIndices, setSelectedIndices] = useState({});

  // CLU identifiers user has generated PDF for this session
  const [pdfGeneratedFor, setPdfGeneratedFor] = useState([]);
  //#endregion

  
  // -------------------- USEEFFECTS --------------------
  //#region - useEffects
  // Little prompt to user if it's their first time
  useEffect(() => {
    setAuthenticated(user.isAuthenticated);
    if (user.new) {
      if (chosenOrg.id !== -1) {
      } else {
        enqueueSnackbar('Please select an organization to get started');
      }
    }
  }, [user]);

  // Decide whether or not the current state of fs_selectedFields matches the state of selectedFields
  // AND whether or not the current state of commodityYear matches yearDataLoaded
  useEffect(() => {
    const difference = [...fs_selectedFields].filter((elem) => !(selectedFields.has(elem)));
    if (difference.length || (commodityYear !== yearDataLoaded.current)) setSelectedFieldsAreLoaded(false);
    else setSelectedFieldsAreLoaded(true);
  }, [fs_selectedFields, commodityYear])

  // This will call initialLoad after user clicks "Load Acreage Data" and handleLoadingData is called
  useEffect(() => {
    // _.size(selectedFields) does not seem to work to get the size for some reason...
    // console.log('selectedFields :>> ', selectedFields);
    // console.log('_.size(selectedFields) :>> ', _.size(selectedFields));
    // console.log('selectedFields.size :>> ', selectedFields.size);
    if (selectedFields.size && reloadData) {
      initialLoad();
      setReloadData(false);
    }
  }, [selectedFields, reloadData]);

  useEffect(() => {
    if (initialLoadComplete && !error[0]) {
      setSuccessfullyLoadedData(true);
    } else {
      setSuccessfullyLoadedData(false);
    }
  }, [initialLoadComplete, error]);

  // This takes the right actions when step is changed.
  useEffect(() => {
    if (prevStep === 0 && step !== 0) {
      // If going from setup page to any other page
      if (!gartPath) { createCLUSummary(); } else { createOperationSummary(); }
    }
    setPrevStep(step);

    // Reset reinsuranceYear when user goes to first or second step (somewhere they can save)
    // Reason for this is because we include reinsuranceYear in Zones table...
    // It doesn't make sense to include it but that is what is happening. There's no need to have it change before saving.
    // That could cause the user to lose data..
    // NOTE: Simpler than resetting it here would be to just never change reinsuranceYear state var. Have a copy in FinalPage that they can edit before submitting instead..
    if (step === 0 || step === 1) {
      determineReinsuranceYear(commodityYear);
    }
  }, [step]);

  // useEffect(() => {
  //   console.log('reinsuranceYear :>> ', reinsuranceYear);
  // }, [reinsuranceYear])


  // This is the index cropTypes and intendedUses will be found in (...Callback.updates) variables
  // This index should not be changed (unless changed in handleCropUpdate and handleCropTypeUpdate)
  const relevantIndex = 1;
  // Useful to make sure commoditiesSeen has updated before setting orderedMappings in updateOperation later on
  useEffect(() => {
    if (_.size(commSeenCallback) && commoditiesSeen.hasOwnProperty(commSeenCallback.updates[relevantIndex].cropTypes)) {
      if (commSeenCallback.level === 'operation') {
        updateOperation(
          commSeenCallback.farmIndex,
          commSeenCallback.fieldIndex,
          commSeenCallback.opIndex,
          commSeenCallback.updates,
          commSeenCallback.mappings,
        );
      } else if (commSeenCallback.level === 'clu') {
        updateIntersection(
          commSeenCallback.updates,
          commSeenCallback.indexes,
          commSeenCallback.mappings,
        );
      } else {
        consoleImportant('useEffect in AcreageReporting.js has been passed a commSeenCallback.level that is neither operation nor clu.');
      }

      // Erase this state so this does not get called again unless needed
      setCommSeenCallback({});
    }
  }, [commoditiesSeen, commSeenCallback]);

  // Useful to make sure commodityTypesSeen has updated before setting orderedMappings in updateOperation later on
  useEffect(() => {
    if (_.size(commTypesSeenCallback) && commodityTypesSeen.hasOwnProperty(commTypesSeenCallback.updates[relevantIndex].intendedUses)) {
      if (commTypesSeenCallback.level === 'operation') {
        updateOperation(
          commTypesSeenCallback.farmIndex,
          commTypesSeenCallback.fieldIndex,
          commTypesSeenCallback.opIndex,
          commTypesSeenCallback.updates,
          commTypesSeenCallback.mappings,
        );
      } else if (commTypesSeenCallback.level === 'clu') {
        updateIntersection(
          commTypesSeenCallback.updates,
          commTypesSeenCallback.indexes,
          commTypesSeenCallback.mappings,
        );
      } else {
        consoleImportant('useEffect in AcreageReporting.js has been passed a commTypesSeenCallback.level that is neither operation nor clu.');
      }

      // Erase this state so this does not get called again unless needed
      setCommTypesSeenCallback({});
    }
  }, [commodityTypesSeen, commTypesSeenCallback]);

  // Only update when selectedIndices have changed. Only time new operationMaps should be needed is then or when loading in new data
  useEffect(() => {
    updateOperationsMaps(orderedMappings, selectedIndices, true);
  }, [selectedIndices])

  // Only update orderedMappings with retrieved operation maps when opMapMappings gets set.
  // This happens when updateOperationsMaps is done running and in a useEffect so that we can use most up-to-date version of orderedMappings
  useEffect(() => {
    if (_.size(opMapMappings)) setRetrievedOpMaps(orderedMappings, opMapMappings.indices, opMapMappings.mappings);
  }, [opMapMappings])

  // When whose precision data should be used changes, reset variables related to the Field Selection component so user can start from scratch
  useEffect(() => {
    resetFieldSelectionVars();

    // When user changes grower to complete a report for, remove any selected org from CLU Upload modal,
    // and default back to user not using an org for CLU data
    // (NOTE: Even better might be to also have this stored in DB - an object with the key being grower impersonated and value being whether they want to use org data) (and use that as source of x for setUseOrgCLUData(x) and modify that whenever user changes it and loads data)
    // Do not do this the first time as it happens when component is rendered
    if (notInitialLoad.current) {
      setSelectedCLUOrg({id: -1, name: ''});
      setUseOrgCLUData(false);
    } else notInitialLoad.current = true; 
  }, [impersonating]);
  //#endregion


  // -------------------- FUNCTIONALITY --------------------
  //#region - useEffect helpers
  // Resets variables related to the Field Selection component so user can start from scratch
  const resetFieldSelectionVars = () => {
    fs_setChosenFields([]);
    fs_setChosenOrg({ id: -1, name: '' });
    fs_setFarmMaps({});
    fs_setFieldMaps({});
    fs_setSelectedFields(new Set());
  }
  //#endregion

  //#region - initialLoad & data helpers
  /**
   * NOTE: This might be missing more variables that need to be reset.
   * Resets state variables to default when new data is loaded in.
   */
   const resetState = () => {
    // For some of the missing resets, functionality is already taken care of by useEffects or does not need modification
    setLoading(true);
    // Reset these first to avoid errors and race conditions
    setOrderedMappings([]);
    setCLUSummary([]);
    setOperationSummary([]);
    // Don't know if there's any special need to reset these?..
    setCurrentExpanded([]);
    setOperationToEdit({});
    setSelectedIndices({});

    // Reset old data - avoid keeping data that is no longer needed
    // Could keep data for less requests but could also slow down client with too mcuh data
    setStatesSeen({});
    setCommoditiesSeen({});
    setCommodityTypesSeen({});
    setClusSeen({});

    // Don't change commodityYear already selected or commodityYear choices
    setExplanations(false);
    setRefreshTableDates(true);
    // Do not reset setNewOperationDefaults as that could be better for user

    // Reset change tracker
    setChangesMade(false);
    // Reset viewingField for "CreateNewOperation" modal to work well
    setViewingField(undefined);
    // Move step to Setup Page (done as staying on any other step doesn't make sense)
    changeStep(0);
  };

  /**
   * Reset the Acreage Reporting interface to the state it has when initially reaching it from Landing/Onboarding stages.
   * Do not reset FieldSelection, Agent-related states, or CLU Settings however.
   */
   const resetPage = () => {
    resetState();
    setInitialLoadComplete(false);
    setShowFieldSelection(true);
    setSelectedFieldsAreLoaded(false);
    setLoading(false);
  }
  
  // Decides what the reinsurance year should be based on the commodityYear and the current year
  const determineReinsuranceYear = (selectedYear) => {
    const year = selectedYear;

    // DO NOT ACTUALLY DO THIS. DO NOT DO THIS AS COULD CAUSE LOSS OF DATA. LET USER CHANGE IT IF NEEDED.
    // We can prob just have a state var on last page that does this and does not modify reinsurance yer in here.
    //
    // const date = new Date();
    // const currentYear = date.getFullYear();
    // const month = date.getMonth();
    // if (selectedYear === currentYear && month < 6) {
    //   // New year starts July 1. January === 0 from getMonth().
    //   year -= 1;
    // }

    setReinsuranceYear(year);
    return year;
  };

  // Decides what the report name should be based on the reinsuranceYear and the current date & time
  const determineReportName = (org = chosenOrg, reinsYear = reinsuranceYear) => {
    // Update default report name
    let chosenName;
    const date = new Date();
    const suffix = `${reinsYear} (${date.toLocaleDateString()} ${date.toLocaleTimeString()})`;
    if (org?.name && org.name !== '') {
      chosenName = `${org.name} ${zoneType} - ${suffix}`;
    } else {
      // This shouldn't be reachable, but just in case
      chosenName = `${zoneType} Report - ${suffix}`;
    }

    setReportName(chosenName);
    return chosenName;
  }

  // Handles an error during initialLoad (displaying and resetting whatever necessary)
  const handleIntialLoadError = (errorMsg) => {
    setError([true, errorMsg]);
    setLoading(false);
    // In case field selection was already closed. Make sure to get it back up.
    setShowFieldSelection(true);

    // NOTE: Here would also be a good place to designate whether something else should be shown on the error screen. 
    //      Such as: "Contact Us", "Refresh", or "Settings"/"Change CLU Data" buttons, etc. depending on the error.
  }

  // Keys that we want to move into clusSeen
  const translate = ['admin_county', 'admin_state', 'cluShape', 'farm_number', 'tract_number'];

  // Organize cluResponses by operationID for fast and easy retrieval later.
  // And move centralized info to clusSeen
  const organizeCLUs = (cluObject, cluResponseObject, tempClusSeen) => {
    // console.log('cluObject :>> ', cluObject);
    const id = cluObject.operationID;
    const cluID = cluObject.clu_identifier;
    const commonInfoObject = {};

    // (If needed, Translate and) Delete all required information
    for (const element of translate) {
      if (!tempClusSeen.hasOwnProperty(cluID)) {
        commonInfoObject[element] = cluObject[element];
      }
      delete cluObject[element];
    }
    // console.log('cluObject :>> ', cluObject);

    // For retrieval of CLU by operationID - used to associate new CLUs to their operations in initialLoad
    // Treat data from Zones table a little differently
    if (cluObject.savedAndLoaded) {
      // console.log("Has been loaded");
      if (cluResponseObject.hasOwnProperty(id)) {
        const entry = cluResponseObject[id];
        if (Array.isArray(entry) && entry.length) {
          // This should never happen. Would mean a loaded (from DB) operation was also gotten from getMultipleOperations.
          // Check operationIDs from getOperationZones are properly being passed to getMultipleOperations
          consoleImportant('organizeCLUs in AcreageReporting.js has seen a loaded operation already saved in cluResponseObject.');
          // console.log('This shouldn\'t happen.');
        }
      } else {
        // Save [] to show this operation had a boundary but won't be used as ops from Seedings table are used.
        cluResponseObject[id] = [];
      }
    } else {
      // console.log('id, cluResponseObject :>> ', id, cluResponseObject);
      // Do nothing if cluResponseObject is undefined
      // - should mean it's a call from updateIntersections (or initialLoad for saved "fake CLU"), no need for cluResponseObject
      if (!cluResponseObject) {}
      // For retrieval by operationID - for displaying in setup page
      else if (cluResponseObject.hasOwnProperty(id)) {
        cluResponseObject[id].push(cluObject);
      } else {
        cluResponseObject[id] = [cluObject];
      }
    }

    // For retrieval by clu_identifier - for displaying some information at CLU level
    if (!tempClusSeen.hasOwnProperty(cluID)) {
      // Reasons for these elements not being removed from cluObject like translate ones are:
      // clu_identifier - needed for matching between them
      // clu_number - this element is used in various files; not as much of a benefit if forcing them all to use clusSeen for just this
      // cluProducerReviewRequestIndicator - Only exists as a key for cluObject in loaded data (so removed below separately). However, in both cases, it needs to be centralized so it gets modified for all corresponding CLUs at once
      // displayNumber, operationID, and boundary are not shared so no need to do anything with them
      commonInfoObject.clu_identifier = cluObject.clu_identifier;
      commonInfoObject.clu_number = cluObject.clu_number;
      commonInfoObject.cluProducerReviewRequestIndicator = noCode;
      tempClusSeen[cluID] = commonInfoObject;
    }

    // If from Zones table, modify cluProducerReviewRequestIndicator based on what came from database and remove that as well
    // NOTE: There could be a mismatch between these indicators if data saved during different sessions is loaded.
    //    Should maybe prioritize 'Y'?..
    if (cluObject.savedAndLoaded) {
      tempClusSeen[cluID].cluProducerReviewRequestIndicator = cluObject.cluProducerReviewRequestIndicator;
      delete cluObject.cluProducerReviewRequestIndicator;
    }
  };

  // Creates "fake CLU" information with the passed nonCLUBoundary and opID and stores it in tempClusSeen (and cluResponseObject if needed)
  const createFakeCLU = async (nonCLUBoundary, opID, cluResponseObject, tempClusSeen) => {
    const fakeCLU = cloneDeep(fakeCLUTemplate);
    fakeCLU.boundary = nonCLUBoundary;
    fakeCLU.operationID = opID;
    // Store it in cluResponseObject and tempClusSeen just like all the real ones
    organizeCLUs(fakeCLU, cluResponseObject, tempClusSeen);

    // Give it a zoneID
    const newZoneID = await getNewZoneID();
    fakeCLU.zoneID = newZoneID.result;

    // Do not need to mark that this CLU's boundary has been modified as it hasn't

    /** Because this is a "fake" CLU, some items like cluShape will not be valid.
     *  Various different operations will be mapped to this same "fake CLU" and so they will not each have unique 
     *  boundaries, cluIDs, etc. The markers -1 (on section key) and 'X' (on cluID key) will be used in other parts 
     *  of the code to notify that these "CLU" entries should be handled differently.
     */
     fakeCLU.section = -1;
     return fakeCLU;
  }

  // Properly handle adding a new nonCLUBoundary. Either merge it with an existing CLU or create a new "fake CLU" and add to tempClusSeen
  const handleNonCLUBoundary = async (matchingCLUs, nonCLUBoundary, opID, cluResponseObject, tempClusSeen) => {
    let overlapFound = null;

    // Check for intersections with actual CLU intersections
    for (const match of matchingCLUs) {
      // console.log('========================================================================');
      // console.log('match.boundary, nonCLUBoundary :>> ', match.boundary, nonCLUBoundary);
      const allFeatures = getIntersections(match.boundary, nonCLUBoundary, true);
      
      // If any intersection was found, then a list of the Polygon features of both shapes are returned to union
      if (allFeatures.length) {
        overlapFound = [match, allFeatures];
        break;
      }
    } 

    // If an intersection was found, attach this boundary to that CLU 
    if (overlapFound) {
      // Merge CLU intersection's and touching boundary's boundaries
      const newBoundary = turf.union.apply(this, overlapFound[1]);
      // console.log('newBoundary :>> ', newBoundary);

      const match = overlapFound[0];
      // The area that will be added later by addCLUDefaults will be the merged area. We need to get the area before boundary merges
      addCLUDefaults(match, {}); // Only modification that will persist from this is 'acres'
      match.boundary = JSON.stringify(newBoundary.geometry);
      // Here we also want to mark this CLU to show that things sticking outside of it's boundary have been added
      match.section = -1;

      // Lastly, if needed, make sure to mark that this CLU's boundary has been modified
      if (!match.reportedAcreageModifiedIndicator || match.reportedAcreageModifiedIndicator === noCode) {
        match.reportedAcreageModifiedIndicator = yesCode;
        match.reportedAcreageModifiedReasonCode = 'O';
        match.reportedAcreageModifiedOtherReasonText = 'Attached "orphaned boundary"';
      }
      // console.log('match, match.reportedAcreageModifiedIndicator :>> ', match, match.reportedAcreageModifiedIndicator);

      return [false, match];
      
      // NOTE: Considering how this looks and that the shapes might be clipped if the user tries to edit the boundary anyways..
      //      And that it probably won't be accepted by RMA (unless there's a size limit) and would just generally be confusing,
      //      I think it might just be better to show all these other shapes as separate instead of tacking them onto CLUs.
      //      So, this if(){} would change (would basically merge into the first else...) but the other two would stay the same
      // Seems we do not want that for now but if wanted later, this should be an easy change.
    }
    // If no intersections were found, make it it's own "CLU" island
    else {
      // Create a "fake CLU" to group up these sorts of boundaries
      const fakeCLU = await createFakeCLU(nonCLUBoundary, opID, cluResponseObject, tempClusSeen);
      return [true, fakeCLU];
    }
  }

  /**
   * Gets the associated crop types for the given crop code from 2021 ACRSI CVT table
   * Proceeds to create display values for these and returns the results.
   * @param {String} commodityCode crop code to be matched against
   * @returns {null|Object} null if nothing was returned or call failed or the found types
   */
  const getCommodityTypes = async (commodityCode) => {
    const cropTypes = await getCVTCropTypes({ commodityCode });
    if (!cropTypes) {
      // NOTE: If undefined was returned, it's probably best to retry call before saying this
      enqueueSnackbar('An error occurred. You might have been logged out. Please refresh the page and try again.');
      return [{ commodityTypeCode: 'Refresh', commodityTypeName: 'Error', displayValue: 'Error, Refresh' }];
    }

    // Error handling - if empty result or string saying something's invalid
    if (!cropTypes.length || typeof cropTypes === 'string') {
      return null;
    }

    // Add displayValue and store results
    addDisplayCodes(cropTypes, 'cropType');
    return cropTypes;
  };

  /**
   * Given an operation or CLU and its associated crop, either get the crop types if they are new, or don't.
   * Also checks whether there were no returned crop types and correctly marks that for operation or CLU.
   * @param {String} commodityCode chosen crop code
   * @param {Object} target operation or CLU to check for
   * @param {String} type designates whether this call is for an 'operation' or 'CLU'
   * @param {Object} tempCommoditiesSeen object to store mappings for crops to crop types
   * @returns {String} whether to continue
   */
  const handleCommodityTypes = async (commodityCode, target, type, tempCommoditiesSeen) => {
    if (!tempCommoditiesSeen.hasOwnProperty(commodityCode)) {
      const cropTypes = await getCommodityTypes(commodityCode);
      if (!cropTypes) {
        tempCommoditiesSeen[commodityCode] = undefined;
        target.error = `There are no crop types available for insurance coverage for this ${type}'s crop.`;
        return 'continue';
      }
      tempCommoditiesSeen[commodityCode] = cropTypes;
    } else {
      const foundTypes = tempCommoditiesSeen[commodityCode];
      // We need to check this to make sure cvtCrops was not undefined
      if (foundTypes === undefined) {
        target.error = `There are no crop types available for insurance coverage for this ${type}'s crop.`;
        return 'continue';
      }
    }
    return '';
  };

  /**
   * Gets the associated intended uses for the given crop code and crop type code from 2021 ACRSI CVT table
   * Proceeds to create display values for these and returns the results.
   * @param {String} commodityCode crop code to be matched against
   * @param {String} commodityTypeCode crop type code to be matched against
   * @returns {null|Object} null if nothing was returned or call failed or the found uses
   */
  const getIntendedUses = async (commodityCode, commodityTypeCode) => {
    const intendedUses = await getCVTIntendedUses({ commodityCode, commodityTypeCode });
    if (!intendedUses) {
      // NOTE: If undefined was returned, it's probably best to retry call before saying this
      enqueueSnackbar('An error occurred. You might have been logged out. Please refresh the page and try again.');
      return [{ intendedUseCode: 'Refresh', intendedUseName: 'Error', displayValue: 'Error, Refresh' }];
    }

    // Error handling - if empty result or string saying something's invalid
    if (!intendedUses.length || typeof intendedUses === 'string') {
      return null;
    }

    // Add displayValue and store results
    addDisplayCodes(intendedUses, 'intendedUse');
    return intendedUses;
  };

  /**
   * Given an operation or CLU and its associated crop/crop type, either get the intended uses if they are new, or don't.
   * Also checks whether there were no returned intended uses and correctly marks that for operation or CLU.
   * @param {String} commCodeAndCommTypeCode merged chosen crop code and crop type
   * @param {String} commodityCode chosen crop code
   * @param {String} commodityTypeCode chosen crop type
   * @param {Object} target operation or CLU to check for
   * @param {String} type designates whether this call is for an 'operation' or 'CLU'
   * @param {Object} tempCommodityTypesSeen object to store mappings for crop/crop type to intended uses
   * @returns {String} whether to continue
   */
  const handleIntendedUses = async (commCodeAndCommTypeCode, commodityCode, commodityTypeCode, target, type, tempCommodityTypesSeen) => {
    if (!tempCommodityTypesSeen.hasOwnProperty(commCodeAndCommTypeCode)) {
      const intendedUses = await getIntendedUses(commodityCode, commodityTypeCode);
      if (!intendedUses) {
        tempCommodityTypesSeen[commCodeAndCommTypeCode] = undefined;
        target.error = `There are no intended uses available for insurance coverage for this ${type}'s crop and chosen crop type.`;
        return 'continue';
      }
      tempCommodityTypesSeen[commCodeAndCommTypeCode] = intendedUses;
    }
    // We need to check this to make sure cvtCrops was not undefined
    else if (tempCommodityTypesSeen[commCodeAndCommTypeCode] === undefined) {
      target.error = `There are no intended uses available for insurance coverage for this ${type}'s crop and chosen crop type.`;
      return 'continue';
    }
    return '';
  };

  // This function adds some starting values to the given CLU Object.
  // Exists for consistency when it's needed and easier to track down issues that occur.
  const addCLUDefaults = (clu, thingsToMap) => {
    // // Get a new boundary in NAD83 CRS to comply with RMA requirements
    // Use contractor's reprojection code to project to UTM zone and assign those values to CLUs
    const reprojObj = reprojectCalcArea(clu.boundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
    // console.log('before clu.acres | after reprojObj.area:>> ', clu.acres, reprojObj.area);
    // console.log('reprojObj.epsgCode :>> ', reprojObj.epsgCode);

    clu.originalBoundary = clu.boundary;
    clu.acres = reprojObj.area;
    clu.finalReportedAcreage = reprojObj.area;;
    clu.projCode = reprojObj.epsgCode;

    for (const [key, val] of Object.entries(thingsToMap)) {
      clu[key] = val;
    }
    return clu;
  };

  /**
   * Checks to see whether the target has something that denotes it should be skipped for the calling function.
   * Helps decide whether a specific operation or CLU should be skipped or not based on some of it's exisitng keys:
   *
   * error - is set when something wrong happens (no data, missing boundary, etc.);
   * doNotInclude - currently in use for when 'No Match Found' option is true for crop, cropType or intendedUse;
   * shown - whether to not include this CLU in CLUSummary and so in report and pdf as well;
   *
   * NOTE: If an 'error' is present at the CLU level only (for only some of the CLUs) but not at the operation level,
   * some data could be lost between saving and reloading. This should not happen however. Will warn the user if this happens however.
   * Many issues could be marked with the 'error' tag. Would need to see which one caused this particular one..
   *
   * @param {Object} target operation or CLU object to check
   * @param {String} level 'operation' or 'CLU'
   * @param {Boolean} ignoreHidden decides whether CLUs that are hidden or have a 'No Match Found' value should be skipped
   * @returns {Boolean} true or false
   */
  const hasSkipMarker = (target, level, ignoreHidden = false) => {
    let skip = target.hasOwnProperty('error');

    if (level === 'clu') {
      if (skip) {
        enqueueSnackbar(`CLU # ${target.clu_number}, of Field: ${target.fieldName} has an error. Saving could cause loss of data.`);
      }
      // Only for createCLUSummary do we ignore CLUs that are hidden or have a 'No Match Found' option
      // (so, still include them in save and delete calls)
      if (ignoreHidden) { skip = skip || (!target.shown || target.hasOwnProperty('doNotInclude')); }
    } else if (level === 'operation') {
      // In case of 'CART' zoneType, ignore shown and doNotInclude at operation level as it can have a different value for a particular CLU
      // Only treat operation differently if trying to createCLUSummary for 'GART'
      if (gartPath && ignoreHidden) {
        skip = skip || (!target.shown || target.hasOwnProperty('doNotInclude'));
      }
    }

    return (skip);
  };

  // For a given field, find the earliest time any of its operations were saved
  const getFieldsEarliestSavedDate = (fieldItems) => {
    // console.log('fieldItems :>> ', fieldItems, fieldItems.length);
    let mostRecent;
    
    // If an operation has not been saved, this will not use the saved_at time for that one (because that operation has not been saved)
    // If an operation has an error, we will not use the saved_at time for that one either (because that operation cannot be saved)
    if (fieldItems.length > 1) {
      const validDates = fieldItems.filter((operationInfo) => (!hasSkipMarker(operationInfo[1], 'operation')));
      const dates = validDates.map((operationInfo) => operationInfo[1].saved_at);
      // console.log('dates :>> ', dates);
      // If somehow, all operations have an error or similar, do not call reduce
      if (dates.length) {
        mostRecent = dates.reduce((a, b) => (new Date(a) < new Date(b) ? a : b));
      }
    } else {
      mostRecent = fieldItems[0][1].saved_at;
    }

    // console.log('mostRecent :>> ', mostRecent);
    return mostRecent;
  }

  // Decides what to show for lastSaved (datetime) based on field selected
  const updateLastSaved = (farmIndex, fieldIndex, mapping = null) => {
    if (mapping === null) {
      mapping = orderedMappings;
    }
    // console.log('farmIndex, fieldIndex :>> ', farmIndex, fieldIndex);
    // console.log('orderedMappings :>> ', orderedMappings);

    // Get most recent saved at time for this field
    const fieldItems = mapping[farmIndex][1][fieldIndex][1];
    const mostRecent = getFieldsEarliestSavedDate(fieldItems);

    if (mostRecent) {
      const formattedDate = new Date(mostRecent);
      convertToLocalDatetime(formattedDate);
      setLastSaved(formattedDate);
    } else {
      setLastSaved(-2);
    }
  };

  // Get boundary from SeedingOperations table for operation being imported from there
  const getOpBoundary = async (operation) => {
    const operationBoundary = await getOperationBoundary(
      operation.orgID,
      operation.fieldID,
      operation.operationID,
      opType,
      impersonating,
    );

    if (!operationBoundary[0]?.boundary) {
      console.error('No boundary found for operation');
      operation.error = 'It seems your operation does not have any associated boundary. Please try again later.';
      return;
    }

    // const geoJson = L.GeoJSON.geometryToLayer(
    //   wkt.parse(operationBoundary[0].boundary),
    // ).toGeoJSON();
    const geoJson = wkt.parse(operationBoundary[0].boundary);
    return geoJson;
  };

  const setRetrievedOpMaps = async (mappingsToUse, fieldIndices, mapMappingsToUse) => {
    // If data has been reset/reloaded since call to updateOperationMaps (and mappingsToUse does not exist), then do not do this. 
    if (!_.size(mappingsToUse)) return;

    // Actually assign all maps using the most recent version of orderedMappings
    const mappings = cloneDeep(mappingsToUse); // create copy of mappings obj
    const fieldToChange = mappings[fieldIndices.farm][1][fieldIndices.field];
    for (const operation of fieldToChange[1]) {
      const operationInfo = operation[1];

      if (operationInfo.operationMap === undefined || operationInfo.operationMap === null) {
        if (operationInfo.operationID.startsWith('ACREAGEREPORTS')) {
          operationInfo.operationMap = 'NA';
          continue;
        }

        // Get the appropriate profitMap response - this await is necessary but all Promises should be fulfilled by now
        // If an operation does not exist anymore, that is not an issue as everything is done using operationIDs
        const res = await mapMappingsToUse[operation[0]];

        // Only include the operationMap is the calls succeeded
        if (!res || res.code !== undefined || res.fieldOperationList === undefined || res.profitMap === undefined || res.profitMap?.code == 500) {
          operationInfo.operationMap = 'Error';
        } else {
          operationInfo.operationMap = res.profitMap[0];
        }
      }
    }

    // Update current mappings
    setOrderedMappings(mappings);
  }
  
  const getOperationMap = async (orgID, fieldID, requestObj) => {
    let vect = await getVectorImage(orgID, fieldID, requestObj)
    // if vector image failed then try and get normal operation map
    if (vect.code !== undefined || vect.fieldOperationList === undefined || vect.profitMap === undefined || vect.profitMap.code == 500) {
      vect = getProfitMap(orgID, fieldID, requestObj)
    }
    return vect;
  }

  /**
   * Gets the operation maps for operations on the selected field that don't already have them
   * @param {Array} passedMappings orderedMappings state variable
   * @param {Object} selectedIndices Currently selected Indices for viewing field's operations
   */
  const getOperationMaps = async (passedMappings, selectedIndices) => {
    if (passedMappings.length > 0) {
      const farmIndex = selectedIndices.farm;
      const fieldIndex = selectedIndices.field;
      const farm = passedMappings[farmIndex];
      const field = farm[1][fieldIndex];
      const allPromises = []
      const mapMappings = {};

      // Make all the calls to get the necessary maps
      for (const operation of field[1]) {
        const operationInfo = operation[1];

        if (operationInfo.operationMap === undefined || operationInfo.operationMap === null) {
          // Construct data to send to get operation map from
          const fieldOperationList = [];
          if (operationInfo.operationID.startsWith('ACREAGEREPORTS')) {
            continue;
          }
          for (const variety of operationInfo.varietyNames) {
            // Construct profit map request
            const fieldOperation = {
              operationID: operationInfo.operationID,
              cropName: operationInfo.name,
              attribute: 'AppliedRate',
              fileType: 'Seeding',
              price: 1,
              variety,
              cellsize: '0.0001',
            };
            fieldOperationList.push(fieldOperation);
          }

          const requestObj = {
            FieldOperationList: fieldOperationList,
            Constant: 0,
            IsHighResolutionMapRequest: false,
            IsOperationMapRequest: true,
            Year: operationInfo.year,
          };

          const mapPromise = getOperationMap(operationInfo.orgID, operationInfo.fieldID, requestObj);
          allPromises.push(mapPromise);
          mapMappings[operation[0]] = mapPromise;
        }
      }

      // Handle all the resulting maps
      await Promise.allSettled(allPromises);
      setOpMapMappings({indices: selectedIndices, mappings: mapMappings});
    }
  };

  // If appropriate, get any missing operationMaps for the operations in the selected field (only updates currently shown farm & field)
  const updateOperationsMaps = async (passedMappings, currentIndices) => {
    try {
      if (Object.keys(currentIndices).length > 0 && passedMappings.length > 0) {
        await getOperationMaps(passedMappings, currentIndices);
      }
    } catch (err) {
      console.log("An error occurred while getting operation maps: ", err);
    }
  }
  //#endregion

  /**
   * This function is called on initial page load. It gets all the relevant information for the selected fields in terms of planting,
   * the CLU intersections, types, etc. and creates the data that is necessary to show these details in the tables and map.
   */
  const initialLoad = async () => {
    setLoading(true);
    try {
      resetState();

      // ----- GET Operation Data -----
      const reinsYear = determineReinsuranceYear(commodityYear);
      determineReportName(chosenOrg, reinsYear);
      const arRequestBody = {
        commodityYear,
        // Should just go from -1 to +1 as it doesn't make sense to include other years past that.. right?..
        commodityYears: createYearArrayRange(commodityYear - 1, commodityYear + 1),
        fieldIDs: [...selectedFields],
        operationType: opType,
        orgID: chosenOrg.id,
        zoneType, // for getOperationZones
        cluOwnerEmail: useOwnCLUData,
        ownerEmail: impersonating,
        useOrgCLUData,
      };

      // Get Operation Zones already stored in DB
      const operationZones = await getOperationZones(arRequestBody);
      if (!operationZones) {
        // Show message to user that they might be logged out
        const errorMsg = 'An error occurred. You might have been logged out. Please refresh the page and try again.';
        handleIntialLoadError(errorMsg);
        return;
      }

      // Keep track of IDs not to get new intersections for
      // console.log('operationZones :>> ', operationZones);
      let zoneOps = [];
      let existingIds = [];
      if (operationZones.length > 0) {
        [zoneOps, existingIds] = formatOperationZones(operationZones, gartPath);
      }
      arRequestBody.operationIDs = existingIds;

      // Get info on planting/seeding operations (that have not yet been saved to Zones table) from SeedingOperations table
      const seedingOps = await getMultipleOperations(arRequestBody);
      if (!seedingOps) {
        // Show message to user that they might be logged out
        const errorMsg = 'An error occurred. You might have been logged out. Please refresh the page and try again.';
        handleIntialLoadError(errorMsg);
        return;
      }

      const seedingOperations = combineOps(seedingOps);
      // console.log('seedingOperations :>> ', seedingOperations);
      // console.log('operationsMapping :>> ', operationsMapping);
      
      // Check that there are seeding operations for these field and years
      if (!seedingOperations.length && !zoneOps.length) {
        // Show message to user that no operations are available
        const errorMsg = 'No operations were found for the selected fields during the selected year.';
        handleIntialLoadError(errorMsg);
        return;
      }

      // Stores all operation data for later parsing/modification
      const seedings = [...zoneOps, ...seedingOperations];
      // console.log('seedings :>> ', seedings);

      // NOTE: Later: If there are more than like 100 operations, this is already a place I could notify user to select less farms/fields unless they have just selected one (this would probably need to be its own function as would be long and reused)

      // BEWARE: Be careful when making changes here and not in updateIntersections as updateIntersections basically emulates initialLoad.

      // Ignore items relating to CLUs for GART
      const cluResponseObject = {}; // store mappings of op.ID -> CLUs for easy retrieval later for CLUInfo
      const tempClusSeen = {}; // store common CLU information
      if (!gartPath) {
        // ----- GET CLU Data -----
        // Get CLUs intersection information for each operation's zone
        // BEWARE: If something related to use of cluResponse is changed here, it should also be changed in updateIntersections
        const cluResponse = await getCLUIntersections(arRequestBody);
        if (debuggingOutputs) console.log('cluResponse :>> ', cluResponse);

        // NOTE: In case getCLUIntersections fails for some reason. Automatically try again first before telling user to try another time.
        if (!cluResponse || typeof cluResponse === 'string') {
          const errorMsg = 'An issue occurred while loading your farms\' and fields\' information. Please try again later.';
          handleIntialLoadError(errorMsg);
          return;
        }
        if (!includeNonCLUBoundaries) {
          // Check whether any info relating to CLUs was available (if reached here, then operations were found)
          if (!cluResponse.length && !zoneOps.length) {
            // Show message to user that no intersecting CLUs are available
            // This could also mean that none of the operations in arRequestBody have a boundary. So could ask them to contact us?
            const errorMsg = ['No intersecting CLUs were found for the plantings on the selected fields during the selected year.', 'Either your plantings are missing boundaries or you have no intersecting CLUs available.'];
            handleIntialLoadError(errorMsg);
            return;
          }
        }

        const seedingCLUs = new Set();
        // Organize cluResponses by operationID for fast and easy retrieval later. And move centralized info to clusSeen
        // For seeding Ops, also assign zoneIDs to each intersection
        for (const cluObject of cluResponse) {
          organizeCLUs(cluObject, cluResponseObject, tempClusSeen);
          seedingCLUs.add(cluObject.clu_identifier);

          // Check that clu object does not have a zoneID, then create a new one and attach
          // NOTE: Could eventually just do one call (keep track of ones needing IDs and pass the number of new IDs we need)
          if (cluObject.zoneID === undefined || cluObject.zoneID === null) {
            const newZoneID = await getNewZoneID();
            cluObject.zoneID = newZoneID.result;
          } else {
            // console.log('Something is wrong. These CLUs should never have a zoneID.');
          }
        }

        // Now, get "orphaned boundaries", if appropriate
        // Need to do this here so this info gets processed correctly (even as a "fake CLU") like all other similar CLU info (addCLUDefaults, multipolygon -> polygon, etc.)
        // And because we want to use cluResponseObject before it gets data from saved Ops
        let nonCLUResponse;
        if (includeNonCLUBoundaries) {
          nonCLUResponse = await getNonCLUBoundaries(arRequestBody);
          if (debuggingOutputs) console.log('nonCLUResponse :>> ', nonCLUResponse);

          // NOTE: In case getNonCLUBoundaries fails for some reason. Automatically try again first before telling user to try another time.
          if (!nonCLUResponse || typeof nonCLUResponse === 'string') {
            const errorMsg = 'An issue occurred while loading your farms\' and fields\' information. Please try again later.';
            handleIntialLoadError(errorMsg);
            return;
          }

          // Only process this data if it exists
          if (nonCLUResponse.length) {
            // For each new boundary, compare to all new CLU intersections (for the same operation) 
            for (const nonCLU of nonCLUResponse) {
              const opID = nonCLU.operationID;
              const nonCLUBoundary = nonCLU.boundary;

              // Check if this operationID has already been seen - this should be true for everything in nonCLUResponse..
              if (cluResponseObject.hasOwnProperty(opID)) {
                const matchingCLUs = cluResponseObject[opID];
                await handleNonCLUBoundary(matchingCLUs, nonCLUBoundary, opID, cluResponseObject, tempClusSeen);
              }
              // This should never happen
              else {
                consoleImportant('This shouldn\'t be possible. AcreageReporting.js -> initialLoad');
              }
            }
          }

          // Check for any new operation that doesn't have any CLUs intersecting it and gather data to make it it's own "orphaned operation"
          // This is the same as adding any other CLU. Treated the same as above else.
          for (const op of seedingOperations) {
            if (cluResponseObject.hasOwnProperty(op.operationID)) continue;

            const opBoundary = await getOpBoundary(op);
            // Create a "fake CLU" to group up these sorts of boundaries
            await createFakeCLU(opBoundary, op.operationID, cluResponseObject, tempClusSeen);
          }

          // Make sure to add the "fake CLU" template to tempClusSeen in case there is only saved operation(s) associated with it
          organizeCLUs(fakeCLUTemplate, undefined, tempClusSeen);

          // Check whether any sort of boundary info was available at all (if reached here, then operations were found)
          // Checking cluResponseObject should be equivalent to checking cluResponse, nonCLUResponse, & fakeCLUs from seedingOperations
          if (!_.size(cluResponseObject) && !zoneOps.length) {
            // Show message to user that no intersecting CLUs are available
            // This could also mean that none of the operations in arRequestBody have a boundary. So could ask them to contact us?
            const errorMsg = 'It seems your field\'s plantings are missing boundaries. Please open them on ProfitLayers and save them or Contact Us.';
            handleIntialLoadError(errorMsg);
            return;
          }
        }

        // Organize saved Ops' CLUs by operationID as well and keep track of CLUs we need data for
        const zonesCLUs = new Set();
        for (const savedOp of zoneOps) {
          for (const cluObject of savedOp.CLUInfo) {
            organizeCLUs(cluObject, cluResponseObject, tempClusSeen);
            zonesCLUs.add(cluObject.clu_identifier);
          }
        }

        // Find which CLUs we do not have the original shape of yet and get them
        // (ie. all the ones that were saved and not gotten from intersections query with seeding operations)
        for (const identifier of seedingCLUs) {
          zonesCLUs.delete(identifier);
        }
        if (zonesCLUs.size) {
          const missingCLUInfo = await getCLUInfo({ 
            cluIDs: [...zonesCLUs],
            cluOwnerEmail: useOwnCLUData,
            orgID: useOrgCLUData ? chosenOrg.id : '', 
            ownerEmail: impersonating,
          });
          if (!missingCLUInfo || typeof missingCLUInfo === 'string') {
            const errorMsg = 'An issue occurred while retrieving your CLU information. Please try again later.';
            handleIntialLoadError(errorMsg);
            return;
          }

          // At this point, if nothing was returned from getCLUInfo (or getCLUIntersections), then the user probably does not have access to CLU data for any of the operations they loaded in.
          if (!cluResponse.length && !missingCLUInfo.length) {
            // Show message to user that no CLU data is available for the retrieve & saved planting data with current "CLU Data" selections
            // Try to make it clear that data has been saved when using another set of CLU Data settings
            // NOTE: Should probably make it clearer if it happens with any operation and NOT just show the rest of the data...? 
            const errorMsg = [
              'It appears you have previously saved planting information for the selected fields.',
              'However, you do not have access to the CLU data associated with these saved plantings with your current settings.', 
              'To access that data, please reset the page from the settings in the top right and try changing your "CLU Data" settings before reloading your data.', 
              'If you would like to use a different set of CLUs for your report, you can then reset your data and proceed to change your "CLU Data" settings to what you would like to use.'
            ];
            handleIntialLoadError(errorMsg);
            return;
          }

          // If we do have the missing CLU data, then just add it to tempClusSeen
          for (const missingInfo of missingCLUInfo) {
            tempClusSeen[missingInfo.clu_identifier].cluShape = missingInfo.cluShape;
          }
        }
        
        // Store seenCLUs info
        setClusSeen(tempClusSeen);
        // console.log('tempClusSeen :>> ', tempClusSeen);
      }

      // ----- GET Field Data -----
      // Get all the fields' shape information to validate their location in the US and get their state and county
      const fieldsInfo = await getFieldsInfo(arRequestBody);
      if (!fieldsInfo || typeof fieldsInfo === 'string') {
        const errorMsg = 'An issue occurred while loading your farms\' and fields\' information. Please try again later.';
        handleIntialLoadError(errorMsg);
        return;
      }
      // Reorganize this into an object as will need to iterate through it multiple times if not in object
      const fieldsInfoObject = {};
      for (const fieldObject of fieldsInfo) {
        const id = fieldObject.fieldID;
        // Initially thought that this should really not be needed but doesn't hurt either. However,
        // There was a user who had the same field as part of different farms, so this issue did occur. We decided it didn't matter as this is only used to get state and county codes, we do not need to notify the user either, and will just not overwrite fieldsInfoObject entry with basically a copy of the entry.
        if (fieldsInfoObject.hasOwnProperty(id)) {
          // consoleImportant('Two Fields have the same ID. This should never happen. Abort.');
          // const errorMsg = 'An issue occurred while loading your farms\' and fields\' information. Please contact support with your list of farms and fields.';
          // handleIntialLoadError(errorMsg);
          // return;
          continue;
        }

        fieldsInfoObject[id] = fieldObject;
      }

      // Will later use state variables as storage for these so that we can keep track of and reuse any previous values as well
      // Does bring up the concern of having unnecessary data stored (unused state/crop/etc.) but prob still better than
      // having so many duplicates
      const fieldsSeen = {}; // store field location information - only used in initialLoad
      const tempStatesSeen = {}; // store cvt crops for state
      const seedingsSeen = {}; // store cvt mappings for seeding's crop - only used in initialLoad
      const tempCommoditiesSeen = {}; // store cvt commodity types for cvt crop
      const tempCommodityTypesSeen = {}; // store cvt intended uses for crop and type

      // ----- GET Crop Data -----
      // Get all the CVT crop options
      const cvtCrops = await getCVTCrops();

      const stateAbbr = 'allStates';
      // Error handling - if empty result or string saying something's invalid
      if (!cvtCrops.length || typeof cvtCrops === 'string') {
        consoleImportant('An error occurred while loading crop information from CVT. Check servers.');
        const errorMsg = 'An error occurred while loading crop information. Please try again later.';
        handleIntialLoadError(errorMsg);
        return;
      }

      // Add a default value for when no match was found
      const noMatchCode = 'Found';
      const noMatchName = 'No Match';
      const noMatchDisplay = 'No Match Found';
      cvtCrops.push({ commodityCode: noMatchCode, commodityName: noMatchName });
      // Store these mappings for the 'No Match Found' commodity so they are available even if all commodities originally have a match
      tempCommoditiesSeen[noMatchCode] = [{ commodityTypeCode: noMatchCode, commodityTypeName: noMatchName, displayValue: noMatchDisplay }];
      tempCommodityTypesSeen[`${noMatchCode}${noMatchCode}`] = [{ intendedUseCode: noMatchCode, intendedUseName: noMatchName, displayValue: noMatchDisplay }];

      addDisplayCodes(cvtCrops, 'crop');

      // Log the results - tempStatesSeen is a remnant from old method when state mattered. Left in code as no need to remove really.
      tempStatesSeen[stateAbbr] = cvtCrops;

      // ----- At this point, we have all the data we need to properly display some operation data for the user. So no more handleIntialLoadError calls or returns until the end -----

      // --------------------------------------------------------------
      // ----- GET the rest of the information for the operations -----
      // We need to do this for each operation's zone
      for (const [index, op] of seedings.entries()) {
        // console.log('\n\n\nLooking at index: '+index)

        // NOTE: Make sure this shows up properly if length is pretty long (ellipsis?)
        // Do this first as it does not depend on any of the other calls, but needs to be set whether or not any of them fail
        op.varietyList = op.varietyNames.toString().replaceAll(',', ', ');
        op.title = `${op.year} ${op.name} Planting${op.varietyList.length > 0 ? ` - ${op.varietyList}` : ''}`;

        // Ignore items relating to CLUs for GART
        if (!gartPath) {
          // There's no reason to do any of this if operation does not have an associated boundary -> so no CLU intersections to show
          // NOTE: Can probably just fix this from here if we want to. Could probably call endpoint to create boundary for this one?...
          //        Easier for us (but harder for the user): they can go to ProfitLayers and just save their operation (should create boundary)
          if (!cluResponseObject.hasOwnProperty(op.operationID)) {
            if (includeNonCLUBoundaries) {
              op.error = 'It seems your planting did not have any associated boundary. Please try updating your planting in ProfitLayers.';
            } else {
              op.error = 'It seems your planting did not have any associated boundary or no intersecting CLUs were found. Please try updating your planting in ProfitLayers or uploading CLUs for this area.';
            }
            continue;
          }
          // If user has saved operation but CLU data is not available, then they likely are using wrong CLU sharing status ('' or 'EmailGoesHere') 
          else if (op.savedAndLoaded && !tempClusSeen[op.CLUInfo[0].clu_identifier].cluShape) {
            op.error = 'It seems this planting was previously saved with CLU data that you do not have access to with your current selections. Please reset the page from the settings and try changing your "CLU Data" settings.';
            // or Resetting your data.'; // Resetting data involves quite a lot of changes and it's not clearly needed so not allowed
            continue;
          }
        }

        // ----- GET Field Location Data -----
        // If this is loaded data
        if (op.savedAndLoaded && !fieldsSeen.hasOwnProperty(op.fieldID)) {
          fieldsSeen[op.fieldID] = {
            // latitude: centerPoints[1],
            // longitude: centerPoints[0],
            // state: locationInfo.StateName,
            stateCode: op.stateCode,
            // stateAbbr: locationInfo.ST_ABBR,
            // county: locationInfo.CountyName,
            countyCode: op.countyCode,
          };
        }
        // Get field' state and county information, if not already done
        // We only need to do this once per field.
        else if (!fieldsSeen.hasOwnProperty(op.fieldID)) {
          const fieldData = fieldsInfoObject[op.fieldID];
          const geojson = L.GeoJSON.geometryToLayer(wkt.parse(fieldData.shape)).toGeoJSON();

          const featureCollection = {
            type: 'FeatureCollection',
            features: [geojson],
          };

          const centroid = turf.centroid(featureCollection);
          const centerPoints = centroid.geometry.coordinates;

          // For each field, we make sure if it's in the US and also store that with the rmaCrops info.
          const locationInfo = await getStateAndCounty(centerPoints[1], centerPoints[0], true);

          // NOTE: Honestly, all these calls should have a check and repeat if failed.
          if (!locationInfo) {
            // NOTE: If undefined was returned, it's probably best to retry call before saying this
            enqueueSnackbar('An error occurred. You might have been logged out. Please refresh the page and try again.');
            op.error = 'An error occurred. You might have been logged out. Please refresh the page and try again.';
            continue;
          }

          // Error handling - if string saying something's invalid
          if (typeof locationInfo === 'string') {
            op.error = 'An error occurred. Sorry for the inconvenience. Please refresh the page and try again.';
            continue;
          }

          if (!_.isEmpty(locationInfo)) {
            // Store all this data in case we encounter field again
            fieldsSeen[op.fieldID] = {
              latitude: centerPoints[1],
              longitude: centerPoints[0],
              // state: locationInfo.StateName,
              stateCode: locationInfo.STATEFP,
              stateAbbr: locationInfo.ST_ABBR,
              // county: locationInfo.CountyName,
              countyCode: locationInfo.COUNTYFP,
            };
          } else {
            op.error = 'Acreage Reporting is only available for fields in the U.S..';
            continue;
          }
        } else if (fieldsSeen[op.fieldID].hasOwnProperty('error')) {
          // NOTE: Might want to have something outside the statistics table that will alert user that there are issues with some operations... (Might tell them the # of issues and where in the table?)

          op.error = 'Acreage Reporting is only available for fields in the U.S..';
          continue;
        }
        // We currently only use countyCode and stateCode from this
        const fieldLocationInfo = fieldsSeen[op.fieldID];

        // ----- GET Matching CVT Crop -----
        let cropMatch;
        // If this is loaded data
        if (op.savedAndLoaded) {
          // User might have changed from original mapping so we want to always use user-selected options
          cropMatch = { commodityCode: op.crop, commodityTypeCode: op.cropType, intendedUseCode: op.intendedUse };
          // console.log('cropMatch :>> ', cropMatch);
          // This mapping could affect other "new" operation mappings, could be better UX-wise or worse...
          // (depends on whether user might want to have this new mappings for all ops or just 1,2,...)
          if (!seedingsSeen.hasOwnProperty(op.name)) {
            seedingsSeen[op.name] = cropMatch;
          }
        } else if (!seedingsSeen.hasOwnProperty(op.name)) {
          // Call endpoint to get mapping for seeding operation crop to CVT crop conversions
          // (This is needed because initial crop name might not be the same as its equivalent in CVT)
          // Use SeedingCropsToCVTCrops for matchings. Then get commodityType and intendedUse from that.
          cropMatch = await getCVTMapping({ seedingsCropName: op.name });

          if (!cropMatch) {
            // NOTE: If undefined was returned, it's probably best to retry call before saying this
            enqueueSnackbar('An error occurred. You might have been logged out. Please refresh the page and try again.');
            op.error = 'An error occurred. You might have been logged out. Please refresh the page and try again.';
            continue;
          }

          // Error handling - if string saying something's invalid
          if (typeof cropMatch === 'string') {
            op.error = 'An error occurred. Sorry for the inconvenience. Please refresh the page and try again.';
            continue;
          }

          // Get the commodityCode for the current operation's crop
          // No match found - if empty result or maps to null
          if (!cropMatch.length || cropMatch[0].commodityCode === null) {
            // Need to handle this properly. Should let user close this and select whatever crop they want
            enqueueSnackbar(`No associated crop code was found for crop: ${op.name}, opID: ${op.operationID}`);
            // Set error value so we can still display this to user in statistics table.
            op.error = ['noMatch', 'The attempt at mapping this operation\'s crop to an equivalent one insured by RMA failed.'];

            // Error above is closable; Set cropMatch to default one
            // In this case, crop Type and untended Use dropdowns will only have a 'No Match Found' option whenever Crop says it too
            cropMatch = { commodityCode: noMatchCode, commodityTypeCode: noMatchCode, intendedUseCode: noMatchCode };
            // With current logic, changing the crop at the CLU level will overwrite the 'doNotInclude' key at the operation level as well.
            // So, for CART, the CLU is what is looked at to decide what is used for saving, creating report, etc..
            op.doNotInclude = noMatchDisplay;
          } else {
            // There should only be one result
            cropMatch = cropMatch[0];
          }

          // Hopefull not, but later might need to add padding to the codes for these as I do for displayValue (if they're not N/A)
          // Would need to do if there's an error in [SeedingCropsToCVTCrops] table and cvt codes aren't padded with 0's
          // cropMatch.map // entry.commodityCode.toString().padStart(4, '0')
          // Store these in seedingsSeen when gotten so I don't make queries I don't need to
          seedingsSeen[op.name] = cropMatch;
        } else {
          // We need to check this to make sure CVT mapping was not undefined
          if (!seedingsSeen[op.name]) {
            op.error = 'The attempt at mapping this operation\'s crop to an equivalent one insured by RMA failed.';
            continue;
          }

          // Either ways, get the match
          cropMatch = seedingsSeen[op.name];
        }
        const { commodityCode } = cropMatch;

        // ----- GET Crop Type and IntendedUse Data -----
        let command;
        // Get commodity type information, if not already done
        command = await handleCommodityTypes(commodityCode, op, 'operation', tempCommoditiesSeen);
        if (command === 'continue') { continue; }

        // Use the result from earlier mapping as a default commodityType value
        const { commodityTypeCode } = cropMatch;

        // Get intended use information, if not already done
        const commCodeAndCommTypeCode = `${commodityCode}${commodityTypeCode}`;
        command = await handleIntendedUses(commCodeAndCommTypeCode, commodityCode, commodityTypeCode, op, 'operation', tempCommodityTypesSeen);
        if (command === 'continue') { continue; }

        // If this is saved and loaded data, the CLUs could have different crops and crop Types than the operation.
        // So we need to make sure to get those too
        // Ignore items relating to CLUs for GART
        if (!gartPath && op.savedAndLoaded) {
          for (const CLU of op.CLUInfo) {
            // We can just get the data below directly so no need for cropMatch creation out here
            // And there's also no need to store mapping in seedingsSeen as the mapping is only used in initialLoad for matching operation table cropNames to CVT defaults. CLU has no cropName.

            // Get the matching crop types and intended uses
            const commodityCode = CLU.crop;
            command = await handleCommodityTypes(commodityCode, CLU, 'CLU', tempCommoditiesSeen);
            if (command === 'continue') { continue; }
            const commodityTypeCode = CLU.cropType;
            const commCodeAndCommTypeCode = CLU.intendedUses;
            await handleIntendedUses(commCodeAndCommTypeCode, commodityCode, commodityTypeCode, CLU, 'CLU', tempCommodityTypesSeen);
            // if (command === 'continue') { continue }; // here does not matter as it will be done anyways
          }
        }

        // ----- STORE Found Operation Data -----
        // Add values to seedings' operation as necessary
        op.crop = commodityCode;
        op.crops = stateAbbr;
        op.cropType = commodityTypeCode;
        op.cropTypes = commodityCode;
        op.intendedUse = cropMatch.intendedUseCode;
        op.intendedUses = commCodeAndCommTypeCode;

        // These are all already present in loaded data
        if (!op.savedAndLoaded) {
          // Ignore items relating to CLUs for GART
          if (!gartPath) {
            // Insert cropType, intendedUse, and other info directly in operation's CLU zones
            // BEWARE: If something in thingsToMap is changed here, it should probably also be changed in updateIntersections
            const thingsToMap = {
              crop: commodityCode,
              croppingPracticeCode: 997,
              cropType: commodityTypeCode,
              cropTypes: commodityCode,
              geospatialShapeProcessDate: new Date().toISOString(),
              includeGeospatialInReport: yesCode,
              intendedUse: cropMatch.intendedUseCode,
              intendedUses: commCodeAndCommTypeCode,
              irrigationPracticeCode: op.irrigationPracticeCode,
              micsCode: op.micsCode,
              micsName: op.micsName,
              organicPracticeTypeCode: op.organicPracticeTypeCode,
              sharePercentage: 100,
              shown: op.shown,
            };

            // Get final version of CLU data
            const newCluResponses = [];
            const zoneIDsFromDB = [];
            let operationAcres = 0;

            for (const clu of cluResponseObject[op.operationID]) {
              let finalThingsToMap = {...thingsToMap};
              // Do not automatically add reportedAcreageModifiedIndicator anymore in case clu/nonCLU boundaries were merged
              // And make sure to not overwrite pre-merge acreage in this case
              if (!clu.reportedAcreageModifiedIndicator) {
                finalThingsToMap['reportedAcreageModifiedIndicator'] = noCode;
              } else {
                finalThingsToMap['acres'] = clu.acres;
              }

              newCluResponses.push(addCLUDefaults(clu, finalThingsToMap));
              // Keep track of any newly created zoneIDs in zoneIDsFromDB
              zoneIDsFromDB.push(clu.zoneID);
              operationAcres += clu.finalReportedAcreage;
            }

            // Need to store all info in one place (seedings/mappingObject for now, then transfer to farm/field/operation orderedArray).
            // Set some default values for operation
            op.CLUInfo = newCluResponses;
            op.cluProducerReviewRequestIndicator = noCode;
            op.zoneIDsFromDB = zoneIDsFromDB;
            op.acres = operationAcres;
          } else {
            // If new GART entries - instead of CLU, operation info needs zoneID
            const boundary = await getOpBoundary(op);
            const newZoneID = await getNewZoneID();

            // Set some default values for operation
            // These additions all rely on having op's boundary.
            op.operationBoundary = boundary; // this will just replace clu's boundary var
            op.originalBoundary = boundary;
            const reprojObj = reprojectCalcArea(boundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
            op.acres = reprojObj.area;
            op.finalReportedAcreage = reprojObj.area;;
            op.projCode = reprojObj.epsgCode;

            op.geospatialShapeProcessDate = op.created_at;
            op.reportedAcreageModifiedIndicator = noCode;
            op.zoneID = newZoneID.result;
          }

          // Set some more default values for operation
          op.sharePercentage = 100;
          op.croppingPracticeCode = 997;
          op.includeGeospatialInReport = yesCode;
          // Everything should just be hidden by default except the very first operation (was for table layout)
          op.expanded = false;
          op.countyCode = fieldLocationInfo.countyCode;
          op.stateCode = fieldLocationInfo.stateCode;
        }
      }
      // ----- END Operation Loop -----
      // Reset error var at this point as we successfully got data - done here and not in resetState to avoid unnecessary flickering if an error occurs again
      setError([]);

      // ----- BREAK Up MultiPolygons -----
      // Everything to include for the new entries should be the same, except zoneID, 
      // boundary/operationBoundary, acres, finalReportedAcreage, and displayNumber/name.
      // CART will store new data in operations' CLUInfos and both in mappingObject as well
      let mappingObject = {}
      await Promise.all(seedings.map(async (op) => {
        const IDS = [op.farmID, op.fieldID, op.operationID];
        
        // Just add operations that had errors then skip - return is equivalent to a for loop continue in this case
        if (hasSkipMarker(op, 'operation')) {
          addToMappingObject(mappingObject, ...IDS, op);
          return;
        }

        // CART: Break up CLUs and give them different display_number
        if (!gartPath) {
          const newPolygons = [];
          const zoneIDsFromDB = []

          // New entries should just modify CLUInfo
          await Promise.all(op.CLUInfo.map(async (CLU) => {
            const cluB = CLU.boundary;
            const boundary = typeof cluB === 'string' ? JSON.parse(cluB) : cluB;

            // If this CLU's boundary is a MultiPolygon, construct the new CLUs from it
            if (boundary.type === 'MultiPolygon') {
              await Promise.all(boundary.coordinates.map(async (polyCoords, polyIndex) => {
                const newPoly = {...CLU};
                const newBoundary = {type: 'Polygon', coordinates: polyCoords};
                // Get the necessary info for this new entry
                const reprojObj = reprojectCalcArea(newBoundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');

                // Ignore this area if it is too small
                if (reprojObj.area >= 0.01) {
                  // We want to assign a new ZoneID to all these
                  const newZoneID = await getNewZoneID();

                  // Assign values to variables that need to change
                  newPoly.acres = reprojObj.area; // Keeping original acres wouldn't make too much sense here
                  newPoly.boundary = JSON.stringify(newBoundary);
                  newPoly.originalBoundary = newPoly.boundary; // Keeping original boundary could as long as user doesn't revert...
                  newPoly.displayNumber = `${CLU.displayNumber}_${polyIndex}`;
                  newPoly.finalReportedAcreage = reprojObj.area;
                  newPoly.zoneID = newZoneID.result;

                  // Mark this as modified and add default change reasons, if ones do not exist already
                  newPoly.reportedAcreageModifiedIndicator = yesCode;
                  if (!newPoly.reportedAcreageModifiedReasonCode) {
                    newPoly.reportedAcreageModifiedReasonCode = 'O';
                    newPoly.reportedAcreageModifiedOtherReasonText = 'Breaking up MultiPolygon to Polygon';
                  }
                  newPoly.geospatialShapeModifiedIndicator = yesCode;
                  if (!newPoly.geospatialShapeModifiedReasonCode) {
                    newPoly.geospatialShapeModifiedReasonCode = 'O';
                    newPoly.geospatialShapeModifiedOtherReasonText = 'Breaking up MultiPolygon to Polygon';
                  }

                  // Keep track of any newly created zoneIDs in zoneIDsFromDB
                  zoneIDsFromDB.push(newPoly.zoneID);
                  // Store the new CLU entry
                  newPolygons.push(newPoly);
                }
              }));
            }
            else {
              // Store ones that do not need to be modified as well (easier than trying to delete ones that are being broken up)
              newPolygons.push(CLU);
            }
          }));

          // Replace operation's CLUInfo with new entries AND add new zoneIDs to zoneIDsFromDB
          op.CLUInfo = newPolygons;
          op.zoneIDsFromDB.push(...zoneIDsFromDB);

          // Need to build a mappingObject for 'CART' - add operations to it
          addToMappingObject(mappingObject, ...IDS, op);
        }
        // GART: Break up operations and add "1 -", "2 -", ... before the name
        else {
          const opB = op.operationBoundary;
          const boundary = typeof opB === 'string' ? JSON.parse(opB) : opB;

          // If this operation's boundary is a MultiPolygon, construct new operations from it
          if (boundary.type === 'MultiPolygon') {
            await Promise.all(boundary.coordinates.map(async (polyCoords, polyIndex) => {
              const newPoly = {...op};
              const newBoundary = {type: 'Polygon', coordinates: polyCoords};
              // Get the necessary info for this new entry
              const reprojObj = reprojectCalcArea(newBoundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');

              // Ignore this area if it is too small
              if (reprojObj.area >= 0.01) {
                // We want to assign a new ZoneID to all these
                const newZoneID = await getNewZoneID();

                // Assign values to variables that need to change
                newPoly.acres = reprojObj.area;
                newPoly.operationBoundary = JSON.stringify(newBoundary);
                newPoly.name = `${polyIndex+1} - ${op.name}`;
                newPoly.finalReportedAcreage = reprojObj.area;
                // Keep original zoneID for deletion - this should be the only place actually adding a zoneIDToDelete for op
                newPoly.zoneIDToDelete = op.zoneID;
                newPoly.zoneID = newZoneID.result;

                // Mark this as modified and add default change reasons, if ones do not exist already
                newPoly.reportedAcreageModifiedIndicator = yesCode;
                if (!newPoly.reportedAcreageModifiedReasonCode) {
                  newPoly.reportedAcreageModifiedReasonCode = 'O';
                  newPoly.reportedAcreageModifiedOtherReasonText = 'Breaking up MultiPolygon to Polygon';
                }
                newPoly.geospatialShapeModifiedIndicator = yesCode;
                if (!newPoly.geospatialShapeModifiedReasonCode) {
                  newPoly.geospatialShapeModifiedReasonCode = 'O';
                  newPoly.geospatialShapeModifiedOtherReasonText = 'Breaking up MultiPolygon to Polygon';
                }

                // Need to build a mappingObject for 'GART' - add new operations to it
                addToMappingObject(mappingObject, ...IDS, newPoly);
              }
            }));
          }
          else {
            // Store ones that do not need to be modified as well
            addToMappingObject(mappingObject, ...IDS, op);
          }
        }
      }));

      // IMPORTANT TO KNOW
      // var: seedings (unorganized operation array for easy iteration) and var: mappingObject (object organized by keys for easy grouping) are very related as they essentially store the same data but organized differently.
      // seedings is not used after this point
      // mappingObject's whole purpose is for the next section
      // Their resulting data is used to create orderedMappings

      // ----- REFORMAT All Found Data -----
      // Should only have to order farms and fields (alphabetically), operations (chronologically, by date), and CLUs (by acres)
      /**
       * eg.
       * [
       *    [farm1,
       *        [field1-1,
       *            [operation1-1-1,
       *              operationInfo
       *            ],
       *            [operation1-1-2,
       *              operationInfo
       *            ],
       *            ...
       *        ],
       *        [field1-2,
       *            ...
       *        ]
       *    ],
       *    [farm2,
       *        ...
       *    ]
       * ]
       */
      // Want this to be an array so we can store the order we want
      // Doesn't really make sense to sort before here as info is stored in unordered object (mappingObject) before now
      const orderedArray = [];
      for (const farmID in mappingObject) {
        const farmArray = [];
        // Handle all the farms' fields
        for (const fieldID in mappingObject[farmID]) {
          const fieldArray = [];

          // Handle all the fields' operations
          for (const operationID in mappingObject[farmID][fieldID]) {
            const opInfo = mappingObject[farmID][fieldID][operationID];

            opInfo.forEach((operation) => {
              // If appropriate, sort the CLU data by acres.
              // Skip sorting the operations that had errors
              // Needs to be done after breaking up MultiPolygons and in case some data comes back not sorted from DB
              if (!gartPath && !hasSkipMarker(operation, 'operation')) {
                operation.CLUInfo.sort((a, b) => b.finalReportedAcreage - a.finalReportedAcreage);
              }

              fieldArray.push([
                operationID,
                operation,
              ]);
            });
          }

          // We want to sort by date here as sorting in seedings wouldn't affect mappingObject and would sort more than necessary
          fieldArray.sort((a, b) => new Date(b[1].finalPlantedDate) - new Date(a[1].finalPlantedDate));
          farmArray.push([fieldMaps[fieldID].name, fieldArray]);
        }
        // Sort by field name
        farmArray.sort((a, b) => a[0].localeCompare(b[0]));
        orderedArray.push([farmMaps[farmID], farmArray]);
      }
      // Sort by farm name
      orderedArray.sort((a, b) => a[0].localeCompare(b[0]));
      // --- Done creating orderedArray

      // ----- STORE All Found Data -----
      // Make sure the first operation is expanded
      const firstOperation = orderedArray[0][1][0][1][0][1];
      firstOperation.expanded = true;
      setCurrentExpanded([0, 0, 0]);

      // Updates relating to first operation
      setOperationToEdit({
        ...firstOperation, farmIndex: 0, fieldIndex: 0, opIndex: 0,
      });
      setNewOperationDefaults(firstOperation);
      updateLastSaved(0, 0, orderedArray);

      // Build index tracking obj
      const indxs = {
        farm: 0,
        field: 0,
      };
      setSelectedIndices(indxs);

      // We want to store the found crops, crop Types, and intended Uses for later use to limit repetition
      setStatesSeen(tempStatesSeen);
      setCommoditiesSeen(tempCommoditiesSeen);
      setCommodityTypesSeen(tempCommodityTypesSeen);

      // Get profit maps for operations on first field
      updateOperationsMaps(orderedArray, indxs);
      // Set the final table values for use in statistics table
      setOrderedMappings(orderedArray);
      setShowFieldSelection(false);

      // View final states
      if (debuggingOutputs) {
        console.log('mappingObject :>> ', mappingObject);
        console.log('orderedArray :>> ', orderedArray);
      }
    }
    // General error handling
    catch (err) {
      // console.log('An error occurred while loading acreage information. Be aware that this may not always tell you where this error actually happened: ', err);
      setError([true, 'An error occurred while loading acreage information. Please try again later.']);
      setShowFieldSelection(true);
    } finally {
      setLoading(false);
      setInitialLoadComplete(true);
    }
  };

  //#region - post-initialLoad helpers
  /**
   * Combine operation varieties information by seeing which ones have matching IDs.
   * We want to store farm and field information as well.
   * So we store operation data in an object with farm key and value being object with field key with value being object with operation key and this operation object created here as its value. This will make it easy and quick to find items for displaying in table.
   * And I can still use operations array to iterate over them all in initialLoad without changing much of the logic.
   * @param  {Array} ops List of operation objects
   * @return {Array} List of combined operations
   */
  const combineOps = (ops) => {
    const operations = [];

    for (const op of ops) {
      const match = operations.filter((x) => x.operationID === op.seedingOperationID);

      // Group up varieties by operationID
      // When getting NEW data, we need to always group up different entries (even for 'GART')
      if (match.length > 0) {
        const index = operations.indexOf(match[0]);
        const matchedOperation = operations[index];

        matchedOperation.acres += op.area;
        matchedOperation.varietyNames.push(op.name);
        if (op.isDeleted) { matchedOperation.shown = false; }
      } else {
        // Create and store needed operation data
        // Could always add field and farm info here if we need to retrieve them (bottom-up) for some reason
        const foundDate = op.endDate !== undefined ? parseDate(op.endDate, true) : undefined;
        const source = op.source.toLowerCase();
        const micsCodeMapping = precisionSources[source];
        // 'O' ('Other')
        const micsCode = micsCodeMapping || 'O';
        const micsName = micsCodeMapping ? 'Enter MICS Name' : source.charAt(0).toUpperCase() + source.slice(1);

        // NOTE: If for some reason we want a field to be duplicated if it appears for multiple farms, then we would need to modify fieldMaps so it stores all field->farm mappings and over here, iterate over all those farms. (could create completely separated operations or have them connected so changes in one appear in the other)
        const farmID = fieldMaps[op.fieldID].farm.id;
        // NOTE: Use of below currently treats operations from 'climate' and 'AgIntegrated' sources as equivalent to 'Ag-Analytics' source
        const operation = {
          acres: op.area,
          created_at: op.created_at,
          date: foundDate,
          farmID,
          fieldID: op.fieldID,
          finalPlantedDate: foundDate,
          // This should probably be 'G' ('GIS Algorithm') if not from 'Ag-Analytics' and 'D' ('Digitized') if from
          geospatialDataSubmissionMethodCode: micsCodeMapping ? 'G' : 'D',
          // 'I' ('Irrigated')
          irrigationPracticeCode: op.isIrrigated ? 'I' : noCode,
          micsCode,
          micsName,
          name: op.cropName,
          operationID: op.seedingOperationID,
          // 'OC' ('Organic (USDA Certified))
          organicPracticeTypeCode: op.isOrganic ? 'OC' : noCode,
          // NOTE: This will have to change once multi org select is implemented
          orgID: chosenOrg.id,
          // This is currently the same as EndDate.
          plantingDate: op.endDate,
          // This will be be 'Y' ('Yes') if not from 'Ag-Analytics' and 'N' ('No') if from
          // Currently, it will be 'Y' only if from John Deere or CNH (not climate or AgIntegrated)
          precisionAgriculturalSubMeterAccuracyIndicator: micsCodeMapping ? yesCode : noCode,
          operation_updated_at: op.updated_at,
          shown: !op.isDeleted,
          varietyNames: [op.name],
          year: op.cropSeason, // used for title
          EndDate: op.endDate,
          StartDate: op.startDate,
          source: op.source
        };

        operations.push(operation);
      }
    }

    return operations;
  };

  /**
   * Given an array of crop/type/practice/etc. with associated name and codes,
   * create a third entry for each with the name and code (padded as necessary) but in one string
   * @param {Array} target array to use with names and codes
   * @param {String} type passed type
   */
   const addDisplayCodes = (target, type) => {
    for (const entry of target) {
      if (type === 'crop') {
        entry.displayValue = `${entry.commodityName} ${entry.commodityCode}`;
      } else if (type === 'cropType') {
        let chosenDisplay = `${entry.commodityTypeName} ${entry.commodityTypeCode}`;
        // If it says 'N/A', don't repeat that twice
        if (entry.commodityTypeCode === 'N/A') chosenDisplay = `${entry.commodityTypeCode}`;

        entry.displayValue = chosenDisplay;
      } else if (type === 'intendedUse') {
        let chosenDisplay = `${entry.intendedUseName} ${entry.intendedUseCode}`;
        // If it says null, replace that with 'N/A' instead - this should not normally happen... Seems like an issue with data from RMA
        if (entry.intendedUseCode === null) {
          entry.intendedUseName = 'N/A';
          entry.intendedUseCode = 'N/A';
        }
        // If it says 'N/A', don't repeat that twice
        if (entry.intendedUseCode === 'N/A') chosenDisplay = `${entry.intendedUseCode}`;

        entry.displayValue = chosenDisplay;
      }

      // RMA method - keep (commented out) here for now
      // if (type === 'crop') {
      //   entry.displayValue = `${entry.commodityName} ${entry.commodityCode.toString().padStart(4, '0')}`;
      // } else if (type === 'type') {
      //   entry.displayValue = `${entry.typeName} ${entry.typeCode.toString().padStart(3, '0')}`;
      // } else if (type === 'practice') {
      //   entry.displayValue = `${entry.practiceName} ${entry.practiceCode.toString().padStart(3, '0')}`;
      // }
    }
  };

  // Based on whether or not data has been loaded and whether or not changes have been made to the data,
  // do the appropriate thing when user clicks "Load Acreage Data"
  const handleLoadingData = () => {
    if (successfullyLoadedData && changesMade) {
      // Prompt user so they know their data will be overwritten
      setOpenConfirm(true);
      // In this case, calling of matchFieldSelectionInputs will be handled by ConfirmationPopup
    } else {
      matchFieldSelectionInputs();
    }
  }

  /**
   * Sets the variables to be used by initialLoad and rest of the tool from the current selection from FieldSelection
   * Then, causes initialLoad to start.
   */
  const matchFieldSelectionInputs = () => {
    // Match data
    setChosenFields(fs_chosenFields);
    setChosenOrg(fs_chosenOrg);
    setFarmMaps(fs_farmMaps);
    setFieldMaps(fs_fieldMaps);
    setSelectedFields(fs_selectedFields);
    // To get initialLoad to run again even if selectedFields hasn't changed
    setReloadData(true);

    // Vars to keep track of whether data for fields and year in Field Selection has been loaded yet
    setSelectedFieldsAreLoaded(true);
    yearDataLoaded.current = commodityYear;
  };

  //#endregion

  //#region - data update handlers
  const maxAcreage = 999999.99;
  const specialInputs = new Set(['cropRowCount', 'cropRowWidth', 'skipRowCount', 'skipRowWidth', 'skipRowConversionFactor']);

  // Check for valid inputs
  const checkValidInput = (modify, val, disableElements = undefined) => {
    // console.log('modify, val :>> ', modify, val);
    // Share %
    if (modify === 'sharePercentage') {
      if (val > 100) {
        enqueueSnackbar('You cannot enter a share percentage greater than 100%');
        return false;
      } if (val === 0) {
        enqueueSnackbar('0% is not a valid share percentage');
        return false;
      }
    }
    // Acreage values
    else if (modify === 'finalReportedAcreage') {
      if (val === 0) {
        enqueueSnackbar('Acreage must be greater than 0');
        return false;
      }
      if (val > maxAcreage) {
        enqueueSnackbar(`An acreage greater than ${maxAcreage} is not valid`);
        return false;
      }
    }
    // Date values
    else if (modify === 'finalPlantedDate') {
      // This comment might change based on further modifications to date component
      // Empty strings are stopped at the SOURCE now. - ie. do NOT rely on this
      // But will leave this here to let someone know in case they forget to add it
      if (!val) {
        consoleImportant('In AcreageReporting.js, see checkValidInput for issue when validating finalPlantedDate.');
        enqueueSnackbar('Please enter a valid date');
        return false;
      }

      const enteredDate = new Date(val);
      // Make sure date is valid
      if (isNaN(enteredDate)) {
        enqueueSnackbar('Please enter a valid date');
        return false;
      }

      // Make sure date is below maxDate
      const today = new Date();
      const tomorrow = new Date(today);
      tomorrow.setDate(tomorrow.getDate() + 1);
      // console.log('enteredDate > today :>> ', enteredDate > today);
      if (enteredDate > today) {
        enqueueSnackbar(`Please enter a date before ${tomorrow.toLocaleDateString()}`);
        return false;
      }

      // Make sure date is above minDate
      const minDate = new Date('1800-01-01');
      // console.log('enteredDate < minDate :>> ', enteredDate < minDate);
      if (enteredDate < minDate) {
        enqueueSnackbar(`Please enter a date after ${minDate.toLocaleDateString()}`);
        return false;
      }
    }
    // These ones from presetData.js must be handled differently
    else if (specialInputs.has(modify)) {
      let min;
      let max;
      const relevantInput = additionalTextInputs[modify];
      // passed disableElements will only not be undefined when widths are being modified
      if (disableElements === 'butWidth') {
        min = relevantInput.restrictedValues.min;
        max = relevantInput.restrictedValues.max;
      } else {
        min = relevantInput.allowedValues.min;
        max = relevantInput.allowedValues.max;
      }

      if (val === '') {
        // empty string should not cause invalid message
        return true;
      }
      if (val >= min && val <= max) {
        // Valid input will be between or equal to min and max
        return true;
      }
      enqueueSnackbar(`Please enter a value between ${min} and ${max}`);
      return false;
    }

    return true;
  };

  // Takes the appropriate steps for handling modifications to finalReportedAcreage
  const handleFinalReportedAcreage = (toUpdate, value, explanationsRequired) => {
    // This happens first as we want the current reportedAcreageModifiedIndicator value
    // prevAreaInfo is only for use in ChangeExplanation - and this line below will always happen before ChangeExplanation modal opens
    // so this only needs to be modified here
    toUpdate.prevAreaInfo = [toUpdate.finalReportedAcreage, toUpdate.reportedAcreageModifiedIndicator];

    // Check that new value is not the same as current one
    if (value === Number(toUpdate.finalReportedAcreage.toFixed(2))) {}
    // Do not prompt if it is original acreage
    else if (value === Number(toUpdate.acres.toFixed(2))) {
      // This reportedAcreageModifiedIndicator is not needed as logic (comparing acres) can be handled when creating output report
      // But this could be another way to make sure we are handling everything correctly
      // However, this is def more beneficial for checking if boundary was modified as easier than comparing boundaries directly
      toUpdate.reportedAcreageModifiedIndicator = noCode;
    } else {
      toUpdate.reportedAcreageModifiedIndicator = yesCode;
      explanationsRequired.push('finalReportedAcreage');
    }
  };

  // Handles making a query to get new crop types and associated intended uses whenever crop changes.
  const handleCropUpdate = async (farmIndex, fieldIndex, opIndex, cluIndex, justUpdated, mappings) => {
    try {
      const { previousCropCode } = justUpdated;
      const newCropCode = justUpdated.crop;
      let cropTypes;
      let newCommoditiesSeen;
      // Only get commodityTypes if we don't have them already
      if (commoditiesSeen.hasOwnProperty(newCropCode)) {
        cropTypes = commoditiesSeen[newCropCode];
      } else {
        cropTypes = await getCommodityTypes(newCropCode);
        const prevCommoditiesSeen = cloneDeep(commoditiesSeen);
        newCommoditiesSeen = { ...prevCommoditiesSeen, [newCropCode]: cropTypes };
      }

      // If change is at operation level
      if (cluIndex === undefined) {
        const op = mappings[farmIndex][1][fieldIndex][1][opIndex][1];

        if (!cropTypes) {
          // Store any newly found commodityTypes
          if (!commoditiesSeen.hasOwnProperty(newCropCode)) {
            setCommoditiesSeen(newCommoditiesSeen);
          }

          // The callback below is not necessary in this case as error will prevent any display for operation/CLU
          op.error = 'There are no crop types available for insurance coverage for this operation\'s crop.';
          // Store final results
          setOrderedMappings(mappings);
        } else {
          const updates = [
            { cropType: cropTypes[0].commodityTypeCode },
            { cropTypes: newCropCode },
          ];
          // If appropriate, make sure to remove any previously added doNotInclude key
          if (newCropCode !== 'Found' && previousCropCode === 'Found') {
            updates.push({ doNotInclude: undefined });
          }

          // If there are two states we want to set, set them one after another (and use useEffect to coordinate)
          // as orderedMappings will update fields that require values from commoditiesSeen
          if (!commoditiesSeen.hasOwnProperty(newCropCode)) {
            // In total, 2 things to change for this operation and for each CLU (type, types)
            // updateOperation will already have updated crop for all, so don't need to update that
            setCommoditiesSeen(newCommoditiesSeen);
            setCommSeenCallback({
              level: 'operation',
              farmIndex,
              fieldIndex,
              opIndex,
              updates,
              mappings,
            });
          } else {
            // Handle the 'No Match Found' option differently - want to have a marker so we know not to include it
            // This will always already be in commoditiesSeen
            if (newCropCode === 'Found') {
              updates.push({ doNotInclude: 'No Match Found' });
            }

            // In total, 2 things to change for this operation and for each CLU (type, types)
            // updateOperation will already have updated crop for all, so don't need to update that
            updateOperation(
              farmIndex,
              fieldIndex,
              opIndex,
              updates,
              mappings,
            );
          }
        }
      }
      // If change is at CLU level
      else {
        const clu = mappings[farmIndex][1][fieldIndex][1][opIndex][1].CLUInfo[cluIndex];

        if (!cropTypes) {
          // Store any newly found commodityTypes
          if (!commoditiesSeen.hasOwnProperty(newCropCode)) {
            setCommoditiesSeen(newCommoditiesSeen);
          }

          clu.error = `There are no crop types available for insurance coverage for CLU ${clu.clu_number}'s crop.`;
          setOrderedMappings(mappings);
        } else {
          const updates = [
            { cropType: cropTypes[0].commodityTypeCode },
            { cropTypes: newCropCode },
          ];
          // If appropriate, make sure to remove any previously added doNotInclude key
          if (newCropCode !== 'Found' && previousCropCode === 'Found') {
            updates.push({ doNotInclude: undefined });
          }

          // If there are two states we want to set, set them one after another (and use useEffect to coordinate)
          // as orderedMappings will update fields that require values from commoditiesSeen
          if (!commoditiesSeen.hasOwnProperty(newCropCode)) {
            // 2 things to change for this CLU (type, types)
            // updateIntersection will already have updated crop for it, so don't need to update that
            setCommoditiesSeen(newCommoditiesSeen);
            setCommSeenCallback({
              level: 'clu',
              updates,
              indexes: {
                farmIndex, fieldIndex, opIndex, cluIndex,
              },
              mappings,
            });
          } else {
            // Handle the 'No Match Found' option differently - want to have a marker so we know not to include it
            if (newCropCode === 'Found') {
              updates.push({ doNotInclude: 'No Match Found' });
            }

            // 2 things to change for this CLU (type, types)
            // updateIntersection will already have updated crop for it, so don't need to update that
            updateIntersection(
              updates,
              {
                farmIndex, fieldIndex, opIndex, cluIndex,
              },
              mappings,
            );
          }
        }
      }
    } catch (e) {
      console.error(e);
      setLoading(false);
    }
  };

  // Handles making a query to get new intended uses whenever crop type changes.
  const handleCropTypeUpdate = async (farmIndex, fieldIndex, opIndex, cluIndex, cropCode, newCropTypeCode, mappings) => {
    try {
      const cropCodeAndCropTypeCode = `${cropCode}${newCropTypeCode}`;
      let intendedUses;
      let newCommodityTypesSeen;
      // Only get intendedUses if we don't have them already
      if (commodityTypesSeen.hasOwnProperty(cropCodeAndCropTypeCode)) {
        intendedUses = commodityTypesSeen[cropCodeAndCropTypeCode];
      } else {
        intendedUses = await getIntendedUses(cropCode, newCropTypeCode);
        const prevCommodityTypesSeen = cloneDeep(commodityTypesSeen);
        newCommodityTypesSeen = { ...prevCommodityTypesSeen, [cropCodeAndCropTypeCode]: intendedUses };
      }

      // If change is at operation level
      if (cluIndex === undefined) {
        const op = mappings[farmIndex][1][fieldIndex][1][opIndex][1];

        if (!intendedUses) {
          // Store any newly found intendedUses
          if (!commodityTypesSeen.hasOwnProperty(cropCodeAndCropTypeCode)) {
            setCommodityTypesSeen(newCommodityTypesSeen);
          }

          op.error = 'There are no intended uses available for insurance coverage for this operation\'s crop and chosen crop type.';
          setOrderedMappings(mappings);
        } else {
          // If there are two states we want to set, set them one after another (and use useEffect to coordinate)
          // as orderedMappings will update fields that require values from commoditiesSeen
          if (!commodityTypesSeen.hasOwnProperty(cropCodeAndCropTypeCode)) {
            setCommodityTypesSeen(newCommodityTypesSeen);
            setCommTypesSeenCallback({
              level: 'operation',
              farmIndex,
              fieldIndex,
              opIndex,
              updates: [
                { intendedUse: intendedUses[0].intendedUseCode },
                { intendedUses: cropCodeAndCropTypeCode },
              ],
              mappings,
            });
          } else {
            // In total, 2 things to change for this operation and for each CLU (use, uses)
            // updateOperation will already have updated cropType for all, so don't need to update that
            updateOperation(
              farmIndex,
              fieldIndex,
              opIndex,
              [
                { intendedUse: intendedUses[0].intendedUseCode },
                { intendedUses: cropCodeAndCropTypeCode },
              ],
              mappings,
            );
          }
        }
      }
      // If change is at CLU level
      else {
        const clu = mappings[farmIndex][1][fieldIndex][1][opIndex][1].CLUInfo[cluIndex];

        if (!intendedUses) {
          // Store any newly found intendedUses
          if (!commodityTypesSeen.hasOwnProperty(cropCodeAndCropTypeCode)) {
            setCommodityTypesSeen(newCommodityTypesSeen);
          }

          // NOTE: This shouldn't happen unless it's an error. So, might want to tell them here to save, refresh, and try again.
          clu.error = `There are no intended uses available for insurance coverage for CLU ${clu.clu_number}'s crop and chosen crop type.`;
          setOrderedMappings(mappings);
        } else {
          // If there are two states we want to set, set them one after another (and use useEffect to coordinate)
          // as orderedMappings will update fields that require values from commoditiesSeen
          if (!commodityTypesSeen.hasOwnProperty(cropCodeAndCropTypeCode)) {
            setCommodityTypesSeen(newCommodityTypesSeen);
            setCommTypesSeenCallback({
              level: 'clu',
              updates: [
                { intendedUse: intendedUses[0].intendedUseCode },
                { intendedUses: cropCodeAndCropTypeCode },
              ],
              indexes: {
                farmIndex, fieldIndex, opIndex, cluIndex,
              },
              mappings,
            });
          } else {
            // 2 things to change for this CLU (use, uses)
            // updateIntersection will already have updated cropType for it, so don't need to update that
            updateIntersection(
              [
                { intendedUse: intendedUses[0].intendedUseCode },
                { intendedUses: cropCodeAndCropTypeCode },
              ],
              {
                farmIndex, fieldIndex, opIndex, cluIndex,
              },
              mappings,
            );
          }
        }
      }
    } catch (e) {
      console.error(e);
    } finally {
      setLoading(false);
    }
  };

  // Handles prepolutating the appropriate variables whenever SkipRowPatternCode changes.
  const handleSkipRowPatternCodeUpdate = (farmIndex, fieldIndex, opIndex, cluIndex, newSkipRowPatternCode, mappings) => {
    let disableElements;

    // Decide which elements will still be editable
    // This is also done here as some of these elements can be shown in CLU while they are not shown in Advanced Options. So,
    // need to track whether they can be editted in both places.
    if (newSkipRowPatternCode === '99') {
      disableElements = false;
    } else if (newSkipRowPatternCode !== '' && !cartDropdownData.skipRowPatternCodes.specificWidths.has(newSkipRowPatternCode)) {
      disableElements = 'butWidth';
    } else {
      disableElements = true;
    }

    // Get the appropriate mappings
    const updateMappings = cartDropdownData.skipRowPatternCodes.mappings[newSkipRowPatternCode];
    let updates;
    // In this case, we only want to update disableElements
    if (newSkipRowPatternCode === '' || newSkipRowPatternCode === '99') {
      updates = [
        { disableElements },
      ];
    } else {
      updates = [
        { cropRowCount: updateMappings.cropRowCount },
        { cropRowWidth: updateMappings.cropRowWidth },
        { skipRowCount: updateMappings.skipRowCount },
        { skipRowWidth: updateMappings.skipRowWidth },
        { skipRowConversionFactor: updateMappings.skipRowConversionFactor },
        { disableElements },
      ];
    }

    // If change is at operation level
    if (cluIndex === undefined) {
      // In total, 6 (or 1) things to change for this operation and for each CLU (cropRowCount, cropRowWidth, skipRowCount, skipRowWidth, skipRowConversionFactor, & disableElements)
      // updateOperation will already have updated newSkipRowPatternCode for all, so don't need to update that
      updateOperation(
        farmIndex,
        fieldIndex,
        opIndex,
        updates,
        mappings,
      );
    }
    // If change is at CLU level
    else {
      // 6 (or 1) things to change for this CLU (cropRowCount, cropRowWidth, skipRowCount, skipRowWidth, skipRowConversionFactor, & disableElements)
      // updateIntersection will already have updated newSkipRowPatternCode for it, so don't need to update that
      updateIntersection(
        updates,
        {
          farmIndex, fieldIndex, opIndex, cluIndex,
        },
        mappings,
      );
    }
  };

  // Updates will almost always contain only one value to update. But this will contain two values when we want to modify
  // something that requires keeping track of whether it has been modified - such as acres, planting date, and shape
  // or when we update crop, that will need to update crop type and intended use
  const updateOperation = async (farmIndex, fieldIndex, opIndex, updates, passedMappings = undefined) => {
    // Updating some values requires user to provide explanation of why
    const explanationsRequired = [];
    // console.log("Update Operation", farmIndex, fieldIndex, opIndex, updates, passedMappings)

    let justUpdated = {};
    let mappings;
    // These will be passed from handleCropUpdate and handleCropTypeUpdate - they will not be from state variable so no need to clone
    if (passedMappings) {
      mappings = passedMappings;
    } else {
      mappings = cloneDeep(orderedMappings);
    }

    // Get operation to update from indexes
    const toUpdate = mappings[farmIndex][1][fieldIndex][1][opIndex][1];
    // console.log("OP TO UPDATE", JSON.parse(JSON.stringify(toUpdate)))

    updates.forEach((update, index) => {
      const values = Object.entries(update);
      // eslint-disable-next-line prefer-destructuring
      const modify = values[0][0];
      const value = values[0][1];
      // console.log("modify", modify, "value", value)

      let disableElements;
      // If we are modifying crop or cropType, keep track
      if (modify === 'crop' || modify === 'cropType') {
        setLoading(true);
        justUpdated = { ...update, previousCropCode: toUpdate.crop };
      } else if (modify === 'skipRowPatternCode') {
        justUpdated = update;
      }
      // These need to be handled differently for their input validation
      else if (modify === 'skipRowWidth' || modify === 'cropRowWidth') {
        disableElements = toUpdate.disableElements;
      }

      // Check to make sure input is valid and modify it for all CLUs
      if (checkValidInput(modify, value, disableElements)) {
        // Handle input date a little differently
        if (modify === 'finalPlantedDate') {
          // This happens first as we want the current plantedDateModifiedIndicator value
          // prevDateInfo is only for use in ChangeExplanation - and this line below will always happen before ChangeExplanation modal opens
          // so this only needs to be modified here
          toUpdate.prevDateInfo = [toUpdate.finalPlantedDate, toUpdate.plantedDateModifiedIndicator];

          // Do not prompt if it is same as current date or if it is original date
          if (new Date(value).setHours(0, 0, 0, 0) === new Date(toUpdate.finalPlantedDate).setHours(0, 0, 0, 0)) {} else if (new Date(value).setHours(0, 0, 0, 0) === new Date(toUpdate.date).setHours(0, 0, 0, 0)) {
            toUpdate.plantedDateModifiedIndicator = noCode;

            if (!gartPath) { // Ignore if zoneType is 'GART'
              toUpdate.CLUInfo.forEach((clu) => {
                clu.plantedDateModifiedIndicator = noCode;
              });
            }
          } else {
            toUpdate.plantedDateModifiedIndicator = yesCode;
            explanationsRequired.push(modify);
            // In this case, updating prevDateInfo and plantedDateModifiedIndicator for CLUs will be handled by ChangeExplanation
          }
        }
        // Handle input acreage a little differently - this only happens for 'GART' path
        else if (modify === 'finalReportedAcreage') {
          handleFinalReportedAcreage(toUpdate, value, explanationsRequired);
        } else if (modify === 'geospatialShapeModifiedIndicator') {
          explanationsRequired.push(modify);
        }

        // Handle removing 'error' or 'doNotInclude' key properly
        if ((modify === 'error' || modify === 'doNotInclude') && value === undefined) {
          delete toUpdate[modify];

          if (!gartPath) { // Ignore if zoneType is 'GART'
            toUpdate.CLUInfo.forEach((clu) => {
              delete clu[modify];
            });
          }
        } else {
          // Update value
          toUpdate[modify] = value;
          // We do not want to add acres, CLUInfo, or zoneIDsFromDB to all CLUs
          // const skip = ['acres', 'CLUInfo', 'zoneIDsFromDB'].filter(key => key === modify);
          if (modify === 'acres' || modify === 'CLUInfo' || modify === 'zoneIDsFromDB') { return; }

          if (!gartPath) { // Ignore if zoneType is 'GART'
            // Update operation's CLUs with modified value
            toUpdate.CLUInfo.forEach((clu) => {
              // This is stored in a different variable for CLUs so handle differently as well
              if (modify === 'cluProducerReviewRequestIndicator') {
                clusSeen[clu.clu_identifier][modify] = value;
              } else {
                // eslint-disable-next-line prefer-destructuring
                // should check here for things like existing zoneID? (Stephane: what does this comment mean?)
                clu[modify] = value;
              }
            });
          }
        }
      }
      // Handle if date - refresh array in SetupTables to show previous date
      else if (modify === 'finalPlantedDate') {
        setRefreshTableDates(true);
      }
    });

    // console.log("MAPPINGS", mappings)
    // Handle crop, cropType, and skipRowPatternCode updates differently
    if (!_.size(justUpdated)) {
      // Store final results
      // - if explanationsRequired includes finalPlantedDate, will save from ChangeExplanation.
      // This is all done to avoid setting state twice if not needed
      // - if geospatialShapeModifiedIndicator (from updated operation boundary), we still want to save as we need to save the
      // new CLU intersection results (because we don't save previous CLUs, etc., cancel is not an option currently)
      // - (for 'GART' path) finalReportedAcreage also needs to update orderedMappings now for update to show
      if (!_.size(explanationsRequired) || (!explanationsRequired.includes('finalPlantedDate'))) {
        setOrderedMappings(mappings);
        setChangesMade(true);
      }
      setOperationToEdit({
        ...toUpdate, farmIndex, fieldIndex, opIndex,
      });
    } else {
      // If we just modified crop
      if (Object.prototype.hasOwnProperty.call(justUpdated, 'crop')) {
        await handleCropUpdate(farmIndex, fieldIndex, opIndex, undefined, justUpdated, mappings);
      }
      // If we just modified cropType
      else if (Object.prototype.hasOwnProperty.call(justUpdated, 'cropType')) {
        await handleCropTypeUpdate(farmIndex, fieldIndex, opIndex, undefined, toUpdate.crop, justUpdated.cropType, mappings);
      }
      // If we just modified skipRowPatternCode
      else if (Object.prototype.hasOwnProperty.call(justUpdated, 'skipRowPatternCode')) {
        handleSkipRowPatternCodeUpdate(farmIndex, fieldIndex, opIndex, undefined, justUpdated.skipRowPatternCode, mappings);
      }
    }

    // This should be executed last as will change orderedMappings
    // Set some values so user can explain reason for modifications
    if (explanationsRequired.length) {
      setExplanations(explanationsRequired);
      setProvidingExplanationFor('operation');
      setOpenExplanation(true);
    }
  };

  // Changes to grey out operation level info if CLU level info changes
  // NOTE: This will currently not ungrey out operation info if user presses cancel from ChangeExplanation or changes back at CLU level
  //       Only way to do so if by changing at operation level again.
  // We will handle specific elements at a time to try to avoid causing weird issues because changes weren't fully tested
  // We will ignore 'acres'
  const elementsToGreyOut = new Set(
    ['crop', 'cropType', 'intendedUse', 'croppingPracticeCode', 'irrigationPracticeCode', 'organicPracticeTypeCode', 'sharePercentage', 'micsCode', 'micsName', 'skipRowPatternCode', 'skipRowConversionFactor', 'cropRowCount', 'cropRowWidth', 'skipRowCount', 'skipRowWidth', 'productPlantingCode', 'includeGeospatialInReport', 'cluProducerReviewRequestIndicator'],
  );
  // For displaying mismatches between CLUs and operation
  const displayMarker = '#%NULL%#'; // displayMarker CANNOT have numbers as textfields will display them
  const greyedOut = 'rgba(0, 0, 0, 0.13)';
  const white = '#ffffff';
  // Still need to test with the ones in advanced options and changes from editMap modal.

  /**
   * Updates clu info from an operations intersections. Makes a deep copy of
   * orderedMappings, then use index passed to find the CLU to update values for.
   * This type of edit will NOT update the the operation info stored in database,
   * it is purely used locally for acreage report.
   * @param {Array} updates List of key value pairs to update
   * @param {Array} indexes Indexes needed to find clu in mappings state array
   * @returns {void}
   */
  const updateIntersection = async (updates, indexes, passedMappings = undefined) => {
    try {
      // console.log("updateIntersection Indexes", indexes)
      // console.log(updates)
      let justUpdated = {};
      let mappings;
      // These will be passed from handleCropUpdate and handleCropTypeUpdate - they will not be from state so no need to clone
      if (passedMappings) {
        mappings = passedMappings;
      } else {
        // Deep clone nested map array
        mappings = cloneDeep(orderedMappings);
      }

      // Updating some values requires user to provide explanation of why
      const explanationsRequired = [];

      // Get CLU to update from indexes
      // eslint-disable-next-line max-len
      const operation = mappings[indexes.farmIndex][1][indexes.fieldIndex][1][indexes.opIndex][1];
      const toUpdate = operation.CLUInfo[indexes.cluIndex];

      updates.forEach((update) => {
        const values = Object.entries(update);
        const modify = values[0][0];
        const value = values[0][1];

        let disableElements;
        // If we are modifying crop or cropType, keep track
        if (modify === 'crop' || modify === 'cropType') {
          setLoading(true);
          justUpdated = { ...update, previousCropCode: toUpdate.crop };
        } else if (modify === 'skipRowPatternCode') {
          justUpdated = update;
        }
        // These need to be handled differently for their input validation
        else if (modify === 'skipRowWidth' || modify === 'cropRowWidth') {
          disableElements = toUpdate.disableElements;
        }

        if (checkValidInput(modify, value, disableElements)) {
          // Handle input acreage a little differently
          if (modify === 'finalReportedAcreage') {
            handleFinalReportedAcreage(toUpdate, value, explanationsRequired);
          }

          // This is stored in a different variable so handle differently as well
          if (modify === 'cluProducerReviewRequestIndicator') {
            clusSeen[toUpdate.clu_identifier][modify] = value;
          } else {
            // Handle removing 'error' or 'doNotInclude' key properly
            // (currently, 'error' can only be removed at the operation level however)
            if (modify === 'doNotInclude' && value === undefined) {
              delete toUpdate[modify];
              delete operation[modify];
            } else {
              // eslint-disable-next-line prefer-destructuring
              toUpdate[modify] = value;

              // If specific CLU level info changes, grey out operation level info
              if (elementsToGreyOut.has(modify)) {
                operation[modify] = displayMarker;
              }
            }
          }
        }
      });

      // Handle crop, cropType, and skipRowPatternCode updates differently
      if (!_.size(justUpdated)) {
        // console.log('operation :>> ', operation);
        // console.log('mappings :>> ', mappings);
        // Store final results
        setOrderedMappings(mappings);
        setChangesMade(true);

        // Store this for use in ChangeExplanation
        setOperationToEdit({
          ...operation, farmIndex: indexes.farmIndex, fieldIndex: indexes.fieldIndex, opIndex: indexes.opIndex, cluIndex: indexes.cluIndex,
        });
        setCluBoundary(toUpdate);
      } else {
        // If we just modified crop, we want to call handleCropUpdate and actually store new changes (types and uses) at CLU level
        if (Object.prototype.hasOwnProperty.call(justUpdated, 'crop')) {
          await handleCropUpdate(indexes.farmIndex, indexes.fieldIndex, indexes.opIndex, indexes.cluIndex, justUpdated, mappings);
        }
        // If we just modified cropType
        else if (Object.prototype.hasOwnProperty.call(justUpdated, 'cropType')) {
          await handleCropTypeUpdate(indexes.farmIndex, indexes.fieldIndex, indexes.opIndex, indexes.cluIndex, toUpdate.crop, justUpdated.cropType, mappings);
        }
        // If we just modified skipRowPatternCode
        else if (Object.prototype.hasOwnProperty.call(justUpdated, 'skipRowPatternCode')) {
          handleSkipRowPatternCodeUpdate(indexes.farmIndex, indexes.fieldIndex, indexes.opIndex, indexes.cluIndex, justUpdated.skipRowPatternCode, mappings);
        }
      }

      // This should be executed last as will change orderedMappings
      // Set some values so user can explain reason for modifications
      if (explanationsRequired.length) {
        setExplanations(explanationsRequired);
        setProvidingExplanationFor('clu');
        setOpenExplanation(true);
      }
    } catch (e) {
      console.error(e);
    }
  };

  // BEWARE: Do not use these basic versions of the above functions unless you have a reason to.
  //        basicUpdateOperation is currently only for use by ChangeExplanation.js
  //        and basicUpdateIntersection by ChangeExplanation.js and EditMap.js
  // Basic operation updating
  const basicUpdateOperation = (updates, indexes, cancel = false) => {
    const mappings = cloneDeep(orderedMappings);
    // Get CLU to update from indexes
    // eslint-disable-next-line max-len
    const toUpdate = mappings[indexes.farmIndex][1][indexes.fieldIndex][1][indexes.opIndex][1];

    updates.forEach((update) => {
      const values = Object.entries(update);
      const modify = values[0][0];
      const value = values[0][1];

      // Shouldn't even need to check the input at this point but this could reveal some coding errors
      if (checkValidInput(modify, value)) {
        // eslint-disable-next-line prefer-destructuring
        toUpdate[modify] = value;

        if (!gartPath) { // Ignore if zoneType is 'GART'
          // Update operation's CLUs with modified value
          toUpdate.CLUInfo.forEach((clu) => {
            // eslint-disable-next-line prefer-destructuring
            clu[modify] = value;
          });
        }
      }
    });

    setOrderedMappings(mappings);
    setChangesMade(true);
    // If this was a cancel call from ChangeExplanation, refresh date array in SetupTables
    if (cancel) {
      setRefreshTableDates(true);
    }
  };

  // Basic CLU updating
  const basicUpdateIntersection = (updates, indexes, passedMappings) => {
    const mappings = cloneDeep(passedMappings || orderedMappings);
    // Get CLU to update from indexes
    // eslint-disable-next-line max-len
    const toUpdate = mappings[indexes.farmIndex][1][indexes.fieldIndex][1][indexes.opIndex][1].CLUInfo[indexes.cluIndex];

    updates.forEach((update) => {
      const values = Object.entries(update);
      const modify = values[0][0];
      const value = values[0][1];
      if (checkValidInput(modify, value)) {
        // This is stored in a different variable so handle differently as well
        if (modify === 'cluProducerReviewRequestIndicator') {
          clusSeen[toUpdate.clu_identifier][modify] = value;
        } else {
          // eslint-disable-next-line prefer-destructuring
          toUpdate[modify] = value;
        }
      }
    });

    setOrderedMappings(mappings);
    setChangesMade(true);
    return mappings;
  };
  //#endregion

  //#region - map/modal helpers
  /**
    * After operation boundary has been edited, we will need to update
    * intersecting CLUs based on updated operation boundary.
    * This function largely echoes what happens in initialLoad but for a singular operation. Much of the data does not need
    * to be gotten again but some does. Be careful when making changes in initialLoad vs here that they match where needed.
    * @param {Object} indexes Operations indexes in orderedMappings
    * @param {Object} operation Operation data
    * @returns {void}
    */
  const updateIntersections = async (indexes, operation, passBoundary = false) => {
    try {
      // Prep vars
      let cluResponse;
      let updates;
      const boundary = operation.operationBoundary;
      // These are only needed if (!passBoundary || includeNonCLUBoundaries)
      const year = commodityYear;
      const arRequestBody = {
        commodityYear: year,
        commodityYears: createYearArrayRange(year - 1, year + 1),
        fieldIDs: [operation.fieldID],
        operationID: operation.operationID,
        operationType: opType,
        orgID: chosenOrg.id,
        cluOwnerEmail: useOwnCLUData,
        ownerEmail: impersonating,
        useOrgCLUData,
      };
      let nonCLUResponse;
      
      // Handle path differently if 'GART' zoneType
      if (!gartPath) {
        setLoading(true);
        if (!passBoundary) {
          // console.log("operation", operation)

          cluResponse = await getCLUIntersections(arRequestBody);
          // NOTE: Should probably have a retry and then error message if this fails...
          // console.log("CLU RESPONSE", cluResponse)
          
          // NOTE: Getting "orphaned boundaries" is not supported for this path as this path should actually not be needed
        } else {
          const shape = wkt.convert(JSON.parse(boundary));
          const cluRequestBody = {
            boundaries: shape,
            count: 1,
            cluOwnerEmail: useOwnCLUData,
            orgID: useOrgCLUData ? chosenOrg.id : '', 
            ownerEmail: impersonating,
          };
          cluResponse = await getCLUIntersectionsOld(cluRequestBody);
          // NOTE: Should probably have a retry and then error message if this fails...

          // Now, get "orphaned boundaries", if appropriate
          if (includeNonCLUBoundaries) {
            // NOTE: This call will not use the updated boundary to find the nonCLUBoundaries...
            // So, those ones will not be exactly accurate but then again, nonCLUBoundaries are not really meant to be used..
            // And it won't get any data if the operation does not intersect with any CLU. So, functionality just disabled for those cases
            nonCLUResponse = await getNonCLUBoundariesGivenBounds(cluRequestBody);
            // if (debuggingOutputs) console.log('nonCLUResponse :>> ', nonCLUResponse);

            // NOTE: In case getNonCLUBoundaries fails for some reason. Automatically try again first before telling user to try another time.
            if (!nonCLUResponse || typeof nonCLUResponse === 'string') {
              enqueueSnackbar('An issue occurred while retrieving your planting\'s information. Please try again later.');
              return false;
            }
          }
        }
        // BEWARE: If something related to use of cluResponse is changed here, it should also be changed in initialLoad

        // For new seeding operation intersections, modify result and clusSeen as necessary to match rest of code
        const tempClusSeen = cloneDeep(clusSeen);
        for (const cluObject of cluResponse) {
          // Add operationID back in case call to getCLUIntersectionsOld was made
          cluObject.operationID = operation.operationID;
          organizeCLUs(cluObject, undefined, tempClusSeen);
        }

        // Store needed data to add to any completely new clu from first old CLU
        // BEWARE: If something in thingsToMap is changed here, it should probably also be changed in initialLoad
        const firstCLU = operation.CLUInfo[0];
        const thingsToMap = {
          crop: firstCLU.crop,
          croppingPracticeCode: firstCLU.croppingPracticeCode,
          cropType: firstCLU.cropType,
          cropTypes: firstCLU.cropTypes,
          geospatialShapeProcessDate: new Date().toISOString(),
          includeGeospatialInReport: yesCode,
          intendedUse: firstCLU.intendedUse,
          intendedUses: firstCLU.intendedUses,
          irrigationPracticeCode: firstCLU.irrigationPracticeCode,
          micsCode: firstCLU.micsCode,
          micsName: firstCLU.micsName,
          organicPracticeTypeCode: firstCLU.organicPracticeTypeCode,
          reportedAcreageModifiedIndicator: yesCode, // Making this yes doesn't matter as addCLUDefaults makes acres===finalReportedAcreage
          sharePercentage: firstCLU.sharePercentage,
          shown: true,
        };
        const cluInfo = [];
        let operationAcres = 0;

        for (const clu of cluResponse) {
          // If possible, get the default values from a previously existing CLU
          const prevCLU = operation.CLUInfo.filter((entry) => entry.clu_identifier === clu.clu_identifier)[0];
          if (prevCLU) {
            // If we are copying values, then there are only a few things that need to be updated for the new CLU
            const newThings = {
              acres: prevCLU.acres, // Make sure to not overwrite original acreage
              geospatialShapeProcessDate: new Date().toISOString(),
              reportedAcreageModifiedIndicator: yesCode,
            };
            // Assign all data from match
            const newCLU = { ...clu, ...prevCLU };
            // Change boundary to updated one
            newCLU.boundary = clu.boundary;

            // A modification reason will be provided to all these when this function is done running and updateOperation is called 
            cluInfo.push(addCLUDefaults(newCLU, newThings));
            operationAcres += newCLU.finalReportedAcreage;
          } else {
            // Give these CLUs new zoneIDs, should always be necessary
            // NOTE: Could eventually just do one call (keep track of ones needing IDs and pass the number of new IDs we need)
            const newZoneID = await getNewZoneID();
            clu.zoneID = newZoneID.result;
            operation.zoneIDsFromDB.push(newZoneID.result);

            cluInfo.push(addCLUDefaults(clu, thingsToMap));
            operationAcres += clu.finalReportedAcreage;
          }
        }

        // Parse "orphaned boundaries", if appropriate
        if (includeNonCLUBoundaries && nonCLUResponse.length) {
          // For each new nonCLUboundary, compare to all new CLU intersections (for the same operation) 
          for (const nonCLU of nonCLUResponse) {
            const [noMatchFound, newCLU] = await handleNonCLUBoundary(cluInfo, nonCLU.boundary, nonCLU.operationID, undefined, tempClusSeen);

            // Only do this if a new CLU for an "orphaned boundary" was created
            if (noMatchFound) {
              // Store these new CLU's zoneID
              operation.zoneIDsFromDB.push(newCLU.zoneID);

              // nonCLUBoundaries always count as completely new "CLUs" as there is no way to associate them to a previously existing version
              cluInfo.push(addCLUDefaults(newCLU, thingsToMap));
            }
            else {
              // Remove old boundary's acreage that has already been added to operationAcres
              operationAcres -= newCLU.finalReportedAcreage;

              // Just update this CLU's boundary-related info; it's already in cluInfo
              // Also make sure to not overwrite original CLU acreage
              addCLUDefaults(newCLU, {acres: newCLU.acres});
            }

            // Add the new acreage to total operation acreage
            operationAcres += newCLU.finalReportedAcreage;
          }
        }

        // Update clusSeen - don't really need this after handleNonCLUBoundary as that can't add anything new to tempClusSeen
        setClusSeen(tempClusSeen);

        // NOTE: This currently DOES NOT break up incoming MultiPolygons into Polygons as does initialLoad...
        // ADD THAT

        // Everything that could have changed for the operation should be passed in here
        updates = [
          { acres: operationAcres }, // Here we modify acres and not finalReportedAcreage as acres is what is used for !gartPath
          { CLUInfo: cluInfo },
          { operationBoundary: boundary },
          { zoneIDsFromDB: operation.zoneIDsFromDB },
        ];
      }
      // For 'GART', do not get any new CLUs, just update a few vars (acreage and shape info)
      else {
        const reprojObj = reprojectCalcArea(boundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
        updates = [
          { finalReportedAcreage: reprojObj.area },
          { geospatialShapeProcessDate: new Date().toISOString() },
          { operationBoundary: boundary },
          { projCode: reprojObj.epsgCode },
        ];
      }

      // For either operation or CLU, mark that operation boundary was modified (and prompt ChangeExplanation)
      updates.push({ geospatialShapeModifiedIndicator: yesCode });

      // Update all changes for operation
      updateOperation(
        indexes.farm,
        indexes.field,
        indexes.operation,
        updates,
      );

      return true;
    } catch (err) {
      console.error('An error occurred in updateIntersections: ', err);
      return false;
    } finally {
      setLoading(false);
    }
  };
  
  // Helps setup the modal to edit the CLU boundary
  const editOperationWithClu = (cluInfo, operationInfo) => {
    // console.log('clusSeen, cluInfo :>> ', clusSeen, cluInfo);
    // console.log('clusSeen[cluInfo.clu_identifier] :>> ', clusSeen[cluInfo.clu_identifier]);
    setCluBoundary(cluInfo);
    setOperationToEdit(operationInfo);
    setOpenMap(true);
  };

  // Replaces the operation object in orderedMappings with the same operationID as the passed obj
  // Used in: EditMap
  // This really does not seem like it is necessary. Dev could have just called updateOperation from EditMap...
  const updateOperationInMapping = (operation) => {
    const farmIndex = selectedIndices.farm;
    const fieldIndex = selectedIndices.field;
    const mappings = [...orderedMappings];
    const operationIndex = mappings[farmIndex][1][fieldIndex][1].findIndex((x) => x[0] === operation.operationID);

    // Change the operation in orderedMappings
    if (operationIndex !== -1) {
      mappings[farmIndex][1][fieldIndex][1][operationIndex][1] = operation;
      // Update saved date if appropriate
      updateLastSaved(farmIndex, fieldIndex, mappings);
      setOrderedMappings(mappings);
    }
  };

  // Add operation created in CreateNewOperation to orderedMappings for display in tool
  // Adds to an existing field or to a new field as needed
  // Actual saving of new operation is handled by saveNewZone
  // newField option is currently not in use
  const addOperation = async (operation, fieldToUse, passedClusSeen, newField = false) => {
    try {
      // NOTE: Will need to change this whole function to handle multiple new operations instead...
      // Save to Zones table
      await saveNewZone(operation, passedClusSeen);

      const fieldName = fieldToUse.name;
      const inYearRange = (operation.year >= commodityYear - 1) && (operation.year <= commodityYear + 1);

      // Do not do any of this if data hasn't even been loaded yet OR if year is too far apart
      if (successfullyLoadedData && inYearRange) {
        // Extract some data
        const farmID = fieldToUse.farmId;
        const mappingsClone = cloneDeep(orderedMappings);
        // Check the first operation of the first field of this farm for farmID
        const farmIndex = mappingsClone.findIndex((farm) => farm[1][0][1][0][1].farmID === farmID);

        // Handle farm operation was added to not being one of the loaded in ones
        if (farmIndex === -1) {
          enqueueSnackbar('Success. However, the farm your planting was added to is not currently selected so it will not be displayed in the interface.');
          return;
        }

        // Modify the appropriate farm
        const farmInfo = mappingsClone[farmIndex][1];

        const operationArray = [operation.operationID, operation];
        // Add operation to new or existing field as appropriate
        if (newField) {
          // A field was created along with the planting and will need to be added to mappings
          const fieldMapping = [fieldName, [operationArray]];
          farmInfo.push(fieldMapping);

          // Update fieldMaps - in case initialLoad is called again after this
          setFieldMaps((prev) => ({
            ...prev,
            [operation.fieldID]: { name: fieldName, farm: { id: operation.farmID, name: farmMaps[operation.farmID] } },
          }));
        } else {
          const fieldID = fieldToUse.id;
          // Check the first operation of this field for fieldID
          const fieldIndex = farmInfo.findIndex((field) => field[1][0][1].fieldID === fieldID);

          // Handle 'field operation was added to' not being one of the loaded in ones
          if (fieldIndex === -1) {
            enqueueSnackbar('Success. However, the field your planting was added to is not currently selected so it will not be displayed in the interface.');
            return;
          }

          farmInfo[fieldIndex][1].push(operationArray);
        }

        setOrderedMappings(mappingsClone);
        // Make sure this new operation's dates is included in FarmSection
        setRefreshTableDates(true);
      }
      // Notify user if they won't see operation here
      else {
        enqueueSnackbar(`Make sure to select year ${operation.year} to view your new planting data.`);
      }

      enqueueSnackbar(`Your planting was successfully added to field: ${fieldName}`);
    } catch (err) {
      console.error(err);
    }
  };

  // Called by addOperation above. Saves new CLUs or Operation created from Create Operation modal.
  const saveNewZone = async (operation, passedClusSeen) => {
    // console.log(operation)
    let zoneIDs;
    const newZones = [];
    const operationYear = operation.year;

    // Handle 'CART' vs. 'GART' zoneTypes differently
    if (!gartPath) {
      for (const clu of operation.CLUInfo) {
        // Get reference CLU for saving
        const staticCluInfo = passedClusSeen[clu.clu_identifier];
        const zoneToSave = formatZoneForSave(
          clu,
          staticCluInfo,
          zoneType,
          operationYear,
          operationYear,
          operation,
          gartPath,
        );
        newZones.push(zoneToSave);
      }
      zoneIDs = operation.zoneIDsFromDB;
    } else {
      const zoneToSave = formatZoneForSave(
        operation,
        null,
        zoneType,
        operationYear,
        operationYear,
        operation,
        gartPath,
      );
      newZones.push(zoneToSave);
      zoneIDs = [operation.zoneID];
    }

    // console.log(newZones)
    await saveAcreageZones(zoneIDs, newZones);
  };

  // Decide whether to open or close report file upload modal and assign operationID if it exists
  const toggleReportFileUpload = (source = null, operationID = null) => {
    if (source !== null) {
      if (operationID !== null) {
        setFileOperationID(operationID);
      }
      setReportFileUploadOpen(true);
    } else {
      setFileOperationID(null);
      setReportFileUploadOpen(false);
    }
  };
  
  // Decide whether to open or close report file download modal and assign operationID if it exists
  const toggleReportFileDownload = (source = null, operationID = null, operationInfo = null) => {
    if (source) {
      if (operationID !== null) {
        setFileOperationID(operationID);
        if(operationInfo !== null){
          setFileOperationInfo(operationInfo)
        }
      }
      setReportFileDownloadOpen(true);
    } else {
      setFileOperationID(null);
      setReportFileDownloadOpen(false);
    }
  };

  const handleNewPlanting = (isPrevented) => {
    setOpenCreateNewOperation(true);
    setNewPreventedPlanting(isPrevented);
  };
  //#endregion

  //#region - reset/save data
  /**
 * Sends request to backend to delete the currently selected entries from the Zones table.
 * If successful, calls initial load to pull info in from SeedingOperations table.
 * @param {Boolean} deleteCreated whether or not to delete plantings created from this interface
 * @returns undefined
 */
    const resetData = async (deleteCreated = false) => {
    setLoading(true);
    // Build request obj
    const zoneIDs = new Set();
    // console.log(orderedMappings);

    // Get all the zoneIDs to be deleted
    for (const farm of orderedMappings) {
      for (const field of farm[1]) {
        for (const operation of field[1]) {
          const operationInfo= operation[1];
          if (hasSkipMarker(operationInfo, 'operation')) {
            continue;
          }

          if (!gartPath) {
            operationInfo.zoneIDsFromDB.forEach(zoneIDs.add, zoneIDs);
          }
          // Get zoneIDs from operation if zoneType is 'GART'
          else {
            // If there is a removed op to delete, add it here
            if (operationInfo.hasOwnProperty('zoneIDToDelete')) {
              zoneIDs.add(operationInfo.zoneIDToDelete);
            }
            zoneIDs.add(operationInfo.zoneID);
          }
        }
      }
    }

    const idList = Array.from(zoneIDs);
    const arQuery = {
      deleteCreated,
      zoneIDs: idList,
      zoneType,
    };
    // console.log(arQuery)
    const result = await deleteZones(arQuery);

    if (typeof result === 'string') {
      enqueueSnackbar('An error occurred. We couldn\'t successfully delete your data. Please try again later.');
      setLoading(false);
    } else {
      initialLoad();
    }
  };

  // Checks whether the current year is the same as the year that data was loaded with.
  // If it is, then call saveReportData. If not, then open SaveYearWarning modal.
  const checkYearForSave = async () => {
    if (commodityYear === yearDataLoaded.current) {
      return await saveReportData();
    }
    else {
      setOpenConfirm(false);
      setOpenSaveYearWarning(true);
    }
  }

  // Reverts the year to the one data was loaded with then calls saveReportData
  const saveWithOldYear = () => {
    setCommodityYear(yearDataLoaded.current);
    saveReportData(yearDataLoaded.current);
  }

  // Saves the currently loaded/input information so user can keep the current state of interface
  const saveReportData = async (passedYear = undefined) => {
    try {
      setSavingReport(true);
      const yearToUse = passedYear || commodityYear;

      // Parse orderedMappings data and add any missing information to be stored in [Farmer_Data].[AcreageReporting].[Zones]
      const dataAggregateArray = [];
      // Make copy to update with zoneIDs
      const mappings = cloneDeep(orderedMappings);
      const zoneIDs = new Set();

      // First, want to get all the data in here by CLU/operation
      for (const farm of mappings) {
        for (const field of farm[1]) {
          for (const operation of field[1]) {
            const operationInfo = operation[1];
            // console.log(operationInfo, operationInfo)
            // Skip this operation if there was an unresolved error/marking related to it
            if (hasSkipMarker(operationInfo, 'operation')) {
              continue;
            }

            // Decide how to acquire the data to save based on zoneType
            if (!gartPath) {
              // Keep track of the zoneIDs to delete
              operationInfo.zoneIDsFromDB.forEach(zoneIDs.add, zoneIDs);

              for (const clu of operationInfo.CLUInfo) {
                // Skip this CLU if there was an unresolved error/marking related to it
                if (hasSkipMarker(clu, 'clu')) {
                  continue;
                }

                // Get reference CLU for saving
                const staticCluInfo = clusSeen[clu.clu_identifier];
                // console.log(staticCluInfo);
                // console.log('operationInfo :>> ', operationInfo);
                const zoneToSave = formatZoneForSave(
                  clu,
                  staticCluInfo,
                  zoneType,
                  yearToUse,
                  reinsuranceYear,
                  operationInfo,
                  gartPath,
                );

                // Store this info
                dataAggregateArray.push(zoneToSave);
              }
            } else {
              // Keep track of the zoneIDs to delete
              // If there is a removed op to delete, add it here
              if (operationInfo.hasOwnProperty('zoneIDToDelete')) {
                zoneIDs.add(operationInfo.zoneIDToDelete);
              }
              zoneIDs.add(operationInfo.zoneID);

              const zoneToSave = formatZoneForSave(
                operationInfo,
                null,
                zoneType,
                yearToUse,
                reinsuranceYear,
                operationInfo,
                gartPath,
              );

              // Store this info
              dataAggregateArray.push(zoneToSave);
            }
          }
        }
      }

      // Save the final parsed data
      const zoneIDsFromDB = Array.from(zoneIDs);
      const response = await saveAcreageZones(zoneIDsFromDB, dataAggregateArray);
      if (debuggingOutputs) {
        console.log('dataAggregateArray :>> ', dataAggregateArray);
        console.log('response', response);
      }

      // Update last saved date based on request response
      if (response && typeof response !== 'string') {
        const savedDate = new Date();
        setLastSaved(savedDate);

        // Update saved_at for operations that were saved so updateLastSaved can work properly
        for (const farm of mappings) {
          for (const field of farm[1]) {
            for (const operation of field[1]) {
              const operationInfo = operation[1];
              // Skip this operation if there was an unresolved error/marking related to it
              if (hasSkipMarker(operationInfo, 'operation')) {
                continue;
              }
              // We do want ISO string but we don't want code to know that.
              // So, it needs to assume it's local and not automatically convert, so we need to remove the 'Z'
              operationInfo.saved_at = savedDate.toISOString().split('Z')[0];
            }
          }
        }

        // If saved_at has been updated, properly store it in orderedMappings
        setOrderedMappings(mappings);
        setChangesMade(false);
      } else {
        setLastSaved(-1);
      }

      return response;
    }
    // General error handling
    catch (err) {
      console.log('An error occurred while saving acreage information: ', err);
      enqueueSnackbar('An error occurred while saving acreage information. Please try again later.');
    } finally {
      setSavingReport(false);
    }
  };
  //#endregion


  // -------------------- STEP AND PAGE CONTROLS --------------------
  //#region - review step data helpers
  const numLettersInAlphabet = 26;
  const asciiBaseValue = 65;
  // Convert a number to a subfield representation. Values can be anywhwere in this range: A,...,Z,AA,...,AZ,ZA,...,ZZ
  const convertToSubField = (entryIndex) => {
    const quotient = Math.trunc(entryIndex / numLettersInAlphabet);
    const remainder = entryIndex % numLettersInAlphabet;
    if (quotient > 26) {
      enqueueSnackbar('There are way too many subfields for this CLU...');
      consoleImportant('convertToSubField in AcreageReporting. Something went very wrong here; this should not be allowed.');
      // NOTE: This should never happen if farm/field/operation/CLUs are properly limited from the beginning so will not handle it
    }
    const prefix = quotient > 0 ? String.fromCharCode(asciiBaseValue - 1 + quotient) : '';

    const representation = prefix + String.fromCharCode(asciiBaseValue + remainder);
    // console.log('entryIndex :>> ', entryIndex);
    // console.log('representation :>> ', representation);
    return representation;
  };

  // 5 was manually picked as at that precision is when points really seemed to be overlapping
  // Points on the same line would at least differ at the 4th decimal for x or y and will never have both x and y be so similar.
  // This was the case on 07/23/2021 when using coordinates projected to UTM from Geodetic CRS: WGS 84
  const sweetSpot = 5;
  const minimumNumVerticesForPolygon = 3; // Should be 3
  // Custom function to simplify shapes.
  // This helps in cases where there are points that are basically on top of each other and turf calls fail in those cases.
  // This will remove one of those "duplicate" points.
  // Originally added to fix turf.intersect failing for detecting overlaps. However, this also simplifies the shape and ->
  // NOTE: This could potentially be used any time we try to save an edit... but could really make process much slower too??
  const simplifyShapes = (inputShape) => {
    const simplifiedShape = [];

    // Iterate through this polygon's outerbounds and holes (if any) and repeat the same process
    for (const currentBounds of inputShape) {
      const newShape = [];

      // Iterate through this shape's points
      for (let i = 0; i < currentBounds.length - 1; i++) {
        const currentPoint = currentBounds[i];
        const nextPoint = currentBounds[i+1];

        // Add this point if different enough to next point (if points do not seem to basically be the same)
        // Only adds one point if there's many similar ones in a row
        if (currentPoint[0].toFixed(sweetSpot) !== nextPoint[0].toFixed(sweetSpot) || currentPoint[1].toFixed(sweetSpot) !== nextPoint[1].toFixed(sweetSpot)) {
          newShape.push(currentPoint);
        }
      }

      // Do the same as above with the last point
      const currentPoint = newShape[newShape.length - 1];
      const nextPoint = newShape[0];
      if (currentPoint[0].toFixed(sweetSpot) !== nextPoint[0].toFixed(sweetSpot) || currentPoint[1].toFixed(sweetSpot) !== nextPoint[1].toFixed(sweetSpot)) {
        // Add the first point to close the Polygon
        // - if it's different than the actual last point, then previous first point was removed and last point can be ignored
        newShape.push(newShape[0]);
      } else {
        // Replace the last point if it's too similar
        newShape[newShape.length - 1] = newShape[0];
      }
      
      // Ignore areas that will not be Polygons anymore if modified.
      if (currentBounds.length > minimumNumVerticesForPolygon) {
        // If appropriate, store newShape
        simplifiedShape.push(newShape);
      }
      else {
        // If it was meant to be the first addition to simplifiedShape, then this shape is now entirely invalid
        if (!simplifiedShape.length) {
          // // These outputs show that this situation only seems to happen with shapes that are not valid anyways
          // const shape = turf.polygon(inputShape);
          // const reprojObj = reprojectCalcArea(shape.geometry, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
          // console.log('turf.convertArea(turf.area(shape), \'meters\', \'acres\') :>> ', turf.convertArea(turf.area(shape), 'meters', 'acres'));
          // console.log('reprojObj :>> ', reprojObj);
          return null;
        }
      }
    }

    if (debuggingOutputs) {
      // console.log('inputShape :>> ', inputShape);
      // console.log('simplifiedShape :>> ', simplifiedShape);
    }
    return simplifiedShape;
  }

  // This will take a shape and remove any "overlapping" points, turn it into a valid turf Polygon, and unkink it, if necessary
  const fixShapes = (shape) => {
    const simplifiedShape = simplifyShapes(shape);
    // console.log('simplifiedShape :>> ', simplifiedShape);

    // Do the following if there's a shape to be returned.
    if (simplifiedShape) {
      // unkinkPolygon is now added to help remove any existing (non-noded?) self-intersections (or kinks)
      // Not removing these might cause failures when shapes like these are found
      // unkinkPolygon is broken and removes holes from Polygons... (see: https://github.com/Turfjs/turf/issues/1719)
      // We knowsimplifiedShape is supposed to be a Polygon, so I can take the shape without the holes, unkink it, then add the holes back. Should definitely be good enough.
      const newShapes = turf.unkinkPolygon(turf.polygon([simplifiedShape[0]]));
      // This is also assuming that the first feature being returned after unkinkPolygon is the main one that was unkinked...
      // This might fail in some cases where there's huge kinks in the boundary... But that should never happen
      newShapes.features[0].geometry.coordinates.push(...simplifiedShape.slice(1));
      // console.log('newShapes :>> ', newShapes);

      let survivors = [];
      // After unkinking, some of the resulting Polygons may still be too small to be valid or be really weird shapes...,
      // so do this to weed them out. 
      // 0.02 is used as the magic number as too often it is the border of the CLUs that are returned instead of nothing (when going through nonCLUBoundaries) (0.01 would have probably been fine but 0.015 is used instead just to be safe)
      // This is the same cutoff used when getting nonCLUBoundaries
      for (const shape of newShapes.features) {
        const shapeArea = turf.convertArea(turf.area(shape), 'meters', 'acres');
        // const reprojObj = reprojectCalcArea(shape.geometry, 'UTM', 'GeoJSON', 'WGS84', 'Acres');
        // console.log('shapeArea :>> ', shapeArea);
        // console.log('reprojObj :>> ', reprojObj);
        if (shapeArea >= 0.015) {
          survivors.push(shape);
        }
      }
      return survivors;
    }
  }

  // Fixes the given shape to create the appropriate Feature for checking for intersections
  // Avoids MultiPolygons as there seems to be an error in the code for handling this
  // See - https://github.com/Turfjs/turf/issues/2069
  const createTurfFeature = (inputShape) => {
    const boundary = typeof inputShape === 'string' ? JSON.parse(inputShape) : inputShape;
    const polygonList = [];

    if (boundary.type === 'Polygon') {
      const newPolygons = fixShapes(boundary.coordinates);
      if (newPolygons) polygonList.push(...newPolygons);
    }
    else if (boundary.type === 'MultiPolygon') {
      for (const poly of boundary.coordinates) {
        const newPolygons = fixShapes(poly);
        // console.log('newPolygons :>> ', newPolygons);

        if (newPolygons) polygonList.push(...newPolygons);
      };
    }
    return polygonList;
  }

  // Breaks up MultiPolygons and Polygons into list of Polygon Features, then checks for any intersections, 
  // and returns the MultiPolygon (array of Polygons) of any existing intersection 
  const getIntersections = (firstBoundary, secondBoundary, getShapes = false) => {
    // Get the proper subfields' shapes for intersection comparison
    // console.log('firstBoundary, secondBoundary :>> ', firstBoundary, secondBoundary);
    const firstShapes = createTurfFeature(firstBoundary);
    const secondShapes = createTurfFeature(secondBoundary);
    // console.log('firstShapes, secondShapes :>> ', firstShapes, secondShapes);
    
    const intersections = [];
    // Try to find if any of the sub Polygons of firstCLU intersect with any of those from secondCLU
    for (const firstShape of firstShapes) {
      for (const secondShape of secondShapes) {
        // console.log('.');
        const intersection = turf.intersect(firstShape, secondShape);
        // console.log('intersection :>> ', intersection);

        // Add the resulting Polygons to intersections
        if (intersection) {
          // Depending on what is needed, return appropriately
          // For call from initialLoad to find CLUs that this shape is touching, as soon as we find one, exit
          if (getShapes) {
            return [...firstShapes, ...secondShapes];
          }

          const intersectedGeometry = intersection.geometry;
          if (intersectedGeometry.type === 'GeometryCollection') {
            intersectedGeometry.geometries.map((geometry) => intersections.push(geometry.coordinates));
          }
          else {
            intersections.push(intersectedGeometry.coordinates);
          }
        }
        // else {
        //   // console.log('firstShape, secondShape :>> ', firstShape, secondShape);
        //   // console.log('intersection :>> ', intersection);
        // }
      }
    }
    // console.trace('intersections :>> ', intersections);
    
    // If getShapes and we reached here, then there were no intersection
    // Otherwise, return whatever intersections is
    return intersections;
  }

  // Compare the different subfields within a particular farm/tract/CLU grouping to see if any of them intersect
  // For FixOverlappingBoundaries
  const checkForIntersections = (cluGrouping) => {
    try {
      // console.log('cluGrouping :>> ', cluGrouping);
      const intersectingSubfields = {};
      // Within each grouping, compare every one against the others
      for (const [indexFS, firstCLU] of cluGrouping.entries()) {
        for (const [indexSC, secondCLU] of cluGrouping.slice(indexFS + 1).entries()) {
          // For debugging
          // const target = 'F5B5D4BB-B408-4033-BCE5-1CDE9085970F';
          // const targetCLU = firstCLU.clu_identifier === target && secondCLU.clu_identifier === target;
          const targetCLU = false;

          // The actual index of the second shape will be different than just indexSC
          const indexSS = indexFS + indexSC + 1;
          const intersections = getIntersections(firstCLU.boundary, secondCLU.boundary);

          // For debugging
          if (targetCLU) {
            console.log('HERE================================================');
            console.log('intersections :>> ', intersections);
          }

          // If any of the boundaries overlap, get their overall area and store info if area is big enough
          if (intersections.length) {
            const area = turf.convertArea(turf.area(turf.multiPolygon(intersections)), 'meters', 'acres');
            if (targetCLU) console.log('area :>> ', area);

            // Ignore this overlap if it is less than 0.01 acres
            if (area < 0.01) { continue; }

            let subfield = secondCLU.subfieldNumber;
            // add mappings to tracking object
            // indexFS -> indexSS
            if (intersectingSubfields.hasOwnProperty(indexFS)) {
              intersectingSubfields[indexFS].push({ index: indexSS, subfield });
            } else {
              intersectingSubfields[indexFS] = [{ index: indexSS, subfield }];
            }

            subfield = firstCLU.subfieldNumber;
            // indexSS -> indexFS
            if (intersectingSubfields.hasOwnProperty(indexSS)) {
              intersectingSubfields[indexSS].push({ index: indexFS, subfield });
            } else {
              intersectingSubfields[indexSS] = [{ index: indexFS, subfield }];
            }
          } else {
            // console.log('intersections :>> ', intersections);
            // const area = turf.convertArea(turf.area(intersections), 'meters', 'acres');
            // console.log('area :>> ', area);
          }

          // For debugging
          if (targetCLU) {
            console.log('indexFS :>> ', indexFS);
            console.log('intersectingSubfields :>> ', intersectingSubfields);
            console.log('END');
          }
        }

        // Delete the indexes that are not needed (indexes were created for this in createCLUSummary)
        if (!(indexFS in intersectingSubfields)) {
          delete firstCLU.indexes;
        }
      }

      // console.log('intersectingSubfields :>> ', intersectingSubfields);
      // Make the tracking object accessible through first subfield
      cluGrouping[0].intersectingSubfields = intersectingSubfields;
      return intersectingSubfields;
    } catch (err) {
      console.error(err);
      // In case of an error, do not change intersectingSubfields
      return undefined;
    }
  };

  // For 'CART', gets the CLU data in the right format to display on the second screen.
  // This organized data is also helpful for creating the final report.
  const createCLUSummary = () => {
    const expandedStates = [];
    const cluSummary_array = [];
    const cluSummary_object = {};
    if (debuggingOutputs) {
      console.log('orderedMappings :>> ', orderedMappings);
    }

    // First, want to get all the data in here by CLU (want to store in object as well to easily be able to add to CLU list)
    for (const [farmIndex, farm] of orderedMappings.entries()) {
      for (const [fieldIndex, field] of farm[1].entries()) {
        for (const [opIndex, operation] of field[1].entries()) {
          const operationInfo = operation[1];
          // Skip this operation if there was an unresolved error/marking related to it
          if (hasSkipMarker(operationInfo, 'operation', true)) {
            continue;
          }

          for (const [cluIndex, clu] of operationInfo.CLUInfo.entries()) {
            // Skip this CLU if there was an unresolved error/marking related to it
            if (hasSkipMarker(clu, 'clu', true)) {
              continue;
            }

            // Add necessary info to CLU for later display
            clu.farmName = farm[0];
            clu.fieldName = field[0];
            clu.operationTitle = operationInfo.title;
            clu.linkInfo = (
              <>
                <Typography>
                  Farm:
                  {farm[0]}
                </Typography>
                <Typography>
                  Field:
                  {field[0]}
                </Typography>
                <Typography>
                  Planting:
                  {operationInfo.title}
                </Typography>
              </>
            );
            clu.crops = operationInfo.crops;
            // If these don't exist already, then even operation was never changed from default
            if (!clu.finalPlantedDate) { clu.finalPlantedDate = operationInfo.finalPlantedDate; }
            if (!clu.skipRowPatternCode) { clu.skipRowPatternCode = ''; }
            if (!clu.productPlantingCode) { clu.productPlantingCode = ''; }

            // Access to operation info added to CLU for report
            clu.operationInfo = operationInfo;

            // Add indexes to reach CLU from orderedMappings for future intersectingSubfields
            clu.indexes = {
              farmIndex, fieldIndex, opIndex, cluIndex,
            };

            // Store this info
            if (cluSummary_object.hasOwnProperty(clu.clu_identifier)) {
              const cluArray = cluSummary_object[clu.clu_identifier];
              clu.subfieldNumber = convertToSubField(cluArray.length);
              cluArray.push(clu);
            } else {
              // Add subfieldNumber based on position in array
              clu.subfieldNumber = convertToSubField(0);
              // Store data as an array in object for quick retrieval
              const cluArray = [clu];
              cluSummary_object[clu.clu_identifier] = cluArray;
              // Will also add them all to an array (will be ordered) to have a consistent order to make it easier for the user
              cluSummary_array.push(cluArray);
              expandedStates.push(false);
            }
          }
        }
      }
    }

    // Sort them by farm, tract, and CLU numbers
    // All CLUs copies have the same info so just use the first copy each time
    cluSummary_array.sort((a, b) => {
      const cluA = clusSeen[a[0].clu_identifier];
      const cluB = clusSeen[b[0].clu_identifier];
      return cluA.farm_number - cluB.farm_number || cluA.tract_number - cluB.tract_number || cluA.clu_number - cluB.clu_number;
    });

    // Compare the different subfields within a farm/tract/CLU to see if any of them intersect
    for (const cluGrouping of cluSummary_array) {
      checkForIntersections(cluGrouping);
    }
    if (debuggingOutputs) {
      console.log('cluSummary_array :>> ', cluSummary_array);
    }

    // Store the resulting array so that it can be used for ReviewPage
    setCLUSummary(cluSummary_array);
    // Expand the first clu section by default
    expandedStates[0] = true;
    setSummaryExpanded(expandedStates);
  };

  // For 'GART', gets the operation data in the right format to display on the second screen.
  // This organized data is also helpful for creating the final report.
  const createOperationSummary = () => {
    const expandedStates = [];
    const opSummary_array = [];
    if (debuggingOutputs) {
      console.log('orderedMappings :>> ', orderedMappings);
    }

    // First, want to get all the data in here by Operation
    for (const [farmIndex, farm] of orderedMappings.entries()) {
      for (const [fieldIndex, field] of farm[1].entries()) {
        const fieldSummary = [];

        // Store all of a field's operations together
        for (const [opIndex, operation] of field[1].entries()) {
          const operationInfo = operation[1];
          // Skip this operation if there was an unresolved error/marking related to it
          if (hasSkipMarker(operationInfo, 'operation', true)) {
            continue;
          }

          // Add necessary info to CLU for later display
          const boundary = typeof operationInfo.operationBoundary === 'string' ? operationInfo.operationBoundary : JSON.stringify(operationInfo.operationBoundary);
          operationInfo.boundary = boundary; // Needed for display on Review Page
          operationInfo.farmName = farm[0];
          operationInfo.fieldName = field[0];
          if (!operationInfo.productPlantingCode) { operationInfo.productPlantingCode = ''; }
          if (!operationInfo.skipRowPatternCode) { operationInfo.skipRowPatternCode = ''; }

          // Rename some operation info to match what existed for 'CART' zoneType
          operationInfo.operationInfo = operationInfo; // Needed for creating 'GART' report
          operationInfo.operationTitle = operationInfo.title; // Needed for display on Review Page

          fieldSummary.push(operationInfo);
          expandedStates.push(false);
        }

        // Store this info
        // Will add them all to an array (will be ordered) to have a consistent order to make it easier for the user
        if (fieldSummary.length) opSummary_array.push(fieldSummary);
      }
    }

    // They should already be sorted alphabetically (by farm, and field). That should be fine..
    if (debuggingOutputs) {
      console.log('opSummary_array :>> ', opSummary_array);
    }

    // Store the resulting array so that it can be used for ReviewPage
    setOperationSummary(opSummary_array);
    // Expand the first operation section by default
    expandedStates[0] = true;
    setSummaryExpanded(expandedStates);
  };
  //#endregion

  //#region - steps/page control
  // Decide how to display error message
  const displayError = () => {
    let toDisplay = error[1];
      
    return (
      <Box maxWidth="2000px" mx="auto" mt={5} p="0px 8px" display="flex" justifyContent="center">
        <Box className={classes.errorMessageBox}>
          {
            typeof toDisplay == 'string' ?
              toDisplay
            : toDisplay instanceof Array ?
              toDisplay.map((message, index) => 
                <Box key={index}> {message} </Box>
              )
            : 'An error occured.'
          }
        </Box>
      </Box>
    )
  }

  const a11yProps = (index) => ({
    id: `farm-selection-tab-${index}`,
    'aria-controls': `farm-selection-tabpanel-${index}`,
  });

  // Sets and updates required values for when farm tab changes
  const handleTabChange = (event, newValue) => {
    const ndxs = { ...selectedIndices };
    ndxs.farm = newValue;
    ndxs.field = 0;
    setSelectedIndices(ndxs);
    updateLastSaved(newValue, 0);
    setRefreshTableDates(true);
  };
  
  // Sets and updates required values for when field tab changes
  const updateSelectedIndices = (farmIndex, fieldIndex) => {
    const indxs = { ...selectedIndices };
    indxs.farm = farmIndex;
    indxs.field = fieldIndex;
    setSelectedIndices(indxs);
  };

  /**
   * Main display for Acreage Reporting feature - lets user view operation intersections with CLU information (CART method)
   * @returns {JSX} Alternative rendering of statistics information (less table like) and error message if applicable
   */
  const viewOperations = () => (
    (!!error[0]) ? (
      displayError()
    ) : (_.size(orderedMappings) > 0) && (
      <Box>
        <AppBar position="static" style={{ maxWidth: width }}>
          <Tabs
            value={selectedIndices.farm}
            onChange={handleTabChange}
            aria-label="field selection"
            variant="scrollable"
            scrollButtons="on"
          >
            {orderedMappings.map((farmInfo, farmIndex) => (
              <Tab key={farmInfo[0]} label={farmInfo[0]} {...a11yProps(farmIndex)} />
            ))}
          </Tabs>
        </AppBar>
        {orderedMappings.map((farmInfo, farmIndex) => (
          <TabPanel key={farmInfo[0]} value={selectedIndices.farm} index={farmIndex}>
            <FarmSection
              key={farmInfo[0]}
              clusSeen={clusSeen}
              commoditiesSeen={commoditiesSeen}
              commodityTypesSeen={commodityTypesSeen}
              commodityYear={commodityYear}
              editOperationWithClu={editOperationWithClu}
              farmInfo={farmInfo}
              farmIndex={farmIndex}
              gartPath={gartPath}
              impersonating={impersonating}
              mismatchOptions={[displayMarker, greyedOut, white]}
              openFileDownload={toggleReportFileDownload}
              openFileUpload={toggleReportFileUpload}
              refreshTableDates={refreshTableDates}
              reinsuranceYear={reinsuranceYear}
              setRefreshTableDates={setRefreshTableDates}
              setViewingField={setViewingField}
              statesSeen={statesSeen}
              updateIntersection={updateIntersection}
              updateIntersections={updateIntersections}
              updateLastSaved={updateLastSaved}
              updateOperation={updateOperation}
              updateSelectedItems={updateSelectedIndices}
            />
          </TabPanel>
        ))}
      </Box>
    )
  );
  
  // List of years user can select from to get data from (equivalent to RMA Commodity Year)
  const selectYear = () => (
    <Box p={1} mb={1}>
      <Box height={21} mb="4px" pl={0.5}>
        Commodity Year
      </Box>

      <Select
        variant="outlined"
        MenuProps={MenuProps}
        value={commodityYear}
        style={{ width: 240, margin: '0 10px', backgroundColor: '#ffffff' }}
        onChange={(e) => {
          setCommodityYear(e.target.value);
        }}
        disabled={loading}
      >
        { commodityYears.map((year, i) => (
          <MenuItem
            key={i}
            value={year}
          >
            {year}
          </MenuItem>
        ))}
      </Select>

    </Box>
  );

  // Determines what to display depending on the current step and other relevant vars
  const stepsDisplay = () => (
    <Box display="flex" flexGrow={1}>
      {/* Left pane - multi selection and load button */}
      {/* Keep field selection mounted, but hidden when not needed.
          This allows it to track internal state and most importantly
          the AutoComplete components maintain their internal selected values.
      */}
      <Slide
        in={showFieldSelection}
        direction="right"
        // -312px is based directly on 310px min-width of FieldSelection component below (needs 2 extra pixels for some reason)
        marginLeft={!showFieldSelection ? '-312px' : ''}
      >
        <Box className={classes.fieldSelection} style={{ maxHeight: chosenHeight }}>
          <FieldSelection
            organizations={organizations}
            chosenFields={fs_chosenFields}
            chosenOrg={fs_chosenOrg}
            farmMaps={fs_farmMaps}
            fieldMaps={fs_fieldMaps}
            impersonating={impersonating}
            setChosenFields={fs_setChosenFields}
            setChosenOrg={fs_setChosenOrg}
            setFarmMaps={fs_setFarmMaps}
            setFieldMaps={fs_setFieldMaps}
            setSelectedFields={fs_setSelectedFields}
          />

          {/* Commodity Year selection box */}
          { fs_selectedFields?.size > 0 && selectYear() }

          {/* Buttons to load data, integrate, and upload precision or CLU data */}
          {authenticated && (
            <Box p={1} width={286} display="flex" flexDirection="column" alignItems="center">
              <CustomToolTip
                title={
                  fs_selectedFields?.size < 1 ?
                    'Select at least one field to load data' 
                  : selectedFieldsAreLoaded ?
                    'Reload data: Data for the current commodity year and all the selected fields HAS already been loaded'
                  :
                    'Load new data: Data for the current commodity year and all the selected fields HAS NOT yet been loaded'
                  }
                placement="top"
              >
                <Box bgcolor={selectedFieldsAreLoaded ? "#ffffff" : ""}>
                  <Button
                    variant={selectedFieldsAreLoaded ? "outlined" : "contained"}
                    color="primary"
                    size="large"
                    onClick={() => handleLoadingData()}
                    disabled={loading || fs_selectedFields?.size < 1}
                    disableElevation
                  >
                    <span>Load Acreage Data</span>
                    <AssessmentOutlinedIcon style={{ marginLeft: 4 }} />
                  </Button>
                </Box>
              </CustomToolTip>

              <Divider flexItem style={{ height: 1, marginTop: 4 }} />
              <Box mt="4px" bgcolor="#ffffff">
                <Button
                  color="primary"
                  variant="outlined"
                  disableElevation
                  onClick={(e) => setOpenIntegration(true)}
                  style={{ width: 230 }}
                >
                  Connect Precision Ag
                </Button>
              </Box>

              {/* Only available if user has chosen an organization */}
              <CustomToolTip
                title={chosenOrg.id !== -1 ? 'Upload data for your chosen organization' : 'First, load acreage data for an organization'}
                placement="top"
              >
                <Box mt={1} bgcolor="#ffffff">
                  <Button
                    color="primary"
                    variant="outlined"
                    disableElevation
                    onClick={(e) => setUploadPrecisionFile(true)}
                    style={{ width: 230 }}
                    disabled={chosenOrg.id === -1}
                  >
                    Upload Precision Data
                  </Button>
                </Box>
              </CustomToolTip>

              {/* We do not want the user to be able to change Agent and CLU Data Settings once they have loaded data as that could cause problems when saving and loading again later (because we would use different settings) */}
              {!gartPath && (
                <CustomToolTip 
                  title={
                    useOwnCLUData !== '' ? 'While using another user\'s CLU data, you cannot use your own, uploaded CLU data. To change this, open the Settings in the top-right.'
                    : initialLoadComplete ? 'Access the initial screen to upload more CLU boundaries. To do this, open the Settings in the top-right and click "Reset Page". Any changes not saved will be lost.'
                    : ''
                  }
                  placement="top"
                >
                  <Box my={1} bgcolor="#ffffff">
                    <Button
                      color="primary"
                      variant="outlined"
                      disableElevation
                      onClick={(e) => setShowCLUFileUpload(true)}
                      style={{ width: 230 }}
                      disabled={useOwnCLUData !== '' || initialLoadComplete || loading}
                    >
                      <PublishIcon style={{ marginRight: 10 }} />
                      Upload CLU
                    </Button>
                  </Box>
                </CustomToolTip>
              )}
            </Box>
          )}
        </Box>
      </Slide>

      {/* Body according to current step selection */}
      {step === 0 ? (
        <Box className={classes.step} height={chosenHeight}>
          {!initialLoadComplete ? (
            <Overview
              gartPath={gartPath}
              loading={loading}
              setAgentPortalOpen={setAgentPortalOpen}
            />
          ) : (
            // Only display the operation data box if there is an error or there is data to display
            <Box display="flex" flexDirection="column" flexGrow={1}>
              { viewOperations() }
            </Box>
          )}
        </Box>
      ) : step === 1 ? (
        <Box className={classes.step} height={chosenHeight}>
          <CreateReviewPage
            checkForIntersections={checkForIntersections}
            checkValidInput={checkValidInput}
            CLUSummary={CLUSummary}
            clusSeen={clusSeen}
            commoditiesSeen={commoditiesSeen}
            commodityTypesSeen={commodityTypesSeen}
            gartPath={gartPath}
            getIntersections={getIntersections}
            operationSummary={operationSummary}
            setCLUSummary={setCLUSummary}
            setSummaryExpanded={setSummaryExpanded}
            statesSeen={statesSeen}
            summaryExpanded={summaryExpanded}
            updateCLU={basicUpdateIntersection}
          />
        </Box>
      ) : step === 2 ? (
        <Box className={classes.step} height={chosenHeight}>
          <FinalPage
            chosenOrg={chosenOrg}
            clusSeen={clusSeen}
            CLUSummary={CLUSummary}
            commodityYear={commodityYear}
            commodityYears={commodityYears}
            enterPersonalName={enterPersonalName}
            enterTaxID={enterTaxID}
            gartPath={gartPath}
            identifier={identifier}
            loading={loading}
            name={name}
            operationSummary={operationSummary}
            printedName={printedName}
            reinsuranceYear={reinsuranceYear}
            reportName={reportName}
            setEnterPersonalName={setEnterPersonalName}
            setEnterTaxID={setEnterTaxID}
            setIdentifier={setIdentifier}
            setLoading={setLoading}
            setName={setName}
            setPdfOpen={setPdfOpen}
            setPrintedName={setPrintedName}
            setReinsuranceYear={setReinsuranceYear}
            setReportName={setReportName}
            setSignature={setSignature}
            setSignatureTimeStamp={setSignatureTimeStamp}
            zoneType={zoneType}
          />
        </Box>
      ) : (
        <Box className={classes.step} height={chosenHeight}>
          <ViewSubmitted />
        </Box>
      )}
    </Box>
  );

  /**
   * Handles changing to the given step, if appropriate
   * @param {Number} section step to change to
   * @returns {void}
   */
  const changeStep = (section) => {
    // Whatever happens, you can always switch to (setup page / pick the farms/fields)
    if (section === 0) {
      // Set this to show dates in operation section of table
      setRefreshTableDates(true);
      setShowFieldSelection(true);
      setStep(0);
    }
    // If user wants to see acreage report, etc., user needs to be logged in first
    else if (authenticated) {
      // If trying to switch to step 1, make sure some data is loaded first
      if (!orderedMappings.length) {
        if (section === 1) {
          enqueueSnackbar(`Please setup your acreage report before reviewing your ${gartPath ? 'Plantings' : 'CLUs'}`);
        } else if (section === 2) {
          enqueueSnackbar('Please setup your acreage report before finalizing your report');
        } else if (section === 9) {
          // Viewing submitted reports required nothing user than being logged in
          setStep(section);
          setShowFieldSelection(false);
        }
      } else {
        setShowFieldSelection(false);
        setStep(section);
      }
    } else {
      setLoginPromptOpen(true);
      setToContinuePrompt('Sign in to continue using the tool');
    }
  };
  //#endregion

  // General layout of interface and modals
  const arInterface = () => (
    <Box width="100%">
      {/* Steps selection bar */}
      <AppBar
        position="static"
        color="transparent"
        style={{ boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.05)' }}
      >
        <Box className={classes.appBar}>
          <Steps
            authenticated={authenticated}
            changeSection={changeStep}
            gartPath={gartPath}
            initialLoadComplete={initialLoadComplete}
            loading={loading}
            mobileBreakPoint={mobileBreakPoint}
            openFileDownload={toggleReportFileDownload}
            openFileUpload={toggleReportFileUpload}
            section={step}
            setSettingsOpen={setSettingsOpen}
            successfullyLoadedData={successfullyLoadedData}
          />
        </Box>
      </AppBar>

      {/* Actual steps JSX */}
      <Box className={classes.body}>
        {stepsDisplay()}
      </Box>

      {/* // -------------------- FOOTER AND MODALS -------------------- */}
      {/* Footer component */}
      {user?.isAuthenticated && (
        <Footer
          changeStep={changeStep}
          gartPath={gartPath}
          handleNewPlanting={handleNewPlanting}
          lastSaved={lastSaved}
          loading={loading}
          org={chosenOrg}
          checkYearForSave={checkYearForSave}
          savingReport={savingReport}
          setPdfOpen={setPdfOpen}
          setShowFieldSelection={setShowFieldSelection}
          showFieldSelection={showFieldSelection}
          showFooterInfo={successfullyLoadedData}
          step={step}
        />
      )}

      {/* Sign In, Create Account, and Connect Precision Ag Modals */}
      <Connect
        open={loginPromptOpen}
        setOpen={setLoginPromptOpen}
        message={toContinuePrompt}
        signInFrom="SIGNINFROMACREAGE"
      />

      <IntegrateModal
        open={openIntegration}
        setOpen={setOpenIntegration}
      />

      {/* Settings Modal */}
      {settingsOpen && (
        <Settings
          gartPath={gartPath}
          includeNonCLUBoundaries={includeNonCLUBoundaries}
          initialLoadComplete={initialLoadComplete}
          loading={loading}
          refreshData={initialLoad}
          resetPage={resetPage}
          resetWarning={setOpenResetWarning}
          setEditAccessesOpen={setEditAccessesOpen}
          setIncludeNonCLUBoundaries={setIncludeNonCLUBoundaries}
          setSettingsOpen={setSettingsOpen}
          settingsOpen={settingsOpen}
          setUseOrgCLUData={setUseOrgCLUData}
          setUseOwnCLUData={setUseOwnCLUData}
          showSettingsInfo={successfullyLoadedData}
          useOrgCLUData={useOrgCLUData}
          useOwnCLUData={useOwnCLUData}
        />
      )}

      {/* Modal for confirming reset of data from settings */}
      { openResetWarning && (
        <ResetWarning
          warningOpen={openResetWarning}
          setWarningOpen={setOpenResetWarning}
          resetData={resetData}
        />
      )}

      {/* Modal for editing who has access to user's CLU data */}
      { editAccessesOpen && (
        <EditCLUAccesses
          editAccessesOpen={editAccessesOpen}
          setEditAccessesOpen={setEditAccessesOpen}
        />
      )}

      {/* Modal for tracking explanations for changing planting date and acres */}
      {openExplanation && (
        <ChangeExplanation
          open={openExplanation}
          setOpen={setOpenExplanation}
          explanations={explanations}
          updateOperation={basicUpdateOperation}
          updateIntersection={basicUpdateIntersection}
          operation={operationToEdit}
          providingExplanationFor={providingExplanationFor}
        />
      )}

      {/* Confirmation modal for choosing to lose or save data */}
      { openConfirm && (
        <ConfirmationPopup
          getFieldsEarliestSavedDate={getFieldsEarliestSavedDate}
          load={matchFieldSelectionInputs}
          open={openConfirm}
          orderedMappings={orderedMappings}
          save={checkYearForSave}
          savingReport={savingReport}
          setOpen={setOpenConfirm}
        />
      )}

      {/* Confirmation modal for choosing whether to save data for the new vs. loaded year */}
      { openSaveYearWarning && (
        <SaveYearWarning
          commodityYear={commodityYear}
          open={openSaveYearWarning}
          saveReportData={saveReportData}
          saveWithOldYear={saveWithOldYear}
          setOpen={setOpenSaveYearWarning}
          yearDataLoaded={yearDataLoaded.current}
        />
      )}

      {/* Modal for when editting CLU */}
      { Object.keys(operationToEdit).length > 0 && Object.keys(cluBoundary).length > 0 && openMap && (
        <EditMap
          clu={cluBoundary}
          cluFullBoundary={clusSeen[cluBoundary.clu_identifier].cluShape}
          clusSeen={clusSeen}
          commoditiesSeen={commoditiesSeen}
          commodityTypesSeen={commodityTypesSeen}
          commodityYear={commodityYear}
          getCommodityTypes={getCommodityTypes}
          getIntendedUses={getIntendedUses}
          getIntersections={getIntersections}
          open={openMap}
          operation={operationToEdit}
          reinsuranceYear={reinsuranceYear}
          setClusSeen={setClusSeen}
          setCommoditiesSeen={setCommoditiesSeen}
          setCommodityTypesSeen={setCommodityTypesSeen}
          setOpen={setOpenMap}
          statesSeen={statesSeen}
          updateClu={basicUpdateIntersection}
          updateOperationInMapping={updateOperationInMapping}
          zoneType={zoneType}
        />
      )}

      {/* Modal for creating a new operation (normal or prevented planting) */}
      { openCreateNewOperation && (
        <CreateNewOperation
          addOperation={addOperation}
          allowCLUSelection={successfullyLoadedData}
          clusSeen={clusSeen}
          chosenOrg={chosenOrg}
          commoditiesSeen={commoditiesSeen}
          commodityTypesSeen={commodityTypesSeen}
          commodityYear={commodityYear}
          defaultValues={newOperationDefaults}
          gartPath={gartPath}
          getCommodityTypes={getCommodityTypes}
          getIntendedUses={getIntendedUses}
          handleNonCLUBoundary={handleNonCLUBoundary}
          impersonating={impersonating}
          includeNonCLUBoundaries={includeNonCLUBoundaries}
          open={openCreateNewOperation}
          organizations={organizations}
          preventedPlanting={newPreventedPlanting}
          setClusSeen={setClusSeen}
          setCommoditiesSeen={setCommoditiesSeen}
          setCommodityTypesSeen={setCommodityTypesSeen}
          setOpen={setOpenCreateNewOperation}
          statesSeen={statesSeen}
          useOrgCLUData={useOrgCLUData}
          useOwnCLUData={useOwnCLUData}
          viewingField={viewingField}
        />
      )}

      {/* Modal for uploading CLU files */}
      { showCLUFileUpload && (
        <CLUUploadModal
          impersonating={impersonating}
          open={showCLUFileUpload}
          organizations={organizations}
          selectedCLUOrg={selectedCLUOrg}
          setAgentPortalOpen={setAgentPortalOpen}
          setOpen={setShowCLUFileUpload}
          setSelectedCLUOrg={setSelectedCLUOrg}
          setUseOrgCLUData={setUseOrgCLUData}
          useOrgCLUData={useOrgCLUData}
        />
      )}

      {/* Modal for personal file upload */}
      { reportFileUploadOpen && (
        <ReportFileUpload
          open={reportFileUploadOpen}
          setOpen={toggleReportFileUpload}
          cropYear={commodityYear}
          operationID={fileOperationID}
        />
      )}

      {/* Modal for personal file download */}
      { reportFileDownloadOpen && (
        <ReportFileDownload
          cropYear={commodityYear}
          open={reportFileDownloadOpen}
          operationID={fileOperationID}
          operationInfo={fileOperationInfo}
          setOpen={toggleReportFileDownload}
        />
      )}

      {/* Modal for when user wants to upload a precision ag file */}
      {uploadPrecisionFile && (
        <UploadAgFile
          uploadFile={uploadPrecisionFile}
          setUploadFile={setUploadPrecisionFile}
          orgID={chosenOrg.id}
        />
      )}

      {/* Modal for agents to view and edit whose grower's data they have access to */}
      {agentPortalOpen && (
        <AgentPortalModal
          currentUser={user.email}
          impersonating={impersonating}
          loadSharedUsers={loadSharedUsers}
          open={agentPortalOpen}
          setImpersonating={setImpersonating}
          setOpen={setAgentPortalOpen}
          sharedUsers={sharedUsers}
        />
      )}

      {/* Modals for creating CART or GART PDF from Review or Final page */}
      {pdfOpen && (
        !gartPath ? (
          <CreateCARTPdf
            clusSeen={clusSeen}
            CLUSummary={CLUSummary}
            commoditiesSeen={commoditiesSeen}
            commodityTypesSeen={commodityTypesSeen}
            open={pdfOpen}
            pdfGeneratedFor={pdfGeneratedFor}
            printedName={printedName}
            reportName={reportName}
            setOpen={setPdfOpen}
            setPdfGeneratedFor={setPdfGeneratedFor}
            setReportName={setReportName}
            signature={signature}
            signatureTimeStamp={signatureTimeStamp}
            statesSeen={statesSeen}
          />
        ) : (
          <CreateGARTPdf
            clusSeen={clusSeen}
            CLUSummary={CLUSummary}
            commoditiesSeen={commoditiesSeen}
            commodityTypesSeen={commodityTypesSeen}
            open={pdfOpen}
            operationSummary={operationSummary}
            pdfGeneratedFor={pdfGeneratedFor}
            printedName={printedName}
            reportName={reportName}
            setOpen={setPdfOpen}
            setPdfGeneratedFor={setPdfGeneratedFor}
            setReportName={setReportName}
            signature={signature}
            signatureTimeStamp={signatureTimeStamp}
            statesSeen={statesSeen}
          />
        )
      )}

      {/* Loading Spinner */}
      {loading && <SpinningLoader />}
    </Box>
  );

  // -------------------- RETURN --------------------
  return (arInterface());

}
// END EXPORT ACREAGEREPORTING

AcreageReporting.propTypes = {
  impersonating: PropTypes.string.isRequired,
  setImpersonating: PropTypes.func.isRequired,
  sharedUsers: PropTypes.arrayOf(
    PropTypes.shape(
      {
        accepted: PropTypes.number.isRequired,
        owner_email_address: PropTypes.string.isRequired,
      },
    ),
  ).isRequired,
  loadSharedUsers: PropTypes.func.isRequired,
  setUseOrgCLUData: PropTypes.func.isRequired,
  useOrgCLUData: PropTypes.bool.isRequired,
};

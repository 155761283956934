import React, { useEffect, useState } from "react";
import { makeStyles, Button, Switch } from "@material-ui/core";
import { COLORS } from "../../../../../constants";
import InfoPopover from "../../../../Helpers/InfoPopover";
import ProfitByVarietyMatrixTable from "./ProfitByVarietyMatrixTable/VarietyProfitMatrix";
import Spinner from "../../../../Helpers/Spinner";
import { produce } from "immer";
import AdvancedControlsIcon from "../../../../../images/advanced-controls.svg";
import AdvancedControlsActiveIcon from "../../../../../images/advanced-controls-active.svg";
import FilterRow from "../FarmOverviewTableContainer/OverviewTableFilters"
import ToggleHeatmapIcon from "../../../../../images/toggle-heatmap.svg";
import ToggleHeatmapActiveIcon from "../../../../../images/toggle-heatmap-active.svg";
import VarietyAcresMatrixTable from "./VarietyAcresMatrixTable/VarietyAcresMatrix"

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 400,
    overflow: "auto",
  },
  titleContainer: {
    color: COLORS.darkGray,
    fontSize: 20,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 21,
  },
  matrixContainer: {},
  buttonGroup: ({ mobileView }) => ({
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    "& .MuiButton-root": {
      padding: 6,
    },
    width: 330,
    marginTop: mobileView ? "1rem" : "0 .5rem",
  }),
  button: ({ mobileView }) => ({
    color: COLORS.green,
    backgroundColor: COLORS.white,
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: "bold",
    border: `1px solid ${COLORS.green}`,
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "none",
    minWidth: 158,
    margin: mobileView ? 0 : "0 .5rem",
    "&.active": {
      backgroundColor: COLORS.green,
      color: COLORS.white,
    },
  }),
}));

const ProfitByVarietyMatrix = ({
  message,
  setMessage,
  state,
  setState,
  isLoaded,
  setIsLoaded,
  fields,
  tableControlsVariety,
  setTableControlsVariety,
  varietyOptions,
  setVarietyOptions,
  showVarietyAcreage,
  setShowVarietyAcreage,
  varieties,
  setVarieties,
  varietyAcresData,
  setVarietyAcresData,
  minMaxProfit,
  showDetails,
  setShowDetails
}) => {
  const classes = useStyles();

  return (
    <div className={classes.matrixContainer}>
      <div className={classes.wrapper}>
        <div className={classes.titleContainer}>
          Variety Breakdown ($ / acre)
          <InfoPopover text="A view of profitability across all fields" />

          <Button
            className={`${classes.button} ${tableControlsVariety.showHeatmap ? "active" : ""
              }`}
            onClick={() =>
              setTableControlsVariety(
                produce((draft) => {
                  draft.showHeatmap = !tableControlsVariety.showHeatmap;
                })
              )
            }
            variant="contained"
            style={{ marginLeft: !showDetails ? "350px" : "500px" }}
            startIcon={
              <img
                alt="toggle between table and the heat map"
                src={
                  tableControlsVariety.showHeatmap
                    ? ToggleHeatmapActiveIcon
                    : ToggleHeatmapIcon
                }
              />
            }
          >
            Toggle Heatmap
        </Button>
          {
            !showDetails && <Button
              className={`${classes.button} ${tableControlsVariety.showAdvancedControls ? "active" : ""
                }`}
              onClick={() =>
                setTableControlsVariety(
                  produce((draft) => {
                    draft.showAdvancedControls = !tableControlsVariety.showAdvancedControls;
                  })
                )
              }
              variant="contained"
              startIcon={
                <img
                  alt="open the advanced controls modal"
                  src={
                    tableControlsVariety.showAdvancedControls
                      ? AdvancedControlsActiveIcon
                      : AdvancedControlsIcon
                  }
                />
              }
            >
              Advanced Controls
            </Button>
          }

        </div>
        <div>
          <FilterRow options={varietyOptions} setOptions={setVarietyOptions} showDetails={showDetails} />
        </div>
        <div style={{ marginLeft: "0px" }}>
          <b>Summary</b>
          <Switch
            checked={showDetails}
            onChange={(e) => setShowDetails(e.target.checked)}
            name="checkedA"
            inputProps={{ "aria-label": "primary checkbox" }}
          />
          <b>Details</b>
        </div>
        {
          showDetails && <div style={{ marginLeft: "700px" }}>
            <b>Profit Per Acre</b>
            <Switch
              checked={showVarietyAcreage}
              onChange={(e) => setShowVarietyAcreage(e.target.checked)}
              name="checkedA"
              inputProps={{ "aria-label": "primary checkbox" }}
            />
            <b>Total Acreage Worked</b>
          </div>
        }

        {!isLoaded ? (
          <Spinner message={message} />
        ) : (
            !showDetails ? <ProfitByVarietyMatrixTable
              values={state}
              fields={fields}
              data={state}
              options={varietyOptions}
              setOptions={setVarietyOptions}
              showHeatmap={tableControlsVariety.showHeatmap}
            /> :
              <VarietyAcresMatrixTable
                values={state}
                varieties={varieties}
                setVarieties={setVarieties}
                data={state}
                fields={fields}
                varietyAcresData={varietyAcresData}
                setVarietyAcresData={setVarietyAcresData}
                minMaxProfit={minMaxProfit}
                showHeatmap={tableControlsVariety.showHeatmap}
                showVarietyAcreage={showVarietyAcreage}
              />
          )}
      </div>
    </div>
  );
};

export default ProfitByVarietyMatrix;

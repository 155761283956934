/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable require-jsdoc */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import { useSnackbar } from 'notistack';

// Map imports
import * as L from 'leaflet';
import * as turf from '@turf/turf';

// Functions
import {
  createCollection,
} from '../MapFunctions/fieldInteractions';

import { EditBoundary } from '../Toolbars/EditBoundary';
import { Tiles } from '../../../constants/Tiles';
import { costUnits } from '../../ProfitLayers/utils/conversions';

/**
 * Map interactions for fixing overlapping CLU boundaries for review page of Acreage Reporting.
 * Rendered in: AcreageReporting/Modals/FixOverlappingBoundaries.js
 * @param {Number} boundaryNo Number of boundary to edit 
 * @param {String} cluFullBoundary CLU complete boundary
 * @param {Array} colorsToUse Colors to use for selected boundaries
 * @param {Function} createFeature Formats boundary as a Feature object expected by turf
 * @param {Object} firstSubfield Information on first CLU subfield
 * @param {Function} getIntersectingAcres Calculates the acres of the intersection of 2 boundaries
 * @param {Function} getIntersections Checks whether there are any intersections between passed boundaries
 * @param {Function} handleBoundaryUpdate handles updating the appropriate boundary vars in parent component
 * @param {Function} handleTwoLayers Flag to set toolbar state
 * @param {Function} resetToolbar Function to reset the toolbar
 * @param {Object} secondSubfield Information on second CLU subfield
 * @param {Function} setBoundaryNo Function to change boundaryNo
 * @param {Function} setHandleTwoLayers Function to change handleTwoLayers
 * @param {Function} setUserHasClipped Function to change userHasClipped
 * @param {String} userHasClipped Holds info on which boundary has been clipped
 * @return {JSX} Map display
 */
export function UpdateOverlappingMap({
  boundaryNo,
  cluFullBoundary,
  colorsToUse,
  createFeature,
  firstSubfield,
  getIntersectingAcres,
  getIntersections,
  handleBoundaryUpdate,
  handleTwoLayers,
  resetToolbar,
  secondSubfield,
  setBoundaryNo,
  setHandleTwoLayers,
  setUserHasClipped,
  userHasClipped,
}) {
  let mapId = Math.floor(Math.random() * Math.floor(10000)).toString();
  const { enqueueSnackbar } = useSnackbar();

  // Map
  const [map, setMap] = useState(null);
  const [activeTool, setActiveTool] = useState('');

  // Boundary vars
  const previousBoundary = useRef(null);
  const [editedBoundary, setEditedBoundary] = useState(null);
  const [updatedBoundary, setUpdatedBoundary] = useState(null);
  const [handleFailure, setHandleFailure] = useState(false);
  const [doneUpdating, setDoneUpdating] = useState(false);

  // Layers
  const cluLayer = useRef(L.featureGroup(null));
  const firstAPPLayer = useRef(L.featureGroup(null));
  const [firstAPPColor, secondAPPColor] = colorsToUse;
  const secondAPPLayer = useRef(L.featureGroup(null));

  // To track which of the boundaries is being editted
  const currentLayer = useRef(null);


  // -------------- USE EFFECTS -------------- //
  useEffect(() => {
    if (map === null) {
      try {
        drawMap(mapId);
      } catch (error) {
        // Incase generate duplicate ID
        mapId = Math.floor(Math.random() * Math.floor(10000)).toString();
      }
    }
  }, [mapId, map]);

  useEffect(() => {
    if (firstSubfield && secondSubfield && map) {
      clearCluLayer();
      // Skip this for "fake CLUs"
      if (cluFullBoundary !== 'X') drawCluBoundary();

      clearAPPLayers();
      drawIntersectionBoundary(firstSubfield.boundary, firstAPPLayer.current, firstAPPColor, 0.3);
      drawIntersectionBoundary(secondSubfield.boundary, secondAPPLayer.current, secondAPPColor, 0.3);
      
      // Fitbounds differently for "fake CLUs"
      if (cluFullBoundary === 'X') {
        var bounds = L.latLngBounds(firstAPPLayer.current.getBounds());
        bounds.extend(secondAPPLayer.current.getBounds());

        map.fitBounds(bounds, {
          padding: [30, 30],
        });
      }
    }
  }, [firstSubfield, secondSubfield, map]);
  
  // Based on boundaryNo, update what is the currentLayer and update what is the previousBoundary based on whether based
  useEffect(() => {
    if (boundaryNo === 1) {
      const firstLayer = firstAPPLayer.current;
      currentLayer.current = {layer: firstLayer, color: firstAPPColor};
      previousBoundary.current = firstLayer.getLayers().length ? firstLayer.toGeoJSON() : firstSubfield.boundary;
      firstLayer.bringToFront();
    } else if (boundaryNo === 2) {
      const secondLayer = secondAPPLayer.current;
      currentLayer.current = {layer: secondLayer, color: secondAPPColor};
      previousBoundary.current = secondLayer.getLayers().length ? secondLayer.toGeoJSON() : secondSubfield.boundary;
      secondLayer.bringToFront();
    } else if (boundaryNo !== -1) {
      console.log("Something is wrong. boundaryNo should always be -1, 1 or 2.")
    }
  }, [boundaryNo]);

  useEffect(() => {
    if (editedBoundary) {
      updateBoundary(editedBoundary);
    }
  }, [editedBoundary]);

  // Update the correct boundary based on currentLayer
  useEffect(() => {
    if (updatedBoundary && map) {
      try {
        previousBoundary.current = updatedBoundary;
        drawIntersectionBoundary(updatedBoundary, currentLayer.current.layer, currentLayer.current.color);
      } catch (err) {
        console.error(err);
      }
    }
  }, [updatedBoundary, map]);

  // Make sure that updatedBoundary useEffect (above) has finished 
  // If drawBoundary does not finish running, then doneUpdating could not have the appropriate value and so this would never run...
  // Could set doneUpdating to something else like 'true' in those catches to know that they failed and still run this
  useEffect(() => {
    // console.log('handleFailure, doneUpdating :>> ', handleFailure, doneUpdating);
    if (handleFailure && doneUpdating) {
      // In case setUpdatedBoundary ran, remove layer from appropriate APP and map
      currentLayer.current.layer.removeLayer(updatedBoundary);
      if (doneUpdating !== true) map.removeLayer(doneUpdating);
      // Redraw boundary in case of bad cut or edit
      drawIntersectionBoundary(handleFailure, currentLayer.current.layer, currentLayer.current.color)
      // Also make sure to reset previousBoundary
      previousBoundary.current = handleFailure;
      
      // Reset vars
      setDoneUpdating(false);
      setHandleFailure(false);
    }
    // If just doneUpdating is true, then handleFailure isn't true because it wasn't an error that occured
    else if (doneUpdating) {
      setDoneUpdating(false);
    }
  }, [handleFailure, doneUpdating, map])


  // -------------- MAIN FUNCTIONALITY -------------- //
  const drawMap = (id) => {
    try {
      const mapboxTiles = L.tileLayer(
        Tiles.ESRIBASEMAP,
      );

      // Add map to div by Id, set any parameters and initial view, add mapbox layer
      const map = L.map(id, {
        dragging: !L.Browser.mobile,
        editable: true,
        editOptions: {
          lineGuideOptions: {
            opacity: 0,
          },
        },
      })
        .setView([41.016, -92.4083], 5)
        .addLayer(mapboxTiles);

      // draw controls
      map.pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawRectangle: true,
        dragMode: true,
        drawPolygon: true,
        cutPolygon: true,
        editPolygon: true,
        drawPolyline: true,
        deleteLayer: true,
      });

      setMap(map);
    } catch (err) {
      console.error(err);
    }
  };

  const drawIntersectionBoundary = (selectedSubfield, layer, color, opacity) => {
    try {
      drawBoundary(
        selectedSubfield,
        color,
        5,
        layer,
        null,
        setEditedBoundary,
        null,
        false,
        opacity,
      );
    } catch (err) {
      console.error(err);
    }
  };

  const clearAPPLayers = () => {
    firstAPPLayer.current.eachLayer((layer) => {
      map.removeLayer(layer);
    });
    secondAPPLayer.current.eachLayer((layer) => {
      map.removeLayer(layer);
    });
    firstAPPLayer.current.clearLayers();
    secondAPPLayer.current.clearLayers();
  };

  const clearCluLayer = () => {
    cluLayer.current.eachLayer((layer) => {
      map.removeLayer(layer);
    });
    cluLayer.current.clearLayers();
  };

  const drawCluBoundary = () => {
    drawBoundary(
      cluFullBoundary,
      '#8e24aa',
      2,
      cluLayer.current,
      null,
      null,
      null,
      true,
    );
  };

  const drawBoundary = (
    boundary,
    color,
    weight,
    layer,
    handleClick,
    boundaryEdit,
    toolTip,
    fitBounds,
    fillOpacity,
  ) => {
    // handleClick in this case is an object containing the function and value
    // to pass. Implemented this way to avoid passing layer up and for future
    // flexibility.
    // console.log('boundary, color, weight, layer, handleClick, boundaryEdit, toolTip, fitBounds, fillOpacity, :>> ', 
    //     boundary, color, weight, layer, handleClick, boundaryEdit, toolTip, fitBounds, fillOpacity,);

    const collection = createCollection(
      boundary,
      color,
      weight,
      layer,
      false,
      handleClick,
      boundaryEdit,
      finishCut,
      toolTip,
      fillOpacity,
    );
    // console.log('collection :>> ', collection);

    if (collection) {
      collection.addTo(map);

      if (fitBounds) {
        map.fitBounds(collection.getBounds(), {
          padding: [30, 30],
        });
      }
    }
    setDoneUpdating(collection);
  };

  const updateBoundary = async (boundary) => {
    let updatedRan = false;
    try {
      // Remove edited boundary that falls outside of CLU boundary
      // console.log('boundary.toGeoJSON(), JSON.parse(cluFullBoundary) :>> ', boundary.toGeoJSON(), JSON.parse(cluFullBoundary));
      const intersections = getIntersections(boundary.toGeoJSON().geometry, JSON.parse(cluFullBoundary));
      if (intersections.length) {
        // Convert to the appropriate geometry depending on number of Polygons
        const intersection = intersections.length > 1 ? turf.multiPolygon(intersections) : turf.polygon(intersections[0]);

        setUpdatedBoundary(intersection);
        updatedRan = true;
        const failed = handleBoundaryUpdate(true, intersection.geometry);
        if (failed) { throw 'handleBoundaryUpdate failed'; }
      } else {
        enqueueSnackbar('Your new boundary is invalid. This is likely caused by overlapping boundary points.');
        console.log(intersections);
        // Redraw boundary in case of bad cut or edit
        drawIntersectionBoundary(previousBoundary.current, currentLayer.current.layer, currentLayer.current.color)
      }
    } catch (e) {
      enqueueSnackbar('Your new boundary is invalid. This is likely caused by overlapping boundary points.');
      console.log('An error occured: ', e);

      // console.log('updatedRan, previousBoundary.current :>> ', updatedRan, previousBoundary.current);
      // Check updatedRan to see if we need to wait for setUpdatedBoundary to finish running before resetting
      if (!updatedRan) {
        setDoneUpdating(true);
      }
      setHandleFailure(previousBoundary.current);
    } finally {
      // Remove original layer from appropriate APP and map
      currentLayer.current.layer.removeLayer(boundary);
      map.removeLayer(boundary);
    }
  };

  // Will remove any intersecting areas from the underlying/other boundary.
  // For now, will not do it if doing so causes the other boundary to become a MultiPolygon 
  // Want to just setEditedBoundary and let updateBoundary take care of the rest but it doesn't seem like this is going to work...
  // Tool was built to only edit boundary we are on, but this would involve editting the other boundary so will prob need custom code just for clop function
  // And can just set things back to normal as soon as user clicks something else 
  const clip = () => {
    const firstLayer = firstAPPLayer.current;
    const secondLayer = secondAPPLayer.current;
    let boundary;
    
    try {
      if (boundaryNo === 1) {
        // console.log('secondLayer.getLayers() :>> ', secondLayer.getLayers());
        boundary = secondLayer.getLayers()[0];
        const secondShape = secondLayer.toGeoJSON().features[0];
        const firstShape = firstLayer.toGeoJSON().features[0];
        // console.log('secondShape :>> ', JSON.stringify(secondShape), secondShape);
        // console.log('firstShape :>> ', JSON.stringify(firstShape), firstShape);

        // Take what remains of the second boundary if the first is excluded
        const difference = turf.difference(secondShape, firstShape);
        const geometry = difference.geometry;
        // console.log('difference :>> ', JSON.stringify(difference), difference);
        if (difference) {
          if (geometry.type === 'MultiPolygon') {
            enqueueSnackbar('Error. Your new boundary would be invalid. The clipped shape cannot be a MultiPolygon.');

            // NOTE: The cases where this happens can also just be handled by using the cut tool. So, will rely on that for now.
            // const newCoordinatesArray = [];
            // // Make sure that this MultiPolygon actually has shapes that are of a significant size. If not, just remove them
            // for (const coordinates of geometry.coordinates) {
            //   // If not a rectangle, check the area
            //   // const area = turf.convertArea(turf.area(createFeature({type: 'Polygon', coordinates})), 'meters', 'acres');
            //   // console.log('area :>> ', area);

            //   // If it's a simple rectangle, check that both the width and length are significant enough
            //   var from = turf.point(coordinates[0]);
            //   var to = turf.point([-75.534, 39.123]);
            //   var options = {units: 'miles'};

            //   var distance = turf.distance(from, to, options);
            //   console.log('distance :>> ', distance);
            //   // if (area >= 0.01) {
            //   //   newCoordinatesArray.push(coordinates);
            //   // }
            // }

            // if (newCoordinatesArray.length === 0) {
            //   enqueueSnackbar('Error. Your new boundary would be invalid. You cannot clip with a shape that fully encompasses your other boundary.');
            // } else if (newCoordinatesArray.length === 1) {
            //   const revisedDifference = {type: 'Polygon', coordinates: newCoordinatesArray};
            //   drawIntersectionBoundary(revisedDifference, secondLayer, secondAPPColor);
            //   handleBoundaryUpdate(true, revisedDifference, 'second');
            // } else {
            //   enqueueSnackbar('Error. Your new boundary would be invalid. The clipped shape cannot be a MultiPolygon.');
            // }
          }
          else {
            // console.log('drawing new difference boundary');
            drawIntersectionBoundary(difference, secondLayer, secondAPPColor);
            handleBoundaryUpdate(true, geometry, 'second');
            setUserHasClipped('second');
            firstLayer.bringToFront();
            enqueueSnackbar('Successfully clipped underlying boundary');
          }
        } else {
          enqueueSnackbar('Error. Your new boundary would be invalid. You cannot clip with a shape that fully encompasses your other boundary.');
        }
      } else if (boundaryNo === 2) {
        boundary = firstLayer.getLayers()[0];
        const firstShape = firstLayer.toGeoJSON().features[0];
        const secondShape = secondLayer.toGeoJSON().features[0];

        // Take what remains of the first boundary if the second is excluded
        const difference = turf.difference(firstShape, secondShape);
        const geometry = difference.geometry;
        if (difference) {
          if (geometry.type === 'MultiPolygon') {
            enqueueSnackbar('Error. Your new boundary would be invalid. The clipped shape cannot be a MultiPolygon.');
          }
          else {
            // console.log('drawing new difference boundary');
            drawIntersectionBoundary(difference, firstLayer, firstAPPColor);
            handleBoundaryUpdate(true, geometry, 'first');
            setUserHasClipped('first');
            secondLayer.bringToFront();
            enqueueSnackbar('Successfully clipped underlying boundary');
          }
        } else {
          enqueueSnackbar('Error. Your new boundary would be invalid. You cannot clip with a shape that fully encompasses your other boundary.');
        }
      } else if (boundaryNo !== -1) {
        console.log("Something is wrong. boundaryNo should always be -1, 1 or 2.")
      }
    } 
    catch (e) {
      enqueueSnackbar('Your new boundary is invalid. This is likely caused by overlapping boundary points.');
      console.log('Error', e);
      // NOTE: Complete the catch part. handleBoundaryUpdate can fail.. handle that. Will just need to reset underlying boundary
      // // In case setUpdatedBoundary ran, remove layer from appropriate APP and map
      // if (intersection) {
      //   currentLayer.current.layer.removeLayer(intersection);
      //   map.removeLayer(intersection);
      // }
      // // Redraw boundary in case of bad cut or edit
      // drawIntersectionBoundary(previousBoundary.current, currentLayer.current.layer, currentLayer.current.color)
    } finally {
      // Only remove the boundary if another one was successfully added
      if (boundaryNo === 1 && secondLayer.getLayers().length > 1) {
        // Remove original layer from appropriate APP and map
        secondLayer.removeLayer(boundary);
        map.removeLayer(boundary);
      } else if (boundaryNo === 2 && firstLayer.getLayers().length > 1) {
        // Remove original layer from appropriate APP and map
        firstLayer.removeLayer(boundary);
        map.removeLayer(boundary);
      }
    }
  }

  // // Update intersecting boundary to be complete clu
  // const fillBoundary = () => {
  //   try {
  //     const geo = JSON.parse(cluFullBoundary);
  //     setUpdatedBoundary(cluFullBoundary);
  //     handleBoundaryUpdate(true, geo);
  //   } catch (e) {
  //     console.error('unable to update', e);
  //     enqueueSnackbar('Unable to update boundary');
  //   }
  // };

  // const resetBoundary = () => {
  //   try {
  //     if (boundaryNo === 1) {
  //       setUpdatedBoundary(firstSubfield.originalBoundary);
  //     } else if (boundaryNo === 2) {
  //       setUpdatedBoundary(secondSubfield.originalBoundary);
  //     } else { console.log("Something is wrong. boundaryNo should always be -1, 1 or 2."); }
  //     handleBoundaryUpdate(false, null);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const edit = () => {
    try {
      setActiveTool('edit');
      const layer = currentLayer.current.layer;
      // layer.bringToFront();
      layer.pm.enable();
    } catch (err) {
      console.error(err);
    }
  };

  const finishEdit = () => {
    try {
      setActiveTool('');
      const layer = currentLayer.current.layer;
      layer.pm.disable();
    } catch (err) {
      console.error(err);
    }
  };

  const cut = () => {
    setActiveTool('cut');
    const layer = currentLayer.current.layer;
    layer.bringToFront();
    // enable cutting mode
    map.pm.enableGlobalCutMode({
      allowSelfIntersection: false,
    });
  };

  const finishCut = () => {
    setActiveTool('');
    clearCluLayer();
    drawCluBoundary();
  };

  // For now, cancel is not available. 
  // If want to use it, see ClientApp\src\components\Maps\AcreageReporting\UpdateIntersection.js for how to
  // const cancel = () => {
  //   try {
  //     // map.pm.disableDraw();
  //     // map.pm.disableGlobalEditMode();
  //     map.pm.disableGlobalCutMode();
  //     setActiveTool('');
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <EditBoundary
        activeTool={activeTool}
        boundaryNo={boundaryNo}
        // cancel={cancel}
        clip={clip}
        colorsToUse={colorsToUse}
        // cut={cut}
        edit={edit}
        // fillBoundary={fillBoundary}
        finishEdit={finishEdit}
        handleTwoLayers={handleTwoLayers}
        // reset={resetBoundary}
        resetToolbar={resetToolbar}
        setBoundaryNo={setBoundaryNo}
        setHandleTwoLayers={setHandleTwoLayers}
      />

      <Box
        id={`${mapId}`}
        display="flex"
        flexGrow={1}
      />

    </Box>
  );
}

UpdateOverlappingMap.propTypes = {
  cluFullBoundary: PropTypes.string.isRequired,
  colorsToUse: PropTypes.array.isRequired,
  firstSubfield: PropTypes.object.isRequired,
  handleBoundaryUpdate: PropTypes.func.isRequired,
  handleTwoLayers: PropTypes.string.isRequired,
  resetToolbar: PropTypes.func.isRequired,
  secondSubfield: PropTypes.object.isRequired,
  setHandleTwoLayers: PropTypes.func.isRequired,
};

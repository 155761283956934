import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../constants";

const useStyles = makeStyles(() => ({
  wrapper: {
    height: 50,
    width: 50,
    position: "relative",
    "&:after": {
      content: '""',
      position: "absolute",
      height: 28,
      width: 28,
      backgroundColor: COLORS.lightGreen,
      borderRadius: "50%",
      top: 11,
      left: 11,
      zIndex: 1,
    },
  },
  svg: {
    height: "100%",
    width: "100%",
    borderRadius: "50%",
    transform: "rotate(-90deg)",
  },
  circle: {
    fill: COLORS.white,
    stroke: COLORS.green,
    strokeWidth: 32,
  },
}));

/**
 * Draws the profitablity pie chart
 * @param {Number} value percentage value
 */
const Pie = ({ value = 0.5 }) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <svg className={classes.svg} viewBox="0 0 32 32">
        <circle
          className={classes.circle}
          r="16"
          cx="16"
          cy="16"
          strokeDasharray={`${value}, 100`}
        />
      </svg>
    </div>
  );
};

export default Pie;

Pie.propTypes = {
  value: PropTypes.number.isRequired,
};

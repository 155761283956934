// -------------------- IMPORTS --------------------
// React
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// material-ui
import {
  Box,
  Button,
  Divider,
  TextField,
  Collapse,
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowDropUpIcon from '@material-ui/icons/ArrowDropUp';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

// Styling and helpful packages
import { blackText, grey } from '../../../styles/colors';
import { makeStyles } from '@material-ui/core/styles';
import useEventListener from '@use-it/event-listener';
import { cloneDeep } from 'lodash';
import * as _ from 'underscore';
import { useSnackbar } from 'notistack';

// Functionality
import { allCLUInfo, getAppropriateValue } from './reviewCLUsDisplay';
import { errorRed } from '../helpers';
import * as preset from '../presetData';
import { FixOverlappingBoundaries } from '../Modals/FixOverlappingBoundaries';
import { CluMap } from '../../Maps/AcreageReporting/ViewClu';
import { FieldMap } from '../../Maps/AcreageReporting/ViewOperation';
import { zoneColors } from '../../Maps/Styles/layerStyles';
import { CustomToolTip } from '../../../utils/customComponents';
import { exists } from '../../../utils/helpers';

// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  bolden: {
    fontWeight: 500,
  },
  cluHeader: {
    padding: '4px 16px 4px 0px',
    display: 'flex',
    alignItems: 'center',
    fontWeight: 500,
  },
  cluRow: {
    padding: '8px 16px 8px 0px',
    display: 'flex',
    alignItems: 'center',
  },
  disabledStyling: {
    backgroundColor: 'rgba(0, 0, 0, 0.05)',
  },
  icon: theme.plIcon,
  main: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    alignItems: 'center',
    color: blackText,
  },
  mapBox: {
    margin: '8px',
    height: 440,
    minWidth: 440,
    maxWidth: 440,
  },
  sectionContent: {
    display: 'flex',
    overflowX: 'auto',
  },
  stickyParts1: {
    position: 'sticky',
    left: '0',
    backgroundColor: theme.palette.greys.main,
  },
  stickyParts2: {
    position: 'sticky',
    left: '0',
    backgroundColor: theme.palette.greys.light,
  },
  stickyTitle: {
    position: 'sticky',
    left: '0',
    backgroundColor: 'rgba(157, 179, 156, 0.24)',
    padding: '4px 16px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: '18px',
    fontWeight: 500,
  },
  summaryColumn: {
    minWidth: '100px',
    maxWidth: '100px',
    margin: '0px 8px',
  },
  summaryBox: {
    minWidth: '165px',
    maxWidth: '165px',
    margin: '0px 8px',
  },
  wrappedCluRow: {
    padding: 8,
    display: 'flex',
    flexWrap: 'wrap',
    alignItems: 'flex-end',
    borderBottom: `1px solid ${theme.palette.greys.light}`,
  },
}));

// -------------------- MAIN FUNCTION --------------------
/**
 * User can review all CLUs on map and in tabel format
 * @param {Function} checkForIntersections Checks for and updates any intersecting subfields
 * @param {Function} checkValidInput Verifies whether passed input is valid
 * @param {Object} clusSeen Data relating to clus user has viewed
 * @param {Array} CLUSummary Summary of CLUs for 'CART'
 * @param {Object} commoditiesSeen Data relating to clus user has viewed
 * @param {Object} commodityTypesSeen Data relating to commodityTypes user has viewed
 * @param {Boolean} gartPath Denotes whether this interface instance is for creating GART files
 * @param {Function} getIntersections Checks whether there are any intersections between passed boundaries
 * @param {Array} operationSummary Summary of Operations for 'GART'
 * @param {Function} setCLUSummary To change state of CLUSummary object
 * @param {Object} statesSeen Data relating to states user has viewed
 * @param {Function} setSummaryExpanded Controls view expansion
 * @param {Array} summaryExpanded If view is expanded
 * @param {Function} updateCLU Updates CLU values with no sideeffects
 * @returns {JSX} Review page
 */
export function CreateReviewPage({
  checkForIntersections,
  checkValidInput,
  clusSeen,
  CLUSummary,
  commoditiesSeen,
  commodityTypesSeen,
  gartPath,
  getIntersections,
  operationSummary,
  setCLUSummary,
  setSummaryExpanded,
  statesSeen,
  summaryExpanded,
  updateCLU,
}) {
  // -------------------- VARIABLES --------------------
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [prefixToUse, setPrefixToUse] = useState('');
  const [lastExpanded, setLastExpanded] = useState(0);

  // To deal with overlapping boundaries
  const [currentCLUSectionIndex, setCurrentCLUSectionIndex] = useState(-1);
  const [openOverlappingBoundaries, setOpenOverlappingBoundaries] = useState(false);
  const [isRecentMapDrawn, setIsRecentMapDrawn] = useState(true);

  // Var of mappings to have the same code for all cvt types
  const seenCVTElements = { crop: statesSeen, cropType: commoditiesSeen, intendedUse: commodityTypesSeen };


  // -------------------- USE EFFECTS --------------------
  // Set prefixToUse to the correct value depending on gartPath
  useEffect(() => {
    if (exists(gartPath)) {
      setPrefixToUse(!gartPath ? 'CLUSummarySection' : 'OpSummarySection');
    }
  }, [gartPath])

  // React appropriately when "Fix Overlapping Boundaries" modal has closed
  useEffect(() => {
    // If the modal was just closed, rerun the intersection function for these subfields to make sure they were fixed
    if (currentCLUSectionIndex != -1 && !openOverlappingBoundaries) {
      const summaryCopy = cloneDeep(CLUSummary);
      const remaining = checkForIntersections(summaryCopy[currentCLUSectionIndex]);

      // If an error occured, let user know and do not change CLUSummary
      if (!remaining) {
        enqueueSnackbar('An error occured. One of your CLU subfield boundaries might have overlapping points.');
        return;
      }

      // Store the updated array
      // checkForIntersections should have only updated the intersectingSubfields key here so not updating orderedMappings should be fine
      setCLUSummary(summaryCopy);

      // If there is still an overlap remaining, let user know
      if (_.size(remaining)) {
        enqueueSnackbar('There are still remaining overlaps for this CLU');
      }
      // If all overlaps were fixed, let user know
      else {
        enqueueSnackbar('All overlaps fixed for this CLU');
      }
    }
  }, [openOverlappingBoundaries])


  // -------------------- FUNCTIONALITY --------------------
  /**
   * Given an index, unexpand current CLU, expand next CLU, and set it into focus
   * @param {Number} nextToExpand Section index to expand and focus
   * @param {String} direction Direction to expand
   * @returns {void}
   */
  function expandNextSection(nextToExpand, direction) {
    const summaryExpanded_copy = cloneDeep(summaryExpanded);
    summaryExpanded_copy[lastExpanded] = false;
    // For some reason, the nextToExpand change consistently happens first.
    // To limit rerendering, let's make sure the false change (unexpanded section) goes in first.
    setSummaryExpanded(summaryExpanded_copy);
    summaryExpanded_copy[nextToExpand] = true;
    setSummaryExpanded(summaryExpanded_copy);
    setLastExpanded(nextToExpand);

    // window.setTimeout(function () {
    //   document.getElementById(`${prefixToUse}${nextToExpand}`).focus();
    // }, 0);
    let block;
    if (direction === 'backwards') { block = 'start'; }
    else { block = 'end'; }
    window.setTimeout(() => {
      document.getElementById(`${prefixToUse}${nextToExpand}`).scrollIntoView({ behavior: 'smooth', block });
    }, 0);
  }

  /**
   * On tab press, decide which Section to expand next
   * @param {Object} event User event
   * @returns {void}
   */
  function findNextSection(event) {
    // console.log('key, shiftKey: ', event.key, event.shiftKey);
    if (event.key === 'Tab') {
      // Stop anything else from handling this event
      event.preventDefault();
      event.stopImmediatePropagation();
      let nextToExpand;
      let direction;

      // Move backwards
      if (event.shiftKey) {
        if (lastExpanded <= 0) { return; }
        nextToExpand = lastExpanded - 1;
        direction = 'backwards';
      }
      // Move forwards
      else {
        if ((!gartPath && lastExpanded >= CLUSummary.length - 1) || (gartPath && lastExpanded >= operationSummary.length - 1)) { return; }
        nextToExpand = lastExpanded + 1;
        direction = 'forwards';
      }

      expandNextSection(nextToExpand, direction);
    }
  }
  useEventListener('keydown', findNextSection);


  // Actually controls variable to modify expansion state of CLU or Operation Section
  const modifyExpanded = (index, expandedState) => {
    const summaryExpanded_copy = cloneDeep(summaryExpanded);
    summaryExpanded_copy[index] = expandedState;
    setSummaryExpanded(summaryExpanded_copy);

    if (expandedState) {
      setLastExpanded(index);
    }
  };

  /**
   * JSX for controlling when to hide/show a CLU's information.
   * @param {Number} cluIndex Index to expand or collapse
   * @returns {void}
   */
  const expansionControl = (cluIndex) => (
    (!summaryExpanded[cluIndex]
      ? (
        <ArrowDropDownIcon
          onClick={() => {
            modifyExpanded(cluIndex, true);
          }}
          style={{ cursor: 'pointer', fontSize: 50 }}
        />
      )
      : (
        <ArrowDropUpIcon
          onClick={() => {
            modifyExpanded(cluIndex, false);
          }}
          style={{ cursor: 'pointer', fontSize: 50 }}
        />
      )
    )
  );

  // Common code for getting the display value for an element's change reason code
  const handleChangeReason = (info, entry) => {
    const code = entry[info.source];
    const match = preset[info.link].filter((elem) => elem.code === code);

    if (match.length) {
      return match[0].display;
    }
    // If nothing was input by user, then enforcing required fields is not working well

    console.log('Something is wrong here. info.source should have a value if indicator is "Y" for info: ', { info }, ' | and entry: ', { entry });
    return '';
  };

  /**
   * Used when only one subfield/operation is present. Instead of scrolling, wrap data.
   * Takes CLU/operation object from an array. Displays info about object.
   * @param  {Object} entry Entry from array
   * @return {JSX} Wrapped row
   */
   const createSingleRow = (entry) => (
    <Box>
      {!gartPath ? (
        <Box m={1} display="flex">
          {/* subfieldNumber and Operation information */}
          <Box mx={1} minWidth={88}>
            <Box className={classes.bolden}>
              Subfield
            </Box>
            <Divider />
            <Box mt={1} whiteSpace="nowrap" display="flex" justifyContent="center" fontSize="1.1rem">
              <FiberManualRecordIcon
                style={{ color: zoneColors[entry.colorIndex], marginRight: 8, marginLeft: -13 }}
              />
              <span>{entry.subfieldNumber}</span>
            </Box>
          </Box>

          <Box mx={1}>
            <Box className={classes.bolden}>
              Planting Info
            </Box>
            <Divider />
            <Box mt={1} fontSize="1.1rem">
              {`Farm: ${entry.farmName} | Field: ${entry.fieldName} | Planting: ${entry.operationTitle}`}
            </Box>
          </Box>
        </Box>
      ) : (
        <Box m={1} display="flex">
          {/* Operation color and information */}
          <Box mx={1} minWidth={88}>
            <Box className={classes.bolden}>
              Color
            </Box>
            <Divider />
            <Box mt={1} whiteSpace="nowrap" display="flex" justifyContent="center" fontSize="1.1rem">
              <FiberManualRecordIcon
                style={{ color: zoneColors[entry.colorIndex] }}
              />
            </Box>
          </Box>

          <Box mx={1}>
            <Box className={classes.bolden}>
              Planting Info
            </Box>
            <Divider />
            <Box mt={1} fontSize="1.1rem">
              {entry.operationTitle}
            </Box>
          </Box>
        </Box>
      )}

      <Box className={classes.wrappedCluRow}>
        {/* All the data columns - here, data is gotten and formatted as needed */}
        {allCLUInfo.map((info, index) => {
          // Skip CLU item if 'GART' zoneType
          if (gartPath && info.source === 'cluProducerReviewRequestIndicator') { return; }

          // Vars that will be filled differently depending on type
          const [value, endAdornment, inputComponent] = getAppropriateValue(
            info, entry, seenCVTElements, clusSeen, handleChangeReason, enqueueSnackbar
          );

          // Display the TextField
          return (
            <Box key={index} width={165} margin={0.5}>
              <Box className={classes.bolden}>
                {info.header}
              </Box>
              <TextField
                variant="outlined"
                value={value}
                inputProps={{
                  style: {
                    padding: 14,
                    textAlign: 'center',
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment,
                  inputComponent,
                  className: classes.disabledStyling,
                  style: {
                    color: blackText,
                  },
                }}
                disabled
              />
            </Box>
          );
        })}
      </Box>
    </Box>
  );

  /**
   * Takes Object from an array and it's index. Displays info about object for table.
   * @param  {Object} entry Entry from array
   * @param  {Number} entryIndex Index of entry row in containing section
   * @return {JSX} Table row
   */
  const createRow = (entry, entryIndex) => (
    // It is fine for these keys to just be the indices as the order will never change and everything is associated to the object anyways
    <>
      <Box className={classes.cluRow}>
        {/* subfieldNumber or color */}
        {!gartPath ? (
          <Box whiteSpace="nowrap" className={classes.summaryColumn} display="flex" justifyContent="center" alignItems="center">
            <FiberManualRecordIcon style={{ color: zoneColors[entry.colorIndex], marginRight: '15px', marginLeft: '-30px' }} />
            <span>{entry.subfieldNumber}</span>
          </Box>
        ) : (
          <Box whiteSpace="nowrap" className={classes.summaryColumn} display="flex" justifyContent="center" alignItems="center">
            <FiberManualRecordIcon style={{ color: zoneColors[entry.colorIndex] }} />
          </Box>
        )}

        {/* Operation information */}
        <Box className={classes.summaryBox}>
          <CustomToolTip key={entryIndex} placement="right" title={!gartPath ? entry.linkInfo : entry.operationTitle}>
            <TextField
              variant="outlined"
              value={`${!gartPath ? `Farm: ${entry.farmName} | Field: ${entry.fieldName} | ` : ''}Planting: ${entry.operationTitle}`}
              inputProps={{
                style: {
                  padding: 14,
                  textAlign: 'center',
                },
              }}
              // disabled class would come after a class declared here so color still wouldn't change in that case
              // so declared separately in style
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                className: classes.disabledStyling,
                style: {
                  color: blackText,
                },
              }}
              disabled
            />
          </CustomToolTip>
        </Box>

        {/* All the data columns - here, data is gotten and formatted as needed */}
        {allCLUInfo.map((info, index) => {
          // Skip CLU item for 'GART' zoneType
          if (gartPath && info.source === 'cluProducerReviewRequestIndicator') { return; }

          // Vars that will be filled differently depending on type
          const [value, endAdornment, inputComponent] = getAppropriateValue(
            info, entry, seenCVTElements, clusSeen, handleChangeReason, enqueueSnackbar
          );

          // Display the TextField
          return (
            <Box key={index} className={classes.summaryBox}>
              <TextField
                variant="outlined"
                value={value}
                inputProps={{
                  style: {
                    padding: 14,
                    textAlign: 'center',
                  },
                }}
                // eslint-disable-next-line react/jsx-no-duplicate-props
                InputProps={{
                  endAdornment,
                  inputComponent,
                  className: classes.disabledStyling,
                  style: {
                    color: blackText,
                  },
                }}
                disabled
              />
            </Box>
          );
        })}
      </Box>
    </>
  );

  const createCLUSection = (cluInfo, cluIndex) => {
    // If this section is not representing a "fake" CLU, check to see if any of its subfields have been expanded with "orphaned boundaries"
    const fakeCLU = cluInfo[0].clu_identifier === 'X';
    let foundExpandedSubfield = false;

    // Create a copy so as to not alter the original's order
    const cluSortedInfo = [...cluInfo];
    // Sort so larger subfields are drawn first and to keep sorted indexes - the same objects are referenced so they should also be modified
    cluSortedInfo.sort((a, b) => b.acres - a.acres);
    for (const [index, cluEntry] of cluSortedInfo.entries()) {
      cluEntry.colorIndex = index;

      if (!fakeCLU && !foundExpandedSubfield && cluEntry.section === -1) {
        foundExpandedSubfield = true;
      }
    }

    return (
      <Box
        key={cluIndex}
        id={`CLUSummarySection${cluIndex}`}
        tabIndex={-1}
        display="flex"
        mb={2}
        width="100%"
      >

        {/* CLU Section Info */}
        <Box
          height="min-content"
          width="100%"
        >
          <Box
            className={`${classes.stickyTitle}`}
            onClick={() => modifyExpanded(cluIndex, !summaryExpanded[cluIndex])}
          >
            <Box display="flex" alignItems="center">
              {(() => {
                // All CLU copies have the same info so just use the first copy each time
                const cluRef = clusSeen[cluInfo[0].clu_identifier];

                return (
                  `Farm # ${cluRef.farm_number} | Tract # ${cluRef.tract_number} | CLU # ${cluRef.clu_number}`
                );
              }).call(this)}

              {/* For CLUs modified/created by nonCLUBoundaries, notify user that something is different*/}
              {/* For "fake CLUs", give an explanation of why things seem different and why EditMap is disabled */}
              {fakeCLU && (
                <CustomToolTip placement="right" title={'These subfields did not intersect with any CLUs and so have limited functionality. These boundaries will be included in PDF reports but WILL NOT be included in report downloads or submissions to USDA. For more functionality, please upload CLU boundaries that intersect with these subfields\' boundaries.'}>
                  <InfoOutlinedIcon style={{marginLeft: 16, color: "red"}}/>
                </CustomToolTip>
              )}
              {/* For modified CLUs, let them know that boundary has been altered and how to fix */}
              {foundExpandedSubfield && (
                <CustomToolTip placement="right" title={'At least one of this CLU\'s subfield\'s boundary extends past this CLU\'s boundary. This has happened because your uploaded CLU boundaries do not fully cover the associated planting\'s boundary. Boundaries that signficantly extend past the CLU boundary will likely not be accepted by USDA. To resolve this, please upload CLU boundaries that cover your planting\'s entire boundary.'}>
                  <InfoOutlinedIcon style={{marginLeft: 16, color: "red"}}/>
                </CustomToolTip>
              )}
            </Box>

            {/* Show a message if these CLU subfields contain overlapping boundaries */}
            {_.size(cluInfo[0].intersectingSubfields) > 0 && (
              <Button
                variant="outlined"
                onClick={(event) => {
                  event.stopPropagation();
                  setCurrentCLUSectionIndex(cluIndex);
                  setOpenOverlappingBoundaries(true);
                }}
                disableElevation
                style={{
                  color: errorRed,
                  borderColor: errorRed,
                  backgroundColor: '#ffffff',
                }}
              >
                <span>Fix Overlapping Boundaries</span>
              </Button>
            )}

            {expansionControl(cluIndex)}
          </Box>

          {/* summaryExpanded stores expansion state for now */}
          <Collapse
            in={summaryExpanded[cluIndex]}
            unmountOnExit
            mountOnEnter
          >
            <Box className={classes.sectionContent}>
              {/* CLU Map */}
              <Box className={classes.mapBox}>
                <CluMap
                  clu={cluSortedInfo}
                  completeBoundary={clusSeen[cluInfo[0].clu_identifier].cluShape}
                  isRecentMapDrawn={isRecentMapDrawn}
                  setIsRecentMapDrawn={setIsRecentMapDrawn}
                />
              </Box>

              {/* CLU info */}
              <Box>
                {cluInfo.length === 1 ? (
                  createSingleRow(cluInfo[0])
                ) : (
                  <>
                    {/* CLU Headers and then Rows */}
                    <Box className={classes.cluHeader}>
                      <Box className={classes.summaryColumn}>
                        Subfield
                      </Box>
                      <Box className={classes.summaryBox}>
                        Planting Info
                      </Box>
                      {allCLUInfo.map((info, index) => {
                        return (
                          <Box key={index} className={classes.summaryBox}>
                            {info.header}
                          </Box>
                        )
                      })}
                    </Box>
                    <Divider className={classes.stickyParts1} />

                    {/* Show all the CLU subfields */}
                    {cluInfo.map((cluEntry, entryIndex) => (
                      <Box key={entryIndex}>
                        {createRow(cluEntry, entryIndex)}
                        <Divider className={classes.stickyParts2} />
                      </Box>
                    ))}
                  </>
                )}
              </Box>
            </Box>
          </Collapse>

        </Box>
      </Box>
    );
  };

  const createOperationSection = (opInfo, opIndex) => {
    // Create a copy so as to not alter the original's order
    const fieldSortedInfo = [...opInfo];
    // Sort so larger subfields are drawn first and to keep sorted indexes - the same objects are referenced so they should also be modified
    fieldSortedInfo.sort((a, b) => b.acres - a.acres);
    for (const [index, opEntry] of fieldSortedInfo.entries()) {
      opEntry.colorIndex = index;
    }

    return (
      <Box
        key={opIndex}
        id={`OpSummarySection${opIndex}`}
        tabIndex={-1}
        display="flex"
        mb={2}
        width="100%"
      >

        {/* Operation Section Info */}
        <Box
          height="min-content"
          width="100%"
        >
          <Box
            className={classes.stickyTitle}
            onClick={() => modifyExpanded(opIndex, !summaryExpanded[opIndex])}
          >
            Farm: {opInfo[0].farmName} | Field: {opInfo[0].fieldName}

            {expansionControl(opIndex)}
          </Box>

          {/* summaryExpanded stores expansion state for now */}
          <Collapse
            in={summaryExpanded[opIndex]}
            unmountOnExit
            mountOnEnter
          >
            <Box className={classes.sectionContent}>
              {/* Operations Map */}
              <Box className={classes.mapBox}>
                <FieldMap
                  field={fieldSortedInfo}
                />
              </Box>

              {/* Operation info */}
              <Box>
                {opInfo.length === 1 ? (
                  createSingleRow(opInfo[0])
                ) : (
                  <>
                    {/* Operation Headers and then Rows */}
                    <Box className={classes.cluHeader}>
                      <Box className={classes.summaryColumn}>
                        Color
                      </Box>
                      <Box className={classes.summaryBox}>
                        Planting Info
                      </Box>
                      {allCLUInfo.map((info, index) => {
                        // Skip CLU item for 'GART' zoneType
                        if (gartPath && info.source === 'cluProducerReviewRequestIndicator') { return; }

                        return (
                          <Box key={index} className={classes.summaryBox}>
                            {info.header}
                          </Box>
                        )
                      })}
                    </Box>
                    <Divider className={classes.stickyParts1} />

                    {/* Show all the Field's Operations */}
                    {opInfo.map((entry, entryIndex) => {
                      return (
                        <Box key={entryIndex}>
                          {createRow(entry, entryIndex)}
                          <Divider className={classes.stickyParts2} />
                        </Box>
                      )
                    })}
                  </>
                )}
              </Box>
            </Box>
          </Collapse>

        </Box>
      </Box>
    );
  };
  
  /**
   * Handles the display for the 3rd page/screen of Acreage Reporting.
   * This lets the user review their information by CLUs. This is a lot more similar to how the information will be stored and transmitted or downloaded.
   * @returns {JSX} Review page
   */
   const reviewPage = () => (
    <>
      <Box
        textAlign="center"
        fontSize={20}
        pt={3}
        pb={2}
      >
        <Box className={classes.bolden}>
          Review your {!gartPath ? 'CLUs by farm and tract' : 'Plantings by farm and field'}
        </Box>
        <Box fontSize="smaller">
          Instructions: Press
          {' '}
          <em>tab</em>
          {' '}
          to easily go to the next {!gartPath ? 'CLU' : 'field'} and
          {' '}
          <em>shift + tab</em>
          {' '}
          to go to the previous one
        </Box>
      </Box>
      <Divider />

      <Box
        className={classes.main}
        textAlign="center"
      >
        {/* {console.log(CLUSummary)} */}
        {!gartPath ?
          CLUSummary.map((cluInfo, cluIndex) => createCLUSection(cluInfo, cluIndex))
        :
          operationSummary.map((opInfo, opIndex) => createOperationSection(opInfo, opIndex))
        }
      </Box>
    </>
  );

  // Similar to basicUpdateIntersection, update CLUSummary
  const updateCLUSummarySection = (updates, subfieldIndex, passedSummary) => {
    const summary = cloneDeep(passedSummary || CLUSummary);
    // Get CLU to update from indexes
    // eslint-disable-next-line max-len
    const toUpdate = summary[currentCLUSectionIndex][subfieldIndex];

    updates.forEach((update) => {
      const values = Object.entries(update);
      const modify = values[0][0];
      const value = values[0][1];
      // checkValidInput is mainly kept to verify finalReportedAcreage
      if (checkValidInput(modify, value)) {
        // eslint-disable-next-line prefer-destructuring
        toUpdate[modify] = value;
      }
    });

    // Update the CLUSummary var
    setCLUSummary(summary);
    // If this is the second (or greater) call to this, then make sure to redraw map with updates
    if (passedSummary) { setIsRecentMapDrawn(false); }
    return summary;
  };

  // -------------------- RETURN --------------------
  return (
    <Box width="100%">
      {/* Section description, map, and all CLU Info */}
      { reviewPage() }

      { openOverlappingBoundaries
        && (
        <FixOverlappingBoundaries
          cluFullBoundary={clusSeen[(CLUSummary[currentCLUSectionIndex][0]).clu_identifier].cluShape}
          cluSection={CLUSummary[currentCLUSectionIndex]}
          cluSectionIndex={currentCLUSectionIndex}
          getIntersections={getIntersections}
          open={openOverlappingBoundaries}
          setOpen={setOpenOverlappingBoundaries}
          updateCLU={updateCLU}
          updateCLUSummarySection={updateCLUSummarySection}
        />
        )}
    </Box>
  );
}

CreateReviewPage.propTypes = {
  checkValidInput: PropTypes.func.isRequired,
  CLUSummary: PropTypes.array.isRequired,
  clusSeen: PropTypes.object.isRequired,
  commoditiesSeen: PropTypes.object.isRequired,
  commodityTypesSeen: PropTypes.object.isRequired,
  setCLUSummary: PropTypes.func.isRequired,
  statesSeen: PropTypes.object.isRequired,
  setSummaryExpanded: PropTypes.func.isRequired,
  summaryExpanded: PropTypes.array.isRequired,
  updateCLU: PropTypes.func.isRequired,
};

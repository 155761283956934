import * as fr from "../../../utils/farmReportDataFetchers";
import { exists, formatDate } from "../../../utils/helpers";
import { getIndividualCropPrice } from "../../../utils/dataFetchers";

export async function getHarvestForFarm(orgId, farmId, year) {
  try {
    let harvestOps = await fr.getFarmOperations(orgId, farmId, year, "Harvest");
    //console.log('Seeding Ops', harvestOps)
    let map = await getHarvestMapData(harvestOps);
    return map;
  } catch (err) {
    console.log("err", err);
  }
}

export const getHarvestMapData = async (harvestOps, setMessage) => {
  try {
    let map = new Map();
    let cropPriceMap = new Map();

    for (let op of harvestOps.data) {
      op.totalUnit = op.varietyYieldUnit;
      if (op.price === null || op.price.toString() === '0') {
        //console.log('harvest price', op, op.price)
        if (exists(op.crop) && exists(op.totalUnit)) {
          // get price if name and unit are available
          let cropSeason = new Date(op.endDate).getFullYear();
          if(cropPriceMap.has(op.crop + "-" + op.totalUnit + "-" + cropSeason)){
            op.price = cropPriceMap.get(op.crop + "-" + op.totalUnit + "-" + cropSeason)
          }
          else{
            setMessage("Fetching harvest crop price...");
            const cropPrice = await getIndividualCropPrice(
              op.crop,
              op.totalUnit,
              cropSeason
            );
  
            // PRICE RETURNED FOR HARVEST
            op.price = cropPrice;
            cropPriceMap.set(op.crop + "-" + op.totalUnit + "-" + cropSeason, op.price);
          }          
        } else {
          op.price = 0;
        }
      }

      op = {
        ...op,
        fileType: "HARVEST",
        cellsize: "0.0001",
        attribute: "VRYIELDVOL",
        totalMaterial: op.varietyYield,
        area:
          op.varietyArea === null &&
          op.varietyArea === 0 &&
          op.summaryArea === null &&
          op.summaryArea === 0
            ? op.fieldAcres
            : op.varietyArea,
        operationCost:
          op.area > 0 ? (op.varietyYield * op.price) / op.varietyArea : 0,
        price: op.price,
        productName: op.crop,
        unit: op.totalUnit,
        date: formatDate(op.endDate),
        quantity:
          op.varietyYield !== null
            ? op.varietyYield
            : op.summaryYieldRate * op.area,
      };
      if (!map.get(op.fieldID)) map.set(op.fieldID, []);
      let data = map.get(op.fieldID);
      //console.log(data)
      data.push(op);
      map.set(op.fieldID, data);
    }
    //console.log('harvest map', map)
    return map;
  } catch (err) {
    console.log("error formatting harvest data", err);
  }
};

export const combineHarvestOps = (ops) => {
  const operations = [];

  for (const op of ops) {
    const match = operations.filter((x) => x.operationID === op.operationID);

    if (match.length > 0) {
      const index = operations.indexOf(match[0]);
      operations[index].varieties.push(op);

      operations[index].hidden = checkHidden(operations[index].varieties);
    } else {
      const operation = {
        varieties: [op],
        date: op.date,
        hidden: op.isDeleted,
        name: op.productName,
        operationID: op.operationID,
        source: op.source,
      };

      operations.push(operation);
    }
  }

  return operations;
};

const checkHidden = (varieties) => {
  // if any varieties are deleted (hidden), hide operation
  const hidden = varieties.filter((x) => x.isDeleted);
  return hidden.length > 0;
};

/* eslint-disable no-plusplus */
/* eslint-disable require-jsdoc */
//#region - imports
// React
import React, { useContext, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom';
import ReactGA from 'react-ga';

// Components/Pages
import { AcreageHome } from './components/AcreageReporting/AcreageHome';
import { AerialPrecisionImagery } from './components/AerialPrecisionImagery/AerialPrecisionImagery';
import { APIDocumentation } from './components/VendorResponse/APIDocumentation';
import { ComingSoon } from './components/Home/ComingSoon';
import { ConnectAccount } from './components/Shared/ConnectAccount';
import { DataLayers } from './components/DataLayers/DataLayers';
import { Digitize } from './components/Digitize/Digitize';
import { Digs } from './components/Digs/Digs';
import { DigsResults } from './components/Digs/Results/DigsResults';
import { DoNotCall } from './components/DoNotCall/DoNotCall';
import { DroneAnalysis } from './components/DroneAnalysis/DroneAnalysis';
import { EditProfile } from './components/Profile/EditProfile';
import { FieldManager } from './components/FieldManager/FieldManager';
import { Esri } from './components/Pricing/esri/Esri';
import { IntegrationInvite } from './components/Shared/IntegrationInvite';
import { IntegrationPage } from './components/Partners/IntegrationPage';
import { IntegrationThanks } from './components/Shared/IntegrationThanks';
import { LoanUnderwriting } from './components/LoanUnderwriting/LoanUnderwriting';
import { ManageSubscription } from './components/ManageSubscription/ManageSubscription';
import { Navbar } from './components/Nav/Nav';
import { NotFound } from './components/Shared/NotFound';
import { OrganizationPreferences } from './components/Profile/OrganizationPreferences';
import { Press } from './components/PressRelease/Press';
import { PriceConverter } from './components/PriceTool/PriceTool';
import { Pricing } from './components/Pricing/profitlayers/PurchasePL';
import { ProfitLayers } from './components/ProfitLayers/ProfitLayers';
import ReportContainer from './components/ReportContainer';
import { ReportDisplay } from './components/DroneAnalysis/Reports/ReportDisplay';
import { Services } from './components/Home/Services';
import { SimpleOrder } from './components/Digs/SimpleOrder';
import { SoilTesting } from './components/SoilTesting/SoilTesting';
import { SoilTestingResults } from './components/SoilTesting/Results/SoilTestingResults';
import { TermsAndConditions } from './components/Home/TermsAndConditions';
import { UserContext } from './components/Context/UserContext';
import { VendorResponse } from './components/VendorResponse/VendorResponse';

// Helper functions/components
import {
  createClient,
  createNewFarm,
  createNewOrganization,
  fetchOrganizations,
  getNewGUID,
  getNewOrgID,
  getPreferredOrgs,
  isAuthenticated,
  isIntegrated,
  validateToken,
} from './utils/dataFetchers';
import { Endpoints } from './constants/Endpoints';
import RouteChangeTracker from './utils/RouteChangeTracker';

// Useful packages
import { useSnackbar } from 'notistack';

//#endregion

export function App() {
  //#region - variables
  // console.log(Endpoints.GA_TRACKING_CODE)
  ReactGA.initialize(Endpoints.GA_TRACKING_CODE);

  const [user, setUser] = useContext(UserContext);
  const [section, setSection] = useState(-1);
  const { enqueueSnackbar } = useSnackbar();

  const [inviteOpen, setInviteOpen] = useState(false);

  // set profit layers to setup tab from another page
  const [plStep, setPlStep] = useState(0);
  const [dataLayersStep, setDataLayersStep] = useState(0);

  const [integrating, setIntegrating] = useState(false);
  
  const defaultSection = 'datalayers';
  const sectionMapping = {
    'profitlayers': 0,
    'datalayers': 1,
    'fieldmanager': 2,
    'services': 3,
    'purchase': 4,
    'pricing': 4
  }
  const [pageNotFound, setPageNotFound] = useState(false);
  //#endregion

  //#region - use Effects
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    let url = document.URL.split('?');
    let tokenExists = false;
    let gonnaCallUserInfo = false;

    // handling query string items
    if (url.length > 1) {
      // split url should never be more than 1 unless we put 2 '?' in url
      // if that happens take the first paramatr or loop?
      const extension = url[1];
      if (extension === 'SignUp=true') {
        gonnaCallUserInfo = true;
        createDefaultOrg();
        // remove the querystring from url
        url.pop();
        window.history.replaceState({}, document.title, url[0]);
      } else if (extension.includes('init=1')) {
        // console.log("NEW INTEGRATION")
        // Make sure we are on the PL screen here after integration
        setSection(0);

        // have some sort of loader here? maybe timed for like 5 seconds?
        url.pop();
        // window.history.replaceState({}, document.title, url[0])
        setIntegrating(true);
        // User will have already viewed info modal if integrated
        sessionStorage.setItem('viewed', JSON.stringify({ profitLayersInfo: true }));
      } else if (extension.includes('token')) {
        tokenExists = true;
        const urlParams = new URLSearchParams(extension);
        const token = urlParams.get('token');
        // need to make sure token is valid before moving on....
        callValidateToken(token);
      }
    }

    // Get user info when appropriate
    if (!tokenExists && !gonnaCallUserInfo) {
      getUserInfo();
    }

    // handle custom routing for items in sectionMapping
    // this is being done to allow for direct links to specific pages while still being able to switch around without redirects
    // split by '/' and then decide what to show based on last item
    const whatToShow = shouldShowNavComponents();
    // Do nothing if not appropriate
    if (pageNotFound || !whatToShow) {
      return;
    }
    // If it's just to the root of the site, then show DL or
    // If just show one of the items in the nav bar
    else {
      setSection(sectionMapping[whatToShow]);
    }
  }, []);

  // Do not select a section if user navigated to a nonexistent page
  // Have it here and in above useEffect so whichever runs last sets section correctly
  useEffect(() => {
    if (pageNotFound) {
      setSection(-1);
    }
  }, [pageNotFound])
  //#endregion

  //#region - functions
  async function getUserInfo() {
    try {
      const data = await isAuthenticated();
      if (data === undefined || data === null) {
        setUser({ email: null, isAuthenticated: false, organizations: [] });
      } else {
        let integrateData = await isIntegrated();
        integrateData = integrateData === undefined ? { status: 'Fail' } : integrateData;
        const userIntegrated = integrateData.status === 'Success' ? integrateData.integrated : false;
        const preferredOrgs = await getPreferredOrgs();
        const orgs = await fetchOrganizations();
        const finalOrgs = [];
        if (orgs && orgs?.length > 0) {
          for (let i = 0; i < orgs.length; i++) {
            const record = preferredOrgs.filter((org) => org.ID === orgs[i].ID);
            // Below code should match code in OrganizationPreferences.js
            // If org has been added to [UserPreferredOrganizations] table, then check its status there to decide whether to add it or not. If not, just add it
            if (!record.length || (record.length && record[0].IsPrefered)) {
              finalOrgs.push(orgs[i]);
            }
          }
        }
        setUser({
          email: data.EmailId,
          isAuthenticated: data.Data,
          isIntegrated: userIntegrated,
          organizations: finalOrgs,
          allOrgs: orgs,
          firstname: data.FirstName,
          lastname: data.LastName,
          city: data.City,
          preferredOrgs,
        });
      }
    } catch (err) {
      setUser({ email: null, isAuthenticated: false, organizations: [] });
      console.error('APP.JS failed in getUserInfo', err);
    }
  }

  async function createDefaultClient(orgId) {
    const newId = await getNewGUID('Clients');
    await createClient(newId, 'Default-Client', orgId, 'Ag-Analytics');
  }

  async function createDefaultFarm(orgId) {
    const newId = await getNewGUID('Farms');
    await createNewFarm(newId, 'Default-Farm', orgId, 'Ag-Analytics');
  }

  async function createDefaultOrg() {
    const newID = await getNewOrgID('Organizations');
    await createNewOrganization(newID, 'Default', 'Ag-Analytics');

    // create default client and farm for org and update user info
    createDefaultClient(newID);
    createDefaultFarm(newID);
    getUserInfo();
  }

  const callValidateToken = async (token) => {
    // send token to backend to make sure still valid (within allowed timeframe)
    const valid = await validateToken(token);
    if (valid) {
      const updatedUser = { ...user };
      updatedUser.token = token;
      updatedUser.isAuthenticated = true;
      sessionStorage.clear();
      setUser(updatedUser);
    } else {
      enqueueSnackbar('Token invalid');
    }
  };

  const shouldShowNavComponents = () => {
    // First four should always be something like: [ "https:", "", "app.profit.ag", "app" ]
    const url = document.URL.split("/");
    const tail = url[url.length-1].toLowerCase();
    const beforeTail = url[url.length-2].toLowerCase();
    
    // Just fail if URL is nonsensically long or not one of the navbar ones
    if (url.length > 6) {
      return false
    } else if (
      (!tail && (beforeTail == 'app' || beforeTail == 'localhost:3000')) ||
      tail === 'app'
    ) {
      return defaultSection;
    } else if (
      (!tail && Object.keys(sectionMapping).includes(beforeTail)) ||
      Object.keys(sectionMapping).includes(tail)
    ) {
      return tail || beforeTail;
    } else {
      return false;
    }
  }
  //#endregion

  return (
    <Router basename="/app">
      {/* Navbar and other components not directly tied to route */}
      <Navbar
        openInviteModal={setInviteOpen}
        pageNotFound={pageNotFound}
        section={section}
        setSection={setSection}
        shouldShowNavComponents={shouldShowNavComponents}
      />
      <IntegrationInvite open={inviteOpen} setOpen={setInviteOpen} />
      <RouteChangeTracker />

      {/* Every page to be displayed should be inside this div and Switch */}
      <div style={{ paddingTop: '56px' }}>
        <Switch>
          {/* Pages linked in steps */}
          <Route exact path="/(ProfitLayers|DataLayers|FieldManager|Services|Purchase|Pricing)?">
            {section === 0 ? (
              <ProfitLayers
                plStep={plStep}
                integrating={integrating}
                setSection={setSection}
                setDataLayersStep={setDataLayersStep}
              />
            ) : section === 1 ? (
              <DataLayers
                setSection={setSection}
                setPlStep={setPlStep}
                dataLayersStep={dataLayersStep}
              />
            ) : section === 2 ? (
              <FieldManager
                setSection={setSection}
                setPlStep={setPlStep}
                digitizing={false}
                setDataLayersStep={setDataLayersStep}
              />
            ) : section === 3 ? (
              <Services setSection={setSection} />
            ) : section === 4 ? (
              <Pricing setSection={setSection} />
            ) : (
              // Show nothing if it is not an exact match for the section
              <>
              </>
            )}
          </Route>

          {/* Services links */}
          (
            <Route exact path="/AcreageReporting">
              <AcreageHome setSection={setSection} />
            </Route>
            {/* NOTE: "AerialPrecisionImagery was meant to be another imagery partner, but it did not work out." -Tim 12/06/2021 */}
            {/* As it stands, the AerialPrecisionImagery component is basically the same as the DroneAnalysis component. Will redirect to /UAV for now. */}
            {/* <Route exact path="/AerialPrecisionImagery">
              <AerialPrecisionImagery setHomeSection={setSection} />
              <Redirect to="/UAV"/>
            </Route> */}
            <Route exact path="/UAV">
              <DroneAnalysis setHomeSection={setSection} />
            </Route>
            <Route path="/UAV/Reports">
              <ReportDisplay />
            </Route>
            <Route exact path="/WaterManagement">
              <Digs setHomeSection={setSection} />
            </Route>
            <Route exact path="/Digs">
              <Digs setHomeSection={setSection} />
            </Route>
            <Route exact path="/ADS">
              <Digs setHomeSection={setSection} ads />
            </Route>
            <Route path="/WaterManagement/Results">
              <DigsResults setHomeSection={setSection} />
            </Route>
            <Route path="/Digs/Results">
              <DigsResults setHomeSection={setSection} />
            </Route>
            <Route path="/DigsServices">
              <SimpleOrder ads={false} />
            </Route>
            <Route path="/ADSServices">
              <SimpleOrder ads />
            </Route>
            <Route exact path="/DigsComplete">
              <Digs hideNav />
            </Route>
            <Route exact path="/DigsComplete/Map">
              <Digs hideNav />
            </Route>
            <Route exact path="/ADSComplete">
              <Digs hideNav ads />
            </Route>
            <Route exact path="/ADSComplete/Map">
              <Digs hideNav ads />
            </Route>
            <Route exact path="/Soil">
              <SoilTesting setHomeSection={setSection} />
            </Route>
            <Route exact path="/Soil/Overview">
              <SoilTesting setHomeSection={setSection} startingStep={0} />
            </Route>
            <Route path="/Soil/Results">
              <SoilTestingResults setHomeSection={setSection} />
            </Route>
            <Route exact path="/VendorResponse">
              <VendorResponse setHomeSection={setSection} />
            </Route>
          )

          {/* Other links */}
          (
            <Route exact path="/APIDocumentation">
              <APIDocumentation />
            </Route>
            <Route exact path="/ConnectAccount">
              <ConnectAccount setSection={setSection} />
            </Route>
            <Route exact path="/Digitize">
              <Digitize />
            </Route>
            <Route exact path="/DoNotCall">
              <DoNotCall />
            </Route>
            <Route exact path="/EditProfile">
              <EditProfile setSection={setSection} />
            </Route>
            <Route exact path="/Esri/Purchase">
              <Esri />
            </Route>
            <Route exact path="/Example/PressBeta">
              <Press />
            </Route>
            <Route exact path="/FarmReport">
              <ReportContainer reportType="farm" />
            </Route>
            <Route exact path="/Integrate">
              <IntegrationPage setSection={setSection} />
            </Route>
            <Route exact path="/IntegrationThanks">
              <IntegrationThanks setSection={setSection} />
            </Route>
            <Route exact path="/Land">
              <LoanUnderwriting />
            </Route>
            <Route exact path="/ManageSubscription">
              <ManageSubscription />
            </Route>
            <Route exact path="/MarketReport">
              <ReportContainer reportType="market" />
            </Route>
            <Route exact path="/OrganizationPreferences">
              <OrganizationPreferences setSection={setSection} />
            </Route>
            <Route exact path="/PriceConverter/:pageSource?">
              <PriceConverter />
            </Route>
            <Route exact path="/TermsAndConditions">
              <TermsAndConditions setHomeSection={setSection} />
            </Route>
          )

          {/* Not found page */}
          {/* This needs to be the last ROUTE so only displays when nothing else matches */}
          <Route>
            <NotFound
              setPageNotFound={setPageNotFound}
            />
          </Route>
        </Switch>
      </div>
    </Router>
  );
}

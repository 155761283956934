// -------------------- IMPORTS --------------------
// React
import React, { useEffect, useState } from 'react';

// material-ui
import {
	Box,
	Button,
	CircularProgress,
	Divider,
	Modal,
	Typography
} from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { darkText } from '../../../styles/colors';

// API calls
import {
	getUploadedFiles,
} from '../../../utils/dataFetchers';
import { downloadFile } from '../../../utils/helpers';
import { Endpoints } from "../../../constants/Endpoints";

// other components
import { DownloadOperationFiles } from '../Functionality/DownloadOperationFiles';

// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
	body: {
		height: 'auto',
		width: '100%',
		fontSize: 16,
		color: theme.palette.text.black,
	},
	fileName: {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
		maxWidth: 560,
	},
	head: {
		height: '50px',
		padding: '20px',
		alignItems: 'center',
		width: '100%',
		color: darkText,
		fontSize: 20,
		fontWeight: 500,
		display: 'flex',
	},
	icon: {
		...theme.icon,
	},
	paper: {
		...theme.modalPaper,
		position: 'absolute',
		height: 'auto',
		maxWidth: '700px',
	},
	row: {
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'space-between',
		marginBottom: 16,
		width: '100%',
		height: 46,
		fontSize: 16,
	},
}));


// -------------------- MAIN FUNCTION --------------------

/**
 * Rendered in AcreageReporting\AcreageReporting.js
 * User will be able to view and download uploaded files from this modal
 * @param {Number} cropYear Year crop was planted
 * @param {Boolean} open Determine if modal is opened or closed
 * @param {String} operationID Operation ID
 * @param {Object} operationInfo object containing information about the operation
 * @param {Function} setOpen Sets if modal is open
 * @return {JSX} Modal for viewing/downloading files
 */
export function ReportFileDownload({
	cropYear,
	open,
	operationID,
	operationInfo,
	setOpen,
}) {
	// -------------------- VARIABLES --------------------
	const classes = useStyles();
	const [fileInfo, setFileInfo] = useState([]);
	const [loading, setLoading] = useState(true);


	// -------------------- USE EFFECTS --------------------
	useEffect(() => {
		getFilesInBlob();
	}, [])


	// -------------------- FUNCTIONALITY --------------------
	const getFilesInBlob = async () => {
		const response = await getUploadedFiles(cropYear, operationID);

		// Set fileInfo to the proper state depending on response
		if (!Array.isArray(response)) {
			setFileInfo(['error', 'An error occured while attempting to retrieve your uploaded files. You might be logged out. Please refresh and try again.']);
		} else if (!response.length) {
			const operationClause = operationID ? ' and this operation.' : '.';
			setFileInfo(['error', 'You have not uploaded any files for this crop year' + operationClause]);
		} else {
			// If user uploads a file with the same name as one that already exists, the old one will get overwritten but it doesn't remove old entry from DB table
			// So, only show unique files here
			const responseSet = new Set();
			const uniqueResponses = [];
			response.map((elem) => {
				if (!responseSet.has(elem.fileName)) {
					responseSet.add(elem.fileName);
					uniqueResponses.push(elem);
				}
			})

			setFileInfo(uniqueResponses);
		}
		setLoading(false);
	}



	const modalJSX = () => (
		<Modal
			open={open}
			onClose={() => setOpen(false)}
			style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
		>
			<Box
				className={classes.paper}
				borderRadius="borderRadius"
			>
				{/* X icon */}
				<Box display="flex" justifyContent="flex-end">
					<HighlightOffIcon
						className={classes.icon}
						style={{ position: 'absolute', marginTop: '10px' }}
						onClick={() => setOpen(false)}
					/>
				</Box>

				{/* Modal Title */}
				<Box className={classes.head}>
					View and Download Uploaded Files
				</Box>

				{/* Modal Body */}
				<Box className={classes.body}>
					<Divider />
					<Box
						display="flex"
						flexDirection="column"
						alignItems="center"
						padding="16px 20px 0px"
						maxHeight="80vh"
						style={{ overflowY: "auto" }}
					>
						{fileInfo.length > 0 ? (
							fileInfo[0] === 'error' ? (
								<Box className={classes.row}>
									{fileInfo[1]}
								</Box>
							) : (
								// Here, map the name and a download button for each of the found uploaded files
								fileInfo.map((file) => (
									<Box key={file.fileName} className={classes.row} p={0}>
										<Typography className={classes.fileName}>
											{file.fileName}
										</Typography>

										<Button
											// className={classes.button}
											variant="contained"
											color="primary"
											onClick={async () => {
												// Encode the characters in case any of them need to be escaped for URL
												const path = encodeURIComponent(file.blobPath);
												const name = encodeURIComponent(file.fileName);
												const downloadLink = `${Endpoints.BASEURL}${Endpoints.DOWNLOADFILEFROMBLOB}?blobPath=${path}&fileName=${name}`;
												downloadFile(downloadLink, file.fileName);
											}}
										>
											<GetAppIcon />
										</Button>
									</Box>
								))
							)
						) : (
							// loading var is not actually needed as this is in the else condition
							// loading && <CircularProgress disableShrink/>
							<CircularProgress disableShrink style={{ marginBottom: 16 }} />
						)}
					</Box>
				
					<DownloadOperationFiles
						operationInfo={operationInfo}
					/>
				</Box>
			</Box>
		</Modal>
	)


	// -------------------- RETURN --------------------
	return (modalJSX());
}
import React, { useState } from "react";
import PropTypes from "prop-types";
import { makeStyles, Slider, withStyles } from "@material-ui/core";
import { COLORS } from "../../../../../../constants";

const CustomSlider = withStyles({
  root: {
    color: COLORS.green,
    height: 7,
  },
  thumb: {
    width: 0,
    height: "24px",
    border: `5px solid ${COLORS.green}`,
    backgroundColor: "currentColor",
    borderRadius: "5px",
    marginLeft: -2.5,
    marginRight: 0,
    marginTop: -10,
  },
  active: {},
  track: {
    height: 7,
  },
  rail: {
    color: "#d8d8d8",
    opacity: 1,
    height: 7,
  },
})(Slider);

const useStyles = makeStyles(() => ({
  brushContainer: {
    height: "25px",
    width: "100%",
    position: "relative",
  },
}));

/**
 * custom component for slider handle
 * @param {object} props with styles, etc
 * @returns {component} custom markup
 */
function CustomThumbComponent(props) {
  return <span {...props} />;
}

/**
 * Handles the filtered range in the filtered results in advanced controls.
 * @param {array} value array of values
 * @param {function} updateFilterRange to update filtered range
 */

const Brush = ({ value, updateFilterRange, initialValues }) => {
  const classes = useStyles();
  const [localValue] = useState(value);
  const handleChange = (evt, newValue) => {
    updateFilterRange(newValue);
  };

  return (
    <div className={classes.brushContainer}>
      <CustomSlider
        ThumbComponent={CustomThumbComponent}
        onChange={handleChange}
        getAriaLabel={(index) => (!index ? "Minimum range" : "Maximum range")}
        min={initialValues[0]}
        max={localValue[1]}
        value={value}
      />
    </div>
  );
};

export default Brush;

Brush.propTypes = {
  value: PropTypes.array.isRequired,
  updateFilterRange: PropTypes.func.isRequired,
};

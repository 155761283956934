import { Box, Select, MenuItem, Input, Chip, Checkbox, ListItemText } from '@material-ui/core';
import React, { useState, useEffect, useContext, useRef } from 'react';
import { MenuProps } from "../../../../styles/select";
import { CustomToolTip } from "../../../../utils/customComponents";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { makeStyles } from '@material-ui/core/styles';
import CustomSelect from "../../../Helpers/CustomSelect";
import { randomNormal } from "d3-random";
import * as fr from "../../../../utils/farmReportDataFetchers";
import SeasonSelect from "../../../Helpers/SeasonSelect";
import { COLORS, MONTHS } from "../../../../constants";
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
  chips: theme.chips,
  chip: theme.chip,
  infoToolTip: theme.infoToolTip,
  actionsContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    flexWrap: "wrap",
  },
  innerActionsContainer: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "1rem",
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
    color: COLORS.darkGray,
    display: "flex",
    alignItems: "center",
    marginTop: ".4rem",
    marginBottom: ".2rem",
  },
}));

export const randomY = randomNormal(17, 5);

export function MultiYearGraphs({
  multiYearHarvestData,
  multiYearNutrientData,
  multiYears,
  multiYearCrops,
  allFieldsInfo,
  profitSummaries
}) {
  //console.log("MONTHS.map(x => x.active)", MONTHS.filter(x => x.active === true).map(x => x.id))
  const classes = useStyles();

  const [selectedCrop, setSelectedCrop] = useState(multiYearCrops.length > 0 ? multiYearCrops[0] : "");
  const [selectedYears, setSelectedYears] = useState(multiYears.length > 0 ? multiYears : [])
  const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
  const [selectedMonths, setSelectedMonths] = useState(MONTHS.filter(x => x.active === true).map(x => x.id))
  const [seasons, setSeasons] = useState(MONTHS);
  const [selectedField,setSelectedField] = useState(allFieldsInfo.length > 0 ? allFieldsInfo[0].FieldID : "")
  const [years, setYears] = useState(multiYears);

  const [precipMapData, setPrecipMapData] = useState(new Map());
  const [gddMapData, setGddMapData] = useState(new Map());

  const [firstVariableOptions, setFirstVariableOptions] = useState([
    { id: "Yield", label: "Yield (bu./acre)", active: true },
    { id: "Profit", label: "Profit ($/ac)", active: true },
  ]);
  const activeFirstVariableOption = firstVariableOptions.find(
    (option) => option.active
  );

  const [secondVariableOptions, setSecondVariableOptions] = useState([
    { id: "Precipitation", label: "Precipitation (inches)", active: true },
    { id: "GDD", label: "GDD", active: true },
    { id: "Nutrients", label: "Nutrients (lbs/ac)", active: true },
  ]);
  const activeSecondVariableOption = secondVariableOptions.find(
    (option) => option.active
  );
  const [yUnit, setYUnit] = useState("");

  const [nutrientOptions, setNutrientOptions] = useState([
    { id: "Nitrogen", label: "Nitrogen", active: true },
    { id: "Sulfur", label: "Sulfur", active: false },
    { id: "Calcium", label: "Calcium", active: false },
    { id: "Phosphorus", label: "Phosphorus", active: false },
    { id: "Copper", label: "Copper", active: false },
    { id: "Cobalt", label: "Cobalt", active: false },
    { id: "Potassium", label: "Potassium", active: false },
    { id: "Molybdenum", label: "Molybdenum", active: false },
    { id: "Manganese", label: "Manganese", active: false },
    { id: "Boron", label: "Boron", active: false },
    { id: "Zinc", label: "Zinc", active: false },
    { id: "Magnesium", label: "Magnesium", active: false },
    { id: "Iron", label: "Iron", active: false },
    { id: "Chlorine", label: "Chlorine", active: false },
  ]);

  const nutrientVariableOption = nutrientOptions.find(
    (option) => option.active
  );

  const [data, setData] = useState(null)

  // useEffect(() => {
  //   if(activeFirstVariableOption.id === "Yield"){
  //     if(multiYearHarvestData !== null){
  //       let cropHYears = multiYearHarvestData.filter(x=> x.CropName === selectedCrop && x.FieldID === selectedField)
  //       //console.log("cropHYears", cropHYears)
  //       if(cropHYears.length > 0){
  //         setYears([...new Set(cropHYears.map(x => x.CropSeason))])
  //         setSelectedYears([...new Set(cropHYears.map(x => x.CropSeason))])
  //       }
  //       else{
  //         setYears([])
  //         setSelectedYears([])
  //       }
  //     }
  //   }
  //   else{
  //     if(profitSummaries !== null && profitSummaries.data.length > 0){
  //       let profitYears = profitSummaries.data.filter(x=> x.fieldID === selectedField)
  //       //console.log("cropHYears", cropHYears)
  //       if(profitYears.length > 0){
  //         setYears([...new Set(profitYears.map(x => x.year))])
  //         setSelectedYears([...new Set(profitYears.map(x => x.year))])
  //       }
  //       else{
  //         setYears([])
  //         setSelectedYears([])
  //       }
  //     }
  //   }
    
  // }, [activeFirstVariableOption, multiYearHarvestData, selectedCrop, selectedField])

  useEffect(() => {
    setSelectedMonths(seasons.filter(x => x.active === true).map(x => x.id))
  }, [seasons])


  useEffect(() => {
    if (activeFirstVariableOption.id === "Yield") {
      if (activeSecondVariableOption.id === "Precipitation") {
        yieldPrecipData();
      }

      if (activeSecondVariableOption.id === "GDD") {
        yieldGddData();
      }

      if (activeSecondVariableOption.id === "Nutrients") {
        yieldNutrientData();
      }
    }
    else if (activeFirstVariableOption.id === "Profit") {
      if (activeSecondVariableOption.id === "Precipitation") {
        profitPrecipData();
      }

      if (activeSecondVariableOption.id === "GDD") {
        profitGddData();
      }

      if (activeSecondVariableOption.id === "Nutrients") {
        profitNutrientData();
      }
    }
  }, [selectedCrop,
    selectedYears,
    selectedMonths,
    activeFirstVariableOption,
    activeSecondVariableOption,
    nutrientVariableOption,
    selectedField,
    precipMapData,
    gddMapData,
    profitSummaries])

  useEffect(() => {    
    (async() => {
      let field = allFieldsInfo.filter(f => f.FieldID === selectedField)[0]

      if(!precipMapData.has(field.state.toString()+field.county.toString())) {
        let pyearMap = new Map();
        for(let pyear of multiYears){
          if(field.state !== "NULL" && field.county !== "NULL"){
            let pres = await fr.getPrecipData(field.state.toString()+field.county.toString(), pyear)
            pres = JSON.parse(pres)
            pyearMap.set(pyear, pres)
          }          
        }
        let pmap = precipMapData
        pmap.set(field.state.toString()+field.county.toString(), pyearMap)
        setPrecipMapData(pmap)
      }     
      
      if(!gddMapData.has(field.state.toString()+field.county.toString())) {
        let gyearMap = new Map();
        for(let gyear of multiYears){
          if(field.state !== "NULL" && field.county !== "NULL"){
            let gres = await fr.getGddData(field.state.toString()+field.county.toString(), gyear)
            gres = JSON.parse(gres)
            gyearMap.set(gyear, gres)
          }          
        }
        let gmap = gddMapData
        gmap.set(field.state.toString()+field.county.toString(), gyearMap)
        setGddMapData(gmap)
      }     
    })()
  }, [selectedField]);

  const handleYearChange = (event) => {
    setSelectedYears(event.target.value)
  }

  const handleMonthChange = (event) => {
    setSelectedMonths(event.target.value)
  }

  const yieldPrecipData = () => {
    try{
      let finalPrecipData = [], finalYield = [];

      let field = allFieldsInfo.filter(f => f.FieldID === selectedField)[0]
      let fips = field.state.toString() + field.county.toString();
      let precipData = precipMapData.get(fips);
      //Precip Data for selected years
      for (let y of selectedYears) {
        let precip = 0;
        if(precipData !== undefined){
          let yearInfo = precipData.get(y)
          if(yearInfo !== undefined){
            for(let i=0; i<months.length;i++){
              for(let j=0; j<selectedMonths.length;j++){
                if(months[i] === selectedMonths[j]){
                  precip += yearInfo[i] === undefined ? 0 : yearInfo[i].Precipitation; 
                }
              }
            }
            precip *= 0.03937;
            finalPrecipData.push({
              x: y,
              y: +precip.toFixed(2)
            })
          }
        }      
      }
  
      //Yield Data for selected years and crop
      let harvestData = multiYearHarvestData.filter(x => x.CropName === selectedCrop)  
      
      if (harvestData.length > 0) {
        for (let x of harvestData) {
          let harYear = selectedYears.filter(y => y === x.CropSeason)
          if(harYear.length > 0){
            finalYield.push({
              x: x.CropSeason,
              y: +x.AverageYield,
              fieldName: x.FieldID
            })
          }        
        }
      }
  
      var options = formatOptions("Yield vs. Precipiataion", 
      "Crop Season", "Precipitation (inch)", "Yield (bu./ac)", "Precipitation", "Yield (bu./acre)");
  
      options.series[0].data = finalPrecipData
      options.series[1].data = finalYield
      setData(options)
    }
    catch(err){
      console.log("yieldPrecipData function error", err)
    }    
  }

  const yieldGddData = () => {
    try{
      let finalGddData = [], finalYield = [];

      let field = allFieldsInfo.filter(f => f.FieldID === selectedField)[0]
      let fips = field.state.toString() + field.county.toString();
      let gddData = gddMapData.get(fips);
  
      //Precip Data for selected years
      for (let y of selectedYears) {
        let gdd = 0;
        if(gddData !== undefined){
          let yearInfo = gddData.get(y)
          if(yearInfo !== undefined){
            for(let i=0; i<months.length;i++){
              for(let j=0; j<selectedMonths.length;j++){
                if(months[i] === selectedMonths[j]){
                  gdd += yearInfo[i] === undefined ? 0 : yearInfo[i].GDD;  
                }
              }
            }
            finalGddData.push({
              x: y,
              y: +gdd.toFixed(2)
            })
          }
        }      
      }
  
      //Yield Data for selected years and crop
      let harvestData = multiYearHarvestData.filter(x => x.CropName === selectedCrop 
        && x.FieldID === selectedField)
  
      
      if (harvestData.length > 0) {
        for (let x of harvestData) {
          let harYear = selectedYears.filter(y => y === x.CropSeason)
          if(harYear.length > 0){
            finalYield.push({
              x: x.CropSeason,
              y: +x.AverageYield,
              fieldName: x.FieldID
            })
          }        
        }
      }
  
      var options = formatOptions("Yield vs. GDD", 
      "Crop Season", "GDD", "Yield (bu./ac)", "GDD", "Yield (bu./acre)");
  
      options.series[0].data = finalGddData
      options.series[1].data = finalYield
      setData(options)
    }
    catch(err){
      console.log("yieldGddData error", err)
    }    
  }

  const yieldNutrientData = () => {
    try{
      let finalNutrientInfo = [], finalYield = [];

      //Nutrients Data for selected years
      let nutrientData = multiYearNutrientData.filter(x => x.NutrientAlias === nutrientVariableOption.id 
        && x.FieldID === selectedField)
      if (nutrientData.length > 0) {
        for (let x of nutrientData) {
          let nutrientYear = selectedYears.filter(y => y === x.CropSeason)
          if(nutrientYear.length > 0){
            finalNutrientInfo.push({
              x: x.CropSeason,
              y: +x.NutrientRate.toFixed(2),
              fieldName: x.FieldID
            })
          }        
        }
      }
  
      //Yield Data for selected years and crop
      let harvestData = multiYearHarvestData.filter(x => x.CropName === selectedCrop 
        && x.FieldID === selectedField)    
      if (harvestData.length > 0) {
        for (let x of harvestData) {
          let harYear = selectedYears.filter(y => y === x.CropSeason)
          if(harYear.length > 0){
            finalYield.push({
              x: x.CropSeason,
              y: +x.AverageYield,
              fieldName: x.FieldID
            })
          }        
        }
      }
  
      var options = formatOptions("Yield vs. Nutrient", 
      "Crop Season", "Nutrients (lbs/ac)", "Yield (bu./ac)", "Nutrient", "Yield (bu./acre)");
  
      options.series[0].data = finalNutrientInfo
      options.series[1].data = finalYield
      setData(options)
    }
    catch(err){
      console.log("yieldNutrientData error",err)
    }    
  }

  const profitPrecipData = () => {
    try{
      let finalPrecipData = [], finalProfit = [];

      let field = allFieldsInfo.filter(f => f.FieldID === selectedField)[0]
      let fips = field.state.toString() + field.county.toString();
      let precipData = precipMapData.get(fips);
      //Precip Data for selected years
      for (let y of selectedYears) {
        let precip = 0;
        if(precipData !== undefined){
          let yearInfo = precipData.get(y)
          if(yearInfo !== undefined){
            for(let i=0; i<months.length;i++){
              for(let j=0; j<selectedMonths.length;j++){
                if(months[i] === selectedMonths[j]){
                  precip += yearInfo[i] === undefined ? 0 : yearInfo[i].Precipitation; 
                }
              }
            }
            precip *= 0.03937;
            finalPrecipData.push({
              x: y,
              y: +precip.toFixed(2)
            })
          }
        }      
      }
      console.log("finalPrecipData", finalPrecipData)
      //Profit Data for selected years and crop
      let profitData = profitSummaries.data.filter(x => x.fieldID === selectedField)
  
      if (profitData.length > 0) {        
        //console.log("profitData", profitData)
        for (let x of profitData) {
          //console.log("x", x)
          let profits = selectedYears.filter(y => y === x.year)
          //console.log("profits", profits)
          if(profits.length > 0){
            finalProfit.push({
              x: x.year,
              y: +x.profit.toFixed(2),
            })
          }        
        }
      }
      console.log("finalProfit", finalProfit)
  
      var options = formatOptions("Profit vs. Precipiataion", 
      "Crop Season", "Precipitation (inch)", "Profit ($/ac)", "Precipitation", "Profit ($/ac)");
  
      options.series[0].data = finalPrecipData
      options.series[1].data = finalProfit
      setData(options)
    }
    catch(err){
      console.log("profitPrecipData error", err)
    }
  }

  const profitGddData = () => {
    try{
      let finalGddData = [], finalProfit = [];

      let field = allFieldsInfo.filter(f => f.FieldID === selectedField)[0]
      let fips = field.state.toString() + field.county.toString();
      let gddData = gddMapData.get(fips);
  
      //Precip Data for selected years
      for (let y of selectedYears) {
        let gdd = 0;
        if(gddData !== undefined){
          let yearInfo = gddData.get(y)
          if(yearInfo !== undefined){
            for(let i=0; i<months.length;i++){
              for(let j=0; j<selectedMonths.length;j++){
                if(months[i] === selectedMonths[j]){
                  gdd += yearInfo[i] === undefined ? 0 : yearInfo[i].GDD;   
                }
              }
            }
            finalGddData.push({
              x: y,
              y: +gdd.toFixed(2)
            })
          }
        }      
      }
  
      //Profit Data for selected years and crop
      let profitData = profitSummaries.data.filter(x => x.fieldID === selectedField)
        
      if (profitData.length > 0) {        
        //console.log("profitData", profitData)
        for (let x of profitData) {
          //console.log("x", x)
          let profits = selectedYears.filter(y => y === x.year)
          //console.log("profits", profits)
          if(profits.length > 0){
            finalProfit.push({
              x: x.year,
              y: +x.profit.toFixed(2),
            })
          }        
        }
      }
  
      var options = formatOptions("Profit vs. GDD", 
      "Crop Season", "GDD", "Profit ($/ac)", "GDD", "Profit ($/ac)");
  
      options.series[0].data = finalGddData
      options.series[1].data = finalProfit
      setData(options)
    }
    catch(err){
      console.log("profitGddData error", err)
    }
  }

  const profitNutrientData = () => {
    try{
      let finalNutrientInfo = [], finalProfit = [];

      //Nutrients Data for selected years
      let nutrientData = multiYearNutrientData.filter(x => x.NutrientAlias === nutrientVariableOption.id 
        && x.FieldID === selectedField)
      if (nutrientData.length > 0) {
        for (let x of nutrientData) {
          let nutrientYear = selectedYears.filter(y => y === x.CropSeason)
          if(nutrientYear.length > 0){
            finalNutrientInfo.push({
              x: x.CropSeason,
              y: +x.NutrientRate.toFixed(2),
              fieldName: x.FieldID
            })
          }        
        }
      }
  
      //Profit Data for selected years and crop
      let profitData = profitSummaries.data.filter(x => x.fieldID === selectedField)
  
      
      if (profitData.length > 0) {        
        //console.log("profitData", profitData)
        for (let x of profitData) {
          //console.log("x", x)
          let profits = selectedYears.filter(y => y === x.year)
          //console.log("profits", profits)
          if(profits.length > 0){
            finalProfit.push({
              x: x.year,
              y: +x.profit.toFixed(2),
            })
          }        
        }
      }
  
      var options = formatOptions("Profit vs. Nutrient", 
      "Crop Season", "Nutrients (lbs/ac)", "Profit ($/ac)", "Nutrients (lbs/ac)", "Profit ($/ac)");
  
      options.series[0].data = finalNutrientInfo
      options.series[1].data = finalProfit
      setData(options)
    }
    catch(err){
      console.log("profitNutrientData error", err)
    }
  }

  const formatOptions = (title, xAxis, yAxis1, yAxis2, yAxis1Name, yAxis2Name) => {
    let options = {
      chart: {
        type: 'scatter',
        zoomType: 'xy'
      },
      title: {
        text: '<b>' + title + '</b>'
      },
      xAxis: {
        title: {
          text: '<b>' + xAxis + '</b>'
        }
      },
      yAxis: [{
        title: {
          text: '<b>' + yAxis1 + '</b>'
        },
      }, {
        title: {
          text: '<b>' + yAxis2 + '</b>'
        },
        opposite: true
      }],
      legend: {
        enabled: true
      },
      series: [{
        name: yAxis1Name,
        type: 'scatter',
        data: [{}],
        zIndex: 4,
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      {
        name: yAxis2Name,
        type: 'scatter',
        yAxis: 1,
        data: [{}],
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
      ],
      navigation: {
        menuItemStyle: {
          borderLeft: '10px solid #FFFFFF',
          fontSize: '15px',
          padding: "2px 10px"
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true,
      },
    }

    return options
  }

  return (
    <Box my={1} style={{ width: "100%" }}>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box
          display="flex"
          flexWrap="wrap"
          fontSize={18}
        >
          <Box m={1}>
            <Box className={classes.label}>
              Crop
            </Box>
            <Select
              variant="outlined"
              MenuProps={MenuProps}
              style={{ width: 200, height: 40 }}
              value={selectedCrop}
              onChange={(e) => setSelectedCrop(e.target.value)}
            >
              {
                multiYearCrops.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x}
                  >
                    {x}
                  </MenuItem>
                ))
              }
            </Select>
          </Box>

          <Box m={1}>
            <Box className={classes.label}>
              Crop Year
              </Box>
            <Select
              id="years-mutiple"
              multiple
              value={selectedYears}
              onChange={handleYearChange}
              input={<Input id="select-multiple-years" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value, i) => (
                    <Chip key={i} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {years.map((year, i) => (
                <MenuItem key={i} value={year}>
                  <Checkbox
                    color="primary"
                    checked={selectedYears.includes(year)}
                  />
                  <ListItemText primary={year} />
                </MenuItem>
              ))}
            </Select>
          </Box>

          {/* <Box m={1}>
            <Box>
              Season Month
              </Box>
            <Select
              id="months-mutiple"
              multiple
              value={selectedMonths}
              onChange={handleMonthChange}
              input={<Input id="select-multiple-months" />}
              renderValue={(selected) => (
                <div className={classes.chips}>
                  {selected.map((value, i) => (
                    <Chip key={i} label={value} className={classes.chip} />
                  ))}
                </div>
              )}
              MenuProps={MenuProps}
            >
              {months.map((month, i) => (
                <MenuItem key={i} value={month}>
                  <Checkbox
                    color="primary"
                    checked={selectedMonths.includes(month)}
                  />
                  <ListItemText primary={month} />
                </MenuItem>
              ))}
            </Select>
          </Box> */}

          <Box m={1}>
            <SeasonSelect seasons={seasons} setSeasons={setSeasons} />
          </Box>

          <div className={classes.actionsContainer}>
            <div className={classes.innerActionsContainer}>
              <CustomSelect
                options={firstVariableOptions}
                setOptions={setFirstVariableOptions}
                label="First Variable"
              />
              <CustomSelect
                options={secondVariableOptions}
                setOptions={setSecondVariableOptions}
                label="Second Variable"
              />
              {activeSecondVariableOption.id == "Nutrients" && (
                <CustomSelect
                  options={nutrientOptions}
                  setOptions={setNutrientOptions}
                  label="Nutrients"
                />
              )}
            </div>
          </div>
        </Box>

        <Box
          display="flex"
          flexWrap="wrap"
          fontSize={18}
        >
          <Box m={1}>
            <Box className={classes.label}>
              Fields
            </Box>
            <Select
              variant="outlined"
              MenuProps={MenuProps}
              style={{ width: 200, height: 40 }}
              value={selectedField}
              onChange={(e) => setSelectedField(e.target.value)}
            >
              {
                allFieldsInfo.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x.FieldID}
                  >
                    {x.FieldName}
                  </MenuItem>
                ))
              }
            </Select>
          </Box>
        </Box>

        <Box id='yieldWeather'>
          {
            data !== null && <HighchartsReact
              highcharts={Highcharts}
              options={data}
            />
          }

        </Box>
      </Box>
    </Box>
  )
} 
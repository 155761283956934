import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Divider, TextField,
} from '@material-ui/core';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import DeleteIcon from '@material-ui/icons/Delete';
import EditOutlinedIcon from '@material-ui/icons/EditOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import { ImageModal } from '../../Shared/ImageModal';
import { dollarFormat, numFormat, checkIsMobile } from '../../../utils/helpers';
import { ToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  fieldInfo: {
    borderRadius: '10px',
    border: 'solid black 1px',
    margin: 'auto',
    padding: '10px',
    marginBottom: '10px',
  },
  icons: {
    color: theme.palette.primary.main,
    border: `solid ${theme.palette.primary.main} 2px`,
    borderRadius: '360px',
    fontSize: 24,
    padding: '2px',
    marginLeft: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  editNameIcon: {
    color: theme.palette.primary.main,
    fontSize: 18,
    marginLeft: 2,
    marginBottom: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dot: {
    fontSize: 12,
    color: theme.palette.greys.dark,
    margin: '4px 2px 0',
  },
  redDot: {
    fontSize: 12,
    color: '#dc3545',
    margin: '4px 2px 0',
  },
  image: {
    marginTop: 4,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  input: {
    height: 40,
  },
  textInput: {
    fontWeight: 500,
    color: theme.palette.text.primary,
  },
  infoToolTip: theme.infoToolTip,
}));

/**
 * Displays items in user's order. Will be used in order cart. Optionally, if
 * delete and edit fields are not an option they can be left out of paramaters
 * and component will not render those icon options.
 * @param {Object} fieldOrderInfo Info for field to be displayed
 * @param {Function} deleteField Removes field from order
 * @param {Function} editField Removes field from order and allows user to edit
 * @param {Function} editFieldName Update field name in cart and if selected
 * @returns {JSX} Item in order
 */
export const OrderItem = ({
  fieldOrderInfo,
  deleteField,
  editField,
  editFieldName,
  orderErrors,
}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const [mobileDisplay, setMobileDisplay] = useState(false);

  const [updateName, setUpdateName] = useState(false);
  const [updatedFieldName, setUpdatedFieldName] = useState(fieldOrderInfo.fieldName);

  useEffect(() => {
    setMobileDisplay(checkIsMobile());
  }, []);

  const completeNameEdit = () => {
    editFieldName(fieldOrderInfo.id, updatedFieldName);
    setUpdateName(false);
  };

  const packageName = (name) => {
    try {
      if (name !== undefined) {
        return name.split('"')[0];
      }
      return '';
    } catch (err) {
      console.error(err);
      return '';
    }
  };

  return (
    <Box className={classes.fieldInfo}>
      {/* Soil testing info styled for each field */}
      <Box display="flex" alignItems="center" height={40}>
        { deleteField && (
          <DeleteIcon
            className={classes.icons}
            onClick={() => deleteField(fieldOrderInfo.id)}
          />
        )}

        { editField && (
          <EditOutlinedIcon
            className={classes.icons}
            onClick={() => editField(fieldOrderInfo)}
          />
        )}

        <Box style={{ marginLeft: '12px' }}>
          { !updateName ? (
            <Typography style={{ fontWeight: 500 }}>
              {`${fieldOrderInfo.fieldName}`}
              { editFieldName && (
                <EditOutlinedIcon
                  className={classes.editNameIcon}
                  onClick={() => setUpdateName(true)}
                />
              )}
            </Typography>
          ) : (
            <Box display="flex">
              <TextField
                value={updatedFieldName}
                onChange={(e) => setUpdatedFieldName(e.target.value)}
                InputProps={{
                  className: classes.textInput,
                }}
                onBlur={() => completeNameEdit()}
              />
              <CheckCircleIcon
                className={classes.editNameIcon}
                onClick={() => completeNameEdit()}
              />
            </Box>
          )}
        </Box>

        <Typography style={{ marginLeft: 'auto', fontWeight: 500 }}>
          {dollarFormat(fieldOrderInfo.subTotal)}
        </Typography>
      </Box>
      <Divider />
      <Box display="flex">
        <Box className={classes.image} onClick={() => setOpen(true)}>
          <img
            style={{ height: 120, borderRadius: 4 }}
            src={fieldOrderInfo?.fieldImage}
            alt="field with zones"
          />
        </Box>
        <Box pl={1} mt={0.5}>

          {orderErrors[fieldOrderInfo.name] &&
            <Box>
              {orderErrors[fieldOrderInfo.name]['acres'] && 
                <Box display='flex'>
                  <FiberManualRecordIcon className={classes.redDot} />
                  <Box display='flex' style={{color:'red'}}>
                    {' Selected field must be at least 40 acres.'}
                  </Box>
                </Box>
              }
              {orderErrors[fieldOrderInfo.name]['coverage'] && 
                <Box display='flex'>
                  <FiberManualRecordIcon className={classes.redDot} />
                  <Box display='flex' style={{color:'red'}}>
                    {' Selected field is outside "'}
                    <a href='https://app.profit.ag/app/soil/overview' style={{color:'red', textDecoration:'underline'}} target="_blank">soil testing coverage</a>
                    {'".'}
                  </Box>
                </Box>
              }
            </Box>
          }

          <Box display="flex">
            <FiberManualRecordIcon className={classes.dot} />
            { fieldOrderInfo?.zoneAcres ? (
              `${numFormat(fieldOrderInfo?.zoneAcres)} Acres`
            ) : (
              `${numFormat(fieldOrderInfo?.acres)} Acres`
            )}
          </Box>

          <Box display="flex">
            <FiberManualRecordIcon className={classes.dot} />
            <Box display="flex">
              {`${fieldOrderInfo?.sampleZones?.length} Zones`}
              {fieldOrderInfo?.zoneType === 'grid' && (
              <ToolTip
                text="During automatic grid creation, it is possible some zones created will be too small to be visible on the map image."
                mobileDisplay={mobileDisplay}
              />
            )}
            </Box>
          </Box>

          <Box display="flex">
            <FiberManualRecordIcon className={classes.dot} />
            { packageName(fieldOrderInfo?.package) }
          </Box>

          <Box display="flex">
            <FiberManualRecordIcon className={classes.dot} />
            <Box display="flex">
              <span style={{ whiteSpace: 'nowrap' }}>Cost ($/Acre):</span>
              {' '}
              { dollarFormat(fieldOrderInfo?.costPerAcre) }
              { fieldOrderInfo.package.includes('Custom') && (
              <ToolTip
                text="Custom package cost per acre will be determined when you speak with your soil specialist"
                mobileDisplay={mobileDisplay}
              />
              )}
            </Box>

          </Box>

          <Box display="flex">
            <FiberManualRecordIcon className={classes.dot} />
            <Box display="flex">
              <span style={{ whiteSpace: 'nowrap' }}>Package Subtotal:</span>
              {' '}
              {dollarFormat(fieldOrderInfo?.subTotal)}
              { fieldOrderInfo.package.includes('Custom') && (
              <ToolTip
                text="Custom package pricing will be determined when you speak with your soil specialist"
                mobileDisplay={mobileDisplay}
              />
              )}
            </Box>

          </Box>
          
        </Box>
      </Box>

      <ImageModal
        open={open}
        setOpen={setOpen}
        image={fieldOrderInfo?.fieldImage}
      />

    </Box>
  );
};

OrderItem.propTypes = {
  fieldOrderInfo: PropTypes.object.isRequired,
  deleteField: PropTypes.func,
  editField: PropTypes.func,
  editFieldName: PropTypes.func,
};

OrderItem.defaultProps = {
  deleteField: undefined,
  editField: undefined,
  editFieldName: undefined,
};

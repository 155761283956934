import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Select, MenuItem } from "@material-ui/core";
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { MenuProps } from "../../../styles/select";
import { blackText, grey, darkGrey } from '../../../styles/colors'
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  selections: {
    height: 40,
    minWidth: 80,
  },
  boxLabel: theme.boxLabel,
  infoIcon: {
    ...theme.infoToolTip,
    marginLeft: '2px',
    marginBottom: '10px'
  },
  inputName: {
    height: 25,
    whiteSpace: 'nowrap',
  },
}));

export function OptionsBox({
  insuranceType,
  setInsuranceType,
  unit,
  setUnit,
  coverageLevel,
  setCoverageLevel,
  coverageLevels,
  displayOption,
  setDisplayOption,
  getInputAndSendRequest,
  disableUserInputs
}) {
  const classes = useStyles();

  const insuranceTypes = [
    ['Revenue Protection', 'Revenue Protection insures you against revenue losses (price times yield). So, this policy protects against low yields and/or low prices. Additionally, the price used to set your revenue baseline will adjust up and provide you higher coverage if prices go up at harvest.'],
    ['Yield Protection', 'Yield Protection insures you against yield losses only, at the base price set at the beginning of the year. It does not insure price declines, but is cheaper than Revenue Protection.'],
    ['Revenue Protection with Harvest Price Exclusion', 'Revenue Protection with Harvest Price Exclusion insures you against revenue losses (price times yield). So, this policy protects against low yields and/or low prices. But the price used to set your revenue baseline is fixed and will NOT adjust up if prices go up at harvest. It is cheaper than regular Revenue Protection.']
  ]

  const units = [
    ['Basic', 'Under Basic Units, all of the acres you own/cash rent in a county are insured together, separate from all acres in a county that are share rented. All acres share rented with different landlords are also insured as their own unit. So, you can have multiple Basic Units in a county, which means more opportunities to collect loss payments. However, Basic Units are more expensive than Enterprise Units.'],
    ['Optional', 'Optional Units are like Basic Units, except acres are divided by Township instead of County. So, you can have multiple Optional Units in a Township, which means even more opportunities to collect loss payments. However, Optional Units are more expensive than Enterprise and Basic Units.'],
    ['Enterprise', 'Under Enterprise Units, all of your acres of a single crop in a county are insured together. Premiums for this policy are less expensive than Basic or Optional Units.']
  ]

  const displayOptions = [
    'Revenue Distribution',
    'Yield Distribution',
    'Harvest Price Distribution',
    'Insurance Payment Distribution'
  ]

  // Advanced Options modal
  const [open, setOpen] = useState(false);

  return (
    <Box
      mx={1}
      color={blackText}
      fontWeight={500}
      display='flex'
      flexDirection='column'
    >
      <Box
        color={grey}
        className={classes.boxLabel}
      >
        Options
      </Box>

      <Box
        p={1}
        display="flex"
        border={1}
        borderRadius="borderRadius"
        borderColor={grey}
        minHeight={98}
        paddingTop="13px"
      >
        {/* Only show these fist two if not in InsuranceAI */}
        {getInputAndSendRequest === undefined && (
          <Box>
            <Box className={classes.inputName}>
              Insurance Type
              <CustomToolTip
                placement="right"
                title="Chosen insurance type. See descriptions for each below."
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </CustomToolTip>
            </Box>
            <Select
              className={classes.selections}
              variant='outlined'
              MenuProps={MenuProps}
              value={insuranceType}
              onChange={(e) => setInsuranceType(e.target.value)}
              style={{
                width: insuranceType === 'Revenue Protection with Harvest Price Exclusion' ? 415 : 205
              }}
              disabled={!!disableUserInputs}
            >
              {
                insuranceTypes.map((type, i) => (
                  <MenuItem
                    key={i}
                    value={type[0]}
                  >
                    {type[0]}
                    <CustomToolTip
                      placement="right"
                      title={type[1]}
                    >
                      <InfoOutlinedIcon className={classes.infoIcon} />
                    </CustomToolTip>
                  </MenuItem>
                ))
              }
            </Select>
          </Box>
        )}

        {setUnit !== undefined && (
          <Box ml={2}>
            <Box className={classes.inputName}>
              Units
              <CustomToolTip
                placement="right"
                title="Units determine which acres are grouped together for insurance purposes (Enterprise, Basic, or Optional). See descriptions for each below."
              >
                <InfoOutlinedIcon className={classes.infoIcon} />
              </CustomToolTip>
            </Box>
            <Select
              className={classes.selections}
              variant='outlined'
              MenuProps={MenuProps}
              value={unit}
              onChange={(e) => setUnit(e.target.value)}
              disabled={!!disableUserInputs}
            >
              {
                units.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x[0]}
                  >
                    {x[0]}
                    <CustomToolTip
                      placement="right"
                      title={x[1]}
                    >
                      <InfoOutlinedIcon className={classes.infoIcon} />
                    </CustomToolTip>
                  </MenuItem>
                ))
              }
            </Select>
          </Box>
        )}

        {/* Component for InsuranceAI: for picking insurance type, and coverage level and revenue distribution dropdowns */}
        {setCoverageLevel !== undefined && setDisplayOption !== undefined && (         
          <Box display='flex'>
            <Box>
              <Box className={classes.inputName}>
                Insurance Type
                <CustomToolTip
                  placement="right"
                  title="Chosen insurance type. See descriptions for each below."
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </CustomToolTip>
              </Box>
              <Select
                className={classes.selections}
                variant='outlined'
                MenuProps={MenuProps}
                value={insuranceType}
                onChange={(e) => {
                  setInsuranceType(e.target.value)
                  getInputAndSendRequest()
                }}
                style={{
                  width: insuranceType === 'Revenue Protection with Harvest Price Exclusion' ? 415 : 205
                }}
                disabled={!!disableUserInputs}
              >
                {
                  insuranceTypes.map((type, i) => (
                    <MenuItem
                      key={i}
                      value={type[0]}
                    >
                      {type[0]}
                      <CustomToolTip
                          placement="right"
                          title={type[1]}
                        >
                          <InfoOutlinedIcon className={classes.infoIcon} />
                        </CustomToolTip>
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>

            <Box ml={2}>
              <Box className={classes.inputName}>
                Coverage Level
                <CustomToolTip
                  placement="right"
                  title="Coverage level is an election made by you, which determines what percent of your expected yield is covered. It is similar to a deductible."
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </CustomToolTip>
              </Box>

              <Select
                className={classes.selections}
                variant='outlined'
                MenuProps={MenuProps}
                value={coverageLevel}
                onChange={(e) => {
                  setCoverageLevel(e.target.value)
                  getInputAndSendRequest()
                }}
                style={{ width: 97 }}
                disabled={!!disableUserInputs}
              >
                {
                  coverageLevels.map((type, i) => (
                    <MenuItem
                      key={i}
                      value={type}
                    >
                      {type}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>

            <Box ml={2}>
              <Box className={classes.inputName}>
                Display
                <CustomToolTip
                  placement="right"
                  title="The graphs below display your forecasts for likelihood of revenue, yield, price, and insurance payments outcomes. They show the estimated probability of different outcomes based on your field's characteristics and type of insurance."
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </CustomToolTip>
              </Box>

              <Select
                className={classes.selections}
                variant='outlined'
                MenuProps={MenuProps}
                value={displayOption}
                onChange={(e) => setDisplayOption(e.target.value)}
                disabled={!!disableUserInputs}
              >
                {
                  displayOptions.map((type, i) => (
                    <MenuItem
                      key={i}
                      value={type}
                    >
                      {type}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
          </Box>
        )}

      </Box>
    </Box>
  )
}

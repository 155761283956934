import React from 'react';
import PropTypes from 'prop-types';
import { Box, Modal } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles';
import { IntroVideo } from './Intro';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
  },
  close: {
    borderRadius: '50%',
    color: theme.palette.text.primary,
    position: 'absolute',
    top: -34,
    right: 0,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

/**
 * Currently not rendered anyway. Original intent was to be used in Onboarding.js
 * Modal containing intro video
 * @param {Bool} open Determines if modal is opened or closed
 * @param {Func} setOpen Set modal opened or closed
 * @returns {JSX} Modal
 */
export const IntroModal = ({ open, setOpen }) => {
  const classes = useStyles();

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="soil-testing-info"
      aria-describedby="soil-testing-order-process-description"
    >
      <Box className={classes.paper}>
        <Box bgcolor="white" className={classes.close} onClick={() => { setOpen(false); }}>
          <CloseIcon size="large" />
        </Box>
        <IntroVideo />
      </Box>
    </Modal>
  );
};

IntroModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  TextField,
  Button
} from "@material-ui/core";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { capitalizeFirstLetter, numFormat } from '../../../../utils/helpers'
import { NumberFormatThree } from '../../../../utils/NumberFormatCustom';

const theme = createMuiTheme({
  overrides: {
    // Style sheet name ⚛️
    MuiButton: {
      // Name of the rule
      text: {
        // Some CSS
        background: "red",
        height: 5,
        width: 15,
        marginLeft: "10px",
      },
    },
  },
});
const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.greys.dark,
    "&:hover": {
      cursor: "pointer",
    },
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
  },
  greenIcon: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
  },
  input: {
    width: "400px",
    margin: "5px",
  },
  mixDisabledInput: { ...theme.disabledInput, width: '400px' },
}));

export function Zone({ zone, index, remove, update, unit, hideValues}) {
  const classes = useStyles();
  const [display, setDisplay] = useState(true);
  // const [rate, setRate] = useState(0);
  useEffect(()=>{
    // updating 0 to 14 doesn't reflect up to operations total
    // if(zone.quantity == undefined || zone.quantity == 0){
    //   zone.quantity = 14;
    // }
    if(zone.quantity == undefined){
      zone.quantity = 0;
    }
  }, [])

  const displayZoneName = (zone, i) => {
    let zoneName = ''
    if ('soilType' in zone) {
      // Display soil type as zone name for SSURGO
      zoneName =  zone.soilType
    } else {
      zoneName = `Zone ${i + 1}`
    }

    return `${zoneName} - ${numFormat(zone.properties.CALCACRES)} Acres`
  }

  return (
    <Box my={1}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" pl={1} fontSize={16}>
          { displayZoneName(zone, index) }
          { zone.properties.color !== undefined && (
            <ThemeProvider theme={theme}>
              <Button disabled style={{ background: zone.properties.color }}></Button>
            </ThemeProvider>
          )}
        </Box>
        <Box className={classes.greenIcon}>
          <DeleteOutlineOutlinedIcon
            fontSize="small"
            onClick={() => remove(index)}
          />
        </Box>
      </Box>
      <Box display="flex">
        <Box>
          <Box pl={1}>
            {`Applied Rate (${capitalizeFirstLetter(unit)}/Acre)`}
          </Box>
          
          {/* Decides whether we show thw applied rate to the user or not based on whether a unit values has been selected. */}
          {!hideValues ? (
            <TextField
              className={classes.input}
              variant="outlined"
              value={Number(zone.quantity).toFixed(3).replace(/\.?0+$/, '')}
              InputProps={{
                inputComponent: NumberFormatThree,
              }}
              // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
              onBlur={(e) => update(index, Number(e.target.value.replaceAll(',', '')))}
            />
          ) : (
            <Box className={classes.mixDisabledInput}>
              {"No Unit Selected"}
            </Box>
          )}
          
        </Box>
      </Box>
    </Box>
  );
}

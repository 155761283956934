import React, { useEffect, useState } from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  ListSubheader
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
  selections: theme.selections,
}));

export const SelectionMenu = ({shapeFiles, selected, handleChange, loading}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl}>
      <Select
        className={classes.selections}
        variant="outlined"
        value={selected || ''}
        onChange={(e) => handleChange(e.target.value)}
        disabled={loading}
      >
        {shapeFiles?.zones?.length > 0 && (
          <ListSubheader>Zones</ListSubheader>
        )}
        {
          shapeFiles.zones.map((x, i) => (
            <MenuItem
              key={`${x}-${i}`}
              value={x}
              name={x}
            >
              {x.split('/')[x.split('/').length - 1].slice(0, -4)}
            </MenuItem>
          ))
        }

        {shapeFiles?.results?.length > 0 && (
          <ListSubheader>Lab Results</ListSubheader>
        )}
        {
          shapeFiles.results.map((x, i) => (
            <MenuItem
              key={`${x}-${i}`}
              value={x}
              name={x}
            >
              {x.split('/')[x.split('/').length - 1].slice(0, -4)}
            </MenuItem>
          ))
        }

        {shapeFiles?.recomendations?.length > 0 && (
          <ListSubheader>Fertility Recomendations</ListSubheader>
        )}

        {
          shapeFiles.recomendations.map((x, i) => (
            <MenuItem
              key={`${x}-${i}`}
              value={x}
              name={x}
            >
              {x.split('/')[x.split('/').length - 1].slice(0, -4)}
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}

import React from "react";
import { CircularProgress } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../constants";

const useStyles = makeStyles(() => ({
  container: {
    display: "flex",
    justifyContent: "center",
    marginTop: "25%",
    "& circle": {
      stroke: COLORS.green,
    },
  },
}));

const Spinner = ({ message }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <CircularProgress
        size={60}
        style={{
          position: "absolute",
          top: "30%",
          right: 0,
          left: 0,
          margin: "auto",
          zIndex: 1005,
          color: COLORS.green,
        }}
      />
      {message}
    </div>
  );
};

export default Spinner;

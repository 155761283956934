import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles(() => ({
  bar: {
    stroke: "none",
  },
}));

/**
 * Creates bar for chart
 * @param {object} point with x, y, width, height, color data
 */

const Bar = ({ point }) => {
  const classes = useStyles();
  const { x, y, width, height, color } = point;

  return (
    <rect
      className={classes.bar}
      x={x}
      y={y}
      width={width}
      height={height}
      style={{ fill: color }}
    />
  );
};

export default Bar;

Bar.propTypes = {
  point: PropTypes.object.isRequired,
};

/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { lightBlue, grey } from '@material-ui/core/colors';
import {
  AppBar, Grid, Tabs, Tab, Button, Typography, Container, Box, Link, Collapse, Divider, Paper, Select, MenuItem,
} from '@material-ui/core/';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Endpoints } from '../../../constants/Endpoints';
import { TabPanel } from './Panels';
import { ViewPDFs } from './ViewPDFs';
import { ViewDownloads } from './ViewDownloads';
// import { DisplayMap } from '../../Maps/DisplayMap'
import { Map } from './Map';
import { OrderInformation } from './OrderInformation';
import { exists } from '../../../utils/helpers';
import logo from '../../../images/Logo.png';
import { useWindowDimensions } from '../../../utils/dimensions';
import { FieldContext, FieldProvider } from '../../Context/FieldContext';
import { HighResMap } from './HighResMap';

const useStyles = makeStyles((theme) => ({
  appbar: {
    ...theme.appBar,
    justifyContent: 'center',
    height: 75,
  },
  orderCard: {
    margin: 4,
    padding: 4,
    minWidth: '200px',
  },
  reportCard: {
    width: '350px',
    fontWeight: 500,
    fontSize: 18,
    fontColor: theme.palette.text.blackText,
  },
  buttonBig: {
    margin: '.5rem',
    padding: '.5rem',
    width: '40%',
  },
  tabPanel: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.paper,
    minWidth: '200px',
  },
  tabRoot: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    overflow: 'hidden',
    minWidth: '200px',
  },
  sideTabs: {
    borderRight: `1px solid ${theme.palette.divider}`,
    overflow: 'hidden',
    minWidth: '200px',
  },
  media: {
    height: 0,
    paddingTop: '56.25%', // 16:9
  },
  orderImage: {
    width: '50px',
  },
  title: {
    color: grey[700],
    display: 'inline',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  value: {
    display: 'inline',
    marginLeft: '5px',
    fontWeight: 500,
    fontSize: '1.2rem',
  },
  expansionBox: {
    cursor: 'pointer',
    height: '200px',
    width: '20px',
  },
  mobileTabs: theme.mobileTabs,
}));

const a11yProps = (index) => ({
  id: `simple-tab-${index}`,
  'aria-controls': `simple-tabpanel-${index}`,
});

export const ReportDisplay = () => {
  /* API will return completed reports. populate list of reports to select from there.
     When report is selected, GetReports endpoint is called to get that report based on ID. */

  const classes = useStyles();
  const { width } = useWindowDimensions();

  // complete orders
  const [orders, setOrders] = useState([]);
  // current selected report and id of report suborder
  const [report, setReport] = useState({ subOrderDetails: [] });
  const [reportName, setReportName] = useState('');
  const [subReport, setSubReport] = useState({});
  const [subOrderId, setSubOrderId] = useState('');

  const [token, setToken] = useState('');
  const [boundary, setBoundary] = useState('');
  const [mapImage, setMapImage] = useState('');
  const [extent, setExtent] = useState('');
  // report files
  const [reportPdfs, setReportPdfs] = useState([]);
  const [reportDownloadables, setReportDownloadables] = useState([]);
  const [reportImages, setReportImages] = useState([]);

  // tab controls
  const [subReportDisplay, setSubReportDisplay] = useState(0);
  const [value, setValue] = useState(0);
  const [detailsTabValue, setDetailsTabValue] = useState(0);

  const [open, setOpen] = useState(width >= 1080);

  const [cogFiles, setCogFiles] = useState([]);
  const [fileName, setFileName] = useState([]);
  const [selectedFile, setSelectedFile] = useState('');

  useEffect(() => {
    const url = window.location.href;
    const splitUrl = url.toUpperCase().split('REPORTS');
    if (splitUrl.length > 1 && splitUrl[1].length > 1) {
      getReportFromToken(splitUrl[1].replace('/', ''));
      setToken(splitUrl[1].replace('/', ''));
    } else {
      getOrders();
    }
  }, []);

  useEffect(() => {
    if (subOrderId !== report.subOrderId) {
      setSubOrderId(report.subOrderId);
    }
    getMapData();

    if (report.orderName && report.orderName !== null && report.orderName !== undefined) {
      setReportName(report.orderName);
    } else {
      setReportName('');
    }
  }, [report]);

  useEffect(() => {
    if (exists(subOrderId)) {
      getReportFiles();
    }
  }, [subOrderId]);

  useEffect(() => {
    getMapData();
  }, [value]);

  useEffect(() => {
    if (exists(report.subOrderDetails)) {
      setSubReport(report.subOrderDetails[subReportDisplay]);
    }
  }, [subReportDisplay]);

  useEffect(() => {
    if (exists(subReport)) {
      setSubOrderId(subReport.orderId);
      getMapData();
      getReportFiles();
    }
  }, [subReport]);

  useEffect(() => {
    const filesList = []; const
      namesList = [];
    // eslint-disable-next-line no-restricted-syntax
    for (const file of reportDownloadables) {
      let name = file.split('/');
      name = name[name.length - 1];
      if (name.toUpperCase().includes('COG.TIF')) {
        filesList.push(file);
        namesList.push(name);
      }
    }
    setCogFiles(filesList);
    setFileName(namesList);
    if (namesList.length > 0) {
      setSelectedFile(0);
    }
  }, [reportDownloadables]);

  const getMapData = () => {
    try {
      const { boundary } = subReport;
      setBoundary(boundary);
      if (exists(subReport.PreviewImages)) {
        const image = subReport.PreviewImages[0].base64;
        const mapImage = checkImage(image);

        setMapImage(mapImage);
        if (exists(subReport?.PreviewImages[0]?.mapExtent)) {
          const extent = subReport.PreviewImages[0].mapExtent;
          setExtent(cleanExtent(extent));
        }
      } else {
        setMapImage('');
      }
    } catch (err) {
      console.error(`Problem getting map data: ${err}`);
    }
  };

  const cleanExtent = (x) => {
    // Known bad values response could contain for extent
    if (x === undefined || x === 'Null') {
      return [];
    }

    // Split string in to array of coordinates
    const extent = x.split(',');

    // Additional check to catch potential invalid data
    if (extent.length < 4) {
      return [];
    }

    const points = [];
    for (let i = 0; i < extent.length; i += 2) {
      points.push([parseFloat(extent[i + 1]), parseFloat(extent[i])]);
    }
    return points;
  };

  const handleTabChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleDetailsChange = (event, newValue) => {
    setDetailsTabValue(newValue);
  };

  const getOrders = async () => {
    const response = await fetch(Endpoints.BASEURL + Endpoints.GETORDERIDS,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
        credentials: 'include',
      });
    try {
      const orders = await response.json();
      orders.sort((a, b) => new Date(b.dateCreated) - new Date(a.dateCreated));
      setOrders(orders);
      getReport(orders[0].orderID);
    } catch (err) {
      console.error(`problem getting report: ${err}`);
    }
  };

  const getReportFromToken = async (token) => {
    /* If token is present in link get that individual report for display.
    This will happen instead of loading all orders, even for logged in user. */

    const response = await fetch(Endpoints.BASEURL + Endpoints.GETREPORTSFROMTOKEN + token,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
        credentials: 'include',
      });
    try {
      const jsonResponse = await response.json();
      const data = await jsonResponse[0];
      const report = JSON.parse(data.orderResponse);
      const orders = report.subOrderDetails.filter((x) => x.subOrderId !== null);
      setReport(report);
      setSubOrderId(report.subOrderDetails[0].subOrderId);
      setSubReport(report.subOrderDetails[0]);
    } catch (err) {
      console.error(`problem getting report: ${err}`);
    }
  };

  const getReport = async (reportId) => {
    const response = await fetch(Endpoints.BASEURL + Endpoints.GETREPORTS + reportId,
      {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
        credentials: 'include',
      });
    try {
      const jsonResponse = await response.json();
      const data = await jsonResponse[0];
      const report = JSON.parse(data.orderResponse);
      setReport(report);
      setSubOrderId(report.subOrderDetails[0].subOrderId);
      setSubReport(report.subOrderDetails[0]);
    } catch (err) {
      console.error(`problem getting report: ${err}`);
    }
  };

  const getReportFiles = async () => {
    /* Get PDFs, images, Tiffs, and other files associated with selected report from blob storage.
    First check if token is present, if so use this in fetch url, if not request as normal for
    logged in user. */
    const { orderId } = report;
    const url = token !== '' ? `${Endpoints.BASEURL + Endpoints.GETPDFS + orderId}&suborderId=${subReport.subOrderId}&token=${token}`
      : `${Endpoints.BASEURL + Endpoints.GETPDFS + orderId}&suborderId=${subReport.subOrderId}`;
    if (exists([orderId, subReport.subOrderId])) {
      try {
        const response = await fetch(url,
          {
            method: 'POST',
            headers: {
              Accept: 'application/json',
            },
            credentials: 'include',
          });
        const reportFiles = await response.json();
        if (exists(reportFiles.pdfs)) {
          setReportPdfs(reportFiles.pdfs);
        }
        if (exists(reportFiles.images)) {
          setReportImages(reportFiles.images);
        }
        if (exists(reportFiles.downloads)) {
          setReportDownloadables(reportFiles.downloads);
        }
      } catch (err) {
        setReportPdfs([]);
        setReportImages([]);
        setReportDownloadables([]);
        console.error(`problem getting pdfs: ${err}`);
      }
    } else {
      setReportPdfs([]);
      setReportImages([]);
      setReportDownloadables([]);
    }
  };

  // Check that base64 image has data tag, if not add it
  const checkImage = (image) => (image.substring(0, 4) === 'data' ? image : `data:image/jpeg;base64,${image}`);

  const openInteractive = () => {
    /* Used to add open link on click to map image */
    if (exists(subReport.interactiveAppLink)) {
      window.open(subReport.interactiveAppLink);
    }
  };

  const formatDate = (date) => {
    // split date on T for time, format month/day/year
    const d = date.split('T')[0].split('-');
    return `${d[1]}/${d[2]}/${d[0]}`;
  };

  /* Functions to create page content */
  const createOrderCards = (order, i) =>
    /* Create cards for completed orders. Use will select individual report to view
    from these cards. They will remain visible at top of page so new reports can be quickly
    and easily selected */
    (

      <Box
        key={i}
        m={2}
        p={2}
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="space-between"
        border={1}
        borderColor="greys.main"
        borderRadius="borderRadius"
        style={{ width: '240px', height: '180px' }}
      >
        <Box
          fontWeight={500}
          fontSize={16}
          color="#424242"
        >
          <Box>
            Order:
            {order.orderID}
          </Box>
          <Box>
            Created on:
            {formatDate(order.dateCreated)}
          </Box>
          {order.dateModified !== null
            && (
              <Box>
                Completed on:
                {formatDate(order.dateModified)}
              </Box>
            )}
        </Box>

        <Button
          variant="contained"
          color="primary"
          onClick={() => {
            const element = document.getElementById('uav-orderbar');
            element.scrollIntoView({ block: 'center', behavior: 'smooth' });
            getReport(order.orderID);
          }}
          disableElevation
        >
          View Order
        </Button>

      </Box>
    );

  // create cards for report summaries
  const createReportCards = (report, i) => (
    <Box
      key={i}
      m={1}
      p={1}
      className={classes.reportCard}
      borderRadius="borderRadius"
      border={1}
      borderColor="greys.main"
    >
      <Typography align="center" style={{ fontWeight: 500, fontSize: 22 }}>
        {report.reportName}
      </Typography>

      <Divider />
      {
        (report.reportSummary !== undefined && report.reportSummary !== null)
        && report.reportSummary.map((summary, i) => displayReportSummaries(summary, i))
      }
    </Box>
  );

  const displaySubReport = (subReport) =>
  /* Display details of selected sub order for report. Sub Orders will contain
    the actual info for orders, including results of individual reports ordered,
    images, pdfs and potentially more. */

    (
      <Box
        p={3}
        display="flex"
        flexWrap="wrap"
        justifyContent="space-around"
      >
        {exists(subReport.interactiveAppLink)
          && (
            <Box
              m={1}
              p={1}
              borderRadius="borderRadius"
              border={1}
              borderColor="greys.main"
              style={{ width: '400px' }}
            >
              <Box>
                <Typography variant="h6" align="center" style={{ margin: '5px 20px' }}>
                  Click the image or
                  {' '}
                  <Link color="secondary" href={subReport.interactiveAppLink} target="_blank" rel="noreferrer">here</Link>
                  {' '}
                  to open Interactive Map and view high resolution images.
                </Typography>
              </Box>
              <Box style={{ width: '100%', height: '350px' }}>
                <Map
                  boundary={boundary}
                  mapImage={mapImage}
                  extent={extent}
                  selectField={openInteractive}
                />
              </Box>
            </Box>
          )}
        {(exists(subReport.summary)) && subReport.summary.map((reportSummary, i) => createReportCards(reportSummary, i))}
      </Box>
    );

  const displayReportSummaries = (summary, i) => {
    switch (summary.title) {
      case 'Plant Health':
        const descriptions = summary.description.split(',');
        return (
          <Box key={i} p={2}>
            {
              descriptions.map((description, i) => (
                <Box
                  key={i}
                >
                  {description}
                </Box>
              ))
            }
          </Box>
        );
      default:
        return (
          <Box key={i} p={2} display="flex" flexWrap="wrap">
            <Box>
              {summary.description}
            </Box>
          </Box>
        );
    }
  };

  const displayImages = (image, i) => {
    const imageLink = token !== '' ? `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${image}&token=${token}` : `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${image}`;

    return (
      <Box
        key={i}
        m={1}
        p={1}
        borderRadius="borderRadius"
        border={1}
        borderColor="greys.main"
      >
        <Typography
          align="center"
          variant="h6"
        >
          {image.split('/')[image.split('/').length - 1].replace('.png', '')}
        </Typography>
        <img
          className="img-fluid"
          key={i}
          src={imageLink}
          style={{ maxWidth: '450px' }}
          alt={image}
        />
      </Box>
    );
  };

  const handleFieldChange = (i) => {
    setDetailsTabValue(0);
    setSubReportDisplay(i);
  };

  const handleFileSelect = (index) => {
    setSelectedFile(index);
  };

  const tabSelect = () => (
    <Box>
      <Divider />
      <div className={classes.tabRoot}>
        <Tabs
          orientation="vertical"
          variant="scrollable"
          value={detailsTabValue}
          onChange={handleDetailsChange}
          aria-label="Vertical tabs example"
          className={classes.sideTabs}
        >
          <Tab label="Quick Summary" {...a11yProps(0)} />
          <Tab label="Interactive Map" {...a11yProps(1)} />
          <Tab label="Detailed Reports" {...a11yProps(2)} />
          <Tab label="High Resolution Images" {...a11yProps(3)} />
          <Tab label="Downloads" {...a11yProps(4)} />
          <Tab label="Order Summary" {...a11yProps(5)} />
        </Tabs>
      </div>
    </Box>
  );

  const mobileSelection = () => (
    <Paper flexGrow={1}>
      <Tabs
        value={detailsTabValue}
        indicatorColor="primary"
        textColor="primary"
        onChange={handleDetailsChange}
        variant="scrollable"
        scrollButtons="on"
        style={{ maxHeight: 30, minHeight: 30 }}
        centered
      >
        <Tab className={classes.mobileTabs} label="Quick Summary" />
        <Tab className={classes.mobileTabs} label="Interactive Map" />
        <Tab className={classes.mobileTabs} label="Detailed Reports" />
        <Tab className={classes.mobileTabs} label="High Resoultion Images" />
        <Tab className={classes.mobileTabs} label="Downloads" />
        <Tab className={classes.mobileTabs} label="Order Summary" />
      </Tabs>
    </Paper>
  );

  const contentDisplay = () => (
    detailsTabValue === 0
      ? (
        <Box>
          {exists(subReport) && displaySubReport(subReport)}
          <Box mt={1} display="flex" flexWrap="wrap" justifyContent="space-around">
            {(reportImages !== undefined && reportImages.length !== 0) && reportImages.map((image, i) => displayImages(image, i))}
          </Box>
        </Box>
      )
      : detailsTabValue === 1
        ? (
          <Box m={3}>
            {exists(subReport.interactiveAppLink) && openInteractive()}
            <Typography variant="h5" align="center" style={{ margin: '5px 20px' }}>
              Your interactive map will automatically open in a new tab.
            </Typography>
            <Typography variant="h5" align="center" style={{ margin: '5px 20px' }}>
              If it does not, please click
              {' '}
              <Link color="secondary" href={subReport.interactiveAppLink} target="_blank" rel="noreferrer">here</Link>
              .
            </Typography>
          </Box>
        )

        : detailsTabValue === 2
          ? (
            <ViewPDFs
              reportPdfs={reportPdfs}
              token={token}
            />
          )
          : detailsTabValue === 3
            ? (
              <Box
                m={1}
                p={1}
                borderRadius="borderRadius"
                border={1}
                borderColor="greys.main"
                style={{ width: '750px' }}
              >
                <Box>
                  <Box>Select File</Box>
                  <Select
                    style={{ height: '30px', width: 'auto' }}
                    variant="outlined"
                    value={selectedFile}
                    onChange={(e) => handleFileSelect(e.target.value)}
                    displayEmpty
                  >
                    {fileName.map((x, i) => (
                      <MenuItem value={i} key={i}>
                        {x}
                      </MenuItem>
                    ))}
                  </Select>
                </Box>

                <Box style={{ width: '100%', height: '500px' }}>
                  <HighResMap
                    boundary={boundary}
                    mapImage={mapImage}
                    extent={extent}
                    selectField={openInteractive}
                    selectedFile={selectedFile}
                    cogFiles={cogFiles}
                    token={token}
                  />
                </Box>
              </Box>
            )
            : detailsTabValue === 4
              ? (
                <ViewDownloads
                  reportDownloadables={reportDownloadables}
                  token={token}
                />
              )
              : (
                <OrderInformation
                  report={report}
                  subReport={subReport}
                />
              )
  );

  return (
    <Box pt={1}>

      <Box>
        <Typography
          align="center"
          variant="h4"
          component="h1"
          style={{ color: '#424242' }}
        >
          UAV Service Reports
        </Typography>
        {orders.length > 0
          && (
            <Typography
              align="center"
              variant="body1"
              paragraph
              style={{ color: '#424242', fontWeight: 500 }}
            >
              Select a completed report below to see details
            </Typography>
          )}
      </Box>

      {/* Completed Orders */}
      <Box display="flex" flexWrap="wrap">
        {orders.length > 0 && orders.map((order, i) => createOrderCards(order, i))}
      </Box>

      <Box my={1}>
        <Divider />
        <Typography align="center" variant="h4" style={{ marginTop: 12, color: '#424242' }}>
          {reportName}
        </Typography>
      </Box>

      {(orders.length > 0 || token !== '')
        ? (
          <>
            <AppBar
              position="static"
              color="transparent"
              style={{ boxShadow: '0 1px 10px 5px rgba(0, 0, 0, 0.05)' }}
            >
              <Box className={classes.appbar} id="uav-orderbar">
                {exists(report.subOrderDetails)
                  && report.subOrderDetails.filter((order) => order.subOrderId !== null).map((order, i) => (
                    <Button
                      key={i}
                      variant="outlined"
                      color="primary"
                      onClick={() => handleFieldChange(i)}
                      style={{ margin: '15px', height: 50 }}
                      disableElevation
                    >
                      {exists(order.fieldName) && order.fieldName !== 'null' ? order.fieldName : `Field ${i + 1}`}
                    </Button>
                  ))}
              </Box>

            </AppBar>

            {width < 1080 && mobileSelection()}
            <Box display="flex">
              {width >= 1080 && tabSelect()}
              <Box display="flex" flexWrap="wrap">
                {(exists(subReport)) && contentDisplay()}
              </Box>
            </Box>
          </>
        ) : (
          <Box m={1}>
            <Typography variant="h6" align="center">
              Your report is not yet ready. Please check back soon!
            </Typography>
          </Box>
        )}

    </Box>
  );
};

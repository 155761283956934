import React from 'react';
import { Box, Switch } from '@material-ui/core';

export function ImageSwitch({checked, handleSwitch}) {
  return (
    <Box
      display="flex"
      alignItems="center"
      fontSize={14}
    >
      <Switch
        color="primary"
        checked={checked}
        onChange={(e) => handleSwitch(e.target.checked)}
        inputProps={{ 'aria-label': 'boundary toggle' }}
      />
      Toggle Imagery
    </Box>
  )
}

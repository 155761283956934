import React, { useEffect, useState } from "react";
import { makeStyles, CircularProgress } from "@material-ui/core";
import PropTypes from "prop-types";
import { ascending } from "d3-array";
import * as topojson from "topojson-client";
import Header from "./Header";
import TitleContainer from "./TitleContainer";
import Overview from "./Overview";
import Analytics from "./Analytics";
import MapIcon from "../../../images/map.svg";
import ChartIcon from "../../../images/chart.svg";
import PennsylvaniaCountyMap from "../../../shapefiles/PA-42-pennsylvania-counties.json"; // retrieve from API
import {
  getStateNames,
  fetchStateAndCountyForFields,
  getPrecipitationForState,
  getSoilData,
  getNccpiForState,
  getGddForState,
} from "../../../utils/farmReportDataFetchers";
import {
  getStateData,
  getFarmData,
} from "../../Helpers/MarketReportHelper/helper";
import { ORG_ID } from "../../../constants";
import { green } from "../../../styles/colors";

const useStyles = makeStyles(() => ({
  container: ({ mobileView }) => ({
    display: "flex",
    flex: "1 1 100%",
    flexDirection: "column",
    padding: 25,
    marginLeft: mobileView ? 0 : 250,
    maxWidth: 1000,
  }),
}));

/**
 * Renders the market report
 * @param {array} views array of objects with id, label and active properties
 * @param {function} setViews set state function to switch between overview and analytics
 * @param {object} activeFarm object with farm data
 * @param {string} activeYear the year
 * @param {boolean} mobileView for mobile views
 */

const MarketReport = ({
  views,
  setViews,
  activeFarm,
  activeYear,
  mobileView,
  orgId,
  loadedFarmData,
  setLoadedFarmData,
  loadedStateData,
  setLoadedStateData,
}) => {
  const classes = useStyles({ mobileView });

  // Get all the available counties from a state shapefile
  const counties = topojson.feature(
    PennsylvaniaCountyMap,
    PennsylvaniaCountyMap.objects.counties
  ); // switch to counties returned by API - preferably in TopoJSON format matching the format of this .json file

  // Assign random values to each of the counties
  counties.features = counties.features.map((feature) => ({
    ...feature,
    properties: {
      ...feature.properties,
      value: {
        "Yield/Acre": Math.random() * 25,
        Precipitation: Math.random() * 25,
      }, // add real value here to populate choropleth map
      // You will need to write a function here that maps the county name(found in properties.name)
      // to the county name in your Yield/Precipitation data and retrieves the value
    },
  }));

  const [visualizationViews, setVisualizationView] = useState([
    {
      id: "chart",
      label: "Chart",
      icon: ChartIcon,
      active: true,
    },
    {
      id: "map",
      label: "Map",
      icon: MapIcon,
      active: false,
    },
  ]);
  const activeView = views?.find((view) => view.active);
  const isOverviewActive = activeView.id === "overview";

  const activeVisualizationView = visualizationViews.find(
    (view) => view.active
  );

  // Use the county names from the shapefile to populate the select dropdown for comparison geographies
  //console.log('counties', counties)
  const [geographies, setGeographies] = useState(
    counties.features
      .map((d) => ({ name: d.properties.NAME }))
      .sort((a, b) => ascending(a.name, b.name))
      .map((d, i) => ({ ...d, active: !i }))
  );

  // useEffect(() => {
  //   console.log('geographies', geographies)
  // }, [])

  const [stateNames, setStateNames] = useState(null);

  const [selectedState, setSelectedState] = useState({
    id: "",
    name: "",
    active: false,
  });

  useEffect(() => {
    getStateNamesForDD();
  }, []);

  useEffect(() => {
    (async () => {
      //API call to get state and county values, associtated to fields in the selected farm
      let stateAndCounty = await fetchStateAndCountyForFields(
        orgId,
        activeFarm.id
      );
      stateAndCounty = JSON.parse(stateAndCounty);
    })();
  }, [orgId, activeFarm]);

  const getStateNamesForDD = async () => {
    try {
      let res = await getStateNames();
      let states = [];

      for (let s in res) {
        states.push({
          name: res[s],
          id: s,
          active: false,
        });
      }
      if (states.length > 0) {
        states[0].active = true;
      }
      //console.log('states', states)
      setStateNames(states);
      let state = states.filter((geography) => geography.active == true)[0];
      setSelectedState(state);
    } catch (err) {
      console.log("get state names error", err);
    }
  };

  

  useState(() => {
    console.log(loadedStateData, loadedFarmData)
  }, [loadedStateData, loadedFarmData])

  const [harvestVarietyForState, setHarvestVarietyForState] = useState([]);
  const [seedingVarietyForState, setSeedingVarietyForState] = useState([]);
  const [harvestDataForState, setHarvestDataForState] = useState([]);
  const [seedingDataForState, setSeedingDataForState] = useState([]);
  const [nutrientDataForState, setNutrientDataForState] = useState([]);
  const [precipForState, setPrecipForState] = useState([]);
  const [soilDataForState, setSoilDataForState] = useState([]);
  const [nccpiForState, setNccpiForState] = useState([]);
  const [gddForState, setGddForState] = useState([]);
  const [harvestDataForCounty, setHarvestDataForCounty] = useState([]);
  const [precipitationDataForCounty, setPrecipitationDataForCounty] = useState([]);
  const [gddDataForCounty, setGddDataForCounty] = useState([]);
  const [cropNames, setCropNames] = useState([]);

  useEffect(() => {
    setLoadedStateData(false);
  }, [activeYear, selectedState.id])

  useEffect(() => {
    (async () => {
      if (selectedState.id !== "") {
        setLoadedStateData(false);
        const [
          harvestVarietyForStateRes,
          seedingVarietyForStateRes,
          harvestDataForStateRes,
          seedingDataForStateRes,
          nutrientDataForStateRes,
          precipData,
          soilData,
          nccpi,
          gdd,
          harDataForCounty,
          precipDataForCounty,
          gddForCounty
        ] = await getStateData(selectedState.id, activeYear);

        setHarvestVarietyForState(harvestVarietyForStateRes);
        setSeedingVarietyForState(seedingVarietyForStateRes);
        setHarvestDataForState(harvestDataForStateRes);
        setSeedingDataForState(seedingDataForStateRes);
        setNutrientDataForState(nutrientDataForStateRes);
        setPrecipForState(precipData);
        setSoilDataForState(soilData);
        setNccpiForState(nccpi);
        setGddForState(gdd);
        setHarvestDataForCounty(harDataForCounty);
        setPrecipitationDataForCounty(precipDataForCounty);
        setGddDataForCounty(gddForCounty);
        setLoadedStateData(true);
      }
      //getFarmData()
    })();
  }, [selectedState.id, activeYear]);

  const [harvestVarietyForFarm, setHarvestVarietyForFarm] = useState([]);
  const [seedingVarietyForFarm, setSeedingVarietyForFarm] = useState([]);
  const [harvestDataForFarm, setHarvestDataForFarm] = useState([]);
  const [seedingDataForFarm, setSeedingDataForFarm] = useState([]);
  const [nutrientDataForFarm, setNutrientDataForFarm] = useState([]);
  const [precipDataForFarm, setPrecipDataForFarm] = useState([]);
  const [soilDataForFarm, setSoilDataForFarm] = useState([]);
  const [nccpiForFarm, setNccpiForFarm] = useState([]);
  const [gddForFarm, setGddForFarm] = useState([]);

  useEffect(() => {
    (async () => {
    setLoadedFarmData(false);
    })();
  }, [activeFarm.id, activeYear])

  useEffect(() => {
    (async () => {
      if (activeFarm.id !== "") {
        setLoadedFarmData(false);
        const [
          harvestVarietyForFarmRes,
          seedingVarietyForFarmRes,
          harvestDataForFarmRes,
          seedingDataForFarmRes,
          nutrientDataForFarmRes,
        ] = await getFarmData(orgId, activeFarm.id, activeYear);

        let stateAndCounty = await fetchStateAndCountyForFields(
          orgId,
          activeFarm.id
        );
        stateAndCounty = JSON.parse(stateAndCounty);
        let state = stateAndCounty.map((x) => x.state);
        state = [...new Set([...state])];
        let precipData = await getPrecipitationForState(state[0], activeYear);
        precipData = JSON.parse(precipData);
        let gddData = await getGddForState(state[0], activeYear);
        gddData = JSON.parse(gddData);

        if (state != "") {
          let soilData = await getSoilData(state, activeYear);
          let nccpi = await getNccpiForState(state);
          soilData = JSON.parse(soilData);
          nccpi = JSON.parse(nccpi);
          setSoilDataForFarm(soilData);
          setNccpiForFarm(nccpi);
        }

        setHarvestVarietyForFarm(harvestVarietyForFarmRes);
        setSeedingVarietyForFarm(seedingVarietyForFarmRes);
        setHarvestDataForFarm(harvestDataForFarmRes);
        setSeedingDataForFarm(seedingDataForFarmRes);
        setNutrientDataForFarm(nutrientDataForFarmRes);
        setPrecipDataForFarm(precipData);
        setGddForFarm(gddData);
        setLoadedFarmData(true);
      }
    })();
  }, [orgId, activeFarm.id, activeYear]);

  useEffect(() => {
    if (harvestDataForState != null && harvestDataForFarm != null) {
      let harCropsState = harvestDataForState.map((x) => x.CropName);
      //console.log("harCropsState", harCropsState)
      let harCropsFarm = harvestDataForFarm.map((x) => x.CropName);
      //console.log("harCropsFarm", harCropsFarm)
      let cropsForDD = [...new Set([...harCropsState, ...harCropsFarm])].sort();
      //console.log("cropsForDD", cropsForDD)
      let selectedCrop = "";
      let crpData = [];
      for (let c of cropsForDD) {
        crpData.push({
          id: c,
          active: false,
        });
      }
      if (crpData.length > 0) {
        selectedCrop = crpData[0].id;
        crpData[0].active = true;
      }
      setCropNames(crpData);
    }
  }, [harvestDataForState, harvestDataForFarm]);

  return (
    <div className={classes.container}>
      {!loadedStateData && !loadedFarmData && (
        <CircularProgress
          size={60}
          style={{
            position: "absolute",
            top: "50%",
            right: 0,
            left: 0,
            margin: "auto",
            zIndex: 1005,
            color: green,
          }}
        />
      )}

      <TitleContainer
        views={views}
        setViews={setViews}
        activeYear={activeYear}
        mobileView={mobileView}
      />
      <Header
        geographies={stateNames}
        setGeographies={setStateNames}
        valueAccessor={(d) => d.name}
        showButton={!isOverviewActive}
        activeFarm={activeFarm}
        visualizationViews={visualizationViews}
        setVisualizationView={setVisualizationView}
        selectedState={selectedState}
        setSelectedState={setSelectedState}
      />
      {isOverviewActive ? (
        <Overview
          mobileView={mobileView}
          selectedState={selectedState}
          orgId={orgId}
          harvestVarietyForState={harvestVarietyForState}
          seedingVarietyForState={seedingVarietyForState}
          harvestDataForState={harvestDataForState}
          seedingDataForState={seedingDataForState}
          harvestVarietyForFarm={harvestVarietyForFarm}
          seedingVarietyForFarm={seedingVarietyForFarm}
          harvestDataForFarm={harvestDataForFarm}
          seedingDataForFarm={seedingDataForFarm}
          loadedStateData={loadedStateData}
          loadedFarmData={loadedFarmData}
          nutrientDataForState={nutrientDataForState}
          nutrientDataForFarm={nutrientDataForFarm}
          precipForState={precipForState}
          precipDataForFarm={precipDataForFarm}
          soilDataForState={soilDataForState}
          soilDataForFarm={soilDataForFarm}
          nccpiForState={nccpiForState}
          nccpiForFarm={nccpiForFarm}
          gddForFarm={gddForFarm}
          gddForState={gddForState}
        />
      ) : (
        <Analytics
          geographies={stateNames}
          setGeographies={setStateNames}
          activeVisualizationView={activeVisualizationView}
          counties={counties}
          activeFarm={{ ...activeFarm, yield: 100 }}
          cropNames={cropNames}
          activeYear={activeYear}
          harvestDataForState={harvestDataForState}
          harvestDataForFarm={harvestDataForFarm}
          harvestVarietyForFarm={harvestVarietyForFarm}
          harvestVarietyForState={harvestVarietyForState}
          nutrientDataForState={nutrientDataForState}
          nutrientDataForFarm={nutrientDataForFarm}
          precipDataForFarm={precipDataForFarm}
          precipForState={precipForState}
          harvestDataForCounty={harvestDataForCounty}
          selectedState={selectedState}
          precipitationDataForCounty={precipitationDataForCounty}
          gddForFarm={gddForFarm}
          gddForState={gddForState}
          gddDataForCounty={gddDataForCounty}
        /> // use an actual farm value
      )}
    </div>
  );
};

export default MarketReport;

MarketReport.propTypes = {
  views: PropTypes.array.isRequired,
  setViews: PropTypes.func.isRequired,
  activeFarm: PropTypes.object.isRequired,
  activeYear: PropTypes.string.isRequired,
  mobileView: PropTypes.bool.isRequired,
};

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Button,
  FormControl,
  makeStyles,
  MenuItem,
  Select,
} from "@material-ui/core";
import { COLORS, Theme } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    alignContent: "center",
    width: "100%",
    borderColor: COLORS.lightGray,
    borderStyle: "solid",
    borderWidth: "1px 0 1px 0",
    padding: "21.5px 0",
    flexWrap: "wrap",
  },
  title: {
    ...Theme.Title,
    marginRight: theme.spacing(3),
    lineHeight: "3rem",
    whiteSpace: "nowrap",
  },
  leftContainer: {
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    alignItems: "center",
    alignContent: "center",
  },
  geographySelect: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    alignContent: "center",
    marginRight: 17,
    paddingTop: 10,
    "& .MuiInputBase-root": {
      height: 40,
    },
  },
  label: {
    fontSize: 14,
    fontWeight: "bold",
    marginRight: 23,
    color: COLORS.darkGray,
  },
  buttonWrapper: {
    display: "flex",
    paddingTop: 10,
  },
  backButton: {
    color: COLORS.green,
    backgroundColor: COLORS.white,
    fontSize: 13,
    fontWeight: "bold",
    border: `1px solid ${COLORS.green}`,
    borderRadius: 5,
    boxShadow: "none",
    textTransform: "none",
  },
}));

/**
 * Renders the header of the market report
 * @param {array} geographies array of objects with county name and active propperites
 * @param {function} setGeographies set state function for making selected geography (county) active
 * @param {boolean} showButton boolean that shows button (for map/chart selection)
 * depending on whether overview or analytics is active
 * @param {object} activeFarm object with farm data
 * @param {array} visualizationViews array of objects with id, label, icon and active properties: chart or map
 * @param {function} setVisualizationView set state function to change between chart and map
 * @param {function} valueAccessor function to access name of county on geography object
 */

const Header = ({
  geographies,
  setGeographies,
  showButton,
  activeFarm,
  visualizationViews,
  setVisualizationView,
  valueAccessor = (d) => d.name,
  selectedState,
  setSelectedState,
}) => {
  const classes = useStyles();

  const [selectedGeography, setSelectedGeography] = useState({
    id: "",
    name: "",
    active: true,
  });

  useEffect(() => {
    //console.log('geographies', geographies)
    if (geographies != null) {
      setSelectedGeography(geographies.find((geography) => geography.active));
      setSelectedState(geographies.find((geography) => geography.active));
    }
    //console.log('selectedGeography', selectedGeography)
  }, [geographies]);
  //const selectedGeography = geographies.find((geography) => geography.active);

  const handleChange = (evt) =>
    setGeographies((prevState) =>
      prevState.map((d) => ({
        ...d,
        active: valueAccessor(d) === evt.target.value,
      }))
    );
  const inActiveVisualizationView = visualizationViews.find((d) => !d.active);

  return (
    <div className={classes.headerContainer}>
      <div className={classes.title}>
        {`Compare ${activeFarm.name} to ${valueAccessor(selectedGeography)}`}
      </div>
      <div className={classes.leftContainer}>
        <div className={classes.geographySelect}>
          <div className={classes.label}>Select geography</div>
          <FormControl variant="outlined">
            <Select
              id="geography-select-outlined"
              value={valueAccessor(selectedGeography)}
              onChange={handleChange}
            >
              {geographies != null &&
                geographies.map((geography) => (
                  <MenuItem
                    key={`menu-item-for-${valueAccessor(geography)}`}
                    value={valueAccessor(geography)}
                  >
                    {valueAccessor(geography)}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
        </div>
        {showButton && (
          <div className={classes.buttonWrapper}>
            <Button
              className={classes.backButton}
              variant="contained"
              onClick={() =>
                setVisualizationView((xs) =>
                  xs.map((d) => ({ ...d, active: !d.active }))
                )
              }
              startIcon={
                <img
                  src={inActiveVisualizationView.icon}
                  alt="switch between map and chart market views"
                />
              }
            >
              {`Switch to ${inActiveVisualizationView.label}`}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  geographies: PropTypes.array.isRequired,
  setGeographies: PropTypes.func.isRequired,
  showButton: PropTypes.bool.isRequired,
  activeFarm: PropTypes.object.isRequired,
  visualizationViews: PropTypes.array.isRequired,
  setVisualizationView: PropTypes.func.isRequired,
  valueAccessor: PropTypes.func,
};

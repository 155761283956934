import React, {useState} from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Divider,
  TextField,
  CircularProgress,
  IconButton,
} from "@material-ui/core";
import SaveIcon from '@material-ui/icons/Save';
import { lightGrey } from '../../../../styles/colors';
import organic from '../../images/organic.svg';
import irrigated from '../../images/irrigated.svg';
import {
  numFormat,
  getDecimalPlaces,
  capitalizeFirstLetter
} from '../../../../utils/helpers';
import { unitConversion } from '../../utils/conversions';
import { NumberFormatCustom, NumberFormatSeeds } from '../../../../utils/NumberFormatCustom';
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import {Parser} from "../Shared/Parser"


const useStyles = makeStyles((theme) => ({
  tableRow: {
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    fontWeight: 500,
    color: theme.palette.text.black,
  }
}));

export function CreateRow({
  variety,
  i,
  opIndex,
  shown,
  perAcre,
  handlePriceChange,
  savePrice,
  savingPrice,
  templateMode
}) {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false)

  // if is NaN, display 0
  const showCost = (cost) => {
    const decimalPlaces = getDecimalPlaces(cost)
    const formatedCost = numFormat(cost, 2, decimalPlaces)
    return formatedCost !== 'NaN' ? formatedCost : 0
  }

  const inputComponent = () => {
    const lowerCaseUnit = variety.unit.toLowerCase()
    if (lowerCaseUnit === 'seeds' || lowerCaseUnit === 'seed') {
      return NumberFormatSeeds
    }
    return NumberFormatCustom
  }

  return (
    <Box key={i}>
      <Divider />
      <Box
        className={classes.tableRow}
        style={{backgroundColor: i % 2 == 0 ? lightGrey : '#ffffff'}}
      >
        <Box m={1} style={{ width: '20%' }}>
          { variety.variety }

          <Box display="flex">
            {variety.isIrrigated && (
              <Box mx={1}>
                <img src={irrigated} alt="irrigated" />
              </Box>
            )}
            {variety.isOrganic && (
              <Box mx={1}>
                <img src={organic} alt="organic" />
              </Box>
            )}
          </Box>

        </Box>

        <Box m={1} style={{ width: '15%', whiteSpace: 'nowrap' }}>
          {numFormat(variety.area)}
          {' Acres'}
        </Box>
        <Box m={1} style={{ width: '20%', whiteSpace: 'nowrap' }}>
          {`${numFormat((variety.quantity / variety.area), 0, 2)}
          ${capitalizeFirstLetter(unitConversion(variety.unit, true))}/Acre`}
        </Box>
        <Box
          m={1}
          display="flex"
          alignItems='center'
          style={{ width: '25%' }}
        >
          <TextField
            variant="outlined"
            style={{
              height: '37px',
              width: '70%',
              minWidth: '80px',
              backgroundColor: '#ffffff'
            }}
            inputProps={{
              style: {
                padding: 10,
                fontWeight: 500,
                fontSize: 14,
                backgroundColor: '#ffffff'
              },
            }}
            InputProps={{
              inputComponent: inputComponent(),
              startAdornment: '$',
            }}
            value={variety.price}
            // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
            onChange={(e) => handlePriceChange(Number(e.target.value.replaceAll(',', '')), variety, i)}
            onBlur={() => savePrice(opIndex, variety.operationID, shown)}
          />
          <Box style={{height: 48}}>
            {
              variety.operationID !== savingPrice ? (
                <IconButton
                  aria-label='save price'
                  color='primary'
                  disabled={savingPrice !== ''}
                  onClick={() => savePrice(opIndex, variety.operationID, shown)}
                >
                    <SaveIcon />
                </IconButton>
              ) : (
                <CircularProgress size={20} thickness={4} style={{margin: 12}}/>
              )
            }
          </Box>
        </Box>
        <Box m={1} style={{ width: '20%', whiteSpace: 'nowrap' }}>
          {'$'}
          {
            perAcre
              ? showCost((variety.price * (variety.quantity / variety.area)))
              : showCost(variety.price * variety.quantity)
          }
          {perAcre && '/Acre'}
        </Box>

      </Box>
      {!templateMode &&
        <Parser name={variety.variety} type={"variety"}/>
      }

    </Box>
  )
}

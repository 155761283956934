// -------------------- IMPORTS --------------------
// React
import React, { useEffect, useState } from 'react';

// material-ui
import {
  Box,
  Container,
  Button,
  Divider,
  MenuItem,
  Select,
  Switch,
  TextField,
} from '@material-ui/core';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import MaskedInput from 'react-text-mask';
import { MenuProps } from '../../../styles/select';
import { blackText } from '../../../styles/colors';
import { Consent } from './Consent';

// API calls and other functionality
import {
  getRMAStatus,
  saveAcreageReport,
  saveInquiryInfo,
  submitRMAFile,
} from '../../../utils/dataFetchers';
import { numFormat } from '../../../utils/helpers';
import { NumberFormatNumber } from '../../../utils/NumberFormatCustom';
import { taxIDTypes } from '../presetData';
import { errorRed } from '../helpers';
import { CustomToolTip } from '../../../utils/customComponents';

// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  errorText: {
    color: errorRed,
    margin: '3px 14px 0px 14px',
    textAlign: 'left',
    fontSize: '0.75rem',
    fontWeight: 400,
    lineHeight: 1.66,
    letterSpacing: '0.03333em',
    paddingLeft: '10px',
  },
  finalElements: {
    padding: '10px',
    paddingBottom: '0px',
    height: '75.2px',
  },
  label: {
    fontSize: '.9rem',
    fontWeight: 500,
  },
  reportBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '14px',
    border: '2px solid rgba(0, 0, 0, 0.3)',
    borderRadius: '4px',
  },
  panes: {
    width: '613px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '16px 24px',
    color: blackText,
  },
  textInput: {
    height: 44.6,
    width: 220,
  },
}));

// -------------------- MAIN FUNCTION --------------------
/**
 * User will enter their required identification information and digitally sign.
 * Options to name report, download CART/GART file, submit to USDA ACRSI Clearinghouse,
 * and download signed PDF.
 *
 * @param {Object} chosenOrg Organization chosen by user that data is from
 * @param {Object} clusSeen Data relating to clus user has viewed
 * @param {Array} CLUSummary Summary of CLUs for 'CART'
 * @param {Number} commodityYear Planting year
 * @param {Array} commodityYears Available years to choose planting year from
 * @param {Boolean} enterPersonalName Whether user has chosen to enter business or personal name
 * @param {Boolean} enterTaxID Whether user has chosen to enter an agendy ID or a tax ID
 * @param {Boolean} gartPath Denotes whether this interface instance is for creating GART files
 * @param {Object} identifier Stores ID information entered by user
 * @param {Boolean} loading Loading state for disabling
 * @param {Object} name Stores personal or business name entered by user
 * @param {Array} operationSummary Summary of Operations for 'GART'
 * @param {String} printedName User name in plaint text for use along with digital signature
 * @param {Number} reinsuranceYear Year for submission of acreage report
 * @param {String} reportName Name report will be associated with
 * @param {Function} setEnterPersonalName Modifies enterPersonalName state
 * @param {Function} setEnterTaxID Modifies enterTaxID state
 * @param {Function} setIdentifier Modifies identifier state
 * @param {Function} setLoading Modifies loading state
 * @param {Function} setName Modifies name state
 * @param {Function} setPdfOpen Opens PDF
 * @param {Function} setPrintedName Store user name in plain text
 * @param {Function} setReinsuranceYear Modifies reinsuranceYear state
 * @param {Function} setReportName Modifies reportName state
 * @param {Function} setSignature Store base64 png of digital signature
 * @param {Function} setSignatureTimeStamp Time user digitally signed
 * @param {String} zoneType Whether following 'CART' or 'GART' path
 *
 * @returns {JSX} Finalize Report Page
 */
export function FinalPage({
  chosenOrg,
  clusSeen,
  CLUSummary,
  commodityYear,
  commodityYears,
  enterPersonalName,
  enterTaxID,
  gartPath,
  identifier,
  loading,
  name,
  operationSummary,
  printedName,
  reinsuranceYear,
  reportName,
  setEnterPersonalName,
  setEnterTaxID,
  setIdentifier,
  setLoading,
  setName,
  setPdfOpen,
  setPrintedName,
  setReinsuranceYear,
  setReportName,
  setSignature,
  setSignatureTimeStamp,
  zoneType,
}) {
  // -------------------- VARIABLES --------------------
  //#region - variables 
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Values needed for valid submissions to RMA
  const originatorIdentification = 'TAgA'; // identifier 'TAgA' chosen by us and given to RMA
  const submissionsource = 'ThirdParty Service'; // 'ThirdParty Service'
  const submittedby = 'Ag-Analytics'; // 'Ag-Analytics'

  // Used for displaying chose Tax ID Type masks
  const taxIDMasks = [
    [/\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/],
    [/\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/, /\d/, /\d/, /\d/],
  ];
  // For getting appropriate data to store in CART/GART file
  const choicesToUse = {
    agency: ['interagencyCustomerIdentifier'],
    tax: ['taxIdentification', 'taxIdentificationTypeCode'],
    business: ['businessName'],
    personal: ['firstName', 'middleName', 'lastName', 'nameSuffix'],
  };

  // Variables used to parse data to create CART/GART file
  const yesCode = ['Y'];
  const otherCode = ['O'];
  const plantingCodes = ['F', 'P', 'V'];
  const skipRowCodes = ['01', '02', '03', '11', '21', '22', '31', '32', '41', '42', '44', '51', '52', '61', '62', '71', '72', '81', '82', '99'];
  const conditionalElements = [
    { element: 'reportedAcreageModifiedReasonCode', indicators: ['reportedAcreageModifiedIndicator'], conditions: [yesCode] },
    { element: 'reportedAcreageModifiedOtherReasonText', indicators: ['reportedAcreageModifiedIndicator', 'reportedAcreageModifiedReasonCode'], conditions: [yesCode, otherCode] },
    { element: 'plantedDateModifiedReasonCode', indicators: ['plantedDateModifiedIndicator'], conditions: [yesCode] },
    { element: 'plantedDateModifiedOtherReasonText', indicators: ['plantedDateModifiedIndicator', 'plantedDateModifiedReasonCode'], conditions: [yesCode, otherCode] },
    { element: 'productPlantingCode', indicators: ['productPlantingCode'], conditions: [plantingCodes] },
    { element: 'skipRowPatternCode', indicators: ['skipRowPatternCode'], conditions: [skipRowCodes] },
  ];
  const geospatialConditionalElements = [
    // geospatialShapeSourceCode is created in createCARTFile function as it should always be 'D'
    { element: 'geospatialShapeSourceCode' },
    { element: 'geospatialShapeModifiedIndicator' },
    { element: 'geospatialShapeModifiedReasonCode', indicators: ['geospatialShapeModifiedIndicator'], conditions: [yesCode] },
    { element: 'geospatialShapeModifiedOtherReasonText', indicators: ['geospatialShapeModifiedIndicator', 'geospatialShapeModifiedReasonCode'], conditions: [yesCode, otherCode] },
    { element: 'geospatialShapeProcessDate', value: [false, 'geospatialShapeProcessDate'] },
    { element: 'geospatialDataSubmissionMethodCode', value: [true, 'geospatialDataSubmissionMethodCode'] },
    { element: 'micsCode' },
    { element: 'micsName', indicators: ['micsCode'], conditions: [otherCode] },
    { element: 'geospatialSourceDataCreationDate', value: [true, 'plantingDate'] },
    { element: 'precisionAgriculturalSubMeterAccuracyIndicator', value: [true, 'precisionAgriculturalSubMeterAccuracyIndicator'] },
  ];

  // For input validation
  const [inputsValid, setInputsValid] = useState(false);
  const [validateInputs, setValidateInputs] = useState(false);
  const taxIDLength = 9;
  const [consentChecked, setConsentChecked] = useState(false);
  const [signed, setSigned] = useState(false);

  // Colors
  const greyBackground = 'rgba(0, 0, 0, 0.2)';
  const whiteBackground = '#ffffff';
  //#endregion
  
  
  // -------------------- USE EFFECTS --------------------
  //#region - useEffects
  useEffect(() => {
    const valueToCheck = enterTaxID ? 'taxIdentification' : 'interagencyCustomerIdentifier';
    // console.log('identifier[valueToCheck] :>> ', identifier[valueToCheck]);
    const value = identifier[valueToCheck];
    if (value !== '') {
      // 'taxIdentification' is the only one with real restrictions - make sure ID is long enough
      if (valueToCheck === 'taxIdentification' && value.length !== taxIDLength) {
        setInputsValid(false);
      } else {
        setInputsValid(true);
      }
    } else {
      setInputsValid(false);
    }
  }, [identifier]);
  //#endregion

  // -------------------- FUNCTIONALITY --------------------
  //#region - create files
  /**
   * Given an object with instructions fitting the requirements of an element to include,
   * get the appropriate element's value and store it in target
   * @param {Object} option information about element and how to get it
   * @param {Object} newAPP CLU or Operation to get the element value from
   * @param {Object} target object to store element value in
   */
  const includeAppropriateData = (option, newAPP, target) => {
    // Handling setting the element correctly
    const curElement = option.element;
    // Some of the elements need to be taken from another element
    if (option.hasOwnProperty('value')) {
      const keyInfo = option.value;
      let valueToUse;

      // This indicates to take value from operationInfo
      if (keyInfo[0]) {
        valueToUse = newAPP.operationInfo[keyInfo[1]];
      } else {
        valueToUse = newAPP[keyInfo[1]];
      }

      if (curElement === 'geospatialShapeProcessDate' || curElement === 'geospatialSourceDataCreationDate') {
        // Can assume that all the dates I receive at this point will be in UTC Time.
        // I will just add a Z at the end if it is not already present as the DB does not return dates with Z's on them
        if (!valueToUse.endsWith('Z')) {
          valueToUse += 'Z';
        }
      }

      target[curElement] = valueToUse;
    }
    // Some of the elements just need special handling
    else if (curElement === 'geospatialShapeModifiedIndicator') {
      target[curElement] = newAPP.geospatialShapeModifiedIndicator ? newAPP.geospatialShapeModifiedIndicator : 'N';
    } else if (curElement === 'skipRowPatternCode') {
      // If we are to include skipRowPatternCode, then just include all these other ones too
      // (Want to avoid checking against all skipRowPatternCode options again if not necessary)
      // (The counts and skipRowConversionFactor are not needed unless code is '99' (and even less is needed if code is '01-03') but will keep this way (include all) unless CART/GART intake does not accept this for some reason.)
      target.skipRowPatternCode = newAPP.skipRowPatternCode;
      target.cropRowCount = newAPP.cropRowCount;
      target.skipRowCount = newAPP.skipRowCount;
      target.cropRowWidth = newAPP.cropRowWidth;
      target.skipRowWidth = newAPP.skipRowWidth;
      target.skipRowConversionFactor = numFormat(newAPP.skipRowConversionFactor / 100, 4, 4, true);
    }
    // Some elements are just direct mappings
    else {
      target[curElement] = newAPP[curElement];
    }
  };

  /**
   * Given a CLU or Operation, create the appropriate agricultural production plan (APP) entry for it.
   * The object follows guidelines by RMA CART/GART Schemas
   * @param {Object} newAPP CLU or Operation object
   * @returns {Object} formatted APP entry
   */
  const handleAPPEntry = (newAPP) => {
    // Format numbers as wanted in CART schema
    const appAcres = numFormat(newAPP.acres, 2, 2, true);
    const finalAcres = numFormat(newAPP.finalReportedAcreage, 2, 2, true);
    // We would used to use newAPP.reportedAcreageModifiedIndicator for this but now we'll just compare the actual values as that is what matters to RMA in the end
    const acrModInd = appAcres === finalAcres ? 'N' : 'Y';
    newAPP.reportedAcreageModifiedIndicator = acrModInd;
    // This number is supposed to be a 'decimal' with form X.XXXX but we cannot display trailing zeros in JSON.. should be fine, right?
    const appSharePerc = numFormat(newAPP.sharePercentage / 100, 4, 4, true);
    // These elements are mandatory - we always want to include them
    // BEWARE: Please DO NOT change element order here. They are set to be easily compared to CART Schema document
    const newAPPEntry = {
      stateAnsiCode: newAPP.operationInfo.stateCode,
      countyAnsiCode: newAPP.operationInfo.countyCode,
      subfieldNumber: newAPP.subfieldNumber,
      coreProductCode: newAPP.crop,
      coreProductTypeCode: newAPP.cropType,
      productIntendedUseCode: newAPP.intendedUse,
      originalReportedAcreage: appAcres,
      finalReportedAcreage: finalAcres,
      reportedAcreageModifiedIndicator: acrModInd,
      acreageCalculationProjectionCode: newAPP.projCode,
      originalPlantedDate: newAPP.operationInfo.date,
      finalPlantedDate: newAPP.finalPlantedDate,
      plantedDateModifiedIndicator: newAPP.plantedDateModifiedIndicator ? newAPP.plantedDateModifiedIndicator : 'N',
      irrigationPracticeCode: newAPP.irrigationPracticeCode,
      organicPracticeTypeCode: newAPP.organicPracticeTypeCode,
      croppingPracticeCode: newAPP.croppingPracticeCode,
      // For now, assuming there will always only be one customer included
      producerShare: [{ fileCustomerIdentifier: 1, producerSharePercent: appSharePerc }],
    };

    // conditionalElements variables handles the following elements:
    // reportedAcreageModifiedReasonCode, reportedAcreageModifiedOtherReasonText,
    // plantedDateModifiedReasonCode, plantedDateModifiedOtherReasonText,
    // productPlantingCode, skipRowPatternCode (and the other 5 associated skiprow elements)

    // Some elements are only added based on conditions, so will do these separately
    for (const option of conditionalElements) {
      let foundNoIssues = true;
      // If any of the indicator values don't match any one of their associated conditions, then don't include
      for (const [index, condition] of option.conditions.entries()) {
        const includeFlags = condition.filter((e) => e === newAPP[option.indicators[index]]);
        if (!includeFlags.length) {
          foundNoIssues = false;
          break;
        }
      }

      if (foundNoIssues) {
        includeAppropriateData(option, newAPP, newAPPEntry);
      }
    }

    // geospatialConditionalElements variables handles the following elements:
    // geospatialShapeSourceCode,
    // geospatialShapeModifiedIndicator, geospatialShapeModifiedReasonCode, geospatialShapeModifiedOtherReasonText,
    // geospatialShapeProcessDate, geospatialDataSubmissionMethodCode, micsCode, micsName,
    // geospatialSourceDataCreationDate, precisionAgriculturalSubMeterAccuracyIndicator

    // Handle geospatialInfo and geospatialMetadata elements separately - only include if user decided to
    if (newAPP.includeGeospatialInReport === 'Y') {
      // Handle boundary
      const boundary = typeof newAPP.boundary === 'string' ? JSON.parse(newAPP.boundary) : newAPP.boundary;
      delete boundary.bbox; // This can sometimes be present. Not needed for Acrsi
      const geospatialInfo = {
        // id: idx,
        type: 'Feature',
        geometry: boundary,
        properties: {},
      };

      // Default geospatialShapeSourceCode value
      // Assuming it should always be 'Digitized' (ie. "Third party provider/producer has drawn/digitized the shape/polygon")
      newAPP.geospatialShapeSourceCode = 'D';

      const geospatialMetadata = {};
      for (const option of geospatialConditionalElements) {
        let foundNoIssues = true;

        // The only condition might have been includeGeospatialInReport - check if there is another one
        if (option.conditions) {
          // If any of the indicator values don't match any one of their associated conditions, then don't include
          for (const [index, condition] of option.conditions.entries()) {
            const includeFlags = condition.filter((e) => e === newAPP[option.indicators[index]]);
            if (!includeFlags.length) {
              foundNoIssues = false;
              break;
            }
          }
        }

        // Populate geospatialMetadata object
        if (foundNoIssues) {
          includeAppropriateData(option, newAPP, geospatialMetadata);
        }
      }

      // Store results under geospatialInfo key for specific APP
      geospatialInfo.properties = geospatialMetadata;
      newAPPEntry.geospatialInfo = geospatialInfo;
      }

    return newAPPEntry;
  }

  // Gathers all the required data to create a CART file for download/transmission purposes
  // ALL CART elements should have a chance of being included from this function (so they should all be directly named in here)
  const createCARTFile = () => {
    // Needed vars
    const cartFileJSON = {
      cropYear: commodityYear,
      originatorIdentification,
    }; // Object
    const farmInfo = [];
    let tractInfo = [];
    let fieldInfo = [];
    const customerInfo = [];
    // console.log('CLUSummary :>> ', CLUSummary);

    // Compile the required data - Separate all CLU Section info by farm, tract, field/CLU, and agriculturalProductionPlans/operations
    for (const [cluSectionIndex, cluSection] of CLUSummary.entries()) {
      const cluExample = cluSection[0];
      const operationInfo = [];

      // Skip adding "fake CLU" data to CART reports
      if (cluExample.clu_identifier === 'X') { continue; }

      // agriculturalProductionPlan (APP) / operation level
      for (const cluAPP of cluSection) {
        const cluAPPEntry = handleAPPEntry(cluAPP);
        // Store this APP
        operationInfo.push(cluAPPEntry);
      }

      // Use clusSeen to get the common CLU information for this CLU
      const referenceCLUObject = clusSeen[cluExample.clu_identifier];
      // Field/CLU level
      const fieldEntry = {
        cluIdentifier: cluExample.clu_identifier,
        fieldNumber: +referenceCLUObject.clu_number,
        cluProducerReviewRequestIndicator: referenceCLUObject.cluProducerReviewRequestIndicator,
        agriculturalProductionPlan: operationInfo,
      };
      fieldInfo.push(fieldEntry);

      // Field->tract and tract->farm levels need to be handled a bit differently as farms(/tracts) can have multiple tracts(/fields)
      // (This is already taken care of above for APP->field/CLU and is not needed for farm->root as farm only appears once per report)

      const nextSectionIndex = cluSectionIndex + 1;
      const nextCluExample = nextSectionIndex < CLUSummary.length ? clusSeen[CLUSummary[nextSectionIndex][0].clu_identifier] : undefined;
      // If there's anything left to iterate over and next section's farm number is the same (same farm, different tract),
      // then store data in the same tract array (do not reset tractInfo)
      if (nextSectionIndex < CLUSummary.length && nextCluExample.farm_number === referenceCLUObject.farm_number) {
        // Since everything is sorted, we know we are done with a tract when we encounter the next number
        if (nextCluExample.tract_number !== referenceCLUObject.tract_number) {
          // Tract level
          const tractEntry = {
            tractNumber: +referenceCLUObject.tract_number,
            field: fieldInfo,
          };
          tractInfo.push(tractEntry);

          // Reset this for the next tract
          fieldInfo = [];
        }
      } else {
        // console.log('About to store farmEntry in farmInfo');
        // Tract level
        const tractEntry = {
          tractNumber: +referenceCLUObject.tract_number,
          field: fieldInfo,
        };
        tractInfo.push(tractEntry);

        // Farm level
        // Convert dev placeholders, if necessary, for testing submission endpoints
        const adminCode = referenceCLUObject.admin_state === 'FK' ? cluExample.operationInfo.stateCode : referenceCLUObject.admin_state;
        const countyCode = referenceCLUObject.admin_county === 'DEV' ? cluExample.operationInfo.countyCode : referenceCLUObject.admin_county;
        const farmEntry = {
          administrativeStateFsaCode: adminCode, // String
          administrativeCountyFsaCode: countyCode, // String
          farmNumber: +referenceCLUObject.farm_number, // Integer
          tract: tractInfo, // Array
        };
        farmInfo.push(farmEntry);
        // console.log('farmEntry :>> ', farmEntry);

        // Reset these for the next farm
        fieldInfo = [];
        tractInfo = [];
      }
    }

    // Get customer information
    customerInfo.push({ fileCustomerIdentifier: 1 });
    for (const entry of choicesToUse[enterTaxID ? 'tax' : 'agency']) {
      customerInfo[0][entry] = identifier[entry];
    }
    for (const entry of choicesToUse[enterPersonalName ? 'personal' : 'business']) {
      if (name[entry] !== '') customerInfo[0][entry] = name[entry];
    }

    // Store and return
    // console.log('farmInfo :>> ', farmInfo);
    cartFileJSON.farm = farmInfo; // Array
    cartFileJSON.customer = customerInfo; // Array
    return cartFileJSON;
  };

  // Gathers all the required data to create a GART file for download/transmission purposes
  // ALL GART elements should have a chance of being included from this function (so they should all be directly named in here)
  const createGARTFile = () => {
    // Needed vars
    const gartFileJSON = {
      cropYear: commodityYear,
      originatorIdentification,
    }; // Object
    const agriculturalProductionPlan = [];
    const customerInfo = [];
    // console.log('operationSummary :>> ', operationSummary);

    // Compile the required data - Separate all Operation Section info into agriculturalProductionPlans/operations
    for (const [opSectionIndex, opSection] of operationSummary.entries()) {
      // console.log('opSection :>> ', opSection);

      // Get all data at the agriculturalProductionPlan (APP) / operation level
      for (const opAPP of opSection) {
        const opAPPEntry = handleAPPEntry(opAPP);
        // Store this APP
        agriculturalProductionPlan.push(opAPPEntry);
      }
    }

    // Get customer information
    customerInfo.push({ fileCustomerIdentifier: 1 });
    for (const entry of choicesToUse[enterTaxID ? 'tax' : 'agency']) {
      customerInfo[0][entry] = identifier[entry];
    }
    for (const entry of choicesToUse[enterPersonalName ? 'personal' : 'business']) {
      if (name[entry] !== '') customerInfo[0][entry] = name[entry];
    }

    // Store and return
    gartFileJSON.agriculturalProductionPlan = agriculturalProductionPlan; // Array
    gartFileJSON.customer = customerInfo; // Array
    // console.log('gartFileJSON :>> ', gartFileJSON);
    return gartFileJSON;
  };

  // #######################################################################################################################################
  // NOTE: FOR CART & GART CODE ABOVE, STILL HAVEN'T FULLY TESTED:
  //    - Will need access to government endpoint for final, true testing
  //
  //    - Will also have to make sure all uploaded CLU data have column names that are the same as what we use
  //    (might have to:
  //        - (current, prob final) automatically convert column names ourselves,
  //        - (not great) have an interface where they can do the mapping when they upload,
  //        - (would be nice but unlikely) or maybe mappings will be exact as this is the standard and won't need to do anything...
  //    )
  //
  // #######################################################################################################################################
  //#endregion
  
  //#region - save files
  const specialInputs = ['interagencyCustomerIdentifier', 'taxIdentification', 'businessName', 'firstName', 'middleName', 'lastName', 'nameSuffix'];

  // Creates the correct data file and calls endpoint to store information about created report file
  const handleSave = async (JSONFile, recipient) => {
    try {
      // This is similar to what is done by handleGenerateReport for transmissions. Changes made here should probably ALSO be made there 
      const report = {
        reportName,
        taxIdentificationTypeCode: identifier.taxIdentificationTypeCode,
        // OrganizationID: null,
        cropYear: commodityYear,
        'Reinsurance Year': reinsuranceYear,
        originatorIdentification,
        AcrsiFile: JSON.stringify(JSONFile),
        isDeleted: false,
        created_at: new Date(),
        submissionsource,
        submittedby,
        // originalTransmissionId: null, // This is given to us after submission to RMA so cannot be included before that
        transmission_date: new Date(),
        transmitted_to: recipient,
      };

      // Iterate through these and only include them if they exist.
      for (const input of specialInputs) {
        // Check to see if this is an identifier (or a name)
        if (input === 'interagencyCustomerIdentifier' || input === 'taxIdentification') {
          // If there is a value for it - there should be at least one or the other. But will store both if they exist
          if (identifier[input]) {
            report[input] = identifier[input];
          }
        } else {
          // If there is a value for it
          if (name[input]) {
            report[input] = name[input];
          }
        }
      }

      // Save report and notify user of success or failure
      const response = await saveAcreageReport(report);
      if (typeof response !== 'string' && response?.id) {
        enqueueSnackbar(`Successfully saved ${reportName}`);
        return response.id;
      } else {
        enqueueSnackbar('Unable to save report at this time. Please try again later.');
      }
    } catch (err) {
      console.error(err);
      enqueueSnackbar('Unable to save report at this time. Please try again later.');
    }
  };

  // Calls the RMA ACRSI inquiry endpoint to retrieve the status of the submission associated with the transmissionId provided.
  // If the data has been processed by RMA, update the Reports table record associated with given tableID with the result of the processing.
  const getAndSaveRMAInquiry = async (tableID, transmissionId) => {
    // Call RMA ACRSI inquiry endpoint
    const response = await getRMAStatus(transmissionId);
    // console.log('response :>> ', response);

    const requestBody = {tableID, transmissionId};
    // Check return
    if (!response || typeof response === 'string' || !response.status) {
      // Something went wrong. Do not return however so we can still store transmissionId
    }
    else {
      requestBody.RMAResponse = response.status;
    }

    // Save transmissionId (and maybe status) to Reports table
    await saveInquiryInfo(requestBody);
  }

  /**
   * Handles the appropriate method for each type of download/transmision possible
   * fileType options are: 'CART', 'TransmitCART', 'GART', 'TransmitGART'
   * @param {String} fileType type of file requested for download
   */
  const handleGenerateReport = async (fileType) => {
    try {
      // Make sure all required personal info has been entered
      if (!inputsValid) {
        setValidateInputs(true);
        const type = fileType === zoneType ? 'downloading' : 'transmitting';
        enqueueSnackbar(`Please complete all required fields before ${type} your report`);
        return;
      }

      // Decide who recipient will be
      let recipient;
      if (fileType === zoneType) {
        recipient = 'User Download'; // 'User Download'
      } else if (fileType === `Transmit${zoneType}`) {
        recipient = 'RMA ACRSI Intake Endpoint'; // 'RMA ACRSI Intake Endpoint'
      } else {
        // recipient = '[email Goes Here]'; // When I implement option to email report
        enqueueSnackbar('An error occured while creating your report. Please contact us for further assistance.');
        console.error('No other fileType is allowed.');
        return;
      }

      // Set loading here as could return prior to this and no reason to show loading spinner
      setLoading(true);
      let JSONFile;
      // Create the appropriate file
      if (fileType === 'CART' || fileType === 'TransmitCART') {
        JSONFile = createCARTFile();
      } else {
        JSONFile = createGARTFile();
      }
      // Whatever the case, save the generated file
      const tableID = await handleSave(JSONFile, recipient);

      // CART or GART
      if (fileType === zoneType) {
        // Take the necessary steps to download the file
        const dataStr = `data:text/json;charset=utf-8, ${JSON.stringify(JSONFile)}`;
        const downloadAnchorNode = document.createElement('a');
        downloadAnchorNode.setAttribute('href', dataStr);
        downloadAnchorNode.setAttribute('download', `${reportName}.json`);
        document.body.appendChild(downloadAnchorNode); // required for firefox
        downloadAnchorNode.click();
        downloadAnchorNode.remove();
      }
      // TransmitCART or TransmitGART
      else if (fileType === `Transmit${zoneType}`) {
        // This is similar to what is done by handleSave. Changes made here should ALSO be made there 
        const report = {
          AcrsiFile: JSONFile,
          submissionsource,
          submittedby,
          originalTransmissionId: null, // ...
          timestamp: new Date(),
        };
        // BEWARE: 
        // - If testing on dev / prod with local server, Visual Studio has to be opened as an Administrator to be able to read the ccertificate's private key
        // - Firefox does not seem to be able to access/use the certificate? for checking status of transmission. Chrome works however.
        const response = await submitRMAFile({fileType: zoneType, file: JSON.stringify(report)});
        // Example response :>>  {"status":"ok","transmissionId":2351422}

        // NOTE: - We need to decide whether we want to use transmissionId and how
        // Deciding that is based on response from RMA. If repeated clu_identifiers from new submissions overwrite previous submissions, then we can ignore it for CART.
        // But, that won't work for GART. But it would be hard to make this happen for user as they could use a different combination of fields each time...
        // Start we question to RMA and figure out what to do from there

        try {
          const jsonData = JSON.parse(response);
          if (typeof jsonData.status === 'string' && jsonData.status.toLowerCase() === 'ok') {
            enqueueSnackbar(`Successfully transmitted report to RMA.`);
            getAndSaveRMAInquiry(tableID, jsonData.transmissionId);
          } else {
            enqueueSnackbar('Unable to transmit report at this time. Please try again later.');
          }
        } catch (err) {
          enqueueSnackbar('Unable to transmit report at this time. Please try again later.');
        }
      }
      else {
        enqueueSnackbar('An error occured while creating your report. Please contact us for further assistance.');
        console.error('No other fileType is allowed.');
      }
    } catch (err) {
      enqueueSnackbar('An error occured while creating your report. Please try again later.');
      console.error('Error: ', err);
    } finally {
      setLoading(false);
    }
  };
  //#endregion

  //#region - JSX for displaying page
  // Decides whether or not to add an asterisk (to denote required status) to the label for this element
  const decideIfRequiredLabel = (label, targetValue, validValue) => {
    if (targetValue === validValue) { label += ' *'; }
    return label;
  };

  // Decides whether or not this element should be validated and
  // if it should be, decides whether the input for this element is invalid
  const decideIfInvalidInput = (targetValue, validValue, element, input) => {
    // console.log('targetValue, validValue, element, input :>> ', targetValue, validValue, element, input);
    // If user hasn't clicked one of the "Download ..." or "Transmit ..." buttons yet,
    // or this element does not matter for current validation, do not show errors
    if (!validateInputs || targetValue !== validValue) { return; }

    if (element === 'interagencyCustomerIdentifier' || element === 'reportName') {
      if (input === '') { return true; }
    } else if (element === 'taxIdentification') {
      // console.log('input.length :>> ', input.length);
      if (input.length !== taxIDLength) { return true; }
    }
    // If nothing deserved to return true, then there is an issue
    return false;
  };

  // Left-most boxes for user to enter identification information to be included in the generated report
  const identificationInfo = () => (
    <Box className={classes.panes}>
      {/* Identification Number */}
      <Box className={classes.reportBox}>
        <Box fontSize={16.67} width="100%">
          Enter
          {' '}
          <b>either</b>
          {' '}
          your
          {' '}
          <em>Agency Customer Identifier</em>
          {' '}
          or your
          {' '}
          <em>Tax ID</em>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Box>
            Agency
          </Box>

          <Switch
            color="primary"
            checked={enterTaxID}
            onChange={() => setEnterTaxID(!enterTaxID)}
            name="identification-type"
            inputProps={{ 'aria-label': 'identification-type' }}
          />

          <Box>
            Tax
          </Box>
        </Box>

        <Box display="flex">
          <Box className={classes.finalElements}>
            <Box className={classes.label}>{decideIfRequiredLabel('Agency Customer Identifier', enterTaxID, false)}</Box>
            <TextField
              className={classes.textInput}
              variant="outlined"
              style={{ backgroundColor: enterTaxID ? greyBackground : whiteBackground }}
              value={identifier.interagencyCustomerIdentifier}
              onChange={(e) => setIdentifier({ ...identifier, interagencyCustomerIdentifier: e.target.value })}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatNumber,
              }}
              disabled={enterTaxID}
              error={decideIfInvalidInput(enterTaxID, false, 'interagencyCustomerIdentifier', identifier.interagencyCustomerIdentifier)}
              helperText={decideIfInvalidInput(enterTaxID, false, 'interagencyCustomerIdentifier', identifier.interagencyCustomerIdentifier) ? 'Invalid input' : ''}
            />
          </Box>
          <Box margin="auto" padding="0px 16px" fontWeight={500}>
            OR
          </Box>
          <Box>
            <Box className={classes.finalElements}>
              <Box className={classes.label}>{decideIfRequiredLabel('Tax ID', enterTaxID, true)}</Box>
              <MaskedInput
                className={classes.textInput}
                style={{
                  backgroundColor: !enterTaxID ? greyBackground : whiteBackground,
                  padding: 12,
                  border: decideIfInvalidInput(enterTaxID, true, 'taxIdentification', identifier.taxIdentification) ? `1px solid ${errorRed}` : '1px solid rgba(0, 0, 0, 0.23)',
                  borderRadius: '4px',
                }}
                value={identifier.taxIdentification}
                mask={taxIDMasks[identifier.taxIdentificationTypeCode - 1]}
                showMask
                placeholderChar={'\u2000'}
                // Remove all spaces (not really spaces, is '\u2000') and hyphen from the input
                onChange={(e) => {
                  setIdentifier({ ...identifier, taxIdentification: (e.target.value).replaceAll('-', '').replaceAll('\u2000', '') });
                }}
                disabled={!enterTaxID}
              />
            </Box>
            { decideIfInvalidInput(enterTaxID, true, 'taxIdentification', identifier.taxIdentification)
              && <p className={classes.errorText}>Invalid input</p>}
            <Box className={classes.finalElements}>
              <Box className={classes.label}>Tax ID Type</Box>
              <Select
                className={classes.textInput}
                variant="outlined"
                MenuProps={MenuProps}
                style={{ backgroundColor: !enterTaxID ? greyBackground : whiteBackground }}
                value={identifier.taxIdentificationTypeCode}
                onChange={(e) => setIdentifier({ ...identifier, taxIdentificationTypeCode: e.target.value })}
                disabled={!enterTaxID}
              >
                {taxIDTypes.map((option) => (
                  <MenuItem key={option.code} value={option.code}>
                    {option.display}
                  </MenuItem>
                ))}
              </Select>
            </Box>
          </Box>
        </Box>
      </Box>

      {/* Business' or Individual's Name */}
      <Box className={classes.reportBox} mt="10px">
        <Box fontSize={16.67} width="100%">
          Optional: Enter
          {' '}
          <b>either</b>
          {' '}
          your
          {' '}
          <em>Business Name</em>
          {' '}
          or your
          {' '}
          <em>Personal Name</em>
        </Box>
        <Box
          display="flex"
          alignItems="center"
        >
          <Box>
            Business
          </Box>

          <Switch
            color="primary"
            checked={enterPersonalName}
            onChange={() => setEnterPersonalName(!enterPersonalName)}
            name="business-personal-name"
            inputProps={{ 'aria-label': 'business-personal-name' }}
          />

          <Box>
            Individual
          </Box>
        </Box>

        <Box display="flex">
          <Box className={classes.finalElements}>
            <Box className={classes.label}>Business Name</Box>
            <TextField
              className={classes.textInput}
              variant="outlined"
              style={{ backgroundColor: enterPersonalName ? greyBackground : whiteBackground }}
              value={name.businessName}
              onChange={(e) => setName({ ...name, businessName: e.target.value })}
              inputProps={{
                style: {
                  padding: 12,
                  height: 20.6,
                },
                maxLength: 100,
              }}
              disabled={enterPersonalName}
            />
          </Box>
          <Box margin="auto" padding="0px 16px" fontWeight={500}>
            OR
          </Box>
          <Box>
            <Box className={classes.finalElements}>
              <Box className={classes.label}>First Name</Box>
              <TextField
                className={classes.textInput}
                variant="outlined"
                style={{ backgroundColor: !enterPersonalName ? greyBackground : whiteBackground }}
                value={name.firstName}
                onChange={(e) => setName({ ...name, firstName: e.target.value })}
                inputProps={{
                  style: {
                    padding: 12,
                    height: 20.6,
                  },
                  maxLength: 20,
                }}
                disabled={!enterPersonalName}
              />
            </Box>
            <Box className={classes.finalElements}>
              <Box className={classes.label}>Middle Name</Box>
              <TextField
                className={classes.textInput}
                variant="outlined"
                style={{ backgroundColor: !enterPersonalName ? greyBackground : whiteBackground }}
                value={name.middleName}
                onChange={(e) => setName({ ...name, middleName: e.target.value })}
                inputProps={{
                  style: {
                    padding: 12,
                    height: 20.6,
                  },
                  maxLength: 20,
                }}
                disabled={!enterPersonalName}
              />
            </Box>
            <Box className={classes.finalElements}>
              <Box className={classes.label}>Last Name</Box>
              <TextField
                className={classes.textInput}
                variant="outlined"
                style={{ backgroundColor: !enterPersonalName ? greyBackground : whiteBackground }}
                value={name.lastName}
                onChange={(e) => setName({ ...name, lastName: e.target.value })}
                inputProps={{
                  style: {
                    padding: 12,
                    height: 20.6,
                  },
                  maxLength: 25,
                }}
                disabled={!enterPersonalName}
              />
            </Box>
            <Box className={classes.finalElements}>
              <Box className={classes.label}>Name Suffix</Box>
              <TextField
                className={classes.textInput}
                variant="outlined"
                style={{ backgroundColor: !enterPersonalName ? greyBackground : whiteBackground }}
                value={name.nameSuffix}
                onChange={(e) => setName({ ...name, nameSuffix: e.target.value })}
                inputProps={{
                  style: {
                    padding: 12,
                    height: 20.6,
                  },
                  maxLength: 10,
                }}
                disabled={!enterPersonalName}
              />
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  // Right-most box with buttons for downloading and submitting acreage report
  const generateReport = () => (
    <Box className={classes.panes}>
      <Box className={classes.reportBox} width={502} >
        <Box mb={1} fontSize={16.67} textAlign="center">
          Submit your completed acreage report, or download a copy in PDF or digital format
        </Box>
        <Box p={1}>
          <Box className={classes.label}>Report Name</Box>
          <TextField
            className={classes.textInput}
            style={{ width: 350 }}
            variant="outlined"
            value={reportName}
            onChange={(e) => setReportName(e.target.value)}
            inputProps={{
              style: {
                padding: 12,
                height: 20.6,
              },
            }}
            disabled={loading}
            error={decideIfInvalidInput(null, null, 'reportName', reportName)}
            helperText={decideIfInvalidInput(null, null, 'reportName', reportName) ? 'Invalid input' : ''}
          />
        </Box>

        <Box p={1}>
          <Box className={classes.label}>Reinsurance Year</Box>
          <Select
            variant="outlined"
            MenuProps={MenuProps}
            value={reinsuranceYear}
            className={classes.textInput}
            onChange={(e) => {
              setReinsuranceYear(e.target.value);
            }}
            disabled={loading}
          >
            {commodityYears.map((year) => (
              <MenuItem key={year} value={year}>{year}</MenuItem>
            ))}
          </Select>
        </Box>

        {/* These commented out additions were made for taking screenshots for the Overview page. Left in case needed again later. */}
        {/* <Box display="flex">
          <Box> */}
            {/* Download or Transmit Options */}
            <Box p={1}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => handleGenerateReport(zoneType)}
                disabled={loading}
                style={{ width: 230 }}
                disableElevation
              >
                Download Report
              </Button>
            </Box>

            <Box p={1}>
              <Button
                color="primary"
                variant="contained"
                size="large"
                onClick={() => setPdfOpen(true)}
                disabled={loading}
                style={{ width: 230 }}
                disableElevation
              >
                Create
                {(consentChecked && signed) ? ' Signed ' : ' '}
                PDF
              </Button>
            </Box>
          {/* </Box> */}

          {/* This button saves and will transmit to RMA (Can also do: AIP, etc.?) */}
          <Box p={1}>
            <Button
              color="primary"
              variant="contained"
              size="large"
              onClick={() => handleGenerateReport(`Transmit${zoneType}`)}
              disabled={loading || !consentChecked || !signed}
              style={{ width: 230 }}
              disableElevation
            >
              Submit to USDA ACRSI Clearinghouse
            </Button>
          </Box>
        {/* </Box> */}
      </Box>
    </Box>
  );

  /**
   * Handles creating the components for the "Finalize Report" Page
   * @returns JSX of Finalize Report page
   */
  const finalPage = () => (
    <Container maxWidth="xl" disableGutters style={{ display: "flex", flexDirection: "column"}}>
      {/* "Title" and Instructions */}
      <Box
        textAlign="center"
        fontSize={20}
        p={2}
        pt={3}
      >
        <Box fontWeight={500}>
          Enter identification information and generate your report or pdf
        </Box>
      </Box>
      <Divider style={{margin: "0px 16px"}}/>

      {/* Page Data */}
      <Box px={2} style={{overflowX: "auto"}}>
        <Box display="flex" justifyContent="space-around" minWidth="1713px">
          {/* Left-most pane */}
          {identificationInfo()}

          {/* Center pane */}
          <Box className={classes.panes}>
            <Box className={classes.reportBox}>
              <Consent
                checked={consentChecked}
                setChecked={setConsentChecked}
                setSigned={setSigned}
                setSignatureImage={setSignature}
                printedName={printedName}
                setPrintedName={setPrintedName}
                setSignatureTimeStamp={setSignatureTimeStamp}
              />
            </Box>
          </Box>

          {/* Right-most pane */}
          {generateReport()}
        </Box>
      </Box>
    </Container>
  );
  //#endregion

  // -------------------- RETURN --------------------
  return (finalPage());
}

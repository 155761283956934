import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { COLORS } from "../../../../../../../constants";

const useStyles = makeStyles(() => ({
  path: {
    stroke: COLORS.black,
    strokeWidth: "2px",
    fillOpacity: 0,
    pointerEvents: "none",
  },
}));

const ActivePath = ({ d }) => {
  const classes = useStyles();
  return <path className={classes.path} d={d} />;
};

export default ActivePath;

ActivePath.propTypes = {
  d: PropTypes.string.isRequired,
};

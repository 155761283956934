// -------------------- IMPORTS --------------------
// Functionality
import * as wkt from 'terraformer-wkt-parser';
import * as turf from '@turf/turf';
import { formatDate } from '../ProfitLayers/utils/conversions';
import { cartDropdownData } from './presetData';
import { getProfitMap, getVectorImage } from '../../utils/dataFetchers';

// Helpful Packages

// -------------------- VARIABLES --------------------

// Red color for error messages
export const errorRed = '#ff1744';

// -------------------- HELPER FUNCTIONS --------------------

//#region - parse and convert dates
/**
 * Remove time component and format date as (mm/dd/yyyy) or (yyyy-mm-dd)
 * @param {String} date date to be formatted (allowed format: yyyy-mm-ddT... or yyyy/mm/ddT...)
 * @param {Boolean} callFormat if provided, further converts date format from (mm/dd/yyyy) to (yyyy-mm-dd)
 * @returns {String} correctly formatted string of date
 */
export const parseDate = (date, callFormat = false) => {
  const dash = date.includes('-');
  const d = dash ? date.split('T')[0].split('-') : date.split('T')[0].split('/');
  const d2 = `${d[1]}/${d[2]}/${d[0]}`;
  return callFormat ? formatDate(d2) : d2;
};

/**
 * Format Date object as (yyyy-mm-dd)
 * @param {Date} date Date object to be formatted
 * @returns {String} correctly formatted string of date
 */
export const parseDateObject = (date) => {
  // toLocaleString -> "mm/dd/yyyy, XX:XX:XX XM"
  // toLocaleDateString -> "mm/dd/yyyy"
  const datePieces = date.toLocaleDateString().split('/');
  const formattedDate = `${datePieces[2].padStart(4, '0')}-${datePieces[0].padStart(2, '0')}-${datePieces[1].padStart(2, '0')}`;
  return formattedDate;
};

/**
 * BEWARE: This WILL modify the argument.
 * Converts the given UTC date and time to local date and time.
 * @param {Date} lastTimeSaved Date object to convert
 * @returns {void} undefined
 */
export const convertToLocalDatetime = (lastTimeSaved) => {
  const offset = new Date().getTimezoneOffset();
  lastTimeSaved.setMinutes(lastTimeSaved.getMinutes() - offset);
};
//#endregion

//#region - extract data on load
/**
 * Extract and format CLU data for CLUInfo array of saved "operation"
 * @param {Object} clu clu data to extract info from
 * @returns {Object} properly formatted CLU data
 */
const extractCLUInfo = (clu) => {
  // Need to make sure that any data that would be added later at CLU level is also added back here.
  // So, prob want to check saveReportData and include anything from there that could be set for CLU...
  // We want to do pretty much the opposite (but not exactly) of what is happening there
  // Don't however need to include elements that will be included by createCLUSummary
  // Being: farmName, fieldName, operationTitle, linkInfo, crops, date, countyCode, stateCode, and subfieldNumber

  const cropToUse = clu.coreProductCode === 'None' ? 'Found' : clu.coreProductCode;
  const cropTypeToUse = clu.coreProductTypeCode === 'None' ? 'Found' : clu.coreProductTypeCode;
  const CLUInfoEntry = {
    acres: clu.originalReportedAcreage,
    crop: cropToUse,
    cropType: cropTypeToUse,
    cropTypes: cropToUse,
    intendedUse: clu.productIntendedUseCode === 'None' ? 'Found' : clu.productIntendedUseCode,
    intendedUses: `${cropToUse}${cropTypeToUse}`,
    operationID: clu.operationID,
    projCode: clu.acreageCalculationProjectionCode,
    savedAndLoaded: true,
    sharePercentage: clu.producerSharePercent * 100,
    shown: !clu.isHidden,
  };
  // If needed, reset doNotInclude key at CLU level on loading saved data
  if (clu.coreProductCode === 'None') {
    CLUInfoEntry.doNotInclude = 'No Match Found';
  }

  // All of the direct matches THAT CANNOT BE NULL IN DB are just put in an array to iterate over it
  const directMappings = ['admin_county', 'admin_state', 'boundary', 'clu_identifier', 'clu_number', 'cluProducerReviewRequestIndicator',
    'created_at', 'croppingPracticeCode', 'displayNumber', 'farm_number', 'finalPlantedDate', 'finalReportedAcreage',
    'geospatialShapeModifiedIndicator', 'geospatialShapeProcessDate', 'includeGeospatialInReport', 'irrigationPracticeCode', 'micsCode',
    'organicPracticeTypeCode', 'plantedDateModifiedIndicator', 'reportedAcreageModifiedIndicator', 'section', 'tract_number', 'zoneID'];
  for (const element of directMappings) {
    CLUInfoEntry[element] = clu[element];
  }

  // We only want to include these elements if they have values
  const conditionalElements = ['cropRowCount', 'cropRowWidth', 'geospatialShapeModifiedReasonCode',
    'geospatialShapeModifiedOtherReasonText', 'micsName', 'originalBoundary', 'plantedDateModifiedReasonCode',
    'plantedDateModifiedOtherReasonText', 'productPlantingCode', 'reportedAcreageModifiedReasonCode',
    'reportedAcreageModifiedOtherReasonText', 'skipRowCount', 'skipRowPatternCode', 'skipRowWidth'];
  // Map the rest of the needed elements
  for (const element of conditionalElements) {
    if (clu[element]) { CLUInfoEntry[element] = clu[element]; }
  }

  // Handle these two conditional elements a little differently
  CLUInfoEntry.skipRowConversionFactor = clu.skipRowConversionFactor * 100;
  const { skipRowPatternCode } = clu;
  // This is not necessary at the operation level for 'GART' as what is disabled is handled directly in AdvancedOptions
  if (skipRowPatternCode && !cartDropdownData.skipRowPatternCodes.specificWidths.has(skipRowPatternCode)) {
    CLUInfoEntry.disableElements = 'butWidth';
  } else {
    CLUInfoEntry.disableElements = true;
  }

  return CLUInfoEntry;
};

// Given mappingObject, inserts operations into a farm->field->[operation1, ...] hierarchy, creating keys as needed
// Reason it's here is that it was previously also used in this file (helpers.js).
export const addToMappingObject = (mappingObject, farmID, fieldID, operationID, operation) => {
  // console.log('operation :>> ', operation);
  // Add new operation
  const newOp = [operation];

  // Existing farmID
  if (mappingObject.hasOwnProperty(farmID)) {
    const farmMappings = mappingObject[farmID];

    // Existing fieldID
    if (farmMappings.hasOwnProperty(fieldID)) {
      const fieldMappings = farmMappings[fieldID];

      // Existing operationID - this should only happen for 'GART' when breaking MultiPolygons
      if (fieldMappings.hasOwnProperty(operationID)) {
        fieldMappings[operationID].push(operation);
      }
      // New operationID
      else {
        fieldMappings[operationID] = newOp;
      }
    }
    // New fieldID
    else {
      farmMappings[fieldID] = {
        [operationID]: newOp,
      };
    }
  }
  // New farmID
  else {
    mappingObject[farmID] = {
      [fieldID]: {
        [operationID]: newOp,
      },
    };
  }
};

/**
 * Takes the response from getOperationZones() in dataFetchers and formats it into
 * a Farm/Field/Operation hierarchy by combining CLU information for a specific operationID
 * @param {Array} operationZones list of zone rows from the database AcreageReporting.Zones table
 * @param {Boolean} gartPath Denotes whether this interface instance is for creating GART files
 * @return {Array} List of combined operations in array and object form
 */
export const formatOperationZones = (operationZones, gartPath) => {
  const operations = [];

  const operationIDs = new Set();
  for (const op of operationZones) {
    const match = operations.filter((x) => x.operationID === op.operationID);
    operationIDs.add(op.operationID);

    // Group up CLUs by operationID
    // 'GART' can now have multiple entries with the same operationID, but they should not be grouped up
    if (!gartPath && match.length > 0) {
      const index = operations.map((x) => x.operationID).indexOf(match[0].operationID);
      const operation = operations[index];
      operation.acres += op.originalReportedAcreage;
      operation.CLUInfo.push(extractCLUInfo(op));
      operation.zoneIDsFromDB.push(op.zoneID);
    } else {
      const varieties = op.varietyList !== null ? op.varietyList.split(', ') : null;

      // For 'CART', we do not store operation-level data, so "operation" data will just be extracted from the first returned CLU data
      const operation = {
        // Defaults we initially get just as in combineOps
        acres: op.originalReportedAcreage,
        date: op.originalPlantedDate,
        finalPlantedDate: op.finalPlantedDate,
        fieldID: op.fieldID,
        farmID: op.farmID,
        geospatialDataSubmissionMethodCode: op.geospatialDataSubmissionMethodCode,
        irrigationPracticeCode: op.irrigationPracticeCode,
        micsCode: op.micsCode,
        name: op.cropName,
        operationID: op.operationID,
        organicPracticeTypeCode: op.organicPracticeTypeCode,
        orgID: op.organizationID,
        plantingDate: op.geospatialSourceDataCreationDate,
        precisionAgriculturalSubMeterAccuracyIndicator: op.precisionAgriculturalSubMeterAccuracyIndicator,
        varietyNames: varieties,
        year: op.cropYear,
        EndDate: op.endDate,
        StartDate: op.startDate,

        // Extra info we already have since getting it from saved data
        // crops, cropTypes, intendedUses, varietyList are added to operation in initialLoad
        countyCode: op.countyAnsiCode,
        crop: op.coreProductCode === 'None' ? 'Found' : op.coreProductCode,
        croppingPracticeCode: op.croppingPracticeCode,
        cropType: op.coreProductTypeCode === 'None' ? 'Found' : op.coreProductTypeCode,
        expanded: false,
        includeGeospatialInReport: op.includeGeospatialInReport,
        intendedUse: op.productIntendedUseCode === 'None' ? 'Found' : op.productIntendedUseCode,
        operationBoundary: op.operationBoundary,
        operation_updated_at: op.operation_updated_at,
        sharePercentage: op.producerSharePercent * 100,
        stateCode: op.stateAnsiCode,

        // New info (to show last saved date and time, etc.)
        saved_at: op.updated_at,
        savedAndLoaded: true,
      };

      // We only want to include these elements if they have values (originalBoundary should always?...)
      const conditionalElements = ['cropRowCount', 'cropRowWidth', 'geospatialShapeModifiedReasonCode', 'geospatialShapeModifiedOtherReasonText', 'micsName', 'originalBoundary', 'plantedDateModifiedReasonCode', 'plantedDateModifiedOtherReasonText', 'productPlantingCode', 'reportedAcreageModifiedReasonCode', 'reportedAcreageModifiedOtherReasonText', 'skipRowCount', 'skipRowPatternCode', 'skipRowWidth'];
      for (const element of conditionalElements) {
        if (op[element]) { operation[element] = op[element]; }
      }
      operation.skipRowConversionFactor = op.skipRowConversionFactor * 100;

      // Extra information to include if for 'CART' zoneType
      if (!gartPath) {
        operation.cluProducerReviewRequestIndicator = op.cluProducerReviewRequestIndicator;
        // Need a separate function to store the correct data for CLUInfo
        operation.CLUInfo = [extractCLUInfo(op)];
        operation.zoneIDsFromDB = [op.zoneID];
      }
      // Map the rest of the needed elements for 'GART' zoneType
      else {
        operation.created_at = op.created_at; // Only for used when saving 'GART' zoneType
        operation.finalReportedAcreage = op.finalReportedAcreage;
        operation.geospatialShapeModifiedIndicator = op.geospatialShapeModifiedIndicator;
        operation.geospatialShapeProcessDate = op.geospatialShapeProcessDate;
        operation.plantedDateModifiedIndicator = op.plantedDateModifiedIndicator;
        operation.projCode = op.acreageCalculationProjectionCode;
        operation.reportedAcreageModifiedIndicator = op.reportedAcreageModifiedIndicator;
        operation.shown = !op.isHidden;
        operation.zoneID = op.zoneID;

        // If needed, reset doNotInclude key at Operation level on loading saved data
        if (op.coreProductCode === 'None') {
          operation.doNotInclude = 'No Match Found';
        }
      }

      // NOTE: Implement later: (if !gartPath and CLUInfo.length > 1) will want to go through all CLUs to decide whether to show value at operation level or displayMarker
      //      This will make more sense for the user and also give a consistency between saving and loading

      // Store this data
      operations.push(operation);
    }
  }

  const uniqueIds = Array.from(operationIDs);
  return [operations, uniqueIds];
};
//#endregion

//#region - format data
/**
 * Converts a given boundary to WKT format
 * @param {Object|String} boundary boundary to be converted
 * @returns boundary in WKT format
 */
const convertBoundaryToWKT = (boundary) => {
  let boundaryToUse = boundary;
  if (typeof (boundary) === 'string') {
    boundaryToUse = JSON.parse(boundary);
  }
  return wkt.convert(boundaryToUse).toUpperCase();
};

/**
 * Creates an object with the right keys and values for saving CLU or Operation information to the Zones table
 * @param {Object} zone CLU or Operation to be saved
 * @param {Object} staticCluInfo Object containing more info on this particular CLU
 * @param {String} zoneType Whether on 'CART' or 'GART' path
 * @param {Number} commodityYear Planting year
 * @param {Number} reinsuranceYear Year for submission of acreage report
 * @param {Object} operationInfo Associated operation
 * @param {Boolean} gartPath Denotes whether this interface instance is for creating GART files
 * @param {any} doNotSave Whether or not to ignore this particular zone - not sure why this would be used
 * @returns {Object} Formatted CLU or operation ready for being stored in Zones table
 */
export const formatZoneForSave = (
  zone,
  staticCluInfo,
  zoneType,
  commodityYear,
  reinsuranceYear,
  operationInfo,
  gartPath = false,
  doNotSave = null,
) => {
  // Make sure to make a copy of object so original isn't altered. ONLY assignments permitted in here
  const zoneDBInfo = { ...zone };

  // --- Remove keys (that may be present) that are not needed ---
  // None of these deletes should trigger any garbage collection
  // (1) - For displaying CVT options
  // (2) - From creation of CLUSummary
  // (3) - For overlapping CLU boundaries
  // (4) - For ChangeExplanation
  delete zoneDBInfo.colorIndex; // From sorting in ReviewPage.js
  delete zoneDBInfo.crops; // (1)
  delete zoneDBInfo.cropTypes; // (1)
  delete zoneDBInfo.disableElements; // For skip row options
  delete zoneDBInfo.doNotInclude; // For handling 'No Match Found' option
  delete zoneDBInfo.farmName; // (2)
  delete zoneDBInfo.fieldName; // (2)
  delete zoneDBInfo.geospatialShapeSourceCode; // From creation of CART file
  delete zoneDBInfo.indexes; // (3)
  delete zoneDBInfo.intersectingSubfields; // (3)
  delete zoneDBInfo.intendedUses; // (1)
  delete zoneDBInfo.linkInfo; // (2)
  delete zoneDBInfo.operationInfo; // (2)
  delete zoneDBInfo.operationTitle; // (2)
  delete zoneDBInfo.orgID;
  delete zoneDBInfo.prevAreaInfo; // (4)
  delete zoneDBInfo.prevDateInfo; // (4)
  delete zoneDBInfo.saved_at; // For displaying last saved date
  delete zoneDBInfo.subfieldNumber; // (2)

  // New things to delete if this is 'GART' format - many of above deletes are needed too
  if (gartPath) {
    // (1) - From creation of operation object
    // (2) - Added for CART file
    delete zoneDBInfo.boundary; // From creation of CLUSummary
    delete zoneDBInfo.countyCode; // (2)
    delete zoneDBInfo.date; // (1)
    delete zoneDBInfo.expanded; // For expansion states
    delete zoneDBInfo.farmID; // (1)
    delete zoneDBInfo.fieldID; // (1)
    delete zoneDBInfo.name; // (1)
    delete zoneDBInfo.operationMap; // For displaying profit map
    delete zoneDBInfo.plantingDate; // (1)
    delete zoneDBInfo.stateCode; // (2)
    delete zoneDBInfo.title; // For info on review page
    delete zoneDBInfo.varietyNames; // (1)
    delete zoneDBInfo.year; // (1)
    delete zoneDBInfo.zoneIDToDelete; // From breaking up MultiPolygons
  }

  // --- Add info to CLU needed to save properly ---
  // DO NOT CHANGE THE ORDERING

  // Identification and date info
  // zoneID is already present
  zoneDBInfo.zoneType = zoneType;
  // Indicator for split MultiPolygon fields are now stored with displayNumber.
  // section will now be used for storing CLUs modified/created by nonCLUboundaries
  if (!zoneDBInfo.section) { zoneDBInfo.section = 0; }
  // hasAssociatedCLUData - not needed anymore
  // clu_identifier, clu_number, and displayNumber are already present
  if (gartPath) { // These do not exist for 'GART'
    zoneDBInfo.clu_identifier = -1;
    zoneDBInfo.clu_number = -1;
    zoneDBInfo.displayNumber = -1;
  }
  zoneDBInfo.cropYear = commodityYear;
  zoneDBInfo['Reinsurance Year'] = reinsuranceYear; // This is really not needed anymore but it's marked as not null in DB..
  zoneDBInfo.originalPlantedDate = operationInfo.date;
  if (!zoneDBInfo.finalPlantedDate) { zoneDBInfo.finalPlantedDate = operationInfo.finalPlantedDate; }
  // Skip harvestDate
  if (!zoneDBInfo.plantedDateModifiedIndicator) { zoneDBInfo.plantedDateModifiedIndicator = 'N'; }
  // plantedDateModifiedReasonCode and plantedDateModifiedOtherReasonText can just be ignored if they don't exist already

  // Operation and crop info
  // Will let [Operation Type] default to 'Seeding'
  // If 'Found', change to 'None' as 'Found' is too long for varchar(4) limit
  zoneDBInfo.coreProductCode = zoneDBInfo.crop === 'Found' ? 'None' : zoneDBInfo.crop;
  delete zoneDBInfo.crop;
  // Will ignore [Commodity Code] and [Commodity Name] for now as not used
  zoneDBInfo.CropName = operationInfo.name;
  zoneDBInfo.varietyList = operationInfo.varietyList;

  // Acreage info
  zoneDBInfo.originalReportedAcreage = zoneDBInfo.acres;
  delete zoneDBInfo.acres;
  // finalReportedAcreage is already present
  // Skip acreageHarvested
  if (!zoneDBInfo.reportedAcreageModifiedIndicator) { zoneDBInfo.reportedAcreageModifiedIndicator = 'N'; }
  // reportedAcreageModifiedReasonCode and reportedAcreageModifiedOtherReasonText can just be ignored if they don't exist already
  zoneDBInfo.acreageCalculationProjectionCode = zoneDBInfo.projCode;
  delete zoneDBInfo.projCode;
  // Will let areaUnit default to 'ac'

  // CVT info
  // If 'Found', change to 'None' as 'Found' is too long for varchar(4) limit
  zoneDBInfo.coreProductTypeCode = zoneDBInfo.cropType === 'Found' ? 'None' : zoneDBInfo.cropType;
  delete zoneDBInfo.cropType;
  zoneDBInfo.productIntendedUseCode = zoneDBInfo.intendedUse === 'Found' ? 'None' : zoneDBInfo.intendedUse;
  delete zoneDBInfo.intendedUse;
  // Will ignore [Type Code], [Type Name], [Practice Code], and [Practice Name] for now as not used

  // Planting practices, location, other, and share info
  // productPlantingCode can just be ignored if it doesn't exist already
  // irrigationPracticeCode, organicPracticeTypeCode, and croppingPracticeCode are already present
  zoneDBInfo.stateAnsiCode = operationInfo.stateCode;
  zoneDBInfo.countyAnsiCode = operationInfo.countyCode;
  // stateAnsiName and countyAnsiName are not stored or used so left empty for now
  // Skip yield, yieldUnit, ownershipType, and landlord
  zoneDBInfo.producerSharePercent = zoneDBInfo.sharePercentage / 100;
  delete zoneDBInfo.sharePercentage;

  // Geospatial info - boundaries
  // Convert all boundaries to wkt
  // zoneDBInfo.boundary will be null for 'GART' zoneType
  const boundary = convertBoundaryToWKT(zoneDBInfo.boundary || operationInfo.operationBoundary);
  let originalBoundary = boundary;
  // In case zoneDBInfo.originalBoundary does not exist for some reason..
  if (!zoneDBInfo.originalBoundary) {
    originalBoundary = convertBoundaryToWKT(zoneDBInfo.originalBoundary);
  }
  zoneDBInfo.originalBoundary = originalBoundary;

  if (!gartPath) {
    // boundary is already present but need to convert boundary to WKT so controller can convert it to geography type for insert
    zoneDBInfo.boundary = boundary;
  }

  if (operationInfo.operationBoundary) {
    const operationBoundary = convertBoundaryToWKT(operationInfo.operationBoundary);
    if (gartPath) {
      // Do not need to store boundary for 'GART' - same as operationBoundary - but it is a required field in Zones table
      zoneDBInfo.boundary = operationBoundary;
    }
    zoneDBInfo.operationBoundary = operationBoundary;
  }

  // Geospatial info - other
  // This does not exist for 'GART'
  if (!gartPath) {
    zoneDBInfo.cluProducerReviewRequestIndicator = staticCluInfo.cluProducerReviewRequestIndicator;
  } else {
    zoneDBInfo.cluProducerReviewRequestIndicator = 'Z';
  }
  // includeGeospatialInReport is already present
  if (!zoneDBInfo.geospatialShapeModifiedIndicator) { zoneDBInfo.geospatialShapeModifiedIndicator = 'N'; }
  // geospatialShapeModifiedReasonCode and geospatialShapeModifiedOtherReasonText can just be ignored if they don't exist already
  // geospatialShapeProcessDate is already present
  zoneDBInfo.geospatialDataSubmissionMethodCode = operationInfo.geospatialDataSubmissionMethodCode;
  // micsCode and micsName are already present
  // plantingDate is currently the same as EndDate.
  zoneDBInfo.geospatialSourceDataCreationDate = operationInfo.plantingDate;
  zoneDBInfo.precisionAgriculturalSubMeterAccuracyIndicator = operationInfo.precisionAgriculturalSubMeterAccuracyIndicator;

  // Skiprow, hidden, and other info
  if (zoneDBInfo.skipRowConversionFactor) { zoneDBInfo.skipRowConversionFactor /= 100; }
  // The other 5 "skip row options" can just be ignored if they don't exist already
  zoneDBInfo.isHidden = !zoneDBInfo.shown;
  delete zoneDBInfo.shown;
  // Will ignore isEarlyPlant, isReplant, isLatePlant, isPreventedPlant, BasicUnitNumber, and OptionalUnitNumber for now as not used

  if (!gartPath) {
    // CLU info from dbo.CLU
    zoneDBInfo.tract_number = staticCluInfo.tract_number;
    zoneDBInfo.farm_number = staticCluInfo.farm_number;
    // NOTE: admin_state and admin_county should always exist for real CLU data.. but there is no check when uploading CLUs..
    // Submissions with invalid CLU data will not be accepted by RMA
    //    As soon as a check is added for uploaded CLUs (and databases are checked for validity), this check below should be removed too.
    zoneDBInfo.admin_state = staticCluInfo.admin_state !== null ? staticCluInfo.admin_state : '';
    zoneDBInfo.admin_county = staticCluInfo.admin_county !== null ? staticCluInfo.admin_county : '';
  }
  // These do not exist for 'GART', but like others, they are all required for Zones table
  else {
    zoneDBInfo.tract_number = -1;
    zoneDBInfo.farm_number = -1;
    zoneDBInfo.admin_state = -1;
    zoneDBInfo.admin_county = -1;
  }

  // ID info
  zoneDBInfo.OperationID = (zoneDBInfo.operationID !== undefined && zoneDBInfo.operationID !== null) ? zoneDBInfo.operationID : null;
  delete zoneDBInfo.operationID;
  zoneDBInfo.FieldID = operationInfo.fieldID;
  zoneDBInfo.FarmID = operationInfo.farmID;
  zoneDBInfo.OrganizationID = operationInfo.orgID;
  // user_id is passed from controller

  // Date info
  // Behave differently if data was saved or not
  if (!zoneDBInfo.savedAndLoaded) {
    delete zoneDBInfo.created_at; // This line is not needed for CLU but is needed for Operation
    // At first, operation's created_at from Seedings table exists and we want to be able to use that for initial value of operation_updated_at if needed (would be needed if this is the first save from Seedings table and operation was never updated)
    // Using created_at key of data that was saved would not be valid (would not be date for operation but for zone record)
    zoneDBInfo.operation_updated_at = (operationInfo.operation_updated_at !== undefined && operationInfo.operation_updated_at !== null) ? operationInfo.operation_updated_at : operationInfo.created_at;
  } else if (!gartPath) {
    // For CLUs, created_at should already exist
    zoneDBInfo.operation_updated_at = operationInfo.operation_updated_at;
  }
  // For Operations, on subsequent saves, skip created_at and operation_updated_at as they should already exist

  zoneDBInfo.updated_at = new Date().toISOString();
  delete zoneDBInfo.savedAndLoaded;
  zoneDBInfo.StartDate = operationInfo.StartDate;
  zoneDBInfo.EndDate = operationInfo.EndDate;

  // If this clu/operation is not meant to be saved, mark it as so
  if (doNotSave !== null) {
    zoneDBInfo.doNotSave = true;
  }

  return zoneDBInfo;
};

// Creates the appropriate feature object for turf calls
export const createFeature = (inputBoundary) => {
  let boundary = inputBoundary;
  if (typeof (boundary) === 'string') {
    boundary = JSON.parse(boundary);
  }
  if (boundary.type !== 'Feature') {
    boundary = {
      type: 'Feature',
      properties: {},
      geometry: boundary,
    };
  }
  return boundary;
};
//#endregion

//#region - CLU Upload functions (mainly used in Create Planting modal?)
/** *************************************
 * * CLU FILE UPLOAD HELPER FUNCTION * *
 ***************************************/
// Most of these CLU upload functions should reuse code from AcreageReporting\Functionality\uploadClus.js
// so updates can be made everywhere at once...
const parseGeoJSON = (geojson) => {
  let reqObj = null;
  let geom = null;
  if (geojson.type === 'FeatureCollection') {
    // loop over features
    for (const feature of geojson.features) {
      // parse out properties/field names
      if (reqObj === null) {
        reqObj = feature.properties;
      } else {
        for (const key of Object.keys(feature.properties)) {
          reqObj[key] = feature.properties[key];
        }
      }
      if (feature.properties.tract_boundary) {
        geom = feature.geometry;
      }
    }
  } else {
    reqObj = geojson.properties;
    geom = geojson.geometry;
  }
  const shape = wkt.convert(geom);
  reqObj.shape = shape;

  if (reqObj.last_change_date) {
    const changeDate = new Date(reqObj.last_change_date);
    reqObj.last_change_date = changeDate;
  }
  if (reqObj.creation_date) {
    const creationDate = new Date(reqObj.creation_date);
    reqObj.creation_date = creationDate;
  }

  return [reqObj, geom];
};

export const handleCluFile = async (e) => {
  // console.log("e.target.files[0]", e.target.files)
  const fileobj = e.target.files[0];
  const cluRequest = null;
  const geojson = null;

  if (e.target.files[0].name.includes('.zip')) {
    const reader = new FileReader();
    // console.log("reader", reader, e.target.files[0])
    reader.readAsArrayBuffer(e.target.files[0]);
    reader.onload = function (e) {
      // console.log("reader", reader, e)
      // console.log("e.target.result", reader.result)
      const JSZip = require('jszip');
      const zip = new JSZip(reader.result);
      try {
        const shpString = zip.file(/.shp$/i)[0].name;
        const dbfString = zip.file(/.dbf$/i)[0].name;

        const shp = zip.file(shpString).asArrayBuffer();
        const dbf = zip.file(dbfString).asArrayBuffer();

        return loadShpZip(fileobj, shp, dbf);
        // handleClick(fileobj, zip.file(shpString).asArrayBuffer(), zip.file(dbfString).asArrayBuffer())
      } catch (err) {
        return "Couldn't find .shp or .dbf file!";
      }
    };
  } else if (fileobj.name.includes('.json')) {
    console.log('json uploaded', fileobj);
    const jsonString = await fileobj.text();
    console.log(jsonString);
    const jsonObj = JSON.parse(jsonString);
    console.log(jsonObj);
    // send geojson to be parsed into obj to send to backend
    return parseGeoJSON(jsonObj);
  } else {

  }
};

const loadShpZip = async (file1, shpFile1, dbfFile1) => {
  const multiGeojsons = [];
  const shapefile = require('shapefile');
  if (file1 !== null) {
    // console.log("file.stream()", file1.stream())
    shapefile.open(shpFile1, dbfFile1, null)
      .then((source) => source.read()
        .then(function log(result) {
          if (result.done) return;
          console.log(result.value);

          if (result.value.geometry.type !== 'Point' && multiGeojsons.length <= 20) {
            multiGeojsons.push(result.value);
            // console.log("multiGeojsons", multiGeojsons)
            return source.read().then(log);
          }
          if (result.value.geometry.type === 'Point') {

          }
        })).then(async (data) => {
        let newBoundary = null;
        if (multiGeojsons.length > 1) {
          const coordinates = [];
          for (const polygon of multiGeojsons) {
            coordinates.push(polygon.geometry.coordinates);
          }
          const geom = {
            type: 'MultiPolygon',
            coordinates,
          };
          newBoundary = {
            type: 'Feature',
            geometry: geom,
            properties: multiGeojsons[0].properties,
          };
        } else if (multiGeojsons.length === 1) {
          newBoundary = {
            type: 'Feature',
            geometry: {
              type: 'Polygon',
              coordinates: multiGeojsons[0].geometry.coordinates,
            },
            properties: { ...multiGeojsons[0].properties },
          };
          // newBoundary = multiGeojsons[0]
        }

        newBoundary.properties.CALCACRES = turf.convertArea(turf.area(newBoundary), 'meters', 'acres');

        console.log('newBoundary', newBoundary);

        const newField = {
          shape: wkt.convert(newBoundary.geometry),
          clu_identifier: getPropertyValue(multiGeojsons[0].properties, 'clu_identifier'),
          clu_number: getPropertyValue(multiGeojsons[0].properties, 'clu_number'),
          tract_number: getPropertyValue(multiGeojsons[0].properties, 'tract_number'),
          farm_number: getPropertyValue(multiGeojsons[0].properties, 'farm_number'),
          clu_classification_code: getPropertyValue(multiGeojsons[0].properties, 'clu_classification'),
          clu_caculated_acreage: getPropertyValue(multiGeojsons[0].properties, 'clu_caculated_acreage'),
          highly_erodible_land_type_code: getPropertyValue(multiGeojsons[0].properties, 'highly_erodible_land_type_code'),
          comments: getPropertyValue(multiGeojsons[0].properties, 'comments'),
          state_code: getPropertyValue(multiGeojsons[0].properties, 'state_code'),
          county_code: getPropertyValue(multiGeojsons[0].properties, 'county_code'),
          data_source_site_identifier: getPropertyValue(multiGeojsons[0].properties, 'data_source_site_identifier', 'number'),
          data_source: getPropertyValue(multiGeojsons[0].properties, 'data_source'),
          admin_state: getPropertyValue(multiGeojsons[0].properties, 'admin_state'),
          admin_county: getPropertyValue(multiGeojsons[0].properties, 'admin_county'),
          cropland_indicator_3CM: getPropertyValue(multiGeojsons[0].properties, 'cropland_indicator_3CM'),
          sap_crp: getPropertyValue(multiGeojsons[0].properties, 'sap_crp'),
          clu_status: getPropertyValue(multiGeojsons[0].properties, 'clu_status'),
          cdist_fips: getPropertyValue(multiGeojsons[0].properties, 'cdist_fips'),
          edit_reason: getPropertyValue(multiGeojsons[0].properties, 'edit_reason'),
          clu_alt_id: getPropertyValue(multiGeojsons[0].properties, 'clu_alt_id'),
          last_chg_user_nm: getPropertyValue(multiGeojsons[0].properties, 'last_chg_user_nm'),
          CIMSFILE: getPropertyValue(multiGeojsons[0].properties, 'CIMSFILE'),
          CIMS_LOC_STATE: getPropertyValue(multiGeojsons[0].properties, 'CIMS_LOC_STATE'),
          CIMS_LOC_COUNTY: getPropertyValue(multiGeojsons[0].properties, 'CIMS_LOC_COUNTY'),
          Source: 'USERENTERED',
          OrganizationID: null,
          FieldID: null,
          last_change_date: getPropertyValue(multiGeojsons[0].properties, 'last_change_date'),
        };
        console.log('shape', newField);
        // console.log("newBoundary", newBoundary)
        try {
          return [newField, newBoundary];
        } catch (err) {
          console.log('error inserting field boundary', err);
        }
      })
      .catch((error) => console.error(error.stack));
  }
};

const getPropertyValue = (propertiesObj, key, type = null) => {
  if (propertiesObj[key]) {
    return propertiesObj[key];
  }
  const truncated = key.substring(0, 10);
  if (propertiesObj[truncated]) {
    if (type !== null) {
      if (typeof (propertiesObj[truncated]) === type) {
        return propertiesObj[truncated];
      }

      return null;
    }

    return propertiesObj[truncated];
  }
  return null;
};
//#endregion
import React from 'react';
import YouTube from 'react-youtube';

export class PromoVideo extends React.Component {
  render() {
    const opts = {
      height: 320,
      width: window.innerWidth > 580 ? '560' : (window.innerWidth - 20),
    };

    return <YouTube videoId="wco8S4KoeBY" opts={opts} onReady={this._onReady} />;
  }

  _onReady(event) {
    // access to player in all event handlers via event.target
    event.target.pauseVideo();
  }
}

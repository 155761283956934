/* eslint-disable prefer-destructuring */
/* eslint-disable radix */
/* eslint-disable require-jsdoc */
/* eslint-disable no-restricted-syntax */
/* eslint-disable camelcase */
// React and mui
import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';

// Styling and helpful packages
import { zoneColors } from '../Styles/layerStyles';
import { opacityIcon } from '../Styles/icons';
import { useSnackbar } from 'notistack';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import * as _ from 'underscore';

// Custom Components and Functionality
import {
  createCollection,
} from '../MapFunctions/fieldInteractions';
import { getFeature } from '../MapFunctions/helpers';
import { EditBoundary } from '../Toolbars/EditBoundary';
import { reprojectCalcArea } from '../../../utils/reprojectCalcArea';
import { Tiles } from '../../../constants/Tiles';

/**
 * Map for handling interactions relating to modifying CLU boundaries
 * Used in AcreageReporting\Modals\EditMap.js
 * 
 * @param {Function} boundaryHasBeenModified User has started modifying boundary
 * @param {Object} clu CLU information
 * @param {String} cluFullBoundary CLU complete boundary
 * @param {Function} getIntersections Checks whether there are any intersections between passed boundaries
 * @param {Function} handleBoundaryUpdate Updates acres, boundary indicator, and actual boundary
 * @param {Function} setAcres Updates total CLU subfields' acreage to show to user
 * @param {Function} setIntersectionZones Stores created zones from splitting of CLU intersection for parent component
 * @param {Object} operation Operation data
 * @return {JSX} Map display
 */
export function UpdateIntersectionMap({
  boundaryHasBeenModified,
  clu,
  cluFullBoundary,
  getIntersections,
  handleBoundaryUpdate,
  setAcres,
  setIntersectionZones,
  operation,
}) {
  //#region - variables
  const { enqueueSnackbar } = useSnackbar();

  let mapId = Math.floor(Math.random() * Math.floor(10000)).toString();
  const [map, setMap] = useState(null);
  const [activeTool, setActiveTool] = useState('');
  const [editedBoundary, setEditedBoundary] = useState(null);
  const [updatedBoundary, setUpdatedBoundary] = useState(null); // should always be an object
  // This is useful to ensure useEffect runs in case the updated boundary is the same as the previous one.
  // Important when user resets, then slices, then tries to reset again.
  // Does not seem to be an issue for fill though... Probably because originalBoundary is the exact same object used when resetting but when filling, a new object is created each time  
  const [mustUpdateBoundary, setMustUpdateBoundary] = useState(0);
  const [originalBoundary, setOriginalBoundary] = useState(null);

  // To avoid doing this many times unnecessarily
  const parsedFullBounds = JSON.parse(cluFullBoundary);
  const fullBoundsTurf = turf.polygon(parsedFullBounds.coordinates);

  // Needed to prevent editedboundary from being used on cancel
  const editedCanceled = useRef(false);

  // Determine which function to call on draw complete
  const polygonFormat = useRef('draw');
  // Determine if user has already been notified of purple boundary
  const hasntNotified = useRef(true);

  // Layers
  const cluLayer = useRef(L.featureGroup(null)); // for full CLU boundary
  const operationLayer = useRef(L.featureGroup(null)); // for clu boundaries being editted
  const operationMapLayer = useRef(L.imageOverlay(null)); // for operation map
  const opacitySlider = useRef(null);

  // Zone creation
  const slicingMode = useRef(false);
  const currentSlice = useRef(null);
  const slicedZones = useRef({type: 'Feature', geometry: {type: 'MultiPolygon', coordinates: []}});
  const [zones, setZones] = useState([]);
  // This limits tools available after a slice/draw as their interactions have not been implemented
  const [zonesSliced, setZonesSliced] = useState(false);
  const [redrawZones, setRedrawZones] = useState(0);

  // Update states so actual functions can be called in useEffect
  const [newZone, setNewZone] = useState(null);
  const [newSlice, setNewSlice] = useState(null);
  //#endregion

  //#region - useEffects
  useEffect(() => {
    if (map === null) {
      try {
        drawMap(mapId);
      } catch (error) {
        // Incase generate duplicate ID
        mapId = Math.floor(Math.random() * Math.floor(10000)).toString();
      }
    }
  }, [mapId, map]);

  useEffect(() => {
    if (clu && cluFullBoundary && map) {
      drawCluBoundary();
      drawIntersectionBoundary(clu);
      if (clu?.originalBoundary) {
        setOriginalBoundary(JSON.parse(clu.originalBoundary));
      } else {
        // If originalBoundary is missing for any reason, we can just use normal boundary
        setOriginalBoundary(JSON.parse(clu.boundary));
      }
    }
  }, [clu, cluFullBoundary, map]);

  // Update boundary after edits
  useEffect(() => {
    if (updatedBoundary && map) {
      try {
        clearOperations();
        drawBoundary(
          updatedBoundary,
          '#1e88e5',
          5,
          operationLayer.current,
          null,
          setEditedBoundary,
          null,
          false,
          0.3,
        );
      } catch (err) {
        console.error(err);
      }
    }
  }, [updatedBoundary, map, mustUpdateBoundary]);

  // Called after an edit was made to the displayed boundary.
  useEffect(() => {
    if (editedBoundary) {
      if (!editedCanceled.current) {
        // Handle the updated boundary
        try {
          // Clear boundary from map
          map.removeLayer(editedBoundary);
          updateBoundary(editedBoundary.toGeoJSON());
        } catch (err) {
          enqueueSnackbar('Your new boundary is invalid. This is likely caused by overlapping boundary points.');
          console.error(err);
        }
      } else {
        // There is no built in ability to cancel an edit with geoMan/Leaflet tools,
        // so this is how we will handle a cancel interaction
        editedCanceled.current = false;
        setEditedBoundary(null);
        resetToLastState();
      }
    }
  }, [editedBoundary]);

  // Called when user finishes drawing (draw, connect)
  useEffect(() => {
    if (newZone) {
      if (polygonFormat.current === 'draw') {
        const success = handleDraw(newZone);
        if (success) setZonesSliced(true);
      } else if (polygonFormat.current === 'connect') {
        handleConnect(newZone);
      }
      setNewZone(null);
      setActiveTool('');
    }
  }, [newZone]);

  // When user has drawn a point/line, take the appropriate actions for slice function
  useEffect(() => {
    if (newSlice) {
      handleSlice(newSlice);
      setNewSlice(null);
    }
  }, [newSlice]);

  // Handles drawing zones and other necessary calls when user finishes a draw/slice 
  // and zones are ready to be drawn (have been processed for intersections, etc.)
  useEffect(() => {
    if (zones?.length) {
      clearOperations();
      zones.forEach((zone) => {
        drawZone(zone);
      });
      setIntersectionZones(zones);
      setZonesSliced(true);
    }
  }, [zones, redrawZones]);

  useEffect(() => {
    if (operation.operationMap !== undefined && map !== undefined && map !== null) {
      if (operation.operationMap !== undefined && operation.operationMap !== 'Error' && operation.operationMap !== 'NA') {
        drawOperationMap(operation.operationMap);
      }
    }
  }, [operation, map]);
  //#endregion

  //#region - draw maps
  const drawMap = (id) => {
    try {
      const mapboxTiles = L.tileLayer(
        Tiles.ESRIBASEMAP,
      );

      // Add map to div by Id, set any parameters and initial view, add mapbox layer
      const intersectionMap = L.map(id, {
        dragging: !L.Browser.mobile,
        editable: true,
        editOptions: {
          lineGuideOptions: {
            opacity: 0,
          },
        },
      })
        .setView([41.016, -92.4083], 5)
        .addLayer(mapboxTiles);

      // draw controls
      intersectionMap.pm.addControls({
        drawMarker: false,
        drawCircle: false,
        drawCircleMarker: false,
        drawRectangle: true,
        dragMode: true,
        drawPolygon: true,
        cutPolygon: true,
        editPolygon: true,
        drawPolyline: true,
        deleteLayer: true,
      });

      // Handle the addition of a vertex while slicing
      intersectionMap.on('pm:drawstart', ({ workingLayer }) => {
        workingLayer.on('pm:vertexadded', (e) => {
          if (slicingMode.current) {
            setNewSlice(e);
          }
        });
      });

      // Handle the end of a draw, slice, or connect
      intersectionMap.on('pm:create', ({shape, layer}) => {
        try {
          intersectionMap.removeLayer(layer);

          // This condition only happens when closing a Polygon (so, not for slice)
          if (shape === 'Polygon') {
            const feature = getFeature(layer);
            // console.log('feature :>> ', feature);

            if (feature) {
              setNewZone(feature);
            }
          }

          // Background: Currently, creating a shape within another with slice tool is invalid because of the use of a PolyLine for slicing and not a Polygon so there is no way to know if user clicked another vertex to finish or to close a polygon
          // Implementation Reason: Slicing should be much simpler to implement with pm.enableGlobalSplitMode function but maybe it was not used for a reason (probably because we would have to pay for that...).
          // Issue: The way the slice tool is implemented (automatically slice if user has created a valid split) makes it so that we cannot detect if the slice has failed (because failure and success are not determined by pm:create but by lineIntersect in handleSlice but handleSlice does not know whether user is done, pm:create does and these two do not currently communicate).
          // ***** So we cannot tell the user they did something wrong. ***** 
          // OK Solution (current): 
          //    - User can just use the draw tool to accomplish shape within another shape functionality
          //    - Tooltip on 'Slice' tool explains possible issue
          // Different? Solution: 
          //    - This could be allowed by only making the split when the user clicks on a vertex (pm:create calls handleSlice instead of pm:vertexadded), this removes the automatic slicing feature but allows us to notify them of the reason slice failed if it does

          // For slice tool, make sure to clear currentSlice in case user did not create a valid line
          if (slicingMode.current) {
            currentSlice.current = null;
            setActiveTool('');
          }
          // Turn off slicing mode
          slicingMode.current = false;
        } catch (err) {
          console.error(err);
        }
      });

      // Handle resetting toolbar if cut is invalid
      intersectionMap.on('pm:globalcutmodetoggled', (e) => {
        if (!e.enabled) setActiveTool(''); 
      })

      intersectionMap.addLayer(cluLayer.current);
      setMap(intersectionMap);
    } catch (err) {
      console.error(err);
    }
  };

  const updateOperationOpacity = (value) => {
    operationMapLayer.current.setOpacity(value / 100);
  };

  const drawOperationMap = (operationMapObj) => {
    const extentString = operationMapObj.extent;
    const image = `data:image/png;base64,${operationMapObj.pngb64}`;

    const brkn = extentString.split(', ').map((x) => parseFloat(x));
    const corner1 = L.latLng(brkn[1], brkn[0]);
    const corner2 = L.latLng(brkn[3], brkn[2]);
    const bounds = L.latLngBounds(corner1, corner2);

    operationMapLayer.current.setUrl(image);
    operationMapLayer.current.setBounds(bounds);

    if (!map.hasLayer(operationMapLayer.current)) {
      operationMapLayer.current.addTo(map);
      // add opacity control
      opacitySlider.current = L.control.slider(
        (value) => { updateOperationOpacity(parseInt(value)); },
        {
          id: opacitySlider.current,
          orientation: 'horizontal',
          position: 'topleft',
          title: 'Opacity',
          min: 0,
          max: 100,
          step: 1,
          value: 100,
          collapsed: true,
          increment: true,
          size: '200px',
          logo: opacityIcon,
        },
      );
      opacitySlider.current.addTo(map);
    }
  };
  //#endregion

  //#region - draw/clear boundaries
  const clearOperations = () => {
    const layer = operationLayer.current;
    layer.eachLayer((layer) => {
      map.removeLayer(layer);
    });
    layer.clearLayers();
  };

  const clearCluLayer = () => {
    cluLayer.current.eachLayer((layer) => {
      map.removeLayer(layer);
    });
  };

  const drawBoundary = (
    boundary,
    color,
    weight,
    layer,
    handleClick,
    boundaryEdit,
    toolTip,
    fitBounds,
    fillOpacity,
  ) => {
    // handleClick in this case is an object containing the function and value
    // to pass. Implemented this way to avoid passing layer up and for future
    // flexibility.

    const collection = createCollection(
      boundary,
      color,
      weight,
      layer,
      false,
      handleClick,
      boundaryEdit,
      finishCut,
      toolTip,
      fillOpacity,
    );
    if (collection) {
      collection.addTo(map);

      if (fitBounds) {
        map.fitBounds(collection.getBounds(), {
          padding: [30, 30],
        });
      }
    }
  };

  const drawCluBoundary = () => {
    drawBoundary(
      cluFullBoundary,
      '#8e24aa',
      2,
      cluLayer.current,
      null,
      null,
      null,
      true,
    );
  };

  const drawIntersectionBoundary = async (selectedClu) => {
    try {
      clearOperations();
      drawBoundary(
        selectedClu.boundary,
        '#1e88e5',
        5,
        operationLayer.current,
        null,
        setEditedBoundary,
        null,
        false,
        0.3,
      );
    } catch (err) {
      console.error(err);
    }
  };

  // Draw zones - zones var is populated after a slice/draw action occurs
  const drawZone = (zone) => {
    setActiveTool('');
    try {
      const collection = createCollection(
        zone.geometry,
        zone.properties.color,
        2,
        operationLayer.current,
        undefined,
        undefined,
        setEditedBoundary, // setEditedBoundary // was undefined
        undefined,
        undefined,
        0.35,
      );
      collection.addTo(map);
    } catch (err) {
      console.error(err);
    }
  };
  //#endregion

  //#region - handle boundary updates
  // Try to see if any of the edited boundary lies outside of the full clu boundary
  // And notify the user if it makes sense to
  // Called by edit, connect, and draw actions; those are the only ones where user can add something outside of fullCLUBoundary
  const notifyUserAboutFullBoundary = (newZones) => {
    // Only notify the user the first time this happens and only look for it if it hasn't happened yet
    if (hasntNotified.current) {
      for (const boundary of newZones) {
        try {
          const outside = turf.difference(boundary, fullBoundsTurf);

          // Only notify user if this is of a significant size
          if (outside) {
            const area = turf.convertArea(turf.area(outside), 'meters', 'acres');
            if (area >= 0.01) {
              enqueueSnackbar('Therefore, any part of your modified boundary falling outside of the purple boundary will be ignored.', {persist: true});
              enqueueSnackbar('The outer purple boundary represents the maximum size this CLU\'s boundary can be.', {persist: true});
              hasntNotified.current = false;
            }
          }
        } catch (err) {
          console.log('Attempting to find area outside of CLU failed: ', err);
        }
      }
    }
  }

  // Reset tool to last valid boundary state - either zones or boundary
  const resetToLastState = () => {
    // Reset toolbar state
    cancel();

    // Follow appropriate reset steps
    if (zones.length) {
      setRedrawZones(redrawZones+1);
    } else {
      // Redraw operation in case of bad cut or edit - use updatedBoundary if it exists
      setUpdatedBoundary(updatedBoundary || clu.boundary);
      setMustUpdateBoundary(mustUpdateBoundary+1); // in case updatedBoundary was used
    }
  }

  // Called after edit, cut, or connect actions on drawn boundary(ies)
  const updateBoundary = async (boundary) => {
    try {
      // Check boundary is valid - user might have deleted all points in boundary
      if (!boundary.geometry || !boundary.geometry.coordinates[0][0]) {
        enqueueSnackbar('Please input a valid boundary.');
        console.log('boundary :>> ', boundary);
        resetToLastState();
      } else {
        // Try to see if any of the edited boundary lies outside of the full clu boundary (matters for edit and connect)
        notifyUserAboutFullBoundary([boundary], fullBoundsTurf);

        // console.log('boundary :>> ', boundary);
        // Remove edited boundary that falls outside of CLU boundary
        const intersections = getIntersections(boundary.geometry, parsedFullBounds);
        // console.log('intersections :>> ', intersections);
        if (intersections.length) {
          // Convert to the appropriate geometry depending on number of Polygons
          if (intersections.length > 1) {
            // Convert all these to individual Polygons to work with the zones flow
            // NOTE: Cut tool creating more than 2 Polygons does not work. Seems to be issue with leaflet
            const newPolys = intersections.map((intersection, index) => {
              const newIntersection = turf.polygon(intersection);
              const reprojection = reprojectCalcArea(JSON.stringify(newIntersection), 'UTM', 'GeoJSON', 'WGS84', 'Acres');

              newIntersection.properties = {};
              newIntersection.properties.color = zoneColors[index];
              newIntersection.properties.CALCACRES = reprojection.area;
              return newIntersection;
            });
            updateZonesVariables(newPolys);
          } else {
            // If only one Polygon, then flow is different
            const intersection = turf.polygon(intersections[0]);

            // Get reprojected acres
            const projection = reprojectCalcArea(JSON.stringify(intersection.geometry), 'UTM', 'GeoJSON', 'WGS84', 'Acres');
            setUpdatedBoundary(intersection);
            handleBoundaryUpdate(projection.area, intersection.geometry, true);
          }
        } else {
          enqueueSnackbar('Your new boundary is invalid. This is likely caused by overlapping boundary points.');
          resetToLastState();
        }
      }
    } catch (err) {
      enqueueSnackbar('Your new boundary is invalid. This is likely caused by overlapping boundary points.');
      console.log('Error updating boundary: ', err);
      resetToLastState();
    }
  };

  /**
   * Takes a new polygon and combined with existing boundary.
   * If boundary has been updated, union new polygon with that,
   * otherwise union with original boundary.
   * Update boundary will handle acres reprojection.
   * @param {Object} polygon Drawn polygon from map
   * @returns {void}
   */
  const handleConnect = (polygon) => {
    try {
      let boundaryToConnect;
      if (updatedBoundary) {
        boundaryToConnect = updatedBoundary;
      } else {
        boundaryToConnect = originalBoundary;
      }

      let feature = boundaryToConnect;
      if (boundaryToConnect.type !== 'Feature') {
        feature = {
          type: 'Feature',
          propeties: {},
          geometry: boundaryToConnect,
        };
      }
      const union = turf.union(polygon, feature);
      updateBoundary(union);
    } catch (err) {
      console.error(err);
    }
  };
  //#endregion

  //#region - slice and draw tools helpers
  // Always update both of these variables at the same time
  const updateZonesVariables = (newZones) => {
    setZones(newZones);

    // We do not want to call handleBoundaryUpdate here instead of boundaryHasBeenModified.
    // That is mainly because we want to store new boundary data in intersectionZones var instead of updatedBoundary
    if (newZones.length) {
      const totalAcres = newZones.reduce((total, current) => total + current.properties.CALCACRES, 0);
      setAcres(totalAcres);
      boundaryHasBeenModified(true);
    }
  }

  // Function for drawing zones after a draw tool use
  const handleDraw = (newPoly) => {
    try {
      // lower opacity so can see zones
      operationMapLayer.current.setOpacity(0);
      // Try to see if any of the edited boundary lies outside of the full clu boundary
      notifyUserAboutFullBoundary([newPoly], fullBoundsTurf);

      // Get intersection of new zone with complete CLU boundary
      // This newPoly cannot be a MultiPolygon, so not using getIntersections here
      const intersection = turf.intersect(newPoly.geometry, parsedFullBounds);
      if (!intersection) {
        enqueueSnackbar('Your new boundary is invalid. Please draw a shape that intersects with full (purple) CLU boundary.');
        enablePolygonDrawMode();
        return false;
      }

      const polyReprojection = reprojectCalcArea(JSON.stringify(intersection.geometry), 'UTM', 'GeoJSON', 'WGS84', 'Acres');
      intersection.properties.CALCACRES = polyReprojection.area;
      if (zones.length) {
        // Zones have already been added, so we'll need to check differences for all
        const updatedZones = [];

        zones.forEach((zone) => {
          const difference = turf.difference(zone, intersection);
          if (difference) {
            const diffReprojection = reprojectCalcArea(JSON.stringify(difference.geometry), 'UTM', 'GeoJSON', 'WGS84', 'Acres');
            difference.properties.CALCACRES = diffReprojection.area;
            difference.properties.color = zone.properties.color;
            updatedZones.push(difference);
          } else {
            updatedZones.push(zone);
          }
        });

        intersection.properties.color = zoneColors[updatedZones.length];
        updateZonesVariables([...updatedZones, intersection]);
      } else {
        // We're just dealing with adding one new zone to current clu boundary
        // Get difference between boundary and intersection so no overlap
        const boundaryToUse = updatedBoundary || JSON.parse(clu.boundary);
        const cluDifference = turf.difference(boundaryToUse, intersection);
        const diffReprojection = reprojectCalcArea(JSON.stringify(cluDifference.geometry), 'UTM', 'GeoJSON', 'WGS84', 'Acres');
        cluDifference.properties.CALCACRES = diffReprojection.area;

        // Add zone colors
        cluDifference.properties.color = zoneColors[zones.length];
        intersection.properties.color = zoneColors[zones.length + 1];
        updateZonesVariables([cluDifference, intersection]);
      }
      return true;
    } catch (err) {
      console.error(err);
      return false;
    }
  };
  
  function polygonCut(polygon, line, idPrefix) {
    // this is a function to cut a pulygon into seperate polygons
    // based on a line.
    // taken from - https://gis.stackexchange.com/questions/344068/splitting-a-polygon-by-multiple-linestrings-leaflet-and-turf-js
    const THICK_LINE_UNITS = 'kilometers';
    const THICK_LINE_WIDTH = 0.001;
    let i; let j; let id; let intersectPoints; var lineCoords; let forCut; let
      forSelect;
    let thickLineString; let thickLinePolygon; let clipped; let polyg; let
      intersect;
    let polyCoords = [];
    let cutPolyGeoms = [];
    const cutFeatures = [];
    const offsetLine = [];
    let retVal = null;

    if (
      (polygon.type != 'Polygon' && polygon.type != 'MultiPolygon')
      || line.type != 'LineString'
    ) {
      return retVal;
    }

    if (typeof idPrefix === 'undefined') {
      idPrefix = '';
    }

    intersectPoints = turf.lineIntersect(polygon, line);
    if (intersectPoints.features.length == 0) {
      return retVal;
    }

    var lineCoords = turf.getCoords(line);
    if (
      turf.booleanWithin(turf.point(lineCoords[0]), polygon)
      || turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)
    ) {
      return retVal;
    }

    offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS,
    });
    offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, {
      units: THICK_LINE_UNITS,
    });

    for (i = 0; i <= 1; i++) {
      forCut = i;
      forSelect = (i + 1) % 2;
      polyCoords = [];
      for (j = 0; j < line.coordinates.length; j++) {
        polyCoords.push(line.coordinates[j]);
      }
      for (
        j = offsetLine[forCut].geometry.coordinates.length - 1;
        j >= 0;
        j--
      ) {
        polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
      }
      polyCoords.push(line.coordinates[0]);

      thickLineString = turf.lineString(polyCoords);
      thickLinePolygon = turf.lineToPolygon(thickLineString);
      clipped = turf.difference(polygon, thickLinePolygon);

      cutPolyGeoms = [];
      for (j = 0; j < clipped.geometry.coordinates.length; j++) {
        polyg = turf.polygon(clipped.geometry.coordinates[j]);
        intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
        if (intersect.features.length > 0) {
          cutPolyGeoms.push(polyg.geometry.coordinates);
        }
      }

      cutPolyGeoms.forEach((geometry, index) => {
        id = `${idPrefix + (i + 1)}.${index + 1}`;
        cutFeatures.push(turf.polygon(geometry, { id }));
      });
    }

    if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);

    return retVal;
  }
  
  const handleSlice = (newSlice) => {
    try {
      // currentSlice.current is used as a way to track the points that were added by the user 
      // so, when done with handleSlice, this should be reset to null
      // handleSlice terminates on: slice cancelled/error, completed slice, or in case of an invalid slice line (caught by pm:create event)
      if (currentSlice.current === null) {
        currentSlice.current = L.polyline([newSlice.latlng]);
      } else {
        currentSlice.current.addLatLng(newSlice.latlng);
        const sliceGeo = currentSlice.current.toGeoJSON();

        // For determining intersection, use field boundary if zones don't exist, otherwise use zones
        let intersection = {
          features: [],
        };
        let features = [];
        const existing = [];

        // console.log('zones :>> ', zones);
        if (zones.length === 0) {
          const geom = updatedBoundary || JSON.parse(clu.boundary);
          // If no slices exist, push the clu intersection geojson into the features list
          // Use clu.boundary the first time as updatedBoundary does not exist yet
          const feature = geom.type !== 'Feature' ?
            {
              type: 'feature',
              propeties: {},
              geometry: geom,
            }
          : geom;

          features.push(feature);
          // intersect the slice with the feature
          intersection = turf.lineIntersect(feature.geometry, sliceGeo);
        } else {
          // need to do a intersect check on each existing zone
          for (const sz of zones) {
            const zoneIntersection = turf.lineIntersect(sz, sliceGeo);
            // if intersects, add sz to features for cut
            if (zoneIntersection.features.length > 1) {
              intersection = zoneIntersection;
              features.push(sz);
            } else {
              // need to make sure that existing zones not in a slice are saved
              existing.push(sz);
            }
          }
          // console.log('existing :>> ', existing);
        }

        // This checks whether the line the user has drawn has created more than 1 shape
        // If it has, then we proceed with the split. If not, then we wait for more vertices
        if (intersection.features.length > 1) {
          // line has connected to other edge
          // split polygon based on line
          // loop over features, checking for a multipoly.
          // if multi, split into polys and remake list
          const multiPolyNdxs = new Set();
          const newPolys = [];

          features.forEach((feat) => {
            if (feat.geometry.type === 'MultiPolygon') {
              // loop over coordinates and create new Polygon feature
              // eslint-disable-next-line no-restricted-syntax
              for (const coordinates of feat.geometry.coordinates) {
                const newP = {
                  geometry: {
                    coordinates: [],
                    type: 'Polygon',
                  },
                  properties: {},
                  type: 'Feature',
                };

                if (coordinates.length > 1) {
                  newP.geometry.coordinates.push(coordinates);
                } else {
                  newP.geometry.coordinates = coordinates;
                }
                newPolys.push(newP);
                multiPolyNdxs.add(features.indexOf(feat));
              }
            }
          });

          // Remove multi polygons from list and add in new created polygons
          const multiNdxArr = Array.from(multiPolyNdxs);
          multiNdxArr.map((x) => features.splice(x, 1));
          features = [...features, ...newPolys];
          // console.log({features, newPolys});

          // Remove hole polygons was done before as above forEach would split up the holes as well for some reason.
          // There is no need to do this however...
          // Old comment: This might be the place to take the difference of overlapping polygons?
          // features = removeHolePolygons(features);

          // To keep track of new zones
          const newZones = [];

          // Split each zone that the drawn line splits in two
          // Because of newPolys, all the original shapes which had all passed the lineIntersect test might not be the only ones fpart of features so we need to handle that
          for (const toSplit of features) {
            let split;

            try {
              // This attemps to split the relevant polygons (toSplit) using the drawn line (sliceGeo)
              split = polygonCut(toSplit.geometry, sliceGeo.geometry);
            } catch (err) {
              // On error, notify user and reset slice tool
              console.error('Whoops: ', err);
              enqueueSnackbar('An error occured. This is likely because the shape you are trying to create would be too small.');
              cancel();
              return;
            }

            if (split !== null) {
              const splitFeats = split.features;
              splitFeats.forEach((x, i) => {
                const feature = { ...x };
                // Get reprojected acres
                const reprojection = reprojectCalcArea(JSON.stringify(feature.geometry), 'UTM', 'GeoJSON', 'WGS84', 'Acres');
                feature.properties.CALCACRES = reprojection.area;
                newZones.push(feature);
              });
            } 
            // This else is only possible because of "newPolys" merged into features
            else {
              const featurePoly = turf.polygon(toSplit.geometry.coordinates);
              // const area = turf.area(featurePoly) / 4046.86;
              const reprojection = reprojectCalcArea(JSON.stringify(toSplit.geometry), 'UTM', 'GeoJSON', 'WGS84', 'Acres');
              featurePoly.properties.CALCACRES = reprojection.area;
              newZones.push(featurePoly);
            }
          }

          // Merge newly cut zones
          const tempZones = [...newZones, ...existing];
          const finalZones = [];
          tempZones.forEach((zone, i) => {
            const updatedZone = { ...zone };
            updatedZone.properties.color = zoneColors[i];
            finalZones.push(updatedZone);
          });

          // Clear currentSlice
          currentSlice.current = null;

          // Update the appropriate variables
          // This call will overwrite zones (which will overwrite setIntersectionZones)
          updateZonesVariables(finalZones);

          // Finish drawing line to restart
          const lineControl = document.getElementsByClassName(
            'control-icon leaflet-pm-icon-polyline',
          )[0];
          const control = lineControl.parentNode;
          const lineEditControls = control.nextSibling;
          let finishLine = null;
          for (let i = 0; i < lineEditControls.childNodes.length; i++) {
            if (
              lineEditControls.childNodes[i].className
            === 'leaflet-pm-action  action-finish'
            ) {
              finishLine = lineEditControls.childNodes[i];
              break;
            }
          }
          finishLine.click();
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  // Remove remnants from previous slices - called ONLY when reseting/filling boundary
  const clearZones = () => {
    updateZonesVariables([]);
    setIntersectionZones([]);
    setZonesSliced(false);
  }
  //#endregion

  //#region - draw/edit tools buttons
  const edit = () => {
    try {
      boundaryHasBeenModified(true);
      setActiveTool('edit');

      // If there are zones, group them all up so they can all be modified at once
      if (zones.length) {
        // const boundary = turf.union.apply(this, zones);
        const boundary = turf.multiPolygon(zones.map((x) => x.geometry.coordinates));
        drawIntersectionBoundary({boundary});
      }

      operationLayer.current.bringToFront();
      operationLayer.current.pm.enable();
    } catch (err) {
      console.error(err);
    }
  };

  const finishEdit = () => {
    try {
      setActiveTool('');
      operationLayer.current.pm.disable();
    } catch (err) {
      console.error(err);
    }
  };

  // Update intersecting boundary to be complete clu
  const fillBoundary = () => {
    try {
      // Get reprojected acres
      const projection = reprojectCalcArea(cluFullBoundary, 'UTM', 'GeoJSON', 'WGS84', 'Acres');

      // Clear sliced zone items
      clearZones();
      setUpdatedBoundary(parsedFullBounds);
      handleBoundaryUpdate(projection.area, parsedFullBounds, true);
    } catch (e) {
      console.error('unable to update', e);
      enqueueSnackbar('Unable to update boundary');
    }
  };

  const resetBoundary = () => {
    try {
      // Clear sliced zone items
      clearZones();
      setUpdatedBoundary(originalBoundary);
      setMustUpdateBoundary(mustUpdateBoundary+1);
      handleBoundaryUpdate(null, null, false);
    } catch (err) {
      console.error(err);
    }
  };

  const cut = () => {
    setActiveTool('cut');
    operationLayer.current.bringToFront();
    // enable cutting mode
    map.pm.enableGlobalCutMode({
      allowSelfIntersection: false,
    });
  };
  
  const finishCut = () => {
    setActiveTool('');
    clearCluLayer();
    drawCluBoundary();
  };

  const connectBoundaries = () => {
    polygonFormat.current = 'connect';
    setActiveTool('connect');
    enablePolygonDrawMode();
  };

  const sliceBoundary = () => {
    try {
      setActiveTool('slice');
      slicingMode.current = true;
      const lineControl = document.getElementsByClassName(
        'control-icon leaflet-pm-icon-polyline',
      )[0];
      lineControl.click();
    } catch (err) {
      console.error(err);
    }
  };

  // enable polygon draw mode
  const enablePolygonDrawMode = () => {
    map.pm.enableDraw('Polygon', {
      snappable: true,
      snapDistance: 20,
      allowSelfIntersection: false,
    });
  };

  const draw = () => {
    polygonFormat.current = 'draw';
    setActiveTool('draw');
    enablePolygonDrawMode();
  };

  // Reset everything appropriate when user presses 'Cancel'
  const cancel = () => {
    try {
      // Special handling if following an 'edit' action
      if (activeTool === 'edit') {
        editedCanceled.current = true;
        // Will also handle check to make sure boundary was not previously modified before resetting
        boundaryHasBeenModified(false);

        // Zones do not get redrawn properly after a cancel if user has not edited the boundaries
        if (zones.length) {
          setRedrawZones(redrawZones+1);
        }
      }

      // Disable all features
      map.pm.disableDraw();
      map.pm.disableGlobalCutMode();
      map.pm.disableGlobalEditMode();

      // Reset slicing controls
      slicingMode.current = false;
      currentSlice.current = null;
      if (!zones.length) setZonesSliced(false); 

      // Reset toolbar
      setActiveTool('');
    } catch (err) {
      console.error(err);
    }
  };
  //#endregion

  //#region - JSX display
  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      <EditBoundary
        activeTool={activeTool}
        edit={edit}
        cut={cut}
        finishEdit={finishEdit}
        fillBoundary={fillBoundary}
        reset={resetBoundary}
        sliceBoundary={sliceBoundary}
        draw={draw}
        connect={connectBoundaries}
        cancel={cancel}
        zonesSliced={zonesSliced}
      />

      <Box
        id={`${mapId}`}
        display="flex"
        flexGrow={1}
      />

    </Box>
  //#endregion
  );
}

UpdateIntersectionMap.propTypes = {
  boundaryHasBeenModified: PropTypes.func.isRequired,
  clu: PropTypes.object.isRequired,
  cluFullBoundary: PropTypes.string.isRequired,
  getIntersections: PropTypes.func.isRequired,
  handleBoundaryUpdate: PropTypes.func.isRequired,
  setAcres: PropTypes.func.isRequired,
  setIntersectionZones: PropTypes.func.isRequired,
  operation: PropTypes.object.isRequired,
};

import React from 'react';
import { Fade, IconButton } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleRoundedIcon from '@material-ui/icons/RemoveCircleRounded';
import { CustomToolTip } from '../../../utils/customComponents';

/**
 * Rendered in AcreageReporting/Display/OperationDisplay.js
 * Also used in AcreageReporting/Modals/Advanced options, as paramater from OperationDisplay.js
 * Provides user with appropraite icons and actions to add or remove an option shown at operation
 * level or in advanced options to each of an operations CLUs. This is to allow the user to update
 * these values for individual CLUs without effecting the value at operation level.
 * @param {String} name Option to include or exclude
 * @param {Array} additionalCluOptions All additional options includes
 * @param {Function} addCluOption Adds selected option to additionalCluOptions
 * @param {Function} removeCluOption Removes selected option to additionalCluOptions
 * @returns {JSX} Inclusion/Exclusion buttons
 */
export const inclusionControl = (name, additionalCluOptions, addCluOption, removeCluOption) => (
  !additionalCluOptions.includes(name) ? (
    <Fade in>
      <IconButton
        color="primary"
        style={{ padding: 0 }}
        onClick={() => addCluOption(name)}
      >
        <CustomToolTip
          title="Include this option to edit in all CLU rows."
          placement="right-start"
        >
          <AddCircleIcon style={{ fontSize: '1.1rem' }} />
        </CustomToolTip>
      </IconButton>
    </Fade>
  ) : (
    <Fade in>
      <IconButton
        color="primary"
        style={{ padding: 0 }}
        onClick={() => removeCluOption(name)}
      >
        <CustomToolTip
          title="Remove this option to edit in all CLU rows."
          placement="right-start"
        >
          <RemoveCircleRoundedIcon style={{ fontSize: '1.1rem' }} />
        </CustomToolTip>
      </IconButton>
    </Fade>
  )
);

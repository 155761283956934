import { uploadOtherFiles, submitVendorOrder } from '../../../utils/dataFetchers';

export const submitOrder = async (
  order,
  subOrderDetails,
  totalAcres,
  totalCost,
  fullname,
  orgName,
  email,
  phone,
  mailingAddress,
  city,
  zipCode,
  state,
  orderName,
  comments,
  setSubmitting,
  setResponse,
  setOrderFail,
  fileData,
  setFileData,
  agentEmail,
  discount,
  ads,
  paid,
  transaction,
  promoCode,
) => {
  try {
    const organizationID = order[0].orgId !== undefined ? order[0].orgId : '';
    const organizationName = (order[0].org !== '' && order[0].org !== undefined) ? order[0].org : orgName;
    let orderID = '';

//  console.log("In Submit Order")
    try {
      if (fileData != null) {
        const res = await uploadOtherFiles(fileData);
        orderID = res.orderID;
      }
    } catch (err) {
      console.error('Something went wrong', err);
    }

    const completeOrder = {
      orderDate: String(new Date()),
      orderId: orderID,
      orderName,
      vendorName: 'DIGS',
      ProductName: 'AgAnalyticsWaterManagement',
      ProductType: 'WaterManagement',
      ProductID: '2',
      farmerDetails: {
        fullname,
        email,
        phone,
        mailingAddress,
        city,
        zipCode,
        state,
      },
      organizationName,
      organizationID,
      totalCost,
      totalAcres,
      subOrderDetails,
      comments,
      engineeringCostPerAcre: 0,
      totalEngineeringCost: 0,
      discount,
      ads,
      paid,
      promoCode,
      ...transaction
    };

    // Response from endpoint
//  console.log(completeOrder)
    
    const apiResponse = await submitVendorOrder(completeOrder, agentEmail);
    // console.log('Response submission response', apiResponse)
    setSubmitting(false);
    const status = apiResponse?.status;

    // Response JSON for order
    const response = await apiResponse.json();

    if (status === 200) {
      setResponse(response);
    } else {
      setOrderFail(true);
    }

    setFileData(null);
  } catch (err) {
    console.error(err);
  }
};

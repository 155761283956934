import * as L from 'leaflet';
import * as turf from '@turf/turf';
import { getRandomColor } from './helpers';


export const handleSliceVertex = (
  e,
  boundary,
  currentSlice,
  slicedZones,
  setZones,
  drawnZones,
  setDrawnZones,
  map,
  slicedMap,
  zonesColors
) => {

  let usedColors = [];

  if (currentSlice.current === null) {
    currentSlice.current = L.polyline([e.latlng]);
  } else {
    currentSlice.current.addLatLng(e.latlng);
    const sliceGeo = currentSlice.current.toGeoJSON();

    // use field boundary if zones don't exist, other wise use zones
    let intersection = {
      features: [],
    };
    let features = [];
    const existing = [];

    if (slicedZones.current.length === 0) {
      //if no slices exist, push the field geojson into the features list
      features.push(JSON.parse(boundary))
      // if (editedFieldGeo.current === null) {
      //   features.push(JSON.parse(fieldData.selectedField.boundary));
      // } else {
      //   features.push(editedFieldGeo.current);
      // }
      //intersect the slice with the feature
      intersection = turf.lineIntersect(features[0], sliceGeo);
      // console.log("initial intersection",intersection);
    } else {
      // need to do a intersect check on each existing zone
      for (const sz of slicedZones.current) {
        const zoneIntersection = turf.lineIntersect(sz, sliceGeo);
        // console.log("zone intersection", zoneIntersection)
        // if intersects, add sz to features for cut
        if (zoneIntersection.features.length > 1) {
          intersection = zoneIntersection;
          // console.log("adding sz to feature", sz)
          features.push(sz);
        } else {
          // need to make sure that existing zones not in a slice are saved
          // console.log("adding sz to existing list")
          existing.push(sz);
        }
      }
    }


    //if intersection was successful?
    if (intersection.features.length > 1) {
      //clear lists of previous items
      slicedZones.current = [];
      setZones([{ zone: null, type: 'clear' }]);

      // line has connected to other edge
      // split polygon based on line
      // loop over features, checking for a multipoly. if multi, split into polys and remake list
      const multiPolyNdxs = new Set();
      const newPolys = [];
      // console.log("Features", features)
      for (const feat of features) {
        // console.log(feat)
        if (feat.geometry.type == 'MultiPolygon' || feat.geometry.coordinates.length > 1) {
          // loop over coordinates and create new Polygon feature
          for (const coordinates of feat.geometry.coordinates) {

            const newP = {
              geometry: {
                coordinates: [],
                type: 'Polygon',
              },
              properties: {},
              type: 'Feature',
            };
            if(coordinates.length > 1){
              newP.geometry.coordinates.push(coordinates)
            }
            else{
              newP.geometry.coordinates = coordinates;
            }
            newPolys.push(newP);
            multiPolyNdxs.add(features.indexOf(feat));
          }
        }
      }
      // console.log("new polys from features",newPolys)
      // console.log("features multipoly ndxs",multiPolyNdxs)

      // remove multi polygons from list and add in new created polygons
      let multiNdxArr = Array.from(multiPolyNdxs)
      multiNdxArr.map((x) => features.splice(x, 1));

      features = [...features, ...newPolys];
      //this might be the palce to take difference of overlapping polygons?
      features = removeHolePolygons(features)

      // console.log("features after poly editing", features)
      const zoneColors = [];
      let finalZones = null;

      for (const toSplit of features) {
        let split;
        // console.log(toSplit, sliceGeo.geometry)
        try {
          split = polygonCut(toSplit.geometry, sliceGeo.geometry);
          // console.log(toSplit, split);
        } catch (err) {
          // console.log("Whhops", err)
          // clear vertexes + line
          // clearSlice()
          // enqueueSnackbar("Whoops, looks like something went wrong. The Split tool struggles with fields that have strange shapes and holes. Please try another tool.",{
          //   autoHideDuration: 8000
          // } )
          return;
        }

        if (split != null) {
          // console.log("Split", split)
          const splitFeats = split.features;
          for (const feature of splitFeats) {
            const featurePoly = turf.polygon(feature.geometry.coordinates);
            const area = turf.area(featurePoly) / 4046.86;
            feature.properties.CALCACRES = area;
            slicedZones.current.push(feature);
          }
        } else {
          // console.log("Split null, tosplit:", toSplit)
          const featurePoly = turf.polygon(toSplit.geometry.coordinates);
          const area = turf.area(featurePoly) / 4046.86;
          featurePoly.properties.CALCACRES = area;
          slicedZones.current.push(featurePoly);
        }

        if (finalZones === null) {
          if(split === null){
            finalZones = {
              type: "FeatureCollection",
              features: [toSplit]
            }
          }
          else{
            finalZones = split
          }
        } else if (split === null) {
          finalZones.features.push(toSplit);
        } else {
          split.features.map((x) => finalZones.features.push(x));
        }
      }

      slicedZones.current = [...slicedZones.current, ...existing];

      const currentZones = [];

      // by this point if finalZones is null then there is no new completed split
      // return to avoid clearing zones and map errors
      if (finalZones === null) {
        return;
      }

      // if(Object.keys(geoJson).length === 0){
      setZones([{ zone: null, type: 'clear' }]);
      // }
      //console.log("slicedzones", slicedZones)
      let currColors = []
      for (let i=0; i < slicedZones.current.length; i++) {
        let color = ''
        if (zonesColors.length > i) {
          color = zonesColors[i]
        } else {
          color = getRandomColor();
        }

        slicedZones.current[i].properties.color = color;
        slicedZones.current[i].properties.featureRef = slicedMap;
        slicedZones.current[i].properties.mapRef = map;
      }

      existing.map((x) => finalZones.features.push(x));

      if (origin === 'Other') {
        // handleZone(slicedZones.current, "multiple")
        setZones([{ zone: slicedZones.current, type: 'multiple' }]);
      } else {
        // slicedZones.current.map((x) => handleZone(x, "add"));
        const forZones = slicedZones.current.map((x) => ({ zone: x, type: 'add' }));
        setZones(forZones);
      }

      // need to create zones with geojson and keep track of zones
      if (map.hasLayer(slicedMap.current)) {
        map.removeLayer(slicedMap.current);
      }
      slicedMap.current.clearLayers();
      //console.log("final zones", finalZones)

      slicedMap.current.addData(finalZones);

      // loop over layers and set feature id to layer id
      slicedMap.current.eachLayer((layer) => {
        layer.feature.properties.id = layer._leaflet_id;
      });


      // remove previously drawn from map
      drawnZones.forEach((item) => {
        item.eachLayer((layer) => {
          map.removeLayer(layer);
        });
        item.remove();
      });

      // set drawn zones to slicedzone for tracking purposes
      setDrawnZones(slicedMap.current)
      drawnZones.current = [slicedMap.current];
      //slicedMap.current.addTo(map);
      colorZones(zoneColors, slicedMap);

      // finish drawing line to restart
      const lineControl = document.getElementsByClassName(
        'control-icon leaflet-pm-icon-polyline',
      )[0];
      const control = lineControl.parentNode;
      const lineEditControls = control.nextSibling;
      let finishLine = null;
      for (let i = 0; i < lineEditControls.childNodes.length; i++) {
        if (
          lineEditControls.childNodes[i].className
          === 'leaflet-pm-action  action-finish'
        ) {
          finishLine = lineEditControls.childNodes[i];
          break;
        }
      }
      finishLine.click();
      // clear currentSlice
      currentSlice.current = null;

      // click start again for continued slicing
      const start = document.getElementsByClassName(
        'control-icon leaflet-pm-icon-polyline',
      )[0];
      start.click();
      return finalZones.features;
      console.log('finalZones',finalZones)
    }
  }
};

function polygonCut(polygon, line, idPrefix) {
  // this is a function to cut a pulygon into seperate polygons
  // based on a line.
  // taken from - https://gis.stackexchange.com/questions/344068/splitting-a-polygon-by-multiple-linestrings-leaflet-and-turf-js
  const THICK_LINE_UNITS = 'kilometers';
  const THICK_LINE_WIDTH = 0.001;
  let i; let j; let id; let intersectPoints; var lineCoords; let forCut; let
    forSelect;
  let thickLineString; let thickLinePolygon; let clipped; let polyg; let
    intersect;
  let polyCoords = [];
  let cutPolyGeoms = [];
  const cutFeatures = [];
  const offsetLine = [];
  let retVal = null;

  if (
    (polygon.type != 'Polygon' && polygon.type != 'MultiPolygon')
    || line.type != 'LineString'
  ) {
    return retVal;
  }

  if (typeof idPrefix === 'undefined') {
    idPrefix = '';
  }

  intersectPoints = turf.lineIntersect(polygon, line);
  if (intersectPoints.features.length == 0) {
    return retVal;
  }

  var lineCoords = turf.getCoords(line);
  if (
    turf.booleanWithin(turf.point(lineCoords[0]), polygon)
    || turf.booleanWithin(turf.point(lineCoords[lineCoords.length - 1]), polygon)
  ) {
    return retVal;
  }

  offsetLine[0] = turf.lineOffset(line, THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS,
  });
  offsetLine[1] = turf.lineOffset(line, -THICK_LINE_WIDTH, {
    units: THICK_LINE_UNITS,
  });

  for (i = 0; i <= 1; i++) {
    forCut = i;
    forSelect = (i + 1) % 2;
    polyCoords = [];
    for (j = 0; j < line.coordinates.length; j++) {
      polyCoords.push(line.coordinates[j]);
    }
    for (
      j = offsetLine[forCut].geometry.coordinates.length - 1;
      j >= 0;
      j--
    ) {
      polyCoords.push(offsetLine[forCut].geometry.coordinates[j]);
    }
    polyCoords.push(line.coordinates[0]);

    thickLineString = turf.lineString(polyCoords);
    thickLinePolygon = turf.lineToPolygon(thickLineString);
    clipped = turf.difference(polygon, thickLinePolygon);

    cutPolyGeoms = [];
    for (j = 0; j < clipped.geometry.coordinates.length; j++) {
      polyg = turf.polygon(clipped.geometry.coordinates[j]);
      intersect = turf.lineIntersect(polyg, offsetLine[forSelect]);
      if (intersect.features.length > 0) {
        cutPolyGeoms.push(polyg.geometry.coordinates);
      }
    }

    cutPolyGeoms.forEach((geometry, index) => {
      id = `${idPrefix + (i + 1)}.${index + 1}`;
      cutFeatures.push(turf.polygon(geometry, { id }));
    });
  }

  if (cutFeatures.length > 0) retVal = turf.featureCollection(cutFeatures);

  return retVal;
}

/**
 * checks list of polygons to see if any polygon lies within another and takes the difference
 * removing any holes from what should be a multipolygon
 * @param {*} features
 */
const removeHolePolygons = (features) => {
  try{
    let maskedZones = []
    let intersected = []
    for(let i=0; i<features.length; i++){
      let evalZone = features[i]
      if(!intersected.includes(i)){
        for(let j=i+1; j<features.length; j++){
          if(turf.booleanContains(evalZone, features[j])){
            evalZone= turf.difference(evalZone, features[j])
            intersected.push(j)
          }
        }
        maskedZones.push(evalZone)
      }
    }
    return maskedZones
  }
  catch(err){
    console.log(err)
    return features
  }
}

const colorZones = (zoneColors, slicedMap) => {
  let x = 0;

  const newZonesNum = zoneColors.length;
  slicedMap.current.eachLayer((layer) => {
    // layer.on('click', (e) => {
    //   setClickedFeature(e.target.feature);
    // });
    if (x <= newZonesNum) {
      var zoneTheme = {
        color: zoneColors[x],
        fillColor: zoneColors[x],
        fillOpacity: '0.3',
        strokeWidth: '5',
      };
    }
    x++;
    layer.setStyle(zoneTheme);
  });
}

/* eslint-disable max-len */
/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';

import { Box, Typography, IconButton } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import VisibilityIcon from '@material-ui/icons/Visibility';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from '../../../utils/customComponents';
import { CreateCLURow } from './CluRow';
import { numFormat } from '../../../utils/helpers';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  errorMessageBox: {
    ...theme.errorMessageBox,
    fontSize: '16px',
  },
  root: {
    display: 'flex',
    flexDirection: 'column',
  },
}));

/**
 * Display attributes for operation at selected CLU intersection
 * @param {Oject} CLU Clu data
 * @param {Number} cluIndex Index of CLU in operation
 * @param {Array} additionalOptions Options to display for updating
 * @param {Object} operationInfo Field data
 * @param {Number} opIndex Index in field array
 * @param {Number} fieldIndex Field index in farm array
 * @param {Number} farmIndex Farms index in ordered mappings
 * @param {Object} statesSeen key:value mappings of states seen and their associated commodities
 * @param {Object} commoditiesSeen key:value mappings of commodities seen and their associated commodity types
 * @param {Object} commodityTypesSeen key:value mappings of commodity types seen and their associated intended uses
 * @param {Object} clusSeen reference for CLU information
 * @param {Function} updateIntersection Updates data for a CLU, that intersects with operations
 * @param {Function} editOperationWithClu Helps setup the modal to edit the CLU boundary
 * @param {Array} expanded Expanded clus
 * @param {Function} setExpanded Sets expansion panels
 * @param {Array} included Included CLUs
 * @param {Function} setIncluded Sets status of included
 * @returns {JSX} CLU attribtues
 */
export function CluDisplay({
  clu,
  cluIndex,
  additionalOptions,
  operationInfo,
  fieldIndex,
  farmIndex,
  opIndex,
  statesSeen,
  commoditiesSeen,
  commodityTypesSeen,
  clusSeen,
  updateIntersection,
  editOperationWithClu,
  expanded,
  setExpanded,
  included,
  setIncluded,
}) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  // Handle expanding and hiding one CLU
  const handleChange = () => (event, isExpanded) => {
    if (isExpanded) {
      setExpanded([...expanded, clu?.zoneID]);
    } else {
      const filtered = expanded.filter((x) => x !== clu?.zoneID);
      setExpanded(filtered);
    }
  };

  // Handle including and excluding one CLU
  const handleInExclude = (wasIncluded) => {
    if (wasIncluded) {
      setIncluded([...included, clu?.zoneID]);
    } else {
      const filtered = included.filter((x) => x !== clu?.zoneID);
      setIncluded(filtered);
    }
  }

  // Decides what to display in the header/summary section for this CLU
  const createTitle = () => {
    if (clusSeen[clu.clu_identifier]) {
      return (
        `Farm #${clusSeen[clu.clu_identifier].farm_number}, Tract #${clusSeen[clu.clu_identifier].tract_number}, CLU #${clu.displayNumber}`
      )
    }
    else {
      enqueueSnackbar('Something went wrong while getting some of your CLU information. Please press "Load Acreage Data" again.');
      return (
        `Farm #?, Tract #?, CLU #?`
      )
    }
  }

  return (
    <Box>
      <Accordion
        expanded={expanded.includes(clu?.zoneID)}
        onChange={handleChange('panel')}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1bh-content"
          id="panel1bh-header"
        >
          <Box display="flex" alignItems="center">
            {clu.shown ? (
              <IconButton
                style={{ padding: '0px 8px 0px' }}
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  updateIntersection([{ shown: false }], {
                    farmIndex,
                    fieldIndex,
                    opIndex,
                    cluIndex,
                  });
                  handleInExclude(false);
                }}
              >
                <CustomToolTip placement="right" title={'This CLU WILL be included in final report'}>
                  <VisibilityIcon fontSize="small" />
                </CustomToolTip>
              </IconButton>
            ) : (
              <IconButton
                style={{ padding: '0px 8px 0px' }}
                color="primary"
                onClick={(event) => {
                  event.stopPropagation();
                  updateIntersection([{ shown: true }], {
                    farmIndex,
                    fieldIndex,
                    opIndex,
                    cluIndex,
                  })
                  handleInExclude(true);
                }}
              >
                <CustomToolTip placement="right" title={'This CLU WILL NOT be included in final report'}>
                  <VisibilityOffIcon fontSize="small" />
                </CustomToolTip>
              </IconButton>
            )}

            {/* Don't allow opening EditMap for "fake CLUs" - functionality not built */}
            <IconButton
              style={{ padding: '0px 8px 0px' }}
              color="primary"
              onClick={(event) => {
                event.stopPropagation();
                editOperationWithClu(clu, {
                  ...operationInfo,
                  fieldIndex,
                  farmIndex,
                  opIndex,
                  cluIndex,
                });
              }}
              disabled={clu.clu_identifier === 'X'}
            >
              <EditLocationIcon />
            </IconButton>

            <Typography style={{ fontSize: '1.2rem' }}>
              {createTitle()}
            </Typography>

            {/* There should be no reason for finalReportedAcreage to not have a value... */}
            <Box ml={2}>
              {'Acres: '}
              {numFormat(clu?.finalReportedAcreage
                ? clu.finalReportedAcreage
                : clu.acres
              )}
            </Box>

            {/* For CLUs modified/created by nonCLUBoundaries, notify user that something is different*/}
            {clu.section === -1 && (
              // For "fake CLUs", give an explanation of why things seem different and why EditMap is disabled
              clu.clu_identifier === 'X' ? (
                <CustomToolTip placement="right" title={'This entry did not intersect with a CLU and so has limited functionality. This boundary will be included in PDF reports but WILL NOT be included in report downloads or submissions to USDA. For more functionality, please upload CLU boundaries that intersect with this entry\'s boundary.'}>
                  <InfoOutlinedIcon style={{marginLeft: 16, color: "red"}}/>
                </CustomToolTip>
              ) :
              // For modified CLUs, let them know that boundary has been altered and how to fix
              (
                <CustomToolTip placement="right" title={'This entry\'s boundary extends past the original CLU boundary. This has happeend because your uploaded CLU boundaries do not fully cover your planting boundary. Boundaries that signficantly extend past the CLU boundary will likely not be accepted by USDA. To resolve this, please upload CLU boundaries that cover this planting\'s entire boundary.'}>
                  <InfoOutlinedIcon style={{marginLeft: 16, color: "red"}}/>
                </CustomToolTip>
              )
            )}
          </Box>
        </AccordionSummary>

        <AccordionDetails style={{ padding: 0 }}>
          {!clu.error ? (
            <CreateCLURow
              key={clu.zoneID}
              additionalCluOptions={additionalOptions}
              cluRow={clu}
              cluIndex={cluIndex}
              clusSeen={clusSeen}
              commoditiesSeen={commoditiesSeen}
              commodityTypesSeen={commodityTypesSeen}
              fieldIndex={fieldIndex}
              farmIndex={farmIndex}
              opIndex={opIndex}
              operationInfo={operationInfo}
              statesSeen={statesSeen}
              updateIntersection={updateIntersection}
            />
          ) : (
            <Box
              key={clu.zoneID}
              m={1}
              className={classes.errorMessageBox}
            >
              {clu.error}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>
    </Box>
  );
}

CluDisplay.propTypes = {
  clu: PropTypes.object.isRequired,
  cluIndex: PropTypes.number.isRequired,
  additionalOptions: PropTypes.array.isRequired,
  operationInfo: PropTypes.object.isRequired,
  opIndex: PropTypes.number.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  farmIndex: PropTypes.number.isRequired,
  updateIntersection: PropTypes.func.isRequired,
  editOperationWithClu: PropTypes.func.isRequired,
  statesSeen: PropTypes.object.isRequired,
  commoditiesSeen: PropTypes.object.isRequired,
  commodityTypesSeen: PropTypes.object.isRequired,
  clusSeen: PropTypes.object.isRequired,
  expanded: PropTypes.array.isRequired,
  setExpanded: PropTypes.func.isRequired,
  included: PropTypes.array.isRequired,
  setIncluded: PropTypes.func.isRequired,
};

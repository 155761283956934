import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import { COLORS, ORG_ID } from "../../../../../constants";
import InfoPopover from "../../../../Helpers/InfoPopover";
import ProfitabilityMatrixTable from "./ProfitabilityMatrixTable/ProfitabilityMatrixTable";
import { produce } from "immer";
import Spinner from "../../../../Helpers/Spinner";
import { fetchProfitMapSummaries } from "../../../../../utils/dataFetchers";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    height: 400,
    overflow: "auto",
  },
  titleContainer: {
    color: COLORS.darkGray,
    fontSize: 20,
    fontWeight: "bold",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    marginBottom: 21,
  },
  matrixContainer: {},
}));

const ProfitabilityMatrixContainer = ({
  ORG_ID,
  activeFarm,
  reload,
  setReload,
  message,
  setMessage,
  state,
  setState,
  isLoaded,
  setIsLoaded
}) => {
  const classes = useStyles();
  //console.log("state", state)

  /*NOTE: MOVED BELOW LOGIC TO REPORTCONTAINER/FARMREPORT/INDEX.JS FILE 
  TO REDUCE NUMBER OF API CALLS HAPPENING */
  
  // const [state, setState] = useState({
  //   data: [],
  //   metadata: {},
  // });
  // const [isLoaded, setIsLoaded] = useState(false);

  // useEffect(() => {
  //   (async () => {
  //     setIsLoaded(false);
  //     if (activeFarm.id !== undefined && reload) {
  //       setMessage("Fetching Profit Layers Summaries...");
  //       const farms = await fetchProfitMapSummaries(ORG_ID, activeFarm.id);
  //       setState(
  //         produce((draft) => {
  //           draft.data = farms?.data || [];
  //           draft.metadata = farms?.metadata || {};
  //         })
  //       );
  //       setReload(false);
  //     }
  //     setIsLoaded(true);
  //   })();
  // }, [ORG_ID, activeFarm, reload]);

  return (
    <div className={classes.matrixContainer}>
      <div className={classes.wrapper}>
        <div className={classes.titleContainer}>
          Profitability Matrix ($ / acre)
          <InfoPopover text="A view of profitability across all fields" />
        </div>
        {!isLoaded ? (
          <Spinner message={message} />
        ) : (
          <ProfitabilityMatrixTable values={state} />
        )}
      </div>
    </div>
  );
};

export default ProfitabilityMatrixContainer;

import {
  getSeedingMapData,
  combineSeedingOps,
  convertSeedsToKseeds,
} from "./SeedingHelper";
import { getHarvestMapData, combineHarvestOps } from "./HarvestHelper";
import { getApplicationMapData } from "./ApplicationHelper";
import { getGenericMap, getTillageMap } from "./GenericHelper";
import * as df from "../../../utils/dataFetchers";
import * as fr from "../../../utils/farmReportDataFetchers";
import { MONTHS } from "../../../constants";
import * as calc from "../../../utils/ProfitLayersCalc";
import { formatForProfitMap } from "../../ProfitLayers/utils/conversions";
import { Endpoints } from "../../../constants/Endpoints";

const years = [2010, 2011, 2012, 2013, 2014, 2015, 2016, 2017, 2018, 2019, 2020, 2021]

export const formatDate = (date) => {
  /* format date as expected for profit map */
  if (date !== undefined && date !== "") {
    let dash = date.includes("-");
    const d = dash
      ? date.split("T")[0].split("-")
      : date.split("T")[0].split("/");
    return `${d[1]}/${d[2]}/${d[0]}`;
  }
  else return ""
};

export const processData = async (
  results,
  activeYear,
  dieselPrices,
  allFields,
  setMessage
) => {
  //Step 1: Use helper functions to sort each operation data for each field and store using map
  //Step 2: Combine all each field data for all ops
  //Step 3: Perform calculations to get revenue, expenses and profit

  setMessage("Formatting seeding operations data...");
  let seedingMap = await getSeedingMapData(
    results.filter((r) => r.type == "Seeding")[0],
    setMessage
  );
  //console.log("seedingMaop", seedingMap);

  setMessage("Formatting harvest operations data...");
  let harvestMap = await getHarvestMapData(
    results.filter((r) => r.type == "Harvest")[0],
    setMessage
  );
  //console.log("harvestMap", harvestMap);

  setMessage("Formatting application operations data...");
  let applicationMap = await getApplicationMapData(
    results.filter((r) => r.type == "Application")[0],
    activeYear,
    setMessage
  );
  //console.log("applicationMap", applicationMap);
  setMessage("Formatting generic operations data...");
  let genericMap = await getGenericMap(
    results.filter((r) => r.type == "Generic")[0],
    setMessage
  );
  //console.log('genricMap', genericMap)

  setMessage("Formatting tillage operations data...");
  let tillMap = await getTillageMap(
    results.filter((r) => r.type == "Tillage")[0],
    dieselPrices,
    setMessage
  );
  //console.log("tillageMap", tillMap)

  //NetSeededAcre logic for fields

  return [seedingMap, harvestMap, applicationMap, genericMap, tillMap];
};

export const getPrecipMapData = async (stateAndCounty, activeYear) => {
  try {
    let precipData = new Map();
    let fips = []

    for (let s of stateAndCounty) {
      fips.push(s.state + s.county);
    }
    let res = await fr.getPrecipData(fips.join(","), years.join(","));
    res = JSON.parse(res)
    for (let fip of fips) {
      precipData.set(fip, res.filter(x => x.FIPS.toString() === fip.toString()))
    }
    return precipData;
  } catch (err) {
    console.log("get precipmap data error", err);
  }
};

export const getGddMapData = async (stateAndCounty, activeYear) => {
  try {
    let gddData = new Map();

    let fips = []

    for (let s of stateAndCounty) {
      fips.push(s.state + s.county);
    }
    let res = await fr.getGddData(fips.join(","), years.join(","));
    res = JSON.parse(res)
    for (let fip of fips) {
      gddData.set(fip, res.filter(x => x.FIPS.toString() === fip.toString()))
    }
    return gddData;
  } catch (err) {
    console.log("get gdd map data error", err);
  }
};

export const calcuatePrecipitation = (precipData, state, county, months) => {
  let precip = 0;

  try {
    if (state?.length > 0 && county?.length > 0) {
      let fips = state.toString() + county.toString();
      let data = precipData.get(fips);
      let count = 0;

      for (let i = 0; i < months.length; i++) {
        if (months[i].active) {
          if (data[i] !== undefined) {
            count++;
            precip += data[i].Precipitation; //average precipitation
          }
        }
      }
      precip *= 0.03937;
    }
    return precip;
  } catch (err) {
    //console.log("something happened while calculating precipitation data", err);
    return precip;
  }
};

export const calcuatePrecipitationForYear = (data, months) => {
  let precip = 0;

  try {
    let count = 0;

    for (let i = 0; i < months.length; i++) {
      if (months[i].active) {
        if (data[i] !== undefined) {
          count++;
          precip += data[i].Precipitation; //average precipitation
        }
      }
    }
    precip *= 0.03937;
    // console.log("precip", precip)
    return precip;
  } catch (err) {
    //console.log("something happened while calculating precipitation data", err);
    return precip;
  }
};

export const calcuateGDD = (gddData, state, county, months) => {
  let gdd = 0;

  try {
    if (state?.length > 0 && county?.length > 0) {
      let fips = state.toString() + county.toString();
      let data = gddData.get(fips);
      let count = 0;

      for (let i = 0; i < months.length; i++) {
        if (months[i].active) {
          if (data[i] !== undefined) {
            count++;
            gdd += data[i].GDD; //average precipitation
          }
        }
      }
    }
    return gdd;
  } catch (err) {
    //console.log("something happened while calculating precipitation data", err);
    return gdd;
  }
};

export const calcuateGDDForYear = (data, months) => {
  let gdd = 0;

  try {
    for (let i = 0; i < months.length; i++) {
      if (months[i].active) {
        if (data[i] !== undefined) {
          gdd += data[i].GDD; //average GDD
        }
      }
    }
    return gdd;
  } catch (err) {
    //console.log("something happened while calculating GDD data", err);
    return gdd;
  }
};

export const getNetSeededAcres = (
  profitMapSummaries,
  convertedSeedings,
  combinedHarvestOp,
  field,
  activeYear
) => {
  try {
    let profitMapSum = profitMapSummaries.data.filter(
      (p) => p.fieldID === field.FieldID && p.year === activeYear
    );
    const summaryAcres =
      profitMapSum && profitMapSum.length > 0
        ? profitMapSum[0].netSeededAcre
        : 0;
    let fieldAcres = calc.calculateNetSeededAcres(
      field,
      convertedSeedings,
      combinedHarvestOp
    );
    const netSeededAcres =
      summaryAcres !== 0 && summaryAcres <= fieldAcres
        ? summaryAcres
        : fieldAcres;

    // console.log("fieldAcres", fieldAcres)
    // console.log("summaryAcres", summaryAcres)
    // console.log("netSeededAcres", netSeededAcres)

    return netSeededAcres;
  } catch (err) {
    console.log("getNetSeededAcres err", err);
  }
};

export const harvestRevCalc = (combinedHarvestOp, netSeededAcres) => {
  let revenue = 0,
    cropYield = 0,
    crops = [],
    varieties = [],
    totalPrice = 0;

  for (let op of combinedHarvestOp) {
    //console.log('harvest calc', op)    
    if (!op.hidden) {
      cropYield = op.varieties[0].summaryYieldRate;
      crops.push({
        name: op.varieties[0].productName,
        cropYield: op.varieties[0].summaryYieldRate
      });

      for (let va of op.varieties) {
        varieties.push({
          cropName: op.varieties[0].productName,
          name: va.variety,
          varietyYield: va.varietyYieldRate,
          varietyAcres: va.area
        });
        let acres = isNaN(netSeededAcres) ? va.area : netSeededAcres;
        let quantity = va.varietyYieldRate * va.area 
        totalPrice += quantity * va.price
      }
    }
  }

  revenue = totalPrice / netSeededAcres


  if (varieties.length == 0) {
    varieties.push({
      cropName: "",
      name: "",
      varietyYield: 0,
      varietyAcres: 0
    });
  }

  if (crops.length == 0) {
    crops.push({
      name: "",
      cropYield: 0,
    });
  }

  //console.log("crops", crops)

  return [revenue, cropYield, varieties, crops];
};

export const seedingExpenCalc = (convertedSeedings, netSeededAcres) => {
  let seedexpenses = 0,
    varieties = [];

  for (let op of convertedSeedings) {
    if (!op.hidden) {
      for (let va of op.varieties) {
        let acres = isNaN(netSeededAcres) ? va.area : netSeededAcres;
        seedexpenses += (va.varietyRate * va.area * va.price); //replace va.area in the denominator with netSeededAcres

        varieties.push({
          cropName: op.varieties[0].productName,
          name: va.variety,
          varietyAcres: va.area
        });
      }
    }
  }
  seedexpenses = seedexpenses/netSeededAcres

  if (varieties.length == 0) {
    varieties.push({
      cropName: "",
      name: "",
      varietyYield: 0,
      varietyAcres: 0
    });
  }

  return [seedexpenses, varieties];
};

export const appExpenCalc = (appOp, netSeededAcres) => {
  let appExpenses = 0;

  for (let op of appOp) {
    if (!op.isDeleted) {
      for (let c of op.applicationComponents) {
        let acres = isNaN(netSeededAcres) ? c.area : netSeededAcres;
        appExpenses += (c.componentApplicationRate * c.area * c.price);
      }
    }
  }

  appExpenses = appExpenses / netSeededAcres

  return appExpenses;
};

export const fpExpenCalc = (fp, netSeededAcres) => {
  let cost = fp.map((x) => x.price * x.area).reduce((a,b) => a+b, 0);
  return cost / netSeededAcres

};

export const otherExpenCalc = (costs, netSeededAcres) => {
  let otherCost = 0;

  for (let op of costs) {
    if (!op.isDeleted) {
      for (let z of op.zones) {
        let acres = isNaN(netSeededAcres) ? z.area : netSeededAcres;
        otherCost += (z.area * z.price);
      }
    }
  }

  otherCost = otherCost / netSeededAcres

  return otherCost;
};

export const otherRevCalc = (revs, netSeededAcres) => {
  let otherRev = 0;

  for (let op of revs) {
    if (!op.isDeleted) {
      for (let z of op.zones) {
        let acres = isNaN(netSeededAcres) ? z.area : netSeededAcres;
        otherRev += (z.area * z.price);
      }
    }
  }

  otherRev = otherRev / netSeededAcres

  return otherRev;
};

export const getDataForAnalytics = async (
  allFields,
  seed,
  har,
  app,
  generic,
  tillage,
  profitMapSummaries,
  activeYear,
  months,
  dieselPrices,
  farmReportInfo
) => {
  let updatedData = [];

  let seedingOps = new Map(),
    harvestOps = new Map(),
    appOps = new Map(),
    fieldPassOps = new Map(),
    otherCostOps = new Map(),
    otherRevOps = new Map();

  let crops = [];

  if (allFields.length > 0) {
    for (let f of allFields) {
      let varietyBreakdown = [];
      //get operation data for fieldID and save it separate variables
      let seedOp = seed.has(f.FieldID) ? seed.get(f.FieldID) : [];
      let harOp = har.has(f.FieldID) ? har.get(f.FieldID) : [];
      let appOp = app.has(f.FieldID) ? app.get(f.FieldID) : [];
      let genericOp = generic.has(f.FieldID) ? generic.get(f.FieldID) : [];
      let till = tillage.has(f.FieldID) ? tillage.get(f.FieldID) : [];

      // console.log("seedingOp", seedOp)
      // console.log("appOp", appOp)

      /*Precip Calc Region Begin: group all ops together to get state and county id associuated with field and perform calclation to get cumulative precipitatiuon  */
      let fieldData = [...seedOp, ...harOp, ...appOp, ...genericOp, ...till];
      let opsData = [...seedOp, ...harOp, ...appOp, ...till];

      const state = [...new Set(fieldData.map((c) => c.state))];
      const county = [...new Set(fieldData.map((c) => c.county))];

      /*Precip Calc Region End */

      /*Filter Region to separate fieldpass, other cost and revenue */
      let fp = genericOp.filter((x) => x.type === "Field Pass");
      fp = fp.map((x) => ({ ...x, originalArea: x.area }));
      fp = [...fp, ...till];

      let costs = genericOp.filter((x) => x.type === "Cost");
      let revs = genericOp.filter((x) => x.type === "Revenue");
      /*Filter Region end */

      //Combine Seeding Ops similar to profitlayers, group all varieties associaed with an operationiD
      let combinedSeedOp = combineSeedingOps(seedOp);
      const convertedSeedings = convertSeedsToKseeds(combinedSeedOp);
      seedingOps.set(f.FieldID, convertedSeedings);

      //Combine Harvest Ops similar to profitlayers, group all varieties associaed with an operationiD
      let combinedHarvestOp = combineHarvestOps(harOp);
      harvestOps.set(f.FieldID, combinedHarvestOp);
      //Set operations in map
      appOps.set(f.FieldID, appOp);
      fieldPassOps.set(f.FieldID, fp);
      otherCostOps.set(f.FieldID, costs);
      otherRevOps.set(f.FieldID, revs);

      /*NetSeededAcres Calculation Region Start */
      const netSeededAcres = getNetSeededAcres(
        profitMapSummaries,
        convertedSeedings,
        combinedHarvestOp,
        f,
        activeYear
      );

      /*NetSeededAcres Calculation Region End */

      /*Region to get primary crop start*/
      let harvestCrop = combinedHarvestOp.map((c) => c.name);
      let seedCrop = convertedSeedings.map((c) => c.name);
      crops.push(...new Set([...harvestCrop]));

      let primaryCrop = [...new Set([...harvestCrop, ...seedCrop])].join(",");
      /*Region to get primary crop end*/

      /*Region to get costs and revenues start */
      const [revenue, cropYield, varieties, cropYields] = harvestRevCalc(
        combinedHarvestOp,
        netSeededAcres
      );

      const [seedexpenses, seedingVarieties] = seedingExpenCalc(convertedSeedings, netSeededAcres);


      let varietiesList = [...varieties, ...seedingVarieties].map(x => x.name);
      varietiesList = [...new Set(varietiesList)]
      for (let name of varietiesList) {
        let harVar = varieties.filter(x => x.name === name)
        let seedVar = seedingVarieties.filter(x => x.name === name)

        if (harVar.length > 0) {
          varietyBreakdown.push({
            cropName: harVar[0].cropName,
            name: name,
            varietyAcres: harVar[0].varietyAcres,
            fieldID: f.FieldID
          })
        }
        else if (seedVar.length > 0) {
          varietyBreakdown.push({
            cropName: seedVar[0].cropName,
            name: name,
            varietyAcres: seedVar[0].varietyAcres,
            fieldID: f.FieldID
          })
        }
        else {
          varietyBreakdown.push({
            cropName: "",
            name: name,
            varietyAcres: 0,
            fieldID: f.FieldID
          })
        }
      }
      //console.log("varietyBreakdown", varietyBreakdown)
      //varietyBreakdown.push([...varieties, ...seedingVarieties]);

      const appExpenses = appExpenCalc(appOp, netSeededAcres);

      if (netSeededAcres > 0) {
        fp = acreageAdjustment(
          fp,
          netSeededAcres,
          combinedHarvestOp,
          convertedSeedings,
          appOp,
          [activeYear],
          dieselPrices
        );
      }
      const fpCost = fpExpenCalc(fp, netSeededAcres);

      const otherCost = otherExpenCalc(costs, netSeededAcres);

      const otherRev = otherRevCalc(revs, netSeededAcres);

      /*Region to get costs and revenues end */
      let profitablePer = null,
        unprofitablePercent = null;

      let farmReport = farmReportInfo.filter((d) => d.FieldID == f.FieldID);

      if (farmReport.length > 0) {
        profitablePer = farmReport[0].Profitable_Percent;
        unprofitablePercent = farmReport[0].UnProfitable_Percent;
      }

      let obj = {
        fieldID: f.FieldID,
        fieldName: f.FieldName,
        organizationID: f.OrganizationID,
        state: state[0],
        county: county[0],
        acres: isNaN(netSeededAcres) ? f.acres : netSeededAcres,
        active: true,
        selected: false,
        primaryCrop: primaryCrop,
        revenue: revenue + otherRev,
        expenses: (seedexpenses + appExpenses + fpCost + otherCost) * -1,
        profit:
          revenue +
          otherRev -
          (seedexpenses + appExpenses + fpCost + otherCost),
        percentProfitable: profitablePer !== null ? Math.round(profitablePer * 100) / 100 : null,
        unprofitablePercent: unprofitablePercent !== null ? Math.round(unprofitablePercent * 100) / 100 : null,
        cropYield: cropYield,
        varieties: varieties,
        harvest: revenue,
        seeding: seedexpenses * -1,
        chemicals: appExpenses * -1,
        fieldPasses: fpCost * -1,
        otherCosts: otherCost * -1,
        otherRevenues: otherRev,
        crops: cropYields,
        noData: opsData.length == 0,
        varietyBreakdown: varietyBreakdown,
      };
      //console.log('obj', obj)
      updatedData.push(obj);
      //fieldOpInfo.set(f.FieldID, [...seedOp, ...harOp, ...appOp]);
    }
  }

  return [
    updatedData,
    seedingOps,
    harvestOps,
    appOps,
    fieldPassOps,
    otherCostOps,
    otherRevOps,
    crops,
  ];
};

/**
 * adjusts the acreage value for field pass operations to update to the total
 * acreage covered by the operations (sum of acres)
 * @param {Array} fieldPassOps Field pass operations
 * @param {float} acres Acres
 * @returns {Array} copy of field passes
 */
const acreageAdjustment = (
  fieldPassOps,
  acres,
  harvests,
  seedings,
  applicationOperations,
  selectedYears,
  dieselPrices
) => {
  // make copy of fieldPasses
  //console.log('acreage adjustment', fieldPassOps)

  const types = ["Application Costs", "Harvesting Costs", "Seeding Costs"];
  const fieldPassCopy = [...fieldPassOps];
  // console.log("Field pass in adjustment", fieldPassCopy)
  for (const type of types) {
    let displayed = [];
    let totalArea = 0;
    let adjustmentFactor = 0;
    switch (type) {
      case "Application Costs":
        //console.log("applicationOperations", applicationOperations);
        displayed = applicationOperations.filter(
          (x) => !x.isDeleted && x.isIncluded
        );
        //console.log("displayed", displayed)
        break;
      case "Harvesting Costs":
        displayed = harvests.filter((x) => !x.hidden);
        //console.log("harvest", harvests)
        break;
      case "Seeding Costs":
        displayed = seedings.filter((x) => !x.hidden);
        break;
    }

    if (type === "Application Costs") {
      totalArea = displayed.map((x) => x.area).reduce((a, b) => a + b, 0);
      //console.log("totalArea", totalArea)
      adjustmentFactor = totalArea / acres;
      //console.log("adjustmentFactor", adjustmentFactor)
    } else {
      for (const op of displayed) {
        //console.log("op.varieties", op.varieties)
        const opArea = op.varieties
          .map((x) => x.area)
          .reduce((a, b) => a + b, 0);
        totalArea += opArea;
        //console.log("totalArea", totalArea, acres)
      }
      adjustmentFactor = totalArea / acres;
      //console.log("adjustmentFactor", adjustmentFactor)
    }

    const ndx = fieldPassCopy.findIndex(
      (x) =>
        x.name === `${type} - ${selectedYears[0]}` &&
        x.operationYear === +selectedYears[0]
    );
    // console.log('displayed', displayed)
    let totalCost = 0;
    for (const operation of displayed) {
      // console.log(operation)
      let cost = 0;
      switch (type) {
        case "Application Costs":
          if (operation.isIncluded) {
            //console.log('app ops', operation)
            cost = calc.getApplicationCost(operation, dieselPrices);
            //console.log("app cost", cost)
          }
          break;
        case "Harvesting Costs":
          //console.log("harvest", operation.varieties)
          cost = operation.varieties
            .map((x) => calc.getHarvestCost(x, dieselPrices))
            .reduce((a, b) => a + b, 0);
          //console.log("har cost", cost)
          // cost = calc.getHarvestCost(operation, dieselPrices)
          break;
        case "Seeding Costs":
          //console.log("seeding", operation.varieties)
          cost = operation.varieties
            .map((x) => calc.getSeedingCost(x, dieselPrices))
            .reduce((a, b) => a + b, 0);
          //console.log('seed cost', cost)
          // cost = calc.getSeedingCost(operation, dieselPrices)
          break;
      }
      // console.log(type+" cost", cost)
      totalCost += cost;
    }

    // update values
    if (ndx >= 0) {
      fieldPassCopy[ndx].area = acres * adjustmentFactor;
      // console.log("fieldPassCopy[ndx].price", fieldPassCopy[ndx].price)
      // console.log("totalCost", totalCost)
      // console.log("acres", acres)
      fieldPassCopy[ndx].price =
        parseInt(fieldPassCopy[ndx].price) === 0
          ? totalCost / acres
          : fieldPassCopy[ndx].price;
    }
  }
  //console.log("fieldPassCopy", fieldPassCopy)

  return fieldPassCopy;
};

export const formatForProfitMapGen = (fieldOps) => {
  console.log("fieldOps", fieldOps)
  let operationsCopy = JSON.parse(JSON.stringify(fieldOps));
  const toPop = [];
  for (const op of operationsCopy) {
    if (op.fileType.toUpperCase() !== "HARVEST") {
      if (op.fileType.toUpperCase() === "GENERIC") {
        if (op.type === "Field Pass" || op.type === "Cost") {
          // console.log(op)
          if (
            op.name.includes("Application Costs - ") ||
            op.name.includes("Harvesting Costs - ") ||
            op.name.includes("Seeding Costs - ")
          ) {
            op.price *= 1;
          }
          if (op.price > 0) {
            op.price = parseFloat(0 - op.price);
          }
          if (op.type === "Field Pass" && op.area === 0) {
            toPop.push(
              operationsCopy.map((x) => x.operationID).indexOf(op.operationID)
            );
          }
        }
      } else if (op.fileType.toUpperCase() === "SEEDING") {
        // Convert values back to seeds
        if (op.unit === "K seeds") {
          op.unit = op?.originalUnit !== undefined ? op.originalUnit : "seeds";
          op.totalMaterialUnit =
            op?.originalUnit !== undefined ? op.originalUnit : "seeds";
          op.price /= 1000;
          op.averageMaterialTarget = isNaN(op.averageMaterialTarget) ? op.averageMaterialTarget * 1000 : op.rate * 1000;
          op.quantity *= 1000;
          op.rate *= 1000;
        }
        op.price = parseFloat(0 - op.price);
      } else if (op.fileType.toUpperCase() === "TILLAGE") {
        operationsCopy.Constant -= op.price;
      } else if (op.fileType.toUpperCase() === "APPLICATION") {
        op.price =
          op.operationCost > 0
            ? parseFloat(0 - op.operationCost / op.summaryApplicationRate)
            : op.operationCost / op.summaryApplicationRate;
      } else if (op.price > 0) {
        op.price = parseFloat(0 - op.price);
      }
    }
  }

  toPop.map((x) => operationsCopy.splice(x, 1));

  return operationsCopy;
};

export const convertPlantings = (plantings) => {
  /* Convert harvest and seeding operations to format needed for
     reports and calculations.
  */
  const varieties = [];
  for (const op of plantings) {
    for (const variety of op.varieties) {
      varieties.push(variety);
    }
  }
  return varieties;
};

export const formatChemicalData = (data) => {
  let map = new Map();

  try {
    for (let op of data) {
      if (!map.get(op.FieldID)) map.set(op.FieldID, []);
      let opData = map.get(op.FieldID);
      //console.log(data)
      opData.push(op);
      map.set(op.FieldID, opData);
    }

    return map;
  } catch (err) {
    console.log("error formatting chemical data", err);
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  Typography,
  Link,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
    backgroundColor: theme.palette.greys.light,
    width: 340,
  },
  head: theme.updateOperationHeader,
  close: {
    fontSize: 24,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 6,
    right: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
}));

/**
 * Alert user order has failed. Provide contact link.
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Function} setOpen Sets if modal is open
 * @returns {JSX}Modal
 */
export const OrderFailAlert = ({ open, setOpen }) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="uav-info"
      aria-describedby="uav-order-process-description"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
      >
        <HighlightOffIcon
          className={classes.close}
          onClick={() => setOpen(false)}
        />
        <Box p={2}>
          <Typography>
            There was a problem completing your order. Please try again or
            <Link href="https://www.analytics.ag/contact" target="_blank" color="secondary"> contact us </Link>
            or email support@analytics.ag and we will be happy to help with completing your order.
          </Typography>
        </Box>

      </Box>
    </Modal>
  );
};

OrderFailAlert.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

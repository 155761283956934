export const soilTestResponse = {
  "orderId": "e5fd9a38",
  "orderName": "Soil Testing",
  "orderCompletedDate": "Wed Jul 15 2020 17: 53: 38 GMT-0700 (Pacific Daylight Time)",
  "orderLink": "https: //www.paypal.com/invoice/p/#*********",
  "farmerDetails":{
    "fullName": "Swathi",
    "eMail": "swathi@ag-analytics.org",
    "phone": "1111111111",
    "mailingAddress": "asd",
    "city": "asd",
    "state": "IN",
    "zipCode": "14850"
  },
  "organizationName": "Test",
  "organizationID": 1234,
  "subOrderDetails": [
    {
      "subOrderId": "bfa234a2-fe7e-4746-9836-b27f87f3f4bf",
      "fieldId": "1b2vqn",
      "fieldName": "Field - 1",
      "acres": 104.090813,
      "XYCoordinates": "[-86.52171,40.91166]",
      "boundary": "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.513911844456,40.91350056699764],[-86.51412968813457,40.9135248253105],[-86.51491046064922,40.91399717622875],[-86.51554166601505,40.914297284492136],[-86.51657883164773,40.914770700207725],[-86.51774981819949,40.91550779984516],[-86.51857512424255,40.91608327692177],[-86.52041452920713,40.91615958529667],[-86.52164930107438,40.91642784676776],[-86.52273333937205,40.916684040236305],[-86.5256744390241,40.916661853062124],[-86.52587206504376,40.916634135956656],[-86.52586988418778,40.91563299536648],[-86.52450547594691,40.91562737820101],[-86.52449524256139,40.91495234167729],[-86.52586842548743,40.914957995714985],[-86.52586646946196,40.91405990214281],[-86.52586134062835,40.91372238388095],[-86.52554286371213,40.912867679000215],[-86.51791297950035,40.91276846399347],[-86.51791771622959,40.9101006206472],[-86.51395127163386,40.910065909514174],[-86.513911844456,40.91350056699764]]],\"bbox\":[-86.52587206504376,40.910065909514174,-86.513911844456,40.916684040236305]},\"properties\":{\"CALCACRES\":103.91000366,\"OBJECTID\":6562420}}",
      "sampleZones": [
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.525731789227166,40.913375],[-86.52497,40.913375],[-86.52497,40.912860490170381],[-86.525543,40.912868],[-86.525731789227166,40.913375]]]},\"properties\":{\"acres\":0.787986636,\"color\":\"\",\"zone\":\"1\",\"zoneGUID\":\"e5fd9a38_1\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.52586732293986,40.914654],[-86.52497,40.914654],[-86.52497,40.913375],[-86.525731789227166,40.913375],[-86.525861,40.913722],[-86.525866,40.91406],[-86.52586732293986,40.914654]]]},\"properties\":{\"acres\":2.594907,\"color\":\"\",\"zone\":\"2\",\"zoneGUID\":\"e5fd9a38_2\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"MultiPolygon\",\"coordinates\":[[[[-86.5258705994006,40.915933],[-86.52497,40.915933],[-86.52497,40.915629043956045],[-86.52587,40.915633],[-86.5258705994006,40.915933]]],[[[-86.52497,40.914954075746543],[-86.52497,40.914654],[-86.52586732293986,40.914654],[-86.525868,40.914958],[-86.52497,40.914954075746543]]]]},\"properties\":{\"acres\":1.25648916,\"color\":\"\",\"zone\":\"3\",\"zoneGUID\":\"e5fd9a38_3\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.52497,40.916667266235976],[-86.52497,40.915933],[-86.5258705994006,40.915933],[-86.525872,40.916634],[-86.525674,40.916662],[-86.52497,40.916667266235976]]]},\"properties\":{\"acres\":1.51829743,\"color\":\"\",\"zone\":\"4\",\"zoneGUID\":\"e5fd9a38_4\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.52497,40.912860490170381],[-86.52497,40.913375],[-86.523277,40.913375],[-86.523277,40.912838301441681],[-86.52497,40.912860490170381]]]},\"properties\":{\"acres\":2.05916548,\"color\":\"\",\"zone\":\"5\",\"zoneGUID\":\"e5fd9a38_5\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.52497,40.913375],[-86.52497,40.914654],[-86.523277,40.914654],[-86.523277,40.913375],[-86.52497,40.913375]]]},\"properties\":{\"acres\":5.010684,\"color\":\"\",\"zone\":\"6\",\"zoneGUID\":\"e5fd9a38_6\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.52497,40.914654],[-86.52497,40.914954075746543],[-86.524495,40.914952],[-86.524505,40.915627],[-86.52497,40.915629043956045],[-86.52497,40.915933],[-86.523277,40.915933],[-86.523277,40.914654],[-86.52497,40.914654]]]},\"properties\":{\"acres\":4.27651548,\"color\":\"\",\"zone\":\"7\",\"zoneGUID\":\"e5fd9a38_7\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.52497,40.915933],[-86.52497,40.916667266235976],[-86.523277,40.916679930635837],[-86.523277,40.915933],[-86.52497,40.915933]]]},\"properties\":{\"acres\":2.90131068,\"color\":\"\",\"zone\":\"8\",\"zoneGUID\":\"e5fd9a38_8\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.523277,40.912838301441681],[-86.523277,40.913375],[-86.521585,40.913375],[-86.521585,40.912816125819134],[-86.523277,40.912838301441681]]]},\"properties\":{\"acres\":2.14480162,\"color\":\"\",\"zone\":\"9\",\"zoneGUID\":\"e5fd9a38_9\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.523277,40.913375],[-86.523277,40.914654],[-86.521585,40.914654],[-86.521585,40.913375],[-86.523277,40.913375]]]},\"properties\":{\"acres\":5.00772429,\"color\":\"\",\"zone\":\"10\",\"zoneGUID\":\"e5fd9a38_10\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.523277,40.914654],[-86.523277,40.915933],[-86.521585,40.915933],[-86.521585,40.914654],[-86.523277,40.914654]]]},\"properties\":{\"acres\":5.00762749,\"color\":\"\",\"zone\":\"11\",\"zoneGUID\":\"e5fd9a38_11\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.523277,40.915933],[-86.523277,40.916679930635837],[-86.522733,40.916684],[-86.521649,40.916428],[-86.521585,40.916414100486229],[-86.521585,40.915933],[-86.523277,40.915933]]]},\"properties\":{\"acres\":2.5777545,\"color\":\"\",\"zone\":\"12\",\"zoneGUID\":\"e5fd9a38_12\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.521585,40.912816125819134],[-86.521585,40.913375],[-86.519892,40.913375],[-86.519892,40.912793937090434],[-86.521585,40.912816125819134]]]},\"properties\":{\"acres\":2.23297286,\"color\":\"\",\"zone\":\"13\",\"zoneGUID\":\"e5fd9a38_13\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.521585,40.913375],[-86.521585,40.914654],[-86.519892,40.914654],[-86.519892,40.913375],[-86.521585,40.913375]]]},\"properties\":{\"acres\":5.010684,\"color\":\"\",\"zone\":\"14\",\"zoneGUID\":\"e5fd9a38_14\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.521585,40.914654],[-86.521585,40.915933],[-86.519892,40.915933],[-86.519892,40.914654],[-86.521585,40.914654]]]},\"properties\":{\"acres\":5.010587,\"color\":\"\",\"zone\":\"15\",\"zoneGUID\":\"e5fd9a38_15\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.521585,40.915933],[-86.521585,40.916414100486229],[-86.520415,40.91616],[-86.519892,40.916138113586953],[-86.519892,40.915933],[-86.521585,40.915933]]]},\"properties\":{\"acres\":1.22000384,\"color\":\"\",\"zone\":\"16\",\"zoneGUID\":\"e5fd9a38_16\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.519892,40.912793937090434],[-86.519892,40.913375],[-86.518199,40.913375],[-86.518199,40.912771748361727],[-86.519892,40.912793937090434]]]},\"properties\":{\"acres\":2.31990242,\"color\":\"\",\"zone\":\"17\",\"zoneGUID\":\"e5fd9a38_17\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.519892,40.913375],[-86.519892,40.914654],[-86.518199,40.914654],[-86.518199,40.913375],[-86.519892,40.913375]]]},\"properties\":{\"acres\":5.010684,\"color\":\"\",\"zone\":\"18\",\"zoneGUID\":\"e5fd9a38_18\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.519892,40.914654],[-86.519892,40.915933],[-86.5183597826087,40.915933],[-86.518199,40.915820939393939],[-86.518199,40.914654],[-86.519892,40.914654]]]},\"properties\":{\"acres\":4.98974133,\"color\":\"\",\"zone\":\"19\",\"zoneGUID\":\"e5fd9a38_19\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.519892,40.915933],[-86.519892,40.916138113586953],[-86.518575,40.916083],[-86.5183597826087,40.915933],[-86.519892,40.915933]]]},\"properties\":{\"acres\":0.5784527,\"color\":\"\",\"zone\":\"20\",\"zoneGUID\":\"e5fd9a38_20\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.51791665954255,40.910816],[-86.516507,40.910816],[-86.516507,40.91008855104613],[-86.517918,40.910101],[-86.51791665954255,40.910816]]]},\"properties\":{\"acres\":2.353888,\"color\":\"\",\"zone\":\"21\",\"zoneGUID\":\"e5fd9a38_21\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.517914259842513,40.912096],[-86.516507,40.912096],[-86.516507,40.910816],[-86.51791665954255,40.910816],[-86.517914259842513,40.912096]]]},\"properties\":{\"acres\":4.1719656,\"color\":\"\",\"zone\":\"22\",\"zoneGUID\":\"e5fd9a38_22\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.518199,40.912771748361727],[-86.518199,40.913375],[-86.516507,40.913375],[-86.516507,40.912096],[-86.517914259842513,40.912096],[-86.517913,40.912768],[-86.518199,40.912771748361727]]]},\"properties\":{\"acres\":4.56281137,\"color\":\"\",\"zone\":\"23\",\"zoneGUID\":\"e5fd9a38_23\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.518199,40.913375],[-86.518199,40.914654],[-86.516507,40.914654],[-86.516507,40.913375],[-86.518199,40.913375]]]},\"properties\":{\"acres\":5.00772429,\"color\":\"\",\"zone\":\"24\",\"zoneGUID\":\"e5fd9a38_24\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.518199,40.914654],[-86.518199,40.915820939393939],[-86.51775,40.915508],[-86.516579,40.914771],[-86.516507,40.914738089681784],[-86.516507,40.914654],[-86.518199,40.914654]]]},\"properties\":{\"acres\":2.38216686,\"color\":\"\",\"zone\":\"25\",\"zoneGUID\":\"e5fd9a38_25\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.516507,40.91008855104613],[-86.516507,40.910816],[-86.514814,40.910816],[-86.514814,40.910073614066043],[-86.516507,40.91008855104613]]]},\"properties\":{\"acres\":2.87931037,\"color\":\"\",\"zone\":\"26\",\"zoneGUID\":\"e5fd9a38_26\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.516507,40.910816],[-86.516507,40.912096],[-86.514814,40.912096],[-86.514814,40.910816],[-86.516507,40.910816]]]},\"properties\":{\"acres\":5.014796,\"color\":\"\",\"zone\":\"27\",\"zoneGUID\":\"e5fd9a38_27\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.516507,40.912096],[-86.516507,40.913375],[-86.514814,40.913375],[-86.514814,40.912096],[-86.516507,40.912096]]]},\"properties\":{\"acres\":5.01078129,\"color\":\"\",\"zone\":\"28\",\"zoneGUID\":\"e5fd9a38_28\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.516507,40.913375],[-86.516507,40.914654],[-86.516323031645555,40.914654],[-86.515542,40.914297],[-86.51491,40.913997],[-86.514814,40.913938907692312],[-86.514814,40.913375],[-86.516507,40.913375]]]},\"properties\":{\"acres\":3.79420733,\"color\":\"\",\"zone\":\"29\",\"zoneGUID\":\"e5fd9a38_29\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.516507,40.914654],[-86.516507,40.914738089681784],[-86.516323031645555,40.914654],[-86.516507,40.914654]]]},\"properties\":{\"acres\":0.0178986751,\"color\":\"\",\"zone\":\"30\",\"zoneGUID\":\"e5fd9a38_30\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.514814,40.910073614066043],[-86.514814,40.910816],[-86.513942484716168,40.910816],[-86.513951,40.910066],[-86.514814,40.910073614066043]]]},\"properties\":{\"acres\":1.49762523,\"color\":\"\",\"zone\":\"31\",\"zoneGUID\":\"e5fd9a38_31\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.514814,40.910816],[-86.514814,40.912096],[-86.513927951965073,40.912096],[-86.513942484716168,40.910816],[-86.514814,40.910816]]]},\"properties\":{\"acres\":2.60301876,\"color\":\"\",\"zone\":\"32\",\"zoneGUID\":\"e5fd9a38_32\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.514814,40.912096],[-86.514814,40.913375],[-86.513913430567683,40.913375],[-86.513927951965073,40.912096],[-86.514814,40.912096]]]},\"properties\":{\"acres\":2.64393067,\"color\":\"\",\"zone\":\"33\",\"zoneGUID\":\"e5fd9a38_33\"}}",
        "{\"type\":\"Feature\",\"geometry\":{\"type\":\"Polygon\",\"coordinates\":[[[-86.514814,40.913375],[-86.514814,40.913938907692312],[-86.51413,40.913525],[-86.513912,40.913501],[-86.513913430567683,40.913375],[-86.514814,40.913375]]]},\"properties\":{\"acres\":0.634396851,\"color\":\"\",\"zone\":\"34\",\"zoneGUID\":\"e5fd9a38_34\"}}"
      ],
      "package": "5 Acre Polygon Basic Package (S1 Test) \"5.0PolyBas\"",
      "costPerAcre": [5],
      "summary": [
        {
          "reportName": "Soil Analysis Summary",
          "reportSummary": [
            {
              "title": "Average Soil OM",
              "description": "2.614%"
            },
            {
              "title": "Average Soil CEC",
              "description": "10.66 meq/100g"
            },
            {
              "title": "Average Soil P1",
              "description": "50.29 ppm"
            },
            {
              "title": "Average Soil K",
              "description": "138.37 ppm"
            },
            {
              "title": "Average Soil CA",
              "description": "1543.7 ppm"
            },
            {
              "title": "Average Soil MG",
              "description": "226.40 ppm"
            },
            {
              "title": "Average Soil pH",
              "description": "6.710 (1)"
            },
            {
              "title": "Average Soil BpH",
              "description": "7.045 (1)"
            },
            {
              "title": "Number of Samples",
              "description": "20"
            }
          ]
        }
      ],
      "shapefiles": ["https://drive.google.com/uc?export=download&id=1EwDLRbC7GpyG9mcEEo9ewdAqSxT-AUuF"],
      "pdfLink": ["https://drive.google.com/uc?export=download&id=1EwDLRbC7GpyG9mcEEo9ewdAqSxT-AUuF"],
      "Operator": "Scott Jackman",
      "OperatorEmail": "abc@gmail.com",
      "OperatorPhone": "*******",
      "OperatorCompany": "Deveron",
      "subTotal": 520.45
    }
  ],
  "totalAcres": 104.09081411388411,
  "totalCost": 520.45
}
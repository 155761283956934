/* eslint-disable max-len */
import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box, Typography, Divider, Container,
} from '@material-ui/core/';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import deveron from '../../images/Deveron.png';
import aglogo from '../../images/AgAnalytics.png';
import { Endpoints } from '../../constants/Endpoints';
import { useWindowDimensions } from '../../utils/dimensions';
import { darkGrey, green } from '../../styles/colors';
import infographic from '../../images/soiltesting_infographic.png';
import background from '../../images/soilBackground.png';
import coverageMap from '../../images/2022_Soil_Coverage_Map.png'

const lightBlue = '#007cba';
const blue = '#2f5597';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    backgroundAttachment: 'fixed',
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
    color: theme.palette.text.primary,
  },
  darken: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    backgroundColor: 'rgba(0,0,0,.6)',
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
  },
  logo: {
    width: 'auto',
    margin: '8px 30px 0px',
  },
  aglogo: {
    width: 'auto',
    margin: '0 30px',
  },
  dot: {
    fontSize: 12,
    marginRight: 4,
    color: theme.palette.greys.dark,
  },
  square: {
    fontSize: '12px',
    margin: '3px 3px 0 0',
    color: lightBlue,
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  product: {
    minWidth: 200,
    padding: '8px',
    margin: '16px',
    fontWeight: 500,
    borderColor: blue,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      borderColor: lightBlue,
    },
  },
  fertilityTests: {
    height: 260,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    padding: '8px',
    // border: `2px solid ${theme.palette.primary.main}`,
    // borderRadius: 4,
    '&:hover': {
      cursor: 'pointer',
      borderColor: theme.palette.primary.dark,
    },
  },
  package: {
    maxWidth: '832px',
    padding: '8px',
    margin: 8,
    fontWeight: 500,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 4,
  },
  header: {
    paddingTop: 8,
    paddingBottom: 4,
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderBottom: `1px solid ${darkGrey}`,
  },
  mobileHeader: {
    paddingTop: 8,
    paddingBottom: 4,
    borderBottom: `1px solid ${darkGrey}`,
  },
  link: {
    color: lightBlue,
    '&:hover': {
      cursor: 'pointer',
      color: blue,
    },
  },
  options: {
    borderRadius: 4,
    padding: '0px 4px',
    border: `2px solid ${theme.palette.primary.main}`,
    '&:hover': {
      cursor: 'pointer',
      border: `2px solid ${theme.palette.primary.dark}`,
    },
  },
}));

/**
 * Soil testing overview
 * @param {Function} setSection Moves to field selection
 * @returns {JSX} Soil testing overview
 */
export function Overview({ setSection }) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  const header = () => (
    <Box className={classes.header}>
      <img
        className={classes.aglogo}
        src={aglogo}
        alt="ag analytics"
        style={{ height: '50px' }}
      />
      <Typography align="center" style={{ fontWeight: 500, fontSize: '2.3rem' }}>
        Soil Testing Solutions
      </Typography>
      <img
        className={classes.logo}
        src={deveron}
        alt="digs"
        style={{ height: '40px' }}
      />
    </Box>
  );

  const mobileHeader = () => (
    <Box className={classes.mobileHeader}>
      <Box display="flex" justifyContent="space-around">
        <img
          className={classes.aglogo}
          src={aglogo}
          alt="ag analytics"
          style={{ height: '45px' }}
        />
        <img
          className={classes.logo}
          src={deveron}
          alt="deveron logo"
          style={{ height: '35px' }}
        />
      </Box>
      <Typography align="center" style={{ fontWeight: 500, fontSize: '2.3rem' }}>
        Soil Testing Solutions
      </Typography>
    </Box>
  );

  return (
    <Box className={classes.root}>
      <Box className={classes.darken}>
        <Container
          maxWidth="md"
          style={{ backgroundColor: '#ffffff', height: '100%', paddingBottom: 8 }}
        >

          { width < 918 ? mobileHeader() : header() }

          <Box mt={2}>
            <Typography>
              Ag-Analytics and Deveron offers a comprehensive package of soil testing services to
              meet your needs. From sampling and lab testing to analysis, Ag-Analytics and Deveron
              will handle the process from start to finish.
            </Typography>
          </Box>

          <Box my={1} display="flex" justifyContent="center" flexDirection="column" alignItems="center">
            <Box border={2} borderColor={blue} width="90%">
              <img src={infographic} alt="infographic" height="auto" width="100%" />
            </Box>
          </Box>

          <Box mt={2}>
            <Typography align="center" variant="h5">
              How To Order
            </Typography>
            <Divider />
            <Box mt={1}>
              <Typography>
                Order fast, high-quality soil testing services. Begin by selecting your field boundary on
                the mapping tool. Then, define your preferred soil sampling zones by using the grid tool.
                Next, select a nutrient level package to add to your selected field. Finally, complete and
                place your order by filling in your contact information. After completion of the service,
                all results, maps, and reports will be distributed to the customer through the Ag-Analytics
                site. For any other questions, please
                {' '}
                <a className={classes.link} target="_blank" rel="noreferrer" href="https://www.analytics.ag/contact">contact us</a>
                {' '}
                at support@analytics.ag.
              </Typography>

            </Box>
            <Typography align="center" variant="body">
              Currently the Soil Testing service is only available in the regions highlighted below.
            </Typography>
            <Box>
              <img src={coverageMap} alt="Coverage Map" style={{width: '100%'}}/>
            </Box>
          </Box>

          <Box mt={1}>
            <Typography align="center" variant="h5">
              Products
            </Typography>
            <Divider />

            <Box display="flex" flexWrap="wrap" justifyContent="center">
              <Box m={0.5}>
                <Typography align="center" variant="h6">
                  Polygon Sampling Options
                </Typography>
                <Box className={classes.options} onClick={() => setSection(1)}>
                  <Box p={1} display="flex" justifyContent="space-between">
                    <Typography>2.5 Acre Polygon Basic Fertility Package:</Typography>
                    <Typography style={{ marginLeft: 8 }}>$7.00/Acre</Typography>
                  </Box>
                  <Divider style={{ backgroundColor: darkGrey, height: 1 }} />

                  <Box p={1} display="flex" justifyContent="space-between">
                    <Typography>2.5 Acre Polygon Advanced Fertility Package:</Typography>
                    <Typography style={{ marginLeft: 8 }}>$10.00/Acre</Typography>
                  </Box>

                  <Divider style={{ backgroundColor: darkGrey, height: 1 }} />

                  <Box p={1} display="flex" justifyContent="space-between">
                    <Typography>5 Acre Polygon Basic Fertility Package:</Typography>
                    <Typography style={{ marginLeft: 8 }}>$5.00/Acre</Typography>
                  </Box>

                  <Divider style={{ backgroundColor: darkGrey, height: 1 }} />

                  <Box p={1} display="flex" justifyContent="space-between">
                    <Typography>5 Acre Polygon Advanced Fertility Package:</Typography>
                    <Typography style={{ marginLeft: 8 }}>$7.00/Acre</Typography>
                  </Box>
                </Box>
              </Box>

              <Box m={0.5}>
                <Typography align="center" variant="h6">
                  Grid Sampling Options
                </Typography>
                <Box className={classes.options} onClick={() => setSection(1)}>
                  <Box p={1} display="flex" justifyContent="space-between">
                    <Typography>2.5 Acre Grid Basic Fertility Package:</Typography>
                    <Typography style={{ marginLeft: 8 }}>$8.00/Acre</Typography>
                  </Box>
                  <Divider style={{ backgroundColor: darkGrey, height: 1 }} />

                  <Box p={1} display="flex" justifyContent="space-between">
                    <Typography>2.5 Acre Grid Advanced Fertility Package:</Typography>
                    <Typography style={{ marginLeft: 8 }}>$11.00/Acre:</Typography>
                  </Box>

                  <Divider style={{ backgroundColor: darkGrey, height: 1 }} />

                  <Box p={1} display="flex" justifyContent="space-between">
                    <Typography>5 Acre Grid Basic Fertility Package:</Typography>
                    <Typography style={{ marginLeft: 8 }}>$6.00/Acre</Typography>
                  </Box>

                  <Divider style={{ backgroundColor: darkGrey, height: 1 }} />

                  <Box p={1} display="flex" justifyContent="space-between">
                    <Typography>5 Acre Grid Advanced Fertility Package:</Typography>
                    <Typography style={{ marginLeft: 8 }}>$7.50/Acre</Typography>
                  </Box>
                </Box>
              </Box>

            </Box>
          </Box>

          <Box mt={0.5} display="flex" flexDirection="column" alignItems="center">
            <Typography align="center" variant="h6" color="textPrimary">
              All product packages include:
            </Typography>
            <Box className={classes.package}>
              <Box py={0.5}>
                <Typography>
                  <strong>Management Zone Creation:  </strong>
                  Yield-based management zones created by combining your declared 2.5-acre
                  or 5-acre sampling grids with your latest yield maps (provided by the customer)
                  and bare-earth imagery.
                </Typography>
              </Box>
              <Box py={0.5}>
                <Typography>
                  <strong>Soil Collection:  </strong>
                  Professional collection of soil samples from your field.
                </Typography>
              </Box>
              <Box py={0.5}>
                <Typography>
                  <strong>Lab Fertility Analysis:  </strong>
                  Laboratory soil tests and fertility analysis based on best management practices
                  from the region. Customers can select Basic or Advanced Fertility tests and analysis.
                  Results are generated and returned as data tables.
                </Typography>
              </Box>
              <Box py={0.5}>
                <Typography>
                  <strong>Map Book:  </strong>
                  A collection of maps representing the results returned by the Lab Fertility Analysis.
                </Typography>
              </Box>
              <Box py={0.5}>
                <Typography>
                  <strong>Soil Analytics Tool:  </strong>
                  Visual analysis of limiting nutrients and overall nutrient performance, which combines
                  your yield maps (provided by the customer) and Lab Fertility Analysis results.
                </Typography>
              </Box>
              <Box py={0.5}>
                <Typography>
                  <strong>Fertility Application Script:  </strong>
                  Recommendations for next season’s nutrient management plan, as defined by results from
                  the Lab Fertility Analysis and the Soil Analytics Tool, as well as your predicted upcoming crop.
                </Typography>
              </Box>
            </Box>
          </Box>

          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box mt={0.5} mx={1}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                Basic Fertility Tests Include:
              </Typography>
              <Divider style={{ backgroundColor: blue }} />
              <Box className={classes.fertilityTests}>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Organic Matter
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Available Phosphorus
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Exchangeable Potassium
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Magnesium
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Calcium
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Soil pH
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Buffer pH
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Cation Exchange Capacity
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Percent Base Saturation of Cation Elements
                  </Typography>
                </Box>
              </Box>
            </Box>

            <Box mt={0.5} mx={1}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                Advanced Fertility Tests Include:
              </Typography>
              <Divider style={{ backgroundColor: blue }} />
              <Box className={classes.fertilityTests}>
                <Typography style={{ fontWeight: 500 }}>
                  All components included in Basic +
                </Typography>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Sulfur
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Zinc
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Manganese
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Iron
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Copper
                  </Typography>
                </Box>
                <Box display="flex" alignItems="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Boron
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box mt={1.5}>
            <Typography align="center" variant="h5">
              Results
            </Typography>
            <Divider />

            <Box mt={1}>
              <Typography align="center">
                Upon completion, the customer will receive results as PDF charts, as well as maps and zone results in shapefile format.
              </Typography>

              <Typography align="center">
                When your results are ready, you may view them at
                {' '}
                <a className={classes.link} target="_blank" rel="noreferrer" href={`${Endpoints.HOME}/app/soil/results`}>app.profit.ag/app/soil/results</a>
              </Typography>
            </Box>

          </Box>

          <Box mt={1}>
            <Typography align="center" color="textPrimary" variant="subtitle2">
              *Please note this service is currently only available in the contiental U.S.
            </Typography>
          </Box>
        </Container>
      </Box>
    </Box>
  );
}

Overview.propTypes = {
  setSection: PropTypes.func.isRequired,
};

import React, { useState, useEffect, useContext, useRef } from 'react'
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  Box, Typography, Select, MenuItem, Button
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import {
  grey,
  lightGrey,
  darkGrey,
  green,
  blackText,
  darkText,
} from "../../../styles/colors";
import { MenuProps } from '../../../styles/select';
import { weightedAvgYield, weightedNutrientValue} from './helper'

const useStyles = makeStyles((theme) => ({
  icon: theme.icon,
  greenIcon: theme.greenIcon,
  input: theme.plInput,
  disabledInput: theme.disabledInput,
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
    },
  },
}));

export function NutrientByYield({ field, nutrientParser, yieldInfo, crops, appYears, years, acres, dates}) {

  /**
   * Update Field Information
   * @param {Object} field selected field data
   */
  const classes = useStyles();
  const nutrientNames = ['Nitrogen', 'Phosphorus', 'Potassium', 'Manganese', 'Chlorine', 'Calcium', 'Copper', 'Sulphur', 'Zinc', 'Magnesium', 'Boron']
  const [selectedNutrient, setSelectedNutrient] = useState(nutrientNames[0])
  const [selectedCrop, setSelectedCrop] = useState('')
  const [yieldData, setYieldData] = useState(null)
  const [byMonthData, setByMonthData] = useState(null)
  const [display, setDisplay] = useState('yield')

  //let grainYield = [], grainYield1 = [], yieldYears = [], finalYieldData = [], finalNutrientData = []

  useEffect(() => {
    if (crops.length > 0) {
      setSelectedCrop(crops[0])
    }
  }, [crops])

  useEffect(() => {
    drawNGraph()
    graphNDate()
  }, [nutrientParser, yieldInfo, selectedCrop, selectedNutrient])

  function drawNGraph() {
    //console.log('2')
    let type = selectedNutrient
    let nutrientData = []
    var cropType = selectedCrop;
    let yieldYears = [], finalYieldData = [], finalNutrientData = [];
    let nRate = [], grainYield = [], nRate1 = [], grainYield1 = [];

    if (type == 'Nitrogen') {
      nRate = []; grainYield = [];
      nRate1 = []; grainYield1 = [];
    }

    var weightedYieldRes = weightedAvgYield(cropType, type, yieldInfo)
    finalYieldData = weightedYieldRes['finalYieldData']

    finalYieldData = finalYieldData.slice(0);
    finalYieldData.sort(function (a, b) {
      var x = a.x;
      var y = b.x;
      return x < y ? -1 : x > y ? 1 : 0;
    })

    let nutrientRes = weightedNutrientValue(type, nutrientParser, appYears, years, acres)
    finalNutrientData = nutrientRes.finalNutrientData

    //nBalanceFactors()
    //console.log('finalNutrientData', finalNutrientData)
    //console.log('nRate', nRate)

    finalNutrientData = finalNutrientData.slice(0);
    finalNutrientData.sort(function (a, b) {
      var x = a.x;
      var y = b.x;
      return x < y ? -1 : x > y ? 1 : 0;
    })

    var options = {
      title: {
        text: '<b>' + type + ' Application vs. Yield</b>'
      },
      xAxis: {
        title: {
          text: '<b>Crop Season</b>'
        }
      },
      yAxis: [{
        title: {
          text: '<b>' + type + ' (lbs./acre)</b>'
        },
      }, {
        title: {
          text: '<b>Yield (bu./acre)</b>'
        },
        opposite: true
      }],
      legend: {
        enabled: true
      },
      series: [{
        name: type + ' Application (lbs./acre)',
        type: 'column',
        data: [{}],
        states: {
          inactive: {
            opacity: 1
          }
        }
      },
      {
        name: 'Yield (bu./acre)',
        type: 'line',
        yAxis: 1,
        data: [{}],
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
      ],

      navigation: {
        menuItemStyle: {
          borderLeft: '10px solid #FFFFFF',
          fontSize: '15px',
          padding: "2px 10px"
        }
      },
      tooltip: {
        crosshairs: true,
        shared: true,
        //formatter: function () {
        //    return 'Amount of <b>' + type + '</b> applied per acre is <b>' + Math.round(this.y*100)/100 + 'lbs</b> in the year <b>' + this.x + '</b>.'
        //}
      },
    }


    //console.log('finalNutrientData', finalNutrientData)
    //console.log('finalYieldData', finalYieldData)

    options.series[0].data = finalNutrientData;
    options.series[1].data = finalYieldData;
    // Set data to use for chart display
    //console.log('setting yield data')
    setYieldData(options)
  }  

  const graphNDate = () => {
    var data = [];
    let type = selectedNutrient

    for (var i = 0; i < nutrientParser.length; i++) {
        var object = {};
        if (type == 'Nitrogen') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].N,
                        x: Date.UTC(year, month, day)
                    }
                    //console.log(object)
                }
            }
        }
        else if (type == 'Phosphorus') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].P,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Potassium') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].K,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Manganese') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].Mn,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Chlorine') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].Cl,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Calcium') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].Ca,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Copper') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].Cu,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Sulphur') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].S,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Zinc') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].Z,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Magnesium') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].Mg,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        else if (type == 'Boron') {
            for (var j = 0; j < dates.length; j++) {
                if (nutrientParser[i].id == dates[j].id) {
                    var date = new Date(dates[j].date);
                    var month = date.getUTCMonth();
                    var year = date.getUTCFullYear();
                    var day = date.getUTCDay();
                    object = {
                        y: nutrientParser[i].B,
                        x: Date.UTC(year, month, day)
                    }
                }
            }
        }
        data.push(object)
    }
    //console.log('graph 2', data)

    data = data.slice(0);
    data.sort(function (a, b) {
        var x = a.x;
        var y = b.x;
        return x < y ? -1 : x > y ? 1 : 0;
    })

    var options = {
        chart: {
            type: 'column',
        },
        title: {
            text: '<b>' + type + ' Application By Month</b>'
        },
        xAxis: {
            type: 'datetime',
            title: {
                text: '<b>Date</b>'
            }
        },
        yAxis: {
            title: {
                text: '<b>' + type + ' (lbs./acre)</b>'
            },
        },
        legend: {
            enabled: false
        },
        series: [
            [{}]
        ],

        navigation: {
            menuItemStyle: {
                borderLeft: '10px solid #FFFFFF',
                fontSize: '15px',
                padding: "2px 10px"
            }
        },
        tooltip: {
            crosshairs: true,
            formatter: function () {
                return 'Amount of <b>' + type + '</b> applied per acre is <b>' + Math.round(this.y * 100) / 100 + 'lbs</b> on <b>' + (new Date(this.x)).toLocaleDateString() + '</b>.'
            }
        },
        plotOptions: {
            series: {
                pointWidth: 10,
                pointPadding: 0.25
            }
        },
        credits: {
            text: 'Ag-Analytics',
            href: 'https://analytics.ag'
        },
    }
    options.series[0].data = data
    //console.log('options',options)
    setByMonthData(options)
  }

  return (
    <Box my={1} style={{ width: "100%" }}>
      <Box display="flex" justifyContent="center">
        <Button
          color='primary'
          variant={display === 'yield' ? 'contained' : 'outlined'}
          onClick={() => setDisplay('yield')}
          style={{margin: '0 8px'}}
          disableElevation
        >
          Nutrient X Yield
        </Button>
        <Button
          color='primary'
          variant={display === 'month' ? 'contained' : 'outlined'}
          onClick={() => setDisplay('month')}
          style={{margin: '0 8px'}}
          disableElevation
        >
          Nutrient by Month
        </Button>
      </Box>
      <Box display="flex" justifyContent="space-between" flexDirection="column">
        <Box
          display="flex"
          flexWrap="wrap"
          fontSize={18}
        >
          <Box mx={1}>
            <Box>
              Nutrient
            </Box>
            <Select
              variant="outlined"
              MenuProps={MenuProps}
              style={{ width: 200, height: 40 }}
              value={selectedNutrient}
              onChange={(e) => setSelectedNutrient(e.target.value)}
            >
              {
                nutrientNames.map((x, i) => (
                  <MenuItem
                    key={i}
                    value={x}
                  >
                    {x}
                  </MenuItem>
                ))
              }
            </Select>
          </Box>

          { display === 'yield' && (
            <Box mx={1}>
              <Box>
                Crop
              </Box>
              <Select
                variant="outlined"
                MenuProps={MenuProps}
                style={{ width: 200, height: 40 }}
                value={selectedCrop}
                onChange={(e) => setSelectedCrop(e.target.value)}
              >
                {
                  crops.map((x, i) => (
                    <MenuItem
                      key={i}
                      value={x}
                    >
                      {x}
                    </MenuItem>
                  ))
                }
              </Select>
            </Box>
          )}

        </Box>

      </Box>

      <Box
        id='nutrient-x-yield'
      >
        {
          (display === 'yield' && yieldData !== null) && (
            <HighchartsReact
              highcharts={Highcharts}
              options={yieldData}
            />
          )
        }

        {
          (display === 'month' && byMonthData !== null) && (
            <HighchartsReact
              highcharts={Highcharts}
              options={byMonthData}
            />
          )
        }
      </Box>

    </Box>
  )
}

import { exists } from "../../../utils/helpers";
import {
  getFieldOperations,
  getIndividualSeedPrice,
  saveOperations,
} from "../../../utils/dataFetchers";
import { formatDate, determineInitialHide, combineOps } from "./helpers";

/**
 * Get seeding data for field from utils/dataFetchers/getFieldOperations using
 * endpoint /api/ProfitTool/ProfitLayers/FieldOperations. Pass data to
 * cleanSeeding to add key/value pairs needed so Planting components can easily
 * use both seeding and harvest. Then pass cleaned data to combinedOps to
 * combine varieties in to operations. After combining, convert operations with
 * unit seed and all varieties >= 1,000 seeds to K seed units and prices.
 * @param  {String}  orgId Id of organization to get operations for
 * @param  {String}  fieldId Id of field to get operations for
 * @param  {Number}  acres Field acres used to determine rates when missing
 * @param  {Number}  year Will be used if only one year is selected
 * @param  {Array}   years Array of years if quering for multiple years
 * @param  {Boolean} updateFromPriceTool Lets controller know if is refresh request
 * @param  {Function} setSeedingOperations sets the seedingOperations state variable in profitlayers.js
 * @return {Promise}
 */
export const getSeedingOperations = async (
  orgId,
  fieldId,
  acres,
  year,
  years,
  updateFromPriceTool,
  setSeedingOperations
) => {
  const seedingOps = await getFieldOperations(
    orgId,
    fieldId,
    "SEEDING",
    year,
    years,
    updateFromPriceTool
  );
  let seedings = [];
  if (updateFromPriceTool && seedingOps.length > 0) {
    let request = {
      FieldOperationList: seedingOps,
      Year: year,
    };
    request.FieldOperationList.map((x) => {
      x["FileType"] = "Seeding";
      x["OperationID"] = x.seedingOperationID;
      x["LogID"] = x.seedingLoggedID;
      x["Variety"] = x.name;
    });
    let res = await saveOperations(orgId, fieldId, request);
    seedings = await getSeedingOperations(
      orgId,
      fieldId,
      acres,
      year,
      years,
      false,
      setSeedingOperations
    );
    return seedings;
  } else {
    if (seedingOps && seedingOps.length > 0) {
      const seedingOperations = await cleanSeeding(
        seedingOps,
        year,
        years,
        acres
      );
      setSeedingOperations(seedingOperations);
      const combinedOps = combineOps(seedingOperations);
      //console.log(combinedOps)
      const convertedSeedings = convertSeedsToKseeds(combinedOps);
      seedings = convertedSeedings.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
      );

      return seedings;
    }
    return [];
  }
};

/**
 * If seeding operation has no price, get price from getIndividualSeedPrice.
 * Format operation object in way expected for Profit Map and further components.
 * @param  {Array}  ops Operation objects
 * @param  {Number}  year Selected year
 * @param  {Array}   years Array of years if quering for multiple years
 * @param  {Number}  acres Field acres used to determine rates when missing
 * @return {Array}  Updated array of seeding operation objects
 */
const cleanSeeding = async (ops, year, years, acres) => {
  const seedingOperations = [];

  // Check if operation has no saved price (precision ag data)
  for (const op of ops) {
    if (op.price === null || op.price === 0) {
      if (exists(op.cropName) && exists(op.totalMaterialUnit)) {
        // get price if name and unit are available
        const crop = await getIndividualSeedPrice(
          op.cropName,
          op.totalMaterialUnit
        );

        // PRICE RETURNED FOR SEEDS ARE FOR 1K SEEDS NEED TO DIVIDE
        if(['seed', 'seeds'].includes(op.totalMaterialUnit.toLowerCase())){
          const seedPrice = crop / 1000;
          op.price = seedPrice;
        }
        else{
          op.price = crop
        }
        
      } else {
        op.price = 0;
      }
    }

    // Add key/value pairs needed to use same component for seeding and harvest
    const operation = formatSeeding(op, year, years, acres);
    seedingOperations.push(operation);
  }
  return seedingOperations;
};

/**
 * Create keys from existing value that can be consistent with harvests
 * @param  {Object} op Operation to format
 * @param  {Number} year Used to determine if initialy show or hide
 * @param  {Array}  years Array of years if quering for multiple years
 * @param  {Number}  acres Field acres used to determine rates when missing
 * @return {Object} Operation with new key/value pairs
 */
const formatSeeding = (op, year, years, acres) => ({
  ...op,
  attribute:
    op.averageMaterialResult === null || op.averageMaterialResult === 0
      ? "TargetRate"
      : "AppliedRate",
  area: op.area === undefined ? acres : op.area,
  cellsize: "0.0001",
  date: op.endDate !== undefined ? formatDate(op.endDate) : op.date,
  fileType: "SEEDING",
  isDeleted: determineInitialHide(op, year, years),
  logID: op.seedingLoggedID === undefined ? 0 : op.seedingLoggedID,
  uid: Math.random().toString(36).substr(2, 9),
  operationCost: op.area > 0 ? (-op.totalMaterial * op.price) / op.area : 0,
  operationID: op.seedingOperationID,
  price: ((op.price + Number.EPSILON) * 10000) / 10000,
  productName: op.cropName,
  quantity:
    op.totalMaterial === undefined
      ? acres * op.averageMaterialTarget
      : op.totalMaterial,
  rate:
    op.totalMaterialUnit === "seeds"
      ? +(op.totalMaterial / op.area).toFixed(0)
      : +(op.totalMaterial / op.area).toFixed(2),
  unit: op.totalMaterialUnit,
  variety: op.name,
});

const convertSeedsToKseeds = (operations) => {
  for (const operation of operations) {
    if (allVarietiesKseeds(operation)) {
      const updatedVarietes = operation.varieties.map((variety) =>
        convertVarietyToKseeds(variety)
      );
      operation.varieties = updatedVarietes;
    }
  }

  return operations;
};

/**
 * Checks if all varieties of operation are >= 1,000 seeds
 * @param  {Object} operation Operation to check
 * @return {Bool} Should operation varieties be converted to Kseeds
 */
export const allVarietiesKseeds = (operation) => {
  //console.log("operation", operation)
  const convertToK =
    operation.varieties.filter(
      (x) =>
        (x.unit.toLowerCase() === "seeds" || x.unit.toLowerCase() === "seed") &&
        x.rate >= 1000
    ).length === operation.varieties.length;

  return convertToK;
};

/**
 * Converts seeding variety to K seeds price and quantity
 * @param  {Object} variety Seeding variety to convert to K seeds
 * @return {Object} Seeding variety updated to K seeds
 */
const convertVarietyToKseeds = (variety) => {
  // Object contains no objects as values, so we can shallow copy
  const converted = { ...variety };

  converted.averageMaterialResult = variety.averageMaterialResult / 1000;
  converted.averageMaterialTarget = variety.averageMaterialTarget / 1000;
  converted.orginalUnit = variety.unit;
  converted.price = Number((variety.price * 1000).toFixed(5));
  converted.quantity = variety.quantity / 1000;
  converted.rate = variety.rate / 1000;
  converted.totalMaterial = variety.totalMaterial / 1000;
  converted.unit = "K seeds";

  return converted;
};

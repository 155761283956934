import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { PhonelinkSetup } from '@material-ui/icons'

export function BrainTreeDropIn({braintree, paypal, setProcessing, renderSubmitButton, googlepay, authorizationToken, onError, onCreate, handlePaymentMethod, 
  isSubmitButtonDisabled, setIsSubmitButtonDisabled, source}) {
    const [dropInInstance, setDropInInstance] = useState(null)
    //const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)
    const [subscriptionIsSubmitted, setSubscriptionIsSubmitted] = useState(false)

    //console.log("braintree, paypal, renderSubmitButton, googlepay, authorizationToken, onError, onCreate", authorizationToken)

    // const renderSubmitButton = ({ onClick, isDisabled, text }) => {
    //     return (
    //         <button
    //             onClick={onClick}
    //             disabled={isDisabled}
    //         >{text}</button>
    //     )
    // }
  useEffect(()=>{
    //if(!(!braintree || dropInInstance)){
      setup()
    //}
  },[authorizationToken])

    const handleSubmit = async (event) => {
        //console.log("handleSubmit")
        // if(source === "ProfitLayers"){
        //   
        // }
        
        //console.log("dropInInstance", dropInInstance, isSubmitButtonDisabled)
        
        if (dropInInstance && !isSubmitButtonDisabled) {
          //console.log("inside if")
            setIsSubmitButtonDisabled(true)
            dropInInstance.requestPaymentMethod(async (err, payload) => {
              //console.log(err, payload)
                if (err) {
                    //console.log("err", err);
                    setIsSubmitButtonDisabled(false)
                } else {
                    setIsSubmitButtonDisabled(true)
                    setSubscriptionIsSubmitted(true)
                    //console.log("else")
                    await handlePaymentMethod(payload);
                    //setIsSubmitButtonDisabled(false)
                }
            });
        }
    }

  const setup = () => {
      //console.log("setup")
    const options={
      paypal: paypal,
      googlepay: googlepay,
      container: '.braintree-dropin-react-form',
      authorization: authorizationToken
    }

    braintree.create(options, (err, dropinInstance) => {
       // console.log("err, dropinInstance", options, err, dropinInstance)

        if(err){
          if(onError){
            onError()
          }
          return
        } else{
            if(onCreate){
              onCreate()
            }
        }
        if(dropinInstance.isPaymentMethodRequestable()){
          setIsSubmitButtonDisabled(false)
        }

        dropinInstance.on('paymentMethodRequestable', (e)=>{
          if(subscriptionIsSubmitted === false){
            setIsSubmitButtonDisabled(false)
          }
        })

        dropinInstance.on('noPaymentMehodRequestable', () => {
          setIsSubmitButtonDisabled(true)
        })

       // console.log("dropinInstance", dropinInstance)
        setDropInInstance(dropinInstance)
    })
  }

    return (
        <div className='braintree-dropin-react'>
        <div className='braintree-dropin-react-form' />
        <div className='braintree-dropin-react-submit-btn-wrapper'>
            {renderSubmitButton({
                onClick: handleSubmit,
                isDisabled: isSubmitButtonDisabled,
                text: 'test'
            })}
        </div>
    </div>
    )
}

import React, { useState, useEffect, Fragment } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  Box,
  Typography,
  Button,
  Divider,
  TextField,
  Chip,
  Checkbox,
  Tooltip,
} from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineOutlinedIcon from "@material-ui/icons/DeleteOutlineOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import * as con from "../../utils/conversions";
import { Endpoints } from "../../../../constants/Endpoints";
import { getVarietyNames } from "../../../../utils/dataFetchers";
import { numFormat } from '../../../../utils/helpers'

const theme = createMuiTheme({
  overrides: {
      // Style sheet name ??
      MuiButton: {
          // Name of the rule
          text: {
              // Some CSS
              background: "red",
              height: 5,
              width: 15,
              marginLeft: "10px",
          },
      },
  },
});

const useStyles = makeStyles((theme) => ({
  icon: {
    color: theme.palette.greys.dark,
    "&:hover": {
      cursor: "pointer",
    },
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
  },
  greenIcon: {
    color: theme.palette.primary.main,
    "&:hover": {
      cursor: "pointer",
    },
    fontWeight: 500,
    display: "flex",
    alignItems: "center",
    margin: "0 10px",
  },
  input: {
    ...theme.plInput,
    width: '212px',
  },
  disabledInput: {
    ...theme.disabledInput,
    width: '212px',
  },
  infoToolTip: theme.infoToolTip,
}));

export function Zone({
  allClickedFeatures,
  clickedFeature,
  zone,
  index,
  type,
  displayZones,
  handleZoneChange,
  handleUpdate,
  remove,
  manualEntry,
  unit
}) {
  /**
   * Enter data for harvest and seeding zones.
   * @param {Object} zone Zone to add data for.
   * @param {Number} index Index of zone.
   * @param {String} type Harvest or seeding.
   * @param {Object} field Field data.
   * @param {Array} displayZones Display data for zones in array.
   * @param {Function} handleZoneChange Add or remove zone from displayZones.
   * @param {String} unit
   */

  const classes = useStyles();
  const [display, setDisplay] = useState(false);

  const [organic, setOrganic] = useState(false);
  const [irrigated, setIrrigated] = useState(false);

  const [allVarieties, setAllVarieties] = useState([]);
  const [varietyInputValue, setVarietyInputValue] = useState("");

  useEffect(() => {
    let show = displayZones.includes(index) ? true : false;
    setDisplay(show);
  }, [displayZones]);

  const displayZoneName = (zone, i) => {
    let zoneName = ''
    if ('soilType' in zone) {
      // Display soil type as zone name for SSURGO
      zoneName =  zone.soilType
    } else {
      zoneName = `Zone ${i + 1}`
    }

    return `${zoneName} - ${numFormat(zone.area)} Acres`
  }

  return (
    <Box my={1}>
      <Box display="flex" justifyContent="space-between">
        <Box display="flex" style={{cursor: "pointer",  userSelect: "none"}}
           onClick = { () => {handleZoneChange(index); }}>
          {display ? (
            <ExpandLessIcon
              className={classes.icon}
              //onClick={() => handleZoneChange(index)}
            />
          ) : (
            <ExpandMoreIcon
              className={classes.icon}
              //onClick={() => handleZoneChange(index)}
            />
          )}
          { displayZoneName(zone, index) }
          {zone.color != undefined && (
            <ThemeProvider theme={theme}>
                <Button disabled style={{ background: zone.color }}></Button>
            </ThemeProvider>
          )}
        </Box>

        <Box display="flex">
          {manualEntry && (
            <Box className={classes.greenIcon}>
              <DeleteOutlineOutlinedIcon
                fontSize="small"
                onClick={() => remove(index)}
              />
            </Box>
          )}
        </Box>
      </Box>

      {display && (
          unit.toUpperCase() === "ACRE" ?
          <Fragment>
              <Box mt={1} display="flex">
                <Fragment>
                  <Box>
                    <Box display='flex' alignItems='center'>
                      <Box>
                        {type === "Revenue" ? 'Revenue ($/Acre)' : 'Cost ($/Acre)'}
                      </Box>
                      <Tooltip
                        title='See an incorrect price? Update it for your entire organization in the Price Converter by clicking the info icon.'
                        placement='right'
                      >
                        <a href={`${Endpoints.HOME}/app/priceconverter`} target='_blank'>
                          <InfoOutlinedIcon className={classes.infoToolTip}/>
                        </a>
                      </Tooltip>
                    </Box>
                    <Box className={classes.disabledInput}>
                      {(zone.rate * zone.price).toFixed(2)}
                    </Box>
                  </Box>
                  <Box>
                    {type === "Revenue" ? 'Total Revenue' : 'Total Cost'}
                    <Box className={classes.disabledInput}>
                      {(zone.rate * zone.price * zone.area).toFixed(2)}
                    </Box>
                  </Box>
                </Fragment>
              </Box>
            </Fragment>

          : unit.toUpperCase() === "INCHES" ?
          <Fragment>
            <Box mt={1} display="flex">
              <Fragment>
                <Box>
                  <Box>Tillage Depth (inches)</Box>
                  {manualEntry ?
                  <TextField
                    className={classes.input}
                    variant="outlined"
                    type="number"
                    value={!isNaN(zone.rate) ? zone.rate : ""}
                    onChange={(e) =>
                      handleUpdate(index, parseFloat(e.target.value), "quantity")
                    }
                  />
                  :
                  <Box className={classes.disabledInput}>{zone.rate}</Box>
                  }

                </Box>
              </Fragment>
            </Box>
          </Fragment>
          :
          <Fragment>
          <Box mt={1} display="flex">
            <Fragment>
              <Box>
                Quantity / Acre
                <TextField
                  className={classes.input}
                  variant="outlined"
                  type="number"
                  value={!isNaN(zone.rate) ? zone.rate : ""}
                  onChange={(e) =>
                    handleUpdate(index, parseFloat(e.target.value), "quantity")
                  }
                />
              </Box>
              <Box>
                Total Quantity
                <Box className={classes.disabledInput}>
                {Number(zone.total).toFixed(2)}
                </Box>
              </Box>
            </Fragment>
          </Box>
          <Box mt={1} display="flex">
            <Fragment>
              <Box>
                {type === "Revenue" ? 'Revenue / Acre' : 'Cost / Acre'}
                <Box className={classes.disabledInput}>
                  {(zone.rate * zone.price).toFixed(2)}
                </Box>
              </Box>
              <Box>
                {type === "Revenue" ? 'Total Revenue' : 'Total Cost'}
                <Box className={classes.disabledInput}>
                  {(zone.rate * zone.price * zone.area).toFixed(2)}
                </Box>
              </Box>
            </Fragment>
          </Box>
        </Fragment>
      )}
    </Box>
  );
}

import React, { useState } from "react";
import { Button, ButtonGroup, makeStyles, Switch } from "@material-ui/core";
import { COLORS } from "../../../constants";

const useStyles = makeStyles(() => ({
  wrapper: ({ mobileView }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: mobileView ? "flex-start" : "flex-end",
    width: "100%",
    borderBottom: `1px solid ${COLORS.lightGray}`,
  }),
  categoryGroup: {
    overflowX: "auto",
    "& .MuiButtonGroup-root": {
      overflowX: "auto",
    },
    "& .MuiButton-root": {
      color: COLORS.text,
      fontSize: "13px",
      backgroundColor: COLORS.veryLightGrey,
      border: `1px solid ${COLORS.lightGray}`,
      textTransform: "none",
      marginTop: 5,
      "&.active": {
        backgroundColor: COLORS.white,
        color: COLORS.green,
        borderBottom: `3px solid ${COLORS.green}`,
      },
    },
  },
}));

const ComparisonButtonGroup = ({
  categories,
  setCategories,
  valueAccessor = (d) => d.name,
  mobileView,
  checked,
  setChecked,
  nutrientRate,
  setNutrientRate,
  reportType
}) => {
  const classes = useStyles({ mobileView });
  const selectedCategory = categories.find((category) => category.active);
  const handleChange = (value) => () => {
    setCategories((prevState) => {
      return prevState.map((d) => ({
        ...d,
        active: valueAccessor(d) === value,
      }));
    });
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  const handleChemChange = (e) => {
    setNutrientRate(e.target.checked)
  }

  return (
    <div className={classes.wrapper}>
      <div className={classes.categoryGroup}>
        <ButtonGroup aria-label="comparison category button group">
          {categories.map((category) => {
            return (
              category.name !== "Chemical Rate" && (
                <Button
                  className={
                    valueAccessor(selectedCategory) === valueAccessor(category)
                      ? "active"
                      : ""
                  }
                  key={`category-button-for-${valueAccessor(category)}`}
                  onClick={handleChange(valueAccessor(category))}
                >
                  {valueAccessor(category)}
                </Button>
              )
            );
          })}
        </ButtonGroup>
      </div>
      {/* {reportType == "farm" && (categories.filter((x) => x.active)[0].name === "Chemicals" ||
        categories.filter((x) => x.active)[0].name === "Chemical Rate") ? (
          <div>
            <div>
              Application Operations
              <Switch
                checked={checked}
                onChange={handleSwitchChange}
                name="checkedA"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              Chemical {'&'} Nutrient Rates
            </div>

            {
              checked && <div>
              Chemical Rate
                <Switch
                checked={nutrientRate}
                onChange={handleChemChange}
                name="checkedA"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
              Nutrient Rate
            </div>
            }
            
          </div>

        ) : (
          <div></div>
        )} */}
    </div>
  );
};

export default ComparisonButtonGroup;

import React from "react";
import { scaleLinear } from "d3-scale";
import Axes from "../../../Helpers/BaseChart/Axes";
import FieldMarker from "../FieldMarker";
import { COLORS } from "../../../../constants";
import { makeStyles } from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
  line: {
    fill: "none",
    strokeDasharray: "2,2",
  },
}));

const ScatterComparePlot = ({
  innerWidth,
  innerHeight,
  setState,
  points,
  radius,
  activeMarker,
  isDisaggregated,
  firstVariableOption = {},
  secondVariableOption = {},
  xAccessor = (d) => d.x,
  yAccessor = (d) => d.y,
  nameAccessor = (d) => d.name,
}) => {
  const classes = useStyles();

  // Break apart the points to show county and farm
  let minX = Infinity;
  let maxX = 0;
  let minY = Infinity;
  let maxY = 0;
  const items = points.map((point) => {
    const county = {
      ...point.county,
      color: !isDisaggregated ? COLORS.gray : point.color,
      type: "county",
    };
    if (Math.min(xAccessor(county), xAccessor(point)) < minX)
      minX = Math.min(xAccessor(county), xAccessor(point));
    if (Math.max(xAccessor(county), xAccessor(point)) > maxX)
      maxX = Math.max(xAccessor(county), xAccessor(point));
    if (Math.min(yAccessor(county), yAccessor(point)) < minY)
      minY = Math.min(yAccessor(county), yAccessor(point));
    if (Math.max(yAccessor(county), yAccessor(point)) > maxY)
      maxY = Math.max(yAccessor(county), yAccessor(point));
    return [point, county];
  });

  const xScale = scaleLinear()
    .range([0, innerWidth])
    .domain([minX, maxX])
    .nice();
  const yScale = scaleLinear().range([innerHeight, 0]).domain([minY, maxY]);

  return (
    <g
      className="scatter__compare__plot"
      onMouseOut={() =>
        setState((prevState) => ({ ...prevState, activeMarker: null }))
      }
    >
      <Axes
        height={innerHeight}
        width={innerWidth}
        xScale={xScale}
        yScale={yScale}
        showXAxisLabel={true}
        xAxisLabel={firstVariableOption.label}
        showYAxisLabel={true}
        yAxisLabel={secondVariableOption.label}
      />
      {items.map((point) => {
        const x1 = xScale(xAccessor(point[0]));
        const x2 = xScale(xAccessor(point[1]));
        const y1 = yScale(yAccessor(point[0]));
        const y2 = yScale(yAccessor(point[1]));
        return (
          <g
            className="farm__and_county_markers"
            key={`farm-marker-for-${point[0].name}-${point[0].variety}`}
          >
            <line
              className={classes.line}
              x1={x1}
              x2={x2}
              y1={y1}
              y2={y2}
              style={{
                stroke: !isDisaggregated ? COLORS.gray : point[0].color,
              }}
            />
            <FieldMarker
              marker={{
                ...point[0],
                xPosition: x1,
                yPosition: y1,
                isActive:
                  !activeMarker ||
                  nameAccessor(activeMarker) === nameAccessor(point[0]),
                label: !isDisaggregated && nameAccessor(point[0]),
                color: !isDisaggregated ? COLORS.green : point[0].color,
                labelColor: COLORS.green,
              }}
              isFarm={true}
              radius={radius}
              setState={setState}
            />
            <FieldMarker
              marker={{
                ...point[1],
                xPosition: x2,
                yPosition: y2,
                isActive:
                  !activeMarker ||
                  nameAccessor(activeMarker) === nameAccessor(point[1]),
                label: !isDisaggregated && nameAccessor(point[1]),
                labelColor: COLORS.text,
              }}
              isFarm={false}
              radius={radius}
              setState={setState}
            />
          </g>
        );
      })}
    </g>
  );
};

export default ScatterComparePlot;

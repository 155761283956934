import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider, Typography } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { numFormat, dollarFormat } from '../../../../utils/helpers';
import { green, blackText } from '../../../../styles/colors'
import { unitConversion } from '../../utils/conversions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  table: {
    margin: 4,
    fontWeight: 500,
    color: theme.palette.text.black,
    minWidth: 400,
    display: 'flex',
    flexDirection: 'column',
  },
  labels: {
    display: "flex",
    padding: "4px 8px",
    alignItems: "center",
    fontWeight: 500,
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  tableRow: {
    display: "flex",
    flexGrow: 1,
    padding: "8px",
    fontWeight: 500,
    color: theme.palette.text.black,
  },
}));

export function MobileVarietySummary({
  legend,
  data,
  perAcre,
  netSeededAcres,
  selectedVariety,
}) {
  const classes = useStyles();

  const [isSummary, setIsSummary] = useState(true)
  const [mean, setMean] = useState(0)

  useEffect(() => {
    console.log('data', data)
    //const test = data.filter((x) => x.variety === selectedVariety.name)[0]
  }, [data])

  useEffect(() =>  {
    console.log(selectedVariety)
    const selected = data.filter(x => x.variety === selectedVariety.name)
    console.log(selected)
    if (selected !== undefined && selected.length > 0) {
      setMean(dollarFormat(selected[0].mean))
    }
  }, [selectedVariety, data])

  useEffect(() => {
    console.log(legend)
    if (legend !== undefined && legend.length  > 0) {
      // If variety is listed, display summary of all varieties
      setIsSummary(legend[0].variety !== null)
    }
  }, [legend])

  const varietySummaryLegend = () => {
    return (
      <Box style={{width: '100%'}}>
        <Box
          display='flex'
          flexGrow={1}
          color={'#ffffff'}
          style={{padding: 4, backgroundColor: green}}
        >
          <Box style={{width: '10%'}}>

          </Box>
          <Box style={{width: '50%'}}>
            Variety
          </Box>
          <Box style={{width: '20%', whiteSpace: 'nowrap'}}>
            {'Area (%)'}
          </Box>
          <Box style={{width: '20%', whiteSpace: 'nowrap'}}>
            {perAcre ? '$/Acre' : '$'}
          </Box>
        </Box>

        <Divider />
        {
          legend.map((x, i) => summaryLegendRow(x, i))
        }
      </Box>
    )
  }

  const summaryLegendRow = (x, i) => {
    const displayingVariety = data.filter(y => y.variety === x.variety)
    const mean = displayingVariety.length > 0 ? displayingVariety[0].mean : 0
    return (
      <Box
        key={i}
        display='flex'
        alignItems='center'
        style={{padding: 4}}
      >
        <Box style={{width: '10%'}}>
          <FiberManualRecordIcon
            style={{color: x.color}}
          />
        </Box>
        <Box style={{width: '50%'}}>
          <Box mx={1}>
            {x.variety}
          </Box>
        </Box>
        <Box style={{width: '20%'}}>
          {x.area.replace(' ', '')}
        </Box>
        <Box style={{width: '20%'}}>
          {dollarFormat(mean)}
        </Box>

      </Box>
    )
  }

  const varietyLegend = () => {
    return (
      <Box>
        {
          legend.map((x, i) => varietyLegendRow(x, i))
        }
      </Box>
    )
  }

  const varietyLegendRow = (x, i) => {
    return (
      <Box
        key={i}
        display='flex'
        alignItems='center'
        style={{padding: 4}}
      >
        <FiberManualRecordIcon
          style={{color: x.color}}
        />
        <Box mx={1}>
          {dollarFormat(x.min)}
          {perAcre ? '/ac ' : ' '}
          {' to  '}
          {dollarFormat(x.max)}
          {perAcre ? '/ac ' : ' '}
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box
        className={classes.table}
        border={1}
        borderColor={green}
        borderRadius='borderRadius'
      >

        {
          !isSummary && (
            <Box style={{backgroundColor: green}}>
              <Typography
                align='center'
                style={{fontWeight: 500, fontSize: 16, color:'#ffffff'}}
              >
                {selectedVariety.name}
              </Typography>
              <Typography
                align='center'
                style={{fontWeight: 500, fontSize: 14, color:'#ffffff'}}
              >
                {'Average: '}
                {mean}
                {perAcre && ' /ac'}
              </Typography>
            </Box>
          )
        }

        <Box>
          {
            isSummary ? (
              varietySummaryLegend()
            ) : (
              varietyLegend()
            )
          }
        </Box>


      </Box>
    </Box>

  )
}

/* eslint-disable no-restricted-syntax */
/* eslint-disable valid-jsdoc */
import * as turf from '@turf/turf';
import * as L from 'leaflet';
import { cloneDeep } from 'lodash';
import { getStateAndCounty } from '../../../utils/dataFetchers';
import { getCircularReplacer } from '../../../utils/helpers';
import { createGeoFromBoundary } from './helpers';
import { fieldToolTip } from '../Styles/tooltips';

/**
 * Draw field boundaries on map and add appropriate functions to thier layer.
 * Convert stored field boundary to geoJson.
 * If selected field, fit map to its bounds.
 * @param  {Object}  boundary Boundary to create feature collection for
 * @param  {Boolean} [snapTo=false] If selected field, snap to its bounds
 * @return {Object} Feature Collection
 */
export const createCollection = (
  boundary,
  color,
  weight,
  fieldLayer,
  addStyles,
  handleClick,
  setEditedBoundary,
  finishCut,
  toolTip,
  fillOpacity,
) => {
  try {
    const geo = createGeoFromBoundary(boundary);
    const newCollection = L.geoJson(geo, {
      onEachFeature: (feature, layer) => {
        layer.setStyle({
          color,
          weight,
          opacity: 1,
          fillColor: color,
          fillOpacity: fillOpacity || 0,
        });
        fieldLayer.addLayer(layer);

        if (addStyles) {
          fieldLayer.on('mouseover', (e) => {
            e.layer.setStyle({ color: '#6a1b9a', fillOpacity: 0.05 });
          });

          fieldLayer.on('mouseout', (e) => {
            e.layer.setStyle({ color: '#8e24aa', fillOpacity: 0 });
          });

          fieldLayer.on('click', (e) => {
            e.layer.setStyle({ color: '#561280', opacity: 0.99, fillOpacity: 0 });
          });
        }

        if (toolTip) {
          layer.bindTooltip(toolTip, { className: 'leaftletTooltipClass' });
        }

        if (setEditedBoundary) {
          layer.on('pm:update', (e) => {
            setEditedBoundary(e.layer);
          });
        }

        if (setEditedBoundary && finishCut) {
          layer.on('pm:cut', (e) => {
            finishCut(e.layer);
            setEditedBoundary(e.layer);
          });
        }

        if (handleClick) {
          layer.on({
            click: (e) => {
              // handleClick will actually be an object. This will allow us to
              // pass down values when createCollection is called by another
              // function in an iteration.
              handleClick.function(handleClick.value);
            },
          });
        }
      },
    });
    return newCollection;
  } catch (err) {
    console.error(err);
    return {};
  }
};

export const updateFieldAfterBoundaryEdit = (fieldToUpdate, editedBoundary) => {
  const updatedField = cloneDeep(fieldToUpdate);
  updatedField.points = editedBoundary.geometry.coordinates;
  updatedField.geometry = editedBoundary.geometry;
  updatedField.features = editedBoundary;
  updatedField.boundary = JSON.stringify(editedBoundary);
  updatedField.acres = turf.convertArea(turf.area(editedBoundary), 'meters', 'acres');
  const centroid = turf.centroid(editedBoundary);
  updatedField.latitude = centroid.geometry.coordinates[1];
  updatedField.longitude = centroid.geometry.coordinates[0];

  return updatedField;
};

/**
* Build a field from CLU or Polygon freature. Use id for editing with segment
* and grid tools. Get org and farm data from metaData if exists to use with
* field selection,
 * @param  {Object}  feature  Feature collection of drawn/selected field
 * @param  {Object}  data     FieldData used to set org and farm info
 * @return {Object}           Field from feature with full data.
 */
export const createField = async (feature, data) => {
  // get centroid, then state and county
  const polygon = turf.polygon(feature.geometry.coordinates);
  const centroid = turf.centroid(polygon);
  const latitude = centroid.geometry.coordinates[1];
  const longitude = centroid.geometry.coordinates[0];
  const [state, county] = await getStateAndCounty(latitude, longitude);
  const acres = turf.convertArea(turf.area(polygon), 'meters', 'acres');

  let boundary = {};

  // remove map ref to avoid cyclic
  if (feature.properties.mapRef !== undefined) {
    delete feature.properties.mapRef;
  }

  try {
    boundary = JSON.stringify(feature);
  } catch (e) {
    console.error(e);
    boundary = JSON.stringify(feature, getCircularReplacer());
  }

  // polygons wont have id so create a random
  const id = feature.id !== undefined ? feature.id : Math.random().toString(36).substring(7);

  const name = '';

  const newField = {
    acres,
    boundary,
    centroid,
    county,
    id,
    latitude,
    longitude,
    name,
    state,
    coordinates: feature.geometry,
    farm: data.farm,
    farmId: data.farmId,
    org: data.org,
    orgId: data.orgId,
    points: feature.geometry.coordinates,
    source: 'new',
  };

  return newField;
};

export const createMultiPolygonField = async (acres, feature, geometry, points) => {
  try {
    const boundary = JSON.stringify(feature);
    const centroid = turf.centroid(feature);
    const [longitude, latitude] = centroid.geometry.coordinates;
    const [state, county] = await getStateAndCounty(latitude, longitude);
    const { coordinates } = geometry;

    const field = {
      acres,
      boundary,
      centroid,
      coordinates,
      county,
      geometry,
      feature,
      latitude,
      longitude,
      points,
      state,
    };

    return field;
  } catch (e) {
    console.error(e);
  }
};

/**
 * When two polygons are combined, we need calculate new acres based on if
 * there is any intersection.
 * @param {Array} coordinates Current field coordinates
 * @param {Object} feature Feature to add
 * @param {Number} fieldAcres Current field acres
 * @param {String} type Polygon or multiPolygon
 * @return {Number} Acres for new polygon or multipolygon
 */
export const calculateFieldAcres = (coordinates, feature, fieldAcres, type) => {
  try {
    let currentAcres = fieldAcres;
    if (type === 'polygon') {
      const intersects = turf.intersect(coordinates, feature.geometry);
      if (intersects !== null) {
        const acres = turf.convertArea(turf.area(intersects), 'meters', 'acres');
        // Subtract intersecting area from current acres
        currentAcres -= acres;
        currentAcres += feature.properties.CALCACRES - acres;
      } else {
        // If not intersection is detected, add acres
        currentAcres += feature.properties.CALCACRES;
      }
    } else if (type === 'multipolygon') {
      let intersectingAcres = 0;
      for (const coordinate of coordinates) {
        const featureToCheck = { type: 'Polygon', coordinates: coordinate };
        const intersects = turf.intersect(featureToCheck, feature.geometry);
        if (intersects !== null) {
          const acres = turf.convertArea(turf.area(intersects), 'meters', 'acres');
          intersectingAcres += acres;
        }
      }
      const nonIntersectingAcres = feature.properties.CALCACRES - intersectingAcres;

      currentAcres -= intersectingAcres;
      currentAcres += nonIntersectingAcres;
    } else {
      console.error('invalid type given');
    }

    return currentAcres;
  } catch (err) {
    console.error(err);
    return 0;
  }
};

/**
 * Checks for overlapping boundaries new zone has with current zones. If overlap
 * is found, use turf difference to update new zone with only non-overlapping
 * points. We then also need to calculate new acres for transformed zone.
 * @param {Array} currentZones Zones already added to field
 * @param {Object} newZone Newly created zones
 * @return {Object} Zone with no overlapping points with any current zones
 */
export const checkZoneOverlap = (currentZones, newZone) => {
  // Clone so we can safely up date in loop
  let zoneToCheck = cloneDeep(newZone);

  for (const zone of currentZones) {
    // get zoneToCheck excluding the area of zone
    const difference = turf.difference(zoneToCheck, zone);
    if (difference !== null) {
      // Calculate acres of difference area
      const acres = turf.convertArea(
        turf.area(difference.geometry), 'meters', 'acres',
      );
      difference.properties.CALCACRES = acres;

      // Update zone to check with difference so we can see if any other zones
      // overlap
      zoneToCheck = difference;
    } else {
      // If zone is completely inside another zone, don't add it
      const contains = turf.booleanContains(zone, zoneToCheck);
      return null;
    }
  }
  return zoneToCheck;
};

export const checkZoneIntersectsWithField = (boundary, newZone) => {
  try {
    let fieldBoundary = boundary;
    if (boundary?.coordinates?.length > 1) {
      fieldBoundary = {
        ...boundary,
        coordinates: [boundary.coordinates],
      };
    }
    const inBounds = turf.intersect(fieldBoundary, newZone);
    return inBounds;
  } catch (err) {
    console.error(err);
  }
};

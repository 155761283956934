import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Box, Modal, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import HighlightOffIcon from "@material-ui/icons/HighlightOff";
import { useWindowDimensions } from "../../../utils/dimensions";
import { useSnackbar } from "notistack";
import { FieldReportMap } from "../../Maps/FieldReportMap";

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: "auto",
  },
  head: theme.updateOperationHeader,
  close: {
    ...theme.icon,
    marginTop: 12,
  },
  details: theme.plDetails,
  display: theme.disabledInput,
  select: {
    // height: 44.6,
    margin: 4,
    width: 200,
    backgroundColor: "#ffffff",
  },
  dateSelection: {
    margin: 4,
    "& .MuiInputBase-root": {
      padding: 4,
      "& .MuiButtonBase-root": {
        padding: 0,
        paddingLeft: 10,
      },
      "& .MuiInputBase-input": {
        padding: 10,
        paddingLeft: 0,
      },
    },
    width: 200,
    backgroundColor: "#ffffff",
  },
  footer: theme.updateOperationFooter,
  label: {
    color: theme.palette.greys.dark,
    fontWeight: 500,
    fontSize: "0.875rem",
  },
  legend: {
    overflowY: "auto",
    display: "flex",
    flexDirection: "column",
    padding: "12px",
  },
  legendRow: {
    display: "flex",
    alignItems: "center",
    padding: "8px",
  },
}));

function getModalStyle() {
  const top = 20;
  return {
    top: `${top}%`,
    margin: "auto",
    zIndex: 1002,
    width: "75%",
    height: "75%",
    padding: 4,
  };
}

/**
 * Modal for editing CLU intersection boundary
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Function} setOpen Sets if modal is open
 */
export const ProfitMapResponse = ({
  open,
  setOpen,
  mapImage,
  mapExtents,
  boundary,
  print,
  legend,
  selectedField,
}) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  const handleClose = () => {
    setOpen(false);
  };

  const mapLegend = () => (
    <Box className={classes.legend} style={{ height: "100%", width: "100%" }}>
      <Box display="flex">
        <Box style={{ width: "60%", paddingLeft: "40px" }}>
          Profit Range ($/acre)
        </Box>
        <Box style={{ width: "20%", paddingLeft: "20px" }}>Acres</Box>
        <Box style={{ width: "20%", paddingLeft: "10px" }}>% of total</Box>
      </Box>

      {legend.map((row, i) => (
        <Box key={i} className={classes.legendRow}>
          <Box display="flex" alignItems="center" style={{ width: "60%" }}>
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              borderRadius="50%"
              style={{
                width: "20px",
                height: "20px",
                overflow: "hidden",
                backgroundColor: row.color,
              }}
            ></Box>

            <Box style={{ marginLeft: "10px" }}>
              ${row.min.toFixed(2)} to ${row.max.toFixed(2)}
            </Box>
          </Box>

          <Box style={{ width: "20%", paddingLeft: "20px" }}>
            {(selectedField.acres / parseFloat(row.area.split(" ")[0])).toFixed(
              2
            )}
          </Box>

          <Box style={{ width: "20%", paddingLeft: "10px" }}>
            {row.area.split(" ")[0]}
            {"%"}
          </Box>
        </Box>
      ))}
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        height: "75%",
        width: "75%",
        margin: "auto",
      }}
      aria-labelledby="change-explanation"
      aria-describedby="explain-reason-data-was-changed"
    >
      <Box
        className={classes.paper}
        style={{ height: "100%", width: "100%" }}
        boxShadow={2}
      >
        <Box display="flex" justifyContent="flex-end" style={{ width: "100%" }}>
          <HighlightOffIcon
            className={classes.close}
            onClick={() => {
              setOpen(false);
            }}
          />
        </Box>

        <Box p={1} fontSize={16}>
          {`Detailed Profit Layers`}
        </Box>

        <Box display="flex" flexDirection="row">
          <Box m={1}>
            <FieldReportMap
              mapImage={mapImage}
              mapExtents={mapExtents}
              boundary={boundary}
              print={print}
              id="expand"
              width="750px"
            />
          </Box>
          <Box
            m={1}
            style={{ position: "absolute", left: "50%", width: "45%" }}
          >
            {mapLegend()}
          </Box>
        </Box>
      </Box>
    </Modal>
  );
};

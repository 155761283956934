/* eslint-disable require-jsdoc */
/* eslint-disable react/prop-types */
import React from 'react';
import { Box } from '@material-ui/core';
import { ZoneSelectionMap } from '../Maps/ZoneSelectionMap';

export function ZoneSelection({
  bodyHeight,
  selectField,
  selectedField,
  goToSelectPackage,
  goToFieldSelection,
  toolTip,
  autoGenerateGrids,
}) {
  return (
    <Box display="flex" height="100%">
      <ZoneSelectionMap
        height={bodyHeight}
        selectField={selectField}
        selectedField={selectedField}
        goToSelectPackage={goToSelectPackage}
        goToFieldSelection={goToFieldSelection}
        toolTip={toolTip}
        autoGenerateGrids={autoGenerateGrids}
      />
    </Box>
  );
}

import React, { useState, useEffect } from 'react';
import {
  Box,
  TextField,
  Button,
  Typography,
  Divider,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { blackText } from '../../../../styles/colors';
import { useWindowDimensions } from "../../../../utils/dimensions";
import { useSnackbar } from "notistack";
import {CreateTable} from './CreateTable'
import { NumberFormatCustom } from '../../../../utils/NumberFormatCustom';

const useStyles = makeStyles((theme) => ({
  input: {
    ...theme.plInput,
  },
}));

export function LandlordTenant({
  operations,
  year,
  totalRevenue,
  totalCosts,
  totalProfit,
  netSeededAcres,
  combineOps,
  perAcre,
}) {

  //console.log('operations', operations, year)
  const { height, width } = useWindowDimensions();
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [tenantShare, setTenantShare] = useState(100)
  const [landlordShare, setLandlordShare] = useState(0)
  const [applyChanges, setApplyChanges] = useState(true)

  const [harvestOps, setHarvestOps] = useState([])
  const [seedingOps, setSeedingOps] = useState([])
  const [applicationOps, setApplicationOps] = useState([])
  const [fieldpassOps, setFieldpassOps] = useState([])
  const [otherCostOps, setOtherCostOps] = useState([])
  const [otherRevOps, setOtherRevOps] = useState([])

  const [landlordCost, setLandlordCost] = useState(0)
  const [landlordRev, setLandlordRev] = useState(0)
  const [landlordProfit, setLandlordProfit] = useState(0)

  const [tenantCost, settenantCost] = useState(0)
  const [tenantRev, settenantRev] = useState(0)
  const [tenantProfit, settenantProfit] = useState(0)

  useEffect(() => {
    let harvest = [], seeding = [], application = [], fieldPass = [], otherCost = [], otherRev = []

    if(applyChanges){
      //console.log('operations', operations)
      //console.log('displayed', displayed)
      for(let op of operations){
        op.tenantShare=tenantShare
        op.landlordShare=100-tenantShare
        if(op.fileType.toUpperCase() == "HARVEST"){
          harvest.push(op)
        }
        else if(op.fileType.toUpperCase() == "SEEDING"){
          op.operationCost = op.operationCost < 0 ? op.operationCost*-1 : op.operationCost
          seeding.push(op)
        }
        else if(op.fileType.toUpperCase() == "APPLICATION"){
          application.push(op)
        }
        else if(op.fileType.toUpperCase() == "TILLAGE"){
          fieldPass.push(op)
        }
        else if(op.fileType.toUpperCase() === "GENERIC" && op.aux1.toUpperCase() == "FIELD PASS"){
          fieldPass.push(op)
        }
        else if(op.fileType.toUpperCase() === "GENERIC" && (op.aux1.toUpperCase() == "COST")){
          otherCost.push(op)
        }
        else if(op.fileType.toUpperCase() === "GENERIC" && (op.aux1.toUpperCase() == "REVENUE")){
          otherRev.push(op)
        }
      }

      let combinedSeeding = combineOps(seeding)
      let combinedHarvest = combineOps(harvest)
      //console.log('before combinedSeeding', combinedSeeding)

      for(let operation of combinedSeeding){
        let ops = operation.varieties
        const acres = ops.map((x) => x.area).reduce((a, b) => (a + b), 0);
        let quantity = 0;
        let price = 0;
        let costPerAcre = 0;

        for (const op of ops) {
          const percent = op.area / acres;
          quantity += (op.quantity / op.area) * percent;
          const opPrice = op.price * percent;
          const opCostPerAcre = (op.price * (op.quantity / op.area)) * percent;
          if (!(Number.isNaN(opPrice))) {
            price += opPrice;
          }
          if (!Number.isNaN(opCostPerAcre)) {
            costPerAcre += opCostPerAcre;
          }
        }
        operation.area = acres
        operation.price = price
        operation.costPerAcre = costPerAcre
        operation.rate = quantity
        operation.unit = ops[0].unit
        operation.productName = operation.name
        operation.cropSeason = ops[0].cropSeason
      }

      for(let operation of combinedHarvest){
        let ops = operation.varieties
        const acres = ops.map((x) => x.area).reduce((a, b) => (a + b), 0);
        let quantity = 0;
        let price = 0;
        let costPerAcre = 0;

        for (const op of ops) {
          const percent = op.area / acres;
          quantity += (op.quantity / op.area) * percent;
          const opPrice = op.price * percent;
          const opCostPerAcre = (op.price * (op.quantity / op.area)) * percent;
          if (!(Number.isNaN(opPrice))) {
            price += opPrice;
          }
          if (!Number.isNaN(opCostPerAcre)) {
            costPerAcre += opCostPerAcre;
          }
        }
        operation.area = acres
        operation.price = price
        operation.costPerAcre = costPerAcre
        operation.rate = quantity
        operation.unit = ops[0].unit
        operation.productName = operation.name
        operation.cropSeason = ops[0].cropSeason
      }

      //console.log('after combinedSeeding', combinedSeeding)
      harvest.sort((a,b) => b.cropSeason - a.cropSeason)
      seeding.sort((a,b) => b.cropSeason - a.cropSeason)
      application.sort((a,b) => b.cropSeason - a.cropSeason)
      fieldPass.sort((a,b) => b.cropSeason - a.cropSeason)
      otherCost.sort((a,b) => b.cropSeason - a.cropSeason)
      otherRev.sort((a,b) => b.cropSeason - a.cropSeason)

      // harvest.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      // seeding.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      // application.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      // fieldPass.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      // otherCost.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      // otherRev.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)

      setHarvestOps(harvest)
      setSeedingOps(seeding)
      setApplicationOps(application)
      setFieldpassOps(fieldPass)
      setOtherCostOps(otherCost)
      setOtherRevOps(otherRev)
    }
    setApplyChanges(false)
  }, [operations, applyChanges])

  const updateValues = () => {
    //console.log('updateValues')
    setApplyChanges(true)
  }

  const handleTenantShare = (val) => {
    setTenantShare(val)
    setLandlordShare(100-val)
  }

  const handleLandlordShare = (val) => {
    setTenantShare(100-val)
    setLandlordShare(val)
  }

  const handleTenantLandlordShare = (opId, val, type, attribute) => {

    if(type == 'harvest'){
      let opM = harvestOps.filter(opn => opn.operationID == opId)
      let opNM = harvestOps.filter(opn => opn.operationID !== opId)
      if(attribute == 'landlord'){
        opM[0].landlordShare = val
        opM[0].tenantShare = 100 - val
      }
      else if(attribute == 'tenant'){
        opM[0].tenantShare = val
        opM[0].landlordShare = 100 - val
      }
      let harvest = [...opM, ...opNM]
      harvest.sort((a,b) => b.cropSeason - a.cropSeason)
      //harvest.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      setHarvestOps(harvest)
    }

    if(type == 'seeding'){
      let opM = seedingOps.filter(opn => opn.operationID == opId)
      let opNM = seedingOps.filter(opn => opn.operationID !== opId)
      if(attribute == 'landlord'){
        opM[0].landlordShare = val
        opM[0].tenantShare = 100 - val
      }
      else if(attribute == 'tenant'){
        opM[0].tenantShare = val
        opM[0].landlordShare = 100 - val
      }
      let seeding = [...opM, ...opNM]
      seeding.sort((a,b) => b.cropSeason - a.cropSeason)
      //seeding.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      setSeedingOps(seeding)
    }

    if(type == 'application'){
      let opM = applicationOps.filter(opn => opn.operationID == opId)
      let opNM = applicationOps.filter(opn => opn.operationID !== opId)
      if(attribute == 'landlord'){
        opM[0].landlordShare = val
        opM[0].tenantShare = 100 - val
      }
      else if(attribute == 'tenant'){
        opM[0].tenantShare = val
        opM[0].landlordShare = 100 - val
      }
      let application = [...opM, ...opNM]
      application.sort((a,b) => b.cropSeason - a.cropSeason)
      //application.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      setApplicationOps(application)
    }

    if(type == 'fieldpass'){
      let opM = fieldpassOps.filter(opn => opn.operationID == opId)
      let opNM = fieldpassOps.filter(opn => opn.operationID !== opId)
      if(attribute == 'landlord'){
        opM[0].landlordShare = val
        opM[0].tenantShare = 100 - val
      }
      else if(attribute == 'tenant'){
        opM[0].tenantShare = val
        opM[0].landlordShare = 100 - val
      }
      let fieldpass = [...opM, ...opNM]
      fieldpass.sort((a,b) => b.cropSeason - a.cropSeason)
      //fieldpass.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      setFieldpassOps(fieldpass)
    }

    if(type == 'costs'){
      let opM = otherCostOps.filter(opn => opn.operationID == opId)
      let opNM = otherCostOps.filter(opn => opn.operationID !== opId)
      if(attribute == 'landlord'){
        opM[0].landlordShare = val
        opM[0].tenantShare = 100 - val
      }
      else if(attribute == 'tenant'){
        opM[0].tenantShare = val
        opM[0].landlordShare = 100 - val
      }
      let othercost = [...opM, ...opNM]
      othercost.sort((a,b) => b.cropSeason - a.cropSeason)
      //othercost.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      setOtherCostOps(othercost)
    }

    if(type == 'revenue'){
      let opM = otherRevOps.filter(opn => opn.operationID == opId)
      let opNM = otherRevOps.filter(opn => opn.operationID !== opId)
      if(attribute == 'landlord'){
        opM[0].landlordShare = val
        opM[0].tenantShare = 100 - val
      }
      else if(attribute == 'tenant'){
        opM[0].tenantShare = val
        opM[0].landlordShare = 100 - val
      }
      let otherrev = [...opM, ...opNM]
      otherrev.sort((a,b) => b.cropSeason - a.cropSeason)
      //otherrev.sort((a,b) => a.productName === b.productName ? 0 : a.productName < b.productName ? -1 : 1)
      setOtherRevOps(otherrev)
    }
  }

  useEffect(() => {
    let llrev = 0, llcosts = 0, llprofit = 0;
    let trev = 0, tcosts = 0, tprofit = 0;

    for(let op of harvestOps) {
      llrev += (op.price * op.rate * op.area * (op.landlordShare/100) )/netSeededAcres
      trev += (op.price * op.rate * op.area * (op.tenantShare/100) )/netSeededAcres
    }

    //console.log('llrev, trev', llrev, trev)

    for(let op of seedingOps) {
      llcosts += (op.price * op.rate * op.area * (op.landlordShare/100) )/netSeededAcres
      tcosts += (op.price * op.rate * op.area * (op.tenantShare/100) )/netSeededAcres
    }

    //console.log('llcosts, tcosts', llcosts, tcosts)

    for(let op of applicationOps) {
      llcosts += (op.price * op.averageMaterialResult * op.area * (op.landlordShare/100) )/netSeededAcres
      tcosts += (op.price * op.averageMaterialResult * op.area * (op.tenantShare/100) )/netSeededAcres
    }

    //console.log('llcosts, tcosts', llcosts, tcosts)

    for(let op of fieldpassOps) {
      llcosts += (op.price * op.area * (op.landlordShare/100)) / netSeededAcres
      tcosts += (op.price * op.area * (op.tenantShare/100)) / netSeededAcres
    }

    //console.log('llcosts, tcosts', llcosts, tcosts)

    for(let op of otherCostOps) {
      llcosts += (op.price * op.area * (op.landlordShare/100)) / netSeededAcres
      tcosts += (op.price * op.area * (op.tenantShare/100)) / netSeededAcres
    }

    for(let op of otherRevOps) {
      llrev += (op.price * op.area * (op.landlordShare/100)) / netSeededAcres
      trev += (op.price * op.area * (op.tenantShare/100)) / netSeededAcres
    }

    llprofit = llrev - llcosts
    tprofit = trev - tcosts

    //console.log('llprofit, tprofit', llprofit, tprofit)

    setLandlordCost(Math.round(llcosts*100)/100)
    setLandlordRev(Math.round(llrev*100)/100)
    setLandlordProfit(Math.round(llprofit*100)/100)

    settenantCost(Math.round(tcosts*100)/100)
    settenantRev(Math.round(trev*100)/100)
    settenantProfit(Math.round(tprofit*100)/100)
  }, [harvestOps, seedingOps, applicationOps, fieldpassOps, otherRevOps, otherCostOps])

  return (
    <Box
      p={1}
      fontWeight={500}
      color={blackText}
      style={{
        width: '100%',
        minWidth: '500px',
        height: '95%',
        minHeight: '500px',
        overflow: 'auto',
      }}
    >
      <Box p={1} maxWidth={845}>
        <Typography variant='h6'>
          Landlord - Tenant Split Post Analysis
        </Typography>
        <Typography>
          Users who split or share costs with their landlord can quickly calculate the actual cost each pays for all field operations. Split costs across your entire operation with the percentage boxes at the top of the page, or split the costs of individual operations based on your needs. When you're done, print out a PDF to share with your landlord.
        </Typography>
      </Box>

      <Divider />
      
      <Box p={1} display='flex' flexWrap='wrap' alignItems='center'>
        <Box mx={1}>
          <Box>
            Landlord Share (in %)
          </Box>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={landlordShare}
            onChange={(e)=>handleLandlordShare(e.target.value) }
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: '%',
            }}
          />
        </Box>

        <Box mx={1}>
          <Box>
            Tenant Share (in %)
          </Box>
          <TextField
            id="outlined-basic"
            variant="outlined"
            value={tenantShare}
            onChange={(e)=>handleTenantShare(e.target.value) }
            InputProps={{
              inputComponent: NumberFormatCustom,
              endAdornment: '%',
            }}
          />
        </Box>

        <Box mx={1}>
          <Button
            variant="outlined"
            color="primary"
            onClick={updateValues}
          >
            Apply Changes To All Operations
          </Button>
        </Box>

      </Box>

      <CreateTable
        operations={operations}
        tenantShare={tenantShare}
        setTenantShare={setTenantShare}
        landlordShare={landlordShare}
        setLandlordShare={setLandlordShare}
        applyChanges={applyChanges}
        setApplyChanges={setApplyChanges}
        totalRevenue={totalRevenue}
        totalCosts={totalCosts}
        totalProfit={totalProfit}
        netSeededAcres={netSeededAcres}
        combineOps={combineOps}
        harvestOps={harvestOps}
        seedingOps={seedingOps}
        applicationOps={applicationOps}
        fieldpassOps={fieldpassOps}
        otherCostOps={otherCostOps}
        otherRevOps={otherRevOps}
        handleTenantLandlordShare={handleTenantLandlordShare}
        landlordRev={landlordRev}
        landlordProfit={landlordProfit}
        tenantCost={tenantCost}
        tenantRev={tenantRev}
        tenantProfit={tenantProfit}
        landlordCost={landlordCost}
        perAcre={perAcre}
      />
    </Box>
  );
}

import React, { useState, useEffect, useRef } from 'react';
import braintree from 'braintree-web-drop-in';
import { BrainTreeDropIn } from '../ProfitLayers/Reports/BrainTreeDropInReact';
import { Endpoints } from '../../constants/Endpoints';
import {
	Box, Button, Typography, Tabs, Tab, Divider, CircularProgress,
} from '@material-ui/core';
import { SpinningLoader } from '../Shared/SpinningLoader'
import { exists, validEmail } from '../../utils/helpers';
import {
	customerExists, 
	anymousCustomerExists,
	getOrCreateAnonymousCustomer,
	getOrCreateCustomer,
	generatePaymentNonce,
	generateAnonymousPaymentNonce
} from '../../utils/dataFetchers'


const renderSubmitButton = ({ onClick, isDisabled, text }) => {
	return (
		<Button variant="contained"
			color="secondary"
			onClick={onClick}
			disabled={isDisabled}
			style={{ width: '100%', marginBottom: '10px' }}
			disableElevation
		>
			PURCHASE
		</Button>
	)
}

/**
 * 
 * @param {Boolean} authenticated bool to determine if the current user is signed in or not
 * @param {Function} setProcessing updates the parents processing state variable
 * @param {Function} handlePayment function in parent component for making a transaction
 * @param {String} source  string denoting where this component was rendered (ie, "ProfitLayers" or "ADS")
 * @param {String} customerEmail the email the customer entered into the order form
 * @param {Number} price the price of the current transaction
 * @returns 
 */
export function PaymentApp({ 
  authenticated, 
  setProcessing, 
  handlePayment, 
  source = "ProfitLayers",
  customerEmail,
  price,
  retryNonce
}) {
  const [isLoading, setIsLoading] = useState(false)
  const [paymentNonce, setPaymentNonce] = useState(null)
  const [isSuccessful, setIsSuccessful] = useState(false)
  const [displayMessage, setDisplayMessage] = useState(false)
  const [message, setMessage] = useState("")
  const [isEdit, setIsEdit] = useState(false)
  const [conuntryList, setCountryList] = useState([])
  const [stateList, setStateList] = useState([])
  const [taxAmount, setTaxAmount] = useState(0)
  const [purchaseHasBeenClicked, setPurchaseHasBeenClicked] = useState(false)
  const [disablePurchaseButton, setDisablePurchaseButton] = useState(false)
  const [savingCustomer, setSavingCustomer] = useState(false)
  const [stateId, setStateId] = useState(null)
  const [dealerInformation, setDealerInformation] = useState(null)
  const [showErrorMessgae, setShowErrorMessage] = useState(false)
  const [customerInformation, setCustomerInformation] = useState(null)
  const [isSubmitButtonDisabled, setIsSubmitButtonDisabled] = useState(true)

  /**
   * this should only ever fire on render once the props are passed in
   * handles which user flow to go through for getting the first payment nonce
   */
	useEffect(() => {
    if (authenticated && paymentNonce === null) {
      createBraintreeInputs()
    }
    if(!authenticated && validEmail(customerEmail)){
      createBraintreeInputs(customerEmail)
    }
  }, [authenticated])


  useEffect(() => {
    if(retryNonce !== null && retryNonce !== paymentNonce){
      setPaymentNonce(retryNonce)
    }
  },[retryNonce])

	/**
	 * Method to get or create the BrainTree customer object and then
	 * get the payment nonce for the user so that the purchase can be made
	 * @param {string} email users email (only required if user is not authenticated)
	 * @returns 
	 */
	const createBraintreeInputs = async (email = null) => {
		try{
			let request = {
				IsAzureRegistration: true
			}
      if(email !== null){
        request['EmailId']=email
      }
      // send request to get the customer associated with current user or create one
			let customer = email === null ? await getOrCreateCustomer(request) : await getOrCreateAnonymousCustomer(request)
			if(!exists(customer)){
				return
			}
      // console.log("CUSTOMER", customer)
      // if something internal failed then don't try to continue
      if (customer.message == "Fail request"){
        console.log("Could not get or create customer", customer)
        return
      }
      setCustomerInformation(customer.data)
      // get the payment nonce for the customer
			let nonceResponse = email === null ? await generatePaymentNonce() : await generateAnonymousPaymentNonce(email)
			if(!exists(nonceResponse)){
				return
			}
			setPaymentNonce(nonceResponse.clientToken)
		}
		catch(err){
			console.log(err)
		}
	}


  /**
   * gets the price, payment nonce, and customer information and sends to the Parent components handlePayment method
   * @param {Object} payload paylod response from Braintree from requesting a payment method, used for the payload.nonce
   */
  const handlePaymentMethod = async (payload) => {
    console.log("BRAINTREE PAYLOAD", payload)
    setProcessing(true)
    let referralCode = "", orgSource = ""

    let customer = '', customerInfo = '', subscription = ''


    let feature;
    let customerData;
    let subscriptionData;

    feature = {
      "price": price,
      "nonce": payload.nonce,
      "lastFour": payload.details.lastFour,
      "cardType": payload.details.cardType,
      "expMonth": payload.details.expirationMonth,
      "expYear": payload.details.expirationYear
    }

    customerData = {
      EmailId: customerInformation.emailId,
      FirstName: "",
      LastName: "",
      Phone: customerInformation.phone,
      StateId: customerInformation.stateId,
      CountryId: customerInformation.countryId,
      ApiKey: customerInformation.apiKey,
      Organization: customerInformation.organization,
      CustomerID: customerInformation.customerId
    }

    //console.log("customerData", customerData)
    setIsSubmitButtonDisabled(true)
    let result = await handlePayment(customerData, feature)
    if(result == null){
      setIsSubmitButtonDisabled(false)
    }
  }

  const onError = () => {
    setShowErrorMessage(true)
  }
  const onCreate = () => {

  }


  return (
    <Box>
      {isLoading && (
        <SpinningLoader />
      )}

      <BrainTreeDropIn
        braintree={braintree}
        paypal={{ flow: 'vault' }}
        authorizationToken={paymentNonce}
        renderSubmitButton={renderSubmitButton}
        setProcessing={setProcessing}
        handlePaymentMethod={handlePaymentMethod}
        onError={onError}
        onCreate={onCreate}
        googlePay={{
          merchantId: Endpoints.API_BRAINTREE_GOOGLEPAY_MERCHANTID,
          transactionInfo: {
            totalPriceStatus: 'FINAL',
            totalPrice: 500,
            currencyCode: 'USD'
          },
          cardRequirements: {
            billingAddressRequired: false
          }
        }}
        isSubmitButtonDisabled={isSubmitButtonDisabled}
        setIsSubmitButtonDisabled={setIsSubmitButtonDisabled}
        source={source}
      />

    </Box>

  )
}
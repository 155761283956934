import React from "react";
import { makeStyles } from '@material-ui/core/styles';
import { Box, Container, Typography, Divider} from "@material-ui/core";
import { blackText } from '../../styles/colors'
import background from '../../images/crop-background.jpg'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    width: '100%',
    minHeight: `calc(100vh - 56px)`,
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto'
  },
  darken: {
    display: 'flex',
    flexGrow: 1,
    width: '100%',
    minHeight: `calc(100vh - 56px)`,
    backgroundColor: 'rgba(0,0,0,.2)',
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
  }
}))

export const ComingSoon = ({setSection, text}) => {
  const classes = useStyles()

  return (
    <Box className={classes.root}>
      <Box className={classes.darken}>
        <Container maxWidth='md' style={{paddingTop: '40px'}}>
          <Box
            p={2}
            display='flex'
            flexDirection='column'
            fontSize={18}
            fontWeight={500}
            color={blackText}
            borderRadius='borderRadius'
            boxShadow={1}
            style={{backgroundColor: '#ffffff'}}
          >
            <Box>
              <Typography variant='h4' align='center'>
                Coming Soon!
              </Typography>
            </Box>

            <Divider style={{margin: '4px 0'}}/>

            {
              (text !== undefined && typeof text === 'object') && text.map((x, i) =>
              <Box key={i} p={1}>
                <Typography align='center' style={{fontWeight: 500, fontSize: 20, color: blackText}}>
                  {x}
                </Typography>
              </Box>
            )}

          </Box>
        </Container>
      </Box>
    </Box>
  )
}

/* eslint-disable react/jsx-no-target-blank */
import React, { useEffect, useContext, useState } from 'react';
import ReactGA from 'react-ga'
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  Typography,
  Divider,
  Button,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  Fade,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { UserContext } from '../Context/UserContext';
import { FieldContext } from '../Context/FieldContext';
import { useWindowDimensions } from '../../utils/dimensions';
import infographic from '../../images/plgif_condensed.gif';
import logo from '../../images/ProfitLayers_Full_Gradient.png';
import { grey } from '../../styles/colors';
import { Endpoints } from '../../constants/Endpoints';
import { getProfitMapAccess } from '../../utils/dataFetchers';
import { Integrate } from '../Partners/Integrate'

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: 0,
    overflowY: 'auto',
    backgroundColor: theme.palette.greys.light,
  },
  head: theme.updateOperationHeader,
  close: {
    fontSize: 24,
    color: theme.palette.primary.main,
    position: 'absolute',
    top: 6,
    right: 6,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  buttonBox: {
    backgroundColor: '#ffffff',
    margin: '8px 0',
    borderRadius: 8,
  },
  customButton: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontSize: '1.1rem',
    fontWeight: 500,
    padding: 8,
    width: 200,
    textTransform: 'none',
  },
  infoBox: {
    padding: 8,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
  link: {
    textDecoration: 'none',
    whiteSpace: 'nowrap',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
      textDecoration: 'none',
    },
  },
}));

/**
 * Informative modal for user to see when first visit ProfitLayers app.
 * Includes appropriate infographics depending on whether user is logged in and has integrated.
 * Details description, if integrated, steps to generate PL, and links to sign in/up and/or start using the app.
 * @param {Bool} open Determine if modal is opened or closed
 * @param {Function} setOpen Sets if modal is open
 * @returns {JSX} Info modal
 */
export const InfoModal = ({ open, setOpen }) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [authenticated, setAuthenticated] = useState(false);
  const [showSubsciptionLink, setShowSubsciptionLink] = useState(false);
  const user = useContext(UserContext)[0];
  const fieldData = useContext(FieldContext)[0];

  useEffect(() => {
    sessionStorage.setItem('viewed', JSON.stringify({ profitLayersInfo: true }));
  }, []);

  useEffect(() => {
    if (user) {
      setAuthenticated(user?.isAuthenticated);
    }
  }, [user]);

  useEffect(() => {
    if (fieldData?.selectedField?.orgId !== '') {
      getAccess(fieldData.selectedField.orgId);
    }
  }, [fieldData]);

  const getAccess = async (orgId) => {
    const access = await getProfitMapAccess(orgId);
    // If user does not have profit map access, show subscribe link
    setShowSubsciptionLink(!access);
  };

  const learnMore = () => {
    setOpen(false);
    window.open('https://www.profit.ag/', '_blank');
  };

  const subscribe = () => {
    window.location.assign(`${Endpoints.HOME}/app/Purchase`);
  };

  const signIn = () => {
    ReactGA.event({
      category: "User",
      action: "Sign In"
    })
    window.location.assign(Endpoints.BASEURL + Endpoints.SIGNIN);
  };

  const customText = () => (
    <Box
      my={1}
      p={1}
      display="flex"
      flexDirection="column"
      fontSize={16}
      fontWeight={500}
      style={{ maxWidth: 740 }}
    >
      <Box>
        Sign up by connecting your Precision Ag system for advanced insights, analysis, and
        features.
      </Box>
      <Box>
        This connection will securely pull in your field boundaries,
        information, and any operational data*.
      </Box>
    </Box>
  );

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="profit-layers-info"
      aria-describedby="profit-layers-description"
    >
      <Fade in={open}>
        <Box
          className={classes.paper}
          boxShadow={2}
          height="auto"
          width={width - 40}
          maxWidth={user?.isIntegrated ? 1000 : 930}
          maxHeight={height - 20}
        >
          {/* Header - PL and x button */}
          <HighlightOffIcon
            className={classes.close}
            onClick={() => { setOpen(false); }}
          />
          <Box py={1} bgcolor="#ffffff" display="flex" justifyContent="center" alignItems="center">
            <img src={logo} alt="logo" height="60px" width="auto" />
          </Box>
          <Divider style={{ backgroundColor: grey }} />

          {/* App description */}
          <Box p={1}>
            <Typography align="center" style={{ fontSize: '1.2rem', fontWeight: 500 }}>
              Profit Layers combines your integrated precision agriculture harvest, seeding,
              application, and tillage data with our product price database to generate profit,
              revenue and cost layers.&nbsp;
              { (!authenticated || showSubsciptionLink) && (
              <a className={classes.link} href="https://www.profit.ag/" target="_blank">
                <span className={classes.link}>Learn More.</span>
              </a>
              )}
            </Typography>
          </Box>
          <Divider />

          {/* Integration links or app visual */}
          <Box px={1} pb={1} display="flex" justifyContent="space-around" flexWrap="wrap">
            {user?.isIntegrated
            ?
              <>
                <Box pt={1} display="flex" flexDirection="column">
                  <Stepper orientation="vertical" style={{ maxWidth: 440 }}>
                    <Step active>
                      <StepLabel>
                        <Typography style={{ fontWeight: 500 }}>
                          Select Field
                        </Typography>
                      </StepLabel>
                      <StepContent>
                        <Typography>
                          Select your field from the dropdown menu or claim a field by address, coordinates, or zooming on the map.
                        </Typography>
                      </StepContent>
                    </Step>
                    <Step active>
                      <StepLabel>
                        <Typography style={{ fontWeight: 500 }}>
                          Set up ProfitLayers
                          <sup>&reg;</sup>
                        </Typography>

                      </StepLabel>
                      <StepContent>
                        <Typography>
                          Manage your integrated data, supplement with your locally uploaded precision data, and manually create operations as needed.
                          Add any other costs/revenues, and choose which operations to include in your profit map.
                        </Typography>

                      </StepContent>
                    </Step>
                    <Step active>
                      <StepLabel>
                        <Typography style={{ fontWeight: 500 }}>
                          View ProfitLayers
                          <sup>&reg;</sup>
                        </Typography>

                      </StepLabel>
                      <StepContent>
                        <Typography>
                          Analyze your in-field profits by variety, test application, elevation, and more!
                          Create zones to better identify problem areas on your field.
                          Gain valuable insight into the variables that are impacting your profits.
                        </Typography>
                      </StepContent>
                    </Step>
                  </Stepper>
                </Box>

                <Box pt={1} display="flex" justifyContent="center">
                  <img src={infographic} alt="Infographic" height="auto" width="100%" style={{ maxWidth: '520px', borderRadius: 4 }} />
                </Box>
              </>
            :
              <Integrate
                customText={!authenticated ? customText : undefined}
                condensedTerms
              />
            }
          </Box>
          <Divider style={{ height: 2, backgroundColor: grey }} />

          {/* Bottom section with buttons */}
          <Box
            display="flex"
            justifyContent="space-between"
            flexDirection={width > 800 ? 'row' : 'column'}
          >
            {/* Sign In/Subscribe/Learn More buttons */}
            {!authenticated ? (
              <Box className={classes.infoBox} width={width > 800 ? '45%' : '100%'}>
                <Typography align="center" style={{ fontWeight: 500, fontSize: '1.8rem' }}>
                  Have an account?
                </Typography>

                <Box width="80%">
                  <Typography style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                    Select your integrated field boundary and view your Profit Layers
                    <sup>&reg;</sup>
                    .
                  </Typography>
                </Box>

                <Box className={classes.buttonBox}>
                  <Button className={classes.customButton} onClick={signIn}>
                    Sign In
                  </Button>
                </Box>
              </Box>
            ) : showSubsciptionLink ? (
              <Box className={classes.infoBox} width={width > 800 ? '45%' : '100%'}>
                <Typography align="center" style={{ fontWeight: 500, fontSize: '1.8rem' }}>
                  Subscribe
                </Typography>

                <Box width="80%">
                  <Typography style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                    Subscribe today to unlock unlimited profit maps with a full 30-day money back guarantee.
                  </Typography>
                </Box>

                <Box className={classes.buttonBox}>
                  <Button className={classes.customButton} onClick={subscribe}>
                    Subscribe Now
                  </Button>
                </Box>
              </Box>
            ) : (
              <Box className={classes.infoBox} width={width > 800 ? '45%' : '100%'}>
                <Typography align="center" style={{ fontWeight: 500, fontSize: '1.8rem' }}>
                  Overview
                </Typography>

                <Box width="80%">
                  <Typography style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                    Click below to see more about how ProfitLayers
                    <sup>&reg;</sup>
                    can provides unique insights in to your operations.
                  </Typography>
                </Box>

                <Box className={classes.buttonBox}>
                  <Button className={classes.customButton} onClick={learnMore}>
                    Learn More
                  </Button>
                </Box>
              </Box>
            )}

            {/* OR/divider separator */}
            {width > 800 ? (
              <Box display="flex" flexDirection="column" alignItems="center" width="10%">
                <Divider orientation="vertical" style={{ height: '40%', width: 2, backgroundColor: grey }} />
                <Box height="20%" fontSize={26}>Or</Box>
                <Divider orientation="vertical" style={{ height: '40%', width: 2, backgroundColor: grey }} />
              </Box>
            ) : (
              <Divider style={{ height: 2, backgroundColor: grey }} />
            )}

            {/* Start Now button */}
            <Box className={classes.infoBox} width={width > 800 ? '45%' : '100%'}>
              <Typography align="center" style={{ fontWeight: 500, fontSize: '1.8rem' }}>
                Quick Start
              </Typography>

              <Box width="80%">
                <Typography style={{ fontWeight: 500, fontSize: '1.1rem' }}>
                  Find your field by address, coordinates, or zoom on the map
                  {!authenticated ? ' to claim your field' : '.'}
                </Typography>
              </Box>

              <Box className={classes.buttonBox}>
                <Button id="modal_start_now" className={classes.customButton} onClick={() => setOpen(false)}>
                  Start Now
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Fade>
    </Modal>
  );
};

InfoModal.propTypes = {
  open: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired,
};

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Container, Box, Button, Checkbox,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';

import { OrderForm } from '../../Shared/Vendors/OrderForm';
import { terms } from '../../Shared/Vendors/TermsAndConditions';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { soilTestingTerms } from './vendorTerms';
import { submitOrder } from './SubmitOrder';
import {
  canPlaceOrder,
  incompleteOrderMessage,
} from '../../Shared/Vendors/validateBeforeSubmit';
import { darkGrey } from '../../../styles/colors';
import { DisplayOrder } from './DisplayOrder';

const useStyles = makeStyles((theme) => ({
  card: {
    borderRadius: 4,
    border: `solid ${darkGrey} 1px`,
    padding: 16,
    maxWidth: 800,
    color: theme.palette.text.primary,
  },
  terms: {
    height: 300,
    overflowY: 'auto',
  },
  checkbox: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  hover: {
    fontColor: theme.palette.text.primary,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.secondary,
    },
  },
}));

/**
 * Displays cart, order form, terms and conditions, and submit order button.
 * Uses OrderItems component to display items in cart by mapping orderDetails.
 * @param {Array} orderDetails List of objects containing field and order info
 * @param {Function} deleteField  Removes field from cart
 * @param {Function} editField Sets field to currently selected so user can edit
 * @param {Function} editFieldName Update field name in cart and if selected
 * @param {Number} cartTotal Total cost of packages in cart
 * @param {Boolean} checkoutClicked Lets this component know submit order was clicked from footer
 * @param {Function} setCheckoutClicked Reset value of checkoutClicked after acknowledged
 * @param {Object} formData User information for form
 * @param {Function} setFormData Sets user information for form
 * @param {Object} fileData User uploaded files
 * @param {Function} setFileData Handles user uploaded files
 * @param {Function} handleCompletedOrder When order is submitted
 * @param {Number} costToCollectSample Base cost vendor charges (currently $0)
 * @returns {JSX} Submit Order Page
 */
export function Order({
  orderDetails,
  deleteField,
  editField,
  editFieldName,
  cartTotal,
  checkoutClicked,
  setCheckoutClicked,
  formData,
  setFormData,
  fileData,
  setFileData,
  handleCompletedOrder,
  costToCollectSample,
}) {
  const classes = useStyles();

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [orderValid, setOrderValid] = useState(true);
  const [orderErrors, setOrderErrors] = useState({});

  // Alert user to incomplete order (missing form data, TaC not checked...)
  const [alert, setAlert] = useState(false);
  const [incompleteMessage, setIncompleteMessage] = useState('');

  const [loading, setLoading] = useState(false);

  const allowedStates = ['North Dakota',
    'South Dakota',
    'Nebraska',
    'Kansas',
    'Oklahoma',
    'Texas',
    'Minnesota',
    'Iowa',
    'Missouri',
    'Arkansas',
    'Wisconsin',
    'Illinois',
    'Mississippi',
    'Indiana',
    'Michigan',
    'Ohio'
  ]

  useEffect(() => {
    if (checkoutClicked) {
      // acknowledge click and reset value
      setCheckoutClicked(false);
      handleCheckout();
    }
  }, [checkoutClicked]);

  useEffect(() => {
    if(orderDetails.length){
      validateOrder()
    }
  },[orderDetails])

  // Terms and conditions checkbox control
  const check = () => {
    setAlert(false);
    setTermsAccepted(!termsAccepted);
  };

  const validateOrder = () => {
    let errors = {}
    for (const item of orderDetails){
      let acreIssue = item.acres < 40;
      let outsideCoverage = !allowedStates.includes(item.state);
      if(acreIssue || outsideCoverage){
        errors[item.name] = {'acres': acreIssue, 'coverage': outsideCoverage}
        setOrderValid(false);
      }
    }
    if(Object.keys(errors).length == 0){
      setOrderValid(true);
    }
    setOrderErrors(errors);
  }

  const handleCheckout = async () => {
    // Check that all needed data is available to submit order
    if (canPlaceOrder(orderDetails, formData, termsAccepted)) {
      setLoading(true);
      setIncompleteMessage('');

      const orderResponse = await submitOrder(
        orderDetails,
        formData,
        costToCollectSample,
        fileData,
        setFileData,
      );

      setLoading(false);
      handleCompletedOrder(orderResponse);
    } else {
      // Show message to user informing which data is missing
      const { message, alertMessage } = incompleteOrderMessage(
        orderDetails, formData, termsAccepted,
      );
      setAlert(alertMessage);
      setIncompleteMessage(message);
    }
  };

  const incompleteAlert = () => (
    <Box my={1} display="flex" justifyContent="center">
      <Box
        p={1}
        style={{ maxWidth: 380 }}
        fontWeight={500}
        border={1}
        borderColor="red"
        borderRadius="borderRadius"
      >
        { incompleteMessage }
      </Box>
    </Box>
  );

  return (
    <Container>
      <Box
        pt={1}
        display="flex"
        flexWrap="wrap"
        justifyContent="center"
      >
        <Box pr={1} display="flex" flexDirection="column" alignItems="center">
          <Box className={classes.card}>
            {/* Submit Order Card */}
            <DisplayOrder
              deleteField={deleteField}
              editField={editField}
              editFieldName={editFieldName}
              orderDetails={orderDetails}
              orderErrors={orderErrors}
              total={cartTotal}
            />

            <Box
              mt={1}
              display="flex"
              justifyContent="center"
            >
              <Button
                color="primary"
                variant="contained"
                size="large"
                disabled={!orderValid}
                onClick={() => handleCheckout()}
                disableElevation
              >
                Submit Order
              </Button>
            </Box>
            { incompleteMessage !== '' && (
              incompleteAlert()
            )}
          </Box>

          {/* Terms and Condition Box */}
          <Box
            my={1}
            py={1}
            border={1}
            borderRadius="borderRadius"
            borderColor={alert ? 'red' : darkGrey}
            style={{ maxWidth: 490 }}
          >
            <Box
              display="flex"
              alignItems="center"
            >
              <Checkbox
                checked={termsAccepted}
                icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
                checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
                onChange={() => check()}
              />

              <Box className={classes.hover} onClick={() => check()}>
                I have read and agree to the Terms of Use & Privacy Policy
              </Box>
            </Box>

            <Box pl={1} className={classes.terms}>
              { terms(soilTestingTerms)}
            </Box>
          </Box>
        </Box>

        {/* User info form */}
        <Box
          mx={1}
          width={400}
        >
          <Box
            border={1}
            borderRadius="borderRadius"
            borderColor={darkGrey}
          >
            <OrderForm
              formData={formData}
              setFormData={setFormData}
              dateSelection="Optionally, select the date when you would like our agents to collect samples from your field"
              fileData={fileData}
              setFileData={setFileData}
            />
          </Box>

        </Box>

      </Box>

      { loading && <SpinningLoader />}

    </Container>
  );
}

Order.propTypes = {
  orderDetails: PropTypes.array.isRequired,
  deleteField: PropTypes.func.isRequired,
  editField: PropTypes.func.isRequired,
  editFieldName: PropTypes.func.isRequired,
  cartTotal: PropTypes.number.isRequired,
  checkoutClicked: PropTypes.bool.isRequired,
  setCheckoutClicked: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  fileData: PropTypes.object,
  setFileData: PropTypes.func.isRequired,
  handleCompletedOrder: PropTypes.func.isRequired,
  costToCollectSample: PropTypes.number.isRequired,
};

Order.defaultProps = {
  fileData: null,
};

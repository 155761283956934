import React, {useEffect, useState} from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { COLORS, SINGLE_DECIMAL_FORMAT } from "../../../../../../constants";

const useStyles = makeStyles(() => ({
  wrapper: {
    display: "flex",
    flexDirection: "column",
    fontSize: 14,
    color: COLORS.text,
    lineHeight: "19px",
    fontWeight: "normal",
  },
  legendItems: {
    "& :not(:last-child)": {
      borderBottom: `1px solid ${COLORS.lightGray}`,
    },
  },
  legendItem: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "10px 0",
  },
  circle: {
    borderRadius: "50%",
    width: 17,
    height: 17,
    marginRight: 15,
  },
  farmContainer: {
    display: "flex",
    flexDirection: "column",
    marginTop: 45,
    borderRadius: 5,
    border: `1px solid ${COLORS.green}`,
    height: 64,
    padding: 9,
  },
  title: {
    fontWeight: "bold",
    color: COLORS.green,
  },
}));

/**
 * Renders the legend container
 * @param {string} legendType the topic of the legend: either 'Yield/Acre' or 'Precipitation'
 * @param {function} colorScale function that assigns color according to scale
 * @param {object} activeFarm object with farm data
 */

const Legend = ({ legendType, colorScale, activeFarm, harvestDataForFarm, crop, precipDataForFarm }) => {
  const classes = useStyles();

  const [value, setValue] = useState(0);

  useEffect(() => {
    let val= 0;

    if(legendType == "Yield/Acre"){
      val = harvestDataForFarm.filter(x => x.CropName === crop.id).length > 0 ? harvestDataForFarm.filter(x => x.CropName === crop.id)[0].AverageHarvestYield : 0
    }
    else if(legendType == "Precipitation"){
      val = precipDataForFarm.reduce(
        (accumulator, currentValue) => accumulator + currentValue.Precipitation, 0,
      );
      val *= 0.03937;
    }
    setValue(val)
  }, [legendType])

  return (
    <div className={classes.wrapper}>
      <div className={classes.legendItems}>
        {colorScale
          .range()
          .reverse()
          .map((d, i) => {
            const [x1, x2] = colorScale.invertExtent(d);
            let text;
            switch (i) {
              case 0:
                text = `>${SINGLE_DECIMAL_FORMAT(x2)}`;
                break;
              default:
                text = `${SINGLE_DECIMAL_FORMAT(x1)} - ${SINGLE_DECIMAL_FORMAT(
                  x2
                )}`;
            }
            return (
              <div className={classes.legendItem} key={`legend-item-for-${d}`}>
                <div
                  className={classes.circle}
                  style={{ backgroundColor: d }}
                />
                {`${text} ${
                  legendType === "Yield/Acre" ? "bushels/acre" : "inches"
                }`}
              </div>
            );
          })}
      </div>
      <div className={classes.farmContainer}>
        <div className={classes.title}>{activeFarm.name}</div>
        <div className={classes.legendItem}>
          <div
            className={classes.circle}
            style={{ backgroundColor: colorScale(value) }}
          />
          {`${SINGLE_DECIMAL_FORMAT(value)} ${
            legendType === "Yield/Acre" ? "bushels/acre" : "inches"
          }`}
        </div>
      </div>
    </div>
  );
};

export default Legend;

Legend.propTypes = {
  legendType: PropTypes.string.isRequired,
  colorScale: PropTypes.func.isRequired,
  activeFarm: PropTypes.object.isRequired,
};

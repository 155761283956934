/*
 * Some tooltip text will be shared between components. Store it here so can
 * ensure consistency and make changes in one place
 */

export const hiddenInfo = `These operations will not be used to calculate profits
  for your field. If you would like this operation to be accounted for,
  click the icon under show.`;

export const outliersAndAnomalies = 'The ProfitLayers system automatically removes certain outliers and anomalies in your precision agriculture sensor readings that other platforms may ignore, and as a result may be slightly different when processed through from other systems. However, this will not affect the patterns in your ProfitLayers, and our research indicates that your ProfitLayers and the summaries will be most accurate when processed by our system using the methods established.';

import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import {
  Box, Typography, Divider, Checkbox, Fade,
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { dollarFormat } from '../../../utils/helpers';
import { OrderItem } from './OrderItem';

const useStyles = makeStyles((theme) => ({
  checkbox: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
}));

/**
 * Displays items in users order. Will be used as order cart and to display
 * completed orders. Optionally, if delete and edit fields are not an option
 * they can be left out of paramaters and component will not render those icons.
 * @param {Array} orderDetails Objects of order information
 * @param {Array} fieldImages Images of fields
 * @param {Function} deleteField Removes field from order
 * @param {Function} editField Removes field from order and allows user to edit
 * @param {Function} editFieldName Update field name in cart and if selected
 * @param {Number} cartTotal Reports cost
 * @param {Number} flightCost Cost for flight
 * @param {Bool} nirAdded Option to add NIR to order
 * @param {Function} setNirAdded Adds NIR to order
 * @param {Number} nirCost Cost of adding NIR
 * @returns {JSX} Order details
 */
export const DisplayOrder = ({
  orderDetails,
  fieldImages,
  deleteField,
  editField,
  editFieldName,
  cartTotal,
  flightCost,
  nirAdded,
  setNirAdded,
  nirCost,
}) => {
  const classes = useStyles();
  const [total, setTotal] = useState(cartTotal + flightCost);

  useEffect(() => {
    if (nirAdded) {
      setTotal(cartTotal + flightCost + nirCost);
    } else {
      setTotal(cartTotal + flightCost);
    }
  }, [nirAdded]);

  return (
    <Box>
      <Box mt={1}>
        { orderDetails.length > 0 ? (
          orderDetails.map((fieldOrderInfo) => (
            <OrderItem
              key={fieldOrderInfo?.latitude}
              fieldOrderInfo={fieldOrderInfo}
              fieldImages={fieldImages}
              deleteField={deleteField}
              editField={editField}
              editFieldName={editFieldName}
            />
          ))
        ) : (
          <Typography align="center">
            Your cart is empty
          </Typography>
        )}

      </Box>

      <Divider style={{ marginTop: 18 }} />

      <Box>
        { setNirAdded && (
          <Box display="flex" alignItems="center">
            <Checkbox
              color="primary"
              checked={nirAdded}
              onChange={(e) => setNirAdded(e.target.checked)}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
              checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
            />
            <Box fontWeight={500}>
              Add NIR Imagery to your order for $175
            </Box>
          </Box>
        )}
        <Fade in={nirAdded}>
          <Box mt={0.5} display="flex">
            <Typography style={{ fontWeight: 500 }}>NIR Cost:</Typography>
            <Typography style={{ marginLeft: 'auto', fontWeight: 500 }}>
              {dollarFormat(nirCost)}
            </Typography>
          </Box>
        </Fade>
        <Box display="flex">
          <Typography style={{ fontWeight: 500 }}>
            {`Report${orderDetails.length > 1 ? 's' : ''} Subtotal:`}
          </Typography>
          <Typography style={{ marginLeft: 'auto', fontWeight: 500 }}>
            {dollarFormat(cartTotal)}
          </Typography>
        </Box>

        <Box mt={0.5} display="flex">
          <Typography style={{ fontWeight: 500 }}>Flight Cost:</Typography>
          <Typography style={{ marginLeft: 'auto', fontWeight: 500 }}>
            {dollarFormat(flightCost)}
          </Typography>
        </Box>

        <Divider />

        <Box mt={1} display="flex">
          <Typography style={{ fontWeight: 500 }}>Total Cost:</Typography>
          <Typography style={{ marginLeft: 'auto', fontWeight: 500 }}>
            {dollarFormat(total)}
          </Typography>
        </Box>

      </Box>
    </Box>
  );
};

DisplayOrder.propTypes = {
  orderDetails: PropTypes.array.isRequired,
  fieldImages: PropTypes.object.isRequired,
  deleteField: PropTypes.func,
  editField: PropTypes.func,
  editFieldName: PropTypes.func,
  cartTotal: PropTypes.number.isRequired,
  flightCost: PropTypes.number.isRequired,
  nirAdded: PropTypes.bool.isRequired,
  setNirAdded: PropTypes.func,
  nirCost: PropTypes.number.isRequired,
};

DisplayOrder.defaultProps = {
  deleteField: undefined,
  editField: undefined,
  editFieldName: undefined,
  setNirAdded: undefined,
};

import React from 'react';
import { Box, Modal, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';


const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    minWidth: 340,
    minHeight: 340,
    padding: 0,
  },
  close: theme.close,
}))

export const ImageModal = ({open, setOpen, image}) => {
  const classes = useStyles();
  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="larger-image"
      aria-describedby="image-modal-for-larger-view"
    >
      <Box className={classes.paper}>
        <HighlightOffIcon
          className={classes.close}
          onClick={() => {setOpen(false)}}
        />
        <Box display='flex'>
          <img style={{width: '100%', height: 'auto'}} src={image}/>
        </Box>

      </Box>
    </Modal>
  )
}

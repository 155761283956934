import React from "react";
import { makeStyles } from "@material-ui/core";
import { polygonHull } from "d3-polygon";
import { COLORS } from "../../../../../../constants";

const useStyles = makeStyles((theme) => ({
  hull: {
    fill: COLORS.mediumGray,
    fillOpacity: 0.15,
    stroke: "none",
    strokeLinejoin: "round",
  },
}));

const PolygonHull = ({
  values,
  radius,
  color,
  xAccessor = (d) => d.xPosition,
  yAccessor = (d) => d.yPosition,
}) => {
  const classes = useStyles();

  // Create points for a polygon hull
  let points = [];
  for (let value of values) {
    const isLeftOfCenter = xAccessor(value) < 0,
      isBelowCenter = yAccessor(value) > 0;
    const lowerBound = 1, // 1 - 0.1,
      upperBound = 1; //1 + 0.1;
    points.push([
      (xAccessor(value) - radius) * (isLeftOfCenter ? upperBound : lowerBound),
      (yAccessor(value) - radius) * (isBelowCenter ? lowerBound : upperBound),
    ]);
    points.push([
      (xAccessor(value) - radius) * (isLeftOfCenter ? upperBound : lowerBound),
      (yAccessor(value) + radius) * (isBelowCenter ? upperBound : lowerBound),
    ]);
    points.push([
      (xAccessor(value) + radius) * (isLeftOfCenter ? lowerBound : upperBound),
      (yAccessor(value) - radius) * (isBelowCenter ? lowerBound : upperBound),
    ]);
    points.push([
      (xAccessor(value) + radius) * (isLeftOfCenter ? lowerBound : upperBound),
      (yAccessor(value) + radius) * (isBelowCenter ? upperBound : lowerBound),
    ]);
  }

  return (
    <g className="polygon__hull">
      <path
        className={classes.hull}
        d={`M${polygonHull(points).join("L")}Z`}
        style={{ fill: color }}
      />
    </g>
  );
};
export default PolygonHull;

import React, {
  useEffect, useState, useContext, useRef,
} from 'react';
import { Box, Button } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Leaflet and Turf
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import * as owm from 'leaflet-openweathermap';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { Tiles } from '../../../constants/Tiles';
import { getOWMImagery } from '../../../utils/dataFetchers';

import { drawFieldLayer, drawImage } from '../../Maps/MapFunctions/helpers';
import { exists } from '../../../utils/helpers';
import { scrollTo } from '../../../utils/helpers'
import { blackText } from '../../../styles/colors'

const useStyles = makeStyles(theme => ({
  button: {
    "&:hover": {
      cursor: "pointer",
      color: theme.palette.primary.main,
    },
  },
}))

export function RadarMap({ boundary, centroid, containerHeight }) {
  const classes = useStyles();
  const [map, setMap] = useState(null);
  const fieldLayer = L.featureGroup(null);

  // Radar
  const radarLayers = useRef([]);
  const index = useRef(0);
  // Toggle for radar map
  const showRadar = useRef(true);
  const [isRadar, setIsRadar] = useState(true);

  // Open weather map options
  const owmOptions = useRef({});
  const owm = useRef([]);
  const [selectedOwm, setSelectedOwm] = useState('');
  const [owmImage, setOWMImage] = useState(null);

  // draw map when component mounts
  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      drawFieldLayer(geoJson, fieldLayer, map, false);
    }
  }, [boundary, map]);

  useEffect(() => {
    if (map !== null) {
      // to load radar map
      loadRadar();
    }
  }, [map]);

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('radar-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([34.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current'],
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }

  function loadRadar() {
    for (let hour = 0; hour <= 5; hour++) {
      const time = (50) - (hour * 10);
      if (hour < 5) {
        const layer = `nexrad-n0q-900913-m${time}m`;
        radarLayers.current[hour] = L.tileLayer.wms('https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0q.cgi', {
          layers: layer,
          format: 'image/png',
          transparent: true,
          opacity: showRadar.current ? 1.0 : 0,
          zIndex: 100,
        }).addTo(map);
      }
      if (hour == 5) {
        const layer = 'nexrad-n0q-900913';
        radarLayers.current[10] = L.tileLayer.wms('https://mesonet.agron.iastate.edu/cgi-bin/wms/nexrad/n0q.cgi', {
          layers: layer,
          format: 'image/png',
          transparent: true,
          opacity: showRadar.current ? 1.0 : 0,
          zIndex: 100,
        }).addTo(map);
      }
    }
    radarLoop();
  }

  // Radar map
  function radarLoop() {
    setTimeout(() => { //  call a 3s setTimeout when the loop is called
      radarLayers.current.map((layer) => { layer.setOpacity(0); });
      if (!showRadar.current) {
        radarLayers.current[index.current].setOpacity(0);
      } else {
        radarLayers.current[index.current].setOpacity(1.0);
      }

      index.current++;
      //  increment the counter
      if (index.current < 5) {
        //  if the counter < 10, call the loop function
        radarLoop(); //  ..  again which will trigger another
      } else {
        index.current = 0;
        radarLoop();
      } //  ..  setTimeout()
    }, 2000);
    return index.current;
  }

  const zoomToField = () => {
    try {
      map.setView(centroid, 12)
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <Box
      fontSize={16}
      fontWeight={500}
      color={blackText}
      bgcolor='#ffffff'
    >
      <Box m={2} >
        <Box fontSize={18}>
          Real-Time Weather
        </Box>

        <Box fontSize={'1rem'}>
          Scroll down to see details about your fields <span className={classes.button} onClick={() => scrollTo('current-weather')}>current weather</span> and any <span className={classes.button} onClick={() => scrollTo('weather-alerts')}>severe weather alerts</span>.
        </Box>

        <Button
          color='primary'
          variant='outlined'
          onClick={() => zoomToField()}
        >
          Zoom to your field
        </Button>
      </Box>

      <div id="radar-map" style={{ height: containerHeight, width: '100%' }} />
    </Box>
  );
}

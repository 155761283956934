import React, { useEffect, useState, useContext } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Container,
  Typography,
  Button,
  LinearProgress,
  CircularProgress,
  Link,
  Checkbox,
  FormControl, 
  TextField
} from '@material-ui/core';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import { useSnackbar } from 'notistack';
import {
  blackText, darkText, darkGrey, greyBlue,
} from '../../../styles/colors';
import {
  sleep,
  getImage,
  numFormat,
  validEmail,
  validPhone,
} from '../../../utils/helpers';
import { submitOrder } from './Submit';
import { Cart } from './Cart';
import { Success } from './Success';
import { useWindowDimensions } from '../../../utils/dimensions';
import {saveOrderPayment, searchAgentPromo} from '../../../utils/dataFetchers'
import { Endpoints } from '../../../constants/Endpoints';
import { terms } from './TermsAndConditions';
import { DigsImage } from '../../Maps/DigsImage';
import { OrderForm } from '../../Shared/Vendors/OrderForm';
import { PurchaseADS } from './PurchaseADS';
import { UserContext } from '../../Context/UserContext';


const useStyles = makeStyles((theme) => ({
  orderForm: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
  divider: {
    backgroundColor: greyBlue,
    height: '2px',
  },
  form: {
    backgroundColor: '#ffffff',
  },
  remove: {
    fontSize: '12px',
    maringBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  hover: {
    fontColor: blackText,
    '&:hover': {
      cursor: 'pointer',
      color: darkText,
    },
  },
  checkbox: {
    fontSize: 28,
    color: greyBlue,
  },
  payOptionBox:{
    display: 'flex',
    justifyContent: 'space-evenly',
    paddingBottom: '20px'
  },
  divider: {
    backgroundColor: greyBlue,
    height: '2px',
  },
  cart: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    margin: '0 4px',
    padding: '0 8px',
    minWidth: '400px',
    minHeight: '250px',
    backgroundColor: '#ffffff',
    fontSize: 18,
    border: `1px solid ${theme.palette.greys.dark}`,
    borderRadius: 4,
  },
  remove: {
    fontSize: '12px',
    marginLeft: '5px',
    maringBottom: '20px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  promoBox:{
    display: 'flex',
    alignItems: 'center',
    paddingBottom: '15px',
    justifyContent: 'center',
  }
}));

/**
 * [Checkout description]
 * @param {Array} order  Services in order
 * @param {Function} remove Remove services from order
 * @param {Bool} includesFields If fields includes in order
 * @param {Bool} hideNav Determines if component is called from DIGS iframe
 * @param {Function} setSection Set to 4 after order complete
 * @param {Object} formData User infomation for order form
 * @param {Function} setFormData Update user form information
 * @param {Object} fileData User uploaded files
 * @param {Function} setFileData User uplaoded files
 * @param {Bool} ads Bool to determine if rendered for ADS or DIGS (passed down from Digs.js)
 * @returns {JSX} Cart, terms, and order form
 */
export function Checkout({
  order, 
  remove, 
  includesFields, 
  hideNav, 
  setSection, 
  formData, 
  setFormData, 
  fileData, 
  setFileData, 
  ads
}) {
  const user = useContext(UserContext)[0];
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  const [total, setTotal] = useState(0);
  const [discount, setDiscount] = useState(0);
  const [orderDiscount, setOrderDiscount] = useState(0);

  // Orders without fields
  const [acres, setAcres] = useState(1);

  const [termsAccepted, setTermsAccepted] = useState(false);
  const [alert, setAlert] = useState(false);
  const [incompleteMessage, setIncompleteMessage] = useState(
    'Please complete the order form before submitting your order.',
  );

  // submitted order response
  const [submitting, setSubmitting] = useState(false);
  const [response, setResponse] = useState(null);
  const [orderFail, setOrderFail] = useState(false);
  const [incomplete, setIncomplete] = useState(false);

  const [transaction, setTransaction] = useState(null)

  // For images
  const [field, setField] = useState([]);
  const [gettingFieldImages, setGettingFieldImages] = useState(false);
  const [updatedOrder, setUpdatedOrder] = useState([]);

  // for payment option
  const [hidePayment, setHidePayment] = useState(true);
  const [payNow, setPayNow] = useState(true);

  // extra email to add to report
  const [agentEmail, setAgentEmail] = useState(null)
  const [agentCode, setAgentCode] = useState(null)
  const [promoCode, setPromoCode] = useState(null)
  const discountReports = ['Holistic Drainage Services', 'Watershed Facilitation']
  const [referralCode, setReferralCode] = useState('')
  const [focusReferral, setFocusReferral] = useState(false);

  useEffect(() => {
    if (includesFields) {
      const totalAcres = order.reduce((accumulator, item) => accumulator + item.acres, 0);
      setAcres(totalAcres);
    }
    if(order.length === 0){
      setDiscount(0)
      setHidePayment(true)
    }
  }, [order]);

  useEffect(() => {
    if (includesFields && updatedOrder.length !== order.length) {
      getFieldImages(order);
    }
  }, [order]);

  useEffect(() => {
    const elmnt = document.getElementById('digs-ordering');
    elmnt.scrollIntoView();
  }, []);

  useEffect(() => {
    if(transaction !== null){
      submit(true)
    }
  },[transaction])

  useEffect(() => {
    if(response !== null){
      saveOrderTransaction()
    }
  },[response])

  useEffect(() => {
    if(promoCode !== null){
      if(promoCode.includes('CONTRACTOR')){
        calculateDiscount()
      }
      if(promoCode.includes('PIPE')){
        setDiscount(0)
      }
      if(promoCode !== referralCode){
        setReferralCode(promoCode)
      }
    }
  },[promoCode])

  /**
   * Track acre value and update discount
   * this will only be used in the 'SimpleOrder' mode
   */
  useEffect(() => {
    if(!includesFields){
      if(promoCode !== null){
        if(promoCode.includes('CONTRACTOR')){
          calculateDiscount()
        }
        if(promoCode.includes('PIPE')){
          setDiscount(0)
        }
        if(promoCode !== referralCode){
          setReferralCode(promoCode)
        }
      }
    }
  },[acres, promoCode])

  const getFieldImages = async (orderData) => {
    setGettingFieldImages(true);
    const orderWithImages = [];

    // eslint-disable-next-line no-restricted-syntax
    for (const entry of orderData) {
      // set field for map
      setField(entry);
      // give it time to draw boundary
      // eslint-disable-next-line no-await-in-loop
      await sleep(1000);
      // get map image
      // eslint-disable-next-line no-await-in-loop
      const fieldImage = await getImage('field-image');

      // push updated entry with image
      orderWithImages.push({ ...entry, fieldImage });
    }
    setUpdatedOrder(orderWithImages);
    setGettingFieldImages(false);
  };

  const check = () => {
    setAlert(false);
    setTermsAccepted(!termsAccepted);
  };

  /**
   * create and send a request to the backend to store information about a transaction
   * in the database
   */
  const saveOrderTransaction = async () => {
    
    // calculate the final price from the order submission response to use if there was no transaction
    let orderTotal = Number(response.totalCost).toFixed(2)
    let discount = response.discount
    let finalPrice = orderTotal - discount

    let request = {
      OrderID: response.orderId,
      Vendor: 'DIGS',
      AgentCode: agentCode,
      TransactionID: transaction === null ? null : transaction.transactionId,
      CustomerID: transaction === null ? null : transaction.customerId,
      Amount: transaction === null ? finalPrice : transaction.amount,
      Paid: transaction == null ? 0 : 1
    }
    let saved = await saveOrderPayment(request)
  }

  /**
   * Triggered by pressing the 'Pay Now' button
   * displays the payment options if the order form is complete
   */
  const handlePayNow = () =>{
    if(!isOrderReady()){
      createIncompleteOrderMessage()
    }
    else{
      setPayNow(true)
      setHidePayment(false)
    }
  }

  /**
   * Function triggered by pressing the 'Pay Later' button
   * hides payment options and submits report
   */
  const handlePayLater = () => {
    // if we want to still have an agent email potential then we will need another step between payLater and submit
//  console.log("Pay later")
    setPayNow(false)
    setHidePayment(true)
    submit(false)
  }

  const isOrderReady = () =>{
    let valid = order.length > 0
    && formData.email !== ''
    && validEmail(formData.email)
    && formData.phone !== ''
    && validPhone(formData.phone)
    && formData.address !== ''
    && formData.zipCode !== ''
    && termsAccepted

    return valid
  }

  const submit = (paid=true) => {
    if (
      order.length > 0
      && formData.email !== ''
      && validEmail(formData.email)
      && formData.phone !== ''
      && validPhone(formData.phone)
      && formData.address !== ''
      && formData.zipCode !== ''
      && termsAccepted
    ) {
      const orderCopy = [...order];
      setSubmitting(true);
      const subOrderDetails = [];
      if (includesFields) {
        // Check that field images have been added. If something went wrong and
        // after function has ran use original order.
        const orderList = !gettingFieldImages && updatedOrder.length === order.length ? updatedOrder : order;

        orderList.forEach((item) => {
          // change reports key to digsReports, id to fieldId, and name to fieldName
          const updated = {
            ...item,
            digsReports: item.reports,
            fieldId: item.id,
            fieldName: item.name,
          };
          // remove incorrect keys
          delete updated.id;
          delete updated.name;
          delete updated.reports;
          subOrderDetails.push(updated);
        });

        // for (const field of orderList) {
        //   // change reports key to digsReports, id to fieldId, and name to fieldName
        //   const updated = {
        //     ...field,
        //     digsReports: field.reports,
        //     fieldId: field.id,
        //     fieldName: field.name,
        //   };
        //   // remove incorrect keys
        //   delete updated.id;
        //   delete updated.name;
        //   delete updated.reports;
        //   subOrderDetails.push(updated);
        // }
      } else {
        const updated = {
          acres,
          boundary: '',
          centroid: {},
          county: '',
          digsReports: order,
          coordinates: [],
          farm: '',
          farmId: '',
          fieldId: '',
          fieldName: '',
          geoJson: {},
          latitude: 0,
          longitude: 0,
          org: formData.organization,
          orgId: '',
          points: [],
          state: formData.state,
        };
        subOrderDetails.push(updated);
      }

      if (!includesFields) {
        orderCopy[0].orgId = '';
        orderCopy[0].org = formData.organization;
      }

      setOrderDiscount(discount)
      submitOrder(
        orderCopy,
        subOrderDetails,
        acres,
        total,
        formData.userName,
        formData.organization,
        formData.email,
        formData.phone,
        formData.address,
        formData.city,
        formData.zipCode,
        formData.state,
        formData.orderName,
        formData.comments,
        setSubmitting,
        setResponse,
        setOrderFail,
        fileData,
        setFileData,
        agentEmail,
        discount,
        ads,
        paid,
        transaction,
        promoCode,
      );
      setSection(4);
    } else {
      createIncompleteOrderMessage();
    }
  };

  const createIncompleteOrderMessage = () => {
    let message = 'To complete your order, please';
    const incompletes = [];

    if (order.length === 0) {
      incompletes.push('select at least one service');
    }

    if (!termsAccepted) {
      incompletes.push('read and accept the terms of use');
      // alert turns ToC box border red
      setAlert(true);
    }

    if (formData.email === '' || !validEmail(formData.email)) {
      incompletes.push('provide a valid email address');
    }

    if (formData.phone === '' || !validPhone(formData.phone)) {
      incompletes.push('provide a valid phone number');
    }

    if (formData.address === '' || formData.zipCode === '') {
      incompletes.push('provide a complete address');
    }

    if (incompletes.length === 1) {
      message += ` ${incompletes[0]}.`;
    } else {
      // multiple incompletes
      incompletes.forEach((item, i) => {
        if (i === incompletes.length - 1) {
          // last one
          message += ` and ${item}.`;
        } else {
          message += ` ${item},`;
        }
      });
    }
//  console.log("Incomplete message", message)

    setIncompleteMessage(message);
    setIncomplete(true);
  };

  /**
   * * THIS IS A COPY OF THE FUNCTION IN PurchaseADS.JS component to handle discounts for customers who select paylater **
   * Calculate the discount that should be applied to the current total price
   * currently there are two bundles that are applicable for discounts,
   * both receive a $10/ac discount
   */
  const calculateDiscount = () =>{
    let totalDiscount = 0;
    // console.log('Order',order)
    order.forEach((item, i) => {
      if(includesFields){
        let reports = item.reports
        reports.forEach((report, i) =>{
      //  console.log(report)
          if(discountReports.includes(report.name)){
            let itemDiscount = item.acres * 10
            // console.log('item discount', itemDiscount)
            totalDiscount += itemDiscount
          }
        })
      }
      else{
        if(discountReports.includes(item.name)){
          let itemDiscount = acres * 10
          // console.log('item discount', itemDiscount)
          totalDiscount += itemDiscount
        }
      }
      
    })
//  console.log('Total discount', totalDiscount)
    setDiscount(totalDiscount.toFixed(2))
  }

  /**
   * * THIS IS A COPY OF THE FUNCTION IN PurchaseADS.JS component to handle promocodes for customers who select paylater **
   * takes the input code from a text box and sends it to the backend to check
   * if the input matches a valid promo code provided by DIGS
   * won't send a request until the first portion of the code is entered.
   * all codes either start with 'PIPE' or 'CONTRACTOR' followed by numbers
   * @param {String} value input value from the promo code text box
   */
  const handleReferralCode = async (value) => {
    setReferralCode(value)
    try {
			console.log("check code for ADS")
      // do some pre request checks to lighten load on back end
      if((value.includes('PIPE') && value.length > 4)|| (value.includes('CONTRACTOR') && value.length > 10)){
        const res = await searchAgentPromo(value, 'DIGS');
    //  console.log(res)
        if (res.length === 1){
          setPromoCode(value)
          setAgentEmail(res[0].agentEmail)
          setAgentCode(res[0].agentCode)
        }
        else{
          setPromoCode(null)
          setAgentEmail(null)
          setDiscount(0)
        }
      }
      else{
        setDiscount(0)
        setPromoCode(null)
        setAgentEmail(null)
      }
      
    } catch (err) {
      console.error('error verifying disocunt code', err);
      setPromoCode(null)
      setAgentEmail(null)
      setDiscount(0)
    }
  };


  const orderProblem = () => (
    <Box
      my={1}
      p={3}
      boxShadow={1}
      style={{ height: '200px', width: 'auto', backgroundColor: '#ffffff' }}
    >
      <Typography variant="h6" align="center">
        There was a problem completing your order. Please try again or
        <Link href={`${Endpoints.BASEURL}/Home/Contact`} target="_blank" color="secondary"> contact us </Link>
        or email support@analytics.ag and we will be happy to help with completing your order.
      </Typography>

    </Box>
  );

  const submittingOrder = () => (
    <Box
      my={1}
      p={3}
      boxShadow={1}
      style={{ height: '200px', width: 'auto', backgroundColor: '#ffffff' }}
    >
      <Box>
        <Typography variant="h5" align="center">
          Your order is being submitted
        </Typography>
        <Typography variant="h5" align="center">
          Please remain on this page until you see your order confirmation.
        </Typography>
      </Box>
      <Box mt={2}>
        <LinearProgress />
      </Box>
    </Box>
  );

  const formAlert = () => (
    <Box
      m={1}
      p={1}
      display="flex"
      justifyContent="center"
      fontWeight={500}
      borderRadius="borderRadius"
      border={1}
      borderColor="red"
      boxShadow={1}
      style={{ backgroundColor: '#ffffff', maxWidth: 440 }}
    >
      { incompleteMessage }
    </Box>
  );

  const termsConditions = () => (
    <Box>
      <Box
        ml={1}
        pr={1}
        display="flex"
        alignItems="center"
        fontWeight={500}
        fontSize={14}
        borderRadius="borderRadius"
        border={1}
        borderColor={alert ? 'red' : darkGrey}
        style={{ backgroundColor: '#ffffff', marginBottom: 4, maxWidth: 440 }}
        id="checkbox"
      >
        <Checkbox
          checked={termsAccepted}
          icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
          checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
          onChange={() => check()}
        />
        <Box className={classes.hover} onClick={() => check()}>
          I have read and agree to the Terms of Use & Privacy Policy
        </Box>
      </Box>
      {hidePayment &&
        <Box
          mx={1}
          p={1}
          borderRadius="borderRadius"
          border={1}
          borderColor={alert ? 'red' : darkGrey}
          style={{
            maxHeight: 200,
            maxWidth: 440,
            overflowY: 'auto',
            backgroundColor: '#ffffff',
          }}
        >
          <Box>
            { terms }
          </Box>
        </Box>
      }
    </Box>
    
  )

  return (
    <Box
      py={1}
      color={blackText}
      style={{
        backgroundColor: '#f3f3f3',
        width: '100%',
        height: !hideNav ? '100%' : height - 135,
        overflow: 'auto',
      }}
    >

      <Container maxWidth="lg">
        {
          submitting ? submittingOrder()
            : orderFail ? orderProblem()
              : response === null
                ? (
                  <Box display="flex" justifyContent="center" flexWrap="wrap" id="digs-ordering">
                    <Box>
                      <Box
                        mx={1}
                        border={1}
                        borderRadius="borderRadius"
                        borderColor={darkGrey}
                        bgcolor="#ffffff"
                        width={400}
                      >
                        <OrderForm
                          formData={formData}
                          setFormData={setFormData}
                          fileData={fileData}
                          setFileData={setFileData}
                        />
                      </Box>
                    </Box>

                    {!ads &&
                      <Box display="flex" flexDirection="column">
                        <Cart
                          order={order}
                          getTotal={setTotal}
                          remove={remove}
                          includesFields={includesFields}
                          getAcres={setAcres}
                        />

                        { incomplete && formAlert() }

                        <Box m={1}>
                          { gettingFieldImages && (
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress color="secondary" size={18} />
                            <Box ml={1} fontWeight={500} fontSize={16} color={blackText}>
                              Loading order
                            </Box>
                          </Box>

                          )}

                          <Box mt={0.5} display="flex" justifyContent="center">
                            <Button
                              color="secondary"
                              variant="contained"
                              style={{ margin: 'auto', backgroundColor: greyBlue }}
                              onClick={() => submit()}
                              disabled={gettingFieldImages}
                              disableElevation
                            >
                              Submit Order
                            </Button>
                          </Box>
                        </Box>

                        {termsConditions()}

                      </Box>

                    }
                    {ads && /** ADS Specific rendering of the left column of the page (Cart, Payment options, and terms) */
                      <Box display="flex" flexDirection="column">
                        { incomplete && formAlert() }
                        {hidePayment && 
                          <Cart
                            order={order}
                            getTotal={setTotal}
                            remove={remove}
                            includesFields={includesFields}
                            getAcres={setAcres}
                            ads={ads}
                            discount={discount}
                          />
                        }
                        
                        {(payNow && !hidePayment) &&
                          <Box> {/** Payment details and checkout cc payment */}
                            <PurchaseADS
                              customerEmail={formData.email}
                              price={total}
                              isOrderReady={isOrderReady}
                              createIncompleteOrderMessage={createIncompleteOrderMessage}
                              setTransaction={setTransaction}
                              setAgentEmail={setAgentEmail}
                              setAgentCode={setAgentCode}
                              order={order}
                              remove={remove}
                              includesFields={includesFields}
                              setDiscountAmount={setDiscount}
                              promoCode={promoCode}
                              setPromoCode={setPromoCode}
                              cartAcres={acres}
                              setCartAcres={setAcres}
                              setCartTotal={setTotal}
                              
                            />
                          </Box>
                        }

                        <Box m={1}>
                          { gettingFieldImages && (
                          <Box display="flex" justifyContent="center" alignItems="center">
                            <CircularProgress color="secondary" size={18} />
                            <Box ml={1} fontWeight={500} fontSize={16} color={blackText}>
                              Loading order
                            </Box>
                          </Box>

                          )}
                        </Box>
                        
                        {hidePayment &&
                          <Box className={classes.promoBox}>
                            <Typography variant="subtitle2" style={{ paddingRight: 5 }}>
                              Enter Promo Code:
                            </Typography>

                            <FormControl variant="outlined">
                              <TextField
                                style={{background: 'white'}}
                                inputRef={(input) => (input && focusReferral) && input.focus()}
                                variant="outlined"
                                value={referralCode}
                                onChange={(event) => handleReferralCode(event.target.value)}
                                label="Promo Code"
                              />
                            </FormControl>
                          </Box>
                        }

                        <Box className={classes.payOptionBox}> {/** Pay now and Pay later options */}
                            {(hidePayment) &&
                              <Button
                                onClick={() => handlePayNow()}
                                color="secondary"
                                variant="contained"
                              >
                                Pay Now
                              </Button>
                            }
                            
                            <Button
                              onClick={handlePayLater}
                              color="secondary"
                              variant="contained"
                            >
                              {`Submit & Pay Later`}
                            </Button>
                        </Box>

                        {termsConditions()}
                        
                      </Box>
                    }
                    
                    <Box
                      style={{
                        position: 'relative',
                        overflow: 'hidden',
                      }}
                    >
                      <Box
                        style={{
                          position: 'absolute',
                          right: '-5000px',
                          top: '5000px',
                        }}
                      >
                        <Box>
                          <Box
                            id="field-image"
                            display="flex"
                            flexDirection="column"
                            borderRadius="borderRadius"
                            border={1}
                            borderColor={greyBlue}
                            bgcolor="#ffffff"
                            fontWeight={500}
                            color={blackText}
                            fontSize={16}
                          >
                            <Box px={1}>
                              <Box mr={1}>
                                {`Field: ${field?.name}`}
                              </Box>
                              <Box>
                                {`Acres: ${numFormat(field?.acres)}`}
                              </Box>

                              <Box display="flex" flexWrap="wrap">
                                <Box mr={1}>
                                  {`Latitude: ${numFormat(field?.latitude, 2, 5)}`}
                                </Box>
                                <Box>
                                  {`Longitude: ${numFormat(field?.longitude, 2, 5)}`}
                                </Box>
                              </Box>
                            </Box>

                            <DigsImage
                              field={field}
                            />
                          </Box>

                        </Box>
                      </Box>

                    </Box>

                  </Box>
                )
                : (
                  <Success
                    order={response}
                    includesFields={includesFields}
                    forIframe={hideNav}
                    ads={ads}
                    discount={orderDiscount}
                  />
                )
        }
      </Container>
    </Box>
  );
}

Checkout.propTypes = {
  order: PropTypes.array.isRequired,
  remove: PropTypes.func.isRequired,
  includesFields: PropTypes.bool.isRequired,
  hideNav: PropTypes.bool.isRequired,
  setSection: PropTypes.func.isRequired,
  formData: PropTypes.object.isRequired,
  setFormData: PropTypes.func.isRequired,
  fileData: PropTypes.object,
  setFileData: PropTypes.func.isRequired,
};

Checkout.defaultProps = {
  fileData: null,
};

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { Theme } from "../../../../../../constants";

const useStyles = makeStyles(() => ({
  headerContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    marginBottom: 13,
  },
  title: Theme.Title,
}));

/**
 * Renders the header in the map container
 * @param {string} topic either 'Yield/Acre'  or 'Precipitation'
 * @param {object} crop object with id and active property
 */

const Header = ({ topic, crop, activeYear }) => {
  const classes = useStyles();

  return (
    <div className={classes.headerContainer}>
      <div className={classes.title}>
        {`Map of ${topic} for ${crop === undefined ? "" : crop.id} in ${activeYear}`}
      </div>
    </div>
  );
};

export default Header;

Header.propTypes = {
  topic: PropTypes.string.isRequired,
  crop: PropTypes.object.isRequired,
};

/* eslint-disable camelcase */
import React, {
  useState, useEffect, useContext, useRef,
} from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Tabs, Tab, Fade,
} from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { cloneDeep } from 'lodash';

// Icons and styles
import { makeStyles } from '@material-ui/core/styles';
import { green, grey } from '../../styles/colors';

// Helper functions
import { useWindowDimensions } from '../../utils/dimensions';
import { dollarFormat, numFormat, validEmail } from '../../utils/helpers';

// Context
import { FieldContext } from '../Context/FieldContext';
import { UserContext } from '../Context/UserContext';

// Components
import { SelectField } from './SelectField';
import { ZoneSelection } from './ZoneSelection';
import { SelectPackage } from './SoilPackages/SelectPackage';
import { SelectedFieldImage } from './SoilPackages/SelectedFieldImage';
import { Overview } from './Overview';
import { Order } from './Ordering/Order';
import { OrderComplete } from './Ordering/OrderComplete';
import { InfoModal } from './InfoModal';
import { PopupManager } from '../Shared/Popups/PopupManager';
import poweredBy from '../../images/Ag-Analytics_Full_Black_Powered.svg';


const useStyles = makeStyles((theme) => ({
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
    backgroundColor: 'white',
  },
  centeredAppBar: {
    ...theme.appBar,
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  appBarSelections: {
    ...theme.appBarSelections,
    margin: '0 32px',
  },
  smallAppBarSelection: {
    ...theme.appBarSelections,
    margin: '0 10px',
  },
  mobileTabs: { ...theme.mobileTabs, height: 40 },
  body: {
    fontWeight: 500,
    color: theme.palette.text.primary,
    overflow: 'auto',
  },
  footer: theme.footer,
  fullLogo: {
    width: '250px',
    height: 'auto',
    marginRight: '20px'
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginRight: '8px'
  },
  smallLogo: {
    width: '90px',
    height: 'auto',
    marginRight: '4px'
  },
}));

/**
 * Handles entire order process for soil testing service. User will start with view
 * of map screen to select field, then create zones. Optionally, they can see overview
 * page with more information. After grid/zone creation, user will select their package.
 * Once a package is selected, user can complete order or add another field.
 * @param {Function} setHomeSection Set section for navbar
 * @param {Number} startingStep Step to show first
 * @returns {JSX} Soil testing components
 */
export function SoilTesting({ setHomeSection, startingStep = 1 }) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  const user = useContext(UserContext)[0];
  const fieldData = useContext(FieldContext)[0];

  // Screen pixel width to render components with mobile formatting
  const mobileBreakPoint = 880;
  // Breakpoint steps overflow screen width
  const stepSize = 1079;

  const [bodyHeight, setBodyHeight] = useState(height - 180);

  const [infographicOpen, setInfographicOpen] = useState(false);

  // Overview, Select Field, Create Zones, Select Test, Submit Order, Completed Order
  const [step, setStep] = useState(startingStep);

  const [selectedField, setSelectedField] = useState(null);
  const [initialBounds, setInitialBounds] = useState(null);

  const [cart, setCart] = useState([]);
  const [cartTotal, setCartTotal] = useState(0);
  const [checkoutClicked, setCheckoutClicked] = useState(false);

  const [formData, setFormData] = useState({
    orderName: 'Soil Testing',
    address: '',
    city: '',
    email: '',
    organization: '',
    phone: '',
    state: '',
    userName: '',
    zipCode: '',
    preferredDate: '',
    disableEmailUpdate: false,
    comments: '',
  });
  const [fileData, setFileData] = useState(null);

  const [apiResponse, setApiResponse] = useState();

  const fieldCount = useRef(1);

  const toolTip = {
    title: 'Soil sample pricing is per acre and independent of the number of zones selected.',
    text: 'The pricing of the soil sample and analysis results is applied at a per acre rate, not on the number of zones selected.',
  };

  // Price Vendor charges to collect sample
  const costToCollectSample = 0;

  useEffect(() => {
    const url = document.URL.split('?');
    const extension = url[1];
    if (extension === 'fromSignIn') {
      url.pop();
      window.history.replaceState({}, document.title, url[0]);
    } else if (document.URL.includes('overview')) {
      // user came here from /overview
      const soilUrl = document.URL.replace('/overview', '');
      window.history.replaceState({}, document.title, soilUrl);
    } else {
      const hasViewed = JSON.parse(sessionStorage.getItem('viewed'));
      if (!hasViewed?.soilTestingInfo) {
        setInfographicOpen(true);
      }
    }
  }, []);

  useEffect(() => {
    // Sets Navigation to Services
    if (setHomeSection) {
      setHomeSection(3);
    }
  }, [setHomeSection]);

  useEffect(() => {
    if (user?.email !== null && validEmail(user?.email)) {
      setFormData({
        ...formData,
        email: user.email,
        disableEmailUpdate: true,
        validEmail: true,
      });
    }
  }, [user]);

  useEffect(() => {
    loadField(fieldData);
  }, [fieldData]);

  useEffect(() => {
    // Set height of page body based on which step navigation displayed
    if (width > stepSize) {
      setBodyHeight(height - 181);
    } else if (width > mobileBreakPoint) {
      setBodyHeight(height - 156);
    } else {
      setBodyHeight(height - 141);
    }
  }, [width, height]);

  // Select most recently user choosen field from fieldData
  const loadField = (data) => {
    const field = data?.selectedField?.selectedAt ? data.selectedField : null;
    const drawnField = data?.fieldToClaim?.selectedAt ? data.fieldToClaim : null;

    if (field && drawnField) {
      // Both exists, so take most recent
      const selectedTime = new Date(field.selectedAt);
      const claimedTime = new Date(drawnField.selectedAt);

      if (selectedTime.getTime() > claimedTime.getTime()) {
        selectField(field);
      } else {
        selectField({ ...drawnField, name: makeName(fieldData.fieldToClaim) });
      }
    } else if (field) {
      selectField(field);
    } else if (drawnField) {
      selectField(drawnField);
    }
  };

  const makeName = (field) => {
    let name = 'Field';

    if (field?.latitude) {
      name += `- ${numFormat(field?.latitude)}, ${numFormat(field?.longitude)}`;
    }

    return name;
  };

  const addAnotherField = () => {
    if (cart.length >= 5) {
      enqueueSnackbar('If you would like to add services for more than 5 fields, please submit this order and place another with your additional fields');
    } else {
      setSelectedField(null);
      setStep(1);
    }
  };

  const selectField = (field, move = false) => {
    if (cart?.length >= 5) {
      enqueueSnackbar('If you would like to add services for more than 5 fields, please submit this order and place another with your additional fields');
      return;
    }

    if (!field) {
      setSelectedField(null);
      return;
    }

    const selectingField = field;

    if (!field?.name || field?.name === '') {
      selectingField.name = `Field - ${fieldCount.current}`;
      fieldCount.current += 1;
    }

    setSelectedField(selectingField);

    if (field?.org !== '') {
      setFormData({ ...formData, organization: field.org, state: field?.state });
    } else {
      setFormData({ ...formData, state: field?.state });
    }

    if (move) {
      setStep(move);
    }
  };

  const selectFieldWithZones = (field) => {
    if (field !== null) {
      // When zones are changed we need to reset any selected package
      setSelectedField({ ...field, package: null, packageInfo: null });
    }
  };

  const addPackage = async (selectedPackage, fieldImage = '', nextStep = true) => {
    // Update selected field tests to selected package
    const updatedField = {
      ...selectedField,
      package: selectedPackage.name,
      packageInfo: selectedPackage,
    };

    // If user selects package within first .8 seconds of SelectPackage render
    // Field image will not have time to get the image. The function that calls
    // addPackage in SelectPackage will then pass await getFieldImage and pass
    // image down to be set here as to ensure state updates properly.
    if (fieldImage !== '') {
      updatedField.fieldImage = fieldImage;
    }

    setSelectedField(updatedField);
    addFieldToCart(updatedField);

    // Optionally move to next step on selection
    if (nextStep) {
      setStep(4);
    }
  };

  // -------------------- Step Progression Related Functions -----------------\\
  // Determine text of continue button based on current step
  const footerContinueText = {
    0: 'Select Field',
    1: 'Create Zones',
    2: 'Select Package',
    3: 'Checkout',
  };

  const handleContinue = () => {
    if (step === 1) {
      goToCreateZones();
    } else if (step === 2) {
      goToSelectPackage();
    } else if (step === 3) {
      goToSubmitOrder();
    } else {
      setStep(step + 1);
    }
  };

  const handleStepChange = (event, value) => {
    if (value === 2) {
      goToCreateZones();
    } else if (value === 4) {
      goToSubmitOrder();
    } else {
      setStep(value);
    }
  };

  const goToFieldSelection = () => {
    setStep(1);
  };

  const goToCreateZones = () => {
    if (selectedField !== null) {
      setStep(2);
    } else {
      // Make sure we aren't trying to create zones before selecting field
      if (step !== 1) {
        setStep(1);
      }
      enqueueSnackbar('Please select a field before creating zones');
    }
  };

  const [autoGenerateGrids, setAutoGenerateGrids] = useState(false);

  const goToSelectPackage = (direct) => {
    // If user is in zone creation tab, but has not yet created zones, we will
    // auto generate them using whole field grid
    if (step !== 2 || selectedField?.zones?.length || direct) {
      setStep(3);
      setAutoGenerateGrids(false);
    } else {
      setAutoGenerateGrids(true);
    }
  };

  const goToSubmitOrder = () => {
    if (cart.length > 0) {
      setStep(4);
      addFieldToCart(selectedField);
    } else if (selectedField !== null) {
      if (selectedField?.package !== null) {
        setStep(4);
        addFieldToCart(selectedField);
      } else {
        // Make sure user has selected a package
        setStep(4);
      }
    } else {
      // Make sure we aren't trying to create zones before selecting field
      enqueueSnackbar('Please select a field before submitting order');
    }
  };

  const handleCheckout = () => {
    setCheckoutClicked(true);
  };

  const handleCompletedOrder = (orderResponse) => {
    if (orderResponse.status === 200) {
      // Reset order info
      setSelectedField(null);
      // setCart([]);
      setCartTotal(0);
    }

    // Move to order complete component
    setStep(5);

    // Store response for display
    setApiResponse(orderResponse);
  };

  const startNewOrder = () => {
    setStep(1);
    setSelectedField(null);
    setCart([]);
    setCartTotal(0);
    setCheckoutClicked(false);
    fieldCount.current = 1;
  };

  // ---------------------------- Cart Handeling ----------------------------\\
  const addFieldToCart = (fieldToAdd) => {
    try {
      if (fieldToAdd?.packageInfo && fieldToAdd?.zones.length) {
        // if field name doesn't have a name, give it a generic one
        const fieldName = fieldToAdd?.name !== '' ? fieldToAdd?.name : `Field-${cart.length + 1}`;

        const field = {
          ...fieldToAdd,
          fieldName,
          costPerAcre: fieldToAdd?.packageInfo?.msrp,
          subTotal: +(fieldToAdd?.zoneAcres * fieldToAdd?.packageInfo?.msrp).toFixed(2),
          sampleZones: fieldToAdd?.zones ? cleanZones(fieldToAdd?.zones) : [],
        };

        const updatingIndex = cart.findIndex((entry) => entry.id === fieldToAdd.id);

        if (updatingIndex >= 0) {
          // updating an existing field
          const tempCart = cloneDeep(cart);
          tempCart.splice(updatingIndex, 1, field);

          setCart(tempCart);
          setCartTotal(calculateTotal(tempCart));
        } else {
          // new field
          const updatedCart = [...cart, field];
          setCart(updatedCart);
          setCartTotal(calculateTotal(updatedCart));
          setInitialBounds(field?.boundary);
        }
      }
    } catch (err) {
      console.error(err);
    }
  };

  const cleanZones = (zones) => {
    // Remove potential circular map refs and uneeded data
    const cleanedZones = [];

    zones.forEach((zone) => {
      const newZone = {
        geometry: zone.geometry,
        properties: {
          Soil_Type: zone.properties?.Soil_Type,
          acres: zone.properties?.CALCACRES,
          color: zone.properties.color,
          zone: zone.properties?.zone,
        },
        type: 'Feature',
      };

      cleanedZones.push(newZone);
    });

    return cleanedZones;
  };

  // Code to delete field from cart
  const deleteFieldFromCart = (deletedFieldId) => {
    // Remove unwanted field
    const updatedCart = cart.filter((x) => x.id !== deletedFieldId);

    // Update cart
    setCart([...updatedCart]);
    setCartTotal(calculateTotal([...updatedCart]));
  };

  // This is only called when the user wants to edit a field already in the cart
  const editFieldInCart = (field) => {
    setSelectedField(field);
    setStep(3);
  };

  const editFieldName = (id, updatedName) => {
    // Edit field name in cart

    // Get index of field to edit
    const fieldIndex = cart.findIndex((x) => x.id === id);

    const cartCopy = [...cart];

    // Remove and return field to update
    const fieldToUpdate = cartCopy.splice(fieldIndex, 1)[0];
    fieldToUpdate.fieldName = updatedName;
    fieldToUpdate.name = updatedName;

    // Add back at correct index
    cartCopy.splice(fieldIndex, 0, fieldToUpdate);
    setCart(cartCopy);
  };

  const calculateTotal = (updatedCart) => updatedCart.reduce(
    (accumulator, currentValue) => accumulator + currentValue.subTotal, 0,
  );

  // ------------------------- Navigation and footer -------------------------\\
  // Navigation through component
  const steps = () => (
    <Box className={classes.appBar}>
      <Box
        className={classes.appBarSelections}
        color={step === 0 ? green : grey}
        onClick={() => setStep(0)}
      >
        Overview
      </Box>

      <Box
        className={classes.appBarSelections}
        color={step === 1 ? green : grey}
        onClick={() => setStep(1)}
      >
        Select Field
      </Box>

      <Box
        className={classes.appBarSelections}
        color={step === 2 ? green : grey}
        onClick={() => goToCreateZones()}
      >
        Create Zones
      </Box>

      <Box
        className={classes.appBarSelections}
        color={step === 3 ? green : grey}
        onClick={() => goToSelectPackage()}
      >
        Select Package
      </Box>

      <Box
        className={classes.appBarSelections}
        color={step === 4 ? green : grey}
        onClick={() => goToSubmitOrder()}
      >
        Submit Order
      </Box>
    </Box>
  );

  const mobileSteps = () => (
    <Box style={{ width }}>
      <Tabs
        value={step}
        // indicatorColor="primary"
        // textcolor="primary"
        onChange={handleStepChange}
        variant="scrollable"
        scrollButtons="on"
        style={width < mobileBreakPoint ? { maxHeight: 30, minHeight: 30 } : {}}
      >
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Overview"
        />
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Select Field"
        />
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Create Zones"
        />
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Select Package"
        />
        <Tab
          className={width < mobileBreakPoint ? classes.mobileTabs : ''}
          label="Submit Order"
        />
      </Tabs>
    </Box>
  );

  const footer = () => (
    <Box className={classes.footer}>
      <Box mx={2} display="flex">
        {/* Show back button if not on step one or view results */}
        { (step > 0 && step < 5) && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => setStep(step - 1)}
            style={{ backgroundColor: '#ffffff' }}
          >
            Back
          </Button>
        )}

        { step < 4 && (
          <>
            { selectedField?.name && (
              <>
                <Box
                  mx={1}
                  ml={2}
                  fontSize={width > mobileBreakPoint ? 14 : 12}
                  fontWeight={600}
                >
                  <Box color={grey} fontSize={12}>
                    FIELD
                  </Box>
                  {selectedField?.name}
                </Box>

                <Box
                  mx={1}
                  fontSize={width > mobileBreakPoint ? 14 : 12}
                  fontWeight={600}
                >
                  <Box color={grey} fontSize={12}>
                    ACRES
                  </Box>
                  { selectedField?.zoneAcres !== undefined && selectedField?.zoneAcres > 0 ? (
                    numFormat(selectedField.zoneAcres)
                  ) : (
                    numFormat(selectedField?.acres)
                  )}
                </Box>
              </>
            )}

            {(cartTotal > 0 && width > mobileBreakPoint) && (
            <Box
              mx={2}
              ml={2}
              fontSize={14}
              fontWeight={600}
            >
              <Box color={grey} fontSize={12}>
                SUBTOTAL
              </Box>
              {dollarFormat(cartTotal)}
            </Box>
            )}
          </>
        )}

      </Box>

      <Box mx={1} display="flex">
        {width > 760 &&
          <Box>
            <img
              src={poweredBy}
              className={width > 1024 ? classes.fullLogo : classes.mediumLogo}
              alt={"Powered By Ag-Analytics"}
            />
          </Box>
        }


        { step === 4 && (
          <Button
            variant="outlined"
            color="primary"
            onClick={() => addAnotherField()}
          >
            {`Add ${width > 500 ? 'another' : ''} Field`}
          </Button>
        )}

        <Box ml={1}>
          {/* Show Continue To or Checkout */}
          { step === 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleContinue()}
              disableElevation
            >
              {`${footerContinueText[step]}`}
            </Button>
          )}
          { step > 0 && step < 4 ? (
            <Fade in={step !== 1 || selectedField !== null}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => handleContinue()}
              >
                {width > 700 && 'Continue to '}
                {`${footerContinueText[step]}`}
              </Button>
            </Fade>

          ) : step === 4 ? (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => handleCheckout()}
            >
              Submit Order
            </Button>
          ) : ''}
        </Box>
      </Box>
    </Box>
  );

  const navBar = () => (
    width > stepSize ? steps() : mobileSteps()
  );

  return (
    <Box>
      { step < 5 && navBar() }

      {/* Set body height to window - (navbar + steps + footer) heights */}
      <Box
        className={classes.body}
        height={step < 5 ? bodyHeight : '100%'}
      >
        {
          step === 0 ? (
            <Overview
              setSection={setStep}
            />
          ) : step === 1 ? (
            <SelectField
              bodyHeight={bodyHeight}
              mobileBreakPoint={mobileBreakPoint}
              isAuthenticated={user.isAuthenticated}
              selectField={selectField}
              selectedField={selectedField}
              initialBounds={initialBounds}
              nextStep={2}
            />
          ) : step === 2 ? (
            <ZoneSelection
              bodyHeight={bodyHeight}
              selectField={selectFieldWithZones}
              selectedField={selectedField}
              goToSelectPackage={goToSelectPackage}
              goToFieldSelection={goToFieldSelection}
              toolTip={toolTip}
              autoGenerateGrids={autoGenerateGrids}
            />
          ) : step === 3 ? (
            <Fade in={step === 3}>
              <SelectPackage
                gridSize={selectedField?.gridSizeAcres}
                addPackage={addPackage}
                field={selectedField}
                updateField={setSelectedField}
                goToFieldSelection={goToFieldSelection}
                goToCreateZones={goToCreateZones}
              />
            </Fade>
          ) : step === 4 ? (
            <Fade in={step === 4}>
              <Order
                orderDetails={cart}
                deleteField={deleteFieldFromCart}
                editField={editFieldInCart}
                editFieldName={editFieldName}
                cartTotal={cartTotal}
                checkoutClicked={checkoutClicked}
                setCheckoutClicked={setCheckoutClicked}
                formData={formData}
                setFormData={setFormData}
                handleCompletedOrder={handleCompletedOrder}
                costToCollectSample={costToCollectSample}
                fileData={fileData}
                setFileData={setFileData}
              />
            </Fade>
          ) : (
            // Screen after Order is submitted
            <Fade in={step === 5}>
              <OrderComplete
                startNewOrder={startNewOrder}
                status={apiResponse?.status}
                apiResponse={apiResponse?.response}
                costToCollectSample={costToCollectSample}
                order={cart}
                formData={formData}
                handleCompletedOrder={handleCompletedOrder}
                fileData={fileData}
                setFileData={setFileData}
              />
            </Fade>
          )
      }
      </Box>
      { step < 5 && footer() }

      { (step < 5 && selectedField !== null && selectedField?.zones?.length) && (
        <Box
          style={{
            position: 'relative',
            overflow: 'hidden',
          }}
        >
          <Box
            style={{
              position: 'absolute',
              right: '-5000px',
              top: '5000px',
            }}
          >
            <SelectedFieldImage field={selectedField} />
          </Box>
        </Box>
      )}

      {infographicOpen && (
        <InfoModal
          open={infographicOpen}
          setOpen={setInfographicOpen}
          setStep={setStep}
        />
      )}

      {/* 12/01/2021 - We want to remove popups from services */}
      {/* <PopupManager
        acreageReporting
        droneAnalysis
        waterManagement
      /> */}
    </Box>
  );
}

SoilTesting.propTypes = {
  setHomeSection: PropTypes.func.isRequired,
  startingStep: PropTypes.number,
};

SoilTesting.defaultProps = {
  startingStep: 1,
};

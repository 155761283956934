import { submitVendorOrder, uploadOtherFiles } from '../../../utils/dataFetchers';

/**
 * Submit order and returns status and response
 * @param {Object} order Order information
 * @param {Object} formData User information
 * @param {Number} costToCollectSample Cost vendor charges for collection
 * @param {Object} fileData User uploaded files
 * @param {Function} setFileData Sets user uploaded files to null on submit
 * @returns {Object} Status and response
 */
export async function submitOrder(order, formData, costToCollectSample, fileData, setFileData) {
  const { subOrderDetails, totalCost, totalAcres } = getSubOrderDetails(order);

  const organizationID = order[0].orgId !== undefined ? order[0].orgId : '';
  const organizationName = (order[0].org !== '' && order[0].org !== undefined) ? order[0].org : formData.organization;

  let orderID = '';

  try {
    if (fileData != null) {
      const res = await uploadOtherFiles(fileData);
      orderID = res.orderID;
    }
  } catch (err) {
    console.error('Problem uploading files', err);
  }

  const completeOrder = {
    orderDate: String(new Date()),
    orderId: orderID,
    orderName: formData.orderName,
    vendorName: 'Deveron',
    ProductName: 'AgAnalyticsSoil',
    ProductType: 'Soil',
    ProductID: '3',
    farmerDetails: {
      fullname: formData.userName,
      email: formData.email,
      phone: formData.phone,
      mailingAddress: formData.address,
      city: formData.city,
      zipCode: formData.zipCode,
      state: formData.state,
    },
    organizationName,
    organizationID,
    totalCost,
    totalAcres,
    subOrderDetails,
    preferredDate: formData.preferredDate,
    vendorFee: costToCollectSample,
    comments: formData.comments,
  };

  // Response from endpoint
  const apiResponse = await submitVendorOrder(completeOrder);

  const { status } = apiResponse;

  // Response JSON for order
  const response = await apiResponse.json();
  setFileData(null);

  return { status, response };
}

const getSubOrderDetails = (order) => {
  const subOrderDetails = [];

  let totalAcres = 0;
  let totalCost = 0;

  order.forEach((field) => {
    const structuredForOrder = {
      acres: field.zoneAcres,
      boundary: field.boundary,
      centroid: field.centroid,
      coordinates: field.coordinates,
      costPerAcre: [field.packageInfo.msrp],
      county: field.county,
      farm: field?.farm,
      farmId: field?.farmId,
      fieldId: field.id,
      fieldImage: field.fieldImage,
      fieldname: field.fieldName,
      latitude: field.latitude,
      longitude: field.longitude,
      org: field?.org,
      orgId: field?.orgId,
      package: field.package,
      points: field.points,
      subTotal: Number(field.subTotal).toFixed(2),
      state: field.state,
      sampleZones: field.sampleZones.map((zone) => JSON.stringify(zone)),
      surveyQuestions: {},
    };

    totalAcres += field.zoneAcres;
    totalCost += Number(Number(field.subTotal).toFixed(2));

    subOrderDetails.push(structuredForOrder);
  });

  return { subOrderDetails, totalAcres, totalCost };
};

/* eslint-disable no-restricted-syntax */
import React, { useEffect, useState, useRef } from 'react';
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import 'leaflet-easyprint';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import * as wkt from 'terraformer-wkt-parser';
import { drawFieldLayer, drawImage } from '../../Maps/MapFunctions/helpers';
import { exists, sleep } from '../../../utils/helpers';
import { Endpoints } from '../../../constants/Endpoints';
import { Tiles } from '../../../constants/Tiles';

const countyStyle = {
  color: '#e81c62',
  weight: 2,
  opacity: 0.6,
};

/**
 * Map view for severe weather alerts.
 * @param  {Object} boundary Selected field boundary
 * @param  {Function} getSevereWeatherAlerts API call to get weather alerts
 * @param  {Array} severeWeatherAlerts    Weather alerts to display
 * @param  {Function} setExpanded Alerts to expand in accordian based on area click
 * @param  {Function} highlight Highlights text alert
 * @returns {JSX} weather map
 */
export function WeatherAlertMap({
  boundary,
  getSevereWeatherAlerts,
  severeWeatherAlerts,
  setExpanded,
  expanded,
  highlight,
  height,
  width,
  setWeatherAlertsOnMap,
  location,
}) {
  const [map, setMap] = useState(null);
  const imageOverlay = useRef(null);
  const fieldLayer = L.featureGroup(null);
  const userSelectedFeatures = L.featureGroup(null);
  const alertLayerFeatures = useRef(L.featureGroup(null));

  const [zoomLevel, setZoomLevel] = useState(6);
  const [countiesWithAlerts, setCountiesWithAlerts] = useState([]);

  const countiesForWeatherAlert = useRef(L.geoJSON(null, {
    style: countyStyle,
  }));

  useEffect(() => {
    drawMap();
  }, []);

  

  useEffect(() => {
    if (map && location === '') {
      map.setMaxZoom(17);
    }
  }, [location, map]);

  useEffect(() => {
    if (map !== null && expanded.length > 0) {
      map.eachLayer((layer) => {
        try {
          if (expanded.includes(layer.feature.properties.name[0].id)) {
            highlightLayer(layer, '#e81c62');
          }
        } catch (err) {
          // console.log(err)
        }
      });

      if (alertLayerFeatures.current !== null) {
        alertLayerFeatures.current.eachLayer((layer) => {
          if (expanded.includes(layer?.properties?.name[0]?.id)) {
            highlightLayer(layer, '#8b0000');
          }
        });
      }
    }
  }, [expanded]);

  const highlightLayer = async (layer, resetColor) => {
    layer.setStyle({
      color: '#bfea12',
      weight: 2,
      opacity: 1,
    });
    layer.bringToFront();

    await sleep(1000);

    layer.setStyle({
      color: resetColor,
      weight: 2,
      opacity: 0.6,
    });
    layer.bringToBack();
  };

  useEffect(() => {
    //console.log(severeWeatherAlerts)
    if (severeWeatherAlerts !== null && severeWeatherAlerts !== undefined && map !== null) {
      const alerts = []; 
      const ids = [];
      for (const alert of severeWeatherAlerts) {
        try {
          const fips = alert.fipsInExtent.split(',');

          for (const x of fips) {
            const fipKey = x.trim();
            const obj = {
              fips: fipKey,
              id: alert.id,
            };
            // remove occasional leading whitespace
            alerts.push(x.trim());
            ids.push(obj);
          }
        } catch (e) {
          // probably undefined
        }
      }

      const alertSet = [...new Set(alerts)];
      setCountiesWithAlerts(alertSet);
      drawCountyLayer(alertSet, ids);
    }
  }, [severeWeatherAlerts]);

  useEffect(() => {
    if (map !== null) {
      setWeatherAlertsOnMap([]);

      const bounds = map.getBounds();
      const UR = `${bounds._northEast.lng} ${bounds._northEast.lat}`;
      const LR = `${bounds._southWest.lng} ${bounds._northEast.lat}`;
      const LL = `${bounds._southWest.lng} ${bounds._southWest.lat}`;
      const UL = `${bounds._northEast.lng} ${bounds._southWest.lat}`;

      const wkt = `POLYGON((${UR},${LR},${LL},${UL},${UR}))`;
      getSevereWeatherAlerts(wkt);
    }
  }, [zoomLevel]);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };

      drawFieldLayer(geoJson, fieldLayer, map);
      setZoomLevel(12);
      map.setZoom(12);
    }
  }, [boundary, map]);

  useEffect(() => {
    if (severeWeatherAlerts !== null && map !== null) {
      drawWeatherAlertMap(severeWeatherAlerts, fieldLayer);
    }
  }, [severeWeatherAlerts, map]);

  const goTo = (alertId) => {
    // scroll alert for selected boundary in to view in dropdown
    const element = document.getElementById(alertId);
    if (element !== null) {
      element.scrollIntoView({ block: 'center' });
    }
  };

  const drawWeatherAlertMap = (result, feature) => {
    // create empty where
    const where = '';
    // create empty feature group
    alertLayerFeatures.current.clearLayers();
    map.addLayer(alertLayerFeatures.current);

    // loop through the results
    for (let i = 0; i < result.length; i++) {
      const newShape = result[i].shape;
      // if the results has a shape add it to the layer array
      if (newShape !== '') {
        const newFeat = wkt.parse(newShape);

        // add the weather polygons to the array
        const newFeature = L.GeoJSON.geometryToLayer(newFeat).setStyle({ color: '#8b0000', opacity: 0.99, fillOpacity: 0.6 });
        newFeature.type = 'Feature';
        newFeature.properties = { name: [{ id: result[i].id }] };
        newFeature.addTo(alertLayerFeatures.current);
      }
    }

    const alertsOnMap = [];
    // add click event to each shape
    alertLayerFeatures.current.eachLayer((layer) => {
      alertsOnMap.push(layer?.properties?.name[0]?.id);

      if (expanded.includes(layer?.properties?.name[0]?.id)) {
        highlightLayer(layer, '#8b0000');
      }

      layer.on('click', (e) => {
        setExpanded(e.target.properties.name.map((x) => x.id));
        goTo(e.target.properties.name[0].id);
        highlight(e.target.properties.name[0].id);

        // flash feature in highlight color
        e.target.setStyle({
          color: '#bfea12',
          weight: 2,
          opacity: 1,
        });
        if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
          e.target.bringToFront();
        }
        // then after 1000 ms return feature to previous color
        setTimeout(() => {
          e.target.setStyle({
            color: '#8b0000',
            weight: 2,
            opacity: 0.99,
            fillOpacity: 0.6,
          });
        }, 1000);
      });
    });

    setWeatherAlertsOnMap((prev) => [...prev, ...alertsOnMap]);
  };

  const getGeom = (wkt = false) => {
    const bounds = map.getBounds();
    const max = bounds.getNorthEast();
    const min = bounds.getSouthWest();

    const geomParam = {
      xmin: min.lng,
      ymin: min.lat,
      xmax: max.lng,
      ymax: max.lat,
      spatialReference: { wkid: 4326 },
    };
    if (wkt) {
      const bboxCoords = `POLYGON ((${geomParam.xmin} ${geomParam.ymin}, ${geomParam.xmax} ${geomParam.ymin}, ${
        geomParam.xmax} ${geomParam.ymax}, ${geomParam.xmin} ${geomParam.ymax},${geomParam.xmin} ${geomParam.ymin}))`;
      return bboxCoords;
    }
    return geomParam;
  };

  /**
   * Filter by counties with current weather alerts based on FIP (state and county)
   * @param  {Array}  counties All county features returned from api
   * @param  {Array} alerts  FIPS of counties with servere weather alerts
   * @param  {Array} ids County ids
   * @return {Array} Filtered county layers, but those with sever weather alerts
   */
  const filterCounties = (counties, alerts, ids) => {
    const featuresWithAlerts = [];
    console.log(counties, alerts, ids);

    // eslint-disable-next-line no-restricted-syntax
    for (const county of counties.features) {
      // state and county, like in alerts
      const completeFip = county.properties.STATEFP + county.properties.CNTY_FIPS;

      if (alerts.includes(completeFip)) {
        const fipID = ids.filter((id) => completeFip === id.fips);
        county.properties.name = fipID;
        featuresWithAlerts.push(county);
      }
    }

    // Use filtered features for layer
    // counties.features = featuresWithAlerts;
    // console.log('counties', counties);
    return counties;
  };

  const drawCountyLayer = (alerts, ids) => {
    try {
      const geom = getGeom(true);
      const type = 'envelope';
      const level = 'County';
      const qString = `?Geometry=${
        geom
      }&Geometry_type=${
        type
      }&Level=${
        level}`;
      fetch(`${Endpoints.BASEURL}/api/Boundary/GetBoundary${qString}`, {
        method: 'GET',
        headers: {
          Accept: 'application/json',
        },
      })
        .then((response) => {
          if (response.ok) {
            return response.json();
          }
        })
        .then((info) => {
          const results = JSON.parse(info);
          //console.log('results', results);
          countiesForWeatherAlert.current.clearLayers();
          if (info !== undefined && results !== undefined) {
            // const alertCounties = filterCounties(results, alerts, ids);
            // countiesForWeatherAlert.current.addData(results);
            const alertsOnMap = [];
            const alertCounties = [];
            // console.log('alerts',alerts);
            // console.log('id', ids)
            for (const item of results) {
              const temp = { ...item };
              const { shape } = item;
              const geo = wkt.parse(shape);
              const feature = { type: 'Feature', geometry: {}, properties: {} };
              feature.geometry = geo;
              delete temp.shape;
              feature.properties = { ...temp, name: [] };
              // console.log(feature)
              const completeFip = feature.properties.STATEFP + feature.properties.CNTY_FIPS;
              feature.properties.name = completeFip;
              if (alerts.includes(completeFip)) {
                const fipID = ids.filter((id) => completeFip === id.fips);
                feature.properties.name = fipID;
                alertCounties.push(feature);
                // featuresWithAlerts.push(county);
              }
            }

            countiesForWeatherAlert.current.addData(alertCounties);

            //console.log('countiesForWeatherAlert.current', countiesForWeatherAlert.current);
            // add click event to each shape
            countiesForWeatherAlert.current.eachLayer((layer) => {
              //console.log('layer', layer);
              if (expanded.includes(layer?.feature?.properties?.name[0]?.id)) {
                highlightLayer(layer, '#e81c62');
              }
              alertsOnMap.push(layer.feature.properties.name[0].id);

              layer.on('click', (e) => {
                setExpanded(e.target.feature.properties.name.map((x) => x.id));
                goTo(e.target.feature.properties.name[0].id);
                highlight(e.target.feature.properties.name[0].id);

                // flash feature in highlight color
                e.target.setStyle({
                  color: '#bfea12',
                  weight: 2,
                  opacity: 1,
                });
                if (!L.Browser.ie && !L.Browser.opera && !L.Browser.edge) {
                  e.target.bringToFront();
                }
                // then after 1000 ms return feature to previous color
                setTimeout(() => {
                  e.target.setStyle({
                    color: '#e81c62',
                    weight: 2,
                    opacity: 0.6,
                  });
                }, 1000);
              });
            });
            // console.log('countiesForWeatherAlert.current', countiesForWeatherAlert.current);
            map.addLayer(countiesForWeatherAlert.current);
            setWeatherAlertsOnMap((prev) => [...prev, ...alertsOnMap]);
          }
        }).catch((error) => console.log(error));
    } catch (err) {
      // console.log(`Failed to draw county layer, error: ${err}`);
    }
  };

  const drawMap = async () => {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('weather-alert-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], zoomLevel)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current'],
    }).addTo(map);

    map.on('zoomend', (e) => {
      const currentZoom = map.getZoom();
      setZoomLevel(currentZoom);
    });

    setMap(map);
    map.addLayer(fieldLayer);
  };

  return (
    <div
      id="weather-alert-map"
      style={{
        height,
        minHeight: 500,
        width,
        borderRadius: 2,
      }}
    />
  );
}

import React, { useState, useEffect } from "react";
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font,
  Image,
  Svg,
  Circle,
  Link
} from "@react-pdf/renderer";
//import Logo from "../../../../images/Logo.png";
import {
  COLORS,
  DOLLAR_FORMAT,
  SINGLE_DECIMAL_FORMAT,
  PERCENT_FORMAT,
} from "../../../constants";
import RobotoRegular from "../../../assets/fonts/Roboto-Regular.ttf";
import RobotoBold from "../../../assets/fonts/Roboto-Bold.ttf";
import RobotoBlack from "../../../assets/fonts/Roboto-Black.ttf";
import AgLogo from '../../../images/AgAnalyticsLogo_Black_Inline_Lower_Powered1.png'
import Logo from '../../../images/ProfitLayers_Full_Gradient.png'

Font.register({
  family: "Roboto",
  fonts: [
    { src: RobotoRegular, fontWeight: 400 },
    { src: RobotoBold, fontWeight: 700 },
    { src: RobotoBlack, fontWeight: 900 },
  ],
  format: "truetype",
});

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    paddingHorizontal: 100,
    paddingVertical: 40,
    fontFamily: "Roboto",
    fontWeight: 400,
  },
  topBar: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    height: 50,
    backgroundColor: COLORS.veryLightGrey,
    marginBottom: 32,
    padding: 8,
  },
  topLeft: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: "100%",
  },
  topRight: {
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
  },
  logo: {
    height: 'auto',
    width: '150px',
    // width: '112.5pt',
  },
  reportHeader: {
    color: COLORS.darkGray,
    fontSize: 14,
    fontWeight: 700,
    paddingLeft: 8,
  },
  generate: {
    fontSize: 9,
    color: COLORS.gray,
    fontWeight: 700,
    marginBottom: 2,
  },
  date: {
    fontSize: 11,
    color: COLORS.darkGray,
    fontWeight: 700,
  },
  organization: {
    color: "#b3b3b3",
    fontSize: 9,
    fontWeight: 700,
    textTransform: "uppercase",
    paddingBottom: 2,
  },
  organizationName: {
    color: "#777777",
    fontSize: 12,
    fontWeight: 900,
  },
  farmName: {
    color: COLORS.green,
    fontSize: 24,
    marginVertical: 16,
  },
  profitRow: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 65,
    opacity: 0.75,
    marginBottom: 24,
  },
  profitRowLeft: {
    flex: 1,
    backgroundColor: COLORS.lightGreen,
    marginRight: 1,
    height: 65,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 11,
    color: COLORS.text,
  },
  profitRowNum: {
    fontWeight: 700,
  },
  profitRowText: {
    marginTop: 4,
  },
  profitRowRight: {
    flex: 1,
    backgroundColor: COLORS.lightGreen,
    marginLeft: 1,
    height: 65,
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
    fontSize: 11,
    color: COLORS.green,
    opacity: 1,
    position: "relative",
  },
  profitRowRightContent: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-start",
    alignItems: "center",
    marginLeft: 15,
  },
  profitPercent: {
    fontWeight: 700,
    fontSize: 16,
  },
  table: {
    fontSize: 10,
    width: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexWrap: "nowrap",
  },
  row: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-around",
    paddingLeft: 8,
  },
  rowEven: {
    backgroundColor: COLORS.veryLightGrey,
  },
  rowOdd: {
    backgroundColor: COLORS.white,
  },
  cell: {
    width: "20%",
  },
  header: {
    backgroundColor: COLORS.white,
    borderStyle: "solid",
    borderTopColor: COLORS.lightGray,
    borderTopWidth: 1,
    borderBottomColor: COLORS.green,
    borderBottomWidth: 2,
    marginBottom: 2,
  },
  headerText: {
    fontSize: 9,
    fontWeight: 700,
    color: COLORS.text,
    margin: 8,
  },
  tableText: {
    margin: 12,
    fontSize: 9,
    color: COLORS.darkGray,
  },
  summary: {
    borderStyle: "solid",
    borderTopColor: COLORS.green,
    borderTopWidth: 2,
    marginTop: 2,
    marginBottom: 24,
  },
  fieldName: {
    color: COLORS.green,
    fontSize: 18,
    marginBottom: 16,
  },
  categoryName: {
    color: COLORS.green,
    fontSize: 14,
    fontWeight: 700,
    borderStyle: "solid",
    borderBottomColor: COLORS.green,
    borderBottomWidth: 2,
    paddingBottom: 8,
  },
  svgWrapper: {
    width: 50,
    height: 50,
    position: "absolute",
    top: 8,
    left: 45,
  },
  svg: {
    height: "100%",
    width: "100%",
    transform: "rotate(90deg)",
  },
  circle: {
    stroke: COLORS.green,
    strokeWidth: 8,
  },
  svgWrapperBase: {
    width: 50,
    height: 50,
    zIndex: -1,
    position: "relative",
  },
  circleBase: {
    stroke: COLORS.white,
    strokeWidth: 8,
  },
  bottomBar: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    height: 50,
    backgroundColor: COLORS.veryLightGrey,
    padding: 8,
  },
  bottomBarText: {
    fontSize: 10,
    color: COLORS.text,
  },
  link: {
    color: COLORS.green,
  },
  footerLogo: {
    position: 'absolute',
    bottom: 30,
    left: 20,
    width: '91px',
    // width: '68.25pt',
    height: 'auto',
  },
  footerLink: {
    position: 'absolute',
    bottom: 35,
    left: 130,
    fontSize: 8,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 12,
    bottom: 30,
    left: 0,
    right: 0,
    textAlign: 'center',
    color: 'grey',
  },
});

/**
 * Renders a PDF report for the provided fields. This is currently rendered and used in the FarmReport's OverviewTableSummary
 * @param {Array} headerCells defined header cells for the report (totals, fieldname, primary crop, etc.)
 * @param {Array} selectedFields array of fields being included in PDF report
 * @param {Object} activeFarm object containing farm data
 * @param {Int} activeYear currently selected year
 * @param {Object} fieldHeaderCells defined header cells for the fields in the report
 * @param {Array} data list of all operation data for all included fields
 * @param {Object} selectedOrg object containing organization data
 */
const PrintFarmReport = ({
  headerCells,
  selectedFields,
  activeFarm,
  activeYear,
  fieldHeaderCells,
  data,
  selectedOrg,
}) => {
  //remove last column that is checkbox
  const farmHeaders = headerCells.slice(0, -1);
  const categories = Object.keys(fieldHeaderCells);
  let date = new Date();
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    hour12: true,
  };

  const dateArray = date.toLocaleString("en-US", options).split(",");
  const dateFormatted = `${dateArray[0]},${dateArray[1]} at${dateArray[2]}`;

  return (
    <Document>
      <Page style={styles.page} size="A4" orientation="landscape" wrap>
        <View style={styles.topBar}>
          <View style={styles.topLeft}>
            <Image style={styles.logo} src={Logo} />
            <Text style={styles.reportHeader}>Detailed Farm Report</Text>
          </View>
          <View style={styles.topRight}>
            <Text style={styles.generate}>Generated on</Text>
            <Text style={styles.date}>{dateFormatted}</Text>
          </View>
        </View>
        <View>
          <Text style={styles.organization}>Organization</Text>
          <Text style={styles.organizationName}>{selectedOrg.Name}</Text>
        </View>
        <Text
          style={styles.farmName}
        >{`${activeFarm.name} ${activeYear} Report`}</Text>

        {!selectedFields.length ? (
          <Text style={styles.tableText}>Please select some fields.</Text>
        ) : (
          <>
            {/* Table one: the fields */}
            <View style={styles.table}>
              {/* headers for farm */}
              <View style={[styles.row, styles.header]}>
                {farmHeaders.map((header) => (
                  <Text
                    key={header.heading}
                    style={[styles.headerText, styles.cell]}
                  >
                    {header.heading}
                  </Text>
                ))}
              </View>
              <View>
                {/* rows for each field */}
                {selectedFields.map((field, i) => (
                  <View
                    key={`field-${i}`}
                    style={
                      i === 0 || i % 2 === 0
                        ? [styles.row, { backgroundColor: COLORS.lightGray }]
                        : [styles.row, { backgroundColor: COLORS.white }]
                    }
                  >
                    {farmHeaders.map((header, j) => (
                      <Text
                        key={`cell-${i}-${j}`}
                        style={[styles.cell, styles.tableText]}
                      >
                        {header.format
                          ? header.format(header.accessor(field))
                          : header.accessor(field)}
                      </Text>
                    ))}
                  </View>
                ))}
                <View
                  style={[styles.row, styles.summary, { marginBottom: 48 }]}
                >
                  {/* summary row */}
                  {farmHeaders.map(
                    ({ aggregateFunction, format, accessor }, i) => {
                      const aggregateValue =
                        aggregateFunction &&
                        aggregateFunction(selectedFields, accessor);
                      return (
                        <Text
                          key={`summaryCell-${i}`}
                          style={[styles.cell, styles.headerText]}
                        >
                          {!i
                            ? "Summary:"
                            : format
                            ? format(aggregateValue)
                            : aggregateValue}
                        </Text>
                      );
                    }
                  )}
                </View>
              </View>
            </View>

            {/* //Next Section: each field */}
            {selectedFields.map((field, i) => (
              <View key={`${field.name}-${i}`}>
                {categories.map((category, i) => {
                  let rows = [];

                  if(data !== null){
                    let fieldValues = data.find(
                      (d) => d.category === category && d.fieldId === field.id
                    );                 
                    rows =
                      fieldValues === undefined ? [] : fieldValues.values;
                  }
                  
                  return (
                    <View key={`${field.name}-category-${i}`} wrap={false}>
                      {/* putting field name inside category section so that 
                        it wraps with section and is not orphaned on page */}
                      {i === 0 && (
                        <View>
                          <Text style={styles.fieldName}>{field.name}</Text>
                          {/* profit row */}
                          <View style={styles.profitRow}>
                            <View style={styles.profitRowLeft}>
                              <Text
                                style={styles.profitRowNum}
                              >{`${SINGLE_DECIMAL_FORMAT(field.acres)} `}</Text>
                              <Text>{`acres yielded profits of ${DOLLAR_FORMAT(
                                field.profit
                              )}`}</Text>
                            </View>
                            {(field.unprofitablePercent !== null && field.unprofitablePercent !== undefined) &&
                              <View style={styles.profitRowRight}>
                                <View style={styles.svgWrapperBase}>
                                  <Svg style={styles.svg} viewBox="0 0 48 48">
                                    <Circle
                                      style={styles.circleBase}
                                      r="16"
                                      cx="24"
                                      cy="24"
                                      strokeDasharray={`${1 * 100}, 100`}
                                    />
                                  </Svg>
                                </View>
                                <View style={styles.svgWrapper}>
                                  <Svg style={styles.svg} viewBox="0 0 48 48">
                                    <Circle
                                      style={styles.circle}
                                      r="16"
                                      cx="24"
                                      cy="24"
                                      strokeDasharray={`${field.unprofitablePercent}, 100`}
                                    />
                                  </Svg>
                                </View>
                                <View style={styles.profitRowRightContent}>
                                  <Text style={styles.profitPercent}>{`${
                                    field.unprofitablePercent + "% "
                                  }`}</Text>
                                  <Text style={styles.profitRowText}>
                                    of this field is unprofitable.
                                  </Text>
                                </View>
                              </View>
                          
                            }
                            
                          </View>
                        </View>
                      )}

                      <Text style={[styles.categoryName, { width: "15%" }]}>
                        {category}
                      </Text>
                      
                      <View style={styles.table}>
                        {/* category headers */}
                        <View style={[styles.row, styles.header]}>
                          {fieldHeaderCells[category].map((header, i) => (
                            <Text
                              key={`header-${category}-${field.name}-${i}`}
                              style={[styles.headerText, styles.cell]}
                            >
                              {header.heading}
                            </Text>
                          ))}
                        </View>

                        <View>
                          {/* data rows */}
                          {rows.map((row, i) => (
                            <View
                              key={`${field.name}-${category}-row-${i}`}
                              style={
                                i === 0 || i % 2 === 0
                                  ? [
                                      styles.row,
                                      { backgroundColor: COLORS.lightGray },
                                    ]
                                  : [
                                      styles.row,
                                      { backgroundColor: COLORS.white },
                                    ]
                              }
                            >
                              {fieldHeaderCells[category].map((headerCell, j) =>                              
                                {
                                  const matchingValue = headerCell.accessor(row);
                                  // const unit = headerCell.unitAccessor
                                  //   ? headerCell.unitAccessor(row)
                                  //   : null;
                                  return (
                                    <Text
                                    key={`${field.name}-${category}-row-${i}-${j}`}
                                    style={[styles.cell, styles.tableText]}
                                  >
                                      {headerCell.format
                                        ? headerCell.format(matchingValue)
                                        : matchingValue}
                                      {headerCell.unit !== null && typeof headerCell.unit !== "string"
                                        ? headerCell.unit(row)
                                        : headerCell.unit !== null
                                        ? headerCell.unit
                                        : ""}
                                    </Text>
                                  );
                                })}
                            </View>
                          ))}
                          {/* summary row */}
                          {rows.length > 0 &&
                            <View
                              style={
                                i === categories.length - 1
                                  ? [
                                      styles.row,
                                      styles.summary,
                                      { marginBottom: 36 },
                                    ]
                                  : [styles.row, styles.summary]
                              }
                            >
                              {fieldHeaderCells[category].map(
                                (
                                  { aggregateFunction, format, accessor },
                                  k
                                ) => {
                                  const aggregateValue =
                                    aggregateFunction &&
                                    aggregateFunction(rows, accessor)
                                    
                                  return (
                                    <Text
                                      key={`${field.name}-${category}-summaryCell-${k}`}
                                      style={[styles.cell, styles.headerText]}
                                    >
                                      {!k
                                        ? "Summary:"
                                        : format !== null
                                        ? format(aggregateValue)
                                        : aggregateValue}
                                    </Text>
                                  );
                                }
                              )}
                            </View>
                          }
                          
                        </View>
                      
                      </View>
                    
                    </View>
                  );
                })}
              </View>
            ))}
          </>
        )}
        <View style={styles.bottomBar}>
          <Text style={styles.bottomBarText}>
            Have questions about this report?
          </Text>
          <Text style={styles.bottomBarText}>
            Contact us at{" "}
            <a href="mailto:support@analytics.ag" style={styles.link}>
              support@analytics.ag
            </a>
          </Text>
        </View>
      
        { AgLogo !== null && (
          <Image src={AgLogo} data-img={AgLogo} style={styles.footerLogo} fixed />
        )}

        <Link style={styles.footerLink} src={'https://profit.ag'} fixed>
          profit.ag
        </Link>

        <Text style={styles.pageNumber} render={({ pageNumber, totalPages }) => (
          `${pageNumber} / ${totalPages}`
        )} fixed />

      </Page>
    </Document>
  );
};

export default PrintFarmReport;

import React, { useState, useEffect } from 'react';
import { Box, Modal, Button, Switch, Collapse } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { CreateSsurgoTable } from '../../DataLayers/SSURGO/ssurgoTable'
import Draggable from 'react-draggable';
import { sleep } from '../../../utils/helpers'


const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.primary,
    left: 4,
    borderRadius: 4,
    zIndex: 1200,
  },
  header: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
       cursor: 'pointer',
    },
  }
}))

export function SsurgoTable({
  average,
  legend,
  fieldAcres,
  maxHeight,
  display,
  setShowSsurgoTable,
}) {
  const classes = useStyles();
  const [mobileView, setMobileView] = useState(false);

  useEffect(() => {
    if (window.matchMedia('only screen and (max-width: 760px)').matches) {
      setMobileView(true);
    }
  }, []);

  const handleChange = async (checked) => {
    if (checked) {
      setShowSsurgoTable('table')
    } else {
      setShowSsurgoTable('option')
    }
  };

  const table = () => (
    <Box
      className={classes.paper}
      style={{
        maxHeight: maxHeight,
        width: 'auto',
        overflow: 'auto',
        top: mobileView ? 140 : 190,
        left: mobileView ? 4 : 50,
      }}
    >
      <Box className={classes.header}>
        <Switch
          color="primary"
          checked={display === 'table'}
          onChange={ (e) => handleChange(e.target.checked) }
          inputProps={{ 'aria-label': 'table toggle' }}
        />
        <Box mr={1}>
          Soil Table
        </Box>
      </Box>

      <Collapse
        in={display === 'table'}
        mountOnEnter={true}
        unmountOnExit={true}
      >
        <Box style={{maxWidth: window.innerWidth -5, overflow: 'auto' }}>
          <CreateSsurgoTable
            average={average}
            legend={legend}
            fieldAcres={fieldAcres}
            minWidth={200}
          />
        </Box>
      </Collapse>
    </Box>
  )

  return (

    mobileView ? (
      table()
    ) : (
      <Draggable>
        { table() }
      </Draggable>
    )
  )
}

/* eslint-disable react/prop-types */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
/* eslint-disable default-case */
import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Divider,
  Container,
  Button,
  FormControl,
  TextField,
  Modal,
  Fade,
  Stepper,
  Step,
  StepLabel,
  StepContent,
  IconButton,
} from '@material-ui/core/';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import PublishIcon from '@material-ui/icons/Publish';
import DateFnsUtils from '@date-io/date-fns';
import {
  KeyboardDatePicker,
  MuiPickersUtilsProvider,
} from '@material-ui/pickers';
import { useSnackbar } from 'notistack';
import {
  Accordion, AccordionDetails, AccordionSummary, CustomToolTip,
} from '../../utils/customComponents';
import { useWindowDimensions } from '../../utils/dimensions';
import { blackText, darkGrey, lightGrey } from '../../styles/colors';
import { SpinningLoader } from '../Shared/SpinningLoader';
import * as df from '../../utils/dataFetchers';

const lightBlue = '#007cba';
const greyBlue = '#006ba1';

const useStyles = makeStyles((theme) => ({
  logo: {
    width: 'auto',
    margin: '0 30px',
  },
  aglogo: {
    width: 'auto',
    margin: '0 30px',
  },
  square: {
    fontSize: '12px',
    margin: '3px 3px 0 0',
    color: lightBlue,
  },
  select: {
    display: 'flex',
    alignItems: 'center',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  product: {
    minWidth: 200,
    padding: '8px',
    margin: '16px',
    fontWeight: 500,
    borderColor: darkGrey,
    textAlign: 'center',
    '&:hover': {
      cursor: 'pointer',
      borderColor: greyBlue,
    },
  },
  package: {
    maxWidth: '832px',
    padding: '8px',
    margin: 8,
    fontWeight: 500,
    borderColor: darkGrey,
    '&:hover': {
      cursor: 'pointer',
      borderColor: greyBlue,
    },
  },
  header: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    fontSize: 18,
  },
  link: {
    color: lightBlue,
    '&:hover': {
      cursor: 'pointer',
      color: greyBlue,
    },
  },
  formControl: {
    margin: theme.spacing(2),
    minWidth: 250,
  },
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
  },
  buttonHelperText: {
    fontSize: '.75rem',
    fontWeight: 400,
    color: '#767676',
    marginTop: 3,
  },
  dot: {
    fontSize: '.8rem',
    margin: '4px',
  },
  learnMore: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    padding: 8,
    border: `2px solid ${theme.palette.primary.main}`,
    borderRadius: 8,
  },
  infoIcon: theme.infoToolTip,
}));

const roundIcon = {
  width: '20px',
  height: '20px',
  fontSize: '14px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  border: 2,
  borderRadius: '50%',
  marginRight: '10px',
};

/**
 * Vendor Response page
 * @param {Function} setHomeSection Sets nav bar highlight
 * @returns {JSX} Vendor Response page
 */
export function VendorResponse({ setHomeSection }) {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const { enqueueSnackbar } = useSnackbar();

  // Client Details
  const [clientkey, setClientKey] = useState('');
  const [clientSecret, setClientSecret] = useState('');
  const [clientName, setClientName] = useState('');

  // OrderDetails
  const [orderId, setOrderId] = useState('');
  const [orderName, setOrderName] = useState('');
  const [completedDate, setCompletedDate] = useState(new Date());
  const [orderCompletedLink, setOrderCompletedLink] = useState('');
  const [orgId, setOrgId] = useState('');
  const [orgName, setOrgName] = useState('');
  const [totalAcres, setTotalAcres] = useState(0);
  const [totalCost, setTotalCost] = useState(0);
  const [totalFlightCost, setTotalFlightCost] = useState(0);
  const [flightCostPerAcre, setFlightCostPerAcre] = useState(0);
  const [farmerDetails, setFarmerDetails] = useState({
    fullName: '',
    eMail: '',
    phone: '',
    mailingAddress: '',
    city: '',
    state: '',
    zipCode: '',
  });
  const [subOrderDetails, setSuborderDetails] = useState([{
    id: 1,
    subOrderId: '',
    fieldId: '',
    fieldName: '',
    acres: 0,
    XYCoordinates: '',
    reports: '',
    costPerAcre: '',
    summary: [{
      id: 1,
      reportName: '',
      reportSummary: [{
        id: 1,
        title: '',
        description: '',
      }],
    }],
    tiffImages: [{
      id: 1,
      bandName: '',
      tiffLink: '',
    }],
    previewImages: [{
      id: 1,
      base64: '',
      mapExtent: '',
    }],
    pdfLink: '',
    shapefiles: '',
    interactiveAppLink: '',
    operator: '',
    operatorEmail: '',
    operatorPhone: '',
    operatorCompany: '',
    resolution: '',
    equipmentType: '',
    equipmentBrand: '',
    fieldImage: '',
    subTotal: 0,
    state: '',
    county: '',
    digsReports: '',
    sampleZones: '',
    package: '',
    boundary: '',
  }]);
  const [show, setShow] = useState(false);
  const [displayMessage, setDisplayMessage] = useState('');
  const [file, setFile] = useState(null);
  const [showLoader, setShowLoader] = useState(false);

  useEffect(() => {
    setHomeSection(-1);
  }, [setHomeSection]);

  /* Suborder details section start
    1. addNewForm() => to add new subOrderDetails details in subOrderDetails state
    2. subOrderDetailsForm() => creates new textboxes in form
    3. handleSuborderDetials() => to edit/modify suborder details form data
  */
  const addNewForm = () => {
    setSuborderDetails([...subOrderDetails, {
      id: subOrderDetails.length + 1,
      subOrderId: '',
      fieldId: '',
      fieldName: '',
      acres: 0,
      XYCoordinates: '',
      reports: '',
      costPerAcre: '',
      summary: [{
        id: '',
        reportName: '',
        reportSummary: [{
          id: '',
          title: '',
          description: '',
        }],
      }],
      tiffImages: [{
        id: 1,
        bandName: '',
        tiffLink: '',
      }],
      previewImages: [{
        id: 1,
        base64: '',
        mapExtent: '',
      }],
      pdfLink: '',
      shapefiles: '',
      interactiveAppLink: '',
      operator: '',
      operatorEmail: '',
      operatorPhone: '',
      operatorCompany: '',
      resolution: '',
      equipmentType: '',
      equipmentBrand: '',
      fieldImage: '',
      subTotal: 0,
      state: '',
      county: '',
      digsReports: '',
      sampleZones: '',
      package: '',
      boundary: '',
    }]);
  };

  const subOrderDetailsForm = (x) => (

    <Accordion key={x.id}>
      <AccordionSummary
        expandIcon={<ExpandMoreIcon />}
        aria-controls={`panel${x.id}content`}
        id={`panel${x.id}header`}
      >
        <Box fontWeight="bold">
          Suborder
          {x.id}
        </Box>
      </AccordionSummary>
      <AccordionDetails>
        <div style={{ margin: '15px' }}>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl}>
                <TextField
                  required
                  variant="outlined"
                  label="Suborder ID"
                  onChange={(e) => handleSuborderDetials(x.id, 'subOrderId', e.target.value)}
                  value={x.subOrderId}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  required
                  variant="outlined"
                  label="Field ID"
                  onChange={(e) => handleSuborderDetials(x.id, 'fieldId', e.target.value)}
                  value={x.fieldId}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  label="Field Name"
                  variant="outlined"
                  onChange={(e) => handleSuborderDetials(x.id, 'fieldName', e.target.value)}
                  value={x.fieldName}

                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  label="Field Acres"
                  variant="outlined"
                  onChange={(e) => handleSuborderDetials(x.id, 'acres', e.target.value)}
                  value={x.acres}
                />
              </FormControl>
            </form>
          </Box>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ minWidth: '75%' }}>
                <TextField
                  multiline
                  variant="outlined"
                  label="Field Boundary"
                  onChange={(e) => handleSuborderDetials(x.id, 'boundary', e.target.value)}
                  value={x.boundary}
                  helperText="Field boundary GeoJSON. For exact boundary, please refer to original order"
                />
              </FormControl>
            </form>
          </Box>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ minWidth: '75%' }}>
                <TextField
                  variant="outlined"
                  label="Field Image"
                  onChange={(e) => handleSuborderDetials(x.id, 'fieldImage', e.target.value)}
                  value={x.fieldImage}
                />
              </FormControl>
            </form>

          </Box>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Reports"
                  onChange={(e) => handleSuborderDetials(x.id, 'reports', e.target.value)}
                  value={x?.reports ? x.reports : ''}
                  helperText="To inlude multiple reports, add as comma separated string (optional)"
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  multiline
                  variant="outlined"
                  label="Cost Per Acre"
                  onChange={(e) => handleSuborderDetials(x.id, 'costPerAcre', e.target.value)}
                  value={x.costPerAcre}
                  helperText="To inlude multiple costs, add as comma separated string (optional)"
                />
              </FormControl>
            </form>
          </Box>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ minWidth: '75%' }}>
                <TextField
                  multiline
                  variant="outlined"
                  label="PDF Links"
                  onChange={(e) => handleSuborderDetials(x.id, 'pdfLink', e.target.value)}
                  value={x?.pdfLink ? x.pdfLink : ''}
                  helperText="To inlude multiple PDF links, add as comma separated string (optional)"
                />
              </FormControl>
            </form>
          </Box>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ minWidth: '75%' }}>
                <TextField
                  multiline
                  variant="outlined"
                  label="Shapefile Links"
                  onChange={(e) => handleSuborderDetials(x.id, 'shapefiles', e.target.value)}
                  value={x?.shapefiles ? x.shapeFiles : ''}
                  helperText="To inlude multiple shapefile links, add as comma separated string (optional)"
                />
              </FormControl>
            </form>
          </Box>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ minWidth: '75%' }}>
                <TextField
                  multiline
                  variant="outlined"
                  label="Package"
                  onChange={(e) => handleSuborderDetials(x.id, 'package', e.target.value)}
                  value={x?.package ? x.package : ''}
                  helperText="Currently being used for soil testing results (Optional)"
                />
              </FormControl>
            </form>
          </Box>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ minWidth: '75%' }}>
                <TextField
                  variant="outlined"
                  label="Interactive Map Link"
                  onChange={(e) => handleSuborderDetials(x.id, 'interactiveAppLink', e.target.value)}
                  value={x?.interactiveAppLink ? x.interactiveAppLink : ''}
                  helperText="Currently being used for UAV results (Optional)"
                />
              </FormControl>
            </form>
          </Box>
          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl} style={{ minWidth: '75%' }}>
                <TextField
                  multiline
                  rowsMax={10}
                  variant="outlined"
                  label="Sample Zones"
                  onChange={(e) => handleSuborderDetials(x.id, 'sampleZones', e.target.value)}
                  value={x?.sampleZones ? x.sampleZones : ''}
                  helperText="To inlude multiple zones, add as comma separated string and being used in soil testing results (optional)"
                />
              </FormControl>
            </form>
          </Box>

          <Box p={1}>
            <Box fontWeight="bold">
              TIFF Links
              <Button variant="outlined" color="primary" onClick={() => addNewTiffForm(x.id)} style={{ margin: '10px' }}>
                + Add TIFF Link
              </Button>
              <Box className={classes.buttonHelperText}>
                Currently being used for UAV results (Optional)
              </Box>
            </Box>
            {x?.tiffImages?.map((t) => (
              tiffImagesForm(x.id, t)
            ))}
          </Box>

          <Box p={1}>
            <Box fontWeight="bold">
              Preview Images
              <Button variant="outlined" color="primary" onClick={() => addNewPreviewImage(x.id)} style={{ margin: '10px' }}>
                + Add Preview Image
              </Button>
              <Box className={classes.buttonHelperText}>
                Currently being used for UAV results (Optional)
              </Box>
            </Box>
            {x?.previewImages?.map((t, i) => (
              previewImagesForm(x.id, t)
            ))}
          </Box>

          <Box p={1}>
            <Box fontWeight="bold">
              Report Summary
              <Button variant="outlined" color="primary" onClick={() => addNewReportSummary(x.id)} style={{ margin: '10px' }}>
                + Add Report Summary
              </Button>
            </Box>
            {x?.summary?.map((t) => (
              reportSummaryForm(x.id, t)
            ))}
          </Box>

          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Operator Name"
                  onChange={(e) => handleSuborderDetials(x.id, 'operator', e.target.value)}
                  value={x?.operator ? x.operator : ''}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Operator Email"
                  onChange={(e) => handleSuborderDetials(x.id, 'operatorEmail', e.target.value)}
                  value={x?.operatorEmail ? x.operatorEmail : ''}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Operator Phone"
                  onChange={(e) => handleSuborderDetials(x.id, 'operatorPhone', e.target.value)}
                  value={x?.operatorPhone ? x.operatorPhone : ''}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Operator Company"
                  onChange={(e) => handleSuborderDetials(x.id, 'operatorCompany', e.target.value)}
                  value={x?.operatorCompany ? x.operatorCompany : ''}
                />
              </FormControl>
            </form>
          </Box>

          <Box>
            <form className={classes.root} noValidate autoComplete="off">
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Resolution"
                  onChange={(e) => handleSuborderDetials(x.id, 'resolution', e.target.value)}
                  value={x?.resolution ? x.resolution : ''}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Equipment Type"
                  onChange={(e) => handleSuborderDetials(x.id, 'equipmentType', e.target.value)}
                  value={x?.equipmentType ? x.equipmentType : ''}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Equipment Brand"
                  onChange={(e) => handleSuborderDetials(x.id, 'equipmentBrand', e.target.value)}
                  value={x?.equipmentBrand ? x.equipmentBrand : ''}
                />
              </FormControl>

              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  label="Subtotal"
                  onChange={(e) => handleSuborderDetials(x.id, 'subTotal', e.target.value)}
                  value={x?.subTotal ? x.subTotal : ''}
                />
              </FormControl>
            </form>
          </Box>

          <Box>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => handleCloseSuborder(x.id)}
              style={{ float: 'right' }}
            >
              Delete Suborder
            </Button>
          </Box>
        </div>

      </AccordionDetails>
    </Accordion>

  );

  const handleSuborderDetials = (id, key, value) => {
    let subOrderSelected = subOrderDetails.filter((s) => s.id === id)[0];
    const otherSubs = subOrderDetails.filter((s) => s.id !== id);

    switch (key) {
      case 'subOrderId':
        subOrderSelected = {
          ...subOrderSelected, subOrderId: value,
        };
        break;
      case 'fieldId':
        subOrderSelected = {
          ...subOrderSelected, fieldId: value,
        };
        break;
      case 'fieldName':
        subOrderSelected = {
          ...subOrderSelected, fieldName: value,
        };
        break;
      case 'acres':
        subOrderSelected = {
          ...subOrderSelected, acres: value,
        };
        break;
      case 'county':
        subOrderSelected = {
          ...subOrderSelected, county: value,
        };
        break;
      case 'boundary':
        subOrderSelected = {
          ...subOrderSelected, boundary: value,
        };
        break;
      case 'state':
        subOrderSelected = {
          ...subOrderSelected, state: value,
        };
        break;
      case 'fieldImage':
        subOrderSelected = {
          ...subOrderSelected, fieldImage: value,
        };
        break;
      case 'reports':
        subOrderSelected = {
          ...subOrderSelected, reports: value,
        };
        break;
      case 'costPerAcre':
        subOrderSelected = {
          ...subOrderSelected, costPerAcre: value,
        };
        break;
      case 'pdfLink':
        subOrderSelected = {
          ...subOrderSelected, pdfLink: value,
        };
        break;
      case 'shapefiles':
        subOrderSelected = {
          ...subOrderSelected, shapefiles: value,
        };
        break;
      case 'interactiveAppLink':
        subOrderSelected = {
          ...subOrderSelected, interactiveAppLink: value,
        };
        break;
      case 'package':
        subOrderSelected = {
          ...subOrderSelected, package: value,
        };
        break;
      case 'sampleZones':
        subOrderSelected = {
          ...subOrderSelected, sampleZones: value,
        };
        break;
      case 'operator':
        subOrderSelected = {
          ...subOrderSelected, operator: value,
        };
        break;
      case 'operatorEmail':
        subOrderSelected = {
          ...subOrderSelected, operatorEmail: value,
        };
        break;
      case 'operatorPhone':
        subOrderSelected = {
          ...subOrderSelected, operatorPhone: value,
        };
        break;
      case 'operatorCompany':
        subOrderSelected = {
          ...subOrderSelected, operatorCompany: value,
        };
        break;
      case 'resolution':
        subOrderSelected = {
          ...subOrderSelected, resolution: value,
        };
        break;
      case 'equipmentType':
        subOrderSelected = {
          ...subOrderSelected, equipmentType: value,
        };
        break;
      case 'equipmentBrand':
        subOrderSelected = {
          ...subOrderSelected, equipmentBrand: value,
        };
        break;
      case 'subTotal':
        subOrderSelected = {
          ...subOrderSelected, subTotal: value,
        };
        break;
    }

    const subOrders = [...otherSubs, subOrderSelected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const handleCloseSuborder = (subId) => {
    if (subOrderDetails.length > 1) {
      const subOrderDetailsClone = JSON.parse(JSON.stringify(subOrderDetails));
      subOrderDetailsClone.splice(subId - 1, 1);

      for (let i = 0; i < subOrderDetailsClone.length; i++) {
        subOrderDetailsClone[i].id = i + 1;
      }

      subOrderDetailsClone.sort((a, b) => a.id - b.id);
      setSuborderDetails(subOrderDetailsClone);
    } else {
      enqueueSnackbar('Vendor order need to have atleast 1 suborder');
    }
  };
  /* Suborder detials section end */

  /* TIFF Section Start
     1. addNewTiffForm() => to add new tiff details in subOrderDetails state
     2. tiffImagesForm() => creates new textboxes in form
     3. handleTIFFDetails() => to edit/modify tiff form data
  */
  const addNewTiffForm = (subId) => {
    let selectedSub = subOrderDetails.filter((x) => x.id === subId)[0];
    const other = subOrderDetails.filter((x) => x.id !== subId);

    const newTiff = {
      id: selectedSub.tiffImages.length + 1,
      bandName: '',
      tiffLink: '',
    };
    const tiffLinks = [...selectedSub.tiffImages, newTiff];
    selectedSub = {
      ...selectedSub,
      tiffImages: tiffLinks,
    };

    const subOrders = [...other, selectedSub];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const tiffImagesForm = (id, x) => (
    <div key={x.id} style={{ margin: '15px' }}>
      <Box border={1} padding={2}>
        <form className={classes.root} noValidate autoComplete="off">
          <FormControl className={classes.formControl}>
            <TextField
              variant="outlined"
              label="Band Name"
              onChange={(e) => handleTIFFDetails(id, x.id, 'bandName', e.target.value)}
              value={x.bandName}
            />
          </FormControl>

          <FormControl className={classes.formControl} style={{ minWidth: 500 }}>
            <TextField
              variant="outlined"
              label="TIFF Link"
              onChange={(e) => handleTIFFDetails(id, x.id, 'tiffLink', e.target.value)}
              value={x.tiffLink}
            />
          </FormControl>
          <IconButton
            color="primary"
            onClick={() => deleteTiffs(id, x.id)}
          >
            <HighlightOffIcon />
          </IconButton>

        </form>
      </Box>
    </div>
  );

  const handleTIFFDetails = (subId, tifId, key, value) => {
    let subOrderSelected = subOrderDetails.filter((s) => s.id === subId)[0];
    const otherSubs = subOrderDetails.filter((s) => s.id !== subId);

    let editedTiff = subOrderSelected.tiffImages.filter((s) => s.id === tifId)[0];
    const otherTiff = subOrderSelected.tiffImages.filter((s) => s.id !== tifId);

    switch (key) {
      case 'bandName':
        editedTiff = {
          ...editedTiff, bandName: value,
        };
        break;
      case 'tiffLink':
        editedTiff = {
          ...editedTiff, tiffLink: value,
        };
        break;
    }
    const tiffs = [...otherTiff, editedTiff];
    tiffs.sort((a, b) => a.id - b.id);
    subOrderSelected = {
      ...subOrderSelected, tiffImages: tiffs,
    };
    const subOrders = [...otherSubs, subOrderSelected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const deleteTiffs = (subId, id) => {
    const selected = subOrderDetails.filter((x) => x.id == subId)[0];
    const other = subOrderDetails.filter((x) => x.id !== subId);

    const tiffs = [...selected.tiffImages];
    tiffs.splice(id - 1, 1);
    for (let i = 0; i < tiffs.length; i++) {
      tiffs[i].id = i + 1;
    }
    tiffs.sort((a, b) => a.id - b.id);
    selected.tiffImages = tiffs;
    const subOrders = [...other, selected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };
  /* TIFF Section End */

  /* Preview Images Section Start
     1. addNewPreviewImage() => to add new preview image details in subOrderDetails state
     2. previewImagesForm() => creates new textboxes in form
     3. handlePreviewDetails() => to edit/modify preview image form data
  */
  const addNewPreviewImage = (subId) => {
    let selectedSub = subOrderDetails.filter((x) => x.id === subId)[0];
    const other = subOrderDetails.filter((x) => x.id !== subId);

    const newPreview = {
      id: selectedSub.previewImages.length + 1,
      base64: '',
      mapExtent: '',
    };
    const previewImages = [...selectedSub.previewImages, newPreview];
    selectedSub = {
      ...selectedSub,
      previewImages,
    };

    const subOrders = [...other, selectedSub];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const previewImagesForm = (id, x) => (
    <div key={x.id} style={{ margin: '15px' }}>
      <Box border={1} padding={2}>
        <form className={classes.root} noValidate autoComplete="off">
          <FormControl className={classes.formControl} style={{ width: 500 }}>
            <TextField
              variant="outlined"
              label="Base 64"
              onChange={(e) => handlePreviewDetails(id, x.id, 'base64', e.target.value)}
              value={x.base64}
            />
          </FormControl>

          <FormControl className={classes.formControl} style={{ width: '25%' }}>
            <TextField
              variant="outlined"
              label="Map Extent"
              onChange={(e) => handlePreviewDetails(id, x.id, 'mapExtent', e.target.value)}
              value={x.mapExtent}
            />
          </FormControl>

          <IconButton
            color="primary"
            onClick={() => deletePreviews(id, x.id)}
          >
            <HighlightOffIcon />
          </IconButton>

        </form>
      </Box>
    </div>
  );

  const handlePreviewDetails = (subId, preId, key, value) => {
    let subOrderSelected = subOrderDetails.filter((s) => s.id === subId)[0];
    const otherSubs = subOrderDetails.filter((s) => s.id !== subId);

    let editedPreview = subOrderSelected.previewImages.filter((s) => s.id === preId)[0];
    const otherPreview = subOrderSelected.previewImages.filter((s) => s.id !== preId);

    switch (key) {
      case 'base64':
        editedPreview = {
          ...editedPreview, base64: value,
        };
        break;
      case 'mapExtent':
        editedPreview = {
          ...editedPreview, mapExtent: value,
        };
        break;
    }
    const previews = [...otherPreview, editedPreview];
    previews.sort((a, b) => a.id - b.id);
    subOrderSelected = {
      ...subOrderSelected, previewImages: previews,
    };

    const subOrders = [...otherSubs, subOrderSelected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const deletePreviews = (subId, id) => {
    const selected = subOrderDetails.filter((x) => x.id == subId)[0];
    const other = subOrderDetails.filter((x) => x.id !== subId);

    const previews = [...selected.previewImages];
    previews.splice(id - 1, 1);
    for (let i = 0; i < previews.length; i++) {
      previews[i].id = i + 1;
    }
    previews.sort((a, b) => a.id - b.id);
    selected.previewImages = previews;
    const subOrders = [...other, selected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };
  /* Preview Images Section End */

  /* Report Summary Section Start */
  const addNewReportSummary = (subId) => {
    let selectedSub = subOrderDetails.filter((x) => x.id === subId)[0];
    const other = subOrderDetails.filter((x) => x.id !== subId);

    const newReportSummary = {
      id: selectedSub.summary.length + 1,
      reportName: '',
      reportSummary: [{
        id: 1,
        title: '',
        description: '',
      }],
    };

    const summaries = [...selectedSub.summary, newReportSummary];
    summaries.sort((a, b) => a.id - b.id);
    selectedSub = {
      ...selectedSub,
      summary: summaries,
    };
    // console.log("summaries", [...other, selectedSub])
    const subOrders = [...other, selectedSub];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const reportSummaryForm = (id, x) => (
    <div key={x.id} style={{ margin: '15px' }}>
      <Box border={1} padding={2}>
        <FormControl className={classes.formControl}>
          <TextField
            variant="outlined"
            label="Report Name"
            onChange={(e) => handleReportName(id, x.id, 'reportName', e.target.value)}
            value={x.reportName}
          />
        </FormControl>

        <IconButton
          color="primary"
          onClick={() => deleteReportName(id, x.id)}
        >
          <HighlightOffIcon />
        </IconButton>

        <Box fontWeight="bold">
          Summary Description
          <Button variant="outlined" color="primary" onClick={() => addNewSummaryDesc(id, x.id)} style={{ margin: '10px' }}>
            + Add Summary Description
          </Button>
        </Box>

        {x?.reportSummary?.map((t) => (
          reportSummaryDescForm(id, x.id, t)
        ))}

      </Box>
    </div>
  );

  const handleReportName = (subId, repId, key, value) => {
    let subOrderSelected = subOrderDetails.filter((s) => s.id === subId)[0];
    const otherSubs = subOrderDetails.filter((s) => s.id !== subId);

    let editedRepName = subOrderSelected.summary.filter((s) => s.id === repId)[0];
    const otherReports = subOrderSelected.summary.filter((s) => s.id !== repId);

    switch (key) {
      case 'reportName':
        editedRepName = {
          ...editedRepName,
          reportName: value,
        };
        break;
    }

    const reports = [...otherReports, editedRepName];
    reports.sort((a, b) => a.id - b.id);
    subOrderSelected = {
      ...subOrderSelected, summary: reports,
    };

    const subOrders = [...otherSubs, subOrderSelected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const deleteReportName = (subId, id) => {
    let subOrderSelected = subOrderDetails.filter((s) => s.id === subId)[0];
    const otherSubs = subOrderDetails.filter((s) => s.id !== subId);

    const reports = [...subOrderSelected.summary];

    reports.splice(id - 1, 1);
    for (let i = 0; i < reports.length; i++) {
      reports[i].id = i + 1;
    }
    reports.sort((a, b) => a.id - b.id);
    subOrderSelected = {
      ...subOrderSelected, summary: reports,
    };

    const subOrders = [...otherSubs, subOrderSelected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const addNewSummaryDesc = (subId, repId) => {
    let selectedSub = subOrderDetails.filter((x) => x.id === subId)[0];
    const other = subOrderDetails.filter((x) => x.id !== subId);

    const selectedReport = selectedSub.summary.filter((x) => x.id === repId)[0];
    const otherReport = selectedSub.summary.filter((x) => x.id !== repId);

    const sumDesc = {
      id: selectedReport.reportSummary.length + 1,
      title: '',
      description: '',
    };
    const summaries = [...selectedReport.reportSummary, sumDesc];

    summaries.sort((a, b) => a.id - b.id);
    selectedReport.reportSummary = summaries;

    const summaryList = [...otherReport, selectedReport];
    summaryList.sort((a, b) => a.id - b.id);
    selectedSub = {
      ...selectedSub,
      summary: summaryList,
    };

    const subOrders = [...other, selectedSub];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const reportSummaryDescForm = (subId, repId, x) => (
    <div key={x.id} style={{ margin: '15px' }}>
      <Box>
        <form className={classes.root} noValidate autoComplete="off">
          <FormControl className={classes.formControl}>
            <TextField
              variant="outlined"
              label="Title"
              onChange={(e) => handleReportDesc(subId, repId, x.id, 'title', e.target.value)}
              value={x.title}
            />
          </FormControl>

          <FormControl className={classes.formControl} style={{ minWidth: 500 }}>
            <TextField
              variant="outlined"
              label="Description"
              onChange={(e) => handleReportDesc(subId, repId, x.id, 'description', e.target.value)}
              value={x.description}
            />
          </FormControl>

          <IconButton
            color="primary"
            onClick={() => deleteDesc(subId, repId, x.id)}
          >
            <HighlightOffIcon />
          </IconButton>
        </form>
      </Box>
    </div>
  );

  const handleReportDesc = (subId, repId, id, key, value) => {
    const subOrderSelected = subOrderDetails.filter((s) => s.id === subId)[0];
    const otherSubs = subOrderDetails.filter((s) => s.id !== subId);

    const editedRepName = subOrderSelected.summary.filter((s) => s.id === repId)[0];
    const otherReports = subOrderSelected.summary.filter((s) => s.id !== repId);

    let editedDesc = editedRepName.reportSummary.filter((s) => s.id == id)[0];
    const otherDesc = editedRepName.reportSummary.filter((s) => s.id !== id);

    switch (key) {
      case 'title':
        editedDesc = {
          ...editedDesc,
          title: value,
        };
        break;
      case 'description':
        editedDesc = {
          ...editedDesc,
          description: value,
        };
        break;
    }
    const desc = [...otherDesc, editedDesc];
    desc.sort((a, b) => a.id - b.id);
    editedRepName.reportSummary = desc;

    const reports = [...otherReports, editedRepName];
    reports.sort((a, b) => a.id - b.id);
    subOrderSelected.summary = reports;
    const subOrders = [...otherSubs, subOrderSelected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  const deleteDesc = (subId, repId, id) => {
    const subOrderSelected = subOrderDetails.filter((s) => s.id === subId)[0];
    const otherSubs = subOrderDetails.filter((s) => s.id !== subId);

    const editedRepName = subOrderSelected.summary.filter((s) => s.id === repId)[0];
    const otherReports = subOrderSelected.summary.filter((s) => s.id !== repId);

    const descriptions = [...editedRepName.reportSummary];

    descriptions.splice(id - 1, 1);
    for (let i = 0; i < descriptions.length; i++) {
      descriptions[i].id = i + 1;
    }

    descriptions.sort((a, b) => a.id - b.id);

    editedRepName.reportSummary = descriptions;

    const reports = [...otherReports, editedRepName];
    reports.sort((a, b) => a.id - b.id);

    subOrderSelected.summary = reports;
    const subOrders = [...otherSubs, subOrderSelected];
    subOrders.sort((a, b) => a.id - b.id);
    setSuborderDetails(subOrders);
  };

  /* Report Summary Section End */

  /* POST result structure to API */
  const postResults = async () => {
    try {
      setShowLoader(true);
      let vendorName = await df.checkVendorAuthentication(clientkey, clientSecret);
      vendorName = vendorName.replace(/\"/g, '');
      // vendorName = 'DIGS'
      if (vendorName === clientName) {
        const subOrder = [];

        for (const sub of subOrderDetails) {
          let obj = {
            ...sub,
            pdfLink: sub.pdfLink?.split(','),
            shapefiles: sub.shapefiles?.split(','),
            reports: sub.reports?.split(','),
            costPerAcre: sub.costPerAcre?.split(',').map((x, i) => Number(x)),
            sampleZones: sub.sampleZones?.split(','),
          };

          if (vendorName.toUpperCase() === 'FARM FLIGHT') {
            obj = {
              ...obj,
              flightOperator: sub.operator,
              flightOperatorEmail: sub.operatorEmail,
              flightOperatorPhone: sub.operatorPhone,
              flightOperatorCompany: sub.operatorCompany,
            };
          }
          subOrder.push(obj);
        }

        const request = {
          orderId,
          orderName,
          orderCompletedDate: completedDate,
          orderLink: orderCompletedLink,
          farmerDetails,
          organizationName: orgName,
          organizationID: orgId,
          totalAcres,
          totalCost,
          subOrderDetails: subOrder,
        };

        const response = await df.postVendorResponse(request, clientkey, clientSecret);

        setShowLoader(false);
        if (response?.message === 'Successful!') {
          resetState();
          setShow(true);
          setDisplayMessage('Your order was submitted successfully. Thank you!');
        } else if (response?.message) {
          setShow(true);
          setDisplayMessage(JSON.stringify(response.message));
        } else {
          // If no response message, assume something went wrong
          enqueueSnackbar('There was an issue submitting your order. Please try again or contact us if the problem persits.');
        }
      }
    } catch (err) {
      setShowLoader(false);
      enqueueSnackbar('There was an issue submitting your order. Please try again or contact us if the problem persits.');
      console.error('post results', err);
    }
  };

  /* Handle Farmer Details */
  const handleFarmerDetails = (e, key) => {
    switch (key) {
      case 'fullName':
        setFarmerDetails({ ...farmerDetails, fullName: e });
        break;
      case 'email':
        setFarmerDetails({ ...farmerDetails, eMail: e });
        break;
      case 'phone':
        setFarmerDetails({ ...farmerDetails, phone: e });
        break;
      case 'mailing':
        setFarmerDetails({ ...farmerDetails, mailingAddress: e });
        break;
      case 'city':
        setFarmerDetails({ ...farmerDetails, city: e });
        break;
      case 'zipcode':
        setFarmerDetails({ ...farmerDetails, zipCode: e });
        break;
      case 'state':
        setFarmerDetails({ ...farmerDetails, state: e });
        break;
    }
  };

  /* Reset state after successful message */
  const resetState = () => {
    setClientKey('');
    setClientSecret('');
    setClientName('');

    setOrderId('');
    setOrderName('');
    setCompletedDate(new Date());
    setOrderCompletedLink('');
    setOrgId('');
    setOrgName('');
    setTotalAcres(0);
    setTotalCost(0);
    setFarmerDetails({
      fullName: '',
      eMail: '',
      phone: '',
      mailingAddress: '',
      city: '',
      state: '',
      zipCode: '',
    });
    setSuborderDetails([{
      id: 1,
      subOrderId: '',
      fieldId: '',
      fieldName: '',
      acres: 0,
      XYCoordinates: '',
      reports: '',
      costPerAcre: '',
      summary: [{
        id: 1,
        reportName: '',
        reportSummary: [{
          id: 1,
          title: '',
          description: '',
        }],
      }],
      tiffImages: [{
        id: 1,
        bandName: '',
        tiffLink: '',
      }],
      previewImages: [{
        id: 1,
        base64: '',
        mapExtent: '',
      }],
      pdfLink: '',
      shapefiles: '',
      interactiveAppLink: '',
      operator: '',
      operatorEmail: '',
      operatorPhone: '',
      operatorCompany: '',
      resolution: '',
      equipmentType: '',
      equipmentBrand: '',
      fieldImage: '',
      subTotal: 0,
      state: '',
      county: '',
      digsReports: '',
      sampleZones: '',
      package: '',
      boundary: '',
    }]);

    setFile(null);
  };

  const readFile = (evt) => {
    const f = evt.target.files[0];
    setFile(f);
    if (f) {
      const r = new FileReader();
      r.onload = function (e) {
        const contents = e.target.result;
        const ct = r.result;

        parseData(ct);
      };
      r.readAsText(f);
    } else {
      enqueueSnackbar('Unable to load selected file. If you are sure this was the correct file, please contact us for assistance.');
    }
  };

  const parseData = (text) => {
    try {
      const orderData = JSON.parse(text);

      // section to set order info
      if (orderData.orderId !== undefined) setOrderId(orderData.orderId);
      if (orderData.orderName !== undefined) setOrderName(orderData.orderName);
      if (orderData.totalCost !== undefined) setTotalCost(orderData.totalCost);
      if (orderData.totalAcres !== undefined) setTotalAcres(orderData.totalAcres);
      if (orderData.organizationID !== undefined) setOrgId(orderData.organizationID);
      if (orderData.organizationName !== undefined) setOrgName(orderData.organizationName);

      // section to set customer/farmer details
      setFarmerDetails(orderData.farmerDetails);

      // section to set suborder details
      for (let i = 0; i < orderData.subOrderDetails.length; i++) {
        orderData.subOrderDetails[i].id = i + 1;

        if (orderData.subOrderDetails[i].tiffImages === undefined) {
          orderData.subOrderDetails[i].tiffImages = [{
            id: 1,
            bandName: '',
            tiffLink: '',
          }];
        }

        if (orderData.subOrderDetails[i].previewImages === undefined) {
          orderData.subOrderDetails[i].previewImages = [{
            id: 1,
            base64: '',
            mapExtent: '',
          }];
        }

        if (orderData.subOrderDetails[i].summary === undefined) {
          orderData.subOrderDetails[i].summary = [{
            id: 1,
            reportName: '',
            reportSummary: [{
              id: 1,
              title: '',
              description: '',
            }],
          }];
        }

        if (orderData.subOrderDetails[i].reports !== undefined) {
          orderData.subOrderDetails[i].reports = typeof (orderData.subOrderDetails[i].reports) !== 'string' ? orderData.subOrderDetails[i].reports.join(',') : orderData.subOrderDetails[i].reports;
        }

        if (orderData.subOrderDetails[i].costPerAcre !== undefined) {
          orderData.subOrderDetails[i].costPerAcre = typeof (orderData.subOrderDetails[i].costPerAcre) !== 'string' ? orderData.subOrderDetails[i].costPerAcre.join(',') : orderData.subOrderDetails[i].costPerAcre;
        }

        if (orderData.subOrderDetails[i].pdfLink !== undefined) {
          orderData.subOrderDetails[i].pdfLink = typeof (orderData.subOrderDetails[i].pdfLink) !== 'string' ? orderData.subOrderDetails[i].pdfLink.join(',') : orderData.subOrderDetails[i].pdfLink;
        }

        if (orderData.subOrderDetails[i].shapefiles !== undefined) {
          orderData.subOrderDetails[i].shapefiles = typeof (orderData.subOrderDetails[i].shapefiles) !== 'string' ? orderData.subOrderDetails[i].shapefiles.join(',') : orderData.subOrderDetails[i].shapefiles;
        }

        if (orderData.subOrderDetails[i].sampleZones !== undefined) {
          orderData.subOrderDetails[i].sampleZones = typeof (orderData.subOrderDetails[i].sampleZones) !== 'string' ? orderData.subOrderDetails[i].sampleZones.join(',') : orderData.subOrderDetails[i].sampleZones;
        }

        if (orderData.subOrderDetails[i].flightOperator !== undefined) {
          orderData.subOrderDetails[i].operator = orderData.subOrderDetails[i].flightOperator;
        }

        if (orderData.subOrderDetails[i].flightOperatorEmail !== undefined) {
          orderData.subOrderDetails[i].operatorEmail = orderData.subOrderDetails[i].flightOperatorEmail;
        }

        if (orderData.subOrderDetails[i].flightOperatorPhone !== undefined) {
          orderData.subOrderDetails[i].operatorPhone = orderData.subOrderDetails[i].flightOperatorPhone;
        }

        if (orderData.subOrderDetails[i].flightOperatorCompany !== undefined) {
          orderData.subOrderDetails[i].operatorCompany = orderData.subOrderDetails[i].flightOperatorCompany;
        }
      }
      setSuborderDetails(orderData.subOrderDetails);
    } catch (err) {
      console.error(err);
      setFile(null);
      enqueueSnackbar('Unable to load selected file. If you are sure this was the correct file, please contact us for assistance.');
    }
  };

  return (
    <Box>
      <Box
        display="flex"
        color={blackText}
        bgcolor="#f3f3f3"
      >
        <Container
          maxWidth="lg"
          style={{ backgroundColor: '#ffffff', height: '100%', paddingBottom: 8 }}
        >
          {/* Desciption about vendor response page */}
          <Box mt={2}>
            <Typography align="center" variant="h5">
              Vendor Response Page
            </Typography>
            <Divider />

            <Box p={0.5} display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography>
                  Easily send order results back to Ag-Analytics through this page.
                </Typography>
                <Typography>
                  Simply fill out the appropriate text boxes below with result information.
                </Typography>
                <Typography>
                  When all order information is complete, click the “Submit Order Results” button
                </Typography>
              </Box>

              <Box display="flex" justifyContent="center" flexGrow={1}>
                <Box className={classes.learnMore}>
                  <Typography align="center" style={{ fontWeight: 500 }}>
                    Have question or looking for API documentation?
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    href="/app/APIDocumentation"
                    target="blank"
                    disableElevation
                    style={{ marginTop: 8 }}
                  >
                    <span style={{ color: '#ffffff' }}>Learn More</span>
                  </Button>
                </Box>
              </Box>

            </Box>

            <Box display="flex" flexWrap="wrap" justifyContent="space-between" alignItems="center">
              <Box>
                <Typography>
                  The following information will be required to successfully complete submission:
                </Typography>
                <Box display="flex" align="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Vendor Information, including Client Key, Client Secret, and Client Name
                  </Typography>
                </Box>
                <Box display="flex" align="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Order ID and Suborder ID
                  </Typography>
                </Box>
                <Box display="flex" align="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    Customer Details, including email
                  </Typography>
                </Box>
                <Box display="flex" align="center">
                  <FiberManualRecordIcon className={classes.dot} />
                  <Typography>
                    All entries marked with *
                  </Typography>
                </Box>
              </Box>

              <Box pt={1} display="flex" justifyContent="center" flexGrow={1}>
                <Box className={classes.learnMore}>
                  <Box>
                    <Typography align="center" style={{ fontWeight: 500 }}>
                      Sample order example
                    </Typography>
                    <Typography>
                      To try the interface and test your connection.
                    </Typography>
                  </Box>
                  <Accordion>
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content-sample-results"
                      id="panel1a-header-sample-results"
                    >
                      <Typography>View Sample Order Data</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box>
                        <Typography>
                          Client Key: v0ob98uhkj2r7fmv
                        </Typography>
                        <Typography>
                          Client Secret: LtQkRgG5cxjpSTOFN4FDZyJg
                        </Typography>
                        <Typography>
                          Client Name: TestClient
                        </Typography>
                        <Typography>
                          Sample Order JSON:&nbsp;
                          <a href="https://drive.google.com/uc?export=download&id=1uG1RyDmkdCQI9mOe2_aoifhb-F-rUKtn" download>download</a>
                        </Typography>
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                </Box>
              </Box>
            </Box>
          </Box>

          <Divider style={{ marginTop: 16 }} />

          <Box mt={1}>
            <Typography align="center" style={{ fontSize: '1.1rem', fontWeight: 500 }}>
              Fill in order information on the forms below
            </Typography>
            <Box display="flex" alignItems="center" flexDirection="column">
              <Box display="flex" alignItems="center">
                <Typography style={{ fontSize: '1.1rem' }}>
                  Have a file containing order information?
                </Typography>
                <CustomToolTip
                  placement="top-start"
                  title="Upload the json or text file and we will auto-populate as many of the forms as possible"
                >
                  <InfoOutlinedIcon className={classes.infoIcon} />
                </CustomToolTip>
              </Box>

              <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                  color="primary"
                  variant="contained"
                  component="label"
                  disableElevation
                >
                  <PublishIcon />
                  Upload File
                  <input
                    onChange={(event) => readFile(event)}
                    type="file"
                    hidden
                  />
                </Button>
                { file?.name && (
                  <Typography style={{ fontWeight: 500, marginLeft: 8 }}>
                    Order:
                    {' '}
                    {file.name}
                  </Typography>
              )}
              </Box>

            </Box>
          </Box>

          <Box>
            <Stepper orientation="vertical" style={{ padding: 0 }}>
              <Step active key="vendorDetails">
                <StepLabel><Box fontWeight="bold" fontSize={16}>Vendor Details</Box></StepLabel>
                <StepContent>
                  <Box mt={2} border={1} padding={2}>
                    <Box>
                      <form className={classes.root} noValidate autoComplete="off">
                        <FormControl className={classes.formControl}>
                          <TextField variant="outlined" required label="Client Key" onChange={(e) => setClientKey(e.target.value)} value={clientkey} />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField variant="outlined" required label="Client Secret" onChange={(e) => setClientSecret(e.target.value)} value={clientSecret} />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField variant="outlined" required label="Client Name" onChange={(e) => setClientName(e.target.value)} value={clientName} />
                        </FormControl>
                      </form>
                    </Box>
                  </Box>
                </StepContent>
              </Step>

              <Step active key="orderDetails">
                <StepLabel><Box fontWeight="bold" fontSize={16}>Order Details</Box></StepLabel>
                <StepContent>
                  {/* form to enter Order Details */}
                  <Box mt={2} border={1} padding={2}>
                    <Box>
                      <form className={classes.root} noValidate autoComplete="off">
                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            required
                            label="Order ID"
                            onChange={(e) => setOrderId(e.target.value)}
                            value={orderId}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Order Name"
                            onChange={(e) => setOrderName(e.target.value)}
                            value={orderName}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <MuiPickersUtilsProvider utils={DateFnsUtils}>
                            <KeyboardDatePicker
                              autoOk
                              inputVariant="outlined"
                              format="MM/dd/yyyy"
                              label="Order Complete Date"
                              onChange={(date) => setCompletedDate(date)}
                              value={completedDate}
                              KeyboardButtonProps={{
                                'aria-label': 'change date',
                              }}
                              style={{ width: 250 }}
                            />

                          </MuiPickersUtilsProvider>
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Invoice Link"
                            onChange={(e) => setOrderCompletedLink(e.target.value)}
                            value={orderCompletedLink}
                            helperText="Link to order invoice"
                          />
                        </FormControl>
                      </form>
                    </Box>
                    <Box>
                      <form className={classes.root} noValidate autoComplete="off">
                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Organization ID"
                            onChange={(e) => setOrgId(e.target.value)}
                            value={orgId}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Organization Name"
                            onChange={(e) => setOrgName(e.target.value)}
                            value={orgName}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Total Acres"
                            onChange={(e) => setTotalAcres(e.target.value)}
                            value={totalAcres}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Total Cost"
                            onChange={(e) => setTotalCost(e.target.value)}
                            value={totalCost}
                          />
                        </FormControl>
                      </form>
                    </Box>
                  </Box>
                </StepContent>
              </Step>

              <Step active key="customerDetails">
                <StepLabel><Box fontWeight="bold" fontSize={16}>Customer Details</Box></StepLabel>
                <StepContent>
                  {/* form to enter farmer details */}
                  <Box mt={2} border={1} padding={2}>
                    {/* <Box fontWeight="bold" fontSize={16}>Enter Customer Details</Box> */}
                    <Box>
                      <form className={classes.root} noValidate autoComplete="off">
                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Full Name"
                            onChange={(e) => handleFarmerDetails(e.target.value, 'fullName')}
                            value={farmerDetails.fullName}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            required
                            variant="outlined"
                            label="Email"
                            onChange={(e) => handleFarmerDetails(e.target.value, 'email')}
                            value={farmerDetails.eMail}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Phone Number"
                            onChange={(e) => handleFarmerDetails(e.target.value, 'phone')}
                            value={farmerDetails.phone}
                          />
                        </FormControl>
                      </form>
                    </Box>
                    <Box>
                      <form className={classes.root} noValidate autoComplete="off">
                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Mailing Address"
                            onChange={(e) => handleFarmerDetails(e.target.value, 'mailing')}
                            value={farmerDetails.mailingAddress}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="City"
                            onChange={(e) => handleFarmerDetails(e.target.value, 'city')}
                            value={farmerDetails.city}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="State"
                            onChange={(e) => handleFarmerDetails(e.target.value, 'state')}
                            value={farmerDetails.state}
                          />
                        </FormControl>

                        <FormControl className={classes.formControl}>
                          <TextField
                            variant="outlined"
                            label="Zipcode"
                            onChange={(e) => handleFarmerDetails(e.target.value, 'zipcode')}
                            value={farmerDetails.zipCode}
                          />
                        </FormControl>
                      </form>
                    </Box>
                  </Box>
                </StepContent>
              </Step>

              <Step active label="subOrder">
                <StepLabel><Box fontWeight="bold" fontSize={16}>Suborder Details</Box></StepLabel>
                <StepContent>
                  {/* Form to enter suborder details */}
                  <Box mt={2} border={1} padding={2}>
                    <Box fontWeight="bold" fontSize={16}>
                      <Button variant="outlined" color="primary" onClick={(e) => addNewForm()} style={{ margin: '10px' }}>
                        + Add SubOrder
                      </Button>
                    </Box>
                    {subOrderDetails?.map((x, i) => (
                      subOrderDetailsForm(x)
                    ))}
                  </Box>
                </StepContent>
              </Step>
            </Stepper>
          </Box>

          <Box mt={1} display="flex" justifyContent="center">
            <Button
              variant="contained"
              color="primary"
              size="large"
              onClick={(e) => postResults()}
              disableElevation
            >
              Submit Order Results
            </Button>
          </Box>
        </Container>

        <Modal
          open={show}
          onClose={() => setShow(false)}
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Fade in={show} timeout={500}>
            <Box
              style={{
                margin: 'auto',
                width: 360,
                backgroundColor: lightGrey,
              }}
              borderRadius="borderRadius"
              boxShadow={2}
            >

              <Box display="flex" justifyContent="flex-end">
                <IconButton
                  color="primary"
                  onClick={() => setShow(!show)}
                >
                  <HighlightOffIcon />
                </IconButton>
              </Box>
              <Box p={1}>
                <Typography align="center" style={{ fontSize: '1.2rem' }}>
                  {displayMessage}
                </Typography>

              </Box>
            </Box>
          </Fade>
        </Modal>
      </Box>
      {showLoader && <SpinningLoader top="50%" position="fixed" />}
    </Box>
  );
}

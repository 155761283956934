import React from "react";
import PropTypes from "prop-types";
import {
  makeStyles,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import { COLORS } from "../../../constants";
import CogIcon from "../../../images/gear.svg";
import SyncArrows from "../../../images/sync_arrows.svg";
import { useDimensions } from "../../../utils";

const useStyles = makeStyles({
  yearContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    padding: "15px 0px 10px 20px",
    "& .inner__container": {
      display: "flex",
      flexDirection: "column",
      fontWeight: "bold",
      "& .year": {
        fontSize: 14,
        color: COLORS.gray,
      },
      "& .MuiInputBase-root": {
        fontWeight: "bold",
        fontSize: 16,
        color: COLORS.darkGray,
        "&:before": {
          content: "none",
        },
      },
    },
  },
  icons: {
    margin: "0 .5rem",
  },
  formControl: {
    margin: ".5rem 1rem",
    "& .MuiOutlinedInput-input": {
      padding: "12px 30px 12px 20px",
    },
  },
});

/**
 * Renders the lower mobile nave where you select year and farm
 * @param {array} farms array of objects with farm data and active property
 * @param {function} setFarms set state function to set the active farm
 * @param {array} years array of objects with value and active properties
 * @param {function} setYears set state function to set the active year
 */

const LowerMobileNav = ({ farms, setFarms, years, setYears }) => {
  const classes = useStyles();
  const { width, height } = useDimensions();

  const onFarmChange = (e) => {
    setFarms(
      farms.map((farm) => ({
        ...farm,
        active: farm.name === e.target.value,
      }))
    );
  };

  const onYearChange = (evt) =>
    setYears(
      years.map((year) => ({
        ...year,
        active: year.value === evt.target.value,
      }))
    );
  const activeFarm = farms.find((farm) => farm.active);

  const activeYear = years.find((year) => year.active).value;
  return (
    <div className={classes.yearContainer} style={{ height: height - 195 }}>
      <div className="inner__container">
        <div className="year">Year</div>
        <Select id="year-select" value={activeYear} onChange={onYearChange}>
          {years.map((year) => (
            <MenuItem
              key={`year-menu-item-for-${year.value}`}
              value={year.value}
            >
              {year.value}
            </MenuItem>
          ))}
        </Select>
      </div>
      {/* <img className={classes.icons} src={SyncArrows} alt="sync data" />
      <img src={CogIcon} alt="more options related to year select" /> */}
      <div>
        {activeFarm && (
          <FormControl variant="outlined" className={classes.formControl}>
            <InputLabel>Change Farm</InputLabel>
            <Select
              id="farm-select"
              value={activeFarm.name}
              onChange={onFarmChange}
              label="Change Farm"
            >
              {farms.map((farm) => (
                <MenuItem
                  key={`farm-menu-item-for-${farm.name}`}
                  value={farm.name}
                >
                  {farm.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
        {/* {farms.map((farm, i) => {
            return <option key={`farm-row-for-${nameAccessor(farm)}`}>{nameAccessor(farm)}</option>
          })}
        </CustomSelect> */}
      </div>
    </div>
  );
};

export default LowerMobileNav;

LowerMobileNav.propTypes = {
  farms: PropTypes.array.isRequired,
  setFarms: PropTypes.func.isRequired,
  years: PropTypes.array.isRequired,
  setYears: PropTypes.func.isRequired,
};

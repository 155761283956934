/* eslint-disable camelcase */
/* eslint-disable no-restricted-syntax */
/* eslint-disable max-len */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box, MenuItem, Select, Button, TextField, Radio,
} from '@material-ui/core';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import MoreOutlinedIcon from '@material-ui/icons/MoreOutlined';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import PageviewOutlinedIcon from '@material-ui/icons/PageviewOutlined';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';

import { makeStyles } from '@material-ui/core/styles';
import { cloneDeep } from 'lodash';
import {
  Accordion,
  AccordionSummaryCentered,
  AccordionDetails,
  CustomToolTip,
} from '../../../utils/customComponents';
import { MenuProps } from '../../../styles/select';
import { UpdateOperationsMap } from '../../Maps/AcreageReporting/UpdateOperations';
import { inclusionControl } from './helpers';
import { CluDisplay } from './CluDisplay';
import { AdvancedOptions } from '../Modals/AdvancedOptions';
import { NumberFormatRMAArea } from '../../../utils/NumberFormatCustom';
import { formatDate } from '../../ProfitLayers/utils/conversions';
import { cartDropdownData } from '../presetData';
import { useWindowDimensions } from '../../../utils/dimensions';
import { numFormat } from '../../../utils/helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: 4,
    margin: '12px 8px',
    border: `1px solid ${theme.palette.greys.main}`,
  },
  header: {
    padding: 4,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.5rem',
    fontWeight: 500,
  },
  options: {
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.greys.main}`,
  },
  operationInfo: {
    display: 'flex',
    borderBottom: `1px solid ${theme.palette.greys.main}`,
    backgroundColor: '#f2f2f2',
  },
  attributes: {
    padding: 4,
    display: 'flex',
    flexDirection: 'column',
    borderRight: `1px solid ${theme.palette.greys.main}`,
  },
  mapContainer: {
    marginRight: '4px',
    display: 'flex',
    flexGrow: 1,
    minWidth: 480,
    maxWidth: 700,
    width: 620,
    maxHeight: 700,
    minHeight: 480,
    border: `1px solid ${theme.palette.greys.main}`,
    borderRadius: 4,
  },
  selects: {
    height: 44.6,
    minWidth: 226,
    width: 300,
    maxWidth: 400,
    backgroundColor: '#ffffff',
  },
  dateSelection: {
    margin: 0,
    height: 44.6,
    backgroundColor: '#ffffff',
    width: 180,
    maxWidth: 200,
    '& .MuiInputBase-root': {
      padding: 2,
      '& .MuiButtonBase-root': {
        padding: 0,
        paddingLeft: 10,
      },
      '& .MuiInputBase-input': {
        padding: 10,
        paddingLeft: 0,
      },
    },
  },
  display: {
    height: 44.6,
    width: 180,
    maxWidth: 200,
    display: 'flex',
    alignItems: 'center',
    padding: '0 14px',
    backgroundColor: theme.palette.greys.light,
    borderRadius: 4,
    border: `1px solid ${theme.palette.greys.main}`,
  },
  errorMessageBox: {
    ...theme.errorMessageBox,
    fontSize: '16px',
  },
}));

/**
 * Displays selection boxes with operation data, operation map, and intersecting CLUs.
 * User can update all operation info here and in advanced options. CLU data may be
 * updated by selecting CLU at bottom of card and interaction with selection boxes there.
 * Inclusion control function is used to add options available at operation level to all
 * CLUs for individual editing. Any change to operation data at top level will be passed
 * down to all CLUs.
 * @param {Object} clusSeen reference for CLU information
 * @param {Object} commoditiesSeen key:value mappings of commodities seen and their associated commodity types
 * @param {Object} commodityTypesSeen key:value mappings of commodity types seen and their associated intended uses
 * @param {Dates} dates dates to be used for operation
 * @param {Function} editOperationWithClu Helps setup the modal to edit the CLU boundary
 * @param {Number} farmIndex Farms index in ordered mappings
 * @param {Number} fieldIndex Field index in farm array
 * @param {String} gartPath Denotes whether this interface instance is for creating GART files
 * @param {String} impersonating email address for user being impersonated by agent
 * @param {Array} mismatchOptions Options for displaying mismatches between CLUs and operation
 * @param {Function} openFileDownload Opens modal for downloading personal files
 * @param {Function} openFileUpload Opens modal for uploading personal files
 * @param {Object} operationInfo Field data
 * @param {Number} opIndex Index in field array
 * @param {Function} setDates function to change dates
 * @param {Object} statesSeen key:value mappings of states seen and their associated commodities
 * @param {Function} updateIntersection Updates data for a CLU, that intersects with operations
 * @param {Function} updateIntersections Updates which CLUs intersect with operations
 * @param {Function} updateOperation Updates operation
 * @returns {JSX} Operation Display
 */
export function OperationDisplay({
  clusSeen,
  commoditiesSeen,
  commodityTypesSeen,
  dates,
  editOperationWithClu,
  farmIndex,
  fieldIndex,
  gartPath,
  impersonating,
  mismatchOptions,
  openFileDownload,
  openFileUpload,
  operationInfo,
  opIndex,
  setDates,
  statesSeen,
  updateIntersection,
  updateIntersections,
  updateOperation,
}) {
  const classes = useStyles();
  const { width } = useWindowDimensions();
  const displayBreakPoint = 1600;
  // For displaying mismatches between CLUs and operation
  const [displayMarker, greyedOut, white] = mismatchOptions;

  // Keeps tracks of the elements that need to be shown in each CLU row
  // some are present by default, some are added by user
  const [additionalCluOptions, setAdditionalCluOptions] = useState(['acres', 'crop', 'cropType', 'intendedUse']);
  const [optionsOpen, setOptionsOpen] = useState(false);

  const [expanded, setExpanded] = useState([]);

  const today = new Date().toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
  const todayFormatted = formatDate(today);

  // This helps show to user which CLUs will be included in report and pdf
  const getAllIncluded = () => {
    const currentIncluded = [];
    for (const clu of operationInfo.CLUInfo) {
      if (clu.shown) { currentIncluded.push(clu.zoneID); }
    }
    return currentIncluded;
  };

  // Would probably be the tiniest bit more space efficient to store the ones that won't be included as that scenario should happen less often.
  const [included, setIncluded] = useState((!gartPath && !operationInfo?.error) ? getAllIncluded() : []);

  useEffect(() => {
    if (width >= 1640) {
      setAdditionalCluOptions([...additionalCluOptions, 'organicPracticeType', 'irrigationCodes']);
    } else if (width >= 1440) {
      setAdditionalCluOptions([...additionalCluOptions, 'organicPracticeType']);
    }
    // console.log("OPERATION INFO", operationInfo)
  }, []);

  // Handle which additional Clu options to show at the CLU level
  const addCluOption = (option) => {
    setAdditionalCluOptions([...additionalCluOptions, option]);
  };

  const removeCluOption = (option) => {
    setAdditionalCluOptions(additionalCluOptions.filter((x) => x !== option));
  };

  // const advancedOptionInclusion = (option) => inclusionControl(option, additionalCluOptions, addCluOption, removeCluOption);
  const elementInclusionIcon = (option) => {
    if (!gartPath) { // Ignore if zoneType is 'GART'
      return inclusionControl(option, additionalCluOptions, addCluOption, removeCluOption);
    }
  };

  const displayOperationName = (operation) => {
    let name = operation?.name;

    if (operation?.varietyList) {
      name += ` - ${operation.varietyList}`;
    }
    return name;
  };

  // Handle expansion states of CLUDisplays
  const handleSelect = (clu) => {
    setExpanded([clu.zoneID]);
  };

  const selectAll = () => {
    const all = operationInfo.CLUInfo.map((x) => x.zoneID);
    setExpanded(all);
  };

  const hideAll = () => {
    setExpanded([]);
  };

  // Decides whether the background for this item should be greyed out or white
  const displayBackground = (input) => (input === displayMarker ? greyedOut : white);

  // Intended Use Box. This is reused so split out of options for simplicity
  const intendedUseBox = () => (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <span style={{ marginRight: 4 }}>Intended Use</span>
        { elementInclusionIcon('intendedUse') }
      </Box>
      <Select
        labelId="intendedUse-selection-label"
        label="Intended Use"
        variant="outlined"
        MenuProps={MenuProps}
        value={operationInfo.intendedUse}
        className={classes.selects}
        onChange={(e) => updateOperation(
          farmIndex, fieldIndex, opIndex, [{ intendedUse: e.target.value }],
        )}
        style={{ background: displayBackground(operationInfo.intendedUse) }}
      >
        {commodityTypesSeen[operationInfo.intendedUses].map((x) => (
          <MenuItem
            key={x.intendedUseCode}
            value={x.intendedUseCode}
          >
            {x.displayValue}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  // Organic Status Box. This is reused so split out of options for simplicity
  const organicStatusBox = () => (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <span style={{ marginRight: 4 }}>Organic Status</span>
        { elementInclusionIcon('organicPracticeType') }
      </Box>

      <Select
        displayEmpty
        variant="outlined"
        MenuProps={MenuProps}
        value={operationInfo?.organicPracticeTypeCode ? operationInfo.organicPracticeTypeCode : ''}
        className={classes.selects}
        onChange={(e) => updateOperation(
          farmIndex,
          fieldIndex,
          opIndex,
          [{ organicPracticeTypeCode: e.target.value }],
        )}
        style={{ background: displayBackground(operationInfo.organicPracticeTypeCode) }}
      >
        {cartDropdownData.organicPracticeType.options.map((x) => (
          <MenuItem
            key={x.code}
            value={x.code}
          >
            {x.display}
          </MenuItem>
        ))}
      </Select>
    </Box>
  );

  // Irrigation Status Box. This is reused so split out of options for simplicity
  const irrigationStatusBox = () => (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <span style={{ marginRight: 4 }}>Irrigation Status</span>
        { elementInclusionIcon('irrigationCodes') }
      </Box>
      <Box
        border={1}
        borderColor="rgba(0, 0, 0, 0.23)"
        borderRadius={4}
        bgcolor="#ffffff"
        display="flex"
        height={44.6}
        width={300}
        justifyContent="space-around"
        style={{ background: displayBackground(operationInfo.irrigationPracticeCode) }}
      >
        <Box p={0.5} display="flex" alignItems="center">
          <Radio
            color="primary"
            checked={operationInfo?.irrigationPracticeCode === 'I'}
            onChange={() => updateOperation(farmIndex, fieldIndex, opIndex, [{ irrigationPracticeCode: 'I' }])}
            value="a"
            name="non-irrigated-radio-input"
            inputProps={{ 'aria-label': 'I' }}
            style={{ padding: '0 4px' }}
          />
          <Box>Irrigated</Box>
        </Box>
        <Box p={0.5} display="flex" alignItems="center">
          <Radio
            color="primary"
            checked={operationInfo?.irrigationPracticeCode === 'N'}
            onChange={() => updateOperation(farmIndex, fieldIndex, opIndex, [{ irrigationPracticeCode: 'N' }])}
            value="a"
            name="non-irrigated-radio-input"
            inputProps={{ 'aria-label': 'N' }}
            style={{ padding: '0 4px' }}
          />
          <Box>Non-Irrigated</Box>
        </Box>
      </Box>
    </Box>
  );

  const dateSelection = () => (
    <Box p={1}>
      <Box display="flex" alignItems="center">
        <span style={{ marginRight: 4 }}>Planting Date</span>
      </Box>
      <Box>
        <TextField
          variant="outlined"
          type="date"
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            min: '1800-01-01',
            max: todayFormatted,
            style: { padding: '12.8px 14px' },
          }}
          style={{ height: '44.6px', backgroundColor: white }}
          value={dates[fieldIndex][opIndex]}
          // Check if not an empty string and update state var
          // Unlike acres, whose onChange is handled by NumberFormatCustom, we need an onChange here (so need state var)
          onChange={(event) => {
            const date = event.target.value;
            if (date === '') { return; }
            const datesCopy = cloneDeep(dates);
            datesCopy[fieldIndex][opIndex] = date;
            setDates(datesCopy);
          }}
          // Actually update orderedMappings when user leaves focus
          onBlur={(event) => {
            const date = event.target.value;
            updateOperation(farmIndex, fieldIndex, opIndex, [{ finalPlantedDate: date }]);
          }}
        />
        {/* This box's sole purpose is to hide the 'x' that cannot be removed */}
        <Box
          position="relative"
          width={20}
          height={40.6}
          top={-42.6}
          left={120}
          marginBottom="-42.6px"
          marginRight="120px"
          style={{ backgroundColor: white }}
        />
      </Box>
    </Box>
  );

  // Handle including and excluding all CLUs
  const inExCludeAll = (include) => {
    if (include) {
      const all = operationInfo.CLUInfo.map((x) => x.zoneID);
      setIncluded(all);
    } else {
      setIncluded([]);
    }
    updateOperation(farmIndex, fieldIndex, opIndex, [{ shown: include }]);
  };

  const options = () => (
    <Box className={classes.options}>
      <Box display="flex" flexWrap="wrap">
        <Box p={1}>
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: 4 }}>Crop</span>
            { elementInclusionIcon('crop') }
          </Box>
          <Select
            labelId="crop-selection-label"
            label="Crop"
            variant="outlined"
            MenuProps={MenuProps}
            value={operationInfo.crop}
            className={classes.selects}
            onChange={(e) => {
              updateOperation(farmIndex, fieldIndex, opIndex, [{ crop: e.target.value }]);
            }}
            style={{ background: displayBackground(operationInfo.crop) }}
          >
            {statesSeen[operationInfo.crops].map((x) => (
              <MenuItem
                key={x.commodityCode}
                value={x.commodityCode}
              >
                {x.displayValue}
              </MenuItem>
            ))}
          </Select>
        </Box>

        <Box p={1}>
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: 4 }}>Crop Type</span>
            { elementInclusionIcon('cropType') }
          </Box>
          <Select
            variant="outlined"
            MenuProps={MenuProps}
            value={operationInfo.cropType}
            className={classes.selects}
            onChange={(e) => updateOperation(
              farmIndex, fieldIndex, opIndex, [{ cropType: e.target.value }],
            )}
            style={{ background: displayBackground(operationInfo.cropType) }}
          >
            {commoditiesSeen[operationInfo.cropTypes].map((x) => (
              <MenuItem
                key={x.commodityTypeCode}
                value={x.commodityTypeCode}
              >
                {x.displayValue}
              </MenuItem>
            ))}
          </Select>
        </Box>

        { width >= displayBreakPoint && (
          intendedUseBox()
        )}
      </Box>

      <Box display="flex" flexWrap="wrap">
        { width < displayBreakPoint && (
          intendedUseBox()
        )}

        <Box p={1}>
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: 4 }}>Cropping Practice</span>
            { elementInclusionIcon('croppingPractice') }
          </Box>

          <Select
            displayEmpty
            variant="outlined"
            MenuProps={MenuProps}
            value={operationInfo?.croppingPracticeCode ? operationInfo.croppingPracticeCode : ''}
            className={classes.selects}
            onChange={(e) => updateOperation(
              farmIndex, fieldIndex, opIndex, [{ croppingPracticeCode: e.target.value }],
            )}
            style={{ background: displayBackground(operationInfo.croppingPracticeCode) }}
          >
            {cartDropdownData.croppingPractice.options.map((x) => (
              <MenuItem
                key={x.code}
                value={x.code}
              >
                {x.display}
              </MenuItem>
            ))}
          </Select>
        </Box>

        { width >= displayBreakPoint && (
          <>
            {irrigationStatusBox()}
            {organicStatusBox()}
          </>
        )}
      </Box>

      { width < displayBreakPoint && (
        <Box display="flex" flexWrap="wrap">
          {irrigationStatusBox()}
          {organicStatusBox()}
        </Box>
      )}

      <Box display="flex" flexWrap="wrap">
        <Box p={1}>
          <Box>
            Planted Acres
          </Box>
          {!gartPath ? (
            <Box className={classes.display}>
              {numFormat(operationInfo.acres)}
            </Box>
          ) : (
            <TextField
              className={classes.selects}
              variant="outlined"
              value={operationInfo.finalReportedAcreage}
              onBlur={(e) => updateOperation(
                farmIndex, fieldIndex, opIndex, [{ finalReportedAcreage: Number(e.target.value.replaceAll(',', '')) }],
              )}
              inputProps={{
                style: {
                  padding: 14,
                  height: 16.6,
                },
              }}
              // eslint-disable-next-line react/jsx-no-duplicate-props
              InputProps={{
                inputComponent: NumberFormatRMAArea,
              }}
              style={{ width: 144, minWidth: 144 }}
            />
          )}
        </Box>

        {/* Make sure the date exists before displaying it */}
        { (dates?.length > 0 && dates[fieldIndex][opIndex]) && dateSelection() }

        <Box p={1}>
          <Box display="flex" alignItems="center">
            <span style={{ marginRight: 4 }}>Share %</span>
            { elementInclusionIcon('sharePercentage') }
          </Box>
          <TextField
            className={classes.selects}
            variant="outlined"
            value={operationInfo.sharePercentage}
            onBlur={(e) => updateOperation(
              farmIndex, fieldIndex, opIndex, [{ sharePercentage: Number(e.target.value.replaceAll(',', '')) }],
            )}
            inputProps={{
              style: {
                padding: 14,
                textAlign: 'center',
                height: 16.6,
              },
            }}
            // eslint-disable-next-line react/jsx-no-duplicate-props
            InputProps={{
              inputComponent: NumberFormatRMAArea,
              endAdornment: '%',
            }}
            style={{ width: 144, minWidth: 144, background: displayBackground(operationInfo.sharePercentage) }}
          />
        </Box>
      </Box>

      <Box display="flex" flexWrap="wrap" alignItems="flex-end">
        { operationInfo.CLUInfo?.length > 1 && (
          <>
            <Box p={1}>
              {operationInfo.CLUInfo.length !== included.length ? (
                <CustomToolTip placement="right" title="Include all CLUs in review screen, pdf, and report">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => inExCludeAll(true)}
                    disableElevation
                  >
                    <VisibilityIcon fontSize="small" style={{ marginRight: 6 }} />
                    Include All CLUs
                  </Button>
                </CustomToolTip>
              ) : (
                <CustomToolTip placement="right" title="Exclude all CLUs from review screen, pdf, and report">
                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => inExCludeAll(false)}
                    disableElevation
                  >
                    <VisibilityOffIcon fontSize="small" style={{ marginRight: 6 }} />
                    Exclude All CLUs
                  </Button>
                </CustomToolTip>
              )}
            </Box>

            <Box p={1}>
              {operationInfo.CLUInfo.length !== expanded.length ? (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={selectAll}
                  disableElevation
                >
                  <MoreVertIcon style={{ marginRight: 6 }} />
                  View All CLUs
                </Button>
              ) : (
                <Button
                  color="primary"
                  variant="outlined"
                  onClick={hideAll}
                  disableElevation
                >
                  <MoreHorizIcon style={{ marginRight: 6 }} />
                  Hide All CLUs
                </Button>
              )}
            </Box>
          </>
        )}

        <Box p={1}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOptionsOpen(true)}
            disableElevation
          >
            <MoreOutlinedIcon style={{ marginRight: 10 }} />
            <span style={{ whiteSpace: 'nowrap' }}>Advanced Options</span>
          </Button>
        </Box>

        {/* Upload and Download Files */}
        <Box p={1}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => openFileUpload('operation', operationInfo.operationID, operationInfo)}
            disableElevation
          >
            <AttachFileIcon style={{ marginRight: 6 }} />
            <span style={{ whiteSpace: 'nowrap' }}>Upload File</span>
          </Button>
        </Box>
        <Box p={1}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => openFileDownload('operation', operationInfo.operationID, operationInfo)}
            disableElevation
          >
            {/* <PageviewIcon style={{ marginRight: 6 }} /> */}
            <PageviewOutlinedIcon style={{ marginRight: 6 }} />
            <span style={{ whiteSpace: 'nowrap' }}>View Files</span>
          </Button>
        </Box>
      </Box>

    </Box>
  );

  // Display CLU information
  const displayClus = () => (
    <Box px={1} flexGrow={1}>
      {operationInfo.CLUInfo.map((clu, i) => (
        <CluDisplay
          key={clu.zoneID}
          clu={clu}
          cluIndex={i}
          clusSeen={clusSeen}
          operationInfo={operationInfo}
          opIndex={opIndex}
          fieldIndex={fieldIndex}
          farmIndex={farmIndex}
          additionalOptions={additionalCluOptions}
          statesSeen={statesSeen}
          commoditiesSeen={commoditiesSeen}
          commodityTypesSeen={commodityTypesSeen}
          updateIntersection={updateIntersection}
          editOperationWithClu={editOperationWithClu}
          expanded={expanded}
          setExpanded={setExpanded}
          included={included}
          setIncluded={setIncluded}
        />
      ))}
    </Box>
  );

  return (
    <Box className={classes.root} boxShadow={3}>
      <Accordion defaultExpanded>
        <AccordionSummaryCentered
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
          <Box className={classes.header}>
            { displayOperationName(operationInfo) }
          </Box>
        </AccordionSummaryCentered>

        <AccordionDetails>
          {/* Display an error message if one was found */}
          { operationInfo?.error ? (
            <Box my={1} display="flex">
              <Box m={1}>
                {Array.isArray(operationInfo.error) && operationInfo.error[0] === 'noMatch' ? (
                  <Box className={classes.errorMessageBox} justifyContent="space-between">
                    {operationInfo.error[1]}

                    <HighlightOffIcon
                      style={{ cursor: 'pointer', marginLeft: '24px' }}
                      onClick={() => { updateOperation(farmIndex, fieldIndex, opIndex, [{ error: undefined }]); }}
                    />
                  </Box>
                ) : (
                  <Box className={classes.errorMessageBox}>
                    {operationInfo.error}
                  </Box>
                )}
              </Box>
            </Box>
          ) : (
            <Box mb={1} display="flex" flexWrap={width > 900 ? 'nowrap' : 'wrap'}>
              {/* Display operation and CLU information */}
              <Box pr={1}>
                { options() }

                {(operationInfo?.CLUInfo && width >= displayBreakPoint) && (
                  <Box display="flex" maxWidth={width - 540} minWidth={380}>
                    { displayClus() }
                  </Box>
                )}
              </Box>

              {/* Display CLU information - do not need CLUInfo if 'GART' zoneType */}
              {/* If cluBoundaries is [], then all related CLU Info (clusSeen, setSelectedClu) can be ignored and map works correctly still */}
              { ((!gartPath && operationInfo?.CLUInfo) || (gartPath)) && (
                <Box className={classes.mapContainer}>
                  <UpdateOperationsMap
                    cluBoundaries={!gartPath ? operationInfo.CLUInfo : []}
                    clusSeen={clusSeen}
                    expanded={expanded}
                    gartPath={gartPath}
                    height="100%"
                    impersonating={impersonating}
                    indexes={{
                      field: fieldIndex,
                      farm: farmIndex,
                      operation: opIndex,
                    }}
                    operation={operationInfo}
                    setSelectedClu={handleSelect}
                    updateIntersections={updateIntersections}
                  />
                </Box>
              )}
            </Box>
          )}
        </AccordionDetails>
      </Accordion>

      {/* Display CLU information for mobile */}
      { (!operationInfo?.error) && (operationInfo?.CLUInfo && width < displayBreakPoint) && (
        <Box pb={1} display="flex">
          {displayClus()}
        </Box>
      )}

      {/* Advanced Options modal */}
      { optionsOpen && (
        <AdvancedOptions
          gartPath={gartPath}
          inclusionControl={elementInclusionIcon}
          indexes={{ farmIndex, fieldIndex, opIndex }}
          mismatchOptions={mismatchOptions}
          open={optionsOpen}
          operation={operationInfo}
          setOpen={setOptionsOpen}
          updateOperation={updateOperation}
        />
      )}
    </Box>
  );
}

OperationDisplay.propTypes = {
  clusSeen: PropTypes.shape().isRequired,
  commoditiesSeen: PropTypes.shape().isRequired,
  commodityTypesSeen: PropTypes.shape().isRequired,
  editOperationWithClu: PropTypes.func.isRequired,
  dates: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.string,
    )
  ).isRequired,
  farmIndex: PropTypes.number.isRequired,
  fieldIndex: PropTypes.number.isRequired,
  gartPath: PropTypes.bool.isRequired,
  impersonating: PropTypes.string.isRequired,
  mismatchOptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  openFileDownload: PropTypes.func.isRequired,
  openFileUpload: PropTypes.func.isRequired,
  // While a lot of these will typically be Required, if there is an error, then they are not...
  operationInfo: PropTypes.oneOfType([
    PropTypes.shape({
      error: PropTypes.string.isRequired,
    }),
    PropTypes.shape({
      acres: PropTypes.number.isRequired,
      crop: PropTypes.string.isRequired,
      crops: PropTypes.string.isRequired,
      croppingPracticeCode: PropTypes.number.isRequired,
      cropRowCount: PropTypes.number,
      cropRowWidth: PropTypes.number,
      cropType: PropTypes.string.isRequired,
      cropTypes: PropTypes.string.isRequired,
      CLUInfo: PropTypes.arrayOf(PropTypes.shape({
        shown: PropTypes.bool.isRequired,
        zoneID: PropTypes.string.isRequired,
      })),
      cluProducerReviewRequestIndicator: PropTypes.string,
      error: PropTypes.oneOfType([
        PropTypes.array,
        PropTypes.string,
      ]),
      finalReportedAcreage: PropTypes.number,
      includeGeospatialInReport: PropTypes.string.isRequired,
      intendedUse: PropTypes.string.isRequired,
      intendedUses: PropTypes.string.isRequired,
      irrigationPracticeCode: PropTypes.string.isRequired,
      micsCode: PropTypes.string.isRequired,
      micsName: PropTypes.string.isRequired,
      operationID: PropTypes.string.isRequired,
      organicPracticeTypeCode: PropTypes.string.isRequired,
      productPlantingCode: PropTypes.string,
      reportedAcreageModifiedIndicator: PropTypes.string,
      sharePercentage: PropTypes.number.isRequired,
      shown: PropTypes.bool,
      skipRowConversionFactor: PropTypes.number,
      skipRowCount: PropTypes.number,
      skipRowWidth: PropTypes.number,
      skipRowPatternCode: PropTypes.string,
      stateCode: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      varietyList: PropTypes.string.isRequired,
      varietyNames: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
      year: PropTypes.number.isRequired,
      zoneID: PropTypes.string,
      zoneIDsFromDB: PropTypes.arrayOf(PropTypes.string.isRequired),
    })
  ]).isRequired,
  opIndex: PropTypes.number.isRequired,
  setDates: PropTypes.func.isRequired,
  statesSeen: PropTypes.shape({
    stateAbbr: PropTypes.shape(),
  }).isRequired,
  updateIntersection: PropTypes.func.isRequired,
  updateIntersections: PropTypes.func.isRequired,
  updateOperation: PropTypes.func.isRequired,
};

import React from 'react'
import { FieldSelection } from "../FieldSelection/FieldSelection";
import { FieldManager } from '../FieldManager/FieldManager'

export function Digitize() {

  const changeSection = () => {
    //console.log('')
  }

  return (
    <FieldManager
      setSection={changeSection}
      setPlStep={changeSection}
      digitizing={true}
    />
  )
}

import React from "react";
import { makeStyles } from "@material-ui/core";
import { pointer } from "d3-selection";
import { COLORS } from "../../../../constants";

const useStyles = makeStyles((theme) => ({
  field: {
    stroke: "none",
    cursor: "pointer",
  },
  farm: {
    cursor: "pointer",
    stroke: COLORS.text,
    strokeWidth: "2px",
  },
  label: {
    textAnchor: "middle",
    fontSize: "14px",
    stroke: "none",
    fontWeight: "bold",
  },
}));

const ACTIVE_OPACITY = 1;
const INACTIVE_OPACITY = 0.2;

const FieldMarker = ({
  marker,
  setState,
  radius = 5,
  xAccessor = (d) => d.xPosition,
  yAccessor = (d) => d.yPosition,
  onlyOneVariable = false,
  isFarm = false,
}) => {
  const classes = useStyles();
  const { color, isActive, label, labelColor } = marker;
  const onMouseEnter = (evt) => {
    const [left, top] = pointer(evt);
    setState((prevState) => ({
      ...prevState,
      activeMarker: { ...marker, top, left, onlyOneVariable },
    }));
  };

  return (
    <g className="field__marker">
      <circle
        className={isFarm ? classes.farm : classes.field}
        cx={xAccessor(marker)}
        cy={yAccessor(marker)}
        r={radius}
        style={{
          fill: color,
          fillOpacity: isActive ? ACTIVE_OPACITY : INACTIVE_OPACITY,
          strokeOpacity: isActive ? ACTIVE_OPACITY : INACTIVE_OPACITY,
        }}
        onMouseEnter={onMouseEnter}
      />
      {label && (
        <text
          className={classes.label}
          dy={-radius * 2}
          x={xAccessor(marker)}
          y={yAccessor(marker)}
          style={{
            fill: labelColor,
            fillOpacity: isActive ? ACTIVE_OPACITY : INACTIVE_OPACITY,
          }}
        >
          {label}
        </text>
      )}
    </g>
  );
};

export default FieldMarker;

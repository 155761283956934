import React from "react";
import { Box, Modal } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useWindowDimensions } from "../../../../utils/dimensions";
import { EditRanges } from './EditRanges'



const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.modalPaper,
    justifyContent: 'space-between',
  },
}));

export function EditRangesModal({
  open,
  closeModal,
  legend,
  applyUpdate,
  binRange,
  isOperation,
  map,
  pushMap,
  netSeededAcres,
  savedTemplate,
  allTemplates,
  perAcre,
  mapLoading,
  selectedTemplate,
  setSelectedTemplate,
  templateRes,
  setTemplateRes,
}){
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <Modal
      open={open}
      onClose={() => closeModal()}
      style={{display:'flex', alignItems:'center', justifyContent:'center'}}
    >
      <Box
        className={classes.paper}
        borderRadius="borderRadius"
        style={{
          top: '5%',
          margin:'auto',
          zIndex: 1002,
          overflowY: 'auto',
          height: 'auto',
          minHeight: 400,
          maxHeight: height - 80,
          width: width > 840 ? 800 : width - 40,
          minWidth: 400,
        }}
      >
        <EditRanges
          legend={legend}
          applyUpdate={applyUpdate}
          binRange={binRange}
          isOperation={isOperation}
          map={map}
          pushMap={pushMap}
          netSeededAcres={netSeededAcres}
          perAcre={perAcre}
          savedTemplate={savedTemplate}
          allTemplates={allTemplates}
          mapLoading={mapLoading}
          closeModal={closeModal}
          selectedTemplate={selectedTemplate}
          setSelectedTemplate={setSelectedTemplate}
          templateRes={templateRes}
          setTemplateRes={setTemplateRes}
        />
      </Box>
    </Modal>
  )
}

import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

// MUI and Styles
import {
  Box, Button, Typography, Container,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import background from '../../../images/Onboarding_LandingPage_CornField.jpg';
import { darkGreen } from '../../../styles/colors';

import { UserContext } from '../../Context/UserContext';
import { signInFromAcreage } from '../../../utils/signInLinks';
import { checkIsMobile } from '../../../utils/helpers';

const useStyles = makeStyles(() => ({
  root: {
    paddingTop: 56,
    display: 'flex',
    width: '100%',
    minHeight: 'calc(100vh - 56px)',
    flexGrow: 1,
    backgroundImage: `url(${background})`,
    backgroundPosition: 'center',
    backgroundRepeat: 'noRepeat',
    backgroundSize: 'cover',
    overflowY: 'auto',
    fontFamily: 'OpenSans',
  },
  header: {
    display: 'flex',
    justifyContent: 'center',
    minHeight: 180,
  },
  text: {
    color: 'white',
    fontWeight: 600,
    fontSize: '2rem',
    textAlign: 'center',
    lineHeight: 1.25,
  },
  newUserButton: {
    margin: 8,
    textTransform: 'none',
    fontSize: '2rem',
    fontFamily: 'OpenSans-SemiBold',
    backgroundColor: 'white',
    color: darkGreen,
    width: 240,
    borderRadius: 16,
    '&:hover': {
      backgroundColor: '#fafafa',
    },
  },
  returningButton: {
    margin: 8,
    textTransform: 'none',
    fontSize: '2rem',
    fontFamily: 'OpenSans',
    fontWeight: 600,
    color: 'white',
    border: '3px solid white',
    width: 240,
    borderRadius: 16,
  },
}));

/**
 * Rendered in AcreageReporting/AcreageHome.js
 * Initial page uer will see when visits Acreage Reporting. From here, is user is
 * returning they may navigate directly to reporting. A new user (or any who wishes to)
 * will be able navigate to the on boarding process (component located in Onboarding.js).
 * @param {Number} pickFormat Step number for final page if user skips: 99.5
 * @param {Function} setInitialStep Expected options: 99.5
 * @param {Function} setStage Expected options: 'landing', 'onboarding', 'reporting'
 * @returns {JSX} Landing Page
 */
export const Landing = ({
  pickFormat,
  setInitialStep,
  setStage
}) => {
  const classes = useStyles();
  const user = useContext(UserContext)[0];
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    setIsMobile(checkIsMobile());
  }, []);

  const handleReturning = () => {
    if (user?.isAuthenticated) {
      setInitialStep(pickFormat);
      setStage('onboarding');
    } else {
      signInFromAcreage();
    }
  };

  const startOnboarding = () => {
    window.scrollTo(0, 0);
    setStage('onboarding');
  };

  return (
    <Box className={classes.root}>
      <Container maxWidth="lg" style={{ display: 'flex' }}>
        <Box
          display="flex"
          flexDirection="column"
          flexGrow={1}
        >
          {/* Title */}
          <Box pt="24px" className={classes.header}>
            <Typography
              align="center"
              style={{
                fontSize: isMobile ? 52 : 62,
                lineHeight: isMobile ? 1.2 : 1.5,
                fontFamily: 'OpenSans',
                color: 'white',
                fontWeight: 700,
              }}
            >
              Acreage Reporting Made Simple
            </Typography>
          </Box>

          {/* Overview */}
          <Box pt={isMobile ? '32px' : '24px'} display="flex" justifyContent="center">
            <Box maxWidth={1020}>
              <Typography className={classes.text}>
                PARS&trade; simplifies the acreage reporting process for you.
                <br></br>
                After integrating your precision data, PARS&trade; will generate your acreage report. You can submit this report directly to the USDA Clearinghouse for distribution to FSA and your AIP. 
                <br></br>
                Let PARS&trade; save you time and money today!
              </Typography>
            </Box>
          </Box>

          {/* New/Returning Users Buttons */}
          <Box pt="32px" mb="32px" display="flex" justifyContent="center" minHeight={180}>
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="flex-end"
              justifyContent="space-around"
              width="100%"
              maxWidth={1020}
            >
              <Box>
                <Button
                  className={classes.newUserButton}
                  variant="contained"
                  onClick={startOnboarding}
                >
                  New Users
                </Button>
              </Box>

              <Box>
                <Button
                  className={classes.returningButton}
                  variant="outlined"
                  onClick={handleReturning}
                >
                  Returning?
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

Landing.propTypes = {
  setStage: PropTypes.func.isRequired,
};

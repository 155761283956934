/* eslint-disable max-len */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Container,
  Typography,
  Divider,
  Button,
} from '@material-ui/core';
import { UserContext } from '../../Context/UserContext';
import { numFormat } from '../../../utils/helpers';
import { Endpoints } from '../../../constants/Endpoints';
import { integrationLinks } from '../../Partners/Connect';
import { blackText } from '../../../styles/colors';

/**
 * Order successfully sent and summary page
 * @param {Object} order Order returned from post request
 * @param {Bool} includesFields Order includes fields or is for single order
 * @param {Bool} forIframe If hosted on DIGS site
 * @returns {JSX} Order summary, Links, sign in/up
 */
export function Success({ 
  order, 
  includesFields, 
  forIframe,
  ads,
  discount 
}) {
  const user = useContext(UserContext)[0];

  const orderSummary = (subOrder, i) => (
    <Box key={i} p={1} fontWeight={500} fontSize={16}>

      <Box display="flex">
        {
            includesFields
            && (
            <Box>
              {'Field: '}
              {subOrder.fieldName}
            </Box>
            )
          }

        <Box px={3}>
          {'Acres:  '}
          {numFormat(subOrder.acres)}
        </Box>
      </Box>

      <Divider />

      <Box p={1} display="flex" fontWeight={500} fontSize={14}>

        <Box style={{ width: '60%' }} borderBottom={1}>
          Service
        </Box>

        <Box style={{ width: '20%' }} borderBottom={1}>
          Price / Acre
        </Box>

        <Box style={{ width: '20%' }} borderBottom={1}>
          Total
        </Box>
      </Box>

      {
          subOrder.digsReports.map((x, j) => (
            <Box key={j} p={1} display="flex" fontWeight={500} fontSize={14}>
              <Box style={{ width: '60%' }}>
                {x.name}
              </Box>

              <Box style={{ width: '20%' }}>
                {'$'}
                {numFormat(x.price)}
              </Box>

              <Box style={{ width: '20%' }}>
                {'$'}
                {numFormat(x.price * subOrder.acres)}
              </Box>
            </Box>
          ))
        }

      <Divider />
    </Box>

  );

  return (
    <Container maxWidth="md">
      <Box>
        <Box>

          <Box
            p={2}
            boxShadow={1}
            borderRadius="borderRadius"
            fontWeight={500}
            color={blackText}
            fontSize={16}
            style={{ background: '#ffffff' }}
          >
            <Typography align="center" variant="h5">
              Thank you for your order!
            </Typography>

            <Box p={1}>
              <Typography align="center" style={{ fontSize: '1.1rem', fontWeight: 500 }}>
                A DIGS consultant will be in contact with you shortly to schedule your services.
              </Typography>

            </Box>

            {
              !forIframe
              && (
              <Box>
                <Box p={1}>
                  To discover more of what we offer visit
                  {' '}
                  <a style={{ textDecoration: 'none' }} href="https://www.profit.ag/" target="_blank" rel="noreferrer">Profit.ag</a>
                </Box>

                <Box p={1}>
                  View your fields soil data, current weather, and more at
                  {' '}
                  <a style={{ textDecoration: 'none' }} href={`${Endpoints.HOME}/app/DataLayers`}>
                    Data Layers
                    <sup>&trade;</sup>
                  </a>
                  .
                </Box>

                {
                  user.isAuthenticated

                    ? (
                      <Box p={1}>
                        Generate custom Profit Layer maps in minutes and easily breakdown ROI by product and field,
                        at
                        {' '}
                        <a style={{ textDecoration: 'none' }} href={`${Endpoints.HOME}/app/ProfitLayers`}>
                          ProfitLayers
                          <sup>&reg;</sup>
                        </a>
                        .
                      </Box>
                    )

                    : (
                      <Box p={1}>
                        <Box>
                          To explore more of our tools and services please sign in or create an account
                        </Box>
                        <Box display="flex" justifyContent="center" flexWrap="wrap">
                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => window.location.assign(Endpoints.BASEURL + Endpoints.SIGNIN)}
                            style={{ margin: 8 }}
                          >
                            Sign In
                          </Button>

                          <Button
                            color="primary"
                            variant="outlined"
                            onClick={() => window.location.assign(Endpoints.BASEURL + Endpoints.SIGNIN)}
                            style={{ margin: 8 }}
                          >
                            Create Account
                          </Button>
                        </Box>

                      </Box>
                    )
                }

                <Box p={1}>
                  <Box>
                    Easily link your Climate FieldView, John Deere Operations Center, or CNH Equipment for free.
                  </Box>
                  { integrationLinks() }
                </Box>
              </Box>
              )
            }

          </Box>
        </Box>

        <Box
          my={2}
          boxShadow={1}
          borderRadius="borderRadius"
          style={{ backgroundColor: '#ffffff' }}
        >
          <Typography align="center" variant="h6">
            Order Summary
          </Typography>

          <Divider style={{ marginBottom: '10px' }} />

          { order.subOrderDetails.map((x, i) => orderSummary(x, i)) }

          {/* {
            includesFields ?
            order.subOrderDetails.map((x, i) => orderSummary(x, i)) :
            order.subOrderDetails.map((x, i) => simpleSummary(x, i))
          } */}

          <Box
            p={2}
            display="flex"
            justifyContent="flex-end"
            flexGrow={1}
            fontWeight={500}
            fontSize={18}
          >
            {ads
              ?
              <Box style={{ marginRight: '20px', display:'flex', flexDirection:'column' }}>
                <Box>
                  {'Sub-Total : $'}
                  {numFormat(order.totalCost)}
                </Box>
                <Box>
                  {'Discount : -$'}
                  {numFormat(discount)}
                </Box>
                <Box>
                  {'Total : $'}
                  {numFormat(order.totalCost - discount)}
                </Box>
              </Box>
              :
              <Box style={{ marginRight: '20px' }}>
                <Box>
                  {'Total : $'}
                  {numFormat(order.totalCost)}
                </Box>

              </Box>
            }
          </Box>
        </Box>

      </Box>
    </Container>
  );
}

Success.propTypes = {
  order: PropTypes.object.isRequired,
  includesFields: PropTypes.bool.isRequired,
  forIframe: PropTypes.bool.isRequired,
};

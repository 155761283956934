/* eslint-disable camelcase */
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  Select,
  MenuItem,
  Divider,
  IconButton,
} from '@material-ui/core';
import GetAppRoundedIcon from '@material-ui/icons/GetAppRounded';
import { useSnackbar } from 'notistack';
import { makeStyles } from '@material-ui/core/styles';
import { MenuProps } from '../../../styles/select';
import {
  convertShapeToTIFF,
} from '../../../utils/dataFetchers';
import { ResultsMap } from './Map/ResultsMap';
import { Endpoints } from '../../../constants/Endpoints';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { fileDisplay } from './helpers';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: 16,
    color: theme.palette.text.primary,
  },
  selections: theme.selections,
}));

/**
 * Shape file to geoTIFF conversion and map display
 * @param {String} boundary Stringified field boundary
 * @param {Object} shapeFiles Files to be selected for GeoJSON conversion and display
 * @param {Array} shapesWithoutAttributes Shape files that have been converted without dbf file
 * @param {Funtion} getGeoJson Converts shape file to geoJsons and adds to list
 * @param {String} token To make back end requests for non-logged in users
 * @returns {JSX} Shape file converter
 */
export const Converter = ({
  boundary, shapeFiles, getGeoJson, shapesWithoutAttributes, token,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [selected, setSelected] = useState(shapeFiles[0] ? shapeFiles[0] : '');
  const [selectedFile, setSelectedFile] = useState('');
  const [selectedFieldName, setSelectedFieldName] = useState('');
  const [downloadables, setDownloadables] = useState([]);

  const [shapeFileOptions, setShapeFileOptions] = useState([]);

  // Map display
  const [geoJson, setGeoJson] = useState(null);

  useEffect(() => {
    if (shapeFiles?.length) {
      const shapesWithAttributes = shapeFiles.filter(
        (file) => !shapesWithoutAttributes.includes(file),
      );
      setShapeFileOptions(shapesWithAttributes);

      if (shapesWithAttributes?.length && selected !== shapesWithAttributes[0]) {
        handleChange(shapesWithAttributes[0]);
      }
    }
  }, [shapeFiles, shapesWithoutAttributes]);

  const handleChange = async (path) => {
    try {
      const requestUrl = path.replace('#', '%23');
      setSelected(path);

      const convertedShape = await getGeoJson(requestUrl);
      if (convertedShape?.GeoJSON) {
        setGeoJson(convertedShape.GeoJSON);
      }

      const file = await convertShapeToTIFF(requestUrl, 0, null, token);

      if (file?.Shape_FieldNames) {
        setSelectedFile(file);
      } else {
        invalidConversion(path);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const invalidConversion = (path) => {
    enqueueSnackbar(
      `We didn't find any attributes for ${fileDisplay(path)}. For your convience, we've removed it from available options and selected the next available file.`,
      { autoHideDuration: 9000 },
    );
    try {
      // Index of selected file
      const index = shapeFileOptions.indexOf(path);

      if ((index + 1) < shapeFileOptions?.length) {
        handleChange(shapeFileOptions[index + 1]);
      } else if (shapeFileOptions?.length) {
        handleChange(shapeFileOptions[0]);
      }
    } catch (err) {
      console.error(err);
    }
  };

  const handleFieldChange = async (field) => {
    try {
      setLoading(true);
      setSelectedFieldName(field);
      const requestUrl = selected.replace('#', '%23');
      const newFile = await convertShapeToTIFF(requestUrl, 0, field.name, token);

      setLoading(false);
      if (newFile?.File_ID && newFile?.Shape_FieldNames[0]?.name) {
        setDownloadables((prev) => [...prev, { file: newFile, shape: requestUrl }]);
        window.location.assign(`${Endpoints.BASEURL}${Endpoints.DOWNLOADRASTERFILE}${'GeoTIFF'}&FileID=${newFile.File_ID}&Name=${newFile.Shape_FieldNames[0].name}`);
      } else {
        enqueueSnackbar('Unable to download selected file');
      }
    } catch (err) {
      enqueueSnackbar('Unable to download selected file');
      console.error(err);
    }
  };

  const downloadButton = (download, i) => {
    const shapeName = download.shape.split('/')[download.shape.split('/').length - 1];
    const fileName = download.file.Shape_FieldNames[0].name;
    const name = `${shapeName} - ${fileName}`;
    const fileLink = `${Endpoints.BASEURL}${Endpoints.DOWNLOADRASTERFILE}${'GeoTIFF'}&FileID=${download.file.File_ID}&Name=${fileName}`;
    return (
      <Box
        key={`${name}-${i}`}
        my={1}
        display="flex"
        flexDirection="column"
      >
        <Box
          display="flex"
          alignItems="center"
        >

          <IconButton
            color="primary"
            id={`download-${name}-${i}`}
            href={fileLink}
          >
            <GetAppRoundedIcon />
          </IconButton>

          <Typography variant="subtitle2" style={{ margin: '0 10px' }}>
            { name }
          </Typography>
        </Box>
        <Divider />
      </Box>
    );
  };

  return (
    <Box className={classes.root}>
      <Box display="flex" flexWrap="wrap" width="100%">
        <Box pr={1}>
          <Box>
            <Typography variant="h6">
              Easily convert your Shape Files to downloadable GeoTIFFs.
            </Typography>
            <Box pl={1}>
              <Typography>
                First select a Shape File from the dropdown.
              </Typography>
              <Typography>
                Once its loaded, selected the variable you would like to convert.
              </Typography>
              <Typography>
                After the conversion is complete, your field download will begin automatically.
              </Typography>
            </Box>
          </Box>

          <Divider />

          <Box mt={1} fontWeight={500}>
            Select a Shape File to convert
          </Box>
          <Box m={1}>
            <Select
              className={classes.selections}
              variant="outlined"
              value={selected || ''}
              onChange={(e) => handleChange(e.target.value)}
            >
              {shapeFileOptions.map((file) => (
                <MenuItem key={file} value={file}>
                  {fileDisplay(file)}
                </MenuItem>
              ))}
            </Select>
          </Box>

          { selectedFile?.Shape_FieldNames && (
            <Box mt={1} fontWeight={500}>
              <Box>
                Select a field to download
              </Box>
              <Box m={1}>
                <Select
                  className={classes.selections}
                  variant="outlined"
                  MenuProps={MenuProps}
                  value={selectedFieldName}
                  onChange={(e) => handleFieldChange(e.target.value)}
                  disabled={loading}
                  displayEmpty
                >
                  <MenuItem value="">
                    <em>-- Select Variable to download--</em>
                  </MenuItem>
                  {
                    selectedFile.Shape_FieldNames.map((x) => (
                      <MenuItem
                        key={x.name}
                        value={x}
                        name={x.name}
                      >

                        {x.name}
                      </MenuItem>
                    ))
                  }
                </Select>
              </Box>
            </Box>
          )}

          { downloadables?.length > 0 && (
            <Box mt={0.5}>
              <Divider />
              <Box mt={0.5} p={1}>
                <Typography>
                  Files available for download:
                </Typography>

                <Box display="flex" flexDirection="column">
                  { downloadables.map((file, i) => downloadButton(file, i)) }
                </Box>
              </Box>
            </Box>
          )}
        </Box>

        { (selected && geoJson) && (
          <Box
            my={2}
            px={1}
            py={0.5}
            display="flex"
            flexDirection="column"
            flexGrow={1}
          >
            <Typography align="center" variant="h6">
              {fileDisplay(selected)}
            </Typography>
            <Divider />

            <ResultsMap
              boundary={boundary}
              mapGeo={geoJson}
            />
          </Box>
        )}
      </Box>

      { loading && <SpinningLoader />}
    </Box>
  );
};

Converter.propTypes = {
  boundary: PropTypes.string.isRequired,
  shapeFiles: PropTypes.array.isRequired,
  shapesWithoutAttributes: PropTypes.array.isRequired,
  getGeoJson: PropTypes.func.isRequired,
  token: PropTypes.string.isRequired,
};

import { Endpoints } from '../constants/Endpoints';

export const signInFromAcreageOnboarding = () => {
  window.location.assign(Endpoints.BASEURL + Endpoints.SIGNINFROMACREAGEONBOARD);
};

export const signUpFromAcreageOnboarding = () => {
  window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUPFROMACREAGEONBOARD);
};

export const signInFromAcreage = () => {
  window.location.assign(Endpoints.BASEURL + Endpoints.SIGNINFROMACREAGE);
};

export const signUpFromAcreage = () => {
  window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUPFROMACREAGE);
};

export const integratePrecision = (integrating, pageSource, inplace=false) => {
  if (integrating === 'deere') {
    const deereLink = `${Endpoints.BASEURL}${Endpoints.DEEREINTEGRATE}?source=`;
    if (!inplace){
      window.open(deereLink + pageSource, '_blank');
    }
    else{
      window.location.replace(deereLink + pageSource)
    }
  } else if (integrating === 'climate') {
    const climateLink = `${Endpoints.BASEURL}${Endpoints.CLIMATEINTEGRATE}?source=`;
    if(!inplace){
      window.open(climateLink + pageSource, '_blank');
    }
    else{
      window.location.replace(climateLink + pageSource)
    }
  } else if (integrating === 'cnh') {
    const cnhLink = `${Endpoints.BASEURL}${Endpoints.CNHINTEGRATE}?source=`;
    if(!inplace){
      window.open(cnhLink + pageSource, '_blank');
    }
    else{
      window.location.replace(cnhLink + pageSource)
    }
  }
};

import React from 'react';
import {
  Box,
  Tooltip,
  Divider,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { grey, darkGrey, lightGrey } from '../../styles/colors';
import { numFormat, exists, dollarFormat } from '../../utils/helpers';

// Takes two arrays of objects to create table from
export const createTable = (
  premiumValues,
  liabilityValues,
  yieldGuarantee,
  allAcres
) => {

  const iconStyle = {
    marginLeft: '2px',
    marginBottom: '10px',
    fontSize: "14px",
    color: darkGrey,
    textDecoration: 'none'
  };

  return (
    <Box
      id='insurance-table'
      m={1}
      align="center"
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      style={{ minWidth: '400px', maxWidth: '1000px', overflowX: 'auto' }}
    >
      <Box
        p={1}
        display="flex"
        fontSize={16}
        alignItems='center'
      >
        <Box pl={1} mx={1} style={{ width: '26%', whiteSpace: 'nowrap'  }}>
          Coverage
          <Tooltip
            placement="right-start"
            title="Coverage level is an election made by you, which determines what percent of your expected yield is covered. It is similar to a deductible. For example, if your expected yield (your average historical yield, known as Approved Yield) is 100 bushels/acre, and you elect a 75% Coverage Level, then you will get a loss payment if your yield is less than 75 bushels/acre due to insured causes."
          >
            <InfoOutlinedIcon style={iconStyle} />
          </Tooltip>
        </Box>

        <Box pl={1} mx={1} style={{ width: '37%', whiteSpace: 'nowrap'  }}>

          Premium
          {!allAcres && '/Acre'}

          <Tooltip
            placement="right-start"
            title="Premium is the amount you pay for insurance. While you have to enroll prior to Sales Closing, you do not actually pay your premium until around harvest time. The Premiums are intended to be priced so that over time, you should on average collect more in loss payments than you pay into premium since it is subsidized, though this may vary from year-to-year ,and producer-to-producer. So, you shouldn't expect to recieve loss payments every year. Only you know if it is a good investment for your operation."
          >
            <InfoOutlinedIcon style={iconStyle} />
          </Tooltip>
        </Box>

        <Box mx={1} style={{ width: '37%' }}>
          Liability
          {!allAcres && '/Acre'}
        </Box>
        { yieldGuarantee && (
          <Box mx={1} style={{ width: '37%', whiteSpace: 'nowrap' }}>
            Yield Guarantee
          </Box>
        )}
      </Box>

      <Divider style={{ color: grey, height: 4 }} />
      {
        premiumValues.map((x, i) => createRow(x, i, liabilityValues, yieldGuarantee))
      }
    </Box>
  );
};

/**
 * Takes Object from an array, it's index, and a seconds array. Display
 * coverage % from first array (if needed, sort in calling function to ensure
 * match) and value. Use index to get matching object from second array and
 * display value in third column. In this component, premium values with map
 * object X and liability values will be arr2. For now terms are being kept
 * generic incase this table and row creation is needed in other places.
 * @param  {Object} x    Entry from first array
 * @param  {Number} i    Index
 * @param  {Array} arr2 Get value from this array on matching index from array 1
 * @return {JSX}      Table row
 */
const createRow = (x, i, arr2, yieldGuarantee) => (
  <Box
    key={i}
    p={1}
    display="flex"
    alignItems="center"
    style={{ backgroundColor: i % 2 == 0 ? '#ffffff' : lightGrey }}
  >
    <Box style={{ width: '26%' }}>
      {x.name}
    </Box>

    <Box style={{ width: '37%' }}>
      {dollarFormat(x.value)}
    </Box>

    <Box style={{ width: '37%' }}>
      {dollarFormat(arr2[i].value)}
    </Box>

    { yieldGuarantee && (
      <Box style={{ width: '37%' }}>
        {numFormat(yieldGuarantee[i][2])}
        {'/Acre'}
      </Box>
    )}

  </Box>
);

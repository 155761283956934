import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, CircularProgress } from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import { alertETLstatus } from '../../../utils/integrationStatus';

const useStyles = makeStyles((theme) => ({
  root: {
    fontFamily: 'OpenSans',
    color: theme.palette.text.primary,
  },
  header: {
    fontSize: 38,
    fontWeight: 700,
    marginTop: 24,
    fontFamily: 'OpenSans',
  },
  box: {
    display: 'flex',
    padding: 8,
    borderRadius: 16,
    border: `1px solid ${theme.palette.greys.dark}`,
    backgroundColor: '#d9d9d9',
  },
  text: {
    fontSize: 24,
    marginTop: 24,
    fontFamily: 'OpenSans-SemiBold',
  },
  circle: {
    marginRight: 8,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: 36,
    width: 36,
    fontSize: 16,
    borderRadius: '50%',
  },
  status: {
    margin: 8,
    display: 'flex',
    justifyContent: 'space-between',
    height: 60,
    width: 380,
  },
  statusKey: {
    fontFamily: 'OpenSans',
    fontSize: '1.1rem',
    fontWeight: 700,
    color: theme.palette.text.primary,
  },
  statusText: {
    fontSize: 18,
    fontFamily: 'OpenSans-SemiBold',
    display: 'flex',
    alignItems: 'center',
  },
  statusDisplay: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: 12,
  },
}));

/**
 * Rendered in AcreageReporting/Onboarding.js
 * Alert user to current status of integration process. User will be able
 * to see which of steps 1-4 process is currently at and the time of the
 * last status update.
 * @returns {JSX} Integration Status
 */
export const IntegrationStatus = () => {
  const classes = useStyles();

  const [integrationStatus, setIntegrationStatus] = useState('...');
  const [integrationSource, setSource] = useState('...');
  const [lastUpdated, setLastUpdated] = useState('...');

  const [step, setStep] = useState(1);

  useEffect(() => {
    // Track whether component is mounted
    let isMounted = [true];

    alertETLstatus(
      isMounted,
      integrationSource,
      setSource,
      -1,
      () => {},
      setLastUpdated,
      () => {},
      setIntegrationStatus,
      setStep,
      () => {},
    );

    return () => {
      isMounted[0] = false;
    };
  }, []);

  // Custom display components
  const completedCircle = (number) => (
    <Box className={classes.circle} color="#ffffff" bgcolor="primary.main">
      { number }
    </Box>
  );

  const inProgressCircle = (number) => (
    <Box className={classes.circle} color="primary.main" bgcolor="#ffffff" border={1} borderColor="primary.main">
      { number }
    </Box>
  );

  const waitingCircle = (number) => (
    <Box className={classes.circle} color="#767676" bgcolor="#d9d9d9" border={1} borderColor="#767676">
      { number }
    </Box>
  );

  const iconDisplay = (stepToCheck) => {
    if (stepToCheck < step) {
      return completedCircle(stepToCheck);
    } if (stepToCheck === step) {
      return inProgressCircle(stepToCheck);
    }
    return waitingCircle(stepToCheck);
  };

  const statusDisplay = (stepToCheck) => {
    if (stepToCheck < step) {
      return <DoneIcon color="primary" style={{ marginBottom: 6 }} />;
    } if (stepToCheck === step) {
      return <CircularProgress color="primary" size={32} />;
    }
    return '';
  };

  return (
    <Box className={classes.root}>
      <Typography align="center" className={classes.header}>
        Your Integration
      </Typography>

      <Box
        mt={3}
        display="flex"
        flexWrap="wrap"
        alignItems="center"
        justifyContent="center"
      >
        <Box className={classes.box} maxWidth={400} flexDirection="column">
          <Box>
            <Box display="flex" my={1}>
              <Box width="38%" className={classes.statusKey}>
                STATUS:
              </Box>
              <Box px={0.5} width="62%" fontWeight={400}>
                {integrationStatus}
              </Box>
            </Box>
            <Box display="flex" my={1} flexWrap="wrap">
              <Box width="38%" className={classes.statusKey}>
                LAST UPDATED:
              </Box>
              <Box display="flex" flexWrap="wrap" px={0.5} width="62%" fontWeight={400}>
                <span style={{ whiteSpace: 'nowrap' }}>
                  {lastUpdated.split('Time:')[0]}
                  &nbsp;
                </span>
                <span style={{ whiteSpace: 'nowrap' }}>{lastUpdated.split('Time:')[1]}</span>

              </Box>
            </Box>
            <Box display="flex" my={1}>
              <Box width="38%" className={classes.statusKey}>
                SOURCE:
              </Box>
              <Box px={0.5} fontWeight={400} width="62%">
                {integrationSource}
              </Box>
            </Box>
          </Box>

          <Box px={2} fontSize=".7rem">
            This automatic, one-time integration process may take longer depending on
            the number of fields and operations integrated.
          </Box>
        </Box>
      </Box>

      <Box m={2}>
        <Box className={classes.status}>
          <Box display="flex" alignItems="center">
            { iconDisplay(1) }
            <Box className={classes.statusText}>
              LOADING FIELD BOUNDARIES
            </Box>
          </Box>

          <Box className={classes.statusDisplay}>
            { statusDisplay(1) }
          </Box>
        </Box>

        <Box className={classes.status}>
          <Box display="flex" alignItems="center">
            { iconDisplay(2) }
            <Box className={classes.statusText}>
              LOADING OPERATIONS
            </Box>
          </Box>

          <Box className={classes.statusDisplay}>
            { statusDisplay(2) }
          </Box>
        </Box>

        <Box className={classes.status}>
          <Box display="flex" alignItems="center">
            { iconDisplay(3) }
            <Box className={classes.statusText}>
              PROCESSING OPERATIONS
            </Box>
          </Box>

          <Box className={classes.statusDisplay}>
            { statusDisplay(3) }
          </Box>
        </Box>

        <Box className={classes.status}>
          <Box display="flex" alignItems="center">
            { iconDisplay(4) }
            <Box className={classes.statusText}>
              INTEGRATION COMPLETE
            </Box>
          </Box>

          <Box className={classes.statusDisplay}>
            { statusDisplay(4) }
          </Box>
        </Box>
      </Box>

    </Box>
  );
};

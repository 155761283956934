import React, { useState, Fragment, useEffect, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Typography,
  Link,
  Checkbox,
  Divider,
  Button,
  Modal,
  Tooltip,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { blackText } from '../../../../styles/colors'
import { FieldContext } from '../../../Context/FieldContext'
import * as turf from '@turf/turf';
import { useSnackbar } from "notistack";

const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontWeight: 500,
    color: blackText,
  },
  close: {
    fontSize: 18,
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
    },
  }
}));

function getModalStyle() {
  const top = 20;
  return {
    top: `${top}%`,
    margin: 'auto',
    zIndex: 1002,
    width: 300,
    height: 240,
    padding: 4,
  };
}

export function UploadShapeFile({uploadFile, setUploadFile, displayUploadedZones}) {
  /**
   * Create new zones using shapefile
   * @param {Bool} uploadFile Determines if shape file upload is selected
   * @param {Function} setUploadFile sets state of upladFile
   * @param {Function} displayUploadedZones function from zonemap that displays converted geojson zones on map
   */

  //console.log("UploadShapeFile Component")

  const classes = useStyles();
  const modalStyle = getModalStyle();
  const { enqueueSnackbar } = useSnackbar();

  const [fieldData, setFieldData] = useContext(FieldContext)
  //const [showModal, setShowModal] = useState(uploadFile)
  const [fieldName, setFieldName] = useState("")
  const [btnDisabled, setBtnDisabled] = useState(true)
  const [shpFile, setShpFile] = useState(null)
  const [dbfFile, setDbfFile] = useState(null)
  const [file, setFile] = useState(null)

  const handleClose = () => {
    setUploadFile(false)
  }

  const handleChange = (e) => {
    //console.log("e.target.files[0]", e.target.files)
    let fileobj = e.target.files[0]

    if(e.target.files[0].name.includes(".zip")){
      setFile(e.target.files[0])
      setFieldName(e.target.files[0].name)

      setBtnDisabled(false)
      let reader = new FileReader();
      //console.log("reader", reader, e.target.files[0])
      reader.readAsArrayBuffer(e.target.files[0])
      reader.onload = function(e) {
        //console.log("reader", reader, e)
        //console.log("e.target.result", reader.result)
        let JSZip = require('jszip')
        let zip = new JSZip(reader.result)
        try{
          var shpString =  zip.file(/.shp$/i)[0].name
          var dbfString =  zip.file(/.dbf$/i)[0].name
          //console.log("shpString", shpString)

          //console.log("zip.file(shpString)", zip.file(shpString).asArrayBuffer())
          setShpFile(zip.file(shpString).asArrayBuffer())
          setDbfFile(zip.file(dbfString).asArrayBuffer())
          handleClick(fileobj, zip.file(shpString).asArrayBuffer(), zip.file(dbfString).asArrayBuffer())
        }
        catch(err){
          enqueueSnackbar("Couldn't find .shp or .dbf file!")
          setUploadFile(false)
        }
      }
    }
    else{
      enqueueSnackbar("File format is wrong, upload a zip file!")
      setUploadFile(false)
    }
  }

  const handleClick = (file1, shpFile1, dbfFile1) => {
    //console.log("handleClick")
    loadShpZip(file1, shpFile1, dbfFile1);
  }

  async function loadShpZip(file1, shpFile1, dbfFile1) {
    //console.log("file", file)
    let multiGeojsons = [];
    var shapefile = require("shapefile");
    if(file1 !== null){
     // console.log("file.stream()", file1.stream())
      shapefile.open(shpFile1, dbfFile1, null)
      .then(source => source.read()
      .then(function log(result) {
        if (result.done) return;
        //console.log(result.value);

        if(result.value.geometry.type !== 'Point' && multiGeojsons.length <= 20){
          multiGeojsons.push(result.value)
          //console.log("multiGeojsons", multiGeojsons)
          return source.read().then(log);
        }
        else if(result.value.geometry.type === 'Point'){
          enqueueSnackbar("Cannot process point shape file, try with a different file")
          setUploadFile(false)
          return
        }
    })).then(async function (data){
      let newBoundary = null, varietyList = [], zones = []
      //console.log("multiGeojsons", multiGeojsons)
      if(multiGeojsons.length > 1){
        let coordinates = []
        for(let polygon of multiGeojsons){
         // console.log("polygon", polygon)
          let modifiedPoly = JSON.parse(JSON.stringify(polygon))
          coordinates.push(modifiedPoly.geometry.coordinates)
          modifiedPoly.properties.zone = polygon
         // console.log("polygon.properties",modifiedPoly.properties)
          varietyList.push(modifiedPoly.properties)
          zones.push(modifiedPoly.geometry)
        }
        let geom = {
          type: 'MultiPolygon',
          coordinates: coordinates
        }
        newBoundary = {
          type: 'Feature',
          geometry: geom,
          properties: multiGeojsons[0].properties,
          varieties: varietyList,
          zones: zones
        }
      }else if(multiGeojsons.length === 1){
        newBoundary = {
          type: 'Feature',
          geometry: {
            type: 'Polygon',
            coordinates: multiGeojsons[0].geometry.coordinates
          },
          properties: {...multiGeojsons[0].properties},
          varieties: [multiGeojsons[0].properties],
          zones: [multiGeojsons[0].geometry]
        }
        //newBoundary = multiGeojsons[0]
      }

      newBoundary.properties.CALCACRES = turf.convertArea(turf.area(newBoundary), 'meters', 'acres')
      //console.log("newBoundary", newBoundary)

      displayUploadedZones(newBoundary);

    })
    .catch(error => console.error(error.stack));
    }
    //feature: null, coordinates: [], points: [], acres: 0
  }

  return (
    <Box>
      <Modal
        open={uploadFile}
        onClose={() => handleClose()}
        aria-labelledby="field-claim-prompt"
        aria-describedby="procede-field-claim-prompt"
        style={{
          display:'flex',
          alignItems:'center',
          justifyContent:'center'
        }}
      >
        <Box
          className={classes.root}
          style={modalStyle}
          borderRadius="borderRadius"
          boxShadow={2}
        >
          <Box>
            <Box
              display="flex"
              justifyContent='flex-end'
              style={{width: '100%'}}
            >
              <HighlightOffIcon
                className={classes.close}
                onClick={() => handleClose()}
              />
            </Box>
            <Box p={1} fontSize={16}>
              {`Upload a zipped shapefile to import and set your operation’s
                field zones, applied rates, and more`}
            </Box>
          </Box>


          <Box p={1}>
            {fieldName}
          </Box>

          <Box my={1}>
            <Button
              variant="contained"
              component="label"
              color='primary'
              disableElevation
            >
              Upload Zipped Shape File
              <input
                type="file"
                style={{ display: "none" }}
                multiple
                onChange={(e) => handleChange(e)}
                accept=".zip"
              />
            </Button>
          </Box>

        </Box>
      </Modal>
    </Box>
  )
}

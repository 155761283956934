import React, { useState, useEffect } from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { FormControlLabel } from "@material-ui/core";
import { COLOR_RANGE, COLORS, NEGATIVE_COLOR_RANGE, POSITIVE_COLOR_RANGE } from "../../../../../../constants";
import caratImage from "../../../../../../images/carat.svg";
import GreenCheckbox from "../../../../../Helpers/GreenCheckbox";
import { scaleQuantize } from "d3-scale";

const useStyles = makeStyles(() => ({
  row: {
    border: "none",
    cursor: "pointer",
  },
  cell: {
    fontSize: 14,
    color: COLORS.text,
    padding: 7,
    "&.active": {
      borderLeft: `3px solid ${COLORS.green}`,
    },
  },
  fieldName: {
    display: "flex",
    flexDirection: "row",
    alignContent: "center",
    alignItems: "center",
    "& img": {
      marginLeft: 8,
    },
  },
}));

/**
 * Renders the row of the farm overview table
 * @param {object} row  row data
 * @param {array} headerCells  array of objects with functions/information
 * needed to access data in row object
 * @param {array} fields  array of objects with field data
 * @param {function} setFields set state function
 * @param {boolean} showHeatmap for whether or not to show heat map
 */
const Row = ({
  row,
  headerCells,
  fields,
  setFields,
  showHeatmap,
  areAllActive,
  setAreAllActive,
}) => {
  const classes = useStyles();
  const fieldName = headerCells[0].accessor(row);

  useEffect(() => {
    let selectedFields = fields.filter((f) => f.active == true);
    setAreAllActive(selectedFields.length === fields.length);
  }, [fields]);

  return (
    <TableRow className={`${classes.row} ${row.active ? "active" : ""}`}>
      {headerCells.map(
        (
          { accessor, format, id, isCheckBox, scale, showCarat, heading, extentValue },
          i
        ) => {
          const matchingValue = accessor(row);
          let formattedValue = format ? format(matchingValue) : matchingValue;
          //console.log("formattedValue", formattedValue)
          formattedValue =
            typeof formattedValue === "string" &&
            formattedValue != "" &&
            formattedValue.includes("$")
              ? formattedValue + "/Acre"
              : heading.includes("%")
              ? formattedValue + "%"
              : formattedValue;
          //console.log("formattedValue", formattedValue)
          let finalScale = scale;
          
          if(extentValue != null && Math.min(...extentValue) < 0 && Math.max(...extentValue) ==0){
            finalScale = scaleQuantize()
            .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(extentValue);
          }
          else if(extentValue != null && Math.max(...extentValue) >=0 && Math.min(...extentValue) == 0){
            finalScale = scaleQuantize()
            .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
            .domain(extentValue);
          }
          else if(extentValue !== null && Math.min(...extentValue) < 0 && Math.max(...extentValue) >=0){
            //console.log("matchingValue", matchingValue)
            if(matchingValue < 0){
              finalScale = scaleQuantize()
              .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
              .domain([extentValue[0], 0]);
            }
            else if(matchingValue >= 0){
              finalScale = scaleQuantize()
              .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
              .domain([0, extentValue[0]]);
            }      
          }

          const backgroundColor = showHeatmap
            ? finalScale
              ? finalScale(matchingValue)
              : row.active
              ? "unset"
              : COLORS.white
            : row.active
            ? "unset"
            : COLORS.white;
          const color = showHeatmap
            ? finalScale
              ? COLOR_RANGE.find((d) => d.backgroundColor === backgroundColor)
              : { color: "inherit" }
            : { color: "inherit" };
          return (
            <TableCell
              key={`table-cell-for-${id}`}
              className={`${classes.cell} ${isCheckBox ? "isCheckBox" : ""}  ${
                !i && row.active ? "active" : ""
              }`}
              align={isCheckBox ? "center" : "left"}
              onClick={
                !isCheckBox
                  ? () =>
                      setFields(
                        fields.map((field) => {
                          if (field.name === fieldName) {
                            return {
                              ...field,
                              selected: true,
                            };
                          }
                          return field;
                        })
                      )
                  : () => null
              }
              style={{
                backgroundColor: matchingValue
                  ? i
                    ? backgroundColor
                    : row.active
                    ? COLORS.offWhite
                    : COLORS.white
                  : row.active
                  ? COLORS.offWhite
                  : COLORS.white,
                color: formattedValue != "$0.00/Acre" ? color.color : COLORS.text,
                border: `4px solid ${COLORS.white}`,
              }}
            >
              {isCheckBox ? (
                <FormControlLabel
                  control={
                    <GreenCheckbox
                      checked={matchingValue}
                      onChange={() => {
                        setFields(
                          fields.map((field) => {
                            if (field.name === fieldName) {
                              return {
                                ...field,
                                active: !field.active,
                              };
                            }
                            return field;
                          })
                        );
                      }}
                      name={`check-box-for-${fieldName}`}
                    />
                  }
                  label=""
                />
              ) : showCarat ? (
                <div className={classes.fieldName}>
                  {formattedValue}
                  <span>
                    <img
                      src={caratImage}
                      alt="go to a detailed view of the field"
                    />
                  </span>
                </div>
              ) : (
                formattedValue
              )}
            </TableCell>
          );
        }
      )}
    </TableRow>
  );
};
export default Row;

Row.propTypes = {
  row: PropTypes.object.isRequired,
  headerCells: PropTypes.array.isRequired,
  fields: PropTypes.array.isRequired,
  setFields: PropTypes.func.isRequired,
  showHeatmap: PropTypes.bool.isRequired,
};

import React from 'react'
import {Box, Button, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Endpoints } from '../../../constants/Endpoints'
import { useWindowDimensions } from '../../../utils/dimensions'

const useStyles = makeStyles(theme => ({
  downloadButton: {
    '&:hover': {
         cursor: 'pointer',
         color: theme.palette.primary.dark
      },
  }
}));

export function ViewDownloads({reportDownloadables, token}) {
  const {height, width} = useWindowDimensions();
  const classes = useStyles();



  const displayDownloadable = (file, i) => {
    const fileLink = token !== '' ? `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${file}&token=${token}` : `${Endpoints.BASEURL}${Endpoints.DOWNLOADPDFS}${file}`
    const extension = file.split('.')
    return (
      width > 800 ?
      <Box
        key={i}
        m={1}
        p={1}
        display='flex'
        alignItems='center'
        borderRadius='borderRadius'
        border={1}
        borderColor="greys.main"
      >
        <Button
          className={classes.downloadButton}
          style={{width: 155}}
          color='primary'
          href={fileLink}
          rel="noreferrer"
        >
          {'download '}
          {extension[extension.length-1]}
        </Button>

        <Typography style={{margin: '0 10px'}}>
          {file.split('/')[file.split('/').length - 1]}
        </Typography>
      </Box>
      :
      <Box
        key={i}
        m={1}
        p={1}
        display='flex'
        flexDirection='column'
        alignItems='center'
        borderRadius='borderRadius'
        border={1}
        borderColor="greys.main"
      >
        <Typography>
          {file.split('/')[file.split('/').length - 1].split('_').join(' ')}
        </Typography>
        <Button
          className={classes.downloadButton}
          variant='outlined'
          color='primary'
          href={fileLink}
          rel="noreferrer"
        >
          {'download '}
          {extension[extension.length-1]}
        </Button>
      </Box>
    )
  }

  return(
      <Box m={1} p={3}>
        {reportDownloadables.map((file, i) => displayDownloadable(file, i))}
      </Box>
  )
}

import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import * as L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../Maps/leaflet.css';
import { drawFieldLayer, getMinHeight } from '../../Maps/MapFunctions/helpers';
import { exists } from '../../../utils/helpers';
import { Tiles } from '../../../constants/Tiles';

export function GuidanceLinesMap({
  boundary,
  geoJSON,
  showGuidanceLines,
  height,
  location,
}) {
  const [map, setMap] = useState(null);
  const geoLayer = useRef(null);
  const fieldLayer = L.featureGroup(null);

  useEffect(() => {
    drawMap();
  }, []);

  useEffect(() => {
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      drawFieldLayer(geoJson, fieldLayer, map);
    }
  }, [boundary, map]);

  useEffect(() => {
    if (map && location === '') {
      map.setMaxZoom(17);
    }
  }, [location, map]);

  useEffect(() => {
    if (exists(geoJSON) && map !== null) {
      // Add guidance lines to map and set the color
      if (showGuidanceLines) {
        try {
          if (map.hasLayer(geoLayer.current)) {
            map.removeLayer(geoLayer.current);
          }
          geoLayer.current = L.geoJSON(geoJSON);
          // geoLayer.current.eachLayer(function(layer){
          //   layer.setStyle({
          //     color: "#3053c9",
          //   });
          // })
          geoLayer.current.addTo(map);
        } catch (e) {
          console.log(`Problem converting guidance lines data to GeoJson: ${e}`);
        }
      // Remove the lines
      } else if (geoLayer.current !== null) {
        try {
          map.removeLayer(geoLayer.current);
        } catch (e) {
          console.log(e);
        }
      }
    }
  }, [map, geoJSON, showGuidanceLines]);

  /**
   * Draw the map and add the field boundary extracted earlier
   */
  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const map = L.map('GuidanceLines-Map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current'],
    }).addTo(map);

    setMap(map);
    map.addLayer(fieldLayer);
  }

  return (
    <Box
      id="GuidanceLines-Map"
      display="flex"
      flexGrow={1}
      borderRadius={4}
      minHeight={getMinHeight()}
    />

  );
}

/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable valid-jsdoc */
// React and HTML
import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  Popover,
  Slide,
  Switch,
  TextField,
  Typography,
} from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import ArrowDropUpIcon from "@material-ui/icons/ArrowDropUp";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";

// Functionality
import { useSnackbar } from "notistack";
import { makeStyles } from "@material-ui/core/styles";
import { Endpoints } from "../../../constants/Endpoints";
import { GuidanceLinesMap } from "./GuidanceLinesMap";
import { getAndConvertGuidanceLines } from "../../../utils/dataFetchers";
import { consoleImportant, exists, sleep } from "../../../utils/helpers";
import {
  NumberFormatThree,
  NumberFormatCustom,
} from "../../../utils/NumberFormatCustom";
import { CustomToolTip } from "../../../utils/customComponents";
import { SpinningLoader } from "../../Shared/SpinningLoader";
import { useWindowDimensions } from "../../../utils/dimensions";

// Style
import { blackText, darkText, green } from "../../../styles/colors";

import { GetSoilTesting, IntegrationLinks } from "../../Shared/ServiceLinks";

const useStyles = makeStyles((theme) => ({
  disabledInput: theme.disabledInput,
  errorMessageBox: theme.errorMessageBox,
  infoIcon: {
    ...theme.infoToolTip,
    marginLeft: "2px",
    marginBottom: "10px",
  },
  input: {
    width: "150px",
    margin: "0px",
  },
  inputName: {
    height: 25,
    whiteSpace: "nowrap",
  },
  section: {
    // margin: '8px 0px',
  },
  sectionLeft: {
    margin: "8px 0px",
    marginRight: "24px",
  },
}));

/**
 * Guidance lines
 * @param {Bool} loading Data is loading
 * @returns {JSX} Guidance lines
 */
export function GuidanceLines({
  field,
  loading,
  setLoading,
  errorMessage,
  setErrorMessage,
  mobileBreakPoint,
  precisionField,
  onConnectClick,
}) {
  const classes = useStyles();
  const { width, height } = useWindowDimensions();

  // Toggle imagery
  const [showGuidanceLines, setShowGuidanceLines] = useState(true);
  const { enqueueSnackbar } = useSnackbar();

  // Component data
  const [machineWidth, setMachineWidth] = useState(50);
  const [lateralOverlap, setLateralOverlap] = useState(0.5);
  const [oldMachineWidth, setOldMachineWidth] = useState(50);
  const [oldLateralOverlap, setOldLateralOverlap] = useState(0.5);
  const [optimalAngle, setOptimalAngle] = useState([]);
  const [minNumPasses, setMinNumPasses] = useState([]);
  const [isMultiPolygon, setIsMultiPolygon] = useState(false);
  const [hideNote, setHideNote] = useState(false);
  const [geoJSON, setGeoJSON] = useState({});
  const [responseGeoJSON, setResponseGeoJSON] = useState({});
  const [showMap, setShowMap] = useState(true);

  // Message to show when area is too small for the stripe size (taken directly from flask code)
  const smallSizeMsg =
    "You can cover this feature with just a single stripe. Nothing will be computed. You may want to use a smaller stripe width.";

  // For downloads
  const [wrongInput, setWrongInput] = useState(false);
  const [noData, setNoData] = useState(true);
  const [downloadsPopoverOpen, setPopoverOpen] = useState(false);
  const [downloadsPopoverAnchor, setPopoverAnchor] = useState(null);

  useEffect(() => {
    if (exists(field.boundary)) {
      setLoading(true);
      // Set geoJSON for api calls
      const chosenGeoJSON = {
        type: "FeatureCollection",
        features: [JSON.parse(field.boundary)],
      };
      setGeoJSON(chosenGeoJSON);

      // Have an initial call on load
      getData(machineWidth, lateralOverlap, chosenGeoJSON);
    }
  }, [field.boundary]);

  /**
   * Handle notifying the user in case call to getAndConvertGuidanceLines fails.
   * We will automatically try again once so message changes depending on attempt.
   * @param {Number} numTry we need this to keep track of what attempt this is
   * @param {Array} varsToUse variables to use when retrying request
   * @returns {Void} sets states
   */
  const handleError = async (numTry, varsToUse) => {
    if (numTry === 0) {
      // If load had an error, try again after 2 secs
      await sleep(2000);
      // Do not pass geoJSON as it will get stringified again
      await getData(varsToUse[0], varsToUse[1], varsToUse[2], 1);
    } else if (numTry === 1) {
      setErrorMessage(
        "Something went wrong while trying to get the guidance lines. Please try again later."
      );
      setNoData(true);
      setLoading(false);
    }
  };

  /**
   * Makes the request to GuidanceLines endpoint to both get the optiaml number of passes and angle for the given
   * configuration.
   * @param {undefined|Number} machine_width machine equipment width in feet
   * @param {undefined|Number} overlap lateral overlap desired in feet
   * @param {undefined|object} geo_json A geojson of field boundary of selected field
   * @param {String} conversion type of file to request from spatial conversion
   * @returns {Void} sets states
   */
  const getData = async (
    machine_width = undefined,
    overlap = undefined,
    geo_json = undefined,
    numTry = 0
  ) => {
    setLoading(true);
    setNoData(false);
    setIsMultiPolygon(false);

    const varsToUse = [];
    varsToUse.push(machine_width || machineWidth);
    varsToUse.push(overlap || lateralOverlap);
    varsToUse.push(geo_json || geoJSON);
    // Create request
    const req = {
      machine_width: varsToUse[0],
      overlap: varsToUse[1],
      AOI: JSON.stringify(varsToUse[2]),
      Conversion: "Shapefile",
    };
    // console.log('req :>> ', req);

    let response;
    // Call to GuidanceLine and convert geoJSON will be handled by the same API call
    response = await getAndConvertGuidanceLines(req);
    // console.log('response :>> ', response);
    // console.log('response[number_passes] :>> ', response['number_passes']);

    // Something went wrong
    if (typeof response !== "object") {
      consoleImportant(
        "An error occurred while trying to get and convert guidance lines."
      );
      console.log("Incorrect response type:", response);
      handleError(numTry, varsToUse);
      return;
    }
    // Remove any error message, if any.
    setErrorMessage("");

    // Extract the necessary information
    // console.log('response[optimal_angle].length :>> ', response['optimal_angle'].length);
    if (response.optimal_angle.length > 1) {
      setIsMultiPolygon(true);
    }
    setOptimalAngle(response.optimal_angle);
    setMinNumPasses(response.number_passes);
    setResponseGeoJSON(JSON.parse(response.GeoJSON));

    setShowGuidanceLines(true);
    setLoading(false);
  };

  /**
   * Handles the appropriate method for each type of download posssible (GeoJSON, KML, and Shapefile)
   * @param {String} fileType type of file requested for download
   */
  const handleDownloadClick = async (fileType) => {
    // GeoJSON doesn't require us to make any more calls
    if (fileType === "GeoJSON") {
      const dataStr = `data:text/json;charset=utf-8,${JSON.stringify(
        responseGeoJSON
      )}`;
      const downloadAnchorNode = document.createElement("a");
      downloadAnchorNode.setAttribute("href", dataStr);
      downloadAnchorNode.setAttribute("download", `GeoJSON_${field.name}.json`);
      document.body.appendChild(downloadAnchorNode); // required for firefox
      downloadAnchorNode.click();
      downloadAnchorNode.remove();
    }
    // KML or Shapefile can be gotten using fileID retrieved from endpoint call
    else {
      setLoading(true);
      const req = {
        machine_width: machineWidth,
        overlap: lateralOverlap,
        AOI: JSON.stringify(geoJSON),
        Conversion: fileType,
      };
      const response = await getAndConvertGuidanceLines(req);
      const fileID = response.File_ID;
      const download = `${Endpoints.BASEURL}${Endpoints.GUIDANCELINES}?file_type=${fileType}&file_id=${fileID}&name=${field.name}`;
      document.location.href = download;
      setLoading(false);
    }
  };

  /**
   * Handle the actions to take when one of the input values is changed. Format number, check its valid, update number, remake call.
   * Separated as code is common and putting in direction in onBlur causes changes to happen while page is rendering (after done loading).
   * Don't know why that is happening but oldMachineWidth and oldLateralOverlap help avoid that
   * @param {Number} value user input value
   * @param {String} type which button this input is from
   */
  const handleClick = (value, type) => {
    const input = Number(value.replaceAll(",", ""));
    const valid = checkValidity(input, type);
    // console.log('!valid :>> ', !valid);
    if (!valid) return;

    switch (type) {
      case "machineWidth":
        setMachineWidth(input);
        setOldMachineWidth(input);
        getData(input, lateralOverlap, geoJSON);
        break;
      case "lateralOverlap":
        setLateralOverlap(input);
        setOldLateralOverlap(input);
        getData(machineWidth, input, geoJSON);
        break;
    }
  };

  /**
   * Checks the input to make sure it is valid depending on which one it is.
   * Machine width should be greater, they can't be negative or equal, and do nothing if value didn't change.
   * @param {Number} input user input value
   * @param {String} type option the value is for
   */
  const checkValidity = (input, type) => {
    setShowMap(false);
    setWrongInput(true);
    // console.log('input, type :>> ', input, type);
    switch (type) {
      case "machineWidth":
        if (input <= 0) {
          enqueueSnackbar("Please enter a width that is greater than zero.");
          return false;
        }
        if (input <= lateralOverlap) {
          enqueueSnackbar(
            "Please enter a width that is greater than the lateral overlap."
          );
          return false;
        }
        if (input === oldMachineWidth) {
          setShowMap(true);
          setWrongInput(false);
          return false;
        }
        if (input > 300) {
          enqueueSnackbar("Please enter a smaller machine width.");
          return false;
        }
        break;
      case "lateralOverlap":
        if (input <= 0) {
          enqueueSnackbar(
            "Please enter an overlap value that is greater than zero."
          );
          return false;
        }
        if (input >= machineWidth) {
          enqueueSnackbar(
            "Please enter an overlap value that is less than the machine width."
          );
          return false;
        }
        if (input === oldLateralOverlap) {
          setShowMap(true);
          setWrongInput(false);
          return false;
        }
        break;
    }

    // In case something unexpected went wrong.
    if (!input) {
      enqueueSnackbar("Something went wrong. Please try again.");
      return false;
    }

    setShowMap(true);
    setWrongInput(false);
    return true;
  };

  /**
   * Takes the given value and decides what to display to user based on what it means.
   * @param {String} value possible value to display
   */
  const decideWhatToShow = (value) => {
    let chosenValue;
    let tooltip;

    // if value is -1, then area was too small for machine to pass more than once
    // return "Unavailable" with tooltip containing smallSizeMsg
    if (value === "-1") {
      chosenValue = "Unavailable";
      tooltip = smallSizeMsg;
    }

    return (
      <Box
        display="flex"
        className={classes.input}
        style={{
          backgroundColor: "rgba(0, 0, 0, 0.12)",
          padding: 12,
          borderTopLeftRadius: 4,
          borderTopRightRadius: 4,
        }}
      >
        {chosenValue}
        <CustomToolTip title={tooltip} placement="right">
          <InfoOutlinedIcon className={classes.infoIcon} />
        </CustomToolTip>
      </Box>
    );
  };

  /**
   * Returns optimal angle and minimum number of passes for display.
   */
  const angleAndPasses = (angle, passes) => (
    <>
      <Box className={classes.sectionLeft}>
        <Box className={classes.inputName}>Optimal Angle (°)</Box>
        {angle === "-1" ? (
          decideWhatToShow(angle)
        ) : (
          <TextField
            className={classes.input}
            variant="filled"
            value={angle}
            disabled
            inputProps={{
              style: {
                padding: 12,
                fontWeight: 500,
                fontSize: 14,
                color: blackText,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatThree,
            }}
          />
        )}
      </Box>

      <Box className={classes.sectionLeft}>
        <Box className={classes.inputName}>Min. Number of Passes</Box>
        {passes === "-1" ? (
          decideWhatToShow(passes)
        ) : (
          <TextField
            className={classes.input}
            variant="filled"
            value={passes}
            disabled
            inputProps={{
              style: {
                padding: 12,
                fontWeight: 500,
                fontSize: 14,
                color: blackText,
              },
            }}
            InputProps={{
              inputComponent: NumberFormatThree,
            }}
          />
        )}
      </Box>
    </>
  );

  const header = () => (
    <Box mt={1} mx={1}>
      <Box fontSize={18}>Optimal Guidance Lines Estimator (BETA)</Box>

      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        This tool estimates the optimal angle and number of passes required to
        cover the target field given an equipment&apos;s width and the desired
        lateral overlap.
      </Typography>
      <Divider />
    </Box>
  );

  return (
    <Slide direction="right" in>
      <Box color={blackText} fontWeight={500}>
        {header()}

        {/* Textfields and Buttons */}

        <Box mt={0.5} display="flex" alignItems="center" flexWrap="wrap">
          <Box px={1} display="flex" flexWrap="wrap" alignItems="flex-end">
            <Box className={classes.sectionLeft}>
              <Box className={classes.inputName}>Machine Width (ft.)</Box>
              <TextField
                className={classes.input}
                variant="outlined"
                value={machineWidth}
                // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                onChange={(e) =>
                  setMachineWidth(Number(e.target.value.replaceAll(",", "")))
                }
                onBlur={(e) => handleClick(e.target.value, "machineWidth")}
                inputProps={{
                  style: {
                    padding: 12,
                    fontWeight: 500,
                    fontSize: 14,
                  },
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                disabled={loading}
              />
            </Box>

            <Box className={classes.sectionLeft}>
              <Box className={classes.inputName}>Lateral Overlap (ft.)</Box>
              <TextField
                className={classes.input}
                variant="outlined"
                value={lateralOverlap}
                // Make sure to convert number before using it: Number(e.target.value.replaceAll(',', ''))
                onChange={(e) =>
                  setLateralOverlap(Number(e.target.value.replaceAll(",", "")))
                }
                onBlur={(e) => handleClick(e.target.value, "lateralOverlap")}
                inputProps={{
                  style: {
                    padding: 12,
                    fontWeight: 500,
                    fontSize: 14,
                  },
                }}
                InputProps={{
                  inputComponent: NumberFormatCustom,
                }}
                disabled={loading}
              />
            </Box>

            <Button
              color="primary"
              variant="contained"
              style={{ marginBottom: 12 }}
              onClick={() => getData()}
              disabled={errorMessage !== ""}
              disableElevation
            >
              Regenerate Optimal Guidance Lines
            </Button>
          </Box>

          <Box px={1} display="flex" flexWrap="wrap" alignItems="flex-end">
            {!isMultiPolygon &&
              angleAndPasses(optimalAngle[0], minNumPasses[0])}

            <Box mx={1} mb={1} color={green} fontWeight={600}>
              <Button
                color="primary"
                variant="contained"
                onClick={(e) => {
                  setPopoverAnchor(e.currentTarget);
                  setPopoverOpen(!downloadsPopoverOpen);
                }}
                disableElevation
                disabled={loading || wrongInput || noData}
              >
                Downloads
                {downloadsPopoverOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                <Popover
                  id="downloads-popover"
                  open={downloadsPopoverOpen}
                  anchorEl={downloadsPopoverAnchor}
                  onClose={() => {
                    setPopoverAnchor(null);
                    setPopoverOpen(false);
                  }}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <Box style={{ padding: "1em" }}>
                    <Box className={classes.edit} fontSize={18}>
                      <Box
                        onClick={() => handleDownloadClick("Shapefile")}
                        style={{ cursor: "pointer" }}
                      >
                        Shapefile
                      </Box>
                    </Box>

                    <Divider />

                    <Box
                      className={classes.edit}
                      fontSize={18}
                      style={{ paddingTop: "0.5em" }}
                    >
                      <Box
                        onClick={() => handleDownloadClick("KML")}
                        style={{ cursor: "pointer" }}
                      >
                        KML
                      </Box>
                    </Box>

                    <Divider />

                    <Box
                      className={classes.edit}
                      fontSize={18}
                      style={{ paddingTop: "0.5em" }}
                    >
                      <Box
                        onClick={() => handleDownloadClick("GeoJSON")}
                        style={{ cursor: "pointer" }}
                      >
                        GeoJSON
                      </Box>
                    </Box>
                  </Box>
                </Popover>
              </Button>
            </Box>

            <Box mb={0.5} display="flex" alignItems="center" fontSize={14}>
              <Switch
                color="primary"
                checked={showGuidanceLines}
                onChange={(e) => setShowGuidanceLines(e.target.checked)}
                inputProps={{ "aria-label": "boundary toggle" }}
              />
              Toggle Guidance Lines
            </Box>
          </Box>
        </Box>

        {/* Map, error display, download button, and loader */}
        {!loading &&
          showMap &&
          (errorMessage === "" ? (
            <Box display="flex" flexWrap="wrap">
              <Box
                p={0.5}
                display="flex"
                flexGrow={1}
                minWidth={
                  width >= 1080
                    ? width - 800
                    : width > mobileBreakPoint
                    ? width - 240
                    : width - 30
                }
                borderRadius={4}
              >
                <GuidanceLinesMap
                  boundary={field.boundary}
                  geoJSON={responseGeoJSON}
                  showGuidanceLines={showGuidanceLines}
                  height={window.innerHeight - 410}
                  location={field?.state}
                />
              </Box>

              {/* Show angles and passes here if there are more than one (ie. field is a multipolygon) */}
              {isMultiPolygon ? (
                <Box
                  p={1}
                  display="flex"
                  flexDirection="column"
                  maxWidth="450px"
                >
                  <Box
                    className={classes.errorMessageBox}
                    style={{ display: "block", borderColor: "#424242" }}
                    mb={2}
                  >
                    <Box
                      height="22px"
                      display="flex"
                      alignItems="center"
                      justifyContent="space-between"
                    >
                      Please Note:
                      {hideNote ? (
                        <ArrowDropDownIcon
                          onClick={() => setHideNote(false)}
                          style={{ cursor: "pointer", fontSize: 50 }}
                        />
                      ) : (
                        <ArrowDropUpIcon
                          onClick={() => setHideNote(true)}
                          style={{ cursor: "pointer", fontSize: 50 }}
                        />
                      )}
                    </Box>
                    <Divider />
                    {!hideNote && (
                      <Box fontSize="1rem" mt={1}>
                        For best results, please use a field that contains a
                        singular Polygon (one contiguous shape that can have
                        holes) and not a MultiPolygon (two or more completely
                        separate Polygons). The download files are for the whole
                        field and not each polygon.
                      </Box>
                    )}
                  </Box>
                  <Box
                    display="flex"
                    flexDirection="column"
                    alignItems="center"
                  >
                    {optimalAngle.map((angle, i) => (
                      <>
                        <Box fontSize={18} fontWeight={500}>
                          Field {i + 1}
                        </Box>
                        <Box display="flex" ml={3} mb={3}>
                          {angleAndPasses(angle, minNumPasses[i])}
                        </Box>
                      </>
                    ))}
                  </Box>
                </Box>
              ) : (
                <Box
                  display="flex"
                  flexWrap="wrap"
                  flexDirection={
                    width < mobileBreakPoint || width > 1080 ? "column" : "row"
                  }
                >
                  {!precisionField && (
                    <Box display="flex" justifyContent="center">
                      <Box p={0.5}>
                        <IntegrationLinks onConnect={onConnectClick} />
                      </Box>
                    </Box>
                  )}

                  <Box p={0.5} display="flex" justifyContent="center">
                    <GetSoilTesting />
                  </Box>
                </Box>
              )}
            </Box>
          ) : (
            <Box m={1} display="flex">
              <Box className={classes.errorMessageBox}>{errorMessage}</Box>
            </Box>
          ))}

        {loading && <SpinningLoader />}
      </Box>
    </Slide>
  );
}

/* eslint-disable no-nested-ternary */
import React, {
	useState, useEffect, useContext, useRef,
} from 'react';
import {
	Box,
	Typography,
	Link,
	Divider,
	FormControl,
	TextField,
  setRef,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ClearIcon from '@material-ui/icons/Clear';
import { Endpoints } from '../../../constants/Endpoints';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import CheckRoundedIcon from '@material-ui/icons/CheckRounded';
import { useSnackbar } from 'notistack';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import * as df from '../../../utils/dataFetchers';
import { BrainTreeDropIn } from '../../ProfitLayers/Reports/BrainTreeDropInReact';
import { PaymentApp } from '../../Pricing/SingleTransaction';
import { useWindowDimensions } from '../../../utils/dimensions';
import { CustomToolTip } from '../../../utils/customComponents';
import { SpinningLoader } from '../../Shared/SpinningLoader';
import { dollarFormat, numFormat, validEmail } from '../../../utils/helpers';
import {
	grey,
	lightGrey,
	offWhite,
	darkGrey,
	green,
	blackText,
	darkText,
} from '../../../styles/colors';
import { UserContext } from '../../Context/UserContext';
import {createTransaction, createAnonymousTransaction, searchAgentPromo} from '../../../utils/dataFetchers'
const greyBlue = '#006ba1';

const useStyles = makeStyles((theme) => ({
  planBox: {
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    '& > *': {
      margin: theme.spacing(1),
      width: theme.spacing(16),
      height: theme.spacing(16),
    },
  },
  plan: {
    width: '380px',
    maxWidth: '380px',
    height: 'auto',
    flexGrow: 1,
  },
  planHeader: {
    backgroundColor: '#67b346',
    height: '120px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: '15px 15px 0 0',
  },
  appBar: {
    ...theme.appBar,
    justifyContent: 'space-between',
  },
  appBarSelections: theme.appBarSelections,
  appBarSelectionsMobile: {
    ...theme.appBarSelections,
    margin: '0 10px',
    overflowX: 'auto',
  },
  mobileSteps: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%',
  },
  paper: {
    position: 'absolute',
    width: '90vw',
    maxWidth: 1000,
    height: '85vh',
    backgroundColor: theme.palette.greys.light,
    overflow: 'auto',
  },
  operationBox: theme.centeredColumn,
  operations: {
    backgroundColor: theme.palette.greys.light,
    height: '100%',
    padding: '15px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    overflowY: 'auto',
  },
  label: theme.label,
  greenClick: theme.plIcon,
  icon: theme.icon,
  edit: {
    marginLeft: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  getPdf: {
    display: 'flex',
    color: theme.palette.primary.main,
    fontWeight: 700,
    fontSize: 16,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  typography: {
    button: {
      textTransform: 'none',
    },
  },
  select: {
    padding: '10px',
    borderRadius: '5px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  sync: {
    ...theme.greenHover,
    fontSize: 18,
    marginLeft: '5px',
  },
  partner: {
    height: '60px',
    width: 'auto',
    margin: 6,
  },
  partner2: {
    height: '80px',
    width: 'auto',
    margin: 18,
  },
  connect: theme.connect,
  mobileConnect: {
    ...theme.connect,
    fontSize: 16,
    padding: 6,
    margin: 6,
  },
  logIn: {
    position: 'absolute',
    width: '90vw',
    height: '90vh',
    backgroundColor: theme.palette.lightGrey,
  },
  arrows: theme.navigationArrows,
  cardText: {
    fontWeight: 500,
    fontSize: 18,
    color: blackText,
    padding: 4,
  },
  planLabel: {
    fontWeight: 600,
    fontSize: 16,
    color: blackText,
  },
  planText: {
    fontWeight: 500,
    fontSize: 16,
    color: blackText,
  },
  close: {
    fontSize: 18,
    color: theme.palette.greys.main,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  infoToolTip: theme.infoToolTip,
  planCard: {
    display: 'flex',
    flexDirection: 'column',
    width: 380,
    minHeight: 360,
    justifyContent: 'space-between',
    padding: '8px 16px',
    border: `2px solid ${theme.palette.greys.dark}`,
    backgroundColor: offWhite,
    color: theme.palette.text.primary,
  },
  premiumCard: {
    display: 'flex',
    flexDirection: 'column',
    width: 380,
    minHeight: 360,
    justifyContent: 'space-between',
    padding: '8px 16px',
    border: `2px solid ${theme.palette.primary.main}`,
    backgroundColor: offWhite,
    color: theme.palette.text.primary,
  },
  planTitle: {
    fontWeight: 500,
    fontSize: 32,
    textAlign: 'center',
  },
  planCardText: {
    display: 'flex',
    margin: 4,
  },
  checkout: {
    minWidth: 380,
    maxWidth: 450,
    margin: '0 4px',
    padding: '0 8px',
    border: `1px solid ${theme.palette.greys.dark}`,
    borderRadius: 4,
    color: theme.palette.text.primary,
    backgroundColor: '#ffffff',
  },
  dot: {
    fontSize: 8,
    marginRight: 8,
    marginTop: 5,
  },
  check: {
    fontSize: 14,
    marginRight: 8,
    marginTop: 4,
  },
  claimOffer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
    padding: '12px 6px',
    backgroundColor: green,
    color: 'white',
    fontSize: 16,
    fontWeight: 600,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  messageDisplay: {
    margin: 8,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 4,
  },
  imageContainer: {
    borderRadius: 4,
    border: `solid ${theme.palette.greys.dark} 1px`,
    margin: 16,
    paddingBottom: 6,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  buttonLink: {
    color: theme.palette.primary.main,
    textDecoration: 'none',
    backgroundColor: '#ffffff',
  },
  discountText: {
    marginBottom: 8,
    fontWeight: 500,
    fontSize: '1rem',
    color: theme.palette.primary.main,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  termsContainer: {
    display: 'flex',
    flexDirection: 'column',
    maxWidth: 640,
    margin: 8,
    paddingTop: 8,
    color: theme.palette.text.primary,
  },
  terms: {
    height: 300,
    overflowY: 'auto',
  },
  checkbox: {
    fontSize: 28,
    color: theme.palette.primary.main,
  },
  hover: {
    fontColor: theme.palette.text.primary,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.secondary,
    },
  },
}));

/**
 * Component that displays and handles functionality for the DIGS/ADS checkout box
 * emulates the look and design from the Digs\Checkout\Cart.js file
 * @param {String} customerEmail email entered into the order form
 * @param {Number} price total price of the order
 * @param {Function} isOrderReady function to check that the order form is complete
 * @param {Function} createIncompleteOrderMessage function to message user that the order is not complete
 * @param {Function} setTransaction set the transaction state variable in parent component
 * @param {Function} setAgentEmail set the agentEmail state variable in parent component
 * @param {Function} setAgentCode set the agentCode state variable in parent component
 * @param {Object} order order object containing the selected bundels and fields
 * @param {Function} remove function to remove an item from the order
 * @param {Boolean} includesFields bool to determine if the order contains fields
 * @param {Function} setDiscountAmount set the discount state variable in parent component
 * @param {Number} cartAcres the acre value from the Checkout/Cart
 * @param {Function} setCartAcres function to update the acre value in Checkout
 * @param {Function} setCartTotal function to update the total value in Checkout
 * @returns 
 */
export function PurchaseADS({ 
	customerEmail, 
	price,
  isOrderReady,
  createIncompleteOrderMessage,
  setTransaction,
  setAgentEmail,
  setAgentCode,
  order,
  remove,
  includesFields,
  setDiscountAmount,
  promoCode,
  setPromoCode,
  cartAcres,
  setCartAcres,
  setCartTotal
}) {
	const user = useContext(UserContext)[0];
	const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [total, setTotal] = useState(0);
  const [acres, setAcres] = useState(cartAcres ? cartAcres : 1);

  const [basePrice, setBasePrice] = useState(0);
  const [finalPrice, setFinalPrice] = useState(0);
  const [discount, setDiscount] = useState(0);
  const discountReports = ['Holistic Drainage Services', 'Watershed Facilitation']

  const [referralcode, setReferralcode] = useState('');
  const [focusReferral, setFocusReferral] = useState(false);

	const [processing, setProcessing] = useState(false);
  const [isPaymentProcessing, setIsPaymentProcessing] = useState(false);
  const [showPayment, setShowPayment] = useState(false);
  const [checkingOut, setCheckingOut] = useState(false);
  const [isSuccessful, setIsSuccessful] = useState(false);
  const [isFail, setIsFail] = useState(false);

  const [retryNonce, setRetryNonce] = useState(null)

	const [email, setEmail] = useState(true)
	const [emailConfirmed, setEmailConfirmed] = useState(false);

  /**
   * track the price prop and the discount state variable
   * used to calculate the final price
   */
	useEffect(() => {
		if(price !== undefined && price !== null && price !== 0){
			let base = includesFields ? price.toFixed(2) : total.toFixed(2)
			setBasePrice(base)
      let final = (base - discount).toFixed(2)
      setFinalPrice(final)
		}
    setDiscountAmount(discount)
	},[price, discount])

  /**
   * Tracks customerEmail prop
   * if the email is valid then we can display the
   * braintree payment info
   */
	useEffect(() => {
		if (validEmail(customerEmail)){
			setShowPayment(true)
		}
	}, [customerEmail])

  /**
   * Tracks user context object
   * if the user is authenticated then we can display the
   * braintree payment info
   */
	useEffect(() => {
		if(user?.isAuthenticated){
			setShowPayment(true)
		}
	},[user])

  // /**
  //  * tracks the promoCode state variable (Set by handleReferralCode())
  //  * once the promoCode is set determines if a discount should be applied
  //  */
  // useEffect(() => {
  //   if(promoCode !== null){
  //     if(promoCode.includes('CONTRACTOR')){
  //       calculateDiscount()
  //     }
  //     if(promoCode.includes('PIPE')){
  //       setDiscount(0)
  //       setDiscountAmount(0)
  //     }
  //     if(promoCode !== referralcode){
  //       setReferralcode(promoCode)
  //     }
  //   }
  // },[promoCode])

  /**
   * track the calculated total and update base and discounted prices
   * this will only be used if component is rendered in the 'SimpleOrder' version
   */
  useEffect(() => {
    if(total > 0){
      let base = total.toFixed(2)
			setBasePrice(base)
      let final = (base - discount).toFixed(2)
      setFinalPrice(final)
    }
  },[total])

  /**
   * Tracks the users order
   * once ready, uses data to calculate the total cost
   * tracks the promoCode state variable (Set by handleReferralCode())
   * once the promoCode is set determines if a discount should be applied
   */
  useEffect(() => {
    calculateTotal();
    if(promoCode !== null){
      if(promoCode.includes('CONTRACTOR')){
        calculateDiscount()
      }
      if(promoCode.includes('PIPE')){
        setDiscount(0)
        setDiscountAmount(0)
      }
      if(promoCode !== referralcode){
        setReferralcode(promoCode)
      }
    }
    if(order.length === 0){
      setBasePrice(0)
      setFinalPrice(0)
      setDiscount(0)
      setDiscountAmount(0)
    }
  }, [order, acres, promoCode]);

  /**
   * Calculate the discount that should be applied to the current total price
   * currently there are two bundles that are applicable for discounts,
   * both receive a $10/ac discount
   */
  const calculateDiscount = () =>{
    let totalDiscount = 0;
    order.forEach((item, i) => {
      if(includesFields){
        let reports = item.reports
        reports.forEach((report, i) =>{
          if(discountReports.includes(report.name)){
            let itemDiscount = item.acres * 10
            totalDiscount += itemDiscount
          }
        })
      }
      else{
        if(discountReports.includes(item.name)){
          let itemDiscount = acres * 10
          totalDiscount += itemDiscount
        }
      }
    })
    setDiscount(totalDiscount.toFixed(2))
    setDiscountAmount(totalDiscount.toFixed(2))
  }

  /**
   * calculate the total price of the order
   * sets the 'total' state variable
   */
  const calculateTotal = () => {
    let orderTotal = 0;

    if (includesFields) {
      order.forEach((field) => {
        const prices = field.reports.map((x) => x.price);
        const fieldTotal = prices.reduce((a, b) => a + b, 0);
        orderTotal += fieldTotal * field.acres;
      });
    } else {
      order.forEach((service) => {
        orderTotal += service.price * acres;
      });
    }
    setTotal(orderTotal);
    if(!includesFields){
      setCartTotal(orderTotal)
    }
  };

  /**
   * takes the input code from a text box and sends it to the backend to check
   * if the input matches a valid promo code provided by DIGS
   * won't send a request until the first portion of the code is entered.
   * all codes either start with 'PIPE' or 'CONTRACTOR' followed by numbers
   * @param {String} value input value from the promo code text box
   */
	const handleReferralCode = async (value) => {
    setReferralcode(value);

    try {
      // do some pre request checks to lighten load on back end
      if((value.includes('PIPE') && value.length > 4)|| (value.includes('CONTRACTOR') && value.length > 10)){
        const res = await searchAgentPromo(value, 'DIGS');
        if (res.length === 1){
          // update state variables with values from response
          setPromoCode(value)
          setAgentEmail(res[0].agentEmail)
          setAgentCode(res[0].agentCode)
        }
        else{
          setPromoCode(null)
          setAgentEmail(null)
          setDiscount(0)
          setDiscountAmount(0)
        }
      }
      else{
        setDiscount(0)
        setDiscountAmount(0)
        setPromoCode(null)
        setAgentEmail(null)
      }
      
    } catch (err) {
      console.error('error verifying disocunt code', err);
      setPromoCode(null)
      setAgentEmail(null)
      setDiscount(0)
      setDiscountAmount(0)
    }
  };

	const handleSubscriptionPayment = async (customerData, subscriptionData) => {
    setIsPaymentProcessing(true);
    setShowPayment(false);
    let customerInfo = '';
    try {
      fetch(Endpoints.BASEURL + Endpoints.API_CUSTOMER, {
        method: 'POST',
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(customerData),
      })
        .then((response) => {
          if (response.ok) {
            const jsonresult = response.json();
            return jsonresult;
          }
          throw new Error(response);
        })
        .then((response) => {
          const customer = JSON.stringify(response.data);
          const dontgotosubscritionpage = sessionStorage.getItem(
            'dontGoToSubscriptionList',
          );

          sessionStorage.setItem('customerInfo', customer);
          customerInfo = customer;

          subscriptionData.ApiKey = customerInfo.apiKey;

          fetch(Endpoints.BASEURL + Endpoints.API_SUBSCRIPTION, {
            method: 'POST',
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
            },
            credentials: 'include',
            body: JSON.stringify(subscriptionData),
          })
            .then((response) => {
              if (response.ok) {
                return response.json();
              }
              throw new Error(response);
            })
            .then((response) => {
              // console.log('response', response);
              if (response.isSuccessful === true) {
                setCheckingOut(false);
                setShowPayment(false);
                setIsPaymentProcessing(false);
                setIsSuccessful(true);
                setIsFail(false);
              } else {
                setShowPayment(false);
                setIsPaymentProcessing(false);
                setIsSuccessful(false);
                setIsFail(true);
              }
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error('error', error);
        });
    } catch (err) {
      console.error('handlePaymentMethod', err);
    }
  };

  /**
   * sends a request to the backend to create a transaction in Braintree and save the result in the database
   * the returned transaction is then set as a state variable, triggering the order submission.
   * @param {Object} customerData object containing customer information, see SingleTransaction.js function handlePaymentMethod() for the exact structure
   * @param {Object} feature object containing the price and nonce for the transaction
   */
  const handlePayment = async (customerData, feature) => {
    if (isOrderReady()){
      let transactionRequest = {
        'customer': customerData,
        'price': feature.price,
        'nonce': feature.nonce
      }
      let result = user?.isAuthenticated ? await createTransaction(transactionRequest) : await createAnonymousTransaction(transactionRequest)
      // console.log(result)
      if (result?.status === "Fail"){
        // console.log('Failed to create braintree transaction')
        enqueueSnackbar('Could not process payment method please wait a moment and try again.',{autoHideDuration: 6000})
        //trigger flow for payment nonce again
        setRetryNonce(result.nonce)
      }
      else{
        result['customerId'] = customerData.CustomerID
        result = {
          ...result,
          ...feature,
          customerId: customerData.CustomerID,
        }
        setTransaction(result)
      }
    }
    else{
      createIncompleteOrderMessage()
    }
    

  }

	const returnCheckoutDetails = (plan) => {
    if (plan === 'standard') {
      return (
        <Box id="checkout info" color={blackText}> 
          <Box display="flex" justifyContent="flex-end">
            <Box
              display="flex"
              justifyContent="flex-end"
              style={{ width: '40%' }}
              fontWeight={500}
            >
              <Box style={{ width: '40%' }}>
                $/Acre
              </Box>
              <Box style={{ width: '55%' }}>
                {' Total'}
              </Box>
              <Box style={{ width: '5%' }} />
            </Box>
          </Box>
          <Divider className={classes.divider} variant="middle" />
          {
            includesFields ? order.map((field, i) => displayOrder(field, i, remove))
            : order.map((report, i) => simpleDisplay(report, i))
          }

          <Typography variant="body1" style={{ margin: 4 }}>
            <span className={classes.planLabel}>Order Cost: </span>
            <span className={classes.planText}>
              $
              {basePrice}
            </span>
          </Typography>

          <Typography variant="body1" style={{ margin: 4 }}>
            <span className={classes.planLabel}>Discount Applied: </span>
            <span className={classes.planText}>
              -$
              {discount}
            </span>
          </Typography>

          <Typography variant="body1" style={{ margin: 4 }}>
            <span className={classes.planLabel}>Total: </span>
            <span className={classes.planText}>
              $
              {finalPrice}
            </span>
          </Typography>

          <Divider />

					{!validEmail(customerEmail) &&
						<Box color="red">
							** Please Enter Your Email In The Order Form **
						</Box>
					}

          <Box my={1}>
            {
              !includesFields
              && (
              <Box display="flex" alignItems="center">
                {'Acres: '}
                <TextField
                  style={{ width: '100px', marginLeft: '3px' }}
                  variant="outlined"
                  type="number"
                  inputProps={{
                    style: {
                      padding: 7,
                    },
                  }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={acres}
                  onChange={(e) => {setAcres(e.target.value); setCartAcres(Number.parseFloat(e.target.value))}}
                />
              </Box>
              )
            }

            <Typography variant="subtitle2" style={{ marginBottom: 6 }}>
              Enter Promo Code:
            </Typography>

            <FormControl variant="outlined">
              <TextField
                inputRef={(input) => (input && focusReferral) && input.focus()}
                variant="outlined"
                value={referralcode}
                onChange={(event) => handleReferralCode(event.target.value)}
                label="Promo Code"
              />
            </FormControl>
          </Box>
          <Divider />

          <Box id="bt" style={{ marginTop: '10px' }}>
            {showPayment && (
              <PaymentApp
								authenticated={user?.isAuthenticated}
                source="ADS"
                handlePayment={handlePayment}
                setProcessing={setProcessing}
                referralcode={referralcode}
								customerEmail={customerEmail}
                price={finalPrice}
                retryNonce={retryNonce}
              />
            )}
            {isPaymentProcessing && (
              <Box className={classes.head}>
                Processing your payment...
              </Box>
            )}
            {isSuccessful && !isFail && (
              <Box ml={1}>
                <Typography style={{ fontWeight: 500 }}>
                  <span
                    style={{ cursor: 'pointer' }}
                  >
                    Thank you for your purchase.
                    <sup>&reg;</sup>
                    .
                  </span>
                </Typography>
              </Box>
            )}
            {!isSuccessful && isFail && (
              <Box ml={1}>
                <Typography style={{ fontWeight: 500 }}>
                  Couldn&#39;t process your payment, please contact us at
                  {' '}
                  <b>support@analytics.ag</b>
                </Typography>
              </Box>
            )}
          </Box>
        </Box>
      );
    }
  };

  const displayOrder = (field, i) => (
    <Box key={i} px={2} color={blackText} fontWeight={500}>
      <Box mt={1} display="flex" justifyContent="space-between">
        <Box>
          <Box fontSize={16}>{field.name}</Box>
          <Box fontSize={12}>
            {'Acres: '}
            {numFormat(field.acres)}
          </Box>
        </Box>
      </Box>

      {field.reports.map((report, j) => (
        <Box key={j}>
          <Box
            pt={1}
            px={1}
            display="flex"
            justifyContent="space-between"
            flexGrow={1}
            fontSize="1rem"
          >
            <Box style={{ width: '60%' }}>
              {report.name}
            </Box>

            <Box
              pl={1}
              display="flex"
              justifyContent="flex-end"
              style={{ width: '40%' }}
            >
              <Box style={{ width: '40%' }}>
                {'$'}
                {report.price}
              </Box>
              <Box style={{ width: '55%' }}>
                {'$'}
                {numFormat(report.price * field.acres)}
              </Box>
              <Box style={{ width: '5%' }}>
                <ClearIcon
                  className={classes.remove}
                  onClick={() => remove(i, j, report)}
                />
              </Box>
            </Box>

          </Box>
          <Divider style={{ backgroundColor: grey }} />
        </Box>
      ))}
    </Box>
  );

  const simpleDisplay = (report, i) => (
    <Box key={i} px={2} color={blackText} fontWeight={500}>
      <Box mt={1} display="flex" justifyContent="space-between">
        <Box
          pt={1}
          px={1}
          display="flex"
          justifyContent="space-between"
          flexGrow={1}
        >
          <Box style={{ width: '60%' }}>
            {report.name}
          </Box>

          <Box
            pl={1}
            display="flex"
            justifyContent="flex-end"
            style={{ width: '40%' }}
          >
            <Box style={{ width: '40%' }}>
              $
              {report.price}
            </Box>
            <Box style={{ width: '55%' }}>
              $
              { Number.isNaN(acres) ? report.price : numFormat(report.price * acres) }
            </Box>
            <Box style={{ width: '5%' }}>
              <ClearIcon
                className={classes.remove}
                onClick={() => remove(report)}
              />
            </Box>
          </Box>
        </Box>
      </Box>
      <Divider />
    </Box>
  );

	return (
		<Box display='flex' flexDirection='column'>
			<Box id="checkout" className={classes.checkout}>
				<Typography variant="h6" align="center">
					Checkout
				</Typography>

				<Divider />

				{returnCheckoutDetails('standard')}

				<Box mt={1}>
					<Typography align='center' variant='subtitle2'>
						View&nbsp;
						<Link
							href={`${Endpoints.HOME}/termsandconditions`}
							target="_blank"
							color="primary"
						>
							terms and conditions here
						</Link>
					</Typography>
				</Box>
			</Box>
		</Box>
	)
}
import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {
  Button,
  Box,
  Divider,
  Typography,
} from '@material-ui/core';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Highcharts from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import {
  blackText, darkText, grey, lightGrey,
} from '../../../styles/colors';
import { numFormat, dollarFormat, exists } from '../../../utils/helpers';
import { GeneralSettings } from '../Settings/GeneralSettings';
import { OptionsBox } from '../Settings/OptionsBox';
import { AdvancedOptions } from '../Settings/AdvancedOptions';
import { RefineEstimates } from '../Settings/RefineEstimates';
import { CustomToolTip } from '../../../utils/customComponents';

const useStyles = makeStyles((theme) => ({
  infoIcon: theme.infoToolTip,
  selections: {
    height: 40,
    minWidth: 50,
  },
  table: {
    minWidth: '450px',
    maxWidth: '550px',
    overflowX: 'auto',
  },
  errorMessageBox: theme.errorMessageBox,
}));

/**
 * This tool displays options, graphs, and tables to help estimate yield risk in relation to insurance.
 * Data is shared between WhatIf.js, PremiumCalculator.js, and InsuranceAI.js
 *
 * @param {Number} acres Defaults to selected fields acres, user can update
 * @param {Object} advancedOptions stores current state of advanced options
 * @param {Number} approvedYield Defaults to 118, updated by getYield in getValues
 * @param {Number} commodityYear currently set commodity year
 * @param {String} coverageLevel selected insurance coverage level
 * @param {Array} coverageLevels list of possible coverage levels
 * @param {Object} crop currently set crop
 * @param {Array} crops crop choices for current year
 * @param {Array} d_Yprc y-axis values for all graphs
 * @param {Array} d_revprc x-axis values for Revenue Distribution graph
 * @param {Array} d_yieldprc x-axis values for Yield Distribution graph
 * @param {Array} d_priceprc x-axis values for Harvest Price Distribution graph
 * @param {Array} d_indemprc x-axis values for Insurance Payment Distribution graph
 * @param {Boolean} disableUserInputsOnLoad indicates whether data is currently loading or being processed
 * @param {Boolean} error error indicator for getRMARates
 * @param {Array} errorMessage if not empty, contains information about type of error
 * @param {Array} exclusionYears Used in RefineEstimates
 * @param {Function} getCalculateYieldFromHistoryData used to create body for request to calculateYieldFromHistoryin Refine Estimates
 * @param {Function} getInputAndSendRequest Recalculate insurance tool values and calls RMA rates based on user input (initially default values)
 * @param {Number} guaranteeInsurance insurance value to be used for graph display (seems unnecessary)
 * @param {Function} handleChange to update general settings values upstream
 * @param {String} insuranceType currently set insurance type
 * @param {String} locationErrorMessage Message to display to user in un-supported location
 * @param {Bool} mobileView If view is under mobile breakpoint
 * @param {Object} practice Selected practice (e.g. { practiceCode: '4', practiceName: 'Non-Irrigated' })
 * @param {Array} practices Available practices for dropdown
 * @param {Number} projectedPrice Defaults 3.96, updated by getGetPriceAndVolatility
 * @param {Function} setCoverageLevel function to update the currently set coverage level
 * @param {Function} setInsuranceType to update insurance type value upstream
 * @param {Function} setYields Used in RefineEstimates on user input
 * @param {Boolean} showTables controls whether Insurance AI statistic tables are shown
 * @param {Array} soilAndYieldTableInputs table inputs for soil and yield table
 * @param {String} source to know page source (DataLayers/ProfitLayers)
 * @param {Array} statisticTableInputs table inputs for statistic table
 * @param {Object} type Selected type, (e.g. {typeCode: '26', typeName: 'Grain'})
 * @param {Array} types Available types of crop for dropdown
 * @param {Function} updateAdvancedOptions to update advanced options values upstream
 * @param {Function} updateYields Updates actual and rate yield from response from RefineEstimates
 * @param {Array} yields Yields used for initial values Refine Estimates
 * @returns {JSX} The entire display for Insurance AI tab
 */
export function InsuranceAI({
  acres,
  advancedOptions,
  approvedYield,
  commodityYear,
  coverageLevel,
  coverageLevels,
  crop,
  crops,
  d_Yprc,
  d_revprc,
  d_yieldprc,
  d_priceprc,
  d_indemprc,
  disableUserInputsOnLoad,
  error,
  errorMessage,
  exclusionYears,
  getCalculateYieldFromHistoryData,
  getInputAndSendRequest,
  guaranteeInsurance,
  handleChange,
  insuranceType,
  locationErrorMessage,
  mobileView,
  practice,
  practices,
  projectedPrice,
  setCoverageLevel,
  setInsuranceType,
  setYields,
  showTables,
  soilAndYieldTableInputs,
  source,
  statisticTableInputs,
  type,
  types,
  updateAdvancedOptions,
  updateYields,
  yields,
}) {
  // Needed vars
  const classes = useStyles();
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);
  const [refiningEstimates, setRefiningEstimates] = useState(false);
  const [displayOption, setDisplayOption] = useState('');
  const [insuranceCrops, setInsuranceCrops] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const [disableUserInputs, setDisableUserInputs] = useState(false);

  const [graphData, setGraphData] = useState('');

  // useEffects
  useEffect(() => {
    const option = insuranceType === 'Yield Protection' ? 'Yield Distribution' : 'Revenue Distribution';
    setDisplayOption(option);
  }, [insuranceType]);

  // Update the graph whenever new data is available for it
  useEffect(() => {
    // console.log('displayOption', displayOption)
    if (exists(d_Yprc) && exists(displayOption)) {
      try {
        drawGraph(displayOption);
      } catch (err) {
        console.log(err);
      }
    }
  }, [displayOption, d_Yprc]);

  // Update insuranceCrops with the only options that work for insuranceAI
  useEffect(() => {
    const selectedCrops = crops.filter((x) => x.Name === 'Corn' || x.Name === 'Soybeans');
    setInsuranceCrops(selectedCrops);
  }, [crops]);

  // Figures out if there is an errorMessage and whether it applies to this tool
  useEffect(() => {
    // Should always have the tools it's meant for, the error message, and what to disable
    if (errorMessage.length > 2 && (errorMessage[0] === 'InsuranceAI' || errorMessage[0] === 'All')) {
      setShowErrorMessage(true);
      setDisableUserInputs(errorMessage[2]);
    } else {
      setShowErrorMessage(false);
      setDisableUserInputs(false);
    }
  }, [errorMessage]);

  // Functions
  // Toggle whether or not to show advanced options
  const toggleOptions = () => {
    setShowAdvancedOptions(!showAdvancedOptions);
  };

  // Takes two arrays of objects to create statistic table from
  const createStatisticTable = (arr1, arr2) => (
    <Box
      id="statistic-table"
      m={1}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      align="center"
      className={classes.table}
    >
      <Box p={1} display="flex" fontSize={18}>
        <Box style={{ width: '50%' }}>
          Statistic
        </Box>
        <Box style={{ width: '25%' }}>
          Per Acre
        </Box>
        <Box style={{ width: '25%' }}>
          Total
        </Box>

      </Box>

      <Divider style={{ color: grey, height: 4 }} />
      {
          arr1.map((x, i) => createStatisticRow(x, i))
        }
      <Box
        key={3}
        p={1}
        display="flex"
        alignItems="center"
        style={{ backgroundColor: 3 % 2 === 0 ? '#ffffff' : lightGrey }}
      >
        <Box style={{ width: '50%' }}>
          {arr2[0].rowName}
        </Box>

        <Box style={{ width: '50%' }}>
          {arr2[0].value}
        </Box>
      </Box>

      {source.toUpperCase() === 'PROFITLAYERS'
        && (
        <Box>
          <Box
            key={4}
            p={1}
            display="flex"
            alignItems="center"
            style={{ backgroundColor: 4 % 2 === 0 ? '#ffffff' : lightGrey }}
          >
            <Box style={{ width: '50%' }}>
              {arr2[1].rowName}
            </Box>

            <Box style={{ width: '50%' }}>
              $
              {arr2[1].value}
            </Box>
          </Box>

          <Box
            key={5}
            p={1}
            display="flex"
            alignItems="center"
            style={{ backgroundColor: 5 % 2 === 0 ? '#ffffff' : lightGrey }}
          >
            <Box style={{ width: '50%' }}>
              {arr2[2].rowName}
            </Box>

            <Box style={{ width: '50%' }}>
              $
              {arr2[2].value}
            </Box>
          </Box>
        </Box>
        )}
    </Box>
  );

  /**
   * Takes Object from an array and it's index. Displays row name, per acre and total
   * values.
   * @param  {Object} x    Entry from array
   * @param  {Number} i    Index
   * @return {JSX}         Table row
   */
  const createStatisticRow = (x, i) => (
    <Box
      key={i}
      p={1}
      display="flex"
      alignItems="center"
      style={{ backgroundColor: i % 2 === 0 ? '#ffffff' : lightGrey }}
    >
      <Box
        style={{ width: '50%' }}
      >
        {x.rowName}

        <CustomToolTip
          title={x.tooltip}
          placement="right"
        >
          <InfoOutlinedIcon className={classes.infoIcon} />
        </CustomToolTip>
      </Box>

      <Box style={{ width: '25%' }}>
        {dollarFormat(x.acre)}
      </Box>

      <Box style={{ width: '25%' }}>
        {`${'$'}${numFormat(x.total, 0, 0)}`}
      </Box>

    </Box>
  );

  // Takes an array of objects to create soil and yield table from
  const createSoilAndYieldTable = (arr1) => (
    <Box
      id="soil-and-yield-table"
      m={1}
      border={1}
      borderColor={grey}
      borderRadius="borderRadius"
      align="center"
      className={classes.table}
    >
      <Box p={1} display="flex" fontSize={18}>
        <Box style={{ width: '75%' }}>
          Soil Statistics and Yield Risk
        </Box>
        <Box style={{ width: '25%' }}>
          Value
        </Box>
      </Box>

      <Divider style={{ color: grey, height: 4 }} />
      {
          arr1.map((x, i) => createSoilAndYieldRow(x, i))
        }
    </Box>
  );

  /**
   * Takes Object from an array and it's index. Displays row name in first column and value in second column.
   * @param  {Object} x    Entry from array
   * @param  {Number} i    Index
   * @return {JSX}         Table row
   */
  const createSoilAndYieldRow = (x, i) => (
    <Box
      key={i}
      p={1}
      display="flex"
      alignItems="center"
      style={{ backgroundColor: i % 2 === 0 ? '#ffffff' : lightGrey }}
    >
      <Box style={{ width: '75%' }}>
        {x.rowName}
      </Box>

      <Box style={{ width: '25%' }}>
        {x.value}
      </Box>

    </Box>
  );

  /**
   * Extracts the necessary data for displaying the distribution of the chosen type
   * @param {String} vflag grpah type to display
   */
  function drawGraph(vflag) {
    // console.log('coverageLevel', coverageLevel)
    // console.log('vflag', vflag)
    const guarantee = guaranteeInsurance;
    const guaranteeYield = (parseInt(coverageLevel) / 100) * approvedYield;
    if (vflag == 'Revenue Distribution') {
      var vertical = guarantee;
      var graphtext = '<b>Revenue Guarantee</b>';
    } else if (vflag == 'Yield Distribution') {
      var vertical = guaranteeYield;
      var graphtext = '<b>Yield Guarantee</b>';
    }
    // console.log(guarantee, guaranteeYield);
    const keystr = vflag;

    const keypair = {
      'Revenue Distribution': 'Revenue ($/acre)',
      'Yield Distribution': 'Yield (bu/acre)',
      'Harvest Price Distribution': 'Price ($/bu)',
      'Insurance Payment Distribution': 'Insurance Payment($/acre)',
    };

    const headings = {
      'Revenue Distribution': 'Revenue Distribution',
      'Yield Distribution': 'Yield Distribution',
      'Harvest Price Distribution': 'Harvest Price Distribution',
      'Insurance Payment Distribution': 'Insurance Payment Distribution',
    };

    const mcs = [];
    const plotseries = [];

    for (var i = 0; i < d_Yprc.length; i++) {
      let yourObject = {};
      if (vflag == 'Yield Distribution') {
        yourObject = {
          yieldprc: d_yieldprc[i],
          Yprc: d_Yprc[i],
        };
      } else if (vflag == 'Revenue Distribution') {
        yourObject = {
          revprc: d_revprc[i],
          Yprc: d_Yprc[i],
        };
      } else if (vflag == 'Harvest Price Distribution') {
        yourObject = {
          priceprc: d_priceprc[i],
          Yprc: d_Yprc[i],
        };
      } else if (vflag == 'Insurance Payment Distribution') {
        yourObject = {
          indemprc: d_indemprc[i],
          Yprc: d_Yprc[i],
        };
      }

      mcs.push(yourObject);
    }

    for (var i = 0; i < d_Yprc.length; i++) {
      if (vflag == 'Revenue Distribution') {
        mcs[i].x = mcs[i].revprc;
        delete mcs[i].revprc;
        mcs[i].y = mcs[i].Yprc;
        delete mcs[i].Yprc;
      } else if (vflag == 'Yield Distribution') {
        mcs[i].x = mcs[i].yieldprc;
        delete mcs[i].yieldprc;
        mcs[i].y = mcs[i].Yprc;
        delete mcs[i].Yprc;
      } else if (vflag == 'Harvest Price Distribution') {
        mcs[i].x = mcs[i].priceprc;
        delete mcs[i].priceprc;
        mcs[i].y = mcs[i].Yprc;
        delete mcs[i].Yprc;
      } else if (vflag == 'Insurance Payment Distribution') {
        mcs[i].x = mcs[i].indemprc;
        delete mcs[i].indemprc;
        mcs[i].y = mcs[i].Yprc;
        delete mcs[i].Yprc;
      }
    }

    let guaranteeYieldPercent; let
      guaranteePercent;
    for (var i = 0; i < d_Yprc.length; i++) {
      // console.log(i, Number(guarantee), d_revprc[i], )
      if (Number(guarantee) >= d_revprc[i] && Number(guarantee) <= d_revprc[i + 1]) {
        guaranteePercent = d_Yprc[i];
      }
      if (Number(guaranteeYield) >= d_yieldprc[i] && Number(guaranteeYield) <= d_yieldprc[i + 1]) {
        guaranteeYieldPercent = d_Yprc[i];
      }
    }
    // console.log(guaranteePercent, guaranteeYieldPercent)

    const options = {
      chart: {
        type: 'line',
        style: {
          fontFamily: "'Open Sans','Segoe UI', Calibri, Arial, Helvetica, sans-serif",
        },
      },
      title: {
        text: `<b>${headings[keystr]}</b>`,
      },
      xAxis: {
        title: {
          text: `<b>${keypair[keystr]}</b>`,
        },
        plotLines: [{
          color: 'red',
          value: vertical,
          width: 2,
          label: {
            text: graphtext,
            verticalAlign: 'middle',
            textAlign: 'top',
            x: -10,
            rotation: -90,
            // verticalAlign: 'top',
            // textAlign: 'top',
            // x: -10,
            // y: -10,
            // rotation: 0
          },
          zIndex: 5,
        }],

      },
      yAxis: {
        title: {
          text: '<b>Probability (%)</b>',
        },
        max: 100,
        tickInterval: 25,
      },
      legend: {
        enabled: false,
      },
      series: [{
        data: [{}],
      },
        // {
        //    data: [{}]
        // }
      ],
      tooltip: {

        formatter() {
          if (vflag == 'Revenue Distribution') {
            // if (this.x == guarantee && options.series[1].color == "#FF0000") {
            //    console.log(frepay)
            //    return 'Your <strong>Revenue Guarantee</strong> will be less than <strong>$' +
            //      Math.round((this.x * 100) / 100) + '/acre</strong>' + ' approximately <strong>' + freqpay + '%</strong> of the time'
            // }
            // else {
            return `Your <strong>${keypair[keystr]}</strong> will be less than <strong>$${Math.round((this.x * 100) / 100)
            }/acre</strong>` + ` approximately <strong>${this.y}%</strong> of the time`;
            // }
          } if (vflag == 'Yield Distribution') {
            return `Your <strong>${keypair[keystr]}</strong> will be less than <strong>${Math.round((this.x * 100) / 100)
            } bu/acre</strong> approximately <strong>${this.y}%</strong> of the time`;
          } if (vflag == 'Harvest Price Distribution') {
            return `Your <strong>${keypair[keystr]}</strong> will be less than <strong>$${Math.round(this.x * 100) / 100
            }/bu</strong>` + ` approximately <strong>${this.y}%</strong> of time`;
          } if (vflag == 'Insurance Payment Distribution') {
            const unit = '$/acre';
            return `If you have a claim, your <strong>${keypair[keystr]}</strong> will be less than <strong>$${
              Math.round(this.x * 100) / 100}/acre</strong> approximately <strong>${this.y}%</strong> of time`;
          }
        },
        crosshairs: true,
      },
      navigation: {
        menuItemStyle: {
          borderLeft: '10px solid #FFFFFF',
          fontSize: '15px',
          padding: '2px 10px',
        },
      },
    };

    options.series[0].data = mcs;
    setGraphData(options);
  }

  const header = () => (
    <Box mt={1} mx={1}>
      <Box fontSize={18}>
        Crop Insurance AI - Insurance, Revenue, and Yield Forecast
      </Box>

      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        This tool factors in your fields, soil, climate and location to
        create a customized yield risk model, estimates your likelihood
        of insurance payments for different types of insurance, and
        compares it to the cost of the insurance.
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        Enter your own yield information in the &quot;Advanced&quot; Tab to refine your estimates.
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        This information is a premium estimate only, and is only for
        educational purposes. You must refer to an authorized crop
        insurance agent for official quote(s) and specific information
        regarding insurance coverage, actuarial information, conditions
        and exclusions.
      </Typography>
      <Typography variant="body1" style={{ fontWeight: 500, color: blackText }}>
        This tool assumes Optional Units and works for Corn (Grain) and Soybeans in select counties.
      </Typography>
      <Divider />
    </Box>
  );

  // What is actually returned for InsuranceAI tab
  return (
    <Box
      color={blackText}
      fontWeight={500}
    >
      { header() }

      { locationErrorMessage === '' ? (
        <>
          <Box
            display="flex"
            flexWrap="wrap"
          >
            <Box
              display="flex"
              flexWrap="wrap"
              width="100%"
            >
              {/* Selections */}
              <GeneralSettings
                handleChange={handleChange}
                commodityYear={commodityYear}
                crops={insuranceCrops}
                crop={crop}
                disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
              />

              {/* Shows insurance type, coverage level, display (for graph) */}
              <OptionsBox
                insuranceType={insuranceType}
                setInsuranceType={setInsuranceType}
                coverageLevel={coverageLevel}
                coverageLevels={coverageLevels}
                setCoverageLevel={setCoverageLevel}
                displayOption={displayOption}
                setDisplayOption={setDisplayOption}
                getInputAndSendRequest={getInputAndSendRequest}
                disableUserInputs={disableUserInputs || disableUserInputsOnLoad}
              />
            </Box>
          </Box>

          <Box
            display="flex"
            flexWrap="wrap"
          >
            <Box
              display="flex"
              flexWrap="wrap"
              alignItems="center"
              justifyContent="center"
            >
              <Box>
                {/* Shows the buttons for recalculating the premium, refining the yield values, and advanced options */}
                {refiningEstimates && (
                  <RefineEstimates
                    yields={yields}
                    setYields={setYields}
                    updateYields={updateYields}
                    open={refiningEstimates}
                    setOpen={setRefiningEstimates}
                    mobileView={mobileView}
                    commodityYear={commodityYear}
                    exclusionYears={exclusionYears}
                    getCalculateYieldFromHistoryData={getCalculateYieldFromHistoryData}
                    getInputAndSendRequest={getInputAndSendRequest}
                  />
                )}

                {showAdvancedOptions && (
                  <AdvancedOptions
                    open={showAdvancedOptions}
                    setOpen={setShowAdvancedOptions}
                    options={advancedOptions}
                    updateOptions={updateAdvancedOptions}
                    mobileView={mobileView}
                    getInputAndSendRequest={getInputAndSendRequest}
                    forInsuranceAI
                    handleChange={handleChange}
                    types={types}
                    type={type}
                    practices={practices}
                    practice={practice}
                    acres={acres}
                    projectedPrice={projectedPrice}
                    approvedYield={approvedYield}
                  />
                )}

                <Box
                  my={2}
                  display="flex"
                  flexWrap="wrap"
                >
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => getInputAndSendRequest()}
                    style={{ margin: 8 }}
                    disabled={disableUserInputsOnLoad || showErrorMessage}
                    disableElevation
                  >
                    Recalculate Premium
                  </Button>

                  <Button
                    color="primary"
                    variant="outlined"
                    onClick={() => setRefiningEstimates(!refiningEstimates)}
                    style={{ margin: 8, height: 40, whiteSpace: 'nowrap' }}
                    disabled={disableUserInputsOnLoad || showErrorMessage}
                    disableElevation
                  >
                    Refine Estimates
                  </Button>

                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ margin: 8, height: 40, whiteSpace: 'nowrap' }}
                    onClick={() => toggleOptions()}
                    disabled={disableUserInputsOnLoad || showErrorMessage}
                    disableElevation
                  >
                    Advanced Options
                  </Button>
                </Box>

                {showTables && !showErrorMessage && (
                  <Box p={1}>
                    <HighchartsReact
                      containerProps={{
                        style: {
                          height: 350,
                        },
                      }}
                      highcharts={Highcharts}
                      options={graphData}
                    />
                  </Box>
                )}
              </Box>

              <Box id="insurance-ai-tables">
                {showTables && !showErrorMessage && (
                  <Box
                    p={1}
                    className={classes.table}
                  >
                    {createStatisticTable(...statisticTableInputs)}

                    <Box
                      className={classes.table}
                      m={1}
                      mb={3}
                      color={grey}
                    >
                      * Estimated insurance payments only include losses for yield and price, not replant and preplant losses.
                    </Box>

                    {createSoilAndYieldTable(soilAndYieldTableInputs)}

                  </Box>
                )}
              </Box>
            </Box>
          </Box>

          {showTables && !showErrorMessage && (
            <Box
              m={1}
              color="#e60000"
              display="flex"
              justifyContent="center"
              style={{ minWidth: '450px', maxWidth: '1000px' }}
            >
              ** This information is a premium estimate only, and is only for educational purposes. You must refer to an authorized crop insurance agent for official quote(s) and specific information regarding insurance coverage, actuarial information, conditions and exclusions.
            </Box>
          )}

          {showErrorMessage && (
            <Box m={1} display="flex">
              <Box className={classes.errorMessageBox}>
                {errorMessage[1]}
              </Box>
            </Box>
          )}
        </>
      ) : (
        <Box m={1} display="flex">
          <Box className={classes.errorMessageBox}>
            {locationErrorMessage}
          </Box>
        </Box>
      )}
    </Box>
  );
}

import React from "react";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core";
import { COLORS } from "../../../../../constants";
import HeaderRow from "./HeaderRow/index";
import MarketTableRow from "./MarketTableRow/index";

const useStyles = makeStyles(() => ({
  wrapper: {
    overflowX: "auto",
  },
  visualizationContainer: {
    width: "100%",
    marginTop: "35px",
    color: COLORS.text,
    fontSize: 14,
  },
}));

/**
 * Renders the market report overview viz container
 * @param {object} data object with keys that are the tab categories and values that are an array of data objects
 * @param {string} activeCategory the name of the active category
 */

const VisualizationContainer = ({ data, activeCategory }) => {
  const classes = useStyles();

  // there are two rows of headings
  const headings = [
    {
      col2: {
        Seeding: "Avg. Seeding Rate (seeds/Acre)",
        Yields: "Avg. Yield Amount (bushel/Acre)",
        Chemicals: "Avg. Applied Amount (lbs/Acre)",
      },
      col3: "",
      col4: {
        Seeding: "Avg. Planting Date",
        Yields: "Avg. Harvest Date",
        Chemicals: "Avg. Application Date",
      },
      col5: "",
    },
    {
      col2: { one: "State", two: "Your Farm" },
      col3: "% Difference",
      col4: "State",
      col5: "Your Farm",
    },
  ];

  return (
    <div className={classes.wrapper}>
      <table className={classes.visualizationContainer}>
        <tbody>
          {headings.map((heading, i) => (
            <HeaderRow
              key={`header-row-${i}-for-table`}
              headings={heading}
              index={i}
              category={activeCategory}
            />
          ))}
          {data[activeCategory] !== undefined &&
            data[activeCategory].map((row, i) => (
              <MarketTableRow
                key={`row-for-${i}`}
                row={row}
                category={activeCategory}
                index={i}
              />
            ))}
        </tbody>
      </table>
    </div>
  );
};

export default VisualizationContainer;

VisualizationContainer.propTypes = {
  data: PropTypes.object.isRequired,
  activeCategory: PropTypes.string.isRequired,
};

import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Box, Divider, Typography } from "@material-ui/core";
import FiberManualRecordIcon from '@material-ui/icons/FiberManualRecord';
import { numFormat, dollarFormat } from '../../../../utils/helpers';
import { green, blackText } from '../../../../styles/colors'
import { unitConversion } from '../../utils/conversions';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    justifyContent: 'center',
    width: '100%'
  },
  table: {
    marginTop: 4,
    fontWeight: 500,
    color: theme.palette.text.black,
    minWidth: 300,
  },
  labels: {
    display: "flex",
    padding: "4px 8px",
    alignItems: "center",
    fontWeight: 500,
    color: 'white',
    backgroundColor: theme.palette.primary.main
  },
  tableRow: {
    display: "flex",
    flexGrow: 1,
    padding: "8px",
    fontWeight: 500,
    color: theme.palette.text.black,
  },
}));

export function MobileOperationSummary({
  legend,
  unit,
  data,
  perAcre,
  netSeededAcres,
}) {
  const classes = useStyles();

  const [formattedUnit, setFormattedUnit] = useState('')
  const [mean, setMean] = useState(0)

  useEffect(() => {
    if (unit !== '' && unit !== null && unit !== undefined) {
      let converted = unitConversion(unit, true);
      let formatted = converted.charAt(0).toUpperCase() + unit.slice(1);
      setFormattedUnit(formatted)
    }
  }, [unit])

  useEffect(() => {
    if (data !== undefined && data.length > 0) {
      setMean(numFormat(data[0].mean))
    }
  }, [data])


  const legendRow = (x, i) => {
    return (
      <Box
        key={i}
        display='flex'
        alignItems='center'
        style={{padding: 4}}
      >
        <FiberManualRecordIcon
          style={{color: x.color, marginRight: 6}}
        />
        <Box>
          {numFormat(x.min)}
          {' '}
          {formattedUnit}
          {' - '}
          {numFormat(x.max)}
          {' '}
          {formattedUnit}
        </Box>
      </Box>
    )
  }

  return (
    <Box className={classes.root}>
      <Box
        className={classes.table}
        border={1}
        borderColor={green}
        borderRadius='borderRadius'
      >
        <Box style={{backgroundColor: green}}>
          <Typography align='center' style={{fontWeight: 500, fontSize: 16, color: '#ffffff'}}>
            Operation Map Summary
          </Typography>

          <Typography align='center' style={{fontWeight: 500, fontSize: 14, color: '#ffffff'}}>
            {`Average ${formattedUnit} ${perAcre ? 'per acre' : ''}: ${mean}`}
          </Typography>
        </Box>

          {
            legend !== undefined && (
              legend.map((x, i) => legendRow(x, i))
            )
          }

      </Box>
    </Box>

  )
}

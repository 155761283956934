import { Endpoints } from "../constants/Endpoints";

const STATENAMES = "/api/RMARatesData/getStateNames";
const GETHARVESTVARIETYDATA = "/api/FarmReport/GetHarvestVarietyData";
const GETSEEDINGVARIETYDATA = "/api/FarmReport/GetSeedingVarietyData";
const GETHARVESTVARIETYFORFARM = "/api/FarmReport/GetHarvestVarietyForFarm";
const GETSEEDINGVARIETYFORFARM = "/api/FarmReport/GetSeedingVarietyForFarm";
const GETHARVESTDATA = "/api/FarmReport/GetHarvestData";
const GETSEEDINGDATA = "/api/FarmReport/GetSeedingData";
const GETHARVESTDATAFORFARM = "/api/FarmReport/GetHarvestDataForFarm";
const GETSEEDINGDATAFORFARM = "/api/FarmReport/GetSeedingDataForFarm";
const GETNUTRIENTDATA = "/api/FarmReport/GetNutrientData";
const GETNUTRIENTDATAFORFARM = "/api/FarmReport/GetNutrientDataForFarm";
const PRECIPFORSTATE = "/api/FarmReport/GetPrecipitationDataForState";
const GETNCCPI = "/api/FarmReport/GetNCCPI";
const GETSOILDATA = "/api/FarmReport/GetSoilData";
const LOGFARMREPORTINFO = "/api/FarmReport/LogFarmReportInfo";
const GETFARMREPORTINFO = "/api/FarmReport/GetFarmReportInfo";
const GDDFORSTATE = "/api/FarmReport/GetGDDDataForState";
const GETCHEMICALDATA = "/api/FarmReport/GetChemicalData";
const GETGDDDATA = "/api/FarmReport/GetGDDData";
const GETMULTIYEARHARVESTDATA = "/api/FarmReport/GetMultiYearHarvestData";
const GETMULTIYEARNUTRIENTDATA = "/api/FarmReport/GetMultiYearNutrientData";
const GETNUTRIENTRATEFORFIELDS = "/api/FarmReport/GetNutrientRateForOps";
const GETHARVESTDATAFORCOUNTY = "/api/FarmReport/GetHarvestDataForCounty";
const GETPRECIPDATAFORCOUNTIES = "/api/FarmReport/GetPrecipitationDataForCounties";
const GETGDDDATAFORCOUNTIES = "/api/FarmReport/GetGddDataForCounties";
const GETVARIETYBREAKDOWN = "/api/FarmReport/GetVarietyBreakdown";
const GETMULTIYEARVARIETYDATA = "/api/FarmReport/GetMultiYearVarietyData";
const GETPRECIPITATION = "/api/FarmReport/GetPrecipitation"
const GETGDD = "/api/FarmReport/GetGDD"

export async function getFarmOperations(orgId, farmID, year, type) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETFARMOPERATIONS +
        "?orgID=" +
        orgId +
        "&farmID=" +
        farmID +
        "&year=" +
        year +
        "&type=" +
        type,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("failed to get farm operations", err);
  }
}

export async function getFieldWithProfitSummary(orgId, farmID, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETFIELDWITHPROFITSUMMARY +
        "?orgID=" +
        orgId +
        "&farmID=" +
        farmID +
        "&year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("failed to get farm operations", err);
  }
}

export async function getNutrientDataForFarm(orgId, farmId, year, nutrients) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETNUTRIENTDATAFARM +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmId +
        "&Year=" +
        year,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          NutrientNames: nutrients.join(","), 
          Year: year.join(",")
        })
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getPrecipData(fips, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETPRECIPITATIONDATA,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Year: year,
          FIPS: fips
        }),
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getGddData(fips, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETGDDDATA +
        "?FIPS=" +
        fips +
        "&Year=" +
        year,
      {
        method: "POST",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          Year: year,
          FIPS: fips
        }),
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}


export const getStateNames = async () => {
  try {
    let response = await fetch(`${Endpoints.BASEURL}${STATENAMES}`, {
      method: "GET",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    return response.json();
  } catch (err) {
    console.log("Error getting severe weather alerts: ", err);
  }
};

export async function getHarvestVarietyData(year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETHARVESTVARIETYDATA +
        "?Year=" +
        year +
        "&State=" +
        state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getSeedingVarietyData(year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETSEEDINGVARIETYDATA +
        "?Year=" +
        year +
        "&State=" +
        state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getHarvestVarietyForFarm(orgId, farmId, year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETHARVESTVARIETYFORFARM +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmId +
        "&Year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getSeedingVarietyForFarm(orgId, farmId, year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETSEEDINGVARIETYFORFARM +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmId +
        "&Year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getHarvestData(year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GETHARVESTDATA + "?Year=" + year + "&State=" + state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getSeedingData(year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GETSEEDINGDATA + "?Year=" + year + "&State=" + state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getHarvestDataForFarm(orgId, farmId, year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETHARVESTDATAFORFARM +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmId +
        "&Year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getSeedingDataForFarm(orgId, farmId, year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETSEEDINGDATAFORFARM +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmId +
        "&Year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getNutrientData(year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GETNUTRIENTDATA + "?Year=" + year + "&State=" + state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getNutrientDataForSelectedFarm(
  orgId,
  farmId,
  year,
  state
) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETNUTRIENTDATAFORFARM +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmId +
        "&Year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function fetchStateAndCountyForFields(orgId, farmId) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        Endpoints.GETSTATEANDCOUNTYOFFIELD +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmId,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getPrecipitationForState(state, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + PRECIPFORSTATE + "?State=" + state + "&Year=" + year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getNccpiForState(state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GETNCCPI + "?State=" + state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error processing Polaris", error);
  }
}

export async function getSoilData(state, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GETSOILDATA + "?State=" + state + "&Year=" + year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting precip data", error);
  }
}

export async function logFarmReportInfo(request) {
  const url = `${Endpoints.BASEURL}${LOGFARMREPORTINFO}`;
  try {
    let response = await fetch(url, {
      method: "POST",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
      body: JSON.stringify(request),
    });
    return response.json();
  } catch (err) {
    console.log(`Error logging farm report info: ${err}`);
  }
}

export async function getFarmReportSummary(orgId, farmID, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETFARMREPORTINFO +
        "?orgID=" +
        orgId +
        "&farmID=" +
        farmID +
        "&year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("failed to get farm operations", err);
  }
}

export async function getGddForState(state, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GDDFORSTATE + "?State=" + state + "&Year=" + year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting gdd data", error);
  }
}

export async function getChemicalData(orgId, farmID, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETCHEMICALDATA +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmID +
        "&Year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("failed to get chemicals data", err);
  }
}

export async function getMultiYearHarvestData(orgId, farmID) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETMULTIYEARHARVESTDATA +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmID,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("failed to get multi year harvest data", err);
  }
}

export async function getMultiYearNutrientData(orgId, farmID) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETMULTIYEARNUTRIENTDATA +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmID,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("failed to get multi year nutrient data", err);
  }
}


export async function getNutrientDataForFields(orgId, farmID, year) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
        GETNUTRIENTRATEFORFIELDS +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmID +
        "&Year=" +
        year,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (err) {
    console.log("failed to get chemicals data", err);
  }
}

export async function getHarvestDataForCounty(year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GETHARVESTDATAFORCOUNTY + "?Year=" + year + "&State=" + state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting harvest info for county", error);
  }
}

export async function getPrecipDataForCounty(year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GETPRECIPDATAFORCOUNTIES + "?Year=" + year + "&State=" + state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting precip info for county", error);
  }
}

export async function getGddDataForCounty(year, state) {
  try {
    let response = await fetch(
      Endpoints.BASEURL + GETGDDDATAFORCOUNTIES + "?Year=" + year + "&State=" + state,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      }
    );
    return response.json();
  } catch (error) {
    console.log("Error getting gdd info for county", error);
  }
}

export async function getVarietyBreakdown(OrgID, Year, fieldIds){
  try{
    let response = await fetch(
      Endpoints.BASEURL +
        GETVARIETYBREAKDOWN +
        "?OrgID=" +
        OrgID,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          fieldIds: fieldIds,
          Years: Year
        })
      }
    );
    return response.json();
  }
  catch(err){
    console.log("error getting variety breakdown", )
  }
}


export async function getMultiYearVarietyData(orgId, farmID) {
  try {
    let response = await fetch(
      Endpoints.BASEURL +
      GETMULTIYEARVARIETYDATA +
        "?OrgID=" +
        orgId +
        "&FarmID=" +
        farmID,
      {
        method: "GET",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        }
      }
    );
    return response.json();
  } catch (err) {
    console.log("failed to get multi year harvest data", err);
  }
}


export async function getPrecipitation(xcoord, ycoord, year, startMonth, endMonth){
  try{
    let response = await fetch(
      Endpoints.BASEURL +
      GETPRECIPITATION +
      "?xcoord=" +
      xcoord +
      "&ycoord=" +
      ycoord +
      "&startMonth=" +
      startMonth +
      "&endMonth=" +
      endMonth,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Year: year.join(",")
        })
      }
    );
    return response.json();
  }
  catch(err){
    console.log("getPrecipitation error", err)
  }
}

export async function getGDD(xcoord, ycoord, year, startMonth, endMonth){
  try{
    let response = await fetch(
      Endpoints.BASEURL +
      GETGDD +
      "?xcoord=" +
      xcoord +
      "&ycoord=" +
      ycoord +
      "&startMonth=" +
      startMonth +
      "&endMonth=" +
      endMonth,
      {
        method: "POST",
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Year: year.join(",")
        })
      }
    );
    return response.json();
  }
  catch(err){
    console.log("getGDD error", err)
  }
}
import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TableBody,
  TableContainer,
  Table,
  makeStyles,
} from "@material-ui/core";

import { ascending, groups, min, max, sum, extent } from "d3-array";
import { scaleQuantize } from "d3-scale";
import {
  COLOR_RANGE,
  COLORS,
  SORT,
  SORT_LOOKUP,
  NEGATIVE_COLOR_RANGE,
  POSITIVE_COLOR_RANGE
} from "../../../../../../constants";
import Header from "../../../../../Helpers/Table/Header";
import Row from "./VarietyProfitRow";
import { format } from "d3-format";
import { kebabCase } from "lodash";

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    overflowY: "auto",
    minWidth: "1000px",
    overflowX: 'hidden'
  },
  table: {
    // minWidth: "100%",
    color: COLORS.text,
  },
  tableBody: {
    borderColor: COLORS.green,
    borderTop: "2px solid",
    borderBottom: "2px solid",
    "& .MuiTableRow-root": {
      height: 36,
      "&.active": {
        backgroundColor: COLORS.veryLightGrey,
        borderLeft: `3px solid ${COLORS.green}`,
      },
    },
  },
}));

/**
 * Renders the profit by vairety matrix and the handles the sort
 * @param {object} values object with 'profitByVariety' as one of the key which is a key-value object, where key is variety name and values is profit of that variety among all fields, missingFields is an array for which profit map is not yet generated
 */

const ProfitByVarietyMatrixTable = ({
  values,
  fields,
  data,
  options,
  setOptions,
  setData,
  showHeatmap
}) => {
  //console.log("values", values)
  const classes = useStyles();

  const headerCells = [
    {
      heading: "Crop Name",
      id: "crop",
      accessor: (d) => d.cropName,
      format: null,
      aggregateFunction: null,
      unit: null,
    },
    {
      heading: "Variety Name",
      id: "variety",
      accessor: (d) => d.varietyName,
      format: null,
      aggregateFunction: null,
      unit: null,
    },
    {
      heading: "Average Profit",
      id: "average-profit",
      accessor: (d) => d.profit,
      format: format("$,.2f"),
      aggregateFunction: sum,
      unit: " /Acre",
      numeric: true,
    },
    {
      heading: "Variety Acreage",
      id: "acres",
      accessor: (d) => d.varietyAcres,
      format: format(".2f"),
      aggregateFunction: sum,
      unit: "",
      numeric: true,
    }    
  ].map((headerCell) => ({
    ...headerCell,
    id: kebabCase(headerCell.heading),
    scale: headerCell.aggregateFunction
      ? scaleQuantize()
          .range(COLOR_RANGE.map((d) => d.backgroundColor))
          .domain(extent(data, headerCell.accessor))
      : null,
    extentValue: headerCell.aggregateFunction
    ? extent(data, headerCell.accessor) : null
  }))
  
  const [state, setState] = useState({
    order: SORT.Asc,
    orderBy: headerCells[0].id,
  });
  const tableRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === SORT.Asc;
    const isSameIndicator = property === state.orderBy;
    setState({
      orderBy: property,
      order: isSameIndicator ? (isAsc ? SORT.Desc : SORT.Asc) : SORT.Asc,
    });
  };

  // Handle the sort, by the selected header
  const selectedHeader = headerCells.find((d) => d.id === state.orderBy);

  //console.log("data", data)
  let rows = groups(data, (d) => d.varietyName);
  rows = rows.sort((a, b) => {
    const sortFn = SORT_LOOKUP.get(state.order);
    return sortFn(selectedHeader.accessor(a), selectedHeader.accessor(b));
  });

  return (
    <TableContainer id="heat-map-table" className={classes.container}>
      <Table className={classes.table} ref={tableRef}>
        <Header
          order={state.order}
          orderBy={state.orderBy}
          onRequestSort={handleRequestSort}
          headerCells={headerCells}
        />
        <TableBody className={classes.tableBody}>
          {data
            .filter((field) =>
              options.every((option) => {
                const { numeric, accessor, filteredRange } = option;
                const value = accessor(field);
                if (numeric) {
                  return value >= filteredRange[0] && value <= filteredRange[1];
                }
                return filteredRange.includes(value);
              })
            )
            .sort((a, b) => {
              const sortFn = SORT_LOOKUP.get(state.order);
              return sortFn(
                selectedHeader.accessor(a),
                selectedHeader.accessor(b)
              );
            }).map((row, i) => (
              <Row
                key={`heat-map-table-row-for-${i}`}
                row={row}
                headerCells={headerCells}
                showHeatmap={showHeatmap}
              />
            ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ProfitByVarietyMatrixTable;

ProfitByVarietyMatrixTable.propTypes = {
  values: PropTypes.object.isRequired,
};

import React, { useEffect, useState, useRef } from 'react';
import { Box } from '@material-ui/core';
import * as L from 'leaflet';
import '@geoman-io/leaflet-geoman-free';
import '@geoman-io/leaflet-geoman-free/dist/leaflet-geoman.css';
import 'leaflet-path-transform';
import '../../Maps/leaflet.css';
import { OpenStreetMapProvider } from 'leaflet-geosearch';
import { drawBasicMap } from '../../Maps/MapFunctions/drawMap'
import {
  drawFieldLayer,
  drawImage,
  getMinHeight,
} from '../../Maps/MapFunctions/helpers';
import { exists } from '../../../utils/helpers';
import { Tiles } from '../../../constants/Tiles';
import { geoAlbers } from 'd3-geo';



export function PreviousBoundariesMap({
  boundary, extent
}){

  const [map, setMap] = useState(null);
  const imageOverlay = useRef(null);
  const fieldLayer = useRef(L.featureGroup(null))
  const boundaryLayer = useRef(L.geoJSON(null))

  useEffect(() => {
    drawMap()
  },[])


  useEffect(() => {
    console.log(boundary)
    if (exists(boundary) && map !== null) {
      const features = typeof boundary === 'object' ? boundary : JSON.parse(boundary);
      const geoJson = {
        type: 'FeatureCollection',
        features: [features],
      };
      boundaryLayer.current.clearLayers()
      boundaryLayer.current.addData(geoJson)
      if(!map.hasLayer(boundaryLayer.current)){
        let bounds = boundaryLayer.current.getBounds()
        map.fitBounds(bounds)
        boundaryLayer.current.addTo(map)

      }
    }
  },[boundary,map])

  async function drawMap() {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    const provider = new OpenStreetMapProvider();
    const map = L.map('boundary-map', {
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    })
      .setView([41.016, -92.4083], 5)
      .addLayer(mapboxTiles);

    L.easyPrint({
      sizeModes: ['A4Portrait', 'A4Landscape', 'Current'],
    }).addTo(map);

    setMap(map);
  }

  return(
    <Box
      id="boundary-map"
      display="flex"
      flexGrow={1}
      borderRadius={4}
      minHeight={getMinHeight()}
    />
  )

}
// Helpful Packages
import * as turf from '@turf/turf';
import * as proj4 from 'proj4/dist/proj4';


// Function for caclculating area from coordinates
function areaCacl(arrayOfx, arrayOfy) {
    let n = arrayOfx.length;

    let area = 0;
    for (var i = 0; i < n-1; i++){
        area += arrayOfx[i] * arrayOfy[i+1] - arrayOfy[i] * arrayOfx[i+1] 
    }

    if (n >2){
        area += arrayOfx[n-1] * arrayOfy[0] - arrayOfy[n-1] * arrayOfx[0]
    }

    return Math.abs(area/2);
}

// Function for defining UTM zone
function UTMzone(long, lat) {
    const zone = Math.floor((long+180)/6) + 1;

    let crs = 'EPSG:3857'

    if (lat > 0) {
        switch (zone) {
            case 1:
                crs = 'EPSG:26901';
                break;
            
            case 2:
                crs = 'EPSG:26902';
                break;
                
            case 3:
                crs = 'EPSG:26903';
                break;

            case 4:
                crs = 'EPSG:26904';
                break;

            case 5:
                crs = 'EPSG:26905';
                break;

            case 6:
                crs = 'EPSG:26906';
                break;

            case 7:
                crs = 'EPSG:26907';
                break;
            
            case 8:
                crs = 'EPSG:26908';
                break;
                
            case 9:
                crs = 'EPSG:26909';
                break;

            case 10:
                crs = 'EPSG:26910';
                break;

            case 11:
                crs = 'EPSG:26911';
                break;

            case 12:
                crs = 'EPSG:26912';
                break;

            case 13:
                crs = 'EPSG:26913';
                break;
            
            case 14:
                crs = 'EPSG:26914';
                break;
                
            case 15:
                crs = 'EPSG:26915';
                break;

            case 16:
                crs = 'EPSG:26916';
                break;

            case 17:
                crs = 'EPSG:26917';
                break;

            case 18:
                crs = 'EPSG:26918';
                break;

            case 19:
                crs = 'EPSG:26919';
                break;
            
            case 20:
                crs = 'EPSG:26920';
                break;
                
            case 21:
                crs = 'EPSG:26921';
                break;

            case 22:
                crs = 'EPSG:26922';
                break;

            case 23:
                crs = 'EPSG:26923';
                break;

            case 55:
                crs = 'EPSG:32655';
                break;
        }
    }

    if (lat <0) {
        switch (zone) {
            case 2:
                crs = 'EPSG:32702';    
                break;
        
            case 55:
                crs = 'EPSG:32755';    
                break;
        }
    }

    return crs;
}

// Main function
/**
 * Takes an input boundary, its type and CRS, and the requested output CRS and area unit.
 * Using given info, reprojects the given boundary to the requested output CRS and calculates its area.
 * 
 * Accepted arguments are listed next to the parameters.
 * For boundary, accepted string type is wkt. And accepted object type is one of the following turf objects:
 * (Polygon, MultiPolygon, Feature, FeatureCollection). NOTE: only the first feature in a FeatureCollection will be used however.
 * 
 * @param {Object|String} boundary Boundary to be reprojected
 * @param {String} reprojectCRS Requested output CRS - 'EPSG:3857' or 'UTM'
 * @param {String} boundaryType Type of input boundary - 'geojson' or 'json' for GeoJSON and 'wkt' or 'txt' for WKT
 * @param {String} inputCRS Input Coordinate Refrence System (CRS) - 'WGS84' or ? (not tested with other)
 * @param {String} areaUnit Requested unit of output area - 'Acres', 'ha', 'km2', 'm2', 'mi2', 'sqft', 'sqinch'
 * @returns {Object} Object with area, areaunit, epsgCode, and inputCRS info
 */
export function reprojectCalcArea(boundary, reprojectCRS='EPSG:3857', boundaryType = 'wkt', inputCRS = 'WGS84', areaUnit = 'm2') {
// function reprojectCalcArea(boundary, reprojectCRS="EPSG:3857", boundaryType = 'wkt', inputCRS = 'WGS84', areaUnit = 'm2') {
    // Projections added for the function
    proj4.defs([            
        ['EPSG:26901', '+proj=utm +zone=1 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26902', '+proj=utm +zone=2 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26903', '+proj=utm +zone=3 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26904', '+proj=utm +zone=4 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26905', '+proj=utm +zone=5 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26906', '+proj=utm +zone=6 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26907', '+proj=utm +zone=7 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26908', '+proj=utm +zone=8 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26909', '+proj=utm +zone=9 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26910', '+proj=utm +zone=10 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26911', '+proj=utm +zone=11 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26912', '+proj=utm +zone=12 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26913', '+proj=utm +zone=13 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26914', '+proj=utm +zone=14 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26915', '+proj=utm +zone=15 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26916', '+proj=utm +zone=16 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26917', '+proj=utm +zone=17 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26918', '+proj=utm +zone=18 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26919', '+proj=utm +zone=19 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26920', '+proj=utm +zone=20 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26921', '+proj=utm +zone=21 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26922', '+proj=utm +zone=22 +datum=NAD83 +units=m +no_defs'],
        ['EPSG:26923', '+proj=utm +zone=23 +datum=NAD83 +units=m +no_defs'],

        ['EPSG:32655', '+proj=utm +zone=55 +datum=WGS84 +units=m +no_defs'],
        ['EPSG:32702', '+proj=utm +zone=2 +south +datum=WGS84 +units=m +no_defs'],
        ['EPSG:32755', '+proj=utm +zone=55 +south +datum=WGS84 +units=m +no_defs'],
    ]);

    // Vars
    let xarray = [];
    let yarray = [];
    let latarray = [];
    let longarray = [];
    let m2area;

    // Handle WKT format
    if (boundaryType.toLowerCase() === 'wkt' || boundaryType.toLowerCase() === 'txt') {
        let indicator = boundary.search('MULTIPOLYGON');
        
        if (indicator == -1) 
        {
            // Checking if inside Polygon exist multiple Polygons
            indicator = boundary.search('\\), \\(')
            if (indicator == -1) 
            {
                indicator = boundary.search('\\), \\(')
                
                if (indicator == -1) 
                {
                    indicator = boundary.search('\\), \\(')
                }
            }
        }

        let splited = '';
        boundary = boundary.replaceAll(' ,',',').replaceAll(', ',',').replaceAll('  ',' ');

        // If MultiPolygon, parse
        if (indicator!=-1) 
        {
            let parsedtext = boundary.replaceAll("MULTIPOLYGON","").replaceAll("POLYGON","").replaceAll('),(','#').replaceAll("(","").replaceAll(")","");
            splited = parsedtext.split("#");
            
            let l1 = splited[0].split(',');
            let lonlatTemp = (l1[0].split(' '));
            
            let lonlat = lonlatTemp.filter(function (el) {
                return el != "";
            });

            let long_first = parseFloat(lonlat[0]);
            let lat_first = parseFloat(lonlat[1]);


            if (reprojectCRS === 'UTM') {
                reprojectCRS = UTMzone(long_first,lat_first);
            }
            
            let areas = [];
            for (let p = 0; p < splited.length; p++) {
                let array1 = splited[p].split(',');

                for (let q = 0; q < array1.length; q++) {
                    let x = array1[q].split(' ');
                    x = x.filter(function (el) {
                        return el != "";
                    });

                    xarray.push(proj4(inputCRS,reprojectCRS,[parseFloat(x[0]),parseFloat(x[1])])[0]);
                    yarray.push(proj4(inputCRS,reprojectCRS,[parseFloat(x[0]),parseFloat(x[1])])[1]);
                }

                areas.push(areaCacl(xarray,yarray));
                xarray = [];
                yarray = [];
            }
            let numberOfPolygons = splited.length;

            let polygon1 = '';
            let polygon2 = '';

            let sign_array = [];
            for (let sa = 0; sa < areas.length; sa++) {
                sign_array[sa]=1;
            }
            
            // Checking holes in polygons
            for (let p = 0; p< numberOfPolygons; p++)
            {
                polygon1 = '[[['+(splited[p].replaceAll(',','],[').replaceAll(' ',','))+']]]';
                let polygon12 = polygon1.replace('[,','[');
                let FirstPolygon = (turf.polygon(JSON.parse(polygon12)));

                for (let q = 0; q < numberOfPolygons; q++) 
                {    
                    polygon2 = '[[['+(splited[q].replaceAll(',','],[').replaceAll(' ',','))+']]]';
                    let polygon22 = polygon2.replace('[,','[');

                    let SecondPolygon = (turf.polygon(JSON.parse(polygon22)));
                    if (p!=q) 
                    {
                        let solve = turf.booleanContains(FirstPolygon,SecondPolygon);

                        if (solve === true) {
                            sign_array[q]=-1;  
                        }
                    }
                    polygon2 = '';
                }
                polygon1 = '';
            }

            m2area = 0;
            for(var i = 0; i< areas.length; i++) 
            {
                m2area += areas[i]*sign_array[i];
            }
        }
        // If Polygon, parse
        else {
            let parsedtext = boundary.replaceAll("POLYGON","").replaceAll("(","").replaceAll(")","");
            splited = parsedtext.split(",");
        
            for (let i = 0; i < splited.length; i++) {
                let coor1 = splited[i];
                let coords = coor1.split(" ")

                if (coords[0] === " " | coords[0] === "") {
                    coords.splice(0,1);
                }

                longarray.push(parseFloat(coords[0]));
                latarray.push(parseFloat(coords[1]));
            }

            let long_first = longarray[0];
            let lat_first = latarray[1];

            if (reprojectCRS === 'UTM') {
                reprojectCRS = UTMzone(long_first,lat_first);
            }

            for (let j = 0; j < latarray.length; j++) {
                xarray.push(proj4(inputCRS,reprojectCRS,[longarray[j],latarray[j]])[0]);
                yarray.push(proj4(inputCRS,reprojectCRS,[longarray[j],latarray[j]])[1]);
            }

            m2area = areaCacl(xarray,yarray);
        }
    }

    // Handle GeoJSON format
    if (boundaryType.toLowerCase() === 'geojson' || boundaryType.toLowerCase() === 'json') {
        // Some vars
        let parsedjson = typeof boundary === 'string' ? JSON.parse(boundary) : boundary;
        var type = turf.getType(parsedjson);
        var geometry = parsedjson;

        // If necessary, get first shape's info from FeatureCollection
        if (type === 'FeatureCollection') {
            geometry = parsedjson.features[0].geometry;
            type =  turf.getType(geometry);
    
            if (type === 'MultiPolygon') {
                parsedjson = parsedjson.features[0].geometry;
            }
        }
        // Or get shape's info from Feature
        if (parsedjson.type === 'Feature') {
            geometry = parsedjson.geometry;
    
            if (type === 'MultiPolygon') {
                parsedjson = parsedjson.geometry;
            }
        }
        // console.log('geometry :>> ', geometry);

        // If MultiPolygon, parse
        if (type === 'MultiPolygon') {
            geometry = turf.getCoords(parsedjson);
            let geometry2 = parsedjson.coordinates;

            // In case that we have multiple nested multipolygons
            if (geometry2.length>1) 
            {
                let n = 0;
                geometry = [];

                for (let g = 0; g < geometry2.length; g++) 
                {
                    let tempArray = geometry2[g];

                    for (let g2 = 0; g2 < tempArray.length; g2++) 
                    {
                        geometry[n] = tempArray[g2];
                        n+=1;
                    }
                }

                let long_first = geometry[0][0][0];
                let lat_first = geometry[0][0][1];

                if (reprojectCRS === 'UTM') 
                {
                    reprojectCRS = UTMzone(long_first,lat_first);
                }
                
                let numberOfPolygons = geometry.length;
                let polygon1 = '';
                let polygon2 = '';
                let areas = [];
                
                for (let q = 0; q < numberOfPolygons; q++) 
                {
                    let polOfInt = geometry[q];
                    
                    for (let co = 0; co < polOfInt.length; co++)
                    {
                        xarray.push(proj4(inputCRS,reprojectCRS,[polOfInt[co][0],polOfInt[co][1]])[0]);
                        yarray.push(proj4(inputCRS,reprojectCRS,[polOfInt[co][0],polOfInt[co][1]])[1]);  
                    }
                    areas.push(areaCacl(xarray,yarray));
                    xarray = [];
                    yarray = [];                           
                }

                let sign_array = [];
                for (let sa = 0; sa < areas.length; sa++) {
                    sign_array[sa]=1;
                }
                
                // Checking holes in polygons
                for (let p = 0; p< numberOfPolygons; p++)
                {
                    for (let pol1 = 0; pol1 < geometry[p].length; pol1++) 
                    {
                        polygon1 += '['+geometry[p][pol1][0].toFixed(5)+','+geometry[p][pol1][1].toFixed(5)+']'
                    }
                    polygon1 = polygon1.replaceAll('][','],[');
                    polygon1 = '[['+polygon1+']]';

                    let FirstPolygon = (turf.polygon(JSON.parse(polygon1)));

                    for (let q = 0; q < numberOfPolygons; q++) 
                    {    
                        for (let pol2 = 0; pol2 < geometry[q].length; pol2++) 
                        {
                            polygon2 += '['+geometry[q][pol2][0].toFixed(5)+','+geometry[q][pol2][1].toFixed(5)+']'
                        }
                        polygon2 = polygon2.replaceAll('][','],[');
                        polygon2 = '[['+polygon2+']]';

                        let SecondPolygon = (turf.polygon(JSON.parse(polygon2)));
                        if (p!=q) 
                        {
                            let solve = turf.booleanContains(FirstPolygon,SecondPolygon);

                            if (solve === true) {
                                sign_array[q]=-1;  
                            }
                        }
                        polygon2 = '';
                    }
                    polygon1 = '';
                }
                m2area = 0;
                for(var i = 0; i< areas.length; i++) 
                {
                    m2area += areas[i]*sign_array[i];
                }
            }
            else
            {
                let n = 0;
                let g_length = geometry.length;

                while (g_length === 1) 
                {
                    geometry = geometry[n];
                    g_length = geometry.length;
                    n+=1;
                }
                
                if (geometry[0].length === 1) 
                {
                    for (let ne = 0; ne < geometry.length; ne++) 
                    {
                        geometry[ne] = turf.coordAll(turf.polygon(geometry[ne]));
                    }
                }

                let long_first = geometry[0][0][0];
                let lat_first = geometry[0][0][1];

                if (reprojectCRS === 'UTM') 
                {
                    reprojectCRS = UTMzone(long_first,lat_first);
                }
                
                let numberOfPolygons = geometry.length;

                let polygon1 = '';
                let polygon2 = '';

                let areas = [];
                for (let p = 0; p < g_length; p++) 
                {
                    for (let q = 0; q < geometry[p].length; q++) 
                    {
                        xarray.push(proj4(inputCRS,reprojectCRS,[geometry[p][q][0],geometry[p][q][1]])[0]);
                        yarray.push(proj4(inputCRS,reprojectCRS,[geometry[p][q][0],geometry[p][q][1]])[1]);                           
                    }

                    areas.push(areaCacl(xarray,yarray));
                    xarray = [];
                    yarray = [];
                }

                let sign_array = [];
                for (let sa = 0; sa < areas.length; sa++) {
                    sign_array[sa]=1;
                }
                
                // Checking holes in polygons
                for (let p = 0; p< numberOfPolygons; p++)
                {
                    for (let pol1 = 0; pol1 < geometry[p].length; pol1++) 
                    {
                        polygon1 += '['+geometry[p][pol1][0].toFixed(5)+','+geometry[p][pol1][1].toFixed(5)+']'
                    }
                    polygon1 = polygon1.replaceAll('][','],[');
                    polygon1 = '[['+polygon1+']]';

                    let FirstPolygon = (turf.polygon(JSON.parse(polygon1)));

                    for (let q = 0; q < numberOfPolygons; q++) 
                    {    
                        for (let pol2 = 0; pol2 < geometry[q].length; pol2++) 
                        {
                            polygon2 += '['+geometry[q][pol2][0].toFixed(5)+','+geometry[q][pol2][1].toFixed(5)+']'
                        }
                        polygon2 = polygon2.replaceAll('][','],[');
                        polygon2 = '[['+polygon2+']]';

                        let SecondPolygon = (turf.polygon(JSON.parse(polygon2)));
                        if (p!=q) 
                        {
                            let solve = turf.booleanContains(FirstPolygon,SecondPolygon);

                            if (solve === true) {
                                sign_array[q]=-1;  
                            }
                        }
                        polygon2 = '';
                    }
                    polygon1 = '';
                }

                m2area = 0;
                for(var i = 0; i< areas.length; i++) 
                {
                    m2area += areas[i]*sign_array[i];
                }

            }
        }

        // If Polygon, parse
        if (type === 'Polygon') {
            let numberOfPolygons = geometry.coordinates.length;
            let projCoords = turf.coordAll(geometry);

            let long_first = projCoords[0][0];
            let lat_first = projCoords[0][1];

            if (reprojectCRS === 'UTM') {
                reprojectCRS = UTMzone(long_first,lat_first);
            }

            if (numberOfPolygons>1)
            {
                // Inside the Polygon exist multiple other polygons
                // Therefore we have to take into area calculation all of them

                let polygon1 = '';
                let polygon2 = '';

                let areas = [];
                
                for (let q = 0; q < numberOfPolygons; q++) 
                {
                    let polOfInt = geometry.coordinates[q];
                    
                    for (let co = 0; co < polOfInt.length; co++)
                    {
                        xarray.push(proj4(inputCRS,reprojectCRS,[polOfInt[co][0],polOfInt[co][1]])[0]);
                        yarray.push(proj4(inputCRS,reprojectCRS,[polOfInt[co][0],polOfInt[co][1]])[1]);  
                    }
                    areas.push(areaCacl(xarray,yarray));
                    xarray = [];
                    yarray = [];                           
                }

                let sign_array = [];
                for (let sa = 0; sa < areas.length; sa++) {
                    sign_array[sa]=1;
                }
                
                // Checking holes in polygons
                for (let p = 0; p< numberOfPolygons; p++)
                {
                    for (let pol1 = 0; pol1 < geometry.coordinates[p].length; pol1++) 
                    {
                        polygon1 += '['+geometry.coordinates[p][pol1][0].toFixed(5)+','+geometry.coordinates[p][pol1][1].toFixed(5)+']'
                    }
                    polygon1 = polygon1.replaceAll('][','],[');
                    polygon1 = '[['+polygon1+']]';

                    let FirstPolygon = (turf.polygon(JSON.parse(polygon1)));

                    for (let q = 0; q < numberOfPolygons; q++) 
                        {    
                            for (let pol2 = 0; pol2 < geometry.coordinates[q].length; pol2++) 
                        {
                            polygon2 += '['+geometry.coordinates[q][pol2][0].toFixed(5)+','+geometry.coordinates[q][pol2][1].toFixed(5)+']'
                        }
                        polygon2 = polygon2.replaceAll('][','],[');
                        polygon2 = '[['+polygon2+']]';

                        let SecondPolygon = (turf.polygon(JSON.parse(polygon2)));
                        if (p!=q) 
                        {
                            let solve = turf.booleanContains(FirstPolygon,SecondPolygon);

                            if (solve === true) {
                                sign_array[q]=-1;  
                            }
                        }
                        polygon2 = '';
                    }
                    polygon1 = '';
                }
                m2area = 0;
                for(var i = 0; i< areas.length; i++) 
                {
                    m2area += areas[i]*sign_array[i];
                }
            }
            else
            {
                let json_coordinates = turf.coordAll(geometry);

                for (let int = 0; int < json_coordinates.length; int++) {
                    longarray.push(json_coordinates[int][0]);
                    latarray.push(json_coordinates[int][1]);
                }

                let long_first = longarray[0];
                let lat_first = latarray[0];

                for (let j = 0; j < latarray.length; j++) {
                    xarray.push(proj4(inputCRS,reprojectCRS,[longarray[j],latarray[j]])[0]);
                    yarray.push(proj4(inputCRS,reprojectCRS,[longarray[j],latarray[j]])[1]);
                }

                m2area = areaCacl(xarray,yarray);
            }  
        }        
    }

    // Caculating the total area
    let unitOfArea = 'Acres';
    let areavalue = Math.abs(m2area);

    // Convert to other units, if necessary
    switch (areaUnit) {
        case 'ha':
            areavalue*=0.0001;
            unitOfArea = 'Hectares';
            break;

        case 'km2':
            areavalue*=0.000001;
            unitOfArea = 'Square kilometers';
            break;

        case 'm2':
            areavalue = m2area;
            unitOfArea = 'Square meters';
            break;

        case 'mi2':
            areavalue*=0.000000386102159;
            unitOfArea = 'Square miles';
            break;
        
        case 'sqft':
            areavalue*=10.7639104;
            unitOfArea = 'Square foot';
            break;

        case 'sqinch':
            areavalue*=1550.0031;
            unitOfArea = 'Square inches';
            break;
    
        default :
            areavalue*=0.000247105381;
            unitOfArea = 'Acres';
            break;
    }

    // Return formatted input
    const epsgCode = reprojectCRS.replace("EPSG:","");
    let outputJSON = {
        area: areavalue,
        areaUnit: unitOfArea,
        epsgCode: epsgCode,
        inputCRS: inputCRS
    };
    return outputJSON;
}
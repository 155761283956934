import React, {
  useState, useContext, useRef, useEffect,
} from 'react';
import PropTypes from 'prop-types';

// MUI components, icons, and styling
import {
  Container, Box, Typography, Button, Checkbox, Drawer, Fade, TextField, Tooltip, IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useSnackbar } from 'notistack';
import CheckBoxOutlineBlankIcon from '@material-ui/icons/CheckBoxOutlineBlank';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import CloseIcon from '@material-ui/icons/Close';
import LinkIcon from '@material-ui/icons/Link';
import HelpIcon from '@material-ui/icons/Help';

import {
  darkGreen, darkBlue, grey, grey2, lightGrey, red, pewter, blackText,
} from '../../../styles/colors';

// Import components, context, and functions
import { UserContext } from '../../Context/UserContext';
import { IntegrationStatus } from './IntegrationStatus';
import { AgentPortal } from '../Agents/AgentPortal';
import { IntroModal } from '../Videos/IntroModal';
import { UploadClus } from './UploadClus';
import { UploadAgFile } from '../../ProfitLayers/utils/UploadAgFile';
import { Help } from './Help';
import {
  signInFromAcreageOnboarding,
  signUpFromAcreageOnboarding,
  integratePrecision,
} from '../../../utils/signInLinks';
import { sendInviteEmail, generateInviteLink, copyToClipboard } from '../Agents/invitations';
import { useWindowDimensions } from '../../../utils/dimensions';
import { terms } from '../../Partners/Terms';

// Images
import deere from '../../../images/deere_clear.png';
import climate from '../../../images/climate_clear.png';
import cnh from '../../../images/cnh_clear.png';

const useStyles = makeStyles((theme) => ({
  root: {
    color: theme.palette.text.primary,
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 1,
    fontFamily: 'OpenSans',
  },
  header: {
    fontSize: 38,
    fontWeight: 700,
    marginTop: 24,
    fontFamily: 'OpenSans',
  },
  subHeader: {
    fontSize: '1.6rem',
    fontWeight: 500,
    fontFamily: 'OpenSans',
    lineHeight: 1.25,
    marginTop: 32,
  },
  step: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-around',
  },
  buttonBox: {
    margin: 8,
    display: 'flex',
    flexDirection: 'column',
    height: 290,
    width: 360,
  },
  buttonBoxHeader: {
    minHeight: 88,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    color: 'white',
    fontSize: '2.4rem',
    fontWeight: 500,
    fontFamily: 'OpenSans',
  },
  buttonBoxBody: {
    padding: '0 16px',
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    alignItems: 'center',
    borderTop: 0,
    border: `2px solid ${grey}`,
    fontFamily: 'OpenSans',
  },
  buttonBoxText: {
    padding: 8,
    fontSize: '1.3rem',
    textAlign: 'center',
    fontFamily: 'OpenSans',
  },
  button: {
    margin: '24px 8px 8px',
    width: 220,
    textTransform: 'none',
    fontSize: '1.3rem',
    fontFamily: 'OpenSans',
    color: 'white',
  },
  smallButton: {
    textTransform: 'none',
    fontSize: '1.2rem',
    fontFamily: 'OpenSans',
    backgroundColor: darkGreen,
    color: 'white',
    height: '36px',
    marginLeft: 16,
  },
  integrationBox: {
    margin: '8px 8px 0 8px',
    borderRadius: 8,
    backgroundColor: lightGrey,
    border: `2px solid ${grey}`,
    boxShadow: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 220,
    height: 100,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: '#fafafa',
    },
  },
  preIntegrationBox: {
    margin: '8px 8px 0 8px',
    borderRadius: 8,
    backgroundColor: lightGrey,
    border: `2px solid ${grey}`,
    boxShadow: 16,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: 110,
    height: 50,
  },
  sideButton: {
    position: 'absolute',
    right: 0,
    top: 160,
    display: 'flex',
    alignItems: 'center',
    padding: '14px',
    width: 120,
    height: 80,
    border: `2px solid ${grey}`,
    backgroundColor: '#f2f2f2',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.greys.light,
    },
  },
  sideButtonMobile: {
    position: 'absolute',
    right: 0,
    top: 80,
    display: 'flex',
    alignItems: 'center',
    padding: '8px',
    margin: 8,
    border: `2px solid ${grey}`,
    backgroundColor: '#f2f2f2',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: theme.palette.greys.light,
    },
  },
  checkbox: {
    color: darkGreen,
    fontSize: 32,
  },
  closeDrawer: {
    marginTop: 28,
    marginLeft: 8,
    borderRadius: '50%',
    border: `1px solid ${darkGreen}`,
    backgroundColor: theme.palette.greys.light,
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: grey2,
    },
  },
  closeIcon: {
    height: '1.2rem',
    width: '1.2rem',
    color: darkGreen,
  },
  drawerTab: {
    marginRight: 8,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: lightGrey,
    '&:hover': {
      cursor: 'pointer',
    },
  },
  navButton: {
    textTransform: 'none',
    fontSize: '1rem',
    fontFamily: 'OpenSans',
  },
  actionText: {
    fontFamily: 'OpenSans-SemiBold',
    color: theme.palette.text.primary,
    textDecoration: 'underline',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  semiBold: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '1.1rem',
    color: theme.palette.text.primary,
  },
  fsaSteps: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '1.3rem',
    color: theme.palette.text.primary,
  },
  invites: {
    borderRadius: 4,
    border: `1px solid ${grey2}`,
    backgroundColor: theme.palette.greys.light,
    padding: '8px 16px',
  },
  textInput: {
    width: 440,
    backgroundColor: 'white',
  },
  input: {
    '&::placeholder': {
      fontFamily: 'OpenSans-SemiBold',
      color: theme.palette.text.primary,
      textAlign: 'center',
    },
  },
}));

/**
 * Rendered in AcreageReporting/AcreageHome.js
 * Walk both grower and agent through on boarding process. For a grower this will
 * include signing up/in, integrating precision data, an instructional video, and
 * ability to get and upload their CLUs. Agent flow is still mostly TBD, but will likely
 * at the least involve the Agent Portal currently rendered here.
 * @param {String} impersonating User whose data Agent is using
 * @param {Number} initialStep Initial step. Expected options: 0 (default), 99.5 (returning user)
 * @param {Bool} integrated Determine is user is starting here or resuming after integration
 * @param {Function} loadSharedUsers Get Growers who have shared data
 * @param {Array} organizations User organizations
 * @param {Number} pickFormat Step number for final page if user skips: 99.5
 * @param {Bool} resume Determine is user is starting here or resuming after signin
 * @param {Object} selectedCLUOrg Org to associate uploaded CLU data with ({id: -1, name: ''})
 * @param {Function} setImpersonating Set user who's data Agent is using
 * @param {Function} setReportType Expected options: 'CART', 'GART'
 * @param {Function} setSelectedCLUOrg Changes state of selectedCLUOrg
 * @param {Function} setStage Expected options: 'landing', 'onboarding', 'reporting'
 * @param {Function} setUseOrgCLUData Expects true or false
 * @param {Function} setUserType Sets type of user. Valid inputs 'agent' and 'grower'
 * @param {Array} sharedUsers Growers who have shared data with current user
 * @param {Boolean} useOrgCLUData CLU data to use. Either true or false
 * @param {String} userType Agent or grower
 * @returns {JSX} Landing Page
 */
export const Onboarding = ({
  impersonating,
  initialStep,
  integrated,
  loadSharedUsers,
  organizations,
  pickFormat,
  resume,
  selectedCLUOrg,
  setImpersonating,
  setReportType,
  setSelectedCLUOrg,
  setStage,
  setUseOrgCLUData,
  setUserType,
  sharedUsers,
  useOrgCLUData,
  userType,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const { width } = useWindowDimensions();
  const wrapBreakpoint = 800;
  const user = useContext(UserContext)[0];
  const primaryColor = userType === 'agent' ? darkBlue : darkGreen;

  // If user has come back after sign in, start on step 2
  const [step, setStep] = useState(resume ? integrated ? 3 : 2 : initialStep);

  // If user already has to CLUs or needs to get them
  const [hasData, setHasData] = useState(true);

  // Emails and links for Agent inviting Grower
  // Grower email address to send invitation to
  const [growerToInvite, setGrowerToInvite] = useState('');

  // Generated invite link
  const [inviteLink, setInviteLink] = useState('');
  const inviteRef = useRef(null);

  const [integrationStatusOpen, setIntegrationStatusOpen] = useState(integrated);
  const [agentPortalOpen, setAgentPortalOpen] = useState(false);
  const [infoVideoOpen, setInfoVideoOpen] = useState(false);

  // Terms and conditions for integration
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [termsAlert, setTermsAlert] = useState(false);

  // Upload CLUs
  const [showCLUFileUpload, setShowCLUFileUpload] = useState(false);
  const [clusUploaded, setClusUploaded] = useState(false);

  // Upload Precision Data
  const [openPrecisionUpload, setOpenPrecisionUpload] = useState(false);

  // Open help modal to download PDF guide
  const [openHelp, setOpenHelp] = useState(false);

  
  /// if user integrated from onboarding, this will autoclose the integration drawer after 6 seconds
  // useEffect(() => {
  //   if(integrated){
  //     console.log("Setting timeout for integration status")
  //     setTimeout(function(){setIntegrationStatusOpen(false)}, 6000)
  //   }
  // },[integrated])


  const handleCheck = (event) => {
    setTermsAlert(false);
    setTermsAccepted(event.target.checked);
  };

  const handleIntegration = (integrating) => {
    if (termsAccepted) {
      // TODO: Update endpoint to redirect back to acreage reporting
      integratePrecision(integrating, 'ACREAGEREPORTING', true);
    } else {
      setTermsAlert(true);
      enqueueSnackbar('Please accept the terms and conditions before integrating');
    }
  };

  const stepper = () => (
    <Box marginTop={width > 880 ? '32px' : 0} display="flex" justifyContent="center" flexGrow={1}>
      <Box display="flex">
        {[...Array(5).keys()].map((x) => (
          stepIndicator(x)
        ))}
      </Box>
    </Box>
  );

  const stepIndicator = (indicator) => (
    <Box
      key={indicator}
      m={1}
      borderRadius={4}
      height={16}
      width={width > 880 ? 80 : width > 460 ? 40 : 28}
      bgcolor={step > (indicator + 0.5) ? primaryColor : grey2}
      onClick={() => handleIndicatorClick(indicator)}
    />
  );

  const handleIndicatorClick = (indicator) => {
    if (indicator < step && step > 1) {
      window.scrollTo(0, 0);
      // Indicator starts at 0, step starts at 1
      setStep(indicator + 1);
    }
  };

  const handleStepChange = (newStep) => {
    window.scrollTo(0, 0);
    if (newStep === 1 && user.isAuthenticated) {
      // User if already logged in, so skip that step
      setStep(2);
    } else if (newStep === pickFormat+0.5) {
      setStage('reporting');
    } else {
      setStep(newStep);
    }
  };

  /**
   * Uses endpoint that will route back to AcreageReporting, using URL that will
   * route user back to next on boarding step. Uses storage to keep track of if user
   * is a grower or agent
   * @returns {void}
   */
  const handleSignIn = () => {
    localStorage.setItem('acreageReportingUserType', userType);
    signInFromAcreageOnboarding();
  };

  const goToFsa = () => {
    window.open('https://apps.fsa.usda.gov/usda_scc_gateway/er/');
  };

  const startReporting = () => {
    setStage('reporting');
  };

  const intro = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Typography className={classes.header}>
          New to PARS&trade;? Start here!
        </Typography>

        <Box mt="24px">
          <Typography className={classes.subHeader}>
            Get the best experience by telling us what you most need PARS&trade; for:
          </Typography>
        </Box>
      </Box>

      <Box
        mt="48px"
        display="flex"
        justifyContent={width > wrapBreakpoint ? "space-between" : "center"}
        flexWrap="wrap"
      >
        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: darkGreen }}>
            Grower
          </Box>
          <Box className={classes.buttonBoxBody}>
            <Typography className={classes.buttonBoxText}>
              You&apos;re here to report your acreage using precision data
            </Typography>

            <Button
              className={classes.button}
              style={{ background: darkGreen }}
              variant="contained"
              onClick={() => {
                setUserType('grower');
                handleStepChange(1);
              }}
            >
              That&apos;s me!
            </Button>
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: darkBlue }}>
            Agent
          </Box>
          <Box className={classes.buttonBoxBody}>
            <Typography className={classes.buttonBoxText}>
              You&apos;re here to create acreage reports for your growers
            </Typography>

            <Button
              className={classes.button}
              style={{ background: darkBlue }}
              variant="contained"
              onClick={() => {
                setUserType('agent');
                handleStepChange(1);
              }}
            >
              That&apos;s me!
            </Button>
          </Box>
        </Box>
      </Box>

      { skip() }

    </Box>
  );

  const agentOnBoarding = () => (
    <>
      { step === 1 && (
        <Fade
          in={step === 1}
          unmountOnExit
          mountOnEnter
        >
          {createAccount()}
        </Fade>
      )}

      { step === 2 && (
        <Fade
          in={step === 2}
          unmountOnExit
          mountOnEnter
        >
          {agentStepTwo()}
        </Fade>
      )}

      { step === 2.5 && hasData && (
        <Fade
          in={step === 2.5 && hasData}
          unmountOnExit
          mountOnEnter
        >
          {integrate()}
        </Fade>
      )}

      { (step === 2.5 && !hasData) && (
        <Fade
          in={step === 2.5 && !hasData}
          unmountOnExit
          mountOnEnter
        >
          {getGrowerData()}
        </Fade>
      )}

      { step === 3 && (
        <Fade
          in={step === 3}
          unmountOnExit
          mountOnEnter
        >
          {checkIfHaveClus()}
        </Fade>
      )}

      { step === 4 && (
        <Fade
          in={step === 4}
          unmountOnExit
          mountOnEnter
        >
          {cartOrGart()}
        </Fade>
      )}

      { step === 4.5 && (
        <Fade
          in={step === 4.5}
          unmountOnExit
          mountOnEnter
        >
          {getClus()}
        </Fade>
      )}

      { step === 5 && (
        <Fade
          in={step === 5}
          unmountOnExit
          mountOnEnter
        >
          {uploadClus()}
        </Fade>
      )}

      { step === pickFormat && (
        <Fade
          in={step === pickFormat}
          unmountOnExit
          mountOnEnter
        >
          {skipChooseCartOrGart()}
        </Fade>
      )}
    </>
  );

  const growerOnBoarding = () => (
    <>
      {step === 1 && (
        <Fade
          in={step === 1}
          unmountOnExit
          mountOnEnter
        >
          {createAccount()}
        </Fade>
      )}

      {step === 2 && (
        <Fade
          in={step === 2}
          unmountOnExit
          mountOnEnter
        >
          {integrate()}
        </Fade>
      )}

      {step === 3 && (
        <Fade
          in={step === 3}
          unmountOnExit
          mountOnEnter
        >
          {checkIfHaveClus()}
        </Fade>
      )}

      {step === 4 && (
        <Fade
          in={step === 4}
          unmountOnExit
          mountOnEnter
        >
          {cartOrGart()}
        </Fade>
      )}

      {step === 4.5 && (
        <Fade
          in={step === 4.5}
          unmountOnExit
          mountOnEnter
        >
          {getClus()}
        </Fade>
      )}

      {(step === 5) && (
        <Fade
          in={step === 5}
          unmountOnExit
          mountOnEnter
        >
          {uploadClus()}
        </Fade>
      )}
      
      { step === pickFormat && (
        <Fade
          in={step === pickFormat}
          unmountOnExit
          mountOnEnter
        >
          {skipChooseCartOrGart()}
        </Fade>
      )}
    </>
  );

  const createAccount = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Typography className={classes.header}>
          Let&apos;s create your account!
        </Typography>

        <Typography className={classes.subHeader}>
          {`Hi ${userType === 'grower' ? 'Grower' : 'Agent'}! `}
          Let&apos;s create your PARS&trade; account and learn a little about you!
        </Typography>
      </Box>

      <Box mt="52px" display="flex" flexGrow={1} justifyContent="center">
        <Box className={classes.buttonBox} style={{ height: 220 }}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: primaryColor }}>
            { userType === 'grower' ? 'Grower' : 'Agent' }
          </Box>
          <Box className={classes.buttonBoxBody}>
            <Button
              className={classes.button}
              style={{ background: primaryColor, width: 230 }}
              variant="contained"
              onClick={() => signUpFromAcreageOnboarding()}
            >
              Create My Account
            </Button>
          </Box>
        </Box>
      </Box>

      <Box mt="36px" display="flex" flexGrow={1} justifyContent="center">
        <Box display="flex" alignItems="center">
          <Typography className={classes.semiBold}>
            Already have an account?
          </Typography>
          <Button
            variant="contained"
            onClick={handleSignIn}
            style={{
              textTransform: 'none',
              fontSize: '1.2rem',
              fontFamily: 'OpenSans',
              backgroundColor: primaryColor,
              color: 'white',
              height: 40,
              marginLeft: 8,
            }}
          >
            Sign In
          </Button>
        </Box>
      </Box>

      <Box
        p={1}
        mt="62px"
        display="flex"
        flexDirection="column"
        border="1px solid black"
        borderRadius="16px"
      >
        <Box style={{
          fontFamily: 'OpenSans-SemiBold',
          fontSize: '0.9rem',
          color: blackText,
          textAlign: 'center',
        }}>
          After creating your account, we'll integrate your{userType !== 'grower' && ' grower\'s'} precision data so PARS&trade; can generate {userType === 'grower' ? 'your' : 'their'} acreage report.
        </Box>

        <Box display="flex" flexWrap="wrap" justifyContent="center">
          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box className={classes.preIntegrationBox}>
              <img src={climate} alt="Climate Field View Integration" style={{ width: 90, height: 'auto' }} />
            </Box>

            <Box className={classes.preIntegrationBox}>
              <img src={deere} alt="John Deere Integration" style={{ width: 90, height: 'auto' }} />
            </Box>
          </Box>

          <Box display="flex" flexWrap="wrap" justifyContent="center">
            <Box className={classes.preIntegrationBox}>
              <img src={cnh} alt="CNH Integration" style={{ width: 90, height: 'auto' }} />
            </Box>

            <Box className={classes.preIntegrationBox}>
              <Typography align="center" style={{ fontSize: 16, fontFamily: 'OpenSans-SemiBold' }}>
                Flash drive
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>

      { skip() }
    </Box>
  );

  const integrate = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Typography className={classes.header}>
          {userType === 'grower' ? (
            'Next, Integrate Your Data'
          ) : (
            "Let's integrate your grower's data!"
          )}
        </Typography>

        <Typography className={classes.subHeader}>
          {userType === 'grower' ? (
            `Nice to meet you ${user.email}! Please read our Terms of Use, then select your precision data platform below to import your data!`
          ) : (
            "Great! Please read our Terms of Use, then select your precision data platform below to import your grower's data!"
          )}
        </Typography>
      </Box>

      <Box mt="32px">
        <Box display="flex" justifyContent="center" flexWrap="wrap">
          <Box display="flex" flexWrap="wrap">
            <Box mr={1}>
              {number(1, 'large')}
            </Box>

            <Box
              width={320}
              border={2}
              borderRadius={8}
              borderColor={termsAlert ? red : grey}
              bgcolor={lightGrey}
            >
              <Box m={1} bgcolor="white" height={220} border={1} style={{ overflowY: 'auto' }}>
                {terms()}
              </Box>

              <Box px={1} pt={1} display="flex">
                <Checkbox
                  checked={termsAccepted}
                  icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
                  checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
                  onChange={handleCheck}
                />
                <Box width={220}>
                  <Typography className={classes.semiBold} style={{ fontSize: '.9rem' }}>
                    I have read and agree to the Terms of Use & Privacy Policy
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>

          <Box ml={width > wrapBreakpoint ? "24px" : ""} display="flex" flexWrap="wrap">
            <Box pt={1}>
              {number(2, 'large')}
            </Box>
            <Box
              mb={1}
              display="flex"
              flexDirection="column"
              justifyContent="space-between"
            >
              <Box display="flex" flexWrap="wrap" justifyContent="center">
                <Box
                  className={classes.integrationBox}
                  onClick={() => handleIntegration('climate')}
                >
                  <img src={climate} alt="Climate Field View Integration" style={{ width: 180, height: 'auto' }} />
                </Box>

                <Box
                  className={classes.integrationBox}
                  onClick={() => handleIntegration('deere')}
                >
                  <img src={deere} alt="John Deere Integration" style={{ width: 180, height: 'auto' }} />
                </Box>
              </Box>

              <Box display="flex" flexWrap="wrap" justifyContent="center">
                <Box 
                  className={classes.integrationBox}
                  onClick={() => handleIntegration('cnh')}
                >
                  <img src={cnh} alt="CNH Integration" style={{ width: 180, height: 'auto' }} />
                </Box>

                <Box
                  className={classes.integrationBox}
                  onClick={() => setOpenPrecisionUpload(true)}
                >
                  <Typography align="center" style={{ fontSize: 20, fontFamily: 'OpenSans-SemiBold' }}>
                    My data is on my flash drive
                  </Typography>
                </Box>
              </Box>

              <Box p={1} display="flex" justifyContent="center">
                <Typography className={classes.actionText} onClick={() => handleStepChange(3)}>
                  Already integrated? Skip this step
                </Typography>
              </Box>
            </Box>
          </Box>

        </Box>
      </Box>

      { skip() }
    </Box>
  );

  const number = (n, size) => (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      height={size === 'large' ? 44 : 32}
      width={size === 'large' ? 44 : 32}
      borderRadius="50%"
      border={1}
      borderColor={primaryColor}
      color={primaryColor}
      fontSize={size === 'large' ? 22 : 16}
    >
      {n}
    </Box>
  );

  // When user clicks the skip link, decides whether to skip the tutorial or bring the user to a page where they can choosen CART or GART
  const chooseAppropriateAction = () => {
    if (step < 4) setStep(pickFormat);
    else setStage('reporting');
  }

  const skip = () => (
    <Box my="32px" display="flex" justifyContent="center">
      <Typography className={classes.actionText} onClick={() => chooseAppropriateAction()}>
        Returning User? Skip this tutorial
      </Typography>
    </Box>
  );

  const openIntegrationStatus = () => (
    <Box
      className={width > wrapBreakpoint ? classes.sideButton : classes.sideButtonMobile}
      onClick={() => setIntegrationStatusOpen(true)}
    >
      <Typography align="center">
        Check My Precision Data Status
      </Typography>
    </Box>
  );

  const goToAgentPortalButton = () => (
    <Box className={classes.sideButton} style={{ top: 280 }} onClick={() => setAgentPortalOpen(true)}>
      <Typography>
        Open Agent Portal
      </Typography>
    </Box>
  );

  const goToAgentPortalMobile = () => (
    !hasData && (
      <Box mt="16px" display="flex" justifyContent="center">
        <Typography className={classes.actionText} onClick={() => setAgentPortalOpen(true)}>
          Open Agent Portal
        </Typography>
      </Box>
    )
  );

  const integrationStatus = () => (
    <React.Fragment>
      <Drawer anchor="right" open={integrationStatusOpen} onClose={() => setIntegrationStatusOpen(false)}>
        <Box display="flex" flexGrow={1} width={width < 480 ? width - 20 : 480}>
          <Box style={{ height: '100%' }}>
            <Box className={classes.closeDrawer} onClick={() => setIntegrationStatusOpen(false)}>
              <CloseIcon className={classes.closeIcon} />
            </Box>
          </Box>
          <Box pl={1}>
            <IntegrationStatus/>
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  );

  const agentPortal = () => (
    <React.Fragment>
      <Drawer anchor="right" open={!!agentPortalOpen} onClose={() => setAgentPortalOpen(false)}>
        <Box display="flex" flexGrow={1}>
          <Box style={{ height: '100%' }}>
            <Box className={classes.closeDrawer} onClick={() => setAgentPortalOpen(false)}>
              <CloseIcon className={classes.closeIcon} />
            </Box>
          </Box>
          <Box pl={1} mt={3} width={width < 480 ? width - 20 : 480}>
            <AgentPortal 
              currentUser={user.email}
              impersonating={impersonating}
              loadSharedUsers={loadSharedUsers}
              open={agentPortalOpen}
              setImpersonating={setImpersonating}
              setOpen={setAgentPortalOpen}
              sharedUsers={sharedUsers}
            />
          </Box>
        </Box>
      </Drawer>
    </React.Fragment>
  )

  const cartOrGart = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Typography className={classes.header}>
          No problem! Would You Like to Use CLUs?
        </Typography>

        <Box maxWidth={840}>
          <Typography className={classes.subHeader}>
            You can choose to download your{userType === 'grower' ? '' : ' grower\'s'} CLUs from the FSA Farm+ portal,
            or create an acreage report that doesn&apos;t need {userType === 'grower' ? 'your' : 'their'} CLUs.
          </Typography>
        </Box>
      </Box>

      <Box mt="52px" display="flex" justifyContent={width > wrapBreakpoint ? "space-between" : "center"} flexWrap="wrap">
        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: primaryColor }}>
            Yes
          </Box>
          <Box className={classes.buttonBoxBody} style={{ padding: 0 }}>
            <Typography className={classes.buttonBoxText}>
              { userType === 'grower' ? (
                'I would like to download my CLU boundaries from FSA Farm+'
              ) : (
                "I would like to download my grower's CLUs from FSA Farm+"
              )}
            </Typography>

            <Button
              className={classes.button}
              style={{ background: primaryColor }}
              variant="contained"
              onClick={() => {
                setReportType('CART')
                handleStepChange(4.5)
              }}
            >
              Use CLUs
            </Button>
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: pewter }}>
            No
          </Box>
          <Box className={classes.buttonBoxBody} style={{ padding: 0 }}>
            <Typography className={classes.buttonBoxText}>
              I want to create a simplified report that doesn't need CLUs
            </Typography>

            <Button
              className={classes.button}
              style={{ background: pewter }}
              variant="contained"
              onClick={() => {
                setReportType('GART')
                setStage('reporting')
              }}
            >
              Don&apos;t Use CLUs
            </Button>
          </Box>
        </Box>
      </Box>

      { width < wrapBreakpoint && userType === 'agent' && goToAgentPortalMobile() }
    </Box>
  );

  const includeCLULink = () => (
    <a 
      href='https://www.fsa.usda.gov/programs-and-services/aerial-photography/imagery-products/common-land-unit-clu/index'
      target='_blank'
      rel="noreferrer" // Used to avoid phishing vulnerabilities
    >FSA CLU boundaries</a>
  )

  // Step when user skips before choosing CART or GART path
  const skipChooseCartOrGart = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Typography className={classes.header}>
          Would You Like to Use CLUs?
        </Typography>

        <Typography className={classes.subHeader} style={{ fontSize: '1.5rem' }}>
          You can create your {userType === 'grower' ? 'digital' : 'grower\'s'} acreage report with or without {includeCLULink()}.
        </Typography>
      </Box>

      <Box mt="52px" display="flex" justifyContent="space-between" flexWrap="wrap">
        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: primaryColor }}>
            Yes
          </Box>
          <Box className={classes.buttonBoxBody} style={{ padding: 0 }}>
            <Typography className={classes.buttonBoxText}>
              I want to create and submit a fully CLU-based acreage report
            </Typography>

            <Button
              className={classes.button}
              style={{ background: primaryColor }}
              variant="contained"
              onClick={() => {
                setReportType('CART')
                setStage('reporting')
              }}
            >
              Use CLUs
            </Button>
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: pewter }}>
            No
          </Box>
          <Box className={classes.buttonBoxBody} style={{ padding: 0 }}>
            <Typography className={classes.buttonBoxText}>
              I want to create a simplified report that doesn&apos;t need CLUs
            </Typography>

            <Button
              className={classes.button}
              style={{ background: pewter }}
              variant="contained"
              onClick={() => {
                setReportType('GART')
                setStage('reporting')
              }}
            >
              Don&apos;t Use CLUs
            </Button>
          </Box>
        </Box>
      </Box>
    </Box>
  );

  /* ------------ Grower Steps ------------*/
  const checkIfHaveClus = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Typography className={classes.header}>
          Next, do you have your{userType === 'grower' ? '' : ' grower\'s'} CLUs?
        </Typography>

        <Typography className={classes.subHeader}>
          If you have {userType === 'grower' ? 'your' : 'their'}
          {' '}
          {includeCLULink()}
          {' '}
          digitally, you can use them in your acreage report.
        </Typography>
      </Box>

      <Box mt="52px" display="flex" flexWrap="wrap" justifyContent={width > wrapBreakpoint ? "space-between" : "center"}>
        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: primaryColor }}>
            Yes
          </Box>
          <Box className={classes.buttonBoxBody}>
            <Typography className={classes.buttonBoxText}>
              I have digital copies of my{userType === 'grower' ? '' : 'grower\'s'} CLU boundaries on my computer
            </Typography>

            <Button
              className={classes.button}
              style={{ background: primaryColor }}
              variant="contained"
              onClick={() => {
                handleStepChange(5);
              }}
            >
              I Have {userType === 'grower' ? 'My' : 'Their'} CLUs
            </Button>
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: pewter }}>
            No
          </Box>
          <Box className={classes.buttonBoxBody} style={{ padding: 0 }}>
            <Typography className={classes.buttonBoxText}>
              I don&apos;t have digital copies of my{userType === 'grower' ? '' : 'grower\'s'} CLU boundaries
            </Typography>

            <Button
              className={classes.button}
              style={{ background: pewter }}
              variant="contained"
              onClick={() => {
                handleStepChange(4);
              }}
            >
              I Don&apos;t Have CLUs
            </Button>
          </Box>
        </Box>
      </Box>

      { skip() }

      { width < wrapBreakpoint && userType === 'agent' && goToAgentPortalMobile() }
    </Box>
  );

  const uploadClus = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Typography className={classes.header}>
          {userType === 'grower' ? (
            "Fantastic! Let's upload your CLUs"
          ) : (
            "Fantastic, Let's upload their CLUs"
          )}
        </Typography>

        <Typography className={classes.subHeader}>
          Upload your&nbsp;
          {userType === 'agent' ? "grower's " : ''}
          CLU boundaries, and PARS&trade; will automatically
          process them against your precision&nbsp;
          {userType === 'grower' ? 'operations.' : 'data.'}
        </Typography>
      </Box>

      <Box mt="32px" display="flex" justifyContent="center">
        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: primaryColor }}>
            {userType === 'grower' ? 'Upload My CLUs' : 'Upload CLUs'}
          </Box>
          <Box className={classes.buttonBoxBody} style={{ padding: '0 28px' }}>
            <Typography className={classes.buttonBoxText}>
              {userType === 'grower' ? (
                'Upload my CLUs boundaries to my account'
              ) : (
                "Upload my grower's CLU boundaries to my account"
              )}
            </Typography>

            <Button
              className={classes.button}
              style={{ background: primaryColor }}
              variant="contained"
              onClick={() => setShowCLUFileUpload(true)}
            >
              Let&apos;s go!
            </Button>
          </Box>
        </Box>
      </Box>

      { skip() }

      { width < wrapBreakpoint && userType === 'agent' && goToAgentPortalMobile() }
    </Box>
  );

  const getClus = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Box mt="24px" display="flex" alignItems="center">
          <Typography className={classes.header} style={{ marginTop: 0 }}>
            {`Let's download ${userType === 'grower' ? 'your' : 'their'} CLUs from FSA Farm+`}
          </Typography>

          {/* Icon to download pdf guide */}
          <Box ml="12px">
            <IconButton style={{ color: primaryColor }} onClick={() => setOpenHelp(true)}>
              <HelpIcon />
            </IconButton>
          </Box>
        </Box>
      </Box>

      <Box mt={1} display="flex" justifyContent="center">
        <Box>
          <Box my={3} display="flex" alignItems="center">
            {number(1)}
            <Box pl={2} className={classes.fsaSteps}>
              First, create your free FSA Farm+ account linked to your email address
            </Box>
          </Box>
          <Box my={3} display="flex" alignItems="center">
            {number(2)}
            <Box pl={2} className={classes.fsaSteps}>
              Verify your identity with the USDA online questionnaire
            </Box>
          </Box>
          <Box my={3} display="flex" alignItems="center">
            {number(3)}
            <Box pl={2} className={classes.fsaSteps}>
              Search for your farm using Farm Number or SSN
            </Box>
          </Box>
          <Box mt={3} display="flex" alignItems="center">
            {number(4)}
            <Box pl={2} className={classes.fsaSteps}>
              Download and save your CLU shapefile as a GeoJSON
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={3} display="flex" justifyContent="center">
        <Button
          className={classes.smallButton}
          variant="contained"
          style={{ background: primaryColor, marginLeft: 0, width: 200 }}
          onClick={goToFsa}
        >
          Let&apos;s Go!
        </Button>
      </Box>

      <Box mt={3} display="flex" justifyContent="center">
        <Box
          p={2}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius={4}
          border={2}
          borderColor={grey2}
          bgcolor={lightGrey}
        >
          <Box fontSize={20} fontWeight={600} fontFamily="OpenSans">
            Finished Downloading Your CLUs?
          </Box>
          <Button
            className={classes.smallButton}
            variant="contained"
            style={{ background: primaryColor, width: 200 }}
            onClick={() => setStep(5)}
          >
            Upload My CLUs
          </Button>
        </Box>
      </Box>

      { skip() }

      { width < wrapBreakpoint && userType === 'agent' && goToAgentPortalMobile()}
    </Box>
  );

  /* ------------ Agent Steps ------------*/
  const agentStepTwo = () => (
    <Box mt="24px">
      <Box pl={2}>
        <Typography className={classes.header}>
          Next, Let&apos;s Talk About Data
        </Typography>

        <Typography className={classes.subHeader}>
          {`Nice to meet you ${user.email}! Now, do you have access to your grower's precision data?`}
        </Typography>
      </Box>

      <Box mt="52px" display="flex" justifyContent={width > wrapBreakpoint ? "space-between" : "center"} flexWrap="wrap">
        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: primaryColor }}>
            Yes
          </Box>
          <Box className={classes.buttonBoxBody}>
            <Typography className={classes.buttonBoxText}>
              I have access, either on a cloud platform or my computer
            </Typography>

            <Button
              className={classes.button}
              style={{ background: primaryColor }}
              variant="contained"
              onClick={() => {
                handleStepChange(step + 0.5);
                setHasData(true);
              }}
            >
              That&apos;s me!
            </Button>
          </Box>
        </Box>

        <Box className={classes.buttonBox}>
          <Box className={classes.buttonBoxHeader} style={{ backgroundColor: pewter }}>
            No
          </Box>
          <Box className={classes.buttonBoxBody} style={{ padding: 0 }}>
            <Typography className={classes.buttonBoxText}>
              I need to ask my growers to share their precision data with me
            </Typography>

            <Button
              className={classes.button}
              style={{ background: pewter }}
              variant="contained"
              onClick={() => {
                handleStepChange(step + 0.5);
                setHasData(false);
              }}
            >
              That&apos;s me!
            </Button>
          </Box>
        </Box>
      </Box>

      { skip() }
    </Box>
  );

  const getGrowerData = () => (
    <Box mt="24px" display="flex" flexGrow={1} flexDirection="column" justifyContent="center">
      <Box pl={2}>
        <Typography className={classes.header}>
          Let&apos;s Gather your Grower&apos;s Data
        </Typography>

        <Typography className={classes.subHeader}>
          No problem! Now let&apos;s reach out to your grower to gather their data.
          You can send them a direct email or generate a shareable link.
        </Typography>
      </Box>

      <Box mt={4} display="flex" justifyContent="center">
        <Box className={classes.invites}>
          <Typography className={classes.semiBold}>
            Invite your grower to securely share their data by sending them an email.
          </Typography>
          <Box mt={1} display="flex" alignItems="center">
            <TextField
              className={classes.textInput}
              size="small"
              variant="outlined"
              placeholder="Enter email address"
              value={growerToInvite}
              onChange={(e) => setGrowerToInvite(e.target.value)}
              InputProps={{ classes: { input: classes.input } }}
            />
            <Box m={1}>
              <Button
                className={classes.smallButton}
                style={{ background: darkBlue }}
                variant="contained"
                onClick={async () => enqueueSnackbar( await sendInviteEmail(growerToInvite))}
              >
                Invite My Grower
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt={2} display="flex" justifyContent="center">
        <Box className={classes.invites}>
          <Typography className={classes.semiBold}>
            Create a link to give to your grower so they can securely share their data.
          </Typography>
          <Box mt={1} display="flex" alignItems="center">
            <TextField
              className={classes.textInput}
              inputRef={inviteRef}
              size="small"
              variant="outlined"
              placeholder="Get your invite link &#8594;"
              value={inviteLink}
              onChange={(e) => setGrowerToInvite(e.target.value)}
              InputProps={{
                classes: { input: classes.input },
                startAdornment: inviteLink ? linkIcon : '',
              }}
              disabled={!inviteLink}
            />
            <Box m={1}>
              <Button
                className={classes.smallButton}
                style={{ background: darkBlue }}
                variant="contained"
                onClick={() => generateInviteLink(user.email, setInviteLink)}
              >
                Generate My Link
              </Button>
            </Box>
          </Box>
        </Box>
      </Box>

      <Box mt="32px" display="flex" flexGrow={1} justifyContent="center">
        <Box display="flex" alignItems="center">
          <Typography className={classes.semiBold}>
            Ready to continue?
          </Typography>
          <Button
            variant="contained"
            onClick={() => handleStepChange(3)}
            style={{
              textTransform: 'none',
              fontSize: '1.2rem',
              fontFamily: 'OpenSans',
              backgroundColor: primaryColor,
              color: 'white',
              height: 40,
              marginLeft: 8,
            }}
          >
            Continue to CLUs
          </Button>
        </Box>
      </Box>

      { skip() }
    </Box>
  );

  const linkIcon = (
    <Tooltip placement="left" title="Copy Link To Clipboard">
      <LinkIcon onClick={copyToClipboard} style={{ cursor: 'pointer', color: darkBlue, marginRight: 8 }} />
    </Tooltip>
  );

  return (
    <Box display="flex" flexGrow={1}>
      <Container maxWidth="md" className={classes.root}>
        <Box display="flex" flexDirection="column" flexGrow={1}>
          {(step > 0 && step !== pickFormat) && stepper()}

          {step === 0 && intro()}

          {(step > 0 && userType === 'agent') && agentOnBoarding()}

          {(step > 0 && userType === 'grower') && growerOnBoarding()}

          {showCLUFileUpload && (
            <UploadClus
              clusUploaded={clusUploaded}
              impersonating={impersonating}
              open={showCLUFileUpload}
              organizations={organizations}
              primaryColor={primaryColor}
              selectedCLUOrg={selectedCLUOrg}
              setAgentPortalOpen={setAgentPortalOpen}
              setClusUploaded={setClusUploaded}
              setOpen={setShowCLUFileUpload}
              setSelectedCLUOrg={setSelectedCLUOrg}
              startReporting={startReporting}
              setUseOrgCLUData={setUseOrgCLUData}
              useOrgCLUData={useOrgCLUData}
            />
          )}

          { infoVideoOpen && (
            <IntroModal
              open={infoVideoOpen}
              setOpen={setInfoVideoOpen}
            />
          )}

          { openHelp && (
            <Help
              open={openHelp}
              setOpen={setOpenHelp}
              primaryColor={primaryColor}
            />
          )}

          { openPrecisionUpload && (
            <UploadAgFile
              uploadFile={openPrecisionUpload}
              setUploadFile={setOpenPrecisionUpload}
            />
          )}

        </Box>
      </Container>

      { (step >= 3 && step !== pickFormat && hasData) && openIntegrationStatus() }

      { (userType === 'agent' && step >= 3 && !hasData && width >= wrapBreakpoint) && goToAgentPortalButton() }

      { integrationStatus() }
      { agentPortal() }
    </Box>
  );
};

Onboarding.propTypes = {
  impersonating: PropTypes.string,
  loadSharedUsers: PropTypes.func.isRequired,
  resume: PropTypes.bool.isRequired,
  setImpersonating: PropTypes.func.isRequired,
  setStage: PropTypes.func.isRequired,
  setUseOrgCLUData: PropTypes.func.isRequired,
  setUserType: PropTypes.func.isRequired,
  sharedUsers: PropTypes.arrayOf(
    PropTypes.shape(
      {
        accepted: PropTypes.number.isRequired,
        owner_email_address: PropTypes.string.isRequired,
      },
    ),
  ),
  useOrgCLUData: PropTypes.bool.isRequired,
  userType: PropTypes.string.isRequired,
};

Onboarding.defaultTypes = {
  impersonating: null,
  sharedUsers: null,
};

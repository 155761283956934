import React, { useState, useRef, useEffect } from "react";
import PropTypes from "prop-types";
import {
  TableBody,
  TableContainer,
  Table,
  makeStyles,
} from "@material-ui/core";

import { ascending, groups, min, max, sum, extent } from "d3-array";
import { scaleQuantize } from "d3-scale";
import {
  COLOR_RANGE,
  COLORS,
  SORT,
  SORT_LOOKUP,
  NEGATIVE_COLOR_RANGE,
  POSITIVE_COLOR_RANGE
} from "../../../../../../constants";
import Header from "../../../../../Helpers/Table/Header";
import Row from "./VarietyAcresRow";
import { format } from "d3-format";
import { kebabCase } from "lodash";
import Summary from "./VarietyAcresSummary"

const useStyles = makeStyles(() => ({
  container: {
    width: "100%",
    overflow: "auto",
    minWidth: "1000px",
    maxWidth: "1000px"
  },
  table: {
    // minWidth: "100%",
    color: COLORS.text,
  },
  tableBody: {
    borderColor: COLORS.green,
    borderTop: "2px solid",
    borderBottom: "2px solid",
    "& .MuiTableRow-root": {
      height: 36,
      "&.active": {
        backgroundColor: COLORS.veryLightGrey,
        borderLeft: `3px solid ${COLORS.green}`,
      },
    },
  },
}));

/**
 * Renders the profit by vairety matrix and the handles the sort
 * @param {object} values object with 'profitByVariety' as one of the key which is a key-value object, where key is variety name and values is profit of that variety among all fields, missingFields is an array for which profit map is not yet generated
 */

const VarietyAcresMatrixTable = ({
  values,
  varieties,
  setVarieties,
  fields,
  varietyAcresData,
  setVarietyAcresData,
  profitAccessor = (d) => d.profit,
  acresAccessor = (d) => d.varietyAcres,
  minMaxProfit,
  showHeatmap,
  showVarietyAcreage
}) => {
  //console.log("values", values)
  const classes = useStyles();
  const data = varietyAcresData;

  const headerCells = [
    {
      id: "variety",
      heading: null,
      accessor: (d) => d[0],
    },
  ].concat(
    Array.from(new Set(data.map((d) => d.varietyName)))
      .sort(ascending)
      .map((d) => ({
        id: data.filter(x => x.varietyName == d)[0].variety,
        heading: d,
        accessor: (f) => {
          const match = f[1].find((g) => g.varietyName === d);
          return match ? showVarietyAcreage ? acresAccessor(match) : profitAccessor(match) : null;
        },
      }))
  );

  //console.log("headerCells", headerCells)
  
  const [state, setState] = useState({
    order: SORT.Asc,
    orderBy: headerCells[0].id,
  });
  const tableRef = useRef(null);

  const handleRequestSort = (event, property) => {
    const isAsc = state.orderBy === property && state.order === SORT.Asc;
    const isSameIndicator = property === state.orderBy;
    setState({
      orderBy: property,
      order: isSameIndicator ? (isAsc ? SORT.Desc : SORT.Asc) : SORT.Asc,
    });
  };

  // Handle the sort, by the selected header
  const selectedHeader = headerCells.find((d) => d.id === state.orderBy);

  //console.log("data", data)
  let rows = groups(data, (d) => d.fieldName);
  rows = rows.sort((a, b) => {
    const sortFn = SORT_LOOKUP.get(state.order);
    return sortFn(selectedHeader.accessor(a), selectedHeader.accessor(b));
  });
  // console.log("rows variety", rows)

  const profitScale = scaleQuantize()
  .range(COLOR_RANGE.map((d) => d.backgroundColor))
  .domain([minMaxProfit[0], minMaxProfit[1]]);

  const negativeScale = scaleQuantize()
  .range(NEGATIVE_COLOR_RANGE.map((d) => d.backgroundColor))
  .domain([minMaxProfit[0], 0]);

  const positiveScale = scaleQuantize()
  .range(POSITIVE_COLOR_RANGE.map((d) => d.backgroundColor))
  .domain([0, minMaxProfit[1]]);

  return (
    <TableContainer id="heat-map-table" className={classes.container}>
      <Table className={classes.table} ref={tableRef}>
        <Header
          order={state.order}
          orderBy={state.orderBy}
          onRequestSort={handleRequestSort}
          headerCells={headerCells}
        />
        <TableBody className={classes.tableBody}>
          {rows.map((row, i) => (
            <Row
              key={`heat-map-table-row-for-${i}`}
              row={row}
              headerCells={headerCells}
              showHeatmap={showHeatmap}
              profitScale={profitScale}
              negativeScale={negativeScale}
              positiveScale={positiveScale}
              showVarietyAcreage={showVarietyAcreage}
            />
          ))}
        </TableBody>
        <Summary
          headerCells={headerCells}
          values={values}
          showVarietyAcreage={showVarietyAcreage}
        />
      </Table>
    </TableContainer>
  );
};

export default VarietyAcresMatrixTable;

VarietyAcresMatrixTable.propTypes = {
  values: PropTypes.object.isRequired,
};

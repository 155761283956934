import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Modal,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';
import { AgentPortal } from './AgentPortal';
import { useWindowDimensions } from '../../../utils/dimensions';

const useStyles = makeStyles((theme) => ({
  paper: {
    position: 'absolute',
    backgroundColor: '#ffffff',
    display: 'flex',
    flexDirection: 'column',
    fontWeight: 500,
    fontSize: 16,
    top: `${50}%`,
    left: `${50}%`,
    transform: `translate(-${50}%, -${50}%)`,
    padding: 1,
    borderRadius: 4,
  },
  head: theme.updateOperationHeader,
  select: {
    margin: 4,
    width: 200,
    backgroundColor: '#ffffff',
  },
  close: {
    position: 'absolute',
    top: 6,
    right: 6,
  },
}));

/**
 * Rendered in AcreageReporting/AcreageReporting.js
 * Renders AgentPortal in modal. Pass down shared users, ability to load,
 * impersonated user, and ability to set impersonated user along with current
 * user email.
 * @param {String} currentUser Current user's email
 * @param {String} impersonating User whose data Agent is using
 * @param {Function} loadSharedUsers Get Growers who have shared data
 * @param {Bool} open Modal open
 * @param {Function} setImpersonating Set user who's data Agent is using
 * @param {Function} setOpen Determine modal open state
 * @param {Array} sharedUsers Growers who have shared data with current user
 * @returns {JSX} Modal
 */
export const AgentPortalModal = ({
  currentUser,
  impersonating,
  loadSharedUsers,
  open,
  setImpersonating,
  setOpen,
  sharedUsers,
}) => {
  const classes = useStyles();
  const { height, width } = useWindowDimensions();

  return (
    <Modal
      open={!!open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="agent-portal"
      aria-describedby="agent-portal"
    >
      <Box
        className={classes.paper}
        boxShadow={2}
        width={width >= 960 ? 920 : width - 40}
        maxHeight={height - 40}
      >
        <Box>
          <IconButton className={classes.close} color="primary" onClick={() => { setOpen(false); }}>
            <HighlightOffIcon />
          </IconButton>
        </Box>

        <AgentPortal
          currentUser={currentUser}
          impersonating={impersonating}
          inModal
          loadSharedUsers={loadSharedUsers}
          open={open}
          setImpersonating={setImpersonating}
          setOpen={setOpen}
          sharedUsers={sharedUsers}
        />
      </Box>
    </Modal>
  );
};

AgentPortalModal.propTypes = {
  currentUser: PropTypes.string,
  impersonating: PropTypes.string,
  loadSharedUsers: PropTypes.func.isRequired,
  open: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.string,
  ]).isRequired,
  setImpersonating: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  sharedUsers: PropTypes.arrayOf(
    PropTypes.shape(
      {
        accepted: PropTypes.number.isRequired,
        owner_email_address: PropTypes.string.isRequired,
      },
    ),
  ),
};

AgentPortalModal.defaultProps = {
  currentUser: undefined,
  impersonating: null,
  sharedUsers: null,
};

import React, {
  useState, useContext, useEffect,
} from 'react';
import ReactGA from 'react-ga';
import PropTypes from 'prop-types';

import { makeStyles } from '@material-ui/core/styles';
import {
  AppBar, IconButton, Menu, MenuItem, Box, Link, Button,
} from '@material-ui/core/';
import MenuIcon from '@material-ui/icons/Menu';
import HelpIcon from '@material-ui/icons/Help';
import logo from '../../images/ProfitLayers_Full_Gradient.png';
import { grey } from '../../styles/colors';

import { Endpoints } from '../../constants/Endpoints';
import { UserContext } from '../Context/UserContext';
import { useWindowDimensions } from '../../utils/dimensions';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  nav: {
    backgroundColor: theme.palette.greys.light,
    height: '56px',
    fontSize: 14,
    fontWeight: 500,
    color: theme.palette.text.black,
    flexGrow: 1,
  },
  menuButton: {
    margin: '0 4px 0 1px',
    paddingBottom: '16px',
    fontSize: '20px',
    color: theme.palette.text.black,
  },
  helpIcon: {
    fontSize: 24,
    paddingBottom: 5,
    marginRight: 5,
    color: theme.palette.text.black,
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.text.grey,
    },
  },
  selection: {
    color: theme.palette.text.black,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'inset 0px 0px 5px #555555',
      borderRadius: 5,
    },
  },
  selected: {
    color: theme.palette.text.black,
    '&:hover': {
      cursor: 'pointer',
      boxShadow: 'inset 0px 0px 5px #555555',
    },
    boxShadow: 'inset 0px 0px 5px #c1c1c1',
    borderRadius: 5,
  },
  demo: {
    textDecoration: 'none',
    '&:hover': {
      cursor: 'pointer',
      color: theme.palette.primary.dark,
    },
  },
  loggedIcon: {
    fontSize: '14px',
    paddingBottom: '2px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  arrowIcon: {
    fontSize: '18px',
    padding: '0 0 2px 2px',
    '&:hover': {
      cursor: 'pointer',
    },
  },
  dropdownItem: {
    fontWeight: 500,
    width: '100%',
    color: theme.palette.text.black,
    '&:hover': {
      cursor: 'pointer',
      textDecoration: 'none',
      color: theme.palette.primary.dark,
    },
  },
  menuItem: {
    fontWeight: 500,
  },
  fullLogo: {
    width: 'auto',
    height: '48px',
    marginLeft: '20px',
  },
  mediumLogo: {
    width: '160px',
    height: 'auto',
    marginLeft: '8px',
  },
  smallLogo: {
    width: '90px',
    height: 'auto',
    marginLeft: '4px',
  },
}));

/**
 * Direct links to ProfitLayers, DataLayers, FieldManager, Services, Pricing,
 * and user email or sign in button vary by screen size. Additional option
 * in hamburger menu on right side of screen
 * @param {Function} openInviteModal opens modal for inviting a grower
 * @param {Boolean} pageNotFound determines whether URL matches a valid route
 * @param {Number} section determines displayed component in Home
 * @param {Function} setSection sets section for display in Home
 * @param {Function} shouldShowNavComponents helps decide how to proceed when user clicks a navbar button
 * @returns {JSX} Navbar
 */
export function Navbar({
  openInviteModal,
  pageNotFound,
  section,
  setSection,
  shouldShowNavComponents,
}) {
  const user = useContext(UserContext)[0];
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [auth, setAuth] = useState(false);
  const [menuOpen, setMenuOpen] = useState(null);

  const breakOne = 1079;
  const breakTwo = 886;
  const breakThree = 600;
  const startHiding = 560;

  const sectionMapping = {
    profitlayers: 0,
    datalayers: 1,
    fieldmanager: 2,
    services: 3,
    purchase: 4,
  };

  useEffect(() => {
    setAuth(user.isAuthenticated);
  }, [user]);

  const openInvite = () => {
    setMenuOpen(null);
    openInviteModal(true);
  };

  const handleNavClick = (sectionName) => {
    // Only cause the browser to not request the page when we are navigating between navbar sections to (1. avoid showing multiple sections at once (without exact) or 2. actually load those components (with exact))
    // pageNotFound boolean determines whether we are on the not found page
    if (!pageNotFound && shouldShowNavComponents()) {
      window.history.replaceState({}, document.title, `${Endpoints.HOME}/app/${sectionName}`);
      setSection(sectionMapping[sectionName.toLowerCase()]);
    } else {
      window.location.assign(`${Endpoints.HOME}/app/${sectionName}`);
    }
  };

  const menu = () => (
    <Menu
      id="simple-menu"
      anchorEl={menuOpen}
      keepMounted
      open={Boolean(menuOpen)}
      onClose={() => setMenuOpen(null)}
      style={{ margin: '35px 20px 0 0' }}
    >
      <Box style={{ backgroundColor: '#ffffff' }}>
        {
          (width <= 590 && !auth)
          && (
          <MenuItem>
            <Box
              id="nav_sign_in"
              className={classes.dropdownItem}
              onClick={() => handleLogin()}
            >
              Sign In
            </Box>
          </MenuItem>
          )
        }

        { width <= 400 && (
          <>
            <MenuItem onClick={() => handleNavClick('ProfitLayers')}>
              <Box
                className={classes.dropdownItem}
              >
                Profit Layers
                <sup>&reg;</sup>
              </Box>
            </MenuItem>

            <MenuItem onClick={() => handleNavClick('DataLayers')}>
              <Box
                className={classes.dropdownItem}
              >
                Data Layers
                <sup>&trade;</sup>
              </Box>
            </MenuItem>

            <MenuItem onClick={() => handleNavClick('FieldManager')}>
              <Box
                className={classes.dropdownItem}
              >
                FieldManager
                <sup>&trade;</sup>
              </Box>
            </MenuItem>

            <MenuItem onClick={() => handleNavClick('Services')}>
              <Box
                className={classes.dropdownItem}
              >
                Services
              </Box>
            </MenuItem>
          </>
        )}

        <MenuItem onClick={() => handleNavClick('Purchase')}>
          <Box
            className={classes.dropdownItem}
            onClick={() => ReactGA.event({ category: 'Navigation', action: 'Navigate to Pricing', label: 'Nav Bar Link' })}
          >
            Pricing
          </Box>
        </MenuItem>

        <Link
          href="https://www.profit.ag/"
          target="_blank"
          className={classes.dropdownItem}
        >
          <MenuItem className={classes.menuItem}>
            About
          </MenuItem>
        </Link>

        <Link
          className={classes.dropdownItem}
          href="https://www.profit.ag/help-center"
          target="_blank"
        >
          <MenuItem className={classes.menuItem}>
            Help
          </MenuItem>
        </Link>

        <Link
          className={classes.dropdownItem}
          href={`${Endpoints.HOME}/app/Integrate`}
        >
          <MenuItem className={classes.menuItem}>
            Integrate Precision Ag
          </MenuItem>
        </Link>

        {auth && (
          <div>
            <Box className={classes.dropdownItem}>
              <MenuItem className={classes.menuItem} onClick={() => openInvite()}>
                Connect Account Invite
              </MenuItem>
            </Box>
            <Link
              className={classes.dropdownItem}
              href={`${Endpoints.BASEURL}/FarmScope/ProfitLayers`}
            >
              <MenuItem className={classes.menuItem}>
                ProfitLayers (Legacy)
              </MenuItem>
            </Link>

            <Link
              className={classes.dropdownItem}
              style={{ textDecoration: 'none' }}
              href={`${Endpoints.HOME}/app/FieldManager`}
            >
              <MenuItem className={classes.menuItem}>
                FieldManager
              </MenuItem>
            </Link>

            <Link
              className={classes.dropdownItem}
              style={{ textDecoration: 'none' }}
              href={`${Endpoints.HOME}/app/AcreageReporting`}
            >
              <MenuItem className={classes.menuItem}>
                Acreage Reporting
              </MenuItem>
            </Link>

            <Link
              className={classes.dropdownItem}
              style={{ textDecoration: 'none' }}
              href={`${Endpoints.HOME}/app/FarmReport`}
            >
              <MenuItem className={classes.menuItem}>
                Farm Report
              </MenuItem>
            </Link>

            <Link
              className={classes.dropdownItem}
              style={{ textDecoration: 'none' }}
              href={`${Endpoints.BASEURL}/Profile/PremiumFeatures`}
            >
              <MenuItem className={classes.menuItem}>
                Premium Features
              </MenuItem>

            </Link>

            <Link
              className={classes.dropdownItem}
              style={{ textDecoration: 'none' }}
              href={`${Endpoints.HOME}/app/OrganizationPreferences`}
            >
              <MenuItem className={classes.menuItem}>
                Organization Preferences
              </MenuItem>
            </Link>

            <Link
              className={classes.dropdownItem}
              style={{ textDecoration: 'none' }}
              href={`${Endpoints.HOME}/app/PriceConverter`}
            >
              <MenuItem className={classes.menuItem}>
                Update Prices
              </MenuItem>
            </Link>

            <Link
              className={classes.dropdownItem}
              style={{ textDecoration: 'none' }}
              href={`${Endpoints.HOME}/app/ManageSubscription`}
            >
              <MenuItem className={classes.menuItem}>
                Manage Subscription
              </MenuItem>
            </Link>

          </div>
        )}
        <MenuItem onClick={() => handleNavClick('Purchase')}>
          <Box
            className={classes.dropdownItem}
            onClick={() => ReactGA.event({ category: 'Navigation', action: 'Navigate to Pricing', label: 'Nav Bar Link 2' })}
          >
            Purchase Subscription
          </Box>
        </MenuItem>

        {
          auth
          && (
          <div>

            <Link
              className={classes.dropdownItem}
              style={{ textDecoration: 'none' }}
              href={`${Endpoints.HOME}/app/EditProfile`}
            >
              <MenuItem className={classes.menuItem}>
                Profile
              </MenuItem>

            </Link>

            <Box
              className={classes.dropdownItem}
              onClick={() => handleLogout()}
            >
              <MenuItem className={classes.menuItem}>
                Log Out
              </MenuItem>
            </Box>

          </div>
          )
        }
      </Box>

    </Menu>
  );

  const openMenu = (event) => {
    setMenuOpen(event.currentTarget);
  };

  const handleLogin = () => {
    // Old code
    // window.location.assign(Endpoints.BASEURL + Endpoints.SIGNIN);
    ReactGA.event({
      category: 'User',
      action: 'Sign In',
    });
    try {
      // Do not redirect to main app if sign in is from AcreageReporting
      const acreageReporting = document.URL.toLowerCase().split('acreagereporting');
      let url = Endpoints.BASEURL;

      if (acreageReporting.length === 1) {
        // Usual redirect
        url += Endpoints.SIGNIN;
      } else {
        // Custom redirect
        url += Endpoints.SIGNINFROMACREAGE;
      }
      window.location.assign(url);
    } catch (err) {
      console.error(err);
    }
  };

  const handleLogout = () => {
    sessionStorage.clear();
    localStorage.clear();
    window.location.assign(Endpoints.BASEURL + Endpoints.SIGNOUT);
  };

  return (
    <div className={classes.root}>
      <AppBar position="fixed" className={classes.nav}>
        <Box
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          style={{ width }}
        >

          <Link href={`${Endpoints.HOME}/app/ProfitLayers`}>
            <img
              src={logo}
              className={
                 width > 1024 ? classes.fullLogo
                   : width > 760 ? classes.mediumLogo
                     : classes.smallLogo
               }
              alt="ProfitLayers Logo"
            />
          </Link>

          {
            width > 400
            && (
            <Box display="flex" alignItems="center">
              <Box
                id="main_nav_profit_layers"
                className={section === 0 ? classes.selected : classes.selection}
                onClick={() => handleNavClick('ProfitLayers')}
                fontSize={width > breakTwo ? 14 : width > breakThree ? 12 : 10}
                style={{
                  padding: '8px',
                  margin: width > breakTwo ? '0 4px' : '0 2px',
                }}
              >
                PROFITLAYERS
                <sup>&reg;</sup>
              </Box>

              <Box
                id="main_nav_data_layers"
                className={section === 1 ? classes.selected : classes.selection}
                onClick={() => handleNavClick('DataLayers')}
                fontSize={width > breakTwo ? 14 : width > breakThree ? 12 : 10}
                style={{
                  padding: '8px',
                  margin: width > breakTwo ? '0 4px' : '0 2px',
                }}
              >
                DATA LAYERS
                <sup>&trade;</sup>
              </Box>

              { width > startHiding && (
                <Box
                  id="main_nav_field_manager"
                  className={section === 2 ? classes.selected : classes.selection}
                  onClick={() => handleNavClick('FieldManager')}
                  fontSize={width > breakTwo ? 14 : width > breakThree ? 12 : 10}
                  style={{
                    padding: '8px',
                    margin: width > breakTwo ? '0 4px' : '0 2px',
                  }}

                >
                  FIELDMANAGER
                  <sup>&reg;</sup>
                </Box>
              )}

              <Box
                id="main_nav_services"
                className={section === 3 ? classes.selected : classes.selection}
                onClick={() => handleNavClick('Services')}
                fontSize={width > breakTwo ? 14 : width > breakThree ? 12 : 10}
                style={{
                  padding: '8px',
                  margin: width > breakTwo ? '0 4px' : '0 2px',
                }}
              >
                SERVICES
              </Box>

              { width > startHiding && (
                <Box
                  id="main_nav_pricing"
                  className={section === 4 ? classes.selected : classes.selection}
                  onClick={() => handleNavClick('Purchase')}
                  fontSize={width > breakTwo ? 14 : width > breakThree ? 12 : 10}
                  style={{
                    padding: '8px',
                    margin: width > breakTwo ? '0 4px' : '0 2px',
                  }}
                >
                  PRICING
                </Box>
              )}
            </Box>
            )
          }

          <Box pr={1} display="flex" alignItems="center">
            {
              auth ? (
                width > breakOne
                  ? (
                    <Box mr={1} fontSize={12}>
                      <Box
                        display="flex"
                        alignItems="center"
                        justifyContent="flex-end"
                        color={grey}
                      >
                        LOGGED IN
                      </Box>
                      <Box fontWeight={600} style={{ whiteSpace: 'nowrap' }}>
                        {user.email}
                      </Box>
                    </Box>
                  )
                  : ''
              )
                : width > 590 ? (
                  <Button
                    id="nav_sign_in"
                    variant="outlined"
                    color="primary"
                    onClick={() => handleLogin()}
                    disableElevation
                    style={{
                      backgroundColor: '#ffffff',
                      padding: '4px 8px',
                      whiteSpace: 'nowrap',
                    }}
                  >
                    Sign In
                  </Button>
                )
                  : ''
            }

            <IconButton
              edge="start"
              className={classes.menuButton}
              aria-label="menu"
              onClick={openMenu}
            >
              <MenuIcon />
            </IconButton>

            {menu()}

            { width >= 680 && (
              <Link href="https://www.profit.ag/help-center" target="_blank">
                <HelpIcon className={classes.helpIcon} />
              </Link>
            )}

          </Box>
        </Box>
      </AppBar>
    </div>
  );
}

Navbar.propTypes = {
  section: PropTypes.number.isRequired,
  setSection: PropTypes.func.isRequired,
};

import React from "react";
import { withStyles } from "@material-ui/core";
import Chip from "@material-ui/core/Chip";
import { COLORS } from "../../../constants";

const ThemedChip = withStyles((theme) => ({
  root: {
    backgroundColor: COLORS.green,
    color: COLORS.white,
    "&:not(:last-child)": {
      marginRight: theme.spacing(1),
    },
    "& .MuiSvgIcon-root.MuiChip-deleteIcon": {
      fill: COLORS.white,
    },
    margin: "2px !important"
  },
}))((props) => <Chip {...props} />);

export default ThemedChip;

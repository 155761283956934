// -------------------- IMPORTS --------------------
// React
import React from 'react';

// material-ui
import {
	Box,
	Button,
	Divider,
	Modal,
	Tooltip,
	Typography
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Styling and helpful packages
import { makeStyles } from '@material-ui/core/styles';
import { darkText } from '../../../styles/colors';


// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
	body: {
		height: 'auto',
		width: '100%',
		fontSize: 16,
		fontWeight: 500,
		color: theme.palette.text.black,
		display: 'flex',
		flexDirection: 'column',
		overflowY: 'auto',
	},
	head: {
		height: '50px',
		padding: '20px',
		alignItems: 'center',
		width: '100%',
		color: darkText,
		fontSize: 20,
		fontWeight: 500,
		display: 'flex',
	},
  icon: {
		...theme.icon,
	},
	paper: {
		...theme.modalPaper,
		position: 'absolute',
		height: 'auto',
		maxWidth: '820px',
		maxHeight: '70%',
		display: 'flex',
		flexDirection: 'column',
	},
}));


// -------------------- MAIN FUNCTION --------------------

/**
 * Will let user view status of their submission to RMA
 * @param {Boolean} open Determine if modal is opened or closed
 * @param {Function} setOpen Sets if modal is open
 * @param {String} statusInfo String of RMA Status
 * @return {JSX} Modal JSX
 */
export function ViewRMAStatus({
	open,
	setOpen,
	statusInfo,
}) {
	// -------------------- VARIABLES --------------------
	const classes = useStyles();


  // -------------------- FUNCTIONALITY --------------------
	/**
	 * Looks through returned RMA status to see whether or not a recognizable failure seems to have occured
	 * 
	 * Assuming that structure should always be like:
	 *[
			[
				{
					"stepCode": "Validation",
					"sourceSystem": "RMA",
					"statusName": "failure",
					"addInfoText": "",
					"createDateTime": "2021-06-29T17:04:31.593",
					"errors": [
						{
							"internalMessage": "",
							"refUrl": "",
							"createdTimestamp": "2021-06-29T17:04:31.607",
							"loggingDBReferenceCode": null,
							"errorName": "ACRSI-053-02",
							"errorMessageRule": "There was an error calling the FRS Service",
							"errorMessageDetail": "for property at Farm[1]"
						}
					]
				},
				{
					"stepCode": "ErrorReport",
					"sourceSystem": "RMA",
					"statusName": "ok",
					"addInfoText": "Submission Source is not AIP Service",
					"createDateTime": "2021-06-29T17:04:32.047",
					"errors": []
				},
				{
					"stepCode": "Delete",
					"sourceSystem": "RMA",
					"statusName": "ok",
					"addInfoText": "\r\n  ",
					"createDateTime": "2021-06-29T17:04:32.31",
					"errors": []
				}
			]
		]
	 * 
	 * @param {Object} status status returned from RMA
	 * @returns whether or not a failure message was found
	 */
	const hasFailure = (status) => {
		for (const entry of status) {
			if (entry[0]?.statusName === 'failure') return true;
		}	
		return false;
	}

	/**
	 * Open a new tab where the user can contact us with a support message.
	 */
  const contact = () => {
    // window.open('https://www.profit.ag/#comp-iock1pqk'); // This one is more so for demos
    window.open('https://www.profit.ag/contact');
  };

	const modalJSX = () => {
		// Parse status and check for known issues
		const parsedStatus = JSON.parse(statusInfo);
		const errorFound = Array.isArray(parsedStatus) && hasFailure(parsedStatus);
		const internalError = !errorFound && parsedStatus.hasOwnProperty('error');

		return (
			<Modal
				open={open}
				onClose={() => setOpen(false)}
				style={{ display: "flex", alignItems: "center", justifyContent: "center" }}
			>
				<Box
					className={classes.paper}
					borderRadius="borderRadius"
				>
					{/* X icon */}
					<Box display="flex" justifyContent="flex-end">
						<HighlightOffIcon
							className={classes.icon}
							style={{ position: "absolute", marginTop: "10px" }}
							onClick={() => setOpen(false)}
						/>
					</Box>

					{/* Modal Title */}
					<Box className={classes.head}>
						ACRSI Submission Status
					</Box>

					{/* Modal Body */}
					<Box className={classes.body}>
						<Divider />

						{/* Show a message if an error was detected */}
						{(errorFound || internalError) &&
							<>
								<Box 
									p={4}
									display="flex"
									alignItems="center"
									justifyContent="space-between"
									style={{ background: "rgba(255, 170, 170, 0.5)" }}
								> 
									{/* If it's an error from submitting data */}
									{errorFound &&
										<>
											<Box maxWidth={550}>
												It seems an error occurred with your submission when being processed by USDA. Please contact us for assistance.
											</Box>

											<Button
												color="primary"
												variant="contained"
												disableElevation
												style={{
													width: 150,
													minWidth: 100,
													marginLeft: "16px",
												}}
												onClick={contact}
											>
												Contact Us
											</Button>
										</>
									}

									{/* If it's an error from trying to retrieve the status */}
									{internalError && parsedStatus.error}
								</Box>
								<Divider />
							</>
						}

						{/* Show the RMA status if it exists */}
						{!internalError &&
							<Box style={{ display: "flex", overflowY: "auto" }}>
								<pre style={{ whiteSpace: "pre-wrap", overflowY: "auto", width: "100%", padding: "24px 36px 16px", margin: 0  }}>
									{JSON.stringify(parsedStatus, null, 2)}
								</pre>
							</Box>
						}
					</Box>
				</Box>
			</Modal>
		)
	}


	// -------------------- RETURN --------------------
	return (modalJSX());
}
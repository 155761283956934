import React from "react";
import {
  makeStyles,
  Select,
  InputLabel,
  MenuItem,
  FormControl,
} from "@material-ui/core";
import { COLORS } from "../../../constants";

const useStyles = makeStyles((theme) => ({
  label: {
    fontSize: 14,
    fontWeight: "bold",
    paddingBottom: theme.spacing(1),
    color: COLORS.darkGray,
  },
  formControl: {
    margin: "8px 16px 8px 0px",
    minWidth: 120,
    borderRadius: 5,
    "& .MuiInputBase-root": {
      height: 40,
    },
  },
  selectEmpty: {
    marginTop: theme.spacing(2),
  },
}));

const CustomSelectWithAsync = ({
  label,
  options = [],
  valueAccessor = (d) => d?.id,
  onChange,
}) => {
  const classes = useStyles();

  const selectedOption = options.find((option) => option.active);
  const handleChange = (evt) => onChange(evt.target.value);

  return (
    <div className={classes.formControl}>
      <InputLabel className={classes.label}>{label}</InputLabel>
      <FormControl variant="outlined">
        <Select value={valueAccessor(selectedOption)} onChange={handleChange}>
          {options.map((option) => {
            return (
              <MenuItem
                key={`menu-item-for-${valueAccessor(option)}`}
                value={valueAccessor(option)}
              >
                {valueAccessor(option)}
              </MenuItem>
            );
          })}
        </Select>
      </FormControl>
    </div>
  );
};

export default CustomSelectWithAsync;

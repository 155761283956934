import React from 'react'
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box } from '@material-ui/core'
import { soilTestResponse } from '../Files/SoilTestingResponse'
import { order } from '../Files/SoilTestOrder'
import ReactJson from 'react-json-view'

export function SoilTest({ }) {
  return (
    <Box>
      <Box fontSize={16} fontWeight={500}>
        <Box>JSON Structure of Soil Testing Service Results:</Box>
        <Box mt={2}>
          Find JSON structure document <a href="https://drive.google.com/uc?export=download&id=1FDA0A-CxMlIshIHXxD3_k0rG_ZQ6C8C4" download>here</a>.
        </Box>
        <Box mt={2}>
          Below is the JSON structure that will be sent to Soil Test after customer has placed order. Order and Suborder information which needs to be in results can be found from order request
          <Box>
          <ReactJson
            src={order}
            theme="monokai"
            name={false}
            indentWidth={2}
            collapseStringsAfterLength={50}
          />
          </Box>
        </Box>
        <Box width="100%" mt={2}>
          <Box>Result JSON Schema</Box>                    
          <ReactJson
            src={soilTestResponse}
            theme="monokai"
            name={false}
            indentWidth={2}
            collapseStringsAfterLength={50}
          />
        </Box>
      </Box>
    </Box>
  )
}
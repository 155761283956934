import React, { useState, Fragment, useEffect, useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import ReactGA from 'react-ga';
import {
  Box,
  Typography,
  Link,
  Checkbox,
  Divider,
  Button,
  Modal,
  Container,
} from "@material-ui/core";
import CheckBoxOutlineBlankIcon from "@material-ui/icons/CheckBoxOutlineBlank";
import CheckBoxIcon from "@material-ui/icons/CheckBox";
import Zoom from "@material-ui/core/Zoom";
import { useSnackbar } from "notistack";
import CancelOutlinedIcon from "@material-ui/icons/CancelOutlined";
import InfoOutlinedIcon from "@material-ui/icons/InfoOutlined";
import { Endpoints } from "../../constants/Endpoints";
import deere from "../../images/deere_clear.png";
import climate from "../../images/climate_clear.png";
import cnh from "../../images/cnh_clear.png";
import background from "../../images/tractors.jpg";
import { terms } from "./Terms";
import { useWindowDimensions } from "../../utils/dimensions";
import {
  lightGrey,
  darkGrey,
  blackText,
  darkText,
  green,
  grey,
} from "../../styles/colors";
import { UserContext } from "../Context/UserContext";
import { CustomToolTip } from "../../utils/customComponents";

const useStyles = makeStyles((theme) => ({
  connect: {
    display: "flex",
    alignItems: "center",
    flexDirection: "column",
    justifyContent: "space-between",
    padding: 16,
    margin: 10,
    fontWeight: 500,
    fontSize: 16,
    color: theme.palette.text.dark,
    backgroundColor: "#ffffff",
    "&:hover": {
      cursor: "pointer",
      backgroundColor: "#f8f8ff",
    },
  },
  logo: {
    height: "80px",
    width: "auto",
    marginBottom: 18,
  },
  tac: {
    margin: 10,
    padding: 16,
    overflowY: "auto",
    backgroundColor: "white",
    color: theme.palette.text.dark,
    maxWidth: 740,
  },
  termsBox: {
    // backgroundColor: theme.palette.primary.dark
  },
  blink: {
    animation: "blinker 1s linear",
  },
  typography: {
    button: {
      textTransform: "none",
    },
  },
  checkbox: {
    fontSize: 38,
    color: green,
  },
  hover: {
    fontColor: blackText,
    "&:hover": {
      cursor: "pointer",
      color: darkText,
    },
  },
  partner: {
    height: "60px",
    width: "auto",
    margin: 6,
  },
  fullConnect: {
    ...theme.connect,
    borderRadius: 4,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  mobileConnect: {
    ...theme.connect,
    fontSize: 16,
    padding: 6,
    margin: 6,
    borderRadius: 4,
    border: `2px solid ${theme.palette.primary.main}`,
  },
  buttonLink: {
    color: theme.palette.primary.main,
    textDecoration: "none",
    fontSize: 17,
  },
  infoToolTip: theme.infoToolTip,
}));

const handleLogin = (signInFrom) => {
  ReactGA.event({
    category: "User",
    action: "Sign In"
  })
  try {
    let url = Endpoints.BASEURL;
    if (!signInFrom) {
      // Usual redirect
      url += Endpoints.SIGNIN;
    } else {
      // Custom redirect
      url += Endpoints[signInFrom];
    }
    window.location.assign(url);
  } catch (err) {
    console.error(err);
  }
};

const handleSignUp = () => {
  ReactGA.event({
    category: "User",
    action: "Sign Up"
  })
  window.location.assign(Endpoints.BASEURL + Endpoints.SIGNUP);
};

const getModalStyle = () => {
  const top = 5;
  return {
    top: `${top}%`,
    margin: "auto",
    zIndex: 1002,
    backgroundColor: "white",
    // height: '100%',
    // width: '100%',
  };
};

/**
 * Integrate options and terms to display wherever
 * 
 * Rendered in: (Search for Partners/Integrate)
 * components\Partners\IntegrationPage.js
 * components\ProfitLayers\InfoModal.js
 * components\Pricing\Pricing.js
 * components\Pricing\esri\Esri.js
 * components\Pricing\profitlayers\PurchasePL.js
 * components\ManageSubscription\ManageSubscription.js
 * components\Shared\Connect.js
 * components\Shared\ConnectAndIntegrate.js
 * components\Shared\IntegrateModal.js
 * components\ProfitLayers\Operations\Applications\Applications.js
 * components\ProfitLayers\Operations\Others\Others.js
 * components\ProfitLayers\Operations\Plantings\Plantings.js
 * 
 * @param {String} header Header text
 * @param {Function} setChecked TaC checked
 * @param {String} customText Custom text
 * @param {Boolean} condensed bool to determine layout - not currently in use
 * @returns {JSX} Integrate Modal
 */
export function Integrate({ header, setChecked, customText, condensed, condensedTerms, customBackground, showLogin=false }) {
  const user = useContext(UserContext)[0];
  const [modalStyle] = React.useState(getModalStyle);
  const classes = useStyles();
  const { height, width } = useWindowDimensions();
  const [termsChecked, setTermsChecked] = useState(false);
  const [loginPromptOpen, setLoginPromptOpen] = useState(false);
  const [authenticated, setAuthenticated] = useState(false);
  const [alert, setAlert] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [pageSource, setPageSource] = useState("PROFITLAYERS");
  const [dataShare, setDataShare] = useState(null);

  const deereLink = `${Endpoints.BASEURL}${Endpoints.DEEREINTEGRATE}?source=`;
  const climateLink = `${Endpoints.BASEURL}${Endpoints.CLIMATEINTEGRATE}?source=`;
  const cnhLink = `${Endpoints.BASEURL}${Endpoints.CNHINTEGRATE}?source=`;

  useEffect(() => {
    const url = document.URL.split("?");
    if (url.length > 1) {
      const extension = url[1];
      if (extension.includes("source")) {
        const source = extension.replace("source=", "");
        // console.log(token)
        setPageSource(source);
        // remove token from url so user can't grab it
        url.pop();
        window.history.replaceState({}, document.title, url[0]);
      }
      else if(extension.includes('dataShare')){
        const urlParams = new URLSearchParams(extension);
        const email = urlParams.get('dataShare');
        setDataShare(email)
      }
    }
  }, []);

  const integratePrecision = (url) => {
    if (!termsChecked) {
      setAlert(true);
      enqueueSnackbar(
        "Please read and agree to the terms and conditions before integrating"
      );
      const elmnt = document.getElementById("checkbox");
      elmnt.scrollIntoView();
    } else if (url === "deere") {
      ReactGA.event({
        category: "Integration",
        action: "Start Deere Integration"
      })
      console.log("deereLink", deereLink);
      let link = `${deereLink}${pageSource}`
      if (dataShare !== null){
        link = link + `&dataShare=${dataShare}`
      }
      window.open(link, "_blank");
    } else if (url === "climate") {
      ReactGA.event({
        category: "Integration",
        action: "Start Climate Integration"
      })
      let link = `${climateLink}${pageSource}`
      if (dataShare !== null){
        link = link + `&dataShare=${dataShare}`
      }
      window.open(link, "_blank");
    } else if (url === "cnh") {
      ReactGA.event({
        category: "Integration",
        action: "Start CNH Integration"
      })
      let link = `${cnhLink}${pageSource}`
      if (dataShare !== null){
        link = link + `&dataShare=${dataShare}`
      }
      window.open(link, "_blank");
    }
  };

  // const getDeere = async () => {
  //   let response = await fetch(Endpoints.BASEURL+Endpoints.DEEREINTEGRATE+"?source=ESRI",{
  //     method: "GET",
  //     headers: {
  //       Authorization: "Bearer "+userToken
  //     }
  //   })
  //   console.log(response)
  //   let result = await response.json()
  //   console.log(result)
  //   window.open(result.url, "_blank")
  // }

  const check = () => {
    setAlert(false);
    setTermsChecked(!termsChecked);
    if (setChecked !== undefined) {
      setChecked(!termsChecked);
    }
  };

  const scrollToTerms = () => {
    const elmnt = document.getElementById("terms-of-use");
    elmnt.scrollIntoView();
  };

  const openTerms = () => {
    let url = `${Endpoints.HOME}/app/TermsAndConditions`
    window.open(url, '_blank').focus()
  }

  // This is not currently used as loginPromptOpen is never set to true...
  const showIntegrate = () => (
    <Modal
      open={loginPromptOpen}
      onClose={() => setLoginPromptOpen(false)}
      aria-labelledby="log-in-sign-up"
      aria-describedby="log-in-sign-up-prompt"
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <Box
        style={modalStyle}
        className={classes.paper}
        borderRadius="borderRadius"
        boxShadow={2}
      >
        <Box
          p={2}
          display="flex"
          justifyContent="space-between"
          style={{ width: "100%" }}
        >
          <Box>&nbsp;</Box>

          <Box fontWeight={500} fontSize={20} color={blackText}>
            Sign in and Connect Precision Ag.
          </Box>

          <CancelOutlinedIcon
            className={classes.edit}
            onClick={() => setLoginPromptOpen(false)}
          />
        </Box>

        <Divider />
        {!authenticated && (
          <Box
            display="flex"
            justifyContent="center"
            style={{ width: "100%" }}
            flexWrap="wrap"
          >
            <Button
              color="primary"
              variant="contained"
              onClick={() => handleLogin()}
              style={{ margin: "8px 16px", fontSize: 20, width: "220px" }}
            >
              SIGN IN
            </Button>

            <Button
              color="primary"
              variant="contained"
              onClick={() => handleSignUp()}
              style={{ margin: "8px 16px", fontSize: 20 }}
            >
              CREATE AN ACCOUNT
            </Button>
          </Box>
        )}
        <Divider />
        <Box p={2}>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setLoginPromptOpen(false)}
          >
            Exit
          </Button>
        </Box>
      </Box>
    </Modal>
  );

  const standardText = () => (
    <Box
      my={1}
      p={1}
      display="flex"
      flexDirection="column"
      fontSize={16}
      fontWeight={500}
      style={{ maxWidth: 740 }}
    >
      <Box>
        Connect your Precision Ag system for advanced insights, analysis, and
        features.
      </Box>
      <Box>
        This connection will securely pull in your field boundaries,
        information, and any operational data*.
      </Box>
    </Box>
  );

  return (
    <Box
      px={1}
      display="flex"
      flexDirection="column"
      alignItems="center"
      flexGrow={1}
      style={customBackground ? { backgroundColor: customBackground } : { backgroundColor: lightGrey }}
      color={blackText}
    >
      {/* Alternative Header */}
      {header && (
        <Box p={condensed ? 1 : 2}>
          <Typography variant={condensed ? "h6" : "h5"} align="center" style={condensed ? { marginBottom: 2 } : { marginBottom: 4 }}>
            Connect your Precision Ag System
          </Typography>
          <Divider />
        </Box>
      )}

      {/* Usual Header */}
      {!condensed && (
        customText ? customText() : standardText()
      )}

      {(!condensed && showLogin ) &&
        <Box
          display="flex"
          justifyContent="center"
          style={{ width: "100%", marginBottom: 10 }}
          flexWrap="wrap"
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => handleLogin()}
            style={{ margin: "8px 16px", fontSize: 20, width: "220px" }}
          >
            SIGN IN
          </Button>

          <Button
            color="primary"
            variant="contained"
            onClick={() => handleSignUp()}
            style={{ margin: "8px 16px", fontSize: 20 }}
          >
            CREATE AN ACCOUNT
          </Button>
        </Box>
      }
      
      {/* Terms of Use */}
      {!condensed && 
        <Box
          mx={1}
          p={1}
          display="flex"
          flexDirection="column"
          flexGrow={1}
          borderRadius="borderRadius"
          style={{ backgroundColor: "#ffffff", maxWidth: 740 }}
          border={1}
          borderColor={alert ? "red" : grey}
        >
          <Typography variant="subtitle2" align="center">
            * Please note, availability of operational data varies with each
            precision ag system.
          </Typography>
          <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            fontWeight={500}
            fontSize={18}
            id="checkbox"
          >
            <Checkbox
              checked={termsChecked}
              icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
              checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
              onChange={() => check()}
            />
            <Box style={{fontColor: blackText}}>
              I have read and agree to the Terms of Use & Privacy Policy
            </Box>
          </Box>
        </Box>
      }
      
      {condensed ? (
        // Integration Links, Terms of Use, and Agreement
        <Box display="flex" id="item-container" style={{alignItems: 'center'}}>
          <Box p={1} display="flex" justifyContent="center" flexWrap="wrap" flexDirection="column">
            {/* Deere */}
            <Box
              className={classes.connect}
              border={1}
              borderColor={green}
              borderRadius="borderRadius"
              onClick={() => integratePrecision("deere")}
            >
              <img className={classes.logo} src={deere} alt="Connect to Deere" />
            </Box>

            {/* Climate */}
            <Box
              className={classes.connect}
              border={1}
              borderColor={green}
              borderRadius="borderRadius"
              onClick={() => integratePrecision("climate")}
            >
              <img
                className={classes.logo}
                src={climate}
                alt="Connect to Climate"
              />
            </Box>

            {/* CNH */}
            <Box
              className={classes.connect}
              border={1}
              borderColor={green}
              borderRadius="borderRadius"
              onClick={() => integratePrecision("cnh")}
            >
              <img className={classes.logo} src={cnh} alt="Connect to CNH" />
            </Box>
          </Box>

          <Box id="terms-check" display="flex" flexDirection="column">
            <Box
              mx={1}
              p={1}
              display="flex"
              flexDirection="column"
              flexGrow={1}
              borderRadius="borderRadius"
              style={{ backgroundColor: "#ffffff"}}
              border={1}
              borderColor={alert ? "red" : grey}
            >
              <Typography variant="subtitle2" align="center">
                * Please note, availability of operational data varies with each
                precision ag system.
              </Typography>
              <Box
                display="flex"
                alignItems="center"
                justifyContent="center"
                fontWeight={500}
                fontSize={18}
                id="checkbox"
              >
                <Checkbox
                  checked={termsChecked}
                  icon={<CheckBoxOutlineBlankIcon className={classes.checkbox} />}
                  checkedIcon={<CheckBoxIcon className={classes.checkbox} />}
                  onChange={() => check()}
                />
                <Box className={classes.hover} onClick={() => condensedTerms ? openTerms() : scrollToTerms()}>
                  I have read and agree to the Terms of Use & Privacy Policy
                </Box>
              </Box>
            </Box>

            <Box
              className={classes.tac}
              id="terms-of-use"
              border={1}
              borderColor={grey}
              borderRadius="borderRadius"
              style={{ height: 300 }}
            >
              {terms()}
            </Box>
          </Box>
        </Box>

      ) : (

        // Integration Links and Terms of Use
        <>
          <Box display="flex" style={{marginTop: 5, fontSize: 'small'}} >
            <Box><span>Read our </span><span style={{cursor:'pointer', color: green}} onClick={() => openTerms()}>Terms of Service and Privacy Policy.</span></Box>
          </Box>
          <Box p={1} display="flex" justifyContent="center" flexWrap="wrap">
            {/* Deere */}
            <Box
              className={classes.connect}
              border={1}
              borderColor={green}
              borderRadius="borderRadius"
              onClick={() => integratePrecision("deere")}
            >
              <img className={classes.logo} src={deere} alt="Connect to Deere" />
            </Box>

            {/* Climate */}
            <Box
              className={classes.connect}
              border={1}
              borderColor={green}
              borderRadius="borderRadius"
              onClick={() => integratePrecision("climate")}
            >
              <img
                className={classes.logo}
                src={climate}
                alt="Connect to Climate"
              />
            </Box>

            {/* CNH */}
            <Box
              className={classes.connect}
              border={1}
              borderColor={green}
              borderRadius="borderRadius"
              onClick={() => integratePrecision("cnh")}
            >
              <img className={classes.logo} src={cnh} alt="Connect to CNH" />
            </Box>
          </Box>

          {!condensedTerms && 
            <Box
              className={classes.tac}
              id="terms-of-use"
              border={1}
              borderColor={grey}
              borderRadius="borderRadius"
              style={{ height: header ? 400 : condensedTerms ? 170 : 200 }}
            >
              {terms()}
            </Box>
          }
          
        </>
      )}
      {showIntegrate()}
    </Box>
  );
}

/**
 * Display buttons with links for the user to create an account, sign in, or integrate their precision data.
 * @param {Bool} mobileView  Determines when to switch to mobile view
 * @param {Bool} signedIn If user is signed in
 * @param {Function} onConnect Actions on image and connect click
 * @param {String} signInFrom Optionally endpoint key to redirect to after sign in
 * @returns {JSX} Sign in and integrate
 */
export const IntegrationLinks = ({
  mobileView,
  signedIn,
  onConnect,
  source,
  signInFrom,
}) => {
  const classes = useStyles();

  return (
    <Box
      p={2}
      display="flex"
      justifyContent="center"
      style={{
        backgroundColor: lightGrey,
        overflowY: "auto",
        overflowX: "hidden",
      }}
    >
      <Box
        p={2}
        display="flex"
        flexDirection="column"
        flexGrow={1}
        alignItems="center"
        borderRadius="borderRadius"
        boxShadow={1}
        style={{
          backgroundColor: "#ffffff",
        }}
      >
        <Box p={0.5} fontWeight={500} fontSize={16} color={blackText}>
          Connect your preferred Precision Ag system for advanced insights and
          features
        </Box>

        <Box
          display="flex"
          flexDirection={!mobileView ? "column" : "row"}
          justifyContent="center"
          alignItems="center"
          flexWrap="wrap"
        >
          <Box className={classes.hover} onClick={() => onConnect()}>
            <img className={classes.partner} src={climate} alt="climate-logo" />
          </Box>

          <Box className={classes.hover} onClick={() => onConnect()}>
            <img className={classes.partner} src={deere} alt="deere-logo" />
          </Box>

          <Box className={classes.hover} onClick={() => onConnect()}>
            <img className={classes.partner} src={cnh} alt="cnh-logo" />
          </Box>
        </Box>

        <Box
          className={!mobileView ? classes.fullConnect : classes.mobileConnect}
          onClick={() => onConnect()}
        >
          CONNECT PRECISION AG
        </Box>

        {!signedIn && (
          <>
            <Box
              className={
                !mobileView ? classes.fullConnect : classes.mobileConnect
              }
              onClick={() => handleLogin(signInFrom)}
            >
              SIGN IN
            </Box>

            <Box
              className={
                !mobileView ? classes.fullConnect : classes.mobileConnect
              }
              onClick={() => handleSignUp()}
            >
              CREATE AN ACCOUNT
            </Box>
          </>
        )}

        <Link
          className={!mobileView ? classes.fullConnect : classes.mobileConnect}
          target="_blank"
          href={`${Endpoints.HOME}/app/purchase`}
          style={{ textDecoration: "none" }}
        >
          <span className={classes.buttonLink}>PURCHASE SUBSCRIPTION</span>
        </Link>
      </Box>
    </Box>
  );
};

/* eslint-disable no-underscore-dangle */
import React, {
  useEffect, useState, useRef,
} from 'react';
import PropTypes from 'prop-types';
import { Box, Button, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

// Map imports
import * as L from 'leaflet';
import * as turf from '@turf/turf';
import * as wkt from 'terraformer-wkt-parser';

// Icons
import AddIcon from '@material-ui/icons/Add';
import AddBoxOutlinedIcon from '@material-ui/icons/AddBoxOutlined';
import EditLocationIcon from '@material-ui/icons/EditLocation';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import scissors from '../../images/scissors.png';

// Tooltips
import { CustomToolTip } from '../../utils/customComponents';

// Functions
import { createGeoFromBoundary, getFeature } from './MapFunctions/helpers';
// import { drawCountyLayer, drawStateLayer } from './MapFunctions/drawMap';

// Layer styles
// import {
//   stateStyle,
//   countyStyle,
// } from './Styles/layerStyles';
import { Tiles } from '../../constants/Tiles';

// API calls and other functionality
import { getCLUIntersectionsOld } from '../../utils/dataFetchers';

const useStyles = makeStyles((theme) => ({
  mapControls: {
    ...theme.mapControls,
    justifyContent: 'center',
    padding: 8,
  },
  mobileControls: {
    ...theme.mapControls,
    padding: 4,
    backgroundColor: '#ffffff',
    borderTop: '1px solid #555555',
  },
  zoneControls: {
    ...theme.mapControls,
    padding: 8,
    flexWrap: 'nowrap',
  },
  icon: {
    ...theme.greenIcon,
    backgroundColor: '#ffffff',
    border: '1px solid',
    borderRadius: 6,
    paddingRight: 6,
    margin: '2px 10px',
  },
  mobileIcon: theme.greenIcon,
  infoToolTip: theme.infoToolTip,
}));

/**
 * Create an operation by editing field boundary or drawing polygon.
 * Used in: ClientApp\src\components\AcreageReporting\Modals\CreateNewOperation.js
 * @param {Object} selectedOrg Name and ID of chosen org
 * @param {Array} cluBoundaries Users CLU boundaries
 * @param {Object} field Field to create operation for
 * @param {Boolean} gartPath Whether or not current zoneType is 'GART'
 * @param {Function} handleBoundaryFromGeoJson Create a field from CLU selection or Poly draw
 * @param {Array} handleClus Get users CLUs within map bounds
 * @param {String} impersonating email address for user being impersonated by agent
 * @param {Object} operation Operation boundary
 * @param {Function} setLoading loading state for indicator
 * @param {Function} updateCluBoundaries Adds CLU boundaries when come in to map view
 * @param {Function} updateOperation Update operation on boundary edit or draw
 * @param {Boolean} useOrgCLUData CLU data to use. Either true or false
 * @param {String} useOwnCLUData whether to own user's or another's CLU data
 * @returns {JSX} Map for operation creation
 */
export function CreateOperation({
  selectedOrg,
  cluBoundaries,
  field,
  gartPath,
  handleBoundaryFromGeoJson,
  handleClus,
  impersonating,
  operation,
  setLoading,
  updateCluBoundaries,
  updateOperation,
  useOrgCLUData,
  useOwnCLUData,
}) {
  const classes = useStyles();

  const [map, setMap] = useState(null);

  // Layers
  const fieldLayer = useRef(L.featureGroup(null));
  const cluLayer = useRef(L.featureGroup(null));
  const operationLayer = useRef(L.featureGroup(null));
  // const stateLayer = useRef(L.geoJSON(null, {
  //   style: stateStyle,
  //   onEachFeature: highlightMousover,
  // }));

  // const countyLayer = useRef(L.geoJSON(null, {
  //   style: countyStyle,
  //   onEachFeature: highlightMousover,
  // }));

  const [activeTool, setActiveTool] = useState('');
  const [drawnBoundary, setDrawnBoundary] = useState(null);
  const [mapBounds, setMapBounds] = useState(null);
  const [previousZoom, setPreviousZoom] = useState(0); // Start at 0 to avoid calling getCluBoundariesInBounds twice

  const [enableClus, setEnableClus] = useState(!gartPath ? true : false);

  useEffect(() => {
    if (map === null) {
      drawMap();
    }
  }, []);

  useEffect(() => {
    if (field?.boundary && map) {
      drawField();
    }
  }, [field, map]);

  const handleMapMove = (passedMap, event, moveEvent, zoomed) => {
    const operationMap = passedMap || map;
    const currentZoom = operationMap.getZoom();
    // console.log(event, ' currentZoom :>> ', currentZoom);
    if (currentZoom > 12) {
      const bounds = {
        ne: operationMap.getBounds().getNorthEast(),
        nw: operationMap.getBounds().getNorthWest(),
        se: operationMap.getBounds().getSouthEast(),
        sw: operationMap.getBounds().getSouthWest(),
      };
      setMapBounds({bounds, currentZoom, zoomed});

      if (moveEvent) { moveEvent(); }
    }
  }

  useEffect(() => {
    if (operation?.geometry && map) {
      drawOperationBoundary(operation);
    }
  }, [operation, map]);

  useEffect(() => {
    if (drawnBoundary) {
      handleDraw(drawnBoundary);
    }
  }, [drawnBoundary]);

  useEffect(() => {
    if (cluBoundaries?.size > 0 && map) {
      clearCluLayer();
      cluBoundaries.forEach((clu) => {
        drawClu(clu);
      });

      operationLayer.current.bringToFront();
    }
  }, [cluBoundaries, map]);

  useEffect(() => {
    if (enableClus && mapBounds) { 
      const currentZoom = mapBounds.currentZoom;
      // If user has just dragged the map (zoomed is false), always get new CluBoundaries
      // If zoomed, if user has just zoomed in, do not get/set a shortened list of CLUs
      // This is to have a greater selection of CLUs for user to choose from (and to limit unnecessary calls)
      if ((!mapBounds.zoomed) || (currentZoom <= previousZoom)) { 
        getCluBoundariesInBounds(mapBounds.bounds);
        setPreviousZoom(currentZoom);
      }
    }
  }, [enableClus, mapBounds]);

  // Draw map with state, county, clu, field, and operation layers
  const drawMap = () => {
    const mapboxTiles = L.tileLayer(
      Tiles.ESRIBASEMAP,
    );

    // Add map to div by Id, set any parameters and initial view, add mapbox layer
    const operationMap = L.map('operation-creation', {
      dragging: !L.Browser.mobile,
      editable: true,
      editOptions: {
        lineGuideOptions: {
          opacity: 0,
        },
      },
    }).setView([41.016, -92.4083], 5).addLayer(mapboxTiles);

    // drawStateLayer(stateLayer, operationMap.getBounds());

    const moveEvent = () => {
      // const currentZoom = operationMap.getZoom();
      // const bounds = operationMap.getBounds();

      // if (currentZoom > 8 && currentZoom <= 11) {
      //   drawCountyLayer(countyLayer, bounds);
      //   operationMap.addLayer(countyLayer.current);
      // } else if (currentZoom <= 6) {
      //   operationMap.addLayer(stateLayer.current);
      //   operationMap.removeLayer(countyLayer.current);
      // } else {
      //   operationMap.removeLayer(countyLayer.current);
      //   operationMap.removeLayer(stateLayer.current);
      // }
    };

    operationMap.on('dragend', () => {
      // When map is dragged, get current bounds to find CLUs for
      try {
        handleMapMove(operationMap, 'dragend', moveEvent, false);
      } catch (err) {
        console.error(err);
      }
    });

    operationMap.on('zoomend', () => {
      // When map is zoomed in or out, get current bounds to find CLUs for
      // Difference from dragend is that we do not change userClus when zooming out
      try {
        handleMapMove(operationMap, 'zoomend', moveEvent, true);
      } catch (err) {
        console.error(err);
      }
    });

    operationMap.on('pm:create', ({ layer }) => {
      try {
        const feature = getFeature(layer);
        setDrawnBoundary({ feature, layer, type: 'polygon' });
      } catch (err) {
        map.removeLayer(layer);
      }
    });

    operationMap.addLayer(fieldLayer.current);
    operationMap.addLayer(cluLayer.current);
    operationMap.addLayer(operationLayer.current);
    setMap(operationMap);
  };

  const getCluBoundariesInBounds = async ({ne, nw, se, sw}) => {
    try {
      setLoading(true);
      // Make valid geoJson from bound coordinates
      const geo = {
        type: 'Polygon',
        coordinates: [[
          [ne.lng, ne.lat],
          [nw.lng, nw.lat],
          [sw.lng, sw.lat],
          [se.lng, se.lat],
          [ne.lng, ne.lat],
        ]],
      };

      // Convert to shape for query
      const shape = wkt.convert(geo);
      const cluRequestBody = {
        boundaries: shape,
        count: 1,
        cluOwnerEmail: useOwnCLUData,
        orgID: useOrgCLUData ? selectedOrg.id : '', 
        ownerEmail: impersonating,
      };
      // Get CLU boundaries that intersect with map bounds shape
      const cluIntersections = await getCLUIntersectionsOld(cluRequestBody);
      // Update the list of CLUs that user can choose from in cluSelection
      handleClus(cluIntersections);

      // Parse the shapes from string
      cluIntersections.forEach((clu, i) => {
        cluIntersections[i].cluShape = JSON.parse(cluIntersections[i].cluShape);
      });

      // Add CLUs with boundaries to state tracked in parent
      updateCluBoundaries(new Set([...cluIntersections]));
    } catch (err) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const drawField = () => {
    try {
      clearFieldLayer();

      const geo = createGeoFromBoundary(field.boundary);
      L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          layer.setStyle({
            color: '#8e24aa',
            weight: 6,
            opacity: 1,
            fillOpacity: 0,
          });

          layer.on({
            click: () => { handleBoundaryFromGeoJson(field.boundary); },
            mouseover: () => layer.setStyle({ color: '#FF00FF' }),
            mouseout: () => layer.setStyle({ color: '#8e24aa' }),
          });
          fieldLayer.current.addLayer(layer);
        },
      });

      operationLayer.current.bringToFront();
    } catch (err) {
      console.error(err);
    }
  };

  const drawClu = (clu) => {
    try {
      L.geoJson(clu.cluShape, {
        onEachFeature: (feature, layer) => {
          layer.setStyle({
            color: '#FF8C00',
            weight: 4,
            opacity: 1,
            fillOpacity: 0,
          });
          layer.on({
            click: () => {
              const layerFeature = {
                type: 'Feature',
                geometry: clu.cluShape,
              };
              handleBoundaryFromGeoJson(layerFeature, true);
            },
            mouseover: () => layer.setStyle({ color: '#FFA500' }),
            mouseout: () => layer.setStyle({ color: '#FF8C00' }),
          });
          cluLayer.current.addLayer(layer);
        },
      });
      operationLayer.current.bringToFront();
    } catch (err) {
      console.error(err);
    }
  };

  const drawOperationBoundary = (boundary) => {
    try {
      clearOperationLayer();
      const geo = createGeoFromBoundary(boundary);
      const collection = L.geoJson(geo, {
        onEachFeature: (feature, layer) => {
          layer.setStyle({
            fillOpacity: 0.1,
          });

          operationLayer.current.addLayer(layer);

          layer.on('pm:update', (e) => {
            updateOperation(e.layer.toGeoJSON());
          });

          layer.on('pm:cut', (e) => {
            if (e.layer) {
              updateOperation(e.layer.toGeoJSON());
              redrawField(e.layer);
            }
            finishCut();
          });
        },
      });
      map.fitBounds(collection.getBounds(), {
        padding: [30, 30],
      });
      operationLayer.current.bringToFront();
      // Whenever the operation boundary changes, get new CLUs to draw
      // Map won't automatically cause this as we are not using 'moveend' but 'zoomend' and 'drageend', 
      // and drageend (which we would want) is not triggered on call to map.fitBounds()
      // A little timeout to help make sure fitBounds finishes running - 500 ms was found to be long enough for most cases
      window.setTimeout(() => { handleMapMove(map, 'fitBounds', null, false); }, 500);
    } catch (err) {
      console.error(err);
    }
  };

  const handleCluSwitch = (checked) => {
    setEnableClus(checked);

    if (map) {
      if (checked) {
        map.addLayer(cluLayer.current);
        operationLayer.current.bringToFront();
      } else {
        map.removeLayer(cluLayer.current);
      }
    }
  };

  // Handle polygon draw
  const handleDraw = (polygon) => {
    setActiveTool('');
    // Remove drawn layer
    map.removeLayer(polygon.layer);

    const intersects = turf.intersect(field.boundary.geometry, polygon.feature.geometry);

    if (intersects) {
      // Drawn boundary is inside of field boundary
      // Add drawn feature to operation layer
      clearOperationLayer();
      drawOperationBoundary(polygon.feature);

      // Update operation boundary
      updateOperation(polygon.feature);
    } else {
      // Drawn boundary is completely outside of field boundary
      handleBoundaryFromGeoJson(polygon.feature);
    }
  };

  const redrawField = (cutLayer) => {
    try {
      map.removeLayer(cutLayer);
      fieldLayer.current.eachLayer((layer) => {
        map.removeLayer(layer);
      });
      drawField();

      // Add drawn feature to operation layer
      clearOperationLayer();
      drawOperationBoundary(cutLayer.feature);
    } catch (err) {
      console.error(err);
    }
  };

  const clearOperationLayer = () => {
    try {
      operationLayer.current.eachLayer((layer) => {
        map.removeLayer(layer);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const clearFieldLayer = () => {
    try {
      fieldLayer.current.eachLayer((layer) => {
        map.removeLayer(layer);
      });
    } catch (err) {
      console.error(err);
    }
  };

  const clearCluLayer = () => {
    try {
      cluLayer.current.eachLayer((layer) => {
        map.removeLayer(layer);
      });
    } catch (err) {
      console.error(err);
    }
  };

  // ---------- Draw Tools ---------- //
  const addPolygon = () => {
    try {
      // Turn off CLUs
      setActiveTool('draw');
      map.pm.enableDraw('Polygon', { snappable: false });
    } catch (err) {
      console.error(err);
    }
  };

  const addSquare = () => {
    try {
      setActiveTool('draw');
      map.pm.enableDraw('Rectangle', { snappable: false });
    } catch (err) {
      console.error(err);
    }
  };

  const cancelDraw = () => {
    try {
      // disable draw mode
      map.pm.disableDraw();
      setActiveTool('');
    } catch (err) {
      console.error(err);
    }
  };

  const editBoundary = () => {
    try {
      setActiveTool('edit');
      operationLayer.current.bringToFront();
      operationLayer.current.pm.enable();
    } catch (err) {
      console.error(err);
    }
  };

  const cutBoundary = () => {
    try {
      setActiveTool('cut');
      operationLayer.current.bringToFront();
      // enable cutting mode
      map.pm.enableGlobalCutMode({
        allowSelfIntersection: false,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const cancelCut = () => {
    try {
      map.pm.disableGlobalCutMode();
      setActiveTool('');
    } catch (err) {
      console.error(err);
    }
  };

  // const coverBoundary = () => {
  //   try {
  //     clearOperationLayer();
  //     drawOperationBoundary(field.boundary);
  //     updateOperation(field.boundary);
  //   } catch (err) {
  //     console.error(err);
  //   }
  // };

  const finishCut = () => {
    setActiveTool('');
  };

  const finishEdit = () => {
    try {
      setActiveTool('');
      operationLayer.current.pm.disable();
    } catch (err) {
      console.error(err);
    }
  };

  const toolbar = () => (
    <Box className={classes.mapControls}>
      { activeTool === '' && (
        <Box display="flex" flexWrap="wrap">
          <Box mx={0.5} display="flex" bgcolor="#ffffff">
            <CustomToolTip
              title="Draw polygons by clicking to place vertices. Click the first vertex to complete."
              placement="top-start"
            >
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => addPolygon()}
              >
                <AddIcon />
                {' '}
                {'Draw'}
              </Button>
            </CustomToolTip>
          </Box>

          <Box mx={0.5} display="flex" bgcolor="#ffffff">
            <CustomToolTip
              title="Draw square polygon. After completion you can edit the boundary"
              placement="top-start"
            >
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => addSquare()}
              >
                <AddBoxOutlinedIcon />
                {' '}
                {'Square'}
              </Button>
            </CustomToolTip>
          </Box>

          <Box mx={0.5} display="flex" bgcolor="#ffffff">
            <CustomToolTip
              title="Edit boundary of selected field by dragging polygon points"
              placement="top-start"
            >
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => editBoundary()}
              >
                <EditLocationIcon />
                {' '}
                {'Edit Boundary'}
              </Button>
            </CustomToolTip>
          </Box>

          <Box mx={0.5} display="flex" bgcolor="#ffffff">
            <CustomToolTip
              title="Cut boundary by selectiong a section to remove"
              placement="top-start"
            >
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => cutBoundary()}
              >
                <img src={scissors} height={24} alt="Cut" />
                {' '}
                {'Cut'}
              </Button>
            </CustomToolTip>
          </Box>

          {/* <Box mx={0.5} display="flex" bgcolor="#ffffff">
            <CustomToolTip
              title="Operation covers the entire field"
              placement="top-start"
            >
              <Button
                color="primary"
                variant="outlined"
                size="small"
                onClick={() => coverBoundary()}
              >
                <SettingsOverscanIcon />
                {' '}
                {'Cover Field'}
              </Button>
            </CustomToolTip>
          </Box> */}

          { !gartPath &&
            <Box display="flex" alignItems="center">
              <Switch
                color="primary"
                checked={enableClus}
                onChange={(e) => handleCluSwitch(e.target.checked)}
                inputProps={{
                  'aria-label': 'boundary toggle',
                }}
              />
              Show CLUs
            </Box>
          }
        </Box>
      )}

      { activeTool === 'edit' && (
        <Box>
          <Box mx={0.5} bgcolor="#ffffff">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => finishEdit()}
              disableElevation
            >
              Finish Edit
            </Button>
          </Box>
        </Box>
      )}

      { activeTool === 'draw' && (
        <Box>
          <Box mx={0.5} bgcolor="#ffffff">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => cancelDraw()}
              disableElevation
            >
              <CancelOutlinedIcon />
              Cancel Draw
            </Button>
          </Box>
        </Box>
      )}

      { activeTool === 'cut' && (
        <Box>
          <Box mx={0.5} bgcolor="#ffffff">
            <Button
              color="primary"
              variant="outlined"
              onClick={() => cancelCut()}
              disableElevation
            >
              <CancelOutlinedIcon />
              Cancel Cut
            </Button>
          </Box>
        </Box>
      )}

    </Box>
  );

  return (
    <Box
      display="flex"
      flexDirection="column"
      flexGrow={1}
    >
      {toolbar()}

      <Box
        id="operation-creation"
        display="flex"
        flexGrow={1}
      />
    </Box>
  );
}

CreateOperation.propTypes = {
  selectedOrg: PropTypes.shape({
    id: PropTypes.number.isRequired,
    name: PropTypes.string.isRequired,
  }),
  cluBoundaries: PropTypes.object.isRequired,
  field: PropTypes.object,
  handleBoundaryFromGeoJson: PropTypes.func.isRequired,
  impersonating: PropTypes.string.isRequired,
  operation: PropTypes.object.isRequired,
  updateCluBoundaries: PropTypes.func.isRequired,
  updateOperation: PropTypes.func.isRequired,
  useOrgCLUData: PropTypes.bool.isRequired,
  useOwnCLUData: PropTypes.string.isRequired,
};

CreateOperation.defaultProps = {
  field: null,
};

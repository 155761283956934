// -------------------- IMPORTS --------------------
// React
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

// Material-ui
import {
  Box, Modal, Button, Typography, IconButton, Fade, CircularProgress,
} from '@material-ui/core';
import HighlightOffIcon from '@material-ui/icons/HighlightOff';

// Styling
import { makeStyles } from '@material-ui/core/styles';
import { darkGreen } from '../../../styles/colors';

// Functionality, custom components, and helpful packages
import { CLUUploadOptions } from '../Modals/CLUUpload';
import { SpinningLoader } from '../../Shared/SpinningLoader';


// -------------------- STYLING --------------------
const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.centeredModal,
    padding: '0 0 28px 0',
    overflowY: 'auto',
    maxWidth: 835,
    boxShadow: '16px',
    maxHeight: '90vh',
  },
  header: {
    fontSize: 28,
    fontWeight: 700,
    margin: '18px 0 8px 0',
    fontFamily: 'OpenSans',
    textAlign: 'center',
  },
  text: {
    fontFamily: 'OpenSans-SemiBold',
    fontSize: '1.1rem',
    color: theme.palette.text.primary,
  },
  close: {
    position: 'absolute',
    top: -6,
    right: 0,
    color: darkGreen,
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  completeSection: {
    margin: 16,
    padding: '8px 24px',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    borderRadius: 8,
    border: `2px solid ${theme.palette.greys.main}`,
    backgroundColor: theme.palette.greys.light,
  },
  button: {
    textTransform: 'none',
    fontSize: '1.2rem',
    fontFamily: 'OpenSans',
    color: 'white',
    height: 40,
    width: 220,
    marginTop: 16,
  },
}));

// -------------------- MAIN FUNCTION --------------------
/**
 * Rendered in AcreageReporting/Onboarding/Onboarding.js
 * User can upload zipped shapefiles of geoJSON CLUs. When finished,
 * they can move to AcreageReporting.js to start completing reports.
 * @param {Bool} clusUploaded CLUs have been successfully uploaded
 * @param {String} impersonating User whose data Agent is using
 * @param {Bool} open Modal open
 * @param {Array} organizations User organizations
 * @param {String} primaryColor Color hex code determined by user type, agent or grower
 * @param {Object} selectedCLUOrg Org to associate uploaded CLU data with ({id: -1, name: ''})
 * @param {Function} setAgentPortalOpen Sets Agent Portal Modal open or close
 * @param {Function} setClusUploaded Sets CLUs have been successfully uploaded
 * @param {Function} setOpen Set modal open or close
 * @param {Function} setSelectedCLUOrg Changes state of selectedCLUOrg
 * @param {Function} setUseOrgCLUData Expects true or false
 * @param {Function} startReporting Move from onboarding to reporting
 * @param {Boolean} useOrgCLUData CLU data to use. Either true or false
 * @returns {JSX} Upload CLU modal
 */
export const UploadClus = ({
  clusUploaded,
  impersonating,
  open,
  organizations,
  primaryColor,
  selectedCLUOrg,
  setAgentPortalOpen,
  setClusUploaded,
  setOpen,
  setUseOrgCLUData,
  setSelectedCLUOrg,
  startReporting,
  useOrgCLUData,
}) => {
  const classes = useStyles();

  const [loading, setLoading] = useState(false);

  return (
    <Modal
      open={open}
      onClose={() => setOpen(false)}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
      aria-labelledby="change-explanation"
      aria-describedby="explain-reason-data-was-changed"
    >
      <Box
        className={classes.paper}
      >
        <IconButton
          className={classes.close}
          style={{ color: primaryColor }}
          onClick={() => setOpen(false)}
        >
          <HighlightOffIcon />
        </IconButton>

        <Typography className={classes.header}>
          Upload Your CLUs
        </Typography>

        <Box className={classes.section}>
          <CLUUploadOptions
            impersonating={impersonating}
            loading={loading}
            organizations={organizations}
            primaryColor={primaryColor}
            selectedCLUOrg={selectedCLUOrg}
            setAgentPortalOpen={setAgentPortalOpen}
            setClusUploaded={setClusUploaded}
            setLoading={setLoading}
            setOpen={setOpen}
            setSelectedCLUOrg={setSelectedCLUOrg}
            setUseOrgCLUData={setUseOrgCLUData}
            useOrgCLUData={useOrgCLUData}
          />

          {loading && <SpinningLoader size="sm" />}
          {/* {loading && 
            <Box mt="16px" display="flex" justifyContent="center" alignItems="center" height={40}>
              <CircularProgress />
            </Box>
          } */}
        </Box>

        <Fade
          in={clusUploaded && !loading}
          unmountOnExit
          mountOnEnter
        >
          <Box className={classes.completeSection}>
            <Typography className={classes.text}>
              Finished uploading your CLU files? Let&apos;s start building an acreage report!
            </Typography>
            <Button
              className={classes.button}
              style={{ background: primaryColor }}
              variant="contained"
              onClick={startReporting}
            >
              Upload Complete!
            </Button>
          </Box>
        </Fade>

      </Box>
    </Modal>
  );
};

UploadClus.propTypes = {
  clusUploaded: PropTypes.bool.isRequired,
  open: PropTypes.bool.isRequired,
  primaryColor: PropTypes.string.isRequired,
  setClusUploaded: PropTypes.func.isRequired,
  setOpen: PropTypes.func.isRequired,
  setUseOrgCLUData: PropTypes.func.isRequired,
  startReporting: PropTypes.func.isRequired,
  useOrgCLUData: PropTypes.bool.isRequired,
};

import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { makeStyles } from '@material-ui/core/styles';
import {
  Box,
  Button,
  Typography,
  Select,
  MenuItem,
  TextField,
  Divider,
  Modal,
  Checkbox,
  IconButton,
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import SaveIcon from '@material-ui/icons/Save';
import SyncIcon from '@material-ui/icons/Sync';
import CancelOutlinedIcon from '@material-ui/icons/CancelOutlined';
import { darkText, blackText, green } from '../../styles/colors';
import { MenuProps } from '../../styles/select';

function getModalStyle() {
  const top = 10;

  return {
    top: `${top}%`,
    margin:'auto',
    zIndex: 1050,
    height: 'auto',
    width: '400px',
  };
}

const useStyles = makeStyles((theme) => ({
  paper: {
    ...theme.modalPaper,
    width: '600px',
    height: 'auto',
    justifyContent: 'space-between',
    zIndex: 1049,
  },
  head: {
    height: '50px',
    padding: '20px',
    alignItems: 'center',
    width: '100%',
    color: darkText,
    fontSize: 20,
    fontWeight: 500,
    display: 'flex',
  },
  body: {
    height: 'auto',
    width: '100%',
    padding: '0 20px',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.black,
  },
  buttonBox: {
    ...theme.buttonBox,
    alignItems: 'center',
    height: '60px',
  },
  input: {
    width: '122px',
    height: '40px',
  },
  description: {
    fontSize: 14,
    color: theme.palette.text.grey,
  },
  edit: {
    marginLeft: "5px",
    fontSize: 14,
    "&:hover": {
      cursor: "pointer",
    },
  },
  icon: {
    color: theme.palette.primary.main,
    fontSize: 20,
    "&:hover": {
      cursor: "pointer",
    },
    marginLeft: 6,
  }
}));

export function Settings({
  netSeededAcres,
  setNetSeededAcres,
  perAcre,
  setPerAcre,
  highRes,
  setHighRes,
  settingsOpen,
  setSettingsOpen,
  handleReportNameChange,
  setEditReportName,
  editReportName,
  reportName,
  setNewReportName,
  clipByBoundary,
  setClipByBoundary,
  syncData,
  orgSynced,
  operationsSynced,
  boundarySynced,
  setManuallyUpdated
}) {
  const classes = useStyles();
  const [modalStyle] = useState(getModalStyle);
  const [acres, setAcres] = useState(+Number(netSeededAcres).toFixed(2));
  const [per, setPer] = useState(perAcre);
  const [useHighRes, setUseHighRes] = useState(highRes);
  const [open, setOpen] = useState(settingsOpen);

  const save = () => {
    setNetSeededAcres(acres);
    setPerAcre(per);
    setUseHighRes(useHighRes);
    setSettingsOpen(false);
    setManuallyUpdated(true)
  };

  return (
    <Modal
      open={settingsOpen}
      style={{display:'flex',alignItems:'center',justifyContent:'center'}}
    >
      <Box
        style={modalStyle}
        className={classes.paper}
        borderRadius="borderRadius"
      >
        <Box className={classes.head}>
          Report Settings
        </Box>
        <Box className={classes.body}>
          <Divider />

          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box>
              Net Seeded Area(acres)
              <Box className={classes.description}>
                This value was read from your synced data for the field.
              </Box>
            </Box>
            <TextField
              className={classes.input}
              variant="outlined"
              type="number"
              value={acres}
              inputProps={{
                style: {
                  padding: '8.5px 14px',
                },
              }}
              onChange={(event) => setAcres(event.target.value)}
            />
          </Box>

          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box>
              Show value as
              <Box className={classes.description}>
                Changing this will update information presented by tables.
              </Box>
            </Box>
            <Select
              className={classes.input}
              variant="outlined"
              MenuProps={MenuProps}
              value={per}
              onChange={(event) => setPer(event.target.value)}
            >
              <MenuItem value>Per Acre</MenuItem>
              <MenuItem value={false}>Per Field</MenuItem>
            </Select>
          </Box>

          <Box
            mt={3}
            display="flex"
            justifyContent="space-between"
            flexWrap="wrap"
          >
            <Box>
              Generate high-resolution maps (BETA)
              <Box className={classes.description}>
                High-res maps take longer to generate. (~5 minutes)
              </Box>
            </Box>
            <Select
              className={classes.input}
              variant="outlined"
              MenuProps={MenuProps}
              value={highRes}
              onChange={(event) => setHighRes(event.target.value)}
            >
              <MenuItem value={true}>Yes</MenuItem>
              <MenuItem value={false}>No</MenuItem>
            </Select>
          </Box>

          <Box mt={1}>
            <Box display='flex' alignItems='center'>
              <Box>
                Sync Organization
              </Box>
              <IconButton
                color="primary"
                onClick={() => syncData("organizations")}
                style={{padding: 4}}
              >
                <SyncIcon />
              </IconButton>
            </Box>
            <Box fontSize={14} color={darkText}>
              {'Last Synced: '}
              {orgSynced}
            </Box>
          </Box>

          <Box mt={1}>
            <Box display='flex' alignItems='center'>
              <Box>
                Sync Operations
              </Box>
              <IconButton
                color="primary"
                onClick={() => syncData("operations")}
                style={{padding: 4}}
              >
                <SyncIcon />
              </IconButton>

            </Box>
            <Box fontSize={14} color={darkText}>
              {'Last Synced: '}
              {operationsSynced}
            </Box>
          </Box>

          <Box mt={1}>
            <Box display='flex' alignItems='center'>
              <Box>
                Sync Boundaries
              </Box>
              <IconButton
                color="primary"
                onClick={() => syncData("boundaries")}
                style={{padding: 4}}
              >
                <SyncIcon />
              </IconButton>

            </Box>
            <Box fontSize={14} color={darkText}>
              {'Last Synced: '}
              {boundarySynced}
            </Box>
          </Box>

          <Box
           my={2}
           display="flex"
           flexWrap="wrap"
           alignItems='center'
          >
            <Checkbox
              color='primary'
              checked={clipByBoundary}
              onChange={() => setClipByBoundary(!clipByBoundary)}
            />
            <Box>
              Clip ProfitMap by field boundary
            </Box>

          </Box>

        </Box>
        <Box className={classes.buttonBox}>
          <Box m={1}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => setSettingsOpen(false)}
              style={{backgroundColor: '#ffffff'}}
            >
              Cancel
            </Button>
          </Box>
          <Box m={1}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              onClick={() => save()}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Box>
    </Modal>
  );
}

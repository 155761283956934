import React, { useEffect, useState, useContext, Fragment } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import { Box, Typography, Select, MenuItem, Link, Divider } from '@material-ui/core';
import { blackText, grey, lightGrey } from "../../styles/colors";
import { useWindowDimensions } from '../../utils/dimensions'
import {dollarFormat} from '../../utils/helpers'

const useStyles = makeStyles(theme => ({
  table: { 
    minWidth: '400px', 
    maxWidth: '800px', 
    overflowX: 'auto', 
    backgroundColor: "white"
  }
}))

/**
 * Display data in table for the Forecasted value of selected field
 * @param {object} forecast data from the getComps() request in LoanUnderwriting
 * @param {string} status 'Sold' or 'For Sale'
 */
export function LandForecastTable({forecast, status}){
  const {width, height} = useWindowDimensions();
  const classes = useStyles()
  const tableBreakPoint = 660;

  const [details, setDetails] = useState(null)

  useEffect(()=>{
    if(forecast !== undefined && status !== undefined && forecast!==null){
      // console.log(forecast)
      // console.log(forecast[status])
      let toUse = forecast[status]
      setDetails(toUse)
    }
  },[forecast, status])


  return(
    <Box>
      {details !== null &&
        <Box
          m={1}
          border={1}
          borderColor={grey}
          borderRadius='borderRadius'
          className={classes.table}
        >
          <Box
            p={1}
            display='flex'
            fontSize={width > tableBreakPoint ? 14 : 12}
            style={{textAlign: "center"}}
          >
            <Box style={{width: '100%'}}>
              Comparable Sales Summary ({status})
            </Box>
          </Box>
          <Divider/>
          <Box
            p={1}
            display='flex'
            fontSize={width > tableBreakPoint ? 14 : 12}
          >
            <Box style={{width: '35%'}}>
              Metric
            </Box>
            <Box style={{width: '65%'}}>
              Value ($/Acre)
            </Box>

          </Box>

          <Divider style={{color: grey, height: 4}}/>

          <Box
            p={1}
            display='flex'
            alignItems='center'
            style={{backgroundColor: 'rgb(244, 244, 244)'}}
          >
            <Box style={{width: '35%'}}>
              Average Price
            </Box>
            <Box style={{width: '65%'}}>
              {details.avg_price === undefined ? 0 : dollarFormat(details.avg_price)}
            </Box>
          </Box>

          <Box
            p={1}
            display='flex'
            alignItems='center'
          >
            <Box style={{width: '35%'}}>
              Max Price
            </Box>
            <Box style={{width: '65%'}}>
              {details.max_price === undefined ? 0 : dollarFormat(details.max_price)}
            </Box>
          </Box>

          <Box
            p={1}
            display='flex'
            alignItems='center'
            style={{backgroundColor: 'rgb(244, 244, 244)'}}
          >
            <Box style={{width: '35%'}}>
              Min Price
            </Box>
            <Box style={{width: '65%'}}>
              {details.min_price === undefined ? 0 : dollarFormat(details.min_price)}
            </Box>
          </Box>

          <Box
            p={1}
            display='flex'
            alignItems='center'
          >
            <Box style={{width: '35%'}}>
              90th Percentile
            </Box>
            <Box style={{width: '65%'}}>
              {details.Percentile_90 === undefined ? 0 : dollarFormat(details.Percentile_90)}
            </Box>
          </Box>

          <Box
            p={1}
            display='flex'
            alignItems='center'
            style={{backgroundColor: 'rgb(244, 244, 244)'}}
          >
            <Box style={{width: '35%'}}>
              10th Percentile
            </Box>
            <Box style={{width: '65%'}}>
              {details.Percentile_10 === undefined ? 0 : dollarFormat(details.Percentile_10)}
            </Box>
          </Box>

          <Box
            p={1}
            display='flex'
            alignItems='center'
          >
            <Box style={{width: '35%'}}>
              Count
            </Box>
            <Box style={{width: '65%'}}>
              {details.num_list === undefined ? 0 : details.num_list}
            </Box>
          </Box>

        </Box>

      }

    </Box>
    
  )
}